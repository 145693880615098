/*
 * input-components.js
 * This file contains code for input component which will render input based on schema.
 * @licensor  Miral
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';

import './input-component.scss';
import { canUseDOM, checkTenant, isMatchTenant, isParksExpressCheckoutTenant } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';

const Input = ({
  data,
  onChange,
  onBlur,
  onFocus,
  onPaste,
  onCopy,
  controlled,
  is2StepPayment,
  isTabIndex,
  emailValidated,
  isManageBooking = false,
}) => {
  const [inputFocused, setInputFocused] = useState(true);

  const isParks = isParksExpressCheckoutTenant();

  const [inputValue, setInputValue] = useState(data.value || '');

  const regexPattern = /^(?!.*[<>]).*$/;

  const handleInputChange = (event, type) => {
    const { value } = event.target;
    if (regexPattern.test(value)) {
      setInputValue(value);
      if (type === 'change') {
        onChange(event);
      }
      if (type === 'blur') {
        onBlur(event);
      }
      if (type === 'focus') {
        onFocus(event);
      }
    }
  };

  const manageBookingYiHowerHandler = (data) => {
    window.PubSub.publish('showHowerComponentYiManageBooking', data);
  };

  const manageBookingYiLeaveHowerHandler = (data) => {
    window.PubSub.publish('hideHowerComponentYiManageBooking', data);
  };

  let serverErrorOnDom = canUseDOM() && document.querySelectorAll('.yasid-email-error').length > 0;
  try {
    let inputHtml = null;
    let inputProps = {};
    let inputType = data.type;
    if (data.type === 'Password' && data.name === 'CVV') {
      inputProps.inputMode = 'numeric';
    }
    if (data.type === 'Number') {
      inputType = 'tel';
    }
    if (data.inputMode) {
      inputProps.inputMode = data.inputMode;
    }

    if (!controlled) {
      const formattedClass = data.class && data.class.includes('card-number') ? `vertical-${data.class}` : '';
      inputHtml = (
        <div
          className={`form-element ${data.active} ${
            data.toggleDisplay
          } ${formattedClass} input-type-${data.type.toLowerCase()} `}
        >
          <DynamicContent
            tagName="label"
            attrs={{
              className: 'form-label',
              htmlFor: data.id,
              id: 'label_' + data.id,
            }}
            innerHtml={data.label}
          />
          <input
            className={`form-input body-copy-3 ${data.class} ${data.showErrors ? data.valid : ''} ${
              is2StepPayment && data.isRequired ? 'required--field' : ''
            }`}
            name={data.name}
            type={inputType}
            {...inputProps}
            readOnly={data.readonly}
            aria-required={data.isRequired}
            defaultValue={data.value}
            value={inputValue}
            id={data.id}
            disabled={data.disabled}
            tabIndex={isTabIndex}
            data-index={data.index}
            data-pid={data.produtId}
            data-fieldname={data.fieldName}
            onChange={(e) => handleInputChange(e, 'change')}
            onBlur={(e) => handleInputChange(e, 'blur')}
            autoComplete={is2StepPayment ? 'off' : 'on'}
            onFocus={(e) => handleInputChange(e, 'focus')}
            onPaste={onPaste}
            onCopy={onCopy}
            placeholder={data.placeholder}
            maxLength={data.maxLength}
            aria-labelledby={data.showErrors ? `error_${data.id}` : null}
            aria-label={!data.showErrors ? data.placeholder : null}
            aria-invalid={data.showErrors ? true : false}
          />
          {data.showErrors ? (
            <Text
              tag="span"
              id={`error_${data.id}`}
              aria-live="assertive"
              aria-relevant="additions removals"
              className={`error-msg body-copy-6 ${is2StepPayment ? 'form-error--field' : ''}`}
              field={{
                value: data.message,
                editable: data.message,
              }}
            />
          ) : null}
        </div>
      );
    } else {
      inputHtml = (
        <div
          className={`form-element ${serverErrorOnDom && inputType === 'Email' ? 'server-email-unvalidated' : ''}
            ${inputFocused && !data.showErrors && emailValidated && inputType === 'Email' ? 'email-validated' : ''} ${
            data.active
          } ${data.toggleDisplay} input-type-${data.type.toLowerCase()}`}
        >
          <DynamicContent
            tagName="label"
            attrs={{
              className: 'form-label',
              htmlFor: data.id,
            }}
            innerHtml={data.label}
          />
          {isMatchTenant(UIConfig.YIB2C) && data?.name === 'BookingRefId' && isManageBooking && (
            <span
              className="managebooking-yi-email-tooltip"
              onMouseOver={() => manageBookingYiHowerHandler(true)}
              onMouseLeave={() => manageBookingYiLeaveHowerHandler(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.992 12.496C8.992 13.056 8.544 13.488 8 13.488C7.456 13.488 7.008 13.04 7.008 12.496V6.496C7.008 5.936 7.456 5.504 8 5.504C8.544 5.504 8.992 5.952 8.992 6.496V12.496ZM8 4.496C7.44 4.496 7.008 4.048 7.008 3.504C7.008 2.96 7.456 2.512 8 2.512C8.544 2.512 8.992 2.96 8.992 3.504C8.992 4.048 8.56 4.496 8 4.496Z"
                  fill="#67757C"
                />
              </svg>
            </span>
          )}

          <input
            className={`form-input body-copy-3 ${data.class} ${data.showErrors ? data.valid : ''} ${
              is2StepPayment && data.isRequired ? 'required--field' : ''
            } ${isParks && data.isDisabled ? 'disabled' : ''}`}
            name={data.name}
            type={inputType}
            {...inputProps}
            value={inputValue}
            tabIndex={isTabIndex ? isTabIndex : 0}
            autoComplete={is2StepPayment ? 'off' : 'on'}
            readOnly={data.readonly}
            aria-required={data.isRequired}
            id={data.id}
            data-index={data.index}
            data-pid={data.produtId}
            data-fieldname={data.fieldName}
            onBlur={(e, field, blurForm) => {
              onBlur(e, field, blurForm);
              setInputFocused(true);
            }}
            onFocus={(e) => {
              onFocus(e);
              if (e.target.id === data.id) {
                setInputFocused(false);
              }
            }}
            // onChange={(e, field, blurForm) => {
            //   onChange(e, field, blurForm);
            //   if (e.target.id === data.id) {
            //     setInputFocused(false);
            //   }
            // }}
            onChange={(e, field, blurForm) => {
              if (regexPattern.test(e.target.value)) {
                setInputValue(e.target.value);
                onChange(e, field, blurForm);
              }
              if (e.target.id === data.id) {
                setInputFocused(false);
              }
            }}
            onPaste={onPaste}
            onCopy={onCopy}
            placeholder={data.placeholder}
            maxLength={data.maxLength}
            aria-labelledby={'error_' + data.id}
            aria-invalid={data.showErrors ? true : false}
          />
          {data.type.toLowerCase() === 'password' && data.tenantId && data.tenantId === 'YIB2C' && (
            <>
              <span className="valid-password-icon"></span>
            </>
          )}
          {data.showErrors ? (
            <Text
              tag="span"
              id={`error_${data.id}`}
              aria-live="assertive"
              aria-relevant="additions removals"
              className={`error-msg body-copy-6 ${is2StepPayment ? 'form-error--field' : ''}`}
              field={{
                value: data.message,
                editable: data.message,
              }}
            />
          ) : null}
        </div>
      );
    }
    return inputHtml;
  } catch (err) {
    return logComponentRenderingError(err, 'Input');
  }
};

Input.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf(['Text', 'Number', 'Email', 'Password', 'Hidden', 'Date', 'Reset']).isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
  }),
};

export default Input;
