import {
  isLoggedInUser,
  getLoggedInUser,
  getYasIdGTMData,
  getEmailIdGTMData,
  generateShaEncryption,
  checkGA4Tenants,
} from '../../common/utility';
import UIConfig from '../../common/UIConfig';

export const getDataLayerScript = ({
  siteType = '',
  marketType = '',
  tenantName = '',
  language = '',
  primaryItem = '',
  secondaryItem = '',
  tertiarySection = '',
  pageName = '',
  userRole = '',
  userRoleLoggedin = '',
  loginStatusLoggedIn = '',
  loginStatus = '',
  searchTerm = '',
  searchCount = '',
  pageCategory = '',
}) => {
  if (checkGA4Tenants([], [UIConfig.tenants.ya])) {
    return `
    var dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({ site_type: '${siteType}', 
    event:'page_view',
    tenant: '${tenantName || undefined}', 
    page_language: '${language || undefined}', 
    primary_section: '${primaryItem || undefined}', 
    secondary_section: '${secondaryItem || undefined}', 
    tertiary_section: '${tertiarySection || undefined}', 
    page_name: '${pageName || undefined}', 
    page_category: '${pageCategory || undefined}', 
    user_role: '${isLoggedInUser() ? userRoleLoggedin : userRole}', 
    login_status: '${isLoggedInUser() ? loginStatusLoggedIn : loginStatus}', 
    search_term:'${searchTerm || undefined}', 
    search_count: '${searchCount || undefined}', 
    market_type: '${marketType || undefined}',
    my_pass_id:'${getYasIdGTMData() || undefined}',
    user_id:'${getYasIdGTMData() || undefined}', 
    login_type:'${getLoggedInUser()?.loginProvider || undefined}', 
    email:'${getEmailIdGTMData() ? generateShaEncryption(getEmailIdGTMData()) : undefined}'
  });`;
  }
  return `
    var dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({ siteType: '${siteType}', 
    tenant: '${tenantName || ''}', 
    language: '${language || ''}', 
    primarySection: '${primaryItem || ''}', 
    secondarySection: '${secondaryItem || ''}', 
    tertiarySection: '${tertiarySection || ''}', 
    pageName: '${pageName || ''}', 
    pageCategory: '${pageCategory || ''}', 
    userRole: '${
      isLoggedInUser() ? (tenantName === 'b2b' ? getLoggedInUser().userType : userRoleLoggedin) : userRole
    }', 
    loginStatus: '${isLoggedInUser() ? loginStatusLoggedIn : loginStatus}', 
    searchTerm: '${searchTerm || ''}', 
    searchCount: '${searchCount || ''}', 
    marketType: '${marketType || ''}',
    myPassId:'${getYasIdGTMData()}',
    userId:'${getEmailIdGTMData()}'});`;
};

export const getTagManagerScript = ({ gtmId = '' }) => {
  return `
  (function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '${gtmId}');
`;
};
