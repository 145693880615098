/*
 * Filter.js
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
const { func } = PropTypes;

/**
 * Filters class Provide a defined interface for
 * declaring filters and getting state of them at any given time.
 * It injects an updateFilter func(name, value) function
 * through the context to the 'filter' components. (defined in filter-composition.js)
 */

export default class Filters extends React.Component {
  static childContextTypes = {
    updateFilter: func,
  };

  state = { filters: {} };
  /**
   * notifyChange function contains the flag whether filters should be updated or not
   * @param    {dontNotifyMainComponent} accepts the flag of whether Parent Component
   * has to be notified or not.
   * @return   {[Void]} function does not return anything.
   */

  notifyChange = (dontNotifyMainComponent) => {
    this.props.onChange(this.state.filters, dontNotifyMainComponent);
  };
  /**
   * updateFilter function updates the array of filters
   * @param    {name} contains the name of the filter
   * @param    {value} contains the value of the filter
   * @param    {dontNotifyMainComponent} contains the flag whether Parent Component's filters
   * should be notified or not
   * @return   {[Void]} function does not return anything.
   */

  updateFilter = (name, value, dontNotifyMainComponent) => {
    this.setState(
      {
        filters: { ...this.state.filters, [name]: value },
      },
      this.notifyChange.bind(this, dontNotifyMainComponent),
    );
  };

  /**
   * getChildContext() function is React function to pass data
   * through the component tree without having to pass the props down manually at every level.
   * @return   {[updateFilter]} returns the current context of updateFilter function
   */
  getChildContext() {
    return {
      updateFilter: this.updateFilter,
    };
  }

  render() {
    return <div className="filterWrapper">{this.props.children}</div>;
  }
}

Filters.propTypes = {
  onChange: func.isRequired,
};
