import React from 'react';
import PropTypes from 'prop-types';
import CardCarouselItem from '../card-item';
import Slider from 'react-slick';
import { detectMobile } from '../../../common/utility';

import './card-carousel-component.scss';

export default class CardCarouselComponent extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.settings = {};
    this.heroHandler = this.props.heroHandler;
    this.state = {
      mobileView: false,
      activeCardId: props.activeCardId,
    };
    this.variantCardCta = 'card_with_cta';
    this.slider = React.createRef();
  }

  /**
   * componentDidMount LifeCycle Method to subscribe to windowResize event to check
   * if current view is Mobile or not.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  componentDidMount() {
    this.setState({
      mobileView: detectMobile(),
    });
    window.PubSub.subscribe('windowResize', this.updateCarouselWithPreviewCardView);
  }

  /**
   * componentWillUnmount LifeCycle Method to unsubscribe to windowResize event.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  componentWillUnmount() {
    window.PubSub.unsubscribe('windowResize');
  }

  /**
   * setActiveCardState to get the id of clicked card and set state of activeCardId
   * @param    {[number]} newId -- id of clicked card
   * @return   {[Void]} function does not return anything.
   */
  setActiveCardState = (newId) => {
    this.setState({
      activeCardId: newId,
    });
  };

  /**
   * updateCarouselWithPreviewCardView   function to update Carousel Card List View on Window Resize event.
   * It calls setState function to check if current view is Mobile or not
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  updateCarouselWithPreviewCardView = () => {
    this.setState({
      mobileView: detectMobile(),
    });
  };

  /**
   * componentWillMount LifeCycle Method to set Carousel setting
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  componentWillMount() {
    const dataArraylength = this.data.carouselList.length;
    this.settings = {
      dots: true,
      infinite: true,
      arrows: true,
      centerMode: true,
      variableWidth: true,
      slidesToShow: 1,
      accessibility: true,
      beforeChange: (oldIndex, newIndex) => {
        const data = this.data.carouselList[newIndex];
        this.heroHandler(data);
      },
      customPaging: function(i) {
        return (
          <span>
            {i + 1}/{dataArraylength}
          </span>
        );
      },
      responsive: [
        {
          breakpoint: 640,
          settings: {
            centerPadding: '20px',
          },
        },
        {
          breakpoint: 1023,
          settings: {
            centerPadding: '40px',
          },
        },
      ],
    };
  }

  /**
	* cardCarouselItems   function to display Card Items based on the selected viewtype selected
		and device
	* @param    {[data]} data object of the element
	* @return   {[Object]} Return a render function which conatins the JSX of the component.
	*/

  cardCarouselItems(data, variantCardCta = null) {
    return this.state.mobileView ? (
      <div>
        <Slider ref={this.slider} {...this.settings}>
          {data.carouselList &&
            data.carouselList.map((item, index) => (
              <div key={index}>
                <CardCarouselItem
                  data={item}
                  heroHandler={() => {
                    // Empty function. As it does not needs to be handled
                  }}
                  setActiveCardState={() => {
                    // Empty function. As it does not needs to be handled
                  }}
                  id={index}
                  ctaButton={data.variant && data.variant === variantCardCta ? item.heroButton : null}
                />
              </div>
            ))}
        </Slider>
      </div>
    ) : (
      <div>
        <ul className="card-carsl-item-list">
          {data.carouselList &&
            data.carouselList.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    index === this.state.activeCardId
                      ? 'card-carous-list-trans-2x card-carsl-item'
                      : 'card-carous-list-trans-1x card-carsl-item'
                  }
                >
                  <CardCarouselItem
                    data={item}
                    heroHandler={this.heroHandler}
                    setActiveCardState={this.setActiveCardState}
                    id={index}
                    ctaButton={data.variant && data.variant === variantCardCta ? item.heroButton : null}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.mobileView !== nextState.mobileView || !this.state.mobileView ? true : false;
  }

  render() {
    this.state.mobileView &&
      setTimeout(() => {
        this.slider.current.slickGoTo(parseInt(this.props.activeCardId) || this.data.carouselList.length);
      }, 0);

    return (
      <div className="card-carousel-component-container">{this.cardCarouselItems(this.data, this.variantCardCta)}</div>
    );
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */

CardCarouselComponent.propTypes = {
  activeCardId: PropTypes.number,
  heroHandler: PropTypes.func,
  data: PropTypes.shape({
    carouselList: PropTypes.arrayOf(
      PropTypes.shape({
        cardTitle: PropTypes.string,
        cardStripFlag: PropTypes.bool,
        cardStripText: PropTypes.string,
        cardDefaultValue: PropTypes.bool,
        cardImage: PropTypes.object.isRequired,
        heroDescription: PropTypes.string,
        heroTitle: PropTypes.string,
        heroImage: PropTypes.object.isRequired,
        heroButton: PropTypes.object,
        textColor: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};
