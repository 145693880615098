/*
 * media-carousel-components.js
 * @licensor  Miral
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  carouselDefaultSettings,
  detectMobile,
  aspectRatioCalculator,
  isTouchDevice,
  deepCloneObject,
  getMainObject,
  isLiveStreamingStarted,
  isArabicMode,
  getLoggedInUser,
  canUseDOM,
  resolvePath,
  isMobileOrTab,
  isKeyboardNavigation,
  checkIfParks,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { AnchorLink, Carousel, DynamicContent, MediaCarouselItem } from '../../presentation/base';
import useForceUpdate from '../../../hooks/useForceUpdate';
import useBeforeFirstRender from '../../../hooks/useBeforeFirstRender';
import LiveStreaming from '../../presentation/live-streaming';
import { logComponentRenderingError } from '../../../common/logger';
import { checkTenant } from '../../../common/utility';

import Helmet from 'react-helmet';
import { bodyClicks } from '../../../common/analytics-events';
const MediaCarousel = (props) => {
  const { data, fromTabsWithSlider, isSmartHeroCarousel } = props;
  const isOverlay = props.overlay || false;
  const isThumbnailHalfSlideShow = props.thumbnailHalfSlideShow || false;
  let currentSlide = props.currentSlide;
  let mobileView = detectMobile();
  const isFirstLoad = useRef(true);
  const settings = useRef(null);
  const carouselRef = useRef(null);
  const [domLoaded, setDomLoaded] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [isShowContent, setIsShowContent] = useState(false);
  // const [activeSlider, setActiveSlider] = useState(0);
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const currentTenant = resolvePath(getMainObj, 'tenantID', '').toLowerCase();

  // Numbers for Sliders (Gallery component - popup slider) - YAS
  const thumbnailSlideNumberSetting = {
    updatedThumbnailSlide: 4.2,
    initialThumbnailSlide: 3.2,
    totalThumbnailSlide: 5,
  };

  // useEffect(() => {

  // }, []);

  // const slideAutoPlay = () => {
  //   const { carousel, mediaItems } = data;
  //   const { autoplay, autoplaySpeed, infiniteScroll } = carousel;
  //   let slideCount = 0;
  //   if (checkTenant(UIConfig.tenants.ya) && autoplay && autoplaySpeed) {
  //     slideNextInterval.current = setInterval(() => {
  //       try {
  //         const curIndx = slideCurrent.current;
  //         const slideRef = sliderRef.current;
  //         const curSlide = slideRef.innerSlider?.state?.currentSlide || curIndx;
  //         if (curSlide >= mediaItems.length - 1 && !infiniteScroll) {
  //           clearInterval(slideNextInterval.current);
  //         } else if (slideRef.slickNext) {
  //           slideCount = curIndx + 1;
  //           if (slideCount !== curIndx) {
  //             slideCurrent.current = slideCount;
  //             slideRef.slickNext();
  //           }
  //         }
  //       } catch (err) {
  //         console.log('YA autoslide', err);
  //         clearInterval(slideNextInterval.current);
  //       }
  //     }, autoplaySpeed);
  //   }
  // };

  useEffect(() => {
    let hashValue = window.location.hash.split('#')[1];
    const { liveStreaming } = props.data.mediaItems[0];
    setIsShowContent(false);
    if (data.variant.indexOf('v-media-gallery-full-bleed') > -1) {
      window.PubSub.subscribe('oreintationChange', () => {
        setTimeout(() => {
          updateMediaCarouselView();
          const forceUpdate = useForceUpdate;
          forceUpdate();
        }, 50);
      });
    }
    if (
      liveStreaming &&
      liveStreaming.enableLiveStream &&
      isLiveStreamingStarted(liveStreaming) &&
      hashValue === liveStreaming.deepLinkingHashValue
    ) {
      openLiveStreamingPopup(liveStreaming);
    }
    isFirstLoad.current = false;
    if (isSmartHeroCarousel) {
      const bodyWidth = document.getElementsByTagName('body')[0].offsetWidth;
      const slickSlides = document.querySelectorAll('.smart-hero-carousel-view .slick-slide');
      slickSlides?.length &&
        slickSlides.forEach((slide) => {
          slide.style.minWidth = `${bodyWidth}px`;
        });
      setTimeout(() => {
        playActiveVid();
      }, 500);

      window.PubSub.subscribe('isTabbing', (msg, data) => {
        makeBtnDisable();
        if (data.isEnabled && checkTabbingEnable()) {
          // const slideActive = document.querySelector(`.smart-hero-carousel-view .slick-slide.slick-active`);
          const slideNotActive = document.querySelectorAll(`.smart-hero-carousel-view .slick-slide:not(.slick-active)`);
          slideNotActive?.length &&
            slideNotActive.forEach((slide) => {
              slide.style.visibility = 'hidden';
            });
        } else {
          makeAllSlidesVisible();
        }
      });
    }
    setDomLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeBtnDisable = () => {
    const slideDisabled = document.querySelector('.smart-hero-carousel-view .slick-slider .slick-disabled');
    if (slideDisabled) {
      slideDisabled.setAttribute('disabled', true);
    }
  };

  const checkTabbingEnable = () => {
    return !isMobileOrTab() && isKeyboardNavigation();
  };

  const playActiveVid = () => {
    const parentClass = checkTenant(UIConfig.iamMapping.etihadarena)
      ? '.tenant-etihadarena'
      : '.smart-hero-carousel-view';
    const allVideo = document.querySelectorAll(`${parentClass} .slick-slide .c-video-player video`);
    const allActiveVideo = document.querySelectorAll(
      `${parentClass}  .slick-slide.slick-active[data-index]:not([data-index="-1"]) .c-video-player.video-autoplay video`,
    );
    allVideo?.length && allVideo.forEach((slide) => slide.pause());
    let activeVideo;
    if (allActiveVideo.length > 1 && isArabicMode()) {
      activeVideo = allActiveVideo;
    } else {
      activeVideo = allActiveVideo?.[0];
    }
    setShowControls(false);
    if (activeVideo) {
      if (activeVideo.length > 0) {
        activeVideo.forEach((vid) => vid.play());
      } else {
        activeVideo.play();
      }
    }
  };

  const makeAllSlidesVisible = () => {
    const slideNext = document.querySelectorAll('.smart-hero-carousel-view .slick-slide');
    if (slideNext?.length) {
      slideNext.forEach((slide) => (slide.style.visibility = 'visible'));
    }
  };

  /**
   * updateMediaCarouselView   function to update Media Carousel View on Window Resize event.
   * It calls setState function to check if current view is Mobile or not
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  const updateMediaCarouselView = () => {
    mobileView = detectMobile();
    updateSettings();
  };
  /**
   * customCarouselSettings function to return the custom carousel settings properties
   * and update in carousel settings
   * @param {[data]} data object of the element
   * @return {[Object]} Return custom carausel settings.
   */
  const getCustomCarouselSettings = () => {
    const { carousel, mediaItems, variant } = data;
    const className = classNames('c-media-carousel--item', { 'dot-enabled': carousel.dots });
    const dotsClass =
      data.variant !== UIConfig.commonVariant.photoCollageThemeParkVariant &&
      data.variant !== UIConfig.swadVariant.photoCollageThemeParkVariant
        ? classNames((!isOverlay || mobileView) && mediaItems.length > 1 ? 'custom-dots dots-mc' : 'no-dots')
        : classNames('slick-dots');
    return {
      lazyLoad: variant && variant.indexOf(UIConfig.b2c.purchaseJourney.mediaCarousel.yaCarouselLazyLoadVariant) > -1,
      className,
      dotsClass,
      customPaging: function(i) {
        let slickItemContent = '';
        if (variant && variant.indexOf('remove-pagination-zero') > -1) {
          slickItemContent = (
            <span>
              <span className="slide-in-view">
                {i < 9 && <span className="prefixed-zero">0</span>}
                {carousel && mediaItems && carousel.rtl ? mediaItems.length - i : i + 1}
              </span>
              <span className="separator">/</span>
              <span className="total-slide">
                {mediaItems.length < 10 && <span className="prefixed-zero">0</span>}
                {mediaItems && mediaItems.length}
              </span>
            </span>
          );
        } else {
          slickItemContent = (
            <span>
              <span className="slide-in-view">{carousel.rtl ? mediaItems.length - i : i + 1}</span>
              <span className="separator">/</span>
              <span className="total-slide">{mediaItems.length}</span>
            </span>
          );
        }
        if (
          variant &&
          (variant.indexOf('ya-two-image-carousal') > -1 || variant.indexOf('v-carousel-nopadding') > -1)
        ) {
          return <button className="media-carousel--button">{slickItemContent}</button>;
        }
        return slickItemContent;
      },
    };
  };
  const updateSettings = () => {
    const { carousel, mediaItems, variant } = data;

    const checkForParks = () => {
      // FWAD, YWW, WBW, QAW, CLYMB
      if (
        checkTenant(UIConfig?.iamMapping?.fwad) ||
        checkTenant(UIConfig?.iamMapping?.yww) ||
        checkTenant(UIConfig?.iamMapping?.wbw) ||
        checkTenant(UIConfig?.iamMapping?.ppad) ||
        checkTenant(UIConfig?.iamMapping?.clymb) ||
        checkTenant(UIConfig?.iamMapping?.etihadarena) ||
        checkTenant(UIConfig?.iamMapping?.yasisland)
      ) {
        return true;
      } else {
        return false;
      }
    };

    let slidesToShow;
    const slideStatus = currentTenant === UIConfig.YIB2C && isThumbnailHalfSlideShow;
    const sliderNumber = slideStatus
      ? thumbnailSlideNumberSetting.updatedThumbnailSlide
      : thumbnailSlideNumberSetting.totalThumbnailSlide;

    if (props.thumbnail) {
      slidesToShow = carousel.thumbnailSlideToShow || sliderNumber;
    } else {
      slidesToShow = carousel.slidesToShow;
    }

    const autoplay = mediaItems.length > 1 ? carousel.autoplay : false;
    let customCarouselSettings = getCustomCarouselSettings();
    const responsiveCarouselSettings = {
      breakpoint: 10000,
      settings: {
        autoplay: !checkTenant(UIConfig.iamMapping.etihadarena) ? autoplay : carousel.autoplay,
        swipe: isTouchDevice(),
      },
    };
    if (!isSmartHeroCarousel) {
      customCarouselSettings = Object.assign(
        {},
        deepCloneObject(carouselDefaultSettings),
        deepCloneObject(customCarouselSettings),
      );
    }
    !isSmartHeroCarousel && customCarouselSettings.responsive.push(responsiveCarouselSettings);
    customCarouselSettings.autoplay = autoplay;
    customCarouselSettings.slidesToScroll = carousel.slidesToScroll;
    customCarouselSettings.infinite = checkForParks()
      ? carousel.infiniteScroll
      : props.thumbnail && mediaItems.length < 5;
    customCarouselSettings.centerPadding = '0';
    // for Adaptive height
    // if(checkTenant(UIConfig.tenants.ya)){
    //   customCarouselSettings.adaptiveHeight = true;
    // }
    customCarouselSettings.arrows = mediaItems && mediaItems.length > 1 ? carousel.arrows : false;
    customCarouselSettings.slidesToShow = slidesToShow;
    customCarouselSettings.initialSlide = !props.thumbnail ? props.currentSlide : thumbnailSlide();
    if (!checkTenant(UIConfig.iamMapping.etihadarena)) {
      customCarouselSettings.afterChange = afterChange;
    } else {
      customCarouselSettings.beforeChange = (cur, next) => {
        setTimeout(() => {
          playActiveVid();
        }, 0);
      };
    }
    customCarouselSettings.centerMode = !props.thumbnail;
    customCarouselSettings.rtl = carousel.rtl || false;
    customCarouselSettings.dots = slidesToShow >= mediaItems.length ? false : customCarouselSettings.dots;
    //check if show items are greater than the actual ones
    customCarouselSettings.arrows = slidesToShow >= mediaItems.length ? false : customCarouselSettings.arrows;
    if (variant.includes('hero-carousel')) {
      customCarouselSettings.infinite = checkForParks() ? carousel.infiniteScroll : mediaItems.length < 1;
      customCarouselSettings.draggable = mediaItems.length < 1;
      customCarouselSettings.swipe = mediaItems.length > 1;
    }
    if (variant.includes('yi-tabs-with-slider')) {
      customCarouselSettings.centerPadding = props.data.carousel.centerPadding;
    }
    if (variant.includes('hero-carousel-yi')) {
      customCarouselSettings.centerPadding = '0px';
    }
    if (
      variant.indexOf('v-media-gallery-full-bleed') > -1 &&
      props.className &&
      props.className.indexOf('overlay') > -1
    ) {
      customCarouselSettings.centerPadding = '0';
      if (props.className && props.className.indexOf('overlay-thumbnail') > -1) {
        customCarouselSettings.infinite = checkForParks() ? carousel.infiniteScroll : mediaItems.length > 7;
        customCarouselSettings.arrows = mediaItems.length > 7;
        customCarouselSettings.slidesToShow = 7;
      }
    } else if (variant.indexOf('v-media-gallery-full-bleed') > -1) {
      customCarouselSettings.centerPadding = mobileView && !isOverlay && mediaItems.length > 1 ? '10px' : '218px';
      if (variant.indexOf('ya-two-image-carousal') > -1) {
        customCarouselSettings.centerPadding = mobileView && !isOverlay && mediaItems.length > 1 ? '10px' : '95px';
      }
    }
    settings.current = Object.assign(
      {},
      !isSmartHeroCarousel && deepCloneObject(carouselDefaultSettings),
      deepCloneObject(customCarouselSettings),
    );
    if (isSmartHeroCarousel) {
      settings.current = {
        ...settings.current,
        infinite: checkForParks() ? carousel.infiniteScroll : false,
        ...carousel,
        edgeFriction: 0,
        beforeChange: (cur, next) => {
          if (checkTabbingEnable()) {
            const slideEnabled = document.querySelectorAll('.smart-hero-carousel-view .slick-slider .slick-arrow');
            if (slideEnabled?.length) {
              slideEnabled.forEach((slide) => slide.removeAttribute('disabled'));
            }
          }
          makeAllSlidesVisible();
          setTimeout(() => {
            playActiveVid();
          }, 0);
        },
        afterChange: (cur) => {
          if (checkTabbingEnable()) {
            const slideNext = document.querySelectorAll(
              `.smart-hero-carousel-view .slick-slide:not([data-index="${cur}"])`,
            );
            makeBtnDisable();
            slideNext?.length &&
              slideNext.forEach((slide) => {
                slide.style.visibility = 'hidden';
              });
          }
        },
      };
    }
    if (checkTenant(UIConfig.iamMapping.etihadarena)) {
      settings.current = {
        ...settings.current,
        autoplaySpeed: carousel.autoplaySpeed,
        lazyLoad: false,
        pauseOnHover: false,
      };
    }
  };

  const afterChange = (index) => {
    currentSlide = index;
    !props.thumbnail && props.onSlideChange(index);
  };
  /*
   * initialThumbnail function to return slide number for thumbnail slider initially
   */
  const thumbnailSlide = () => {
    const { carousel } = data;
    let thumbnailSlideToShow =
      currentTenant === UIConfig.YIB2C && isThumbnailHalfSlideShow
        ? carousel.thumbnailSlideToShow || thumbnailSlideNumberSetting.initialThumbnailSlide
        : carousel.thumbnailSlideToShow || thumbnailSlideNumberSetting.totalThumbnailSlide;

    if (props.currentSlide < thumbnailSlideToShow) {
      return 0;
    } else {
      return props.currentSlide - thumbnailSlideToShow + 1;
    }
  };
  /*
   * updateSlide function to update the slider with current slide
   */
  const updateSlide = () => {
    if (props.thumbnail) {
      return thumbnailSlide();
    } else {
      return props.currentSlide;
    }
  };
  /*
   * Open Live Streaming Popup
   */
  const openLiveStreamingPopup = (data) => {
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      customClass: 'live-streaming',
      dataToAppend: <LiveStreaming {...data} />,
    });
  };

  const videoClickCallback = () => {
    if (!showControls) {
      setShowControls(true);
    }
  };

  const checkParks = () => {
    return checkIfParks() || checkTenant(UIConfig.iamMapping.clymb) || checkTenant(UIConfig.iamMapping.ppad);
  };

  /**
   * showMediaCarouselItems   function to display MediaCarousel Items based on the selected viewtype selected
   * and device
   * @param {[data]} data object of the element
   * @return {[Object]} Return a render function which conatins the JSX of the component.
   */
  const showMediaCarouselItems = () => {
    const { aspectRatio, mediaItems, textAlignment, variant, carousel } = data;
    const isUpdateSlide = props.overlay || props.isUpdateSlide;
    settings.current.slideUpdate = isUpdateSlide ? updateSlide() : null;
    const params = { videoStatus: props.videoStatus, height: props.height };
    if (aspectRatio) {
      const { width, height } = aspectRatioCalculator(aspectRatio);
      params.height = mobileView ? '250px' : `${height}px`;
      params.width = mobileView ? '100%' : `${width}px`;
    }
    const videoData = {
      videoStatus: params.videoStatus,
      height: params.height,
      isSwipable: true,
    };
    const isImageClickable = variant.indexOf('v-carousel-nocta') !== -1;
    const mainObj = getMainObject();
    const contrastValue = mainObj && mainObj.applyTextShadow;
    const className = classNames(
      'v-carousel-view-container c-media-carousel-view-container clearfix',
      props.className,
      { 'no-transform-track': props.thumbnail && data.mediaItems.length < 5 },
      textAlignment ? `text-align-${textAlignment}` : '',
      aspectRatio ? `asr-${aspectRatio.replace(':', '-')}` : ' ',
    );
    const arrayIndexes = Array(data.mediaItems.length)
      .fill()
      .map((ele, index) => index)
      .reverse();
    let direction = '';
    const tenantId = getLoggedInUser().tenantID || (mainObj && mainObj.tenantID);
    if (tenantId && tenantId.toLowerCase() === UIConfig.ymcB2CTenant && canUseDOM() && isArabicMode()) {
      direction = 'ltr';
    }
    return (
      <div className={`w--middle w--middle-mc ${!domLoaded ? 'dom-pending' : ''}`}>
        <div className="w--content w--content-mc">
          <div
            className={`${className} ${
              carousel.autoplay && checkTenant(UIConfig.iamMapping.etihadarena) ? 'carousel-auto-play' : ''
            }`}
            dir={direction}
            ref={carouselRef}
          >
            <Carousel data={settings.current}>
              {mediaItems.map((item, index) => (
                <div
                  key={index}
                  className={`c-media-carousel--item-content ${
                    props.thumbnail && props.currentSlide === index ? 'highlight-thumb' : ''
                  }`}
                >
                  {contrastValue && (
                    <DynamicContent
                      tagName="style"
                      innerHtml={`
                      ${
                        props.addContrast
                          ? `.c-media-carousel-wrapper .c-media-carousel .slick-slide.slick-active .media-carousel-item picture:after {
                        opacity: ${props.addContrast}}`
                          : ''
                      }
                        @media only screen and (min-width: 1024px) {
                          .c-media-carousel-wrapper.hero-carousel .w--content .media-carousel-item .media-desc-container .media-carousel-item--title {
                            text-shadow: 0 2px 4px rgba(0,0,0,.4);
                          }
                          .c-media-carousel-wrapper.hero-carousel .w--content .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
                            text-shadow: 0 2px 4px rgba(0,0,0,.4);
                          }
                        }
                      `}
                    />
                  )}
                  {item?.mediaType == 'video' ? (
                    <Helmet>
                      {item?.videoInfo?.videoUrl && (
                        <link rel="preload" as="video" href={item?.videoInfo?.videoUrl} type="video/mp4" />
                      )}
                    </Helmet>
                  ) : (
                    <Helmet>
                      {item?.imageInfo?.mobileImage?.src && index === 0 && (
                        <link
                          rel="preload"
                          as="image"
                          href={item?.imageInfo?.mobileImage?.src}
                          imagesrcset={item?.imageInfo?.mobileImage?.src}
                          imagesizes="50vw"
                        />
                      )}
                    </Helmet>
                  )}

                  <MediaCarouselItem
                    {...item}
                    changeToH1={data.changeContentTitleToH1}
                    onMediaClick={props.onMediaClick}
                    mediaItems={data.mediaItems}
                    heroBelowImage={props.heroBelowImage}
                    thirdPartyWidget={data.thirdPartyWidget}
                    overlay={isOverlay}
                    thumbnail={props.thumbnail || false}
                    videoData={videoData}
                    index={index}
                    variant={variant}
                    activeItem={
                      data.carousel.rtl && !isFirstLoad.current
                        ? arrayIndexes[currentSlide] === index
                        : currentSlide === index
                    }
                    isImageClickable={isImageClickable}
                    registerLabel={data.registerLabel}
                    openLiveStream={
                      item.liveStreaming && item.liveStreaming.enableLiveStream ? openLiveStreamingPopup : null
                    }
                    fromTabsWithSlider={fromTabsWithSlider}
                    isSmartHeroCarousel={isSmartHeroCarousel}
                    showControls={showControls}
                    {...(checkParks() && { setShowControls: () => videoClickCallback() })}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    );
  };
  useBeforeFirstRender(updateSettings);
  try {
    const mediaClasses = classNames('component c-media-carousel v-carousel-all-view v-media-carousel-all-view', {
      'show-content': isShowContent,
    });
    return (
      <div className={mediaClasses} data-c-name="media-carousel" data-c-render="universal">
        {data.mediaItems && data.mediaItems.length > 0 && showMediaCarouselItems()}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'MediaCarousel', data.variant);
  }
};
export default MediaCarousel;
/**
 * Used to define the proptypes that will be received by the component.
 */
MediaCarousel.propTypes = {
  onSlideChange: PropTypes.func,
  data: PropTypes.shape({
    mediaItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    carousel: PropTypes.object,
    variant: PropTypes.string,
    componentTitle: PropTypes.string,
    componentDescription: PropTypes.string,
    textAlignment: PropTypes.string,
    aspectRatio: PropTypes.string,
    thirdPartyWidget: PropTypes.array,
    services: PropTypes.object,
    addContrast: PropTypes.string,
    registerLabel: PropTypes.string,
  }),
  onMediaClick: PropTypes.func,
  currentSlide: PropTypes.number,
  isUpdateSlide: PropTypes.bool,
  addContrast: PropTypes.string,
  overlay: PropTypes.bool,
  className: PropTypes.string,
  videoStatus: PropTypes.string,
  thumbnail: PropTypes.bool,
};
