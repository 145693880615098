import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import UIConfig from '../../../common/UIConfig';
import { DynamicContent, SvgSprite } from '../base';
import { handleEnterKey } from '../../../common/utility';

import './google-maps-direction.scss';

/**
 * GoogleMapsDirectionComponent Class creates the google map Direction area and add functionality to it's parent google component
 */

export default class GoogleMapsDirectionComponent extends React.Component {
  /**
   * Constructor of the class is defined which handles binding of the events and the props to the super class.
   */

  constructor(props) {
    super(props);
    this.changeMode = this.changeMode.bind(this);
    this.toggleDirectionPanel = this.toggleDirectionPanel.bind(this);
    this.changeView = this.changeView.bind(this);
    this.onStartLocationChange = this.onStartLocationChange.bind(this);
    this.onEndLocationChange = this.onEndLocationChange.bind(this);
    this.defaultLocation = this.props.defaultLocation;
    this.state = {
      mode: this.getDefaultMode(),
      startLocation: null,
      endLocation: null,
      collapsed: true,
      routeResponse: null,
      hidePanel: true,
      loading: false,
      startLocationText: '',
      endLocationText: '',
      selectedEndPoint: '',
      showError: false,
    };
    this.directionLoaded = false;
    this.noUserLocation = true;
    this.map = this.props.map;
    this.yasIsland = this.props.yasIsland;
    this.markers = this.props.markers;
    this.centerOfMap = this.props.centerOfMap;
    this.isYAGettingHere = this.props.data.variant === 'yaGettingHere';
  }

  componentDidMount() {
    this.setState(
      {
        endLocation: new window.google.maps.LatLng(this.defaultLocation.lat, this.defaultLocation.lng),
        endLocationText: this.defaultLocation.name,
        selectedEndPoint: this.defaultLocation.name.split(' ').join(''),
      },
      () => {
        this.addAutoCompleteListner();
        this.geocoder = new window.google.maps.Geocoder();
        this.startIcon = new window.google.maps.Marker({
          icon: this.props.data.directions.startIcon || UIConfig.googleMap.startIcon,
        });
        this.endIcon = new window.google.maps.Marker({
          icon: this.props.data.directions.endIcon || UIConfig.googleMap.endIcon,
        });

        this.directionsService = new window.google.maps.DirectionsService();
        let polylineOptions = {};
        if (this.isYAGettingHere) {
          polylineOptions = {
            geodesic: true,
            strokeOpacity: 0,
            strokeWeight: 7,
            icons: [
              {
                icon: {
                  path: 'M 0,-1 0,1',
                  strokeOpacity: 1,
                  scale: 4,
                },
                offset: '0',
                repeat: '20px',
              },
            ],
          };
        }

        this.directionsDisplay = new window.google.maps.DirectionsRenderer({
          map: this.map,
          polylineOptions: {
            strokeColor: this.props.data.directions.fillColor,
            ...polylineOptions,
          },
          suppressMarkers: true,
        });
      },
    );
  }

  /**
   * This method will attach the autocomplete functionality in two inputs
   */
  addAutoCompleteListner() {
    //Get the REF of start and end Input Element
    const searchBoxStart = new window.google.maps.places.Autocomplete(this.startPoint),
      searchBoxEnd = new window.google.maps.places.Autocomplete(this.endPoint);

    //Add listener to start
    window.google.maps.event.addListener(searchBoxStart, 'place_changed', () => {
      let { geometry, formatted_address: startLocationText, name } = searchBoxStart.getPlace();

      if (!geometry) {
        return;
      }

      if (this.isYAGettingHere) {
        startLocationText = startLocationText.includes(name) ? startLocationText : `${name} ${startLocationText}`;
        this.noUserLocation = true;
      }

      //Update state with new startLocation
      this.setState({ startLocation: geometry.location, startLocationText }, () => this.calculateAndDisplayRoute());
    });

    //Add listener to End
    window.google.maps.event.addListener(searchBoxEnd, 'place_changed', () => {
      let { geometry, formatted_address: endLocationText } = searchBoxEnd.getPlace();
      if (!geometry) {
        return;
      }
      //Update state with new endLocation
      this.setState({ endLocation: geometry.location, endLocationText }, () => this.calculateAndDisplayRoute());
    });
  }

  /**
   * This method will be used to get the Path between start and end location
   */
  calculateAndDisplayRoute() {
    if (this.state.startLocation && this.state.endLocation) {
      //Get the route from Google API
      this.setState({ loading: true, showError: false }, () => {
        this.directionsService.route(
          {
            origin: this.state.startLocation,
            destination: this.state.endLocation,
            travelMode: window.google.maps.TravelMode[this.state.mode].toUpperCase(),
          },
          (response, status) => {
            this.directionLoaded = true;
            if (status === 'OK') {
              this.setDirections(response);
            } else {
              this.setState(
                {
                  routeResponse: null,
                  loading: false,
                  showError: true,
                },
                () => {
                  this.hideDirectionPath();
                  this.hideShowMarkers(null);
                  this.yasIsland && this.yasIsland.setMap(null);
                  this.props.getDirectionURL('');
                },
              );
            }
          },
        );
      });
    } else {
      this.hideDirectionPath();
    }
  }

  /*
   * Hide the directions and reset the markers if no route found
   */
  hideDirectionPath() {
    this.directionsDisplay.setMap(null);
    this.directionsDisplay.setPanel(null);
    this.resetDirectionsIcons();
  }

  /*
   * This method is a callback, whenever the direction API has returned a new route
   */
  setDirections(response) {
    //Change the state and adjust the map accodingly
    this.setState({ showError: false, routeResponse: response, loading: false }, () => {
      this.directionsDisplay.setDirections(response);
      this.directionsDisplay.setPanel(this.panel);
      this.directionsDisplay.setMap(this.map);
      this.setDirectionsIcons(response);
      if (this.isYAGettingHere) {
        const origin = new window.google.maps.LatLng(
          response.request.origin.location.lat(),
          response.request.origin.location.lng(),
        );
        const destination = new window.google.maps.LatLng(
          response.request.destination.location.lat(),
          response.request.destination.location.lng(),
        );
        const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=${response.request.travelMode}`;
        this.props.getDirectionURL(url);
      }

      if (!this.state.collapsed) {
        this.hideShowMarkers(null);
        this.yasIsland.setMap(null);
      }
    });
  }

  /*
   * This method will change the icons of start and end location
   */
  setDirectionsIcons(response) {
    this.startIcon.setPosition(response.routes[0].legs[0].start_location);
    this.startIcon.setMap(this.map);
    this.endIcon.setPosition(response.routes[0].legs[0].end_location);
    this.endIcon.setMap(this.map);
  }

  /*
   * This method will remove start end location markers from map
   */
  resetDirectionsIcons() {
    this.startIcon.setMap(null);
    this.endIcon.setMap(null);
  }

  /**
   * This method will be used to get the user location and will set the location to start point
   */
  getUserLocation(lat, lng, callback) {
    //Send query to Google geocoder to get the address of user location
    const location = { lat, lng };
    this.geocoder.geocode({ location: location }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.setState(
          {
            startLocation: results[0].geometry.location,
            startLocationText: results[0].formatted_address,
          },
          () => callback(),
        );
      }
    });
  }

  /**
   * This method will retrieve the default transport mode from JSON
   */
  getDefaultMode() {
    const defaultMode = this.props.data.directions.modes.filter((mode) => mode.default);
    return defaultMode[0].type;
  }

  /**
   * This method will retrieve the default location from locations array, that are plotted in the map
   */
  getDefaultLocation() {
    let location;
    this.props.data.googleMap.locations.forEach((loc) => {
      if (loc.default) {
        location = loc;
      }
    });

    return location;
  }

  /**
   * This method will show hide the direction from
   */
  changeView() {
    this.setState(
      {
        collapsed: !this.state.collapsed,
      },
      () => this.resetMap(this.state.collapsed),
    );
  }

  /**
   * This method will either show all the markers or remove all the markers from the map
   */
  hideShowMarkers(map) {
    this.markers.forEach((marker) => {
      marker.setMap(map);
    });
  }

  /**
   * This method will toggle the map markers and search panel
   */
  resetMap = (collapsed) => {
    if (this.noUserLocation) {
      if (this.isYAGettingHere) {
        this.setState({ loading: true });
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getUserLocation(position.coords.latitude, position.coords.longitude, () => {
            this.toggleSearchPanel(collapsed, this.noUserLocation);
            this.hideShowMarkers(null);
            if (!this.isYAGettingHere) {
              this.noUserLocation = false;
            } else {
              this.setState({ loading: false });
            }
          });
        },
        () => (this.noUserLocation = false),
      );
    } else {
      this.toggleSearchPanel(collapsed, this.noUserLocation);
    }
  };

  /**
   * This method is further check the status of search box and map markers
   */
  toggleSearchPanel(collapsed, userLocation) {
    if (userLocation) {
      this.calculateAndDisplayRoute();
    }

    if (collapsed) {
      if (this.directionLoaded) {
        this.hideShowMarkers(this.map);
      }
      this.setMaptoInitialState();
    } else {
      if (this.directionLoaded) {
        this.yasIsland.setMap(null);
        this.hideShowMarkers(null);
      }
      //Set the directions again if we have correct response in our state variable
      if (this.state.routeResponse) {
        this.startIcon.setMap(this.map);
        this.endIcon.setMap(this.map);
        this.directionsDisplay.setMap(this.map);
      }
    }
  }

  /*
   * After getting the response of PATH, this method will toggle the area containing the directions text
   */
  toggleDirectionPanel() {
    this.setState(
      {
        hidePanel: !this.state.hidePanel,
      },
      () => {
        if (!this.state.hidePanel) {
          this.directionsDisplay.setPanel(this.panel);
        } else {
          this.directionsDisplay.setPanel(null);
        }
      },
    );
  }

  /*
   * This method will remove the direction path and show the markers and Polygon
   */
  setMaptoInitialState() {
    if (!this.isYAGettingHere) {
      this.yasIsland.setMap(this.map);
    }
    this.directionsDisplay.setMap(null);
    this.map.setZoom(Number(this.props.data.googleMap.zoom));
    this.map.setCenter(this.centerOfMap);
    this.resetDirectionsIcons();
  }

  /**
   * This method will change the mode of transport
   */
  changeMode(mode) {
    this.setState({ mode: mode, hidePanel: this.state.hidePanel !== mode }, () => this.calculateAndDisplayRoute());
  }

  /**
   * This method will change the value of Controlled input element of start location
   */
  onStartLocationChange(e) {
    const val = e.target.value;
    this.setState(
      {
        startLocationText: val,
        startLocation: val ? this.state.startLocation : null,
        routeResponse: val ? this.state.routeResponse : false,
      },
      () => {
        if (!this.state.startLocationText) {
          this.calculateAndDisplayRoute();
        }
      },
    );
  }

  /**
   * This method will change the value of Controlled input element of end location
   */
  onEndLocationChange(e) {
    const val = e.target.value;
    this.setState(
      {
        endLocationText: val,
        endLocation: val ? this.state.endLocation : null,
        routeResponse: val ? this.state.routeResponse : false,
      },
      () => {
        if (!this.state.endLocationText) {
          this.calculateAndDisplayRoute();
        }
      },
    );
  }

  isNoDirection() {
    return this.state.startLocation && this.state.endLocation && this.state.showError && !this.state.routeResponse;
  }

  onParkingSpotSelection = (location) => {
    this.setState(
      {
        endLocation: new window.google.maps.LatLng(location.lat, location.lng),
        endLocationText: location.name,
        selectedEndPoint: location.name.split(' ').join(''),
      },
      () => this.calculateAndDisplayRoute(),
    );
  };

  /**
   * render  Used to render the JSX of the component
   */
  render() {
    const { directions, googleMap } = this.props.data;
    const { startLabel, endLabel } = directions;
    const { selectedEndPoint } = this.state;

    return (
      <div className="w--content">
        <div className={`c-google-maps-component-directions ${!this.state.collapsed && 'opened'}`}>
          {this.state.loading && <div className="loading"></div>}
          {!this.isYAGettingHere && (
            <button className="open-collapse" onClick={this.changeView}>
              {!this.state.collapsed ? <SvgSprite id="icn-close" /> : <SvgSprite id="icn-direction" />}
            </button>
          )}

          <div className={`opened-view ${!this.isYAGettingHere && this.state.collapsed && 'hide'}`}>
            <ul>
              {this.props.data.directions.modes.map(
                (mode) =>
                  mode.active && (
                    <li
                      key={mode.type}
                      className={`icon ${this.state.mode === mode.type ? 'defaultActive' : ''}`}
                      onClick={() => this.changeMode(mode.type)}
                    >
                      <span>
                        <SvgSprite id={`icn-${mode.type.toLowerCase()}`} />
                      </span>
                    </li>
                  ),
              )}
            </ul>
            <div className="c-google-maps-component-inputs">
              <div className="c-google-maps-component--input from">
                {this.isYAGettingHere && (
                  <div className="location-icon">
                    <DynamicContent
                      tagName="label"
                      innerHtml={startLabel}
                      attrs={{ className: 'input-heading', htmlFor: 'startPoint' }}
                    />
                    <span className="icon" onClick={this.resetMap}></span>
                  </div>
                )}
                <input
                  autoComplete="false"
                  type="text"
                  onChange={this.onStartLocationChange}
                  value={this.state.startLocationText}
                  placeholder={this.props.data.directions.startLabel}
                  ref={(startPoint) => (this.startPoint = startPoint)}
                  id="startPoint"
                  name="startPoint"
                  aria-label={startLabel}
                />
              </div>
              <div className="c-google-maps-component--input to">
                {this.isYAGettingHere && (
                  <DynamicContent tagName="p" innerHtml={endLabel} attrs={{ className: 'input-heading' }} />
                )}
                {!this.isYAGettingHere ? (
                  <input
                    autoComplete="false"
                    type="text"
                    onChange={this.onEndLocationChange}
                    value={this.state.endLocationText}
                    placeholder={this.props.data.directions.endLabel}
                    ref={(endPoint) => (this.endPoint = endPoint)}
                  />
                ) : (
                  <div className="destination-points">
                    {googleMap.locations.map((location, index) => (
                      <Text
                        tag="label"
                        key={index}
                        role="label"
                        className={location.name.split(' ').join('') === selectedEndPoint ? 'selected' : ''}
                        onClick={() => this.onParkingSpotSelection(location)}
                        onKeyDown={(e) => handleEnterKey(e, () => this.onParkingSpotSelection(location))}
                        ref={(endPoint) => (this.endPoint = endPoint)}
                        tabIndex="0"
                        field={{ value: location.name, editable: location.name }}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="directions-panel">
              {this.state.routeResponse && !this.isYAGettingHere && (
                <DirectionSummary
                  hidePanel={this.state.hidePanel}
                  routes={this.state.routeResponse}
                  mode={this.state.mode}
                  showdirections={this.toggleDirectionPanel}
                  showHideText={this.props.data.directions.togglePanel}
                />
              )}

              {this.isNoDirection() && <h4 className="errorText">{this.props.data.directions.errorText}</h4>}

              {!this.state.hidePanel && !this.isYAGettingHere && (
                <div className="directions-panel-container" ref={(panel) => (this.panel = panel)}></div>
              )}
            </div>
          </div>
          <div className={`collapsed-view ${!this.state.collapsed && 'hide'}`} onClick={this.changeView}>
            <div>
              <span className="icn-hamburger">
                <SvgSprite id="icn-hamburger" />
              </span>
              <Text
                tag="span"
                className="default-location"
                field={{ value: this.defaultLocation.name, editable: this.defaultLocation.name }}
              />
              <span className="icn-search">
                <SvgSprite id="icn-search" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */

GoogleMapsDirectionComponent.propTypes = {
  data: PropTypes.object,
  yasIsland: PropTypes.object,
  markers: PropTypes.array,
  centerOfMap: PropTypes.object,
};

/**
 * This component will create the directions summary whenever new path is receieved
 */
const DirectionSummary = ({ routes, showdirections, mode, hidePanel, showHideText }) => {
  if (routes) {
    return (
      <div className="directions-row">
        <div className="directions-row-inner">
          <div className="directions-row-inner-icon">
            <SvgSprite id={`icn-${mode.toLowerCase()}`} />
          </div>
          <div className="directions-row-inner-duration">{routes.routes[0].legs[0].duration.text}</div>
          <div className="directions-row-inner-distance">{routes.routes[0].legs[0].distance.text}</div>
        </div>
        <button className="directions-row-cta" onClick={showdirections}>
          {hidePanel ? showHideText.showText : showHideText.hideText}
        </button>
      </div>
    );
  }
};
