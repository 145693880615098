import UIConfig from '../UIConfig';
import '../pubsub';
import { Environments } from '../constants';
import { getCurrentLanguage } from './datetime';

export const canUseDOM = () => {
  return typeof window !== 'undefined' && window.document && window.document.createElement;
};

export const getUserAgent = () => {
  if (canUseDOM() && window.localStorage && window.localStorage.deviceInfo) {
    return JSON.parse(window.localStorage.deviceInfo);
  } else {
    return null;
  }
};

/**
 * Refrsh Tabs with placeholder component
 */
export const refreshTabs = () => {
  window.PubSub.publish(UIConfig.events.REFRESH_TABS);
};

export const getDocumentReferrer = () => {
  return canUseDOM() ? document.referrer : '';
};

export const getEnvVariablesClient = () => {
  const envElm = canUseDOM() && document.getElementById('__ENV_VARIABLES__');
  const envVariablesValues = canUseDOM() && envElm && envElm.innerHTML.trim();

  return JSON.parse(envVariablesValues) !== null && JSON.parse(envVariablesValues);
};

export const isUniformEnabled = () => {
  const envVariables = canUseDOM() && getEnvVariablesClient();
  const isUniformEnabled = envVariables.isUniformEnabled === 'true';

  return isUniformEnabled;
};

export const isEnvDevelopment = () => {
  return canUseDOM() && getEnvVariablesClient()
    ? getEnvVariablesClient().nodeEnv === Environments.Development
    : (process.env.NODE_ENV_PROXY || process.env.NODE_ENV) === Environments.Development;
};

/**
 * create a focus cycle while press the tab and shift tab inside addThis overlay
 * {@param} {Object} HTML element selector for first focusable element
 * {@param} {Object} HTML element selector for last focusable element
 */
export const createFocusCycle = (closeElm, last) => {
  closeElm &&
    closeElm.addEventListener('keydown', function(e) {
      if (e.keyCode === 9 && e.shiftKey) {
        last.focus();
        e.preventDefault();
      }
    });
  last &&
    last.addEventListener('keydown', function(e) {
      if (e.keyCode === 9 && !e.shiftKey) {
        closeElm.focus();
        e.preventDefault();
      }
    });
};

/**
 * Desc: Add focus on input field of social share overlay and move focus inside overlay
 *       social overlay open and close callback used in the function
 */
export const addThisSocialOverlayFocus = () => {
  window.addthis &&
    window.addthis.addEventListener('addthis.menu.open', () => {
      let previouslyFocused = document.activeElement;
      setTimeout(() => {
        if (canUseDOM()) {
          const firstElm = document.querySelector('.at-expanded-menu-search-input');
          const closeElm = document.querySelector('.at-expanded-menu-close');
          const lastElm = document.querySelector('.at-branding-logo');
          firstElm.focus();
          createFocusCycle(closeElm, lastElm);
        }
      }, 1000);
      // close addThis overlay
      window.addthis &&
        window.addthis.addEventListener('addthis.menu.close', () => {
          previouslyFocused.focus();
        });
    });
};

export const focusOnFirstElm = () => {
  // focus inside the overlay
  let firstElm = document.querySelector('#ara-fName'),
    closeElm = document.querySelector('#closeDialog'),
    lastElm = document.querySelector('#StartChatM .auth-dialog');
  firstElm.focus();
  createFocusCycle(closeElm, lastElm);
};

export const updateViewportMetaTagContent = () => {
  // Fixed Zoom Issue in IOS devices Webview
  const viewportMetaEl = document.querySelector('meta[name=viewport]');
  viewportMetaEl &&
    viewportMetaEl.setAttribute('content', viewportMetaEl.content.replace('user-scalable=1', 'user-scalable=0'));
};

const closeChatBox = (e) => {
  let closeElm = document.querySelector('#closeDialog');
  if (e.keyCode === 13) {
    closeElm.removeEventListener('keydown', closeChatBox);
    closeElm.click();
    document.querySelector('#arabotWebchatStartChat').focus();
    e.preventDefault();
    e.stopPropagation();
  }
};

const closeChatBoxEventBind = () => {
  let closeElm = document.querySelector('#closeDialog');
  closeElm.setAttribute('tabindex', '0');
  closeElm.addEventListener('keydown', closeChatBox);
};

//add chat bot element in tab order and launch it on keypress
export const attachBotEvent = () => {
  let chatBotElement = document.querySelector('#arabotWebchatStartChat');
  if (chatBotElement) {
    chatBotElement.setAttribute('tabindex', '0');
    chatBotElement.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        chatBotElement.click();
        setTimeout(() => {
          focusOnFirstElm();
          closeChatBoxEventBind();
        }, 1000);
      }
    });
  }
};

export const isApplePayAvailable = () => {
  return canUseDOM() && window.ApplePaySession && window.ApplePaySession.canMakePayments();
};

export const attachPageShowEvent = () => {
  window.onpageshow = (event) => {
    if (event.persisted) {
      window.location.reload();
    }
  };
};

/**
 * pushState to change url
 * @param {*} state
 * @param {*} title
 * @param {*} url
 */
export const pushState = (state, title = 'page', url) => {
  window.history.pushState(state, title, url);
};

/**
 * onPopState to register a callback when onpopstate is fired
 * @param {*} cb
 */
export const onPopState = (cb) => {
  if (canUseDOM()) {
    window.onpopstate = cb;
  }
};

//redirect to previous page
export const backToPrevious = (url) => {
  if (canUseDOM()) {
    if (url) {
      window.location.href = url;
    } else {
      window.history.back();
    }
  }
};

/**
 * Redirects to given path
 * @param {String} path Path of the location
 */
export const redirectTo = (path) => {
  if (canUseDOM()) {
    window.location = path;
  }
};

export const getBaseURL = () => {
  if (canUseDOM()) {
    let baseUrl = window.location.hostname;
    if (baseUrl !== 'localhost') {
      baseUrl = `.${baseUrl.replace('www.', '')}`;
      //.replace('nfr.', '') // Commenting this code for Signup duplicate UserProfile cookie.
      //.replace('uat.', '')}`;// Commenting this code for Signup duplicate UserProfile cookie.
    }

    return baseUrl;
  }
  return '';
};

export const getOrigin = () => {
  if (canUseDOM()) {
    return window.location.origin;
  }
  return '';
};

export const setLocalStorage = (key, value) => {
  if (canUseDOM() && window.localStorage) {
    window.localStorage.setItem(key, value);
  }
};

export const setSessionStorage = (key, value) => {
  if (canUseDOM() && window.sessionStorage) {
    window.sessionStorage.setItem(key, value);
  }
};

export const getSessionStorage = (key) => {
  if (canUseDOM() && window.sessionStorage) {
    return window.sessionStorage.getItem(key);
  }
};

/**
 * set atob and btoa to convert base64 to hexa and vice versa on window object
 */
export const setWindowFunctions = () => {
  if (!window.atob) {
    var tableStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/:;,';
    var table = tableStr.split('');

    window.atob = function(base64) {
      if (/(=[^=]+|={3,})$/.test(base64)) throw new Error('String contains an invalid character');
      base64 = base64.replace(/=/g, '');
      var n = base64.length & 3;
      if (n === 1) throw new Error('String contains an invalid character');
      for (var i = 0, j = 0, len = base64.length / 4, bin = []; i < len; ++i) {
        var a = tableStr.indexOf(base64[j++] || 'A'),
          b = tableStr.indexOf(base64[j++] || 'A');
        var c = tableStr.indexOf(base64[j++] || 'A'),
          d = tableStr.indexOf(base64[j++] || 'A');
        if ((a | b | c | d) < 0) throw new Error('String contains an invalid character');
        bin[bin.length] = ((a << 2) | (b >> 4)) & 255;
        bin[bin.length] = ((b << 4) | (c >> 2)) & 255;
        bin[bin.length] = ((c << 6) | d) & 255;
      }
      return String.fromCharCode.apply(null, bin).substr(0, bin.length + n - 4);
    };

    window.btoa = function(bin) {
      for (var i = 0, j = 0, len = bin.length / 3, base64 = []; i < len; ++i) {
        var a = bin.charCodeAt(j++),
          b = bin.charCodeAt(j++),
          c = bin.charCodeAt(j++);
        if ((a | b | c) > 255) throw new Error('String contains an invalid character');
        base64[base64.length] =
          table[a >> 2] +
          table[((a << 4) & 63) | (b >> 4)] +
          (isNaN(b) ? '=' : table[((b << 2) & 63) | (c >> 6)]) +
          (isNaN(b + c) ? '=' : table[c & 63]);
      }
      return base64.join('');
    };
  }
};

export const isHidden = (el) => {
  var style = window.getComputedStyle(el);
  return style.display === 'none' || style.visibility === 'hidden';
};

export const isIntersectionObserverSupported = () => {
  return typeof window !== 'undefined' && 'IntersectionObserver' in window;
};

export const currentScrollPosition = () => {
  return {
    scrollX: window.scrollX || window.pageXOffset,
    scrollY: window.scrollY || window.pageYOffset,
  };
};

export const checkArrowKeys = (event) => {
  const arrs = ['left', 'up', 'right', 'down'];
  const key = event.keyCode;
  if (key && key > 36 && key < 41) {
    return arrs.includes(arrs[key - 37]);
  }
};

/**
 *
  Get Hash Array Splited based on "#"
 */
export const getUrlHashArr = () => (canUseDOM() && window.location.hash ? window.location.hash.split('#') : []);

export const isArabicMode = () => {
  return document.querySelector('html').lang.indexOf('ar') !== -1;
};

export const getLanguageWithoutLocal = () => {
  if (canUseDOM()) {
    let language = document.getElementsByTagName('html')[0].getAttribute('lang-code');
    if (!language) {
      language = getCurrentLanguage().split('-')[0];
    }
    return language;
  }
};

//Adding loading overlay
export const addLoaderOverlay = (el) => {
  if (canUseDOM()) {
    let parent = el ? el : document.querySelector(UIConfig.loader.defaultPreLoaderTarget);

    if (parent && (getComputedStyle(parent).position === 'static' || getComputedStyle(parent).position === '')) {
      parent.classList.add('loader-ele-relative');
    }
    let loader = document.createElement('div');
    loader.className = 'loading';
    loader.setAttribute('role', 'alert');
    loader.setAttribute('aria-live', 'assertive');
    loader.appendChild(document.createTextNode(UIConfig.loader.ariaLabel));
    parent && parent.appendChild(loader);
  }
};

//Removing loading overlay
export const removeLoaderOverlay = (el) => {
  if (canUseDOM()) {
    let parent = el ? el : document.querySelector(UIConfig.loader.defaultPreLoaderTarget);
    let loadingDiv = parent.querySelector('.loading');
    if (loadingDiv) {
      parent.classList.remove('loader-ele-relative');
    }

    //Remove all the loaders from page
    const loaders = document.querySelectorAll('.loading');
    if (loaders && loaders.length) {
      for (let i = 0; i < loaders.length; i++) {
        loaders[i].parentNode && loaders[i].parentNode.removeChild(loaders[i]);
      }
    }
  }
};

export const removeLoader = () => {
  let errorLoader = document.querySelector('.loader');
  errorLoader && errorLoader.remove();
};

export const hasHeaderComponent = () => {
  if (canUseDOM()) {
    return document.getElementsByClassName('c-header')[0];
  }
};

export const isHtmlVideoSupported = () => {
  if (canUseDOM()) return document.createElement('video').canPlayType ? true : false;
};

/**
 * addThisHandler function to set position of share icon button relative HeroPanel and header
 * @param  {undefined} undefined
 * @return {undefined} undefined
 */
export const addThisHandler = () => {
  let targetComponentHeight = 415;
  const header = document.querySelector('.c-header');
  let bodyHeight = document.getElementsByTagName('body')[0];
  const headerHeight = header ? header.clientHeight : 0;
  bodyHeight = parseFloat(bodyHeight ? bodyHeight.clientHeight : 0);

  if (document.getElementById('at-expanding-share-button')) {
    const addThis = document.getElementById('at-expanding-share-button').clientHeight;
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementById('at-expanding-share-button').style.position = 'absolute';
    document.getElementById('at-expanding-share-button').style.bottom = `${bodyHeight -
      (targetComponentHeight + headerHeight + addThis + 15)}px`;
    document.getElementById('at-expanding-share-button').style.visibility = 'visible';
  } else {
    const timer = setInterval(() => {
      if (document.getElementById('at-expanding-share-button')) {
        const addThis = document.getElementById('at-expanding-share-button').clientHeight;
        document.getElementsByTagName('body')[0].style.position = 'relative';
        document.getElementById('at-expanding-share-button').style.position = 'absolute';
        document.getElementById('at-expanding-share-button').style.bottom = `${bodyHeight -
          (targetComponentHeight + headerHeight + addThis + 15)}px`;
        document.getElementById('at-expanding-share-button').style.visibility = 'visible';
        clearInterval(timer);
      }
    }, 10);

    setTimeout(() => {
      clearInterval(timer);
    }, 10000);
  }
};

/**
 * Use this function to get the array of passed element, so that array methods will be
 * available on the elements array
 * @param {Object} HTML element selector
 * @return {Array} return the array of HTML element collection
 */
export const getArrayOfElement = (selector) => {
  return canUseDOM() && Array.prototype.slice.call(document.querySelectorAll(selector));
};

/**
 * get current language
 */
export const getLanguage = () => {
  const localElem = document.querySelector('.dropdown-item--selected');
  return localElem.getAttribute('data-value');
};

/**
 * navigate to Skip Link
 */
export const navigateToSkipLink = (isDelayed) => {
  const skipToContentElement = document.getElementById('skip-to-content').children[0];
  isDelayed
    ? setTimeout(function() {
        skipToContentElement.focus();
      }, 600)
    : skipToContentElement.focus();
};

export const isKeyboardNavigation = () => document.body.classList.contains('user-is-tabbing');

export const strip_html_tags = (str) => {
  if (str === null || str === '') return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, '');
};

export const decodeHtml = (html) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = strip_html_tags(html);
  return txt.value;
};
