/**
 * purchase-journey-wrapper-component.js
 * This file contains code for wrapper component of booking tabs and minicart component
 * @author      Vivek Gaur, Sapient - (vgaur4)
 * @license     Miral
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import UICONFIG from '../../../../common/UIConfig';
import CartActions from '../cart/cart-actions';
import {
  PerformanceService,
  ProductService,
  CurrencyConverterService,
  RecommendationService,
} from '../../../../common/services';
import PayfortUtility from '../../payment/payfort/payfort-utility';
import { CartModelProduct } from '../cart/model/cart-model-product';
import {
  getErrorMap,
  getLoggedInUser,
  getFallbackLanguage,
  createDeepCopy,
  getUserAgent,
  hasHeaderComponent,
  createMappedData,
  toTwoDecimalPlaces,
  canUseDOM,
  detectMobile,
  flipObjectKeyValues,
  detectViewPort,
  deepCopyArrayOfObjects,
  resolvePath,
  isLoggedInUser,
  getPartnerCodeData,
  getMembershipDetails,
  setLocalStorage,
  checkTenant as checkSwadTenant,
  getSessionStorage,
  momentTimezone,
  parseQueryString,
  checkIfParks,
} from '../../../../common/utility';
import BookingTabs from '../booking-tabs/booking-tabs-component';
import ProductWidgetOverlay from '../booking-overlay/product-widget-overlay';
import MiniCart from '../mini-cart';
import UpSell from '../upsell/upsell-component';
import ErrorSummary from '../../../presentation/base/error-summary/error-summary-component';
import Overlay from '../../../presentation/base/overlay/overlay-component';
import GTMData from '../gtm-data';
import FlashSaleComponent from '../flash-sale';
import UIConfig from '../../../../common/UIConfig';
import { CartModel } from '../cart/model/cart-model';
import RecommendationActions from '../recommendation-rules/recommendation-rules-component';
import TextWithCTA from '../../text-with-cta';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import YaAddOnsService from '../event-performance-list/yas-arena-add-ons-service';
import YaAvailabiltyService from '../event-performance-list/add-ons-availability-service';
import { yaBuyTicketAnalytics } from '../../../../common/analytics-events';
import { logComponentRenderingError } from '../../../../common/logger';
import { checkTenant } from '../../../../common/utility/tenantsUtils';
import TicketUpgrade from '../ticket-upgrade';
import classNames from 'classnames';

import * as helper from './helper';
import { checkParksTenants, isEmpty } from '../../../../common/utility/helpers';
class PurchaseJourney extends Component {
  constructor(props) {
    super(props);
    this.showMinicartCouponCode =
      props.data.miniCart &&
      props.data.miniCart.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart;
    this.recommendationTabName = UIConfig.b2c.purchaseJourney.recommendationTabCode;
    this.pastRecommendationTabName = UIConfig.b2c.purchaseJourney.pastPurchasedTabCode;
    this.isSeatedTypeJourney = props.data.isSeatedTypeJourney;
    this.isSwad = checkSwadTenant(UIConfig.iamMapping.swad);
    this.ifParks = checkParksTenants();
    this.isTicketUpgradeEnable = (props?.data?.ticketUpgrade?.enableTicketUpgrade || false) && checkParksTenants();
    this.isParks = checkIfParks();
    this.prductAddedToCart = [];
    this.isBookingPage = false;
    this.isYAEventListingPage = props.data.variant === 'ya-event-listing';
    this.isYaNonSeatedJourney =
      this.isYAEventListingPage && props.data.tabs && props.data.tabs.length && this.isNonSeatedEvent(props.data.tabs);

    this.cartActions = new CartActions({
      serviceUrl: props.data.services,
      moduleName: 'purchase-journey',
      tenantId: getLoggedInUser().tenantID,
      isSeatedTypeJourney: this.isSeatedTypeJourney,
      coveoMappingList: props.data.coveoMappingList,
      isYaNonSeatedJourney: this.isYaNonSeatedJourney,
    });
    this.selectedCurrency = '';
    this.productList = [];
    this.products = {};
    this.porductsNotfound = {};
    if (props.data && props.data.variant) {
      this.withoutPurchase = props.data.variant === 'timer-only' && !props.data.isTakeOverBanner;
    }
    this.coveoDefaultParams = !this.withoutPurchase
      ? {
          perPageResults: UICONFIG.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: this.props.data.coveoMappingList,
          serviceUrl: this.props.data.services.getCoveoProducts.url,
          fieldsToInclude: this.props.data.coveoMappingList && Object.values(this.props.data.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};
    this.applyCouponCallback = this.applyCouponCallback.bind(this);
    this.removeCouponCallback = this.removeCouponCallback.bind(this);
    this.cartLoaded = false;
    this.isMobile = detectMobile();
    this.CartActionObj = new CartActions(this.setCartDefaults(props));
    this.cartModelWithProduct = createDeepCopy(CartModelProduct);
    this.annualPassGridVariant = props.data.params?.annualPassWidget === 'true';
    this.miniCartFrmHeaderEnbl = props.isHeader && props.isMiniCart;
    this.state = {
      cartData: this.cartActions.getCustomCart(this.productList),
      errObj: '',
      groupQtyErr: '',
      purchaseWrapperErrors: '',
      convertedCurrency: null,
      bErrObj: null,
      cartEmpty: false,
      isRecommendationVisible: !this.withoutPurchase && this.props.data.tabs && this.isRecommendationEnabled(),
      recommendedDataRes: null,
      setRecommendedData: false,
      isAddOns: false,
      addOnsErrorProdArr: [],
      availability: {},
      addOnProducts: null,
      productWidgetOnly: {},
      isYARecommendationVisible: false,
      isRecommendationData: {},
      isPostPurchasedAddon: false,
    };

    //sorting the discount map as per days
    this.props.data.discountMap &&
      this.props.data.discountMap.sort(function(a, b) {
        return a.days - b.days;
      });
    this.tabs = {};
    if (this.props.data.tabs && this.props.data.tabs.length) {
      this.props.data.tabs.forEach((tab) => (this.tabs[tab.type] = { key: tab.coveoKey, value: tab.coveoValue }));
    }
    this.eventPublished = false;
    this.isMobileDevice = detectViewPort() === 'mobile';
    this.recommendedProducts = {};
    this.emptyCartModel = createDeepCopy(CartModel);
    this.tenantID = GTMData.getTenantId();
    this.isRecommendationControlled = this.props.data.miniCart && this.props.data.miniCart.isRecommendationControlled;
    this.productPackageTypeMapping = this.props.data.miniCart && this.props.data.miniCart.productPackageTypeMapping;
    this.isRecommendationAllowedForAll =
      this.props.data.miniCart && this.props.data.miniCart.isRecommendationAllowedForAll;
    if (this.isRecommendationControlled) {
      this.recommendationActions = new RecommendationActions({
        productPackageTypeMapping: this.productPackageTypeMapping,
      });
    }
    this.tenantID = GTMData.getTenantId();
    this._yaCartSvc = null;
  }

  isNonSeatedEvent = (tabs) => {
    let isNonSeatedEvent = false;
    const tab = tabs.find((tab) => tab.eventDetails !== null);

    if (tab) {
      isNonSeatedEvent = tab.eventDetails.isNonSeated;
    }

    // Also Check if user have previously selected Tour Event and later decides to select Seated event
    // Set isYaNonSeatedJourney to true to load non seated tour cart

    return isNonSeatedEvent || YALocalStorageSvc.hadSelectedNonSeatedEvent();
  };

  isRecommendationEnabled = () => helper.isRecommendationEnabled(this);
  isYAEmptyBookingPage = (isYARecommendationVisible) => helper.isYAEmptyBookingPage(this, isYARecommendationVisible);
  isPastPurchasedEnabled = () => helper.isPastPurchasedEnabled(this);
  isPastPurchaseRecommendationEnabled = () => helper.isPastPurchaseRecommendationEnabled(this);

  checkRecommendationVisibility = (response) => {
    this.setState({ isRecommendationVisible: response && response.products.length > 0 });
  };

  publishEvent = (eventStatus) => {
    window.PubSub.publish(UIConfig.events.MINICARTEXIST, eventStatus);
    this.eventPublished = eventStatus;
  };

  addToCartCallback = (
    products,
    groupingNotRequired = false,
    context,
    productMaxQty,
    extraCartParams,
    variantFromChild = '',
  ) => {
    this.groupingNotRequired = groupingNotRequired;
    if (this.props.data.upsell) {
      this.getUpsellProducts(products, context);
    } else if (this.isSwad && this.state.productWidgetPJData && this.state.productWidgetPJData.upsell) {
      this.getUpsellProducts(products, context, this.state.productWidgetPJData);
    } else {
      this.addToCart(products, context, productMaxQty, extraCartParams, variantFromChild);
    }
  };

  setDefaultCartState = (isInlineEditMode) => (isInlineEditMode ? this.cartModelWithProduct : this.emptyCartModel);
  setPartnerSegmentData = (cartData) => {
    const isProductWidget = this.props.data?.productWidget?.productDetails || false;
    const { memberDiscounts } = isProductWidget ? this.state?.productWidgetPJData?.miniCart : this.props?.data?.miniCart || {};
    const localstoragemembership = getMembershipDetails('membershipData');
    const findMembership = getPartnerCodeData(memberDiscounts, '');
    const preappliedCouponCode = localstoragemembership && localstoragemembership.couponCode[0];
    const partnersegProductIds =
      findMembership && findMembership.products && findMembership.products.length > 0 ? findMembership.products : [];

    const applypartnerSegmentPromo =
      canUseDOM() && localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo)
        ? true
        : false;
    const shouldApplyPromocode = cartData.some(
      (item) => partnersegProductIds.includes(item.productId) && applypartnerSegmentPromo,
    );
    shouldApplyPromocode &&
      preappliedCouponCode &&
      setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo, true);
  };
  /**
   * To add products to mini cart
   * @param {Array} products products to be added to mini cart
   * @param {[Object]}
   * @param {[Number]} productMaxQty - extra cart params.
   * @param {[Object]} extraCartParams - extra cart params.
   */
  addToCart = (products, context, productMaxQty, extraCartParams, variantFromChild = '', isFromUpsell) => {
    // add required keys for cart and checkbasket
    products = this.enrichData(products);
    const data = this.props.data;

    if (extraCartParams?.isTicketUpgradeCart) {
      this.clearCartCallback();
    }

    const perPromises = [];

    // extract the quntity from param and add it to respective product
    products.forEach((prod) => {
      const quantity = prod.quantity;
      let callPerformance = false;
      if (!prod.performanceId) {
        prod.performanceId = null; //for checkbasket
        callPerformance = prod.hasperformance === '1';
      }

      /* Reset performance id when hasperformance is 0 to fix price value */
      if (prod.hasperformance === '0') {
        prod.performanceId = null;
      }
      if (variantFromChild) {
        prod.isDynamicPricing = true;
      }

      prod.guestDetails = prod.guestDetails || null; //for checkbasket
      prod.quantity = quantity;
      prod.currQuantity = quantity;
      prod.groupedQuantity = quantity;
      if (this.isSwad) {
        prod.addedFromType = UIConfig.regularText;
        if (isFromUpsell) {
          prod.addedFromType = UIConfig.upSellText;
        } else if (this.props.data?.isCrossSellRecommendationEnable) {
          prod.addedFromType = UIConfig.crossSellTxt;
        }
        if (
          prod?.category &&
          prod?.category[0] === parseQueryString('tkt') &&
          parseQueryString('addedFrom') === UIConfig.postPurchaseCrossSellTxt
        ) {
          prod.addedFromType = UIConfig.postPurchaseCrossSellTxt;
        }
      }
      //adding null check for getPerformance service
      if (callPerformance && data.services.getPerformance) {
        const url = data.services.getPerformance.url.replace('{0}', prod.productId);
        // data.services.getPerformance.url.replace('{0}', prod.productId);
        perPromises.push(
          PerformanceService.getPerformanceData(
            url,
            prod.fromDate,
            prod.toDate,
            true,
            UICONFIG.loader.defaultPreLoaderTarget,
          ),
        );
      }
    });

    Promise.all(perPromises)
      .then((responses) => {
        let errObj = getErrorMap('getPerformance', {}, true);
        responses.forEach((res, index) => {
          products[index].performanceId = this.getPerformanceId(res.data.performancelist.performance, true);
          if (!products[index].performanceId) {
            res.error = {
              code: '7001',
              text: 'product is not sellable',
            };
            throw res;
          }
        });
        // save the data to cart
        let cartMaxQty, prodMaxQty;
        if (data.miniCart) {
          cartMaxQty = data.miniCart.maxCartQuantity;
          prodMaxQty = data.miniCart.maxQuantity;
        } else {
          cartMaxQty = data.cartMaxQty;
        }
        //TO activate min cart error message in case max qty is reached for add ons
        if (this.isYAEventListingPage && productMaxQty) {
          prodMaxQty = productMaxQty;
        }
        const remQty = cartMaxQty - this.state.cartData.cart.totalQuantity;
        this.updateCouponCodeData();
        if (!extraCartParams) {
          extraCartParams = {};
        }

        this.prductAddedToCart = products;
        this.cartActions
          .updateCart(this.productList, products, prodMaxQty, remQty, extraCartParams)
          .then((res) => {
            this.productList = deepCopyArrayOfObjects(res.productList);
            errObj = getErrorMap('updateCart', {}, true);
            if (this.getTenantId().toLowerCase() === UIConfig.yasArenaB2CTenant.toLowerCase()) {
              yaBuyTicketAnalytics({
                ...products[0],
                ...extraCartParams,
                productsArr: products,
              });
            } else {
              let productData = { products: this.prductAddedToCart };
              if (isFromUpsell && this.props.data?.upsell?.isGAEnabledForUpSell) {
                productData.addedFrom = isFromUpsell;
              } else if (this.props.data?.isCrossSellRecommendationEnable && this.props.data?.isGAEnabledForCrossSell) {
                productData.addedFrom = UIConfig.crossSellTxt;
              }
              const productList = [];
              const { products } = productData;
              if (this.ifParks) {
                if (Array.isArray(products) && products.length > 1) {
                  const pjTabs = this.state?.productWidgetPJData || '';
                  const tabsData = data?.tabs || pjTabs?.tabs;
                  const { category, tab } = products[0];
                  const prodCat = Array.isArray(category) ? category[0] : category;
                  const sltdTab = (tabsData?.length && tabsData.find((tb) => tab?.includes(tb.coveoValue))) || {};
                  const selectedTab = sltdTab?.controls[Object.keys(sltdTab?.controls)[0]].options.find(
                    (itm) => itm.coveoValue === prodCat,
                  );
                  const selectedTabProductIds = selectedTab?.productOverlaySelector?.collections.map(
                    (itm) => itm.productId,
                  );
                  productList.push(...products.filter((itm) => selectedTabProductIds.includes(itm.productId)));
                  productList.push(
                    ...products.filter((itm) => !productList.some((prod) => prod.productId === itm.productId)),
                  );
                  GTMData.push('addToCart', { products: productList });
                } else {
                  GTMData.push('addToCart', productData);
                }
              } else {
                GTMData.push('addToCart', productData);
              }
            }
            if (!this.props.data?.isCrossSellRecommendationEnable && this.showMinicartCouponCode) {
              this.renderCartUI(getErrorMap(this.props.data.services.updateCart.url, {}, true));
            }

            this.setState(
              {
                cartData: this.cartActions.getCustomCart(this.productList, true),
                errObj: errObj,
                bErrObj: '',
                groupQtyErr: '',
                addOnsErrorProdArr: [],
                isPostPurchasedAddon: products && products.length && products[0].isPostPurchasedAddon,
              },
              () => {
                isLoggedInUser() && this.setPartnerSegmentData(products);
                this.isMobileDevice && !this.eventPublished && this.publishEvent(true);
                window.PubSub.publish(UIConfig.events.MINICARTUPDATED, true);
              },
            );
            !this.isMobile && this.stickyCart();
            if (
              data.productWidget &&
              data.productWidget.ctaDetails &&
              data.productWidget.ctaDetails.link &&
              data.productWidget.ctaDetails.link.href &&
              data.variant.toLowerCase() !== 'product-new-calendar'
            ) {
              window.location.href = data.productWidget.ctaDetails.link.href;
            }
            if (
              (data.tabs && data.tabs[0].cssClass === 'v-multi-product-widget') ||
              variantFromChild === 'v-dynamic-product-pricing'
            ) {
              window.PubSub.publish('redirectToBookingPageCallback', {
                redirectToBookingPage: true,
              });
            } else if (context && context.href) {
              window.location.href = context.href;
            }
            if (this.isSwad) {
              window.PubSub.publish(UIConfig.AddedToCart, {
                isTicketAdded: true,
              });
            }
          })
          .catch((res) => {
            const err = res.error || res.response.error; //for different cutom responses
            this.errorCallback('updateCart', err, err.updatedProductList[0]);
          });
      })
      .catch((res) => this.errorCallback('getPerformance', res.error));
  };

  /**
   * to find upsell products for the selected product
   * @param {Array} products products for which upsell products to be found
   */
  getUpsellProducts = (products, context, PJData) => {
    const data = {
      mode: 'UpSell',
      recordCount: products.length,
    };
    const url = PJData ? PJData.services.getUpsellProducts.url : this.props.data.services.getUpsellProducts.url;
    data.payload = {};
    data.payload.products = [];
    products.forEach((prod) => data.payload.products.push({ id: prod.productId }));
    ProductService.getRelatedProducts('Purchase-Journey', data, url, true, UICONFIG.loader.defaultPreLoaderTarget)
      .then((res) => {
        if (res.data.relatedproductdetails && res.data.relatedproductdetails.upsells.length) {
          const upsellProducts = {};
          res.data.relatedproductdetails.upsells.forEach((upsell) => {
            const key = upsell.main_product.id,
              productDetails = this.getUpsellProductDetails(upsell);
            upsellProducts[key] = upsellProducts[key] || [];
            upsellProducts[key].push(...productDetails);
          });
          this.showUpsellProducts(products, upsellProducts, context, PJData);
        } else {
          this.addToCart(products, context);
        }
      })
      .catch(() => this.addToCart(products, context));
  };

  getUpsellProductDetails = (upsell) => helper.getUpsellProductDetails(this, upsell);

  showUpsellProducts = (products, upsellProducts, context, PJData) =>
    helper.showUpsellProducts(this, products, upsellProducts, context, PJData);

  changeCategoryToString = (prods) => helper.changeCategoryToString(this, prods);

  getRemainingProductDetails = (products, results) => helper.getRemainingProductDetails(this, products, results);

  getGroupedUpsellProds = (upsells, validLength) => helper.getGroupedUpsellProds(this, upsells, validLength);

  /**
   * Open the overlay with upsell products
   * @param {object} products currently selected products
   * @param {object} upsellProducts products map of upsell products
   */
  openUpsellOverlay = (products, upsellProducts, context, PJData, isGAEnabledForUpSell) => {
    const groupedUpsellProds = this.getGroupedUpsellProds(upsellProducts, products.length);
    const customTitle = PJData ? PJData.upsell.title : this.props.data.upsell.title;
    if (
      !this.upsellProdsAvailable ||
      Object.keys(groupedUpsellProds).length === 0 ||
      Object.keys(upsellProducts).length !== products.length
    ) {
      this.addToCart(products);
      return;
    }
    const upsellProductLength = Object.keys(groupedUpsellProds).length;
    const prodLengthClass = ['', 'c-single-double-upsell', 'c-single-double-upsell', 'c-multi-upsell'];
    const customClass = `purchase-overlay upsell ${prodLengthClass[upsellProductLength]} ${
      this.isSwad ? 'mobile-fix-height' : ''
    }`;

    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      customClass: customClass,
      redirectionContext: context,
      customFixedTitle: customTitle,
      dataToAppend: (
        <UpSell
          isGAEnabledForUpSell={isGAEnabledForUpSell}
          data={PJData ? PJData.upsell : this.props.data.upsell}
          discountMap={PJData && this.state.productWidgetPJData.discountMap}
          products={products}
          addToCartCallback={this.addToCart}
          groupedProducts={groupedUpsellProds}
          insertDiscountNode={this.insertDiscount}
          redirectionContext={context}
        />
      ),
    });
  };

  /**
   * insert discount node using back calculation in a product
   */
  insertDiscount = (prod, discountMap = this.props.data.discountMap) => {
    for (let i = 0; i < discountMap.length; i++) {
      const dis = discountMap[i],
        discountPer = Number(dis.discountPer);
      if (dis.code === prod.pricing && discountPer > 0) {
        prod.discount = {
          actualPerc: discountPer,
        };
        prod.unitPrice = toTwoDecimalPlaces(this.getUnitPrice(prod.gross, discountPer));
        break;
      }
    }
  };

  /**
   * get base price using discount percentage and price
   */
  getUnitPrice = (discountedPrice, discountPer) => (discountedPrice * 100) / (100 - discountPer);

  getProductDetailsById = (productId) => helper.getProductDetailsById(this, productId);

  /**
   * currently returning performance id at 0th position as there is ony
   * 1 time slot
   */
  getPerformanceId = (list, singleSlot) => {
    if (singleSlot && list && list.length) {
      return list[0].sellable && list[0].performanceId;
    }
  };

  updateProduct = (prod, quantity, maxQuantity, isAnnualPass) =>
    helper.updateProduct(this, prod, quantity, maxQuantity, isAnnualPass);

  clearCartCallback = () => {
    const cartData = this.cartActions.getCustomCart([]);
    this.productList = [];
    this.updateCartAfterDeleteAction(cartData, '', this.state.errObj);
  };

  updateCartAfterDeleteAction = (cartData, groupQtyErr, errObj) =>
    helper.updateCartAfterDeleteAction(this, cartData, groupQtyErr, errObj);

  deleteProduct = (prod, maxQuantity) => helper.deleteProduct(this, prod, maxQuantity);

  showClassType = (data) => this.cartActions.showClassType(data, this.props.data.showClassTypeName);

  createMappedData = (data, keyMap) => createMappedData(data, keyMap || this.props.data.coveoMappingList);

  enrichData = (products) => helper.enrichData(this, products);

  calculateToDate = (prod, mupValidityMap) => helper.calculateToDate(this, prod, mupValidityMap);

  getDiscountedProducts = (products, days, discountMap, disableDiscount) =>
    helper.getDiscountedProducts(this, products, days, discountMap, disableDiscount);

  getDiscount = (days, discountMap, products = []) => helper.getDiscount(this, days, discountMap, products);

  errorCallback = (serviceName, error, prod) => helper.errorCallback(this, serviceName, error, prod);

  createProductStore = (tab, param, isPreLoader) => helper.createProductStore(this, tab, param, isPreLoader);

  getProductStore = (tab, param, isPreLoader) =>
    helper.getProductStore(
      this,
      tab,
      param,
      isPreLoader,
      this.isSwad && this.props.data.isCrossSellRecommendationEnable,
    );

  getProductQuantityInCart = (prod) => helper.getProductQuantityInCart(this, prod);

  bookingTabsDataMapper = (props) => helper.bookingTabsDataMapper(this, props);

  updateProductForCoveo = (product) => {
    product.isFlexible = true;
    product.fromDate = momentTimezone()
      .startOf('day')
      .format(UIConfig.calendar.dateFormat);
    product.rangeInMonths = 12;
    return product;
  };

  beforeSetStateMiniCart = (cartProps) => {
    this.cartLoaded = true;
    if (cartProps) {
      this.productList = cartProps?.items || [];
      const isPackages = cartProps?.packages;
      if (isPackages?.length) {
        const unGroupPkgProd = this.cartActions.getUngroupedPkgPrd(isPackages);
        this.productList = [...this.productList, ...unGroupPkgProd];
      }

      const cart = this.cartActions.getCustomCart(this.productList, true);

      this.setState(
        {
          cartData: cart,
          cartEmpty: cart.cart.items || cart.cart.packages ? false : true,
          errObj: getErrorMap('getCart', {}, true, '', this.state.errObj),
          groupQtyErr: '',
        },
        () => {
          this.isMobileDevice && !this.eventPublished && this.publishEvent(true);
        },
      );
      if (!this.props.data?.isCrossSellRecommendationEnable && this.showMinicartCouponCode) {
        const couponData = {
          couponCode: cartProps.couponCode,
          promotions: cartProps.promotions,
        };
        this.updateCartProducts('', '', '', couponData);
      }

      let eventDetails;
      if (!this.isYaNonSeatedJourney) {
        const { eventId, eventTitle } = YALocalStorageSvc.getSetEventDetails();
        eventDetails = { eventId, eventTitle };
      }

      this.props.data.tabs &&
        !this.withoutPurchase &&
        !this.isSwad &&
        !this.ifParks &&
        GTMData.push('checkout', {
          products: this.productList,
          step: UIConfig.gtmStepOne,
          timeSlot: cartProps.timeSlot,
          date: cartProps.date,
          subTotal: cartProps.grossPrice,
          ...eventDetails,
        });
    }
  };
  setStateOfMinicart = () => {
    const { updatedCart } = this.props;
    const isFwadMiniCartData = this.isParks && this.miniCartFrmHeaderEnbl && !isEmpty(updatedCart) && isLoggedInUser();
    this.cartActions
      .getCartOnPageLoad()
      .then((res) => this.beforeSetStateMiniCart(isFwadMiniCartData ? updatedCart : res?.data?.cart))
      .catch((res) => {
        this.cartLoaded = true;
        isFwadMiniCartData ? this.beforeSetStateMiniCart(updatedCart) : this.errorCallback('getCart', res.error);
        this.showMinicartCouponCode && this.setState({ isRecommendationVisible: false });
      });
  };

  stickyCart = () => helper.stickyCart(this);

  componentDidMount = () => {
    if (this.isParks && detectMobile() && window.location.pathname.includes(UIConfig.pages.booking)) {
      this.isBookingPage = true;
    }
    if (canUseDOM()) {
      !this.isMobile && window.addEventListener('scroll', this.stickyCart);
    }
    if (
      get(this.props, 'data.tabs[0].cssClass', '') === 'v-multi-product-widget' ||
      get(this.props, 'data.tabs[0].variant', '') === 'v-multi-product-widget'
    ) {
      this.bookingTabsDataMapper(this.props);
    }
    if (!this.withoutPurchase) {
      if (getLoggedInUser().tenantID === UIConfig.yasArenaB2CTenant) {
        const { services } = this.props.data;
        this._yaAddOnSvc = new YaAddOnsService({
          services,
          coveoMappingList: this.props.data.coveoMappingList,
        });

        this._availabilitySvc = new YaAvailabiltyService({
          services,
          coveoMappingList: this.props.data.coveoMappingList,
        });

        this.cartActions
          .initYaJourneyActions({
            propsData: this.props.data,
            addToCart: this.addToCartCallback,
            isNonSeatedJourney: this.isYaNonSeatedJourney,
            getCartItems: async () => {
              return await this.productList;
            },
          })
          .then((response) => {
            this._yaCartSvc = response.actionsObj;
            this.setStateOfMinicart();
          });
      } else {
        this.setStateOfMinicart();
      }
      if (this.isSwad || this.ifParks) {
        window.PubSub.subscribe(UIConfig.events.PURCHASEJOURNEYDATA, (msg, data) => {
          this.setState({
            productWidgetPJData: data.purchaseJourneyData,
          });
        });
      }
    }
    /* added for fwad minicart - anual pass page */
    if (this.isParks && canUseDOM() && !window.location.pathname.includes(UIConfig.pages.booking)) {
      window.PubSub.subscribe(UIConfig.events.UPDATE_HEADER_CART, (msg, data) => {
        this.beforeSetStateMiniCart(data);
      });
    }

    GTMData.push('pageView');
    if (this.isSwad && this.props.data.showPostPurchaseRecommendation) {
      this.createProductStore(this.recommendationTabName).then((response) => {
        this.setState({
          isRecommendationVisible: resolvePath(response, 'products', []).length > 0,
          recommendedDataRes: response,
          setRecommendedData: response ? true : false,
        });
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.showMinicartCouponCode) {
      if (
        this.state.cartData &&
        prevState.cartData &&
        Object.keys(this.state.cartData.cart.items).length !== Object.keys(prevState.cartData.cart.items).length
      ) {
        if (
          this.isSwad &&
          this.props.data.isCrossSellRecommendationEnable &&
          Object.keys(prevState.cartData.cart.items).length > 0
        ) {
          return;
        } else {
          this.createProductStore(this.recommendationTabName).then((response) => {
            this.setState({
              isRecommendationVisible: resolvePath(response, 'products', []).length > 0,
              recommendedDataRes: response,
              setRecommendedData: response ? true : false,
            });
          });
        }
      } else if (this.state.setRecommendedData) {
        this.setState({
          setRecommendedData: false,
        });
      }
    }
    if (this.isYAEventListingPage) {
      //ADD ONS TAB rendering check
      const { items } = this.state.cartData.cart;
      let cartItems = Object.values(items);
      if (cartItems.length > 0) {
        this.handleAddOnVisibility(cartItems, prevState);
        const tabs = this.props.data?.tabs;
        const eventDetails = tabs && tabs.length ? tabs[0].eventDetails : {};
        setLocalStorage(
          UIConfig.localStoreKeys.purchaseJourney.disableDownloadButton,
          eventDetails.disableDownloadButton || false,
        );
      } else if (cartItems.length === 0 && Object.values(prevState.cartData.cart.items).length > 0) {
        this.setState({
          isAddOns: false,
        });
      }
    }
  }

  handleAddOnVisibility = (cartItems, prevState) => helper.handleAddOnVisibility(this, cartItems, prevState);

  /**
   * Calls the cross-sell api to retrieve recommended product ids
   * @param cartItems {Array} items added to cart
   * @returns {Promise} Recommended Items
   */
  getRecommendedItems = (cartItems = []) => {
    const url = this.props.data.services.getRecommendation.url;
    let cartProducts = [];
    const sessionCartData = JSON.parse(getSessionStorage(UIConfig.localStoreKeys.purchaseJourney.cartData))?.cart
      ?.items;
    if (!this.props.data.showPostPurchaseRecommendation) {
      cartItems.forEach((ci) => {
        ci.products.forEach((p) => cartProducts.push(p));
      });
    } else if (this.props.data.showPostPurchaseRecommendation && sessionCartData && Array.isArray(sessionCartData)) {
      cartProducts = sessionCartData;
    }
    const productArray = cartProducts.map((value) => ({ productId: value.productId, quantity: value.quantity }));

    const requestData = { checkSuggestiveSell: productArray };
    return RecommendationService.getFilterProducts(url, 'Purchase-Journey', requestData, true)
      .then((res) => {
        if (res.data) {
          if (this.isSwad) {
            let ticketsFromSessionCart = cartProducts.map((ticket) => {
              return ticket.productId;
            });
            let newData = res.data.PRODUCTGROUP;
            if (newData && newData[0]?.PRODUCTID) {
              let newTicketsData = [];
              for (let i = 0; i < newData[0].PRODUCTID.length; i++) {
                if (!ticketsFromSessionCart.includes(newData[0].PRODUCTID[i])) {
                  newTicketsData.push(newData[0].PRODUCTID[i]);
                }
              }
              newData[0].PRODUCTID = newTicketsData;
            }
            if (
              !this.props.data.showPostPurchaseRecommendation &&
              this.props.data.isCrossSellRecommendationEnable &&
              newData[0].PRODUCTID.length <= 0
            ) {
              if (this.props.data?.miniCart?.ctaCheckout && this.props.data?.miniCart?.ctaCheckout[0]) {
                window.location = this.props.data?.miniCart?.ctaCheckout[0].href;
              }
            }
            return newData;
          } else {
            return res.data.PRODUCTGROUP;
          }
        }
        if (
          this.isSwad &&
          !this.props.data.showPostPurchaseRecommendation &&
          this.props.data.isCrossSellRecommendationEnable
        ) {
          if (this.props.data?.miniCart?.ctaCheckout && this.props.data?.miniCart?.ctaCheckout[0]) {
            window.location = this.props.data?.miniCart?.ctaCheckout[0].href;
          }
        }
        return [];
      })
      .catch((error) => {
        if (
          this.isSwad &&
          !this.props.data.showPostPurchaseRecommendation &&
          this.props.data.isCrossSellRecommendationEnable
        ) {
          if (this.props.data?.miniCart?.ctaCheckout && this.props.data?.miniCart?.ctaCheckout[0]) {
            window.location = this.props.data?.miniCart?.ctaCheckout[0].href;
          }
        }
        this.recommendedProducts = {};
        return [];
      });
  };

  currencyCallBack = (e, updateCart) => {
    const defaultCurrency = this.state.cartData.cart.currency;
    if (e.value === defaultCurrency || !e.value) {
      this.selectedCurrency = defaultCurrency;
      if (this.state.convertedCurrency) {
        this.setState({
          convertedCurrency: null,
        });
      }
      return;
    }
    this.selectedCurrency = e.value;
    const currencyData = this.props.data.miniCart.currencyConverters,
      url = this.props.data.services.getConvertedCurrency.url, //add acc to place
      apiData = {
        access_code: currencyData.accessCode,
        amount: 100,
        converted_currency: e.value.replace(/[^\w\s]/gi, ''),
        currency: defaultCurrency,
        language: 'en',
        merchant_identifier: currencyData.merchantIdentifier,
        service_command: currencyData.serviceCommand,
      };
    apiData.signature = PayfortUtility.getPayFortSignature(apiData, currencyData.merchantPassphrase);
    CurrencyConverterService.getConvertedCurrency(url, apiData, 'my-cart', true, UIConfig.loader.defaultPreLoaderTarget)
      .then((res) => {
        const data = {
          converted_currency: e.value,
          converted_amount: res.data.converted_amount / 100,
        };
        this.setState({
          convertedCurrency: data,
          errObj: getErrorMap('CurrencyConverter', {}, true, {}, this.state.errObj),
        });
      })
      .catch((res) => {
        this.setState({
          convertedCurrency: null,
          errObj: getErrorMap(
            'CurrencyConverter',
            this.props.data.services.getConvertedCurrency,
            false,
            res.error,
            this.state.errObj,
          ),
        });
      });
  };

  applyCouponCallback = (response, isSuccess) => helper.applyCouponCallback(this, response, isSuccess);

  removeCouponCallback = () => {
    const couponData = { promotions: null, couponCode: '' };
    !this.props.data?.isCrossSellRecommendationEnable && this.updateCartProducts('', '', '', couponData);
    this.cartActions._storeExtraCartParams(couponData);
    window.PubSub.publish(UIConfig.events.ON_REMOVING_PROMOCODE);
  };

  renderCartUI = (errorData) => helper.renderCartUI(this, errorData);

  isCartEmpty = (response) => response && response.error && response.error.code === UIConfig.errorCodes.emptyCart;

  renderEmptyCart = () => helper.renderEmptyCart(this);

  handleCheckBasketError = (apiData) => helper.handleCheckBasketError(this, apiData);

  updateCouponCodeData = () => {
    const cartState = this.state.cartData.cart;
    if (this.showMinicartCouponCode && cartState && cartState.hasOwnProperty('couponCode')) {
      this.cartActions._storeExtraCartParams(cartState);
    }
  };

  /**
   * updateCartProducts function to update cart products
   * @param    {[Object]} product [product ot be modified].
   * @param    {[Number]} quantity [changed quantity of product].
   * @param    {[Number]} maxQuantity [maximum quantity that can be added for give product].
   * @param    {[Object]} extraCartParams [extraParameters required to be added in cart].
   * @return   {[void]} setState to render final cart after updation .
   */
  updateCartState = ({ cartResponse, cartData, event = null, errObj = null, bErrObj = null, cartEmpty = false }) => {
    if (cartData && cartResponse) {
      cartData.cart.totalTax = cartResponse.cart.totalTax;
      if (cartResponse.cart.totalFee && cartResponse.cart.totalFee > 0) {
        cartData.cart.totalFee = cartResponse.cart.totalFee;
      }
      cartData.cart.grossPrice = cartResponse.cart.grossPrice;
      cartData.cart.netPrice = cartResponse.cart.netPrice;
      cartData.cart.couponCode = cartResponse.cart.couponCode;
      cartData.cart.promotions = cartResponse.cart.promotions;

      if (cartData.cart && cartResponse.cart) {
        cartResponse.cart.items.forEach((cardItem, idx) => {
          cartData.cart.items[idx].price.gross = cardItem.price.gross;
          cartData.cart.items[idx].price.net = cardItem.price.net;
          cartData.cart.items[idx].price.tax = cardItem.price.tax;
        });
      }
    }

    cartData = this.getGroupedCart(cartData);

    this.setState(
      {
        cartData: cartData,
        errObj: errObj,
        bErrObj: bErrObj,
        cartEmpty: cartEmpty,
        groupQtyErr: bErrObj,
      },
      () => {
        if (event) {
          window.PubSub.publish(event, {
            cartData: this.state.cartData,
          });
        }
      },
    );
  };

  wrapCartResponse = (cartAPIData) => helper.wrapCartResponse(this, cartAPIData);

  validateQuantityBeforeCartUpdate = (product, quantity, maxQuantity, extraCartParams) =>
    helper.validateQuantityBeforeCartUpdate(this, product, quantity, maxQuantity, extraCartParams);

  updateCartProducts = (product, quantity, maxQuantity, extraCartParams) =>
    helper.updateCartProducts(this, product, quantity, maxQuantity, extraCartParams);

  setCartDefaults = (props) => helper.setCartDefaults(this, props);

  getTenantId = () => getLoggedInUser().tenantID;

  getGroupedCart = (cart) => {
    const groupedCart = createDeepCopy(cart);
    let productList = groupedCart?.cart?.items || [];
    const isPackages = groupedCart?.cart?.packages;
    if (isPackages?.length) {
      const unGroupPkgProd = this.CartActionObj.getUngroupedPkgPrd(isPackages);
      productList = [...productList, ...unGroupPkgProd];
    }
    groupedCart.cart.items = this.CartActionObj.groupCartProducts(productList);

    return groupedCart;
  };

  isEmptyBookingPage = () => helper.isEmptyBookingPage(this);

  getEmptyBookingData = () => helper.getEmptyBookingData(this);
  clearYACart = (mode) => {
    this.cartActions.clearYACart({ mode: mode, productList: this.productList }).then((response) => {
      this.clearCartCallback();
    });
  };

  getWidgetVariant = (data) => {
    const { variant, productWidget } = data;
    if (variant.toLowerCase() === 'product-new-calendar') {
      return (
        <ProductWidgetOverlay
          data={{ productWidget: productWidget, variant: variant }}
          addToCartCallback={this.addToCartCallback}
          createProductStore={this.createProductStore}
          getDiscountedProducts={this.getDiscountedProducts}
          createMappedData={this.createMappedData}
          getProductQuantityInCart={this.getProductQuantityInCart}
        />
      );
    }

    return (
      <FlashSaleComponent
        data={{ productWidget: data.productWidget, variant: data.variant }}
        errObj={this.state.errObj || this.state.groupQtyErr}
        addToCartCallback={this.addToCartCallback}
        createProductStore={this.createProductStore}
        createMappedData={this.createMappedData}
        getProductQuantityInCart={this.getProductQuantityInCart}
        withWrapper
      />
    );
  };

  checkAddonsAvailable = () => {
    if (checkTenant(UIConfig.yasArenaB2CTenant)) {
      return !this.state.isYARecommendationVisible;
    }
    if (this.isPastPurchasedEnabled() || !this.isEmptyBookingPage()) {
      return true;
    }
    return false;
  };

  getBookingTabsMiniCart = (data, containerClasses, wContentClasses, isHeadMiniCart) => {
    let eventTitle = YALocalStorageSvc.getEventTitle();
    return (
      <div className={containerClasses}>
        <div className={wContentClasses}>
          {data.productWidget && !data.isTakeOverBanner && this.getWidgetVariant(data)}
          {data.timerWidget && !data.isTakeOverBanner && (
            <FlashSaleComponent data={{ timerWidget: data.timerWidget, variant: data.variant }} withWrapper />
          )}
          {data.isTakeOverBanner && (
            <FlashSaleComponent
              data={{
                timerWidget: data.timerWidget,
                productWidget: data.productWidget,
                variant: data.variant,
                isTakeOverBanner: data.isTakeOverBanner,
              }}
              addToCartCallback={this.addToCartCallback}
            />
          )}

          {this.checkAddonsAvailable()
            ? data.tabs &&
              !this.isTicketUpgradeEnable && (
                <div
                  className={`b2c-booking-tabs-wrapper ${this.isPastPurchasedEnabled() ? 'past-purchase-wrapper' : ''}`}
                >
                  {!this.isSwad && <ErrorSummary data={this.state.purchaseWrapperErrors} />}
                  <BookingTabs
                    data={
                      (data.tabs[0] && data.tabs[0].cssClass !== 'v-multi-product-widget') ||
                      data.variant !== 'v-multi-product-widget'
                        ? data.tabs
                        : this.state.productWidgetOnly
                    }
                    textWithCtaSettings={data.textWithCtaSettings}
                    miniCart={data.miniCart}
                    carousel={data.carousel}
                    variant={data.variant}
                    hideTabs={data.hideTabs}
                    isCrossSellRecommendationEnable={data.isCrossSellRecommendationEnable}
                    showPostPurchaseRecommendation={data.showPostPurchaseRecommendation}
                    bookingUrl={data.bookingUrl}
                    hightLightFirstTicket={data.hightLightFirstTicket}
                    postPurchaseRecommendationCTA={data.postPurchaseRecommendationCTA}
                    isGAEnabledForCrossSell={data.isGAEnabledForCrossSell}
                    maxTicketToShow={data.maxTicketToShow}
                    recommendationUrl={data.recommendationUrl}
                    noRecommendationTitle={data.noRecommendationTitle}
                    noRecommendationDesc={data.noRecommendationDesc}
                    backLinkText={data.backLinkText}
                    addToCartCallback={this.addToCartCallback}
                    createProductStore={this.createProductStore}
                    getDiscountedProducts={this.getDiscountedProducts}
                    isYAEmptyBookingPage={this.isYAEmptyBookingPage}
                    createMappedData={this.createMappedData}
                    services={data.services}
                    tabsMapper={data.tabsMapper}
                    cartData={this.state.cartData}
                    mupValidityMap={data.mupValidityMap}
                    businessErrors={!this.isYAEventListingPage ? data.miniCart.businessErrors : {}}
                    discountMap={data.discountMap}
                    coveoMapList={flipObjectKeyValues(data.coveoMappingList)}
                    showMinicartCouponCode={this.showMinicartCouponCode}
                    recommendedProducts={this.recommendedProducts}
                    isRecommendationVisible={this.state.isRecommendationVisible}
                    checkRecommendationVisibility={this.checkRecommendationVisibility}
                    isRecommendationData={this.state.isRecommendationData}
                    isPostPurchasedAddon={this.state.isPostPurchasedAddon}
                    recommendedDataRes={this.state.recommendedDataRes}
                    setRecommendedData={this.state.setRecommendedData}
                    additionalRecommendedData={{
                      productPackageTypeMapping: this.productPackageTypeMapping,
                      isRecommendationControlled: this.isRecommendationControlled,
                      isRecommendationAllowedForAll: this.isRecommendationAllowedForAll,
                      cartLabels: data.miniCart && data.miniCart.cartLabels,
                    }}
                    isYasArenaView={this.isYAEventListingPage}
                    coveoMappingList={data.coveoMappingList}
                    cartActions={this.cartActions}
                    isYaAddOn={this.state.isAddOns}
                    availabilityMapping={this.state.availabilityProductMapping}
                    addOnProducts={this.state.addOnProducts}
                    isYaNonSeatedJourney={this.isYaNonSeatedJourney}
                    clearYACart={this.clearYACart}
                    showYaNonSeatedOverlay={this._yaCartSvc && this._yaCartSvc.showNonSeatedOverlay}
                    selectNewYAPerformance={() => this.cartActions.clearYACart({ productList: this.productList })}
                    getProductsList={async () => await this.productList}
                    {...(this.annualPassGridVariant && {
                      annualPassGridVariant: this.annualPassGridVariant,
                      updateQuantity: this.updateProduct,
                      deleteProduct: this.deleteProduct,
                    })}
                  />
                  {this.isSwad && <ErrorSummary data={this.state.purchaseWrapperErrors} />}
                </div>
              )
            : !this.isTicketUpgradeEnable && (
                <div className="b2c-booking-tabs-wrapper empty-booking">
                  <TextWithCTA data={this.getEmptyBookingData()} />
                </div>
              )}
          {this.isTicketUpgradeEnable && (
            <TicketUpgrade
              data={data?.tabs[0]}
              miniCart={data?.miniCart}
              upgradeObj={data?.ticketUpgrade}
              services={data?.services}
              selectedticket={'selectedTicket'}
              addToCartCallback={this.addToCartCallback}
              upgradeTicket={'upgradeTicket'}
              hideTabs={data?.hideTabs}
              coveoMappingList={data?.coveoMappingList}
            />
          )}
          {!data.showPostPurchaseRecommendation &&
            !this.isTicketUpgradeEnable &&
            (!this.annualPassGridVariant || (this.isMobile && !this.isParks)) &&
            data.miniCart &&
            this.cartLoaded &&
            Object.keys(data.miniCart).length !== 0 && (
              <div className="b2c-mini-cart-wrapper">
                <MiniCart
                  isHeaderMiniCart={data.isHeaderMiniCartEnabled}
                  data={data.miniCart}
                  isYASIDVersionEnable={this.props.data.isYASIDVersionEnable}
                  items={this.state.cartData}
                  updateQuantity={
                    !this.props.data?.isCrossSellRecommendationEnable && this.showMinicartCouponCode
                      ? this.validateQuantityBeforeCartUpdate
                      : this.updateProduct
                  }
                  showToastNotification={!this.props.hideToastNotificationOfCart && this.isSwad ? true : false}
                  deleteProduct={this.deleteProduct}
                  errObj={this.state.errObj}
                  recommendationUrl={data.recommendationUrl}
                  bErrObj={this.state.groupQtyErr}
                  showClassType={this.showClassType}
                  stickyCart={this.stickyCart}
                  showMinicartCouponCode={this.showMinicartCouponCode}
                  currencyCallBack={this.currencyCallBack}
                  isRecommendationData={this.state.isRecommendationData}
                  isPostPurchasedAddon={this.state.isPostPurchasedAddon}
                  convertedCurrency={this.state.convertedCurrency}
                  addOnsErrorProdArr={this.state.addOnsErrorProdArr}
                  recommendationActions={this.recommendationActions}
                  isRecommendationControlled={this.isRecommendationControlled}
                  isRecommendationAllowedForAll={this.isRecommendationAllowedForAll}
                  isYaNonSeatedJourney={this.isYaNonSeatedJourney}
                  cartEmpty={this.state.cartEmpty}
                  yasArenaCart={{
                    deleteAndChange: this.clearYACart,
                    isYasArenaView: this.isYAEventListingPage, //this.yasArenaCartView,
                    eventName: eventTitle,
                    availabilityMapping: this.state.availabilityProductMapping,
                  }}
                  couponCode={{
                    applyCouponCallback: this.applyCouponCallback,
                    removeCouponCallback: this.removeCouponCallback,
                    services: data.services,
                  }}
                  isMiniCartEnabled={isHeadMiniCart}
                  isHeader={this.props.isHeader}
                />
              </div>
            )}
          {(getUserAgent() || !hasHeaderComponent()) && <Overlay />}
        </div>
      </div>
    );
  };

  render() {
    const data = this.props.data;
    const isHeadMiniCart = this.props.isMiniCart;
    const { yasId } = getLoggedInUser();
    try {
      const containerClasses = classNames(`component c-purchase-journey `, {
        'no-padding-top': data.productWidget,
        'no-flex': this.withoutPurchase,
        'container ': this.isSwad && !data.isHeaderMiniCartEnabled,
        'swad-cross-sell': this.isSwad && data.isCrossSellRecommendationEnable,
        'swad-cross-sell-after-payment': this.isSwad && data.showPostPurchaseRecommendation,
        'v-multi-product-widget':
          data.tabs &&
          (data.tabs[0].cssClass === 'v-multi-product-widget' || data.tabs[0].variant === 'v-multi-product-widget'),
      });
      const wContentClasses = classNames(
        'w--content',
        { 'purchase-content': !data.productWidget },
        { 'post-purchase-content': this.isPastPurchasedEnabled() },
        { 'annual-pass-grid-content': this.annualPassGridVariant },
      );

      if (checkTenant(UIConfig.yasArenaB2CTenant) && canUseDOM() && this.isPastPurchaseRecommendationEnabled()) {
        const { isRecommendationAllowedForAll } = data?.tabs?.length && data.tabs[0];
        return yasId || isRecommendationAllowedForAll ? (
          this.getBookingTabsMiniCart(data, containerClasses, wContentClasses, isHeadMiniCart || this.isBookingPage)
        ) : (
          <></>
        );
      }

      if (canUseDOM() && this.isPastPurchasedEnabled()) {
        return yasId ? (
          this.getBookingTabsMiniCart(data, containerClasses, wContentClasses, isHeadMiniCart || this.isBookingPage)
        ) : (
          <></>
        );
      }
      if (
        data.showPostPurchaseRecommendation &&
        ((this.state.recommendedDataRes && this.state.recommendedDataRes.products.length <= 0) ||
          !this.state.recommendedDataRes)
      ) {
        return null;
      }
      return this.getBookingTabsMiniCart(data, containerClasses, wContentClasses, isHeadMiniCart || this.isBookingPage);
    } catch (err) {
      return logComponentRenderingError(err, 'PurchaseJourneyWrapper', data.variant);
    }
  }
}

PurchaseJourney.propTypes = {
  data: PropTypes.shape({
    miniCart: PropTypes.object,
    coveoMappingList: PropTypes.object,
    discountMap: PropTypes.array,
    tabs: PropTypes.array,
    services: PropTypes.object,
  }),
};

export default PurchaseJourney;
