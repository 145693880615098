import React from 'react';
import PropTypes from 'prop-types';
import FastTicketComponent from '../../../presentation/b2c-purchase-journey/fast-ticket/fast-ticket-component';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
export default class FastPassComponent extends React.Component {
  constructor(props) {
    super(props);
    const { coveoKey, coveoValue, tickets } = this.props.data.controls.fastPassControl;
    this.tickets = tickets;
    this.coveoPayload = {
      itemType: {
        key: coveoKey,
        value: coveoValue,
      },
      parksKey: {
        key: null,
        value: [],
        logic: 'OR',
      },
      quantity: {
        maxQuantity: tickets[0].visitorCounter.maxCount,
      },
    };
    this.deltaCountForError = 1;
    this.state = {
      disableAddToCart: this.updateInitialCoveoPayload(),
    };
  }

  updateInitialCoveoPayload = () => {
    this.tickets.forEach((item) => {
      item.ticket &&
        item.ticket.parks.forEach(() => {
          this.updateQuantity({
            quantity: item.visitorCounter.options[0].defaultQuantity,
            ticket: item.ticket,
            initialState: true,
          });
        });
    });
    return this.coveoPayload.parksKey.value.length ? false : true;
  };

  addtoCart = () => {
    this.props.addToCartCallback(this.coveoPayload);
    this.updatePropsQuantity(null, 0); //reset select on add to cart
    this.setState({
      disableAddToCart: true,
    });
  };

  updatePropsQuantity = (parkValue, quantity) => {
    this.tickets.forEach((item) => {
      item.ticket &&
        item.ticket.parks.forEach((park) => {
          if (parkValue === park.coveoValue || parkValue == null) {
            item.visitorCounter.options[0].defaultQuantity = quantity;
          }
        });
    });
  };

  updateQuantity = (data) => {
    const parkValue = data.ticket.parks[0].coveoValue,
      parkValueIndex = this.coveoPayload.parksKey.value.indexOf(parkValue);
    this.coveoPayload.parksKey.key = data.ticket.parks[0].coveoKey;
    if (parkValueIndex === -1 && data.quantity > 0) {
      this.coveoPayload.parksKey.value.push(parkValue);
    }
    this.coveoPayload.quantity[parkValue] = data.quantity;
    if (data.quantity === 0) {
      this.coveoPayload.parksKey.value.splice(parkValueIndex, 1);
    }
    if (
      this.coveoPayload.parksKey.value.length &&
      !data.initialState &&
      data.quantity < data.max + this.deltaCountForError
    ) {
      this.setState({
        disableAddToCart: false,
      });
    } else if (!data.initialState) {
      this.setState({
        disableAddToCart: true,
      });
    }

    this.updatePropsQuantity(parkValue, data.quantity);
  };

  renderTicket = (ticket, index) => {
    return (
      <FastTicketComponent
        data={ticket}
        key={index}
        tktVal={this.props.tktVal}
        updateQuantity={this.updateQuantity.bind(this)}
      />
    );
  };

  render() {
    const { addToCart, controls } = this.props.data,
      { tickets } = controls.fastPassControl;
    return (
      <div className="component c-fast-pass">
        <div className="w--content">
          <div className="c-fast-content">{tickets.map((ticket, index) => this.renderTicket(ticket, index))}</div>
          <div className="c-fast-bottom">
            <div className={this.state.disableAddToCart ? 'btn-primary disabled' : 'btn-primary'}>
              <button type="button" className="add-to-cart" onClick={this.addtoCart.bind(this)}>
                {addToCart}
              </button>
            </div>
            {this.props.data.vatInfo && (
              <DynamicContent
                tagName="div"
                attrs={{ className: 'vat-info body-2' }}
                innerHtml={this.props.data.vatInfo}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

FastPassComponent.PropsTypes = {
  data: PropTypes.shape({
    controls: PropTypes.object.isRequired,
  }),
};
