import React from 'react';
import UIConfig from '../../../../../common/UIConfig';
import { resolvePath, canUseDOM } from '../../../../../common/utility';

const currentTenant =
  canUseDOM() && resolvePath(JSON.parse(localStorage.getItem('mainObj')), 'tenantID', '').toLowerCase();

const otherTenantsCalenderLegendsStyle = (mainClass, prod, product) => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    .${mainClass} .calendar-inside-overlay .react-datepicker__day.${prod.productId.replaceAll(
            '.',
            '-',
          )}::before {
                content : "";
                background-color: ${product.colorCode};
                position: absolute;
                height: 6px;
                width: 6px;
                bottom: -4px;
                border-radius: 50%;
                left: 10px;
                border: none;
                transform: none;
              }
            `,
        }}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    .${mainClass} .calendar-inside-overlay .react-datepicker__day.react-datepicker__day--selected.${prod.productId.replaceAll(
            '.',
            '-',
          )}::before {
                content : "";
                background-color: ${product.colorCode};
                width: inherit;
                height: inherit;
                border-radius: inherit;
                border-style: solid;
                border-width: 4px;
                border-color: ${product.colorCode};
                position: absolute;
                top: -6px;
                left: -6px;
              }
            `,
        }}
      />
    </>
  );
};

const swadTenantCalenderLegendsStyle = (mainClass, prod, product) => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    .${mainClass} .calendar-inside-overlay .react-datepicker__day.${prod.productId.replaceAll(
            '.',
            '-',
          )}::before {
                content : "";
                background-color: ${product.colorCode};
                position: absolute;
                height: 6px;
                width: 6px;
                bottom: -8px;
                border-radius: 50%;
                left: 50% !important;
                border: none;
                transform: translateX(-50%)!important;
              }
            `,
        }}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    .${mainClass} .v-dynamic-product-pricing.calendar-inside-overlay .react-datepicker__day.react-datepicker__day--selected.${prod.productId.replaceAll(
            '.',
            '-',
          )}::before {
                    content : "";
                    background-color: ${product.colorCode};
                    position: absolute;
                    height: 6px;
                    width: 6px;
                    bottom: -9px;
                    border-radius: 50%;
                    left: 50%;
                    border: none;
                    transform: translateX(-50%);
              }
            `,
        }}
      />
    </>
  );
};
const index = ({ context, mainClass, isDrivingExperience = false }) =>
  ((context.isDynamicPricingProd || isDrivingExperience) &&
    context.props.searchProductList
      .filter((elem, pos, arr) => {
        return arr.indexOf(elem) === pos;
      })
      .map((prod) => {
        const product = context.legends.find((item) => item.productId === prod.productId);
        if (product) {
          return currentTenant === UIConfig.SWADB2C
            ? swadTenantCalenderLegendsStyle(mainClass, prod, product)
            : otherTenantsCalenderLegendsStyle(mainClass, prod, product);
        }
      })) || <></>;

export default index;
