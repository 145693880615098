import UIConfig from '../UIConfig';
import { isMatchTenant } from './login';
import { canUseDOM } from './window';

export const easeInOutQuad = (currentTime, start, change, duration) => {
  currentTime /= duration / 2;
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + start;
  }
  currentTime--;
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
};

/* function to retrieve generic error messge from the local storage */
export const getGenericErrors = () => {
  let errors = {};
  if (canUseDOM()) {
    if (!localStorage.errors && window.location.hostname === 'localhost') {
      return {
        '404': 'Page not found',
        '500': 'Internal Server error',
        '400': 'bad request local',
        default: 'Default error occur',
      };
    } else if (localStorage.errors) {
      errors = JSON.parse(localStorage.errors);
    } else if (localStorage.mainObj) {
      const mainObjData = JSON.parse(localStorage.mainObj);
      errors = mainObjData.errors ? mainObjData.errors : errors;
    }
  }
  return errors;
};

/* function returning error oject in case of generic system errors */
/**
 * @param    {string} code - need reponse status from Apiwrapper
 * @param    {object} errors - error object received from Apiwrapper
 * @param    {string} isSystemError - boolean received from Apiwrapper true / false
 * @return   {object}  return javascript object contains error code , systemError and text.
 */
export const getErrorObj = (code, errors, isSystemError, isFullResNeed, errorRes) => {
  const errorObj = {};
  errorObj.error = {
    code: code,
    text: '',
    errorRes: isFullResNeed ? errorRes : null,
  };
  if (errors && errors[code]) {
    errorObj.error.text = errors[code];
    errorObj.error.systemError = !!isSystemError;
  } else if (errorRes && errorRes.data && errorRes.data.error && errorRes.data.error.errordescription) {
    errorObj.error.text = errorRes.data.error.errordescription;
    errorObj.error.systemError = !!isSystemError;
  } else if (errorRes && errorRes.data && errorRes.data.error) {
    errorObj.error.text = errorRes.data.error.text;
  }
  return errorObj;
};

/* function returning error map oject for displaying errors on components */
/**
 * @param    {string} serviceName - Service Name
 * @param    {object} errorsData - error messge object recived from json contract
 * @param    {boolean} status - true in case of api call success else false
 * @param    {object} error - error object recived from ApiWrapper in case of faliure
 * @return   {object}  previous state of errors.
 */
export const getErrorMap = (serviceName, errorsData, status, error, currentState, errorCodesToSkip) => {
  const errorObj = {},
    genericErrors = getGenericErrors(),
    isServiceSuccessed = status;
  let currentError;
  //checking if current service is fail or success
  if (!isServiceSuccessed && error && (error.code || error.errorcode)) {
    //setting default error base object
    errorObj[serviceName] = {
      code: error.code || error.errorcode,
      text: '',
    };

    if (errorCodesToSkip && errorCodesToSkip.length) {
      let skipError = false;
      errorCodesToSkip.forEach((item) => {
        if (!skipError && (item === error.code || item === error.errorcode)) {
          skipError = true;
        }
      });
      if (skipError) {
        return '';
      }
    }

    //Checking if error type is generic or API specific
    if (errorsData && (errorsData[error.code] || errorsData[error.errorcode])) {
      errorObj[serviceName].text = errorsData[error.code] || errorsData[error.errorcode] || genericErrors['default'];
    } else if (errorsData && errorsData['default']) {
      errorObj[serviceName].text = errorsData['default'];
    } else if (Object.keys(genericErrors).length) {
      errorObj[serviceName].text = genericErrors[error.code] || genericErrors[error.errorcode];
      //error handling snippet
      if (!errorObj[serviceName].text) {
        errorObj[serviceName].text = error.text || error.errordescription;
      }
    } else {
      errorObj[serviceName].text = error.text || error.errordescription;
    }

    //modify existing map object and merge current error in map object
    currentError = {
      ...errorObj,
      ...currentState,
    };
  } else {
    if (currentState && currentState[serviceName]) {
      delete currentState[serviceName];
      currentError = currentState[serviceName];
    } else {
      currentError = currentState;
    }
  }
  return currentError;
};

/* function returning error map oject for displaying errors on components */
/**
 * @param    {string} serviceNameKey - Service Name
 * @param    {object} services - this will be payment services
 * @param    {boolean} responseOrError - response error object
 * @return   {object}  mapping of error object.
 */
export const getErrorDataInObject = (serviceKey, services, responseOrError = null) => {
  let code = null,
    text = '',
    errors = {},
    errorData;
  if (responseOrError) {
    code = responseOrError.status || responseOrError.code;
    text = responseOrError.response_message || responseOrError.text;
  } else {
    code = 404;
    text = services.tokenization.errors['E01'];
  }

  if (responseOrError && responseOrError.response_code) {
    errorData = { code: responseOrError.response_code, text: text };
    errors = getErrorMap(serviceKey + 1, services[serviceKey].errors, false, errorData, null);
  }

  errorData = { code: code, text: text };

  return getErrorMap(serviceKey, services[serviceKey].errors, false, errorData, errors);
};

export const filterErrorsData = (errorsData, key = false) => {
  if (key) {
    const filteredData = errorsData.find((element) => element.key === key);
    return filteredData && filteredData.additionalParameters;
  } else {
    return errorsData[0].additionalParameters;
  }
};

export const getErrorMessage = ({ obj, errCode }) => {
  const trimErrorCode = errCode.substr(-3);
  for (var key in obj) {
    if (key.indexOf(trimErrorCode) > -1) {
      return obj[key];
    }
  }
  return {};
};

/**
 * Desc: Check if any of the field is Empty or null
 * @return {Boolean} return true if passes the filter
 */
export const isEmptyObject = (obj) => {
  let isErrorNotExist = true;
  Object.keys(obj).forEach(function(key) {
    if (obj[key] === '' || obj[key] === null || obj[key] === undefined || !obj[key]) {
      isErrorNotExist = false;
      return isErrorNotExist;
    }
  });
  return isErrorNotExist;
};

// conditions below will be removed or changed acording to the tenant on which the mini header is going

export const getHeaderHeight = (headerClass = 'c-header-top') => {
  if (canUseDOM()) {
    if (!isMatchTenant(UIConfig.tenants.yww)) {
      const header = document.getElementsByClassName(headerClass);
      return header?.length ? header[0].clientHeight : 0;
    } else {
      const miniHeaderClass = 'c-mini-header';
      const miniHeader = document.getElementsByClassName(miniHeaderClass);
      const myCartHeader = document.getElementsByClassName('c-b2c-cart-header');
      return miniHeader?.length ? miniHeader[0].clientHeight + myCartHeader[0].clientHeight : 0;
    }
  }
};

/**
 * getClosestByClass function return the desired closest parent of the target element.
 * @param    {[Object]} el [DOM object of the element for whom we want to find the parent]
 * @param    {[String]} className [class of the parent node which needs to be found].
 * @return   {[Object]} returns the found element and null if no such element is found
 */
export const getClosestByClass = (el, className) => {
  while (el && el.classList && !el.classList.contains(className)) {
    el = el.parentNode;
    if (!el) {
      return null;
    }
  }
  return el;
};

/*
 * Bound to an event to fire only once after a specific amount of time has passed
 */
export const debounce = (fn, delay) => {
  var timer = null;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
};

/**
 * scrollTo function scrolls the page to the desired position.
 * @param    {[Object]} DOM element to be scrolled
 * @param    {[Number]} scroll Position
 * @param    {[Number]} animation time
 */
export const scrollTo = (element, to, duration) => {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = () => {
    currentTime += increment;
    const scrollValue = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = scrollValue;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const scrollToView = (data, scrollStart = false) => {
  const headerHeight = getHeaderHeight();
  const hasOverlay = getClosestByClass(data.errorBlock, 'overlay-wrapper');

  if (hasOverlay !== window.document) {
    data.errorBlock.scrollIntoView({ behavior: 'smooth' });
  } else {
    const errorOffset = data.errorBlock.offsetTop - (headerHeight + UIConfig.headerOffset);
    const ele = document.documentElement;
    const element = ele.scrollTop !== 0 ? ele : document.body;
    scrollStart
      ? scrollTo(ele, errorOffset, UIConfig.formScrollTime)
      : scrollTo(element, errorOffset, UIConfig.formScrollTime);
  }
};

export const scrollToError = debounce((data) => {
  if (data && data.errorBlock && canUseDOM()) {
    scrollToView(data);
  }
}, UIConfig.serverErrorDebounce);

export const scrollToFormErrorOrSuccess = (eleClassName, scrollStart = false, postPurchaseForm = false) => {
  //Find the first inline form error element, and scroll the page
  if (canUseDOM()) {
    const target = document.getElementsByClassName(eleClassName);
    if (target.length) {
      if (postPurchaseForm) target[0].parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      else scrollToView({ errorBlock: target[0].parentElement }, scrollStart);
    }
  }
};
