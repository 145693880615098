import * as startIcon from '../assets/img/start-icn.png';
import * as endIcon from '../assets/img/end-icn.png';
import * as defaultIcon from '../assets/img/location.png';

import { registerLocale } from 'react-datepicker';
import dateFnsEn from 'date-fns/locale/en-GB';
import dateFnsAr from 'date-fns/locale/ar-SA';
registerLocale('en-gb', { ...dateFnsEn, options: { ...dateFnsEn.options, weekStartsOn: 1 } });
registerLocale('ar-sa', { ...dateFnsAr, options: { ...dateFnsAr.options, weekStartsOn: 1 } });

const UIConfig = {
  undefined: 'Undefined',
  encryptionKeyId: 'PublicKey',
  discountOptions: {
    originalPrice: 'OriginalPrice',
    discount: 'discount',
  },
  ga4Events: {
    new: [
      'viewCart',
      'viewItemList',
      'beginCheckout',
      'selectItem',
      'clickFooter',
      'clickHeader',
      'widgetSearch',
      'subscribe',
      'purchase',
      'progressFlow',
      'startFlow',
      'login',
      'refund',
      'addPaymentInfo',
      'clickTab',
      'clickAccordian',
      'clickCta',
      'clickLink',
      'FormComplete',
      'signUp',
      'viewItem',
    ],
    common: [
      'addToCart',
      'removeCartItem',
      'ticketImpressions',
      'productClick',
      'productDetailsLoad',
      'ticketDetailsLoad',
      'productDetails',
      'productImpressions',
    ],
  },
  ga4ParksTenants: ['fwb2c', 'wbwb2c', 'ywwb2c', 'ppb2c', 'clymbb2c', 'swadb2c'],
  ga4Constants: {
    PAYMENT: 'PAYMENT',
    BUTTON: 'BUTTON',
    ANCHOR: 'A',
    PRIMARY_CTA: 'PRIMARY_CTA',
    SECONDARY_CTA: 'SECONDARY_CTA',
    HOME: 'Home',
    TARGET_BLANK: '_blank',
    CLICK_CTA: 'clickCta',
    CLICK_ACCORDIAN: 'clickAccordian',
    CLICK_TAB: 'clickTab',
    CLICK_LINK: 'clickLink',
    FORM_COMPLETE: 'FormComplete',
    ELEMENTTEXT: 'element_text',
    HEADER_NAVIGATION: 'headerNavigation',
    WEBSITE_SEARCH: 'websiteSearch',
    PARTNER_LINKS: 'partnerLinks',
    NEWSLETTER_SUBSCRIBE: 'newsletterSubscribe',
    ADD_PAYMENT_INFO: 'addPaymentInfo',
    SOCIAL_LINKS: 'socialLinks',
    BUY_TICKETS: 'buyTickets',
    VIEW_ITEM: 'viewItem',
    VIEW_CART: 'viewCart',
    BEGIN_CHECKOUT: 'beginCheckout',
    SELECT_ITEM: 'selectItem',
    REMOVE_FROM_CART: 'removeFromCart',
    ADD_TO_CART_ADONS: 'addToCartAdons',
    SUPPLIER_REGISTRATION: 'supplierRegistration',
    VIEW_ITEM_LIST: 'viewItemList',
    LOGIN: 'login',
    SIGNUP: 'signup',
    CLICK_HEADER: 'clickHeader',
    CLICK_FOOTER: 'clickFooter',
    PURCHASE: 'purchase',
    ADD_SHIPPING_INFO: 'addShippingInfo',
  },
  pages: {
    booking: 'booking',
    profile: 'profilepage',
    supplierRegistrationPage: 'supplier-registration',
  },
  parksStickHeader: {
    variant: 'v-parks-sticky-header',
  },
  parksTicketUpgrade: {
    annualPassType: 'ANP',
    multiparkType: 'MUP',
    ticketUpgradeData: 'ticketUpgradeData',
    ticketUpgradeJourney: 'ticketUpgradeJourney',
    cartType: 'ticket-upgrade',
    cartTypeLocalStorage: 'card-type',
    upgradeUrlWithQS: 'upgradeUrlWithQS',
    maleGuest: 'M',
    femaleGuest: 'F',
    male: 'MALE',
    female: 'FEMALE',
    QuickPass: 'Quick-Pass',
    addonWBW: 'Addons_WB',
    CreateOrderSuccess: 'CreateOrderSuccess',
    cartsummarygridview: 'cartsummarygridview',
    dataPosted: 'dataPosted',
    annualPassFormDisableFields: ['firstname', 'lastname', 'email'],
  },
  fullCalendar: {
    next: 'next',
    prev: 'prev',
    noEventMsg: 'No events to display',
    am: 'am',
    pm: 'pm',
    weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  },
  zeroBounce: {
    contactUs: 'contactus',
  },
  paymentRes: {
    success: 'Success',
    failed: 'Failed',
  },
  classes: {
    vFullWidth: ' v-full-width',
    featureImage: 'feature-image',
    editorialTextFromMediaCarousel: 'from-media-carousel-wrapper-revamp-component',
  },
  tabListingGridVariations: {
    twoGridLayout: 'two-grid-layout',
  },
  subscribes: { initiateServerError: 'initiateServerError' },
  appLocalStorageKeys: {
    mainObj: 'mainObj',
    userEmailAddress: 'userEmailAddress',
    numberOfInstallments: 'numberOfInstallments',
  },
  appCookies: {
    idToken: 'idToken',
  },
  animations: {
    stickyPanels: {
      enabled: true,
    },
  },
  dateFormats: {
    DD_MM_YYYY: 'DD-MM-YYYY',
    YYYY_M_D: 'YYYY-M-D',
    DD_MMM_YYYY: 'DD MMM YYYY',
  },
  viewportTypes: {
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop',
    desktop_xl: 'desktop-xl',
  },
  local: {
    bookingPage: {
      cartAPI: '/data/cartAPI.js',
      sessionAPI: '/data/sessionAPI.js',
      omniGetProducts: '/data/omni-get-products.js',
      coveoGetProducts: '/data/coveo-get-products.js',
      coveoGetNextProducts: '/data/coveo-get-products-',
    },
  },

  dev: {
    bookingPage: {
      cartAPI: 'https://fe-newdev-ux-appserver.azurewebsites.net/api/token/Get',
      sessionAPI: '',
      omniGetProducts: 'https://sitapimgmt.azure-api.net/v1/partners/317317000003/products?tenantid=YWWB2C',
      coveoGetProducts: '',
    },
  },
  loadMoreGuestDetails: 2,
  coveoResultsPerPage: 100,
  viewGuestDetailCount: 15,
  localAdvanceBookingDays: 1,
  loadMoreDefaultCount: 3,
  postpay: {
    promo: {
      CART: 'cart',
      UAE: 'UAE',
      AED: 'AED',
      SCRIPT_SRC: 'https://cdn.postpay.io/v1/js/postpay.js',
      SCRIPT: 'script',
    },
  },
  tabby: {
    promo: {
      CART: 'cart',
      UAE: 'UAE',
      AED: 'AED',
      SCRIPT_SRC: 'https://checkout.tabby.ai/tabby-promo.js',
      SCRIPT: 'script',
    },
  },
  paymentOption: {
    accCredit: 'AccountCredit',
    creditCard: 'CreditCard',
    tabby: 'Tabby',
    paypal: 'PayPal',
    submitApproval: 'SubmitForApproval',
    applePay: 'ApplePay',
    aliPay: 'AliPay',
    gPay: 'GPay',
    pointsPay: 'PointsPay',
    postPay: 'PostPay',
    pointsPayEtihad: 'PointsPayEtihad',
    pointsPayEtisalat: 'PointsPayEtisalat',
    adcbTouchPoints: 'ADCBTouchPoints',
    adcbCard: 'ADCBCard',
    adcbTouchPointsADCBCard: 'ADCBTouchPointsADCBCard',
    free: 'Free',
    installments: 'Installments',
    moneyCard: 'MoneyCard',
    card: 'card',
  },
  bankOptions: {
    adcb: 'ADCB',
    enbd: 'ENBD',
  },
  marketType: {
    LOCAL: 'local',
    SOURCE: 'source',
    NONDATED: 'non-dated',
    DATED: 'dated',
  },
  experienceCategory: {
    PRIMARY: 'PRIMARY',
    SECONDAY: 'Secondary',
    ADD_ON_GUEST: 'AddonGuest',
  },
  ProductType: {
    ADD_ON: 'ADD',
    PROMOTIONS: 'PRO',
    PACKAGE: 'package',
    SINGLE_DAY: 'singleDay',
    MULTI_DAY: 'multiDay',
    B2B_SPC: 'B2BSPC',
    F1: 'F1',
    VIP_PRIVATE: 'vipprivate',
    VIP_SHARED: 'vipshared',
    VIP: 'VIP',
    VVIP: 'VVIP',
  },
  TicketType: {
    GOLD: 'GLD',
    SILVER: 'SLV',
    BRONZE: 'BRZ',
    STANDARD: 'STN',
    PREMIUM: 'PRM',
    VOUCHERS: 'VCH',
  },
  addOnPerformanceType: {
    SHOW_DATE_AND_TIME: 'ShowDateAndTime',
    SHOW_DATE: 'ShowDate',
    EVENT: 'Event',
  },
  events: {
    TOGGLE_OVERLAY_STATE: 'toggleOverlayState',
    CLOSE_OVERLAY: 'closeOverlay',
    CHECK_BLOCKED_SLOTS: 'checkBlockedSlots',
    ENABLE_PAYMENT: 'enablePayment',
    ENABLE_PAYMENT_MYCART: 'enablePaymentMyCart',
    DISABLE_PAYMENT: 'disablePayment',
    GUEST_DETAIL_STATUS: 'guestDetailStatus',
    COOKIE_CLOSE: 'cookieClose',
    TOGGLE_GUEST_CHECKOUT: 'toggleGuestPass',
    TOGGLE_RECIPIENT_DETAILS: 'toggleRecipientDetails',
    CHECK_PAYMENT_ON_OFF: 'checkPaymentOnOff',
    UPDATE_HEADER_CART: 'updateHeaderCart',
    REFRESH_TABS: 'refreshTabs',
    WISHLIST_REFRESH: 'wishlistRefresh',
    OPEN_LIGHTBOX: 'openLightbox',
    DESTINATION_INTRO_CLOSE: 'destinationIntroClose',
    MINICARTEXIST: 'mincartexist',
    MINICARTUPDATED: 'minicartupdated',
    ONCARTPAYMENTVIEWEXPANDED: 'onCartPaymentViewExpanded',
    ONCARTPROMOVIEWEXPANDED: 'onCartPromoViewExpanded',
    ONPROMOCODEEXPANDED: 'onPromoCodeExpanded',
    ONCARTUPDATE: 'onCartUpdate',
    ONCARTUPDATEPOSTPAY: 'onCartUpdatePostPay',
    ONCARTUPDATETABBY: 'onCartUpdateTabby',
    ON_ADDING_PROMOCODE: 'onAddingPromoCode',
    ON_REMOVING_PROMOCODE: 'onRemovingPromoCode',
    TOGGLE_GUEST_CHECKOUT_ANNUALCASE: 'toggleGuestCheckoutAnnualCase',
    UPDATE_MYCART_CARTDATA: 'updateMyCartData',
    ticketLinkedEvent: 'PassLinkedSuccessfully',
    fetchAnnualPasses: 'FetchAnnualPasses',
    TIGGER_ANNUAL_PASS: 'tiggerAnnualPass',
    CHECK_COOKIE_POSITION: 'CHECK_COOKIE_POSITION',
    STEP_2_STICKY_CTA_WITH_COOKIES_EXIST: 'step2StickyCTAWithCookiesExist', // this CTA will be sticky in mobile from bottom 60px when cookies is enable
    PURCHASEJOURNEYDATA: 'PurchaseJourneyData',
    CONFIRMATION_PAGE_REFRESH: 'onConfirmationPageRefresh',
    GET_RECIPIENT_EXTRA_PARAMS: 'getRecipientExtraParams',
    UPDATE_CART_CALL_COMPLETED: 'updateCartCallCompleted',
    MAX_QUANTITY_GEN_ADM: 'quantityExceedLimit',
    CORP_EMAIL_SUBMIT: 'corpEmailSubmit',
    ERROR_PROCEED_ELIGIBILITY: 'errorProceedEligibility',
    specialNotesDataPublish: 'specialNotesDataPublish',
    PUBLISH_YI_CART_LABELS: 'PUBLISH_YI_CART_LABELS',
    DISABLE_INLINE_SIGNUP: 'DISABLE_INLINE_SIGNUP',
    EXPRESS_CHECKOUT_ENABLED: 'EXPRESS_CHECKOUT_ENABLED',
    EXPRESS_CHECKOUT_ENABLED_ANNUAL_PASS: 'EXPRESS_CHECKOUT_ENABLED_ANNUAL_PASS',
    EXPRESS_CHECKOUT_AP_LABELS: 'EXPRESS_CHECKOUT_AP_LABELS',
    EXPRESS_CHECKOUT_PP_SIGNUP: 'EXPRESS_CHECKOUT_PP_SIGNUP',
    EMAIL_VERIFIED: 'EMAIL_VERIFIED',
    EXPRESS_CHECKOUT_B2C_MOBILE_CART_OPENED: 'EXPRESS_CHECKOUT_B2C_MOBILE_CART_OPENED',
    PUBLISH_ORDER_DETAILS: 'PUBLISH_ORDER_DETAILS',
    PUBLISH_ORDER_STATUS_ONCE: 'PUBLISH_ORDER_STATUS_ONCE',
    ANNUAL_PASS_FORM_SUCCESS: 'ANNUAL_PASS_FORM_SUCCESS',
    MAX_CART_QUANTITY: 'MAX_CART_QUANTITY',
    UPDATE_CART_COUNTER: 'UPDATE_CART_COUNTER',
  },
  partnersApi: {
    activeKeyword: 'active',
    companyNameKey: 'name',
    preferenceChecked: 'Y',
    preferenceUnChecked: 'N',
  },
  guestCheckoutForm: {
    country: 'country',
    emirate: 'emirate',
    File: 'File',
  },
  limitErrorsKeys: {
    postPayLimit: 'postPayLimit',
    tabbyLimit: 'tabbyLimit',
  },

  localStoreKeys: {
    loggedInCount: 'logged_in_count',
    cartError: 'cart_error',
    postPayLimitError: 'postpay_limit_error',
    tabbyLimitError: 'tabby_limit_error',
    payment: {
      cmsData: 'cms_data',
      paymentInfo: 'payment_info',
      payFortData: 'payfort_data',
      errorMessage: 'error_message',
      gpayPaymentData: 'gpay_payment_data',
      applePayReconcileError: 'applepay-reconcile-error',
    },
    purchaseJourney: {
      anonymousCart: 'anonymousCart',
      cartData: 'cartData',
      orderStatus: 'orderStatus',
      purchaseStatus: 'purchaseStatus',
      recipientDetails: 'recipientDetails',
      partnerSegmentPromo: 'isPartnerSegmentPromo',
      applypartnerSegmentPromo: 'applyPartnerSegmantPromo',
      limitExceededOverlay: 'limitExceededOverlay',
      futureDateOverlay: 'futureDateOverlay',
      visitedDate: 'visitedDate',
      isBulkPurchaseAllowed: 'isBulkPurchaseAllowed',
      maxTicketsAllowed: 'maxTicketsAllowed',
      maxTickets: 'maxTickets',
      b2bCheckoutQuantity: 'b2bCheckoutQuantity',
      giftVoucher: 'giftVoucher',
      disableDownloadButton: 'disableDownloadButton',
    },
    USER_BLOCK_CHECKED: 'userBlockChecked',
    emailInvoiceTemplateId: 'emailInvoiceTemplateId',
    isInlineSignUpForm: 'isInlineSignUpForm',
    yasIdUserData: 'yasIdUserData',
    CORPORATE_EMAIL: 'corporateEmail',
    b2bTotalQuantity: 'b2bTotalQuantity',
    expressCheckoutPostPurchaseUser: 'expressCheckoutPostPurchaseUser',
    expressCheckoutLabels: 'expressCheckoutLabels',
    expressCheckoutConfirmationSummaryRefreshed: 'expressCheckoutConfirmationSummaryRefreshed',
    emailVerification: {
      emailVerifLinkSent: 'emailVerifLinkSent',
      emailSent: 'emailSent',
      emailTriggered: 'emailTriggered',
      ClosedEmailVerificationBanner: 'ClosedEmailVerificationBanner',
    },
    cartCount: 'cartCount',
  },
  sessionStorageKeys: {
    MY_CARD_SUCCESS: 'myCartSuccess',
    GUEST_DETAIL_SUCCESS: 'guestDetailSuccess',
    RECIPIENT_DETAIL_SUCCESS: 'recipientDetailSuccess',
    ANNUAL_PASS_FORM_SUCCESS: 'annualPassFormSuccess',
    USER_BLOCKED: 'userBlocked',
  },
  querStringParams: {
    thankYouPage: {
      tokenName: 'token_name',
      status: 'status',
      reference: 'ref',
      orderId: 'oId',
      responseCode: 'response_code',
      eventId: 'eventId',
    },
    payfort: {
      fortId: 'fort_id',
      reservationCode: 'res_code',
      responseCode: 'response_code',
      paymentError: 'payment_error',
      authorizationCode: 'authorization_code',
    },
    hotelBookingWidget: {
      promoCode: 'promocode',
    },
    paymentErrorQuery: {
      paymentError: 'payment_error',
      paymentType: 'payment_type',
    },
  },
  payfort: {
    threedUrlKey: '3ds_url',
    status: {
      tokenizationSuccess: '18',
      reconcileSucccess: '14',
      paymentSuccess: '20',
      authorizationSuccess: '02',
    },
    payfortTypes: ['CreditCard', 'Tabby'],
    payfortTypesMap: {
      Tabby: { name: 'form1', id: 'form1' },
      CreditCard: { name: 'payfort', id: 'payfortForm' },
    },
  },
  calendar: {
    dateFormat: 'YYYY-MM-DD',
    AnnualPassValidityDateFormat: 'DD MMM, YYYY',
    slashDateFormat: 'YYYY/MM/DD',
    spaceDateFormat: 'dd MMM yyyy',
    spaceDateforLabelFormat: 'dddd, DD MMMM YYYY',
    timeFormat: 'HH:mm',
    hotelBookingWidget: {
      notesConfigDateFormat: 'MMYYYY',
    },
    day: 'day',
    days: 'days',
    minutes: 'minutes',
    months: 'months',
    seconds: 'seconds',
  },
  moduleName: {
    cart: 'My Cart',
    myOrder: 'My Order',
    paypal: 'PayPal Payment',
    payfort: 'Payfort Payment',
    guestDetailsForm: 'Guest Details Form',
  },
  dateDisplayFormat: 'DD/MM/YYYY',
  errorCodes: {
    emptyCart: 'APIC001',
    emptySession: 'APIS001',
    noTimeSlots: '7001',
    updateFail: 'UPDATEFAIL',
    invalidQuantity: 'INVALIDQTY',
    invalidCartQuantity: 'INVALIDCARTQTY',
    invalidTimeSlot: 'INVALIDTIMESLOT',
    custom: 'CUSTOM',
    deleteFail: 'DELETEFAIL',
    commGetFail: 'COMMFAIL',
    noProductFound: 'COVEONOPRODUCT',
    notFound: '404',
    coveoProdNotFound: 'PRODNOTFOUND',
    disabledProduct: 'DISABLEDPRODUCT',
    disabledCart: 'DISABLEDCART',
    invalidCaptcha: 'CRM002',
    giftVoucherError: 'GIFTVOUCHERERROR',
    timeslotWarning: 'TIMESLOTWARNING',
    paymentProcessed: 'APIPAY14',
    paymentProgress: 'APIPAY00',
    lowQuantityErrorCode: '7002',
  },
  errorClassNames: { serverError: 'server-error' },

  locationSelectorType: {
    varient1: 'expanded',
    varient2: 'collapsed',
  },
  dashboardSummaryDefaultMonth: 3,
  InlineSignUpForm: 'InlineSignUpForm',
  loader: {
    enableLoader: true,
    //changing to container since there is no page-container class in new implementation
    defaultPreLoaderTarget: '.container',
    bodyPreLoaderTarget: 'body',
    contactusDefaultPreLoaderTarget: '.container',
    ariaLabel: 'Loading',
  },
  loaderImage: {
    saadiyatIsland: '/SaadiyatIsland-dist/img/loader.gif',
  },
  loggingTimeInterval: 5 * 60 * 1000,
  checkLogCount: 2,
  LoggingEnabled: true,
  apiTimeout: 90000,
  formScrollTime: 1000,
  serverErrorDebounce: 1000,
  ajaxCallDebounce: 250,
  headerOffset: 20,
  emailValidationTime: 2000,
  orderStatus: {
    All: 'All',
    Pending: 'Pending',
    Approved: 'Approved',
    Declined: 'Declined',
    Archived: 'Archived',
    AllStatus: ['Pending', 'Payment Failure', 'System Failure'],
  },
  dashboard: {
    creditType: 'creditBalance',
    avgAmountType: 'averageOrderAmount',
  },
  imageLazyLoad: true,
  imageLazyLoadOffset: 150,
  roleChangeTimeOut: 2000,
  encryptedParams: {
    ssa: {
      partnerId: 'param1',
      companyName: 'param2',
    },
    others: {
      agentId: 'param1',
      role: 'param2',
      partnerId: 'param3',
    },
  },
  eventListingFilter: {
    yaFilterVariant: 'v-yasarena-filter',
    yaEventStaticTheme: 'Static_Listing_Theme',
  },
  contactUs: {
    yaClasses: {
      textareaMsg: 'ya-text-area-message',
    },
  },
  b2c: {
    addonsMessage: {
      addonsItems: 'addons',
      bundleAddonsItems: 'bundleAddons',
      loggedInUserMsg: 'mypass',
      withoutLoggedInUserMsg: 'guest',
    },
    purchaseJourney: {
      annualPass: 'annual_pass',
      tabs: {
        general_admission: ['REG', 'MUP'],
        annual_pass: 'ANP',
        fast_pass: 'PER',
      },
      ymcdateTimeFormat: 'DD MMM, YYYY HH:mm',
      ymcArabicDateTimeFormat: 'HH:mm YYYY, MMM DD',
      dateFormat: 'DD MMM, YYYY',
      swadDateFormat: 'DD/MM/YY',
      yaDateFormat: 'MMM DD YYYY',
      yaDateFormatFull: 'YYYYMMDD',
      yaDateTimeFormate: 'YYYYMMDD[T]HHmmSS',
      yaDayFormat: 'dddd',
      yaDayDateFormat: 'dddd, DD MMM YYYY',
      yaPurchaseDateFormat: 'ddd, MMM DD, YYYY',
      yaTimeFormat: 'HH:mm:ss',
      yaReqTimeFormat: 'HH:mm',
      arabicDateFormat: 'YYYY, MMM DD',
      cartVariant: {
        miniCart: 'minicart',
        myCart: 'mycart',
        cartSummary: 'cartsummary',
        cartPaymentView: 'cartpaymentview',
        couponcodeminicart: 'couponcodeminicart',
        yasArenaCart: 'cart-view-seatselection',
      },
      paymentVariants: {
        is2StepPayment: 'v-Payment-2Step',
        is2StepPaymentYasArena: 'v-Payment-2Step-Ya',
        yaGuestCheckout: 'v-form-view-checkout-ya-2step', // Yas Arena Guest Checkout Variant.
      },
      mediaCarousel: {
        yaCarouselLazyLoadVariant: 'v-carousel-lazyload',
      },
      yasArenaCart: {
        localStorageKeys: {
          yasArenaSeatInfo: 'seatInfo',
          yasArenaEventId: 'eventId',
          yasArenaEventDetails: 'eventDetails',
          yasArenaPerformanceDetails: 'performanceDetails',
          yasArenaVisitedEvents: 'visitedEvents',
          yasArenaCreateOrder: 'createdOrder',
          yaStore: 'yasArena_store',
          addOnsAvailability: 'addOnsAvailability',
          staticUrlDetail: 'staticUrlDetail',
          promotionDetail: 'promotionDetail',
          yasArenaEventTitle: 'yasArenaEventTitle',
          yasArenaCartEventTitle: 'cartEventTitle',
        },
        overlayModes: {
          change: 'change',
          remove: 'remove',
        },
        callerPage: {
          payment: 'payment',
          multidateEvent: 'multidateEvent',
        },
      },
      yaTourOverlay: {
        add: 'ADD',
        update: 'UPDATE',
      },
      confirmationSummaryVariant: {
        step_two: 'v-confirmation-summary-logo',
        ya_step_two: 'v-ya-order-confirmation',
        ymc_step_two: 'v-ymc-booking-confirmation-summary',
      },
      editorialGridVariant: {
        step_two: 'v-overlay-view',
        ya_footer: 'ya-editorialgrid-footer',
      },
      ymcMapping: {
        eventType: {
          f1: 'f1',
          wrx: 'wrx',
        },
        seatType: {
          nonSeated: 'nseat',
          seat: 'seat',
        },
        localStorageKey: {
          seatedCheckoutOrderId: 'seatedCheckoutCreateOrderId',
          shippingAddress: 'seatedShippingAddress',
          billingAddress: 'seatedBillingAddress',
          seatedOrderDetails: 'seatedOrderDetails',
          seatedCheckoutSalesAK: 'seatedCheckoutSalesAK',
        },
        dispatchValue: 'dispatch',
        engLangCode: 'en',
        crossSellType: {
          FEB: 'feb',
          ARC: 'arc',
        },
        pubSubKey: {
          seatedPaymentError: 'seated-payment-error',
        },
        emptyCart: 'emptycart',
        emptyRecommendation: 'emptyrecommendation',
      },
      additionalProductCode: 'AD',
      recommendationTabCode: 'recommendation',
      swadRecommendationTabCode: 'crosssell_recommendation',
      pastPurchaseRecommendationTabCode: 'ya_past_purchase_addon',
      pastPurchasedTabCode: 'postrecommendation',
      postRecommendationContent: 'Post Purchased',
      postPurchasedIdType: 'PostPurchased',
      postSubEventFB: 'FEB',
      coveoResultsPerPage: 999,
      touchPoint: {
        purchaseInfo: 'touch_points_purchase_info',
        paymentType: {
          full: 'FULL',
          partial: 'PARTIAL',
        },
      },
      moneyCard: {
        purchaseInfo: 'money_card_purchase_info',
        paymentType: {
          full: 'FULL',
          partial: 'PARTIAL',
        },
      },
    },
    profile: {
      dateFormat: 'YYYY-MM-DD',
      mobileFormat: 'YYYY-MM-DD',
      dateDisplayFormat: 'DD/MM/YYYY',
      purchase: {
        dateTimeFormat: 'DD-MM-YYYY hh:mm:ss',
        dateDisplayFormat: 'MMM D, YYYY',
        yaDateDisplayFormat: 'MMM DD, YYYY',
        addressSortDateFormat: 'MM-DD-YYYY hh:mm:ss',
      },
    },
    footerVariants: {
      yaFooter: 'v-ya-footer',
      yaTextWithCta: 'v-cta-ya-footer',
      miniFooter: 'v-mini-footer',
    },
    flowType: {
      longName: {
        staycation: 'Staycation',
        daycation: 'Daycation',
        hotelonly: 'Hotel Only',
      },
      shortName: {
        daycation: 'DCT',
        hotelonly: 'HO',
      },
    },
  },
  deviceType: {
    android: 'Android',
    ios: 'IOS',
  },
  invoiceDetail: 'invoiceDetail',
  allowedClassType: ['ADULT', 'JUNIOR'],
  googleMap: {
    defaultIcon,
    startIcon,
    endIcon,
  },
  form: {
    selectWithText: {
      imageUrl: 'https://feb2cstaticpagestacn.blob.core.windows.net/webimages/common/Platform/Flag',
    },
    isEmptyValue: 'empty-key',
  },
  defaultCookieExpireTime: 43200,
  cookieVariantTypes: {
    cookiePrivacyPolicyVariant: 'privacy-policy',
    cookieAnnoucementNotification: 'announcement-notification',
  },
  heroCarouselSetting: {
    dots: false,
    arrows: true,
    autoplay: false,
    rtl: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infiniteScroll: false,
  },
  packageType: 'Hotel',
  ymcB2CTenant: 'ymcb2c',
  YIB2C: 'yib2c',
  SWADB2C: 'swadb2c',
  YI: 'yi',
  YAS: 'YAS',
  yasArenaB2CTenant: 'YAB2C',
  tenants: {
    fwad: 'fwb2c',
    wbw: 'wbwb2c',
    yww: 'ywwb2c',
    ymc: 'ymcb2c',
    ya: 'yab2c',
    b2b: 'allb2b',
    ppad: 'ppb2c',
    clymb: 'clymbb2c',
    swadb2c: 'swadb2c',
    yi: 'yib2c',
  },
  geoLocation: {
    httpsUrl: '/sitecore/api/forms/GetIpLocationDetail',
  },
  b2b: {
    purchaseJourney: {
      b2bCreditsettlement: 'b2bCreditsettlement',
      b2bAdvanceTopUp: 'b2bAdvanceTopUp',
    },
  },
  languages: {
    ar: 'ar-AE',
    arLang: 'ar',
    arSA: 'ar-sa',
    enLang: 'en',
    enGB: 'en-gb',
  },
  annualPassesTypes: {
    windowHash: '#manageannualpass',
    activePass: 'Active Passes',
    renewPass: 'Renew Passes',
    linkannualpass: 'Link Annual Pass',
    expired: 'expired',
    active: 'active',
    hashMapping: {
      activepasses: 'Active Passes',
      renewpasses: 'Renew Passes',
      linkannualpass: 'Link Annual Pass',
    },
  },
  emailVerification: {
    heroPanel: {
      source: {
        myProfile: 'my-profile',
        emailVerification: 'email-verification',
      },
    },
  },
  labels: 'Labels',
  coveSettings: 'CoveoSettings',
  productTypeForAnnualPasses: {
    annualPass: 'Annual Pass',
    multiParkAnnualPass: 'Multi Park Annual Pass',
  },
  annualpassSessionCart: 'cartForAnnualPassRenewal',
  annualPassPurchaseCart: 'cartForAnnualPassPurchase',
  annualPassRenewalData: 'annualPassRenewalData',
  isAnnualPassInlineSignUpForm: 'isAnnualPassInlineSignUpForm',
  payloadForRenew: 'payloadForRenew',
  imagePrefix: 'data:image/jpeg;base64,',
  gtmStepOne: 1,
  gtmStepTwo: 2,
  gtmStepThree: 3,
  gtmStepFour: 4,
  gtmStepFive: 5,
  gtmStepSix: 6,
  gtmStepSeven: 7,
  currencyArabic: 'AED',
  passengerItemCheck: ['pass', 'vip'],
  promotionalEvent: 'promotional',
  kgfSnpVariant: 'v-editorial-view-kgf-snp',
  heroWithAttributes: 'hero-with-attributes',
  eventSchedule: {
    yaDateFormat: 'ddd, MMM DD, YYYY',
    yaTimeFormat: 'HH:mm',
  },
  search: {
    resultsSubmit: 'ResultsSubmit',
  },
  featureTitle: {
    iamResetPassword: 'iam-reset-password',
    contentTeaserWrapper: 'content-teaser-wrapper',
    imageLeft: 'image-left',
  },
  loginCta: {
    html: '',
    href: '',
    url: '',
    label: 'Login',
    title: 'Login',
    error: 'We had trouble signing you in. Please try after some time.',
    primaryCTA: 'OK',
  },
  logoutCta: {
    html: '',
    href: '#',
    url: '#',
    label: 'LOGOUT',
    title: 'LOGOUT',
  },
  profile: {
    ctaLinks: [
      {
        html: '',
        href: '/en/yasisland/myprofilepage',
        url: '/en/yasisland/myprofilepage',
        label: 'Profile',
        title: 'Profile',
      },
    ],
    bodyCopy: 'Welcome',
    userName: '',
    CORP_USER_TYPE: {
      ENABLED: 'enabled',
      DISABLED: 'disabled',
      DELETED: 'deleted',
    },
    sectionType: {
      personal: 'guest-detail',
      communication: 'communication-prefrence',
      family: 'family-member-section',
      submitPref: 'my-info-submit',
      submitComm: 'comm-pref-submit',
      configSection: 'config-section',
      membership: 'membership-details',
      corporateMembership: 'corporate-membership-details',
      createCorporateMember: 'create-corporate-member',
      inactiveCorporateMember: 'inactive-corporate-member',
      emailSubmit: 'corporate-email-submit',
    },
    YASGIFTS: 'YASGIFTS',
  },
  heroPanelVariations: [
    'no-tiles',
    'v-heropanel-view',
    'v-hero-panel-fullBleed',
    'profile-tabs',
    'b2b-no-tiles',
    'v-email-verification',
  ],
  rsaEncKeys: {
    public: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDAaf3yvgNA0yeenFiAQRBOJsPE
s/4jWjaam7afuW6Hj6Z3QqCq7iZbqNE80udccMuLOredQDNqmzCSzKHjN9ogm1EY
s+7uhS3Xpz7WfjrTireQizVTqkdoJnN4unPWrUMzn/8vC7rZanmBlX/Yag1IP19X
X6VU6i5ZaYzoslYMbQIDAQAB`,
  },
  socialShareVariation: 'v-social-share',
  paymentMethod: {
    payFort: 'payfort',
    aliPay: 'alipay',
    pointsPay: 'pointspay',
    gPay: 'gpay',
    postpay: 'postpay',
    tabby: 'tabby',
  },
  jssForms: {
    events: {
      formSubmissionError: 'formSubmissionError',
      jssFormSettingsData: 'jssFormSettingsData',
      formSubmissionApiError: 'formSubmissionApiError',
    },
    variant: {
      B2CBookDate: 'B2C-bookDate',
      B2CContactUs: 'B2C-contactUs',
      B2CUserNotLoggedIn: 'v-UserNotLoggedIn',
    },
    saveSiteCoreData: '{0C61EAB3-A61E-47B8-AE0B-B6EBA0D6EB1B}',
    fields: {
      checkboxId: '{BA8D2700-AB22-4CE5-909C-94621391D72D}',
      selectWithTextId: '{5BB2A4FA-5BAE-4D57-ACC8-648C737637B7}',
      buttonId: '{676F3573-800F-48C8-B30B-A6F6BB9A0FD9}',
      calendarId: '{41B13162-30C4-46CC-A874-53E0FB427D84}',
      textFieldId: '{73A32952-B04F-4E75-B490-DA0B0DC0A46B}',
      dropDownId: '{B20D0C57-904D-42BE-8A69-B089FB39876B}',
      hiddenTypeId: '{761F1C4B-13FE-45B6-88F3-B3C9D21E7F1B}',
      emailConfirmationId: '{72B22999-8509-49A1-BD0C-04D68A9131CD}',
      captchaTypeId: '{906D522F-4D87-4CEF-853F-2FE69E5D3A00}',
      recaptchaTypeId: '{23CF8995-4452-4CED-A8C4-33A580B2119E}',
    },
    operators: {
      '{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}': '=',
      '{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}': '!=',
      '{BF8935A6-1976-43A0-ABA5-D0BC128A76EA}': 'contains',
      '{45AAB0FB-775B-40F5-B3B8-7CAE3ABBF643}': 'doesNotContain',
      '{FD10F291-3C2E-4AE7-8A67-2F8271CB3DF2}': 'startsWith',
      '{6B92597D-F2E0-47D3-A40D-59AFB37EEDE5}': 'doesNotStartWith',
      '{D375ED5B-E156-4A2B-9F91-DFD5B03F0D78}': 'endsWith',
      '{F3AC7A1A-3458-4385-BB65-860315313DB3}': 'doesNotEndWith',
      '{61FF63A0-375C-47BD-9986-1F81BD12BBBB}': '>',
      '{062C6ED9-EA6E-4A88-AE54-C88E2147971D}': '>=',
      '{8FE41E53-AD87-4D24-B50F-EA0F6BDF739F}': '<',
      '{88AC1C6B-BAFE-40A7-BB75-E304C8EC29DD}': '<=',
    },
    actions: {
      '{AAE07A52-46A4-49EF-98B0-C2595BAC2382}': 'show',
      '{7F58C8DD-D7C0-4FB7-BB44-8EC6B5E1C3D9}': 'hide',
      '{5744A87E-E32C-42CC-862F-96842A0202BB}': 'enable',
      '{C698C993-549E-486A-A09C-BB8D830DA958}': 'disable',
      '{4E448D57-BA06-42DC-9519-6BCD102CB332}': 'goToPage',
    },
    calender: {
      pastDate: 'pastDate',
      futureDate: 'futureDate',
    },
    formType: {
      getQuote: 'getQuote',
      getHealthNFitnessRegistration: 'getHealthNFitnessRegistration',
      getWrxPreRegistration: 'getWrxPreRegistration',
      getContactUs: 'getContactUs',
    },
    errorConstant: {
      required: 'required',
      emailConfirmation: 'emailconfirmation',
      captcha: 'captcha',
    },
    fieldName: {
      confirmEmailFieldName: 'ConfirmEmail',
      weightInKg: 'WeightInKg',
      heightInCm: 'HeightInCm',
    },
    customSections: {
      cascade: 'Cascade',
      ageValidator: 'AgeValidator',
      bmi: 'BMI',
    },
    emailFlagValue: 2,
  },
  staticPlaceholdersMapping: {
    yasisland: 'yi',
    yww: 'yww',
    fwad: 'fwad',
    wbw: 'wbw',
    ymc: 'ymc',
    saadiyat: 'si',
    etihadarena: 'ya',
    ppad: 'ppad',
    clymb: 'clymb',
    b2b: 'b2b',
    swad: 'swad',
    rrc: 'rrc',
  },
  iamMapping: {
    yasisland: 'yasisland',
    yww: 'yww',
    fwad: 'fwad',
    wbw: 'wbw',
    ymc: 'ymc',
    saadiyat: 'saadiyat',
    etihadarena: 'ya',
    ppad: 'ppad',
    clymb: 'clymb',
    b2b: 'b2b',
    swad: 'swad',
    rrc: 'rrc',
  },
  coveoMapping: {
    yasisland: 'YI',
    yww: 'YWW',
    fwad: 'FW',
    wbw: 'WBW',
    ymc: 'YMC',
    saadiyat: 'SI',
    etihadarena: 'YA',
    ppad: 'PPAD',
    clymb: 'CLYMB',
    b2b: 'B2B',
    swad: 'SWAD',
    rrc: 'RRC',
  },
  multiColumnClassMapping: {
    'jss-col-1': 'left-column',
    'jss-col-2': 'right-column',
  },
  isDefaultContent: true,
  ymcDefaultBgColor: '#717073',
  ymcArticleImgSize: {
    mobilesz: 190,
    tabletsz: 280,
    desktopsz: 370,
  },
  ymcInfoBoxImgSize: {
    mobilesz: 180,
    tabletsz: 450,
    desktopsz: 500,
  },
  component: {
    heroPanel: 'HeroPanel',
    collapsibleContent: 'collapsiblecontent',
  },
  yasislandVariant: {
    NOCHILD: 'no-child',
  },
  f1Listing: {
    SECTORS: 'sectors',
  },
  textWithCtaVariants: {
    leftVariant: 'v-textwithcta-view-left',
  },
  dyanmicPricingVariant: 'v-dynamic-product-pricing',
  B2BCabana: {
    CAB4: 'CAB4',
    CAB6: 'CAB6',
    Dynamic: 'Dynamic',
  },
  moneyCardProduct: {
    flexibleMoneyCard: 'flexibleMoneyCard',
    ticketType: 'MONEYCARD',
    flexibleTicketType: 'MCARD',
    productType: 'MoneyCard',
  },
  membershipDetails: {
    HAW: 'haw',
  },
  // Guest Checkout Addon
  guestCheckoutDetails: {
    guestCheckoutLogin: 'guestCheckoutLogin',
    manageBookingForm: 'managebooking',
  },

  // Login Form
  isLogInSignUp: 'LogInSignUp',
  // YIR Variants

  commonVariant: {
    headerYASTenantVariant: {
      listingHeaderVariant: 'listingVariant',
      checkoutHeaderVariant: 'checkoutVariant',
    },
    defaultcurrencyArabic: 'AED',
    variant: 'yasisland',
    headerVariant: 'v-header-yasisland',
    transparentVariant: 'transparentVariant',
    multiMegaNavHeaderVariant: 'v-header-multimeganav',
    b2cHeaderVariant: 'v-header-b2c',
    yasArenaR1HeaderVariant: 'v-ya-header-r1',
    yasArenaHeaderVariant: 'v-ya-header',
    footerVariant: 'v-footer-yasisland',
    checkoutVariant: 'v-form-view-checkout',
    bookingWidgetVariant: 'v-BookingWidget_TransparentBackground',
    bookingWidgetOnlyStartDate: 'v-BookingWidget_OnlyStartDate',
    listOfMorePackagesWithFilter: 'v-yasisland-list-of-more-packages',

    photoCollageThemeParkVariant: 'yi-photo-collage-theme-park-slider',
    searchVariant: 'v-search',
    b2cSearchVariant: 'nav-item-search-b2c',
    bookingconfirmationVariant: 'v-bookingconfirmation-view',
    manageBookingVariant: 'v-managebooking-view',
    specialnotesVariant: 'v-hotel-specialnotes',
    SpecialNotesFieldName: 'SpecialNotes',
    autoSuggestSearchVariant: 'search-autosuggest-custom-styles',
    mediaGalleryFullBleedVariant: 'v-media-gallery-full-bleed',
    removePaginationZeroVariant: 'remove-pagination-zero',
    yasArenaTwoImageCarousalVariant: 'ya-two-image-carousal',
    carouselNopaddingVariant: 'v-carousel-nopadding',
    heroCarouselVariant: 'hero-carousel',
    heroCarouselYIVariant: 'hero-carousel-yi',
    carouselNoCTAVariant: 'v-carousel-nocta',
    gaClickEvents: {
      buttonClick: 'button click',
      imageClick: 'image click',
      linkClick: 'link click',
    },
    sliderVariant: {
      WithGryedBackground: 'WithGryedBackground',
    },
    kgfCarouselWithCtaVariant: 'kgf-snp-cta',
  },
  swadVariant: {
    headerYASTenantVariant: {
      listingHeaderVariant: 'listingVariant',
      checkoutHeaderVariant: 'checkoutVariant',
    },
    autoSuggestSearchVariant: 'search-autosuggest-custom-styles',
    yasArenaR1HeaderVariant: 'v-ya-header-r1',
    variant: 'swad',
    headerVariant: 'v-header-swad',
    multiMegaNavHeaderVariant: 'v-header-multimeganav',
    footerVariant: 'v-footer-swad',
    photoCollageThemeParkVariant: 'swad-photo-collage-theme-park-slider',
    quickLinksOneLine: 'v-quick-links-one-line',
  },
  FEEDBACK_LINK_LABEL: 'feed-back',
  CORPORATE_ENROLLMENT: 'CorporateEnrollment',
  CORP_VERIFY_URL_IDENTIFIER: '/corporate-enrollment?corporateEmailId=',
  CORP_SUCCESS_CODE: 'CE200',
  CORPORATE: 'CORPORATE',
  USER_SELECTED_CHOICE: 'USER_SELECTED_CHOICE',
  STAYCATION_USER_SELECTED_CHOICE: 'STAYCATION_USER_SELECTED_CHOICE',
  DAYCATION_USER_SELECTED_CHOICE: 'DAYCATION_USER_SELECTED_CHOICE',
  cookiesExpireMs: 41760,
  h_mm_a: 'h:mm a',
  h_ma: 'h:mma',
  h_a: 'h a',
  h_ha: 'ha',
  iso_date: 'YYYY-MM-DD[T]HH:mm:ss',
  cartErrorCodesForPayment: 'CartErrorCodesForPayment',
  annualPassSavedChanges: 'annualPassSavedChanges',
  reSizeMapComponent: 'reSizeMapComponent',
  swapMapView: 'swapMapView',
  headerCartClick: 'headerCartClick',
  crossSellTxt: 'Cross Sell',
  upSellText: 'Up Sell',
  regularText: 'Regular',
  postPurchaseCrossSellTxt: 'Post Purchase Cross Sell',
  AddedToCart: 'AddedToCart',
  failedTransaction: 'failedTransaction',
  ticketTypeJunior: 'junior',
  CMPconsent: {
    confirmed: 'CONFIRMED',
    withdrawn: 'WITHDRAWN',
    email: 'email_consent',
    phone: 'phone_consent',
    sms: 'sms_consent',
    whatsApp: 'whatsApp_consent',
    website: 'website_consent',
    mobile: 'mobile_consent',
  },
  formFields: {
    type: {
      HIDDEN: 'Hidden',
    },
    id: {
      PASS_TYPE: 'passType',
      PRODUCT_DESC: 'productDescription',
    },
  },
  newsletter: {
    channelName: 'channelName',
    channelType: 'Email',
    contentType: 'contentType',
  },
};

export default UIConfig;
