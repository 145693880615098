import React, { memo, useEffect, useRef } from 'react';
import TicketTooltip from '../../b2c-purchase-journey/ticket-tooltip/ticket-tooltip';
import { DynamicContent, Image } from '../../base';
import { logComponentRenderingError } from '../../../../common/logger';
import { checkParksTenants, checkTenant, getLoggedInUser } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GTMData from '../../../container/b2c-purchase-journey/gtm-data';

const PassComponent = ({
  data,
  selectedTicket,
  handleOnClick,
  componentState,
  handelKeyDown,
  accordionID,
  ariaControls,
  ariaExpanded,
  handleKeyDownOnAccrodion,
  ariaLabel,
  renderSWADCounter,
  annualPassGridVariant,
  colorGradient,
  gaOnClick,
}) => {
  const gredientColorDiv = useRef(null);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const onClickHandle = (e) => {
    gaOnClick(data.ticket);
    if (!isSwad && !annualPassGridVariant) {
      handleOnClick(data.ticket.coveoValue, e.target);
    }
  };

  useEffect(() => {
    if (annualPassGridVariant && gredientColorDiv?.current && colorGradient) {
      gredientColorDiv.current.style.setProperty('--gradient-color', colorGradient);
    }
  }, []);

  try {
    const info = data.ticket && data.ticket.info;
    const Icons = data.ticket && data.ticket.Icons;
    const readMoreLabel = data.ticket && data.ticket.readMoreLabel;
    const { offerTag } = data.ticket;
    return (
      <>
        {isSwad && data?.ticket?.image && (
          <div className="pass-logo product-image-wrapper">
            {data.ticket.offerLabel && (
              <DynamicContent tagName="span" attrs={{ className: 'offer-label' }} innerHtml={data.ticket.offerLabel} />
            )}
            <Image image={data.ticket.image} disableLazyLoad={true} />
          </div>
        )}
        {annualPassGridVariant && (
          <>
            {colorGradient && <div className="offer-color" ref={gredientColorDiv} />}
            {offerTag && <DynamicContent tagName="h5" attrs={{ className: 'offer-tag' }} innerHtml={offerTag} />}
          </>
        )}
        <div
          className={`c-pass ticket-box pass${
            componentState !== 'noSelect' && selectedTicket === data.ticket.coveoValue ? ' selected' : ''
          } clearfix`}
          onClick={onClickHandle}
          id={accordionID}
          aria-controls={ariaControls}
          aria-expanded={ariaExpanded}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onClickHandle(e);
            }
          }}
          {...(!isSwad && !annualPassGridVariant && { tabIndex: '0' })}
        >
          {!isSwad && (
            <>
              <div className="border-design right"></div>
              <div className="border-design left"></div>
            </>
          )}
          <div className="pass-details">
            <div className="pass-container">
              {!data.ticket.image && data.ticket.offerLabel && (
                <DynamicContent tagName="div" attrs={{ className: 'offer-label' }} innerHtml={data.ticket.offerLabel} />
              )}
              <div className="pass-title">
                {data.ticket.productDetailLink && !isSwad ? (
                  <Text
                    tag="a"
                    href={data.ticket.productDetailLink}
                    className="heading-4"
                    field={{
                      value: data.ticket.title,
                      editable: data.ticket.title,
                    }}
                  />
                ) : (
                  <DynamicContent tagName="p" innerHtml={data.ticket.title} />
                )}
                {!annualPassGridVariant && data.ticket.subTitle && (
                  <DynamicContent tagName="span" attrs={{ className: 'sub-title' }} innerHtml={data.ticket.subTitle} />
                )}
              </div>
              {(isSwad || annualPassGridVariant) && data?.ticket?.prices && (
                <div className="pass-price-details">
                  {Array.isArray(data.ticket.prices) &&
                    data.ticket.prices.length > 0 &&
                    data.ticket.prices.map((option, index) => {
                      return (
                        <p key={index} className="body-2">
                          {option.offerPrice && option.isOfferActive ? (
                            <span className="currency offer-price">
                              {option.currency + ' '}
                              {isSwad
                                ? option.offerPrice.toLocaleString('en', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })
                                : option.offerPrice}
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className={option.isOfferActive ? 'active-offer currency' : 'currency'}>
                            {option.currency + ' '}
                            {isSwad
                              ? option.price.toLocaleString('en', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })
                              : option.price}
                          </span>
                        </p>
                      );
                    })}
                </div>
              )}
              <DynamicContent
                tagName="div"
                attrs={{ className: 'pass-description' }}
                innerHtml={data.ticket.description}
              />
              {isSwad && Icons && Icons.length > 0 && (
                <div className="parks-logo">
                  {Icons.map((icon) => (
                    <Image image={icon} disableLazyLoad={true} />
                  ))}
                </div>
              )}
              {isSwad && info && (info.description || info.title) && (
                <div className="ticket-includes-wrapper">
                  <DynamicContent
                    tagName="span"
                    attrs={{ className: 'ticket-includes-title' }}
                    innerHtml={info.title}
                  />
                  <DynamicContent
                    tagName="span"
                    attrs={{ className: 'ticket-includes-description' }}
                    innerHtml={info.description}
                  />
                </div>
              )}
              {isSwad && (data.ticket?.readMoreLink?.href || data.ticket.productDetailLink) && readMoreLabel && (
                <Text
                  tag="a"
                  target="_blank"
                  href={data.ticket?.readMoreLink?.href || data.ticket.productDetailLink}
                  className="read-more-link"
                  field={{ value: readMoreLabel }}
                />
              )}
              {!isSwad && !annualPassGridVariant && (
                <div className="pass-price-details">
                  {Array.isArray(data.ticket.prices) &&
                    data.ticket.prices.length > 0 &&
                    data.ticket.prices.map((option, index) => {
                      return (
                        <p key={index} className="body-2">
                          {option.for + ' ' + option.from}
                          <span className="currency"> {option.currency + ' ' + option.price}</span>
                        </p>
                      );
                    })}
                </div>
              )}
            </div>
            {!isSwad && !annualPassGridVariant && (
              <div className="pass-logo">
                <Image image={data.ticket.image} disableLazyLoad={true} />
              </div>
            )}
          </div>
          {!annualPassGridVariant && (
            <div className="checkbox-label" htmlFor={data.ticket.name}>
              <input
                type="radio"
                id={data.ticket.coveoValue}
                name="ticket-selection"
                value={data.ticket.title}
                checked={componentState !== 'noSelect' && selectedTicket === data.ticket.coveoValue}
                onChange={(e) => {
                  handleOnClick(data.ticket.coveoValue, e.target);
                }}
              />
              <div
                className="checkbox"
                id={data.ticket.coveoValue}
                tabIndex="0"
                aria-label={ariaLabel}
                role="radio"
                aria-checked={componentState !== 'noSelect' && selectedTicket === data.ticket.coveoValue}
              ></div>
            </div>
          )}
          {info && (
            <TicketTooltip title={info.title} subTitle={info.subTitle} description={info.description} tabIndex="0" />
          )}
          {renderSWADCounter}
        </div>
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'PassComponent');
  }
};

export default memo(PassComponent);
