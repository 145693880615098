/*
 * hero-card-wbw-components.js
 * This file contains code for Hero Card component, it renders hero cards
 * @licensor  Miral
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AnchorLink, DynamicContent, InlineSvg } from '../base';
import { isB2B, isTouchDevice } from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';

import './hero-tile.scss';

/**
 * Returning SVG Object - Accepting {Object for image details}
 * @param    {object}    img     having data of masked image
 */
const getMaskedSvg = (img, isB2BTenant) => {
  return (
    <div className="hero-tile--btm-img">
      {isB2BTenant ? (
        <img src={img.desktopImage.src} alt={img.desktopImage.alt} />
      ) : (
        <svg
          baseProfile="full"
          width="240"
          height="135"
          version="1.2"
          viewBox="0 0 240 135"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <mask id="tileMask" maskUnits="userSpaceOnUse" maskContentUnits="userSpaceOnUse">
              <path d="M141.024 135H240V0H0v135h98.496c2.125-9.148 10.838-16 21.264-16s19.14 6.852 21.264 16z" />
            </mask>
          </defs>
          <image mask="url(#tileMask)" width="100%" height="100%" alt={img.alt} xlinkHref={img.src} />
        </svg>
      )}
    </div>
  );
};

const HeroTile = ({ onclick, tiles }) => {
  const [isB2BTenant, setIsB2BTenant] = useState(false);

  useEffect(() => {
    setIsB2BTenant(isB2B());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!tiles || !tiles.length) {
    return null;
  }

  try {
    return (
      <ul className="list-wrapper">
        {tiles.map((item, index) => {
          const { cta, imageBottom, imageTop, title } = item;
          return (
            <li
              className={classNames('hero-tile', { 'touch-device': isTouchDevice() }, { 'show-tile': isB2BTenant })}
              key={index}
              onClick={() => onclick(item)}
            >
              <span className="cardCurves">
                <InlineSvg
                  icon={`${isB2BTenant ? 'hero-tile-without-curve' : 'hero-tile-curve'}`}
                  width="240"
                  height="366"
                  viewBox="0 0 240 366"
                />
              </span>
              <div className="hero-tile--content">
                {imageTop && (
                  <div className="hero-tile--top-img">
                    <img src={imageTop.desktopImage.src} alt={imageTop.desktopImage.alt} />
                  </div>
                )}
              </div>
              {imageBottom && getMaskedSvg(imageBottom, isB2BTenant)}
              <div className="hero-tile--desc">
                {title && <DynamicContent tagName="p" innerHtml={title} />}
                {cta && (
                  <div className="btn btn-secondary">
                    <AnchorLink link={{ ...cta }} />
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HeroTile');
  }
};

export default HeroTile;

HeroTile.propTypes = {
  onclick: PropTypes.func,
  tiles: PropTypes.array,
};
