//SHA: https://github.com/emn178/js-sha512

import { getErrorMap, removeAnonymousCart, removeRecipientDetails } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';

import sha512 from 'js-sha512';

class PayfortUtility {
  static sortObjectKeys = (obj = {}) => {
    return Object.keys(obj)
      .sort()
      .reduce((acc, key) => {
        if (Array.isArray(obj[key])) {
          acc[key] = obj[key].map(PayfortUtility.sortObjectKeys);
        }
        if (obj[key] !== null && typeof obj[key] === 'object') {
          acc[key] = PayfortUtility.sortObjectKeys(obj[key]);
        } else {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
  };

  static getPayFortSignature = (apiData, merchantPassPhrase) => {
    let data = PayfortUtility.sortObjectKeys(apiData);
    let signatureString = merchantPassPhrase;

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        signatureString += key + '=' + data[key];
      }
    }
    signatureString += merchantPassPhrase;

    return sha512.sha512(signatureString);
  };

  static clearLocalStore = () => {
    localStorage.removeItem('payment_info');
    localStorage.removeItem('payfort_data');
    localStorage.removeItem('isOrderHistoryPayment');
  };
  static clearAnonymousCart = () => {
    removeAnonymousCart();
    window.PubSub.publish(UIConfig.events.UPDATE_HEADER_CART, { totalQuantity: 0 });
  };

  static clearRecipientDetails = () => {
    removeRecipientDetails();
  };

  static getErrorObject = (serviceKey, services, responseOrError = null) => {
    let code = null,
      text = '',
      errors = {},
      errorData;
    if (responseOrError) {
      code = responseOrError.status || responseOrError.code;
      text = responseOrError.response_message || responseOrError.text;
    } else {
      code = 404;
      text = services.tokenization.errors['E01'];
    }

    if (responseOrError && responseOrError.response_code) {
      errorData = { code: responseOrError.response_code, text: text };
      errors = getErrorMap(serviceKey, services[serviceKey].errors, false, errorData, null);
    }

    errorData = { code: code, text: text };

    return getErrorMap(serviceKey, services[serviceKey].errors, false, errorData, errors);
  };
}

export default PayfortUtility;
