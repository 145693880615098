/*
 * hero-card-wbw-components.js
 * This file contains code for Hero Card component, it renders hero cards
 * @licensor  Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AnchorLink, DynamicContent, Image } from '../base';
import { logComponentRenderingError } from '../../../common/logger';
import './hero-card-component.scss';

const HeroCard = ({ cards }) => {
  if (!cards || !cards.length) {
    return null;
  }
  try {
    return (
      <div className="hero-card-container w--content clearfix">
        {cards.map((item, index) => {
          const { body, cta, image, shortTitle, titleDescription } = item;

          return (
            <div className={classNames({ 'single-card': cards.length <= 1 }, 'hero-card clearfix')} key={index}>
              <div className="card-image">{image && <Image image={image} />}</div>
              <div className="card-content">
                {shortTitle && cta && cta.href ? (
                  <a href={cta.href}>
                    <DynamicContent tagName="div" attrs={{ className: 'card-title' }} innerHtml={shortTitle} />
                  </a>
                ) : shortTitle ? (
                  <DynamicContent tagName="div" attrs={{ className: 'card-title' }} innerHtml={shortTitle} />
                ) : null}
                {body && <DynamicContent tagName="div" attrs={{ className: 'card-body' }} innerHtml={body} />}
                <div className="read-more">
                  <span id={`title-desc-${index}`} className="hide">
                    {titleDescription}
                  </span>

                  {cta && cta.label && (
                    <AnchorLink
                      link={{ ...cta, ariaLabel: `${cta.label} ${shortTitle}` }}
                      className="secondary-cta right-arrow"
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HeroCard');
  }
};

export default HeroCard;

HeroCard.propTypes = {
  cards: PropTypes.array,
};
