const _keyCodes = {
  Enter: 13,
  Esc: 27,
  Space: 32,
  Tab: 9,
  ArrowUp: 38,
  ArrowDown: 40,
};
export const KeyCodes = Object.freeze(_keyCodes);

const _keyCodeNames = {
  Enter: 'Enter',
  DownArrow: 'down-arrow',
  upArrow: 'up-arrow',
};
export const KeyCodeNames = Object.freeze(_keyCodeNames);

const _mouseEvents = {
  MouseDown: 'mousedown',
  Click: 'click',
};
export const MouseEvents = Object.freeze(_mouseEvents);

export const _touchEvents = {
  TouchStart: 'touchstart',
};
export const TouchEvents = Object.freeze(_touchEvents);

export const _keyboardEvents = {
  Keydown: 'keydown',
};
export const KeyboardEvents = Object.freeze(_keyboardEvents);

const _environments = {
  Production: 'production',
  Development: 'development',
};
export const Environments = Object.freeze(_environments);

const _logLevels = {
  Error: 'error',
  Warning: 'warning',
};
export const LogLevels = Object.freeze(_logLevels);
