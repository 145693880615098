/*
 * cookie-component.js
 * This file contains code for Cookies

 * @licensor  Miral 2.0
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import UIConfig from '../../../common/UIConfig';
import {
  canUseDOM,
  detectViewPort,
  getCookie,
  getBaseURL,
  isEnterPressed,
  navigateToSkipLink,
  setCookie,
  resolvePath,
  getLoggedInUser,
  getLoginUser,
  isEmailVerificationEnabled,
} from '../../../common/utility';
import { AnchorLink, DynamicContent, SvgSprite } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';
import { bodyClicks } from '../../../common/analytics-events';
import ApiWrapper from '../../../common/api-wrapper';

const isAuthorizedAgentOverlay = (variant) => variant === 'v-authorized-overlay';

const Cookie = ({ data }) => {
  const { closeBtnLabel, ctaLabel, daysToExpire, description, title, variant, imagelogo, ctaLink } = data;
  const isCookiePrivacyPolicyVariant = variant === UIConfig.cookieVariantTypes.cookiePrivacyPolicyVariant;
  const isCookieAnnoucementNotification = variant === UIConfig.cookieVariantTypes.cookieAnnoucementNotification;

  const [cookieStatus, setCookieStatus] = useState(true);
  const [announcementStatus, setAnnouncementStatus] = useState(getCookie('ANNOUNCEMENT_ACCEPTED') || false);
  const [isCheckoutEnable, setIsCheckoutEnable] = useState(false);
  const [isEnableStickyCTAStep2, setIsEnableStickyCTAStep2] = useState(false);
  const [isConfirmationError, setIsConfirmationError] = useState(false);
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailVerifiedLoading, setIsEmailVerifiedLoading] = useState(false);
  const isUserLoggedIn = Object.keys(getLoginUser()).length > 0 ? true : false;

  const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
    if (checkfortenants) {
      bodyClicks(eventName, titleData, labelData);
    }
  };

  useEffect(() => {
    if (
      isUserLoggedIn &&
      !localStorage.getItem(UIConfig.localStoreKeys.emailVerification.ClosedEmailVerificationBanner)
    ) {
      setIsEmailVerifiedLoading(true);
      const { email, idToken } = getLoggedInUser();
      ApiWrapper.api({
        url: data?.services?.emailValidation?.url?.replace('{emailId}', email),
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + idToken,
        },
        preLoader: true,
        preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
      })
        .then((response) => {
          setIsEmailVerified(response.data.emailValidation === 'Y');
          setIsEmailVerifiedLoading(false);
        })
        .catch((response) => {});
    }

    if (!getCookie('CookieConsent')) {
      setCookieStatus(false);
    }

    if (canUseDOM() && detectViewPort() === 'mobile') {
      window.PubSub.subscribe(UIConfig.events.MINICARTEXIST, (msg, data) => setIsCheckoutEnable(data));
      window.PubSub.subscribe(UIConfig.events.STEP_2_STICKY_CTA_WITH_COOKIES_EXIST, (msg, data) =>
        setIsEnableStickyCTAStep2(data),
      );
      window.PubSub.subscribe(UIConfig.events.CONFIRMATION_PAGE_REFRESH, () => setIsConfirmationError(true));
    }
  }, []);

  const keyDownHandler = (e, isAccepted = false) => {
    if (isEnterPressed(e)) {
      isAccepted ? onAcceptCookieState(e) : onCloseCookieState(e);
      navigateToSkipLink(true);
    }
  };

  const onAcceptCookieState = (e) => {
    e.preventDefault();
    if (isCookiePrivacyPolicyVariant) {
      setAnnouncementStatus(true);
      setCookie('ANNOUNCEMENT_ACCEPTED', true, UIConfig.defaultCookieExpireTime, getBaseURL());
    } else {
      cookieApiCall(true);
    }
  };

  const onCloseCookieState = (e) => {
    e.preventDefault();
    cookieApiCall(false);
  };

  const cookieApiCall = (userAction) => {
    let cookieExpireTime = 0;
    if (userAction) {
      cookieExpireTime = daysToExpire >= 0 ? daysToExpire * 24 * 60 : UIConfig.defaultCookieExpireTime;
    }

    setCookie('CookieConsent', new Date().getTime(), cookieExpireTime, getBaseURL());

    setCookieStatus(true);
    window.PubSub.publish(UIConfig.events.COOKIE_CLOSE, true);
  };

  function onCloseEmailVerificationBanner() {
    localStorage.setItem(UIConfig.localStoreKeys.emailVerification.ClosedEmailVerificationBanner, true);
    document.getElementById('email-verification__cookie').style.display = 'none';
  }

  const isEmailVerifEnabled = isEmailVerificationEnabled();

  const emailVerificationVariant = data.variant === 'v-email-verification';

  if (
    isEmailVerifEnabled &&
    emailVerificationVariant &&
    canUseDOM() &&
    localStorage.getItem(UIConfig.localStoreKeys.emailVerification.ClosedEmailVerificationBanner)
  ) {
    return <></>;
  }

  if (isEmailVerifEnabled && emailVerificationVariant && isEmailVerifiedLoading) {
    return <></>;
  }

  if (isEmailVerifEnabled && emailVerificationVariant && isUserLoggedIn && !isEmailVerified) {
    return (
      <div className="email-verification__cookie" id="email-verification__cookie">
        <div className="email-verification__cookie__content">
          <div className="email-verification__cookie__content__description">{data.description}</div>
          <div className="email-verification__cookie__content__action">
            <AnchorLink
              link={{
                label: ctaLabel,
                href: ctaLink?.href,
              }}
            />
          </div>
        </div>

        <div>
          <div
            className="email-verification__cookie__close cursor-pointer"
            onClick={(e) => {
              onCloseEmailVerificationBanner(e);
            }}
          >
            <SvgSprite id="icn-close" />
          </div>
        </div>
      </div>
    );
  }

  if (
    (data.variant === 'v-email-verification' && isUserLoggedIn && isEmailVerified) ||
    (data.variant === 'v-email-verification' && !isUserLoggedIn)
  ) {
    return <></>;
  }

  try {
    const conditionalClasses = classNames('component c-cookies', {
      'c-announcements': isCookiePrivacyPolicyVariant,
      'c-cookies-minicart': isCheckoutEnable,
      'c-cookies-step-2-cta-sticky': isEnableStickyCTAStep2,
      'c-cookies-authorized-agent': isAuthorizedAgentOverlay(variant),
      'confirmation-error': isConfirmationError,
      'c-announcements-notification': isCookieAnnoucementNotification,
    });

    return (
      !(cookieStatus || announcementStatus) && (
        <div className={`${conditionalClasses}`}>
          <div className="w--content">
            <div className="cookie-description">
              {isCookieAnnoucementNotification && imagelogo?.src && (
                <img src={imagelogo.src} width="40" height="40" alt={imagelogo.alt || ''} />
              )}
              <div>
                {isCookieAnnoucementNotification && title && (
                  <Text tag="h6" field={{ value: title, editable: title }} />
                )}
                <Text tag="span" field={{ value: description, editable: description }} />
              </div>
            </div>
            {!isCookieAnnoucementNotification && (
              <a
                href="/#"
                role="button"
                className="cookie-accept"
                onKeyDown={(e) => keyDownHandler(e, true)}
                onClick={(e) => {
                  onAcceptCookieState(e);
                  googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.linkClick, 'Cookie', ctaLabel);
                }}
              >
                <Text tag="span" className="cookie-accept-wrapper" field={{ value: ctaLabel, editable: ctaLabel }} />
                {isCookiePrivacyPolicyVariant && (
                  <DynamicContent
                    tagName="style"
                    innerHtml={`.c-cookies.c-announcements .cookie-accept::after { display : none; }`}
                  />
                )}
              </a>
            )}
            {!isCookiePrivacyPolicyVariant && (
              <>
                {!isCookieAnnoucementNotification && <span className="cookie-separator"></span>}
                <a
                  href="/#"
                  role="button"
                  aria-label={closeBtnLabel}
                  className="cookie-close-icon"
                  onKeyDown={(e) => keyDownHandler(e)}
                  onClick={(e) => {
                    onCloseCookieState(e);
                    googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.imageClick, 'Cookie', 'icn-close');
                  }}
                >
                  <SvgSprite id="icn-close" />
                </a>
              </>
            )}
          </div>
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Cookie', data.variant);
  }
};

Cookie.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    ctaLabel: PropTypes.string,
  }),
};

export default Cookie;
