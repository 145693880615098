import React, { useState, useEffect } from 'react';

export const useCountdownTimer = (props) => {
  const { isRunningKey, countdownTimerKey } = props;

  function getTimerValue() {
    return localStorage.getItem(countdownTimerKey);
  }

  function updateTimerValue(val) {
    localStorage.setItem(countdownTimerKey, val.toString());
  }

  function clearLocalStorage() {
    localStorage.removeItem(isRunningKey);
    localStorage.removeItem(countdownTimerKey);
  }

  function getTimerStatus() {
    return localStorage.getItem(isRunningKey);
  }

  function updateTimerStatus(val) {
    localStorage.setItem(isRunningKey, val);
  }

  const [time, setTime] = useState(null);
  let timer;

  useEffect(() => {
    setTime(getTimerValue());
  }, []);

  const runTimer = () => {
    let timerVal = Number(getTimerValue());
    if (timerVal > 0) {
      setTime(timerVal);
      timer = setInterval(() => {
        if (timerVal > 0) {
          timerVal = timerVal - 1;
          updateTimerValue(timerVal);
          setTime(timerVal);
        }
      }, 1000);
    }
    if (timerVal === 0) {
      clearLocalStorage();
      clearInterval(timer);
    }
  };

  useEffect(() => {
    const wasRunning = getTimerStatus() === 'true';
    if (wasRunning) {
      runTimer();
    }
    return () => clearInterval(timer);
  }, [time]);

  const startTimer = (duration) => {
    updateTimerValue(duration);
    runTimer();
    updateTimerStatus(true);
  };

  return { time, startTimer };
};
