import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import {
  canUseDOM,
  detectMobile,
  getLoggedInUser,
  getLoginUser,
  getMainObject,
  getSessionStorage,
  iOSDevice,
  isEnterPressed,
  isLoggedInUser,
  isZoomIn,
  openIAMOverlay,
  resolvePath,
  checkTenant,
  updateViewportMetaTagContent,
  detectViewPort,
  isF1SeatedTypeProduct,
  isYasArenaJourney,
  isAddonProduct,
  checkParksTenants,
  isMatchTenant,
  isMobileOrTab,
  getYasIdGTMData,
  setSessionStorage,
  checkIfParks,
  getGa4Category,
  toLowerCase,
  checkGA4Tenants,
  getLocalStorageByKey,
  setLocalStorage,
  isTicketUpgradeJourney,
  isTicketUpgradePayment,
  removeTicketUpgradeCart,
  parseQueryString,
} from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';
import { logoutCurrentUser } from '../../../common/session/session-utility';
import { login } from '../../../common/login';
import { ServiceConfig } from '../../../common/services';
import SojrenAnalytics from '../../../common/sojren-analytics';
import UIConfig from '../../../common/UIConfig';
import {
  AnchorLink,
  DynamicContent,
  ErrorOverlay,
  Image,
  Overlay,
  SearchOverlay,
  SvgSprite,
} from '../../presentation/base';
import HeaderBody from '../../presentation/header-body/header-body-component';
import HeaderBottom from '../../presentation/header-bottom/header-bottom-component';
import IAMPopup from '../../presentation/iam-popup/iam-popup';
import LanguageSwitcher from '../../presentation/language-switcher';
import CurrencySwitcher from '../../presentation/currency-switcher';
import ProfileTooltip from '../../presentation/tooltip/tooltip-component';
import { defaultGoogleAnalyticYas } from '../../../common/analytics-events';
import CartActions from '../../container/b2c-purchase-journey/cart/cart-actions';
import { hamburgerIcon, hamburgerIconRevamp, hamburgerIconRevampDark } from '../../../assets/img';
import './header-component.scss';
import GTMData from '../../container/b2c-purchase-journey/gtm-data';
import className from 'classnames';

import HeaderMiniCart from '../../presentation/header-bottom/header-mini-cart';
import CartCount from './CartCount';
import classNames from 'classnames';

const userInfo = getLoggedInUser();

const isWishListActive = () => {
  if (canUseDOM()) {
    const isUserLoggedIn = Object.keys(getLoginUser()).length > 0;
    localStorage.wishlistData =
      localStorage.wishlistData && (ServiceConfig.getWishlistID() || isUserLoggedIn)
        ? localStorage.wishlistData
        : JSON.stringify({});
    return localStorage.wishlistData ? Object.values(JSON.parse(localStorage.wishlistData)).includes(true) : false;
  } else {
    return false;
  }
};

const GTMDataOnClickLink = (event = {}) => {
  if (isMatchTenant(UIConfig.tenants.yi)) {
    try {
      const elem = event.target;
      const { tagName } = elem || {};
      const isButton = tagName === UIConfig.ga4Constants.BUTTON || elem.closest('button');

      const isAnchor = tagName === UIConfig.ga4Constants.ANCHOR || elem.closest('a');
      const anchorHref = isAnchor && elem.closest('a').href;
      const category = getGa4Category(window?.location?.pathname);
      const isChatBotCTA = elem.closest('#labibaWebBotDiv');

      if (isAnchor && !isChatBotCTA && !isButton) {
        GTMData.push(UIConfig.ga4Constants.CLICK_LINK, {
          name: toLowerCase(elem?.textContent),
          [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(elem?.textContent) || '[IMAGE]',
          link_url: anchorHref,
          category,
        });
      }
    } catch (error) {
      console.log('GA4 error: ', error);
    }
  }
};

/**
 * Header is a layout container Component for header related components.
 * contains nav, profile, language-switcher and other links
 * @param    {props} Object as defined from data-contract.
 * @return   {Header} markup that containes header top, middle and bottom
 */
const Header = ({ data, params }) => {
  let lastScrollVal = 0;
  const [miniCartObj, setMiniCartObj] = useState('');
  const [showHeader, setShowHeader] = useState(false);
  const [showMiniCartInHeader, setShowMiniCartInHeader] = useState(false);
  const [isBookingPageForMinicart, setIsBookingPageForMinicart] = useState(false);
  const [newUpdatedCart, setNewUpdatedCart] = useState({});
  const [lastPage, setLastPage] = useState(`${data.logoCta.href}`);
  const [isBeforeDomLoads, setBeforeDomLoads] = useState(true);
  const isMiniHeader = data.variant === 'v-mini-header-b2c' || params?.headerType === 'mini-header';
  const isEmailVerificationConfirmationPage =
    data.variant === 'v-email-verification' || data?.source === 'email-verification';

  const cartRef = useRef();
  useEffect(() => {
    window.PubSub.subscribe('myCartMockObject', (msg, data) => {
      setMiniCartObj(data);
      setShowMiniCartInHeader(data?.isHeaderMiniCartEnabled);
      setIsBookingPageForMinicart(
        data?.isHeaderMiniCartEnabled && data?.isHeaderMiniCartEnabledOnBookingToPaymentPages,
      );
    });
    //added sub for fwad minicart
    window.PubSub.subscribe('miniCartArrowClicked', (msg, data) => {
      setHideCart(false);
      if (!document.querySelector('body').classList.contains('booking--page')) {
        document.querySelector('body').classList.remove('minicart-no-scroll');
        document.querySelector('body').classList.remove('disable-scroll');
      }
    });

    window.PubSub.subscribe('showHeader', (msg, data) => {
      setShowHeader(true);
    });

    if (!showHeader) {
      setShowHeader(true);
    }
    if (isYasArenaJourney()) {
      scrollToAddons(true);
    }
    setBeforeDomLoads(false);
  }, []);

  useEffect(() => {
    getPageRedirectionsUrl();
    document.body.addEventListener('click', GTMDataOnClickLink);
    return () => {
      document.body.removeEventListener('click', GTMDataOnClickLink);
    };
  }, []);

  const searchSettingsObj = data.searchSettings;
  let searchSettings;
  let cartActions = null;

  // PubSub.subscribe(“topic1”,subscribeMethod);

  const [cartTotalQuantity, setCartTotalQuantity] = useState(0);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [profileExpanded, setProfileExpanded] = useState(false);
  const [type, setType] = useState('');
  const [toggleEmptyMiniCart, setToggleEmptyMiniCart] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [wishlistActive, setWishlistActive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [enableYasIDVersion, setEnableYasIDVersion] = useState(false);
  const [loadActionButtons, setLoadActionButtons] = useState(false);
  const [searchiconstatus, setsearchiconstatus] = useState(false);
  const searchSettingVariant = searchSettingsObj?.searchVariant || '';
  const isSwadHeaderVariant = data.variant === UIConfig.swadVariant.headerVariant;
  const isYasHeaderVariant = data.variant === UIConfig.commonVariant.headerVariant;
  const isMultiMegaNavHeaderVariant = data.variant === UIConfig.commonVariant.multiMegaNavHeaderVariant;
  const isHeaderVariant = isYasHeaderVariant || isSwadHeaderVariant || isMultiMegaNavHeaderVariant;
  const [isParentData, setIsParentData] = useState(false);
  const [hideCart, setHideCart] = useState(false);
  const [isHeaderSticky, setHeaderSticky] = useState(false);
  const [isHeaderStickyPos, setHeaderStickyPos] = useState(0);
  const [isBodyHeight, setBodyHeight] = useState('');
  const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();
  const isStickyHeaderVar = data.headerVariant === UIConfig.parksStickHeader.variant;
  const mobileRef = useRef(isMobile);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isParks = checkIfParks();
  const isYmcMobile = checkTenant(UIConfig.iamMapping.ymc) && detectMobile();
  const [loginEventFired, setLoginEventFired] = useState(0);
  const [isAnnualPassPage, setIsAnnualPassPage] = useState(false);
  const isTicketUpgrade = (data?.ticketUpgrade?.enableTicketUpgrade || false) && checkParksTenants();
  const [showTicketUpgradeHeaderFlag, setShowTicketUpgradeHeaderFlag] = useState(false);

  useEffect(() => {
    setShowTicketUpgradeHeaderFlag(isTicketUpgradePage() || isPaymentPage());
  }, []);

  useLayoutEffect(() => {
    if (checkParksTenants()) {
      let bodyRef = document.getElementsByTagName('body')[0];
      const isMiniCartVisible = document.querySelector('.cart-dropdown-wrap.showCart');
      if ((profileExpanded || menuExpanded || isMiniCartVisible || hideCart) && bodyRef) {
        bodyRef.classList.add('hideChatBot-icon');
      } else {
        bodyRef.classList.remove('hideChatBot-icon');
      }
    }
  }, [profileExpanded, menuExpanded, hideCart]);

  if (data.search && searchSettingsObj) {
    if (isHeaderVariant) {
      searchSettings = {
        placeHolder: searchSettingsObj.inputPlaceholder,
        submitBtnLabel: searchSettingsObj.searchBtnLabel,
        searchPageUrl: searchSettingsObj.searchPageUrl,
        autoSuggestLimit: Number(searchSettingsObj.autoSuggestLimit),
        currentLanguage: searchSettingsObj.currentLanguage,
        coveoKeyMap: searchSettingsObj.coveoKeyMap,
        autoSuggestMobileView: searchSettingsObj.autoSuggestMobileView,
        additionalProperty: searchSettingsObj.additionalProperty || {},
        serviceUrls: searchSettingsObj.serviceUrls,
        searchAriaLabel: searchSettingsObj.searchAriaLabel,
        resetAriaLabel: searchSettingsObj.resetAriaLabel,
        currentLocale: searchSettingsObj.currentLocale,
        searchSettingVariant: searchSettingsObj.searchVariant || '',
        customFixedTitle: searchSettingsObj.customFixedTitle || '',
        customSuggestionText: searchSettingsObj.suggestionText || '',
      };
    } else {
      searchSettings = {
        placeHolder: searchSettingsObj.inputPlaceholder,
        submitBtnLabel: searchSettingsObj.searchBtnLabel,
        searchPageUrl: searchSettingsObj.searchPageUrl,
        autoSuggestLimit: Number(searchSettingsObj.autoSuggestLimit),
        currentLanguage: searchSettingsObj.currentLanguage,
        coveoKeyMap: searchSettingsObj.coveoKeyMap,
        autoSuggestMobileView: searchSettingsObj.autoSuggestMobileView,
        additionalProperty: searchSettingsObj.additionalProperty || {},
        serviceUrls: searchSettingsObj.serviceUrls,
        searchAriaLabel: searchSettingsObj.searchAriaLabel,
        resetAriaLabel: searchSettingsObj.resetAriaLabel,
        currentLocale: searchSettingsObj.currentLocale,
      };
    }
  }

  const getPageRedirectionsUrl = () => {
    const currentUrl = window.location.pathname + window.location.hash;
    const storageUrl = getLocalStorageByKey('historyStack');
    if (storageUrl?.length) {
      const [firstUrl, secondUrl] = storageUrl;
      let relativePaths = secondUrl.split('/') || [];
      let newUrlExist = false;
      if (relativePaths?.length) {
        relativePaths = relativePaths.filter((path) => path);
        relativePaths.forEach((u, i) => {
          if (i !== 0) {
            newUrlExist = currentUrl.includes(u);
          }
        });
      }
      if (!newUrlExist) {
        secondUrl && setLastPage(secondUrl);
        storageUrl.length > 1 && storageUrl.shift();
        storageUrl.push(currentUrl);
        setLocalStorage('historyStack', JSON.stringify(storageUrl));
      } else {
        firstUrl && setLastPage(firstUrl);
      }
    } else {
      setLocalStorage('historyStack', JSON.stringify([lastPage, window.location.pathname]));
    }
  };

  const handleRedirection = (e) => {
    e.preventDefault();
    // Check if the previous URL is within your site
    if (document.referrer.includes(window.location.origin)) {
      // Go back one page in the browser history
      window.history.back();
    } else {
      window.location.href = lastPage || data.backLink.href;
    }
  };

  const bookingWidgetPosition = () => {
    if (data?.headerVariant) {
      if (detectViewPort() === 'desktop' || detectViewPort() === 'desktop-xl') {
        document.body.className += ` ${data.headerVariant}`;
      }
      if (detectViewPort() === 'tablet' || detectViewPort() === 'mobile') {
        document.body.className = document.body.className.replace(data.headerVariant, '');
        if (data.headerVariant === UIConfig.commonVariant.headerYASTenantVariant.listingHeaderVariant) {
          document.body.className += ' mobileListing';
        } else {
          document.body.className = document.body.className.replace('mobileListing', '');
        }
      }
    }
  };

  const getScrolledVal = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const stickyHeader = document.querySelector('.custom-mobile-header.sticky-header');
    const headerTopWhiteSpaceFwad = 28;
    if (tenantId === UIConfig.tenants.wbw || tenantId === UIConfig.tenants.fwad) {
      if (tenantId === UIConfig.tenants.fwad) {
        if (stickyHeader && winScroll <= lastScrollVal) {
          lastScrollVal = winScroll;
          return winScroll > headerTopWhiteSpaceFwad;
        }
        lastScrollVal = winScroll;
      }
      return winScroll > 100;
    }
    return winScroll / height;
  };

  const checkIfPageScrolled = () => {
    if (isMiniHeader) {
      setHeaderStickyPos(window.scrollY);
    } else {
      const scrolled = getScrolledVal();
      setHeaderSticky(scrolled);
    }
  };

  const getBodyHeight = () => {
    const winHeight = window.outerHeight;
    const topHeader = document.getElementsByClassName('c-header-top')?.[0];
    let rmngHeight = winHeight - topHeader?.offsetHeight - (tenantId === UIConfig.tenants.fwad ? 28 : 0);
    setBodyHeight(rmngHeight);
  };

  useEffect(() => {
    if (menuExpanded || profileExpanded) {
      getBodyHeight();
    }
  }, [menuExpanded, profileExpanded]);

  const singUpGTMData = (user_id) => {
    return {
      event: 'sign_up',
      login_status: 'logged in',
      user_id: user_id,
      my_pass_id: user_id,
      user_type: 'new customer',
    };
  };

  useEffect(() => {
    const annualElement = document.querySelector('.buy-annual-pass--page');
    if (isAnnualPassPage && parseInt(cartTotalQuantity) === 0) {
      annualElement && annualElement.classList.add('empty-cart');
    }
    if (isAnnualPassPage && parseInt(cartTotalQuantity) > 0) {
      annualElement && annualElement.classList.add('cart-has-items');
    }
    return () => {
      annualElement && annualElement.classList.remove('empty-cart');
      annualElement && annualElement.classList.remove('cart-has-items');
    };
  }, [isAnnualPassPage, cartTotalQuantity]);

  useEffect(() => {
    let isUserLoggedIn = !!(userInfo && userInfo.idToken);
    const widgetPath = document.querySelector('.c-hotel-booking-RedBackground.backgroundWrapper');
    if (
      (isHeaderVariant &&
        data.headerVariant !== UIConfig.commonVariant.headerYASTenantVariant.listingHeaderVariant &&
        data.headerVariant !== UIConfig.commonVariant.headerYASTenantVariant.checkoutHeaderVariant) ||
      isYmcMobile
    ) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset >= 121) widgetPath && widgetPath.classList.add('stickyWidget');
        else widgetPath && widgetPath.classList.remove('stickyWidget');
      });
      if (data.sticky) {
        stickyHeader();
      }
    }
    const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const checkfortenants =
      resolvePath(getMainObj, 'tenantID', '').toLowerCase() === checkTenant(UIConfig.iamMapping.swad);
    const isLoginSinupEventFired = getSessionStorage('loginSignUpEventFired');
    const isRegistrationComplete = getSessionStorage('RegistrationComplete');
    if (
      resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C ||
      (checkGA4Tenants([UIConfig.tenants.yi], [UIConfig.tenants.ya]) &&
        getYasIdGTMData() &&
        !isLoginSinupEventFired &&
        !isRegistrationComplete)
    ) {
      if (checkParksTenants() || isMatchTenant(UIConfig.tenants.ya)) {
        GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
          name: 'SignIn',
          ...(isMatchTenant(UIConfig.tenants.ya) ? { login_type: getLoggedInUser()?.loginProvider || 'Custom' } : {}),
        });
        if (isMatchTenant(UIConfig.tenants.ya)) {
          GTMData.push(UIConfig.ga4Constants.ADD_SHIPPING_INFO, { tier: 'login' });
        }
      } else {
        GTMData.push(UIConfig.ga4Constants.LOGIN, {
          login_status: isUserLoggedIn ? 'logged in' : '',
          user_id: getYasIdGTMData(),
          user_type: 'existing customer',
        });
      }
      setSessionStorage('loginSignUpEventFired', 'true');
    } else if (checkfortenants) {
      data.language?.labels?.languageCode &&
        defaultGoogleAnalyticYas(data.language.labels.languageCode, isUserLoggedIn);
    }
    if (checkParksTenants() || isMatchTenant(UIConfig.tenants.ya)) {
      const isRegistrationComplete = JSON.parse(getSessionStorage('RegistrationComplete'));
      if (isRegistrationComplete) {
        GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
          name: 'SignUp',
          ...(isMatchTenant(UIConfig.tenants.ya) ? { loginType: getLoggedInUser()?.loginProvider || 'Custome' } : {}),
        });
        if (isMatchTenant(UIConfig.tenants.ya)) {
          GTMData.push(UIConfig.ga4Constants.ADD_SHIPPING_INFO, { tier: 'sign up' });
        }
        setSessionStorage('RegistrationComplete', false);
      }
    }

    if (isMatchTenant(UIConfig.tenants.yi) && getYasIdGTMData()) {
      const isRegistrationComplete = getSessionStorage('RegistrationComplete');
      if (isRegistrationComplete) {
        GTMData.push('signUp', singUpGTMData(getYasIdGTMData()), false, true);
        setSessionStorage('RegistrationComplete', false);
      }
    }

    if (data.variant === 'v-header-view') {
      isUserLoggedIn = !!data.profile;
    }
    if (isHeaderVariant) {
      bookingWidgetPosition();
      window.addEventListener('resize', bookingWidgetPosition);
    }
    if (data.sticky && !detectMobile()) {
      stickyHeader();
    }
    setIsLoggedIn(isUserLoggedIn);
    setLoadActionButtons(true);
    const viewport = detectMobile();
    setIsMobile(viewport);
    mobileRef.current = viewport;
    !data.isWishListEnabled && data.setWishListData && setWishListStorage();
    addListenerMulti(window, 'keydown mousedown', handleFirstTab);
    addOrRemoveZoomInClass();

    if (canUseDOM()) {
      SojrenAnalytics.set('pageCategory');
      iOSDevice() && updateViewportMetaTagContent();

      const updateStateOnViewChange = () => {
        const isMobileViewport = detectMobile();
        if (mobileRef.current !== isMobileViewport) {
          setMenuExpanded(false);
          setProfileExpanded(false);
          setType('');
          setIsMobile(isMobileViewport);
          mobileRef.current = viewport;
        } else {
          setIsMobile(isMobileViewport);
        }
      };

      window.PubSub.subscribe('windowResize', () => {
        updateStateOnViewChange();
        addOrRemoveZoomInClass();
      });

      window.PubSub.subscribe('oreintationChange', () => {
        setTimeout(() => {
          updateStateOnViewChange();
        }, 50);
      });

      if (data.isWishListEnabled) {
        setWishlistActive(isWishListActive());
        window.PubSub.subscribe(UIConfig.events.WISHLIST_REFRESH, () => {
          setWishlistActive(isWishListActive());
        });
      }

      if (sessionStorage.getItem(UIConfig.sessionStorageKeys.USER_BLOCKED) === 'true') {
        showSuspendedUserOverlay();
      }

      const headerNextEl = document.querySelector('header')?.nextElementSibling;
      if (headerNextEl) {
        headerNextEl.id = 'main-content';
        headerNextEl.setAttribute('tabindex', '-1'); // IE Fix
      }
    }

    if (isLoggedInUser() && !getSessionStorage('isSessionInit')) {
      login();
    }

    const profileIconEl = document.querySelectorAll('.c-header-top-icon.profile')[0];
    profileIconEl && profileIconEl.classList.remove('link-disable');

    const enableYasIDVersion = resolvePath(getMainObject(), 'additionalProperty.enableYasIDVersion', 'false');
    setEnableYasIDVersion(enableYasIDVersion);

    isExperienceEditorActive() && document.body.classList.add('experience-editor-mode');

    // Sticky Header On Mobile
    checkIfPageScrolled();
    window.addEventListener('scroll', checkIfPageScrolled);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    subscribeCart();
    updateCartIcon();

    if (
      (checkTenant(UIConfig.iamMapping.fwad) ||
        checkTenant(UIConfig.iamMapping.wbw) ||
        checkTenant(UIConfig.iamMapping.yww)) &&
      detectMobile() &&
      document.body.classList.contains('buy-annual-pass--page')
    ) {
      setIsAnnualPassPage(true);
    }
    return () => {
      window.removeEventListener('scroll', checkIfPageScrolled);
      setIsAnnualPassPage(false);
    };
  }, []);

  const renderLanguageSwitcher = (lightMode = false) => {
    return (
      <div className="header-bottom-lang-switcher lang-sw lang-switcher-on-top">
        <LanguageSwitcher data={data.language} lightMode={lightMode} />
      </div>
    );
  };

  const renderCurrencySwitcher = () => {
    return (
      <div className="header-bottom-currency-switcher">
        <CurrencySwitcher data={data.currency} />
      </div>
    );
  };

  const addOrRemoveZoomInClass = () => {
    isZoomIn() ? document.body.classList.add('zoom-in') : document.body.classList.remove('zoom-in');
  };

  const addListenerMulti = (element, eventNames, listener) => {
    var events = eventNames.split(' ');
    for (var i = 0, iLen = events.length; i < iLen; i++) {
      element.addEventListener(events[i], listener, false);
    }
  };

  const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', handleFirstTab);
      window.PubSub.publish('isTabbing', { isEnabled: true });
    } else if (document.getElementsByClassName('user-is-tabbing').length > 0) {
      document.body.classList.remove('user-is-tabbing');
      window.addEventListener('keydown', handleFirstTab);
      window.PubSub.publish('isTabbing', { isEnabled: false });
    }
  };

  const setWishListStorage = () => {
    if (canUseDOM()) {
      const isUserLoggedIn = Object.keys(getLoginUser()).length > 0;
      localStorage.wishlistData =
        localStorage.wishlistData && (ServiceConfig.getWishlistID() || isUserLoggedIn)
          ? localStorage.wishlistData
          : JSON.stringify({});
    }
  };

  const showSuspendedUserOverlay = () => {
    sessionStorage.removeItem(UIConfig.sessionStorageKeys.USER_BLOCKED);
    const overlayAttrs = {
      iconId: 'icn-suspended-account',
      htmlContent: data.logoutServiceUrls.suspendedUserPopUpMessage,
    };

    window.PubSub.publish(
      searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
        ? 'searchToggleOverlayState'
        : 'toggleOverlayState',
      {
        shouldOpen: true,
        dataToAppend: <ErrorOverlay {...overlayAttrs} />,
        customClass: 'c-error-overlay account-suspended',
      },
    );
  };

  /**
   * This function makes the header sticky
   */
  const stickyHeader = () => {
    const header = document.getElementsByClassName('c-header');
    const body = document.getElementsByTagName('body');
    const headerRef = header[0];
    const bodyRef = body[0];

    const addScrollBodyClass = data.headerVariant == 'stickyVariant';

    //Header Height
    let headHeight = headerRef?.offsetHeight;

    window.addEventListener('scroll', () => {
      if (data.headerVariant !== UIConfig.commonVariant.headerYASTenantVariant.listingHeaderVariant) {
        if (detectMobile()) {
          if (window.pageYOffset >= headerRef.classList.contains('sticky-header')) {
            headerRef.classList.add('sticky-header');
            document.getElementsByTagName('body')[0].classList.add('yas-sticky-header');
            addScrollBodyClass && bodyRef.classList.add('body-sticky-space');
          } else if (headerRef.classList.contains('sticky-header')) {
            headerRef.classList.remove('sticky-header');
            document.getElementsByTagName('body')[0].classList.remove('yas-sticky-header');
            addScrollBodyClass && bodyRef.classList.remove('body-sticky-space');
          }
          if (isYmcMobile) {
            const cookiesElem = document.querySelector('.c-cookies');
            if (cookiesElem) {
              if (window.pageYOffset === 0) {
                cookiesElem.style.display = 'block';
              } else {
                cookiesElem.style.display = 'none';
              }
            }
          }
        } else {
          if (window.pageYOffset >= headHeight) {
            headerRef.classList.add('sticky-header');
            document.getElementsByTagName('body')[0].classList.add('yas-sticky-header');
            addScrollBodyClass && bodyRef.classList.add('body-sticky-space');
          } else if (headerRef?.classList.contains('sticky-header')) {
            headerRef.classList.remove('sticky-header');
            document.getElementsByTagName('body')[0].classList.remove('yas-sticky-header');
            addScrollBodyClass && bodyRef.classList.remove('body-sticky-space');
          }
        }
      }
      headHeight = headerRef?.offsetHeight;
    });
  };

  /**
   * This function toggle the menu state in mobile version
   */
  const toggleMenuState = () => {
    let header;
    let bodyRef;
    let headerRef;
    if (profileExpanded) {
      setMenuExpanded(!profileExpanded);
      setType('profile');
    } else {
      setMenuExpanded(!menuExpanded);
      setType('menu');
    }
    setProfileExpanded(false);
    setType('menu');
    if (canUseDOM()) {
      window.PubSub.publish('toggleMenuState', {});
      showBuyNow(parseInt(cartTotalQuantity));

      if (isHeaderVariant || checkIfSelectedParks()) {
        header = document.getElementsByTagName('header');
        bodyRef = document.getElementsByTagName('body')[0];
        headerRef = header[0];

        bodyRef.classList.contains('disable-scroll')
          ? bodyRef.classList.remove('disable-scroll')
          : bodyRef.classList.add('disable-scroll');
        headerRef.classList.add('sticky-header');
        //added for fwad minicart
        if (checkIfParks()) fwadMobileMiniCartCssToggle();
      }
    }
  };

  const fwadMobileMiniCartCssToggle = () => {
    if (document.querySelector('.c-header').classList.contains('sticky-header'))
      document.querySelector('.c-header').classList.remove('sticky-header');
    /* remove the class if menu is closed or cart is empty */
    if (
      document.querySelector('.c-header-top-icon').classList.contains('close') ||
      document.querySelector('.custom-mobile-header').classList.contains('cart-has-no-items')
    ) {
      document.querySelector('body').classList.remove('disable-scroll');
    }
  };

  /**
   * This function toggle the profile state in mobile version
   */
  const toggleProfileState = () => {
    setProfileExpanded(!profileExpanded);
    setMenuExpanded(false);
    setType('profile');
  };

  const handleSkipToContent = (evt) => {
    if (evt.type === 'click' || (evt.type === 'keydown' && isEnterPressed(evt))) {
      evt.preventDefault();
      const mainContentEle = document.getElementById('main-content');
      if (mainContentEle) {
        mainContentEle.focus();
        window.scrollTo(0, 0);
        window.history.pushState('', '', evt.target.hash);
      }
    }
  };

  const closeMenu = () => {
    setMenuExpanded(false);
  };

  /**
   *  for sonar fixes to bring down for function created : Header Top
   */
  const headerTop = (data) => {
    return (
      <div className="c-header-top">
        <div
          className={`c-header-top-icon
                        ${profileExpanded ? 'close' : 'profile'}
                        ${!isLoggedIn ? ' hide-visibility' : ''}`}
          onClick={toggleProfileState}
        >
          {profileExpanded ? <SvgSprite id="icn-close-white" /> : <SvgSprite id="icn-user" />}
        </div>
        <div className="c-header-top-logo">
          {data.logoCta ? (
            <AnchorLink
              link={{
                href: data.logoCta.href,
                label: data.logo && data.logo.html,
                target: data.logoCta.target,
              }}
            />
          ) : (
            <DynamicContent tagName={'div'} innerHtml={data.logo && data.logo.html} />
          )}
        </div>
        <button className={`c-header-top-icon ${menuExpanded ? 'close' : 'hamburger'}`} onClick={toggleMenuState}>
          {menuExpanded ? <SvgSprite id="icn-close-white" /> : <img src={hamburgerIcon} alt="menu" />}
        </button>
      </div>
    );
  };

  const toggleAllExpand = (e) => {
    const isCloseClicked = e.target.classList && e.target.className.indexOf('close');
    if (isCloseClicked === -1 || menuExpanded || profileExpanded) {
      toggleMenuState();
      window.PubSub.subscribe('menuClose', () => closeMenu());
    } else {
      toggleProfileState();
    }
  };

  /**
   * openErrorOverlay function opens the error overlay.
   * @param {Object} content which is appended in overlay
   * @return {null}
   */

  const openErrorOverlay = (modalHTML) => {
    window.PubSub.publish(
      searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
        ? 'searchToggleOverlayState'
        : 'toggleOverlayState',
      {
        shouldOpen: true,
        dataToAppend: modalHTML,
        customClass: 'c-error-overlay v-b2c-overlay smallSizeOverlay',
      },
    );
  };

  const unAvailableLoginCta = () => {
    logComponentRenderingError(new Error(UIConfig.loginCta.error), 'Header');
    let attrs = {
        title: UIConfig.loginCta.error,
        primaryCTA: UIConfig.loginCta.primaryCTA,
      },
      overlayHTML = <ErrorOverlay {...attrs} />;
    openErrorOverlay(overlayHTML);
  };

  const getUserIcon = () => {
    if (isMiniHeader) {
      return 'icn-user-white';
    }
    if (checkIfQAW()) {
      return 'icn-user-strong';
    }
    return 'icn-user';
  };

  const profileTopIcon = (data) => {
    return (
      <button
        aria-label={data?.loginCta ? data?.loginCta?.label : UIConfig.loginCta.label}
        className="c-header-top-icon profile"
        onClick={() =>
          resolvePath(data, 'loginCta.href')
            ? openIAMOverlay(<IAMPopup loginURL={data?.loginCta?.href} />)
            : unAvailableLoginCta()
        }
      >
        {isMiniHeader ? (
          <svg viewBox="0 0 25 25" id="icn-user-white">
            <g fill="#fff" fill-rule="evenodd">
              <path d="M12.5 25C19.398 25 25 19.396 25 12.5 25 5.602 19.398 0 12.5 0S0 5.602 0 12.5 5.602 25 12.5 25zm-6.898-4.607c1.891-2.256 4.346-3.482 6.898-3.482 2.552 0 5.007 1.226 6.898 3.482A10.352 10.352 0 0 1 12.5 23.01c-2.653 0-5.074-.994-6.898-2.617zM12.5 1.99c5.803 0 10.51 4.707 10.51 10.51 0 2.421-.829 4.675-2.22 6.433-2.289-2.587-5.173-4.012-8.29-4.012-3.117 0-6.001 1.427-8.29 4.012A10.432 10.432 0 0 1 1.99 12.5c0-5.803 4.707-10.51 10.51-10.51z"></path>
              <path d="M12.808 13.615a4.806 4.806 0 0 0 4.807-4.808A4.805 4.805 0 0 0 12.808 4 4.805 4.805 0 0 0 8 8.807a4.806 4.806 0 0 0 4.808 4.808zm0-7.719a2.902 2.902 0 0 1 2.91 2.91 2.902 2.902 0 0 1-2.91 2.91 2.902 2.902 0 0 1-2.91-2.91 2.922 2.922 0 0 1 2.91-2.91z"></path>
            </g>
          </svg>
        ) : (
          <SvgSprite id={getUserIcon()} />
        )}
      </button>
    );
  };

  const logoutUser = (e) => {
    sessionStorage.removeItem('loginEventFired');
    e.preventDefault();
    logoutCurrentUser();
  };

  const renderProfileTooltipVariant = (data, userInfo, isLoggedIn) => {
    return (
      isLoggedIn &&
      (isHeaderVariant || isMiniHeader) &&
      data.profile && (
        <ProfileTooltip
          wrapperClass="header-bottom-profile"
          buttonClass="c-header-top-icon profile logged-in"
          isDefaultOpen={!isMiniHeader}
          buttonInnerHtml={
            !isMiniHeader ? (
              <span>
                <SvgSprite id="icn-user" />
                <SvgSprite id="logged-in-icon" />
              </span>
            ) : (
              <>
                <span>
                  <svg id="logged-in-icon-white" viewBox="0 0 20 20">
                    <g fill="#fff" fill-rule="evenodd">
                      <path d="M10 12c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4"></path>
                      <path d="M10 2c4.411 0 8 3.589 8 8a7.962 7.962 0 0 1-2.042 5.32C14.467 13.858 12.329 13 10 13c-2.296 0-4.455.875-5.952 2.327A7.96 7.96 0 0 1 2 10c0-4.411 3.589-8 8-8m7.193 14.93A9.96 9.96 0 0 0 20 10c0-5.514-4.486-10-10-10S0 4.486 0 10a9.964 9.964 0 0 0 2.811 6.935c-.014.025-.034.047-.047.073 0 0 .091.05.247.132a9.964 9.964 0 0 0 14.164-.19l.023-.013a.03.03 0 0 1-.005-.007"></path>
                    </g>
                  </svg>
                </span>
                <SvgSprite id="icn-dropdown-white" />
              </>
            )
          }
          tabIndex="0"
          refName="profileToolTip"
          ariaLabelToolTip={data.ariaLabelToolTipLogin}
          isMiniHeader={isMiniHeader}
        >
          <div className="header-bottom-profile-tooltip">
            {userInfo?.firstName && (
              <DynamicContent
                tagName="div"
                innerHtml={data.profile.bodyCopy + ' ' + userInfo.firstName}
                attrs={{ className: 'header-bottom-profile-name' }}
              />
            )}
            <ul>
              {(data.profile.ctaLinks[0].label === UIConfig.loginCta.label
                ? UIConfig.profile.ctaLinks
                : data.profile.ctaLinks
              ).map((link, i) => (
                <>
                  <li key={i}>
                    {link.icon && <img src={link.icon} alt={link.label} />}
                    <AnchorLink link={link} tabIndex="0" />
                  </li>
                </>
              ))}
            </ul>
            {(data.logoutCta ? data.logoutCta : (data.logoutCta = UIConfig.logoutCta)) && (
              <div className="header-bottom-btn-logout">
                {data.logoutCta.icon && <img src={data.logoutCta.icon} alt={data.logoutCta.label} />}
                <AnchorLink link={{ ...data.logoutCta, href: '#', attrs: { onClick: logoutUser } }} tabIndex="0" />
              </div>
            )}
          </div>
        </ProfileTooltip>
      )
    );
  };

  const renderBookTicketBtnWihoutLogged = (body) => {
    return (body.variant === 'v-header-yasisland' ||
      body.variant === 'v-header-swad' ||
      body.variant === 'v-header-multimeganav') &&
      body.bookTicketCta ? (
      <div className="header-bottom-book-ticket">
        <AnchorLink
          link={{
            label: body.bookTicketCta.label,
            href: body.bookTicketCta.href,
          }}
        />
      </div>
    ) : null;
  };

  const subscribeCart = () => {
    cartActions = new CartActions({
      serviceUrl: data.services,
      moduleName: 'header',
      tenantId: getLoggedInUser().tenantID,
    });

    if (canUseDOM()) {
      window.PubSub.subscribe(UIConfig.events.UPDATE_HEADER_CART, updateCartIcon);
    }
  };

  /* added for fwad mini cart */
  const showBuyNow = (qty) => {
    if (detectMobile()) {
      setTimeout(() => {
        if (checkIfParks() && qty === 0 && !document.body.classList.contains('booking--page')) {
          const stickyBtn = document.querySelector('#c-header-top div.btn-subscribe.btn-primary.reverse.sticky');
          if (stickyBtn) {
            stickyBtn.style.display = 'block';
          }
          /* added for remove the class if cart is empty */
          if (document.querySelector('.custom-mobile-header')?.classList.contains('cart-has-no-items')) {
            document.querySelector('body').classList.remove('disable-scroll');
          }
        }
      }, 100);
    }
  };

  const scrollToAddons = (onLoad) => {
    const urlParams = new URLSearchParams(window.location.search);
    const salesAk = urlParams.get('sale');
    const isCheckout = urlParams.get('currpage') === 'checkout';
    if (onLoad) {
      if (!salesAk && !isCheckout) {
        return;
      }
    } else if (!window.location.hash || window.location.hash !== '#ya_recommendation') {
      return;
    }
    const lazyLoadContainer = document.querySelectorAll('.lazyload-wrapper');
    if (lazyLoadContainer.length > 1) {
      const topPosition = lazyLoadContainer?.[1].offsetTop - (isMobileOrTab() ? 0 : 120);
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  };

  const updateCartIcon = (msg, data) => {
    if (data) {
      window.PubSub.publish(UIConfig.events.UPDATE_CART_COUNTER, { value: data.totalQuantity });
      setCartTotalQuantity(data.totalQuantity);
      showBuyNow(parseInt(data.totalQuantity));
      setNewUpdatedCart(data);
    } else {
      cartActions &&
        cartActions
          .getCartOnPageLoad()
          .then((res) => {
            // storing in local storage and not using pubsub here becuase the subscriber component loads much after the publish event
            localStorage.setItem(UIConfig.localStoreKeys.cartCount, res.data.cart.totalQuantity);
            setNewUpdatedCart(res.data.cart);
            setCartTotalQuantity(parseInt(res.data.cart.totalQuantity));
            showBuyNow(parseInt(res.data.cart.totalQuantity));
            const f1SeatedProduct =
              res.data.cart &&
              res.data.cart.items &&
              res.data.cart.items.find((item) => {
                return isF1SeatedTypeProduct(item);
              });

            if (isYasArenaJourney()) {
              const yaProduct =
                res.data.cart && res.data.cart.items && res.data.cart.items.find((item) => !isAddonProduct(item));

              if (yaProduct) {
                scrollToAddons();
                window.PubSub.publish('startCartTimer', {
                  startDate: yaProduct.timeStamp,
                });
              }
            }

            if (getLoggedInUser().tenantID && getLoggedInUser().tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
              if (f1SeatedProduct) {
                window.PubSub.publish('startCartTimer', {
                  startDate: f1SeatedProduct.timeStamp,
                });
              } else {
                window.PubSub.publish('stopCartTimer');
              }
            }
          })
          .catch((res) => {
            if (getLoggedInUser().tenantID && getLoggedInUser().tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
              window.PubSub.publish('stopCartTimer');
            }
            setCartTotalQuantity(0);
            showBuyNow(0);
          });
    }
  };

  const toggleMiniCart = (hideCart) => {
    let bodyRef = document.getElementsByTagName('body')[0];
    let headerRef = document.getElementsByClassName('c-header')[0];
    let stickyRef = document.getElementsByClassName('sticky-header')[0];
    let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    let removeStickyMenu = headerRef.offsetHeight <= winScroll;

    if (hideCart) {
      bodyRef.classList.remove('disable-scroll');
      !removeStickyMenu && headerRef.classList.remove('sticky-header');
      isAnnualPassPage && headerRef && headerRef.classList.remove('show-cart-content');
    } else {
      bodyRef.classList.add('disable-scroll');
      !stickyRef && headerRef.classList.add('sticky-header');
      isAnnualPassPage && headerRef && headerRef.classList.add('show-cart-content');
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      let headerRef = document.getElementsByClassName('c-header')[0];
      let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      let removeStickyMenu = headerRef?.offsetHeight <= winScroll;
      if (
        (cartRef &&
          cartRef.current &&
          cartRef.current == 'cart-dropdown-wrap' &&
          (cartRef.current == event.target || !cartRef.current.contains(event.target)) &&
          event.target &&
          event.target.id !== 'header-cart' &&
          !event.target.classList.contains('loading') &&
          event.target.classList !== ('lightHamburger' || 'darkHamburger')) ||
        event.target.classList.contains('cart-dropdown-wrap') ||
        event.target.id === 'hamburger-cross-icon'
      ) {
        setHideCart(false);
        !removeStickyMenu && document.getElementsByClassName('c-header')[0].classList.remove('sticky-header');
        document.getElementsByTagName('body')[0].classList.remove('disable-scroll');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mouseup', handleClickOutside);
  }, [cartRef]);

  const clearCartIfItIsTicketUpgradeType = () => {
    if (canUseDOM()) {
      if (!isTicketUpgrade && isTicketUpgradeJourney()) {
        removeTicketUpgradeCart();
      }
    }
  };

  const renderSwadCart = () => {
    return isMobile
      ? miniCartObj.isHeaderMiniCartEnabled && (
          <div className={className('c-header-top-icon-wrap', { active: hideCart })}>
            <button
              className="c-header-top-icon cart"
              id="header-cart"
              tabIndex={0}
              onClick={(e) => {
                e.stopPropagation();
                document.body.classList.toggle('minicart-expanded');
                if (miniCartObj?.isHeaderMiniCartEnabledOnBookingToPaymentPages) {
                  window.PubSub.publish(UIConfig.headerCartClick, {
                    isClicked: true,
                    isEmptyToggle: !toggleEmptyMiniCart,
                  });
                  setToggleEmptyMiniCart(!toggleEmptyMiniCart);
                  /* addded for mincart fwad */
                  if (checkIfParks()) toggleMiniCart(true);
                  isSwad && GTMData.push('viewCart', newUpdatedCart?.items, true);
                } else {
                  let showCartRef = document.getElementsByClassName('show-cart-content')[0];
                  if (showCartRef) {
                    toggleMiniCart(true);
                    setHideCart(false);
                    return;
                  }
                  toggleMiniCart(hideCart);
                  setHideCart(!hideCart);
                  if (isSwad) {
                    GTMData.push('viewCart', newUpdatedCart?.items, true);
                    window.PubSub.publish('toggleHeaderCart', {
                      toggle: true,
                    });
                  }
                }
              }}
            >
              <SvgSprite id="icn-cart" cssClass="cart-icon" />
              {parseInt(cartTotalQuantity) ? <p className="total-quantity"> {cartTotalQuantity} </p> : null}
            </button>
            {/* {HeaderMiniCartTesting()} */}

            {!miniCartObj?.isHeaderMiniCartEnabledOnBookingToPaymentPages && (
              <div
                ref={cartRef}
                className={`cart-dropdown-wrap ${parseInt(cartTotalQuantity) ? 'hasOrder' : 'noOrder'} ${
                  hideCart && !isAnnualPassPage ? 'showCart' : ''
                }`}
              >
                <div className="header-miniCart">
                  {/* {miniCartObj.data && <PurchaseJourney data={miniCartObj.data} isMiniCart={true} />} */}
                  {miniCartObj.isHeaderMiniCartEnabled && (hideCart || isAnnualPassPage) && (
                    <HeaderMiniCart data={miniCartObj} updatedCart={newUpdatedCart} />
                  )}
                </div>
              </div>
            )}
          </div>
        )
      : '';
  };
  const checkIfSelectedParks = () => checkParksTenants() && !isMatchTenant(UIConfig.SWADB2C);

  const checkIfQAW = () => tenantId.toLowerCase() === UIConfig.tenants.ppad;

  const toggleHumbugerCloseIcon = () => {
    if (menuExpanded || profileExpanded) {
      return <SvgSprite id={checkIfQAW() ? 'icn-close-white-strong' : 'icn-close-white'} />;
    }
    if (!isHeaderVariant) {
      return <img src={hamburgerIcon} alt={data.headerAriaLabel} />;
    } else {
      return (
        <>
          <img src={hamburgerIconRevamp} alt={data.headerAriaLabel} className="lightHamburger" />
          <img src={hamburgerIconRevampDark} alt={data.headerAriaLabel} className="darkHamburger" />
        </>
      );
    }
  };

  const renderButtonProfile = (data) => {
    if (!data.isLoginDisabled) {
      if (
        !isLoggedIn &&
        (data.variant === 'v-header-b2c' ||
          data.variant === 'v-ya-header' ||
          data.variant === 'v-header-yasisland' ||
          data.variant === 'v-header-swad' ||
          data.variant === 'v-header-multimeganav')
      ) {
        if (enableYasIDVersion === 'true') {
          return (!isHeaderVariant || isYmcMobile) && profileTopIcon(data);
        } else {
          return (
            <a
              aria-label={data?.loginCta ? data?.loginCta?.label : UIConfig.loginCta.label}
              className="c-header-top-icon profile link-disable"
              role="button"
              href={resolvePath(data, 'loginCta.href') ? data?.loginCta?.href : null}
              onClick={() => (resolvePath(data, 'loginCta.href') ? null : unAvailableLoginCta())}
            >
              <SvgSprite id="icn-user" />
            </a>
          );
        }
      } else {
        return (
          (!isHeaderVariant || isYmcMobile) && (
            <div
              className="c-header-top-icon profile"
              onClick={toggleProfileState}
              role="button"
              aria-label={data?.loginCta ? data?.loginCta?.label : UIConfig?.loginCta?.label}
            >
              <SvgSprite id="icn-user" />
              <SvgSprite id="logged-in-icon" />
            </div>
          )
        );
      }
    }
  };

  const renderCart = (body) => {
    const checkCartCta = body.cart && body.cartCta;

    if (!checkCartCta) {
      return <></>;
    }

    return <CartCount data={body} variant={body.variant} scrollToAddons={scrollToAddons} />;
  };

  const renderMenu = (data) => {
    const showcartInHeader = showMiniCartInHeader && parseInt(cartTotalQuantity) > 0 ? true : false;
    if (!menuExpanded && !data.stickySubscribeCta) {
      return renderCart(data);
    }
    if (menuExpanded || profileExpanded) {
      return renderButtonProfile(data, showcartInHeader);
    }
    // codition to hide buy now button !profileExpanded added to show mini cart icon when peofile is extended
    if (showcartInHeader && !profileExpanded) {
      return null;
    }
    return renderBtnContent(data);
  };

  const hideCartBtn = () => {
    setHideCart(false);
  };

  const mobileHeaderTopVariant = (data) => {
    const { logo, logoCta, lightLogo } = data;
    if (showTicketUpgradeHeaderFlag) {
      return (
        <div id="c-header-top" className="c-header-top ticket-upgrade-header">
          <div className="c-header-top-logo">
            {logoCta && logo && (
              <a aria-label={logo.imageAltText} href={logoCta.href} target={logoCta.target} className="dark-logo">
                {<Image image={logo} disableLazyLoad={true} />}
              </a>
            )}
            {isHeaderVariant && logoCta && lightLogo && (
              <a aria-label={lightLogo.imageAltText} href={logoCta.href} target={logoCta.target} className="light-logo">
                {<Image image={lightLogo} disableLazyLoad={true} />}
              </a>
            )}
          </div>
        </div>
      );
    }
    return (
      <div id="c-header-top" className="c-header-top min-height-mobile">
        <button
          className={`c-header-top-icon ${menuExpanded || profileExpanded ? 'close' : 'hamburger'}`}
          onClick={toggleAllExpand}
          tabIndex={isEmailVerificationConfirmationPage ? '-1' : '0'}
          aria-label={
            menuExpanded || profileExpanded ? data.hamburgerCloseAriaLabel || 'Close icon' : data.headerAriaLabel
          }
        >
          <div id="hamburger-cross-icon" className={`${menuExpanded || profileExpanded ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className="c-header-top-logo">
          {logoCta && logo && (
            <a aria-label={logo.imageAltText} href={logoCta.href} target={logoCta.target} className="dark-logo">
              {<Image image={logo} disableLazyLoad={true} />}
            </a>
          )}
          {isHeaderVariant && logoCta && lightLogo && (
            <a aria-label={lightLogo.imageAltText} href={logoCta.href} target={logoCta.target} className="light-logo">
              {<Image image={lightLogo} disableLazyLoad={true} />}
            </a>
          )}
        </div>
        {/* condition to show cart for home and booking page - fwad */}
        {showMiniCartInHeader &&
          parseInt(cartTotalQuantity) <= 0 &&
          !profileExpanded &&
          !isBookingPageForMinicart &&
          hideCart &&
          hideCartBtn()}
        {(showMiniCartInHeader &&
          parseInt(cartTotalQuantity) > 0 &&
          !menuExpanded &&
          !profileExpanded &&
          !isBookingPageForMinicart) ||
        (showMiniCartInHeader && isBookingPageForMinicart && !menuExpanded && !profileExpanded)
          ? renderSwadCart()
          : renderMenu(data)}
        {/* condition to show profile icon when menu is expanded - fwad */}
        {/* {(menuExpanded || profileExpanded) && showMiniCartInHeader ? renderMenu(data) : null} */}
      </div>
    );
  };

  const headerTopVariant = (data) => {
    const { logo, logoCta, lightLogo } = data;
    if (showTicketUpgradeHeaderFlag) {
      return (
        <div className="c-header-top-logo">
          <a aria-label={logo.imageAltText} href={logoCta.href} target={logoCta.target} className="dark-logo">
            {<Image image={logo} disableLazyLoad={true} />}
          </a>
        </div>
      );
    } else {
      return (
        <div className="c-header-top">
          {renderButtonProfile(data)}

          {(!isHeaderVariant || isYmcMobile) && data.whatsAppUrl && (
            <a
              aria-label="Whatsapp"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="c-header-top-icon whatsapp"
              role="button"
              href={data.whatsAppUrl}
            >
              <SvgSprite id="icn-whatsapp-header" />
            </a>
          )}

          {(!isHeaderVariant || data.variant === UIConfig.commonVariant.multiMegaNavHeaderVariant) &&
          data.isWishListEnabled ? (
            <a
              aria-label={data.wishListLink.label}
              className={`c-header-top-icon wishlist ${wishlistActive ? 'active' : ''}`}
              role="button"
              href={data.wishListLink.href}
            >
              {''}
            </a>
          ) : null}

          <div className="c-header-top-logo">
            {logoCta && logo && (
              <a aria-label={logo.imageAltText} href={logoCta.href} target={logoCta.target} className="dark-logo">
                {<Image image={logo} disableLazyLoad={true} />}
              </a>
            )}
            {isHeaderVariant && logoCta && lightLogo && (
              <a aria-label={lightLogo.imageAltText} href={logoCta.href} target={logoCta.target} className="light-logo">
                {<Image image={lightLogo} disableLazyLoad={true} />}
              </a>
            )}
          </div>
          {isHeaderVariant && (
            <div className="c-header-top-logo-text">
              <span>{data.headingLabel || ''}</span>
            </div>
          )}

          <div>
            {data.variant === 'v-header-swad' &&
              miniCartObj.isHeaderMiniCartEnabled &&
              renderBookTicketBtnWihoutLogged(data)}
          </div>
          <div>{data.variant === 'v-header-swad' && renderSwadCart()}</div>
          {data.languageSelectorOnTopMenu ? (
            !isHeaderVariant ? (
              renderLanguageSwitcher()
            ) : (
              <span className="top-mobile-nav">
                {renderLanguageSwitcher()}
                {data.variant === UIConfig.commonVariant.headerVariant &&
                  data.isShowCurrencyToggle === true &&
                  isMobile && <span>{renderCurrencySwitcher()}</span>}
                {!isLoggedIn && profileTopIcon(data)}
                {isLoggedIn && (
                  <span className="loggedMobileListing">{renderProfileTooltipVariant(data, userInfo, isLoggedIn)}</span>
                )}
              </span>
            )
          ) : (
            <button
              className={`c-header-top-icon ${menuExpanded || profileExpanded ? 'close' : 'hamburger'}`}
              onClick={toggleAllExpand}
              tabIndex="0"
              aria-label={
                menuExpanded || profileExpanded ? data.hamburgerCloseAriaLabel || 'Close icon' : data.headerAriaLabel
              }
            >
              {menuExpanded || profileExpanded ? (
                <SvgSprite id="icn-close-white" />
              ) : !isHeaderVariant ? (
                <img src={hamburgerIcon} alt={data.headerAriaLabel} />
              ) : (
                <>
                  <img src={hamburgerIconRevamp} alt={data.headerAriaLabel} className="lightHamburger" />
                  <img src={hamburgerIconRevampDark} alt={data.headerAriaLabel} className="darkHamburger" />
                </>
              )}
            </button>
          )}
        </div>
      );
    }
  };

  const renderBtnContent = (body) => {
    return (
      <div className="btn-subscribe btn-primary reverse sticky">
        <AnchorLink link={{ ...body.stickySubscribeCta }} />
      </div>
    );
  };

  const renderStickySubscribeBtn = (body) => {
    if (body.stickySubscribeCta && window.location.pathname !== body.stickySubscribeCta.href) {
      const footer = document.getElementsByClassName('footer-wrapper')[0];
      footer && footer.classList.add('btn-subscribe-sticky');
      return renderBtnContent(body);
    } else {
      document.body.classList.add('purchase-cookie');
    }
    return null;
  };
  const sendDataToParent = (val) => {
    setsearchiconstatus(val);
  };

  const renderHeaderBottom = (body, type, logged) => {
    if (isMobile && checkIfSelectedParks()) {
      return null;
    }
    return (
      <HeaderBottom
        miniCartData={miniCartObj}
        searchSettings={searchSettings}
        isMobile={isMobile}
        body={data}
        type={type}
        logged={isLoggedIn}
        variant={data.variant}
        wishlistActive={wishlistActive}
        localStorageUserInfo={userInfo}
        loadActionButtons={loadActionButtons}
        unAvailableLoginCta={unAvailableLoginCta}
        sendDataToParent={sendDataToParent}
        searchVariant={searchSettingVariant}
        sendToParent={searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? setIsParentData : ''}
        isParks={checkIfSelectedParks()}
        scrollToAddons={scrollToAddons}
        newUpdatedCart={newUpdatedCart}
        setNewUpdatedCart={setNewUpdatedCart}
      />
    );
  };

  const isTicketUpgradePage = () => {
    if (canUseDOM()) {
      const checkOrderId = canUseDOM() && parseQueryString('orderId');
      const checkUserId = canUseDOM() && parseQueryString('userId');
      if (isTicketUpgrade && checkOrderId && checkUserId) {
        console.log('header can use DOM, checkOrderId, checkUserId return true', checkOrderId, checkUserId);
        return true;
      }
    }
    return false;
  };

  const isPaymentPage = () => {
    if (canUseDOM()) {
      if (isTicketUpgrade && isTicketUpgradePayment()) {
        return true;
      }
    }
    return false;
  };

  const renderHeaderContent = () => {
    const FinalHeader = (
      <>
        <HeaderBody
          searchSettings={searchSettings}
          body={data}
          type={type}
          logged={isLoggedIn}
          variant={data.variant}
          isMobile={isMobile}
          wishList={data.isWishListEnabled}
          wishlistActive={wishlistActive}
          localStorageUserInfo={userInfo}
          isParks={checkIfSelectedParks()}
          logout={logoutUser}
          isBodyHeight={isBodyHeight}
          miniCart={miniCartObj}
          isFwad={isParks}
          hideCart={hideCart}
          setHideCart={setHideCart}
          cartTotalQuantity={cartTotalQuantity}
        />
        {canUseDOM() && renderHeaderBottom(data, type, isLoggedIn)}
      </>
    );

    if (canUseDOM() && showTicketUpgradeHeaderFlag) {
      return null;
    }

    if (isYmcMobile) return <section className="header-mobile-ymc">{FinalHeader}</section>;

    return FinalHeader;
  };

  const checkLoggedIn = () => {
    const idTokenAvail = document.cookie.includes('idToken');
    if (isLoggedIn || (idTokenAvail && isBeforeDomLoads)) {
      return true;
    }
    return false;
  };

  try {
    return (
      data && (
        <header
          className={`${data.headerVariant} ${isStickyHeaderVar ? 'custom-mobile-header' : ''} ${
            isHeaderSticky && isMobile && isStickyHeaderVar ? 'sticky-header' : ''
          } ${isMiniHeader ? 'custom-mini-header' : ''} ${
            isHeaderVariant &&
            searchiconstatus &&
            searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
              ? 'search-icon-style'
              : ''
          }
          ${
            isHeaderVariant && isParentData && searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
              ? 'search-autosuggest-custom-height'
              : ''
          } ${menuExpanded ? 'mobile-menu-opened' : ''}
          ${cartTotalQuantity > 0 ? 'cart-has-items' : 'cart-has-no-items'}
          ${classNames({
            'header-email-verification-parent': isEmailVerificationConfirmationPage,
          })}
          `}
        >
          <div>
            <div id="skip-to-content">
              <a href="#main-content" onKeyDown={handleSkipToContent} onClick={handleSkipToContent}>
                {data.skipLinkLabel}
              </a>
            </div>
            {isMiniHeader ? (
              <div className={`c-mini-header ${isHeaderStickyPos > 60 ? 'sticky-header' : ''}`}>
                <div className="container">
                  <div className="left-container">
                    <button className="redirect-btn" onClick={handleRedirection}>
                      <span>{data.backLink.label}</span>
                    </button>
                  </div>
                  <div className="right-container">
                    {renderLanguageSwitcher(true)}
                    {!isLoggedIn && profileTopIcon(data)}
                    {checkLoggedIn() && renderProfileTooltipVariant(data, userInfo, isLoggedIn)}
                  </div>
                  <Overlay />
                </div>
              </div>
            ) : (
              <div
                className={`c-header component ${isLoggedIn ? 'header-logged' : 'header-pre-logged'} ${
                  searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? 'search-icon-style' : ''
                } ${isYasHeaderVariant ? 'yas-island' : ''} ${isSwadHeaderVariant ? 'swad' : ''} ${
                  data.variant === UIConfig.commonVariant.yasArenaR1HeaderVariant ? ' v-ya-header-r1' : ''
                } ${data.headerVariant} ${isYasHeaderVariant && isMobile ? 'yas-mobile-header' : ''} ${
                  isSwadHeaderVariant && isMobile ? 'swad-mobile-header' : ''
                } ${showTicketUpgradeHeaderFlag ? 'ticket-upgrade-header' : ''}`}
                id="c-header"
              >
                <div
                  className={`c-header-wrapper${!menuExpanded && !profileExpanded ? ' hide-on-load' : ''} ${
                    profileExpanded ? 'profile-open' : ''
                  }`}
                >
                  {isStickyHeaderVar && mobileHeaderTopVariant(data)}
                  {(data.variant === 'v-header-b2c' ||
                    data.variant === 'v-ya-header' ||
                    data.variant === 'v-ya-header-r1' ||
                    data.variant === 'v-header-yasisland' ||
                    data.variant === 'v-header-swad' ||
                    data.variant === 'v-header-multimeganav') &&
                    !isStickyHeaderVar &&
                    headerTopVariant(data)}
                  {data.variant === 'v-header-view' && headerTop(data)}
                  {data.variant === 'v-header-swad' && !showTicketUpgradeHeaderFlag ? (
                    <div className={className('swad-header-wrapper', { active: showHeader })}>
                      <HeaderBody
                        searchSettings={searchSettings}
                        body={data}
                        type={type}
                        logged={isLoggedIn}
                        variant={data.variant}
                        isMobile={isMobile}
                        wishList={data.isWishListEnabled}
                        wishlistActive={wishlistActive}
                        localStorageUserInfo={userInfo}
                        profileExpanded={profileExpanded}
                        miniCart={miniCartObj}
                        isFwad={isParks}
                        hideCart={hideCart}
                        setHideCart={setHideCart}
                        cartTotalQuantity={cartTotalQuantity}
                      />
                      <HeaderBottom
                        miniCartData={miniCartObj}
                        searchSettings={searchSettings}
                        isMobile={isMobile}
                        body={data}
                        type={type}
                        logged={isLoggedIn}
                        variant={data.variant}
                        wishlistActive={wishlistActive}
                        localStorageUserInfo={userInfo}
                        loadActionButtons={loadActionButtons}
                        unAvailableLoginCta={unAvailableLoginCta}
                        sendDataToParent={sendDataToParent}
                        searchVariant={searchSettingVariant}
                        sendToParent={
                          searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
                            ? setIsParentData
                            : ''
                        }
                        newUpdatedCart={newUpdatedCart}
                        setNewUpdatedCart={setNewUpdatedCart}
                      />
                    </div>
                  ) : (
                    renderHeaderContent()
                  )}
                  {isMobile && !checkIfSelectedParks() && renderStickySubscribeBtn(data)}
                  {checkParksTenants() && clearCartIfItIsTicketUpgradeType()}
                  <Overlay />
                  {isHeaderVariant && searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? (
                    <SearchOverlay />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </div>
        </header>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Header', data.variant);
  }
};

/**
 * basic structure of data contract
 */
Header.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.object.isRequired,
    mobileBodyCopy: PropTypes.string,
    registerCta: PropTypes.object,
    loginCta: PropTypes.object,
    logoutCta: PropTypes.object,
    bookTicketCta: PropTypes.object,
    megaNavData: PropTypes.array,
    profile: PropTypes.object,
    language: PropTypes.object.isRequired,
    search: PropTypes.bool,
    cart: PropTypes.bool,
    searchSettings: PropTypes.object,
  }),
};

export default Header;
