import React, { Component } from 'react';
import UIConfig from '../../../../common/UIConfig';
import classNames from 'classnames';
import SliderComponent from './slider-component';
import {
  getLoggedInUser,
  getAnonymousCart,
  resolvePath,
  isLoggedInUser,
  toTwoDecimalPlaces,
  getCurrency,
  getLocalStorageByKey,
  getRecipientDetails,
  setLocalStorage,
  getCurrentLanguage,
  getOrderIdpromoCode,
  removeGuestUser,
  getUserAgent,
  isAnnualPassCase,
  massageMobileData,
  createAnnualPassData,
  removeMoneyCardStorage,
  getSessionStorage,
  mycartError,
} from '../../../../common/utility';
import {
  cardInputInitializer,
  handleCardNumberPaste,
  expiryInputInitializer,
  cvvInputInitializer,
  rsaEncryption,
  getValidityFormatted,
} from '../../../../common/payment-action/actions';
import { MoneyCardService } from '../../../../common/services';
import { KeyCodes } from '../../../../common/constants';
import Button from '../../../presentation/base/button/button-component.js';
import PayfortUtility from '../payfort/payfort-utility';
import PaymentCheckboxes from '../base/payment-checkbox/payment-checkbox';
import PaymentFormComponent from '../base/payment-form/payment-form-component';
import { guestFormError, shouldProceedToPay, cartCall } from '../../../../common/payment-action/actions';

import { DynamicContent, Input } from '../../../presentation/base';
import { logComponentRenderingError, Logging } from '../../../../common/logger';

import { ServiceConfig } from '../../../../common/services';
import { isEmpty } from '../../../../common/utility/helpers';
class MoneyCard extends Component {
  constructor(props) {
    super(props);
    this.propsData = this.props.propsData;
    this.payData = this.props.payData;
    this.cartData = this.props.cartData;
    this.cardEncryptedValue = null;
    this.lang = 'en';
    this.paymentCase = '';
    this.sliderChanged = false;
    this.serviceKey = {
      moneyCardredemptionReconcile: 'moneyCardredemptionReconcile',
    };

    this.formData = {
      sections: [{ fields: this.payData.paymentOptions.fields }],
    };

    this.inputData = {};
    this.userAgent = getUserAgent();
    this.state = {
      error: false,
      totalMoneyCardBalance: 0,
      guestHasError: true,
      enableButtonBasedOnMoneyCard: true,
      updatedMoneyCardValue: 0,
      updatedCreditCardValue: 0,
      data: [
        {
          value: '',
          checkClicked: false,
          error: false,
          isLabel: false,
          duplicateError: false,
          id: 0,
          cardBalance: 0,
          serviceError: '',
          regexError: false,
        },
      ],
    };

    this.cardDetails = {};
    this.anonymousUser = !isLoggedInUser();
  }
  componentDidMount() {
    if (!isLoggedInUser()) {
      window.PubSub.subscribe('guestFormHasError', this.checkGuestFormError);
    }
    window.PubSub.subscribe('recipientdetailsHasError', this.checkGuestFormError);
  }

  dateValidationInitialize = () => {
    const today = new Date();
    this.currentMonth = today.getMonth();
    this.currentYear = today
      .getFullYear()
      .toString()
      .substr(-2);
    const validationObject = this.payData.paymentOptions.fields.filter((value) => {
      return value.id === 'validity';
    });
    const dateValidationErrorArray = validationObject[0].validations.filter((value) => {
      return value.regex === '';
    });
    this.dateValidationError = dateValidationErrorArray[0].error;
  };

  handleFormFields = () => {
    const {
      paymentOptions: { cardTypes },
    } = this.propsData;

    cardInputInitializer(cardTypes, this.payData.paymentCards);
    expiryInputInitializer();
    cvvInputInitializer();
    this.dateValidationInitialize();
    document.getElementById('cardNumber') &&
      document.getElementById('cardNumber').addEventListener('paste', (e) => handleCardNumberPaste(e, cardTypes));
  };

  componentDidUpdate(prevProps) {
    if (document.getElementById('cardNumber')) {
      this.handleFormFields();
    }
  }

  // shouldComponentUpdate() {
  //    this.handleFormFields();
  // }

  replacePlaceHolder = (url, value) => {
    const replaceMediaCode =
      url.replace('{ticketMediaCode}', value.toUpperCase()) + '?tenantid=' + ServiceConfig.getTenantId();
    return replaceMediaCode;
  };

  getLastFiveDigit = (value) => {
    let lastFourDigit = '';
    if (value) {
      const len = value.length;
      lastFourDigit = value.slice(len - 5, len);
    }
    return lastFourDigit;
  };

  /**
   *  non loggedin case
   *  check guest form error and update the state of guest form
   */

  checkGuestFormError = (msg, data) => {
    const isErrorExist =
      Object.keys(data).length > 0 && data.hasOwnProperty('isRecipientError')
        ? data.isRecipientError
        : !isLoggedInUser()
        ? guestFormError(data, this.props.isEnabled)
        : false;
    this.setState({
      guestHasError: isErrorExist,
    });
  };

  calculateTotalBalance = () => {
    let balance = 0;
    this.state.data.forEach((item) => {
      if (item.cardBalance) balance += parseFloat(item.cardBalance);
    });
    this.sliderChanged = false;
    this.setState({ totalMoneyCardBalance: balance, updatedCreditCardValue: 0 });
  };

  handleCheckBalance = (value, index, data) => {
    const { checkMoneyCardBalance } = this.propsData.services;
    const { url } = checkMoneyCardBalance;

    const self = this;
    if (value) {
      const arr = self.state.data;
      MoneyCardService.getMoneyCardBalance({
        url: this.replacePlaceHolder(url, value),
        moduleName: UIConfig.paymentOption.moneyCard,
        preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
      })
        .then(function({ data }) {
          if (data && data.orderticketdetails && data.orderticketdetails.tickets[0]) {
            const { usageRecap } = data.orderticketdetails.tickets[0];

            const arr = self.state.data;
            arr[index].cardBalance = usageRecap.balance;
            arr[index].checkClicked = true;
            arr[index].isLabel = true;
            arr[index].value = usageRecap.mediaCode;
            arr[index].serviceError = '';
            arr[index].regexError = false;

            self.setState({ data: [...arr] }, () => self.calculateTotalBalance());
          } else if (data && data.error && data.error.errorcode) {
            // self.setState({ error: true });
            arr[index].serviceError = data.error.errorcode;
            self.setState({ data: [...arr] }, () => self.calculateTotalBalance());
          }
        })
        .catch((err) => {
          Logging(err, 'Check balance API response failed', false, '');
        });
    }
  };

  edit = (index) => {
    let arr = this.state.data;
    arr[index].isLabel = false;
    arr[index].checkClicked = false;
    arr[index].cardBalance = 0;
    arr[index].services = '';
    arr[index].value = '';
    if (this.state.data && this.state.data.length === this.state.data.filter((item) => !item.checkClicked).length) {
      arr = this.state.data[0];
    }
    this.setState({ data: Array.isArray(arr) ? arr : [arr] }, () => this.calculateTotalBalance());
  };

  add = () => {
    this.state.data.push({
      value: '',
      checkClicked: false,
      isLabel: false,
      error: true,
      duplicateError: false,
      id: this.state.data.length,
      serviceError: '',
      regexError: false,
    });
    this.setState({ data: this.state.data });
  };

  checkDuplicateError = () => {
    const editFieldArray = this.state.data.filter((item) => !item.isLabel);
    editFieldArray.length &&
      editFieldArray.forEach((eachItem) => {
        const sameIndex = this.state.data.findIndex(
          (item) => item.value && eachItem.value && item.value === eachItem.value && item.id !== eachItem.id,
        );

        const arr = this.state.data;
        if (sameIndex !== -1) {
          arr[eachItem.id].duplicateError = true;
          arr[eachItem.id].error = true;
          this.setState({ data: [...arr] });
        } else {
          arr[eachItem.id].duplicateError = false;
          arr[eachItem.id].error = false;
          this.setState({ data: [...arr] });
        }
      });
  };

  onChangeHandler = (e, index) => {
    const arr = this.state.data;
    arr[index].value = e.target.value;
    arr[index].error = false;
    arr[index].regexError = false;
    this.setState({ data: [...arr] });
  };

  renderCardComponent = () => {
    const {
      checkMoneyCardBalance: { errors },
    } = this.propsData.services;
    const { addMoneyCardAriaLabel, editMoneyCardAriaLabel } = this.propsData.paymentOptions.paymentLabels;
    let addButtonData = {
      type: 'submit',
      label: '+',
    };
    const { paymentFormSection } = this.propsData.paymentOptions.paymentTypes.find(
      ({ value }) => value === UIConfig.moneyCardProduct.productType,
    );
    this.inputData = {
      name: paymentFormSection.fields[0].name,
      placeholder: paymentFormSection.fields[0].placeholder,
      maxLength: paymentFormSection.fields[0].maxLength,
      validations: paymentFormSection.fields[0].validations,
      type: 'text',
    };
    const buttonData = {
      type: paymentFormSection.fields[1].type,
      label: paymentFormSection.fields[1].label,
    };

    return this.state.data.map((item, index) => {
      const showError = !item.duplicateError ? (item.serviceError ? item.serviceError : false) : '401';
      const regexError = item.regexError ? this.inputData.validations[0].error : '';
      const inputData = { ...this.inputData, placeholder: `${this.inputData.placeholder} ${index + 1}` };
      return (
        <div className="money-card-section">
          {!item.checkClicked ? (
            <div key={item} className="money-card-input-section">
              <div className="input-container">
                <Input
                  data={{ ...inputData, class: showError || regexError ? 'item-error' : '' }}
                  onBlur={(e) => this.handleCustomValidation(e, index)}
                  onChange={(e) => this.onChangeHandler(e, index)}
                  isNewForm="true"
                />
                {(showError || regexError) && <div className="field-error-mesg">{errors[showError] || regexError}</div>}
              </div>
              <div className="check-balance-button btn" tabIndex="0">
                <button
                  type={buttonData.type}
                  disabled={!item.value || item.error || item.regexError}
                  onClick={() => this.handleCheckBalance(item.value, index)}
                  aria-label={buttonData.label}
                >
                  <DynamicContent tagName="span" innerHtml={buttonData.label} />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="card-info-wrapper">
                <p className="card-info">
                  <span className="masked-card-number">****</span>
                  <span className="masked-card-number">****</span>
                  <span className="card-number">{this.getLastFiveDigit(item.value)}</span>
                </p>

                <div className="icons-wrapper">
                  <div className="icon">
                    <DynamicContent
                      tagName="a"
                      innerHtml=" "
                      attrs={{
                        onClick: () => this.edit(index),
                        className: 'update-icon',
                        tabIndex: '0',
                        'aria-label': editMoneyCardAriaLabel,
                      }}
                    />
                  </div>
                </div>
                <Button
                  disabled={false}
                  data={{
                    ...addButtonData,
                    class:
                      index === this.state.data.length - 1 ? 'last-child add-payment-option' : 'add-payment-option',
                    ariaLabel: addMoneyCardAriaLabel,
                  }}
                  isNewForm={true}
                  clickCallback={() => this.add()}
                />
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  regexTest = (value) => {
    if (this.inputData && !isEmpty(this.inputData) && value) {
      const exp = '^([a-zA-Z0-9]+\\s)*[a-zA-Z0-9]+$';
      const regex = new RegExp(exp);
      return !regex.test(value);
    }
    return false;
  };

  handleCustomValidation = (e, index) => {
    const newData = this.state.data.map((item, idx) => {
      return {
        ...item,
        value: idx === index ? e.target.value : item.value,
        error: e.target.value ? false : true,
        serviceError: idx === index ? '' : item.serviceError,
        regexError: idx === index ? this.regexTest(e.target.value) : this.regexTest(item.value),
      };
    });
    this.setState(
      {
        data: newData,
      },
      () => this.checkDuplicateError(),
    );
  };

  /**
   *  blocks payment if conditions not met
   *  conditions : guest form not filled, t&c not checked
   */

  canProceedToPay = () => {
    return shouldProceedToPay({
      checkboxEnabled: this.props.isEnabled,
      tncErrorFunc: this.props.showTnCError,
      guestFormErrorFunc: this.props.guestUserHasErrors,
      guestErrorOnChangeState: this.state.guestHasError,
      submitGuestForm: true,
      cartData: this.cartData,
    });
  };

  submitMoneyCardRedemptionOnEnter = (e) => {
    if (e.which === KeyCodes.Enter) {
      // 13 is key code for enter key
      this.submitDataMoneyCard(e);
    }
  };

  payButtonCallback = () => {
    this.props.usePayFortForMoneyCard(this.cardDetails);
  };

  getReconcileData = (paymentData) => {
    let data = {};
    let userProfileData = {};
    // const paymentData = getLocalStorageByKey(UIConfig.localStoreKeys.payment.cmsData) || {};
    const isRenewAnnualPass = resolvePath(paymentData, 'cmsData.pageVariant') === 'annualPassRenew';
    if (this.anonymousUser) {
      const anonymousUserData = getAnonymousCart();
      userProfileData.userId = resolvePath(anonymousUserData, 'cart.reservationOwner.email', '');
    } else {
      const loggedInUserData = getLoggedInUser();
      userProfileData.userId = loggedInUserData && loggedInUserData.email;
    }

    const {
      apMainPassholderRenewEmailTemplateID,
      apMainPassholderPurchaseEmailTemplateID,
      apAddPassholderRenewEmailTemplateID,
      apAddPassholderPurchaseEmailTemplateID,
      apAddPassholderNewUserRenewEmailTemplateID,
      apAddPassholderNewUserPurchaseEmailTemplateID,
      emailTemplateId,
      invoiceTemplateId,
      tenantID,
    } = paymentData.cmsData;

    const cartData = isRenewAnnualPass
      ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
      : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart));
    if (cartData) {
      let isAnnualPass = isAnnualPassCase(cartData, true);
      if (isAnnualPass) {
        const annualPassTicketHolders = createAnnualPassData({ cartData });
        data.annualPassRequest = {
          isAnnualRenew: isRenewAnnualPass,
          emailTemplateIdAnnualPass: isRenewAnnualPass
            ? apMainPassholderRenewEmailTemplateID
            : apMainPassholderPurchaseEmailTemplateID,
          emailTemplateIdWithYasId: isRenewAnnualPass
            ? apAddPassholderRenewEmailTemplateID
            : apAddPassholderPurchaseEmailTemplateID,
          emailTemplateIdWithNoYasId: isRenewAnnualPass
            ? apAddPassholderNewUserRenewEmailTemplateID
            : apAddPassholderNewUserPurchaseEmailTemplateID,
          ticketHolders: annualPassTicketHolders,
        };
      }
    }
    data.locale = getCurrentLanguage();
    data.orderId = paymentData.orderData.orderId;
    data.emailTemplateId = emailTemplateId;
    data.invoiceTemplateId = invoiceTemplateId;
    data.marketType = '';
    data.paymentMethod = UIConfig.moneyCardProduct.productType;
    data.tenantId = tenantID;
    const getOrderIdpromoCodeTemp = getOrderIdpromoCode();
    const orderIdpromoCode = getOrderIdpromoCodeTemp ? getOrderIdpromoCodeTemp.split('_') : 'null';
    data.promoCode = orderIdpromoCode[1] === 'null' ? null : orderIdpromoCode[1];
    data.userId = userProfileData.userId;
    data.payfortReconcileObj = null;
    data.totalAmount = this.props.cartData.grossPrice;
    data.redemptionRequest = {
      tpRedemptionFlag: this.paymentCase,
      cardAmount: 0,
      redeemAmount: this.props.cartData.grossPrice,
      moneyCardList: this.state.data.reduce((acc, item) => {
        if (item.value) {
          acc.push({ moneyCardMediaCode: item.value, balance: item.cardBalance });
        }
        return acc;
      }, []),
    };
    return data;
  };

  handleCreateOrderSuccess = () => {
    window.PubSub.subscribe('CreateOrderSuccess', (msg, data) => {
      let cmsData = getLocalStorageByKey(UIConfig.localStoreKeys.payment.cmsData);
      if (data && data.isCreateOrderSuccess) {
        cmsData.orderData = {
          resCode: data.resCode,
          orderId: data.orderId,
          redemptionRequest: {},
        };

        cmsData.cmsData = {
          ...cmsData.cmsData,
          marketType: this.payData.marketType,
          thankYouPageUrl: this.payData.thankYouPageUrl,
          componentName: this.payData.componentName,
          notEncrypted: this.payData.notEncrypted,
        };

        setLocalStorage(UIConfig.localStoreKeys.payment.cmsData, JSON.stringify(cmsData));

        MoneyCardService.getMoneyCardFullRedemption({
          url: cmsData.services.moneyCardredemptionReconcile.url,
          apiData: this.getReconcileData(cmsData),
          preLoaderTarget: '.otp-overlay',
        })
          .then((response) => {
            if (response.data && response.data.isSuccess) {
              Logging(
                response,
                cmsData.cmsData.componentName || 'MoneyCardRedemption',
                true,
                'step2 reconcile price success',
              );
              this.redirectToThankYouPage(true, cmsData.orderData.orderId);
            } else {
              Logging(response, cmsData.cmsData.componentName, false, 'Money Card Redemption Status False');
              this.redirectToThankYouPage(
                false,
                0,
                this.serviceKey.moneyCardredemptionReconcile,
                response.data.responseMessage.error,
                {},
                'System Failure',
              );
            }
          })
          .catch((err) => {
            Logging(err, cmsData.cmsData.componentName, false, 'Money Card Redemption api call Failed');
            this.redirectToThankYouPage(
              false,
              0,
              this.serviceKey.moneyCardredemptionReconcile,
              err.error,
              {},
              'System Failure',
            );
          });
      } else {
        mycartError();
      }
    });
  };

  setStoragePurchaseInfo = (cartGrossPrice = 0, redeemAmount = 0) => {
    const { updatedCreditCardValue } = this.state;
    const moneyCardPI = {
      paymentType:
        updatedCreditCardValue > 0
          ? UIConfig.b2c.purchaseJourney.moneyCard.paymentType.partial
          : UIConfig.b2c.purchaseJourney.moneyCard.paymentType.full,
      redeemAmount: redeemAmount,
      cardAmount: updatedCreditCardValue,
      moneyCardList: this.state.data.reduce((acc, item) => {
        if (item.value) {
          acc.push({ moneyCardMediaCode: item.value, balance: item.cardBalance });
        }
        return acc;
      }, []),
    };

    setLocalStorage(UIConfig.b2c.purchaseJourney.moneyCard.purchaseInfo, JSON.stringify(moneyCardPI));
  };

  handlePayFortFormDetails = (cardNumber, validity, cardCvv) => {
    if (cardNumber && cardNumber.value && validity.value) {
      const cardNumberValue = cardNumber.value.replace(/\s/g, ''),
        validityValue = getValidityFormatted(validity.value); // YYMM

      this.cardEncryptedValue = rsaEncryption(cardNumberValue);
      this.expiryDateEncryptedValue = rsaEncryption(validityValue);

      this.cardDetails = {
        cardNumber: cardNumberValue,
        validity: validity.value,
        cvv: cardCvv.value,
      };
      // this.getTouchPointsBalance();
    }
  };

  submitDataMoneyCard = (e) => {
    window.PubSub.unsubscribe('CreateOrderSuccess');
    const { grossPrice } = this.props.cartData;
    const { errorCode, updatedCreditCardValue, updatedMoneyCardValue } = this.state;
    let formSubmitError = false;
    const cardNumber = document.getElementById('cardNumber'),
      validity = document.getElementById('validity'),
      cardCvv = document.getElementById('CVV');
    if ((cardNumber && !cardNumber.value) || (validity && !validity.value) || (cardCvv && !cardCvv.value)) {
      formSubmitError = true;
    }
    if (this.anonymousUser) {
      window.PubSub.subscribe('guestFormHasError', this.checkGuestFormError);
    }

    if (
      this.anonymousUser &&
      (this.props.guestUserHasErrors() || document.querySelectorAll('.yasid-email-error').length > 0)
    ) {
      formSubmitError = true;
    }

    if (formSubmitError) {
      window.PubSub.publish('initiatePaymentSubmitValidation');
      return;
    } else {
      this.handlePayFortFormDetails(cardNumber, validity, cardCvv);
    }

    // if (remainingAmount < cartAmount) {
    if (updatedCreditCardValue <= grossPrice) {
      this.setStoragePurchaseInfo(grossPrice, updatedMoneyCardValue);
    }

    if (!this.anonymousUser && this.props.isEnabled) {
      const updateCartURL = this.propsData.services.updateCart.url;
      const isCallReqForFullMoneyCard = Boolean(
        (updatedCreditCardValue === grossPrice && !updatedMoneyCardValue && this.cartData && !isEmpty(this.cartData)) ||
          this.props.isRenewAnnualPass,
      );
      if (this.props.annualPassCartData && isAnnualPassCase({ cart: this.props.annualPassCartData }, true)) {
        this.cartData = this.props.annualPassCartData;
      }

      if (updatedCreditCardValue < grossPrice || isCallReqForFullMoneyCard) {
        const recipientDetails = getRecipientDetails();
        this.cartData.personalisedGiftObj = recipientDetails;

        cartCall(
          updateCartURL,
          { cart: this.cartData },
          this.props.isRenewAnnualPass,
          UIConfig.moneyCardProduct.productType,
        )
          .then((res) => {
            if (updatedCreditCardValue <= 0) {
              this.handleCreateOrderSuccess();
              this.props.setRedirectPaymentData();
            } else {
              this.payButtonCallback();
            }
          })
          .catch((res) => {
            this.props.isErrorOccured('updateCart', res.error);
          });
      } else {
        // for full payment using PayFort
        this.payButtonCallback();
      }
    } else if (!this.anonymousUser && !this.canProceedToPay()) {
      return false;
    }

    if (this.anonymousUser) {
      const anonymousUserData = getAnonymousCart();
      const userData = resolvePath(anonymousUserData, 'cart.reservationOwner', '');
      const details = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        mobileNumber: userData.mobileNumber,
        country: userData.country,
        nationality: userData.nationality,
      };

      setLocalStorage('guestUser', JSON.stringify(details));

      if (updatedCreditCardValue <= 0) {
        if (!this.canProceedToPay()) {
          return false;
        }
        this.handleCreateOrderSuccess();
      } else {
        if ((!this.state.onlyHasGuestFormError || !this.anonymousUser) && !this.props.isEnabled) {
          this.canProceedToPay();
          return false;
        } else {
          this.payButtonCallback();
        }
      }
    }
  };

  pageRedirect = ({ paymentPageErrorMessage, status, paymentData }) => {
    const thankYouPageVar = UIConfig.querStringParams.thankYouPage;
    window.top.location.href = `${resolvePath(paymentData, 'cmsData.thankYouPageUrl')}?${
      thankYouPageVar.status
    }=${status}&${thankYouPageVar.reference}=${UIConfig.moneyCardProduct.productType}`;
  };

  redirectToThankYouPage = (
    status,
    orderId = 0,
    serviceKey = '',
    responseOrError = null,
    paymentPageErrorMessage = {},
    paymentStatus,
  ) => {
    const paymentData = getLocalStorageByKey(UIConfig.localStoreKeys.payment.cmsData) || {};
    const isRenewAnnualPass = resolvePath(paymentData, 'cmsData.pageVariant') === 'annualPassRenew';
    PayfortUtility.clearLocalStore();
    removeMoneyCardStorage();
    removeGuestUser();
    if (!this.userAgent) {
      if (status) {
        localStorage.removeItem(UIConfig.localStoreKeys.yasIdUserData);
        if (isRenewAnnualPass) {
          sessionStorage.removeItem('cartForAnnualPassRenewal');
          sessionStorage.removeItem(UIConfig.payloadForRenew);
          sessionStorage.removeItem('annualPassRenewalData');
        }
        const isRenewAnnualPassStr = isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
        const isEncryptedStr = resolvePath(paymentData, 'cmsData.notEncrypted') ? '&notEncrypted=true' : '';
        localStorage.removeItem('userReservationInfo');
        const thankYouPageVar = UIConfig.querStringParams.thankYouPage;
        window.top.location.href = `${resolvePath(paymentData, 'cmsData.thankYouPageUrl')}?${
          thankYouPageVar.status
        }=${status}&${thankYouPageVar.orderId}=${encodeURIComponent(orderId)}&${thankYouPageVar.reference}=${
          UIConfig.moneyCardProduct.productType
        }${isEncryptedStr}&isPage=${resolvePath(paymentData, 'cmsData.pageVariant')}${isRenewAnnualPassStr}`;
      } else {
        const errorData = PayfortUtility.getErrorObject(serviceKey, paymentData.services, responseOrError);
        localStorage.setItem(UIConfig.localStoreKeys.payment.errorMessage, JSON.stringify(errorData));
        this.pageRedirect({ paymentPageErrorMessage, status, paymentData });
      }
    } else {
      window.JSbridge.nativeCallback(
        massageMobileData({
          status: status,
          orderID: orderId || null,
          ref: UIConfig.moneyCardProduct.productType,
        }),
      );
    }
  };

  enableBasedOnCardValue = () => {
    const { grossPrice } = this.props.cartData;

    const moneyCadVal = this.sliderChanged ? this.state.updatedMoneyCardValue : this.state.totalMoneyCardBalance;
    if (grossPrice <= moneyCadVal) {
      this.paymentCase = 'FULL';
    } else {
      if (this.state.updatedCreditCardValue + this.state.updatedMoneyCardValue === grossPrice) {
        this.paymentCase = 'PARTIAL';
      } else if (this.state.totalMoneyCardBalance < grossPrice && this.state.updatedCreditCardValue === grossPrice) {
        this.paymentCase = 'PARTIAL';
      }
    }
    return false;
  };
  renderMoneyCardPayButton = () => {
    const {
      propsData: { paymentOptions },
      openOverlay,
      onTncChangeHandler,
      showCheckBox,
      hideNewsletterOption,
      disableBookButton,
      hasTnCError,
      tabIndex,
      isEnabled,
      pointsPayConfig,
    } = this.props;
    return (
      <div className="c-form moneycard-payment-wrapper checkbox-wrapper-step2">
        <PaymentCheckboxes
          paymentOptions={paymentOptions}
          openOverlay={openOverlay}
          onTncChangeHandler={onTncChangeHandler}
          key="money-card-term-condition"
          stateData={{
            showCheckBox,
            hideNewsletterOption,
            disableBookButton,
            hasTnCError,
            tabIndex,
            isTncChecked: isEnabled,
            bindCheckBoxError: true,
          }}
        />
        <PaymentCheckboxes
          paymentOptions={paymentOptions}
          openOverlay={openOverlay}
          onTncChangeHandler={onTncChangeHandler}
          showNewsLetterCheckBoxOnly={true}
          key="money-card-news-letter-condition"
          stateData={{
            showCheckBox,
            hideNewsletterOption,
            disableBookButton,
            hasTnCError,
            tabIndex,
            isTncChecked: isEnabled,
            bindCheckBoxError: true,
          }}
        />
        <div
          className={
            (this.state.guestHasError && this.anonymousUser) || !isEnabled || this.enableBasedOnCardValue()
              ? 'moneycard-proceed-btn c-payment-btn-disabled'
              : 'moneycard-proceed-btn'
          }
        >
          <button
            disabled={false}
            onClick={(e) => this.submitDataMoneyCard(e)}
            onKeyPress={this.submitMoneyCardRedemptionOnEnter}
            aria-label={paymentOptions.primaryCTA.label}
          >
            <DynamicContent tagName="span" innerHtml={paymentOptions.primaryCTA.label} />
          </button>
        </div>
      </div>
    );
  };

  updateMAndCValue = (moneycardvalue, creditcardvalue, sliderValue) => {
    this.sliderChanged = true;
    this.setState(
      {
        updatedMoneyCardValue: parseFloat(moneycardvalue),
        updatedCreditCardValue: parseFloat(creditcardvalue),
        updatedSliderValue: parseFloat(sliderValue),
      },
      () => this.enableBasedOnCardValue(),
    );
  };

  updateInputState = (moneycardvalue, creditcardvalue, sliderValue) => {
    this.setState(
      {
        updatedMoneyCardValue: parseFloat(moneycardvalue),
        updatedCreditCardValue: parseFloat(creditcardvalue),
        updatedSliderValue: parseFloat(sliderValue),
      },
      () => this.enableBasedOnCardValue(),
    );
  };

  renderMoneyCardSection = () => {
    const {
      moneyCardAvailableBalance,
      moneyCardTotalAmountLabel,
      sliderTitle,
      lowBalanceError,
      payfortMoneyCardTitle,
    } = this.propsData.paymentOptions.paymentLabels;
    const { grossPrice, currency } = this.props.cartData;

    return (
      <>
        <div className="w--bottom">
          {this.renderCardComponent()}
          {this.state.data.find((item) => item.checkClicked) && (
            <>
              <div className="balance-section">
                <div>
                  <DynamicContent
                    tagName="span"
                    innerHtml={moneyCardAvailableBalance}
                    attrs={{ className: 'available-bal-label', 'aria-label': `${moneyCardAvailableBalance}` }}
                  />
                  <DynamicContent
                    tagName="span"
                    innerHtml={`${getCurrency()} ${toTwoDecimalPlaces(this.state.totalMoneyCardBalance)}`}
                    attrs={{
                      className: `available-bal-value ${
                        this.state.totalMoneyCardBalance < grossPrice ? 'error-balance' : ''
                      } `,
                      'aria-label': `${this.state.totalMoneyCardBalance}`,
                    }}
                  />
                </div>
                {this.state.totalMoneyCardBalance < grossPrice && (
                  <div className="error-less-balance" aria-label={lowBalanceError}>
                    {lowBalanceError}
                  </div>
                )}
              </div>
              <div class="c-b2c-cart-container">
                <p class="cart-summary-heading">
                  <DynamicContent
                    tagName="span"
                    innerHtml={moneyCardTotalAmountLabel}
                    attrs={{ className: 'total', 'aria-label': `${moneyCardTotalAmountLabel}` }}
                  />
                  <span class="total-title" aria-label={`${currency} ${grossPrice}`}>
                    <span>{currency}</span>
                    <span>{toTwoDecimalPlaces(grossPrice)}</span>
                  </span>
                </p>
              </div>

              <div className="money-card-slider-section">
                <DynamicContent
                  tagName="div"
                  innerHtml={sliderTitle}
                  attrs={{ className: 'slider-header', 'aria-label': `${sliderTitle}` }}
                />
                <SliderComponent
                  totalMoney={grossPrice}
                  currency={currency}
                  moneyCardBal={this.state.totalMoneyCardBalance}
                  sliderLabels={this.propsData.paymentOptions.paymentLabels}
                  updateMAndCState={this.updateMAndCValue}
                  updatedMoneyCardValue={this.state.updatedMoneyCardValue}
                  updatedCreditCardValue={this.state.updatedCreditCardValue}
                  updatedSliderValue={this.state.updatedSliderValue}
                  sliderChanged={this.sliderChanged}
                  getInputStateUpdate={this.updateInputState}
                />
              </div>

              {this.state.updatedCreditCardValue > 0 && (
                <div className="money-card-payment-section c-payment-wrapper">
                  <DynamicContent
                    tagName="div"
                    innerHtml={payfortMoneyCardTitle}
                    attrs={{ className: 'payfort-moneycard-title', 'aria-label': `${payfortMoneyCardTitle}` }}
                  />
                  <div className="c-payfort">
                    <div className="custom-payfort-form">
                      <form
                        action=""
                        method="POST"
                        name="moneycard"
                        id="moneycardform"
                        autoComplete="off"
                        className="c-payment-form contact-us-form c-form c-payfort-form-v1"
                      >
                        <PaymentFormComponent
                          lang={this.lang}
                          propsData={this.propsData}
                          is2StepPayment={this.props.is2StepPayment}
                          data={this.formData}
                          isNewForm={true}
                          isMoneyCard={true}
                          cardImageData={
                            this.props.is2StepPayment
                              ? this.payData
                              : JSON.parse(window.localStorage.getItem(UIConfig.localStoreKeys.payment.payFortData))
                          }
                          payfortType={UIConfig.paymentOption.moneyCard}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              )}

              {this.renderMoneyCardPayButton()}
            </>
          )}
        </div>
      </>
    );
  };

  render() {
    try {
      const containerClasses = classNames(
        'component',
        'c-money-card-payment',
        'money-card',
        'c-payment-b2c',
        'c-payment-b2c-step',
      );
      return (
        <div className={containerClasses}>
          <>{this.renderMoneyCardSection()}</>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'MoneyCard');
    }
  }
}

export default MoneyCard;
