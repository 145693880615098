import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import AddOnsOverlay from '../add-ons-overlay/add-ons-overlay-component';
import DrivingExperienceB2C from './b2c-driving-experience/b2c-driving-experience-component';
import {
  canUseDOM,
  createCustomEvent,
  isEmpty,
  isEnterPressed,
  isEventTypeProduct,
  isMatchTenant,
  checkTenant,
  checkParksTenants,
  toLowerCase,
  checkIfParksTenants,
} from '../../../../common/utility';
import { DynamicContent, Image, Spinner, AnchorLink } from '../../../presentation/base';
import TicketTooltip from '../../../presentation/b2c-purchase-journey/ticket-tooltip/ticket-tooltip';
import UIConfig from '../../../../common/UIConfig';
import RecommendationActions from '../recommendation-rules/recommendation-rules-component';
import AddOnsEventComponent from './add-ons-event';
import { logComponentRenderingError } from '../../../../common/logger';
import userBeforeFirstRender from '../../../../hooks/useBeforeFirstRender';
import GTMData from '../gtm-data';

const GTMDataOnClickCTA = (data) => {
  if (isMatchTenant(UIConfig.tenants.fwad) || isMatchTenant(UIConfig.tenants.yi)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
      name: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.name) : data.name,
      [UIConfig.ga4Constants.ELEMENTTEXT]: isMatchTenant(UIConfig.tenants.yi)
        ? toLowerCase(data.elementText)
        : data.elementText,
      category: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.category) : data.category,
    });
  }
};

const checkForRecommendationControl = (
  ticket,
  isRecommendationControlled,
  recommendationTabName,
  isRecommendationAllowedForAll,
  isPastPurchasedRecommendation,
) => {
  if (
    isRecommendationControlled &&
    ticket.tab &&
    recommendationTabName === (isPastPurchasedRecommendation ? ticket.tab[0].toLowerCase() : ticket.tab.toLowerCase())
  ) {
    const { crossSellType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    // For ADD ONS products which are required to be in unlimited quantity
    if (
      isMatchTenant(UIConfig.ymcB2CTenant) &&
      (isRecommendationAllowedForAll ||
        (ticket.subEventType && ticket.subEventType.toLowerCase() === crossSellType.FEB))
    ) {
      return false;
    } else if (isRecommendationAllowedForAll || isEventTypeProduct(ticket)) {
      return true;
    }
  }
  return false;
};

const componentToAppend = ({ props, closeOverlay, isDrivingExp, ticketDetails }) => {
  const {
    additionalProds,
    addToCartCallback,
    businessErrors,
    cartData,
    category,
    coveoMapList,
    data,
    dateSelector,
    getDiscountedProducts,
    overlayErrorCallback,
    searchProductList,
    services,
    ticket,
    timeSlotSelector,
    variant,
    visitorCounter,
    showMinicartCouponCode,
    discountMap,
    enablePJPopup,
    copySearchProductList,
  } = props;

  let Component = AddOnsOverlay;
  if (data.isEventQuantityOverlay) {
    Component = AddOnsEventComponent;
  } else if (isDrivingExp) {
    Component = DrivingExperienceB2C;
  }

  return (
    <Component
      data={data}
      product={ticket}
      counterData={visitorCounter}
      calenderData={dateSelector}
      addToCartCallback={addToCartCallback}
      closeOverlay={closeOverlay}
      services={services}
      multiTicketSelector={category.multiTicketSelector}
      productOverlaySelector={category.productOverlaySelector}
      showProductNameInOverlay={category.showProductNameInOverlay}
      getDiscountedProducts={getDiscountedProducts}
      timeSlotData={timeSlotSelector}
      cartData={cartData}
      businessErrors={businessErrors}
      additionalProds={additionalProds}
      coveoMapList={coveoMapList}
      searchProductList={searchProductList}
      copySearchProductList={copySearchProductList}
      showMinicartCouponCode={showMinicartCouponCode}
      discountMap={discountMap}
      subCategory={category.coveoValue}
      enablePJPopup={enablePJPopup}
      variant={variant}
      overlayErrorCallback={overlayErrorCallback}
      legends={category.legends}
      moneyCardDetails={category.moneyCardAmount}
      enableDynamicCalendar={category?.enableDynamicCalendar}
      attentions={ticketDetails?.attentions}
      attentionTitle={ticketDetails?.attentionsLabel}
      recommendationUrl={props.recommendationUrl}
    />
  );
};

const openOverlay = (tncPopup, isPJOnly) => {
  if (!isExperienceEditorActive()) {
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      dataToAppend: tncOverlay(tncPopup),
      customClass: classNames('ride-info-overlay c-flash-sale-overlay', { 'v-multi-product-widget': isPJOnly }),
    });
  }
};

const tncOverlay = (tncPopup) => {
  return (
    <div className="overlay-sub-container">
      {tncPopup.tncTitle && (
        <DynamicContent
          tagName="h2"
          innerHtml={tncPopup.tncTitle}
          attrs={{
            className: 'overlay-sub-heading',
          }}
        />
      )}
      {tncPopup.tncDescription && (
        <DynamicContent
          tagName="p"
          innerHtml={tncPopup.tncDescription}
          attrs={{
            className: 'overlay-description',
          }}
        />
      )}
    </div>
  );
};

const checkKeyDownHandler = (e, tncPopup, isPJOnly) => {
  if (isEnterPressed(e)) {
    openOverlay(tncPopup, isPJOnly);
  }
};
// get conditional price classes for wrapper component
const getPriceWrapperClass = (isSubPrice, isOfferPrice) => {
  let className = 'price-wrapper ';
  if (isSubPrice) {
    className += ' sub-price-currency';
  }
  if (isOfferPrice) {
    className += ' remove-margin';
  }
  return className;
};
// get conditional price classes for component
const getClassPriceClassName = (className, isOfferActive) => {
  let classes = className;
  if (isOfferActive) {
    classes += ' active-offer';
  }
  return classes;
};
const renderPriceValue = (value, isSwad) => {
  if (isSwad) {
    return value.toLocaleString('en', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    return value;
  }
};
const renderPrice = ({ price, isOfferPrice, isSwad }) => {
  return (
    <>
      <DynamicContent
        tagName="span"
        attrs={{ className: getClassPriceClassName('c-fast-price-currency', price.isOfferActive) }}
        innerHtml={price.currency}
      />
      <DynamicContent
        tagName="span"
        attrs={{ className: getClassPriceClassName('c-fast-price-price', price.isOfferActive) }}
        innerHtml={isOfferPrice ? renderPriceValue(price.offerPrice, isSwad) : renderPriceValue(price.price, isSwad)}
      />
    </>
  );
};
const checkRmngValue = (remaingingQty) => {
  if (remaingingQty <= 0) {
    return '00';
  }
  if (remaingingQty < 10) {
    return `0${remaingingQty}`;
  }
  return remaingingQty;
};

const renderPriceList = ({ price, index, isPastPurchasedRecommendation, ticketValue, data, showPriceTogether }) => {
  const rmngQtyLabel = data && data.postPurchaseProductAddons?.rmngQtyLabel;
  const unltdAllocLabel = data && data.postPurchaseProductAddons?.unltdAllocLabel;
  const { isUnlimited, maxQuantity, addedQuatity } = !isEmpty(ticketValue) && ticketValue;
  const remaingingQty = maxQuantity - addedQuatity;
  return (
    <>
      <li className="c-fast-price d-inline-block" key={index}>
        <DynamicContent tagName="span" attrs={{ className: 'c-fast-price-description' }} innerHtml={price.for} />
        {price.from && (
          <DynamicContent tagName="span" attrs={{ className: 'c-fast-price-currency' }} innerHtml={price.from} />
        )}
        {showPriceTogether && price.isOfferActive && price.offerPrice ? (
          <div className={getPriceWrapperClass(price.for) + ' offer-price-wrapper'}>
            {renderPrice({ price, isOfferPrice: true, isSwad: showPriceTogether })}
          </div>
        ) : (
          <></>
        )}
        {showPriceTogether ? (
          <div className={getPriceWrapperClass(price.for, price.isOfferActive ? true : false)}>
            {renderPrice({ price, isSwad: showPriceTogether })}
          </div>
        ) : (
          renderPrice({ price })
        )}
      </li>
      {!showPriceTogether && isPastPurchasedRecommendation && ticketValue && (
        <li className="c-remainging-qty d-inline-block" key={index}>
          <DynamicContent
            tagName="span"
            attrs={{ className: 'remainging-qty-label' }}
            innerHtml={isUnlimited ? unltdAllocLabel : `${rmngQtyLabel ?? ''}: `}
          />
          {!isUnlimited && (
            <DynamicContent
              tagName="span"
              attrs={{ className: 'remainging-qty-value' }}
              innerHtml={checkRmngValue(remaingingQty)}
            />
          )}
        </li>
      )}
    </>
  );
};

//function added to handle the keyboard handling of the custom checkboxes
const handleCheckBoxKeyDownEvent = (e) => {
  if (isEnterPressed(e)) {
    handleCheckUncheck(e.target);
  }
};

const handleCheckUncheck = (targetElement) => {
  const checkBox = document.querySelector('#' + targetElement.previousSibling.id);
  if (checkBox !== null) {
    const event = createCustomEvent('change');
    checkBox.checked = !checkBox.checked;
    checkBox.dispatchEvent(event);
  }
};

const handleOnClick = (e, props, coveoValue) => {
  if (!props.isPastPurchasedRecommendation) {
    props.handleOnClick(coveoValue, e.target);
  }
};

const getOverlaySelector = (
  data,
  dateSelector,
  renderQuantityOverlay,
  renderDateSelector,
  renderCounterAndAddToCart,
  ticketDetails,
  renderPriceList,
) => {
  if (data.isQuantityInOverlay) {
    return renderQuantityOverlay();
  } else if (dateSelector) {
    return renderDateSelector(ticketDetails, renderPriceList);
  }
  return renderCounterAndAddToCart(ticketDetails);
};

const AddOnsTicketComponent = (props) => {
  const { isPastPurchasedRecommendation, recommendationsCatergoryData, addedQuatity } = props;
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [enableAddToCart, setEnableAddToCart] = useState(false);
  const [showMaxQuantityError, setShowMaxQuantityError] = useState(false);
  const [showRcmdProductsLimitError, setRcmdProductsLimitError] = useState(false);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isParks = checkIfParksTenants([], [UIConfig.tenants.ppad]);

  const coveoValue = props.category.coveoValue || props.category.ticketSelector.ticket.coveoValue;
  const isRecommendationControlled =
    props.additionalRecommendedData && props.additionalRecommendedData.isRecommendationControlled;
  const isRecommendationAllowedForAll =
    props.additionalRecommendedData && props.additionalRecommendedData.isRecommendationAllowedForAll;
  const recommendationTabName = UIConfig.b2c.purchaseJourney.recommendationTabCode;

  const isPlpVariant = props.variant === 'v-overlay-selected';
  const isPJOnly = props.data.variant === 'v-multi-product-widget';
  const isDynamicPricingProd = props.data.variant === 'v-dynamic-product-pricing';

  let quantity = useRef(props.ticket.quantity || 0);
  const recommendationActions = useRef(null);

  userBeforeFirstRender(() => {
    if (isRecommendationControlled) {
      recommendationActions.current = new RecommendationActions({
        productPackageTypeMapping: props.additionalRecommendedData.productPackageTypeMapping,
      });
    }
  });

  useEffect(() => {
    if (!props.data.isEventQuantityOverlay) {
      canUseDOM() &&
        window.PubSub.subscribe('closeOverlay', () => {
          if (isDynamicPricingProd) {
            props.copySearchProductList.forEach((newCoveo, index) => {
              props.searchProductList[index].gross = newCoveo.gross;
            });
          }
          if (props.variant !== 'v-overlay-selected') {
            setIsOverlayOpen(false);
          }
        });
      if (isPlpVariant) {
        setIsOverlayOpen(true);
      }
    }
    toggleAddToCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // componentWillReceiveProps equivalent code
  const isFirstRun = useRef(true);
  const prevTicket = useRef(null);
  const prevCartData = useRef(null);
  const prevQuantityData = useRef(null);

  useEffect(() => {
    const { ticket, cartData } = props;
    const { crossSellType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    if (isFirstRun.current) {
      isFirstRun.current = false;
      prevTicket.current = ticket;
      prevCartData.current = cartData;
      return;
    }

    if (
      checkForRecommendationControl(
        ticket,
        isRecommendationControlled,
        recommendationTabName,
        isRecommendationAllowedForAll,
        isPastPurchasedRecommendation,
      )
    ) {
      const propParentQuantity = recommendationActions.current.getParentProductQuantity(ticket, cartData);
      const prevPropParentQuantity = recommendationActions.current.getParentProductQuantity(
        prevTicket.current,
        prevCartData.current,
      );
      const propChildQuantity = recommendationActions.current.getChildProductQuantity(ticket, cartData);
      const prevPropChildQuantity = recommendationActions.current.getChildProductQuantity(
        prevTicket.current,
        prevCartData.current,
      );
      const childProductFromCart = recommendationActions.current.getChildProductFromCart(ticket, cartData);
      const recommendedProductFromCart = recommendationActions.current.checkRecommendedProductAvailable(
        ticket,
        cartData,
      );

      if (propParentQuantity > prevPropParentQuantity || propChildQuantity < prevPropChildQuantity) {
        setEnableAddToCart(quantity.current > 0);
        setShowMaxQuantityError(false);
        setRcmdProductsLimitError(false);
      } else if (propParentQuantity !== prevPropParentQuantity || propChildQuantity !== prevPropChildQuantity) {
        quantity.current = 0;
        setEnableAddToCart(quantity.current > 0);
        setShowMaxQuantityError(false);
        setRcmdProductsLimitError(false);
      }
      if (
        childProductFromCart &&
        recommendedProductFromCart &&
        ticket.subEventType.toLowerCase() === crossSellType.ARC
      ) {
        if (propChildQuantity < propParentQuantity) {
          setEnableAddToCart(quantity.current > 0);
        } else {
          setEnableAddToCart(false);
        }
      } else if (recommendedProductFromCart && ticket.subEventType.toLowerCase() === crossSellType.ARC) {
        setEnableAddToCart(false);
      } else {
        setRcmdProductsLimitError(false);
      }
      prevTicket.current = ticket;
      prevCartData.current = cartData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const toggleAddToCart = () => {
    setEnableAddToCart(quantity.current > 0);
  };

  const isMaxQuantity = (category) => {
    const { addedQuatity, maxQuantity, isUnlimited } =
      recommendationsCatergoryData && recommendationsCatergoryData[category];
    if (addedQuatity >= maxQuantity && !isUnlimited) {
      return true;
    }
    return false;
  };

  const updateQuantity = (updatedQuantity, type) => {
    const { ticket, cartData } = props;
    const { crossSellType } = UIConfig.b2c.purchaseJourney.ymcMapping;

    if (
      checkForRecommendationControl(
        ticket,
        isRecommendationControlled,
        recommendationTabName,
        isRecommendationAllowedForAll,
        isPastPurchasedRecommendation,
      )
    ) {
      const maxQuantity = isPastPurchasedRecommendation
        ? recommendationsCatergoryData && recommendationsCatergoryData[ticket.category].maxQuantity
        : recommendationActions.current.checkMaxQuantity(ticket, cartData);
      const childProductFromCart = recommendationActions.current.getChildProductFromCart(ticket, cartData);
      const recommendedProductFromCart = recommendationActions.current.checkRecommendedProductAvailable(
        ticket,
        cartData,
      );
      if (
        !childProductFromCart &&
        recommendedProductFromCart &&
        ticket.subEventType.toLowerCase() === crossSellType.ARC
      ) {
        setRcmdProductsLimitError(true);
        setShowMaxQuantityError(false);
        setEnableAddToCart(false);
      } else if (updatedQuantity > maxQuantity) {
        setShowMaxQuantityError(true);
        setRcmdProductsLimitError(false);
        setEnableAddToCart(quantity.current > 0);
      } else {
        quantity.current = updatedQuantity;
        setShowMaxQuantityError(false);
        setRcmdProductsLimitError(false);
        setEnableAddToCart(quantity.current > 0);
      }
    } else {
      quantity.current = updatedQuantity;
      toggleAddToCart();
    }
  };

  const isVouchers = (ticket) => isParks && ticket?.itemType === UIConfig.TicketType.VOUCHERS;

  const renderCounterAndAddToCart = (ticketDetails) => {
    const { additionalRecommendedData, data, visitorCounter, ticket } = props;

    const increaseDecreaseBy = visitorCounter.options[0].increaseDecreaseBy || 1;
    const ariaLabelDecrease = visitorCounter.options[0].ariaLabelDecrease || '';
    const ariaLabelIncrease = visitorCounter.options[0].ariaLabelIncrease || '';
    const ticketCounterText = visitorCounter.options[0].ticketCounterText || '';
    let cartLabels = {};
    if (additionalRecommendedData) {
      cartLabels = additionalRecommendedData.cartLabels;
    }
    const maximumQuantity = isVouchers(ticket) ? visitorCounter.options?.[0]?.maximumQuantity : visitorCounter.maxCount;
    return (
      <div className={`c-fast-counter-cart-button-wrapper${quantity.current === 0 ? '' : ' color-change'}`}>
        {isSwad ? (
          <div className="spinner-wrapper">
            <p className="ticket-price">{ticket.currency + ' ' + ticket.gross}</p>
            <Spinner
              updateQuantity={updateQuantity}
              isInputDisabled="true"
              min={0}
              max={maximumQuantity}
              quantity={quantity.current}
              increaseDecreaseBy={increaseDecreaseBy}
              ariaLabelDecrease={ariaLabelDecrease}
              ariaLabelIncrease={ariaLabelIncrease}
              ticketCounterText={ticketCounterText}
              showMaxQuantityError={showMaxQuantityError}
              addSubQuantity={(newValue) => addedQuatity && addedQuatity(newValue, ticket.category)}
              isPastPurchasedRecommendation={isPastPurchasedRecommendation}
              isMaxQuantity={() => isMaxQuantity(ticket.category)}
              addedQuatity={recommendationsCatergoryData && recommendationsCatergoryData[ticket.category].addedQuatity}
              setShowMaxQuantityError={setShowMaxQuantityError}
            />
          </div>
        ) : (
          <Spinner
            updateQuantity={updateQuantity}
            isInputDisabled="true"
            min={0}
            max={maximumQuantity}
            quantity={quantity.current}
            increaseDecreaseBy={increaseDecreaseBy}
            ariaLabelDecrease={ariaLabelDecrease}
            ariaLabelIncrease={ariaLabelIncrease}
            ticketCounterText={ticketCounterText}
            showMaxQuantityError={showMaxQuantityError}
            addSubQuantity={(newValue) => addedQuatity && addedQuatity(newValue, ticket.category)}
            isPastPurchasedRecommendation={isPastPurchasedRecommendation}
            isMaxQuantity={() => isMaxQuantity(ticket.category)}
            addedQuatity={recommendationsCatergoryData && recommendationsCatergoryData[ticket.category].addedQuatity}
            setShowMaxQuantityError={setShowMaxQuantityError}
          />
        )}
        {showMaxQuantityError && cartLabels && cartLabels.maxLimitErrorLabel && (
          <p className="max-limit-error">{cartLabels.maxLimitErrorLabel}</p>
        )}
        {showRcmdProductsLimitError && cartLabels && cartLabels.rcmdProductsLimitError && (
          <p className="max-limit-error">{cartLabels.rcmdProductsLimitError}</p>
        )}

        <div className="c-fast-bottom">
          {isSwad && visitorCounter?.description && (
            <DynamicContent
              attrs={{ className: 'body-2 c-primary-section-description' }}
              tagName="p"
              innerHtml={visitorCounter.description}
            />
          )}
          <div className={enableAddToCart ? 'btn-primary ' : 'btn-primary disabled'}>
            <button
              type="button"
              className="add-to-cart"
              aria-label={`${ticketDetails.title} ${data.addToCart}`}
              onClick={(e) => {
                GTMDataOnClickCTA({
                  name: `${data?.name} - ${ticketDetails.title} - ${data.addToCart}`,
                  [UIConfig.ga4Constants.ELEMENTTEXT]: data.addToCart,
                  category: UIConfig.ga4Constants.PRIMARY_CTA,
                });
                addToCart(e);
              }}
              tabIndex={!enableAddToCart && '-1'}
            >
              {data.addToCart}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const addToCart = (e) => {
    const { addToCartCallback, ticket, visitorCounter, addedQuatity } = props;
    const rcmdData = recommendationsCatergoryData?.[ticket?.category];
    e.stopPropagation();
    if (isPastPurchasedRecommendation) {
      ticket.quantity = rcmdData.addedQuatity;
      ticket.tab = ticket.tab && typeof ticket.tab === 'object' ? ticket.tab.length && ticket.tab[0] : ticket.tab;
      ticket.postPurchasedAddonCount = rcmdData;
      ticket.isPostPurchasedAddon = true;
    } else {
      ticket.quantity = quantity.current;
    }
    if (isEventTypeProduct(ticket) && ticket.performanceAk) {
      ticket.performanceId = ticket.performanceAk;
    }
    ticket.minCount = visitorCounter.minCount;
    ticket.maxCount = isVouchers(ticket) ? visitorCounter.options?.[0]?.maximumQuantity : visitorCounter.maxCount;
    const { defaultQuantity } = visitorCounter?.options?.[0];
    if (isPastPurchasedRecommendation && rcmdData?.maxQuantity <= 0 && !rcmdData?.isUnlimited) {
      setShowMaxQuantityError(true);
      addedQuatity && addedQuatity(defaultQuantity, ticket.category);
      updateQuantity(defaultQuantity);
      return;
    }
    addToCartCallback && addToCartCallback([Object.assign({}, ticket)], true);
    addedQuatity && addedQuatity(defaultQuantity, ticket.category);
    updateQuantity(defaultQuantity);
  };

  const closeOverlay = () => {
    if (isDynamicPricingProd) {
      props.copySearchProductList.forEach((newCoveo, index) => {
        props.searchProductList[index].gross = newCoveo.gross;
      });
    }
    setIsOverlayOpen(false);
  };

  const GTMDataOnSelectItem = () => {
    try {
      const { data, ga4Ticket, ticket, additionalProds = [] } = props;
      const secondaryProd = additionalProds.find((prod) => prod?.experienceCatgory?.toLowerCase() !== 'primary');
      const itemArr = [ga4Ticket || ticket];
      if (secondaryProd) {
        itemArr.push(secondaryProd);
      }
      const category =
        data && data.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      GTMData.push('selectItem', {
        itemsList: itemArr,
        category,
        gaTabTitle: data?.gaTabTitle || undefined,
      });
    } catch (error) {
      console.log('GA4 error: ', error);
    }
  };

  const renderDateSelector = (ticketDetails, renderPriceList) => {
    const { data, dateSelector, ticket, visitorCounter } = props;

    const multiTicketSelector = props.category.multiTicketSelector;
    const keyMap = multiTicketSelector && multiTicketSelector.stringMapping;
    const category = ticket.category;
    const isDrivingExp =
      keyMap &&
      (category.indexOf(keyMap.drivingExperience) !== -1 || category.indexOf(keyMap.passengerExperience) !== -1);
    const classToAppend = isDrivingExp
      ? 'drvng-exp addOns-Overlay'
      : classNames('addOns-Overlay calendar-overlay-genral-admission', {
          'v-multi-product-widget': isPJOnly,
          'v-dynamic-product-pricing': isDynamicPricingProd,
          'fit-onscreen-overlay': data.enableTac,
          'calender-overlay-mobile-height mobile-fix-height': isSwad,
        });

    const btnLabel = dateSelector.options[0].calendarSettings && dateSelector.options[0].calendarSettings.calendarLabel;
    return (
      <>
        {isSwad && visitorCounter?.description && (
          <DynamicContent
            attrs={{ className: 'body-2 c-primary-section-description' }}
            tagName="p"
            innerHtml={visitorCounter.description}
          />
        )}
        <div className="btn-primary">
          {!isPlpVariant && !isPJOnly && (
            <Text
              tag="button"
              onClick={() => {
                setIsOverlayOpen(true);
                if (checkParksTenants()) {
                  GTMDataOnSelectItem();
                }
              }}
              aria-label={`${ticketDetails.title} ${btnLabel}`}
              field={{ value: btnLabel, editable: btnLabel }}
            />
          )}

          {isPJOnly && (
            <button
              type="button"
              aria-label={`${ticketDetails.title} ${dateSelector.options[0].calendarSettings &&
                dateSelector.options[0].calendarSettings.calendarLabel}`}
              onClick={() => {
                setIsOverlayOpen(true);
                if (checkParksTenants()) {
                  GTMDataOnSelectItem();
                }
              }}
            >
              {ticketDetails.prices &&
                ticketDetails.prices.map((price, index) =>
                  renderPriceList({ price, index, showPriceTogether: isSwad }),
                )}
            </button>
          )}
          {isOverlayOpen &&
            window.PubSub.publish('toggleOverlayState', {
              shouldOpen: true,
              customClass: classToAppend,
              dataToAppend: componentToAppend({ props, closeOverlay, isDrivingExp, ticketDetails }),
            })}
        </div>
      </>
    );
  };
  // added this function to add wrapper for SWAD
  const renderTicketDetail = ({
    ticket,
    ticketDetails,
    eventLocationName,
    category,
    dateSelector,
    data,
    info,
    ticketVal,
  }) => {
    return (
      <>
        <div className="c-fast-desc">
          {!ticketDetails.image && ticketDetails.offerLabel && (
            <DynamicContent tagName="div" attrs={{ className: 'offer-label' }} innerHtml={ticketDetails.offerLabel} />
          )}
          {ticket.productDetailLink && !isSwad ? (
            <Text
              tag="a"
              href={ticket.productDetailLink}
              className="heading-4"
              field={{
                value: ticketDetails.title,
                editable: ticketDetails.title,
              }}
            />
          ) : (
            <DynamicContent tagName="div" attrs={{ className: 'heading-4' }} innerHtml={ticketDetails.title} />
          )}
          {eventLocationName && (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'heading-6 event-location' }}
              innerHtml={eventLocationName}
            />
          )}
          <DynamicContent tagName="div" attrs={{ className: 'body-1' }} innerHtml={ticketDetails.subTitle} />
          {!isSwad && category && category.Icons && category.Icons.length > 0 && (
            <div className="parks-logo">
              {category.Icons.map((icon) => (
                <Image image={icon} disableLazyLoad={true} />
              ))}
            </div>
          )}
          {!isSwad && (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'body-2 ticket-desc' }}
              innerHtml={ticketDetails.description}
            />
          )}

          {!isPJOnly &&
            ticketDetails.prices &&
            (isSwad ? (
              <>
                {ticketDetails.prices.length > 0 && (
                  <ul className="c-fast-price-list">
                    {ticketDetails.prices.map((price, index) =>
                      renderPriceList({ price, index, showPriceTogether: isSwad, ticketValue: ticketVal }),
                    )}
                  </ul>
                )}
              </>
            ) : (
              <>
                {ticketDetails.prices.map((price, index) =>
                  renderPriceList({ price, index, isPastPurchasedRecommendation, ticketValue: ticketVal, data }),
                )}
              </>
            ))}
          {isSwad && (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'body-2 ticket-desc' }}
              innerHtml={ticketDetails.description}
            />
          )}
          {isSwad && category && category.Icons && category.Icons.length > 0 && (
            <div className="parks-logo">
              {category.Icons.map((icon) => (
                <Image image={icon} disableLazyLoad={true} />
              ))}
            </div>
          )}
          {isSwad && info && (info.description || info.title) && (
            <div className="ticket-includes-wrapper">
              <DynamicContent tagName="span" attrs={{ className: 'ticket-includes-title' }} innerHtml={info.title} />
              <DynamicContent
                tagName="span"
                attrs={{ className: 'ticket-includes-description' }}
                innerHtml={info.description}
              />
            </div>
          )}

          {isSwad && (ticketDetails?.readMoreLink?.href || ticket.productDetailLink) && ticketDetails.readMoreLabel && (
            <Text
              tag="a"
              target="_blank"
              href={ticketDetails?.readMoreLink?.href || ticket.productDetailLink}
              className="read-more-link"
              field={{ value: ticketDetails.readMoreLabel }}
            />
          )}
        </div>
        <div className="c-fast-logo-counter-wrapper">
          {!dateSelector && !isSwad && ticket.image && (
            <div className="c-fast-logo">
              <Image image={null} disableLazyLoad={true} />
            </div>
          )}
          <div className="c-fast-counter">
            {data.isEventQuantityOverlay
              ? componentToAppend({ props, closeOverlay, ticketDetails })
              : getOverlaySelector(
                  data,
                  dateSelector,
                  renderQuantityOverlay,
                  renderDateSelector,
                  renderCounterAndAddToCart,
                  ticketDetails,
                  renderPriceList,
                )}
          </div>
          {info && (
            <TicketTooltip title={info.title} subTitle={info.subTitle} description={info.description} tabIndex="0" />
          )}
        </div>
      </>
    );
  };
  const renderQuantityOverlay = () => {
    const { data, ticket } = props;
    const multiTicketSelector = props.category.multiTicketSelector;
    const keyMap = multiTicketSelector && multiTicketSelector.stringMapping;
    const category = ticket.category;
    const isDrivingExp =
      keyMap &&
      (category.indexOf(keyMap.drivingExperience) !== -1 || category.indexOf(keyMap.passengerExperience) !== -1);
    const classToAppend = isDrivingExp
      ? 'drvng-exp addOns-Overlay'
      : classNames(
          'addOns-Overlay calendar-overlay-genral-admission',
          { 'v-multi-product-widget': isPJOnly },
          { 'v-dynamic-product-pricing': isDynamicPricingProd },
        );
    return (
      <div className="btn-primary">
        {!isPlpVariant && (
          <button
            type="button"
            onClick={() => {
              setIsOverlayOpen(true);
              if (checkParksTenants()) {
                GTMDataOnSelectItem();
              }
            }}
          >
            {' '}
            {data.pjSelectLabel}{' '}
          </button>
        )}
        {isOverlayOpen &&
          window.PubSub.publish('toggleOverlayState', {
            shouldOpen: true,
            customClass: classToAppend,
            dataToAppend: componentToAppend({ props, closeOverlay, isDrivingExp }),
          })}
      </div>
    );
  };

  try {
    const { ariaLabelCheckbox, category, data, dateSelector, selectedTicket, ticket, ticketState, tncPopup } = props;
    const ticketDetails = category.ticketSelector ? category.ticketSelector.ticket : category;
    const info = ticketDetails.info;
    const ticketSelectedState = ticketState === 'default' && selectedTicket === coveoValue;
    const eventLocationName = ticket.eventLocationName;
    if (isPlpVariant) {
      return (
        <div className="v-overlay-selected">
          {data.isEventQuantityOverlay
            ? componentToAppend({ props, closeOverlay, ticketDetails })
            : getOverlaySelector(
                data,
                dateSelector,
                renderQuantityOverlay,
                renderDateSelector,
                renderCounterAndAddToCart,
              )}
        </div>
      );
    }

    const ticketVal = recommendationsCatergoryData && recommendationsCatergoryData[ticket && ticket.category];
    return (
      <div
        className={`c-fast-ticket-wrapper${ticketSelectedState ? ' selected' : ''}`}
        onClick={(e) => handleOnClick(e, props, coveoValue)}
        data-product={ticket.productId}
      >
        <div className="c-fast-ticket">
          {!isSwad && ticket.productImage && (
            <img className="product-image" src={ticket.productImage} alt={ticket.productImage} />
          )}
          {isSwad && ticketDetails.image && (
            <div className="product-image-wrapper">
              {ticketDetails.offerLabel && (
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'offer-label' }}
                  innerHtml={ticketDetails.offerLabel}
                />
              )}
              <Image image={ticketDetails.image} disableLazyLoad={true} />
            </div>
          )}
          {isSwad ? (
            <div className="ticket-content-wrapper">
              {renderTicketDetail({
                ticket,
                ticketDetails,
                eventLocationName,
                category,
                dateSelector,
                data,
                info,
                ticketVal,
              })}
            </div>
          ) : (
            renderTicketDetail({
              ticket,
              ticketDetails,
              eventLocationName,
              category,
              dateSelector,
              data,
              info,
              ticketVal,
            })
          )}
          {/* {!isPJOnly && (
            <div className="checkbox-label" htmlFor={ticketDetails.name}>
              <input
                type="radio"
                id={coveoValue}
                name="ticket-selection"
                value={ticketDetails.title}
                checked={ticketSelectedState}
                onChange={(e) => handleOnClick(e, props.handleOnClick, coveoValue)}
              />
              <div
                className="checkbox"
                id={coveoValue}
                tabIndex={0}
                aria-label={data.ariaLabelCheckbox ? data.ariaLabelCheckbox : ariaLabelCheckbox}
                role="radio"
                aria-checked={ticketSelectedState}
                onKeyDown={(e) => handleCheckBoxKeyDownEvent(e)}
              ></div>
            </div>
          )} */}
          {isPJOnly && tncPopup && (
            <AnchorLink
              className="tnc-link"
              link={{ label: tncPopup.tncLabel }}
              onClick={() => openOverlay(tncPopup, isPJOnly)}
              onKeyDown={(e) => checkKeyDownHandler(e, tncPopup, isPJOnly)}
              tabIndex="0"
            />
          )}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'AddOnsTicketComponent');
  }
};

AddOnsTicketComponent.defaultProps = {
  ariaLabelCheckbox: 'Press enter to select ticket',
};

AddOnsTicketComponent.propTypes = {
  additionalProds: PropTypes.array,
  additionalRecommendedData: PropTypes.object,
  addToCartCallback: PropTypes.func,
  ariaLabelCheckbox: PropTypes.string,
  businessErrors: PropTypes.object,
  cartData: PropTypes.object,
  category: PropTypes.object,
  coveoMapList: PropTypes.object,
  data: PropTypes.object,
  dateSelector: PropTypes.object,
  getDiscountedProducts: PropTypes.func,
  handleOnClick: PropTypes.func,
  overlayErrorCallback: PropTypes.func,
  searchProductList: PropTypes.array,
  selectedTicket: PropTypes.string,
  services: PropTypes.object,
  ticket: PropTypes.object,
  ticketState: PropTypes.string,
  timeSlotSelector: PropTypes.object,
  variant: PropTypes.string,
  visitorCounter: PropTypes.object,
};

export default AddOnsTicketComponent;
