import { useEffect } from 'react';

const useNoScript = (elementData) => {
  useEffect(() => {
    const noscript = document.createElement('noscript');

    const element = document.createElement(elementData.element);

    Object.keys(elementData.props).forEach((key) => {
      element[key] = elementData.props[key];
    });

    noscript.appendChild(element);
    document.body.appendChild(noscript);

    return () => {
      document.body.removeChild(noscript);
    };
  }, [elementData]);
};

export default useNoScript;
