import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import UIConfig from '../../../../common/UIConfig';
import {
  getValidStartDate,
  momentTimezone,
  canUseDOM,
  isMatchTenant,
  getClientUtcOffset,
  convertJsDateToMomentObj,
} from '../../../../common/utility';
import { DynamicContent, SvgSprite } from '../../../presentation/base';
import CalendarOverlayB2c from '../calender-overlay-b2c/calender-overlay-b2c';

export default class DateFlexibleSelector extends Component {
  constructor(props) {
    super(props);
    this.renderDateFields = this.renderDateFields.bind(this);
    this.selectDateFields = this.selectDateFields.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.updateData = this.updateData.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.toggleHighlightedText = this.toggleHighlightedText.bind(this);
    this.validOnRange = [];
    this.defaultAccessPolicy = {};

    this.state = {
      isCalendarOpen: false,
      isCheckboxFocus: false,
      isCalendarInputFocus: false,
      selectedField: !props.unChecked ? props.data && props.data.options && props.data.options[0].name : '',
      selectedDate: null,
      bestRate: {
        highlightedText: null,
        code: null,
      },
    };
    this.defaultValue = {
      defaultDateDelta: 1,
      range: 0,
    };
  }

  closeOverlay() {
    const { selectedDate, selectedField } = this.state;
    if (selectedField === 'dated' && selectedDate == null) {
      this.setState({ isCalendarOpen: false, isCalendarInputFocus: true });
    } else {
      this.setState({ isCalendarOpen: false, isCalendarInputFocus: false });
    }
    window.PubSub.unsubscribe('closeOverlay');
  }
  toggleHighlightedText(e) {
    if (this.calendarSettings.highlightDates) {
      const bestRate =
        e.type === 'mouseover'
          ? {
              code: this.calendarSettings.bestRate.code,
              highlightedText: this.calendarSettings.bestRate.title,
            }
          : {
              code: null,
              highlightedText: null,
            };
      this.setState({ bestRate });
    }
  }

  showCalendar(e) {
    e.stopPropagation();
    this.setState({ isCalendarOpen: true });
    window.PubSub.subscribe('closeOverlay', this.closeOverlay);
  }

  selectDateFields(value, option) {
    this.setState({ selectedField: value, isCheckboxFocus: false, isCalendarInputFocus: false });
    this.props.setDateData({
      selectedField: value,
      rangeInMonths: option.ticketValidity,
      selectedDate: this.state.selectedDate,
      accessPolicy: {
        key: option.coveoKey,
        value: option.coveoValue,
      },
    });
  }

  handleOnKeyDownCalendar(e, opt) {
    if (e.keyCode === 13) {
      this.selectDateFields(opt.name, opt);
      opt.name === 'dated' && this.showCalendar(e);
    }
  }

  renderDateFields(data, disabled) {
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const { additionalProperty } = Object(mainObj);
    const { purchaseJourneyDateFormat } = Object(additionalProperty);
    const allParkTanent =
      isMatchTenant(UIConfig.tenants.fwad) ||
      isMatchTenant(UIConfig.tenants.wbw) ||
      isMatchTenant(UIConfig.tenants.yww) ||
      isMatchTenant(UIConfig.tenants.ppad);
    return (
      <div className={`c-date-flexible-selector-date-wrapper ${data.hidden ? 'hide' : ''}`}>
        {data.options.map((opt, i) => {
          const highlightedText = this.state.bestRate.highlightedText || opt.highlightedText;
          return (
            <div key={i} className="c-date-flexible-selector-date-selector">
              {opt.highlightedText && (
                <Text
                  tag="div"
                  className="c-highlighted-ribbon"
                  field={{ value: highlightedText, editable: highlightedText }}
                  onClick={this.showCalendar}
                  onMouseOver={this.toggleHighlightedText}
                  onMouseOut={this.toggleHighlightedText}
                />
              )}
              <div className={`calender-container ${!opt.coveoValue ? 'disabled' : ''}`}>
                <label htmlFor={this.props.parent + '_' + opt.name}>
                  <div className="checkbox-label">
                    <input
                      name={this.props.parent}
                      value={opt.name}
                      type="radio"
                      checked={opt.name === this.state.selectedField && this.state.selectedDate}
                      onChange={(e) => this.selectDateFields(e.target.value, opt)}
                      onClick={(e) => opt.name === 'dated' && this.showCalendar(e)}
                      id={this.props.parent + '_' + opt.name}
                    />
                    <div
                      className={`checkbox ${this.state.isCheckboxFocus ? ' checkbox-focus' : ''}`}
                      ref="calendarCheckbox"
                      tabIndex={data.hidden ? -1 : 0}
                      aria-label={data.ariaLabelCheckbox ? data.ariaLabelCheckbox : this.props.ariaLabelCheckbox}
                      role="radio"
                      aria-checked={opt.name === this.state.selectedField && this.state.selectedDate}
                      onKeyDown={(e) => this.handleOnKeyDownCalendar(e, opt)}
                    ></div>
                    <span className="sr-only">{opt.ariaLabel}</span>
                  </div>
                  <div className="date-type-selector">
                    <DynamicContent tagName="span" innerHtml={opt.title} attrs={{ className: 'title' }} />
                    {opt.name === 'dated' ? (
                      <span
                        onClick={this.showCalendar}
                        onKeyDown={(e) => this.handleOnKeyDownCalendar(e, opt)}
                        role="button"
                        aria-label={
                          (this.state.selectedDate &&
                            this.state.selectedDate.format(UIConfig.b2c.purchaseJourney.dateFormat)) ||
                          opt.title
                        }
                        tabIndex={data.hidden || disabled ? -1 : 0}
                        className={`calendar-cta ${this.state.isCalendarInputFocus ? ' calendar-input-focus' : ''}`}
                        ref="calendarCta"
                      >
                        <DynamicContent
                          tagName="span"
                          attrs={{ className: 'calendar-input' }}
                          innerHtml={
                            (this.state.selectedDate &&
                              this.state.selectedDate.format(UIConfig.b2c.purchaseJourney.dateFormat)) ||
                            purchaseJourneyDateFormat ||
                            opt.calendarSettings.format
                          }
                        />
                        <SvgSprite id="calendar-icon" />
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </label>
                {allParkTanent && this.state.isCalendarInputFocus && (
                  <DynamicContent
                    tagName="p"
                    attrs={{ className: 'date-validation-msg' }}
                    innerHtml={opt && opt.errorMessage}
                  />
                )}
                <DynamicContent tagName="p" attrs={{ className: 'sub-title' }} innerHtml={opt.subTitle} />
                <DynamicContent tagName="p" attrs={{ className: 'description' }} innerHtml={opt.description} />
                {opt.discountText && (
                  <p className="discount-label">
                    <DynamicContent tagName="span" innerHtml={opt.discountText} />
                    <SvgSprite id="ticket-icon" />
                  </p>
                )}
                {opt.validityText && (
                  <p className="discount-label">
                    <DynamicContent tagName="span" innerHtml={opt.validityText} />
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  updateData(data) {
    if (data) {
      this.setState({ selectedDate: data.date, isCalendarOpen: false, isCalendarInputFocus: false });
      window.PubSub.unsubscribe('closeOverlay');
      this.props.setDateData({
        selectedDate: data.date,
        accessPolicy: this.defaultAccessPolicy,
        timeSlot: data.timeSlot || '',
        performanceId: data.performanceId || '',
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.componentState === 'noSelect') {
      this.setState({ selectedDate: null, isCheckboxFocus: false, isCalendarInputFocus: false });
      this.props.setDateData({
        selectedDate: null,
        selectedField: this.state.selectedField,
        accessPolicy: this.defaultAccessPolicy,
      });
    }
    const { selectedField, selectedDate } = this.state;
    if (newProps.addToCartClicked) {
      if (!selectedField) {
        this.refs.calendarCheckbox && this.refs.calendarCheckbox.focus();
        this.setState({ isCheckboxFocus: true });
      } else if (selectedField === 'flexible') {
        this.refs.calendarCheckbox && this.refs.calendarCheckbox.focus();
        this.setState({ isCheckboxFocus: false, isCalendarInputFocus: false });
      } else if (selectedField === 'dated' && selectedDate == null) {
        this.refs.calendarCta && this.refs.calendarCta.focus();
        this.setState({ isCalendarInputFocus: true });
        setTimeout(() => {
          this.refs.calendarCta.scrollIntoView({ behaviour: 'instant', block: 'center' });
        }, 0);
      }
    }
  }

  // this update the start date, if a ticket needs to be valid on a certain day of week.
  updateStartDate(validDayOfWeek) {
    const currentDate = this.calendarSettings.startDate;

    while (validDayOfWeek.indexOf(currentDate.day()) < 0) {
      currentDate.add(1, 'day');
    }
    this.calendarSettings.startDate = currentDate;
  }

  componentDidMount() {
    let settings;
    this.props.data.options.forEach((field, i) => {
      if (field.name === 'dated') {
        settings = field.calendarSettings;
        this.defaultAccessPolicy = {
          key: field.coveoKey,
          value: field.coveoValue,
        };
        const startDate = settings && settings.startDate;
        const validStartDate = getValidStartDate(startDate);
        const givenStartDate = moment(startDate).isValid()
          ? momentTimezone(startDate, getClientUtcOffset())
          : validStartDate.clone();
        this.calendarSettings = {
          startDate: validStartDate.add(settings.defaultDateDelta, 'days'),
          endDate: settings.endDate
            ? momentTimezone(settings.endDate, getClientUtcOffset())
            : givenStartDate.add(Number(settings.range) || this.defaultValue.range, 'days'),
          tooltipmap: settings.tooltipmap,
        };

        if (settings.validOn) {
          this.validOnRange = settings.validOn.split(',').map((x) => +x);
          //this.updateStartDate(this.validOnRange); // Commented for now, as start date now being calculated in calendarcomponent directly.
        }
        this.calendarSettings.bestRate = field.bestRate;
        this.calendarSettings.title = settings.title;
        this.calendarSettings.format = settings.format;
        this.calendarSettings.options = settings.options;
        this.calendarSettings.selectDateCta = settings.calendarLabel;
        this.calendarSettings.enabledDates = settings.enabledDates;
        this.calendarSettings.disabledDates = settings.disabledDates;
        this.calendarSettings.calendarTooltip = settings.calendarTooltip;
        this.calendarSettings.defaultSelectorDiscount = settings.defaultSelectorDiscount;
        this.calendarSettings.highlightDates = settings.highlightDate;
        this.calendarSettings.defaultDateDelta = settings.defaultDateDelta;
        this.calendarSettings.validOnRange = this.validOnRange;
      }
      if (i === 0) {
        const dateObj = {
          selectedField: this.state.selectedField,
          accessPolicy: this.defaultAccessPolicy,
          rangeInMonths: field.ticketValidity,
        };
        if (this.props.data.hidden && this.props.data.defaultDate) {
          dateObj.selectedDate = momentTimezone(this.props.data.defaultDate, getClientUtcOffset());
        }
        this.props.setDateData(dateObj);
      }
    });
  }

  getFormattedDates = (dates) => {
    return dates && dates.length ? dates.map((dateString) => momentTimezone(dateString, getClientUtcOffset())) : null;
  };

  allIncludedDates = () => {
    const allEnabledDates = this.getFormattedDates(this.calendarSettings.enabledDates);
    const includeDates = allEnabledDates
      ? allEnabledDates.sort(function(left, right) {
          return moment(left).format('X') - moment(right).format('X');
        })
      : null;

    const currentDate = moment();
    return includeDates
      ? includeDates.filter((d) => momentTimezone(d, getClientUtcOffset()).isSameOrAfter(currentDate, 'days'))
      : includeDates;
  };

  isSameDate = (date) => {
    const includeDateLogic = this.allIncludedDates();
    const findDate =
      includeDateLogic &&
      includeDateLogic.find((item) =>
        momentTimezone(item, getClientUtcOffset()).isSame(momentTimezone(date, getClientUtcOffset()), 'day'),
      );
    if (findDate) {
      return true;
    }
    return false;
  };

  isValidDay = (date) => {
    date = convertJsDateToMomentObj(date);
    if (!this.validOnRange.length) {
      return 1;
    }

    if (this.isSameDate(date)) {
      return true;
    }
    const day = date.day();
    return this.validOnRange.indexOf(day) >= 0;
  };

  render() {
    const { data, disabled, timeSlotSelector, ticket, services } = this.props;
    const { bestRate, selectedDate, isCalendarOpen } = this.state;
    return (
      <div className="c-date-flexible-selector">
        {isCalendarOpen && (
          <CalendarOverlayB2c
            data={this.calendarSettings}
            defaultDate={selectedDate}
            updateData={this.updateData}
            filterDate={this.isValidDay}
            bestRate={bestRate}
            product={ticket}
            services={services}
            timeSlotData={timeSlotSelector}
          />
        )}
        {this.renderDateFields(data, disabled)}
      </div>
    );
  }
}

DateFlexibleSelector.PropsTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
};

DateFlexibleSelector.defaultProps = {
  ariaLabelCheckbox: 'Press enter to select ticket',
};
