/**
 * Desc: Submenu Items
 *
 */

import { getClosestByClass } from '../utility';
import { PopupMenu } from './PopupMenuLinks';

var MenubarItem = function(domNode, menuObj) {
  this.menu = menuObj;
  this.domNode = domNode;
  this.popupMenu = false;

  this.hasFocus = false;
  this.hasHover = false;

  this.keyCode = Object.freeze({
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    DOWN: 40,
  });
};

MenubarItem.prototype.init = function() {
  this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
  this.domNode.addEventListener('blur', this.handleBlur.bind(this));

  // Initialize pop up menus
  let subMenuListElm = getClosestByClass(this.domNode, 'has-submenu');
  let nextElement = subMenuListElm ? subMenuListElm.querySelector('.meganav') : false;

  if (nextElement) {
    this.popupMenu = new PopupMenu(nextElement, this);
    this.popupMenu.init();
  }
};

MenubarItem.prototype.handleKeydown = function(event) {
  var flag = false;
  switch (event.keyCode) {
    case this.keyCode.SPACE:
    case this.keyCode.DOWN:
      if (this.popupMenu && !this.popupMenu.hasPopupMenuOpen) {
        this.popupMenu.open();
        this.popupMenu.setFocusToFirstItem();
        flag = true;
      } else if (this.popupMenu.hasPopupMenuOpen) {
        flag = true;
        this.popupMenu.close();
      }
      break;
    case this.keyCode.ESC:
      if (this.popupMenu.hasPopupMenuOpen) {
        flag = true;
        this.popupMenu.close();
      }
      break;
    default:
      break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

MenubarItem.prototype.setExpanded = function(value) {
  if (value) {
    this.domNode.setAttribute('aria-expanded', true);
  } else {
    this.domNode.setAttribute('aria-expanded', false);
  }
};

MenubarItem.prototype.handleBlur = function(event) {
  this.menu.hasFocus = false;
  if (this.popupMenu) {
    setTimeout(this.popupMenu.close.bind(this.popupMenu, false), 300);
  }
};

export { MenubarItem };
