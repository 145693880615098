/*
 * image-components.js
 * This file contains code for image component which will render diiferent renditions based on viewport.
 * @licensor  Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import { breakpoints, checkTenant } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { DynamicContent } from '..';
import classNames from 'classnames';
import { logComponentRenderingError } from '../../../../common/logger';
import { LazyLoadImage } from 'react-lazy-load-image-component';
/**
 * ImageTag creates picture tag containing different image renditions
 * @param    {[Object]} Object containing different image set for desktop, tablet and mobile(desktopImage,tabletImage,mobileImage)
 * @return   {[Object]} JSX for Picture tag
 */
const Image = (props) => {
  try {
    const img = props.image;
    if (!img) {
      return null;
    }
    let image = null;

    if (props.noRendition) {
      return getSimpleImgTag(img);
    }

    if (props.image.desktopImage && props.image.desktopImage.html) {
      /*This is used for inline editing in CMS*/
      image = <EditableImages item={props.image.desktopImage.html} />;
    } else if (_isValid(img.mobileImage) || _isValid(img.tabletImage) || _isValid(img.desktopImage)) {
      image = getActualImage(props);
    }
    return image;
  } catch (err) {
    return logComponentRenderingError(err, 'Image');
  }
};

const _isValid = (img) => img && img.src;

/**
 * EditableImages creates element for CMS inline editing
 */
const EditableImages = (props) => {
  return <DynamicContent tagName="div" attrs={{ className: 'editable-image' }} innerHtml={props.item} />;
};

/**
 * getActualImage checks the condition to load the desired image
 */
const getActualImage = (props) => {
  if (UIConfig.imageLazyLoad) {
    if (props.disableLazyLoad) {
      return imageWithoutDataSrc(props.image, props.usemap, props.isOpacityRemove, props.isMediaItem);
    } else {
      return imageWithDataSrc(props.image, props.usemap, props.dataDescription);
    }
  } else {
    return imageWithoutDataSrc(props.image, props.usemap, props.isOpacityRemove, props.isMediaItem);
  }
};

/**
 * imageWithoutDataSrc creates picture tag containing different image renditions, with lazy-load data attribute
 */
const imageWithDataSrc = (image, usemap, dataDescription) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  return (
    <picture>
      <source data-srcset={image.mobileImage.src} media={`(max-width: ${breakpoints.tablet - 1}px)`} />
      <source data-srcset={image.tabletImage.src} media={`(max-width: ${breakpoints.desktop - 1}px)`} />
      <source data-srcset={image.desktopImage.src} />
      <img
        src={
          isSwad && image.desktopImage.src
            ? image.desktopImage.src
            : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
        }
        data-src={image.desktopImage.src}
        className={`lazyload ${dataDescription ? 'lightbox-img' : ''}`}
        alt={image.imageAltText}
        useMap={usemap}
        {...(dataDescription && {
          'data-description': dataDescription,
          onClick: () => {
            window.PubSub.publish(UIConfig.events.OPEN_LIGHTBOX, {
              src: image.desktopImage.src,
            });
          },
        })}
      />
      <span className="loading-icon"></span>
    </picture>
  );
};
const LazyLoadFullScreenImages = ({ image, usemap, isMediaItem }) => {
  if (isMediaItem) return <LazyLoadImage src={image.desktopImage.src} alt={image.imageAltText} useMap={usemap} />;

  return <img src={image.desktopImage.src} alt={image.imageAltText} useMap={usemap} />;
};
/**
 * imageWithoutDataSrc creates picture tag containing different image renditions, but without lazy-load
 */
const imageWithoutDataSrc = (image, usemap, isOpacityRemove = false, isMediaItem = false) => {
  return (
    <picture className={classNames({ 'hide-opacity': isOpacityRemove })}>
      <source media={`(max-width: ${breakpoints.tablet - 1}px)`} srcSet={image.mobileImage.src} />
      <source media={`(max-width: ${breakpoints.desktop - 1}px)`} srcSet={image.tabletImage.src} />
      <LazyLoadFullScreenImages image={image} usemap={usemap} isMediaItem={isMediaItem} />
    </picture>
  );
};

/**
 * getSimpleImgTag creates html native <img /> tag
 */
const getSimpleImgTag = (props) => {
  if (UIConfig.imageLazyLoad) {
    if (props.disableLazyLoad) {
      return <img src={props.src} alt={props.alt} />;
    } else {
      return <img className="lazyload" data-src={props.src} alt={props.alt} />;
    }
  } else {
    return <img src={props.src} alt={props.alt} />;
  }
};

Image.propTypes = {
  desktopImage: PropTypes.shape({
    html: PropTypes.string,
    src: PropTypes.string,
  }),
  tabletImage: PropTypes.shape({
    html: PropTypes.string,
    src: PropTypes.string,
  }),
  mobileImage: PropTypes.shape({
    html: PropTypes.string,
    src: PropTypes.string,
  }),
  altText: PropTypes.string,
  disableLazyLoad: PropTypes.bool,
};

export default Image;
