import React from 'react';
import 'focus-within-polyfill';

import { canUseDOM } from '../../../common/utility';
import { renewToken } from '../../../common/session/renew-token';
import { idleTimeout, showLogoutReason } from '../../../common/session/idle-timeout';

import './../../../scss/main.scss';

let isRenewTokenCalled = false;

const Init = () => {
  /**
   * These functionalities are called for logout
   */
  if (canUseDOM()) {
    //Flag to prevent renew token from being called multiple times
    if (!isRenewTokenCalled) {
      renewToken();
      isRenewTokenCalled = true;
    }
    idleTimeout();

    setTimeout(() => {
      showLogoutReason();
    }, 500);
  }
  return <></>;
};

export default Init;
