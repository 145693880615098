/*
 * anchor-link-component.js
 * This file contains code for image component which will render diiferent renditions based on viewport.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DynamicContent, Image } from '..';
import { addSecurityAttrsToAnchor } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * ImageTag creates picture tag containing different image renditions
 * @param    {[Object]} Object containing different image set for desktop, tablet and mobile(desktopImage,tabletImage,mobileImage)
 * @return   {[Object]} JSX for Picture tag
 */

const linkOrlinkImage = (props) => {
  return props.link.image ? <Image image={props.link.image} /> : props.link.label;
};

const AnchorLink = (props) => {
  try {
    if (!props.link) {
      return null;
    }

    const securityAttrs = addSecurityAttrsToAnchor(props.link.target);
    return (
      <DynamicContent
        tagName="a"
        innerHtml={linkOrlinkImage(props)}
        attrs={{
          href: props.link.href,
          onClick: props.onClick ? props.onClick.bind(this) : null,
          rel: securityAttrs || (props.link.noFollow ? 'nofollow' : null),
          target: props.link.target || null,
          className: props.className || '',
          ...props.link.attrs,
          tabIndex: props.tabIndex || null,
          onKeyDown: props.onKeyDown ? props.onKeyDown.bind(this) : null,
          'aria-labelledby': props?.link?.ariaLabelledby,
          'aria-label': props?.link?.ariaLabel,
        }}
      />
    );
  } catch (err) {
    return logComponentRenderingError(err, 'AnchorLink');
  }
};

AnchorLink.propTypes = {
  link: PropTypes.shape({
    target: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
  }),
};

export default AnchorLink;
