import React from 'react';
import PropTypes from 'prop-types';
import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';

import './button-component.scss';

const Button = ({
  copy = 'default text',
  className = '',
  clickCallback,
  data,
  onReset,
  isNewForm,
  is2StepPayment,
  disabled,
  isTabIndex,
  parksExpressSignInSignUpOnClick,
  enableBtnClass,
}) => {
  const onButtonClick = (callback) => {
    if (callback) {
      callback();
    } else {
      return;
    }
  };

  try {
    if (data.type?.toLowerCase() === 'reset') {
      return (
        <div className="section-action">
          <button
            type="button"
            onClick={(e) => {
              onReset && onReset(e);
            }}
          >
            <DynamicContent
              attrs={{ className: `${data.class} section-action--text` }}
              tagName="span"
              innerHtml={data.label ? data.label : data.copy}
            />
          </button>
        </div>
      );
    }

    if (data.type === 'ButtonCTA') {
      return (
        <div className="btn btn-secondary back-button text-center">
          <a href={data.href.url} target={data.href.target ? data.href.target : null}>
            <DynamicContent tagName="span" innerHtml={data.label} />
          </a>
        </div>
      );
    }

    return (
      <div className="text-center">
        <div className={is2StepPayment ? 'hide' : data.class ? 'btn ' + data.class : 'btn ' + className}>
          <button
            disabled={disabled}
            tabIndex={isTabIndex ? isTabIndex : 0}
            type={data.type ? data.type : 'button'}
            id={is2StepPayment ? `${parksExpressSignInSignUpOnClick ? 'express-' : ''}submit-btn` : data.id}
            onClick={isNewForm ? clickCallback : onButtonClick(clickCallback)}
            {...(parksExpressSignInSignUpOnClick && { onClick: parksExpressSignInSignUpOnClick })}
            aria-label={data.ariaLabel}
            {...(data.isTabby && {
              'data-tabby-info': 'installments',
              'data-tabby-price': data.amount,
              'data-tabby-currency': data.currency,
            })}
            {...(enableBtnClass && { className: data.class || '' })}
          >
            <DynamicContent tagName="span" innerHtml={data.label ? data.label : copy} />
            {data.icon && <span className="btn-icon"></span>}
          </button>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Button');
  }
};

export default Button;

Button.propTypes = {
  className: PropTypes.string,
  copy: PropTypes.string,
  clickCallback: PropTypes.func,
  parksExpressSignInSignUpOnClick: PropTypes.func,
  type: PropTypes.string,
};
