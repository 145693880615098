/*
 * input-date-components.js
 * This file contains code for input component which will render input based on schema.
 * @licensor  Miral
 */

import React from 'react';

import { DynamicContent, SvgSprite } from '..';
import {
  detectViewPort,
  momentTimezone,
  canUseDOM,
  isMobileOrTab,
  getValidStartDate,
  createDeepCopy,
  keyCodeObject,
  isKeyboardNavigation,
  currentLocale,
  getClientUtcOffset,
  convertJsDateToMomentObj,
} from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { PerformanceService } from '../../../../common/services';
import ApiWrapper from '../../../../common/api-wrapper';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { logComponentRenderingError } from '../../../../common/logger';

import './input-date-component.scss';

export default class InputDate extends React.Component {
  constructor(props) {
    super(props);
    this.dateOfBirth = props.data.value || '';
    this.dobObject = {
      value: this.dateOfBirth,
      name: props.data.name || 'Date2',
      type: 'change',
      id: this.props.data.id,
    };
    this.hasFocus = false;
    this.dayFocus = false;
    this.monthFocus = false;
    this.yearFocus = false;
    const splitDate = this.getSplitDateObj(this.dateOfBirth);
    this.date = {
      dd: splitDate.dd,
      mm: splitDate.mm,
      yy: splitDate.yy,
    };
    this.isDateRange = props.data.isDateRange || false;
    this.excludeDates = this.getFormattedDates(props.data.disabledDates);

    this.isDateRangeCalender = false;
    this.calStartDate = '';
    this.calEndDate = '';

    this.state = {
      datepickerDate: '',
      minOrdersByDate: moment(props.data.minOrdersByDate).isValid() ? props.data.minOrdersByDate : '',
      maxOrdersByDate: moment(props.data.maxOrdersByDate).isValid() ? props.data.maxOrdersByDate : '',
      openCal: false,
      startDate: null,
      endDate: null,
      minDate: moment(),
      selectRange: false,
      dateDropdownPlaceholder: null,
      tabFocus: false,
      isShowError: true,
      customDates: [],
    };
    this.toggleShowHideCalendar = this.toggleShowHideCalendar.bind(this);
    this.calendarCallBack = this.calendarCallBack.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.checkDateAvailability = this.checkDateAvailability.bind(this);
  }

  getFormattedDates(dates = []) {
    return dates && dates.length ? dates.map((dateString) => momentTimezone(dateString, getClientUtcOffset())) : [];
  }

  componentDidMount = () => {
    if (canUseDOM()) {
      document.addEventListener('mousedown', this.closeCal);
      document.addEventListener('touchstart', this.closeCal);
    }

    this.isDateRangeCalender &&
      this.props.data.services &&
      this.checkDateAvailability(this.calStartDate, this.calEndDate, this.excludeDates);
  };

  componentWillUnmount = () => {
    if (canUseDOM()) {
      document.removeEventListener('mousedown', this.closeCal);
      document.removeEventListener('touchstart', this.closeCal);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.openCal !== this.state.openCal && !this.state.openCal && isKeyboardNavigation()) {
      const bookTicketWrapper = document.querySelector('.booking-calendar-wrapper');
      bookTicketWrapper && bookTicketWrapper.getElementsByTagName('input')[0].focus();
    }
  };

  calendarCallBack = (dateSelection) => {
    dateSelection = convertJsDateToMomentObj(dateSelection, this.state.startDate, this.state.endDate);
    const UpdatedDates = this.getUpdatedDates(dateSelection, this.state);
    const data = this.props.data;
    const selectedDateRange = `${UpdatedDates.startDate ? moment(UpdatedDates.startDate).format('DD MMM YYYY') : ''} ${
      UpdatedDates.endDate ? `- ${moment(UpdatedDates.endDate).format('DD MMM YYYY')}` : ''
    }`;
    this.setState({
      startDate: UpdatedDates.startDate,
      endDate: UpdatedDates.endDate,
      minDate: UpdatedDates.minDate,
      selectRange: UpdatedDates.selectRange,
      dateDropdownPlaceholder: selectedDateRange,
    });
    this.props.onBlur({
      target: {
        name: data.name,
        value: moment(UpdatedDates.startDate).format(UIConfig.b2c.profile.dateFormat),
        id: data.name,
      },
    });

    if (
      (this.isDateRange && UpdatedDates.startDate && UpdatedDates.endDate) ||
      (!this.isDateRange && UpdatedDates.startDate)
    ) {
      this.toggleShowHideCalendar();
    }
    const dobCalendarWrapper = document.querySelector('.dob-calendar-wrapper');
    dobCalendarWrapper && dobCalendarWrapper.getElementsByTagName('input')[0].focus();
  };

  getUpdatedDates = (date, state) => {
    let dates = {
      startDate: state.startDate,
      endDate: state.endDate,
      minDate: state.minDate,
      selectRange: state.selectRange,
    };
    if (
      (this.isDateRange && (!dates.startDate || date.diff(dates.startDate) < 0)) ||
      (state.startDate ? date.format() === state.startDate.format() : false)
    ) {
      dates.startDate = date;
      dates.minDate = date;
      dates.endDate = null;
      dates.selectRange = true;
    } else if (this.isDateRange && !dates.endDate) {
      dates.endDate = date;
      dates.minDate = moment();
      dates.selectRange = false;
    } else {
      dates.startDate = date;
      dates.endDate = null;
      if (this.isDateRange) {
        dates.selectRange = true;
        dates.minDate = date;
      }
    }
    return dates;
  };

  setDatePickerRef = (node) => {
    this.datePickerRef = node;
  };

  closeCal = (event) => {
    if (this.datePickerRef && !this.datePickerRef.contains(event.target)) {
      this.setState({
        openCal: false,
        tabFocus: false,
      });
      if (!this.state.startDate) {
        this.setState({ dateDropdownPlaceholder: null });
        this.props.onBlur({ target: { name: this.props.data.name, value: '' } });
      }
    }
  };

  toggleShowHideCalendar = () => {
    this.setState({
      openCal: !this.state.openCal,
    });

    setTimeout(() => {
      const dateRangeEle = document.querySelector('.dob-calender');
      dateRangeEle && dateRangeEle.focus();
    }, 10);
  };

  onFocusCheckError = () => {
    this.setState({ tabFocus: !this.state.tabFocus });
  };

  onBlurCheckError = () => {
    if (!this.state.dateDropdownPlaceholder) {
      this.props.onBlur({ target: { name: this.props.data.name, value: '' } });
    }
    this.setState({ tabFocus: false });
  };

  handleDatePickerKeyDown = (e) => {
    const kCode = e.keyCode || e.which;
    if (kCode === keyCodeObject.SPACE) {
      e.target.click();
    }
  };

  findAvailableSelectedDate = (date) => {
    while (
      this.excludeDates.some(
        (disableDate) => disableDate.format(UIConfig.dateDisplayFormat) === date.format(UIConfig.dateDisplayFormat),
      )
    ) {
      date = momentTimezone(date, getClientUtcOffset()).add(1, 'days');
    }
    return date;
  };

  renderSlider = (data) => {
    const { openCal, tabFocus, dateDropdownPlaceholder } = this.state;
    return (
      <div
        className={`form-element ${openCal || tabFocus ? 'focused' : ' '} ${
          openCal || dateDropdownPlaceholder || tabFocus || this.dateOfBirth ? 'active' : ''
        } input-type-select-date`}
      >
        <DynamicContent
          tagName="label"
          attrs={{
            className: 'form-label',
            htmlFor: 'date',
          }}
          innerHtml={data.label}
        />
        <input
          type="text"
          name="date"
          id="date"
          value={dateDropdownPlaceholder}
          maxLength="0"
          onClick={this.toggleShowHideCalendar}
          onFocus={this.onFocusCheckError}
          onBlur={this.onBlurCheckError}
          className={`${this.isDateRange ? 'selected-option range' : 'selected-option single'} ${
            !openCal && data.showErrors ? 'error' : ''
          }`}
        />
        {(openCal || tabFocus) && (
          <div className="form-element-date-picker" ref={this.setDatePickerRef}>
            <DatePicker
              selected={this.state.startDate == null ? this.state.startDate : this.state.startDate.toDate()}
              selectsStart
              selectsRange
              selectsEnd={this.state.selectRange}
              inline
              startDate={this.state.startDate == null ? this.state.startDate : this.state.startDate.toDate()}
              endDate={this.state.endDate == null ? this.state.endDate : this.state.endDate.toDate()}
              maxDate={moment()
                .add(data.range ? data.range : 365, 'days')
                .toDate()}
              minDate={this.state.minDate == null ? this.state.minDate : this.state.minDate.toDate()}
              onChange={this.calendarCallBack}
              allowSameDay={this.isDateRange}
              monthsShown={detectViewPort() === 'mobile' ? 1 : 2}
              locale={currentLocale()}
            />
          </div>
        )}
        {data.ordersByDateError && <span className="error-msg body-copy-6">{data.message} error</span>}
      </div>
    );
  };

  renderDOBCalendar = (data, tabIndex) => {
    const today = moment();
    const { openCal, tabFocus, dateDropdownPlaceholder } = this.state;
    const { calenderRange, startDate, defaultDateDelta, endDate } = data;
    const isDateRangeCalendar = calenderRange || endDate;
    let minDate = isDateRangeCalendar ? getValidStartDate(startDate).add(defaultDateDelta, 'days') : undefined;
    const actualStartDate = moment(startDate).isValid()
      ? momentTimezone(startDate, getClientUtcOffset())
      : moment().add(defaultDateDelta, 'days');
    // Find next available selected date in case selected date is disabled
    if (this.excludeDates && this.excludeDates.length > 0) {
      minDate = this.findAvailableSelectedDate(minDate);
    }
    const actualEndDate =
      endDate && moment(endDate).isValid() ? momentTimezone(endDate, getClientUtcOffset()) : undefined;
    const isOptionVisible = isDateRangeCalendar ? undefined : true;

    this.isDateRangeCalender = isDateRangeCalendar;
    this.calStartDate = minDate;
    this.calEndDate = actualEndDate
      ? actualEndDate
      : momentTimezone(createDeepCopy(actualStartDate), getClientUtcOffset()).add(calenderRange, 'days');

    let commonCalLabel = data.calenderLabels || {};
    const calendarNotes = data.calendarNotes || [],
      calenderLabels = commonCalLabel && Object.keys(commonCalLabel),
      showCalendarFooter = calenderLabels || (calendarNotes && calendarNotes.length);

    let dateRangeItem;
    if (today == null) dateRangeItem = today;
    else dateRangeItem = today.toDate();

    return (
      <div
        className={`form-element dob-calendar-wrapper ${openCal || tabFocus ? 'focused' : ' '} ${
          isDateRangeCalendar ? 'booking-calendar-wrapper' : ''
        } ${openCal || dateDropdownPlaceholder || tabFocus || this.dateOfBirth ? 'active' : ''} input-type-select-date`}
      >
        <DynamicContent
          tagName="label"
          attrs={{
            className: 'form-label',
            htmlFor: 'date',
          }}
          innerHtml={data.label}
        />
        <input
          name="date"
          id="date"
          type="text"
          value={dateDropdownPlaceholder || this.dateOfBirth}
          maxLength="0"
          onClick={this.toggleShowHideCalendar}
          onFocus={this.onFocusCheckError}
          onBlur={this.onBlurCheckError}
          onKeyDown={(e) => this.handleDatePickerKeyDown(e)}
          tabIndex={tabIndex ? tabIndex : 0}
          className={`${this.isDateRange ? 'selected-option range' : 'selected-option single'} ${
            !openCal && data.showErrors ? 'error' : ''
          }`}
        />
        {openCal && (
          <div className="form-element-date-picker" ref={this.setDatePickerRef}>
            <DatePicker
              calendarClassName={`dob-calendar ${isDateRangeCalendar ? 'book-ticket-calendar' : ''}`}
              selected={
                isDateRangeCalendar
                  ? (this.state.startDate == null ? this.state.startDate : this.state.startDate.toDate()) ||
                    (minDate == null ? minDate : minDate.toDate())
                  : dateRangeItem
              }
              minDate={minDate == null ? minDate : minDate.toDate()}
              maxDate={
                isDateRangeCalendar
                  ? (actualEndDate == null ? actualEndDate : actualEndDate.toDate()) ||
                    momentTimezone(createDeepCopy(actualStartDate), getClientUtcOffset())
                      .add(calenderRange, 'days')
                      .toDate()
                  : dateRangeItem
              }
              onChange={this.calendarCallBack}
              yearDropdownItemNumber={110}
              dropdownMode="select"
              className={'dob-calender'}
              showMonthDropdown={isOptionVisible}
              showYearDropdown={isOptionVisible}
              scrollableYearDropdown={isOptionVisible}
              inline
              excludeDates={this.excludeDates.map((dates) => {
                return dates.toDate();
              })}
              allowSameDay
              shouldCloseOnSelect
              highlightDates={this.state.customDates.map((dates) => {
                return dates.toDate();
              })}
              locale={currentLocale()}
            >
              {showCalendarFooter && (
                <div className="calendar-footer">
                  <div className="calendar-labels">
                    {calenderLabels &&
                      calenderLabels.map((item) => (
                        <div className="calendar-label">
                          <span className={`calendar-label-icon ${item}-icon`} />
                          <DynamicContent
                            tagName="span"
                            attrs={{ className: 'calendar-label-title' }}
                            innerHtml={commonCalLabel[item]}
                          />
                        </div>
                      ))}
                  </div>
                  {calendarNotes && calendarNotes.length > 0 && (
                    <div className="help-text-container">
                      {calendarNotes.map((opt, i) => (
                        <div key={i} className="help-text-wrapper">
                          <DynamicContent tagName="span" attrs={{ className: 'title' }} innerHtml={opt.title} />
                          <DynamicContent
                            tagName="span"
                            attrs={{ className: 'description' }}
                            innerHtml={opt.description}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </DatePicker>
          </div>
        )}
        {data.showErrors && <span className="error-msg body-copy-6">{data.message}</span>}
      </div>
    );
  };

  checkDateAvailability = (startDate, endDate, excludeDates) => {
    let availableDate = [],
      excludedDates = [],
      disableDates = [],
      enabledDates = [],
      soldOutDates = [];
    const fromDate = moment(startDate).format(UIConfig.b2c.profile.dateFormat),
      toDate = moment(endDate).format(UIConfig.b2c.profile.dateFormat),
      { getProductOccupancy, performance } = this.props.data.services,
      productId = document.getElementById('productId').value,
      performanceAPI = performance.url.replace('{0}', productId);

    if (excludeDates && excludeDates.length > 0) {
      excludeDates.forEach((item) => {
        excludedDates.push(moment(item).format(UIConfig.b2c.profile.dateFormat));
      });
    }

    ApiWrapper.experienceServices(
      {
        method: 'GET',
        url: `${getProductOccupancy.url}?productId=${productId}&fromDate=${fromDate}&toDate=${toDate}`,
        headers: {
          'Content-Type': 'application/json',
        },
      },
      false,
    ).then((response) => {
      if (response.data && response.data.dateLimits && response.data.dateLimits.length > 0) {
        response.data.dateLimits.forEach((item) => {
          if (
            availableDate.indexOf(item.date) === -1 &&
            excludedDates.indexOf(item.date) === -1 &&
            item.available > 0
          ) {
            availableDate.push(item.date);
          } else {
            if (excludedDates.indexOf(item.date) === -1) {
              soldOutDates.push(item.date);
            }
          }
        });
      }

      PerformanceService.getPerformanceData(
        performanceAPI,
        fromDate,
        toDate,
        true,
        UIConfig.loader.defaultPreLoaderTarget,
      ).then((response) => {
        if (
          response.data &&
          response.data.performancelist &&
          response.data.performancelist.performance &&
          response.data.performancelist.performance.length > 0
        ) {
          response.data.performancelist.performance.forEach((item) => {
            if (availableDate.indexOf(item.date) !== -1) {
              if (parseInt(item.availability.available) === 0) {
                soldOutDates.push(momentTimezone(item.date, getClientUtcOffset()));
              } else if (item.sellable !== 'true') {
                disableDates.push(momentTimezone(item.date, getClientUtcOffset()));
              } else {
                enabledDates.push(momentTimezone(item.date, getClientUtcOffset()));
              }
            }
          });
          const performanceDates = response.data.performancelist.performance.map((item) => item.date);
          availableDate.forEach((occupancyDate) => {
            if (performanceDates.indexOf(occupancyDate) === -1)
              disableDates.push(momentTimezone(occupancyDate, getClientUtcOffset()));
          });
          const customDates = [
            {
              'disable-date': disableDates,
            },
            {
              'available-seats': enabledDates,
            },
            {
              'sold-out': soldOutDates.map((item) => momentTimezone(item, getClientUtcOffset())),
            },
          ];

          soldOutDates.length &&
            soldOutDates.forEach((item) => {
              excludedDates.indexOf(item) === -1 && this.excludeDates.push(momentTimezone(item, getClientUtcOffset()));
            });

          this.setState({ customDates });
        }
      });
    });
  };

  renderDataTypeWithCalender = (data) => {
    return (
      <div id={data.id} className={`form-element ${data.active} input-type-text`}>
        <div className="date-picker-container">
          <DatePicker
            showDisabledMonthNavigation
            ref={(el) => this.onDatepickerRef(el)}
            onChange={this.handleDate}
            onBlur={this.handleBlur}
            selected={
              this.state.datepickerDate == null ? this.state.datepickerDate : this.state.datepickerDate.toDate()
            }
            placeholderText={data.id}
            className="start-date-with-calendar"
            id={`${data.id}`}
            minDate={
              this.state.minOrdersByDate == null ? this.state.minOrdersByDate : this.state.minOrdersByDate.toDate()
            }
            maxDate={
              this.state.maxOrdersByDate == null ? this.state.maxOrdersByDate : this.state.maxOrdersByDate.toDate()
            }
            dateFormat="DD/MM/YYYY"
            disabled={this.state.minOrdersByDate === undefined && this.state.maxOrdersByDate === undefined}
            locale={currentLocale()}
          />
          <div className="calendar-icon">
            <SvgSprite id="icn-calendar" />
          </div>
        </div>
        {data.showErrors && this.state.isShowError && <span className="error-msg body-copy-6">{data.message}</span>}
      </div>
    );
  };

  onDatepickerRef = (el) => {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  };

  getSplitDateObj = (date) => {
    let temp = ['', '', ''];
    if (date) {
      if (date.includes('-')) {
        temp = date.split('-').reverse();
      } else {
        temp = date.split('/');
      }
    }

    return {
      dd: temp[0] || '',
      mm: temp[1] || '',
      yy: temp[2] || '',
    };
  };
  updatedDob = () => {
    const dob = `${this.date.dd}/${this.date.mm}/${this.date.yy}`;
    this.dobObject.value = dob;
    this.dobObject.type = 'change';
    this.props.onChange(this.dobObject);
  };
  onkeyuphandler = (e) => {
    let val = e.target.value;
    const type = e.target.name;
    this[type + 'Focus'] = true;
    if (type === 'day') {
      if (/^([0-9]|0[1-9]|[12]\d|3[01])$/.test(val) || val === '') {
        this.date.dd = val;
        this.updatedDob();
        if (val.length >= 2) {
          const nextElement = e.target.parentElement.nextElementSibling.lastElementChild;
          this[nextElement.name + 'Focus'] = true;
          nextElement.focus();
        }
      }
    } else if (type === 'month') {
      if (/^([0-9]|0[1-9]|1[012])$/.test(val) || val === '') {
        this.date.mm = val;
        this.updatedDob();
        if (val.length >= 2) {
          const nextElement = e.target.parentElement.nextElementSibling.lastElementChild;
          this[nextElement.name + 'Focus'] = true;
          nextElement.focus();
        }
      }
    } else {
      if (/^[0-9]{0,4}$/.test(val)) {
        this.date.yy = val;
        this.updatedDob();
      }
    }
  };

  onBlur = (e) => {
    this[e.target.name + 'Focus'] = false;
    setTimeout(() => {
      if (!this.dayFocus && !this.monthFocus && !this.yearFocus) {
        this.dobObject.type = e.type;
        this.props.onBlur && this.props.onBlur({ target: this.dobObject });
        this.hasFocus = false;
      }
    }, 0);
  };

  onFocus = (e) => {
    if (!this.hasFocus) {
      this.dobObject.type = e.type;
      this.props.onFocus && this.props.onFocus({ target: this.dobObject });
    }
    this[e.target.name + 'Focus'] = true;
    this.hasFocus = true;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.value !== this.props.data.value) {
      this.dateOfBirth = nextProps.data.value;
      this.date = this.getSplitDateObj(this.dateOfBirth);

      if (!nextProps.data.value) {
        this.setState({ dateDropdownPlaceholder: '', startDate: '' });
      }
    }
    if (
      nextProps.data.maxOrdersByDate !== this.props.data.maxOrdersByDate ||
      nextProps.data.minOrdersByDate !== this.props.data.minOrdersByDate
    ) {
      this.setState({
        minOrdersByDate: moment(nextProps.data.minOrdersByDate).isValid() ? nextProps.data.minOrdersByDate : '',
        maxOrdersByDate: moment(nextProps.data.maxOrdersByDate).isValid() ? nextProps.data.maxOrdersByDate : '',
      });
    }
  }

  handleDate = (date) => {
    date = convertJsDateToMomentObj(date);
    const finalDate = moment(date).isValid() ? date : '';
    this.setState({
      datepickerDate: finalDate,
      isShowError: !finalDate,
    });
  };

  handleBlur = (e) => {
    e.target &&
      (moment(e.target.value, 'DD/MM/YYYY', true).isValid()
        ? this.props.handleDateProp(e.target)
        : (e.target.value = ''));
  };

  render() {
    try {
      const data = this.props.data;
      const tabIndex = this.props.isTabIndex;
      if (data.type && data.type.toLowerCase() === 'rangecalendar') {
        return this.renderSlider(data);
      } else if (data.type && data.type.toLowerCase() === 'calendar') {
        return this.renderDOBCalendar(data, tabIndex);
      } else if (data.class && data.class.includes('DateType-With-Calendar')) {
        return this.renderDataTypeWithCalender(data);
      } else if (isMobileOrTab()) {
        return (
          <div id={data.id} className={`form-element ${data.active} input-type-text`}>
            <DynamicContent
              tagName="label"
              attrs={{
                className: 'form-label',
                htmlFor: 'dob',
              }}
              innerHtml={data.label}
            />
            <input
              type="date"
              className="form-input body-copy-3"
              name={data.name}
              value={data.value}
              placeholder=""
              maxLength={data.maxLength}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              onFocus={this.props.onFocus}
            />
            {data.showErrors && <span className="error-msg body-copy-6">{data.message}</span>}
          </div>
        );
      } else {
        const dateLabels = data.dateLabels || {};

        return (
          <div
            id={data.id}
            className={`form-element dob-wrapper ${this.date.dd ? 'active' : null} ${
              data.showErrors ? 'dob-error' : null
            }`}
          >
            <DynamicContent
              tagName="span"
              attrs={{
                className: 'form-element dob-label',
                htmlFor: 'day',
              }}
              innerHtml={data.label}
            />
            <div className={`form-element dob input-type-text dob-day${this.date.dd ? ' active' : ''}`}>
              <input
                className="form-input body-copy-3 dob"
                name={'day'}
                id={'day'}
                onChange={this.onkeyuphandler}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                value={this.date.dd}
                type="text"
                placeholder={dateLabels.dayLabel}
                autoComplete={'off'}
                tabIndex={tabIndex ? tabIndex : 0}
                aria-label={`${data.label} ${dateLabels.dayLabel}`}
              />
            </div>
            <div className={`form-element dob input-type-text dob-month ${this.date.mm ? 'active' : null}`}>
              <input
                name={'month'}
                className="form-input body-copy-3 dob"
                onChange={this.onkeyuphandler}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                value={this.date.mm}
                type="text"
                placeholder={dateLabels.monthLabel}
                autoComplete={'off'}
                tabIndex={tabIndex ? tabIndex : 0}
              />
            </div>
            <div className="form-element dob input-type-text  dob-year">
              <input
                name={'year'}
                className="form-input body-copy-3 dob"
                onChange={this.onkeyuphandler}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                value={this.date.yy}
                type="text"
                placeholder={dateLabels.yearLabel}
                autoComplete={'off'}
                tabIndex={tabIndex ? tabIndex : 0}
              />
            </div>
            {data.showErrors && <span className="error-msg body-copy-6">{data.message}</span>}
          </div>
        );
      }
    } catch (err) {
      return logComponentRenderingError(err, 'InputDate');
    }
  }
}
