/*
 * my-profile-b2c-component.js
 * This file contains code for newsletter component, it renders form with right and left image container
 * @licensor  Miral
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Form from '../../container/form/form-component';
import { CorporateEmailServices, ProfileServices, ServiceConfig } from '../../../common/services';
import ErrorSummary from '../../presentation/base/error-summary/error-summary-component';
import { DynamicContent, SvgSprite } from '../../presentation/base';
import {
  getLoggedInUser,
  getErrorMap,
  deepCloneObject,
  differenceByKey,
  isMobileOrTab,
  getLocalStorageByKey,
  canUseDOM,
  isLoggedInUser,
  setLocalStorage,
  getMembershipDetails,
  getLeisureFacility,
  getRenewTokenConfig,
  setCookie,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { GLOBAL_VARS } from '../../../common/global-vars';
import moment from 'moment';
import { Logging, logComponentRenderingError } from '../../../common/logger';
import { checkTenant, isEmpty, replacePlaceHolder, resolvePath } from '../../../common/utility/helpers';
import ConfirmationDialog from '../../presentation/confirmation-dialog';
import { replaceByCorpEmailId } from '../jss-form/helper';
import { fetchMembershipDetails } from '../../../common/session/renew-token';

const CorporateUserFeild = ({ className, sectionSubTitle, children }) => {
  try {
    return (
      <fieldset className={`corporate-user-field ${className}`} aria-label="field section">
        <section className="fieldset-sub-title">{sectionSubTitle}</section>
        {children}
      </fieldset>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CorporateUserFeild');
  }
};

const MyProfileB2c = (props) => {
  const isViewportMobileOrTab = isMobileOrTab();
  const parkName = getLeisureFacility() || '';
  let personalFormData = useRef({ ...deepCloneObject(props.data), sections: [] });
  let membershipFormData = useRef({ ...deepCloneObject(props.data), sections: [] });
  const corpMembershipFormData = useRef({ ...deepCloneObject(props.data), sections: [] });
  const createCorpMemFormData = useRef({ ...deepCloneObject(props.data), sections: [] });
  const inactiveCorpMemFormData = useRef({ ...deepCloneObject(props.data), sections: [] });
  let communicationFormData = useRef({ ...deepCloneObject(props.data), sections: [] });
  const isMemberShipTitleShown = useRef(false);
  const privacyPolicyUpdate = useRef(null);
  const oldPreferenceSection = useRef(null);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const [serverResponse, setServerResponse] = useState({
    serverErrors: null,
    dataLoaded: props.isInlineMode ? true : false,
    submitCommSuccess: false,
    submitInfoSuccess: false,
  });
  const [membershipResponse, setMembershipResponse] = useState({
    showMembership: false,
    membershipData: {},
    membershipDetails: [],
  });

  const [openCorpDelete, setOpenCorpDelete] = useState(false);
  const [corpUserStatus, setCorpUserStatus] = useState('');
  const [corpUserEmail, setCorpUserEmail] = useState('');
  const [isCorpEmailSuccess, setCorpEmailSuccess] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [isOldConsentUser, setConsentUser] = useState(false);

  let prefilldata = useRef({
    communicationPreferencesVO: null,
    familiesVO: null,
  });

  const config = useRef({
    componentName: 'Profile',
    uniqueKey: 'channelType',
    preLoadTarget: '.my-profile-b2c',
    status: props.data.apiStatus,
    mapping: {},
    fieldTypes: {
      Link: 'link',
      Button: 'button',
      CheckBox: 'checkbox',
      Date: 'date',
      Reset: 'reset',
      Date2: 'date2',
      SelectWithText: 'SelectWithText',
    },
    sourceSystemName: props.data.sourceSystemName,
  });
  const isEA = checkTenant(UIConfig.iamMapping.etihadarena);
  const isYMC = checkTenant(UIConfig.iamMapping.ymc);
  const isQAW = checkTenant(UIConfig.iamMapping.ppad);
  const isNotPark = !isEA && !isYMC && !isQAW;
  let totalMemberCount = 1;
  let responsedata = useRef({
    showMembership: false,
    membershipDetails: [],
    membershipData: {},
  });

  const loadMembershipData = () => {
    const localstoragemembership = getMembershipDetails();

    if (localstoragemembership) {
      setMembershipResponse({
        showMembership: localstoragemembership.isMembershipDetails,
        membershipDetails: localstoragemembership.membershipDetails,
        membershipData: localstoragemembership.membershipData,
      });

      responsedata.current.showMembership = localstoragemembership.isMembershipDetails;
      responsedata.current.membershipDetails = localstoragemembership.membershipDetails;
      responsedata.current.membershipData = localstoragemembership.membershipData;
    }
  };

  useEffect(() => {
    if (!isExperienceEditorActive()) {
      loadFormData();
    }

    loadMembershipData();

    setTimeout(() => {
      onPolicyUpdateClick();
    }, 2500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabRedirect = () => {
    const myPreferencesElmn = document.getElementById('mypreferences_tab_1');

    myPreferencesElmn.click();
  };

  const onPolicyUpdateClick = () => {
    const updatePolicyLinkTop = privacyPolicyUpdate?.current?.querySelector('.member-description a');
    const updatePolicyLink = oldPreferenceSection?.current?.querySelector(
      '.my-profile-b2c-forms--comm .fieldset-title a',
    );
    updatePolicyLinkTop && updatePolicyLinkTop.addEventListener('click', handleTabRedirect);
    updatePolicyLink && updatePolicyLink.addEventListener('click', handleTabRedirect);
  };

  const loadFormData = () => {
    let newurl = getLoggedInUser().yasId;

    createConfiguration();
    var guestUrl = props.data.services.getGuestProfile.url.replace('{yasId}', newurl);
    const getGuestApiComplete = ProfileServices.GetGuestInfo(
      config.current.componentName,
      guestUrl,
      true,
      UIConfig.loader.defaultPreLoaderTarget,
    );

    getGuestApiComplete
      .then((infoResponse) => {
        if (infoResponse.data) {
          const { yiCmpFlag } = infoResponse.data;
          setCookie('isOldConsentUser', `${yiCmpFlag}`);
          setConsentUser(!yiCmpFlag);
        }
        handleApiResponse(infoResponse, props.data.services.getGuestProfile, () => {
          prefilldata.current = profielInfoDataMapper(infoResponse.data);
          getCommunicationSection();
        });
        loadMembershipData();
      })
      .catch((response) => {
        if (response && response.error) {
          response.error.code = UIConfig.errorCodes.guestGetFail;
        }
        setServerResponse({
          ...serverResponse,
          serverErrors: getErrorMap(
            guestUrl,
            props.data.services.getGuestProfile.errors,
            false,
            response.error,
            serverResponse.serverErrors,
          ),
          dataLoaded: true,
        });
      });
  };

  /**
   * On multiply field initialy and when user click add more option.
   * @param section section having family fields
   * @param familyData data to be usesd
   */

  const createFamilySection = (section, familyData) => {
    section.defaultFields = section.defaultFields || getRequiredFields(section);
    section.fields = [];

    if (familyData && familyData.length > 0) {
      totalMemberCount = familyData.length;
      const data = {};
      for (let index = 0; index < totalMemberCount; index++) {
        section.defaultFields.forEach((field) => {
          addFieldInFamilySection(section, field, index);
          data[field.name + index] = familyData[index][field.name];
        });
      }
      mapSectionWithData(section, data);
    } else {
      addBlankRowToFamilySection(section, 0);
    }
  };

  const getRequiredFields = (section) => {
    //remove add button from defaultFields to be added everytime
    section.fields.forEach((field, key) => {
      if (field.type.toLowerCase() === config.current.fieldTypes.Link) {
        field.type = config.current.fieldTypes.Button;
        section.actionButton = field;
        section.fields.splice(key, 1);
      }
    });
    return section.fields;
  };

  const addFieldInFamilySection = (section, field, position, mapfields) => {
    let { name, id, ...rest } = field;
    //add index with each field and push in fields array as new field
    const newField = {
      name: name + position,
      id: id + position,
      ...rest,
    };
    mapfields && mapFieldWithValue(newField, {});
    section.fields.push(newField);
  };

  const addBlankRowToFamilySection = (section, position) => {
    section.defaultFields.forEach((field) => {
      addFieldInFamilySection(section, field, position, true);
    });
  };

  const addFamilyMember = (formData, payload) => {
    formData.sections.forEach((section) => {
      if (section.sectionType === UIConfig.profile.sectionType.family) {
        addBlankRowToFamilySection(section, getCurrentFamilyRowCount(section));
        totalMemberCount += 1;
      }
    });
    return formData;
  };

  const getCurrentFamilyRowCount = (section) => {
    return totalMemberCount;
  };

  const profielInfoDataMapper = ({
    mdmId,
    firstName,
    lastName,
    email,
    mobileNum,
    dob,
    country,
    countryCode,
    countryAlphaCode,
    nationality,
    nationalityAlphaCode,
    gender,
    familiesVO,
  }) => {
    const profileInfo = {
      mdmId,
      firstName,
      lastName,
      email,
      mobileNum,
      Date2: dob,
      country,
      countryCode,
      nationality,
      gender,
      familiesVO,
    };
    if (countryAlphaCode !== undefined) {
      profileInfo.country = countryAlphaCode;
      profileInfo.nationality = nationalityAlphaCode;
    }
    return profileInfo;
  };

  const createConfiguration = () => {
    props.data.sections.forEach((section) => {
      if (section.sectionType === UIConfig.profile.sectionType.configSection) {
        createMappingConfig(section);
      }
    });
  };

  const getCommunicationSection = () => {
    ProfileServices.GetGuestCommunicationPref(
      config.current.componentName,
      props.data.services.getGuestCommunicationPref.url,
      true,
      config.current.preLoadTarget,
      props.data.includeLeisure,
    )
      .then((commResponse) => {
        handleApiResponse(commResponse, props.data.services.getGuestCommunicationPref, () => {
          prefilldata.current = Object.assign(prefilldata.current, commResponse.data);
          formatDataForForms(() => setServerResponse({ ...serverResponse, dataLoaded: true }));
        });
        // Update state based on user consent
      })
      .catch((response) => {
        if (response && response.error) {
          response.error.code = UIConfig.errorCodes.commGetFail;
        }
        setServerResponse({
          ...serverResponse,
          serverErrors: getErrorMap(
            props.data.services.getGuestCommunicationPref.url,
            props.data.services.getGuestCommunicationPref.errors,
            false,
            response.error,
            serverResponse.serverErrors,
          ),
          dataLoaded: true,
        });
      });
  };

  const deleteCorporateEmail = async (corpEmailId) => {
    CorporateEmailServices.deleteCorporateEmail(
      config.current.componentName,
      replacePlaceHolder('{emailId}', corpEmailId, props.data.services.deletecorporateaccount.url),
      true,
      config.current.preLoadTarget,
    )
      .then(async () => {
        if (!isExperienceEditorActive()) {
          const config = getRenewTokenConfig();
          await fetchMembershipDetails(config);

          setLocalStorage(UIConfig.localStoreKeys.IS_CORP_USER_STATUS_CHANGE, true);
          loadFormData();
        }
      })
      .catch((response) => {
        if (response && response.error) {
          response.error.code = UIConfig.errorCodes.deleteFail;
        }

        setServerResponse({
          ...serverResponse,
          serverErrors: getErrorMap(
            props.data.services.deletecorporateaccount.url,
            props.data.services.deletecorporateaccount.errors,
            false,
            response.error,
            serverResponse.serverErrors,
          ),
          dataLoaded: true,
        });
      })
      .finally(() => {
        handleOpenCorpDelete(false);
      });
  };

  const handleApiResponse = (response, service, cb) => {
    if (response.data && response.data.code && response.data.code !== 404) {
      const error = {
        code: response.data.code,
        text: response.data.message,
      };
      setServerResponse({
        ...serverResponse,
        serverErrors: getErrorMap(service.url, service.errors, false, error, serverResponse.serverErrors),
        dataLoaded: true,
      });
    } else {
      cb && cb();
    }
  };

  const loadDefaultUserValues = (fields) => {
    const userData = getUserData();

    const userDefaultData = {
      firstName: userData?.userName,
      lastName: userData?.lastName,
      myPassId: userData?.yasId,
      personalEmail: userData?.email,
      leisureFacility: userData?.tenantName || userData?.leisureFacility,
      countryofresidence: userData?.country,
      mobileNumber: userData?.mobileNum,
      nationality: userData?.nationalityAlphaCode,
    };

    return fields.map((field) => {
      field.value = userDefaultData[field?.name];
      return field;
    });
  };

  const formatDataForForms = (callBack) => {
    personalFormData.current.sections = [];
    membershipFormData.current.sections = [];
    communicationFormData.current.sections = [];
    corpMembershipFormData.current.sections = [];
    createCorpMemFormData.current.sections = [];
    inactiveCorpMemFormData.current.sections = [];

    props.data.sections.forEach((section) => {
      switch (section.sectionType) {
        case UIConfig.profile.sectionType.personal:
          mapSectionWithData(section, prefilldata.current);
          personalFormData.current.sections.push(section);
          break;

        case UIConfig.profile.sectionType.family:
          createFamilySection(section, prefilldata.current.familiesVO);
          personalFormData.current.sections.push(section);
          break;

        case UIConfig.profile.sectionType.communication:
          const data = getCommunicationPrefData(section, prefilldata.current.communicationPreferencesVO);
          mapSectionWithData(section, data);
          communicationFormData.current.sections.push(section);
          break;
        case UIConfig.profile.sectionType.membership:
          responsedata.current.membershipDetails.length > 0 &&
            responsedata.current.membershipDetails.forEach((memberdata, index) => {
              if (memberdata?.segmentType === UIConfig.CORPORATE) return;

              const formattedData = getmembershipData(section, memberdata);
              const sectionSubTitle =
                props.data.membershipDiscountCardTitle &&
                props.data.membershipDiscountCardTitle[memberdata.partnerCode];
              const cardNumber = formattedData.cardNumber;
              const expiryDate = formattedData.expiryDate;
              let updatedsection = { ...section };
              if (index !== 0) {
                updatedsection = { ...updatedsection, sectionTitle: `<h2> </h2>` };
              }
              updatedsection = { ...updatedsection, sectionsubTitle: sectionSubTitle, cardNumber, expiryDate };
              mapSectionWithData(updatedsection, formattedData);
              membershipFormData.current.sections.push(updatedsection);
            });

          if (membershipFormData.current.sections.length) {
            isMemberShipTitleShown.current = true;
          }
          break;
        case UIConfig.profile.sectionType.corporateMembership:
          if (responsedata.current?.membershipDetails?.length > 0) {
            const corporateMemberData = responsedata.current.membershipDetails.find(
              (memberdata) => memberdata.segmentType === UIConfig.CORPORATE,
            );
            if (!corporateMemberData) return;

            setCorpUserStatus(corporateMemberData?.status);
            corpUserEmail?.length < 1 && setCorpUserEmail(corporateMemberData?.email);

            const sectionSubTitle = props?.data?.membershipDiscountCardTitle?.['Corporate'] || '';

            let updatedsection = { ...section };

            updatedsection = { ...updatedsection, sectionSubTitle };

            if (isMemberShipTitleShown.current) {
              updatedsection = { ...updatedsection, sectionTitle: '' };
            }

            //set default email id
            updatedsection.fields.forEach((field) => {
              if (field?.type === 'Email' && field?.id === 'corporateEmail') {
                field.value = corporateMemberData?.email;
              }
            });

            const updatedCorpSection = { ...updatedsection, sectionTitle: '' };
            membershipFormData.current.sections.push(updatedsection);
            corpMembershipFormData.current.sections.push(updatedCorpSection);
          }
          break;
        case UIConfig.profile.sectionType.createCorporateMember:
          if (responsedata.current?.membershipDetails?.length > 0) {
            const corporateMemberData = responsedata.current.membershipDetails.find(
              (memberdata) => memberdata?.segmentType === UIConfig.CORPORATE,
            );
            if (!corporateMemberData) return;

            setCorpUserStatus(corporateMemberData?.status);

            const sectionSubTitle = props?.data?.membershipDiscountCardTitle?.['Corporate'] || '';

            let updatedsection = { ...section };
            const updatedFields = loadDefaultUserValues(updatedsection.fields);

            updatedsection.fields = [...updatedFields];
            updatedsection = { ...updatedsection, sectionSubTitle };

            if (isMemberShipTitleShown.current) {
              updatedsection = { ...updatedsection, sectionTitle: '' };
            }

            const updatedCorpSection = { ...updatedsection, sectionTitle: '' };
            membershipFormData.current.sections.push(updatedsection);
            createCorpMemFormData.current.sections.push(updatedCorpSection);
          }
          break;

        case UIConfig.profile.sectionType.inactiveCorporateMember:
          if (responsedata.current?.membershipDetails?.length > 0) {
            const corporateMemberData = responsedata.current.membershipDetails.find(
              (memberdata) => memberdata?.segmentType === UIConfig.CORPORATE,
            );
            if (!corporateMemberData) return;

            setCorpUserStatus(corporateMemberData?.status);
            setCorpUserEmail(corporateMemberData?.email);

            const sectionSubTitle = props?.data?.membershipDiscountCardTitle?.['Corporate'] || '';

            let updatedsection = { ...section };
            const updatedFields = loadDefaultUserValues(updatedsection.fields);

            updatedsection.fields = [...updatedFields];
            updatedsection = { ...updatedsection, sectionSubTitle };

            //set default email id
            updatedsection.fields.forEach((field) => {
              if (field?.type === 'Email' && field?.id === 'corporateEmail') {
                field.value = corporateMemberData?.email;
              }
            });

            if (isMemberShipTitleShown.current) {
              updatedsection = { ...updatedsection, sectionTitle: '' };
            }

            const updatedCorpSection = { ...updatedsection, sectionTitle: '' };
            membershipFormData.current.sections.push(updatedsection);
            inactiveCorpMemFormData.current.sections.push(updatedCorpSection);
          }
          break;

        case UIConfig.profile.sectionType.submitPref:
          personalFormData.current.sections.push(section);
          break;

        case UIConfig.profile.sectionType.submitComm:
          communicationFormData.current.sections.push(section);
          break;

        case UIConfig.profile.sectionType.emailSubmit:
          createCorpMemFormData.current.sections.push(section);
          break;

        default:
          break;
      }
    });
    if (callBack) {
      callBack();
    }
  };

  const createMappingConfig = (section) => {
    section.fields.forEach((field) => {
      config.current.mapping[field.name] = convertOptionsToMap(field.options);
    });
  };

  const convertOptionsToMap = (optionsArr = []) => {
    const convertedMap = {};

    optionsArr.forEach((option) => {
      convertedMap[option.value] = option.text;
    });
    return convertedMap;
  };

  const getCommunicationPrefData = (section, apiData) => {
    const data = {};
    if (apiData && apiData.length) {
      for (let index = 0; index < apiData.length; index++) {
        if (apiData[index].implicit_opt_in_out === config.current.mapping.optIn.yes) {
          data[apiData[index].channelType] = apiData[index].channelType;
        } else {
          data[apiData[index].channelType] = '';
        }
        data.language = apiData[index].language;
      }
    } else {
      section.fields.forEach((field) => {
        data[field.name] = '';
        data.language = '';
      });
    }
    return data;
  };

  const getmembershipData = (section, apiData) => {
    const data = {};
    section.fields.forEach((field) => {
      data[field.name] = apiData[field.id];
    });
    return data;
  };

  const getDateValue = (data, field) => {
    let date = '';
    if (data[field.name]) {
      if (field.name.toLowerCase() === 'expirydate') {
        date = moment(data[field.name], [
          UIConfig.b2c.profile.dateFormat,
          UIConfig.b2c.profile.dateDisplayFormat,
        ]).format(UIConfig.b2c.profile.dateDisplayFormat);
      } else {
        if (isViewportMobileOrTab) {
          date = moment(data[field.name], [
            UIConfig.b2c.profile.dateFormat,
            UIConfig.b2c.profile.dateDisplayFormat,
          ]).format(UIConfig.b2c.profile.dateFormat);
        } else {
          date = moment(data[field.name], [
            UIConfig.b2c.profile.dateFormat,
            UIConfig.b2c.profile.dateDisplayFormat,
          ]).format(UIConfig.b2c.profile.dateDisplayFormat);
        }
      }
    }
    return date;
  };

  const mapSectionWithData = (section, data) => {
    section.fields.forEach((field) => {
      mapFieldWithValue(field, data);
    });
  };
  const mapFieldWithValue = (field, data) => {
    const selectedFieldType = field.type.toLowerCase();
    if (selectedFieldType === config.current.fieldTypes.CheckBox) {
      field.checked = data[field.name];
    }
    if (field.type.toLowerCase().indexOf('date') !== -1 || field.name.indexOf('dob') !== -1) {
      field.type = field.type !== 'Date2' ? (isViewportMobileOrTab ? 'Date' : 'Text') : field.type;
      field.validations = isViewportMobileOrTab ? [] : field.validations;
      data[field.name] = getDateValue(data, field);
    }
    if (selectedFieldType === config.current.fieldTypes.SelectWithText.toLowerCase()) {
      field.dropdownValue = data['countryCode'];
    }
    field.value = data[field.name];
  };

  const formatCommPrefData = (payload) => {
    const communicationData = {};
    communicationData.postData = [];
    communicationData.putData = [];

    props.data.sections.forEach((section) => {
      if (section.sectionType === UIConfig.profile.sectionType.communication) {
        const availablePreferences = [];
        section.fields.forEach((field) => {
          if (config.current.mapping[field.name]) {
            const preference = createCommunicationPrefObj(field, payload);
            availablePreferences.push(preference);
          }
        });
        const apiData = prefilldata.current.communicationPreferencesVO
          ? prefilldata.current.communicationPreferencesVO
          : [];
        const toPOSTRequest = availablePreferences.filter(differenceByKey(apiData, config.current.uniqueKey)),
          differenceInKeys = toPOSTRequest.map((req) => req.channelType),
          filteredFormData = availablePreferences.filter((data) => differenceInKeys.indexOf(data.channelType) < 0),
          toPUTRequest = mergeArrays(config.current.uniqueKey, apiData, filteredFormData);

        communicationData.putData = toPUTRequest;
        communicationData.postData = toPOSTRequest;
      }
    });
    return communicationData;
  };

  /**
   * mergeArrays, merge two arrays of preference with replace or add
   * @param    {Void}
   * @return   {Void}
   */
  function mergeArrays(key, arrays) {
    const array = [];
    const groups = new Map(); // key => [pos in array, [array, of, objects, with, the, same, key]]

    for (let i = 1; i < arguments.length; ++i) {
      for (let j = 0; j < arguments[i].length; ++j) {
        const element = arguments[i][j];
        if (element.hasOwnProperty(key)) {
          const keyValue = element[key];
          if (groups.has(keyValue)) {
            groups.get(keyValue)[1].push(element);
          } else {
            array.push(element);
            groups.set(keyValue, [array.length - 1, []]);
          }
        } else {
          array.push(element);
        }
      }
    }

    for (const group of groups) {
      if (group[1][1].length === 0) continue;
      array[group[1][0]] = Object.assign.apply(Object, [{}, array[group[1][0]]].concat(group[1][1]));
    }
    return array;
  }

  const createCommunicationPrefObj = (field, payload) => {
    const commPrefObj = { ...config.current.mapping[field.name] };
    commPrefObj.implicit_opt_in_out =
      payload[field.name] && payload[field.name] !== 'false'
        ? config.current.mapping.optIn.yes
        : config.current.mapping.optIn.no;
    commPrefObj.explicit_opt_in_out =
      payload[field.name] && payload[field.name] !== 'false'
        ? config.current.mapping.optIn.yes
        : config.current.mapping.optIn.no;
    commPrefObj.leisureFacility = parkName;
    commPrefObj.language = payload.language;
    commPrefObj.channelType = field.name;
    commPrefObj.channelValue =
      field.name === config.current.mapping.SMS.channelType ||
      field.name === config.current.mapping.Mobile.channelType ||
      field.name === config.current.mapping.Notification.channelType
        ? prefilldata.current.mobileNum
        : field.name === config.current.mapping.Email.channelType
        ? prefilldata.current.email
        : '';
    return commPrefObj;
  };

  const formatPersonalDataForUpdate = (data) => {
    let formattedData = {};
    formattedData.familiesVO = [];

    props.data.sections.forEach((section) => {
      if (section.sectionType === UIConfig.profile.sectionType.family) {
        const totalMembers = getCurrentFamilyRowCount(section),
          familyData = [];
        for (let index = 0; index < totalMembers; index++) {
          const memberData = createMemberData(section, index, data);
          memberData && familyData.push(memberData);
        }
        formattedData.familiesUO = familyData;
      }
      if (section.sectionType === UIConfig.profile.sectionType.personal) {
        formattedData = profielInfoDataMapper(data);
        formattedData.mdmId = prefilldata.current.mdmId;
        formattedData.dob = converDateToApiFormat(data.Date2);
        formattedData.sourceSystemName = config.current.sourceSystemName;
        formattedData.countryCode = data.additionalObjVal;
      }
    });
    return formattedData;
  };

  const createMemberData = (section, index, data) => {
    let memberData = {};
    section.defaultFields.forEach((field) => {
      memberData[field.name] = data[field.name + index];
      if (field.name === 'roleTo') {
        memberData.roleFrom = config.current.mapping.Relation[memberData[field.name]];
      } else if (field.type.toLowerCase() === config.current.fieldTypes.Date) {
        memberData[field.name] = converDateToApiFormat(memberData[field.name]);
      }
    });
    memberData.mdmId =
      prefilldata.current.familiesVO &&
      prefilldata.current.familiesVO[index] &&
      prefilldata.current.familiesVO[index].mdmId
        ? prefilldata.current.familiesVO[index].mdmId
        : '';

    memberData.status = memberData.mdmId ? config.current.status : null;

    if (isMemberDataInValid(memberData)) {
      memberData = null;
    }
    return memberData;
  };

  const converDateToApiFormat = (date) => {
    return date
      ? moment(date, [UIConfig.b2c.profile.dateDisplayFormat, UIConfig.b2c.profile.dateFormat]).format(
          UIConfig.b2c.profile.dateFormat,
        )
      : null;
  };

  const isMemberDataInValid = (memberData) => {
    let isInValid = false;
    for (const key in memberData) {
      if (!memberData[key] && key !== 'mdmId' && key !== 'status') {
        isInValid = true;
        break;
      }
    }
    return isInValid;
  };

  const myInfoFormSubmit = (errors, payLoad) => {
    const { services } = props.data;
    const data = formatPersonalDataForUpdate(payLoad);
    const profileCookie = ProfileServices.UpdateGuestInfo(
      config.current.componentName,
      services.updateGuestProfile.url,
      data,
      true,
      config.current.preLoadTarget,
    )
      .then((response) => {
        const { idToken } = getLoggedInUser();
        if (idToken && !isEmpty(response.data) && resolvePath(services, 'updateProfileCookie.url', '')) {
          ProfileServices.saveUserProfileCookieInCMS(
            config.current.componentName,
            services.updateProfileCookie.url,
            { idToken },
            false,
            config.current.preLoadTarget,
          )
            .then((response) => {
              Logging(
                response,
                response.config && response.config.moduleName ? response.config.moduleName : 'my-profile',
                true,
                'idtoken cookie updated',
              );
            })
            .catch((response) => {
              Logging(response.error, 'update profile cookie', true, 'idtoken cookie update failed');
            });
        }

        Logging(
          response,
          response.config && response.config.moduleName ? response.config.moduleName : 'my-profile',
          true,
          'My profile saved',
        );
        const data = profielInfoDataMapper(response.data);
        prefilldata.current = deepCloneObject(Object.assign({}, prefilldata.current, data));

        formatDataForForms(() => {
          setServerResponse({
            ...serverResponse,
            serverErrors: getErrorMap(props.data.services.updateGuestProfile.url, {}, true),
            submitInfoSuccess: true,
          });
        });
      })
      .catch((response) => {
        setServerResponse({
          ...serverResponse,
          serverErrors: getErrorMap(
            props.data.services.updateGuestProfile.url,
            props.data.services.updateGuestProfile.errors,
            false,
            response.error,
            serverResponse.serverErrors,
          ),
          submitInfoSuccess: false,
        });
      });
  };

  const commPrefFormSubmit = (errors, payLoad) => {
    const data = formatCommPrefData(payLoad),
      apiData = {},
      promiseArr = [];
    if (data.postData.length) {
      apiData.communicationPreferencesIO = data.postData;
      promiseArr.push(
        ProfileServices.CreateCommunicationPref(
          config.current.componentName,
          props.data.services.createGuestCommunicationPref.url,
          apiData,
          true,
          config.current.preLoadTarget,
          props.data.includeLeisure,
        ),
      );
    }
    if (data.putData.length) {
      apiData.communicationPreferencesUO = data.putData;
      promiseArr.push(
        ProfileServices.UpdateCommunicationPref(
          config.current.componentName,
          props.data.services.updateGuestCommunicationPref.url,
          apiData,
          true,
          config.current.preLoadTarget,
          props.data.includeLeisure,
        ),
      );
    }
    Promise.all(promiseArr)
      .then((response) => {
        Logging(
          response[0],
          response[0].config && response[0].config.moduleName ? response[0].config.moduleName : 'my-profile-1',
          true,
          'my profile save 1',
        );
        const dataSet1 = (response[0].data && response[0].data.communicationPreferencesVO) || [];
        let finalDataSet = {};
        if (response.length > 1) {
          Logging(
            response[1],
            response[1].config && response[1].config.moduleName ? response[1].config.moduleName : 'my-profile-2',
            true,
            'my profile save 2',
          );
          const dataSet2 = (response[1].data && response[1].data.communicationPreferencesVO) || [];
          finalDataSet = dataSet1.concat(dataSet2);
        } else {
          finalDataSet = dataSet1;
        }
        prefilldata.current.communicationPreferencesVO = finalDataSet;

        formatDataForForms(() => {
          setServerResponse({
            ...serverResponse,
            serverErrors: getErrorMap(props.data.services.updateGuestCommunicationPref.url, {}, true),
            submitCommSuccess: true,
          });
        });
      })
      .catch((response) => {
        setServerResponse({
          ...serverResponse,
          serverErrors: getErrorMap(
            props.data.services.updateGuestCommunicationPref.url,
            props.data.services.updateGuestCommunicationPref.errors,
            false,
            response.error,
            serverResponse.serverErrors,
          ),
          submitCommSuccess: false,
        });
      });
  };
  const closeOverlay = () => {
    setMembershipResponse({ ...membershipResponse, showMembership: false });
    setLocalStorage('showMyProfileMembership', false);
  };
  const removeExtraRows = (updatedFormData) => {
    const updatedMemberList = [];
    updatedFormData.sections.forEach((section) => {
      if (section.sectionType === UIConfig.profile.sectionType.family) {
        section.fields.forEach((field) => {
          if (!field.notFound) {
            updatedMemberList.push(field);
          }
        });
        section.fields = updatedMemberList;
        totalMemberCount = updatedMemberList.length;
      }
    });
    return updatedFormData;
  };

  const handleOpenCorpDelete = (isOpen) => {
    setOpenCorpDelete(isOpen);
  };

  const isCorpEnabledUser = () => {
    return corpUserStatus.toLowerCase() === UIConfig.profile.CORP_USER_TYPE.ENABLED && corpUserEmail?.length > 0;
  };

  const isCorpDeletedUser = () => {
    return corpUserStatus.toLowerCase() === UIConfig.profile.CORP_USER_TYPE.DELETED;
  };

  const isCorpDisabledUser = () => {
    return corpUserStatus.toLowerCase() === UIConfig.profile.CORP_USER_TYPE.DISABLED;
  };

  const getUserData = () => {
    const loggedUser = getLoggedInUser();
    return loggedUser;
  };

  const handleEmailChange = (e) => {
    const email = e?.target?.value;

    setCorpUserEmail(email);
  };

  const resetServerErrors = () => {
    setServerResponse({
      ...serverResponse,
      serverErrors: null,
      dataLoaded: true,
    });
  };

  const handleEmailSubmit = async (errors, payLoad) => {
    resetServerErrors();
    try {
      setCorpUserEmail(payLoad?.corporateEmail);
      setIsServerError(false);

      // payLoad.leisureFacility = 'FWAD';

      const response = await CorporateEmailServices.submitCorpUserEmail(
        config.current.componentName,
        props.data.services.createcorporateaccount.url,
        payLoad,
        true,
        UIConfig.loader.defaultPreLoaderTarget,
      );

      if (response?.data?.isFormSubmitted) {
        setCorpEmailSuccess(true);
        resetServerErrors();
      } else {
        setIsServerError(true);
      }
    } catch (error) {
      setIsServerError(true);

      setServerResponse({
        ...serverResponse,
        serverErrors: getErrorMap(
          props.data.services.createcorporateaccount.url,
          props.data.services.createcorporateaccount.errors,
          false,
          error.error,
          serverResponse.serverErrors,
        ),
        dataLoaded: true,
      });
    }
  };

  const renderConfirmDelete = (findMembership) => {
    const deletePopUpData =
      findMembership &&
      findMembership.messageOverlay &&
      findMembership.messageOverlay.reduce((previousMessage, message) => {
        previousMessage[message.overlayKey] = {
          title: message.title,
          bodyCopy: message.bodyCopy,
          primaryCTA: message.primaryCTA,
          secondaryCTA: message.secondaryCTA,
        };
        return previousMessage;
      }, {});

    if (deletePopUpData?.removeAccountOverlay) {
      const { title, bodyCopy, primaryCTA, secondaryCTA } = deletePopUpData.removeAccountOverlay;

      return (
        <ConfirmationDialog
          open={openCorpDelete}
          title={title}
          bodyCopy={bodyCopy}
          confirmText={primaryCTA}
          handleConfirm={() => deleteCorporateEmail(corpUserEmail)}
          cancelText={secondaryCTA}
          handleCancel={() => handleOpenCorpDelete(false)}
          parentClass="corp-delete-popup"
        />
      );
    }
  };

  const renderCorpServerError = () => {
    const serverErrors = serverResponse.serverErrors;

    if (!serverErrors) return;
    serverErrors[props.data.services.createcorporateaccount.url].text = replaceByCorpEmailId(
      serverErrors[props.data.services.createcorporateaccount.url].text,
      corpUserEmail,
    );
    serverErrors.text = '';

    return <ErrorSummary data={serverErrors} />;
  };

  const renderCorpSuccessMessage = () => {
    const successData = props.data.successMessage;

    if (isSwad)
      return <DynamicContent tagName="p" innerHtml={successData.bodyCopy?.replace('{corpEmail}', corpUserEmail)} />;

    return (
      <div className="corp-success">
        <h4>{successData.title.bodyCopy}</h4>
        <DynamicContent tagName="p" innerHtml={successData.bodyCopy} />
        <p className="email">{corpUserEmail}</p>
      </div>
    );
  };

  const renderExpiryDateField = (cardDetails) => {
    return (
      <div id="expiryDate" className="form-element date-container">
        <label className="form-label date-title" for="day">
          Expiry Date
        </label>
        <div className="date-text-container">
          <div className="form-element day">
            <input
              className=""
              name="day"
              id="day"
              type="text"
              autocomplete="off"
              tabindex="0"
              aria-label="Expiry Date undefined"
              value={`${cardDetails.expiryDate.substring(0, 2)}`}
            />
          </div>
          <div className="month">
            <input
              name="month"
              className=""
              type="text"
              autocomplete="off"
              tabindex="0"
              value={`${cardDetails.expiryDate.substring(3, 5)}`}
            />
          </div>
          <div className="year">
            <input
              name="year"
              className=""
              type="text"
              autocomplete="off"
              tabindex="0"
              value={`${cardDetails.expiryDate.substring(6, 10)}`}
            />
          </div>
        </div>
      </div>
    );
  };

  const isNotParkTenant = () => {
    return ServiceConfig.getTenantId().toLowerCase() === UIConfig.YIB2C;
  };

  try {
    if (canUseDOM() && !isLoggedInUser() && !isExperienceEditorActive()) {
      if (document.querySelector('.hero-panel')) {
        document.querySelector('.hero-panel').style.display = 'none';
      } else if (document.querySelector('.hero-panel-with-card')) {
        document.querySelector('.hero-panel-with-card').style.display = 'none';
      }

      const tenantName = GLOBAL_VARS.pageDetails.tenantName;
      const lang = document.getElementsByTagName('html')[0].getAttribute('lang') || 'en';
      window.location.href.includes(`/${tenantName}`)
        ? (window.location.href = `/${lang}/${tenantName}`)
        : (window.location.href = `/${lang}`);
      return <></>;
    } else {
      const membershipfromscv =
        Object.keys(membershipResponse.membershipData).length > 0 ? membershipResponse.membershipData.segmentType : '';
      const findMembership =
        props.data.memberDiscounts &&
        props.data.memberDiscounts.length > 0 &&
        props.data.memberDiscounts.find(
          (member) => member.discountType.toLowerCase() === membershipfromscv.toLowerCase(),
        );
      const sessionBasedPopUp =
        (getLocalStorageByKey('showMyProfileMembership') && membershipResponse.showMembership) || false;

      return (
        <div className="my-profile-b2c" ref={oldPreferenceSection}>
          {/* isLoggedInUser() */}
          {isLoggedInUser() && sessionBasedPopUp && findMembership && Object.keys(findMembership).length > 0 && (
            <div className="my-profile-member-container">
              <div className="overlay--close-button" onClick={closeOverlay} onKeyDown={closeOverlay} tabIndex="0">
                <SvgSprite id="icn-close" viewBox="0 0 18 18" />
              </div>
              <div className="member-title">{findMembership.profileDiscountTitle}</div>
              <div className="member-subtitle">{findMembership.profileDiscountSubtitle}</div>
              <DynamicContent
                tagName="div"
                attrs={{ className: 'member-description' }}
                innerHtml={findMembership.profileDicountBodyCopy}
              />
            </div>
          )}
          {!isServerError && <ErrorSummary data={serverResponse.serverErrors} />}
          {serverResponse.dataLoaded && (
            <div className="my-profile-b2c-forms">
              <div className="my-profile-b2c-forms--personal">
                <Form
                  data={personalFormData.current}
                  submitCallback={myInfoFormSubmit}
                  sectionAction={addFamilyMember}
                  isControlled={true}
                  submitSuccess={serverResponse.submitInfoSuccess}
                  resetCallBack={removeExtraRows}
                />
              </div>
              <div className="my-profile-b2c-forms--member">
                <div data-c-name="Form" data-c-render="Universal" className="component c-form my-profile">
                  <div className="w--content c-form-wrapper">
                    <form novalidate="" className="form my-profile undefined undefined">
                      {membershipFormData.current.sections.length ? (
                        <>
                          <section className="fieldset-title">
                            <h3> MEMBERSHIP DETAILS </h3>
                          </section>
                          {membershipFormData.current.sections.map((cardDetails, indx) => {
                            return (
                              <>
                                {cardDetails?.sectionType === UIConfig.profile.sectionType.membership && (
                                  <fieldset className="membership-details disabled" aria-label="field section">
                                    <section className="fieldset-sub-title">{cardDetails.sectionsubTitle}</section>
                                    <legend></legend>
                                    <div className="form-element active undefined input-type-text">
                                      <label className="form-label" for="cardNumber">
                                        Card Number
                                      </label>
                                      <input
                                        className="form-input body-copy-3 null"
                                        name="cardNumber"
                                        type="Text"
                                        tabindex="0"
                                        autocomplete="on"
                                        aria-required="true"
                                        id="cardNumber"
                                        placeholder="Card Number"
                                        maxlength=""
                                        aria-labelledby="error_cardNumber"
                                        aria-invalid="false"
                                        value={cardDetails.cardNumber}
                                      />
                                    </div>
                                    {!isNotParkTenant() ? (
                                      <div id="expiryDate" className="form-element dob-wrapper active null">
                                        <span className="form-element dob-label" for="day">
                                          Expiry Date
                                        </span>
                                        <div className="form-element dob input-type-text dob-day">
                                          <input
                                            className="form-input body-copy-3 dob"
                                            name="day"
                                            id="day"
                                            type="text"
                                            autocomplete="off"
                                            tabindex="0"
                                            aria-label="Expiry Date undefined"
                                            value={`${cardDetails.expiryDate.substring(0, 2)}`}
                                          />
                                        </div>
                                        <div className="form-element dob input-type-text dob-month active">
                                          <input
                                            name="month"
                                            className="form-input body-copy-3 dob"
                                            type="text"
                                            autocomplete="off"
                                            tabindex="0"
                                            value={`${cardDetails.expiryDate.substring(3, 5)}`}
                                          />
                                        </div>
                                        <div className="form-element dob input-type-text dob-year">
                                          <input
                                            name="year"
                                            className="form-input body-copy-3 dob"
                                            type="text"
                                            autocomplete="off"
                                            tabindex="0"
                                            value={`${cardDetails.expiryDate.substring(6, 10)}`}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      renderExpiryDateField(cardDetails)
                                    )}
                                  </fieldset>
                                )}
                                {isCorpEnabledUser() &&
                                  cardDetails?.sectionType === UIConfig.profile.sectionType.corporateMembership && (
                                    <CorporateUserFeild
                                      className="corporate-user-enabled"
                                      sectionSubTitle={cardDetails.sectionSubTitle}
                                    >
                                      <div className={'form-element active input-type-text'}>
                                        <Form
                                          data={corpMembershipFormData.current}
                                          isControlled={true}
                                          resetCallBack={() => handleOpenCorpDelete(true)}
                                        />
                                      </div>
                                    </CorporateUserFeild>
                                  )}
                                {isCorpDisabledUser() &&
                                  cardDetails?.sectionType === UIConfig.profile.sectionType.inactiveCorporateMember && (
                                    <CorporateUserFeild
                                      className="corporate-user-disabled"
                                      sectionSubTitle={cardDetails.sectionSubTitle}
                                    >
                                      <div className={'form-element active input-type-text'}>
                                        <Form
                                          data={corpMembershipFormData.current}
                                          isControlled={true}
                                          resetCallBack={() => handleOpenCorpDelete(true)}
                                        />
                                      </div>
                                    </CorporateUserFeild>
                                  )}
                                {isCorpDeletedUser() &&
                                  cardDetails?.sectionType === UIConfig.profile.sectionType.createCorporateMember && (
                                    <CorporateUserFeild
                                      className="corporate-user-deleted"
                                      sectionSubTitle={cardDetails.sectionSubTitle}
                                    >
                                      {isServerError && renderCorpServerError()}
                                      {isCorpEmailSuccess ? (
                                        renderCorpSuccessMessage()
                                      ) : (
                                        <div className={'input-type-text'}>
                                          <Form
                                            data={createCorpMemFormData.current}
                                            isControlled={true}
                                            submitCallback={handleEmailSubmit}
                                            onChange={handleEmailChange}
                                            submitSuccess={serverResponse.submitInfoSuccess}
                                            uniqueId={UIConfig.profile.sectionType.createCorporateMember}
                                          />
                                        </div>
                                      )}
                                    </CorporateUserFeild>
                                  )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </form>
                  </div>
                </div>
              </div>
              {isOldConsentUser && isNotPark && (
                <div className="my-profile-b2c-forms--comm">
                  <Form
                    data={communicationFormData.current}
                    submitCallback={commPrefFormSubmit}
                    isControlled={true}
                    submitSuccess={serverResponse.submitCommSuccess}
                  />
                </div>
              )}
            </div>
          )}
          {renderConfirmDelete(findMembership)}
        </div>
      );
    }
  } catch (err) {
    return logComponentRenderingError(err, 'MyProfileB2c', props.data.variant);
  }
};

MyProfileB2c.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default MyProfileB2c;
