import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { formFetcher } from './form-fetcher';
import { createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms';
import { componentMapping } from './jss-form-component-mapping';
import classNames from 'classnames';
import {
  resolvePath,
  getLocalStorageByKey,
  scrollToFormErrorOrSuccess,
  getErrorMap,
  getEnvVariablesClient,
  canUseDOM,
  isEmpty,
  checkInlineSignup,
  getLoggedInUser,
  addLoaderOverlay,
  removeLoaderOverlay,
  checkTenant,
  getTenantId,
  checkGA4Tenants,
} from '../../../common/utility';
import {
  contactUsAnalytics,
  getQuoteAnalytics,
  HnFRegistrationAnalytics,
  preregistrationAnalytics,
} from '../../../common/analytics-events';
import userBeforeFirstRender from '../../../hooks/useBeforeFirstRender';
import { logComponentRenderingError } from '../../../common/logger';
import { ErrorSummary } from '../../presentation/base';
import TextWithCTA from '../text-with-cta';
import UIConfig from '../../../common/UIConfig';
import { emailZeroBounce, replaceByCorpEmailId, setCorpEmailId } from './helper';
import { scrollToServerError } from '../payment/utils';
import GTMData from '../b2c-purchase-journey/gtm-data';

const JssForm = ({ fields }) => {
  const defaultFieldFactory = useRef();
  const jssFormData = useRef({});
  const siteCoreApiKey = useRef('');
  const defaultFormValues = useRef({});
  const [formResponse, setFormResponse] = useState({
    serverErrors: null,
    success: false,
    errors: null,
  });

  const defaultEmail = getLoggedInUser()?.email || '';
  const [corpEmail, setCorpEmail] = useState(defaultEmail);
  const [zeroBounceUrl, setZeroBounceUrl] = useState('');

  const formType = resolvePath(fields?.fields?.[1]?.fields?.[1]?.model, 'text', '');
  const isCorpFormType = formType === UIConfig.CORPORATE_ENROLLMENT;

  useEffect(() => {
    let jssFormData = getLocalStorageByKey(UIConfig.jssForms.events.jssFormSettingsData);
    let apiUrl = jssFormData?.data?.services?.NewsletterVerifyEmailSubscriptionAPI || '';
    setZeroBounceUrl(apiUrl);
  }, []);

  const getUserData = () => {
    const loggedUser = getLoggedInUser();
    return loggedUser;
  };

  const setDefaultFormValues = () => {
    if (isCorpFormType) {
      const formTypeDefaultValueMapping = {
        [UIConfig.CORPORATE_ENROLLMENT]: {
          firstName: '',
          lastName: '',
          myPassId: '',
          personalEmail: '',
        },
      };

      const defaultFields = formTypeDefaultValueMapping[formType];
      const loggedInUserData = getUserData();

      if (!isEmpty(defaultFields)) {
        const personalEmail = loggedInUserData?.email || '';

        defaultFields['firstName'] = loggedInUserData?.userName || '';
        defaultFields['lastName'] = loggedInUserData?.lastName || '';
        defaultFields['myPassId'] = loggedInUserData?.yasId;
        defaultFields['personalEmail'] = personalEmail;
        defaultFields['leisureFacility'] = loggedInUserData?.tenantName || loggedInUserData?.leisureFacility;
        defaultFields['countryofresidence'] = loggedInUserData?.country || '';
        defaultFields['mobileNumber'] = loggedInUserData?.mobileNum || '';
        defaultFields['nationality'] = loggedInUserData?.nationalityAlphaCode || '';

        defaultFormValues.current = defaultFields;

        setCorpEmailId(personalEmail);
      }
    }
  };

  const handleCorpEmailChange = (valueFieldName, inputValue) => {
    setCorpEmail(inputValue);
    setCorpEmailId(inputValue);
  };

  const getCorpEmailConfig = useCallback(() => {
    const loggedInUserData = getUserData();

    const isCorpEmailError = formResponse?.success;
    const defaultValue = corpEmail || loggedInUserData?.email;

    return {
      onChange: handleCorpEmailChange,
      isCorpEmailField: isCorpFormType,
      isCorpEmailError,
      defaultValue,
      getInputValue: setCorpEmail,
    };
  }, [formResponse]);

  const setFormFields = useCallback(() => {
    for (let key in componentMapping) {
      let corpFieldProps = {};

      if (componentMapping.hasOwnProperty(key)) {
        const ComponentName = componentMapping[key];

        if (isCorpFormType) {
          corpFieldProps = getCorpEmailConfig();
        }

        defaultFieldFactory.current.setComponent(key, (props) => <ComponentName {...props} {...corpFieldProps} />);
      }
    }
  }, [getCorpEmailConfig]);

  useEffect(() => {
    setFormFields();
  }, [setFormFields]);

  userBeforeFirstRender(() => {
    defaultFieldFactory.current = createDefaultFieldFactory();
    setFormFields();
    const envVariables = canUseDOM() && getEnvVariablesClient();
    siteCoreApiKey.current = resolvePath(envVariables, 'sitecore_api_key', '');
  });

  useEffect(() => {
    jssFormData.current = getLocalStorageByKey(UIConfig.jssForms.events.jssFormSettingsData) || {};

    if (!isCorpFormType) {
      localStorage.removeItem(UIConfig.jssForms.events.jssFormSettingsData);
    }

    checkInlineSignup() && localStorage.removeItem(UIConfig.localStoreKeys.isInlineSignUpForm); //remove isinlinesignup from localstorage to avoid duplicate email service call

    // Set default form values for corporate enrollment
    setDefaultFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getResponseError = (res) => {
    const resData = resolvePath(res, 'data.error.code', '');
    const resCodeData = resolvePath(res, 'data.error.errorcode', '');
    let errors = {};

    if (res.error && (res.error.code || res.error.errorcode)) {
      errors = res.error;
    } else if (resData || resCodeData) {
      errors = res.data.error;
    }

    return errors;
  };

  const getCorporateEmailError = (errData) => {
    const errResponse = { ...errData };
    const corpEmailId = corpEmail || defaultFormValues.current?.personalEmail;
    errResponse.JSSForm.text = replaceByCorpEmailId(errResponse?.JSSForm?.text, corpEmailId);

    return errResponse;
  };

  /* Error Framework Call */
  const handleFormErrors = (res, key) => {
    const captchaErrMsg = resolvePath(res, 'captchaErrMsg', '');
    const isFormError = resolvePath(res, 'isFormError', '');
    const errorsArr = resolvePath(jssFormData, 'current.data.errorData', []);
    let emailInput = document.querySelector("input[type='email']");
    let emailInputValue = emailInput?.value || '';
    let errorData = {};
    errorsArr &&
      errorsArr.forEach((item) => {
        if (item && item.value) {
          errorData[item.value] = item.key?.replace('{email}', emailInputValue);
        }
      });
    if (captchaErrMsg) {
      errorData = {
        [UIConfig.errorCodes.invalidCaptcha]: captchaErrMsg,
      };
    }
    if (isFormError || captchaErrMsg) {
      const errorMsgData = getErrorMap(key, errorData, false, getResponseError(res), formResponse.serverErrors);

      let updatedErrData = {};
      if (isCorpFormType) {
        updatedErrData = getCorporateEmailError(errorMsgData);
      }

      setFormResponse({ ...formResponse, errors: { ...errorMsgData, ...updatedErrData } });
      if (isCorpFormType) {
        scrollToServerError('', 'server-error');
      } else {
        scrollToFormErrorOrSuccess('server-error');
      }
    }
  };

  const handleFormAnalytics = (res, gtmParams) => {
    const formType = resolvePath(jssFormData, 'current.data.formType', '');
    const { getQuote, getHealthNFitnessRegistration, getWrxPreRegistration } = UIConfig.jssForms.formType;
    if (formType === getQuote) {
      getQuoteAnalytics(gtmParams, res, gtmParams);
    } else if (formType === getHealthNFitnessRegistration) {
      HnFRegistrationAnalytics(gtmParams, res, gtmParams);
    } else if (formType === getWrxPreRegistration) {
      preregistrationAnalytics(gtmParams, gtmParams);
    } else {
      contactUsAnalytics(formType, gtmParams);
    }
  };

  const onFormSubmitSuccess = (res, gtmParams) => {
    setFormResponse({ ...res, success: true, errors: null });
    handleFormAnalytics(res, gtmParams);
    const tenant = getTenantId();
    // publish the event of form success
    window.PubSub.publish(UIConfig.events.CORP_EMAIL_SUBMIT, { isSubmitted: true, corpEmailId: '' });
    if ((checkTenant(UIConfig.YIB2C) || tenant === UIConfig.YIB2C) && isCorpFormType) {
      setTimeout(() => {
        scrollToServerError('', 'success-email-container');
      }, 0);
    } else {
      scrollToFormErrorOrSuccess('success-notification');
    }
  };

  const updateEmailData = () => {
    const defaultFormFields = { ...defaultFormValues.current };
    defaultFormFields['corporateEmail'] = corpEmail;

    defaultFormValues.current = { ...defaultFormFields };
  };

  const handleFormSubmission = async (formData, endpoint) => {
    let errEmail = document.getElementsByClassName('error-msg');
    let corpLoaderContainer;

    if (errEmail.length > 0) {
      scrollToFormErrorOrSuccess('error-msg');
      return;
    }

    if (isCorpFormType) {
      updateEmailData();
      corpLoaderContainer = document.querySelector('.corporate-enrollment');
    }

    const { data } = jssFormData.current;
    const { services, variant } = data;

    addLoaderOverlay(corpLoaderContainer);

    const resData = await emailZeroBounce(zeroBounceUrl?.url);
    if (!resData.data) return;

    return new Promise((resolve, reject) => {
      formFetcher({
        formData,
        endpoint,
        formFields: fields,
        services,
        variant,
        siteCoreApiKey: siteCoreApiKey.current,
        defaultFormValues: defaultFormValues.current,
        isCorpFormType,
      })
        .then((res) => {
          if (res && res.onlySiteCoreFormSubmission) {
            if (res.success && isEmpty(res.error)) {
              onFormSubmitSuccess(res, res.gtmParams);
              resolve(res);
            } else {
              handleFormErrors({ ...res, isFormError: true }, 'JSSForm');
              reject(res);
            }
          } else if (res && res.data) {
            const formRes = res.data.data;
            if (formRes.isFormSubmitted || formRes.success) {
              onFormSubmitSuccess(formRes, res.gtmParams);
            } else {
              handleFormErrors({ ...formRes, isFormError: true }, 'JSSForm');
            }
            resolve(res.data);
          }
          if (checkGA4Tenants()) {
            GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
              name: 'Corporate Discount Form',
            });
          }
        })
        .catch((err) => {
          const captchaID = resolvePath(err, 'captchaID', '');
          captchaID && window[captchaID].ReloadImage();
          if (err && !err.isFormValidationFailed) {
            handleFormErrors(err, 'JSSForm');
          }
          reject(err);
        })
        .finally(() => {
          removeLoaderOverlay(isCorpFormType ? corpLoaderContainer : '');
        });
    });
  };

  try {
    const formClassName = resolvePath(fields, 'metadata.cssClass', '');
    const successMessage = resolvePath(jssFormData, 'current.data.successMessage', {});
    const { success, errors } = formResponse;
    const jssFormClasses = classNames('component c-form jss-form', { 'form-submitted': success });
    return (
      <div className={isCorpFormType ? 'corporate-email-container' : ''}>
        <div className="jss-form-wrapper">
          {errors && <ErrorSummary disableScroll data={errors} hideErrorWrapper={true} />}
          {success && !isCorpFormType ? (
            <div className="success-notification">
              <TextWithCTA data={successMessage} />
            </div>
          ) : null}

          <div className={`${jssFormClasses} ${formClassName}`}>
            <div className="w--content field-section c-form-wrapper">
              <Form
                form={fields}
                sitecoreApiHost={''}
                sitecoreApiKey={siteCoreApiKey.current}
                fieldFactory={defaultFieldFactory.current}
                formFetcher={handleFormSubmission}
                onRedirect={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'JSSForm');
  }
};

export default withRouter(JssForm);
