import React, { useState, useEffect, useRef } from 'react';
import { logComponentRenderingError } from '../../../../../common/logger';
import UICONFIG from '../../../../../common/UIConfig';
import {
  getFallbackLanguage,
  canUseDOM,
  createMappedData,
  flipObjectKeyValues,
  addLoaderOverlay,
  redirectTo,
  removeLoaderOverlay,
  toTwoDecimalPlaces,
  momentTimezone,
  getClientUtcOffset,
} from '../../../../../common/utility';
import AddOnsOverlay from '../../add-ons-overlay/add-ons-overlay-component';
import ApiWrapper from '../../../../../common/api-wrapper';
import Accordion from '../../../../presentation/accordian';
import { DynamicContent, SvgSprite } from '../../../../presentation/base';
import { getB2CProductDetails } from '../../../../../common/coveo-api.js';
import ProductStore from '../../../b2c-purchase-journey/purchase-journey-wrapper/product-store';
import PackageQuantityExperienceB2C from './b2c-package-quantity-component';
import DrivingExperienceB2C from '../b2c-driving-experience/b2c-driving-experience-component';
import AddOnsEventComponent from '../add-ons-event';
import moment from 'moment';

const PackageExperienceB2C = (props) => {
  const packageDetails = props.packageDetails;
  const pjData = props.pjData;
  const tabData = pjData.tabs?.length && pjData.tabs[0];
  const pkgTabData = pjData.tabs?.length && pjData.tabs.find((tab) => tab?.type === 'package');
  const packageMapper = pjData.packageMapper || {};
  const [totalQuantity, setTotalQuantity] = useState(packageDetails.minimum);
  const [totalCalculatedAmount, setTotalCalculatedAmount] = useState(0);
  const [stepNumber, setStepNumber] = useState(0);
  const [productDetails, setProductDetails] = useState([]);
  const [openIndexNumber, setOpenIndexNumber] = useState(0);
  const [isProductValid, setIsProductValid] = useState(false);
  const [isTncChecked, setTncChecked] = useState(false);
  const [isInValidPkg, setInValidPkg] = useState([]);
  const [validProd, setValidProd] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const sltdSecProd = useRef([]);

  const B2CProducts = useRef([]);
  const tabs = useRef({});
  const coveoDefaultParams = useRef([]);

  const displaySections = () => {
    if (stepNumber === 0) {
      return renderQuantityStep();
    }

    if (stepNumber === 1) {
      return renderProductDetailsStep();
    }

    if (stepNumber === 2) {
      return renderConfirmationStep();
    }

    return renderQuantityStep();
  };

  useEffect(() => {
    invokeProductDetails();
  }, []);

  const initialOverlayPerformanceCall = (product, dateSelector, currentTabData) => {
    const calendarData = dateSelector.options[0].calendarSettings;
    const calendarValidity = dateSelector.options[0].ticketValidity;
    let endDataBasedOnRange = moment().locale('en');
    if (calendarData) {
      endDataBasedOnRange = endDataBasedOnRange
        .add(parseInt(calendarData.range, 10), 'days')
        .format(UICONFIG.calendar.dateFormat);
    } else if (calendarValidity) {
      endDataBasedOnRange = endDataBasedOnRange.add(calendarValidity, 'months').format(UICONFIG.calendar.dateFormat);
    }
    if (calendarData?.range && calendarData?.endDate) {
      const endDate = momentTimezone(calendarData.endDate, getClientUtcOffset()).format(UICONFIG.calendar.dateFormat);
      product.toDate = momentTimezone(endDataBasedOnRange, getClientUtcOffset()).isSameOrAfter(endDate, 'days')
        ? endDate
        : endDataBasedOnRange;
    } else {
      product.isFlexible = currentTabData.isQuantityInOverlay;
      product.fromDate = moment().format(UICONFIG.calendar.dateFormat);
      product.toDate = endDataBasedOnRange;
    }
    return product;
  };

  const invokeProductDetails = () => {
    const coveoSetting = props.coveoSettings;
    const isPreLoader = true;
    const perPageResults = UICONFIG.b2c.purchaseJourney.coveoResultsPerPage;
    const coveoKeyMap = pjData.coveoMappingList;
    const fieldsToInclude = Object.values(coveoKeyMap);
    const lang = getFallbackLanguage();
    const searchTemplate = 'ProductB2C';
    const preLoaderTarget = UICONFIG.loader.defaultPreLoaderTarget;
    const productCoveoResults = {};
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const productIdsResult = JSON.parse(packageDetails.productDetails);
    const productId = productIdsResult.PackageRule.map((product) => {
      return product.ProductList.toString();
    });
    const isValidPackageProducts = productId.reduce((result, value) => {
      result[value] = false;
      return result;
    }, {});

    if (pjData.tabs && pjData.tabs.length) {
      pjData.tabs.forEach((tab) => (tabs.current[tab.type] = { key: tab.coveoKey, value: tab.coveoValue }));
    }

    coveoDefaultParams.current = props.enablePJPopup
      ? {
          perPageResults: UICONFIG.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.coveoMappingList,
          serviceUrl: pjData.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};
    ApiWrapper.coveoService({
      preLoaderTarget,
      url: coveoSetting.serviceUrl,
      method: 'POST',
      preLoader: isPreLoader,
      data: {
        numberOfResults: perPageResults,
        fieldsToInclude: fieldsToInclude,
        aq: `(@${coveoKeyMap.language}==${lang})(@${coveoKeyMap.templatename}==${searchTemplate})(@${coveoKeyMap.productId}==(${productId}))`,
        searchHub: mainObj.additionalProperty.searchHub,
      },
    }).then((prodsData) => {
      productCoveoResults.results = [];
      productCoveoResults.totalCount = prodsData.data.totalCount;

      prodsData.data.results.forEach((v) => {
        productCoveoResults.results.push(v.raw);
      });

      const productMappedResults =
        productCoveoResults.results && createMappedData(productCoveoResults.results, coveoKeyMap);

      const prds = productMappedResults.map((product) => {
        const currentTabData = pjData.tabs.find((tab) => tab.coveoValue.toLowerCase() === product.tab[0].toLowerCase());
        const prodDetails = currentTabData?.controls?.extras?.options?.find(
          (option) => option.coveoValue.toLowerCase() === product.category?.toString().toLowerCase(),
        );

        props.enablePJPopup &&
          createProductStore(currentTabData.coveoValue && currentTabData.coveoValue.toLowerCase()).then((response) => {
            product.extra.additionalProductList = response;
          });
        const validation = [];
        const { stringMapping, options } = prodDetails.multiTicketSelector;
        options.forEach((opt) => {
          const key = Object.keys(stringMapping).find((key) => stringMapping[key] === opt.identifier);
          validation.push(key);
        });
        product = {
          ...initialOverlayPerformanceCall(product, prodDetails.dateSelector, currentTabData),
          extra: {
            currentTabData: currentTabData,
            ...prodDetails,
            validation,
          },
        };
        return product;
      });

      setProductDetails(prds);
      checkValidation(prds);
      setIsProductValid(isValidPackageProducts);
    });
  };

  const nextStep = () => {
    setStepNumber(stepNumber + 1);
    displaySections();
  };

  const prevStep = () => {
    setStepNumber(stepNumber - 1);
    displaySections();
  };

  const checkAvailablity = (id, available) => {
    const existingProdList = [...productDetails];
    let inValidProduct = [...isInValidPkg];
    const selectedIdx = existingProdList.findIndex((prd) => prd.productId === id);
    existingProdList[selectedIdx].productMaxAvail = available;
    if (available < totalQuantity) {
      inValidProduct.push(id);
    } else {
      if (inValidProduct.includes(id)) {
        inValidProduct = inValidProduct.filter((e) => e !== id);
      }
    }
    setProductDetails(existingProdList);
    setInValidPkg(inValidProduct);
  };

  const packageStateHandler = (newProduct) => {
    const existingProdList = [...productDetails];
    const newUpdatedProd =
      existingProdList?.length &&
      existingProdList.map((prod) => {
        if (prod.productId === newProduct.productId) {
          return { ...prod, ...newProduct };
        }
        return prod;
      });
    // if secondary products needs to be added use below const
    // const idx = newUpdatedProd.findIndex((prd) => prd.productId === newProduct.productId);
    // if (idx === -1) {
    //   const secIdx = sltdSecProd.current.findIndex((secPrd) => secPrd.productId === newProduct.productId);
    //   const parentProd =
    //     newUpdatedProd?.length &&
    //     newUpdatedProd.find((prd) => JSON.stringify(prd.category) === JSON.stringify(newProduct.category));
    //   const secNewPrd = [...sltdSecProd.current];
    //   if (secIdx === -1) {
    //     secNewPrd.push({ ...newProduct, extra: parentProd.extra });
    //     sltdSecProd.current = secNewPrd;
    //   }
    // } else {
    //   const secProds = [...sltdSecProd.current];
    //   const uniqueArray =
    //     secProds?.length &&
    //     secProds.reduce((acc, curr) => {
    //       const matchNode = JSON.stringify(curr.category) === JSON.stringify(newUpdatedProd[idx].category);
    //       if (!matchNode) {
    //         acc.push(curr);
    //       }
    //       return acc;
    //     }, []);
    //   sltdSecProd.current = uniqueArray || [];
    //   newUpdatedProd[idx] = { ...newUpdatedProd[idx], ...newProduct, extra: newUpdatedProd[idx].extra };
    // }

    setProductDetails(newUpdatedProd);
    checkValidation(newUpdatedProd);
  };

  const updateProductValidity = (productId, isValid) => {
    const newIsValidProduct = { ...isProductValid };

    if (newIsValidProduct.hasOwnProperty(productId)) {
      // update the value of the property
      newIsValidProduct[productId] = isValid;
    }
    setIsProductValid(newIsValidProduct);
  };

  const updateSecProdArray = (prod, type, map) => {
    const secProds = {};
    secProds[type] = secProds[type] || {};
    switch (prod.experienceCatgory) {
      case map.secondary:
        secProds[type].secondary = secProds[type].secondary || [];
        secProds[type].secondary.push(prod);
        break;
      case map.addOnGuest:
        secProds.addOnGuest = secProds.addOnGuest || [];
        secProds.addOnGuest.push(prod);
        break;
      case map.primary:
        secProds[type].primary = secProds[type].primary || [];
        secProds[type].primary.push(prod);
        break;
      default:
        break;
    }
    return secProds[type];
  };

  const getCategoryProds = (category, products) => {
    return products.filter((product) => {
      if (!product.category || !category) {
        return false;
      }
      if (product.category.toString() === category.toString()) {
        return product;
      }
      return false;
    });
  };

  const createProductStore = (tab, param) => {
    return new Promise((resolve, reject) => {
      if (!B2CProducts.current[tab]) {
        const params = tabs.current[tab] ? [tabs.current[tab]] : [param];
        params.push({ key: pjData.coveoMappingList.disabledProduct, value: '0' });
        getB2CProductDetails({
          ...coveoDefaultParams.current,
          queryParams: params,
        })
          .then((res) => {
            res.results = props.changeCategoryToString(res.results, coveoDefaultParams);
            B2CProducts.current[tab] = new ProductStore(res.results);
            resolve(B2CProducts.current[tab]);
          })
          .catch((res) => {
            props.errorCallback('getCoveoProducts', res.error);
            const errObj = props.getErrorMap(
              'getCoveoProducts',
              pjData.services.getCoveoProducts.errors,
              false,
              res.error,
            );
            reject(errObj);
          });
      } else {
        resolve(B2CProducts.current[tab]);
      }
    });
  };

  const editProduct = (index) => {
    setStepNumber(1);
    setOpenIndexNumber(index);
  };

  // if secondary products needs to be added use below function
  // const getSecProdSltd = (prod) => {
  //   return (
  //     sltdSecProd.current?.length &&
  //     sltdSecProd.current.find((secPrd) => JSON.stringify(secPrd.category) === JSON.stringify(prod.category))
  //   );
  // };

  const createDetailsAccordionHeader = (product, index) => {
    const { productName, selectedDate, timeSlot, isFlexible, toDate } = product;
    const { validOn, validUntil } = pjData?.miniCart?.cartLabels;
    const date = isFlexible ? moment(toDate) : selectedDate;
    const isValid = validProd.includes(product.productId);
    return (
      <div className="c-order-list-product-header" key={index} onClick={() => getProductDetail(index)}>
        <div className="header-content-wrapper">
          <div className="orderHead">
            <div className="purchaseDate">
              <span className="header-label">
                {packageMapper?.productNameText} #{parseInt(index + 1)}
                {isValid && (
                  <span className="valid-check-icon">
                    <SvgSprite id="white-checkmark-icon" />
                  </span>
                )}
              </span>
              <span className="product-label">{productName}</span>
              <span className="separator"></span>
            </div>
          </div>
          {stepNumber === 2 && (date || timeSlot) && (
            <div className="orderHead">
              <div className="purchaseDate">
                <span className="header-label">{isFlexible ? validUntil : validOn}</span>
                <span className="product-label">{date && `${date.format('MMM DD, YYYY')} ${timeSlot || ''}`}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const componentToAppend = ({
    props,
    product,
    multiTicketSelector,
    isDrivingExp,
    category,
    multiTicketSelectorCoveoMap,
  }) => {
    const currentTabData = product.extra.currentTabData;

    const coveoCategory = { [currentTabData.coveoKey]: currentTabData.coveoValue };
    const additionalProductList = product.extra?.additionalProductList;
    let B2CProduct =
      typeof additionalProductList?.getFilteredProductsFromKeyVal === 'function' &&
      additionalProductList.getFilteredProductsFromKeyVal(coveoCategory);
    let allProducts = B2CProduct?.length && createMappedData(B2CProduct, pjData.coveoMappingList);
    let categoryProducts =
      allProducts?.length && allProducts.filter((prod) => prod.category && prod.category[0] === product.category[0]);
    let isSecProds = {};
    categoryProducts?.length &&
      categoryProducts.forEach((prd) => {
        let isCategoryMappingExist = false;
        const cat = prd.category;

        if (cat && multiTicketSelectorCoveoMap) {
          for (const item in multiTicketSelectorCoveoMap) {
            if (item === cat[0]) {
              isCategoryMappingExist = true;
              break;
            }
          }
        }

        if (
          multiTicketSelectorCoveoMap &&
          (cat.indexOf(multiTicketSelectorCoveoMap.drivingExperience) !== -1 ||
            cat.indexOf(multiTicketSelectorCoveoMap.passengerExperience) !== -1 ||
            isCategoryMappingExist)
        ) {
          const newSecProd = updateSecProdArray(prd, cat[0], multiTicketSelectorCoveoMap);
          isSecProds = { ...isSecProds, ...newSecProd };
        }
      });

    let counterData = product.extra.visitorSelector || {};
    let dateSelector = product.extra.dateSelector || {};
    let timeSlotSelector = product.extra.timeSlotSelector || {};
    let productOverlaySelector = product.extra.productOverlaySelector || {};

    const { addToCartCallback, getDiscountedProducts, overlayErrorCallback, variant, enablePJPopup } = props;

    let Component = AddOnsOverlay;
    if (currentTabData.isEventQuantityOverlay) {
      Component = AddOnsEventComponent;
    } else if (isDrivingExp) {
      Component = DrivingExperienceB2C;
    }

    return (
      <Component
        data={currentTabData}
        product={product}
        inValidQty={isInValidPkg.includes(product.productId)}
        counterData={counterData}
        calenderData={dateSelector}
        addToCartCallback={addToCartCallback}
        services={pjData.services}
        multiTicketSelector={multiTicketSelector}
        productOverlaySelector={productOverlaySelector}
        showProductNameInOverlay={product.extra.showProductNameInOverlay}
        getDiscountedProducts={getDiscountedProducts}
        timeSlotData={timeSlotSelector}
        cartData={pjData.miniCart}
        businessErrors={pjData.miniCart.businessErrors}
        additionalProds={getCategoryProds(product.category, allProducts)}
        coveoMapList={flipObjectKeyValues(pjData.coveoMappingList)}
        searchProductList={allProducts}
        packageStateHandler={packageStateHandler}
        updateProductValidity={updateProductValidity}
        enablePJPopup={enablePJPopup}
        variant={
          (variant === 'v-product-list-filter' || variant === 'v-f1-multi-filter') && enablePJPopup
            ? 'v-overlay-selected'
            : ''
        }
        overlayErrorCallback={overlayErrorCallback}
        enableDynamicCalendar={product?.enableDynamicCalendar}
        checkAvailablity={checkAvailablity}
        isPackageJourney
      />
    );
  };

  const createDetailsAccordionDetail = (
    product,
    index,
    multiTicketSelector,
    isDrivingExp,
    category,
    multiTicketSelectorCoveoMap,
  ) => {
    const currentTabData = product.extra.currentTabData;

    let counterData = product.extra.visitorSelector || {};

    const nonDatedProduct = currentTabData.isQuantityInOverlay;

    if (!product.hasOwnProperty('quantity') && counterData.hasOwnProperty('options')) {
      product.quantity = counterData?.options[0]?.defaultQuantity || 0;
    }

    // Reset Secondary products
    // Object.keys(secProds).forEach(function(key) {
    //   delete secProds[key];
    // });

    return (
      <div>
        <DynamicContent tagName="div" attrs={{ className: 'acc-sub-title' }} innerHtml={product.extra.description} />
        {!nonDatedProduct &&
          (!multiTicketSelector ||
            (product.experienceCatgory === multiTicketSelectorCoveoMap.primary &&
              componentToAppend({
                props,
                product,
                multiTicketSelector,
                isDrivingExp,
                category,
                multiTicketSelectorCoveoMap,
              })))}
      </div>
    );
  };

  const createConfirmationAccordionDetail = (product, index) => {
    const currentTabData = product.extra.currentTabData;
    const nonDatedProduct = currentTabData.isQuantityInOverlay;

    if (nonDatedProduct) {
      return (
        <div>
          <DynamicContent tagName="div" attrs={{ className: 'acc-sub-title' }} innerHtml={product.extra.description} />
        </div>
      );
    }

    return (
      <div className="c-order-list-product-header">
        <div className="header-content-wrapper">
          <div className="orderHead">
            <div className="purchaseDate">
              <span className="header-label">{product?.license && packageMapper?.drivingLicenseText}</span>
              <span className="product-label">{product?.license && product.license}</span>
              <span className="separator"></span>
            </div>
          </div>
          <div className="orderHead">
            <div className="btn-add-to-cart">
              <div className={'btn-primary'}>
                <DynamicContent
                  tagName="button"
                  innerHtml={packageMapper?.editText}
                  attrs={{
                    onClick: () => editProduct(index),
                    className: 'add-to-cart',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const redirectToBookingPageCallback = (checkoutContext) => {
    const { data, showMinicartCouponCode } = props;
    addLoaderOverlay();
    if (
      data.variant === 'v-multi-product-widget' ||
      (data.variant === UICONFIG.dyanmicPricingVariant && checkoutContext.href)
    ) {
      redirectTo(checkoutContext.href);
    } else if (!showMinicartCouponCode && !(checkoutContext && checkoutContext.href)) {
      window.location.href = tabData?.addToCartCTA?.href;
    }
    if (!checkoutContext.href) {
      removeLoaderOverlay();
    }
  };

  const getPkgCode = (data) => {
    // if secondary products needs to be added use below const
    // const stlcPkg = getSecProdSltd(data);
    const { productId, performanceId, timeSlot } = data;
    let pkgCode = productId;
    if (performanceId) {
      pkgCode = `${pkgCode}-${performanceId}`;
    }
    if (performanceId) {
      pkgCode = `${pkgCode}-${timeSlot}`;
    }
    return pkgCode;
  };

  const addToCartPackage = (checkoutContext) => {
    const { closeOverlay, addToCartCallback } = props;
    //const packageIdType = getPackageIdType(productDetails, packageDetails.packageCode);
    const packageUniqueCode = productDetails.map((data) => getPkgCode(data));
    const newProductDetails = [];
    productDetails.forEach((prd) => {
      // if secondary products needs to be added use below const
      // const secProd = getSecProdSltd(prd);
      const product = prd;
      const { minimum, maximum, packageCode } = packageDetails;
      delete product.extra;
      newProductDetails.push({
        ...product,
        quantity: 1,
        packageQty: totalQuantity,
        packageMin: minimum,
        packageMax: maximum,
        packageCode: packageCode,
        packageTitle: packageDetails?.pkgTitle,
        packageGroupCode: `${packageCode}-${packageUniqueCode}`,
        minCount: minimum || 0,
        maxCount: maximum || 0,
      });
    });

    // setProductDetails(newProductDetails);
    closeOverlay && closeOverlay();

    addToCartCallback && addToCartCallback(newProductDetails, true, checkoutContext, redirectToBookingPageCallback);
  };

  const getProductDetail = (productIndex) => {
    let allProd = productDetails.map((prd, i) => {
      if (productIndex === i) {
        return { ...prd, isOpen: true };
      }
      return prd;
    });

    setTimeout(() => {
      const elem = document.getElementById(`accordion_header_${productIndex}`);
      elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 2000);

    setProductDetails(allProd);
  };

  const renderQuantityStep = () => {
    const overLayWrapper = document.querySelector('.package-journey-overlay .overlay-wrapper');
    if (overLayWrapper) {
      overLayWrapper.classList.add('step-one');
    }
    return (
      <>
        <div className="product-name-title">
          <div className="package-header">
            <DynamicContent tagName="h4" attrs={{ className: 'heading-4' }} innerHtml={packageDetails.pkgTitle} />
          </div>
        </div>
        <PackageQuantityExperienceB2C
          data={packageDetails}
          packageName={packageDetails.packageName}
          nextStepFunction={nextStep}
          handleQuantity={setTotalQuantity}
          handleCalculatedAmount={setTotalCalculatedAmount}
          totalCalculatedAmount={totalCalculatedAmount}
          totalQuantity={totalQuantity}
          currency={tabData?.currency}
          maxErrorText={pkgTabData?.controls?.extras?.options?.[0]?.visitorSelector?.maxVistorCountError}
        />
        <div className="btn-add-to-cart">
          <div className={'btn-primary'}>
            <DynamicContent
              tagName="button"
              innerHtml={packageMapper?.continueAddDetailsText}
              attrs={{
                onClick: nextStep,
                className: 'add-to-cart',
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const changePage = () => {
    const eleToScroll = document.querySelector('.package-journey-component');
    eleToScroll && eleToScroll.scrollIntoView({ behavior: 'smooth' });
    nextStep();
  };

  const renderProductDetailsStep = () => {
    const overLayWrapper = document.querySelector('.package-journey-overlay .overlay-wrapper');
    if (overLayWrapper) {
      overLayWrapper.classList.remove('step-one');
    }
    const accordianListItems = [];
    productDetails.forEach((prodAccordion, index) => {
      const multiTicketSelector = prodAccordion.extra.multiTicketSelector;
      const multiTicketSelectorCoveoMap = multiTicketSelector ? multiTicketSelector.stringMapping : {};
      const category = prodAccordion.category;

      const isDrivingExp =
        multiTicketSelectorCoveoMap &&
        (category.indexOf(multiTicketSelectorCoveoMap.drivingExperience) !== -1 ||
          category.indexOf(multiTicketSelectorCoveoMap.passengerExperience) !== -1);
      if (prodAccordion.experienceCatgory !== multiTicketSelectorCoveoMap.primary) {
        return;
      }
      accordianListItems.push({
        summaryComponent: createDetailsAccordionHeader(prodAccordion, index),
        detailComponent: createDetailsAccordionDetail(
          prodAccordion,
          index,
          multiTicketSelector,
          isDrivingExp,
          category,
          multiTicketSelectorCoveoMap,
        ),
      });
    });

    return (
      <>
        <div className="product-name-title">
          <div className="package-header">
            <DynamicContent tagName="h4" attrs={{ className: 'heading-4' }} innerHtml={packageDetails.pkgTitle} />
          </div>
          <div className="product-quantity-display">
            <span className="heading-6">
              {packageMapper?.qtyText}: {totalQuantity < 10 ? `0${totalQuantity}` : totalQuantity}
            </span>
            <span className="heading-6">
              {tabData?.currency} {toTwoDecimalPlaces(totalCalculatedAmount)}
            </span>
          </div>
        </div>
        <div className="package-accordion-selector">
          <Accordion
            accordianItems={accordianListItems}
            isMultiExpandable={false}
            isRTE={false}
            openDefaultIndex={openIndexNumber}
          />
        </div>
        <div className="btn-add-to-cart">
          <div className={'btn-primary'}>
            <DynamicContent
              tagName="button"
              innerHtml={packageMapper?.proceedToConfirm}
              attrs={{
                onClick: changePage,
                disabled: isButtonDisabled,
                className: 'add-to-cart',
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderTotalAmount = () => {
    return (
      <div className="ticket-total-amount-wrapper">
        <div className={`total-amount ${totalCalculatedAmount === '' ? 'disabled' : ''}`}>
          <h3>{tabData?.total}</h3>
          <div className="amount">
            <h3>
              {tabData?.currency} {toTwoDecimalPlaces(totalCalculatedAmount)}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  const renderAmountwithButtons = () => {
    const { multiTicketSelector } = productDetails?.[0]?.extra;
    return (
      <>
        {renderTotalAmount()}
        <div className={`t-n-c ${multiTicketSelector.hideTNC ? 't-n-c-hide' : ''}`}>
          <label htmlFor="tAndCCheckbox" className="checkbox-label">
            <input
              type="checkbox"
              checked={isTncChecked}
              onClick={() => setTncChecked(!isTncChecked)}
              className="hide"
              id="tAndCCheckbox"
            />
            <div className="checkbox"></div>
            <DynamicContent
              tagName="div"
              attrs={{ className: 't-n-c--label' }}
              innerHtml={multiTicketSelector && multiTicketSelector.tnc}
            />
          </label>
        </div>
      </>
    );
  };

  const renderConfirmationStep = () => {
    const accordianListItems = [];
    productDetails.forEach((prodAccordion, index) => {
      // if secondary products needs to be added use below const
      // const secProd = getSecProdSltd(prodAccordion);
      accordianListItems.push({
        summaryComponent: createDetailsAccordionHeader(prodAccordion, index),
        detailComponent: createConfirmationAccordionDetail(prodAccordion, index),
      });
    });
    return (
      <>
        <div className="product-name-title">
          <div className="package-header">
            <DynamicContent tagName="h4" attrs={{ className: 'heading-4' }} innerHtml={packageDetails.pkgTitle} />
          </div>
        </div>
        <div className="package-accordion-selector confirmation-accordian">
          <Accordion
            accordianItems={accordianListItems}
            isMultiExpandable={false}
            isRTE={false}
            getOrderDetailFn={(i) => getProductDetail(i)}
          />
        </div>
        <div className="package-total-quantity">
          <h4>{packageMapper?.packageQtyText}</h4>
          <h4>
            {totalQuantity} {packageMapper?.qtyText}
          </h4>
        </div>
        {renderAmountwithButtons()}
        <div className="btn-add-to-cart">
          <div className={'btn-primary'}>
            <DynamicContent
              tagName="button"
              innerHtml={tabData?.addToCartCTA?.label}
              attrs={{
                onClick: addToCartPackage,
                disabled: !isTncChecked,
                className: 'add-to-cart',
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const checkValidation = (prod) => {
    const allProds = prod || productDetails || [];
    const inValidProd = [];
    const isValidProd = [...validProd];
    allProds.forEach((prd) => {
      // if secondary products needs to be added use below const
      // const secProd = getSecProdSltd(prd);
      const currProd = prd;
      const invalid =
        currProd.extra?.validation?.length &&
        currProd.extra.validation.some((val) => val !== 'secondaryProd' && !currProd?.[val]);
      if (invalid) {
        inValidProd.push(prd);
        if (isValidProd.includes(prd.productId)) {
          var index = isValidProd.indexOf(prd.productId);
          if (index > -1) {
            isValidProd.splice(index, 1);
          }
        }
      } else {
        if (!isValidProd.includes(prd.productId)) {
          isValidProd.push(prd.productId);
        }
      }
    });
    setValidProd(isValidProd);
    if (inValidProd.length || isInValidPkg.length) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  try {
    return (
      <div className="c-add-ons-overlay package-journey-component calendar-inside-overlay event-quantity-overlay">
        {stepNumber !== 0 && (
          <div className="package-back-button" onClick={prevStep}>
            <span className="arrow" /> <span className="heading-6">{packageMapper?.backQty}</span>
          </div>
        )}
        {displaySections()}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'PackageExperienceB2C');
  }
};

export default PackageExperienceB2C;
