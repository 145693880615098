import React, { Component } from 'react';
import Button from '../../../../presentation/base/button/button-component.js';
import RichText from '../../../../presentation/base/rich-text/rich-text-component';
import Image from '../../../../presentation/base/image/image-component';
import Input from '../../../../presentation/base/input/input-component';
import PaymentCheckboxes from '../../base/payment-checkbox/payment-checkbox';
import ADCBTouchPointsOTP from '../adcb-touchpoints-otp';
import ADCBTouchPointPopUp from '../adcb-touchpoints-popup';
import classNames from 'classnames';
import {
  guestFormError,
  shouldProceedToPay,
  cartCall,
  validateInputFieldNum,
  getOnlyGuestFormError,
} from '../../../../../common/payment-action/actions';
import UIConfig from '../../../../../common/UIConfig';
import {
  setLocalStorage,
  canUseDOM,
  displayDigitsWithCommaFormat,
  toTwoDecimalPlaces,
  isAnnualPassCase,
  isLoggedInUser,
  getLocalStorageByKey,
  getRecipientDetails,
  mycartError,
  checkTenant,
  checkPaymentType,
  checkParksTenants,
} from '../../../../../common/utility';
import { Logging, logComponentRenderingError } from '../../../../../common/logger';

class ADCBTouchPointsStep2 extends Component {
  constructor(props) {
    super(props);
    this.propsData = this.props.propsData;
    this.payData = this.props.payData;
    this.anonymousUser = !isLoggedInUser();
    const {
      paymentOptions: {
        adcbTouchPointspayconfig: { minTransactionValue },
      },
      services: {
        getTouchPointEnquiry: { errors },
      },
    } = this.props.propsData;
    this.minimumRedeemAmount = (minTransactionValue && this.getParseFloatValue(minTransactionValue)) || 50.0;
    this.touchPointsErrors = {
      ...errors,
    };
    this.touchPointBalanceDetail = this.props.touchPointBalanceDetails || null;
    this.isRemainingEqualCartAmount = true;
    this.cartData = this.props.cartData;

    this.state = {
      focus: false,
      enteredAmount: '',
      guestHasError: false,
      onlyHasGuestFormError: false,
      cartAmount: this.getCartAmount(),
      remainingAmount: this.getCartAmount(),
      errorCode: null,
      payBtnADCB: 'active-btn payment-submit-btn btn-primary ',
    };
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
  }

  getCartAmount = () => {
    let cartAmount = null;
    if (this.cartData && this.cartData.grossPrice) {
      cartAmount = this.getParseFloatValue(this.cartData.grossPrice);
    }
    return cartAmount;
  };

  getParseFloatValue = (num) => {
    return parseFloat(parseFloat(num).toFixed(2));
  };

  componentDidMount() {
    if (this.anonymousUser) {
      window.PubSub.subscribe('guestFormHasError', this.checkGuestFormError);
    }
    window.PubSub.subscribe('recipientdetailsHasError', this.checkGuestFormError);
    const firstFocusEle = document.querySelector('.edit-payment-option button');
    firstFocusEle && firstFocusEle.focus();
    this.handleInputField();
  }

  setStoragePurchaseInfo = (cartGrossPrice = 0, redeemAmount = 0) => {
    const cardAmount = this.state.remainingAmount,
      { touchPointBalanceDetails } = this.props,
      touchPointsPI = {
        paymentType:
          cardAmount > 0
            ? UIConfig.b2c.purchaseJourney.touchPoint.paymentType.partial
            : UIConfig.b2c.purchaseJourney.touchPoint.paymentType.full,
        redeemAmount: redeemAmount,
        cardAmount: cardAmount,
        cardNum: touchPointBalanceDetails.cardNumber,
        expiry: touchPointBalanceDetails.expiryDate,
      };

    setLocalStorage(UIConfig.b2c.purchaseJourney.touchPoint.purchaseInfo, JSON.stringify(touchPointsPI));
  };

  /**
   *  non loggedin case
   *  check guest form error and update the state of guest form
   */

  checkGuestFormError = (msg, data) => {
    if (
      canUseDOM() &&
      data.isError &&
      document.querySelectorAll('.adcb-detail-box .error-msg').length === document.querySelectorAll('.error-msg').length
    ) {
      data.isError = false;
    }
    const stateObj = {};

    if (Object.keys(data).length > 0 && data.hasOwnProperty('isRecipientError')) {
      stateObj.guestHasError = data.isRecipientError;
    } else if (this.anonymousUser) {
      const isErrorExist = guestFormError(data, this.props.isEnabled);
      const onlyHasGuestFormError = getOnlyGuestFormError(data);
      if (this.state.guestHasError !== isErrorExist) {
        stateObj.guestHasError = isErrorExist;
      }
      if (this.state.onlyHasGuestFormError !== onlyHasGuestFormError) {
        stateObj.onlyHasGuestFormError = onlyHasGuestFormError;
      }
    }

    Object.keys(stateObj).length &&
      this.setState({
        ...stateObj,
      });
  };

  getErrorMessage = (isOnBlur, enteredValue, cartAmount) => {
    let errorCode = null;
    const { touchPointBalanceDetails } = this.props;
    const equivalentAmount = this.getParseFloatValue(touchPointBalanceDetails.equivalentAmount);
    if (enteredValue > equivalentAmount) {
      errorCode = 'TP0002';
    } else if (enteredValue > cartAmount) {
      errorCode = 'TP0003';
    }
    return errorCode;
  };

  getRemainingAmount = (value, cartAmount) => {
    const { touchPointBalanceDetails } = this.props;
    const equivalentAmount = this.getParseFloatValue(touchPointBalanceDetails.equivalentAmount);
    let calAmount = cartAmount;
    this.isRemainingEqualCartAmount = true;

    if (value && !isNaN(value)) {
      const enteredValue = this.getParseFloatValue(value);
      if (enteredValue <= equivalentAmount && enteredValue <= calAmount && enteredValue >= this.minimumRedeemAmount) {
        calAmount = calAmount - enteredValue;
        this.isRemainingEqualCartAmount = false;
      }
    }
    return calAmount;
  };

  onFieldBlur = (e) => {
    if (!e.target.readOnly) {
      let amount = (e.target.value && !isNaN(e.target.value) && parseFloat(e.target.value)) || '';

      const remainingAmount = this.getRemainingAmount(amount, this.state.cartAmount),
        errorCode = this.getErrorMessage(true, this.getParseFloatValue(amount), this.state.cartAmount);

      this.setState({
        focus: false,
        enteredAmount: (amount && this.getParseFloatValue(amount)) || '',
        errorCode,
        remainingAmount,
      });
    }
  };

  setCustomAmount = (e) => {
    let enteredAmount = '';
    if (e.target.value > 0) {
      enteredAmount = e.target.value;
    }
    this.setState({
      enteredAmount,
    });
  };

  onFieldFocus = (e) => {
    this.setState({ focus: true });
  };

  openOverlay = () => {
    const { touchPointBalanceDetails } = this.props;
    const resendParams = {
      cardNumber: touchPointBalanceDetails.cardNumber,
      expiryDate: touchPointBalanceDetails.expiryDate,
      redeemAmount: this.state.enteredAmount || 0,
    };
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      dataToAppend: (
        <ADCBTouchPointsOTP propsData={this.propsData} resendParams={resendParams} cartData={this.cartData} />
      ),
      customClass: 'ride-info-overlay otp-overlay',
    });
  };

  /**
   *  blocks payment if conditions not met
   *  conditions : guest form not filled, t&c not checked
   */

  canProceedToPay = () => {
    return shouldProceedToPay({
      checkboxEnabled: this.props.isEnabled,
      tncErrorFunc: this.props.showTnCError,
      guestFormErrorFunc: this.props.guestUserHasErrors,
      guestErrorOnChangeState: this.state.guestHasError,
      submitGuestForm: this.state.remainingAmount <= 0,
    });
  };

  getLastFourDigit = () => {
    const { displayCardValue } = this.props;
    let lastFourDigit = '';
    if (displayCardValue) {
      const len = displayCardValue.length;
      lastFourDigit = displayCardValue.slice(len - 4, len);
    }
    return lastFourDigit;
  };

  preventSubmitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  checkIfPaymentContinue = (ifpaymentContinue) => {
    if (ifpaymentContinue) {
      this.submitDataADCBTouchPoint();
    }
  };

  checkEnteredTouchPoints = () => {
    const { equivalentAmount } = this.props.touchPointBalanceDetails;
    const { enteredAmount } = this.state;
    const { isEnabled } = this.props;
    let touchPointsToRedeem = this.getParseFloatValue(enteredAmount);
    touchPointsToRedeem = isNaN(touchPointsToRedeem) ? 0.0 : touchPointsToRedeem;
    const { adcbPopupMessage } = this.props.propsData.paymentOptions.paymentLabels;
    const localStoragePopUpVal = localStorage.getItem('adcbpopupvisibility');
    const ifShowPopUp = localStoragePopUpVal ? JSON.parse(localStoragePopUpVal) : true;
    const showPopup = Boolean(
      ifShowPopUp &&
        equivalentAmount > this.minimumRedeemAmount &&
        adcbPopupMessage &&
        !touchPointsToRedeem &&
        isEnabled,
    );
    if (showPopup) {
      const { adcbPopupSubmit, adcbPopupCancel } = this.props.propsData.paymentOptions.paymentLabels;
      setLocalStorage('adcbpopupvisibility', false);
      try {
        window.PubSub.publish('toggleOverlayState', {
          shouldOpen: true,
          dataToAppend: (
            <ADCBTouchPointPopUp
              popUpMessage={adcbPopupMessage}
              acceptButtonText={adcbPopupSubmit}
              discardButtonText={adcbPopupCancel}
              checkPayment={this.checkIfPaymentContinue}
            />
          ),
          customClass: `purchase-overlay cart-delete-overlay v-b2c-overlay ${
            this.isSwad ? 'adcb-touchPoint-popup' : null
          }`,
        });
      } catch (err) {
        return logComponentRenderingError(err, 'ADCBTouchPointsPopUp');
      }
    } else {
      this.submitDataADCBTouchPoint();
    }
  };

  submitDataADCBTouchPoint = () => {
    window.PubSub.unsubscribe('CreateOrderSuccess');
    const { errorCode, remainingAmount, cartAmount, enteredAmount } = this.state;

    if (this.anonymousUser) {
      window.PubSub.subscribe('guestFormHasError', this.checkGuestFormError);
    }

    if (errorCode) {
      const cardNumberInput = document.getElementById('redeemAmount');
      cardNumberInput && cardNumberInput.focus();
      if (this.props.isEnabled) {
        return false;
      }
    }

    if (remainingAmount < cartAmount) {
      this.setStoragePurchaseInfo(cartAmount, enteredAmount);
    }

    if (!this.anonymousUser && this.props.isEnabled) {
      const updateCartURL = this.propsData.services.updateCart.url;
      const isCallReqForFullADCBCard = Boolean(
        (remainingAmount === cartAmount && !enteredAmount && this.cartData && this.cartData.touchpointPayOption) ||
          this.props.isRenewAnnualPass,
      );
      if (this.props.annualPassCartData && isAnnualPassCase({ cart: this.props.annualPassCartData }, true)) {
        this.cartData = this.props.annualPassCartData;
      }
      if (remainingAmount < cartAmount || isCallReqForFullADCBCard) {
        /* Set recipient details */
        const recipientDetails = getRecipientDetails();
        this.cartData.personalisedGiftObj = recipientDetails;

        // for Partial and Full ADCBTouchPoint Payment
        cartCall(
          updateCartURL,
          { cart: this.cartData },
          this.props.isRenewAnnualPass,
          UIConfig.paymentOption.adcbTouchPoints,
        )
          .then((res) => {
            if (remainingAmount <= 0) {
              this.handleCreateOrderSuccess();
              this.props.setRedirectPaymentData();
            } else {
              this.props.payButtonCallback();
            }
          })
          .catch((res) => {
            this.props.isErrorOccured('updateCart', res.error);
          });
      } else {
        // for full payment using PayFort
        this.props.payButtonCallback();
      }
    } else if (!this.anonymousUser && !this.canProceedToPay()) {
      return false;
    }

    if (this.anonymousUser) {
      if (remainingAmount <= 0) {
        if (!this.canProceedToPay()) {
          return false;
        }
        this.handleCreateOrderSuccess();
      } else {
        if ((!this.state.onlyHasGuestFormError || !this.anonymousUser) && !this.props.isEnabled) {
          this.canProceedToPay();
          return false;
        } else {
          this.props.payButtonCallback();
        }
      }
    }
  };

  handleCloseRedirection = () => {
    const { paymentURL, paymentLabels } = this.propsData.paymentOptions;
    let url = paymentURL.url;
    if (paymentURL.url) {
      url = paymentURL.url.split('#')[0];
    }
    window.top.location.href = `${url}?payment_error=${paymentLabels.otpCancelErrorMsg}#${UIConfig.paymentOption.adcbTouchPoints}`;
  };

  handleCreateOrderSuccess = () => {
    window.PubSub.subscribe('CreateOrderSuccess', (msg, data) => {
      let cmsData = getLocalStorageByKey(UIConfig.localStoreKeys.payment.cmsData);
      const { touchPointBalanceDetails } = this.props;
      if (data && data.isCreateOrderSuccess) {
        cmsData.orderData = {
          resCode: data.resCode,
          orderId: data.orderId,
          redemptionRequest: {
            requestId: data.touchpointResponse.requestId,
            tpRedemptionFlag: data.touchpointResponse.paymentType,
            merchantId: data.touchpointResponse.merchantId,
            otpReferenceNbr: data.touchpointResponse.otpReferenceNumber,
            cardAmount: data.touchpointResponse.cardAmount,
            redeemAmount: data.touchpointResponse.redeemAmount,
            cardNumber: touchPointBalanceDetails.cardNumber,
            expiryDate: touchPointBalanceDetails.expiryDate,
          },
        };

        cmsData.cmsData = {
          ...cmsData.cmsData,
          marketType: this.payData.marketType,
          thankYouPageUrl: this.payData.thankYouPageUrl,
          componentName: this.payData.componentName,
          notEncrypted: this.payData.notEncrypted,
        };
        setLocalStorage(UIConfig.localStoreKeys.payment.cmsData, JSON.stringify(cmsData));
        if (data.touchpointResponse) {
          if (data.touchpointResponse.paymentType === UIConfig.b2c.purchaseJourney.touchPoint.paymentType.full) {
            window.PubSub.unsubscribe('closeOverlay');
            Logging(data, UIConfig.paymentOption.adcbTouchPoints, true, 'ADCB OTP screen success');
            window.PubSub.subscribe('closeOverlay', () => {
              this.handleCloseRedirection();
            });
            this.openOverlay();
          }
        } else {
          Logging(data, UIConfig.paymentOption.adcbTouchPoints, true, 'ADCB OTP screen error');
        }
      } else {
        mycartError();
      }
    });
  };

  handleInputField = () => {
    const cardNumberInput = document.getElementById('redeemAmount');
    if (cardNumberInput) {
      cardNumberInput.addEventListener('keypress', (e) => {
        return validateInputFieldNum(e, true);
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    let stateObj = {};
    if (this.cartData && nextProps.cartData && this.cartData.grossPrice !== nextProps.cartData.grossPrice) {
      this.cartData = nextProps.cartData;
      stateObj.cartAmount = this.getParseFloatValue(nextProps.cartData.grossPrice);
      stateObj.remainingAmount = this.getRemainingAmount(this.state.enteredAmount, stateObj.cartAmount);
      const errorCode =
        (this.state.enteredAmount > 0 && this.getErrorMessage(true, this.state.enteredAmount, stateObj.cartAmount)) ||
        null;
      if (this.state.errorCode !== errorCode && this.state.enteredAmount > 0) {
        stateObj.errorCode = errorCode;
      }
    }
    Object.keys(stateObj).length && this.setState({ ...stateObj });
  }

  render() {
    const { paymentLabels, vatLabel, cardDetailImage } = this.propsData.paymentOptions;
    const {
      showCheckBox,
      onTncChangeHandler,
      hideNewsletterOption,
      disableBookButton,
      hasTnCError,
      tabIndex,
      isEnabled,
      touchPointBalanceDetails,
      propsData,
      onNewsLetterChangeHandler,
      newsLetterEnabled,
    } = this.props;
    const { remainingAmount, errorCode, focus, enteredAmount, guestHasError } = this.state;
    const isBtnEnabledDark =
      (!this.anonymousUser || (this.anonymousUser && !guestHasError)) &&
      isEnabled &&
      !errorCode &&
      !document.querySelectorAll('.yasid-email-error').length > 0;
    const payBtnADCBClasses = classNames('active-btn payment-submit-btn btn-primary', {
      'button-enabled-dark': isBtnEnabledDark,
      'button-enabled-light': !isBtnEnabledDark,
    });
    let buttonData = {
      type: 'submit',
      label:
        (checkParksTenants() &&
          checkPaymentType(propsData?.paymentOptions?.paymentTypes, UIConfig.paymentOption.adcbTouchPoints)) ||
        this.payData.primaryCTA.label,
      class: payBtnADCBClasses,
    };

    let editButtonData = {
      type: 'submit',
      label: paymentLabels.editCTALabel,
      class: 'edit-payment-option',
    };

    const availablePoints = `(${displayDigitsWithCommaFormat(
      parseInt(touchPointBalanceDetails.totalTouchPoints, 10),
    )} ${paymentLabels.touchpointLabel})`;

    let touchPointInputData = {
      class: errorCode ? 'error' : '',
      val: enteredAmount || '',
      label: paymentLabels.redeemAmountPlaceholder,
      type: 'Number',
      name: 'redeemAmount',
      id: 'redeemAmount',
      active: enteredAmount || focus ? 'active' : '',
      showErrors: errorCode ? true : false,
      message: this.touchPointsErrors[errorCode],
      readonly: false,
      inputMode: 'decimal',
    };

    let amountLabel = paymentLabels.totalAmountLabel;
    if (!this.isRemainingEqualCartAmount) {
      amountLabel = paymentLabels.remainingAmountLabel;
    }

    let equivalentAmount = '';
    if (touchPointBalanceDetails.equivalentAmount) {
      const eqAmountParse =
        touchPointBalanceDetails.equivalentAmount && parseFloat(touchPointBalanceDetails.equivalentAmount);
      equivalentAmount = eqAmountParse && toTwoDecimalPlaces(eqAmountParse);
    }

    try {
      return (
        <div className="adcb-payment-step2-wrapper">
          <div className="card-info-wrapper">
            <p className="card-info">
              <span className="masked-card-number">****</span>
              <span className="masked-card-number">****</span>
              <span className="masked-card-number">****</span>
              <span className="card-number">{this.getLastFourDigit()}</span>
            </p>
            <Button
              disabled={false}
              data={editButtonData}
              isNewForm={true}
              clickCallback={this.props.handleEditCallback}
            />
          </div>
          <div className="adcb-detail-box">
            <div className="adcb-points-info-wrapper">
              {this.isSwad && <p className="text-label-balance">{paymentLabels.availableLabel}</p>}
              <p className="adcb-points-converted-amount">
                {!this.isSwad && <span className="text-label">{paymentLabels.availableLabel}</span>}
                <span className="currency">{paymentLabels.currencyLabel}</span>
                <span className="amount">{equivalentAmount}</span>
              </p>
              <p className="adcb-touch-points">{availablePoints}</p>
            </div>
            <Input
              data={touchPointInputData}
              onFocus={this.onFieldFocus}
              onBlur={this.onFieldBlur}
              onChange={this.setCustomAmount}
            />
            <p className="adcb-transaction-label">{paymentLabels.minTransactionLabel}</p>
            <div className="total-amount-wrapper">
              <p className="text-label">{amountLabel}</p>
              <p className="amount-wrapper">
                <span className="currency">{paymentLabels.currencyLabel}</span>
                <span className="amount">{toTwoDecimalPlaces(remainingAmount)}</span>
              </p>
              {paymentLabels.remainingAmountDisclaimer && (
                <p className="additional-payment-info">{paymentLabels.remainingAmountDisclaimer}</p>
              )}
            </div>
          </div>
          <div className="c-form adcb-touchpoints-wrapper checkbox-wrapper-step2">
            <PaymentCheckboxes
              paymentOptions={this.propsData.paymentOptions}
              onTncChangeHandler={onTncChangeHandler}
              key="adcb-touch-point-terms-condition"
              onNewsLetterChangeHandler={this.propsData.onNewsLetterChangeHandler}
              stateData={{
                showCheckBox,
                hideNewsletterOption,
                disableBookButton,
                hasTnCError,
                tabIndex,
                isTncChecked: isEnabled,
                bindCheckBoxError: true,
                newsLetterEnabled: this.propsData.newsLetterEnabled,
              }}
            />
          </div>
          <div className="adcb-submit-btn" id="adcbSubmitBtn">
            <Button data={buttonData} isNewForm={true} clickCallback={this.checkEnteredTouchPoints} disabled={false} />
            <RichText
              data={{
                bodyCopy: vatLabel,
                attrs: { className: 'body-3 vat-label' },
              }}
            />
            {cardDetailImage && (
              <div className="card-detail-image">
                <Image noRendition image={cardDetailImage} />
              </div>
            )}
          </div>

          <div className="c-form adcb-touchpoints-wrapper checkbox-wrapper-step2">
            <PaymentCheckboxes
              paymentOptions={this.propsData.paymentOptions}
              onTncChangeHandler={onTncChangeHandler}
              showNewsLetterCheckBoxOnly={true}
              key="adcb-touch-point-news-letter-condition"
              onNewsLetterChangeHandler={onNewsLetterChangeHandler}
              stateData={{
                showCheckBox,
                hideNewsletterOption,
                disableBookButton,
                hasTnCError,
                tabIndex,
                isTncChecked: isEnabled,
                bindCheckBoxError: true,
                newsLetterEnabled: newsLetterEnabled,
              }}
            />
          </div>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'ADCBTouchPointsStep2');
    }
  }
}

export default ADCBTouchPointsStep2;
