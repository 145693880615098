import React, { useState, useEffect } from 'react';
import UIConfig from '../../../../common/UIConfig';
import moment from 'moment';
import {
  canUseDOM,
  resolvePath,
  parseQueryString,
  createMappedData,
  checkTenant,
  getFallbackLanguage,
  getErrorMap,
  getAnonymousCart,
  removeTicketUpgradeCart,
  toTwoDecimalPlaces,
} from '../../../../common/utility';
import GTMData from '../gtm-data';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { DynamicContent, Image, SvgSprite, AnchorLink } from '../../../presentation/base';
import { getB2CProductDetails } from '../../../../common/coveo-api';
import { OrderService, TryUpgradeData } from '../../../../common/services';
import Tooltip from '../../../presentation/tooltip/tooltip-component';
import { errorCallback } from '../purchase-journey-wrapper/helper';

const TicketUpgrade = (props) => {
  const { data, services, upgradeObj, miniCart, addToCartCallback } = props;
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const ticketData = data.controls;
  const [guestOrderData, setGuestOrderData] = useState({});
  const [suggestedTicketsData, setSuggestedTicketsData] = useState([]);
  const [coveoList, setCoveoList] = useState([]);
  const [errorMeassage, setErrorMessage] = useState(false);
  const [tuErrorMeassage, setTuErrorMessage] = useState(false);
  const [ticketUpgradeError, seTicketUpgradeError] = useState(false);
  const [checkboxList, setCheckboxList] = useState([]);
  const [selectedTicketIds, setSelectedTicketIds] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState();
  const [ticketList, setTicketList] = useState();
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem(UIConfig.appLocalStorageKeys.mainObj));
  const tenantId = resolvePath(getMainObj, 'tenantID', '');
  const [linkedJuniorTicketsInfo, setLinkedJuniorTicketsInfo] = useState({
    juniorProductId: null,
    orderContainsJuniorTickets: false,
  });
  const getOrderId = parseQueryString('orderId');
  const getUserId = parseQueryString('userId');
  const getPaymentPageError = parseQueryString('isRedirectedFromPaymentPage') || '';
  const [ticketUpgradeModal, setTicketUpgradeModal] = useState({});
  const [ticketUpgradeLocalStorage, setTicketUpgradeLocalStorage] = useState(false);
  const [gaData, setGaData] = useState([]);
  const [gaCategoryName, setGaCategoryName] = useState('');
  const [ticketUpgradableDateModal, setTicketUpgradableDateModal] = useState(false);

  useEffect(() => {
    if (ticketUpgradeLocalStorage) {
      //Note: We should remove all the storage before initiating the ticket upgrade journey
      removeTicketUpgradeCart();
      upgradeTicketOnClick(ticketUpgradeModal.ticketIds, ticketUpgradeModal.productId);
    }
  }, [ticketUpgradeModal, ticketUpgradeLocalStorage]);

  useEffect(() => {
    if (selectedTicket) {
      const selectedProductData = getGaProductData(selectedTicket);
      if (selectedProductData) {
        GTMData.push(
          'selectItem',
          { itemsList: [selectedProductData], category: gaCategoryName, gaTabTitle: gaCategoryName },
          false,
          false,
          true,
        );
      }
    }
  }, [selectedTicket]);

  useEffect(() => {
    if (gaData && gaData.length > 0) {
      const categoryName = data?.gaTabTitle || data?.name || window?.location?.pathname;
      setGaCategoryName(categoryName);

      GTMData.push(
        'viewItemList',
        { allProductList: gaData, category: categoryName, gaTabTitle: categoryName },
        false,
        false,
        true,
      );

      const selectedProductData = getGaProductData(selectedTicket);
      if (selectedProductData) {
        GTMData.push(
          'selectItem',
          { itemsList: [selectedProductData], category: categoryName, gaTabTitle: categoryName },
          false,
          false,
          true,
        );
      }
    }
  }, [gaData]);

  useEffect(() => {
    if (coveoList && coveoList.length > 0) {
      let finalGaData = [];
      const coveoProductsData = createMappedData(coveoList, props.coveoMappingList);
      if (coveoProductsData && coveoProductsData.length > 0) {
        if (suggestedTicketsData?.ticketUpgradeSuggestions) {
          suggestedTicketsData.ticketUpgradeSuggestions.forEach((data) => {
            const coveoProductData = coveoProductsData
              ?.map((coveoData) => {
                if (coveoData.productId === data.productId) {
                  return coveoData;
                }
                return;
              })
              .filter((data) => data !== undefined);

            if (coveoProductData && coveoProductData.length > 0) {
              finalGaData.push(coveoProductData[0]);
            }
          });
        }
      }

      // Set GA Data
      setGaData(finalGaData);
    }
  }, [coveoList]);

  useEffect(() => {
    let checkOrderId = canUseDOM() && parseQueryString('orderId');
    let checkUserId = canUseDOM() && parseQueryString('userId');
    if (getPaymentPageError === 'true') {
      setErrorMessage(true);
    } else if (checkOrderId && checkUserId) {
      getTicketUpgradeInfo();
    } else {
      setErrorMessage(true);
    }
  }, []);

  const getTicketInfoBindToCheckbox = (ticketData) => {
    const suggestedProductData = suggestedTicketsData?.ticketUpgradeSuggestions
      ?.map((data) => {
        if (data.productId === selectedTicket) {
          return data;
        }
        return;
      })
      .filter((data) => data !== undefined);

    let priceDiff = parseFloat(suggestedProductData?.[0]?.productUpgradePrice);
    if (
      linkedJuniorTicketsInfo.orderContainsJuniorTickets &&
      linkedJuniorTicketsInfo.juniorProductId === ticketData?.productId
    ) {
      priceDiff = parseFloat(suggestedProductData?.[0]?.productPrice) - parseFloat(ticketData.price.gross);
    }

    return {
      mediaId: ticketData?.tickets[0]?.media[0]?.mediaId,
      ticketId: ticketData?.tickets[0]?.ticketId,
      productId: ticketData?.productId,
      validFrom: ticketData?.tickets[0]?.validFrom,
      priceDiff: priceDiff,
      accountAKId: ticketData?.tickets[0]?.ticketHolder?.accountAKId,
    };
  };

  useEffect(() => {
    const checkboxSelect = checkboxList?.map((ticketData) => {
      return getTicketInfoBindToCheckbox(ticketData);
    });
    setSelectedTicketIds(checkboxSelect);
  }, [checkboxList, selectedTicket]);

  useEffect(() => {
    let finalTicketData = [];

    if (suggestedTicketsData?.upgradableProduct?.productType === UIConfig.parksTicketUpgrade.annualPassType) {
      for (const layoutData of ticketData?.annualPass?.options) {
        if (layoutData?.ticketSelector?.ticket?.productId === suggestedTicketsData?.upgradableProduct?.productId) {
          finalTicketData.push(layoutData);
          break;
        }
      }
    } else {
      for (const layoutData of ticketData?.extras?.options) {
        if (
          layoutData?.productOverlaySelector?.collections[0]?.productId ===
          suggestedTicketsData?.upgradableProduct?.productId
        ) {
          if (layoutData?.productOverlaySelector?.collections[0]?.linkedJuniorProductId) {
            linkedJuniorTicketsInfo.juniorProductId =
              layoutData?.productOverlaySelector?.collections[0]?.linkedJuniorProductId;
          }
          finalTicketData.push(layoutData);
          break;
        }
      }
    }

    if (suggestedTicketsData?.ticketUpgradeSuggestions) {
      for (const suggestedTicket of suggestedTicketsData?.ticketUpgradeSuggestions) {
        if (suggestedTicket?.productType === UIConfig.parksTicketUpgrade.annualPassType) {
          for (const layoutData of ticketData?.annualPass?.options) {
            if (layoutData?.ticketSelector?.ticket?.productId === suggestedTicket?.productId) {
              finalTicketData.push(layoutData);
              break;
            }
          }
        } else {
          for (const layoutData of ticketData?.extras?.options) {
            if (layoutData?.productOverlaySelector?.collections[0]?.productId === suggestedTicket?.productId) {
              finalTicketData.push(layoutData);
              break;
            }
          }
        }
      }
    }

    // Check - Does the order contain any junior tickets?
    const orderLineItems = guestOrderData?.items?.map((lineItem) => {
      if (
        linkedJuniorTicketsInfo.juniorProductId &&
        lineItem.productId === linkedJuniorTicketsInfo.juniorProductId &&
        !linkedJuniorTicketsInfo.orderContainsJuniorTickets
      ) {
        setLinkedJuniorTicketsInfo({ ...linkedJuniorTicketsInfo, orderContainsJuniorTickets: true });
      }
    });

    setTicketList(finalTicketData);
  }, [suggestedTicketsData]);

  useEffect(() => {
    if (linkedJuniorTicketsInfo.orderContainsJuniorTickets) {
      const orderLineItems = guestOrderData?.items
        ?.map((lineItem) => {
          if (
            linkedJuniorTicketsInfo.juniorProductId &&
            lineItem.productId === linkedJuniorTicketsInfo.juniorProductId
          ) {
            return lineItem;
          }
        })
        .filter((data) => data !== undefined);
      const finalCheckboxList = [...checkboxList, ...orderLineItems];
      setCheckboxList(finalCheckboxList);
    }
  }, [linkedJuniorTicketsInfo]);

  useEffect(() => {
    ticketList &&
      setSelectedTicket(
        ticketList[1]?.productOverlaySelector?.collections[0]?.productId ||
          ticketList[1]?.ticketSelector?.ticket.productId,
      );
  }, [ticketList]);

  useEffect(() => {
    const checker = guestOrderData?.items?.map((data) => data.tickets[0].isUpgraded)?.includes(true);
    setTuErrorMessage(checker);
  }, [guestOrderData]);

  const handleCheckAllChange = (e) => {
    setSelectedTicketIds(
      e.target.checked
        ? checkboxList?.map((ticketData) => {
            return getTicketInfoBindToCheckbox(ticketData);
          })
        : [],
    );
  };

  const handleCheckboxEvent = (e, mediaId) => {
    e.persist();
    setSelectedTicketIds((prevChecked) => {
      const selectedTicketData = checkboxList
        ?.map((ticketData) => {
          if (ticketData.tickets[0].media[0].mediaId === mediaId) {
            return ticketData;
          }
          return;
        })
        .filter((data) => data !== undefined);

      return e.target.checked
        ? [...prevChecked, getTicketInfoBindToCheckbox(selectedTicketData[0])]
        : prevChecked.filter((item) => item.mediaId !== mediaId);
    });
  };

  const ticketSelect = (productId) => {
    setSelectedTicket(productId);
  };

  const coveoApiCall = (ticketUpgradesData) => {
    // Product Ids to include in the Coveo query
    let productIds = [];

    if (ticketUpgradesData?.ticketUpgradeSuggestions) {
      ticketUpgradesData.ticketUpgradeSuggestions.forEach((data) => {
        productIds.push(data.productId);
      });
    }

    ticketUpgradesData?.upgradableProduct?.productId && productIds.push(ticketUpgradesData.upgradableProduct.productId);

    if (linkedJuniorTicketsInfo.juniorProductId) {
      productIds.push(linkedJuniorTicketsInfo.juniorProductId);
    }

    const coveoDefaultParams = {
      perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
      coveoKeyMap: props.coveoMappingList,
      serviceUrl: props?.services?.getCoveoProducts.url,
      fieldsToInclude: props.coveoMappingList && Object.values(props.coveoMappingList),
      lang: getFallbackLanguage(),
    };
    const queryParams = [
      {
        key: coveoDefaultParams.coveoKeyMap.productId,
        value: [productIds],
      },
    ];

    getB2CProductDetails({
      ...coveoDefaultParams,
      queryParams: queryParams,
    }).then((response) => {
      if (response?.results) {
        setCoveoList(response.results);
      }
    });
  };

  const getTicketUpgradeInfo = () => {
    const guestOrderUrl = `${services?.getAnonymousOrderDetail?.url}?tenantid=${tenantId}&isEncrypted=true`;
    const ticketUpgradeUrl = services?.getTicketUpgradeSuggestions?.url;

    const guestOrderPayload = {
      orderId: getOrderId,
      userId: getUserId,
    };

    const ticketUpgradePayload = {
      orderId: getOrderId,
      userId: getUserId,
      language: getFallbackLanguage(),
      tenantId: tenantId,
    };

    const getGuestOrderDetails = OrderService.getTicketUpgradeDetails(
      guestOrderUrl,
      guestOrderPayload,
      true,
      UIConfig.loader.defaultPreLoaderTarget,
    );

    const getTicketUpgradesDetails = OrderService.getTicketUpgradeDetails(
      ticketUpgradeUrl,
      ticketUpgradePayload,
      true,
      UIConfig.loader.defaultPreLoaderTarget,
    );

    getGuestOrderDetails
      .then((guestOrderResponse) => {
        if (guestOrderResponse?.statusText === 'OK') {
          getTicketUpgradesDetails.then((ticketUpgradesResponse) => {
            if (
              ticketUpgradesResponse?.statusText === 'OK' &&
              ticketUpgradesResponse.data?.ticketUpgradeSuggestions?.length > 0
            ) {
              let guestOrderData = guestOrderResponse.data;
              let ticketUpgradesData = ticketUpgradesResponse.data;

              // Note: The AP order response is different from the normal ticket purchase. So, here we need to handle it by updating the order response:
              // Case-1. If the upgradable product type is ANP then we should adjust the order response that is similar to the normal ticket purchase.
              // Case-2. If the order contains the AP and any other ticket purchase then we should not consider the order for the upgrade flow.
              if (ticketUpgradesData?.upgradableProduct?.productType === UIConfig.parksTicketUpgrade.annualPassType) {
                if (ticketUpgradesData?.isOrderContainsAnnualPassAndOtherTickets) {
                  // Update the order line items
                  let orderItems = [];
                  for (const item of guestOrderData?.orderdetails?.order?.items) {
                    if (item.quantity === '1') {
                      orderItems.push({ productId: item.productId, tickets: item.tickets, quantity: '1' });
                    } else {
                      const { productId, tickets } = item;
                      tickets.forEach((ticket) => {
                        orderItems.push({ productId: productId, tickets: [ticket], quantity: '1' });
                      });
                    }
                  }

                  guestOrderData.orderdetails.order.items = orderItems;
                } else {
                  const { productId, tickets } = guestOrderData?.orderdetails?.order?.items[0];
                  const orderItems = tickets?.map((ticket) => {
                    return { productId: productId, tickets: [ticket], quantity: '1' };
                  });

                  guestOrderData.orderdetails.order.items = orderItems;
                }

                setGuestOrderData(guestOrderData.orderdetails.order);
              } else {
                setGuestOrderData(guestOrderData.orderdetails.order);
              }

              setSuggestedTicketsData(ticketUpgradesData);

              const upgradableLineItems = guestOrderData?.orderdetails?.order?.items
                ?.map((lineItem) => {
                  if (lineItem.productId === ticketUpgradesData?.upgradableProduct?.productId) {
                    return lineItem;
                  }
                })
                .filter((data) => data !== undefined);
              setCheckboxList(upgradableLineItems);

              coveoApiCall(ticketUpgradesData);
            } else {
              setErrorMessage(true);
            }
          });
        }
      })
      .catch((errorResponse) => {
        setErrorMessage(true);
        errorCallback(
          getErrorMap('getAnonymousOrderDetail', services.getAnonymousOrderDetail?.errors, false, errorResponse?.error),
        );
      });
  };

  const productPrice = (productId) => {
    return suggestedTicketsData?.ticketUpgradeSuggestions
      ?.map((ticketdata) => {
        if (ticketdata.productId === productId) {
          return ticketdata.productPrice;
        }
        return;
      })
      .filter((data) => data !== undefined);
  };

  const calculateVisits = (productId) => {
    return suggestedTicketsData?.ticketUpgradeSuggestions
      ?.map((ticketdata) => {
        if (ticketdata.productId === productId) {
          return Math.floor(
            parseFloat(ticketdata.productPrice / suggestedTicketsData?.upgradableProduct?.productPrice),
          );
        }
        return;
      })
      .filter((data) => data !== undefined);
  };

  const productUpgradePrice = (productId, ticketInfo = null) => {
    const suggestedProductData = suggestedTicketsData?.ticketUpgradeSuggestions
      ?.map((ticketData) => {
        if (ticketData.productId === productId) {
          return ticketData;
        }
        return;
      })
      .filter((data) => data !== undefined);

    // Calculate the price difference for the junior ticket
    if (
      ticketInfo &&
      linkedJuniorTicketsInfo.orderContainsJuniorTickets &&
      linkedJuniorTicketsInfo.juniorProductId === ticketInfo?.productId
    ) {
      return parseFloat(suggestedProductData?.[0]?.productPrice) - parseFloat(ticketInfo?.price?.gross);
    } else {
      return suggestedProductData?.[0]?.productUpgradePrice;
    }
  };

  const productsTotalPrice = () => {
    let totalPrice = 0;
    selectedTicketIds.forEach((item) => {
      totalPrice += parseFloat(item.priceDiff.toFixed(2));
    });
    const totalPriceCheck = Number.isInteger(totalPrice);
    if (totalPriceCheck) {
      return totalPrice;
    } else {
      return toTwoDecimalPlaces(totalPrice);
    }
  };

  const getRibbonLabel = (productId) => {
    return suggestedTicketsData?.ticketUpgradeSuggestions
      ?.map((upgradeLabel) => {
        if (upgradeLabel.productId === productId) {
          return upgradeLabel.productUpgradeLabel;
        }
        return;
      })
      .filter((data) => data !== undefined)
      .toString();
  };

  let tooltipComp = null;
  const close = () => {
    tooltipComp.closeTooltip();
  };

  const getCoveoPersonLabel = (productId) => {
    const coveoProductData = getCoveoProductData(productId);
    return coveoProductData?.[0]?.[props.coveoMappingList.classTypeName];
  };

  const getCoveoProductName = (productId) => {
    const coveoProductData = getCoveoProductData(productId);
    return coveoProductData?.[0]?.[props.coveoMappingList.productName];
  };

  const getCoveoProductData = (productId) => {
    return coveoList
      ?.map((coveoData) => {
        if (coveoData[props.coveoMappingList.productId] === productId) {
          return coveoData;
        }
        return;
      })
      .filter((data) => data !== undefined);
  };

  const getGaProductData = (productId) => {
    return gaData
      ?.map((data) => {
        if (data.productId === productId) {
          return data;
        }
        return;
      })
      .filter((data) => data !== undefined)?.[0];
  };

  const getDateValue = (productId) => {
    return guestOrderData?.items
      .map((ticketdata, index) => {
        if (ticketdata.productId === productId && index < 1) {
          return ticketdata.tickets[0].validTo;
        }
        return;
      })
      .filter((data) => data !== undefined)
      .toString();
  };

  const ErrorMessageContainer = (
    <div>
      {errorMeassage && (
        <Text
          tag="p"
          className="tu-error-subheading"
          field={{ value: upgradeObj?.upgradeToLabel, editable: upgradeObj?.upgradeToLabel }}
        />
      )}
      <div className="tu-error">
        {/* Dynamic error Message */}
        <div className="tu-parent">
          {getPaymentPageError === 'true'
            ? upgradeObj?.ticketUpgradePaymentErrorMessage && (
                <DynamicContent
                  tagName="div"
                  attrs={{ className: 'tu-error-content' }}
                  innerHtml={upgradeObj.ticketUpgradePaymentErrorMessage}
                />
              )
            : upgradeObj?.ticketUpgradeErrorMessage && (
                <DynamicContent
                  tagName="div"
                  attrs={{ className: 'tu-error-content' }}
                  innerHtml={upgradeObj.ticketUpgradeErrorMessage}
                />
              )}
          <div className="tu-contact-details">
            {upgradeObj?.ticketUpgradePhoneNumber && (
              <div className="tu-phone-detail body-1">
                <SvgSprite id="phone-icon" cssClass="phone-icon" />
                <AnchorLink
                  link={{
                    href: `tel:${upgradeObj.ticketUpgradePhoneNumber}`,
                    label: `<span class='sr-only'>${upgradeObj.ticketUpgradePhoneNumber}</span>${upgradeObj.ticketUpgradePhoneNumber}`,
                  }}
                />
              </div>
            )}

            {upgradeObj?.ticketUpgradeEmailLabel && (
              <div className="tu-email-detail body-1">
                <SvgSprite id="email-icon" cssClass="email-icon" />
                <AnchorLink
                  link={{
                    href: upgradeObj.ticketUpgradeEmailLink,
                    label: `<span class='sr-only'>${upgradeObj.ticketUpgradeEmailLabel}</span>${upgradeObj.ticketUpgradeEmailLabel}`,
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {/* Image */}
        {upgradeObj?.ticketUpgradeErrorMessageImage?.src && (
          <div className="tu-image">
            <img src={upgradeObj.ticketUpgradeErrorMessageImage.src} alt="martian-img" width={273} />
          </div>
        )}
      </div>
    </div>
  );

  const checkSelected = (productId) => {
    return selectedTicket === productId;
  };

  const checkTicketCount = (productId) => {
    return checkboxList
      ?.map((data) => {
        if (data.productId === productId) {
          return data;
        }
      })
      .filter((data) => data !== undefined)?.length;
  };

  const isUpgradePeriodExpired = (validToDate) => {
    const todaysDate = new Date();

    const todayYears = todaysDate.getFullYear();
    const todayMonth = todaysDate.getMonth();
    const toDay = todaysDate.getDate();

    const validYear = validToDate.getFullYear();
    const validMonth = validToDate.getMonth();
    const validDate = validToDate.getDate();
    if (
      todayYears > validYear ||
      (todayYears === validYear && todayMonth > validMonth) ||
      (todayYears === validYear && todayMonth === validMonth && toDay > validDate)
    ) {
      return true;
    }
    return false;
  };

  const upgradeTicketOnClick = (selectedTicketIds, upgradeToProductId) => {
    const primaryAccountAKId = guestOrderData?.reservationOwnerAccount?.accountAKId;
    const upgradeToProductCoveoData = getCoveoProductData(upgradeToProductId);
    const upgradeToProductType = upgradeToProductCoveoData?.[0]?.[props.coveoMappingList.itemType];

    const validToDate = new Date(getDateValue(suggestedTicketsData?.upgradableProduct.productId));
    if (isUpgradePeriodExpired(validToDate)) {
      setTicketUpgradableDateModal(true);
      return;
    }

    let items = [];
    selectedTicketIds.forEach((item) => {
      let itemToUpgrade = {
        fromMediaCode: item.mediaId,
        toTicketHolderAK: item.accountAKId ? item.accountAKId : primaryAccountAKId,
        toProductId: upgradeToProductId,
        ...(upgradeToProductType === UIConfig.parksTicketUpgrade.multiparkType && {
          validFrom: moment.utc(item.validFrom).format(UIConfig.calendar.dateFormat),
        }),
        ...(upgradeToProductType === UIConfig.parksTicketUpgrade.multiparkType),
      };

      items.push(itemToUpgrade);
    });

    const config = {
      url: props.services?.tryUpgrades?.url?.replace('{0}', tenantId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
      data: {
        items: items,
      },
    };

    TryUpgradeData.tryUpgradeAPI(config)
      .then((upgradeResponse) => {
        if (upgradeResponse && upgradeResponse.status === 200) {
          // Read Account(s) Details
          let accountIds = [{ id: primaryAccountAKId }];
          if (suggestedTicketsData?.upgradableProduct?.productType === UIConfig.parksTicketUpgrade.annualPassType) {
            selectedTicketIds.forEach((item) => {
              accountIds.push({ id: item.accountAKId });
            });
          }
          const readAccountPayload = {
            accounts: accountIds,
          };

          const readAccountDetailsUrl = services?.getAccountDetail?.url?.replace('{0}', tenantId);
          const accountDetails = OrderService.getGuestAccountDetails(
            readAccountDetailsUrl,
            readAccountPayload,
            true,
            UIConfig.loader.defaultPreLoaderTarget,
          );

          //Creating a ticket-upgrade cart object
          accountDetails.then((accountResponse) => {
            if (accountResponse && accountResponse.status === 200) {
              const allAccountDetails =
                accountResponse &&
                accountResponse.data?.guestDetails?.map((data) => {
                  data.photo = ''; // Remove Photo

                  if (data?.gender?.toUpperCase() === UIConfig?.parksTicketUpgrade?.maleGuest) {
                    data.gender = UIConfig?.parksTicketUpgrade?.male;
                  } else if (data?.gender?.toUpperCase() === UIConfig?.parksTicketUpgrade?.femaleGuest) {
                    data.gender = UIConfig?.parksTicketUpgrade?.female;
                  }
                  return data;
                });

              // Filter and map the items from the Coveo and Layout service data which are part of the ticket-upgrade journey
              const upgradeToItems = upgradeResponse.data.itemList.item.filter((item) => parseInt(item.qty) === 1);

              //Note: Here we are upgrading the single/multiple tickets to a single ticket, which means we should target only one product
              const coveoData = coveoList?.length && createMappedData(coveoList, props.coveoMappingList);
              let finalProductData = coveoData?.filter((data) => {
                return data.productId === upgradeToProductId ? data : null;
              })?.[0];

              //Note: In the case of the junior ticket selection, we need to handle it separately
              //      (or we can say, we need to have a separate line item in a cart) and the main reason is the price difference between Adult and Junior tickets
              let adultTicketsData = [];
              let juniorTicketsData = [];
              if (linkedJuniorTicketsInfo.orderContainsJuniorTickets) {
                juniorTicketsData = selectedTicketIds
                  ?.filter((ticketData) => {
                    return ticketData.productId === linkedJuniorTicketsInfo.juniorProductId ? ticketData : null;
                  })
                  .filter((data) => data !== undefined || data !== null);
              }
              if (juniorTicketsData?.length === 0) {
                adultTicketsData = selectedTicketIds;
              } else {
                adultTicketsData = selectedTicketIds
                  ?.filter((ticketData) => {
                    return ticketData.productId !== linkedJuniorTicketsInfo.juniorProductId ? ticketData : null;
                  })
                  .filter((data) => data !== undefined || data !== null);
              }

              // Get product details from the layout service data
              const upgradeToProductData = ticketList?.filter((data) => {
                // Note: The internal structure of AP and Non-AP products is different, so we have to make a check at two different places
                return upgradeToProductId ===
                  (data?.productOverlaySelector?.collections[0]?.coveoval || data?.ticketSelector?.ticket?.productId)
                  ? data
                  : null;
              });

              //Adjust the quantity, price, and other details
              let cartProducts = [];
              const primaryAccountDetails = allAccountDetails?.filter(
                (data) => data?.accountId === primaryAccountAKId,
              )?.[0];
              if (
                suggestedTicketsData?.upgradableProduct?.productType === UIConfig.parksTicketUpgrade.annualPassType &&
                upgradeToProductType === UIConfig.parksTicketUpgrade.annualPassType &&
                allAccountDetails?.length > 0
              ) {
                finalProductData.guestDetails = [];
                for (const accountDetails of allAccountDetails) {
                  if (accountDetails?.accountId !== primaryAccountAKId) {
                    finalProductData.guestDetails.push(accountDetails);
                  }
                }
              } else {
                finalProductData.guestDetails = [];
                finalProductData.guestDetails.push(primaryAccountDetails);
              }
              if (isSwad) {
                finalProductData.eventType = finalProductData && finalProductData.eventType.join('');
              }
              finalProductData.isFlexible = finalProductData.isFlexible =
                upgradeToProductType === UIConfig.parksTicketUpgrade.annualPassType ? true : '';
              finalProductData.fromDate = moment.utc(upgradeToItems[0].activeFrom).format(UIConfig.calendar.dateFormat);
              finalProductData.rangeInMonths =
                upgradeToProductType === UIConfig.parksTicketUpgrade.annualPassType ? 12 : '';
              finalProductData.minCount = upgradeToProductData[0]?.visitorSelector?.minCount;
              finalProductData.maxCount = upgradeToProductData[0]?.visitorSelector?.maxCount;
              finalProductData.isIncreaseDecreaseEnabled = false;
              finalProductData.toDate = '';

              if (juniorTicketsData?.length > 0 && adultTicketsData?.length > 0) {
                // Adjust the price based on the Adult and Junior ticket price difference
                const totalQuantity = adultTicketsData.length + juniorTicketsData.length;
                let totalPrice =
                  adultTicketsData[0].priceDiff * adultTicketsData?.length +
                  juniorTicketsData[0].priceDiff * juniorTicketsData.length;
                const finalPrice = parseFloat(totalPrice / totalQuantity);

                finalProductData.gross = finalPrice;
                finalProductData.unitPrice = finalPrice;
                finalProductData.quantity = totalQuantity;
              } else {
                if (juniorTicketsData?.length === 0 && adultTicketsData?.length > 0) {
                  finalProductData.gross = adultTicketsData[0].priceDiff;
                  finalProductData.unitPrice = adultTicketsData[0].priceDiff;
                  finalProductData.quantity = adultTicketsData.length;
                } else if (juniorTicketsData?.length > 0 && adultTicketsData?.length === 0) {
                  finalProductData.gross = juniorTicketsData[0].priceDiff;
                  finalProductData.unitPrice = juniorTicketsData[0].priceDiff;
                  finalProductData.quantity = juniorTicketsData.length;
                }
              }

              cartProducts.push(finalProductData);

              if (accountResponse && cartProducts.length > 0) {
                //Note: The GTM add_to_cart event will be triggered when an item is added to the cart
                addToCartCallback(cartProducts, false, null, null, {
                  isTicketUpgradeCart: true,
                });

                // Store upgrade request payload & redirect to the Payment page
                setTimeout(() => {
                  const finalTicketUpgradePayload = {
                    items: items,
                    productItemType: upgradeToProductType,
                    fromProductItemType: suggestedTicketsData?.upgradableProduct?.productType,
                    orderId: suggestedTicketsData?.orderId,
                    yasId: primaryAccountDetails?.yasId,
                    omniAccountAK: primaryAccountAKId,
                    loggedAccount: {
                      firstName: primaryAccountDetails?.firstName,
                      lastName: primaryAccountDetails?.lastName,
                      email:
                        primaryAccountDetails?.email && primaryAccountDetails?.email !== ''
                          ? primaryAccountDetails?.email
                          : suggestedTicketsData?.userId,
                      yasId: primaryAccountDetails?.yasId,
                      mobileNumber: primaryAccountDetails?.mobileNumber,
                      nationality: primaryAccountDetails?.nationality,
                      countryOfResidence: primaryAccountDetails?.countryOfResidence,
                    },
                  };

                  localStorage.setItem(
                    UIConfig.parksTicketUpgrade.ticketUpgradeData,
                    JSON.stringify(finalTicketUpgradePayload),
                  );
                  localStorage.setItem(
                    UIConfig.parksTicketUpgrade.upgradeUrlWithQS,
                    JSON.stringify(window.location.href),
                  );

                  if (upgradeObj?.ticketUpgradePaymentPageLink)
                    window.location.href = `${upgradeObj.ticketUpgradePaymentPageLink}?cart-type=${UIConfig.parksTicketUpgrade.cartType}`;
                }, 500);
              } else {
                seTicketUpgradeError(true);
              }
            } else {
              seTicketUpgradeError(true);
            }
          });
        } else {
          seTicketUpgradeError(true);
        }
      })
      .catch((errorResponse) => {
        errorCallback('tryUpgrades', services.tryUpgrades?.errors, false, errorResponse?.error);
      });
  };

  const checkAnonymousCart = (props) => {
    const anonymousCart = getAnonymousCart();
    if (anonymousCart) {
      if (anonymousCart?.cart?.isTicketUpgradeCart) {
        removeTicketUpgradeCart();
        upgradeTicketOnClick(props.ticketIds, props.productId);
      } else {
        setTicketUpgradeModal({
          modal: true,
          ticketIds: props.ticketIds,
          productId: props.productId,
        });
      }
    } else {
      upgradeTicketOnClick(props.ticketIds, props.productId);
    }
  };

  return (
    <div className="ticket-upgrade-component">
      {!errorMeassage && ticketData ? (
        <>
          {(ticketUpgradeModal?.modal || ticketUpgradableDateModal) && (
            <div data-c-name="Overlay" className="c-error-overlay v-b2c-overlay overlay active">
              <div className="overlay-container ">
                <div role="dialog" className="overlay-wrapper" tabindex="-1">
                  <div className="custom-fixed-title"></div>
                  <div className="overlay-content-container">
                    <div className="overlay-content-inner-container">
                      <div className="overlay-body">
                        {!ticketUpgradableDateModal && <h2 className="heading-3">{upgradeObj?.modalPopupTitle}</h2>}
                        {!ticketUpgradableDateModal ? (
                          <div>
                            <p className="existing-item-warning">{upgradeObj?.modalPopupSubTitle}</p>
                          </div>
                        ) : (
                          <DynamicContent
                            tagName="p"
                            attrs={{ className: 'existing-item-warning' }}
                            innerHtml={upgradeObj?.ticketUpgradePeriodExpiredErrorMessage}
                          />
                        )}

                        {!ticketUpgradableDateModal && (
                          <div className="btn btn-primary">
                            <DynamicContent
                              tagName="button"
                              innerHtml={upgradeObj?.modalPopupButtonText}
                              attrs={{
                                onClick: () => setTicketUpgradeLocalStorage(true),
                                disabled: selectedTicketIds.length > 0 && !ticketUpgradeError ? false : true,
                                className: 'continue-checkout',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="top-border">
                    <div
                      role="button"
                      aria-label="close"
                      className="overlay--close-button"
                      tabindex="0"
                      onClick={() => {
                        setTicketUpgradableDateModal(false);
                        setTicketUpgradeModal(false);
                      }}
                    >
                      <SvgSprite id="icn-close" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="upgradable-tickets-title">
            {ticketList &&
              ticketList
                .filter((data) => {
                  const overlayProductId = data?.productOverlaySelector?.collections[0]?.productId;
                  const ticketProductId = data?.ticketSelector?.ticket?.productId;
                  const upgradableProductId = suggestedTicketsData?.upgradableProduct?.productId;

                  return (
                    (overlayProductId || ticketProductId) &&
                    (overlayProductId === upgradableProductId || ticketProductId === upgradableProductId)
                  );
                })

                .map((data, ticketIndex) => {
                  return (
                    <>
                      <div className="tu-wrapper upgradable-ticket" key={ticketIndex}>
                        <div className="ut-left">
                          <div className="ut-title">
                            <Text
                              tag="p"
                              className="heading-4"
                              field={{
                                value:
                                  data?.title ||
                                  data.ariaLabelCheckbox ||
                                  getCoveoProductName(suggestedTicketsData?.upgradableProduct?.productId),
                                editable:
                                  data?.title ||
                                  data.ariaLabelCheckbox ||
                                  getCoveoProductName(suggestedTicketsData?.upgradableProduct?.productId),
                              }}
                            />
                          </div>

                          <div className="ut-date">
                            {suggestedTicketsData?.upgradableProduct?.productType ===
                            UIConfig.parksTicketUpgrade.annualPassType ? (
                              <Text
                                tag="span"
                                className="ut-date-label"
                                field={{
                                  value: miniCart.cartLabels.validUntil,
                                  editable: miniCart.cartLabels.validUntil,
                                }}
                              />
                            ) : (
                              <Text
                                tag="span"
                                className="ut-date-label"
                                field={{
                                  value: miniCart.cartLabels.validOn,
                                  editable: miniCart.cartLabels.validOn,
                                }}
                              />
                            )}{' '}
                            {(data?.productOverlaySelector?.collections[0]?.productId ||
                              data?.ticketSelector?.ticket?.productId) && (
                              <Text
                                tag="span"
                                className="ut-date-value"
                                field={{
                                  value: moment
                                    .utc(
                                      getDateValue(
                                        data?.productOverlaySelector?.collections[0]?.productId ||
                                          data?.ticketSelector?.ticket?.productId,
                                      ),
                                    )
                                    .format(UIConfig.calendar.AnnualPassValidityDateFormat),
                                  editable: moment
                                    .utc(
                                      getDateValue(
                                        data?.productOverlaySelector?.collections[0]?.productId ||
                                          data?.ticketSelector?.ticket?.productId,
                                      ),
                                    )
                                    .format(UIConfig.calendar.AnnualPassValidityDateFormat),
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="ut-right">
                          {(data?.productOverlaySelector?.collections[0]?.productId ||
                            data?.ticketSelector?.ticket?.productId) && (
                            <div className="ut-person">
                              <Text
                                tag="span"
                                className="person-label"
                                field={{
                                  value: getCoveoPersonLabel(
                                    data?.productOverlaySelector?.collections[0]?.productId ||
                                      data?.ticketSelector?.ticket?.productId,
                                  ),
                                  editable: getCoveoPersonLabel(
                                    data?.productOverlaySelector?.collections[0]?.productId ||
                                      data?.ticketSelector?.ticket?.productId,
                                  ),
                                }}
                              />

                              <span className="person-total">
                                {checkTicketCount(
                                  data?.productOverlaySelector?.collections[0]?.productId ||
                                    data?.ticketSelector?.ticket?.productId,
                                )}
                              </span>
                            </div>
                          )}
                          {linkedJuniorTicketsInfo?.orderContainsJuniorTickets && (
                            <div className="ut-person">
                              <Text
                                tag="span"
                                className="person-label"
                                field={{
                                  value: getCoveoPersonLabel(linkedJuniorTicketsInfo?.juniorProductId),
                                  editable: getCoveoPersonLabel(linkedJuniorTicketsInfo?.juniorProductId),
                                }}
                              />
                              <span className="person-total">
                                {checkTicketCount(linkedJuniorTicketsInfo?.juniorProductId)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      {upgradeObj.upgradeToLabel && (
                        <div className="tu-upgradable-label">
                          <Text
                            tag="span"
                            field={{
                              value: upgradeObj.upgradeToLabel,
                              editable: upgradeObj.upgradeToLabel,
                            }}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
          </div>

          {!tuErrorMeassage && (
            <div className="upgradable-tickets-item">
              {ticketList &&
                ticketList
                  .filter((data) => {
                    const overlayProductId = data?.productOverlaySelector?.collections[0]?.productId;
                    const ticketProductId = data?.ticketSelector?.ticket?.productId;
                    const upgradableProductId = suggestedTicketsData?.upgradableProduct?.productId;

                    return (
                      (overlayProductId || ticketProductId) &&
                      overlayProductId !== upgradableProductId &&
                      ticketProductId !== upgradableProductId
                    );
                  })
                  .map((data, ticketIndex) => {
                    return (
                      !tuErrorMeassage && (
                        <div
                          key={ticketIndex}
                          className={`tu-wrapper tu-suggestion ${
                            checkSelected(
                              data?.productOverlaySelector?.collections[0]?.productId ||
                                data?.ticketSelector?.ticket.productId,
                            )
                              ? 'selectedticket'
                              : ''
                          }`}
                          onClick={() =>
                            ticketSelect(
                              data?.productOverlaySelector?.collections[0]?.productId ||
                                data?.ticketSelector?.ticket?.productId,
                            )
                          }
                          data-product={
                            data?.productOverlaySelector?.collections[0]?.productId ||
                            data?.ticketSelector?.ticket?.productId
                          }
                        >
                          {getRibbonLabel(
                            data?.productOverlaySelector?.collections[0]?.productId ||
                              data?.ticketSelector?.ticket?.productId,
                          ) && (
                            <DynamicContent
                              tagName="div"
                              attrs={{ className: 'ribbon-pop' }}
                              innerHtml={getRibbonLabel(
                                data?.productOverlaySelector?.collections[0]?.productId ||
                                  data?.ticketSelector?.ticket?.productId,
                              )}
                            />
                          )}
                          <div className="tu-select-ticket">
                            <label
                              className={`tu-radio-wraper`}
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  ticketSelect(
                                    data?.productOverlaySelector?.collections[0]?.productId ||
                                      data?.ticketSelector?.ticket?.productId,
                                  );
                                }
                              }}
                            >
                              <input
                                className={`body-copy-3`}
                                name={'TicketUpgrade'}
                                type="radio"
                                tabIndex="-1"
                                checked={
                                  selectedTicket ===
                                  (data?.productOverlaySelector?.collections[0]?.productId ||
                                    data?.ticketSelector?.ticket?.productId)
                                }
                                onChange={() =>
                                  ticketSelect(
                                    data?.productOverlaySelector?.collections[0]?.productId ||
                                      data?.ticketSelector?.ticket?.productId,
                                  )
                                }
                                value={
                                  data?.productOverlaySelector?.collections[0]?.productId ||
                                  data?.ticketSelector?.ticket?.productId
                                }
                              />
                            </label>
                          </div>
                          <div className="tu-container">
                            <div className="tu-left">
                              <div className="c-fast-desc">
                                {
                                  <Text
                                    tag="p"
                                    className="heading-4"
                                    field={{
                                      value:
                                        data?.title ||
                                        data.ariaLabelCheckbox ||
                                        getCoveoProductName(suggestedTicketsData?.upgradableProduct?.productId),
                                      editable:
                                        data?.title ||
                                        data.ariaLabelCheckbox ||
                                        getCoveoProductName(suggestedTicketsData?.upgradableProduct?.productId),
                                    }}
                                  />
                                }
                                {isSwad && (
                                  <>
                                    <div className="tu-footer-text">
                                      {guestOrderData?.total.currency && (
                                        <div className="tu-total-price">
                                          {guestOrderData?.total.currency}{' '}
                                          {productPrice(
                                            data?.productOverlaySelector?.collections[0]?.productId ||
                                              data?.ticketSelector?.ticket?.productId,
                                          )}
                                        </div>
                                      )}
                                      {guestOrderData?.total.currency && (
                                        <div className="tu-person-price">
                                          {guestOrderData.total.currency}{' '}
                                          {productUpgradePrice(
                                            data?.productOverlaySelector?.collections[0]?.productId ||
                                              data?.ticketSelector?.ticket?.productId,
                                            null,
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="tu-person-label">{upgradeObj.perPersonLabel}</div>
                                  </>
                                )}
                                {isSwad &&
                                  selectedTicket !== data?.ticketSelector?.ticket?.productId &&
                                  data?.ticketSelector?.ticket?.productId && (
                                    <>
                                      <Text
                                        tag="div"
                                        className="tu-recoup-label"
                                        field={{
                                          value: upgradeObj?.annualPassRecoupLabel.replace(
                                            '{0}',
                                            calculateVisits(data?.ticketSelector?.ticket?.productId),
                                          ),
                                          editable: upgradeObj?.annualPassRecoupLabel.replace(
                                            '{0}',
                                            calculateVisits(data?.ticketSelector?.ticket?.productId),
                                          ),
                                        }}
                                      />
                                    </>
                                  )}
                                {data?.ticketSelector ? (
                                  <>
                                    <DynamicContent
                                      tagName="span"
                                      attrs={{ className: 'pass-title' }}
                                      innerHtml={data?.ticketSelector?.ticket?.subTitle}
                                    />
                                    <DynamicContent
                                      tagName="div"
                                      attrs={{ className: 'body-1' }}
                                      innerHtml={data?.ticketSelector?.ticket?.description}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <DynamicContent
                                      tagName="span"
                                      attrs={{ className: 'pass-title' }}
                                      innerHtml={data?.info?.subTitle}
                                    />
                                    <DynamicContent
                                      tagName="div"
                                      attrs={{ className: 'body-1' }}
                                      innerHtml={data?.description}
                                    />
                                  </>
                                )}

                                {data?.Icons ? (
                                  <div className="parks-logo">
                                    {data?.Icons?.map((icon) => (
                                      <Image image={icon} disableLazyLoad={false} />
                                    ))}
                                  </div>
                                ) : (
                                  <div className="pass-logo">
                                    <Image image={data?.ticketSelector?.ticket?.image} disableLazyLoad={false} />
                                  </div>
                                )}

                                {(data?.info || data.ticketSelector?.ticket?.info) && !isSwad && (
                                  <Tooltip
                                    onHover={true}
                                    wrapperClass="c-ticket-tooltip"
                                    buttonInnerHtml={
                                      <span className="info-icon-express">
                                        {data?.info?.title || data?.ticketSelector?.ticket?.info?.title}
                                      </span>
                                    }
                                    ariaLabel={data?.info?.title || data?.ticketSelector?.ticket?.info?.title}
                                    buttonClass=""
                                  >
                                    <div className="ticket-tooltip-body">
                                      <div className="close-tooltip">
                                        <span className="close-icon" onClick={close}>
                                          <SvgSprite id="close-icon" />
                                        </span>
                                      </div>
                                      {(data?.info?.title || data?.ticketSelector?.ticket?.info?.title) && (
                                        <DynamicContent
                                          tagName="div"
                                          attrs={{ className: 'title' }}
                                          innerHtml={data?.info?.title || data?.ticketSelector?.ticket?.info?.title}
                                        />
                                      )}
                                      {(data?.info?.subTitle || data?.ticketSelector?.ticket?.info?.subTitle) && (
                                        <DynamicContent
                                          tagName="div"
                                          attrs={{ className: 'sub-title' }}
                                          innerHtml={
                                            data?.info?.subTitle || data?.ticketSelector?.ticket?.info?.subTitle
                                          }
                                        />
                                      )}
                                      {(data?.info?.description || data?.ticketSelector?.ticket?.info?.description) && (
                                        <DynamicContent
                                          tagName="div"
                                          attrs={{ className: 'description' }}
                                          innerHtml={
                                            data?.info?.description || data?.ticketSelector?.ticket?.info?.description
                                          }
                                        />
                                      )}
                                    </div>
                                  </Tooltip>
                                )}
                                {isSwad &&
                                  ((data?.info && (data?.info?.description || data?.info?.title)) ||
                                    data.ticketSelector?.ticket?.info) && (
                                    <div className="ticket-includes-wrapper">
                                      <DynamicContent
                                        tagName="span"
                                        attrs={{ className: 'ticket-includes-title' }}
                                        innerHtml={data?.info?.title || data?.ticketSelector?.ticket?.info?.title}
                                      />
                                      <DynamicContent
                                        tagName="span"
                                        attrs={{ className: 'ticket-includes-description' }}
                                        innerHtml={
                                          data?.info?.description || data?.ticketSelector?.ticket?.info?.description
                                        }
                                      />
                                    </div>
                                  )}
                                {isSwad && (data?.readMoreLabel || data?.ticketSelector?.ticket?.readMoreLabel) && (
                                  <Text
                                    tag="a"
                                    target="_blank"
                                    className="read-more-link"
                                    href={data?.readMoreLink?.href || data?.ticketSelector?.ticket?.readMoreLink?.href}
                                    field={{
                                      value: data?.readMoreLabel || data?.ticketSelector?.ticket?.readMoreLabel,
                                    }}
                                  />
                                )}
                              </div>
                            </div>

                            {checkboxList?.length !== 0 && (
                              <div className="tu-right">
                                {selectedTicket ===
                                  (data?.productOverlaySelector?.collections[0]?.productId ||
                                    data?.ticketSelector?.ticket?.productId) && (
                                  <div className="tu-header">
                                    <label
                                      htmlFor={
                                        data?.productOverlaySelector?.collections[0]?.productId ||
                                        data?.ticketSelector?.ticket?.productId
                                      }
                                      className="checkbox-label"
                                    >
                                      <input
                                        type="checkbox"
                                        id={
                                          data?.productOverlaySelector?.collections[0]?.productId ||
                                          data?.ticketSelector?.ticket?.productId
                                        }
                                        checked={
                                          selectedTicket ===
                                            (data?.productOverlaySelector?.collections[0].productId ||
                                              data?.ticketSelector?.ticket?.productId) &&
                                          selectedTicketIds?.length === checkboxList?.length
                                        }
                                        onClick={(e) => handleCheckAllChange(e)}
                                      />
                                      <div
                                        className="checkbox"
                                        tabIndex="0"
                                        onKeyDown={(e) => {
                                          if (e.keyCode === 13) {
                                            e.target.click();
                                          }
                                        }}
                                      ></div>
                                      <div className="selectall-label">
                                        <span className="select-label">{upgradeObj?.selectAllLabel}</span>{' '}
                                        <span className="select-no">({checkboxList?.length})</span>
                                      </div>
                                    </label>
                                  </div>
                                )}
                                {selectedTicketIds?.length !== checkboxList?.length &&
                                  selectedTicket ===
                                    (data?.productOverlaySelector?.collections[0]?.productId ||
                                      data?.ticketSelector?.ticket?.productId) && (
                                    <div className="tu-body">
                                      {checkboxList?.map((ticketData, index) => {
                                        var ticketInfo = { productId: ticketData.productId, price: ticketData.price };
                                        return (
                                          <div className="tu-checkbox-list">
                                            <label
                                              htmlFor={ticketData.tickets[0].media[0].mediaId + `${ticketIndex}`}
                                              className="checkbox-label"
                                              key={index}
                                            >
                                              <input
                                                type="checkbox"
                                                id={ticketData?.tickets[0].media[0].mediaId + `${ticketIndex}`}
                                                onClick={(e) =>
                                                  handleCheckboxEvent(e, ticketData?.tickets[0]?.media[0].mediaId)
                                                }
                                                value={ticketData?.tickets[0]?.media[0].mediaId}
                                              />
                                              <div
                                                className="checkbox"
                                                tabIndex="0"
                                                onKeyDown={(e) => {
                                                  if (e.keyCode === 13) {
                                                    e.target.click();
                                                  }
                                                }}
                                              ></div>
                                              <div className="checkbox-text">
                                                <Text
                                                  tag="span"
                                                  className="person-label"
                                                  field={{
                                                    value: getCoveoPersonLabel(ticketData?.productId),
                                                    editable: getCoveoPersonLabel(ticketData?.productId),
                                                  }}
                                                />
                                                <div className="checkbox-currency-info">
                                                  <span className="checkbox-currency-label">
                                                    {guestOrderData?.total?.currency}{' '}
                                                  </span>
                                                  <Text
                                                    tag="span"
                                                    className="checkbox-price"
                                                    field={{
                                                      value: productUpgradePrice(
                                                        data?.productOverlaySelector?.collections[0]?.productId ||
                                                          data?.ticketSelector?.ticket?.productId,
                                                        ticketInfo,
                                                      ),
                                                      editable: productUpgradePrice(
                                                        data?.productOverlaySelector?.collections[0]?.productId ||
                                                          data?.ticketSelector?.ticket?.productId,
                                                        ticketInfo,
                                                      ),
                                                    }}
                                                  />
                                                  {ticketUpgradeError && <>Ticket is not upgradable</>}
                                                </div>
                                              </div>
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}

                                <div className="tu-footer">
                                  {!isSwad && (
                                    <>
                                      <div className="tu-footer-text">
                                        {guestOrderData?.total.currency && (
                                          <div className="tu-total-price">
                                            {guestOrderData?.total.currency}{' '}
                                            {productPrice(
                                              data?.productOverlaySelector?.collections[0]?.productId ||
                                                data?.ticketSelector?.ticket?.productId,
                                            )}
                                          </div>
                                        )}
                                        {guestOrderData?.total.currency && (
                                          <div className="tu-person-price">
                                            {guestOrderData.total.currency}{' '}
                                            {productUpgradePrice(
                                              data?.productOverlaySelector?.collections[0]?.productId ||
                                                data?.ticketSelector?.ticket?.productId,
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div className="tu-person-label">{upgradeObj.perPersonLabel}</div>
                                    </>
                                  )}
                                  {!isSwad &&
                                    selectedTicket !== data?.ticketSelector?.ticket?.productId &&
                                    data?.ticketSelector?.ticket?.productId && (
                                      <>
                                        <Text
                                          tag="div"
                                          className="tu-recoup-label"
                                          field={{
                                            value: upgradeObj?.annualPassRecoupLabel.replace(
                                              '{0}',
                                              calculateVisits(data?.ticketSelector?.ticket?.productId),
                                            ),
                                            editable: upgradeObj?.annualPassRecoupLabel.replace(
                                              '{0}',
                                              calculateVisits(data?.ticketSelector?.ticket?.productId),
                                            ),
                                          }}
                                        />
                                      </>
                                    )}
                                  {selectedTicket ===
                                    (data?.productOverlaySelector?.collections[0]?.productId ||
                                      data?.ticketSelector?.ticket?.productId) && (
                                    <div className="btn-checkout">
                                      <div className={`btn-primary ${selectedTicketIds?.length > 0 ? '' : 'disabled'}`}>
                                        <DynamicContent
                                          tagName="button"
                                          innerHtml={upgradeObj?.ticketUpgradeButtonText}
                                          attrs={{
                                            onClick: () => {
                                              checkAnonymousCart({
                                                modal: true,
                                                ticketIds: selectedTicketIds,
                                                productId:
                                                  data?.productOverlaySelector?.collections[0]?.productId ||
                                                  data?.ticketSelector?.ticket?.productId,
                                              });
                                            },
                                            disabled:
                                              selectedTicketIds?.length > 0 && !ticketUpgradeError ? false : true,
                                            className: 'continue-checkout',
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedTicketIds?.length > 0 &&
                                    selectedTicket ===
                                      (data?.productOverlaySelector?.collections[0]?.productId ||
                                        data?.ticketSelector?.ticket?.productId) && (
                                      <div className="tu-final-text">
                                        <Text
                                          tag="span"
                                          className="tu-final-label"
                                          field={{
                                            value: miniCart?.cartLabels?.total?.trim(),
                                            editable: miniCart?.cartLabels?.total?.trim(),
                                          }}
                                        />
                                        <span className="tu-final-price">
                                          {guestOrderData?.total?.currency} {productsTotalPrice()}
                                        </span>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    );
                  })}
            </div>
          )}

          {tuErrorMeassage && ErrorMessageContainer}
          {typeof tuErrorMeassage === 'boolean' && !tuErrorMeassage && !errorMeassage && ticketList?.length > 0 && (
            <div className="tu-notes">
              <DynamicContent
                tagName="div"
                attrs={{ className: 'tu-notes-container' }}
                innerHtml={upgradeObj.ticketUpgradeNotes}
              />
            </div>
          )}
        </>
      ) : (
        ErrorMessageContainer
      )}
    </div>
  );
};

export default TicketUpgrade;
