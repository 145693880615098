import React, { Component } from 'react';
import UIConfig from '../../../../common/UIConfig';
import { getFallbackLanguage } from '../../../../common/utility';
import AddOnsTile from './add-ons-tile-component';

class AddOnsTabComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderAddOns: false,
      addOnsList: [],
    };
    this.coveoDefaultParams = {
      perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
      coveoKeyMap: this.props.coveoMappingList,
      serviceUrl: this.props.services.getCoveoProducts.url,
      fieldsToInclude: Object.keys(this.props.coveoMappingList),
      lang: getFallbackLanguage(),
    };
  }

  render() {
    if (!this.props.isYaAddOn) {
      return null;
    }
    return (
      <div>
        {this.props.addOnProducts &&
          this.props.addOnProducts.length > 0 &&
          this.props.addOnProducts.map((item) => {
            return (
              <AddOnsTile
                data={item}
                addToCartCallBack={this.props.addToCartCallback}
                cartData={this.props.cartData}
                mupValidityMap={this.props.mupValidityMap}
                tabsData={this.props.data}
                availabilityMapping={this.props.availabilityMapping}
                additionalRecommendedData={this.props.additionalRecommendedData}
                isYaNonSeatedJourney={this.props.isYaNonSeatedJourney}
                checkPerformanceUrl={this.props.services.addoncheckbasket}
              />
            );
          })}
      </div>
    );
  }
}

export default AddOnsTabComponent;
