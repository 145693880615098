/*
 * tab-feature-list-component.js
 * @licensor  Miral
 */
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  canUseDOM,
  randomNumber,
  resolvePath,
  checkTenant,
  toLowerCase,
  checkGA4Tenants,
} from '../../../common/utility';
import { AnchorLink, DynamicContent, SvgSprite } from '../../presentation/base';
import { Header, Panel, TabHeaders, TabPanels, Tabs } from '../../presentation/tabs/tabs';
import FeatureGrid from '../../presentation/feature-grid';
import GTMData from '../b2c-purchase-journey/gtm-data';
import DropDown from '../../presentation/drop-down';
import { logComponentRenderingError } from '../../../common/logger';
import UIConfig from '../../../common/UIConfig';
import { shortTabsSorting, shortTabsByDateSorting, isMatchTenant, getGa4Category } from '../../../common/utility';

const GTMDataOnClickTab = (data) => {
  if (checkGA4Tenants()) {
    GTMData.push(UIConfig.ga4Constants.CLICK_TAB, {
      name: isMatchTenant(UIConfig.tenants.yi) ? data.name : data.name,
      elementText: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.elementText) : data.elementText,
      category: toLowerCase(getGa4Category(window?.location?.pathname)),
    });
  }
};

/**
 * TabFeatureList functional component contains the
 * functionality of creating featured list with tabs
 * Depends on following component TabHeaders, Header, TabPanels, Panel, FeaturedGrid
 * @return {[Object]} Return the JSX of the component.
 */
const TabFeatureList = ({
  data,
  propData,
  isUpdateDropdownProps,
  showExtraField,
  tileLabels,
  filterVariant,
  showInfoIcon,
  enablePJPopup,
  invokePJCallback,
  invokePackageJourney,
  carouselData,
  index,
  infoAriaLabel,
  disableLazyLoadImage,
  buyNowLabel,
  theme,
  addToCartCallback,
  getDiscountedProducts,
  cartData,
  businessErrors,
}) => {
  const enableDdnOnMobile = data.variation === 'product-list' ? false : true;
  const activeTab = data.activeTab ? data.activeTab : 0;
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const variaton = data.variation;
  const variant = data.variant ? data.variant : isSwad && propData.variant ? propData.variant : '';
  const gridVariation = data.gridVariation;
  const tabsAnalyticsData = [];
  let isProductImpressionsTrigerred = false;
  const [tabData, setTabData] = useState([]);
  const [pjData, setPjData] = useState([]);
  const isDynamicListEnabled =
    propData?.enableDynamicList && data.gridVariation === UIConfig.tabListingGridVariations.twoGridLayout;

  /* React hooks */
  useEffect(() => {
    let sortingOptions = propData?.sortingData?.options;
    if (sortingOptions && variant !== UIConfig.commonVariant.listOfMorePackagesWithFilter) {
      onSortingChange(data.tabList, { value: sortingOptions[0]?.value }, propData.sortingData, propData.sortByDate);
    } else {
      setTabData(data.tabList);
    }
  }, [data]);

  /* React hooks */
  useEffect(() => {
    window.PubSub.subscribe(UIConfig.events.PURCHASEJOURNEYDATA, (msg, data) => {
      setPjData(data);
    });
  }, []);

  /* Product impression GA FIX - 01/11/2022 */
  useEffect(() => {
    ticketImpressionsAnalytics(data);
  }, [tabData, data]);

  /* GA handler functions */
  const ticketImpressionsAnalytics = (data) => {
    if (!data) {
      return;
    }
    const analytics = tabsAnalyticsData[activeTab];
    /* Do nothing if its YMC. Refer to productImpressionsData for YMC */
    if (
      Array.isArray(tabData) &&
      tabData.length &&
      canUseDOM() &&
      analytics &&
      !isProductImpressionsTrigerred &&
      GTMData.getTenantId() !== UIConfig.ymcB2CTenant &&
      propData &&
      propData?.enableGaProdImpression
    ) {
      GTMData.push('ticketImpressions', { products: analytics });
      isProductImpressionsTrigerred = true;
    }
  };

  /**
   * bindTabFeatureList   binds featured list
   * @param    {[Array]} tabsData array of objects for which featured listing needs to be created
   * @return   {[Object]} JSX for featured tiles
   */
  const bindTabFeatureList = (tabsData, index = 0, filterTitle, hideFilterDropdownOnMobile, sortingElement) => {
    if (tabsData.length === 1) {
      const pjTabData = pjData?.purchaseJourneyData?.tabs?.length && pjData.purchaseJourneyData.tabs[0];
      tabsAnalyticsData.push({ ...tabsData[0], gaTabTitle: pjTabData?.gaTabTitle || undefined });
      return (
        <div className="tabs">
          {sortingElement && <div className="tabs-menu-container margin-left-0">{sortingElement}</div>}
          <section className="tabs-panel-container ">
            <div className="tabs-panel is-active">{bindFeaturedGird(tabsData[0].listing, index, null)}</div>
          </section>
        </div>
      );
    } else {
      return bindTabs(
        tabsData,
        data.enableDropDownOnMobile ? data.enableDropDownOnMobile : false,
        filterTitle,
        hideFilterDropdownOnMobile,
        sortingElement,
      );
    }
  };

  const bindResizeOnTabChange = () => {
    let resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
  };

  /**
   * update hash on tab change
   */
  const setActiveTabHash = (index, tabData) => {
    if (tabData[index] && tabData[index].type) {
      window.location.hash = tabData[index].type;
    }
    if (tabData?.[index]?.title) {
      GTMDataOnClickTab({
        name: tabData?.[index]?.title,
        [UIConfig.ga4Constants.ELEMENTTEXT]: tabData?.[index]?.title,
      });
    }
  };

  /**
   * bindTabs   binds tabs for featured list
   * @param    {[Array]} tabsData array of objects for which featured listing needs to be created
   * @return   {[Object]} JSX for tabs containing featured grid
   */
  const bindTabs = (tabsData, enableDropDownOnMobile, filterTitle, hideFilterDropdownOnMobile, sortingElement) => {
    const tabs = createTabsHTML(tabsData);
    const dropDownOnMobile = enableDdnOnMobile || enableDropDownOnMobile;
    if (!tabs.headerHTML.length && !tabs.panelHTML.length) {
      return <div></div>;
    }
    return (
      <Tabs
        defaultActiveTab={activeTab}
        dropDownOnMobile={dropDownOnMobile}
        onAfterChange={bindResizeOnTabChange}
        isUpdateDropdownProps={isUpdateDropdownProps}
        onBeforeChange={(index) => setActiveTabHash(index, tabsData)}
        resetActiveTab={true}
      >
        <TabHeaders
          title={filterTitle}
          hideFilterDropdownOnMobile={isSwad ? true : hideFilterDropdownOnMobile}
          sortingElement={sortingElement}
        >
          {tabs.headerHTML}
        </TabHeaders>
        <TabPanels>{tabs.panelHTML}</TabPanels>
      </Tabs>
    );
  };

  /**
   * createTabsHTML   creates HTML for tabs component
   * @param    {[Array]} tabsData array of objects for which tabs needs to be created
   * @return   {[Object]} JSX for tabHeadrs and tabPanels
   */
  const createTabsHTML = (tabsData) => {
    const tabsHTML = {
      headerHTML: [],
      panelHTML: [],
    };
    tabsData.forEach((tabData, index) => {
      tabsHTML.headerHTML.push(bindTabHeader(tabData, index));
      tabData.listing && tabsHTML.panelHTML.push(bindTabPanel(tabData.listing, index, tabData.type));
    });
    return tabsHTML;
  };

  /**
   * bindTabHeader   binds tabs headers for featured list
   * @param    {[Object]} tabData object for which tabs header needs to be created
   * @param    {[Number]} index index of tabs header
   * @return   {[Object]} JSX for tabs header
   */
  const bindTabHeader = (tabData, index) => {
    const pjTabData =
      pjData?.purchaseJourneyData?.tabs?.length &&
      pjData.purchaseJourneyData.tabs.find((tab) => tabData?.type?.includes(tab?.coveoValue));
    tabData.title && tabsAnalyticsData.push({ ...tabData, gaTabTitle: pjTabData?.gaTabTitle || '' });
    return (
      <Header index={index} key={index} classes={'simple_accessibility'}>
        <DynamicContent tagName="span" innerHtml={tabData.title} />
      </Header>
    );
  };

  /**
   * bindTabPanel   binds tabs panel for featured list
   * @param    {[Object]} tabPanel object for which tab panel needs to be created
   * @param    {[Number]} index index of tabs panel
   * @return   {[Object]} JSX for tabs panel
   */
  const bindTabPanel = (tabPanel, index, type) => (
    <Panel index={index} key={index}>
      {bindFeaturedGird(tabPanel, index, type)}
    </Panel>
  );
  /*
    This function will returnd if the checkbox is checked in CMS 
    also the gridVariation should be equals to two-grid-layout, and the length of the tiles should be odd
   */
  const enableFullWidth = useMemo(
    () => isDynamicListEnabled && !!(tabData && tabData[activeTab]?.listing && tabData[activeTab].listing.length % 2),
    [activeTab, isDynamicListEnabled, tabData],
  );

  /*
    Here we made a refactoring of the classnames to pass to this component 
   */
  const getSecondaryClasses = () => {
    const basicClasses = ` ${variant} ${gridVariation ?? ''}`;

    const normalClasses = ` ${basicClasses} ${
      variaton === UIConfig.classes.featureImage || filterVariant === UIConfig.classes.featureImage
        ? (!isDynamicListEnabled && UIConfig.classes.vFullWidth) ?? ''
        : variaton
    }`;

    if (enableFullWidth) return ` ${basicClasses} ${UIConfig.classes.vFullWidth}`;

    return normalClasses;
  };
  const tabFilterListingClasses = () => {
    const fixClasses = `component c-tab-feature-list gradient${randNum}`;
    return `${fixClasses} ${getSecondaryClasses()}`;
  };

  /**
   * bindFeaturedGird   binds tiles for a panel
   * @param    {[Array]} tabPanelTiles array of tiles to be bound
   * @return   {[Object]} JSX for feature grid component
   */
  const bindFeaturedGird = (tabPanelTiles, index, type) => {
    if (!tabPanelTiles) {
      return null;
    }
    return (
      <FeatureGrid
        soldoutCtaLabel={propData?.soldoutText}
        tiles={tabPanelTiles}
        showDots={data.showDots}
        tilesToShowInitially={data.tilesToShowInitially}
        viewMoreLabel={data.viewMoreLabel}
        redirectCta={data.redirectCta}
        variation={variaton}
        maxLengthForDescription={propData?.maxLengthForDescription}
        showExtraField={showExtraField}
        filterVariant={filterVariant}
        showInfoIcon={showInfoIcon}
        tileLabels={tileLabels}
        variant={variant}
        enablePJPopup={enablePJPopup}
        invokePJCallback={invokePJCallback}
        invokePackageJourney={invokePackageJourney}
        carouselData={carouselData}
        index={index}
        infoAriaLabel={infoAriaLabel}
        disableLazyLoadImage={disableLazyLoadImage}
        buyNowLabel={buyNowLabel}
        theme={theme}
        includeTabs={propData && propData?.includeTabs ? propData.includeTabs : ''}
        includeAllTab={propData && propData?.includeAllTab ? propData.includeAllTab : ''}
        tabType={type}
        enableWishList={propData && propData.enableWishList}
        services={propData && propData.services}
        loginCtaUrl={propData && propData.loginCtaUrl}
        isDynamicListEnabled={isDynamicListEnabled}
        pjData={pjData}
        addToCartCallback={addToCartCallback}
        getDiscountedProducts={getDiscountedProducts}
        cartData={cartData}
        businessErrors={businessErrors}
      />
    );
  };

  /**
   * render  Used to render the JSX of the component
   * @param    {[Void]} function does not accept anything.
   * @return   {[Object]} JSX of the component
   */

  let randNum = randomNumber(3);
  const onSortingChange = (tabData, e, sortingData, sortByDate) => {
    let operation;
    sortingData.options.map((option) => {
      if (option.value === e.value) {
        operation = option.sortOrder;
      }
    });
    if (Array.isArray(tabData)) {
      if (operation === 'default') {
        setTabData(shortTabsSorting(tabData, 'sortField', true));
      } else if (operation === 'asc') {
        if (sortByDate) {
          setTabData(shortTabsByDateSorting(tabData, true));
        } else {
          setTabData(shortTabsSorting(tabData, 'title', true));
        }
      } else if (operation === 'desc') {
        if (sortByDate) {
          setTabData(shortTabsByDateSorting(tabData, false));
        } else {
          setTabData(shortTabsSorting(tabData, 'title', false));
        }
      }
    }
  };
  const getSortingElement = (propData, onSortingChange) => {
    return (
      propData?.sortingData &&
      propData?.sortingData?.options && (
        <div className="form-control sort-wrapper">
          <p className="form-label">{propData.sortingData.label}</p>
          <DropDown
            ariaLabel="sorting-option"
            callBackFunction={(e) => {
              onSortingChange(tabData, e, propData?.sortingData, propData?.sortByDate);
            }}
            listItems={propData?.sortingData.options}
            showSelectedValue="true"
          />
        </div>
      )
    );
  };
  try {
    if (!data) {
      return null;
    }
    return (
      <div className={tabFilterListingClasses()} data-c-render="server-only" data-c-name="TabFeatureList">
        <DynamicContent
          tagName="style"
          innerHtml={`
                .component.c-tab-feature-list.gradient${randNum} .w--top::before {
                    background: ${data.gradient};
                }
            `}
        />
        <div className="w--container">
          <div className="w--top">
            <span className="c-tab-feature-list--top-curve">
              <SvgSprite id="icn-default-smile-top" styleClass="icn-default-smile-top" />
            </span>
            <div className="w--content">
              <div className="c-tab-feature-list--header">
                {data.shortTitle ? (
                  <DynamicContent tagName="p" attrs={{ className: 'heading-7' }} innerHtml={data.shortTitle} />
                ) : null}
                <DynamicContent tagName="h2" attrs={{ className: 'heading-1' }} innerHtml={data.title} />
              </div>
            </div>
            <span className="svgCurve">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1366 138"
                className="inline-svg"
                preserveAspectRatio="xMinYMin slice"
              >
                <g>
                  <defs>
                    <linearGradient x1="0%" x2="100%">
                      <stop offset="0%" />
                      <stop offset="100%" />
                    </linearGradient>
                  </defs>
                  <path
                    fill="url(#svgFeatureCurve)"
                    d="M0 0h1365.96l-.042 35.91C1163.482 103.97 936.154 138 683.936 138 431.716 138 203.738 104 0 36V0z"
                  />
                </g>
              </svg>
            </span>
          </div>
          <div className="w--middle">
            <div className="w--content">
              <div className="c-tab-feature-list--body">
                {tabData &&
                  bindTabFeatureList(
                    tabData,
                    index,
                    propData?.filterTitle,
                    propData?.hideFilterDropdownOnMobile,
                    getSortingElement(propData, onSortingChange),
                  )}
              </div>
            </div>
          </div>
          {resolvePath(data, 'ctaLink.href') && resolvePath(data, 'ctaLink.label') && (
            <div className="w--bottom">
              <div className="w--content">
                <div className="btn btn-hero-cta">
                  <AnchorLink link={{ ...data.ctaLink }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'TabFeatureList', variant);
  }
};

export default React.memo(TabFeatureList);

/**
 * Used to define the proptypes that will be received by the component.
 */
TabFeatureList.propTypes = {
  data: PropTypes.shape({
    shortTitle: PropTypes.string,
    title: PropTypes.string,
    variation: PropTypes.string,
    tabList: PropTypes.arrayOf(PropTypes.object),
    ctaLink: PropTypes.shape({
      html: PropTypes.string.isRequired,
      copy: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      target: PropTypes.string,
    }),
  }),
};
