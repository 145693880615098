import React from 'react';
import classNames from 'classnames';

import UIConfig from '../../../../common/UIConfig';
import {
  isDisabledProduct,
  parseIfJsonString,
  toTwoDecimalPlaces,
  isF1SeatedTypeProduct,
  isEventTypeProduct,
  isMatchTenant,
  checkTenant,
  checkParksTenants,
  isTicketUpgradePayment,
} from '../../../../common/utility';
import GTMData from '../../b2c-purchase-journey/gtm-data';

import { DynamicContent, Spinner } from '../../../presentation/base';
import { isFastPass, getUpdateQuantity, openPopAddOns, getDeleteButton } from './grouped-product-row';

const loadItemRowView = (
  item,
  data,
  currency,
  deleteOverlay,
  deleteProduct,
  showClassType,
  addGuestCount,
  isCartPaymentView = false,
  isRenewAnnualPass,
  propData,
  isSwad,
) => {
  const keyMap = data.stringMapping ? data.stringMapping : {},
    isDrvExp =
      !item.showQtySpinnerInCart &&
      item.otherReason &&
      (item.otherReason === keyMap.secondary || item.otherReason === keyMap.primary),
    isFwadTenant = isMatchTenant(UIConfig.tenants.fwad),
    itemCategory = item && item.category && item.category[0] ? item.category[0].toLowerCase() : '',
    itemValue = item?.ticketVal?.toLowerCase(),
    isPassenger =
      UIConfig.passengerItemCheck.indexOf(itemCategory) >= 0 || UIConfig.passengerItemCheck.indexOf(itemValue) >= 0,
    passengerCount = isPassenger ? addGuestCount + 1 : addGuestCount,
    addGuestLbl = passengerCount > 1 ? keyMap.passengers : keyMap.passenger,
    driver = keyMap.driver;

  const ticketItemInfo = () => {
    const elements = [];

    if (isQuantRequired(item, isDrvExp, showClassType)) {
      isRenewAnnualPass
        ? elements.push(
            <DynamicContent
              tagName="p"
              attrs={{ className: 'heading-4 ticket-holder' }}
              innerHtml={`${item.quantity ? item.quantity : ''} ${item.classType ? item.classType : ''}`}
            />,
          )
        : item.classTypeName &&
          elements.push(
            <DynamicContent
              tagName="p"
              attrs={{ className: 'heading-4 ticket-holder' }}
              innerHtml={`${item.classTypeName ? item.classTypeName : ''} <p class ="display-qaunt body-1">${
                !data.editable && item.quantity ? 'x ' + item.quantity : ''
              }</p>`}
            />,
          );

      if (isCartPaymentView) {
        elements.push(
          <DynamicContent
            tagName="p"
            attrs={{ className: 'price' }}
            innerHtml={`${currency} ${
              isSwad
                ? (item.price.gross / item.quantity).toLocaleString('en', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : toTwoDecimalPlaces(item.price.gross / item.quantity)
            }`}
          />,
        );
      }
    }
    if (isDrvExp) {
      if (!isFwadTenant && item.license)
        elements.push(
          <DynamicContent
            tagName="div"
            attrs={{ className: 'price' }}
            innerHtml={item.license + ' ' + keyMap.license}
          />,
        );
      if (!isPassenger && isFwadTenant) {
        elements.push(
          <DynamicContent
            tagName="div"
            attrs={{ className: 'price driver-column' }}
            innerHtml={`<strong>1x ${driver}</strong>`}
          />,
        );
      }
      if (passengerCount && isFwadTenant) {
        elements.push(
          <DynamicContent
            tagName="div"
            attrs={{ className: 'price passenger-column' }}
            innerHtml={`${passengerCount}x ${addGuestLbl}`}
          />,
        );
      } else if (addGuestCount) {
        elements.push(
          <DynamicContent
            tagName="div"
            attrs={{ className: 'price ' }}
            innerHtml={`${addGuestCount} x ${addGuestLbl}`}
          />,
        );
      }
    }

    if (data.cartLabels.discountInfo) {
      let discountValue = '';
      if (item.discount.computed) discountValue = `${currency} ${toTwoDecimalPlaces(item.discount.computed)}`;
      elements.push(
        <DynamicContent
          tagName="p"
          attrs={{ className: 'body-2 ' + (parseInt(item.discount.actualPerc, 10) ? '' : 'no-discount') }}
          innerHtml={`${data.cartLabels.discountInfo.replace('{0}', item.discount.actualPerc)} </br> ${discountValue}`}
        />,
      );
    }

    return elements.map((el) => el);
  };

  const grossTicketPrice = isMatchTenant(UIConfig.tenants.ymc) && parseInt(item.gross);
  return data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.miniCart ||
    data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart ? (
    grossTicketPrice === 0 ? (
      ''
    ) : (
      <div className="ticket-item">
        <span className="ticket-type" tabIndex="0">
          {(!isSwad && isQuantRequired(item, isDrvExp, showClassType)) ||
          (isSwad && isQuantRequired(item, isDrvExp, showClassType) && item.classTypeName) ? (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'heading-4' }}
              innerHtml={`${item.classTypeName ? item.classTypeName : ''} <p class ="display-qaunt body-1">${
                !data.editable && item.quantity ? 'x ' + item.quantity : ''
              }</p>`}
            />
          ) : (
            ''
          )}
          {!isDrvExp ? (
            item.hasOwnProperty('baseProductPrice') && item.baseProductPrice ? (
              <div>
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'price' }}
                  innerHtml={`${currency} ${toTwoDecimalPlaces(item.price.gross / item.quantity)}`}
                />
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'base-product-price' }}
                  innerHtml={item.baseProductPrice}
                />
              </div>
            ) : (
              <DynamicContent
                tagName="p"
                attrs={{ className: 'price' }}
                innerHtml={`${currency} ${
                  isSwad
                    ? (item.price.gross / item.quantity).toLocaleString('en', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : toTwoDecimalPlaces(item.price.gross / item.quantity)
                }`}
              />
            )
          ) : (
            <div>
              {!isPassenger && isFwadTenant ? (
                <DynamicContent tagName="div" attrs={{ className: 'price driver-column' }} innerHtml={`1x ${driver}`} />
              ) : null}
              {passengerCount ? (
                <DynamicContent
                  tagName="div"
                  attrs={{ className: 'price passenger-column' }}
                  innerHtml={`${passengerCount}x ${addGuestLbl}`}
                />
              ) : null}
            </div>
          )}
        </span>
      </div>
    )
  ) : (
    <div className="ticket-item">
      <div className="ticket-type" tabIndex="0">
        {ticketItemInfo()}
        {!isCartPaymentView && loadDeleteButton(data, deleteProduct, item, deleteOverlay)}
      </div>
      {!isDrvExp && !isCartPaymentView && (
        <DynamicContent
          tagName="p"
          attrs={{ className: 'price' }}
          innerHtml={`${currency} ${toTwoDecimalPlaces(item.price.gross / item.quantity)}`}
        />
      )}
    </div>
  );
};

const checkRecommendationMaxLimitError = (propData, product) => {
  if (propData && propData.isRecommendationControlled) {
    const prodArr = propData.addOnsErrorProdArr;
    if (prodArr && prodArr.length && prodArr.indexOf(product.productIdType) !== -1) {
      return true;
    }
  }
  return false;
};

/**
 * This function will calculate the min max qty of spinner
 * @param {*} item product added in cart
 * @param {*} data json data for cart
 * @param {*} cartTotalQuantity commulative current cart quantity
 */
const getMinMaxQuantity = (item, data, cartTotalQuantity) => {
  let min = data.minQuantity,
    max = getMaxQuantity(item, data.maxQuantity, cartTotalQuantity, data.maxCartQuantity) + 1,
    spinnerIncreaseDecrease = 1;
  if (data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart) {
    max = getMaxQuantity(item, data.maxQuantity, cartTotalQuantity, data.maxCartQuantity);
  }
  if (item.maxAvailableTickets) {
    max = Math.min(max, item.maxAvailableTickets);
  }
  if (data.minMaxQuantityMap) {
    const key = data.minMaxQuantityMap.cartKey,
      prod = item[key] && item[key].toString(),
      minMap = data.minMaxQuantityMap.minQuantityMap,
      maxMap = data.minMaxQuantityMap.maxQuantityMap,
      spinnerMappings = data.minMaxQuantityMap.spinnerMappings;
    if (minMap && minMap[prod]) {
      min = Math.max(min, minMap[prod]);
    }
    if (maxMap && maxMap[prod]) {
      max = Math.min(max, maxMap[prod]);
    }
    if (spinnerMappings && spinnerMappings[prod]) {
      spinnerIncreaseDecrease = +spinnerMappings[prod];
    }
  }
  if (item && item.timeSlotMaxAvailableQty) {
    max = item.timeSlotMaxAvailableQty;
  }
  return {
    min,
    max,
    spinnerIncreaseDecrease,
  };
};

const checkDeleteOverlay = (data, deleteProduct, item, deleteOverlay, propData) => {
  const checkValidation = propData && propData.isRecommendationControlled && item;
  const isReAllowedForAll = propData && propData.isRecommendationAllowedForAll;
  const quantity = -item.groupedQuantity;
  if (checkValidation && (isEventTypeProduct(item) || isReAllowedForAll)) {
    const { cartData, addOnsErrorProdArr, recommendationActions } = propData;
    let addOnsErrObj = recommendationActions.getAddOnsProdsOnParentQuantityChange(
      item,
      cartData,
      addOnsErrorProdArr,
      quantity,
    );
    if (addOnsErrObj && addOnsErrObj.hasOwnProperty('isOpenPopOverlay') && addOnsErrObj.isOpenPopOverlay) {
      openPopAddOns(propData);
    } else {
      deleteOverlay(data, deleteProduct, item);
    }
  } else if (!item.eventType && item.relatedProductIds && isMatchTenant(UIConfig.tenants.ymc)) {
    const { cartData, addOnsErrorProdArr, recommendationActions } = propData;
    let addOnsErrObj = recommendationActions.getAddOnsProdsOnParent(item, cartData, addOnsErrorProdArr, quantity);
    if (addOnsErrObj && addOnsErrObj.hasOwnProperty('isOpenPopOverlay') && addOnsErrObj.isOpenPopOverlay) {
      openPopAddOns(propData);
    } else {
      deleteOverlay(data, deleteProduct, item);
    }
  } else {
    deleteOverlay(data, deleteProduct, item);
  }
};

const getMaxQuantity = (item, maxQuantity, cartTotalQuantity, maxCartQuantity) => {
  const updateMaxQuantity = parseIfJsonString(item.otherReason).maxGrouppedQuantity || maxQuantity;
  return item.groupType === UIConfig.b2c.purchaseJourney.additionalProductCode
    ? Math.min(
        Number(item.otherReason),
        Number(item.quantity) + Number(updateMaxQuantity) - Number(item.groupedQuantity),
        Number(item.quantity) + Number(maxCartQuantity) - Number(cartTotalQuantity),
      )
    : Math.min(
        Number(item.quantity) + Number(updateMaxQuantity) - Number(item.groupedQuantity),
        Number(item.quantity) + Number(maxCartQuantity) - Number(cartTotalQuantity),
      );
};

const loadDeleteButton = (data, deleteProduct, item, deleteOverlay, isCartPaymentView, propData) => {
  const keyMap = data.stringMapping || {},
    isDrvExp =
      item &&
      !item.showQtySpinnerInCart &&
      item.otherReason &&
      (item.otherReason === keyMap.secondary || item.otherReason === keyMap.primary),
    isFwadTenant = isMatchTenant(UIConfig.tenants.fwad),
    itemCategory = item && item.category && item.category[0] ? item.category[0].toLowerCase() : '',
    isPassenger = UIConfig.passengerItemCheck.indexOf(itemCategory) >= 0,
    isExtraPassengers = item && item.groupedQuantity > 1,
    isDeleteBtnPosChange = isFwadTenant && isDrvExp && !isPassenger && isExtraPassengers;
  const disabledeleteBtn = item.isIncreaseDecreaseEnabled || false;

  return getDeleteButton(
    data.editable,
    () => checkDeleteOverlay(data, deleteProduct, item, deleteOverlay, propData),
    item.productName,
    data.deleteIconAriaLabel,
    propData,
    isDeleteBtnPosChange,
    disabledeleteBtn,
  );
};

const isQuantRequired = (item, isDrvExp, showClassType) => {
  if (!item?.productType && isTicketUpgradePayment()) {
    return true;
  }
  let flag = !isFastPass({ products: [item] }) && !isDrvExp && item?.productType;
  let showClassTypeFlag = false;
  if (isEventTypeProduct(item)) {
    flag = true;
    showClassTypeFlag = true;
  } else if (
    item?.productType === UIConfig?.parksTicketUpgrade?.addonWBW &&
    !item.category?.includes(UIConfig?.parksTicketUpgrade?.QuickPass)
  ) {
    showClassTypeFlag = parseIfJsonString(item?.otherReason)?.grouped && showClassType(item?.productType?.toString());
  } else {
    showClassTypeFlag = showClassType(item?.productType?.toString());
  }

  return flag && showClassTypeFlag;
};

/**
 * CreateItemRow function creates a row inside each ticket type
 * @param    {[Object]} item [data required to render the view].
 * @param    {[String]} currency [function to open the overlay].
 * @param    {[Object]} data [data for labels].
 * @param    {[function]} updateQuantity [callback function for updating the quantity].
 * @param    {[function]} deleteProduct [callback functions for deleting the product].
 * @param    {[Object]} deleteOverlay [delete Overlay]
 * @param    {[Number]} cartTotalQuantity [Total Quantity of cart]
 * @param    {[Boolean]} showClassType [whether to show class type or not - Adult and Junior]
 * @param    {{Number}} addGuestCount [Number of Guest to add]
 * @param    {[Boolean]} isCartPaymentView [Create Item Row in 2 Step Purchase Journey Mode]
 * @param    {[Boolean]} isRenewAnnualPass [Create Row for Renew Annual Pass Scenario]
 * @return   {[Object]} returns html .
 */
const CreateItemRow = ({
  item,
  currency,
  data,
  updateQuantity,
  deleteProduct,
  deleteOverlay,
  cartTotalQuantity,
  showClassType,
  addGuestCount,
  isCartPaymentView,
  isRenewAnnualPass,
  propData,
}) => {
  const keyMap = data.stringMapping ? data.stringMapping : {},
    isDrvExp =
      !item.showQtySpinnerInCart &&
      item.otherReason &&
      (item.otherReason === keyMap.secondary || item.otherReason === keyMap.primary),
    isAddGuest = item.otherReason && item.otherReason === keyMap.addOnGuest,
    minMaxQty = getMinMaxQuantity(item, data, cartTotalQuantity);
  const itemOtherReason = parseIfJsonString(item.otherReason);
  const maxQuantityForSpinner = itemOtherReason.maxGrouppedQuantity || data.maxQuantity;
  const isEarlyBird = data.cartLabels.discountInfo;
  const isRecommendationMaxError = checkRecommendationMaxLimitError(propData, item);
  const isSeatedTypeProduct = isF1SeatedTypeProduct(item);
  const ticketShowClasses = classNames({
    hide: isAddGuest,
    show: !isCartPaymentView,
    'max-limit-error': isRecommendationMaxError,
  });
  const ticketInfoClasses = classNames('ticket-info', {
    'no-discount-hide': !parseInt(item.discount.actualPerc, 10),
  });
  const ticketSubSectionClasses = classNames({
    'drv-exp ticket-sub-section': isDrvExp,
    'ticket-sub-section': !isDrvExp,
    'early-bird-wrap': isEarlyBird,
  });
  const GAForIncreaseDecrease = (isIncrease, isJunior, quantity) => {
    if (isIncrease) {
      GTMData.push('addToCart', {
        products: [{ ...item, quantity: quantity || item.quantity, spinnerQty: true }],
        isFromCart: true,
        isJunior,
        promotions: propData?.cartData?.cart?.promotions,
      });
    } else {
      GTMData.push('removeCartItem', {
        products: [{ ...item, quantity: quantity || item.quantity, spinnerQty: true }],
        isFromCart: true,
        isJunior,
        multiProd: null,
        promotions: propData?.cartData?.cart?.promotions,
      });
    }
  };
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isParks = checkParksTenants();
  return (
    <div className={ticketShowClasses}>
      {!isAddGuest && (
        <div className={ticketSubSectionClasses}>
          {loadItemRowView(
            item,
            data,
            currency,
            deleteOverlay,
            deleteProduct,
            showClassType,
            addGuestCount,
            isCartPaymentView,
            isRenewAnnualPass,
            propData,
            isSwad,
          )}
          {isRenewAnnualPass ? (
            ''
          ) : (
            <div className={ticketInfoClasses}>
              {data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.miniCart ||
              data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart ? (
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'body-2 ' + (parseInt(item.discount.actualPerc, 10) ? '' : 'no-discount') }}
                  innerHtml={`${data.cartLabels.discountInfo.replace('{0}', item.discount.actualPerc)} </br>
                            (${currency} ${toTwoDecimalPlaces(item.discount.computed)})`}
                />
              ) : null}
              {!isSeatedTypeProduct && data.editable !== false && !isDrvExp && (
                <Spinner
                  min={item.minCount || minMaxQty.min}
                  isInputDisabled="true"
                  quantity={item.quantity}
                  id={item.productId}
                  name={item.productIdType}
                  ariaLabel={item.classTypeName}
                  inValidCart={itemOtherReason && itemOtherReason.nonEditable}
                  max={item.maxCount || minMaxQty.max}
                  increaseDecreaseBy={item.increaseDecreaseBy || minMaxQty.spinnerIncreaseDecrease}
                  GAForIncreaseDecrease={isSwad || isParks ? GAForIncreaseDecrease : null}
                  checkQuantity={(val) =>
                    getUpdateQuantity(
                      item,
                      propData,
                      Number(val) - Number(item.quantity),
                      maxQuantityForSpinner,
                      updateQuantity,
                    )
                  }
                  isSpinnerDisabled={item.isIncreaseDecreaseEnabled || propData?.cart?.isTicketUpgradeCart || false}
                  ariaLabelDecrease={data.ariaLabelDecrease ? data.ariaLabelDecrease : ''}
                  ariaLabelIncrease={data.ariaLabelIncrease ? data.ariaLabelIncrease : ''}
                  ticketCounterText={data.ticketCounterText ? data.ticketCounterText : ''}
                />
              )}
              {isSeatedTypeProduct && data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart && (
                <DynamicContent tagName="p" attrs={{ className: 'spinner-count-quantity' }} innerHtml={item.quantity} />
              )}
              {isRecommendationMaxError && (
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'spinner-max-limit-error' }}
                  innerHtml={data.cartLabels.maxLimitErrorLabel}
                />
              )}
              {isCartPaymentView &&
                loadDeleteButton(data, deleteProduct, item, deleteOverlay, isCartPaymentView, propData)}
              {data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.miniCart &&
                loadDeleteButton(data, deleteProduct, item, deleteOverlay)}
              {data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart &&
                loadDeleteButton(data, deleteProduct, item, deleteOverlay, isCartPaymentView, propData)}
            </div>
          )}
          {data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.myCart ? (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'body-2 ' + (parseInt(item.discount.actualPerc, 10) ? '' : 'no-discount') }}
              innerHtml={`${data.cartLabels.discountInfo.replace('{0}', item.discount.actualPerc)} </br>
                            (${currency} ${toTwoDecimalPlaces(item.discount.computed)})`}
            />
          ) : null}
        </div>
      )}
      {isDisabledProduct(item) && (
        <DynamicContent
          tagName="div"
          attrs={{ className: 'body-2 ticket-section--error' }}
          innerHtml={data.businessErrors[UIConfig.errorCodes.disabledProduct]}
        />
      )}
    </div>
  );
};

export default CreateItemRow;
