/*
 * This file contains code for Media Carousel Wrapper component, it renders Media Carousel Component
 * @licensor  Miral
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MediaCarousel from './media-carousel-component';
import { detectMobile, isEnterPressed, resolvePath } from '../../../common/utility';
import { DynamicContent, SvgSprite } from '../../presentation/base';
import ThirdPartyWidget from '../../presentation/third-party-widget';
import { logComponentRenderingError } from '../../../common/logger';
import QuickLinks from '../../presentation/quick-links';
import './media-carousel-wrapper.scss';
import { memo } from 'react';

const MediaCarouselWrapper = (props) => {
  const { data, isSmartHeroCarousel } = props;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [videoStatus, setVideoStatus] = useState('play');
  const [isUpdateSlide, setIsUpdateSlide] = useState(false);
  const isOverlayOpened = useRef(false);

  /*
    *Callback function calls on slide change
    @param currentSlide int
    */
  const onSlideChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
    setVideoStatus('stop');
    setIsUpdateSlide(false);
  };

  /*
    *Callback function calls on media click
    @param currentSlide int
    */
  const onMediaClick = (currentSlide) => {
    onSlideChange(currentSlide);
    !data.variant.includes('hero-carousel') && showOverlay();
  };

  useEffect(() => {
    window.PubSub.subscribe('closeOverlay', () => {
      isOverlayOpened.current = false;
      setIsUpdateSlide(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   *open overlay
   */
  const showOverlay = () => {
    isOverlayOpened.current = true;
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      dataToAppend: overlayHtml() || '',
      customClass: 'media-carousel-overlay',
    });
  };

  /*
   *close overlay
   */
  const closeOverlay = (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && (isEnterPressed(e) || e.which === 27))) {
      setIsUpdateSlide(true);
      isOverlayOpened.current = false;

      window.PubSub.publish('toggleOverlayState', {
        shouldOpen: false,
        dataToAppend: '',
        customClass: 'media-carousel-overlay',
      });
    }
  };

  /*
   *html for overlay
   */
  const overlayHtml = () => {
    return (
      <div className="overlay-carousel-container">
        <div className="overlay--close-button" onClick={closeOverlay} onKeyDown={closeOverlay} tabIndex="0">
          <SvgSprite id="icn-close" viewBox="0 0 18 18" />
        </div>
        <MediaCarousel
          data={data}
          overlay={true}
          className="overlay-carousel"
          onSlideChange={onSlideChange}
          currentSlide={currentSlide}
          videoStatus={videoStatus}
          addContrast={props.addContrast}
        />
        {!detectMobile() && data.mediaItems.length > 1 && (
          <MediaCarousel
            data={data}
            overlay={true}
            className="overlay-thumbnail"
            thumbnail={true}
            onSlideChange={onSlideChange}
            currentSlide={currentSlide}
            onMediaClick={onSlideChange}
            addContrast={props.addContrast}
          />
        )}
      </div>
    );
  };

  try {
    if (!resolvePath(data, 'mediaItems.length')) {
      return logComponentRenderingError(new Error('Missing MediaItems'), 'MediaCarouselWrapper', data.variant);
    }

    const mediaItems = data.mediaItems[0];
    const heroBelowImage = data.variant.includes('hero-carousel-text-below-image');

    return (
      <div
        data-c-render="universal"
        className={classNames('c-media-carousel-wrapper component', data.theme, data.variant, {
          'from-hero-component-container': data.heroComponent,
        })}
      >
        {isOverlayOpened.current && showOverlay()}
        <div className="media-carousel--content">
          <div
            className={classNames(
              'w--content w-media-content',
              data.textAlignment ? `text-align-${data.textAlignment}` : '',
            )}
          >
            {data.componentTitle && (
              <DynamicContent
                tagName="strong"
                attrs={{ className: 'heading-2 media-carousel--title' }}
                innerHtml={data.componentTitle}
              />
            )}
            <MediaCarousel
              data={data}
              onSlideChange={onSlideChange}
              onMediaClick={onMediaClick}
              currentSlide={currentSlide}
              isUpdateSlide={isUpdateSlide}
              addContrast={props.addContrast || data.addContrast}
              heroBelowImage={heroBelowImage}
              isSmartHeroCarousel={isSmartHeroCarousel}
            />
            {!isSmartHeroCarousel && data.thirdPartyWidget && data.thirdPartyWidget.length > 0 && !heroBelowImage && (
              <ThirdPartyWidget
                data={data.thirdPartyWidget}
                wishlistData={{
                  title: mediaItems.imageTitle,
                  desc: mediaItems.bodyCopy,
                  image: mediaItems.imageInfo.desktopImage.src,
                }}
              />
            )}
            {data.componentDescription && (
              <DynamicContent
                tagName="div"
                attrs={{ className: 'component-description' }}
                innerHtml={data.componentDescription}
              />
            )}
          </div>
          {!detectMobile() && <QuickLinks quickLinks={data.quickLinks} />}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'MediaCarouselWrapper', data.variant);
  }
};

export default memo(MediaCarouselWrapper);

MediaCarouselWrapper.propTypes = {
  data: PropTypes.shape({
    mediaItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    carousel: PropTypes.object,
    variant: PropTypes.string,
    componentTitle: PropTypes.string,
    componentDescription: PropTypes.string,
    textAlignment: PropTypes.string,
    aspectRatio: PropTypes.string,
    thirdPartyWidget: PropTypes.array,
    services: PropTypes.object,
    addContrast: PropTypes.string,
    registerLabel: PropTypes.string,
  }),
  addContrast: PropTypes.string,
};
