/*
 * editorial-list-components.js
 * This file contains code for Editorial List component, it renders 2 RTE fields(Title and BodyCopy)
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';

import { DynamicContent } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';

/**
 * EditorialList is a component which renders the Editorial list items(e.g. is basically combination of 2 RTE fields)
 * @param    {object}    data     having data of Editorial items
 */
const EditorialList = ({ data }) => {
  if (!data) {
    return null;
  }

  try {
    return (
      <div className={'component c-editorial-list ' + data.variant + ''} data-c-render="server-only">
        <div className="w--content">
          <dl className="editorial-list-container">
            {data.listItems &&
              data.listItems.reduce((acc, item, idx) => {
                acc.push([
                  <dt className="c-editorial-list--title" key={`title-${idx}`}>
                    <DynamicContent tagName="div" innerHtml={item.title} attrs={{ className: 'heading-4' }} />
                  </dt>,
                  <dd className="c-editorial-list--bodycopy body-copy-4" key={`bodycopy-${idx}`}>
                    <DynamicContent
                      tagName="div"
                      attrs={{ className: 'c-editorial-list--bodycopy--rte' }}
                      innerHtml={item.bodyCopy}
                    />
                  </dd>,
                ]);
                return acc;
              }, [])}
          </dl>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'EditorialList', data.variant);
  }
};

/*
 * Used to define the proptypes that will be received by the component.
 */
EditorialList.propTypes = {
  data: PropTypes.shape({
    listItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
};

export default EditorialList;
