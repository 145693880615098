import React, { useState } from 'react';
import { logComponentRenderingError } from '../../../../common/logger';
import { DropDown, Input } from '..';
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import UIConfig from '../../../../common/UIConfig';
import { getJSSFieldSectionErrors, getFieldValue } from '../../../../common/forms-utility';
import { resolvePath } from '../../../../common/utility';
import { calculateBMI } from '../../../../common/forms-utility';

const BMIField = (props) => {
  const fields = resolvePath(props, 'field.fields', []);
  const [bmiHeight, setBMIHeight] = useState('');
  const [bmiWeight, setBMIWeight] = useState('');
  const [BodyMassIndex, setBodyMassIndex] = useState('');

  const dropDownHandler = ({ dropDownSelectedItem, fieldName }) => {
    const { weightInKg, heightInCm } = UIConfig.jssForms.fieldName;
    const selectedValue = resolvePath(dropDownSelectedItem, 'value', '');
    if (weightInKg === fieldName && selectedValue) {
      setBMIWeight(selectedValue);
      if (bmiHeight) {
        setBodyMassIndex(calculateBMI(bmiHeight, selectedValue));
      }
    } else if (heightInCm === fieldName && selectedValue) {
      setBMIHeight(selectedValue);
      if (bmiWeight) {
        setBodyMassIndex(calculateBMI(selectedValue, bmiWeight));
      }
    }
  };

  const handleBMIFields = () => {
    const { dropDownId } = UIConfig.jssForms.fields;
    return fields.map((field) => {
      const errors = getJSSFieldSectionErrors(field, props.errors);
      const typeId = field.model.fieldTypeItemId;
      const fieldValue = getFieldValue(field, props.apiFieldValue);
      if (typeId === FieldTypes.DropdownList || typeId === dropDownId) {
        return (
          <DropDown
            {...props}
            field={field}
            dropDownHandler={dropDownHandler}
            errors={errors}
            apiFieldValue={fieldValue}
          />
        );
      } else {
        return <Input {...props} field={field} errors={errors} apiFieldValue={BodyMassIndex} isReadOnly={true} />;
      }
    });
  };

  try {
    return handleBMIFields();
  } catch (err) {
    return logComponentRenderingError(err, 'BMIField');
  }
};

export default BMIField;
