import { yaRemoveItemAnalytics } from '../../../../common/analytics-events';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  checkIfParksTenants,
  checkParksTenants,
  checkTenant,
  getLoggedInUser,
  getTenantId,
  isEventTypeF1InCart,
  isTicketUpgradePayment,
} from '../../../../common/utility';
import GTMData from '../gtm-data';

export const getAddressObj = (self) => {
  let address = { shippingAddress: null, billingAddress: null };
  const { shippingAddress, billingAddress } = UIConfig.b2c.purchaseJourney.ymcMapping.localStorageKey;
  const shippingAdd = localStorage.getItem(shippingAddress);
  const billingAdd = localStorage.getItem(billingAddress);

  if (shippingAdd) {
    address.shippingAddress = JSON.parse(shippingAdd);
  }
  if (billingAdd) {
    address.billingAddress = JSON.parse(billingAdd);
  }
  return address;
};

export const updatedCartWithAddresses = (self, productList) => {
  let f1Cart = {};
  if (self._tenantId && self._tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
    const address = canUseDOM() && self.getAddressObj();
    // check here for f1 seated Type
    if (isEventTypeF1InCart(productList) && address) {
      f1Cart.shippingAddress = address.shippingAddress;
      f1Cart.billingAddress = address.billingAddress;
    } else {
      f1Cart.shippingAddress = null;
      f1Cart.billingAddress = null;
    }
  }
  return f1Cart;
};

export const exitingCartOnPageLoad = (self, doCheckBasket) => {
  return new Promise((resolve, reject) => {
    self
      ._getCartDataWrapper(doCheckBasket)
      .then((response) => {
        if (response && response.data && response.data.cart) {
          if (checkTenant(UIConfig.iamMapping.ymc)) {
            const pkgList = response.data.cart.packages;
            const newPkgList = [];
            if (pkgList?.length) {
              pkgList.forEach((pkg) => {
                const updatedItems = [];
                pkg.items?.length &&
                  pkg.items.forEach((item) => {
                    if (typeof item?.discount === 'number') {
                      updatedItems.push({
                        ...item,
                        discount: null,
                      });
                    } else {
                      updatedItems.push(item);
                    }
                  });
                newPkgList.push({ ...pkg, items: updatedItems });
              });
              response.data.cart.packages = newPkgList;
            }
          }
          self._storeExtraCartParams(response.data.cart);
          if (response.data.cart.saleAk) {
            self._updateSeatedSaleAk(response.data.cart.saleAk);
          }
          resolve(response);
        } else if (response && response.cart) {
          self._storeExtraCartParams(response.cart);
          reject(response);
        } else {
          reject(response);
        }
      })
      .catch((response) => {
        if (response && response.cart) {
          self._storeExtraCartParams(response.cart);
        }
        reject(response);
      });
  });
};

export const getCombinedCartOnPageLoad = (self, doCheckBasket, orderId) => {
  let cartRes = {};
  let cartItems = {};
  return new Promise((resolve, reject) => {
    self
      .exitingCartOnPageLoad(doCheckBasket)
      .then((res) => {
        if (res.data && res.data.cart) {
          self.seatedJourneyActions
            .getSeatedProduct(orderId)
            .then((res1) => {
              if (res1 && res1.products && res1.products.length) {
                cartItems = self.seatedJourneyActions.getFilteredCartItems(res.data.cart.items, res1.products);
                cartRes = self.getCustomCart(cartItems);
                window.PubSub.publish('startCartTimer', {
                  startDate: res1.products[0].timeStamp,
                });
              } else {
                cartRes = res.data;
              }
              resolve({ data: cartRes });
            })
            .catch((res1Error) => {
              reject(res1Error);
            });
        }
      })
      .catch((resError) => {
        if (resError && resError.error && resError.error.code === UIConfig.errorCodes.emptyCart) {
          self.seatedJourneyActions
            .getSeatedProduct(orderId)
            .then((res1) => {
              cartRes = self.getCustomCart(res1.products);
              if (res1.products && res1.products.length && canUseDOM()) {
                window.PubSub.publish('startCartTimer', {
                  startDate: res1.products[0].timeStamp,
                });
              }
              resolve({ data: cartRes });
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject(resError);
        }
      });
  });
};

export const getYASeatedCartOnPageLoad = (self, doCheckBasket, orderId) => {
  return new Promise((resolve, reject) => {
    self
      .exitingCartOnPageLoad(doCheckBasket)
      .then((res) => {
        if (res.data && res.data.cart) {
          self._yaJourneyActions.getExistingOrderDetails(orderId).then((response) => {
            const cart = self.getCustomCart(response.products);
            resolve({ data: cart });
          });
        }
      })
      .catch((resError) => {
        if (
          self._yaJourneyActions &&
          resError &&
          resError.error &&
          resError.error.code === UIConfig.errorCodes.emptyCart
        ) {
          self._yaJourneyActions.getExistingOrderDetails(orderId).then((response) => {
            self._updatePersistentCart(response.products).then((resp) => {
              resolve({ data: self.getCustomCart(resp.productList) });
            });
          });
        } else {
          reject(resError);
        }
      });
  });
};

/*
  update product list and delte persitent cart
*/
export const deleteCart = (self, productList, product, maxQuantity) => {
  const cartItem = [...productList];
  const { updatedProductList = [], invalidQuantity } = self._deleteFromProductList(productList, product, maxQuantity);
  const deletedProd = [];
  cartItem.forEach((item) => {
    if (invalidQuantity && item.productIdType === product.productIdType && item.productId !== product.productId) {
      item.invalidQuantity = invalidQuantity;
    }
    const isPresent = updatedProductList?.some((lst) => JSON.stringify(lst) === JSON.stringify(item));
    if (!isPresent) {
      deletedProd.push(item);
    }
  });
  if (self._tenantId.toLowerCase() === UIConfig.yasArenaB2CTenant.toLowerCase()) {
    yaRemoveItemAnalytics({ products: [product] });
  } else {
    GTMData.push('removeCartItem', {
      products: product,
      isFromCartDelete: true,
      isJunior: product?.classType === 'JUNIOR',
      ...(checkParksTenants() && { multiProd: deletedProd }),
    });
  }
  if (invalidQuantity && !checkIfParksTenants([], [UIConfig.tenants.ppad])) {
    const errorCode = UIConfig.errorCodes.invalidQuantity;
    return new Promise((resolve, reject) => {
      const response = self._getCustomError({
        errorCode: errorCode,
        errorText: 'quantity exceeded',
      });
      response.error.updatedProductList = updatedProductList;
      reject(response);
    });
  }
  if (updatedProductList) {
    return self._deletePersistentCart(updatedProductList);
  } else {
    return self._getCustomError({
      errorCode: UIConfig.errorCodes.deleteFail,
      errorText: 'product not found',
    });
  }
};

/*
  Show/hide of shipping & billing section
*/
export const hideAddressSection = (self, productList) => {
  const tenantId = getLoggedInUser().tenantID || JSON.parse(localStorage.mainObj).tenantID;
  if (tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
    const cartData = self.getCustomCart(productList);
    if (cartData && cartData.cart) {
      window.PubSub.publish('payment_page_b2c_cart', {
        cart: cartData && cartData.cart,
      });
    }
  }
};

/*
  group products for UI
*/
export const groupCartProducts = (self, productList, key = 'productIdType') => {
  if (productList.length > 0) {
    const groupedData = {};
    let productRowId = '';
    productList.forEach((product) => {
      const productModel = {
        products: [],
        productName: '',
        parks: [],
        net: 0,
        gross: 0,
        tax: 0,
        unit: 0,
      };

      // add sector if f1listing has sectors
      productRowId = product[key] + (product['sector'] || '') + (product['packageGroupCode'] || '');
      //add item to array if it's parent product exists
      if (groupedData[productRowId]) {
        groupedData[productRowId].products.push(product);
        groupedData[productRowId].invalidQuantity =
          groupedData[productRowId].invalidQuantity || product.invalidQuantity;
      } else {
        //add new product if it does not exists
        groupedData[productRowId] = productModel;
        groupedData[productRowId].products.push(product);
        groupedData[productRowId].invalidQuantity = product.invalidQuantity;
      }

      //calculation for indivdual product row
      const isPackagePrd = product.packageGroupCode;
      if (isPackagePrd) {
        groupedData[productRowId].isPackageProd = isPackagePrd;
        groupedData[productRowId].packageGroupCode = product.packageGroupCode;
        groupedData[productRowId].packageQty = product.packageQty;
        groupedData[productRowId].packageTitle = product.packageTitle;
        groupedData[productRowId].packageMax = product.packageMax || product.maxCount;
        groupedData[productRowId].packageMin = product.packageMin || product.minCount;
        groupedData[productRowId].productMaxAvail = product.productMaxAvail;
      }
      groupedData[productRowId].productName = product.productName;
      groupedData[productRowId].parks = product.parks || [];
      groupedData[productRowId].fromDate = product.fromDate;
      groupedData[productRowId].toDate = product.toDate;
      groupedData[productRowId].eventType = product.eventType;
      groupedData[productRowId].net += Number(product.price.net);
      groupedData[productRowId].gross += Number(product.price.gross);
      groupedData[productRowId].tax += Number(product.price.tax);
    });
    return groupedData;
  } else {
    return {};
  }
};

export const showClassType = (self, data, classMap) => {
  if (checkParksTenants() && isTicketUpgradePayment()) {
    return true;
  }
  if (!classMap) {
    return true;
  }
  if (!data) {
    return false;
  }
  for (let i = 0; i < classMap.length; i++) {
    if (data.indexOf(classMap[i]) !== -1) {
      return true;
    }
  }
  return false;
};

export const confirmationReleaseLockOrderService = (url) => {
  try {
    const orderId = localStorage.getItem('orderId');
    const tenantId = getTenantId().toUpperCase();
    const reqObject = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        tenantid: tenantId,
      },
    };
    const apiUrl = `${url}${orderId}?tenantid=${tenantId}`;
    fetch(apiUrl, reqObject)
      .then((response) => response.json())
      .then((data) => {
        return;
      });
  } catch (error) {
    console.log('error in releaseLock Api:', error);
  }
};

export const checkForInvalidQuantityProducts = (items) => {
  let invalidProductQuantity = false;
  for (let key in items) {
    if (items?.[key]?.invalidQuantity) {
      invalidProductQuantity = items?.[key]?.invalidQuantity;
      return invalidProductQuantity;
    }
  }
  return invalidProductQuantity;
};

export const checkForCartGlobalErrors = (items, maxQuantity) => {
  maxQuantity = +maxQuantity;
  let totalCartQuantity = 0;
  let isGlobalCartQuantityError = false;
  let isGroupedProd = !Array.isArray(items);
  if (isGroupedProd) {
    for (let key in items) {
      if (items?.[key]?.products?.[0]) {
        totalCartQuantity += items?.[key]?.products?.[0]?.quantity || 0;
      }
      if (items?.[key]?.products?.[1]) {
        totalCartQuantity += items?.[key]?.products?.[1]?.quantity || 0;
      }
    }
  } else {
    totalCartQuantity = items.reduce((acc, item) => acc + item?.quantity, 0);
  }
  if (totalCartQuantity > maxQuantity) {
    isGlobalCartQuantityError = true;
  }
  return isGlobalCartQuantityError;
};
