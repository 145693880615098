import { AnalyticsClient } from '../coveo-analytics/analytics-client';
import { analyticsActionCauseList } from '../coveo-analytics/analytics-action-list';
import { analyticsConfig } from '../coveo-analytics/analytics-config';
import { getSearchContent, getAdvancedSearchContent } from '../coveo-api';
import { checkTenant } from '../../common/utility';
import UIConfig from '../../common/UIConfig';

export class queryAnalyticsService {
  static getQuery(config) {
    config.fieldsToInclude = config.fieldsToInclude.concat(analyticsConfig.additionalFields);
    const isSwad = checkTenant(UIConfig.iamMapping.swad);
    const searchPromise = getSearchContent(config);
    const queryObj = {
      config: config,
      promise: searchPromise,
    };
    const searchInfo = JSON.parse(localStorage.getItem('searchInfo'));
    let meta;
    let actionCause;

    if (searchInfo && !isSwad) {
      const queryType = searchInfo.queryType;
      if (queryType === 'querySuggest') {
        //logs omniBoxAnalytics Event
        const partialQuery = searchInfo.partialQuery;
        const partialQueries = partialQuery ? partialQuery.split('').join() : undefined;
        const suggestionRanking = searchInfo.suggestionRanking;
        const suggestions = searchInfo.suggestions;
        meta = { partialQuery, partialQueries, suggestionRanking, suggestions };
        actionCause = analyticsActionCauseList.omniboxAnalytics;
      } else if (queryType === 'pageLinks') {
        //logs custom pageNumber Event
        actionCause = analyticsActionCauseList.pagerNumber;
        meta = {
          pagerNumber: config.pageNo / config.perPageResults,
          query: config.query,
        };
      } else {
        // logs search analytics event
        actionCause = analyticsActionCauseList.searchboxSubmit;
        meta = null;
      }
    } else {
      //logs page refresh event - signifying search from bookmarked link
      actionCause = analyticsActionCauseList.searchFromLink;
      meta = null;
    }

    if (actionCause === analyticsActionCauseList.pagerNumber) {
      AnalyticsClient.logCustomEvent(actionCause, meta);
    } else {
      AnalyticsClient.logSearchEvent(actionCause, meta, queryObj);
    }
    localStorage.removeItem('searchInfo');

    return searchPromise;
  }
  static logClickEvent(result) {
    const actionCause = {
      name: 'documentOpen',
      type: 'documentOpen',
    };
    return AnalyticsClient.logClickEvent(actionCause, null, result);
  }

  static getAdvancedSearchQuery(config) {
    const searchPromise = getAdvancedSearchContent(config);
    return searchPromise;
  }
}
