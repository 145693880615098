import React from 'react';

import { setExpireAtValue, setLocalStorage, canUseDOM, resolvePath, getCurrentLanguage } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';

const GlobalMessage = (props) => {
  const propsCopy = { ...props };

  if (canUseDOM()) {
    const searchValue = '{route}';
    const pathName = window.location.pathname;
    let replaceValue = pathName === '/' ? `/${getCurrentLanguage()}` : pathName;

    const fbUrl = resolvePath(props, 'additionalProperty.facebookURL', null);
    if (fbUrl) {
      propsCopy.additionalProperty.facebookURL = fbUrl.replace(searchValue, replaceValue);
    }

    const gUrl = resolvePath(props, 'additionalProperty.googleURL', null);
    if (fbUrl) {
      propsCopy.additionalProperty.googleURL = gUrl.replace(searchValue, replaceValue);
    }
  }
  setLocalStorage(UIConfig.appLocalStorageKeys.mainObj, JSON.stringify(propsCopy));
  setExpireAtValue();

  return <></>;
};

export default GlobalMessage;
