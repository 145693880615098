import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AnchorLink, AutoSuggest, DynamicContent, RichText, SvgSprite } from '../base';
import Nav from '../nav/nav-component';
import { logComponentRenderingError } from '../../../common/logger';
import UIConfig from '../../../common/UIConfig';
import { resolvePath, canUseDOM, checkParksTenants } from '../../../common/utility';
import { GLOBAL_VARS } from '../../../common/global-vars';
import { bookYourHolidayClickAnalytics } from '../../../common/analytics-events';

import './header-body-component.scss';
import LanguageSwitcher from '../language-switcher';
import OpeningHours from '../../container/opening-hours';
import CartCount from '../../container/header/CartCount';

/**
 * Header body is part of header in which either meganav, profile or welcom text will be displayed.
 * @param    {body} Object as defined from data-contract.
 * @param    {searchSettings} Object has settings for Search overlay component
 * @param    {type} String define which type of body will be displayed profile/menu or desktop
 * @param    {logged} Boolean wheather user logged in or not
 * @return   {HeaderBody} markup that containes header Body
 */

const HeaderBody = ({
  body,
  searchSettings,
  type,
  logged,
  variant,
  isMobile,
  wishList,
  wishlistActive,
  localStorageUserInfo,
  isParks,
  logout,
  miniCart,
  isBodyHeight,
  isFwad,
  hideCart,
  setHideCart,
  cartTotalQuantity,
}) => {
  const [isOpeningHoursOpen, setOpeningHoursOpen] = useState(false);
  const [isHeaderStickyHeight, setHeaderStickyHeight] = useState();

  const toggleOpeningHours = () => {
    setOpeningHoursOpen(!isOpeningHoursOpen);
  };

  const checkStickyHeaderHeight = () => {
    const winHeight = window.outerHeight;
    const diffHeight = winHeight - window.innerHeight;
    setHeaderStickyHeight(diffHeight);
  };

  useEffect(() => {
    checkStickyHeaderHeight();
    window.addEventListener('resize', checkStickyHeaderHeight);
    return () => {
      window.removeEventListener('resize', checkStickyHeaderHeight);
    };
  }, []);

  try {
    if (!body) {
      return null;
    }
    return (
      <div
        className={'header-body' + (logged ? ' header-body-logged' : '') + ' ' + getHasProfileVarient()}
        style={{
          ...(isParks && isBodyHeight && { height: `${isBodyHeight}px` }),
          ...(isParks && isMobile && { paddingBottom: `${isHeaderStickyHeight}px` }),
        }}
      >
        {type === 'menu' &&
          getLoggedInVersion(
            searchSettings,
            logged,
            body,
            isMobile,
            variant,
            type,
            isParks,
            isOpeningHoursOpen,
            toggleOpeningHours,
            miniCart,
            isFwad,
            hideCart,
            setHideCart,
            cartTotalQuantity,
          )}
        {!type && loadBaseNav(body, variant, isMobile, wishlistActive, miniCart)}
        {!logged && getPreLoggedVersion(body)}
        {type === 'profile' && loadProfile(body, variant, localStorageUserInfo)}
        {type === 'profile' && isParks && logged && renderLogoutBtn(body, logout)}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HeaderBody');
  }
};

const getHasProfileVarient = (variant, type) => {
  return variant === UIConfig.commonVariant.b2cHeaderVariant && type === 'profile'
    ? 'header-body-b2c'
    : '' ||
      ((variant === UIConfig.commonVariant.headerVariant ||
        variant === UIConfig.swadVariant.headerVariant ||
        variant === UIConfig.commonVariant.multiMegaNavHeaderVariant) &&
        type === 'profile')
    ? 'header-body-b2c'
    : '';
};
const renderLanguageSwitcher = (body, type) => {
  return type !== 'profile' && body.language && body.language.languages ? (
    <div className="header-bottom-lang-switcher">
      <LanguageSwitcher data={body.language} />
    </div>
  ) : null;
};

const renderCart = (body, type, logged, miniCart, isFwad, hideCart, setHideCart, cartTotalQuantity) => {
  const checkCartCta = body.cart && body.cartCta;
  const showCartWithProfileIcon = type !== 'profile' || body.variant === 'v-header-multimeganav';

  if (checkCartCta && showCartWithProfileIcon && logged) {
    return (
      <CartCount
        data={body}
        variant={body.variant}
        isHeaderMiniCartEnabled={miniCart.isHeaderMiniCartEnabled}
        isFwad={isFwad}
        isHeaderMiniCartEnabledOnBookingToPaymentPages={miniCart.isHeaderMiniCartEnabledOnBookingToPaymentPages}
        hideCart={hideCart}
        setHideCart={setHideCart}
        cartTotalQty={cartTotalQuantity}
      />
    );
  }

  return <></>;
};

const getLoggedInVersion = (
  searchSettings,
  logged,
  body,
  isMobile,
  variant,
  type,
  isParks,
  isOpen,
  toggleDropdown,
  miniCart,
  isFwad,
  hideCart,
  setHideCart,
  cartTotalQuantity,
) => {
  const ifSelectedHeaderType =
    variant === 'v-header-b2c' ||
    variant === 'v-ya-header' ||
    variant === 'v-header-yasisland' ||
    variant === 'v-header-swad' ||
    variant === 'v-header-multimeganav';

  return (
    <div className="header-body-content-logged">
      {isParks && isMobile && (
        <>
          {body?.openingHours && <OpeningHours data={body.openingHours} apiUrl={body.services} type="light" />}
          <div className="top-body-sec">
            <div>
              <a
                aria-label="Whatsapp"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="c-header-top-icon whatsapp"
                role="button"
                href={body.whatsAppUrl}
              >
                <SvgSprite id="whatsapp-sticky-header-icons" />
              </a>
            </div>
            <div className="cart-language-option">
              {renderCart(
                body,
                type,
                ifSelectedHeaderType || logged,
                miniCart,
                isFwad,
                hideCart,
                setHideCart,
                cartTotalQuantity,
              )}
              <div>{renderLanguageSwitcher(body, type)}</div>
            </div>
          </div>
        </>
      )}
      {searchSettings &&
        (searchSettings.additionalProperty.anonymousAccess || logged) &&
        Boolean(searchSettings.serviceUrls) && <AutoSuggest data={searchSettings} />}
      <Nav
        data={body.megaNavData || []}
        hideNavLinks={body.hideNavLinksMobile && isMobile}
        variant={variant}
        headerAriaLabelDesktop={body.headerAriaLabelDesktop}
        headerAriaLabel={body.headerAriaLabel}
        enableMobileNav={isParks && isMobile}
      />
    </div>
  );
};

const handleBookYourHoliday = () => {
  const datalayerItem = canUseDOM() && window.dataLayer && window.dataLayer[0];
  /* Fire book your holiday event for all the pages */
  if (datalayerItem) {
    bookYourHolidayClickAnalytics();
  }
};

const loadBaseNav = (body, variant, isMobile, wishlistActive, miniCart) => {
  return (
    <div className="header-body-nav-links">
      <Nav
        data={body.megaNavData || []}
        variant={variant}
        disableMegaNav={body.disableMegaNav}
        headerAriaLabelDesktop={body.headerAriaLabelDesktop}
        headerAriaLabel={body.headerAriaLabel}
        hideNavLinks={body.hideNavLinksMobile && isMobile}
      />
      {variant === UIConfig.swadVariant.headerVariant && !miniCart && body.bookTicketCta && (
        <div className="header-body-other-links">
          <span className="links ticket">
            <AnchorLink
              link={{
                label: body.bookTicketCta.label,
                href: body.bookTicketCta.href,
              }}
            />
          </span>
        </div>
      )}
      {(variant === UIConfig.commonVariant.headerVariant ||
        (variant === UIConfig.swadVariant.headerVariant && miniCart.isHeaderMiniCartEnabled) ||
        variant === UIConfig.commonVariant.multiMegaNavHeaderVariant) && (
        <div className="header-body-other-links">
          {body.bookTicketCta && (
            <span className="links ticket">
              <AnchorLink
                link={{
                  label: body.bookTicketCta.label,
                  href: body.bookTicketCta.href,
                }}
              />
            </span>
          )}
          {body.isWishListEnabled && (
            <span className={`links wishlist ${wishlistActive ? 'active' : ''}`}>
              <a role="button" aria-label="wishlist" href={body.wishListLink.href}>
                {''}
              </a>
            </span>
          )}

          {body.subscribeCta && (
            <span
              className={`links btn-subscribe btn-primary reverse ${
                body.subscribeCta.class === 'v-multicolor-cta' ? 'v-multicolor-cta' : ''
              } ${body.subscribeCta.class === 'v-festive-header-cta' ? 'v-festive-header-cta' : ''}`}
            >
              <AnchorLink
                link={{
                  label: body.subscribeCta.label,
                  href: body.subscribeCta.href,
                }}
                onClick={() => handleBookYourHoliday()}
              />
            </span>
          )}

          {(variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant) &&
            body.subscribeCta.label && (
              <span className="book-btn">
                <AnchorLink
                  link={{
                    label: body.subscribeCta.label,
                    href: body.subscribeCta.href,
                  }}
                  className="cta-button primary"
                  onClick={() => bookHoliday()}
                />
              </span>
            )}
        </div>
      )}
    </div>
  );
};

// Used to stick booking hotel booking widget with header
const bookHoliday = () => {
  const hasClass = document.body.classList.contains('bookin-widget-opened');
  const topPosition = window.pageYOffset;
  if (topPosition > 0 && !hasClass) document.body.className += ' bookin-widget-opened';
};

const getPreLoggedVersion = (body) => {
  // EP-755 - for EA, registerCta button should not load in this component, it should appear only in header-bottom-component.
  const isEtihadArena = resolvePath(GLOBAL_VARS, 'pageDetails.tenantName', '') === UIConfig.iamMapping.etihadarena;
  const isEASecondaryCTA = isEtihadArena && resolvePath(body, 'registerCta.label', null);

  return (
    <div className="header-body-content-pre-logged">
      {body.mobileBodyCopy && (
        <RichText
          data={{
            bodyCopy: body.mobileBodyCopy,
            attrs: { className: 'header-body-rte' },
          }}
        />
      )}
      {body.registerCta && !isEASecondaryCTA && (
        <div className="btn btn-secondary header-body-btn-register heading-7">
          <AnchorLink link={{ ...body.registerCta }} />
        </div>
      )}
    </div>
  );
};

const renderLogoutBtn = (body, logout) => {
  return (
    (body.logoutCta ? body.logoutCta : (body.logoutCta = UIConfig.logoutCta)) && (
      <div className="header-bottom-btn-logout">
        <AnchorLink link={{ ...body.logoutCta, attrs: { onClick: logout } }} tabIndex="0" />
      </div>
    )
  );
};

const loadProfile = (body, variant, localStorageUserInfo) => {
  const { userName } = localStorageUserInfo;
  if (variant === 'v-header-view') {
    return (
      <div className="header-body-profile">
        <DynamicContent
          tagName="div"
          innerHtml={body.profile.bodyCopy + ' ' + userName}
          attrs={{ className: 'header-body-profile-name' }}
        />
        <ul>
          {body.profile.ctaLinks &&
            body.profile.ctaLinks.map((link, i) => {
              return (
                <li key={i}>
                  <AnchorLink link={{ ...link }} />
                </li>
              );
            })}
        </ul>
      </div>
    );
  } else if (
    [
      UIConfig.commonVariant.b2cHeaderVariant,
      UIConfig.commonVariant.yasArenaHeaderVariant,
      UIConfig.commonVariant.headerVariant,
      UIConfig.swadVariant.headerVariant,
      UIConfig.commonVariant.multiMegaNavHeaderVariant,
    ].indexOf(variant) >= 0 &&
    body.profile
  ) {
    return (
      <div className="header-body-profile">
        {userName && (
          <DynamicContent
            tagName="div"
            innerHtml={body.profile.bodyCopy + ' ' + userName}
            attrs={{ className: 'header-body-profile-name' }}
          />
        )}
        <ul>
          {(body.profile.ctaLinks[0].label === UIConfig.loginCta.label
            ? UIConfig.profile.ctaLinks
            : body.profile.ctaLinks
          ).map((link, i) => (
            <li key={i}>
              <AnchorLink link={link} />
            </li>
          ))}
        </ul>
      </div>
    );
  }
};

HeaderBody.propTypes = {
  body: PropTypes.object.isRequired,
  type: PropTypes.string,
  logged: PropTypes.bool,
  options: PropTypes.object,
};

export default HeaderBody;
