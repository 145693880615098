/*
 * Annual pass hub component
 * This file contains code for Annual Pass Hub Component.
 * This component provides presentation grid view for annual pass hub.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AnchorLink, DynamicContent, Image, SvgSprite } from '../../presentation/base/';

/**
 * renders the ap hub as per the given variant
 * @param {*} params JSON data for the component
 */
const AnnualPassHub = ({ data }) => (
  <div data-c-name="annual-pass-hub" data-c-render="server" className={`component c-ap-hub ${data.variant}`}>
    <div className="w--content">
      <div className="c-ap-hub-table">
        <CardsHeader title={data.title} cards={data.cards} variant={data.variant} />
        {data.variant === 'v-grid-view' &&
          data.questions.map((question, i) => (
            <div className="c-ap-hub-row">
              <div className="c-ap-hub-cell first-col">
                <DynamicContent tagName="div" attrs={{ className: 'c-ap-hub-cell-data' }} innerHtml={question.value} />
              </div>
              {data.cards.map((card) => (
                <div className="c-ap-hub-cell">
                  <TableCell type={card.answers[question.key]} naLabel={data.naLabel} />
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  </div>
);

/**
 * renders card header containing cards
 * @param {string} title title of ap in case of grid view
 * @param {Array} cards array of cards
 * @param {string} variant varient for grid view
 */
const CardsHeader = ({ title, cards, variant }) => (
  <div className="c-ap-hub-row c-ap-hub-row-header">
    {variant === 'v-grid-view' && (
      <div className="c-ap-hub-cell first-col">
        <DynamicContent tagName="h2" attrs={{ className: 'c-ap-hub-title' }} innerHtml={title} />
      </div>
    )}
    {cards.map((card) => (
      <div className="c-ap-hub-cell">
        <Card card={card} />
      </div>
    ))}
  </div>
);

/**
 * to render icon or text in table cell
 * @param {String} type type of cell
 */
const TableCell = ({ type, naLabel }) => {
  switch (type) {
    case '0':
      return (
        <span className="ap-cross-icon">
          <SvgSprite id="ap-cross-icon" />
        </span>
      );
    case '1':
      return (
        <span className="ap-tick-icon">
          <SvgSprite id="ap-tick-icon" />
        </span>
      );
    case 'N/A':
      return <DynamicContent tagName="span" attrs={{ className: 'na-text' }} innerHtml={naLabel} />;
    default:
      return <DynamicContent tagName="span" attrs={{ className: 'na-text' }} innerHtml={type} />;
  }
};

/**
 * card component
 * @param {*} card card to render
 */
const Card = ({ card }) => (
  <div
    className={`c-ap-hub--card ticket-box ${card.cardTheme}`}
    style={{
      borderBottomColor: card.borderBottomColor || '#000',
      backgroundColor: card.borderBottomColor || '#000',
    }}
  >
    <Image image={card.cardImage} />
    <DynamicContent tagName="div" attrs={{ className: 'c-ap-hub--card-desc' }} innerHtml={card.description} />
    {card.cardCta && (
      <div className={classNames('card-cta btn-primary', card.cardCta.class)}>
        <AnchorLink link={{ ...card.cardCta }} />
      </div>
    )}
  </div>
);

/**
 * basic structure of data contract
 */
AnnualPassHub.propTypes = {
  data: PropTypes.shape({
    cards: PropTypes.array,
    questions: PropTypes.array,
    variant: PropTypes.string,
    title: PropTypes.string,
  }),
};
export default AnnualPassHub;
