import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import UIConfig from '../../../../common/UIConfig';
import Image from '../../../presentation/base/image/image-component';
import TicketTooltip from '../../../presentation/b2c-purchase-journey/ticket-tooltip/ticket-tooltip';
import { isEnterPressed, getFormattedTime, resolvePath, isMatchTenant } from '../../../../common/utility';
import { GLOBAL_VARS } from '../../../../common/global-vars';
import GTMData from '../gtm-data';

const EventPerformance = ({
  actionLink,
  soldOutText,
  validateCartAndRedirection,
  performanceData,
  isNonSeated,
  addToCartLabel,
  secondaryCTA,
  isEventVisited,
  nonSeatedJourneyTAndC,
  isNewPerformanceSelection,
  showMessageWhenItemsInCart,
  clearYACart,
  closeOverlay,
  showYaNonSeatedOverlay,
  cartData,
}) => {
  let { imageInfo } = performanceData;

  const {
    date,
    description,
    title,
    closeTime,
    openTime,
    tooltipTitle,
    tooltipDescription,
    isDisabled,
    sellable,
  } = performanceData;

  useEffect(() => {
    if (isMatchTenant(UIConfig.tenants.ya)) {
      GTMData.push(UIConfig.ga4Constants.VIEW_ITEM, { ...performanceData });
    }
  }, []);

  const handleEnter = (evt) => {
    if (isMatchTenant(UIConfig.tenants.ya)) {
      GTMData.push(UIConfig.ga4Constants.BUY_TICKETS, {
        click_text: evt?.target?.textContent,
        click_url: actionLink,
      });
    }
    if (evt.type === 'click' || (evt.type === 'keydown' && isEnterPressed(evt))) {
      evt.preventDefault();
      if (isNonSeated) {
        /**
         *  if user has not selected TnC - then show TnC and Proceed
         *  else if user tries to select and new performance or event - show message, cart will be deleted and new
         *  selection required
         *  else - user is trying to update existing tour cart
         */
        if (!isEventVisited()) {
          nonSeatedJourneyTAndC(performanceData.performanceId, () => {
            closeOverlay();
            showYaNonSeatedOverlay(performanceData);
          });
        } else if (isNewPerformanceSelection(performanceData.performanceId)) {
          showMessageWhenItemsInCart(performanceData.performanceId, () => {
            clearYACart();
            closeOverlay();
            showYaNonSeatedOverlay(performanceData);
          });
        } else {
          showYaNonSeatedOverlay(performanceData);
        }
      } else {
        validateCartAndRedirection();
      }
    }
  };

  if (imageInfo && !imageInfo.src) {
    imageInfo = false;
  }

  // EP-755 - add Travel box related button, only for EA
  const isEtihadArena = resolvePath(GLOBAL_VARS, 'pageDetails.tenantName', '') === UIConfig.iamMapping.etihadarena;
  const isEASecondaryCTA = isEtihadArena && secondaryCTA && secondaryCTA.label;

  return (
    <div className="event-tile">
      {imageInfo && (
        <div className="event-image">
          <Image image={imageInfo} noRendition />
        </div>
      )}
      <div className="event-info-wrapper">
        <div className="event-details">
          {title ? (
            <div className="event-title">{title}</div>
          ) : (
            <div>
              <div className={classNames('event-date', { show: !imageInfo })}>
                {moment(date).format(UIConfig.b2c.purchaseJourney.yaDayDateFormat)}
              </div>
              <div className={classNames('event-time', { bold: imageInfo })}>
                {`${getFormattedTime(openTime)} - ${getFormattedTime(closeTime)}`}
              </div>
            </div>
          )}
          {description && <div className="event-description">{description}</div>}
        </div>
        <div className="ea-button-wrapper">
          {isEASecondaryCTA && (
            <>
              <div className="btn-cta-spacer"></div>
              <div className="btn-cta-golden">
                <a tabIndex="0" href={secondaryCTA.url ? secondaryCTA.url : '#'} target={secondaryCTA.target}>
                  {secondaryCTA.label}
                </a>
              </div>
            </>
          )}
          <div className={isEASecondaryCTA ? 'btn-primary-last' : 'btn-primary'}>
            <a
              tabIndex="0"
              onClick={!isDisabled ? handleEnter : null}
              className={classNames({ disabled: isDisabled })}
              onKeyDown={!isDisabled ? handleEnter : null}
            >
              {sellable ? addToCartLabel : soldOutText}
            </a>
          </div>
        </div>
        <div className="tooltip-wrapper">
          <TicketTooltip title={tooltipTitle} description={tooltipDescription} />
        </div>
      </div>
    </div>
  );
};

EventPerformance.PropsTypes = {
  performanceData: PropTypes.object,
  isNonSeated: PropTypes.bool,
  soldOutText: PropTypes.string,
  addToCartLabel: PropTypes.string,
};

export default EventPerformance;
