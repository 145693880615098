import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from '../../../../common/utility';
import Accordion from '../../../presentation/accordian';
import TextWithCTA from '../../text-with-cta';
import AddOnsCategoryComponent from '../add-ons/add-ons-category-component';

const PastPurchasedAccrodian = ({
  products,
  getOrderDetail,
  newProps,
  filteredCategories,
  data,
  recommendationsCatergoryData,
  addedQuatity,
  emptyData,
}) => {
  const [isRecEmpty, setRecEmpty] = useState(false);
  const [selectedTicketData] = useState([]);
  const [ticketState] = useState([]);
  const [searchProductList] = useState([]);

  const accordianListItems = useRef([]);

  useEffect(() => {
    const productRecommendation =
      products.length &&
      products.filter((order) => {
        return (
          order.recommendations &&
          Object.keys(order.recommendations).some((key) => recommendationsCatergoryData[key].totalTicket !== 0)
        );
      });
    if (!productRecommendation.length) {
      setRecEmpty(true);
    } else {
      setRecEmpty(false);
    }
  }, [products, recommendationsCatergoryData]);

  const createAccordianHeader = (order, index) => {
    const { productName, weekDays } = order;
    const { quantity } = data[index].f1Product;
    const { productNameLabel, quantityLabel, validityLabel, weekDaysDisplay } = newProps.data.postPurchaseProductAddons;
    return (
      <div className="c-order-list-product-header" key={order.orderAk} onClick={() => getOrderDetail(index)}>
        <div className="header-content-wrapper">
          <div className="orderHead">
            <div className="purchaseDate w-100">
              <span className="header-label">{productNameLabel}</span>
              <span className="date f-bold">{productName || '-'}</span>
              <span className="separator"></span>
            </div>
          </div>
          <div className="orderHead">
            <div className="purchaseDate">
              <span className="header-label">{validityLabel}</span>
              <span className="date f-bold">{weekDaysDisplay ? weekDaysDisplay[weekDays] : '-'}</span>
              <span className="separator"></span>
            </div>
            <div className="orderId">
              <span className="header-label">{quantityLabel}</span>
              <span className="order f-bold">{quantity < 10 ? `0${quantity}` : quantity}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const createAccordianDetail = (order, index) => {
    const categoryComponents = [];
    const getProductCategoriesList = () => {
      let myCategoryList = {};
      if (order.recommendations) {
        for (let pKey in order.recommendations) {
          myCategoryList[pKey] = false;
        }
      }
      return myCategoryList;
    };
    return (
      <div>
        {order &&
          order.recommendations &&
          Object.keys(order.recommendations).length > 0 &&
          filteredCategories.forEach((category) => {
            if (
              order.recommendations[category] &&
              (recommendationsCatergoryData[category].totalTicket > 0 ||
                recommendationsCatergoryData[category].isUnlimited)
            ) {
              categoryComponents.push(
                <AddOnsCategoryComponent
                  data={newProps.data}
                  key={category}
                  category={category}
                  products={order.recommendations[category]}
                  createProductStore={newProps.createProductStore}
                  createMappedData={newProps.createMappedData}
                  addToCartCallback={newProps.addToCartCallback}
                  services={newProps.services}
                  getDiscountedProducts={newProps.getDiscountedProducts}
                  cartData={newProps.cartData}
                  businessErrors={newProps.businessErrors}
                  selectedTicket={selectedTicketData.selectedTicket}
                  ticketState={ticketState}
                  prodCategoryList={getProductCategoriesList}
                  coveoMapList={newProps.coveoMapList}
                  searchProductList={searchProductList}
                  showMinicartCouponCode={newProps.showMinicartCouponCode}
                  discountMap={newProps.discountMap}
                  additionalRecommendedData={newProps.additionalRecommendedData}
                  ariaLabelCheckbox={newProps.ariaLabelCheckbox}
                  isPastPurchasedRecommendation
                  totalTicketsPurchased={data[index].f1Product.quantity}
                  recommendationsCatergoryData={recommendationsCatergoryData}
                  addedQuatity={(val, cat) => addedQuatity(val, cat)}
                />,
              );
            }
          })}

        {categoryComponents}
      </div>
    );
  };

  const getAccordianList = () => {
    accordianListItems.current = [];
    products.forEach((order, index) => {
      const hasAddon = Object.keys(order.recommendations).filter(
        (key) => recommendationsCatergoryData[key].totalTicket > 0 || recommendationsCatergoryData[key].isUnlimited,
      );
      if (hasAddon.length && order.recommendations && !isEmpty(order.recommendations)) {
        accordianListItems.current.push({
          summaryComponent: createAccordianHeader(order, index),
          detailComponent: createAccordianDetail(order, index),
        });
      }
    });
  };

  products && products.length && getAccordianList();
  if (isRecEmpty) {
    return (
      <div className="b2c-booking-tabs-wrapper empty-booking">
        <TextWithCTA data={emptyData} />
      </div>
    );
  }
  return (
    <div className="my-purchase-component">
      <div className="orderDetail">
        <Accordion
          accordianItems={accordianListItems.current}
          isMultiExpandable={false}
          isRTE={false}
          getOrderDetailFn={(i) => getOrderDetail(i)}
          isPastPurchasedRecommendation
        />
      </div>
    </div>
  );
};

export default PastPurchasedAccrodian;
