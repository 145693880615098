import UIConfig from '../../../../common/UIConfig';
import React from 'react';
import classNames from 'classnames';
import TourOverlayComponent from './tour-overlay-component';
import TourOverlayComponentWithSector from './tour-overlay-component-with-sector';
import { PerformanceService } from '../../../../common/services';
import { getB2CProductDetails } from '../../../../common/coveo-api';
import { createMappedData, getFallbackLanguage, getUTCTimeByFormat } from '../../../../common/utility';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';

export default class TourOverlay {
  constructor({
    labels,
    performanceData,
    coveoMappingList,
    performanceService,
    findperformancecapacityandproduct,
    coveoProductSvc,
    cartItems,
    addToCartCallback,
    eventDetails,
  }) {
    this.labels = labels;
    this.isNonSeatedSector = eventDetails.isNonSeatedSector;
    this.nonAvailabilityMessage = eventDetails.nonAvailabilityMessage;
    this.performanceData = performanceData;
    this.coveoMappingList = coveoMappingList;
    this.performanceService = performanceService;
    this.findperformancecapacityandproduct = findperformancecapacityandproduct;
    this.coveoProductSvc = coveoProductSvc;
    this.cartItems = cartItems;
    this.defaultValues = {};
    this.addToCart = this.addToCart.bind(this);
    this.addToCartCallback = addToCartCallback;
    this.mode = '';
  }

  openOverlay(products) {
    const { isNonSeatedSector } = YALocalStorageSvc.getSetPerformance();
    const showSector = this.isNonSeatedSector || isNonSeatedSector;
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: true,
      dataToAppend: showSector ? (
        <TourOverlayComponentWithSector
          closeOverlay={this.closeOverlay}
          products={products}
          labels={this.labels}
          defaultValues={this.defaultValues}
          mode={this.mode}
          performanceData={this.performanceData}
          addToCart={this.addToCart}
          findperformancecapacityandproduct={this.findperformancecapacityandproduct}
          nonAvailabilityMessage={this.nonAvailabilityMessage}
        />
      ) : (
        <TourOverlayComponent
          closeOverlay={this.closeOverlay}
          products={products}
          labels={this.labels}
          defaultValues={this.defaultValues}
          mode={this.mode}
          performanceData={this.performanceData}
          addToCart={this.addToCart}
        />
      ),
      customClass: classNames('eventPerformance-Overlay', { 'tour-overlay-with-sector': showSector }),
    });
  }

  addToCart(selectedProducts) {
    this.closeOverlay();
    const { isNonSeatedSector } = YALocalStorageSvc.getSetPerformance();
    const eventDetails = YALocalStorageSvc.getSetEventDetails();
    selectedProducts = selectedProducts.filter((product) => product.quantity > 0);

    let { openTime, closeTime, performanceId, date, availability, sectors } = this.performanceData;

    openTime = getUTCTimeByFormat(`${date}T${openTime}`);
    closeTime = getUTCTimeByFormat(`${date}T${closeTime}`);

    const extraParams = {
      performanceId,
      timeSlot: `${openTime} - ${closeTime}`,
      date,
    };

    YALocalStorageSvc.getSetPerformance({
      openTime,
      closeTime,
      performanceId,
      date,
      availability,
      sectors,
      isNonSeatedSector: this.isNonSeatedSector || isNonSeatedSector,
    });

    YALocalStorageSvc.getSetEventDetails({
      ...eventDetails,
      eventTitle: selectedProducts && selectedProducts[0].eventTitle && (selectedProducts[0].eventTitle[0] || ''),
      eventId: selectedProducts && selectedProducts[0].eventId && (selectedProducts[0].eventId[0] || ''),
    });
    YALocalStorageSvc.getSetCartTitle(true);
    this.addToCartCallback(selectedProducts, false, null, availability.available, extraParams);
  }

  closeOverlay() {
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, { shouldOpen: false });
  }

  showTourProducts(mode = UIConfig.b2c.purchaseJourney.yaTourOverlay.add, fromCartAction) {
    const { isNonSeatedSector } = YALocalStorageSvc.getSetPerformance();
    this.mode = mode;
    const sector = this.isNonSeatedSector || isNonSeatedSector;
    if (sector) {
      this.getSectorsProductsAndOpenOverlay(fromCartAction);
    } else {
      this.getProductsAndOpenOverlay();
    }
  }
  findSectorSubSector = (response) => {
    const { productInfo } = response;
    let products = [];
    productInfo.forEach((seatCat) => {
      seatCat.seatCategory.forEach((seat) => {
        products.push(seat);
      });
    });
    return products;
  };

  getSectorsProductsAndOpenOverlay = (fromCartAction) => {
    let { performanceId } = this.performanceData;
    //take sector from performance sectors map with response
    const url = this.findperformancecapacityandproduct.replace('{performanceak}', performanceId);

    const svcSuccessCallback = (response) => {
      const productsResults = this.findSectorSubSector(response) || [];
      let productIds = productsResults.map((prd) => prd.productList[0].productAk);

      this.getPerformanceProducts(productIds).then((products) => {
        this.openOverlay(this.setDefaults(products, productsResults));
      });
    };

    PerformanceService.findperformancecapacityproduct(url, true, performanceId, fromCartAction).then(
      svcSuccessCallback,
    );
  };

  getProductsAndOpenOverlay() {
    let { performanceId } = this.performanceData;
    const url = this.performanceService.replace('{performanceId}', performanceId);
    const svcSuccessCallback = (response) => {
      const productIds = response.data.products[0].productId;
      this.getPerformanceProducts(productIds).then((products) => {
        this.openOverlay(this.setDefaults(products));
      });
    };

    PerformanceService.findAllPerformanceProducts(url, true, performanceId).then(svcSuccessCallback);
  }

  setDefaults(mappedProducts, sectorProducts) {
    const { date, performanceId } = this.performanceData;

    const productsWithQuantity = mappedProducts.map((product) => {
      const cartProduct = this.cartItems.length && this.cartItems.find((i) => i.productId === product.productId);
      const checkAvailable =
        sectorProducts &&
        sectorProducts.length > 0 &&
        sectorProducts.find((prd) => prd.productList[0].productAk === product.productId);
      product.quantity = cartProduct ? cartProduct.quantity : 0;
      product.fromDate = date;
      product.toDate = date;
      product.performanceId = performanceId;
      product.available = checkAvailable ? checkAvailable.available : 0;

      return { ...product };
    });

    return productsWithQuantity;
  }

  /**
   *  Get Products from Coveo by product Ids
   *  @param {[Object]} -  Product Ids
   *  @returns Products to show in overlay
   */

  getPerformanceProducts(productIds) {
    const params = [
      {
        key: this.coveoMappingList.productId,
        value: productIds.map((ids) => ids),
      },
    ];

    const getCoveoDefaultParams = {
      perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
      coveoKeyMap: this.coveoMappingList,
      serviceUrl: this.coveoProductSvc,
      fieldsToInclude: Object.values(this.coveoMappingList),
      lang: getFallbackLanguage(),
    };

    const successFn = (resolve) => (res) => {
      const mappedProductsData = createMappedData(res.results, this.coveoMappingList);

      resolve(mappedProductsData);
    };

    const rejectFn = (reject) => (error) => {
      reject(error);
    };

    return new Promise((resolve, reject) => {
      getB2CProductDetails({
        ...getCoveoDefaultParams,
        queryParams: params,
      })
        .then(successFn(resolve))
        .catch(rejectFn(reject));
    });
  }
}
