import React, { useState, useEffect, useRef, useCallback } from 'react';
import { logComponentRenderingError } from '../../../../common/logger';
import LabelComponent from '../label-component';
import FieldErrorComponent from '../field-error';
import { validateJSSField } from '../rules-validator';
import classNames from 'classnames';
import { resolvePath, getLocalStorageByKey, debounce } from '../../../../common/utility';
import { getTypeOfField } from '../../../../common/forms-utility';
import { emailZeroBounce } from '../../../container/jss-form/helper';
import UIConfig from '../../../../common/UIConfig';
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';

const { Email } = FieldTypes;

const Input = (props) => {
  const { tracker, field, errors, isCorpEmailError, isCorpEmailField = false, defaultValue = '' } = props,
    isConfirmEmailField = field.model.isConfirm,
    // change variable type
    valueFieldName = field.valueField.name,
    valueFieldId = field.valueField.id,
    initialValue = resolvePath(field, 'model.value', '') || defaultValue;

  const [isFocus, setFocus] = useState(false);
  const [fieldValue, setFieldValue] = useState(initialValue);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [url, setUrl] = useState();

  const getMatchEmail = () => {
    if (isConfirmEmailField) {
      const fieldToConfirm = resolvePath(field, 'model.fieldToConfirm', '');
      return { [fieldToConfirm]: props.matchWithEmail };
    }
    return {};
  };
  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  useEffect(() => {
    isConfirmEmailField && fieldValue && handleChange(fieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.matchWithEmail]);

  useEffect(() => {
    const { apiFieldValue } = props;
    if (apiFieldValue) {
      handleChange(apiFieldValue);
      setFieldValue(apiFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiFieldValue]);

  useEffect(() => {
    let jssFormData = getLocalStorageByKey(UIConfig.jssForms.events.jssFormSettingsData);
    let apiUrl = jssFormData?.data?.services?.NewsletterVerifyEmailSubscriptionAPI || '';
    setUrl(apiUrl);
  }, []);

  const handleChange = async (inputValue, zeroBounceApi) => {
    let valid = true,
      errorMessages = [];
    const errorMessagesObj = validateJSSField(field, inputValue, getMatchEmail());
    let message = errorMessagesObj[valueFieldName];

    if (field.model.fieldTypeItemId == Email && inputValue != null && inputValue != undefined) {
      const zeroBounce = url || zeroBounceApi;

      let resData = await emailZeroBounce(zeroBounce?.url);

      if (!resData.data) {
        message = {
          message: zeroBounce.errors[200],
        };
      }
    }

    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }

    setErrorMsg(errorMessages);
    props.getInputValue && props.getInputValue(inputValue);
    // skip in case of confirm email
    props.onChange(valueFieldName, inputValue, valid, errorMessages);
    tracker.onBlurField(field, inputValue, errorMessages);
  };

  const onChange = (e) => {
    const value = e.target.value || '';
    setFieldValue(value);

    if (isCorpEmailField) handleChange(value, url);
  };

  const onFocus = (e) => {
    setFocus(true);
  };

  const onBlur = (e) => {
    const value = e.target.value;
    handleChange(value);
    setFieldValue(value);
    setFocus(false);
  };

  try {
    const isErrorExist = (errorMsg && errorMsg.length) || isCorpEmailError;
    const customWrapperClasses = classNames('form-element', { active: isFocus || fieldValue });
    const inputClasses = classNames('form-input body-copy-3', { error: isErrorExist });

    return (
      <div className={`${customWrapperClasses} ${field.model.cssClass} ${isFocus || fieldValue ? 'active' : ''} `}>
        <LabelComponent field={field} />
        <input
          className={inputClasses}
          name={valueFieldName}
          type={props.type || getTypeOfField(field)}
          value={fieldValue}
          id={valueFieldId}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={field.model.title ? '' : field.model.placeholderText}
          maxLength={field.model.maxLength}
          aria-labelledby={isErrorExist ? `error_${valueFieldId}` : null}
          aria-label={!isErrorExist ? field.model.placeholderText : null}
          aria-invalid={isErrorExist}
          readOnly={props.isReadOnly}
        />
        <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Input');
  }
};

export default Input;
