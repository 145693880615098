import api from '../../../../common/api-wrapper';
import { Logging } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  getErrorMessage,
  getLoggedInUser,
  getOrderIdpromoCode,
  getUserAgent,
  isEmpty,
  massageMobileData,
  removeGuestUser,
  isAnnualPassCase,
  getSessionStorage,
  createAnnualPassData,
  setLocalStorage,
  removeADCBTouchPointsStorage,
  removeMoneyCardStorage,
  getAnonymousCart,
  getTouchPointsPurchaseInfo,
  getErrorMap,
  postNativeMessage,
  resolvePath,
  getMoneyCardPurchaseInfo,
  setSessionStorage,
  isLoggedInUser,
  getLocalStorageByKey,
  getPurchaseAllowed,
  isB2B,
  isTicketUpgradeJourney,
} from '../../../../common/utility';
import { checkTenant } from '../../../../common/utility/tenantsUtils';
import PayfortUtility from './payfort-utility';
import { savePaymentFailureStatus } from '../../../../common/b2b-purchase-journey-utility';
import { getEncrytpedText, checkYAEventId, doublePaymentOverlay } from '../utils';
import ApiWrapper from '../../../../common/api-wrapper';
import { setReconcilRequestAction } from '../../../../utils/paymentUtils';

class PayfortPayment {
  constructor(token = '', paymentData, status, cart = null, responseCode, cardDetails, publicKey) {
    this.tokenName = token;
    this.responseCode = responseCode;
    this.paymentData = paymentData;
    this.payfortType = paymentData.payfortType;
    this.is2StepJourney = paymentData.cmsData.paymentOptions && paymentData.is2StepPayment;
    this.status = status;
    this.cardDetails = cardDetails;
    this.fortId = '';
    this.userInfo = this._getLoggedinUserInfo();
    this.cartData = cart != null && cart.cart != null ? cart.cart : null;
    this.anonymousUser = canUseDOM() ? (Object.keys(this.cartData || {}).length == 0 ? false : true) : false;
    this.serviceKey = {
      makePayment: 'makePayment',
      reconcile: 'reconcile',
      authorizePayment: 'authorizePayment',
      tabbyreconcile: 'tabbyreconcile',
    };
    this.userAgent = getUserAgent();
    this.deviceFingerprint = this._getDeviceFingerprint();
    this.isB2b_reconcileCSAP =
      this.paymentData.cmsData.paymentOptions.pageVariant === 'b2bCreditsettlement' ||
      this.paymentData.cmsData.paymentOptions.pageVariant === 'b2bAdvanceTopUp';
    this.isRenewAnnualPass = this.paymentData.cmsData.paymentOptions.pageVariant === 'annualPassRenew';
    this.ADCBDetails = null;
    this.moneyCardDetails = null;
    this.currentPaymentMethod = this.getPaymentMethod();
    this.payMethodForPaymentPage = this.getPayMethodForPaymentPage();
    this.isADCBPartialPayment = this.checkForADCBTouchPointsPay();
    this.isMoneyCardPartialPayment = this.checkForMoneyCardPartialPay();
    this.isNativeBridge = canUseDOM() && window.NativeBridge;
    this.isTabby = this.payfortType === UIConfig.paymentOption.tabby;
    this.b2bErrorService =
      this.userInfo.tenantID === 'ALLB2B' ? this.paymentData.paymentErrorCode[9991] : 'Error Occured';
  }

  getPaymentMethod = () => {
    if (this.paymentData && this.paymentData.adcbPaymentTypes) {
      return this.paymentData.adcbPaymentTypes;
    } else if (this.paymentData && this.paymentData.moneyCardPaymentTypes) {
      return this.paymentData.moneyCardPaymentTypes;
    }
    return this.payfortType;
  };

  getPayMethodForPaymentPage = () => {
    if (
      this.currentPaymentMethod === UIConfig.paymentOption.adcbCard ||
      this.currentPaymentMethod === UIConfig.paymentOption.adcbTouchPointsADCBCard
    ) {
      return UIConfig.paymentOption.adcbTouchPoints;
    }
    return this.payfortType;
  };

  checkForADCBTouchPointsPay = () => {
    this.ADCBDetails = getTouchPointsPurchaseInfo();
    if (
      this.ADCBDetails &&
      this.ADCBDetails.redeemAmount &&
      this.ADCBDetails.redeemAmount > 0 &&
      UIConfig.b2c.purchaseJourney.touchPoint.paymentType.partial === this.ADCBDetails.paymentType
    ) {
      return true;
    }
    return false;
  };

  checkForMoneyCardPartialPay = () => {
    this.moneyCardDetails = getMoneyCardPurchaseInfo();
    if (
      this.moneyCardDetails &&
      this.moneyCardDetails.redeemAmount &&
      this.moneyCardDetails.redeemAmount > 0 &&
      UIConfig.b2c.purchaseJourney.touchPoint.paymentType.partial === this.moneyCardDetails.paymentType
    ) {
      return true;
    }
    return false;
  };

  _getDeviceFingerprint = () => {
    if (canUseDOM()) {
      const input = document.getElementById('device_fingerprint');
      if (input) {
        return input.value;
      }
    }
  };

  _getLoggedinUserInfo = () => {
    if (this.userInfo) {
      return this.userInfo;
    } else {
      return (this.userInfo = getLoggedInUser());
    }
  };

  _isCardValid = () => {
    return this.status === UIConfig.payfort.status.tokenizationSuccess;
  };

  _getAPIData = (data) => {
    var apiData = {};
    apiData.command = data.cmsData.command;
    apiData.currency = data.currency;
    apiData.eci = data.cmsData.eci;
    apiData.amount = data.price;
    apiData.token_name = this.tokenName;
    if (data.plan_code) {
      apiData.issuer_code = data.issuer_code;
      apiData.installments = data.installments;
      apiData.plan_code = data.plan_code;
    }
    apiData.merchant_reference = data.merchantReference;
    if (data.actualMerchantReference) {
      apiData.order_description = data.actualMerchantReference;
    }
    if (this.anonymousUser && this.cartData) {
      if (isTicketUpgradeJourney()) {
        const ticketUpgradeData = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);
        apiData.customer_email = ticketUpgradeData?.loggedAccount?.email;
      } else {
        apiData.customer_email = this.cartData.reservationOwner.email;
      }
    } else if (this.userInfo.tenantID.toLowerCase() === UIConfig.YIB2C && !this.userInfo.yasId) {
      // Get user email from local storage if payment journey is guest checkout
      const guestUserDetails = getLocalStorageByKey('guestCheckoutUserDetails');
      if (guestUserDetails) apiData.customer_email = this.userInfo.email = guestUserDetails.Email;
    } else {
      apiData.customer_email = this.userInfo.email;
    }
    if (data.isFrictionlessPayfort) {
      apiData.merchant_extra = this.userInfo.yasId;
    }
    const customerIpLocation = getSessionStorage('customerIpLocation') || '';
    apiData.customer_ip = customerIpLocation || data.trueClientIP || data.cmsData.customerIP;
    apiData.access_code = data.cmsData.accessCode;
    apiData.merchant_identifier = data.cmsData.merchantIdentifier;
    apiData.language = data.language;
    apiData.return_url = data.cmsData.interMediatePageUrl2;
    //device fingerprint for ReD security
    if (data.cmsData.rememberMe === 'YES') {
      apiData.remember_me = data.cmsData.rememberMe;
    }
    if (this.deviceFingerprint && this.userInfo.deviceFingerprint) {
      apiData.device_fingerprint = this.deviceFingerprint;
    }

    return apiData;
  };

  _get3dUrl = (paymentData) => {
    let apiData = this._getAPIData(paymentData);

    if (this.userInfo.tenantID.toLowerCase() === UIConfig.YIB2C) {
      apiData.merchant_extra = this.paymentData.cmsData.merchantExtra;
      apiData.merchant_extra1 = this.paymentData.cmsData.merchantExtra1;
    }

    apiData.signature = PayfortUtility.getPayFortSignature(apiData, paymentData.cmsData.merchantPassphrase);

    const url = this.getReconcileUrl();
    let payfortPayload = {
      payfortPurchaseObj: apiData,
      reconcilationObj: this._getReconcileData(),
      reconcilationReqest: url,
      url: paymentData.cmsData.payFortUrl2,
    };
    // add if bulkorder is true isBulkOrder
    if (getPurchaseAllowed() && isB2B()) {
      payfortPayload = { ...payfortPayload, isBulkOrder: true };
    }
    const insertUrls = () => {
      if (this.isTabby) return paymentData.cmsData.services.insertabbyreconcile.url;
      return paymentData.cmsData.services.makePayment.url;
    };
    let payfortConfig = {
      method: 'POST',
      url: insertUrls(),
      data: payfortPayload,
    };
    if ((isEmpty(this.userInfo) || !this.userInfo.idToken) && this.cartData) {
      return api.api(payfortConfig);
    } else {
      return api.experienceServices(payfortConfig);
    }
  };

  _getAuthorizationUrl = (paymentData) => {
    let apiData = this._getAPIData(paymentData);
    apiData.signature = PayfortUtility.getPayFortSignature(apiData, paymentData.cmsData.merchantPassphrase);
    let payfortConfig = {
      method: 'POST',
      url: paymentData.cmsData.services.authorizePayment.url,
      data: {
        payfortAuthorizeObj: apiData,
        url: paymentData.cmsData.payFortUrl2,
      },
    };
    return api.experienceServices(payfortConfig);
  };

  _getReconcileData = (flexiPayData = {}) => {
    let data = {};
    var cmsData = this.paymentData.cmsData;
    const {
      apMainPassholderRenewEmailTemplateID,
      apMainPassholderPurchaseEmailTemplateID,
      apAddPassholderRenewEmailTemplateID,
      apAddPassholderPurchaseEmailTemplateID,
      apAddPassholderNewUserRenewEmailTemplateID,
      apAddPassholderNewUserPurchaseEmailTemplateID,
    } = cmsData.paymentOptions;
    var isRenewAnnualPass = cmsData.paymentOptions.pageVariant === 'annualPassRenew';
    const cartData = isRenewAnnualPass
      ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
      : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart));
    const isTicketUpgrade = isTicketUpgradeJourney();
    if (cartData || this.isTabby) {
      var isAnnualPass = !this.isTabby && isAnnualPassCase(cartData, true);
      if (isAnnualPass || this.isTabby) {
        const annualPassTicketHolders = createAnnualPassData({ cartData });
        data.annualPassRequest = {
          isAnnualRenew: isRenewAnnualPass,
          emailTemplateIdAnnualPass: isRenewAnnualPass
            ? apMainPassholderRenewEmailTemplateID
            : apMainPassholderPurchaseEmailTemplateID,
          emailTemplateIdWithYasId: isRenewAnnualPass
            ? apAddPassholderRenewEmailTemplateID
            : apAddPassholderPurchaseEmailTemplateID,
          emailTemplateIdWithNoYasId: isRenewAnnualPass
            ? apAddPassholderNewUserRenewEmailTemplateID
            : apAddPassholderNewUserPurchaseEmailTemplateID,

          ticketHolders: annualPassTicketHolders,
        };
      }
    }
    data.isTicketUpgradeRequest = isTicketUpgrade;
    data.payfortReconcileObj = {
      query_command: this.paymentData.cmsData.queryCommand,
      access_code: this.paymentData.cmsData.accessCode,
      merchant_identifier: this.paymentData.cmsData.merchantIdentifier,
      merchant_reference: this.paymentData.merchantReference,
      language: this.paymentData.language,
      fort_id: this.fortId,
      ...(this.isTabby && { cardInfo: null }),
    };

    if (this.isB2b_reconcileCSAP) {
      data.creditSettlementInvoice = this.paymentData.CreditAks;

      data.reconType = this.paymentData.pageVarient;
      // data.transactionId= "testId";
      data.partnerName = this._getLoggedinUserInfo().userName;
      data.totalAmount = this.paymentData.price / 100;
    }
    const { cardInfo, ...signatureData } = data.payfortReconcileObj;
    data.payfortReconcileObj.signature = PayfortUtility.getPayFortSignature(
      signatureData,
      this.paymentData.cmsData.merchantPassphrase,
    );

    // append extra params for yasisland
    if (this.userInfo.tenantID.toLowerCase() === UIConfig.YIB2C) {
      data.travelBoxAddPaymentUrl = this.paymentData.cmsData.services.TravelBoxAddPaymentUrl.url;
      data.travelBoxConfirmBookingUrl = this.paymentData.cmsData.services.TravelBoxConfirmBookingUrl.url;

      data.payfortReconcileObj.merchant_extra = this.paymentData.cmsData.merchantExtra;
      data.payfortReconcileObj.merchant_extra1 = this.paymentData.cmsData.merchantExtra1;
    }

    data.url = this.paymentData.cmsData.payFortUrl2;
    data.locale = canUseDOM() ? window.localStorage.getItem('currentLanguage') : this.paymentData.language;
    data.orderId = this.paymentData.orderId;
    data.emailTemplateId = this.paymentData.emailTemplateId;
    data.invoiceTemplateId = this.paymentData.invoiceTemplateId;
    data.marketType = this.paymentData.marketType;
    data.paymentMethod = this.currentPaymentMethod;
    data.tenantId = this.userInfo.tenantID;
    if (this.isTabby) {
      data.amount = this.paymentData.price;
      data.settlement_reference = this.paymentData?.settlement_reference;
    }
    data.isOrderHistoryPayment = (canUseDOM() && JSON.parse(localStorage.getItem('isOrderHistoryPayment'))) || false;
    const getOrderIdpromoCodeTemp = getOrderIdpromoCode();
    const orderIdpromoCode = getOrderIdpromoCodeTemp ? getOrderIdpromoCodeTemp.split('_') : 'null';
    data.promoCode = orderIdpromoCode[1] == 'null' ? null : orderIdpromoCode[1];
    if (this.anonymousUser) {
      if (isTicketUpgrade) {
        const ticketUpgradeData = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);
        data.userId = ticketUpgradeData?.loggedAccount?.email;
      } else {
        data.userId = getAnonymousCart().cart.reservationOwner.email;
      }
    } else {
      data.userId = getLoggedInUser().email;
    }
    const b2bTotalQty = getLocalStorageByKey(UIConfig.localStoreKeys.b2bTotalQuantity);
    if (b2bTotalQty > Number(this.paymentData.cmsData.maxTickets)) {
      data.isBulkOrder = true;
    } else {
      data.isBulkOrder = false;
    }

    const orderApprovalInfo = localStorage.getItem('orderApprovalInfo');
    if (orderApprovalInfo) {
      const cardInfoDecrypted = atob(orderApprovalInfo);
      if (cardInfoDecrypted) {
        const { cardNumber, paymentOption } = JSON.parse(cardInfoDecrypted);
        const numbrOfInstalls = localStorage.getItem(UIConfig.appLocalStorageKeys.numberOfInstallments);
        data.payfortReconcileObj.cardInfo = {
          approval_code:
            !isEmpty(flexiPayData) && flexiPayData.authorization_code
              ? flexiPayData.authorization_code
              : this.paymentData.authorizationCode,
          card_number: cardNumber || '',
          card_type: paymentOption || '',
          app_label:
            !numbrOfInstalls || numbrOfInstalls == '0' || numbrOfInstalls == 'null'
              ? ''
              : UIConfig.paymentOption.installments,
          number_of_installments: numbrOfInstalls,
        };
        localStorage.removeItem('orderApprovalInfo');
      }
    }

    if (this.isADCBPartialPayment && this.ADCBDetails) {
      const userData = {};
      if (this.anonymousUser) {
        const anonymousUserData = getAnonymousCart();
        userData.firstName = anonymousUserData && anonymousUserData.cart.reservationOwner.firstName;
      } else {
        const loggedInUserData = getLoggedInUser();
        userData.firstName = loggedInUserData && loggedInUserData.userName;
      }
      data.redemptionRequest = {
        cardNumber: this.ADCBDetails.cardNum,
        customerName: userData.firstName,
        expiryDate: this.ADCBDetails.expiry,
        tpRedemptionFlag: this.ADCBDetails.paymentType,
        otp: '11',
        otpReferenceNbr: '',
        redeemAmount: this.ADCBDetails.redeemAmount,
        cardAmount: this.ADCBDetails.cardAmount,
      };
    }

    if (this.isMoneyCardPartialPayment && this.moneyCardDetails) {
      const userData = {};
      if (this.anonymousUser) {
        const anonymousUserData = getAnonymousCart();
        userData.firstName = anonymousUserData && anonymousUserData.cart.reservationOwner.firstName;
      } else {
        const loggedInUserData = getLoggedInUser();
        userData.firstName = loggedInUserData && loggedInUserData.userName;
      }
      data.redemptionRequest = {
        // cardNumber: this.moneyCardDetails.cardNum,
        customerName: userData.firstName,
        tpRedemptionFlag: this.moneyCardDetails.paymentType,
        redeemAmount: this.moneyCardDetails.redeemAmount,
        cardAmount: this.moneyCardDetails.cardAmount,
        moneyCardList: this.moneyCardDetails.moneyCardList,
      };
    }
    return { ...data, userId: getEncrytpedText(data.userId) };
  };

  pageRedirect = ({ paymentPageErrorMessage, status, errorData, serviceKey, currentStep }) => {
    if (this.is2StepJourney && !isEmpty(paymentPageErrorMessage)) {
      window.top.location.href =
        `${this.paymentData.cmsData.paymentOptions.paymentURL.url}` +
        '?payment_error=' +
        paymentPageErrorMessage +
        '#' +
        this.payMethodForPaymentPage;
    } else if (this.isNativeBridge) {
      const errorObj = getErrorMap(
        serviceKey,
        this.paymentData.cmsData.services[serviceKey].errors,
        false,
        errorData,
        null,
      );
      postNativeMessage({
        isSuccess: false,
        myPassId: this.userInfo.yasId,
        error: {
          code: resolvePath(errorObj, `${serviceKey}.code`, '') || 'UNKNOWN',
          description: resolvePath(errorObj, `${serviceKey}.text`, ''),
        },
      });
    } else {
      if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
        window.top.location.href =
          this.paymentData.thankYouPageUrl +
          '?' +
          UIConfig.querStringParams.thankYouPage.status +
          '=' +
          status +
          '&' +
          UIConfig.querStringParams.thankYouPage.reference +
          '=' +
          this.currentPaymentMethod +
          '&errorStep=' +
          currentStep;
      } else {
        window.top.location.href =
          this.paymentData.thankYouPageUrl +
          '?' +
          UIConfig.querStringParams.thankYouPage.status +
          '=' +
          status +
          '&' +
          UIConfig.querStringParams.thankYouPage.reference +
          '=' +
          this.currentPaymentMethod;
      }
    }
  };

  _redirectToThankYouPage = (
    status,
    orderId = 0,
    serviceKey = '',
    responseOrError = null,
    paymentPageErrorMessage = {},
    paymentStatus,
    currentStep,
  ) => {
    PayfortUtility.clearLocalStore();
    removeGuestUser();
    if (status) {
      if (!this.userAgent) {
        localStorage.removeItem('yasIdUserData');
        if (this.isRenewAnnualPass) {
          sessionStorage.removeItem('cartForAnnualPassRenewal');
          sessionStorage.removeItem(UIConfig.payloadForRenew);
          sessionStorage.removeItem('annualPassRenewalData');
        }
        const isRenewAnnualPass = this.isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
        const ticketUpgrade = isTicketUpgradeJourney() ? '&isTicketUpgrade=true' : '';
        if (this.userInfo.tenantID !== 'ALLB2B') {
          if (!isLoggedInUser()) {
            const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
            this.userInfo.tenantID = resolvePath(getMainObj, 'tenantID', '');
          }

          if (this.userInfo.tenantID.toLowerCase() === UIConfig.YIB2C) {
            const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
            sessionStorage.removeItem('cartId');

            if (_isAddonAddedAfterBooking == 'true') {
              setSessionStorage('addonSuccessfullyAdded', 'true');
            }

            sessionStorage.removeItem('addonAddedAfterConfirmation');

            if (this.paymentData.orderId.includes('_')) {
              // ! Underscore will only come in upsell of addon journey in payfort. We need to remove values after underscore from order id.
              this.paymentData.orderId = this.paymentData.orderId.split('_')[0];
            }
            // Storing bookingId for after payment services i.e Upsell of addons
            setSessionStorage('bookingId', this.paymentData.orderId);
          }

          window.top.location.href =
            this.paymentData.thankYouPageUrl +
            '?' +
            checkYAEventId() +
            UIConfig.querStringParams.thankYouPage.status +
            '=' +
            status +
            '&' +
            UIConfig.querStringParams.thankYouPage.orderId +
            '=' +
            btoa(this.paymentData.orderId) +
            '&' +
            UIConfig.querStringParams.thankYouPage.reference +
            '=' +
            this.currentPaymentMethod +
            isRenewAnnualPass +
            ticketUpgrade;
        } else {
          localStorage.removeItem('userReservationInfo');
          window.top.location.href =
            this.paymentData.thankYouPageUrl +
            '?' +
            UIConfig.querStringParams.thankYouPage.status +
            '=' +
            status +
            '&' +
            UIConfig.querStringParams.thankYouPage.orderId +
            '=' +
            encodeURIComponent(this.paymentData.orderId) +
            '&' +
            UIConfig.querStringParams.thankYouPage.reference +
            '=' +
            this.currentPaymentMethod +
            (this.paymentData.notEncrypted ? '&notEncrypted=true' : '') +
            '&' +
            'isPage' +
            '=' +
            this.paymentData.pageVarient +
            isRenewAnnualPass +
            ticketUpgrade;
        }
      } else {
        window.JSbridge.nativeCallback(
          massageMobileData({
            status: status,
            orderID: this.paymentData.orderId || null,
            ref: this.currentPaymentMethod,
          }),
        );
      }
    } else {
      let errorData = PayfortUtility.getErrorObject(serviceKey, this.paymentData.cmsData.services, responseOrError);
      // only for B2B: saving the payement failure status
      localStorage.setItem(UIConfig.localStoreKeys.payment.errorMessage, JSON.stringify(errorData));
      if (this.userInfo.tenantID === 'ALLB2B') {
        savePaymentFailureStatus(
          {
            reservationCode: this.paymentData.resCode,
            paymentStatus: paymentStatus || this.paymentData.paymentErrorCode[9991],
          },
          this.paymentData.paymentFailureUrl,
        )
          .then((res) => {
            this.pageRedirect({ paymentPageErrorMessage, status, errorData, serviceKey });
          })
          .catch((err) => {
            this.pageRedirect({ paymentPageErrorMessage, status, errorData, serviceKey });
          });
      } else if (this.userInfo.tenantID.toLowerCase() === UIConfig.YIB2C) {
        this.pageRedirect({ paymentPageErrorMessage, status, errorData, serviceKey, currentStep });
      } else {
        this.pageRedirect({ paymentPageErrorMessage, status, errorData, serviceKey });
      }
    }
  };

  getReconcileUrl = () => {
    let url = '';
    let encryptionParam = this.paymentData.notEncrypted ? '?isEncrypted=false' : '';
    const { services } = this.paymentData.cmsData;
    if (this.isTabby) return services.tabbyreconcile.url;
    if (this.isADCBPartialPayment) {
      url = services.touchPointsRedemption && services.touchPointsRedemption.url + encryptionParam;
    } else if (this.isMoneyCardPartialPayment) {
      url = services.moneyCardredemptionReconcile && services.moneyCardredemptionReconcile.url + encryptionParam;
    } else if (this.isB2b_reconcileCSAP) {
      url = services.reconcileCSAP.url + encryptionParam;
    } else {
      url = services.reconcile.url + encryptionParam;
    }
    return url;
  };

  _reconcilePrice = (flexiPayData = {}) => {
    const url = this.getReconcileUrl();
    let reconcileConfig = {
      method: 'POST',
      url,
      data: this._getReconcileData(flexiPayData),
    };
    localStorage.removeItem(UIConfig.appLocalStorageKeys.numberOfInstallments);
    if (this.anonymousUser && this.cartData) {
      return api.api(reconcileConfig);
    } else {
      return api.experienceServices(reconcileConfig);
    }
  };

  genericVerifyResponse = (data) => {
    const orderApprovalInfo = {
      approvalCode: data.response_code || null,
      cardNumber: data.card_number || null,
      paymentOption: data.payment_option || null,
    };
    setLocalStorage('orderApprovalInfo', btoa(JSON.stringify(orderApprovalInfo)));
    let signData = { ...data };
    delete signData.signature;
    for (let key in signData) {
      if (signData[key] === null) {
        delete signData[key];
      }
    }
    const responseSignature = PayfortUtility.getPayFortSignature(
      signData,
      this.paymentData.cmsData.merchantResponsePassphrase,
    );
    return responseSignature === data.signature;
  };

  _verifyResponse = (data) => {
    if (!data) {
      return false;
    }
    if (data.status === UIConfig.payfort.status.paymentSuccess) {
      return this.genericVerifyResponse(data);
    }
    return false;
  };

  _verifyResponseForFlexi3D = (data) => {
    if (!data) {
      return false;
    }
    if (data.status === UIConfig.payfort.status.reconcileSucccess) {
      return this.genericVerifyResponse(data);
    }
    return false;
  };

  makePayment() {
    let cardFailRes = '';
    if (this._isCardValid() && this.paymentData) {
      if (this.paymentData.isFrictionlessPayfort) {
        this._getAuthorizationUrl(this.paymentData)
          .then((response) => {
            if (this._verifyResponse(response.data)) {
              Logging(
                response,
                this.paymentData.componentName || 'Payfort_Authorize',
                true,
                'step 1 Authorize success',
              );
              window.top.location.href = response.data[UIConfig.payfort.threedUrlKey];
            } else {
              Logging(response, this.paymentData.componentName, false, 'Authorize status fail');
              this._redirectToThankYouPage(
                false,
                0,
                this.serviceKey.authorizePayment,
                response.data,
                {},
                this.b2bErrorService,
              );
            }
          })
          .catch((err) => {
            Logging(err, this.paymentData.componentName, false, 'Authorize Payment api call failed');
            this._redirectToThankYouPage(
              false,
              0,
              this.serviceKey.authorizePayment,
              err.error,
              {},
              this.b2bErrorService,
            );
          });
      } else {
        this._get3dUrl(this.paymentData)
          .then((response) => {
            localStorage.setItem(
              UIConfig.appLocalStorageKeys.numberOfInstallments,
              response.data?.number_of_installments,
            );
            if (response?.data?.error && checkTenant(UIConfig.yasArenaB2CTenant)) {
              doublePaymentOverlay(response.data.error);
            }

            if (this._verifyResponse(response.data)) {
              Logging(response, this.paymentData.componentName || 'Payfort_3DURL', true, 'step 1 3DURL success');
              if (
                (this.userInfo && this.userInfo.email) ||
                (this.cartData != null && this.cartData.reservationOwner != null)
              ) {
                window.top.location.href = response.data[UIConfig.payfort.threedUrlKey];
              } else {
                Logging(response, this.paymentData.componentName, false, 'User Info/ User Email missing');
                this._redirectToThankYouPage(false, 0, this.serviceKey.makePayment, response.data);
              }
            } else if (
              response.data.status === UIConfig.payfort.status.reconcileSucccess &&
              this._verifyResponseForFlexi3D(response.data)
            ) {
              this.reconcilePrice(response.data);
            } else {
              Logging(response, this.paymentData.componentName, false, '3DURL status fail');
              if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
                this._redirectToThankYouPage(
                  false,
                  0,
                  this.serviceKey.makePayment,
                  response.data,
                  {},
                  this.b2bErrorService,
                  this.is2StepJourney && 'one',
                );
              } else {
                this._redirectToThankYouPage(
                  false,
                  0,
                  this.serviceKey.makePayment,
                  response.data,
                  {},
                  this.b2bErrorService,
                );
              }
            }
          })
          .catch((err) => {
            Logging(err, this.paymentData.componentName, false, '3DURL api call failed');
            if (err.error && checkTenant(UIConfig.yasArenaB2CTenant)) {
              // send3durl failed Double payment
              doublePaymentOverlay(err.error);
            } else if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
              this._redirectToThankYouPage(
                false,
                0,
                this.serviceKey.makePayment,
                err.error,
                {},
                this.b2bErrorService,
                this.is2StepJourney && 'one',
              );
            } else {
              this._redirectToThankYouPage(false, 0, this.serviceKey.makePayment, err.error, {}, this.b2bErrorService);
            }
          });
      }
    } else {
      Logging(
        cardFailRes,
        this.paymentData ? this.paymentData.componentName : 'Payfort Payment',
        false,
        'Card Validation Failure',
      );
      const error = { code: '17', text: 'Tokenization failed.' };

      if (this.is2StepJourney) {
        this.paymentPageError = getErrorMessage({
          obj: this.paymentData.cmsData.services.paymentPageTokenization.errors,
          errCode: this.responseCode,
        });
      }
      this._redirectToThankYouPage(
        false,
        0,
        this.paymentData.isFrictionlessPayfort ? this.serviceKey.authorizePayment : this.serviceKey.makePayment,
        error,
        this.paymentPageError,
        this.b2bErrorService,
      );
    }
  }

  authorizeSucess() {
    if (this.paymentData && this.status === UIConfig.payfort.status.authorizationSuccess) {
      this.fortId = this.paymentData.fortId;
      if (!this.isNativeBridge) {
        this._redirectToThankYouPage(true, 0);
      } else {
        postNativeMessage({
          isSuccess: true,
          myPassId: this.userInfo.yasId,
          error: null,
          cardNumber: this.cardDetails.cardNumber,
          expiryDate: this.cardDetails.expiryDate,
        });
      }
    } else {
      Logging('', this.paymentData.componentName, false, 'Payment Failure step 2');
      const errorData = { code: this.status, text: 'Transaction Failure' };
      if (this.responseCode) {
        errorData.response_code = this.responseCode;
      }
      if (!this.isNativeBridge) {
        this._redirectToThankYouPage(false, 0, this.serviceKey.authorizePayment, errorData);
      } else {
        const errorObj = getErrorMap(
          this.serviceKey.authorizePayment,
          this.paymentData.cmsData.services[this.serviceKey.authorizePayment].errors,
          false,
          errorData,
          null,
        );
        postNativeMessage({
          isSuccess: false,
          myPassId: this.userInfo.yasId,
          error: {
            code: this.responseCode || 'UNKNOWN',
            description: resolvePath(errorObj, `${this.serviceKey.authorizePayment}.text`, ''),
          },
        });
      }
    }
  }

  reconcilePrice(flexiPayData = {}) {
    let reconcileFailRes = '';
    if (
      this.paymentData &&
      (this.status === UIConfig.payfort.status.reconcileSucccess ||
        flexiPayData.status === UIConfig.payfort.status.reconcileSucccess)
    ) {
      setReconcilRequestAction();
      this.fortId = !isEmpty(flexiPayData) && flexiPayData.fort_id ? flexiPayData.fort_id : this.paymentData.fortId;
      this._reconcilePrice(flexiPayData)
        .then((response) => {
          if (response?.data?.error && checkTenant(UIConfig.yasArenaB2CTenant)) {
            doublePaymentOverlay(response.data.error);
          }
          if (response.data && response.data.isSuccess) {
            Logging(
              response,
              this.paymentData.componentName || 'Payfort_reconcile',
              true,
              'step2 reconcile price success',
            );
            this._redirectToThankYouPage(
              true,
              getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C
                ? response.data.orderId
                : this.paymentData.orderId,
            );
          } else {
            Logging(response, this.paymentData.componentName, false, 'Reconcile Status False');
            removeADCBTouchPointsStorage();
            removeMoneyCardStorage();
            if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
              this._redirectToThankYouPage(
                false,
                0,
                this.serviceKey.reconcile,
                response.data.responseMessage.error,
                {},
                'System Failure',
                this.is2StepJourney && 'two',
              );
            } else {
              this._redirectToThankYouPage(
                false,
                0,
                this.serviceKey.reconcile,
                response.data.responseMessage.error,
                {},
                'System Failure',
              );
            }
          }
        })
        .catch((err) => {
          Logging(err, this.paymentData.componentName, false, 'Reconcile api call Failed');
          removeADCBTouchPointsStorage();
          removeMoneyCardStorage();
          if (err.error && checkTenant(UIConfig.yasArenaB2CTenant)) {
            // reconcileation failed Double payment
            doublePaymentOverlay(err.error);
          } else if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
            this._redirectToThankYouPage(
              false,
              0,
              this.serviceKey.reconcile,
              err.error,
              {},
              'System Failure',
              this.is2StepJourney && 'two',
            );
          } else {
            this._redirectToThankYouPage(false, 0, this.serviceKey.reconcile, err.error, {}, 'System Failure');
          }
        });
    } else {
      Logging(reconcileFailRes, this.paymentData.componentName, false, 'Payment Failure before reconcile');
      const error = { code: this.status, text: 'Transaction Failure' };
      if (this.responseCode) {
        error.response_code = this.responseCode;
      }
      removeADCBTouchPointsStorage();
      removeMoneyCardStorage();
      if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
        this._redirectToThankYouPage(
          false,
          0,
          this.serviceKey.makePayment,
          error,
          {},
          '',
          this.is2StepJourney && 'two',
        );
      } else {
        this._redirectToThankYouPage(false, 0, this.serviceKey.makePayment, error);
      }
    }
  }
  makeTabbyPayment = async () => {
    if ((this.paymentData?.cmsData?.tabbyErrorsCode ?? ['13', '00']).find((item) => item === this.status)) {
      Logging('', this.paymentData ? this.paymentData.componentName : 'Payfort Payment', false, 'Transaction Failed');
      const error = { code: '17', text: 'Tokenization failed.' };

      if (this.is2StepJourney) {
        this.paymentPageError = getErrorMessage({
          obj: this.paymentData.cmsData.services[
            this.isTabby ? this.serviceKey.tabbyreconcile : 'paymentPageTokenization'
          ].errors,
          errCode: this.responseCode,
        });
      }
      this._redirectToThankYouPage(
        false,
        0,
        this.paymentData.isFrictionlessPayfort ? this.serviceKey.authorizePayment : this.serviceKey.tabbyreconcile,
        error,
        this.paymentPageError,
        this.b2bErrorService,
      );
      return;
    }
    this._get3dUrl(this.paymentData)
      .then((res) => {
        this.reconcilePrice();
      })
      .catch((err) => {
        Logging(err, this.paymentData.componentName, false, '3DURL api call failed');
        if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
          this._redirectToThankYouPage(
            false,
            0,
            this.serviceKey.makePayment,
            err.error,
            {},
            this.b2bErrorService,
            this.is2StepJourney && 'one',
          );
        } else {
          this._redirectToThankYouPage(false, 0, this.serviceKey.makePayment, err.error, {}, this.b2bErrorService);
        }
      });
  };
}

export default PayfortPayment;
