import UIConfig from '../../../common/UIConfig';
import {
  canUseDOM,
  getOrderIdpromoCode,
  removeGuestUser,
  getSessionStorage,
  isAnnualPassCase,
  createAnnualPassData,
  parseQueryString,
  getUserAgent,
  massageMobileData,
  getErrorDataInObject,
  getAnonymousCart,
  getLoggedInUser,
} from '../../../common/utility';
import api from '../../../common/api-wrapper';
import { Logging } from '../../../common/logger';
import { setReconcilRequestAction } from '../../../utils/paymentUtils';

class AliPayPayment {
  constructor({ aliPayFinalData, cmsData, paymentDataAnonymous }) {
    this.paymentFailure = ['2010', '2011'];
    this.aliPayData = aliPayFinalData;
    this.paymentData = cmsData || {};
    this.paymentData.componentName = UIConfig.paymentOption.aliPay;
    this.cartData = paymentDataAnonymous && paymentDataAnonymous.cart != null ? paymentDataAnonymous.cart : null;
    this.anonymousUser = canUseDOM() && this.cartData;
    this.isRenewAnnualPass = false;
  }

  /**
   *  Successfull Payment
   *  Send status to Native app
   */

  actionMobileApp = ({ status }) => {
    window.JSbridge.nativeCallback(
      massageMobileData({
        status: status,
        orderID: localStorage.getItem('orderId') || null,
        ref: UIConfig.paymentOption.aliPay,
      }),
    );
  };

  /**
   *  Redirect action method
   *  if something fails
   */

  pageRedirectURL = ({ paymentData, pageUrl, status, key, responseOrError }) => {
    if (paymentData) {
      const { url, error } = paymentData;
      window.top.location.href = `${url.href}?payment_error=${error}#${UIConfig.paymentOption.aliPay}`;
    } else {
      let errorData = getErrorDataInObject(key, this.paymentData.services, responseOrError);
      localStorage.setItem(UIConfig.localStoreKeys.payment.errorMessage, JSON.stringify(errorData));
      window.location.href = `${pageUrl.url}?${UIConfig.querStringParams.thankYouPage.status}=${status}&${UIConfig.querStringParams.thankYouPage.reference}=${UIConfig.paymentOption.aliPay}`;
    }
  };

  /**
   *  Successfull Payment
   *  Complete Payment and close Payment Sheet
   *  call reconcile to close order
   */

  redirectToActionPage = ({ isSuccess, status, statusCode }) => {
    const {
      cmsData: { primaryCTA, paymentURL },
    } = this.paymentData;
    const {
      AliPayTransaction: { errors },
    } = this.paymentData.services;
    if (isSuccess) {
      setReconcilRequestAction();
      this.reconciliation()
        .then((res) => {
          const orderId = btoa(localStorage.getItem('orderId'));
          const isRenewAnnualPass = this.isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
          if (res.data.isSuccess) {
            Logging(res, this.paymentData.componentName, true, `Ali pay reconcall success`);
            this.clearStorage();
            if (!getUserAgent()) {
              window.location.href = `${primaryCTA.url}?${UIConfig.querStringParams.thankYouPage.status}=${status}&${UIConfig.querStringParams.thankYouPage.orderId}=${orderId}&${UIConfig.querStringParams.thankYouPage.reference}=${UIConfig.paymentOption.aliPay}${isRenewAnnualPass}`;
            } else {
              this.actionMobileApp({ status });
            }
          } else {
            Logging(res.data, this.paymentData.componentName, false, 'Reconcile Status False');
            this.pageRedirectURL({ pageUrl: primaryCTA, status: false, key: 'AlipayRecon' });
          }
        })
        .catch((err) => {
          Logging(err, this.paymentData.componentName, true, `Ali pay reconcall error`);
          this.pageRedirectURL({ pageUrl: primaryCTA, status: false, key: 'AlipayRecon', responseOrError: err.error });
        });
    } else {
      if (!getUserAgent()) {
        if (this.paymentFailure.indexOf(statusCode) > -1) {
          const paymentData = {
            url: paymentURL,
            error: errors[statusCode],
          };
          this.pageRedirectURL({ paymentData });
        } else {
          this.pageRedirectURL({ pageUrl: primaryCTA, status: false, key: 'AliPayTransaction' });
        }
      } else {
        this.actionMobileApp({ status });
      }
    }
  };

  /**
   *  if payment success, move to confirmation page
   *  if fails, redirect to payment journey
   */

  moveToNextPaymentStep = () => {
    const statusCode = this.aliPayData.transactionstatus;
    if (statusCode === '2000') {
      this.redirectToActionPage({
        isSuccess: true,
        status: true,
      });
    } else {
      this.redirectToActionPage({
        isSuccess: false,
        status: false,
        statusCode,
      });
    }
  };

  /**
   *  Begin Reconcilation
   *  When successfull -  redirects to Confirmation Page.
   *  When Failed -  close payment sheet and show error
   */

  reconciliation = () => {
    let userId;
    let annualPassRequest = null;
    const { transactionid } = this.aliPayData;
    const {
      emailTemplateId,
      invoiceTemplateId,
      conversionRate,
      tenantID,
      pageVariant,
      apMainPassholderRenewEmailTemplateID,
      apMainPassholderPurchaseEmailTemplateID,
      apAddPassholderRenewEmailTemplateID,
      apAddPassholderPurchaseEmailTemplateID,
      apAddPassholderNewUserRenewEmailTemplateID,
      apAddPassholderNewUserPurchaseEmailTemplateID,
    } = this.paymentData.cmsData;
    const { resCode, amount } = this.paymentData.orderData;
    const { AlipayRecon } = this.paymentData.services;
    const getOrderIdpromoCodeTemp = getOrderIdpromoCode();
    const orderIdpromoCode = getOrderIdpromoCodeTemp ? getOrderIdpromoCodeTemp.split('_') : 'null';
    const isRenewAnnualPass = pageVariant === 'annualPassRenew';
    this.isRenewAnnualPass = isRenewAnnualPass;
    const cartData = isRenewAnnualPass
      ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
      : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart));
    const orderId = localStorage.getItem('orderId');

    if (this.anonymousUser) {
      userId = JSON.parse(localStorage.getItem('anonymousCart')).cart.reservationOwner.email;
    } else {
      if (cartData) {
        const isAnnualPass = isAnnualPassCase(cartData, true);
        if (isAnnualPass) {
          const annualPassTicketHolders = createAnnualPassData({ cartData });
          annualPassRequest = {
            isAnnualRenew: isRenewAnnualPass,
            emailTemplateIdAnnualPass: isRenewAnnualPass
              ? apMainPassholderRenewEmailTemplateID
              : apMainPassholderPurchaseEmailTemplateID,
            emailTemplateIdWithYasId: isRenewAnnualPass
              ? apAddPassholderRenewEmailTemplateID
              : apAddPassholderPurchaseEmailTemplateID,
            emailTemplateIdWithNoYasId: isRenewAnnualPass
              ? apAddPassholderNewUserRenewEmailTemplateID
              : apAddPassholderNewUserPurchaseEmailTemplateID,
            ticketHolders: annualPassTicketHolders,
          };
        }
      }
      userId = getLoggedInUser().email;
    }
    const apiData = {
      alipayReconcileObj: {
        clientInfolanguage: 'us',
        transactionId: transactionid,
        reservationCode: !isRenewAnnualPass ? resCode : '',
      },
      locale: 'en',
      orderId: orderId,
      emailTemplateId: emailTemplateId,
      tenantId: tenantID,
      paymentMethod: 'alipay',
      marketType: ' ',
      invoiceTemplateId: invoiceTemplateId,
      userId: userId,
      promoCode: orderIdpromoCode[1] == 'null' ? null : orderIdpromoCode[1],
      annualPassRequest: annualPassRequest,
      conversionRate: conversionRate,
      amount: amount,
    };
    const aliPayConfig = {
      method: 'POST',
      url: `${AlipayRecon.url}?isEncrypted=true`,
      data: {
        ...apiData,
      },
    };

    if (this.anonymousUser) {
      return api.api(aliPayConfig);
    } else {
      return api.experienceServices(aliPayConfig);
    }
  };

  /**
   *  clear stored data on localstorage
   */

  clearStorage = () => {
    if (this.isRenewAnnualPass) {
      sessionStorage.removeItem('cartForAnnualPassRenewal');
      sessionStorage.removeItem(UIConfig.payloadForRenew);
      sessionStorage.removeItem('annualPassRenewalData');
    }
    localStorage.removeItem('payment_info');
    localStorage.removeItem('payfort_data');
    localStorage.removeItem('isOrderHistoryPayment');
    localStorage.removeItem('cms_data');
    localStorage.removeItem('resCode');
    localStorage.removeItem('yasIdUserData');
    removeGuestUser();
  };
}

export const initializeAliPay = (props) => {
  const cmsData = JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.payment.cmsData));
  const aliPayData = props.data.paymentData;
  const paymentDataAnonymous = getAnonymousCart();
  const isGet = parseQueryString('transactionId') !== null;
  const transactionid = isGet ? parseQueryString('transactionId') : aliPayData.transactionid;
  const transactionstatus = isGet ? parseQueryString('transactionStatus') : aliPayData.transactionstatus;
  const aliPayFinalData = {
    transactionid,
    transactionstatus,
  };
  const AliPay = new AliPayPayment({ aliPayFinalData, cmsData, paymentDataAnonymous });
  AliPay.moveToNextPaymentStep();
};
