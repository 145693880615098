import React, { useEffect, useState } from 'react';
import { parseFloatToFixed } from '../../../../../utils/numbersUtils';

const TotalAmount = ({ context, isDrivingExperience = false }) => {
  const calculatedTotal = context.state[isDrivingExperience ? 'total' : 'totalCalculatedAmount'];
  const [total, setTotal] = useState();

  useEffect(() => {
    let totalVal = '';
    if (isDrivingExperience) {
      totalVal = parseFloatToFixed(calculatedTotal);
      if (context.state.buttonDisable) {
        totalVal = parseFloatToFixed(0);
      }
    } else {
      totalVal = calculatedTotal;
    }
    setTotal(totalVal);
  }, [context.state.buttonDisable, isDrivingExperience]);

  return (
    <div className="ticket-total-amount-wrapper">
      <div className={`total-amount ${calculatedTotal === '' ? 'disabled' : ''}`}>
        <h3>{context.props.data.total}</h3>
        <div className="amount">
          <h3>
            {context.props.data.currency} {total}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default TotalAmount;
