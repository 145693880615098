import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import UIConfig from '../../../../common/UIConfig';
import { Calendar, DynamicContent, ErrorSummary, AnchorLink } from '../../../presentation/base';
import CounterComponent from '../visitors-count-selector/visitors-count-selector-component';
import { PerformanceService, PromotionService } from '../../../../common/services';
import DropDown from '../../../presentation/drop-down';
import { secProds } from '../add-ons/add-ons-utility';
import {
  toTwoDecimalPlaces,
  deepCloneObject,
  momentTimezone,
  getValidStartDate,
  canUseDOM,
  resolvePath,
  isDesktopViewPort,
  detectMobile,
  isEmpty,
  decodeHtmlEntity,
  getClientUtcOffset,
  isMatchTenant,
  checkTenant,
} from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

import * as helper from './helper';
import { CalendarLabels, LineSeparator, Legends, ImportantNotes } from '../common-components';
import GTMData from '../gtm-data';

export default class AddOnsOverlayComponent extends Component {
  constructor(props) {
    super(props);
    this.getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    this.isSWAD = resolvePath(this.getMainObj, 'tenantID', '').toLowerCase() === UIConfig.SWADB2C;
    this.isFWAD = resolvePath(this.getMainObj, 'tenantID', '').toLowerCase() === UIConfig.tenants.fwad;
    this.isYmc = checkTenant(UIConfig.tenants.ymc);
    this.overlaySelAvailable = false;
    this.additionalProducts = [];
    this.validOnRange = [];
    this.isAdultChildOverlay = this.props.data.isAdultChildOverlay;
    this.isQuantityInOverlay = this.props.data.isQuantityInOverlay;
    this.isAddOnExperienceOverlay = this.props.data.isAddOnExperienceOverlay;
    this.isTotalAvailabilityCal = this.props.data.enableTac;
    this.controls = this.props.data.controls;
    this.productOverlaySelector = this.props.productOverlaySelector || {};
    this.isMultiProductCal = this.productOverlaySelector.isMultiProductCal || false;
    this.legends = this.props.legends || [];
    this.showProductNameInOverlay = this.props.showProductNameInOverlay || false;
    this.overlaySelMap = !this.isQuantityInOverlay ? this.getOverlaySelMapping() : null;
    this.counterData = this.props.counterData;
    this.isDynamicPricingProd = this.props.data.variant === 'v-dynamic-product-pricing';
    this.flexibleMoneyCard = false;
    this.maxCount = this.props.counterData.maxCount;
    this.isPackageJourney = this.props.isPackageJourney || false;
    this.finalSelectedProducts = [];
    this.calendarData =
      this.props.calenderData && this.props.calenderData.options && this.props.calenderData.options[0].calendarSettings;
    this.defaultSelAdditionalProds = this.props.additionalProds ? this.getDefaultAdditionalProd() : false;
    this.isOverlaySelectionLength =
      this.productOverlaySelector &&
      Array.isArray(this.productOverlaySelector.collections) &&
      this.productOverlaySelector.collections.length > 1;
    this.product =
      !this.isPackageJourney && this.defaultSelAdditionalProds
        ? this.defaultSelAdditionalProds
        : deepCloneObject(this.props.product);
    if (this.isDynamicPricingProd) this.product.gross = 0;
    this.prodQty = [];
    this.isPJOnly = this.props.data.variant === 'v-multi-product-widget';
    this.daysDelta = 0;
    this.tnc = this.checkTnc();
    if (this.isAdultChildOverlay && props.counterData.options.length === this.props.additionalProds.length) {
      this.props.additionalProds.forEach((item, idx) => {
        if (props.counterData.options.length) {
          props.counterData.options[idx].description =
            props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(item.gross));
        }
      });
    } else {
      if (props.data.variant !== UIConfig.dyanmicPricingVariant) {
        const prodPriceLabel = props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(this.product.gross));
        props.counterData.options[0].description = prodPriceLabel;
      }
    }
    if (!this.isQuantityInOverlay) {
      this.calendarSettings = this.props.calenderData.options[0].calendarSettings;
      this.minDate = this.getValidStartDate(this.calendarSettings.startDate).add(
        this.calendarSettings.defaultDateDelta,
        'days',
      );
      if (this.calendarSettings.endDate)
        this.maxDate = momentTimezone(this.calendarSettings.endDate, getClientUtcOffset());
      else if (this.isTotalAvailabilityCal)
        this.maxDate = moment().add(parseInt(this.calendarSettings.range, 10), 'days');
      else
        this.maxDate = momentTimezone(this.calendarSettings.startDate, getClientUtcOffset()).add(
          parseInt(this.calendarSettings.range, 10),
          'days',
        );
      this.validOn = this.calendarSettings.validOn;
    }
    if (this.isQuantityInOverlay) {
      this.ticketValidity = this.props.calenderData && this.props.calenderData.options[0].ticketValidity;
    }
    if (this.validOn) {
      this.validOnRange = this.validOn.split(',').map((x) => +x);
    }
    this.calendarDateChosen = false;
    const category = this.props.product.category;
    this.primaryProd = this.props.product;
    this.slctdSecIdx = 0;
    this.slctdExpCatgory = null;
    this.secProds =
      secProds[category] && secProds[category].secondary ? deepCloneObject(secProds[category].secondary) : [];
    this.daysDelta = 0;
    this.licenseAvailable = false;
    this.termsAndCondCheck = false;
    this.updatedAdditionalProds = this.props.additionalProds;
    this.fallBackProducts = {};
    this.isDesktop = isDesktopViewPort();
    this.currentDateSlots = null;
    this.productsArr = [];
    this.defaultValue = {
      defaultDateDelta: 1,
      range: 0,
    };
    this.selectedTile = this.controls.extras.options.filter((opt) => opt.coveoValue === category[0]);
    this.enableTimeSlotExperience =
      this.selectedTile && this.selectedTile.length && this.selectedTile[0].enableTimeSlotExperience;

    if (this.isPJOnly) {
      const validStartDate = this.getValidStartDate(this.calendarSettings.startDate);
      const givenStartDate = moment(this.calendarSettings.startDate).isValid()
        ? momentTimezone(this.calendarSettings.startDate, getClientUtcOffset())
        : validStartDate.clone();
      this.calendarSettings = {
        ...this.calendarSettings,
        startDate: validStartDate.add(this.calendarSettings.defaultDateDelta, 'days'),
        endDate: this.calendarSettings.endDate
          ? momentTimezone(this.calendarSettings.endDate, getClientUtcOffset())
          : givenStartDate.add(Number(this.calendarSettings.range) || this.defaultValue.range, 'days'),
      };
      this.minDate = this.calendarSettings.startDate;
      this.maxDate = this.calendarSettings.endDate
        ? momentTimezone(this.calendarSettings.endDate, getClientUtcOffset())
        : momentTimezone(this.calendarSettings.startDate, getClientUtcOffset()).add(
            parseInt(this.calendarSettings.range, 10),
            'days',
          );
    }

    this.state = {
      totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
      date: this.minDate,
      disableAddToCart: true,
      performanceData: {},
      isVIPPrivateOrShared: false,
      slotSelected: false,
      maxAvailableTickets: '',
      vipCounterData: this.props.counterData,
      customDates: [],
      selectDefaultDate: false,
      modifiedPerformanceData: [],
      existingProductError: false,
      calendarReset: false,
      isMobile: false,
      carProdsClicked: false,
      minDateFromCartData: this.isPJOnly ? this.minDate : '',
      cardAmount: '',
      amountMultiplesOfTen: 0,
      selectedAttentions: [],
      visitedDate: null,
      isPerformanceCaptured: false,
      performancePriceListError: null,
      performancePriceList: [],
    };
  }

  confgDfltSetngs = (secProducts) => helper.confgDfltSetngs(this, secProducts);

  checkTnc = () => helper.checkTnc(this);

  getOverlaySelMapping = () => helper.getOverlaySelMapping(this);

  getDefaultAdditionalProd = () => helper.getDefaultAdditionalProd(this);

  scrollToNextSection = (section) => helper.scrollToNextSection(this, section);

  getValidStartDate = (date) => {
    if (this.validOn) {
      const currentDate = momentTimezone(date, getClientUtcOffset());
      // if (this.validOnRange) {
      //   while (this.validOnRange.indexOf(currentDate.day()) < 0) {
      //     currentDate.add(1, 'day');
      //   }
      // }
      return currentDate;
    }
    return getValidStartDate(date);
  };

  redirectToBookingPageCallback = (checkoutContext) => helper.redirectToBookingPageCallback(this, checkoutContext);

  addToCart = (checkoutContext) => helper.addToCart(this, checkoutContext);

  setVisitorsCount = (data) => helper.setVisitorsCount(this, data);

  calculateTotal = () => helper.calculateTotal(this);

  fetchMultiProductPerformance = () => {
    this.productsArr = [];
    this.productDetails = [];
    if (this.isDynamicPricingProd) {
      this.props.searchProductList
        .filter(({ ticketVal, category, experienceCatgory, ticketType, classType }) => {
          const res =
            ticketVal &&
            ticketVal === this.product.ticketVal &&
            category &&
            this.product.category &&
            (this.isFWAD && (ticketType === UIConfig.ProductType.VIP || ticketType === UIConfig.ProductType.VVIP)
              ? classType === this.product.classType
              : true) &&
            category[0] === this.product.category[0];
          if (ticketType === 'VVIP' || ticketType === 'VIP') {
            return res && experienceCatgory?.toLowerCase() === 'primary';
          }
          return res;
        })
        .forEach((prod) => {
          this.productsArr.push(prod.productId);
          this.productDetails.push({
            productId: prod.productId,
            eventAk: prod.eventAks,
          });
        });
    } else {
      this.props.additionalProds.forEach((prod) => this.productsArr.push(prod.productId));
    }
    PerformanceService.getMultiProductPerformanceData(
      this.props.services.getMultiProdPerformance.url,
      this.product.fromDate,
      this.product.toDate || this.product.fromDate,
      this.isDynamicPricingProd ? { productDetail: this.productDetails } : { productId: this.productsArr },
      true,
      '.addOns-Overlay',
    )
      .then((response) => {
        if (response.data.performancelist.hasOwnProperty('performance') && this.isTotalAvailabilityCal) {
          this.createTACData(response.data.performancelist);
        } else {
          this.setState({
            performanceData: { error: { errorcode: '7001' } },
            slotSelected: false,
            disableAddToCart: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          performanceData: { error: err.error },
          slotSelected: false,
          disableAddToCart: true,
          modifiedPerformanceData: [],
          customDates: [],
        });
      });
  };

  getPerformanceProduct = () => {
    const url = this.props.services.getPerformance.url.replace('{0}', this.product.productId);
    const validToDate =
      this.product.toDate &&
      momentTimezone(this.product.toDate, getClientUtcOffset()).isSameOrAfter(this.product.fromDate, 'days')
        ? this.product.toDate
        : this.product.fromDate;
    PerformanceService.getPerformanceData(url, this.product.fromDate, validToDate, true, '.addOns-Overlay')
      .then((response) => {
        if (
          response.data.performancelist.hasOwnProperty('performance') &&
          this.isTotalAvailabilityCal &&
          (!this.isDynamicPricingProd || this.enableTimeSlotExperience)
        ) {
          this.createTACData(response.data.performancelist);
        } else {
          const availabile =
            response.data.performancelist.hasOwnProperty('performance') &&
            response.data.performancelist.performance.some((timeSlot) => {
              return timeSlot.availability.available > 0 && timeSlot.sellable === 'true';
            });
          if (availabile) {
            this.product.performanceId = '';
            this.product.timeSlot = '';
            if (
              !(this.props.timeSlotData && this.props.timeSlotData?.showSingleTimeSlot) &&
              response.data.performancelist.performance.length === 1
            ) {
              this.product.performanceId = response.data.performancelist.performance[0].performanceId;
              this.setState({ slotSelected: true, performanceData: {}, minDateFromCartData: '' }, () =>
                this.calculateTotal(),
              );
            } else {
              this.setState({ performanceData: response.data.performancelist });
            }
            if (
              this.isDynamicPricingProd &&
              this.props.timeSlotData &&
              !isEmpty(this.props.timeSlotData) &&
              this.props.timeSlotData?.showSingleTimeSlot
            ) {
              this.scrollToNextSection('c-date-selector--time-slot');
            } else {
              this.scrollToNextSection('react-datepicker');
            }
          } else {
            this.setState({
              performanceData: { error: { errorcode: '7001' } },
              slotSelected: false,
              disableAddToCart: true,
              minDateFromCartData: '',
              performancePriceList: [],
              totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          performanceData: { error: err.error },
          slotSelected: false,
          disableAddToCart: true,
          modifiedPerformanceData: [],
          customDates: [],
          performancePriceList: [],
          totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
        });
      });
  };

  getPerformancePrice(performance) {
    const url = this.props.services.getPriceByPerformance.url;
    const data = {
      productID: this.product.productId,
      performanceList: performance?.map(({ performanceId }) => ({ performanceID: performanceId })),
    };
    // Rest
    this.setState({
      performancePriceListError: null,
      performancePriceList: [],
    });
    PerformanceService.getPerformacnePrice(url, data, true, '.addOns-Overlay')
      .then((response) => {
        if (response.data.hasOwnProperty('performanceList') && response.data.performanceList?.length > 0) {
          const performancePriceList = response.data.performanceList;
          // Defaults when there is only one available stol
          const items = [];
          this.state.performanceData.performance.forEach((performance) => {
            items.push({
              value: performance.performanceId,
              disabled: performance.sellable === 'false' || performance.availability.available === '0',
            });
          });
          const filterAvailableSlots = items.filter((item) => !item.disabled);
          if (filterAvailableSlots?.length === 1) {
            this.setSelectedValue(filterAvailableSlots[0].value, performancePriceList);
          }
          // Update state
          this.setState({ performancePriceList }, () => this.calculateTotal());
        }
      })
      .catch((err) => {
        this.setState({
          performancePriceListError: err.error,
          performancePriceList: [],
        });
      });
  }

  setDate = (date) => {
    this.product.fromDate = date.locale('en').format(UIConfig.calendar.dateFormat);
    this.calendarDateChosen = this.isTotalAvailabilityCal ? false : true;
    this.setState({
      totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
      date: this.isTotalAvailabilityCal ? null : date,
      disableAddToCart: true,
      performanceData: {},
      slotSelected: false,
      maxAvailableTickets: '',
      calendarReset: false,
      carProdsClicked: false,
      visitedDate: date,
    });
    if (this.product.itemType === 'PER' || this.product.hasperformance === '1') {
      if (this.isMultiProductCal) {
        this.fetchMultiProductPerformance();
      } else {
        this.getPerformanceProduct();
      }
    } else {
      if (this.isDynamicPricingProd && this.calendarSettings.enableDatePreSelection) {
        this.setState({
          selectDefaultDate: true,
        });
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.enablePJPopup) {
      this.calendarSettings = nextProps.calenderData.options[0].calendarSettings;
      this.minDate = this.getValidStartDate(this.calendarSettings.startDate).add(
        this.calendarSettings.defaultDateDelta,
        'days',
      );
      this.maxDate = this.calendarSettings.endDate
        ? momentTimezone(this.calendarSettings.endDate, getClientUtcOffset())
        : momentTimezone(this.calendarSettings.startDate, getClientUtcOffset()).add(
            parseInt(this.calendarSettings.range, 10),
            'days',
          );
      this.setState({
        totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
        date: this.minDate,
      });
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.performanceData.performance?.length > 0 && this.isYmc) {
      const currentPerformanceTime = new Date(this.state.performanceData.date).getTime();
      const prevPerformanceTime = new Date(prevState.performanceData.date).getTime();
      if (prevPerformanceTime !== currentPerformanceTime) {
        const performance = this.state.performanceData.performance;
        this.getPerformancePrice(performance);
      }
    }
  }

  componentWillMount() {
    this.props.data && this.props.data.isAddOnExperienceOverlay && this.confgDfltSetngs(this.secProds);
  }

  componentDidMount() {
    this.product =
      !this.isPackageJourney && this.defaultSelAdditionalProds
        ? this.defaultSelAdditionalProds
        : deepCloneObject(this.props.product);
    if (this.isDynamicPricingProd) {
      this.product.gross = 0;
    }
    if (this.props.data.variant !== UIConfig.dyanmicPricingVariant) {
      this.props.counterData.options[0].description =
        this.props.data.currency + ' ' + this.product.gross ? toTwoDecimalPlaces(parseFloat(this.product.gross)) : 0;
    } //If amount needs to be shown configure from cms as "0.00"
    if (this.isTotalAvailabilityCal) {
      this.fallBackInitialProducts();
      this.initialOverlayPerformanceCall();
    } else {
      this.minDate && !this.isPJOnly && this.setDate(this.minDate);
    }
    this.setState({
      isMobile: detectMobile(),
      vipCounterData: helper.getVIPCounterData(this, this.product.productId),
      isVIPPrivateOrShared: helper.isVIPPrivateOrShared(this),
    });
    this.isMultiProductCal && this.isTotalAvailabilityCal && canUseDOM() && this.addStyleTagDynamically();
  }

  addStyleTagDynamically = () => helper.addStyleTagDynamically(this);

  dynamicStylesClasses = () => helper.dynamicStylesClasses(this);

  initialOverlayPerformanceCall = () => helper.initialOverlayPerformanceCall(this);

  createTACData = (perfList) => helper.createTACData(this, perfList);

  defaultDropDownSelection = () => helper.defaultDropDownSelection(this);

  calendarDateCallback = (date, isMount, preventScroll) =>
    helper.calendarDateCallback(this, date, isMount, preventScroll);

  timeFormat = (performance) => helper.timeFormat(this, performance);

  timeFormatTac = (performance) => helper.timeFormatTac(this, performance);

  selectTimeSlot = (event, isAutoSelected) => helper.selectTimeSlot(this, event, isAutoSelected);

  getPromotionProducts = (isMultiSameTimeSlot = false) => {
    this.setState({ isPerformanceCaptured: false });
    let url = resolvePath(this.props.services, 'getPromotion.url', '');
    if (url) {
      url = url.replace('{0}', this.product.performanceId);
      PromotionService.getPromotionData(url, true, '.addOns-Overlay')
        .then((response) => {
          const perfProduct = response.data.products;
          if (perfProduct && perfProduct.length) {
            this.checkPromotionProduct(perfProduct);
            if (isMultiSameTimeSlot) this.product.productId = perfProduct[0].productId[0];
          } else {
            this.checkDynamicProduct();
          }
          this.setState({ isPerformanceCaptured: true });
        })
        .catch(() => {
          this.checkDynamicProduct();
        });
    }
  };

  filterOutOfferProductsCoveo = (baseProduct) => helper.filterOutOfferProductsCoveo(this, baseProduct);

  filterPromotionProduct = (availablePromProduct, coveoProducts) =>
    helper.filterPromotionProduct(this, availablePromProduct, coveoProducts);

  filterSecondaryPromotionProduct = (coveoSecPromProd, baseProd, availableProProduct) =>
    helper.filterSecondaryPromotionProduct(this, coveoSecPromProd, baseProd, availableProProduct);

  checkPromotionProduct = (availableProProduct) => helper.checkPromotionProduct(this, availableProProduct);

  updatedVisitorCounterPrice = (primaryProduct, idx, baseProduct) =>
    helper.updatedVisitorCounterPrice(this, primaryProduct, idx, baseProduct);

  updateBaseProduct = (prod, isPriceUpdateRequired) => helper.updateBaseProduct(this, prod, isPriceUpdateRequired);

  combineCloneObject = (obj, copyObj) => helper.combineCloneObject(this, obj, copyObj);

  fallBackInitialProducts = () => helper.fallBackInitialProducts(this);

  resetPriceDescriptionForCounter = (selectedDate, performanceData) =>
    helper.resetPriceDescriptionForCounter(this, selectedDate, performanceData);

  resetPriceLabelCounter = (product, idx) => helper.resetPriceLabelCounter(this, product, idx);

  calDateDelta = (date) => helper.calDateDelta(this, date);

  filterPrimaryDynamicProd = (similarDynamicProd, baseProd, days, disableDiscount) =>
    helper.filterPrimaryDynamicProd(this, similarDynamicProd, baseProd, days, disableDiscount);

  filterSecondaryDynamicProd = (similarDynamicProd, baseProd, days, disableDiscount) =>
    helper.filterSecondaryDynamicProd(this, similarDynamicProd, baseProd, days, disableDiscount);

  groupSecondaryProducts = (products) => helper.groupSecondaryProducts(this, products);

  checkDynamicProduct = () => helper.checkDynamicProduct(this);

  renderErrorMsg = () => {
    return (
      <ErrorSummary
        data={{
          error: {
            code: this.state.performanceData.error.errorcode,
            text: this.props.services.getPerformance.errors[this.state.performanceData.error.errorcode],
          },
        }}
      />
    );
  };

  renderLowQtyErrorMsg = () => {
    return (
      <ErrorSummary
        data={{
          error: {
            code: UIConfig.errorCodes.lowQuantityErrorCode,
            text: this.props.services.getPerformance.errors[UIConfig.errorCodes.lowQuantityErrorCode],
          },
        }}
      />
    );
  };

  renderTimeSlots = () => {
    const items = [],
      labels = this.props.timeSlotData && this.props.timeSlotData.labels ? this.props.timeSlotData.labels : {};

    this.state.performanceData.performance.forEach((performance) => {
      items.push({
        value: performance.performanceId,
        disabled: performance.sellable === 'false' || performance.availability.available === '0',
        text: `${this.timeFormat(performance)} (${performance.availability.available} ${labels.availableLabel})`,
      });
    });

    const filterAvailableSlots = items.filter((item) => !item.disabled);

    return (
      <div className="c-date-selector--time-slot">
        <DynamicContent tagName="h3" attrs={{ className: 'heading-5' }} innerHtml={labels.title} />
        <DynamicContent
          tagName="p"
          attrs={{ className: 'sub-heading' }}
          innerHtml={this.props.timeSlotData.description}
        />
        <DropDown
          listItems={filterAvailableSlots}
          defaultValue={
            this.product.performanceId
              ? filterAvailableSlots.filter((ele) => ele.value === this.product.performanceId)[0].text
              : labels.dropdownLabel
          }
          callBackFunction={this.selectTimeSlot}
          showSelected="true"
        />
      </div>
    );
  };

  setSelectedValue = (value, performancePriceList) => {
    const selectedperformance = (performancePriceList ?? this.state.performancePriceList)?.find(
      (per) => per.performanceId === value,
    );
    if (this.secProds && this.secProds.length > 0) {
      this.secProds[0].gross = selectedperformance?.price ?? this.secProds.gross;
    } else {
      this.product.gross = selectedperformance?.price ?? this.product.gross;
      this.props.counterData.options[0].description =
        this.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(this.product.gross));
    }
    this.slctdExpCatgory = null;
  };

  renderTimeSlotsTAC = () => {
    const items = [],
      labels = this.props.timeSlotData && this.props.timeSlotData.labels ? this.props.timeSlotData.labels : {};

    this.state.performanceData.performance.forEach((performance) => {
      const performacePrice = this.state.performancePriceList?.find(
        ({ performanceId }) => performanceId === performance.performanceId,
      );
      const isPrimProdSlctd =
        this.secProds && this.secProds.length > 0 && this.slctdExpCatgory === UIConfig.experienceCategory.PRIMARY;
      const price =
        !isPrimProdSlctd && performacePrice?.price ? `- ${this.props.data.currency} ${performacePrice?.price}` : '';
      items.push({
        value: performance.performanceId,
        disabled: performance.sellable === 'false' || performance.availability.available === '0',
        text: `${this.timeFormatTac(performance)} ${price} (${performance.availability.available} ${
          parseInt(performance.availability.available || '0') ? labels.availableLabel : labels.notAvailableLabel || ' '
        })`,
      });
    });

    const filterAvailableSlots = items.filter((item) => !item.disabled);
    const availableSlotData = filterAvailableSlots.filter((ele) => ele.value === this.product.performanceId);
    let defaultValue;
    if (availableSlotData && availableSlotData.length) {
      defaultValue = availableSlotData[0].text;
    }
    if (filterAvailableSlots && filterAvailableSlots.length) {
      return (
        <div className="c-date-selector--time-slot">
          <DynamicContent tagName="h3" attrs={{ className: 'heading-5' }} innerHtml={labels.title} />
          {this.props.timeSlotData && (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'sub-heading' }}
              innerHtml={this.props.timeSlotData.description}
            />
          )}
          {this.isYmc ? (
            this.state.performancePriceList?.length > 0 && (
              <DropDown
                listItems={filterAvailableSlots}
                defaultValue={this.product.performanceId && defaultValue ? defaultValue : labels.dropdownLabel}
                callBackFunction={this.selectTimeSlot}
                getSelectedValue={this.setSelectedValue.bind(this)}
                showSelected="true"
                isUpdateDropdownProps={this.isTotalAvailabilityCal}
              />
            )
          ) : (
            <DropDown
              listItems={filterAvailableSlots}
              defaultValue={this.product.performanceId && defaultValue ? defaultValue : labels.dropdownLabel}
              callBackFunction={this.selectTimeSlot}
              showSelected="true"
              isUpdateDropdownProps={this.isTotalAvailabilityCal}
            />
          )}
        </div>
      );
    } else {
      this.setState({ performanceData: { error: { errorcode: '7001' } }, slotSelected: false });
    }
  };

  //Should be used for the YPS-7184 do not remove.
  renderTimeSlotsV2 = () => {
    const items = [],
      timeRange = [],
      labels = this.props.timeSlotData && this.props.timeSlotData.labels ? this.props.timeSlotData.labels : {};

    this.state.performanceData.performance.forEach((performance) => {
      let timeSplit = performance.openTime.split(':')[0];

      if (
        timeRange.indexOf(timeSplit) === -1 &&
        performance.sellable === 'true' &&
        performance.availability.available !== '0'
      ) {
        timeRange.push(timeSplit);
        items.push({
          value: performance.performanceId,
          disabled: performance.sellable === 'false' || performance.availability.available === '0',
          text: `${this.timeFormat(performance)} (${performance.availability.available} ${labels.availableLabel})`,
        });
      }
    });

    return (
      <div className="c-date-selector--time-slot">
        <DynamicContent tagName="h3" attrs={{ className: 'heading-5' }} innerHtml={labels.title} />
        <DynamicContent
          tagName="p"
          attrs={{ className: 'sub-heading' }}
          innerHtml={this.props.timeSlotData.description}
        />
        <DropDown
          listItems={items}
          defaultValue={
            this.product.performanceId
              ? items.filter((ele) => ele.value === this.product.performanceId)[0].text
              : labels.dropdownLabel
          }
          callBackFunction={this.selectTimeSlot}
          showSelected="true"
        />
      </div>
    );
  };

  renderTotalAmount = () => {
    return (
      <div className="ticket-total-amount-wrapper">
        <div className={`total-amount ${this.state.totalCalculatedAmount === '' ? 'disabled' : ''}`}>
          <h3>{this.props.data.total}</h3>
          <div className="amount">
            <h3>
              {this.props.data.currency} {this.state.totalCalculatedAmount}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  isValidDay = (date) => helper.isValidDay(this, date);

  addCarsCallBack = (evt) => helper.addCarsCallBack(this, evt);

  getDefaultCheckedValue = (prod) => helper.getDefaultCheckedValue(this, prod);

  GTMDataOnSelectItem = (tickets) => {
    try {
      const { data } = this.props;
      const category =
        data && data.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      GTMData.push('selectItem', { itemsList: tickets, category, gaTabTitle: data?.gaTabTitle || undefined });
    } catch (error) {
      console.log('GA4 error: ', error);
    }
  };

  bindCars = (itms) => {
    const cars = [],
      carProds = this.additionalProducts || [];
    let idx,
      carsProdLen = carProds.length;
    // if (isMatchTenant(UIConfig.tenants.yww) && this.state.isMobile && (this.isPJOnly || this.isDynamicPricingProd)) {
    //   const items = [];
    //   if (this.isPJOnly) {
    //     this.props.additionalProds &&
    //       this.props.additionalProds.forEach((prod) => {
    //         const productItem = this.props.productOverlaySelector.collections.filter(
    //           (item) => item.productId === prod.productId,
    //         )[0];
    //         items.push({ ...prod, value: prod.productId, text: `${productItem.title}` });
    //       });
    //   } else if (this.isDynamicPricingProd) {
    //     this.additionalProducts &&
    //       this.additionalProducts.forEach((prod) => {
    //         const productItem = this.props.productOverlaySelector.collections.filter(
    //           (item) => item.productId === prod.productId,
    //         )[0];
    //         items.push({ ...prod, value: prod.productId, text: productItem.title, priceValue: productItem.price });
    //       });
    //     items.forEach((prod) => {
    //       const productItem = this.props.additionalProds.filter((item) => item.productId === prod.productId)[0];

    //       this.props.additionalProds.forEach((addProd) => {
    //         if (addProd.ticketVal === productItem.ticketVal) {
    //           addProd.text = prod.text;
    //           addProd.value = addProd.productId;
    //           addProd.priceValue = productItem.price;
    //         }
    //       });
    //     });
    //   }

    //   cars.push(
    //     <div className="exctmnt--dropdown-items">
    //       <DropDown
    //         listItems={items}
    //         defaultValue={
    //           this.isDynamicPricingProd
    //             ? this.props.additionalProds.filter((ele) => ele.value === this.product.productId)[0].text
    //             : items.filter((ele) => ele.value === this.product.productId)[0].text
    //         }
    //         defaultProductId={
    //           this.isDynamicPricingProd
    //             ? this.props.additionalProds.filter((ele) => ele.value === this.product.productId)[0].value
    //             : ''
    //         }
    //         callBackFunction={this.addCarsCallBack}
    //         showSelected="true"
    //       />
    //     </div>,
    //   );
    // } else {
    for (idx = 0; idx < carsProdLen; idx++) {
      cars.push(
        <li className={`exctmnt--list-items ${this.isDynamicPricingProd ? 'dynamic-pricing-list' : ''}`} key={idx}>
          <input
            type="radio"
            name="secPrds"
            id={'secPrds' + idx}
            data-value={idx}
            data-prod={JSON.stringify(carProds[idx])}
            value={idx}
            defaultChecked={this.getDefaultCheckedValue(carProds[idx])}
            onChange={this.addCarsCallBack}
            tabIndex={
              carProds[idx].productId ===
              (resolvePath(this.fallBackProducts, 'baseProduct.productId') || this.defaultSelAdditionalProds.productId)
                ? '0'
                : '-1'
            }
          />
          <label htmlFor={'secPrds' + idx}>
            <div className="radio"></div>
            <p className="title">{itms.collections[idx].title}</p>
            {!this.isPJOnly && (
              <p className="price">
                <span className="price--currency">{`${itms.collections[idx].price || ''}`}</span>
              </p>
            )}
          </label>
        </li>,
      );
    }
    // }

    return cars;
  };

  renderCarsAddOns = () => {
    let prodOS = this.productOverlaySelector;
    return (
      <div className="exctmnt section">
        <DynamicContent
          tagName="p"
          innerHtml={prodOS.title}
          attrs={{
            className: 'exctmnt--title',
          }}
        />
        {this.isOverlaySelectionLength && <ul className="exctmnt--wrapper">{this.bindCars(prodOS)}</ul>}
      </div>
    );
  };

  showCalendarLabels = () => <CalendarLabels context={this} />;
  handleSecondaryProdOffer = (currentProduct) => helper.handleSecondaryProdOffer(this, currentProduct);

  secPrdCallBack = (evt) => helper.secPrdCallBack(this, evt);

  bindSecPrds = (itm) => {
    const timeSlot = this.state?.selectedTimeSlot;
    const lstItms = [],
      sortedArray = this.secProds,
      sortedArrayLng = sortedArray.length;
    let idx;
    for (idx = 0; idx < sortedArrayLng; idx++) {
      if (isMatchTenant(UIConfig.tenants.ymc) && !timeSlot && idx > 0) {
        break;
      }
      lstItms.push(
        <li className="exctmnt--list-items upsell-items" key={idx}>
          <input
            type="radio"
            name="secPrds"
            id={'secPrds' + idx}
            onClick={this.secPrdCallBack}
            data-value={idx}
            data-prod={JSON.stringify(sortedArray[idx])}
            value={idx}
            onChange={this.secPrdCallBack}
            className={`${idx === this.slctdSecIdx ? 'button-checked' : 'button-unchecked'}`}
            tabIndex={idx === this.slctdSecIdx ? '0' : '-1'}
          />
          <label htmlFor={'secPrds' + idx}>
            <div className="radio-button"></div>
            <p className="title">{sortedArray[idx].productName}</p>
            <p className="price">
              <span className="price--currency">{this.props.data.currency}</span>
              <span className="price--value">{Number(sortedArray[idx].gross) - Number(this.primaryProd.gross)}</span>
            </p>
          </label>
        </li>,
      );
    }
    lstItms.push(
      <li className="exctmnt--list-items upsell-items" key={idx}>
        <input
          type="radio"
          name="secPrds"
          id={'secPrds' + idx}
          onClick={this.secPrdCallBack}
          data-value={idx}
          data-prod={JSON.stringify(this.primaryProd)}
          value={idx}
          onChange={this.secPrdCallBack}
          className={`${this.slctdSecIdx === sortedArrayLng ? 'button-checked' : 'button-unchecked'}`}
          tabIndex={this.slctdSecIdx === sortedArrayLng ? '0' : '-1'}
        />
        <label htmlFor={'secPrds' + idx}>
          <div className="radio-button"></div>
          <p className="title">{itm.collection.noThanks}</p>
          <p className="price price-hide">
            <span className="price--currency">{this.props.data.currency}</span>
            <span className="price--value">0</span>
          </p>
        </label>
      </li>,
    );
    return lstItms;
  };

  renderExcitemnt = (itm) => {
    if (isMatchTenant(UIConfig.tenants.ymc) && !this.state.isPerformanceCaptured) {
      return null;
    }
    if (this.secProds && this.secProds.length && this.product && !this.isPackageJourney) {
      return (
        <div className="exctmnt section">
          <DynamicContent
            tagName="p"
            innerHtml={itm.title}
            attrs={{
              className: 'exctmnt--title',
            }}
          />
          <DynamicContent
            tagName="p"
            innerHtml={itm.description}
            attrs={{
              className: 'exctmnt--sub-title',
            }}
          />
          <ul className="exctmnt--wrapper">{this.bindSecPrds(itm)}</ul>
        </div>
      );
    } else {
      return null;
    }
  };

  updateButtonState = () => helper.updateButtonState(this);

  lcnsCallBack = (data) => helper.lcnsCallBack(this, data);

  termAndCondcallback = (event) => helper.termAndCondcallback(this, event);

  configureCntry = (cntries) => helper.configureCntry(this, cntries);

  renderDrivingLicense = (item) => {
    this.licenseAvailable = true;
    let license = '';
    if (this.isPackageJourney && this.props?.product?.license) {
      license = this.props?.product?.license || item.defaultText;
    } else {
      license = this.product.license || item.defaultText;
    }
    return (
      <div className="drvLcns section">
        <DynamicContent
          tagName="p"
          innerHtml={item.title}
          attrs={{
            className: 'drvLcns--title',
          }}
        />
        <DynamicContent
          tagName="p"
          innerHtml={item.description}
          attrs={{
            className: 'drvLcns--sub-title',
          }}
        />
        <DropDown
          listItems={this.configureCntry(item.country)}
          defaultValue={license}
          callBackFunction={this.lcnsCallBack}
          isUpdateDropdownProps={true}
          showSelected="true"
        />
        <DynamicContent
          tagName="p"
          innerHtml={item.tnctext}
          attrs={{
            className: 'drvLcns--verbose',
          }}
        />
      </div>
    );
  };

  renderSections = () => {
    const multiTktSelc = this.props.multiTicketSelector || {},
      sections = multiTktSelc.options,
      keyMap = multiTktSelc.stringMapping;
    return (
      sections &&
      sections.map((item) => {
        switch (item.identifier) {
          case keyMap.license:
            return this.renderDrivingLicense(item);
          case keyMap.secondaryProd:
            return this.renderExcitemnt(item);
          default:
            return null;
        }
      })
    );
  };

  setCarProdState = () => {
    if (!this.isDynamicPricingProd) this.setState({ carProdsClicked: false });
  };

  getButtonWrapperCondition = () => {
    let className = 'btn-primary';
    if (
      this.props?.attentions &&
      this.props?.attentions.length > 0 &&
      !(this.props?.attentions.length === this.state.selectedAttentions.length)
    ) {
      className += ' disabled';
    }
    if (this.state.disableAddToCart) {
      className += ' disabled';
    }
    return className;
  };
  renderAmountwithButtons = () => {
    const { data } = this.props;
    const addToCartLabel = data?.addToCartCTA?.label || data?.addToCart;
    return (
      <>
        {(this.state.slotSelected || this.isDynamicPricingProd) && this.renderTotalAmount()}
        {!this.tnc && (
          <div className={`t-n-c ${this.tnc ? 't-n-c-hide' : ''}`}>
            <label htmlFor="tAndCCheckbox" className="checkbox-label">
              <input type="checkbox" onClick={this.termAndCondcallback} className="hide" id="tAndCCheckbox" />
              <div className="checkbox"></div>
              <DynamicContent
                tagName="div"
                attrs={{ className: 't-n-c--label' }}
                innerHtml={
                  (this.props.multiTicketSelector && this.props.multiTicketSelector.tnc) ||
                  (this.props.timeSlotData && this.props.timeSlotData.tnc)
                }
              />
            </label>
          </div>
        )}
        {!this.isPJOnly && addToCartLabel && (
          <div className="btn-add-to-cart">
            <div className={this.getButtonWrapperCondition()}>
              <DynamicContent
                tagName="button"
                innerHtml={
                  (this.props.data.addToCartCTA && this.props.data.addToCartCTA.label) || this.props.data.addToCart
                }
                attrs={{
                  onClick: this.addToCart,
                  disabled: this.state.disableAddToCart,
                  className: 'add-to-cart',
                }}
              />
            </div>
          </div>
        )}
        {this.props.data && this.props.data.continueCheckoutCTA && (
          <div className="btn-checkout">
            <div className={this.getButtonWrapperCondition()}>
              <DynamicContent
                tagName="button"
                innerHtml={this.props.data.continueCheckoutCTA.label}
                attrs={{
                  onClick: () =>
                    this.addToCart(
                      this.props.recommendationUrl && !this.props?.timeSlotData
                        ? { href: this.props.recommendationUrl }
                        : this.props.data.continueCheckoutCTA,
                    ),
                  disabled: this.state.disableAddToCart,
                  className: 'continue-checkout',
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  addRemoveSelectedCheckbox = (attentions, attention) => {
    let selectedAttention = this.state.selectedAttentions;
    if (selectedAttention.includes(attention)) {
      selectedAttention.forEach((att, index) => {
        if (att.label === attention.label) {
          selectedAttention.splice(index, 1);
          return att;
        }
      });
    } else {
      selectedAttention.push(attention);
    }
    this.setState({ selectedAttentions: selectedAttention });
  };
  renderAttentions = () => {
    let attentions = this.props?.attentions;
    let attentionTitle = this.props?.attentionTitle;
    return (
      <div className="attentions-box">
        <div className="attentions-row">
          <DynamicContent tagName="h6" attrs={{ className: 'heading-6' }} innerHtml={attentionTitle} />
        </div>
        {attentions.map((attention, i) => (
          <div className="attentions-row" key={i}>
            <label htmlFor={`attention-${i}`} className="checkbox-label">
              <input
                type="checkbox"
                onClick={() => this.addRemoveSelectedCheckbox(attentions, attention)}
                className="attention-checkbox"
                id={`attention-${i}`}
              />
              <div className="checkbox"></div>
              <DynamicContent tagName="div" attrs={{ className: 'attention-label' }} innerHtml={attention.value} />
            </label>
          </div>
        ))}
      </div>
    );
  };

  renderLeftContent = () => {
    let showCars =
      this.props.productOverlaySelector && this.props.additionalProds && this.props.additionalProds.length
        ? true
        : false;
    return (
      <>
        {this.calendarSettings.calendarTooltip && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
        .calendar-overlay-genral-admission .calendar-inside-overlay .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month):hover::after {
            content : "${decodeHtmlEntity(this.calendarSettings.calendarTooltip)}";
          }
        `,
            }}
          />
        )}
        <Legends context={this} mainClass="calendar-overlay-genral-admission" />
        {(!this.isMultiProductCal || this.isDynamicPricingProd) && showCars && this.renderCarsAddOns()}
        <DynamicContent
          tagName="h3"
          attrs={{ className: 'heading-3 calendar-title' }}
          innerHtml={this.props.calenderData.title}
        />
        {this.isTotalAvailabilityCal && this.isSWAD && this.showCalendarLabels()}
        <Calendar
          ref={(instance) => (this._calendar = instance)}
          setDateCustom={this.isTotalAvailabilityCal ? this.calendarDateCallback : this.setDate}
          minDate={this.minDate}
          maxDate={this.maxDate}
          filterDate={this.isValidDay}
          calendarSettings={this.calendarSettings}
          isTotalAvailabilityCal={this.isTotalAvailabilityCal}
          highlightDates={this.state.customDates}
          reset={this.state.calendarReset}
          inlineDisable={this.isSWAD ? true : this.isDesktop}
          disableCloseOnSelect={true}
          isKeyboardNavigationAllowed={this.isDesktop}
          onBlurCustom={(e, name, data) => this._calendar.openDatePicker()}
          onClickOutsideCustom={(e) => this._calendar.openDatePicker()}
          carProdsClicked={this.state.carProdsClicked}
          setCarProdState={this.setCarProdState}
          selectedDate={this.state.minDateFromCartData}
          variant={this.isPJOnly}
          isDynamicPricingProd={this.isDynamicPricingProd}
          isBookingOverlay={false}
          isDatePreSelection={this.state.selectDefaultDate}
          hasPerformanceCall={this.product.itemType === 'PER' || this.product.hasperformance === '1'}
        />
        {this.isTotalAvailabilityCal && !this.isSWAD && this.showCalendarLabels()}
        {this.isSWAD &&
          !this.state.performanceData.hasOwnProperty('performance') &&
          this.state.performanceData.hasOwnProperty('error') &&
          this.renderErrorMsg()}
        {this.props?.attentions && this.props?.attentions.length > 0 && this.renderAttentions()}
      </>
    );
  };

  renderRightContent = () => {
    let showCars = !!(
      this.props.productOverlaySelector &&
      this.props.additionalProds &&
      this.props.additionalProds.length
    );
    let hideQtySelector = !!(this.state.maxAvailableTickets <= 1 && showCars);
    const calenderDesc = this.props.calenderData.description.replace(/&nbsp;/g, '');
    return (
      <>
        {this.isDynamicPricingProd && <LineSeparator />}
        {calenderDesc && (
          <DynamicContent
            tagName="p"
            attrs={{ className: 'calendar-subtitle' }}
            innerHtml={this.props.calenderData.description}
          />
        )}

        {this.isPackageJourney && this.props.inValidQty && this.renderLowQtyErrorMsg()}

        {!this.isSWAD && this.props.timeSlotData && this.props.timeSlotData.displayTimeSlotByHour
          ? this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length > 1 &&
            this.renderTimeSlotsV2()
          : this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length &&
            this.isTotalAvailabilityCal &&
            this.props.timeSlotData?.showSingleTimeSlot
          ? this.renderTimeSlotsTAC()
          : this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length >= 1 &&
            this.props.timeSlotData?.showSingleTimeSlot &&
            this.renderTimeSlots()}
        {/* {this.state.performanceData.hasOwnProperty("performance") && this.state.performanceData.performance.length > 1 && this.renderTimeSlots()} */}
        {!this.isSWAD &&
          !this.state.performanceData.hasOwnProperty('performance') &&
          this.state.performanceData.hasOwnProperty('error') &&
          this.renderErrorMsg()}

        {this.state.slotSelected && this.showProductNameInOverlay && (
          <div className="product-name-title">
            <DynamicContent tagName="h4" attrs={{ className: 'heading-4' }} innerHtml={this.product.productName} />
          </div>
        )}
        {!this.isPackageJourney && (this.state.slotSelected || this.isDynamicPricingProd) && (
          <CounterComponent
            data={{
              primaryCounter: this.state.isVIPPrivateOrShared ? this.state.vipCounterData : this.props.counterData,
            }}
            isVIPPrivateOrShared={this.state.isVIPPrivateOrShared}
            name={this.props.product.productName}
            setVisitorData={this.setVisitorsCount}
            isAdultChildOverlay={this.isAdultChildOverlay}
            maxAvailableTicketsCount={this.state.maxAvailableTickets}
            hideQtySelector={this.isPJOnly || this.isDynamicPricingProd ? false : hideQtySelector}
            isPJOnly={this.isPJOnly}
            timeSlotAvailable={this.props.timeSlotData}
            spinnerInitialCount={this.state.initialCount} // Count passed for dynamic pricing products on click of different overlay selector radio button
            isDynamicPricingProd={this.isDynamicPricingProd}
            isProductOverlayClicked={this.state.isProductOverlayClicked}
          />
        )}
        {this.props.data && this.props.data.isAddOnExperienceOverlay && this.renderSections()}
        {this.isDynamicPricingProd && <LineSeparator param="middle" />}

        {this.isDynamicPricingProd || this.isSWAD ? (
          <div className="v-align-amount-and-buttons">{this.renderAmountwithButtons()}</div>
        ) : (
          !this.isPackageJourney && this.renderAmountwithButtons()
        )}
        {/* placeholder for total amount and buttons */}
        {!this.isPackageJourney && this.state.slotSelected && (
          <div className="vat-info">
            <DynamicContent tagName="p" attrs={{ className: 'vat-info-label' }} innerHtml={this.props.data.vatInfo} />
          </div>
        )}
        {this.isDynamicPricingProd &&
          this.calendarData &&
          Array.isArray(this.calendarData.options) &&
          this.calendarData.options.length > 0 && <LineSeparator param="bottom" />}
        <ImportantNotes options={this.calendarData && this.calendarData.options} />
      </>
    );
  };

  renderAddOns = () => {
    return (
      <div
        className={`c-add-ons-overlay calendar-inside-overlay ${
          this.isPJOnly || this.isDynamicPricingProd ? 'v-multi-product-widget' : ''
        } ${this.isDynamicPricingProd ? 'v-dynamic-product-pricing' : ''}`}
      >
        {this.isSWAD ? <div className="swad-left-panel">{this.renderLeftContent()}</div> : this.renderLeftContent()}

        {this.isSWAD ? <div className="swad-right-panel">{this.renderRightContent()}</div> : this.renderRightContent()}
      </div>
    );
  };

  moneyCardAmountHandler = (e) => {
    this.setState({ cardAmount: parseFloat(e.target.value || 0) }, () => this.calculateTotal());
  };

  amountValidation = (e) => {
    const value = e.target.value ? parseFloat(e.target.value) : 0;
    this.setState({ amountMultiplesOfTen: !!(value % 10) }, () => this.updateButtonState());
  };

  renderQuantity = () => {
    const maxCount = this.props.counterData.maxCount;
    let hideQtySelector = maxCount <= 1 ? true : false;

    const moneyCardDetails = this.props.moneyCardDetails;
    this.flexibleMoneyCard =
      moneyCardDetails &&
      !isEmpty(moneyCardDetails) &&
      moneyCardDetails.type === UIConfig.moneyCardProduct.flexibleMoneyCard
        ? true
        : false;
    return (
      <div className="c-add-ons-overlay">
        {this.flexibleMoneyCard && (
          <>
            <div className="money-card-title">
              <DynamicContent tagName="h4" attrs={{ className: 'heading-4' }} innerHtml={moneyCardDetails.title} />
            </div>
            <div className="c-flexible-money-card">
              <DynamicContent
                tagName="div"
                attrs={{ className: 'money-card-subTitle' }}
                innerHtml={moneyCardDetails.amountTitle}
              />

              <div className="form-element">
                <input
                  type="number"
                  name="moneyCardAmount"
                  className="form-input body-copy-3 card-number "
                  data-value={this.state.cardAmount ? this.state.cardAmount : null}
                  placeholder={moneyCardDetails.amoutPlaceHolder}
                  value={this.state.cardAmount ? this.state.cardAmount : null}
                  onChange={(e) => this.moneyCardAmountHandler(e)}
                  onBlur={(e) => this.amountValidation(e)}
                  tabIndex={0}
                />
                {!!this.state.amountMultiplesOfTen && (
                  <DynamicContent
                    tagName="span"
                    attrs={{ className: 'amount-validation-error' }}
                    innerHtml={moneyCardDetails.errorMessage}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {
          <CounterComponent
            data={{ primaryCounter: this.props.counterData }}
            name={this.props.product.productName}
            setVisitorData={this.setVisitorsCount}
            maxAvailableTicketsCount={maxCount}
            hideQtySelector={hideQtySelector}
            timeSlotAvailable={this.props.timeSlotData}
            spinnerInitialCount={this.state.initialCount} // Count passed for dynamic pricing products on click of different overlay selector radio button
            isDynamicPricingProd={this.isDynamicPricingProd}
            isProductOverlayClicked={this.state.isProductOverlayClicked}
            priceForFlexibleMoneyCard={this.state.cardAmount}
            flexibleMoneyCard={this.flexibleMoneyCard}
          />
        }
        {this.renderTotalAmount()}
        {!this.tnc && (
          <div className={`t-n-c ${this.tnc ? 't-n-c-hide' : ''}`}>
            <label htmlFor="tAndCCheckbox" className="checkbox-label">
              <input type="checkbox" onClick={this.termAndCondcallback} className="hide" id="tAndCCheckbox" />
              <div className="checkbox"></div>
              <DynamicContent
                tagName="div"
                attrs={{ className: 't-n-c--label' }}
                innerHtml={
                  (this.props.multiTicketSelector && this.props.multiTicketSelector.tnc) ||
                  (this.props.timeSlotData && this.props.timeSlotData.tnc)
                }
              />
            </label>
          </div>
        )}
        <div className="btn-add-to-cart" data-product={`{productId:${this.props.product.productId}}`}>
          <div className={`btn-primary ${this.state.disableAddToCart ? 'disabled' : ''}`}>
            <DynamicContent
              tagName="button"
              innerHtml={
                (this.props.data.addToCartCTA && this.props.data.addToCartCTA.label) || this.props.data.addToCart
              }
              attrs={{
                onClick: this.addToCart,
                disabled: this.state.disableAddToCart,
                className: 'add-to-cart',
              }}
            />
          </div>
        </div>
        {this.props.data && this.props.data.continueCheckoutCTA && (
          <div className="btn-checkout">
            <div className={`btn-primary ${this.state.disableAddToCart ? 'disabled' : ''}`}>
              <DynamicContent
                tagName="button"
                innerHtml={this.props.data.continueCheckoutCTA.label}
                attrs={{
                  onClick: () => this.addToCart(this.props.data.continueCheckoutCTA),
                  disabled: this.state.disableAddToCart,
                  className: 'continue-checkout',
                }}
              />
            </div>
          </div>
        )}
        {!this.isPackageJourney && this.state.slotSelected && (
          <div className="vat-info">
            <DynamicContent tagName="p" attrs={{ className: 'vat-info-label' }} innerHtml={this.props.data.vatInfo} />
          </div>
        )}
      </div>
    );
  };

  render() {
    try {
      return (
        <div>
          {this.isQuantityInOverlay ? (
            this.renderQuantity()
          ) : this.isSWAD ? (
            <div className="main-wrapper">{this.renderAddOns()}</div>
          ) : (
            this.renderAddOns()
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'AddOnsOverlayComponent');
    }
  }
}

AddOnsOverlayComponent.PropsTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
};
