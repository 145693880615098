/*
 * Recaptcha.js
 * This file contains code for Captcha
 */
import React from 'react';
import PropTypes from 'prop-types';
import { logComponentRenderingError } from '../../../common/logger';
import ReCAPTCHA from 'react-google-recaptcha';
import '../captcha/captcha-component.scss';
import { canUseDOM, getLocalStorageByKey } from '../../../common/utility';

/**
 * ReCaptcha is a component which will be rendered by Form component, it expects its props from Form component
 * @param    {object}    data
 */
const Recaptcha = ({ data, onBlur }) => {
  const mainObj = canUseDOM() ? getLocalStorageByKey('mainObj') : {};
  const verifyCaptcha = (res) => {
    if (res) {
      const payload = {
        target: {
          name: data.name,
          value: res,
        },
      };
      onBlur(payload);
    }
  };
  const expireCaptcha = () => {
    const payload = {
      target: {
        name: data.name,
        value: '',
      },
    };
    onBlur(payload);
  };
  try {
    return (
      <>
        <div className="form-element" data-id={data.name}>
          <ReCAPTCHA
            sitekey={mainObj && mainObj.additionalProperty.siteKey ? mainObj.additionalProperty.siteKey : ''}
            render="explicit"
            onChange={(res) => verifyCaptcha(res)}
            onExpired={expireCaptcha}
          />
        </div>
        <div className="recaptcha">
          {data.showErrors ? <span className="error-msg body-copy-6">{data.message}</span> : null}
        </div>
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Recaptcha');
  }
};

Recaptcha.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }),
};

export default Recaptcha;
