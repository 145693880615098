/*
 *	This is an object containing key value pair of partnet API request and request
 */

export const PartnerApi = {
  selfContact: {
    getSuccessKey: 'getContactOutput',
    postHeader: 'updateContactInput',
    postSuccessKey: 'ContactSelfServiceOutput',
    postMethod: 'PUT',
  },
  getAgent: {
    getSuccessKey: 'getAgentOutput',
    postHeader: 'updateAgentInput',
    postSuccessKey: 'updateAgentOutput',
    postMethod: 'PUT',
  },
  createAgent: {
    postHeader: 'createAgentInput',
    postSuccessKey: 'createAgentOutput',
    postMethod: 'POST',
  },
  getOperator: {
    getSuccessKey: 'getOperatorOutput',
    postHeader: 'updateOperatorInput',
    postSuccessKey: 'updateOperatorOutput',
    postMethod: 'PUT',
  },
  createOperator: {
    postHeader: 'createOperatorInput',
    postSuccessKey: 'createOperatorOutput',
    postMethod: 'POST',
  },
  getAgentList: {
    getSuccessKey: 'listAgentOutput',
    postHeader: 'updateAgentInput',
    postSuccessKey: 'updateAgentOutput',
  },
  getPartner: {
    getSuccessKey: 'getPartnerOutput',
    postHeader: 'updatePartnerInput',
    postSuccessKey: 'updatePartnerResponse',
    postMethod: 'PUT',
  },
  getPartnerList: {
    getSuccessKey: 'listPartnerOutput',
  },
  swapContact: {
    postHeader: 'swapInput',
    postSuccessKey: 'swapOutput',
    postMethod: 'PUT',
  },
  getGuestPreferences: {
    getSuccessKey: 'preferencesVO',
    postHeader: 'preferencesIO',
    putHeader: 'preferencesUO',
  },
};
