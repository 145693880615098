import React from 'react';
import { SitecoreContext } from './lib/SitecoreContext';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import RouteHandler, { getServerSideRenderingState } from './RouteHandler';
import TelemetryProvider from '../components/miscellaneous/app-insights-telemetry/telemetry-provider';
import store from '../redux/dynamicStore/store/configureStore';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// wrap the app with:
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({ path, Router, appInsights, config, componentFactory, tenantName, IconSprite, storeData }) => {
  // configure store
  const mainStore = store(storeData);
  const routeRenderFunction = (props) => (
    <RouteHandler route={props} config={config} tenantName={tenantName} IconSprite={IconSprite} />
  );
  const ssrInitialState = getServerSideRenderingState();

  const sitecoreContext =
    ssrInitialState && ssrInitialState.sitecore && ssrInitialState.sitecore.route
      ? {
          route: ssrInitialState.sitecore.route,
          itemId: ssrInitialState.sitecore.route.itemId,
          ...ssrInitialState.sitecore.context,
        }
      : undefined;
  return (
    <Provider store={mainStore}>
      <SitecoreContext componentFactory={componentFactory} context={sitecoreContext}>
        <Router location={path} context={{}}>
          <TelemetryProvider appInsights={appInsights}>
            <Switch>
              {routePatterns.map((routePattern) => (
                <Route key={routePattern} path={routePattern} render={routeRenderFunction} />
              ))}
            </Switch>
          </TelemetryProvider>
        </Router>
      </SitecoreContext>
    </Provider>
  );
};

export default AppRoot;
