import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Card from './card';

const getCurrentOffsetTimeOnOtherTZ = (offset) => {
  let t = moment();
  t.utcOffset(offset);

  return moment.now();
};

/**
 * @param endTime - the number of seconds to countdown from
 * @param className - a class to assign to the wrapper component
 * @desc A Countdown components counts down from a given
 * number of seconds. (Maximum 24 hr)
 */
function Timercountdown({ data, className = '', hidleFlashSaleBanner }) {
  // COMPONENT STATE -------------------------------------------------------------------------------------------------
  const { startTime, endTime, daysLabel, hoursLabel, minutesLabel, secondsLabel, title } = data || {};
  const startDate = useRef(moment.utc(startTime || undefined));
  const endDate = useRef(moment.utc(endTime || undefined));
  const eventTime = moment(endDate.current).diff(moment(), 'seconds');
  const [disableCounter, setSisableCounter] = useState(true);
  const [localSeconds, setLocalSeconds] = useState(eventTime);
  const isEndDate =
    moment.duration(endDate.current.diff(getCurrentOffsetTimeOnOtherTZ(moment.parseZone(endDate.current)._offset))) >=
    0;

  // SIDE EFFECTS ----------------------------------------------------------------------------------------------------

  /**
   * @desc Subtracts the local seconds by one
   * every second, until it reaches zero.
   */
  useEffect(() => {
    hideFlashSale(isEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let intervalId;
    hideTimerBeforeStartDate();
    if (localSeconds > 0) {
      intervalId = setTimeout(() => setLocalSeconds(localSeconds - 1), 1000);
    }

    if (localSeconds <= 0) {
      hideFlashSale(false);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSeconds]);

  // UTILITY ---------------------------------------------------------------------------------------------------------

  /**
   * @param num {int}
   * @desc Pads a number with 0's and returns
   * an array of integers.
   */
  function prettify(num) {
    return num
      .toString(10)
      .padStart(2, '0')
      .split('')
      .map((val) => parseInt(val, 10));
  }

  function renderBlock(countTitle, num) {
    num = prettify(num);
    return (
      <div className="bloc-time">
        <div className={'cards'}>
          <Card number={num[0]} />
          <Card number={num[1]} />
        </div>
        <span className="count-title">{countTitle}</span>
      </div>
    );
  }

  const hideTimerBeforeStartDate = () => {
    // Hide timer in case start date is future date
    setSisableCounter(
      moment.duration(
        startDate.current.diff(getCurrentOffsetTimeOnOtherTZ(moment.parseZone(startDate.current)._offset)),
      ) >= 0,
    );
  };

  const hideFlashSale = (isEndDate) => {
    hidleFlashSaleBanner(isEndDate);
  };

  // COMPONENTS ------------------------------------------------------------------------------------------------------
  const s = localSeconds % 60;
  const m = Math.floor((localSeconds % 3600) / 60);
  const h = Math.floor((localSeconds % 86400) / 3600);
  const d = Math.floor(localSeconds / (3600 * 24));
  return (
    <>
      {localSeconds > 0 && !disableCounter && (
        <>
          <div className="countdown-title">{title}</div>
          <div className={`countdown ${className}`}>
            {renderBlock(daysLabel, d)}
            {renderBlock(hoursLabel, h)}
            {renderBlock(minutesLabel, m)}
            {renderBlock(secondsLabel, s)}
          </div>
        </>
      )}
    </>
  );
}

export default Timercountdown;
