import sha512 from 'js-sha512';

import api from '../../../../common/api-wrapper';
import { OrderService } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  checkAnonymousCart,
  generateGUID,
  getCurrentLanguage,
  getLoggedInUser,
  getPromoCode,
  removePromoCode,
  setOrderIdpromoCode,
  isAnnualPassCase,
  getSessionStorage,
  createAnnualPassData,
  checkInlineSignup,
  getAnonymousCart,
  getMainObject,
  resolvePath,
  getLocalStorageByKey,
  isLoggedInUser,
  isTicketUpgradePayment,
  getAnpGuestDetailsForTuJourney,
  getAnpTicketHoldersForTuJourney,
  backToUpgradePage,
} from '../../../../common/utility';
import { YALocalStorageSvc } from '../../b2c-purchase-journey/my-cart/yasarena-cart-management-service';
import { checkTenant } from '../../../../common/utility/tenantsUtils';
import { getEncrytpedText } from '../utils';

export class ApplePayComponentService {
  constructor(componentData) {
    this.componentData = componentData;
    this.getSessionRequestObject = this.getSessionRequestObject.bind(this);
    this.getPayFortApiData = this.getPayFortApiData.bind(this);
    this.getSupportedVersion = this.getSupportedVersion.bind(this);
    this._generate_signature = this._generate_signature.bind(this);
    this._sortObjectKeys = this._sortObjectKeys.bind(this);
    this._getGuestUserStatus = this._getGuestUserStatus.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.reconcile = this.reconcile.bind(this);
    this.getDynamicMerchantReference = this.getDynamicMerchantReference.bind(this);
    this.isRenewAnnualPass = componentData.paymentOptions.pageVariant === 'annualPassRenew';
    this.is2StepPaymentYasArena = componentData.is2StepPaymentYasArena;
    this._enableDeviceFingerprint = componentData?.paymentOptions?.applepayconfig?.enableDeviceFingerprint;
  }

  getSupportedVersion() {
    let supportedVersion = 1;
    for (let index = this.componentData.paymentOptions.applepayconfig.supportedVersion; index === 1; index--) {
      if (window.ApplePaySession.supportsVersion(index)) {
        supportedVersion = index;
        break;
      }
    }
    return supportedVersion;
  }

  getSessionRequestObject() {
    return {
      countryCode: this.componentData.paymentOptions.applepayconfig.countryCode,
      currencyCode: this.componentData.paymentOptions.applepayconfig.currencyCode,
      supportedNetworks: this.componentData.paymentOptions.applepayconfig.supportedNetworks,
      merchantCapabilities: this.componentData.paymentOptions.applepayconfig.merchantCapabilities,
    };
  }

  getCCInfo(approvalCode) {
    let cardInfo = {};
    const orderApprovalInfo = localStorage.getItem('orderApprovalInfo');
    if (orderApprovalInfo) {
      const cardInfoDecrypted = atob(orderApprovalInfo);
      if (cardInfoDecrypted) {
        const { cardNumber, paymentOption } = JSON.parse(cardInfoDecrypted);
        cardInfo = {
          approval_code: approvalCode || '',
          card_number: cardNumber || '',
          card_type: paymentOption || '',
        };
        return cardInfo;
      }
    }
    return cardInfo;
  }

  getAnnualPassRequest = (isRenewAnnualPass, cartData, isTicketUpgrade) => {
    const {
      apMainPassholderPurchaseEmailTemplateID,
      apMainPassholderRenewEmailTemplateID,
      apAddPassholderNewUserPurchaseEmailTemplateID,
      apAddPassholderNewUserRenewEmailTemplateID,
      apAddPassholderRenewEmailTemplateID,
      apAddPassholderPurchaseEmailTemplateID,
    } = this.componentData.paymentOptions;

    const annualPassTicketHolders = isTicketUpgrade
      ? getAnpTicketHoldersForTuJourney()
      : createAnnualPassData({ cartData });

    return {
      isAnnualRenew: isRenewAnnualPass,
      emailTemplateIdAnnualPass: isRenewAnnualPass
        ? apMainPassholderRenewEmailTemplateID
        : apMainPassholderPurchaseEmailTemplateID,
      emailTemplateIdWithYasId: isRenewAnnualPass
        ? apAddPassholderRenewEmailTemplateID
        : apAddPassholderPurchaseEmailTemplateID,
      emailTemplateIdWithNoYasId: isRenewAnnualPass
        ? apAddPassholderNewUserRenewEmailTemplateID
        : apAddPassholderNewUserPurchaseEmailTemplateID,
      ticketHolders: annualPassTicketHolders,
    };
  };

  getPayFortApiData(applePayData, orderData, ip) {
    const applePayconfig = this.componentData.paymentOptions.applepayconfig;
    let userId = '';
    let customerName = '';
    let annualPassRequest = null;
    const isTicketUpgrade = isTicketUpgradePayment();
    const ticketUpgradeData = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);
    const cart = getAnonymousCart();
    const isRenewAnnualPass = this.componentData.paymentOptions.pageVariant === 'annualPassRenew' || false;
    const cartData = isRenewAnnualPass
      ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
      : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart));
    if (isTicketUpgrade) {
      if (isLoggedInUser()) {
        const loggedinUser = getLoggedInUser();
        userId = loggedinUser.email;
        customerName = `${loggedinUser.userName} ${loggedinUser.lastName}`;
        if (cartData) {
          const isAnnualPass = isAnnualPassCase(cartData, true);
          if (isAnnualPass) {
            annualPassRequest = this.getAnnualPassRequest(isRenewAnnualPass, cartData, true);
          }
        }
      } else {
        userId = ticketUpgradeData?.loggedAccount?.email;
        customerName = `${ticketUpgradeData?.loggedAccount?.firstName} ${ticketUpgradeData?.loggedAccount?.lastName}`;
      }
    } else {
      if (checkAnonymousCart()) {
        userId = cart.cart.reservationOwner && cart.cart.reservationOwner.email;
        customerName = `${cart.cart.reservationOwner.firstName} ${cart.cart.reservationOwner.lastName}`;
      } else {
        const loggedinUser = getLoggedInUser();
        userId = loggedinUser.email;
        customerName = `${loggedinUser.userName} ${loggedinUser.lastName}`;

        if (checkTenant(UIConfig.YIB2C) && !isLoggedInUser()) {
          const guestUserDetails = getLocalStorageByKey('guestCheckoutUserDetails');
          userId = guestUserDetails?.Email;
          customerName = `${guestUserDetails?.FirstName} ${guestUserDetails?.LastName}`;
        }

        // AnnualPass request object

        if (cartData) {
          const isAnnualPass = isAnnualPassCase(cartData, true);
          if (isAnnualPass) {
            annualPassRequest = this.getAnnualPassRequest(isRenewAnnualPass, cartData, false);
          }
        }
      }
    }

    const merch_ref = this.getDynamicMerchantReference(orderData);
    const payfortPayload = {
      digital_wallet: 'APPLE_PAY',
      command: 'PURCHASE',
      access_code: applePayconfig.accesscode,
      merchant_identifier: this.componentData.merchantIdentifier,
      merchant_reference: merch_ref,
      amount: isTicketUpgradePayment() ? orderData?.sale?.total?.gross * 100 : orderData.payfortAmount,
      order_description: orderData.reservationCode || orderData.sale?.reservationcode?.ak,
      currency: applePayconfig.currencyCode || orderData?.sale?.total?.currency,
      language: 'en',
      customer_email: userId || ticketUpgradeData?.loggedAccount?.email,
      ...(ip && { customer_ip: ip }),
      apple_data: applePayData.paymentData.data,
      apple_signature: applePayData.paymentData.signature,
      customer_name: customerName,
      apple_header: {
        apple_transactionId: applePayData.paymentData.header.transactionId,
        apple_ephemeralPublicKey: applePayData.paymentData.header.ephemeralPublicKey,
        apple_publicKeyHash: applePayData.paymentData.header.publicKeyHash,
      },
      apple_paymentMethod: {
        apple_displayName: applePayData.paymentMethod.displayName,
        apple_network: applePayData.paymentMethod.network,
        apple_type: applePayData.paymentMethod.type,
      },
      ...(checkTenant(UIConfig.YIB2C) && {
        merchant_reference: orderData.orderID,
        amount: orderData.TotalAmount * 100,
        order_description: orderData.orderID,
        merchant_extra: orderData?.merchant_extra,
        merchant_extra1: orderData?.merchant_extra1,
      }),
      ...(this._enableDeviceFingerprint && {
        device_fingerprint: localStorage.getItem('device_fingerprint'),
      }),
    };

    payfortPayload.signature = this._generate_signature(payfortPayload);
    const reconcilationRequest = this.componentData.services.reconcile.url;
    const payfortPurchaseObj = {
      url: this.componentData.payFortUrl2,
      locale: getCurrentLanguage(),
      orderId: orderData.orderID || orderData?.sale?.ak,
      emailTemplateId: this.componentData.paymentOptions.emailTemplateId,
      invoiceTemplateId: this.componentData.paymentOptions.invoiceTemplateId,
      marketType: ' ',
      paymentMethod: 'ApplePay',
      tenantId: getLoggedInUser().tenantID,
      promoCode: null,
      userId: getEncrytpedText(userId || ticketUpgradeData?.loggedAccount?.email),
      annualPassRequest: annualPassRequest,
      isTicketUpgradeRequest: isTicketUpgradePayment(),
      payfortReconcileObj: {
        query_command: 'CHECK_STATUS',
        access_code: applePayconfig.accesscode,
        merchant_identifier: this.componentData.merchantIdentifier,
        merchant_reference: merch_ref,
        language: 'en',
        fort_id: '',
        signature: '',
        cardInfo: this.getCCInfo(),
        ...(checkTenant(UIConfig.YIB2C) && {
          merchant_reference: orderData.orderID,
          merchant_extra: orderData?.merchant_extra,
          merchant_extra1: orderData?.merchant_extra1,
        }),
      },
      ...(checkTenant(UIConfig.YIB2C) && {
        travelBoxAddPaymentUrl: this.componentData?.services?.TravelBoxAddPaymentUrl?.url,
        travelBoxConfirmBookingUrl: this.componentData?.services?.TravelBoxConfirmBookingUrl?.url,
      }),
    };

    return { payfortPayload, payfortPurchaseObj, reconcilationRequest };
  }

  reconcile(reconcileData) {
    const data = { ...reconcileData };
    const approvalCode = data?.payfortReconcileObj?.cardInfo?.approval_code;
    data.payfortReconcileObj = {
      ...data?.payfortReconcileObj,
      cardInfo: this.getCCInfo(approvalCode),
    };
    let reconcileConfig = {
      method: 'POST',
      url: this.componentData.services.reconcile.url,
      data: data,
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    };
    if (checkAnonymousCart()) {
      return api.api(reconcileConfig);
    } else {
      return api.experienceServices(reconcileConfig);
    }
  }

  _sortObjectKeys = (obj = {}) => {
    return Object.keys(obj)
      .sort()
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
  };

  _generate_signature = (payload) => {
    let sorted_Payload = this._sortObjectKeys(payload);
    const merch_Pass = this.componentData.paymentOptions.applepayconfig.merchantPassphrase;
    let signatureString = merch_Pass;

    for (let key in sorted_Payload) {
      if (sorted_Payload.hasOwnProperty(key)) {
        if (typeof sorted_Payload[key] === 'object') {
          let subPropsString = `${key}={`;
          let subProps = sorted_Payload[key];
          for (let subKey in subProps) {
            subPropsString += `${subKey}=${subProps[subKey]}, `;
          }
          subPropsString = `${subPropsString.trim().replace(/.$/, '')}}`;
          signatureString += subPropsString;
        } else {
          signatureString += key + '=' + sorted_Payload[key];
        }
      }
    }

    signatureString += merch_Pass;

    return sha512.sha512(signatureString);
  };

  _getGuestUserStatus = () => {
    const cart = getAnonymousCart();
    if (cart) {
      return cart;
    }
    return false;
  };

  getDynamicMerchantReference = (data) => {
    const specialChar = '_';
    let generatedMerRef = generateGUID() + specialChar + (data.reservationCode || data.sale?.reservationcode?.ak);

    /*
                if length is greater than 40 payfort will fail
                if we cut the merchatnRef then reconcile will fail,hence snipping guid as a fail safe
                however it still may fail is res code is more than 39(_ + 39)
            */
    // if (generatedMerRef.length > 40) {
    //     generatedMerRef = specialChar + this.merchantReference;
    // }
    return generatedMerRef;
  };
  //Creating Order in omini
  createOrder = async (cartData, partnerRefernce, dueDate) => {
    const cart = {
      cart: { ...cartData },
    };
    const paymentInfo = {
      cmsData: {
        paymentOptions: {
          tncType: this.componentData.paymentOptions.tncType,
          tncCondition: this.componentData.paymentOptions.tncCondition,
        },
      },
    };

    localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(paymentInfo));
    const { createAnnonymousOrder, createOrderYasId, ticketUpgrades, checkTermsCtdnApi } = this.componentData.services;
    const serviceUrl = checkInlineSignup() ? createOrderYasId.url : createAnnonymousOrder.url;

    if (this.is2StepPaymentYasArena) {
      const orderDetails = YALocalStorageSvc.getSetOrderDetails();
      if (Object.keys(orderDetails).length > 0) {
        return await { data: orderDetails };
      }
    }

    const handleThen = (response) => {
      const userPromoCode = isTicketUpgradePayment()
        ? response?.data?.sale?.reservationcode?.ak
        : `${response.data.reservationCode}_${getPromoCode()}`;
      setOrderIdpromoCode(userPromoCode);
      removePromoCode();
      this.is2StepPaymentYasArena && YALocalStorageSvc.getSetOrderDetails(response.data);
      if (isTicketUpgradePayment()) {
        if (response?.data?.sale?.ak) {
          return response;
        } else {
          return backToUpgradePage();
        }
      } else {
        return response;
      }
    };
    if (isTicketUpgradePayment()) {
      const tenantId = getLoggedInUser().tenantID || resolvePath(getMainObject(), 'tenantID');
      const ticketUpgradesUrl = ticketUpgrades?.url?.replace('{0}', tenantId);
      let ticketUpgradePayload = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);
      const yasId = getLoggedInUser()?.yasId;
      if (yasId && ticketUpgradePayload && ticketUpgradePayload.yasId === null) {
        ticketUpgradePayload.loggedAccount.yasId = yasId;
        ticketUpgradePayload.yasId = yasId;
      }
      if (ticketUpgradePayload?.productItemType === UIConfig.parksTicketUpgrade.annualPassType) {
        const guestDetails = getAnpGuestDetailsForTuJourney();
        if (guestDetails && guestDetails.length > 0) {
          ticketUpgradePayload.items.map((obj, index) => {
            return (obj.ticketHolderDetails = guestDetails[index]);
          });
        }
      }

      return await OrderService.createTicketUpgradeOrder(
        ticketUpgradesUrl,
        UIConfig.loader.defaultPreLoaderTarget,
        ticketUpgradePayload,
        checkTermsCtdnApi?.url,
      ).then(handleThen);
    } else {
      if (checkAnonymousCart()) {
        cart.cart.reservationOwner =
          this._getGuestUserStatus().cart.reservationOwner !== null
            ? this._getGuestUserStatus().cart.reservationOwner
            : null;
        let mainObj = getMainObject(),
          autoLoginStatus = resolvePath(mainObj, 'additionalProperty.enableAutoLogin'),
          autoLoginParam = `isAutoLogin=${autoLoginStatus}`,
          extraParams;

        if (autoLoginStatus) {
          extraParams = autoLoginParam;
        }
        return await OrderService.createAnnonymousOrder(
          'ApplePay',
          serviceUrl,
          UIConfig.loader.defaultPreLoaderTarget,
          cart,
          extraParams,
          this.componentData.services.updateCart,
        ).then(handleThen);
      } else {
        return await OrderService.createOrder(
          'ApplePay',
          this.isRenewAnnualPass
            ? this.componentData.services.renewPass.url
            : this.componentData.services.createOrder.url,
          UIConfig.loader.defaultPreLoaderTarget,
          partnerRefernce,
          dueDate,
          null,
          this.isRenewAnnualPass,
          this.componentData.services.getGuestProfile && this.componentData.services.getGuestProfile.url,
          null,
          this.componentData.services.updateCart,
          this.componentData.services,
        ).then(handleThen);
      }
    }
  };
}

ApplePayComponentService.displayName = 'ApplePayService';
