/**
 * tabs-filter-listing-component.js
 * This file contains the code of the parent component for filters and listing
 * @author      SapientRazorfish
 * @license     Miral
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import GTMData from '../b2c-purchase-journey/gtm-data';
import ListFilter from '../../presentation/list-filter';
import TabFeatureList from '../tab-feature-list';
import { AnchorLink, DynamicContent, ErrorSummary, Overlay } from '../../presentation/base';
import ProductStore from '../b2c-purchase-journey/purchase-journey-wrapper/product-store';
import UICONFIG from '../../../common/UIConfig';
import { getListContent, getB2CProductDetails } from '../../../common/coveo-api.js';
import { default as Select } from 'react-select';
import { components } from 'react-select';
import Dropdown from 'react-dropdown';
import PackageExperienceB2C from '../b2c-purchase-journey/add-ons/b2c-package-experience/b2c-package-experience-component';
import {
  canUseDOM,
  randomNumber,
  getErrorMap,
  getFallbackLanguage,
  createMappedData,
  flipObjectKeyValues,
  getLoggedInUser,
  isEmpty,
  getUserAgent,
  hasHeaderComponent,
  decodeQueryString,
  getMainObject,
  isLoggedInUser,
  getCookie,
  getLoginUser,
  isMatchTenant,
  checkIfParks,
  getGa4Category,
  toLowerCase,
  checkGA4Tenants,
  checkTenant,
  getMembershipDetails,
  getPartnerCodeData,
  setLocalStorage,
} from '../../../common/utility';
import ApiWrapper from '../../../common/api-wrapper';
import { PerformanceService, ServiceConfig } from '../../../common/services';
import AddOnsTicketComponent from '../b2c-purchase-journey/add-ons/add-ons-ticket-component';
import CartActions from '../b2c-purchase-journey/cart/cart-actions';
import { secProds } from './../b2c-purchase-journey/add-ons/add-ons-utility.js';
import { bookNowClickAnalytics } from '../../../common/analytics-events';
import { logComponentRenderingError } from '../../../common/logger';

import {
  noFilter,
  setFieldTypeData,
  setSliderData,
  dataMashUp,
  createTabData,
  scrollTabMobileViewPort,
  getProductTabType,
  enrichProductData,
  getPerformanceId,
  updateCouponCodeData,
  getDiscount,
  updateSecProdArray,
  getCategoryProds,
  changeCategoryToString,
  getTabIndexByHash,
  CONFIG,
} from './helpers';
import UIConfig from '../../../common/UIConfig';

const dateTimeFormat = 'YYYY/MM/DD@hh:mm:ss';
const MultiFilterSettings = {
  DATE_FORMAT: 'YYYY/MM/DD',
  MONTH: 'month',
  THIS_MONTH_VALUE: 'This_Month',
  NEXT_MONTH_VALUE: 'Next_Month',
};

const GTMDataOnClickTab = (data) => {
  if (checkGA4Tenants()) {
    GTMData.push(UIConfig.ga4Constants.CLICK_TAB, {
      name: checkTenant(UIConfig.iamMapping.yasisland) ? toLowerCase(data.name) : data.name,
      elementText: checkTenant(UIConfig.iamMapping.yasisland) ? toLowerCase(data.elementText) : data.elementText,
      category: toLowerCase(getGa4Category(window?.location?.pathname)),
    });
  }
};

const renderOverlayErrorMsg = (errorOverlayObj) => {
  if (!isEmpty(errorOverlayObj)) {
    return (
      <ErrorSummary
        data={{
          error: {
            code: errorOverlayObj.errorcode,
            text: errorOverlayObj.text,
          },
        }}
      />
    );
  }
};

const TabsFilterListing = (props) => {
  const tabs = useRef({});
  const coveoDefaultParams = useRef({});
  const sliderObj = useRef({});
  const facetObj = useRef({});
  const venueObj = useRef({});
  const calenderObj = useRef({});
  const products = useRef({});
  const categoryKeyObj = useRef({});
  const groupBy = useRef([]);
  const tabsTitle = useRef({});
  const pjData = useRef({});
  const cartActions = useRef(null);
  const activeProdId = useRef(null);
  const activePackageInfo = useRef(null);
  const activePackageDetails = useRef([]);
  const groupingNotRequired = useRef(false);
  const config = useRef({});
  const responseObj = useRef(null);
  const productList = useRef([]);
  const isFirstRender = useRef(true);

  const [coveoData, setCoveoData] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [purchaseWrapperErrors, setPurchaseWrapperErrors] = useState('');
  const [errObj, setErrObj] = useState('');
  const [cartData, setCartData] = useState({});
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [errorOverlayObj, setErrorOverlayObj] = useState({});
  const [defaultFilterValue, setDefaultFilterValue] = useState({});
  const [locationKeys, setLocationKeys] = useState([]);
  const [multiSelectValue, setMultiSelectValue] = useState([]);
  const [sortFieldSelected, setSortFieldSelected] = useState(props?.data?.sortingData?.options[0]?.text || '');
  const [sortInitialValue, setSortInitialValue] = useState(props?.data?.sortingData?.options[0]?.label || '');
  const [isPackageJourney, setIsPackageJourney] = useState(false);
  const [packageListingSortSelection, setPackageListingSortSelection] = useState('');

  const isMounted = useRef(true);
  const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();
  const { history } = props;

  useEffect(() => {
    if (!isFirstRender.current) {
      getCoveoData();
    }
  }, [multiSelectValue, sortFieldSelected]);

  useEffect(() => {
    if (tenantId === UICONFIG.ymcB2CTenant && history) {
      return history.listen((location) => {
        const params = new URL(window.location).searchParams;
        const hashValue = window.location.hash ? decodeURIComponent(window.location.hash.substring(1)) : '';
        if (history.action === 'POP' && locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          getCoveoDatabyUrlParam(filters, params, hashValue);
        }
      });
    }
  }, [locationKeys]);

  /**
   * getCoveoDatabyUrlParam Coveo data can be fetched by using URL query params
   * @param {[string]} location hash
   * @param {filters} Coveo Query Filters hash
   */
  const getCoveoDatabyUrlParam = (filters, params, hashvalue) => {
    let filterData = {};
    let toFilter = {};
    let name = '';
    const search = canUseDOM && window.location.search;
    const nohash =
      window.location.hash && decodeURIComponent(window.location.hash.substring(1)) !== 'all' ? false : true;
    if (canUseDOM() && filters.length > 0) {
      filters.forEach((item) => {
        item.fields.forEach((data) => {
          if (params.has(data.name.toLowerCase())) {
            filterData = {
              ...filterData,
              [data.name]: params.has(data.name.toLowerCase())
                ? params.get(data.name.toLowerCase()).toUpperCase()
                : 'no-filter',
            };
            toFilter = { ...toFilter, [data.name]: data.fieldToConfirm };
          } else if (data.type === 'ToggleButton' && hashvalue && hashvalue === data.name.toLowerCase()) {
            filterData = {
              ...filterData,
              [data.type]: data.name,
            };
            toFilter = {
              ...toFilter,
              [data.type]:
                data.name.toLowerCase() === 'all'
                  ? {}
                  : {
                      [data.type]: `@${data.fieldToConfirm}${data.operator}${data.name}`,
                    },
            };
            name = data.type ? data.type : '';
          } else if (data.type !== 'ToggleButton') {
            filterData = { ...filterData, [data.name]: 'no-filter' };
            toFilter = { ...toFilter, [data.name]: data.fieldToConfirm };
          } else if (data.type === 'ToggleButton' && !hashvalue) {
            filterData = { ...filterData, [data.type]: 'All' };
            toFilter = { ...toFilter, [data.type]: {} };
            name = data.type ? data.type : '';
          }
        });
      });
    }
    const searchParams = [...params.values()];
    const isnoSearch =
      Boolean(nohash) && searchParams.length == 0 ? true : searchParams.every((v) => v.toLowerCase() === 'no-filter');
    setDefaultFilterValue(filterData);
    if ((!isnoSearch && search) || hashvalue !== 'all') {
      getCoveoData(filterData, toFilter, name);
    }
    if (isnoSearch && nohash) {
      getCoveoData(null, null);
      venueObj.current = {};
    }
  };

  useEffect(() => {
    const { coveoSettings, enablePJPopup, variant } = props.data;
    //componentWillMount code
    const groupByObj = {
      field: `@${coveoSettings.coveoKeyMap.groupBy}`,
      maximumNumberOfValues: coveoSettings.groupByCount || 50,
    };
    groupBy.current.push(groupByObj);
    isFirstRender.current = false;

    if (variant === 'v-yasisland-filter' || variant === 'v-event-filter' || variant === 'v-yasarena-filter') {
      noFilter(calenderObj, getCoveoData, coveoSettings.coveoKeyMap.endDate);
    } else {
      const params = new URL(window.location).searchParams;
      const search = new URL(window.location).search;
      const hashValue = decodeURIComponent(window.location.hash.substring(1));
      if (((search && search.length > 0) || hashValue) && tenantId === UICONFIG.ymcB2CTenant) {
        getCoveoDatabyUrlParam(filters, params, hashValue);
      } else {
        getCoveoData(null, null, null, true);
      }
    }

    //componentDidMount code
    if (enablePJPopup) {
      window.PubSub.subscribe(UICONFIG.events.PURCHASEJOURNEYDATA, (msg, data) => {
        if (isMounted.current) {
          pjData.current = data.purchaseJourneyData;
          cartActions.current = new CartActions({
            serviceUrl: pjData.current.services,
            moduleName: 'purchase-journey',
            tenantId: getLoggedInUser().tenantID,
          });
          setStateOfMinicart();
        }
      });
    }

    /* added for fwad minicart */
    if (checkIfParks()) {
      window.PubSub.subscribe(UIConfig.events.UPDATE_HEADER_CART, (msg, data) => {
        productList.current = data?.items;
        setCartData(cartActions.current.getCustomCart(data?.items || [], true));
      });
    }
    window.PubSub.subscribe('closeOverlay', () => {
      activeProdId.current = null;
      setIsPackageJourney(false);
      setIsOverlayOpen(false);
    });

    // get ItemID
    setTimeout(() => {
      isLoggedInUser() && getMyFavsWishlist();
    }, 500);

    return () => {
      isMounted.current = false;
      window.removeEventListener('hashchange', updateActiveTab, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*----------------- My Faves Listing START -------------------------*/
  // Fetch the ItemID based on Language and add in localstorage
  const getMyFavsWishlist = () => {
    const mainObj = getMainObject();
    if (!mainObj) {
      return Promise.reject('no mainObj');
    }

    let userEmailId = ServiceConfig.getPartnerEmailId();
    const isUserLoggedIn = Object.keys(getLoginUser()).length > 0 ? true : false;
    const url = `${props.data.services.GetWishList?.url}?UserId=${userEmailId} ${
      ServiceConfig.getWishlistID() ? '&wid=' + ServiceConfig.getWishlistID() : ''
    }`;
    if (!ServiceConfig.getWishlistID() && !isUserLoggedIn) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }

    const config = {
      url: url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': mainObj.additionalProperty.ocpApimSubscriptionKey,
      },
      moduleName: 'wishlist',
    };

    return ApiWrapper.experienceServices(config, isUserLoggedIn ? true : false)
      .then((response) => {
        return new Promise((resolve) => {
          const wishlistData = {};
          if (response && response.data) {
            myWishlistFinalData(response.data);
          }
          if (response && response.data && response.data.wishlist) {
            response.data.wishlist
              .map((parent) => parent.itemId)
              .forEach((item) => (item ? (wishlistData[item] = true) : null));
            response.data.wishlist
              .map((parent) => parent.items.map((child) => child.itemId))
              .toLocaleString()
              .split(',')
              .forEach((item) => (item ? (wishlistData[item] = true) : null));
            canUseDOM() && localStorage.setItem('wishlistData', JSON.stringify(wishlistData));
          }
          resolve(response.data, response.status);
        });
      })
      .catch((response) => {
        return new Promise((resolve, reject) => {
          reject(response.error);
        });
      });
  };

  const myWishlistFinalData = (myFavsWishlistDataItem) => {
    const myFavslists = [];
    myFavsWishlistDataItem.myFavslists.map((item) => {
      if (getCookie('myLang').slice(0, 2) === item.language.slice(0, 2)) {
        myFavslists.push(item.itemId);
      }
    });
    canUseDOM() && localStorage.setItem('wishlistData', JSON.stringify(myFavslists.toString()));
  };
  /*----------------- My Faves Listing END -------------------------*/

  /**
* getCoveoData  function that makes the coveo call.
                getListContent is the promise that hits the coveo with the config items.
                It prepares the config object to be used to make the call
* @param    {[Object]} obj [updated filter object].
* @param    {[String]} toFilter [coveo key for each filter type to make the coveo call].
* @param    {[String]} sectionTitle [to distinguish facet and  slider type filter].
* @return   {Void} constructor does not return anythingd.
*/
  const getCoveoData = (obj, expression, type, isFirstLoad, sortingMethodYmc) => {
    const {
      categoryType,
      contentType,
      coveoSettings,
      currentLanguage,
      hideExpired,
      hideViewMoreLabel,
      includeCategory,
      includeProductIds,
      isDetailPageWithFilterListing,
      isMobileOnly,
      isProductTypeEventQuery,
      isSimilarExpAuthoredDriven,
      productEventType,
      productId,
      tenantId,
      tilesToShowInitially,
      variant,
    } = props.data;

    const { coveoKeyMap, coveoToken, defaultFilters, serviceUrl, sortQuery } = coveoSettings;

    const sortCriteria = coveoKeyMap.sortCriteria ? coveoKeyMap.sortCriteria.replace(/[0-9]/g, '') : 'fieldascending';
    let expiredEventsFilter = null;

    if ((obj && Object.keys(obj).length > 2) || (expression && Object.keys(expression).length > 2)) {
      type = CONFIG.dateRangeType;
      for (const key in expression) {
        if (!expression.hasOwnProperty(key)) {
          continue;
        }
        if (key === 'ToggleButton' && variant === 'v-f1-multi-filter') {
          type = 'ToggleButton';
        }
        (key === 'ToggleButton' && variant === 'v-f1-multi-filter') || key === 'DateRangeFilter'
          ? setFieldTypeData(type, key, expression, facetObj, calenderObj)
          : setSliderData(key, expression, obj, sliderObj, venueObj, variant);
      }
    } else if (expression && obj) {
      for (const key in expression) {
        if (!expression.hasOwnProperty(key)) {
          continue;
        }
        if (key === 'ToggleButton' && variant === 'v-f1-multi-filter') {
          type = 'ToggleButton';
        }
        key === type
          ? setFieldTypeData(type, key, expression, facetObj, calenderObj)
          : setSliderData(key, expression, obj, sliderObj, venueObj, variant);
      }
    } else {
      facetObj.current = {};
      sliderObj.current = {};
      calenderObj.current = {};
    }
    if (hideExpired) {
      expiredEventsFilter = `(@${coveoKeyMap.endDate}>=${moment
        .utc()
        .locale(UICONFIG.languages.enGB)
        .format(dateTimeFormat)})`;
    }

    if (includeCategory) {
      if (isProductTypeEventQuery) {
        categoryKeyObj.current.productEventType = productEventType;
      } else {
        categoryKeyObj.current.categoryKey = categoryType;
      }
    }

    const mapMultiFilters = () => {
      const _mappedMultiFilters = {};

      multiSelectValue.forEach((_filterValues, index) => {
        if (_filterValues) {
          let _coveoQuery = filters[index].fields[0].coveoQuery;
          _mappedMultiFilters[_coveoQuery] = _filterValues.filterValues;
        }
      });
      return _mappedMultiFilters;
    };

    const sortSelectedSortOrder = () => {
      let sortVal = sortFieldSelected;

      if (sortVal.sortOrder == undefined) {
        return sortQuery[0].sortOrder;
      }

      return sortVal.sortOrder;
    };

    const sortSelectedCoveoField = () => {
      let sortVal = sortFieldSelected;

      if (sortVal.coveoField == undefined) {
        return coveoKeyMap.sortField;
      }

      return sortVal.coveoField;
    };

    let defaultParams = defaultFilters;
    if (
      (variant === 'v-event-filter' || variant === UICONFIG.eventListingFilter.yaFilterVariant) &&
      coveoKeyMap.hasOwnProperty('isMobileOnly') &&
      isMobileOnly
    ) {
      defaultParams = {
        ...defaultParams,
        [coveoKeyMap.isMobileOnly]: 0,
      };
    }

    if (isDetailPageWithFilterListing) {
      categoryKeyObj.current.productEventType = productEventType;
      categoryKeyObj.current.categoryKey = categoryType;
      categoryKeyObj.current.excludeProductId = productId;
    }
    if (isSimilarExpAuthoredDriven && includeProductIds) {
      categoryKeyObj.current.productEventType = productEventType;
      categoryKeyObj.current.categoryKey = categoryType;
      categoryKeyObj.current.includeProductIds = includeProductIds;
    }
    let queryParams = decodeQueryString();

    let sortCriteriaItem;
    if (sortQuery && sortQuery.length && sortQuery[0].sortOrder) sortCriteriaItem = sortQuery[0].sortOrder;

    if (checkTenant(UIConfig.iamMapping.ymc) && queryParams?.sortingBy?.length) {
      sortCriteriaItem = queryParams?.sortingBy;
    } else sortCriteriaItem = sortCriteria;
    //coveo config object
    config.current = {
      perPageResults: (queryParams.page && 100) || (hideViewMoreLabel ? tilesToShowInitially.desktop : 100),
      fieldsToInclude: Object.values(coveoKeyMap),
      coveoKeyMap,
      serviceUrl,
      tenantId,
      language: currentLanguage,
      facetFilter: facetObj.current,
      slideFilter: sliderObj.current,
      venueFilter: venueObj.current,
      expirendEventsFilter: expiredEventsFilter,
      calenderFilter: calenderObj.current,
      multiSelectFilters: multiSelectValue.length > 0 ? mapMultiFilters() : {},
      contentType,
      groupBy: groupBy.current,
      coveoToken,
      sortField:
        variant === UICONFIG.commonVariant.listOfMorePackagesWithFilter
          ? sortSelectedCoveoField()
          : coveoKeyMap.sortField,
      sortCriteria:
        variant === UICONFIG.commonVariant.listOfMorePackagesWithFilter ? sortSelectedSortOrder() : sortCriteriaItem,
      defaultParams,
      ...categoryKeyObj.current,
      firstResult: (queryParams.startIndex && Number(queryParams.startIndex)) || 0,
      tabType: queryParams.tabType,
      availabilityFilter: JSON.parse(sessionStorage.getItem('ymcAvailablitySort')),
    };
    variant === 'product-list' && coveoKeyMap.fromPrice && config.current.fieldsToInclude.push(coveoKeyMap.fromPrice);

    contentType === 'ProductDetail'
      ? config.current.fieldsToInclude.push(coveoKeyMap.finePrint, coveoKeyMap.purchaseLink, coveoKeyMap.priceText)
      : config.current.fieldsToInclude.push(coveoKeyMap.iconData);

    if (contentType === 'OfferAndPromotions') {
      config.current.fieldsToInclude.push(coveoKeyMap.redeemText, coveoKeyMap.redeemUrl);
    }

    //coveo call
    if (isSimilarExpAuthoredDriven) {
      includeProductIds && coveoCall(isFirstLoad);
    } else {
      setTimeout(() => {
        coveoCall(isFirstLoad);
      }, 1100);
    }
  };

  const coveoCall = (isFirstLoad) => {
    if (canUseDOM()) {
      getListContent(config.current).then((res) => {
        if (isMounted.current) {
          const contentResults = dataMashUp(res, props.data, tabsTitle);
          const coveoData = createTabData(contentResults, props.data, groupByTabs);
          GTMData.getTenantId() === UICONFIG.ymcB2CTenant &&
            GTMData.push('productImpressions', { products: coveoData });
          setCoveoData(coveoData);
          setErrorOverlayObj({});
          setTotalCount(contentResults.totalCount || 0);
          scrollTabMobileViewPort();
          window.addEventListener('hashchange', () => updateActiveTab(coveoData), false);
        }
      });
    }
  };

  /**
   * error handling
   * @param {Object} err error object
   */
  const errorCallback = (serviceName, error) => {
    if (error) {
      if (serviceName === 'getCoveoProducts' && error.code.toString() === UICONFIG.errorCodes.notFound) {
        const errorObj = getErrorMap(
          serviceName,
          pjData.current.services[serviceName].errors,
          false,
          error,
          purchaseWrapperErrors,
        );
        setPurchaseWrapperErrors(errorObj);
      } else {
        const errorObj = getErrorMap(serviceName, pjData.current.services[serviceName].errors, false, error, errObj);
        setErrObj(errorObj);
      }
    }
  };

  const closeOverlay = () => {
    window.PubSub.subscribe('closeOverlay', () => {
      activeProdId.current = null;
      setIsPackageJourney(false);
      setIsOverlayOpen(false);
    });
  };
  const invokePackagedJourney = (packageCode, packageTitle) => {
    const { coveoSettings } = props.data;
    const datalayerItem = canUseDOM() && window.dataLayer && window.dataLayer[0];
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();
    const preLoaderTarget = UICONFIG.loader.defaultPreLoaderTarget;
    const isPreLoader = true;
    const perPageResults = UICONFIG.b2c.purchaseJourney.coveoResultsPerPage;
    const coveoKeyMap = coveoSettings.coveoKeyMap;
    const fieldsToInclude = Object.values(coveoSettings.packageKeyMap);
    const lang = getFallbackLanguage();
    const searchTemplate = 'Package';
    const productResults = {};
    const pkgInfo = {
      pkgCode: packageCode,
      pkgTitle: packageTitle,
    };
    activePackageInfo.current = pkgInfo;

    if (!packageCode) {
      return;
    }

    setIsPackageJourney(true);
    ApiWrapper.coveoService({
      preLoaderTarget,
      url: coveoSettings.serviceUrl,
      method: 'POST',
      preLoader: isPreLoader,
      data: {
        numberOfResults: perPageResults,
        fieldsToInclude: fieldsToInclude,
        aq: `(@${coveoKeyMap.language}==${lang})(@${coveoKeyMap.templateName}==${searchTemplate})(@${coveoSettings.packageKeyMap.packageCode}==${packageCode})`,
        searchHub: mainObj.additionalProperty.searchHub,
      },
    }).then((coveoData) => {
      productResults.results = [];
      productResults.totalCount = coveoData.data.totalCount;

      coveoData.data.results.forEach((v) => {
        productResults.results.push(v.raw);
      });

      const packageDetails = Object.entries(coveoSettings.packageKeyMap).reduce((acc, [key, value]) => {
        if (productResults.results.length && productResults.results[0].hasOwnProperty(value)) {
          return { ...acc, [key]: productResults.results[0][value] };
        }
        return acc;
      }, {});

      activePackageDetails.current = packageDetails;

      setIsOverlayOpen(true);
      setErrorOverlayObj({});
    });
  };

  const renderPackageJourney = () => {
    const { enablePJPopup, variant, coveoSettings } = props.data;

    return window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      customClass: 'addOns-Overlay package-journey-overlay calendar-overlay-genral-admission',
      dataToAppend: (
        <PackageExperienceB2C
          data={props.data}
          packageDetails={{ ...activePackageInfo.current, ...activePackageDetails.current }}
          createProductStore={createProductStore}
          addToCartCallback={addToCartCallback}
          getDiscountedProducts={getDiscountedProducts}
          getCategoryProds={getCategoryProds}
          changeCategoryToString={changeCategoryToString}
          errorCallback={errorCallback}
          getErrorMap={getErrorMap}
          closeOverlay={closeOverlay}
          coveoTabData={coveoData}
          pjData={pjData.current}
          services={pjData.current.services}
          enablePJPopup={enablePJPopup}
          coveoSettings={coveoSettings}
          coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
          overlayErrorCallback={overlayErrorCallback}
          variant={
            (variant === 'v-product-list-filter' || variant === 'v-f1-multi-filter') && enablePJPopup
              ? 'v-overlay-selected'
              : ''
          }
        />
      ),
    });
  };

  /**
   * Get purchase journey Products
   * @param {Object} err error object
   */
  const invokePurchaseJourney = (prodId, productTitle) => {
    const { categoryType, enablePJPopup } = props.data;
    const datalayerItem = canUseDOM() && window.dataLayer && window.dataLayer[0];
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();

    if (!prodId || !pjData.current) {
      return;
    }

    setIsPackageJourney(false);
    enablePJPopup && moment.locale('en-gb');
    coveoDefaultParams.current = enablePJPopup
      ? {
          perPageResults: UICONFIG.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.current.coveoMappingList,
          serviceUrl: pjData.current.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.current.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};

    if (pjData.current.tabs && pjData.current.tabs.length) {
      pjData.current.tabs.forEach((tab) => (tabs.current[tab.type] = { key: tab.coveoKey, value: tab.coveoValue }));
    }

    activeProdId.current = prodId;
    const catgryType = getProductTabType(categoryType, coveoData, activeProdId);
    enablePJPopup &&
      createProductStore(catgryType && catgryType.toLowerCase()).then((response) => {
        responseObj.current = response;
        openAddToCartPopUp(prodId);
      });
    if (datalayerItem && tenantId === UICONFIG.ymcB2CTenant) {
      bookNowClickAnalytics(productTitle);
    }
  };

  const setPartnerSegmentData = (cartData, data) => {
    const localstoragemembership = getMembershipDetails('membershipData');
    const findMembership = getPartnerCodeData(data.miniCart?.memberDiscounts, '');
    const preappliedCouponCode = localstoragemembership && localstoragemembership.couponCode[0];
    const partnersegProductIds =
      findMembership && findMembership.products && findMembership.products.length > 0 ? findMembership.products : [];

    const applypartnerSegmentPromo =
      canUseDOM() && localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo)
        ? true
        : false;
    const shouldApplyPromocode = cartData.some(
      (item) => partnersegProductIds.includes(item.productId) && applypartnerSegmentPromo,
    );
    shouldApplyPromocode &&
      preappliedCouponCode &&
      setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo, true);
  };

  const addToCartCallback = (products, grouping, context, redirectToBookingPageCallback) => {
    const { mupValidityMap } = props.data;
    groupingNotRequired.current = grouping;
    // add required keys for cart and chekbasket
    products = enrichProductData(products, mupValidityMap, groupingNotRequired, isPackageJourney);
    const data = pjData.current;

    const perPromises = [];

    // extract the quntity from param and add it to respective product
    products.forEach((prod) => {
      const quantity = prod.quantity;
      let callPerformance = false;
      if (!prod.performanceId) {
        prod.performanceId = null; //for checkbasket
        callPerformance = prod.hasperformance === '1';
      }

      /* Reset performance id when hasperformance is 0 to fix price value */
      if (prod.hasperformance === '0') {
        prod.performanceId = null;
      }

      prod.guestDetails = prod.guestDetails || null; //for checkbasket
      prod.quantity = quantity;
      prod.currQuantity = quantity;
      prod.groupedQuantity = quantity;
      //adding null check for getPerformance service
      if (callPerformance && data.services.getPerformance) {
        const url = data.services.getPerformance.url.replace('{0}', prod.productId);
        perPromises.push(
          PerformanceService.getPerformanceData(
            url,
            prod.fromDate,
            prod.toDate,
            true,
            UICONFIG.loader.defaultPreLoaderTarget,
          ),
        );
      }
      return prod;
    });

    Promise.all(perPromises)
      .then((responses) => {
        let errorObj = getErrorMap('getPerformance', {}, true);
        responses.forEach((res, index) => {
          products[index].performanceId = getPerformanceId(res.data.performancelist.performance, true);
          if (!products[index].performanceId) {
            res.error = {
              code: '7001',
              text: 'product is not sellable',
            };
            throw res;
          }
        });
        // save the data to cart

        let cartMaxQty;
        let prodMaxQty;
        if (data.miniCart) {
          cartMaxQty = data.miniCart.maxCartQuantity;
          prodMaxQty = data.miniCart.maxQuantity;
        } else {
          cartMaxQty = data.cartMaxQty;
        }

        const remQty = cartMaxQty - cartData.cart.totalQuantity;
        updateCouponCodeData(cartData.cart, cartActions);
        cartActions.current
          .updateCart(productList.current || [], products, prodMaxQty, remQty)
          .then((res) => {
            productList.current = res.productList;
            errorObj = getErrorMap('updateCart', {}, true);
            GTMData.push('addToCart', { products: products });

            setCartData(cartActions.current.getCustomCart(productList.current || [], true));
            setErrObj(errorObj);
            setIsOverlayOpen(false);

            isLoggedInUser() && setPartnerSegmentData(products, data);

            window.PubSub.publish(UICONFIG.events.MINICARTUPDATED, true);
            activeProdId.current = null;
            if (isPackageJourney) {
              activePackageInfo.current = { ...activePackageInfo.current, pkgCode: null };
            }

            if (context && context.href) {
              window.location.href = context.href;
            }
            redirectToBookingPageCallback();
          })
          .catch((res) => {
            const err = res.error || res?.response?.error; //for different cutom responses
            errorCallback('updateCart', err);
            redirectToBookingPageCallback();
          });
      })
      .catch((res) => errorCallback('getPerformance', res.error));
  };

  const getDiscountedProducts = (products, days, discountMap, disableDiscount) => {
    discountMap = discountMap || pjData.current.discountMap;
    const baseProducts = [];
    const selectedProducts = [];
    const baseProductPrices = {};
    const baseDis = getDiscount(0, discountMap);
    const selectedDis = getDiscount(days, discountMap);

    products.forEach((prod) => {
      const prodCode = prod.pricing;
      prod.discount = {};
      prod.discount.actualPerc = 0;
      prod.discount.computed = 0;
      if (prodCode === baseDis.code) {
        prod.unitPrice = prod.gross || 0;
        baseProducts.push(prod);
        baseProductPrices[prod.classType] = prod.gross || 0;
      } else if (prodCode === selectedDis.code) {
        prod.discount.actualPerc = selectedDis.discountPer;
        selectedProducts.push(prod);
      }
    });
    if (baseDis.code === selectedDis.code || disableDiscount) {
      return baseProducts;
    } else {
      return selectedProducts.map((prod) => {
        prod.unitPrice = baseProductPrices[prod.classType];
        return prod;
      });
    }
  };

  const openAddToCartPopUp = (prodId) => {
    activeProdId.current = prodId;
    setIsOverlayOpen(true);
    setErrorOverlayObj({});
  };

  const overlayErrorCallback = (error, errorData) => {
    let errorObj = {};
    if (!isEmpty(error) && error.errorcode) {
      errorObj.errorcode = error.errorcode;
      if (errorData && errorData[error.errorcode]) {
        errorObj.text = errorData[error.errorcode];
      } else if (error && error.errordescription) {
        errorObj.text = error.errordescription;
      }
      setErrorOverlayObj(errorObj);
    }
  };

  const renderPJPopup = () => {
    const { enablePJPopup, variant } = props.data;
    const categoryType = getProductTabType(props.data.categoryType, coveoData, activeProdId);
    let currentTabData =
      pjData.current.tabs &&
      pjData.current.tabs.filter(
        (tab) => tab.coveoValue.toLowerCase() === (categoryType && categoryType.toLowerCase()),
      )[0];

    const category = { [currentTabData.coveoKey]: currentTabData.coveoValue };
    let product = responseObj.current.getFilteredProductsFromKeyVal(category);
    let allProducts = product.length && createMappedData(product, pjData.current.coveoMappingList);
    product = allProducts.filter((prod) => prod.productId === activeProdId.current)[0];
    let categoryProducts = allProducts.filter((prod) => prod.category && prod.category[0] === product.category[0]);
    let counterData = null;
    let dateSelector = null;
    let timeSlotSelector = null;
    let catgry = null;
    let productOverlaySelector = null;
    let category1;

    for (let tktType in currentTabData.controls) {
      let currentBaseProdOptions =
        currentTabData.controls[tktType].options &&
        currentTabData.controls[tktType].options.filter((opt) => {
          return opt.coveoValue === product.category.toString();
        })[0];

      if (currentBaseProdOptions) {
        catgry = currentBaseProdOptions;
        counterData = currentBaseProdOptions.visitorSelector;
        dateSelector = currentBaseProdOptions.dateSelector;
        timeSlotSelector = currentBaseProdOptions.timeSlotSelector;
        productOverlaySelector = currentBaseProdOptions.productOverlaySelector;
      }
      category1 = currentBaseProdOptions;
    }

    if (!product.hasOwnProperty('quantity')) {
      product.quantity =
        (counterData &&
          counterData.visitorSelector &&
          counterData.visitorSelector.options &&
          counterData.visitorSelector.options[0] &&
          counterData.visitorSelector.options[0].defaultQuantity) ||
        0;
    }

    // Reset Secondary products
    Object.keys(secProds).forEach(function(key) {
      delete secProds[key];
    });

    return [].concat(
      categoryProducts.map((product) => {
        const multiTicketSelector = category1.multiTicketSelector;
        const coveoKeyMap = multiTicketSelector ? multiTicketSelector.stringMapping : {};
        const category = product.category;

        let isCategoryMappingExist = false;
        if (coveoKeyMap) {
          for (const item in coveoKeyMap) {
            if (item === category[0]) {
              isCategoryMappingExist = true;
              break;
            }
          }
        }

        if (
          coveoKeyMap &&
          (category.indexOf(coveoKeyMap.drivingExperience) !== -1 ||
            category.indexOf(coveoKeyMap.passengerExperience) !== -1 ||
            isCategoryMappingExist)
        ) {
          updateSecProdArray(product, category[0], coveoKeyMap);
        }
        if (!product.hasOwnProperty('quantity')) {
          product.quantity = category1.visitorSelector.options[0].defaultQuantity;
        }

        return (
          <div>
            {!multiTicketSelector || product.experienceCatgory === coveoKeyMap.primary ? (
              <AddOnsTicketComponent
                data={currentTabData}
                cartData={pjData.current.miniCart}
                businessErrors={pjData.current.miniCart.businessErrors}
                ticket={product}
                dateSelector={dateSelector}
                visitorCounter={counterData}
                createMappedData={createMappedData}
                addToCartCallback={addToCartCallback}
                services={pjData.current.services}
                getDiscountedProducts={getDiscountedProducts}
                timeSlotSelector={timeSlotSelector}
                category={catgry}
                handleOnClick={() => {}}
                enablePJPopup={enablePJPopup}
                additionalProds={getCategoryProds(product.category, allProducts)}
                coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
                productOverlaySelector={productOverlaySelector}
                searchProductList={allProducts}
                overlayErrorCallback={overlayErrorCallback}
                variant={
                  (variant === 'v-product-list-filter' || variant === 'v-f1-multi-filter') && enablePJPopup
                    ? 'v-overlay-selected'
                    : ''
                }
              />
            ) : null}
          </div>
        );
      }),
    );
  };

  /**
   * Product data coveo call
   * @param {Object} err error object
   */
  const createProductStore = (tab, param) => {
    return new Promise((resolve, reject) => {
      if (!products.current[tab]) {
        const params = tabs.current[tab] ? [tabs.current[tab]] : [param];
        params.push({ key: pjData.current.coveoMappingList.disabledProduct, value: '0' });
        getB2CProductDetails({
          ...coveoDefaultParams.current,
          queryParams: params,
        })
          .then((res) => {
            res.results = changeCategoryToString(res.results, coveoDefaultParams);
            products.current[tab] = new ProductStore(res.results);
            setPurchaseWrapperErrors('');
            resolve(products.current[tab]);
          })
          .catch((res) => {
            errorCallback('getCoveoProducts', res.error);
            const errObj = getErrorMap(
              'getCoveoProducts',
              pjData.current.services.getCoveoProducts.errors,
              false,
              res.error,
            );
            reject(errObj);
          });
      } else {
        resolve(products.current[tab]);
      }
    });
  };

  const setStateOfMinicart = () => {
    cartActions.current
      .getCartOnPageLoad()
      .then((res) => {
        if (res.data && res.data.cart) {
          productList.current = res.data.cart.items || [];
          const isPackages = res.data.cart?.packages;
          if (isPackages?.length) {
            const unGroupPkgProd = cartActions.current.getUngroupedPkgPrd(isPackages);
            productList.current = [...productList.current, ...unGroupPkgProd];
          }
        }
      })
      .catch((res) => {
        productList.current = [];
        errorCallback('getCart', res.error);
      })
      // IE Edge `finally` block fix
      // https://github.com/quasarframework/quasar/issues/3427#issuecomment-468540385
      .catch(() => true)
      .then(() => {
        setCartData(cartActions.current.getCustomCart(productList.current || [], true));
        setErrObj(getErrorMap('getCart', {}, true, '', errObj));
      });
  };

  const updateActiveTab = (coveoData) => {
    const tabIndex = getTabIndexByHash(coveoData.tabList, props.data.variant);
    const tabTitle = coveoData?.tabList?.[tabIndex]?.title;
    if (tabTitle && !checkGA4Tenants([UICONFIG.tenants.yi])) {
      GTMDataOnClickTab({ name: tabTitle, elementText: tabTitle });
    }
    setCoveoData({ ...coveoData, activeTab: tabIndex !== -1 ? tabIndex : 0 });
    setErrorOverlayObj({});
    scrollTabMobileViewPort();
  };

  /**
   * groupByTabs  function crreates the tabList data.
   *              It creates the data for each tab with title and listing.
   * @param    {[Object]} data [object that has the refined response of coveo].
   * @return   {[Object]} tabList [data of each tab]
   */
  const groupByTabs = (data) => {
    const { allTabLabel, includeAllTab, includeTabs, tabsPriority } = props.data;
    const tabList = [];
    const defaultPriority = Object.keys(tabsTitle.current).length;
    // creating data for 'All' tabs
    if (includeAllTab) {
      for (const key in data.All) {
        if (!data.All.hasOwnProperty(key)) {
          continue;
        }
        const priority = tabsPriority.findIndex((element) => element.toLowerCase() === key.toLowerCase());
        const tabItem = {
          title: allTabLabel || key,
          priority: priority === -1 ? defaultPriority : priority,
          listing: data.All[key],
        };
        tabItem['type'] = 'All';

        tabList.push(tabItem);
      }
    }

    // creating data for each tabs separately
    if (includeTabs) {
      for (const tabKey in data.tabsData) {
        if (!data.tabsData.hasOwnProperty(tabKey)) {
          continue;
        }
        const priority = tabsPriority.findIndex((element) => element.toLowerCase() === tabKey.toLowerCase());
        const tabItem = {
          title: tabsTitle.current[tabKey],
          priority: priority === -1 ? defaultPriority : priority,
          listing: data.tabsData[tabKey],
        };
        const type =
          data.tabsData[tabKey][0] && data.tabsData[tabKey][0].categoryKey && data.tabsData[tabKey][0].categoryKey;
        tabItem['type'] = type;
        if (tabItem.title) {
          tabList.push(tabItem);
        }
      }
    }
    return tabList.sort((item, nextItem) => item.priority - nextItem.priority);
  };

  const {
    carousel,
    cssClass,
    description,
    enablePJPopup,
    filters,
    gradient,
    gridVariation,
    includeFilters,
    includeProductIds,
    isCarouselInsideCard,
    isListingCarouselOnAllView,
    isProductTypeEventQuery,
    isSimilarExpAuthoredDriven,
    noResultsTabs,
    noSearchLinks,
    resultText,
    showInfoIcon,
    subTitle,
    tileLabels,
    title,
    variant,
    infoAriaLabel,
    buyNowLabel,
    theme,
  } = props.data;

  // #region for multiSelect filter and sorting

  const getDateForCurrentMonth = () => {
    const endOfMonth = moment()
      .endOf(MultiFilterSettings.MONTH)
      .format(MultiFilterSettings.DATE_FORMAT);
    const currentDate3 = moment().format(MultiFilterSettings.DATE_FORMAT);

    return `${currentDate3}..${endOfMonth}`;
  };

  const getDateForNextMonth = () => {
    const nextMonthStartDate = moment()
      .add(1, MultiFilterSettings.MONTH)
      .startOf(MultiFilterSettings.MONTH)
      .format(MultiFilterSettings.DATE_FORMAT);
    const nextMonthEndtDate = moment()
      .add(1, MultiFilterSettings.MONTH)
      .endOf(MultiFilterSettings.MONTH)
      .format(MultiFilterSettings.DATE_FORMAT);
    return `${nextMonthStartDate}..${nextMonthEndtDate}`;
  };

  const multiFilterOption = (optoinData) => {
    let _optionContent = [];
    optoinData.fields.map((_fields) => {
      _fields.options.map((_options) => {
        const newobj = {
          value:
            _options.value === MultiFilterSettings.THIS_MONTH_VALUE
              ? getDateForCurrentMonth()
              : _options.value && _options.value === MultiFilterSettings.NEXT_MONTH_VALUE
              ? getDateForNextMonth()
              : _options.value,
          label: _options.text,
        };
        _optionContent.push(newobj);
      });
    });
    return _optionContent;
  };

  // Filter Option Rendering
  const Option = (props) => {
    return (
      <div className="filter-custom-multi-option form-group checkbox__wrapper">
        <div className="list-item">
          <components.Option {...props}>
            <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
          </components.Option>
        </div>
      </div>
    );
  };

  const multiSelectOnChange = (selectedVal, filterIndex) => {
    let multiFiltersWithIndex = multiSelectValue;
    if (multiFiltersWithIndex[filterIndex]) {
      // Remove existing selected values in order to handle unchecking
      multiFiltersWithIndex[filterIndex].filterValues = [];
    }

    selectedVal.map((_item) => {
      if (!multiFiltersWithIndex[filterIndex]) {
        multiFiltersWithIndex[filterIndex] = { filterValues: [_item.value] };
      } else {
        if (multiFiltersWithIndex[filterIndex].filterValues)
          multiFiltersWithIndex[filterIndex].filterValues = [
            ...new Set([...multiFiltersWithIndex[filterIndex].filterValues, _item.value]),
          ];
      }
    });

    setMultiSelectValue([...multiFiltersWithIndex]);
  };

  // Reset all values from Filter Dropdown
  let arrGetRef = [];
  const getRef = (data) => {
    return arrGetRef.push(data);
  };

  const handleClearAll = () => {
    for (let i = 0; i < arrGetRef.length; i++) {
      arrGetRef[i].clearValue();
    }
    setMultiSelectValue([]);
  };

  const moreFilterShow = () => {
    // Remove Hide Class
    const allFilterItems = document.querySelectorAll('.multi-filter-items .filter-custom-multi-select');
    for (let i = 0; i < allFilterItems.length; i++) {
      allFilterItems[i].classList.remove('hide');
    }
    // Add Hide Class in More Filter CTA
    var moreFilterCTA = document.querySelectorAll('.multi-filter-container-wrap .more-filter');
    for (let j = 0; j < moreFilterCTA.length; j++) {
      moreFilterCTA[j].className += ' hide';
    }
  };

  let showFilter = 3;
  const getFilterCount = () => {
    return (
      filters.length > showFilter && (
        <div className="filter-custom-multi-select more-filter" onClick={() => moreFilterShow()}>
          <span className="clip-title">{tileLabels.moreFilterBtnText}</span>
          {`: ${filters.length - showFilter}`}
        </div>
      )
    );
  };

  const handleMobileClick = () => {
    document.getElementById('s-and-f-container').classList.add('isMobile');
    document.querySelector('body').classList.add('isMobileFilters');
  };

  const handleMobileCloseClick = () => {
    document.getElementById('s-and-f-container').classList.remove('isMobile');
    document.querySelector('body').classList.remove('isMobileFilters');
  };

  const sortDropdown = (selectedItem) => {
    const getSortVal = props.data.sortingData.options.find((item) => item.value === selectedItem.value);

    setSortInitialValue(getSortVal.text);
    setSortFieldSelected(getSortVal);
  };

  // #endregion

  try {
    let localTotalCount = '';
    const isYasislandFilter =
      variant === 'v-yasisland-filter' || variant === UICONFIG.commonVariant.listOfMorePackagesWithFilter;
    const gridVariationTitle = isYasislandFilter
      ? includeFilters && filters.length
        ? `${gridVariation}-title-filter-on`
        : `${gridVariation}-title`
      : '';

    if (totalCount === 0) {
      localTotalCount = noResultsTabs;
    } else if (totalCount > 0) {
      localTotalCount = resultText.replace('{0}', totalCount);
    }
    let randNum = randomNumber(3);
    let displayTitle = !!title;
    if (isSimilarExpAuthoredDriven) {
      if (includeProductIds) {
        displayTitle = true;
      } else {
        displayTitle = false;
      }
    }

    const carouselData = {
      isCarouselInsideCard,
      isListingCarouselOnAllView,
      isProductTypeEventQuery,
      carouselSetting: carousel,
    };

    return (
      <div
        className={classNames(
          'c-tabs-filter-listing',
          `gradient${randNum}`,
          'component',
          variant,
          cssClass,
          gridVariationTitle,
        )}
      >
        <DynamicContent
          tagName="style"
          innerHtml={`
            .c-tabs-filter-listing.v-yasisland-filter.gradient${randNum}::before {
              background: ${gradient};
            }
          `}
        />
        <div className="w--content">
          {subTitle && <DynamicContent tagName="p" attrs={{ className: 'sub-title' }} innerHtml={subTitle} />}
          {displayTitle && <DynamicContent tagName="h2" attrs={{ className: 'title heading-2' }} innerHtml={title} />}
          {description && <DynamicContent tagName="div" attrs={{ className: 'description' }} innerHtml={description} />}
          {includeFilters &&
            variant !== 'v-yasisland-filter' &&
            variant !== UICONFIG.commonVariant.listOfMorePackagesWithFilter &&
            filters.length > 0 && (
              <ListFilter
                data={props.data}
                totalCount={localTotalCount}
                callBack={getCoveoData}
                filterVariant={variant}
                filterData={defaultFilterValue}
                setPackageListingSortSelection={setPackageListingSortSelection}
                filters={filters}
                getCoveoDatabyUrlParam={getCoveoDatabyUrlParam}
              />
            )}
          {includeFilters && variant === 'v-yasisland-filter' && filters.length > 0 && (
            <ListFilter
              data={props.data}
              totalCount={localTotalCount}
              callBack={getCoveoData}
              filterData={defaultFilterValue}
            />
          )}
          {includeFilters && variant === UICONFIG.commonVariant.listOfMorePackagesWithFilter && filters.length > 0 && (
            <>
              <DynamicContent
                tagName="h2"
                attrs={{ className: 'result-title' }}
                innerHtml={`${totalCount === null ? '0' : totalCount} ${resultText}`}
              />

              <div className="s-and-f-container" id="s-and-f-container">
                <div className="mobile-wrapper-btns" onClick={handleMobileClick}>
                  <div className="filter-dropdown__sort-wrapper">
                    <div className="filter-dropdown__sort-title">{sortInitialValue}</div>
                  </div>
                  <div className="filter-dropdown__filter-wrapper">
                    <div className="filter-dropdown__filter-title ">{`${tileLabels.filterLabel}: ${filters.length}`}</div>
                  </div>
                </div>

                <div className="s-and-f-container-mobile-wrap">
                  <div className="mobile-pop-title">{tileLabels.mobilePopUpTitle}</div>
                  <div className="mobile-close-menu" onClick={handleMobileCloseClick}>
                    <span className="sr-only">{tileLabels.mobileCloseText}</span>
                  </div>

                  <div className="mobile-result-btn" onClick={handleMobileCloseClick}>
                    <span>{tileLabels.mobileResultBtnTitle}</span>
                  </div>

                  <div className="sorting-section">
                    <h3 className="section-title">{tileLabels.sortLabel}</h3>
                    <div className="sorting-dropdown-wrapper">
                      <Dropdown
                        options={props.data.sortingData.options}
                        defaultValue={sortFieldSelected}
                        placeholder={sortFieldSelected}
                        onChange={sortDropdown}
                      />
                    </div>
                  </div>

                  <div className="multi-filter-section-right">
                    <h3 className="section-title">{tileLabels.filterLabel}</h3>
                    <div className="multi-filter-container">
                      <div className="multi-filter-container-wrap">
                        <div className="multi-filter-items">
                          {filters.map((_items, index) => (
                            <>
                              <Select
                                hideSelectedOptions={false}
                                options={multiFilterOption(_items)}
                                isMulti
                                className={`filter-custom-multi-select ${index > 2 ? 'hide' : ''}`}
                                ref={(ref) => getRef(ref)}
                                defaultMenuIsOpen={false}
                                allowSelectAll={false}
                                placeholder={_items.fields.map((fmap) => fmap.label)}
                                isSearchable={false}
                                onChange={(item) => multiSelectOnChange(item, index)}
                                components={{
                                  Option,
                                }}
                                classNamePrefix={'filter-custom'}
                              />
                            </>
                          ))}
                        </div>
                        {getFilterCount()}
                      </div>

                      <div className={`multi-filter-container-clearAll`} onClick={(e) => handleClearAll()}>
                        {tileLabels.clearAllBtnText}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {localTotalCount && totalCount === 0 && (
            <div className="no-result" aria-live="polite">
              <DynamicContent
                tagName="p"
                attrs={{ className: `total-count heading-4 total-count-no-result ` }}
                innerHtml={localTotalCount}
              />
              {variant === UICONFIG.commonVariant.listOfMorePackagesWithFilter ||
                variant === 'v-yasisland-filter' ||
                (variant === 'v-event-filter' && (
                  <div className="no-result-btn">
                    {noSearchLinks.map((each) => (
                      <div className={each.class}>
                        <AnchorLink link={each} />
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          )}
          {renderOverlayErrorMsg(errorOverlayObj)}
          <TabFeatureList
            data={coveoData}
            propData={props.data}
            tileLabels={tileLabels}
            showExtraField={
              variant === 'v-yasisland-filter' || variant === UICONFIG.commonVariant.listOfMorePackagesWithFilter
            }
            isUpdateDropdownProps={true}
            filterVariant={variant}
            showInfoIcon={showInfoIcon}
            enablePJPopup={enablePJPopup}
            invokePJCallback={invokePurchaseJourney}
            invokePackageJourney={invokePackagedJourney}
            carouselData={carouselData}
            index={props.index}
            infoAriaLabel={infoAriaLabel}
            disableLazyLoadImage={includeFilters}
            buyNowLabel={buyNowLabel}
            theme={theme}
            addToCartCallback={addToCartCallback}
            getDiscountedProducts={getDiscountedProducts}
            cartData={cartData}
            businessErrors={pjData.current.miniCart?.businessErrors}
          />
          {enablePJPopup && isOverlayOpen && (isPackageJourney ? renderPackageJourney() : renderPJPopup())}
        </div>
        {(getUserAgent() || !hasHeaderComponent()) && <Overlay />}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'TabsFilterListing', variant);
  }
};

TabsFilterListing.propTypes = {
  data: PropTypes.shape({
    allTabLabel: PropTypes.string,
    carousel: PropTypes.object,
    categoryType: PropTypes.string,
    contentType: PropTypes.string,
    coveoSettings: PropTypes.object,
    cssClass: PropTypes.string,
    currentItemId: PropTypes.string,
    currentLanguage: PropTypes.string,
    description: PropTypes.string,
    enablePJPopup: PropTypes.bool,
    filters: PropTypes.array,
    gradient: PropTypes.string,
    gridVariation: PropTypes.string,
    hideExpired: PropTypes.bool,
    hideFilterText: PropTypes.string,
    includeAllTab: PropTypes.bool,
    includeCategory: PropTypes.bool,
    includeFilters: PropTypes.bool,
    includeProductIds: PropTypes.string,
    includeTabs: PropTypes.bool,
    isCarouselInsideCard: PropTypes.bool,
    isDetailPageWithFilterListing: PropTypes.bool,
    isListingCarouselOnAllView: PropTypes.bool,
    isMobileOnly: PropTypes.bool,
    isProductTypeEventQuery: PropTypes.bool,
    isSimilarExpAuthoredDriven: PropTypes.bool,
    mupValidityMap: PropTypes.object,
    noResultsTabs: PropTypes.string,
    noSearchLinks: PropTypes.array,
    productEventType: PropTypes.string,
    productId: PropTypes.string,
    resultText: PropTypes.string,
    services: PropTypes.object,
    showFilterText: PropTypes.string,
    showInfoIcon: PropTypes.bool,
    subTitle: PropTypes.string,
    tabsPriority: PropTypes.array,
    tenantId: PropTypes.string,
    ticketBookingSourceId: PropTypes.string,
    tileLabels: PropTypes.object,
    title: PropTypes.string,
    variant: PropTypes.string,
  }),
  index: PropTypes.number,
};

export default withRouter(React.memo(TabsFilterListing));
