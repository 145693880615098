import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  canUseDOM,
  getSessionStorage,
  setSessionStorage,
  isTicketUpgradeJourney,
  checkParksTenants,
  getLocalStorageByKey,
} from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';

function BackLink({ data }) {
  if (canUseDOM()) {
    if (getSessionStorage('backtopage') === 'true') {
      setTimeout(() => {
        let selectedItem = document.querySelector(`body`);
        selectedItem && selectedItem.scrollIntoView(true);
        sessionStorage.removeItem('backtopage');
      }, 2000);
    }
  }
  const { text, link } = data;
  const hrefBackLink = () => {
    const isParks = checkParksTenants();
    if (isParks && isTicketUpgradeJourney()) {
      const upgradeUrlWithQS = canUseDOM() && getLocalStorageByKey(UIConfig.parksTicketUpgrade.upgradeUrlWithQS);
      window.location.href = upgradeUrlWithQS ? upgradeUrlWithQS : window.history.back();
    } else if (window.history.length > 2) {
      setSessionStorage('backtopage', 'true');
      window.history.back();
    } else if (link) {
      window.location.href = link;
    }
  };

  return (
    <div className="container backlinkWrapper">
      <div className="row">
        <div className="col-12">
          <div className="backlink">
            {text && canUseDOM() && (
              <>
                {link && !isTicketUpgradeJourney() ? (
                  <Text tag="a" href={link} field={{ value: text, editable: text }} />
                ) : (
                  <Text
                    tag="a"
                    href="javascript:;"
                    onClick={() => hrefBackLink()}
                    field={{ value: text, editable: text }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackLink;
