import React from 'react';
import PropTypes from 'prop-types';

import UIConfig from '../../../../common/UIConfig.js';
import { DynamicContent, RichText, SvgSprite } from '..';
import { logComponentRenderingError } from '../../../../common/logger';

import './error-overlay-component.scss';

const closeOverlay = () => {
  window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, { shouldOpen: false });
};

const ErrorOverlay = ({
  title,
  bodyCopy,
  primaryCTA,
  secondaryCTA,
  iconId = 'icn-exclaimation-mark',
  primaryCallback,
  secondaryCallback,
  htmlContent,
}) => {
  try {
    return (
      // just to avoid regression throughout the tenants we are making it separate.
      htmlContent ? (
        <div className="c-error-overlay-body">
          <div className="c-error-overlay--icon">
            <SvgSprite id={iconId} />
          </div>
          <RichText
            data={{
              bodyCopy: htmlContent,
            }}
          />
        </div>
      ) : (
        <div className="c-error-overlay-body">
          <div className="c-error-overlay--icon">
            <SvgSprite id={iconId} />
          </div>
          {title && <DynamicContent tagName="h2" attrs={{ className: 'heading-3' }} innerHtml={title} />}
          {bodyCopy && <DynamicContent tagName="p" attrs={{ className: 'body-copy-2' }} innerHtml={bodyCopy} />}
          {primaryCTA && (
            <div className="btn btn-primary">
              <DynamicContent
                tagName="button"
                attrs={{ onClick: primaryCallback || closeOverlay }}
                innerHtml={primaryCTA}
              />
            </div>
          )}
          {secondaryCTA && (
            <div className="btn btn-secondary">
              <DynamicContent
                tagName="button"
                attrs={{ onClick: secondaryCallback || closeOverlay }}
                innerHtml={secondaryCTA}
              />
            </div>
          )}
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ErrorOverlay');
  }
};

ErrorOverlay.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  ctaLabel: PropTypes.string,
  iconId: PropTypes.string,
};

ErrorOverlay.defaultProps = {
  iconId: 'icn-exclaimation-mark',
};

export default ErrorOverlay;
