import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DropDown from '../../drop-down';
import UIConfig from '../../../../common/UIConfig';
import LabelComponent from '../label-component';
import FieldErrorComponent from '../field-error';
import { getMainObject, resolvePath, isLoggedInUser } from '../../../../common/utility';
import { getEnvProxyUrl } from '../../../../common/forms-utility';
import { logComponentRenderingError, Logging } from '../../../../common/logger';
import { validateJSSField } from '../rules-validator';
import { FormServices } from '../../../../common/services';
import classNames from 'classnames';

const SelectWithText = (props) => {
  const selectList = useRef([]);
  const mainObj = getMainObject(),
    imageAssetUrl = resolvePath(mainObj, 'additionalProperty.flagUrl', '') || UIConfig.form.selectWithText.imageUrl,
    { tracker, field, errors } = props,
    valueFieldId = field.valueField.id,
    valueFieldName = field.valueField.name,
    initialValue = resolvePath(field, 'model.value', '');

  if (selectList.current && !selectList.current.length) {
    field.model.items.forEach((option) => {
      const optionText = resolvePath(option, 'text', '');
      const textArr = optionText.split('|');
      if (optionText && textArr.length > 1 && optionText.includes('|')) {
        selectList.current.push({
          img: option.image || imageAssetUrl + `/${textArr[3] ? textArr[3].toLowerCase().replace(' ', '_') : ''}.png`,
          value: option.value,
          text: textArr[2],
          description: textArr[3],
          code: textArr[1],
          selected: option.selected,
        });
      }
    });
  }

  const getInitialDropDownValue = () => {
    if (resolvePath(selectList, 'current', []).length) {
      return selectList.current.find((itm) => itm.selected) || selectList.current[0];
    }
  };

  const [dropDownSelectedItem, setDropDownSelectedItem] = useState(getInitialDropDownValue());
  const [inputFieldValue, setInputFieldValue] = useState(initialValue);
  const [errorMsg, setErrorMsg] = useState(errors);

  const getMyGeoLoc = () => {
    const geoLocationObj = getEnvProxyUrl();
    let url = UIConfig.geoLocation.httpsUrl;
    if (geoLocationObj.isAkamai === 'false') {
      url = geoLocationObj.siteCoreApiUrl + url;
    }

    FormServices.getGeoLocation({
      moduleName: 'DropDown',
      serviceUrl: url,
      preLoader: false,
    })
      .then((res) => {
        if (res.data) {
          const countryCode = res.data.Country || res.data.country;
          countryCode && setSelectedItem(countryCode);
        }
      })
      .catch((err) => {
        Logging(err, 'GEO Location API failed');
      });
  };

  useEffect(() => {
    if (!isLoggedInUser() && field.model.geoIPEnabled) {
      getMyGeoLoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  useEffect(() => {
    if (props.dropDownValue) {
      setSelectedItem(props.dropDownValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dropDownValue]);

  useEffect(() => {
    const { apiFieldValue } = props;
    if (apiFieldValue) {
      const valueArr = apiFieldValue.split('|');
      if (valueArr[0] || valueArr[1]) {
        const code = valueArr[0] || '';
        const phoneNum = valueArr[1].replace(code, '');
        let selectObj = {};
        if (code) {
          selectObj = selectList.current.find((item) => item.code === code) || dropDownSelectedItem;
        }
        setDropDownSelectedItem(selectObj);
        phoneNum && handleChange(phoneNum, selectObj.code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiFieldValue]);

  const setSelectedItem = (value) => {
    const selectObj = selectList.current.filter((item) => item.value === value)[0] || dropDownSelectedItem;
    setDropDownSelectedItem(selectObj);
    updateFormState(selectObj.code + inputFieldValue);
  };

  const updateFormState = (valueWithCode, valid = true, errorMessages = []) => {
    props.onChange(valueFieldName, valueWithCode, valid, errorMessages);
    tracker.onBlurField(field, valueWithCode, errorMessages);
  };

  const onSelect = (e, y) => {
    setSelectedItem(e.value);
  };

  const handleChange = (inputValue, code) => {
    let valid = true,
      errorMessages = [];
    const errorMessagesObj = validateJSSField(field, code + inputValue);
    const message = errorMessagesObj[valueFieldName];
    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }
    setInputFieldValue(inputValue);
    setErrorMsg(errorMessages);
    updateFormState(code + inputValue, valid, errorMessages);
  };

  const onInputBlur = (e) => {
    const userNumberVal = e.target.value || '';
    const inputValue = userNumberVal.charAt(0) === '0' ? userNumberVal.substring(1) : userNumberVal;
    handleChange(inputValue, dropDownSelectedItem.code);
  };

  try {
    const isErrorExist = errorMsg && errorMsg.length;
    const inputClasses = classNames('form-input body-copy-3', { error: isErrorExist });
    return (
      selectList &&
      selectList.current.length > 0 && (
        <div className={`form-element select-with-text active ${field.model.cssClass}`}>
          <LabelComponent field={field} />
          <div className={`form-dropdown`}>
            <DropDown
              callBackFunction={onSelect}
              listItems={selectList.current}
              defaultValue={dropDownSelectedItem.value}
              showSelectedValue="true"
              showSelectedImage={true}
              showItemCode={true}
              firstTimeLoad="true"
              isUpdateDropdownProps={true}
              lazyLoadListImage={true}
              tabIndex="0"
            />
          </div>
          <input
            disabled={!dropDownSelectedItem.code}
            className={inputClasses}
            name={valueFieldName}
            id={valueFieldId}
            onBlur={onInputBlur}
            defaultValue={inputFieldValue}
            type="tel"
            aria-labelledby={'error_' + valueFieldName}
            aria-invalid={isErrorExist}
            tabIndex="0"
          />
          <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CustomSelectWithText');
  }
};

SelectWithText.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    controlFunc: PropTypes.func,
    resize: PropTypes.bool,
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.string,
    placeholder: PropTypes.string,
  }),
};

export default SelectWithText;
