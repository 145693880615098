import { canUseDOM } from './utility';

class SojrenAnalytics {
  static imageTag = { targetDiv: '', src: '' };

  static bookingConfirmation = ({ products, price, orderId }) => {
    const pn = products.map((product) => product.productName)[0],
      hp = price,
      hcu = products[0].currency || 'AED',
      hconfno = orderId,
      et = 'ticket purchase';

    SojrenAnalytics.imageTag.targetDiv = 'sojern-pixel-confirmation';
    SojrenAnalytics.imageTag.src = `https://pixel.sojern.com/pixel/img/138867?p_v=1&f_v=v3_image&vid=hot&et=hc&pn=${pn}&hp=${hp}&hcu=${hcu}&hconfno=${hconfno}&et=${et}`;
  };

  static pageCategory = ({ title }) => {
    const titleTag = document.getElementsByTagName('title')[0] || {},
      pc = title || titleTag.innerText;

    SojrenAnalytics.imageTag.targetDiv = 'sojern-pixel';
    SojrenAnalytics.imageTag.src = `https://pixel.sojern.com/pixel/img/138866?p_v=1&f_v=v3_image&vid=hot&pc=${pc}`;
  };

  static set(method, data = {}) {
    SojrenAnalytics[method](data);
    if (canUseDOM()) {
      const div = document.getElementsByClassName(SojrenAnalytics.imageTag.targetDiv)[0];
      if (div) {
        div.innerHTML = `<img alt='Sojern Image Pixel' height='1' src='${encodeURI(
          SojrenAnalytics.imageTag.src,
        )}' width='1'>`;
      }
    }
  }
}

export default SojrenAnalytics;
