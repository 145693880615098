import { getCurrentLanguage } from '../../../../../common/utility';

export const CartModel = {
  cart: {
    items: [],
    currency: 'AED',
    tenantId: 'ALLB2C',
    userLang: getCurrentLanguage(),
    totalQuantity: '0',
    subTotal: {
      total: 0,
    },
    couponCode: '',
    totalDiscount: '0',
    netPrice: '0',
    grossPrice: '0',
    totalTax: '0',
    isCouponRequired: false,
    hasImplicitPromotion: false,
    reservationOwner: null,
    promotions: null,
    isTicketUpgradeCart: false,
  },
};
