/**
 * Desc: Popup menu Level 1
 */

import { getClosestByClass } from '../utility';
import { MenuItem } from './PopupMenuItemLinks';

var PopupMenu = function(domNode, controllerObj) {
  this.domNode = domNode;
  this.controller = controllerObj;
  this.menuitems = []; // See PopupMenu init method
  this.firstChars = []; // See PopupMenu init method
  this.hasFocus = false; // See MenuItem handleFocus, handleBlur
  this.firstItem = null; // See PopupMenu init method
  this.lastItem = null; // See PopupMenu init method
  this.hasPopupMenuOpen = false; // popup menu open
};

PopupMenu.prototype.init = function() {
  var menuItem, numItems, menuElement;

  this.domNode.addEventListener('blur', this.handleBlur.bind(this));
  // Traverse the element children of domNode: configure each with
  // menuitem role behavior and store reference in menuitems array.

  menuElement = this.domNode.querySelectorAll('a');
  for (let anchorTag of menuElement) {
    menuItem = new MenuItem(anchorTag, this);
    menuItem.init();
    this.menuitems.push(menuItem);
  }

  // Use populated menuitems array to initialize firstItem and lastItem.
  numItems = this.menuitems.length;
  if (numItems > 0) {
    this.firstItem = this.menuitems[0];
    this.lastItem = this.menuitems[numItems - 1];
  }
};

/** Event Handler */
PopupMenu.prototype.handleBlur = function() {
  this.menu.hasFocus = false;
  setTimeout(this.controller.close.bind(this.controller, false), 300);
};

/* MENU DISPLAY METHODS */

PopupMenu.prototype.open = function() {
  var closestElm = getClosestByClass(this.controller.domNode, 'has-submenu');
  if (closestElm) {
    closestElm.classList.add('open');
    this.hasPopupMenuOpen = true;
  }
  this.controller.setExpanded(true);
};

/** Close sub menu */
PopupMenu.prototype.close = function(force) {
  let hasFocus = this.hasFocus,
    menuItemLength = this.menuitems.length;
  this.hasPopupMenuOpen = false;

  for (var i = 0; i < menuItemLength; i++) {
    let mi = this.menuitems[i];
    if (mi.popupMenu) {
      hasFocus = hasFocus | mi.popupMenu.hasFocus;
    }
  }

  if (force || !hasFocus) {
    var closestElm = getClosestByClass(this.domNode, 'has-submenu');
    if (closestElm) {
      closestElm.classList.remove('open');
      this.controller.setExpanded(false);
    }
  }
};

PopupMenu.prototype.setFocusToFirstItem = function() {
  this.firstItem.domNode.focus();
};

PopupMenu.prototype.setFocusToNextItem = function(currentItem) {
  var index;

  if (currentItem === this.lastItem) {
    this.firstItem.domNode.focus();
  } else {
    index = this.menuitems.indexOf(currentItem);
    this.menuitems[index + 1].domNode.focus();
  }
};

PopupMenu.prototype.setFocusToPreviouseItem = function(currentItem) {
  let index;

  if (currentItem === this.firstItem) {
    this.lastItem.domNode.focus();
  } else {
    index = this.menuitems.indexOf(currentItem);
    this.menuitems[index - 1].domNode.focus();
  }
};

/* Focus on main menu whne ESC */

PopupMenu.prototype.setFocusToController = function(command, flag) {
  if (typeof command !== 'string') {
    command = '';
  }

  if (command === '') {
    if (this.controller && this.controller.domNode) {
      this.controller.domNode.focus();
    }
    return;
  }
};

PopupMenu.prototype.setFocusOnTab = function(event, currentItem) {
  if (currentItem === this.lastItem) {
    this.firstItem.domNode.focus();
    event.preventDefault();
  }
};

export { PopupMenu };
