import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import PassComponent from '../../../presentation/b2c-purchase-journey/annual-pass/pass-component';
import TierComponent from '../../../presentation/b2c-purchase-journey/annual-pass/tier-component';
import VisitorsCountSelector from '../visitors-count-selector/visitors-count-selector-component';
import DateFlexibleSelector from '../date-flexible-selector/date-flexible-selector-component';
import UIConfig from '../../../../common/UIConfig';
import {
  toTwoDecimalPlaces,
  getClosestByClass,
  momentTimezone,
  focusHandlerOnAccordion,
  detectViewPort,
  getClientUtcOffset,
} from '../../../../common/utility';
import moment from 'moment';
import AddOnsTicketComponent from '../add-ons/add-ons-ticket-component';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';

export default class SpecialTicketComponent extends Component {
  constructor(props) {
    super(props);
    this.tktVal = props.tktVal;
    this.specialTicketObj = props.data.controls.specialTicket.options;
    this.paramObj = [];
    this.specialTicketObj.forEach((option) => {
      option.ticketSelector.ticket.coveoValue === this.tktVal && this.paramObj.push(option);
      if (!this.additionalCounterCoveoKey && option.additionalCounter) {
        this.additionalCounterCoveoKey = option.additionalCounter.coveoKey;
      }
    });
    const selectedTicket = this.paramObj.length
      ? this.tktVal
      : this.specialTicketObj[0].ticketSelector.ticket.coveoValue;

    const defaultSelectedTicketObj = this.paramObj.length ? this.paramObj[0] : this.specialTicketObj[0],
      showTierSelector =
        defaultSelectedTicketObj.tierSelector && defaultSelectedTicketObj.tierSelector.tiers.length > 0,
      selectedTier = showTierSelector ? defaultSelectedTicketObj.tierSelector.tiers[0].coveoValue : null,
      showDateSelector =
        defaultSelectedTicketObj.dateSelector &&
        !defaultSelectedTicketObj.dateSelector.hidden &&
        defaultSelectedTicketObj.dateSelector.options.length > 0;

    const disableAddToCart =
      Object.values(this.getTicketCount(defaultSelectedTicketObj).primaryCounterCount).reduce(
        (sum, value) => (sum += value),
        0,
      ) > 0
        ? true
        : false;

    this.dateSelectorFlexible =
      defaultSelectedTicketObj.dateSelector &&
      defaultSelectedTicketObj.dateSelector.options.filter((option) => option.name === 'flexible');
    this.selectedTicket = defaultSelectedTicketObj;
    this.ticketSelectorCoveoKey = defaultSelectedTicketObj.ticketSelector.ticket.coveoKey;
    this.visitorSelectorCoveoKey = defaultSelectedTicketObj.visitorSelector.coveoKey;
    this.finalSelectedProducts = [];

    const selectedTicketData = {
      selectedTicket,
      selectedTier,
      ticketCount: this.getTicketCount(defaultSelectedTicketObj),
    };
    const date = defaultSelectedTicketObj.dateSelector ? this.setDateObject(defaultSelectedTicketObj) : {};

    this.state = {
      showPassesCounter: true,
      counterComponentState: 'default',
      passComponentState: this.tktVal ? 'default' : 'noSelect',
      tierComponentState: 'default',
      calenderComponentState: 'default',
      totalCalculatedAmount: '',
      date,
      showTierSelector,
      showDateSelector,
      disableAddToCart,
      selectedTicketData,
      performanceProducts: [],
      selectedTimeslot: '',
      selectedPerformanceId: '',
    };
  }

  setDateObject(selectedTicketObj) {
    const defaultSelectedDate = momentTimezone(selectedTicketObj.dateSelector.defaultDate, getClientUtcOffset());
    return {
      fromDate: selectedTicketObj.dateSelector.hidden ? defaultSelectedDate : '',
      isFlexible:
        selectedTicketObj.dateSelector.hidden && selectedTicketObj.dateSelector.options[0].name === 'flexible',
      rangeInMonths:
        selectedTicketObj.dateSelector.options[0].name === 'flexible'
          ? selectedTicketObj.dateSelector.options[0].ticketValidity
          : null,
      accessPolicykey: selectedTicketObj.dateSelector.options[0].coveoKey,
      accessPolicyValue: selectedTicketObj.dateSelector.options[0].coveoValue,
    };
  }

  getTicketCount = (defaultSelectedTicketObj) => {
    const ticketCount = {
      primaryCounterCount: {},
      additionalCounterCount: {},
    };

    defaultSelectedTicketObj.visitorSelector &&
      defaultSelectedTicketObj.visitorSelector.options.length > 0 &&
      defaultSelectedTicketObj.visitorSelector.options.forEach((option) => {
        const optionCategory = option.coveoValue ? option.coveoValue : option.name;
        ticketCount.primaryCounterCount[optionCategory] = option.defaultQuantity;
      });

    defaultSelectedTicketObj.additionalCounter &&
      defaultSelectedTicketObj.additionalCounter.options.length > 0 &&
      defaultSelectedTicketObj.additionalCounter.options.forEach((option) => {
        const optionCategory = option.coveoValue ? option.coveoValue : option.name;
        ticketCount.additionalCounterCount[optionCategory] = option.defaultQuantity;
      });
    return ticketCount;
  };

  componentDidMount() {
    this.props.createProductStore(this.props.data.type).then((response) => {
      this.responseObj = response;
      this.updateState();
    });
  }

  groupCounterProducts = (products) => {
    const grouppedProducts = {};
    products.forEach((product) => {
      if (product.category) {
        if (!grouppedProducts.hasOwnProperty(product.category)) grouppedProducts[product.category] = [];

        grouppedProducts[product.category].push(product);
      }
    });
    return grouppedProducts;
  };
  updateState = (elem, isAddonTicket) => {
    let counterSum = 0;
    for (const counter in this.state.selectedTicketData.ticketCount) {
      if (this.state.selectedTicketData.ticketCount[counter])
        counterSum += Object.values(this.state.selectedTicketData.ticketCount[counter]).reduce(
          (sum, value) => (sum += value),
          0,
        );
    }
    const disableButton =
      counterSum > 0 &&
      counterSum < parseInt(this.selectedTicket.visitorSelector.maxCount, 10) + 1 &&
      this.getComponentState();

    if (disableButton) {
      let totalCalculatedAmount = 0;
      this.finalSelectedProducts = [];
      if (this.responseObj) totalCalculatedAmount = this.addProductAndCalculateTotal(totalCalculatedAmount);
      this.setState(
        {
          totalCalculatedAmount: toTwoDecimalPlaces(totalCalculatedAmount),
        },
        () => {
          totalCalculatedAmount !== '' &&
            this.setState({
              disableAddToCart: !this.checkIfDateSet(),
            });
        },
      );
    } else {
      this.setState({ disableAddToCart: !disableButton, totalCalculatedAmount: '' });
    }
    if (elem && !isAddonTicket) {
      setTimeout(() => {
        const parentClass = 'c-pass',
          parent = getClosestByClass(elem, parentClass);
        parent && parent.scrollIntoView({ behaviour: 'instant', block: 'center' });
      }, 0);
    } else if (this.tktVal) {
      setTimeout(() => {
        const passSelector = document.querySelector('.pass-component > .c-pass.selected');
        const ticketSelector = document.querySelector('.c-fast-ticket-wrapper.selected');
        const parent = passSelector || ticketSelector || '';
        if (detectViewPort() !== 'mobile') {
          parent && parent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        } else {
          parent && parent.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      }, 0);
      setTimeout(() => {
        if (detectViewPort() === 'mobile') {
          window.scrollBy(0, -10);
        }
      }, 500);
    }
  };

  addProductAndCalculateTotal = (totalCalculatedAmount) => {
    for (const category in this.state.selectedTicketData.ticketCount.primaryCounterCount) {
      if (this.state.selectedTicketData.ticketCount.primaryCounterCount[category] > 0) {
        let selectedProduct = this.responseObj.getFilteredProductsFromKeyVal(this.getQueryParamsObj(category));
        if (selectedProduct.length > 0) {
          selectedProduct = this.props.createMappedData(selectedProduct)[0];
          this.finalSelectedProducts.push(this.updateProductForCoveo(selectedProduct, category));
          totalCalculatedAmount +=
            parseFloat(selectedProduct.gross, 10) *
            this.state.selectedTicketData.ticketCount.primaryCounterCount[category];
        } else {
          totalCalculatedAmount = '';
          this.setState({ disableAddToCart: true, totalCalculatedAmount });
        }
      }
    }
    if (this.state.selectedTicketData.ticketCount.additionalCounterCount && this.selectedTicket.additionalCounter) {
      const count = Object.values(this.state.selectedTicketData.ticketCount.additionalCounterCount)[0];
      const additionalProduct = this.getAdditionalProduct();
      if (count > 0 && additionalProduct && additionalProduct.gross) {
        this.finalSelectedProducts.push(additionalProduct);
        totalCalculatedAmount += parseFloat(additionalProduct.gross, 10) * count;
      }
    }
    return totalCalculatedAmount;
  };

  getComponentState = () => {
    return (
      this.state.counterComponentState !== 'noSelect' &&
      this.state.tierComponentState !== 'noSelect' &&
      this.state.passComponentState !== 'noSelect'
    );
  };

  checkIfDateSet = () => {
    return !this.state.showDateSelector || moment.isMoment(this.state.date.fromDate);
  };

  getQueryParamsObj = (category) => {
    const selectedTicket = {
      [this.ticketSelectorCoveoKey]: this.state.selectedTicketData.selectedTicket,
      [this.visitorSelectorCoveoKey]: category,
    };
    if (this.state.date.accessPolicyValue) {
      selectedTicket[this.state.date.accessPolicykey] = this.state.date.accessPolicyValue;
    }
    if (
      this.state.selectedTicketData.selectedTier &&
      this.state.selectedTicketData.selectedTier !== null &&
      this.state.selectedTicketData.selectedTier !== ''
    ) {
      this.tierSelectorCoveoKey = this.selectedTicket.tierSelector.tiers[0].coveoKey;
      return { ...selectedTicket, [this.tierSelectorCoveoKey]: this.state.selectedTicketData.selectedTier };
    }
    return selectedTicket;
  };

  getAdditionalProduct = () => {
    const { selectedTicketData, date, selectedTimeslot, selectedPerformanceId } = this.state;
    const { createMappedData } = this.props;
    let queryObj = {
      [this.selectedTicket.additionalCounter.coveoKey]: this.selectedTicket.additionalCounter.options[0].coveoValue,
    };
    if (
      this.tierSelectorCoveoKey &&
      selectedTicketData.selectedTier &&
      selectedTicketData.selectedTier !== null &&
      selectedTicketData.selectedTier !== ''
    )
      queryObj = { ...queryObj, [this.tierSelectorCoveoKey]: selectedTicketData.selectedTier };
    let selectedProduct = this.responseObj.getFilteredProductsFromKeyVal(queryObj);
    if (selectedProduct.length > 0) {
      selectedProduct = createMappedData(selectedProduct)[0];
      selectedProduct.isFlexible = date.isFlexible;
      selectedProduct.fromDate = date.fromDate ? date.fromDate.format(UIConfig.calendar.dateFormat) : '';
      /* Required for wbw */
      selectedProduct.timeSlot = selectedTimeslot;
      selectedProduct.performanceId = selectedPerformanceId;

      selectedProduct.quantity = Object.values(selectedTicketData.ticketCount.additionalCounterCount)[0];
      selectedProduct.otherReason = this.selectedTicket.additionalCounter.options[0].maxCount;
      selectedProduct.groupType = UIConfig.b2c.purchaseJourney.additionalProductCode;
      selectedProduct.category = selectedProduct.category
        ? selectedProduct.category
        : this.finalSelectedProducts[0].category;
      selectedProduct.rangeInMonths = date.isFlexible ? date.rangeInMonths : '';
      selectedProduct.minCount = this.selectedTicket.additionalCounter.options[0].minimumQuantity;
      selectedProduct.maxCount = this.selectedTicket.additionalCounter.options[0].maxCount;
      selectedProduct.isIncreaseDecreaseEnabled = this.selectedTicket.additionalCounter.isIncreaseDecreaseEnabled;
      return selectedProduct;
    }
  };

  updateProductForCoveo = (product, category) => {
    const { selectedTimeslot, date, selectedTicketData, selectedPerformanceId } = this.state;
    const { minimumQuantity, maximumQuantity, increaseDecreaseBy } = this.selectedTicket.visitorSelector.options[0];
    /* Required for wbw */
    product.timeSlot = selectedTimeslot;
    product.performanceId = selectedPerformanceId;
    product.fromDate = date.fromDate ? date.fromDate.format(UIConfig.calendar.dateFormat) : '';

    product.quantity = selectedTicketData.ticketCount.primaryCounterCount[category];
    product.rangeInMonths = date.isFlexible ? date.rangeInMonths : '';
    product.isFlexible = date.isFlexible;
    product.otherReason = JSON.stringify({
      pplPerTicket: this.selectedTicket.visitorSelector.pplPerTicket || 1,
    });
    product.minCount = minimumQuantity || 0;
    product.maxCount = maximumQuantity || 0;
    product.increaseDecreaseBy = increaseDecreaseBy || 1;
    return product;
  };

  handleTicketSelection = (selectedTicket, target) => {
    this.selectedTicket = this.props.data.controls.specialTicket.options.filter(
      (option) => option.ticketSelector.ticket.coveoValue === selectedTicket,
    )[0];
    const showTierSelector =
        this.selectedTicket.tierSelector && this.selectedTicket.tierSelector.tiers.length > 0 ? true : false,
      showDateSelector =
        this.selectedTicket.dateSelector &&
        !this.selectedTicket.dateSelector.hidden &&
        this.selectedTicket.dateSelector.options.length > 0
          ? true
          : false,
      selectedTier = showTierSelector ? this.selectedTicket.tierSelector.tiers[0].coveoValue : '';

    const date = this.selectedTicket.dateSelector ? this.setDateObject(this.selectedTicket) : null;

    this.setState(
      {
        showPassesCounter: false,
        showDateSelector: false,
        showTierSelector: false,
        date,
      },
      () => {
        this.setState(
          {
            passComponentState: 'default',
            selectedTicketData: { ...this.state.selectedTicketData, selectedTicket, selectedTier },
            tierComponentState: showTierSelector ? 'noSelect' : 'default',
            counterComponentState: 'noSelect',
            calenderComponentState: showDateSelector ? 'noSelect' : 'default',
            showPassesCounter: true,
            showTierSelector,
            showDateSelector,
          },
          () => {
            this.updateState(target, this.selectedTicket.ticketSelector.isAddonTicket);
          },
        );
      },
    );
  };

  handleTierSelection = (selectedTier) => {
    this.setState(
      {
        tierComponentState: 'default',
        selectedTicketData: { ...this.state.selectedTicketData, selectedTier },
      },
      () => {
        this.updateState();
      },
    );
  };

  addToCart = (products, noGroup) => {
    if (products && products.length) {
      this.finalSelectedProducts = products;
    }
    if (this.props.addToCartCallback) {
      const selectedPassEle = document.querySelector('.pass.selected');
      this.props.addToCartCallback(this.finalSelectedProducts, noGroup === true);
      this.setState(
        {
          counterComponentState: 'noSelect',
          passComponentState: 'noSelect',
          tierComponentState: 'noSelect',
          calenderComponentState: 'noSelect',
          disableAddToCart: true,
          totalCalculatedAmount: '',
        },
        () => {
          selectedPassEle && selectedPassEle.parentElement.focus();
        },
      );
    }
  };

  setVisitorsCount = (data) => {
    this.setState(
      {
        counterComponentState: 'default',
        selectedTicketData: { ...this.state.selectedTicketData, ticketCount: data.spinnerCount },
      },
      () => {
        this.updateState();
      },
    );
  };

  getProductByTicket = (ticket) => {
    if (!ticket) {
      return;
    }
    //const multiTicketSelector = ticket.multiTicketSelector,
    //keyMap = multiTicketSelector ? multiTicketSelector.stringMapping : {},
    const category = { [ticket.ticketSelector.ticket.coveoKey]: ticket.ticketSelector.ticket.coveoValue };
    //getDisProd = this.props.getDiscountedProducts,
    let product = this.responseObj.getFilteredProductsFromKeyVal(category);
    product = product.length && this.props.createMappedData(product)[0];
    // if(keyMap && !this.aryExist &&
    //     (category.indexOf(keyMap.drivingExperience) !== -1 || category.indexOf(keyMap.passengerExperience) !== -1)) {
    //     this.updateSecProdArray(product,category[0],keyMap);
    // }
    if (product && !product.hasOwnProperty('quantity')) {
      product.quantity = ticket.visitorSelector ? ticket.visitorSelector.options[0].defaultQuantity : 0;
    }
    return product;
  };

  renderAddonTicket = (ticket, index) => {
    const product = this.getProductByTicket(ticket);
    return (
      <div>
        <AddOnsTicketComponent
          data={this.props.data}
          cartData={this.props.cartData}
          businessErrors={this.props.businessErrors}
          key={index}
          recommendationUrl={this.props.recommendationUrl}
          ticket={product}
          dateSelector={ticket.dateSelector}
          visitorCounter={ticket.visitorSelector}
          createMappedData={this.props.createMappedData}
          addToCartCallback={this.addToCart}
          services={this.props.services}
          getDiscountedProducts={this.props.getDiscountedProducts}
          timeSlotSelector={ticket.timeSlotSelector}
          category={ticket}
          selectedTicket={this.state.selectedTicketData.selectedTicket}
          handleOnClick={this.handleTicketSelection}
          ticketState={this.state.passComponentState}
        />
      </div>
    );
  };

  /**
   *  Accessibility Handler for accordion
   */

  handleKeyDownAccordion = (event, coveoValue) => {
    let keyCode = event.keyCode.toString();

    // trigger the function to open the tab panel
    if (keyCode.match(/13|32/)) {
      // ENTER|SPACE
      this.handleTicketSelection(coveoValue, event.target);
    }
    // move focus on the accordion heading using arrow key
    else if (keyCode.match(/38|40/)) {
      // UP|DOWN arrow
      focusHandlerOnAccordion(event);
    }
  };

  displayTickets = (tickets) => {
    let tierSelection, calendar, validDay;

    return tickets.map((tickt, index) => {
      tierSelection = tickt.tierSelector;
      calendar = tickt.dateSelector;
      validDay = this.isValidOnPresent(tickt)
        ? parseInt(tickt.dateSelector.options[0].calendarSettings.validOn, 10)
        : null;
      const isThisSelectedTicket =
        tickt.ticketSelector.ticket.coveoValue === this.state.selectedTicketData.selectedTicket;
      return tickt.ticketSelector.isAddonTicket ? (
        this.renderAddonTicket(tickt, index)
      ) : (
        <div className="pass-component" tabIndex="-1">
          <PassComponent
            key={index}
            data={tickt.ticketSelector}
            handleOnClick={this.handleTicketSelection}
            selectedTicket={this.state.selectedTicketData.selectedTicket}
            componentState={this.state.passComponentState}
            handelKeyDown={this.handleKeyDownAccordion}
            accordionID={`accordion_header_${index}`}
            ariaControls={`accordion_body_${index}`}
            ariaExpanded={isThisSelectedTicket}
            ariaLabel={tickt.ariaLabel ? tickt.ariaLabel : this.props.ariaLabelCheckbox}
          />
          {isThisSelectedTicket && this.state.passComponentState === 'default' && (
            <div className="option-selector" id={`accordion_body_${index}`} aria-expanded={isThisSelectedTicket}>
              <div className={`calendar-passcounter-wrapper${this.state.showDateSelector ? '' : ' no-dateselector'}`}>
                {this.renderCalendar(tickt, calendar, validDay)}
                {this.renderPassCounter(tickt)}
              </div>
              {this.state.showTierSelector && (
                <div className="tier-container-wrapper">
                  {tierSelection && tierSelection.tiers.length > 0 && this.displayTierSelection(tierSelection.tiers)}
                </div>
              )}
              {this.responseObj && (
                <div className="ticket-total-amount-wrapper">
                  <div className="body-3"> {this.props.data.priceLabel} </div>
                  <div className={`total-amoumt ${this.state.totalCalculatedAmount === '' ? 'disabled' : ''}`}>
                    <h3 className="totalText">{this.props.data.total}</h3>
                    <div className="amount">
                      <p className="currency">
                        {this.props.data.currency}&nbsp;
                        {this.state.totalCalculatedAmount}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className={`btn-primary c-special-ticket-add-cart ${this.state.disableAddToCart ? 'disabled' : ''}`}>
                <button
                  className="add-to-cart"
                  onClick={this.addToCart}
                  disabled={this.state.disableAddToCart ? true : false}
                >
                  {this.props.data.addToCart}
                </button>
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  displayTicketsCounter = (selectedTicketData) => {
    if (selectedTicketData && selectedTicketData.visitorSelector) {
      const counterData = selectedTicketData.additionalCounter
        ? {
            primaryCounter: selectedTicketData.visitorSelector,
            additionalCounter: selectedTicketData.additionalCounter,
          }
        : { primaryCounter: selectedTicketData.visitorSelector };
      return (
        <div className="special-ticket visitor-counter">
          <VisitorsCountSelector
            ticketData={selectedTicketData.ticketSelector}
            data={counterData}
            setVisitorData={this.setVisitorsCount}
            componentState={this.state.counterComponentState}
            name={this.props.data.name}
          />
        </div>
      );
    }
    return null;
  };

  displayTierSelection = (tiers) => {
    return tiers.map((tier, index) => {
      return (
        <TierComponent
          key={index}
          data={tier}
          handleOnClick={this.handleTierSelection}
          selectedTier={this.state.selectedTicketData.selectedTier}
          componentState={this.state.tierComponentState}
          ariaLabel={this.props.ariaLabelCheckbox}
        />
      );
    });
  };

  displayDateFlexibleSelector = (ticket, calendar, validDay) => {
    const product = this.getProductByTicket(ticket);
    return (
      <DateFlexibleSelector
        data={calendar}
        parent={this.props.data.type}
        ticket={product}
        setDateData={this.setDateData}
        componentState={this.state.calenderComponentState}
        unChecked={true}
        filterDate={validDay !== null ? (date) => date.day() === validDay : null}
        services={this.props.services}
        timeSlotSelector={ticket.timeSlotSelector}
      />
    );
  };

  setDateData = (data) => {
    const date = {
      fromDate: data.selectedField === 'flexible' ? moment() : data.selectedDate,
      isFlexible: data.selectedField === 'flexible',
      rangeInMonths: data.selectedField === 'flexible' ? data.rangeInMonths : null,
      accessPolicykey: data.accessPolicy.key,
      accessPolicyValue: data.accessPolicy.value,
    };
    this.setState(
      {
        calenderComponentState: 'default',
        date: {
          ...this.state.date,
          ...date,
        },
        selectedTimeslot: data.timeSlot,
        ...(data.timeSlot ? { selectedPerformanceId: data.performanceId } : {}), // Set performance id When timeslot is selected
      },
      () => {
        this.updateState();
      },
    );
  };

  isValidOnPresent = (selectedTicket) => {
    return (
      selectedTicket.dateSelector &&
      !selectedTicket.dateSelector.hidden &&
      selectedTicket.dateSelector.options.length > 0 &&
      selectedTicket.dateSelector.options[0].calendarSettings.validOn
    );
  };

  renderCalendar = (ticket, calendar, validDay) =>
    calendar && this.state.showDateSelector && this.displayDateFlexibleSelector(ticket, calendar, validDay);

  renderPassCounter = (selectedTicket) => this.state.showPassesCounter && this.displayTicketsCounter(selectedTicket);

  renderTickets = (tickets) => tickets.length > 0 && <div>{this.responseObj && this.displayTickets(tickets)}</div>;

  render() {
    const data = this.props.data;

    return (
      <div>
        <div className="component c-special-ticket">
          <div className="w--content">
            <div className="pass-container-wrapper">{this.renderTickets(data.controls.specialTicket.options)}</div>
          </div>
          {this.props.data.vatInfo && (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'vat-info body-2' }}
              innerHtml={this.props.data.vatInfo}
            />
          )}
        </div>
      </div>
    );
  }
}

SpecialTicketComponent.propTypes = {
  data: PropTypes.shape({
    controls: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

SpecialTicketComponent.defaultProps = {
  ariaLabelCheckbox: 'Press enter to select ticket',
};
