// import classNames from 'classnames';
import React, { Component } from 'react';
// import { Logging, } from "../../../../common/logger";
import UIConfig from '../../../../common/UIConfig';
import { KeyCodes } from '../../../../common/constants';
import PaymentCheckboxes from '../base/payment-checkbox/payment-checkbox';
import Image from '../../../presentation/base/image/image-component';
import { guestFormError, shouldProceedToPay, cartCall } from '../../../../common/payment-action/actions';
import {
  redirectTo,
  setLocalStorage,
  removePointsPayStorage,
  isLoggedInUser,
  getRecipientDetails,
  mycartError,
  checkTenant,
  canUseDOM,
  checkParksTenants,
  isMatchTenant,
  getLocalStorageByKey,
} from '../../../../common/utility';
import { Logging } from '../../../../common/logger';
import { logComponentRenderingError } from '../../../../common/logger';
import { scrollToServerError, isThereExtraErrors, consentRequest, checkAnnualPassError } from '../utils';
import GTMData from '../../b2c-purchase-journey/gtm-data';
import ApiWrapper from '../../../../common/api-wrapper';
/**
 * functionality to make payment through PointsPay
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */

/**
 * PointsPay Class ( which extends the React.Component) contains the
 * functionality to make payment through PointsPay
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */
export default class PointsPay extends Component {
  constructor(props) {
    super(props);
    this.pointsPayBtnRef = null;
    this.state = {
      guestHasError: false,
    };
  }

  componentDidMount() {
    if (!isLoggedInUser()) {
      window.PubSub.subscribe('guestFormHasError', this.checkGuestFormError);
    } else {
      window.PubSub.subscribe('recipientdetailsHasError', this.checkGuestFormError);
    }
  }

  componentWillUnmount() {
    removePointsPayStorage();
  }

  /**
   *  non loggedin case
   *  check guest form error and update the state of guest form
   */

  checkGuestFormError = (msg, data) => {
    const isErrorExist =
      Object.keys(data).length > 0 && data.hasOwnProperty('isRecipientError')
        ? data.isRecipientError
        : !isLoggedInUser()
        ? guestFormError(data, this.props.isEnabled)
        : false;

    this.setState({
      guestHasError: isErrorExist,
    });
  };

  /**
   *  blocks payment if conditions not met
   *  conditions : guest form not filled, t&c not checked
   */

  canProceedToPay = () => {
    return shouldProceedToPay({
      checkboxEnabled: this.props.isEnabled,
      tncErrorFunc: this.props.showTnCError,
      guestFormErrorFunc: this.props.guestUserHasErrors,
      guestErrorOnChangeState: this.state.guestHasError,
      cartData: this.props.cartData,
    });
  };

  setProductsCategoryObj = () => {
    const { normal, promotional } = this.props.pointsPayConfig;
    const cartData = this.props.cartData;
    const totalGrossPrice = cartData.grossPrice;
    const promotionProductsGross = cartData.items
      .filter((item) => item.ItemPromotionList && item.ItemPromotionList.length > 0)
      .reduce((sum, item) => {
        return sum + item.price.gross;
      }, 0);

    //  YA promotional(discount) event based on isPromotionalEvent
    const promotionalEventProductGross = cartData.items
      .filter((item) => item.isPromotionalEvent)
      .reduce((sum, item) => {
        return sum + item.price.gross;
      }, 0);
    const paymentInfo = {
      cmsData: {
        paymentOptions: {
          tncType: this.props.paymentOptions.tncType,
          tncCondition: this.props.paymentOptions.tncCondition,
        },
      },
    };
    localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(paymentInfo));

    if (promotionalEventProductGross) {
      setLocalStorage(
        UIConfig.paymentOption.pointsPay,
        JSON.stringify([
          { code: promotional, amount: promotionalEventProductGross },
          { code: normal, amount: totalGrossPrice - promotionalEventProductGross },
        ]),
      );
    } else {
      setLocalStorage(
        UIConfig.paymentOption.pointsPay,
        JSON.stringify([
          { code: promotional, amount: promotionProductsGross },
          { code: normal, amount: totalGrossPrice - promotionProductsGross },
        ]),
      );
    }
  };

  submitDataPointsPayOnEnter = (e) => {
    if (e.which === KeyCodes.Enter) {
      // 13 is key code for enter key
      this.submitDataPointsPay(e);
    }
  };
  /**
   *  Redirect to PointsPay page if create  order is success
   */

  submitDataPointsPay = (e) => {
    const { cartData } = this.props;
    let hasAnnualPassError = false;
    if (checkAnnualPassError(cartData, this.submitDataPointsPay)) {
      hasAnnualPassError = true;
    }
    if (isMatchTenant(UIConfig.tenants.ya)) {
      GTMData.push(UIConfig.ga4Constants.ADD_PAYMENT_INFO, {
        cartData,
        paymentType: UIConfig.paymentOption.pointsPay,
      });
    }
    // MarketingConsentUpdateAPI call here
    const newsletterCheckbox = canUseDOM() && document.getElementById('paymentNewsletter')?.checked;
    if (newsletterCheckbox && isLoggedInUser()) {
      const { MarketingConsentUpdateAPI } = this.props.services;
      consentRequest(MarketingConsentUpdateAPI?.url);
    }
    window.PubSub.unsubscribe('CreateOrderSuccess');
    // Creates category object which we have to pass in Cart API
    this.setProductsCategoryObj();
    if (!this.canProceedToPay() || hasAnnualPassError) {
      e.preventDefault();
      e.stopPropagation();
      scrollToServerError(e);
      return false;
    } else if (this.props.isEnabled && isLoggedInUser()) {
      if (isThereExtraErrors(e)) return false;
      const updateCartURL = this.props.services.updateCart.url;
      const recipientDetails = getRecipientDetails();
      const cartData = {
        ...this.props.cartData,
        personalisedGiftObj: recipientDetails,
      };
      cartCall(updateCartURL, { cart: cartData }, this.props.isRenewAnnualPass, UIConfig.paymentOption.pointsPay)
        .then((res) => {
          this.props.setRedirectPaymentData();
        })
        .catch((res) => {
          this.props.isErrorOccured('updateCart', res.error);
        });
    }
    if (isThereExtraErrors(e)) return false;
    const isVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
    if (isVoucher) {
      window.PubSub.publish('formSubmitVoucher', true);
    }
    if (checkParksTenants()) {
      GTMData.push('addPaymentInfo', {
        cartData: this.props.cartData,
        paymentType: this.props.currentOption,
      });
    }

    window.PubSub.subscribe('CreateOrderSuccess', (msg, data) => {
      let cmsDataLocal = localStorage.getItem(UIConfig.localStoreKeys.payment.cmsData);
      let cmsData = (cmsDataLocal && JSON.parse(cmsDataLocal)) || {};
      cmsData.orderData = {
        resCode: data.resCode,
        pointsPayResponse: data.pointsPayResponse,
      };
      if (this.props.pointsPayConfig && this.props.pointsPayConfig.accessToken) {
        cmsData.accessToken = this.props.pointsPayConfig.accessToken;
        cmsData.pointsPayTypeMethod = this.props.currentOption;
      }

      localStorage.setItem(UIConfig.localStoreKeys.payment.cmsData, JSON.stringify(cmsData));
      if (data.isCreateOrderSuccess && data.pointsPayResponse) {
        const { paymentLink } = data.pointsPayResponse;
        Logging(data, this.props.currentOption, true, 'PointsPay redirect success');
        paymentLink && redirectTo(paymentLink);
      } else {
        mycartError();
        Logging(data, this.props.currentOption, true, 'PointsPay redirect error');
      }
    });
  };

  render() {
    const {
      paymentOptions,
      openOverlay,
      onTncChangeHandler,
      showCheckBox,
      hideNewsletterOption,
      disableBookButton,
      hasTnCError,
      tabIndex,
      isEnabled,
      pointsPayConfig,
      onNewsLetterChangeHandler,
      onParkConsentChangeHandler,
      newsLetterEnabled,
      parkConsentEnabled,
      cartData,
    } = this.props;
    try {
      return (
        <div className="c-form points-pay-wrapper checkbox-wrapper-step2">
          {checkTenant(UIConfig.iamMapping.ymc) && (
            <PaymentCheckboxes
              paymentOptions={paymentOptions}
              openOverlay={openOverlay}
              onTncChangeHandler={onTncChangeHandler}
              onNewsLetterChangeHandler={onNewsLetterChangeHandler}
              showNewsLetterCheckBoxOnly={true}
              key="point-pay-news-letter-condition"
              stateData={{
                showCheckBox,
                hideNewsletterOption,
                disableBookButton,
                hasTnCError,
                tabIndex,
                isTncChecked: isEnabled,
                bindCheckBoxError: true,
                newsLetterEnabled: newsLetterEnabled,
              }}
            />
          )}
          <PaymentCheckboxes
            paymentOptions={paymentOptions}
            openOverlay={openOverlay}
            onTncChangeHandler={onTncChangeHandler}
            onNewsLetterChangeHandler={onNewsLetterChangeHandler}
            onParkConsentChangeHandler={onParkConsentChangeHandler}
            cartData={cartData}
            showParkConsentCheckbox={true}
            key="point-pay-terms-condition"
            stateData={{
              showCheckBox,
              hideNewsletterOption,
              disableBookButton,
              hasTnCError,
              tabIndex,
              isTncChecked: isEnabled,
              bindCheckBoxError: true,
              newsLetterEnabled: newsLetterEnabled,
              parkConsentEnabled: parkConsentEnabled,
            }}
          />
          {this.props.emailConfirmationText}
          <div className={this.state.guestHasError ? 'points-pay-btn c-payment-btn-disabled' : 'points-pay-btn'}>
            <span onKeyPress={this.submitDataPointsPayOnEnter} onClick={this.submitDataPointsPay} tabIndex={tabIndex}>
              <Image image={pointsPayConfig.cardImage} disableLazyLoad />
            </span>
          </div>
          {!checkTenant(UIConfig.iamMapping.ymc) && (
            <PaymentCheckboxes
              paymentOptions={paymentOptions}
              openOverlay={openOverlay}
              onTncChangeHandler={onTncChangeHandler}
              onNewsLetterChangeHandler={onNewsLetterChangeHandler}
              showNewsLetterCheckBoxOnly={true}
              key="point-pay-news-letter-condition"
              stateData={{
                showCheckBox,
                hideNewsletterOption,
                disableBookButton,
                hasTnCError,
                tabIndex,
                isTncChecked: isEnabled,
                bindCheckBoxError: true,
                newsLetterEnabled: newsLetterEnabled,
              }}
            />
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, UIConfig.paymentOption.PointsPay);
    }
  }
}
