/**
 * Desc: Popup Menu Item Links
 */

var MenuItem = function(domNode, menuObj) {
  this.domNode = domNode;
  this.menu = menuObj;
  this.keyCode = Object.freeze({
    TAB: 9,
    ESC: 27,
    UP: 38,
    DOWN: 40,
  });
};

MenuItem.prototype.init = function() {
  this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
  this.domNode.addEventListener('blur', this.handleBlur.bind(this));
  this.domNode.addEventListener('focus', this.handleFocus.bind(this));
};

MenuItem.prototype.handleKeydown = function(event) {
  let flag = false;

  switch (event.keyCode) {
    case this.keyCode.ESC:
      this.menu.setFocusToController();
      this.menu.close(true);
      flag = true;
      break;

    case this.keyCode.DOWN:
      this.menu.setFocusToNextItem(this);
      flag = true;
      break;
    case this.keyCode.UP:
      this.menu.setFocusToPreviouseItem(this);
      flag = true;
      break;

    default:
      break;
  }
  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

MenuItem.prototype.handleFocus = function() {
  this.menu.hasFocus = true;
};

MenuItem.prototype.handleBlur = function(event) {
  this.menu.hasFocus = false;
  setTimeout(this.menu.close.bind(this.menu, false), 300);
};

export { MenuItem };
