import { KeyCode, isKeyboardNavigation, keyCodeObject } from '../utility';

const isHidden = (el) => {
  const style = window.getComputedStyle(el);
  return style.display === 'none' || style.visibility === 'hidden' || el.offsetParent === null;
};

const isRadio = (el) => el.type === 'radio';

const getFocussableElements = (element) => {
  const focusableElsArray = Array.from(element.querySelectorAll("a[href],button,input,select,textarea,[tabindex='0']"));

  return focusableElsArray.filter((el, ix) => !(isHidden(el) || el.disabled || (isRadio(el) && !el.checked)));
};

export const createFocusLoop = (element, fnCloseOverlay) => {
  const focusableEls = getFocussableElements(element);
  const focusableElsLen = focusableEls.length;

  if (focusableElsLen === 0) return null;

  let firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableElsLen - 1];
  if (isKeyboardNavigation()) {
    firstFocusableEl.focus(); // First element focus inside overlay
  } else {
    element.focus(); // Overlay focus
  }

  const focusOnElement = (e, el) => {
    el.focus();
    e.preventDefault();
  };

  const keydown = (e) => {
    const kCode = e.keyCode || e.which;
    const { activeElement } = document;

    if (
      kCode === keyCodeObject.ENTER &&
      (activeElement.classList.contains('increase-btn') || activeElement.classList.contains('decerease-btn'))
    ) {
      setTimeout(() => {
        if (activeElement.disabled && activeElement === firstFocusableEl) {
          firstFocusableEl = getFocussableElements(element)[0];
          focusOnElement(e, firstFocusableEl);
        } else {
          firstFocusableEl = getFocussableElements(element)[0];
        }
      }, 10);
    }

    if (e.key === 'Tab' || kCode === KeyCode.TAB) {
      if ((isRadio(firstFocusableEl) && !firstFocusableEl.checked) || firstFocusableEl.tabIndex === -1) {
        firstFocusableEl = getFocussableElements(element)[0];
      }

      if (e.shiftKey) {
        /* shift + tab */
        if (activeElement === firstFocusableEl) {
          if (activeElement.classList.contains('react-datepicker-ignore-onclickoutside')) {
            activeElement.addEventListener(
              'blur',
              function _handleBlur() {
                focusOnElement(e, lastFocusableEl);
                activeElement.removeEventListener('blur', _handleBlur, true);
              },
              true,
            );
          } else {
            focusOnElement(e, lastFocusableEl);
          }
        }
      } else {
        /* tab */
        activeElement === lastFocusableEl && focusOnElement(e, firstFocusableEl);
      }
    } else if (e.key === 'Escape' || kCode === KeyCode.ESC) {
      fnCloseOverlay(e);
    }
  };
  return keydown;
};
