import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AnchorLink, DynamicContent, Image } from '../base';
import Video from '../video';
import { getMainObject, detectViewPort, resolvePath, isMatchTenant, checkTenant } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { logComponentRenderingError } from '../../../common/logger';
import { GLOBAL_VARS } from '../../../common/global-vars';

import './hero-banner-component.scss';
import QuickLinks from '../quick-links';

/**
 * CarouselPreviewHero  component contain title, description and CTA link on hero image
 */
const HeroBanner = (props) => {
  let ctaButton = '';
  let titleDescription = '';
  let shortTitle = '';

  const keyDownHandler = (e) => {
    let activeTile = document.querySelector('.card-carous-list-trans-2x [tabindex]');
    if (e.keyCode === 9 && activeTile && !e.shiftKey) {
      activeTile.focus();
      e.preventDefault();
    }
  };

  try {
    /**
     * on getting cta text make markup for cta button and store in ctaButton variable
     */
    if (props.cta) {
      ctaButton = (
        <div
          className={classNames('btn-primary', {
            btn_primary_on_image: props.variant === 'hero-panel-with-card-secondary',
          })}
        >
          <AnchorLink
            link={{ ...props.cta, ariaLabel: `${props.cta.label} ${props.title.text}` }}
            onKeyDown={(e) => keyDownHandler(e)}
          />
        </div>
      );
    }

    /**
     * on getting title description text make markup for title description  and store in titleDescription variable
     */
    if (props.titleDescription) {
      const isYasIsland = resolvePath(GLOBAL_VARS, 'pageDetails.tenantName', '') === UIConfig.iamMapping.yasisland;
      const isHeroPanel = props.parentComponent && props.parentComponent === 'HeroPanel';
      const isYasIslandCustomization = props.title.heading !== 'h1' && isYasIsland && isHeroPanel;

      if (isYasIslandCustomization) {
        titleDescription = (
          <DynamicContent tagName="h1" innerHtml={props.titleDescription} attrs={{ className: 'hero-banner-desc' }} />
        );
      } else {
        titleDescription = (
          <DynamicContent tagName="div" innerHtml={props.titleDescription} attrs={{ className: 'hero-banner-desc' }} />
        );
      }
    }

    if (props.shortTitle) {
      shortTitle = (
        <DynamicContent
          tagName={props.shortTitleHeading || 'h2'}
          innerHtml={props.shortTitle}
          attrs={{ className: 'hero-banner-shortTitle' }}
        />
      );
    }

    const bannerStyle = props.bgColor ? { backgroundColor: props.bgColor, background: props.bgColor } : null;
    const mainObj = getMainObject();
    const contrastValue = mainObj && mainObj.applyTextShadow;

    if (contrastValue) {
      setTimeout(() => {
        if (document.querySelector('.card-carousel-component-holder')) {
          document.querySelector('.card-carousel-component-holder').classList.add('shadow');
        }
      }, 0);
    }
    return (
      <div className="hero-banner-container" style={bannerStyle}>
        {props.heroVideo ? (
          <div className="hero-video-wrapper">
            <Video
              active={props.isActive || true}
              data={props.heroVideo}
              videoData={props.heroVideoData}
              imageInfo={props.heroVideoImage}
              activeCardId={props.activeCardId}
            />
          </div>
        ) : (
          <div
            className={`hero-image ${contrastValue ? 'image-contrast' : ''} ${
              props?.isSoldOut && checkTenant(UIConfig.iamMapping.ymc) ? 'hero-image-soldout-overlay' : ''
            } `}
          >
            {contrastValue && (
              <DynamicContent
                tagName="style"
                innerHtml={`
                .c-carousel-with-preview-component .carousel-with-preview-holder .hero-detail-container .hero-banner-title {
                  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
                }
              `}
              />
            )}
            {!!(props?.isSoldOut && isMatchTenant(UIConfig.tenants.ymc)) && (
              <div className="hero-image-soldout-overlay-label">{props?.soldOutLabel}</div>
            )}
            {props.heroImage && <Image image={props.heroImage} disableLazyLoad={true} />}
          </div>
        )}
        {!props.isMobile && <QuickLinks quickLinks={props.quickLinks} />}
        <div
          className="hero-detail-container w--content"
          style={{
            maxWidth: props.textWidth ? props.textWidth + '%' : '',
            width: props.textWidth ? props.textWidth + '%' : '',
          }}
        >
          {(shortTitle || props.title.text) && (
            <div className="contents">
              {shortTitle}
              <div className="title">
                {props.title && (
                  <DynamicContent
                    tagName={props.title.heading || 'h1'}
                    innerHtml={props.title.text || ''}
                    attrs={{ className: 'hero-banner-title' }}
                  />
                )}
              </div>
              {titleDescription}
            </div>
          )}
          {ctaButton}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HeroBanner');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
HeroBanner.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string,
    heading: PropTypes.string,
  }),
  titleDescription: PropTypes.string,
  ctaText: PropTypes.string,
  ctaHref: PropTypes.string,
  ctaTarget: PropTypes.string,
  heroImage: PropTypes.shape({
    desktopImage: PropTypes.shape({
      html: PropTypes.string,
      src: PropTypes.string.isRequired,
    }).isRequired,
    mobileImage: PropTypes.shape({
      html: PropTypes.string,
      src: PropTypes.string.isRequired,
    }).isRequired,
    tabletImage: PropTypes.shape({
      html: PropTypes.string,
      src: PropTypes.string.isRequired,
    }).isRequired,
    imageAltText: PropTypes.string.isRequired,
  }).isRequired,
  variant: PropTypes.string,
};

export default HeroBanner;
