import { jssCommonPlaceholders } from './constants';

const convertRoute = (route) => {
  const routePlaceholderKeys = Object.keys(route?.placeholders ?? {});

  jssCommonPlaceholders.forEach((item) => {
    if (!routePlaceholderKeys.includes(item)) route.placeholders[item] = [];
  });
  return route;
};
export { convertRoute };
