import moment from 'moment';

import UIConfig from '../../../../common/UIConfig';

export const timeFormat = (fromDate, performance) => {
  if (!fromDate || !performance) {
    return;
  }

  const openTime = moment
      .utc(fromDate + ' ' + performance.openTime)
      .locale('en')
      .format(UIConfig.calendar.timeFormat),
    closeTime = moment
      .utc(fromDate + ' ' + performance.closeTime)
      .locale('en')
      .format(UIConfig.calendar.timeFormat);
  return openTime + ' - ' + closeTime;
};
