import React from 'react';
import './bookingnotfound-mb.scss';

const BookingNotFound = ({ data }) => {
  return (
    <div className="bookingnotfound-managebooking-frame">
      <p className="warning-sorry-we-can">
        <span className="text-wrapper">
          {data?.errors?.MBW}:
          <br />
        </span>
        <span className="warning-description">{data?.errors['0101']}</span>
      </p>
    </div>
  );
};

export default BookingNotFound;
