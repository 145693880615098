import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { TrackerContext } from '@uniformdev/tracking-react';

import {
  addSecurityAttrsToAnchor,
  canUseDOM,
  resolvePath,
  iOSDevice,
  detectMobile,
  isSafariBrowser,
  checkTenant,
  isMatchTenant,
  checkGA4Tenants,
} from '../../../common/utility';
import GoalAnalytics from '../../../common/goal-analytics';
import { logComponentRenderingError, Logging } from '../../../common/logger';
import { socialIconsAnalytics } from '../../../common/analytics-events';
import UIConfig from '../../../common/UIConfig';
import { AnchorLink, DynamicContent, Image, RichText, SvgSprite } from '../../presentation/base';
import FooterColumn from './footer-column';
import { footerClickAnalytics } from '../../../common/analytics-events';
import GTMData from '../b2c-purchase-journey/gtm-data';

const getCurrentTenant = () => {
  try {
    return resolvePath(JSON.parse(localStorage.getItem('mainObj')), 'tenantID', '').toLowerCase();
  } catch (err) {
    return '';
  }
};
const currentTenant = getCurrentTenant();
const currentTenantDOM = canUseDOM() && currentTenant;

const getCopyRightText = (data) => {
  try {
    let copyRightText = resolvePath(data, 'copyRightText', '');
    const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
    copyRightText = data.copyRightYear
      ? copyRightText.replace(/##copyRightYear##/g, data.copyRightYear)
      : data.copyRightText;

    copyRightText = data.legalCTA
      ? copyRightText.replace(/##legalLinkText##/g, data.legalCTA.html)
      : data.copyRightText;

    if (
      resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C ||
      resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.SWADB2C
    ) {
      copyRightText = copyRightText.replace('&#169;', '&#9400;');
    }
    return copyRightText;
  } catch (err) {
    return '';
  }
};

const handleClick = (event, data) => {
  if (isMatchTenant(UIConfig.tenants.ya)) {
    GTMData.push(UIConfig.ga4Constants.SOCIAL_LINKS, {
      platform_name: data,
    });
  }
  socialIconsAnalytics(data, window.dataLayer && window.dataLayer[0].pageName);
};

const googleAnalyticHandleClickFooter = (event, data, sectionName) => {
  if (checkGA4Tenants()) {
    GTMData.push(UIConfig.ga4Constants.CLICK_FOOTER, { data, sectionName });
  } else {
    footerClickAnalytics(event, data, sectionName);
  }
};

const callGoalAnalytics = (goal, tContext) => {
  if (resolvePath(goal, 'goalId', '') && resolvePath(goal, 'services.setGoal.pageUrl', '')) {
    const goalAnalytics = new GoalAnalytics(goal, [], tContext, 'Footer Goal');
    goalAnalytics.sendGoal();
  }

  return true;
};

const getDeviceBasedIcons = (data = {}) => {
  const { appLink = [] } = data;
  const isMobile = detectMobile();
  const isiOS = iOSDevice();
  const isSafari = isSafariBrowser();

  if (isSafari || isiOS) {
    return appLink.slice(0, 1);
  }

  if (isMobile && !isiOS) {
    return appLink.slice(1);
  }

  return appLink;
};

//renderNewsLetter not for seaworld
const renderNewsLetter = (data) => {
  try {
    const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
    return (
      <section className="footer-container-head w--content">
        <RichText data={{ ...data.newsLetter, attrs: { className: 'footer-container-head--rte body-copy-2' } }} />
        {data.newsLetter.cta && (
          <div className="footer-container-head--sign-up-button btn btn-primary">
            <AnchorLink
              link={{ ...data.newsLetter.cta }}
              onClick={
                resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C
                  ? (e) => googleAnalyticHandleClickFooter(e, data.phoneNumber, 'footer-container-head--sign-up-button')
                  : ''
              }
            />
          </div>
        )}
      </section>
    );
  } catch (err) {
    return <></>;
  }
};

const renderFooterBody = (data, tContext, footerDropdownOpen, setFooterDropdownOpen) => {
  const toggleState = () => {
    setFooterDropdownOpen(!footerDropdownOpen);
  };
  const isYasArena = data.variant === UIConfig.b2c.footerVariants.yaFooter;
  const currentTenant = getCurrentTenant();
  try {
    return (
      <div className="footer-container-body w--content ">
        <ul className={'footer-container-body-links'}>
          {data.linkCategories &&
            data.linkCategories.map((column, i) => (
              <FooterColumn
                data={data}
                key={i}
                genId={i}
                category={column}
                arrowAltText={data.footerArrowMobAriaLabel || 'Footer Arrow'}
                // This prop is passed to differentiate columns for Yas Island Revamp
                variant={
                  currentTenant === UIConfig.YIB2C || currentTenant === UIConfig.SWADB2C ? currentTenant : 'other'
                }
              />
            ))}
          {/* Extra markup for contact us details for Seaworld */}
          {data.contactUsDetails && currentTenant === UIConfig.SWADB2C && (
            <li class="footer-container-body-links-column">
              <DynamicContent tagName="label" innerHtml={data.contactUsDetails.title} />
              <span
                className={footerDropdownOpen ? 'drop-active dropdown-arrow ' : 'dropdown-arrow'}
                onClick={toggleState}
              ></span>
              <ul>
                {data.contactUsDetails.fields.map((field) => {
                  return (
                    <li className={field.className}>
                      <DynamicContent tagName="p" innerHtml={field.title} />
                      <DynamicContent
                        tagName="a"
                        attrs={{
                          href: field.navigateLink.href,
                        }}
                        innerHtml={field.value}
                      />
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
        </ul>
        {/* extra markup check for yasIsland and seaworld*/}
        {currentTenant === UIConfig.YIB2C || currentTenant === UIConfig.SWADB2C ? (
          <section
            className={
              (currentTenant === UIConfig.YIB2C && 'yasisland-appdata-social-links') ||
              (currentTenant === UIConfig.SWADB2C && 'seaworld-appdata-social-links')
            }
          >
            {data.socialMediaItems && renderSocialLinks(data)}
            {renderAppData(data, tContext)}
            {data.chatServices && renderChatWithUs(data.chatServices)}
            <SecondaryLogo
              data={data}
              isYasArena={isYasArena}
              currentTenant={currentTenant}
              injectSecondaryLogo={currentTenant === UIConfig.YIB2C}
            />
          </section>
        ) : (
          <>
            {data.variant === 'v-footer-yasisland' && renderAppData(data, tContext)}
            {data.socialMediaItems && data.variant === 'v-footer-yasisland' && renderSocialLinks(data)}
          </>
        )}
      </div>
    );
  } catch (err) {
    return <></>;
  }
};

const canRendesLogos = (data, isYasArena, currentTenant, isSecondary = false) =>
  (![UIConfig.swadVariant.footerVariant, isSecondary ? '' : UIConfig.commonVariant.footerVariant].includes(
    data.variant,
  ) &&
    !isYasArena) ||
  currentTenant === UIConfig.tenants.ppad;

const PrimaryLogo = ({ data, isYasArena, currentTenant, miniFooter = false }) => {
  const primeLogo = miniFooter ? data?.siteLogo : data?.primaryLogo;

  if (!canRendesLogos(data, isYasArena, currentTenant) || !primeLogo?.image?.html) return <></>;
  return (
    <div className="primary-logo">
      <LazyLoad offset={100}>
        {primeLogo?.cta && (
          <AnchorLink
            link={{
              href: primeLogo?.cta?.href,
              label: primeLogo?.image?.html,
              target: primeLogo?.cta?.target,
            }}
            onClick={
              currentTenant === UIConfig.YIB2C
                ? (e) => googleAnalyticHandleClickFooter(e, primeLogo?.image, data?.ourPartners?.bodyCopy)
                : ''
            }
          />
        )}
      </LazyLoad>
    </div>
  );
};

const SecondaryLogo = ({ data, isYasArena, currentTenant, injectSecondaryLogo = false }) => {
  if (
    !canRendesLogos(data, isYasArena, currentTenant, injectSecondaryLogo) ||
    !(data.secondaryLogo && data.secondaryLogo?.image?.html)
  )
    return <></>;

  return (
    <div className="secondary-logo">
      {data.secondaryLogo && data.secondaryLogo.cta && (
        <AnchorLink
          link={{
            ariaLabel: data.primaryLogo.cta.label,
            href: data.secondaryLogo.cta.href,
            label: data.secondaryLogo.image && data.secondaryLogo.image.html,
            target: data.secondaryLogo.cta.target,
          }}
          onClick={
            currentTenant === UIConfig.YIB2C
              ? (e) => googleAnalyticHandleClickFooter(e, data.primaryLogo.image, data.ourPartners.bodyCopy)
              : ''
          }
        />
      )}
    </div>
  );
};
const renderLogo = (data, tContext) => {
  const isYasArena = data.variant === UIConfig.b2c.footerVariants.yaFooter;
  const currentTenantId = getCurrentTenant();
  try {
    return (
      <div className="footer-container-body-logo-parent">
        {isYasArena && data.phoneNumber && (
          <div className="footer-contact-us">
            <Text
              tag="div"
              className="contact-label"
              field={{ value: data.contactUsLabel, editable: data.contactUsLabel }}
            />
            <Text
              tag="a"
              href={`tel:${data.phoneNumber}`}
              className="contact-number"
              field={{ value: data.phoneNumber, editable: data.phoneNumber }}
              onClick={
                currentTenantId === UIConfig.YIB2C
                  ? (e) => googleAnalyticHandleClickFooter(e, data.phoneNumber, data.ourPartners.bodyCopy)
                  : ''
              }
            />
          </div>
        )}
        <section className={`footer-container-body-logos w--content clearfix`}>
          <PrimaryLogo data={data} isYasArena={isYasArena} currentTenant={currentTenantId} />
          <SecondaryLogo data={data} isYasArena={isYasArena} currentTenant={currentTenantId} />
          {currentTenantId === UIConfig.YIB2C || currentTenantId === UIConfig.SWADB2C
            ? renderPartnerLogo(data)
            : renderAppData(data, tContext)}
        </section>
      </div>
    );
  } catch (err) {
    return <></>;
  }
};

const renderPartnerLogo = (data) => {
  try {
    return (
      data.ourPartners &&
      data.ourPartners.appLink.length > 0 && (
        <section className="partner-logo">
          <DynamicContent
            tagName="div"
            innerHtml={data.ourPartners.bodyCopy}
            attrs={{
              className: 'body-copy-5 partner-logo-text',
            }}
          />
          {data.ourPartners.appLink.map((link, index) => {
            return (
              index < 4 && (
                <div className="partner-logo-link" key={index}>
                  {link.cta && (
                    <AnchorLink
                      onClick={
                        currentTenantDOM === UIConfig.YIB2C
                          ? (e) => googleAnalyticHandleClickFooter(e, link.image, data.ourPartners.bodyCopy)
                          : ''
                      }
                      link={{
                        href: link.cta.href,
                        label: link.image && link.image.html,
                        target: link.cta.target,
                      }}
                    />
                  )}
                </div>
              )
            );
          })}
        </section>
      )
    );
  } catch (err) {
    return <></>;
  }
};

const renderChatWithUs = (chatServices) => {
  try {
    return (
      <div className="chat-with-us-wrapper">
        <h4>{chatServices.titleCopy}</h4>
        {chatServices.services.map((socialLink, i) => {
          return (
            <div className="chat-with-us-link" key={i}>
              <a
                href={socialLink.cta && socialLink.cta.href}
                aria-label={socialLink.cta.label}
                target={socialLink.cta && socialLink.cta.target ? socialLink.cta.target : null}
                rel={`${addSecurityAttrsToAnchor(socialLink.cta && socialLink.cta.target)}${
                  socialLink.cta.noFollow ? ' nofollow' : ''
                }`}
                onClick={(e) => googleAnalyticHandleClickFooter(e, socialLink.cta.label, chatServices.titleCopy)}
                className={`icn-${socialLink.socialMediaItem} ${socialLink.serviceName}-chat-service social-link-item `}
              >
                <SvgSprite id={`icn-${socialLink.serviceName}`} viewBox="0 0 33 33" />
                <span className={'chat-link-title'}>{socialLink.ctaText}</span>
              </a>
            </div>
          );
        })}
      </div>
    );
  } catch (err) {
    return <></>;
  }
};

const renderAppData = (data, tContext) => {
  try {
    const appLink = getDeviceBasedIcons(data?.appData);
    const currentTenantDOM = getCurrentTenant();
    return (
      data?.appData &&
      appLink?.length > 0 && (
        <div className="app-data">
          {(currentTenantDOM === UIConfig.YIB2C || currentTenantDOM === UIConfig.SWADB2C) && data.appData.titleCopy && (
            <h4>{data.appData.titleCopy}</h4>
          )}
          {currentTenantDOM === UIConfig.YIB2C || currentTenantDOM === UIConfig.SWADB2C ? (
            <div className="app-data-links">
              {data.appData.bodyCopy && (
                <DynamicContent
                  tagName="div"
                  innerHtml={data.appData.bodyCopy}
                  attrs={{
                    className: 'body-copy-5 app-text',
                  }}
                />
              )}
              {appLink?.map((link, index) => {
                return (
                  <LazyLoad offset={100} classNamePrefix="app-link" key={index}>
                    <div>
                      {link.cta && (
                        <AnchorLink
                          link={{
                            ariaLabel: link.cta.label,
                            href: link.cta.href,
                            label: link.image && link.image.html,
                            target: link.cta.target,
                          }}
                          onClick={
                            (() => callGoalAnalytics(link.goal, tContext),
                            currentTenantDOM === UIConfig.YIB2C
                              ? (e) => googleAnalyticHandleClickFooter(e, link.image, data.appData.titleCopy)
                              : '')
                          }
                        />
                      )}
                    </div>
                  </LazyLoad>
                );
              })}
            </div>
          ) : (
            <>
              {data.appData.bodyCopy && (
                <DynamicContent
                  tagName="div"
                  innerHtml={data.appData.bodyCopy}
                  attrs={{
                    className: 'body-copy-5 app-text',
                  }}
                />
              )}
              {data.appData.appLink.map((link, index) => {
                return (
                  <LazyLoad offset={100} classNamePrefix="app-link" key={index}>
                    <div>
                      {link.cta && (
                        <AnchorLink
                          link={{
                            ariaLabel: link.cta.label,
                            href: link.cta.href,
                            label: link.image && link.image.html,
                            target: link.cta.target,
                          }}
                          onClick={
                            (() => callGoalAnalytics(link.goal, tContext),
                            currentTenantDOM === UIConfig.YIB2C
                              ? (e) => googleAnalyticHandleClickFooter(e, link.image, data.appData.titleCopy)
                              : '')
                          }
                        />
                      )}
                    </div>
                  </LazyLoad>
                );
              })}
            </>
          )}
        </div>
      )
    );
  } catch (err) {
    return <></>;
  }
};

const renderSocialLinks = (data) => {
  const currentTenant = getCurrentTenant();
  try {
    return (
      data.socialMediaItems.length > 0 &&
      (currentTenant === UIConfig.YIB2C || currentTenant === UIConfig.SWADB2C ? (
        <div className="social-links-wrapper">
          <h4>{data.socialTitleCopy}</h4>
          <div className="social-link-items">
            {data.socialMediaItems.map((socialLink, i) => {
              return (
                socialLink.socialMediaItem !== 'whatsapp' && (
                  <a
                    key={i}
                    href={socialLink.cta && socialLink.cta.href}
                    aria-label={socialLink.cta.label}
                    target={socialLink.cta && socialLink.cta.target ? socialLink.cta.target : null}
                    rel={addSecurityAttrsToAnchor(socialLink.cta && socialLink.cta.target)}
                    className={`icn-${socialLink.socialMediaItem} social-link-item`}
                    onClick={(e) => {
                      handleClick(e, socialLink.socialMediaItem);
                      if (currentTenant === UIConfig.YIB2C) {
                        googleAnalyticHandleClickFooter(e, socialLink.cta.label, data.socialTitleCopy);
                      }
                    }}
                  >
                    <span className={'social-link-title'}>{socialLink.cta.label}</span>
                    <SvgSprite id={`icn-${socialLink.socialMediaItem}`} viewBox="0 0 33 33" />
                  </a>
                )
              );
            })}
          </div>
        </div>
      ) : (
        <div className="social-link">
          {data.socialMediaItems.map((socialLink, i) => {
            return (
              <a
                key={i}
                href={socialLink.cta && socialLink.cta.href}
                aria-label={socialLink.cta.label}
                target={socialLink.cta && socialLink.cta.target ? socialLink.cta.target : null}
                rel={addSecurityAttrsToAnchor(socialLink.cta && socialLink.cta.target)}
                className={`icn-${socialLink.socialMediaItem} social-link-item`}
                onClick={(e) => {
                  handleClick(e, socialLink.socialMediaItem);
                  if (currentTenant === UIConfig.YIB2C) {
                    googleAnalyticHandleClickFooter(e, socialLink.cta.label, data.socialTitleCopy);
                  }
                }}
              >
                <span className={'social-link-title'}>{socialLink.cta.label}</span>
                <SvgSprite id={`icn-${socialLink.socialMediaItem}`} viewBox="0 0 33 33" />
              </a>
            );
          })}
        </div>
      ))
    );
  } catch (err) {
    return <></>;
  }
};

const renderMultiImagePartnerLogos = (data) => {
  let logos = data.ourPartners && data.ourPartners.appLink;
  try {
    if (logos) {
      return (
        <div className="partner-logo">
          {logos.map((logo, index) => {
            let imageObj = {
              desktopImage: { ...logo.image },
              tabletImage: logo.tabletImage,
              mobileImage: logo.mobileImage,
              imageAltText: logo.image.alt,
            };
            imageObj.desktopImage.html = '';
            return (
              <div className="partner-logo-link" key={index}>
                <a
                  href={logo.cta.href}
                  target={logo.cta.target}
                  rel={addSecurityAttrsToAnchor(logo.cta.target)}
                  onClick={(e) => {
                    if (currentTenant === UIConfig.YIB2C) {
                      googleAnalyticHandleClickFooter(e, logo.image.alt, data.ourPartners.title);
                    }
                    if (isMatchTenant(UIConfig.tenants.ya)) {
                      GTMData.push(UIConfig.ga4Constants.PARTNER_LINKS, {
                        click_text: imageObj?.imageAltText,
                        click_url: logo?.cta?.href || undefined,
                      });
                    }
                  }}
                >
                  <Image image={imageObj} />
                </a>
              </div>
            );
          })}
        </div>
      );
    }
    return <></>;
  } catch (err) {
    return <></>;
  }
};

const renderMultiImageOurPartnerLogos = (data) => {
  try {
    let logos = data.ourPartnersLogo?.partnerImages;
    const isEA = checkTenant(UIConfig.iamMapping.etihadarena);
    if (Array.isArray(logos) && logos.length > 0) {
      return (
        <div className="new-partner-logo">
          <div className="new-partner-title">{data.ourPartnersLogo.title}</div>
          {logos.map((logo, index) => {
            let imageObj = {
              desktopImage: { ...logo.desktopImage },
              tabletImage: logo.tabletImage,
              mobileImage: logo.mobileImage,
              imageAltText: logo.desktopImage.alt,
            };
            imageObj.desktopImage.html = '';
            const { href, target } = logo?.cta;
            return (
              <div className="partner-logo-link" key={index}>
                <a
                  href={isEA && href ? href : undefined}
                  target={isEA && href && target ? target : undefined}
                  title={imageObj.imageAltText}
                  onClick={(e) => {
                    if (currentTenant === UIConfig.YIB2C) {
                      googleAnalyticHandleClickFooter(e, logo.desktopImage.alt, data.ourPartnersLogo.title);
                    }
                    if (isMatchTenant(UIConfig.tenants.ya)) {
                      GTMData.push(UIConfig.ga4Constants.PARTNER_LINKS, {
                        click_text: imageObj?.imageAltText,
                        click_url: href || undefined,
                      });
                    }
                  }}
                >
                  <Image image={imageObj} />
                </a>
              </div>
            );
          })}
        </div>
      );
    } else return <></>;
  } catch (err) {
    return <></>;
  }
};

const renderSubFooter = (data) => {
  try {
    return (
      <div className="footer-container-sub-footer">
        <div className="w--content clearfix">
          {data.copyRightText && (
            <div className="copyright-text">
              <Text tag="p" field={{ value: getCopyRightText(data), editable: getCopyRightText(data) }} />
            </div>
          )}
          {data.externalTextLinks && data.variant !== UIConfig.swadVariant.footerVariant && (
            <div className="text-link">
              {data.externalTextLinks.map((textLink, i) => {
                return <AnchorLink key={i} link={textLink && textLink.linkCta} className="text-link-item" />;
              })}
            </div>
          )}
          {/* extra markup check for yasIsland */}
          {data.socialMediaItems &&
            data.variant !== 'v-footer-yasisland' &&
            data.variant !== UIConfig.swadVariant.footerVariant &&
            renderSocialLinks(data)}
        </div>
      </div>
    );
  } catch (err) {
    return <></>;
  }
};
const FooterMain = ({ data, params }) => {
  const [footerDropdownOpen, setFooterDropdownOpen] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const tContext = useContext(TrackerContext);
  const isMiniFooter = data.variant === UIConfig.b2c.footerVariants.miniFooter || params?.footerType === 'mini-footer';

  useEffect(() => {
    const mobile = detectMobile();
    updateMainMinHeight(mobile);
    window.addEventListener('resize', () => updateMainMinHeight(mobile));
    setMobile(mobile);
    return () => {
      window.removeEventListener('resize', () => updateMainMinHeight);
    };
  }, []);

  const updateMainMinHeight = (mobile) => {
    if (isMiniFooter && !mobile) {
      setTimeout(() => {
        const height =
          window.innerHeight -
          document.querySelector('footer')?.offsetHeight -
          document.querySelector('header')?.offsetHeight;
        const mainElement = document.querySelector('main');
        if (mainElement && height) {
          mainElement.style.minHeight = `${height}px`;
          if (checkTenant(UIConfig.iamMapping.ppad)) {
            const emptyCartContainer = document.querySelector('.c-b2c-cart-wrapper.cart-payment-empty');
            const emptyCartInnerHTML = document.querySelector(
              '.c-b2c-cart-wrapper.cart-payment-empty .empty-cart-container',
            );
            if (emptyCartContainer && emptyCartInnerHTML) {
              const emptyHeight = emptyCartInnerHTML.offsetHeight;
              emptyCartContainer.style.paddingBottom = `${height - emptyHeight}px`;
            }
          }
        }
      }, 500);
    }
  };

  if (data) {
    try {
      const isPrimaryLogo = resolvePath(data, 'primaryLogo.image.html');
      const isSecondaryLogo = resolvePath(data, 'secondaryLogo.image.html');
      const ourPartnersLen = resolvePath(data, 'ourPartners.appLink', [])?.length;
      const showFooterLogoSection = !!(isPrimaryLogo || isSecondaryLogo || ourPartnersLen);
      const isYasArena = data.variant === UIConfig.b2c.footerVariants.yaFooter;

      const getCopyRightTextContent = (content) => {
        if (content.copyRightText) {
          return (
            <div className="copyright-text">
              <Text tag="p" field={{ value: getCopyRightText(content), editable: getCopyRightText(content) }} />
            </div>
          );
        }
      };

      if (isMiniFooter) {
        const currentTenantId = getCurrentTenant();
        return (
          <footer>
            <div className="c-mini-footer">
              <div className="container">
                <div className="left-container">
                  <PrimaryLogo data={data} isYasArena={isYasArena} currentTenant={currentTenantId} miniFooter />
                  {!isMobile && getCopyRightTextContent(data)}
                </div>
                <div className="right-container">
                  <DynamicContent
                    tagName="p"
                    innerHtml={data?.contactText || 'For assitance call :<a href="tel:600 511115">600 511115</a>'}
                  />
                  {isMobile && getCopyRightTextContent(data)}
                </div>
              </div>
            </div>
          </footer>
        );
      }
      return (
        <footer>
          <div className={`footer-wrapper ${data.variant} ${data.theme} yas-revamp`}>
            {data.variant?.match(/active-curve/g) ? (
              <div className="w--top">
                <div className="svg-wrapper">
                  <SvgSprite id="footer-curve" styleClass="footer-curve" />
                </div>
              </div>
            ) : null}
            {data.variant && data.variant?.includes('active-footer-ribbon') && <div className="active-footer-ribbon" />}

            {/* Smaller Footer */}
            {data.isSmallerFooter ? (
              <div
                data-c-name="Footer"
                data-c-render="server-only"
                className="footer-container smaller-footer-container component"
              >
                {renderSubFooter(data)}
                <div className="footer-links footer-container-body-links-column">
                  <ul>
                    {data.smallerFooterLinks &&
                      data.smallerFooterLinks.map((sub, i) =>
                        sub.label ? (
                          <li key={i} className={'body-copy-6'}>
                            {sub.href ? (
                              sub.class === 'redirect' && data.variant !== UIConfig.YIB2C ? (
                                <span className={'modify-booking'} tabIndex="0">
                                  {sub.label}
                                </span>
                              ) : (
                                <DynamicContent
                                  tagName="a"
                                  attrs={{
                                    href: sub.href,
                                    target: sub.target,
                                    onClick:
                                      data.variant === UIConfig.YIB2C
                                        ? (e) => googleAnalyticHandleClickFooter(e, sub.label, sub.title)
                                        : '',
                                  }}
                                  innerHtml={sub.label}
                                />
                              )
                            ) : (
                              <DynamicContent tagName="span" innerHtml={sub.label} />
                            )}
                          </li>
                        ) : (
                          ''
                        ),
                      )}
                  </ul>
                </div>
              </div>
            ) : (
              <div data-c-name="Footer" data-c-render="server-only" className="footer-container component">
                {data.newsLetter && renderNewsLetter(data)}
                {data.newsLetter && <hr />}
                {data.linkCategories && renderFooterBody(data, tContext, footerDropdownOpen, setFooterDropdownOpen)}
                {!isYasArena && showFooterLogoSection && <hr className="footer-logo-border" />}
                {showFooterLogoSection && renderLogo(data, tContext)}
                {isYasArena && renderMultiImagePartnerLogos(data)}
                {renderMultiImageOurPartnerLogos(data)}
                {renderSubFooter(data)}
              </div>
            )}
          </div>
        </footer>
      );
    } catch (err) {
      Logging('footer', err);
      return logComponentRenderingError(err, 'Footer', data.variant);
    }
  }
};

FooterMain.propTypes = {
  data: PropTypes.shape({
    newsLetter: PropTypes.object,
    primaryLogo: PropTypes.object.isRequired,
    secondaryLogo: PropTypes.object,
    linkCategories: PropTypes.array.isRequired,
    copyRightText: PropTypes.string.isRequired,
  }),
};

export default FooterMain;
