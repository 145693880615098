/*
 * captcha-component.js
 * This file contains code for Captcha, it renders Captcha image with input field and refresh icon

 * @licensor  Miral
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../base';
import { canUseDOM } from '../../../common/utility';
import Script from 'react-load-script';
import { logComponentRenderingError } from '../../../common/logger';

import './captcha-component.scss';

/**
 * Captcha is a component which will be rendered by Form component, it expects its props from Form component
 * @param    {object}    data     It is expecting botnet session id in data , this id will be used to make server call for catcha image
 */

export default class Captcha extends Component {
  onHelpClick(e) {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window[this.props.data.captcha.id].OnHelpLinkClick();
      return window[this.props.data.captcha.id].FollowHelpLink;
    }
  }

  refreshCaptcha(e) {
    e.preventDefault();
    if (canUseDOM()) {
      window[this.props.data.captcha.id].ReloadImage();
      return false;
    }
  }

  playSound(e) {
    e.preventDefault();
    if (canUseDOM()) {
      window[this.props.data.captcha.id].PlaySound();
      return false;
    }
  }
  initBotDetect(
    captchaId,
    instanceId,
    inputId,
    autoUppercaseInput,
    autoFocusInput,
    autoClearInput,
    autoReloadExpiredImage,
    autoReloadPeriod,
    autoReloadTimeout,
    soundStartDelay,
    limitSoundRegeneration,
  ) {
    var inputIdString = null;
    if (inputId) {
      inputIdString = "'" + inputId + "'";
    }

    if (document.getElementById(captchaId + '_CaptchaImage') && window.BotDetect) {
      let BotDetect = window.BotDetect;
      window[captchaId] = new BotDetect(
        captchaId,
        instanceId,
        inputIdString,
        autoUppercaseInput,
        autoFocusInput,
        autoClearInput,
        autoReloadExpiredImage,
        autoReloadPeriod,
        autoReloadTimeout,
        soundStartDelay,
        limitSoundRegeneration,
      );
      window[captchaId].PostInit();
    }
  }

  handleScriptLoad() {
    if (typeof window !== 'undefined' && window.BotDetect) {
      this.initBotDetect(
        this.props.data.captcha.id,
        this.props.data.captcha.botnetSessionId,
        this.props.data.id,
        true,
        true,
        true,
        true,
        1200,
        7200,
        0,
        false,
      );
    }
  }

  render() {
    try {
      return (
        <div
          className="component c-captcha"
          data-captchaId={this.props.data.captcha.botnetSessionId}
          data-id={this.props.data.captcha.id}
        >
          <div className="c-captcha--botdetect" id={this.props.data.captcha.id + '_CaptchaDiv'}>
            <div className="c-captcha--botdetect-image" id={this.props.data.captcha.id + '_CaptchaImageDiv'}>
              <a href="//captcha.org/captcha.html?asp.net" onClick={this.onHelpClick.bind(this)} tabIndex="-1">
                <img
                  className="c-captcha--botdetect-image"
                  id={this.props.data.captcha.id + '_CaptchaImage'}
                  src={
                    '/BotDetectCaptcha.ashx?get=image&c=' +
                    this.props.data.captcha.id +
                    '&t=' +
                    this.props.data.captcha.botnetSessionId
                  }
                  alt={this.props.data.captcha.altTextCaptcha}
                />
              </a>
            </div>
            <div className="c-captcha--botdetect-refresh" id={this.props.data.captcha.id + '_CaptchaIconsDiv'}>
              <a
                className="c-captcha--botdetect-reload"
                id={this.props.data.captcha.id + '_ReloadLink'}
                href="/"
                onClick={this.refreshCaptcha.bind(this)}
              >
                <img
                  className="c-captcha--botdetect-reload"
                  id={this.props.data.captcha.id + '_ReloadIcon'}
                  src="/BotDetectCaptcha.ashx?get=reload-icon"
                  alt={this.props.data.captcha && this.props.data.captcha.altTextRefreshIcon}
                />
              </a>

              <a
                className="LBD_SoundLink"
                id={this.props.data.captcha.id + '_SoundLink'}
                href={
                  '/BotDetectCaptcha.ashx?get=sound&c=' +
                  this.props.data.captcha.id +
                  '&t=' +
                  this.props.data.captcha.botnetSessionId
                }
                onClick={this.playSound.bind(this)}
              >
                <img
                  className="LBD_SoundIcon"
                  id={this.props.data.captcha.id + '_SoundIcon'}
                  src="/BotDetectCaptcha.ashx?get=sound-icon"
                  alt={this.props.data.captcha.altTextHelpIcon}
                />
              </a>

              <div className="LBD_Placeholder" id={this.props.data.captcha.id + '_AudioPlaceholder'}></div>
            </div>
            <input
              type="hidden"
              name={'BDC_VCID_' + this.props.data.captcha.id}
              id={'BDC_VCID_' + this.props.data.captcha.id}
              value={this.props.data.captcha.botnetSessionId}
            />
            <input
              type="hidden"
              name={'BDC_BackWorkaround_' + this.props.data.captcha.id}
              id={'BDC_BackWorkaround_' + this.props.data.captcha.id}
              value="0"
            />
          </div>
          <Input
            data={{ ...this.props.data, type: 'Text' }}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
          />
          {typeof window !== 'undefined' ? (
            <Script url={this.props.data.captcha.url} onLoad={this.handleScriptLoad.bind(this)} />
          ) : (
            ''
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'Captcha');
    }
  }
}

Captcha.propTypes = {
  data: PropTypes.shape({
    captcha: PropTypes.object.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }),
};
