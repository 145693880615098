import React from 'react';
import ActionComponent from './action-component';

const GlobalHOC = (WrappedComponent) => {
  return (props) => {
    return <ActionComponent WrappedComponent={WrappedComponent} props={props} />;
  };
};

export default GlobalHOC;
