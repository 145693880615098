/*
 * filter-coposition.js
 * This file contains code for filters and creates Composed component to encapsulate
 * behaviour of all filters under one composed object
 * @licensor  Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * filter(ComposedComponent) funtion creates filter class component that can be used as a separate
 * filter component
 * @param    {(ComposedComponent)} accepts filter component which needs to be returned as a
 * Filter class.
 * @return   {[Class]} Returns a Filter Class .
 */
export default function filter(ComposedComponent) {
  const { func, string } = PropTypes;

  return class Filter extends React.Component {
    static contextTypes = {
      updateFilter: func.isRequired,
    };

    static propTypes = {
      filterName: string.isRequired,
    };
    /**
     * Constructor of the class is defined which handles binding of the events to the elements, the
     * props to the super class and defining the state of the component.
     * @prop   {composedElementProps} contains props and updateFilter function to be passed to
     * Composable Element
     */
    constructor(props) {
      super(props);
      this.composedElementProps = {
        ...this.props,
        updateFilter: this.updateFilter,
      };
    }
    /**
     * updateFilter function is  Used to update Main filter object with the filter of Composed filter object
     * @param    {[value]} function accepts the value of filter passed
     * @return   {[Void]} function does not return anything
     */

    updateFilter = (value, dontNotifyMainComponent) => {
      this.context.updateFilter(this.props.filterName, value, dontNotifyMainComponent);
    };

    render() {
      try {
        return <ComposedComponent {...this.composedElementProps} />;
      } catch (err) {
        return logComponentRenderingError(err, 'ComposedComponent');
      }
    }
  };
}
