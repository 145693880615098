import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../presentation/base/spinner/spinner-component';
import { isEnterPressed, isMatchTenant } from '../../../../common/utility';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import TicketTooltip from '../../../presentation/b2c-purchase-journey/ticket-tooltip/ticket-tooltip';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import UICONFIG from '../../../../common/UIConfig';
import moment from 'moment';
import classnames from 'classnames';
import { CartService, ServiceConfig } from '../../../../common/services';
import { errorCallback } from '../purchase-journey-wrapper/helper';
import { checkTenant } from '../../../../common/utility/tenantsUtils';
import GTMData from '../gtm-data';
class AddOnsTile extends Component {
  constructor(props) {
    super(props);
    this.quantity = this.props.data.quantity || 0;
    this.state = {
      enableCartButton: false,
      inCart: false,
      remainingQty: this.props.availabilityMapping && this.props.availabilityMapping[this.props.data.productId],
      maxLimitExceeded: false,
      addToCartRequired: this.props.callAddToCart,
      soldOutLimitError: '',
      soldOut: false,
    };
  }

  componentDidMount() {
    const { items } = this.props.cartData.cart;
    //Handling first load scenario for max limit
    if (Object.keys(items).length > 0) {
      this.cartQuantity = this.getCurrentAddOnQty(items);

      const remainingQty = this.isAddOnQtyLeft({
        availability: this.props.availabilityMapping && this.props.availabilityMapping[this.props.data.productId],
        cartQuantity: this.cartQuantity,
      });

      this.handleStateForMaxLimit({
        remainingQty,
      });
    }
  }

  //State updation with the use of remaining qty of the current add on
  handleStateForMaxLimit = ({ remainingQty }) => {
    if (remainingQty) {
      this.setState({
        maxLimitExceeded: false,
        inCart: this.cartQuantity > 0,
        enableCartButton: false,
      });
    } else {
      this.setState({
        maxLimitExceeded: true,
        enableCartButton: false,
        inCart: this.cartQuantity > 0,
      });
    }
  };

  //returns a boolean value based on the calculation using availabilty and cartQty
  isAddOnQtyLeft = ({ availability, cartQuantity }) => {
    return availability - cartQuantity >= 0;
  };

  //return the cart quantity of the current add on
  getCurrentAddOnQty = (items) => {
    const cartItemValues = Object.values(items);
    const currentAddOn = cartItemValues.filter((item) => {
      return item.products[0].productId === this.props.data.productId;
    });
    return currentAddOn.length > 0 ? currentAddOn[0].products[0].quantity : 0;
  };

  componentDidUpdate(prevProps, prevState) {
    const { items, totalQuantity } = this.props.cartData.cart;
    const prevCartQuantity = prevProps.cartData.cart.totalQuantity;
    const prevAvailability = prevProps.availabilityMapping && prevProps.availabilityMapping[this.props.data.productId];
    const updatedAvailability =
      this.props.availabilityMapping && this.props.availabilityMapping[this.props.data.productId];

    this.cartQuantity = this.getCurrentAddOnQty(items);

    //making spinner quantity zero only on cart update (previous cart total Quantity not equal to current cart total quantity)
    if (totalQuantity !== prevCartQuantity || prevAvailability !== updatedAvailability) {
      //on cart update setting the spinner quantity to zero
      this.quantity = 0;

      //calculating remaining add on quantity
      const remainingQty = this.isAddOnQtyLeft({
        availability: updatedAvailability,
        cartQuantity: this.cartQuantity,
      });

      this.handleStateForMaxLimit({
        remainingQty,
      });
    }
  }
  isOverLayExisting = (e) => {
    const eventDetails = YALocalStorageSvc.getSetEventDetails();
    const isCartProductAvailable = eventDetails && eventDetails.eventId && eventDetails.eventId.length;

    if (this.props.overlayDisplay && isCartProductAvailable && !this.props.recommendationForAll) {
      const addOnProductIdEventId = this.props.eventDetails.eventId;
      const isDifferentProduct = addOnProductIdEventId !== eventDetails.eventId;
      if (isCartProductAvailable && isDifferentProduct && !this.props.emptyBookingError) {
        this.props.displayPopUp();
      }
    } else if (this.props.overlayDisplay && this.props.recommendationForAll) {
      this.displayAddToCartPopUp(e);
    } else {
      this.addToCart(e);
    }
  };
  addToCart = (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && isEnterPressed(e))) {
      e.preventDefault();
      YALocalStorageSvc.getSetEventDetails(this.props.eventDetails);
      const productData = { ...this.props.data };
      const availabilityFromApi = this.props.availabilityMapping && this.props.availabilityMapping.availabilityFromApi;

      productData.quantity = this.quantity;

      //if performanceType present in the add on, add performanceId in the cart for that add on
      if (productData.hasOwnProperty('performanceType') && productData['performanceType'] && availabilityFromApi) {
        productData.performanceId =
          availabilityFromApi[this.props.data.productId] &&
          availabilityFromApi[this.props.data.productId].performanceId;
      }
      //deleting properties product image and product tooltip as not required in check basket
      if (productData.hasOwnProperty('productImage')) {
        delete productData['productImage'];
      }
      if (productData.hasOwnProperty('productToolTip')) {
        delete productData['productToolTip'];
      }
      const mupValidityMap = this.props.mupValidityMap;
      if (productData.hasOwnProperty('accessPolicy') && mupValidityMap && mupValidityMap[productData.accessPolicy]) {
        const rangeInMonths = parseInt(mupValidityMap[productData.accessPolicy]);
        const { date } = YALocalStorageSvc.getSetPerformance();

        let toDate = moment(date).clone();
        toDate = toDate.add(rangeInMonths, 'month');
        toDate.subtract(1, 'days');

        productData.fromDate = date;
        productData.toDate = toDate.locale('en').format(UICONFIG.calendar.dateFormat);
        productData.rangeInMonths = parseInt(mupValidityMap[productData.accessPolicy]);
      }

      this.props.addToCartCallBack &&
        this.props.addToCartCallBack(
          [Object.assign({}, productData)],
          true,
          null,
          this.props.availabilityMapping && this.props.availabilityMapping[this.props.data.productId],
        );
      if (isMatchTenant(UICONFIG.tenants.ya)) {
        GTMData.push(UICONFIG.ga4Constants.ADD_TO_CART_ADONS, productData);
      }
    }
  };

  createOverlayOnAddToCart = (e) => {
    const { addToCartOverlay } = this.props.tabsData.postPurchaseProductAddons;
    const onConfirmClickHandler = (e) => {
      this.closeOverlay();
      this.addToCart(e);
    };
    return (
      <div className="overlay-data">
        <DynamicContent
          tagName="p"
          attrs={{ className: '' }}
          innerHtml={addToCartOverlay && addToCartOverlay.bodyCopy}
        />
        <div className="action-line">
          <div className="btn-primary">
            <button type="button" onClick={(e) => onConfirmClickHandler(e)}>
              {addToCartOverlay.primaryCTA}
            </button>
          </div>
        </div>
      </div>
    );
  };

  displayAddToCartPopUp = (e) => {
    window.PubSub.publish(UICONFIG.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: true,
      dataToAppend: this.createOverlayOnAddToCart(e),
      customClass: classnames('multiDateBooking-Overlay tnc'),
    });
  };

  closeOverlay = () => {
    window.PubSub.publish(UICONFIG.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: false,
    });
  };

  checkAddonPeformance = (quantity) => {
    const { data, checkPerformanceUrl, cartData } = this.props;
    const { productId } = data;
    const { url, errors } = checkPerformanceUrl;
    const updatedUrl = url?.replace('{tenantId}', ServiceConfig.getTenantId());
    const exsitingProd =
      Object.entries(cartData.cart.items).find(
        ([key]) => cartData?.cart?.items?.[key]?.products?.[0]?.productId === productId,
      )?.[1] || {};
    const exsitingQty = exsitingProd?.products?.[0]?.quantity || 0;
    const payload = {
      AddonCheckBaskets: [
        {
          productId: productId || '',
          quantity: exsitingQty + quantity,
        },
      ],
    };

    if (url) {
      CartService.checkAddonAvailability(payload, 'AddOnsTile', updatedUrl)
        .then((response) => {
          if (response.status === 200 && typeof response.data === 'object') {
            const { error, orderdetails } = response.data;
            if (error?.errorcode) {
              this.quantity = quantity - 1;
              this.setState({
                maxLimitExceeded: this.quantity > 0,
                soldOutLimitError: errors?.[error?.errorcode],
              });
            } else if (orderdetails?.order.items?.length) {
              const prodExist = orderdetails.order.items.some((prd) => prd.productId === productId);
              if (prodExist) {
                this.quantity = quantity;
                this.setState({
                  enableCartButton: this.quantity > 0,
                  maxLimitExceeded: false,
                  soldOutLimitError: '',
                });
              }
            }
          }
        })
        .catch((res) => {
          errorCallback('getCoveoProducts', res.error);
        });
    }
  };

  updateQuantity = (quantity) => {
    const { checkPerformanceUrl } = this.props;
    if (checkTenant(UICONFIG.yasArenaB2CTenant) && quantity > 0 && checkPerformanceUrl?.url) {
      this.checkAddonPeformance(quantity);
    } else {
      const remainingQty =
        this.props.availabilityMapping && this.props.availabilityMapping[this.props.data.productId] - this.cartQuantity;
      if (quantity > remainingQty) {
        this.quantity = quantity - 1;
        this.setState({
          maxLimitExceeded: true,
          enableCartButton: this.quantity > 0,
        });
      } else {
        this.quantity = quantity;
        this.setState({
          enableCartButton: this.quantity > 0,
          maxLimitExceeded: false,
        });
      }
    }
  };

  buttonLabel = () => {
    const { addToCart, addToCartSelected, calenderLabels } = this.props.tabsData;

    if (this.state.inCart) return addToCartSelected;
    if (this.state.soldOutLimitError && this.quantity === 0) return calenderLabels.soldOutLabel;
    return addToCart;
  };

  render() {
    const {
      productImage,
      productName,
      description,
      productToolTip,
      gross,
      currency,
      experienceCatgory,
    } = this.props.data;
    const { cartLabels } = this.props.additionalRecommendedData;
    return (
      <div className={experienceCatgory ? 'add-ons-tile highlight-border ' : 'add-ons-tile'}>
        {productImage && (
          <div className="add-ons-image">
            {/* TODO get alt text from coveo */}
            <img src={productImage} alt="" />
          </div>
        )}
        {experienceCatgory && (
          <div className="preferred__ribbon">
            <i aria-hidden="true" className="fa fa-heart"></i>
            <span className="ng-star-inserted">{experienceCatgory}</span>
            <i aria-hidden="true" className="fa fa-heart"></i>
          </div>
        )}
        <div className="add-ons-details">
          {productName && <div className="add-ons-title">{productName}</div>}
          {description && <div className="add-ons-description">{description}</div>}
          {gross && (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'body-1 add-ons-item-price' }}
              innerHtml={`${currency} ${gross}`}
            />
          )}
        </div>
        <div className="add-ons-spinner-wrapper">
          <div className="spinner-container">
            <Spinner
              isInputDisabled="true"
              max={
                this.state.soldOutLimitError
                  ? this.quantity
                  : this.props.availabilityMapping?.[this.props.data.productId]
              }
              updateQuantity={this.updateQuantity}
              quantity={this.quantity}
            />
            {/* TODO get this value from CMS */}
            {!this.props.displayMaxLimit && this.state.maxLimitExceeded && (
              <p className={`max-limit-error ${this.state.soldOutLimitError ? 'sold-out-error' : ''}`}>
                {this.state.soldOutLimitError || cartLabels.maxLimitErrorLabel}
              </p>
            )}
          </div>
          <div className={this.state.enableCartButton ? 'btn-primary ' : 'btn-primary disabled'}>
            <button
              tabIndex={this.state.enableCartButton ? '0' : '-1'}
              onClick={this.isOverLayExisting}
              onKeyDown={this.isOverLayExisting}
            >
              {this.buttonLabel()}
            </button>
          </div>
        </div>
        {productToolTip && (
          <div className="tooltip-wrapper">
            <TicketTooltip description={productToolTip} />
          </div>
        )}
      </div>
    );
  }
}

export default AddOnsTile;

AddOnsTile.PropsTypes = {
  data: PropTypes.object,
  soldOutText: PropTypes.string,
  actionLink: PropTypes.string.isRequired,
};

// Adding new booean property displayMaxLimit default as false since Product quantity not applicable for etihadarena club lounge addon products
AddOnsTile.defaultProps = {
  displayMaxLimit: false,
};
