/**
 * upsell-component.js
 * This file contains code for upsell component which renders on client side
 * @license  Miral
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GTMData from '../gtm-data';

import { getArrayItemByKeyVal, toTwoDecimalPlaces, getYasIdGTMData, checkTenant } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';

import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import ErrorSummary from '../../../presentation/base/error-summary/error-summary-component';

class UpSell extends Component {
  constructor(props) {
    super(props);
    this.selectedProds = props.products;
    this.gtmData = [];
    this.upsellSectionRef = React.createRef();
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
  }

  componentDidMount() {
    if (this.isSwad && this.upsellSectionRef) {
      setTimeout(() => {
        this.upsellSectionRef.current.focus();
      }, 0);
    }
  }

  updateProducts = (products) => {
    products.map((prod) => {
      const selectedProd = getArrayItemByKeyVal(this.selectedProds, { productId: prod.baseProduct });
      prod.quantity = selectedProd && parseFloat(selectedProd.quantity);
      //added check for annual pass product so that date should not get added in case of annual pass
      if (prod.itemType !== UIConfig.b2c.purchaseJourney.tabs.annual_pass) {
        prod.fromDate = selectedProd && selectedProd.fromDate;
        prod.isFlexible = selectedProd && selectedProd.isFlexible;
        prod.rangeInMonths = selectedProd && selectedProd.rangeInMonths;
      }
      this.isSwad && this.props.discountMap
        ? this.props.insertDiscountNode(prod, this.props.discountMap)
        : this.props.insertDiscountNode(prod);
      this.gtmData.push(this.createDataForGTM(prod, selectedProd));
      return prod;
    });
    this.selectedProds = products;
    this.pushGTMFlags();
    window.PubSub.publish('closeOverlay', {
      close: true,
      upsell: true,
      addedFromUpSell: true,
      context: this.props.redirectionContext ? this.props.redirectionContext : false,
    });
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: false,
    });
  };

  createDataForGTM = (prod, selectedProd) => {
    return {
      dimension14: `Upsell: ${selectedProd && selectedProd.productName} To ${prod && prod.productName}` || undefined,
    };
  };

  pushGTMFlags() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'productUpsell',
      eventAction: 'Upsell',
      eventLabel: this.gtmData[0]['dimension14'],
      myPassId: getYasIdGTMData(),
    });
  }

  renderSection = (products, sectionId) => {
    const addProduct = () => {
      this.updateProducts(products);
    };
    return (
      <div className="c-upsell-section-wrapper">
        <div key={sectionId} className="c-upsell-section" tabIndex={this.isSwad ? '-1' : '0'}>
          <DynamicContent
            tagName="h2"
            attrs={{ className: 'product-heading body-copy-2' }}
            innerHtml={`${this.props.data.headingLabel} ${products[0].productName || ''}`}
          />
          {this.isSwad && (
            <div className="ticket-details">{products.map((prod, index) => this.renderColumn(prod, index))}</div>
          )}
          {this.isSwad && !products[0].description ? (
            ''
          ) : (
            <p className="product-description body-copy-4">{products[0].description}</p>
          )}
          {!this.isSwad && (
            <div className="ticket-details">{products.map((prod, index) => this.renderColumn(prod, index))}</div>
          )}
          {!this.isSwad && <hr className="divider" />}
          <div className="btn-primary update-btn" data-value={sectionId}>
            <button onClick={addProduct} data-value={sectionId}>
              <DynamicContent
                tagName="span"
                attrs={{
                  'data-value': sectionId,
                  'aria-label':
                    this.props.data.upgradeButtonLabel + this.props.data.headingLabel + (products[0].productName || ''),
                }}
                innerHtml={this.props.data.upgradeButtonLabel}
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderColumn = (product, index) => {
    return (
      <div key={index} className="c-upsell-section-column">
        <div className="upsell-product-detail">
          <p className="product-name body-copy-4">{product.classTypeName}</p>
          <DynamicContent
            tagName="span"
            attrs={{ className: 'product-price body-copy-4' }}
            innerHtml={`${this.props.data.fromLabel} ${product.priceDiff.currency} ${
              !this.isSwad
                ? toTwoDecimalPlaces(product.priceDiff.gross)
                : product.priceDiff.gross.toLocaleString('en', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
            }`}
          />
          <span></span>
        </div>
      </div>
    );
  };

  addProducts = (data) => {
    const upsellText = data.addedFromUpSell ? UIConfig.upSellText : null;
    if (this.isSwad && data.context) {
      this.props.addToCartCallback(this.selectedProds, data.context, null, null, null, upsellText);
    } else {
      this.props.addToCartCallback(this.selectedProds, null, null, null, null, upsellText);
    }
    window.PubSub.unsubscribe('closeOverlay', this.addProducts);
    if (this.isSwad && data.upsell) {
      window.PubSub.publish('closeUpsellOverlay', {
        resub: true,
      });
    }
  };

  componentWillMount() {
    window.PubSub.subscribe('closeOverlay', (msg, data) => this.addProducts(data));
  }

  render() {
    const sections = [],
      groupedProds = this.props.groupedProducts;
    for (const key in groupedProds) {
      if (!groupedProds.hasOwnProperty(key)) {
        continue;
      }
      sections.push(this.renderSection(groupedProds[key], key));
    }
    const gridClass = sections.length % 2 == 0 ? 'even' : 'odd';
    return (
      <div className="c-upsell component" data-c-render="client">
        <div className="w-content">
          <div className="upsell-title body-copy-1">{this.props.data.title}</div>
          {this.isSwad && (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'upsell-description' }}
              innerHtml={this.props.data.description}
            />
          )}
          <ErrorSummary data={this.props.errorData} />
          <div className="img-wrapper"></div>
          <div className={`upsell-section-container ${gridClass}`} tabIndex="-1" ref={this.upsellSectionRef}>
            {sections}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */

UpSell.propTypes = {
  prod: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired,
  type: PropTypes.string,
};

export default UpSell;
