/*
 * editorial-grid-components.js
 * This file contains code for Editorial grid component, It renders some optional fields and Editiroal Item component
 * @licenssor  Miral
 */
import React from 'react';

import ApiGateWay from '../../../common/api-wrapper';
import {
  canUseDOM,
  carouselDefaultSettings,
  deepCloneObject,
  detectEdge,
  getMainObject,
  isTouchDevice,
  parseQueryString,
  removeLoaderOverlay,
  getTenantId,
  getCurrentLanguage,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { DynamicContent, ErrorOverlay } from '../../presentation/base';
/* 
yaBannerExternal: 'v-ya-banner-external-url',
we have added this new variant to enable the feature of opening the banner links in a new tab,
*/
export const CAROUSEL_VARIANT = {
  mobileView: 'v-carousel-mobile-view',
  allView: 'v-carousel-view',
  yaCarouselView: 'v-ya-editorial_grid_carousel',
  yaFeatureListingExtn: 'v-ya-feature-listing-extn',
  yaBannerExternal: 'v-ya-banner-external-url',
  twoGridMobileView: 'two-grid-mobile-view',
  similarProducts: 'v-similar-products',
  fourGridLayout: 'v-carousel-4grid-view',
  masonryGrid: 'v-editorialgrid-gallary-view',
};

export const GRID_CAROUSEL_VARIANT = {
  gridView: 'v-editorialgrid-gridview',
};

export const CENTERPADDING = {
  tablet: '63px',
  mobile: '22px',
  desktop: '27px',
  zoomedMobile: '82px',
  titleOnLeft: '45',
  contentOnLeft: '68px',
  halfFull: '43px',
  similarProduct: '23px',
  pdpFiveCardIcons: '67px',
  sliderOnLeft: '42px',
};

export const allViewSettings = (customCarouselSettings, carousel, imageDetail, theme) => {
  /* 2048 define the Maximum View of Desktop   */
  customCarouselSettings = getCustomCarouselSettings({ carousel, imageDetail, theme });
  const responsiveCarouselSettings = {
    breakpoint: 100000,
    settings: {
      centerMode: carousel.centerMode || false,
      draggable: carousel.arrows,
    },
  };

  customCarouselSettings = Object.assign(
    {},
    deepCloneObject(carouselDefaultSettings),
    deepCloneObject(customCarouselSettings),
  );
  customCarouselSettings.responsive.push(responsiveCarouselSettings);
  customCarouselSettings.slidesToScroll = carousel.slidesToScroll;
  customCarouselSettings.arrows = carousel.arrows;
  customCarouselSettings.rtl = carousel.rtl;
  customCarouselSettings.dots = carousel.dots;
  customCarouselSettings.slidesToShow = carousel.slidesToShow <= 0 ? 3 : carousel.slidesToShow;
  customCarouselSettings.swipe = isTouchDevice() || detectEdge();
  return customCarouselSettings;
};

/**
	* getCustomCarouselSettings function to return the custom carousel settings properties
		and update in carausel settings
	* @param    {[data]} data object of the element
	* @return   {[Object]} Return custom carausel settings.
	*/
export const getCustomCarouselSettings = ({ carousel, imageDetail, theme }) => {
  return {
    className: `c-editorial-grid--item ${carousel && carousel.dots ? 'dot-enabled' : ''}`,
    dotsClass: `custom-dots ${imageDetail && imageDetail.length <= 1 ? 'custom-dots-hide' : ''}`,
    customPaging: function(i) {
      return theme && theme.indexOf('remove-pagination-zero') > -1 ? (
        <button>
          <span className="slide-in-view">
            {i < 9 && <span className="prefixed-zero">0</span>}
            {carousel && carousel.rtl && imageDetail ? imageDetail.length - i : i + 1}
          </span>
          <span className="separator">/</span>
          <span className="total-slide">
            {imageDetail && imageDetail.length < 10 && <span className="prefixed-zero">0</span>}
            {imageDetail && imageDetail.length}
          </span>
        </button>
      ) : (
        <button>
          <span className="slide-in-view">
            <span className="prefixed-zero">0</span>
            {carousel && carousel.rtl && imageDetail ? imageDetail.length - i : i + 1}
          </span>
          <span className="separator">/</span>
          <span className="total-slide">
            <span className="prefixed-zero">0</span>
            {imageDetail && imageDetail.length}
          </span>
        </button>
      );
    },
    afterChange: (i) => {
      imageDetail.forEach((item) => {
        item.isActive = false;
        if (item.activeCardId === i) {
          item.isActive = true;
        }
      });
    },
  };
};

/*
 * Carousel settings for character carousel
 * className = character-carousel
 */
export const characterCarouselSettings = (customCarouselSettings, viewport, imageDetail) => {
  customCarouselSettings.swipeToSlide = true;
  customCarouselSettings.cssEase = 'linear';
  if (viewport === 'tablet') {
    customCarouselSettings.slidesToShow = 1;
    customCarouselSettings.centerPadding = '200px';
  } else if (viewport === 'mobile') {
    customCarouselSettings.centerPadding = imageDetail.length <= 2 ? '0px' : '95px';
  }
  return customCarouselSettings;
};

/*
 * Content settings for character carousel
 * className = content-carousel
 */
export const contentCarouselSettings = (customCarouselSettings, viewport) => {
  if (viewport === 'tablet') {
    customCarouselSettings.centerPadding = CENTERPADDING.tablet;
    customCarouselSettings.slidesToShow = 2;
  }
  return customCarouselSettings;
};

export const redirectToURL = (href, target, variant) => {
  if (canUseDOM()) {
    if (variant === CAROUSEL_VARIANT.yaBannerExternal && target) {
      window.open(href, target);
      return;
    }
    window.location = href;
  }
};

/**
 * Creates overlay for new user
 * @param {[data]} data object of the element
 */
export const showOverLay = (e, data) => {
  e.preventDefault();
  const accountID = parseQueryString('id');

  if (accountID) {
    const payload = { accounts: [{ id: accountID }] };
    ApiGateWay.api({
      url: `${data.services.ReadAccountDetails.url}?tenantid=${getTenantId(true)}&isEncrypted=true`,
      method: 'POST',
      isCompleteErrorResNeeded: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload,
      preLoader: true,
      preLoaderTarget: '.page-container',
      noCache: true,
    }).then((response) => {
      const {
        firstName,
        lastName,
        countryOfResidence,
        nationality,
        mobileNumber,
        email,
      } = response.data.guestDetails[0];
      const whatIsMobileNumber = mobileNumber === '' ? '11111' : `${mobileNumber}`;

      createUser({
        firstName,
        lastName,
        country: countryOfResidence,
        nationality,
        mobileNumber,
        email,
        whatIsMobileNumber,
        title: data.title,
        services: data.services,
      });
    });
  } else {
    const { firstName, lastName, country, nationality, mobileNumber, email } = JSON.parse(
      localStorage.getItem('guestUserData'),
    );
    const whatIsMobileNumber = mobileNumber === '' ? '11111' : `${mobileNumber}`;

    createUser({ firstName, lastName, country, nationality, mobileNumber, email, whatIsMobileNumber });
  }

  return false;
};

/**
 * Populate overlay data
 * if user successfully register
 * @param {[data]} data object of the element
 */
export const emailSubscribeOverlay = ({ title, email }) => (
  <div className="user-main-wrapper">
    <DynamicContent
      tagName="div"
      attrs={{ className: 'heading-7 user-content-wrapper' }}
      innerHtml={title.replace('{guestemailId}', `<strong>${email}</strong>`)}
    />
  </div>
);

/**
 * Creates YasID for new user
 * @param {[data]} data object of the element
 */
export const createUser = ({
  firstName,
  lastName,
  country,
  nationality,
  email,
  whatIsMobileNumber,
  title,
  services,
}) => {
  const mainObj = getMainObject() || {};
  const domain = window.location.origin;

  ApiGateWay.api({
    url: `${services.createUser.url}?lang=${getCurrentLanguage()}&tenantid=${getTenantId(true)}&domain=${domain}`,
    method: 'POST',
    isCompleteErrorResNeeded: true,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      user: {
        UserPrincipalName: '',
        AccountEnabled: true,
        City: 'Texas',
        Country: country,
        Department: 'dept',
        FacsimileTelephoneNumber: 'sa',
        GivenName: firstName,
        JobTitle: 'dev',
        Mobile: whatIsMobileNumber,
        PhysicalDeliveryOfficeName: 'test',
        PostalCode: '12100',
        PreferredLanguage: 'en',
        State: 'Dallas',
        StreetAddress: '1222',
        Surname: lastName,
        TelephoneNumber: whatIsMobileNumber,
        CreationType: 'LocalAccount',
        DisplayName: firstName,
        mailnickName: 'string',
        SignInNames: [
          {
            Type: 'emailAddress',
            Value: email,
          },
        ],
      },
      extensionProperties: {
        extension_1b562d6df55b487aa607c2d4c0525216_Nationality: nationality,
        extension_1b562d6df55b487aa607c2d4c0525216_NewsOffer: 'true',
        extension_1b562d6df55b487aa607c2d4c0525216_phone_number: whatIsMobileNumber,
        extension_1b562d6df55b487aa607c2d4c0525216_TNC: 'true',
      },
      client_id: mainObj.additionalProperty.client_id,
    },
    preLoader: true,
    preLoaderTarget: '.page-container',
    noCache: true,
  })
    .then((response) => {
      window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
        shouldOpen: true,
        dataToAppend: emailSubscribeOverlay({
          title,
          email,
        }),
        customClass: 'confirmation-overlay register-success-overlay',
      });
    })
    .catch((err) => {
      removeLoaderOverlay();
      const errorMsg = services.createUser.errors;
      window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
        shouldOpen: true,
        dataToAppend: ErrorOverlay({
          bodyCopy: errorMsg[err.error.errorRes.data['odata.error']['values'][1]['value']],
        }),
        customClass: 'confirmation-overlay register-failure-overlay',
      });
    });
};
