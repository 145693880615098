import React from 'react';
import { AnchorLink } from '../../presentation/base';

const Breadcrumb = ({ data }) => {
  return (
    <div data-c-name="Breadcrumb" data-c-render="server-only" className="component breadcrumb">
      {data.breadcrumb && data.breadcrumb.length && (
        <div className="w--content">
          <ul className="breadcrumb-list">
            {data.breadcrumb.map((item, i) => {
              let href = '';
              if (item.url !== '#') {
                href = item.url;
              } else {
                href = null;
              }

              return (
                <li key={i}>
                  <AnchorLink
                    className="breadcrumb-link"
                    link={{
                      href,
                      label: item.title,
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Breadcrumb;
