/*
 * accordian-components.js
 * This file contains the logic for making the accordian.
 * This comes in the two configurations-> Multi epxnadable and single expandable. This internally usses dl dt dd
 * configuration for the markup.
 * @licensor  Miral
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  parseQueryString,
  getHeaderHeight,
  focusHandlerOnAccordion,
  canUseDOM,
  checkParksTenants,
  checkGA4Tenants,
  getGa4Category,
  isMatchTenant,
} from '../../../common/utility';
import { DynamicContent, SvgSprite } from '../base';
import { logComponentRenderingError } from '../../../common/logger';
import GTMData from '../../container/b2c-purchase-journey/gtm-data';
import UIConfig from '../../../common/UIConfig';

const scrollToItem = (listitemRef, isCollapsibleComponent) => {
  let headerHeight = getHeaderHeight('c-header');
  // in case the collabsible content is called from tab placeholder component then the height should be less
  const isTab = isCollapsibleComponent ? -20 : 60;
  setTimeout(() => {
    listitemRef && listitemRef.current.scrollIntoView({ block: 'start', behaviour: 'smooth' });
    window.scrollBy && window.scrollBy(0, -headerHeight - isTab);
  }, 300);
};

const handlerOnKeyPress = (e, orderIndex, getOrderDetailFn) => {
  if (e.type === 'keypress' && (e.which === 13 || e.which === 32)) {
    getOrderDetailFn(orderIndex);
  }
};

const GTMDataOnClickAccordian = (data) => {
  if (checkGA4Tenants()) {
    GTMData.push(UIConfig.ga4Constants.CLICK_ACCORDIAN, {
      category: getGa4Category(window?.location?.pathname, UIConfig.ga4Constants.HOME),
      name: data?.title || data?.tabTitle || undefined,
      elementText: data?.summaryRTE,
    });
  }
};

/**
 * AccordianListItem creates the list elements of accordian
 */
const AccordianListItem = ({
  prevCurrentLimit,
  data,
  defaultOpen,
  elementClicked,
  getOrderDetail,
  index,
  isMultiExpandable,
  isRTE,
  refreshTabs,
  reRenderedByAccordian,
  parentIndex,
  isCollapsibleComponent,
  title,
  tabTitle = '',
  variant,
}) => {
  const [expanded, setExpanded] = useState(!!defaultOpen);
  const [firstTimeLoad, setFirstTimeLoad] = useState(!defaultOpen);
  const listitemRef = useRef();

  useEffect(() => {
    data.hashKey === parseQueryString('cl') && scrollToItem(listitemRef, isCollapsibleComponent);
    const listItemsLength = document.querySelectorAll('[id*=accordion_header_]').length;
    if (listItemsLength > prevCurrentLimit) {
      const elm = document.querySelector(`#accordion_header_${prevCurrentLimit}`);
      elm && elm.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Skipping first iteration (exactly like componentWillReceiveProps):
  const isReRenderedByAccordianFirstRun = useRef(true);
  useEffect(() => {
    if (isReRenderedByAccordianFirstRun.current) {
      isReRenderedByAccordianFirstRun.current = false;
      return;
    }

    const elementIndex = index.toString();
    if (elementIndex === elementClicked && isMultiExpandable) {
      toggleState();
    } else if (elementIndex !== elementClicked && !isMultiExpandable) {
      expanded && setExpanded(false);
    } else if (elementIndex === elementClicked && !isMultiExpandable) {
      toggleState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRenderedByAccordian]);

  //Skipping first iteration (exactly like componentWillReceiveProps):
  const isRefreshTabsFirstRun = useRef(true);
  useEffect(() => {
    if (isRefreshTabsFirstRun.current) {
      isRefreshTabsFirstRun.current = false;
      return;
    }

    setExpanded(!!defaultOpen);
    setFirstTimeLoad(!defaultOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTabs]);

  const toggleState = () => {
    GTMDataOnClickAccordian({ ...data, title, tabTitle });
    if (expanded) {
      setExpanded(false);
    } else if (firstTimeLoad) {
      setFirstTimeLoad(false);
      setExpanded(true);
    } else {
      setExpanded(true);
    }
  };

  const ssrRendered = (expanded, index, data, isRTE) => {
    if (!canUseDOM()) {
      return isRTE ? (
        <div className="ssr-rendered">
          <DynamicContent
            tagName="div"
            attrs={{
              'data-is-summary': false,
              className: expanded ? 'rte-field detailText' : 'hide rte-field',
              'aria-hidden': !expanded,
              id: `accordion_body_${index}`,
              'aria-labelledby': getAccordianListItemId(),
            }}
            innerHtml={data.detailRTE}
          />
        </div>
      ) : (
        <div
          data-is-summary="false"
          className={expanded ? 'rte-field detailText' : 'hide rte-field'}
          aria-hidden={!expanded}
        >
          {data.detailComponent}
        </div>
      );
    }
  };

  const getAccordianListItemId = () =>
    parentIndex !== undefined ? `accordion_header_${parentIndex}_${index}` : `accordion_header_${index}`;

  try {
    return (
      <li
        data-c-name="AccordianListItem"
        ref={listitemRef}
        role="tablist"
        className={
          expanded
            ? `selected accordian--list-item ${isMatchTenant(UIConfig.tenants.yi) && variant} `
            : `accordian--list-item  ${isMatchTenant(UIConfig.tenants.yi) && variant}`
        }
        data-index={index}
      >
        {isRTE ? (
          <DynamicContent
            tagName={checkParksTenants() ? 'h3' : 'div'}
            attrs={{
              'data-is-summary': true,
              className: 'rte-field summaryText',
              role: 'tab',
              tabIndex: '0',
              id: getAccordianListItemId(),
              'aria-controls': `accordion_body_${index}`,
              'aria-expanded': expanded,
              onKeyDown: focusHandlerOnAccordion,
            }}
            innerHtml={data.summaryRTE}
          />
        ) : (
          <div
            data-is-summary="true"
            className="rte-field summaryText heading-7"
            aria-expanded={expanded}
            role="tab"
            tabIndex="0"
            id={getAccordianListItemId()}
            onKeyPress={(e) => handlerOnKeyPress(e, index.toString(), getOrderDetail)}
            onKeyDown={focusHandlerOnAccordion}
          >
            {data.summaryComponent}
          </div>
        )}
        <SvgSprite id={expanded ? 'icn-arrow-up' : 'icn-arrow-down'} styleClass="chevron" />
        {firstTimeLoad ? null : isRTE ? (
          <DynamicContent
            tagName="div"
            attrs={{
              'data-is-summary': false,
              className: expanded ? 'rte-field detailText' : 'hide rte-field',
              'aria-hidden': !expanded,
              id: `accordion_body_${index}`,
              'aria-labelledby': getAccordianListItemId(),
            }}
            innerHtml={data.detailRTE}
          />
        ) : (
          <div
            data-is-summary="false"
            className={expanded ? 'rte-field detailText' : 'hide rte-field'}
            aria-hidden={!expanded}
          >
            {data.detailComponent}
          </div>
        )}
        {!canUseDOM() && ssrRendered(expanded, index, data, isRTE)}
      </li>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'AccordianListItem');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
AccordianListItem.propTypes = {
  data: PropTypes.shape({
    summaryRTE: PropTypes.string,
    detailRTE: PropTypes.string,
    summaryComponent: PropTypes.object,
    detailComponent: PropTypes.any,
  }),
  index: PropTypes.number,
  isMultiExpandable: PropTypes.bool,
  isRTE: PropTypes.bool,
  elementClicked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default AccordianListItem;
