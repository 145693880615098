/*
 * currency-converter-componet.js
 *
 * @author    SapientNitro
 * @licensor  Miral
 */
import React from 'react';

import { DynamicContent } from '../../base';
import DropDown from '../../drop-down';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * Cart function that renders the cart view and calls different functions for that
 * @param    {[Object]} props [data for rendering th eview].
 * @return   {[Object]} returns html .
 */
const CurrencyConverter = (props) => {
  try {
    const { currencySelectorAriaLabel = 'Change currency dropdown, currently selected currency' } = props;
    return (
      <div className="c-currency-converter">
        <DynamicContent tagName="span" attrs={{ className: 'currency-label' }} innerHtml={props.title} />
        <DropDown
          defaultValue={props.defaultCurrency}
          listItems={props.currencyList}
          callBackFunction={props.currencyCallback}
          ariaLabel={`${currencySelectorAriaLabel}`}
          isCurrencyConverter={props.isCurrencyConverter}
        />
        {!props.isCartPaymentView && (
          <DynamicContent tagName="p" attrs={{ className: 'terms body-copy-6' }} innerHtml={props.subTitle} />
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CurrencyConverter');
  }
};

export default CurrencyConverter;
