import UIConfig from '../UIConfig';
import { GLOBAL_VARS } from '../global-vars';
import { canUseDOM } from './window';
import { resolvePath } from './helpers';

export const detectIE = () => {
  if (canUseDOM()) {
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
  }
};

export const detectEdge = () => {
  if (canUseDOM()) {
    return /Edge\/\d+/.test(window.navigator.userAgent);
  }
};

export const detectBrowser = (browser) => {
  if (canUseDOM()) {
    return window.navigator.userAgent.toLowerCase().indexOf(browser) > -1;
  }
};

export const isIpad = () => {
  if (canUseDOM()) {
    return /iPad/.test(navigator.userAgent) && !window.MSStream;
  }
};

export const iOSDevice = () => canUseDOM() && /iPad|iPhone|iPod/.test(navigator.userAgent);

export const detectMobile = () => {
  return canUseDOM() && window.innerWidth <= 1023;
};

export const viewPortWidthCheck = (val) => {
  return canUseDOM() && window.innerWidth <= val;
};

const detectServerViewport = () => {
  switch (resolvePath(GLOBAL_VARS, 'deviceType')) {
    case 'phone':
      return UIConfig.viewportTypes.mobile;
    case 'tablet':
      return UIConfig.viewportTypes.tablet;
    case 'desktop':
      return UIConfig.viewportTypes.desktop;
    default:
      return UIConfig.viewportTypes.desktop_xl;
  }
};

export const detectViewPort = (ignoreServerViewPort = false) => {
  if (canUseDOM()) {
    if (typeof window !== 'undefined' && window.innerWidth <= 767) {
      return UIConfig.viewportTypes.mobile;
    } else if (typeof window !== 'undefined' && window.innerWidth < 1024) {
      return UIConfig.viewportTypes.tablet;
    } else if (typeof window !== 'undefined' && window.innerWidth < 1366) {
      return UIConfig.viewportTypes.desktop;
    } else {
      return UIConfig.viewportTypes.desktop_xl;
    }
  } else if (!ignoreServerViewPort) {
    return detectServerViewport();
  }
};

export const isDesktopViewPort = () => canUseDOM() && typeof window !== 'undefined' && window.innerWidth > 1024;

export const isMobileOrTab = () => canUseDOM() && /iPhone|iPad|iPod|Android|webOS/i.test(window.navigator.userAgent);

export const isTouchDevice = () => {
  return canUseDOM() && (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);
};

export const isSafariBrowser = () => {
  if (canUseDOM()) {
    const chrome = navigator.userAgent.indexOf('Chrome') > -1;
    const safari = navigator.userAgent.indexOf('Safari') > -1;
    return !chrome && !!safari;
  }
};

export const putElementInViewport = (el) => {
  if (!detectMobile() || !resolvePath(el, 'parentElement.scrollBy')) return false;

  const rect = el.getBoundingClientRect();

  if (rect.left < 0 && rect.right < 0) {
    el.parentElement.scrollBy(rect.right - rect.width - (window.innerWidth - rect.width) / 2, 0);
  } else if (rect.left < 0 && rect.right > 0) {
    el.parentElement.scrollBy(rect.left - (window.innerWidth - rect.width) / 2, 0);
  } else if (rect.left > window.innerWidth) {
    el.parentElement.scrollBy(rect.left + rect.width + (window.innerWidth - rect.width) / 2, 0);
  } else {
    el.parentElement.scrollBy(rect.right - window.innerWidth + (window.innerWidth - rect.width) / 2, 0);
  }
};

export const getZoomLevel = () => {
  let pixelRatio = window.devicePixelRatio;
  if (window.safari !== undefined) {
    pixelRatio = window.outerWidth / window.innerWidth;
  }
  return Math.round(pixelRatio * 100);
};

export const isZoomIn = () =>
  !isMobileOrTab() && getZoomLevel() >= 125 && ['mobile', 'tablet'].includes(detectViewPort());

export const isPopupZoomIn = () =>
  !isMobileOrTab() &&
  (isZoomIn() ||
    (getZoomLevel() > 200 && ['desktop-xl'].includes(detectViewPort())) ||
    (getZoomLevel() > 100 && ['desktop'].includes(detectViewPort())));

/**
 * Description: Get Android device
 */
export const isAndroid = () => navigator.userAgent.match(/android/i);

/**
 *
 * @param {String} selector: HTML element selector
 * @param {String} text: append text
 * @param {String} ElmClassName: class Name
 */
export const appendElement = (selector, ElmClassName, text) => {
  if (isAndroid()) {
    const node = document.createElement('span');
    const textnode = document.createTextNode(text);
    node.className = ElmClassName;
    node.appendChild(textnode);
    document.querySelector(selector).appendChild(node);
  }
};
