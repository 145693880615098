import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ApiWrapper from '../../../common/api-wrapper';
import { decodeQueryString, isMatchTenant, getCurrentLanguage, getLeisureFacility } from '../../../common/utility';
import { logComponentRenderingError, Logging } from '../../../common/logger';
import TextWithCTA from '../text-with-cta';
import UIConfig from '../../../common/UIConfig';

const Unsubscribe = (props) => {
  const [message, setMessage] = useState('Unsubscribing...');

  useEffect(() => {
    const { services } = props.data;
    const url = services && services.UnsubscribeAPI && services.UnsubscribeAPI.url;
    const errors = services && services.UnsubscribeAPI && services.UnsubscribeAPI.errors;

    const params = decodeQueryString();

    const unsubscribe = async () => {
      await ApiWrapper.apiGateway({
        url: `${url}/${params.comPrefId}`.replace(':email', params.emailId),
        method: 'PUT',
        data: {
          explicit_opt_in_out: 'N',
          implicit_opt_in_out: 'N',
        },
      })
        .then((res) => {
          setMessage(props.data.bodyCopy || '');
          Logging(res, 'Unsubscribe API', true, 'Unsubscription completed');
        })
        .catch((res) => {
          if (res.error) {
            const errorCode = res.error.code || res.error.errorCode;
            if (errors && errorCode && errors[errorCode]) {
              setMessage(errors[errorCode]);
            } else {
              setMessage('Unsubscription Failed');
            }
          }
          Logging(res, 'Unsubscribe API', false, 'Unsubscribe Api failed');
        });
    };
    const unsubscribeYMC = async () => {
      await ApiWrapper.apiGateway({
        url: url,
        method: 'POST',
        data: {
          communicationPreferencesIO: [
            {
              channelName: 'Website',
              channelType: 'Email',
              channelValue: params.emailId,
              contentType: 'Newsletter',
              language: getCurrentLanguage(),
              explicit_opt_in_out: 'N',
              implicit_opt_in_out: 'N',
              leisureFacility: getLeisureFacility() || '',
              comPrefId: params.comPrefId,
            },
          ],
        },
      })
        .then((res) => {
          setMessage(props.data.bodyCopy || '');
          Logging(res, 'Unsubscribe API', true, 'Unsubscription completed');
        })
        .catch((res) => {
          if (res.error) {
            const errorCode = res.error.code || res.error.errorCode;
            if (errors && errorCode && errors[errorCode]) {
              setMessage(errors[errorCode]);
            } else {
              setMessage('Unsubscription Failed');
            }
          }
          Logging(res, 'Unsubscribe API', false, 'Unsubscribe Api failed');
        });
    };

    if (url && params && params.emailId && params.comPrefId) {
      isMatchTenant(UIConfig.ymcB2CTenant) ? unsubscribeYMC() : unsubscribe();
    } else {
      setMessage('Missing URL or Query string parameters');
      logComponentRenderingError('Missing URL or Query string parameters', 'Unsubscribe');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = { ...props.data, bodyCopy: message };
  try {
    return (
      <div className="unsubscribe">
        <TextWithCTA data={data} />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Unsubscribe', props.data.variant);
  }
};

export default Unsubscribe;

Unsubscribe.propTypes = {
  data: PropTypes.shape({
    bodyCopy: PropTypes.string,
    title: PropTypes.object,
    primaryCTA: PropTypes.shape({
      tagHTML: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
    }),
    secondaryCTA: PropTypes.shape({
      tagHTML: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
    }),
    services: PropTypes.object,
  }),
};
