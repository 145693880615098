import {
  parseJwt,
  getLoggedInUser,
  getRenewTokenConfig,
  getBaseURL,
  setCookie,
  isB2B,
  getCookie,
  canUseDOM,
} from '../utility';
import ApiWrapper from '../api-wrapper';
import { Logging } from '../logger.js';
import UIConfig from '../UIConfig.js';
import { showLogoutReason } from './idle-timeout';

/* Method to set payload from the jwt token to the use object*/
export const setPayloads = (token, userData, renewTokenData) => {
  const payloads = parseJwt(token);
  userData.userName = payloads.given_name ? payloads.given_name : '';
  renewTokenData.expireAt = payloads.exp ? Number(payloads.exp) * 1000 : 0;
  userData.emails = payloads.emails ? payloads.emails : '';
  userData.userType = payloads.extension_userType ? payloads.extension_userType : '';
  userData.partnerId = payloads.extension_partnerId ? payloads.extension_partnerId : '';
  userData.agentId = payloads.extension_agentID ? payloads.extension_agentID : '';
  userData.marketType = payloads.extension_marketType ? payloads.extension_marketType : '';
  userData.lastName = payloads.family_name ? payloads.family_name : '';
  userData.yasId = payloads.sub ? payloads.sub : '';
  userData.showTarget = payloads.extension_showTarget && payloads.extension_showTarget === 'true' ? true : false;
};

export const clearLocalStorage = () => {
  let ignoreList = [
    'logging',
    'logoutLog',
    'logoutLoopCount',
    'mainObj',
    UIConfig.localStoreKeys.purchaseJourney.anonymousCart,
  ];

  for (const prop in localStorage) {
    if (ignoreList.indexOf(prop) === -1) {
      localStorage.removeItem(prop);
    }
  }

  for (const prop in sessionStorage) {
    sessionStorage.removeItem(prop);
  }
};

/*
Method to logout user,
    (Delete redis session
    Call .net API to clear .net session
    Redirect to Azure logout url)
*/
export const logoutCurrentUser = (logoutReason = 'User Action', showPopup = false, isRedirect = true) => {
  var userData = getLoggedInUser();

  if (showPopup) {
    const renewTokenConfig = getRenewTokenConfig();
    const logoutLogData = {
      reason: logoutReason,
      idleTimeOutButtonText: renewTokenConfig.idleTimeOutButtonText,
      idleTimeOutMessage: renewTokenConfig.idleTimeOutMessage,
      azureLogoutURL: userData?.loginInfo?.azureLogoutURL,
    };
    localStorage.logoutLog = JSON.stringify(logoutLogData);
  }

  if (userData && typeof userData === 'object') {
    const redisDelete = ApiWrapper.apiGateway({
      url: userData.loginInfo.sessionApiUrl,
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + userData.idToken,
      },
      moduleName: 'Logout - Delete redis session',
      reason: logoutReason,
    });

    const sessionDelete = ApiWrapper.platformService({
      url: userData.loginInfo.cmsLogoutUrl,
      method: 'POST',
      moduleName: 'Logout - Delete CMS session',
      reason: logoutReason,
    });

    const triggerLogout = (response) => {
      const loggingData = {},
        { userName, emails, agentId } = userData;
      Object.assign(loggingData, { userName, emails, agentId });
      loggingData.reason = logoutReason;
      if (response) {
        Logging(
          response,
          response.config && response.config.moduleName ? response.config.moduleName : '',
          true,
          response.config.reason,
        );
      }

      clearLocalStorage();

      const cookiesToRemove = [
        'idToken',
        'UserProfile',
        'wishlistID',
        'DetailsCookie',
        'AccessCode',
        'isCorpUserEnable',
      ];

      if (isB2B()) {
        cookiesToRemove.push('UserRole');
      }
      cookiesToRemove.forEach((cookieName) => {
        setCookie(cookieName, '', -1440, getBaseURL());
      });

      sessionStorage.removeItem('isSessionInit');

      if (userData?.loginInfo?.azureLogoutURL && isRedirect) {
        window.location.href = userData.loginInfo.azureLogoutURL;
      }
    };

    Promise.all([redisDelete, sessionDelete])
      .then((response) => {
        triggerLogout(response[1]);
      })
      .catch(() => {
        triggerLogout();
      });
  }
};

/* function to retrieve idToken and check if expired  */
export const isTokenExpired = () => {
  const token = getCookie('idToken');
  if (token) {
    const payloads = parseJwt(token);
    const expireAt = payloads.exp ? Number(payloads.exp) * 1000 : 0;

    // Expired
    if (Date.now() > expireAt) {
      if (canUseDOM()) {
        logoutCurrentUser('Token expired', true, false);
      }
      return true;
    }
    return false;
  }
  // In case there is no idToken
  return true;
};
