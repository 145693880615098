/*
 * radio-components.js
 * This file contains code for radio component which will render input based on schema.
 * @licensor  Miral
 */

import React, { useState, useEffect } from 'react';
import { DynamicContent } from '../../base';
import { validateJSSField } from '../rules-validator';
import { logComponentRenderingError } from '../../../../common/logger';
import FieldErrorComponent from '../field-error';
import classNames from 'classnames';
import { resolvePath } from '../../../../common/utility';

/**
 * Checkbox Component that will create radio or checkbox element as per data type
 * @param  {object} having all required attributes such as form data, events
 */
const Checkbox = (props) => {
  const { tracker, field, errors } = props,
    valueFieldName = field.valueField.name,
    valueFieldId = field.valueField.id,
    initialValue = resolvePath(field, 'model.value', false),
    inputType = 'Checkbox';

  const [fieldValue, setFieldValue] = useState(initialValue);
  const [errorMsg, setErrorMsg] = useState(errors);

  const updateValue = (e) => {
    if (!e.target.checked) {
      e.target.value = false;
    } else {
      e.target.value = true;
    }
  };

  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  const handleChange = (inputValue) => {
    let valid = true,
      errorMessages = [];
    const errorMessagesObj = validateJSSField({ ...field, type: inputType }, inputValue);
    const message = errorMessagesObj[valueFieldName];
    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }
    setFieldValue(inputValue);
    setErrorMsg(errorMessages);
    props.onChange(valueFieldName, inputValue, valid, errorMessages);
    tracker.onBlurField(field, inputValue, errorMessages);
  };

  const onChange = (e) => {
    handleChange(e.target.checked);
  };

  try {
    const isErrorExist = errorMsg && errorMsg.length > 0;
    const inputClasses = classNames('`body-copy-3', { error: isErrorExist });
    return (
      <div className={`form-element form-checkbox ${field.model.cssClass}`}>
        <label key={valueFieldName} className="form-label checkbox-label" htmlFor={valueFieldId}>
          <input
            className={inputClasses}
            name={valueFieldName}
            onChange={onChange}
            onClick={updateValue}
            value={fieldValue}
            id={valueFieldId}
            type={inputType}
            checked={fieldValue}
            aria-labelledby={isErrorExist && 'error_' + valueFieldName}
            aria-invalid={isErrorExist ? true : false}
            tabIndex="0"
          />
          <div className="form-checkbox-fancy"></div>
          <DynamicContent tagName="span" innerHtml={field.model.title} attrs={{ className: 'body-copy-2' }} />
        </label>
        <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Checkbox');
  }
};

export default Checkbox;
