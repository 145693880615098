import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import Image from '../../../presentation/base/image/image-component';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import TicketTooltip from '../../../presentation/b2c-purchase-journey/ticket-tooltip/ticket-tooltip';
import { canUseDOM, detectViewPort, deepCloneObject, isKeyboardNavigation } from '../../../../common/utility';
import Carousel from '../../../presentation/base/carousel';

export default class TicketSelector extends Component {
  constructor(props) {
    super(props);

    this.renderTicket = this.renderTicket.bind(this);
    this.fetchTicket = this.fetchTicket.bind(this);
    this.multiparkCarouselAccessibility = this.multiparkCarouselAccessibility.bind(this);
    this.selectedTicketIndex = props.data.tickets.options.findIndex((option) => option.coveoValue === props.tktVal);
    if (this.selectedTicketIndex < 0) this.selectedTicketIndex = 0;

    this.tktObj = props.data.tickets.options[this.selectedTicketIndex];
    this.tktObjData = props.data.tickets;
    this.state = {
      defaultTile: this.selectedTicketIndex,
      selectedPark: null,
      isTootipOpen: false,
      showMultiParkOptions: props.multiparkExpandable || props.panelDefaultStatus || Boolean(this.selectedTicketIndex),
    };

    this.carouselDiv = React.createRef();
  }
  fetchTicket(checked, data, park, index) {
    let selectedPark = {};
    park
      .map((each) => {
        return each.name;
      })
      .forEach((each) => {
        return (selectedPark[each] = true);
      });
    let defaultTile;

    defaultTile = index;
    this.setState({ selectedPark, defaultTile });
    let selectedTicket = data;
    this.props.setTicketParkData({ selectedPark, coveoValueTicket: selectedTicket && selectedTicket.coveoValue });
  }
  toggleMultipark = (e) => {
    this.setState({ showMultiParkOptions: !this.state.showMultiParkOptions });
  };
  preventPropogation = (e) => {
    e.stopPropagation();
  };
  componentWillReceiveProps(newProps) {
    if (newProps.componentState === 'noSelect') {
      const { selectedPark, defaultTicket } = this.loadDefaultTicket(this.props);
      this.props.setTicketParkData({ selectedPark, coveoValueTicket: defaultTicket.coveoValue });
      this.setState({ defaultTile: 0, selectedPark });
    }
  }

  sendBackAddtocartCta = (isOpen) => {
    this.setState({
      isTootipOpen: isOpen,
    });
  };

  /**
    * customCarouselSettings function to return the custom carousel settings properties
        and update in carausel settings
    * @param    {[data]} data object of the element
    * @return   {[Object]} Return custom carausel settings.
    */
  customCarouselSettings = (data, defaultSettings, props) => {
    if (!data) {
      return null;
    }
    return {
      className: `c-editorial-grid--item ${defaultSettings && defaultSettings.dots ? 'dot-enabled' : ''}`,
      dotsClass: 'custom-dots',
      customPaging: function(i) {
        return (
          <button aria-label={`${props.ariaLabelNavigation} ${i + 1}`}>
            <span className="slide-in-view">
              <span className="prefixed-zero">0</span>
              {defaultSettings && data.tickets.options && defaultSettings.rtl
                ? data.tickets.options.length - 1 - i
                : i + 1}
            </span>
            <span className="separator">/</span>
            <span className="total-slide">
              <span className="prefixed-zero">0</span>
              {data.tickets.options && data.tickets.options.length - 1}
            </span>
          </button>
        );
      },
    };
  };

  componentDidMount() {
    const { selectedPark, defaultTicket } = this.loadDefaultTicket(this.props);
    this.props.setTicketParkData({ selectedPark, coveoValueTicket: defaultTicket.coveoValue });
    this.setState({ selectedPark }, () => {
      setTimeout(() => {
        canUseDOM() &&
          this.props.tktVal &&
          document
            .getElementsByClassName('highlighted-ticket')[0]
            .scrollIntoView({ behaviour: 'instant', block: 'center' });
      }, 0);
    });
    if (isKeyboardNavigation() && this.props.data && this.state.showMultiParkOptions) {
      this.multiparkCarouselAccessibility();
    }
  }

  multiparkCarouselAccessibility() {
    let currentClass = this;
    const slidesToShow = this.settings.slidesToShow;
    setTimeout(function() {
      const slick = Object.values(currentClass.refs);
      const slidersElem = slick[0] && slick[0].querySelectorAll('.slick-track .slick-slide');
      let length = slidersElem.length - 1;
      const cloneSlidesNumber = slidesToShow * 2;
      const checkbox = slick[0].querySelectorAll('.slick-track .slick-slide .outter-wrapper .inner-wrapper .checkbox');
      const customDots = currentClass.settings.dots && slick[0].querySelectorAll('.slick-slider .custom-dots button');
      if (customDots) {
        for (let idx = 0; idx < customDots.length; idx++) {
          customDots[idx].setAttribute('tabindex', '-1');
        }
      }
      if (slidersElem.length > 0) {
        for (let i = 0; i < slidesToShow + 1; i++) {
          slidersElem[i].querySelector('.outter-wrapper').setAttribute('tabindex', '-1');
          slidersElem[i].querySelector('.c-ticket-tooltip a').setAttribute('tabindex', '-1');
          slidersElem[i].querySelector('.inner-wrapper .checkbox').setAttribute('tabindex', '-1');
          slidersElem[length].querySelector('.outter-wrapper').setAttribute('tabindex', '-1');
          slidersElem[length].querySelector('.c-ticket-tooltip a').setAttribute('tabindex', '-1');
          slidersElem[length].querySelector('.inner-wrapper .checkbox').setAttribute('tabindex', '-1');
          length--;
        }
      }
      for (let i = slidesToShow + 1; i < cloneSlidesNumber; i++) {
        checkbox[i + 1].removeEventListener('keydown', this.handleKeyDown);
        checkbox[i + 1].addEventListener('keydown', (e) => {
          currentClass.handleKeyDownAfterChange(e, currentClass.refs.slider.refs.slider.innerSlider, true);
        });
        if (canUseDOM() && !document.querySelector('body').classList.contains('lang-ar')) {
          checkbox[i + 2].removeEventListener('keydown', this.handleKeyDown);
          checkbox[i + 2].addEventListener('keydown', (e) => {
            currentClass.handleKeyDownAfterChange(e, currentClass.refs.slider.refs.slider.innerSlider);
          });
        }
      }
    }, 500);
  }

  componentWillMount() {
    if (canUseDOM()) {
      let customCarouselSettings = {};
      const viewport = detectViewPort();
      this.defaultSettings = { ...this.props.carousel, responsive: [] };
      customCarouselSettings = this.allViewSettings(customCarouselSettings, viewport);
      this.settings = Object.assign({}, deepCloneObject(this.defaultSettings), deepCloneObject(customCarouselSettings));
      if (viewport === 'mobile' || viewport === 'tablet') {
        this.settings.centerMode = true;
        this.settings.centerPadding = '24px';
      } else {
        this.settings.variableWidth = true;
      }
    }
  }

  /**
   * Slider after change onKeyDown
   */
  handleKeyDownAfterChange = (e, attrs, flag) => {
    const keyCode = e.which || e.keyCode;
    if (!e.shiftKey && keyCode === 9 && flag) {
      attrs.slickNext();
    } else if (e.shiftKey && keyCode === 9) {
      attrs.slickPrev();
    }
  };

  /*
   * Default carousel settings
   */
  allViewSettings = (customCarouselSettings, viewport) => {
    /* 2048 define the Maximum View of Desktop   */
    customCarouselSettings = this.customCarouselSettings(this.props.data, this.defaultSettings, this.props);
    customCarouselSettings = Object.assign(
      {},
      deepCloneObject(this.defaultSettings),
      deepCloneObject(customCarouselSettings),
    );
    let mobile = {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: true,
      },
    };
    let tablet = {
      breakpoint: 768,
      settings: {
        arrows: true,
      },
    };
    customCarouselSettings.responsive.push(mobile, tablet);
    //in case selected index is 0
    //initial slide in mobile should be 0 and 1 in desktop
    //but in case if selected index is > 0 then initial slide should remain as is
    const slideCount = 'mobiletablet'.indexOf(viewport) === -1 ? 2 : 1;
    customCarouselSettings.initialSlide = (this.selectedTicketIndex || slideCount) - 1;

    return customCarouselSettings;
  };

  renderMultiparkTickets(data, isTicketWithCTA, isSingleTicket, ariaLabelCheckbox) {
    return data.tickets.options.map((park, i) => {
      return (
        i !== 0 && (
          <div
            className={`c-multi-park-ticket-wrapper ${this.state.defaultTile === i ? 'highlighted-ticket' : ''}`}
            key={i}
          >
            {this.renderTicket(park, i, isTicketWithCTA, isSingleTicket, ariaLabelCheckbox)}
          </div>
        )
      );
    });
  }

  renderMultipark = (data, isTicketWithCTA, isSingleTicket, ariaLabelCheckbox) => {
    return (
      <div className="c-multi-park-panel without-carousel">
        {this.renderMultiparkTickets(data, isTicketWithCTA, isSingleTicket, ariaLabelCheckbox)}
      </div>
    );
  };
  renderCarouselMultipark = (data, isTicketWithCTA, isSingleTicket, ariaLabelCheckbox) => {
    return (
      <div className="carousel">
        <div className="c-multi-park-panel">
          <div className={`${this.props.carousel.cssClass} ${this.props.carousel.variant}`}>
            <div ref={this.carouselDiv} className="v-carousel-view-container clearfixnull">
              <Carousel ref="slider" data={this.settings} getCarouselDivRef={this.getCarouselDivRef}>
                {this.renderMultiparkTickets(data, isTicketWithCTA, isSingleTicket, ariaLabelCheckbox)}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getCarouselDivRef = () => {
    return this.carouselDiv;
  };

  loadDefaultTicket(props) {
    const selectedPark = {},
      { data } = props,
      selectedParkArr = Object.keys(this.tktObj).length ? this.tktObj.parks : data.tickets.options[0].parks;
    selectedParkArr.forEach((park, i) => {
      selectedPark[park.name] = true;
    });
    let defaultTicket = this.tktObj || data.tickets.options[0];
    return {
      defaultTicket,
      selectedPark,
    };
  }

  renderTicket(data, index, ...theArgs) {
    const tile = data;
    const info = tile && tile.info;
    const [isTicketWithCTA, isSingleTicket, ariaLabelCheckbox, addToCartText] = theArgs;
    return (
      <div className="outter-wrapper" tabIndex={isSingleTicket ? null : '0'}>
        <div className="inner-wrapper">
          <div className="selected-ticket">
            <DynamicContent tagName="div" attrs={{ className: 'title' }} innerHtml={tile.title} />
            <DynamicContent tagName="div" attrs={{ className: 'sub-title' }} innerHtml={tile.subTitle} />
            <DynamicContent tagName="div" attrs={{ className: 'description' }} innerHtml={tile.description} />
            <div className="visitor-type-wrapper">
              {tile.prices &&
                tile.prices.map((price, i) => {
                  return (
                    <div className="visitor-type" key={i}>
                      <DynamicContent tagName="span" attrs={{ className: 'normal' }} innerHtml={price.for} />
                      <DynamicContent tagName="span" innerHtml={price.from} />
                      <DynamicContent tagName="span" attrs={{ className: 'bold' }} innerHtml={price.currency} />
                      <DynamicContent tagName="span" attrs={{ className: 'bold' }} innerHtml={price.price} />
                    </div>
                  );
                })}
            </div>
          </div>
          {!isTicketWithCTA && isSingleTicket && (
            <div className="image-wrapper">
              <Image image={tile.image} disableLazyLoad={true} />
            </div>
          )}
          {isTicketWithCTA && isSingleTicket && (
            <div className="btn-primary c-general-admission-add-cart within-ticket">
              <Text
                tag="button"
                className="add-to-cart"
                onClick={this.props.callAddToCart}
                tabIndex={0}
                aria-label={`${tile.title} ${addToCartText}`}
                field={{ value: addToCartText, editable: addToCartText }}
              />
            </div>
          )}
          {isTicketWithCTA && !isSingleTicket && <div className="blank-spacer-20"></div>}
          {info && (
            <TicketTooltip
              title={info.title}
              subTitle={info.subTitle}
              description={info.description}
              parentCallback={this.sendBackAddtocartCta}
              tabIndex="0"
            />
          )}
          <label className="checkbox-label">
            <input
              type="radio"
              name="ticket-selection"
              value={tile.title}
              checked={this.state.defaultTile === index}
              onChange={(e) => this.fetchTicket(e.target.checked, data, tile.parks, index)}
            />
            <div
              className="checkbox"
              tabIndex="0"
              aria-label={data.ariaLabelCheckbox ? data.ariaLabelCheckbox : ariaLabelCheckbox}
              role="radio"
              aria-checked={this.state.defaultTile === index}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.fetchTicket(e.target.checked, data, tile.parks, index);
                }
              }}
            ></div>

            <span className="sr-only">checkbox-label</span>
          </label>
        </div>
      </div>
    );
  }

  renderOptions(data, selectedPark, isTicketWithCTA, addToCartText, ariaLabelDropdown, ariaLabelCheckbox) {
    const isSingleTicket = false;
    return (
      <div className="c-ticket-selector-park-selector c-ticket-selector" onClick={this.toggleMultipark}>
        <div
          className="heading-container ticket-box"
          onKeyDown={this.handleOnKeyPress}
          tabIndex="0"
          aria-controls={data.parks.sectionName}
        >
          <div className="text-wrapper">
            {' '}
            <DynamicContent
              tagName="div"
              attrs={{ className: 'title', id: `title_${data.parks.sectionName}` }}
              innerHtml={data.parks.title}
            />
            <DynamicContent tagName="div" attrs={{ className: 'description' }} innerHtml={data.parks.description} />
          </div>
          <div className="checkbox-wrapper">
            {data.parks.options.map((park, i) => {
              return (
                i !== 0 && (
                  <div className="checkbox-container" key={i}>
                    <Image image={park.image} disableLazyLoad={true} />
                  </div>
                )
              );
            })}
          </div>
        </div>
        <label htmlFor="showMultiParkOptions" className="multi-park checkbox-label">
          <div
            className="chevronDown"
            tabIndex="0"
            role="button"
            aria-label={data.tickets.ariaLabelDropdown ? data.tickets.ariaLabelDropdown : ariaLabelDropdown}
            aria-expanded={this.state.showMultiParkOptions}
            aria-controls="parks"
            onKeyDown={this.handleOnKeyPress}
          >
            <div className={`toggle-panel ${this.state.showMultiParkOptions ? 'open' : 'closed'}`}> </div>
          </div>
        </label>

        {this.state.showMultiParkOptions && (
          <div
            className="c-multi-park-section"
            onClick={this.preventPropogation}
            id={data.parks.sectionName}
            aria-labelledby={`title_${data.parks.sectionName}`}
          >
            <div className="divider"></div>
            {this.tktObjData.enableCarousel && data.tickets.options.length > 3
              ? this.renderCarouselMultipark(data, isTicketWithCTA, isSingleTicket, ariaLabelCheckbox)
              : this.renderMultipark(data, isTicketWithCTA, isSingleTicket, ariaLabelCheckbox)}

            {isTicketWithCTA && (
              <div
                className={`btn-primary c-general-admission-add-cart within-ticket
                                ${this.state.isTootipOpen ? ' send-back-cta' : ''}`}
              >
                <button className="add-to-cart" onClick={this.props.callAddToCart}>
                  {addToCartText}
                </button>
              </div>
            )}
            <DynamicContent
              tagName="div"
              attrs={{ className: 'multi-park-foot-note-title description' }}
              innerHtml={data.parks.multiParkTitle}
            />
            <DynamicContent
              tagName="div"
              attrs={{ className: 'multi-park-foot-note-description description' }}
              innerHtml={data.parks.multiParkDescription}
            />
          </div>
        )}
      </div>
    );
  }
  /**
   * Handle on key press
   * @param {Object} event object
   */

  handleOnKeyPress = (event) => {
    let keyCode = event.keyCode;
    if (keyCode === 13 || keyCode === 32) {
      this.toggleMultipark();
      this.multiparkCarouselAccessibility();
    }
  };

  render() {
    const { data, ariaLabelCheckbox, ariaLabelDropdown } = this.props;
    const isSingleTicket = true;
    return (
      <div className="c-ticket-selector-wrapper" ref={this}>
        <Text
          tag="div"
          className="c-ticket-selector-title body-copy-1"
          field={{ value: data.tickets.title, editable: data.tickets.title }}
        />
        <div className={`c-ticket-selector ticket-box ${this.state.defaultTile === 0 ? 'highlighted-ticket' : ''}`}>
          <div className="c-ticket-selector-selected-ticket-wrapper">
            {data.tickets &&
              this.renderTicket(
                data.tickets.options[0],
                0,
                data.isTicketWithCTA,
                isSingleTicket,
                ariaLabelCheckbox,
                data.addToCartText,
              )}
          </div>
          <DynamicContent
            tagName="div"
            attrs={{ className: 'information-note' }}
            innerHtml={data.tickets.options[0].informationNote}
          />
        </div>
        {this.state.selectedPark &&
          data.tickets.options.length > 1 &&
          this.renderOptions(
            data,
            this.state.selectedPark,
            data.isTicketWithCTA,
            data.addToCartText,
            ariaLabelDropdown,
            ariaLabelCheckbox,
          )}
      </div>
    );
  }
}

TicketSelector.PropsTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
};

TicketSelector.defaultProps = {
  ariaLabelNavigation: 'Press enter to select navigation',
  ariaLabelCheckbox: 'Press enter to select ticket',
  ariaLabelDropdown: 'View More tickets',
};
