import React from 'react';
import DynamicContent from '../../../../presentation/base/dynamic-content/dynamic-content-component';
import { logComponentRenderingError } from '../../../../../common/logger';
import { checkTenant } from '../../../../../common/utility';
import UIConfig from '../../../../../common/UIConfig';

const ADCBTouchPointPopUp = ({ popUpMessage, acceptButtonText, discardButtonText, checkPayment }) => {
  const handleAcceptance = () => {
    checkPayment(true);
    window.PubSub.publish('toggleOverlayState', { shouldOpen: false });
  };

  const handleRejection = () => {
    window.PubSub.publish('toggleOverlayState', { shouldOpen: false });
    checkPayment(false);
  };

  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const buttonWrapper = () => {
    return (
      <>
        <div className="remove-btn btn-primary">
          <DynamicContent
            tagName="button"
            attrs={{
              onClick: () => handleAcceptance(),
              'aria-label': acceptButtonText,
            }}
            innerHtml={acceptButtonText}
          />
        </div>
        <div className="cancel-btn btn-secondary">
          <DynamicContent tagName="button" attrs={{ onClick: handleRejection }} innerHtml={discardButtonText} />
        </div>
      </>
    );
  };

  try {
    return (
      <div className="overlay-data">
        {popUpMessage && (
          <DynamicContent
            tagName="h3"
            attrs={{ className: 'overlay-title heading-4', id: 'overlay-title' }}
            innerHtml={popUpMessage}
          />
        )}
        {/* {popUpMessage && (
          <DynamicContent tagName="p" attrs={{ className: 'overlay-bodyCopy body-1' }} innerHtml={popUpMessage} />
        )} */}
        {isSwad ? <div className="btn-wrapper">{buttonWrapper()}</div> : <>{buttonWrapper()}</>}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ADCBTouchPointPopUp');
  }
};

export default ADCBTouchPointPopUp;
