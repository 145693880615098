/*
 * order-container-mini-components.js
 * This file contains code for Order Mini component, It is exapned view when user click on Arrow button
 * @author SapientNitro
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import DynamicContent from '../../presentation/base/dynamic-content/dynamic-content-component';
import TableRow from '../../presentation/table-row/table-row-component';
import ETicketDownload from '../../presentation/eticket-download/eticket-download';
import { getLoggedInUser } from '../../../common/utility';
import moment from 'moment';
import UIConfig from '../../../common/UIConfig';
import { OrderService } from '../../../common/services';
import { blobToPdf, getErrorMap, resolvePath } from '../../../common/utility';

import './order-detail-mini-component.scss';

const invokeDownload = (props) => {
  const {
    labelConfig: { eticket },
  } = props;
  if (resolvePath(eticket, 'language', '') && eticket.language.length === 1) {
    downloadeTicket(props);
  } else {
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      dataToAppend: (
        <ETicketDownload
          services={props.labelConfig.services}
          url={
            props.labelConfig.services && props.labelConfig.services.eTicketDownload
              ? props.labelConfig.services.eTicketDownload.url
              : ''
          }
          data={props.labelConfig.eticket}
          orderId={props.orderId}
          tenantId={getLoggedInUser().tenantID}
          marketType={props.orderDetail.orderDetailData.orderData.marketType}
          notEncrypted={true}
        />
      ),
      customClass: 'eticket-overlay',
    });
  }
};
const downloadeTicket = (props) => {
  const {
    labelConfig: { services, eticket },
    orderId,
    orderDetail,
  } = props;
  const language = eticket.language[0].value,
    quantity = eticket.selectedQuant,
    data = {
      orderId: orderId,
      language: language,
      tenantId: getLoggedInUser().tenantID,
      marketType: orderDetail.orderDetailData.orderData.marketType,
      ticketCountPerPage: quantity,
    };
  let url = services && services.eTicketDownload ? services.eTicketDownload.url : '';
  const downloadTicket = OrderService.downloadOrder(JSON.stringify(data), url, 'blob', true, '.eticket-overlay', true);

  downloadTicket
    .then((response) => {
      const fileName = eticket.orderId + '_' + language + '.pdf';
      blobToPdf(response, fileName);
    })
    .catch((response) => {
      const errorObj = getErrorMap(
        'eTicketDownload',
        services.eTicketDownload.errors,
        false,
        response.error,
        this.state.errorData,
      );
      this.setState({ errorData: errorObj });
    });
};

const downloadInvoice = (props) => {
  let data = {
    orderId: props.orderId,
    tenantId: getLoggedInUser().tenantID,
  };
  let url =
    props.labelConfig.services && props.labelConfig.services.invoiceDownload
      ? props.labelConfig.services.invoiceDownload.url
      : '';
  const invoiceService = OrderService.downloadOrder(
    JSON.stringify(data),
    url,
    'blob',
    true,
    '.c-order-detail-mini',
    true,
  );

  invoiceService
    .then((response) => {
      const fileName = data.orderId + '_' + props.labelConfig.eticket.invoiceLabel + '.pdf';
      blobToPdf(response, fileName);
    })
    .catch((response) => {
      const errorObj = getErrorMap(
        'invoiceDownload',
        this.props.services.invoiceDownload.errors,
        false,
        response.error,
        this.state.errorData,
      );
      this.setState({ errorData: errorObj });
    });
};

const renderOrderItems = (props) => {
  const rows = [],
    dateFormat = UIConfig.dateDisplayFormat,
    userType = getLoggedInUser().userType;
  let viewOrderCTA = null,
    downloadTicketCTA = null,
    downloadInvoiceCTA = null;

  const checkBulkQuantity =
    props?.orderDetail.orderDetailData.data?.length > 0 &&
    Number(props?.orderDetail.orderDetailData.data[0].quantity) <= Number(props.labelConfig.orderDetail.maxTickets);
  props.orderDetail.orderDetailData.data.forEach((row, idx) => {
    rows.push(
      <TableRow
        key={idx}
        columns={props.orderDetail.orderDetailData.columns.slice(1)}
        row={{ ...row, className: 'table-row ' + row.className }}
      />,
    );
  });
  if (props.orderDetail.orderDetailData.orderData.status === 'Approved') {
    rows.push(
      <tr className="single-row">
        <td className="statussection" colSpan="2">
          <span className="statuslabel">{props.labelConfig.orderDetail.statusLabel.dateOfApproval}</span>
          <span className="statusvalue body-copy-4">
            {moment(props.orderDetail.orderDetailData.orderData.updatedDate).format(dateFormat)}
          </span>
        </td>
        <td className="statussection" colSpan="2">
          <span className="statuslabel">{props.labelConfig.orderDetail.statusLabel.approvedBy}</span>
          <span className="statusvalue body-copy-4">{props.orderDetail.orderDetailData.orderData.updatedBy}</span>
        </td>
      </tr>,
    );
  }
  if (props.orderDetail.orderDetailData.orderData.status === 'Declined') {
    rows.push(
      <tr className="single-row">
        <td className="statussection declinedreason" colSpan="4">
          {props.orderDetail.orderDetailData.orderData.declinedReason}
        </td>
      </tr>,
    );
    rows.push(
      <tr className="single-row">
        <td className="statussection" colSpan="2">
          <span className="statuslabel">{props.labelConfig.orderDetail.statusLabel.declinedBy}</span>
          <span className="statusvalue body-copy-4">{props.orderDetail.orderDetailData.orderData.updatedBy}</span>
        </td>
        <td className="statussection" colSpan="2">
          <span className="statuslabel">{props.labelConfig.orderDetail.statusLabel.dateOfDecline}</span>
          <span className="statusvalue body-copy-4">
            {moment(props.orderDetail.orderDetailData.orderData.updatedDate).format(dateFormat)}
          </span>
        </td>
      </tr>,
    );
  }
  if (userType.toLowerCase() !== 'super admin') {
    viewOrderCTA = (
      <DynamicContent
        tagName="a"
        innerHtml={props.labelConfig.orderDetail.viewOrderCta.label}
        attrs={{
          href: `${props.labelConfig.orderDetail.viewOrderCta.url}?status=true&oId=${props.orderId}&notEncrypted=true&isOrderHistoryPayment=true`,
        }}
      />
    );
    downloadTicketCTA = (
      <DynamicContent
        tagName="button"
        innerHtml={props.labelConfig.orderDetail.downloadCta.label}
        attrs={{ onClick: invokeDownload.bind(this, props) }}
      />
    );
  }
  downloadInvoiceCTA = (
    <DynamicContent
      tagName="button"
      innerHtml={props.labelConfig.orderDetail.downloadInvoice.label}
      attrs={{ onClick: downloadInvoice.bind(this, props), className: 'download-invoice' }}
    />
  );
  rows.push(
    <tr className="single-row">
      <td colSpan="4">
        {props.orderDetail.orderDetailData.orderData.status === 'Approved' && checkBulkQuantity && (
          <div className="btn btn-secondary">{viewOrderCTA}</div>
        )}
        {props.orderDetail.orderDetailData.orderData.status === 'Pending' && (
          <div className="btn btn-secondary">{viewOrderCTA}</div>
        )}
        {props.orderDetail.orderDetailData.orderData.status === 'Approved' && (
          <>
            <div className="btn btn-secondary">{checkBulkQuantity && downloadTicketCTA}</div>
            <div className="btn btn-secondary">{downloadInvoiceCTA}</div>
          </>
        )}
        {props?.orderDetail.orderDetailData.data?.length > 0 &&
          props.orderDetail.orderDetailData.orderData.isBulkOrder &&
          props.orderDetail.orderDetailData.orderData.status === 'Approved' &&
          !checkBulkQuantity && (
            <p className="invoiceInfo">
              {props?.labelConfig?.orderDetail?.bulkPurchaseDownloadLabel}{' '}
              <a href={`mailto:${getLoggedInUser().email}`}>{getLoggedInUser().email}</a>
            </p>
          )}
      </td>
    </tr>,
  );
  return rows;
};

const OrderDetailMini = (props) => {
  return (
    <tr className={'c-order-detail-mini c-order-table-row ' + props.className}>
      <td colSpan={8}>
        <div className="c-order-detail-mini-table">
          <table>
            <caption>
              <span className="agent-number">{props.orderDetail.orderDetailData.agentLabel}</span>
              {props.orderDetail.orderDetailData.agentRef}
            </caption>
            <thead className="detail-header">
              <tr className="c-order-detail-mini--row">
                {props.orderDetail.orderDetailData.columns.slice(1).map((column, idx) =>
                  column.label ? (
                    <th key={idx} className={'column-' + idx}>
                      {column.label}
                    </th>
                  ) : null,
                )}
              </tr>
            </thead>
            <tbody>{renderOrderItems(props)}</tbody>
          </table>
        </div>
      </td>
    </tr>
  );
};
export default OrderDetailMini;

/**
 * Used to define the proptypes that will be received by the component.
 */

OrderDetailMini.propTypes = {
  props: PropTypes.shape({
    orderDetail: PropTypes.object,
  }),
};
