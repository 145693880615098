/* global TabbyCard */
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Logging } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import {
  generateGUID,
  getCurrentLanguage,
  getUserAgent,
  parseQueryString,
  scrollToView,
  getLoggedInUser,
  createCustomEvent,
  isEnterPressed,
  checkShippingDetails,
  isLoggedInUser,
  resolvePath,
  detectViewPort,
  deepCloneObject,
  canUseDOM,
  toTwoDecimalPlaces,
  sortInstallments,
  handleEnterKey,
  getRecipientDetails,
  isEmpty,
  mycartError,
  getSessionStorage,
  getMainObject,
  getMembershipDetails,
  isArabicMode,
  isYasArenaJourney,
  checkTenant,
  isMatchTenant,
  checkPaymentType,
  checkParksTenants,
  getLocalStorageByKey,
  setSessionStorage,
  getLanguageWithoutLocal,
  toLowerCase,
  isAnnualPassCase,
  checkIfGiftVoucherIsInTheCart,
  isTicketUpgradePayment,
  isANPUpgradation,
} from '../../../../common/utility';
import Button from '../../../presentation/base/button/button-component.js';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import Image from '../../../presentation/base/image/image-component';
import RichText from '../../../presentation/base/rich-text/rich-text-component';
import Form from '../../form/form-component';
import PayfortInput from './payfort-input-fields';
import PayfortUtility from './payfort-utility';
import GTMData from '../../b2c-purchase-journey/gtm-data';
import { getValidityFormatted } from '../../../../common/payment-action/actions';
import classNames from 'classnames';
import ApiWrapper from '../../../../common/api-wrapper';
import Carousel from '../../../presentation/base/carousel';
import { CartService, ProfileServices } from '../../../../common/services';
import { checkoutAnalytics } from '../../../../common/analytics-events';

import {
  scrollToServerError,
  isThereExtraErrors,
  getPayfortTypeNameID,
  consentRequest,
  checkAnnualPassError,
  checkAnnualUpgradeError,
} from '../utils';
import { showLimitError } from '../../b2c-purchase-journey/payment/helper';
import { isParkTenant } from '../../../../common/utility/tenantsUtils.js';
// import Radio from "../../../presentation/base/radio/radio-component.js";
/**
 * Payfort Class ( which extends the React.Component) contains the
 * functionality to amke payment through credit card
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */
class Payfort extends Component {
  constructor(props) {
    super(props);
    this.updatedPayLoad = null;
    this.merchantReference = this.getReservationCode();
    this.merchantExtra = '';
    this.merchantExtra1 = '';
    this.cardNumberRef = null;
    this.lang = this.paymentLanguage();
    this.orderId = this.getOrderId();
    this.serviceKeys = {
      tokenization: 'tokenization',
    };
    this.emailConfirmMessage = this.props?.data?.paymentOptions?.paymentLabels?.emailConfirmMessage?.replace(
      '{email}',
      '',
    );
    this.confirmedEmail = '';
    this.payfortData = this.props.is2StepPayment ? this.props.payData : this.getpayfortData();
    this.thankYouPageUrl = this.payfortData.thankYouPageUrl;
    this.paymentData = this.props.is2StepPayment
      ? this.props.payData
      : JSON.parse(window.localStorage.getItem(UIConfig.localStoreKeys.payment.payFortData));
    this.paymentData.isFrictionlessPayfort = this.props.isFrictionlessPayfort;
    this.isB2B = getLoggedInUser().tenantID === 'ALLB2B';
    this.isYmcTenant = getLoggedInUser().tenantID === 'YMCB2C';
    this.tenantID = getLoggedInUser().tenantID;
    this.issuer_code = '';
    this.installments = resolvePath(this.props.payFordata, 'installmentData.labelData.installments', '');
    this.thresholdAmount = resolvePath(this.props.payFordata, 'installmentData.thresholdAmount', 0);
    this.plan_code = '';
    this.paymentType = this.props.payFordata?.paymentOptions?.paymentTypes?.find(
      (item) => item.value === UIConfig.paymentOption.tabby,
    );
    // this might be configured in the future
    // this.showTabbyCard = this.paymentType.showTabbyCard;
    this.showTabbyCard = false;
    this.payfortType = this.props.selectedPayfortType;
    // here we define the payfort Types, tabby, ...
    this.isTabby = this.payfortType === UIConfig.paymentOption.tabby;
    this.tabbyReturnUrl = this.isTabby
      ? `${window.location.origin}/${getLanguageWithoutLocal()}${this.props.payFordata.tabbyReturnUrl ??
          '/Home/TabbyPayment'}`
      : '';
    if (!this.props.is2StepPayment) {
      if (this.merchantReference && this.orderId) {
        //Commenting this line for a hotfix for payment multilingual issue. Hardcoding the language as en by default as of now.
        this.initializeMerchantRef();
      } else {
        this.redirectToErrorPage(this.serviceKeys.tokenization);
      }
    }
    this.cardType = '';

    if (this.props.is2StepPayment) {
      const formData = {
        sections: [{ fields: !this.isTabby && this.paymentData.paymentOptions.fields }],
      };

      this.state = {
        data: formData,
        enablePaymentButton: true,
        B2BPaymentEnable: false,
        changeCardError: false,
        showTermsError: false,
        installmentAvailable: [],
        showBankImages: false,
        showInstallmentWrapper: false,
        payfortInstallmentWrapper: checkParksTenants() ? false : true,
        selectedInstallmentData: {},
        selectedInstallment: 0,
        processingFessMessage: '',
        termsAndCond: '',
        isPaymentLoader: false,
        isMultiParkConsentChecked: false,
      };
      this.isEnabled = false;
      this.customErrorObj = {
        dateError: false,
        cardErrors: false,
      };
    }
  }
  paymentLanguage = () => {
    const langWithLocal = getLanguageWithoutLocal();
    if ([UIConfig.languages.arLang, UIConfig.languages.enLang].includes(langWithLocal)) return langWithLocal;
    else return UIConfig.languages.enLang;
  };
  initPayfortComponent = (props) => {
    this.userAgent = getUserAgent();
    if (this.isB2B) {
      window.PubSub.subscribe('input_filled', (item, obj) => {
        this.setState({ B2BPaymentEnable: obj.isEnabled });
      });
    }

    let __JSS_STATE__ = null;
    let trueClientIP = '';
    const ssrRawJson = document.getElementById('__JSS_STATE__');
    const isVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
    if (ssrRawJson) {
      __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
      trueClientIP = (__JSS_STATE__ && __JSS_STATE__.trueClientIP) || '';
    }

    this.paymentInfo = {
      cmsData: this.paymentData.is2StepPayment ? props.payFordata : props.data,
      is2StepPayment: this.paymentData.is2StepPayment,
      price: this.payfortData.total,
      currency: this.payfortData.currency,
      language: this.lang,
      orderId: this.orderId,
      merchantReference: this.generatedMerchantReference,
      actualMerchantReference: this.merchantReference,
      emailTemplateId: isVoucher
        ? props.payFordata.paymentOptions.giftVoucherSenderEmailTemplate
        : this.payfortData.emailTemplateId,
      invoiceTemplateId: this.payfortData.invoiceTemplateId ? this.payfortData.invoiceTemplateId : ' ',
      marketType: this.payfortData.marketType,
      thankYouPageUrl: this.thankYouPageUrl,
      notEncrypted: this.payfortData.notEncrypted,
      isFrictionlessPayfort: props.isFrictionlessPayfort,
      trueClientIP: __JSS_STATE__ && __JSS_STATE__.trueClientIP,
      payfortType: this.payfortType,
    };

    if (this.isB2B) {
      this.paymentInfo['paymentFailureUrl'] = props.payFordata.services.UpdatePaymentStatus.url;
      this.paymentInfo['paymentErrorCode'] = props.payFordata.services.UpdatePaymentStatus.errors;
    }

    //Commenting this check as it is creating issues when i use ipv6! Beware Dragons here!!
    /*if(paymentData.cmsData.customerIP.indexOf(":") > 0) {
            paymentData.cmsData.customerIP = paymentData.cmsData.customerIP.substring(0, paymentData.cmsData.customerIP.indexOf(":"));
        }*/

    localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(this.paymentInfo));
    if (!this.paymentData.is2StepPayment) {
      this.adjustHeight();
      this.payFortForm.submit();
    }
    if (this.paymentData.is2StepPayment && !this.isTabby) {
      this.cardInputInitializer();
      this.expiryInputInitializer();
      this.cvvInputInitializer();
      document.getElementById('cardNumber') &&
        document.getElementById('cardNumber').addEventListener('paste', this.handleCardNumberPaste);
      this.dateValidationInitialize();
      this.requiredFields = document.getElementsByClassName('required--field');
      this.allowPayment = false;
      window.PubSub.subscribe(UIConfig.events.ON_ADDING_PROMOCODE, (code, msg) => {
        this.recheckCardOnCouponChange(msg);
      });
      window.PubSub.subscribe(UIConfig.events.ON_REMOVING_PROMOCODE, this.removeInvalidCardMsg);
      window.PubSub.subscribe('checkPayButtonStatus', this.verifyPayfortForm);
      this.handlePayButtonSpacing();
    }
    Logging(this.paymentData, 'Payfort-form-post', true, 'Step 0');
    window.PubSub.subscribe('CreateOrderSuccess', this.createOrderSuccessCallback);
    const { usePayfortWithADCB, usePayfortWithMoneyCard } = props;
    if (usePayfortWithADCB || usePayfortWithMoneyCard) {
      window.PubSub.subscribe('SubmitDataForm', (msg, data) => {
        window.PubSub.unsubscribe('CreateOrderSuccess');
        this.cardDetails = data.cardDetails;
        this.allowPayment = true;
        window.PubSub.subscribe('CreateOrderSuccess', this.createOrderSuccessCallback);
        this.submitData(null);
      });
    }
  };
  showLimitError = () => showLimitError(this);
  tabbyLimitError = () => {
    if (!this.showLimitError()) {
      this.props.isErrorOccured(UIConfig.limitErrorsKeys.tabbyLimit, '', false, true);
      localStorage.removeItem(UIConfig.localStoreKeys.tabbyLimitError);
    } else {
      localStorage.setItem(UIConfig.localStoreKeys.tabbyLimitError, true);
    }
  };
  componentDidMount() {
    this.initPayfortComponent(this.props);
    sessionStorage.setItem('isMultiParkConsent', false);
    if (this.isTabby) {
      window.PubSub.subscribe(UIConfig.events.ONCARTUPDATETABBY, this.tabbyLimitError);
      if (this.showLimitError()) localStorage.setItem(UIConfig.localStoreKeys.tabbyLimitError, true);
      if (isLoggedInUser()) {
        ProfileServices.GetGuestInfo(
          this.payfortType,
          this.props.payFordata.services.getGuestProfile.url,
          true,
          UIConfig.loader.defaultPreLoaderTarget,
        ).then((response) => {
          if (response?.data && response?.data?.mobileNum?.startsWith(response?.data?.countryCode)) {
            this.updatedMobileNum = response?.data?.mobileNum?.replace(response?.data?.countryCode, '');
          }
        });
      }
    }

    window.PubSub.subscribe('confirmEmailFire', (msg, data) => {
      if (isMatchTenant(UIConfig.YIB2C)) {
        sessionStorage.setItem('confirmedEmail', data?.contactEmail);
        // let emailMessageToShow = this.props?.payFordata?.paymentOptions?.paymentLabels?.emailConfirmMessage?.replace("{email}" , data?.contactEmail)
        this.setState({
          confirmedEmail: data?.contactEmail,
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.isTabby) {
      this.setState({ tabbyLimitError: false });
      this.showTabbyCard && this.tabbyCardScript && document.body.removeChild(this.tabbyCardScript);
    }
  }
  handlePayButtonSpacing = () => {
    const newsletterCheckbox = document.getElementById('paymentNewsletter');
    const submitBtn = document.getElementById('payfortSubmitBtn');
    if (!newsletterCheckbox) {
      submitBtn && submitBtn.classList.add('pay-btn-alignment');
    }
  };

  dateValidationInitialize = () => {
    const today = new Date();
    this.currentMonth = today.getMonth();
    this.currentYear = today
      .getFullYear()
      .toString()
      .substr(-2);
    const validationObject = this.paymentData.paymentOptions.fields.filter((value) => {
      return value.id === 'validity';
    });
    const dateValidationErrorArray = validationObject[0].validations.filter((value) => {
      return value.regex === '';
    });
    this.dateValidationError = dateValidationErrorArray[0].error;
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ enablePaymentButton: nextProps.enablePaymentButton }, () => {
        this.paymentData.is2StepPayment && this.enableDisableOnPaymentButton();
      });
    }
  }

  recheckCardOnCouponChange = (code) => {
    this.validateCardCouponCombo(code);
    this.verifyPayfortForm();
  };

  removeInvalidCardMsg = () => {
    const cardErrors = document.getElementById('cardErrors');
    const couponFailedError = document.getElementById('couponFailedError');
    if (cardErrors) {
      cardErrors.remove();
    }
    if (couponFailedError) {
      this.setState({
        changeCardError: false,
      });
    }
    this.customErrorObj.cardErrors = false;
    this.handleCardTypeMessage();
    this.verifyPayfortForm();
  };

  initializeMerchantRef = () => {
    this.generatedMerchantReference = this.createMerchantReference();
    this.signature = this.getSignature();
  };

  handleCardNumberPaste = (e) => {
    let pastedValue = (e.clipboardData || window.clipboardData).getData('text');
    this.handleCardImage(pastedValue);
  };

  createMerchantReference = () => {
    if (this.generatedMerchantReference && this.isTabby) {
      return this.generatedMerchantReference;
    } else {
      return (this.generatedMerchantReference = this.getDynamicMerchantReference());
    }
  };

  getDynamicMerchantReference = () => {
    if (this.tenantID.toLowerCase() === UIConfig.YIB2C && !this.paymentData.isFrictionlessPayfort) {
      return this.merchantReference;
    } else {
      const specialChar = '_';
      let generatedMerRef = generateGUID() + specialChar + this.merchantReference;

      /*
            if length is greater than 40 payfort will fail
            if we cut the merchatnRef then reconcile will fail,hence snipping guid as a fail safe
            however it still may fail is res code is more than 39(_ + 39)
        */
      if (generatedMerRef.length > 40) {
        generatedMerRef = specialChar + this.merchantReference;
      }
      if (this.props.isFrictionlessPayfort) {
        generatedMerRef = generateGUID();
      }
      return generatedMerRef;
    }
  };

  adjustHeight = () => {
    this.refs.payfort_iframe.style.height = window.innerHeight + 'px';
  };

  getLanguage = () => {
    if (this.lang) {
      return this.lang;
    } else {
      return (this.lang = getCurrentLanguage());
    }
  };

  getSignature = () => {
    if (this.signature) {
      return this.signature;
    } else {
      this.signature = this.generateSignature();
    }
    return this.signature;
  };

  getpayfortData = () => {
    if (this.payfortData) {
      return this.payfortData;
    } else {
      const data = JSON.parse(window.localStorage.getItem(UIConfig.localStoreKeys.payment.payFortData));
      if (data) {
        this.payfortData = data;
      } else {
        PayfortUtility.clearLocalStore();
        window.location.href = '/';
      }
      // mockdata:localStorage.setItem("payfort_data" , JSON.stringify({"total":"1200","emailTemplateId":"abc","currency":"AED",
      // "marketType":"Local",
      //"thankYouPageUrl":"http://localhost:3000/component-testing/client-side-rendering/thankyou-page.html"}))
    }
    return this.payfortData;
  };
  generateSettlementReference = () => {
    const payFortData = this.paymentData.is2StepPayment ? this.props.payFordata : this.props.data;
    const settlmentData = {
      access_code: payFortData.accessCode,
      language: this.lang,
      merchant_identifier: payFortData.merchantIdentifier,
      merchant_reference: this.generatedMerchantReference,
    };
    return PayfortUtility.getPayFortSignature(settlmentData, payFortData.merchantPassphrase).substring(0, 16);
  };
  generateSignature = () => {
    let email, mobileNum;
    if (isLoggedInUser()) {
      email = getLoggedInUser().email;
      mobileNum = getLoggedInUser().mobileNum;
    } else if (
      localStorage.getItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED) &&
      localStorage.getItem('anonymousCart')
    ) {
      email = JSON.parse(localStorage.getItem('anonymousCart'))?.cart?.reservationOwner?.email;
      mobileNum = JSON.parse(localStorage.getItem('anonymousCart'))?.cart?.reservationOwner?.mobileNumber;
    }

    const payFortData = this.paymentData.is2StepPayment ? this.props.payFordata : this.props.data;
    const commonFields = {
      access_code: payFortData.accessCode,
      language: this.paymentLanguage(),
      merchant_identifier: payFortData.merchantIdentifier,
      merchant_reference: this.generatedMerchantReference,
    };
    this.signatureDataCreditCard = {
      ...commonFields,
      service_command: payFortData.serviceCommand,
      return_url: payFortData.interMediatePageUrl,
    };

    this.tabbyExtraSignatureParams = {
      order_description: this.generatedMerchantReference?.split('_')[1],
      payment_option: UIConfig.paymentOption.tabby.toUpperCase(),
      phone_number: this.updatedMobileNum || mobileNum || '',
      return_url: this.tabbyReturnUrl,
      settlement_reference: this.settlementReference,
    };

    this.signatureDataTabby = {
      ...commonFields,
      command: payFortData?.command,
      currency: this.props.payData.currency,
      customer_email: email,
      amount: this.props?.cartData?.grossPrice * 100,
      ...this.tabbyExtraSignatureParams,
    };

    this.selectedData = {
      Tabby: this.signatureDataTabby,
      CreditCard: this.signatureDataCreditCard,
      [UIConfig.paymentOption.adcbTouchPoints]: this.signatureDataCreditCard,
      [UIConfig.paymentOption.moneyCard]: this.signatureDataCreditCard,
    };
    this.signatureData = this.selectedData[this.payfortType];

    // Use extra values to generate signature for YasIsland
    if (
      resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.YIB2C &&
      !this.paymentData.isFrictionlessPayfort
    ) {
      this.signatureData.merchant_extra = this.merchantExtra;
      this.signatureData.merchant_extra1 = this.merchantExtra1;
    }

    return PayfortUtility.getPayFortSignature(this.signatureData, payFortData.merchantPassphrase);
  };

  getOrderId = () => {
    if (this.orderId) {
      return this.orderId;
    } else {
      return (this.orderId = encodeURIComponent(parseQueryString(UIConfig.querStringParams.thankYouPage.orderId)));
    }
  };

  getReservationCode = () => {
    if (this.merchantReference) {
      return this.merchantReference;
    } else {
      return (this.merchantReference = parseQueryString(UIConfig.querStringParams.payfort.reservationCode));
    }
  };

  redirectToErrorPage = (service = '') => {
    PayfortUtility.clearLocalStore();
    localStorage.setItem(
      UIConfig.localStoreKeys.payment.errorMessage,
      JSON.stringify({
        service: {
          code: 404,
          text: this.props.data.services.tokenization.errors['E01'],
        },
      }),
    );

    window.location.href =
      this.thankYouPageUrl +
      '?' +
      UIConfig.querStringParams.thankYouPage.status +
      '=' +
      false +
      '&' +
      UIConfig.querStringParams.thankYouPage.reference +
      '=' +
      this.payfortType;
  };

  checkEmptyFieldValue = () => {
    const currentPayLoad = this.updatedPayLoad;
    for (let key in currentPayLoad) {
      if (!currentPayLoad[key]) {
        return true;
      }
    }
    return false;
  };

  componentDidUpdate(prevProps) {
    if (this.isTabby && this.state.tabbyLimitError) {
      this.props.isErrorOccured(UIConfig.limitErrorsKeys.tabbyLimit, this.showLimitError(), false, true);
      localStorage.setItem(UIConfig.localStoreKeys.tabbyLimitError, true);
      this.setState({ tabbyLimitError: false });
      const paymentError = document.querySelector(`.heading-3.sub-title`);
      if (paymentError) {
        paymentError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    if (!this.isTabby && this.props.cartData !== prevProps.cartData && document.getElementById('cardNumber')?.value) {
      if (this.props.cartData && this.thresholdAmount < this.props.cartData.grossPrice) {
        this.setState({ showInstallmentWrapper: true, selectedInstallment: 0 });
        this.getInstallmentPlans(document.getElementById('cardNumber').value, 1);
      } else {
        this.setState({ showInstallmentWrapper: false, selectedInstallment: null });
      }
    }
    this.verifyPayfortForm();

    // This condition is added only for Yasisland because this event is being unsubscribed after failed attempt of payment
    if (this.tenantID.toLowerCase() === UIConfig.YIB2C && !this.paymentData.isFrictionlessPayfort) {
      window.PubSub.subscribe('CreateOrderSuccess', this.createOrderSuccessCallback);
    }
  }
  tabbyExtraErrors = (e) => {
    if (this.showLimitError()) {
      this.setState({ tabbyLimitError: true });
    }
    if (localStorage.getItem(UIConfig.localStoreKeys.cartError)) {
      scrollToServerError(e);
    }
    return localStorage.getItem(UIConfig.localStoreKeys.cartError) || this.showLimitError();
  };
  submitData = (e) => {
    e && e.preventDefault();
    let hasAnnualPassError = false;
    if (isTicketUpgradePayment() && isANPUpgradation()) {
      if (checkAnnualUpgradeError()) {
        const updatedAnnualPassError = sessionStorage.getItem('annualPassFormSuccess') === 'false' ? true : false;
        if (!updatedAnnualPassError) {
          hasAnnualPassError = false;
        } else {
          hasAnnualPassError = true;
        }
      }
    } else {
      if (checkAnnualPassError(this.props.cartData, this.submitData)) {
        hasAnnualPassError = true;
      }
    }

    if (isMatchTenant(UIConfig.tenants.yi)) {
      GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
        name: toLowerCase(`${e.target.innerText} - ${this.payfortType}`),
        [UIConfig.ga4Constants.ELEMENTTEXT]:
          this.payfortType === UIConfig.paymentOption.creditCard
            ? toLowerCase(e.target.innerText)
            : toLowerCase(this.payfortType),
        category: toLowerCase(UIConfig.ga4Constants.PAYMENT),
      });
    }
    try {
      if (isMatchTenant(UIConfig.YIB2C)) {
        if (isLoggedInUser()) {
          const loggedInUser = getLoggedInUser();
          setSessionStorage('userName', loggedInUser.userName);
        } else {
          let userName = document.querySelector('input[name="FirstName"]').value;
          setSessionStorage('userName', userName);
        }
      }
    } catch (error) {}
    const tenantID = getLoggedInUser().tenantID;

    const { payFordata, cartData } = this.props;
    if (isMatchTenant(UIConfig.tenants.ya)) {
      GTMData.push(UIConfig.ga4Constants.ADD_PAYMENT_INFO, { cartData, paymentType: this.payfortType });
    }
    const tenent_id = getLoggedInUser().tenantID;
    // MarketingConsentUpdateAPI call here
    const newsletterCheckbox = canUseDOM() && document.getElementById('paymentNewsletter')?.checked;

    if (newsletterCheckbox && isLoggedInUser()) {
      const { MarketingConsentUpdateAPI } = payFordata.services;
      consentRequest(MarketingConsentUpdateAPI?.url);
    }

    if (tenent_id.toLowerCase() === UIConfig.ymcB2CTenant) {
      const isShippingRequired = checkShippingDetails(this.props.cartData);
      if (isShippingRequired) {
        return;
      }
    }

    if (!this.allowPayment || hasAnnualPassError) {
      if (this.customErrorObj.cardErrors) {
        this.setState({
          changeCardError: true,
        });
      }
      if (!this.isEnabled && !this.state.showTermsError) {
        this.setState({
          showTermsError: true,
        });
      }
      window.PubSub.publish('initiatePaymentSubmitValidation');
      scrollToServerError(e);
      e && e.preventDefault();
      return;
    }
    if (this.isTabby && this.tabbyExtraErrors()) return;

    if (isThereExtraErrors(e)) return false;
    if (
      !this.state.B2BPaymentEnable &&
      this.isB2B &&
      document.getElementsByClassName('c-my-cart-order--agent-reference')[0]
    ) {
      scrollToView({ errorBlock: document.getElementsByClassName('c-my-cart-order--agent-reference')[0] }, true);
      e && e.preventDefault();
      return;
    }

    this.setState({
      isPaymentLoader: true,
    });

    const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');

    const isVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
    if (isVoucher) {
      window.PubSub.publish('formSubmitVoucher', true);
    }

    if (checkParksTenants()) {
      GTMData.push('addPaymentInfo', {
        cartData: this.props.cartData,
        paymentType: this.isTabby ? 'Tabby' : this.getPaymentMethod(),
      });
    }

    if (isLoggedInUser()) {
      if (this.tenantID.toLowerCase() === UIConfig.YIB2C && !this.paymentData.isFrictionlessPayfort) {
        const loggedInUser = getLoggedInUser();

        if (!_isAddonAddedAfterBooking || _isAddonAddedAfterBooking == 'false') {
          let userId = getSessionStorage('packageSummaryData');
          if (userId) userId = JSON.parse(userId).userId;
          const localStorageMem = getMembershipDetails('membershipData');
          const { segmentType, status, email } = localStorageMem;
          const isCorpUser = segmentType?.toLowerCase() === 'corporate' && status === 'enabled';
          const { phoneCountryCode, mobileNum } = loggedInUser;
          let passengerObject = {
            Title: 'Mr.',
            FirstName: loggedInUser.userName,
            LastName: loggedInUser.lastName,
            Email: loggedInUser.email,
            ...(isCorpUser && { CorporateEmail: email }),
            Phone: mobileNum ? `${phoneCountryCode} ${mobileNum}` : '',
            CountryOfResidence: loggedInUser.country,
            Nationality: loggedInUser.nationalityAlphaCode,
            UserId: userId,
            AuthToken: loggedInUser.idToken,
          };

          const specialRequests = getSessionStorage('specialRequests');

          if (specialRequests) {
            passengerObject.SpecialRequests = JSON.parse(specialRequests);
          }

          const PassengerDetailsOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(passengerObject),
          };

          const passengerUrl = payFordata.services.SetPassengerDetails.url.replace(
            '{cartId}',
            getSessionStorage('cartId'),
          );

          fetch(passengerUrl, PassengerDetailsOptions)
            .then((res) => {
              if (!res.ok && res.statusText) {
                localStorage.setItem('isPassengerError', true);
              } else {
                localStorage.removeItem('isPassengerError');
                this.props.setPayFortData();
              }
              if (this.tenantID.toLowerCase() === UIConfig.YIB2C && !this.paymentData.isFrictionlessPayfort) {
                checkoutAnalytics(this.props.payData.currency, 'payment', 'Payfort');
              }
            })
            .catch(() => {
              this.setState({
                isPaymentLoader: false,
              });
            });
        }
      }
      /* Update recipient details */
      const cartData = this.props.cartData ? { ...this.props.cartData } : null;
      const recipientDetails = getRecipientDetails();
      if (cartData && recipientDetails && !isEmpty(recipientDetails) && checkIfGiftVoucherIsInTheCart(cartData)) {
        cartData.personalisedGiftObj = recipientDetails;
        if (resolvePath(payFordata.services, 'updateCart.url', '')) {
          CartService.updateCart(
            {
              cart: cartData,
            },
            'RecipientDetails',
            payFordata.services.updateCart.url,
          );
        }
      }

      if (this.props.isCreditSettlement) {
        this.props.setCreditSettlementData(this.props.cartData);
      } else if (this.props.isAdvanceTopup) {
        this.props.setAdvanceTopupData(this.props.cartData);
      } else {
        GTMData.getTenantId() === UIConfig.ymcB2CTenant &&
          GTMData.push('checkout', {
            step: UIConfig.gtmStepFive,
            products: this.props.cartData.items,
            subTotal: this.props.cartData.grossPrice,
          });
        (!isMatchTenant(UIConfig.YIB2C) || _isAddonAddedAfterBooking || this.paymentData.isFrictionlessPayfort) &&
          this.props.setPayFortData();
      }
    } else if (this.props.isFrictionlessPayfort) {
      //trigger create order success directly to skip create order call
      this.props.setPayFortData();
    } else {
      // We are not converting session object, we are directly refering session object. It's not a boolean type so we are not using strict type checking.
      if (_isAddonAddedAfterBooking && _isAddonAddedAfterBooking == 'true') {
        window.PubSub.publish('dataPosted', {
          isDataPosted: true,
        });
      } else {
        document.getElementById('submit-btn').click();
      }

      this.setState({
        isPaymentLoader: false,
      });
    }

    if (this.updatedPayLoad !== null) {
      if (this.updatedPayLoad.cardNumber) {
        this.updatedPayLoad.cardNumber = this.updatedPayLoad.cardNumber.replace(/\s/g, '');
      }
      if (this.updatedPayLoad.validity) {
        this.updatedPayLoad.validity = this.updatedPayLoad.validity
          .split('/')
          .reverse()
          .join('');
      }
    }
  };

  getPaymentMethod = () => {
    if (this.paymentData?.adcbPaymentTypes) {
      return this.paymentData.adcbPaymentTypes;
    } else if (this.paymentData?.moneyCardPaymentTypes) {
      return this.paymentData.moneyCardPaymentTypes;
    }
    return this.payfortType;
  };

  setElementValue = (id, value) => {
    const ele = document.getElementById(id);
    if (ele) {
      ele.value = value;
    }
  };

  updatePayloadWithTouchPointsDetails = () => {
    if ((this.props.usePayfortWithADCB || this.props.usePayfortWithMoneyCard) && this.cardDetails) {
      const validity = getValidityFormatted(this.cardDetails.validity);
      this.setElementValue('cardNumber', this.cardDetails.cardNumber);
      this.setElementValue('validity', this.cardDetails.validity);
      this.setElementValue('CVV', this.cardDetails.cvv);

      this.updatedPayLoad = {
        ...this.updatedPayLoad,
        ...this.cardDetails,
        validity,
      };
    }
  };

  createOrderSuccessCallback = async (msg, data) => {
    this.updatePayloadWithTouchPointsDetails();
    const cardNumber = document.getElementById('cardNumber');
    const cvv = document.getElementById('CVV');
    const validity = document.getElementById('validity');

    const formInput = [cardNumber, cvv, validity];

    if (data.isCreateOrderSuccess) {
      !this.isTabby &&
        formInput.forEach((item) => {
          item.setAttribute('disabled', 'disabled');
        });
      this.merchantReference = data.resCode;
      this.paymentInfo.cmsData.merchantExtra = this.merchantExtra = data.merchantExtra;
      this.paymentInfo.cmsData.merchantExtra1 = this.merchantExtra1 = data.merchantExtra1;
      this.paymentInfo.resCode = data.resCode;
      this.paymentInfo.orderId = data.orderId;
      this.paymentInfo.merchantReference = this.createMerchantReference();
      this.paymentInfo.actualMerchantReference = this.merchantReference;
      this.paymentInfo.price = data.total;
      this.paymentInfo.CreditAks = data.CreditAks;
      this.paymentInfo.pageVarient = this.props.B2B_pageVariation;
      this.paymentInfo.plan_code = this.plan_code;
      this.paymentInfo.issuer_code = this.plan_code ? this.issuer_code : '';
      this.paymentInfo.installments = this.plan_code ? this.installments : '';
      this.paymentInfo.payfortType = this.payfortType;
      if (this.props.usePayfortWithADCB) {
        this.paymentInfo.adcbPaymentTypes = UIConfig.paymentOption.adcbCard;
        this.paymentInfo.touchpointResponse = data.touchpointResponse;
        if (data.touchpointResponse && data.touchpointResponse.cardAmount) {
          this.paymentInfo.adcbPaymentTypes = UIConfig.paymentOption.adcbTouchPointsADCBCard;
          this.paymentInfo.price = parseInt(Math.round(data.touchpointResponse.cardAmount * 100), 10);
        }
      }
      if (this.props.usePayfortWithMoneyCard) {
        //this.paymentInfo.touchpointResponse = data.touchpointResponse;
        if (data.moneyCardResponse && data.moneyCardResponse.cardAmount) {
          this.paymentInfo.moneyCardPaymentTypes = UIConfig.paymentOption.moneyCard;
          this.paymentInfo.price = parseInt(Math.round(data.moneyCardResponse.cardAmount * 100), 10);
          if (data.moneyCardResponse.redeemAmount === 0) {
            this.paymentInfo.moneyCardPaymentTypes = this.payfortType;
          }
        }
      }

      // Flow change for Yas
      if (
        resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.YIB2C &&
        !this.paymentData.isFrictionlessPayfort
      ) {
        this.generatedMerchantReference = data.orderId;
        document.getElementById('merchant_extra').value = data.merchantExtra;
        document.getElementById('merchant_extra1').value = data.merchantExtra1;
      }

      if (this.isTabby) {
        this.settlementReference = this.generateSettlementReference();
      }
      this.initializeMerchantRef();

      document.getElementById('merchant_reference').value = this.generatedMerchantReference;
      document.getElementById('signature').value = this.signature;
      if (!this.isTabby) {
        document.getElementById('cardNumberForm').value = this.updatedPayLoad.cardNumber;
        document.getElementById('cvvForm').value = cvv.value;
        document.getElementById('expiryForm').value = this.updatedPayLoad.validity;
      }
      if (this.isTabby) {
        let email, mobileNum;
        if (isLoggedInUser()) {
          email = getLoggedInUser().email;
          mobileNum = getLoggedInUser().mobileNum;
        } else if (
          localStorage.getItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED) &&
          localStorage.getItem('anonymousCart')
        ) {
          email = JSON.parse(localStorage.getItem('anonymousCart'))?.cart?.reservationOwner?.email;
          mobileNum = JSON.parse(localStorage.getItem('anonymousCart')).cart?.reservationOwner?.mobileNumber;
        }

        document.getElementById('order_description').value = this.generatedMerchantReference?.split('_')[1];
        document.getElementById('customer_email').value = email;
        document.getElementById('phone_number').value = this.updatedMobileNum || mobileNum || '';
        document.getElementById('settlement_reference').value = this.settlementReference;
      }
      localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(this.paymentInfo));
      Logging(this.paymentData, 'Payfort-form-post', true, 'Step 0');
      this.payFortForm.submit();
    } else {
      mycartError();
    }
  };

  cardInputInitializer = () => {
    const cardNumberInput = document.getElementById('cardNumber');
    if (cardNumberInput) {
      cardNumberInput.oninput = (e) => {
        e.target.value = this.cardNumberFormatter(e.target.value);
      };
      cardNumberInput.addEventListener('keypress', (e) => {
        return this.checkDigit(e);
      });
    }
  };

  cvvInputInitializer = () => {
    const cvvInput = document.getElementById('CVV');
    if (cvvInput) {
      if (isYasArenaJourney()) {
        cvvInput.oninput = (e) => {
          const val = e.target.value;
          e.target.value = val.replace(/\D/g, '');
        };
      }
      cvvInput.addEventListener('keypress', (e) => {
        return this.checkDigit(e);
      });
    }
  };

  expiryInputInitializer = () => {
    const validityInput = document.getElementById('validity');
    if (validityInput) {
      validityInput.oninput = (e) => {
        if (e.target.value.length === 1 && e.target.value > 1 && e.target.value <= 9) {
          e.target.value = `0${e.target.value}`;
        }
        let keycode = this.currentKeyCode;

        if (e.target.value.length === 2 && keycode !== 8) {
          if (/^(0?[1-9]|1[012])$/.test(e.target.value)) {
            e.target.value = `${e.target.value}/`;
          } else {
            e.target.value = e.target.value.split('')[0];
          }
        }
        if (e.target.value.length === 3 && e.target.value.indexOf('/') <= -1 && keycode !== 8) {
          e.target.value = this.addSubstring(2, 0, e.target.value, '/');
        }
      };
      validityInput.addEventListener('keydown', (e) => {
        return this.checkDigit(e);
      });
    }
  };

  addSubstring = (idx, rem, str, subStr) => {
    return str.slice(0, idx) + subStr + str.slice(idx + Math.abs(rem));
  };

  handleCardImage = (value) => {
    this.paymentData.cardTypes.forEach((item) => {
      let regExpression = new RegExp(item.regexValue);
      if (regExpression.test(value)) {
        document.getElementById('cardNumber').style.backgroundImage = `url(${item.cardIcon.src})`;
        document.getElementById('CVV').setAttribute('maxlength', item.cVVLength);
      }
    });
  };
  cardNumberFormatter = (value) => {
    if (isYasArenaJourney()) {
      value = value.replace(/\D/g, '');
    }
    if (value.length === 4) {
      this.handleCardImage(value);
    } else if (value.length < 4) {
      document.getElementById('cardNumber').style.backgroundImage = '';
    }

    if (value.length > 5 && value.length <= 7) {
      this.handleCardTypeMessage();
    }
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  checkDigit = (e) => {
    const code = e.keyCode;
    this.currentKeyCode = code;

    // 35:end, 36:home,46:delete, 8:backspace,191:forward slash, 9:tab
    const modifierKeys = [35, 36, 46, 8, 191, 9];

    if ((code >= 48 && code <= 57) || (code >= 96 && code <= 105) || modifierKeys.indexOf(code) !== -1) {
      return true;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  enableDisableOnPaymentButton = () => {
    const paymentButton = document.body.querySelector('.payment-submit-btn button');

    if (this.state.enablePaymentButton) {
      paymentButton.removeAttribute('disabled');
    } else {
      paymentButton.setAttribute('disabled', false);
    }
  };

  verifyPayfortForm = () => {
    const cardNumber = document.getElementById('cardNumber');
    let cardNumberValue = (cardNumber && cardNumber.value) || '';

    let blockPayment = false;
    if (this.requiredFields) {
      for (let item of this.requiredFields) {
        if (!item.value) {
          blockPayment = true;
        }
      }
    }

    const [cardErrorMsgElem] = document.getElementsByClassName('error-adcb');
    cardErrorMsgElem && cardErrorMsgElem.parentNode && cardErrorMsgElem.parentNode.removeChild(cardErrorMsgElem);
    cardNumberValue && cardNumber.classList.remove('error');

    if (this.props.hasADCBProductInCart && cardNumberValue && cardNumberValue.length >= 7) {
      cardNumberValue = cardNumberValue.replace(/ /g, '');
      const { paymentCards, paymentOptions } = this.props.payFordata,
        { paymentTypes } = paymentOptions.hasOwnProperty('paymentTypes') && paymentOptions,
        [creditCardConfig] =
          paymentTypes &&
          paymentTypes.filter((paymentType) =>
            UIConfig.payfort.payfortTypes.find((item) => item === paymentType.value),
          ),
        [cardNumberFieldConfig] =
          creditCardConfig &&
          creditCardConfig.paymentFormSection &&
          creditCardConfig.paymentFormSection.fields.filter((field) => field.name === 'cardNumber'),
        binNumbers = paymentCards[0].binNumbers.split(','),
        adcbCardUsed = binNumbers.filter((binNum) => cardNumberValue.indexOf(binNum) === 0);
      const formErrorMsg = document.querySelector('.error-msg#error_cardNumber');
      if (adcbCardUsed.length === 0 && !this.customErrorObj.cardErrors && !formErrorMsg) {
        const node = document.createElement('span'),
          cardTypeError = resolvePath(cardNumberFieldConfig, 'cardTypeErrorMessage.ADCB', ''),
          textnode = document.createTextNode(cardTypeError);

        node.setAttribute('class', 'error-msg body-copy-6 form-error--field error-adcb');
        node.setAttribute('id', 'error_cardNumber');
        cardNumber.classList.add('error');
        node.appendChild(textnode);
        cardNumber.parentElement.appendChild(node);

        blockPayment = true;
        this.isEnabled = true;
      } else if (adcbCardUsed.length === 0 && !this.customErrorObj.cardErrors && formErrorMsg) {
        let existingError = formErrorMsg.textContent;
        const splitExisting = existingError.split('.');
        if (splitExisting?.length && splitExisting?.[1]) {
          existingError = `${splitExisting?.[0]}.`;
        }
        const cardTypeError = resolvePath(cardNumberFieldConfig, 'cardTypeErrorMessage.ADCB', '');
        const error = `${existingError} ${cardTypeError}`;
        formErrorMsg.innerText = error;
      }
    }
    if (
      !this.isEnabled ||
      this.customErrorObj.dateError ||
      this.customErrorObj.cardErrors ||
      document.getElementsByClassName('form-error--field').length ||
      document.querySelectorAll('.yasid-email-error').length > 0 ||
      blockPayment ||
      isThereExtraErrors()
    ) {
      if (!document.body.querySelector('.active-btn.payment-submit-btn')) {
        document.body.querySelector('.payment-submit-btn') &&
          document.body.querySelector('.payment-submit-btn').classList.remove('button-enabled-dark');
        this.allowPayment = false;
      }
    } else {
      document.body.querySelector('.payment-submit-btn') &&
        document.body.querySelector('.payment-submit-btn').classList.add('button-enabled-dark');
      this.allowPayment = true;
    }
  };

  handleButtonBehavior = (e) => {
    this.isEnabled = e.target.checked;
    //Calling this to check again if button disabled or not
    if (this.props.is2StepPaymentYasArena) {
      this.enableDisableOnPaymentButton();
    }
    if (this.isEnabled) {
      this.setState({
        showTermsError: false,
      });
    } else {
      this.setState({
        showTermsError: true,
      });
    }
    this.verifyPayfortForm();
  };
  //function added to handle the keyboard handling of the custom checkboxes
  handleCheckBoxKeyDownEvent = (e) => {
    if (isEnterPressed(e)) {
      this.handleCheckUncheck(e.target);
    }
  };
  handleCheckUncheck = (targetElement) => {
    const checkBox = document.querySelector('#' + targetElement.previousSibling.id);
    if (checkBox !== null) {
      const event = createCustomEvent('change');
      checkBox.checked = !checkBox.checked;
      checkBox.dispatchEvent(event);
    }
  };

  handleValidityValidation = (field, value) => {
    if (field === 'validity') {
      const customErrorMsg = document.getElementById('payfortCustomError');
      if (value.length !== 5) {
        if (customErrorMsg) {
          customErrorMsg.parentElement && customErrorMsg.parentElement.removeChild(customErrorMsg);
          this.customErrorObj.customErrorMsg = false;
        }
      } else {
        const enteredExpiry = value.split('/');
        const currentMonth = this.currentMonth;
        const currentYear = this.currentYear;

        if (
          (enteredExpiry[1] && currentYear > enteredExpiry[1]) ||
          (currentYear === enteredExpiry[1] && currentMonth + 1 >= enteredExpiry[0])
        ) {
          if (!document.getElementById('payfortCustomError')) {
            const node = document.createElement('span');
            node.setAttribute('class', 'custom-error error-msg body-copy-6');
            node.setAttribute('id', 'payfortCustomError');
            const textnode = document.createTextNode(this.dateValidationError);
            node.appendChild(textnode);
            document.getElementById('validity').parentElement.appendChild(node);
            this.customErrorObj.dateError = true;
          }
        } else {
          if (customErrorMsg) {
            customErrorMsg.parentElement && customErrorMsg.parentElement.removeChild(customErrorMsg);
            this.customErrorObj.customErrorMsg = false;
            this.customErrorObj.dateError = false;
          }
        }
      }
    }

    if (field === 'cardNumber' && this.props.cartData && this.props.cartData.couponCode) {
      this.validateCardCouponCombo();
      this.verifyPayfortForm();
    }
  };

  formInstallmentResponse = (response = []) => {
    const sortOrder = resolvePath(this.props.payFordata, 'installmentData.sortOrder', 0);
    const data = [
      {
        currency_code: this.props.cartData.currency,
        number_of_installment: resolvePath(this.props.payFordata, 'installmentData.labelData.fullAmountText', ''),
        fee_display_value: '',
        amountPerMonth: this.props.cartData && this.props.cartData.grossPrice,
      },
      ...sortInstallments(response, sortOrder),
    ];

    return data;
  };

  getInstallmentPlans = (cardNumber) => {
    const { installmentData } = this.props.payFordata;
    const binumber = cardNumber && cardNumber.replace(/ /g, '').substr(0, 6);
    const payFortData = this.paymentData.is2StepPayment ? this.props.payFordata : this.props.data;
    const querycommand = resolvePath(this.props.payFordata, 'installmentData.labelData.querycommand', '');
    const installmentUrl = resolvePath(this.props.payFordata, 'services.getInstallmentUrl.url', '');
    const variant = resolvePath(installmentData, 'variant', '');

    if (!isEmpty(installmentData) && variant && installmentUrl) {
      const payload = {
        accesscode: payFortData.accessCode,
        language: getCurrentLanguage().split('-')[0],
        merchantidentifier: payFortData.merchantIdentifier,
        querycommand,
        amount: this.props.cartData && this.props.cartData.grossPrice * 100,
        currency: this.props.cartData.currency,
        binumber,
      };
      const config = {
        url: `${installmentUrl}` + this.tenantID,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: payload,
      };

      ApiWrapper.experienceServices(config)
        .then((res) => {
          if (res && res.data) {
            if (res.data.plan_details && res.data.plan_details.length > 0) {
              this.issuer_code = res.data.issuer_code;
              const formResponse = this.formInstallmentResponse(res.data.plan_details);
              this.setState({
                installmentAvailable: formResponse,
                ...(checkParksTenants() && { payfortInstallmentWrapper: true }),
                selectedInstallment: 0,
                showInstallmentWrapper: true,
                showBankImages: false,
                processingFessMessage:
                  getCurrentLanguage() === 'en'
                    ? res.data.processing_fees_message_en
                    : res.data.processing_fees_message_ar
                    ? res.data.processing_fees_message_ar
                    : res.data.processing_fees_message_en,
                termsAndCond:
                  getCurrentLanguage() === 'en'
                    ? res.data.terms_and_condition_en
                    : res.data.terms_and_condition_ar
                    ? res.data.terms_and_condition_ar
                    : res.data.terms_and_condition_en,
              });
            } else if (res.data.code) {
              this.issuer_code = '';
              this.setState({
                processingFessMessage: '',
                termsAndCond: '',
                installmentAvailable: [],
                selectedInstallment: null,
                ...(checkParksTenants() && { payfortInstallmentWrapper: false }),
                showInstallmentWrapper: false,
              });
            }
          }
        })
        .catch((err) => {
          Logging(err, this.paymentData.componentName, false, 'getInstallments API failed');
        });
    }
  };

  handleCardTypeMessage = (binNumber) => {
    const cardNumber = document.getElementById('cardNumber');
    const cardMsg = document.getElementById('cardMsg');
    if (cardNumber && cardNumber.value.length >= 7) {
      const enteredBinNumber =
        binNumber ||
        cardNumber.value
          .split('')
          .splice(0, 7)
          .join('')
          .replace(/\s/g, '');
      for (let item of this.paymentData.paymentCards) {
        if (item.binNumbers && item.binNumbers.indexOf(enteredBinNumber) >= 0 && !cardMsg) {
          const node = document.createElement('span');
          node.setAttribute('class', 'card-type-msg error-msg body-copy-6');
          node.setAttribute('id', 'cardMsg');
          const textnode = document.createTextNode(item.cardMessage);
          node.appendChild(textnode);
          cardNumber.parentElement.appendChild(node);
        }
      }
    } else {
      cardMsg && cardNumber.parentElement && cardNumber.parentElement.removeChild(cardMsg);
    }
  };

  /*
   * Default carousel settings
   */
  allViewSettings = (customCarouselSettings, viewport) => {
    customCarouselSettings = this.customCarouselSettings(this.defaultSettings);
    customCarouselSettings = Object.assign(
      {},
      deepCloneObject(this.defaultSettings),
      deepCloneObject(customCarouselSettings),
    );
    let mobile = {
      breakpoint: 850,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
      },
    };
    let tablet = {
      breakpoint: 851,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
      },
    };
    customCarouselSettings.responsive.push(mobile, tablet);
    //in case selected index is 0
    //initial slide in mobile should be 0 and 1 in desktop
    //but in case if selected index is > 0 then initial slide should remain as is
    const slideCount = 'mobiletablet'.indexOf(viewport) === -1 ? 2 : 1;
    customCarouselSettings.initialSlide = 1;

    return customCarouselSettings;
  };

  /**
    * customCarouselSettings function to return the custom carousel settings properties
        and update in carausel settings
    * @param    {[data]} data object of the element
    * @return   {[Object]} Return custom carausel settings.
    */
  customCarouselSettings = (defaultSettings) => {
    return {
      className: `c-editorial-grid--item ${defaultSettings && defaultSettings.dots ? 'dot-enabled' : ''}`,
      dotsClass: 'custom-dots',
    };
  };

  componentWillMount() {
    const settings = {
      dots: true,
      arrows: true,
      autoplay: false,
      rtl: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
    };
    if (canUseDOM()) {
      let customCarouselSettings = {};
      const viewport = detectViewPort();
      this.defaultSettings = { ...this.settings, responsive: [] };
      customCarouselSettings = this.allViewSettings(customCarouselSettings, viewport);
      this.settings = Object.assign({}, deepCloneObject(this.defaultSettings), deepCloneObject(customCarouselSettings));
      if (viewport === 'mobile' || viewport === 'tablet') {
        this.settings.centerMode = false;
        this.settings.centerPadding = '24px';
        this.settings.variableWidth = true;
      } else {
        this.settings.variableWidth = true;
      }
    }
  }

  handleInstallmentClick = (data, index) => {
    this.setState({ selectedInstallment: index, selectedInstallmentData: data });
    if (data) {
      this.plan_code = data.plan_code;
    }
  };

  renderInstallment = (data, index, ...theArgs) => {
    const tile = data;
    let roi = tile.fee_display_value && parseFloat(tile.fee_display_value / 100);
    if (roi === 0) {
      roi = '0';
    }
    const amountPerMonth = tile.amountPerMonth && toTwoDecimalPlaces(parseFloat(tile.amountPerMonth));
    const payFordata = this.props.payFordata;
    return (
      <div
        className={classNames('outter-wrapper', { 'highlighted-ticket': this.state.selectedInstallment === index })}
        onClick={() => this.handleInstallmentClick(data, index)}
        onKeyDown={(e) => handleEnterKey(e, () => this.handleInstallmentClick(data, index))}
        tabIndex={this.props.isTabIndex}
      >
        <div className="inner-wrapper">
          <div className="selected-ticket">
            <DynamicContent
              tagName="div"
              attrs={{ className: 'title' }}
              innerHtml={`${tile.number_of_installment} ${
                index === 0 ? '' : resolvePath(payFordata, 'installmentData.labelData.monthText', '')
              }`}
            />
            <DynamicContent
              tagName="div"
              attrs={{ className: 'sub-title' }}
              innerHtml={`${tile.currency_code} ${amountPerMonth}`}
            />
            {roi && (
              <DynamicContent
                tagName="div"
                attrs={{ className: 'description' }}
                innerHtml={`${roi === '0' ? '0' : roi.toFixed(2)}% ${resolvePath(
                  payFordata,
                  'installmentData.labelData.interestText',
                  '',
                )}`}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  renderMultipark = (data) => {
    return <div className="c-multi-park-panel without-carousel">{this.renderMultiInstallmentPlans(data)}</div>;
  };

  renderMultiInstallmentPlans = (data = []) => {
    return data.map((item, i) => {
      return (
        <div className="c-multi-park-ticket-wrapper" key={i}>
          {this.renderInstallment(item, i)}
        </div>
      );
    });
  };

  renderCarouselMultiInstallments = (data) => {
    return (
      <div className="carousel">
        <div className="c-multi-park-panel">
          <div className="content-carousel v-carousel-all-view">
            <div className="v-carousel-view-container clearfixnull">
              <Carousel ref="slider" data={this.settings}>
                {this.renderMultiInstallmentPlans(data)}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  };

  validateCardCouponCombo = (code) => {
    const cardNumber = document.getElementById('cardNumber');
    const cardError = document.getElementById('cardErrors');
    if (cardNumber) {
      let cardNumberValue = cardNumber.value;
      if (cardNumberValue.length >= 7) {
        const binNumber = cardNumberValue
          .split('')
          .splice(0, 7)
          .join('')
          .replace(/\s/g, '');
        const filteredPromo = this.paymentData.promoCodes.filter((item) => {
          if (!code) {
            return item.promoCode === this.props.cartData.couponCode;
          }
          return item.promoCode === code;
        });
        if (filteredPromo.length > 0) {
          if (filteredPromo[0].binNumbers.indexOf(binNumber) >= 0) {
            cardError && cardNumber.parentElement && cardNumber.parentElement.removeChild(cardError);
            this.customErrorObj.cardErrors = false;
            this.setState({
              changeCardError: false,
            });
          } else {
            if (!cardError) {
              const node = document.createElement('span');
              node.setAttribute('class', 'custom-error error-msg body-copy-6');
              node.setAttribute('id', 'cardErrors');
              const textnode = document.createTextNode(filteredPromo[0].primaryErrorMessage);
              node.appendChild(textnode);
              cardNumber.parentElement.appendChild(node);
              this.secondaryErrorMsg = filteredPromo[0].secondaryErrorMessage;
              this.customErrorObj.cardErrors = true;
            }
          }
        }
      } else {
        if (cardError) {
          this.customErrorObj.cardErrors = false;
          cardNumber.parentElement && cardNumber.parentElement.removeChild(cardError);
        }
      }
    }
  };

  handleCustomValidation = throttle((payLoad, focusElement, errors) => {
    this.validationErrors = errors;
    this.updatedPayLoad = payLoad;
    if (checkParksTenants() && payLoad.cardNumber === '') {
      this.setState({ payfortInstallmentWrapper: false });
    }
  }, 1000);

  newsletterCheckbox = (paymentNewsletterClass) => {
    return (
      <div
        className={`c-form contact-us-form checkbox-wrapper ${
          this.paymentData.is2StepPayment ? 'checkbox-wrapper-step2' : ''
        }`}
      >
        <div className="c-payment-newsletter">
          <label htmlFor={paymentNewsletterClass} className="checkbox-label">
            <input
              type="checkbox"
              id={paymentNewsletterClass}
              disabled={parseInt(this.props.isTabIndex)}
              onKeyDown={(e) => e.keyCode === 13 && this.submitData(e)}
              onChange={() => this.props.onNewsLetterChangeHandler && this.props.onNewsLetterChangeHandler()}
              checked={this.props.newsLetterEnabled}
            />
            <div className="checkbox" onKeyDown={(e) => this.handleCheckBoxKeyDownEvent(e)}>
              {' '}
            </div>
            {this.paymentData.newsLetterLabel && (
              <RichText
                data={{
                  bodyCopy: this.paymentData.newsLetterLabel,
                  attrs: { className: 'body-3' },
                }}
              />
            )}
          </label>
        </div>
      </div>
    );
  };

  isMultiParkTicketExist = () => {
    if (
      (isParkTenant() || isMatchTenant(UIConfig.tenants.swadb2c)) &&
      this?.props?.payFordata?.paymentOptions?.tAndcMultiParkBioConsent?.length
    ) {
      return this.props?.cartData?.items?.some((item) => item?.itemType?.toLowerCase() === 'mup');
    }
  };

  handleParkConsent = (e) => {
    this.setState({
      isMultiParkConsentChecked: e.target.checked,
    });
    sessionStorage.setItem('isMultiParkConsent', e.target.checked);
  };

  renderParkConsentCheckbox = (parkConsentClass) => {
    return (
      this.isMultiParkTicketExist() && (
        <div
          className={`c-form contact-us-form checkbox-wrapper ${
            this.paymentData.is2StepPayment ? 'checkbox-wrapper-step2' : ''
          } multiparkBioConsentCheckbox-wrapper`}
        >
          <div className="c-payment-newsletter multiparkBioConsentCheckbox">
            <label htmlFor={parkConsentClass} className="checkbox-label">
              <input
                type="checkbox"
                id={parkConsentClass}
                disabled={parseInt(this.props.isTabIndex)}
                onKeyDown={(e) => e.keyCode === 13 && this.submitData(e)}
                onChange={this.handleParkConsent}
                checked={this.state.isMultiParkConsentChecked}
              />
              <div className="checkbox" onKeyDown={(e) => this.handleCheckBoxKeyDownEvent(e)}>
                {' '}
              </div>
              {this?.props?.payFordata?.paymentOptions?.tAndcMultiParkBioConsent && (
                <RichText
                  data={{
                    bodyCopy: this?.props?.payFordata?.paymentOptions?.tAndcMultiParkBioConsent,
                    attrs: { className: 'body-3' },
                  }}
                />
              )}
            </label>
          </div>
        </div>
      )
    );
  };

  /**
   * render  Used to render the JSX of the component
   * @param    {[Void]} function does not accept anything.
   * @return   {[Object]} JSX of the component
   */
  render() {
    let buttonData;
    const getCardNumberElement = canUseDOM() ? document.getElementById('cardNumber') : '';
    if (this.paymentData.is2StepPayment) {
      buttonData = {
        type: 'submit',
        label:
          (checkParksTenants() &&
            checkPaymentType(this.props.payFordata?.paymentOptions?.paymentTypes, UIConfig.paymentOption.creditCard)) ||
          this.paymentData.primaryCTA.label,
        disabled: true,
        class: 'payment-submit-btn  btn-primary button-enabled-light',
        ...(this.paymentData.paymentType === 'CreditCard' &&
          checkParksTenants() && {
            icon: 'icon',
          }),
        ...(this.isTabby &&
          this.showTabbyCard && {
            isTabby: this.isTabby,
            currency: this.props.payData.currency,
            amount: this.props?.cartData?.grossPrice,
          }),
        ...(this.isTabby && {
          label:
            (checkParksTenants() &&
              checkPaymentType(this.props.payFordata?.paymentOptions?.paymentTypes, UIConfig.paymentOption.tabby)) ||
            this.paymentData.primaryCTA.label,
        }),
      };
    }

    const { usePayfortWithADCB, payFordata, usePayfortWithMoneyCard } = this.props;
    let tncTxtLabel = resolvePath(payFordata, 'installmentData.tncText', '');
    if (tncTxtLabel && this.state.termsAndCond) {
      tncTxtLabel = tncTxtLabel.replace('{0}', this.state.termsAndCond);
    }
    let paymentTnCClass = 'paymentTnC',
      paymentNewsletterClass = 'paymentNewsletter',
      wrapperClass = classNames('component c-payfort card-detail', {
        'adcb-payfort-wrapper': usePayfortWithADCB,
        'money-card-payfort-wrapper': usePayfortWithMoneyCard,
        'v-payfort-installments':
          resolvePath(payFordata, 'installmentData.variant', '') === 'payfort-installment-variant',
        'tabby-payment-wrapper': this.isTabby,
      });
    if (usePayfortWithADCB) {
      paymentTnCClass = '';
      paymentNewsletterClass = '';
    }
    const showInstallMentOption =
      this.state.payfortInstallmentWrapper && this.thresholdAmount < this.props?.cartData?.grossPrice;

    return (
      <div className={wrapperClass} data-c-render="client-only" data-c-name="Payfort">
        <div>
          <div className="custom-payfort-form ">
            <form
              action={this.props.payFordata.services.tokenization.url}
              method="POST"
              autoComplete="off"
              role="form"
              ref={(form) => {
                this.payFortForm = form;
              }}
              className="c-payfort-form-v1 contact-us-form c-form"
              {...getPayfortTypeNameID(this.payfortType)}
            >
              <PayfortInput
                {...this.props}
                data={{
                  ...this.props.payFordata,
                  ...(this.isTabby && {
                    interMediatePageUrl: this.tabbyReturnUrl,
                  }),
                }}
                signature={this.signature}
                generatedMerchantReference={this.generatedMerchantReference}
                lang={this.paymentLanguage()}
                merchant_extra={this.merchantExtra}
                merchant_extra1={this.merchantExtra1}
                payfortType={this.payfortType}
              />
              {!this.isTabby && (
                <>
                  <input type="hidden" name="card_number" value="" id="cardNumberForm" />
                  <input type="hidden" name="card_security_code" value="" id="cvvForm" />
                  <input type="hidden" name="expiry_date" value="" id="expiryForm" />
                  {this.paymentData.cardDetailImage && this.isYmcTenant && (
                    <div className="card-detail-image-payfort-ymc">
                      <Image noRendition image={this.paymentData.cardDetailImage} />
                    </div>
                  )}
                  <Form
                    // action={this.props.data.services.tokenization.url}
                    payFortData={this.state.data}
                    isNewForm={this.paymentData.is2StepPayment}
                    {...this.props}
                    className="payfort-form-wrapper"
                    handleCustomValidation={this.handleCustomValidation}
                    handleValidityValidation={this.handleValidityValidation}
                    getInstallmentPlans={
                      this.props.cartData && this.thresholdAmount < this.props.cartData.grossPrice
                        ? this.getInstallmentPlans
                        : () => {}
                    }
                    // getErrorObject={this.getErrorObject}
                    method="POST"
                    name="payfort"
                    id="payfortForm"
                    autoComplete="off"
                    isTabIndex={this.props.isTabIndex}
                  />
                </>
              )}

              {this.isTabby && this.showTabbyCard && <div id="tabbyCard"></div>}
              {payFordata.installmentData &&
                !this.isTabby &&
                (!checkParksTenants() || showInstallMentOption) &&
                resolvePath(payFordata, 'installmentData.variant', '') === 'payfort-installment-variant' && (
                  <div
                    className={`c-payfort-installment-wrapper 
                  ${
                    resolvePath(payFordata, 'installmentData.variant', '') === 'payfort-installment-variant'
                      ? 'v-payfort-installments'
                      : ''
                  }
                ${this.state.installmentAvailable.length === 0 ? 'payfort-empty-installments' : ''} `}
                  >
                    <div className="c-payfort-installment-heading">
                      <DynamicContent
                        tagName="span"
                        attrs={{
                          className: 'heading',
                          onClick: () =>
                            this.setState({
                              showBankImages: !this.state.showBankImages,
                              showInstallmentWrapper: false,
                            }),
                          onKeyDown: (e) =>
                            handleEnterKey(e, () =>
                              this.setState({
                                showBankImages: !this.state.showBankImages,
                                showInstallmentWrapper: false,
                              }),
                            ),
                          tabIndex: this.props.isTabIndex,
                        }}
                        innerHtml={payFordata.installmentData.title}
                      />
                      <span
                        className={classNames('chevron-down', { 'chevron-dir': this.state.showBankImages })}
                        tabIndex={this.props.isTabIndex}
                        onClick={() =>
                          this.setState({ showBankImages: !this.state.showBankImages, showInstallmentWrapper: false })
                        }
                        onKeyDown={(e) =>
                          handleEnterKey(e, () =>
                            this.setState({
                              showBankImages: !this.state.showBankImages,
                              showInstallmentWrapper: false,
                            }),
                          )
                        }
                      ></span>
                    </div>

                    {this.state.showBankImages && payFordata.installmentData.banklogos.length > 0 && (
                      <div className="c-payfort-installments-banks body-2">
                        <DynamicContent tagName="div" innerHtml={payFordata.installmentData.description} />
                        <div className="c-payfort-banks-list">
                          {payFordata.installmentData.banklogos.map((item) => {
                            return (
                              <div className="bank-images">
                                <Image image={item} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {getCardNumberElement &&
                      getCardNumberElement.value &&
                      this.props.cartData &&
                      this.thresholdAmount < this.props.cartData.grossPrice &&
                      this.state.installmentAvailable.length > 0 && (
                        <div className="c-payfort-installment-options">
                          <span
                            className={classNames('chevron-down chevron-size', {
                              'chevron-dir': this.state.showInstallmentWrapper,
                            })}
                            tabIndex={this.props.isTabIndex}
                            onClick={() =>
                              this.setState({
                                showInstallmentWrapper: !this.state.showInstallmentWrapper,
                                showBankImages: false,
                              })
                            }
                            onKeyDown={(e) =>
                              handleEnterKey(e, () =>
                                this.setState({
                                  showInstallmentWrapper: !this.state.showInstallmentWrapper,
                                  showBankImages: false,
                                }),
                              )
                            }
                          ></span>
                          <div className="body-3">
                            <DynamicContent
                              tagName="span"
                              attrs={{
                                className: 'bank-section',
                                tabIndex: this.props.isTabIndex,
                                onClick: () =>
                                  this.setState({
                                    showInstallmentWrapper: !this.state.showInstallmentWrapper,
                                    showBankImages: false,
                                  }),
                                onKeyDown: (e) =>
                                  handleEnterKey(e, () =>
                                    this.setState({
                                      showInstallmentWrapper: !this.state.showInstallmentWrapper,
                                      showBankImages: false,
                                    }),
                                  ),
                              }}
                              innerHtml={payFordata.installmentData.subtitle}
                            />
                          </div>
                        </div>
                      )}

                    {getCardNumberElement &&
                      getCardNumberElement.value &&
                      this.props.cartData &&
                      this.thresholdAmount < this.props.cartData.grossPrice &&
                      this.state.showInstallmentWrapper &&
                      this.state.installmentAvailable.length > 0 && (
                        <div className="c-general-admission">
                          <div
                            className={`c-ticket-selector-park-selector ${
                              this.state.installmentAvailable.length > 3 ? 'add-carousal' : ''
                            }`}
                          >
                            <div className="c-multi-park-section">
                              {this.state.installmentAvailable.length > 3
                                ? this.renderCarouselMultiInstallments(this.state.installmentAvailable)
                                : this.renderMultipark(this.state.installmentAvailable)}
                            </div>
                          </div>
                          <div className="body-3">
                            {this.state.processingFessMessage && (
                              <DynamicContent
                                tagName="span"
                                attrs={{ className: 'installment-note' }}
                                innerHtml={this.state.processingFessMessage}
                              />
                            )}
                            {this.state.termsAndCond && (
                              <DynamicContent
                                tagName="span"
                                attrs={{ className: 'installment-tnc' }}
                                innerHtml={tncTxtLabel}
                              />
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                )}
              {this.paymentType?.bodyCopy && this.isTabby && (
                <DynamicContent
                  attrs={{ className: 'tabby-description' }}
                  tagName="span"
                  innerHtml={this.paymentType?.bodyCopy}
                />
              )}
              <div
                className={`c-form contact-us-form checkbox-wrapper ${
                  this.paymentData.is2StepPayment ? 'checkbox-wrapper-step2' : ''
                }`}
              >
                {!this.props.hideNewsLetter &&
                  this.paymentData.newsLetterLabel !== '' &&
                  checkTenant(UIConfig.iamMapping.ymc) &&
                  this.newsletterCheckbox(paymentNewsletterClass)}
                <div className={`c-payment-tnc tnc-check ${this.state.disableBookButton ? 'disabled' : ''}`}>
                  <label htmlFor={paymentTnCClass} className="checkbox-label">
                    <input
                      type="checkbox"
                      id={paymentTnCClass}
                      onChange={this.handleButtonBehavior}
                      disabled={parseInt(this.props.isTabIndex)}
                      onKeyDown={(e) => e.keyCode === 13 && this.submitData(e)}
                    />
                    <div
                      className={`checkbox ${this.state.showTermsError ? 'checkbox-error-state' : ''}`}
                      onKeyDown={(e) => this.handleCheckBoxKeyDownEvent(e)}
                    >
                      {' '}
                    </div>
                    <span className="body-3">
                      <DynamicContent tagName="span" innerHtml={this.paymentData.tAndcLabel} />
                      {this.state.showTermsError && (
                        <span className="terms-error-msg body-copy-6">{this.paymentData.errorMessageTNCRequired}</span>
                      )}
                    </span>
                  </label>
                </div>
              </div>
              {this.renderParkConsentCheckbox('multipark-consent-class')}
              {isMatchTenant(UIConfig.YIB2C) &&
                !isLoggedInUser() &&
                (this.state.confirmedEmail || sessionStorage.getItem('confirmedEmail')) && (
                  <p className="emailConfirmation-text-yib2c">
                    {this.props?.payFordata?.paymentOptions?.paymentLabels?.emailConfirmMessage?.replace('{email}', '')}
                    <span className="emailConfirmation-text-yib2c-email">
                      {this.state.confirmedEmail || sessionStorage.getItem('confirmedEmail') || ''}
                    </span>
                  </p>
                )}
              {this.props.emailConfirmationText}
              <div className="payfort-submit-btn" id="payfortSubmitBtn">
                <Button
                  data={buttonData}
                  isNewForm={this.paymentData.is2StepPayment}
                  clickCallback={this.submitData.bind(this)}
                  disabled={parseInt(this.props.isTabIndex)}
                  isTabIndex={this.props.isTabIndex}
                />
                {this.state.changeCardError && (
                  <p className="coupon-failed-error error-msg body-copy-6" id="couponFailedError">
                    {this.secondaryErrorMsg}
                  </p>
                )}
                <RichText
                  data={{
                    bodyCopy: this.paymentData.vatLabel,
                    attrs: { className: 'body-3 vat-label' },
                  }}
                />

                {this.paymentData.cardDetailImage && !this.isTabby && (
                  <div className="card-detail-image">
                    <Image noRendition image={this.paymentData.cardDetailImage} />
                  </div>
                )}
              </div>

              {!this.props.hideNewsLetter &&
                this.paymentData.newsLetterLabel !== '' &&
                !checkTenant(UIConfig.iamMapping.ymc) &&
                this.newsletterCheckbox(paymentNewsletterClass)}
            </form>
          </div>
        </div>
        {canUseDOM() &&
          this.tenantID.toLowerCase() === UIConfig.YIB2C &&
          !this.paymentData.isFrictionlessPayfort &&
          this.state.isPaymentLoader && <div className="loader"></div>}
      </div>
    );
  }
}

export default Payfort;

/**
 * Used to define the proptypes that will be received by the component.
 */
Payfort.propTypes = {
  data: PropTypes.shape({
    services: PropTypes.shape({
      tokenization: PropTypes.object.isRequired,
      makePayment: PropTypes.object.isRequired,
      reconcile: PropTypes.object.isRequired,
    }),
    payFortUrl2: PropTypes.string.isRequired,
    serviceCommand: PropTypes.string.isRequired,
    accessCode: PropTypes.string.isRequired,
    merchantIdentifier: PropTypes.string.isRequired,
    interMediatePageUrl: PropTypes.string.isRequired,
    interMediatePageUrl2: PropTypes.string.isRequired,
    merchantPassphrase: PropTypes.string.isRequired,
    command: PropTypes.string.isRequired,
    customerIP: PropTypes.string.isRequired,
    queryCommand: PropTypes.string.isRequired,
    eci: PropTypes.string.isRequired,
  }),
};
