import React, { useMemo, useState } from 'react';
import { resolvePath, UIConfig } from '../../../../../..';
import { checkTenant } from '../../../../../common/utility';
import DynamicContent from '../../../../presentation/base/dynamic-content/dynamic-content-component';
// isDrivingExperience is define if we are coming from driving experience or not
const AmountWithButtons = ({
  context,
  isDrivingExperience = false,
  attentionCheck,
  timeSlotFunction,
  mobileAttention,
}) => {
  const disableButton = context.state[isDrivingExperience ? 'buttonDisable' : 'disableAddToCart'];
  const isSwad = checkTenant(UIConfig.SWADB2C);
  const isDisabled = useMemo(() => {
    if (!isDrivingExperience) return disableButton;

    return disableButton || context.state.total === 0;
  }, [context.state, isDrivingExperience, disableButton]);

  const [swadErrorMsg, setswadErrorMsg] = useState(false); // only for swad tenant - State for error messge HTML structure render.
  const [swadErrorMsgTxt, setswadErrorMsgTxt] = useState(); // only for swad tenant - State for error messge text render.

  // only for swad tenant - subscribe to show datepicker error message HTML render according to design
  window.PubSub.subscribe('toggleDatepickerError', (msg, data) => {
    setswadErrorMsg(data);
  });
  // only for swad tenant - subscribe to show datepicker error message text according to design.
  window.PubSub.subscribe('datePickerErrorMsg', (msg, data) => {
    setswadErrorMsgTxt(data);
  });

  const AddToCartButton = () => (
    <div className="btn-add-to-cart">
      <div className={`btn-primary ${attentionCheck ? 'disabled' : isDisabled ? 'disabled' : ''}`}>
        <DynamicContent
          tagName="button"
          innerHtml={
            (context.props.data && context.props.data.addToCartCTA && context.props.data.addToCartCTA.label) ||
            context.props.data.addToCart
          }
          attrs={{
            onClick:
              ((context.props.childVariant && context.props.childVariant === 'v-overlay-selected') ||
                context.props.data.cssClass === 'v-multi-product-widget') &&
              context.props.data.addToCartCTA &&
              !isDrivingExperience
                ? () => context.addToCart(context.props.data.addToCartCTA)
                : context.addToCart,
            disabled: attentionCheck || isDisabled,
            className: 'add-to-cart',
          }}
        />
      </div>
    </div>
  );
  const CheckoutButton = () => (
    <div className="btn-checkout">
      <div className={`btn-primary ${attentionCheck ? 'disabled' : isDisabled ? 'disabled' : ''}`}>
        <DynamicContent
          tagName="button"
          innerHtml={context.props.data.continueCheckoutCTA.label}
          attrs={{
            onClick: () =>
              context.addToCart(
                context.props.recommendationUrl && !context.props?.timeSlotData
                  ? { href: context.props.recommendationUrl }
                  : context.props.data?.continueCheckoutCTA,
              ),
            disabled: attentionCheck || isDisabled,
            className: 'continue-checkout',
          }}
        />
      </div>
    </div>
  );

  const PaymentButtons = () => {
    const { data } = context.props;
    const addToCartLabel = data?.addToCartCTA?.label || data?.addToCart;
    return (
      <>
        {' '}
        {addToCartLabel && <AddToCartButton />}
        {context.props.data && context.props.data.continueCheckoutCTA && <CheckoutButton />}
      </>
    );
  };

  const RenderButtons = () => {
    if (isDrivingExperience)
      return (
        <div className="drivins-exp-btns">
          <PaymentButtons />
        </div>
      );

    return <PaymentButtons />;
  };
  const AmountAndButtons = () => (
    <>
      {!context.disableTotalPrice &&
        (context.state.slotSelected || context.isDynamicPricingProd) &&
        context.renderTotalAmount()}
      {/* {isDrivingExperience && context.renderTotalAmount()} */}

      {isSwad && swadErrorMsg && (
        <div className="error-group-booking">
          <DynamicContent tagName="div" innerHtml={swadErrorMsgTxt} />
        </div>
      )}
      {timeSlotFunction && timeSlotFunction()}
      {mobileAttention && mobileAttention()}
      <RenderButtons />
    </>
  );
  if (context.isDynamicPricingProd || isDrivingExperience)
    return (
      <div className="v-align-amount-and-buttons">
        <AmountAndButtons />
      </div>
    );

  return <AmountAndButtons />;
};

export default AmountWithButtons;
