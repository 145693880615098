/*
 * GPay Component
 * This file contains code for GPay Payment Component
 * @Author Miral
 * @licensor  Miral
 */
import classNames from 'classnames';
import React from 'react';
import GooglePayButton from '@google-pay/button-react';

import { PaymentAnalytics } from '../../../../common/analytics-events';
import { Logging } from '../../../../common/logger';
import { GPayService } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  getLoggedInUser,
  removeGuestUser,
  setYasId,
  checkAnonymousCart,
  checkInlineSignup,
  getAnonymousCart,
  isLoggedInUser,
  getMainObject,
  resolvePath,
  getAPIStateParam,
  isEmpty,
  getRecipientDetails,
  isEnvDevelopment,
  isMatchTenant,
  checkShippingDetails,
  getErrorMap,
  checkTenant,
  getSessionStorage,
  generateGUID,
  getMembershipDetails,
  removeLoader,
  setSessionStorage,
  canUseDOM,
  checkParksTenants,
  toLowerCase,
  getLocalStorageByKey,
  isTicketUpgradePayment,
  backToUpgradePage,
  isANPUpgradation,
} from '../../../../common/utility';
import { GPayComponentService } from './gpay-component-service';
import { OrderService } from '../../../../common/services';
import { environment } from '../../constants';
import GTMData from '../../b2c-purchase-journey/gtm-data';
import {
  scrollToServerError,
  isThereExtraErrors,
  checkYAEventId,
  consentRequest,
  checkAnnualPassError,
  checkAnnualUpgradeError,
} from '../utils';
import { setReconcilRequestAction } from '../../../../utils/paymentUtils';
class GPayComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEnabled: props.isEnabled,
      inPaymentSheetOpenMode: false,
      YIAnonymousUserEmailId: '',
      conscentFlag: 0,
    };

    this._isErrorOccured = props.isErrorOccured;

    this._componentSvc = new GPayComponentService({
      ...props.propsData,
      is2StepPaymentYasArena: props.is2StepPaymentYasArena,
    });
    this.paymentRequest = this._componentSvc.getPaymentRequest();

    this._orderDetails = null;

    this._services = {
      purchase: props.propsData.services.gpayMakePurchase.url,
      errors: props.propsData.services.gpayMakePurchase.errors,
    };

    this.isYI = checkTenant(UIConfig.iamMapping.yasisland);
  }
  componentDidMount() {
    if (this.isYI) {
      this.paymentRequest.transactionInfo.totalPrice = getSessionStorage('cartTotal').toString();
    } else {
      const cartData = checkInlineSignup() && checkAnonymousCart() ? getAnonymousCart().cart : this.props.cartData;
      this.paymentRequest.transactionInfo.totalPrice = cartData.grossPrice.toString();
      const paymentInfos = {
        cmsData: this.props.propsData,
      };
      localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(paymentInfos));
    }
  }
  /**
   *  Validate, if payment process can be started.
   *
   */
  canProceedToPay = () => {
    let proceedToPay = true;
    const tenantID = getLoggedInUser().tenantID;
    const cartData = checkInlineSignup() && checkAnonymousCart() ? getAnonymousCart().cart : this.props.cartData;
    const { inPaymentSheetOpenMode } = this.state;
    //if tnc checkbox is not clicked
    if (!this.props.isEnabled) {
      this.props.showTnCError(true);
      proceedToPay = false;
    }

    // don't proceed with payment, if user Guest Details are not filled
    if (!isLoggedInUser() && !inPaymentSheetOpenMode) {
      document.getElementById('submit-btn').click();
      if (this.props.guestUserHasErrors()) {
        proceedToPay = false;
      }
    }
    if (tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
      const isShippingRequired = checkShippingDetails(cartData);
      if (isShippingRequired) {
        proceedToPay = false;
      }
      if (proceedToPay) {
        GTMData.getTenantId() === UIConfig.ymcB2CTenant &&
          GTMData.push('checkout', {
            step: UIConfig.gtmStepFive,
            products: cartData.items,
            subTotal: cartData.grossPrice,
          });
      }
    }
    return proceedToPay;
  };

  callLoadMethod = () => {
    const googlepayButtonEle = document.getElementById('gpay-button-online-api-id');
    if (googlepayButtonEle) googlepayButtonEle.click();
  };

  callTravelboxApi = async (_guid, self) => {
    try {
      const services = this.props.propsData?.services;
      const configUrl = services?.TravelBoxPayfortConfig?.url.replace('{cartId}', getSessionStorage('cartId'));
      const response = await fetch(configUrl);
      const _data = await response.json();

      if (_data.CartId) {
        this._orderDetails = {
          ..._data,
          totalPrice: _data.TotalAmount,
          payfortAmount: _data.TotalAmount,
          reservationCode: _data.merchant_reference,
          orderID: _data.merchant_reference + _guid,
          merchant_extra: _data.CartId,
          merchant_extra1: _data.merchant_extra1,
          TravelBoxAddPaymentUrl: services?.TravelBoxAddPaymentUrl,
          TravelBoxConfirmBookingUrl: services?.TravelBoxConfirmBookingUrl,
        };
        this.paymentRequest.transactionInfo.totalPrice = _data.TotalAmount.toString();

        this.setState({ inPaymentSheetOpenMode: true });
        this.callLoadMethod();
        Logging(response, 'GPay_Component', true, `Gpay Pay - Step 2 - Order Id Created`);
      }
      // removeLoader();
      // const serverErrorElement = document.getElementsByClassName('yasid-email-error server-error');
      // if (serverErrorElement && serverErrorElement.length > 0) {
      //   return false;
      // }
    } catch (error) {
      this.setState({ inPaymentSheetOpenMode: false });
      this.createOrderErrorHandler(error);
      removeLoader();
    }
  };

  /**
   *  Handle Pay Button Click
   *  First Create Order and then begin Payment Process.
   */
  handlePayClick = async (e) => {
    if (isMatchTenant(UIConfig.YIB2C)) {
      try {
        if (isLoggedInUser()) {
          const loggedInUser = getLoggedInUser();
          setSessionStorage('userName', loggedInUser.userName);
        } else {
          let userName = document.querySelector('input[name="FirstName"]')?.value;
          if (userName.trim() !== '') {
            setSessionStorage('userName', userName);
          }
        }
      } catch (error) {}
    }

    let hasAnnualPassError = false;
    if (isTicketUpgradePayment() && isANPUpgradation()) {
      if (checkAnnualUpgradeError()) {
        const updatedAnnualPassError = sessionStorage.getItem('annualPassFormSuccess') === 'false' ? true : false;
        if (!updatedAnnualPassError) {
          hasAnnualPassError = false;
        } else {
          hasAnnualPassError = true;
        }
      }
    } else {
      if (checkAnnualPassError(this.props.cartData, this.handlePayClick)) {
        hasAnnualPassError = true;
      }
    }
    if (isMatchTenant(UIConfig.tenants.ya)) {
      GTMData.push(UIConfig.ga4Constants.ADD_PAYMENT_INFO, {
        cartData: this.props.cartData,
        paymentType: UIConfig.paymentOption.gPay,
      });
    }
    if (isMatchTenant(UIConfig.tenants.yi)) {
      GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
        name: toLowerCase(`${UIConfig.paymentOption.gPay} - ${UIConfig.paymentOption.gPay}`),
        [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(UIConfig.paymentOption.gPay),
        category: toLowerCase(UIConfig.ga4Constants.PAYMENT),
      });
    }

    // turnaround fix for not logged in user , it takes from the input element directly in case of not logged in user.
    if (this.props.newsLetterEnabled && this.state.conscentFlag < 1 && isLoggedInUser()) {
      const { MarketingConsentUpdateAPI } = this.props.services;
      consentRequest(MarketingConsentUpdateAPI?.url);
    }

    let self = this;
    if (!this.canProceedToPay() || this.props.isPaymentMethodBlocked || hasAnnualPassError) {
      e.preventDefault();
      e.stopPropagation();
      scrollToServerError(e);
      return false;
    }
    if (isThereExtraErrors(e)) return false;

    const isVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
    if (isVoucher) {
      window.PubSub.publish('formSubmitVoucher', true);
    }

    if (checkParksTenants() || isMatchTenant(UIConfig.tenants.ya)) {
      GTMData.push('addPaymentInfo', {
        cartData: this.props.cartData,
        paymentType: UIConfig.paymentOption.gPay,
      });
    }

    if (this.isYI) {
      if (!this.state.inPaymentSheetOpenMode) {
        const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
        const _isPostBookingJourney = getSessionStorage('addonAddedAfterConfirmation') === 'true' ?? false;
        const _guid = _isPostBookingJourney ? `_${generateGUID()}` : '';

        if (isLoggedInUser()) {
          if (!_isAddonAddedAfterBooking || _isAddonAddedAfterBooking === 'false') {
            const loggedInUser = getLoggedInUser();
            let userId = getSessionStorage('packageSummaryData');
            if (userId) userId = JSON.parse(userId).userId;
            const localStorageMem = getMembershipDetails('membershipData');
            const { segmentType, status, email } = localStorageMem;
            const isCorpUser = segmentType?.toLowerCase() === 'corporate' && status === 'enabled';
            this.setState({
              YIAnonymousUserEmailId: loggedInUser.email,
            });
            const { phoneCountryCode, mobileNum } = loggedInUser;
            let passengerObject = {
              Title: 'Mr.',
              FirstName: loggedInUser.userName,
              LastName: loggedInUser.lastName,
              Email: loggedInUser.email,
              ...(isCorpUser && { CorporateEmail: email }),
              Phone: mobileNum ? `${phoneCountryCode} ${mobileNum}` : '',
              CountryOfResidence: loggedInUser.country,
              Nationality: loggedInUser.nationalityAlphaCode,
              UserId: userId,
              AuthToken: loggedInUser.idToken,
            };
            const specialRequests = getSessionStorage('specialRequests');
            if (specialRequests) {
              passengerObject.SpecialRequests = JSON.parse(specialRequests);
            }

            const PassengerDetailsOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
              },
              body: JSON.stringify(passengerObject),
            };
            const passengerUrl = this.props.propsData?.services?.SetPassengerDetails.url.replace(
              '{cartId}',
              getSessionStorage('cartId'),
            );

            const passengerCallResponse = await fetch(passengerUrl, PassengerDetailsOptions);
            const passengerCallData = await passengerCallResponse.json();
            if (!passengerCallData?.isSuccess) {
              removeLoader();
              return;
            }
          }

          this.callTravelboxApi(_guid, self);
        }
        !isLoggedInUser() &&
          window.PubSub.subscribe('dataPosted', (msg, data) => {
            this.setState({
              YIAnonymousUserEmailId: data.email,
            });

            data.isDataPosted && this.callTravelboxApi(_guid, self);
          });
      }
    } else {
      const cartData = checkInlineSignup() && checkAnonymousCart() ? getAnonymousCart().cart : this.props.cartData;
      localStorage.setItem(UIConfig.localStoreKeys.payment.gpayPaymentData, JSON.stringify(this.props.propsData));
      if (cartData && !cartData.domain) {
        cartData.domain = window.location.origin;
      }
      this.paymentRequest.transactionInfo.totalPrice = cartData.grossPrice.toString();

      if (isLoggedInUser()) {
        const recipientDetails = getRecipientDetails();
        cartData.personalisedGiftObj = recipientDetails;
      }

      if (!this.state.inPaymentSheetOpenMode) {
        e.preventDefault();
        e.stopPropagation();

        this._componentSvc
          .createOrder(cartData, this.props.partnerRefernce, this.props.dueData)
          .then((response) => {
            if (isTicketUpgradePayment()) {
              this._orderDetails = response?.data;
              this.setState({ inPaymentSheetOpenMode: true }, () => {
                self.callLoadMethod();
              });
              Logging(response, 'GPay_Component', true, `GPay - Order Id Created`);
            } else {
              setYasId(response.data);
              let mainObj = getMainObject(),
                idToken = resolvePath(response.data, 'id_token'),
                defaultStateVal = getAPIStateParam(),
                signInData = { state: defaultStateVal, id_token: idToken },
                signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
              // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
              if (signInURL && idToken) {
                OrderService.yasIdAutoLogin(
                  'YasIdAutoLogin',
                  signInURL,
                  UIConfig.loader.defaultPreLoaderTarget,
                  signInData,
                )
                  .then((autoLoginRes) => {
                    this._orderDetails = response.data;
                    this.setState({ inPaymentSheetOpenMode: true }, () => {
                      self.callLoadMethod();
                    });
                    Logging(response, 'GPay_Component', true, `GPay - Order Id Created`);
                  })
                  .catch((err) => {
                    this.setState({ inPaymentSheetOpenMode: false });
                  });
              } else {
                this._orderDetails = response.data;
                this.setState({ inPaymentSheetOpenMode: true }, () => {
                  self.callLoadMethod();
                });
                Logging(response, 'GPay_Component', true, `GPay - Order Id Created`);
              }
            }
          })
          .catch(this.createOrderErrorHandler);
      }
    }
  };

  /**
   *  When Payment is cancelled due to inactivity of user OR user took more than 30 secs enter creditial
   *
   */
  onCancel = (e) => {
    Logging(e, 'GPay_Component', true, `GPay - Payment sheet closed`);
    this.setState({ inPaymentSheetOpenMode: false });
    this.removeSession();
    removeLoader();
  };

  // Handles Create Order Errors
  createOrderErrorHandler = (response_error) => {
    Logging(response_error, 'GPay_Component', true, `GPay - Order Id Generation Failed`);
    //this.removeSession();
    this.setState({ inPaymentSheetOpenMode: false });
    if (response_error.error) {
      if (isTicketUpgradePayment()) {
        return backToUpgradePage();
      }
      if (checkInlineSignup()) {
        this._isErrorOccured('createOrderYasId', response_error.error);
      } else {
        this._isErrorOccured('createOrder', response_error.error);
      }
      if (response_error.error.code) {
        PaymentAnalytics && PaymentAnalytics(response_error.error.code, response_error.error.text);
      }
    }
  };

  /**
   *  When payment is authorised by checkout
   *  re to thankyou page if payment is succesfull
   * throw error if payment is not successful
   */
  onPaymentAuthorised = (paymentData) => {
    Logging(paymentData, 'GPay_Component', true, `GPay - onPaymentAuthorised data received`);
    const self = this;
    this.setState({ inPaymentSheetOpenMode: false });
    const paymentDataRes =
      paymentData.paymentMethodData && !isEmpty(paymentData.paymentMethodData) && paymentData.paymentMethodData;
    const token = paymentDataRes.tokenizationData && paymentDataRes.tokenizationData.token;
    const cardType = paymentDataRes.info && paymentDataRes.info.cardNetwork;
    let cartData = {};
    if (!this.isYI) {
      cartData = isTicketUpgradePayment()
        ? getAnonymousCart().cart
        : checkInlineSignup() && checkAnonymousCart()
        ? getAnonymousCart().cart
        : this.props.cartData;
    }

    const svc = new GPayService();
    const { YIAnonymousUserEmailId } = this.state;
    const apiData = this._componentSvc.getGPayReconcileData(
      cartData,
      token,
      this._orderDetails,
      cardType,
      YIAnonymousUserEmailId,
    );
    if (apiData) {
      setReconcilRequestAction();
    }
    return new Promise(function(resolve, reject) {
      svc
        .makePurchase(apiData, self._services.purchase)
        .then((paymentRes) => {
          if (paymentRes && paymentRes.data && paymentRes.data.statusCode === 201) {
            Logging(paymentRes, 'GPay_Component', true, `GPay - MakePayment - Payment Successful`);
            self.reconcile(paymentRes.data);
            resolve({ transactionState: 'SUCCESS' });
          } else if (
            paymentRes &&
            paymentRes.data &&
            paymentRes.data.isSuccess &&
            (paymentRes.data.statusCode === 202 || paymentRes.data.isRedirect)
          ) {
            resolve({ transactionState: 'SUCCESS' });
            window.top.location.href = paymentRes.data.redirectURL;
          } else {
            //self._isErrorOccured('GPayMakePurchase', purchase_error.error);
            Logging(paymentRes, 'GPay_Component', true, `GPay - MakePayment - Payment Failed`);
            self.removeSession();
            resolve({
              transactionState: 'ERROR',
              error: {
                intent: 'PAYMENT_AUTHORIZATION',
                message: 'Something Went Wrong, please try again',
                reason: 'PAYMENT_FAILED',
              },
            });
          }
        })
        .catch((purchase_error) => {
          const error = purchase_error.error;
          const errorCode =
            error && error.errorRes && error.errorRes.data && error.errorRes.data.source
              ? error.errorRes.data.source.error
              : 'GPAY_001';
          const errorObj = getErrorMap('GPayMakePayment', self._services.errors, false, errorCode, null);
          Logging(purchase_error, 'GPay_Component', true, `GPay MakePayment - API Failed`);
          // self._isErrorOccured('GPayMakePurchase', purchase_error.error);
          self.removeSession();
          resolve({
            transactionState: 'ERROR',
            error: {
              intent: 'PAYMENT_AUTHORIZATION',
              message: errorObj['GPayMakePayment'].text,
              reason: errorObj['GPayMakePayment'].text,
            },
          });
        });
    });
  };

  /**
   *  When successfull -  redirects to Confirmation Page.
   *  When Failed -  close payment sheet and show error
   *  removepayment session.
   */
  reconcile = (reconcileData) => {
    let newOrderId = this._orderDetails.orderID || this._orderDetails?.sale?.ak;
    if (this.isYI) {
      newOrderId = this._orderDetails?.orderID?.split('_')[0];
    }
    this.redirectToThankYouPage(true, newOrderId, 'GPayMakePurchase');
  };

  /**
   *  In case of Error remove GPay session and order details.
   */
  removeSession = () => {
    this._orderDetails = null;
  };

  //Redirecting to thank you page
  redirectToThankYouPage = (status, orderId = 0) => {
    removeGuestUser();
    const tenantID = getLoggedInUser().tenantID;
    let isRenewAnnualPass = this.props.propsData.paymentOptions.pageVariant === 'annualPassRenew';
    if (isRenewAnnualPass) {
      sessionStorage.removeItem('cartForAnnualPassRenewal');
      sessionStorage.removeItem(UIConfig.payloadForRenew);
      sessionStorage.removeItem('annualPassRenewalData');
    }
    let annualPassRenew = isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
    const ticketUpgrade = isTicketUpgradePayment() ? '&isTicketUpgrade=true' : '';
    if (status) {
      if (tenantID !== 'ALLB2B') {
        localStorage.removeItem('yasIdUserData');
        const newOrderId = this.isYI ? btoa(orderId) : orderId;
        window.top.location.href =
          this.props.propsData.paymentOptions.primaryCTA.url +
          '?' +
          checkYAEventId() +
          UIConfig.querStringParams.thankYouPage.status +
          '=' +
          status +
          '&' +
          UIConfig.querStringParams.thankYouPage.orderId +
          '=' +
          newOrderId +
          '&' +
          UIConfig.querStringParams.thankYouPage.reference +
          '=' +
          UIConfig.paymentOption.gPay +
          annualPassRenew +
          ticketUpgrade;
      } else {
        window.top.location.href =
          this.props.propsData.paymentOptions.primaryCTA.url +
          '?' +
          UIConfig.querStringParams.thankYouPage.status +
          '=' +
          status +
          '&' +
          UIConfig.querStringParams.thankYouPage.orderId +
          '=' +
          encodeURIComponent(orderId) +
          '&' +
          UIConfig.querStringParams.thankYouPage.reference +
          '=' +
          UIConfig.paymentOption.gPay;
      }
    } else {
      window.top.location.href =
        this.props.propsData.paymentOptions.primaryCTA.url +
        '?' +
        UIConfig.querStringParams.thankYouPage.status +
        '=' +
        status +
        '&' +
        UIConfig.querStringParams.thankYouPage.reference +
        '=' +
        UIConfig.paymentOption.gPay;
    }
  };

  componentWillUnmount() {
    this.removeSession();
  }

  handleLoadPaymentData(paymentRequest) {
    Logging(paymentRequest, 'GPay_Component', true, `GPay - onLoadPaymentData data request received`);
    this.onPaymentAuthorised(paymentRequest);
  }
  handleOnError = (e) => {
    Logging(e.error, 'GPay_Component', true, `GPay - onError event logs`);
  };

  render() {
    const classes = classNames('gpay-wrapper', {
      disabled: this.props.hasADCBProductInCart,
    });

    return (
      <>
        {this.props.emailConfirmationText}
        <GooglePayButton
          environment={isEnvDevelopment() ? environment.TEST : environment.PRODUCTION}
          paymentRequest={this.paymentRequest}
          onClick={(e) => this.handlePayClick(e)}
          onCancel={(e) => this.onCancel(e)}
          buttonType="plain"
          className={classes}
          buttonColor={isMatchTenant(UIConfig.ymcB2CTenant) ? 'white' : 'default'}
          buttonSizeMode="fill"
          onLoadPaymentData={(paymentRequest) => this.handleLoadPaymentData(paymentRequest)}
          //onPaymentDataChanged={(paymentData) => this.paymentDataChanged(paymentData)}
          onError={(e) => this.handleOnError(e)}
          // onPaymentAuthorized={(paymentData) => this.onPaymentAuthorised(paymentData)}
        />
      </>
    );
  }
}

GPayComponent.displayName = 'GPayComponent';
export { GPayComponent as GPay };
