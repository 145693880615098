import React from 'react';
import UIConfig from '../../../../common/UIConfig';
import { getMainObject, resolvePath } from '../../../../common/utility';

const PayFortForm = ({
  data,
  lang,
  generatedMerchantReference,
  signature,
  merchantExtra,
  merchantExtra1,
  payfortType,
  cartData,
  payData,
}) => {
  const isTabby = payfortType === UIConfig.paymentOption.tabby;

  const serviceCommand = {
    Tabby: { name: 'command', value: data.TabbyServiceCommand ?? 'PURCHASE' },
    CreditCard: { name: 'service_command', value: data.serviceCommand },
    [UIConfig.paymentOption.adcbTouchPoints]: { name: 'service_command', value: data.serviceCommand },
    [UIConfig.paymentOption.moneyCard]: { name: 'service_command', value: data.serviceCommand },
  };
  return (
    <div>
      <input type="hidden" {...serviceCommand[payfortType]} />
      <input type="hidden" name="access_code" value={data.accessCode} />
      <input type="hidden" name="merchant_identifier" value={data.merchantIdentifier} />
      <input type="hidden" name="language" value={lang} />
      <input type="hidden" name="return_url" value={data.interMediatePageUrl} />
      <input type="hidden" name="merchant_reference" value={generatedMerchantReference} id="merchant_reference" />
      <input type="hidden" name="signature" id="signature" value={signature} />
      {resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.YIB2C && (
        <>
          <input type="hidden" name="merchant_extra" id="merchant_extra" value={merchantExtra} />
          <input type="hidden" name="merchant_extra1" id="merchant_extra1" value={merchantExtra1} />
        </>
      )}
      {isTabby && (
        <>
          <input type="hidden" name="customer_email" id="customer_email" />
          <input type="hidden" name="phone_number" id="phone_number" />
          <input type="hidden" name="order_description" id="order_description" />
          <input type="hidden" name="amount" value={cartData.grossPrice * 100} />
          <input type="hidden" name="currency" value={payData?.currency}></input>
          <input type="hidden" name="payment_option" value={UIConfig.paymentOption.tabby.toUpperCase()} />
          <input type="hidden" name="settlement_reference" id="settlement_reference" />
        </>
      )}
      <input type="submit" className="hide" name="btn_submit" value="Submit" />
    </div>
  );
};

export default PayFortForm;
