import React, { useEffect, useState } from 'react';
import IAMPopup from '../../../presentation/iam-popup/iam-popup';
import UIConfig from '../../../../common/UIConfig';
import classNames from 'classnames';
import { AnchorLink, DynamicContent, SvgSprite } from '../../../presentation/base';
import Tooltip from '../../../presentation/tooltip/tooltip-component';
import { logComponentRenderingError } from '../../../../common/logger';
import {
  openIAMOverlay,
  getMainObject,
  getLoggedInUser,
  resolvePath,
  canUseDOM,
  setSessionStorage,
  isMatchTenant,
  toLowerCase,
  checkIfParks,
} from '../../../../common/utility';
import { setLoginGTM } from '../../../../common/analytics-events';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import GTMData from '../gtm-data';

const GTMDataOnClickCTA = (data) => {
  if (isMatchTenant(UIConfig.tenants.fwad) || isMatchTenant(UIConfig.tenants.yi)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
      name: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.name) : data.name,
      [UIConfig.ga4Constants.ELEMENTTEXT]: isMatchTenant(UIConfig.tenants.yi)
        ? toLowerCase(data.elementText)
        : data.elementText,
      category: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.category) : data.category,
    });
  }
};

const renderProfileTooltip = (tooltipId, toolTip, tabIndex) => {
  const tooltipClasses = classNames('header-bottom-profile', { 'hide-anchor': tabIndex === '-1' });

  return (
    <div className="c-ticket-tooltip">
      <Tooltip
        onHover={true}
        wrapperClass={tooltipClasses}
        buttonClass="c-header-top-icon profile"
        buttonInnerHtml={
          <div>
            <SvgSprite id={tooltipId} cssClass="tooltip-icon" />
          </div>
        }
        tabIndex="0"
        ariaLabel={toolTip}
      >
        <div className="ticket-tooltip-body">
          <DynamicContent tagName="div" innerHtml={toolTip} attrs={{ className: 'description' }} />
        </div>
      </Tooltip>
    </div>
  );
};
const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
const isSwadCheck = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.SWADB2C;

/*
   render checkout buttons
   @params: cartItems
   @params:data
   @params:error 
*/
const CheckoutButtons = ({
  propData,
  invalidProductQuantity,
  isAnnualPass,
  is2StepJourney,
  tabIndex,
  enableLoginButton,
  isRecipientDetails,
  isSwad,
  showCrossSell,
  isExpressCheckout,
}) => {
  const [isProductId, setProductId] = useState();

  useEffect(() => {
    const { eventId } = YALocalStorageSvc.getSetEventDetails();
    setProductId(eventId || '');
  }, []);

  try {
    const { ctaCheckout, cartType, orLabel, toolTip, variant, cartTitle } = propData && propData.data;
    const items = propData.cart && propData.cart.items;
    const mainObj = getMainObject();
    const enableYasIDVersion =
      mainObj && mainObj.additionalProperty ? mainObj.additionalProperty.enableYasIDVersion : false;
    const { errObj, bErrObj } = propData;
    const checkoutBtnSize = ctaCheckout.length;
    const loggedInUser = getLoggedInUser();
    const tooltipId = is2StepJourney ? 'tooltip-icon-tickets-2step' : 'tooltip-icon-tickets';
    const isBookDateB2C = variant === 'B2C-bookDate';

    return (
      !propData.cartSummaryExpandedView && (
        <div className="c-my-cart-b2c-container">
          <div className="c-b2c-cart-content">
            <div
              className={`cart-summary-container ${
                isExpressCheckout ? 'express-checkout-main-title checkout-buttons' : ''
              }`}
            >
              <div
                className={
                  errObj || bErrObj || invalidProductQuantity
                    ? 'checkout-button btn-primary disabled'
                    : 'checkout-button'
                }
              >
                {ctaCheckout &&
                  ctaCheckout.map((paymentButton, index) => {
                    let guestCheckoutCTAFound = false;
                    let annualPassloginBtnAsGuest = false;
                    if (
                      cartType !== UIConfig.b2c.purchaseJourney.cartVariant.miniCart ||
                      (cartType !== UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart && items)
                    ) {
                      guestCheckoutCTAFound =
                        items &&
                        items.some(
                          (item) =>
                            item.productType.indexOf('AnnualPass') > -1 && paymentButton.class === 'guest-checkout',
                        );
                      annualPassloginBtnAsGuest =
                        items &&
                        items.some((item) => item.productType.indexOf('AnnualPass') > -1 && loggedInUser == null);
                    }

                    const buttonClasses = classNames({
                      'annualpass-guest-login': annualPassloginBtnAsGuest,
                      [paymentButton.class]: !annualPassloginBtnAsGuest && (checkoutBtnSize > 1 || isBookDateB2C),
                      'btn-primary':
                        !isRecipientDetails && !(!annualPassloginBtnAsGuest && (checkoutBtnSize > 1 || isBookDateB2C)),
                      'btn btn-primary annualPass-login-btn': isAnnualPass,
                    });
                    const anchorClasses = classNames('form-wrapper-buttons-anchor', {
                      'link-disable': !enableLoginButton && isBookDateB2C,
                      disabled: isSwad && invalidProductQuantity,
                    });

                    //TODO: Need to verify this link and remove if no other tenant uses, already we have anchor link below for cart page
                    return (
                      !guestCheckoutCTAFound && (
                        <div>
                          {/* <div className={paymentButton.class}>
                            <AnchorLink link={{ ...paymentButton }} />
                          </div> */}
                          {index > 0 && orLabel && (
                            <DynamicContent
                              tagName="p"
                              innerHtml={orLabel}
                              attrs={{
                                className: `or-label`,
                              }}
                            />
                          )}
                          <div className={buttonClasses}>
                            {paymentButton.href && paymentButton.href.length > 0 ? (
                              enableYasIDVersion === 'true' && !propData.isYASIDVersionEnable ? (
                                <button
                                  type="button"
                                  className="form-wrapper-buttons-anchor"
                                  tabIndex={tabIndex || 0}
                                  onClick={() => {
                                    openIAMOverlay(<IAMPopup loginURL={paymentButton.href} />);
                                    !checkIfParks() && setLoginGTM();
                                  }}
                                >
                                  {isAnnualPass ? paymentButton.ctaText : paymentButton.label}
                                </button>
                              ) : isSwad && propData.cartData.cart.items.length === 0 ? (
                                <AnchorLink
                                  className={anchorClasses}
                                  link={{
                                    href:
                                      showCrossSell && propData.recommendationUrl
                                        ? propData.recommendationUrl
                                        : paymentButton.href,
                                    label: isAnnualPass ? paymentButton.ctaText : paymentButton.label,
                                    ariaHidden: true,
                                  }}
                                  tabIndex="-1"
                                />
                              ) : (
                                <AnchorLink
                                  className={anchorClasses}
                                  link={{
                                    href:
                                      showCrossSell && propData.recommendationUrl
                                        ? propData.recommendationUrl
                                        : `${paymentButton.href}${
                                            mainObj?.tenantID.toLowerCase() ===
                                              UIConfig.yasArenaB2CTenant.toLowerCase() && isProductId
                                              ? `?eventid=${isProductId}`
                                              : ''
                                          }`,
                                    label: isAnnualPass
                                      ? paymentButton.ctaText
                                      : `${paymentButton.label} <span class="btn-icon"><span>`,
                                  }}
                                  tabIndex={invalidProductQuantity ? -1 : 0}
                                  onClick={() => {
                                    if (isMatchTenant(UIConfig.tenants.ya)) {
                                      GTMData.push(UIConfig.ga4Constants.BEGIN_CHECKOUT, { ...propData });
                                    }
                                    const elementText = isAnnualPass ? paymentButton.ctaText : paymentButton.label;
                                    GTMDataOnClickCTA({
                                      name: `${cartTitle} - ${cartType} - ${elementText}`,
                                      elementText,
                                    });
                                  }}
                                />
                              )
                            ) : isSwadCheck ? (
                              <DynamicContent
                                tagName="p"
                                attrs={{
                                  className: `form-wrapper-buttons-anchor`,
                                }}
                                innerHtml={paymentButton.label}
                              />
                            ) : isAnnualPass ? null : (
                              <DynamicContent
                                tagName="p"
                                attrs={{
                                  className: `form-wrapper-buttons-anchor`,
                                }}
                                innerHtml={paymentButton.label}
                              />
                            )}

                            {paymentButton.tooltip && !isAnnualPass && toolTip && (
                              <div className="header-bottom">
                                {index === 0 && renderProfileTooltip(tooltipId, toolTip, tabIndex)}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    );
                  })}
                {isExpressCheckout && isSwadCheck && (
                  <button
                    className="close-express-checkout"
                    onClick={() => {
                      window.PubSub.publish(UIConfig.events.DISABLE_INLINE_SIGNUP, { enabled: true });
                    }}
                  ></button>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CheckoutButtons');
  }
};
export default CheckoutButtons;
