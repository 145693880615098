import * as coveoApi from '../../../../common/coveo-api';
import * as services from '../../../../common/services';
import * as utility from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import GTMData from '../gtm-data';

export default class SeatedJourneyActions {
  constructor(data) {
    this.data = data;
    this._tenantId = utility.getLoggedInUser().tenantID;
  }

  /**
   *  @param {[String]} orderId OrderId of cart created on Viva page
   *  @returns {[Object]}  seated Product Array
   */
  getSeatedProduct = (orderId) => {
    const url = this.data.serviceUrl.readOrderDetails && this.data.serviceUrl.readOrderDetails.url;
    return new Promise((resolve, reject) => {
      services.CartService.getOrderDetails('GetOrderDetails', url, orderId)
        .then((response) => {
          let resData = response.data;
          if (resData && resData.error) {
            reject({
              error: {
                code: resData.error.errorcode,
                text: resData.error.errordescription,
              },
            });
          } else if (resData.orderdetails) {
            let order = resData.orderdetails.order;
            const orderStatus = order.status.toUpperCase();

            if (orderStatus === 'DELETED' || orderStatus === 'COMPLETED') {
              return resolve({ products: [] });
            }
            seatedLocalStorageSvc.RemoveSeatedJourneyKeys(
              UIConfig.b2c.purchaseJourney.ymcMapping.localStorageKey.seatedOrderDetails,
            );
            seatedLocalStorageSvc.GetSetSaleAk(orderId);

            const coveoDefaultParams = {
              perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
              coveoKeyMap: this.data.coveoMappingList,
              serviceUrl: this.data.serviceUrl.getCoveoProducts && this.data.serviceUrl.getCoveoProducts.url,
              fieldsToInclude: Object.values(this.data.coveoMappingList),
              lang: utility.getFallbackLanguage(),
            };

            //Get all ProductId's for coveo call.
            const productIds = order.items.map((item) => item.productId);
            const queryParams = [
              {
                key: coveoDefaultParams.coveoKeyMap.productId,
                value: productIds,
              },
            ];

            coveoApi
              .getB2CProductDetails({
                ...coveoDefaultParams,
                queryParams: queryParams,
              })
              .then((res) => {
                let mappedProductsData = utility.createMappedData(res.results, coveoDefaultParams.coveoKeyMap);
                mappedProductsData = this.enrichData(mappedProductsData, order);
                GTMData.push('addToCart', { products: mappedProductsData });
                // update the url
                utility.canUseDOM() && window.history.replaceState(null, null, window.location.pathname);
                return resolve({ products: mappedProductsData });
              })
              .catch(this.catchHandler(reject));
          }
        })
        .catch(this.catchHandler(reject));
    });
  };

  /*
        get string from semi-colon to weekdays Array
    */
  getArray = (product, key) => {
    if (product && product.hasOwnProperty(key) && product[key]) {
      return product[key].split(';');
    }
    return [];
  };

  getFilteredCartItems = (cartItems, seatedProducts) => {
    let productList = [];
    let allRelatedProducts = {};
    const products = cartItems && cartItems.filter((item) => !utility.isF1SeatedTypeProduct(item));
    const seatedProdsInCart = cartItems && cartItems.filter((item) => utility.isF1SeatedTypeProduct(item));
    /*
            Note - This logic need to be updated to work in edit mode
            removing seated related add-ons from cart
         */
    if (products && seatedProducts && seatedProdsInCart) {
      seatedProdsInCart.forEach((product) => {
        const relatedProductIds = this.getArray(product, 'relatedProductIds');
        relatedProductIds.forEach((item) => {
          if (!allRelatedProducts.hasOwnProperty(item)) {
            allRelatedProducts[item] = product.productId;
          }
        });
      });
    }
    productList =
      products &&
      products.filter((item) => {
        const sampleProd = Object.keys(allRelatedProducts);
        return sampleProd.indexOf(item.productId) === -1;
      });
    return [...productList, ...seatedProducts];
  };

  getProductType = (item) => {
    if (item.tab && item.tab.length) {
      return item.tab[0];
    }
  };

  enrichData = (products, order) => {
    const result = products.map((prod) => {
      const item = order.items.find((or) => or.productId === prod.productId);
      if (item) {
        prod.fromDate = '';
        prod.toDate = '';

        prod.productIdType = utility.getProdIdType(prod);

        prod.price = {};
        prod.price.gross = Number(item.price.gross) || 0;
        prod.price.net = Number(item.price.net) || 0;
        prod.price.tax = Number(item.price.tax) || 0;
        prod.unitPrice = prod.unitPrice || prod.gross;

        prod.quantity = parseInt(item.quantity, 10);
        prod.currQuantity = parseInt(item.quantity, 10);
        prod.groupedQuantity = parseInt(item.quantity, 10);

        prod.performanceId = item.performances[0].performanceId;

        prod.discount = prod.discount || {}; //for checkbasket
        prod.discount.actualPerc = prod.discount.actualPerc || 0; //for checkbasket
        prod.discount.computed = prod.unitPrice ? prod.unitPrice - prod.gross : 0;

        prod.guestDetails = prod.guestDetails || null; //for checkbasket
        prod.seatInfo = utility.getSeatInfo(item);
        prod.productType = this.getProductType(item) || '';
        prod.seatId = this.getSeatIds(item);
        prod.timeStamp = order.date;
        prod.shippingEventType = prod.eventType; // for shipping charges calculation
      }
      return prod;
    });
    return result;
  };

  catchHandler = (reject) => (error) => {
    reject({
      error: {
        code: error.error.errorcode,
        text: error.error.errordescription,
      },
    });
  };

  /**
   * Get Seat Id's
   */

  getSeatIds = (item) => {
    let seatId = [];
    item.tickets.forEach((ticket) => {
      if (ticket.Seat) {
        seatId.push(ticket.Seat.seatId);
      }
    });
    return seatId;
  };

  getReplaceUrl = (url, key, value) => {
    return url.replace(key, value);
  };

  getLocaleValue = () => {
    const { engLangCode } = UIConfig.b2c.purchaseJourney.ymcMapping;
    let lang = utility.getCurrentLanguage() || '';
    if (lang) {
      lang = this.getReplaceUrl(lang, '-', '_');
      if (lang.toLowerCase() === engLangCode) {
        lang = `${lang}_US`;
      }
    }
    return lang;
  };

  /**
     *  Handles redirect to viva cart page. this method is responsible for :
     *      -  gets performance Id and redirects to viva cart page
     *      -
     *  @param  {[object]}  product  product which is redirected
     *  @param  {[boolean]}  showRedirectLoader to display loader
     *  @param  {[object]}  serviceUrl URL of viva cart page

     */

  handleRedirection = ({ serviceUrl, product, showRedirectLoader }) => {
    let redirectUrl = '',
      returnUrl = '',
      productId = '',
      performanceId = '';
    if (serviceUrl && product) {
      redirectUrl = serviceUrl.seatedJourneyRedirection && serviceUrl.seatedJourneyRedirection.url;
      returnUrl = serviceUrl.seatedJourneyReturn && serviceUrl.seatedJourneyReturn.url;
      productId = product.productId;
      performanceId = product.performanceId;
    }
    const locale = this.getLocaleValue();
    let urlPerformanceAk = this.getReplaceUrl(redirectUrl, '{0}', performanceId);
    let urlProductAk = this.getReplaceUrl(urlPerformanceAk, '{1}', productId);
    let urlReturn = this.getReplaceUrl(urlProductAk, '{2}', returnUrl);
    let finalUrl = this.getReplaceUrl(urlReturn, '{3}', locale);
    showRedirectLoader && utility.addLoaderOverlay();

    utility.redirectTo(finalUrl);
  };
}

export class seatedLocalStorageSvc {
  static _getDecryptedValue = (key) => {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(utility.decryptParam(item));
    }
    return '';
  };

  static _setEncryptedValue = (key, value) => {
    utility.setLocalStorage(key, utility.encryptParam(JSON.stringify(value)));
  };

  static GetSetOrderDetails = (orderDetails) => {
    const { seatedOrderDetails } = UIConfig.b2c.purchaseJourney.ymcMapping.localStorageKey;
    if (utility.canUseDOM()) {
      if (orderDetails) {
        seatedLocalStorageSvc._setEncryptedValue(seatedOrderDetails, orderDetails);
      } else {
        return seatedLocalStorageSvc._getDecryptedValue(seatedOrderDetails) || {};
      }
    }
  };

  static GetSetSaleAk = (saleAk) => {
    const { seatedCheckoutOrderId } = UIConfig.b2c.purchaseJourney.ymcMapping.localStorageKey;
    if (utility.canUseDOM()) {
      if (saleAk) {
        seatedLocalStorageSvc._setEncryptedValue(seatedCheckoutOrderId, saleAk);
      } else {
        return seatedLocalStorageSvc._getDecryptedValue(seatedCheckoutOrderId) || '';
      }
    }
  };

  static RemoveSeatedJourneyKeys = (removeKey) => {
    const localStorageKeys = UIConfig.b2c.purchaseJourney.ymcMapping.localStorageKey;
    if (utility.canUseDOM() && removeKey) {
      localStorage.removeItem(localStorageKeys[removeKey]);
    } else if (utility.canUseDOM()) {
      for (const key in localStorageKeys) {
        localStorage.removeItem(localStorageKeys[key]);
      }
    }
  };
}
