import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import LazyLoad from 'react-lazyload';
import { parseQueryString, canUseDOM, isLoggedInUser, resolvePath } from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';

const notToLazyLoad = [
  'Cookie',
  'SEO',
  'WishlistNotification',
  'Widget',
  'Analytics',
  'AnalyticsBody',
  'AnalyticsNoRefresh',
  'Header',
  'HotelBookingWidget',
  'MediaCarouselWrapper',
];
const toLazyLoad = ['MyCart', 'PaymentComponent', 'PurchaseJourney'];
const loggedInExclusionList = ['GuestCheckoutForm'];

/**
 *
 * @param {Component} WrappedComponent is component to be returned with updated Props
 * @param {Component} props is the props passed to the component
 * @param {Component} propsData is data passed with/without 'data' node
 * @param {Component} isDCR is flag used to differentiate whether it's from Default content resolver component or not
 */
const ActionComponent = ({ WrappedComponent, props }) => {
  const { componentName } = props.rendering || props || {};
  const { renderingType, csrContract } = props.fields;
  const data = props.fields.data || props.fields || null;
  const [finalData, setData] = useState(data);
  const getPersonalizedDataFromCache = parseQueryString('personalized');
  const envElm = canUseDOM() && document.getElementById('__ENV_VARIABLES__');
  const getEnvVariables = canUseDOM() && envElm && envElm.innerHTML.trim();

  useEffect(() => {
    if (renderingType === 'CSR') {
      if (getPersonalizedDataFromCache === 'true' && sessionStorage.getItem(`${componentName}`)) {
        const csrData = JSON.parse(sessionStorage.getItem(`${componentName}`));
        setData(csrData);
      } else {
        Axios.get(`${csrContract}`)
          .then((res) => {
            let csrData = res.data.elements[0].contents.data;
            sessionStorage.setItem(`${componentName}`, JSON.stringify(csrData));
            setData(csrData);
          })
          .catch((err) => {
            logComponentRenderingError(err, `${WrappedComponent.name} GlobalHOC`);
          });
      }
    }
  }, [renderingType, csrContract, getPersonalizedDataFromCache, componentName, WrappedComponent]);

  try {
    const disAllowedFormtypes = ['expressCheckoutSignIn', 'managebooking'];
    //Do not load cached component for loggedIn User
    if (
      isLoggedInUser() &&
      loggedInExclusionList.includes(componentName) &&
      !disAllowedFormtypes.includes(data?.formType)
    ) {
      return <></>;
    }
    if (finalData) {
      const isLazyLoad = finalData.isLazyLoad === false ? false : true;
      const finalLazyLoad =
        !isLazyLoad && toLazyLoad.includes(componentName)
          ? toLazyLoad.filter((item) => item.toLowerCase() !== componentName.toLowerCase())
          : toLazyLoad;
      let newProps = Object.assign({}, props);
      const isDCR = !newProps.fields.data;
      const obj = Array.isArray(finalData) ? [] : {};
      if (isDCR) {
        newProps.fields = Object.assign(obj, finalData);
      } else {
        newProps.fields.data = Object.assign(obj, finalData);
      }

      const lazyLoad =
        canUseDOM() && JSON.parse(getEnvVariables) !== null
          ? JSON.parse(envElm.innerHTML).lazyLoad
          : process.env.LAZYLOAD || 'false';

      if (lazyLoad && JSON.parse(lazyLoad)) {
        if (notToLazyLoad.includes(componentName)) {
          return <WrappedComponent {...newProps} />;
        }
        return (
          <LazyLoad height={100} offset={50}>
            <WrappedComponent {...newProps} />
          </LazyLoad>
        );
      } else if (finalLazyLoad.includes(componentName)) {
        return (
          <LazyLoad height={100} offset={50}>
            <WrappedComponent {...newProps} />
          </LazyLoad>
        );
      } else {
        return <WrappedComponent {...newProps} />;
      }
    } else {
      return <></>;
    }
  } catch (err) {
    return logComponentRenderingError(
      err,
      `${WrappedComponent.name} GlobalHOC`,
      props.fields.data.variant || resolvePath(props.fields.Variant, 'fields.ComponentClass.value', ''),
    );
  }
};

export default ActionComponent;
