import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { logComponentRenderingError, Logging } from '../../../../common/logger';
import LabelComponent from '../label-component';
import FieldErrorComponent from '../field-error';
import { validateJSSField } from '../rules-validator';
import { resolvePath, isLoggedInUser } from '../../../../common/utility';
import { getEnvProxyUrl } from '../../../../common/forms-utility';
import { FormServices } from '../../../../common/services';
import classNames from 'classnames';
import UIConfig from '../../../../common/UIConfig';

const DropDown = (props) => {
  const { tracker, field, errors } = props,
    valueFieldName = field.valueField.name,
    valueFieldId = field.valueField.id,
    fieldName = field.model.name,
    cascadingUrl = resolvePath(field, 'model.cascading', '');

  const [dropDownFieldItems, setDropDownFieldItems] = useState(resolvePath(field, 'model.items', []));

  const [isFocus, setFocus] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errors);
  const getInitialValue = () => {
    const initialObj = dropDownFieldItems.find((item) => item.selected) || {};
    return resolvePath(initialObj, 'value', '');
  };
  const [fieldValue, setFieldValue] = useState(getInitialValue());

  const updateCountryOrder = (countryCode) => {
    const updatedItems = dropDownFieldItems;
    for (let i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].value === countryCode) {
        updatedItems.unshift(updatedItems[i]);
        break;
      }
    }
    setDropDownFieldItems(updatedItems);
  };

  const getMyGeoLoc = () => {
    const geoLocationObj = getEnvProxyUrl();
    let url = UIConfig.geoLocation.httpsUrl;
    if (geoLocationObj.isAkamai === 'false') {
      url = geoLocationObj.siteCoreApiUrl + url;
    }

    FormServices.getGeoLocation({
      moduleName: 'DropDown',
      serviceUrl: url,
      preLoader: false,
    })
      .then((res) => {
        if (res.data) {
          const countryCode = res.data.Country || res.data.country;
          updateCountryOrder(countryCode);
          countryCode && handleChange(countryCode);
        }
      })
      .catch((err) => {
        Logging(err, 'GEO Location API failed');
      });
  };

  useEffect(() => {
    if (!isLoggedInUser() && field.model.geoIPEnabled) {
      getMyGeoLoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  useEffect(() => {
    setDropDownFieldItems(resolvePath(field, 'model.items', []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.model.items]);

  useEffect(() => {
    const { apiFieldValue } = props;
    apiFieldValue && handleChange(apiFieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiFieldValue]);

  const getFieldsByItemId = (inputValue) => {
    const dropDownSelectedItem = dropDownFieldItems.find((item) => inputValue === item.value);
    props.dropDownHandler && props.dropDownHandler({ dropDownSelectedItem, valueFieldName, cascadingUrl, fieldName });
  };

  const handleChange = (inputValue) => {
    let valid = true,
      errorMessages = [];
    const errorMessagesObj = validateJSSField(field, inputValue);
    const message = errorMessagesObj[valueFieldName];
    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }
    setErrorMsg(errorMessages);
    setFieldValue(inputValue);
    getFieldsByItemId(inputValue);
    props.onChange(valueFieldName, inputValue, valid, errorMessages);
    tracker.onBlurField(field, inputValue, errorMessages);
  };

  const onChange = (e) => {
    if (fieldValue !== e.target.value) {
      handleChange(e.target.value);
    }
    setFocus(false);
  };

  const onFocus = (e) => {
    setFocus(true);
  };

  try {
    const isErrorExist = errorMsg && errorMsg.length > 0;
    const alignmentClass = resolvePath(field, 'model.alignment', '');
    const customWrapperClasses = classNames('form-element select-box', { active: isFocus || fieldValue });
    const selectClasses = classNames('form-select body-copy-3', {
      'required--field': field.model.required,
      error: isErrorExist,
    });
    const fieldHasItems = dropDownFieldItems.length > 0;
    return (
      fieldHasItems && (
        <div className={`${customWrapperClasses} ${field.model.cssClass} ${alignmentClass}`}>
          <LabelComponent field={field} />
          <div>
            <select
              id={valueFieldId}
              name={valueFieldName}
              value={fieldValue}
              aria-required={field.model.required}
              data-fieldname={fieldName}
              onChange={onChange}
              onBlur={onChange}
              onFocus={onFocus}
              tabIndex={0}
              className={selectClasses}
            >
              <option value="">{field.model.title ? '' : field.model.placeholderText}</option>
              {dropDownFieldItems.map((opt, index) => {
                return (
                  <option key={`${opt.value}_${index}`} value={opt.value} disabled={opt.disabled || false}>
                    {opt.text}
                  </option>
                );
              })}
            </select>
          </div>
          <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'JSSDropDown');
  }
};

DropDown.propTypes = {
  field: PropTypes.shape({
    valueField: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string,
    }),
    model: PropTypes.shape({
      name: PropTypes.string.isRequired,
      items: PropTypes.array.isRequired,
      title: PropTypes.string,
      placeholderText: PropTypes.string,
    }),
    tracker: PropTypes.shape({
      onBlurField: PropTypes.func,
    }),
    onChange: PropTypes.func,
  }),
};

export default DropDown;
