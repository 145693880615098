/*
 * Paypal-component.js
 * This file contains code for PayPal Payment Component
 * @licensor  Miral
 */
import { checkoutAnalytics, PaymentAnalytics } from '../../../../common/analytics-events';
import { Logging } from '../../../../common/logger';
import { CartService, OrderService, PayPalServices, ServiceConfig } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  addLoaderOverlay,
  checkAnonymousCart,
  getErrorMap,
  getLocale,
  getLoggedInUser,
  getPromoCode,
  getUserAgent,
  massageMobileData,
  parseQueryString,
  removeGuestUser,
  removeLoaderOverlay,
  removePromoCode,
  roundToTwoPlaces,
  setOrderIdpromoCode,
  isAnnualPassCase,
  getSessionStorage,
  createAnnualPassData,
  checkInlineSignup,
  setYasId,
  getAnonymousCart,
  isLoggedInUser,
  getMainObject,
  resolvePath,
  getAPIStateParam,
  getRecipientDetails,
  setSessionStorage,
  generateGUID,
  getLocalStorageByKey,
  checkTenant,
  isEmpty,
  getLanguageWithoutLocal,
} from '../../../../common/utility';
import { savePaymentFailureStatus } from '../../../../common/b2b-purchase-journey-utility';
import ApiWrapper from '../../../../common/api-wrapper';
import { checkYAEventId } from '../utils';
/**
 * defualt variables declared
 *
 */
let config = {
    amount: 0,
    locale: getLocale(),
    deviceData: null,
    tenantId: ServiceConfig.getTenantId(),
    serviceKey: {
      sendTransaction: 'sendTransaction',
      createOrder: 'createOrder',
      getToken: 'getToken',
      default: 'default',
    },
    error: {
      code: 'default',
      text: '',
    },
    paypalCartData: '',
  },
  showError = () => {
    //Please comment purpose of the empty block
  };

//Converting USD to AED
const convertAedToUsd = (price, exhngRate) => {
  return roundToTwoPlaces(price / exhngRate);
};
//Redirecting to thank you page
const redirectToThankYouPage = (data) => {
  removeGuestUser();
  let isRenewAnnualPass = config.data.pageVariant === 'annualPassRenew';
  if (isRenewAnnualPass) {
    sessionStorage.removeItem('cartForAnnualPassRenewal');
    sessionStorage.removeItem(UIConfig.payloadForRenew);
    sessionStorage.removeItem('annualPassRenewalData');
  }
  canUseDOM() && localStorage.removeItem('isOrderHistoryPayment');
  canUseDOM() && localStorage.removeItem('yasIdUserData');
  let annualPassRenew = isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
  window.location.replace(config.data.thankYouPageUrl + data + annualPassRenew);
};

const getGuestUserStatus = () => {
  const cart = getAnonymousCart();
  if (cart) {
    return cart;
  }
  return false;
};

const getCartData = () => {
  window.PubSub.publish('paypalLoaded', { paypalLoaded: true });
  window.PubSub.subscribe(UIConfig.events.ONCARTUPDATE, (msg, data) => {
    config.paypalCartData = { cart: data.cart };
    config.amount = convertAedToUsd(data.cart.grossPrice, config.data.conversionRate);
  });
};

// Send Transaction API call B2B
const b2bDoTransaction = (data) => {
  const userAgent = getUserAgent();
  const sendTransactionURL =
    config.data.B2B_isCreditSettlement || config.data.B2B_isAdvanceTopUp
      ? config.data.services.sendTransactionAdcs.url
      : config.data.services.sendTransaction.url;
  const encryptionParam = parseQueryString('notEncrypted') ? '?isEncrypted=false' : '';
  PayPalServices.sendPayPalTrans(
    config.data.componentName,
    data,
    sendTransactionURL + encryptionParam,
    true,
    UIConfig.loader.defaultPreLoaderTarget,
  )
    .then((res) => {
      if (res) {
        Logging(
          res,
          config.data && config.data.componentName ? config.data.componentName : 'Paypal-transaction',
          true,
          'Paypal transaction success',
        );
        if (!userAgent) {
          const encryptionParam = parseQueryString('notEncrypted') ? '&notEncrypted=true' : '';
          const orderData =
            '?' +
            checkYAEventId() +
            'status=true&ref=PayPal&oId=' +
            (getLoggedInUser().tenantID !== 'ALLB2B' ? btoa(data.orderId) : encodeURIComponent(data.orderId)) +
            '&isPage=' +
            config.data.pageVariant +
            encryptionParam;
          redirectToThankYouPage(orderData);
        } else {
          window.JSbridge.nativeCallback(
            massageMobileData({
              status: true,
              orderID: data.orderId,
              ref: 'PayPal',
            }),
          );
        }
      }
    })
    .catch((response) => {
      const cmsErrors = config.data.services.sendTransaction.error,
        errorData = getErrorMap(config.serviceKey.sendTransaction, cmsErrors, false, response.error, null);
      PaymentAnalytics && PaymentAnalytics(errorData.code, errorData.text);

      const status = '?status=false';
      localStorage.setItem('error_message', JSON.stringify(errorData));

      if (getLoggedInUser().tenantID === 'ALLB2B' && data.reservationCode) {
        savePaymentFailureStatus(
          {
            reservationCode: data.reservationCode,
            paymentStatus: config.data.services.UpdatePaymentStatus.paymentErrorCode[9991],
          },
          config.data.services.UpdatePaymentStatus.url,
        )
          .then((res) => {
            redirectToThankYouPage(status);
          })
          .catch((err) => {
            redirectToThankYouPage(status);
          });
      } else {
        if (!userAgent) {
          redirectToThankYouPage(status);
        } else {
          window.JSbridge.nativeCallback(
            massageMobileData({
              status: true,
              orderID: data.orderId,
              ref: 'PayPal',
            }),
          );
        }
      }
    });
};

//Send Transaction API call
const doTransaction = async (data) => {
  const userPromoCode = `${data.reservationCode}_${getPromoCode()}`;
  setOrderIdpromoCode(userPromoCode);
  removePromoCode();
  const userAgent = getUserAgent();
  const sendTransactionURL = config.data.B2B_isCreditSettlement
    ? config.data.services.sendTransactionAdcs.url
    : config.data.services.sendTransaction.url;
  const encryptionParam = parseQueryString('notEncrypted') ? '?isEncrypted=false' : '';
  if (data.annualPassRequest && data.annualPassRequest.isAnnualRenew) {
    // delete(data.emailTemplates);
    data.reservationCode = '';
  }

  PayPalServices.sendPayPalTrans(config.data.componentName, data, sendTransactionURL + encryptionParam)
    .then((res) => {
      if (res) {
        Logging(
          res,
          config.data && config.data.componentName ? config.data.componentName : 'Paypal-transaction',
          true,
          'Paypal transaction success',
        );
        if (!userAgent) {
          const encryptionParam = parseQueryString('notEncrypted') ? '&notEncrypted=true' : '';
          let tenantId = '';

          if (isLoggedInUser()) {
            tenantId = getLoggedInUser().tenantID;
          } else {
            const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
            tenantId = resolvePath(getMainObj, 'tenantID', '').toLowerCase();
          }

          if (tenantId.toLowerCase() === UIConfig.YIB2C) {
            const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
            sessionStorage.removeItem('cartId');

            // We are not converting session object, we are directly refering session object. It's not a boolean type so we are not using strict type checking.
            if (_isAddonAddedAfterBooking == 'true') {
              setSessionStorage('addonSuccessfullyAdded', 'true');
            }

            sessionStorage.removeItem('addonAddedAfterConfirmation');
            // Storing bookingId for after payment services i.e Upsell of addons
            setSessionStorage('bookingId', res.data.BookingId);
          }

          const orderData =
            '?' +
            checkYAEventId() +
            'status=true&ref=PayPal&oId=' +
            (tenantId.toLowerCase() === UIConfig.YIB2C
              ? btoa(res.data.BookingId)
              : tenantId !== 'ALLB2B'
              ? btoa(data.orderId)
              : encodeURIComponent(data.orderId)) +
            encryptionParam;
          redirectToThankYouPage(orderData);
        } else {
          window.JSbridge.nativeCallback(
            massageMobileData({
              status: true,
              orderID: data.orderId,
              ref: 'PayPal',
            }),
          );
        }
      }
    })
    .catch((response) => {
      const cmsErrors = config.data.services.sendTransaction.error,
        errorData = getErrorMap(config.serviceKey.sendTransaction, cmsErrors, false, response.error, null);
      PaymentAnalytics && PaymentAnalytics(errorData.code, errorData.text);

      let status = '?status=false';
      localStorage.setItem('error_message', JSON.stringify(errorData));

      if (getLoggedInUser().tenantID === 'ALLB2B' && data.reservationCode) {
        savePaymentFailureStatus(
          {
            reservationCode: data.reservationCode,
            paymentStatus: config.data.services.UpdatePaymentStatus.paymentErrorCode[9991],
          },
          config.data.services.UpdatePaymentStatus.url,
        )
          .then((res) => {
            if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
              status += '&errorStep=one';
            }
            redirectToThankYouPage(status);
          })
          .catch((err) => {
            if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.YIB2C) {
              status += '&errorStep=two';
            }
            redirectToThankYouPage(status);
          });
      } else {
        if (!userAgent) {
          redirectToThankYouPage(status);
        } else {
          window.JSbridge.nativeCallback(
            massageMobileData({
              status: true,
              orderID: data.orderId,
              ref: 'PayPal',
            }),
          );
        }
      }
    });
};

const createAnnonymousOrderBody = (payLoad, res, config) => {
  payLoad.orderId = res.data.orderID;
  payLoad.reservationCode = res.data.reservationCode;
  payLoad.amount = config.amount;
  payLoad.userId = getAnonymousCart().cart.reservationOwner.email;
  Logging(
    res,
    config.data && config.data.componentName ? config.data.componentName : 'create-order',
    true,
    'Paypal create order success',
  );
  doTransaction(payLoad);
};

const updateRecipientDetails = async (cartData) => {
  if (!cartData) {
    return;
  }
  const recipientDetails = getRecipientDetails();
  cartData.personalisedGiftObj = recipientDetails;
  await CartService.updateCart(
    {
      cart: cartData,
    },
    'RecipientDetails',
    config.data.services.updateCart.url,
  );
};

//Creating Order in omini
const createOrder = (payLoad, cartData) => {
  const isRenewAnnualPass = config.data.pageVariant === 'annualPassRenew';
  if (isLoggedInUser()) {
    const cartData = isRenewAnnualPass
      ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
      : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart));

    if (cartData) {
      const isAnnualPass = isAnnualPassCase(cartData, true);
      if (isAnnualPass) {
        const {
          emailTemplates: {
            apMainPassholderPurchaseEmailTemplateID,
            apMainPassholderRenewEmailTemplateID,
            apAddPassholderNewUserPurchaseEmailTemplateID,
            apAddPassholderNewUserRenewEmailTemplateID,
            apAddPassholderRenewEmailTemplateID,
            apAddPassholderPurchaseEmailTemplateID,
          },
        } = config.data;
        const annualPassTicketHolders = createAnnualPassData({ cartData });
        payLoad.annualPassRequest = {
          isAnnualRenew: isRenewAnnualPass,
          emailTemplateIdAnnualPass: isRenewAnnualPass
            ? apMainPassholderRenewEmailTemplateID
            : apMainPassholderPurchaseEmailTemplateID,
          emailTemplateIdWithYasId: isRenewAnnualPass
            ? apAddPassholderRenewEmailTemplateID
            : apAddPassholderPurchaseEmailTemplateID,
          emailTemplateIdWithNoYasId: isRenewAnnualPass
            ? apAddPassholderNewUserRenewEmailTemplateID
            : apAddPassholderNewUserPurchaseEmailTemplateID,
          ticketHolders: annualPassTicketHolders,
        };
      }
    }
  }

  if (!isLoggedInUser()) {
    cartData = checkInlineSignup() ? getAnonymousCart() : cartData;
    cartData.cart.reservationOwner =
      getGuestUserStatus().cart.reservationOwner !== null ? getGuestUserStatus().cart.reservationOwner : null;
  }
  if (checkAnonymousCart()) {
    const serviceUrl = checkInlineSignup()
      ? config.data.services.createOrderYasId.url
      : config.data.services.createAnnonymousOrder.url;
    let mainObj = getMainObject(),
      autoLoginStatus = resolvePath(mainObj, 'additionalProperty.enableAutoLogin'),
      autoLoginParam = `isAutoLogin=${autoLoginStatus}`,
      extraParams;

    if (autoLoginStatus) {
      extraParams = autoLoginParam;
    }
    OrderService.createAnnonymousOrder(
      config.data.componentName,
      serviceUrl,
      UIConfig.loader.defaultPreLoaderTarget,
      cartData,
      extraParams,
      config.data.services.updateCart,
    )
      .then(function(res) {
        if (res.data.orderID) {
          setYasId(res.data);
          let mainObj = getMainObject(),
            idToken = resolvePath(res.data, 'id_token'),
            defaultStateVal = getAPIStateParam(),
            signInData = { state: defaultStateVal, id_token: idToken },
            signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
          // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
          if (signInURL && idToken) {
            OrderService.yasIdAutoLogin('YasIdAutoLogin', signInURL, UIConfig.loader.defaultPreLoaderTarget, signInData)
              .then((autoLoginRes) => {
                createAnnonymousOrderBody(payLoad, res, config);
              })
              .catch((err) => {
                /* do nothing */
              });
          } else {
            createAnnonymousOrderBody(payLoad, res, config);
          }
        }
      })
      .catch((response) => {
        if (checkInlineSignup()) {
          showError('createOrderYasId', response.error);
        } else {
          showError(config.serviceKey.createOrder, response.error);
        }
        PaymentAnalytics && PaymentAnalytics(response.error.code, response.error.text);
      });
  } else {
    /* Send recipient details */
    const isSwad = checkTenant(UIConfig.iamMapping.swad);
    const recipientDetails = getRecipientDetails();
    isSwad && cartData && recipientDetails && !isEmpty(recipientDetails)
      ? updateRecipientDetails({ ...cartData.cart })
      : !isSwad && updateRecipientDetails(cartData);

    OrderService.createOrder(
      config.data.componentName,
      isRenewAnnualPass ? config.data.services.renewPass.url : config.data.services.createOrder.url,
      UIConfig.loader.defaultPreLoaderTarget,
      config.data.agentRefNum,
      config.data.dueDate,
      null,
      isRenewAnnualPass,
      config.data.services.getGuestProfile && config.data.services.getGuestProfile.url,
      null,
      config.data.services.updateCart,
      config.data.services,
    )
      .then(function(res) {
        if (res.data.orderID) {
          payLoad.promoCode = getPromoCode();
          payLoad.orderId = res.data.orderID;
          payLoad.reservationCode = res.data.reservationCode;
          payLoad.amount = config.amount;
          Logging(
            res,
            config.data && config.data.componentName ? config.data.componentName : 'create-order',
            true,
            'Paypal create order success',
          );

          const marketingConsent = document.getElementById('paymentNewsletter')
            ? document.getElementById('paymentNewsletter').checked
            : '';

          if (marketingConsent && isLoggedInUser()) {
            const { email, yasId = '', tenantID, idToken } = getLoggedInUser();
            const apiData = config.data.services.MarketingConsentUpdateAPI;
            const { phone, sms, whatsApp, website, mobile } = UIConfig.CMPconsent;
            ApiWrapper.apiGateway({
              url: apiData.url.replace(':email', email),
              method: 'POST',
              data: {
                emailId: email,
                myPassId: yasId,
                channelName: tenantID,
                language: getLanguageWithoutLocal(),
                consentPurpose: [
                  { [UIConfig.CMPconsent.email]: true },
                  { [phone]: true },
                  { [sms]: true },
                  { [whatsApp]: true },
                  { [website]: true },
                  { [mobile]: true },
                ],
                source: '',
              },
              headers: {
                Authorization: 'Bearer ' + idToken,
              },
              preLoader: true,
              preLoaderTarget: '.newsletter-signup-container',
            }).then((response) => {
              const { status, data } = response;
              if (typeof data === 'object' && (status === 201 || status === 200)) {
                Logging(data, 'Marketing Consent Update', true, 'Logged In User');
              }
            });
          }
          doTransaction(payLoad);
        }
      })
      .catch((response) => {
        showError(config.serviceKey.createOrder, response.error);
        PaymentAnalytics && PaymentAnalytics(response.error.code, response.error.text);
      });
  }
};
//Creating PayPal Checkout Button on Payment Page
const createCheckoutButton = (brainTree, instance) => {
  brainTree.paypalCheckout.create(
    {
      client: instance,
    },
    function(error, paypalCheckoutInstance) {
      let cachePayPalInstance = paypalCheckoutInstance;
      if (!error) {
        removeLoaderOverlay();
        window.paypal.Button.render(
          {
            env: config.data.environment,
            onClick: function(e) {
              // Using for GA
              if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
                const currencyId = getSessionStorage('GA_CurrencyId') || '';
                checkoutAnalytics(currencyId, 'payment', 'Paypal');
              }

              if (!isLoggedInUser()) {
                if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
                  const serverErrorElement = document.getElementsByClassName('yasid-email-error server-error');
                  if (serverErrorElement && serverErrorElement.length > 0) {
                    e && e.preventDefault();
                    return false;
                  }
                }

                const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
                // We are not converting session object, we are directly refering session object. It's not a boolean type so we are not using strict type checking.
                if (_isAddonAddedAfterBooking && _isAddonAddedAfterBooking == 'true') {
                  window.PubSub.publish('dataPosted', {
                    isDataPosted: true,
                  });
                } else {
                  document.getElementById('submit-btn').click();
                }
              } else if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
                // Send passenger details if user is logged in and tenant is YasIsland
                const loggedInUser = getLoggedInUser();
                const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');

                if (!_isAddonAddedAfterBooking || _isAddonAddedAfterBooking == 'false') {
                  let userId = getSessionStorage('packageSummaryData');
                  if (userId) userId = JSON.parse(userId).userId;
                  const { phoneCountryCode, mobileNum } = loggedInUser;
                  let passengerObject = {
                    Title: 'Mr.',
                    FirstName: loggedInUser.userName,
                    LastName: loggedInUser.lastName,
                    Email: loggedInUser.email,
                    Phone: mobileNum ? `${phoneCountryCode} ${mobileNum}` : '',
                    CountryOfResidence: loggedInUser.country,
                    Nationality: loggedInUser.nationalityAlphaCode,
                    UserId: userId,
                    AuthToken: loggedInUser.idToken,
                  };

                  const specialRequests = getSessionStorage('specialRequests');

                  if (specialRequests) {
                    passengerObject.SpecialRequests = JSON.parse(specialRequests);
                  }

                  const PassengerDetailsOptions = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                    },
                    body: JSON.stringify(passengerObject),
                  };

                  const passengerUrl = config.data.services.SetPassengerDetails.url.replace(
                    '{cartId}',
                    getSessionStorage('cartId'),
                  );

                  fetch(passengerUrl, PassengerDetailsOptions).then((res) => {
                    if (!res.ok && res.statusText) {
                      localStorage.setItem('isPassengerError', true);
                    } else {
                      localStorage.removeItem('isPassengerError');
                    }
                  });
                }
              }
            },
            payment: async function(e) {
              if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
                //#region  Get payfort configurations
                const _paymentData = getLocalStorageByKey('payment_info');
                // We are not converting session object, we are directly refering session object. It's not a boolean type so we are not using strict type checking.
                const _isPostBookingJourney = getSessionStorage('addonAddedAfterConfirmation') == 'true' ?? false;
                // ! if post booking journey i.e upsell of addons then we'll have to append guid after merchant_reference
                const _guid = _isPostBookingJourney ? `_${generateGUID()}` : '';

                const configUrl = _paymentData.cmsData.services.TravelBoxPayfortConfig.url.replace(
                  '{cartId}',
                  getSessionStorage('cartId'),
                );
                const response = await fetch(configUrl);

                const _data = await response.json();
                config.data.reservationCode = _data.merchant_reference + _guid;
                config.data.orderID = _data.merchant_reference + _guid;
                config.amountInAed = _data.TotalAmount;
                config.amount = convertAedToUsd(_data.TotalAmount, _paymentData.cmsData.paymentOptions.conversionRate);
                //#endregion
                const serverErrorElement = document.getElementsByClassName('yasid-email-error server-error');
                if (serverErrorElement && serverErrorElement.length > 0) {
                  e && e.preventDefault();
                  return false;
                }
              }
              return cachePayPalInstance.createPayment({
                flow: 'checkout',
                amount: config.amount,
                currency: 'USD',
                locale: config.locale,
                enableShippingAddress: false,
                shippingAddressEditable: false,
              });
            },
            onAuthorize: function(data, actions) {
              return cachePayPalInstance
                .tokenizePayment(data)
                .then(function(res) {
                  let payLoad = {
                    paymentNonce: res.nonce,
                    deviceData: config.deviceData,
                    locale: config.locale,
                    tenantId: config.tenantId,
                    paymentMode: config.data.paymentType,
                    emailTemplateId: config.data.emailTemplateId,
                    invoiceTemplateId: config.data.invoiceTemplateId ? config.data.invoiceTemplateId : ' ',
                    marketType: config.data.marketType,
                    conversionRate: config.data.conversionRate,
                    is2StepJourney: config.data.is2StepPayment,
                    isOrderHistoryPayment:
                      (canUseDOM() && JSON.parse(localStorage.getItem('isOrderHistoryPayment'))) || false,
                  };
                  if (config.data.B2B_isCreditSettlement || config.data.B2B_isAdvanceTopUp) {
                    payLoad.creditSettlementInvoice = config.data.creditSettlementInvoice;
                    payLoad.omniAmount = parseFloat(config.data.total);
                    payLoad.reconType = config.data.pageVariant;
                  }
                  if (config.data && config.data.orderID) {
                    payLoad.orderId = config.data.orderID;
                    payLoad.reservationCode = config.data.reservationCode;
                    payLoad.amount = config.amount;

                    if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
                      payLoad.amountInAed = config.amountInAed;
                      payLoad.TravelBoxAddPaymentUrl = config.TravelBoxAddPaymentUrl;
                      payLoad.TravelBoxConfirmBookingUrl = config.TravelBoxConfirmBookingUrl;
                      payLoad.cartId = getSessionStorage('cartId');
                    }

                    config.data.B2B_isCreditSettlement || config.data.B2B_isAdvanceTopUp
                      ? b2bDoTransaction(payLoad)
                      : doTransaction(payLoad);
                  } else {
                    createOrder(payLoad, config.paypalCartData);
                  }
                })
                .catch((e) => {
                  showError(config.serviceKey.default, config.error);
                  PaymentAnalytics && PaymentAnalytics(config.error.code, config.error.text);
                });
            },
            onError: function(err) {
              removeLoaderOverlay();
              showError(config.serviceKey.default, config.error);
              PaymentAnalytics && PaymentAnalytics(config.error.code, config.error.text);
            },
          },
          '.btn-hero-cta',
        );
      } else {
        removeLoaderOverlay();
      }
    },
  );
};

//Intializing data from current device
const initDataCollector = (brainTree, instance) => {
  brainTree.dataCollector.create(
    {
      client: instance,
      paypal: true,
    },
    function(error, dataInstance) {
      if (!error) {
        let deviceData = JSON.parse(dataInstance.deviceData);
        config.deviceData = deviceData.correlation_id;
        createCheckoutButton(brainTree, instance);
      } else {
        removeLoaderOverlay();
      }
    },
  );
};
//Intializing PayPal instance on client
const initPayPalInstance = (token) => {
  if (token && window.braintree) {
    let brainTree = window.braintree;
    brainTree.client.create(
      {
        authorization: token,
      },
      function(error, instance) {
        if (!error) {
          initDataCollector(brainTree, instance);
        } else {
          removeLoaderOverlay();
          showError(config.serviceKey.default, config.error);
          PaymentAnalytics && PaymentAnalytics(config.error.code, config.error.text);
        }
      },
    );
  }
};

/**
 * This method load PayPal Service Component on Payment component.
 * @param    @Object type {data , paymentType, amountToPay, conversionRate, thankYouPageURl}
 * @param    @Object type {Cart , complete cart object}
 *
 */
export const loadPayPal = async (data, error, paypalCartData) => {
  getCartData();
  addLoaderOverlay();
  config.data = data;
  showError = error;
  config.paypalCartData = paypalCartData;
  config.amount = convertAedToUsd(data.total, data.conversionRate);

  if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
    config.TravelBoxAddPaymentUrl = data.services.TravelBoxAddPaymentUrl.url;
    config.TravelBoxConfirmBookingUrl = data.services.TravelBoxConfirmBookingUrl.url;
  }

  PayPalServices.getPayPalToken('payment', data.services.getToken.url)
    .then(function(response) {
      let res = response.data;
      if (res && res.tokenDetail) {
        let tokenObj = res.tokenDetail;
        if (tokenObj && !tokenObj.error.code) {
          initPayPalInstance(tokenObj.token);
        }
      }
      Logging(
        res,
        config.data && config.data.componentName ? config.data.componentName : 'paypal-get-token',
        true,
        'Get paypal token success',
      );
    })
    .catch((response) => {
      removeLoaderOverlay();
      showError(config.serviceKey.getToken, response.error);
      PaymentAnalytics && PaymentAnalytics(response.error.code, response.error.text);
    });
};
