/* This file contains code for Editorial item component, it renders some optional fields and wraps some images within the anchor tag depends on icon type
 * @licensor  Miral
 */

import React, { useEffect, useState } from 'react';
import { AnchorLink, DynamicContent, Image, RichText } from '..';
import PropTypes from 'prop-types';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import Video from '../../video';
import WishlistIcon from '../../wishlist-icon';
import IAMPopup from '../../iam-popup/iam-popup';
import {
  canUseDOM,
  getMainObject,
  openIAMOverlay,
  resolvePath,
  checkParksTenants,
  isMatchTenant,
  setLocalStorage,
  getGa4Category,
  detectMobile,
  checkIfTagetDateAproached,
} from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import { bodyClicks } from '../../../../common/analytics-events';
import GTMData from '../../../container/b2c-purchase-journey/gtm-data';
import EventTimer from '../timer/event-timer-component';
import classNames from 'classnames';
import moment from 'moment';

const iconTypeHtml = (data) => {
  if (!data) {
    return null;
  }
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;

  const imageType = data.imageType ? data.imageType : 'image';
  const actionLabel = data.actionLabel ? data.actionLabel : '';
  const isEEActive = isExperienceEditorActive();

  const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
    if (checkfortenants) {
      bodyClicks(eventName, titleData, labelData);
    }
  };
  switch (data.imageInfo.desktopImage && data.iconType) {
    case 'telephone':
      return (
        <div className={'editorial-item--' + imageType}>
          {isEEActive ? (
            <Image image={data.imageInfo} />
          ) : (
            <a
              aria-label={actionLabel ? actionLabel : `${data.imageTitle} icon`}
              href={'tel:' + data.actionLink}
              className="editorial-item--link"
              onClick={() => {
                googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.imageClick, 'telephone', actionLabel);
              }}
            >
              <img src={data.imageInfo.desktopImage.src} alt={data.imageInfo.imageAltText}></img>
            </a>
          )}
        </div>
      );
    case 'email':
      return (
        <div className={'editorial-item--' + imageType}>
          {isEEActive ? (
            <Image image={data.imageInfo} />
          ) : (
            <a
              aria-label={actionLabel ? actionLabel : `${data.imageTitle} icon`}
              href={'mailto:' + data.actionLink}
              className="editorial-item--link"
              onClick={() => {
                googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.imageClick, 'email', actionLabel);
              }}
            >
              <img src={data.imageInfo.desktopImage.src} alt={data.imageInfo.imageAltText}></img>{' '}
            </a>
          )}
        </div>
      );
    case 'map':
      return (
        <div className={'editorial-item--' + imageType}>
          {isEEActive ? (
            <Image image={data.imageInfo} />
          ) : (
            <a
              aria-label={actionLabel ? actionLabel : `${data.imageTitle} icon`}
              href={data.actionLink || 'javascript:void(0)'} // eslint-disable-line
              className="editorial-item--link"
              target="_blank"
              onClick={() => {
                googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.imageClick, 'map', actionLabel);
              }}
            >
              <img src={data.imageInfo.desktopImage.src} alt={data.imageInfo.imageAltText}></img>
            </a>
          )}
        </div>
      );
    case 'clickImage':
      return (
        <div className={'editorial-item--' + imageType}>
          <a
            aria-label={actionLabel ? actionLabel : `${data.imageTitle} icon`}
            href={data.actionLink || 'javascript:void(0)'} // eslint-disable-line
            className="editorial-item--link"
            onClick={() => {
              googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.imageClick, 'clickImage', actionLabel);
            }}
          >
            <Image image={data.imageInfo} disableLazyLoad={data.mobileDisableLazyLoad} />
          </a>
        </div>
      );
    default:
      return (
        <div className={'editorial-item--' + imageType}>
          <Image image={data.imageInfo} disableLazyLoad={data.mobileDisableLazyLoad} />
        </div>
      );
  }
};

const videoRenderer = (data) => {
  if (!data || !data.videoInfo) {
    return null;
  }

  return (
    <Video
      active={data.isActive}
      data={data.videoInfo}
      autoplay={data.videoInfo.autoplay}
      videoData={data.videoData}
      imageInfo={data.imageInfo}
      activeCardId={data.activeCardId}
    />
  );
};

const getImgVideo = (data) => {
  if (data.videoFirstOnMobile && data.videoInfo) {
    return videoRenderer(data);
  } else if (data.imageInfo) {
    return iconTypeHtml(data);
  } else {
    return '';
  }
};

const setEAGA4StorageAndPushEvent = (data, elem) => {
  const eAGa4Storage = {
    index: data.index + 1,
    list_item_id: getGa4Category(window?.location?.pathname, UIConfig.ga4Constants.HOME),
  };
  setLocalStorage('EAGa4Storage', JSON.stringify(eAGa4Storage));
  GTMData.push(UIConfig.ga4Constants.SELECT_ITEM, {
    ...data,
    click_text: elem ? elem?.textContent?.toLowerCase() : data?.moreLinkButton?.label,
  });
};

const selectItemGTMEvent = (event, data) => {
  if (isMatchTenant(UIConfig.tenants.ya)) {
    const elem = event.target;
    const { tagName } = elem || {};
    const isAnchor = tagName === UIConfig.ga4Constants.ANCHOR || elem.closest('a');
    const isButton = tagName === UIConfig.ga4Constants.BUTTON || elem.closest('button');
    if (isAnchor || isButton) {
      setEAGA4StorageAndPushEvent(data, elem);
    }
  }
};

/**
 * @param {object} data will have imageinfo,image title and body copy fields
 */
const EditorialItem = (data) => {
  const [isEventLive, setIsEventLive] = useState(false);
  const [initIsEventLiveCheck, setInitIsEventLiveCheck] = useState(false);

  const { eventTimer } = data;

  const checkIfEventIsLive = () => {
    const fromDate = moment();
    const toDate = moment(moment.utc(eventTimer?.startTime || undefined));
    const seconds = toDate.diff(fromDate, UIConfig.calendar.seconds);
    return +seconds <= 0;
  };

  useEffect(() => {
    const isEventLive = checkIfEventIsLive();
    setIsEventLive(isEventLive);
    setInitIsEventLiveCheck(true);
  }, []);

  const mainObj = getMainObject() || {};
  const enableYasIDVersion = Boolean(resolvePath(mainObj, 'additionalProperty.enableYasIDVersion', false));

  const renderTimerCta = () => {
    console.log({ isEventLive, eventTimer });
    if (data.moreLinkButton && data.moreLinkButton.label) {
      return (
        <div
          className={`${eventTimer ? 'editorial-item--bodycopy' : ''} editorial-item--read-more`}
          onKeyDown={(e) => {
            e.which === 13 && e.target.querySelector('a').click();
          }}
        >
          {enableYasIDVersion && data.isLoginUrl ? (
            <button
              tabIndex={`${data.variant === 'V-Ya-Editorial_Grid_Carousel-VIP' ? '0' : '-1'}`}
              ariaLabel={`${data.moreLinkButton.label} ${data.imageTitle}`}
              className="right-arrow"
              onClick={() => openIAMOverlay(<IAMPopup loginURL={data.moreLinkButton.href} />)}
            >
              {data.moreLinkButton.label}
            </button>
          ) : (
            <AnchorLink
              link={{ ...data.moreLinkButton, ariaLabel: `${data.moreLinkButton.label} ${data.imageTitle}` }}
              className={`right-arrow  ${eventTimer ? 'btn-primary' : ''}`}
              tabIndex={`${data.variant === 'V-Ya-Editorial_Grid_Carousel-VIP' ? '0' : '-1'}`}
              onClick={() => {
                setEAGA4StorageAndPushEvent(data);
              }}
            />
          )}
        </div>
      );
    }
    return null;
  };

  try {
    return (
      <div
        className={classNames('editorial-item', {
          'editorial-item-icons': data.icons && data.icons.length,
        })}
      >
        {getImgVideo(data)}
        <div className="editorial-item--text-section">
          {data.wishlist && data.wishlist.enableWishList ? (
            <WishlistIcon
              data={{
                wishlist: data.wishlist,
                childData: {
                  title: data.imageTitle,
                  link: data.moreLinkButton.href,
                  desc: data.bodyCopy,
                  image: data.imageInfo.desktopImage.src,
                },
              }}
            />
          ) : null}
          {data.variant === 'v-similar-products' && data.tagging && data.tagging.length && (
            <div className="product-tag">{data.tagging[0]}</div>
          )}
          {data.shortImageTitle ? (
            <p
              className="editorial-item--short-title heading-7"
              dangerouslySetInnerHTML={{ __html: data.shortImageTitle }}
            />
          ) : (
            ''
          )}
          {data.imageTitle && checkParksTenants() && data.moreLinkButton && data.moreLinkButton.href ? (
            <a href={data.moreLinkButton.href} tabIndex={data.variant === 'v-carousel-4grid-view' ? '0' : '-1'}>
              <strong
                className="editorial-item--title heading-5"
                tabIndex={data.variant === 'v-carousel-4grid-view' ? '-1' : '0'}
                dangerouslySetInnerHTML={{ __html: data.imageTitle }}
              />
            </a>
          ) : data.imageTitle ? (
            <strong className="editorial-item--title heading-5" dangerouslySetInnerHTML={{ __html: data.imageTitle }} />
          ) : null}
          {data.variant && data.variant === 'v-similar-products' && (
            <div className="price-wrapper">
              {data.currentPrice && (
                <div className="current-price">
                  {data.priceText} <span className="price">{data.currentPrice}</span>
                </div>
              )}
              {data.netPrice && (
                <div className="net-price">
                  {data.priceText} <span className="price">{data.netPrice}</span>
                </div>
              )}
            </div>
          )}
          {data.variant !== 'v-similar-products' && (
            <RichText
              data={{
                bodyCopy: data.bodyCopy,
                attrs: {
                  className: 'editorial-item--bodycopy body-copy-4',
                  onClick: (event) => {
                    selectItemGTMEvent(event, data);
                  },
                },
              }}
            />
          )}
          {initIsEventLiveCheck &&
            isMatchTenant(UIConfig.tenants.ya) &&
            eventTimer &&
            !isEventLive &&
            checkIfTagetDateAproached(eventTimer?.endTime) === false && (
              <div className="editorial-item-countdown-timer c-countdown-wrapper">
                <div className="countdown--timer">
                  <EventTimer
                    startTime={eventTimer?.startTime}
                    handleIsEventLive={setIsEventLive}
                    options={
                      eventTimer?.options || {
                        ...(detectMobile() ? {} : { months: eventTimer?.monthsLabel || UIConfig.calendar.months }),
                        days: eventTimer?.daysLabel || UIConfig.calendar.days,
                        hours: eventTimer?.hoursLabel || UIConfig.calendar.hours,
                        minutes: eventTimer?.minutesLabel || UIConfig.calendar.minutes,
                        seconds: eventTimer?.secondsLabel || UIConfig.calendar.seconds,
                      }
                    }
                  />
                </div>
              </div>
            )}
          {data.secondaryDescription && (
            <RichText
              data={{
                bodyCopy: data.secondaryDescription,
                attrs: { className: 'editorial-item--shortDescription body-copy-4' },
              }}
            />
          )}
          {isMatchTenant(UIConfig.tenants.ya) &&
            initIsEventLiveCheck &&
            (eventTimer && checkIfTagetDateAproached(eventTimer?.endTime) === false
              ? isEventLive && renderTimerCta()
              : renderTimerCta())}
          {!isMatchTenant(UIConfig.tenants.ya) && initIsEventLiveCheck && renderTimerCta()}
          {data.variant && data.variant === 'v-similar-products' && data.buyButtonLink && (
            <p className="editorial-item--buy--now">
              <a className="buy-now-button" href={data.buyButtonLink}>
                {data.buyNowLabel}
              </a>
            </p>
          )}
        </div>
        {data.icons && (
          <div className="item-rendered clearfix">
            {data.icons.map((item, index) => {
              return (
                <div key={index} className="editorial-grid-tile-icon ride-info-tile">
                  {item.image && <Image image={item.image} disableLazyLoad={data.mobileDisableLazyLoad} />}
                  <DynamicContent
                    tagName="p"
                    innerHtml={item.title + ':' + item.shortTitle}
                    attrs={{ className: 'body-3' }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'EditorialItem');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
EditorialItem.propTypes = {
  data: PropTypes.shape({
    imageInfo: PropTypes.arrayOf(PropTypes.object),
    imageTitle: PropTypes.string,
    videoInfo: PropTypes.arrayOf(PropTypes.object),
    shortImageTitle: PropTypes.string,
    bodyCopy: PropTypes.string.isRequired,
    icons: PropTypes.array,
    videoFirstOnMobile: PropTypes.bool,
  }),
};

export default EditorialItem;
