import UIConfig from '../../../common/UIConfig.js';
import {
  deepCloneObject,
  isEmpty,
  getErrorObj,
  addLoaderOverlay,
  removeLoaderOverlay,
  checkTenant,
} from '../../../common/utility';
import ApiWrapper from '../../../common/api-wrapper';
import imageCompression from 'browser-image-compression';
import { Logging } from '../../../common/logger.js';
export const toggleIsRequiredRule = (self, dataField, status) => {
  if (dataField !== 'isPrimaryForm') {
    const ruleIdx = self.rules.findIndex((elm) => elm.name === dataField.id);
    if (ruleIdx > -1) {
      self.rules[ruleIdx].rules.isRequired = status;
    }
  }
};

export const toggleValidationRulesForSecondaryForm = (self, dataFields, sectionIndex, formIndex) => {
  const stateData = self.state.data;
  if (dataFields.some((field) => self.payload[field.id])) {
    let keepRequiredSame = true;
    dataFields.forEach((field, fieldIndex) => {
      let getIdx = field.name && field.name.split('_')[1];
      if (!keepRequiredSame && field.name === `emirate_${getIdx}`) {
        stateData[sectionIndex].form[formIndex][fieldIndex].isRequired = false;
      }
      if (field.name === `country_${getIdx}` && field.value !== 'AE') {
        keepRequiredSame = false;
      } else {
        keepRequiredSame = true;
      }
      self.toggleIsRequiredRule(field, stateData[sectionIndex].form[formIndex][fieldIndex].isRequired);
    });
    self.setState({ data: stateData });
  } else {
    dataFields.forEach((field) => {
      self.toggleIsRequiredRule(field, false);
    });
  }
};

export const toggleValidationRulesForSecondaryFormOnSubmit = (self) => {
  const sections = deepCloneObject(self.state.data);
  sections.forEach((section, sectionIndex) => {
    section.form.forEach((dataFields, formIndex) => {
      !dataFields.isPrimaryForm && self.toggleValidationRulesForSecondaryForm(dataFields, sectionIndex, formIndex);
    });
  });
};

export const checkIfValidImage = (file) => {
  const part = file.type.split('/')[0];
  return part !== 'image';
};

export const onFieldChange = async (self, e, field, blurForm) => {
  var value, name, target, id, file;
  if (e === 'deletePhoto') {
    value = '';
    name = field.name;
    id = field.id;
  } else {
    target = !e.target ? e : e.target;
    value = target.value;
    name = target.name;
    id = target.id;
  }
  const data = deepCloneObject(self.state.data);
  const detail = self.fieldMapping[id];

  if (target && target.type === 'file') {
    e.preventDefault();

    value = '';
    let reader = new FileReader();
    file = e.target.files[0];
    self.fileName = file?.name;
    let photoData = data[detail.park].form[detail.row][detail.col];
    let allowedFileTypes = photoData.allowedFileExtension.split('|');
    let checkDoubleExtType = checkIfValidImage(file);
    const size = parseInt(photoData.maxSize);

    const uploadFile = (file) => {
      if (!self.isTypeValid(allowedFileTypes, file) || checkDoubleExtType) {
        data[detail.park].form[detail.row][detail.col].photoError = photoData.fileExtensionErrorMessage;
      } else if (file.size > size) {
        data[detail.park].form[detail.row][detail.col].photoError = photoData.imageSizeErrorMessage;
      } else {
        data[detail.park].form[detail.row][detail.col].photoError = '';
        reader.onloadend = (file) => {
          value = reader.result.split(',')[1];
          let data = deepCloneObject(self.state.data);
          const detail = self.fieldMapping[id];
          data[detail.park].form[detail.row][detail.col].val = value;
          data[detail.park].form[detail.row][detail.col].value = {
            fileContent: UIConfig.imagePrefix + value,
            fileName: self.fileName,
          };
          data[detail.park].form[detail.row][detail.col].fileName = self.fileName;
          self.payload[name] = value;
          self.setState(
            {
              data,
              photoError: false,
            },
            () => {
              if (
                document.querySelector(`#delete-photo_${id}`) &&
                (document.querySelector('.user-is-tabbing') || self.isMobileOrTab)
              ) {
                document.querySelector(`#delete-photo_${id}`).focus();
              }
            },
          );
        };
        reader.readAsDataURL(file);
      }
    };
    addLoaderOverlay();
    if (file.size > size) {
      const options = {
        maxSizeMB: size / 1024 / 1024, // convert the size to MB
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        file = compressedFile;
      } catch (err) {
        Logging(err, 'Image Compression', false, 'Image Compression error');
      }
    }
    removeLoaderOverlay();

    uploadFile(file);
  }

  if (data[detail.park].form[detail.row][detail.col].type === 'SelectWithText') {
    data[detail.park].form[detail.row][detail.col].dropdownValue = field.target.value;
  }

  data[detail.park].form[detail.row][detail.col].val = value;
  data[detail.park].form[detail.row][detail.col].value = value;
  self.payload[name] = value;
  if (data[detail.park].form[detail.row][detail.col].includeChildFields) {
    const parentField = data[detail.park].form[detail.row][detail.col];
    const childField = data[detail.park].form[detail.row].filter((f) => {
      if (f.parentFieldId) {
        return parentField['data-name'] === f.parentFieldId.toLowerCase();
      } else {
        return false;
      }
    })[0];
    const childDetails = self.fieldMapping[childField.id];
    data[childDetails.park].form[childDetails.row][childDetails.col].hide =
      childField.parentFieldValue !== parentField.value;
    if (data[childDetails.park].form[childDetails.row][childDetails.col].hide) {
      data[childDetails.park].form[childDetails.row][childDetails.col].value = '';
      self.toggleRules(data[childDetails.park].form[childDetails.row][childDetails.col], 'remove');
    } else {
      self.toggleRules(data[childDetails.park].form[childDetails.row][childDetails.col], 'add');
    }
  }
  if (!data[detail.park].form[detail.row].isPrimaryForm)
    self.toggleValidationRulesForSecondaryForm(data[detail.park].form[detail.row], detail.park, detail.row);

  if (value.trim() === '') {
    data[detail.park].form[detail.row][detail.col].active = '';
  } else {
    data[detail.park].form[detail.row][detail.col].active = 'active';
  }
  let emailerrors = { ...self.state.emailErrors };
  const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmailTrue = emailReg.test(String(value).toLowerCase());
  if (name.includes('email') && isEmailTrue && blurForm) {
    const apiData = self.props.data.services.NewsletterVerifyEmailSubscriptionAPI;
    let url = apiData.url.replace(':email', '?email=' + value);
    await ApiWrapper.apiGateway({
      url: url,
      method: 'GET',
      preLoader: false,
    }).then((response) => {
      if (response.status === 200) {
        let errorObj = getErrorObj(response.status, apiData.errors, true);
        if (response.data === false) {
          const emailerror = { message: errorObj.error.text, valid: 'error' };
          emailerrors = { ...emailerrors, [name]: emailerror };
        } else if (response.data === true) {
          delete emailerrors[name];
          emailerrors = { ...emailerrors };
        }
      }
    });
  }
  const validationErrors = self.checkError(value, name);
  const errors = { ...validationErrors, ...emailerrors },
    isReqEvt = e.type !== 'change';
  if (errors && !isEmpty(errors) && errors[name] && isReqEvt) {
    if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
      data[detail.park].form[detail.row][detail.col].showErrors = true;
      data[detail.park].form[detail.row][detail.col].message = errors[name].message;
      data[detail.park].form[detail.row][detail.col].valid = errors[name].valid;
    }
  } else {
    data[detail.park].form[detail.row][detail.col].showErrors = false;
    data[detail.park].form[detail.row][detail.col].message = '';
    data[detail.park].form[detail.row][detail.col].valid = '';

    if (
      !data[detail.park].form[detail.row].isPrimaryForm &&
      data[detail.park].form[detail.row].every(
        (field) => self.payload[field.id] === undefined || self.payload[field.id] === '',
      )
    )
      data[detail.park].form[detail.row].forEach((field) => {
        field.showErrors = false;
        field.message = '';
      });
  }

  self.setState(
    {
      data: data,
      emailErrors: emailerrors,
    },
    () => {
      if (
        e === 'deletePhoto' &&
        document.querySelector(`#photo-upload-section_${id}`) &&
        (document.querySelector('.user-is-tabbing') || self.isMobileOrTab)
      ) {
        document.querySelector(`#photo-upload-section_${id}`).focus();
      }
    },
  );
};

export const toggleRules = (self, field, action) => {
  if (self.rules) {
    switch (action) {
      case 'remove':
        const spliceIndex = self.rules.findIndex((rule) => rule.name === field.name);
        if (spliceIndex !== -1) {
          self.rules.splice(spliceIndex, 1);
        }
        break;
      case 'add':
      default:
        const index = self.rules.findIndex((x) => x.name === field.name);
        if (index === -1) {
          self.rules.push({
            name: field.id,
            rules: {
              isRequired: field.isRequired,
              message: field.isRequiredMessage,
              type: field.type,
              isConfirm: field.isConfirm ? field.isConfirm : false,
              cMessage: field.isConfirmMessage ? field.isConfirmMessage : null,
              fieldToConfirm: field.fieldToConfirm ? field.fieldToConfirm : null,
              validation: field.validations,
              minDate: field.minDate,
              minMessage: field.isMinMessage,
              maxDate: field.maxDate,
              maxMessage: field.isMaxMessage,
            },
          });
        }
        break;
    }
  }
};

/**
 * Desc: Create Data from cart
 * @param {Object} cart data passed from cart component
 */
export const formatCartData = (self, cartData) => {
  let annualPasses = {};
  if (cartData.cart && cartData.cart.items) {
    self.cart = cartData;
    annualPasses = cartData.cart.items
      .filter((item) => item.itemType === 'ANP')
      .map((pass) => {
        let bodyCopyText = '';
        self.state.data.productCategories &&
          self.state.data.productCategories.forEach((category) => {
            if (category.propertyName === pass.productTypeCategory) {
              bodyCopyText = category.propertyValues.Description;
            }
          });
        return {
          PassID: pass.productId,
          PassType: pass.productName,
          Quantity: pass.quantity,
          GuestDetails: pass.guestDetails,
          ClassName: pass.classTypeName,
          ProdIdType: pass.productIdType,
          bodyCopyText: bodyCopyText,
        };
      });
  }
  return annualPasses;
};

/**
 * Desc: accordion
 * @param {String} name of accordion to be toggled
 */
export const accordion = (self, label) => {
  const {
    state: { openSections },
  } = self;

  const isOpen = !!openSections[label];
  self.setState((state) => ({
    openSections: {
      ...state.openSections,
      [label]: !isOpen,
    },
  }));
};

/**
 * Desc: accordion expanded in which have errors
 * @param {Array} array of form elements
 */
export const accordionOpenInCaseError = (self, data) => {
  data.forEach((formSection, formIndex) => {
    // start form dataoogel
    formSection['form'].forEach((formArray, index, array) => {
      let label = 'accordion_' + formIndex + '_' + index;
      let hasError = formArray.some((fieldVal, fieldValIndex) => {
        return fieldVal.message && fieldVal.message.length;
      });
      if (hasError) {
        self.setState((state) => ({
          openSections: {
            ...state.openSections,
            [label]: hasError,
          },
        }));
      }
    });
    // end form data
  });
};

/**
 * Desc: Copy Primary form value
 * @param {Array} field name that needs to be copy from primary form
 */
export const getPrimaryFormValue = (self, copyFieldName) => {
  let data = self.state.data;
  let annualPrimaryData = {};
  if (data && data.length && data[0].form.length) {
    data[0].form[0].forEach((form, index) => {
      copyFieldName.forEach((copyFieldName, copyFieldNameIndex) => {
        if (form['data-name'] === copyFieldName) {
          annualPrimaryData[copyFieldName] = {
            val: form['val'],
            value: form['value'],
          };
        }
      });
    });
  }
  return annualPrimaryData;
};

/**
 * Desc: Set primary form data into passholder forms
 * @param {Array} fields names that need to be copy into passholder form
 */
export const setSecondaryFormValue = (self, setFieldsValue) => {
  let data = self.state.data,
    getFieldValue = setFieldsValue,
    primaryFormValue = self.getPrimaryFormValue(getFieldValue),
    formArray = [],
    updatedFormElements = [];
  if (data.length) {
    // start form iteration
    data.forEach((annualPassForm, annualPassFormIndex) => {
      // start form elements loop
      annualPassForm.form.forEach((formData, index) => {
        if (annualPassFormIndex === 0 && index === 0) {
          // main placeholder form data skipped for copy
          formArray.push(formData);
        } else {
          formData.forEach((formElements, formElementsIndex) => {
            setFieldsValue.forEach((setFieldValue, copyFieldNameIndex) => {
              if (formElements['value'] === '' && formElements['data-name'] === setFieldValue) {
                formElements['value'] = primaryFormValue[setFieldValue].value;
                formElements['val'] = primaryFormValue[setFieldValue].val;
                if (setFieldValue === 'mobileNum') {
                  formElements['dropdownValue'] = primaryFormValue[setFieldValue].value.replace(
                    primaryFormValue[setFieldValue].val,
                    '',
                  );
                }
                if (formElements['value']) {
                  formElements['active'] = 'active';
                } else {
                  formElements['active'] = '';
                }
              }
              formElements['showErrors'] = false;
            });
            updatedFormElements.push(formElements); // updated field value accordion to primary form
          });
          formArray.push(updatedFormElements); // push the updated field value in form array of data array
          updatedFormElements = []; // empty filed array
        }
      });
      // end form elements loop
      data[annualPassFormIndex].form = formArray; // update the form property with update fields values
      formArray = [];
    });
    // end form iteration
  }
  // update state with update fields values
  self.setState({
    data: data,
  });
};

const resetPayload = (resetData, payload) => {
  if (!resetData || !payload) return {};
  let newPayload = { ...payload };

  resetData.forEach((item) => {
    item &&
      item.form &&
      item.form.length > 0 &&
      item.form.forEach((formItem) => {
        formItem &&
          formItem.length > 0 &&
          formItem.forEach((info) => {
            newPayload[info.id] = info && info.value ? info.value : '';
          });
      });
  });
  return newPayload;
};

const reverseDate = (date, format) => {
  if (date && typeof date === 'string' && date.includes('/')) {
    let newDate = date.split('/');
    if (format && format.startsWith('Y')) newDate = newDate.reverse().join('-');
    else newDate = newDate.join('/');

    return newDate;
  }

  return date;
};

export { resetPayload, reverseDate };
