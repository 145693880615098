import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import {
  DropDown,
  Input,
  Checkbox,
  TextArea,
  TextField,
  Calendar,
  SelectWithText,
  AdvancedButton,
  InputHidden,
  CascadeDropDown,
  CalendarWithInput,
  EmailConfirmation,
  RadioButton,
  CheckboxGroup,
  Captcha,
  BMIField,
  Recaptcha,
} from '../../presentation/jss-form-components';
import FormSectionWrapper from './form-section-wrapper';
import UIConfig from '../../../common/UIConfig';

const {
  textFieldId,
  buttonId,
  calendarId,
  selectWithTextId,
  checkboxId,
  dropDownId,
  hiddenTypeId,
  emailConfirmationId,
  captchaTypeId,
  recaptchaTypeId,
} = UIConfig.jssForms.fields;
const {
  SingleLineText,
  Section,
  Email,
  MultipleLineText,
  DropdownList,
  RadioButtonList,
  CheckboxList,
  Button,
} = FieldTypes;
const { cascade, ageValidator, bmi } = UIConfig.jssForms.customSections;

export const componentMapping = {
  [SingleLineText]: Input,
  [Email]: Input,
  [MultipleLineText]: TextArea,
  [Section]: FormSectionWrapper,
  [Button]: AdvancedButton,
  [DropdownList]: DropDown,
  [buttonId]: AdvancedButton,
  [selectWithTextId]: SelectWithText,
  [checkboxId]: Checkbox,
  [calendarId]: Calendar,
  [textFieldId]: TextField,
  [dropDownId]: DropDown,
  [hiddenTypeId]: InputHidden,
  [emailConfirmationId]: EmailConfirmation,
  [RadioButtonList]: RadioButton,
  [CheckboxList]: CheckboxGroup,
  [captchaTypeId]: Captcha,
  [recaptchaTypeId]: Recaptcha,
};

export const customSectionMapping = {
  [cascade]: CascadeDropDown,
  [ageValidator]: CalendarWithInput,
  [bmi]: BMIField,
};
