/*
 * newsletter-signup-Component.js
 * This file contains code for newsletter component, it renders form with right and left image container
 * @licensor  Miral
 */

import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ApiWrapper from '../../../common/api-wrapper';
import {
  canUseDOM,
  resolvePath,
  getLeisureFacility,
  getLoggedInUser,
  isMatchTenant,
  checkTenant,
  getLanguageWithoutLocal,
  checkIfParks,
  checkGA4Tenants,
} from '../../../common/utility';
import GoalAnalytics from '../../../common/goal-analytics';
import { logComponentRenderingError } from '../../../common/logger';
import { newsletterSubscribeAnalytics } from '../../../common/analytics-events';
import { Image } from '../../presentation/base';
import Form from '../form/form-component';
import TextWithCTA from '../text-with-cta';
import { TrackerContext } from '@uniformdev/tracking-react';
import UIConfig from '../../../common/UIConfig';
import GTMData from '../b2c-purchase-journey/gtm-data';

const getLanguageForEmail = () => {
  return canUseDOM() ? document.getElementsByTagName('HTML')[0].getAttribute('lang') : 'en';
};

const sendMailForConfirmation = (response, params, url, isEventSubscription) => {
  // const obj =
  //   response.data &&
  //   response.data.communicationPreferencesVO.length &&
  //   response.data.communicationPreferencesVO[0];
  ApiWrapper.experienceServices({
    url,
    method: 'POST',
    data: {
      Email: params.email,
      //   PreferenceId: obj.comPrefId,
      Domain: window.location.origin,
      Tenant: params.tenant,
      Language: getLanguageForEmail(), //new paramter added for newseletter email
      isEventSubscription: isEventSubscription,
    },
  });
};

const sendTermAndCondition = (response, params, url) => {
  url = url.replace(':email', params.email);
  if (params.TermsAndConditions) {
    ApiWrapper.experienceServices({
      url,
      method: 'POST',
      data: {
        termsNConditionsIO: [
          {
            accepted: 'Y',
            channel: 'Email',
            condition: params.TermsAndConditions_URL,
            leisureFacility: getLeisureFacility() || '',
            type: 'Terms on Subscription',
            version: params.TermsAndConditions_Version,
          },
        ],
      },
    });
  }
};

const configureAutoComplete = (fields) => {
  fields.forEach((item) => {
    item.autocomplete = item.type === 'Email' ? 'off' : 'on';
  });
  return [...fields];
};

const NewsletterSignup = ({ data }) => {
  const isYaEventSubscription = data.variant === 'v-ya-event-newsletter';

  const [serverErrors, setServerErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  const tContext = useContext(TrackerContext);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const showSuccessMessage = () => {
    setSuccess(true);

    const titleEle = document.querySelector('.newsletter-signup-container .c-text-with-cta .w--content .title');
    if (titleEle) {
      titleEle.setAttribute('tabindex', '0');
      titleEle.focus();
    }
  };

  const onFormSubmitSuccess = (response, params) => {
    const { services, successMessage } = data;
    const { NewsletterSendMail, TermsAndConditions } = services;
    const { goal } = successMessage;

    showSuccessMessage();
    sendMailForConfirmation(response, params, NewsletterSendMail.url, isYaEventSubscription);
    sendTermAndCondition(response, params, TermsAndConditions.url);

    if (resolvePath(goal, 'goalId') && resolvePath(goal, 'services.setGoal.pageUrl')) {
      const goalAnalytics = new GoalAnalytics(goal, [], tContext, 'Newsletter Thank You');
      goalAnalytics.sendGoal();
    }

    newsletterSubscribeAnalytics(params);
  };

  const onFormSubmitError = (data, error) => {
    setServerErrors(error);
  };

  const onSubmitHideError = () => {
    setServerErrors(false);
  };

  const newsletterFormSubmit = (errors, payLoad) => {
    if (checkGA4Tenants(UIConfig.ga4ParksTenants)) {
      try {
        GTMData.push('subscribe', {});
      } catch (error) {
        console.log('Subcribe event error:', error);
      }
    }

    const params = { ...payLoad };
    if (!data) {
      return null;
    }
    const apiData = !checkTenant(UIConfig.iamMapping.ymc)
      ? data.services.NewsletterSubscriptionAPI
      : data.services.NewsletterSubscribe;
    const { yasId = '', tenantID } = getLoggedInUser();
    const { email } = UIConfig.CMPconsent;
    const newsletterData = {
      emailId: params.email,
      myPassId: yasId,
      channelName: tenantID,
      language: getLanguageWithoutLocal(),
      consentPurpose: [{ [email]: true }],
      source: '',
    };
    const ymcNewsletterData = {
      communicationPreferencesIO: [
        {
          channelName: UIConfig.newsletter.channelName,
          channelType: UIConfig.newsletter.channelType,
          channelValue: params.email,
          contentType: UIConfig.newsletter.contentType,
          language: getLanguageWithoutLocal(),
          explicit_opt_in_out: 'Y',
          implicit_opt_in_out: 'Y',
          leisureFacility: getLeisureFacility(),
        },
      ],
    };
    const postData = !checkTenant(UIConfig.iamMapping.ymc) ? newsletterData : ymcNewsletterData;
    ApiWrapper.apiGateway({
      url: apiData.url.replace(':email', params.email),
      method: data.method ? data.method : 'POST',
      data: postData,
      preLoader: true,
      preLoaderTarget: '.newsletter-signup-container',
      noAuthHeader: true,
    })
      .then((response) => {
        if (typeof response.data === 'object' && response.status === 201) {
          onFormSubmitSuccess(response, params);
          localStorage.removeItem('isEmailError');
        }
      })
      .catch((response) => {
        onFormSubmitError(data, apiData.errors[response.error.code]);
      });
  };

  const eventFormSubmit = (errors, { email }) => {
    const { eventId, eventName, sections } = data;
    const { url, errors: cmsErrors } = data.services.eventSubscriptionAPI;
    const fieldObj =
      sections &&
      sections.length &&
      sections[0].fields.reduce((obj, field) => {
        obj[field.name] = field.value;
        return obj;
      }, {});

    ApiWrapper.apiGateway({
      url,
      method: 'POST',
      data: {
        eventName: eventName,
        eventAk: eventId,
        language: getLanguageForEmail(),
        tenant: fieldObj.tenant,
        email: email,
        PID: fieldObj.PID,
      },
      preLoader: true,
      preLoaderTarget: '.newsletter-signup-container',
    })
      .then((res) => {
        if (typeof res.data === 'object' && res.data.isFormSubmitted) {
          showSuccessMessage();
        }
      })
      .catch((err) => {
        onFormSubmitError(null, cmsErrors[err.error.code]);
      });
  };

  const renderCustomError = () => {
    return (
      <div className="error-message" aria-live="assertive">
        {serverErrors}
      </div>
    );
  };

  const renderMarkupBasedOnState = () => {
    if (success) {
      return <TextWithCTA data={data && data.successMessage} />;
    } else {
      return (
        <div className={`${data.variant}`}>
          <Form
            data={data}
            submitCallback={isYaEventSubscription ? eventFormSubmit : newsletterFormSubmit}
            noScroll={true}
            renderCustomError={renderCustomError}
            isRenderCustomError={!!serverErrors && isSwad}
            onSubmitHideNewsletterError={onSubmitHideError}
          />
          {serverErrors && !isSwad && renderCustomError()}
        </div>
      );
    }
  };

  try {
    const formFields = data.sections && data.sections.length && data.sections[0].fields;
    data.sections[0].fields = configureAutoComplete(formFields);
    return (
      <div
        className={classnames('newsletter-signup-container component', {
          'v-ya-event-newsletter': isYaEventSubscription,
          servererror: serverErrors && UIConfig.SWADB2C,
        })}
        id="newsletter-signup-container"
      >
        <div className="w--content ns-container">
          <div className="ns-left-image">{data.leftImage && <Image image={data.leftImage} />}</div>
          <div className="ns-content" aria-live="polite">
            {renderMarkupBasedOnState()}
          </div>
          {data.rightImage && (
            <div className="ns-right-image">
              <Image image={data.rightImage} />
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'NewsletterSignup', data.variant);
  }
};

NewsletterSignup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default NewsletterSignup;
