import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import IAMPopup from '../../iam-popup/iam-popup';
import { Image } from '../../base';
import { openIAMOverlay, getMainObject, resolvePath, isLoggedInUser } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

import './yas-id-registration-step-two.scss';

const YasIDRegistration = (props) => {
  try {
    const { bodyCopy, ctaDetail, enableYasIDVersion, headerImage, imageDetail, shortTitle, variant } = props,
      mainObj = getMainObject(),
      isAutoLoginEnabled = resolvePath(mainObj, 'additionalProperty.enableAutoLogin'),
      imgDet3MoreBtnHref = resolvePath(imageDetail[3], 'moreLinkButton.href'),
      imgDet3MoreBtnLbl = resolvePath(imageDetail[3], 'moreLinkButton.label');

    return (
      variant === 'v-overlay-view' && (
        <div className="component c-yas-id-registration">
          <div className="yas-id-details">
            <div className="image-with-content bunny">
              <Image image={headerImage} />
            </div>
            <div className="image-with-content yas-id-details-right-content">
              <div className="image-with-content-upper yas-id">
                <span className="yas-id-image" />
              </div>
              {imageDetail &&
              imageDetail.length >= 4 &&
              imageDetail[3] &&
              imageDetail[3].isLoginUrl &&
              enableYasIDVersion ? (
                <Text
                  tag="div"
                  className="image-with-content-lower"
                  field={{ value: imageDetail[3].bodyCopy, editable: imageDetail[3].bodyCopy }}
                />
              ) : (
                <Text tag="div" className="image-with-content-lower" field={{ value: bodyCopy, editable: bodyCopy }} />
              )}
            </div>
          </div>
          <div className="yas-id-registration">
            <div className="yas-id-registration-contraries">
              {imageDetail[0] && (
                <div className="image-with-content">
                  <div className="image-with-content-upper contrary">
                    <Image image={imageDetail[0].imageInfo} />
                  </div>
                  <Text
                    tag="div"
                    className="image-with-content-lower"
                    field={{ value: imageDetail[0].imageTitle, editable: imageDetail[0].imageTitle }}
                  />
                </div>
              )}
              {imageDetail[1] && (
                <div className="image-with-content">
                  <div className="image-with-content-upper contrary">
                    <Image image={imageDetail[1].imageInfo} />
                  </div>
                  <Text
                    tag="div"
                    className="image-with-content-lower"
                    field={{ value: imageDetail[1].imageTitle, editable: imageDetail[1].imageTitle }}
                  />
                </div>
              )}
              {imageDetail[2] && (
                <div className="image-with-content">
                  <div className="image-with-content-upper contrary">
                    <Image image={imageDetail[2].imageInfo} />
                  </div>
                  <Text
                    tag="div"
                    className="image-with-content-lower"
                    field={{ value: imageDetail[2].imageTitle, editable: imageDetail[2].imageTitle }}
                  />
                </div>
              )}
            </div>
            {imageDetail &&
            imageDetail.length >= 4 &&
            imageDetail[3] &&
            imageDetail[3].isLoginUrl &&
            enableYasIDVersion ? (
              <div className="click-to-register">
                <button
                  className="click-to-register-content"
                  onClick={() => openIAMOverlay(<IAMPopup loginURL={imageDetail[3].moreLinkButton.href} />)}
                >
                  {imageDetail[3].moreLinkButton.label}
                </button>
                <Text
                  tag="span"
                  className="click-to-register-right"
                  field={{ value: imageDetail[3].subtitle, editable: imageDetail[3].subtitle }}
                />
              </div>
            ) : isLoggedInUser() &&
              enableYasIDVersion &&
              isAutoLoginEnabled &&
              imageDetail.length >= 4 &&
              imageDetail[3] &&
              imgDet3MoreBtnHref &&
              imgDet3MoreBtnLbl ? (
              <div className="click-to-register">
                <a href={`${imgDet3MoreBtnHref}`} className="click-to-register-content">
                  {imgDet3MoreBtnLbl}
                </a>
                <Text
                  tag="span"
                  className="click-to-register-right"
                  field={{ value: imageDetail[3].subtitle, editable: imageDetail[3].subtitle }}
                />
              </div>
            ) : (
              <div className="click-to-register">
                <a href="# " className="click-to-register-content" onClick={props.showOverLay}>
                  {ctaDetail.label}
                </a>
                <Text
                  tag="span"
                  className="click-to-register-right"
                  field={{ value: shortTitle, editable: shortTitle }}
                />
              </div>
            )}
          </div>
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'YasIDRegistration');
  }
};

YasIDRegistration.propTypes = {
  bodyCopy: PropTypes.string,
  imageDetail: PropTypes.object,
  ctaDetail: PropTypes.object,
  shortTitle: PropTypes.string,
};

export default YasIDRegistration;
