import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { isIntersectionObserverSupported, currentScrollPosition } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

import './lazy-load-component.scss';

export default class LazyLoad extends React.Component {
  static defaultProps = {
    placeholder: <div />,
    thresholdY: 0,
    callback: () => {},
    debounceWait: 600,
  };

  static propTypes = {
    callback: PropTypes.func,
    children: PropTypes.node.isRequired,
    placeholder: PropTypes.node,
    thresholdY: PropTypes.number,
    debounceWait: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      renderLazyLoadedComponent: false,
      isIntersectionObserverAvailable: isIntersectionObserverSupported(),
      callbackCalled: false,
      distanceOfElementFromTop: undefined,
    };
    this.observer = null;
    const { debounceWait } = this.props;
    this.handleViewportChange = this.handleViewportChange.bind(this);
    this.addEventListeners = this.addEventListeners.bind(this);
    this.removeEventListeners = this.removeEventListeners.bind(this);
    this.handleViewportChangeEvents = this.handleViewportChangeEvents.bind(this);
    this.handleViewportChangeEvents = debounce(this.handleViewportChangeEvents, debounceWait);
    this.handleViewportChange = debounce(this.handleViewportChange, debounceWait);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const { isIntersectionObserverAvailable } = this.state;
    if (this.ref && this.ref.current) {
      this.setPlaceholderNodePosition();
      if (isIntersectionObserverAvailable) {
        this.createObserver();
      } else {
        this.addEventListeners();
      }
    }
  }

  componentWillUnmount() {
    const { isIntersectionObserverAvailable } = this.state;
    if (isIntersectionObserverAvailable) {
      this.observer.disconnect();
    } else {
      this.removeEventListeners();
    }
  }

  setPlaceholderNodePosition = () => {
    const elementPos = this.ref.current.getBoundingClientRect();
    this.setState({
      distanceOfElementFromTop: elementPos.top,
    });
  };

  handleViewportChangeEvents = () => {
    if (this.loadOnVerticalScroll()) {
      this.setState({ renderLazyLoadedComponent: true });
      this.callCallback();
      this.removeEventListeners();
    }
  };

  addEventListeners = () => {
    window.addEventListener('resize', this.handleViewportChangeEvents);
    window.addEventListener('scroll', this.handleViewportChangeEvents);
  };

  removeEventListeners = () => {
    window.removeEventListener('resize', this.handleViewportChangeEvents);
    window.removeEventListener('scroll', this.handleViewportChangeEvents);
  };

  loadOnVerticalScroll = () => {
    const { distanceOfElementFromTop } = this.state;
    const { thresholdY } = this.props;
    const scrollYDistance = currentScrollPosition().scrollY;
    const scrolledFromTop = window.innerHeight + scrollYDistance;
    return distanceOfElementFromTop - thresholdY < scrolledFromTop;
  };

  createObserver = () => {
    const { thresholdY } = this.props;
    const options = {
      root: null,
      rootMargin: `0px 0px ${thresholdY}px 0px`,
      threshold: 0.0,
    };

    this.observer = new IntersectionObserver(this.handleViewportChange, options);
    this.observer.observe(this.ref.current);
  };

  handleViewportChange = (changes) => {
    changes.forEach((change) => {
      if (change.intersectionRatio > 0) {
        this.setState({ renderLazyLoadedComponent: true });
        this.callCallback();
        this.observer.disconnect();
      }
    });
  };

  callCallback = () => {
    const { callback } = this.props;
    const { callbackCalled } = this.state;
    if (!callbackCalled && typeof callback === 'function') {
      callback();
      this.setState({ callbackCalled: true });
    }
  };

  render() {
    try {
      const { children, placeholder } = this.props;
      const { renderLazyLoadedComponent } = this.state;

      return <div ref={this.ref}>{renderLazyLoadedComponent ? children : placeholder}</div>;
    } catch (err) {
      return logComponentRenderingError(err, 'LazyLoad');
    }
  }
}
