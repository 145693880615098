/**
 * calendar-component.js
 * This file contains code for calendar component
 * @author      Vivek Gaur, Sapient - (vgaur4)
 * @license     Miral
 */

import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  canUseDOM,
  KeyCode,
  momentTimezone,
  getMainObject,
  getLoggedInUser,
  currentLocale,
  isB2B,
  getClientUtcOffset,
  checkTenant,
  convertJsDateToMomentObj,
} from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import { DynamicContent } from '..';
import UIConfig from '../../../../common/UIConfig';
import './calendar-component.scss';
import {
  enableVisitedDate,
  visitedDatePreselection,
} from '../../../container/b2c-purchase-journey/add-ons-overlay/helper';
class Calendar extends Component {
  constructor(props) {
    super(props);
    this.calendarSettings = this.props.calendarSettings || {};
    this.allEnabledDates = this.getFormattedDates(this.calendarSettings.enabledDates);
    this.includeDates = this.allEnabledDates
      ? this.allEnabledDates.sort(function(left, right) {
          return moment(left).format('X') - moment(right).format('X');
        })
      : null;
    this.excludeDates = this.getFormattedDates(this.calendarSettings.disabledDates) || [];
    this.currentDate = moment();
    this.includeDates = this.includeDates
      ? this.includeDates.filter((d) => momentTimezone(d, getClientUtcOffset()).isSameOrAfter(this.currentDate, 'days'))
      : this.includeDates;
    this.tooltipMap = this.calendarSettings.tooltipmap;
    this.selectDefaultDate = this.calendarSettings.enableDatePreSelection && this.props.isDatePreSelection;
    this.onLoad = this.props.isDynamicPricingProd && !this.selectDefaultDate;
    this.state = {
      minDate: this.initDate(props) || moment(new Date()),
      startDate: this.initDate(props) || moment(new Date()),
      tooltipActiveDates: null,
      blockJumpToActiveDate: false,
      dateOnMonthChange: null,
    };
    this._datepicker = null;
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
  }
  applyTodayLabel = () => {
    const { todayLabel } = this.props.calendarSettings || {};
    if (todayLabel) {
      const selectedDateEle = document.querySelector('.react-datepicker__day--disabled.react-datepicker__day--today');
      if (selectedDateEle) {
        const activedateClass = document.querySelector('.react-datepicker__day--disabled.active-dates');
        activedateClass && selectedDateEle.classList.remove('active-dates');
        selectedDateEle.classList.add('today-label');
        selectedDateEle.setAttribute('dataToday', `${todayLabel}`);
      }
    }
  };
  handleChange = (date, isMount, isDynamicPricing = false, firstLoad = false) => {
    date = visitedDatePreselection(this, date, isDynamicPricing, firstLoad);
    if (moment.isMoment(date) && (!isDynamicPricing || this.selectDefaultDate)) {
      this.setState({ startDate: date }, this.props.setDateCustom(date, isMount));
      this.props.isDynamicPricingProd && this.selectDefaultDate && this.setDatePickerPreSelection(date);
    }
  };
  checkIsArray(data) {
    return data && data.length > 0;
  }
  initDate = (props) => {
    const minDate = this.props.minDate;
    const startDate = momentTimezone(this.calendarSettings.startDate, getClientUtcOffset());
    let findGreaterMinDate = minDate || startDate;
    let disabledInsideValidOn = true;
    const validOn = this.calendarSettings.validOnRange || this.calendarSettings.validOn || [];
    let filteredPerformance = [];
    const customDates =
      props.isDynamicPricingProd &&
      this.selectDefaultDate &&
      props.highlightDates &&
      props.highlightDates.length > 0 &&
      props.highlightDates.find((item) => item['active-dates']);
    if (minDate && momentTimezone(this.calendarSettings.startDate, getClientUtcOffset())) {
      findGreaterMinDate = minDate.isSameOrAfter(startDate, 'days') ? minDate : startDate;
    }

    if (customDates && this.checkIsArray(customDates['active-dates'])) {
      const sortedDates = customDates['active-dates'].sort((a, b) => new Date(a) - new Date(b));
      //filterdate after defaultdeltadate
      const filteredDefaultDate = sortedDates.filter((d) =>
        momentTimezone(d, getClientUtcOffset()).isSameOrAfter(findGreaterMinDate, 'day'),
      );
      //filterexcludedates
      if (this.checkIsArray(this.excludeDates)) {
        filteredPerformance =
          this.checkIsArray(filteredDefaultDate) &&
          filteredDefaultDate.filter(
            (ar) => !this.excludeDates.some((ar1) => momentTimezone(ar, getClientUtcOffset()).isSame(ar1, 'day')),
          );
      } else {
        filteredPerformance = filteredDefaultDate;
      }
      //update include dates
      if (this.checkIsArray(this.includeDates)) {
        this.includeDates =
          this.checkIsArray(filteredPerformance) &&
          filteredPerformance.filter((ar) =>
            this.includeDates.some((ar1) => momentTimezone(ar, getClientUtcOffset()).isSame(ar1, 'day')),
          );
      }
      findGreaterMinDate = this.getminDate(filteredPerformance, findGreaterMinDate);
    }
    let date = props.selectedDate || findGreaterMinDate;
    // Calendar logic changed EP-2144
    // ValidOn will decide calendar week disabling and if any enabled date is present in validOn week then that date will be enabled.
    // Default Delta will decide the start date at the first based on the value of delta cominig from CMS.
    // Disabled Dates will disable the particular dates in the calendar.
    // Below logic will calculate the start date.

    while (disabledInsideValidOn) {
      if (
        validOn &&
        validOn.length > 0 &&
        momentTimezone(date, getClientUtcOffset()) &&
        validOn.indexOf(momentTimezone(date, getClientUtcOffset()).day()) < 0
      ) {
        if (
          this.includeDates &&
          this.includeDates.find((d) =>
            momentTimezone(d, getClientUtcOffset()).isSame(momentTimezone(date, getClientUtcOffset()), 'day'),
          )
        ) {
          date = momentTimezone(date, getClientUtcOffset());
          disabledInsideValidOn = false;
        } else {
          date = momentTimezone(date, getClientUtcOffset()).add(1, 'days');
          disabledInsideValidOn = true;
        }
      } else if (this.checkIsArray(this.excludeDates)) {
        disabledInsideValidOn = false;

        if (this.checkIsArray(filteredPerformance)) {
          date = momentTimezone(date, getClientUtcOffset());
          disabledInsideValidOn = false;
        } else {
          for (let i = 0; i < this.excludeDates.length; i++) {
            if (
              momentTimezone(this.excludeDates[i], getClientUtcOffset()).isSame(
                momentTimezone(date, getClientUtcOffset()),
                'day',
              )
            ) {
              date = momentTimezone(date, getClientUtcOffset()).add(1, 'days');
              disabledInsideValidOn = true;
            }
          }
        }
      } else if (validOn && validOn.length === 0 && this.checkIsArray(this.includeDates)) {
        date = this.includeDates[0];
        disabledInsideValidOn = false;
      } else {
        if (props.isDynamicPricingProd) {
          date = props.selectedDate ? momentTimezone(props.selectedDate, getClientUtcOffset()) : date;
        } else {
          date = props.selectedDate ? momentTimezone(props.selectedDate, getClientUtcOffset()) : '';
        }
        this.props.isTotalAvailabilityCal &&
          date !== '' &&
          this.setState({
            startDate: date?.isSameOrAfter(startDate, 'days') && date?.isSameOrAfter(minDate, 'days') ? date : '',
          });
        disabledInsideValidOn = false;
      }
    }
    if (date !== '' && date?.isSameOrAfter(startDate, 'days') && date?.isSameOrAfter(minDate, 'days')) return date;

    return '';
  };
  getminDate(dates, findGreaterMinDate) {
    const arrDates = this.checkIsArray(dates) && dates.map((str) => new Date(str));
    const mindateFromFilteredArray = arrDates && arrDates.length > 0 && new Date(Math.min(...arrDates));
    const minDate = momentTimezone(mindateFromFilteredArray, getClientUtcOffset()).isSameOrAfter(
      findGreaterMinDate,
      'day',
    )
      ? mindateFromFilteredArray
      : findGreaterMinDate;
    return momentTimezone(minDate, getClientUtcOffset());
  }
  getFormattedDates(dates) {
    return dates && dates.length ? dates.map((dateString) => momentTimezone(dateString, getClientUtcOffset())) : null;
  }

  componentDidMount() {
    if (this.props.calendarOverlay && !this.calendarSettings.enableDatePreSelection && enableVisitedDate()) {
      this.setState(
        {
          startDate: convertJsDateToMomentObj(
            localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.visitedDate),
          ),
        },
        this.props.setSelectedDefaultDate && this.props.setSelectedDefaultDate(),
      );
      this.onLoad = false;
    }
    const selected = this.props.openToHighlightedDate || this.state.startDate || moment();
    const selectedMonth = moment(selected).format('MM');
    let tooltipActiveDates = this.getSelMonthTooltip(selectedMonth);
    this.setState({
      tooltipActiveDates,
    });
    this.openDatePicker();
    if (!this.props.isDynamicPricingProd) {
      this.handleChange(this.initDate(this.props), true, this.props.isDynamicPricingProd, true);
    }
  }

  componentDidUpdate(prevProps) {
    const localObj = getMainObject() || getLoggedInUser();

    if (
      localObj &&
      localObj.tenantID &&
      localObj.tenantID.toLowerCase() === UIConfig.ymcB2CTenant &&
      prevProps.highlightDates !== this.props.highlightDates
    ) {
      this.setState({ blockJumpToActiveDate: true });
      if (this.props.selectedDate) {
        this.handleChange(this.initDate(this.props), true);
        this.datePickerMonthChange(momentTimezone(this.props.selectedDate, getClientUtcOffset()));
      }
    } else if (
      prevProps.highlightDates !== this.props.highlightDates &&
      localObj.tenantID.toLowerCase() !== UIConfig.ymcB2CTenant
    ) {
      setTimeout(() => {
        this.applyTodayLabel();
      }, 50);
    }
  }

  removeFocusDate = () => {
    const focusedDateEle = document.querySelector('.focused-date');
    focusedDateEle && focusedDateEle.classList.remove('focused-date');
  };

  handleDatePickerKeyDown = (e) => {
    const kCode = e.keyCode || e.which;
    // To remove highlighted date of next month
    document.body.classList.remove('date-highlight-remove');
    // Both condition to check if the key pressed is Tab
    if (e.key === 'Tab' || kCode === KeyCode.TAB) {
      this.removeFocusDate();
    } else {
      this.setFocusOnSelectedDate(e);
    }
  };

  setFocusOnSelectedDate = (e) => {
    const reactDatepickerNavigation = document.querySelector('.react-datepicker-popper .react-datepicker__navigation');
    if (e.key === 'Enter') {
      this.removeFocusDate();
      reactDatepickerNavigation && reactDatepickerNavigation.setAttribute('tabindex', '-1');
      return;
    }
    reactDatepickerNavigation && reactDatepickerNavigation.setAttribute('tabindex', '0');
    setTimeout(() => {
      this.removeFocusDate();
      const selectedDateEle = document.querySelector('.react-datepicker__day--selected');
      const keyboardSelectedDateEle = document.querySelector('.react-datepicker__day--keyboard-selected');
      if (!keyboardSelectedDateEle && selectedDateEle) {
        selectedDateEle.classList.add('focused-date');
      }
      this.changeAriaLabel();
    }, 10);
  };

  changeAriaLabel = () => {
    const isFocusedDate = document.querySelector('.focused-date');
    const selected = canUseDOM() && isFocusedDate?.getAttribute('aria-label');
    if (selected) {
      document.querySelector('#selectedDateId').innerHTML = selected;
      this.applyTodayLabel();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.variant &&
      (this.props.reset !== nextProps.reset ||
        (this.props.selectedDate && this.props.selectedDate.toString() !== nextProps.selectedDate.toString()))
    ) {
      this.handleChange(this.initDate(nextProps), false, false, true);
    }
    if (nextProps.carProdsClicked || nextProps.resetonTabChange || nextProps.isDatePreSelection) {
      if (nextProps.isDatePreSelection) {
        this.selectDefaultDate = this.calendarSettings.enableDatePreSelection && nextProps.isDatePreSelection;
        this.handleChange(this.initDate(nextProps), true, false, true);
      }

      this.setState({
        dateOnMonthChange: null,
        blockJumpToActiveDate: false,
      });
    }
  }

  getSelMonthTooltip = (selectedMonth) => {
    if (!selectedMonth) return false;
    const tooltipMap = { ...this.tooltipMap };
    const tMap =
      tooltipMap &&
      Object.keys(tooltipMap).filter((mapDate) => {
        let mDate = mapDate.split('/');
        return mDate[1] === selectedMonth;
      });
    return tMap;
  };

  datePickerMonthChange = (newDate) => {
    if (!newDate) return false;
    const selectedMonth = moment(newDate).format('MM');
    let tooltipActiveDates = this.getSelMonthTooltip(selectedMonth);
    this.setState({
      tooltipActiveDates,
      dateOnMonthChange: newDate,
      blockJumpToActiveDate: true,
    });
    // To show highlighted date of next month
    document.body.classList.add('date-highlight-remove');
  };

  renderToolTip = (selected) => {
    const toolTipRender =
      this.state.tooltipActiveDates &&
      this.state.tooltipActiveDates.map((date, indx) => {
        const tooltipText = this.tooltipMap[date],
          toolTipDateParts = date.split('/'),
          toolTipDateNum = toolTipDateParts[2],
          toolTipYear = toolTipDateParts[0],
          toolTipMonth = toolTipDateParts[1],
          innerHtmlTest = `div.react-datepicker__day.date-${toolTipDateNum}-${toolTipMonth}-${toolTipYear}.react-datepicker__day--0${toolTipDateNum}:hover::after {
                      content : "${tooltipText}" !important;
                  }`;

        return <DynamicContent tagName="style" innerHtml={innerHtmlTest} />;
      });
    return toolTipRender;
  };

  openDatePicker = () => {
    this.props.isKeyboardNavigationAllowed && this._datepicker && this._datepicker.setOpen(true);
    if (this.props.setCarProdState) this.props.setCarProdState();
    setTimeout(() => {
      if (this.props.enableActiveDates && this.props.isDynamicPricingProd && this.props.isBookingOverlay) {
        const selectedDateEle = document.querySelector('.react-datepicker__month');
        selectedDateEle.classList.add('enable-active-dates');
      }
      this.applyTodayLabel();
    }, 0);
  };

  setDatePickerPreSelection = (date) => this._datepicker && this._datepicker.setPreSelection(date);

  calendarSelectedDate = () => {
    if (
      this.props.isDynamicPricingProd &&
      (this.props.carProdsClicked || this.props.resetonTabChange) &&
      !this.selectDefaultDate
    )
      return '';

    const localObj = getMainObject() || getLoggedInUser();
    if (localObj && localObj.tenantID && localObj.tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
      if (!this.onLoad)
        return !this.state.startDate
          ? new Date()
          : new Date(`${moment(this.state.startDate).format(UIConfig.calendar.dateFormat)}`);
      return '';
    } else {
      if (!this.onLoad)
        return this.state.startDate == '' || this.state.startDate == null
          ? this.state.startDate
          : new Date(moment.isMoment(this.state.startDate) ? this.state.startDate.format() : this.state.startDate);
      return '';
    }
  };

  calendarInline = () => {
    if ((this.props.isDynamicPricingProd && (this.props.carProdsClicked || this.props.resetonTabChange)) || this.onLoad)
      return true;
    if (!this.props.inlineDisable) return false;
    return true;
  };

  calendarClose = () => {
    // To remove highlighted date of next month
    document.body.classList.remove('date-highlight-remove');
  };
  render() {
    try {
      let openToDate;
      const validOn = this.calendarSettings.validOnRange || this.calendarSettings.validOn || [];
      const { blockJumpToActiveDate, dateOnMonthChange } = this.state,
        localObj = getMainObject() || getLoggedInUser(),
        highlightDates = this.props.highlightDates && this.props.highlightDates.find((item) => item['active-dates']);
      if (localObj && localObj.tenantID && localObj.tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
        if (highlightDates && highlightDates['active-dates'].length > 0) {
          this.props.highlightDates.forEach((item) => {
            if (blockJumpToActiveDate && item['active-dates'] && item['active-dates'].length > 0) {
              openToDate = item['active-dates'][0];
            }
            if (blockJumpToActiveDate && dateOnMonthChange) {
              openToDate = dateOnMonthChange;
            }
          });
        } else if (blockJumpToActiveDate && dateOnMonthChange) {
          openToDate = dateOnMonthChange;
        } else {
          openToDate = moment();
        }
      }
      let minActualDate;
      if (this.includeDates & validOn && validOn.length === 0) {
        if (this.includeDates[0] == null || this.includeDates[0] == '') {
          minActualDate = this.includeDates[0];
        } else {
          minActualDate = new Date(
            moment.isMoment(this.includeDates[0]) ? this.includeDates[0].format() : this.includeDates[0],
          );
        }
      } else {
        if (this.props.isDynamicPricingProd) {
          if (this.state.minDate == null || this.state.minDate === '') {
            minActualDate = this.state.minDate;
          } else {
            minActualDate = new Date(
              moment.isMoment(this.state.minDate) ? this.state.minDate.format() : this.state.minDate,
            );
          }
        } else {
          if (this.props.minDate == null || this.props.minDate == '') {
            minActualDate = this.props.minDate;
          } else {
            minActualDate = new Date(
              moment.isMoment(this.props.minDate) ? this.props.minDate.format() : this.props.minDate,
            );
          }
        }
      }
      let maxActualDate;
      if (this.includeDates & validOn && validOn.length === 0) {
        if (this.includeDates[this.includeDates.length - 1] == null) {
          maxActualDate = this.includeDates[this.includeDates.length - 1];
        } else {
          maxActualDate = this.includeDates[this.includeDates.length - 1].toDate();
        }
      } else {
        if (this.props.maxDate == null || this.props.maxDate == '') {
          maxActualDate = this.props.maxDate;
        } else {
          maxActualDate = this.props.maxDate.toDate();
        }
      }
      let openToDateActual;
      if (openToDate) {
        openToDateActual = moment(openToDate).toDate();
      } else {
        if (this.props.openToDate == null || this.props.openToDate == '') {
          openToDateActual = new Date();
        } else {
          openToDateActual = moment(this.props.openToDate).toDate();
        }
      }
      return (
        <div className="cal-date-picker-wrapper">
          <span aria-live="assertive" id="selectedDateId" className="sr-only"></span>
          <DatePicker
            open
            ref={(dp) => (this._datepicker = dp)}
            locale={isB2B() ? UIConfig.languages.enGB : currentLocale()}
            fixedHeight={this.props.fixedHeight || false}
            dayClassName={(date) =>
              `react-datepicker__day--${moment(date)
                .format('ddd')
                .toLowerCase()} date-${moment(date)
                .format(UIConfig.dateFormats.DD_MM_YYYY)
                .toLowerCase()}`
            }
            onCalendarClose={() => this.calendarClose()}
            selected={this.calendarSelectedDate()}
            inline={this.isSwad ? this.props.inlineDisable : this.calendarInline()}
            monthsShown={this.props.dispMonths}
            minDate={minActualDate}
            maxDate={maxActualDate}
            popperPlacement={this.props.popperPlacement ? this.props.popperPlacement : ''}
            onChange={this.handleChange}
            tabIndex={this.props.inlineDisable ? 0 : 1}
            dateFormat={this.props.dateFormat ? this.props.dateFormat : UIConfig.calendar.spaceDateFormat}
            placeholderText={this.props.placeholderText ? this.props.placeholderText : ''}
            onBlur={this.props.onBlurCustom && ((e) => this.props.onBlurCustom(e, this.props.name, this.props.data))}
            filterDate={this.props.filterDate}
            openToDate={openToDateActual}
            excludeDates={
              this.excludeDates == undefined || this.excludeDates.length == 0
                ? this.excludeDates
                : this.excludeDates.map((dates) => dates.toDate())
            }
            includeDates={validOn && validOn.length === 0 && this.includeDates}
            calendarClassName={this.calendarSettings.calendarTooltip ? 'date-picker-tooltip-on' : ''}
            highlightDates={this.props.highlightDates}
            onMonthChange={this.datePickerMonthChange}
            shouldCloseOnSelect={!this.props.disableCloseOnSelect}
            onFocus={this.props.isKeyboardNavigationAllowed && ((e) => this.setFocusOnSelectedDate(e))}
            onKeyDown={this.props.isKeyboardNavigationAllowed && ((e) => this.handleDatePickerKeyDown(e))}
            showDisabledMonthNavigation
          >
            {this.renderToolTip(this.state.startDate)}
            {this.applyTodayLabel()}
          </DatePicker>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'Calendar');
    }
  }
}

export default Calendar;
