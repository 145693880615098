import React, { useEffect } from 'react';
import UIConfig from '../../../common/UIConfig';
import { setLocalStorage } from '../../../common/utility';

const JSSFormSettings = (props) => {
  const jssFormData = { ...props };

  useEffect(() => {
    setLocalStorage(UIConfig.jssForms.events.jssFormSettingsData, JSON.stringify(jssFormData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default JSSFormSettings;
