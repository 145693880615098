/*
 * image-with-ratio-components.js
 * This file contains code for image component which will render diiferent renditions based on viewport.
 * @licensor  Miral
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { breakpoints, canUseDOM } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { DynamicContent } from '..';
import classNames from 'classnames';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * ImageTag creates picture tag containing different image renditions
 * @param    {[Object]} Object containing different image set for desktop, tablet and mobile(desktopImage,tabletImage,mobileImage)
 * @return   {[Object]} JSX for Picture tag
 */
const ImageWithRatio = (props) => {
  const [loaded, setLoaded] = useState(false);
  try {
    const img = props.image;
    if (!img) {
      return null;
    }
    let image = null;

    if (props.noRendition) {
      return getSimpleImgTag(img, loaded, setLoaded);
    }

    if (props.image.desktopImage && props.image.desktopImage.html) {
      /*This is used for inline editing in CMS*/
      image = <EditableImages item={props.image.desktopImage.html} />;
    } else if (_isValid(img.mobileImage) || _isValid(img.tabletImage) || _isValid(img.desktopImage)) {
      image = getActualImage(props, loaded, setLoaded);
    }
    return image;
  } catch (err) {
    return logComponentRenderingError(err, 'Image');
  }
};

const _isValid = (img) => img && img.src;

/**
 * EditableImages creates element for CMS inline editing
 */
const EditableImages = (props) => {
  return <DynamicContent tagName="div" attrs={{ className: 'editable-image' }} innerHtml={props.item} />;
};

const getDimensions = (image, value) => {
  if (canUseDOM() && window.innerWidth < breakpoints.tablet) {
    return image.mobileImage[value] ? image.mobileImage[value] : '';
  } else if (canUseDOM() && window.innerWidth < breakpoints.desktop) {
    return image.tabletImage[value] ? image.tabletImage[value] : '';
  } else {
    return image.desktopImage[value] ? image.desktopImage[value] : '';
  }
};

/**
 * getActualImage checks the condition to load the desired image
 */
const getActualImage = (props, loaded, setLoaded) => {
  if (UIConfig.imageLazyLoad) {
    if (props.disableLazyLoad) {
      return imageWithoutDataSrc(
        props.image,
        props.usemap,
        props.isOpacityRemove,
        props.isMaxHeight,
        loaded,
        setLoaded,
      );
    } else {
      return imageWithDataSrc(props.image, props.usemap, props.isMaxHeight, loaded, setLoaded);
    }
  } else {
    return imageWithoutDataSrc(props.image, props.usemap, props.isOpacityRemove, props.isMaxHeight, loaded, setLoaded);
  }
};

/**
 * imageWithoutDataSrc creates picture tag containing different image renditions, with lazy-load data attribute
 */
const imageWithDataSrc = (image, usemap, isMaxHeight, loaded, setLoaded) => {
  const height = getDimensions(image, 'height');
  const width = getDimensions(image, 'width');
  const maxheight = isMaxHeight && getDimensions(image, 'height') > 513 ? 513 : getDimensions(image, 'height');
  return (
    <>
      {height && width && !loaded ? (
        <svg width={width} height={maxheight} viewBox={`0 0 ${width} ${height}`}>
          <rect width={width} height={height} fill={UIConfig.ymcDefaultBgColor} opacity=".5" />
        </svg>
      ) : null}
      <picture>
        <source data-srcset={image.mobileImage.src} media={`(max-width: ${breakpoints.tablet - 1}px)`} />
        <source data-srcset={image.tabletImage.src} media={`(max-width: ${breakpoints.desktop - 1}px)`} />
        <source data-srcset={image.desktopImage.src} />
        <img
          data-src={image.desktopImage.src}
          className="lazyload"
          alt={image.imageAltText}
          useMap={usemap}
          style={!loaded ? { visibility: 'hidden' } : {}}
          onLoad={() => setLoaded && setLoaded(true)}
        />
        <span className="loading-icon"></span>
      </picture>
    </>
  );
};
/**
 * imageWithoutDataSrc creates picture tag containing different image renditions, but without lazy-load
 */
const imageWithoutDataSrc = (image, usemap, isOpacityRemove = false, isMaxHeight, loaded, setLoaded) => {
  const maxheight = isMaxHeight && getDimensions(image, 'height') > 513 ? 513 : getDimensions(image, 'height');
  const height = getDimensions(image, 'height') > 500 ? 500 : getDimensions(image, 'height');
  const width = getDimensions(image, 'width');

  return (
    <>
      {height && width && !loaded ? (
        <svg width={width} height={maxheight} viewBox={`0 0 ${width} ${height}`}>
          <rect width={width} height={height} fill={UIConfig.ymcDefaultBgColor} opacity=".5" />
        </svg>
      ) : null}
      <picture className={classNames({ 'hide-opacity': isOpacityRemove })}>
        <source media={`(max-width: ${breakpoints.tablet - 1}px)`} srcSet={image.mobileImage.src} />
        <source media={`(max-width: ${breakpoints.desktop - 1}px)`} srcSet={image.tabletImage.src} />
        <img
          src={image.desktopImage.src}
          alt={image.imageAltText}
          useMap={usemap}
          style={!loaded ? { visibility: 'hidden' } : {}}
          onLoad={() => setLoaded && setLoaded(true)}
        />
      </picture>
    </>
  );
};

/**
 * getSimpleImgTag creates html native <img /> tag
 */
const getSimpleImgTag = (props, loaded, setLoaded) => {
  let imgTag = null;
  let height = props.height;
  let width = props.width;
  if (UIConfig.imageLazyLoad) {
    if (props.disableLazyLoad) {
      imgTag = (
        <img
          src={props.src}
          alt={props.alt}
          style={!loaded ? { visibility: 'hidden' } : {}}
          onLoad={() => setLoaded && setLoaded(true)}
        />
      );
    } else {
      imgTag = (
        <img
          className="lazyload"
          data-src={props.src}
          alt={props.alt}
          style={!loaded ? { visibility: 'hidden' } : {}}
          onLoad={() => setLoaded && setLoaded(true)}
        />
      );
    }
  } else {
    imgTag = (
      <img
        src={props.src}
        alt={props.alt}
        style={!loaded ? { visibility: 'hidden' } : {}}
        onLoad={() => setLoaded && setLoaded(true)}
      />
    );
  }
  return (
    <>
      {imgTag}
      {height && width && !loaded ? (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <rect width={width} height={height} fill={UIConfig.ymcDefaultBgColor} />
        </svg>
      ) : null}
    </>
  );
};

ImageWithRatio.propTypes = {
  desktopImage: PropTypes.shape({
    html: PropTypes.string,
    src: PropTypes.string,
  }),
  tabletImage: PropTypes.shape({
    html: PropTypes.string,
    src: PropTypes.string,
  }),
  mobileImage: PropTypes.shape({
    html: PropTypes.string,
    src: PropTypes.string,
  }),
  altText: PropTypes.string,
  disableLazyLoad: PropTypes.bool,
};

export default ImageWithRatio;
