import {
  getFormattedTime,
  getLoggedInUser,
  getProdDetailsFromOrderDetails,
  getProdIdType,
  isAddonProduct,
  resolvePath,
  getMainObject,
  getYasIdGTMData,
  getEmailIdGTMData,
  getSessionStorage,
  getCookie,
  isLoggedInUser,
  setSessionStorage,
  parseQueryString,
  getMainStayCategory,
} from './utility';
import GTMData from '../components/container/b2c-purchase-journey/gtm-data';

import CartActions from '../components/container/b2c-purchase-journey/cart/cart-actions';
import UIConfig from '../common/UIConfig';

const emailId = (params, keyName) => {
  const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
  const email = resolvePath(params, 'email', '') || resolvePath(params, 'Email', '');
  if (resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C) {
    return { [keyName]: email };
  }
};

export const newsletterSubscribeAnalytics = (params) => {
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: 'JoinOurStory',
    eventLabel: 'Submit',
    ...emailId(params, 'user_id'),
    myPassId: getYasIdGTMData(),
  });
};

export const contactUsAnalytics = (formType, params) => {
  const analyticsEventAction = resolvePath(params, 'AnalyticsEventAction', '');
  const formTypeValue = resolvePath(params, 'FormTypeValue', '');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: analyticsEventAction || 'ContactUs',
    eventLabel: formTypeValue || formType || 'Submit',
    ...emailId(params, 'user_id'),
    myPassId: getYasIdGTMData(),
  });
};

export const PaymentAnalytics = (errCode, errMsg) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'error_message',
    error_code: errCode,
    error_message: errMsg,
    myPassId: getYasIdGTMData(),
  });
};

export const socialIconsAnalytics = (target, title) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'Social Clicks',
    eventAction: target,
    eventLabel: title,
    myPassId: getYasIdGTMData(),
  });
};

export const groupBookingErrorAnalytics = (tabName, page) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'groupTicket',
    eventCategory: 'GroupTicketSuggestion',
    eventAction: tabName,
    eventLabel: page,
    myPassId: getYasIdGTMData(),
  });
};

export const addWishlistAnalytics = (wishlist) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: 'Wishlist Add',
    eventLabel: wishlist,
    myPassId: getYasIdGTMData(),
  });
};

export const removeWishlistAnalytics = (wishlist) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: 'Wishlist Remove',
    eventLabel: wishlist,
    myPassId: getYasIdGTMData(),
  });
};

export const checkAvailabilityAnalytics = (link) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: 'CheckAvailability',
    eventLabel: link,
    myPassId: getYasIdGTMData(),
  });
};

export const bookNowClickAnalytics = (eventLabel, data = {}) => {
  window.dataLayer = window.dataLayer || [];
  const eventAction = (data && data.secondaryBuyLabel) || 'Buy Now';

  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction,
    eventLabel,
    myPassId: getYasIdGTMData(),
  });
};

export const footerClickAnalytics = (targerUrl, eventLabel, sectionNameForAnalytic) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'e_footerinteraction',
    category: 'footer interaction',
    action: 'link click',
    label: `${sectionNameForAnalytic} - ${eventLabel}`,
  });
};

export const headerClickAnalytics = (targerUrl, eventLabel) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'e_headerinteraction',
    category: 'header interaction',
    action: 'menu item click',
    label: eventLabel,
  });
};

export const subHeaderClickAnalytics = (targerUrl, eventLabel) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'e_headerinteraction',
    category: 'header interaction',
    action: 'floating menu item click',
    label: eventLabel,
  });
};

export const searchClickAnalytics = (eventLabel) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'e_headerinteraction',
    category: 'header interaction',
    action: 'search',
    label: `${eventLabel}`,
  });
};

export const bookYourHolidayClickAnalytics = () => {
  window.dataLayer = window.dataLayer || [];
  const eventAction = 'Book Your Holiday';
  const eventLabel = window.location.href;
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction,
    eventLabel,
    myPassId: getYasIdGTMData(),
  });
};

export const getQuoteAnalytics = (params, response, textsOfParams) => {
  const quoteId = response.success.CRMId.match(/\((.*)\)/)[1];
  const date = params.EventDate;
  const eventOwner = params.CompanyName;
  const eventLabel = document && document.referrer;
  const { NumberOfAttendees, Country } = textsOfParams;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: 'Submit a Quote',
    eventLabel,
    quoteId,
    attendees: NumberOfAttendees,
    date,
    eventOwner,
    country: Country,
    myPassId: getYasIdGTMData(),
  });
};

export const HnFRegistrationAnalytics = (params, response, textsOfParams) => {
  const infoSource = params.HearAboutUsFrom ? params.HearAboutUsFrom.join('|') : '';
  const {
    CountryOfResidence,
    ExerciseFrequency,
    Gender,
    HeightInCm,
    IntensityOfActivity,
    Nationality,
    PreferredLanguage,
    PreferredContactMethod,
    WeightInKg,
  } = textsOfParams;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: 'Registration',
    eventLabel: textsOfParams.RegistrationFor ? textsOfParams.RegistrationFor.join('|') : '',
    gender: Gender,
    nationality: Nationality,
    countryOfResidence: CountryOfResidence,
    birthday: params.Birthdate,
    preferredLanguage: PreferredLanguage,
    preferredContact: PreferredContactMethod,
    height: HeightInCm,
    weight: WeightInKg,
    bmi: params.BodyMassIndex,
    activityFrequency: ExerciseFrequency,
    activityIntensity: IntensityOfActivity,
    interests: textsOfParams.Interests ? textsOfParams.Interests.join('|') : '',
    infoSource,
    favoutiteSport: params.FavouriteSport,
    myPassId: getYasIdGTMData(),
  });
};
export const preregistrationAnalytics = (params, textsOfParams) => {
  const { CountryOfResidence, Nationality, Gender, PreferredContactMethod } = textsOfParams;
  const eventLabel = params.FormName;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: params.AnalyticsEventAction || 'Pre registration',
    eventLabel,
    nationality: Nationality,
    gender: Gender,
    countryOfResidence: CountryOfResidence,
    preferredContact: PreferredContactMethod,
    myPassId: getYasIdGTMData(),
  });
};

export const getAnalyticsData = ({
  eventTitle,
  eventName,
  eventId,
  dateOfEvent,
  timeSlot,
  performanceId,
  isYAPaymentPageLoad,
  category,
  date,
  openTime,
  closeTime,
  currency,
  language,
  productsArr,
}) => {
  let adultTicket;
  let juniorTicket;
  let groupTicket;
  let isYaAddOn;

  if (productsArr && Array.isArray(productsArr) && productsArr.length) {
    adultTicket =
      productsArr.find(
        (product) => !isAddonProduct(product) && (product.classType === 'ADULT' || product.classType === 'AD'),
      ) || {};

    juniorTicket =
      productsArr.find(
        (product) => !isAddonProduct(product) && (product.classType === 'JUNIOR' || product.classType === 'CH'),
      ) || {};

    groupTicket = productsArr.find((product) => !isAddonProduct(product) && product.classType === 'GP') || {};

    isYaAddOn = isAddonProduct(productsArr[0]);
  }

  let name = eventTitle || eventName;
  const eventIdData = Array.isArray(eventId) ? eventId[0] : eventId;
  GTMData.updatEventId(eventIdData);

  return {
    id: eventIdData,
    name: Array.isArray(name) ? name[0] : name,
    dimension8: dateOfEvent || date,
    dimension10:
      timeSlot || (openTime && closeTime && `${getFormattedTime(openTime)} - ${getFormattedTime(closeTime)}`),
    dimension11: performanceId,
    dimension15: isYaAddOn ? 'AddOn' : 'Ticket',
    metric1: parseFloat(adultTicket && adultTicket.quantity) || 0,
    metric2: parseFloat(juniorTicket && juniorTicket.quantity) || 0,
    metric3: parseFloat(groupTicket && groupTicket.quantity) || 0,
    currency,
    isYAPaymentPageLoad,
    category,
    language,
  };
};

export const getFWBookingConfirmationAnalytics = (gtmData, keyList, isGtm4) => {
  if (isGtm4) {
    const keyMapObj = (item) => {
      const newObj = {};
      Object.keys(item).forEach((key) => {
        if (keyList[key]) {
          newObj[keyList[key]] = item[key];
        } else {
          newObj[key] = item[key];
        }
      });
      return newObj;
    };
    const newObjArr = [];
    if (!Array.isArray(gtmData)) {
      return keyMapObj(gtmData);
    } else {
      gtmData.forEach((item) => {
        newObjArr.push(keyMapObj(item));
      });
      return newObjArr;
    }
  } else {
    return gtmData;
  }
};

export const getYABookingConfirmationAnalytics = (products, gtmData, orderItems) => {
  const tenantId = getLoggedInUser().tenantID;

  const updatedProducts = products.map((product) => {
    const matchedOrderItem = orderItems.find((d) => d.productId === product.productId);
    const matchedGTMData = gtmData.find((d) => d.productId === product.productId);

    const productObj = getProdDetailsFromOrderDetails(product, matchedOrderItem);

    return {
      ...product,
      productIdType: matchedOrderItem.productIdType || getProdIdType(productObj, !!product.crossSellType),
      price: matchedOrderItem.price,
      dateOfEvent: matchedGTMData.dateType,
      timeSlot: matchedGTMData.dimension10,
      performanceId: matchedGTMData.dimension11,
      quantity: Number(matchedGTMData.quantity),
    };
  });

  const cartAction = new CartActions({ moduleName: 'BookingConfirmation', tenantId });
  const groupedProducts = cartAction.groupCartProducts(updatedProducts);

  return Object.keys(groupedProducts).map((key) => {
    const calculatedGroupedQuantity = groupedProducts[key].products.reduce(
      (sum, { quantity }) => sum + Number(quantity),
      0,
    );

    const yaAnalyticsInfo = getAnalyticsData({
      ...groupedProducts[key].products[0],
      productsArr: groupedProducts[key].products,
    });
    GTMData.updatEventId(yaAnalyticsInfo.id, true);
    return {
      price: parseFloat(groupedProducts[key].gross),
      quantity: parseFloat(groupedProducts[key].products[0].groupedQuantity) || parseFloat(calculatedGroupedQuantity),
      variant: groupedProducts[key].products[0].ticketType,
      ...yaAnalyticsInfo,
    };
  });
};

export const yaBuyTicketAnalytics = (analyticsParams) => {
  const {
    name,
    id,
    dimension8,
    dimension10,
    dimension11,
    dimension15,
    metric1,
    metric2,
    metric3,
    currency,
  } = getAnalyticsData(analyticsParams);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'addToCart',
    myPassId: getYasIdGTMData(),
    emailId: getEmailIdGTMData(),
    ecommerce: {
      currencyCode: currency,
      add: {
        products: [
          {
            name,
            id,
            dimension8,
            dimension10,
            dimension11,
            dimension15,
            metric1,
            metric2,
            metric3,
          },
        ],
      },
    },
  });
};

export const compareTicketsAnalytics = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'dataTrack',
    eventCategory: 'CTAs',
    eventAction: 'Compare Ticket Features',
    eventLabel: 'ticket category features',
    myPassId: getYasIdGTMData(),
  });
};

export const yaRemoveItemAnalytics = ({ products, timeSlot, date }) => {
  const addedProducts = [];
  let lang = '';
  let curr;
  const grouppedProducts = Array.isArray(products) ? { prod: { products } } : products;
  Object.keys(grouppedProducts).forEach((key) => {
    const data = grouppedProducts[key].products;
    let {
      name,
      id,
      dimension8,
      dimension10,
      dimension11,
      dimension15,
      metric1,
      metric2,
      metric3,
      currency,
      language,
    } = getAnalyticsData({ ...data[0], productsArr: data });
    lang = (!lang && language) || lang;
    curr = (!curr && currency) || curr;
    if (dimension15 === 'Ticket' && !dimension10 && timeSlot) {
      dimension10 = timeSlot;
    }
    if (dimension15 === 'Ticket' && !dimension8 && date) {
      dimension8 = date;
    }

    addedProducts.push({
      name,
      id,
      dimension8,
      dimension10,
      dimension11,
      dimension15,
      metric1,
      metric2,
      metric3,
    });
  });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'removeFromCart',
    language: lang,
    myPassId: getYasIdGTMData(),
    emailId: getEmailIdGTMData(),
    ecommerce: {
      currencyCode: curr,
      remove: {
        products: addedProducts,
      },
    },
  });
};

export function setLoginGTM(EventLabel = 'Login/Signup from Payment Page') {
  window.dataLayer = window.dataLayer || [];

  const mainObj = getMainObject();
  let { tenantID } = mainObj;
  let language = document.getElementsByTagName('html')[0].getAttribute('lang');
  tenantID = tenantID.replace('B2C', '');
  if (tenantID === 'FW') tenantID = 'FWAD';
  if (tenantID === 'PP') tenantID = 'PPAD';
  language = language ? language.split('-')[0] : '';

  const referrer = tenantID.toLowerCase() + ':' + language + ':home';
  const pageName = tenantID.toLowerCase() + ':' + language + ':login';
  const eventSlug = EventLabel && EventLabel.replace(/\s+/g, '-');

  window.dataLayer.push({
    EventLabel,
    myPassId: getYasIdGTMData(),
    event: eventSlug,
    EventCategory: 'Ecommerce',
    EventAction: 'Login/Signup',
    siteType: 'B2C',
    pageType: 'IAM',
    tenant: tenantID,
    language: language,
    primarySection: 'login page',
    secondarySection: '',
    referrer: referrer,
    pageName: pageName,
    subTenant: '',
  });
}

export const gaMainData = () => {
  // Add to cart GA
  const container = {};
  let daycationContainer;
  const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
  const cartSummaryObj = JSON.parse(getSessionStorage('cartSummaryForConfirmation'));
  const mainCategory = getSessionStorage('GA_MainCategory');
  if (resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C) {
    let GAData = getSessionStorage('PackageDetailGA');
    if (GAData) GAData = JSON.parse(GAData);
    const productItem = GAData?.ecommerce?.click?.products[0];

    const experienceProductItem = cartSummaryObj?.Cart?.ProductGroups[0]?.Products;

    const GARoomsData = getSessionStorage('roomsData').toString() || '';
    const GAMealPlanData = getSessionStorage('mealPlanData') || '';

    let specialNameRequests = getSessionStorage('specialNameRequests') || '';
    if (specialNameRequests) specialNameRequests = JSON.parse(specialNameRequests) || '';
    const gaMainCategory = getSessionStorage('GA_MainCategory') || getMainStayCategory(parseQueryString('ctype'));

    const daycationCategory = getSessionStorage('GA_DaycationCategory');

    if (mainCategory === UIConfig.b2c.flowType.longName.daycation) {
      daycationContainer = experienceProductItem?.map((specialItem, index) => {
        let containerItem = {};
        const specialName = specialItem?.GA_ContentNode?.Fields?.filter((fObj) => fObj.name === 'Name').map(
          (item) => item.value,
        );
        const specialId = specialItem?.GA_ContentNode?.Fields?.filter((fObj) => fObj.name === 'ContractId').map(
          (item) => item.value,
        );
        const bundleProductName = cartSummaryObj?.Cart?.GA_ProductBundleName;
        let bundleCategory;
        if (specialName[0] !== bundleProductName && bundleProductName !== '' && bundleProductName !== undefined) {
          bundleCategory = `${daycationCategory}/${bundleProductName}`;
        } else {
          bundleCategory = daycationCategory;
        }
        containerItem['name'] = specialName[0] || '';
        containerItem['id'] = specialId[0] || '';
        containerItem['price'] = specialItem.PriceInAED;
        containerItem['category'] = bundleCategory || '';
        containerItem['quantity'] = 1;
        containerItem['journeyType'] = gaMainCategory?.toLowerCase() || '';
        containerItem['metric4'] = specialItem.Venue.NoOfAdults || 0;
        containerItem['metric5'] = specialItem.Venue.NoOfChilds || 0;
        containerItem['dimension6'] = `${specialItem.Venue.NoOfAdults}-${specialItem.Venue.NoOfChilds}-0-0`;
        containerItem['dimension17'] = isLoggedInUser()
          ? UIConfig.b2c.addonsMessage.loggedInUserMsg
          : UIConfig.b2c.addonsMessage.withoutLoggedInUserMsg;
        containerItem['dimension8'] = `${specialItem.Venue.VenueDate?.date || 0}-${specialItem.Venue.VenueDate?.month ||
          0}-${specialItem.Venue.VenueDate?.year || 0}`;

        return containerItem;
      });
    } else if (productItem) {
      container['name'] = productItem?.name || '';
      container['id'] = productItem?.id || '';
      container['packageType'] = productItem?.packageType || '';
      container['journeyType'] = gaMainCategory?.toLowerCase() || '';
      container['category'] = productItem?.category.replace(/[^a-zA-Z &]/g, '') || '';
      container['price'] = productItem?.price || '';
      container['quantity'] = 1;

      container['roomSelected'] = GARoomsData ? GARoomsData.replace(/[^.,a-zA-Z –]/g, '') : '';
      container['mealPlans'] = GAMealPlanData?.replace(/[^a-zA-Z ]/g, '') || '';
      container['specialRequests'] = specialNameRequests ? specialNameRequests.toString() : '';

      container['checkindate'] = productItem?.checkindate || '';
      container['checkoutdate'] = productItem?.checkoutdate || '';
      container['numberOfNights'] = productItem?.numberOfNights || '';
      container['numberOfRooms'] = productItem?.numberOfRooms || '';
      container['roomNights'] = productItem?.roomNights || '';
      container['numberOfTickets'] = productItem?.numberOfTickets || '';
      container['numberOfAdults'] = productItem?.numberOfAdults || 0;
      container['numberOfChildren'] = productItem?.numberOfChildren || 0;
      container['paxCount'] = productItem?.paxCount || '';
      container['eventName'] = productItem?.eventName || '';
      container['eventDate'] = productItem?.eventDate || '';
      container['eventShowTime'] = productItem?.eventShowTime || '';
      container['eventTicketType'] = productItem?.eventTicketType || '';
      container['eventTicketZone'] = productItem?.eventTicketZone || '';
      container['eventPaxCount'] = productItem?.eventPaxCount || '';
      container['eventSeat'] = productItem?.eventSeat || '';
      container['dimension17'] = isLoggedInUser()
        ? UIConfig.b2c.addonsMessage.loggedInUserMsg
        : UIConfig.b2c.addonsMessage.withoutLoggedInUserMsg;
    }
  }

  return mainCategory === UIConfig.b2c.flowType.longName.daycation ? daycationContainer : container;
};

export const checkoutAnalytics = (currency, event, paymentType) => {
  window.dataLayer = window.dataLayer || [];
  const userInfo = getLoggedInUser();
  let isUserLoggedIn = !!(userInfo && userInfo.idToken);
  const gaMainObject = gaMainData();
  const mainCategory = getSessionStorage('GA_MainCategory');
  let cartData = getSessionStorage('cartSummaryForConfirmation');
  if (cartData) cartData = JSON.parse(cartData);
  let addonsData = getSessionStorage('GA_AddonsData');
  if (addonsData && addonsData !== 'undefined') addonsData = JSON.parse(addonsData);
  let dataItem = {};

  if (mainCategory === UIConfig.b2c.flowType.longName.daycation) {
    setSessionStorage('GA_DaycationCartItem', JSON.stringify([gaMainObject]));
  } else {
    setSessionStorage('GA_DaycationCartItem', '');
  }

  if (event === 'checkoutLoad') {
    if (isUserLoggedIn) {
      if (addonsData && addonsData !== 'undefined') {
        dataItem = {
          event: 'e_checkout',
          ecommerce: {
            currencyCode: UIConfig.currencyArabic || '',
            subTotal: cartData?.Cart.Totals?.TotalAmountInAED || '',
            checkout: {
              actionField: {
                step: 2,
                option: 'mypass',
              },
              products: [gaMainObject, ...addonsData],
            },
          },
        };
      } else {
        dataItem = {
          event: 'e_checkout',
          ecommerce: {
            currencyCode: UIConfig.currencyArabic || '',
            subTotal: cartData?.Cart.Totals?.TotalAmountInAED || '',
            checkout: {
              actionField: {
                step: 2,
                option: 'mypass',
              },
              products: mainCategory === UIConfig.b2c.flowType.longName.daycation ? gaMainObject : [gaMainObject],
            },
          },
        };
      }
    } else {
      if (addonsData && addonsData !== 'undefined') {
        dataItem = {
          event: 'e_checkout',
          ecommerce: {
            currencyCode: UIConfig.currencyArabic || '',
            subTotal: cartData?.Cart.Totals?.TotalAmountInAED || '',
            checkout: {
              actionField: {
                step: 2,
                option: 'guest',
              },
              products: [gaMainObject, ...addonsData],
            },
          },
        };
      } else {
        dataItem = {
          event: 'e_checkout',
          ecommerce: {
            currencyCode: UIConfig.currencyArabic || '',
            subTotal: cartData?.Cart.Totals?.TotalAmountInAED || '',
            checkout: {
              actionField: {
                step: 2,
                option: 'guest',
              },
              products: mainCategory === UIConfig.b2c.flowType.longName.daycation ? gaMainObject : [gaMainObject],
            },
          },
        };
      }
    }
    window.dataLayer.push(dataItem);
  } else if (event === 'packageDetails') {
    dataItem = {
      event: 'e_addToCart',
      ecommerce: {
        currencyCode: currency,
        add: {
          products: mainCategory === UIConfig.b2c.flowType.longName.daycation ? gaMainObject : [gaMainObject],
        },
      },
    };
    window.dataLayer.push(dataItem);
  } else {
    if (addonsData && addonsData !== 'undefined') {
      if (event === 'payment') {
        window.dataLayer.push({
          event: 'e_checkout',
          ecommerce: {
            currencyCode: UIConfig.currencyArabic || '',
            subTotal: cartData?.Cart.Totals?.TotalAmountInAED || '',
            checkout: {
              actionField: {
                step: 3,
                option: paymentType,
              },
              products: [gaMainObject, ...addonsData],
            },
          },
        });
      }
    } else {
      if (event === 'payment') {
        window.dataLayer.push({
          event: 'e_checkout',
          ecommerce: {
            currencyCode: UIConfig.currencyArabic || '',
            subTotal: cartData?.Cart.Totals?.TotalAmountInAED || '',
            checkout: {
              actionField: {
                step: 3,
                option: paymentType,
              },
              products: mainCategory === UIConfig.b2c.flowType.longName.daycation ? gaMainObject : [gaMainObject],
            },
          },
        });
      }
    }
  }
};

export const cartAddonGA = (requestObject, action, price) => {
  let container = {};
  let dataItem;

  container['category'] = 'Addons';
  container['quantity'] = 1;
  container['dimension17'] = isLoggedInUser()
    ? UIConfig.b2c.addonsMessage.loggedInUserMsg
    : UIConfig.b2c.addonsMessage.withoutLoggedInUserMsg;
  if (requestObject?.reqObj?.Date) {
    container['dimension8'] = requestObject.reqObj.Date;
  } else {
    container['dimension8'] = '';
  }

  window.dataLayer = window.dataLayer || [];
  if (action === 'removeFromCart') {
    const addonName = requestObject.GA_ContentNode.Fields.filter((fObj) => fObj.name === 'Name').map(
      (item) => item.value,
    );

    container['name'] = addonName[0] || '';
    container['id'] = requestObject.ContractId;
    container['price'] = requestObject.PriceInAED;
    container['metric4'] = requestObject.Venue.NoOfAdult || '';
    container['metric5'] = requestObject.Venue.NoOfChild || '';
    container['dimension6'] = `${requestObject.Venue.NoOfAdult || '0'}-${requestObject.Venue.NoOfChild || '0'}-0-0`;
    dataItem = {
      event: 'e_removeFromCart',
      ecommerce: {
        currencyCode: UIConfig.currencyArabic || '',
        remove: {
          products: [container],
        },
      },
    };
  } else {
    container['name'] = requestObject.name || '';
    container['id'] = requestObject.reqObj.productsInPackage[0].ContractId;
    container['price'] = price;
    container['metric4'] = requestObject.reqObj.NoOfAdult;
    container['metric5'] = requestObject.reqObj.NoOfChild;
    container['dimension6'] = `${requestObject.reqObj.NoOfAdult}-${requestObject.reqObj.NoOfChild}-0-0`;
    dataItem = {
      event: 'e_addToCart',
      ecommerce: {
        currencyCode: requestObject.reqObj.currency,
        add: {
          products: [container],
        },
      },
    };
  }

  window.dataLayer.push(dataItem);
};

export const bodyClicks = (actionName, sectionName, itemName) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'e_pagebodyinteraction',
    category: 'pagebody interaction',
    action: actionName,
    label: `${sectionName} - ${itemName}`,
  });
};
export const defaultGoogleAnalyticYas = (getLanguage, isLoggedIn) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    siteType: 'B2C',
    language: getLanguage,
    user: {
      userRole: `${isLoggedIn ? 'Logged in' : 'Guest'}`,
      loginStatus: `${isLoggedIn ? 'Yes' : 'No'}`,
      emailId: getEmailIdGTMData(),
      myPassId: getYasIdGTMData(),
    },
  });
};

export const languageToggleGA = (currentLanguage, selectedLanguage) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'e_headerinteraction',
    category: 'header interaction',
    action: 'language toggle',
    label: `${currentLanguage} | ${selectedLanguage}`,
  });
};
