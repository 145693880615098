/* eslint-disable no-undef */
import {
  getTenantId,
  getProdIdType,
  deepCloneObject,
  getLoginUser,
  isLoggedInUser,
  getCurrentLanguage,
  getYasIdGTMData,
  getEmailIdGTMData,
  detectViewPort,
  iOSDevice,
  dataLayerCheckoutConvert,
  parseQueryString,
  getLoggedInUser,
  getSessionStorage,
  getMainStayCategory,
  isMatchTenant,
  setSessionStorage,
  setLocalStorage,
  getLocalStorageByKey,
  checkParksTenants,
  getAbsoluteInt,
  decryptParam,
  checkIfParks,
  getLanguageWithoutLocal,
  getUserTypeForGa4,
  getGa4Category,
  checkGA4Tenants,
  getAnonymousCart,
  getCurrency,
  updateGA4LocalStorage,
  getProductIdx,
} from '../../../common/utility';
import CartActions from './cart/cart-actions';
import UIConfig from '../../../common/UIConfig';
import { getAnalyticsData, getFWBookingConfirmationAnalytics } from '../../../common/analytics-events';
import { checkTenant } from '../../../common/utility/tenantsUtils';
import moment from 'moment';

const deviceDetection = () => {
  if (detectViewPort() === UIConfig.viewportTypes.mobile) {
    if (iOSDevice()) {
      return 'iOS';
    } else {
      return 'Android';
    }
  }
  return null;
};

class GTMData {
  static getKeyList = (isGtm4) => {
    if (isGtm4) {
      return {
        name: 'item_name',
        products: 'items',
        category: 'item_category',
        id: 'item_id',
        dateOfVisit: 'date_of_visit',
        dateType: 'date_type',
        item: 'item_name',
        nosAddonGuest: 'number_of_addon_guests',
        nosadult: 'number_of_adults',
        nosjunior: 'number_of_juniors',
        noschild: 'number_of_children',
        currencyCode: 'currency',
        impressions: 'items',
        addToCart: 'add_to_cart',
        removeCart: 'remove_from_cart',
        paymentType: 'payment_type',
        transactionId: 'transaction_id',
        viewCart: 'view_cart',
        beginCheckout: 'begin_checkout',
        viewItemList: 'view_item_list',
        viewItem: 'view_item',
        selectItem: 'select_item',
        itemListId: 'item_list_id',
        itemListName: 'item_list_name',
        discountValue: 'discount_value',
        itemVariant: 'item_variant',
        itemTimeSlot: 'item_timeSlot',
        affiliation: 'affiliation',
        checkinDate: 'checkin_date',
        checkoutDate: 'checkout_date',
        discount: 'discount',
        eventDate: 'event_date',
        eventName: 'event_name',
        eventNumberOfTickets: 'event_number_of_tickets',
        eventSeat: 'event_seat',
        eventShowTime: 'event_show_time',
        eventTicketType: 'event_ticket_type',
        eventTicketZone: 'event_ticket_zone',
        itemCategory: 'item_category',
        itemCategory2: 'item_category2',
        itemCategory3: 'item_category3',
        itemCategory4: 'item_category4',
        itemId: 'item_id',
        itemJourneyType: 'item_journey_type',
        itemlistId: 'item_list_id',
        itemName: 'item_name',
        mealPlan: 'meal_plan',
        numberOfNights: 'number_of_nights',
        numberOfRooms: 'number_of_rooms',
        roomSelected: 'room_selected',
        specialRequests: 'special_requests',
        totalPaxCount: 'total_pax_count',
        price: 'price',
        quantity: 'quantity',
        journeyType: 'journey_type',
        coupon: 'coupon',
        tax: 'tax',
        value: 'value',
        clickFooter: 'click_footer',
        menuCategory: 'menu_category',
        menuCategory2: 'menu_category2',
        widgetSearch: 'widget_search',
        widgetSearchUpdate: 'widget_search_update',
        subscribe: 'subscribe',
        purchase: 'purchase',
        refund: 'refund',
        items: 'items',
        progressFlow: 'progress_flow',
        startFlow: 'start_flow',
        clickHeader: 'click_header',
        login: 'login',
        timeSlot: 'experience_time',
        addPaymentInfo: 'add_payment_info',
        clickTab: 'click_tab',
        clickAccordian: 'click_accordian',
        clickCta: 'click_cta',
        clickLink: 'click_link',
        formComplete: 'form_complete',
        headerNavigation: 'header_navigation',
        websiteSearch: 'website_search',
        websiteSearch: 'website_search',
        partnerLinks: 'partner_links',
        newsletterSubscribe: 'newsletter_subscribe',
        socialLinks: 'social_links',
        buyTickets: 'buy_tickets',
        removeFromCart: 'remove_from_cart',
        addToCartAdons: 'add_to_cart',
        supplierRegistration: 'supplier_registration',
        addShippingInfo: 'add_shipping_info',
      };
    } else {
      return {
        name: 'name',
        products: 'products',
        category: 'category',
        id: 'id',
        dateOfVisit: 'dateOfVisit',
        dateType: 'dateType',
        item: 'item',
        nosAddonGuest: 'nosAddonGuest',
        nosadult: 'nosadult',
        nosjunior: 'nosjunior',
        noschild: 'noschild',
        currencyCode: 'currencyCode',
        impressions: 'impressions',
        timeSlot: 'timeSlot',
      };
    }
  };
  static _setProductsData = (
    products,
    groupProducts = true,
    tenantId,
    addedFrom,
    isFromCart,
    isJunior,
    isFromCartDelete,
    isGtm4,
  ) => {
    const addedProducts = [];

    const grouppedProducts = groupProducts ? this.cartAction.groupCartProducts(products) : products;
    const keyList = GTMData.getKeyList(isGtm4);
    Object.keys(grouppedProducts).forEach((key) => {
      const adultTicket =
        grouppedProducts[key].products.find((product) => product.classType === 'ADULT' || product.classType === 'AD') ||
        {};
      const juniorTicket =
        grouppedProducts[key].products.find(
          (product) => product.classType === 'JUNIOR' || product.classType === 'CH',
        ) || {};
      const addonTicket =
        grouppedProducts[key].products.find((product) => product.experienceCatgory === 'AddonGuest') || {};
      const calculatedGroupedQuantity = grouppedProducts[key].products.reduce(
        (sum, { quantity }) => sum + Number(quantity),
        0,
      );
      const getSWADAdultQuantity = (isFromCart, isJunior, quantity) => {
        if (isFromCart) {
          if (!isJunior && parseFloat(quantity)) {
            return 1;
          }
          return 0;
        } else {
          return parseFloat(quantity) || 0;
        }
      };
      const getSWADJuniorQuantity = (isFromCart, isJunior, quantity) => {
        if (isFromCart) {
          if (isJunior && parseFloat(quantity)) {
            return 1;
          }
          return 0;
        } else {
          return parseFloat(quantity) || 0;
        }
      };

      const getSWADTicketQuantity = (productInfo, isFromCart, isJunior, isFromCartDelete) => {
        if (isFromCart) {
          return 1;
        } else if (isFromCartDelete) {
          if (isJunior) {
            return juniorTicket?.quantity;
          } else {
            return adultTicket?.quantity;
          }
        } else {
          return parseFloat(productInfo?.groupedQuantity) || parseFloat(calculatedGroupedQuantity);
        }
      };

      let name = grouppedProducts[key].products[0].productName;

      let yaAnalyticsInfo;
      if (this.tenantId === UIConfig.yasArenaB2CTenant.toLocaleLowerCase()) {
        const {
          name,
          id,
          dimension8,
          dimension10,
          dimension11,
          dimension15,
          metric1,
          metric2,
          metric3,
          category,
        } = getAnalyticsData({ ...grouppedProducts[key].products[0], productsArr: grouppedProducts[key].products });

        yaAnalyticsInfo = {
          name,
          id,
          dimension8,
          dimension10,
          dimension11,
          dimension15,
          category,
          metric1,
          metric2,
          metric3,
        };
      }

      if (tenantId === UIConfig.ymcB2CTenant) {
        let addons = '';
        const product = grouppedProducts[key].products[0];
        let subCategory = product.tab || product.productType;
        if (Array.isArray(subCategory) && subCategory.length) {
          subCategory = subCategory[0];
        }
        if (subCategory && subCategory.toLowerCase() === UIConfig.b2c.purchaseJourney.recommendationTabCode) {
          addons = product.category ? product.category[0] : '';
        }
        const category = GTMData.getCategoryNameFromEventType(product.eventType);
        addedProducts.push({
          name: product.productName,
          id: product.productId,
          price: parseFloat(grouppedProducts[key].gross),
          quantity: parseFloat(product.groupedQuantity) || parseFloat(calculatedGroupedQuantity),
          category: `${category} / ${subCategory}`,
          addons: addons,
          date: product.fromDate,
        });
      } else {
        //take primary product//
        const drivingExpPrimaryProd =
          grouppedProducts[key].products &&
          grouppedProducts[key].products.length > 0 &&
          grouppedProducts[key].products.filter((prd) => prd.experienceCatgory !== 'AddonGuest');
        const productInfo =
          drivingExpPrimaryProd && drivingExpPrimaryProd.length > 0
            ? drivingExpPrimaryProd[0]
            : grouppedProducts[key].products[0];
        const { loginStatus } = GTMData.getUserData();
        if (tenantId === UIConfig.SWADB2C) {
          let addedPRoductData = {
            id: productInfo.productId,
            name: productInfo.gaTitle || productInfo.productName || name,
            category: productInfo.tab || productInfo.productType,
            nosadult: getSWADAdultQuantity(isFromCart, isJunior, adultTicket?.quantity),
            nosjunior: getSWADJuniorQuantity(isFromCart, isJunior, juniorTicket?.quantity),
            nosAddonGuest: parseFloat(addonTicket.quantity) || 0,
            dateType: productInfo.fromDate && !productInfo.isFlexible ? 'Dated' : 'Flexible',
            dateOfVisit: productInfo.fromDate || undefined,
            price: parseFloat(grouppedProducts[key].gross),
            quantity: getSWADTicketQuantity(productInfo, isFromCart, isJunior, isFromCartDelete),
            subtotalCurrency: productInfo.currency,
            ...yaAnalyticsInfo,
            metric1: getSWADAdultQuantity(isFromCart, isJunior, adultTicket?.quantity),
            metric2: getSWADJuniorQuantity(isFromCart, isJunior, juniorTicket?.quantity),
            metric3: parseFloat(addonTicket.quantity) || 0,
            affiliation: 'Online Store',
            dimension11: productInfo?.ticketVal?.toUpperCase() === '1DAY' ? 'Dated' : 'Flexible',
            dimension6: productInfo.fromDate || undefined,
            dimension2: `${loginStatus ? 'Yes' : 'No'}`,
            dimension5: productInfo.tab || productInfo.productType,
            dimension7: productInfo.addedFromType || addedFrom || UIConfig.regularText,
          };
          if (parseQueryString('status') === 'true') {
            addedPRoductData.dimension8 = 'Successful';
            if (parseQueryString('ref') || window.location?.hash?.split('#')[1]) {
              addedPRoductData.dimension9 = parseQueryString('ref') || window.location?.hash?.split('#')[1];
            }
          } else if (parseQueryString('status') === 'false') {
            addedPRoductData.dimension8 = 'Failed';
            if (parseQueryString('ref') || window.location?.hash?.split('#')[1]) {
              addedPRoductData.dimension9 = parseQueryString('ref') || window.location?.hash?.split('#')[1];
            }
          }
          if (parseQueryString('payment_error')) {
            addedPRoductData.dimension8 = 'Failed';
            if (parseQueryString('ref') || window.location?.hash?.split('#')[1]) {
              addedPRoductData.dimension9 = parseQueryString('ref') || window.location?.hash?.split('#')[1];
            }
            addedPRoductData.dimension12 = parseQueryString('payment_error');
          }
          addedProducts.push(addedPRoductData);
        } else {
          addedProducts.push({
            [keyList.id]: productInfo.productId,
            [keyList.name]: productInfo.gaTitle || productInfo.productName || name,
            [keyList.category]: productInfo.tab || productInfo.productType,
            [keyList.nosadult]: parseFloat(adultTicket.quantity) || 0,
            [keyList.nosjunior]: parseFloat(juniorTicket.quantity) || 0,
            [keyList.nosAddonGuest]: parseFloat(addonTicket.quantity) || 0,
            [keyList.dateType]: productInfo.fromDate && !productInfo.isFlexible ? 'Dated' : 'Flexible',
            [keyList.dateOfVisit]: productInfo.fromDate || undefined,
            price: parseFloat(grouppedProducts[key].gross),
            quantity: parseFloat(productInfo.groupedQuantity) || parseFloat(calculatedGroupedQuantity),
            subtotalCurrency: productInfo.currency,
            ...yaAnalyticsInfo,
          });
        }
      }
    });

    return addedProducts;
  };

  static getWalletData = (product) => {
    return {
      name: product.productName,
      mediaId: product.mediaId,
      productId: product.productAK,
      activeFrom: product.activeFrom,
      activeTo: product.activeTo,
      ticketId: product.ticketId,
    };
  };

  static pageViewData = () => {
    this.data.pageURL = document.location.href;
  };

  static productDetailsData = ({ products }, isGtm4) => {
    this.data.event = isGtm4 ? 'view_item' : 'productDetails';
    const keyList = GTMData.getKeyList(isGtm4);

    const productData = products.map((product) => {
      const { eventId, eventName, productName, tags, tab } = product;

      const result = {
        name: productName,
        category: tab,
      };

      if (this.tenantId === UIConfig.yasArenaB2CTenant.toLocaleLowerCase()) {
        result.id = Array.isArray(eventId) ? (eventId.length ? eventId[0] : '') : eventId;
        result.name = Array.isArray(eventName) ? (eventName.length ? eventName[0] : '') : eventName;
        result.category = tags.map((tag) => tag.key).join(',');
      }
      return result;
    });

    this.data.ecommerce = { detail: { [keyList.products]: productData } };
  };

  static productImpressionsData = ({ products, isEventCards }, isGtm4) => {
    this.data.event = isGtm4 ? 'view_item_list' : 'productImpressions';
    const { language, loginStatus, userId } = GTMData.getUserData();
    const keyList = GTMData.getKeyList(isGtm4);

    if (isEventCards) {
      const productData = products.map((product, index) => {
        return {
          name: product?.title || '',
          id: product?.productId || '',
          price: product?.price[0]?.price || 0,
          category: product?.productCategory || '',
          list: product?.productCategory || '',
          position: index + 1,
          dimension2: `${loginStatus ? 'Yes' : 'No'}`,
          dimension5: product?.productCategory || '',
          dimension7: product.addedFromType || UIConfig.regularText,
        };
      });
      this.data.type = 'ticket';
      this.data.ecommerce = {
        [keyList.impressions]: productData,
        language,
        loginStatus,
        userId,
        currencyCode: UIConfig.currencyArabic,
      };
    } else {
      const productData = products.tabList[0].listing.map((product, index) => {
        let productPrice = '';
        if (typeof product.currentprice === 'string') {
          productPrice = product.currentprice.replace(/,/g, '');
        }
        const category = GTMData.getCategoryNameFromEventType(product.productEventType);
        const subCategory =
          Array.isArray(product.categoryKey) && product.categoryKey.length
            ? product.categoryKey[0]
            : product.categoryKey;
        return {
          [keyList.name]: product.title,
          [keyList.id]: product.productid,
          price: parseInt(productPrice, 10),
          [keyList.category]: `${category}/${subCategory}`,
          list: `${category}/${subCategory}`,
          position: index + 1,
          list: GTMData.getCategoryNameFromEventType(product.productEventType),
        };
      });
      this.data.type = 'ticket';
      this.data.ecommerce = {
        [keyList.impressions]: productData,
        language,
        loginStatus,
        userId,
        [keyList.currencyCode]: UIConfig.currencyArabic,
      };
    }
  };

  static productClickData = ({ products }, isGtm4) => {
    this.data.event = isGtm4 ? 'select_item' : 'productClicks';
    const { language, loginStatus, userId } = GTMData.getUserData();
    const keyList = GTMData.getKeyList(isGtm4);
    if (typeof products.currentprice === 'string') {
      products.currentprice = products.currentprice.replace(/,/g, '');
    }
    let productData = {
      [keyList.name]: products.title,
      [keyList.id]: products.productid,
      price: parseInt(products.currentprice, 10),
    };
    if (this.tenantId === UIConfig.ymcB2CTenant) {
      const category = GTMData.getCategoryNameFromEventType(products.productEventType);
      const subCategory = products.categoryKey ? products.categoryKey[0] : '';
      const ymcCategory = `${category}/${subCategory}`;
      productData = { ...productData, category: ymcCategory };
    } else {
      productData = {
        ...productData,
        [keyList.category]: GTMData.getCategoryNameFromEventType(products.productEventType),
        subCategory: products.categoryKey ? products.categoryKey[0] : '',
      };
    }
    this.data.ecommerce = {
      click: {
        [keyList.products]: [productData],
        actionField: {
          list: GTMData.getCategoryNameFromEventType(products.productEventType) + '-' + products.categoryKey[0],
        },
        language,
        loginStatus,
        userId,
      },
      currencyCode: UIConfig.currencyArabic,
    };
  };

  static productDetailsLoadData = ({ productData }, isGtm4) => {
    this.data.event = isGtm4 ? 'view_item' : 'productDetails';
    const { language, loginStatus, userId } = GTMData.getUserData();

    this.data.ecommerce = {
      detail: { [keyList.products]: [productData], language, loginStatus, userId },
      currencyCode: UIConfig.currencyArabic,
    };
  };

  static getPromoIfAvail = (prdPromo = [], promos = [], quantity) => {
    let disAmount = 0,
      disCode = '',
      disQty = null,
      unit = '';

    if (prdPromo?.length) {
      const promotion = prdPromo[0];
      const isValid =
        promos?.length && promos.find((prm) => (prm.type === '2' || prm.type === '4') && prm.code === promotion.code);
      if (isValid && isValid.type) {
        const { discount, code, OriginalPrice } = promotion;
        const totalDiscount = isValid.discount || 0;
        const discountedProductQty =
          JSON.parse(getAbsoluteInt(totalDiscount)) / JSON.parse(getAbsoluteInt(discount.unit));
        const originalTotalProductPrice = getAbsoluteInt(OriginalPrice.unit) * discountedProductQty;
        disAmount = totalDiscount;
        disCode = code;
        unit = originalTotalProductPrice - getAbsoluteInt(totalDiscount);
        disQty = JSON.parse(getAbsoluteInt(totalDiscount)) / JSON.parse(getAbsoluteInt(discount.unit));
      }
    }

    return {
      prodUnitPice: unit,
      amount: disAmount,
      code: disCode,
      qty: disQty,
    };
  };

  static getTotalPrice = (price, qty) => {
    return parseFloat(price) / qty;
  };

  static nonDiscountPrd = (remainQty, expType, classType, unitPrice) => {
    const keyList = GTMData.getKeyList(true);
    return {
      quantity: remainQty,
      discount: undefined,
      [keyList.nosAddonGuest]: expType === 'AddonGuest' ? remainQty : 0,
      [keyList.nosadult]:
        classType === 'ADULT' || expType === 'PRIMARY' || expType === 'Secondary' ? parseFloat(remainQty) : 0,
      [keyList.nosjunior]: classType === 'JUNIOR' ? parseFloat(remainQty) : 0,
      price: parseInt(unitPrice)?.toFixed(2),
    };
  };

  static parseGA4DataLayer = (products = [], promo = [], isAddRemoveCart = false) => {
    const keyList = GTMData.getKeyList(true);
    const { tenantName } = getLoggedInUser();
    let currencyCode = UIConfig.currencyArabic,
      itemList = [],
      totalCartValue = 0,
      couponCode = '',
      isPromoValid = false;
    const purchasedProd = getLocalStorageByKey('cartPurchaseProd') || [];
    const localCpn = localStorage.getItem('promoCode');
    products.forEach(
      (
        {
          fromDate,
          isFlexible,
          productId,
          productName,
          quantity,
          classType,
          gross,
          tab,
          currency,
          classTypeName,
          price,
          unitPrice,
          productType,
          timeSlot,
          experienceCatgory,
          ItemPromotionList,
          gaCategory,
          gaTitle,
          itemType,
          spinnerQty = false,
        },
        index,
      ) => {
        updateGA4LocalStorage({
          productId,
          itemType,
          classTypeName,
          gaTitle,
          gaCategory,
          experienceCatgory,
          classType,
          index: undefined,
          isParseEvent: true,
        });
        const purchdProd = purchasedProd.length && purchasedProd.find((prdId) => prdId.productId === productId);
        currencyCode = currency;

        const finalPrice = spinnerQty ? gross || unitPrice : price?.gross || gross || unitPrice;
        const { amount, code, qty, prodUnitPice } = this.getPromoIfAvail(ItemPromotionList, promo, quantity);
        if (!isPromoValid && amount) {
          isPromoValid = true;
        }
        const couponApplied = document.querySelector('.c-coupon-code-payment--applied .bold')?.textContent;
        if (couponApplied || code) {
          couponCode = couponApplied || code;
        }
        const itemPrice = this.getTotalPrice(qty ? prodUnitPice : finalPrice, parseFloat(qty || quantity));
        const totalItemPrice = itemPrice * parseFloat(isAddRemoveCart ? quantity : qty || quantity);
        totalCartValue += totalItemPrice;
        const isItemType = (isLoggedInUser() && purchdProd?.itemType) || itemType;
        const expType = experienceCatgory || purchdProd?.experienceCatgory || '';
        const gaData = {
          [keyList.affiliation]: tenantName,
          [keyList.dateOfVisit]: isItemType === 'ANP' ? undefined : fromDate || undefined,
          [keyList.dateType]: isFlexible || isItemType === 'ANP' || isItemType === 'VCH' ? 'flexible' : 'dated',
          discount: (qty && getAbsoluteInt(Number(amount)) / qty) || undefined,
          index: getProductIdx(productId),
          [keyList.category]: gaCategory || purchdProd?.gaCategory || undefined,
          [keyList.id]: productId,
          [keyList.name]: purchdProd?.gaTitle || gaTitle || undefined,
          [keyList.itemVariant]:
            (isLoggedInUser() && (classType || purchdProd?.classTypeName)) || classType || classTypeName || undefined,
          [keyList.nosAddonGuest]: expType === 'AddonGuest' ? qty || quantity : 0,
          [keyList.nosadult]:
            classType === 'ADULT' || expType === 'PRIMARY' || expType === 'Secondary' ? parseFloat(qty || quantity) : 0,
          [keyList.nosjunior]: classType === 'JUNIOR' ? parseFloat(qty || quantity) : 0,
          [keyList.timeSlot]: timeSlot || undefined,
          price: itemPrice.toFixed(2),
          quantity: parseFloat(isAddRemoveCart ? quantity : qty || quantity),
        };
        itemList.push(gaData);
        if (qty && quantity > qty) {
          const remainQty = quantity - qty;
          const totalRemaingingPrice = unitPrice * remainQty;
          totalCartValue += totalRemaingingPrice;
          itemList.push({ ...gaData, ...this.nonDiscountPrd(remainQty, expType, classType, unitPrice) });
        }
      },
    );
    return {
      item: itemList,
      ecommerce: {
        currency: currencyCode || UIConfig.currencyArabic,
        value: totalCartValue?.toFixed(2),
        [keyList.coupon]: isPromoValid ? (localCpn && decryptParam(localCpn)) || couponCode : undefined,
      },
    };
  };
  static addToCartData = ({ products = [], addedFrom, isFromCart, isJunior, promotions }, isGtm4) => {
    if (isGtm4) {
      const { item, ecommerce } = GTMData.parseGA4DataLayer(products, promotions, true);
      const keyList = GTMData.getKeyList(true);
      this.data.event = keyList.addToCart;
      if (addedFrom) {
        this.data.addedFrom = addedFrom;
      }
      this.data.ecommerce = {
        [keyList.products]: item,
        ...ecommerce,
      };
    } else {
      const { language, loginStatus, userId } = GTMData.getUserData();
      const productsData = GTMData._setProductsData(
        products,
        true,
        this.tenantId,
        addedFrom,
        isFromCart,
        isJunior,
        false,
        isGtm4,
      );

      const keyList = GTMData.getKeyList(isGtm4);
      this.data.event = 'addToCart';
      if (addedFrom) {
        this.data.addedFrom = addedFrom;
      }
      this.data.emailId = getEmailIdGTMData();
      this.data.ecommerce =
        this.tenantId === UIConfig.ymcB2CTenant
          ? {
              add: { [keyList.products]: productsData, language, loginStatus, userId },
              currencyCode: products[0].currency,
            }
          : { add: { [keyList.products]: productsData }, [keyList.currencyCode]: productsData[0].subtotalCurrency };
    }
  };

  static removeCartItemData = ({ products, isFromCart, isJunior, isFromCartDelete, multiProd, promotions }, isGtm4) => {
    let subCategory = products.tab || products.productType;
    let addons = '';
    const keyList = GTMData.getKeyList(isGtm4);
    if (Array.isArray(subCategory) && subCategory.length) {
      subCategory = subCategory[0];
    }
    if (
      subCategory &&
      subCategory.toLowerCase() === UIConfig.b2c.purchaseJourney.recommendationTabCode &&
      products.category
    ) {
      addons = products.category[0];
    }
    if (isGtm4) {
      const prodList = isFromCart ? products : [products];
      const { item, ecommerce } = GTMData.parseGA4DataLayer(
        multiProd && Array.isArray(multiProd) ? multiProd : prodList,
        promotions,
        true,
      );
      this.data.event = keyList.removeCart;
      this.data.ecommerce = {
        [keyList.products]: item,
        ...ecommerce,
      };
    } else {
      const prod = Array.isArray(products) ? products?.[0] : products;
      const productsData = [
        {
          [keyList.name]: prod.productName,
          [keyList.id]: prod.productId,
          price: parseFloat(prod.price.gross),
          quantity: parseFloat(prod.quantity),
          [keyList.category]: prod.gaCategory || GTMData.getCategoryNameFromEventType(prod.eventType),
          subCategory: subCategory,
          addons: addons,
          date: prod.fromDate,
        },
      ];
      const { language, loginStatus, userId } = GTMData.getUserData();
      this.data =
        this.tenantId === UIConfig.ymcB2CTenant
          ? {
              event: 'removeFromCart',
              emailId: getEmailIdGTMData(),
              ecommerce: {
                remove: { [keyList.products]: productsData, language, loginStatus, userId },
                currencyCode: UIConfig.currencyArabic,
              },
            }
          : {
              event: 'removeFromCart',
              emailId: getEmailIdGTMData(),
              language,
              ecommerce: {
                [keyList.currencyCode]: UIConfig.currencyArabic,
                remove: {
                  [keyList.products]: GTMData._setProductsData(
                    [prod],
                    true,
                    this.tenantId,
                    null,
                    isFromCart,
                    isJunior,
                    isFromCartDelete,
                  ),
                },
              },
            };
    }
  };

  static viewCartData = (productList, isGtm4, isMiniCart) => {
    try {
      if (isMatchTenant(UIConfig.tenants.ya)) {
        let propData = { ...productList };
        const keyList = GTMData.getKeyList(true);
        let payload = {
          ecommerce: {
            value: propData?.cartData?.cart?.grossPrice,
            currency: propData?.cartData?.cart?.currency,
            items: this.getAllCartProducts(propData?.cartData)?.map((item) => ({
              item_id: item?.productId,
              item_name: item?.productName,
              affiliation: 'etihadarena',
              item_category: item?.itemType,
              item_date: item?.fromDate,
              item_variant: item?.timeSlot,
              quantity: item?.quantity,
              price: item?.gross,
              index: JSON.parse(localStorage.getItem('EAGa4Storage'))?.index,
              item_list_id: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
              item_list_name: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
            })),
          },
        };
        this.data = { ...payload, event: keyList.viewCart };
      } else {
        let products = [];
        let groupedProduct = [];
        const { promotions } = productList;

        if (!isMiniCart) {
          for (const key in productList.items) {
            if (!productList.items.hasOwnProperty(key)) {
              continue;
            }
            if (productList.items[key].products.length > 0) {
              groupedProduct.push(...productList.items[key].products);
            } else {
              products.push(productList.items[key].products[0]);
            }
          }
        } else {
          products = productList;
        }

        const { item, ecommerce } = GTMData.parseGA4DataLayer([...groupedProduct, ...products], promotions);
        const keyList = GTMData.getKeyList(true);
        this.data.event = keyList.viewCart;

        this.data.ecommerce = {
          [keyList.products]: item,
          ...ecommerce,
        };
      }
    } catch (err) {
      console.log('GA4 error viewItem:', err);
    }
  };

  static addPaymentInfoData = (data) => {
    try {
      const { tenantName } = getLoggedInUser();
      const { cartData, paymentType } = data;
      const { currency, couponCode, grossPrice, promotions, items } = cartData || {};
      const couponApplied = document.querySelector('.c-coupon-code-payment--applied .bold')?.textContent;
      const keyList = GTMData.getKeyList(true);
      let itemList = [];
      const localCpn = localStorage.getItem('promoCode');
      const purchasedProd = getLocalStorageByKey('cartPurchaseProd') || [];
      if (isMatchTenant(UIConfig.tenants.ya)) {
        let payload = {
          ecommerce: {
            value: cartData?.grossPrice,
            currency: cartData?.currency,
            payment_type: paymentType,
            items: cartData?.items?.map((item) => ({
              item_id: item?.productId,
              item_name: item?.productName,
              affiliation: 'etihadarena',
              item_category: item?.itemType,
              item_date: item?.fromDate,
              item_variant: item?.timeSlot,
              quantity: item?.quantity,
              price: item?.gross,
              index: JSON.parse(localStorage.getItem('EAGa4Storage'))?.index,
              item_list_id: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
              item_list_name: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
            })),
          },
        };
        this.data = { ...payload, event: keyList.addPaymentInfo };
        return;
      }
      if (checkParksTenants()) {
        items.forEach(
          ({
            productId,
            fromDate,
            productName,
            quantity,
            classTypeName,
            gross,
            tab,
            isFlexible,
            ItemPromotionList,
            classType,
            experienceCatgory,
            price,
            category,
            gaCategory,
            gaTitle,
            itemType = '',
            timeSlot = '',
            unitPrice,
          }) => {
            const purchdProd = purchasedProd?.length && purchasedProd.find((prdId) => prdId.productId === productId);
            const { amount, qty, prodUnitPice } = this.getPromoIfAvail(ItemPromotionList, promotions);
            const isItemType = (isLoggedInUser() && purchdProd?.itemType) || itemType;
            const expType = experienceCatgory || purchdProd?.experienceCatgory || '';
            const finalPrice = price?.gross || gross || unitPrice;
            const gaData = {
              affiliation: tenantName,
              [keyList.dateOfVisit]: isItemType === 'ANP' || !fromDate ? undefined : fromDate,
              [keyList.dateType]: isFlexible || isItemType === 'ANP' || isItemType === 'VCH' ? 'flexible' : 'dated',
              [keyList.discount]: (qty && getAbsoluteInt(Number(amount)) / qty) || undefined,
              [keyList.category]: gaCategory || purchdProd?.gaCategory || undefined,
              [keyList.id]: productId,
              [keyList.name]: gaTitle || purchdProd?.gaTitle || undefined,
              [keyList.itemVariant]: classType || classTypeName,
              [keyList.timeSlot]: timeSlot || undefined,
              [keyList.nosAddonGuest]: expType === 'AddonGuest' ? parseFloat(qty || quantity) : 0,
              [keyList.nosadult]:
                classType === 'ADULT' || expType === 'PRIMARY' || expType === 'Secondary'
                  ? parseFloat(qty || quantity)
                  : 0,
              [keyList.nosjunior]: classType === 'JUNIOR' ? parseFloat(quantity) : 0,
              [keyList.price]: this.getTotalPrice(
                qty ? prodUnitPice : finalPrice,
                parseFloat(qty || quantity),
              )?.toFixed(2),
              [keyList.quantity]: qty || quantity,
              index: getProductIdx(productId),
            };
            itemList.push(gaData);
            if (qty && qty > 1 && quantity > qty) {
              const remainQty = quantity - qty;
              itemList.push({ ...gaData, ...this.nonDiscountPrd(remainQty, expType, classType, unitPrice) });
            }
          },
        );
      }
      this.data.event = keyList.addPaymentInfo;
      const discountVal =
        promotions?.length && promotions.find((prm) => prm.type === '2' || prm.type === '4')?.discount;
      this.data.ecommerce = {
        [keyList.products]: itemList,
        [keyList.currencyCode]: currency || undefined,
        [keyList.coupon]: (localCpn && decryptParam(localCpn)) || couponApplied || couponCode || undefined,
        [keyList.discountValue]: getAbsoluteInt(Number(discountVal)) || undefined,
        [keyList.paymentType]: paymentType || undefined,
        value: Number(grossPrice).toFixed(2) || '',
      };
    } catch (error) {
      console.log('GTM4 Error for add payment info data:', error);
    }
  };

  static viewItemListData = (data) => {
    try {
      const keyList = GTMData.getKeyList(true);
      if (isMatchTenant(UIConfig.tenants.ya)) {
        const items = data?.imageDetail?.map((item, index) => {
          const regex = /<p[^>]*>(.*?)<\/p>/i;
          const match = regex.exec(item?.bodyCopy);
          return {
            item_id: item?.eventID,
            item_name: item?.eventTitle || (match ? match[1] : item?.imageTitle),
            affiliation: 'etihadarena',
            index: index + 1,
            item_date: item?.startDate,
            item_list_id: getGa4Category(window?.location?.pathname, UIConfig.ga4Constants.HOME),
            item_list_name: getGa4Category(window?.location?.pathname, UIConfig.ga4Constants.HOME),
          };
        });
        this.data = { ecommerce: { items }, event: keyList.viewItemList };
        return;
      }
      const { category, gaTabTitle, ...productList } = data;
      let itemList = [];
      let currencyCode = '';

      if (checkParksTenants()) {
        let products = [];
        const { tenantName } = getLoggedInUser();
        if (Array.isArray(productList?.allProductList)) {
          products = [...productList.allProductList];
        } else {
          for (const key in productList) {
            if (!productList.hasOwnProperty(key)) {
              continue;
            }
            products.push(productList[key][0]);
          }
        }

        products.forEach(
          (
            { productId, gaTitle, gaCategory, currency, gross, classType, classTypeName, itemType, experienceCatgory },
            index,
          ) => {
            currencyCode = currency;
            updateGA4LocalStorage({
              productId,
              itemType,
              classTypeName,
              gaTitle,
              gaCategory,
              experienceCatgory,
              classType,
              index: index + 1,
            });
            itemList.push({
              [keyList.id]: productId,
              [keyList.name]: gaTitle || undefined,
              [keyList.category]: gaCategory || undefined,
              // [keyList.nosadult]: classType === 'ADULT' ? parseFloat(quantity) : 0,
              // [keyList.nosjunior]: classType === 'JUNIOR' ? parseFloat(quantity) : 0,
              [keyList.itemVariant]: classType || classTypeName || undefined,
              [keyList.affiliation]: tenantName,
              [keyList.itemListId]: gaTabTitle,
              [keyList.itemListName]: category,
              [keyList.price]: gross || undefined,
              index: getProductIdx(productId),
            });
          },
        );
      } else if (isMatchTenant(UIConfig.tenants.yi)) {
        const getTripDuration = (datefrom, dateto) => {
          const _dateFrom = moment(datefrom, UIConfig.dateFormats.DD_MM_YYYY);
          const _dateTo = moment(dateto, UIConfig.dateFormats.DD_MM_YYYY);
          var diffDays = _dateTo.diff(_dateFrom, 'days');

          return diffDays;
        };
        // const queryParamSearch = useLocation().search;
        // const packageType = sessionStorage.getItem('GA_PackageType');
        // const categoryType = sessionStorage.getItem('GA_Category');
        const itemListName = sessionStorage.getItem('GA_DaycationCategory');

        const itemCategory = getSessionStorage('GA_MainCategory');
        const itemCategory2 = getSessionStorage('GA4PackageType');
        const itemCategory3 = getSessionStorage('GA4PackageOfferingCategory');
        let itemCategory4;
        const nights = getTripDuration(data?.fromDate, data?.toDate);

        currencyCode = data?.currencyId;

        data.visiblePackages &&
          data.visiblePackages.map((singlePackage, index) => {
            const hotelName = singlePackage?.PackageItems[0].GA_ContentNode.Fields.filter(
              (fObj) => fObj.name === 'Name',
            ).map((item) => item.value);

            if (singlePackage?.PackageItems.length > 1) {
              const secondItem = singlePackage?.PackageItems[1];
              if ((secondItem && secondItem.ProductType === 'GEN') || secondItem.ProductType === 'EVT') {
                const nameField = secondItem.GA_ContentNode.Fields.find((field) => field.name === 'Name');
                if (nameField) {
                  itemCategory4 = nameField.value;
                }
              }
            }
            itemList.push({
              [keyList.affiliation]: 'Yas Island',
              [keyList.checkinDate]: data?.fromDate,
              [keyList.checkoutDate]: data?.toDate || 'N/A',
              // [keyList.dateOfVisit]: data?.fromDate,
              [keyList.discount]: singlePackage?.TotalDiscount,
              // [keyList.coupon]: 'N/A',
              [keyList.itemCategory]: itemCategory?.replace(/[^a-zA-Z &]/g, '') || 'N/A',
              [keyList.itemCategory2]:
                parseQueryString('ctype') !== 'DCT' ? itemCategory2?.replace(/[^a-zA-Z &]/g, '') : 'N/A',
              [keyList.itemCategory3]: itemCategory3 || 'N/A',
              [keyList.itemCategory4]: itemCategory4 || 'N/A', // Centro Yas Island with Any 2 Parks take offer name
              [keyList.itemId]: singlePackage?.PackageItems[0].SupplierId || singlePackage?.PackageItems[0]?.ContractId,
              [keyList.itemListId]: itemListName?.replace(/[^a-zA-Z &]/g, '') || 'N/A',
              [keyList.itemListName]: itemListName?.replace(/[^a-zA-Z &]/g, '') || 'N/A',
              [keyList.itemName]: hotelName[0],
              [keyList.nosadult]: data?.sumAdult,
              [keyList.noschild]: data?.sumChild,
              [keyList.totalPaxCount]: data?.sumChild + data?.sumAdult,
              [keyList.numberOfNights]: nights || 'N/A',
              [keyList.numberOfRooms]: data?.roomTotal?.length || 'N/A',
              [keyList.price]: singlePackage?.TotalPriceInAED || 'N/A',
              [keyList.value]: singlePackage?.TotalPriceInAED || 'N/A',
              [keyList.quantity]: '1',
              index: index + 1,
            });
          });

        this.data.event = keyList.viewItemList;
        this.data.ecommerce = {
          [keyList.products]: itemList,
          currency: currencyCode,
          [keyList.journeyType]: itemCategory?.replace(/[^a-zA-Z &]/g, '') || 'N/A',
        };

        return;
      }

      this.data.event = keyList.viewItemList;
      this.data.ecommerce = {
        [keyList.products]: itemList,
        currency: currencyCode,
      };
    } catch (err) {
      console.log('GA4 error viewItemList:', err);
    }
  };

  static viewItemData = (data) => {
    try {
      const { additionalPayload, productItem, productWidget = false } = data || {};
      let itemList = [];
      let currencyCode = '',
        value = '';
      const keyList = GTMData.getKeyList(true);
      if (checkParksTenants()) {
        const { tenantName } = getLoggedInUser();

        currencyCode = additionalPayload?.currencyId;
        value =
          (productItem?.length > 1
            ? productItem?.reduce((prevVal, currVal) => Number(prevVal.gross) + Number(currVal.gross))
            : productItem[0].gross) || '';
        productItem.forEach((itm, idx) => {
          itemList.push({
            [keyList.affiliation]: tenantName,
            [keyList.dateOfVisit]: undefined,
            [keyList.dateType]: itm?.isFlexible ? 'flexible' : 'dated',
            [keyList.discount]: undefined,
            [keyList.itemCategory]: itm?.gaCategory || undefined,
            [keyList.itemId]: itm?.productId,
            ...(!productWidget && {
              [keyList.itemListId]: itm?.category || '',
              [keyList.itemListName]: itm?.category || '',
            }),
            [keyList.itemVariant]: itm?.classType || itm?.classTypeName || '',
            [keyList.itemName]: itm?.gaTitle || itm?.productName || '',
            [keyList.nosAddonGuest]: itm?.numberOfGuest || 0,
            [keyList.nosadult]: itm?.numberOfAdults || 0,
            [keyList.nosjunior]: itm?.numberOfChildren || 0,
            index: idx + 1,
          });
        });
        this.data.event = keyList.viewItem;
        this.data.ecommerce = {
          [keyList.products]: itemList,
          [keyList.value]: value,
          currency: currencyCode,
        };
      } else if (isMatchTenant(UIConfig.tenants.yi)) {
        const hotelName = productItem?.PackageItems[0]?.GA_ContentNode.Fields.filter(
          (fObj) => fObj.name === 'Name',
        ).map((item) => item.value);
        const getTripDuration = (datefrom, dateto) => {
          const _dateFrom = moment(datefrom, UIConfig.dateFormats.DD_MM_YYYY);
          const _dateTo = moment(dateto, UIConfig.dateFormats.DD_MM_YYYY);
          var diffDays = _dateTo.diff(_dateFrom, 'days');

          return diffDays;
        };
        // const queryParamSearch = useLocation().search;
        const packageType = sessionStorage.getItem('GA_PackageType') || '';
        const categoryType = sessionStorage.getItem('GA_Category') || '';
        const gaMainCategory = getSessionStorage('GA_MainCategory') || getMainStayCategory(parseQueryString('ctype'));
        const itemListName = sessionStorage.getItem('GA_DaycationCategory');
        const itemCategory = getSessionStorage('GA_MainCategory');
        const itemCategory2 = getSessionStorage('GA4PackageType');
        const itemCategory3 = getSessionStorage('GA4PackageOfferingCategory');
        const itemCategory4 = getSessionStorage('itemCategory4');
        currencyCode = additionalPayload?.currencyId;
        itemList.push({
          [keyList.affiliation]: 'Yas Island',
          [keyList.checkinDate]: additionalPayload?.fromDate,
          [keyList.checkoutDate]: additionalPayload?.toDate || 'N/A',
          [keyList?.numberOfRooms]:
            gaMainCategory?.toLowerCase() == 'daycation' ? 'N/A' : additionalPayload?.roomTotal[0] || 'N/A',

          [keyList.discount]: productItem?.TotalDiscount || 'N/A',
          [keyList.itemCategory]: itemCategory || 'N/A',
          [keyList.itemCategory2]: itemCategory !== 'Daycation' ? itemCategory2 : 'N/A',
          [keyList.itemCategory3]: itemCategory3 || 'N/A',
          [keyList.itemCategory4]: itemCategory4 || 'N/A',
          [keyList.itemId]: productItem?.PackageItems[0]?.SupplierId || productItem?.PackageItems[0]?.ContractId,
          [keyList.itemListId]: itemListName || 'N/A',
          [keyList.itemListName]: itemListName || 'N/A',
          [keyList.itemName]: hotelName[0] || '',
          [keyList.nosadult]: additionalPayload?.sumAdult || 0,
          [keyList.noschild]: additionalPayload?.sumChild || 0,
          [keyList.mealPlan]: additionalPayload?.confirmationObj?.Hotel?.GA_SelectedBoardBasis || 'N/A',
          [keyList.roomSelected]: additionalPayload?.confirmationObj?.Hotel?.Rooms[0]?.GA_RoomType || 'N/A',
          [keyList.price]: productItem?.TotalPriceInAED || 'N/A',
          [keyList.quantity]: '1',
          index: getSessionStorage('packageIndex'),
        });
        this.data.event = keyList.viewItem;
        this.data.ecommerce = {
          [keyList.products]: itemList,
          [keyList.journeyType]: gaMainCategory?.toLowerCase() || 'N/A',
          [keyList.value]: productItem?.TotalPriceInAED || 'N/A',
          currency: currencyCode,
        };
        sessionStorage.removeItem('itemCategory4');
      } else if (isMatchTenant(UIConfig.tenants.ya)) {
        let propData = { ...data } || {};
        let payload = {
          ecommerce: {
            value: propData?.cartData?.cart?.grossPrice,
            currency: propData?.cartData?.cart?.currency,
            item: {
              item_id: data?.performanceId,
              item_name: data?.performanceId,
              affiliation: 'etihadarena',
              item_date: data?.date,
              availability: data?.availability,
              index: JSON.parse(localStorage.getItem('EAGa4Storage'))?.index,
              item_list_id: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
              item_list_name: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
            },
          },
        };
        this.data = { ...payload, event: keyList.viewItem };
      }
    } catch (err) {
      console.log('GA4 error viewItem:', err);
    }
  };

  static selectItemData = (data) => {
    const keyList = GTMData.getKeyList(true);
    if (isMatchTenant(UIConfig.tenants.ya)) {
      let propData = { ...data } || {};
      const regex = /<p[^>]*>(.*?)<\/p>/i;
      const match = regex.exec(propData?.bodyCopy);
      let payload = {
        click_text: propData.click_text,
        ecommerce: {
          currency: getCurrency(),
          items: [
            {
              item_id: propData?.eventID,
              item_name: propData?.eventTitle || (match ? match[1] : propData?.imageTitle),
              affiliation: 'etihadarena',
              index: propData?.index + 1,
              item_date: propData?.startDate,
              item_list_id: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
              item_list_name: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
            },
          ],
        },
      };
      this.data = { ...payload, event: keyList.selectItem };
      return;
    }
    const { category, gaTabTitle, itemsList = [], productWidget = false } = data;
    const products = [];
    let currencyCode = '',
      value = 0;
    const { tenantName } = getLoggedInUser();
    if (checkParksTenants()) {
      itemsList.forEach(
        ({ productId, gaTitle, gaCategory, gross, tab, currency, classType, classTypeName, groupByLabel }, index) => {
          currencyCode = currency;
          value += Number(gross);
          const prodCat = Array.isArray(category) ? category?.join(',') : category;
          products.push({
            [keyList.id]: productId,
            [keyList.name]: gaTitle || undefined,
            [keyList.discount]: undefined,
            [keyList.category]: gaCategory || undefined,
            [keyList.affiliation]: tenantName,
            [keyList.itemVariant]: classType || classTypeName || undefined,
            [keyList.itemListId]: productWidget ? undefined : gaTabTitle || undefined,
            [keyList.itemListName]: productWidget ? undefined : groupByLabel || prodCat,
            [keyList.price]: gross || undefined, //
            index: getProductIdx(productId),
          });
        },
      );
    } else if (isMatchTenant(UIConfig.tenants.yi)) {
      try {
        const getTripDuration = (datefrom, dateto) => {
          const _dateFrom = moment(datefrom, UIConfig.dateFormats.DD_MM_YYYY);
          const _dateTo = moment(dateto, UIConfig.dateFormats.DD_MM_YYYY);
          var diffDays = _dateTo.diff(_dateFrom, 'days');

          return diffDays;
        };
        const itemListName = sessionStorage.getItem('GA_DaycationCategory');
        let itemCategory4;
        const itemCategory = getSessionStorage('GA_MainCategory');
        const itemCategory2 = getSessionStorage('GA4PackageType');
        const itemCategory3 = getSessionStorage('GA4PackageOfferingCategory');

        const { additionalPayload, packItem } = data;

        currencyCode = additionalPayload?.currencyId;
        const hotelName = packItem?.PackageItems[0].GA_ContentNode.Fields.filter((fObj) => fObj.name === 'Name').map(
          (item) => item.value,
        );

        if (packItem?.PackageItems.length > 1) {
          const secondItem = packItem?.PackageItems[1];
          if ((secondItem && secondItem.ProductType === 'GEN') || secondItem.ProductType === 'EVT') {
            const nameField = secondItem.GA_ContentNode.Fields.find((field) => field.name === 'Name');
            if (nameField) {
              itemCategory4 = nameField.value;
              setSessionStorage('itemCategory4', itemCategory4);
            }
          }
        }

        const nights = getTripDuration(additionalPayload?.fromDate, additionalPayload?.toDate);
        products.push({
          [keyList.affiliation]: 'Yas Island', //
          [keyList.checkinDate]: additionalPayload?.fromDate, //
          [keyList.checkoutDate]: additionalPayload?.toDate || 'N/A', //
          [keyList.discount]: packItem?.TotalDiscount, //
          [keyList.journeyType]: itemCategory || 'N/A',
          [keyList.itemCategory]: itemCategory || 'N/A', //
          [keyList.itemCategory2]: itemCategory2 || 'N/A',
          [keyList.itemCategory3]: itemCategory3 || 'N/A',
          [keyList.itemId]: packItem?.PackageItems[0].SupplierId, //
          [keyList.itemListId]: itemListName || 'N/A', //
          [keyList.itemListName]: itemListName || 'N/A', //
          [keyList.itemName]: hotelName[0], //
          [keyList.nosadult]: additionalPayload?.sumAdult, //
          [keyList.noschild]: additionalPayload?.sumChild, //
          [keyList.totalPaxCount]: additionalPayload?.sumChild + additionalPayload?.sumAdult,
          [keyList.numberOfNights]: nights || '',
          [keyList.price]: packItem?.TotalPriceInAED || 'N/A', //
          [keyList.quantity]: '1', //
          index: getSessionStorage('packageIndex'), //
        });
      } catch (err) {
        console.log('selectItem GA4 error', err);
      }
    }
    this.data.event = keyList.selectItem;
    this.data.ecommerce = {
      [keyList.products]: products,
      currency: currencyCode,
      value: value?.toFixed(2) || 'N/A',
    };
  };

  //get total calculations for cart from product list
  static getCartTotal = (productList) => {
    let totalPrice = 0;
    productList.forEach((product) => {
      totalPrice += Number(product.unitPrice);
    });

    return totalPrice;
  };

  static purchaseActionData = ({ products }) => {
    const allProducts = GTMData._setProductsData(products, true, this.tenantId);
    const { id, name, quantity, price } = allProducts?.length && allProducts[0];

    this.data = {
      event: 'purchaseActionData',
      currencyCode: UIConfig.currencyArabic,
      eventid: id || name,
      price: price,
      quantity: quantity,
      emailId: getEmailIdGTMData(),
    };
  };

  static progressFlowData = (payload) => {
    if (isMatchTenant(UIConfig?.tenants?.yi)) {
      const keyList = GTMData.getKeyList(true);
      this.data = {
        event: keyList.progressFlow,
        name: 'event - checkout',
        step_label: 'select ticket',
        step_number: '2',
      };
    } else {
      const keyList = GTMData.getKeyList(true);
      this.data = {
        event: keyList.progressFlow,
        name: 'Renew Annual Pass',
        step_label: 'Checkout Renew Annual Pass',
        step_number: '2',
      };
    }
  };

  static clickTabData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);
      this.data = {
        event: keyList.clickTab,
        name: payload.name,
        category: payload.category || undefined,
        element_text: payload.elementText,
      };
    } catch (err) {
      console.log('GTM4 Error for click accordian data:', err);
    }
  };
  static clickAccordianData = (payload) => {
    try {
      function removeHtmlTags(input) {
        return input?.replace(/<[^>]+>/g, ''); // This regex removes all HTML tags
      }
      const keyList = GTMData.getKeyList(true);
      this.data = {
        event: keyList?.clickAccordian,
        name: payload.name,
        category: payload?.category || '',
        element_text: removeHtmlTags(payload?.elementText)?.toLowerCase(),
      };
    } catch (err) {
      console.log('GTM4 Error for click accordian data:', err);
    }
  };
  static clickCtaData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);
      if (isMatchTenant(UIConfig.tenants.fwad) || isMatchTenant(UIConfig.tenants.yi)) {
        payload = { ...payload };
      }
      //put tenant specific condition and change the payload
      this.data = { ...payload, event: keyList.clickCta };
    } catch (err) {
      console.log('GTM4 Error for click CTA data:', err);
    }
  };
  static clickLinkData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);
      if (isMatchTenant(UIConfig.tenants.yi)) {
        payload = { ...payload };
      }
      //put tenant specific condition and change the payload
      this.data = { ...payload, event: keyList.clickLink };
    } catch (err) {
      console.log('GTM4 Error for click CTA data:', err);
    }
  };

  static FormCompleteData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);
      this.data = {
        event: keyList?.formComplete,
        form_name: payload.name || undefined,
        user_id: getYasIdGTMData() || undefined,
        ...(isMatchTenant(UIConfig.tenants.ya) ? { login_type: payload.login_type } : {}),
      };
    } catch (err) {
      console.log('GTM4 Error for fom complete data:', err);
    }
  };

  static startFlowData = (payload) => {
    if (isMatchTenant(UIConfig?.tenants?.yi)) {
      const keyList = GTMData.getKeyList(true);
      this.data = {
        event: keyList.startFlow,
        name: 'event - checkout',
        step_label: 'begin event details',
        step_number: '1',
      };
    } else {
      const keyList = GTMData.getKeyList(true);
      this.data = {
        event: keyList.startFlow,
        name: 'Renew Annual Pass',
        step_label: 'Manage Annual Pass',
        step_number: '1',
      };
    }
  };

  static beginCheckoutData = (data) => {
    const keyList = GTMData.getKeyList(true);
    if (isMatchTenant(UIConfig.tenants.ya)) {
      let propData = { ...data } || {};
      let payload = {
        ecommerce: {
          value: propData?.cartData?.cart?.grossPrice,
          currency: propData?.cartData?.cart?.currency,
          items: this.getAllCartProducts(propData?.cartData)?.map((item) => ({
            item_id: item?.productId,
            item_name: item?.productName,
            affiliation: 'etihadarena',
            item_category: item?.itemType,
            item_date: item?.fromDate,
            item_variant: item?.timeSlot,
            quantity: item?.quantity,
            price: item?.gross,
            discount: item?.discount?.computed,
            index: JSON.parse(localStorage.getItem('EAGa4Storage'))?.index,
            item_list_id: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
            item_list_name: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
          })),
        },
      };
      this.data = { ...payload, event: keyList.beginCheckout };
      return;
    }
    let itemList = [],
      ecomData = {};
    if (checkParksTenants()) {
      const { products, promotions } = data;
      const { item, ecommerce } = GTMData.parseGA4DataLayer(products, promotions);
      itemList = [...item];
      ecomData = { ...ecommerce };
    } else if (isMatchTenant(UIConfig.tenants.yi)) {
      const { additionalPayload, packItem } = data;

      const itemListName = sessionStorage.getItem('GA_DaycationCategory');
      let cartSummaryForConfirmation = JSON.parse(getSessionStorage('cartSummaryForConfirmation'));
      const gaMainCategory = getSessionStorage('GA_MainCategory') || getMainStayCategory(parseQueryString('ctype'));
      let itemCategory4;
      const itemCategory = getSessionStorage('GA_MainCategory');
      const itemCategory2 = getSessionStorage('GA4PackageType');
      const itemCategory3 = getSessionStorage('GA4PackageOfferingCategory');
      const specialNameRequests = getSessionStorage('specialNameRequests');

      const hotelName = packItem?.PackageItems[0]?.GA_ContentNode?.Fields.filter((fObj) => fObj.name === 'Name').map(
        (item) => item?.value,
      );

      if (packItem?.PackageItems.length > 1) {
        const secondItem = packItem?.PackageItems[1];
        if ((secondItem && secondItem.ProductType === 'GEN') || secondItem.ProductType === 'EVT') {
          const nameField = secondItem.GA_ContentNode.Fields.find((field) => field.name === 'Name');
          if (nameField) {
            itemCategory4 = nameField?.value;
            setSessionStorage('itemCategory4', itemCategory4);
          }
        }
      }

      const getTripDuration = (datefrom, dateto) => {
        const _dateFrom = moment(datefrom, UIConfig.dateFormats.DD_MM_YYYY);
        const _dateTo = moment(dateto, UIConfig.dateFormats.DD_MM_YYYY);
        var diffDays = _dateTo.diff(_dateFrom, 'days');

        return diffDays;
      };
      const nights = getTripDuration(additionalPayload?.fromDate, additionalPayload?.toDate);
      const venueDate = `${cartSummaryForConfirmation?.Cart?.ProductGroups[0]?.Products[0]?.Venue?.VenueDate?.date}-${cartSummaryForConfirmation?.Cart?.ProductGroups[0]?.Products[0]?.Venue?.VenueDate?.month}-${cartSummaryForConfirmation?.Cart?.ProductGroups[0]?.Products[0]?.Venue?.VenueDate?.year}`;
      const eventName = cartSummaryForConfirmation?.Cart?.ProductGroups[0]?.Products[0]?.GA_ContentNode?.Fields?.filter(
        (fObj) => fObj.name === 'Name',
      ).map((item) => item?.value);
      const eventTicketType = cartSummaryForConfirmation?.Cart?.ProductGroups[0]?.Products[0]?.Venue.GA_VenueTicketType;
      const eventShowTime = cartSummaryForConfirmation?.Cart?.ProductGroups[0]?.Products[0]?.Venue.GA_VenueTime;
      const eventNumberOfTickets = cartSummaryForConfirmation?.Cart?.ProductGroups[0]?.Products[0]?.Venue?.NoOfAdults;

      itemList.push({
        // [keyList.coupon]: product?.coupon || '',

        [keyList.affiliation]: 'Yas Island',
        [keyList.checkinDate]: additionalPayload?.fromDate || 'N/A',
        [keyList.checkoutDate]: additionalPayload?.toDate || 'N/A',
        // [keyList.discount]: product?.discount || '',
        [keyList.eventDate]: venueDate || '', //
        [keyList.eventName]: eventName[0] || '',
        [keyList.eventNumberOfTickets]: eventNumberOfTickets || '', //
        [keyList.eventShowTime]: eventShowTime || 'N/A',
        [keyList.eventTicketType]: eventTicketType || 'N/A',
        [keyList.itemCategory]: itemCategory || 'N/A',
        [keyList.itemCategory2]: itemCategory2 || 'N/A',
        [keyList.itemCategory3]: itemCategory3 || 'N/A',
        [keyList.itemCategory4]: itemCategory4 || 'N/A',
        [keyList.itemId]: packItem?.PackageItems[0]?.SupplierId || packItem?.PackageItems[0]?.ContractId || 'N/A',
        [keyList.itemListId]: itemListName?.replace(/[^a-zA-Z &]/g, '') || 'N/A',
        [keyList.itemListName]: itemListName?.replace(/[^a-zA-Z &]/g, '') || 'N/A',
        [keyList.itemName]: hotelName[0] || '',
        [keyList.nosadult]: additionalPayload?.sumAdult || 0,
        [keyList.noschild]: additionalPayload?.sumChild || 0,
        [keyList.numberOfNights]: nights || 'N/A',
        [keyList.numberOfRooms]:
          gaMainCategory?.toLowerCase() == 'daycation' ? 'N/A' : additionalPayload?.roomTotal[0] || 'N/A',
        [keyList.mealPlan]: cartSummaryForConfirmation?.Cart?.Hotel?.GA_SelectedBoardBasis || 'N/A',
        [keyList.roomSelected]: cartSummaryForConfirmation?.Cart?.Hotel?.Rooms[0]?.GA_RoomType || 'N/A',
        // [keyList.specialRequests]: specialNameRequests || 'N/A',
        [keyList.totalPaxCount]: additionalPayload?.sumAdult + additionalPayload?.sumChild || 'N/A',
        [keyList.quantity]: '1',
        [keyList.price]: packItem?.TotalPriceInAED || 'N/A',
      });

      this.data.event = keyList.beginCheckout;
      this.data.ecommerce = {
        [keyList.products]: itemList,
        [keyList.journeyType]: gaMainCategory?.toLowerCase() || 'N/A',
        [keyList.value]: packItem?.TotalPriceInAED || 'N/A',
      };
      return;
    }

    this.data.event = keyList.beginCheckout;
    this.data.ecommerce = {
      [keyList.products]: itemList,
      ...ecomData,
    };
  };

  static checkoutData = ({ products, step, timeSlot, date, eventId, eventTitle, subTotal }, isGtm4) => {
    const { language, loginStatus, userId } = GTMData.getUserData();
    const keyList = GTMData.getKeyList(isGtm4);

    if (
      this.tenantId === UIConfig.yasArenaB2CTenant.toLocaleLowerCase() &&
      Array.isArray(products) &&
      products.length > 0
    ) {
      products[0].timeSlot = timeSlot || products[0].timeSlot;
      products[0].date = date || products[0].date;
      products[0].eventTitle = products[0].eventTitle || eventTitle;
      products[0].eventId = products[0].eventId || eventId;
    }
    this.data =
      this.tenantId === UIConfig.ymcB2CTenant
        ? {
            event: 'checkout',
            emailId: getEmailIdGTMData(),
            ecommerce: {
              currencyCode: UIConfig.currencyArabic,
              checkout: {
                actionField: { step: step },
                subTotal: parseFloat(subTotal),
                products: GTMData._setProductsData(products, true, this.tenantId, 'subTotal'),
                language,
                loginStatus,
                userId,
              },
            },
          }
        : {
            event: 'checkout',
            emailId: getEmailIdGTMData(),
            language,
            type: 'ticket',
            ecommerce: {
              [keyList.currencyCode]: UIConfig.currencyArabic,
              checkout: {
                actionField: { step: step, action: 'checkout' },
                [keyList.products]: GTMData._setProductsData(products, true, this.tenantId, null, null, null, null),
              },
            },
          };
  };
  static cartItemsData = ({ products, otherEvent }, isGtm4) => {
    const keyList = GTMData.getKeyList(isGtm4);
    if (products && products.length > 0) {
      this.data = {
        event: otherEvent || 'cartItems',
        emailId: getEmailIdGTMData(),
        [keyList.currencyCode]: UIConfig.currencyArabic,
        cartItems: GTMData._setProductsData(products, true, this.tenantId, isGtm4),
      };
    }
  };

  static ticketImpressionsData = ({ products }, isGtm4) => {
    const keyList = GTMData.getKeyList(isGtm4);
    const { language } = GTMData.getUserData();
    const { tenantName } = getLoggedInUser();
    if (isGtm4) {
      const itemList = [];
      products?.listing.length &&
        products.listing.forEach((item, index) => {
          const { gaTitle, gaCategory, title, productId, classType, classTypeName, itemType, experienceCatgory } = item;
          if (item.enablePj === '1' && productId) {
            updateGA4LocalStorage({
              productId,
              itemType,
              classTypeName,
              classType,
              gaTitle,
              gaCategory,
              experienceCatgory,
              index: index + 1,
            });
            itemList.push({
              [keyList.id]: productId,
              [keyList.name]: gaTitle || title,
              [keyList.category]: gaCategory || undefined,
              [keyList.affiliation]: tenantName,
              // [keyList.nosadult]: 0,
              // [keyList.nosjunior]: 0,
              [keyList.itemVariant]: classType || classTypeName || undefined,
              [keyList.itemListId]: products?.gaTabTitle || undefined,
              [keyList.itemListName]: products?.title || undefined,
              index: index + 1,
            });
          }
        });

      this.data.event = keyList.viewItemList;
      this.data.ecommerce = {
        [keyList.products]: itemList,
        currency: UIConfig.currencyArabic,
      };
    } else {
      const productData =
        products.listing &&
        products.listing.map((item) => {
          const {
            eventId,
            eventName,
            gaTitle,
            title,
            categoryKey,
            currentprice,
            fromPrice,
            gaPrice,
            price,
            productId,
          } = item;
          const productprice = gaPrice || price || fromPrice || currentprice;
          const result = {
            [keyList.id]: productId,
            [keyList.name]: gaTitle || title,
            [keyList.category]: Array.isArray(categoryKey) && categoryKey.length ? categoryKey[0] : categoryKey,
            price: productprice ? parseInt(productprice.replace(/,/g, ''), 10) : null,
          };
          if (this.tenantId === UIConfig.yasArenaB2CTenant.toLocaleLowerCase()) {
            result.id = Array.isArray(eventId) ? (eventId.length ? eventId[0] : '') : eventId;
            result.name = Array.isArray(eventName) ? (eventName.length ? eventName[0] : '') : eventName;
            result.category = Array.isArray(categoryKey) ? categoryKey.join(',') : categoryKey;
          }
          return result;
        });

      this.data = {
        language,
        event: 'productImpressions',
        type: 'ticket',
        ecommerce: { [keyList.impressions]: productData },
      };
    }
  };

  static ticketClickData = ({ products, type, clickedFrom, cardIndex }, isGtm4) => {
    const { language, loginStatus } = GTMData.getUserData();
    const keyList = GTMData.getKeyList(isGtm4);
    const { tenantName } = getLoggedInUser();
    // converted productdata variable const to let because need to update values for seaworld specific data
    let productData;
    if (isGtm4) {
      const itemList = [
        {
          [keyList.id]: products.productId,
          [keyList.name]: products.gaTitle || products.title,
          [keyList.discount]: undefined,
          [keyList.category]: products.gaCategory || undefined,
          [keyList.affiliation]: tenantName,
          [keyList.itemListId]: type || products.groupByLabel || '',
          [keyList.itemListName]: type || products.groupByLabel || '',
          index: getProductIdx(products.productId),
        },
      ];

      this.data.event = keyList.selectItem;

      const price = products?.gross || products?.price || 0;
      this.data.ecommerce = {
        [keyList.products]: itemList,
        currency: UIConfig.currencyArabic,
        value: Number(price).toFixed(2) || undefined,
      };
    } else {
      // change data if seaworld tenant
      if (this.tenantId === UIConfig.SWADB2C) {
        productData = [
          {
            id: products?.productId || products?.productOverlaySelector?.collections[0].productId || '',
            name: products?.title || '',
            dimension5:
              products?.productCategory || GTMData.getCategoryNameFromEventType(products.productCategory) || '', // change the category to dimension file as suggested by GA team.
            list: products?.productCategory || GTMData.getCategoryNameFromEventType(products.productCategory) || '', // change the category to dimension file as suggested by GA team.
            category: products?.productCategory || GTMData.getCategoryNameFromEventType(products.productCategory) || '', // change the category to dimension file as suggested by GA team.
            price: products?.price[0]?.price || 0,
            dimension7: products?.addedFromType || clickedFrom || UIConfig.regularText,
            dimension2: `${loginStatus ? 'Yes' : 'No'}`,
            position: cardIndex,
          },
        ];
      } else {
        const ProductPrice = products.gaPrice || products.price || products.fromPrice || products.currentprice || '';
        productData = [
          {
            [keyList.id]: products.productId,
            [keyList.name]: products.gaTitle || products.title,
            [keyList.category]:
              Array.isArray(products.categoryKey) && products.categoryKey.length
                ? products.categoryKey[0]
                : products.categoryKey,
            price: ProductPrice ? parseInt(ProductPrice.replace(/,/g, ''), 10) : null,
          },
        ];
      }
      let newData = {
        language,
        event: 'productClicks',
        type: 'ticket',
        ecommerce: {
          click: { [keyList.products]: productData },
          [keyList.currencyCode]: UIConfig.currencyArabic,
        },
      };

      if (clickedFrom) {
        newData.clickedFrom = clickedFrom;
      }
      this.data = newData;
    }
  };

  static ticketDetailsLoadData = ({ productData }, isGtm4) => {
    const keyList = GTMData.getKeyList(isGtm4);
    const { language } = GTMData.getUserData();
    const formedProductData = getFWBookingConfirmationAnalytics(productData, keyList, isGtm4);
    this.data = {
      language,
      type: 'ticket',
      event: isGtm4 ? 'view_item' : 'productDetails',
      ecommerce: {
        [keyList.currencyCode]: UIConfig.currencyArabic,
        detail: { [keyList.products]: [formedProductData] },
      },
    };
  };

  static purchaseData = ({ products, items, reservationId, revenue, payload }, isGtm4 = false) => {
    const keyList = GTMData.getKeyList(true);
    try {
      const itemListName = sessionStorage.getItem('GA_DaycationCategory');
      const itemCategory = getSessionStorage('GA_MainCategory');
      const itemCategory2 = getSessionStorage('GA4PackageType');
      const itemCategory3 = getSessionStorage('GA4PackageOfferingCategory');
      const gaMainCategory = getSessionStorage('GA_MainCategory') || getMainStayCategory(parseQueryString('ctype'));
      const promoCode = getSessionStorage('checkoutCode');
      const getTripDuration = (datefrom, dateto) => {
        const _dateFrom = moment(datefrom, UIConfig.dateFormats.YYYY_M_D);
        const _dateTo = moment(dateto, UIConfig.dateFormats.YYYY_M_D);
        var diffDays = _dateTo.diff(_dateFrom, 'days');
        return diffDays;
      };
      const hotelName = payload?.productItem?.PackageItems[0]?.GA_ContentNode.Fields.filter(
        (fObj) => fObj.name === 'Name',
      ).map((item) => item.value);
      const nights = getTripDuration(payload?.fromDate, payload?.toDate);
      let itemList = {};
      if (isGtm4 && isMatchTenant(UIConfig.tenants.yi)) {
        itemList[keyList.affiliation] = 'Yas Island';
        itemList[keyList.checkinDate] = payload?.fromDate;
        itemList[keyList.checkoutDate] = payload?.toDate;
        itemList[keyList.value] = payload?.confirmationObj?.Totals?.TotalAmount || 'N/A';
        itemList[keyList.coupon] = promoCode || 'N/A';
        itemList[keyList.discount] = payload?.confirmationObj?.Cart?.Totals?.PromoCodeAmount || 'N/A';
        itemList[keyList.eventDate] = payload?.productItem?.eventDate || 'N/A';
        itemList[keyList.eventName] = payload?.productItem?.eventName || 'N/A';
        itemList[keyList.eventNumberOfTickets] = payload?.productItem.numberOfTickets || 'N/A';
        itemList[keyList.eventSeat] = payload?.productItem?.eventSeat || 'N/A';
        itemList[keyList.eventShowTime] = payload?.productItem?.eventShowTime || 'N/A';
        itemList[keyList.eventTicketType] = payload?.productItem?.eventTicketType || 'N/A';
        itemList[keyList.eventTicketZone] = payload?.productItem?.eventTicketZone || 'N/A';
        itemList[keyList.itemCategory] = itemCategory || 'N/A';
        itemList[keyList.itemCategory2] = itemCategory2 || 'N/A';
        itemList[keyList.itemCategory3] = itemCategory3 || 'N/A';
        itemList[keyList.itemId] =
          payload?.productItem?.PackageContent?.SupplierId || payload?.productItem?.PackageContent?.ContractId || 'N/A';
        itemList[keyList.itemlistId] = itemListName || 'N/A';
        itemList[keyList.itemListName] = itemListName || 'N/A';
        itemList[keyList.itemName] = hotelName[0] || 'N/A';
        itemList[keyList.nosadult] = payload?.confirmationObj?.Hotel?.Rooms?.[0]?.NoOfAdults || 0;
        itemList[keyList.noschild] = payload?.confirmationObj?.Hotel?.Rooms?.[0]?.NoOfChilds || 0;
        itemList[keyList.numberOfNights] = nights || 'N/A';
        itemList[keyList.numberOfRooms] = payload?.confirmationObj?.Hotel?.Rooms?.length || 'N/A';
        // [keyList.roomSelected]: cartSummaryForConfirmation?.Cart?.Hotel?.Rooms[0]?.GA_RoomType || 'N/A',
        itemList[keyList.roomSelected] = payload?.GARoomsData
          ? payload?.GARoomsData?.replace(/[^.,a-zA-Z –]/g, '')
          : 'N/A';
        itemList[keyList.specialRequests] = payload?.specialNameRequests;
        itemList[keyList.totalPaxCount] =
          payload?.confirmationObj?.Hotel?.Rooms?.[0]?.NoOfAdults ||
          0 + payload?.confirmationObj?.Hotel?.Rooms?.[0]?.NoOfChilds ||
          0;
        itemList[keyList.quantity] = 1;
        itemList[keyList.price] = payload?.confirmationObj?.Totals?.TotalAmountInAED || 'N/A';
        itemList[keyList.tax] = payload?.confirmationObj?.Totals?.TaxAmountInAED || 'N/A';
        itemList[keyList.transactionId] = payload?.bId || 'N/A';
        itemList[keyList.paymentType] = payload?.paymentType || 'N/A';

        this.data.event = keyList.purchase;
        this.data.ecommerce = {
          [keyList.items]: [itemList],
          currency: payload?.currencyCode,
          [keyList.journeyType]: gaMainCategory || '',
          [keyList.paymentType]: payload?.paymentType || '',
          [keyList.transactionId]: payload?.bId || '',
          [keyList.value]: payload?.confirmationObj?.Totals?.TotalAmountInAED || 'N/A',
          [keyList.tax]: payload?.confirmationObj?.Totals?.TaxAmountInAED || 'N/A',
        };
      } else if (isMatchTenant(UIConfig.tenants.ya)) {
        let payload = {
          ecommerce: {
            coupon: products?.actionField?.coupon,
            tax: products?.actionField?.tax,
            value: products?.actionField?.tax + products?.actionField?.revenue,
            currency: products?.currency,
            discount: products?.actionField?.discountAmount,
            transaction_id: products?.actionField?.id,
            items: products?.products?.map((item) => ({
              item_id: item?.id,
              item_name: item?.name,
              affiliation: 'etihadarena',
              item_date: item?.dimension8,
              item_variant: item?.variant,
              quantity: item?.quantity,
              price: item?.price,
              currency: item?.currency,
              discount: item?.discount,
              index: JSON.parse(localStorage.getItem('EAGa4Storage'))?.index,
              item_list_id: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
              item_list_name: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
            })),
          },
        };
        this.data = { ...payload, event: keyList.purchase };
      } else {
        const productsData = deepCloneObject(products);
        productsData.forEach((product) => {
          const item = items.filter((item) => item.productId === product.productId)[0];
          product.productType = product.tab = product.tab instanceof Array ? product.tab.sort().join('') : product.tab;
          product.category = product.category instanceof Array ? product.category.sort().join('') : product.category;
          product.quantity = item.quantity;
          product.productIdType = getProdIdType(product);
          product.price = {
            gross: item.price.gross,
            net: item.price.net,
            tax: item.price.tax,
          };
        });

        this.data.event = 'purchase';
        this.data.emailId = getEmailIdGTMData();
        this.data.ecommerce = {
          purchase: {
            actionField: {
              [id]: reservationId,
              affiliation: 'Online Store',
              revenue: revenue,
            },
            [keyList.products]: GTMData._setProductsData(productsData),
          },
        };
      }
    } catch (error) {
      console.log('GTM4 Error for purchase data:', error);
    }
  };

  static refundData = (payload) => {
    let checkinDate = `${payload?.item.Booking?.Guest?.FromDate?.date}-${payload?.item.Booking?.Guest?.FromDate?.month}-${payload?.item.Booking?.Guest?.FromDate?.year}`;
    let checkoutDate = `${payload?.item?.Booking?.Guest?.ToDate?.date}-${payload?.item?.Booking?.Guest?.ToDate?.month}-${payload?.item?.Booking?.Guest?.ToDate?.year}`;
    const keyList = GTMData.getKeyList(true);
    let itemList = {};
    try {
      const itemListName = sessionStorage.getItem('GA_DaycationCategory');
      const packageType = sessionStorage.getItem('GA_PackageType');
      const categoryType = sessionStorage.getItem('GA_Category');
      const gaMainCategory = getSessionStorage('GA_MainCategory') || getMainStayCategory(parseQueryString('ctype'));
      const getTripDuration = (datefrom, dateto) => {
        const _dateFrom = moment(datefrom, UIConfig.dateFormats.YYYY_M_D);
        const _dateTo = moment(dateto, UIConfig.dateFormats.YYYY_M_D);
        var diffDays = _dateTo.diff(_dateFrom, 'days');

        return diffDays;
      };
      // const nights = getTripDuration(payload?.fromDate, payload?.toDate);
      if (isMatchTenant(UIConfig.tenants.yi)) {
        itemList[keyList.affiliation] = 'Yas Island';
        itemList[keyList.checkinDate] = checkinDate; //
        itemList[keyList.checkoutDate] = checkoutDate; //
        itemList[keyList.value] = payload?.item?.Booking?.Totals?.TotalAmount || ''; //
        // itemList[keyList.coupon] =
        //   (payload?.item?.Booking?.BookingPromotionDetails[0] !== null &&
        //     payload?.item?.Booking?.BookingPromotionDetails[0]?.PromoCode) ||
        //   'N/A'; //
        itemList[keyList.discount] = payload?.item?.Booking?.Totals?.PromoCodeAmount?.toString() || 'N/A'; //
        itemList[keyList.itemId] =
          payload?.item?.Booking?.Hotel?.ContentNode?.Fields[6]?.value ||
          payload?.item?.Booking?.Product?.ContractId ||
          ''; //
        itemList[keyList.itemName] = payload?.item?.Booking?.Hotel?.ContentNode?.Fields[0]?.value || ''; //
        itemList[keyList.nosadult] = payload?.item?.Booking?.Hotel?.Rooms[0]?.NoOfAdults || 0; //
        itemList[keyList.noschild] = payload?.item?.Booking?.Hotel?.Rooms[0]?.NoOfChilds || 0; //
        itemList[keyList.numberOfRooms] = payload?.item?.Booking?.Guest?.NoOfRooms || ''; //
        itemList[keyList.roomSelected] = payload?.item?.Booking?.Hotel?.Rooms[0]?.GA_RoomType || ''; //
        itemList[keyList.totalPaxCount] = payload?.item?.Booking?.Guest?.NoOfGuests || ''; //
        itemList[keyList.quantity] = 1; //
        itemList[keyList.price] = payload?.item?.Booking?.Totals?.TotalAmount || ''; //
        itemList[keyList.tax] = payload?.item?.Booking?.Totals?.TaxAmount || ''; //
        itemList[keyList.transactionId] = payload?.item?.BookingId || ''; //
      }
      this.data.event = keyList.refund;
      this.data.ecommerce = {
        [keyList.items]: [itemList],
        currency: payload?.currencyCode,
        [keyList.transactionId]: payload?.item?.BookingId || '',
        [keyList.value]: payload?.item?.Booking?.Totals?.TotalAmount || '',
        [keyList.tax]: payload?.item?.Booking?.Totals?.TaxAmount || '',
      };
    } catch (error) {
      console.log('GA4 refundData error:', error);
    }
  };

  static getUserData = () => {
    let language = getCurrentLanguage();
    const loggedInUser = getLoginUser();
    const userId = loggedInUser.yasId;
    const loginStatus = isLoggedInUser();
    return { language, loginStatus, userId };
  };

  static getTenantId = () => {
    return getTenantId();
  };

  static addToWalletData = ({ productDetails }, isGtm4) => {
    const productData = GTMData.getWalletData(productDetails, this.tenantId);

    this.data.event = `addToWallet ${deviceDetection()}`;
    this.data.emailId = getEmailIdGTMData();
    this.data.ecommerce = { add: { products: productData } };
  };

  static clickFooterData = (data) => {
    const keyList = GTMData.getKeyList(true);

    if (checkGA4Tenants()) {
      this.data = {
        event: keyList.clickFooter,
        [keyList.menuCategory2]:
          typeof data?.data === 'object' && data?.data?.alt !== undefined ? data?.data?.alt : data?.data || undefined,
        [keyList.menuCategory]: data?.sectionName || undefined,
      };
    }
  };

  static clickHeaderData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);

      if (checkGA4Tenants([], [UIConfig.tenants.ya])) {
        if (payload?.menuCategory2 == '') {
          this.data = {
            event: keyList.clickHeader,
            [keyList.menuCategory2]: undefined,
            [keyList.menuCategory]: payload?.menuCategory || undefined,
          };
        } else {
          this.data = {
            event: keyList.clickHeader,
            [keyList.menuCategory]: payload?.menuCategory2 || undefined,
            [keyList.menuCategory2]: payload?.menuCategory || undefined,
          };
        }
      }
    } catch (err) {
      console.log('GTM4 Click header error:', err);
    }
  };

  static supplierRegistrationData = () => {
    try {
      const keyList = GTMData.getKeyList(true);
      this.data = { event: keyList.supplierRegistration };
    } catch (err) {
      console.log('GTM4 supplierRegistration error:', err);
    }
  };

  static websiteSearchData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);
      this.data = { ...payload, event: keyList.websiteSearch };
    } catch (err) {
      console.log('GTM4 Click header error:', err);
    }
  };

  static partnerLinksData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);
      this.data = { ...payload, event: keyList.partnerLinks };
    } catch (err) {
      console.log('GTM4 Click header error:', err);
    }
  };

  static subscribeData = () => {
    try {
      if (checkGA4Tenants()) {
        this.data = { event: 'subscribe' };
      }
    } catch (err) {
      console.log('GTM4 Error for Subscribe data:', err);
    }
  };

  static socialLinksData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);
      this.data = { ...payload, event: keyList.socialLinks };
    } catch (err) {
      console.log('GTM4 Click header error:', err);
    }
  };
  static buyTicketsData = (payload) => {
    try {
      const keyList = GTMData.getKeyList(true);
      this.data = { ...payload, event: keyList.buyTickets };
    } catch (err) {
      console.log('GTM4 Click header error:', err);
    }
  };

  static removeFromCartData = (data) => {
    try {
      const keyList = GTMData.getKeyList(true);
      if (isMatchTenant(UIConfig.tenants.ya)) {
        let payload = {
          ecommerce: {
            items: data?.map((item) => ({
              item_id: item?.productId,
              item_name: item?.productName,
              affiliation: 'etihadarena',
              item_category: item?.itemType,
              item_date: item?.fromDate,
              item_variant: item?.timeSlot,
              quantity: item?.quantity,
              price: item?.gross,
              currency: item?.currency,
              index: localStorage.getItem('EAGA4Index'),
              discount: item?.discount?.computed,
              item_list_id: localStorage.getItem('EAGA4ItemListId'),
              item_list_name: localStorage.getItem('EAGA4ItemListId'),
            })),
          },
        };
        this.data = { ...payload, event: keyList.removeFromCart };
      }
    } catch (err) {
      console.log('GTM4 Remove from cart error:', err);
    }
  };

  static addToCartAdonsData = (data) => {
    try {
      let payload = {};
      const keyList = GTMData.getKeyList(true);
      if (isMatchTenant(UIConfig.tenants.ya)) {
        payload = {
          ecommerce: {
            items: [
              {
                item_id: data?.productId,
                item_name: data?.productName,
                affiliation: 'etihadarena',
                item_category: data?.itemType,
                item_date: data?.fromDate,
                item_variant: data?.timeSlot,
                quantity: data?.quantity,
                price: data?.gross,
                currency: data?.currency,
                index: JSON.parse(localStorage.getItem('EAGa4Storage'))?.index,
                item_list_id: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
                item_list_name: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
              },
            ],
          },
        };
      }
      this.data = { ...payload, event: keyList.addToCartAdons };
    } catch (err) {
      console.log('GTM4 Click header error:', err);
    }
  };

  static getAllCartProducts = (cartData = {}) => {
    const cartState = cartData?.cart;
    const cartProductArr = [];
    if (cartState && cartState.totalQuantity !== '0' && Object.keys(cartState.items).length) {
      Object.keys(cartState.items).forEach((cartProd) => {
        let filterProd = cartState.items[cartProd].products;
        if (filterProd && filterProd.length) {
          cartProductArr.push(filterProd[0]);
        }
      });
    }
    return cartProductArr;
  };

  static widgetSearchData = (data) => {
    const keyList = GTMData.getKeyList(true);
    let itemList = {};

    if (isMatchTenant(UIConfig?.tenants?.yi)) {
      itemList[keyList.journeyType] = data?.journeyType;
      itemList[keyList.checkinDate] = data?.checkindate;
      itemList[keyList.checkoutDate] = data?.checkoutdate;
      itemList[keyList.nosadult] = data?.numberOfAdult;
      itemList[keyList.noschild] = data?.numberOfChildrens;
      itemList[keyList.numberOfNights] = data?.nights;
      itemList[keyList.numberOfRooms] = data?.numberOfRooms;
      itemList[keyList.totalPaxCount] = data?.totalPaxCount;
    }
    this.data = {
      event: window?.location?.href?.includes('listing') ? keyList.widgetSearchUpdate : keyList.widgetSearch,
      ...itemList,
    };
  };
  static addShippingInfoData = (data) => {
    try {
      let payload = {};
      const keyList = GTMData.getKeyList(true);
      const cartData = getAnonymousCart();
      if (isMatchTenant(UIConfig.tenants.ya) && cartData?.cart?.items?.length) {
        payload = {
          ecommerce: {
            currency: cartData?.cart?.currency,
            value: cartData?.cart?.grossPrice,
            shipping_tier: data?.tier,
            items: cartData?.cart?.items?.map((item) => ({
              item_id: item?.productId,
              item_name: item?.productName,
              affiliation: 'etihadarena',
              index: JSON.parse(localStorage.getItem('EAGa4Storage'))?.index,
              item_list_id: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
              item_list_name: JSON.parse(localStorage.getItem('EAGa4Storage'))?.list_item_id,
              item_date: item?.fromDate,
              quantity: item?.quantity,
              price: item?.gross,
            })),
          },
        };
        this.data = { ...payload, event: keyList.addShippingInfo };
      }
    } catch (err) {
      console.log('GTM4 Add shipping Info error:', err);
    }
  };

  static loginData = (data) => {
    this.data = {
      event: 'login',
      login_status: data?.login_status,
      user_id: data?.user_id || undefined,
      user_type: data?.user_type,
    };
  };

  static signUpData = (data) => {
    this.data = data;
  };

  static initalizeData = (event, data, isMiniCart, isExpressCheckout, isTicketUpgrade) => {
    this.tenantId = GTMData.getTenantId();
    this.cartAction = new CartActions({ moduleName: 'GTMData', tenantId: this.tenantId });
    this.data = this.tenantId !== UIConfig.ymcB2CTenant ? { language: getCurrentLanguage() } : {};
    const method = event + 'Data';
    // temporary for preventing duplicacy of event firing , remove this check once all done.
    const anonymousCart = getAnonymousCart();
    const isUpgradeJourney = anonymousCart?.cart?.isTicketUpgradeCart || isTicketUpgrade;

    try {
      if (
        (checkParksTenants() || isMatchTenant(UIConfig.tenants.yi) || isMatchTenant(UIConfig.tenants.ya)) &&
        [...UIConfig.ga4Events.new, ...UIConfig.ga4Events.common].includes(event)
      ) {
        const myPassProp = checkParksTenants() ? 'my_pass_id' : 'myPassId';
        this.data = {};
        GTMData[method](data, true, isMiniCart);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          ...this.data,
          ...(!isExpressCheckout && { [myPassProp]: getYasIdGTMData() || undefined }),
          upgrade: isUpgradeJourney ? 'True' : 'False',
        });
      }
      GTMData[method](data);
    } catch (err) {
      console.log(err);

      return err;
    }
  };
  static addTowishListYmcData = (wishListData) => {
    const { products, eventName } = wishListData;
    const category =
      Array.isArray(products.category) && products.category.length ? products.category[0] : products.category;
    const subCategory = products.tab || products.productType;
    const wishListKey = eventName === 'addTowishlist' ? 'addwish' : 'removewish';
    const result = {
      id: products.productId,
      name: products.gaTitle || products.title,
      category: `${category} / ${subCategory}`,
      price: parseFloat(products.gross) || 0,
      quantity: products.quantity || 1,
    };
    this.data = {
      event: eventName,
      ecommerce: { [wishListKey]: { products: result, currencyCode: UIConfig.currencyArabic } },
    };
  };

  static push = (event, data = {}, isMiniCart = false, isExpressCheckout = false, isTicketUpgrade = false) => {
    try {
      GTMData.initalizeData(event, data, isMiniCart, isExpressCheckout, isTicketUpgrade);
    } catch (error) {
      console.log('GA Error: ', error);
    }
    window.dataLayer = window.dataLayer || [];

    let dataToPush = { ...this.data, myPassId: getYasIdGTMData() || undefined };
    if (checkTenant(UIConfig.yasArenaB2CTenant) && event === 'checkout') {
      dataToPush = dataLayerCheckoutConvert(dataToPush);
    }
    if (!UIConfig.ga4Events.new.includes(event)) {
      window.dataLayer.push(dataToPush);
    }
  };
  static updatEventId = (eventId, status) => {
    const updateDataLayer =
      window.dataLayer &&
      window.dataLayer.map((data) => {
        if (data.siteType === 'B2C') {
          data.eventId = eventId;
          if (status) {
            data.paymentStatus = 'success';
          }
        }
        return data;
      });
    window.dataLayer = updateDataLayer;
  };
  static getCategoryNameFromEventType = (eventType) => {
    eventType = eventType && eventType.toLowerCase();
    let category = '';
    switch (eventType) {
      case 'f1':
        category = 'Forumla 1';
        break;
      case 'wrx':
        category = 'World Rally Cross';
        break;
      default:
        category = 'Experiences';
        break;
    }

    return category;
  };
}

export default GTMData;
