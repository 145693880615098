/*
 * svg-sprite-component.js
 * This file contains code for SVG which will render the required SVG from the Sprite.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import svgSizes from '../../../../common/svgsizeconfig';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * SvgSprite  Generates the required SVG element
 * @param    {id, aspectRatio, viewBox, width, height, noSmile}
 * @return   {[Object]} JSX of SVG
 */
const SvgSprite = (props) => {
  try {
    if (props.noCurve) {
      return null;
    }

    const svg = (
      <svg
        aria-hidden={props.ariaHidden}
        focusable={props.focusableAttribute}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox={svgSizes[props.id]}
        preserveAspectRatio={props.preserveAspectRatio}
        width={props.width}
        height={props.height}
        className={`${props.styleClass} ${props.id}`}
      >
        <title>{props.cssClass ? addTitle(props.cssClass) : addTitle(props.id)}</title>
        <use xlinkHref={'#' + props.id} className={props.cssClass ? props.cssClass : 'svg-use'}></use>
      </svg>
    );

    return svg;
  } catch (err) {
    return logComponentRenderingError(err, 'SvgSprite');
  }
};

const addTitle = (str) => {
  return str.replace(/(ic.*n)|(-)|(b2c)/g, '') + ' icon';
};
// Specifies the default values for props:
SvgSprite.defaultProps = {
  preserveAspectRatio: 'none',
  styleClass: 'icon',
  ariaHidden: true,
  focusableAttribute: false,
};

SvgSprite.propTypes = {
  id: PropTypes.string.isRequired,
  preserveAspectRatio: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  noCurve: PropTypes.bool,
  cssClass: PropTypes.string,
  customClass: PropTypes.string,
};

export default SvgSprite;
