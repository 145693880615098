import UIConfig from '../UIConfig';
import {
  canUseDOM,
  getLoggedInUser,
  getTouchPointsPurchaseInfo,
  getPointsPayPurchaseInfo,
  getAnonymousCart,
  resolvePath,
  getLocalStorageByKey,
  getMoneyCardPurchaseInfo,
  isEmpty,
  checkShippingDetails,
} from '../utility';
import { CartService } from '../services';
import { Logging } from '../logger';
import { isLoggedInUser } from '../../common/utility';
import GTMData from '../../components/container/b2c-purchase-journey/gtm-data';

export const guestFormError = (data, checkBoxEnabled) => {
  const anonymousCart = getAnonymousCart();
  const reservationOwner = resolvePath(anonymousCart, 'cart.reservationOwner', null);
  const isEmailError = getLocalStorageByKey('isEmailError');
  const isBtnDisabled = isEmailError === false ? true : false;
  if (
    !reservationOwner ||
    data.isError ||
    !checkBoxEnabled ||
    document.getElementsByClassName('terms-error-msg').length > 0 ||
    isBtnDisabled ||
    document.querySelectorAll('.yasid-email-error').length > 0
  ) {
    return true;
  }
  return false;
};

export const getOnlyGuestFormError = (data) => {
  const { reservationOwner } = JSON.parse(localStorage.getItem('anonymousCart')).cart; // guest form details
  if (!reservationOwner || data.isError) {
    return true;
  }
  return false;
};

export const clickToActionGuestForm = () => {
  const submitBtnEle = document.getElementById('submit-btn');
  submitBtnEle && submitBtnEle.click();
};

export const shouldProceedToPay = ({
  checkboxEnabled,
  tncErrorFunc,
  guestFormErrorFunc,
  guestErrorOnChangeState,
  submitGuestForm = true,
  cartData,
}) => {
  let proceedToPay = true;
  const tenantID = getLoggedInUser().tenantID;

  const errorDom = document.getElementsByClassName('terms-error-msg'); //error dom if t&c not checked

  //if tnc checkbox is not clicked
  if (!checkboxEnabled) {
    tncErrorFunc(true);
    proceedToPay = false;
  }
  // don't proceed with payment, if user Guest Details are not filled
  if (!isLoggedInUser()) {
    if (checkboxEnabled && !guestFormErrorFunc()) {
      submitGuestForm && clickToActionGuestForm();
    } else if (guestFormErrorFunc()) {
      submitGuestForm && clickToActionGuestForm();
      proceedToPay = false;
    }
    if (guestErrorOnChangeState || errorDom.length > 0) {
      proceedToPay = false;
    }
  }
  if (tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
    const isShippingRequired = checkShippingDetails(cartData);
    if (isShippingRequired) {
      proceedToPay = false;
    }
  }
  if (proceedToPay) {
    GTMData.getTenantId() === UIConfig.ymcB2CTenant &&
      GTMData.push('checkout', { step: UIConfig.gtmStepFive, products: cartData.items, subTotal: cartData.grossPrice });
  }
  return proceedToPay;
};

export const validateInputFieldNum = (e, isDotRequired = false) => {
  const code = e.keyCode;
  if (isDotRequired && code === 46) {
    //Check if the text already contains the . character
    if (e.target.value.indexOf('.') === -1) {
      return true;
    } else {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  } else {
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    return true;
  }
};

export const checkDigit = (e, isKeyCodeRequired) => {
  const code = e.keyCode;

  // 35:end, 36:home,46:delete, 8:backspace,191:forward slash, 9:tab
  const modifierKeys = [35, 36, 46, 8, 191, 9];

  if ((code >= 48 && code <= 57) || (code >= 96 && code <= 105) || modifierKeys.indexOf(code) !== -1) {
    if (isKeyCodeRequired) {
      return { keyCode: code, isCheckDigit: true };
    }
    return true;
  }

  e.preventDefault();
  e.stopPropagation();
};
const handleCardImage = (value, cardTypes = []) => {
  cardTypes.forEach((item) => {
    let regExpression = new RegExp(item.regexValue);
    if (regExpression.test(value)) {
      document.getElementById('cardNumber').style.backgroundImage = `url(${item.cardIcon.src})`;
      document.getElementById('CVV').setAttribute('maxlength', item.cVVLength);
    }
  });
};

export const handleCardNumberPaste = (e, cardTypes) => {
  let pastedValue = (e.clipboardData || window.clipboardData).getData('text');
  handleCardImage(pastedValue, cardTypes);
};

const cardNumberFormatter = (value, cardTypes, paymentCards) => {
  if (value.length === 4) {
    handleCardImage(value, cardTypes);
  } else if (value.length < 4) {
    document.getElementById('cardNumber').style.backgroundImage = '';
  }

  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};

export const cvvInputInitializer = () => {
  document.getElementById('CVV').addEventListener('keypress', (e) => {
    return checkDigit(e);
  });
};

export const cardInputInitializer = (cardTypes, paymentCards) => {
  const cardNumberInput = document.getElementById('cardNumber');
  if (cardNumberInput) {
    cardNumberInput.oninput = (e) => {
      e.target.value = cardNumberFormatter(e.target.value, cardTypes, paymentCards);
    };
    cardNumberInput.addEventListener('keypress', (e) => {
      return checkDigit(e);
    });
  }
};
const addSubstring = (idx, rem, str, subStr) => {
  return str.slice(0, idx) + subStr + str.slice(idx + Math.abs(rem));
};

export const expiryInputInitializer = () => {
  let keyCode = null;
  document.getElementById('validity').oninput = (e) => {
    if (e.target.value.length === 1 && e.target.value > 1 && e.target.value <= 9) {
      e.target.value = `0${e.target.value}`;
    }

    if (e.target.value.length === 2 && keyCode !== 8) {
      if (/^(0?[1-9]|1[012])$/.test(e.target.value)) {
        e.target.value = `${e.target.value}/`;
      } else {
        e.target.value = e.target.value.split('')[0];
      }
    }
    if (e.target.value.length === 3 && e.target.value.indexOf('/') <= -1 && keyCode !== 8) {
      e.target.value = addSubstring(2, 0, e.target.value, '/');
    }
  };
  document.getElementById('validity').addEventListener('keydown', (e) => {
    const checkObj = checkDigit(e, true);
    keyCode = checkObj && checkObj.keyCode;
    return checkObj && checkObj.isCheckDigit;
  });
};

export const rsaEncryption = (textToEncrypt) => {
  if (canUseDOM()) {
    if (window.JSEncrypt) {
      const encrypt = new window.JSEncrypt();
      encrypt.setPublicKey(UIConfig.rsaEncKeys.public);
      return encrypt.encrypt(textToEncrypt);
    }
    return '';
  }
};

// validaity in format YYMM
export const getValidityFormatted = (validity) => {
  return validity
    .split('/')
    .reverse()
    .join('');
};

export const cartCall = (cartServiceURL, cartData, isRenewAnnualPass, paymentType) => {
  const loggedInUserData = getLoggedInUser(),
    userData = {
      userEmail: loggedInUserData && loggedInUserData.email,
      firstName: loggedInUserData && loggedInUserData.userName,
    };

  if (isRenewAnnualPass && cartData && cartData.cart) {
    let dummyCart = {
      cart: {
        tenantId: cartData.cart.tenantId,
        items: [],
        commission: null,
        subTotal: null,
        totalQuantity: '',
        netPrice: 0,
        currency: 'AED',
        marketType: '',
        agentReferenceNumber: '',
        date: '',
        payfortAmount: 0,
        saleAk: '',
        totalDiscount: '0',
        grossPrice: 0,
        totalTax: 0,
        couponCode: '',
        userLang: 'en',
        reservationOwner: null,
        promotions: null,
        shippingAddress: null,
        billingAddress: null,
        shippingCharge: 0,
        shippingChargeNetPrice: 0,
      },
    };
    cartData = dummyCart;
  }

  cartData.cart.hasOwnProperty('touchpointPayOption') && delete cartData.cart.touchpointPayOption;
  cartData.cart.hasOwnProperty('pointsPayCategory') && delete cartData.cart.pointsPayCategory;
  cartData.cart.hasOwnProperty('moneyCardPayOption') && delete cartData.cart.moneyCardPayOption;

  if (paymentType === UIConfig.paymentOption.adcbTouchPoints) {
    const touchPointsCardInfo = getTouchPointsPurchaseInfo();

    if (touchPointsCardInfo && touchPointsCardInfo.redeemAmount > 0) {
      cartData.cart.touchpointPayOption = {
        cardNo: touchPointsCardInfo.cardNum,
        cardExpiry: touchPointsCardInfo.expiry,
        custName: userData.firstName,
        paymentType: touchPointsCardInfo.paymentType,
        redeemAmount: touchPointsCardInfo.redeemAmount,
        cardAmount: touchPointsCardInfo.cardAmount,
      };
    }
  } else if (paymentType === UIConfig.paymentOption.pointsPay) {
    const pointsPayCategoryInfo = getPointsPayPurchaseInfo();

    if (pointsPayCategoryInfo) {
      cartData.cart.pointsPayCategory = pointsPayCategoryInfo;
    }
  } else if (paymentType === UIConfig.paymentOption.moneyCard) {
    const moneyCardInfo = getMoneyCardPurchaseInfo();

    if (moneyCardInfo && !isEmpty(moneyCardInfo)) {
      cartData.cart.moneyCardPayOption = {
        //cardNo: moneyCardInfo.cardNum,
        //cardExpiry: moneyCardInfo.expiry,
        custName: userData.firstName,
        paymentType: moneyCardInfo.paymentType,
        redeemAmount: moneyCardInfo.redeemAmount,
        cardAmount: moneyCardInfo.cardAmount,
      };
    }
  }

  if (cartData) {
    let serviceURL = cartServiceURL + '?uid=' + userData.userEmail,
      hideLoader = false;
    const idToken = loggedInUserData?.idToken || '';

    return new Promise((resolve, reject) => {
      CartService.updateGuestCart(cartData, 'loggedInUserCheckoutForm', serviceURL, hideLoader, idToken)
        .then((res) => {
          Logging(res, 'loggedInUserCheckoutForm', true, 'Cart Details posted');
          resolve(cartData);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }
};
