import { getB2CProductDetails } from '../../../../common/coveo-api';
import { RecommendationService } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import { createMappedData, getFallbackLanguage, getLoggedInUser } from '../../../../common/utility';

/**
 *  Class - Responsible for managing Yas Arena Cart
 */
export default class YaAddOnsService {
  /** constructor */
  constructor(data) {
    this.data = data;
    this._tenantId = getLoggedInUser().tenantID;
    this.coveoDefaultParams = {
      perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
      coveoKeyMap: this.data.coveoMappingList,
      serviceUrl: this.data.services.getCoveoProducts.url,
      fieldsToInclude: Object.values(this.data.coveoMappingList),
      lang: getFallbackLanguage(),
      sortField: this.data.coveoMappingList.sortField,
      sortCriteria: this.data.coveoMappingList.sortCriteria
        ? this.data.coveoMappingList.sortCriteria.replace(/[0-9]/g, '')
        : 'fieldascending',
    };
  }

  getRecommendedProducts = ({ cartItems, preLoaderTarget = UIConfig.loader.defaultPreLoaderTarget }) => {
    this.preLoaderTarget = preLoaderTarget;
    return new Promise((resolve, reject) => {
      this.checkSuggestedItems(cartItems)
        .then((response) => {
          const { PRODUCTGROUP } = response.data;
          if (PRODUCTGROUP.length > 0) {
            return this.getCoveoProductsMapping(PRODUCTGROUP);
          }
        })
        .then((response) => {
          const mappedProducts = createMappedData(response.results, this.data.coveoMappingList);
          resolve({ mappedProducts });
        });
    });
  };

  /**Get Add Ons Coveo product mapping */

  getCoveoProductsMapping = (products) => {
    const productIds = products.map((item) => {
      return item.PRODUCTID.map((id) => {
        return id;
      });
    });
    const params = [
      {
        key: this.data.coveoMappingList.productId,
        value: productIds,
      },
    ];
    return new Promise((resolve, reject) => {
      getB2CProductDetails({
        ...this.coveoDefaultParams,
        queryParams: params,
        preLoaderTarget: this.preLoaderTarget,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  checkSuggestedItems = (cartItems = []) => {
    const url = this.data.services.recommendedProducts.url;
    let cartProducts = [];
    cartItems.forEach((ci) => {
      if (ci.products) {
        ci.products.forEach((p) => cartProducts.push(p));
      }
      //for payment page
      if (ci.productId) {
        cartProducts.push(ci);
      }
      //
    });

    const productArray = cartProducts.map((value) => ({ productId: value.productId, quantity: value.quantity }));

    const requestData = { checkSuggestiveSell: productArray };
    return new Promise((resolve, reject) => {
      RecommendationService.getFilterProducts(url, 'Purchase-Journey', requestData, true, this.preLoaderTarget)
        .then((res) => {
          if (res.data) {
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //Method To check if add Ons In cart
  checkIfAddOnsInCart = (cartItems) => {
    for (let item of cartItems) {
      if (item.crossSellType) {
        return true;
      }
    }
  };
}
