import React, { useState, useEffect } from 'react';
import {
  getCurrentLanguage,
  getLoggedInUser,
  getLoginUser,
  getTenantId,
  checkParksTenants,
  isEmailVerificationEnabled,
} from '../../../common/utility';
import ApiWrapper from '../../../common/api-wrapper.js';
import { AnchorLink, DynamicContent } from '../../presentation/base';
import UIConfig from '../../../common/UIConfig';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useCountdownTimer } from '../../../hooks/useCountdownTimer.js';
import { withRouter } from 'react-router-dom';
import './email-verification.scss';

function EmailVerification(data) {
  const { email, idToken, userName } = getLoggedInUser();
  const [emailSent, setEmailSent] = useState(false);
  const [emailTriggered, setEmailTriggered] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { time, startTimer } = useCountdownTimer({
    isRunningKey: 'isEmailVerifRunning',
    countdownTimerKey: 'emailVerifCountdown',
  });
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isParks, setIsParks] = useState(false);
  const [isEmailVerifEnabled, setIsEmailVerifEnabled] = useState(false);

  const [errorCode, setErrorCode] = useState(null);
  const [errorMsgMap, setErrorMsgMap] = useState({});

  const {
    Image,
    Title,
    SubTitle,
    Description1,
    Description2,
    CTALabel,
    SuccessMessage,
    ResendEmailHelpText,
    HelpText,
    ContactSupport,
    CountDownTimer,
    EmailValidationUrl,
    EmailValidationErrors,
  } = data;

  useEffect(() => {
    setIsEmailVerifEnabled(isEmailVerificationEnabled());
    setIsUserLoggedIn(Object.keys(getLoginUser()).length > 0);
    setIsParks(checkParksTenants());
    let errorMessages = EmailValidationErrors?.value?.split('|') || [];
    const x = errorMessages?.forEach((errorConfig) => {
      const [code, msg] = errorConfig?.split(':') || [];
      if (code && msg) {
        let updatedMap = Object.assign(errorMsgMap);
        updatedMap[code] = msg;
        setErrorMsgMap(updatedMap);
      }
    });

    setEmailSent(localStorage.getItem(UIConfig.localStoreKeys.emailVerification.emailSent));
    setEmailTriggered(localStorage.getItem(UIConfig.localStoreKeys.emailVerification.emailTriggered));
    setIsLoading(true);
    window.PubSub.subscribe(UIConfig.events.EMAIL_VERIFIED, (msg, newData) => {
      setIsEmailVerified(newData);
      setIsLoading(false);
    });
    return () => {
      window.PubSub.unsubscribe(UIConfig.events.EMAIL_VERIFIED);
      localStorage.removeItem(UIConfig.localStoreKeys.emailVerification.emailTriggered);
    };
  }, []);

  useEffect(() => {
    if (time === 0) {
      setEmailSent(false);
      localStorage.removeItem(UIConfig.localStoreKeys.emailVerification.emailSent);
    }
  }, [time]);

  function sendEmail() {
    const tenantId = getTenantId().toUpperCase();
    const currentLanguage = getCurrentLanguage();
    const language = currentLanguage?.toLowerCase() === 'ar-ae' ? 'ar' : currentLanguage;
    const apiUrl = EmailValidationUrl.value;
    const config = {
      url: apiUrl,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + idToken,
      },
      data: JSON.stringify({
        email,
        source: 'website',
        tenant: tenantId,
        language,
        userName,
      }),
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    };
    ApiWrapper.api(config)
      .then((response) => {
        if (response.status === 200) {
          setEmailSent(true);
          setEmailTriggered(true);
          localStorage.setItem(UIConfig.localStoreKeys.emailVerification.emailSent, true);
          localStorage.setItem(UIConfig.localStoreKeys.emailVerification.emailTriggered, true);
          startTimer(Number(CountDownTimer.value) * 60);
        }
      })
      .catch(({ error }) => {
        if (error.code === 400 || error.code === 500) {
          setErrorCode(error.code);
        }
      });
  }

  const getProcessedEmailText = () => {
    let formatedEmailText = '';
    if (SubTitle?.value) {
      formatedEmailText = SubTitle?.value?.replace('{email}', email);
    }
    return formatedEmailText;
  };

  if (isEmailVerifEnabled && isUserLoggedIn && !isLoading && !isEmailVerified) {
    return (
      <div className="email-not-verified-parent w--content">
        <div className="account-not-verified">
          <div
            className={
              isParks
                ? 'account-not-verified__image-title-wrapper-row'
                : 'account-not-verified__image-title-wrapper-col'
            }
          >
            <div className="account-not-verified__image-block">
              <img src={Image.value.src} />
            </div>
            <div className="account-not-verified__title-block">{Title.value}</div>
          </div>
          <div className="account-not-verified__email-header">
            {SubTitle?.value && email && (
              <Text
                tag="span"
                field={{
                  value: getProcessedEmailText(),
                  editable: getProcessedEmailText(),
                }}
              />
            )}
          </div>
          <div className="account-not-verified__email-help-text-1">{Description1.value}</div>
          {/* <div className="account-not-verified__email-help-text-2">{Description2.value}</div> */}
          {!emailSent && (
            <div className="account-not-verified__primary-cta btn-primary-tenant">
              <AnchorLink
                link={{
                  label: emailTriggered ? Description2.value : CTALabel.value,
                  href: '',
                }}
                onClick={(event) => {
                  event.preventDefault();
                  sendEmail();
                }}
              />
            </div>
          )}
          {emailSent && (
            <>
              <div className="account-not-verified__email-sent">
                <div className="account-not-verified__email-sent__action">
                  <div className="account-not-verified__email-sent__action__text">{SuccessMessage.value}</div>
                  <div className="account-not-verified__email-sent__action__icon"></div>
                </div>
                <div className="account-not-verified__email-sent__help-text">
                  {ResendEmailHelpText.value.replace(
                    '{timer}',
                    `${Math.floor(time / 60)}:${(time % 60).toString().padStart(2, '0')}`,
                  )}
                </div>
              </div>
            </>
          )}
          {errorCode && (
            <div className="email-error-msg-block">
              <div className="error-icon"></div>
              <div className="error-msg">{errorMsgMap[errorCode]}</div>
              <div
                className="error-close-icon"
                onClick={() => {
                  setErrorCode(null);
                }}
              ></div>
            </div>
          )}
          <div className="account-not-verified__footer">
            <span className="account-not-verified__footer__help-text">{HelpText.value}</span>
            <div className="account-not-verified__footer__contact-support">
              <DynamicContent
                tagName="span"
                attrs={{ className: 'secondary-btn' }}
                innerHtml={ContactSupport.value.toLowerCase()}
              />
              <div className="account-not-verified__footer__contact-support__navigate-icon"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
export default withRouter(EmailVerification);
