import React, { useEffect, useState } from 'react';
import { setCookie, getCookie } from '../../../common/utility';
import DropDown from '../drop-down';
import { logComponentRenderingError } from '../../../common/logger';
import '../language-switcher/language-switcher.scss';

/**
 * LanguageSwitcher dumb Component helps enabling localization.
 * functionality of switching between different languages
 * @param    {props} Object as defined from data-contract.
 * @return   {DropDown} List of available langugaes
 */

const CurrencySwitcher = ({ data }) => {
  const { labels, currencies, disclaimerTitle, disclaimerDescription } = data;
  const currenciesList = currencies.map((currency) => currency.currencyCode.toLowerCase());

  try {
    useEffect(() => {
      if (getCookie('currencyCode')) {
        setCookie('currencyCode', getCookie('currencyCode'));
      } else {
        setCookie('currencyCode', labels.currencyCode);
      }
    }, []);

    const callBackFunction = (selectedLangObj) => {
      window.location.reload();
      setCookie('currencyCode', selectedLangObj.currencyCode);
    };

    currencies.forEach(function(obj) {
      obj.value = obj.currencyCode;
      obj.text = obj.currencyName;
    });

    return (
      <div className="currency-switcher">
        {currencies && currencies.length && currencies.length !== 1 ? (
          <DropDown
            callBackFunction={callBackFunction}
            listItems={currencies}
            defaultValue={labels && labels.currencyCode}
            callBackParameters={{ defaultCurrencyObj: currencies[0], currenciesList }}
            showSelectedValue="true"
            firstTimeLoad="true"
            lang="true"
            disclaimerText={{ desclaimerTitle: disclaimerTitle, desclaimerDescription: disclaimerDescription }}
          />
        ) : (
          <div className="selected-option currency-switcher--currency-label">{labels.currencyCode}</div>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CurrencyToggle');
  }
};

export default CurrencySwitcher;
