/*
 * mega-nav-component.js
 * This file contains code for Mega Nav component, it renders 1 RTE ,links array and image array
 * @author    Sunil Sharma, SapientNitro <ssharma193@sapient.com>
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';

import { AnchorLink, Image, RichText } from '../base';
import { logComponentRenderingError } from '../../../common/logger';
import './mega-nav-component.scss';

import { subHeaderClickAnalytics } from '../../../common/analytics-events';

/**
 * MegaNav is a component which will be rendered by Nav component, it expects its props from Nav component
 * @param    {object}    data     having data of Mega Nav
 */

const googleAnalyticSubHeaderHandleClick = (event, data) => {
  subHeaderClickAnalytics(event, data);
};

const MegaMobileNav = ({ data, className, enableMobileNav }) => {
  try {
    return (
      <div className={'meganav meganav-' + className}>
        <div className="meganav--main-wrapper">
          <div className="meganav--text-wrapper">
            {data.bodyCopy && (
              <RichText data={{ bodyCopy: data.bodyCopy, attrs: { className: 'meganav--text-wrapper-bodycopy' } }} />
            )}
            <ul className="meganav--text-wrapper-list">
              {data?.navLinks?.length !== 0 &&
                data.navLinks.map((item, idx) => {
                  return (
                    item.linkData?.length !== 0 &&
                    item.linkData?.map((link) => {
                      const { ctaDetail } = link;
                      return (
                        <li key={idx} className="meganav--text-wrapper-list-item">
                          <AnchorLink
                            link={{
                              href: ctaDetail.href,
                              label: ctaDetail.label,
                              target: ctaDetail.target,
                            }}
                            onClick={(e) => googleAnalyticSubHeaderHandleClick(e, link.label)}
                          />
                        </li>
                      );
                    })
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'MegaMobileNav');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
MegaMobileNav.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MegaMobileNav;
