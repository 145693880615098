import classNames from 'classnames';
import React, { Component } from 'react';
import { Logging } from '../../../../common/logger';
import Button from '../../../presentation/base/button/button-component.js';
import { AliPayService, CartService } from '../../../../common/services';
import {
  getLoggedInUser,
  toTwoDecimalPlaces,
  roundToTwoPlaces,
  getLocalStorageByKey,
  isLoggedInUser,
  getRecipientDetails,
  mycartError,
  checkPaymentType,
  checkParksTenants,
} from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { guestFormError, shouldProceedToPay } from '../../../../common/payment-action/actions';
import { scrollToServerError, isThereExtraErrors } from '../utils';
/**
 * Alipay Class ( which extends the React.Component) contains the
 * functionality to make payment through alipay
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */
export default class AliPay extends Component {
  constructor(props) {
    super(props);
    this._isErrorOccured = props.isErrorOccured;
    this.state = {
      disableBookButton: false,
      isTncChecked: false,
      cartData: props.cartData,
      payBtnAlipay: 'payment-submit-btn btn-primary button-enabled-light',
    };
    this.btnClass = {
      light: 'button-enabled-light',
      dark: 'button-enabled-dark',
    };
    this.blockGuestCall = false;
    this.validateGuestForm = true;
  }

  componentDidMount() {
    if (!isLoggedInUser()) {
      window.PubSub.subscribe('guestFormHasError', this.checkGuestFormError);
    } else {
      window.PubSub.subscribe('recipientdetailsHasError', this.checkGuestFormError);
      window.PubSub.subscribe('tncError', this.payButtonColorHandled);
    }
  }

  /**
   *  loggedin case
   *  update pay button state onbasis of checked and non checked
   */

  payButtonColorHandled = (msg, data) => {
    const { light, dark } = this.btnClass;
    this.setState({
      payBtnAlipay: `payment-submit-btn btn-primary ${
        data.isCheckBoxDisable || document.querySelectorAll('.yasid-email-error').length > 0 || isThereExtraErrors()
          ? light
          : dark
      }`,
    });
  };

  /**
   *  non loggedin case
   *  check guest form error
   *  update pay button state onbasis of success or failure
   */

  checkGuestFormError = (msg, data) => {
    const isErrorExist =
      Object.keys(data).length > 0 && data.hasOwnProperty('isRecipientError')
        ? data.isRecipientError
        : !isLoggedInUser()
        ? guestFormError(data, this.props.isEnabled)
        : false;
    const { light, dark } = this.btnClass;
    this.setState({
      guestHasError: isErrorExist,
      payBtnAlipay: `payment-submit-btn btn-primary ${
        isErrorExist || document.querySelectorAll('.yasid-email-error').length > 0 || isThereExtraErrors()
          ? light
          : dark
      }`,
    });
  };

  /**
   *  blocks payment if conditions not met
   *  conditions : guest form not filled, t&c not checked
   */

  canProceedToPay = () => {
    return shouldProceedToPay({
      checkboxEnabled: this.props.isEnabled,
      tncErrorFunc: this.props.showTnCError,
      guestFormErrorFunc: this.props.guestUserHasErrors,
      guestErrorOnChangeState: this.state.guestHasError,
    });
  };

  updateRecipientDetails = async () => {
    const cartData = this.props.cartData ? { ...this.props.cartData } : null;
    const recipientDetails = getRecipientDetails();
    if (cartData) {
      cartData.personalisedGiftObj = recipientDetails;
      await CartService.updateCart(
        {
          cart: cartData,
        },
        'RecipientDetails',
        this.props.services.updateCart.url,
      );
    }
  };

  /**
   *  Call init and transacrtion api
   *  after the success of createorder api
   */

  submitDataAliPay = (e) => {
    window.PubSub.unsubscribe('CreateOrderSuccess');
    const {
      pageVariant,
      alipayconfig: { countryId, aliPayAcceptURL = {}, aliPayCallBackUrl = {} },
    } = this.props.paymentOptions;
    const displayName = 'AliPay';
    const { AliPayInit, AliPayTransaction } = this.props.services;
    const isRenewAnnualPass = pageVariant === 'annualPassRenew';
    const isEncrypted = isRenewAnnualPass ? 'isEncrypted=true' : 'isEncrypted=false';
    const paymentInfo = {
      cmsData: {
        paymentOptions: {
          tncType: this.props.paymentOptions.tncType,
          tncCondition: this.props.paymentOptions.tncCondition,
        },
      },
    };
    localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(paymentInfo));

    if (!this.canProceedToPay()) {
      e.preventDefault();
      e.stopPropagation();
      scrollToServerError(e);
      return false;
    } else if (this.props.isEnabled && isLoggedInUser()) {
      if (isThereExtraErrors()) return;
      /* Update recipient details */
      this.updateRecipientDetails();

      this.props.setRedirectPaymentData();
    }
    if (isThereExtraErrors()) return;

    window.PubSub.subscribe('CreateOrderSuccess', (msg, data) => {
      let cmsData = getLocalStorageByKey(UIConfig.localStoreKeys.payment.cmsData) || {};
      let finalAmount = (this.usdPrice * 100).toFixed();

      cmsData.orderData = {
        resCode: data.resCode,
        amount: finalAmount,
      };

      localStorage.setItem(UIConfig.localStoreKeys.payment.cmsData, JSON.stringify(cmsData));
      if (data.isCreateOrderSuccess) {
        let params = {
          tenantId: getLoggedInUser().tenantID,
          orderId: data.orderId,
          reservationCode: isRenewAnnualPass ? '' : data.resCode,
          amountCountryId: countryId,
          amount: finalAmount,
          clientInfolanguage: 'us',
          amountCurrencyId: '840',
          acceptUrl: aliPayAcceptURL.source,
          callbackUrl: aliPayCallBackUrl.source,
          cancelUrl: aliPayAcceptURL.source,
          declineUrl: aliPayAcceptURL.source,
        };

        let initApiParams = { orderId: data.orderId, reservationCode: isRenewAnnualPass ? '' : data.resCode };
        AliPayService.initAliPay({
          url: AliPayInit.url,
          apiData: JSON.stringify({ ...params, ...initApiParams }),
          moduleName: displayName,
          preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
        })
          .then((response) => {
            const data = response.data && response.data.initializeResponse.transaction;
            Logging(response, 'AliPay_Component', true, `Ali pay initcall success`);
            if (data) {
              delete params.acceptUrl;
              delete params.callbackUrl;
              delete params.cancelUrl;
              delete params.declineUrl;

              params.transactionId = `${data.transactionId}`;
              params.cardholderName = 'visa card';
              AliPayService.transactionAliPay({
                url: `${AliPayTransaction.url}?${isEncrypted}`,
                apiData: JSON.stringify(params),
                moduleName: displayName,
                preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
              })
                .then((res) => {
                  if (res) {
                    Logging(response, 'AliPay_Component', true, `Ali pay authcall success`);
                    window.location.href = res.data.url;
                  } else {
                    this._isErrorOccured('AliPayTransaction', res.data.error);
                  }
                })
                .catch((err) => {
                  Logging(err, 'AliPay_Component', true, `Ali pay error`);
                  this._isErrorOccured('AliPayTransaction', err.error);
                });
            }
          })
          .catch((err) => {
            this._isErrorOccured('AliPayInit', err.error);
          });
      } else {
        mycartError();
      }
    });
  };

  render() {
    const {
      alipayconfig: { totalLabel, aedCurrencyLabel, usdCurrencyLabel, conversionDisclaimer },
      conversionRate,
      paymentTypes,
    } = this.props.paymentOptions;
    let buttonData;

    const { grossPrice } = this.props.cartData;
    this.usdPrice = roundToTwoPlaces(grossPrice / conversionRate);
    buttonData = {
      type: 'submit',
      label:
        (checkParksTenants() && checkPaymentType(paymentTypes, UIConfig.paymentOption.aliPay)) ||
        this.props.paymentOptions.primaryCTA.label,
      disabled: `${parseInt(this.props.isTabIndex)}`,
      class: this.state.payBtnAlipay,
    };

    const AlipayFormClassWrapper = classNames('c-form alipay-wrapper card-detail contact-us-form', {
      'checkbox-wrapper-step2': this.props.is2StepPayment,
    });

    return (
      <div className={`${AlipayFormClassWrapper} ${this.props.hasADCBProductInCart ? 'disabled' : ''}`}>
        <div className="currency-wrapper-parent">
          <div className="currency-child-wrapper">
            <div className="total-txt">{totalLabel}</div>
            <div className="amount-usd">
              <span>
                {aedCurrencyLabel} {toTwoDecimalPlaces(grossPrice)}
              </span>{' '}
              <span className="currency">
                ({usdCurrencyLabel} {this.usdPrice})
              </span>
            </div>
          </div>
          <div className="terms">{conversionDisclaimer}</div>
        </div>
        <div className="alipay-submit-btn" id="alipaySubmitButton">
          <Button
            disabled={parseInt(this.props.isTabIndex)}
            data={buttonData}
            isNewForm={this.props.is2StepPayment}
            clickCallback={this.submitDataAliPay}
            isTabIndex={this.props.isTabIndex}
          />
        </div>
      </div>
    );
  }
}
