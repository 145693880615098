import moment from 'moment';
import React from 'react';

import AddToCalendar from './add-to-calendar';
import AddToWallet from './add-to-wallet';
import UIConfig from '../../../../common/UIConfig';
import {
  detectMobile,
  toTwoDecimalPlaces,
  getSeatInfo,
  checkTenant,
  getLocalStorageByKey,
  checkParksTenants,
} from '../../../../common/utility';
import classNames from 'classnames';
import { logComponentRenderingError } from '../../../../common/logger';
import DisclaimerCard from '../../disclaimer-card';

const ConfirmedTicketTileStepTwo = (props) => {
  const {
    productDisplayName,
    ticketParks,
    ticketDate,
    product,
    timeSlot,
    isTimeSlotVisible,
    quantity,
    validOn,
    classTypeName,
    showYMCAddToWallet,
    ticket,
    isRenewAnnualPassFlag,
    annualPassProductCategorization,
    productTypeCategory,
    seats,
    seatLabel,
    ticketImage,
    propData,
    eventType,
    weekDays,
    eventLocationName,
    seatType,
    wrcEventDate,
    index,
    eventTitle,
    image,
    onImageClick,
    addToWallet,
    enableAddToWallet,
    isCorpCouponApplied,
    multiparkTicketImage,
    ticketDisclaimerTitle,
    ticketDisclaimerDescription,
    itemType,
  } = props;
  const checkIfMultiParkTicket = () => itemType === 'REG';
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isYMC = checkTenant(UIConfig.iamMapping.ymc);
  const isGiftVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
  function ticketTileClassNames() {
    var style = {};
    if (isRenewAnnualPassFlag) {
      annualPassProductCategorization.forEach((categorization) => {
        if (categorization.propertyName === productTypeCategory) {
          style.borderTop = `8px solid ${categorization.propertyValues.BorderColorCode}`;
          style.background = categorization.propertyValues.ColorCode;
        }
      });
    }

    return style;
  }

  const getValidOn = () => {
    const { ymcMapping } = UIConfig.b2c.purchaseJourney;
    if (eventType && eventType.toLowerCase() === ymcMapping.eventType.f1) {
      return propData.formulaOneData && weekDays && propData.formulaOneData.weekDaysDisplay[weekDays];
    } else if (eventType && eventType.toLowerCase() === ymcMapping.eventType.wrx) {
      return wrcEventDate || '';
    } else {
      const isTicketUpgraded = product?.tickets?.filter((data) => {
        return data?.isUpgraded === false;
      })[0];
      if (checkParksTenants() && isTicketUpgraded) {
        return ticketDate && moment.utc(isTicketUpgraded?.validTo).format(UIConfig.b2c.purchaseJourney.dateFormat);
      } else {
        return ticketDate && moment.utc(ticketDate).format(UIConfig.b2c.purchaseJourney.dateFormat);
      }
    }
  };

  try {
    const ticketHeading = (
      <div className="c-booking-confirmation-ticket-title">
        {!isSwad && <p className="heading-4 ticket-name">{ticketParks}</p>}
        <p className="body-2">{productDisplayName}</p>
        {eventLocationName && <p className="location-name">{eventLocationName}</p>}
        {eventType &&
          eventType.toLowerCase() === UIConfig.b2c.purchaseJourney.ymcMapping.eventType.f1 &&
          seatType &&
          seatType.toLowerCase() === UIConfig.b2c.purchaseJourney.ymcMapping.seatType.seat && (
            <p className="seat-section">
              <span className="section-label">
                {propData.formulaOneData && propData.formulaOneData.shippingData.section}{' '}
              </span>
              <span className="seat">{getSeatInfo(product)}</span>
            </p>
          )}
      </div>
    );
    const ticketValidity = (
      <div className="c-booking-confirmation-ticket-validity">
        <p className="heading-4">{validOn}</p>
        <p className="body-2 ticket-validity">{getValidOn()}</p>
        {product.timeSlot && timeSlot && isTimeSlotVisible && (
          <p className="heading-4 ticket-validity ticket-timeslot">{isSwad ? ` | ${timeSlot} ` : timeSlot}</p>
        )}
        {product.timeSlot && timeSlot && isTimeSlotVisible && <p className="body-2">{product.timeSlot}</p>}
      </div>
    );
    const imageData = ticketImage;
    return (
      <>
        <div className="component c-booking-confirmation-ticket ticket-box" tabIndex="0" style={ticketTileClassNames()}>
          <span className="ticket-details">
            {!isSwad && detectMobile() ? ticketHeading : ''}
            <span className={classNames('ticket-sub-details', { 'hide-classtype': !ticket[classTypeName] })}>
              {detectMobile() ? '' : !isSwad ? ticketHeading : ''}
              {isSwad ? (
                <div className="ticket-details-content">
                  {ticketHeading}
                  {ticketValidity}
                </div>
              ) : (
                ticketValidity
              )}
              <div className="c-booking-confirmation-ticket-inclusives">
                {quantity && <p className="heading-4">{quantity}</p>}
                {isSwad && !ticket[classTypeName] ? (
                  ''
                ) : (
                  <p className="body-2 ticket-type">{`${product.quantity || 1} ${ticket[classTypeName] || ''}`}</p>
                )}
                {isSwad && (
                  <p className="body-2 ticket-price">
                    {product.price.currency +
                      ' ' +
                      product.price.gross.toLocaleString('en', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </p>
                )}

                {seats && seats.length > 0 && seatLabel && <p className="heading-4 ticket-seats">{seatLabel}</p>}
                {seats &&
                  seats.length > 0 &&
                  seats.map((seat) => {
                    return <p className="body-2 ticket-seats">{seat}</p>;
                  })}
              </div>
              {propData.variant === 'v-ymc-booking-confirmation-summary' && propData.formulaOneData && (
                <div className="c-booking-confirmation-ticket-price">
                  <p className="heading-4">{propData.formulaOneData.shippingData.priceLabel}</p>
                  <p className="body-2 ticket-price">
                    {product.price.currency + ' ' + toTwoDecimalPlaces(Number(product.price.gross))}
                  </p>
                </div>
              )}
            </span>
            {/* Need to enable when multi park information block is planned for the sprint
            {!checkIfMultiParkTicket() && (
              <div className="ticket-disclaimer-block">
                <DisclaimerCard
                  ticketDisclaimerTitle={ticketDisclaimerTitle}
                  ticketDisclaimerDescription={ticketDisclaimerDescription}
                  isSwad={isSwad}
                />
              </div>
            )} */}
          </span>
          {!isSwad && (
            <div className={`c-booking-confirmation-ticket-park-image park-image-details`}>
              {/* we are using img tag here because we are getting only src from backend for single image */}
              {ticketImage.src && (
                <img
                  src={imageData?.src}
                  data-src={imageData?.src}
                  className="park-image lazyloaded"
                  alt={imageData?.alt}
                />
              )}
            </div>
          )}
          {isSwad && detectMobile() && image && enableAddToWallet && !isGiftVoucher ? (
            <AddToWallet image={image} onImageClick={onImageClick} ticket={product.tickets} addToWallet={addToWallet} />
          ) : null}
          {isYMC && enableAddToWallet && showYMCAddToWallet && detectMobile() ? (
            <AddToWallet image={image} onImageClick={onImageClick} ticket={product.tickets} addToWallet={addToWallet} />
          ) : null}
          {propData.addToCalendar && propData.addToCalendar.label && !index && (
            <AddToCalendar title={eventTitle || productDisplayName} product={product} {...propData.addToCalendar} />
          )}
        </div>

        {!isSwad && !isYMC && !isCorpCouponApplied && detectMobile() && image && enableAddToWallet && !isGiftVoucher ? (
          <AddToWallet image={image} onImageClick={onImageClick} ticket={product.tickets} addToWallet={addToWallet} />
        ) : null}
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ConfirmedTicketTileStepTwo');
  }
};

export default ConfirmedTicketTileStepTwo;
