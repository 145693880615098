/*
 * radio-components.js
 * This file contains code for radio component which will render input based on schema.
 * @licensor  Miral
 */

import React, { useState, useEffect } from 'react';
import { DynamicContent } from '../../base';
import { validateJSSField } from '../rules-validator';
import { logComponentRenderingError } from '../../../../common/logger';
import { getValueFromArray } from '../../../../common/forms-utility';
import FieldErrorComponent from '../field-error';
import classNames from 'classnames';

/**
 * Sub-component of RadioButton
 */

const InputRadioButton = ({
  radioDisabled,
  data,
  inputType,
  onChange,
  radioValues,
  opt,
  i,
  valueFieldId,
  ariaLabelledby,
  isErrorExist,
}) => {
  const inputClasses = classNames('`body-copy-3', { error: isErrorExist });
  return (
    <label
      className={
        radioDisabled && radioDisabled.disabled && radioDisabled.elem.indexOf(data.name) < 0
          ? 'checkbox-label disabled'
          : 'checkbox-label'
      }
      key={i.toString()}
      htmlFor={valueFieldId}
    >
      <input
        className={inputClasses}
        name={data.field.valueField.name}
        onChange={onChange}
        checked={
          radioValues &&
          radioValues[data.field.model.name] &&
          radioValues[data.field.model.name].toLowerCase() === opt.value.toLowerCase()
        }
        value={opt.value}
        aria-required={data.isRequired}
        aria-labelledby={ariaLabelledby}
        type={inputType}
        disabled={radioDisabled && radioDisabled.disabled && radioDisabled.elem.indexOf(data.name) < 0}
      />
      <span className="form-checkbox-fancy"></span>
      <DynamicContent tagName="span" innerHtml={opt.text} attrs={{ className: 'body-copy-5' }} />
    </label>
  );
};

/**
 * Checkbox Component that will create radio or checkbox element as per data type
 * @param  {object} having all required attributes such as form data, events
 */
const RadioButton = (props) => {
  const { tracker, field, errors } = props,
    valueFieldName = field.valueField.name,
    fieldName = props.field.model.name,
    valueFieldId = field.valueField.id,
    defaultSelectedValue = getValueFromArray(props.field.model.items, 'selected', 'value'),
    inputType = 'Radio';

  const [fieldValue, setFieldValue] = useState(defaultSelectedValue);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [radioValues, setRadioValues] = useState({ [fieldName]: fieldValue });

  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  const handleChange = (inputValue) => {
    let valid = true,
      errorMessages = [];
    const errorMessagesObj = validateJSSField({ ...field, type: inputType }, inputValue);
    const message = errorMessagesObj[valueFieldName];
    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }
    setFieldValue(inputValue);
    setRadioValues({ [fieldName]: inputValue });
    setErrorMsg(errorMessages);
    props.onChange(valueFieldName, inputValue, valid, errorMessages);
    tracker.onBlurField(field, inputValue, errorMessages);
  };

  const onChange = (e) => {
    handleChange(e.target.value);
  };

  try {
    const isErrorExist = errorMsg && errorMsg.length > 0;
    return (
      <div aria-live="polite" className={`form-element radio-group radio ${field.model.cssClass}`}>
        <DynamicContent tagName="span" innerHtml={props.field.model.title} attrs={{ className: 'body-copy-6' }} />
        {Array.isArray(props.field.model.items) &&
          props.field.model.items.map((opt, i) => {
            return (
              <InputRadioButton
                radioDisabled={opt.radioDisabled}
                data={props}
                onChange={onChange}
                radioValues={radioValues}
                opt={opt}
                i={i}
                inputType={inputType}
                id={valueFieldId}
                ariaLabelledby={isErrorExist && 'error_' + valueFieldName}
                isErrorExist={isErrorExist}
              />
            );
          })}

        <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Radiobox');
  }
};

export default RadioButton;
