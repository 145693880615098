import React from 'react';
import moment from 'moment';
import { Calendar, DynamicContent, ErrorSummary } from '../../../presentation/base';
import DropDown from '../../../presentation/drop-down';
import {
  scrollTo,
  getErrorMap,
  momentTimezone,
  getValidStartDate,
  getClientUtcOffset,
} from '../../../../common/utility';
import VisitorsCountSelector from '../../b2c-purchase-journey/visitors-count-selector/visitors-count-selector-component';
import { logComponentRenderingError } from '../../../../common/logger';

class AddOnsTicketOverlayComponent extends React.Component {
  constructor(props) {
    super(props);
    this.calendarSettings = this.props.calenderData.options[0].calendarSettings;
    this.minDate = this.getValidStartDate(this.calendarSettings.startDate).add(
      this.calendarSettings.defaultDateDelta,
      'days',
    );
    this.maxDate = this.getValidStartDate(this.calendarSettings.startDate).add(
      parseInt(this.calendarSettings.range, 10),
      'days',
    );
    this.validOn = this.calendarSettings.validOn;

    this.validOnRange = [];
    if (this.validOn) {
      this.validOnRange = this.validOn.split(',').map((x) => +x);
    }

    this.performanceServices = {
      responses: [],
      executeCount: 0,
    };

    this.state = {
      timeSlots: { slots: [], defaultValue: '' },
      errors: null,
      timeSlotSelected: false,
      totalCalculatedAmount: 0,
      disableAddToCart: true,
    };
  }

  resetState = () => {
    this.performanceServices = {
      responses: [],
      executeCount: 0,
    };

    this.setState({
      timeSlots: { slots: [], defaultValue: '' },
      errors: null,
      timeSlotSelected: false,
      totalCalculatedAmount: 0,
      disableAddToCart: true,
    });
  };
  scrollToNextSection = (section) => {
    scrollTo(
      document.getElementsByClassName('overlay-content-inner-container')[0],
      document.getElementsByClassName(section)[0].nextSibling.offsetTop,
      500,
    );
  };

  getValidStartDate = (date) => {
    if (this.validOn) {
      const currentDate = momentTimezone(date, getClientUtcOffset());
      // while (this.validDayOfWeek.indexOf(currentDate.day()) < 0) {
      //   currentDate.add(1, 'day');
      // }
      return currentDate;
    }
    return getValidStartDate(date);
  };

  getFormattedDates = (dates) => {
    return dates && dates.length ? dates.map((dateString) => momentTimezone(dateString, getClientUtcOffset())) : null;
  };

  allIncludedDates = () => {
    const allEnabledDates = this.getFormattedDates(this.calendarSettings.enabledDates);
    const includeDates = allEnabledDates
      ? allEnabledDates.sort(function(left, right) {
          return moment(left).format('X') - moment(right).format('X');
        })
      : null;

    const currentDate = moment();
    return includeDates
      ? includeDates.filter((d) => momentTimezone(d, getClientUtcOffset()).isSameOrAfter(currentDate, 'days'))
      : includeDates;
  };

  isSameDate = (date) => {
    const includeDateLogic = this.allIncludedDates();
    const findDate =
      includeDateLogic &&
      includeDateLogic.find((item) =>
        momentTimezone(item, getClientUtcOffset()).isSame(momentTimezone(date, getClientUtcOffset()), 'day'),
      );
    if (findDate) {
      return true;
    }
    return false;
  };

  isValidDay = (date) => {
    if (!this.validOnRange.length) {
      return 1;
    }

    if (this.isSameDate(date)) {
      return true;
    }
    const day = date.day();
    return this.validOnRange.indexOf(day) >= 0;
  };

  setTimeSlots = () => {
    this.props.getPerformanceListFromResponse(this.performanceServices.responses);
    const timeSlots = this.props.getTimeSlots();

    const errors = getErrorMap(
      'getPerformance',
      this.props.services.getPerformance.errors,
      timeSlots.slots.length > 0,
      { code: '7001' },
      this.state.errors,
    );
    this.setState({ timeSlots: timeSlots, errors: errors });
    this.scrollToNextSection('react-datepicker');
  };

  setDate = (date) => {
    this.resetState();
    const services = this.props.getPerformanceServices(date);
    services.forEach((service) => {
      service
        .then((response) => {
          this.performanceServices.responses.push(response);
          this.performanceServices.executeCount += 1;
          this.performanceServices.executeCount === services.length && this.setTimeSlots();
        })
        .catch((err) => {
          this.performanceServices.executeCount += 1;
          this.performanceServices.executeCount === services.length && this.setTimeSlots();
        });
    });
  };

  selectTimeSlot = (event) => {
    this.props.onSelectTimeSlot(event);
    this.setState({ timeSlotSelected: true });
    this.scrollToNextSection('c-date-selector--time-slot');
  };

  shouldDisableAddToCart = (totalCalculatedAmount, data) => {
    const sum = Object.values(data.spinnerCount.primaryCounterCount).reduce((total, count) => total + count);
    return (
      totalCalculatedAmount === 0 || sum > this.props.counterData.maxCount || sum < this.props.counterData.minCount
    );
  };

  setVisitorData = (data) => {
    const totalCalculatedAmount = this.props.getTotalCalculatedAmount(data);
    this.setState({
      totalCalculatedAmount: totalCalculatedAmount,
      disableAddToCart: this.shouldDisableAddToCart(totalCalculatedAmount, data),
    });
  };

  addToCart = () => {
    this.props.addToCartCallback();
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: false,
    });
  };

  renderTotalAmount = () => {
    return (
      <div className="ticket-total-amount-wrapper">
        <div className={`total-amount ${this.state.disableAddToCart ? 'disabled' : ''}`}>
          <h3>{this.props.data.total}</h3>
          <div className="amount">
            <h3>
              {this.props.data.currency} {this.state.totalCalculatedAmount.toLocaleString()}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  renderSpinners = () => {
    return (
      <VisitorsCountSelector
        data={{ primaryCounter: this.props.counterData }}
        setVisitorData={this.setVisitorData}
        maxAvailableTicketsCount={this.state.maxAvailableTickets}
      />
    );
  };

  renderTimeSlots = () => {
    return (
      <div className="c-date-selector--time-slot">
        <DynamicContent
          tagName="h3"
          attrs={{ className: 'heading-5' }}
          innerHtml={this.props.timeSlotData.labels.title}
        />
        <DynamicContent
          tagName="p"
          attrs={{ className: 'sub-heading' }}
          innerHtml={this.props.timeSlotData.description}
        />
        <DropDown
          listItems={this.state.timeSlots.slots}
          defaultValue={this.state.timeSlots.defaultValue}
          callBackFunction={this.selectTimeSlot}
          showSelected="true"
        />
      </div>
    );
  };

  render() {
    try {
      return (
        <div className="c-add-ons-overlay">
          <DynamicContent
            tagName="h3"
            attrs={{ className: 'heading-3 calendar-title' }}
            innerHtml={this.props.calenderData.title}
          />
          <Calendar
            setDateCustom={this.setDate}
            minDate={this.minDate}
            maxDate={this.maxDate}
            filterDate={this.isValidDay}
            calendarSettings={this.calendarSettings}
          />
          {this.state.errors && <ErrorSummary data={this.state.errors} />}
          {this.state.timeSlots.slots.length > 0 && this.renderTimeSlots()}
          {this.state.timeSlotSelected && this.renderSpinners()}
          {this.state.timeSlotSelected && this.renderTotalAmount()}
          <div className="btn-add-to-cart">
            <div className={`btn-primary ${this.state.disableAddToCart ? 'disabled' : ''}`}>
              <DynamicContent
                tagName="button"
                innerHtml={this.props.data.addToCart}
                attrs={{
                  onClick: this.addToCart,
                  disabled: this.state.disableAddToCart,
                  className: 'add-to-cart',
                }}
              />
            </div>
          </div>
          {this.state.timeSlotSelected && (
            <div className="vat-info">
              <DynamicContent tagName="p" attrs={{ className: 'vat-info-label' }} innerHtml={this.props.data.vatInfo} />
            </div>
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'AddOnsTicketOverlayComponent');
    }
  }
}

export default AddOnsTicketOverlayComponent;
