/*
 * order-table-component.js
 * This file contains code for Order Table component, It is a dumb component,it gets it's data from it's parent(order-container)
 * @author  SapientNitro
 * @licensor  Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import OrderTableRow from '../../presentation/order-table-row/order-table-row-component';
import OrderDetailMini from '../../presentation/order-detail-mini/order-detail-mini-component';
import { DynamicContent, SvgSprite } from '../../presentation/base';

import './order-table-component.scss';

const dueDateStatus = ['All', 'Pending', 'Archived', 'Payment Failure', 'System Failure'];

/*
 * This will render all rows of order table component by calling OrderTableRow component
 */
const renderOrderItems = (props) => {
  const rows = [];
  if (props.orderData.length !== 0) {
    props.orderData.forEach((row, idx) => {
      rows.push(
        <OrderTableRow
          className={
            props.orderDetail[row.bookingId]
              ? props.orderDetail[row.bookingId].orderClass === 'show'
                ? 'up-arrow'
                : 'down-arrow'
              : 'down-arrow'
          }
          key={idx}
          data={props}
          row={row}
          checkedStatus={row.checked}
        />,
      );
      if (props.orderDetail && props.orderDetail[row.bookingId]) {
        rows.push(
          <OrderDetailMini
            orderId={row.bookingId}
            labelConfig={props.labelConfig}
            key={row.bookingId}
            className={props.orderDetail[row.bookingId].orderClass}
            orderDetail={props.orderDetail[row.bookingId]}
          />,
        );
      }
    });

    return rows;
  } else {
    return (
      <tr>
        <Text
          tag="td"
          className="no-result"
          colSpan="8"
          field={{ value: ` ${props.labelConfig.noResult} `, editable: ` ${props.labelConfig.noResult} ` }}
        />
      </tr>
    );
  }
};

const renderInfoMsg = (props) => {
  return (
    <tr>
      <Text
        tag="td"
        className="before-result"
        colSpan="8"
        field={{ value: ` ${props.beforeResultMsg} `, editable: ` ${props.beforeResultMsg} ` }}
      />
    </tr>
  );
};

const rederOrderTable = (props) => {
  return (
    <thead className="header">
      <tr className="c-order-table--row" onClick={props.headerClick}>
        {props.labelConfig.gridLabels.map((column, idx) =>
          column.key === 'dueDate' ? (
            dueDateStatus.indexOf(props.selectedStatus && props.selectedStatus.split(',')[0]) >= 0 ? (
              <th key={idx} className={'c-order-table--column-heading heading-7 ' + props.selectedStatus}>
                <a data-sort={column.key} className={column.key} href="/">
                  <DynamicContent tagName="span" innerHtml={column.label} attrs={{ 'data-sort': column.key }} />
                  <SvgSprite id="sort-arrow" />
                </a>
              </th>
            ) : (
              <th key={idx} className="hide"></th>
            )
          ) : (
            <th key={idx} className={'c-order-table--column-heading heading-7 ' + props.selectedStatus}>
              <a data-sort={column.key} className={column.key} href="/">
                <DynamicContent tagName="span" innerHtml={column.label} attrs={{ 'data-sort': column.key }} />
                <SvgSprite id="sort-arrow" />
              </a>
            </th>
          ),
        )}
        <th key="svg" className="arrow">
          Arrow
        </th>
      </tr>
    </thead>
  );
};

const rederCreditSettlement = (props) => {
  return (
    <thead className="header">
      <tr className="c-order-table--row settlement-table--row" onClick={props.headerClick}>
        <th className={'c-order-table--column-heading heading-7 '}>
          <input
            type="checkbox"
            value="select"
            onChange={(e) => props.onChangeHandler(e)}
            className={'settlement-table-checkbox visibility-hidden'}
            name="selectall"
            ref={props.setAllCheckRef}
            disabled
          />
        </th>
        {props.labelConfig.gridLabels.map((column, idx) => (
          <th key={idx} className={'c-order-table--column-heading heading-7 ' + props.selectedStatus}>
            <span data-sort={column.key} className={column.key}>
              <DynamicContent tagName="span" innerHtml={column.label} attrs={{ 'data-sort': column.key }} />
              <SvgSprite id="sort-arrow" />
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

/*
 * This will render Order Table component
 */

const OrderTable = (props) => {
  return (
    <table className="c-order-table loader-container">
      {props.variant === 'credit-settlement-variant' ? rederCreditSettlement(props) : rederOrderTable(props)}
      <tbody>
        {props.variant === 'credit-settlement-variant'
          ? !props.orderCallCompleted
            ? renderInfoMsg(props)
            : renderOrderItems(props)
          : props.orderCallCompleted
          ? renderOrderItems(props)
          : null}
        {/* {props.orderCallCompleted ? renderOrderItems(props) : null} */}
      </tbody>
    </table>
  );
};

export default OrderTable;

/**
 * Used to define the proptypes that will be received by the component.
 */

OrderTable.propTypes = {
  props: PropTypes.shape({
    orderData: PropTypes.array,
    orderDetail: PropTypes.object,
    noResult: PropTypes.string,
  }),
};
