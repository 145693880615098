import React, { useState } from 'react';
import { deepCloneObject, resolvePath } from '../../../../common/utility';
import { createConfirmEmailField } from '../../../../common/forms-utility';
import { logComponentRenderingError } from '../../../../common/logger';
import Input from '../input/';

const EmailConfirmation = (props) => {
  const emailProps = props;
  const valueFieldName = resolvePath(emailProps, 'field.valueField.name', '');
  // deepcopy of props to prevent from refer same object
  const confirmEmailProps = deepCloneObject(props);
  // change confirmation email properties values to distinguish from email field
  confirmEmailProps.field = createConfirmEmailField(confirmEmailProps.field, emailProps.field.valueField.name);
  const [inputValue, setInputValue] = useState(emailProps.field.model.value);
  const valueConfirmFieldName = resolvePath(confirmEmailProps, 'field.valueField.name', '');
  try {
    return (
      <>
        <Input {...emailProps} getInputValue={(value) => setInputValue(value)} errors={props.errors[valueFieldName]} />
        <Input {...confirmEmailProps} matchWithEmail={inputValue} errors={props.errors[valueConfirmFieldName]} />
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'EmailConfirmation');
  }
};

export default EmailConfirmation;
