/* global window */
import loadScript from 'load-script2';

/**
 * Player defaults to customize player options.
 * @param    {videoId, controls, loop, autoplay, rel, height, clientID,}
 *
 */
const getPlayerParams = (props, playerStateChange) => {
  const noop = () => {};
  const { videoId, controls, loop, autoplay, rel, height, clientID, muted, playList, startTime, endTime } = props;
  const playerType = props.type !== undefined ? props.type : 'youtube';
  const playerDefaults = {
    youtube: {
      URL: 'https://www.youtube.com/iframe_api',
      GLOBAL: 'YT',
      GLOBAL_READY: 'onYouTubeIframeAPIReady',
      OPTIONS: {
        height: height || props.videoData.height || '720px',
        width: props.videoData.width || '100%',
        videoId: videoId,
        playerVars: {
          controls: controls || 0,
          loop: loop || 0,
          rel: rel || 0,
          autoplay: autoplay || 0,
          frameborder: 0,
          mute: muted,
          playlist: loop ? playList || videoId : '',
          start: startTime,
          end: endTime,
          playsinline: 1,
        },
        events: {
          onReady: props.onReady || noop,
          onStateChange: playerStateChange || noop,
        },
      },
    },
    vimeo: {
      URL: 'https://player.vimeo.com/api/player.js',
      GLOBAL: 'Vimeo',
      GLOBAL_READY: 'Vimeo',
      OPTIONS: {
        height: height || 720,
        width: 1280,
        id: videoId,
        autoplay: autoplay || 0,
        loop: loop || 0,
      },
    },
    youku: {
      URL: 'https://player.youku.com/jsapi',
      GLOBAL: 'YKU',
      GLOBAL_READY: 'YKU',
      OPTIONS: {
        height: height || 720,
        width: 1280,
        client_id: clientID || 12121,
        vid: videoId,
        autoplay: autoplay || 1,
        newPlayer: true,
        styleid: '0',
        show_related: rel || 0,
        events: {
          onPlayerReady: props.onReady || noop,
          onPlayStart: props.onPlay || noop,
          onPlayEnd: props.onStop || noop,
        },
      },
    },
  };
  return playerDefaults[playerType];
};

/**
 * Load Player SDK
 * @param {API} as args return from getPlayerParams method default Player Youtube.
 * if user select other type , "vimeo" , 'youku'
 * default API object will change
 */
const loadSdk = (API) => {
  if (window[API.GLOBAL]) {
    return Promise.resolve(window[API.GLOBAL]);
  } else if (API.GLOBAL === 'YT') {
    return new Promise((resolve, reject) => {
      const previousOnReady = window[API.GLOBAL_READY];
      window[API.GLOBAL_READY] = function() {
        if (previousOnReady) {
          previousOnReady();
        }
        resolve(window[API.GLOBAL]);
      };
      loadScript(API.URL, (err) => {
        if (err) {
          reject(err);
        }
      });
    });
  } else {
    return new Promise((resolve, reject) => {
      loadScript(API.URL, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(window[API.GLOBAL]);
        }
      });
    });
  }
};

let sdk = null;
let params;
export default function PlayerService(props, ev) {
  params = getPlayerParams(props, ev);
  sdk = loadSdk(params); // return player promise object
  return { sdk, params };
}
