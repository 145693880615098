import React from 'react';
import idleJs from '../Idle';

import { ErrorOverlay } from '../../components/presentation/base';
import { getLoggedInUser, getRenewTokenConfig, isEmpty } from '../utility';
import { logoutCurrentUser } from './session-utility';

let loggedUser;

//Default values that will be applied if required data doesn't exist
const defaultConfig = {
  sessionIdleTimeOut: 3600000,
  idleTimeOutButtonText: 'OK',
  idleTimeOutMessage: 'Your session has been expired, Please log-in again.',
};

/**
 * setIdleTimeout function sets the configuration for the idle timeout functionality.
 * @param    {[Object]} idleTime [idle time in milisecond after which popup will appear]
 * @param    {[Object]} onIdleTimeout [call back method that will execute after idle time]
 * @return   {[Object]} returns the idle object that will be triggerd from calling function
 */
const setIdleTimeout = (idleTime, onIdleTimeout) => {
  let idle = new idleJs({
    idle: idleTime, // idle time in ms
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
    onIdle: onIdleTimeout, // callback function to be executed after idle time
    onActive: function() {}, // callback function to be executed after back form idleness
    onHide: function() {}, // callback function to be executed when window become hidden
    onShow: function() {}, // callback function to be executed when window become visible
    keepTracking: true, // set it to false of you want to track only once
    startAtIdle: true, // set it to true if you want to start in the idle state
  });

  return idle;
};

/*
onIdleTimeout function will execute if user remains idle for the given time
It sets the reason for logout, text of overlay popup button and message of overlay
popup to the local storage and loggout the user
*/
const onIdleTimeout = () => {
  logoutCurrentUser('idle timeout', true);
};

// idleTimeout function checks if user is login or not, if logged-in it triggers the idle timeout functionality.
export const idleTimeout = () => {
  loggedUser = getLoggedInUser();
  if (loggedUser && loggedUser.hasOwnProperty('idToken')) {
    let renewTokenConfig = getRenewTokenConfig();
    let idleTime = renewTokenConfig.sessionIdleTimeOut
      ? Number(renewTokenConfig.sessionIdleTimeOut) * 1000
      : defaultConfig.sessionIdleTimeOut;

    setIdleTimeout(idleTime, onIdleTimeout).start();
  }
};

// openOverlay function opens the overlay.
const openOverlay = (modalHTML) => {
  window.PubSub.publish('toggleOverlayState', {
    shouldOpen: true,
    dataToAppend: modalHTML,
    customClass: 'c-error-overlay v-b2c-overlay idle-timeout',
  });
};

/*
showLogoutReason function check if user is logged out forcefully because
of some reason if yes, It shows the reason for logout on overlay popup and delete the reason
stored in the local storage.
*/

export const showLogoutReason = () => {
  let logoutlog = localStorage.logoutLog;
  if (logoutlog && !isEmpty(logoutlog)) {
    logoutlog = JSON.parse(logoutlog);
    let primaryCTA = logoutlog.idleTimeOutButtonText
        ? logoutlog.idleTimeOutButtonText
        : defaultConfig.idleTimeOutButtonText,
      title = logoutlog.idleTimeOutMessage ? logoutlog.idleTimeOutMessage : defaultConfig.idleTimeOutMessage,
      attrs = {
        title,
        primaryCTA,
        ...(logoutlog.azureLogoutURL && {
          primaryCallback: () => {
            window.location.href = logoutlog.azureLogoutURL;
          },
        }),
      },
      overlayHTML = <ErrorOverlay {...attrs} />;
    openOverlay(overlayHTML);
  }

  localStorage.removeItem('logoutLog');
};
