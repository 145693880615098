import React from 'react';

const CustomProfiler = (props) => {
  let date = new Date();
  let componentStartTime;

  const initializedTime = () => {
    componentStartTime = date.toISOString();
  };
  const renderedTime = () => {
    let date = new Date();
    let componentEndTime = date.toISOString();
    let componentRenderTime = '';
    props.collectComponentData({
      componentName: props.componentName,
      componentStartTime,
      componentEndTime,
      componentRenderTime,
    });
  };

  return (
    <React.Fragment>
      {initializedTime()}
      {props.children}
      {renderedTime()}
      {/*Logging the data when it has reached the last component */}
      {props.lastPlaceholder && props.componentsNumber === props.index ? props.layoutLayerProfiling() : null}
    </React.Fragment>
  );
};

export default CustomProfiler;
