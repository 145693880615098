import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import CTAButton from '../base/cta-button/CTAButton';
import { canUseDOM, resolvePath } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { bodyClicks } from '../../../common/analytics-events';

function SectionHeader({ data }) {
  const { shortTitle, description, ctaButton } = data;
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
  const rowForTitle = data.rowForTitle || 'col-md-4';
  const rowForCTA = data.rowForCTA || 'col-md-3';
  const rowForDescription = data.rowForDescription || 'col-md-5';
  const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
    if (checkfortenants) {
      bodyClicks(eventName, titleData, labelData);
    }
  };

  return (
    <div className="section-header row">
      <div className={rowForTitle}>
        <Text tag="h5" className="title" field={{ value: shortTitle, editable: shortTitle }} />
      </div>
      <div className={ctaButton.href && ctaButton.label ? rowForDescription : 'col-md-8'}>
        <Text tag="p" className="description" field={{ value: description, editable: description }} />
      </div>
      {ctaButton.href && ctaButton.label ? (
        <div className={rowForCTA + ' cta-right-align'}>
          <CTAButton
            ctaButton={ctaButton}
            type="iconCta"
            customClickAnalytic={(e) => {
              googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.linkClick, shortTitle, ctaButton.label);
            }}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
export default SectionHeader;
