/*
 * This file contains code for EditorialText component which will render html from backend.
 * @licensor  Miral
 */

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { AnchorLink, DynamicContent, DynamicContentTagReplace } from '../../presentation/base';
import UIConfig from '../../../common/UIConfig';
import {
  canUseDOM,
  getCardButtonLabel,
  detectMobile,
  isEmpty,
  isMatchTenant,
  getGa4Category,
} from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';
import { bookNowClickAnalytics, bodyClicks } from '../../../common/analytics-events';
import { resolvePath } from '../../../common/utility';
import { renderBuyNowButton } from '../hero-component/helpers';
import GTMData from '../b2c-purchase-journey/gtm-data';

/**
 * EditorialText is a component which render the provided html from backend
 * @param    {string}    object     wrapper html element for the provided html.
 * @return   {[Object]}  Return a react element.
 */
const EditorialText = ({ data }, heroComponent = '') => {
  const [hidden, setHidden] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    setIsMobile(detectMobile());
  }, []);

  if (!data || !(data.title || data.bodyCopy || data.date || data.shortTitle)) {
    return null;
  }

  const {
    bodyCopy,
    bookNowLabel,
    date,
    subTitle,
    limitedLabel,
    isLimited,
    isSoldOut,
    isUpcoming,
    fromPriceLabel,
    fromPriceValue,
    eventBookingUrl,
    shortTitle,
    shortTitleHeading,
    soldOutLabel,
    title,
    upcomingLabel,
    variant,
    changeContentTitleToH1,
    bodyCopy2,
    underlinedText,
    primaryCTA,
    extraClass = '',
    showSuccessIcon = false,
  } = data;

  const handleBookNow = () => {
    bookNowClickAnalytics(window.location.href, { secondaryBuyLabel: bookNowLabel });
  };
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;

  const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
    if (checkfortenants) {
      bodyClicks(eventName, titleData, labelData);
    }
  };

  const supplierRegistrationGTM = (event) => {
    if (isMatchTenant(UIConfig.tenants.ya)) {
      const elem = event.target;
      const { tagName } = elem || {};
      const isAnchor = tagName === UIConfig.ga4Constants.ANCHOR || elem.closest('a');
      const isButton = tagName === UIConfig.ga4Constants.BUTTON || elem.closest('button');
      const supplierRegistrationPage = getGa4Category(window?.location?.pathname);
      if ((isAnchor || isButton) && supplierRegistrationPage.includes(UIConfig.pages.supplierRegistrationPage)) {
        GTMData.push(UIConfig.ga4Constants.SUPPLIER_REGISTRATION);
      }
    }
  };

  const renderHeroAttributes = () => (
    <div className={classNames('w--bottom', { center: !fromPriceValue })}>
      <div className="price-description">
        {fromPriceValue && (
          <div>
            <Text tag="span" className="label" field={{ value: fromPriceLabel, editable: fromPriceLabel }} />
            <Text tag="div" className="price" field={{ value: fromPriceValue, editable: fromPriceValue }} />
          </div>
        )}
      </div>
      {isLimited && <span className="sticky-label">{limitedLabel}</span>}
      {bookNowLabel && (
        <div className="btn-primary hero-cta-button">
          <AnchorLink
            className={classNames({ disabled: isSoldOut || isUpcoming })}
            link={{
              label: getCardButtonLabel(
                isSoldOut,
                soldOutLabel,
                false,
                '',
                bookNowLabel,
                isUpcoming,
                upcomingLabel,
                '',
                false,
              ),
              href: eventBookingUrl,
            }}
            onClick={() => {
              handleBookNow();
              googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.linkClick, '', bookNowLabel);
            }}
          />
        </div>
      )}
    </div>
  );

  const renderThingsToRemember = () => {
    return (
      <>
        <DynamicContent tagName="div" innerHtml={bodyCopy} />
      </>
    );
  };

  const bannerVarient = data.variant === 'v-banner';
  try {
    const titleBasedOnMainTitle = changeContentTitleToH1 ? 'h1' : 'h2';
    return (
      <div
        className={classNames(
          'component c-editorial-text',
          variant,
          isMobile && data.showBorderBottomInMobile ? 'border-bottom-in-mobile' : null,
          extraClass,
        )}
      >
        {variant !== UIConfig.kgfSnpVariant && (
          <div className={classNames('w--content', { 'from-hero-component-container': heroComponent })}>
            {shortTitle && (
              <DynamicContent
                tagName={shortTitleHeading || 'h6'}
                attrs={{ className: 'shortTitle' }}
                innerHtml={shortTitle}
              />
            )}
            {!bannerVarient && title && (
              <DynamicContent tagName={title.heading || titleBasedOnMainTitle} innerHtml={title.text || ''} />
            )}
            <DynamicContent tagName="p" attrs={{ className: 'article-date' }} innerHtml={date} />
            <DynamicContentTagReplace
              tagName="div"
              attrs={{ className: 'body-copy-1', onClick: supplierRegistrationGTM }}
              innerHtml={bodyCopy}
              replace={'{0}'}
              replaceWith="blockquote"
            />
            <DynamicContent tagName="h6" attrs={{ className: 'sub-title' }} innerHtml={subTitle} />
            {bodyCopy2?.bodyCopy && (
              <DynamicContent tagName={'p'} attrs={{ className: 'body-text-2' }} innerHtml={bodyCopy2?.bodyCopy} />
            )}
            {!hidden && data.bodyCopy1 && (
              <DynamicContentTagReplace
                tagName="div"
                attrs={{ className: 'body-copy-1' }}
                innerHtml={data.bodyCopy1}
                replace={'{0}'}
                replaceWith="blockquote"
              />
            )}
            {!bannerVarient && data.readMoreText && data.readLessText && data.bodyCopy1 && (
              <Text
                tag="a"
                href="javascript:;"
                tabIndex="0"
                className={hidden ? 'hideCollapsibleContent rmbtn' : 'showCollapsibleContent rmbtn'}
                onClick={() => {
                  setHidden(!hidden);
                }}
                field={{
                  value: hidden ? data.readMoreText : data.readLessText,
                  editable: hidden ? data.readMoreText : data.readLessText,
                }}
              ></Text>
            )}
            {underlinedText && (
              <div className="verified-email">
                <Text
                  tag="a"
                  href="javascript:;"
                  tabIndex="0"
                  className={'email-link'}
                  field={{
                    value: underlinedText,
                    editable: false,
                  }}
                ></Text>
                {showSuccessIcon && <div className="success-icon">success icon</div>}
              </div>
            )}
          </div>
        )}
        {variant === UIConfig.kgfSnpVariant && renderThingsToRemember()}
        {variant === UIConfig.heroWithAttributes && renderHeroAttributes()}
        {!isEmpty(primaryCTA) && renderBuyNowButton(primaryCTA)}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'EditorialText', variant);
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
EditorialText.propTypes = {
  data: PropTypes.shape({
    bodyCopy: PropTypes.string,
    bookNowLabel: PropTypes.string,
    date: PropTypes.string,
    limitedLabel: PropTypes.string,
    isLimited: PropTypes.bool,
    isSoldOut: PropTypes.bool,
    isUpcoming: PropTypes.bool,
    fromPriceLabel: PropTypes.string,
    fromPriceValue: PropTypes.string,
    eventBookingUrl: PropTypes.string,
    shortTitle: PropTypes.string,
    shortTitleHeading: PropTypes.string,
    soldOutLabel: PropTypes.string,
    title: PropTypes.shape({
      text: PropTypes.string,
      heading: PropTypes.string,
    }),
    upcomingLabel: PropTypes.string,
    variant: PropTypes.string,
  }),
};

export default EditorialText;
