import React, { useEffect } from 'react';
import UIConfig from '../../../common/UIConfig';
import { DynamicContent, Overlay } from '../base';

const getDialogData = (title, bodyCopy, confirmText, handleConfirm, cancelText, handleCancel) => {
  return (
    <div className="overlay-data">
      {title?.length > 0 && (
        <DynamicContent
          tagName="h3"
          attrs={{ className: 'overlay-title heading-4', id: 'overlay-title' }}
          innerHtml={title}
        />
      )}
      {bodyCopy?.length > 0 && (
        <DynamicContent tagName="p" attrs={{ className: 'overlay-bodyCopy body-1' }} innerHtml={bodyCopy} />
      )}
      <div className="future-date-overlay-bottom">
        {confirmText?.length > 0 && (
          <div className="btn-add-to-cart">
            <div className="btn-yi-default btn-primary">
              <DynamicContent
                tagName="button"
                innerHtml={confirmText}
                attrs={{
                  onClick: (e) => handleConfirm && handleConfirm(e),
                  'aria-label': title + confirmText,
                  className: 'add-to-cart',
                }}
              />
            </div>
          </div>
        )}
        {cancelText?.length > 0 && (
          <div className="btn-yi-default cancel-btn btn-secondary">
            <DynamicContent
              tagName="button"
              attrs={{ onClick: (e) => handleCancel && handleCancel(e) }}
              innerHtml={cancelText}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const ConfirmationDialog = ({
  open,
  title,
  bodyCopy = '',
  confirmText,
  handleConfirm,
  cancelText,
  handleCancel,
  htmlContent,
  parentClass = '',
}) => {
  const openDialogue = (isOpen) => {
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: isOpen,
      dataToAppend:
        htmlContent ||
        getDialogData(title, bodyCopy, confirmText, handleConfirm, cancelText, handleCancel, parentClass),
      customClass: `${parentClass} purchase-overlay cart-delete-overlay v-b2c-overlay`,
      overlayCloseAriaLabel: '',
    });
  };

  useEffect(() => {
    openDialogue(open);
    window.PubSub.subscribe(UIConfig.events.CLOSE_OVERLAY, handleCancel);
  }, [open]);

  return <Overlay />;
};

export default ConfirmationDialog;
