import moment from 'moment';

import { groupBookingErrorAnalytics } from '../../../../common/analytics-events';
import { getB2CProductDetails } from '../../../../common/coveo-api';
import { Logging } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import {
  getErrorMap,
  getLoggedInUser,
  getArrayItemByKeyVal,
  createDeepCopy,
  getProdIdType,
  detectMobile,
  isEmpty,
  deepCopyArrayOfObjects,
  isMatchTenant,
  getProductType,
  checkTenant,
  isLoggedInUser,
} from '../../../../common/utility';
import GTMData from '../gtm-data';
import ProductStore from './product-store';

export const isRecommendationEnabled = (self) => {
  const recommendationTab =
    self.props.data.tabs &&
    self.props.data.tabs.length > 0 &&
    self.props.data.tabs.filter((tab) => {
      return tab.coveoValue.toLowerCase() === self.recommendationTabName;
    });
  return recommendationTab.length > 0 ? recommendationTab[0].enableRecommendation : false;
};
export const isPastPurchaseRecommendationEnabled = (self) => {
  const recommendationTab =
    self.props.data.tabs &&
    self.props.data.tabs.length > 0 &&
    self.props.data.tabs.filter((tab) => {
      return tab.type.toLowerCase() === UIConfig.b2c.purchaseJourney.pastPurchaseRecommendationTabCode;
    });

  return recommendationTab && recommendationTab.length;
};
export const isPastPurchasedEnabled = (self) => {
  const isPastPurchasedAddons =
    self.props.data.tabs &&
    self.props.data.tabs.length > 0 &&
    self.props.data.tabs.filter((tab) => {
      return tab.type.toLowerCase() === self.pastRecommendationTabName;
    });

  if (isPastPurchasedAddons) {
    return isPastPurchasedAddons.length;
  }
};

/**
 * Extract the upsell product ids and incorporate the product details
 * @param {object} upsell upsell object which contains ids of products
 */
export const getUpsellProductDetails = (self, upsell) => {
  const products = [];
  upsell.upsell_ruleItems.forEach((ruleItem) => {
    ruleItem.upsell_products.forEach((prod) => {
      products.push({
        price_difference: ruleItem.price_difference,
        ...self.getProductDetailsById(prod.id),
      });
    });
  });
  return products;
};

/**
 * accept a products map and call the open upsell overlay function with upsell products
 * if product details not found in cached products of current tab then make an api call
 * @param {object} products products map of upsell products
 */
export const showUpsellProducts = (self, products, upsellProducts, context, PJData) => {
  const prodsNotFound = Object.keys(self.porductsNotfound);
  if (prodsNotFound.length) {
    const param = {};
    param.key = self.props.data.coveoMappingList.productId;
    param.value = prodsNotFound;
    getB2CProductDetails({
      ...self.coveoDefaultParams,
      queryParams: [param],
    })
      .then((res) => {
        if (res.results.length < prodsNotFound.length) {
          //making data as per logging format
          const data = {
            config: {},
            request: {
              response: res.results.map((a) => a[param.key]),
            },
            data: param,
          };
          Logging(data, 'Upsell', false, 'Some products not found in coveo');
        }
        self.porductsNotfound = {};
        res.results = self.changeCategoryToString(res.results);
        const resultList = self.createMappedData(res.results);
        const updatedResults = resultList.map((item) => {
          if (item?.itemType === UIConfig.b2c.purchaseJourney.tabs.annual_pass) {
            return self.updateProductForCoveo(item);
          } else {
            return item;
          }
        });
        const results = self.isSwad ? updatedResults : resultList;
        upsellProducts = self.getRemainingProductDetails(upsellProducts, results);
        self.openUpsellOverlay(
          products,
          upsellProducts,
          context,
          PJData,
          self.props.data?.upsell?.isGAEnabledForUpSell,
        );
      })
      .catch((res) => {
        self.errorCallback('getCoveoProducts', res.error);
      });
  } else {
    self.openUpsellOverlay(products, upsellProducts, context, PJData, self.props.data?.upsell?.isGAEnabledForUpSell);
  }
};

export const changeCategoryToString = (self, prods) => {
  const tabKey = self.coveoDefaultParams.coveoKeyMap['tab'];
  return prods.map((product) => {
    if (product[tabKey] && product[tabKey] instanceof Array) {
      product[tabKey] = product[tabKey].sort().join('');
    }
    return product;
  });
};

/**
 * if product details not found in cached products of current tab
 * then get it from coveo results
 * @param {object} products products map of upsell products
 * @param {results}
 */
export const getRemainingProductDetails = (self, products, results) => {
  for (const key in products) {
    if (!products.hasOwnProperty(key)) {
      continue;
    }
    products[key].map((prod) => {
      if (!prod.productFound) {
        prod.productDetails = getArrayItemByKeyVal(results, { productId: prod.productId });
        if (prod.productDetails) {
          prod.productFound = true;
          prod.productDetails.productIdType = getProdIdType(prod.productDetails, self.groupingNotRequired);
        }
      }
      return prod;
    });
  }
  return products;
};

export const getGroupedUpsellProds = (self, upsells, validLength) => {
  const allProducts = [],
    groupedProducts = {};
  for (const key in upsells) {
    if (!upsells.hasOwnProperty(key)) {
      continue;
    }
    const upsellProds = upsells[key];
    upsellProds.forEach((prod) => {
      if (prod.productFound) {
        prod.productDetails.baseProduct = key;
        allProducts.push({ ...prod.productDetails, priceDiff: prod.price_difference });
      }
    });
  }

  self.upsellProdsAvailable = allProducts.length > 0;

  allProducts.forEach((prod) => {
    const key = prod.productIdType;
    groupedProducts[key] = groupedProducts[key] || [];
    groupedProducts[key].push(prod);
  });

  for (let key in groupedProducts) {
    if (!groupedProducts.hasOwnProperty(key)) {
      continue;
    }
    if (groupedProducts[key].length !== validLength) {
      delete groupedProducts[key];
    }
  }
  return groupedProducts;
};

export const getProductDetailsById = (self, productId) => {
  let productDetails = '',
    productFound = false;
  for (const key in self.products) {
    if (self.products.hasOwnProperty(key)) {
      const prodObj = self.products[key];
      const param = {};
      param[self.props.data.coveoMappingList.productId] = productId;
      productDetails = prodObj.getFilteredProductsFromKeyVal(param);
      productFound = productDetails.length > 0;
      if (productFound) {
        break;
      }
    }
  }

  if (productFound) {
    productDetails =
      self.isSwad &&
      self.createMappedData(productDetails)[0]?.itemType === UIConfig.b2c.purchaseJourney.tabs.annual_pass
        ? self.updateProductForCoveo(self.createMappedData(productDetails)[0])
        : self.createMappedData(productDetails)[0];
    productDetails.productIdType = getProdIdType(productDetails, self.groupingNotRequired);
  } else {
    self.porductsNotfound[productId] = true;
  }

  return {
    productId,
    productFound,
    productDetails: productDetails,
  };
};

/**
 * updateProduct function will be called on updating the quantity of product on cart and minicart
 * @param {Object} prod the product to be updated
 * @param {Number} quantity quantity change to be done on product quantity
 * @param {Number} maxQuantity maximum quantity allowed for a group
 */
export const updateProduct = (self, prod, quantity, maxQuantity, isAnnualPass) => {
  self.prductAddedToCart = { ...prod, quantity: quantity };
  prod.currQuantity = quantity;
  const remQty = Number(self.props.data.miniCart.maxCartQuantity) - Number(self.state.cartData.cart.totalQuantity);
  self.cartActions
    .updateCart(self.productList, [{ ...prod }], maxQuantity, remQty)
    .then((res) => {
      self.productList = deepCopyArrayOfObjects(res.productList);
      let productData = { products: [self.prductAddedToCart] };
      const productList = [];
      const { products } = productData;
      if (self.ifParks && isAnnualPass) {
        if (Array.isArray(products) && products.length > 1) {
          const { category, tab } = products[0];
          const prodCat = Array.isArray(category) ? category[0] : category;
          const sltdTab = self.props?.data?.tabs.find((tb) => tb.coveoValue === tab);
          const selectedTab = sltdTab.controls[Object.keys(sltdTab.controls)[0]].options.find(
            (itm) => itm.coveoValue === prodCat,
          );

          const selectedTabProductIds = selectedTab.productOverlaySelector.collections.map((itm) => itm.productId);

          productList.push(...products.filter((itm) => selectedTabProductIds.includes(itm.productId)));
          productList.push(...products.filter((itm) => !productList.some((prod) => prod.productId === itm.productId)));
          GTMData.push('addToCart', { products: productList });
        } else {
          const prod = productData?.products?.[0];
          const selectedPrd = { ...prod, quantity: Math.abs(Number(prod.quantity)), spinnerQty: isAnnualPass };
          if (prod.quantity > 0) {
            GTMData.push('addToCart', { products: [selectedPrd] });
          } else {
            GTMData.push('removeCartItem', {
              products: selectedPrd,
              isFromCartDelete: false,
            });
          }
        }
      }
      self.setState(
        {
          cartData: self.cartActions.getCustomCart(self.productList, true),
          errObj: getErrorMap('updateCart', {}, true, '', self.state.errObj),
          groupQtyErr: '',
        },
        () => {
          self.isMobileDevice && !self.eventPublished && self.publishEvent(true);
          window.PubSub.publish(UIConfig.events.MINICARTUPDATED, true);
        },
      );
    })
    .catch((res) => {
      const err = res.error || res.response.error; //for different cutom responses
      self.errorCallback('updateCart', err, prod);
    });
};

export const updateCartAfterDeleteAction = (self, cartData, groupQtyErr, errObj) => {
  self.setState(
    {
      cartData: cartData,
      errObj: getErrorMap('deleteCart', {}, true, '', errObj),
      groupQtyErr: groupQtyErr,
      addOnsErrorProdArr: [],
      cartEmpty: cartData.cart.items.length === 0,
    },
    () => {
      self.publishEvent(true);
      window.PubSub.publish(UIConfig.events.MINICARTUPDATED, true);
      document.querySelector('.c-progress-bar-wrapper .tab a') &&
        document.querySelector('.c-progress-bar-wrapper .tab a').focus();
    },
  );
};

/**
 * deleteProduct function will be called on updating the quantity of product on cart and minicart
 * @param {Object} prod the product to be deleted
 */
export const deleteProduct = (self, prod, maxQuantity) => {
  self.cartActions
    .deleteCart(createDeepCopy(self.productList), prod, maxQuantity)
    .then((res) => {
      self.productList = deepCopyArrayOfObjects(res.productList);
      let invalidQuantity = false;
      for (let i = 0; i < self.productList.length; i++) {
        if (self.productList[i].invalidQuantity) {
          invalidQuantity = true;
          break;
        }
      }
      // self.updateCouponCodeData();
      self.removeCouponCallback(); //Remove promocode if we are deleting any product in cart
      const groupQtyErr = invalidQuantity ? self.state.groupQtyErr : '',
        cartData = self.cartActions.getCustomCart(self.productList, true),
        remQty = Number(self.props.data.miniCart.maxCartQuantity) - Number(cartData.cart.totalQuantity),
        errObj = remQty >= 0 ? getErrorMap('updateCart', {}, true, '', self.state.errObj) : self.state.errObj;
      if (!self.props.data?.isCrossSellRecommendationEnable && self.showMinicartCouponCode) {
        self.renderCartUI(getErrorMap(self.props.data.services.deleteCart.url, {}, true));
      }

      self.updateCartAfterDeleteAction(cartData, groupQtyErr, errObj);
    })
    .catch((res) => {
      self.isMobileDevice && res.error.code === 'APIC001' && self.eventPublished && self.publishEvent(false);
      self.errorCallback('deleteCart', res.error);
      window.PubSub.publish(UIConfig.events.MINICARTUPDATED, true);
      document.querySelector('.c-progress-bar-wrapper .tab a') &&
        document.querySelector('.c-progress-bar-wrapper .tab a').focus();
      self.removeCouponCallback(); //Remove promocode if we dont have any product in cart
    });
};

/**
 * enrichData function will add the keys to products which would be needed for cart and checkbasket
 * @param {Object} products array of the products to be updated
 * @param {Object} data selection made in tabs
 */
export const enrichData = (self, products) => {
  const result = products.map((prod) => {
    if (
      prod &&
      prod.itemType &&
      (prod.itemType === UIConfig.moneyCardProduct.ticketType ||
        prod.itemType === UIConfig.moneyCardProduct.flexibleTicketType)
    ) {
      prod.fromDate = '';
      prod.toDate = '';
    } else if (prod.fromDate) {
      prod.isFlexible = prod.isFlexible || prod.accessPolicy === '1EAD'; //added to identify flexible prod
      prod.toDate = self.calculateToDate(prod, self.props.data.mupValidityMap);
    } else {
      prod.fromDate = '';
      prod.toDate = '';
    }

    //For Yas Arena - itemType is not coming in coveo products.
    if (self.isYAEventListingPage && !prod.hasOwnProperty('itemType') && prod.hasOwnProperty('crossSellType')) {
      prod.itemType = prod.crossSellType;
    }

    prod.productType = getProductType(prod); // added a tab category to find out prduct tab category
    prod.productIdType = getProdIdType(prod, self.groupingNotRequired);
    prod.price = {};
    prod.price.gross = prod.gross || 0;
    prod.price.net = prod.net || 0;
    prod.price.tax = prod.tax || 0;
    prod.discount = prod.discount || {}; //for checkbasket
    prod.discount.actualPerc = prod.discount.actualPerc || 0; //for checkbasket
    prod.discount.computed = prod.unitPrice ? prod.unitPrice - prod.gross : 0;
    prod.unitPrice = prod.unitPrice || prod.gross;

    delete prod['description']; // Delete description for CART API.

    return prod;
  });
  return result;
};

/**
 * caculate "to" date of on the basis of product type and from date
 * @param {Object} date contains date reated data like flexible, from, range
 * @param {object} prod product for which to date calculated
 */
export const calculateToDate = (self, prod, mupValidityMap) => {
  const to = moment(prod.fromDate).clone();
  if (prod.validUntil) {
    return moment(prod.validUntil)
      .locale('en')
      .format(UIConfig.calendar.dateFormat);
  } else if (prod.isFlexible) {
    if (prod?.itemType === 'VCH') {
      to.add(prod.rangeInMonths, 'days');
    } else {
      to.add(prod.rangeInMonths, 'month');
      to.subtract(1, 'days');
    }
  } else if (prod.itemType === 'MUP' && mupValidityMap.hasOwnProperty(prod.accessPolicy)) {
    to.add(mupValidityMap[prod.accessPolicy] - 1, 'day');
  }
  return parseInt(prod.validUntilDate) ? prod.toDate : to.locale('en').format(UIConfig.calendar.dateFormat);
};

/**
 * extract the discounted products as pere provided discountMap
 * and add the unitPrice key which is the price of base product
 * @param {Object} products array of all products of gien category
 * @param {Number} days days in advance for which booking is done
 * @param {Object} discountMap map containg details of discount against the range of days
 */
export const getDiscountedProducts = (self, products, days, discountMap, disableDiscount) => {
  discountMap = discountMap || self.props.data.discountMap;
  const baseProducts = [],
    selectedProducts = [],
    baseProductPrices = {},
    baseDis = self.getDiscount(0, discountMap, products),
    selectedDis = self.getDiscount(days, discountMap, products);

  products.forEach((prod) => {
    const prodCode = prod.pricing;
    prod.discount = {};
    prod.discount.actualPerc = 0;
    prod.discount.computed = 0;
    if (prodCode === baseDis.code) {
      prod.unitPrice = prod.gross || 0;
      baseProducts.push(prod);
      baseProductPrices[prod.classType] = prod.gross || 0;
    } else if (prodCode === selectedDis.code) {
      prod.discount.actualPerc = selectedDis.discountPer;
      selectedProducts.push(prod);
    }
  });
  if (baseDis.code === selectedDis.code || disableDiscount) {
    return baseProducts;
  } else {
    selectedProducts.forEach((prod) => {
      prod.unitPrice = baseProductPrices[prod.classType];
    });
    return selectedProducts;
  }
};

/**
 * extract discount code from discount map provided
 * @param {Number} days days in advance for which booking is done
 * @param {Object} discountMap map containg details of discount against the range of days
 */
export const getDiscount = (self, days, discountMap, products) => {
  if (isMatchTenant(UIConfig.ymcB2CTenant) && products && products.length > 0) {
    const { pricing } = products[0];
    const matched = discountMap.find(({ code }) => code === pricing);
    return matched && !isEmpty(matched) ? matched : discountMap[discountMap.length - 1];
    // return discountMap[i].code === pricing ? discountMap[i] : discountMap[discountMap.length - 1];
  } else {
    for (let i = 0; i < discountMap.length - 1; i++) {
      if (days >= discountMap[i].days && days < discountMap[i + 1].days) {
        return discountMap[i];
      }
    }
  }

  return discountMap[discountMap.length - 1];
};

/**
 * error handling
 * @param {Object} err error object
 */
export const errorCallback = (self, serviceName, error, prod = null) => {
  if (error) {
    if (error.code === UIConfig.errorCodes.emptyCart) {
      //in case of emptycart
      self.productList = [];
      self.setState({
        cartData: self.cartActions.getCustomCart(self.productList, true),
        cartEmpty: true,
        errObj: getErrorMap('getCart', {}, true, '', self.state.errObj),
        groupQtyErr: '',
      });
    } else if (error.code === UIConfig.errorCodes.invalidQuantity) {
      self.productList = error.updatedProductList;
      groupBookingErrorAnalytics(prod && prod.productName, window.dataLayer && window.dataLayer[0].pageName);
      self.setState({
        groupQtyErr: error,
        cartData: self.cartActions.getCustomCart(self.productList, true),
      });
    } else if (error.code === UIConfig.errorCodes.invalidCartQuantity) {
      self.productList = error.updatedProductList;
      const errObj = getErrorMap(serviceName, self.props.data.miniCart.businessErrors, false, error, self.state.errObj);
      self.setState({
        errObj: errObj,
        cartData: self.cartActions.getCustomCart(self.productList, true),
      });
    } else if (serviceName === 'getCoveoProducts' && error.code.toString() === UIConfig.errorCodes.notFound) {
      const errObj = getErrorMap(
        serviceName,
        self.props.data.services[serviceName].errors,
        false,
        error,
        self.state.purchaseWrapperErrors,
      );
      self.setState({
        purchaseWrapperErrors: errObj,
      });
    } else {
      const errObj = getErrorMap(
        serviceName,
        self.props.data.services[serviceName].errors,
        false,
        error,
        self.state.errObj,
      );
      self.setState({
        errObj: errObj,
      });
    }
  }
};

/**
 * Creates the list of products shown under each booking tab
 * For Recommendations tab, we filter the recommended items shown based on
 * the products present in the cart
 */
export const createProductStore = (self, tab, param, isPreLoader) => {
  if (tab.toLowerCase() === self.recommendationTabName) {
    // Get products from cart
    let cartData = self.state.cartData;
    let items = Object.values(cartData.cart.items);
    let recommendedItems = self.getRecommendedItems(items);
    return Promise.all([self.getProductStore(tab, param, isPreLoader), recommendedItems])
      .then((response) => {
        const recommendedProductIds = Array.prototype.concat.apply(
          [],
          response.length > 0 ? response[1].map((rp) => rp.PRODUCTID) : [],
        );

        let filteredProducts = response[0].products.filter((item) => {
          return recommendedProductIds.indexOf(item[self.props.data.coveoMappingList.productId]) > -1;
        });

        return new ProductStore(filteredProducts);
      })
      .catch((error) => {
        return error;
      });
  } else {
    return self.getProductStore(tab, param, isPreLoader);
  }
};

export const getProductStore = (self, tab, param, isPreLoader, isSwadCrossSellEnabled) => {
  return new Promise((resolve, reject) => {
    if (!self.products[tab]) {
      let params = self.tabs[tab] ? [self.tabs[tab]] : param ? [param] : [];
      if (isSwadCrossSellEnabled) {
        params = [];
      }
      params.push({ key: self.props.data.coveoMappingList.disabledProduct, value: '0' });

      getB2CProductDetails({
        ...self.coveoDefaultParams,
        queryParams: params,
        isPreLoader,
      })
        .then((res) => {
          res.results = self.changeCategoryToString(res.results);
          self.products[tab] = new ProductStore(res.results);
          self.setState({
            purchaseWrapperErrors: '',
          });
          resolve(self.products[tab]);
        })
        .catch((res) => {
          self.errorCallback('getCoveoProducts', res.error);
          const errObj = getErrorMap(
            'getCoveoProducts',
            self.props.data.services.getCoveoProducts.errors,
            false,
            res.error,
          );
          reject(errObj);
        });
    } else {
      resolve(self.products[tab]);
    }
  });
};

export const getProductQuantityInCart = (self, prod) => {
  if (self.productList && self.productList.length) {
    for (let i = 0; i < self.productList.length; i++) {
      const item = self.productList[i];
      if (prod.productId === item.productId && prod.fromDate === item.fromDate) {
        return item.quantity;
      }
    }
  }
  return 0;
};

// make mini cart sticky
export const stickyCart = (self) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const miniCartWrapper = document.getElementsByClassName('b2c-mini-cart-wrapper')[0];
  const miniCartref = document.getElementsByClassName('c-b2c-mini-cart')[0];
  const tabs = document.getElementsByClassName('c-booking-tabs')[0];
  const header = document.getElementsByClassName('c-header')[0];
  const tabsPanel = document.querySelector('.tabs-panel-container');
  const tabsmenu = document.querySelector('.tabs-menu-container');
  if (!miniCartWrapper || !miniCartref || !tabs || !header) {
    return;
  }
  const tabsTop = tabs.getBoundingClientRect().top + window.pageYOffset;
  if (
    window.pageYOffset >=
      tabsTop -
        (header.classList.contains('sticky-header') ? (isSwad ? header.offsetHeight + 74 : header.offsetHeight) : 0) &&
    window.pageYOffset <=
      miniCartWrapper.offsetHeight +
        miniCartWrapper.offsetTop -
        miniCartref.offsetHeight -
        (header.classList.contains('sticky-header') ? (isSwad ? header.offsetHeight + 74 : header.offsetHeight) : 0) -
        40
  ) {
    miniCartWrapper.classList.add('sticky-cart');
    miniCartref.style.position = 'fixed';
    miniCartref.style.top =
      (header.classList.contains('sticky-header') ? (isSwad ? header.offsetHeight + 74 : header.offsetHeight) : 0) +
      'px';
  } else if (detectMobile() || window.pageYOffset <= tabsTop) {
    miniCartWrapper.classList.remove('sticky-cart');
    miniCartref.style.position = 'relative';
    miniCartref.style.top = '0px';
  } else {
    miniCartWrapper.classList.remove('sticky-cart');
    miniCartref.style.top = isSwad
      ? miniCartWrapper.offsetHeight - miniCartref.offsetHeight + 'px'
      : miniCartWrapper.offsetHeight - miniCartref.offsetHeight - 40 + 'px';
    miniCartref.style.position = 'relative';
  }
};

//Method to restrict the Recommendation API flow multiple calls
export const handleAddOnVisibility = (self, cartItems, prevState) => {
  const isData = self.state.cartData && prevState.cartData;
  const prevValues = Object.values(prevState.cartData.cart.items);
  const updatedStateValues = Object.values(cartItems);

  if (isData && updatedStateValues.length > prevValues.length && prevValues.length === 0) {
    self._yaAddOnSvc
      .getRecommendedProducts({
        cartItems: cartItems,
      })
      .then((response) => {
        return self._availabilitySvc.getAvailability({
          coveoMappedData: response.mappedProducts,
          cartItems,
        });
      })
      .then((response) => {
        const updatedCartProperties = self._availabilitySvc.yaGetUpdatedProductList({
          productList: [...self.productList],
          cartActionProps: {
            serviceUrl: self.props.data.services,
            moduleName: 'purchase-journey',
            tenantId: getLoggedInUser().tenantID,
            isSeatedTypeJourney: self.isSeatedTypeJourney,
            coveoMappingList: self.props.data.coveoMappingList,
            isYaNonSeatedJourney: self.isYaNonSeatedJourney,
          },
          updatedAvailability: response.availability,
          returnGroupedData: true,
        });

        const { err, customCart, productList } = updatedCartProperties;
        self.productList = deepCopyArrayOfObjects(productList);

        //Setting state with cartData, availabilty product mapping and group qty error
        self.setState({
          cartData: customCart,
          availabilityProductMapping: response.availability,
          groupQtyErr: err,
          isAddOns: true,
          addOnProducts: response.coveoMappedData,
        });
      });
  }
  // Maximum Availabilty handling in case of Non Seated Event in case of change/updation of main product
  if (self.isNonSeatedEvent && cartItems.length > 0 && prevValues.length !== 0) {
    //Main product quantities of updated and Prev Cart
    const currentQuantity = self._availabilitySvc.getMainProductQuantity(cartItems);
    const prevQuantity = prevValues && self._availabilitySvc.getMainProductQuantity(prevValues);

    if (prevQuantity !== currentQuantity) {
      const updatedAvailability = self._availabilitySvc.getAvailabiltyProductMapping({
        coveoMappedData: self._availabilitySvc.coveoMappedData,
        availabilityFromApi: self._availabilitySvc.availabilityFromApi,
        performanceQuantity: currentQuantity,
      });

      const updatedCartProperties = self._availabilitySvc.yaGetUpdatedProductList({
        productList: [...self.productList],
        cartActionProps: {
          serviceUrl: self.props.data.services,
          moduleName: 'purchase-journey',
          tenantId: getLoggedInUser().tenantID,
          isSeatedTypeJourney: self.isSeatedTypeJourney,
          coveoMappingList: self.props.data.coveoMappingList,
          isYaNonSeatedJourney: self.isYaNonSeatedJourney,
        },
        updatedAvailability,
        returnGroupedData: true,
      });

      const { err, customCart, productList } = updatedCartProperties;
      self.productList = deepCopyArrayOfObjects(productList);

      //Setting state with cartData, availabilty product mapping and group qty error
      self.setState({
        cartData: customCart,
        availabilityProductMapping: updatedAvailability,
        groupQtyErr: err,
      });
    }
  }
};

export const applyCouponCallback = (self, response, isSuccess) => {
  if (isSuccess) {
    const couponData = {
      promotions: [],
    };
    const promoModel = {
      code: response.coupondetails.coupon.code,
      name: response.coupondetails.coupon.name,
      type: response.coupondetails.coupon.type,
      description: '',
      discount: '',
    };
    couponData.promotions.push(promoModel);
    couponData.couponCode = response.coupondetails.coupon.code;
    self.updateCartProducts('', '', '', couponData);
    self.cartActions._storeExtraCartParams(couponData);
  }
  if (response.coupondetails && response.coupondetails.coupon) {
    window.PubSub.publish(
      response.coupondetails && UIConfig.events.ON_ADDING_PROMOCODE,
      response.coupondetails.coupon.code,
    );
  }
};

/**
 * renderCartUI function to do check basket and render cart
 * @return   {[Object]} returns html .
 */
export const renderCartUI = (self, errorData = null) => {
  self.CartActionObj.getCartMashup('grouped')
    .then((response) => {
      if (response.data && response.data.cart) {
        self.updateCartState({
          cartResponse: self.wrapCartResponse(response.data.cart),
          cartData: self.cartActions.getCustomCart(self.productList),
          // cartData: self.cartActions.getCustomCart(self.productList, true),
          errObj: errorData,
        });
      }
    })
    .catch((apiData) => {
      self.handleCheckBasketError(apiData);
    });
};

export const renderEmptyCart = (self) => {
  self.updateCartState({
    cartData: self.emptyCartModel,
    event: null,
    errObj: null,
    bErrObj: null,
    cartEmpty: true,
    groupQtyErr: null,
  });
};

/**
 * handleCheckBasketError function to handle check basket errors
 * @param    {[Object]} apiData [response form check basket].
 * @return   {[Object]} returns html .
 */
export const handleCheckBasketError = (self, apiData) => {
  const error = apiData.error || (apiData.response && apiData.response.error);
  if (error) {
    if (self.isCartEmpty(apiData)) {
      self.renderEmptyCart();
    } else if (apiData.response && apiData.response.data && apiData.response.data.cart) {
      self.updateCartState({
        cartData: self.wrapCartResponse(apiData.response.data.cart),
        event: null,
        errObj: getErrorMap(
          self.props.data.services.getCartMashup.url,
          self.props.data.services.getCartMashup.errors,
          false,
          apiData.response.data.error,
          self.state.errObj,
          ['5002'],
        ),
      });
    } else if (apiData.cart) {
      self.updateCartState({
        cartData: self.wrapCartResponse(apiData.cart),
        event: null,
        errObj: getErrorMap(
          self.props.data.services.getCartMashup.url,
          self.props.data.services.getCartMashup.errors,
          false,
          apiData.error,
          self.state.errObj,
          ['5002'],
        ),
      });
    } else {
      self.setState({
        errObj: getErrorMap(
          self.props.data.services.getCartMashup.url,
          self.props.data.services.getCartMashup.errors,
          false,
          error,
          self.state.errObj,
          ['5002'],
        ),
      });
    }
  }
};

/**
 * wrapCartResponse function to wrap cart response in a cart object
 * @param    {[Object]} cart [cart object without outer cart object].
 * @return   {[Object]}cart [cart object wrapped in cart object] .
 */
export const wrapCartResponse = (self, cartAPIData) => {
  const wrapperData = {
    cart: cartAPIData,
  };
  return wrapperData;
};

export const validateQuantityBeforeCartUpdate = (self, product, quantity, maxQuantity, extraCartParams) => {
  let isMaxLimit = false;
  if (
    self.isRecommendationControlled &&
    extraCartParams &&
    (extraCartParams.hasOwnProperty('addOnsErrorArray') || extraCartParams.hasOwnProperty('isMaxLimit'))
  ) {
    isMaxLimit = extraCartParams.isMaxLimit;
    self.setState({ addOnsErrorProdArr: extraCartParams.addOnsErrorArray });
  }
  if (!isMaxLimit) {
    self.updateCartProducts(product, quantity, maxQuantity, extraCartParams);
  }
};

/**
 * updateCartProducts function to update cart products
 * @param    {[Object]} product [product ot be modified].
 * @param    {[Number]} quantity [changed quantity of product].
 * @param    {[Number]} maxQuantity [maximum quantity that can be added for give product].
 * @param    {[Object]} extraCartParams [extraParameters required to be added in cart].
 * @return   {[void]} setState to render final cart after updation .
 */
export const updateCartProducts = (self, product, quantity, maxQuantity, extraCartParams) => {
  const isPackageProd =
    (product.hasOwnProperty('packageGroupCode') && product.packageGroupCode !== null) ||
    (product.hasOwnProperty('packageCode') && product.packageCode !== null);
  const productArr = [];

  if (isPackageProd) {
    product.items.forEach((item) => {
      item.products.forEach((subItem) => {
        subItem.packageQty = quantity;
        productArr.push(subItem);
      });
    });
  } else if (product) {
    product.currQuantity = quantity;
    productArr.push(product);
  }

  const cartCurrentData = self.cartActions.getCustomCart(self.productList, false);

  const productList = self.state.cartData.cart.items.category
    ? self.state.cartData.cart.items
    : cartCurrentData.cart.items;

  self.CartActionObj.updateCart(
    productList,
    productArr,
    maxQuantity,
    self.props.data.miniCart.maxCartQuantity - self.state.cartData.cart.totalQuantity,
    extraCartParams,
  )
    .then(() => {
      //do a check basket
      self.renderCartUI(getErrorMap(self.props.data.services.updateCart.url, {}, true));
    })
    .catch((response) => {
      const serverError = response.error ? response : response.response.error ? response.response : { error: {} };
      if (serverError.error.code === UIConfig.errorCodes.invalidQuantity) {
        groupBookingErrorAnalytics(product.productName, window.dataLayer && window.dataLayer[0].pageName);
        const cart = self.CartActionObj.getCustomCart(serverError.error.updatedProductList);
        self.updateCartState({
          cartResponse: cart,
          cartData: cart,
          event: null,
          errObj: self.state.errObj,
          bErrObj: serverError.error,
        });
      } else if (serverError.error.code === UIConfig.errorCodes.invalidCartQuantity) {
        groupBookingErrorAnalytics(product.productName, window.dataLayer && window.dataLayer[0].pageName);
        self.errorCallback('updateCart', serverError.error, serverError.error.updatedProductList[0]);
        self.setState({ groupQtyErr: serverError.error });
      } else {
        //error handling
        self.setState({
          errObj: getErrorMap(
            self.props.data.services.updateCart.url,
            self.props.data.services.updateCart.errors,
            false,
            serverError.error,
            self.state.errObj,
          ),
        });
      }
    });
};

export const setCartDefaults = (self, props) => {
  const serviceUrl = props.data.services;
  if (props.isMiniCart && !isLoggedInUser()) {
    delete serviceUrl.getCartMashup;
    delete serviceUrl.getCartMashupAnonymous;
  }
  return {
    serviceUrl: serviceUrl,
    moduleName: 'Minicart',
    tenantId: self.getTenantId(),
    is2StepPaymentJourney: self.isCartPaymentView || self.showMinicartCouponCode,
    isSeatedTypeJourney: self.isSeatedTypeJourney,
    coveoMappingList: props.data.coveoMappingList,
  };
};
//past purchase error display
export const isYAEmptyBookingPage = (self, isYARecommendationVisible) => {
  if (self.props.data.tabs && isPastPurchaseRecommendationEnabled(self) && self.props.data.tabs.length === 1) {
    self.setState({ isYARecommendationVisible: isYARecommendationVisible });
  }
};
// Check if recommendation is enabled  and it is not visible - for F1 booking page
export const isEmptyBookingPage = (self) =>
  !self.withoutPurchase &&
  self.props.data.tabs &&
  self.isRecommendationEnabled() &&
  !self.state.isRecommendationVisible &&
  self.props.data.tabs.length === 1;

export const getEmptyBookingData = (self) => {
  const textWithCtaSettings = self.props.data && self.props.data.textWithCtaSettings;
  const { emptyCart, emptyRecommendation } = UIConfig.b2c.purchaseJourney.ymcMapping;
  if (textWithCtaSettings) {
    const { ctaMapper, mapperValues } = textWithCtaSettings;
    if (ctaMapper && ctaMapper.length && !isEmpty(mapperValues)) {
      return Object.keys(self.state.cartData.cart.items).length === 0 && !self.state.isYARecommendationVisible
        ? ctaMapper[mapperValues[emptyCart]]
        : ctaMapper[mapperValues[emptyRecommendation]];
    }
  }
};

export const bookingTabsDataMapper = (self, props) => {
  const {
    visitorSelector,
    timeSlotSelector,
    productOverlaySelector,
    info,
    dateSelector,
    enableDynamicCalendar,
    disableTotalPrice,
  } = props.data.tabs[0].controls.extras.options[0];
  const tabs = [...props.data.tabs[0].controls.extras.options[0].ticketSelector.options];
  const tabsData = props;

  const mergeTabDetails = tabs.map((tab) => ({
    ...tab,
    visitorSelector,
    timeSlotSelector,
    productOverlaySelector,
    info,
    dateSelector,
    enableDynamicCalendar,
    disableTotalPrice,
  }));

  tabsData.data.tabs[0].controls.extras.options = [...mergeTabDetails];
  self.setState({ productWidgetOnly: tabsData.data.tabs });
};
