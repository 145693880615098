import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getCurrency, toLowerCase, getGa4Category } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import GTMData from '../gtm-data';

const TicketUpgradeConfirmation = ({ ticketUpgradeData, ticketUpgradeBackgroundImage }) => {
  let currency = getCurrency();

  useEffect(() => {
    const titleHeight = document.querySelector('.ticket-upgrade-heading')?.offsetHeight ?? 0;
    const ticketUpgradeSuggestion = document.querySelector('.ticket-upgrade-options-list')?.offsetHeight ?? 0;
    const suggestionHeight = document.querySelector('.ticket-upgrade-options-list');

    if (titleHeight > ticketUpgradeSuggestion) {
      suggestionHeight.style.height = `${titleHeight}px`;
    }
  }, []);

  const handleOnClick = () => {
    GTMData.push(
      UIConfig.ga4Constants.CLICK_CTA,
      {
        name: toLowerCase(ticketUpgradeData?.ticketUpgradeCTALabel),
        [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(ticketUpgradeData?.ticketUpgradeCTALabel),
        category: getGa4Category(window?.location?.pathname),
      },
      false,
      false,
      true,
    );

    setTimeout(() => {
      window.location.href = ticketUpgradeData?.ticketUpgradeCTA;
    }, 10);
  };

  return (
    <div className="ticket-upgrade-container">
      <div className="ticket-upgrade-background-image-div">
        <img
          className="ticket-upgrade-background-image-src"
          src={ticketUpgradeBackgroundImage?.src}
          data-src={ticketUpgradeBackgroundImage?.src}
          alt={ticketUpgradeBackgroundImage?.alt}
        />
      </div>
      <div className="ticket-upgrade-content">
        {/* Ticket Upgrade Heading */}
        <div className="ticket-upgrade-heading">
          <Text
            tag="p"
            className="ticket-upgrade-subheading"
            field={{
              value: ticketUpgradeData?.saveTimeAndMoneyLabel,
              editable: ticketUpgradeData?.saveTimeAndMoneyLabel,
            }}
          />
          <Text
            tag="p"
            className="ticket-upgrade-title"
            field={{
              value: ticketUpgradeData?.upgradeBenefitsLabel,
              editable: ticketUpgradeData?.upgradeBenefitsLabel,
            }}
          />
        </div>
        {/* Suggedted Upgradable Tickets */}
        <div className="ticket-upgrade-options-list">
          {ticketUpgradeData?.ticketUpgradeSuggestions?.map((data) => {
            return (
              <div key={data.productId} className="ticket-upgrade-options">
                <Text
                  tag="p"
                  className="ticket-upgrade-options-offer"
                  field={{ value: data.productName, editable: data.productName }}
                />
                <Text
                  tag="p"
                  className="ticket-upgrade-options-from"
                  field={{
                    value: ticketUpgradeData?.ticketUpgradeFromLabel,
                    editable: ticketUpgradeData?.ticketUpgradeFromLabel,
                  }}
                />
                <div className="ticket-upgrade-options-amount-details">
                  <Text
                    tag="p"
                    className="ticket-upgrade-options-reject-amount"
                    field={{
                      value: `${currency} ${data.productPrice}`,
                      editable: `${currency} ${data.productPrice}`,
                    }}
                  />
                  <Text
                    tag="p"
                    className="ticket-upgrade-options-final-amount"
                    field={{
                      value: `${currency} ${data.productUpgradePrice}`,
                      editable: `${currency} ${data.productUpgradePrice}`,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {/* Ticket Upgrade CTA */}
        <div className="ticket-upgrade-btn">
          <div className="btn-primary">
            <button type="button" onClick={handleOnClick}>
              {ticketUpgradeData?.ticketUpgradeCTALabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketUpgradeConfirmation;
