const svgSizes = {
  'icn-rmedia-top': '0 0 1366 125',
  'icn-rmedia-bot': '0 0 1366 120',
  'hero-panel-curve-no-tiles': '0 0 1366 176',
  'hero-panel-curve-with-tiles': '0 0 1366 108',
  'icn-infobox-smile': '0 0 1366 107',
  'footer-curve': '0 0 1366 188',
  'icn-cta-smile': '0 1 1365 136',
  'icn-default-smile-top': '0 0 1440 122',
};

export default svgSizes;
