import React from 'react';
import useScript from '../../../hooks/useScript';
import { getDataLayerScript } from '../common-analytics-scripts';
import { checkGA4Tenants } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';

const AnalyticsNoRefresh = (props) => {
  useScript(!checkGA4Tenants([UIConfig.tenants.yi]) && getDataLayerScript(props));

  const tagScript = `
    var runScript = true;
    if (localStorage.orderStatus) {
        var orderStatus = JSON.parse(localStorage.orderStatus);
        runScript = !orderStatus;
    }`;

  useScript(tagScript);

  return <React.Fragment></React.Fragment>;
};

export default AnalyticsNoRefresh;
