import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';

import { logComponentRenderingError } from '../../../common/logger';

const HotelBookingWidgetCategorySelector = forwardRef((props, ref) => {
  try {
    return (
      <>
        <div className="c-hotel-booking-widget-category-selector">
          <div className="category-label">{props.dataList.categoryTitle}</div>
          <div className="category-dropdown-wrapper">
            <span className={`error-label ${props.holidayExperienceLabelError && 'category-error'}`}>
              {props.categoryValidation}
            </span>
            <Dropdown
              options={props.dataList.categories}
              onChange={props.onSelect}
              placeholder={props.dataList.categoryPlaceholder}
              defaultMenuIsOpen={false}
              className={props.dataList.categories.length === 1 ? 'default-category-selector' : ''}
              value={props.dataList.categories.length === 1 ? props.dataList.categories[0] : props.defaultOption}
            />
          </div>
        </div>
        {props.mobileWidget && props.showInformativeText && (
          <div className="c-hotel-booking-widget-category-selector">
            <div className="category-dropdown-wrapper">
              <div className="informative-text">
                <div className="content">{props.showInformativeText}</div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HotelBookingWidgetCategorySelector', props.data.variant);
  }
});

export default HotelBookingWidgetCategorySelector;

HotelBookingWidgetCategorySelector.propTypes = {
  onSelect: PropTypes.func,
  dataList: PropTypes.object,
};
