import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import CartActions from '../cart/cart-actions';
import { PerformanceService } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import { isEmpty } from '../../../../common/utility';

export default class AddOnAvailabilityService {
  constructor(data) {
    this.data = data;
  }

  // Creates request body in the specific format using mappedData
  getRequestBody = (coveoMappedData, performanceDetails) => {
    const perfMapping = {};
    const requestBody = {
      productDetail: [],
    };
    //Mapping and adding all the common performance type wih the respective key
    coveoMappedData.forEach((item) => {
      const commonParams = {
        productid: item.productId,
        eventak: item.eventId && item.eventId[0],
        crossSellCode: item.crossSellType,
      };
      const iTemp = [commonParams];
      const temp = perfMapping[item.performanceType];

      if (!temp) {
        perfMapping[item.performanceType] = {
          products: iTemp,
        };
      } else {
        temp.products.push(...iTemp);
      }
    });

    if (perfMapping[UIConfig.addOnPerformanceType.SHOW_DATE_AND_TIME]) {
      perfMapping[UIConfig.addOnPerformanceType.SHOW_DATE_AND_TIME] = {
        ...perfMapping[UIConfig.addOnPerformanceType.SHOW_DATE_AND_TIME],
        fromTime: performanceDetails.openTime,
        toTime: performanceDetails.closeTime,
      };
      requestBody.productDetail.push(perfMapping[UIConfig.addOnPerformanceType.SHOW_DATE_AND_TIME]);
    }
    if (perfMapping[UIConfig.addOnPerformanceType.SHOW_DATE]) {
      perfMapping[UIConfig.addOnPerformanceType.SHOW_DATE] = {
        ...perfMapping[UIConfig.addOnPerformanceType.SHOW_DATE],
        date: performanceDetails.performanceDate,
      };
      requestBody.productDetail.push(perfMapping[UIConfig.addOnPerformanceType.SHOW_DATE]);
    }
    return requestBody;
  };

  // Method uses searchPerformance
  getAvailability = ({ coveoMappedData, cartItems, preLoaderTarget = UIConfig.loader.defaultPreLoaderTarget }) => {
    const { date: performanceDate, closeTime, openTime } = YALocalStorageSvc.getSetPerformance();
    const requestData = this.getRequestBody(coveoMappedData, { performanceDate, closeTime, openTime });

    const url = this.data.services.getAddOnProductAvailability.url;
    return new Promise((resolve, reject) => {
      const performanceQuantity = this.getMainProductQuantity(cartItems);
      let availability = {};

      if (requestData.productDetail.length > 0) {
        PerformanceService.searchPerformance(url, performanceDate, performanceDate, requestData, true, preLoaderTarget)
          .then((response) => {
            availability = this.calculateMaxAvailability(response.data, coveoMappedData, performanceQuantity);
            resolve({ availability, coveoMappedData });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        availability = this.getAvailabiltyProductMapping({
          availabilityFromApi: {},
          coveoMappedData,
          performanceQuantity,
        });
        resolve({ availability, coveoMappedData });
      }
    });
  };

  getMainProductQuantity = (cartItems) => {
    let productQuantity = 0;
    for (let item of cartItems) {
      if (item.products) {
        for (let product of item.products) {
          if (!product.crossSellType) {
            productQuantity += Number(product.quantity);
          }
        }
      } else if (!item.crossSellType) {
        productQuantity += Number(item.quantity);
      }
    }
    return productQuantity;
  };

  calculateMaxAvailability = (availabilityResponse, coveoMappedData, performanceQuantity) => {
    const concatenatedRepsonse = [];
    const availabilityFromApi = {};
    //Concatenating the multi level response to a direct response
    availabilityResponse.performancelist.performance.forEach((item) => {
      const temp = [...item.productAvailability];
      for (let product of temp) {
        availabilityFromApi[product.productId] = {
          availability: product.availability.available,
          performanceId: item.performanceId,
        };
      }
      concatenatedRepsonse.push(...temp);
    });

    return this.getAvailabiltyProductMapping({
      coveoMappedData,
      availabilityFromApi,
      performanceQuantity,
    });
  };

  getAvailabiltyProductMapping = (args) => {
    const finalAvailability = {};
    if (!isEmpty(args) && args.coveoMappedData) {
      for (let product of args.coveoMappedData) {
        //Creating the product key and addOnQuantity availability
        if (product.AddonQuantityType && product.AddonQuantityType === 'ByTicket') {
          const quantityTypeAvailabilty = product.AddonQuantity * args.performanceQuantity;
          finalAvailability[product.productId] = args.availabilityFromApi[product.productId]
            ? Math.min(args.availabilityFromApi[product.productId].availability, quantityTypeAvailabilty)
            : quantityTypeAvailabilty;
        } else {
          finalAvailability[product.productId] = args.availabilityFromApi[product.productId]
            ? Math.min(product.AddonQuantity, args.availabilityFromApi[product.productId].availability)
            : product.AddonQuantity;
        }
      }
      finalAvailability.availabilityFromApi = args.availabilityFromApi;
      this.availabilityFromApi = args.availabilityFromApi;
      this.coveoMappedData = args.coveoMappedData;
      YALocalStorageSvc.getSetAddonsAvailabilty(finalAvailability);
    }
    return finalAvailability;
  };

  //Return the add ons that needs to be updated so that carts max quantity remains in sync
  getProductsToBeUdpated = ({ updatedAvailability, oldProductList }) => {
    const addOnsProductList = [];
    for (const value in updatedAvailability) {
      const productToBeUdpated = oldProductList.find((prod) => prod.productId === value);

      if (productToBeUdpated) {
        //Making the current Quantity equal to product quantity
        //We are using addition mode and not updation mode for Cart in this case
        // Same is applicable for gross as well
        productToBeUdpated.currQuantity = productToBeUdpated.quantity;
        productToBeUdpated.price.gross = productToBeUdpated.unitPrice;
        addOnsProductList.push(productToBeUdpated);
      }
    }
    return addOnsProductList;
  };

  //Method returns the updated product list
  //Scenario:- When main product is updated and maximum availability is updated
  yaGetUpdatedProductList = ({ productList, updatedAvailability, cartActionProps, returnGroupedData }) => {
    //Initializing Cart Actions
    const cart_actions = new CartActions(cartActionProps);

    this.productList = [...productList];
    //Copy of initial product List data
    let oldProductList = [...productList];

    // Looping on the updated maximum availabilty product Ids
    // Getting the products of which th evailabilty has been updated
    // Pushing them in a new array
    let addOnsProductList = this.getProductsToBeUdpated({
      updatedAvailability,
      oldProductList,
    });

    // Getting updated maximum quantity product list with updated invalid quantity calculation
    let newAddOnList = [];
    for (const value in updatedAvailability) {
      const productToBeUdpated = addOnsProductList.find((prod) => prod.productId === value);

      if (productToBeUdpated) {
        //Sending empty product list so that it works in addition mode and not updation
        const tempList = cart_actions.getUpdatedProductList(
          [],
          [{ ...productToBeUdpated }],
          updatedAvailability[value],
          99,
        );
        newAddOnList.push(...tempList.updatedProductList);
      }
    }

    // Error Code in case of invalid quantity
    let errorCode;

    const invalidQuantityProduct = newAddOnList.filter((item) => {
      return item.invalidQuantity;
    });

    if (invalidQuantityProduct.length) {
      errorCode = UIConfig.errorCodes.invalidQuantity;
    }

    //Getting main products from the old product List and add on(maximum availabilty changed products)
    // from newAddOnList
    this.productList = oldProductList.map((value) => {
      const prod = newAddOnList.find((p) => p.productId === value.productId);
      if (prod) {
        return { ...prod };
      }
      return value;
    });

    //Getting Custom cart with updated product list
    // {True} - to get Grouped Cart
    const customCart = returnGroupedData
      ? cart_actions.getCustomCart(this.productList, true)
      : cart_actions.getCustomCart(this.productList);

    //Handling Invalid quantity error scenario
    let err = '';
    if (errorCode) {
      const response = cart_actions._getCustomError({
        errorCode: errorCode,
        errorText: '',
      });

      response.error.updatedProductList = this.productList;
      err = response.error || response.response.error;
    }

    return {
      err,
      customCart,
      productList: this.productList,
    };
  };
}
