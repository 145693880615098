import React from 'react';

import PropTypes from 'prop-types';
import { isEmpty, checkTenant } from '../../../../common/utility';
import { DynamicContent } from '../../../presentation/base/';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';

const AddToWallet = (props) => {
  const { image, onImageClick, ticket, addToWallet } = props;
  const { title, idText } = addToWallet;
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const onClickHandler = (ticketId) => {
    if (!ticketId) return;
    onImageClick(ticketId);
  };

  // added this function to add wrapper for SWAD
  const renderTicketDetails = () => {
    return ticket.map((item) => {
      return (
        <div key={item.ticketId} className="ticket">
          <div className="ticket-details">
            <div className="ticket-id-container">
              <DynamicContent tagName="span" attrs={{ className: 'ticket-id-text' }} innerHtml={`${idText}`} />
              <DynamicContent
                tagName="span"
                attrs={{ className: 'ticket-id', tabIndex: '0' }}
                innerHtml={`${item.ticketId}`}
              />
            </div>
            {!isEmpty(item.ticketHolder) && (
              <DynamicContent
                tagName="div"
                attrs={{ className: 'ticket-display-name', tabIndex: '0' }}
                innerHtml={item.ticketHolder.displayName}
              />
            )}
          </div>
          {image && (
            <div className="add-to-wallet" tabIndex="0">
              <div className="section-item">
                <img src={image} onClick={() => onClickHandler(item.ticketId)} alt="AddToWallet.png" />
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  try {
    return (
      <div className={'component add-to-wallet-container'} tabIndex="0">
        {!isSwad && <DynamicContent tagName="div" attrs={{ className: 'add-to-wallet-header' }} innerHtml={title} />}
        <div className="ticket-container">{renderTicketDetails()}</div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Add to wallet');
  }
};

/**
 * basic structure of data contract
 */
AddToWallet.propTypes = {
  image: PropTypes.string.isRequired,
  onImageClick: PropTypes.func.isRequired,
  ticket: PropTypes.array.isRequired,
  addToWallet: PropTypes.object,
};

export default AddToWallet;
