import moment from 'moment';

import '../pubsub';
import UIConfig from '../UIConfig';
import { keyCodeObject } from './constants';
import { checkInlineSignup, decryptParam, isLoggedInUser, isMatchTenant } from './login';
import { setLocalStorage, getLanguage, getArrayOfElement, canUseDOM } from './window';
import { getCurrentLanguage } from './datetime';
import { GLOBAL_VARS } from '../global-vars';
import { isArabicMode } from '.';
import { isTicketUpgradePayment } from './product';
/**
 * Desc: get value from object/array if path/key exists
 * @param {Object} object/array in which find the path/key
 * @param {String} path/key, has to find in object/array
 * @param {any} default value if path/key not present
 * @return {any} return the value if path/key matches else default value if present else undefined
 * */
export const resolvePath = (obj, path, defaultValue) =>
  (path || '').split('.').reduce((o, p) => (o && o[p] !== undefined ? o[p] : defaultValue), obj || {});

/**
 * checkTenant function return the result of tenant condition.
 * @param    {String} value of tenant to match with current tenant
 * @return   {Boolean} return result of condition
 */
export const checkTenant = (tenantName) => {
  return resolvePath(GLOBAL_VARS, 'pageDetails.tenantName', '') === tenantName;
};

// Tenent check for MiniCart
export const checkIfParks = () => {
  return (
    checkTenant(UIConfig.iamMapping.fwad) ||
    checkTenant(UIConfig.iamMapping.wbw) ||
    checkTenant(UIConfig.iamMapping.yww) ||
    checkTenant(UIConfig.iamMapping.ppad)
  );
};
// Tenent check for ZeroBounce
export const checkTenantsForZeroBounce = () => {
  return (
    checkTenant(UIConfig.iamMapping.swad) ||
    checkTenant(UIConfig.iamMapping.fwad) ||
    checkTenant(UIConfig.iamMapping.wbw) ||
    checkTenant(UIConfig.iamMapping.yww) ||
    checkTenant(UIConfig.iamMapping.ppad) ||
    checkTenant(UIConfig.iamMapping.yasisland)
  );
};
// Tenent check for Parks express checkout
export const isParksExpressCheckoutTenant = () => {
  if (
    (checkTenant(UIConfig.iamMapping.fwad) ||
      checkTenant(UIConfig.iamMapping.wbw) ||
      checkTenant(UIConfig.iamMapping.ppad)) &&
    canUseDOM() &&
    !window?.document?.querySelector('.renewpasspayment--page')
  ) {
    return true;
  }
  if (checkTenant(UIConfig.iamMapping.yww)) return true;
  return false;
};

// Tenent check for MiniCart
export const getAbsoluteInt = (num) => {
  return Math.abs(num);
};

export const getGeoLocation = async () => {
  const apiURL = UIConfig.geoLocation.httpsUrl;
  try {
    return await fetch(apiURL);
  } catch (e) {
    return null;
  }
};
export const checkParksTenants = () => {
  const allParkTanent =
    isMatchTenant(UIConfig.tenants.fwad) ||
    isMatchTenant(UIConfig.tenants.wbw) ||
    isMatchTenant(UIConfig.tenants.yww) ||
    isMatchTenant(UIConfig.tenants.ppad) ||
    isMatchTenant(UIConfig.tenants.clymb) ||
    isMatchTenant(UIConfig.SWADB2C);
  return allParkTanent || false;
};

export const updateGA4LocalStorage = (product) => {
  const { isParseEvent = false } = product;
  const { productId } = product;
  const purchasedProd = getLocalStorageByKey('cartPurchaseProd') || [];
  const exisitngProdIdx = purchasedProd.findIndex((prd) => prd.productId === productId);
  let updatePrd = [...purchasedProd];
  if (exisitngProdIdx === -1) {
    updatePrd.push(product);
  } else if (!isParseEvent) {
    updatePrd[exisitngProdIdx] = { ...product, index: updatePrd?.[exisitngProdIdx]?.index };
  }
  if (updatePrd?.length) {
    setLocalStorage('cartPurchaseProd', JSON.stringify(updatePrd));
  }
};

export const getProductIdx = (prodId) => {
  const purchasedProd = getLocalStorageByKey('cartPurchaseProd') || [];
  const exisitngProdIdx = purchasedProd?.length && purchasedProd.findIndex((prd) => prd.productId === prodId);
  if (exisitngProdIdx) {
    return purchasedProd[exisitngProdIdx]?.index;
  }
  return 1;
};

//this is to be used for GA4 events
export const checkGA4Tenants = (excludedGA4Tenants = [], includedGA4Tenants = []) => {
  const defaultGa4Tenants = [
    UIConfig.tenants.fwad,
    UIConfig.tenants.wbw,
    UIConfig.tenants.yww,
    UIConfig.tenants.yi,
    UIConfig.tenants.ppad,
    UIConfig.tenants.clymb,
    UIConfig.tenants.swadb2c,
  ];
  let actualGA4Tenants = [];
  //remove excluded from default
  defaultGa4Tenants.forEach(function(tenant) {
    if (!excludedGA4Tenants.includes(tenant)) {
      actualGA4Tenants = [...actualGA4Tenants, tenant];
    }
  });
  actualGA4Tenants = [...actualGA4Tenants, ...includedGA4Tenants];
  return !!actualGA4Tenants.find((tenant) => isMatchTenant(tenant));
};

export const checkIfParksTenants = (excludedTenants = [], includedTenants = []) => {
  const defaultTenants = [UIConfig.tenants.fwad, UIConfig.tenants.wbw, UIConfig.tenants.yww, UIConfig.tenants.swadb2c];
  let actualTenants = [];
  //remove excluded from default
  defaultTenants.forEach(function(tenant) {
    if (!excludedTenants.includes(tenant)) {
      actualTenants = [...actualTenants, tenant];
    }
  });
  actualTenants = [...actualTenants, ...includedTenants];
  return !!actualTenants.find((tenant) => isMatchTenant(tenant));
};

export const enableCalendarOnListingPage = () =>
  isMatchTenant(UIConfig.tenants.fwad) || isMatchTenant(UIConfig.tenants.wbw) || isMatchTenant(UIConfig.tenants.yww);
/**
 * getClosest function return the desired closest parent of the target element.
 * @param    {[Object]} element [DOM object of the element for whom we want to find the parent]
 * @param    {[String]} parentTag [parent tag whic needs to be found].
 * @return   {[Object]} returns the found element and null if no such element is found
 */
export const getClosest = (element, parentTag) => {
  // this is necessary since nodeName is always in upper case
  parentTag = parentTag.toUpperCase();
  do {
    if (element.nodeName === parentTag) {
      // parentTag name is found! let's return it.
      return element;
    }
    element = element.parentNode;
  } while (element);
  // not found
  return null;
};

/* function to retrieve main object  */
export const getMainObject = () => {
  if (canUseDOM() && localStorage.mainObj) {
    return JSON.parse(localStorage.mainObj);
  }
};
/* function to add leisureFacility  */
export const getLeisureFacility = () => {
  const mainObj = getMainObject();
  const leisureFacility =
    mainObj && mainObj.additionalProperty.optinLeisureFacility
      ? mainObj.additionalProperty.optinLeisureFacility
      : mainObj.leisureFacility;

  return leisureFacility || '';
};

export const getCurrency = () => {
  const mainObj = getMainObject();
  return mainObj.currency;
};
export const getPipeLineAndSearchHubValues = () => {
  const mainObj = getMainObject();
  const { pipeline, searchHub } = mainObj.additionalProperty;
  return { pipeline, searchHub };
};
export const getSearchHubValues = () => {
  const mainObj = getMainObject();
  const { listingSearchHub } = mainObj.additionalProperty;
  return { listingSearchHub };
};
/* function to retrieve wishlist popup object  */
export const getWishListPopupData = (type) => {
  let output = {};
  if (canUseDOM() && localStorage.WishListPopup) {
    JSON.parse(localStorage.WishListPopup).forEach((popUp) => {
      if (popUp.type === type) {
        output = popUp;
      }
    });
    return output;
  }
};

/**
 * isEmpty function to check if object is empty or not.
 * @param    {[Object]} obj [any javascript object]
 * @return   {[boolean]} returns the boolean value, false if object is not empty and true if object is empty
 */
export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

/* Method to convert object to the query string (this is required with Axios for content-type="application/x-www-form-urlencoded")
Input: Object of data
Output: string in query string format(eg: a=1&b=2&c=3 )
*/
export const querystring = (data) => {
  var qsArray = [];

  for (var prop in data) {
    if (data[prop]) {
      qsArray.push(prop + '=' + data[prop]);
    }
  }

  return qsArray.join('&');
};

export const hasSameProps = (obj1, obj2, key) => {
  if (obj1 && Object.keys(obj1).length && obj2 && Object.keys(obj2).length) {
    return Object.keys(obj1).every((prop) => {
      return obj2.hasOwnProperty(prop) && obj2[prop][key] === obj1[prop][key];
    });
  } else {
    return false;
  }
};

export const createObjectMap = (mapKey, listOfObj) => {
  const objMap = {};
  listOfObj.map((item) => (objMap[item[mapKey]] = item));
  return objMap;
};

export const groupBy = (xs, key, trimValue = false) => {
  return xs.reduce(function(rv, x) {
    const groupKey = trimValue ? valBeforeUnderscore(x[key]) : x[key];
    (rv[groupKey] = rv[groupKey] || []).push(x);
    return rv;
  }, {});
};

export const valBeforeUnderscore = (val) => val.split('_')[0];

export const roundNumber = (num, scale) => {
  if (!('' + num).includes('e')) {
    return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
  } else {
    var arr = ('' + num).split('e');
    var sig = '';
    if (+arr[1] + scale > 0) {
      sig = '+';
    }
    return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale);
  }
};

export const toTwoDecimalPlaces = (number) => {
  return typeof number !== 'undefined'
    ? number.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : null;
};

/* Will filter the List of object, key must be there in object */
export const filterList = (list, id) => {
  return list.filter(function(o) {
    return o.key === id;
  })[0];
};

export const parseQueryString = (name, decrypt, url, fromGpay) => {
  if (canUseDOM()) {
    url = url ? url : window.location.href;
    name = name.replace(/[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    const value = fromGpay ? results[2].replace(/\+/g, ' ') : decodeURIComponent(results[2].replace(/\+/g, ' '));
    return decrypt && value ? decryptParam(value) : value;
  }
  return {};
};

//Function to move caret at the end of Input if value is prefilled on load
export const moveCaretAtEnd = (e) => {
  const temp_value = e.target.value;
  e.target.value = '';
  e.target.value = temp_value;
};

/**
 * ellipsisDecorator appends ellipsis after a character limit
 * @param {*} dataString
 * @param {*} maxLength
 */
export const ellipsisDecorator = (dataString, maxLength) => {
  let trimmedString = dataString;
  //trim the string to the maximum length
  if (dataString.length > maxLength) {
    trimmedString = dataString.substr(0, maxLength);
    /**
     * re-trim if we are in the middle of a word which is evaluated on the basis of spaces
     * if there are no spaces in trimmed string for e.g. stirng in Chinese then return the
     * string trimmed previously.
     */
    trimmedString =
      trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) + '...' || trimmedString;
  }
  return trimmedString;
};

/**
 * decodeQueryString params
 * Reads query string and return a serialzed object
 * @return   {object}
 */
export const decodeQueryString = () => {
  let match, query;
  const pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function(s) {
      return decodeURIComponent(s.replace(pl, ' '));
    };

  if (canUseDOM()) {
    query = window.location.search.substring(1) || window.location.hash.split('?')[1];
  }

  const urlParams = {};
  match = search.exec(query);
  while (match) {
    urlParams[decode(match[1])] = decode(match[2]);
    match = search.exec(query);
  }
  return urlParams;
};

export const generateLoadMoreLabel = (label, loadMoreNumber, remainingCount) => {
  const str = label.replace('{0}', loadMoreNumber);
  return str.replace('{1}', remainingCount);
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const parsePrice = (priceString) => {
  const prcStr = priceString.replace(/,/g, '').split('AED\u200e')[1];
  return prcStr ? Number(prcStr) : 0;
};

//get value by param
export const getParameterByName = (name) => {
  if (canUseDOM()) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
};

// get key by value returns the key of the asked value
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const blobToCSV = (response, fileName) => {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(response.data, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(response.data);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style = 'visibility:hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

/**
 * blobToPdf function return the desired pdf file for the blob response which supports all the browser.
 * @param    {[Object]} response recieved in ajax call
 * @param    {[String]} filename for the pdf file.
 * @return   {[File]} returns pdf file
 */
export const blobToPdf = (response, filename = 'filename.pdf') => {
  const blob = response.data;
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;

  //Fix For Mozilla
  HTMLElement.prototype.click = function() {
    var evt = this.ownerDocument.createEvent('MouseEvents');
    evt.initMouseEvent(
      'click',
      true,
      true,
      this.ownerDocument.defaultView,
      1,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    );
    this.dispatchEvent(evt);
  };

  //Fix For IE
  if (typeof window.navigator.msSaveBlob === 'function') {
    window.navigator.msSaveBlob(response.data, filename);
  }
  link.click();
};

export const blobToFile = (response, filename, type = 'text/plain') => {
  blobToPdf({ data: new Blob([response.data], { type }) }, filename);
};

export const replacePlaceHolder = (placeholder, placeholderText, text) => {
  return text.replace(placeholder, placeholderText);
};

//Get Any value from localstorage
export const getLocalStorageByKey = (key) => {
  const tempStorage = {
    roles: {
      primaryContact: 'Primary Contact',
      operator: 'Operator',
      agentCanPay: 'AgentCanPay',
      agentCannotPay: 'AgentCannotPay',
      prospect: 'Prospect',
      admin: 'Admin',
      superAdmin: 'Super Admin',
      superSalesAdmin: 'Super Sales Admin',
    },
    errors: {
      '500': 'Internal Server Error',
      '401': 'An authorized access',
      '404': 'Page Not Found',
    },
    isEmailError: true,
    showMyProfileMembership: true,
    showBookingMembership: true,
  };
  if (canUseDOM()) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return tempStorage[key];
    }
  }
  return tempStorage[key];
};

// Used to update the Hotel Booking Widget on any CTA click
export const callToAction = (data, url = undefined) => {
  if (data) {
    const hasClass = document.body.classList.contains('bookin-widget-opened');
    const topPosition = window.pageYOffset;
    if (topPosition > 0 && !hasClass) document.body.className += ' bookin-widget-opened';
    const hash = url && url.includes('#') ? url.substring(url.indexOf('#') + 1) : undefined;
    window.PubSub.publish('widgetData', {
      wData: data,
      eventType: hash,
    });
  } else {
    if (url) {
      window.location.href = url;
    }
  }
};

/**
 * creates a meshup object by first mapping the coveo keys with cms keys,
 * then inserts the omni products prices if any and
 * then group the data as per brz,slv,gld tier
 * @func    createFinalMeshup
 * @param   {Object} keyMap         coveo - cms key mapping
 * @param   {Object} coveoProducts  coveo data to be mapped and grouped
 * @param   {String} omniProducts   prices objs for partener specific products.
 * @return  {Object} final mapped and grouped data
 */
export const createFinalMeshup = (keyMap, coveoProducts, omniProducts, tab, tabsMapping, isDrivingExp = false) => {
  const meshData = {};
  for (let index = 0; index < coveoProducts.length; index++) {
    const element = {};

    //coveo - cms key mapping
    for (const key in keyMap) {
      if (coveoProducts[index].hasOwnProperty(keyMap[key])) {
        element[key] = coveoProducts[index][keyMap[key]];
      }
    }

    //omni prices insertion for partener specific products
    for (let j = 0; j < omniProducts.length; j++) {
      if (element.productId === omniProducts[j].id) {
        element['price'] = omniProducts[j].price;
        break;
      }
    }

    //price and quantity obj added so that minicart and mycart works as are
    element.quantity = 0;
    if (!element.price) {
      element.price = {
        gross: parseFloat(element.gross),
        currency: element.currency,
        net: element.net,
      };
    }

    //add fromDate if toDate is there in prod data
    //TODO: temp 9999 date check needs to be removed when fixed form cms end
    if (element.toDate) {
      const toDateFormatted = moment(element.toDate).format(UIConfig.calendar.dateFormat);
      if (toDateFormatted.indexOf('9999') > -1 || toDateFormatted.indexOf('9998') > -1) {
        delete element.toDate;
        delete element.fromDate;
      } else {
        element.toDate = moment(element.toDate).format(UIConfig.calendar.dateFormat);
        element.fromDate = moment().format(UIConfig.calendar.dateFormat);
      }
    }

    //grouping products for ticket and addon tile
    element.productType = tab;
    element.productTypeName = tabsMapping[tab];
    element.productIdType = element.productId;
    if (element.productType.match('SPark|MPark')) {
      let newKey = `${element.parks ? element.parks.sort().join('-') : 'NP'}-${element.ticketVal}`;

      //keys added for mini cart and my cart
      if (isDrivingExp) {
        newKey = 'EXP' + newKey;
      }
      element.groupType = newKey;
      element.groupTypeName =
        (element.themeParkName ? element.themeParkName.join(', ') : 'NoPark') + ' - ' + element.productName;

      if (meshData[newKey]) {
        meshData[newKey].tiers.push(element);
        meshData[newKey].tiers.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));
      } else {
        meshData[newKey] = {};
        meshData[newKey].heading = element.themeParkName ? element.themeParkName.join(', ') : '';
        meshData[newKey].subHeading = element.productName;
        meshData[newKey].type = 'ticket-tile';
        meshData[newKey].tiers = [];
        meshData[newKey].tiers.push(element);
      }
    } else {
      let newKey = element.productType + index;
      if (isDrivingExp) {
        newKey = 'EXP' + newKey;
      }
      element.groupType = newKey;
      element.groupTypeName = element.productName;
      meshData[newKey] = {};
      meshData[newKey].heading = element.productName;
      meshData[newKey].subHeading = element.price
        ? `<span class="body-copy-x-small">${element.price.currency} </span>${element.price.gross}`
        : '';
      meshData[newKey].type = 'addon-tile';
      meshData[newKey].product = element;
    }
  }
  return meshData;
};

export const generateGUID = () => {
  var guid = parseInt(Math.random() * 36);
  return (
    Date.now().toString(36) +
    (guid++ % 36).toString(36) +
    Math.random()
      .toString(36)
      .slice(2, 4)
  );
};

export const createDeepCopy = (oldObj) => {
  return JSON.parse(JSON.stringify(oldObj));
};

export const getDuration = (startDate, endDate) => endDate.valueOf() - startDate.valueOf();

/**
 * setAnonymousLogging function sets the localstorage of logging for anonymous user.
 * Prerequest : anonymousLogging : {"isEnable": "true", "logginApiUrl": "https://fe-uat-apimgmt-service.azure-api.net/v1/logging"}
 * Method workes on localStorage anonymousLogging property.
 */
export const setAnonymousLogging = () => {
  if (localStorage && localStorage.mainObj && localStorage.logging === undefined) {
    const cmsLocalStorage = JSON.parse(localStorage.mainObj);
    if (cmsLocalStorage.additionalProperty) {
      const anonymousLogging = cmsLocalStorage.additionalProperty;
      if (
        (anonymousLogging.anonymousAccess === true || anonymousLogging.anonymousAccess === 'true') &&
        anonymousLogging.logginApiUrl
      ) {
        const loggingObj = {
          logObjects: [],
          logObjectCounter: 0,
          logginApiUrl: anonymousLogging.logginApiUrl,
        };
        localStorage.logging = JSON.stringify(loggingObj);
      }
    }
  }
};

export const differenceByKey = (otherArray, key) => {
  return function(current) {
    return (
      otherArray.filter(function(other) {
        return other[key] === current[key];
      }).length === 0
    );
  };
};

export const deepCloneObject = (obj) => {
  const clone = Array.isArray(obj) ? [] : {};
  for (const i in obj) {
    if (obj[i] !== null && typeof obj[i] === 'object') {
      clone[i] = deepCloneObject(obj[i]);
    } else {
      clone[i] = obj[i];
    }
  }
  return clone;
};

export const massageMobileData = (data) => {
  if (localStorage.getItem(UIConfig.invoiceDetail)) {
    return {
      ...data,
      ...JSON.parse(localStorage.getItem(UIConfig.invoiceDetail)),
    };
  } else {
    return data;
  }
};

export const addSecurityAttrsToAnchor = (target) => {
  if (target && target === '_blank') {
    return 'noopener noreferrer';
  } else {
    return '';
  }
};

/**
 * return the fallback language if products not available for selected language
 */
export const getFallbackLanguage = () => {
  const lang = getCurrentLanguage();
  if (canUseDOM() && localStorage && localStorage.mainObj) {
    const mainObj = JSON.parse(localStorage.mainObj);
    if (mainObj.productLanguages && mainObj.productLanguages.indexOf(lang) === -1) {
      return mainObj.productLanguages[0];
    } else {
      return lang;
    }
  }
};

// return park name
export const getParkName = () => {
  return getLeisureFacility() || '';
};

// return park name string parameter for profile and preferneces call
export const getParkAsParameter = (needed, prefix) => {
  return needed && getParkName() ? prefix + 'leisureFacility=' + getParkName() : '';
};

export const parseIfJsonString = (str) => {
  let jsonObj = {};
  try {
    jsonObj = JSON.parse(str) || {};
  } catch (e) {
    jsonObj = {};
  }
  return jsonObj;
};

export const guestUser = () => {
  if (canUseDOM()) {
    if (localStorage.getItem('guestUser')) {
      return true;
    }
    return false;
  }
};

export const guestUserEmail = () => {
  if (canUseDOM()) {
    if (localStorage.getItem('guestUserEmail')) {
      return true;
    }
    return false;
  }
};

export const aspectRatioCalculator = (aspectRatio) => {
  const divison = aspectRatio.split(':');
  const width = 1280;
  const height = (divison[1] / divison[0]) * width;
  return { width: width, height: Math.round(height) };
};

export const getUserDetails = (data) => {
  if (canUseDOM()) {
    if (data && data.emails) {
      return data.emails[0];
    } else if (localStorage.guestUser) {
      return JSON.parse(localStorage.guestUser).email;
    } else if (localStorage.guestUserEmail) {
      return JSON.parse(localStorage.guestUserEmail).email;
    }
  }
  return 'anonymous';
};

export const throttle = (callback, limit) => {
  var wait = false; // Initially, we're not waiting
  return function() {
    // We return a throttled function
    if (!wait) {
      // If we're not waiting
      callback.call(); // Execute users function
      wait = true; // Prevent future invocations
      setTimeout(function() {
        // After a period of time
        wait = false; // And allow future invocations
      }, limit);
    }
  };
};

export const randomNumber = (length) => {
  length = length || 1;
  let val = Math.floor(Math.random() * Math.pow(10, length));
  return val;
};

export const deepCopyArrayOfObjects = (arryObject) => {
  return JSON.parse(JSON.stringify(arryObject));
};

// detecting body height change (heropanel with cards, heropanel)
export const onElementHeightChange = (elm, callback) => {
  var lastHeight = elm.clientHeight,
    newHeight;
  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight !== newHeight) callback();
    lastHeight = newHeight;

    if (elm.onElementHeightChangeTimer) clearTimeout(elm.onElementHeightChangeTimer);

    elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
};

// Keys and values are swapped in given object using below function
export const flipObjectKeyValues = (objItem) => {
  return Object.keys(objItem).reduce((obj, key) => Object.assign({}, obj, { [objItem[key]]: key }), {});
};

export const setMultipleLocalStorage = (obj = {}) => {
  const keys = Object.keys(obj);
  keys &&
    keys.length &&
    keys.forEach((key) => {
      setLocalStorage(key, obj[key]);
    });
};

/**
 * Generate arrays of tab and tab panel
 */
export const generateArrays = (tabItemsElem) => {
  if (canUseDOM()) {
    let tabs = document.querySelectorAll(tabItemsElem);
    const tabLength = tabs.length;
    for (let i = 0; i < tabLength; i++) {
      tabs[i].index = i;
    }
    return tabs;
  }
};

/**
 * Switch focus on press arrow
 * @param {event} event object
 */
export const switchTabFocusOnArrowPress = (event, tabs) => {
  // Add or substract depending on key pressed

  const direction = {
    37: getLanguage() === 'AR' ? 1 : -1, // left arrow
    39: getLanguage() === 'AR' ? -1 : 1, // right arrow
  };

  let pressed = event.keyCode;

  if (direction[pressed]) {
    let target = event.target;
    if (target.index !== undefined) {
      if (tabs[target.index + direction[pressed]]) {
        tabs[target.index + direction[pressed]].focus();
        tabs[target.index + direction[pressed]].click();
      }
    }
  }
};

/**
 * Move focus on press key up and down in accordion
 * @param {Object} event
 */
export const focusHandlerOnAccordion = (event) => {
  let getArrayOfAccordionElm = getArrayOfElement('.is-active [id*=accordion_header_]'),
    keyCode = event.keyCode.toString();
  if (!keyCode.match(/38|40/)) {
    // if not pressed key UP|DOWN arrow then return false
    return false;
  }
  let target = event.target,
    index = getArrayOfAccordionElm.indexOf(target),
    direction = keyCode.match(/34|40/) ? 1 : -1, // page down | down arrow
    length = getArrayOfAccordionElm.length,
    newIndex = (index + length + direction) % length;
  setTimeout(() => {
    getArrayOfAccordionElm[newIndex].focus();
  }, 200);
  event.preventDefault();
};

/**
 * Custom Event object creator(for manually triggerting events)
 */
export const createCustomEvent = (eventName) => {
  let event;
  if (typeof Event === 'function') {
    event = new Event(eventName);
  } else {
    //for IE
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  return event;
};

/**
 * returns true/false if enter key is pressed ()
 */
export const isEnterPressed = (event) => {
  let keyCode = event.keyCode || event.which;
  return keyCode === 13 ? true : false;
};

export const isSelectedLanguageArabic = () => {
  return getCurrentLanguage() === UIConfig.languages.ar;
};

export const hexToBase64 = (str) => {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, '')
        .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
        .replace(/ +$/, '')
        .split(' '),
    ),
  );
};

export const base64ToHex = (str) => {
  for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, '')), hex = []; i < bin.length; ++i) {
    var tmp = bin.charCodeAt(i).toString(16);
    if (tmp.length === 1) tmp = '0' + tmp;
    hex[hex.length] = tmp;
  }
  return hex.join('');
};

/**
 * Converts a string to its html characters completely.
 *
 * @param {String} str String with unescaped HTML characters
 **/
export const decodeHtmlEntity = (str) => {
  if (canUseDOM() && str) {
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  } else {
    return str;
  }
};

export const applyGradientValue = (addContrast, defaultValue) => {
  let contrastValue;

  if (addContrast === '0%') {
    contrastValue = false;
  } else if (addContrast) {
    contrastValue = addContrast;
  } else if (defaultValue) {
    contrastValue = defaultValue;
  } else {
    contrastValue = false;
  }

  return contrastValue;
};

export const handleEnterKey = (event, fn) => {
  if (event.which === keyCodeObject.ENTER || event.keyCode === keyCodeObject.ENTER) {
    fn();
  }
};

/**
 * Get the Protocol and Host Name name of given string URL
 * @param {String} URL: String URL from which subtract the protocol and host name
 * @return {String} return the host name of given URL
 */
export const getProtocolHostName = (stringUrl) => {
  return stringUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0];
};

export const getProp = (object, keys, defaultVal) => {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1));
  }
  return object === undefined ? defaultVal : object;
};

/**
 * Update query string param in URL
 * @param {String} key: Query string key in URL
 * @param {String} value: Value to update or add in URL
 */
export const updateUrlQueryParam = (key, value) => {
  if ('URLSearchParams' in window) {
    if (key?.toLowerCase() === 'togglebutton') {
      window.location.hash = value;
    } else {
      var searchParams = new URLSearchParams(window.location.search);

      searchParams.set(key, value?.toLowerCase());
      var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + window.location.hash;
      window.history.pushState(null, '', newRelativePathQuery);
    }
  }
};

/**
 * Remove query string param in URL.
 */
export const deleteUrlQueryParam = (key) => {
  if ('URLSearchParams' in window) {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    window.location.hash = '';
    var searchParamQuery = searchParams.toString() ? `?${searchParams.toString()}` : '';
    var newRelativePathQuery = `${window.location.pathname}${searchParamQuery}`;
    window.history.pushState(null, '', newRelativePathQuery);
  }
};

export const getAPIStateParam = () => {
  let apiStateParam = `{0}|yasconnect_${GLOBAL_VARS.pageDetails.tenantName}`;
  if (checkParksTenants() && isTicketUpgradePayment()) {
    return apiStateParam.replace('{0}', window.location.pathname + window.location.search);
  }

  return apiStateParam.replace('{0}', window.location.pathname);
};

/** Post data to Native App
 * @param {Object} message: Query string key in URL
 */
export const postNativeMessage = (message) => {
  window.NativeBridge && window.NativeBridge.postMessage(JSON.stringify(message));
};

const getCoveyKey = (array, key) => Object.keys(array[0].raw).find((item) => item.includes(key));

/** Remove Duplicate Coveo Items based on fId and fvesion
 * @param {Object} data: Coveo Results
 */
export const removeDuplicateCoveoItems = (data) => {
  const { results } = data;
  if (!results || !results.length) return;
  const fid = getCoveyKey(results, 'fid');
  const fversion = getCoveyKey(results, 'fversion');

  if (!fid || !fversion) return;
  const newResults = [...results];

  const filteredResults = newResults
    .sort((a, b) => b.raw[fversion] - a.raw[fversion])
    .filter((v, i, a) => a.findIndex((t) => t.raw[fid] === v.raw[fid]) === i); //.sort((a,b) => a.a - b.a)

  const updatedResults = results.filter((res) => {
    return filteredResults.find((f) => f.raw[fid] === res.raw[fid] && f.raw[fversion] === res.raw[fversion]);
  });

  return { ...data, results: updatedResults };
};

/** Split variant and match with selected Variant
 * @param {string} variant: Variant with multiple classes
 * @selectedVariant {string} selectedVariant: selected variant
 */
export const matchVariant = (variant, selectedVariant) => {
  if (!variant) return;
  const splitVariation = variant.split(' ');
  if (Array.isArray(splitVariation)) {
    return splitVariation.find((variant) => variant === selectedVariant);
  }
  return null;
};

/**
 *
 * Conditions for sorting tabs---Tabs with filter
 *@param {Array,string,boolean} -List, Field Name and Async Status
 *
 */

export const shortTabsSorting = (list, fieldName, isAscSort) => {
  return list.map((tab) => {
    let newTab = { ...tab };
    newTab.listing = tab.listing.sort((a, b) => {
      let fa = a[fieldName]?.toLowerCase() ?? '',
        fb = b[fieldName]?.toLowerCase() ?? '';

      //ASC
      if (isAscSort) {
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      } else {
        //DSNC
        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
        return 0;
      }
    });
    return newTab;
  });
};

/**
 *
 * Conditions for sorting tabs---Tabs with filter-Date
 * @param {Array,boolean} -List and Async Status
 */
export const shortTabsByDateSorting = (list, isAscSort) => {
  return list.map((tab) => {
    let newTab = { ...tab };
    newTab.listing = tab.listing.sort((a, b) => {
      let fa = moment(a.tileDate, UIConfig.dateDisplayFormat),
        fb = moment(b.tileDate, UIConfig.dateDisplayFormat);
      if (isAscSort) {
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      } else {
        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
        return 0;
      }
    });
    return newTab;
  });
};

/**
 *
 * Converting AM/PM and Day Name dynamic from CMS
 */

export const customMomentChange = (props) => {
  const { morningText, afternoonText, eveningText, weekTranslations } = props;
  const { am, pm, weekDays } = UIConfig.fullCalendar;
  moment.updateLocale(isArabicMode() ? UIConfig.languages.arLang : UIConfig.languages.enLang, {
    meridiem: function(hours) {
      if (hours < 12) {
        return morningText || am;
      }
      if (hours >= 12 && hours < 17) {
        return afternoonText || pm;
      }
      return eveningText || pm;
    },
    weekdays: weekTranslations?.fullName || weekDays,
  });
};

/** Changing timestamp to required time format in ar and en
 * @param {Object} data: Coveo Results
 */
export const formatDate = (value, format, locale) => {
  if (value && format) {
    if (locale) {
      return moment(value, 'YYYY-MM-DD[T]HH:mm:ss')
        .locale(isArabicMode() ? UIConfig.languages.arLang : UIConfig.languages.enLang)
        .format(format);
    }
    return moment(value, 'YYYY-MM-DD[T]HH:mm:ss').format(format);
  }
  return null;
};

export const getDateformatStamp = (event, lang) => {
  const min = moment(event, UIConfig.iso_date).minute();
  if (min > 0) {
    return lang ? UIConfig.h_mm_a : UIConfig.h_ma;
  }
  return lang ? UIConfig.h_a : UIConfig.h_ha;
};

export const applyClass = (event) => {
  const minuteTime = moment(event, UIConfig.iso_date).minute();
  return minuteTime;
};

export const dataLayerCheckoutConvert = (data) => {
  const commonData = {
    'Event id': data?.ecommerce?.checkout?.products?.length && data?.ecommerce?.checkout?.products[0].id,
    price: data?.ecommerce?.checkout?.products?.reduce((total, b) => total + b.price, 0),
    Currency: UIConfig.currencyArabic,
    category: 'Ticket',
    quantity: data?.ecommerce?.checkout?.products?.reduce((total, b) => total + b.quantity, 0),
  };
  return {
    event: data?.event,
    ecommerce: {
      checkout: {
        actionField: {
          ...data?.ecommerce?.checkout?.actionField,
          ...commonData,
        },
        products: [
          {
            ...commonData,
          },
        ],
      },
    },
  };
};

/** Shuffle object keys and values of an Object
 * @param {Object} obj: Any js object
 */
export const shuffleObject = (obj) => {
  let newObj = {};
  if (!isEmpty(obj)) {
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        newObj[obj[key]] = key;
      }
    });

    return newObj;
  }
};

// getting isBulkPurchaseAllowed from localStorage
export const getPurchaseAllowed = () => {
  return JSON.parse(localStorage.getItem('isBulkPurchaseAllowed'));
};

/**getMainStayCategory return stay category
 * @param {String} type: cType from URL
 */
export const getMainStayCategory = (type) => {
  let gaMainCategory = UIConfig.b2c.flowType.longName.staycation;
  if (type === UIConfig.b2c.flowType.shortName.daycation) {
    gaMainCategory = UIConfig.b2c.flowType.longName.daycation;
  }
  return gaMainCategory;
};

const getAllPackageProd = (packages) => {
  const allPckgPrd = [];
  Object.keys(packages).forEach((key) => {
    for (let i = 0; i < packages[key].packageQuantity / packages[key].uniqueObjects.length; i++) {
      allPckgPrd.push({
        packageCode: key,
        packageQuantity: packages[key].packageQuantity,
        items: packages[key].uniqueObjects,
      });
    }
  });
  return allPckgPrd;
};

/** Grouping the packaged products and seperating the non packaged items
 * @param {Object} data: Coveo Results
 */

export const groupPackageList = (productsList) => {
  const productsWithoutPackage = productsList.filter((item) => !item.hasOwnProperty('packageGroupCode'));

  const packages = productsList.reduce((accumulator, currentValue) => {
    delete currentValue.currentTabData;
    if (currentValue.hasOwnProperty('packageGroupCode') && currentValue.packageGroupCode) {
      if (!accumulator[currentValue.packageCode]) {
        accumulator[currentValue.packageCode] = {
          packageQuantity: currentValue.packageQty,
          packageCode: currentValue.packageCode,
          packageDetails: {
            packagePrice: currentValue.packagePrice,
            packageMin: currentValue.packageMin,
            packageMax: currentValue.packageMax,
            packageTitle: currentValue.packageTitle,
          },
          uniqueObjects: [currentValue],
        };
      } else {
        accumulator[currentValue.packageCode].packageQuantity = currentValue.packageQty;
        accumulator[currentValue.packageCode].gross += currentValue.gross;
        if (
          !accumulator[currentValue.packageCode].uniqueObjects.some(
            (obj) => obj.productIdType === currentValue.productIdType,
          )
        ) {
          accumulator[currentValue.packageCode].uniqueObjects.push(currentValue);
        }
      }
    }
    return accumulator;
  }, {});

  return {
    items: productsWithoutPackage,
    packages: getAllPackageProd(packages),
  };
};

export const checkPaymentType = (paymentTypes, value) => {
  return paymentTypes.find((item) => item?.value === value).payButtonLabel;
};

/**
 * Description: Convert text to lowercase
 * @param {String} text: Text to convert
 */
export const toLowerCase = (text) => {
  return text?.toLowerCase();
};

export const getGa4Category = (currentUrl, defaultValue) => {
  const trimmedFromSlash = currentUrl.replace(/\/+$/, '');
  const trimmedUrl = trimmedFromSlash.replace(/\/#+$/, '');
  const pathArr = trimmedUrl.split('/');
  let categoryArr = pathArr.slice(-1);
  if (!categoryArr?.[0]) {
    categoryArr = pathArr?.slice(-2);
  }
  const category = ['en', 'ar', 'ru'].includes(categoryArr?.[0]) ? undefined : categoryArr?.[0];
  return category || defaultValue || undefined;
};

export const checkIfTagetDateAproached = (date) => {
  if (!(date && moment(date).isValid())) {
    return null;
  }
  const fromDate = moment();
  const toDate = moment(date);
  const seconds = toDate.diff(fromDate, UIConfig.calendar.seconds);
  return +seconds <= 0;
};

export const isEmailVerificationEnabled = () => {
  const mainObj = getMainObject();
  return mainObj?.emailVerification || false;
};

export const checkIfIsExpessCheckoutForEmailTemplate = () =>
  !checkInlineSignup() && localStorage.getItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED) && !isLoggedInUser();

export const getEmailTemplateId = (emailTemplateId, anonymousEmailTemplateId) => {
  if (checkIfIsExpessCheckoutForEmailTemplate()) return anonymousEmailTemplateId;
  return emailTemplateId;
};

export const checkIfGiftVoucherIsInTheCart = (cart) =>
  !!cart?.items?.some((cartItem) => !!cartItem?.category?.some((cat) => cat.includes('Gift-Voucher')));
