import React from 'react';
import { SvgSprite } from '..';

const Note = ({ dynamicClassName, iconId, children }) => {
  if (!children) return <></>;
  return (
    <div className={`${dynamicClassName}-note-wrapper`}>
      <div className={`${dynamicClassName}-note-icon`}>
        <SvgSprite id={iconId} />
      </div>
      <div>{children}</div>
    </div>
  );
};

Note.defaultProps = {
  dynamicClassName: 'calendar',
  children: '',
  iconId: 'icn-warning',
};

export default Note;
