import axios from 'axios';

import ApiWrapper from '../api-wrapper';
import UIConfig from '../UIConfig';
import { canUseDOM, getCookie, getLoggedInUser, getParkAsParameter, replacePlaceHolder } from '../utility';
import { isTokenExpired } from '../session/session-utility';

export const preLoaderTarget = UIConfig.loader.defaultPreLoaderTarget;

export class ServiceConfig {
  static config = {
    userDetails: {},
    cart: {},
    partnerDetails: {},
    cartMashUp: {},
    settlementData: {},
    advanceTopupData: {},
    performanceProducts: {},
  };

  static setUserDetails = (data) => {
    ServiceConfig.config.userDetails = data;
  };

  static getTenantId = () => {
    return getLoggedInUser().tenantID;
  };

  static getPartnerId = () => {
    return getLoggedInUser().partnerId;
  };

  static getPartnerEmailId = () => {
    return getLoggedInUser().email;
  };

  static getUserToken = () => {
    return getLoggedInUser().idToken;
  };

  static getParnterCreditUrl = (partnerUrl) => {
    return replacePlaceHolder('{partnerId}', ServiceConfig.getPartnerId(), partnerUrl);
  };

  static getLoggingUrl = () => {
    let logging = JSON.parse(localStorage.logging);
    return logging.logginApiUrl;
  };

  static getWishlistID = () => {
    return getCookie('wishlistID');
  };

  //replaced https://miralmeshup.azure-api.net/api/MyCart,key f8cd6a7f88f543a883e9271b00e4573e with
  // https://fe-dev-ux-apimgmt-service.azure-api.net/booking/myCart

  //fe-dev-ux-apimgmt-service : Azure use experience platform
  //fe-newdev-ux-appserver :
  //sitapimgmt.azure-api.net :
  //fe-dev-audlog-web.azurewebsites.net :
  //cloudplatform.coveo.com :  use coveo
  //platform.cloud.coveo.com : use coveo

  static callPending = {
    sessionService: false,
    getCartService: false,
    getCartMashupService: false,
    getPartnerService: false,
    getSettlementService: false,
    getAdvanceTopupService: false,
  };

  static promiseStack = {
    sessionService: [],
    getCartService: [],
    getCartMashupService: [],
    getPartnerService: [],
    getSettlementService: [],
    getAdvanceTopupService: [],
  };
}

export const callBackFunction = (promiseType, serviceName, configName, res) => {
  let length = ServiceConfig.promiseStack[serviceName].length;
  ServiceConfig.config[configName] = res;
  ServiceConfig.callPending[serviceName] = false;
  for (let index = 0; index < length; index++) {
    let curPromise = ServiceConfig.promiseStack[serviceName][index];
    curPromise[promiseType](res);
  }
  ServiceConfig.promiseStack[serviceName].length = 0;
};

export class PartnerService {
  static getPartnerDetails = (moduleName, serviceUrl, preLoader, preLoaderTarget) => {
    if (canUseDOM()) {
      let promiseResolve,
        promiseReject,
        promiseFnObj,
        promise = new Promise((resolve, reject) => {
          promiseResolve = resolve;
          promiseReject = reject;
        });
      promiseFnObj = {
        resolveFn: promiseResolve,
        rejectFn: promiseReject,
      };
      ServiceConfig.promiseStack.getPartnerService.push(promiseFnObj);
      if (Object.keys(ServiceConfig.config.partnerDetails).length) {
        return Promise.resolve(ServiceConfig.config.partnerDetails);
      } else {
        if (!ServiceConfig.callPending.getPartnerService) {
          let partnerOptions = {
            method: 'GET',
            url: serviceUrl + '/' + ServiceConfig.getPartnerId() + '?r=' + Math.random(),
            headers: {
              'Content-Type': 'application/json',
            },
            moduleName: moduleName || 'partnerOption',
            preLoader: preLoader,
            preLoaderTarget: preLoaderTarget,
            noCache: true,
          };
          ServiceConfig.callPending.getPartnerService = true;
          ApiWrapper.omniServices(partnerOptions)
            .then((res) => {
              callBackFunction('resolveFn', 'getPartnerService', 'partnerDetails', res);
            })
            .catch((res) => {
              callBackFunction('rejectFn', 'getPartnerService', 'partnerDetails', res);
            });
        }
        return promise;
      }
    }
  };

  static getPartnerAccountCredit = (moduleName, serviceUrl, preLoader, preLoaderTarget) => {
    let partnerOptions = {
      method: 'GET',
      url: ServiceConfig.getParnterCreditUrl(serviceUrl),
      headers: {
        'Content-Type': 'application/json',
      },
      moduleName: moduleName || 'partnerOption',
      noCache: true,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(partnerOptions);
  };

  static getContactUsDetails = (moduleName, serviceUrl) => {
    const partnerOptions = {
      url: serviceUrl,
      method: 'GET',
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.crmServices(partnerOptions);
  };

  static getRedumption = (moduleName, serviceUrl) => {
    const partnerOptions = {
      url: serviceUrl,
      method: 'GET',
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.crmServices(partnerOptions);
  };

  static getRedeemedTarget = (moduleName, serviceUrl, preLoader, preLoaderTarget) => {
    const redeemedTargetOptions = {
      url: serviceUrl,
      method: 'GET',
      moduleName: moduleName || 'redeemedTarget',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.apiGateway(redeemedTargetOptions);
  };
}

export class AnnualPassData {
  static getGeoIP = () => {
    let getGeoIP = {
      method: 'GET',
      url: UIConfig.geoLocation.httpsUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return ApiWrapper.experienceServices(getGeoIP);
  };

  static annualPassDataAPI = (config) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));
    if (config.isRenewPass) {
      return ApiWrapper.omniServices(config);
    } else {
      return ApiWrapper.experienceServices(config);
    }
  };
}

export class TryUpgradeData {
  static tryUpgradeAPI = (config) => {
    return ApiWrapper.omniServices(config);
  };
}

export class SettlementData {
  //url, preLoader, preLoaderTarget, data
  static getSettlementData = (serviceUrl, preLoader, preLoaderTarget, data) => {
    if (canUseDOM()) {
      let promiseResolve,
        promiseReject,
        promiseFnObj,
        promise = new Promise((resolve, reject) => {
          promiseResolve = resolve;
          promiseReject = reject;
        });
      promiseFnObj = {
        resolveFn: promiseResolve,
        rejectFn: promiseReject,
      };
      ServiceConfig.promiseStack.getSettlementService.push(promiseFnObj);

      if (!ServiceConfig.callPending.getSettlementService) {
        let settlementOption = {
          method: 'POST',
          url: serviceUrl,
          headers: {
            'Content-Type': 'application/json',
          },
          // moduleName: moduleName || "partnerOption",
          preLoader: preLoader,
          preLoaderTarget: preLoaderTarget,
          data: data,
          noCache: true,
        };
        ServiceConfig.callPending.getSettlementService = true;
        ApiWrapper.omniServices(settlementOption)
          .then((res) => {
            callBackFunction('resolveFn', 'getSettlementService', 'settlementData', res);
          })
          .catch((res) => {
            callBackFunction('rejectFn', 'getSettlementService', 'settlementData', res);
          });
      }
      return promise;
      // }
    }
  };
}

export class AdvanceTopupService {
  //url, preLoader, preLoaderTarget, data
  static getAdvanceTopupData = (serviceUrl, preLoader, preLoaderTarget, data) => {
    if (canUseDOM()) {
      let promiseResolve,
        promiseReject,
        promiseFnObj,
        promise = new Promise((resolve, reject) => {
          promiseResolve = resolve;
          promiseReject = reject;
        });
      promiseFnObj = {
        resolveFn: promiseResolve,
        rejectFn: promiseReject,
      };
      ServiceConfig.promiseStack.getAdvanceTopupService.push(promiseFnObj);
      if (!ServiceConfig.callPending.getAdvanceTopupService) {
        let getAdvanceTopupOption = {
          method: 'POST',
          url: serviceUrl,
          headers: {
            'Content-Type': 'application/json',
          },
          // moduleName: moduleName || "partnerOption",
          preLoader: preLoader,
          preLoaderTarget: preLoaderTarget,
          data: data,
          noCache: true,
        };
        ServiceConfig.callPending.getAdvanceTopupService = true;
        ApiWrapper.omniServices(getAdvanceTopupOption)
          .then((res) => {
            callBackFunction('resolveFn', 'getAdvanceTopupService', 'advanceTopupData', res);
          })
          .catch((res) => {
            callBackFunction('rejectFn', 'getAdvanceTopupService', 'advanceTopupData', res);
          });
      }
      return promise;
      // }
    }
  };
}

export class SessionService {
  static getSession = (moduleName, url, preLoader, preLoaderTarget) => {
    if (canUseDOM()) {
      let promiseResolve,
        promiseReject,
        promiseFnObj,
        promise = new Promise((resolve, reject) => {
          promiseResolve = resolve;
          promiseReject = reject;
        });
      promiseFnObj = {
        resolveFn: promiseResolve,
        rejectFn: promiseReject,
      };
      ServiceConfig.promiseStack.sessionService.push(promiseFnObj);

      if (Object.keys(ServiceConfig.config.userDetails).length) {
        return Promise.resolve(ServiceConfig.config.userDetails);
      } else {
        if (!ServiceConfig.callPending.sessionService) {
          let sessionConfig = {
            method: 'GET',
            url: url + '?r=' + Math.random(),
            moduleName: moduleName || 'startSession',
            preLoader: preLoader,
            preLoaderTarget: preLoaderTarget,
            noCache: true,
          };

          ServiceConfig.callPending.sessionService = true;

          ApiWrapper.omniServices(sessionConfig)
            .then((res) => {
              let length = ServiceConfig.promiseStack.sessionService.length;
              ServiceConfig.setUserDetails(res.data);
              ServiceConfig.callPending.sessionService = false;
              for (let index = 0; index < length; index++) {
                let curPromise = ServiceConfig.promiseStack.sessionService[index];
                curPromise.resolveFn(res.data);
              }
              ServiceConfig.promiseStack.sessionService.length = 0;
            })
            .catch((res) => {
              let length = ServiceConfig.promiseStack.sessionService.length;
              ServiceConfig.setUserDetails(res.data);
              for (let index = 0; index < length; index++) {
                let curPromise = ServiceConfig.promiseStack.sessionService[index];
                curPromise.rejectFn(res);
              }
              ServiceConfig.promiseStack.sessionService.length = 0;
              ServiceConfig.callPending.sessionService = false;
            });
        }
        return promise;
      }
    }
  };

  static setSession = (data, moduleName, url, preLoader, preLoaderTarget, continueLoader) => {
    let sessionConfig = {
      method: 'POST',
      url: url,
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName || 'setSession',
      continueLoader: false,
    };
    return ApiWrapper.omniServices(sessionConfig);
  };
}

export class LoggingService {
  static postLogs = (data, loggingUrl) => {
    let loggingConfig = {
      method: 'POST',
      url: loggingUrl ? loggingUrl : ServiceConfig.getLoggingUrl(),
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    return axios(loggingConfig);
  };
}

export class CreditSettle {
  static createSettle = (data, preLoaderTarget, url, moduleName) => {
    let orderConfig = {
      method: 'POST',
      url: url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName || 'createSettle',
    };

    return ApiWrapper.experienceServices(orderConfig);
  };
}

export class DownloadCSV {
  static fileDownload = (data, serviceUrl, method) => {
    let downloadFileOptions = {
      url: serviceUrl,
      method: method ? method : 'GET',
      responseType: 'blob',
      ContentType: 'application/json',
      data: data ? data : {},
    };
    return ApiWrapper.experienceServices(downloadFileOptions);
  };
}

export class PromotionService {
  static getPromotionData = (url, preLoader, preLoaderTarget) => {
    let config = {
      method: 'GET',
      url: `${url}?tenantid=${ServiceConfig.getTenantId()}`,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return ApiWrapper.omniServices(config);
  };
}

export class Promotion {
  static ValidateCoupon = (url, couponCode, moduleName, preLoader = true) => {
    const config = {
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      url: url.replace('{couponCode}', couponCode) + '?tenantid=' + ServiceConfig.getTenantId(),
      moduleName: moduleName,
    };

    return ApiWrapper.omniServices(config);
  };
}

export class YASIDService {
  static createYasID = (cart, moduleName, serviceUrl) => {
    let yasIdOptions = {
      method: 'POST',
      url: serviceUrl,
      data: cart,
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.api(yasIdOptions);
  };
}

export class RecommendationService {
  static getFilterProducts = (url, moduleName, data, preLoader = true, preLoaderClass = preLoaderTarget) => {
    const config = {
      method: 'POST',
      preLoader: preLoader,
      preLoaderTarget: preLoaderClass,
      url: url + '?tenantid=' + ServiceConfig.getTenantId(),
      moduleName: moduleName,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return ApiWrapper.omniServices(config);
  };
}

export class ProfileServices {
  // my profile tab services

  static GetGuestInfo = (moduleName, serviceUrl, preLoader, preLoaderTarget) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const yasId = getLoggedInUser().yasId;
    const getGuestInfoConfig = {
      url: replacePlaceHolder('{yasId}', yasId, serviceUrl),
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.experienceServices(getGuestInfoConfig);
  };
  static UpdateGuestInfo = (moduleName, serviceUrl, data, preLoader, preLoaderTarget) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const updateGuestInfoConfig = {
      url: replacePlaceHolder('{yasId}', getLoggedInUser().yasId, serviceUrl),
      method: 'PUT',
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.experienceServices(updateGuestInfoConfig);
  };
  static GetGuestCommunicationPref = (moduleName, serviceUrl, preLoader, preLoaderTarget, needFacility) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const parkParam = getParkAsParameter(needFacility, '&');
    const user = getLoggedInUser();
    let newurl = typeof user.email === 'string' ? user.email : user.emails[0];

    const getGuestInfoConfig = {
      url: replacePlaceHolder('{email}', newurl, serviceUrl) + '?yasId=' + getLoggedInUser().yasId + parkParam,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.experienceServices(getGuestInfoConfig);
  };
  static CreateCommunicationPref = (moduleName, serviceUrl, data, preLoader, preLoaderTarget, needFacility) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const parkParam = getParkAsParameter(needFacility, '&');
    const user = getLoggedInUser();
    let userEmail = typeof user.email === 'string' ? user.email : user.emails[0];
    const createPreferenceInfoConfig = {
      url: replacePlaceHolder('{email}', userEmail, serviceUrl) + '?yasId=' + getLoggedInUser().yasId + parkParam,
      method: 'POST',
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.crmServices(createPreferenceInfoConfig);
  };
  static UpdateCommunicationPref = (moduleName, serviceUrl, data, preLoader, preLoaderTarget, needFacility) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const parkParam = getParkAsParameter(needFacility, '&');

    const user = getLoggedInUser();
    let userEmail = typeof user.email === 'string' ? user.email : user.emails[0];
    const updatePreferenceInfoConfig = {
      url: replacePlaceHolder('{email}', userEmail, serviceUrl) + '?yasId=' + getLoggedInUser().yasId + parkParam,
      method: 'PUT',
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.crmServices(updatePreferenceInfoConfig);
  };
  //prefernce tab services
  static GetPreferenceInfo = (moduleName, serviceUrl, preLoader, preLoaderTarget, needFacility) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const parkParam = getParkAsParameter(needFacility, '?');
    const getPreferenceInfoConfig = {
      url: replacePlaceHolder('{yasId}', getLoggedInUser().yasId, serviceUrl) + parkParam,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.crmServices(getPreferenceInfoConfig);
  };

  static GetCMPPreferenceInfo = (moduleName, serviceUrl, preLoader, preLoaderTarget) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const { email, tenantID } = getLoggedInUser();
    const getPreferenceInfoConfig = {
      url: replacePlaceHolder('{email}', email, serviceUrl) + `&tenantId=${tenantID}`,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.crmServices(getPreferenceInfoConfig);
  };

  static CreatePreferenceInfo = (moduleName, serviceUrl, data, preLoader, preLoaderTarget, needFacility) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const parkParam = getParkAsParameter(needFacility, '?');
    const createPreferenceInfoConfig = {
      url: replacePlaceHolder('{yasId}', getLoggedInUser().yasId, serviceUrl) + parkParam,
      method: 'POST',
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.crmServices(createPreferenceInfoConfig);
  };

  static UpdatePreferenceInfo = (moduleName, serviceUrl, data, preLoader, preLoaderTarget, needFacility) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const parkParam = getParkAsParameter(needFacility, '?');
    const updatePreferenceInfoConfig = {
      url: replacePlaceHolder('{yasId}', getLoggedInUser().yasId, serviceUrl) + parkParam,
      method: 'PUT',
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.crmServices(updatePreferenceInfoConfig);
  };
  static GetGuestAddressInfo = (moduleName, serviceUrl, preLoader, preLoaderTarget) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const getGuestAddressInfoConfig = {
      url: replacePlaceHolder('{yasId}', getLoggedInUser().yasId, serviceUrl),
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.experienceServices(getGuestAddressInfoConfig);
  };
  static UpdateGuestAddressInfo = (moduleName, serviceUrl, data, preLoader, preLoaderTarget) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const updateGuestAddressInfoConfig = {
      url: replacePlaceHolder('{yasId}', getLoggedInUser().yasId, serviceUrl),
      method: 'PUT',
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.experienceServices(updateGuestAddressInfoConfig);
  };
  static PostGuestAddressInfo = (moduleName, serviceUrl, data, preLoader, preLoaderTarget) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const postGuestAddressInfoConfig = {
      url: replacePlaceHolder('{yasId}', getLoggedInUser().yasId, serviceUrl),
      method: 'POST',
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.experienceServices(postGuestAddressInfoConfig);
  };
  static DeleteGuestAddressInfo = (moduleName, serviceUrl, preLoader, preLoaderTarget) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const deleteGuestAddressInfoConfig = {
      method: 'DELETE',
      url: replacePlaceHolder('{yasId}', getLoggedInUser().yasId, serviceUrl),
      moduleName: moduleName,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(deleteGuestAddressInfoConfig);
  };

  static saveUserProfileCookieInCMS = (moduleName, serviceUrl, data, preLoader, preLoaderTarget) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));

    const config = {
      url: serviceUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };

    return ApiWrapper.experienceServices(config);
  };
}

export class CurrencyConverterService {
  static getConvertedCurrency = (url, apiData, moduleName, preLoader, preLoaderTarget) => {
    const config = {
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: apiData,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };

    return ApiWrapper.experienceServices(config);
  };
}

export class FormServices {
  static getGeoLocation = ({ moduleName, serviceUrl, preLoader, preLoaderTarget }) => {
    const getGuestInfoConfig = {
      url: serviceUrl,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.experienceServices(getGuestInfoConfig);
  };
  static getDataByItemID = ({ moduleName, serviceUrl, preLoader, preLoaderTarget }) => {
    const getCityByItemIDConfig = {
      url: serviceUrl,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
    };
    return ApiWrapper.experienceServices(getCityByItemIDConfig);
  };
}
export class CorporateEmailServices {
  static deleteCorporateEmail = (moduleName, serviceUrl, preLoader, preLoaderTarget) => {
    const deleteCorporateEmailConfig = {
      method: 'DELETE',
      url: serviceUrl,
      moduleName: moduleName,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(deleteCorporateEmailConfig);
  };

  static submitCorpUserEmail = (moduleName, serviceUrl, data, preLoader, preLoaderTarget) => {
    const corporateEmailConfig = {
      method: 'POST',
      data: data,
      url: serviceUrl,
      preLoader: preLoader,
      moduleName: moduleName,
      preLoaderTarget: preLoaderTarget,
    };

    return ApiWrapper.experienceServices(corporateEmailConfig);
  };
}

export * from './product-services';
