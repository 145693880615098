import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import { resolvePath, canUseDOM, getEnvVariablesClient } from './utility';
import UIConfig from './UIConfig';

/**
 * Get the sitecore url for proxy and akamai status
 */
export const getEnvProxyUrl = () => {
  if (canUseDOM()) {
    const envVariables = getEnvVariablesClient(),
      siteCoreApiUrl = resolvePath(envVariables, 'envSiteCoreApiUrl', ''),
      isAkamai = resolvePath(envVariables, 'akamaiEnabled', true);
    return { siteCoreApiUrl, isAkamai };
  }
};

/**
 * Get the value of an array containing Object property value
 * @param {Array} array in  which iteration occurred
 * @param {String} conditionKey is the key on which the condtion check performed
 * @param {String} requiredKeyValue is the value which is return if the condition is true
 */
export const getValueFromArray = (array, conditionKey, requiredKeyValue) => {
  const filteredArray = array.find((element) => element[conditionKey] === true);
  return filteredArray && filteredArray[requiredKeyValue];
};
/* check jss field to exclude from API response
 * @param {Object} field: field obj
 */
export const checkFieldsToExclude = (field) => {
  const { textFieldId, buttonId } = UIConfig.jssForms.fields;
  const { Button, TextField } = FieldTypes;
  const typeId = resolvePath(field, 'model.fieldTypeItemId', '');
  const fieldsToExclude = [Button, buttonId, textFieldId, TextField];
  return fieldsToExclude.includes(typeId);
};

/**
 * get a sigle level object from nested object
 * @param {Object} obj: nested obj
 * @return {Object}
 */
export const getObjectFromNestedObj = (obj) => {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(...Object.keys(o).map((k) => (typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] })));
    })(obj),
  );
};

/**
 * get fied or Section level error for JSS form
 * @param {field} obj: JSS field object
 * @param {errorObj} obj: form submission error obj
 * @return {any} : return object for section and string for field
 */
export const getJSSFieldSectionErrors = (field, errorObj) => {
  let error = null;
  if (field.hasOwnProperty('fields')) {
    error = errorObj[field.model.name] || {};
  } else {
    const valueFieldName = resolvePath(field, 'valueField.name', '');
    const { emailConfirmationId } = UIConfig.jssForms.fields;
    const { confirmEmailFieldName } = UIConfig.jssForms.fieldName;
    const typeId = resolvePath(field, 'model.fieldTypeItemId', '');
    if (typeId === emailConfirmationId && valueFieldName) {
      const valueEmailFieldName = valueFieldName.replace('Value', confirmEmailFieldName);
      error = {};
      error[valueEmailFieldName] = errorObj[valueEmailFieldName] || '';
      error[valueFieldName] = errorObj[valueFieldName] || '';
    } else {
      error = valueFieldName ? errorObj[valueFieldName] : '';
    }
  }
  return error || '';
};

/*
 * Method to map JSS form inputs with API response
 */
export const getFieldValue = (field, res, mapperFlag = false) => {
  let value = '';
  const fieldName = resolvePath(field, 'model.name', '');
  const phoneNumberFieldName = ['PhoneNumber', 'MobilePhone', 'mobilephone'];
  const countryField = ['country', 'Country', 'countryOfResidence'];
  const firstNameFieldName = ['FirstName', 'firstname'];
  const emailFieldName = ['Email', 'emailaddress1'];
  const lastNameFieldName = ['LastName', 'lastname'];

  let lastName = lastNameFieldName.find((val) => [fieldName].includes(val)) || 'LastName';
  let firstName = firstNameFieldName.find((val) => [fieldName].includes(val)) || 'FirstName';
  let email = emailFieldName.find((val) => [fieldName].includes(val)) || 'email';
  let phoneNumber = phoneNumberFieldName.find((val) => [fieldName].includes(val)) || 'PhoneNumber';
  let country = countryField.find((val) => [fieldName].includes(val)) || 'country';
  let mapper = {
    [email]: 'email',
    [firstName]: 'firstName',
    [lastName]: 'lastName',
    ConfirmEmail: 'email',
    [phoneNumber]: 'mobileNum',
    [country]: 'countryAlphaCode',
  };
  const { selectWithTextId } = UIConfig.jssForms.fields;
  const typeId = resolvePath(field, 'model.fieldTypeItemId', '');
  value = res[mapper[fieldName]] || field.model.value;
  if (selectWithTextId === typeId) {
    value = `${res['countryCode'] || ''}|${value || ''}`;
  }
  return value;
};

export const getFieldErrorMessage = (fieldModel, validationMessageName) => {
  let msg = '';
  const errorMsgObj = resolvePath(fieldModel, 'validationDataModels', []).find(
    (item) => item.name && item.name.toLowerCase() === validationMessageName,
  );
  if (errorMsgObj) {
    msg = errorMsgObj.message;
  }
  return msg;
};

/**
 * Calculates Body Mass Index(BMI) rounded to two decimal places.
 *
 */
export const calculateBMI = (height, weight) => {
  return Math.round((weight / height / height) * 1000000) / 100;
};

export const createConfirmEmailField = (field, fieldToConfirm) => {
  const confirmEmailFieldName = UIConfig.jssForms.fieldName.confirmEmailFieldName;
  field.model = {
    ...field.model,
    isConfirm: true,
    name: confirmEmailFieldName,
    title: field.model.confirmEmailLabel,
    fieldToConfirm,
  };
  const valueFieldName = resolvePath(field, 'valueField.name', '');
  const valueFieldId = resolvePath(field, 'valueField.id', '');
  field.valueField = {
    ...field.valueField,
    name: valueFieldName && valueFieldName.replace('Value', confirmEmailFieldName),
    id: valueFieldId && valueFieldId.replace('Value', confirmEmailFieldName),
  };
  return field;
};

export const getTypeOfField = (field) => {
  const { checkboxId, emailConfirmationId } = UIConfig.jssForms.fields;
  const { Email, RadioButtonList, SingleLineText } = FieldTypes;
  const fieldTypeItemId = resolvePath(field, 'model.fieldTypeItemId', '');
  let type = '';
  if (fieldTypeItemId === Email || fieldTypeItemId === emailConfirmationId) {
    type = 'email';
  } else if (fieldTypeItemId === SingleLineText) {
    type = 'text';
  } else if (fieldTypeItemId === checkboxId) {
    type = 'Checkbox';
  } else if (fieldTypeItemId === RadioButtonList) {
    type = 'Radio';
  }
  return type;
};
