import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AnchorLink, DynamicContent, SvgSprite } from '../base';
import { canUseDOM, getYasIdGTMData } from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';

const renderCalendar = (calendar, downloadOffer, variant) => {
  if (!calendar || (!calendar.url && variant !== 'v-ya-news-attributes')) {
    return null;
  }

  const { description, end, label, location, recurring, start, timezone, title } = calendar;

  return (
    <div className={classNames({ 'download-offer-download': !!downloadOffer })}>
      <div className="addeventatc" data-dropdown-x="left" data-dropdown-y="down">
        <DynamicContent tagName="span" innerHtml={label} attrs={{ className: 'labelText' }} />
        {start && <span className="start">{start}</span>}
        {end && <span className="end">{end}</span>}
        {timezone && <span className="timezone">{timezone}</span>}
        {title && <span className="title">{title}</span>}
        {description && <span className="description">{description}</span>}
        {location && <span className="location">{location}</span>}
        {recurring && <span className="recurring">{recurring}</span>}
        <span className="c-icon">
          <SvgSprite id="add-to-calendar" />
        </span>
      </div>
    </div>
  );
};

/* downloadEventFile will pass the data into the pushEventFlags method to push the same into the datalayer */
const downloadEventFile = (downloadObj, title) => {
  const dataObj = {
    eventAction: `${[title]} : ${downloadObj.analyticsStart}`,
    eventLabel: `Download : ${downloadObj.pdfName}`,
  };
  pushEventFlags(dataObj);
};

/* calendarEventLabel will return label of the calendar event user have clicked on */
const calendarEventLabel = (label, calendars) => {
  const labelObj = calendars.find((obj) => label === obj.name);
  return labelObj && labelObj.text;
};

/* PushEventFlags method will push the object into the datalayer */
const pushEventFlags = (gtmData) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'AddToCalendar',
    eventCategory: 'Events',
    eventAction: gtmData.eventAction,
    eventLabel: gtmData.eventLabel,
    myPassId: getYasIdGTMData(),
  });
};

/*
  AddToCalendar will create a new section in hero component that will be used for PDF download link and add to calendar
*/
const AddToCalendar = ({ title, calendar, downloadOffer, variant }) => {
  try {
    if (calendar && calendar.url && canUseDOM()) {
      const calendarSettings = calendar.calendars.reduce((obj, pair) => {
        obj[pair.name] = { ...pair };
        return obj;
      }, {});

      window.addeventasync = () => {
        window.addeventatc.settings(calendarSettings);
        /* This will trigger on click of any item of expanded 'AddToCalendar' drop down */
        window.addeventatc.register('button-dropdown-click', (obj) => {
          const objService = calendarEventLabel(obj.service, calendar.calendars);
          const dataObj = {
            eventAction: `${[title]} : ${calendar.analyticsStart}`,
            eventLabel: `${[calendar.label]} : ${objService}`,
          };
          pushEventFlags(dataObj);
        });
      };

      const script = document.createElement('script');
      script.setAttribute('src', calendar.url);
      document.body.appendChild(script);
    }

    return (
      <div className="download-offer">
        {renderCalendar(calendar, downloadOffer, variant)}
        {downloadOffer && (
          <div className={classNames({ 'download-offer-calendar': !!(calendar && calendar.url) })}>
            <AnchorLink
              onClick={() => downloadEventFile(downloadOffer, title)}
              link={downloadOffer}
              className="secondary-cta download-offer-calendar--button"
            />
            <span className="download-offer-calendar--icon">
              <SvgSprite id="icn-download" />
            </span>
          </div>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'AddToCalendar', variant);
  }
};

AddToCalendar.propTypes = {
  calendar: PropTypes.shape({
    description: PropTypes.string,
    end: PropTypes.string,
    label: PropTypes.string,
    location: PropTypes.string,
    recurring: PropTypes.string,
    start: PropTypes.string,
    timezone: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  downloadOffer: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default AddToCalendar;
