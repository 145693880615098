/*
 * dynamic-content-components.js
 * This file contains code for DynamicContent component. on e can create tags like label, div etc.
 * @licensor  Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * DynamiContent is a component which render the provided htm dynamically we can use this
 * component in place of using dangerouslySetInnerHTML.
 * @param    {string}    tagName     wrapper html element for the provided html.
 * @param    {object}    attrs       html attributes of the wrapper element.
 * @param    {string}    innerHtml   inner html contents of the element.
 * @return   {[Object]}  Return a react element.
 */
const DynamicContent = ({ tagName, attrs, innerHtml }) => {
  try {
    if (innerHtml) {
      return React.createElement(tagName, {
        ...attrs,
        dangerouslySetInnerHTML: { __html: innerHtml },
      });
    } else {
      return null;
    }
  } catch (err) {
    return logComponentRenderingError(err, 'DynamicContent');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
DynamicContent.propTypes = {
  tagName: PropTypes.string.isRequired,
  innerHtml: PropTypes.string,
  attrs: PropTypes.object,
};

export default DynamicContent;
