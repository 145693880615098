/* eslint-disable no-control-regex */
/* eslint-disable no-new-func */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { DynamicContent } from '../../presentation/base/';
import { logComponentRenderingError } from '../../../common/logger';
import { resolvePath } from '../../../common/utility';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { canUseDOM, getMainObject } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';

const Widget = ({ WidgetCodeSnipet, Style, Title, CTA, Variant }) => {
  const [codeSnippet, cssClass, title, cta, variant] = [
    resolvePath(WidgetCodeSnipet, 'value', ''),
    resolvePath(Style, 'value', ''),
    resolvePath(Title, 'value', ''),
    resolvePath(CTA, 'value', ''),
    resolvePath(Variant, 'fields.ComponentClass.value', ''),
  ];
  const yasIdExist = canUseDOM() && resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
  const swadIdExist = canUseDOM() && resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.SWADB2C;

  try {
    if (!codeSnippet) {
      return null;
    }

    const style = { display: 'none' };
    const isSSRRendering = cssClass === 'ssr-rendering';

    const renderWidget = () => {
      return (
        <div className={cssClass}>
          {yasIdExist || swadIdExist ? (
            <>
              {cta && (title || cta.text) && (yasIdExist || swadIdExist) && (
                <span>
                  <div className="container">
                    <div className="section-header row">
                      <div className="col-md-9">
                        {title && <Text tag="h5" className="title" field={{ value: title, editable: title }} />}
                      </div>
                      {cta.href && cta.text ? (
                        <div className="col-md-3">
                          <a href={cta.href} className="cta-button iconCta" target="_blank" aria-label={cta.text}>
                            {cta.text}
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </span>
              )}
            </>
          ) : (
            <span style={style}>{title}</span>
          )}
          <DynamicContent tagName="div" innerHtml={codeSnippet}></DynamicContent>
        </div>
      );
    };

    const getScript = () => {
      let domElm = document.createElement('div');
      domElm.innerHTML = codeSnippet;
      let thirdPartyScriptEl = domElm.querySelector('script');
      domElm = null;
      if (thirdPartyScriptEl) {
        const getInnerScript = thirdPartyScriptEl.innerHTML.replace(/[^\x00-\x7F]/g, '');
        const excuteScript = new Function(getInnerScript);
        excuteScript();
      }
    };

    useEffect(() => {
      if (!isSSRRendering) {
        const events = ['scroll', 'mousemove', 'touchstart'];

        const initChatWidgetEvent = (event) => {
          getScript();
          events.forEach((e) => event.currentTarget.removeEventListener(e, initChatWidgetEvent));
        };

        events.forEach((e) => document.addEventListener(e, initChatWidgetEvent));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isSSRRendering) {
      return yasIdExist || swadIdExist ? <div className="social-media-widget">{renderWidget()}</div> : renderWidget();
    } else {
      return <></>;
    }
  } catch (err) {
    return logComponentRenderingError(err, 'Widget', variant);
  }
};

export default Widget;
