export class DeviceUtils {
  static getDeviceName(userAgent = navigator.userAgent) {
    const browsers = {
      edge: /Edge/i,
      operaMini: /Opera Mini/i,
      android: /\bAndroid\b/i,
      iphone: /\biPhone\b/i,
      ipad: /iPad/i,
      chrome: /Chrome/i,
      ie: /(?:MSIE)|(?:Trident)/i,
      opera: /(?:Opera)|(?:OPR)/i,
      firefox: /firefox/i,
      safari: /safari/i,
    };
    let deviceType = 'others';
    for (const browser in browsers) {
      if (browsers.hasOwnProperty(browser)) {
        const testResult = userAgent.match(browsers[browser]);
        if (testResult && testResult.length) {
          deviceType = browser;
          break;
        }
      }
    }
    return deviceType;
  }

  static isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
