import React from 'react';
import PropTypes from 'prop-types';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component.js';
import {
  canUseDOM,
  detectMobile,
  decodeQueryString,
  putElementInViewport,
  checkTenant,
  isMobileOrTab,
  isMatchTenant,
  getGa4Category,
  toLowerCase,
  checkGA4Tenants,
} from '../../../../common/utility';
import { Tabs, TabHeaders, TabPanels, Header, Panel } from '../../../presentation/tabs/tabs';
import FastPassComponent from '../fast-pass/fast-pass-component';
import GeneralAdimission from '../general-admission/general-admission-component';
import BookingOverlay from '../booking-overlay/booking-overlay';
import AnnualPass from '../annual-pass/annual-pass-component';
import SpecialTickets from '../special-ticket/special-ticket-component';
import AddOnsComponent from '../add-ons/add-ons-component';
import UIConfig from '../../../../common/UIConfig';
import EventPerformanceList from '../event-performance-list/event-performance-list-component';
import AddOnsTabComponent from '../add-ons-tab/add-ons-tab-component';
import PastPurchaseAddOn from '../add-ons-tab/past-purchase-addon';
import PastPurchased from '../past-purchased';
import GTMData from '../gtm-data.js';

const GTMDataOnClickTab = (data) => {
  if (checkGA4Tenants()) {
    GTMData.push(UIConfig.ga4Constants.CLICK_TAB, {
      name: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.name) : data.name,
      elementText: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.name) : data.name,
      category: toLowerCase(getGa4Category(window?.location?.pathname)),
    });
  }
};
export default class BookingTabsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.recommendationTabName = UIConfig.b2c.purchaseJourney.recommendationTabCode;
    this.pastRecommendationTabName = UIConfig.b2c.purchaseJourney.pastPurchasedTabCode;
    this.componentMap = {
      FastPassComponent: FastPassComponent,
      BookingOverlay: BookingOverlay,
      GeneralAdimission: GeneralAdimission,
      SpecialTickets: SpecialTickets,
      AnnualPass: AnnualPass,
      AddOnsComponent: AddOnsComponent,
      select_shows: EventPerformanceList,
      ya_recommendation: AddOnsTabComponent,
      ya_past_purchase_addon: PastPurchaseAddOn,
      PastPurchased: PastPurchased,
    };
    this.mapper = this.getComponentsMap({ ...props.tabsMapper });
    this.scrollComplete = false;
    const tabIndex = this.getTabIndexByHash() || 0;
    this.queryParams = decodeQueryString();
    this.state = {
      activeTab: tabIndex !== -1 ? tabIndex : 0,
      isFirstTimeLoad: false,
    };
    this.yaEventListingVariant = this.props.variant === 'ya-event-listing';
    this.isEA = checkTenant(UIConfig.iamMapping.etihadarena);
  }

  scrollToAddons = (onLoad) => {
    const urlParams = new URLSearchParams(window.location.search);
    const salesAk = urlParams.get('sale');
    const isCheckout = urlParams.get('currpage') === 'checkout';
    if (onLoad) {
      if (!salesAk && !isCheckout) {
        return;
      }
    }
    const lazyLoadContainer = document.querySelectorAll('.c-purchase-journey');
    if (lazyLoadContainer.length > 0) {
      const topPosition = lazyLoadContainer?.[0].offsetTop - (isMobileOrTab() ? 0 : 120);
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
    this.scrollComplete = true;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showMinicartCouponCode && this.props.isRecommendationVisible !== prevProps.isRecommendationVisible) {
      if (!this.props.isRecommendationVisible && this.state.activeTab === 0 && this.props.data.length > 1) {
        window.location.hash = this.props.data[1].type;
      } else if (this.props.isRecommendationVisible && this.props.data.length > 1) {
        window.location.hash = this.props.data[0].type;
      }
    } else if (this.props.isYasArenaView) {
      const isPastPurchaseRecomendation =
        this.props.data[0].type.toLowerCase() === UIConfig.b2c.purchaseJourney.pastPurchaseRecommendationTabCode;
      if (
        this.props.isYaAddOn &&
        this.state.activeTab === 0 &&
        prevState.activeTab !== 1 &&
        !isPastPurchaseRecomendation
      ) {
        window.location.hash = this.props.data[1].type;
      } else if (this.props.cartData.cart.items.length === 0 && this.state.activeTab !== 0) {
        window.location.hash = this.props.data[0].type;
      }
      if (!this.scrollComplete) {
        this.scrollToAddons(true);
      }
    }
  }

  componentDidMount() {
    if (canUseDOM()) {
      const { variant, annualPassGridVariant } = this.props;
      !annualPassGridVariant && window.addEventListener('hashchange', this.updateActiveTab.bind(this), false);
      const isMobileView =
        detectMobile() &&
        variant &&
        (variant.toLowerCase() === 'booking-tabs-mobile-ddl' || this.yaEventListingVariant);
      const activeElSelector = isMobileView ? '.dropdown-item--selected' : '.tabs-menu--links.is-active';
      const activeEl = document.querySelector(activeElSelector);
      activeEl && putElementInViewport(activeEl);
      this.setState({ isFirstTimeLoad: true });
    }
  }

  componentWillUnmount() {
    if (canUseDOM()) {
      window.removeEventListener('hashchange', this.updateActiveTab.bind(this), false);
    }
  }

  getComponentsMap = (mapper) => {
    if (!mapper || Object.keys(mapper).length === 0) {
      return {
        fast_pass: FastPassComponent,
        general_admission_tab: BookingOverlay,
        general_admission: GeneralAdimission,
        special_ticket: SpecialTickets,
        annual_pass: AnnualPass,
        add_ons: AddOnsComponent,
        select_shows: EventPerformanceList,
        ya_recommendation: AddOnsTabComponent,
        ya_past_purchase_addon: PastPurchaseAddOn,
        extra_special_ticket: SpecialTickets,
        postrecommendation: PastPurchased,
      };
    }
    for (let key in mapper) {
      if (!mapper.hasOwnProperty(key)) {
        continue;
      }
      mapper[key] = this.componentMap[mapper[key]];
    }
    return mapper;
  };

  /**
   * get tab index by location hash
   * @param {[string]} location hash
   */
  getTabIndexByHash = () => {
    if (canUseDOM()) {
      return (
        this.props.data.findIndex &&
        this.props.data.findIndex((item) => item.type === window.location.hash.substring(1).split('?')[0])
      );
    }
  };

  /**
   * update hash on tab change
   */
  setActiveTabHash = (id) => {
    GTMDataOnClickTab(this.props.data?.[id]);
    if (this.props.data[id] && this.props.data[id].type) {
      window.location.hash = this.props.data[id].type;
    }
  };

  /**
   * bindTabHeader   binds tabs headers
   * @param    {[Object]} tabData object for which tabs header needs to be created
   * @param    {[Number]} index -index of tabs panel
   */
  bindTabHeader = (tabData, index) => {
    const title = tabData.name;
    const coveoValue = tabData.coveoValue;
    let headerItemClasses = '';
    if (
      (coveoValue.toLowerCase() === this.recommendationTabName && !this.props.isRecommendationVisible) ||
      (tabData.type.toLowerCase() === 'ya_recommendation' && !this.props.isYaAddOn)
    ) {
      headerItemClasses = 'hide-tab';
    }
    return (
      <Header index={index} key={index} classes={headerItemClasses}>
        <DynamicContent
          attrs={{ className: 'heading-5', tabIndex: this.state.activeTab === index ? -1 : 0 }}
          tagName="span"
          innerHtml={title}
        />
      </Header>
    );
  };

  /**
   * bindTabPanel   binds tabs panel
   * @param    {[Object]} tabPanel object for which tab panel needs to be created
   * @param    {[Number]} index -index of tabs panel
   */
  bindTabPanel = (tabPanel, index) => {
    let TagName = this.mapper['' + tabPanel.type.toLowerCase()];
    //TODO:- update type from CMS and remove this code
    if (tabPanel.type.toLowerCase() === 'ya_recommendation') {
      TagName = this.mapper['' + 'recommendation'];
    }
    //
    if (!TagName) {
      TagName = this.mapper.general_admission;
    }

    const coveoValue = tabPanel.coveoValue;
    let panelItemClasses = '';
    if (
      (coveoValue.toLowerCase() === this.recommendationTabName &&
        tabPanel.type.toLowerCase() !== this.pastRecommendationTabName &&
        !this.props.isRecommendationVisible) ||
      (tabPanel.type.toLowerCase() === 'ya_recommendation' && !this.props.isYaAddOn)
    ) {
      panelItemClasses = 'hide-tab';
    }
    return (
      <Panel index={index} key={index} classes={panelItemClasses}>
        {TagName && (
          <TagName
            textWithCtaSettings={this.props.textWithCtaSettings}
            data={tabPanel}
            isCrossSellRecommendationEnable={this.props.isCrossSellRecommendationEnable}
            hightLightFirstTicket={this.props.hightLightFirstTicket}
            showPostPurchaseRecommendation={this.props.showPostPurchaseRecommendation}
            postPurchaseRecommendationCTA={this.props.postPurchaseRecommendationCTA}
            isGAEnabledForCrossSell={this.props.isGAEnabledForCrossSell}
            bookingUrl={this.props.bookingUrl}
            maxTicketToShow={this.props.maxTicketToShow}
            recommendationUrl={this.props.recommendationUrl}
            backLinkText={this.props.backLinkText}
            carousel={this.props.carousel}
            tktVal={this.queryParams.tkt}
            addToCartCallback={this.props.addToCartCallback}
            mupValidityMap={this.props.mupValidityMap}
            createProductStore={this.props.createProductStore}
            getDiscountedProducts={this.props.getDiscountedProducts}
            createMappedData={this.props.createMappedData}
            isYAEmptyBookingPage={this.props.isYAEmptyBookingPage}
            services={this.props.services}
            cartData={this.props.cartData}
            productMainCategoryWithTabName={this.props?.miniCart?.productMainCategoryWithTabName}
            businessErrors={this.props.businessErrors}
            coveoMapList={this.props.coveoMapList}
            showMinicartCouponCode={this.props.showMinicartCouponCode}
            discountMap={this.props.discountMap}
            checkRecommendationVisibility={this.props.checkRecommendationVisibility}
            recommendedDataRes={this.props.recommendedDataRes}
            setRecommendedData={this.props.setRecommendedData}
            additionalRecommendedData={this.props.additionalRecommendedData}
            coveoMappingList={this.props.coveoMappingList}
            isYaAddOn={this.props.isYaAddOn}
            availabilityMapping={this.props.availabilityMapping}
            cartActions={this.props.cartActions}
            addOnProducts={this.props.addOnProducts}
            isYaNonSeatedJourney={this.props.isYaNonSeatedJourney}
            clearYACart={this.props.clearYACart}
            showYaNonSeatedOverlay={this.props.showYaNonSeatedOverlay}
            selectNewYAPerformance={this.props.selectNewYAPerformance}
            getProductsList={this.props.getProductsList}
            isFirstTimeLoad={this.state.isFirstTimeLoad}
            setOverlay={this.setOverlay}
            noRecommendationTitle={this.props.noRecommendationTitle}
            noRecommendationDesc={this.props.noRecommendationDesc}
            productDetailsKeyForAnnualPass={this.props.coveoMappingList.productDetailLink}
            {...(this.props.annualPassGridVariant && {
              annualPassGridVariant: this.props.annualPassGridVariant,
              updateQuantity: this.props.updateQuantity,
              deleteProduct: this.props.deleteProduct,
            })}
          />
        )}
      </Panel>
    );
  };
  setOverlay = (flag) => {
    this.setState({ isFirstTimeLoad: flag });
  };
  updateActiveTab = (event) => {
    if (event.target.location.hash === '#main-content') return;
    this.setState(
      {
        activeTab: this.getTabIndexByHash() || 0,
      },
      () => {
        const { variant } = this.props,
          isMobileView =
            detectMobile() &&
            variant &&
            (variant.toLowerCase() == 'booking-tabs-mobile-ddl' || this.yaEventListingVariant),
          activeElSelector = isMobileView ? '.dropdown-item--selected' : '.tabs-menu--links.is-active',
          activeEl = document.querySelector(activeElSelector);
        activeEl && putElementInViewport(activeEl);
      },
    );
  };

  /**
   * bindTabs   binds tabs
   * @param    {[Array]} tabsData array
   */
  bindTabs = (tabsData) => {
    const { variant } = this.props;
    const isBookingTabsMobileDDL =
      (detectMobile() && variant && (variant === 'booking-tabs-mobile-ddl' || this.yaEventListingVariant)) || false;
    const hideNav =
      tabsData[0].type.toLowerCase() === this.pastRecommendationTabName || this.props.annualPassGridVariant;

    const tabsList = [];

    if (!this.props.hideTabs) {
      if (this.isEA) {
        tabsData.forEach((item, index) => item.name && tabsList.push(this.bindTabHeader(item, index)));
      } else {
        tabsData.forEach((item, index) => tabsList.push(this.bindTabHeader(item, index)));
      }
    }

    return (
      <Tabs
        defaultActiveTab={this.state.activeTab}
        dropDownOnMobile={isBookingTabsMobileDDL}
        isUpdateDropdownProps={isBookingTabsMobileDDL}
        onBeforeChange={this.setActiveTabHash}
        tabData={this.props}
        annualPassGridVariant={this.props.annualPassGridVariant}
      >
        <TabHeaders classes={`${hideNav ? 'd-none' : ''}`}>{tabsList}</TabHeaders>
        <TabPanels>{tabsData.map((item, index) => this.bindTabPanel(item, index))}</TabPanels>
      </Tabs>
    );
  };

  render() {
    let { data } = this.props;
    return (
      <div className="component c-booking-tabs">
        <div className="">{data && data.length ? this.bindTabs(data) : null}</div>
      </div>
    );
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */
BookingTabsComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
