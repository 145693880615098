/**
 * KeyCode
 *
 * Map of common (non-printable) keycodes for the `keydown` and `keyup` events.
 * Note that `keypress` handles things differently and may not return the same
 * values.
 */
export const KeyCode = {
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

//Variable to store configuration for Auto Suggest component
export const autoSuggestConfig = {
  minimumCharacters: 3,
};

/**
 * Accessibility functionality
 */
export const keyCodeObject = Object.freeze({
  ENTER: 13,
  SPACE: 32,
  UP: 38,
  DOWN: 40,
  TAB: 9,
});

/**
 * Variable to Store Global Default settings for Carousel component
 */
export const carouselDefaultSettings = {
  dots: true,
  centerMode: true,
  centerPadding: '100px',
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 3000,
  useCSS: true,
  rtl: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000000,
      settings: 'unslick',
    },
  ],
};

/**
 * Variable to Store Global Pagination settings
 */
export const paginationDefaultSettings = {
  previousLabel: '<-',
  nextLabel: '->',
  breakLabel: '...',
  breakClassName: 'break-me',
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 3,
  onPageChange: this && this.handlePageClick,
  containerClassName: 'pagination',
  pageCount: 10,
  subContainerClassName: 'pages pagination',
  activeClassName: 'active',
};

export const breakpoints = {
  desktop: 1024,
  tablet: 768,
  mobile: 320,
};
