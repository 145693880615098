import UIConfig from '../UIConfig';

import { scrollToError } from './errors';
import { canUseDOM, refreshTabs, getUserAgent } from './window';
import { getCurrentLanguage } from './datetime';
import { isLoggedInUser } from './login';
import crypto from 'crypto';

export * from './device';
export * from './login';
export * from './constants';
export * from './errors';
export * from './window';
export * from './product';
export * from './datetime';
export * from './helpers';

/*Attaches the resize event on window which will publish the event named window Resize.
  The person who wants to detects the resize event then he/she will have to subscribe to the
  event named windowResize*/
(() => {
  if (canUseDOM()) {
    window.addEventListener('resize', () => {
      window.PubSub.publish('windowResize', {
        userAgent: window.navigator.userAgent,
      });
    });

    window.addEventListener('orientationchange', function() {
      let angle = window.screen.orientation ? window.screen.orientation.angle : window.orientation;
      window.PubSub.publish('oreintationChange', {
        orientation: angle,
      });
    });

    window.PubSub.subscribe('errorOccured', (msg, data) => {
      scrollToError(data);
    });

    /**
     * add refresh tabs functionality to select tab given in smart url
     */
    const tabLinks = document.getElementsByClassName('refresh-tabs');
    for (let i = 0; i < tabLinks.length; i++) {
      tabLinks[i].onclick = refreshTabs;
    }
  }
})();

//overrideDateFormatB2C
(() => {
  const lang = getCurrentLanguage();
  if (lang === 'ar-AE') {
    UIConfig.b2c.purchaseJourney.dateFormat = UIConfig.b2c.purchaseJourney.arabicDateFormat;
    UIConfig.b2c.purchaseJourney.ymcdateTimeFormat = UIConfig.b2c.purchaseJourney.ymcArabicDateTimeFormat;
  }
})();

if (canUseDOM()) {
  window.JSbridge = {
    setUserAgent: function(data) {
      window.localStorage.deviceInfo = JSON.stringify(data);
    },
    nativeCallback: function(data) {
      const userAgent = getUserAgent();
      if (userAgent && userAgent.deviceType === UIConfig.deviceType.android) {
        window.Android.dataToAndroid(JSON.stringify(data));
      } else if (userAgent && userAgent.deviceType === UIConfig.deviceType.ios) {
        window.webkit.messageHandlers.callbackhandler.postMessage(data);
      }
    },
  };
}

export const generateShaEncryption = (content) => {
  return crypto
    .createHash('sha256')
    .update(content)
    .digest('hex');
};

export const deepCloneObject = (obj) => {
  const clone = Array.isArray(obj) ? [] : {};
  for (const i in obj) {
    if (obj[i] !== null && typeof obj[i] === 'object') {
      clone[i] = deepCloneObject(obj[i]);
    } else {
      clone[i] = obj[i];
    }
  }
  return clone;
};

/**
 * isEmpty function to check if object is empty or not.
 * @param    {[Object]} obj [any javascript object]
 * @return   {[boolean]} returns the boolean value, false if object is not empty and true if object is empty
 */
export const isEmpty = (obj) => {
  if (!obj) return true;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

/**
 * isReduxDisconnectedMode function to check for disconnected mode for redux component.
 * @param    {[Object]} ownProps [any javascript object] Mock data
 * @param    {[Object]} state [any javascript object] store state
 * @param    {[String]} compName componentName
 * @return   {[object]} state
 */
export const getReduxDisconnectedData = (ownProps, state, compName) => {
  const isDisconnectedMode = ownProps && (isEmpty(state) || !Boolean(state[compName]));
  if (isDisconnectedMode) {
    state[compName] = { [ownProps.uid]: ownProps.data };
  }
  return state;
};

export const sortInstallments = (response, sortOrder) => {
  if (Number(sortOrder) === 1) {
    return response.sort((a, b) => b.number_of_installment - a.number_of_installment);
  }
  return response.sort((a, b) => a.number_of_installment - b.number_of_installment);
};

export const getUserTypeForGa4 = () => (isLoggedInUser() ? 'logged in' : 'guest');
