import React from 'react';
import Helmet from 'react-helmet';
import { logComponentRenderingError } from '../../../common/logger';

const SEO = (props) => {
  try {
    return (
      <>
        <Helmet>
          <meta name="title" content={props.metatitle} />
          <meta name="description" content={props.metaPageDescription} />
          {props.noIndex && <meta name="robots" content="noindex" />}
          <meta name="keywords" content={props.metaKeywords}></meta>
          <link rel="canonical" href={props.canonicalUrl} />
          <title>{props.pageTitle}</title>
        </Helmet>
        {Array.isArray(props.hrefLang) && (
          <Helmet>
            {props.hrefLang.length > 0 &&
              props.hrefLang.map((item) => {
                return <link rel="alternate" href={item.url} hreflang={item.lang} />;
              })}
          </Helmet>
        )}
        {props.fbTitle && (
          <Helmet>
            <meta property="og:title" content={props.fbTitle} />
            {props.fbDescription && <meta property="og:description" content={props.fbDescription} />}
            {props.fbImage && <meta property="og:image" content={props.fbImage} />}
            {props.fbPageType && <meta property="og:type" content={props.fbPageType} />}
            {props.fbUrl && <meta property="og:url" content={props.fbUrl} />}
          </Helmet>
        )}
        {props.twtTitle && (
          <Helmet>
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={props.twtTitle} />
            {props.twtDescription && <meta name="twitter:description" content={props.twtDescription} />}
            {props.twtImage && <meta name="twitter:image:src" content={props.twtImage} />}
            {props.twtHandle && <meta name="twitter:site" content={props.twtHandle} />}
          </Helmet>
        )}
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'SEO', props.data.variant);
  }
};

export default SEO;
