import moment from 'moment';

import UIConfig from '../UIConfig';
import { canUseDOM } from './window';
import { isLoggedInUser } from './login';
import { encryptParam, decryptParam, getLoggedInUser, parseQueryString, getSessionStorage } from '../utility';
import { getLocalStorageByKey, toTwoDecimalPlaces } from './';

export const roundToTwoPlaces = (number, showDecimalZeros = false) => {
  const numberWithDecimalZeroes = parseFloat(Math.round(number * 100) / 100).toFixed(2);
  return showDecimalZeros ? numberWithDecimalZeroes : parseFloat(numberWithDecimalZeroes);
};

/**
 * Desc: match text in array elements
 * @param {String} string to search in array elements
 * @param {Array} array in which search perform
 * @{}
 * */
export const matchInArray = (stringSearch, arrayExpressions) => {
  const position = String(arrayExpressions).search(stringSearch);
  return position > -1 ? true : false;
};

export const priceValue = (price) => {
  if (isNaN(price) || !price) {
    return toTwoDecimalPlaces(0);
  }
  return parseFloat(price);
};

export const createPriceProductMashUp = (omniProducts, coveoProducts) => {
  omniProducts.forEach((item) => {
    if (coveoProducts[item.id]) {
      coveoProducts[item.id]['price'] = item.price;
    }
  });
  return coveoProducts;
};

export const createMapAndGroup = (data, key) => {
  const productTypeMap = {};
  const commissionMap = {};
  const subTotalMap = {};
  const group = data.reduce(function(reducedValue, product) {
    if (!productTypeMap[key]) {
      productTypeMap[product[key]] = product[key + 'Name'];
      commissionMap[product[key]] = 0;
      subTotalMap[product[key]] = 0;
    }
    (reducedValue[product[key]] = reducedValue[product[key]] || []).push(product);
    return reducedValue;
  }, {});
  return { group, productTypeMap, commissionMap, subTotalMap };
};

const getAdditionalDiscount = ({ discountListItem, totalPrice }) => {
  let sum = discountListItem.reduce((acc, val) => {
    return acc + parseFloat(val.discount.amount);
  }, 0);

  return {
    discountListItem: Math.abs(sum),
    totalPrice: Math.abs(sum) + parseFloat(totalPrice),
  };
};

export const groupCartData = (cartData, isSourceMarket = false) => {
  /*This function makes the grouping for cart and mini cart. The final grouping is like
         let finalGrouping = {
              facetType1: {
                  groupType1 : {
                      groupTypeName: Name of the group or park. Like ferrari abu dhabi,
                      products: {
                          productIdType1: {
                              "ticketType": true,
                              "ticketTypeName": true,
                              "sequence" : true,
                              "date" : true,
                              "productName" : true,
                              "timeSlot" : true,
                              "quantity": true,
                              "productId": true,
                              "performanceId": true,
                              "availability": true,
                              "parentId": true,
                              "groupType": true,
                              "groupTypeName": true,
                              "productType": true,
                              "productTypeName": true,
                              "changeInPrice": true,
                              "otherReason": true,
                              "price": true,
                              "commissionInPerc": true,
                              "commission": true,
                              "netPrice": true,
                              "guestDetails": true,
                              "productIdType": true,
                              "associatedProdId": true,
                              "parentId": true,
                              "fromDate": true,
                              "toDate": true,
                              "license": true,
                              "addOns": true,
                              "ticketVal": true,
                              "total": true,
                              "unitPrice": true,
                          }
                      }
                  },
                  groupType2 : {
  
                  },
                  availability: Tells the availability of the products. This is set by checkBasket,
                  commission : Total comission of the facet,
                  productType : unique id of the facet,
                  productTypeName : The name of the facet. Like single park, multipark, add ons etc,
                  subTotal: Subtotal of the facet,
                  totalTiles: total number of tiles that will be formed on UI. Means the total number of unique tickets in the facet. This used for load more logic on Cart page,
              }
         }*/
  const productList = createMapAndGroup(cartData.items, 'productType'),
    parentGroupProducts = productList.group,
    parentProductTypeMap = productList.productTypeMap,
    finalData = {};
  let cartAvailability = true,
    guestDetailValid = true,
    commissionMap = {
      total: 0,
    },
    subTotalMap = {
      total: 0,
    },
    netPrice = 0,
    grossPrice = 0,
    blockedTimeSlotsMap = {};
  const addOnsCache = {};
  commissionMap = {
    ...productList.commissionMap,
    ...commissionMap,
  };
  subTotalMap = {
    ...productList.subTotalMap,
    ...subTotalMap,
  };
  finalData.map = {
    ...parentProductTypeMap,
  };

  for (const group in parentGroupProducts) {
    const nestedGroup = createMapAndGroup(parentGroupProducts[group], 'groupType');
    let availability = true;
    parentGroupProducts[group] = nestedGroup.group;
    parentProductTypeMap[group] = nestedGroup.productTypeMap;
    finalData.map[group] = {
      ...nestedGroup.productTypeMap,
    };
    parentProductTypeMap[group].totalTiles = 0;

    for (const items in parentGroupProducts[group]) {
      parentProductTypeMap[group][items] = {};
      const objMap = {};
      const mapKey = 'productIdType';
      let looped = false;

      parentGroupProducts[group][items] = parentGroupProducts[group][items].sort((a, b) => {
        return a.sequence - b.sequence;
      });

      parentProductTypeMap[group].totalTiles += 1;

      // eslint-disable-next-line no-loop-func
      parentGroupProducts[group][items].forEach((item, index) => {
        const itemPromotionApplied = item.ItemPromotionList ? item.ItemPromotionList.length > 0 : false;
        const commission = itemPromotionApplied
          ? getAdditionalDiscount({ discountListItem: item.ItemPromotionList, totalPrice: item.price.gross })
              .discountListItem
          : item.commission || 0;
        let totalPricePrd = 0,
          netPricePrd = 0,
          grossPricePrd = 0;
        if (item.associatedProdId) {
          netPricePrd = item.total;
          totalPricePrd = item.netPrice + (item.commission || 0);
          grossPricePrd = item.price.gross;
        } else if (item.parentId) {
          totalPricePrd = 0;
          grossPricePrd = item.price.gross;
        } else {
          netPricePrd = item.price.net;
          grossPricePrd = item.price.gross;
          totalPricePrd = grossPricePrd + (itemPromotionApplied ? commission || item.commission : item.commission || 0);
        }
        item.commission = commission;
        objMap[item[mapKey]] = item;
        commissionMap[group] = roundToTwoPlaces(commissionMap[group] + commission);
        commissionMap['total'] = roundToTwoPlaces(commissionMap['total'] + commission);
        let mapAvailable = parentProductTypeMap[group][items],
          noAddOnGuest = true;
        parentProductTypeMap[group][items] = mapAvailable ? mapAvailable : {};
        parentProductTypeMap[group][items]['products'] = objMap;

        if (!looped) {
          parentProductTypeMap[group].productTypeName = item.productTypeName;
          parentProductTypeMap[group].productType = item.productType;
          parentProductTypeMap[group][items].groupTypeName = item.groupTypeName;
          looped = true;
        }
        if (!item.availability) {
          availability = false;
          cartAvailability = availability;
        }
        if (isSourceMarket && !item.guestDetails) {
          guestDetailValid = false;
        }
        if (item.associatedProdId) {
          if (addOnsCache[item.associatedProdId]) {
            let totalCommission = (item.commission || 0) + (addOnsCache[item.associatedProdId].commission || 0);
            item.addOns = [addOnsCache[item.associatedProdId]];
            item.total = Number(item.price.gross) + Number(addOnsCache[item.associatedProdId].price.gross);
            item.netPrice = item.total + totalCommission;
            totalPricePrd = item.netPrice;
            grossPricePrd = item.total;
            noAddOnGuest = false;
          } else {
            for (const key in parentGroupProducts[group]) {
              const currPrd = parentGroupProducts[group][key][index];
              if (currPrd && item && currPrd.parentId === item.associatedProdId) {
                let totalCommission = (item.commission || 0) + (currPrd.commission || 0);
                item.addOns = [currPrd];
                noAddOnGuest = false;
                item.total = Number(item.price.net) + Number(currPrd.price.net);
                item.netPrice = item.total + totalCommission;
                totalPricePrd = item.netPrice;
                grossPricePrd = item.total;
                break;
              }
            }
          }
          if (noAddOnGuest) {
            item.addOns = [{}];
          }
          blockedTimeSlotsMap[item.fromDate] = blockedTimeSlotsMap[item.fromDate]
            ? blockedTimeSlotsMap[item.fromDate]
            : [];
          blockedTimeSlotsMap[item.fromDate].push(item.timeSlot.replace(/\s/g, ''));
        } else if (item.parentId) {
          addOnsCache[item.parentId] = item;
          delete parentProductTypeMap[group][items];
        } else {
          item.netPrice = totalPricePrd;
        }
        subTotalMap[group] = roundToTwoPlaces(subTotalMap[group] + totalPricePrd);
        subTotalMap['total'] = roundToTwoPlaces(subTotalMap['total'] + totalPricePrd);
        netPrice = roundToTwoPlaces(netPrice + netPricePrd);
        grossPrice = roundToTwoPlaces(grossPrice + grossPricePrd);
      });
    }
    parentProductTypeMap[group].availability = availability;
    parentProductTypeMap[group].commission = commissionMap[group];
    parentProductTypeMap[group].subTotal = subTotalMap[group];
    netPrice = roundToTwoPlaces(subTotalMap['total'] - commissionMap['total']);

    finalData.data = parentProductTypeMap;
    finalData.priceMap = {
      blockedTimeSlotsMap,
      commission: commissionMap,
      subTotal: subTotalMap,
      netPrice: netPrice,
      grossPrice: grossPrice,
    };
    finalData.cartAvailability = cartAvailability;
    finalData.guestDetailValid = guestDetailValid;
  }

  return finalData;
};

export const isBuyButton = (isSoldOut, isFree, theme, isUpcomingEvent) => {
  return !+isSoldOut && !+isFree && theme !== UIConfig.eventListingFilter.yaEventStaticTheme && !+isUpcomingEvent;
};

export const getCardButtonLabel = (
  isSoldOut,
  soldOutLabel,
  isFree,
  freeLabel,
  buyNowLabel,
  isUpcomingEvent,
  upcomingEventLabel,
  expiredLabel,
  isExpired,
) => {
  return isExpired
    ? expiredLabel
    : +isSoldOut
    ? soldOutLabel
    : +isUpcomingEvent
    ? upcomingEventLabel
    : +isFree
    ? freeLabel
    : buyNowLabel;
};

export const getArrayItemByKeyVal = (array, params) => {
  for (let i = 0; i < array.length; i++) {
    const prod = array[i];
    let val = true;
    for (const key in params) {
      if (!prod.hasOwnProperty(key) || prod[key].indexOf(params[key]) === -1) {
        val = false;
        break;
      }
    }
    if (val) {
      return array[i];
    }
  }
};

/**
 * generate unique prodIdType for the product which will be used in grouping
 * @param {Object} prod product for which productIdType needs to be generated
 */
export const getProdIdType = (prod, groupingNotRequired = false) => {
  const tenantId = getLoggedInUser().tenantID || JSON.parse(localStorage.mainObj).tenantID;
  if (prod.hasOwnProperty('relatedProduct') && tenantId.toLowerCase() !== UIConfig.ymcB2CTenant) {
    return (
      (prod.fromDate || '') +
      (prod.toDate || '') +
      (prod.relatedProduct || '') +
      (prod.parksKey ? prod.parksKey.sort().join('') : '') +
      (prod.category || '') +
      (groupingNotRequired ? prod.productId : '')
    );
  } else {
    return (
      (prod.isPostPurchasedAddon ? UIConfig.b2c.purchaseJourney.postPurchasedIdType : '') +
      (prod.productType || '') +
      prod.itemType +
      (prod.ticketVal || '') +
      (prod.fromDate || '') +
      (prod.toDate || '') +
      (prod.performanceId || '') +
      (prod.ticketType || '') +
      (prod.accessPolicy || '') +
      (prod.parksKey ? prod.parksKey.sort().join('') : '') +
      (prod.category || '') +
      (groupingNotRequired ? prod.productId : '')
    );
  }
};

export const getProdDetailsFromOrderDetails = (product, orderItem) => {
  return {
    ...product,
    itemType: product.crossSellType ? product.crossSellType : product.itemType,
    performanceId: orderItem.performances.length ? orderItem.performances[0].performanceId : '',
    productId: product.crossSellType ? product.productId : '',
    accessPolicy: product.accessPolicy,
    fromDate: moment(orderItem.tickets[0].validFrom)
      .locale('en')
      .format(UIConfig.calendar.dateFormat),
    toDate: moment(orderItem.tickets[0].validTo)
      .locale('en')
      .format(UIConfig.calendar.dateFormat),
  };
};

/**
 * Returms mapped hash based on coveoMapping
 * @param {Object, Object}
 * data product for which mappedData needs to be generated
 * keyMap is for coveoMappingList
 */
export const createMappedData = (data, keyMap) => {
  const result = data.map((prod) => {
    const res = {};
    for (const key in keyMap) {
      if (prod.hasOwnProperty(keyMap[key])) {
        res[key] = prod[keyMap[key]];
      }
    }
    return res;
  });
  return result;
};

export const removeADCBTouchPointsStorage = () => {
  canUseDOM() && localStorage.removeItem(UIConfig.b2c.purchaseJourney.touchPoint.purchaseInfo);
};

export const removeMoneyCardStorage = () => {
  canUseDOM() && localStorage.removeItem(UIConfig.b2c.purchaseJourney.moneyCard.purchaseInfo);
};

export const removePointsPayStorage = () => {
  canUseDOM() && localStorage.removeItem(UIConfig.paymentOption.pointsPay);
};
export const removePostPayStorage = () => {
  canUseDOM() && localStorage.removeItem(UIConfig.paymentOption.postPay);
};

export const checkAnonymousCart = () => {
  if (canUseDOM()) {
    if (localStorage.getItem('anonymousCart')) {
      return true;
    }
    return false;
  }
};

export const setPromoCode = (value) => {
  if (canUseDOM()) {
    const encryptValue = value ? encryptParam(value) : value;
    localStorage.setItem('promoCode', encryptValue);
  }
};
// export const setCouponCode = (value) => {
//   if (canUseDOM()) {
//     const encryptValue = value ? encryptParam(value) : value;
//     return encryptValue;
//   }
// };
// export const getCouponCode = (value) => {
//   if (canUseDOM()) {
//     const encryptValue = value ? decryptParam(value) : value;
//     return encryptValue;
//   }
// };

export const getPromoCode = () => {
  if (canUseDOM()) {
    const decryptValue = localStorage.getItem('promoCode')
      ? decryptParam(localStorage.getItem('promoCode'))
      : localStorage.getItem('promoCode');
    return decryptValue;
  }
};

export const setOrderIdpromoCode = (value) => {
  if (canUseDOM()) {
    localStorage.setItem('orderIdpromoCode', value);
  }
};

export const getOrderIdpromoCode = () => {
  if (canUseDOM()) {
    return localStorage.getItem('orderIdpromoCode');
  }
};

export const removePromoCode = () => {
  if (canUseDOM()) {
    localStorage.removeItem('promoCode');
  }
};

export const isDisabledProduct = (product) => {
  return product && product.disabledProduct && Boolean(Number(product.disabledProduct));
};

export const isTicketUpgradeJourney = () => {
  const anonymousCart = canUseDOM() && getAnonymousCart();
  const isTicketUpgradeCart = anonymousCart?.cart?.isTicketUpgradeCart || false;
  if (isTicketUpgradeCart) {
    return true;
  } else {
    return false;
  }
};

export const backToUpgradePage = () => {
  const upgradeUrlWithQS = (canUseDOM() && getLocalStorageByKey(UIConfig.parksTicketUpgrade.upgradeUrlWithQS)) || '';
  if (upgradeUrlWithQS) {
    window.location.href = `${upgradeUrlWithQS}&isRedirectedFromPaymentPage=true`;
  }
};

export const isTicketUpgradePayment = () => {
  const cartTypeQsValue = canUseDOM() && parseQueryString('cart-type');
  const isTicketUpgradePage =
    cartTypeQsValue && cartTypeQsValue.toLowerCase() === UIConfig.parksTicketUpgrade.cartType ? true : false;
  const paymentError =
    canUseDOM() && parseQueryString(UIConfig.querStringParams.paymentErrorQuery.paymentError) ? true : false;
  if (isTicketUpgradeJourney() && paymentError && isTicketUpgradePage) {
    return backToUpgradePage();
  } else if (isTicketUpgradePage && isTicketUpgradeJourney()) {
    return true;
  }
  return false;
};

// Note: Ticket Upgrade - This journey affects the below two pages only (on other pages we must clear the cart if it's initiated with the ticket upgrade journey)
//       1. Ticket Upgarde Page
//       2. Payment Page
export const removeTicketUpgradeCart = () => {
  const paymentError =
    canUseDOM() && parseQueryString(UIConfig.querStringParams.paymentErrorQuery.paymentError) ? true : false;
  if (isTicketUpgradeJourney() && paymentError) {
    const redirectPaymentURL = `${window.location.pathname + window.location.search}&cart-type=${
      UIConfig.parksTicketUpgrade.cartType
    }${window.location.hash}`;
    window.history.replaceState(null, null, redirectPaymentURL);
  } else {
    removeAnonymousCart();
    localStorage.removeItem(UIConfig.parksTicketUpgrade.ticketUpgradeData);
    sessionStorage.removeItem(UIConfig.annualpassSessionCart);
    sessionStorage.removeItem(UIConfig.annualPassPurchaseCart);
    sessionStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.cartData);
    sessionStorage.removeItem(UIConfig.parksTicketUpgrade.upgradeUrlWithQS);
  }
};

export const isANPUpgradation = () => {
  const ticketUpgradeData = canUseDOM() && getLocalStorageByKey(UIConfig?.parksTicketUpgrade?.ticketUpgradeData);
  const upgradeProductType = ticketUpgradeData?.productItemType;
  if (upgradeProductType === UIConfig.parksTicketUpgrade.annualPassType) {
    return true;
  }
  return false;
};

export const isANPToANPUpgradation = () => {
  const ticketUpgradeData = canUseDOM() && getLocalStorageByKey(UIConfig?.parksTicketUpgrade?.ticketUpgradeData);
  const upgradableProductType = ticketUpgradeData?.fromProductItemType; // from product type
  const upgradeProductType = ticketUpgradeData?.productItemType; // to product type
  if (
    upgradeProductType === UIConfig.parksTicketUpgrade.annualPassType &&
    upgradableProductType === upgradeProductType
  ) {
    return true;
  }

  return false;
};

export const getAnonymousCart = () => {
  return canUseDOM() && localStorage.getItem('anonymousCart') && JSON.parse(localStorage.getItem('anonymousCart'));
};

export const setAnonymousCart = (cart) => {
  return canUseDOM() && localStorage.setItem('anonymousCart', JSON.stringify(cart));
};

export const removeAnonymousCart = () => {
  return canUseDOM() && localStorage.removeItem('anonymousCart');
};

export const getRecipientDetails = () => {
  return (
    canUseDOM() &&
    localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.recipientDetails) &&
    JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.recipientDetails))
  );
};

export const removeRecipientDetails = () => {
  return canUseDOM() && localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.recipientDetails);
};

// Annual pass function
export const isAnnualPassCase = (cartData, bypassLoginCase) => {
  if (!bypassLoginCase) {
    if (isLoggedInUser()) {
      return null;
    }
  }
  let { cart } = getAnonymousCart() !== null ? getAnonymousCart() : cartData !== undefined ? cartData : {};
  let productType =
    cart &&
    cart.items.map(function(currentVal, index, arr) {
      return currentVal.productType;
    });
  return cart && matchInArray('AnnualPass', productType);
};

export const getAnpGuestDetailsForTuJourney = () => {
  let guestDetails = [];
  const ticketUpgradePayload = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);
  if (ticketUpgradePayload?.productItemType === UIConfig.parksTicketUpgrade.annualPassType) {
    const annualpassPayload = getSessionStorage(UIConfig.annualPassPurchaseCart);
    const annualPassPurchaseCart = JSON.parse(annualpassPayload);
    guestDetails = annualPassPurchaseCart?.cart?.items?.[0]?.guestDetails;
  }

  return guestDetails;
};

export const getAnpTicketHoldersForTuJourney = () => {
  let ticketHolders = [];
  const guestDetails = getAnpGuestDetailsForTuJourney();
  if (guestDetails && guestDetails?.length > 0) {
    const anpTicketHolders = guestDetails?.forEach((guest) => {
      let data = {};
      data.name = `${guest.firstName} ${guest.lastName}`;
      data.email = guest.email;

      ticketHolders.push(data);
    });
  }

  return ticketHolders;
};

export const createAnnualPassData = ({ cartData }) => {
  let guestDetails = [];
  let ticketHolders = [];
  cartData?.cart?.items &&
    cartData.cart.items.forEach((item) => {
      item?.guestDetails &&
        item.guestDetails.forEach((guest) => {
          guestDetails.push(guest);
        });
    });
  if (guestDetails.length > 0) {
    guestDetails.forEach((guest) => {
      let data = {};
      data.name = `${guest.firstName} ${guest.lastName}`;
      data.email = guest.email;

      ticketHolders.push(data);
    });
  }
  return ticketHolders;
};

export const isF1SeatedTypeProduct = (ticket) => {
  const { eventType, seatType } = UIConfig.b2c.purchaseJourney.ymcMapping;
  if (
    ticket.eventType &&
    ticket.eventType.toLowerCase() === eventType.f1 &&
    ticket.seatType &&
    ticket.seatType.toLowerCase() === seatType.seat
  ) {
    return true;
  }
  return false;
};

export const isSeatedProduct = (product) => {
  const { seatType } = UIConfig.b2c.purchaseJourney.ymcMapping;
  //for loggedin user crossSellType is coming null
  if (product.hasOwnProperty('seatType') && (!product.hasOwnProperty('crossSellType') || !product.crossSellType))
    return product.seatType.toLowerCase() === seatType.seat; // && !isAddonProduct(product));

  return false;
};

export const isAddonProduct = (product, key = 'crossSellType') => {
  return product.hasOwnProperty(key) && product[key];
};

export const yaSortedNonSeatedProducts = (productList) => {
  const productObj = productList.reduce(
    (temp, currentProduct) => {
      if (isAddonProduct(currentProduct)) {
        temp.addOns.push(currentProduct);
      } else {
        temp.products.push(currentProduct);
      }

      return temp;
    },
    { products: [], addOns: [] },
  );

  return [...productObj.products, ...productObj.addOns];
};

export const isEventTypeProduct = (ticket) => {
  const { eventType } = UIConfig.b2c.purchaseJourney.ymcMapping;
  if (
    ticket.eventType &&
    (ticket.eventType.toLowerCase() === eventType.f1 || ticket.eventType.toLowerCase() === eventType.wrx)
  ) {
    return true;
  }
  return false;
};

export const isEventTypeF1InCart = (items = [], chekForSeated) => {
  const { eventType } = UIConfig.b2c.purchaseJourney.ymcMapping;
  const isF1Found = items.find((item) => {
    if (chekForSeated) {
      return isF1SeatedTypeProduct(item);
    }
    return item.eventType && item.eventType.toLowerCase() === eventType.f1;
  });
  return isF1Found ? true : false;
};

export const checkShippingDetails = (cartData) => {
  const isPickUpSelected = JSON.parse(localStorage.getItem('ymcPickUpOption'));
  if (
    cartData.items &&
    cartData.items.length > 0 &&
    isEventTypeF1InCart(cartData.items) &&
    showShippingBillingAddress(cartData.items) &&
    !isPickUpSelected
  ) {
    if (!cartData.shippingAddress) {
      window.PubSub.publish('f1_shipping_address_validation_Error');
      return true;
    }
    return false;
  }
};

export const isF1EventTypeProduct = (ticket) => {
  const { eventType } = UIConfig.b2c.purchaseJourney.ymcMapping;
  if (ticket.eventType && ticket.eventType.toLowerCase() === eventType.f1) {
    return true;
  }
  return false;
};

export const checkEventTypeProduct = (ticket, eventType) => {
  if (eventType && ticket.eventType && ticket.eventType.toLowerCase() === eventType) {
    return true;
  }
  return false;
};

export const getSeatInfo = (item) => {
  let seat = '',
    section = '',
    ticketInfo = '';
  item.tickets.forEach((ticket) => {
    if (ticket.Seat) {
      seat += `${ticket.Seat.rowLabel}${ticket.Seat.colLabel},`;
    }
  });
  if (item.tickets && item.tickets.length && item.tickets[0].Seat) {
    const seatData = item.tickets[0].Seat;
    if (seatData.section) {
      section += seatData.section;
    }
  }
  if (seat) {
    const seatInfo = seat.trim().substring(0, seat.length - 1);
    if (section) {
      ticketInfo = `${section} - ${seatInfo}`;
    } else {
      ticketInfo = seatInfo;
    }
  }
  return ticketInfo;
};

export const removeShippingBillingAddress = () => {
  const { shippingAddress, billingAddress } = UIConfig.b2c.purchaseJourney.ymcMapping.localStorageKey;
  if (canUseDOM()) {
    localStorage.removeItem(shippingAddress);
    localStorage.removeItem(billingAddress);
  }
};

// Returns Sorted Ticket by row and cols for YA
export const getSortedYaTickets = (ticketItems) => {
  const sortedItems = ticketItems.sort((seatOne, seatTwo) => {
    const rowOne = parseInt((seatOne.Seat || {}).row),
      rowTwo = parseInt((seatTwo.Seat || {}).row),
      colOne = parseInt((seatOne.Seat || {}).col),
      colTwo = parseInt((seatTwo.Seat || {}).col);

    return Number.isNaN(rowOne - rowTwo || colOne - colTwo) ? 0 : rowOne - rowTwo || colOne - colTwo;
  });

  return sortedItems;
};

export const getTouchPointsPurchaseInfo = () => {
  return getLocalStorageByKey(UIConfig.b2c.purchaseJourney.touchPoint.purchaseInfo);
};

export const getMoneyCardPurchaseInfo = () => {
  return getLocalStorageByKey(UIConfig.b2c.purchaseJourney.moneyCard.purchaseInfo);
};

export const getPointsPayPurchaseInfo = () => {
  return getLocalStorageByKey(UIConfig.paymentOption.pointsPay);
};

export const removeGuestUser = () => {
  let guestUser = JSON.parse(localStorage.getItem('guestUser'));
  if (checkAnonymousCart() && guestUser) {
    localStorage.setItem('guestUserEmail', JSON.stringify({ email: guestUser.email }));
    localStorage.removeItem('guestUser');
  } else {
    return;
  }
};

export const showShippingBillingAddress = (items = []) => {
  return items.some((item) => !+item.disableShipping);
};

export const showDeliveryOptions = (items = []) => {
  const itemsWithShippingEnabled =
    items && items.length > 0 ? items.filter((item) => !Boolean(Number(item.disableShipping))) : [];
  return itemsWithShippingEnabled && itemsWithShippingEnabled.length > 0
    ? itemsWithShippingEnabled.every((item) => Boolean(Number(item.enablePickup)))
    : false;
};

/**
 * generate unique productType for the product
 * @param {Object} prod product for which productType needs to be generated
 */
export const getProductType = (item) => {
  if (item.tab && item.tab.length && Array.isArray(item.tab)) {
    return item.tab[0];
  } else {
    return item.tab;
  }
};
