/*
 * InputFilters.js
 * This file contains code for Input Filters component.
 * @licensor  Miral
 */
import React from 'react';
import filter from './filter-composition';
import PropTypes from 'prop-types';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * InputFilters Class ( which extends the React.Component) creates Input Based Filter
 */
class InputFilters extends React.Component {
  /**
   * onChange function updates the value of filter in component's state on Change in Input field
   * @param    {[target : {value}]} function accepts the value of target element
   * @return   {[Void]} function does not return anything.
   */
  onChange = ({ target: { value } }) => {
    this.props.updateFilter(value, true);
  };

  render() {
    try {
      return <input onChange={this.onChange} placeholder={this.props.data.label} aria-label={this.props.data.label} />;
    } catch (err) {
      return logComponentRenderingError(err, 'InputFilters');
    }
  }
}

InputFilters.propTypes = {
  props: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string,
    }),
  }),
};
export default filter(InputFilters);
