import EventTracking from './event-tracking';
import { GLOBAL_VARS } from './global-vars';
import { resolvePath } from './utility';

export default class GoalAnalytics {
  constructor(goal, items, trackerContext, goalName) {
    this.goal = goal;
    this.itemsArray = items || [];
    this.trackerContext = trackerContext;
    this.goalName = goalName || '';
  }

  /*
   * Condition to check what type of search we need "AND" / "OR"
   */
  startSearch() {
    const category = this.checkSingleItem();
    const products = this.checkMultipleItems();

    if (this.goal.search.category && this.goal.search.products) {
      this.sendGoal(category && products);
    } else {
      this.sendGoal(category || products);
    }
  }

  /*
   * API call to CMS if goal is true
   */
  sendGoal(goalStatus = true) {
    if (goalStatus) {
      const regEx = /[^{]+(?=})/g;
      const goalId = this.goal.goalId && this.goal.goalId.toLowerCase().match(regEx)[0];
      if (resolvePath(this.goal, 'goalId', '') && resolvePath(this.goal, 'services.setGoal.pageUrl', '')) {
        const eventTracking = new EventTracking(
          GLOBAL_VARS.sitecoreApiKey,
          GLOBAL_VARS.dataFetcher,
          GLOBAL_VARS.scSite,
        );
        eventTracking.submitGoal(goalId, this.goal.services.setGoal.pageUrl, this.trackerContext, this.goalName);
      }
    }
  }

  /*
   * Check single category item and return
   */
  checkSingleItem() {
    if (this.goal.search.category) {
      return this.itemsArray.filter((item) => this.checkCategory(item)).length > 0;
    }
    return false;
  }

  /*
   * Check typeof fcategory, if string then check equality, else if array check indexOf
   */
  checkCategory(item) {
    if (Array.isArray(item[this.goal.search.category.key])) {
      return item[this.goal.search.category.key].indexOf(this.goal.search.category.value) >= 0;
    } else {
      return item[this.goal.search.category.key] === this.goal.search.category.value;
    }
  }

  /*
   * Get intersection of two arrays and return
   */
  checkMultipleItems() {
    if (this.goal.search.products) {
      const items = this.itemsArray.map((items) => items[this.goal.search.products.key]);
      return (
        items
          .filter((item) => this.goal.search.products.value.indexOf(item) !== -1)
          .filter((item, index, arr) => arr.indexOf(item) === index).length > 0
      );
    }
  }
}
