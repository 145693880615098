import React from 'react';
import PropTypes from 'prop-types';

import { AnchorLink, DynamicContent, Image } from '../base';
import { logComponentRenderingError } from '../../../common/logger';

import './mega-nav-accord.scss';
import { headerClickAnalytics } from '../../../common/analytics-events';

const hideMegaNavClass = 'closed';
const showMegaNavClass = 'open';

class MegaNavAccord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentContext: '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.panelToggle = this.panelToggle.bind(this);
    this.onLoad = true;
    this.navData = [...this.props.data.navLinks];
    this.activeSelectNavClassName = 'nav-selected';
  }

  componentDidUpdate() {
    if (!this.state.currentContext) {
      document.removeEventListener('click', this.panelToggle);
    }
  }

  /**
   * Add hide class to all created mega nav
   */
  panelToggle(e) {
    if (this.navContainer && !this.navContainer.contains(e.target)) {
      this.navData &&
        this.navData.forEach((item) => {
          const itemText = item.label;
          if (this[itemText]) {
            this[itemText].class = hideMegaNavClass;
          }
        });

      this.setState({
        currentContext: '',
      });
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.onLoad = false;
    const currentValue = e.target.parentElement.attributes['data-meganav'].value;
    this.navData &&
      this.navData.forEach((item) => {
        if (item) {
          const itemText = item.label;
          if (itemText === currentValue) {
            this[itemText] = item;
          }
          if (this[itemText]) {
            this[itemText].class = hideMegaNavClass;
          }
        }
      });
    if (this.state.currentContext !== currentValue) {
      this[currentValue].class = showMegaNavClass;

      this.setState({
        currentContext: currentValue,
      });

      document.addEventListener('click', this.panelToggle);
    } else {
      this.setState({
        currentContext: '',
      });

      document.removeEventListener('click', this.panelToggle);
    }
  };
  googleAnalyticHandleClick = (event, data) => {
    headerClickAnalytics(event, data);
  };

  render() {
    try {
      return (
        <div className="accordWrapper">
          {this.props.data.navLinks &&
            this.props.data.navLinks.map((item, idx) => (
              <div className={`accord ${this[item.label] ? this[item.label].class : 'closed'}`} key={`accord-${idx}`}>
                <div className="title" data-meganav={item.label}>
                  {(item.ctaDetails && (
                    <AnchorLink
                      className="list-title"
                      link={{
                        href: item.ctaDetails.href,
                        label: item.ctaDetails.label,
                      }}
                    />
                  )) ||
                    (item.label && (
                      <DynamicContent
                        tagName="span"
                        innerHtml={item.label}
                        attrs={{
                          className: 'body-3',
                        }}
                      />
                    ))}
                  <span className="toggle" onClick={this.handleClick}></span>
                </div>
                <div className="body">
                  <div className="meganav--wrapper-column accor">
                    <ul className="meganav--text-wrapper-list">
                      {item.linkData &&
                        item.linkData.map((linkitem, idxx) => (
                          <li className="meganav--text-wrapper-list-item" key={`meganav-${idxx}`}>
                            {linkitem.ctaDetail && (
                              <AnchorLink
                                link={{
                                  href: linkitem.ctaDetail.href,
                                  label: linkitem.ctaDetail.label,
                                  target: linkitem.ctaDetail.target,
                                }}
                                onClick={(e) => this.googleAnalyticHandleClick(e, linkitem.ctaDetail.label)}
                              />
                            )}
                            {linkitem.imageDetail && (
                              <div className="meganav--image-wrapper">
                                <Image image={linkitem.imageDetail} disableLazyLoad={true} />
                              </div>
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'MeganavAccord');
    }
  }
}

MegaNavAccord.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MegaNavAccord;
