import ApiWrapper from '../api-wrapper';
import { analyticsConfig } from './analytics-config';
import { Logging } from '../logger';

export class AnalyticsService {
  /**
   * Sends Search Events to Coveo Analytics
   * @param searchEvents Array of search Events to be logged
   * @returns {Promise}
   */
  static sendSearchEvents(searchEvents) {
    if (searchEvents.length > 0) {
      Logging(null, 'analytics-service', true, 'Logging analytics search events');
      return AnalyticsService.sendToService(searchEvents, 'searches')?.catch((e) => {
        Logging(e, 'analytics-service', true, 'Cove analytics search event failed');
      });
    }
  }

  /**
   * Sends Document View event to Analytics
   * @param documentViewEvent
   * @returns {Promise}
   */
  static sendDocumentViewEvent(documentViewEvent) {
    if (documentViewEvent) {
      Logging(null, 'analytics-service', true, 'Logging analytics document view');
      return AnalyticsService.sendToService(documentViewEvent, 'click').catch((e) => {
        Logging(e, 'analytics-service', true, 'Cove analytics click event failed');
      });
    }
  }

  /**
   * Sends Custom Events
   * @param customEvent
   * @returns {Promise}
   */
  static sendCustomEvent(customEvent) {
    if (customEvent) {
      Logging(null, 'analytics-service', true, 'Logging analytics custom event');
      return AnalyticsService.sendToService(customEvent, 'custom')?.catch((e) => {
        Logging(e, 'analytics-service', true, 'Cove analytics custom event failed');
      });
    }
  }

  /**
   * Method to make send Analytics data to Coveo
   * @param {Object}data
   * @param {String} path
   * @param {Object} `params` are the URL parameters to be sent with the request
   */
  static sendToService(data, path, params = {}) {
    if (!analyticsConfig.analyticsUrl) {
      return Promise.reject('No analytics url found');
    }
    const url = AnalyticsService.buildAnalyticsUrl(path);
    const visitorId = localStorage.getItem('visitorId');
    if (visitorId && visitorId !== 'null' && !params.visitor) {
      params.visitor = visitorId;
    }
    // We use pendingRequest because we don't want to have 2 request to analytics at the same time.
    // Otherwise the visitId won't be set correctly.

    if (!AnalyticsService.pendingRequest) {
      AnalyticsService.pendingRequest = ApiWrapper.coveoService({
        method: 'POST',
        params: params,
        data: data,
        url: url,
        moduleName: 'analytics-endpoints',
      })
        .then((res) => {
          if (res.data) {
            return AnalyticsService.handleAnalyticsEventResponse(res.data);
          }
        })
        .catch((ex) => {
          Logging(ex, 'analytics-service.js', false, 'Coveo analytics-endpoints Api failed');
        })
        .finally(() => {
          AnalyticsService.pendingRequest = null;
        });
    } else {
      return (
        AnalyticsService.pendingRequest
          // IE Edge `finally` block fix
          // https://github.com/quasarframework/quasar/issues/3427#issuecomment-468540385
          .catch(() => true)
          .then(() => {
            return AnalyticsService.sendToService(data, path, params);
          })
      );
    }
  }

  /**
   * Method for handling Response from Coveo Analytics
   * Used for storing Visitor ID in Cookie
   * @param response
   */
  static handleAnalyticsEventResponse(response) {
    let visitId;
    let visitorId;

    if (response['visitId']) {
      visitId = response['visitId'];
      visitorId = response['visitorId'];
    } else if (response['searchEventResponses']) {
      visitId = response['searchEventResponses'][0].visitId;
      visitorId = response['searchEventResponses'][0].visitorId;
    }
    if (visitId) {
      AnalyticsService.visitId = visitId;
    }
    if (visitorId && visitorId !== 'null') {
      try {
        localStorage.setItem('visitorId', visitorId);
      } catch (e) {
        Logging(e, 'analytics-service.js', false, 'localStorage is full');
      }
    }
    return response;
  }

  static buildAnalyticsUrl(path) {
    return `${analyticsConfig.analyticsUrl}${path}`;
  }
}
