import React, { Suspense, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import get from 'lodash/get';

import { DynamicContent, Overlay } from '../../presentation/base';
import {
  addThisHandler,
  canUseDOM,
  createMappedData,
  detectMobile,
  detectViewPort,
  flipObjectKeyValues,
  getErrorMap,
  getFallbackLanguage,
  getLocalStorageByKey,
  getLoggedInUser,
  getMainObject,
  getProdIdType,
  getUserAgent,
  hasHeaderComponent,
  isEmpty,
  isMatchTenant,
  onElementHeightChange,
  resolvePath,
} from '../../../common/utility';

import AddOnsTicketComponent from '../b2c-purchase-journey/add-ons/add-ons-ticket-component';
import { bookNowClickAnalytics } from '../../../common/analytics-events';
import CartActions from '../b2c-purchase-journey/cart/cart-actions';
import EditorialGridTooltip from '../../presentation/editorial-grid-tooltip';
import { getB2CProductDetails } from '../../../common/coveo-api.js';
import GTMData from '../b2c-purchase-journey/gtm-data';
import { logComponentRenderingError } from '../../../common/logger';
import { PerformanceService } from '../../../common/services';
import ProductStore from '../b2c-purchase-journey/purchase-journey-wrapper/product-store';
import { secProds } from '../b2c-purchase-journey/add-ons/add-ons-utility.js';
import UIConfig from '../../../common/UIConfig';
import {
  getDiscount,
  calculateToDate,
  getPerformanceId,
  getCategoryProds,
  updateSecProdArray,
  renderMediaCarouselWrapper,
  renderHeroBanner,
  renderHeroCard,
  renderPjPopupSegment,
  renderThirdPartyWidget,
  renderEditorialText,
  renderOverlayErrorMsg,
} from './helpers';

import './hero-component.scss';
import QuickLinks from '../../presentation/quick-links';
import PackageExperienceB2C from '../b2c-purchase-journey/add-ons/b2c-package-experience/b2c-package-experience-component';
import ApiWrapper from '../../../common/api-wrapper';

const StickyFooter = React.lazy(() => import('../sticky-footer'));

/**
 * Hero componet  will use HeroBanner,tooltip,editorial grid with tooltip,editorial text, textwithCTA component
 */
const HeroComponent = ({ data }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);
  const [errorOverlayObj, setErrorOverlayObj] = useState({});
  const [purchaseWrapperErrors, setPurchaseWrapperErrors] = useState('');
  const [errObj, setErrObj] = useState('');
  const [cartData, setCartData] = useState({});
  const [showPjPopupSegment, setShowPjPopupSegment] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPackageJourney, setIsPackageJourney] = useState(true);
  const isSmartHeroCarousel = data.enableSmartCarousel;

  const products = useRef({});
  const productId = useRef('');
  const pjData = useRef(null);
  const cartActions = useRef(null);
  const productList = useRef([]);
  const coveoDefaultParams = useRef({});
  const groupingNotRequired = useRef(false);
  const responseObj = useRef(null);
  const responseObjAll = useRef(null);
  const jssFormData = useRef({});
  const activePackageInfo = useRef(null);
  const activePackageDetails = useRef([]);

  const [corpSuccessPageData, setCorpSuccessPageData] = useState({});
  const [corpVerifiedPageData, setCorpVerifiedPageData] = useState({});

  if (canUseDOM()) {
    window.onload = () => {
      onElementHeightChange(document.body, () => {
        addThisHandler();
      });
    };
    window.onresize = () => {
      addThisHandler();
    };
  }

  useEffect(() => {
    const { enablePJPopup, productId, title, gaTitle, variant, fromPriceValue, priceValue, gaPrice } = data;
    setIsMobile(detectMobile());

    if (enablePJPopup) {
      window.PubSub.subscribe(UIConfig.events.PURCHASEJOURNEYDATA, (msg, data) => {
        pjData.current = data.purchaseJourneyData;

        cartActions.current = new CartActions({
          serviceUrl: pjData.current.services,
          moduleName: 'purchase-journey',
          tenantId: getLoggedInUser().tenantID,
        });

        setStateOfMinicart();
      });
      setShowPjPopupSegment(true);
    }
    GTMData.getTenantId() === UIConfig.ymcB2CTenant &&
      productId &&
      GTMData.push('productDetailsLoad', {
        productData: { id: productId, name: title, price: gaPrice || priceValue || fromPriceValue || '' },
      });

    if (
      GTMData.getTenantId() === UIConfig.yasArenaB2CTenant.toLocaleLowerCase() &&
      variant === UIConfig.heroWithAttributes
    ) {
      GTMData.push('productDetails', {
        products: [data],
      });
    }
    const findSection =
      window.dataLayer && window.dataLayer.find((data) => data.siteType === 'B2C' && data.primarySection);
    findSection &&
      !isEmpty(findSection) &&
      findSection.primarySection &&
      (findSection.primarySection === 'productdetails' ||
        findSection.primarySection === 'experiences' ||
        findSection.primarySection === 'events' ||
        findSection.primarySection === 'offers' ||
        findSection.primarySection === 'general-admission' ||
        findSection.primarySection === 'expeditions') &&
      productId &&
      GTMData.push('ticketDetailsLoad', {
        productData: { id: productId, name: gaTitle, price: gaPrice || priceValue || fromPriceValue || '' },
      });

    window.PubSub.subscribe('closeOverlay', closeOverlay);
    window.PubSub.subscribe(UIConfig.events.CORP_EMAIL_SUBMIT, onCorpEmailSubmit);
    jssFormData.current = getLocalStorageByKey(UIConfig.jssForms.events.jssFormSettingsData) || {};

    if (isCorpVerifiedUrl(window.location.href)) {
      onCorpEmailVerified();
    }

    setDomLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStateOfMinicart = () => {
    cartActions.current
      .getCartOnPageLoad()
      .then((res) => {
        if (res.data && res.data.cart) {
          productList.current = res.data.cart.items || [];
        }
      })
      .catch((res) => {
        productList.current = [];
        errorCallback('getCart', res.error);
      })
      // IE Edge `finally` block fix
      // https://github.com/quasarframework/quasar/issues/3427#issuecomment-468540385
      .catch(() => true)
      .then(() => {
        setCartData(cartActions.current.getCustomCart(productList.current || [], true));
        setErrObj(getErrorMap('getCart', {}, true, '', errObj));
      });
  };

  /**
   * error handling
   * @param {Object} err error object
   */
  const errorCallback = (serviceName, error) => {
    if (error) {
      if (serviceName === 'getCoveoProducts' && error.code.toString() === UIConfig.errorCodes.notFound) {
        const errObj = getErrorMap(
          serviceName,
          pjData.current.services[serviceName].errors,
          false,
          error,
          purchaseWrapperErrors,
        );
        setPurchaseWrapperErrors(errObj);
      } else {
        const errorObj = getErrorMap(serviceName, pjData.current.services[serviceName].errors, false, error, errObj);
        setErrObj(errorObj);
      }
    }
  };

  /**
   * change category key to String
   * @param {Object} err error object
   */
  const changeCategoryToString = (prods) => {
    const tabKey = coveoDefaultParams.current.coveoKeyMap['tab'];

    return prods.map((product) => {
      if (product[tabKey] && product[tabKey] instanceof Array) {
        product[tabKey] = product[tabKey].sort().join('');
      }
      return product;
    });
  };

  /**
   * Product data coveo call
   * @param {Object} err error object
   */
  const createProductStore = (tab, getActiveProduct) => {
    return new Promise((resolve, reject) => {
      if (!products.current[tab]) {
        const params = [];
        getActiveProduct
          ? params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.productId, value: productId.current },
            )
          : tab &&
            params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.tab, value: tab },
            );

        getB2CProductDetails({
          ...coveoDefaultParams.current,
          queryParams: params,
        })
          .then((res) => {
            res.results = changeCategoryToString(res.results);
            products.current[tab] = new ProductStore(res.results);
            setPurchaseWrapperErrors('');
            resolve(products.current[tab]);
          })
          .catch((res) => {
            errorCallback('getCoveoProducts', res.error);
            const errObj = getErrorMap(
              'getCoveoProducts',
              pjData.current.services.getCoveoProducts.errors,
              false,
              res.error,
            );
            reject(errObj);
          });
      } else {
        resolve(products.current[tab]);
      }
    });
  };

  const updateCouponCodeData = () => {
    const cartState = cartData.cart;
    if (cartState && cartState.hasOwnProperty('couponCode') && cartState.couponCode) {
      cartActions.current._storeExtraCartParams(cartState);
    }
  };

  const getDiscountedProducts = (products, days, discountMap, disableDiscount) => {
    discountMap = discountMap || pjData.current.discountMap;
    const baseProducts = [];
    const selectedProducts = [];
    const baseProductPrices = {};
    const baseDis = getDiscount(0, discountMap);
    const selectedDis = getDiscount(days, discountMap);

    products.forEach((prod) => {
      const prodCode = prod.pricing;
      prod.discount = {};
      prod.discount.actualPerc = 0;
      prod.discount.computed = 0;

      if (prodCode === baseDis.code) {
        prod.unitPrice = prod.gross || 0;
        baseProducts.push(prod);
        baseProductPrices[prod.classType] = prod.gross || 0;
      } else if (prodCode === selectedDis.code) {
        prod.discount.actualPerc = selectedDis.discountPer;
        selectedProducts.push(prod);
      }
    });

    if (baseDis.code === selectedDis.code || disableDiscount) {
      return baseProducts;
    } else {
      return selectedProducts.map((prod) => {
        prod.unitPrice = baseProductPrices[prod.classType];
        return prod;
      });
    }
  };

  /**
   * this function will add the keys to products which would be needed for cart and checkbasket
   * @param {Object} products array of the products to be updated
   * @param {Object} data selection made in tabs
   */
  const enrichProductData = (products) => {
    const result = products.map((prod) => {
      if (prod.fromDate) {
        prod.isFlexible = prod.isFlexible || prod.accessPolicy === '1EAD'; //added to identify flexible prod
        prod.toDate = calculateToDate(prod, data.mupValidityMap);
      } else {
        prod.fromDate = '';
        prod.toDate = '';
      }
      prod.productType =
        prod.hasOwnProperty('packageGroupCode') && prod.packageGroupCode !== null && typeof prod.tab !== 'string'
          ? prod.tab[0]
          : prod.tab; // added a tab category to find out prduct tab category
      prod.productIdType = getProdIdType(prod, groupingNotRequired.current);
      prod.price = {};
      prod.price.gross = prod.gross || 0;
      prod.price.net = prod.net || 0;
      prod.price.tax = prod.tax || 0;
      prod.discount = prod.discount || {}; //for checkbasket
      prod.discount.actualPerc = prod.discount.actualPerc || 0; //for checkbasket
      prod.discount.computed = prod.unitPrice ? prod.unitPrice - prod.gross : 0;
      prod.unitPrice = prod.unitPrice || prod.gross;

      delete prod['description']; // Delete description for CART API.

      return prod;
    });
    return result;
  };

  const addToCartCallback = (products, grouping, context, redirectToBookingPageCallback) => {
    // add required keys for cart and chekbasket
    groupingNotRequired.current = grouping;
    products = enrichProductData(products);
    const data = pjData.current;

    const perPromises = [];
    // extract the quntity from param and add it to respective product
    products.forEach((prod) => {
      const quantity = prod.quantity;
      let callPerformance = false;
      if (!prod.performanceId) {
        prod.performanceId = null; //for checkbasket
        callPerformance = prod.hasperformance === '1';
      }

      /* Reset performance id when hasperformance is 0 to fix price value */
      if (prod.hasperformance === '0') {
        prod.performanceId = null;
      }

      prod.guestDetails = prod.guestDetails || null; //for checkbasket
      prod.quantity = quantity;
      prod.currQuantity = quantity;
      prod.groupedQuantity = quantity;
      //adding null check for getPerformance service
      if (callPerformance && data.services.getPerformance) {
        const url = data.services.getPerformance.url.replace('{0}', prod.productId);
        perPromises.push(
          PerformanceService.getPerformanceData(
            url,
            prod.fromDate,
            prod.toDate,
            true,
            UIConfig.loader.defaultPreLoaderTarget,
          ),
        );
      }
    });

    Promise.all(perPromises)
      .then((responses) => {
        let errorObj = getErrorMap('getPerformance', {}, true);
        responses.forEach((res, index) => {
          products[index].performanceId = getPerformanceId(res.data.performancelist.performance, true);
          if (!products[index].performanceId) {
            res.error = {
              code: '7001',
              text: 'product is not sellable',
            };
            throw res;
          }
        });
        // save the data to cart

        let cartMaxQty;
        let prodMaxQty;

        if (data.miniCart) {
          cartMaxQty = data.miniCart.maxCartQuantity;
          prodMaxQty = data.miniCart.maxQuantity;
        } else {
          cartMaxQty = data.cartMaxQty;
        }

        const remQty = cartMaxQty - cartData.cart.totalQuantity;
        updateCouponCodeData();
        cartActions.current
          .updateCart(productList.current || [], products, prodMaxQty, remQty)
          .then((res) => {
            productList.current = res.productList;
            errorObj = getErrorMap('updateCart', {}, true);
            GTMData.push('addToCart', { products: products });

            setCartData(cartActions.current.getCustomCart(productList.current || [], true));
            setErrObj(errorObj);
            setIsOverlayOpen(false);
            window.PubSub.publish(UIConfig.events.MINICARTUPDATED, true);

            if (context && context.href) {
              window.location.href = context.href;
            }
            redirectToBookingPageCallback();
          })
          .catch((res) => {
            const err = res.error || res.response.error; //for different cutom responses
            errorCallback('updateCart', err);
            redirectToBookingPageCallback();
          });
      })
      .catch((res) => errorCallback('getPerformance', res.error));
  };
  const wishlistAnalyticsData = (event) => {
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const tenantId = mainObj.tenantID && mainObj.tenantID.toLowerCase();

    if (tenantId === UIConfig.ymcB2CTenant) {
      coveoDefaultParams.current = {
        perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
        coveoKeyMap: pjData.current.coveoMappingList,
        serviceUrl: pjData.current.services.getCoveoProducts.url,
        fieldsToInclude: Object.values(pjData.current.coveoMappingList),
        lang: getFallbackLanguage(),
      };
      productId.current = data.productId;
      createProductStore(productId, true).then((response) => {
        const activeProduct = response && createMappedData(response.products, pjData.current.coveoMappingList)[0];

        GTMData.push('addTowishListYmc', {
          products: activeProduct,
          eventName: event,
        });
      });
    }
  };

  const invokePackagedJourney = (packageCode) => {
    const { coveoSettings } = data;
    const datalayerItem = canUseDOM() && window.dataLayer && window.dataLayer[0];
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();
    const preLoaderTarget = UIConfig.loader.defaultPreLoaderTarget;
    const isPreLoader = true;
    const perPageResults = UIConfig.b2c.purchaseJourney.coveoResultsPerPage;
    const { packageKeyMap, coveoMappingList } = pjData.current;
    const fieldsToInclude = Object.values(packageKeyMap);
    const lang = getFallbackLanguage();
    const searchTemplate = 'Package';
    const productResults = {};
    const pkgInfo = {
      pkgCode: data.packageCode || 'PCK2',
      pkgTitle: data.title || '',
    };
    activePackageInfo.current = pkgInfo;

    if (!packageCode) {
      return;
    }

    setIsPackageJourney(true);
    ApiWrapper.coveoService({
      preLoaderTarget,
      url: 'https://platform.cloud.coveo.com/rest/search/',
      method: 'POST',
      preLoader: isPreLoader,
      data: {
        numberOfResults: perPageResults,
        fieldsToInclude: fieldsToInclude,
        aq: `(@${coveoMappingList.language}==${lang})(@${coveoMappingList.templatename}==${searchTemplate})(@${
          packageKeyMap.packageCode
        }==${data.packageCode || 'PCK2'})`,
        searchHub: mainObj.additionalProperty.searchHub,
      },
    }).then((coveoData) => {
      productResults.results = [];
      productResults.totalCount = coveoData.data.totalCount;

      coveoData.data.results.forEach((v) => {
        productResults.results.push(v.raw);
      });

      const packageDetails = Object.entries(packageKeyMap).reduce((acc, [key, value]) => {
        if (productResults.results.length && productResults.results[0].hasOwnProperty(value)) {
          return { ...acc, [key]: productResults.results[0][value] };
        }
        return acc;
      }, {});

      activePackageDetails.current = packageDetails;

      setIsOverlayOpen(true);
      setErrorOverlayObj({});
    });
  };

  const renderPackageJourney = () => {
    const { enablePJPopup, variant } = data;

    coveoDefaultParams.current = data.enablePJPopup
      ? {
          perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.current.coveoMappingList,
          serviceUrl: pjData.current.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.current.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};

    return window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      customClass: 'addOns-Overlay package-journey-overlay calendar-overlay-genral-admission',
      dataToAppend: (
        <PackageExperienceB2C
          data={data}
          packageDetails={{ ...activePackageInfo.current, ...activePackageDetails.current }}
          createProductStore={createProductStore}
          addToCartCallback={addToCartCallback}
          getDiscountedProducts={getDiscountedProducts}
          getCategoryProds={getCategoryProds}
          changeCategoryToString={changeCategoryToString}
          errorCallback={errorCallback}
          getErrorMap={getErrorMap}
          closeOverlay={closeOverlay}
          pjData={pjData.current}
          services={pjData.current.services}
          enablePJPopup={enablePJPopup}
          coveoSettings={{ serviceUrl: 'https://platform.cloud.coveo.com/rest/search/' }}
          coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
          overlayErrorCallback={overlayErrorCallback}
          variant={
            (variant === 'v-product-list-filter' || variant === 'v-f1-multi-filter') && enablePJPopup
              ? 'v-overlay-selected'
              : ''
          }
        />
      ),
    });
  };

  const renderPjPopup = (id, itemData = {}) => {
    const datalayerItem = canUseDOM() && window.dataLayer && window.dataLayer[0];
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const tenantId = mainObj.tenantID && mainObj.tenantID.toLowerCase();
    productId.current = id;

    if (!pjData.current) {
      return;
    }

    coveoDefaultParams.current = data.enablePJPopup
      ? {
          perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.current.coveoMappingList,
          serviceUrl: pjData.current.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.current.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};

    data.enablePJPopup &&
      createProductStore(productId.current, true).then((response) => {
        responseObj.current = response;
        const activeProduct = response && createMappedData(response.products, pjData.current.coveoMappingList)[0];
        const currentTabData =
          pjData.current.tabs &&
          pjData.current.tabs.filter(
            (tab) =>
              tab.coveoValue.toLowerCase() === (activeProduct && activeProduct.tab && activeProduct.tab.toLowerCase()),
          )[0];

        createProductStore(currentTabData.coveoValue, false).then((response) => {
          responseObjAll.current = response;
          setIsOverlayOpen(true);
          setErrorOverlayObj({});
        });
      });

    if (datalayerItem && tenantId === UIConfig.ymcB2CTenant) {
      bookNowClickAnalytics(data.title, itemData);
    }
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const onCorpEmailSubmit = () => {
    // Fetch data from JSS setting and fill all the data in state
    const corpSuccessData = {};
    const successMessage = resolvePath(jssFormData, 'current.data.successMessage', '');
    const successTitle = resolvePath(jssFormData, 'current.data.successMessage.title.bodyCopy', '');
    const corpEmail = getLocalStorageByKey(UIConfig.localStoreKeys.CORPORATE_EMAIL)?.corpEmail || '';

    corpSuccessData['subTitle'] = successTitle;
    corpSuccessData['bodyCopy2'] = successMessage;
    corpSuccessData['underlinedText'] = corpEmail;
    corpSuccessData['extraClass'] = 'email-success-container';
    corpSuccessData['bodyCopy'] = '';
    corpSuccessData['extraClass'] = 'success-email-container';

    setCorpSuccessPageData(corpSuccessData);
  };

  const isCorpVerifiedUrl = (url) => {
    return url?.includes(UIConfig.CORP_VERIFY_URL_IDENTIFIER) ? true : false;
  };

  const getCorpEmailIdFromUrl = (url) => {
    if (!isCorpVerifiedUrl(url)) return '';

    const corpUrl = url?.split('?');
    const emailUrl = corpUrl?.[1];
    const emailIdStr = emailUrl?.split('=');

    const rawEmailid = emailIdStr?.[1];
    const emailid = rawEmailid.includes('%22') ? rawEmailid?.replaceAll('%22', '') : rawEmailid;

    return emailid;
  };

  const onCorpEmailVerified = () => {
    // Fetch data from JSS setting and fill all the data in state
    const corpVerifiedData = {};
    const bookNowButtonData = resolvePath(jssFormData, 'current.data.enrollmentSuccessMessage.primaryCTA', '');
    const successTitle = resolvePath(jssFormData, 'current.data.enrollmentSuccessMessage.title', '');

    const corpEmail = getCorpEmailIdFromUrl(window.location.href) || '';

    corpVerifiedData['subTitle'] = successTitle;
    corpVerifiedData['primaryCTA'] = bookNowButtonData;
    corpVerifiedData['underlinedText'] = corpEmail;
    corpVerifiedData['extraClass'] = 'verified-email-container';
    corpVerifiedData['showSuccessIcon'] = true;

    setCorpVerifiedPageData(corpVerifiedData);
  };

  const renderPJPopupData = () => {
    const activeProduct =
      responseObj.current && createMappedData(responseObj.current.products, pjData.current.coveoMappingList)[0];

    const currentTabData =
      pjData.current.tabs &&
      pjData.current.tabs.filter(
        (tab) => tab.coveoValue.toLowerCase() === (activeProduct.tab && activeProduct.tab.toLowerCase()),
      )[0];

    let counterData = null;
    let dateSelector = null;
    let timeSlotSelector = null;
    let catgry = null;
    let categry = null;
    let productOverlaySelector = null;

    const category = { [currentTabData.coveoKey]: currentTabData.coveoValue };
    const resProducts = responseObjAll.current.getFilteredProductsFromKeyVal(category);
    const allProducts = resProducts.length && createMappedData(resProducts, pjData.current.coveoMappingList);
    const categoryProducts = allProducts.filter(
      (prod) => prod.category && prod.category[0] === activeProduct.category[0],
    );

    for (let tktType in currentTabData.controls) {
      let currentBaseProdOptions =
        currentTabData.controls[tktType].options &&
        currentTabData.controls[tktType].options.find((opt) => opt.coveoValue === activeProduct.category.toString());

      if (currentBaseProdOptions) {
        catgry = currentBaseProdOptions;
        counterData = currentBaseProdOptions.visitorSelector;
        dateSelector = currentBaseProdOptions.dateSelector;
        timeSlotSelector = currentBaseProdOptions.timeSlotSelector;
        productOverlaySelector = currentBaseProdOptions.productOverlaySelector;
      }
      categry = currentBaseProdOptions;
    }

    if (!products.current.hasOwnProperty('quantity')) {
      products.current.quantity =
        (counterData &&
          counterData.visitorSelector &&
          counterData.visitorSelector.options &&
          counterData.visitorSelector.options[0] &&
          counterData.visitorSelector.options[0].defaultQuantity) ||
        0;
    }

    const additionalProds = getCategoryProds(activeProduct.category, allProducts);

    // Reset Secondary products
    Object.keys(secProds).forEach((key) => delete secProds[key]);

    return categoryProducts.map((product, index) => {
      const multiTicketSelector = categry.multiTicketSelector,
        keyMap = multiTicketSelector ? multiTicketSelector.stringMapping : {},
        category = product.category;

      let isCategoryMappingExist = false;
      if (keyMap) {
        for (let item in keyMap) {
          if (item === category[0]) {
            isCategoryMappingExist = true;
            break;
          }
        }
      }

      if (
        keyMap &&
        (category.indexOf(keyMap.drivingExperience) !== -1 ||
          category.indexOf(keyMap.passengerExperience) !== -1 ||
          isCategoryMappingExist)
      ) {
        updateSecProdArray(product, category[0], keyMap);
      }

      if (!product.hasOwnProperty('quantity')) {
        product.quantity = categry.visitorSelector.options[0].defaultQuantity;
      }

      return (
        <div>
          {allProducts && additionalProds && (!multiTicketSelector || product.experienceCatgory === keyMap.primary) && (
            <AddOnsTicketComponent
              data={currentTabData}
              cartData={pjData.current.miniCart}
              businessErrors={pjData.current.miniCart.businessErrors}
              ticket={product}
              dateSelector={dateSelector}
              visitorCounter={counterData}
              createMappedData={createMappedData}
              addToCartCallback={addToCartCallback}
              services={pjData.current.services}
              getDiscountedProducts={getDiscountedProducts}
              timeSlotSelector={timeSlotSelector}
              category={catgry}
              handleOnClick={() => {}}
              additionalProds={additionalProds}
              enablePJPopup={data.enablePJPopup}
              searchProductList={allProducts}
              coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
              productOverlaySelector={productOverlaySelector}
              overlayErrorCallback={overlayErrorCallback}
              variant={data.enablePJPopup ? 'v-overlay-selected' : ''}
            />
          )}
        </div>
      );
    });
  };

  const overlayErrorCallback = (error, errorData) => {
    let errorObj = {};
    if (!isEmpty(error) && error.errorcode) {
      errorObj.errorcode = error.errorcode;
      if (errorData && errorData[error.errorcode]) {
        errorObj.text = errorData[error.errorcode];
      } else if (error && error.errordescription) {
        errorObj.text = error.errordescription;
      }
      setErrorOverlayObj(errorObj);
      setIsOverlayOpen(false);
    }
  };

  try {
    const mainObj = getMainObject();
    const contrastValue = mainObj && mainObj.applyTextShadow;
    const userAgent = getUserAgent() || !hasHeaderComponent();
    const isDisableMore = data.variant && data.variant.includes('disable-more') ? true : false;
    const isPackageJrny = isMatchTenant(UIConfig.tenants.ymc) && data?.packageCode && data?.packageCode !== '';

    return (
      <>
        {data?.mediaItems ? (
          <Helmet>
            {data?.mediaItems[0]
              ? data?.mediaItems[0]?.videoDetail?.videoInfo?.videoUrl && (
                  <link
                    rel="preload"
                    as="video"
                    href={data?.mediaItems[0]?.videoDetail?.videoInfo?.videoUrl}
                    type="video/mp4"
                  />
                )
              : data?.mediaItems[0]?.imageInfo?.mobileImage?.src && (
                  <link
                    rel="preload"
                    as="image"
                    href={data?.mediaItems[0]?.imageInfo?.mobileImage?.src}
                    imagesrcset={data?.mediaItems[0]?.imageInfo?.mobileImage?.src}
                    imagesizes="50vw"
                  />
                )}
          </Helmet>
        ) : (
          <Helmet>
            {data?.image?.mobileImage?.src && (
              <link
                rel="preload"
                as="image"
                href={data?.image?.mobileImage?.src}
                imagesrcset={data?.image?.mobileImage?.src}
                imagesizes="50vw"
              />
            )}
          </Helmet>
        )}
        <div
          data-c-name="hero-component"
          data-c-render="universal"
          className={classNames(
            'component c-hero-component',
            data.textAlignment,
            data.textTheme,
            data.variant,
            { 'v-gradient-filter': !!data.applyGradient },
            { 'fix-hero-height': !!(data.cards && data.cards.length) },
            { 'hero-card': !!(data.heroCards && data.heroCards.length) },
            { 'smart-hero-carousel-view': isSmartHeroCarousel },
            { 'dom-rendering': !domLoaded },
          )}
        >
          {data.applyGradient && (
            <DynamicContent
              tagName="style"
              innerHtml={`
            .component.c-hero-component.v-gradient-filter .hero-image picture::after {
              background-image: ${data.mobileGradient};
            }
            @media only screen and (min-width: 768px){
              .component.c-hero-component.v-gradient-filter .hero-image picture::after {
                background-image: ${data.gradient};
              }
            }
          `}
            />
          )}
          {contrastValue && (
            <DynamicContent
              tagName="style"
              innerHtml={`
            .c-hero-component .hero-banner-shortTitle {
              text-shadow: 0 2px 4px rgba(0,0,0,.4);
            }
            .c-hero-component .hero-banner-title {
              text-shadow: 0 2px 4px rgba(0,0,0,.4);
            }
            .c-hero-component .hero-banner-desc {
              text-shadow: 0 2px 4px rgba(0,0,0,.4);
            }
          `}
            />
          )}

          {isSmartHeroCarousel || (data.videoInfo && data.videoInfo.videoUrl)
            ? renderMediaCarouselWrapper(data, 'heroComponent', isSmartHeroCarousel)
            : renderHeroBanner(data, isMobile)}
          {isMobile && <QuickLinks quickLinks={data.quickLinks} />}
          {data.isHomeCardswithText && renderEditorialText(data, 'heroComponent')}
          {data.heroCards && data.heroCards.length > 0 && (
            <div className="hero-panel-with-card component hero-panel-contain-card">
              {renderHeroCard(data.heroCards)}
            </div>
          )}
          <div className="additional-widget-container">
            {data.enablePJPopup &&
              renderPjPopupSegment(
                data,
                isOverlayOpen,
                isPackageJrny ? invokePackagedJourney : renderPjPopup,
                isPackageJrny ? renderPackageJourney : renderPJPopupData,
                showPjPopupSegment,
              )}
            {data.thirdPartyWidget &&
              data.thirdPartyWidget.length > 0 &&
              renderThirdPartyWidget(
                data.bodyCopy,
                data.image,
                data.isLoginWishlist,
                data.thirdPartyWidget,
                data.title,
                wishlistAnalyticsData,
              )}
          </div>
          {data.cards && data.cards.length > 0 && (
            <EditorialGridTooltip cards={data.cards} disableMore={isDisableMore} />
          )}
          {!data.isHomeCardswithText &&
            renderEditorialText({ ...data, ...corpSuccessPageData, ...corpVerifiedPageData }, 'heroComponent')}
          {renderOverlayErrorMsg(errorOverlayObj)}
          {detectViewPort() === UIConfig.viewportTypes.mobile && isMatchTenant(UIConfig.tenants.ymc) && (
            <Suspense fallback={<></>}>
              <StickyFooter>
                <div className="btn-subscribe btn-primary sticky sticky-footer">
                  {data.enablePJPopup &&
                    renderPjPopupSegment(
                      data,
                      isOverlayOpen,
                      isPackageJrny ? invokePackagedJourney : renderPjPopup,
                      isPackageJrny ? renderPackageJourney : renderPJPopupData,
                      showPjPopupSegment,
                    )}
                </div>
              </StickyFooter>
            </Suspense>
          )}

          {userAgent && <Overlay />}
        </div>
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HeroComponent', data.variant);
  }
};

export default HeroComponent;

HeroComponent.propTypes = {
  data: PropTypes.shape({
    actionLink: PropTypes.string,
    addToCalendar: PropTypes.string,
    applyGradient: PropTypes.bool,
    aspectRatio: PropTypes.string,
    backgroundColor: PropTypes.string,
    bodyCopy: PropTypes.string,
    bodyTitle: PropTypes.string,
    bookNowLabel: PropTypes.string,
    cards: PropTypes.array,
    currentItemId: PropTypes.string,
    date: PropTypes.string,
    daysLabel: PropTypes.string,
    descriptionDetail: PropTypes.string,
    disableReadMore: PropTypes.bool,
    downloadPDF: PropTypes.string,
    enablePJPopup: PropTypes.bool,
    eventBookingUrl: PropTypes.string,
    fromPriceLabel: PropTypes.string,
    fromPriceValue: PropTypes.string,
    gradient: PropTypes.string,
    heroCards: PropTypes.string,
    heroCta: PropTypes.string,
    image: PropTypes.object,
    imageInfo: PropTypes.object,
    isLoginWishlist: PropTypes.bool,
    isLimited: PropTypes.bool,
    isProductSoldOut: PropTypes.bool,
    limitedLabel: PropTypes.string,
    mobileGradient: PropTypes.string,
    popUp: PropTypes.object,
    priceLabel: PropTypes.string,
    priceValue: PropTypes.string,
    productId: PropTypes.string,
    readMore: PropTypes.string,
    secondaryBuyLabel: PropTypes.string,
    secondaryProductId: PropTypes.string,
    services: PropTypes.object,
    shortTitle: PropTypes.string,
    shortTitleHeading: PropTypes.string,
    soldoutText: PropTypes.string,
    textAlignment: PropTypes.string,
    textTheme: PropTypes.string,
    textWidth: PropTypes.number,
    thirdPartyWidget: PropTypes.array,
    ticketBookingSourceId: PropTypes.string,
    title: PropTypes.string,
    titleDescription: PropTypes.string,
    variant: PropTypes.string,
    videoDescription: PropTypes.string,
    videoInfo: PropTypes.object,
    videoTitle: PropTypes.string,
  }),
};
