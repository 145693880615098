import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ApiWrapper from '../../../common/api-wrapper';
import {
  checkTenant,
  canUseDOM,
  getLoggedInUser,
  getLoginUser,
  getMainObject,
  isAddonProduct,
  isF1SeatedTypeProduct,
  isLoggedInUser,
  isYasArenaJourney,
  openIAMOverlay,
  resolvePath,
  isMatchTenant,
  getAnonymousCart,
  parseQueryString,
  getCookie,
  checkIfParks,
  toLowerCase,
  isMobileOrTab,
} from '../../../common/utility';
import { clearLocalStorage, logoutCurrentUser } from '../../../common/session/session-utility';
import { logComponentRenderingError } from '../../../common/logger';
import { Logging } from '../../../common/logger';
import UIConfig from '../../../common/UIConfig';
import CartActions from '../../container/b2c-purchase-journey/cart/cart-actions';
import { AnchorLink, AutoSuggest, DynamicContent, SvgSprite } from '../base';
import IAMPopup from '../iam-popup/iam-popup';
import LanguageSwitcher from '../language-switcher';
import CurrencySwitcher from '../currency-switcher';
import ProfileTooltip from '../tooltip/tooltip-component';
import { GLOBAL_VARS } from '../../../common/global-vars';
import { bookYourHolidayClickAnalytics } from '../../../common/analytics-events';
import './header-bottom-component.scss';
import GTMData from '../../container/b2c-purchase-journey/gtm-data';
import className from 'classnames';
// import PurchaseJourney from '../../container/b2c-purchase-journey/purchase-journey-wrapper/index';

import HeaderMiniCart from './header-mini-cart';
import OpeningHours from '../../container/opening-hours';
import CartCount from '../../container/header/CartCount';
import classNames from 'classnames';

let previousFocussedElement = null;
let cartActions = null;

const GTMDataOnClickCTA = (data) => {
  if (isMatchTenant(UIConfig.tenants.fwad) || isMatchTenant(UIConfig.tenants.yi)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
      name: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.name) : data.name,
      [UIConfig.ga4Constants.ELEMENTTEXT]: isMatchTenant(UIConfig.tenants.yi)
        ? toLowerCase(data.elementText)
        : data.elementText,
      category: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.category) : data.category,
    });
  }
};

/**
 * Header bottom is part of header in which lang switcher and other links like cart search will be
 * displayed.
 * @param    {body} Object as defined from data-contract.
 * @param    {type} String define which type of body will be displayed profile/menu or desktop
 * @param    {logged} Boolean find out wheather user logged in or not
 * @param    {isMobile} Boolean find out wheather device is mobile or desktop
 * @return   {HeaderBottom} markup that containes header bottom
 * @param    {localStorageUserInfo} Object as defined to read localeStorage for the User Name for the defect YPS-5911
 */
const HeaderBottom = (props) => {
  const searchSettings = resolvePath(props, 'searchSettings', {});
  const [hideSearchIcon, setHideSearchIcon] = useState(false);
  const [cartTotalQuantity, setCartTotalQuantity] = useState(0);
  const [profileDisabled, setProfileDisabled] = useState(true);
  const [enableYasIDVersion, setEnableYasIDVersion] = useState(false);
  const [expressLogin, setExpressLogin] = useState(false);

  const sendDataToParent = props.sendDataToParent || false;
  const searchVariant = props.searchVariant || '';
  const isSwadHeaderVariant = props.variant === UIConfig.swadVariant.headerVariant;
  const isYIB2C = props.variant === UIConfig.commonVariant.headerVariant;
  const childToParent = (childdata) => {
    isYIB2C && props.sendToParent(childdata);
  };
  const [miniCartObj, setMiniCartObj] = useState('');

  const [hideCart, setHideCart] = useState(false);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const cartReference = useRef();

  useEffect(() => {
    window.PubSub.subscribe(UIConfig.events.EXPRESS_CHECKOUT_PP_SIGNUP, (msg, expressLogin) => {
      setExpressLogin(true);
    });
    window.PubSub.subscribe('myCartMockObject', (msg, data) => {
      setMiniCartObj(data);
    });

    logoutOnMissingStorage();
    setProfileDisabled(false);

    if (
      props.variant === 'v-header-b2c' ||
      props.variant === 'v-ya-header' ||
      props.variant === 'v-header-multimeganav' ||
      props.variant === 'v-header-swad'
    ) {
      subscribeCart();
      updateCartIcon();
    }

    window.PubSub.subscribe(
      (isYIB2C || isSwadHeaderVariant) && searchVariant === UIConfig.commonVariant.autoSuggestSearchVariant
        ? 'searchCloseOverlay'
        : 'closeOverlay',
      () => {
        if ((isYIB2C || isSwadHeaderVariant) && searchVariant === UIConfig.commonVariant.autoSuggestSearchVariant) {
          sendDataToParent(false);
        }
        setHideSearchIcon(false);
        previousFocussedElement && previousFocussedElement.focus();
      },
    );

    const profileIconEl = document.querySelectorAll('.c-header-top-icon.profile')[1];
    profileIconEl && profileIconEl.classList.remove('link-disable');

    const enableYasIDVersion = resolvePath(getMainObject(), 'additionalProperty.enableYasIDVersion', 'false');
    setEnableYasIDVersion(enableYasIDVersion);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cartReference &&
        cartReference.current &&
        !cartReference.current.contains(event.target) &&
        event.target &&
        event.target.id !== 'header-cart' &&
        event.target.className !== 'total-quantity' &&
        !event.target.classList.contains('loading')
      ) {
        setHideCart(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mouseup', handleClickOutside);
  }, [cartReference]);

  const logoutOnMissingStorage = () => {
    if (canUseDOM() && props.body.hasOwnProperty('logoutServiceUrls')) {
      const isLoggedIn = props.body.logoutServiceUrls && props.body.logoutServiceUrls.isLogin;
      var loggedInUser = getLoginUser();
      const logoutLoopCount = localStorage.getItem('logoutLoopCount') || 0;
      //check user is logged in and loggedInUser property is missing from local storage
      if (isLoggedIn && !Object.keys(loggedInUser).length && Number(logoutLoopCount) <= 2) {
        //making api call to clear .NET session
        ApiWrapper.platformService({
          url: props.body.logoutServiceUrls && props.body.logoutServiceUrls.cmsLogoutURL,
          method: 'POST',
          moduleName: 'Logout - No local storage found',
        }).then((response) => {
          Logging(response, response.config && (response.config.moduleName || ''), true, response.config.reason);
          clearLocalStorage();

          const logoutLogData = {
            reason: 'Local storage missing',
            idleTimeOutButtonText:
              (props.body.logoutServiceUrls && props.body.logoutServiceUrls.idleTimeOutButtonText) || 'OK',
            idleTimeOutMessage:
              (props.body.logoutServiceUrls && props.body.logoutServiceUrls.idleTimeOutMessage) ||
              'Your session has been expired, Please log-in again.',
          };
          localStorage.logoutLog = JSON.stringify(logoutLogData);
          localStorage.logoutLoopCount = Number(logoutLoopCount) + 1;

          //redirect to azure logout url
          window.location.href = props.body.logoutServiceUrls && props.body.logoutServiceUrls.azureLogoutUrl;
        });
      }
    }
  };

  const keyPressCallBack = (e) => {
    if (e.type === 'keydown' && e.which === 13) {
      toggleOverlayState(e);
    }
  };

  const subscribeCart = () => {
    cartActions = new CartActions({
      serviceUrl: props.body.services,
      moduleName: 'header',
      tenantId: getLoggedInUser().tenantID,
    });

    if (canUseDOM()) {
      window.PubSub.subscribe(UIConfig.events.UPDATE_HEADER_CART, updateCartIcon);
    }
  };

  const updateCartIcon = (msg, data) => {
    if (data) {
      setCartTotalQuantity(data.totalQuantity);
      props.setNewUpdatedCart(data);
    } else {
      cartActions &&
        cartActions
          .getCartOnPageLoad()
          .then((res) => {
            setCartTotalQuantity(parseInt(res.data.cart.totalQuantity));
            props.setNewUpdatedCart(res.data.cart);
            const f1SeatedProduct =
              res.data.cart &&
              res.data.cart.items &&
              res.data.cart.items.find((item) => {
                return isF1SeatedTypeProduct(item);
              });

            GTMData.push('cartItems', {
              products: resolvePath(res.data.cart, 'items', []),
            });
            if (parseQueryString('status') === 'false' || parseQueryString('payment_error')) {
              GTMData.push('cartItems', {
                products: resolvePath(res.data.cart, 'items', []),
                otherEvent: UIConfig.failedTransaction,
              });
            }

            if (isYasArenaJourney()) {
              const yaProduct =
                res.data.cart && res.data.cart.items && res.data.cart.items.find((item) => !isAddonProduct(item));

              if (yaProduct) {
                props.scrollToAddons();
                window.PubSub.publish('startCartTimer', {
                  startDate: yaProduct.timeStamp,
                });
              }
            }

            if (getLoggedInUser().tenantID && getLoggedInUser().tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
              if (f1SeatedProduct) {
                window.PubSub.publish('startCartTimer', {
                  startDate: f1SeatedProduct.timeStamp,
                });
              } else {
                window.PubSub.publish('stopCartTimer');
              }
            }
          })
          .catch((res) => {
            if (getLoggedInUser().tenantID && getLoggedInUser().tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
              window.PubSub.publish('stopCartTimer');
            }
          });
    }
  };

  /**
   * searchToggleOverlayState function publishes the searchToggleOverlayState event along with the required data.
   * @param    {Anything} data [Data passed by the publisher of the event].
   * @return   {Void} function does not return anything.
   */
  const toggleOverlayState = (e) => {
    e.preventDefault();
    previousFocussedElement = document.activeElement;
    window.PubSub.publish(
      (isYIB2C || isSwadHeaderVariant) && searchVariant === UIConfig.commonVariant.autoSuggestSearchVariant
        ? 'searchToggleOverlayState'
        : 'toggleOverlayState',
      {
        shouldOpen: true,
        dataToAppend:
          isYIB2C || isSwadHeaderVariant ? (
            <AutoSuggest
              data={searchSettings}
              childToParent={searchVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? childToParent : ''}
            />
          ) : (
            <AutoSuggest data={searchSettings} />
          ),
        customClass: `nav-item-search ${
          (isYIB2C || isSwadHeaderVariant || props.variant === UIConfig.commonVariant.multiMegaNavHeaderVariant) &&
          !props.body.subscribeCta
            ? 'nav-item-search-alignment'
            : ''
        }`,
        variant:
          props.variant === UIConfig.commonVariant.b2cHeaderVariant ||
          props.variant === UIConfig.commonVariant.yasArenaHeaderVariant ||
          isYIB2C ||
          props.variant === UIConfig.commonVariant.swadVariant ||
          props.variant === UIConfig.commonVariant.multiMegaNavHeaderVariant
            ? UIConfig.commonVariant.b2cSearchVariant
            : '',
      },
    );
    if ((isYIB2C || isSwadHeaderVariant) && searchVariant === UIConfig.commonVariant.autoSuggestSearchVariant) {
      sendDataToParent(true);
    }
    setHideSearchIcon(true);
  };

  const logoutUser = (e) => {
    e.preventDefault();
    logoutCurrentUser();
  };

  const renderRegisterBtn = (body, logged) => {
    return (
      body.registerCta &&
      !logged && (
        <div className="btn btn-secondary header-bottom-btn-register">
          <AnchorLink link={{ ...body.registerCta }} />
        </div>
      )
    );
  };

  const renderLoginBtn = (body, logged) => {
    return body.loginCta && !logged ? (
      <div className="btn btn-secondary header-bottom-btn-login">
        <AnchorLink link={body.loginCta} />
      </div>
    ) : null;
  };

  const renderLogoutBtn = (body, type, logged) => {
    return (body.logoutCta ? body.logoutCta : (body.logoutCta = UIConfig.logoutCta)) && type === 'profile' && logged ? (
      <div className="header-bottom-btn-logout">
        <AnchorLink link={{ ...body.logoutCta, attrs: { onClick: logoutUser } }} tabIndex="0" />
      </div>
    ) : null;
  };

  const renderBookTicketBtn = (body, type, logged) => {
    return body.bookTicketCta && type !== 'profile' && logged ? (
      <div className="btn btn-secondary heading-7">
        <AnchorLink link={body.bookTicketCta} />
      </div>
    ) : null;
  };

  const renderSearchBox = (body, logged, isMobile, variant) => {
    const wbwLogged =
      logged || variant === 'wbw' || resolvePath(searchSettings, 'additionalProperty.anonymousAccess', '');
    return body.search &&
      (variant === UIConfig.commonVariant.headerVariant ||
        variant === UIConfig.swadVariant.headerVariant ||
        !isMobile) &&
      wbwLogged ? (
      <a
        href="/#"
        role="button"
        className={hideSearchIcon ? 'c-header-top-icon search hide-visibility' : 'c-header-top-icon search'}
        onKeyDown={keyPressCallBack}
        onClick={toggleOverlayState}
        aria-label={resolvePath(body, 'searchSettings.searchAriaLabel', '')}
      >
        <SvgSprite id="icn-search-white" cssClass="search-icon" />
      </a>
    ) : null;
  };
  {
    /* added localStorageUserInfo to get user details in profile dropdown */
  }
  const headerInfo = ({ body, logged, isMobile, variant, localStorageUserInfo }) => {
    return (
      <>
        <div className="header-body-information">
          {body?.dailyTiming && (
            <span className="header-body-timing" {...(isSwad && { tabIndex: '0' })}>
              {body.dailyTiming}
            </span>
          )}
          {body?.openingHours && (
            <OpeningHours
              data={body.openingHours}
              apiUrl={body.services}
              type="light"
              isSwad={isSwad}
              transparent={body.headerVariant === UIConfig.commonVariant.transparentVariant}
            />
          )}
          <span className={classNames('header-body-chat', { displayNone: !body.whatsAppUrl })}>
            {body.whatsAppUrl && (
              <a
                aria-label="Whatsapp"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="c-header-top-icon swad-whatsapp-icon"
                role="button"
                href={body.whatsAppUrl}
              >
                {body.whatsAppText}
              </a>
            )}
          </span>
          {body.searchSettings && body.searchSettings.coveoKeyMap && renderSearchBox(body, logged, isMobile, variant)}
          {/* added profile tooltip variant for seaworld */}

          {!isSwad
            ? !isMobile && renderCart(body, variant === 'v-header-swad' && !isMobile ? true : logged)
            : !isMobile &&
              miniCartObj &&
              miniCartObj.isHeaderMiniCartEnabled &&
              renderCart(body, variant === 'v-header-swad' && !isMobile ? true : logged)}

          {renderProfileTooltipVariant(body, isMobile, localStorageUserInfo, logged)}
          {renderLanguageSwitcher(body)}
        </div>
      </>
    );
  };

  const renderCart = (body, type, logged, isMobile) => {
    const checkCartCta = body.cart && body.cartCta;
    const { newUpdatedCart } = props;
    const showCartWithProfileIcon =
      (type !== 'profile' || body.variant === UIConfig.commonVariant.multiMegaNavHeaderVariant) &&
      body.variant != 'v-header-swad';
    const isMiniCartData = props.miniCartData || miniCartObj;
    if (isMiniCartData?.isHeaderMiniCartEnabled) {
      return checkCartCta && showCartWithProfileIcon && logged && isMobile ? (
        <a
          href={body.cartCta.href}
          role="button"
          className="c-header-top-icon cart"
          aria-label={(body.cartCta.basketAriaLabel || '') + cartTotalQuantity}
        >
          <SvgSprite id="icn-cart" cssClass="cart-icon" />
          <span className="sr-only">Link to Cart</span>
          {cartTotalQuantity ? <p className="total-quantity"> {cartTotalQuantity} </p> : null}
        </a>
      ) : (
        <div className={className('c-header-top-icon-wrap', { active: hideCart })}>
          <button
            className="c-header-top-icon cart"
            id="header-cart"
            onClick={(e) => {
              e.stopPropagation();
              document.body.classList.toggle('minicart-expanded');
              if (isMiniCartData?.isHeaderMiniCartEnabledOnBookingToPaymentPages) {
                window.PubSub.publish(UIConfig.headerCartClick, { isClicked: true });
              } else {
                window.PubSub.publish('miniCartToggle', { isOpen: !hideCart });
                setHideCart(!hideCart);
                GTMData.push('viewCart', newUpdatedCart?.items, true);
              }
            }}
            tabIndex="0"
            aria-label={isSwad && (body.cartCta?.basketAriaLabel || '') + cartTotalQuantity}
          >
            <SvgSprite id="icn-cart" cssClass="cart-icon" />
            {parseInt(cartTotalQuantity) ? <p className="total-quantity"> {cartTotalQuantity} </p> : null}
          </button>
          {/* {HeaderMiniCartTesting()} */}
          {isMiniCartData.isHeaderMiniCartEnabled && !isMiniCartData?.isHeaderMiniCartEnabledOnBookingToPaymentPages && (
            <div
              ref={cartReference}
              className={`cart-dropdown-wrap ${parseInt(cartTotalQuantity) ? 'hasOrder' : 'noOrder'} ${
                hideCart ? 'showCart' : ''
              }`}
              role="button"
              aria-label={isSwad && (body.cartCta?.basketAriaLabel || '') + cartTotalQuantity}
              tabIndex="0"
            >
              <div className="header-miniCart">
                {/* {miniCartObj.data && <PurchaseJourney data={miniCartObj.data} isMiniCart={true} />} */}
                {isMiniCartData.isHeaderMiniCartEnabled && hideCart && (
                  <HeaderMiniCart data={isMiniCartData} updatedCart={newUpdatedCart} />
                )}
              </div>
            </div>
          )}
        </div>
      );
    } else if (checkCartCta && showCartWithProfileIcon && logged) {
      return <CartCount data={body} variant={body.variant} />;
    }

    return <></>;
  };

  const renderProfileTooltip = (body, isMobile) => {
    return body.profile && !isMobile ? (
      <ProfileTooltip
        wrapperClass="header-bottom-profile"
        buttonClass="c-header-top-icon profile"
        buttonInnerHtml={<SvgSprite id="icn-user" />}
      >
        <div className="header-bottom-profile-tooltip">
          <DynamicContent
            tagName="div"
            innerHtml={body.profile.bodyCopy + '<br />' + body.profile?.firstName}
            attrs={{ className: 'header-bottom-profile-name' }}
          />
          <ul>
            {body.profile.ctaLinks &&
              body.profile.ctaLinks.map((link, i) => {
                return (
                  <li key={i}>
                    <AnchorLink link={{ ...link }} />
                  </li>
                );
              })}
          </ul>
          {body.logoutCta && (
            <div className="header-bottom-btn-logout">
              <AnchorLink link={{ ...body.logoutCta, attrs: { onClick: logoutUser } }} tabIndex="0" />
            </div>
          )}
        </div>
      </ProfileTooltip>
    ) : null;
  };

  const renderProfileTooltipVariant = (body, isMobile, localStorageUserInfo, logged) => {
    if (expressLogin) {
      localStorageUserInfo = getLoggedInUser();
      logged = true;
    }

    const isProfile = localStorageUserInfo && localStorageUserInfo.userName;
    // isProfile and logged Condition and Added Variant for yasisland tenants
    const condition =
      body.variant === UIConfig.commonVariant.headerVariant || body.variant === UIConfig.swadVariant.headerVariant
        ? isProfile && logged
        : isProfile && logged && !isMobile;
    return condition && body.profile ? (
      <ProfileTooltip
        wrapperClass={`header-bottom-profile ${isMatchTenant(UIConfig.tenants.fwad) ? 'fwad-profile' : ''}`}
        buttonClass="c-header-top-icon profile logged-in"
        isDefaultOpen={body.variant === UIConfig.swadVariant.headerVariant ? false : true}
        buttonInnerHtml={
          <span>
            <SvgSprite id="icn-user" />
            <SvgSprite id="logged-in-icon" />
          </span>
        }
        tabIndex="0"
        refName="profileToolTip"
        ariaLabelToolTip={props.body.ariaLabelToolTipLogin}
        isFwad={checkIfParks()}
      >
        <div className="header-bottom-profile-tooltip">
          {localStorageUserInfo.firstName && (
            <DynamicContent
              tagName="div"
              innerHtml={body.profile.bodyCopy + ' ' + localStorageUserInfo.firstName}
              attrs={{ className: 'header-bottom-profile-name' }}
            />
          )}
          <ul>
            {(body.profile.ctaLinks[0].label === UIConfig.loginCta.label
              ? UIConfig.profile.ctaLinks
              : body.profile.ctaLinks
            ).map((link, i) => (
              <li key={i}>
                {(body.variant === UIConfig.commonVariant.headerVariant ||
                  body.variant === UIConfig.swadVariant.headerVariant) &&
                  link.icon && <img src={link.icon} alt={link.label} />}
                <AnchorLink link={link} tabIndex="0" />
              </li>
            ))}
          </ul>
          {(body.logoutCta ? body.logoutCta : (body.logoutCta = UIConfig.logoutCta)) && (
            <div className="header-bottom-btn-logout">
              {(body.variant === UIConfig.commonVariant.headerVariant ||
                body.variant === UIConfig.swadVariant.headerVariant) &&
                body.logoutCta.icon && <img src={body.logoutCta.icon} alt={body.logoutCta.label} />}
              <AnchorLink link={{ ...body.logoutCta, href: '#', attrs: { onClick: logoutUser } }} tabIndex="0" />
            </div>
          )}
        </div>
      </ProfileTooltip>
    ) : !isMobile && !body.isLoginDisabled ? (
      <div className="header-bottom-profile">
        {enableYasIDVersion === 'true' ? (
          <button
            aria-label={body.ariaLabelLoggedOut}
            className="c-header-top-icon profile"
            disabled={profileDisabled}
            onClick={() =>
              resolvePath(body, 'loginCta.href')
                ? openIAMOverlay(<IAMPopup loginURL={body.loginCta.href} />)
                : props.unAvailableLoginCta()
            }
          >
            <SvgSprite id="icn-user" />
          </button>
        ) : (
          <a
            aria-label={body.ariaLabelLoggedOut}
            role="button"
            className="c-header-top-icon profile link-disable"
            href={resolvePath(body, 'loginCta.href') ? body.loginCta.href : null}
            onClick={() => (resolvePath(body, 'loginCta.href') ? null : props.unAvailableLoginCta())}
          >
            <SvgSprite id="icn-user" />
          </a>
        )}
      </div>
    ) : // Added for mobile (Without Logged-in)
    (body.variant === UIConfig.commonVariant.headerVariant || body.variant === UIConfig.swadVariant.headerVariant) &&
      !body.isLoginDisabled ? (
      enableYasIDVersion === 'true' &&
      isMobile && (
        <button
          aria-label={body.ariaLabelLoggedOut}
          className="c-header-top-icon profile mobile-added"
          disabled={profileDisabled}
          onClick={() =>
            resolvePath(body, 'loginCta.href')
              ? openIAMOverlay(<IAMPopup loginURL={body.loginCta.href} />)
              : props.unAvailableLoginCta()
          }
        >
          <SvgSprite id="icn-user" />
        </button>
      )
    ) : null;
  };

  const renderLanguageSwitcher = (body, type) => {
    return type !== 'profile' && body.language && body.language.languages ? (
      <div className="header-bottom-lang-switcher">
        <LanguageSwitcher data={body.language} />
      </div>
    ) : null;
  };
  const renderCurrencySwitcher = (body, type) => {
    return type !== 'profile' && body.currency && body.isShowCurrencyToggle === true && body.currency.currencies ? (
      <div className="header-bottom-currency-switcher">
        <CurrencySwitcher data={body.currency} />
      </div>
    ) : null;
  };

  const renderBookTicketBtnWihoutLogged = (body) => {
    return (body.variant === UIConfig.commonVariant.headerVariant ||
      // body.variant === UIConfig.swadVariant.headerVariant ||
      body.variant === UIConfig.commonVariant.multiMegaNavHeaderVariant) &&
      body.bookTicketCta ? (
      <div className="header-bottom-book-ticket">
        <AnchorLink
          link={{
            label: body.bookTicketCta.label,
            href: body.bookTicketCta.href,
          }}
        />
      </div>
    ) : null;
  };

  const handleBookYourHoliday = () => {
    const datalayerItem = canUseDOM() && window.dataLayer && window.dataLayer[0];
    /* Fire book your holiday event for all the pages */
    if (datalayerItem) {
      bookYourHolidayClickAnalytics();
    }
  };

  const renderSubscribeBtn = (body, type, isEASecondaryCTA) => {
    return (
      type !== 'profile' &&
      body.subscribeCta && (
        <div
          className={`btn-subscribe ${isEASecondaryCTA ? 'btn-primary-last' : 'btn-primary'} reverse
            ${body.subscribeCta.class === 'v-multicolor-cta' ? 'v-multicolor-cta' : ''}
            ${body.subscribeCta.class === 'v-festive-header-cta' ? 'v-festive-header-cta' : ''}`}
        >
          <AnchorLink
            link={{ ...body.subscribeCta }}
            onClick={() => {
              GTMDataOnClickCTA({
                name: `Header_Button - ${body.subscribeCta?.label}`,
                [UIConfig.ga4Constants.ELEMENTTEXT]: body.subscribeCta?.label,
                category: UIConfig.ga4Constants.PRIMARY_CTA,
              });
              handleBookYourHoliday();
            }}
          />
        </div>
      )
    );
  };

  const renderCTABtn = (secondaryCta) => {
    return (
      <div className="btn-cta-golden reverse">
        <AnchorLink link={{ ...secondaryCta }} />
      </div>
    );
  };

  const checkLoginPopup = (cta) => {
    if (!isLoggedInUser()) {
      openIAMOverlay(<IAMPopup loginURL={resolvePath(props, 'body.modifyBookingCta.url')} />);
    } else {
      window.location.href = cta.href;
    }
  };

  const renderTextLink = (body) => {
    return body.variant === UIConfig.commonVariant.headerVariant ||
      body.variant === UIConfig.swadVariant.headerVariant ||
      body.variant === UIConfig.commonVariant.multiMegaNavHeaderVariant ? (
      <div className="header-bottom-secondary-nav">
        {body.modifyBookingCta && (
          <span className="header-bottom-secondary-nav-links" key={`secondary-nav-link-redirection`}>
            <span className={'modify-booking'} tabIndex="0" onClick={() => checkLoginPopup(body.modifyBookingCta)}>
              {resolvePath(body, 'modifyBookingCta.label')}
            </span>
          </span>
        )}
        {body.secondaryNavData.map((listItem, ix) => (
          <span className="header-bottom-secondary-nav-links" key={`secondary-nav-link-${ix}`}>
            <AnchorLink
              link={{
                label: listItem.navInfo.itemText,
                href: listItem.navInfo.navUrl,
              }}
            />
          </span>
        ))}
      </div>
    ) : null;
  };

  const ManageBookingNavTab = ({ href, title, src }) => {
    return (
      <div className="manage-booking-nav-item-container">
        <a href={href} className="nav-item-link">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className="nav-item-icon"
          >
            <g clip-path="url(#clip0_2101_32)">
              <path
                d="M11.5769 15.75C11.5769 15.6731 11.5769 15.5769 11.5769 15.5C11.5769 15.4231 11.5769 15.3269 11.5769 15.25L11.3462 15.1154H2.09615C1.55769 15.1154 1.11538 14.6731 1.11538 14.1346V7.23077H14.8077V10.1538C14.9808 10.0962 15.1731 10.0577 15.3654 10.0577C15.5577 10.0577 15.75 10.0962 15.9231 10.1538V4.25C15.9231 3.09615 14.9808 2.15385 13.8269 2.15385H11.6731V0.557692C11.6731 0.25 11.4231 0 11.1154 0C10.8077 0 10.5577 0.25 10.5577 0.557692V2.15385H5.36539V0.557692C5.36539 0.25 5.11539 0 4.80769 0C4.5 0 4.25 0.25 4.25 0.557692V2.15385H2.09615C0.942308 2.15385 0 3.09615 0 4.25V14.1346C0 15.2885 0.942308 16.2308 2.09615 16.2308H10.8846C11 16.1154 11.1154 16.0192 11.2692 15.9231L11.5769 15.75ZM1.11538 4.25C1.11538 3.71154 1.55769 3.26923 2.09615 3.26923H4.25V4.03846C4.25 4.34615 4.5 4.59615 4.80769 4.59615C5.11539 4.59615 5.36539 4.34615 5.36539 4.03846V3.26923H10.5385V4.03846C10.5385 4.34615 10.7885 4.59615 11.0962 4.59615C11.4038 4.59615 11.6538 4.34615 11.6538 4.03846V3.26923H13.8077C14.3462 3.26923 14.7885 3.71154 14.7885 4.25V6.11538H1.11538V4.25Z"
                fill=""
              />
              <path
                d="M15.3072 16.2121C15.7002 16.2121 16.0188 15.8936 16.0188 15.5006C16.0188 15.1076 15.7002 14.7891 15.3072 14.7891C14.9143 14.7891 14.5957 15.1076 14.5957 15.5006C14.5957 15.8936 14.9143 16.2121 15.3072 16.2121Z"
                fill=""
              />
              <path
                d="M18.9039 16.8851L17.9039 16.3081C17.9808 16.0581 18.0385 15.7697 18.0385 15.4812C18.0385 15.1928 18 14.9235 17.9039 14.6543L18.9039 14.0774C19.1731 13.9235 19.2693 13.5774 19.1154 13.3081C18.9616 13.0389 18.6154 12.9428 18.3462 13.0966L17.3462 13.6735C16.9808 13.2697 16.4808 12.962 15.9231 12.8466V11.712C15.9231 11.4043 15.6731 11.1543 15.3654 11.1543C15.0577 11.1543 14.8077 11.4428 14.8077 11.7505V12.8851C14.25 13.0005 13.75 13.3081 13.3846 13.712L12.3846 13.1351C12.1154 12.9812 11.7693 13.0774 11.6154 13.3466C11.4616 13.6158 11.5577 13.962 11.827 14.1158L12.827 14.6928C12.75 14.9428 12.6923 15.2312 12.6923 15.5197C12.6923 15.8081 12.7308 16.0774 12.827 16.3466L11.827 16.9235C11.5577 17.0774 11.4616 17.4235 11.6154 17.6928C11.7116 17.8658 11.9039 17.9812 12.0962 17.9812C12.1923 17.9812 12.2885 17.962 12.3654 17.9043L13.3654 17.3274C13.7308 17.7312 14.2308 18.0389 14.7885 18.1543V19.2889C14.7885 19.5966 15.0385 19.8466 15.3462 19.8466C15.6539 19.8466 15.9039 19.5966 15.9039 19.2889V18.1543C16.4616 18.0389 16.9616 17.7312 17.327 17.3274L18.327 17.9043C18.4231 17.962 18.5193 17.9812 18.5962 17.9812C18.7885 17.9812 18.9808 17.8851 19.077 17.6928C19.25 17.3851 19.1539 17.0389 18.9039 16.8851ZM15.3654 17.0581C14.5 17.0581 13.8077 16.3658 13.8077 15.5005C13.8077 14.6351 14.5 13.9428 15.3654 13.9428C16.2308 13.9428 16.9231 14.6351 16.9231 15.5005C16.9231 16.3658 16.2308 17.0581 15.3654 17.0581Z"
                fill=""
              />
            </g>
            <defs>
              <clipPath id="clip0_2101_32">
                <rect width="19.2308" height="20" fill="" />
              </clipPath>
            </defs>
          </svg>
          <span className="nav-item-text">{title}</span>
        </a>
      </div>
    );
  };

  try {
    const body = props.body;
    const isMobile = props.isMobile;
    const type = props.type;
    const logged = props.logged;
    const variant = props.variant;
    const languageSelectorOnTopMenu = props.body.languageSelectorOnTopMenu;
    const localStorageUserInfo = props.localStorageUserInfo;
    const loadActionButtons = props.loadActionButtons;
    const headerVariant = props.body.headerVariant;
    const { isParks } = props;

    // EP-755 - add Travel box related button, only for EA
    const isEtihadArena = resolvePath(GLOBAL_VARS, 'pageDetails.tenantName', '') === UIConfig.iamMapping.etihadarena;
    const isEASecondaryCTA = isEtihadArena && resolvePath(body, 'registerCta.label', null);
    {
      isSwadHeaderVariant
        ? headerInfo({ body, logged, isMobile, variant, localStorageUserInfo })
        : renderSearchBox(body, logged, isMobile, variant);
    }

    return (
      <div className={'header-bottom' + (logged ? ' header-bottom-logged' : '')} id="header-lang-selector">
        {body.variant !== UIConfig.commonVariant.headerVariant &&
          body.variant !== UIConfig.swadVariant.headerVariant &&
          renderTextLink(body)}
        {loadActionButtons && !isEASecondaryCTA && renderRegisterBtn(body, logged)}
        {loadActionButtons && variant === 'v-header-view' && renderLoginBtn(body, logged)}
        {renderLogoutBtn(body, type, logged)}
        {!isSwadHeaderVariant && loadActionButtons && renderBookTicketBtn(body, type, logged)}
        {!isSwadHeaderVariant && isParks && !isMobile && body?.openingHours && !hideSearchIcon && (
          <OpeningHours data={body.openingHours} apiUrl={body.services} />
        )}
        {isYIB2C && !isMobileOrTab() && (
          <ManageBookingNavTab
            href={body?.modifyBookingCta?.href}
            title={body?.modifyBookingCta?.title}
            src={body?.modifyBookingIcon?.src}
          />
        )}
        {/* added localStorageUserInfo parameter to headerInfo to access user details  */}
        {isSwadHeaderVariant
          ? headerInfo({ body, logged, isMobile, variant, localStorageUserInfo })
          : renderSearchBox(body, logged, isMobile, variant)}

        {(variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant) &&
          body.mapLink != null &&
          (!isMobile ? (
            <div className="header-bottom-location">
              <a
                href={body?.mapLink?.href}
                role="button"
                aria-label={body?.mapLink?.label ?? 'header-location'}
                className="c-header-top-icon location"
                tabIndex="0"
                target="_blank"
              >
                {''}
              </a>
            </div>
          ) : (
            <a
              href={body?.mapLink?.href}
              role="button"
              aria-label={body?.mapLink?.label ?? 'header-location'}
              className="c-header-top-icon location"
              tabIndex="0"
              target="_blank"
            >
              {''}
            </a>
          ))}
        {variant != 'v-header-swad' &&
          renderCart(
            body,
            type,
            variant === UIConfig.commonVariant.b2cHeaderVariant ||
              variant === UIConfig.commonVariant.yasArenaHeaderVariant ||
              variant === UIConfig.commonVariant.headerVariant ||
              variant === UIConfig.swadVariant.headerVariant ||
              variant === UIConfig.commonVariant.multiMegaNavHeaderVariant
              ? true
              : logged,
          )}
        {variant === 'v-header-view' && renderProfileTooltip(body, isMobile)}
        {(variant === UIConfig.commonVariant.b2cHeaderVariant ||
          variant === UIConfig.commonVariant.yasArenaHeaderVariant ||
          variant === UIConfig.commonVariant.headerVariant ||
          variant === UIConfig.commonVariant.multiMegaNavHeaderVariant) &&
          renderProfileTooltipVariant(body, isMobile, localStorageUserInfo, logged)}
        {((variant !== UIConfig.commonVariant.headerVariant && variant !== UIConfig.swadVariant.headerVariant) ||
          variant === UIConfig.commonVariant.multiMegaNavHeaderVariant) &&
        body.isWishListEnabled ? (
          <div className={`header-bottom-wishlist ${props.wishlistActive ? 'active' : ''}`}>
            <a
              role="button"
              aria-label={body.wishListLink.label}
              className="wishlist"
              href={body.wishListLink.href}
              tabIndex="0"
            >
              {''}
            </a>
          </div>
        ) : null}

        {/* Currency functionality is remaining for Currency Dropdown end*/}
        {variant === UIConfig.commonVariant.headerVariant && !isMobile && <>{renderCurrencySwitcher(body, type)}</>}
        {variant === UIConfig.commonVariant.headerVariant && isMobile && (
          <span id="lang-cur-wrapper">{renderLanguageSwitcher(body, type)}</span>
        )}
        {variant === UIConfig.commonVariant.headerVariant && !isMobile && <>{renderLanguageSwitcher(body, type)}</>}
        {variant !== UIConfig.commonVariant.headerVariant &&
          variant !== UIConfig.swadVariant.headerVariant &&
          renderLanguageSwitcher(body, type)}
        {isEASecondaryCTA && renderCTABtn(body.registerCta)}
        {variant !== UIConfig.commonVariant.headerVariant &&
          variant !== UIConfig.swadVariant.headerVariant &&
          renderSubscribeBtn(body, type, isEASecondaryCTA)}
        {variant === 'v-header-swad' && renderBookTicketBtnWihoutLogged(body)}

        {/* Only for Listing Header variant */}
        {(variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant) &&
          (headerVariant === UIConfig.commonVariant.headerYASTenantVariant.listingHeaderVariant ||
            headerVariant === UIConfig.commonVariant.headerYASTenantVariant.checkoutHeaderVariant) && (
            <>
              {languageSelectorOnTopMenu && (
                <span className="listing-profile">
                  {renderProfileTooltipVariant(body, isMobile, localStorageUserInfo, logged)}
                </span>
              )}
            </>
          )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HeaderBottom');
  }
};
HeaderBottom.propTypes = {
  body: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  logged: PropTypes.bool,
  type: PropTypes.string,
  localStorageUserInfo: PropTypes.object.isRequired,
  miniCart: PropTypes.object,
};

HeaderBottom.defaultProps = {
  ariaLabelToolTipLogin: 'Press enter to open profile',
};

export default HeaderBottom;
