/*
 * renew-token.js
 * This file contains code to renew id token
 * @author    Manoj Singh, SapientNitro <asachan@sapient.com>
 * @licensor  Miral
 */
import {
  canUseDOM,
  getLoggedInUser,
  querystring,
  getRenewTokenConfig,
  setCookie,
  getBaseURL,
  getLocalStorageByKey,
  setLocalStorage,
  getCookie,
  formatUserData,
  getYasIdGTMData,
  encryptParam,
  isMatchTenant,
  isLoggedInUser,
  shuffleObject,
} from '../utility';
import { setPayloads } from './session-utility';
import ApiWrapper from '../api-wrapper';
import { Logging } from '../logger';
import UIConfig from '../UIConfig';

const timeRemaining = (expireTime, timeout) => {
  const expireDate = new Date(expireTime - timeout),
    currentDate = Date.now(),
    dateDiff = expireDate.getTime() - currentDate;

  return dateDiff;
};

const setRenewToken = (response, userData, renewConfig) => {
  userData = userData ? userData : {};
  userData.idToken = response.data.id_token ? response.data.id_token : '';
  if (response.data.id_token) {
    setPayloads(response.data.id_token, userData, renewConfig);
  }
  //converting in minutes since setCookie method accepts time in minutes
  const expiryTimeInMinutes = response.data && response.data.expires_in / 60;

  setCookie('idToken', response.data.id_token, expiryTimeInMinutes, getBaseURL());
  setCookie('UserProfile', JSON.stringify(formatUserData(userData)), '', getBaseURL());

  let updatedMainObj = getLocalStorageByKey('mainObj');
  setLocalStorage('refreshToken', response.data.refresh_token);
  updatedMainObj.loginInfo = renewConfig;
  setLocalStorage('mainObj', JSON.stringify(updatedMainObj));
};

const getQueryData = (renewConfig, authorizationCode) => {
  const grantType = authorizationCode ? renewConfig.getRefreshTokenGrantType : renewConfig.renewIdTokenGrantType;
  const tokenType = authorizationCode
    ? {
        code: authorizationCode,
      }
    : {
        refresh_token: localStorage.getItem('refreshToken') || '',
      };

  let qData = querystring({
    grant_type: grantType,
    client_id: renewConfig.loginClientId,
    scope: renewConfig.loginScope,
    redirect_uri: renewConfig.loginRedirectUri,
    ...tokenType,
  });

  qData = encodeURIComponent(qData);

  return qData;
};

const loadValidMembershipData = (membership) => {
  const resetMembershipData = {
    cardNumber: null,
    couponCode: [''],
    discount: '',
    email: '',
    expiryDate: '',
    mypassId: '',
    partnerCode: '',
    segmentType: '',
    status: '',
  };

  if (membership && membership.data && membership.data.length) {
    //For Fazza or HAW user
    if (membership.data[0].segmentType !== UIConfig.CORPORATE) {
      return membership.data[0];
    }
    if (membership.data[0].segmentType === UIConfig.CORPORATE) {
      setCookie(
        'isCorpUserEnable',
        membership.data[0].status === UIConfig.profile.CORP_USER_TYPE.ENABLED,
        '',
        getBaseURL(),
      );
    }

    //For corporate user with enable status
    if (membership.data[0].status === UIConfig.profile.CORP_USER_TYPE.ENABLED) {
      return membership.data[0];
    } else {
      //User having disabled or deleted status
      return (membership.data && membership.data[1]) || resetMembershipData;
    }
  }
};
const getLeisureFacilityShortCode = (tenantId) => {
  if (!tenantId || !tenantId.length) return '';

  const mappedLeisureFacilty = shuffleObject(UIConfig.tenants);
  return mappedLeisureFacilty[tenantId.toLowerCase()] || '';
};

export const fetchMembershipDetails = (userData) => {
  const url = userData?.getMembershipUrl?.replace('{MypassID}', getYasIdGTMData());
  const leisureShortCode = getLeisureFacilityShortCode(getLoggedInUser().tenantID);
  const leisureFacility = getLoggedInUser().tenantName || leisureShortCode.toUpperCase();

  return ApiWrapper.apiGateway({
    method: 'GET',
    url: url?.replace('{tenantName}', leisureFacility),
    // url: url?.replace('{tenantName}', 'FWAD'),
    headers: {
      'Content-Type': 'application/json',
    },
    moduleName: 'Membership Details',
  })
    .then((response) => {
      if (response && response.data.is_success) {
        // ON SWAD WE DO HAVE ONLY CORP USER
        if (leisureFacility === UIConfig.coveoMapping.swad && response.data?.response_object?.data?.length) {
          response.data.response_object.data = response.data.response_object.data.filter(
            (item) => item.segmentType === UIConfig.CORPORATE,
          );
        }
        const membershipData =
          response.data.response_object &&
          response.data.response_object.data &&
          response.data.response_object.data.length > 0 &&
          loadValidMembershipData(response.data.response_object);

        const MembershipDetailsFromSCV = {
          membershipData: membershipData,
          membershipDetails:
            (response.data.response_object &&
              response.data.response_object.data.length > 0 &&
              response.data.response_object.data) ||
            [],
          isMembershipDetails: true,
        };
        localStorage.setItem('membershipDetails', encryptParam(JSON.stringify(MembershipDetailsFromSCV)));
        window.PubSub.publish('membershipDetailsFromScv', {
          membershipData: membershipData,
          membershipDetails:
            (response.data.response_object &&
              response.data.response_object.data.length > 0 &&
              response.data.response_object.data) ||
            [],
          isMembershipDetails: true,
        });
      } else {
        localStorage.removeItem('membershipDetails');
        window.PubSub.unsubscribe('membershipDetailsFromScv');
      }
    })
    .catch((error) => {
      localStorage.removeItem('membershipDetails');
      Logging(error, 'membershipDetails', false, 'Error in membershipDetails');
    });
};
const fetchToken = (renewConfig, userData, authorizationCode) => {
  if (renewConfig && renewConfig.expireAt) {
    let qData = getQueryData(renewConfig, authorizationCode);

    ApiWrapper.apiGateway({
      method: 'POST',
      url: `${renewConfig.refreshTokenApiUrl}?tenantId=${getLoggedInUser().tenantID}`,
      headers: {
        'Content-Type': renewConfig.contentType,
      },
      data: qData,
      moduleName: 'Renew token',
    })
      .then((response) => {
        setCookie('idToken', '', -1440, getBaseURL());
        setRenewToken(response, userData, renewConfig);
        setCookie('AccessCode', '', -1440, getBaseURL());

        const timeBeforeRenew = Number(renewConfig.timeBeforeRenewInSec) * 1000,
          callTime = timeRemaining(renewConfig.expireAt, timeBeforeRenew);
        //removing global variable since no reference was found where it was being used
        setTimeout(() => fetchToken(renewConfig, userData), callTime);
      })
      .catch((error) => {
        Logging(error, 'Fetch Token', false, 'Error fetching Token');
      });
  }
};

/*
    Method to renew ID token, set interval for renew token for every 1 minute and check if the token is about to expire
    it make and API call to fetch updated ID token and store the update the below mention details to the local storage
    token, user_name, emails, user_type, partner_id, agent_id, market_type, refresh_token, token expire time,
*/
export const renewToken = () => {
  if (canUseDOM) {
    if (!isLoggedInUser()) {
      localStorage.removeItem('membershipDetails');
      window.PubSub.unsubscribe('membershipDetailsFromScv');
    }
    const userData = getLoggedInUser(),
      renewConfig = getRenewTokenConfig(),
      authorizationCode = getCookie('AccessCode'),
      refreshToken = localStorage.getItem('refreshToken') || '';
    if (userData.idToken) {
      if (isMatchTenant(UIConfig.tenants.swadb2c)) {
        fetchMembershipDetails(renewConfig);
      }
      if (authorizationCode && !refreshToken) {
        //When user logins - we need to fetch refresh token for first time
        fetchToken(renewConfig, userData, authorizationCode);
        const allParkTanent =
          isMatchTenant(UIConfig.tenants.fwad) ||
          isMatchTenant(UIConfig.tenants.wbw) ||
          isMatchTenant(UIConfig.tenants.yww);

        const isYasIsland = isMatchTenant(UIConfig.tenants.yi);

        if (allParkTanent || isYasIsland) {
          fetchMembershipDetails(renewConfig);
        }
      } else {
        //to handle the scenario in which user is logged in and refreshes the page
        const timeBeforeRenew = Number(renewConfig.timeBeforeRenewInSec) * 1000,
          callTime = timeRemaining(renewConfig.expireAt, timeBeforeRenew);
        //removing global variable since no reference was found where it was being used
        setTimeout(() => fetchToken(renewConfig, userData), callTime);
      }
    }
  }
};
