import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { logComponentRenderingError, Logging } from '../../../common/logger';

import './opening-hours.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { AnchorLink, SvgSprite } from '../../presentation/base';
import { KeyCodes } from '../../../common/constants';
import {
  customMomentChange,
  detectMobile,
  getLoggedInUser,
  isArabicMode,
  setLocalStorage,
} from '../../../common/utility';
import moment from 'moment';
import { UIConfig } from '../../../..';
import ApiWrapper from '../../../common/api-wrapper';
import { isEmpty } from 'lodash';
import { formatDate, getDateformatStamp } from '../../../common/utility/helpers';

/**
 * Header body is part of header in which either meganav, profile or welcom text will be displayed.
 * @param    {body} Object as defined from data-contract.
 * @param    {searchSettings} Object has settings for Search overlay component
 * @param    {type} String define which type of body will be displayed profile/menu or desktop
 * @param    {logged} Boolean wheather user logged in or not
 * @return   {HeaderBody} markup that containes header Body
 */

const OpeningHours = ({ data, apiUrl, type, isSwad, transparent }) => {
  const ref = useRef();

  // defaultTimeDiff prop will be used when "cacheInterval"
  // prop is not coming from cms to give a default time interval to load the api
  const defaultTimeDiff = 0.25;
  const [isOpeningHoursOpen, setOpeningHoursOpen] = useState(false);
  const [isDateRange, setDateRange] = useState({});
  const [isArabic, setArabic] = useState(false);
  const [isBlueIcon, setBlueIcon] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const toggleOpeningHours = () => {
    setOpeningHoursOpen(!isOpeningHoursOpen);
  };

  const keyPressCallBack = (e) => {
    if (
      e.type === 'keydown' &&
      (e.which === KeyCodes.Enter || e.which === KeyCodes.Space || e.which === KeyCodes.ArrowDown)
    ) {
      toggleOpeningHours();
    }
  };

  const checkIfClickedOutside = (e) => {
    // If the menu is open and the clicked target is not within the menu,
    // then close the menu
    if (isOpeningHoursOpen && ref.current && !ref.current.contains(e.target)) {
      setOpeningHoursOpen(false);
    }
  };

  const checkScrollCloseDropDown = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (isOpeningHoursOpen && winScroll > 60) {
      setOpeningHoursOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkIfClickedOutside);
    window.addEventListener('scroll', checkScrollCloseDropDown);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
      window.removeEventListener('scroll', checkScrollCloseDropDown);
    };
  }, [isOpeningHoursOpen]);

  const getCalendarEvents = (url) => {
    const todayDate = moment()
      .locale('en')
      .format('YYYY-MM-DD');
    const userData = getLoggedInUser();
    const serviceUrl = `${url}?tenantid=${userData.tenantID}&fromDate=${todayDate}&toDate=${todayDate}`;
    const config = {
      url: serviceUrl,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + userData.idToken,
      },
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    };
    ApiWrapper.api(config)
      .then((res) => {
        const openingHours = res.data.parkTimingslist.parkTimings.find(
          (e) => e.extendedProps.isClosed || e.extendedProps.isOpeningHours,
        );
        const storeData = {
          ...openingHours,
          chachedTime: moment(),
        };
        setLocalStorage('openingHoursTime', JSON.stringify(storeData));
        setDateRange(openingHours);
      })
      .catch((err) => {
        Logging(err, 'FullWidthCalendar', false, 'Error in FullWidthCalendar');
      });
  };

  useEffect(() => {
    setArabic(isArabicMode());
    customMomentChange(data);
    setMobile(detectMobile());
    const orderApprovalInfo = localStorage.getItem('openingHoursTime');
    let isInTime = false;
    if (orderApprovalInfo) {
      const { chachedTime } = JSON.parse(orderApprovalInfo);
      const strtDate = moment(chachedTime);
      const timeDiffrence = moment.duration(moment().diff(strtDate)).asHours();
      isInTime = timeDiffrence <= (data.cacheInterval || defaultTimeDiff);
    }
    if (isInTime) {
      setDateRange(JSON.parse(orderApprovalInfo));
    } else {
      const url = apiUrl?.getCalendarAPI?.url;
      if (url) {
        getCalendarEvents(url);
      }
    }
    if (isSwad) {
      const element = document.querySelector('#globalNavigation');
      if (element) {
        element.addEventListener('mouseover', () => {
          setBlueIcon(true);
          setOpeningHoursOpen(false);
        });
        element.addEventListener('mouseout', () => setBlueIcon(false));
        window.addEventListener('resize', onPageResize);
      }
      return () => {
        element.removeEventListener('mouseover', () => {
          setBlueIcon(false);
          setOpeningHoursOpen(false);
        });
        element.removeEventListener('mouseout', () => setBlueIcon(false));
        window.removeEventListener('resize', onPageResize);
      };
    }
  }, []);

  const onPageResize = () => {
    if (window.innerWidth < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const getTodayEventData = (title, closedText) => {
    const { start, end, extendedProps } = isDateRange;
    const { isOpeningHours, isClosed } = extendedProps;

    if (isOpeningHours && start && end) {
      return (
        <>
          {title} <span>{formatDate(start, getDateformatStamp(start, isArabic), 'locale')}</span> -{' '}
          <span>{formatDate(end, getDateformatStamp(end, isArabic), 'locale')}</span>
        </>
      );
    }
    if (isClosed && closedText) {
      return closedText;
    }
  };

  const getIconType = (type) => {
    if (type === 'light') {
      if (!isMobile && isSwad && transparent && !isOpeningHoursOpen && !isBlueIcon) {
        return 'icn-time-clock-header-blue';
      }
      return 'icn-time-clock-header';
    }
    return 'icn-time-clock-header-dark';
  };

  try {
    const { title, label, description, link, closedText } = data;

    if (!data || !title) {
      return null;
    }
    return (
      <section className={`opening-hours-dropdown ${isOpeningHoursOpen ? 'open' : 'close'}`} ref={ref}>
        <div className="opening-hours-container">
          <div className="opening-hours-content">
            <div
              className="opening-hours-header"
              tabIndex="0"
              onClick={toggleOpeningHours}
              onKeyDown={keyPressCallBack}
            >
              <div className="opening-hours-header-left">
                <SvgSprite id={getIconType(type)} />
                <h4>{!isEmpty(isDateRange) ? getTodayEventData(title, closedText) : title}</h4>
              </div>
              <span className={`custom-arrow-icon ${isOpeningHoursOpen ? 'open' : 'close'}`}>
                <span />
                <span />
              </span>
            </div>
            {isOpeningHoursOpen && (
              <div className="opening-hours-details">
                <h4>{label}</h4>
                <Text tag="div" field={{ value: description, editable: description }} />
                <AnchorLink className="view-more" link={link} />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'OpeningHours');
  }
};

OpeningHours.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OpeningHours;
