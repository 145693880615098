/*
 * order-table-rowcomponent.js
 * This file contains code for Order Table Row component, It is a dumb component,it gets it's data from it's parent(order-table)
 * @licensor  Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import { filterList } from '../../../common/utility';
import DynamicContent from '../../presentation/base/dynamic-content/dynamic-content-component';
import SvgSprite from '../../presentation/base/svg-sprite/svg-sprite-component';
import moment from 'moment';
import UIConfig from '../../../common/UIConfig';
const statusIcon = {
  Approved: 'icn-icon-circle-check',
  Pending: 'icn-exclaimation-mark-dot',
  'Payment Failure': 'icn-exclaimation-mark-dot',
  'System Failure': 'icn-exclaimation-mark-dot',
};

/*
    This will render Order Table Row component, will check whether keys are related to date, if yes, will formate the date,will render View order button and agent info for mobile view
*/
const OrderTableRow = (props) => {
  const dateFormat = UIConfig.dateDisplayFormat;

  const keyPressCallBack = (e) => {
    if (e.type === 'keydown' && e.which === 13) {
      props.data.click(e);
    }
  };

  const renderVarient = (props) =>
    props.data.variant === 'credit-settlement-variant' ? (
      <tr className="c-order-table-row settlement-table-row">
        <td className="settlement-checkbox">
          <input
            type="checkbox"
            value="select"
            checked={props.checkedStatus}
            onChange={(e) => props.data.onChangeHandler(e, props.row)}
            className={`settlement-table-row-checkbox ${props.checkedStatus ? 'active' : null}`}
          />
        </td>
        {props.data.labelConfig.gridLabels.map((column, idx) => (
          <td
            className={'c-order-table-row-column body-copy-4 ' + column.key + '-' + props.data.selectedStatus}
            key={idx}
          >
            <span className="labels">{column.label + ': '}</span>
            {column.key === 'dueDate' ? moment(props.row[column.key]).format('DD/MM/YY') : props.row[column.key]}
          </td>
        ))}
      </tr>
    ) : (
      <tr className="c-order-table-row">
        {props.data.labelConfig.gridLabels.map((column, idx) =>
          column.key === 'createdDate' ||
          (column.key === 'dueDate' && props.row['status'] !== UIConfig.orderStatus.Approved) ? (
            <td
              className={'c-order-table-row-column body-copy-4 ' + column.key + '-' + props.data.selectedStatus}
              key={idx}
            >
              <span className="labels">{column.label + ': '}</span>
              {moment(props.row[column.key]).format(dateFormat)}
            </td>
          ) : column.key === 'dueDate' && props.row['status'] === UIConfig.orderStatus.Approved ? (
            <td
              className={'c-order-table-row-column body-copy-4 ' + column.key + '-' + props.data.selectedStatus}
              key={idx}
            >
              <span className="labels">{column.label + ': '}</span>
              {'-'}
            </td>
          ) : column.key === 'price' ? (
            <td className="c-order-table-row-column body-copy-4 " key={idx}>
              <span className="labels">{column.label + ': '}</span>
              {props.row[column.key] ? (
                <span>
                  <span className="body-copy-7">{props.data.currency}</span> {Number(props.row[column.key]).toFixed(2)}
                </span>
              ) : (
                '-'
              )}
              <span className="payment-method">{props.row['paymentInfo']}</span>
            </td>
          ) : column.key === 'status' && props.row['status'] === UIConfig.orderStatus.Declined ? (
            <td className="c-order-table-row-column body-copy-4  status" key={idx}>
              <span className="labels">{column.label + ': '}</span>
              <div className="svg-desktop">
                <SvgSprite id="icn-circle-cross" />
              </div>{' '}
              {props.row[column.key]}
              <a
                data-bookingId={props.row['bookingId']}
                href=""
                onClick={props.data.deleteOrder}
                className="product-quantity--delete-icon svg-desktop"
              >
                <SvgSprite id="icn-delete" />
              </a>
              <div className="svg-mob">
                <SvgSprite id="icn-circle-cross" />
              </div>
            </td>
          ) : column.key === 'status' ? (
            <td className="c-order-table-row-column body-copy-4  status" key={idx}>
              <span className="labels">{column.label + ': '}</span>
              <div className="svg-desktop">
                <SvgSprite id={statusIcon[props.row['status']]} />
              </div>
              {props.row[column.key] ? props.row[column.key] : '-'}
              <div className="svg-mob">
                <SvgSprite id={statusIcon[props.row['status']]} />
              </div>
            </td>
          ) : (
            <td className="c-order-table-row-column body-copy-4 " key={idx}>
              <span className="labels">{column.label + ': '}</span>
              {props.row[column.key] ? props.row[column.key] : '-'}
            </td>
          ),
        )}

        <td className="c-order-table-row-column c-order-table-row-column-vieworder">
          <div className="btn btn-primary">
            <DynamicContent
              tagName="a"
              innerHtml={props.data.labelConfig.orderDetail.viewOrderCta.label}
              attrs={{
                href:
                  props.data.labelConfig.orderDetail.viewOrderCta.url +
                  '?status=true&oId=' +
                  props.row['bookingId'] +
                  '&notEncrypted=true',
              }}
            />
          </div>

          {props.row['status'] === UIConfig.orderStatus.Declined ? (
            <div className="btn btn-secondary">
              <DynamicContent
                tagName="button"
                innerHtml={props.data.labelConfig.deleteOverlay.deleteCta.label}
                attrs={{ onClick: props.data.deleteOrder, 'data-bookingId': props.row['bookingId'] }}
              />
            </div>
          ) : (
            ''
          )}
        </td>

        <td className="c-order-table-row-column c-order-table-row-svg">
          <a
            className={'svg-wrapper ' + props.className}
            data-agentRef={props.row.agentRefNo}
            tabIndex="0"
            data-bookingId={props.row.bookingId}
            onKeyDown={keyPressCallBack}
            onClick={props.data.click}
          ></a>
        </td>
        <td className="c-order-table-row-column c-order-table-row-agent">
          <span>{filterList(props.data.labelConfig.orderDetail.agentColumns, 'AgentRefKey').label + ': '}</span>
          {props.row['agentRefNo']}
        </td>
      </tr>
    );

  return renderVarient(props);
};
export default OrderTableRow;

/**
 * Used to define the proptypes that will be received by the component.
 */

OrderTableRow.propTypes = {
  props: PropTypes.shape({
    data: PropTypes.shape({
      labelConfig: PropTypes.object.isRequired,
      deleteOrder: PropTypes.func.isRequired,
      selectedStatus: PropTypes.string,
      currency: PropTypes.string.isRequired,
    }),
  }),
};
