import React from 'react';
import PropTypes from 'prop-types';
import RadioSet from '../../presentation/radio-set/radio-set';
import { OrderService } from '../../../common/services';
import ErrorSummary from '../../presentation/base/error-summary/error-summary-component';
import { blobToPdf, getErrorMap, resolvePath } from '../../../common/utility';

import './eticket-download.scss';
import { Logging } from '../../../common/logger';

/**
 * EticketDownload is a component which enables us to download ticket
 * @param    {string}    object     wrapper html element for the provided html.
 * @return   {[Object]}  Return a react element.
 */

export default class ETicketDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { language: props.data.selectedLang, ticketCount: props.data.selectedQuant, errorData: '' };
  }

  /**
   * handleLanguageChange is a function which sets the state for the language radio button
   * @param    {event}    object Onchange event
   */
  handleLanguageChange(event) {
    this.setState({ language: event.target.value });
  }

  /**
   * handleCountChange is a function which sets the state for the language radio button
   * @param    {event}    object Onchange event
   */
  handleCountChange(event) {
    this.setState({ ticketCount: event.target.value });
  }

  /**
   * download is a function which enables to download the ticket hitting the ticket service API
   * @param    {event}    object onClick event
   * @return    {file}    object pdf file i.e ticket
   */
  download() {
    const language = this.state.language,
      quantity = this.state.ticketCount,
      data = {
        orderId: this.props.orderId,
        language: language,
        tenantId: this.props.tenantId,
        marketType: this.props.marketType,
        ticketCountPerPage: quantity,
      };
    let url = this.props.url;
    const downloadTicket = OrderService.downloadOrder(
      JSON.stringify(data),
      url,
      'blob',
      true,
      '.eticket-overlay',
      this.props.notEncrypted,
    );

    downloadTicket
      .then((response) => {
        const fileName = data.orderId + '_' + language + '.pdf';
        blobToPdf(response, fileName);
        //To close the overlay
        window.PubSub.publish('toggleOverlayState', {
          shouldOpen: false,
        });
      })
      .catch((response) => {
        Logging(response, resolvePath(response, 'config.moduleName', 'JSSForm'), true, 'Download ticket API failed');

        const errorObj = getErrorMap(
          'eTicketDownload',
          this.props.services.eTicketDownload.errors,
          false,
          response.error,
          this.state.errorData,
        );
        this.setState({ errorData: errorObj });
      });
  }
  render() {
    const data = this.props.data;
    return (
      <div className="eticket-download component">
        <ErrorSummary data={this.state.errorData} />
        <div className="w--content">
          <h3 className="heading-4"> {data.title} </h3>
          <p className="body-copy-3"> {data.summary} </p>
          <div className="title-radio">
            <div className="body-copy-4"> {data.title_lang} </div>
            <RadioSet
              id="language"
              selected={this.state.language}
              radio={data.language}
              onChange={this.handleLanguageChange.bind(this)}
            />
          </div>

          {data.quantity.length !== 0 ? (
            <div className="title-radio">
              <div className="body-copy-4"> {data.title_quant} </div>
              <RadioSet
                id="quantity"
                selected={this.state.ticketCount}
                radio={data.quantity}
                onChange={this.handleCountChange.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          <div className="btn btn-primary">
            <button type="button" onClick={this.download.bind(this)}>
              {data.cta.label}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ETicketDownload.propTypes = {
  props: PropTypes.shape({
    language: PropTypes.object.isRequired,
    title_lang: PropTypes.string.isRequired,
    title_quant: PropTypes.string.isRequired,
    cta: PropTypes.object.isRequired,
    quantity: PropTypes.object.isRequired,
    summary: PropTypes.string,
    orderId: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    marketType: PropTypes.string.isRequired,
  }),
};
