import React, { useEffect, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import UIConfig from '../../../common/UIConfig';
import {
  randomNumber,
  getCookie,
  canUseDOM,
  createMappedData,
  flipObjectKeyValues,
  getErrorMap,
  getFallbackLanguage,
  getLoggedInUser,
  getUserAgent,
  hasHeaderComponent,
  isEmpty,
  checkTenant,
  resolvePath,
  isSafariBrowser,
  iOSDevice,
  detectMobile,
  detectViewPort,
} from '../../../common/utility';
import {
  getDiscount,
  getPerformanceId,
  getCategoryProds,
  updateSecProdArray,
  renderOverlayErrorMsg,
  setStateOfMinicart,
  changeCategoryToString,
  updateCouponCodeData,
  enrichProductData,
} from '../../../common/helpersPJ';
import { AnchorLink, DynamicContent, Image, SvgSprite, Overlay } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';
import AddOnsTicketComponent from '../b2c-purchase-journey/add-ons/add-ons-ticket-component';
import { bookNowClickAnalytics } from '../../../common/analytics-events';
import CartActions from '../b2c-purchase-journey/cart/cart-actions';
import { getB2CProductDetails } from '../../../common/coveo-api.js';
import ApiWrapper from '../../../common/api-wrapper.js';
import GTMData from '../b2c-purchase-journey/gtm-data';
import { PerformanceService } from '../../../common/services';
import ProductStore from '../b2c-purchase-journey/purchase-journey-wrapper/product-store';
import { secProds } from '../b2c-purchase-journey/add-ons/add-ons-utility.js';
import { Logging } from '../../../common/logger';

const renderWBottom = (data, isContactUsConfirmation, renderPjPopup, showPjPopupSegment) => {
  const {
    downloadTicket,
    downloadTicketLabel,
    image,
    primaryCTA,
    secondaryCTA,
    title,
    variant,
    bookNowLabel,
    productId,
    isProductSoldOut,
    soldoutText,
  } = data;

  if (!primaryCTA && !downloadTicket && !showPjPopupSegment) {
    return null;
  }
  const linkAriaLabel =
    title && title.bodyCopy && primaryCTA && primaryCTA.label ? `${title.bodyCopy} ${primaryCTA.label}` : '';

  return (
    <div className="w--bottom">
      {showPjPopupSegment &&
        (isProductSoldOut ? (
          <div className="btn btn-primary sold-out-cta disabled">
            <AnchorLink
              link={{
                href: 'javascript:void(0)', // eslint-disable-line
                label: soldoutText.toUpperCase(),
              }}
            />
          </div>
        ) : (
          <div className={classNames('btn btn-primary book-now', { 'btn-primary-inverted': !!variant })}>
            <AnchorLink
              link={{
                href: 'javascript:void(0)', // eslint-disable-line
                label: bookNowLabel.toUpperCase(),
              }}
              onClick={() => renderPjPopup(productId)}
            />
          </div>
        ))}
      {downloadTicket && (
        <div className="btn btn-primary">
          <DynamicContent
            tagName="button"
            attrs={{ className: 'download-ticket', onClick: downloadTicket }}
            innerHtml={downloadTicketLabel}
          />
        </div>
      )}
      {primaryCTA &&
        primaryCTA.href &&
        (isContactUsConfirmation ? (
          <div className="phone-detail body-1">
            <SvgSprite id="phone-icon" />
            <AnchorLink link={{ ...primaryCTA, href: primaryCTA.href.slice(1), ariaLabel: primaryCTA.title }} />
          </div>
        ) : (
          <div
            className={classNames('btn btn-primary', {
              'btn-primary-inverted': !!variant && variant !== UIConfig.textWithCtaVariants.leftVariant,
            })}
          >
            <AnchorLink
              link={{ ...primaryCTA, ariaLabel: linkAriaLabel.replace(/<[^>]*>/g, '') }}
              onClick={() => handleBookNow(primaryCTA, variant)}
            />
          </div>
        ))}
      {secondaryCTA &&
        secondaryCTA.href &&
        (isContactUsConfirmation ? (
          <div className="email-detail body-1">
            <SvgSprite id="email-icon" />
            <AnchorLink link={{ ...secondaryCTA, ariaLabel: secondaryCTA.title }} />
          </div>
        ) : (
          <div className={classNames('btn btn-secondary', { 'btn-secondary-inverted': !!variant })}>
            <AnchorLink link={secondaryCTA} />
          </div>
        ))}
      {image && !isContactUsConfirmation && (
        <div className="textwith-cta-image">
          {variant === UIConfig.promotionalEvent ? (
            <a href={primaryCTA.href} target={primaryCTA.target}>
              <Image image={image} />
            </a>
          ) : (
            <Image image={image} />
          )}
        </div>
      )}
    </div>
  );
};

const renderWMiddle = (bodyCopy, title, variant, isContactUsConfirmation) => {
  if ((!title || !title.bodyCopy) && !bodyCopy) {
    return null;
  }

  let message = bodyCopy;
  if (variant === 'iam-reset-password') {
    const emailId = getCookie('userEmailAddress') ? getCookie('userEmailAddress') : '';
    message = `${bodyCopy} <p class="user-email">${emailId}</p>`;
  }

  const classes = classNames('w--middle', {
    'w--middle--footer': variant === UIConfig.b2c.footerVariants.yaTextWithCta,
  });

  return (
    <div className={classes}>
      <div className="w--content">
        {variant === 'iam-reset-password' && <div className="success-icon">success icon</div>}
        {title && title.bodyCopy && (
          <DynamicContent tagName="h2" attrs={{ className: 'title heading-2' }} innerHtml={title.bodyCopy} />
        )}
        {message && (
          <DynamicContent
            tagName="div"
            attrs={{ className: classNames('description', isContactUsConfirmation ? 'body-1' : 'body-copy-5') }}
            innerHtml={message}
          />
        )}
      </div>
    </div>
  );
};

const renderTextWithCTA = (
  data,
  renderPjPopup,
  renderPJPopupData,
  errorOverlayObj,
  isOverlayOpen,
  showPjPopupSegment,
  atwData = [],
) => {
  const { bodyCopy, cssClass, gradient, image, title, variant } = data;
  const userAgent = getUserAgent() || !hasHeaderComponent();
  let randNum = randomNumber(3);
  const isYMC = checkTenant(UIConfig.iamMapping.ymc);
  const isContactUsConfirmation = variant === 'v-confirmation-contactus';
  const containerClasses = classNames(
    'component',
    isContactUsConfirmation ? 'c-confirmation-contactUs' : 'c-text-with-cta',
    `gradient${randNum}`,
    variant,
    cssClass,
    { 'c-text-with-cta-footer': variant === UIConfig.b2c.footerVariants.yaTextWithCta },
  );

  const getImageUrlBasedOnMobileOS = (addToWallet) => {
    if (detectViewPort() === UIConfig.viewportTypes.mobile && !isEmpty(addToWallet)) {
      if (iOSDevice()) {
        return addToWallet.iosImage;
      } else {
        return addToWallet.androidImage;
      }
    }
    return null;
  };

  const formResAccToURL = (response) => {
    const bookProUrl = resolvePath(response.BookProResponseModel[0], 'bookproUrl', '');
    if (bookProUrl) {
      if (isSafariBrowser() || iOSDevice()) {
        window.open(bookProUrl, '_self');
      } else {
        window.open(bookProUrl);
      }
    }
  };

  const atwErrorCallback = (err) => {
    atwData[2](err);
  };

  const onClickHandler = async () => {
    const data = {
      passtype: atwData?.[0]?.passType,
      productdescription: atwData?.[0]?.productDescription,
      mediaId: atwData?.[0]?.mediaId,
      reservationCode: atwData?.[0]?.reservationCode,
      name: atwData?.[0]?.name,
    };
    const url = atwData?.[0]?.bookProUrl?.url;
    if (url && data) {
      const config = {
        url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        preLoader: true,
        preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
        data: { BookProRequestModel: [data], tenantId: getLoggedInUser().tenantID },
      };
      try {
        const res = await ApiWrapper.experienceServices(config);
        if (res && res.data) {
          formResAccToURL(res.data);
        }
      } catch (err) {
        const bpuError = atwData?.[0]?.bookProUrl?.errors;
        const errorData = atwData?.[1];
        atwErrorCallback(getErrorMap('getbookprourl', bpuError, false, err, errorData));
        Logging(err, 'Add to wallet module', true, 'get Book pro API failed');
      }
    }
  };
  return (
    <div className={containerClasses}>
      {gradient && (
        <DynamicContent
          tagName="style"
          innerHtml={`
            .c-text-with-cta.component.gradient${randNum} {
              background: ${gradient};
            }
          `}
        />
      )}
      {variant && !isContactUsConfirmation && (
        <div className="w--top">
          <SvgSprite id="icn-cta-smile" styleClass="icn-cta-smile" />
        </div>
      )}
      {isContactUsConfirmation ? (
        <React.Fragment>
          <div className="details">
            {renderWMiddle(bodyCopy, title, variant, isContactUsConfirmation)}
            {renderWBottom(data, isContactUsConfirmation, renderPjPopup, showPjPopupSegment)}
          </div>
          <div className="c-confirmation-contactUs-image-container">
            <Image image={image} disableLazyLoad={true} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderWMiddle(bodyCopy, title, variant, isContactUsConfirmation)}
          {isYMC &&
            detectMobile() &&
            atwData?.[0]?.enableAddToWallet &&
            atwData?.[0]?.atwFormType === UIConfig.jssForms.formType.getHealthNFitnessRegistration && (
              <div className="add-to-wallet" tabIndex="0">
                <div className="section-item">
                  <img
                    src={getImageUrlBasedOnMobileOS(atwData?.[0]?.addToWallet)}
                    onClick={() => onClickHandler()}
                    alt="AddToWallet.png"
                  />
                </div>
              </div>
            )}
          {renderWBottom(data, isContactUsConfirmation, renderPjPopup, showPjPopupSegment)}
        </React.Fragment>
      )}
      {showPjPopupSegment && isOverlayOpen && renderPJPopupData()}
      {renderOverlayErrorMsg(errorOverlayObj)}
      {userAgent && <Overlay />}
    </div>
  );
};

/* Added as part of yasisland booknow cta GA event */
const handleBookNow = (primaryCTA, variant) => {
  if (!primaryCTA || variant !== UIConfig.textWithCtaVariants.leftVariant) {
    return;
  }
  bookNowClickAnalytics(window.location.href, { secondaryBuyLabel: primaryCTA.label });
};

const renderDescription = (
  data,
  renderPjPopup,
  renderPJPopupData,
  errorOverlayObj,
  isOverlayOpen,
  showPjPopupSegment,
) => {
  const {
    bodyCopy,
    cssClass,
    gradient,
    image,
    primaryCTA,
    secondaryCTA,
    title,
    variant,
    bookNowLabel,
    productId,
    isProductSoldOut,
    soldoutText,
  } = data;
  let randNum = randomNumber(3);
  const userAgent = getUserAgent() || !hasHeaderComponent();

  return (
    <div
      className={classNames('component c-text-with-cta', `gradient${randNum}`, variant, cssClass)}
      data-c-render="server-only"
    >
      {gradient && (
        <DynamicContent
          tagName="style"
          innerHtml={`.c-text-with-cta.component.gradient${randNum} { background: ${gradient};}`}
        />
      )}
      {variant && (
        <div className="w--top">
          <SvgSprite id="icn-cta-smile" styleClass="icn-cta-smile" />
        </div>
      )}
      {((title && title.bodyCopy) || bodyCopy) && (
        <div className="w--middle">
          <div className="w--content">
            {title && title.bodyCopy && (
              <div className="title-cta">
                <DynamicContent tagName="h2" attrs={{ className: 'title heading-2' }} innerHtml={title.bodyCopy} />
                <div className="w--bottom">
                  {showPjPopupSegment &&
                    (isProductSoldOut ? (
                      <div className="btn btn-primary  sold-out-cta disabled">
                        <AnchorLink
                          link={{
                            href: 'javascript:void(0)', // eslint-disable-line
                            label: soldoutText.toUpperCase(),
                          }}
                        />
                      </div>
                    ) : (
                      <div className={classNames('btn btn-primary book-now', { 'btn-primary-inverted': !!variant })}>
                        <AnchorLink
                          link={{
                            href: 'javascript:void(0)', // eslint-disable-line
                            label: bookNowLabel.toUpperCase(),
                          }}
                          onClick={() => renderPjPopup(productId)}
                        />
                      </div>
                    ))}

                  {primaryCTA && primaryCTA.href && (
                    <div
                      className={classNames('btn btn-primary', {
                        'btn-primary-inverted': !!variant && variant !== UIConfig.textWithCtaVariants.leftVariant,
                      })}
                    >
                      <AnchorLink link={primaryCTA} onClick={() => handleBookNow(primaryCTA, variant)} />
                    </div>
                  )}
                  {secondaryCTA && secondaryCTA.href && (
                    <div className={classNames('btn btn-secondary', { 'btn-secondary-inverted': !!variant })}>
                      <AnchorLink link={secondaryCTA} />
                    </div>
                  )}
                  {image && (
                    <div className="textwith-cta-image">
                      <Image image={image} />
                    </div>
                  )}
                </div>
              </div>
            )}
            {bodyCopy && (
              <DynamicContent tagName="div" attrs={{ className: 'description body-copy-5' }} innerHtml={bodyCopy} />
            )}
          </div>
        </div>
      )}
      {showPjPopupSegment && isOverlayOpen && renderPJPopupData()}
      {renderOverlayErrorMsg(errorOverlayObj)}
      {userAgent && <Overlay />}
    </div>
  );
};

/**
 * TextWithCTA dumb Component creates textWithCTAs
 * functionality of showing directions when a user selects a location from dropdown.
 * @param    {data} Object as defined from data-contract.
 * @return   {htmlMarkUp}
 */
const TextWithCTA = ({ data, atwData }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [atwErrorData, setAtwErrorData] = useState(null);
  const [errorOverlayObj, setErrorOverlayObj] = useState({});
  const [purchaseWrapperErrors, setPurchaseWrapperErrors] = useState('');
  const [errObj, setErrObj] = useState('');
  const [cartData, setCartData] = useState({});
  const [showPjPopupSegment, setShowPjPopupSegment] = useState(false);
  const products = useRef({});
  const productId = useRef('');
  const pjData = useRef(null);
  const cartActions = useRef(null);
  const productList = useRef([]);
  const coveoDefaultParams = useRef({});
  const groupingNotRequired = useRef(false);
  const responseObj = useRef(null);
  const responseObjAll = useRef(null);

  useEffect(() => {
    const { enablePJPopup } = data;

    if (enablePJPopup) {
      window.PubSub.subscribe(UIConfig.events.PURCHASEJOURNEYDATA, (msg, data) => {
        pjData.current = data.purchaseJourneyData;

        cartActions.current = new CartActions({
          serviceUrl: pjData.current.services,
          moduleName: 'purchase-journey',
          tenantId: getLoggedInUser().tenantID,
        });

        setStateOfMinicart(cartActions, productList, errorCallback, setCartData, setErrObj);
      });
      setShowPjPopupSegment(true);
    }

    window.PubSub.subscribe('closeOverlay', closeOverlay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    triggerProductDetailsEvent();
  }, [data]);

  const triggerProductDetailsEvent = useCallback(() => {
    if (!data) {
      return;
    }
  }, [data]);

  /**
   * error handling
   * @param {Object} err error object
   */
  const errorCallback = (serviceName, error) => {
    if (error) {
      if (serviceName === 'getCoveoProducts' && error.code.toString() === UIConfig.errorCodes.notFound) {
        const errObj = getErrorMap(
          serviceName,
          pjData.current.services[serviceName].errors,
          false,
          error,
          purchaseWrapperErrors,
        );
        setPurchaseWrapperErrors(errObj);
      } else {
        const errorObj = getErrorMap(serviceName, pjData.current.services[serviceName].errors, false, error, errObj);
        setErrObj(errorObj);
      }
    }
  };

  /**
   * Product data coveo call
   * @param {Object} err error object
   */
  const createProductStore = (tab, getActiveProduct) => {
    return new Promise((resolve, reject) => {
      if (!products.current[tab]) {
        const params = [];
        getActiveProduct
          ? params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.productId, value: productId.current },
            )
          : tab &&
            params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.tab, value: tab },
            );

        getB2CProductDetails({
          ...coveoDefaultParams.current,
          queryParams: params,
        })
          .then((res) => {
            res.results = changeCategoryToString(res.results, coveoDefaultParams);
            products.current[tab] = new ProductStore(res.results);
            setPurchaseWrapperErrors('');
            resolve(products.current[tab]);
          })
          .catch((res) => {
            errorCallback('getCoveoProducts', res.error);
            const errObj = getErrorMap(
              'getCoveoProducts',
              pjData.current.services.getCoveoProducts.errors,
              false,
              res.error,
            );
            reject(errObj);
          });
      } else {
        resolve(products.current[tab]);
      }
    });
  };

  const getDiscountedProducts = (products, days, discountMap, disableDiscount) => {
    discountMap = discountMap || pjData.current.discountMap;
    const baseProducts = [];
    const selectedProducts = [];
    const baseProductPrices = {};
    const baseDis = getDiscount(0, discountMap, products);
    const selectedDis = getDiscount(days, discountMap, products);

    products.forEach((prod) => {
      const prodCode = prod.pricing;
      prod.discount = {};
      prod.discount.actualPerc = 0;
      prod.discount.computed = 0;

      if (prodCode === baseDis.code) {
        prod.unitPrice = prod.gross || 0;
        baseProducts.push(prod);
        baseProductPrices[prod.classType] = prod.gross || 0;
      } else if (prodCode === selectedDis.code) {
        prod.discount.actualPerc = selectedDis.discountPer;
        selectedProducts.push(prod);
      }
    });

    if (baseDis.code === selectedDis.code || disableDiscount) {
      return baseProducts;
    } else {
      return selectedProducts.map((prod) => {
        prod.unitPrice = baseProductPrices[prod.classType];
        return prod;
      });
    }
  };

  const addToCartCallback = (products, grouping, context, redirectToBookingPageCallback) => {
    // add required keys for cart and chekbasket
    groupingNotRequired.current = grouping;
    const data = pjData.current;
    products = enrichProductData(products, data, groupingNotRequired);

    const perPromises = [];
    // extract the quntity from param and add it to respective product
    products.forEach((prod) => {
      const quantity = prod.quantity;
      let callPerformance = false;
      if (!prod.performanceId) {
        prod.performanceId = null; //for checkbasket
        callPerformance = prod.hasperformance === '1';
      }

      /* Reset performance id when hasperformance is 0 to fix price value */
      if (prod.hasperformance === '0') {
        prod.performanceId = null;
      }

      prod.guestDetails = prod.guestDetails || null; //for checkbasket
      prod.quantity = quantity;
      prod.currQuantity = quantity;
      prod.groupedQuantity = quantity;
      //adding null check for getPerformance service
      if (callPerformance && data.services.getPerformance) {
        const url = data.services.getPerformance.url.replace('{0}', prod.productId);
        perPromises.push(
          PerformanceService.getPerformanceData(
            url,
            prod.fromDate,
            prod.toDate,
            true,
            UIConfig.loader.defaultPreLoaderTarget,
          ),
        );
      }
    });

    Promise.all(perPromises)
      .then((responses) => {
        let errorObj = getErrorMap('getPerformance', {}, true);
        responses.forEach((res, index) => {
          products[index].performanceId = getPerformanceId(res.data.performancelist.performance, true);
          if (!products[index].performanceId) {
            res.error = {
              code: '7001',
              text: 'product is not sellable',
            };
            throw res;
          }
        });
        // save the data to cart

        let cartMaxQty;
        let prodMaxQty;

        if (data.miniCart) {
          cartMaxQty = data.miniCart.maxCartQuantity;
          prodMaxQty = data.miniCart.maxQuantity;
        } else {
          cartMaxQty = data.cartMaxQty;
        }

        const remQty = cartMaxQty - cartData.cart.totalQuantity;
        updateCouponCodeData(cartData, cartActions);
        cartActions.current
          .updateCart(productList.current || [], products, prodMaxQty, remQty)
          .then((res) => {
            productList.current = res.productList;
            errorObj = getErrorMap('updateCart', {}, true);
            GTMData.push('addToCart', { products: products });

            setCartData(cartActions.current.getCustomCart(productList.current || [], true));
            setErrObj(errorObj);
            setIsOverlayOpen(false);
            window.PubSub.publish(UIConfig.events.MINICARTUPDATED, true);

            if (context && context.href) {
              window.location.href = context.href;
            }
            redirectToBookingPageCallback();
          })
          .catch((res) => {
            const err = res.error || res.response.error; //for different cutom responses
            errorCallback('updateCart', err);
            redirectToBookingPageCallback();
          });
      })
      .catch((res) => errorCallback('getPerformance', res.error));
  };

  const renderPjPopup = (id, itemData = {}) => {
    const datalayerItem = canUseDOM() && window.dataLayer && window.dataLayer[0];
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const tenantId = mainObj.tenantID && mainObj.tenantID.toLowerCase();
    productId.current = id;
    if (!pjData.current) {
      return;
    }

    coveoDefaultParams.current = data.enablePJPopup
      ? {
          perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.current.coveoMappingList,
          serviceUrl: pjData.current.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.current.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};
    data.enablePJPopup &&
      createProductStore(productId.current, true).then((response) => {
        responseObj.current = response;
        const activeProduct = response && createMappedData(response.products, pjData.current.coveoMappingList)[0];
        const currentTabData =
          pjData.current &&
          pjData.current.tabs &&
          pjData.current.tabs.filter(
            (tab) =>
              tab.coveoValue.toLowerCase() === (activeProduct && activeProduct.tab && activeProduct.tab.toLowerCase()),
          )[0];

        createProductStore(currentTabData.coveoValue, false).then((response) => {
          responseObjAll.current = response;
          setIsOverlayOpen(true);
          setErrorOverlayObj({});
        });
      });

    if (datalayerItem && tenantId === UIConfig.ymcB2CTenant) {
      bookNowClickAnalytics(data.title, itemData);
    }
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const renderPJPopupData = () => {
    const activeProduct =
      responseObj.current && createMappedData(responseObj.current.products, pjData.current.coveoMappingList)[0];

    const currentTabData =
      pjData.current &&
      pjData.current.tabs &&
      pjData.current.tabs.filter(
        (tab) => tab.coveoValue.toLowerCase() === (activeProduct.tab && activeProduct.tab.toLowerCase()),
      )[0];

    let counterData = null;
    let dateSelector = null;
    let timeSlotSelector = null;
    let catgry = null;
    let categry = null;
    let productOverlaySelector = null;

    const category = { [currentTabData.coveoKey]: currentTabData.coveoValue };
    const resProducts = responseObjAll.current.getFilteredProductsFromKeyVal(category);
    const allProducts = resProducts.length && createMappedData(resProducts, pjData.current.coveoMappingList);
    const categoryProducts = allProducts.filter(
      (prod) => prod.category && prod.category[0] === activeProduct.category[0],
    );

    for (let tktType in currentTabData.controls) {
      let currentBaseProdOptions =
        currentTabData.controls[tktType].options &&
        currentTabData.controls[tktType].options.find((opt) => opt.coveoValue === activeProduct.category.toString());

      if (currentBaseProdOptions) {
        catgry = currentBaseProdOptions;
        counterData = currentBaseProdOptions.visitorSelector;
        dateSelector = currentBaseProdOptions.dateSelector;
        timeSlotSelector = currentBaseProdOptions.timeSlotSelector;
        productOverlaySelector = currentBaseProdOptions.productOverlaySelector;
      }
      categry = currentBaseProdOptions;
    }

    if (!products.current.hasOwnProperty('quantity')) {
      products.current.quantity =
        (counterData &&
          counterData.visitorSelector &&
          counterData.visitorSelector.options &&
          counterData.visitorSelector.options[0] &&
          counterData.visitorSelector.options[0].defaultQuantity) ||
        0;
    }

    const additionalProds = getCategoryProds(activeProduct.category, allProducts);

    // Reset Secondary products
    Object.keys(secProds).forEach((key) => delete secProds[key]);

    return categoryProducts.map((product, index) => {
      const multiTicketSelector = categry.multiTicketSelector,
        keyMap = multiTicketSelector ? multiTicketSelector.stringMapping : {},
        category = product.category;

      let isCategoryMappingExist = false;
      if (keyMap) {
        for (let item in keyMap) {
          if (item === category[0]) {
            isCategoryMappingExist = true;
            break;
          }
        }
      }

      if (
        keyMap &&
        (category.indexOf(keyMap.drivingExperience) !== -1 ||
          category.indexOf(keyMap.passengerExperience) !== -1 ||
          isCategoryMappingExist)
      ) {
        updateSecProdArray(product, category[0], keyMap);
      }

      if (!product.hasOwnProperty('quantity')) {
        product.quantity = categry.visitorSelector.options[0].defaultQuantity;
      }

      return (
        <div>
          {allProducts && additionalProds && (!multiTicketSelector || product.experienceCatgory === keyMap.primary) && (
            <AddOnsTicketComponent
              data={currentTabData}
              cartData={pjData.current.miniCart}
              businessErrors={pjData.current.miniCart.businessErrors}
              ticket={product}
              dateSelector={dateSelector}
              visitorCounter={counterData}
              createMappedData={createMappedData}
              addToCartCallback={addToCartCallback}
              services={pjData.current.services}
              getDiscountedProducts={getDiscountedProducts}
              timeSlotSelector={timeSlotSelector}
              category={catgry}
              handleOnClick={() => {}}
              additionalProds={additionalProds}
              enablePJPopup={data.enablePJPopup}
              searchProductList={allProducts}
              coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
              productOverlaySelector={productOverlaySelector}
              overlayErrorCallback={overlayErrorCallback}
              variant={data.enablePJPopup ? 'v-overlay-selected' : ''}
            />
          )}
        </div>
      );
    });
  };

  const overlayErrorCallback = (error, errorData) => {
    let errorObj = {};
    if (!isEmpty(error) && error.errorcode) {
      errorObj.errorcode = error.errorcode;
      if (errorData && errorData[error.errorcode]) {
        errorObj.text = errorData[error.errorcode];
      } else if (error && error.errordescription) {
        errorObj.text = error.errordescription;
      }
      setErrorOverlayObj(errorObj);
      setIsOverlayOpen(false);
    }
  };
  try {
    if (data.variant === 'description-box') {
      return renderDescription(
        data,
        renderPjPopup,
        renderPJPopupData,
        errorOverlayObj,
        isOverlayOpen,
        showPjPopupSegment,
      );
    } else {
      return renderTextWithCTA(
        data,
        renderPjPopup,
        renderPJPopupData,
        errorOverlayObj,
        isOverlayOpen,
        showPjPopupSegment,
        [atwData, atwErrorData, setAtwErrorData],
      );
    }
  } catch (err) {
    return logComponentRenderingError(err, 'TextWithCTA', data.variant);
  }
};

export default TextWithCTA;

TextWithCTA.propTypes = {
  data: PropTypes.shape({
    bodyCopy: PropTypes.string,
    cssClass: PropTypes.string,
    downloadTicketLabel: PropTypes.string,
    gradient: PropTypes.string,
    image: PropTypes.object,
    primaryCTA: PropTypes.shape({
      tagHTML: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
    }),
    secondaryCTA: PropTypes.shape({
      tagHTML: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
    }),
    title: PropTypes.object,
    variant: PropTypes.string,
  }),
};
