import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  canUseDOM,
  getSessionStorage,
  getLocalStorageByKey,
  checkIfParks,
  getAnonymousCart,
  isTicketUpgradeJourney,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';

const BackLinkComponent = ({ data }) => {
  if (canUseDOM()) {
    let isParks = checkIfParks();
    let cartData = getAnonymousCart();
    const isTicketUpgrade = (isParks && cartData?.cart?.isTicketUpgradeCart) || false;

    if (!isTicketUpgrade) {
      return <></>;
    }

    if (getSessionStorage('backtopage') === 'true') {
      setTimeout(() => {
        let selectedItem = document.querySelector(`body`);
        selectedItem && selectedItem.scrollIntoView(true);
        sessionStorage.removeItem('backtopage');
      }, 2000);
    }
    if (!isTicketUpgradeJourney()) {
      return <></>;
    }
  }
  const backToTicketUpgrade = () => {
    const upgradeUrlWithQS = canUseDOM() && getLocalStorageByKey(UIConfig.parksTicketUpgrade.upgradeUrlWithQS);
    if (upgradeUrlWithQS) {
      window.location.href = upgradeUrlWithQS;
    } else {
      window.history.back();
    }
  };

  const { text } = data;
  return (
    <div className="container backlinkWrapper">
      <div className="row">
        <div className="col-12">
          <div className="backlink">
            {text && (
              <>
                <div className="backlink-text">
                  <Text
                    tag="a"
                    href="javascript:;"
                    onClick={() => backToTicketUpgrade()}
                    field={{ value: text, editable: text }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackLinkComponent;
