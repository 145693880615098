/*
 * Error Summary Component for displaying Error messages.
 *
 * @author    SapientNitro
 * @licensor  Miral
 */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { DynamicContent, SvgSprite } from '..';
import { hasSameProps, resolvePath } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import classNames from 'classnames';
import UIConfig from '../../../../common/UIConfig';

import './error-summary-component.scss';

const customShouldComponentUpdate = (prevProps, nextProps) => {
  /*
     return true if passing nextProps to render would return
     the same result as passing prevProps to render,
     otherwise return false
     */
  if (
    hasSameProps(prevProps.data, nextProps.data, 'testVal') &&
    matchCode(prevProps.data.error, nextProps.data.error) &&
    !prevProps.hideErrorWrapper
  ) {
    return true; // component will not update
  }
  return false; // component will update
};

const matchCode = (prevCode, nextCode) => {
  if (prevCode && prevCode.code && nextCode && nextCode.code && prevCode.code === nextCode.code) {
    return true;
  }
  return false;
};

const ErrorSummary = (props) => {
  let errorInstance = useRef(null);
  let handleClick = null;
  const [hideErrorWrapper, setHideErrorWrapper] = useState(false);

  /**
   * @param    {object} props  receving props as data
   * @return   [Array]  Return a react element.
   */

  const getMessages = (data) => {
    let msg = [];
    Object.keys(data).forEach(function(key) {
      msg.push(data[key]);
    });
    return msg;
  };

  const scrollToTarget = () => {
    window.PubSub.publish('errorOccured', { errorBlock: errorInstance.current });
  };

  /*Check DOM update*/
  useEffect(() => {
    if (props.data && Object.keys(props.data).length && !props.disableScroll) {
      scrollToTarget();
    }
  });
  const isGiftVoucherError = useMemo(
    () => props.data && props.data.error && props.data.error.code === UIConfig.errorCodes.giftVoucherError,
    [props.data],
  );

  useEffect(() => {
    if (isGiftVoucherError) {
      window.localStorage.setItem(UIConfig.localStoreKeys.cartError, true);
    } else {
      window.localStorage.removeItem(UIConfig.localStoreKeys.cartError);
    }
  }, [props.data, isGiftVoucherError]);

  /**
   * @return   html  Return a react element error list.
   */

  const renderErrorMessages = () => {
    let errors = getMessages(props.data);
    if (errors.length) {
      if (errors[0].text) {
        return errors.map((items, i) => (
          <li key={i}>
            {items.errorFrom !== 'cart' ? (
              <DynamicContent
                tagName="div"
                attrs={{
                  className: 'heading-error',
                  onClick: handleClick,
                  role: 'alert',
                  'aria-live': 'assertive',
                }}
                innerHtml={items.text}
              />
            ) : (
              <div className="heading-error" onClick={handleClick} role="alert" aria-live="assertive">
                {items.text}
              </div>
            )}
          </li>
        ));
      }
      setHideErrorWrapper(true);
    }
  };
  try {
    return props.data && !hideErrorWrapper ? (
      <div
        className={classNames(resolvePath(props, 'className', ''), `${UIConfig.errorClassNames.serverError}`)}
        ref={errorInstance}
      >
        <div className="error-icon">
          <SvgSprite id="icn-exclaimation-mark" />
        </div>
        <ul>{renderErrorMessages()}</ul>
      </div>
    ) : null;
  } catch (err) {
    return logComponentRenderingError(err, 'ErrorSummary');
  }
};

export default React.memo(ErrorSummary, customShouldComponentUpdate);
