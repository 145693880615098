/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/*
 * mega-nav-component
 * This file contains code for Mega Nav component, it renders 1 RTE ,links array and image array
 * @author    Sunil Sharma, SapientNitro <ssharma193@sapient.com>
 * @licensor  Miral
 */

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { checkGA4Tenants, detectMobile, isMatchTenant } from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';
import { AnchorLink, DynamicContent, Image } from '../base';
import MegaNavAccord from '../mega-nav-accord';
import { subHeaderClickAnalytics } from '../../../common/analytics-events';
import UIConfig from '../../../common/UIConfig';

import CTAButton from '../base/cta-button/CTAButton';
import GTMData from '../../container/b2c-purchase-journey/gtm-data';

/**
 * MegaNavVariant is a component which will be rendered by Nav component, it expects its props from Nav component
 * @param    {object}    data     having data of Mega Nav
 */

let middleColumnFormattedArray = [];
const middleColumnDataLengthLimit = 11; // Maximum number of elements in single column of meganav
const isMultiMegaNav = (variant) => variant === 'v-header-multimeganav';

const addToFormattedArry = (itemToAdd, extraLineSpace) => {
  const arrayLastItemLen = middleColumnFormattedArray[middleColumnFormattedArray.length - 1].length;
  if (
    arrayLastItemLen >= middleColumnDataLengthLimit ||
    (itemToAdd.type === 'middle-column-title' && arrayLastItemLen >= middleColumnDataLengthLimit - 1)
  ) {
    middleColumnFormattedArray.push([]);
  }

  const arrayLastItem = middleColumnFormattedArray[middleColumnFormattedArray.length - 1];
  arrayLastItem.push(itemToAdd);
  if (extraLineSpace) {
    arrayLastItem.push({});
  }
};

const middleColumnDataFormatter = (section) => {
  (section || []).forEach((sectionItem) => {
    addToFormattedArry(
      {
        type: 'middle-column-title',
        label: sectionItem.label,
        ctaDetails: sectionItem.ctaDetails,
      },
      true,
    );

    (sectionItem.linkData || []).forEach((item, idx) => {
      if (idx === sectionItem.linkData.length - 1) {
        item.type = 'middle-column-last-item';
        addToFormattedArry(item, true);
      } else {
        addToFormattedArry(item);
      }
    });
  });
};
const googleAnalyticSubHeaderHandleClick = (event, data) => {
  subHeaderClickAnalytics(event, data);
};
const firstColumn = (data, variant) => {
  const { introData, introLink, primaryFeaturedLinks } = data;

  return (
    <div className="meganav--intro-wrapper-list">
      <div className="intro-text">
        <DynamicContent tagName="div" innerHtml={introData} />
      </div>
      {introLink && (
        <AnchorLink
          className="intro-link right-arrow"
          link={{
            label: introLink.label,
            href: introLink.href,
          }}
        />
      )}

      <div className="primary-featured-links">
        {Array.isArray(primaryFeaturedLinks) &&
          primaryFeaturedLinks.length > 0 &&
          primaryFeaturedLinks.map((card) => {
            return (
              <>
                {card.featuredimage && <img src={card.featuredimage} alt={card.featuredimage.imageAltText} />}
                <div className="primary-featured-links-details">
                  <DynamicContent tagName="div" attrs={{ className: 'featured-text' }} innerHtml={card.featuredtext} />

                  <AnchorLink
                    className="intro-link right-arrow first-child"
                    link={{
                      href: card.featuredCta.href,
                      label: card.featuredCta.label,
                    }}
                    onClick={
                      variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant
                        ? (e) => googleAnalyticSubHeaderHandleClick(e, card.featuredCta.label)
                        : ''
                    }
                  />

                  <AnchorLink
                    className="intro-link right-arrow"
                    link={{
                      href: card.secondaryFeaturedCta.href,
                      label: card.secondaryFeaturedCta.label,
                    }}
                    onClick={
                      variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant
                        ? (e) => googleAnalyticSubHeaderHandleClick(e, card.secondaryFeaturedCta.label)
                        : ''
                    }
                  />
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

const middleColumn = (data, variant, isDesktop) => {
  const { itemText, layoutRatio, navLinks } = data;
  const navLinksLen = (navLinks || []).length;
  let isHeaderYasisland;

  if (variant == 'v-header-swad') {
    isHeaderYasisland = variant === 'v-header-swad';
  } else {
    isHeaderYasisland = variant === 'v-header-yasisland';
  }
  const [headerAnalyticsCount, setheaderAnalyticsCount] = useState(0);

  if ((isHeaderYasisland || isMultiMegaNav(variant)) && navLinksLen && layoutRatio && isDesktop) {
    middleColumnFormattedArray = [[]];
    middleColumnDataFormatter(navLinks);
    return (
      <>
        {isHeaderYasisland && data.isSeparateColumn ? (
          <div className={`meganav--text-wrapper meganav-col-${data.navLinks.length} meganav--yas-main-navigation`}>
            {data.navLinks.map((navItemMain) => {
              return (
                <div className={`meganav--wrapper-column separate-columns`}>
                  <ul className="meganav--text-wrapper-list">
                    {/* Meganav Title */}
                    {navItemMain.ctaDetails?.label && (
                      <li className="meganav--text-wrapper-list-item">
                        <AnchorLink
                          className="body-3"
                          link={{
                            href: navItemMain.ctaDetails.href,
                            label: navItemMain.ctaDetails.label,
                          }}
                          onClick={
                            variant === UIConfig.commonVariant.headerVariant ||
                            variant === UIConfig.swadVariant.headerVariant
                              ? (e) => googleAnalyticSubHeaderHandleClick(e, navItemMain.ctaDetails.label)
                              : ''
                          }
                        />
                      </li>
                    )}
                    {navItemMain.ctaDetails === null && navItemMain?.label && (
                      <li className="meganav--text-wrapper-list-item">
                        <span className="body-3">{navItemMain.label}</span>
                      </li>
                    )}

                    {/* Meganav List */}
                    {navItemMain.linkData.map((itemData) => {
                      return (
                        itemData?.ctaDetail?.label && (
                          <li className="meganav--text-wrapper-list-item">
                            <AnchorLink
                              className=""
                              link={{
                                href: itemData.ctaDetail.href,
                                label: itemData.ctaDetail.label,
                              }}
                              onClick={(e) => {
                                let payload = {
                                  menuCategory: itemData.ctaDetail.label,
                                  menuCategory2: data?.itemText,
                                };
                                if (isMatchTenant(UIConfig?.tenants?.yi) && headerAnalyticsCount < 1) {
                                  setheaderAnalyticsCount(headerAnalyticsCount + 1);
                                  GTMData.push(UIConfig.ga4Constants.CLICK_HEADER, payload);
                                } else if (
                                  variant === UIConfig.commonVariant.headerVariant ||
                                  variant === UIConfig.swadVariant.headerVariant
                                ) {
                                  googleAnalyticSubHeaderHandleClick(e, itemData.ctaDetail.label);
                                  GTMData.push(UIConfig.ga4Constants.CLICK_HEADER, payload);
                                }
                              }}
                            />
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={`meganav--text-wrapper ${isHeaderYasisland && 'meganav--yas-main-navigation'}`}>
            {middleColumnFormattedArray.map((columnData, idx) => {
              return (
                <div className="meganav--wrapper-column" key={idx}>
                  <ul className="meganav--text-wrapper-list">
                    {columnData.map((item, index) => {
                      const titleLink = item.ctaDetails;
                      const listTitle =
                        (titleLink && (
                          <AnchorLink
                            className="body-3"
                            link={{
                              href: titleLink.href,
                              label: titleLink.label,
                            }}
                            onClick={
                              variant === UIConfig.commonVariant.headerVariant ||
                              variant === UIConfig.swadVariant.headerVariant
                                ? (e) => googleAnalyticSubHeaderHandleClick(e, titleLink.label)
                                : ''
                            }
                          />
                        )) ||
                        (item.label && <DynamicContent tagName="span" innerHtml={item.label} />);

                      return (
                        <li
                          key={index}
                          className={classNames(
                            'meganav--text-wrapper-list-item',
                            { 'body-3': item.type === 'middle-column-title' },
                            { 'middle-column-list-end': item.type === 'middle-column-last-item' },
                          )}
                        >
                          {item.type === 'middle-column-title'
                            ? listTitle
                            : item.ctaDetail && (
                                <AnchorLink
                                  link={{
                                    href: item.ctaDetail.href,
                                    label: item.ctaDetail.label,
                                    target: item.ctaDetail.target,
                                  }}
                                  onClick={
                                    variant === UIConfig.commonVariant.headerVariant ||
                                    variant === UIConfig.swadVariant.headerVariant
                                      ? (e) => googleAnalyticSubHeaderHandleClick(e, item.ctaDetail.label)
                                      : ''
                                  }
                                />
                              )}
                          {item.imageDetail && (
                            <div className="meganav--image-wrapper">
                              <Image image={item.imageDetail} disableLazyLoad={true} />
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  } else if (isHeaderYasisland && navLinksLen && layoutRatio && !isDesktop) {
    return <MegaNavAccord data={data} />;
  } else {
    return (
      <div className="meganav--text-wrapper">
        {(navLinks || []).map((item, idx) => (
          <div className="meganav--wrapper-column" key={idx}>
            {item.label && (
              <DynamicContent
                tagName="span"
                innerHtml={item.label}
                attrs={{
                  className: 'body-3',
                }}
              />
            )}
            <ul className="meganav--text-wrapper-list" role="menu" aria-label={itemText}>
              {(item.linkData || []).map((linkitem, idxx) => (
                <li key={idxx} className="meganav--text-wrapper-list-item" role="none">
                  {linkitem.ctaDetail && (
                    <AnchorLink
                      link={{
                        attrs: { role: 'menuitem' },
                        href: linkitem.ctaDetail.href,
                        label: linkitem.ctaDetail.label,
                        target: linkitem.ctaDetail.target,
                      }}
                      onClick={(e) => {
                        if (checkGA4Tenants([UIConfig.tenants.yi], [UIConfig.tenants.ya])) {
                          let payload = {
                            menuCategory: linkitem.ctaDetail.label,
                            menuCategory2: data?.itemText,
                          };
                          if (
                            isMatchTenant(UIConfig.tenants.ya) &&
                            linkitem?.ctaDetail?.target === UIConfig.ga4Constants.TARGET_BLANK
                          ) {
                            GTMData.push(UIConfig.ga4Constants.PARTNER_LINKS, {
                              click_text: linkitem?.ctaDetail?.label,
                              click_url: linkitem?.ctaDetail?.href,
                              section_name: data?.itemText,
                            });
                          } else {
                            GTMData.push(UIConfig.ga4Constants.CLICK_HEADER, payload);
                          }
                        }
                        (variant === UIConfig.commonVariant.headerVariant ||
                          variant === UIConfig.swadVariant.headerVariant) &&
                          googleAnalyticSubHeaderHandleClick(e, linkitem.ctaDetail.label);
                      }}
                    />
                  )}
                  {linkitem.imageDetail && (
                    <div className="meganav--image-wrapper">
                      <Image image={linkitem.imageDetail} disableLazyLoad={true} />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }
};

const lastColumn = (data, variant) => {
  const { featuredLinks, featuredTitle, featuredTitleCTA } = data;
  const isMultiMegaNavVariant = isMultiMegaNav(variant);
  const featuredTitleEl =
    (featuredTitleCTA && (
      <AnchorLink
        className="body-3"
        link={{
          href: featuredTitleCTA.href,
          label: featuredTitleCTA.label,
        }}
        onClick={
          variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant
            ? (e) => googleAnalyticSubHeaderHandleClick(e, featuredTitleCTA.label)
            : ''
        }
      />
    )) ||
    (featuredTitle && <DynamicContent tagName="span" attrs={{ className: 'body-3' }} innerHtml={featuredTitle} />);

  return (
    <div className={`meganav--featured-wrapper-list ${featuredTitleEl ? 'title' : ''}`}>
      {variant !== UIConfig.commonVariant.headerVariant &&
        variant !== UIConfig.swadVariant.headerVariant &&
        featuredTitleEl && <div className="featured-title">{featuredTitleEl}</div>}
      {featuredLinks.map((item, idx) => (
        <div className="meganav--featured-list-item" key={idx}>
          <div className="item-block">
            {variant !== UIConfig.commonVariant.headerVariant &&
              variant !== UIConfig.swadVariant.headerVariant &&
              item.featuredCta &&
              !isMultiMegaNavVariant && (
                <a
                  className="link"
                  href={item.featuredCta.href}
                  onClick={
                    variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant
                      ? (e) => googleAnalyticSubHeaderHandleClick(e, featuredTitleCTA.label)
                      : ''
                  }
                >
                  {item.featuredimage && (
                    <span className="featured-image">
                      <img src={item.featuredimage} alt={item.featuredCta.label} />
                    </span>
                  )}
                  {item.featuredtext && (
                    <DynamicContent
                      tagName="span"
                      attrs={{ className: 'featured-text' }}
                      innerHtml={item.featuredtext}
                    />
                  )}
                </a>
              )}
            {variant !== UIConfig.commonVariant.headerVariant &&
              variant !== UIConfig.swadVariant.headerVariant &&
              item.featuredCta &&
              isMultiMegaNavVariant && (
                <div className="link">
                  {item.featuredimage && (
                    <span className="featured-image">
                      <img src={item.featuredimage} alt={item.featuredCta.label} />
                    </span>
                  )}
                  {item.featuredtext && (
                    <DynamicContent
                      tagName="span"
                      attrs={{ className: 'featured-text' }}
                      innerHtml={item.featuredtext}
                    />
                  )}
                  <AnchorLink
                    className="btn-primary btn-link"
                    link={{
                      label: item.featuredCta.label,
                      href: item.featuredCta.href,
                    }}
                    onClick={
                      variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant
                        ? (e) => googleAnalyticSubHeaderHandleClick(e, item.featuredCta.label)
                        : ''
                    }
                  />
                </div>
              )}
            {(variant === UIConfig.commonVariant.headerVariant || variant === UIConfig.swadVariant.headerVariant) &&
              item.featuredCta && (
                <>
                  {variant === UIConfig.swadVariant.headerVariant ? (
                    <div className="featured-header">
                      {featuredTitle && (
                        <DynamicContent
                          tagName="span"
                          attrs={{ className: 'featured-title' }}
                          innerHtml={featuredTitle}
                        />
                      )}

                      {item.secondaryFeaturedCta.label && (
                        <AnchorLink
                          className="featured-link-cta"
                          link={{
                            href: item.secondaryFeaturedCta.href,
                            label: item.secondaryFeaturedCta.label,
                          }}
                          onClick={
                            variant === UIConfig.swadVariant.headerVariant
                              ? (e) => googleAnalyticSubHeaderHandleClick(e, item.secondaryFeaturedCta.label)
                              : ''
                          }
                        />
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="link">
                    {item.featuredtext && (
                      <DynamicContent
                        tagName="span"
                        attrs={{ className: 'featured-text' }}
                        innerHtml={item.featuredtext}
                      />
                    )}
                    {item.featuredimage && (
                      <span className="featured-image">
                        <img src={item.featuredimage} alt={item.featuredCta.label} />
                      </span>
                    )}

                    <CTAButton
                      ctaButton={item.featuredCta}
                      type="iconCta"
                      onClick={
                        variant === UIConfig.commonVariant.headerVariant ||
                        variant === UIConfig.swadVariant.headerVariant
                          ? (e) => googleAnalyticSubHeaderHandleClick(e, item.featuredCta.label)
                          : ''
                      }
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

const MegaNavVariant = ({ data, className, variant }) => {
  try {
    if (!data) {
      return null;
    }

    const { featuredLinks, introData, layoutRatio } = data;
    const gridRatio = layoutRatio ? layoutRatio.split('-') : [];
    const [isDesktop, setDesktopView] = useState(false);

    useEffect(() => {
      if (!detectMobile()) {
        setDesktopView(true);
      }
    }, []);

    return (
      <div className={`meganav ${variant} meganav-'${className || ''}`}>
        <div className={`meganav--main-wrapper ${featuredLinks.length !== 1 ? 'full-width' : ''}`}>
          {variant !== UIConfig.commonVariant.headerVariant &&
          variant !== UIConfig.swadVariant.headerVariant &&
          introData &&
          layoutRatio &&
          isDesktop ? (
            <div className="meganav--intro-wrapper" style={{ width: +gridRatio[0] + '%' }}>
              {firstColumn(data)}
            </div>
          ) : (
            isMultiMegaNav(variant) && firstColumn(data, variant)
          )}
          {layoutRatio && isDesktop ? (
            <div
              className={`meganav--text-wrapper--parent ${featuredLinks.length === 1 ? 'two-column' : ''}`}
              style={{ width: +gridRatio[1] + '%' }}
            >
              {middleColumn(data, variant, isDesktop)}
            </div>
          ) : (
            middleColumn(data, variant, isDesktop)
          )}
          {featuredLinks && layoutRatio && isDesktop && (
            <div
              className={`meganav--featured-wrapper ${featuredLinks.length > 3 ? 'enable-two-column' : ''} ${
                featuredLinks.length === 1 ? 'two-column' : ''
              } ${featuredLinks.length ? '' : 'no-featured'}`}
              style={{ width: +gridRatio[2] + '%' }}
            >
              {lastColumn(data, variant)}
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'MegaNavVariant');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
MegaNavVariant.propTypes = {
  data: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

export default MegaNavVariant;
