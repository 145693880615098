import React from 'react';
import { canUseDOM } from '../../../../common/utility';
import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';

/* Bind overlay to all anchor tag having class 'custom-anchor-in-modal' */
export const bindModalToAnchorLink = () => {
  try {
    const elements = document.getElementsByClassName('custom-anchor-in-modal');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        canUseDOM() &&
          window.PubSub.publish('toggleOverlayState', {
            shouldOpen: true,
            customClass: elements[i].getAttribute('data-custom-class'),
            dataToAppend: (
              <div className="custom-anchor-container">
                <h2 className="heading-3">{elements[i].getAttribute('data-title')}</h2>
                <DynamicContent
                  tagName="div"
                  attrs={{
                    className: 'body-copy-2',
                  }}
                  innerHtml={elements[i].getAttribute('data-description')}
                />
              </div>
            ),
          });
      });
    }
  } catch (err) {
    return logComponentRenderingError(err, 'bindModalToAnchorLink');
  }
};
