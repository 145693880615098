/**
 * tabs-filter-listing-component.js
 * This file contains the code of the parent component for filters and listing
 * @author      SapientRazorfish
 * @license     Miral
 */

import moment from 'moment';

import UICONFIG from '../../../common/UIConfig';
import { canUseDOM, putElementInViewport, getProdIdType, momentTimezone } from '../../../common/utility';
import { secProds } from './../b2c-purchase-journey/add-ons/add-ons-utility.js';

export const CONFIG = {
  startDateOperator: '>=',
  dateRangeType: 'DateRangeFilter',
};

const Mapper = {
  html: 'html',
  label: 'label',
  href: 'href',
  target: 'target',
  tabList: 'tabList',
  variation: 'variation',
  ctaLink: 'ctaLink',
  enableDropDownOnMobile: 'enableDropDownOnMobile',
  groupBy: 'groupBy',
  cta: 'cta',
  src: 'src',
  featureImage: 'featureImage',
  field: 'field',
  iconData: 'iconData',
  all: 'All',
  tabsData: 'tabsData',
  alt: 'alt',
  totalCount: 'totalCount',
  desktopImage: 'desktopImage',
  mobileImage: 'mobileImage',
  tabletImage: 'tabletImage',
  listType: 'listType',
  buyNowCtaLabel: 'buyNowCtaLabel',
  soldOutCtaLabel: 'soldOutCtaLabel',
};

export const setFieldTypeData = (type, key, expression, facetObj, calenderObj) => {
  if (type === 'ToggleButton') {
    facetObj.current = expression[key];
  } else if (type === 'DateFilter' || type === 'DateRangeFilter') {
    calenderObj.current = expression[key];
  }
};

export const setSliderData = (key, expression, obj, sliderObj, venueObj, variant) => {
  const expressionKey = `@${expression[key]}`;
  if (key !== 'VenueFilter' && key.indexOf('YMC') === -1 && obj[key] && obj[key].toLowerCase() !== 'no-filter') {
    sliderObj.current[expressionKey] = obj[key];
  } else if (key === 'VenueFilter' && obj[key] && obj[key].toLowerCase() !== 'no-filter') {
    venueObj.current[expressionKey] = obj[key];
  } else if (
    obj[key] &&
    (variant === 'v-venue-find-filter' || variant === 'v-event-filter' || variant === 'v-f1-multi-filter')
  ) {
    obj[key].toLowerCase() !== 'no-filter'
      ? (venueObj.current[expressionKey] = obj[key])
      : delete venueObj.current[expressionKey];
  } else if (sliderObj.current.hasOwnProperty(expressionKey) || key === 'VenueFilter') {
    delete sliderObj.current[expressionKey];
    delete venueObj.current[expressionKey];
  }
};

export const scrollTabMobileViewPort = () => {
  setTimeout(() => {
    const activeElement = document.querySelector('.tabs-menu--links.is-active');
    activeElement && putElementInViewport(activeElement);
  }, 0);
};

/**
 * get tab index by location hash
 * @param {[string]} location hash
 */
export const getTabIndexByHash = (tabData, variant) => {
  if (
    canUseDOM() &&
    variant !== 'v-yasisland-filter' &&
    variant !== 'v-venue-find-filter' &&
    variant !== UICONFIG.eventListingFilter.yaFilterVariant
  ) {
    return tabData.findIndex(
      (item) => item.type && item.type.toString() === decodeURIComponent(window.location.hash.substring(1)),
    );
  }
};

/**
 * dataMashUp  function extracts the required data from coveo results.
 *               The results are in the 'raw' object whereas the tabs are in 'groupByResults'
 * @param    {[Object]} res [response of the coveo].
 * @return   {[Object]} contentResults [the extracted data from  coveo response as per the requirement]
 */
export const dataMashUp = (res, data, tabsTitle) => {
  const { buyNowLabel, contentType, coveoSettings, ctaLabel, enablePJPopup, soldoutText, sortByDate, variant } = data;

  if (sortByDate) {
    res.results = sortResultsbyDate(res.results, coveoSettings);
  }

  const mainObj = {};
  const allData = {};
  const contentResults = {};

  allData[Mapper.all] = [];
  res.groupByResults.forEach((item) =>
    item.values.forEach((value) => {
      mainObj[value.value.toLowerCase()] = [];
    }),
  );

  res.results.forEach((v) => {
    const coveoKeyMap = coveoSettings.coveoKeyMap;
    const element = {};
    const cta = {};
    const featureImage = {};
    const desktopImage = {};

    // creating cta object
    if (v.raw[coveoKeyMap.ctahref]) {
      cta[Mapper.href] = v.raw[coveoKeyMap.learnMoreUrl] ?? v.raw[coveoKeyMap.ctahref];
      cta[Mapper.label] = ctaLabel;
    }

    if (
      (variant === 'v-product-list-filter' || variant === 'v-f1-multi-filter') &&
      !enablePJPopup &&
      v.raw[coveoKeyMap.buybutton]
    ) {
      element[Mapper.buyNowCtaLabel] = buyNowLabel;
    }

    if ((variant === 'v-product-list-filter' || variant === 'v-f1-multi-filter') && enablePJPopup && buyNowLabel) {
      element[Mapper.buyNowCtaLabel] = buyNowLabel;
    }

    if (variant === 'v-f1-multi-filter' && soldoutText) {
      element[Mapper.soldOutCtaLabel] = soldoutText;
    }

    //added temporarily since ctahref is not coming and therefore the condition mentioned above will fail
    cta[Mapper.label] = ctaLabel;

    // creating featureImage object
    desktopImage[Mapper.src] = v.raw[coveoKeyMap.imageUrl] || '';
    desktopImage[Mapper.alt] = featureImage['imageAltText'] = v.raw[coveoKeyMap.imageAlt] || '';
    featureImage[Mapper.desktopImage] = desktopImage;
    featureImage[Mapper.mobileImage] = desktopImage;
    featureImage[Mapper.tabletImage] = desktopImage;

    //mapping each key value of coveoKeyMap to the response of the object
    for (const key in coveoKeyMap) {
      if (key === Mapper.iconData) {
        element[key] = v.raw[coveoKeyMap[key]] ? JSON.parse(v.raw[coveoKeyMap[key]]) : null;
      } else {
        element[key] = v.raw[coveoKeyMap[key]];
      }
    }

    // pushing cta and featureImage object to each element
    element[Mapper.cta] = cta;
    element[Mapper.featureImage] = featureImage;
    element[Mapper.listType] = contentType;

    if (v.raw[coveoKeyMap.groupBy]) {
      if (Array.isArray(v.raw[coveoKeyMap.groupBy])) {
        v.raw[coveoKeyMap.groupBy].forEach((item) => {
          mainObj[item.trim().toLowerCase()].push(element);
          if (!tabsTitle.current[item.toLowerCase()]) {
            tabsTitle.current[item.toLowerCase()] = element.groupByLabel;
          }
        });
      } else {
        mainObj[v.raw[coveoKeyMap.groupBy].toLowerCase()].push(element);
        if (!tabsTitle.current[v.raw[coveoKeyMap.groupBy].toLowerCase()]) {
          tabsTitle.current[v.raw[coveoKeyMap.groupBy].toLowerCase()] = element.groupByLabel;
        }
      }
    }
    allData[Mapper.all].push(element);

    contentResults[Mapper.all] = allData;
    contentResults[Mapper.tabsData] = mainObj;
    contentResults[Mapper.totalCount] = res.totalCount;
  });
  return contentResults;
};

export const getProductTabType = (categoryType, coveoData, activeProdId) => {
  let filterProduct = {};
  if (coveoData.tabList && coveoData.tabList.length) {
    coveoData.tabList.forEach((item) => {
      if (!Object.keys(filterProduct).length && item.listing && item.listing.length) {
        filterProduct = item.listing.find((item) => item.productid === activeProdId.current) || {};
      }
    });
  }
  if (filterProduct.categoryKey && filterProduct.categoryKey.length) {
    categoryType = filterProduct.categoryKey[0];
  }
  return categoryType;
};

/**
 * caculate "to" date of on the basis of product type and from date
 * @param {Object} date contains date reated data like flexible, from, range
 * @param {object} prod product for which to date calculated
 */
const calculateToDate = (prod, mupValidityMap) => {
  const to = moment(prod.fromDate).clone();
  if (prod.validUntil) {
    return moment(prod.validUntil)
      .locale('en')
      .format(UICONFIG.calendar.dateFormat);
  } else if (prod.isFlexible) {
    to.add(prod.rangeInMonths, 'month');
    to.subtract(1, 'days');
  } else if (prod.itemType === 'MUP' && mupValidityMap && mupValidityMap.hasOwnProperty(prod.accessPolicy)) {
    to.add(mupValidityMap[prod.accessPolicy] - 1, 'day');
  }
  return parseInt(prod.validUntilDate) ? prod.toDate : to.locale('en').format(UICONFIG.calendar.dateFormat);
};

/**
 * this function will add the keys to products which would be needed for cart and checkbasket
 * @param {Object} products array of the products to be updated
 * @param {Object} data selection made in tabs
 */
export const enrichProductData = (products, mupValidityMap, groupingNotRequired, isPackageJourney = false) => {
  return products.map((prod) => {
    if (
      prod &&
      prod.itemType &&
      (prod.itemType === UICONFIG.moneyCardProduct.ticketType ||
        prod.itemType === UICONFIG.moneyCardProduct.flexibleTicketType)
    ) {
      prod.fromDate = '';
      prod.toDate = '';
    } else if (prod.fromDate) {
      prod.isFlexible = prod.isFlexible || prod.accessPolicy === '1EAD'; //added to identify flexible prod
      prod.toDate = calculateToDate(prod, mupValidityMap);
    } else {
      prod.fromDate = '';
      prod.toDate = '';
    }
    prod.productType =
      prod.hasOwnProperty('packageGroupCode') && prod.packageGroupCode !== null && typeof prod.tab !== 'string'
        ? prod.tab[0]
        : prod.tab; // added a tab category to find out prduct tab category
    prod.productIdType = getProdIdType(prod, groupingNotRequired.current);
    prod.price = {};
    prod.price.gross = prod.gross || 0;
    prod.price.net = prod.net || 0;
    prod.price.tax = prod.tax || 0;
    prod.discount = prod.discount || {}; //for checkbasket
    prod.discount.actualPerc = prod.discount.actualPerc || 0; //for checkbasket
    prod.discount.computed = prod.unitPrice ? prod.unitPrice - prod.gross : 0;
    prod.unitPrice = prod.unitPrice || prod.gross;
    prod.isPackageJourney = isPackageJourney || '';

    delete prod['description']; // Delete description for CART API.

    return prod;
  });
};

/**
 * currently returning performance id at 0th position as there is ony
 * 1 time slot
 */
export const getPerformanceId = (list, singleSlot) => {
  if (singleSlot && list && list.length) {
    return list[0].sellable && list[0].performanceId;
  }
};

/**
 * extract discount code from discount map provided
 * @param {Number} days days in advance for which booking is done
 * @param {Object} discountMap map containg details of discount against the range of days
 */
export const getDiscount = (days, discountMap) => {
  for (let i = 0; i < discountMap.length - 1; i++) {
    if (days >= discountMap[i].days && days < discountMap[i + 1].days) {
      return discountMap[i];
    }
  }
  return discountMap[discountMap.length - 1];
};

export const updateCouponCodeData = (cart, cartActions) => {
  if (cart && cart.hasOwnProperty('couponCode') && cart.couponCode) {
    cartActions.current._storeExtraCartParams(cart);
  }
};

export const getCategoryProds = (category, products) => {
  return products.filter((product) => {
    if (!product.category || !category) {
      return false;
    }
    if (product.category.toString() === category.toString()) {
      return product;
    }
    return false;
  });
};

export const updateSecProdArray = (prod, type, map) => {
  secProds[type] = secProds[type] || {};
  switch (prod.experienceCatgory) {
    case map.secondary:
      secProds[type].secondary = secProds[type].secondary || [];
      secProds[type].secondary.push(prod);
      break;
    case map.addOnGuest:
      secProds.addOnGuest = secProds.addOnGuest || [];
      secProds.addOnGuest.push(prod);
      break;
    case map.primary:
      secProds[type].primary = secProds[type].primary || [];
      secProds[type].primary.push(prod);
      break;
    default:
      break;
  }
};

/**
 * change category key to String
 * @param {Object} err error object
 */
export const changeCategoryToString = (prods, coveoDefaultParams) => {
  const tabKey = coveoDefaultParams.current.coveoKeyMap['tab'];
  return prods.map((product) => {
    if (product[tabKey] && product[tabKey] instanceof Array) {
      product[tabKey] = product[tabKey].sort().join('');
    }
    return product;
  });
};

/**
 * createTabData  function creates the final data to be passed to the TabsFeatureList component
 * @param    {[Object]} data [object that has the refined response of coveo].
 * @return   {[Object]} finalData [final data object that is set in the state and passed as props to component]
 */
export const createTabData = (contentResults, data, groupByTabs) => {
  const {
    ctaLink,
    enableDropDownOnMobile,
    gridVariation,
    hideViewMoreLabel,
    redirectCta,
    theme,
    tilesToShowInitially,
    viewMoreLabel,
    variant,
  } = data;

  const finalData = {};
  const tabData = groupByTabs(contentResults);
  finalData[Mapper.tabList] = tabData;
  finalData[Mapper.variation] = theme;
  finalData[Mapper.ctaLink] = ctaLink;
  finalData[Mapper.enableDropDownOnMobile] = enableDropDownOnMobile;
  finalData.tilesToShowInitially = tilesToShowInitially;
  finalData.hideViewMoreLabel = hideViewMoreLabel;
  finalData.viewMoreLabel = viewMoreLabel;
  finalData.redirectCta = redirectCta;
  finalData.gridVariation = gridVariation;
  finalData.variant = variant;
  const tabIndex = getTabIndexByHash(tabData, variant) || 0;
  finalData.activeTab = tabIndex !== -1 ? tabIndex : 0;
  return finalData;
};

export const noFilter = (calenderObj, getCoveoData, endDate) => {
  const updateObj = {};
  calenderObj.current = {};
  calenderObj.current['toDate'] = `@${endDate}${CONFIG.startDateOperator}${moment
    .utc()
    .locale('en')
    .format(UICONFIG.calendar.slashDateFormat)}`;
  updateObj[CONFIG.dateRangeType] = calenderObj.current;
  getCoveoData({}, updateObj, CONFIG.dateRangeType);
};

const sortResultsbyDate = (results, coveoSettings) => {
  const coveoKeyMap = coveoSettings.coveoKeyMap;
  const nextEventDate = 'nextEventDate';
  const sortCriteria = coveoKeyMap.sortCriteria ? coveoKeyMap.sortCriteria.replace(/[0-9]/g, '') : null;
  let featuredResults = [];
  let notFeaturedResults = [];

  results.forEach((item) => {
    const itemStartDate = item.raw[coveoKeyMap.startDate];
    const eventStartDate = itemStartDate
      ? moment
          .unix(momentTimezone(itemStartDate).format('X'))
          .startOf('day')
          .format('X')
      : itemStartDate;

    if (item.raw[coveoKeyMap.recurring] === '1' && item.raw[coveoKeyMap.eventDates]) {
      item.raw[nextEventDate] = eventStartDate;
      let nextEventDateAvailable = false;
      const datesArr = JSON.parse(item.raw[coveoKeyMap.eventDates]);
      datesArr.forEach((eventDate) => {
        const upcomingEventDate = moment
          .unix(eventDate)
          .startOf('day')
          .format('X');
        if (
          upcomingEventDate >=
            moment()
              .startOf('day')
              .format('X') &&
          !nextEventDateAvailable
        ) {
          item.raw[nextEventDate] = upcomingEventDate;
          nextEventDateAvailable = true;
        }
      });
    } else {
      item.raw[nextEventDate] = eventStartDate;
    }
    if (item.raw[coveoKeyMap.featured] === '1') {
      featuredResults.push(item);
    } else {
      notFeaturedResults.push(item);
    }
  });

  if (sortCriteria === 'fielddescending') {
    notFeaturedResults = notFeaturedResults.sort((item1, item2) => {
      return item1.raw[nextEventDate] > item2.raw[nextEventDate]
        ? -1
        : item1.raw[nextEventDate] < item2.raw[nextEventDate]
        ? 1
        : 0;
    });

    featuredResults = featuredResults.sort((item1, item2) => {
      return item1.raw[nextEventDate] > item2.raw[nextEventDate]
        ? -1
        : item1.raw[nextEventDate] < item2.raw[nextEventDate]
        ? 1
        : 0;
    });
  } else {
    notFeaturedResults = notFeaturedResults.sort((item1, item2) => {
      return item1.raw[nextEventDate] < item2.raw[nextEventDate]
        ? -1
        : item1.raw[nextEventDate] > item2.raw[nextEventDate]
        ? 1
        : 0;
    });

    featuredResults = featuredResults.sort((item1, item2) => {
      return item1.raw[nextEventDate] < item2.raw[nextEventDate]
        ? -1
        : item1.raw[nextEventDate] > item2.raw[nextEventDate]
        ? 1
        : 0;
    });
  }

  return featuredResults.concat(notFeaturedResults);
};
