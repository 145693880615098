import React, { useEffect } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai, setAppInsights } from '../../../common/telemetry-service';
import { withRouter } from 'react-router-dom';
import { isEnvDevelopment } from '../../../common/utility';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
const TelemetryProvider = (props) => {
  setAppInsights(props.appInsights);

  useEffect(() => {
    const { history } = props;
    if (Boolean(history) && !isEnvDevelopment()) {
      ai.initialize(history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { children } = props;
  return <>{children}</>;
};

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
