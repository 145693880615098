import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PlayerService from './player';
import {
  detectMobile,
  isHtmlVideoSupported,
  detectViewPort,
  checkIfParks,
  isArabicMode,
  checkTenant,
} from '../../../common/utility';
import { Image, LazyLoad, PlayButton } from '../base';
import { logComponentRenderingError } from '../../../common/logger';

import './video-component.scss';
import UIConfig from '../../../common/UIConfig';

/**
 * Video Component Class which creates video player on page.
 * @param    {Void} Class dpes not accept any parants.
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */

const Video = (props) => {
  const isSmartHeroCarousel = props.isSmartHeroCarousel || false;
  const config = useRef({});
  config.current = { ...props.data, ...props };
  config.current.autoplay = props.active && config.current.autoplay;
  config.current.muted = config.current.muted || config.current.autoplay;
  config.current.playsInline = true;

  const viewport = detectViewPort();
  const { youtubeHeight } = props.data;
  config.current.height =
    youtubeHeight &&
    (viewport === 'mobile'
      ? youtubeHeight.mobileHeight
      : viewport === 'tablet'
      ? youtubeHeight.tabletHeight
      : youtubeHeight.desktopHeight);

  const isMediaLibrary = props.data.type === 'media';

  const showVideoImage = !!config.current.imageUrl;
  const validVideoId = !!(config.current.videoId || isMediaLibrary);
  const showCurve = config.current.variant !== 'default';
  const html5VideoSupported = isHtmlVideoSupported();

  const [showVideo, setShowVideo] = useState(!(config.current.imageUrl || !config.current.videoId));
  const [videoStatus, setVideoStatus] = useState(false);
  const [showCtlr, setShowCtlr] = useState(false);
  const [disableControls, setDisableControls] = useState(true);

  const playerContainer = useRef('');
  const player = useRef('');
  const htmlPlayer = useRef('');

  const isFirstRun = useRef(true);

  useEffect(() => {
    setShowCtlr(props.showControls);
    setTimeout(() => {
      playActiveVid();
    }, 0);
  }, [props.showControls]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      const videoStatus = props.active ? 'play' : 'pause';

      switch (videoStatus) {
        case 'pause':
          videoPause();
          break;
        case 'stop':
          videoStop();
          break;
        case 'play':
        default:
          videoPlay();
      }
    }
  }, [props.active]);
  useEffect(() => {
    if (isSmartHeroCarousel)
      setTimeout(() => {
        setDisableControls(false);
      }, 2000);
  }, []);

  useEffect(() => {
    if (!isMediaLibrary) {
      const videoPlayer = PlayerService(config.current, onPlayerStateChange);

      if (videoPlayer && videoPlayer.sdk) {
        const params = videoPlayer.params;
        videoPlayer.sdk.then((PL) => {
          player.current = new PL.Player(playerContainer.current, params.OPTIONS);

          //Specific check for binding external binding as vimeo doesnt take event with params
          if (params.GLOBAL === 'Vimeo') {
            bindVimeoEvents(player.current);
          }
        });
      }
    }
    isHtmlVideoSupported &&
      htmlPlayer &&
      htmlPlayer.current &&
      (htmlPlayer.current.muted = config.current.muted || config.current.autoplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playActiveVid = () => {
    const allActiveVideo = document.querySelectorAll(
      '.smart-hero-carousel-view .slick-slide.slick-active[data-index]:not([data-index="-1"]) .c-video-player.video-autoplay video',
    );
    let activeVideo;
    if (allActiveVideo.length > 1 && isArabicMode()) {
      activeVideo = allActiveVideo?.[1];
    } else {
      activeVideo = allActiveVideo?.[0];
    }
    if (activeVideo) {
      activeVideo.play();
    }
  };

  const setHtmlPlayerAttribute = () => {
    if (isMediaLibrary) {
      htmlPlayer.current.setAttribute('controlslist', 'nodownload');
    }
  };

  const onPlayerStateChange = (e) => {
    switch (e.data) {
      case 1:
        onPlay(e);
        break;

      case 2:
        onStop(e);
        break;

      default:
        break;
    }
  };

  const videoPlay = () => {
    if (player.current) {
      config.current.muted && typeof player.current.mute === 'function' && player.current.mute();
      typeof player.current.playVideo === 'function' && player.current.playVideo();
    } else if (htmlPlayer.current) {
      htmlPlayer.current.play();
    }
  };

  const videoStop = () => {
    player.current && typeof player.current.stopVideo === 'function' && player.current.stopVideo();
  };

  const videoPause = () => {
    if (player.current) {
      typeof player.current.pauseVideo === 'function' && player.current.pauseVideo();
    } else if (htmlPlayer.current) {
      htmlPlayer.current.pause();
    }
  };

  /**
   * Player events bind if passed as props.
   */
  const onPlay = (e) => {
    if (typeof config.current.onPlay === 'function') {
      config.current.onPlay(e);
    }
  };

  const onStop = (e) => {
    if (config.current.onStop === 'function') {
      config.current.onStop(e);
    }
  };

  const playVideo = (e) => {
    e.preventDefault();

    if (showCurve) {
      const showVideoTimeout = setTimeout(() => {
        clearTimeout(showVideoTimeout);
        setShowVideo(true);
        videoPlay();
      }, 1000);
    } else {
      setShowVideo(true);
      videoPlay();
    }
  };

  const bindVimeoEvents = (vimeoPlayer) => {
    vimeoPlayer.on('play', onPlay);
    vimeoPlayer.on('stop', onStop);
    vimeoPlayer.ready().then(function() {
      if (config.current.onReady === 'function') {
        config.current.onReady(vimeoPlayer);
      }
    });
  };

  const onVideoClick = () => {
    videoStatus ? videoPause() : videoPlay();
    setVideoStatus(!videoStatus);
  };

  /**
   * Render video container
   * @param    {video id} take from props.
   */
  const renderVideoPLayer = () => {
    return <div ref={playerContainer} />;
  };

  const renderVideoTag = () => {
    const isParks = checkIfParks() || checkTenant(UIConfig.iamMapping.clymb) || checkTenant(UIConfig.iamMapping.ppad);
    const settings = {
      autoPlay: config.current.autoplay,
      controls: (isParks && 0) || (disableControls && isSmartHeroCarousel) ? 0 : config.current.controls,
      muted: config.current.muted || config.current.autoplay,
      loop: config.current.loop,
      playsInline: config.current.playsInline,
    };

    const { videoUrl, lazyLoadedImageInfo } = props.data;
    let url = videoUrl;
    if (config.current.startTime || config.current.endTime) {
      url = `${url}#t=`;
      if (config.current.startTime) {
        url = `${url}${config.current.startTime}`;
      }

      if (config.current.endTime) {
        url = `${url},${config.current.endTime}`;
      }
    }

    return html5VideoSupported ? (
      props.data.isLazyLoad ? (
        <LazyLoad
          placeholder={
            lazyLoadedImageInfo && lazyLoadedImageInfo.desktopImage.src && <Image image={lazyLoadedImageInfo} />
          }
          callback={setHtmlPlayerAttribute}
        >
          <video {...settings} key={url} ref={htmlPlayer} {...(isParks && { controls: showCtlr })}>
            <source src={url} />
          </video>
        </LazyLoad>
      ) : (
        <video {...settings} key={url} ref={htmlPlayer} {...(isParks && { controls: showCtlr })}>
          <source src={url} />
        </video>
      )
    ) : (
      <Image image={props.imageInfo} disableLazyLoad={true} />
    );
  };

  const renderVideo = () => {
    const isDefaultAutoplay = props?.data?.autoplay || false;
    return (
      <div
        className={`c-video-player ${
          (isSmartHeroCarousel || checkTenant(UIConfig.iamMapping.etihadarena)) && isDefaultAutoplay
            ? 'video-autoplay'
            : ''
        }`}
        onClick={props.setShowControls}
      >
        {isMediaLibrary ? renderVideoTag() : renderVideoPLayer()}
        {!isMediaLibrary && props.videoData && props.videoData.isSwipable && detectMobile() && (
          <div className="video-overlay" onClick={onVideoClick} />
        )}
      </div>
    );
  };

  /**
   * Render error container if no video id is supplied as props
   * @param    {video id} take from props.
   */
  const noVideoId = () => {
    if (!validVideoId) {
      return <div className="c-video-novideo">Not valid video id</div>;
    }
  };

  /**
   * Render video thumbnail image
   * @param    {image url} take from props.
   * @param    {image alt} take from props.
   */
  const renderImage = () => {
    const { imageUrl, imageAlt, videoId } = config.current;
    if (!showVideo && showVideoImage) {
      return (
        <div className="c-video-image">
          <a href={videoId} onClick={playVideo}>
            <img src={imageUrl} alt={imageAlt} />
            <PlayButton />
          </a>
        </div>
      );
    }
  };

  try {
    return (
      <div className={classNames('c-video', { 'hide-opacity': config.current.isOpacityRemove })}>
        {renderImage()}
        {renderVideo()}
        {noVideoId()}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Video');
  }
};

Video.propTypes = {
  data: PropTypes.shape({
    videoId: PropTypes.string,
    type: PropTypes.oneOf(['youtube', 'vimeo', 'media']),
    imageUrl: PropTypes.string,
    variant: PropTypes.string,
    imageAlt: PropTypes.string,
    autoPlay: PropTypes.bool,
    onPlay: PropTypes.func,
    onStop: PropTypes.func,
    onReady: PropTypes.func,
  }),
};

export default Video;
