/*
 * input-range-components.js
 * This file contains code for input range component which will render input range based on schema.
 * @licensor  Miral
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isSelectedLanguageArabic, checkTenant, detectMobile } from '../../../../common/utility';
import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';
import './input-range-component.scss';
import UIConfig from '../../../../common/UIConfig';

const InputRange = (props) => {
  const optionsLength = props.data.options ? props.data.options.length : 0;
  const liWidth = optionsLength ? 100 / (optionsLength - 1) : 0;
  const isMobile = detectMobile();
  const [sliderValue, setSliderValue] = useState({
    value: props.data.value || 1,
    ticks: props.data.options || [],
    liWidth: liWidth,
  });

  const [direction, setDirection] = useState('left');
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  useEffect(() => {
    setSliderValue({ ...sliderValue, value: props.data.value || 1, ticks: props.data.options || [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reset]);

  useEffect(() => {
    setDirection(isSelectedLanguageArabic() ? 'right' : 'left');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (event) => {
    setSliderValue({ ...sliderValue, value: event.target.value });

    typeof onChange === 'function' &&
      props.onChange({
        type: 'range',
        value: props.data.options[event.target.value - 1].value,
        name: props.data.name,
        toFilter: event.target.dataset.filter,
      });
  };

  const renderTicks = () => {
    const constant = sliderValue.ticks.length >= 5 ? '3px' : '6px';
    return (
      <ul
        className="tickList"
        style={{
          [direction]:
            direction === 'left'
              ? `calc(-${sliderValue.liWidth / 2}% + ${constant})`
              : `calc(${sliderValue.liWidth / 2}%)`,
        }}
      >
        {sliderValue.ticks.map((tick, index) => {
          const active = parseInt(sliderValue.value, 10) === index + 1 ? 'active' : '';
          return isMobile ? (
            <li className={`ticks ${active}`} style={{ width: `${sliderValue.liWidth}%` }} key={index}></li>
          ) : (
            <DynamicContent
              tagName="li"
              innerHtml={tick.text}
              key={`${tick.value}_${index}`}
              attrs={{
                className: `ticks ${active}`,
                style: { width: `${sliderValue.liWidth}%` },
              }}
            />
          );
        })}
      </ul>
    );
  };

  const renderTooltip = () => {
    return (
      <DynamicContent
        tagName="div"
        attrs={{ className: 'range-tooltip body-2' }}
        innerHtml={sliderValue.ticks[sliderValue.value - 1].text}
      />
    );
  };

  try {
    // resetFlag = !resetFlag;
    return (
      <div className="form-element range-container">
        <div className="track"></div>
        <label htmlFor={props.data.id}></label>
        <input
          className={`form-input-range ${props.data.class}`}
          name={props.data.name}
          type="range"
          data-filter={props.data.fieldToConfirm}
          value={sliderValue.value}
          {...(isSwad && {
            style: {
              background: `linear-gradient(to right, #3993FA 0%, #3993FA ${(sliderValue.value - 1) *
                liWidth}%, rgba(88, 88, 88, 0.5) ${(sliderValue.value - 1) * liWidth}%, rgba(88, 88, 88, 0.5) 100%)`,
            },
          })}
          id={props.data.id}
          onChange={onChange}
          min="1"
          max={sliderValue.ticks.length}
          aria-valuemin="1"
          aria-valuemax={sliderValue.ticks.length}
          aria-valuenow={sliderValue.value}
          aria-valuetext={sliderValue.ticks[sliderValue.value - 1].text}
          aria-label={props.data.label}
          data-testid="rtl_Id_slider"
        />
        {renderTicks()}
        {isMobile ? renderTooltip() : ''}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'InputRange');
  }
};

InputRange.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

export default InputRange;
