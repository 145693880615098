/*
 * input-file-component.js
 * This file contains code for input component which will render file input based on schema.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';

import { DynamicContent, SvgSprite } from '..';
import { detectViewPort, checkTenant, detectMobile, isANPToANPUpgradation } from '../../../../common/utility';
import Tooltip from '../../tooltip/tooltip-component';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import classNames from 'classnames';

const InputFile = ({ onChange, data, tabIndex, isRenewAnnualPass }) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const renderProfileTooltip = () => {
    const toolTip = data.infoText;

    return (
      <div className="c-annual-pass-tooltip">
        <Tooltip
          onHover={true}
          wrapperClass="header-bottom-profile"
          buttonClass="profile"
          buttonInnerHtml={
            <div>
              <SvgSprite id={'photo_upload_tooltip'} cssClass="tooltip-icon" />
            </div>
          }
          tabIndex={tabIndex}
          ariaLabel={toolTip}
        >
          <div className="ticket-tooltip-body">
            <DynamicContent tagName="div" innerHtml={toolTip} attrs={{ className: 'description' }} />
          </div>
        </Tooltip>
      </div>
    );
  };

  const renderLabelText = () => {
    let className, text;
    if (detectViewPort() === 'mobile') {
      className = 'mobile-text';
      text = data.mobileText;
    } else {
      className = 'desktop-text';
      text = data.desktopText;
    }

    return <span className={className}>{text}</span>;
  };

  const onPhotoKeyChange = (e, id) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const parent = document.getElementById(id);
      parent && parent.click();
    }
  };

  const onKeypress = (event) => {
    if (event.key === 'Enter') {
      onChange('deletePhoto', data);
    }
  };
  const ExampleImages = () => (
    <div className="example-image-section">
      <p className="example-header">{data.photoExamplesLabel}</p>
      <div className="images">
        {data?.maleFallbackImage?.src && (
          <img src={data.maleFallbackImage.src} alt={data.maleFallbackImage.alt || ''} />
        )}
        {data?.femaleFallbackImage?.src && (
          <img src={data.femaleFallbackImage.src} alt={data.femaleFallbackImage.alt || ''} />
        )}
      </div>
    </div>
  );

  try {
    let renderSection;

    if (isRenewAnnualPass || isANPToANPUpgradation()) {
      renderSection = <></>;
    } else {
      renderSection = (
        <div className="placeholder-photo-upload">
          <div
            id={`photo-upload-section_${data.id}`}
            className="photo-upload"
            tabIndex={tabIndex ? tabIndex : 0}
            {...(!data.value && { onKeyDown: (e) => onPhotoKeyChange(e, data.id) })}
          >
            {!data.value && (
              <input type={data.type} id={data.id} name={data.name} onChange={onChange} tabIndex="-1" title="" />
            )}
            <label
              htmlFor="file_input"
              className={classNames({
                'required-field-error': data.photoError,
              })}
            >
              {data.value ? (
                <>
                  <img src={data.value.fileContent} alt={data.value.fileName} className="user-image" />
                  <span
                    id={`delete-photo_${data.id}`}
                    role="button"
                    className="delete-photo"
                    tabIndex={tabIndex ? tabIndex : 0}
                    onClick={() => onChange('deletePhoto', data)}
                    onKeyPress={(e) => onKeypress(e)}
                    aria-label={data.deletePhotoLabel ? data.deletePhotoLabel : 'Delete icon'}
                  >
                    <SvgSprite id="b2c-delete-icon" />
                  </span>
                </>
              ) : (
                <>
                  {data?.uploadIcon?.src ? (
                    <img src={data.uploadIcon.src} alt={data.uploadIcon?.alt} className="upload-icon" />
                  ) : (
                    <span className="accordionHead" />
                  )}
                  {renderLabelText()}
                </>
              )}
            </label>
          </div>
          {data.photoError && detectMobile() && (
            <DynamicContent tagName="p" attrs={{ className: 'error-msg', id: data.id }} innerHtml={data.photoError} />
          )}
          <ExampleImages />
        </div>
      );
    }

    return (
      !isANPToANPUpgradation() && (
        <div className="photo-upload-section" aria-live="polite">
          <div className="section-label">
            <DynamicContent tagName="p" attrs={{ className: 'sub-heading' }} innerHtml={data.label} />
            {/* {!isSwad && renderProfileTooltip()} */}
          </div>
          {renderSection}
          {!isANPToANPUpgradation() && data.photoError && !detectMobile() && (
            <DynamicContent tagName="p" attrs={{ className: 'error-msg', id: data.id }} innerHtml={data.photoError} />
          )}
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'InputFile');
  }
};

export default InputFile;

InputFile.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
