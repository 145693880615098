/*
 * slider-component.js
 * This file contains code for slider component
 * @licensor  Miral
 */

import React, { Component, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toTwoDecimalPlaces, detectMobile } from '../../../../common/utility';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import { logComponentRenderingError } from '../../../../common/logger';
import debounce from 'lodash/debounce';

/**
 * Slider Component displays the slider in bar and can slide to set the values
 * @param    {Object} Props containing all the infomation of slider Component
 */
class SliderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalMoney: parseFloat(this.props.totalMoney),
      moneyCardState: this.props.moneyCardBal < this.props.totalMoney ? this.props.moneyCardBal : this.props.totalMoney,
      creditState:
        this.props.moneyCardBal < this.props.totalMoney
          ? this.props.totalMoney - this.props.moneyCardBal
          : parseFloat(0),
      method: '',
      moneyCardBalance: this.props.moneyCardBal,
      svalue:
        this.props.moneyCardBal < this.props.totalMoney
          ? this.props.totalMoney - this.props.moneyCardBal
          : parseFloat(0),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.method !== 'change' &&
      (parseFloat(props.updatedCreditCardValue) !== parseFloat(state.creditState) ||
        parseFloat(props.updatedMoneyCardValue) !== parseFloat(state.moneyCardState) ||
        parseFloat(props.updatedSliderValue) !== parseFloat(state.svalue) ||
        parseFloat(props.totalMoney) !== parseFloat(state.totalMoney) ||
        parseFloat(props.moneyCardBal) !== parseFloat(state.moneyCardBalance))
    ) {
      let moneyCardVal, creditCardVal, sliderVal;
      if (props.moneyCardBal < props.totalMoney) {
        moneyCardVal = props.sliderChanged ? props.updatedMoneyCardValue : props.moneyCardBal;
        creditCardVal = props.totalMoney - moneyCardVal;
        sliderVal = props.sliderChanged ? props.updatedSliderValue : moneyCardVal ? moneyCardVal : creditCardVal;
      } else {
        moneyCardVal = props.sliderChanged ? props.updatedMoneyCardValue : props.totalMoney;
        creditCardVal = props.sliderChanged ? props.totalMoney - props.updatedMoneyCardValue : 0;
        sliderVal = props.sliderChanged ? props.updatedSliderValue : 0;
      }
      props.getInputStateUpdate(parseFloat(moneyCardVal).toFixed(2), parseFloat(creditCardVal).toFixed(2), sliderVal);
      return {
        totalMoney: parseFloat(props.totalMoney),
        moneyCardState: parseFloat(moneyCardVal).toFixed(2),
        creditState: parseFloat(creditCardVal).toFixed(2),
        svalue: sliderVal,
        moneyCardBalance: parseFloat(props.moneyCardBal),
        method: '',
      };
    }
  }

  calculateMoney = (val) => {
    const sliderValue = parseFloat(val);
    const { moneyCardBal, totalMoney } = this.props;
    let moneyCardValue = 0;
    let creditCardValue = 0;
    const stepsCal = Math.abs(moneyCardBal / (totalMoney - moneyCardBal));
    if (moneyCardBal < totalMoney) {
      if (moneyCardBal <= sliderValue) {
        moneyCardValue =
          moneyCardBal - (sliderValue - moneyCardBal) * stepsCal <= 0
            ? 0
            : moneyCardBal - (sliderValue - moneyCardBal) * stepsCal;
        creditCardValue = totalMoney - moneyCardValue;
      } else {
        moneyCardValue = moneyCardBal;
        creditCardValue = totalMoney - moneyCardValue;
      }
    } else {
      moneyCardValue = totalMoney - sliderValue;
      creditCardValue = sliderValue;
    }
    this.debounceForStateChange(
      parseFloat(moneyCardValue).toFixed(2),
      parseFloat(creditCardValue).toFixed(2),
      sliderValue,
    );
  };

  debounceForStateChange = debounce((moneycardvalue, creditcardvalue, sliderValue) => {
    this.props.updateMAndCState(moneycardvalue, creditcardvalue, sliderValue);
  }, 10);

  handleSliderChange = (e) => {
    if (this.state.moneyCardBalance == 0) {
      this.state.svalue = 0;
    } else {
      const sliderValue = e.target.value;
      this.calculateMoney(sliderValue);
    }
  };

  handleMoneyCardChange = (e, method) => {
    //if (e.target.value) {
    try {
      if (method === 'change') {
        this.setState({ moneyCardState: e.target.value, method });
        return;
      } else {
        this.setState({ moneyCardState: e.target.value, method: '' });
      }
      let mCardInputValue = parseFloat(e.target.value);
      // const { totalMoney, moneyCardBal } = this.state;
      const { moneyCardBal, totalMoney } = this.props;

      let stepsCal = Math.abs(moneyCardBal / (totalMoney - moneyCardBal));
      if (stepsCal <= 1 || null || undefined || stepsCal === '') {
        stepsCal = 1;
      }

      if (!mCardInputValue) {
        mCardInputValue = moneyCardBal;
      }
      const sliderValue = (moneyCardBal - mCardInputValue) / stepsCal;

      totalMoney < moneyCardBal
        ? totalMoney < mCardInputValue
          ? this.calculateMoney(0)
          : this.calculateMoney(totalMoney - mCardInputValue)
        : this.calculateMoney(moneyCardBal + sliderValue);
    } catch (err) {}

    //this.debounceForStateChange(moneycardvalue, creditcardvalue);
    //}
  };

  handleCreditCard = (e, method) => {
    //if (e.target.value) {
    try {
      if (method === 'change') {
        this.setState({ creditState: e.target.value, method });
        return;
      } else {
        this.setState({ moneyCardState: e.target.value, method: '' });
      }
      const { moneyCardBal, totalMoney } = this.props;
      const cCardInputValue = parseFloat(e.target.value);
      const evt = {
        target: {
          value: totalMoney - cCardInputValue < 0 ? 0 : totalMoney - cCardInputValue,
        },
      };
      this.handleMoneyCardChange(evt, '');
    } catch (err) {}

    // }
    //this.debounceForStateChange(moneycardvalue, creditcardvalue);
  };

  // handlemoney = (e) => {
  //   this.setState({ totalMoney: parseFloat(e.target.value) });
  // };

  render() {
    const { sliderMoneyTitle, sliderCardTitle, sliderAriaLabel } = this.props.sliderLabels;
    try {
      return (
        <>
          {detectMobile() && (
            <input
              type="range"
              id="slider"
              name="slider"
              min={this.props.moneyCardBal < this.props.totalMoney ? this.props.moneyCardBal : 0}
              max={this.state.totalMoney}
              value={this.state.moneyCardBalance === 0 ? 0 : this.state.svalue}
              step={1}
              onChange={this.handleSliderChange}
              aria-abel={sliderAriaLabel}
              className="slider-range"
            />
          )}
          <div class="slider-labels">
            <DynamicContent
              tagName="div"
              innerHtml={sliderMoneyTitle}
              attrs={{ className: 'money-card-slider', 'aria-label': `${sliderMoneyTitle}` }}
            />
            <DynamicContent
              tagName="div"
              innerHtml={sliderCardTitle}
              attrs={{ className: 'credit-card-slider', 'aria-label': `${sliderCardTitle}` }}
            />
          </div>
          <div className="slider-wrapper">
            <input
              className="slider-input"
              type="text"
              id="moneycard"
              value={this.state.moneyCardState} //{`${this.props.currency} ${toTwoDecimalPlaces(this.state.moneyCardState || 0)}`}
              onChange={(e) => this.handleMoneyCardChange(e, 'change')}
              onBlur={(e) => this.handleMoneyCardChange(e, 'blur')}
              aria-label={`${sliderMoneyTitle} ${this.props.currency} ${this.state.moneyCardState}`}
            />
            {!detectMobile() && (
              <input
                type="range"
                id="slider"
                name="slider"
                min={this.props.moneyCardBal < this.props.totalMoney ? this.props.moneyCardBal : 0}
                max={this.state.totalMoney}
                value={this.state.moneyCardBalance === 0 ? 0 : this.state.svalue} // this.props.moneyCardBal < this.props.totalMoney ? this.props.moneyCardBal : 0}
                step={1}
                onChange={this.handleSliderChange}
                aria-label={sliderAriaLabel}
                className="slider-range"
              />
            )}
            <input
              type="text"
              id="creditcard"
              value={this.state.creditState} //{`${this.props.currency} ${toTwoDecimalPlaces(parseFloat(this.state.creditState))}`}
              onChange={(e) => this.handleCreditCard(e, 'change')}
              onBlur={(e) => this.handleCreditCard(e, 'blur')}
              className="slider-input"
              aria-label={`${sliderCardTitle} ${this.props.currency} ${this.state.creditState}`}
            />
          </div>
        </>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'SliderComponent');
    }
  }
}

export default SliderComponent;
