import React, { useState } from 'react';

import { DynamicContent } from '../../../presentation/base';
import DropDown from '../../../presentation/drop-down';
import { timeFormat } from './helper';
import PropTypes from 'prop-types';

const TimeslotSelector = (props) => {
  const { timeSlotData, product, services, performanceData, getSelectedTimeSlotDetails } = props;

  /* Local variables */
  const isTimeSlotsAvailable = performanceData.hasOwnProperty('performance') && performanceData.performance.length;

  /* React State */
  const [selectedTimeSlot, setselectedTimeSlot] = useState('');

  /* Get timeslot value */
  const timeFormatValue = (performance) => timeFormat(product.fromDate, performance);

  /* Time slot selector handler function */
  const selectTimeSlot = (event, isAutoSelected) => {
    setselectedTimeSlot(event.text.split('(')[0].trim());
    getSelectedTimeSlotDetails({
      performanceId: event.performanceId,
      timeSlot: event.text.split('(')[0].trim(),
    });
  };

  /* Render Time slots */
  const renderTimeSlots = () => {
    const labels = timeSlotData && timeSlotData.labels ? timeSlotData.labels : {};
    const availabileLabel = labels ? labels.availableLabel : '';
    const items = performanceData.performance.map((performance) => {
      const availableSlots =
        performance.availability.available > 1 ? `(${performance.availability.available} ${availabileLabel})` : '';
      return {
        performanceId: performance.performanceId,
        value: `${timeFormatValue(performance)} ${availableSlots}`,
        disabled: performance.sellable === 'false' || performance.availability.available === '0',
        text: `${timeFormatValue(performance)} ${availableSlots}`,
      };
    });
    const filterAvailableSlots = items.filter((item) => !item.disabled);
    const filterDefaultAvailableSlots = filterAvailableSlots.filter((ele) => ele.value === product.performanceId);
    const deafultAvailableSlot = filterDefaultAvailableSlots.length ? filterDefaultAvailableSlots[0].text : {};
    const defaultValue =
      product.performanceId && deafultAvailableSlot ? deafultAvailableSlot.text : labels ? labels.dropdownLabel : '';

    return (
      <div className="c-date-selector--time-slot">
        <DynamicContent tagName="h3" attrs={{ className: 'heading-5' }} innerHtml={labels ? labels.title : ''} />
        <DynamicContent tagName="p" attrs={{ className: 'sub-heading' }} innerHtml={timeSlotData.description} />
        <DropDown
          listItems={filterAvailableSlots}
          defaultValue={defaultValue || selectedTimeSlot}
          callBackFunction={selectTimeSlot}
          showSelected="true"
        />
      </div>
    );
  };

  /* Render if slots are available */
  if (isTimeSlotsAvailable) {
    return <>{renderTimeSlots()}</>;
  }
  return null;
};

TimeslotSelector.propTypes = {
  services: PropTypes.object.isRequired,
  getSelectedTimeSlotDetails: PropTypes.object.isRequired,
  performanceData: PropTypes.object.isRequired,
  timeSlotData: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default TimeslotSelector;
