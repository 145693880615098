import React, { useEffect, useRef, useState, useCallback } from 'react';
import { logComponentRenderingError } from '../../../common/logger';
import { AnchorLink, DynamicContent, Image, ImageWithRatio, Overlay } from '../../presentation/base/';
import {
  canUseDOM,
  createMappedData,
  flipObjectKeyValues,
  getErrorMap,
  getFallbackLanguage,
  getLoggedInUser,
  isEmpty,
  getUserAgent,
  hasHeaderComponent,
  resolvePath,
  checkParksTenants,
  checkTenant,
} from '../../../common/utility';
import Video from '../../presentation/video/';
import UIConfig from '../../../common/UIConfig';
import {
  getDiscount,
  getPerformanceId,
  getCategoryProds,
  updateSecProdArray,
  renderOverlayErrorMsg,
  setStateOfMinicart,
  changeCategoryToString,
  updateCouponCodeData,
  enrichProductData,
} from '../../../common/helpersPJ';
import AddOnsTicketComponent from '../b2c-purchase-journey/add-ons/add-ons-ticket-component';
import { bookNowClickAnalytics } from '../../../common/analytics-events';
import CartActions from '../b2c-purchase-journey/cart/cart-actions';
import { getB2CProductDetails } from '../../../common/coveo-api.js';
import GTMData from '../b2c-purchase-journey/gtm-data';
import { PerformanceService } from '../../../common/services';
import ProductStore from '../b2c-purchase-journey/purchase-journey-wrapper/product-store';
import { secProds } from '../b2c-purchase-journey/add-ons/add-ons-utility.js';
import { GLOBAL_VARS } from '../../../common/global-vars';
import classNames from 'classnames';

const FeatureTile = ({
  tile,
  variant,
  classes = '',
  disableReadMore = '',
  lazyloadWithBackground,
  articleListSwadCMS = '',
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [errorOverlayObj, setErrorOverlayObj] = useState({});
  const [purchaseWrapperErrors, setPurchaseWrapperErrors] = useState('');
  const [errObj, setErrObj] = useState('');
  const [cartData, setCartData] = useState({});
  const [showPjPopupSegment, setShowPjPopupSegment] = useState(false);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const products = useRef({});
  const productId = useRef('');
  const pjData = useRef(null);
  const cartActions = useRef(null);
  const productList = useRef([]);
  const coveoDefaultParams = useRef({});
  const groupingNotRequired = useRef(false);
  const responseObj = useRef(null);
  const responseObjAll = useRef(null);
  const userAgent = getUserAgent() || !hasHeaderComponent();
  const { bookNowLabel, isProductSoldOut, soldoutText } = tile;

  useEffect(() => {
    const { enablePJPopup } = tile;
    if (enablePJPopup) {
      window.PubSub.subscribe(UIConfig.events.PURCHASEJOURNEYDATA, (msg, data) => {
        pjData.current = data.purchaseJourneyData;
        cartActions.current = new CartActions({
          serviceUrl: pjData.current.services,
          moduleName: 'purchase-journey',
          tenantId: getLoggedInUser().tenantID,
        });

        setStateOfMinicart(cartActions, productList, errorCallback, setCartData, setErrObj);
      });
      setShowPjPopupSegment(true);
    }

    window.PubSub.subscribe('closeOverlay', closeOverlay);
  }, []);

  useEffect(() => {
    triggerProductDetailsEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tile]);

  const triggerProductDetailsEvent = useCallback(() => {
    if (!tile) {
      return;
    }
  }, [tile]);

  /**
   * error handling
   * @param {Object} err error object
   */
  const errorCallback = (serviceName, error) => {
    if (error) {
      if (serviceName === 'getCoveoProducts' && error.code.toString() === UIConfig.errorCodes.notFound) {
        const errObj = getErrorMap(
          serviceName,
          pjData.current.services[serviceName].errors,
          false,
          error,
          purchaseWrapperErrors,
        );
        setPurchaseWrapperErrors(errObj);
      } else {
        const errorObj = getErrorMap(serviceName, pjData.current.services[serviceName].errors, false, error, errObj);
        setErrObj(errorObj);
      }
    }
  };

  /**
   * Product data coveo call
   * @param {Object} err error object
   */
  const createProductStore = (tab, getActiveProduct) => {
    return new Promise((resolve, reject) => {
      if (!products.current[tab]) {
        const params = [];
        getActiveProduct
          ? params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.productId, value: productId.current },
            )
          : tab &&
            params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.tab, value: tab },
            );

        getB2CProductDetails({
          ...coveoDefaultParams.current,
          queryParams: params,
        })
          .then((res) => {
            res.results = changeCategoryToString(res.results, coveoDefaultParams);
            products.current[tab] = new ProductStore(res.results);
            setPurchaseWrapperErrors('');
            resolve(products.current[tab]);
          })
          .catch((res) => {
            errorCallback('getCoveoProducts', res.error);
            const errObj = getErrorMap(
              'getCoveoProducts',
              pjData.current.services.getCoveoProducts.errors,
              false,
              res.error,
            );
            reject(errObj);
          });
      } else {
        resolve(products.current[tab]);
      }
    });
  };

  const getDiscountedProducts = (products, days, discountMap, disableDiscount) => {
    discountMap = discountMap || pjData.current.discountMap;
    const baseProducts = [];
    const selectedProducts = [];
    const baseProductPrices = {};
    const baseDis = getDiscount(0, discountMap, products);
    const selectedDis = getDiscount(days, discountMap, products);

    products.forEach((prod) => {
      const prodCode = prod.pricing;
      prod.discount = {};
      prod.discount.actualPerc = 0;
      prod.discount.computed = 0;

      if (prodCode === baseDis.code) {
        prod.unitPrice = prod.gross || 0;
        baseProducts.push(prod);
        baseProductPrices[prod.classType] = prod.gross || 0;
      } else if (prodCode === selectedDis.code) {
        prod.discount.actualPerc = selectedDis.discountPer;
        selectedProducts.push(prod);
      }
    });

    if (baseDis.code === selectedDis.code || disableDiscount) {
      return baseProducts;
    } else {
      return selectedProducts.map((prod) => {
        prod.unitPrice = baseProductPrices[prod.classType];
        return prod;
      });
    }
  };

  const addToCartCallback = (products, grouping, context, redirectToBookingPageCallback) => {
    // add required keys for cart and chekbasket
    groupingNotRequired.current = grouping;
    const data = pjData.current;
    products = enrichProductData(products, data, groupingNotRequired);

    const perPromises = [];
    // extract the quntity from param and add it to respective product
    products.forEach((prod) => {
      const quantity = prod.quantity;
      let callPerformance = false;
      if (!prod.performanceId) {
        prod.performanceId = null; //for checkbasket
        callPerformance = prod.hasperformance === '1';
      }

      /* Reset performance id when hasperformance is 0 to fix price value */
      if (prod.hasperformance === '0') {
        prod.performanceId = null;
      }

      prod.guestDetails = prod.guestDetails || null; //for checkbasket
      prod.quantity = quantity;
      prod.currQuantity = quantity;
      prod.groupedQuantity = quantity;
      //adding null check for getPerformance service
      if (callPerformance && data.services.getPerformance) {
        const url = data.services.getPerformance.url.replace('{0}', prod.productId);
        perPromises.push(
          PerformanceService.getPerformanceData(
            url,
            prod.fromDate,
            prod.toDate,
            true,
            UIConfig.loader.defaultPreLoaderTarget,
          ),
        );
      }
    });

    Promise.all(perPromises)
      .then((responses) => {
        let errorObj = getErrorMap('getPerformance', {}, true);
        responses.forEach((res, index) => {
          products[index].performanceId = getPerformanceId(res.data.performancelist.performance, true);
          if (!products[index].performanceId) {
            res.error = {
              code: '7001',
              text: 'product is not sellable',
            };
            throw res;
          }
        });
        // save the data to cart

        let cartMaxQty;
        let prodMaxQty;

        if (data.miniCart) {
          cartMaxQty = data.miniCart.maxCartQuantity;
          prodMaxQty = data.miniCart.maxQuantity;
        } else {
          cartMaxQty = data.cartMaxQty;
        }

        const remQty = cartMaxQty - cartData.cart.totalQuantity;
        updateCouponCodeData(cartData, cartActions);
        cartActions.current
          .updateCart(productList.current || [], products, prodMaxQty, remQty)
          .then((res) => {
            productList.current = res.productList;
            errorObj = getErrorMap('updateCart', {}, true);
            GTMData.push('addToCart', { products: products });

            setCartData(cartActions.current.getCustomCart(productList.current || [], true));
            setErrObj(errorObj);
            setIsOverlayOpen(false);
            window.PubSub.publish(UIConfig.events.MINICARTUPDATED, true);

            if (context && context.href) {
              window.location.href = context.href;
            }
            redirectToBookingPageCallback();
          })
          .catch((res) => {
            const err = res.error || res.response.error; //for different cutom responses
            errorCallback('updateCart', err);
            redirectToBookingPageCallback();
          });
      })
      .catch((res) => errorCallback('getPerformance', res.error));
  };

  const renderPjPopup = (id, itemData = {}) => {
    const datalayerItem = canUseDOM() && window.dataLayer && window.dataLayer[0];
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const tenantId = mainObj.tenantID && mainObj.tenantID.toLowerCase();

    productId.current = id;

    if (!pjData.current) {
      return;
    }

    coveoDefaultParams.current = tile.enablePJPopup
      ? {
          perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.current.coveoMappingList,
          serviceUrl: pjData.current.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.current.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};

    tile.enablePJPopup &&
      createProductStore(productId.current, true).then((response) => {
        responseObj.current = response;
        const activeProduct = response && createMappedData(response.products, pjData.current.coveoMappingList)[0];
        const currentTabData =
          pjData.current &&
          pjData.current.tabs &&
          pjData.current.tabs.filter(
            (tab) =>
              tab.coveoValue.toLowerCase() === (activeProduct && activeProduct.tab && activeProduct.tab.toLowerCase()),
          )[0];

        createProductStore(currentTabData.coveoValue, false).then((response) => {
          responseObjAll.current = response;
          setIsOverlayOpen(true);
          setErrorOverlayObj({});
        });
      });

    if (datalayerItem && tenantId === UIConfig.ymcB2CTenant) {
      bookNowClickAnalytics(tile.title, itemData);
    }
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const renderPJPopupData = () => {
    const activeProduct =
      responseObj.current && createMappedData(responseObj.current.products, pjData.current.coveoMappingList)[0];

    const currentTabData =
      pjData.current &&
      pjData.current.tabs &&
      pjData.current.tabs.filter(
        (tab) => tab.coveoValue.toLowerCase() === (activeProduct.tab && activeProduct.tab.toLowerCase()),
      )[0];

    let counterData = null;
    let dateSelector = null;
    let timeSlotSelector = null;
    let catgry = null;
    let categry = null;
    let productOverlaySelector = null;

    const category = { [currentTabData.coveoKey]: currentTabData.coveoValue };
    const resProducts = responseObjAll.current.getFilteredProductsFromKeyVal(category);
    const allProducts = resProducts.length && createMappedData(resProducts, pjData.current.coveoMappingList);
    const categoryProducts = allProducts.filter(
      (prod) => prod.category && prod.category[0] === activeProduct.category[0],
    );

    for (let tktType in currentTabData.controls) {
      let currentBaseProdOptions =
        currentTabData.controls[tktType].options &&
        currentTabData.controls[tktType].options.find((opt) => opt.coveoValue === activeProduct.category.toString());

      if (currentBaseProdOptions) {
        catgry = currentBaseProdOptions;
        counterData = currentBaseProdOptions.visitorSelector;
        dateSelector = currentBaseProdOptions.dateSelector;
        timeSlotSelector = currentBaseProdOptions.timeSlotSelector;
        productOverlaySelector = currentBaseProdOptions.productOverlaySelector;
      }
      categry = currentBaseProdOptions;
    }

    if (!products.current.hasOwnProperty('quantity')) {
      products.current.quantity =
        (counterData &&
          counterData.visitorSelector &&
          counterData.visitorSelector.options &&
          counterData.visitorSelector.options[0] &&
          counterData.visitorSelector.options[0].defaultQuantity) ||
        0;
    }

    const additionalProds = getCategoryProds(activeProduct.category, allProducts);

    // Reset Secondary products
    Object.keys(secProds).forEach((key) => delete secProds[key]);

    return categoryProducts.map((product, index) => {
      const multiTicketSelector = categry.multiTicketSelector,
        keyMap = multiTicketSelector ? multiTicketSelector.stringMapping : {},
        category = product.category;

      let isCategoryMappingExist = false;
      if (keyMap) {
        for (let item in keyMap) {
          if (item === category[0]) {
            isCategoryMappingExist = true;
            break;
          }
        }
      }

      if (
        keyMap &&
        (category.indexOf(keyMap.drivingExperience) !== -1 ||
          category.indexOf(keyMap.passengerExperience) !== -1 ||
          isCategoryMappingExist)
      ) {
        updateSecProdArray(product, category[0], keyMap);
      }

      if (!product.hasOwnProperty('quantity')) {
        product.quantity = categry.visitorSelector.options[0].defaultQuantity;
      }

      return (
        <div>
          {allProducts && additionalProds && (!multiTicketSelector || product.experienceCatgory === keyMap.primary) && (
            <AddOnsTicketComponent
              data={currentTabData}
              cartData={pjData.current.miniCart}
              businessErrors={pjData.current.miniCart.businessErrors}
              ticket={product}
              dateSelector={dateSelector}
              visitorCounter={counterData}
              createMappedData={createMappedData}
              addToCartCallback={addToCartCallback}
              services={pjData.current.services}
              getDiscountedProducts={getDiscountedProducts}
              timeSlotSelector={timeSlotSelector}
              category={catgry}
              handleOnClick={() => {}}
              additionalProds={additionalProds}
              enablePJPopup={tile.enablePJPopup}
              searchProductList={allProducts}
              coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
              productOverlaySelector={productOverlaySelector}
              overlayErrorCallback={overlayErrorCallback}
              variant={tile.enablePJPopup ? 'v-overlay-selected' : ''}
            />
          )}
        </div>
      );
    });
  };

  const overlayErrorCallback = (error, errorData) => {
    let errorObj = {};
    if (!isEmpty(error) && error.errorcode) {
      errorObj.errorcode = error.errorcode;
      if (errorData && errorData[error.errorcode]) {
        errorObj.text = errorData[error.errorcode];
      } else if (error && error.errordescription) {
        errorObj.text = error.errordescription;
      }
      setErrorOverlayObj(errorObj);
      setIsOverlayOpen(false);
    }
  };

  try {
    let isArticleListImageAvailable = false;
    const { sVGIconImage } = tile;

    // EP-755 - add Travel box related button, only for EA
    const isEtihadArena = resolvePath(GLOBAL_VARS, 'pageDetails.tenantName', '') === UIConfig.iamMapping.etihadarena;
    const isEASecondaryCTA = isEtihadArena && resolvePath(tile, 'secondaryCTATag.label', null);

    const renderEmail = () => {
      if (canUseDOM() && localStorage.getItem('userEmailAddress')) {
        return localStorage.getItem('userEmailAddress');
      }
      return null;
    };
    const handleClick = () => {
      if (tile?.ctaTag?.href && !disableReadMore) window.location.href = tile.ctaTag.href;
    };
    if (
      sVGIconImage &&
      sVGIconImage.desktopImage &&
      sVGIconImage.desktopImage.src &&
      sVGIconImage.mobileImage &&
      sVGIconImage.mobileImage.src &&
      sVGIconImage.tabletImage &&
      sVGIconImage.tabletImage.src
    ) {
      isArticleListImageAvailable = true;
    }
    const enableClickEvent = articleListSwadCMS && !tile.videoInfo && tile?.ctaTag?.href && !disableReadMore;
    const imageClasses = classNames(`article-list--imagewrap `, {
      'image-clickable': enableClickEvent,
    });
    return (
      <div className={`article-list clearfix ${classes} ${articleListSwadCMS}`}>
        <div className={imageClasses} {...(enableClickEvent && { onClick: handleClick })}>
          {tile.videoInfo ? (
            <Video data={tile.videoInfo} active={tile.videoInfo.autoplay} videoData={true} />
          ) : tile.featureImage && variant === UIConfig.featureTitle.iamResetPassword ? (
            tile.featureImage.desktopImage.src && (
              <img src={tile.featureImage.desktopImage.src} alt={tile.featureImage.imageAltText} />
            )
          ) : lazyloadWithBackground ? (
            <ImageWithRatio image={tile.featureImage} isMaxHeight={variant === UIConfig.featureTitle.imageLeft} />
          ) : (
            <Image image={tile.featureImage} />
          )}
        </div>
        <div className="article-list-text">
          <div className="icon-heading-wrapper">
            {variant === UIConfig.featureTitle.iamResetPassword && <div className="success-icon">success icon</div>}
            {isArticleListImageAvailable && !lazyloadWithBackground && (
              <div className="category-icon">
                <Image image={sVGIconImage} />
              </div>
            )}
            {isArticleListImageAvailable && lazyloadWithBackground && (
              <div className="category-icon">
                <ImageWithRatio image={sVGIconImage} isMaxHeight={variant === UIConfig.featureTitle.imageLeft} />
              </div>
            )}
            {tile.shortTitle && <DynamicContent tagName="p" attrs={{ className: '' }} innerHtml={tile.shortTitle} />}
            {tile.ctaTag && tile.ctaTag.href && checkParksTenants() && !disableReadMore ? (
              <a href={tile.ctaTag.href} tabIndex="-1">
                <DynamicContent tagName="h3" attrs={{ className: '', tabIndex: '0' }} innerHtml={tile.title} />
              </a>
            ) : tile.title ? (
              <DynamicContent tagName="h3" attrs={{ className: '' }} innerHtml={tile.title} />
            ) : null}
            {tile.dateString && (
              <DynamicContent tagName="div" attrs={{ className: 'article-date' }} innerHtml={tile.dateString} />
            )}
          </div>
          {tile.titleDescription && (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'title-description' }}
              innerHtml={tile.titleDescription}
            />
          )}
          {variant === UIConfig.featureTitle.iamResetPassword && <p className="user-email">{renderEmail()}</p>}
          {/* SWAD Link Tag */}
          {isSwad && (
            <div className="article-cta-link">
              {tile.secondaryCTATag && (
                <AnchorLink
                  link={{ ...tile.secondaryCTATag, ariaLabel: tile.secondaryCTATag.label + tile.title }}
                  className="secondary-cta right-arrow"
                />
              )}
            </div>
          )}
          <div className={`cta-wrapper ${isEASecondaryCTA && 'flex-div'}`}>
            {isEASecondaryCTA && (
              <div className="btn-cta-golden">
                <AnchorLink link={tile.secondaryCTATag} className="cta-wrap secondary-cta right-arrow" />
              </div>
            )}
            {tile.ctaTag && !disableReadMore && (
              <AnchorLink
                link={{ ...tile.ctaTag, ariaLabel: tile.ctaTag.label + tile.title }}
                className="cta-wrap secondary-cta right-arrow"
              />
            )}
            {showPjPopupSegment &&
              (isProductSoldOut ? (
                <AnchorLink
                  link={{
                    href: 'javascript:void(0)', // eslint-disable-line
                    label: soldoutText.toUpperCase(),
                  }}
                  className={`cta-wrap secondary-cta right-arrow sold-out-cta disabled  ${
                    tile.secondaryCTATag.href ? 'book-now' : ''
                  }`}
                />
              ) : (
                <AnchorLink
                  link={{
                    href: 'javascript:void(0)', // eslint-disable-line
                    label: bookNowLabel.toUpperCase(),
                  }}
                  className={`cta-wrap secondary-cta right-arrow ${tile.ctaTag.href ? 'book-now' : ''}`}
                  onClick={() => renderPjPopup(tile.productId)}
                />
              ))}

            {tile.secondaryCTATag && variant === UIConfig.featureTitle.contentTeaserWrapper && (
              <AnchorLink link={tile.secondaryCTATag} className="cta-wrap secondary-cta right-arrow" />
            )}
          </div>
          {showPjPopupSegment && isOverlayOpen && renderPJPopupData()}
          {renderOverlayErrorMsg(errorOverlayObj)}
          {userAgent && <Overlay />}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'FeatureTile', variant);
  }
};

export default FeatureTile;
