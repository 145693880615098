/**
 * tab-panel-component.js
 * This file contains code for generating multiple compnents inside a TAB
 * @licensor  Miral
 */

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { UIConfig } from '../../../..';

import { detectMobile, switchTabFocusOnArrowPress, isAndroid, checkTenant } from '../../../common/utility';
import { AnchorLink, Carousel, DynamicContent } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';

/**
 * getDropDownTabHeader function will return tab Header with dropdown in mobile and tab in desktop
 */
const getDropDownTabHeader = (props, isMobile) => {
  const { activeTab, dropdownPanelHandler, headings, isDropdownActive, theme } = props;

  return (
    <div
      onClick={dropdownPanelHandler}
      onKeyDown={(e) => {
        /**
         * if user press enter key then this condition become true
         */
        if (e.charCode === 13 || e.keyCode === 13 || e.key === 'Enter') {
          dropdownPanelHandler();
        }
      }}
      className={classNames(
        'v-tabs-dropdown',
        { variation: theme !== 'my-profile-tabs' && isMobile },
        { 'expand-dropdown': isDropdownActive },
      )}
      role={isMobile && isAndroid() ? 'menuitem' : null}
      aria-haspopup={isMobile ? 'true' : null}
      aria-expanded={isMobile && !!isDropdownActive}
    >
      <div className="tab-menu-dropdown-container">
        <p className="tab-menu-dropdown">
          <DynamicContent tagName="span" innerHtml={headings[activeTab]} />
          <span className="tab-menu-dropdown-head-length">({headings.length})</span>
        </p>
        <span className="tab-menu-dropdown-carrot"></span>
      </div>
      {getDefaultTabHeader(props)}
    </div>
  );
};

/**
 * getDefaultTabHeader function will return tab Header without dropdown
 */
const getDefaultTabHeader = (props, isMobile) => {
  const { activeTab, changeTab, slickSettings, tabs, tabsElem, theme, variant } = props;
  const tabsHeaderContent = getTabsHeaderContent(tabs, tabsElem, activeTab, changeTab);

  return (
    <ul
      className={`${
        variant === 'v-tabs-dropdown' && theme === 'my-profile-tabs' && isMobile
          ? 'tabs-menu-mobile'
          : 'tabs-menu w--content'
      } ${props.activeTab !== 0 ? 'after-move' : ''}`}
      role="tablist"
    >
      {variant === 'v-tabs-dropdown' && theme === 'my-profile-tabs' && isMobile ? (
        <Carousel data={slickSettings}>{tabsHeaderContent}</Carousel>
      ) : (
        tabsHeaderContent
      )}
    </ul>
  );
};

const renderIcon = (t) => {
  return (
    <i>
      <img src={t.tabIcon.src} alt={t.tabTitle} title={t.tabTitle} />
    </i>
  );
};

const getTabsHeaderContent = (tabs, tabsElem, activeTab, changeTab) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const tabMenuHeadingClass = 'tabs-menu--links heading-6';

  return tabs.map((t, i) => {
    return t.tabLink ? (
      <li
        key={i.toString()}
        className={classNames(tabMenuHeadingClass, { 'hide-on-mobile': t.hideOnMobile })}
        tabIndex="-1"
        onKeyUp={(e) => keyDownHandler(e, tabsElem)}
        role="tab"
      >
        <AnchorLink link={{ ...t.tabLink, html: '', label: t.tabTitle }} tabIndex={isSwad ? '0' : '-1'} />
      </li>
    ) : (
      <li
        key={i.toString()}
        className={classNames(
          tabMenuHeadingClass,
          { 'hide-on-mobile': t.hideOnMobile },
          { 'is-active': activeTab === i },
        )}
        role="tab"
        onClick={() => changeTab(i, t)}
        onKeyDown={(e) => {
          if (e.charCode === 13 || e.keyCode === 13 || e.key === 'Enter') {
            changeTab(i);
          }
        }}
        onKeyUp={(e) => {
          switchTabFocusOnArrowPress(e, tabsElem);
        }}
        tabIndex={activeTab === i || isSwad ? '0' : '-1'}
        id={`${t.placeholderName}_tab_${i}`}
        aria-controls={`${t.placeholderName}_panel_${i}`}
        aria-selected={activeTab === i ? true : false}
        aria-label={t.tabTitle}
      >
        {t.tabIcon.src ? renderIcon(t) : ''}

        <DynamicContent tagName="span" innerHtml={t.tabTitle} />
      </li>
    );
  });
};

/**
 * Switch focus on arrow movement and on press enter click on anchor tag
 * @param {Object} e
 * @param {Array} tabsElems
 * @rturn null
 */
const keyDownHandler = (e, tabsElems) => {
  switchTabFocusOnArrowPress(e, tabsElems);
  const targetElem = e.target;
  if (e.keyCode === 13) {
    targetElem.querySelector('a').click();
  }
};

/** Creates the TAB links as per the JSON
 * @param  {} Tab title Array of strings, index of active tab
 * return {element} defaultTabHeader or dropDownTabHeader based on the variant passed to it
 */
const TabHeader = (props) => {
  const { tabs, variant } = props;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(detectMobile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!tabs) {
    return null;
  }

  try {
    return variant === 'v-tabs-dropdown' ? getDropDownTabHeader(props, isMobile) : getDefaultTabHeader(props, isMobile);
  } catch (err) {
    return logComponentRenderingError(err, 'TabHeader', variant);
  }
};

export default TabHeader;
