import React from 'react';
import { UIConfig } from '../../../..';
import { checkTenant } from '../../../common/utility';
import { QuickLink } from '../base';
/*
 *Quick links
 */
const QuickLinks = (data) => {
  if (!data?.quickLinks) return <></>;
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isYasIsland = checkTenant(UIConfig.iamMapping.yasisland);
  return (
    <div className={`quick-links ${isYasIsland && 'yasisland-quick-links'}`}>
      <div className="quick-links-band">
        {data?.quickLinks.map((item, index) => (
          <QuickLink {...item} key={index} {...(isSwad && { variant: data?.variant })} count={data.quickLinks.length} />
        ))}
      </div>
    </div>
  );
};
export default QuickLinks;
