import React from 'react';
import useNoScript from '../../../hooks/useNoScript';

const AnalyticsBody = (props) => {
  const elementData = {
    element: 'iframe',
    props: {
      title: 'Google Tag Manager',
      src: `https://www.googletagmanager.com/ns.html?id=${props.gtmId}`,
      height: '0',
      width: '0',
      style: 'display:none;visibility:hidden',
    },
  };

  useNoScript(elementData);

  return <React.Fragment></React.Fragment>;
};

export default AnalyticsBody;
