import React from 'react';
import PropTypes from 'prop-types';
import WishlistIcon from '../wishlist-icon';
import { canUseDOM, detectViewPort, resolvePath } from '../../../common/utility';
import { AnchorLink, DynamicContent } from '../base';
import { logComponentRenderingError } from '../../../common/logger';

import UIConfig from '../../../common/UIConfig';
import { bodyClicks } from '../../../common/analytics-events';

const ThirdPartyWidget = (props) => {
  if (!props.data) {
    return null;
  }
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;

  const bindOpenTripAdvisor = (data) => {
    return window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      customClass: 'overlay-trip-advisor',
      dataToAppend: (
        <iframe
          title="trip-advisor"
          src={data.link.url}
          frameBorder="2px"
          width="100%"
          height="500px"
          scrolling="auto"
        ></iframe>
      ),
    });
  };

  const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
    if (checkfortenants) {
      bodyClicks(eventName, titleData, labelData);
    }
  };

  const openAddThis = () => {
    if (canUseDOM()) {
      const clearClickInterval = setInterval(() => {
        let addThisRef = document.getElementsByClassName('at-expanding-share-button-toggle-btn')[0];
        if (addThisRef) {
          addThisRef.click();
          let detectDevice = detectViewPort();
          if (detectDevice === 'mobile' || detectDevice === 'tablet') {
            setTimeout(() => {
              addThisRef.click();
            }, 0);
          }
          clearInterval(clearClickInterval);
        }
      }, 100);
    }
  };

  const handleKeyDownShare = (e, fn, params) => {
    if (e.which === 13 || e.keyCode === 13) {
      fn(params);
    }
  };

  const handlerKeyDownTripAdvisor = (e, item) => {
    if (e.which === 13 || e.keyCode === 13) {
      bindOpenTripAdvisor(item);
    }
  };

  const loadAddWidgetScript = () => {
    window.PubSub.publish('loadAddWidget', {
      loadAddWidget: true,
    });
  };

  const renderWidgetBasedOnType = () => {
    const { data, isLoginWishList, wishlistData } = props;

    return data.map((item, index) => {
      switch (item.type.toLowerCase()) {
        case 'wishlist':
          return (
            <div className={`item ${item.type ? item.type.toLowerCase() : ''}`} key={index}>
              <WishlistIcon
                data={{
                  wishlist: item.wishlist,
                  childData: {
                    title: wishlistData.title,
                    desc: wishlistData.desc,
                    link: item.link && item.link.href,
                    image: wishlistData.image,
                  },
                }}
                isLoginWishList={isLoginWishList}
                wishlistAnalyticsData={props.wishlistAnalyticsData}
              />
            </div>
          );
        case 'tripadvisor':
          return (
            <div
              className={`item ${item.type.toLowerCase()}`}
              onClick={() => {
                bindOpenTripAdvisor(item);
                googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.linkClick, 'tripadvisor', item.type);
              }}
              onKeyDown={(e) => handlerKeyDownTripAdvisor(e, item)}
              tabIndex="0"
              aria-label={`${item.type} link`}
              key={index}
            ></div>
          );
        case 'share':
          return (
            <div onClick={loadAddWidgetScript} key={index}>
              <div
                className={`item ${item.type.toLowerCase()}`}
                onClick={() => {
                  openAddThis();
                  googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.linkClick, 'tripadvisor', item.type);
                }}
                onKeyDown={(e) => handleKeyDownShare(e, openAddThis)}
                tabIndex="0"
                aria-label={`${item.type} link`}
                id="shareWidget"
              >
                <DynamicContent
                  tagName="style"
                  innerHtml={`
                    .at-expanding-share-button {
                      visibility: hidden !important;
                      animation: none !important;
                    }
                  `}
                />
                {item.shareText}
              </div>
            </div>
          );
        default:
          return (
            <div className={`item ${item.type ? item.type.toLowerCase() : ''}`} key={index}>
              <AnchorLink
                link={{
                  ...item.link,
                  ariaLabel: item.type,
                }}
                onClick={() =>
                  googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.linkClick, item.title, item.type)
                }
              />
            </div>
          );
      }
    });
  };

  try {
    return (
      <div className="third-party-widget-container">
        <div className="third-party-widget-wrapper">
          <div className="third-party-widget">{renderWidgetBasedOnType()}</div>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ThirdPartyWidget');
  }
};

export default ThirdPartyWidget;

ThirdPartyWidget.propTypes = {
  data: PropTypes.array,
  isLoginWishList: PropTypes.bool,
  wishlistData: PropTypes.object,
};
