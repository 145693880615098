import React, { useEffect } from 'react';
import UIConfig from '../../../common/UIConfig';

const PurchaseJourneyData = (props) => {
  const purchaseJourneyData = { ...props };

  useEffect(() => {
    setTimeout(() => {
      window.PubSub.publish(UIConfig.events.PURCHASEJOURNEYDATA, {
        purchaseJourneyData: purchaseJourneyData,
      });
    }, 10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default PurchaseJourneyData;
