import moment from 'moment';
import React from 'react';

import UIConfig from '../../../../common/UIConfig';
import { logComponentRenderingError } from '../../../../common/logger';

const ConfirmedTicketTileStepThree = (props) => {
  try {
    const {
      productDisplayName,
      ticketParks,
      ticketDate,
      product,
      timeSlot,
      isTimeSlotVisible,
      quantity,
      validOn,
      classTypeName,
      ticket,
    } = props;

    return (
      <div className="component c-booking-confirmation-ticket ticket-box">
        <div className="c-booking-confirmation-ticket-title">
          <p className="heading-4 ticket-name">{productDisplayName}</p>
          <p className="body-2">{ticketParks}</p>
        </div>
        <div className="c-booking-confirmation-ticket-validity">
          <p className="heading-4 ticket-validity">{validOn}</p>
          <p className="body-2">
            {ticketDate && moment.utc(ticketDate).format(UIConfig.b2c.purchaseJourney.dateFormat)}
          </p>
          {product.timeSlot && timeSlot && isTimeSlotVisible && (
            <p className="heading-4 ticket-validity ticket-timeslot">{timeSlot}</p>
          )}
          {product.timeSlot && timeSlot && isTimeSlotVisible && <p className="body-2">{product.timeSlot}</p>}
        </div>
        <div className="c-booking-confirmation-ticket-inclusives">
          {quantity && <p className="heading-4 ticket-type">{quantity}</p>}
          <p className="body-2">{`${product.quantity || 1} ${ticket[classTypeName] || ''}`}</p>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ConfirmedTicketTileStepThree');
  }
};

export default ConfirmedTicketTileStepThree;
