import UIConfig from '../../../common/UIConfig';
import {
  canUseDOM,
  removeGuestUser,
  parseQueryString,
  getUserAgent,
  massageMobileData,
  getErrorDataInObject,
  getAnonymousCart,
  getLoggedInUser,
  isTicketUpgradePayment,
} from '../../../common/utility';
import { Logging } from '../../../common/logger';

class GPayPayment {
  constructor({
    gPayData,
    reservationId,
    errorMsg,
    errorCode,
    paymentDataAnonymous,
    isPaymentCaptured,
    isSuccess,
    paymentData,
  }) {
    this.isSuccess = isSuccess;
    this.isPaymentCaptured = isPaymentCaptured;
    this.reservationId = reservationId;
    this.errorMsg = errorMsg;
    this.errorCode = errorCode;
    this.cartData = paymentDataAnonymous && paymentDataAnonymous.cart != null ? paymentDataAnonymous.cart : null;
    this.anonymousUser = canUseDOM() && this.cartData;
    this.isRenewAnnualPass = false;
    this.gPayData = gPayData;
    this.paymentData = paymentData || {};
    this.paymentFailure = ['GPAY_008'];
  }

  //Redirecting to thank you page
  redirectToThankYouPage = (status = true, orderId = 0, propsData) => {
    if (!propsData) return;
    removeGuestUser();
    const tenantID = getLoggedInUser().tenantID;
    let isRenewAnnualPass = propsData.paymentOptions.pageVariant === 'annualPassRenew';
    if (isRenewAnnualPass) {
      sessionStorage.removeItem('cartForAnnualPassRenewal');
      sessionStorage.removeItem(UIConfig.payloadForRenew);
      sessionStorage.removeItem('annualPassRenewalData');
    }
    let annualPassRenew = isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
    let summaryLink;
    if (tenantID !== 'ALLB2B') {
      localStorage.removeItem('yasIdUserData');
      summaryLink =
        propsData.paymentOptions.primaryCTA.url +
        '?' +
        UIConfig.querStringParams.thankYouPage.status +
        '=' +
        status +
        '&' +
        UIConfig.querStringParams.thankYouPage.orderId +
        '=' +
        btoa(orderId) +
        '&' +
        UIConfig.querStringParams.thankYouPage.reference +
        '=' +
        UIConfig.paymentOption.gPay +
        annualPassRenew;
      window.top.location.href = summaryLink;
    } else {
      summaryLink =
        propsData.paymentOptions.primaryCTA.url +
        '?' +
        UIConfig.querStringParams.thankYouPage.status +
        '=' +
        status +
        '&' +
        UIConfig.querStringParams.thankYouPage.orderId +
        '=' +
        encodeURIComponent(orderId) +
        '&' +
        UIConfig.querStringParams.thankYouPage.reference +
        '=' +
        UIConfig.paymentOption.gPay;

      window.top.location.href = summaryLink;
    }
  };

  /**
   *  Successfull Payment
   *  Send status to Native app
   */

  actionMobileApp = ({ status }) => {
    window.JSbridge.nativeCallback(
      massageMobileData({
        status: status,
        orderID: localStorage.getItem('orderId') || null,
        ref: UIConfig.paymentOption.gPay,
      }),
    );
  };

  /**
   *  Redirect action method
   *  if something fails
   */

  pageRedirectURL = ({ paymentData }) => {
    if (paymentData) {
      const { url, error } = paymentData;
      window.top.location.href = `${url.href}?payment_error=${error}#${UIConfig.paymentOption.gPay}`;
    }
    // } else {
    //   let errorData = getErrorDataInObject(key, this.gPayData.services, responseOrError);
    //   localStorage.setItem(UIConfig.localStoreKeys.payment.errorMessage, JSON.stringify(errorData));
    //   window.location.href = `${pageUrl.url}?${UIConfig.querStringParams.thankYouPage.status}=${status}&${UIConfig.querStringParams.thankYouPage.reference}=${UIConfig.paymentOption.gPay}`;
    // }
  };

  /**
   *  Successfull Payment
   *  Complete Payment and close Payment Sheet
   *  call reconcile to close order
   */

  redirectToActionPage = ({ isSuccess, status }) => {
    const {
        paymentOptions: { primaryCTA, paymentURL },
      } = this.paymentData.cmsData,
      {
        gpayMakePurchase: { errors },
      } = this.paymentData.cmsData.services;
    if (isSuccess) {
      const cartType = localStorage.getItem(UIConfig.parksTicketUpgrade.cartTypeLocalStorage) || '';
      const orderId = this.reservationId,
        isRenewAnnualPass = this.isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
      const ticketUpgrade = cartType === UIConfig.parksTicketUpgrade.cartType ? '&isTicketUpgrade=true' : '';
      Logging(this.reservationId, 'GPay_Component', true, 'Gpay pay 3DS success');
      if (!getUserAgent()) {
        window.location.href = `${primaryCTA.url}?${UIConfig.querStringParams.thankYouPage.status}=${status}&${UIConfig.querStringParams.thankYouPage.orderId}=${orderId}&${UIConfig.querStringParams.thankYouPage.reference}=${UIConfig.paymentOption.gPay}${isRenewAnnualPass}${ticketUpgrade}`;
      } else {
        this.actionMobileApp({ status });
      }
      this.clearStorage(cartType);
    } else {
      if (!getUserAgent()) {
        Logging(this.reservationId, 'GPay_Component', true, 'Gpay pay 3DS FAILURE');

        if (Object.keys(errors).length > 0 && errors[this.errorCode]) {
          const paymentData = {
            url: paymentURL,
            error: errors[this.errorCode],
          };
          this.pageRedirectURL({ paymentData });
        } else {
          const paymentData = {
            url: paymentURL,
            error: errors[this.paymentFailure[0]],
          };
          this.pageRedirectURL({ paymentData });
        }
      } else {
        this.actionMobileApp({ status });
      }
    }
  };

  /**
   *  if payment success, move to confirmation page
   *  if fails, redirect to payment journey
   */

  moveToNextPaymentStep = () => {
    if (this.isSuccess) {
      this.redirectToActionPage({
        isSuccess: true,
        status: true,
      });
    } else {
      this.redirectToActionPage({
        isSuccess: false,
        status: false,
      });
    }
  };

  /**
   *  clear stored data on localstorage
   */

  clearStorage = (cartType) => {
    if (this.isRenewAnnualPass) {
      sessionStorage.removeItem('cartForAnnualPassRenewal');
      sessionStorage.removeItem(UIConfig.payloadForRenew);
      sessionStorage.removeItem('annualPassRenewalData');
    }
    localStorage.removeItem('payment_info');
    localStorage.removeItem('payfort_data');
    localStorage.removeItem('isOrderHistoryPayment');
    localStorage.removeItem('cms_data');
    localStorage.removeItem('resCode');
    localStorage.removeItem('yasIdUserData');
    localStorage.removeItem('gpay_payment_data');
    if (cartType) {
      localStorage.removeItem(UIConfig.parksTicketUpgrade.cartTypeLocalStorage);
    }
    removeGuestUser();
  };
}

export const initializeGPay = (props) => {
  const paymentData = JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.payment.paymentInfo));
  const GPayData = props.data.paymentData;
  const paymentDataAnonymous = getAnonymousCart();
  const isSuccess = JSON.parse(parseQueryString('isSuccess'));
  const isPaymentCaptured = JSON.parse(parseQueryString('isPaymentCaptured'));
  const reservationId = parseQueryString('reservationId', false, window.location.href, true);
  const errorMsg = parseQueryString('errorMsg');
  const errorCode = parseQueryString('errorCode');

  const gPayPayment = new GPayPayment({
    GPayData,
    paymentDataAnonymous,
    isSuccess,
    isPaymentCaptured,
    reservationId,
    errorMsg,
    errorCode,
    paymentData,
  });
  gPayPayment.moveToNextPaymentStep();
};
