import React from 'react';
import classNames from 'classnames';
import DynamicContent from '../../../../presentation/base/dynamic-content/dynamic-content-component';
import RichText from '../../../../presentation/base/rich-text/rich-text-component';
import UIConfig from '../../../../../common/UIConfig';
import { checkTenant } from '../../../../../common/utility';
import { isParkTenant } from '../../../../../common/utility/tenantsUtils';

const PaymentCheckbox = ({
  stateData,
  paymentOptions,
  openOverlay,
  onTncChangeHandler,
  onNewsLetterChangeHandler,
  showNewsLetterCheckBoxOnly = false,
  onParkConsentChangeHandler,
  showParkConsentCheckbox = false,
  cartData,
}) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isMultiParkTicketExist = () => {
    if ((isParkTenant() || isSwad) && showParkConsentCheckbox && paymentOptions?.tAndcMultiParkBioConsent?.length) {
      return cartData?.items?.some((item) => item?.itemType?.toLowerCase() === 'mup');
    }
  };

  const renderTnCMandatoryError = () => {
    const showTnCError = stateData.hasTnCError && stateData.bindCheckBoxError;
    const errTxt = paymentOptions.errorMessageTNCRequired;

    if (showTnCError) return showTnCError && <span className="terms-error-msg body-copy-6">{errTxt}</span>;

    return null;
  };

  const paymentTnCClasses = classNames('c-payment-tnc tnc-check', { disabled: stateData.disableBookButton });
  const paymentCheckBoxClasses = classNames('checkbox', { 'checkbox-error-state': stateData.hasTnCError });
  const newsCheckBoxClasses = classNames(null, { 'swad-news-checkbox': isSwad });

  return (
    <div
      className={`${!stateData.showCheckBox ? 'no-border' : 'border'} ${
        showNewsLetterCheckBoxOnly && !checkTenant(UIConfig.iamMapping.ymc)
          ? 'payment-checkbox-wrapper news-letter-checkbox'
          : 'payment-checkbox-wrapper'
      }`}
    >
      {showNewsLetterCheckBoxOnly &&
        !stateData.hideNewsletterOption &&
        stateData.showCheckBox &&
        paymentOptions.newsLetterLabel !== '' && (
          <div className="c-payment-newsletter">
            <label htmlFor="paymentNewsletter" className="checkbox-label">
              <input
                type="checkbox"
                id="paymentNewsletter"
                disabled={parseInt(stateData.tabIndex)}
                className={newsCheckBoxClasses}
                onChange={() => {
                  onNewsLetterChangeHandler && onNewsLetterChangeHandler();
                }}
                checked={stateData.newsLetterEnabled}
              />
              <div className="checkbox"> </div>
              {paymentOptions.newsLetterLabel && (
                <RichText
                  data={{
                    bodyCopy: paymentOptions.newsLetterLabel,
                    attrs: { className: 'body-3' },
                  }}
                />
              )}
            </label>
          </div>
        )}
      {!showNewsLetterCheckBoxOnly && stateData.showCheckBox && (
        <div className={paymentTnCClasses}>
          <label htmlFor="paymentTnC" className="checkbox-label">
            <input
              type="checkbox"
              id="paymentTnC"
              checked={stateData.isTncChecked}
              onChange={onTncChangeHandler}
              disabled={parseInt(stateData.tabIndex)}
            />
            <div className={paymentCheckBoxClasses}> </div>
            <span className="body-3">
              <DynamicContent tagName="span" innerHtml={paymentOptions.tAndcLabel} />
              {paymentOptions.tncCtaTitle && (
                <DynamicContent
                  tagName="span"
                  attrs={{
                    className: 'tncLink',
                    onClick: () =>
                      openOverlay(
                        paymentOptions.termsAndcondition.tncTitle,
                        paymentOptions.termsAndcondition.tncDescription,
                      ),
                  }}
                  innerHtml={paymentOptions.tncCtaTitle}
                />
              )}
              {paymentOptions.andLabel && <DynamicContent tagName="span" innerHtml={paymentOptions.andLabel} />}
              {paymentOptions.privacyPolicyCtaTitle && (
                <DynamicContent
                  tagName="span"
                  attrs={{
                    className: 'tncLink privacyLink',
                    onClick: () =>
                      openOverlay(
                        paymentOptions.privacyPolicy.privacyPolicyTitle,
                        paymentOptions.privacyPolicy.privacyPolicyDescription,
                      ),
                  }}
                  innerHtml={paymentOptions.privacyPolicyCtaTitle}
                />
              )}
              {renderTnCMandatoryError()}
            </span>
          </label>
          {paymentOptions.mandetoryFieldLabel && (
            <div className="body-3 mandatory-label">{paymentOptions.mandetoryFieldLabel}</div>
          )}
        </div>
      )}
      {!!isMultiParkTicketExist() && (
        <div className={`${paymentTnCClasses} multiparkBioConsentCheckbox-wrapper`}>
          <label htmlFor="parkConsentCheckbox" className="checkbox-label">
            <input
              type="checkbox"
              id="parkConsentCheckbox"
              disabled={parseInt(stateData.tabIndex)}
              className={''}
              onChange={(e) => onParkConsentChangeHandler(e)}
              checked={stateData.parkConsentEnabled}
            />
            <div className="checkbox"> </div>
            {paymentOptions?.tAndcMultiParkBioConsent && (
              <RichText
                data={{
                  bodyCopy: paymentOptions?.tAndcMultiParkBioConsent,
                  attrs: { className: 'body-3' },
                }}
              />
            )}
          </label>
        </div>
      )}
    </div>
  );
};

export default PaymentCheckbox;
