import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { Logging } from '../../../common/logger';
import { CartService, ProfileServices, AnnualPassData } from '../../../common/services';
import UIConfig from '../../../common/UIConfig.js';
import {
  checkAnonymousCart,
  deepCloneObject,
  isAnnualPassCase,
  isEmpty,
  scrollToFormErrorOrSuccess,
  focusHandlerOnAccordion,
  setSessionStorage,
  getLoggedInUser,
  setWindowFunctions,
  parseQueryString,
  isMobileOrTab,
  getAnonymousCart,
  setLocalStorage,
  resolvePath,
  getLocalStorageByKey,
  isLoggedInUser,
  checkIfParks,
  isMatchTenant,
  checkTenant,
  isANPToANPUpgradation,
  isTicketUpgradePayment,
} from '../../../common/utility';
import { initValdiations, validateField, validateForm } from '../form/validator';
import Button from '../../presentation/base/button/button-component';
import DynamicContent from '../../presentation/base/dynamic-content/dynamic-content-component';
import ErrorSummary from '../../presentation/base/error-summary/error-summary-component';
import AnnualPassHolder from '../../presentation/annual-pass-holder';
import { logComponentRenderingError } from '../../../common/logger';

import * as helper from './helper';
import errorSummaryComponent from '../../presentation/base/error-summary/error-summary-component';
import GTMData from '../b2c-purchase-journey/gtm-data.js';

const Reset = (props) => {
  return (
    <div className="text-center">
      <div className="btn reset-button">
        <button type="reset" disabled={props.disabled}>
          <span>{props.data.label}</span>
        </button>
      </div>
    </div>
  );
};

class AnnualPassForm extends React.Component {
  mapper = {
    Button: Button,
    Submit: Button,
    Reset: Reset,
  };

  fieldMapper = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    gender: 'gender',
    mobileNum: 'mobileNumber',
    countryCode: 'countryCode',
    country: 'countryOfResidence',
    relation: 'relationship',
    nationality: 'nationality',
    emirate: 'emirate',
    Date2: 'dob',
    photo: 'photo',
  };

  constructor(props) {
    super(props);
    this.state = {
      serverErrors: null,
      success: false,
      data: props.data,
      sections: props.data.sections || [],
      errors: null,
      prefilled: [],
      displayMode: 'edit',
      showForm: false,
      toggleAnnualPass: true,
      openSections: { accordion_0_0: true },
      annualPassCaseNonLoggedInUser: false,
      detectedCountry: null,
      photoError: false,
      apiError: '',
      emailErrors: {},
      emailValidated: true,
    };
    this.resetData = null;
    this.services = props.data.services;
    this.cart = null;
    this.renewCartData = null;
    this.fieldMapping = {};
    this.payload = {};
    this.rules = [];
    this.setFocus = false;
    this.is2StepJourney = props.data.variant === 'v-form-accordion';
    this.isRenewAnnualPass = props.data.isRenewAnnualPass;
    this.isMobileOrTab = isMobileOrTab();
    this.disabledAriaLabel = props.data.disabledAriaLabel;
  }

  getTenantId = () => getLoggedInUser().tenantID;

  toggleIsRequiredRule = (dataField, status) => helper.toggleIsRequiredRule(this, dataField, status);

  toggleValidationRulesForSecondaryForm = (dataFields, sectionIndex, formIndex) =>
    helper.toggleValidationRulesForSecondaryForm(this, dataFields, sectionIndex, formIndex);

  toggleValidationRulesForSecondaryFormOnSubmit = () => helper.toggleValidationRulesForSecondaryFormOnSubmit(this);

  isTypeValid = (allowedFileTypes, file) => {
    let uploadedType = file.type.split('/')[1];
    return allowedFileTypes.includes(uploadedType);
  };

  onFocus = (e) => {
    let target = !e.target ? e : e.target;
    let id = target.id;
    const data = deepCloneObject(this.state.data);
    const detail = this.fieldMapping[id];
    data[detail.park].form[detail.row][detail.col].active = 'active';
    this.setState({ data });
  };

  onFieldChange = (e, field, blurForm) => {
    if ((checkIfParks() || checkTenant(UIConfig.iamMapping.swad)) && !checkTenant(UIConfig.iamMapping.ppad)) {
      sessionStorage.setItem('annualPassFormSuccess', false);
    }
    helper.onFieldChange(this, e, field, blurForm);
  };

  getCartData = () => {
    var checkUserType = checkAnonymousCart();
    let mashupCall = checkUserType ? CartService.getCartMashupAnonymous : CartService.getCartMashup;
    let mashupCallUrl = checkUserType ? this.services.getCartMashupAnonymous.url : this.services.getCartMashup.url;
    let mashupCallData = checkUserType ? getAnonymousCart() : null;
    return mashupCall('Guest details', mashupCallUrl, mashupCallData).then((res) => {
      let annualPasses = {};
      if (res.data.cart && res.data.cart.items) {
        this.cart = res.data;
        annualPasses = res.data.cart.items
          .filter((item) => item.itemType === 'ANP')
          .map((pass) => {
            return {
              PassID: pass.productId,
              PassType: pass.productName,
              Quantity: pass.quantity,
              GuestDetails: pass.guestDetails,
              ClassName: pass.classTypeName,
              ProdIdType: pass.productIdType,
            };
          });
      }
      return new Promise((resolve, reject) => {
        resolve(annualPasses);
      });
    });
  };

  onReset = (e) => {
    e.preventDefault();
    const resetData = JSON.parse(JSON.stringify(this.resetData));
    this.setState({
      data: resetData,
    });
    this.payload = helper.resetPayload(this.resetData, this.payload);
  };

  changeMode = (e) => {
    this.setState({
      displayMode: 'edit',
    });
    this.setFocus = true;
    this.togglePayment(false);
  };

  toggleRules = (field, action) => helper.toggleRules(this, field, action);

  checkError = (value, field) => {
    const payLoad = this.payload,
      errors = validateField([...this.rules], value, field, payLoad);
    return errors;
  };
  updateUserDetails = (cart) => {
    this.renewCartData = cart;
    let guestDetails = [];
    cart.cart.items.forEach((item) => {
      item.guestDetails.forEach((guestDetail) => {
        guestDetails.push(guestDetail);
      });
    });

    let payload = {
      items: [
        {
          ticketHolders: guestDetails,
        },
      ],
    };
    const config = {
      url: `${resolvePath(
        this.props.data,
        'services.updateaccountDetailsAnnualPass.url',
      )}?tenantid=${this.getTenantId()}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
      data: payload,
    };
    AnnualPassData.annualPassDataAPI(config)
      .then((res) => {
        if (res.data.ERROR.CODE === 200) {
          this.setState({
            apiError: '',
          });
          this.togglePayment(true);
          if (checkIfParks()) {
            GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
              name: this.props?.data?.formName || this.props?.data?.formType || '',
            });
          }
          this.renewCartData = this.removePhotoFromCart(this.renewCartData);
          setSessionStorage(UIConfig.annualpassSessionCart, JSON.stringify(this.renewCartData));
          window.PubSub.publish(UIConfig.events.ANNUAL_PASS_FORM_SUCCESS);
        }
      })
      .catch((err) => {
        let apiError = this.props.data.services.updateaccountDetailsAnnualPass.errors[err.error.code];
        if (apiError) {
          this.setState({
            apiError,
          });
        }
      });
  };

  removePhotoFromCart = (cart) => {
    cart.cart.items.map((item) => {
      if (item.guestDetails) {
        item.guestDetails.map((guestDetail) => {
          guestDetail.photo = '';
          return guestDetail;
        });
      }
      return item;
    });
    return cart;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const isRenewReadOnly = this.props.data.sections[2].fields[0].aPRenewReadOnly && this.props.data.isRenewAnnualPass;
    initValdiations();
    this.toggleValidationRulesForSecondaryFormOnSubmit();
    const errors = validateForm([...this.rules], { ...this.payload });
    let focusAligned = false,
      hasError = false,
      isANPToANPUgradationValidation = false;
    if (errors && !isEmpty(errors) && !isRenewReadOnly) {
      const data = deepCloneObject(this.state.data);
      for (const prop in errors) {
        if (errors.hasOwnProperty(prop)) {
          const detail = this.fieldMapping[prop];
          const error = errors[prop];
          isANPToANPUgradationValidation = isANPToANPUpgradation()
            ? prop?.split('_')[0] === this.fieldMapper.photo
            : false;
          if (error && !isEmpty(error) && !isANPToANPUgradationValidation) {
            if (Object.keys(error).length !== 0 && error.constructor === Object) {
              data[detail.park].form[detail.row][detail.col].showErrors = true;
              data[detail.park].form[detail.row][detail.col].message = errors[prop].message;
              data[detail.park].form[detail.row][detail.col].valid = errors[prop].valid;
              data[detail.park].form[detail.row][detail.col].photoError = errors[prop].message;
              hasError = true;
              let focusAfterTime = 10;
              if (checkIfParks()) {
                focusAfterTime = 1000;
              }
              if (!focusAligned) {
                setTimeout(function() {
                  if (prop.includes('Date2')) {
                    document
                      .querySelector(`#${prop}`)
                      .getElementsByTagName('input')[0]
                      .focus();
                  } else {
                    document.querySelector(`#${prop}`).focus();
                  }
                }, focusAfterTime);
                focusAligned = true;
              }
            }
          } else {
            data[detail.park].form[detail.row][detail.col].showErrors = false;
            data[detail.park].form[detail.row][detail.col].message = '';
            this.togglePayment(true);
          }
        }
      }
      if (hasError) {
        setTimeout(function() {
          scrollToFormErrorOrSuccess('error-msg');
        }, 0);
      }
      this.setState({
        data: data,
      });
      if (!isANPToANPUgradationValidation) {
        this.accordionOpenInCaseError(data);
        return;
      }
    }
    this.cart.cart.items.map((item) => {
      const passData = this.state.data.filter((pass) => pass.id === item.productId);
      const passes = [];
      passData[0] &&
        passData[0].form.forEach((dataList) => {
          const userDetail = {};
          for (const data of dataList) {
            if (!data.value && data['data-name'] === 'firstName') {
              return;
            }
            if (data.type === 'SelectWithText') {
              userDetail.countryCode = data.dropdownValue;
            }
            if (this.fieldMapper[data['data-name']] === 'photo') {
              if (data.value) {
                if (this.isRenewAnnualPass) {
                  userDetail[this.fieldMapper[data['data-name']]] = data.val;
                } else {
                  userDetail[this.fieldMapper[data['data-name']]] = {
                    fileName: data.value.fileName,
                    fileContent: data.val,
                  };
                }
              } else {
                userDetail[this.fieldMapper[data['data-name']]] = null;
              }
            } else {
              userDetail[this.fieldMapper[data['data-name']]] = data.value ? data.value : '';
            }
          }
          Object.keys(userDetail).length && passes.push(userDetail);
        });
      item.guestDetails = passes;
      return item;
    });
    let cart = this.cart;
    if (this.props.data.isRenewAnnualPass) {
      const cartData = JSON.parse(sessionStorage.getItem(UIConfig.annualpassSessionCart));
      cart.cart.items.forEach((item) => {
        cartData.cart.items.forEach((data) => {
          if (item.productId === data.productId && item.mediaId === data.mediaId) {
            item.guestDetails.map((guestDetail, index) => {
              guestDetail.accountId = data.guestDetails[index].accountId;
              return guestDetail;
            });
          }
        });
      });
      this.updateUserDetails(cart);
    } else {
      if (checkAnonymousCart()) {
        setSessionStorage(UIConfig.annualPassPurchaseCart, JSON.stringify(cart));
        cart.cart.reservationOwner = JSON.parse(localStorage.getItem('guestUser'));
        setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.anonymousCart, JSON.stringify(cart));
        if (!isTicketUpgradePayment()) {
          this.setState({
            displayMode: 'display',
          });
        }
        if (hasError && isTicketUpgradePayment()) {
          this.togglePayment(false);
          sessionStorage.setItem(UIConfig.sessionStorageKeys.ANNUAL_PASS_FORM_SUCCESS, false);
        } else {
          this.togglePayment(true);
        }
        if (checkIfParks()) {
          GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
            name: this.props?.data?.formName || this.props?.data?.formType || '',
          });
        }
      } else {
        CartService.updateCart(cart, 'GuestDetails', this.services.updateCart.url)
          .then((res) => {
            this.resetData = this.state.data;
            window.PubSub.publish(UIConfig.events.CART_DATA_WITH_PHOTO, {
              cartData: deepCloneObject(cart.cart),
            });
            Logging(res, 'GuestDetails', true, 'Cart Updated');
            this.togglePayment(true);
            if (checkIfParks()) {
              GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
                name: this.props?.data?.formName || this.props?.data?.formType || '',
              });
            }
            if (this.is2StepJourney) {
              cart = this.removePhotoFromCart(cart);
              setSessionStorage(UIConfig.annualPassPurchaseCart, JSON.stringify(cart));
              window.PubSub.publish(UIConfig.events.UPDATE_MYCART_CARTDATA, {
                cart: cart,
              });
            }
            window.PubSub.publish(UIConfig.events.ANNUAL_PASS_FORM_SUCCESS);
          })
          .catch((error) => {
            return error;
          });
      }
    }
    window.PubSub.publish(UIConfig.annualPassSavedChanges, { isChangesSaved: true });
  };

  formBuilder = (mapper, field, reset, index) => {
    const _submitClass = 'btn-primary';
    const isBtnDisabled = Object.keys(this.state.emailErrors).length > 0 ? true : false;
    const isRenewReadOnly = field.aPRenewReadOnly && this.props.data.isRenewAnnualPass;
    const form = () => {
      const Field = mapper[field.type];
      if (Field) {
        return (
          <Field
            key={index}
            data={field}
            className={field.type === 'Submit' && !field.class ? _submitClass : field.class}
            onChange={this.onFieldChange}
            reset={reset}
            onReset={this.onReset}
            controlled={true}
            disabled={(this.state.displayMode === 'display' ? -1 : 0) || isBtnDisabled || isRenewReadOnly}
            is2StepPayment
          />
        );
      }
      return null;
    };
    return form();
  };
  shouldBeEmpty = (dataName) =>
    dataName === 'firstName' || dataName === 'lastName' || dataName === 'gender' || dataName === 'Date2';

  formData = (data, userData) => {
    let rowCouter = 0,
      prevProdIdtype = '';
    if (this.is2StepJourney) {
      this.fieldMapping = {};
      this.payLoad = {};
      this.rules = [];
    }
    const newData = data.map((passList, index) => {
      const item = {};
      item.title = passList['PassType'] + ' - ' + passList['ClassName'];
      item.id = passList['PassID'];
      item.bodyCopyText = passList['bodyCopyText'];
      item.form = [];
      const quantity = Number(passList.Quantity);
      const populateFields = (count, quantity, sectionIndex) => {
        for (; count < quantity; count++) {
          let prefilled = null;
          if (passList.GuestDetails && passList.GuestDetails.length > 0 && passList.GuestDetails[count]) {
            prefilled = passList.GuestDetails[count];
            if (prefilled.city) {
              prefilled.emirate = prefilled.city;
            }
          }
          const sections = this.state.data.sections || this.state.sections;
          const dataItem = sections[sectionIndex].fields.map((field, fieldIndex) => {
            const dataField = Object.assign({}, field);
            if (this.is2StepJourney && !dataField.hasOwnProperty('showErrors')) {
              dataField.showErrors = false;
            }
            let fieldValue = '';
            if (prefilled) {
              if (dataField.id === 'photo') {
                var photoValue = prefilled[this.fieldMapper[field.name]];
                var base64Value = '';
                if (this.props.data.isRenewAnnualPass) {
                  if (typeof photoValue === 'object' || !photoValue) {
                    dataField.val = '';
                    dataField.gender = prefilled.gender;
                    photoValue = '';
                  } else {
                    base64Value = UIConfig.imagePrefix + photoValue;
                    dataField.val = photoValue;
                    dataField.gender = prefilled.gender;
                    photoValue = base64Value;
                  }
                } else {
                  if (photoValue && photoValue.fileContent) {
                    dataField.val = photoValue.fileContent;
                    base64Value = UIConfig.imagePrefix + photoValue.fileContent;
                    photoValue.fileContent = base64Value;
                  }
                }
                dataField.value = photoValue;
                dataField.active = 'active';
                fieldValue = prefilled[this.fieldMapper[field.name]];
              } else if (dataField.id === 'mobileNum') {
                if (prefilled[this.fieldMapper[field.name]]) {
                  dataField.dropdownValue = prefilled['countryCode'];
                  dataField.value = prefilled[this.fieldMapper[field.name]];
                  dataField.active = 'active';
                  dataField.val = prefilled[this.fieldMapper[field.name]].replace(dataField.dropdownValue, '');
                  fieldValue = prefilled[this.fieldMapper[field.name]].replace(dataField.dropdownValue, '');
                }
              } else {
                dataField.value = prefilled[this.fieldMapper[field.name]];
                dataField.active = 'active';
                dataField.val = prefilled[this.fieldMapper[field.name]];
                fieldValue = prefilled[this.fieldMapper[field.name]];
              }
            } else if (userData) {
              const fieldName = field.type === 'Date2' ? this.fieldMapper[field.name] : field.name;
              let alphaCode = field.name;

              if (field.name === 'country') {
                alphaCode = 'countryAlphaCode';
              } else if (fieldName === 'nationality') {
                alphaCode = 'nationalityAlphaCode';
              } else if (fieldName === this.fieldMapper.Date2) {
                alphaCode = this.fieldMapper.Date2;
              }
              if (fieldName === 'mobileNum' && userData[fieldName]) {
                if (userData['countryCode'] === null) {
                  dataField.dropdownValue = field.options[0].code;
                  dataField.value = userData[alphaCode] ? dataField.dropdownValue + userData[alphaCode] : '';
                } else {
                  dataField.dropdownValue = userData['countryCode'];
                  dataField.value = userData[alphaCode] ? userData[alphaCode] : '';
                }
                dataField.active = 'active';
                dataField.val = userData[fieldName].replace(dataField.dropdownValue, '');
                fieldValue = userData[fieldName].replace(dataField.dropdownValue, '');
              } else {
                dataField.value = userData[alphaCode] ? userData[alphaCode] : '';
                dataField.val = userData[fieldName];
                fieldValue = userData[fieldName];
                if (dataField.value) {
                  dataField.active = 'active';
                }
              }
              if (
                (sectionIndex !== 0 && this.shouldBeEmpty(field.id)) ||
                (index !== 0 && this.shouldBeEmpty(field.id))
              ) {
                dataField.value = '';
                dataField.active = '';
                dataField.val = '';
                fieldValue = '';
              }
            } else {
              dataField.value = '';
              dataField.active = '';
              dataField.val = '';
            }

            const fieldId = field.id + '_' + rowCouter;
            dataField.id = fieldId;
            dataField.name = fieldId;
            dataField['data-name'] = field.id;
            if (dataField.type.toLowerCase().indexOf('date') !== -1) {
              dataField.type = this.isMobileOrTab ? 'Date' : dataField.type === 'Date2' ? 'Date2' : 'Text';
              dataField.validations = this.isMobileOrTab ? [] : dataField.validations;
              if (dataField.value) {
                const format = this.isMobileOrTab
                    ? UIConfig.b2c.profile.dateFormat
                    : UIConfig.b2c.profile.dateDisplayFormat,
                  givenFormat =
                    !prefilled && sectionIndex === 0 && userData
                      ? UIConfig.b2c.profile.dateFormat
                      : this.isMobileOrTab
                      ? UIConfig.b2c.profile.mobileFormat
                      : UIConfig.b2c.profile.dateDisplayFormat,
                  dateVal = moment(helper.reverseDate(dataField.value, givenFormat), givenFormat).format(format);
                dataField.value = dateVal;
                dataField.val = dateVal;
                fieldValue = dateVal;
              }
            }
            if (rowCouter && this.is2StepJourney && !fieldValue) {
              ['email', 'country', 'nationality', 'mobileNum'].forEach((fieldvalue) => {
                if (fieldvalue === field.id) {
                  fieldValue = this.payload[field.id + '_0'];
                  return;
                }
              });
            }
            this.payload[fieldId] = fieldValue ? fieldValue : '';
            this.rules.push({
              name: fieldId,
              rules: {
                isRequired: dataField.isRequired,
                message: dataField.isRequiredMessage,
                type: dataField.type,
                isConfirm: dataField.isConfirm ? dataField.isConfirm : false,
                cMessage: dataField.isConfirmMessage ? dataField.isConfirmMessage : null,
                fieldToConfirm: dataField.fieldToConfirm ? dataField.fieldToConfirm : null,
                validation: dataField.validations,
                minDate: dataField.minDate,
                minMessage: dataField.isMinMessage,
                maxDate: dataField.maxDate,
                maxMessage: dataField.isMaxMessage,
              },
            });
            this.fieldMapping[fieldId] = { value: fieldValue, park: index, row: count, col: fieldIndex };
            return dataField;
          });
          rowCouter++;
          if (index === 0 && sectionIndex === 0) {
            dataItem.push('isPrimaryForm');
          }
          item.form.push(dataItem);
        }
      };

      if (passList['ProdIdType'] === prevProdIdtype) {
        populateFields(0, quantity, 1);
      } else {
        populateFields(0, 1, 0);
        populateFields(1, quantity, 1);
      }
      prevProdIdtype = passList['ProdIdType'];
      return item;
    });
    return newData;
  };

  togglePayment = (status) => {
    sessionStorage.setItem(UIConfig.sessionStorageKeys.ANNUAL_PASS_FORM_SUCCESS, status);
    window.PubSub.publish(UIConfig.events.CHECK_PAYMENT_ON_OFF);
  };

  profileData = (response, displayState, userRes) => {
    const dataList = this.formData(response, userRes);
    this.resetData = dataList;
    if (displayState === 'display') {
      this.togglePayment(true);
    } else {
      this.togglePayment(false);
    }
    this.setState({
      data: dataList,
      success: true,
      displayMode: displayState,
      showForm: true,
    });
    this.setSecondaryFormValue(['email', 'country', 'nationality', 'mobileNum']);
    this.disabledAnnualPassForm();
  };

  componentDidMount() {
    window.PubSub.subscribe(UIConfig.events.TIGGER_ANNUAL_PASS, (msg, cartData) => {
      if (cartData) {
        window.PubSub.publish(UIConfig.events.CART_DATA_WITH_PHOTO, {
          cartData: deepCloneObject(cartData.cart),
        });
      }
      let newCartData = deepCloneObject(cartData);
      if (cartData && !cartData.cart.items.length) {
        this.setState({ showForm: false });
        return;
      }

      if (this.is2StepJourney) {
        let annualPasses = this.formatCartData(cartData);
        this.getCartDataCallbackFn(annualPasses);

        if (!this.isRenewAnnualPass) {
          newCartData = this.removePhotoFromCart(newCartData);
          setSessionStorage(UIConfig.annualPassPurchaseCart, JSON.stringify(newCartData));
        }
      } else {
        this.getCartData().then((response) => {
          this.getCartDataCallbackFn(response);
        });
      }
    });
    setWindowFunctions();
    window.PubSub.subscribe(UIConfig.events.EXPRESS_CHECKOUT_ENABLED_ANNUAL_PASS, (msg, expressCheckoutAnnualPass) => {
      if (expressCheckoutAnnualPass)
        this.setState({
          expressCheckoutAnnualPass: true,
        });
    });
  }

  componentDidUpdate() {
    if (document.querySelector('.edit-btn') && document.querySelector('.user-is-tabbing')) {
      setTimeout(() => {
        document.querySelector('.edit-btn').focus();
      }, 0);
    } else if (document.querySelector('#SaveChanges') && document.querySelector('.user-is-tabbing') && this.setFocus) {
      document.querySelector('#firstName_0') && document.querySelector('#firstName_0').focus();
      this.setFocus = false;
    }
  }

  formatCartData = (cartData) => helper.formatCartData(this, cartData);

  /**
   * Desc: getCartData Callback function
   * @param (Array) response data either from getCartData call or from data passed from cart
   */
  getCartDataCallbackFn = (response) => {
    if (response && response.length) {
      const displayState = 'edit';

      if (this.props.data.services.getGuestProfile && this.props.data.services.getGuestProfile.url) {
        if (checkAnonymousCart() || this.props.data.isRenewAnnualPass) {
          this.profileData(response, displayState);
        } else {
          ProfileServices.GetGuestInfo(
            this.props.moduleName || 'Annual-pass-form',
            this.props.data.services.getGuestProfile.url,
          ).then((userRes) => {
            this.profileData(response, displayState, userRes.data);
          });
        }
      }
    } else {
      if (checkAnonymousCart() || response.length === 0) {
        this.setState({ showForm: false });
        return;
      } else {
        this.togglePayment(true);
      }
    }
  };

  accordion = (label) => helper.accordion(this, label);

  accordionOpenInCaseError = (data) => helper.accordionOpenInCaseError(this, data);

  getPrimaryFormValue = (copyFieldName) => helper.getPrimaryFormValue(this, copyFieldName);

  setSecondaryFormValue = (setFieldsValue) => helper.setSecondaryFormValue(this, setFieldsValue);

  /**
   * Desc: Disabled annual pass section in case of non logged in user
   *
   */
  disabledAnnualPassForm = () => {
    if (this.is2StepJourney && isAnnualPassCase()) {
      this.setState((state) => ({
        annualPassCaseNonLoggedInUser: true,
        displayMode: 'display',
      }));
    }
  };

  render() {
    try {
      let is2StepJourney = this.is2StepJourney;
      if (!this.state.success) {
        return null;
      }
      if (this.state.expressCheckoutAnnualPass) return <></>;

      let secGuestCount = 0;

      const annualPassClasses = classNames(
        `c-annual-pass annual-pass-form-wrapper ${this.isRenewAnnualPass ? 'renwal-journey' : ''}`,
        {
          'v-form-accordion': is2StepJourney,
        },
      );
      const annualPassContainer = classNames(null, {
        annualPassContainer: is2StepJourney,
        'form-logged-in-user': isLoggedInUser(),
      });
      const displayFormClass = classNames('c-form', { disabled: this.state.displayMode === 'display' });
      return (
        <div className={annualPassContainer}>
          {this.state.showForm && (
            <div className={annualPassClasses}>
              {this.state.errors && <ErrorSummary data={this.state.errors} />}
              <form className={displayFormClass} onSubmit={this.onSubmit} onReset={this.onReset}>
                {isLoggedInUser() && (
                  <DynamicContent
                    tagName="h3"
                    innerHtml={this.props?.data?.mainTitle?.replace(
                      '{firstName}',
                      resolvePath(getLoggedInUser(), 'userName', ''),
                    )}
                    attrs={{ className: 'heading-3 title' }}
                  />
                )}
                <DynamicContent
                  tagName="div"
                  attrs={{ className: 'fieldset-title' }}
                  innerHtml={this.props.data.title}
                />
                {this.state.data.map((item, index) => {
                  return (
                    <div
                      className={`guest-detail ${this.isRenewAnnualPass ? 'renew-pass' : ''}`}
                      key={index}
                      role="tablist"
                    >
                      <div className="passType">{item.title}</div>
                      {this.isRenewAnnualPass ? (
                        <DynamicContent
                          tagName="div"
                          attrs={{ className: 'bodycopy-text' }}
                          innerHtml={item.bodyCopyText}
                        />
                      ) : (
                        ''
                      )}
                      {item.form.map((form, formIndex) => {
                        let isPrimaryForm = form.indexOf('isPrimaryForm') > -1;
                        let displayName = '';
                        form.forEach((val) => {
                          if (val['data-name'] && val['data-name'] === 'firstName') {
                            displayName += val.value;
                            displayName += ' ';
                          }
                          if (val['data-name'] && val['data-name'] === 'lastName') {
                            displayName += val.value;
                          }
                        });
                        if (isPrimaryForm) {
                          secGuestCount = 0;
                        }

                        let isOpenSection = this.state.openSections['accordion_' + index + '_' + formIndex];
                        let isLoggedOut = this.state.annualPassCaseNonLoggedInUser;
                        if (!is2StepJourney) {
                          isOpenSection = true;
                        }

                        return (
                          <div
                            key={formIndex}
                            className={classNames(null, {
                              accordionSection: is2StepJourney,
                              isLoggedOut: isLoggedOut,
                              disableDiv: isLoggedOut,
                              isLoggedIn: !isLoggedOut,
                            })}
                          >
                            <DynamicContent
                              tagName="div"
                              attrs={{
                                'data-is-summary': true,
                                className: 'guestHeading',
                                'aria-expanded': isOpenSection,
                                role: 'tab',
                                tabIndex: `${this.state.displayMode === 'display' ? -1 : 0}`,
                                id: `accordion_header_${formIndex}`,
                                'aria-controls': `accordion_body_${formIndex}`,
                                onKeyDown: focusHandlerOnAccordion,
                                onKeyPress: (e) => {
                                  e.preventDefault();
                                  is2StepJourney && this.accordion.call(this, 'accordion_' + index + '_' + formIndex);
                                },
                                onClick:
                                  is2StepJourney && this.accordion.bind(this, 'accordion_' + index + '_' + formIndex),
                              }}
                              innerHtml={`${
                                this.isRenewAnnualPass
                                  ? displayName
                                  : this.props.data.sections[isPrimaryForm ? 0 : 1].sectionTitle
                              }${
                                this.isRenewAnnualPass
                                  ? ''
                                  : !isPrimaryForm
                                  ? '<span>&nbsp;' + ++secGuestCount + '</span>'
                                  : ''
                              }${
                                is2StepJourney
                                  ? isOpenSection
                                    ? '<span class="accordionHead expanded"></span>'
                                    : '<span class="accordionHead"></span>'
                                  : ''
                              }`}
                            />
                            {isOpenSection ? (
                              <AnnualPassHolder
                                key={index + '_' + formIndex}
                                data={form}
                                onFieldChange={this.onFieldChange}
                                onFieldBlur={(e, field) => this.onFieldChange(e, field, true)}
                                onFocus={this.onFocus}
                                mode={this.state.displayMode}
                                onReset={this.onReset}
                                isMobile={this.isMobileOrTab}
                                isOpenSection={isOpenSection}
                                formIndex={formIndex}
                                detectedCountry={this.state.detectedCountry}
                                toggleRules={this.toggleRules}
                                isRenewAnnualPass={this.props.data.isRenewAnnualPass}
                                photoError={this.state.photoError}
                                disabledAriaLabel={this.disabledAriaLabel}
                                emailValidated={this.state.emailValidated}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
                {(this.state.displayMode !== 'display' || this.state.annualPassCaseNonLoggedInUser) &&
                  this.props.data.sections[2]?.fields.map((item, index) => {
                    return this.formBuilder(this.mapper, item, new Date(), index);
                  })}
                {this.state.apiError && <div className="api-error">{this.state.apiError}</div>}
              </form>
              {this.state.displayMode !== 'edit' && !this.state.annualPassCaseNonLoggedInUser && (
                <div className="text-center">
                  <div className="edit-btn-wrapper">
                    <button
                      className="edit-btn"
                      onClick={this.changeMode}
                      disabled={this.state.annualPassCaseNonLoggedInUser}
                    >
                      {this.props.data.sections[3].fields[0].label}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'GuestCheckoutForm');
    }
  }
}

export default AnnualPassForm;
