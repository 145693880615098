import React, { useEffect, useState } from 'react';
import { canUseDOM, getSessionStorage, resolvePath, setSessionStorage, checkTenant } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { bodyClicks } from '../../../../common/analytics-events';
import DynamicContent from '../dynamic-content/dynamic-content-component';
import classNames from 'classnames';

export const ArrowToggle = ({ maxCount, currentIndex, onToggle, cardList = false, centerMode = false }) => {
  // let tabTrackId; //this variable is used to handles multiple component in same page
  const [tabTrackId, setTabTrackId] = useState(0);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const toggle = (toggleType) => {
    onToggle(toggleType, tabTrackId);
  };
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;

  const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
    if (checkfortenants) {
      bodyClicks(eventName, titleData, labelData);
    }
  };

  useEffect(() => {
    // this function is used to handles multiple component in same page

    let arrowToggleCount = JSON.parse(getSessionStorage('arrowToggleCount'));

    if (arrowToggleCount > 0) {
      arrowToggleCount++;
      setSessionStorage('arrowToggleCount', arrowToggleCount);
    } else {
      arrowToggleCount = 1;
      setSessionStorage('arrowToggleCount', 1);
    }
    setTabTrackId(arrowToggleCount);
  }, []);

  const decrementClasses = classNames('arrow-toggle btn-decrement ', { tab: !(isSwad && currentIndex < 1) });
  const incrementClasses = classNames('arrow-toggle btn-increment ', {
    tab: !(isSwad && currentIndex === maxCount - 1),
  });

  return (
    <div className={`arrow-toggle-container ${centerMode ? 'arrow-center' : ''}`}>
      <button
        id={`decrementBtn-${tabTrackId}`}
        className={decrementClasses}
        aria-label="btn-decrement"
        aria-hidden={isSwad && currentIndex < 1 ? 'true' : 'false'}
        value="decrement"
        onClick={(e) => {
          toggle(e.target.value);
          googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.buttonClick, e.target.value, currentIndex + 1);
        }}
        style={currentIndex < 1 ? { opacity: cardList && isSwad ? '0.15' : '0', pointerEvents: 'none' } : {}}
        tabIndex={isSwad && currentIndex < 1 ? '-1' : '0'}
      />

      {!(currentIndex === 0 && maxCount === 1) && (
        <>
          <DynamicContent
            tagName="label"
            innerHtml={`${currentIndex + 1}/${maxCount}`}
            attrs={{
              className: 'tabTrack',
              id: `tabTrack-${tabTrackId}`,
            }}
          />
        </>
      )}

      <button
        id={`incrementBtn-${tabTrackId}`}
        className={incrementClasses}
        aria-label="btn-increment"
        value="increment"
        onClick={(e) => {
          toggle(e.target.value);
          googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.buttonClick, e.target.value, currentIndex + 1);
        }}
        style={
          currentIndex === maxCount - 1 ? { opacity: cardList && isSwad ? '0.15' : '0', pointerEvents: 'none' } : {}
        }
        aria-hidden={!!(isSwad && currentIndex === maxCount - 1)}
        tabIndex={isSwad && currentIndex === maxCount - 1 ? '-1' : '0'}
      />
    </div>
  );
};
