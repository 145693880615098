/*
 * nav-component.js
 * This file contains code for Nav component, it renders some links and Mega Nav compnent
 * It is a smart component, which render Mega nav component, on click of it's link, it's state gets changed and it gets re-rendered
 * @licensor  Miral
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  canUseDOM,
  isDesktopViewPort,
  detectMobile,
  isMobileOrTab,
  checkGA4Tenants,
  checkTenant,
} from '../../../common/utility';
import { Menubar } from '../../../common/accessibility/MenuBarLinks';
import { AnchorLink } from '../base';
import MegaNav from '../mega-nav/mega-nav-component';
import MegaNavVariant from '../mega-nav/mega-nav-component-variant';
import { logComponentRenderingError } from '../../../common/logger';
import { headerClickAnalytics } from '../../../common/analytics-events';
import './nav-component.scss';
import './nav-component-ar.scss';
import MegaMobileNav from '../mega-nav/mega-mobile-nav-component';
import { arrowDown } from '../../../assets/img';
import GTMData from '../../container/b2c-purchase-journey/gtm-data';
import UIConfig from '../../../common/UIConfig';

/**
 * MegaNav is a component which will be rendered by Nav component, it expects its props from Nav component
 * @param    {object}    data     having data of MegaNav component
 */
const hideMegaNavClass = 'hide';
const showMegaNavClass = 'show';

const onHoverMenu = () => {
  if (!detectMobile()) {
    let menu = document.querySelector('.yas-island.transparentVariant');
    let menuSwad = document.querySelector('.swad.transparentVariant');
    document.getElementsByTagName('body')[0].classList.toggle('menu-hover'); // Using a class instead, see note below.
    if (menu) {
      menu.classList.toggle('menu-Link-hover');
    } else if (menuSwad) {
      menuSwad.classList.toggle('menu-Link-hover');
    }
  }
};
export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOnBody = this.handleClickOnBody.bind(this);
    this.onLoad = true;
    this.state = {
      currentContext: '',
      isFirstRender: true,
      isCollapsedMenu: '',
      headerAnalyticsCount: 0,
    };
    this.navData = [...this.props.data];
    this.activeSelectNavClassName = 'nav-selected';
  }

  componentDidMount() {
    const menubar = new Menubar(document.getElementById('globalNavigation'));
    menubar.init();

    this.setState({ isFirstRender: false });
  }

  componentDidUpdate() {
    if (!this.state.currentContext) {
      document.removeEventListener('click', this.handleClickOnBody);
    }
  }

  /**
   * Add hide class to all created mega nav
   */
  handleClickOnBody(e) {
    if (this.navContainer && !this.navContainer.contains(e.target)) {
      this.navData &&
        this.navData.forEach((item) => {
          const itemText = item.navInfo.itemText;
          if (this[itemText]) {
            this[itemText].class = hideMegaNavClass;
          }
        });

      this.setState({
        currentContext: '',
      });
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.onLoad = false;
    const currentValue = e.target.attributes['data-meganav'].value;
    this.navData &&
      this.navData.forEach((item) => {
        if (item.navInfo) {
          const itemText = item.navInfo.itemText;
          if (itemText === currentValue) {
            this[itemText] = item.navInfo;
          }
          if (this[itemText]) {
            this[itemText].class = hideMegaNavClass;
          }
        }
      });
    if (this.state.currentContext !== currentValue) {
      this[currentValue].class = showMegaNavClass;

      this.setState({
        currentContext: currentValue,
      });

      document.addEventListener('click', this.handleClickOnBody);
    } else {
      this.setState({
        currentContext: '',
      });

      document.removeEventListener('click', this.handleClickOnBody);
    }
  };

  isNavContent(items) {
    return (
      !this.onLoad && this[items.navInfo.itemText] && (items.navInfo.enableMegaNav || items.navInfo.enableColumnNav)
    );
  }

  googleAnalyticHeaderHandleClick = (event, data) => {
    if (checkGA4Tenants([], [UIConfig.tenants.ya])) {
      let payload = { menuCategory: data, menuCategory2: '' };
      this.setState({ headerAnalyticsCount: this.state.headerAnalyticsCount + 1 });
      GTMData.push(UIConfig.ga4Constants.CLICK_HEADER, payload);
    }
    if (checkTenant(UIConfig.iamMapping.yasisland)) {
      headerClickAnalytics(event, data);
    }
  };

  /**
   * @function renderMenItem - render mega nav items and their childrens
   * @param items - mega nav data
   */
  renderMenuItem = (items, variant) => {
    const itemText = items.navInfo.itemText;
    this.navData &&
      this.navData.forEach((item) => {
        if (item.navInfo && item.navInfo.itemText === itemText) {
          this[itemText] = item.navInfo;
        }
      });

    if (this[itemText] && (items.navInfo.enableMegaNav || items.navInfo.enableColumnNav)) {
      return (
        <div>
          {items.navInfo.enableColumnNav ? (
            <MegaNavVariant data={this[itemText]} variant={variant} />
          ) : (
            <MegaNav data={this[itemText]} />
          )}
        </div>
      );
    } else {
      return '';
    }
  };

  activeB2CLink = (items) => {
    if (!items.navInfo.navLinks) {
      return;
    }

    const paths = items.navInfo.navLinks
      .map((link) => link.linkData)
      .reduce((a, b) => a.concat(b), [])
      .map((l) => (l.ctaDetail ? l.ctaDetail.href : ''));
    paths.push(items.navInfo.navUrl);
    return this.state.isFirstRender ? false : canUseDOM() && paths.includes(window.location.pathname);
  };

  activeB2BClink = (item) => {
    let { enableMegaNav, navUrl } = item.navInfo;
    if (enableMegaNav) {
      navUrl = navUrl.split('/')[2];
      return window.location.pathname.includes(navUrl);
    }
    return window.location.pathname === navUrl;
  };

  renderB2CHeader(items, idx, activeSelectNav, multiColumns, hideNavLinks, variant) {
    activeSelectNav = canUseDOM() ? (this.activeB2CLink(items) ? this.activeSelectNavClassName : activeSelectNav) : '';
    if (items.navInfo.itemText === 'Manage Booking' && !isMobileOrTab()) {
      return null; // Return null to not render anything for this item
    }
    return (
      <li
        key={idx}
        className={
          'nav--wrapper-content nav--wrapper-' +
          (this[items.navInfo.itemText] ? this[items.navInfo.itemText].class : '') +
          (items.navInfo.enableColumnNav ? ' mega-nav-variant has-submenu' : '') +
          multiColumns
        }
        data-meganav={items.navInfo.itemText}
        role="none"
      >
        <span
          className="meganav-hoverable 011"
          data-meganav={items.navInfo.itemText}
          onMouseEnter={onHoverMenu}
          onMouseLeave={onHoverMenu}
        >
          <AnchorLink
            onClick={(e) => this.googleAnalyticHeaderHandleClick(e, items.navInfo.itemText)}
            className={`header-nav-link ${activeSelectNav}`}
            link={{
              attrs: {
                'data-meganav': items.navInfo.itemText,
                role: 'menuitem',
                'aria-haspopup': items.navInfo.navLinks.length ? 'true' : 'false',
                'aria-expanded': items.navInfo.navLinks.length ? 'false' : 'true',
              },
              href: items.navInfo.navUrl || '#',
              label: items.navInfo.itemText,
            }}
            tabIndex="0"
          />
          {(variant === 'v-header-yasisland' ||
            variant === 'v-header-swad' ||
            variant === 'v-header-multimeganav' ||
            variant === 'v-ya-header') &&
          (items.navInfo.navLinks.length > 0 || items.navInfo.introData !== '') && ( // making it conditionally available only when there are items inside meganav for mobile
              <span
                className="header-nav-link-expand"
                data-meganav={items.navInfo.itemText}
                onClick={
                  !hideNavLinks && (items.navInfo.enableMegaNav || items.navInfo.enableColumnNav)
                    ? this.handleClick
                    : ''
                }
              ></span>
            )}
          {!hideNavLinks && this.renderMenuItem(items, variant)}
        </span>
      </li>
    );
  }

  renderB2BHeader(items, idx, activeSelectNav, multiColumns, hideNavLinks) {
    activeSelectNav = canUseDOM() ? (this.activeB2CLink(items) ? this.activeSelectNavClassName : activeSelectNav) : '';
    return (
      <li
        key={idx}
        className={
          'nav--wrapper-content nav--wrapper-' +
          (this[items.navInfo.itemText] ? this[items.navInfo.itemText].class : '') +
          (!hideNavLinks && items.navInfo.enableColumnNav ? ' mega-nav-variant' : '') +
          multiColumns
        }
        data-meganav={items.navInfo.itemText}
      >
        <AnchorLink
          className={`header-nav-link ${activeSelectNav}`}
          link={{
            attrs: { 'data-meganav': items.navInfo.itemText },
            href: items.navInfo.navUrl || '#',
            label: items.navInfo.itemText,
          }}
          onClick={
            !hideNavLinks && (items.navInfo.enableMegaNav || items.navInfo.enableColumnNav) ? this.handleClick : ''
          }
        />
        {!hideNavLinks && this.isNavContent(items) ? (
          <div>
            {items.navInfo.enableColumnNav ? (
              <MegaNavVariant
                className={this[items.navInfo.itemText].class}
                data={this[items.navInfo.itemText]}
                variant={this.props.variant}
              />
            ) : (
              <MegaNav className={this[items.navInfo.itemText].class} data={this[items.navInfo.itemText]} />
            )}
          </div>
        ) : (
          ''
        )}
      </li>
    );
  }

  renderB2BHeaderNoMegaNav(items, idx, activeSelectNav, multiColumns, hideNavLinks) {
    activeSelectNav = canUseDOM() ? (this.activeB2BClink(items) ? this.activeSelectNavClassName : activeSelectNav) : '';
    return (
      <li
        key={idx}
        className={
          'nav--wrapper-content no-mega-nav-wrapper nav--wrapper-' +
          (this[items.navInfo.itemText] ? this[items.navInfo.itemText].class : '') +
          (!hideNavLinks && items.navInfo.enableColumnNav ? ' mega-nav-variant' : '') +
          multiColumns
        }
        data-meganav={items.navInfo.itemText}
      >
        <span className="meganav-hoverable" data-meganav={items.navInfo.itemText}>
          <AnchorLink
            className={`header-nav-link ${activeSelectNav}`}
            link={{
              attrs: { 'data-meganav': items.navInfo.itemText },
              href: items.navInfo.navUrl || '#',
              label: items.navInfo.itemText,
            }}
          />
          {!hideNavLinks && this.renderMenuItem(items) ? (
            <div>
              {items.navInfo.enableColumnNav ? (
                <MegaNavVariant
                  className={this[items.navInfo.itemText].class}
                  data={this[items.navInfo.itemText]}
                  variant={this.props.variant}
                />
              ) : (
                <MegaNav
                  className={this[items.navInfo.itemText].class + ' no-mega-nav'}
                  data={this[items.navInfo.itemText]}
                />
              )}
            </div>
          ) : (
            ''
          )}
        </span>
      </li>
    );
  }

  toggleCollapseMenu = (idx) => {
    this.setState({ isCollapsedMenu: this.state.isCollapsedMenu === idx ? '' : idx });
  };

  renderMobileNav = (items, idx, activeSelectNav) => {
    activeSelectNav = canUseDOM() ? (this.activeB2BClink(items) ? this.activeSelectNavClassName : activeSelectNav) : '';
    const itemText = items.navInfo.itemText;
    this.navData &&
      this.navData.forEach((item) => {
        if (item.navInfo && item.navInfo.itemText === itemText) {
          this[itemText] = item.navInfo;
        }
      });

    return (
      <li
        key={idx}
        className="nav--wrapper-content nav--wrapper- mega-nav-variant"
        data-meganav={items.navInfo.itemText}
      >
        <span className="meganav-hoverable" data-meganav={items.navInfo.itemText}>
          <div className="nav-parent-title">
            <AnchorLink
              className={`header-nav-link ${activeSelectNav}`}
              link={{
                attrs: { 'data-meganav': items.navInfo.itemText },
                href: items.navInfo.navUrl || '#',
                label: items.navInfo.itemText,
              }}
            />

            {this[items.navInfo.itemText]?.navLinks?.length !== 0 && (
              <span
                className={`custom-arrow-icon ${this.state.isCollapsedMenu === idx ? 'open' : 'close'}`}
                onClick={() => this.toggleCollapseMenu(idx)}
              >
                <span></span>
                <span></span>
              </span>
            )}
          </div>
          {this.state.isCollapsedMenu === idx && (
            <div>
              <MegaMobileNav
                className={this[items.navInfo.itemText].class + ' no-mega-nav'}
                data={this[items.navInfo.itemText]}
              />
            </div>
          )}
        </span>
      </li>
    );
  };

  renderNavHeader = (...props) => {
    const { variant, enableMobileNav, disableMegaNav } = this.props;
    const ifSelectedHeaderType =
      variant === 'v-header-b2c' ||
      variant === 'v-header-yasisland' ||
      variant === 'v-header-swad' ||
      variant === 'v-header-multimeganav' ||
      variant === 'v-ya-header';

    if (enableMobileNav) {
      return this.renderMobileNav(...props);
    }
    if (disableMegaNav) {
      return this.renderB2BHeaderNoMegaNav(...props);
    }
    if (ifSelectedHeaderType) {
      return this.renderB2CHeader(...props, variant);
    }
    return this.renderB2BHeader(...props);
  };

  render() {
    try {
      const ariaLabel = isDesktopViewPort() ? this.props.headerAriaLabelDesktop : this.props.headerAriaLabel;
      return (
        <nav className="nav" aria-label={ariaLabel}>
          <ul
            id="globalNavigation"
            className="nav--wrapper"
            ref={(nav) => {
              this.navContainer = nav;
            }}
            role="menubar"
            aria-label={ariaLabel}
          >
            {this.navData &&
              this.navData.map((items, idx) => {
                if (!items.navInfo) {
                  return null;
                }
                const activeSelectNav = items.navInfo.selectNav ? this.activeSelectNavClassName : '';
                let multiColumns = items?.navInfo?.navLinks?.length > 1 ? ' multi-columns' : '';
                return this.renderNavHeader(items, idx, activeSelectNav, multiColumns, this.props.hideNavLinks);
              })}
          </ul>
        </nav>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'Nav');
    }
  }
}

Nav.propTypes = {
  data: PropTypes.array.isRequired,
  variant: PropTypes.string,
};
