import classNames from 'classnames';
import React, { useRef, useReducer, useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { JSEncrypt } from 'jsencrypt';
import { checkoutAnalytics, contactUsAnalytics, setLoginGTM } from '../../../common/analytics-events';
import ApiWrapper from '../../../common/api-wrapper';
import { Logging } from '../../../common/logger';
import { CartService, OrderService } from '../../../common/services';
import UIConfig from '../../../common/UIConfig';
import Constants from '../../../common/UIConfig';
import {
  canUseDOM,
  deepCloneObject,
  getCurrentLanguage,
  getErrorMap,
  isAnnualPassCase,
  checkYasIdExist,
  checkInlineSignup,
  setMultipleLocalStorage,
  setLocalStorage,
  getAnonymousCart,
  getTouchPointsPurchaseInfo,
  getPointsPayPurchaseInfo,
  isLoggedInUser,
  encryptParam,
  getMainObject,
  resolvePath,
  isEmptyObject,
  getMoneyCardPurchaseInfo,
  isEmpty,
  getLeisureFacility,
  detectViewPort,
  setYasId,
  getAPIStateParam,
  getSessionStorage,
  getLoggedInUser,
  setSessionStorage,
  isMatchTenant,
  getExpressCheckoutPostPurchaseUser,
  deleteExpressCheckoutPostPurchaseUser,
  parseQueryString,
  openIAMOverlay,
  checkIfParks,
  getLanguageWithoutLocal,
  parseJwt,
  getLocalStorageByKey,
  isTicketUpgradePayment,
} from '../../../common/utility';
import CheckoutButtons from '../b2c-purchase-journey/cart/checkout-buttons-component';
import Form from '../form/form-component';
import ErrorSummary from '../../presentation/base/error-summary/error-summary-component';
import { logComponentRenderingError } from '../../../common/logger';
import SignInBox from '../../presentation/signin-box/signin-box';
import { AnchorLink, CollapsedView, DynamicContent } from '../../presentation/base';
import SwadBanner from '../../presentation/base/swad-banner';
import { guestCheckoutTenants } from '../../../common/utility/tenantsUtils';
import IAMPopup from '../../presentation/iam-popup/iam-popup';
import ManageBookingHowerBox from '../../presentation/managebooking-howerbox';
import Tooltip from '../../presentation/tooltip/tooltip-component';
import { DownloadSVG } from '../../../assets/img';
import { tickYWW } from '../../../assets/img';
import { tickQAW } from '../../../assets/img';
import GTMData from '../b2c-purchase-journey/gtm-data';

const GuestCheckoutForm = (props) => {
  const initialState = {
    serverErrors: null,
    success: false,
    errors: null,
    data: props.data,
    propData: null,
    displayMode: 'edit',
    toggleGuest: true,
    showGuestForm: ['expressCheckoutSignUp', 'expressCheckoutSignIn'].includes(props.data.formType), // this one needs to be false
    formType: props.data.formType === Constants.guestCheckoutDetails.guestCheckoutLogin,
    isManageBooking: props?.data?.formType == Constants?.guestCheckoutDetails?.manageBookingForm,
    isExpressChekoutForm: props.data.formType === 'expressCheckout',
    isInlineSignUpForm: false,
    yasIdExist:
      canUseDOM() && resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.YIB2C
        ? isLoggedInUser() && checkYasIdExist()
        : checkYasIdExist(),
    isExpressChekoutEnabled: false,
    disabledInlineSignUp: false,
    isExpressPostPurchaseForm: ['expressCheckoutSignUp', 'expressCheckoutSignIn'].includes(props.data.formType),

    isExpressPostPurchaseSignInForm: props.data.formType === 'expressCheckoutSignIn',
    isExpressPostPurchaseSignUpForm: props.data.formType === 'expressCheckoutSignUp',
    renderExpressPostPurchaseSignInForm: false,
    renderExpressPostPurchaseSignUpForm: false,
    renderSuccessMessage: false,
    apBannerData: null,
  };
  const services = props.data.services;
  const isYasArenaCheckout = props.data.variant === UIConfig.b2c.purchaseJourney.paymentVariants.yaGuestCheckout;
  const is2StepPayment = props.data.variant === UIConfig.commonVariant.checkoutVariant || isYasArenaCheckout;
  const isAnnualPass = useRef(false);
  const isPublicKey = props.data.extraParams?.publicKey;
  const isParks = checkIfParks();
  const [isPaymentLoader, setIsPaymentLoader] = useState(false);
  const isYAB2C =
    canUseDOM() &&
    localStorage.mainObj &&
    localStorage.mainObj.tenantID &&
    localStorage.mainObj.tenantID.toLowerCase() === UIConfig.tenants.ya;
  const isSwad = resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.SWADB2C;
  const reducer = (state, action) => {
    return { ...state, ...action.payload };
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingNotFound, setBookingNotFound] = useState(false);
  const [showManageBookingHowerBox, setShowManageBookingHowerBox] = useState();
  const [isExpressCheckoutTenant, setIsExpressCheckoutTenant] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [isParksExpressCheckout, setIsParksExpressCheckout] = useState(false);
  const isFwad = resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.tenants.fwad;
  const isWbw = resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.tenants.wbw;
  const isYww = resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.tenants.yww;
  const isQAW = resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.tenants.ppad;

  useEffect(() => {
    const isExpressCheckout = document.querySelector('.col-lg-4.col-md-12.col-sm-12 .component.c-my-cart-b2c');
    setIsParksExpressCheckout(!!isExpressCheckout && isParks);
  }, []);

  const getEncrytpedText = (val) => {
    if (!val) {
      return '';
    }
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(isPublicKey);
    const encryptedVal = encrypt.encrypt(val);
    return encryptedVal.toString();
  };

  const getUpdatedMiniCart = (msg, data) => {
    const { variant } = props.data;
    let cart = props.data;
    let cartData = getAnonymousCart();
    let showGuestForm =
      cartData && cartData.cart && (cartData.cart.items.length > 0 || cartData.cart.packages.length > 0);
    const userData = cartData && cartData.cart.reservationOwner !== null;
    if (userData) {
      cart = getFilledGuestForm({ guestData: cartData.cart.reservationOwner, data: cart });
    }
    if (variant === 'v-form-view-checkout') {
      let ctaCheckout = cart.sections.filter((section) => section.sectionClass === 'CheckOut')?.[0]?.fields;
      let toolTip = ctaCheckout?.[0]?.showToolTip ? ctaCheckout[0].tooltip : '';
      isAnnualPass.current = cartData && isAnnualPassCase(cartData);
      if (cart) {
        let propData = {
          data: {
            ctaCheckout,
            toolTip,
          },
          cart: cart.cart,
          errObj: null,
          bErrObj: null,
        };
        dispatch({
          payload: { data: cart, propData: { ...state.propData, ...propData, cart }, showGuestForm },
        });
      }
    } else {
      dispatch({
        payload: {
          data: cart,
          showGuestForm,
        },
      });
    }
  };
  const hideGuestForm = () => {
    const cartData = getAnonymousCart();
    let showGuestForm =
      cartData && cartData.cart && (cartData.cart.items.length > 0 || cartData.cart.packages.length > 0);
    dispatch({
      payload: {
        showGuestForm,
      },
    });
  };
  /**
   * triggers an API call to verify whether user has an exisitng YAS ID or not
   *
   * @param {string} emailId
   */
  const checkExistingYasID = (emailId) => {
    let serviceURL = props.data.services.checkEmailExist; //props.data.services.checkExistingYasId;
    let url = serviceURL.url + '?email=' + emailId;
    ApiWrapper.apiGateway({
      url: url,
      method: 'GET',
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    })
      .then((response) => {
        if (response.status) {
          if (!response.data)
            dispatch({
              payload: {
                renderExpressPostPurchaseSignUpForm: true,
              },
            });
          else
            dispatch({
              payload: {
                renderExpressPostPurchaseSignInForm: true,
              },
            });
        }
      })
      .catch((err) => {});
  };
  const checkIfEmailExostInPostPurchase = () => {
    if (state.isExpressPostPurchaseForm && getExpressCheckoutPostPurchaseUser()) {
      const postPurchaseEmail = resolvePath(getExpressCheckoutPostPurchaseUser(), 'Email', '');
      postPurchaseEmail && checkExistingYasID(postPurchaseEmail);
    }
  };

  const createAccountData = (payload) => {
    const { FirstName, LastName, Email, MobileNumber, Country, Nationality } = isTicketUpgradePayment()
      ? payload
      : getExpressCheckoutPostPurchaseUser();

    const user = {
      firstName: FirstName || '',
      lastName: LastName || '',
      email: Email || '',
      mobileNumber: MobileNumber || '',
      country: Country || '',
      nationality: Nationality || '',
      termsAndConditions: true,
      newsOffer: false,
      countryCode: '',
      password: payload.Password,
      userLang: getCurrentLanguage(),
      dob: '',
      personOfDetermination: false,
    };

    const clientId = '';
    const domain = (canUseDOM() && window.origin) || '';
    const source = 'website';
    const channelName = 'Website';

    return { user, clientId, domain, source, channelName };
  };
  const singUpGTMData = (user_id) => {
    return {
      event: 'sign_up',
      login_status: 'logged in',
      user_id: user_id,
      my_pass_id: user_id,
      user_type: 'new customer',
    };
  };
  /**
   * triggers an API to create or Login
   *
   *
   */
  const createAccountOrLogin = (payLoad) => {
    let mainObj = getMainObject();
    let serviceURL = props.data.services?.createUserYasId?.url;
    ApiWrapper.apiGateway({
      url: serviceURL,
      method: 'POST',
      preLoader: true,
      data: createAccountData(payLoad),
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    }).then((response) => {
      if (response.data && response.data.token) {
        setYasId(response.data);
        if (state.isExpressPostPurchaseSignUpForm && isParks) {
          GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
            name: 'SignUp',
            ...(isMatchTenant(UIConfig.tenants.ya) ? { loginType: getLoggedInUser()?.loginProvider || 'Custome' } : {}),
          });
        }
        let defaultStateVal = getAPIStateParam(),
          signInData = { state: defaultStateVal, id_token: response.data.token },
          signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
        // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
        if (signInURL) {
          OrderService.yasIdAutoLogin('YasIdAutoLogin', signInURL, UIConfig.loader.defaultPreLoaderTarget, signInData)
            .then((autoLoginRes) => {
              if (isTicketUpgradePayment()) {
                window.location.reload();
              } else {
                dispatch({
                  payload: {
                    renderSuccessMessage: true,
                  },
                });
              }
              deleteExpressCheckoutPostPurchaseUser();
              window.PubSub.publish(UIConfig.events.EXPRESS_CHECKOUT_PP_SIGNUP, true);
            })
            .catch((err) => {});
        }
      }
    });
  };
  useEffect(() => {
    checkIfEmailExostInPostPurchase();
    checkInlineSignup() && localStorage.removeItem(UIConfig.localStoreKeys.isInlineSignUpForm);
    window.PubSub.subscribe(UIConfig.events.ONCARTUPDATE, (msg, data) => {
      getUpdatedMiniCart();
    });
    window.PubSub.subscribe(UIConfig.events.MINICARTUPDATED, hideGuestForm);
    if (!state.isInlineSignUpForm) {
      isPasswordFieldExist(state.data);
    }
    canUseDOM() && didMountLogic();
    !is2StepPayment && window.PubSub.subscribe(UIConfig.events.TOGGLE_GUEST_CHECKOUT, disableGuest);
    if (is2StepPayment) {
      window.PubSub.subscribe(UIConfig.events.TOGGLE_GUEST_CHECKOUT_ANNUALCASE, () => {
        isAnnualPass.current = isAnnualPassCase();
      });
    }
    const mainObj = getMainObject();
    // Hide guest checkout form if cartId is not valid for YasIsland
    if (resolvePath(mainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C) {
      const cartId = getSessionStorage('cartId');
      const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');

      let showGuestForm = cartId || true;

      if (_isAddonAddedAfterBooking && _isAddonAddedAfterBooking == 'true') showGuestForm = true;

      dispatch({
        payload: {
          showGuestForm,
        },
      });
    }
    if (props.data.formType === 'expressCheckout') {
      setLocalStorage(UIConfig.events.EXPRESS_CHECKOUT_ENABLED, true);
      window.PubSub.publish(UIConfig.events.EXPRESS_CHECKOUT_ENABLED, true);
      if (isAnnualPassCase()) {
        window.PubSub.publish(UIConfig.events.EXPRESS_CHECKOUT_ENABLED_ANNUAL_PASS, true);
      }
      setLocalStorage(UIConfig.localStoreKeys.expressCheckoutLabels, JSON.stringify(props.data.checkoutAsGuestTab));
    }
    if (props.data.formType === 'guestCheckout') {
      deleteExpressCheckoutPostPurchaseUser();
    }
    window.PubSub.subscribe(UIConfig.events.EXPRESS_CHECKOUT_ENABLED, (msg, data) => {
      dispatch({
        payload: {
          isExpressChekoutEnabled: data,
          disabledInlineSignUp: true,
        },
      });
    });

    window.PubSub.subscribe(UIConfig.events.DISABLE_INLINE_SIGNUP, (msg, enabledData) => {
      dispatch({
        payload: {
          disabledInlineSignUp: enabledData?.enabled,
        },
      });
    });
    window.PubSub.subscribe(UIConfig.events.EXPRESS_CHECKOUT_AP_LABELS, (msg, data) => {
      dispatch({
        payload: {
          apBannerData: data,
        },
      });
    });
    setIsExpressCheckoutTenant(guestCheckoutTenants?.includes(getLoggedInUser()?.tenantID?.toLowerCase()));
    window.PubSub.subscribe('showHowerComponentYiManageBooking', (data) => {
      setShowManageBookingHowerBox(true);
    });
    window.PubSub.subscribe('hideHowerComponentYiManageBooking', (data) => {
      setShowManageBookingHowerBox(false);
    });
    sessionStorage.removeItem('confirmedEmail');
    return () => {
      window.PubSub.unsubscribe(UIConfig.events.ONCARTUPDATE);
      window.PubSub.unsubscribe(UIConfig.events.TOGGLE_GUEST_CHECKOUT);
      window.PubSub.unsubscribe(UIConfig.events.TOGGLE_GUEST_CHECKOUT_ANNUALCASE);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterTenantId = (fields) => {
    if (fields.id === 'tenant') {
      return fields.value;
    }
  };
  const sendMailForConfirmation = (response, params) => {
    // const obj = response.data.communicationPreferencesVO[0];
    const url = props.data.services.NewsletterSendMail.url;
    const tenantId = props.data.sections[0].fields.filter(filterTenantId)[0].value;
    ApiWrapper.experienceServices({
      url,
      method: 'POST',
      data: {
        Email: params.email,
        //  PreferenceId: obj.comPrefId,
        Domain: window.location.origin,
        Tenant: tenantId,
      },
    });
  };
  /**
   * On form submit success handler.
   * @param    {[Array]} data array of props objects
   *
   */
  const onFormSubmitSuccess = (response, params) => {
    dispatch({ payload: { success: true, errors: null } });
    const isInlineSignUpForm = checkInlineSignup();
    if (!isInlineSignUpForm && !state.isExpressChekoutForm) {
      sendMailForConfirmation(response, params);
    }
    contactUsAnalytics(null, params);
  };
  /* Error Framework Call */
  const errorFramework = (response, key) => {
    const errorMsgData = getErrorMap(key, props.data.errorData, false, response.error, state.serverErrors);
    dispatch({ payload: { errors: errorMsgData } });
  };
  const getFilledGuestForm = ({ guestData, data }) => {
    let shippingAddress =
      localStorage.getItem('seatedShippingAddress') || localStorage.getItem('seatedBillingAddress') ? true : false;
    const newData = {};
    if (guestData) {
      for (var key in guestData) {
        if (guestData.hasOwnProperty(key)) {
          newData[key.charAt(0).toUpperCase() + key.substr(1)] = guestData[key];
        }
      }
      data.sections &&
        data.sections[0].fields.forEach((field) => {
          if (newData.TermsAndConditions && field.type === 'Checkbox') {
            field.checked = JSON.parse(newData.TermsAndConditions);
          } else if (field.type === 'SelectWithText') {
            field.dropdownValue = newData.AdditionalObjVal;
          } else if (field.type === 'Password' && !state.yasIdExist && !shippingAddress) {
            newData[field.name] = '';
          }
          field.value = newData[field.name];
        });
      return data;
    }
  };
  const newFormdata = (guestData, isAutoSave) => {
    let data = deepCloneObject(state.data);
    const newData = getFilledGuestForm({ guestData, data });
    if (!isAutoSave) {
      dispatch({ payload: { data: newData, displayMode: state.displayMode === 'display' ? 'edit' : 'display' } });
      togglePayment(state.displayMode === 'display');
    } else if (is2StepPayment) {
      dispatch({ payload: { data: newData } });
    }
  };
  //Added for payment section disabled
  const disableEaPaymentForm = (payload, errorObj) => {
    const isRequiredFields = {
      FirstName: payload.FirstName,
      LastName: payload.LastName,
      Email: payload.Email,
      Country: payload.Country,
      Nationality: payload.Nationality,
      MobileNumber: payload.MobileNumber,
      Password: encryptParam(payload.Password),
    };
    let iserrorKeys = false;
    errorObj &&
      Object.keys(errorObj).length > 0 &&
      Object.keys(isRequiredFields).forEach((field) => {
        if (errorObj.hasOwnProperty(field)) {
          iserrorKeys = true;
        }
      });
    const inlinesignupErrors = !isEmptyObject(isRequiredFields) || iserrorKeys;
    setLocalStorage('inlineSignupHasError', inlinesignupErrors);
  };
  const autoSave = (payload, e, errorObj) => {
    let newAnnonymouscart = getAnonymousCart();
    const getMainObj = getMainObject();
    // let isGuestCheckout = guestCheckoutTenants.includes(getLoggedInUser()?.tenantID?.toLowerCase());
    if (payload) {
      let formdata = {
        firstName: payload.FirstName,
        lastName: payload.LastName,
        email: payload.Email,
        mobileNumber: payload.MobileNumber,
        country: payload.Country,
        nationality: payload.Nationality,
        additionalObjVal: payload.additionalObjVal,
        password: getEncrytpedText(payload.Password),
      };
      // if (state.isExpressChekoutEnabled && state.isExpressChekoutForm && isParks) {
      //   if (payload?.Email && !payload?.ContactEmail && !errorObj['Email']) {
      //     sessionStorage.removeItem('confirmedEmail');
      //     window.PubSub.publish('confirmEmailFire', {
      //       contactEmail: payload?.Email,
      //     });
      //   } else {
      //     window.PubSub.publish('confirmEmailFire', {
      //       contactEmail: '',
      //     });
      //   }
      // }

      if (isMatchTenant(UIConfig.YIB2C) || (state.isExpressChekoutEnabled && state.isExpressChekoutForm && isSwad)) {
        if (payload?.Email == payload?.ContactEmail && !errorObj['Email'] && !errorObj['ContactEmail']) {
          sessionStorage.removeItem('confirmedEmail');
          window.PubSub.publish('confirmEmailFire', {
            contactEmail: payload?.Email,
          });
        } else {
          window.PubSub.publish('confirmEmailFire', {
            contactEmail: '',
          });
        }
      }

      if (newAnnonymouscart) {
        newAnnonymouscart.cart.reservationOwner = formdata;
        setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.anonymousCart, JSON.stringify(newAnnonymouscart));
        newFormdata(payload, true);
      }
      if (resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.tenants.ya) {
        disableEaPaymentForm(payload, errorObj);
      }
      //In case of Yas Arena - email notification will be handled by viva
      if (
        e.target.id === 'Email' &&
        payload.Email !== '' &&
        !isYasArenaCheckout &&
        props.data.services.updateCart &&
        !state.isExpressChekoutForm &&
        !state.isExpressPostPurchaseForm &&
        !isTicketUpgradePayment()
      ) {
        let serviceURL = props.data.services.updateCart.url + '?uid=' + payload.Email;
        CartService.updateGuestCart(newAnnonymouscart, 'cartAbandonment', serviceURL, true)
          .then((res) => {
            Logging(res, 'guestCheckoutForm', true, 'Cart Abandonment Successful');
          })
          .catch((err) => {
            Logging(err, 'guestCheckoutForm', false, 'Error in Cart Abandonment Service');
          });
      }
    }
  };

  // #region travelbox passenger related functions
  const getUserId = () => {
    let userId = getSessionStorage('packageSummaryData');
    if (userId) return JSON.parse(userId).userId;
  };

  const getPassengerObject = (_params, _idToken = '') => {
    let userId = getUserId();

    let passengerObject = {
      Title: 'Mr.',
      FirstName: _params.FirstName,
      LastName: _params.LastName,
      // email: isMatchTenant(UIConfig.YIB2C) ? payload?.EmailAddress1  : payload.Email,
      Email: _params.Email,
      Phone: _params.MobileNumber,
      CountryOfResidence: _params.Country,
      Nationality: _params.Nationality,
      UserId: userId,
    };

    let mobileNumberWithCode = _params.MobileNumber;
    let countryCodeFromPayload = _params.additionalObjVal;
    let mobileNumberWithoutCode = mobileNumberWithCode.replace(countryCodeFromPayload, '');

    passengerObject.Phone = `${countryCodeFromPayload} ${mobileNumberWithoutCode}`;

    if (_idToken) passengerObject.AuthToken = _idToken;

    const specialRequests = getSessionStorage('specialRequests');

    if (specialRequests) {
      passengerObject.SpecialRequests = JSON.parse(specialRequests);
    }

    const PassengerDetailsOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(passengerObject),
    };

    return PassengerDetailsOptions;
  };
  // #endregion

  const getCommuniationCall = (userDetails, params, data) => {
    if (userDetails.user.NewsOffer && userDetails.user.TermsAndConditions && !isLoggedInUser()) {
      const apiData = services.NewsletterSubscriptionAPI;
      const { tenantID, yasId = '' } = getLoggedInUser();
      const { email, phone, sms, whatsApp, website, mobile } = UIConfig.CMPconsent;
      ApiWrapper.apiGateway({
        url: apiData.url.replace(':email', params?.Email),
        method: data.method ? data.method : 'POST',
        data: {
          emailId: params?.Email,
          // email: isMatchTenant(UIConfig.YIB2C) ? payload?.EmailAddress1  : payload.Email,

          myPassId: yasId,
          channelName: tenantID,
          consentPurpose: [
            { [email]: true },
            { [phone]: true },
            { [sms]: true },
            { [whatsApp]: true },
            { [website]: true },
            { [mobile]: true },
          ],
          source: '',
        },
        preLoader: true,
        preLoaderTarget: '.newsletter-signup-container',
      }).then((response) => {
        if (typeof response.data === 'object' && response.status === 201) {
          onFormSubmitSuccess(response, params);
        }
      });
    }
  };
  const callConsent = (idToken) => {
    if (!idToken) return;
    const data = parseJwt(idToken);
    const tenant_id = getLocalStorageByKey('mainObj')?.tenantID;

    const guestEmail = data['emails'];

    let qData = {
      emailId: typeof guestEmail === 'string' ? guestEmail : (guestEmail.length && guestEmail[0]) || '',
      channelName: tenant_id,
      language: getLanguageWithoutLocal(),
      consentPurpose: [
        {
          email_consent: true,
        },
        {
          phone_consent: true,
        },
        {
          sms_consent: true,
        },
        {
          whatsApp_consent: true,
        },
        {
          website_consent: true,
        },
        {
          mobile_consent: true,
        },
      ],
      source: '',
    };
    const { MarketingConsentUpdateAPI } = props.data.services;

    ApiWrapper.apiGateway({
      method: 'POST',
      url: `${MarketingConsentUpdateAPI.url}`,
      data: qData,
      moduleName: 'Consent API',
      preLoader: true,
      preLoaderTarget: '.container',
    })
      .then((response) => {
        if (typeof response.data === 'object' && response.status === 201) {
          Logging(response.data, 'Marketing Consent Update', true, 'Logged In User');
          localStorage.setItem(UIConfig.localStoreKeys.loggedInCount, 1);
          window.location.reload();
        }
      })
      .catch((error) => {});
  };
  const createAnnonymousOrder = (action = 'reload', payLoad = null) => {
    const data = props.data;
    let mainObj = getMainObject(),
      autoLoginStatus = resolvePath(mainObj, 'additionalProperty.enableAutoLogin'),
      autoLoginParam = `isAutoLogin=${autoLoginStatus}`,
      extraParams = null;

    if (autoLoginStatus) {
      if (extraParams) {
        extraParams += `&${autoLoginParam}`;
      } else {
        extraParams = autoLoginParam;
      }
    }

    OrderService.createAnnonymousOrder(
      '',
      data.services?.createOrderYasId?.url,
      UIConfig.loader.defaultPreLoaderTarget,
      getAnonymousCart(),
      extraParams,

      data.services.updateCart,
    )
      .then((res) => {
        if (res.data.orderID) {
          setYasId(res.data);
          let idToken = resolvePath(res.data, 'id_token'),
            defaultStateVal = getAPIStateParam(),
            signInData = { state: defaultStateVal, id_token: idToken },
            signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
          // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
          if (signInURL && idToken) {
            OrderService.yasIdAutoLogin('YasIdAutoLogin', signInURL, UIConfig.loader.defaultPreLoaderTarget, signInData)
              .then((autoLoginRes) => {
                localStorage.removeItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED);
                setSessionStorage('RegistrationComplete', true);
                if (payLoad?.MarketingConsent === 'true') {
                  callConsent(idToken);
                  if (isParks) {
                    GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
                      name: 'SignUp',
                      ...(isMatchTenant(UIConfig.tenants.ya)
                        ? { loginType: getLoggedInUser()?.loginProvider || 'Custome' }
                        : {}),
                    });
                  }
                  return;
                }
                if (isParks) {
                  GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
                    name: 'SignUp',
                    ...(isMatchTenant(UIConfig.tenants.ya)
                      ? { loginType: getLoggedInUser()?.loginProvider || 'Custome' }
                      : {}),
                  });
                }
                localStorage.setItem(UIConfig.localStoreKeys.loggedInCount, 1);
                if (action === 'reload') window.location.reload();
              })
              .catch((err) => {});
          } else {
          }
        }
      })
      .catch((response) => {});
  };

  const submitGuestCheckout = (errors, payLoad) => {
    if (state?.isManageBooking && payLoad?.BookingRefId) {
      const url = state?.data?.services?.manageBooking?.url;
      const createCartRequestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Context-Emailid': payLoad?.Email,
        },
      };

      const bookingId = btoa(payLoad?.BookingRefId);
      setIsLoading(true);

      fetch(`${url}/${bookingId}/en`, createCartRequestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`Server returned status: ${response.status}`);
          }
        })
        .then((data) => {
          if (data && data?.BookingStatus !== 0) {
            let confirmationSummaryData = JSON.parse(JSON.stringify(data));
            if (confirmationSummaryData?.Booking?.Product) {
              confirmationSummaryData.Booking.Products = confirmationSummaryData?.Booking?.Product;
              delete confirmationSummaryData?.Booking?.Product;
            }
            if (confirmationSummaryData.Booking) {
              confirmationSummaryData.Cart = confirmationSummaryData.Booking;
              delete confirmationSummaryData.Booking;
            }
            confirmationSummaryData.bookingId = payLoad?.BookingRefId;
            confirmationSummaryData.Email = payLoad?.Email;
            // setPackageSummaryResult(confirmationSummaryData);
            let packageData = JSON.stringify(confirmationSummaryData);
            setSessionStorage('manageBookingPackageSummary', packageData);

            window.PubSub.publish('manageBookingDataFetchedSuccess', true);
            window.location.href = props.data?.redirecturl;
            window.PubSub.publish('specialNotesDataPublish', data);
          } else if (data && data?.BookingStatus === 0) {
            window.PubSub.publish('bookingNotFound', data);
            setBookingNotFound(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error('An error occurred:', error);
        });
    } else {
      const data = props.data;
      if (state.isExpressPostPurchaseForm && getExpressCheckoutPostPurchaseUser()) {
        if (state.isExpressPostPurchaseSignUpForm) createAccountOrLogin(payLoad);
        if (state.isExpressPostPurchaseSignInForm) {
          if (data.signIn?.loginCta?.href) {
            openIAMOverlay(<IAMPopup loginURL={data.signIn?.loginCta?.href} />);
          }
        }
        return;
      }
      if (state.isExpressChekoutEnabled && isAnnualPassCase()) {
        const serverErrorElement = document.getElementsByClassName('yasid-email-error server-error');
        if (serverErrorElement && serverErrorElement.length > 0) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return false;
        }
      }
      let showLoader = true;
      let params = { ...payLoad };
      const mainObj = getMainObject();
      let serviceURL = data.services.updateCart && data.services.updateCart.url + '?uid=' + params.Email;
      if (!data) {
        return null;
      }
      if (resolvePath(mainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C) {
        setIsPaymentLoader(true);
      }
      let newAnnonymouscart = getAnonymousCart();
      let additionalObjVal;
      if (newAnnonymouscart && newAnnonymouscart.cart.reservationOwner.additionalObjVal) {
        additionalObjVal = newAnnonymouscart.cart.reservationOwner.additionalObjVal;
      }
      const selectedPaymentNode = document.getElementsByClassName('c-payment-items selected-mode');
      let selectedPayMethod;
      if (selectedPaymentNode.length > 0) {
        selectedPayMethod = selectedPaymentNode[0].dataset.value;
      }
      setSessionStorage('bookingemail', params?.Email);
      let formdata = {
        firstName: params.FirstName,
        lastName: params.LastName,
        email: params?.Email,
        // email: params.Email,
        mobileNumber: params.MobileNumber,
        country: params.Country,
        nationality: params.Nationality,
        password: getEncrytpedText(params.Password),
        TermsAndConditions:
          is2StepPayment && document.getElementById('paymentTnC')
            ? document.getElementById('paymentTnC').checked
            : params.TermsAndConditions || false, // if TnC is undefined, send false
        NewsOffer: is2StepPayment && document.getElementById('paymentNewsletter')?.checked,
      };

      //// Code for new flow ////
      // Create user code while checkout
      if (resolvePath(mainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C) {
        const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
        const userDetails = {
          user: {
            ...formdata,
            password: getEncrytpedText(params.Password),
            newsOffer: params.ReceiveUpdatesEmail,
            userLang: getCurrentLanguage(),
            countryCode: params.additionalObjVal,
          },
          clientId: props.data.signUpConfig.clientId,
          creationType: props.data.signUpConfig.creationType,
          domain: props.data.signUpConfig.domain,
        };

        if (state.isInlineSignUpForm && !props.data.isGuestCheckout) {
          const createUserOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(userDetails),
          };

          // Create user
          fetch(services.createYasId.url, createUserOptions)
            .then((response) => response.json())
            .then((data) => {
              const _idToken = resolvePath(data, 'token');
              let isPassengerError = false;
              // let userId = getUserId();

              setYasId(data);

              // Don't call setPassengerDetails api if this is post booking journey
              if (!_isAddonAddedAfterBooking || _isAddonAddedAfterBooking == 'false') {
                const passengerUrl = services.SetPassengerDetails.url.replace('{cartId}', getSessionStorage('cartId'));
                fetch(passengerUrl, getPassengerObject(params, _idToken))
                  .then((res) => {
                    if (!res.ok && res.statusText) {
                      isPassengerError = true;
                    }

                    // Code will now run after successfull passenger call to make it sync - start
                    let mainObj = getMainObject(),
                      idToken = _idToken,
                      defaultStateVal = getAPIStateParam(),
                      signInData = { state: defaultStateVal, id_token: idToken },
                      signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');

                    // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
                    if (signInURL && idToken) {
                      OrderService.yasIdAutoLogin(
                        'YasIdAutoLogin',
                        signInURL,
                        UIConfig.loader.defaultPreLoaderTarget,
                        signInData,
                      )
                        .then((autoLoginRes) => {
                          if (UIConfig.payfort.payfortTypes.find((item) => item === selectedPayMethod)) {
                            const currencyId = getSessionStorage('GA_CurrencyId') || '';
                            checkoutAnalytics(currencyId, 'payment', 'Payfort');
                          }
                          if (isPassengerError) {
                            return;
                          }
                          window.PubSub.publish('dataPosted', {
                            isDataPosted: true,
                          });
                        })
                        .catch((err) => {
                          /* do nothing */

                          setIsPaymentLoader(false);
                        });
                    }
                    // Code will now run after successfull passenger call to make it sync - end

                    Logging(res, 'guestCheckoutForm', true, 'Passenger Call Success');
                  })
                  .catch((err) => {
                    console.error('error in set passenger api', err);
                    throw new Error(err);
                  });
              }
            })
            .catch(() => {
              setIsPaymentLoader(false);
            });

          // Newsletter subscription code
          getCommuniationCall(userDetails, params, data);
        } else {
          //guest checkout flow
          getCommuniationCall(userDetails, params, data);

          localStorage.setItem('guestCheckoutUserDetails', JSON.stringify(params));

          // Don't call setPassengerDetails api if this is post booking journey
          if (!_isAddonAddedAfterBooking || _isAddonAddedAfterBooking == 'false') {
            const passengerUrl = services.SetPassengerDetails.url.replace('{cartId}', getSessionStorage('cartId'));

            fetch(passengerUrl, getPassengerObject(params))
              .then((res) => {
                if (!res.ok && res.statusText) {
                  return;
                }
                window.PubSub.publish('dataPosted', {
                  isDataPosted: true,
                });
              })
              .catch((err) => {
                console.error('error in set passenger api', err);
                throw new Error(err);
              });
          } else {
            window.PubSub.publish('dataPosted', {
              isDataPosted: true,
            });
          }
        }

        return;
      }
      ////////
      if (newAnnonymouscart) {
        newAnnonymouscart.cart.reservationOwner = formdata;
        if (checkInlineSignup()) {
          newAnnonymouscart.cart.domain = window.location.origin;
          newAnnonymouscart.cart.yasId = localStorage.getItem(UIConfig.localStoreKeys.yasIdUserData) || '';
          newAnnonymouscart.cart.clientId =
            localStorage.mainObj && JSON.parse(localStorage.mainObj).additionalProperty.client_id;
        }
        if (additionalObjVal) {
          newAnnonymouscart.cart.reservationOwner.additionalObjVal = additionalObjVal;
        }
        newAnnonymouscart.cart.hasOwnProperty('touchpointPayOption') &&
          delete newAnnonymouscart.cart.touchpointPayOption;
        newAnnonymouscart.cart.hasOwnProperty('pointsPayCategory') && delete newAnnonymouscart.cart.pointsPayCategory;
        newAnnonymouscart.cart.hasOwnProperty('moneyCardPayOption') && delete newAnnonymouscart.cart.moneyCardPayOption;
        if (selectedPayMethod === UIConfig.paymentOption.adcbTouchPoints) {
          const touchPointsCardInfo = getTouchPointsPurchaseInfo();
          if (touchPointsCardInfo && touchPointsCardInfo.redeemAmount > 0) {
            newAnnonymouscart.cart.touchpointPayOption = {
              cardNo: touchPointsCardInfo.cardNum,
              cardExpiry: touchPointsCardInfo.expiry,
              custName: params.FirstName,
              paymentType: touchPointsCardInfo.paymentType,
              redeemAmount: touchPointsCardInfo.redeemAmount,
              cardAmount: touchPointsCardInfo.cardAmount,
            };
          }
        } else if (
          [UIConfig.paymentOption.pointsPayEtihad, UIConfig.paymentOption.pointsPayEtisalat].includes(selectedPayMethod)
        ) {
          const pointsPayCategoryInfo = getPointsPayPurchaseInfo();
          if (pointsPayCategoryInfo) {
            newAnnonymouscart.cart.pointsPayCategory = pointsPayCategoryInfo;
          }
        } else if (selectedPayMethod === UIConfig.paymentOption.moneyCard) {
          const moneyCardInfo = getMoneyCardPurchaseInfo();
          if (moneyCardInfo && !isEmpty(moneyCardInfo)) {
            newAnnonymouscart.cart.moneyCardPayOption = {
              //cardNo: moneyCardInfo.cardNum,
              //cardExpiry: moneyCardInfo.expiry,
              custName: params.firstName,
              paymentType: moneyCardInfo.paymentType,
              redeemAmount: moneyCardInfo.redeemAmount,
              cardAmount: moneyCardInfo.cardAmount,
            };
          }
        }
        setMultipleLocalStorage({
          [UIConfig.localStoreKeys.purchaseJourney.anonymousCart]: JSON.stringify(newAnnonymouscart),
          guestUser: JSON.stringify(formdata),
          guestUserData: JSON.stringify(formdata),
        });
        if (is2StepPayment) showLoader = false;
        // Update guest cart with annonymouscart
        const anonymousCart = getAnonymousCart();
        const isTicketUpgradeCart = anonymousCart?.cart?.isTicketUpgradeCart || false;

        if (state.isExpressChekoutEnabled || isTicketUpgradeCart) {
          !isAnnualPassCase() &&
            window.PubSub.publish('dataPosted', {
              isDataPosted: true,
            });
          if (isAnnualPassCase()) {
            if (isTicketUpgradePayment()) {
              createAccountOrLogin(payLoad);
            } else {
              createAnnonymousOrder('reload', payLoad);
            }
          }
          if (state.isExpressChekoutForm) {
            setLocalStorage(UIConfig.localStoreKeys.expressCheckoutPostPurchaseUser, JSON.stringify(payLoad));
          }
        } else {
          CartService.updateGuestCart(newAnnonymouscart, 'guestCheckoutForm', serviceURL, showLoader)
            .then((res) => {
              Logging(res, 'guestCheckoutForm', true, 'Cart Details posted');
              window.PubSub.publish('dataPosted', {
                isDataPosted: true,
              });
            })
            .catch((response) => {
              window.PubSub.publish('dataPosted', {
                isDataPosted: false,
              });
              errorFramework('guestUserPostCart', response);
            });
        }
        if (!is2StepPayment) {
          const diabledFormData = deepCloneObject(state.data);
          diabledFormData.class = 'disabledstate';
          dispatch({
            payload: {
              data: diabledFormData,
              displayMode: 'display',
            },
          });
          newFormdata(payLoad);
          togglePayment(true);
        }
      }
      if (formdata.TermsAndConditions && formdata.NewsOffer && !isLoggedInUser() && !checkInlineSignup()) {
        const apiData = services.NewsletterSubscriptionAPI;
        const { tenantID, yasId = '' } = getLoggedInUser();
        const { email, phone, sms, whatsApp, website, mobile } = UIConfig.CMPconsent;
        ApiWrapper.apiGateway({
          url: apiData.url.replace(':email', params.Email),
          method: data.method ? data.method : 'POST',
          data: {
            emailId: params.Email,
            myPassId: yasId,
            channelName: tenantID,
            consentPurpose: [
              { [email]: true },
              { [phone]: true },
              { [sms]: true },
              { [whatsApp]: true },
              { [website]: true },
              { [mobile]: true },
            ],
            source: '',
          },
          preLoader: true,
          preLoaderTarget: '.newsletter-signup-container',
        }).then((response) => {
          if (typeof response.data === 'object' && response.status === 201) {
            onFormSubmitSuccess(response, params);
          }
        });
      }
    }
  };
  const changeMode = (e) => {
    e.preventDefault();
    const diabledFormData = deepCloneObject(state.data);
    dispatch({
      payload: {
        data: diabledFormData,
        displayMode: 'edit',
      },
    });
    togglePayment(false);
  };
  const didMountLogic = () => {
    let newAnnonymouscart = getAnonymousCart();
    if (newAnnonymouscart && newAnnonymouscart.cart.reservationOwner !== null && isYAB2C) {
      setLocalStorage('inlineSignupHasError', true);
    }
    if (newAnnonymouscart && newAnnonymouscart.cart.reservationOwner !== null) {
      newFormdata(newAnnonymouscart.cart.reservationOwner);
      togglePayment(true);
    } else {
      togglePayment(false);
    }
  };
  const togglePayment = (status) => {
    sessionStorage.setItem(UIConfig.sessionStorageKeys.GUEST_DETAIL_SUCCESS, status);
    window.PubSub.publish(UIConfig.events.CHECK_PAYMENT_ON_OFF);
  };
  const disableGuest = (status) => {
    dispatch({
      payload: {
        toggleGuest: false,
      },
    });
  };
  const isPasswordFieldExist = (data) => {
    data.sections.forEach((item) => {
      item.fields.forEach((field) => {
        if (field.type === 'Password') {
          dispatch({
            payload: {
              isInlineSignUpForm: true,
            },
          });
        }
      });
    });
  };
  const isPasswordFieldExistExpress = (data) => {
    let result = false;
    data.sections.forEach((item) => {
      item.fields.forEach((field) => {
        if (field.type === 'Password') {
          result = true;
        }
      });
    });
    return result;
  };
  const renderMainTitle = () => {
    const isExpressCheckoutMainTitle =
      state.isExpressChekoutEnabled && !isAnnualPassCase() && state.isExpressChekoutForm;
    return (
      <div className={`main-title ${isExpressCheckoutMainTitle ? 'express-checkout-main-title' : ''}`}>
        <Text
          tag={isParksExpressCheckout ? 'h2' : 'h3'}
          field={{
            value: props.data.mainTitle,
            editable: props.data.mainTitle,
          }}
        />
        {isExpressCheckoutMainTitle && isSwad && (
          <button
            className="close-express-checkout"
            onClick={() => {
              window.PubSub.publish(UIConfig.events.DISABLE_INLINE_SIGNUP, { enabled: false });
              deleteExpressCheckoutPostPurchaseUser();
            }}
          ></button>
        )}
      </div>
    );
  };
  /**
   * Generates a success message.
   *
   * @param {boolean} isLogin - Specifies whether this component gets rendered in the confirmation summary for login.
   *                            It will change the status of the local storage to avoid rendering the component after refreshing the page.
   */
  const successMessage = (isLogin = false) => {
    if (isLogin) {
      if (localStorage.getItem(UIConfig.localStoreKeys.expressCheckoutConfirmationSummaryRefreshed)) return <></>;

      localStorage.setItem(UIConfig.localStoreKeys.expressCheckoutConfirmationSummaryRefreshed, true);
    }
    setTimeout(() => {
      const section = 'express-post-purchase-success-wrapper';
      let sectionEle = document.getElementsByClassName(section)[0];
      sectionEle.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
    return (
      <section className="express-post-purchase-success-wrapper">
        <section className="express-post-purchase-success">
          <div className={`main-title`}>
            <Text
              tag="h3"
              field={{
                value: props.data?.successMessage?.title?.bodyCopy,
                editable: props.data?.successMessage?.title?.bodyCopy,
              }}
            />
            {(isFwad || isWbw) && <img src={DownloadSVG} alt={''} className="" />}
            {isYww && <img src={tickYWW} alt={''} className="" />}
            {isQAW && <img src={tickQAW} alt={''} className="" />}
          </div>
          <div className={`redirect-button ${isParks ? 'btn-primary' : ''}`}>
            <AnchorLink link={props.data?.successMessage?.primaryCTA} />
          </div>
        </section>
      </section>
    );
  };
  const CommonCheckoutForm = () => {
    const { variant } = props.data;
    const guestCheckoutContainerClasses = classNames('contact-us-form-wrapper guest-checkout-form-wrapper', {
      disabled:
        (!state.toggleGuest || (state.yasIdExist && !isAnnualPassCase())) &&
        !state.isExpressChekoutEnabled &&
        !state.isExpressPostPurchaseForm,
      'guest-checkout-variation': is2StepPayment,
      'anual-pass-comp': isAnnualPass.current,
      'inline-signup': state.isInlineSignUpForm && !state.isExpressChekoutForm,
      'express-post-purchase': state.isExpressPostPurchaseForm,
      'express-post-purchase-sign-in': state.isExpressPostPurchaseSignInForm,
      'express-checkout-form-wrapper': state.isExpressChekoutForm,
    });

    if (!state.isExpressChekoutEnabled || (state.isExpressChekoutEnabled && isAnnualPassCase()))
      state.isInlineSignUpForm && setLocalStorage(UIConfig.localStoreKeys.isInlineSignUpForm, state.isInlineSignUpForm);
    if (state.isExpressChekoutForm || state.isExpressPostPurchaseForm)
      checkInlineSignup() && localStorage.removeItem(UIConfig.localStoreKeys.isInlineSignUpForm);

    window.PubSub.subscribe(UIConfig.events.DISABLE_INLINE_SIGNUP, (msg, enableData) => {
      const hasPasswordField = isPasswordFieldExistExpress(props.data);
      if (enableData?.enabled && state.isExpressChekoutForm) {
        checkInlineSignup() && localStorage.removeItem(UIConfig.localStoreKeys.isInlineSignUpForm);
      } else {
        hasPasswordField && setLocalStorage(UIConfig.localStoreKeys.isInlineSignUpForm, state.isInlineSignUpForm);
      }
    });

    return (
      <>
        {isLoading && <div className="loader"></div>}
        {state.isExpressChekoutEnabled && state.showGuestForm && state.apBannerData && isAnnualPassCase() && isSwad && (
          <SwadBanner
            title={state.apBannerData?.aPGuestCheckoutDisableTitle}
            description={state.apBannerData?.aPGuestCheckoutDisableMsg}
          />
        )}
        {state.showGuestForm && (
          <section className={guestCheckoutContainerClasses}>
            {state.errors && <ErrorSummary data={state.errors} />}
            {variant === UIConfig.commonVariant.checkoutVariant &&
              state.propData &&
              state.propData.data &&
              !isParksExpressCheckout && (
                <CheckoutButtons
                  propData={state.propData}
                  isAnnualPass={isAnnualPass.current}
                  is2StepJourney={is2StepPayment}
                  tabIndex={state.yasIdExist ? '-1' : '0'}
                  isExpressCheckout={
                    state.isExpressChekoutEnabled && !isAnnualPassCase() && !state.isExpressChekoutForm
                  }
                />
              )}
            {(state.isExpressChekoutEnabled || !isAnnualPass.current) && (
              <section className="guest-checkout-wrapper-container">
                {// render main title
                props.data.mainTitle && renderMainTitle()}

                {props.data.mainDescription && (
                  <DynamicContent
                    tagName="p"
                    innerHtml={props.data.mainDescription.replace(
                      '{firstName}',
                      resolvePath(getExpressCheckoutPostPurchaseUser(), 'FirstName', ''),
                    )}
                    attrs={{ className: 'create-account-description' }}
                  />
                )}
                {/* // render signin box if required
                
                // >>>>>>> for yas island sign in / sign up box are not required for gues checkout story <<<<<<<< */}

                {/* change thsi to dynamic component from CMS */}
                {props.data.signIn && (
                  <div
                    className={`guest-checkout-wrap ${!is2StepPayment ? state.displayMode : ''} ${
                      (state.isExpressChekoutEnabled && isAnnualPassCase()) || state.isExpressPostPurchaseForm
                        ? 'show-submit-button'
                        : ''
                    }`}
                  >
                    <Form
                      data={state.data}
                      getFields={autoSave}
                      submitCallback={submitGuestCheckout}
                      isControlled={true}
                      submitSuccess={true}
                      is2StepPayment={is2StepPayment}
                      resetCallBack={() => {}}
                      isTabIndex={state.yasIdExist ? '-1' : '0'}
                      preventEmailPaste={
                        state.isExpressChekoutEnabled && !isAnnualPassCase() && state.isExpressChekoutForm
                      }
                      isPostPurchaseUser={state.isExpressPostPurchaseForm && getExpressCheckoutPostPurchaseUser()}
                      isManageBooking={state?.isManageBooking}
                      noScroll={state?.isManageBooking ? true : false}
                      isParksExpressCheckout={isParksExpressCheckout}
                      noFormComplete={state.isExpressPostPurchaseForm}
                    />{' '}
                  </div>
                )}

                {state?.isManageBooking && showManageBookingHowerBox && <ManageBookingHowerBox data={state?.data} />}

                {!is2StepPayment && (
                  <div className={`form-wrapper-buttons ${state.displayMode}`}>
                    {
                      <div className="text-center">
                        <div className="edit-btn-wrapper btn-primary">
                          {state.displayMode === 'display' && (
                            <button className="edit-btn edit " onClick={changeMode}>
                              {props.data.sections[1].fields[0].label}
                            </button>
                          )}
                        </div>
                      </div>
                    }
                  </div>
                )}
                {resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.YIB2C && isPaymentLoader && (
                  <div className="loader"></div>
                )}
              </section>
            )}
          </section>
        )}
        {state.formType && canUseDOM() && (
          <SignInBox data={state.data.signIn} propData={props.data} yasIdExist={state.yasIdExist} />
        )}
      </>
    );
  };

  try {
    if (state.isExpressPostPurchaseForm) {
      if (state.isExpressPostPurchaseSignInForm && !state.renderExpressPostPurchaseSignInForm) return <></>;
      if (state.isExpressPostPurchaseSignUpForm && !state.renderExpressPostPurchaseSignUpForm) return <></>;
      if (!getExpressCheckoutPostPurchaseUser()) return <></>;
      if (parseQueryString('status') === 'false') return <></>;
      if (state.renderSuccessMessage) return successMessage();
    }
    if (isLoggedInUser() && state.isExpressPostPurchaseSignInForm && isParks) return successMessage(true);
    var ComponenttoRender = CommonCheckoutForm();
    if (state.isExpressChekoutEnabled && !isAnnualPassCase() && state.showGuestForm) {
      if (state.isExpressChekoutForm) {
        if (!state.disabledInlineSignUp) ComponenttoRender = <></>;
      } else {
        if (state.disabledInlineSignUp && isSwad) {
          ComponenttoRender = (
            <CollapsedView
              title={props.data.signIn.signInTitle}
              subtitle={props.data.signIn.signInText}
              onClick={() => {
                window.PubSub.publish(UIConfig.events.DISABLE_INLINE_SIGNUP, { enabled: false });
                deleteExpressCheckoutPostPurchaseUser();
              }}
            />
          );
        } else if (isParksExpressCheckout) ComponenttoRender = <></>;
      }
    }
    // don't show the Express checkout form in case Annual pass in the Cart
    if (state.isExpressChekoutForm && isAnnualPassCase()) ComponenttoRender = <></>;
    return ComponenttoRender;
  } catch (err) {
    return logComponentRenderingError(err, 'GuestCheckoutForm');
  }
};
export default GuestCheckoutForm;
