import UIConfig from '../UIConfig';
import { getMainObject, resolvePath } from './helpers';

const checkTenant = (tenantId) => resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === tenantId.toLowerCase();
const isParkTenant = () =>
  checkTenant(UIConfig.tenants.fwad) ||
  checkTenant(UIConfig.tenants.wbw) ||
  checkTenant(UIConfig.tenants.yww) ||
  checkTenant(UIConfig.tenants.ppad);
export { checkTenant, isParkTenant };

export const guestCheckoutTenants = ['yib2c'];
