import React, { ReactNode, useContext } from 'react';
import * as sitecoreJssReact from '@sitecore-jss/sitecore-jss-react';

const { SitecoreContextReactContext } = sitecoreJssReact;
const sitecoreJssLegacy = sitecoreJssReact;

/*
  In sitecore-jss-react before version 15 the SitecoreContextFactory stores the
  current Sitecore context for the app.
  For example, whether the page is currently being edited, or the current language.
  Note that the export makes this essentially a singleton, so we can store state in it.
  The Sitecore context is generally updated on route change (/src/index.js).
  The `withSitecoreContext()` higher order component from `sitecore-jss-react`
  can be used to access the context from within a component.
*/
const contextFactory =
  typeof sitecoreJssLegacy.SitecoreContextFactory !== 'undefined'
    ? new sitecoreJssLegacy.SitecoreContextFactory()
    : null;

export const useSitecoreContext = () => {
  const sitecoreContext = useContext(SitecoreContextReactContext);
  // Check to see if we are using the legacy factory
  if (typeof sitecoreContext.getSitecoreContext === 'function') {
    return sitecoreContext.getSitecoreContext();
  } else {
    return sitecoreContext.context;
  }
};

export function SitecoreContext({ componentFactory, children, context }) {
  //   const contextData = {
  //     route: null,
  //     itemId: null,
  //   };

  //   if (context && conte) {
  //     console.log('sitecorecontextlegacy', context);
  //     contextData.route = context.route;
  //     contextData.itemId = context.route && context.route.itemId;
  //     Object.assign(contextData, context.sitecore.context);
  //   }

  if (contextFactory) {
    return (
      <LegacySitecoreContext contextData={context} componentFactory={componentFactory}>
        {children}
      </LegacySitecoreContext>
    );
  } else {
    return (
      <LatestSitecoreContext contextData={context} componentFactory={componentFactory}>
        {children}
      </LatestSitecoreContext>
    );
  }
}

function LegacySitecoreContext({ contextData, componentFactory, children }) {
  if (contextFactory) {
    contextFactory.setSitecoreContext(contextData);
  }
  contextFactory.setSitecoreContext(contextData);

  return (
    <sitecoreJssLegacy.SitecoreContext componentFactory={componentFactory} contextFactory={contextFactory}>
      {children}
    </sitecoreJssLegacy.SitecoreContext>
  );
}

function LatestSitecoreContext({ contextData, componentFactory, children }) {
  return (
    <sitecoreJssReact.SitecoreContext componentFactory={componentFactory} context={contextData}>
      {children}
    </sitecoreJssReact.SitecoreContext>
  );
}
