import React from 'react';
import PropTypes from 'prop-types';
import './radio-set.scss';

/**
 * RadioSet is a dumb component for radio button
 * @param    {string}    object     wrapper html element for the provided html.
 * @return   {[Object]}  Return a react element.
 */

const RadioSet = (props) => {
  const radioValue = props.radio,
    defaultValue = props.selected || 0;
  return (
    <div>
      {radioValue.map((radioValue, index) => {
        return (
          <div className="radio" key={index}>
            <label>
              <input
                className="sr-only"
                type="radio"
                name={props.id}
                checked={radioValue.value === defaultValue}
                value={radioValue.value}
                onChange={props.onChange}
              />
              {radioValue.label}
              <span></span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

RadioSet.propTypes = {
  props: PropTypes.shape({
    radio: PropTypes.object.isRequired,
  }),
};

export default RadioSet;
