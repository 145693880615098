import React, { useState } from 'react';
import classNames from 'classnames';

import UIConfig from '../../../../common/UIConfig';
import { parseIfJsonString, toTwoDecimalPlaces, isEventTypeProduct, isMatchTenant } from '../../../../common/utility';
import { DynamicContent, Spinner } from '../../../presentation/base';
import { getUpdateQuantity, openPopAddOns, getDeleteButton } from './grouped-product-row';

const checkDeleteOverlay = (data, deleteProduct, item, deleteOverlay, propData) => {
  const checkValidation = propData && propData.isRecommendationControlled && item;
  const isReAllowedForAll = propData && propData.isRecommendationAllowedForAll;
  const quantity = -item.groupedQuantity;
  if (checkValidation && (isEventTypeProduct(item) || isReAllowedForAll)) {
    const { cartData, addOnsErrorProdArr, recommendationActions } = propData;
    let addOnsErrObj = recommendationActions.getAddOnsProdsOnParentQuantityChange(
      item,
      cartData,
      addOnsErrorProdArr,
      quantity,
    );
    if (addOnsErrObj && addOnsErrObj.hasOwnProperty('isOpenPopOverlay') && addOnsErrObj.isOpenPopOverlay) {
      openPopAddOns(propData);
    } else {
      deleteOverlay(data, deleteProduct, item);
    }
  } else if (!item.eventType && item.relatedProductIds && isMatchTenant(UIConfig.tenants.ymc)) {
    const { cartData, addOnsErrorProdArr, recommendationActions } = propData;
    let addOnsErrObj = recommendationActions.getAddOnsProdsOnParent(item, cartData, addOnsErrorProdArr, quantity);
    if (addOnsErrObj && addOnsErrObj.hasOwnProperty('isOpenPopOverlay') && addOnsErrObj.isOpenPopOverlay) {
      openPopAddOns(propData);
    } else {
      deleteOverlay(data, deleteProduct, item);
    }
  } else {
    deleteOverlay(data, deleteProduct, item);
  }
};

const getMaxQuantity = (item) => {
  const maxPkgAvailability = item.reduce((prev, current) => {
    const curValue = parseInt(current.productMaxAvail);
    const prevValue = parseInt(prev.productMaxAvail);
    if (curValue < prevValue) {
      return curValue;
    }
    return prevValue;
  });
  return maxPkgAvailability;
};

const loadDeleteButton = (data, deleteProduct, item, deleteOverlay, isCartPaymentView, propData) => {
  const keyMap = data.stringMapping || {},
    isDrvExp =
      item &&
      !item.showQtySpinnerInCart &&
      item.otherReason &&
      (item.otherReason === keyMap.secondary || item.otherReason === keyMap.primary),
    isFwadTenant = isMatchTenant(UIConfig.tenants.fwad),
    itemCategory = item && item.category && item.category[0] ? item.category[0].toLowerCase() : '',
    isPassenger = UIConfig.passengerItemCheck.indexOf(itemCategory) >= 0,
    isExtraPassengers = item && item.groupedQuantity > 1,
    isDeleteBtnPosChange = isFwadTenant && isDrvExp && !isPassenger && isExtraPassengers;
  const disabledeleteBtn = item.isIncreaseDecreaseEnabled || false;

  return getDeleteButton(
    data.editable,
    () => checkDeleteOverlay(data, deleteProduct, item, deleteOverlay, propData),
    item.productName,
    data.deleteIconAriaLabel,
    propData,
    isDeleteBtnPosChange,
    disabledeleteBtn,
  );
};

// const updateQuantity = () => {
//   console.log('cart Updated');
// };
/**
 * CreateItemRow function creates a row inside each ticket type
 * @param    {[Object]} item [data required to render the view].
 * @param    {[String]} currency [function to open the overlay].
 * @param    {[Object]} data [data for labels].
 * @param    {[function]} updateQuantity [callback function for updating the quantity].
 * @param    {[function]} deleteProduct [callback functions for deleting the product].
 * @param    {[Object]} deleteOverlay [delete Overlay]
 * @param    {[Number]} cartTotalQuantity [Total Quantity of cart]
 * @param    {[Boolean]} showClassType [whether to show class type or not - Adult and Junior]
 * @param    {{Number}} addGuestCount [Number of Guest to add]
 * @param    {[Boolean]} isCartPaymentView [Create Item Row in 2 Step Purchase Journey Mode]
 * @param    {[Boolean]} isRenewAnnualPass [Create Row for Renew Annual Pass Scenario]
 * @return   {[Object]} returns html .
 */
const PackageQuantityRow = ({
  currency,
  data,
  deleteProduct,
  deleteOverlay,
  cartTotalQuantity,
  isCartPaymentView,
  packageItem,
  pkgPrice,
  propData,
}) => {
  const [showMaxQuantityError, setShowMaxQuantityError] = useState(false);

  const ticketShowClasses = classNames({
    show: !isCartPaymentView,
    'max-limit-error': showMaxQuantityError,
  });
  const ticketInfoClasses = classNames('ticket-info', {
    // 'no-discount-hide': !parseInt(item.discount.actualPerc, 10),
  });
  const ticketSubSectionClasses = classNames('ticket-sub-section');

  const isMaxQuantity = (items) => {
    return packageItem.items[0].packageQty >= getMaxQuantity(items);
  };

  const { packageMax, packageMin, packageQty } = packageItem.items[0];
  return (
    <div className={ticketShowClasses}>
      <div className={ticketSubSectionClasses}>
        <div className="ticket-item">
          <span className="ticket-type" tabIndex="0">
            <DynamicContent
              tagName="p"
              attrs={{ className: 'price' }}
              innerHtml={`${currency} ${toTwoDecimalPlaces(pkgPrice)}`}
            />
          </span>
        </div>
        <div className={ticketInfoClasses}>
          <Spinner
            max={packageMax}
            min={packageMin}
            quantity={packageQty}
            isInputDisabled="true"
            checkQuantity={(val) =>
              getUpdateQuantity(
                packageItem,
                null,
                Number(val) - Number(packageQty),
                packageItem.items[0].packageMax,
                propData.updateQuantity,
              )
            }
            isMaxQuantity={() => isMaxQuantity(packageItem.items)}
            setShowMaxQuantityError={setShowMaxQuantityError}
            packageMaxAvail={getMaxQuantity(packageItem.items)}
            isPackageProd
          />
          {showMaxQuantityError && (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'spinner-max-limit-error' }}
              innerHtml={data.cartLabels.pkgLimitErrorLabel || 'Seat Category Sold Out'}
            />
          )}
          {loadDeleteButton(data, deleteProduct, packageItem, deleteOverlay, isCartPaymentView, propData)}
        </div>
      </div>
    </div>
  );
};

export default PackageQuantityRow;
