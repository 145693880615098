import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  createMappedData,
  getLoggedInUser,
  getMainObject,
  isEmpty,
  replacePlaceHolder,
} from '../../../../common/utility';
import ApiWrapper from '../../../../common/api-wrapper';
import { getB2CProductDetails } from '../../../../common/coveo-api';
import UIConfig from '../../../../common/UIConfig';
import PastPurchasedAccrodian from './past-purchased-accrodian';
import TextWithCTA from '../../text-with-cta';
import { Logging } from '../../../../common/logger';

export default class PastPurchased extends Component {
  constructor(props) {
    super(props);
    this.categoriesOrder = this.props.data.controls.extras.options.map((category) => category.coveoValue);

    this.state = {
      data: [],
      products: [],
      recommendationsCatergoryData: {},
      cart: {},
      isEmptyProducts: false,
    };
  }

  getProductDetailsFromCoveo = (products, type) => {
    let productIdList = [];
    if (type !== UIConfig.b2c.purchaseJourney.recommendationTabCode) {
      productIdList =
        products &&
        products.length !== 0 &&
        products.map((item) => {
          return item.f1Product.productID;
        });
    } else {
      productIdList = [...products];
    }
    let query = [{ key: this.props.coveoMappingList.productId, value: productIdList }];

    return getB2CProductDetails({
      queryParams: query,
      perPageResults: UIConfig.coveoResultsPerPage,
      coveoKeyMap: this.props.coveoMappingList,
      serviceUrl: this.props.services.getCoveoProducts.url,
      fieldsToInclude: Object.values(this.props.coveoMappingList),
    });
  };

  getRecommendationDetails = (products) => {
    let allProduct = [];
    const result = this.state.data.map(async (pro, i) => {
      const selectedProduct = [];
      pro.recommendationsAddons.map((r) => {
        return selectedProduct.push(...r.productID);
      });
      await this.getProductDetailsFromCoveo(selectedProduct, UIConfig.b2c.purchaseJourney.recommendationTabCode)
        .then((res) => {
          const recommendationListDetails = createMappedData(res.results, this.props.coveoMappingList);
          const updateProductData =
            products && products.length && products.find((pr) => pr.productId === pro.f1Product.productID);
          allProduct[i] = {
            ...updateProductData,
            recommendations: this.groupCounterProducts(recommendationListDetails, pro),
            isOpen: i === 0,
          };
        })
        .catch((err) => {
          Logging(err, 'PastPurchased', false, 'Error in Recommendation Details Coveo');
        });
      return allProduct[i];
    });
    Promise.all(result).then((r) => {
      this.setState({ products: r });
      this.updateRecommendationDetails();
    });
  };

  groupCounterProducts = (products, mainPro) => {
    const { options } = this.props.data?.controls?.extras;
    const grouppedProducts = {};
    let newData = { ...this.state.recommendationsCatergoryData };
    products.forEach((product) => {
      let filterCat = this.filteredCategoriesOrder();
      let category =
        filterCat &&
        filterCat.length > 0 &&
        filterCat.find((element) => product.category && product.category.includes(element));
      if (product.category && product.category.length > 0 && category && !isEmpty(category)) {
        if (!grouppedProducts.hasOwnProperty(category)) grouppedProducts[category] = [];
        grouppedProducts[category].push(product);
        const filteredCategoryData = options?.find((opt) => {
          const sltdCategory = product.category.length ? product.category[0] : product.category;
          return opt.coveoValue === sltdCategory;
        });
        const defaultQuantity = filteredCategoryData?.visitorSelector?.options?.[0]?.defaultQuantity;
        if (this.state.recommendationsCatergoryData.hasOwnProperty(category)) {
          newData[category] = {
            ...newData[category],
            count: newData[category].count + 1,
            maxQuantity: newData[category].maxQuantity + mainPro.f1Product.quantity,
            totalTicket: newData[category].maxQuantity + mainPro.f1Product.quantity,
          };
        } else {
          newData[category] = {
            count: 1,
            maxQuantity: mainPro.f1Product.quantity,
            addedQuatity: defaultQuantity,
            productId: product.productId,
            totalTicket: mainPro.f1Product.quantity,
            isUnlimited: product.subEventType === UIConfig.b2c.purchaseJourney.postSubEventFB,
          };
        }
      }
    });
    this.setState({ recommendationsCatergoryData: newData });
    return grouppedProducts;
  };

  getOrderDetail = (orderIndex) => {
    let allProduct = [...this.state.products];
    allProduct.forEach((pro, i) => {
      if (orderIndex === i) {
        return {
          ...pro,
          isOpen: true,
        };
      }
    });
    setTimeout(() => {
      const elem = document.getElementById(`accordion_header_${orderIndex}`);
      elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
    this.setState({ products: allProduct });
  };

  updateRecommendationDetails = (type, newProps) => {
    const newCart = newProps && newProps.cartData.cart.items;
    const oldCart = this.props.cartData.cart.items;
    const { recommendationsCatergoryData } = this.state;
    let newData = { ...recommendationsCatergoryData };
    Object.keys(recommendationsCatergoryData).forEach((rec) => {
      if (type) {
        if (Object.keys(oldCart).length > Object.keys(newCart).length) {
          const selectedCategory = Object.keys(oldCart).filter(
            (cat) => !Object.keys(newCart).some((oldCat) => oldCat === cat),
          );
          const deletedCat =
            oldCart[selectedCategory && selectedCategory.length && selectedCategory[0]].products[0].category[0];
          if (
            selectedCategory &&
            selectedCategory.length &&
            rec === deletedCat &&
            oldCart[selectedCategory && selectedCategory.length && selectedCategory[0]].products[0].isPostPurchasedAddon
          ) {
            newData[deletedCat] = {
              ...newData[deletedCat],
              maxQuantity: newData[deletedCat].totalTicket,
            };
          }
        } else {
          const selectedCategory = Object.values(newCart).find(
            (value) => value.products[0].category[0] === rec && value.products[0].isPostPurchasedAddon,
          );
          const category = selectedCategory && selectedCategory.products[0].category[0];
          if (selectedCategory && selectedCategory.products[0].isPostPurchasedAddon) {
            newData[category] = {
              ...newData[category],
              maxQuantity: newData[category].totalTicket - selectedCategory.products[0].quantity,
            };
          }
        }
      } else {
        const selectedCategory = Object.values(oldCart).find(
          (value) => value.products[0].category[0] === rec && value.products[0].isPostPurchasedAddon,
        );
        const category = selectedCategory && selectedCategory.products[0].category[0];
        if (selectedCategory && selectedCategory.products[0].isPostPurchasedAddon) {
          newData[category] = {
            ...newData[category],
            maxQuantity: newData[category].totalTicket - selectedCategory.products[0].quantity,
          };
        }
      }
    });

    !type &&
      this.state.data.map((pro, i) => {
        return (
          pro.purchasedAddonsDetails &&
          pro.purchasedAddonsDetails.length !== 0 &&
          pro.purchasedAddonsDetails.forEach((x) => {
            const currentProductRec = this.state?.products?.[i]?.recommendations;
            Object.keys(recommendationsCatergoryData).forEach((rec) => {
              if (
                currentProductRec[rec] &&
                currentProductRec[rec][0].productId === x.productID &&
                recommendationsCatergoryData[rec].productId === x.productID
              ) {
                newData[rec] = {
                  ...newData[rec],
                  maxQuantity: newData[rec].maxQuantity - x.quantity,
                  totalTicket: newData[rec].totalTicket - x.quantity,
                };
              }
            });
          })
        );
      });
    this.setState({ recommendationsCatergoryData: newData });
  };

  filteredCategoriesOrder = () => this.categoriesOrder.filter((v, i, a) => a.indexOf(v) === i);

  componentDidMount() {
    const userData = getLoggedInUser();
    const mainObj = getMainObject();
    const serviceUrl = `${this.props.services.getPostPurchaseAddons.url}?tenantid=${userData.tenantID}&searchHub=${mainObj?.additionalProperty?.searchHub}`;
    const config = {
      url: replacePlaceHolder('{yasId}', userData.yasId, serviceUrl),
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + userData.idToken,
      },
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    };
    ApiWrapper.api(config)
      .then((res) => {
        if (res.data.purchasedProductsDetails) {
          this.setState({ isEmptyProducts: false });
          this.setState({ data: res.data.purchasedProductsDetails });
          this.getProductDetailsFromCoveo(res.data.purchasedProductsDetails)
            .then((r) => {
              const productList = createMappedData(r.results, this.props.coveoMappingList);
              this.getRecommendationDetails(productList);
              this.setState({ cart: this.props.cartData });
            })
            .catch((err) => {
              Logging(err, 'PastPurchased', false, 'Error in Product Details Coveo');
            });
        } else {
          this.setState({ isEmptyProducts: true });
        }
      })
      .catch((err) => {
        Logging(err, 'PastPurchased', false, 'Error in Product Recommendations');
      });
  }

  componentWillReceiveProps(newProps) {
    if (this.state.data && newProps.cartData !== this.props.cartData) {
      this.updateRecommendationDetails(UIConfig.b2c.purchaseJourney.yaTourOverlay.update, newProps);
    }
  }

  updateAddedQuantity = (val, cat) => {
    this.setState({
      recommendationsCatergoryData: {
        ...this.state.recommendationsCatergoryData,
        [cat]: {
          ...this.state.recommendationsCatergoryData[cat],
          addedQuatity: Math.abs(val),
        },
      },
    });
  };

  render() {
    const { ctaMapper, mapperValues } = this.props.textWithCtaSettings;
    const { emptyRecommendation } = UIConfig.b2c.purchaseJourney.ymcMapping;
    return this.state.isEmptyProducts ? (
      <div className="b2c-booking-tabs-wrapper empty-booking">
        <TextWithCTA data={ctaMapper[mapperValues[emptyRecommendation]]} />
      </div>
    ) : (
      <div className="past-purchased-component">
        <PastPurchasedAccrodian
          products={this.state.products}
          getOrderDetail={this.getOrderDetail}
          newProps={this.props}
          filteredCategories={this.filteredCategoriesOrder()}
          data={this.state.data}
          recommendationsCatergoryData={this.state.recommendationsCatergoryData}
          addedQuatity={(val, category) => this.updateAddedQuantity(val, category)}
          emptyData={ctaMapper[mapperValues[emptyRecommendation]]}
        />
      </div>
    );
  }
}

PastPurchased.PropsTypes = {
  data: PropTypes.array,
  products: PropTypes.array,
  recommendationsCatergoryData: PropTypes.object,
  cart: PropTypes.object,
};
