import React from 'react';

import ConfirmedTicketTileStepThree from './confirmed-ticket-tile-step-three';
import ConfirmedTicketTileStepTwo from './confirmed-ticket-tile-step-two';
import { logComponentRenderingError } from '../../../../common/logger';
import { isAddonProduct, createMappedData, isYasArenaJourney, getSortedYaTickets } from '../../../../common/utility';

export default class ConfirmedTicketTile extends React.Component {
  productDisplayName = (ticket, productName, ticketTypeName) => {
    let name = ticket[productName];
    if (ticket[ticketTypeName]) {
      name += ', ' + ticket[ticketTypeName];
    }
    return name;
  };

  isTimeSlotVisible = (data, timeSlotMap) => {
    const key = timeSlotMap.timeslotFilterKey || '';
    if (data && key && data[key] && timeSlotMap.timeslotFilterValue) {
      for (let i = 0; i < data[key].length; i++) {
        if (timeSlotMap.timeslotFilterValue.indexOf(data[key][i]) !== -1) {
          return true;
        }
      }
    }
    return false;
  };

  yaIsTimeSlotVisible = (product, coveoKeyMap) => {
    const mappedData = createMappedData([product], coveoKeyMap);
    if (isAddonProduct(mappedData[0])) {
      return false;
    }
    return true;
  };

  getSeats = (product) => {
    const tkts = product.tickets;
    const sortedTickets = tkts && getSortedYaTickets(tkts);

    let seatMap = [];
    if (sortedTickets) {
      sortedTickets.forEach((ticket) => {
        ticket.Seat && seatMap.push(`${ticket.Seat.rowLabel} ${ticket.Seat.colLabel} ${ticket.Seat.seatLabel}`);
      });
    }
    return seatMap;
  };

  render() {
    try {
      const ticket = this.props.data;
      const {
        product,
        coveoKeyMap,
        validOn,
        quantity,
        ticketDate,
        timeSlot,
        timeSlotFilter,
        isStepTwo,
        ticketImage,
        yaOrderConfirmation,
        seatLabel,
        annualPassProductCategorization,
        propData,
        isYANonSeatedEvent,
        index,
        image,
        onImageClick,
        enableAddToWallet,
        addToWallet,
        isCorpCouponApplied,
        multiparkTicketImage,
        ticketDisclaimerTitle,
        ticketDisclaimerDescription,
      } = this.props;
      const {
        productName,
        parks,
        classTypeName,
        ticketTypeName,
        productTypeCategory,
        eventType,
        weekDays,
        eventLocationName,
        seatType,
        wrcEventDate,
        eventTitle,
        itemType,
      } = coveoKeyMap;
      return isStepTwo ? (
        <ConfirmedTicketTileStepTwo
          productDisplayName={ticket[productName]}
          ticketParks={this.props.ticketName}
          isTimeSlotVisible={
            yaOrderConfirmation
              ? yaOrderConfirmation
              : this.isTimeSlotVisible(
                  // passing yaOrderConfirmation to be always true as we do not have any scenario which doesn't have timeslot
                  this.props.data,
                  timeSlotFilter,
                )
          }
          ticketDate={ticketDate}
          product={product}
          timeSlot={timeSlot}
          quantity={quantity}
          validOn={validOn}
          classTypeName={classTypeName}
          ticket={ticket}
          itemType={ticket[itemType]}
          multiparkTicketImage={multiparkTicketImage}
          ticketDisclaimerTitle={ticketDisclaimerTitle}
          ticketDisclaimerDescription={ticketDisclaimerDescription}
          ticketImage={ticketImage}
          tenantId={this.props.tenantId}
          annualPassProductCategorization={annualPassProductCategorization}
          isRenewAnnualPassFlag={this.props.isRenewAnnualPassFlag}
          productTypeCategory={ticket[productTypeCategory]}
          seatLabel={seatLabel}
          seats={isYasArenaJourney() && !isYANonSeatedEvent && this.getSeats(product)}
          isYANonSeatedEvent={isYANonSeatedEvent}
          propData={propData}
          eventType={ticket[eventType]}
          weekDays={ticket[weekDays]}
          seatType={ticket[seatType]}
          eventLocationName={ticket[eventLocationName]}
          wrcEventDate={ticket[wrcEventDate]}
          index={index}
          eventTitle={Array.isArray(ticket[eventTitle]) ? ticket[eventTitle][0] : ticket[eventTitle]}
          image={image}
          onImageClick={onImageClick}
          showYMCAddToWallet={this.props.showYMCAddToWallet}
          addToWallet={addToWallet}
          enableAddToWallet={enableAddToWallet}
          isCorpCouponApplied={isCorpCouponApplied}
        />
      ) : (
        <ConfirmedTicketTileStepThree
          productDisplayName={this.productDisplayName(ticket, productName, ticketTypeName)}
          ticketParks={ticket[parks].join(' + ')}
          isTimeSlotVisible={this.isTimeSlotVisible(this.props.data, timeSlotFilter)}
          ticketDate={ticketDate}
          product={product}
          timeSlot={timeSlot}
          quantity={quantity}
          validOn={validOn}
          classTypeName={classTypeName}
          ticket={ticket}
        />
      );
    } catch (err) {
      return logComponentRenderingError(err, 'ConfirmedTicketTile');
    }
  }
}
