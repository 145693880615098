import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Logging } from '../../../common/logger';

import HotelBookingWidgetDatepicker from './hotel-booking-widget-datepicker-component';
import HotelBookingWidgetGuestSelector from './hotel-booking-widget-guest-selector-component';
import HotelBookingWidgetCategorySelector from './hotel-booking-widget-category-component';
import {
  scrollTo,
  deepCloneObject,
  detectMobile,
  getValidStartDate,
  getCurrentLanguage,
  decodeHtmlEntity,
  detectViewPort,
  getIncludedDates,
  isEmpty,
  isValidSelection,
  convertJsDateToMomentObj,
  getBaseURL,
  setCookie,
  getCookie,
  checkTenant,
  setSessionStorage,
  isMatchTenant,
  toLowerCase,
} from '../../../common/utility';
import { DynamicContent, Image } from '../../presentation/base';
import { checkAvailabilityAnalytics } from '../../../common/analytics-events';
import {
  canUseDOM,
  parseQueryString,
  momentTimezone,
  addDays,
  currentLocale,
  checkArrowKeys,
  getClientUtcOffset,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import RedirectionOverlay from '../../presentation/redirection-overlay';
import moment from 'moment';
import { isWithinInterval } from 'date-fns';
import 'moment/locale/en-gb';
import { KeyCodes, MouseEvents, TouchEvents, KeyboardEvents } from '../../../common/constants';
import { logComponentRenderingError } from '../../../common/logger';
import GTMData from '../b2c-purchase-journey/gtm-data';
const V_BOOKINGWIDGET_TRANSPARENTBACKGROUND = 'v-BookingWidget_TransparentBackground';
const V_BOOKINGWIDGET_WITHIMAGE = 'v-BookingWidget_WithImage';
const V_BOOKINGWIDGET_REDBACKGROUND = 'v-BookingWidget_RedBackground';
const V_BOOKINGWIDGET_BLACKBACKGROUND = 'v-BookingWidget_BlackBackground';
const V_FESTIVE_THEME = 'festive-season';
const V_CHILDMAXAGE = 11; // This is MAX Child Age

/**
 * HotelBookingWidget smart Component helps making hotel booking.
 * @param    {props} Object as defined from data-contract.
 * @return   {HotelBookingWidgetCategorySelector} Help Selecting Holiday Experience Category
 * @return   {HotelBookingWidgetDatepicker} Help Selecting checkIn and checkOut date of booking
 * @return   {HotelBookingWidgetGuestSelector} Help Selecting Room, Adults and Child count, Child Age
 */

const GTMDataOnClickCTA = ({ name, element_text, category }) => {
  if (isMatchTenant(UIConfig.tenants.yi)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, { name, element_text, category });
  }
};
class HotelBookingWidget extends React.PureComponent {
  constructor(props) {
    super(props);

    const { guestSettings } = props.data;
    this.roomSettings = props.data.roomSettings;
    this.enableStackView = guestSettings.enableStackView || false;
    this.isYasIsland = this.props?.data?.brand === UIConfig.YAS;
    this.isYMC = checkTenant(UIConfig.iamMapping.ymc);
    this.defaultGuestCount = [
      {
        adult: parseInt(guestSettings.guestTypeAdult.defaultValue),
        child: parseInt(guestSettings.guestTypeChild.defaultValue),
        childAge: [],
      },
    ];

    if (this.enableStackView) {
      this.defaultGuestCount[0].room = this.roomSettings.defaultValue;
    }

    this.checkIfSticky = '';

    if (props.data.variant === V_BOOKINGWIDGET_WITHIMAGE) {
      this.checkIfSticky = V_BOOKINGWIDGET_WITHIMAGE;
    } else if (props.data.variant === V_BOOKINGWIDGET_BLACKBACKGROUND) {
      this.checkIfSticky = V_BOOKINGWIDGET_BLACKBACKGROUND;
    } else if (props.data.variant.includes(V_BOOKINGWIDGET_REDBACKGROUND)) {
      this.checkIfSticky = V_BOOKINGWIDGET_REDBACKGROUND;
    }
    this.isDayCation = props.data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate;
    this.withImageVariant = props.data.variant === V_BOOKINGWIDGET_WITHIMAGE;
    this.redVariant = !!(
      props.data.variant &&
      (props.data.variant.includes(V_BOOKINGWIDGET_REDBACKGROUND) ||
        props.data.variant.includes(V_BOOKINGWIDGET_TRANSPARENTBACKGROUND) ||
        props.data.variant.includes(UIConfig.commonVariant.bookingWidgetOnlyStartDate))
    );

    this.calendarSettings = props.data.calendarSettings;

    this.actualStartDate = moment(this.calendarSettings.startDate).isValid()
      ? momentTimezone(this.calendarSettings.startDate, getClientUtcOffset())
      : moment();

    this.minDate = getValidStartDate(this.calendarSettings.startDate).add(
      this.calendarSettings.defaultDateDelta,
      'days',
    );

    if (!this.isYasIsland) {
      this.disabledDatesRange = this.calendarSettings.disabledDates || [];
      this.disabledDates = this.getDatesList(this.disabledDatesRange);
      this.excludeDates = this.getFormattedDates(this.disabledDates);
    }

    this.monthlyNotesConfigDesktop = this.calendarSettings.monthlyNotesDesktop || {};
    this.monthlyNotesConfigMobile = this.calendarSettings.monthlyNotesMobile || {};

    this.state = {
      holidayExperience: '',
      holidayExperienceLabelError: false,
      startDate: null,
      endDate: null,
      isDatePickerOpen: false,
      isGuestSelectorOpen: false,
      calendarLabel: this.calendarSettings.placeHolder,
      calendarLabelError: false,
      calendarTooltip: this.calendarSettings.checkInText,
      guestData: [{ ...this.defaultGuestCount[0] }],
      selectRange: true,
      minDate: this.minDate,
      guestLabelError: false,
      hotelBookingForm: true,
      stickyVariant: this.checkStickyVariant(),
      promoCode: parseQueryString(UIConfig.querStringParams.hotelBookingWidget.promoCode),
      dateSelectionError: false,
      dateSelectionErrorMessage: '',
      monthlyNotes: [],
      guestSelectorLabel1: '',
      guestSelectorLabel2: '',
      universalContainerClasses: '',
      dateNotInRangeError: false,
      isChildSelectOpen: false,
      mobileWidget: false,
      widgetSteps: 0,
      mobileCalendarError: false,
      mobileCategoryError: false,
      mobileChildAgeError: false,
      showInformativeText: '',
      adultAgeLimit: 0,
      childAgeLimit: 0,
      orderStatusFromBookingYMC: true,
    };
    this.isGetPackagesKeyPressed = true;
    this.datePickerRef = createRef();
    this.guestSelectorRef = createRef();
    this.highlightDates = [];
    this.arrayOfDates = [];
    this.selectedTab = '';
    this.isYasIsland = checkTenant(UIConfig.iamMapping.yasisland);
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
    this.cookieName = this.isDayCation
      ? UIConfig.DAYCATION_USER_SELECTED_CHOICE
      : UIConfig.STAYCATION_USER_SELECTED_CHOICE;

    this.cookieObject = {
      from: '',
      to: '',
      adult: '0',
      child: '0',
      childAges: '0',
      brand: '',
      language: '',
      category: '',
    };
  }

  getUniversalContainerClasses = (isHotelBookingForm) => {
    const isStickyAndHotelBookingForm = this.state.stickyVariant && isHotelBookingForm;
    return classNames(
      'c-hotel-booking-widget',
      this.props.data.variant,
      this.props.data.theme,
      // { 'add-hotelsticky-content': window.pageYOffset > 9 && detectMobile() && this.redVariant ? true : false },
      { 'sticky-data-wrapper': this.state.stickyVariant },
      { hide: this.checkIfSticky && isStickyAndHotelBookingForm },
      { show: this.checkIfSticky && !isStickyAndHotelBookingForm },
      { 'v-multicolor-cta-container': this.props.data && this.props.data.multiColorButtonRequired },
    );
  };

  /**
   * @function paxDetails set the initial value of PAX in booking widget
   **/
  paxDetails = (actualValues, noUpdateGuestDataState = false) => {
    const newchild = actualValues.get('child') || '';
    const newadult = actualValues.get('adult') || '';
    const childAge = actualValues.get('childAges') || '';
    let adult = [];
    let child = [];
    let newChildAges;
    adult = newadult?.split(/[ ,]+/).map((age) => parseInt(age, 10)) || [];
    child = newchild?.split(/[ ,]+/).map((age) => parseInt(age, 10)) || [];
    newChildAges = childAge?.split(/[ ,]+/).map((age) => parseInt(age, 10)) || '';
    let pax = [];
    let len = adult.length;
    let count = 0;
    for (var i = 0; i < len; i++) {
      let updatedChildAge = [];
      const toValue = child[i] === 0 ? 1 : child[i];
      updatedChildAge = child.length === 1 && child[i] === 0 ? [] : newChildAges.slice(count, toValue + count);
      pax.push({
        adult: adult[i],
        child: child[i],
        childAge: child[i] === 0 ? [] : updatedChildAge,
      });
      count = child[i] === 0 ? count + 1 : count + child[i];
    }

    if (noUpdateGuestDataState && isMatchTenant(UIConfig.tenants.yi)) {
      return pax;
    } else {
      this.setState({ guestData: pax });
      let message = this.guestDisplayText(pax);
      this.setState({
        guestSelectorLabel1: message.guestSelectorLabel1,
        guestSelectorLabel2: message.guestSelectorLabel2,
        universalContainerClasses: this.getUniversalContainerClasses(this.state.hotelBookingForm),
      });
    }
  };

  viewCheckOnScroll = () => {
    document.body.className = document.body.className.replace('bookin-widget-opened', '');
  };

  //YMC related function for confirmation-summary Start
  updateF1Product = (item, products, coveoMapping) => {
    const availableProduct = products.find(
      (cp) => cp[coveoMapping.eventType] === UIConfig.ProductType.F1 && item?.productId === cp[coveoMapping.productId],
    );
    return availableProduct ? { ...item, productType: UIConfig.ProductType.F1 } : item;
  };

  updateF1ProductsWithType = (products, coveoProducts, coveoMapping) => {
    return products.map((item) => {
      return this.updateF1Product(item, coveoProducts, coveoMapping);
    });
  };

  calculateCheckinCheckoutDate = (isF1, isNonF1, earliestDate, endDate) => {
    const { calendarSettings, f1DateDelta = 4, experienceDateDelta = 2 } = this.props.data;
    let checkin, checkout;
    if (isF1 && !isNonF1) {
      checkin = endDate.clone().subtract(+f1DateDelta, UIConfig.calendar.days);
      checkout = endDate;
    } else if (isF1 && isNonF1) {
      checkin = earliestDate.clone().subtract(+f1DateDelta, UIConfig.calendar.days);
      checkout = earliestDate.clone().add(+f1DateDelta, UIConfig.calendar.days);
    } else {
      checkin = earliestDate.clone().subtract(+experienceDateDelta, UIConfig.calendar.days);
      checkout = earliestDate.clone().add(+experienceDateDelta, UIConfig.calendar.days);
    }
    checkin = checkin.format(UIConfig.dateFormats.DD_MM_YYYY);
    checkout = checkout.format(UIConfig.dateFormats.DD_MM_YYYY);
    return { checkin, checkout };
  };

  getCheckinCheckoutDates = (items) => {
    if (items?.length === 0) return;
    const performances = items.reduce((acc, cur) => {
      return [...acc, ...cur.performances];
    }, []);
    const startDateMoments = performances.map((p) => moment(p.date));
    const earliestDate = moment.min(startDateMoments);
    const endDate = moment(performances[0].endDate);
    const f1Products = items.find((item) => item.productType === UIConfig.ProductType.F1);
    const nonF1Products = items.find((item) => item.productType !== UIConfig.ProductType.F1);
    return this.calculateCheckinCheckoutDate(!!f1Products, !!nonF1Products, earliestDate, endDate);
  };

  onResolveOrderDetails = (data) => {
    const { calendarSettings } = this.props.data;
    const items = this.updateF1ProductsWithType(data?.products, data?.coveoProducts, data?.coveoMapping);
    const dates = this.getCheckinCheckoutDates(items);
    const fromDate = moment(dates.checkin, UIConfig.dateFormats.DD_MM_YYYY);
    const toDate = moment(dates.checkout, UIConfig.dateFormats.DD_MM_YYYY);
    const calFromDate = fromDate.format(calendarSettings.format);
    const calToDate = toDate.format(calendarSettings.format);

    this.setState({
      startDate: fromDate,
      endDate: toDate,
      ...(calFromDate === 'Invalid date' || calToDate === 'Invalid date'
        ? {}
        : { calendarLabel: `${calFromDate} - ${calToDate}` }),
    });
  };
  //YMC related functions for confirmation-summary End

  checkNotValidDates = (fromDate, toDate) => {
    const daycation = this.isDayCation;
    const today = moment();
    const moFromDate = moment(fromDate, UIConfig.dateFormats.DD_MM_YYYY);
    if (
      (!daycation && (toDate === '' || toDate === 'Invalid date')) ||
      fromDate === '' ||
      fromDate === 'Invalid date'
    ) {
      return true;
    }
    if ((!daycation && !moment(toDate, UIConfig.dateFormats.DD_MM_YYYY).isValid()) || !moFromDate.isValid()) {
      return true;
    }
    if (moFromDate.isBefore(today.startOf(UIConfig.calendar.day))) {
      return true;
    }
    return false;
  };

  /**
   * @function componentDidMount set the initial state
   **/

  isKgfOfferApplied(guestData) {
    const experienceWithKgfCarting = this?.props?.data?.packageCategories?.categories.find(
      (category) => category?.kgfCartingEnabled,
    );
    let categorySelected = parseQueryString('category') || sessionStorage?.getItem('selectedCategory');
    let isKgfCartingEnabled = experienceWithKgfCarting?.value === categorySelected;
    setSessionStorage('isKGFCategory', isKgfCartingEnabled);
    // this is setting the sessionstorage only for package listing kgf text
    let packageListingShowKgfText = guestData.some((room) => room.child > 0 && room.adult >= 1) && isKgfCartingEnabled;
    setSessionStorage('packageListingShowKgfText', packageListingShowKgfText);
    // this is for the seting the session storage for package summary and other pages kgf offer applied flag
    return guestData.some((room) => room.child > 0 && room.child <= room.adult) && isKgfCartingEnabled;
  }

  componentDidMount = () => {
    const message = this.guestDisplayText(this.defaultGuestCount, this.props.data);
    const { guestSettings, events, brand, calendarSettings } = this.props.data;
    const disabledDates = this.props.data.calendarSettings.disabledDates;
    let fromDate;
    let toDate;
    let calFromDate;
    let calToDate;
    let isDateInValid = false;

    const urlParams = new URLSearchParams(window.location.search);

    let actualValues = new Map();

    for (const [key, value] of urlParams) {
      actualValues.set(key, value);
    }

    if (actualValues.size === 0) {
      const cookiePresent = getCookie(this.cookieName);
      const cookieObject = cookiePresent && JSON.parse(cookiePresent);
      Object.keys(cookieObject).forEach((key) => {
        actualValues.set(key, cookieObject[key]);
      });
    }

    if (this.isYasIsland) {
      window.addEventListener('scroll', this.viewCheckOnScroll);
      detectViewPort() !== UIConfig.viewportTypes.mobile && window.addEventListener('resize', this.closeMobileWidget);

      let queryStringFromDate, queryStringToDate;

      const queryFrmDate = parseQueryString('from') || actualValues.get('from') || '';
      const queryToDate = parseQueryString('to') || actualValues.get('to') || '';

      if (this.checkNotValidDates(queryFrmDate, queryToDate)) {
        isDateInValid = true;
        queryStringFromDate = '';
        queryStringToDate = '';
      } else {
        isDateInValid = false;
        queryStringFromDate = queryFrmDate;
        queryStringToDate = !this.isDayCation ? queryToDate : '';
      }

      if (this.props.data?.hotelOnlyData?.code) {
        this.setState({ holidayExperience: this.props.data.hotelOnlyData?.code });
      }
      // If query string have parameters, It'll auto populate the value in widget
      if (queryStringFromDate && queryStringToDate) {
        moment.locale(currentLocale());
        // Set details for datepicker
        let category = parseQueryString('category') || actualValues.get('category') || '';
        const found = this.props.data.packageCategories.categories.find((item) => item.value === category);
        const defaultPackage = this.props?.data?.defaultPackageCategory;
        //const packagePlaceholderText = this.props.data.packageCategories.categoryPlaceholder;

        if (this.props.data?.hotelOnlyData?.code) {
          this.setState({ holidayExperience: this.props.data.hotelOnlyData.code });
        } else if (category) {
          this.setState({ holidayExperience: (found && found.value) || defaultPackage });
          this.dynamicBlackoutDates(category);
          this.dynamicAgeGateData(category);
        }

        fromDate = moment(queryStringFromDate, UIConfig.dateFormats.DD_MM_YYYY);
        toDate = moment(queryStringToDate, UIConfig.dateFormats.DD_MM_YYYY);

        calFromDate = fromDate.format(calendarSettings.format);
        calToDate = toDate.format(calendarSettings.format);

        this.cookieObject = {
          ...this.cookieObject,
          from: fromDate.format(UIConfig.dateFormats.DD_MM_YYYY),
          to: toDate.format(UIConfig.dateFormats.DD_MM_YYYY),
          adult: parseQueryString('adult') || actualValues.get('adult') || '',
          child: parseQueryString('child') || actualValues.get('child') || '',
          childAges: parseQueryString('childAges') || actualValues.get('childAges') || '',
          category: this.props.data?.hotelOnlyData?.code || (found && found.value) || defaultPackage,
        };

        this.isYasIsland &&
          setCookie(this.cookieName, JSON.stringify(this.cookieObject), UIConfig.cookiesExpireMs, getBaseURL());

        this.setState({ startDate: fromDate, endDate: toDate, calendarLabel: `${calFromDate} / ${calToDate}` });
        this.paxDetails(actualValues);
        moment.locale(currentLocale());
      }

      let guestDetails = this.paxDetails(actualValues, true);
      const willKgfOfferApply = this.isKgfOfferApplied(guestDetails);

      if (willKgfOfferApply) {
        sessionStorage.setItem('isKgfOfferApplied', true);
      } else {
        sessionStorage.setItem('isKgfOfferApplied', false);
      }

      if (queryStringFromDate && queryStringToDate === '') {
        moment.locale(currentLocale());
        // Set details for datepicker
        let category = parseQueryString('category') || actualValues.get('category') || '';
        const found = this.props.data.packageCategories.categories.find((item) => item.value === category);
        //const packagePlaceholderText = this.props.data.packageCategories.categoryPlaceholder;
        const defaultPackage = this.props?.data?.defaultPackageCategory;

        if (this.props.data?.hotelOnlyData?.code) {
          this.setState({ holidayExperience: this.props.data.hotelOnlyData.code });
        } else if (category) {
          this.setState({ holidayExperience: (found && found.value) || defaultPackage });
          this.dynamicBlackoutDates(category);
          this.dynamicAgeGateData(category);
        }
        fromDate = moment(queryStringFromDate, UIConfig.dateFormats.DD_MM_YYYY);

        calFromDate = fromDate.format(calendarSettings.format);

        this.cookieObject = {
          ...this.cookieObject,
          from: fromDate.format(UIConfig.dateFormats.DD_MM_YYYY),
          adult: parseQueryString('adult') || actualValues.get('adult') || '',
          child: parseQueryString('child') || actualValues.get('child') || '',
          childAges: parseQueryString('childAges') || actualValues.get('childAges') || '',
          category: this.props.data?.hotelOnlyData?.code || (found && found.value) || defaultPackage,
        };

        this.isYasIsland &&
          setCookie(this.cookieName, JSON.stringify(this.cookieObject), UIConfig.cookiesExpireMs, getBaseURL());

        this.setState({ startDate: fromDate, calendarLabel: `${calFromDate}` });
        this.paxDetails(actualValues);
        moment.locale(currentLocale());
      } else {
        const defaultPackage = this.props?.data?.defaultPackageCategory;
        const hotelCode = this.props.data?.hotelOnlyData?.code || '';

        if (defaultPackage) {
          this.setState({
            holidayExperience: defaultPackage,
          });
          this.dynamicBlackoutDates(defaultPackage);
        } else if (hotelCode) {
          this.dynamicAgeGateData(this.props.data.hotelOnlyData);
          this.setState({
            holidayExperience: hotelCode,
          });
          this.dynamicBlackoutDates(hotelCode);
        } else if (this.props.data.packageCategories.categories.length === 1) {
          // categories length equal to 1 is required for single package category.
          const singleCategory = this.props.data.packageCategories.categories[0].value;
          this.dynamicAgeGateData(singleCategory);
          this.setState({
            holidayExperience: singleCategory,
          });
          this.dynamicBlackoutDates(singleCategory);
        }
      }

      if (
        detectViewPort() === UIConfig.viewportTypes.desktop ||
        detectViewPort() === UIConfig.viewportTypes.desktop_xl
      ) {
        document.addEventListener(MouseEvents.MouseDown, this.handleClickOutside);
        document.addEventListener(TouchEvents.TouchStart, this.handleClickOutside);
      }
    } else {
      document.addEventListener(MouseEvents.MouseDown, this.handleClickOutside);
      document.addEventListener(TouchEvents.TouchStart, this.handleClickOutside);
    }

    if (this.enableStackView) {
      const { minValue, maxValue } = guestSettings.guestTypeChild.ageDropDown;
      this.childAgeArray = Array(+maxValue - +minValue + 1)
        .fill()
        .map((_, idx) => +minValue + idx);
    } else {
      this.childAgeArray = Array.from(Array(parseInt(guestSettings.guestTypeChild.maxAgeSelect) + 1).keys());
    }
    this.renderChildAgeDropDown(this.defaultGuestCount[0].child, this.state.guestData[0]);

    !this.state.monthlyNotes.length && this.setMonthlyNotes(this.state.minDate);

    let isQueryStringValid = false;
    const copyActualValue = [];

    if (actualValues.size > 0) {
      for (const [key, value] of actualValues) {
        copyActualValue.push({ key, value });
      }
    }

    const actualData = copyActualValue?.forEach((valueItem) => {
      //It is used to avoid extra querystring parameter in URl
      if (
        valueItem.key === 'category' ||
        valueItem.key === 'from' ||
        valueItem.key === 'to' ||
        valueItem.key === 'adult' ||
        valueItem.key === 'child' ||
        valueItem.key === 'childAges' ||
        valueItem.key === 'brand' ||
        valueItem.key === 'languages' ||
        valueItem.key === 'status'
      ) {
        isQueryStringValid = true;
      }
    });

    if (
      copyActualValue.size === 0 ||
      !isQueryStringValid ||
      actualValues.get('from') === '' ||
      (this.isYasIsland && isDateInValid) ||
      this.isYMC
    ) {
      this.setState({
        guestSelectorLabel1: message.guestSelectorLabel1,
        guestSelectorLabel2: message.guestSelectorLabel2,
        universalContainerClasses: this.getUniversalContainerClasses(this.state.hotelBookingForm),
      });
    }

    if (events && !isEmpty(events)) {
      const { eventDates, eventName, colorCode } = events;

      Array.isArray(eventDates) &&
        eventDates.forEach(({ endDate, startDate }) => {
          this.arrayOfDates = [...this.arrayOfDates, ...getIncludedDates(endDate, startDate)];
          return this.arrayOfDates;
        });
      this.highlightDates.push({
        'event-dates': this.arrayOfDates,
      });
    }

    // Get blackout days
    if (!this.isYasIsland && disabledDates && !isEmpty(disabledDates)) {
      const blackoutDays = [];
      Array.isArray(disabledDates) &&
        disabledDates.forEach((dateRange) => {
          blackoutDays.push(...getIncludedDates(dateRange.endDate, dateRange.startDate));
        });

      this.highlightDates.push({
        'blackout-date': blackoutDays,
      });
    }

    window.PubSub.subscribe('windowResize', this.setStickyVarient);
    document.addEventListener(KeyboardEvents.Keydown, (e) => {
      if (this.state.isGuestSelectorOpen) {
        if (e.keyCode === 27) {
          const guestSelectorPlaceholder = document.querySelector('.guest-selector-placeholder ');
          if (guestSelectorPlaceholder) {
            guestSelectorPlaceholder.focus();
          }
          this.setState({
            isGuestSelectorOpen: false,
          });
        }

        const innerContainer = document.querySelector('.c-hotel-booking-widget-guest-selector .inner-container');
        const childDetails = document.querySelector('.c-hotel-booking-widget-guest-selector .child-details');
        const lastItemInGuestSelector = this.enableStackView
          ? innerContainer.nextSibling
            ? childDetails.lastChild.getElementsByTagName('select')[0]
            : innerContainer.lastChild.getElementsByClassName('increase-btn')[0]
          : document.querySelector('.add-btn');

        if (e.keyCode === 9 && document.activeElement === lastItemInGuestSelector) {
          const guestSelector = document.querySelector('.c-hotel-booking-widget-guest-selector');
          if (guestSelector && guestSelector.nextSibling) {
            guestSelector.nextSibling.focus();
          }
          this.setState({
            isGuestSelectorOpen: false,
          });
        }
      }
    });
    let footerWrapper = document.getElementsByClassName('footer-wrapper')[0];
    footerWrapper && this.state.stickyVariant && footerWrapper.classList.add('hotel-widget-white-space');
    //this event will be subscribed only if orderStatus field in localstorage is false
    if (this.isYMC) {
      window.PubSub.subscribe(UIConfig.events.PUBLISH_ORDER_STATUS_ONCE, (_, data) => {
        this.setState({ orderStatusFromBookingYMC: data.orderStatus });
      });

      window.PubSub.subscribe(UIConfig.events.PUBLISH_ORDER_DETAILS, (_, data) => {
        if (this.state.orderStatusFromBookingYMC === false) {
          this.onResolveOrderDetails(data);
        }
      });
    }

    window.PubSub.subscribe('widgetData', (msg, data) => {
      this.onCallSelect(data);
    });
  };

  addToCartGA = (gaData) => {
    const categoryItem = this.props?.data?.packageCategories?.categories?.find(
      (item) => item.value === gaData.category,
    );
    categoryItem?.gaLabel && sessionStorage.setItem('GA_PackageType', JSON.stringify(categoryItem?.gaLabel));
    categoryItem?.gaCategory && sessionStorage.setItem('GA_Category', JSON.stringify(categoryItem?.gaCategory));

    const container = {};
    container['event'] = 'e_booknow';
    container['journeyType'] = 'staycation';
    container['category'] = categoryItem?.gaCategory;
    container['checkindate'] = gaData?.checkindate || '';
    container['checkoutdate'] = gaData?.checkoutdate || '';
    container['paxCount'] = `${gaData.numberOfAdult}-${gaData.numberOfChildrens}-0`;
    container['numberOfNights'] = gaData?.nights || '';
    container['numberOfRooms'] = gaData?.numberOfRooms || '';
    // Needs to confirm
    container['roomNights'] = gaData?.nights * gaData?.numberOfRooms;
    container['numberOfAdults'] = gaData?.numberOfAdult || '';
    container['numberOfChildren'] = gaData?.numberOfChildrens || '';

    window.dataLayer = window.dataLayer || [];
    const dataItem = {
      ...container,
    };

    window.dataLayer.push(dataItem);
  };

  /**
   * @function componentDidUpdate make the required dom changes after update
   **/
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isDatePickerOpen) {
      const [datePickerInput] = document.getElementsByClassName('date-picker-input');
      datePickerInput && datePickerInput.focus();
    }
    this.setFocusOnErrorField(prevState);
  }

  /**
   * @function dynamicBlackoutDates to create dynamic blackout dates based on category selection
   **/
  dynamicBlackoutDates = (categoryOption) => {
    const blackoutDays = [];
    this.disabledDatesRange = this.props.data.calendarSettings.disabledDates.filter(
      (item) => item.categoryCode === categoryOption,
    );
    Array.isArray(this.disabledDatesRange) &&
      this.disabledDatesRange.forEach((dateRange) => {
        blackoutDays.push(...getIncludedDates(dateRange.endDate, dateRange.startDate));
      });
    this.highlightDates = [];
    this.highlightDates.push({
      'blackout-date': blackoutDays,
    });
    this.disabledDates = this.getDatesList(this.disabledDatesRange);
    this.excludeDates = this.getFormattedDates(this.disabledDates);

    const dynamicStartDate = this.props.data.calendarSettings?.startDatesByCategory?.find(
      (item) => item.categoryCode === categoryOption,
    );
    if (dynamicStartDate) {
      this.actualStartDate = moment(dynamicStartDate.startDate).isValid()
        ? momentTimezone(dynamicStartDate.startDate, getClientUtcOffset())
        : moment();

      // Dynamic start date got selected based on category
      this.minDate = getValidStartDate(dynamicStartDate.startDate).add(dynamicStartDate.defaultDateDelta, 'days');
      this.setState({ minDate: this.minDate });
    } else {
      this.actualStartDate = moment(this.calendarSettings.startDate).isValid()
        ? momentTimezone(this.calendarSettings.startDate, getClientUtcOffset())
        : moment();
      this.minDate = getValidStartDate(this.calendarSettings.startDate).add(
        this.calendarSettings.defaultDateDelta,
        'days',
      );
      this.setState({ minDate: this.minDate });
    }
  };

  /**
   * @function componentWillUnmount to remove event listener
   **/
  componentWillUnmount = () => {
    document.removeEventListener(MouseEvents.MouseDown, this.handleClickOutside);
    document.removeEventListener(TouchEvents.TouchStart, this.handleClickOutside);
    window.removeEventListener('resize', this.closeMobileWidget);
    window.removeEventListener('scroll', this.viewCheckOnScroll);
  };

  getDatesList = (disabledDatesRange) => {
    let disabledDates = [];
    if (disabledDatesRange) {
      disabledDatesRange.forEach((dateObj) => {
        if (dateObj.startDate && dateObj.endDate) {
          this.getDatesFromRange(new Date(dateObj.startDate), new Date(dateObj.endDate)).forEach((date) =>
            disabledDates.push(date),
          );
        } else if (dateObj.startDate) {
          disabledDates.push(
            momentTimezone(new Date(dateObj.startDate), getClientUtcOffset()).format(UIConfig.calendar.dateFormat),
          );
        }
      });
    }
    return disabledDates;
  };

  getDatesFromRange = (startDate, stopDate) => {
    let dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(momentTimezone(currentDate, getClientUtcOffset()).format(UIConfig.calendar.dateFormat));
      currentDate = addDays(currentDate, 1);
    }
    return dateArray;
  };

  getFormattedDates(dates) {
    return dates.length ? dates.map((dateString) => momentTimezone(dateString, getClientUtcOffset())) : [];
  }

  /**
   * @function checkStickyVariant Funtion for the Sticky Varient and it will return boolean
   **/
  checkStickyVariant = () => {
    const { data } = this.props;
    if (detectMobile()) {
      if (detectViewPort() === UIConfig.viewportTypes.mobile && this.redVariant) {
        return true;
      }
      return this.props.data.promocodeSettings.isPromoCodeEnable && data.variant === this.checkIfSticky;
    }
  };

  /**
   * @function setStickyVarient Funtion for the Sticky Varient and it will update the state, it is used for Subscribing the windowResize event
   **/
  setStickyVarient = () => {
    this.setState({
      stickyVariant: this.checkStickyVariant(),
    });
  };

  /**
   * @function getUpdatedDates Funtion modifies and return dates object based on data in stateObj
   * @return {dates} Object with keys startDate, endDate, minDate , calendarTooltip
   **/
  getUpdatedDates = (date) => {
    let dates = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      minDate: this.state.minDate,
      calendarTooltip: this.state.calendarTooltip,
      selectRange: this.state.selectRange,
    };
    if (
      !dates.startDate ||
      date.diff(dates.startDate) < 0 ||
      (this.state.startDate ? date.format() === this.state.startDate.format() : false)
    ) {
      dates.startDate = date;
      dates.endDate = null;
      dates.calendarTooltip = this.calendarSettings.checkOutText;
      dates.selectRange = true;
    } else if (!dates.endDate) {
      dates.endDate = date;
      dates.minDate = this.minDate;
      dates.calendarTooltip = this.calendarSettings.checkInText;
      dates.selectRange = false;
    } else {
      dates.startDate = date;
      dates.endDate = null;
      dates.calendarTooltip = this.calendarSettings.checkOutText;
      dates.selectRange = true;
    }
    return dates;
  };

  /**
   * @function handleDateCallback is a callback from HotelBookingWidgetDatepicker which gets trigeted after dates is slected
   * Based on dates selection it set state and rerender component
   **/
  handleDateCallback = (dateSelection) => {
    const data = this.props.data;
    dateSelection = convertJsDateToMomentObj(dateSelection, this.state.startDate, this.state.endDate);
    currentLocale() === 'ar-sa' &&
      (dateSelection['_locale']['_monthsShort'] = data.calendarSettings.monthsTranslations.shortName);
    const UpdatedDates = this.getUpdatedDates(dateSelection);
    const startDate = UpdatedDates.startDate ? UpdatedDates.startDate.format(data.calendarSettings.format) : '';
    const endDate = UpdatedDates.endDate ? UpdatedDates.endDate.format(data.calendarSettings.format) : '';
    const updatedDatesCopy = JSON.parse(JSON.stringify(UpdatedDates));
    const startDateCheck = momentTimezone(updatedDatesCopy.startDate, getClientUtcOffset()).add('days', 1);

    this.disabledDates = this.disabledDates?.map((date) => moment(date).format(UIConfig.calendar.dateFormat)) || [];

    let dateSelectionError = false;
    let dateSelectionErrorMessage = '';
    let minDate = null;
    let calendarLabel = null;
    let startDateError = false;
    let endDateError = false;
    let dateNotInRangeError = false;

    if (startDate && !endDate) {
      const selectedDates = [{ startDate: startDateCheck, endDate: startDateCheck }];
      const selectedRange = this.getDatesList(selectedDates);

      if (selectedRange.length) {
        for (let i = 0; i < selectedRange.length; i++) {
          if (this.disabledDates.includes(selectedRange[i])) {
            dateSelectionError = true;
            dateSelectionErrorMessage = this.calendarSettings.invalidCheckInMessage || '';
            minDate = this.minDate;
            UpdatedDates.startDate = null;
            UpdatedDates.selectRange = true;
            UpdatedDates.calendarTooltip = this.calendarSettings.checkInText;
            startDateError = true;
          }
        }
      }
    }

    if (endDate) {
      const selectedDates = [{ startDate: UpdatedDates.startDate, endDate: UpdatedDates.endDate }];
      const selectedRange = this.getDatesList(selectedDates);

      if (selectedRange.length) {
        for (let i = 0; i < selectedRange.length; i++) {
          if (this.disabledDates.includes(selectedRange[i])) {
            dateSelectionError = true;
            dateSelectionErrorMessage = this.calendarSettings.invalidCheckOutMessage || '';
            minDate = this.minDate;
            UpdatedDates.endDate = null;
            UpdatedDates.selectRange = true;
            UpdatedDates.calendarTooltip = this.calendarSettings.checkOutText;
            endDateError = true;
          }
        }
      }
    }

    const divider = this.isYasIsland ? '/' : '-';
    if (!startDateError && !endDateError) {
      calendarLabel = `${startDate} ${divider} ${endDate}`;
    } else if (!startDateError) {
      calendarLabel = `${startDate} ${divider} `;
    }
    if (UpdatedDates.startDate && UpdatedDates.endDate && this.arrayOfDates.length) {
      const isDatesInRange = isValidSelection(this.arrayOfDates, UpdatedDates.startDate, UpdatedDates.endDate);
      dateNotInRangeError = !isDatesInRange ? true : false;
    }

    if (data.flashSaleSettings.isSaleOn) {
      const selectedDates = [{ startDate: UpdatedDates.startDate, endDate: UpdatedDates.endDate }];
      let localeStartDate = UpdatedDates.startDate && UpdatedDates.startDate.clone();
      localeStartDate.locale('en').format(UIConfig.calendar.spaceDateFormat);
      const selectedRange = this.getDatesList(selectedDates);
      const cmsStartDate = new Date(data.flashSaleSettings.startDate);
      const cmsEndDate = new Date(data.flashSaleSettings.endDate);
      const minimumDays = parseInt(data.flashSaleSettings.numberOfDays);
      // const isFlashSaleApplicable = moment(new Date()).isBetween(cmsStartDate, moment(cmsEndDate));
      const isUserStartDateInRange = isWithinInterval(
        new Date(moment(localeStartDate).format(UIConfig.calendar.dateFormat)),
        {
          start: new Date(moment(cmsStartDate).format(UIConfig.calendar.dateFormat)),
          end: new Date(moment(cmsEndDate).format(UIConfig.calendar.dateFormat)),
        },
      );
      // if (startDate && endDate && isFlashSaleApplicable && isUserStartDateInRange) {
      if (startDate && endDate && isUserStartDateInRange) {
        if (selectedRange.length < minimumDays + 1) {
          dateSelectionError = true;
          dateSelectionErrorMessage = this.calendarSettings.invalidCheckOutMessage || '';
        }
      }
    }

    this.setState({
      startDate: UpdatedDates.startDate,
      endDate: UpdatedDates.endDate,
      minDate: minDate || UpdatedDates.minDate,
      calendarTooltip: UpdatedDates.calendarTooltip,
      selectRange: UpdatedDates.selectRange,
      calendarLabel: calendarLabel || this.calendarSettings.placeHolder,
      calendarLabelError: false,
      dateSelectionError,
      dateSelectionErrorMessage,
      dateNotInRangeError,
      isDatePickerOpen: false,
    });

    if (
      this.isYasIsland &&
      (detectViewPort() === UIConfig.viewportTypes.mobile || detectViewPort() === UIConfig.viewportTypes.tablet)
    ) {
      this.setState({
        isDatePickerOpen: true,
      });
    } else {
      this.setState({
        isDatePickerOpen: !(startDate && endDate && !dateSelectionError),
      });
    }
  };

  /**
   * @function getUpdatedSingleDates Funtion modifies and return dates object based on data in stateObj
   * @return {dates} Object with keys startDate, endDate, minDate , calendarTooltip
   **/
  getUpdatedSingleDates = (date) => {
    const todayDate = new Date();
    let dates = {
      startDate: this.state.startDate,
      minDate: moment(todayDate).add(this.calendarSettings.defaultDateDelta, 'days'),
      calendarTooltip: this.state.calendarTooltip,
    };
    dates.startDate = date;
    return dates;
  };

  /**
   * @function handleSingleDateCallback is a callback from HotelBookingWidgetDatepicker which gets trigeted after dates is slected
   * Based on dates selection it set state and rerender component
   **/
  handleSingleDateCallback = (dateSelection) => {
    const data = this.props.data;
    dateSelection = convertJsDateToMomentObj(dateSelection);
    currentLocale() === 'ar-sa' &&
      (dateSelection['_locale']['_monthsShort'] = data.calendarSettings.monthsTranslations.shortName);
    const UpdatedDates = this.getUpdatedSingleDates(dateSelection);
    const startDate = UpdatedDates.startDate ? UpdatedDates.startDate.format(data.calendarSettings.format) : '';
    this.disabledDates = this.disabledDates?.map((date) => moment(date).format(UIConfig.calendar.dateFormat)) || [];

    this.setState({
      startDate: UpdatedDates.startDate,
      minDate: UpdatedDates.minDate,
      calendarTooltip: UpdatedDates.calendarTooltip,
      selectRange: false,
      isDatePickerOpen: !startDate,
      calendarLabel: startDate || this.calendarSettings.placeHolder,
      calendarLabelError: false,
    });
    if (
      this.isYasIsland &&
      (detectViewPort() === UIConfig.viewportTypes.mobile || detectViewPort() === UIConfig.viewportTypes.tablet)
    ) {
      this.setState({
        isDatePickerOpen: true,
      });
    }
    if (
      this.props.data.variant == UIConfig.commonVariant.bookingWidgetOnlyStartDate &&
      document.querySelectorAll('.no-scroll').length > 0
    ) {
      document.getElementsByTagName('body')[0].classList.remove('no-scroll');
    }
  };

  /**
   * @function datePickerShowHide show and hide datePicker based on
   * @param {data} received
   * false to hide
   * true to show
   **/
  datePickerShowHide = (data) => {
    this.setMonthlyNotes(this.state.minDate);
    this.setState({
      isDatePickerOpen: data,
    });

    if (
      detectViewPort() === UIConfig.viewportTypes.mobile &&
      (this.props.data.variant == UIConfig.commonVariant.bookingWidgetOnlyStartDate ||
        this.props.data.variant == V_BOOKINGWIDGET_REDBACKGROUND)
    ) {
      if (data) {
        document.getElementsByTagName('body')[0].classList.add('no-scroll');
      } else if (!data && document.querySelectorAll('.no-scroll').length > 0) {
        document.getElementsByTagName('body')[0].classList.remove('no-scroll');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('no-scroll');
      }
    }
  };

  /**
   * @function guestSelectorShowHide show and hide guest selector based on
   * @param {data} received
   * false to hide
   * true to show
   **/
  guestSelectorShowHide = (data) => {
    this.setState({
      isGuestSelectorOpen: data,
    });
  };

  /**
   * @function guestDisplayText display the final text based on the guess selected
   **/

  guestDisplayTextDivder = () => {
    if (this.isYasIsland) {
      return '/';
    }
    if (this.isYMC) {
      return ' , ';
    }
    return ' ';
  };

  guestDisplayText = (guestData) => {
    const { data } = this.props;
    const divider = this.guestDisplayTextDivder();
    const sumOfAdult = guestData.map((room) => (room.adult ? room.adult : 0)).reduce((a, b) => a + b, 0);
    const sumOfChild = guestData.map((room) => (room.child ? room.child : 0)).reduce((a, b) => a + b, 0);
    const roomLabel = guestData.length > 1 ? data.guestSettings.roomsLabel : data.guestSettings.roomLabel;
    const adultLabel =
      sumOfAdult > 1 ? data.guestSettings.guestTypeAdult.labels : data.guestSettings.guestTypeAdult.label;
    const childLabel =
      sumOfChild === 0
        ? ''
        : sumOfChild > 0 && sumOfChild < 2
        ? ` ${divider} ${sumOfChild} ${data.guestSettings.guestTypeChild.label}`
        : ` ${divider} ${sumOfChild} ${data.guestSettings.guestTypeChild.labels}`;

    return {
      guestSelectorLabel1:
        (this.enableStackView && guestData.length > 0 && guestData[0].room && ` - ${guestData[0].room} ${roomLabel}`) ||
        ` ${this.isYasIsland ? '-' : ''} ${guestData.length} ${roomLabel}`,
      guestSelectorLabel2: `${sumOfAdult} ${adultLabel}${childLabel}`,
    };
  };

  /**
   * @function addRoom adds a room based on default guess value
   **/
  addRoom = (e) => {
    const addBtn = document.getElementsByClassName('add-btn');
    const roomInnerContainer = document.getElementsByClassName('room-inner-container');
    const guestData = deepCloneObject(this.state.guestData);
    guestData.push({ ...this.defaultGuestCount[0] });
    this.guestSetState(guestData);
    scrollTo(roomInnerContainer[0], addBtn[0].offsetTop, 500);
    const newRoomId = guestData.length - 1;
    if (e.detail === 0) {
      setTimeout(() => {
        this.setFocusOnNewlyAddedRoom();
      }, 100);
    }
    if (this.isYasIsland) {
      setTimeout(() => {
        document.getElementById(`accordion__heading-${newRoomId}`).click();
      }, 10);
    }
  };

  /**
   * @function setFocusOnNewlyAddedRoom sets focus on the first decrease button on the newly added room.
   **/
  setFocusOnNewlyAddedRoom = () => {
    const roomWrapper = document.querySelectorAll('.room-wrapper');
    roomWrapper[roomWrapper.length - 1].querySelector('.decerease-btn').focus();
  };

  /**
   * @function renderChildAgeDropDown renders child age dropdown based on count and current room data
   **/
  renderChildAgeDropDown = (count, currentGuestDetail) => {
    if (currentGuestDetail.childAge.length) {
      if (count < currentGuestDetail.childAge.length) {
        currentGuestDetail.childAge = currentGuestDetail.childAge.slice(0, count);
      } else {
        currentGuestDetail.childAge.push('');
      }
    } else {
      currentGuestDetail.childAge = Array(count).fill('');
    }
  };

  /**
   * @function handleSpinnerCallback is the callback for Spinner for Adult and Child
   * @param {count}
   * @param {address}
   **/
  handleSpinnerCallback = (count, address) => {
    const identifier = address.split('_');
    const guestData = deepCloneObject(this.state.guestData);
    const currentGuestDetail = guestData[identifier[1]];
    currentGuestDetail[identifier[0]] = count;
    if (identifier[0] === 'child') {
      this.renderChildAgeDropDown(count, currentGuestDetail);
    }
    this.guestSetState(guestData);
  };

  /**
   * @function deleteRoom is the callback to delect specfic room
   **/
  deleteRoom = (roomNum) => {
    const guestData = deepCloneObject(this.state.guestData);
    guestData.splice(roomNum, 1);
    this.guestSetState(guestData);
    if (guestData.length === 1) {
      document.querySelector('.add-btn').focus();
    }
  };

  /**
   * @function guestSetState set the state and rerender DOM
   **/
  guestSetState = (guestData) => {
    const message = this.guestDisplayText(guestData);

    this.setState({
      guestData,
      guestSelectorLabel1: message.guestSelectorLabel1,
      guestSelectorLabel2: message.guestSelectorLabel2,
      guestLabelError: false,
    });
  };

  /**
   * @function detectError detect error in dateSelctor and GuestSelector
   **/
  detectError = (
    holidayExperience,
    startDate,
    endDate,
    adultCount,
    childCount,
    props,
    childAges,
    dateNotInRangeError,
    dateSelectionError,
  ) => {
    let isDateClean = true;
    let isGuestClean = true;
    let childAgeClean = true;
    let isCategory = true;

    if (!startDate || !endDate) {
      isDateClean = false;
    }
    if (
      this.isYasIsland &&
      holidayExperience === '' &&
      (props.hotelOnlyData === null || props.hotelOnlyData?.code === '')
    ) {
      isCategory = false;
      this.setState({ holidayExperienceLabelError: true });
    } else {
      isCategory = true;
      this.setState({ holidayExperienceLabelError: false });
    }

    if (adultCount.reduce((a, b) => a + b, 0) + childCount.reduce((a, b) => a + b, 0) <= 0) {
      isGuestClean = false;
    }

    if (!this.isYasIsland) {
      childAges.forEach((childAge) => {
        if (!childAge) {
          childAgeClean = false;
          return false;
        }
      });
    } else {
      childAges.forEach((childAge) => {
        if (childAge === '') {
          childAgeClean = false;
          return false;
        }
      });
    }

    !isDateClean &&
      this.setState({ calendarLabel: props.calendarSettings.calendarValidation, calendarLabelError: true });
    !isGuestClean &&
      this.setState({ guestSelectorLabel1: props.guestSettings.guestValidation, guestSelectorLabel2: '' });
    !childAgeClean &&
      this.setState({
        guestLabelError: true,
        guestSelectorLabel1: props.guestSettings.guestTypeChild.childAgeValidation,
        guestSelectorLabel2: '',
      });

    return isCategory && isDateClean && isGuestClean && childAgeClean && !dateNotInRangeError && !dateSelectionError;
  };

  /**
   * @function redirect redireds to service URL by replacing static keys to dynamic value
   **/
  redirect = (data) => {
    const state = { ...this.state };
    const adultCount = state.guestData.map((room) => room.adult);
    const childCount = state.guestData.map((room) => room.child);
    let childAges = state.guestData.map((eachRoom) => eachRoom.childAge);
    childAges = [].concat.apply([], childAges);
    sessionStorage.setItem('GA_MainCategory', UIConfig.b2c.flowType.longName.staycation);
    sessionStorage.setItem('GA_DaycationCategory', data?.packageCategories?.categories[0]?.gaCategory);
    state.startDate = state.startDate && state.startDate.clone().locale('en');
    if (state.endDate) {
      state.endDate = state.endDate.clone().locale('en');
    }

    if (
      this.detectError(
        state.holidayExperience,
        state.startDate,
        state.endDate,
        adultCount,
        childCount,
        data,
        childAges,
        state.dateNotInRangeError,
        state.dateSelectionError,
        state.holidayExperienceLabelError,
      )
    ) {
      const dynamicUrl = Object.keys(data.services)[0];
      let url = data.services[dynamicUrl].url;
      const fromDate = state.startDate.format(UIConfig.dateFormats.DD_MM_YYYY);
      const toDate = state.endDate.format(UIConfig.dateFormats.DD_MM_YYYY);
      const childAgesTemp = state.guestData.map((room) => room.childAge.length && room.childAge.join()).join();
      const childAges = childAgesTemp.split(',').filter((cAge) => cAge !== 0);
      const brand = data.brand;
      const redirectionLangCompare = getCurrentLanguage();
      const langFlag = data.redirectionOverlay.languages.indexOf(redirectionLangCompare) >= 0;
      const language = langFlag ? 'en' : redirectionLangCompare.split('-')[0] || '';
      const promoCode = state.promoCode ? state.promoCode : '*';
      const returnURL = data.returnURL;
      const reditrectionLang = !data.redirectionOverlay ? null : langFlag;
      const redirectionDuration = !data.redirectionOverlay ? null : data.redirectionOverlay.redirectionDuration;
      const category = this.state.holidayExperience.replaceAll(' ', '-');

      let redirectUrl = this.getRedirectUrl(
        url,
        fromDate,
        toDate,
        adultCount,
        childCount,
        childAges,
        brand,
        language,
        promoCode,
        returnURL,
        category,
      );

      if (this.enableStackView && redirectUrl.indexOf('{rooms}') !== -1) {
        const rooms = state.guestData.length > 0 && state.guestData[0].room;
        redirectUrl = redirectUrl.replace('{rooms}', rooms);
      }

      if (canUseDOM()) {
        checkAvailabilityAnalytics(window.location.href);
      }

      if (this.isYasIsland) {
        let gaDataItems = {};

        // Used to find Total number of nights
        const fDate = moment(fromDate, UIConfig.dateFormats.DD_MM_YYYY);
        const tDate = moment(toDate, UIConfig.dateFormats.DD_MM_YYYY);
        const diffDays = tDate.diff(fDate, 'days');

        gaDataItems.journeyType = 'staycation';
        gaDataItems.checkindate = fromDate;
        gaDataItems.checkoutdate = toDate;
        gaDataItems.numberOfRooms = adultCount.length;
        gaDataItems.numberOfAdult = adultCount.reduce((a, b) => a + b, 0);
        gaDataItems.numberOfChildrens = childCount.reduce((a, b) => a + b, 0);
        gaDataItems.nights = diffDays;
        gaDataItems.totalPaxCount = gaDataItems.numberOfAdult + gaDataItems.numberOfChildrens;

        try {
          GTMData.push('widgetSearch', gaDataItems);
          GTMDataOnClickCTA({
            name: `${toLowerCase(data.redirectLabel)} - ${toLowerCase(gaDataItems.journeyType)}` || '',
            [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(data.redirectLabel) || '',
            category: toLowerCase(gaDataItems.journeyType) || '',
          });
        } catch (error) {
          console.log('GA4 error for widget search:', error);
        }
        // Below event is part of old GA analytics
        gaDataItems.category = category;
        this.addToCartGA(gaDataItems);
      }

      this.cookieObject = {
        ...this.cookieObject,
        from: fromDate,
        to: toDate,
        adult: adultCount.join(),
        child: childCount.join(),
        childAges: childAges.join(),
        category: category,
      };

      this.isYasIsland &&
        setCookie(this.cookieName, JSON.stringify(this.cookieObject), UIConfig.cookiesExpireMs, getBaseURL());

      window.PubSub.publish('stickyClose');
      this.hotelBookingWidgetToggle('redirect');
      if (data.redirectionOverlay && reditrectionLang) {
        const customClass = 'redirection-overlay-wrapper';
        window.PubSub.publish('toggleOverlayState', {
          shouldOpen: true,
          customClass: customClass,
          dataToAppend: <RedirectionOverlay {...data.redirectionOverlay} />,
        });
        setTimeout(function() {
          this.isSwad ? window.open(redirectUrl, '_blank') : (window.location = redirectUrl);
        }, redirectionDuration);
      } else {
        this.isSwad ? window.open(redirectUrl, '_blank') : (window.location = redirectUrl);
      }
    }
  };

  /**
   * @function detectSingleDateError detect error in dateSelctor and GuestSelector
   **/
  detectSingleDateError = (startDate, adultCount, childCount, props, childAges) => {
    let isDateClean = true;
    let isGuestClean = true;
    let childAgeClean = true;

    if (!startDate) {
      isDateClean = false;
    } else {
      isDateClean = true;
    }

    if (adultCount.reduce((a, b) => a + b, 0) + childCount.reduce((a, b) => a + b, 0) <= 0) {
      isGuestClean = false;
    }

    childAges.forEach((childAge) => {
      if (!childAge) {
        childAgeClean = false;
        return false;
      }
    });

    !isDateClean &&
      this.setState({ calendarLabel: props.calendarSettings.calendarValidation, calendarLabelError: true });
    !isGuestClean &&
      this.setState({ guestSelectorLabel1: props.guestSettings.guestValidation, guestSelectorLabel2: '' });
    !childAgeClean &&
      this.setState({
        guestLabelError: true,
        guestSelectorLabel1: props.guestSettings.guestTypeChild.childAgeValidation,
        guestSelectorLabel2: '',
      });

    return isDateClean && isGuestClean && childAgeClean;
  };

  /**
   * @function getRedirectUrl redirects URL to service by replacing static keys to dynamic value
   */

  getRedirectUrl = (
    url,
    fromDate,
    toDate,
    adultCount,
    childCount,
    childAges,
    brand,
    language,
    promoCode,
    returnURL,
    category,
  ) => {
    let newUrl = url
      .replace('{formDate}', fromDate)
      .replace('{adultCount}', adultCount.join())
      .replace('{childCount}', childCount.join())
      .replace('{childAges}', childAges)
      .replace('{brand}', brand)
      .replace('{language}', language)
      .replace('{returnURL}', returnURL);

    if (toDate) newUrl = newUrl.replace('{toDate}', toDate);

    if (promoCode) newUrl = newUrl.replace('{promoCode}', promoCode);

    if (this.isYasIsland) newUrl = newUrl.replace('{holidayCategory}', category || '');

    return newUrl;
  };

  /**
   * @function redirectSingleDate redireds to service URL by replacing static keys to dynamic value
   **/
  redirectSingleDate = (data) => {
    const state = { ...this.state };
    const adultCount = state.guestData.map((room) => room.adult);
    const childCount = state.guestData.map((room) => room.child);
    let childAges = state.guestData.map((eachRoom) => eachRoom.childAge);
    childAges = [].concat.apply([], childAges);

    sessionStorage.setItem('GA_MainCategory', UIConfig.b2c.flowType.longName.daycation);
    sessionStorage.setItem('GA_DaycationCategory', data.packageCategories.categories[0].gaCategory);

    state.startDate = state.startDate && state.startDate.clone().locale('en');
    if (this.detectSingleDateError(state.startDate, adultCount, childCount, data, childAges)) {
      const dynamicUrl = Object.keys(data.services)[0];
      let url = data.services[dynamicUrl].url;
      const fromDate = state.startDate.format(UIConfig.dateFormats.DD_MM_YYYY);

      const childAgesTemp = state.guestData.map((room) => room.childAge.length && room.childAge.join()).join();
      const childAges = childAgesTemp.split(',').filter((cAge) => cAge !== 0);
      const brand = data.brand;
      const redirectionLangCompare = getCurrentLanguage();
      const langFlag = data.redirectionOverlay.languages.indexOf(redirectionLangCompare) >= 0;
      const language = langFlag ? 'en' : redirectionLangCompare.split('-')[0] || '';

      const returnURL = data.returnURL;
      const reditrectionLang = !data.redirectionOverlay ? null : langFlag;
      const redirectionDuration = !data.redirectionOverlay ? null : data.redirectionOverlay.redirectionDuration;
      const category = this.state.holidayExperience.replaceAll(' ', '-');

      let redirectUrl = this.getRedirectUrl(
        url,
        fromDate,
        '',
        adultCount,
        childCount,
        childAges,
        brand,
        language,
        '',
        returnURL,
        category,
      );

      if (this.enableStackView && redirectUrl.indexOf('{rooms}') !== -1) {
        const rooms = state.guestData.length > 0 && state.guestData[0].room;
        redirectUrl = redirectUrl.replace('{rooms}', rooms);
      }

      if (data.promocodeSettings.isPromoCodeEnable === true && state.promoCode) {
        const promoCode = state.promoCode ? state.promoCode : '*';
        redirectUrl = redirectUrl.replace('{promoCode}', promoCode);
      }

      if (canUseDOM()) {
        checkAvailabilityAnalytics(window.location.href);
      }

      if (this.isYasIsland) {
        let gaDataItems = {};

        // Used to find Total number of nights
        const fDate = moment(fromDate, UIConfig.dateFormats.DD_MM_YYYY);

        gaDataItems.journeyType = 'daycation';
        gaDataItems.checkindate = fromDate;
        gaDataItems.checkoutdate = 'N/A';
        gaDataItems.numberOfRooms = adultCount.length;
        gaDataItems.numberOfAdult = adultCount.reduce((a, b) => a + b, 0);
        gaDataItems.numberOfChildrens = childCount.reduce((a, b) => a + b, 0);
        gaDataItems.nights = 'N/A';
        gaDataItems.totalPaxCount = gaDataItems.numberOfAdult + gaDataItems.numberOfChildrens;

        try {
          GTMData.push('widgetSearch', gaDataItems);
          GTMDataOnClickCTA({
            name: `${toLowerCase(data.redirectLabel)} - ${toLowerCase(gaDataItems.journeyType)}` || '',
            [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(data.redirectLabel) || '',
            category: toLowerCase(gaDataItems.journeyType) || '',
          });
        } catch (error) {
          console.log('GA4 error for widget search:', error);
        }

        // Below event is part of old GA analytics
        gaDataItems.category = category;
        this.addToCartGA(gaDataItems);
      }

      this.cookieObject = {
        ...this.cookieObject,
        from: fromDate,
        to: '',
        adult: adultCount.join(),
        child: childCount.join(),
        childAges: childAges.join(),
        category: category,
      };

      this.isYasIsland &&
        setCookie(this.cookieName, JSON.stringify(this.cookieObject), UIConfig.cookiesExpireMs, getBaseURL());

      window.PubSub.publish('stickyClose');
      this.hotelBookingWidgetToggle('redirect');

      if (data.redirectionOverlay && reditrectionLang) {
        const customClass = 'redirection-overlay-wrapper';
        window.PubSub.publish('toggleOverlayState', {
          shouldOpen: true,
          customClass: customClass,
          dataToAppend: <RedirectionOverlay {...data.redirectionOverlay} />,
        });
        setTimeout(function() {
          this.isSwad ? window.open(redirectUrl, '_blank') : (window.location = redirectUrl);
        }, redirectionDuration);
      } else {
        this.isSwad ? window.open(redirectUrl, '_blank') : (window.location = redirectUrl);
      }
    }
  };

  /**
   * @function handleClickOutside is a callback to close dateSelector or guessSelector based on click outside.
   **/
  handleClickOutside = (event) => {
    if (this.state.isDatePickerOpen || this.state.isGuestSelectorOpen) {
      this.datePickerRef && !this.datePickerRef.current.contains(event.target) && this.datePickerShowHide(false);
      this.guestSelectorRef &&
        !this.guestSelectorRef.current.contains(event.target) &&
        this.guestSelectorShowHide(false);
    }
  };

  /**
   * @function onChildAgeChange set the child age
   **/
  onChildAgeChange = (e, roomNum, childNum) => {
    const value = e.target.value;
    const guestData = deepCloneObject(this.state.guestData);
    guestData[roomNum].childAge[childNum] = value;
    this.setState({ isChildSelectOpen: false });
    this.guestSetState(guestData);
  };
  onChildFocus = (e) => {
    this.setState({ isChildSelectOpen: true });
  };

  updatePromo = (e) => {
    this.setState({
      promoCode: e.target.value.toUpperCase(),
    });
  };

  hotelBookingWidgetToggle = (type) => {
    let elSticky = document.getElementsByClassName('c-hotel-booking-RedBackground');
    if (type == 'redirect' && elSticky && elSticky.length > 0) elSticky[0].style.zIndex = '1001';

    const toggle = this.state.hotelBookingForm;
    this.setState({
      hotelBookingForm: !toggle,
      universalContainerClasses: this.getUniversalContainerClasses(!toggle),
    });
    this.state.hotelBookingForm && !this.redVariant
      ? document.body.classList.add('model-open')
      : document.body.classList.remove('model-open');
  };

  /**
   * Accessibility function for open datepicker on press
   */
  handleKeyDownDatePicker = (event) => {
    const keyCode = event.keyCode || event.which;

    if (keyCode === KeyCodes.Enter) {
      this.datePickerShowHide(true);
      if (this.state.isGuestSelectorOpen) {
        this.guestSelectorShowHide(false);
      }
      if (this.state.isDatePickerOpen) {
        this.handleDatePickerFocus();
      }
    } else if (keyCode === KeyCodes.Esc) {
      this.handleDatePickerFocus();
      this.datePickerShowHide(false);
    }

    checkArrowKeys(event) && this.changeAriaLabel();
  };

  handleDatePickerFocus = () => {
    const datePickerInput = document.querySelector('.date-picker-placeholder');
    if (datePickerInput) {
      datePickerInput.focus();
    }
  };

  handleKeyDownGuestSelector = (event) => {
    const keyCode = event.keyCode;
    if (keyCode === KeyCodes.Enter) {
      this.guestSelectorShowHide(true);
      if (this.state.isDatePickerOpen) {
        this.datePickerShowHide(false);
      }
    }
  };

  handleKeyDownGetPackage = (event, data) => {
    const keyCode = event.keyCode;
    if (keyCode === KeyCodes.Enter) {
      this.isGetPackagesKeyPressed = true;
      this.redirect(data);
    }
  };

  handleKeyDownGetPackageSingleDate = (event, data) => {
    const keyCode = event.keyCode;
    if (keyCode === KeyCodes.Enter) {
      this.isGetPackagesKeyPressed = true;
      this.redirectSingleDate(data);
    }
  };

  changeAriaLabel = (e) => {
    setTimeout(() => {
      let selectedDom = canUseDOM() && document.querySelector('.react-datepicker__day--keyboard-selected');
      if (selectedDom) {
        let selectedDate = selectedDom.getAttribute('aria-label');
        document.querySelector('#selectedDateId').innerHTML = `${selectedDate}`;
      }
    }, 10);
  };

  /**
   *  Desc: Focus the Date picker placeholder or guest selector placeholder on field error
   */
  setFocusOnErrorField = (state) => {
    if (this.isGetPackagesKeyPressed) {
      if (state.calendarLabelError) {
        document.querySelector('.date-picker-placeholder').focus();
        this.isGetPackagesKeyPressed = false;
      }
      if (state.guestLabelError) {
        document.querySelector('.guest-selector-placeholder').focus();
        this.isGetPackagesKeyPressed = false;
      }
    }
  };

  /**
   *  Desc: close guest section on focus get package button
   */
  handleFocus() {
    let guestVissible = document.querySelectorAll('.room-container-wrapper').length;
    if (guestVissible) {
      this.setState({
        isGuestSelectorOpen: false,
      });
    }
  }

  handleFocusOutCalendar = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isDatePickerOpen: false,
    });
  };

  excludedDateToolTip = () => {
    const toolTipRender = [];
    this.disabledDatesRange &&
      this.disabledDatesRange.forEach((dateObject) => {
        const { toolTip, startDate, endDate } = dateObject;
        if (startDate && endDate) {
          const disabledDatesArr = this.getDatesFromRange(new Date(startDate), new Date(endDate));
          if (disabledDatesArr && disabledDatesArr.length > 0) {
            disabledDatesArr.forEach((dd, arrIndx) => {
              toolTipRender.push(this.getToolTipElement(dd, toolTip, arrIndx));
            });
          }
        } else if (startDate) {
          toolTipRender.push(this.getToolTipElement(startDate, toolTip));
        }
      });
    return toolTipRender;
  };

  //** Single Date ToolTip **/
  excludedStartDateToolTip = () => {
    const toolTipRender = [];
    this.disabledDatesRange &&
      this.disabledDatesRange.forEach((dateObject) => {
        const { toolTip, startDate } = dateObject;
        if (startDate) {
          toolTipRender.push(this.getToolTipElement(startDate, toolTip));
        }
      });
    return toolTipRender;
  };

  getToolTipElement = (date, toolTip) => {
    const givenDate = new Date(date);
    const dateFromGivenDate = givenDate.getDate();
    const monthFromGivenDate = givenDate.getMonth() + 1;
    const disbaledDayTooltip = `div.tooltip-${dateFromGivenDate}-${monthFromGivenDate}.react-datepicker__day--disabled:hover::after{content: "${decodeHtmlEntity(
      toolTip,
    )}";}`;

    return (
      <DynamicContent
        key={`${dateFromGivenDate}-${monthFromGivenDate}`}
        tagName="style"
        innerHtml={disbaledDayTooltip}
      />
    );
  };

  setMonthlyNotes = (date, monthChanged = false) => {
    if (!monthChanged && this.state.startDate && this.state.startDate.isValid()) {
      date = this.state.startDate;
    }
    if (date.isValid()) {
      const nativeDate = new Date(date);
      moment.locale('en-gb');

      const monthlyNotes = [];
      const reqDateFormat = UIConfig.calendar.hotelBookingWidget.notesConfigDateFormat;
      const activeMonthYear_1 = moment(nativeDate).format(reqDateFormat);
      const activeMonthYear_2 = moment(nativeDate)
        .add(1, 'months')
        .format(reqDateFormat);

      if (detectViewPort() === UIConfig.viewportTypes.mobile) {
        this.monthlyNotesConfigMobile[activeMonthYear_1] &&
          monthlyNotes.push(this.monthlyNotesConfigMobile[activeMonthYear_1]);
      } else {
        this.monthlyNotesConfigDesktop[activeMonthYear_1] &&
          monthlyNotes.push(this.monthlyNotesConfigDesktop[activeMonthYear_1]);
        this.monthlyNotesConfigDesktop[activeMonthYear_2] &&
          monthlyNotes.push(this.monthlyNotesConfigDesktop[activeMonthYear_2]);
      }
      this.setState({ monthlyNotes });
      moment.locale(currentLocale());
    }
  };

  monthChangeHandler = (date) => {
    date = convertJsDateToMomentObj(date);
    this.setMonthlyNotes(date, true);
  };

  getEventNotIncludedErrorMsg = () => {
    const {
      data: { events },
    } = this.props;
    return (
      this.state.dateNotInRangeError &&
      events.eventSelectionErrMsg &&
      !isEmpty(events) && <div className="date-not-in-range-error"> {events.eventSelectionErrMsg}</div>
    );
  };
  dynamicAgeGateData = (category) => {
    const { data } = this.props;
    const cookiePresent = getCookie(this.cookieName);
    const cookieObject = cookiePresent && JSON.parse(cookiePresent);
    let queryStringFromDate, queryFrmDate;

    if (!this.isYasIsland) {
      queryStringFromDate = parseQueryString('from') || (cookieObject.from && cookieObject.from) || '';
    } else {
      queryFrmDate = parseQueryString('from') || (cookieObject.from && cookieObject.from) || '';
      if (this.checkNotValidDates(queryFrmDate, '')) {
        queryStringFromDate = '';
      } else {
        queryStringFromDate = queryFrmDate;
      }
    }

    if (data.hotelOnlyData.code) {
      const childMaxAge = category && category.childMaxAge ? category.childMaxAge : V_CHILDMAXAGE;
      const childMinAge = category && category.childMinAge ? category.childMinAge : 0;
      const guestData = [{ ...this.defaultGuestCount[0] }];
      queryStringFromDate === '' && this.guestSetState(guestData);
      this.childAgeArray = this.getChildAgeRange(childMinAge, childMaxAge);
      this.setState({
        showInformativeText: category ? category.informativeText : '',
        adultAgeLimit: category && category.adultAgeLimit ? category.adultAgeLimit : 0,
        childAgeLimit: category && category.childAgeLimit ? category.childAgeLimit : 0,
      });
    } else {
      const findCategoryDetails = this.props.data.packageCategories.categories.find(
        (option) => option.value.toLowerCase() === category.toLowerCase(),
      );
      const childMaxAge =
        findCategoryDetails && findCategoryDetails.childMaxAge ? findCategoryDetails.childMaxAge : V_CHILDMAXAGE;
      const childMinAge = findCategoryDetails && findCategoryDetails.childMinAge ? findCategoryDetails.childMinAge : 0;
      // const guestData = [{ ...this.defaultGuestCount[0] }];
      // this.guestSetState(guestData);
      this.childAgeArray = this.getChildAgeRange(childMinAge, childMaxAge);
      this.setState({
        showInformativeText: findCategoryDetails ? findCategoryDetails.informativeText : '',
        adultAgeLimit: findCategoryDetails && findCategoryDetails.adultAgeLimit ? findCategoryDetails.adultAgeLimit : 0,
        childAgeLimit: findCategoryDetails && findCategoryDetails.childAgeLimit ? findCategoryDetails.childAgeLimit : 0,
      });
    }
  };
  getChildAgeRange = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  };

  onSelect = (option) => {
    setSessionStorage('GA4PackageType', option?.label);
    this.setState({
      holidayExperience: option.value,
      holidayExperienceLabelError: false,
      mobileCategoryError: false,
      mobileCalendarError: false,
      mobileChildAgeError: false,
    });
    this.applyBlackOutDates(option.value);
  };

  onCallSelect = (data) => {
    const found = this.props.data.packageCategories.categories.find((item) => item.label === data.wData);
    this.setState({
      holidayExperience: found.value,
      holidayExperienceLabelError: false,
      mobileCategoryError: false,
      mobileCalendarError: false,
      mobileChildAgeError: false,
    });
    this.applyBlackOutDates(found.value);
    if (detectViewPort() === UIConfig.viewportTypes.tablet || detectViewPort() === UIConfig.viewportTypes.mobile)
      this.openMobileBookingWidget();
  };

  /**
   * Desc: Blackout dates will be applied based on category
   */
  applyBlackOutDates = (categoryValue) => {
    this.dynamicAgeGateData(categoryValue);
    this.dynamicBlackoutDates(categoryValue);
    this.setState({ startDate: null, endDate: null, calendarLabel: this.calendarSettings.placeHolder });
  };

  /**
   *  Desc: Mobile Booking Widget for Yasisland
   */
  openMobileBookingWidget = (hotelOnlyFlow) => {
    document.body.classList.add('widget-opened');
    if (hotelOnlyFlow?.code || this.props.data.variant.includes(UIConfig.commonVariant.bookingWidgetOnlyStartDate)) {
      this.setState({
        mobileWidget: true,
        isDatePickerOpen: true,
        widgetSteps: 2,
      });
    } else {
      this.setState({
        mobileWidget: true,
        isDatePickerOpen: true,
        widgetSteps: 1,
      });
    }
    if (document.getElementsByTagName('body')[0].classList.contains('info-section')) {
      const sectionHeight = document.querySelector('.infosection').offsetHeight;
      const topPosition = sectionHeight - 108;
      let bookingWidget = document.querySelector('.backgroundWrapper');
      bookingWidget.style.top = `-${topPosition}px`;
    } else {
      document.querySelector('.backgroundWrapper').removeAttribute('style');
    }
    if (document.querySelector('.v-tabs-hotel-booking-widget')) {
      document.querySelector('.v-tabs-hotel-booking-widget').classList.add('m-widget-opened');
    } else {
      document.querySelector('.v-tabs-hotel-booking-widget').classList.remove('m-widget-opened');
    }
  };

  /**
   *  Desc: Mobile Booking Widget Back Arrow functionality for Yasisland
   */
  mobilePrevSteps = () => {
    this.setState({
      widgetSteps: this.state.widgetSteps - 1,
      mobileCalendarError: false,
      mobileCategoryError: false,
      mobileChildAgeError: false,
    });
  };

  /**
   *  Desc: Mobile Booking Widget Next Button Functionality for Yasisland
   */
  mobileNextSteps = () => {
    const { data } = this.props;
    const { holidayExperience, widgetSteps, startDate, endDate, guestData } = this.state;
    this.datePickerShowHide(true);
    this.guestSelectorShowHide(true);
    if (widgetSteps === 1) {
      if (holidayExperience !== '') {
        this.setState({
          widgetSteps: this.state.widgetSteps + 1,
          mobileCategoryError: false,
          mobileChildAgeError: false,
        });
      } else {
        this.setState({
          mobileCategoryError: true,
        });
      }
    } else if (widgetSteps === 2) {
      if (this.props.data.variant !== UIConfig.commonVariant.bookingWidgetOnlyStartDate) {
        if (startDate && endDate) {
          this.setState({
            mobileWidget: true,
            isDatePickerOpen: true,
            widgetSteps: this.state.widgetSteps + 1,
            mobileCalendarError: false,
          });
        } else {
          this.setState({
            mobileCalendarError: true,
          });
        }
      } else {
        if (startDate) {
          this.setState({
            mobileWidget: true,
            isDatePickerOpen: true,
            widgetSteps: this.state.widgetSteps + 1,
            mobileCalendarError: false,
          });
        } else {
          this.setState({
            mobileCalendarError: true,
          });
        }
      }
    } else {
      let childAges = guestData.map((eachRoom) => eachRoom.childAge);
      childAges = [].concat.apply([], childAges);
      let childAgeError = childAges.find((childAge) => childAge === '');
      if (childAgeError === '') {
        this.setState({
          mobileChildAgeError: true,
        });
      } else {
        this.setState({
          mobileChildAgeError: false,
        });
        this.props.data.variant !== UIConfig.commonVariant.bookingWidgetOnlyStartDate
          ? this.redirect(data)
          : this.redirectSingleDate(data);
      }
    }
  };

  /**
   *  Desc: Close Mobile Booking Widget
   */
  closeMobileWidget = () => {
    if (detectViewPort() === UIConfig.viewportTypes.desktop || detectViewPort() === UIConfig.viewportTypes.desktop_xl) {
      document.addEventListener(MouseEvents.MouseDown, this.handleClickOutside);
      document.addEventListener(TouchEvents.TouchStart, this.handleClickOutside);
    } else {
      document.removeEventListener(MouseEvents.MouseDown, this.handleClickOutside);
      document.removeEventListener(TouchEvents.TouchStart, this.handleClickOutside);
    }
    if (document.querySelector('.v-tabs-hotel-booking-widget')) {
      document.querySelector('.v-tabs-hotel-booking-widget').classList.remove('m-widget-opened');
    }
    document.body.classList.remove('widget-opened');
    this.setState({
      widgetSteps: 0,
      mobileCalendarError: false,
      mobileCategoryError: false,
      mobileChildAgeError: false,
      mobileWidget: false,
      isDatePickerOpen: true,
      isGuestSelectorOpen: false,
    });
    document.querySelector('.backgroundWrapper').removeAttribute('style');
  };

  enabledInfoText = (status) => {
    window.PubSub.publish('infromativeTextEnabled', { isEnabled: status });
  };

  renderHotelWidgetComponent = () => {
    const { data } = this.props;
    const isHotelOnly =
      data?.hotelOnlyData?.code || data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate
        ? this.state.widgetSteps > 2
        : this.state.widgetSteps > 1;
    if (
      this.isYasIsland &&
      (data.variant === UIConfig.commonVariant.bookingWidgetVariant ||
        data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate) &&
      data.packageCategories.categories &&
      data.packageCategories.categories.length === 1
    ) {
      this.childAgeArray = this.getChildAgeRange(
        data.packageCategories.categories[0].childMinAge,
        data.packageCategories.categories[0].childMaxAge,
      );
    }

    return (
      <>
        {this.isYasIsland &&
          (data.variant === UIConfig.commonVariant.bookingWidgetVariant ||
            data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate) &&
          isHotelOnly && <span className="mobile-back" onClick={this.mobilePrevSteps}></span>}
        {this.isYasIsland &&
          (data.variant === UIConfig.commonVariant.bookingWidgetVariant ||
            data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate) &&
          this.state.mobileCalendarError && (
            <span className="mobile-calendar-error">{data.calendarSettings.calendarValidation}</span>
          )}
        {this.isYasIsland &&
          (data.variant === UIConfig.commonVariant.bookingWidgetVariant ||
            data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate) &&
          this.state.mobileCategoryError && (
            <span className="mobile-category-error">{data.packageCategories.categoryValidation}</span>
          )}
        {this.isYasIsland &&
          (data.variant === UIConfig.commonVariant.bookingWidgetVariant ||
            data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate) &&
          this.state.mobileChildAgeError && (
            <span className="mobile-pax-error">{data.guestSettings.guestTypeChild.childAgeValidation}</span>
          )}
        {this.isYasIsland &&
          (data.variant === UIConfig.commonVariant.bookingWidgetVariant ||
            data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate) && (
            <>
              {data.title && <span className="widget-title">{data.title}</span>}
              <span className="mobile-category" onClick={() => this.openMobileBookingWidget(data.hotelOnlyData)}>
                {this.state.startDate ? (
                  <span className="widget-data">
                    {this.state.startDate && `${moment(this.state.startDate).format('DD MMM')}`}
                    {this.state.endDate && `/ ${moment(this.state.endDate).format('DD MMM')}`}
                    {this.state.guestSelectorLabel2 && ` - ${this.state.guestSelectorLabel2}`}
                  </span>
                ) : data.hotelOnlyData !== null && data.hotelOnlyData?.code !== '' ? (
                  data.calendarSettings?.label
                ) : (
                  data.packageCategories.categoryTitle
                )}
                <span className={`widget-icon ${this.state.startDate ? 'edit-widget' : ''}`}></span>
              </span>
            </>
          )}
        {this.isYasIsland &&
        this.state.widgetSteps === 0 &&
        data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate &&
        (detectViewPort() === UIConfig.viewportTypes.mobile || detectViewPort() === UIConfig.viewportTypes.tablet) &&
        this.state.showInformativeText ? (
          <>
            {this.enabledInfoText(true)}
            <div
              className={classNames(this.state.universalContainerClasses, {
                'informative-text': this.state.showInformativeText ? true : false,
              })}
              data-c-render="universal"
            >
              <div className="content"> {this.state.showInformativeText}</div>
            </div>
          </>
        ) : (
          this.enabledInfoText(false)
        )}
        {this.isYasIsland &&
          (data.variant === UIConfig.commonVariant.bookingWidgetVariant ||
            data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate) &&
          this.state.mobileWidget && <span className="mobile-widget-close" onClick={this.closeMobileWidget}></span>}
        {this.isYasIsland &&
          (!data.hotelOnlyData || data.hotelOnlyData === null || data.hotelOnlyData?.code === '') &&
          data.variant === UIConfig.commonVariant.bookingWidgetVariant && (
            <>
              {data.packageCategories.categories && data.packageCategories.categories.length === 1
                ? this.setState({ holidayExperience: data.packageCategories.categories[0].value })
                : ''}

              <HotelBookingWidgetCategorySelector
                dataList={data.packageCategories}
                holidayExperienceLabelError={this.state.holidayExperienceLabelError}
                categoryValidation={data.packageCategories.categoryValidation}
                onSelect={this.onSelect}
                defaultOption={this.state.holidayExperience}
              />
            </>
          )}
        <HotelBookingWidgetDatepicker
          holidayExperience={this.state.holidayExperience}
          data={data}
          handleDateCallback={this.handleDateCallback}
          handleSingleDateCallback={this.handleSingleDateCallback}
          datePickerShowHide={this.datePickerShowHide}
          ref={this.datePickerRef}
          calendarTooltip={this.state.calendarTooltip}
          calendarLabelError={this.state.calendarLabelError}
          calendarLabel={this.state.calendarLabel}
          isDatePickerOpen={this.state.isDatePickerOpen}
          selectRange={this.state.selectRange}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          minDate={this.state.minDate}
          handleKeyDown={this.handleKeyDownDatePicker}
          excludeDates={this.excludeDates}
          excludedDateToolTip={this.excludedDateToolTip}
          excludedStartDateToolTip={this.excludedStartDateToolTip}
          dateSelectionError={this.state.dateSelectionError}
          dateSelectionErrorMessage={this.state.dateSelectionErrorMessage}
          handleFocusOutCalendar={this.handleFocusOutCalendar}
          actualStartDate={this.actualStartDate}
          monthChangeHandler={this.monthChangeHandler}
          monthlyNotes={this.state.monthlyNotes}
          notesTitle={this.calendarSettings.notesTitle || ''}
          highlightDates={this.highlightDates}
          currentWidgetStep={this.state.widgetSteps}
          dateNotInRangeError={this.state.dateNotInRangeError}
          getEventNotIncludedErrorMsg={this.getEventNotIncludedErrorMsg}
          isRedVariant={this.redVariant}
        />
        <HotelBookingWidgetGuestSelector
          data={data}
          ref={this.guestSelectorRef}
          guestSelectorShowHide={this.guestSelectorShowHide}
          childAgeArray={this.childAgeArray}
          handleSpinnerCallback={this.handleSpinnerCallback}
          onChildAgeChange={this.onChildAgeChange}
          onChildFocus={this.onChildFocus}
          deleteRoom={this.deleteRoom}
          addRoom={this.addRoom}
          guestLabelError={this.state.guestLabelError}
          guestSelectorLabel2={this.state.guestSelectorLabel2}
          guestSelectorLabel1={this.state.guestSelectorLabel1}
          isGuestSelectorOpen={this.state.isGuestSelectorOpen}
          guestData={this.state.guestData}
          roomSettings={this.roomSettings}
          handleKeyDown={this.handleKeyDownGuestSelector}
          enableStackView={this.enableStackView}
          isRedVariant={V_BOOKINGWIDGET_REDBACKGROUND}
          childAgeLimit={this.state.childAgeLimit}
          adultAgeLimit={this.state.adultAgeLimit}
        />
        {this.isYasIsland &&
          (data.variant === UIConfig.commonVariant.bookingWidgetVariant ||
            data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate) &&
          (detectViewPort() === UIConfig.viewportTypes.mobile ||
            detectViewPort() === UIConfig.viewportTypes.tablet) && (
            <div className="btn-primary next-step-wrapper">
              <button onClick={() => this.mobileNextSteps()} tabIndex="0" data-testid="redirect-link">
                {this.state.widgetSteps !== 3 ? data.mobileNextPlaceholder : data.redirectLabel}
              </button>
            </div>
          )}
        {data.promocodeSettings.isPromoCodeEnable && (
          <div className="promo">
            <DynamicContent
              tagName="div"
              innerHtml={data.promocodeSettings.label}
              attrs={{
                className: 'promo--label',
              }}
            />
            <input
              aria-label={data.promocodeSettings.label}
              className="promo--input"
              placeholder={data.promocodeSettings.placeHolder}
              value={this.state.promoCode || ''}
              onChange={this.updatePromo}
            ></input>
          </div>
        )}
      </>
    );
  };

  renderEventAndHotelWidgetComponent = (vBookingWidgetOnlyStartDate) => {
    return (
      <div className={vBookingWidgetOnlyStartDate && 'c-hotel-booking-widget-wrapper'}>
        <div className="c-hotel-event-booking-widget">{this.renderHotelWidgetComponent()}</div>
        {detectViewPort() !== UIConfig.viewportTypes.mobile && this.getEventNotIncludedErrorMsg()}
      </div>
    );
  };

  renderWidgetDiscription = (description) => {
    return (
      <DynamicContent
        tagName="div"
        innerHtml={description}
        attrs={{
          className: 'widget--description',
        }}
      />
    );
  };

  checkIfEnabled = () => {
    const { widgetSteps } = this.state;
    const isMobileTablet =
      detectViewPort() === UIConfig.viewportTypes.mobile || detectViewPort() === UIConfig.viewportTypes.tablet;
    return (this.isYasIsland && isMobileTablet && widgetSteps === 1) || !isMobileTablet;
  };

  /**
   * @function render renders the component
   **/
  render = () => {
    const { data } = this.props;
    const { events } = data;
    const vBookingWidgetOnlyStartDate = data.variant == UIConfig.commonVariant.bookingWidgetOnlyStartDate;
    try {
      return (
        <div
          className={classNames({
            'c-hotel-booking-RedBackground': this.redVariant || vBookingWidgetOnlyStartDate,
            backgroundWrapper: data.variant === V_BOOKINGWIDGET_TRANSPARENTBACKGROUND || vBookingWidgetOnlyStartDate,
            'remove-padding': data.variant.includes('remove-padding'),
            'c-hotel-booking-with-image-container': data.variant && data.variant === V_BOOKINGWIDGET_WITHIMAGE,
            'festive-season': (this.redVariant || vBookingWidgetOnlyStartDate) && data.theme === V_FESTIVE_THEME,
            'small-widget': this.props.data?.hotelOnlyData?.code,
          })}
        >
          {this.state.universalContainerClasses && (
            <>
              <div className={this.state.universalContainerClasses} data-c-render="universal">
                {data.imageInfo && this.withImageVariant && (
                  <div className="widget--image">
                    <Image image={data.imageInfo} />
                  </div>
                )}
                {data.title && (
                  <DynamicContent
                    tagName={this.redVariant || vBookingWidgetOnlyStartDate ? 'div' : 'span'}
                    innerHtml={data.title}
                    attrs={{
                      className: 'widget--label',
                    }}
                  />
                )}
                {this.isYMC && data.description && this.renderWidgetDiscription(data.description)}
                {data.bodyText && this.withImageVariant && (
                  <DynamicContent
                    tagName="p"
                    innerHtml={data.bodyText}
                    attrs={{
                      className: 'widget--bodyText',
                    }}
                  />
                )}
                {this.redVariant || vBookingWidgetOnlyStartDate ? (
                  <div
                    className={`c-hotel-booking-content ${this.state.mobileWidget ? 'mobile-widget-opened' : ''} step-${
                      this.state.widgetSteps
                    } ${this.state.mobileCalendarError ? 'datepicker-error' : ''} ${
                      this.state.mobileCategoryError ? 'category-error' : ''
                    } ${this.state.mobileChildAgeError ? 'child-age-error' : ''} `}
                  >
                    {this.renderHotelWidgetComponent()}
                    <div className={`btn-primary book-btn ${data.multiColorButtonRequired ? 'v-multicolor-cta' : ''}`}>
                      <button
                        //daycation is vBookingWidgetOnlyStartDate
                        onClick={() => {
                          const { categories } = data?.packageCategories || {};
                          GTMDataOnClickCTA({
                            name: toLowerCase(data?.redirectLabel),
                            [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(data?.redirectLabel),
                            category: toLowerCase(
                              categories?.find((item) => item?.value === this.state.holidayExperience)?.label,
                            ),
                          });
                          if (vBookingWidgetOnlyStartDate) {
                            this.redirectSingleDate(data);
                          } else {
                            this.redirect(data);
                          }
                        }}
                        onKeyDown={
                          vBookingWidgetOnlyStartDate
                            ? (event) => this.handleKeyDownGetPackageSingleDate(event, data)
                            : (event) => this.handleKeyDownGetPackage(event, data)
                        }
                        onFocus={this.handleFocus}
                        tabIndex="0"
                        data-testid="redirect-link"
                      >
                        <DynamicContent tagName="span" innerHtml={data.redirectLabel} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {!isEmpty(events) && events.eventName
                      ? this.renderEventAndHotelWidgetComponent(vBookingWidgetOnlyStartDate)
                      : this.renderHotelWidgetComponent()}
                    <div className={`btn-primary ${data.multiColorButtonRequired ? 'v-multicolor-cta' : ''}`}>
                      <button
                        //daycation is vBookingWidgetOnlyStartDate
                        onClick={() => {
                          const { categories } = data?.packageCategories || {};
                          GTMDataOnClickCTA({
                            name: toLowerCase(data.redirectLabel),
                            [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(data?.redirectLabel),
                            category: toLowerCase(
                              categories?.find((item) => item?.value === this.state.holidayExperience)?.label,
                            ),
                          });
                          if (vBookingWidgetOnlyStartDate) {
                            this.redirectSingleDate(data);
                          } else {
                            this.redirect(data);
                          }
                        }}
                        onKeyDown={
                          vBookingWidgetOnlyStartDate
                            ? (event) => this.handleKeyDownGetPackageSingleDate(event, data)
                            : (event) => this.handleKeyDownGetPackage(event, data)
                        }
                        onFocus={this.handleFocus}
                        tabIndex="0"
                        data-testid="redirect-link"
                      >
                        <DynamicContent tagName="span" innerHtml={data.redirectLabel} />
                      </button>
                    </div>
                    {data.description && this.withImageVariant && this.renderWidgetDiscription(data.description)}
                  </>
                )}
              </div>
              {this.checkIfEnabled() && this.state.showInformativeText && (
                <div
                  className={classNames(this.state.universalContainerClasses, {
                    'informative-text': this.state.showInformativeText ? true : false,
                  })}
                  data-c-render="universal"
                >
                  <div className="content"> {this.state.showInformativeText}</div>
                </div>
              )}
            </>
          )}
          {!this.isYasIsland && this.state.stickyVariant && (
            <div className={`sticky-button ${this.state.hotelBookingForm ? 'collapse' : 'expand'}`}>
              <button
                className={`${this.state.hotelBookingForm ? 'collapse' : 'expand'}`}
                onClick={this.hotelBookingWidgetToggle}
              >
                {this.state.hotelBookingForm || this.redVariant || vBookingWidgetOnlyStartDate ? (
                  data.title
                ) : (
                  <span className="icon-close"> </span>
                )}
              </button>
            </div>
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'HotelBookingWidget', data.variant);
    }
  };
}

export default HotelBookingWidget;

HotelBookingWidget.propTypes = {
  data: PropTypes.object,
};
