/* eslint-disable no-unused-expressions */
/**
 * CRUD for mini cart and my cart
 */
import moment from 'moment';

import { Logging } from '../../../../common/logger';
import { CartService, ServiceConfig } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  createDeepCopy,
  getLoggedInUser,
  getMainObject,
  parseIfJsonString,
  decodeQueryString,
  isEventTypeF1InCart,
  isYasArenaJourney,
  deepCopyArrayOfObjects,
  isAddonProduct,
  cartHasYASeatedProduct,
  yaSortedNonSeatedProducts,
  getAnonymousCart,
  getLocalStorageByKey,
  setLocalStorage,
  getTenantId,
  isMatchTenant,
  addLoaderOverlay,
  removeLoaderOverlay,
  isTicketUpgradeJourney,
  isLoggedInUser,
} from '../../../../common/utility';
import { CartModel } from './model/cart-model';
import SeatedJourneyActions, { seatedLocalStorageSvc } from './seated-journey-actions';
import YaCartManagementService, { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';

import * as helper from './helper';
import { checkIfParksTenants, getParameterByName, isEmpty } from '../../../../common/utility/helpers';

class CartActions {
  constructor({
    serviceUrl,
    moduleName,
    tenantId,
    is2StepPaymentJourney,
    isSeatedTypeJourney,
    coveoMappingList,
    isMyCartPage,
  }) {
    this._serviceUrls = serviceUrl;
    this._moduleName = moduleName;
    this._tenantId = tenantId;
    this._anonymousCartKey = UIConfig.localStoreKeys.purchaseJourney.anonymousCart;
    this._cartDataKey = UIConfig.localStoreKeys.purchaseJourney.cartData;
    this._promoCodeKey = UIConfig.localStoreKeys.PROMO_CODE;
    this._extraCartParams = {};
    this._is2StepPaymentJourney = is2StepPaymentJourney;
    this.isSeatedTypeJourney = isSeatedTypeJourney;
    this.isMyCartPage = isMyCartPage;

    if (this.isSeatedTypeJourney) {
      this.seatedJourneyActions = new SeatedJourneyActions({
        serviceUrl,
        coveoMappingList,
      });
    }
  }

  /**
   *  Initialize Ya Seated and Non Seated Journey actions
   *  @param {Object} - {propsData, addToCart, isNonSeatedJourney, isPaymentPage, }
   *  @returns Promise object having Ya Journey Actions instance
   */
  initYaJourneyActions = ({ propsData, addToCart, isNonSeatedJourney, isPaymentPage = false, getCartItems }) => {
    return new Promise((resolve, reject) => {
      const { services, miniCart, tabs, coveoMappingList } = propsData;
      const eventDetails = tabs && tabs.length ? tabs[0].eventDetails : {};
      let pageUrls = {};
      if (Object.keys(eventDetails).length) {
        const { errorPageUrl, actionLink, returnUrl, eventName } = eventDetails;
        pageUrls = {
          errorPageUrl,
          actionLink,
          returnUrl,
        };
        YALocalStorageSvc.getSetStaticUrl(pageUrls);
        YALocalStorageSvc.getSetEventTitle(eventName);
      } else {
        pageUrls = YALocalStorageSvc.getSetStaticUrl();
      }
      const { nonSeatedJourneyOverlayLabels: nonSeatedOverlayLabels, ticketValidityMap } = isPaymentPage
        ? propsData.cart
        : miniCart;
      const obj = new YaCartManagementService({
        servicesUrls: services,
        coveoMappingList,
        nonSeatedOverlayLabels,
        addToCart,
        isNonSeatedJourney,
        getCartItems,
        pageUrls,
        eventDetails,
        ticketValidityMap,
      });

      this._yaJourneyActions = obj;
      this._isYaNonSeatedJourney = isNonSeatedJourney;
      if (this._isYaNonSeatedJourney) {
        obj.initNonSeatedOverlay();
      }
      resolve({ actionsObj: obj });
    });
  };

  getAddressObj = () => helper.getAddressObj(this);

  /*
       check for f1 seated type product
     */
  checkCartSeatedTypeProduct = (productList) => {
    if (this._tenantId && this._tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
      return isEventTypeF1InCart(productList, true);
    }
    return false;
  };

  updatedCartWithAddresses = (productList) => helper.updatedCartWithAddresses(this, productList);

  /*
         get custom cart grouped or un-grouped based on config isGrouped
     */
  getCustomCart = (productList, isGrouped = false) => {
    const localObj = getMainObject() || getLoggedInUser();
    const mainObj = localObj?.additionalProperty;
    const showProductDiscount = mainObj?.productLevelDiscount === 'true';

    const cartData = createDeepCopy(CartModel);
    const eventDetails = YALocalStorageSvc.getSetEventDetails();
    if (this._is2StepPaymentJourney && localStorage.anonymousCart) {
      let anonymousCart = getAnonymousCart();
      cartData.cart.reservationOwner = anonymousCart?.cart?.reservationOwner;
    }
    const params = this._extraCartParams;
    if (showProductDiscount && !isEmpty(params) && params?.couponCode === '' && params?.promotions === null) {
      productList.forEach((prod) => {
        delete prod['ItemPromotionList'];
      });
    }
    let total = {};
    if (productList.length > 0) {
      total = this._getCartTotal(productList);

      cartData.cart.totalQuantity = total.quantity;
      cartData.cart.subTotal.total = total.unit;
      cartData.cart.netPrice = total.net;
      cartData.cart.grossPrice = total.gross;
      cartData.cart.totalTax = total.tax;
      cartData.cart.totalDiscount = total.discount;
      cartData.cart.tenantId = this._tenantId;
      cartData.cart.items = isGrouped ? this.groupCartProducts(productList) : productList;

      const cartStoredInSession = JSON.parse(sessionStorage.getItem(this._cartDataKey));
      const anonymousCart = JSON.parse(localStorage.getItem(this._anonymousCartKey));
      if (isLoggedInUser() && cartStoredInSession?.cart?.couponguid) {
        cartData.cart.couponguid = cartStoredInSession?.cart?.couponguid;
      } else if (anonymousCart?.cart?.couponguid) {
        cartData.cart.couponguid = anonymousCart?.cart?.couponguid;
      }
    }
    cartData.cart = Object.assign(cartData.cart, this._extraCartParams);

    // added sale Ak for F1 Seated Product
    if (this.checkCartSeatedTypeProduct(productList)) {
      const saleAk = seatedLocalStorageSvc.GetSetSaleAk();
      if (saleAk) {
        cartData.cart.saleAk = saleAk;
      }
    } else if (isYasArenaJourney()) {
      if (!this._isYaNonSeatedJourney && Object.keys(total).length > 0) {
        cartData.cart.totalFee = Number(total.fee);
        cartData.cart.grossPrice = total.fee > 0 ? total.gross + total.fee : total.gross;
      }

      Array.isArray(cartData.cart.items) &&
        cartData.cart.items.length &&
        cartData.cart.items.forEach((item) => {
          item.isPromotionalEvent = eventDetails.isPromotionalEvent;
        });
      cartData.cart.isCouponRequired = eventDetails.isPromoCodeRequired || getLocalStorageByKey('isPromoCodeRequired');
      cartData.cart = this._addYACartProperties(cartData.cart, productList, isGrouped);
    } else {
      cartData.cart.saleAk = '';
    }

    if (this.isMyCartPage) {
      const address = this.updatedCartWithAddresses(productList);
      cartData.cart.billingAddress = address.billingAddress;
      cartData.cart.shippingAddress = address.shippingAddress;
    }

    return cartData;
  };

  /**
   *  Add properties required for Yas Arena Journey
   *  @param {object} - cart
   *  @param {Array} -  productList
   *  @param {Boolean} -  isGrouped
   *  @returns cart with Arena Properties
   */
  _addYACartProperties = (cart, productList, isGrouped) => {
    const { seatedCheckoutSalesAK } = UIConfig.b2c.purchaseJourney.ymcMapping.localStorageKey;
    const salesAk = localStorage.getItem(seatedCheckoutSalesAK);
    const localSalesAk = YALocalStorageSvc.getSetOrderId();
    if (cartHasYASeatedProduct(productList)) {
      const { performanceId, openTime, closeTime } = YALocalStorageSvc.getSetPerformance();
      const promotions = productList[0].promotions || YALocalStorageSvc.getSetPromotionDetail().promotions;

      cart.saleAk = !isEmpty(localSalesAk) ? localSalesAk : salesAk || '';
      cart.performanceId = performanceId;
      cart.timeSlot = `${openTime} - ${closeTime}`;

      if (promotions) {
        cart.promotions = deepCopyArrayOfObjects(promotions);
        cart.couponCode = promotions[0].code;
        cart.hasImplicitPromotion = true;
        YALocalStorageSvc.getSetPromotionDetail({
          promotions: promotions,
        });
        delete productList[0]['promotions']; //not required for cart and checkbasket
      }
    }

    if (!isGrouped && this._isYaNonSeatedJourney) {
      //GMT+4
      const cartCreatedTime = moment()
        .utcOffset(4)
        .format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
      cart.items = cart.items.map((item) => {
        if (isAddonProduct(item)) {
          return item;
        }
        !item.timeStamp && (item.timeStamp = cartCreatedTime);
        return item;
      });
    }

    if (productList.length) {
      const yaProduct = productList.find((item) => !isAddonProduct(item));
      yaProduct &&
        yaProduct.timeStamp &&
        window.PubSub.publish('startCartTimer', {
          startDate: yaProduct.timeStamp,
        });
    } else {
      window.PubSub.publish('stopCartTimer');
    }

    return cart;
  };

  //get total calculations for cart from product list
  _getCartTotal = (productList) => {
    const total = {
      net: 0,
      unit: 0,
      gross: 0,
      quantity: 0,
      discount: 0,
      tax: 0,
      fee: 0,
      grosswithoutfee: 0,
    };

    productList.forEach((product) => {
      total.quantity += Number(product.quantity);
      total.unit += Number(product.unitPrice);
      total.net += Number(product.price.net);
      total.gross += Number(product.price.gross);
      total.tax += Number(product.price.tax);
      total.discount += Number(product.discount.computed ? product.discount.computed : 0);
      if (product.price.fee && isYasArenaJourney() && !this._isYaNonSeatedJourney) {
        total.fee = Number(product.price.fee);
      }
    });

    return total;
  };

  /*
         get cart data based on user logged in state
         from API or from local store respectively
     */
  getCart = (isTicketUpgradeFlow = false) => {
    if (this._getUser() && !isTicketUpgradeFlow) {
      return CartService.getCart(this._moduleName, this._serviceUrls.getCart.url);
    } else {
      return this._getAnonymousCart();
    }
  };

  _updateSeatedSaleAk = (orderId) => {
    const { seatedCheckoutSalesAK } = UIConfig.b2c.purchaseJourney.ymcMapping.localStorageKey;
    const tenantId = getLoggedInUser().tenantID || JSON.parse(localStorage.mainObj).tenantID;
    if (tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
      const saleAk = seatedLocalStorageSvc.GetSetSaleAk();
      if (!saleAk && orderId) {
        seatedLocalStorageSvc.GetSetSaleAk(orderId);
      }
    }
    if (tenantId.toLowerCase() === UIConfig.tenants.ya) {
      setLocalStorage(seatedCheckoutSalesAK, orderId || '');
    }
  };

  exitingCartOnPageLoad = (doCheckBasket) => helper.exitingCartOnPageLoad(this, doCheckBasket);

  getCombinedCartOnPageLoad = (doCheckBasket, orderId) =>
    helper.getCombinedCartOnPageLoad(this, doCheckBasket, orderId);

  getYASeatedCartOnPageLoad = (doCheckBasket, orderId) =>
    helper.getYASeatedCartOnPageLoad(this, doCheckBasket, orderId);

  getCartOnPageLoad = (doCheckBasket = false) => {
    if (this.isSeatedTypeJourney) {
      const params = decodeQueryString();
      const saleAk = seatedLocalStorageSvc.GetSetSaleAk();
      if ('sale' in params && saleAk !== params.sale) {
        return this.getCombinedCartOnPageLoad(doCheckBasket, params.sale);
      }
      return this.exitingCartOnPageLoad(doCheckBasket);
    } else if (isYasArenaJourney()) {
      const params = decodeQueryString();
      if ('sale' in params) {
        return this.getYASeatedCartOnPageLoad(doCheckBasket, params.sale);
      } else if ('payment_error' in params && !this._isYaNonSeatedJourney) {
        return this.exitingCartOnPageLoad(false);
      } else {
        return this.exitingCartOnPageLoad(doCheckBasket);
      }
    }
    return this.exitingCartOnPageLoad(doCheckBasket);
  };

  _getCartDataWrapper = (doCheckBasket = false) => {
    if (this._getUser() && !isTicketUpgradeJourney()) {
      return this._getAnonymousCart()
        .then((response) => {
          if (response.data && response.data.cart && response.data.cart.items.length > 0) {
            return this._updateLocalCartToAPI(response.data, response.data.cart.items)
              .then(() => {
                this._deleteCartFromStore();
                return this._getCartData(doCheckBasket);
              })
              .catch((response) => {
                this._deleteCartFromStore();
                return response;
              });
          } else {
            return this._getCartData(doCheckBasket);
          }
        })
        .catch((response) => {
          if (response.error && response.error.code === UIConfig.errorCodes.emptyCart) {
            return this._getCartData(doCheckBasket);
          } else {
            return response;
          }
        });
    } else {
      return this._getCartData(doCheckBasket);
    }
  };

  _getCartData = (doCheckBasket) => {
    let isTicketUpgradeFlow = false;
    if (isTicketUpgradeJourney()) {
      isTicketUpgradeFlow = true;
      doCheckBasket = false;
    }

    if (doCheckBasket) {
      return this.getCartMashup();
    } else {
      return this.getCart(isTicketUpgradeFlow);
    }
  };

  _storeExtraCartParams = (cartData) => {
    this._extraCartParams.promotions = cartData.promotions;
    this._extraCartParams.couponCode = cartData.couponCode;
  };

  /*
         get anonymous cart from local store
     */
  _getAnonymousCart = (type) => {
    return new Promise((resolve, reject) => {
      let cartData = JSON.parse(localStorage.getItem(this._anonymousCartKey));
      cartData = cartData && this._deleteLocalCartIfExpired(cartData);
      const tenantId = getLoggedInUser().tenantID || JSON.parse(localStorage.mainObj).tenantID;

      if (cartData) {
        const currentPkgs = cartData?.cart?.packages;
        if (tenantId.toLowerCase() === UIConfig.ymcB2CTenant && currentPkgs?.length) {
          let updatedPkg;
          if (!type) {
            updatedPkg = this._ungroupPackageProds(currentPkgs);
          } else {
            updatedPkg = currentPkgs;
          }
          cartData.cart = { ...cartData.cart, packages: updatedPkg };
        }
        const response = {
          data: cartData,
        };

        resolve(response);
      } else {
        const response = this._getCustomError({
          errorCode: UIConfig.errorCodes.emptyCart,
          errorText: 'no cart in local store',
        });
        reject(response);
      }
    });
  };

  /*
    update discount prop for packages
  */
  updateDiscountPropPackage = (packages) => {
    packages.forEach((pkg) => {
      const items = pkg.items;
      items?.length &&
        items.forEach((item) => {
          item.productType = null;
          item.discount = {
            actualPerc: 0,
            computed: 0,
          };
        });
    });
    return packages;
  };

  /*
    Use only valid promo codes for YMC
  */
  updateSelectedPromos = (cart) => {
    const promos = cart?.promotions;
    const appliedPromotions = promos?.length && promos.filter((prmo) => prmo?.type === '2' || prmo?.type === '4');
    return appliedPromotions?.length ? appliedPromotions : null;
  };

  /*
    Remove discount coming from package product on YMC
  */
  getValidPkgDiscount = (pkgList) => {
    const newPkgList = [];
    pkgList.forEach((pkg) => {
      const updatedItems = [];
      pkg.items?.length &&
        pkg.items.forEach((item) => {
          if (typeof item?.discount === 'number') {
            updatedItems.push({
              ...item,
              discount: { actualPerc: 0, computed: 0 },
            });
          } else {
            updatedItems.push(item);
          }
        });
      newPkgList.push({ ...pkg, items: updatedItems });
    });
    return newPkgList;
  };

  /*
         get check basket reponse
     */
  getCartMashup = () => {
    const tenantId = getLoggedInUser().tenantID || JSON.parse(localStorage.mainObj).tenantID;
    if (this._getUser()) {
      return CartService.getCartMashup(this._moduleName, this._serviceUrls.getCartMashup.url, false);
    } else {
      return this._getAnonymousCart('grouped')
        .then((response) => {
          return new Promise((resolve, reject) => {
            CartService.getCartMashupAnonymous(
              this._moduleName,
              this._serviceUrls.getCartMashupAnonymous.url,
              response.data,
            )
              .then((response) => {
                if (response.data && response.data.cart) {
                  if (tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
                    const packages = response.data.cart?.packages;
                    if (packages?.length) {
                      window.PubSub.publish('getPackageRes', {
                        packages: packages,
                      });
                      response.data.cart.promotions = this.updateSelectedPromos(response.data.cart);
                      response.data.cart.packages = this.updateDiscountPropPackage(packages);
                    }
                  }
                  this._setLocalCart(response.data);
                }
                resolve(response);
              })
              .catch((response) => {
                if (tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
                  if (response.data && response.data.cart) {
                    const packages = response.data.cart?.packages;
                    if (packages?.length) {
                      const promos = response.data.cart?.promotions;
                      const appliedPromotions =
                        promos?.length && promos.filter((prmo) => prmo?.type === '2' || prmo?.type === '4');
                      response.data.cart.promotions = appliedPromotions;
                      response.data.cart.packages = this.updateDiscountPropPackage(packages);
                    }
                    this._setLocalCart(response.data.cart);
                  }
                }
                reject(response);
              });
          });
        })
        .catch((response) => {
          return new Promise((resolve, reject) => {
            reject(response);
          });
        });
    }
  };

  /*
     method to return error msg in custom error format
 */
  _getCustomError = ({ errorCode = '', errorType = UIConfig.errorCodes.custom, errorText = '' }) => {
    const data = {
      error: {
        code: errorCode,
        type: errorType,
        text: errorText,
      },
    };
    return data;
  };

  checkIfVIPProducts = (data) =>
    data?.find((product) => product?.ticketType === 'VVIP' || product?.ticketType === 'VIP');

  /*
         update product list and cart in local store or api
         based on users logged in state
     */
  updateCart = (productList, products, maxQuantity = 99, remainingQuantity = 99, extraCartParams = {}) => {
    if (Object.keys(extraCartParams).length > 0) {
      this._extraCartParams = Object.assign(this._extraCartParams, extraCartParams);
    }

    if (isYasArenaJourney() && this._isYaNonSeatedJourney) {
      const filterfn = (prod) => isAddonProduct(prod);
      const isAddOns = products.filter(filterfn).length > 0;
      if (!isAddOns && products.length > 0) productList = deepCopyArrayOfObjects(productList.filter(filterfn));
    }

    if (products && products.length > 0) {
      const data = this._getUpdatedProductList([...productList], products, maxQuantity, remainingQuantity);
      if ((data.invalidQuantity || data.updatedRemQuantity < 0) && !checkIfParksTenants([], [UIConfig.tenants.ppad])) {
        const errorCode = data.invalidQuantity
          ? UIConfig.errorCodes.invalidQuantity
          : UIConfig.errorCodes.invalidCartQuantity;
        return new Promise((resolve, reject) => {
          const response = this._getCustomError({
            errorCode: errorCode,
            errorText: 'quantity exceeded',
          });
          response.error.updatedProductList = data.updatedProductList;
          reject(response);
        });
      } else {
        return this._updatePersistentCart(data.updatedProductList, this._extraCartParams);
      }
    } else {
      return this._updatePersistentCart(productList, this._extraCartParams);
    }
  };

  /**
   * Wrapper on method _getUpdatedProductList
   * Current usage in YAS ARENA to get updated Product List with different maxQty on updation
   */
  getUpdatedProductList = (productList, products, maxQty, remainingQuantity = 99) => {
    return this._getUpdatedProductList(productList, products, maxQty, remainingQuantity);
  };

  /**
   * add product to list if it is new
   * update quantity if it exists
   */
  _getUpdatedProductList = (productList, products, maxQuantity, remainingQuantity) => {
    let updatedProductList = [...productList],
      invalidQuantity = false,
      data = null,
      updatedRemQuantity = remainingQuantity;

    products.forEach((prod) => {
      const otherReason = parseIfJsonString(prod.otherReason);
      const updateMaxQuantity = otherReason.maxGrouppedQuantity || maxQuantity;
      const pplPerTicket = otherReason.pplPerTicket || 1;
      const updateMinQuantity = otherReason.minGrouppedQuantity || 1;
      const isPackagedProduct = prod.hasOwnProperty('packageGroupCode') && prod.packageGroupCode;

      if (!isPackagedProduct) {
        if (
          this._isExistingProduct(
            updatedProductList,
            prod.productId,
            prod.productIdType,
            prod.sector,
            prod.isPostPurchasedAddon,
          )
        ) {
          data = this._updateProdList(
            updatedProductList,
            prod,
            prod.currQuantity,
            updateMaxQuantity,
            updatedRemQuantity,
            updateMinQuantity,
            pplPerTicket,
            products.length > 1,
          );
        } else {
          data = this._addToProdList(
            updatedProductList,
            prod,
            prod.currQuantity,
            updateMaxQuantity,
            updatedRemQuantity,
            updateMinQuantity,
            pplPerTicket,
            prod.isPostPurchasedAddon,
          );
        }
      } else {
        const { packageGroupCode, productId } = prod;
        if (this._isExistingPkgProduct(updatedProductList, packageGroupCode, productId)) {
          data = this._updatePkgProdList(updatedProductList, prod, packageGroupCode);
        } else {
          data = this._addToProdList(
            updatedProductList,
            prod,
            prod.currQuantity,
            updateMaxQuantity,
            updatedRemQuantity,
            updateMinQuantity,
            pplPerTicket,
            prod.isPostPurchasedAddon,
          );
        }
      }

      updatedProductList = data.updatedProductList;
      invalidQuantity = data.invalidQuantity;
      updatedRemQuantity = data.remainingQuantity;
    });

    if (this._isYaNonSeatedJourney) updatedProductList = yaSortedNonSeatedProducts(updatedProductList);

    return {
      updatedProductList,
      invalidQuantity,
      updatedRemQuantity,
    };
  };

  _updatePkgProdList = (updatedProductList, prod, pckCode) => {
    let invalidQuantity = false;
    const isExistingPrds = updatedProductList.filter((prd) => prd.packageGroupCode === pckCode);
    const otherPrds = updatedProductList.filter((prd) => prd.packageGroupCode !== pckCode);
    const updatedPkgPrd = isExistingPrds.map((pkg) => {
      if (prod.productId === pkg.productId) {
        pkg.packageQty += prod.packageQty;
      }
      pkg.invalidQuantity = pkg.packageQty > Number(pkg.packageMax) || pkg.packageQty < Number(pkg.packageMin);
      if (!invalidQuantity) {
        invalidQuantity = pkg.invalidQuantity || false;
      }
      return pkg;
    });

    return {
      updatedProductList: [...(otherPrds?.length ? otherPrds : []), ...updatedPkgPrd],
      invalidQuantity: invalidQuantity,
      remainingQuantity: 10,
    };
  };

  /*
         udpate quantiity if product exists in product list
     */
  _updateProdList = (
    productList,
    prod,
    quantity,
    maxQuantity,
    remainingQuantity,
    minQuantity = 1,
    pplPerTicket,
    isExistingPrd,
  ) => {
    let invalidQuantity = false;
    productList.map((item) => {
      const factor = prod.classType === 'FAM' || prod.classType === 'FAMN' ? pplPerTicket : 1;
      if (prod.productIdType === item.productIdType && (!prod.sector || prod.sector === item.sector)) {
        const groupQuantity = item.groupedQuantity + quantity * factor;
        const totalQuantity = Number(quantity) + Number(item.quantity);
        const singleProductGross = Number(item.price.gross) / Number(item.quantity);
        if (!checkIfParksTenants([], [UIConfig.tenants.ppad])) {
          item.invalidQuantity = groupQuantity > maxQuantity || groupQuantity < minQuantity;
          if (!item.invalidQuantity && item.groupType === UIConfig.b2c.purchaseJourney.additionalProductCode) {
            item.invalidQuantity = item.quantity > item.otherReason;
          }
        }
        item.groupedQuantity = groupQuantity;
        if (item.hasOwnProperty('maxAvailableTickets') && prod.hasOwnProperty('maxAvailableTickets')) {
          item.maxAvailableTickets = prod.maxAvailableTickets;
        }

        if (prod.productId === item.productId) {
          item.price.gross = singleProductGross * totalQuantity;
          item.price.net = item.price.gross;
          item.quantity = totalQuantity;
          remainingQuantity -= quantity;
          if (prod.groupType === UIConfig.b2c.purchaseJourney.additionalProductCode) {
            item.invalidQuantity = totalQuantity > prod.otherReason;
          }
        }
      }
      if (
        item.isIncreaseDecreaseEnabled &&
        item.fromDate === prod.fromDate &&
        item.toDate === prod.toDate &&
        item.productId === prod.relatedProduct &&
        item.productType === prod.productType &&
        item.tab === prod.tab &&
        !isExistingPrd
      ) {
        const counter = Math.sign(quantity) === 1 ? Number(item.increaseDecreaseBy) : -Number(item.increaseDecreaseBy);
        const qty = Number(item.quantity) + Number(counter);
        const totquantity = qty >= item.minCount && qty <= item.maxCount ? qty : item.quantity;
        const groupQuantity = item.groupedQuantity + Number(counter) * factor;
        item.price.gross = (Number(item.price.gross) / Number(item.quantity)) * totquantity;
        item.price.net = item.price.gross;
        item.quantity = totquantity;
        item.groupedQuantity = groupQuantity;
        if (!checkIfParksTenants([], [UIConfig.tenants.ppad])) {
          item.invalidQuantity = groupQuantity > maxQuantity || groupQuantity < minQuantity;
        }
      }
      if (checkIfParksTenants([], [UIConfig.tenants.ppad])) {
        const tempInvalidQuantity = this._checkForInvalidProduct(
          item,
          prod,
          maxQuantity,
          minQuantity,
          invalidQuantity,
          true,
        );
        if (!invalidQuantity && tempInvalidQuantity) {
          invalidQuantity = tempInvalidQuantity;
        }
        item.invalidQuantity = item.productIdType === prod.productIdType ? tempInvalidQuantity : item.invalidQuantity;
      } else if (!invalidQuantity) {
        invalidQuantity = item.invalidQuantity || false;
      }
      return item;
    });
    return {
      updatedProductList: productList,
      invalidQuantity: invalidQuantity,
      remainingQuantity: remainingQuantity,
    };
  };

  // currrently implemented for Parks(QAW, SWAD, FWAD, WBW, YWW)
  _checkForInvalidProduct = (item, prod, maxQuantity, minQuantity, invalidQuantity, isUpdate) => {
    // invalid Quantity for Parks checked for individual, grouped and
    // attached Product in group (If one is invalid error must be there)
    let groupedInvalidity,
      individualInvalidity,
      individualCurrentValidity,
      attachedProdInvalidity = false;
    if (!isUpdate) {
      // grouped check: Total quantity of both the product should be a valid quantity
      groupedInvalidity = prod.groupedQuantity > maxQuantity || prod.groupedQuantity < minQuantity;
      // individual product check: Individual product in the group/single must be having valid quantity set in CMS
      individualInvalidity = prod.quantity > prod.maxCount || prod.quantity < prod.minCount;
      // current product invalidity check: checking invalidity of current product in the loop
      individualCurrentValidity = item.quantity > item.maxCount || item.quantity < item.minCount;
      // related product check: product in the group will be valid if either of the product is valid
      attachedProdInvalidity = item.productIdType === prod.productIdType ? individualCurrentValidity : invalidQuantity;
    } else {
      // grouped check: Total quantity of both the product should be a valid quantity
      groupedInvalidity = item.groupedQuantity > maxQuantity || item.groupedQuantity < minQuantity;
      // individual product check: Individual product in the group/single must be having valid quantity set in CMS
      individualInvalidity =
        item.productId === prod.productId &&
        // Only the same day product with invalid quantity will be invalid in this scenario
        item.fromDate === prod.fromDate &&
        (item.quantity > prod.maxCount || item.quantity < prod.minCount);
      // current product invalidity check: checking invalidity of current product in the loop
      individualCurrentValidity = item.quantity > item.maxCount || item.quantity < item.minCount;
      // related product check: product in the group will be valid if either of the product is valid
      attachedProdInvalidity = item.productIdType === prod.productIdType ? individualCurrentValidity : invalidQuantity;
    }
    return groupedInvalidity || individualInvalidity || attachedProdInvalidity;
  };

  /**
   * push to product list if group quantity does not exceeds the max count
   */

  _addToProdList = (
    productList,
    prod,
    quantity,
    maxQuantity,
    remainingQuantity,
    minQuantity = 1,
    pplPerTicket,
    postAddOn = false,
  ) => {
    let invalidQuantity = false,
      groupNotFound = true;
    const { gross } = prod.price;
    productList.map((item) => {
      if (
        postAddOn &&
        prod.productIdType === item.productIdType &&
        item.isPostPurchasedAddon &&
        (!prod.sector || prod.sector === item.sector)
      ) {
        let factor = prod.classType === 'FAM' || prod.classType === 'FAMN' ? pplPerTicket : 1;
        const groupQuantity = item.groupedQuantity + quantity * factor;
        let grossPrice = 0;
        grossPrice = Number(prod.price.gross) * Number(quantity);
        groupNotFound = false;
        if (!checkIfParksTenants([], [UIConfig.tenants.ppad])) {
          item.invalidQuantity = prod.invalidQuantity = groupQuantity > maxQuantity || groupQuantity < minQuantity;
        }
        item.groupedQuantity = groupQuantity;
        prod.groupedQuantity = groupQuantity;
        prod.quantity = quantity;
        prod.price.gross = grossPrice;
        prod.price.net = grossPrice;
        remainingQuantity -= quantity;
      }
      if (
        !postAddOn &&
        prod.productIdType === item.productIdType &&
        !prod.isPostPurchasedAddon &&
        (!prod.sector || prod.sector === item.sector)
      ) {
        let factor = prod.classType === 'FAM' || prod.classType === 'FAMN' ? pplPerTicket : 1;
        const groupQuantity = item.groupedQuantity + quantity * factor;
        let grossPrice = 0;
        if (this._isYaNonSeatedJourney) {
          grossPrice = Number(gross) * Number(quantity);
        } else {
          grossPrice = Number(prod.price.gross) * Number(quantity);
        }

        groupNotFound = false;
        if (!checkIfParksTenants([], [UIConfig.tenants.ppad])) {
          item.invalidQuantity = prod.invalidQuantity = groupQuantity > maxQuantity || groupQuantity < minQuantity;
        }
        item.groupedQuantity = groupQuantity;
        prod.groupedQuantity = groupQuantity;
        prod.quantity = quantity;
        prod.price.gross = grossPrice;
        prod.price.net = grossPrice;
        remainingQuantity -= quantity;
      }
      if (!invalidQuantity && checkIfParksTenants([], [UIConfig.tenants.ppad])) {
        invalidQuantity = this._checkForInvalidProduct(item, prod, maxQuantity, minQuantity, invalidQuantity, false);
        prod.invalidQuantity = invalidQuantity;
      } else if (!invalidQuantity) {
        invalidQuantity = prod.invalidQuantity || false;
      }
      return item;
    });
    if (groupNotFound) {
      let factor = prod.classType === 'FAM' || prod.classType === 'FAMN' ? pplPerTicket : 1;
      prod.groupedQuantity = quantity * factor;
      prod.invalidQuantity = prod.groupedQuantity > maxQuantity || prod.groupedQuantity < minQuantity;
      prod.quantity = quantity;
      prod.price.gross = Number(prod.price.gross) * Number(quantity);
      prod.price.net = prod.price.gross;
      remainingQuantity -= quantity;
      if (!invalidQuantity) {
        invalidQuantity = prod.invalidQuantity;
      }
    }
    productList.push(prod);

    return {
      updatedProductList: productList,
      invalidQuantity: invalidQuantity,
      remainingQuantity: remainingQuantity,
    };
  };

  _isExistingPkgProduct = (productList, prodGrpCode, productId) => {
    const isPresent = productList.some((prd) => prd.packageGroupCode === prodGrpCode && prd.productId === productId);
    return isPresent;
  };

  /**
   * check if product exist or not
   */
  _isExistingProduct = (productList, prodId, productIdType, prodSector = '', postAddOn) => {
    const productLength = productList.length;
    for (let index = 0; index < productLength; index++) {
      const prod = productList[index];
      if (
        prod.isPostPurchasedAddon &&
        prod.productId === prodId &&
        prod.productIdType === productIdType &&
        (!prodSector || prod.sector === prodSector)
      ) {
        return true;
      }
      if (
        !postAddOn &&
        (!prod.hasOwnProperty('packageGroupCode') || !prod.packageGroupCode) &&
        prod.productId === prodId &&
        prod.productIdType === productIdType &&
        (!prodSector || prod.sector === prodSector)
      ) {
        return true;
      }
    }
    return false;
  };

  getUngroupedPkgPrd = (packages) => {
    const unGroupPkg = this._ungroupPackageProds(packages);
    const uniqueItems = unGroupPkg.reduce((result, pkg) => {
      const category = pkg.items?.[0].packageGroupCode;
      if (!result[category]) {
        const prods = pkg.items.map((prd) => ({ ...prd, packageQty: prd.packageQty || 1 }));
        result[category] = { ...pkg, items: prods };
      } else {
        const prods = pkg.items.map((prd) => ({ ...prd }));
        result[category].items = prods;
      }
      return result;
    }, {});
    const uniqueItemList = Object.values(uniqueItems).map((uniqueItem) => uniqueItem);
    const allPkgProd = [];
    uniqueItemList.forEach((pkg) => {
      allPkgProd.push(...pkg.items);
    });
    return allPkgProd;
  };

  _ungroupPackageProds = (packagePrd) => {
    const isLoggedIn = getLoggedInUser()?.idToken;
    const groupedProducts = {};
    packagePrd.length &&
      packagePrd.forEach((product) => {
        const pkgGroupCode = product.items[0].packageGroupCode;
        if (groupedProducts[pkgGroupCode]) {
          if (isLoggedIn) {
            const pckgs = groupedProducts[pkgGroupCode];
            const { items } = pckgs[0];
            if (groupedProducts[pkgGroupCode]?.length) {
              const newItems = items?.length && items.map((itm) => ({ ...itm, packageQty: itm.packageQty + 1 }));
              groupedProducts[pkgGroupCode][0] = { ...pckgs[0], items: newItems };
            }
          }
          return null;
        }
        if (!groupedProducts[pkgGroupCode]) {
          groupedProducts[pkgGroupCode] = [];
        }
        const newItems =
          product?.items?.length && product.items.map((itm) => ({ ...itm, packageQty: itm.packageQty || 1 }));
        groupedProducts[pkgGroupCode].push({ ...product, items: newItems });
      });
    const prodArr = [];
    Object.keys(groupedProducts).forEach((key) => {
      prodArr.push(groupedProducts[key][0]);
    });
    return prodArr;
  };

  _groupPackageProds = (items) => {
    const packagePrd = items.filter((prd) => prd.hasOwnProperty('packageGroupCode') && prd.packageGroupCode !== null);
    const groupedProducts = packagePrd.reduce((result, product) => {
      const pkgGroupCode = product.packageGroupCode;
      if (!result[pkgGroupCode]) {
        result[pkgGroupCode] = [];
      }
      result[pkgGroupCode].push(product);
      return result;
    }, {});
    const prodArr = [];
    Object.keys(groupedProducts).forEach((key) => {
      for (let i = 0; i < groupedProducts[key][0].packageQty; i++) {
        prodArr.push({
          packageCode: key.split('-')[0],
          items: groupedProducts[key],
        });
      }
    });
    return {
      packages: prodArr,
      items: items.filter(
        (prd) =>
          !prd.hasOwnProperty('packageGroupCode') ||
          (prd.hasOwnProperty('packageGroupCode') && prd.packageGroupCode === null),
      ),
    };
  };

  /*
         update cart in local store or api
         based on users logged in state
     */

  _updatePersistentCart = (productList, extraCartParams = {}) => {
    const checkIsTicketUpgradeCart = extraCartParams?.isTicketUpgradeCart || false;
    return new Promise((resolve, reject) => {
      let cart = this.getCustomCart(productList);

      const tenantId = getLoggedInUser().tenantID || JSON.parse(localStorage.mainObj).tenantID;
      const data = {
        productList: [],
        cartResponse: null,
      };
      if (tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
        const isF1SeatedInCart = isEventTypeF1InCart(cart.cart.items, true);
        if (!isF1SeatedInCart && canUseDOM()) {
          window.PubSub.publish('stopCartTimer');
        }
        const groupedPacked = this._groupPackageProds(cart?.cart.items);
        const applicablePrmo = this.updateSelectedPromos(cart.cart);
        cart.cart = { ...cart.cart, promotions: applicablePrmo, ...groupedPacked };
      }
      //check for login and update local store or api accordingly
      if (this._getUser() && !checkIsTicketUpgradeCart) {
        this._updateLocalCartToAPI(cart, productList)
          .then((response) => {
            this._updateHeaderCartIconDOM(cart);
            resolve(response);
            this.hideAddressSection(productList);
          })
          .catch((response) => {
            reject(response);
          });
      } else {
        try {
          if (localStorage.getItem('guestUser')) {
            cart.cart.reservationOwner = JSON.parse(localStorage.getItem('guestUser'));
          }
          if (isYasArenaJourney()) {
            cart && cart.cart && cart.cart.items && cart.cart.items.length && this._setLocalCart(cart);
          } else {
            this._setLocalCart(cart);
          }
          data.productList = productList;
          data.cartResponse = { message: 'successs' };
          this._updateHeaderCartIconDOM(cart);
          resolve(data);
          this.hideAddressSection(productList);
        } catch (response) {
          data.cartResponse = this._getCustomError({
            errorCode: UIConfig.errorCodes.updateFail,
            errorText: 'failed updating local store',
          });

          reject({ ...data });
        }
      }
    });
  };

  _updateHeaderCartIconDOM = (cart) => {
    if (canUseDOM()) {
      window.PubSub.publish(UIConfig.events.UPDATE_HEADER_CART, cart.cart);
    }
  };

  _updateLocalCartToAPI = (cart, productList) => {
    return new Promise((resolve, reject) => {
      const data = {
        productList: [],
        cartResponse: null,
      };
      CartService.updateCart(cart, this._moduleName, this._serviceUrls.updateCart.url)
        .then((response) => {
          Logging(response, this._moduleName, true, 'Cart Updated');
          data.productList = productList;
          data.cartResponse = response;
          resolve({ ...data });
        })
        .catch((response) => {
          data.response = response;
          reject({ ...data });
        });
    });
  };
  clearYACart = ({ mode = UIConfig.b2c.purchaseJourney.yasArenaCart.overlayModes.remove, productList = [] }) => {
    const clearCart = () => {
      return new Promise((resolve, reject) => {
        this._deletePersistentCart([]).catch((response) => {
          ServiceConfig.config.cart = {}; // clear cache
          this._yaJourneyActions.onCartAndChange(mode, productList);
          YALocalStorageSvc.removeSelectedPerformanceDetails();
          if (isYasArenaJourney()) {
            const eventDetails = {};
            YALocalStorageSvc.getSetEventDetails(eventDetails);
          }
          resolve({ message: 'success' });
        });
      });
    };

    if (this._isYaNonSeatedJourney) {
      return clearCart();
    } else {
      return CartService.deleteYAEvent(
        'deleteYAEvent',
        this._serviceUrls.changeDeleteYaCart.url,
        YALocalStorageSvc.getSetOrderId(),
      ).then((response) => {
        if (isYasArenaJourney()) {
          const eventDetails = {};
          YALocalStorageSvc.getSetEventDetails(eventDetails);
        }
        return clearCart();
      });
    }
  };

  deleteCart = (productList, product, maxQuantity) => helper.deleteCart(this, productList, product, maxQuantity);

  _deleteFromProductList = (productList, product, maxQuantity, minQuantity = 1) => {
    const firstProd = product?.items?.[0];
    const packageCode =
      firstProd?.hasOwnProperty('packageGroupCode') && firstProd.packageGroupCode && product.items[0].packageGroupCode;
    const indexes = [];
    const otherReason = parseIfJsonString(product.otherReason);
    const updateMaxQuantity = otherReason.maxGrouppedQuantity || maxQuantity;
    const updateMinQuantity = otherReason.minGrouppedQuantity || 1;
    const tenantId = getLoggedInUser().tenantID || JSON.parse(localStorage.mainObj).tenantID;
    let invalidQuantity = false;
    for (let i = 0; i < productList.length; i++) {
      if (packageCode) {
        if (productList[i].packageGroupCode === packageCode) {
          indexes.push(i);
        }
      }
      if (
        product.productIdType === productList[i].productIdType &&
        (!product.sector || product.sector === productList[i].sector)
      ) {
        productList[i].groupedQuantity -= product.quantity;
        productList[i].invalidQuantity =
          productList[i].groupedQuantity > updateMaxQuantity ||
          (productList[i].groupedQuantity > 0 && productList[i].groupedQuantity < updateMinQuantity);
        if (
          !invalidQuantity &&
          checkIfParksTenants([], [UIConfig.tenants.ppad]) &&
          productList[i].productId !== product.productId
        ) {
          invalidQuantity = this._checkForInvalidProduct(
            productList[i],
            product,
            maxQuantity,
            minQuantity,
            invalidQuantity,
            true,
          );
        } else if (!invalidQuantity) {
          invalidQuantity = productList[i].invalidQuantity;
        }
        if (
          productList[i].groupType === UIConfig.b2c.purchaseJourney.additionalProductCode ||
          product.productId === productList[i].productId ||
          (product.relatedProduct &&
            product.relatedProduct === productList[i].productId &&
            tenantId.toLowerCase() !== UIConfig.ymcB2CTenant)
        ) {
          indexes.push(i);
        }
      }
    }
    if (indexes.length === 0) {
      return false;
    } else {
      let deletedProdCount = 0;
      for (let i = 0; i < indexes.length; i++) {
        productList.splice(indexes[i] - deletedProdCount, 1);
        deletedProdCount++;
      }
      return {
        updatedProductList: productList,
        invalidQuantity: invalidQuantity,
      };
    }
  };

  hideAddressSection = (productList) => helper.hideAddressSection(this, productList);

  /*
         delete or clear cart based on products length
     */
  _deletePersistentCart = (productList) => {
    if (productList.length > 0) {
      return this._updatePersistentCart(productList);
    } else {
      return new Promise((resolve, reject) => {
        this._clearCart()
          .then(() => {
            const customResponse = this._getCustomError({
              errorCode: UIConfig.errorCodes.emptyCart,
              errorText: 'no cart in local store',
            });
            this._updateHeaderCartIconDOM({ cart: { totalQuantity: 0 } });
            reject(customResponse);
            const tenantId = getLoggedInUser().tenantID || JSON.parse(localStorage.mainObj).tenantID;
            if (tenantId.toLowerCase() === UIConfig.ymcB2CTenant || isYasArenaJourney()) {
              window.PubSub.publish('stopCartTimer');
            }
            if (isYasArenaJourney()) {
              const eventDetails = {};
              YALocalStorageSvc.getSetEventDetails(eventDetails);
            }
            this.hideAddressSection(productList);
          })
          .catch((response) => {
            reject(response);
          });
      });
    }
  };

  /*
         delete fro local store or cart API based on users logged in state
     */
  _clearCart = () => {
    if (this._getUser()) {
      return CartService.deleteCart(this._moduleName, this._serviceUrls.deleteCart.url);
    } else {
      return this._deleteCartFromStore();
    }
  };

  /*
         delete cart from local store
     */
  _deleteCartFromStore = () => {
    return new Promise((resolve, reject) => {
      try {
        const cart = JSON.parse(localStorage.getItem(this._anonymousCartKey)),
          cartStoredInSession = JSON.parse(sessionStorage.getItem(this._cartDataKey)),
          promoCode = localStorage.getItem(this._promoCodeKey),
          partnerSegmentPromo = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo),
          applyPartnerSegmentPromo = getLocalStorageByKey(
            UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo,
          );

        if (cart) {
          localStorage.removeItem(this._anonymousCartKey); //Remove cart from local storage
          localStorage.removeItem('yasIdUserData');
          if (isYasArenaJourney()) {
            const eventDetails = {};
            YALocalStorageSvc.getSetEventDetails(eventDetails);
          }
        }

        cartStoredInSession && sessionStorage.removeItem(this._cartDataKey); //Remove cart from session storage
        promoCode && localStorage.removeItem(this._promoCodeKey); //Remove Promo Code from local storage
        partnerSegmentPromo && localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo);
        applyPartnerSegmentPromo &&
          localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo);

        if (isYasArenaJourney()) {
          const eventDetails = {};
          YALocalStorageSvc.getSetEventDetails(eventDetails);
        }
        resolve(true);
      } catch (e) {
        reject(false);
      }
    });
  };

  _deleteLocalCartIfExpired = (cart) => {
    const expiryHours = Number(getMainObject().cartExpiry || '48') * 60 * 60 * 1000;
    if (cart.updatedAt + expiryHours <= Date.now()) {
      localStorage.removeItem(this._anonymousCartKey);
      localStorage.removeItem('yasIdUserData');
      localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo);
      localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo);
      return null;
    }
    return cart;
  };

  _setLocalCart = (cart) => {
    cart.updatedAt = Date.now();
    let localCartData = getAnonymousCart();
    cart.cart.reservationOwner =
      localCartData && localCartData.cart.reservationOwner !== null ? localCartData.cart.reservationOwner : null;
    localStorage.setItem(this._anonymousCartKey, JSON.stringify(cart));
  };

  groupCartProducts = (productList, key) => helper.groupCartProducts(this, productList, key);

  /*
         get users logged in state from local store
     */
  _getUser = () => {
    if (this._loggedInUserInfo) {
      return this._loggedInUserInfo;
    } else {
      const userInfo = getLoggedInUser();
      if (userInfo && userInfo.idToken) {
        this._loggedInUserInfo = userInfo;
      } else {
        this._loggedInUserInfo = false;
      }
      return this._loggedInUserInfo;
    }
  };

  showClassType = (data, classMap) => helper.showClassType(this, data, classMap);

  releaseLockOrderService = () => {
    return new Promise((resolve, reject) => {
      try {
        const isPaymentPageError = getParameterByName('payment_error');
        if (
          (isMatchTenant(UIConfig.tenants.fwad) ||
            isMatchTenant(UIConfig.tenants.swadb2c) ||
            isMatchTenant(UIConfig.tenants.ppad)) &&
          isPaymentPageError?.length
        ) {
          const tenantId = getTenantId().toUpperCase();
          const reqObject = {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              tenantid: tenantId,
            },
          };
          addLoaderOverlay();
          let apiUrl = this?._serviceUrls?.deleteOrder?.url;
          const orderId = localStorage.getItem('orderId');
          const abortApiUrl = `${apiUrl}${orderId}?tenantid=${tenantId}`;
          fetch(abortApiUrl, reqObject)
            .then((response) => response.json())
            .then((data) => {
              removeLoaderOverlay();
              resolve();
            })
            .catch((error) => {
              removeLoaderOverlay();
              resolve();
            });
        } else {
          removeLoaderOverlay();
          resolve(); // resolve for any other tenant so that get cart api doesn't get blocked
        }
      } catch (error) {
        console.log('error in releaseLock Api:', error);
        removeLoaderOverlay();
        resolve(); // resolving the promise in every case to continue the existing functionality
      }
    });
  };
}

export default CartActions;
