import React from 'react';
import moment from 'moment';

import UIConfig from '../../../../common/UIConfig';
import { toTwoDecimalPlaces } from '../../../../common/utility';

import { DynamicContent } from '../../base';
import { logComponentRenderingError } from '../../../../common/logger';

const CartSummary = ({ products, heading, currency, cartLabels, isGeneral, isAnnualPass }) => {
  try {
    let subTotal = 0;
    return (
      <div className="c-gridview-summary-details">
        <DynamicContent tagName="h3" innerHtml={heading} attrs={{ className: 'c-gridview-summary-details--heading' }} />
        <div className="c-gridview-summary-details--content">
          {products.map((prod) => {
            subTotal += prod.price.gross;
            return (
              <div className="c-gridview-summary-details--wrapper" key={prod.productId + prod.productIdType}>
                <div className="c-gridview-summary-details--content-product-title">
                  <DynamicContent tagName="h4" attrs={{ className: '' }} innerHtml={prod.productName} />
                  {isGeneral(prod.itemType) && prod.isFlexible ? (
                    <span className="ticket-validity">
                      <DynamicContent
                        tagName="p"
                        attrs={{ className: 'date-label' }}
                        innerHtml={`${cartLabels.flexible} ${cartLabels.validUntil}`}
                      />
                      <p className="date-value">
                        {moment(prod.toDate).format(UIConfig.b2c.purchaseJourney.dateFormat)}
                      </p>
                    </span>
                  ) : (
                    prod.fromDate &&
                    !isAnnualPass(prod.itemType) && (
                      <span className="ticket-validity">
                        <DynamicContent
                          tagName="p"
                          attrs={{ className: 'date-label' }}
                          innerHtml={`${cartLabels.validOn}`}
                        />
                        <p className="date-value">
                          {moment(prod.fromDate).format(UIConfig.b2c.purchaseJourney.dateFormat)}
                        </p>
                        {prod.timeSlot && !prod.isDynamicPricing && (
                          <span className="time-slot">
                            <DynamicContent
                              tagName="p"
                              attrs={{ className: 'date-label' }}
                              innerHtml={`${cartLabels.timeSlot}`}
                            />
                            <p className="date-value">{prod.timeSlot}</p>
                          </span>
                        )}
                      </span>
                    )
                  )}
                </div>
                <div className="c-gridview-summary-details--content-product-details">
                  <DynamicContent
                    tagName="p"
                    attrs={{ className: 'price' }}
                    innerHtml={`${currency} ${toTwoDecimalPlaces(
                      prod.price.gross / prod.quantity,
                    )}${cartLabels.perPerson || ''}`}
                  />
                  <div className="ticket-holder-price-wrapper">
                    <div className="ticket-holder">{`${prod.quantity || 1} ${prod.classTypeName || ''}`}</div>
                    <div className="gross">{`${currency} ${toTwoDecimalPlaces(prod.price.gross)}`}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="c-gridview-summary-details--total">
          <p>
            <DynamicContent tagName="h4" innerHtml={cartLabels.net} />
          </p>
          <p className="gross">{`${currency} ${toTwoDecimalPlaces(subTotal)}`}</p>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CartSummary');
  }
};

export default CartSummary;
