import React from 'react';
import Form from '../../../form/form-component';
import PayfortInput from '../../payfort/payfort-input-fields';
import { isMatchTenant } from '../../../../../common/utility';
import UIConfig from '../../../../../common/UIConfig';
import { Image } from '../../../../presentation/base/';

const PaymentFormComponent = (props) => {
  return (
    <div className="payment-form-wrapper">
      <PayfortInput
        {...props}
        data={props.propsData}
        signature={props.signature}
        generatedMerchantReference={props.generatedMerchantReference}
        lang={props.lang}
      />
      <input type="hidden" name="card_number" value="" id="cardNumberForm" />
      <input type="hidden" name="card_security_code" value="" id="cvvForm" />
      <input type="hidden" name="expiry_date" value="" id="expiryForm" />
      {props.isMoneyCard &&
        props.cardImageData &&
        props.cardImageData.cardDetailImage &&
        isMatchTenant(UIConfig.ymcB2CTenant) && (
          <div className="card-detail-image-payfort-ymc">
            <Image noRendition image={props.cardImageData.cardDetailImage} />
          </div>
        )}
      <Form
        payFortData={props.data}
        isNewForm={props.isNewForm}
        {...props}
        className="payment-form"
        handleCustomValidation={props.handleCustomValidation}
        handleValidityValidation={props.handleValidityValidation}
        method="POST"
        name="payment"
        id="paymentForm"
        autoComplete="off"
        isTabIndex={props.isTabIndex}
      />
    </div>
  );
};

export default PaymentFormComponent;
