/* eslint-disable default-case */
import React, { useEffect, useState, useRef } from 'react';
import { canUseDOM, resolvePath, getLocalStorageByKey } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { initializePointsPay } from '../payment/pointspay/pointspay-index';
import { initializeAliPay } from './alipay-index';
import { initializePayfort } from './payfort-index';
import { initializeGPay } from './gpay-index';
import { logComponentRenderingError } from '../../../common/logger';
import { initializePostPay } from '../payment/postpay/postpay-index';
import { Helmet } from 'react-helmet';

const PaymentHandler = (props) => {
  const method = resolvePath(props, 'data.paymentMethod', null);
  const paymentMethod = method && method.toLowerCase();

  useEffect(() => {
    if (canUseDOM()) {
      switch (paymentMethod) {
        case UIConfig.paymentMethod.pointsPay:
          initializePointsPay(props);
          break;
        case UIConfig.paymentMethod.aliPay:
          initializeAliPay(props);
          break;
        case UIConfig.paymentMethod.payFort:
          initializePayfort(props);
          break;
        case UIConfig.paymentMethod.tabby:
          initializePayfort(props);
          break;
        case UIConfig.paymentMethod.gPay:
          initializeGPay(props);
          break;
        case UIConfig.paymentMethod.postpay:
          initializePostPay(props);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNativeAppLoader = () => {
    const paymentData = getLocalStorageByKey(UIConfig.localStoreKeys.payment.paymentInfo);
    if (resolvePath(paymentData, 'isFrictionlessPayfort', false)) {
      return (
        <>
          <div />
          <div />
          <div />
        </>
      );
    }
    return '';
  };
  try {
    const paymentMessage =
      paymentMethod && resolvePath(getLocalStorageByKey('paymentProcessingMessages'), `${paymentMethod}`, '');
    return (
      <div className="payment-handler loader-ele-relative">
        <Helmet>
          <meta name="robots" content="nofollow" />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div class="w--content">
          <div class="wrapper">
            <div>
              {paymentMessage && <h3 class="message">{paymentMessage}</h3>}
              <div class="loader">{getNativeAppLoader()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, `PaymentHandler-${paymentMethod}`);
  }
};

export default PaymentHandler;
