import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { AnchorLink, DynamicContent, ErrorSummary } from '../../presentation/base';
import { canUseDOM, getLoggedInUser, isEmpty, isLoggedInUser } from '../../../common/utility';

import AddToCalendar from '../../presentation/add-to-calendar';
import EditorialText from '../editorial-text';
import HeroBanner from '../../presentation/hero-banner-component';
import HeroCard from '../../presentation/hero-card-component';
import MediaCarouselWrapper from '../media-carousel-wrapper';
import { secProds } from '../b2c-purchase-journey/add-ons/add-ons-utility.js';
import ThirdPartyWidget from '../../presentation/third-party-widget';
import UIConfig from '../../../common/UIConfig';

export const parseCarouselData = (data, heroComponent) => {
  const {
    actionLink,
    aspectRatio,
    body,
    imageInfo,
    textAlignment,
    title,
    variant,
    videoDescription,
    videoInfo,
    videoTitle,
    quickLinks,
    changeContentTitleToH1,
  } = data;

  return {
    aspectRatio,
    carousel: UIConfig.heroCarouselSetting,
    componentTitle: '',
    mediaItems: [
      {
        actionLink,
        bodyCopy: videoDescription || body,
        imageInfo,
        imageTitle: videoTitle || title,
        mediaType: 'video',
        videoInfo,
      },
    ],
    textAlignment,
    variant,
    heroComponent,
    quickLinks,
    changeContentTitleToH1,
  };
};

/**
 * extract discount code from discount map provided
 * @param {Number} days days in advance for which booking is done
 * @param {Object} discountMap map containg details of discount against the range of days
 */
export const getDiscount = (days, discountMap) => {
  for (let i = 0; i < discountMap.length - 1; i++) {
    if (days >= discountMap[i].days && days < discountMap[i + 1].days) {
      return discountMap[i];
    }
  }
  return discountMap[discountMap.length - 1];
};

/**
 * caculate "to" date of on the basis of product type and from date
 * @param {Object} date contains date reated data like flexible, from, range
 * @param {object} prod product for which to date calculated
 */
export const calculateToDate = (prod, mupValidityMap) => {
  const to = moment(prod.fromDate).clone();

  if (prod.validUntil) {
    return moment(prod.validUntil)
      .locale('en')
      .format(UIConfig.calendar.dateFormat);
  } else if (prod.isFlexible) {
    to.add(prod.rangeInMonths, 'month');
    to.subtract(1, 'days');
  } else if (prod.itemType === 'MUP' && mupValidityMap.hasOwnProperty(prod.accessPolicy)) {
    to.add(mupValidityMap[prod.accessPolicy] - 1, 'day');
  }
  return parseInt(prod.validUntilDate) ? prod.toDate : to.locale('en').format(UIConfig.calendar.dateFormat);
};

/**
 * currently returning performance id at 0th position as there is ony
 * 1 time slot
 */
export const getPerformanceId = (list, singleSlot) => {
  if (singleSlot && list && list.length) {
    return list[0].sellable && list[0].performanceId;
  }
};

export const getCategoryProds = (category, products) => {
  return products.filter((product) => {
    if (!product.category || !category) {
      return false;
    }
    return product.category.toString() === category.toString();
  });
};

export const updateSecProdArray = (prod, type, map) => {
  secProds[type] = secProds[type] || {};

  switch (prod.experienceCatgory) {
    case map.secondary:
      secProds[type].secondary = secProds[type].secondary || [];
      secProds[type].secondary.push(prod);
      break;
    case map.addOnGuest:
      secProds.addOnGuest = secProds.addOnGuest || [];
      secProds.addOnGuest.push(prod);
      break;
    case map.primary:
      secProds[type].primary = secProds[type].primary || [];
      secProds[type].primary.push(prod);
      break;
    default:
      break;
  }
};

export const renderHeroCard = (heroCards) => {
  if (!heroCards || !heroCards.length) {
    return null;
  }

  return <HeroCard cards={heroCards} />;
};

export const heroComponentOverlay = (popUp) => {
  if (!popUp) {
    return null;
  }

  const {
    eligibilityCopy,
    eligibilityLabel,
    specificationCopy,
    specificationLabel,
    termsConditionCopy,
    termsConditionLabel,
  } = popUp;

  return (
    <div class="overlay-sub-container">
      {termsConditionLabel && (
        <DynamicContent
          tagName="h2"
          innerHtml={termsConditionLabel}
          attrs={{
            className: 'overlay-sub-heading',
          }}
        />
      )}
      {termsConditionCopy && (
        <DynamicContent
          tagName="p"
          innerHtml={termsConditionCopy}
          attrs={{
            className: 'overlay-description',
          }}
        />
      )}
      {eligibilityLabel && (
        <DynamicContent
          tagName="h2"
          innerHtml={eligibilityLabel}
          attrs={{
            className: 'overlay-sub-heading',
          }}
        />
      )}
      {eligibilityCopy && (
        <DynamicContent
          tagName="p"
          innerHtml={eligibilityCopy}
          attrs={{
            className: 'overlay-description',
          }}
        />
      )}
      {specificationLabel && (
        <DynamicContent
          tagName="h2"
          innerHtml={specificationLabel}
          attrs={{
            className: 'overlay-sub-heading',
          }}
        />
      )}
      {specificationCopy && (
        <DynamicContent
          tagName="p"
          innerHtml={specificationCopy}
          attrs={{
            className: 'overlay-description',
          }}
        />
      )}
    </div>
  );
};

export const renderHeroBanner = (data, isMobile) => {
  const {
    backgroundColor,
    image,
    shortTitle,
    shortTitleHeading,
    textWidth,
    title,
    titleDescription,
    quickLinks,
    isProductSoldOut,
    isSoldOut,
    soldoutText,
    soldOutLabel,
  } = data;
  return (
    <HeroBanner
      title={{ text: title }}
      heroImage={image}
      shortTitle={shortTitle}
      shortTitleHeading={shortTitleHeading}
      titleDescription={titleDescription}
      bgColor={backgroundColor && !isMobile ? backgroundColor : null}
      textWidth={textWidth && !isMobile ? textWidth : ''}
      quickLinks={quickLinks}
      isMobile={isMobile}
      isSoldOut={isProductSoldOut || isSoldOut}
      soldOutLabel={soldoutText || soldOutLabel}
    />
  );
};

export const renderThirdPartyWidget = (
  bodyCopy,
  image,
  isLoginWishlist,
  thirdPartyWidget,
  title,
  wishlistAnalyticsData,
) => {
  return (
    <ThirdPartyWidget
      data={thirdPartyWidget}
      wishlistData={{
        title: title,
        desc: bodyCopy,
        image: image.desktopImage.src,
      }}
      wishlistAnalyticsData={wishlistAnalyticsData}
      isLoginWishList={isLoginWishlist}
    />
  );
};

export const loadEditorialText = (data) => {
  const {
    bodyCopy,
    bodyShortTitle,
    bodyShortTitleHeading,
    bodyTitle,
    bookNowLabel,
    date,
    eventBookingUrl,
    fromPriceLabel,
    fromPriceValue,
    isLimited,
    limitedLabel,
    shortTitleHeading,
    variant,
    changeContentTitleToH1,
    subTitle,
    underlinedText,
    bodyCopy2,
    primaryCTA,
    extraClass = '',
    showSuccessIcon = false,
  } = data;

  return (
    <EditorialText
      data={{
        bodyCopy,
        bookNowLabel,
        date,
        limitedLabel,
        isLimited,
        fromPriceLabel,
        fromPriceValue,
        eventBookingUrl,
        shortTitle: bodyShortTitle,
        shortTitleHeading: bodyShortTitleHeading,
        title: {
          text: bodyTitle,
          heading: variant === UIConfig.heroWithAttributes ? shortTitleHeading : '',
        },
        variant,
        changeContentTitleToH1,
        subTitle,
        underlinedText,
        bodyCopy2,
        primaryCTA,
        extraClass,
        showSuccessIcon,
      }}
    />
  );
};

export const renderEditorialText = (data, heroComponent = '') => {
  const {
    addToCalendar,
    bodyCopy,
    bodyTitle,
    bodyShortTitle,
    date,
    descriptionDetail,
    disableReadMore,
    downloadPDF,
    heroCta,
    popUp,
    readMore,
    title,
    variant,
    underlinedText,
  } = data;

  const isTitle = bodyCopy || bodyTitle || bodyShortTitle || underlinedText;
  const isUserLoggedIn = isLoggedInUser();
  return (
    (date || isTitle) && (
      <div
        className={classNames('editorial-text', {
          'from-hero-component-container': heroComponent,
          'logged-out': !isUserLoggedIn,
        })}
      >
        {loadEditorialText(data)}
        {descriptionDetail && (
          <DynamicContent
            tagName="div"
            innerHtml={descriptionDetail}
            attrs={{ className: 'body-copy-1 ticket-details w--content' }}
          />
        )}
        {heroCta && (
          <div className="btn-primary hero-cta-button">
            <AnchorLink
              link={{
                href: heroCta.href,
                label: heroCta.label,
                target: heroCta.target,
              }}
              tabIndex="0"
            />
          </div>
        )}
        {(addToCalendar || downloadPDF) && (
          <AddToCalendar title={title} calendar={addToCalendar} downloadOffer={downloadPDF} variant={variant} />
        )}
        {!disableReadMore && popUp && (
          <div className="cta-ride">
            <AnchorLink
              className={variant === 'product-detail' ? 'CTA-button' : 'ride-info'}
              link={{
                href: 'javascript:void(0)', // eslint-disable-line
                label: readMore,
              }}
              onClick={() => openOverlay(data.popUp)}
            />
          </div>
        )}
      </div>
    )
  );
};

export const renderMediaCarouselWrapper = (data, heroComponent = '', isSmartHeroCarousel) => {
  if (isSmartHeroCarousel) {
    return <MediaCarouselWrapper data={data} isSmartHeroCarousel />;
  }
  return <MediaCarouselWrapper data={parseCarouselData(data, heroComponent)} addContrast={data.addContrast} />;
};

// overlay section: Using text with CTA component for overlay view
export const openOverlay = (popUp) => {
  window.PubSub.publish('toggleOverlayState', {
    shouldOpen: true,
    dataToAppend: heroComponentOverlay(popUp),
    customClass: 'ride-info-overlay',
  });
};

export const renderOverlayErrorMsg = (errorOverlayObj) => {
  if (!isEmpty(errorOverlayObj)) {
    return (
      <ErrorSummary
        data={{
          error: {
            code: errorOverlayObj.errorcode,
            text: errorOverlayObj.text,
          },
        }}
      />
    );
  }
};

export const renderBookNowButton = (data, renderPjPopup) => {
  const { bookNowLabel, isProductSoldOut, productId, soldoutText, isSoldOut, soldOutLabel } = data;

  if ((isProductSoldOut || isSoldOut) && (soldoutText || soldOutLabel)) {
    return (
      <button tabIndex="0" aria-label={soldoutText || soldOutLabel} className="btn-primary sold-out-cta disabled">
        {soldoutText || soldOutLabel}
      </button>
    );
  } else if (bookNowLabel && productId) {
    return (
      <button
        onClick={() => {
          renderPjPopup(productId);
        }}
        className="btn-primary"
      >
        {bookNowLabel}
      </button>
    );
  }
  return null;
};

export const renderBuyNowButton = (data) => {
  const { label, href } = data;

  return (
    <div className="action-line">
      <div className="btn-primary">
        <button
          type="button"
          onClick={() => {
            window.location = href;
          }}
        >
          {label}
        </button>
      </div>
    </div>
  );
};

export const renderPjPopupSegment = (data, isOverlayOpen, renderPjPopup, renderPJPopupData, showPjPopupSegment) => {
  const mainObj = canUseDOM() ? JSON.parse(window.localStorage.getItem('mainObj')) : {};
  const { daysLabel, priceLabel, priceValue, secondaryBuyLabel, secondaryProductId } = data;

  return (
    showPjPopupSegment && (
      <div className="pj-buy-now-button">
        <div className={`btn-primary-dark`}>
          {renderBookNowButton(data, renderPjPopup)}
          {secondaryBuyLabel && secondaryProductId && (
            <button
              onClick={() => {
                renderPjPopup(secondaryProductId, data);
              }}
              className="btn-secondary"
            >
              {secondaryBuyLabel}
            </button>
          )}
        </div>
        {isOverlayOpen && renderPJPopupData()}
        {daysLabel && priceLabel && priceValue && (
          <DynamicContent
            tagName="p"
            innerHtml={`${daysLabel} ${priceLabel} ${mainObj.currency} ${priceValue}`}
            attrs={{ className: 'ticket-price' }}
          />
        )}
      </div>
    )
  );
};
