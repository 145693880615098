import classNames from 'classnames';
import React, { useRef, useReducer, useEffect, useState } from 'react';

import UIConfig from '../../../common/UIConfig';
import {
  deepCloneObject,
  canUseDOM,
  isAnnualPassCase,
  checkYasIdExist,
  setLocalStorage,
  getAnonymousCart,
  getCurrentLanguage,
  resolvePath,
  isEmptyObject,
  getRecipientDetails,
  checkTenant,
  getLoggedInUser,
  isLoggedInUser,
} from '../../../common/utility';
import Form from '../form/form-component';
import ErrorSummary from '../../presentation/base/error-summary/error-summary-component';
import { logComponentRenderingError } from '../../../common/logger';
import CheckoutButtons from '../b2c-purchase-journey/cart/checkout-buttons-component';
import { DynamicContent } from '../../presentation/base';
import GTMData from '../b2c-purchase-journey/gtm-data';

const RecipientDetailsForm = (props) => {
  const initialState = {
    serverErrors: null,
    success: false,
    errors: null,
    data: props.data,
    propData: null,
    displayMode: 'edit',
    showRecipientDetailsForm: false,
    yasIdExist: checkYasIdExist(),
  };
  const [defaultGiftMessage, setDefaultGiftMessage] = useState('');

  const isYasArenaCheckout = props.data.variant === UIConfig.b2c.purchaseJourney.paymentVariants.yaGuestCheckout;
  const is2StepPayment = props.data.variant === 'v-form-view' || isYasArenaCheckout;
  const isYAB2C =
    canUseDOM() &&
    localStorage.mainObj &&
    localStorage.mainObj.tenantID &&
    localStorage.mainObj.tenantID.toLowerCase() === UIConfig.tenants.ya;

  const isAnnualPass = useRef(false);
  const reducer = (state, action) => {
    return { ...state, ...action.payload };
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  useEffect(() => {
    const { variant, formName, formType } = props.data;

    if (variant === 'v-form-view') {
      initializeComponent();
    }
    window.PubSub.subscribe('emailError', checkIfFormHasError);
    window.PubSub.subscribe('formSubmitVoucher', (msg, data) => {
      if (data) {
        GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
          name: formName || formType || '',
        });
      }
    });

    return () => {
      window.PubSub.unsubscribe(UIConfig.events.ONCARTUPDATE);
      window.PubSub.unsubscribe(UIConfig.events.TOGGLE_RECIPIENT_DETAILS);
      window.PubSub.unsubscribe(UIConfig.events.TOGGLE_GUEST_CHECKOUT_ANNUALCASE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeComponent = async () => {
    /* 1. Get updated mini cart data when cart is updated */
    await window.PubSub.subscribe(UIConfig.events.ONCARTUPDATE, (msg, data) => {
      /* Publish gift voucher mapping value comes in recipient details form from site core */
      window.PubSub.publish(UIConfig.events.GET_RECIPIENT_EXTRA_PARAMS, {
        extraParams: props.data.extraParams,
        cartData: data,
      });
      /* Check if cart has gift voucher */
      checkIfGiftVoucherExist(data);
    });

    if (is2StepPayment) {
      await window.PubSub.subscribe(UIConfig.events.TOGGLE_GUEST_CHECKOUT_ANNUALCASE, () => {
        isAnnualPass.current = isAnnualPassCase();
      });
    }
  };

  /* Load already filled recipient details into redux */
  const updateData = (recipientDetails, customData) => {
    const data = deepCloneObject(customData.data);
    const newData = getFilledRicipientDetailsForm({ recipientDetails, data });
    dispatch({
      payload: {
        ...customData,
        data: newData,
        displayMode: state.displayMode === 'display' ? 'edit' : 'display',
        showRecipientDetailsForm: true,
      },
    });

    if (is2StepPayment) {
      dispatch({ payload: { ...customData, data: newData, showRecipientDetailsForm: true } });
    }
  };

  /* Validate and check if gift vocher exists */
  const checkIfGiftVoucherExist = (cartData) => {
    const items = resolvePath(cartData.cart, 'items', []);
    const { data } = props;
    let isVoucher = false;
    let isTicket = false;

    /* Don't show form when results are empty or coveo mapping is empty */
    if (!items || !Array.isArray(items)) {
      dispatch({
        payload: {
          showRecipientDetailsForm: false,
        },
      });
      return;
    }

    items.forEach((item) => {
      if (item) {
        /* Get the avaiable category list */
        const categorie = !isSwad ? item.productType : item.productType.toLowerCase();
        /* Check if category is an array */
        const giftVoucher = !isSwad
          ? resolvePath(data, 'extraParams.giftVoucher', '')
          : resolvePath(data, 'extraParams.giftVoucher', '').toLowerCase();
        if (categorie && categorie.includes(giftVoucher)) {
          isVoucher = true;
        } else {
          isTicket = true;
        }
      }
    });
    setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.giftVoucher, JSON.stringify(isVoucher));
    /* show form if only gift voucher exist */
    if (!isTicket && isVoucher && canUseDOM()) {
      getUpdatedMiniCart(cartData);
    }

    /* Dont show the form If only ticket exists */
    if (isTicket && !isVoucher) {
      dispatch({
        payload: {
          ...state,
          showRecipientDetailsForm: false,
        },
      });
    }
  };

  const getFilledRicipientDetailsForm = ({ recipientDetails, data }) => {
    const newData = {};
    if (recipientDetails) {
      for (let key in recipientDetails) {
        if (recipientDetails.hasOwnProperty(key)) {
          newData[key.charAt(0).toUpperCase() + key.substr(1)] = recipientDetails[key];
        }
      }
    }
    if (data.sections) {
      data.sections[0].fields.forEach((field) => {
        if (field.name === 'Message' && !field.value) {
          const defaultValue = data.sections[0].fields[4].value;
          if (defaultValue) {
            setDefaultGiftMessage(defaultValue);
            field.placeholder = defaultValue; // override message field with default gift message comes at index 4 as hidden field in fields array
            field.value = newData[field.name] || '';
          }
        } else if (field.id === 'emailTemplateId') {
          field.value = field.value;
        } else if (recipientDetails) {
          field.value = newData[field.name];
        } else {
          field.value = field.value;
        }
      });
    }
    return data;
  };

  const getUpdatedMiniCart = (data) => {
    let cart = props.data;
    const annonymousCartData = getAnonymousCart();
    /* Reload recipient details from local storage */
    const recipientDetails = getRecipientDetails();
    if (annonymousCartData && annonymousCartData.cart.personalisedGiftObj !== null && isYAB2C) {
      setLocalStorage('inlineSignupHasError', true);
    }

    if (annonymousCartData && annonymousCartData.cart) {
      cart = getFilledRicipientDetailsForm({
        recipientDetails: annonymousCartData.cart.personalisedGiftObj || recipientDetails,
        data: cart,
      });
    }

    const ctaCheckout = cart.sections.filter((section) => section.sectionClass === 'CheckOut')[0].fields;
    const toolTip = ctaCheckout[0].showToolTip ? ctaCheckout[0].tooltip : '';
    isAnnualPass.current = annonymousCartData && isAnnualPassCase(annonymousCartData);
    if (cart) {
      const propData = {
        data: {
          ctaCheckout,
          toolTip,
        },
        cart: cart,
        errObj: null,
        bErrObj: null,
      };
      const customData = {
        ...state,
        data: cart,
        propData: { ...state.propData, ...propData, cart },
      };
      if (annonymousCartData && annonymousCartData.cart && annonymousCartData.cart.personalisedGiftObj) {
        updateData(annonymousCartData.cart.personalisedGiftObj, customData);
      } else {
        updateData(recipientDetails, customData);
      }
    }
  };

  const autoSave = (payload, e, errorObj) => {
    const annonymousCartData = getAnonymousCart();
    if (payload) {
      const formdata = {
        firstName: payload.FirstName,
        lastName: payload.LastName,
        email: payload.Email,
        message: payload.Message && payload.Message.trim() ? payload.Message : defaultGiftMessage,
        emailTemplate: payload.emailTemplateId + `_${getCurrentLanguage()}`,
      };
      checkIfFormIsValid(payload);
      if (annonymousCartData) {
        annonymousCartData.cart.personalisedGiftObj = formdata;
        setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.anonymousCart, JSON.stringify(annonymousCartData));
      }
      setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.recipientDetails, JSON.stringify(formdata));
    }
  };
  const checkIfFormHasError = (msg, data) => {
    window.PubSub.publish('recipientdetailsHasError', { isRecipientError: data.isError });
  };
  //Added for payment section disabled
  const checkIfFormIsValid = (payload, errorObj) => {
    const isRequiredFields = {
      FirstName: payload.FirstName,
      LastName: payload.LastName,
      Email: payload.Email,
      emailTemplate: payload.emailTemplateId,
    };
    let isError = false;
    errorObj &&
      Object.keys(errorObj).length > 0 &&
      Object.keys(isRequiredFields).forEach((field) => {
        if (errorObj.hasOwnProperty(field)) {
          isError = true;
        }
      });
    const error = !isEmptyObject(isRequiredFields) || isError;
    setLocalStorage('inlineSignupHasError', error);
  };

  try {
    const recipientDetailsContainerClasses = classNames(
      'contact-us-form-wrapper guest-checkout-form-wrapper recipient-details-form inline-signup',
      {
        'guest-checkout-variation': is2StepPayment,
        'form-logged-in-user': isLoggedInUser(),
      },
    );
    const tabIndex = state.yasIdExist ? '-1' : '0';
    return (
      state.showRecipientDetailsForm &&
      canUseDOM() && (
        <div className={recipientDetailsContainerClasses}>
          {state.errors && <ErrorSummary data={state.errors} />}
          {isLoggedInUser() && state.data.mainTitle && !isAnnualPass.current && (
            <DynamicContent
              tagName="h3"
              innerHtml={state.data.mainTitle.replace('{firstName}', resolvePath(getLoggedInUser(), 'userName', ''))}
              attrs={{ className: 'heading-3 title' }}
            />
          )}
          {state.propData && state.propData.data && (
            <CheckoutButtons
              propData={state.propData}
              isAnnualPass={isAnnualPass.current}
              tabIndex={tabIndex}
              isRecipientDetails={true}
            />
          )}
          {!isAnnualPass.current && (
            <div className="guest-checkout-wrapper-container">
              <div className={`guest-checkout-wrap ${!is2StepPayment ? state.displayMode : ''}`}>
                <Form
                  data={state.data}
                  getFields={autoSave}
                  isControlled={true}
                  submitSuccess={true}
                  resetCallBack={() => {}}
                  isTabIndex={tabIndex}
                  is2StepPayment={is2StepPayment}
                />
              </div>
            </div>
          )}
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'RecipientDetailsForm');
  }
};

export default RecipientDetailsForm;
