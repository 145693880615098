/*
    mini-cart-reducer.js  This file matins the state chnage of the mini cart.

    @license Miral
*/
import * as types from './mini-cart-constants';

const MiniCartReducer = (namespace) => (miniCartData, action) => {
  switch (action.type) {
    case `${namespace}-${types.UPDATE_CART}`:
    case `${namespace}-${types.CLEAR_CART}`:
      miniCartData.cartProducts = action.payload;
      return { ...miniCartData };

    case `${namespace}-${types.INVALID_CART}`:
      miniCartData.invalidCart = action.payload;
      return { ...miniCartData };

    default:
      return { ...miniCartData };
  }
};

export default MiniCartReducer;
