/*
 * Form-Component.js
 * This file contains code for form component, it renders form
 * @licensor  Miral
 */
import React from 'react';
import moment from 'moment';
import axios from 'axios';
import ApiWrapper from '../../../common/api-wrapper';
import UIConfig from '../../../common/UIConfig';
import {
  deepCloneObject,
  getErrorMap,
  scrollToFormErrorOrSuccess,
  isLoggedInUser,
  isMobileOrTab,
  resolvePath,
  getErrorObj,
  getGenericErrors,
  canUseDOM,
  getEnvVariablesClient,
  getTenantId,
  setLocalStorage,
  getLocalStorageByKey,
  checkTenant,
  isMatchTenant,
  getExpressCheckoutPostPurchaseUser,
  openIAMOverlay,
  checkGA4Tenants,
  checkInlineSignup,
  checkTenantsForZeroBounce,
} from '../../../common/utility';
import {
  Button,
  DynamicContent,
  ErrorSummary,
  Input,
  InputDate,
  InputRange,
  InputTooltip,
  Radio,
  RichText,
  Select,
  SelectWithText,
  TextArea,
} from '../../presentation/base';
import Captcha from '../../presentation/captcha/captcha-component';
import Recaptcha from '../../presentation/recaptcha/Recaptcha';
import { initValdiations, validateForm, focusFirstInputErrorField } from './validator';
import CheckoutButtons from '../b2c-purchase-journey/cart/checkout-buttons-component';
import { Logging } from '../../../common/logger';
import { logComponentRenderingError } from '../../../common/logger';
import * as helper from './helper';
import BookingNotFound from '../../presentation/bookingnotfound-managebooking';
import { setLoginGTM } from '../../../common/analytics-events';
import IAMPopup from '../../presentation/iam-popup/iam-popup';
import GTMData from '../b2c-purchase-journey/gtm-data';

export default class FormMain extends React.Component {
  mapper = {
    Email: Input,
    Text: Input,
    Number: Input,
    Password: Input,
    Hidden: Input,
    Date: Input,
    Textarea: TextArea,
    Radio: Radio,
    Checkbox: Radio,
    CheckBoxGroup: Radio,
    Button: Button,
    Submit: Button,
    Select: Select,
    Captcha: Captcha,
    Recaptcha: Recaptcha,
    ButtonCTA: Button,
    Range: InputRange,
    Reset: Button,
    date: Input,
    SelectWithText: SelectWithText,
    Date2: InputDate,
    TextWithTooltip: InputTooltip,
    RangeCalendar: InputDate,
    Calendar: InputDate,
    RTE: DynamicContent,
    ...((this.props.isPostPurchaseUser || this.props.isParksExpressCheckout) && {
      CustomDropLinkWithToolTip: Button,
    }),
  };

  constructor(props) {
    super(props);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.onFieldFocus = this.onFieldFocus.bind(this);
    this.onFieldPaste = this.onFieldPaste.bind(this);
    this.onFieldCopy = this.onFieldCopy.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFormSubmitSuccess = this.onFormSubmitSuccess.bind(this);
    this.onFormSubmitError = this.onFormSubmitError.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.displayChild = this.displayChild.bind(this);
    this.checkExistingYasID = this.checkExistingYasID.bind(this);
    this.emailErrorHandling = this.emailErrorHandling.bind(this);
    this.parksExpressSignInSignUpOnClick = this.parksExpressSignInSignUpOnClick.bind(this);
    this.formBuilder = this.formBuilder.bind(this);
    this.rules = null;
    this.defaultActiveClass = '';
    this.captchaID = null;
    this.formElements = [];
    this.errorFramework = this.errorFramework.bind(this);
    this.initialPayload = {};
    this.dateFields = [];
    this.initialUpdatedFields = {};
    this.state = {
      focus: '',
      showErrors: this.props.showErrors ? this.props.showErrors : false, // Default error show : false
      active: false,
      successMsg: null,
      serverErrors: this.props.serverErrors ? this.props.serverErrors : null,
      errors: null,
      existingEmailError: null,
      formData: this.props.payFortData ? this.props.payFortData : this.props.data,
      updatedFields: this.initialUpdatedFields,
      detectedCountry: null,
      selectedCountry: null,
      selectedCountryOfResidence: null,
      selectedNationality: null,
      enableLoginButton: false,
      validationErrors: null,
      emailValidationErrors: {},
      emailValidated: false,
    };
    this.isControlledForm = props.isControlled;
    this.checkboxDetailStatus = {};
    this.allActivities = 'select-all';
    this.countries = {};
    this.countryInitialValue = '';
    this.isMobileOrTab = isMobileOrTab();
    this.isYAFreeActivity = resolvePath(props, 'data.variant', '') === 'v-ya-free-activity';
    this.retainDataOnError = props.retainDataOnError;
    this.siteCoreApiUrl = '';
    this.formBuilderField = [];
    this.tenantId = getTenantId(true);
    this.isB2bRegistration = resolvePath(props, 'data.formType', '') === 'isB2bRegistration';
    this.isGuestCheckoutForm = resolvePath(props, 'data.formType', '') === 'guestCheckout';
    this.isExpressCheckoutForm = resolvePath(props, 'data.formType', '') === 'expressCheckout';
    this.isNewsLetterForm = resolvePath(props, 'data.formType', '') === 'newsletter';
    this.isContactUs = props.formType === UIConfig.zeroBounce.contactUs;
    this.isCorpEnrollmentForm = props?.uniqueId === UIConfig.profile.sectionType.createCorporateMember;
    this.getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    this.checkIsYasisland = resolvePath(this.getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
  }

  getCountriesNationalityList = () => {
    const updatedFormData = deepCloneObject(this.state.formData);
    updatedFormData?.sections?.length &&
      updatedFormData.sections.forEach((section) => {
        section?.fields?.length &&
          section.fields.forEach((currentField) => {
            if (currentField.id.toLowerCase() === 'countryofresidence' || currentField.id.toLowerCase() === 'country') {
              currentField.options &&
                currentField.options.forEach((item) => {
                  this.countries[item.identifier] = item.value;
                });
            }
          });
      });
  };

  findMyGeoLoc = () => {
    const envVariables = canUseDOM() && getEnvVariablesClient();
    const isAkamai = envVariables ? envVariables.akamaiEnabled : 'true';
    let geoLocResponse;
    axios
      .get(`${isAkamai === 'false' ? this.siteCoreApiUrl : ''}${UIConfig.geoLocation.httpsUrl}`)
      .then((res) => {
        geoLocResponse = res.data;
        if (geoLocResponse !== null) {
          const country = geoLocResponse.Country || geoLocResponse.country;
          country &&
            this.setState({
              detectedCountry: country,
              selectedCountry: this.countryInitialValue || country,
              selectedCountryOfResidence: country,
              selectedNationality: country,
            });
        }
      })
      .catch((error) => {
        Logging(error, 'GEO Location API failed');
      });
  };

  /**
   * Update Payload object on change of field of type CheckBoxGroup.
   * CheckBoxGroup has a special data attribute named `data-checkedValue`.
   * This special value is set as value instead of "true" or "false"
   * @param    @String type {Value , field name}
   *
   */
  getCheckboxGroupValue(field, value, evntObj) {
    let payloadValue = [...this.state.payLoad[field]];
    const currentValue = evntObj.target.getAttribute('data-checkedValue');

    if (value !== 'false') {
      if (currentValue === this.allActivities) {
        payloadValue = this.checkboxDetailStatus[field].allRegisterValues;
      } else {
        payloadValue.indexOf(currentValue) === -1 && payloadValue.push(currentValue);
      }
      return payloadValue;
    } else {
      if (currentValue === this.allActivities) {
        return [];
      } else {
        return payloadValue.filter((item) => item !== currentValue);
      }
    }
  }

  updatePayLoad = (value, field, evntObj, additionalObj) =>
    helper.updatePayLoad(this, value, field, evntObj, additionalObj);

  getCaptchaValue = (value, evntObj) => helper.getCaptchaValue(this, value, evntObj);

  /**
   * Calculates Body Mass Index(BMI) rounded to two decimal places.
   *
   */
  calculateBMI(height, weight) {
    return Math.round((weight / height / height) * 1000000) / 100;
  }

  checkError = (value, field, checkExistingYasIdError) =>
    helper.checkError(this, value, field, checkExistingYasIdError);

  onResetClick = (e) => helper.onResetClick(this, e);

  onFieldChange = (e) => helper.onFieldChange(this, e);

  onFieldBlur = (e, additionalObj) => {
    helper.onFieldBlur(this, e, additionalObj);
    if (this.isNewsLetterForm && e.target.type === 'email' && e.relatedTarget.type === 'submit') {
      this.onSubmit(e, true);
    }
  };

  onFieldFocus(e) {
    this.setState({ focus: e.target.name });
  }

  onFieldPaste(e) {
    // paste on email and confirm email for yas Island
    if (isMatchTenant(UIConfig.YIB2C) && (e?.target?.id == 'ConfirmEmail' || e?.target?.id == 'Email')) {
      e.preventDefault();
    } else if (this.props.preventEmailPaste && e?.target?.id == 'ConfirmEmail') {
      e.preventDefault();
    } else {
      this.props.payFortData && e.preventDefault();
    }
  }

  onFieldCopy(e) {
    this.props.payFortData && e.preventDefault();
  }

  /**
   * function to show error message for existing email
   *
   * @param {Object} errorObj
   */
  emailErrorHandling = (errorObj) => {
    if (errorObj) {
      this.setState({
        existingEmailError: errorObj,
      });
      document.getElementById('Email') && document.getElementById('Email').classList.add('error');
      window.PubSub.publish('checkPayButtonStatus');
    } else {
      this.setState({
        existingEmailError: null,
      });
      document.getElementById('Email') && document.getElementById('Email').classList.remove('error');
      window.PubSub.publish('checkPayButtonStatus');
    }
  };

  /**
   * triggers an API call to verify whether user has an exisitng YAS ID or not
   *
   * @param {string} emailId
   */
  checkExistingYasID = (emailId) => {
    const genericErrors = getGenericErrors();
    let serviceURL = this.props.data.services.checkExistingYasId;
    let url = serviceURL.url + '?email=' + emailId;
    ApiWrapper.apiGateway({
      url: url,
      method: 'GET',
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    })
      .then((response) => {
        this.state.emailValidated = true;
        if (response.status === 200 && response.data) {
          let errorObj = getErrorObj(response.status, serviceURL.errors, true);
          if (typeof response.data === 'boolean') {
            this.emailErrorHandling(errorObj);
          } else {
            this.emailErrorHandling(null);
          }
        } else {
          this.emailErrorHandling(null);
        }
      })
      .catch((response) => {
        this.state.emailValidated = false;
        if (response.error && response.error.code) {
          const errorCode = resolvePath(response, 'error.code', '');
          let errorObj = getErrorObj(errorCode, serviceURL.errors, true);
          if (errorCode) {
            if (response.duplicateEmail && resolvePath(response, 'duplicateEmail.issuer', '')) {
              errorObj = {
                ...errorObj,
                error: {
                  ...errorObj.error,
                  text: errorObj.error.text.replace('{0}', response.duplicateEmail.issuer),
                },
              };
            }
            this.emailErrorHandling(errorObj);
            this.setState({
              errors: getErrorObj(response.error.code, genericErrors, true),
            });
          }
        }
      });
  };

  /**
   * triggers an API call to verify zero bounce
   *
   * @param {string} emailId
   */
  checkZeroBounceEmail = async (emailId, field) => {
    const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailTrue = emailReg.test(String(emailId).toLowerCase());
    const genericErrors = getGenericErrors();
    if (isEmailTrue) {
      const apiData = this.props.data.services.NewsletterVerifyEmailSubscriptionAPI;
      if (!apiData) {
        return;
      }
      let url = apiData.url.replace(':email', '?email=' + emailId);
      let emailErrorRes = {};
      await ApiWrapper.apiGateway({
        url: url,
        method: 'GET',
        preLoader: false,
      })
        .then((response) => {
          if (response.status === 200) {
            let errorObj = getErrorObj(response.status, apiData.errors, true);
            if (response.data === false) {
              const email = { message: errorObj.error.text, valid: 'error' };
              emailErrorRes = { [field]: email, showErrors: true };

              setLocalStorage('isEmailError', response.data);
              window.PubSub.publish('emailError', { isError: true });
              typeof this.props.onSubmitHideNewsletterError === 'function' && this.props.onSubmitHideNewsletterError();
              this.isExpressCheckoutForm && this.emailErrorHandling(errorObj);
            } else {
              emailErrorRes = { [field]: {}, showErrors: false };
              setLocalStorage('isEmailError', response.data);
              window.PubSub.publish('emailError', { isError: false });
              this.isExpressCheckoutForm && this.emailErrorHandling(null);
            }
          }
          this.setState({
            emailValidationErrors: emailErrorRes,
          });
        })
        .catch((response) => {
          if (response.error && response.error.code) {
            const errorCode = resolvePath(response, 'error.code', '');
            let errorObj = getErrorObj(errorCode, apiData.errors, true);
            if (errorCode) {
              if (response.duplicateEmail && resolvePath(response, 'duplicateEmail.issuer', '')) {
                errorObj = {
                  ...errorObj,
                  error: {
                    ...errorObj.error,
                    text: errorObj.error.text.replace('{0}', response.duplicateEmail.issuer),
                  },
                };
              }
              this.emailErrorHandling(errorObj);
              this.setState({
                errors: getErrorObj(response.error.code, genericErrors, true),
              });
            }
          }
        });
      return emailErrorRes;
    } else {
      this.setState({
        emailValidationErrors: {},
      });
    }
  };

  /**
   * On form submit success handler.
   * @param    {[Array]} data array of props objects
   *
   */
  onFormSubmitSuccess(data) {
    this.setState({ errors: null });
    window.open(data.redirecturl, '_self');
  }
  /**
   * On form submit error handler.
   * @param    {object} response objects containing errors and form submit boolean value
   *
   */
  onFormSubmitError(response) {
    if (typeof response === 'object' && !response.isFormSubmitted) {
      this.setState({ serverErrors: response.error, showErrors: true });
      if (this.captchaID) {
        window[this.captchaID].ReloadImage();
      }
      window.scrollTo(0, 0);
    }
  }

  /**
   * On server Success render form success message.
   *
   * @return   {[Object]} JSX sever errors list
   */
  showSuccess() {
    let serverErrors = this.state.serverErrors,
      isErrors = this.state.showErrors;
    if (!serverErrors && !isErrors && this.state.successMsg) {
      return (
        <div className="server-success body-copy-medium">
          <p className="success-msg">{this.state.successMsg}</p>
        </div>
      );
    }
  }

  getFormData = () => {
    return this.isControlledForm
      ? this.state.formData
      : this.props.payFortData
      ? this.props.payFortData
      : this.props.data;
  };

  /**
   * On page load update payload object.
   * @param    @object type {field name : field value}
   *
   */
  UNSAFE_componentWillMount() {
    let data = this.getFormData(),
      payLoad = this.createNewPayload(data);

    this.getCountriesNationalityList();

    this.initialPayload = payLoad;
    this.setState({ payLoad: payLoad, updateFields: this.initialUpdatedFields });
  }

  createNewPayload = (data) => helper.createNewPayload(this, data);

  rulesMapping = (field) => {
    return {
      name: field.name,
      rules: {
        isRequired: field.isRequired,
        message: field.isRequiredMessage,
        minDate: field.minDate,
        minMessage: field.isMinMessage,
        maxDate: field.maxDate,
        maxMessage: field.isMaxMessage,
        type: field.type,
        isConfirm: field.isConfirm ? field.isConfirm : false,
        cMessage: field.isConfirmMessage ? field.isConfirmMessage : null,
        fieldToConfirm: field.fieldToConfirm ? field.fieldToConfirm : null,
        validation: field.validations,
      },
    };
  };

  toggleRules = (field, action) => {
    if (this.rules) {
      switch (action) {
        case 'remove':
          this.rules.splice(
            this.rules.findIndex((rule) => rule.name === field.name),
            1,
          );
          break;
        case 'add':
        default:
          this.rules.push(this.rulesMapping(field));
          break;
      }
    }
  };

  /**
   * On component load creating validaiton rule object.
   * @param    @object type {field name : field value}
   *
   */
  createRuleValidator() {
    let sections = [];
    if (this.birthdayRegisterationForm) {
      sections = this.state.formData.sections;
    } else {
      sections = this.props.payFortData
        ? this.props.payFortData.sections
        : this.props.data
        ? this.props.data.sections
        : null;
    }
    if (sections) {
      this.rules =
        Array.isArray(sections) &&
        sections.map((section, i) => {
          let rule =
            Array.isArray(section.fields) &&
            section.fields.map((field, i) => {
              return this.rulesMapping(field);
            });
          return rule;
        });
      this.rules = [].concat(...this.rules);
    }
    //To reset registration form on click of Back button

    if (
      resolvePath(window, 'performance.navigation.type') === 2 &&
      this.refs.mainForm &&
      this.refs.mainForm.classList.contains('registration-form')
    ) {
      window.location.reload();
    }
  }

  async componentDidMount() {
    if (!isLoggedInUser()) {
      /**
       * fallback of getIpLocationDetail API call response value null on nodeproxy
       */

      if (canUseDOM()) {
        const envElm = document.getElementById('__ENV_VARIABLES__');
        const getEnvVariables = resolvePath(envElm, 'innerHTML') ? JSON.parse(envElm.innerHTML) : {};
        this.siteCoreApiUrl = resolvePath(getEnvVariables, 'envSiteCoreApiUrl');

        const email = this.state?.payLoad?.['Email'];
        if (email && this.isExpressCheckoutForm && checkTenantsForZeroBounce()) {
          await this.checkZeroBounceEmail(email, 'email');
        }
        if (checkInlineSignup() && this.isGuestCheckoutForm && email && checkTenantsForZeroBounce()) {
          this.checkExistingYasID(email, 'email');
        }
      }
      this.findMyGeoLoc();
    }
    this.createRuleValidator();
    this.dateFields.forEach((dom_id) => {
      const element = document.getElementById(dom_id);
      const element_value = element.value;
      element.value = '';
      element.value = element_value;
    });
    if (this.retainDataOnError) {
      this.updatePayloadWithStorageData();
    }
    //Showing error messages in form in form in case submit button is not a part of the form component
    //This will trigger all the validations
    window.PubSub.subscribe('initiatePaymentSubmitValidation', () => {
      const errors =
        this.state.payLoad && !this.state.payLoad.hasOwnProperty('SignUp')
          ? validateForm(this.rules, this.state.payLoad)
          : {};
      if (Object.keys(errors).length && errors.constructor === Object) {
        this.setState({ validationErrors: errors, showErrors: true }, function() {
          const errorElements = document.getElementsByClassName('error-msg');
          if (errorElements.length) {
            errorElements[0].parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }
    });

    //Showing smothli scroll to the error message with className serverError
    window.PubSub.subscribe(UIConfig.subscribes.initiateServerError, () => {
      const serverError = document.getElementsByClassName(`${UIConfig.errorClassNames.serverError}`);
      if (serverError.length) {
        serverError[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
    window.PubSub.subscribe('removePlaceholder', (msg, event) => {
      let value = event.value;
      let field = event.name;
      let payload = this.state.payLoad;
      if (payload[field]) {
        payload[field] = value;
      }
      if (!value) {
        this.setState({ focus: '' });
      }
      this.setState({ payload });
    });
    this.props &&
      this.props.data &&
      this.props.data.variant === 'B2C-bookDate' &&
      this.setState({ enableLoginButton: true });
    localStorage.removeItem('isEmailError');

    if (this.props.isPostPurchaseUser) {
      const formInput = document.querySelector('.express-post-purchase .input-type-email');
      formInput && formInput.classList.add('active');
      const Input = document.querySelector('.express-post-purchase .input-type-email input');
      Input && Input.focus();
    }

    window.PubSub.subscribe('bookingNotFound', (data) => {
      this.setState({ bookingNotFound: true, bookingNotFoundText: this.props.data?.services?.manageBooking });
    });
  }

  updateFieldValueWithStorage = (field) => helper.updateFieldValueWithStorage(this, field);

  shouldComponentUpdate(newProp) {
    this.createRuleValidator();
    return true;
  }

  updatePayloadWithStorageData = () => helper.updatePayloadWithStorageData(this);

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.serverErrors !== nextProps.serverErrors) {
      this.setState({
        serverErrors: nextProps.serverErrors,
        showErrors: nextProps.showErrors,
        successMsg: nextProps.successMsg,
      });
    }
    if (this.isControlledForm && nextProps.submitSuccess) {
      this.state.formData = nextProps.data;
      this.state.payLoad = this.createNewPayload(nextProps.data);
      this.state.updateFields = this.initialUpdatedFields;
      this.initialPayload = deepCloneObject(this.state.payLoad);
    }
    if (this.isControlledForm && nextProps.data && 'isOrderByDate' in nextProps.data && nextProps.data.isOrderByDate) {
      this.setState({ validationErrors: { From: '', To: '' }, showErrors: false });
    }
    if (this.retainDataOnError && this.props.formSubmitError !== nextProps.formSubmitError) {
      this.updatePayloadWithStorageData();
    }
  }

  /* Error Framework Call */
  errorFramework(response) {
    let errorMsgData = getErrorMap(
      Object.keys(this.props.data.services)[0],
      this.props.data.services.createPartner.errors,
      false,
      response.error,
      this.state.serverErrors,
    );
    this.setState({ errors: errorMsgData });
  }

  checkErrors = () => {
    return (
      localStorage.getItem(UIConfig.localStoreKeys.cartError) ||
      localStorage.getItem(UIConfig.localStoreKeys.postPayLimitError) ||
      localStorage.getItem(UIConfig.localStoreKeys.tabbyLimitError)
    );
  };
  /**
   * On form submit validate form and submit the form.
   *
   *
   */
  onSubmit = async (e, newsletter = false) => {
    e.preventDefault();

    if (this.isNewsLetterForm || this.isCorpEnrollmentForm) {
      const emailFieldName = this.isCorpEnrollmentForm ? 'corporateEmail' : 'email';
      const type = this.isCorpEnrollmentForm ? 'corporateEmail' : 'email';

      await this.checkError(this.state?.payLoad?.[emailFieldName] || '', type);
    }

    initValdiations();
    typeof this.props.onSubmitHideNewsletterError === 'function' && this.props.onSubmitHideNewsletterError();
    let tempemailValidation = {
      showErrors: false,
    };
    const getEmailError = getLocalStorageByKey('isEmailError');
    const isEmailError = getEmailError ? false : true;
    //zerobounce email error check to prevent user from submitting form.
    let submitCallback = this.props.submitCallback,
      noScroll = this.props.noScroll, // to disable scroll functionality
      errors = validateForm(this.rules, this.state.payLoad),
      data = this.props.data,
      params = { ...this.state.payLoad };

    if (!params?.email?.length > 0) {
      this.setState({
        emailValidationErrors: {},
      });
    }
    if ((Object.keys(errors).length && errors.constructor === Object) || isEmailError) {
      this.setState(
        { validationErrors: { ...errors, ...this.state.emailValidationErrors }, showErrors: true },
        function() {
          if (!noScroll) {
            if (this.props.isPostPurchaseUser) scrollToFormErrorOrSuccess('error-msg', false, true);
            else scrollToFormErrorOrSuccess('error-msg');
          }
        },
      );
      //to focus the first input field with errors
      focusFirstInputErrorField();
    }
    // Form submission passing serliaze object
    if (typeof submitCallback !== 'function') {
      params.dataSource = data.dataSource;
      params = this.isB2bRegistration ? helper.updatePartnersApiPayload(params) : params;
      if (!Object.keys(errors).length && data.action && !isEmailError) {
        let _self = this,
          header = {
            __RequestVerificationToken: document.getElementsByName('__RequestVerificationToken').length
              ? document.getElementsByName('__RequestVerificationToken')[0].value
              : '',
          };
        ApiWrapper.platformService({
          url: this.props.data.services.createPartner.url,
          method: data.method ? data.method : 'POST',
          tokenRequired: false,
          data: params,
          preLoader: true,
          preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
          headers: header,
          moduleName: 'Form-Component',
        })
          .then(function(response) {
            const isFormSubmitted = _self.isB2bRegistration
              ? resolvePath(response.data, 'createPartnerResponse.crmAccountId', '')
              : response.data.isFormSubmitted;
            if (typeof response.data === 'object' && isFormSubmitted) {
              _self.onFormSubmitSuccess(data);
            } else {
              if (_self.isB2bRegistration) {
                response.data.isFormSubmitted = false;
                response.error = resolvePath(response.data, 'createPartnerResponse.error', '');
              }
              _self.onFormSubmitError(response.data);
              _self.errorFramework(response);
            }
          })
          .catch((response) => {
            _self.errorFramework(response);
          });
      }
    }

    // Callback method will be intiated
    if (typeof submitCallback === 'function' && !Object.keys(errors).length && !isEmailError) {
      // Map child fields value to parent fields for submiting payload
      const payLoad = deepCloneObject(this.state.payLoad);
      this.state.formData.sections.forEach((section) =>
        section.fields.forEach((field) => {
          const fieldValue = field.value,
            apiMappingKey = field.apiMapping,
            isFieldVisible = !field.hide;

          if (field && field.isChildField && field.replaceChildValueInParent && isFieldVisible) {
            const parentId = field.parents.split('-')[0];
            payLoad[parentId] = fieldValue;
          }

          if (apiMappingKey && isFieldVisible && fieldValue) {
            payLoad[apiMappingKey] = fieldValue;
          }
        }),
      );
      if (this.checkErrors()) return;
      const paymentError = new URLSearchParams(window.location.search).get('payment_error');
      if (
        checkGA4Tenants([UIConfig.tenants.yi], [UIConfig.tenants.ya]) &&
        !paymentError &&
        !this.props.noFormComplete &&
        !newsletter
      ) {
        GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
          name: this.props?.data?.formName || this.props?.data?.formType || '',
        });
      }
      submitCallback.call(this, errors, payLoad, this.captchaID);
    }
  };

  handleInput = (e) => {
    let value, field, target;
    target = !e.target ? e : e.target;
    value = target.value.replace(/[^0-9 ,]/, '');
    field = target.name;
    if (e.target.value.length <= 7) {
      this.updatePayLoad(value, field, e);
    }
    window.PubSub.publish('AdvanceTopUp', {
      cart: {
        amount: value,
      },
      advancePaymentAPI: {
        url: this.props.data.services.b2bAdvanceTopUp.url,
      },
    });
  };

  handleBlur = (e, additionalObj = false) => {
    const value = e.target.value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
      field = e.target.name;
    this.updatePayLoad(value, field, e, additionalObj);
    if (!value) {
      this.setState({ focus: '' });
    }
  };

  handleFocus = (e, additionalObj = false) => {
    const getFocusedValue = e.target.value.split(',').join(''),
      field = e.target.name;
    this.updatePayLoad(getFocusedValue, field, e, additionalObj);
    this.setState({ focus: e.target.name });
  };

  displayChild = (condition, child) => helper.displayChild(this, condition, child);

  parksExpressSignInSignUpOnClick(e) {
    e.preventDefault();
    const data = this.props.data;
    if (data.signIn?.loginCta?.href) {
      openIAMOverlay(<IAMPopup loginURL={data.signIn?.loginCta?.href} />);
    }
  }

  /**
   * Creating form elements object and return to render method
   * * @param    @Object  Mapper-object
   * * @param    @Object  Fields object from JSON
   *
   */
  formBuilder(mapper, fields) {
    const B2BAdvanceTopupVariant = this.props.data && this.props.data.variant === 'B2BAdvanceTopup';
    const onFieldChange = B2BAdvanceTopupVariant ? (e) => this.handleInput(e) : this.onFieldChange;
    const onFieldBlur = B2BAdvanceTopupVariant ? (e) => this.handleBlur(e) : this.onFieldBlur;
    const onFieldFocus = B2BAdvanceTopupVariant ? (e) => this.handleFocus(e) : this.onFieldFocus;

    const checkFormtype = () => this.state.formData.formType === 'guestCheckout';

    const error = this.state.validationErrors,
      showErrors = this.state.showErrors,
      payLoad = this.state.payLoad,
      focusField = this.state.focus,
      form =
        Array.isArray(fields) &&
        fields.map((field, i) => {
          let Field = this.mapper[field.type],
            currentField = field.name;
          field.valid = this.defaultActiveClass;
          field.active = field.active ? field.active : this.defaultActiveClass; // here
          let radioValues = null;
          if (field.type.toLowerCase().indexOf('date') !== -1 || field.name.indexOf('dob') !== -1) {
            field.type = field.type === 'Date2' ? field.type : this.isMobileOrTab ? 'Date' : 'Text';
            if (field.class && field.class.includes('DateType-With-Calendar')) {
              field['maxOrdersByDate'] = this.props.data.maxOrdersByDate;
              field['minOrdersByDate'] = this.props.data.minOrdersByDate;
            }
            field.validations = this.isMobileOrTab ? [] : field.validations;
            if (field.value) {
              this.dateFields.push(field.id);
            }
          }

          if (field && field.isChildField) {
            const parentField = fields.filter((f) => f.id === field.parentFieldId)[0];
            field.parents = `${parentField && parentField.isChildField ? parentField.parents : ''}${
              field.parentFieldId
            }-`;
            if (field.name && field.name.toLowerCase() === 'guardianname') {
              const age = this.isYAFreeActivity
                ? Number(payLoad[field.parentFieldId] || field.parentFieldValue)
                : moment().diff(payLoad[field.parentFieldId] || moment(), 'years');
              const isAdult = age >= field.parentFieldValue;
              this.displayChild(isAdult, field);
            } else if (field.name === 'CurriculamTypeOther') {
              const isCurriculamTypeOther = payLoad[field.parentFieldId] !== field.parentFieldValue;
              this.displayChild(isCurriculamTypeOther, field);
            } else {
              field.hide = payLoad[field.parentFieldId] !== field.parentFieldValue;
            }
            if (field.hide) {
              field.value = '';
              this.toggleRules(field, 'remove');
            } else {
              this.toggleRules(field, 'add');
            }
          }

          if (field.type.toLowerCase() === 'radio') {
            radioValues = {
              [field.name]: this.state.payLoad[field.name],
            };
          }

          if (field.type.toLowerCase() === 'checkboxgroup') {
            radioValues = {
              [field.name]: field.options
                .filter(
                  (opt) =>
                    opt.checked ||
                    (this.state.payLoad[field.name] && this.state.payLoad[field.name].includes(opt.value)),
                )
                .reduce((acc, opt) => {
                  acc.push(opt.value);
                  return acc;
                }, []),
            };
          }

          if (this.tenantId === 'YIB2C' && field.type.toLowerCase() === 'password') {
            field = { ...field, tenantId: this.tenantId };
          }

          // append tenantId if current tenant is YasIsland
          if (
            this.tenantId === 'YIB2C' &&
            (field.id.toLowerCase() === 'country' || field.id.toLowerCase() === 'nationality')
          )
            field = { ...field, tenantId: this.tenantId };

          //Send services to calender component in case seat availability need to be checked
          const services = resolvePath(this.props, 'data.services', null);
          if (field.type.toLowerCase() === 'calendar' && services.getProductOccupancy && services.performance) {
            field.services = services;
          }

          if (field.type.toLowerCase() === 'rte') {
            return <Field tagName="div" attrs={{ className: 'rte-text' }} innerHtml={field.label} />;
          }

          if (Field) {
            const { detectedCountry } = this.state;

            if (
              !isLoggedInUser() &&
              detectedCountry &&
              (field.id.toLowerCase() === 'country' || field.id.toLowerCase() === 'countryofresidence')
            ) {
              const updatedCountryOptions = JSON.parse(JSON.stringify(field.options));
              this.formBuilderField.push(field.id.toLowerCase());
              for (let i = 0; i < field.options.length; i++) {
                if (field.options[i].identifier === detectedCountry) {
                  updatedCountryOptions.splice(i, 1);
                  updatedCountryOptions.unshift(field.options[i]);
                  field.options = JSON.parse(JSON.stringify(updatedCountryOptions));
                  break;
                }
              }

              field.id.toLowerCase() === 'country' &&
                (field.value = field.value || this.countries[this.state.selectedCountry]);
              field.id.toLowerCase() === 'countryofresidence' &&
                this.countries[this.state.selectedCountryOfResidence] &&
                (field.value = this.countries[this.state.selectedCountryOfResidence]);

              payLoad[currentField] = field.value;
              if (field.updateField !== '') {
                this.initialUpdatedFields[field.updateField] = field.value;
              }
            }

            if (
              !isLoggedInUser() &&
              this.formBuilderField.indexOf(field.id.toLowerCase()) === -1 &&
              detectedCountry &&
              field.id.toLowerCase() === 'nationality'
            ) {
              this.formBuilderField.push(field.id.toLowerCase());
              const updatedNationalityOptions = JSON.parse(JSON.stringify(field.options));
              for (let i = 0; i < field.options.length; i++) {
                if (field.options[i].identifier === detectedCountry) {
                  updatedNationalityOptions.splice(i, 1);
                  updatedNationalityOptions.unshift(field.options[i]);
                  field.options = JSON.parse(JSON.stringify(updatedNationalityOptions));
                  break;
                }
              }

              payLoad[currentField] = field.value;
            }

            const mobileFieldIds = ['MobileNumber'];
            if (mobileFieldIds.includes(field.id)) {
              this.initialUpdatedFields[field.id] = this.state.selectedCountry;
            }

            if (error && error.hasOwnProperty(currentField)) {
              field = {
                ...field,
                ...error[currentField],
                showErrors: !this.props.componentRender ? showErrors : field.showErrors, // enhaned field functionality and props can be passed to override existing message
                message: this.props.componentRender
                  ? field.message || error[currentField].message
                  : error[currentField].message,
              };
            }

            if (focusField === currentField || (payLoad.hasOwnProperty(currentField) && payLoad[currentField] !== '')) {
              field = { ...field, active: 'active' };
            }

            const isParksExpressSignInSignUp =
              this.props.isParksExpressCheckout &&
              field.type.toLowerCase() === 'customdroplinkwithtooltip' &&
              field.id === 'LogInSignUp';

            return (
              <>
                {field?.helperText?.length > 0 && (
                  <DynamicContent
                    tagName={'div'}
                    attrs={{ className: 'helper-text-submit' }}
                    innerHtml={field.helperText}
                  />
                )}
                <Field
                  key={i}
                  data={{
                    ...field,
                    ...(this.props.isPostPurchaseUser &&
                      field.id === 'Email' && {
                        val: resolvePath(getExpressCheckoutPostPurchaseUser(), 'Email', ''),
                        defaultValue: resolvePath(getExpressCheckoutPostPurchaseUser(), 'Email', ''),
                        value: resolvePath(getExpressCheckoutPostPurchaseUser(), 'Email', ''),
                        isDisabled: !!resolvePath(getExpressCheckoutPostPurchaseUser(), 'Email', null),
                      }),
                  }}
                  onPaste={this.onFieldPaste}
                  onCopy={this.onFieldCopy}
                  onFocus={onFieldFocus}
                  onChange={onFieldChange}
                  onBlur={onFieldBlur}
                  updatedFieldValue={this.state.updatedFields[field.name]}
                  onReset={(e) => this.onResetClick(e)}
                  controlled={this.isControlledForm}
                  radioValues={radioValues}
                  is2StepPayment={this.props.is2StepPayment || this.props.isNewForm}
                  handleDateProp={this.props.handleDateProp || {}}
                  isTabIndex={this.props.isTabIndex}
                  emailValidated={this.state.emailValidated}
                  setFocus={checkFormtype()}
                  isManageBooking={this.props.isManageBooking}
                  {...(isParksExpressSignInSignUp && {
                    parksExpressSignInSignUpOnClick: (e) => this.parksExpressSignInSignUpOnClick(e),
                  })}
                  {...((this.props.isParksExpressCheckout || this.props.isPostPurchaseUser) && {
                    enableBtnClass: true,
                  })}
                />
              </>
            );
          }
          return null;
        });
    return form;
  }

  onSectionActionClick = () => {
    if (this.props.sectionAction) {
      const updatedFormData = this.props.sectionAction(deepCloneObject(this.state.formData)),
        updatedPayload = this.createNewPayload(updatedFormData);
      this.setState({ payLoad: updatedPayload, formData: updatedFormData });
    }
  };

  render() {
    // return <>Form Render{console.log('this: ', this.props)}</>;

    try {
      let data = this.getFormData();
      if (!data) {
        return null;
      }

      const sections = data.sections;
      const fields =
        Array.isArray(sections) &&
        sections.map((section, i) => {
          const isEmail = section.fields.some((field) => field.type === 'Email');
          const isCustomErrorMessage =
            this.props.isRenderCustomError && this.props.renderCustomError instanceof Function;
          return (
            <fieldset
              key={i}
              className={`${this.props.disableForm ? `${section.sectionClass} disabled` : section.sectionClass} ${
                data.variant
              }`}
              aria-label={section.sectionAriaLabel || `field section`}
            >
              <DynamicContent
                tagName={this.tenantId === 'YMCB2C' ? 'div' : 'section'}
                attrs={{ className: 'fieldset-title' }}
                innerHtml={section.sectionTitle}
              />
              <DynamicContent
                tagName={this.tenantId === 'YMCB2C' ? 'div' : 'section'}
                attrs={{ className: 'fieldset-sub-title' }}
                innerHtml={section.sectionsubTitle}
              />
              <legend>{section.sectionName}</legend>
              {this.checkIsYasisland ? (
                <div className="yasisland-email-error">
                  {this.state.bookingNotFound && this.props.data && (
                    <BookingNotFound data={this.state.bookingNotFoundText} />
                  )}
                  {this.state.existingEmailError && (
                    <ErrorSummary
                      data={this.state.existingEmailError}
                      className="yasid-email-error"
                      disableScroll={true}
                    />
                  )}
                  <div>{this.formBuilder(this.mapper, section.fields)}</div>
                </div>
              ) : (
                <>
                  {this.formBuilder(this.mapper, section.fields)}
                  {isEmail && isCustomErrorMessage && this.props.renderCustomError()}
                </>
              )}

              {section.actionButton ? (
                <div className="section-action">
                  <button
                    type={section.actionButton.type}
                    onClick={(e) => {
                      this.onSectionActionClick();
                    }}
                  >
                    <DynamicContent
                      attrs={{ className: 'section-action--text' }}
                      tagName="span"
                      innerHtml={section.actionButton.label ? section.actionButton.label : section.actionButton.copy}
                    />
                  </button>
                </div>
              ) : null}
            </fieldset>
          );
        });
      return (
        <div data-c-name="Form" data-c-render="Universal" className={'component c-form ' + data.className}>
          {this.state.existingEmailError && !this.checkIsYasisland && (
            <ErrorSummary data={this.state.existingEmailError} className="yasid-email-error" disableScroll={true} />
          )}
          {this.state.errors && <ErrorSummary data={this.state.errors} hideErrorWrapper={this.isB2bRegistration} />}
          <div className="w--content c-form-wrapper">
            {!this.props.isNewForm ? (
              <form
                ref="mainForm"
                noValidate="novalidate"
                onSubmit={this.onSubmit}
                getdata={this.getFields}
                className={`form ${data.className} ${this.state.showForm} ${this.props.formClass}`}
                action={
                  data.action
                    ? data.action
                    : data.formType && data.services[data.formType]
                    ? data.services[data.formType].url
                    : null
                }
                autoComplete={data.autoComplete}
                method={data.method}
              >
                {data.title ? (
                  <RichText
                    data={{
                      bodyCopy: this.props.data.title,
                      attrs: {
                        className: 'heading-1',
                        htmlFor: data.id,
                      },
                    }}
                  />
                ) : null}
                {(this.tenantId === 'YIB2C' || this.isSwad) && data.subTitle ? (
                  <RichText
                    data={{
                      bodyCopy: this.props.data.subTitle,
                      attrs: {
                        className: 'body-copy-1',
                        htmlFor: data.id,
                      },
                    }}
                  />
                ) : null}
                {this.props &&
                  this.props.data &&
                  (this.props.data.variant === UIConfig.jssForms.variant.B2CBookDate ||
                    this.props.data.variant === UIConfig.jssForms.variant.B2CUserNotLoggedIn) &&
                  this.props.loginData && (
                    <CheckoutButtons
                      propData={this.props.loginData}
                      isAnnualPass={false}
                      is2StepJourney={true}
                      enableLoginButton={this.state.enableLoginButton}
                    />
                  )}
                {this.showSuccess()}
                {fields}
              </form>
            ) : (
              <div>{fields}</div>
            )}
          </div>
        </div>
      );
    } catch (err) {
      const paymentVariant = resolvePath(this.props, 'payFordata.paymentOptions.variant', '');
      let variant = resolvePath(this.props, 'data.variant', '');
      if (paymentVariant) {
        variant = paymentVariant;
      }
      return logComponentRenderingError(err, 'Form', variant);
    }
  }
}
