import React, { useState } from 'react';
import './swad-banner-component.scss';
import cn from 'classnames';

const SwadBanner = ({ title, description, onClose }) => {
  const [isClose, setIsClose] = useState(false);

  const renderCrossIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M6.4 19L5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6L6.4 19z"
        ></path>
      </svg>
    );
  };

  return (
    <div className={cn('swad-banner', { 'swad-banner-hide': isClose })}>
      <div className="swad-banner-container">
        <div className="swad-banner-wrapper">
          <div className="swad-banner-wrapper--title">{title}</div>
          <button
            onClick={() => {
              if (onClose) onClose();
              else setIsClose(true);
            }}
            className="swad-banner-btn"
          >
            {renderCrossIcon()}
          </button>
        </div>
        <div className="swad-banner-wrapper--description">{description}</div>
      </div>
    </div>
  );
};

export default SwadBanner;
