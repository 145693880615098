import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  canUseDOM,
  decodeHtmlEntity,
  getLoginUser,
  getMainObject,
  getWishListPopupData,
  openIAMOverlay,
  resolvePath,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { Wishlist } from '../../../common/wishlist-api';
import { AnchorLink, DynamicContent, SvgSprite } from '../base';
import { ServiceConfig } from '../../../common/services';
import { addWishlistAnalytics, removeWishlistAnalytics } from '../../../common/analytics-events';
import { logComponentRenderingError } from '../../../common/logger';
import IAMPopup from '../../presentation/iam-popup/iam-popup';

const isInWishlist = (data, itemId) => {
  let localWishlistData = canUseDOM() && localStorage && localStorage.wishlistData;
  return !!(data.wishlist && localWishlistData && JSON.parse(localWishlistData)[itemId]);
};

const closeModal = () => {
  window.PubSub.publish('toggleOverlayState', { shouldOpen: false });
};

const renderWishlistErrorModal = (wishlistErrType) => {
  const data = getWishListPopupData(wishlistErrType);
  return (
    <div className="wishlist-error-section">
      <div className="wishlist-error-icon">
        <SvgSprite id="icn-exclaimation-mark" />
      </div>
      <DynamicContent tagName="div" attrs={{ className: 'heading-4' }} innerHtml={data.bodyCopy} />
    </div>
  );
};

const parseAnalyticsData = (propsData, isLoginWishList) => {
  const data = parseWishListData(propsData, isLoginWishList);
  return data.wishlist[0].title + (data.wishlist[0].item.title ? ': ' + data.wishlist[0].item.title : '');
};

const parseWishListData = (data, isLoginWishList) => {
  const { wishlist } = data;
  const { childData } = data;
  const { parentWishList } = wishlist;
  if (data.wishlist.sameAsParent) {
    return {
      wishlist: [
        {
          title: parentWishList.title,
          link: isLoginWishList ? wishlist.bookingUrl : parentWishList.link,
          desc: parentWishList.description,
          image: parentWishList.cardImage.src,
          productCode: parentWishList.travelBoxCode,
          itemId: parentWishList.parentuniqueId,
          category: parentWishList.category,
          item: {},
        },
      ],
    };
  }
  return {
    wishlist: [
      {
        title: parentWishList.title,
        link: parentWishList.link,
        desc: parentWishList.description,
        image: parentWishList.cardImage.src,
        productCode: parentWishList.travelBoxCode,
        itemId: parentWishList.parentuniqueId,
        category: parentWishList.category,
        item: Object.assign({}, childData, {
          itemId: wishlist.uniqueId,
          productCode: wishlist.travelBoxCode,
          category: wishlist.category,
        }),
      },
    ],
  };
};

const triggerWishlistErrorModal = (wishlistErrType) => {
  try {
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      dataToAppend: renderWishlistErrorModal(wishlistErrType),
      customClass: 'wishlist-popup',
    });
  } catch (error) {
    return error;
  }
};

const WishlistIcon = ({ data: propsData, isLoginWishList, wishlistAnalyticsData }) => {
  const isUserLoggedIn = Object.keys(getLoginUser()).length > 0;
  const sameAsParent = propsData.wishlist.sameAsParent;
  let itemId = sameAsParent ? propsData.wishlist.parentWishList.parentuniqueId : propsData.wishlist.uniqueId;

  const [isActive, setIsActive] = useState(false);
  const [enableYasIDVersion, setEnableYasIDVersion] = useState(false);

  useEffect(() => {
    setIsActive(isInWishlist(propsData, itemId));
    window.PubSub.subscribe(UIConfig.events.WISHLIST_REFRESH, () => {
      setIsActive(isInWishlist(propsData, itemId));
    });

    const enableYasIDVersion = resolvePath(getMainObject(), 'additionalProperty.enableYasIDVersion', false);
    setEnableYasIDVersion(Boolean(enableYasIDVersion));

    return () => {
      window.PubSub.unsubscribe(UIConfig.events.WISHLIST_REFRESH);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLoginModal = () => {
    const data = getWishListPopupData('wishlistLogin');
    return (
      <div>
        <h4>{decodeHtmlEntity(data.title)}</h4>
        <DynamicContent tagName="div" attrs={{ className: 'body-copy' }} innerHtml={data.bodyCopy} />
        <div className="btn-primary">
          {enableYasIDVersion ? (
            <button onClick={() => openIAMOverlay(<IAMPopup loginURL={data.primaryCTA.href} />)}>
              {data.primaryCTA.label}
            </button>
          ) : (
            <AnchorLink link={data.primaryCTA} />
          )}
        </div>
        <div className="btn_secondary">
          <button onClick={closeModal}>{data.secondaryCTA}</button>
        </div>
      </div>
    );
  };

  const addToWishlist = (data) => {
    itemId = data.wishlist[0].item && data.wishlist[0].item.itemId;
    const parentId = data.wishlist[0].itemId;
    const wishlistIdPresent = !!ServiceConfig.getWishlistID();
    const isWishlistHaveZero =
      !!canUseDOM() && localStorage.wishlistData && Object.keys(JSON.parse(localStorage.wishlistData)).length === 0;
    setIsActive(true);

    const { item } = data.wishlist[0];
    item && item.desc && (item.desc = item.desc.replace(/style='.*'/, ''));

    if (!isUserLoggedIn && !wishlistIdPresent && isWishlistHaveZero) {
      try {
        window.PubSub.publish('toggleOverlayState', {
          shouldOpen: true,
          dataToAppend: renderLoginModal(),
          customClass: 'wishlist-popup',
        });
      } catch (error) {
        return error;
      }
    }

    Wishlist.PostWishlist(data, 'wishlist', itemId, parentId)
      .then(() => {
        addWishlistAnalytics(parseAnalyticsData(propsData, isLoginWishList));
        wishlistAnalyticsData('addTowishlist');
      })
      .catch((response) => {
        setIsActive(false);
        triggerWishlistErrorModal('wishlistAddError');
      });
  };

  const deleteWishlist = () => {
    setIsActive(false);
    closeModal();

    Wishlist.DeleteWishlist([itemId], 'wishlist')
      .then((response, status) => {
        removeWishlistAnalytics(parseAnalyticsData(propsData, isLoginWishList));
        wishlistAnalyticsData('removeFromwishlist');
        setIsActive(false);
        Wishlist.GetWishlist('wishlist');
      })
      .catch((response) => {
        setIsActive(true);
        triggerWishlistErrorModal('wishlistRemoveError');
      });
  };

  const removeFromWishlist = () => {
    if (sameAsParent) {
      try {
        window.PubSub.publish('toggleOverlayState', {
          shouldOpen: true,
          dataToAppend: renderParentDeleteModal(),
          customClass: 'wishlist-popup',
        });
      } catch (error) {
        deleteWishlist();
      }
    } else {
      deleteWishlist();
    }
  };

  const handleKeyDown = (e) => {
    if (e.which === 13) {
      onClickWishlistIcon(e);
    }
  };

  const onClickWishlistIcon = (e) => {
    e.stopPropagation();
    if (isActive) {
      removeFromWishlist();
    } else {
      addToWishlist(parseWishListData(propsData, isLoginWishList));
    }
  };

  const renderParentDeleteModal = () => {
    const data = getWishListPopupData('wishlistDelete2');
    return (
      <div>
        <h4>{data.title}</h4>
        <DynamicContent tagName="div" attrs={{ className: 'body-copy' }} innerHtml={data.bodyCopy} />
        <div className="btn-primary">
          <button onClick={deleteWishlist}>{data.primaryCTA.label}</button>
        </div>
        <div className="btn_secondary">
          <button onClick={closeModal}>{data.secondaryCTA}</button>
        </div>
      </div>
    );
  };

  if (!propsData || (isLoginWishList && !isUserLoggedIn)) {
    return null;
  }

  try {
    return (
      <div className="wishlist-icon-component">
        <div className="wishlist-wrapper">
          <div
            className={`wishlist-icon ${isActive ? 'active' : ''}`}
            onClick={onClickWishlistIcon}
            tabIndex="0"
            onKeyDown={handleKeyDown}
            aria-label={`${propsData.childData.title} Add to wishlist`}
          />
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'WishlistIcon');
  }
};

WishlistIcon.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default WishlistIcon;
