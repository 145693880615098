import React, { useState, useEffect } from 'react';
import { getLoginUser, isEmailVerificationEnabled } from '../../../common/utility';
import { AnchorLink } from '../../presentation/base';
import UIConfig from '../../../common/UIConfig';
import { canUseDOM, checkParksTenants, isMobileOrTab } from '../../../common/utility';
import './email-verification-confirmation.scss';

function EmailVerificationConfirmation(data) {
  const {
    SuccessIcon,
    SuccessMessage,
    LoggedInUserCTA,
    AnonymousUserCTA,
    DownloadAppTitle,
    FailureIcon,
    FailureMessage,
    ResendEmailSuccessText,
    AppleStoreIcon,
    AppleStoreLink,
    GooglePlayStoreIcon,
    GooglePlayStoreLink,
    AnonymousUserSignInText,
    SecondaryCTA,
    MobileAppCTA,
  } = data;

  const isUserLoggedIn = Object.keys(getLoginUser()).length > 0 ? true : false;
  const [urlParams, setUrlParams] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const mainObj = canUseDOM() && JSON.parse(localStorage.getItem(UIConfig.appLocalStorageKeys.mainObj));
  const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();
  const isParks = checkParksTenants();
  const isEmailVerifEnabled = isEmailVerificationEnabled();

  useEffect(() => {
    setUrlParams(new URLSearchParams(window.location.search));
    setEmailSent(localStorage.getItem(UIConfig.localStoreKeys.emailVerification.emailVerifLinkSent));
    window.PubSub.subscribe(UIConfig.events.EMAIL_VERIFIED, (msg, newData) => {
      setIsEmailVerified(newData);
    });
    return () => {
      window.PubSub.unsubscribe(UIConfig.events.EMAIL_VERIFIED);
    };
  }, []);

  const renderDownloadAppSection = () => {
    return (
      <>
        <div className="email-verification-success__download-app-title">{DownloadAppTitle.value}</div>
        <div className="email-verification-success__apps-block">
          <AnchorLink
            className="email-verification-success__apps-block__apple-store"
            link={{
              ariaLabel: AppleStoreLink.value.url,
              href: AppleStoreLink.value.href,
              label: `<img
                        src=${AppleStoreIcon.value.src}
                        alt=${AppleStoreIcon.value.alt}
                    />`,
              target: AppleStoreLink.value.target,
            }}
          />

          <AnchorLink
            className="email-verification-success__apps-block__play-store"
            link={{
              ariaLabel: GooglePlayStoreLink.value.url,
              href: GooglePlayStoreLink.value.href,
              label: `<img
                        src=${GooglePlayStoreIcon.value.src}
                        alt=${GooglePlayStoreIcon.value.alt}
                    />`,
              target: GooglePlayStoreLink.value.target,
            }}
          />
        </div>
      </>
    );
  };

  const renderSuccessSection = () => {
    const { text: loggedInCTAText, href: loggedInHref } = LoggedInUserCTA.value;
    const { text: anonymousCTAText, href: anonymousHref } = AnonymousUserCTA.value;
    const { text: MobileAppCTAText, href: MobileAppCTAHref } = MobileAppCTA.value;

    return (
      <div className="email-verification-success">
        <div
          className={
            isParks
              ? 'email-verification-success__image-title-wrapper-row'
              : 'email-verification-success__image-title-wrapper-col'
          }
        >
          <img className="email-verification-success__success-icon" src={SuccessIcon.value.src} />
          <div className="email-verification-success__success-msg">{SuccessMessage.value}</div>
        </div>
        <div className="email-verification-success__logged-in-cta">
          <div className="btn-primary-tenant">
            <AnchorLink
              link={{
                label:
                  UIConfig.tenants.yi && isMobileOrTab()
                    ? MobileAppCTAText
                    : isUserLoggedIn
                    ? loggedInCTAText
                    : anonymousCTAText,
                href:
                  UIConfig.tenants.yi && isMobileOrTab()
                    ? MobileAppCTAHref
                    : isUserLoggedIn
                    ? loggedInHref
                    : anonymousHref,
              }}
              className="primary-cta"
            />
          </div>
        </div>
        {tenantId === UIConfig.tenants.yi && renderDownloadAppSection()}
      </div>
    );
  };

  const renderExpiredSection = () => {
    const { text: loggedInCTAText, href: loggedInHref } = LoggedInUserCTA.value;
    // const { text: secondaryCTAText, href: secondaryHref } = SecondaryCTA.value;
    const { text: anonymousCTAText, href: anonymousHref } = AnonymousUserCTA.value;
    const { text: MobileAppCTAText, href: MobileAppCTAHref } = MobileAppCTA.value;
    return (
      <div className="email-verification-failure">
        <div
          className={
            isParks
              ? 'email-verification-failure__image-title-wrapper-row'
              : 'email-verification-failure__image-title-wrapper-col'
          }
        >
          <img className="email-verification-failure__failure-icon" src={FailureIcon.value.src} />
          <div className="email-verification-failure__failure-msg">{FailureMessage.value}</div>
        </div>
        {!isUserLoggedIn && (
          <div className="email-verification-failure__logged-out-msg">{AnonymousUserSignInText.value}</div>
        )}
        <div className="email-verification-failure__logged-in-cta">
          <div className="btn-primary-tenant">
            <AnchorLink
              link={{
                label:
                  tenantId === UIConfig.tenants.yi && isMobileOrTab()
                    ? MobileAppCTAText
                    : isUserLoggedIn
                    ? loggedInCTAText
                    : anonymousCTAText,
                href:
                  tenantId === UIConfig.tenants.yi && isMobileOrTab()
                    ? MobileAppCTAHref
                    : isUserLoggedIn
                    ? loggedInHref
                    : anonymousHref,
              }}
              className="primary-cta"
            />
          </div>
        </div>

        {tenantId === UIConfig.tenants.yi && renderDownloadAppSection()}
      </div>
    );
  };

  if (isEmailVerified) {
    return <></>;
  }

  if (isEmailVerifEnabled && urlParams?.get('status')?.toLowerCase() === 'false') {
    return <div className="email-verification-confirmation">{renderExpiredSection()}</div>;
  }

  if (isEmailVerifEnabled && urlParams?.get('status')?.toLowerCase() === 'true') {
    return <div className="email-verification-confirmation">{renderSuccessSection()}</div>;
  }

  return <></>;
}

export default EmailVerificationConfirmation;
