import className from 'classnames';
import React, { useEffect, useState } from 'react';

import { DynamicContent } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';
import { isTicketUpgradeJourney, getAnonymousCart } from '../../../common/utility';

const createStepsVariation = (data) => {
  return isTicketUpgradeJourney()
    ? null
    : data.map((item, index) => {
        const divClasses = className('step tab');
        const hrefClasses = className('icons', {
          payments: index === 1,
          tickets: index === 0,
        });
        const activeClasses = className({ active: item['currentTab'] });
        return (
          <div className={divClasses} key={index}>
            <a
              href={item.cta.href}
              className={activeClasses}
              title={item.cta.title}
              aria-label={activeClasses ? 'selected' : ''}
              target={item.cta.target}
            >
              <span className={hrefClasses} />
              <DynamicContent
                tagName="span"
                attrs={{
                  className: className('tab-label'),
                }}
                innerHtml={item.tabTitle}
              />
            </a>
          </div>
        );
      });
};

const ProgressBar = (props) => {
  const [isTicketUpgrade, setisTicketUpgrade] = useState();

  useEffect(() => {
    const anonymousCart = getAnonymousCart();
    setisTicketUpgrade(anonymousCart?.cart?.isTicketUpgradeCart);
  }, []);

  try {
    return isTicketUpgrade ? (
      <></>
    ) : (
      <div className={`c-progress-bar ${isTicketUpgrade ? 'd-none' : ''}`}>
        <nav className="c-progress-bar-wrapper">
          <div className="steps">
            {Array.isArray(props.data.progressBarItems) && props.data.progressBarItems.length
              ? createStepsVariation(props.data.progressBarItems)
              : null}
          </div>
        </nav>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ProgressBar');
  }
};

export default ProgressBar;
