import React from 'react';
import { logComponentRenderingError } from '../../../common/logger';
import { resolvePath } from '../../../common/utility';
import { getJSSFieldSectionErrors, getFieldValue } from '../../../common/forms-utility';
import { componentMapping, customSectionMapping } from './jss-form-component-mapping';

const FormSubSection = (props) => {
  const field = props.field;

  const sectionHandler = () => {
    const itemType = field.model.name;
    let Component = null;
    const { apiFieldValue } = props;
    const itemTypeArr = itemType.split('-');
    // format Cascade-0, AgeValidator-0
    if (itemType && customSectionMapping[itemTypeArr[0]]) {
      Component = customSectionMapping[itemTypeArr[0]];
      return <Component {...props} errors={props.errors} apiFieldValue={apiFieldValue} />;
    } else if (resolvePath(field, 'fields', []).length) {
      return field.fields.map((item) => {
        const errors = getJSSFieldSectionErrors(item, props.errors);
        const type = resolvePath(item, 'model.fieldTypeItemId', '');
        if (type && componentMapping[type]) {
          Component = componentMapping[type];
          const fieldValue = getFieldValue(item, apiFieldValue);
          return <Component {...props} field={item} errors={errors} apiFieldValue={fieldValue} />;
        }
      });
    }
  };

  try {
    return sectionHandler();
  } catch (err) {
    return logComponentRenderingError(err, 'FormSubSection');
  }
};

export default FormSubSection;
