export const CartModelProduct = {
  cart: {
    items: [
      {
        productId: 'FPK.EVN23.MCC25',
        productName: '1 Day Standard Ticket',
        classType: 'ADULT',
        classTypeName: 'Adult',
        ticketVal: '1DAY',
        ticketType: 'STN',
        ticketTypeName: 'STN',
        itemType: 'REG',
        gross: '295.0',
        net: '0',
        currency: 'AED',
        parksKey: ['WBW'],
        parks: ['Warner Bros. World™'],
        templatename: 'ProductB2C',
        language: 'en',
        isDeleted: '0',
        code: 'TTW000001N1ALAW',
        pricing: 'STAN',
        accessPolicy: '1EAD',
        category: ['1-Day-1-Park'],
        tab: 'GeneralAdmission_WB',
        validityFrame: '6MON',
        validityFrameValues: '6MON',
        associatedProdId: 'TTW000001N1ALAW',
        disabledProduct: '0',
        discount: {
          actualPerc: 0,
          computed: 0,
        },
        unitPrice: '295.0',
        isFlexible: true,
        fromDate: '2019-01-27',
        quantity: 2,
        rangeInMonths: 6,
        toDate: '2019-07-26',
        productType: 'GeneralAdmission_WB',
        productIdType: 'GeneralAdmission_WBREG1DAY2019-01-272019-07-26STN1EADWBW1-Day-1-Park',
        price: {
          gross: 590,
          net: 590,
          tax: 0,
        },
        performanceId: null,
        guestDetails: null,
        currQuantity: 2,
        groupedQuantity: 2,
        invalidQuantity: false,
      },
    ],
    currency: 'AED',
    tenantId: 'WBWB2C',
    userLang: 'en',
    totalQuantity: 2,
    subTotal: {
      total: 295,
    },
    couponCode: '',
    totalDiscount: 0,
    netPrice: 590,
    grossPrice: 590,
    totalTax: 0,
    reservationOwner: null,
    promotions: null,
    isTicketUpgradeCart: false,
  },
  updatedAt: 1548582444950,
};
