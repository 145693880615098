/*
 * dynamic-content-components.js
 * This file contains code for DynamicContent component. on e can create tags like label, div etc.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * DynamiContent is a component which render the provided html dynamically, we can use this
 * component in place of using dangerouslySetInnerHTML.
 * @param    {string}    tagName     wrapper html element for the provided html.
 * @param    {object}    attrs       html attributes of the wrapper element.
 * @param    {string}    innerHtml   inner html contents of the element.
 * @return   {[Object]}  Return a react element.
 */
const DynamicContentTagReplace = ({ tagName, attrs, innerHtml, replace, replaceWith, empty }) => {
  try {
    if (innerHtml) {
      if (replace && replaceWith) {
        let flag = 0;
        let lastIndex;

        if (empty) {
          while (innerHtml.indexOf(replace) !== -1) {
            innerHtml = innerHtml.replace(replace, `<${replaceWith}/>`);
          }
        } else {
          while (innerHtml.indexOf(replace) !== -1) {
            lastIndex = innerHtml.indexOf(replace);

            innerHtml = innerHtml.replace(replace, `<${flag ? '/' : ''}${replaceWith}>`);
            flag = !flag;
          }
        }
        if (!empty && flag) {
          innerHtml =
            innerHtml.substr(0, lastIndex) + replace + ' ' + innerHtml.substr(lastIndex + `</${replaceWith}>`.length);
        }
        return <DynamicContent tagName={tagName} innerHtml={innerHtml} attrs={attrs} />;
      }
    }
    return null;
  } catch (err) {
    return logComponentRenderingError(err, DynamicContentTagReplace);
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
DynamicContentTagReplace.propTypes = {
  tagName: PropTypes.string.isRequired,
  innerHtml: PropTypes.string.isRequired,
  attrs: PropTypes.object,
  replace: PropTypes.string,
  replaceWith: PropTypes.string,
  empty: PropTypes.bool,
};

export default DynamicContentTagReplace;
