import React from 'react';
import { DynamicContent } from '../../base';

const LabelComponent = (props) => {
  const field = props.field;
  return (
    <DynamicContent
      tagName="label"
      innerHtml={field.model.title}
      attrs={{ htmlFor: field.valueField.id, className: 'form-label' }}
    />
  );
};

export default LabelComponent;
