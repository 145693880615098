/*
 * table-row-component.js
 * This file contains code for Table Row component, It is a dumb component,it is a generic component, which will render tr as per passed configuration, No variations
 * @licensor  Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import SvgSprite from '../../presentation/base/svg-sprite/svg-sprite-component';
import { roundToTwoPlaces, toTwoDecimalPlaces } from '../../../common/utility';

const ticketIcon = {
  Silver: 'icn-ticket-SLV',
  Bronze: 'icn-ticket-BRZ',
  Gold: 'icn-ticket-GLD',
};

const makeThemeParkName = (data = {}, coveoMapping = {}) => {
  const productName = data[coveoMapping.productName] || '';
  return productName;
};

const isGuestDetailTicket = (ticketData) => {
  return ticketData && ticketData.title && ticketData.title.indexOf(':') > -1;
};

const getTicketName = (ticketData, guestDetailTicket) => {
  return guestDetailTicket ? ticketData.title.split(':')[1] : ticketData;
};

const getTicketType = (ticketData, ticketName, row, coveoMapping, isGuestDeatil) => {
  return isGuestDeatil ? makeThemeParkName(ticketData, coveoMapping) : ticketName ? ticketName : row['productName'];
};

const TableRow = ({ columns, row, coveoMapping, isGuestDeatil = false }) => {
  return (
    <tr className={row.className ? row.className : ''}>
      {columns.map((column, idx) => {
        if (column.key === 'ticket') {
          const ticketData = row[column.key];
          const guestDetailTicket = isGuestDetailTicket(ticketData);
          const ticketName = getTicketName(ticketData, guestDetailTicket);
          const ticketType = getTicketType(ticketData, ticketName, row, coveoMapping, isGuestDeatil);
          const themeParkName = guestDetailTicket ? ticketData.title.split(':')[0] : '';
          const ticketTypeIcon = guestDetailTicket ? ticketData.ticketIcon : ticketName;

          return (
            <td key={idx} className={'body-copy-5 ticket column-' + idx}>
              <span className="labels heading-7">{column.label + ': '}</span>
              {row[column.key] !== undefined && ticketIcon[ticketTypeIcon] ? (
                <SvgSprite id={ticketIcon[ticketTypeIcon]} />
              ) : (
                ''
              )}
              <div className="description body-copy-4">
                <span className="ticketType">
                  <span className="themeParkName heading-7">{themeParkName}</span>
                  <span className="heading-7"> {ticketType} </span>
                </span>
              </div>
            </td>
          );
        } else if ((column.key === 'subTotal' || column.key === 'price') && row[column.key] !== undefined) {
          const subtotal = row[column.key].split(' '),
            price = subtotal.pop(),
            currency = row[column.key].replace(price, '');

          return (
            <td key={idx} className={'column-' + idx}>
              <span className="labels heading-7">{column.label + ': '}</span>
              <span className="currency body-copy-8">{currency}</span>
              <span className="price body-copy-5">{toTwoDecimalPlaces(roundToTwoPlaces(price))}</span>
            </td>
          );
        } else {
          return (
            column.label && (
              <td key={idx} className={'body-copy-3 column-' + idx}>
                <span className="heading-7 labels">{column.label + ': '}</span>
                {row[column.key]}
              </td>
            )
          );
        }
      })}
    </tr>
  );
};
export default TableRow;

/**
 * Used to define the proptypes that will be received by the component.
 */

TableRow.propTypes = {
  columns: PropTypes.array,
  row: PropTypes.object,
};
