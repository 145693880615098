/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFormattedTime, getYasIdGTMData, getEmailIdGTMData } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { DynamicContent, Spinner } from '../../../presentation/base';

/**
 *  Arena Tour - Product Overlay
 */
export default class TourOverlayComponent extends Component {
  constructor(props) {
    super(props);
    const { add, update } = props.labels;

    this.availability = { ...props.performanceData.availability };
    this.state = {
      total: 0,
      products: [...props.products],
      allowAddUpdate: !props.products.filter((prod) => prod.quantity > 0).length,
      hasMaxAvailabilityError: false,
    };
  }

  componentDidMount() {
    this.calculateTotal();
  }

  // set the latest quantity provided in state
  updateQuantity = (val, key) => {
    const products = [...this.state.products];
    const product = products.find((prod) => prod.productId === key);
    product.quantity = val;

    let totalQuantity = products.reduce((total, product) => {
      return total + product.quantity;
    }, 0);

    const allowAddUpdate = totalQuantity === 0;
    const hasMaxAvailabilityError = this.getQuantityWithMultiplier(products) > this.availability.available;

    this.setState({ products: [...products], allowAddUpdate, hasMaxAvailabilityError }, this.calculateTotal);
  };

  /**
   *  Get the Total quantity * tourGroupQuantity
   *  @param {Object} products - collection of Non Seated Products
   *  @returns {Number} total quantity multiplied by group quantity
   */
  getQuantityWithMultiplier = (products) => {
    let totalQuantity = products.reduce((total, product) => {
      const quantity = product.quantity * product.tourGroupQuantity;
      return total + quantity;
    }, 0);

    return totalQuantity;
  };

  /**
   *  Calculate total price of selected products
   *  and  save to state
   */
  calculateTotal = () => {
    const total = this.state.products.reduce((total, product) => {
      return total + (product.gross ? product.quantity * product.gross : 0);
    }, 0);

    this.setState({ total });
  };

  /**
   *  Save/update selected quantity
   *
   */
  addToCart = () => {
    this.props.addToCart(this.state.products);
  };

  /* PushEventFlags method will push the object into the datalayer */
  pushEventFlags = () => {
    const { title, date, openTime, closeTime, performanceId } = this.props.performanceData;

    const { total } = this.state;
    const { products } = this.state;
    const metric = {};

    window.dataLayer = window.dataLayer || [];

    products.forEach((product, i) => {
      metric[`metric${i + 1}`] = parseFloat(product.quantity);
    });

    window.dataLayer.push({
      event: 'addToCart',
      myPassId: getYasIdGTMData(),
      emailId: getEmailIdGTMData(),
      ecommerce: {
        currencyCode: 'AED',
        add: {
          products: [
            {
              name: title,
              id: products[0].eventId[0],
              category: '<event category>',
              price: parseFloat(total),
              dimension8: date,
              dimension10: `${getFormattedTime(openTime)} - ${getFormattedTime(closeTime)}`,
              dimension11: performanceId,
              ...metric,
            },
          ],
        },
      },
    });
  };

  render() {
    let currency;
    const { closeOverlay, labels } = this.props;
    const { products, allowAddUpdate, hasMaxAvailabilityError } = this.state;
    const { title, cancel, total, maxAvailabilityError, add, update } = labels;
    const actionButtonText = this.props.mode === UIConfig.b2c.purchaseJourney.yaTourOverlay.add ? add : update;

    return (
      <div className="overlay-data">
        <DynamicContent
          tagName="h2"
          attrs={{ className: 'overlay-title heading-2', id: 'overlay-title' }}
          innerHtml={title}
        />
        <div className="overlay-body">
          {products.length > 0 && (
            <div className="event-overlay-content">
              {products.map((product) => {
                currency = product.currency;

                const id = product.productId;

                return (
                  <div className="event-overlay-item" key={product.productId}>
                    <div className="event-overlay-cell">{product.productName}</div>
                    <div className="event-overlay-cell">
                      {currency}&nbsp;&nbsp;{product.gross}
                    </div>
                    <div className="event-overlay-cell">
                      <Spinner
                        eventKey={id}
                        isInputDisabled="true"
                        min={0}
                        max={product.AddonQuantity}
                        quantity={product.quantity}
                        updateQuantity={this.updateQuantity}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="event-overlay-item total">
                <div className="event-overlay-cell">{total}</div>
                <div className="event-overlay-cell">
                  <span>{currency}</span>&nbsp;&nbsp;
                  <span>{this.state.total}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        {hasMaxAvailabilityError && maxAvailabilityError && (
          <div id="maxAvailabilityError" className="maxAvailabilityError">
            {maxAvailabilityError.replace('{0}', this.availability.available)}
          </div>
        )}
        <div className="action-line">
          <div className="btn-primary">
            <button type="button" onClick={this.addToCart} disabled={allowAddUpdate || hasMaxAvailabilityError}>
              {actionButtonText}
            </button>
          </div>
          <div className="btn-secondary">
            <button type="button" onClick={closeOverlay}>
              {cancel}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

TourOverlayComponent.PropsTypes = {
  closeOverlay: PropTypes.func,
  products: PropTypes.object.isRequired,
  performanceData: PropTypes.object,
  mode: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};
