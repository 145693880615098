import React from 'react';
import { createDeepCopy, checkTenant } from '../../../../../common/utility';
import DynamicContent from '../../../../presentation/base/dynamic-content/dynamic-content-component';
import UIConfig from '../../../../../common/UIConfig';

const index = ({ context }) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  let combinedCalendarLabel = {};
  let colorMap = {};
  let commonCalLabel = !context.isDynamicPricingProd ? context.props.data.calenderLabels : '';
  if (context.isMultiProductCal) {
    if (context.isDynamicPricingProd) {
      const productListForCalendarLabel =
        context.props.copySearchProductList && createDeepCopy(context.props.copySearchProductList);
      Array.isArray(productListForCalendarLabel) &&
        productListForCalendarLabel.length > 0 &&
        Array.isArray(context.legends) &&
        context.legends.length > 0 &&
        productListForCalendarLabel
          .sort((a, b) => a.gross - b.gross)
          .forEach((calProd) => {
            if (calProd.hasOwnProperty('productId') && context.productsArr.includes(calProd.productId)) {
              const legendObj = context.legends.find((item) => item.productId === calProd.productId);
              if (legendObj) {
                // removed applied color only for swad
                combinedCalendarLabel[calProd.productId] =
                  legendObj.priceLabel +
                  ' - ' +
                  `<span class="${isSwad ? 'amout-container' : ''}" style="${
                    !isSwad ? 'color:' + legendObj.colorCode : ''
                  }">${calProd.currency} ${calProd.gross}</span>`;
                colorMap[calProd.productId] = legendObj.colorCode;
              }
            }
          });
    } else {
      const calendProds = context.productOverlaySelector.collections || [];
      calendProds.forEach((calProd) => {
        if (calProd.hasOwnProperty('cssClassKey')) {
          combinedCalendarLabel[calProd.cssClassKey] = calProd.title;
        }
      });
    }
    combinedCalendarLabel = {
      ...combinedCalendarLabel,
      ...commonCalLabel,
    };
    if (combinedCalendarLabel.hasOwnProperty('availabilityLabel')) {
      delete combinedCalendarLabel.availabilityLabel;
    }
  } else {
    combinedCalendarLabel = commonCalLabel;
  }
  const calenderLabels = Object.keys(combinedCalendarLabel);
  return (
    <>
      {calenderLabels && calenderLabels.length > 0 && (
        <div className="calendar-labels">
          {calenderLabels &&
            calenderLabels.map((item) => (
              <div className="calendar-label">
                <span
                  className={`calendar-label-icon ${item}-icon`}
                  style={{ backgroundColor: colorMap[item] ? colorMap[item] : 'unset' }}
                />
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'calendar-label-title' }}
                  innerHtml={combinedCalendarLabel[item]}
                />
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default index;
