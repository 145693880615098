import React from 'react';
import DynamicContent from '../../../../presentation/base/dynamic-content/dynamic-content-component';
import RichText from '../../../../presentation/base/rich-text/rich-text-component';

const B2BPaymentCheckbox = ({ stateData, paymentOptions, onTncChangeHandler, onClickHandler }) => {
  return (
    <div>
      <div className="c-payment-tnc">
        <input type="checkbox" id="paymentTnC" checked={stateData.isTncChecked} onChange={onTncChangeHandler} />
        <label htmlFor="paymentTnC" className="heading-5">
          <RichText
            data={{
              bodyCopy: paymentOptions.tAndcLabel,
              attrs: { className: 'body-copy-4' },
            }}
          />
        </label>
      </div>
      <div className={stateData.proceedToPay ? 'c-payment-btn-show' : 'c-payment-hide-btn'}>
        <div className={stateData.isTncChecked ? 'c-payment-btn' : 'c-payment-btn c-payment-btn-disabled'}>
          {paymentOptions.primaryCTA.html ? (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'btn btn-hero-cta', onClick: onClickHandler }}
              innerHtml={paymentOptions.primaryCTA.html}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default B2BPaymentCheckbox;
