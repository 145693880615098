import { analyticsConfig } from './analytics-config';
import { PendingSearchEvent } from './pending-search-event';
import { AnalyticsService } from './analytics-service';
import { getOrigin } from '../utility';

export class AnalyticsClient {
  /**
   * Logs Search Event before sending it for analytics purpose
   * @param {Object} actionCause
   * @param {Object} meta
   * @param {Object} queryObj of type { config, Promise}
   */
  static logSearchEvent(actionCause, meta, queryObj) {
    return AnalyticsClient.pushSearchEvent(actionCause, meta, queryObj);
  }

  /**
   *
   * @param actionCause
   * @param meta
   * @param result
   * @returns {Promise} Promise
   */
  static logClickEvent(actionCause, meta, result) {
    const metaObject = AnalyticsClient.buildMetaObject(meta, result);
    return AnalyticsClient.pushClickEvent(actionCause, metaObject, result);
  }

  /**
   *
   * @param {Object} actionCause
   * @param {Object} meta
   * @returns {Promise}  Promise
   */
  static logCustomEvent(actionCause, meta) {
    return AnalyticsClient.pushCustomEvent(actionCause, meta);
  }

  /**
   *
   * @param  actionCause
   * @param  metaObject
   * @param  {Object} queryObj of type { config, Promise}
   */
  static pushSearchEvent(actionCause, metaObject, queryObj) {
    if (AnalyticsClient.pendingSearchEvent && AnalyticsClient.pendingSearchEvent.getEventCause() !== actionCause.name) {
      AnalyticsClient.pendingSearchEvent = null;
    }
    if (!AnalyticsClient.pendingSearchEvent) {
      const searchEvent = AnalyticsClient.buildSearchEvent(actionCause, metaObject);
      AnalyticsClient.pendingSearchEvent = new PendingSearchEvent(
        AnalyticsService,
        searchEvent,
        queryObj,
        analyticsConfig.sendToCloud,
      );

      setTimeout(() => {
        // The pending search event will finish processing automatically
        AnalyticsClient.pendingSearchEvent = null;
      }, 0);
    }
  }

  /**
   *
   * @param actionCause
   * @param metaObject
   * @param result
   * @return {Promise}
   */
  static pushClickEvent(actionCause, metaObject, result) {
    const event = AnalyticsClient.buildClickEvent(actionCause, metaObject, result);
    return analyticsConfig.sendToCloud ? AnalyticsService.sendDocumentViewEvent(event) : Promise.resolve(null);
  }

  /**
   * Calls the Analytics API end points for sending Custom Event for tracking
   * @param {Object} actionCause
   * @param {Object} metaObject
   * @return {Promise} Promise - IAPIAnalyticsEventResponse
   */
  static pushCustomEvent(actionCause, metaObject) {
    const customEvent = AnalyticsClient.buildCustomEvent(actionCause, metaObject);
    return analyticsConfig.sendToCloud ? AnalyticsService.sendCustomEvent(customEvent) : Promise.resolve(null);
  }

  /**
   * Builds Generic Parameters required for calling Analytics API
   * @param actionCause
   * @param metaObject
   * @return {{actionCause: *, actionType: *, username: (string|*), userDisplayName: (string|*), anonymous: (boolean|*), device: *, mobile: *, language: string, responseTime: undefined, originLevel1: (string|*), originLevel2: string, originLevel3: string, originContext: string, customData: *, userAgent: string}}
   */
  static buildAnalyticsEvent(actionCause, metaObject) {
    const eventObj = {
      actionCause: actionCause.name,
      actionType: actionCause.type,
      anonymous: analyticsConfig.anonymous,
      device: analyticsConfig.device,
      mobile: analyticsConfig.mobile,
      language: analyticsConfig.language,
      responseTime: undefined,
      originLevel1: analyticsConfig.searchHub,
      originLevel2: analyticsConfig.originLevel2,
      originLevel3: getOrigin(),
      originContext: analyticsConfig.originContext,
      customData: metaObject && Object.keys(metaObject).length > 0 ? metaObject : undefined,
      userAgent: navigator.userAgent,
    };
    if (analyticsConfig.userId) {
      eventObj.username = analyticsConfig.userId;
    }
    if (analyticsConfig.userDisplayName) {
      eventObj.userDisplayName = analyticsConfig.userDisplayName;
    }
    return eventObj;
  }

  /**
   * Builds Parameters required for calling search analytics api
   * @param actionCause
   * @param metaObject
   */
  static buildSearchEvent(actionCause, metaObject) {
    return Object.assign(AnalyticsClient.buildAnalyticsEvent(actionCause, metaObject), {
      searchQueryUid: undefined,
      pipeline: undefined,
      queryText: undefined,
      advancedQuery: undefined,
      results: undefined,
      resultsPerPage: undefined,
      pageNumber: undefined,
      didYouMean: undefined,
      contextual: analyticsConfig.isContextual,
    });
  }

  /**
   * Builds Parameters required for calling click analytics api
   * @param actionCause
   * @param metaObject
   * @param result
   * @return {Object}
   */
  static buildClickEvent(actionCause, metaObject, result) {
    return Object.assign(AnalyticsClient.buildAnalyticsEvent(actionCause, metaObject), {
      searchQueryUid: result.searchUid,
      queryPipeline: result.pipeline,
      documentUri: result.sysuri,
      documentUriHash: result.sysurihash,
      documentUrl: result.documentUrl,
      documentTitle: result.documentTitle,
      collectionName: result.syscollection,
      sourceName: result.source,
      originLevel2: analyticsConfig.originLevel2,
      documentPosition: result.index + 1,
      responseTime: 0,
      viewMethod: actionCause.name,
      rankingModifier: result.rankingModifier,
    });
  }

  /**
   * Builds parameters required for calling custom event analytics api
   * @param actionCause
   * @param metaObject
   * @return {Object} Object containing parameters required for calling custom event analytics api
   */
  static buildCustomEvent(actionCause, metaObject) {
    return Object.assign(AnalyticsClient.buildAnalyticsEvent(actionCause, metaObject), {
      eventType: actionCause.type,
      eventValue: actionCause.name,
      originLevel2: analyticsConfig.originLevel2,
      responseTime: 0,
    });
  }

  /**
   *
   * @param meta
   * @param result - Query Result
   */
  static buildMetaObject(meta, result) {
    if (result) {
      meta = meta || {};
      meta['contentIDKey'] = 'urihash';
      meta['contentIDValue'] = result.urihash;
    }
    return meta;
  }
}
