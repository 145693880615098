/*
 * rich-text-components.js
 * This file contains code for RichText component which will render html from backend.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * RichText is a component which render the provided html from backend
 * @param    {string}    object     wrapper html element for the provided html.
 * @return   {[Object]}  Return a react element.
 */

const RichText = ({ data }) => {
  try {
    return <DynamicContent tagName="div" attrs={data.attrs} innerHtml={data.bodyCopy} />;
  } catch (err) {
    return logComponentRenderingError(err, 'RichText');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */

RichText.propTypes = {
  data: PropTypes.shape({
    bodyCopy: PropTypes.string.isRequired,
    attrs: PropTypes.object,
  }),
};

export default RichText;
