/*
 * hero-panel-components.js
 * This file contains code for Hero Panel component, it renders hero Component
 * @licensor  Miral
 */
import React, { Suspense, useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import HeroBanner from '../../presentation/hero-banner-component';
import HeroCard from '../../presentation/hero-card-component';
import HeroTile from '../../presentation/hero-tile';
import { AnchorLink, DynamicContent, SvgSprite } from '../../presentation/base';
import {
  addThisHandler,
  detectMobile,
  getLoginUser,
  getMainObject,
  isLoggedInUser,
  onElementHeightChange,
  isB2B,
  isMatchTenant,
  detectViewPort,
  resolvePath,
  canUseDOM,
  checkTenant,
  isLoginValid,
  getLoggedInUser,
  isEmailVerificationEnabled,
} from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';
import UIConfig from '../../../common/UIConfig';
import { bodyClicks } from '../../../common/analytics-events';
import Helmet from 'react-helmet';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const StickyFooter = lazy(() => import('../sticky-footer'));

const whichCurve = (variant) => {
  let curve = null;
  if (variant.match(/no-tiles/g)) {
    curve = <SvgSprite id="hero-panel-curve-no-tiles" styleClass="hero-panel-curve-no-tiles" />;
  } else {
    curve = <SvgSprite id="hero-panel-curve-with-tiles" styleClass="hero-panel-curve-with-tiles" />;
  }
  return curve;
};

const getCardList = (isHeroPanelWithCards, items) => {
  return isHeroPanelWithCards ? <HeroCard cards={items} /> : <HeroTile onclick={handleClick} tiles={items} />;
};

const handleClick = (tile) => {
  const { cta: { target, url } = {} } = tile;
  if (url) {
    window.open(url, target && '_self');
  }
};
const displayUserName = (text) => {
  const userInfo = getLoginUser();
  if (text.includes('{FirstName}')) {
    return userInfo?.userName;
  }
  if (userInfo.userName && userInfo.lastName) {
    return userInfo.userName + ' ' + userInfo.lastName;
  }
  if (userInfo.userName && userInfo.lastName) {
    return userInfo.userName + ' ' + userInfo.lastName;
  }
};

const componentTitle = (title, personalized) => {
  if (isTitle(title) && personalized && isLoggedInUser()) {
    const updatedTitle = title.text.replace(title.text.match(/\{\{([^}]*)\}\}/gi), displayUserName(title.text) || '');
    return (
      <DynamicContent tagName={title.heading || 'h2'} attrs={{ className: 'heading-1' }} innerHtml={updatedTitle} />
    );
  } else {
    return (
      title && (
        <DynamicContent tagName={title.heading || 'h2'} attrs={{ className: 'heading-1' }} innerHtml={title.text} />
      )
    );
  }
};

const isTitle = (title) => {
  return title && title.text.match(/\{\{([^}]*)\}\}/gi) && title.text;
};

const renderHeroPanel = (backgroundColor, body, heroCta, image, title, variant) => {
  return (
    <HeroBanner
      bgColor={backgroundColor && !detectMobile() ? backgroundColor : null}
      cta={heroCta}
      heroImage={image}
      title={title}
      titleDescription={body}
      variant={variant}
      parentComponent={UIConfig.component.heroPanel}
    />
  );
};

const HeroPanel = ({ data }) => {
  const { body, cards, heroCta } = data;
  const isVariationFound =
    data.variant && data.variant.split(' ').some((variant) => UIConfig.heroPanelVariations.includes(variant));
  const isHeroPanelWithCards = !isVariationFound;
  const [title, setTitle] = useState('');
  const [isB2BTenant, setIsB2BTenant] = useState(false);
  const [urlParams, setUrlParams] = useState(null);
  const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailVerifEnabled, setIsEmailVerifEnabled] = useState(false);

  useEffect(() => {
    window.PubSub.subscribe(UIConfig.events.EMAIL_VERIFIED, (msg, newData) => {
      setIsEmailVerified(newData);
      setIsEmailVerifEnabled(isEmailVerificationEnabled());
    });
    setUrlParams(new URLSearchParams(window.location.search));
    if (isHeroPanelWithCards) {
      window.onload = () => {
        onElementHeightChange(document.body, function() {
          addThisHandler();
        });
        addThisHandler();
      };

      window.onresize = () => {
        addThisHandler();
      };
    }
    let contactUsWrapper = document.getElementsByClassName('v-UserNotLoggedIn')[0] || '';
    if (checkfortenants && !isLoginValid() && contactUsWrapper) {
      let heroPanelWrapper = document.getElementsByClassName('hero-panel')[0];
      heroPanelWrapper && heroPanelWrapper.classList.add('v-UserNotLoggedIn');
    }

    setTitle(data.title);
    setIsB2BTenant(isB2B());
    return () => {
      window.PubSub.unsubscribe(UIConfig.events.EMAIL_VERIFIED);
    };
  }, []);

  const { userName, tenantID } = getLoggedInUser();

  const emailVerificationVariant = data.variant === 'v-email-verification';

  const userNameFromUrlParams = urlParams && urlParams?.get('userName');

  const getProcessedUsername = () => {
    let formattedUsername = '';
    if (data?.title?.text && (userName || userNameFromUrlParams)) {
      formattedUsername = data?.title?.text?.replace('{{FirstName}}', userName || userNameFromUrlParams);
    }
    return formattedUsername;
  };
  try {
    const { myProfile, emailVerification } = UIConfig.emailVerification.heroPanel.source;
    if (
      (isEmailVerifEnabled && emailVerificationVariant && data?.source === myProfile && !isEmailVerified) ||
      (emailVerificationVariant && data?.source === emailVerification)
    ) {
      return (
        <div className="hero-panel__email-verification">
          {data?.title?.text && (userName || userNameFromUrlParams) && (
            <Text
              tag="div"
              className="hero-panel__email-verification__body"
              field={{
                value: getProcessedUsername(),
                editable: getProcessedUsername(),
              }}
            />
          )}
        </div>
      );
    }
  } catch (err) {
    return logComponentRenderingError(err, 'HeroPanel', data.variant);
  }

  // we don't need to show hero panel in EA & YMC when the account is verified
  const hideHeroPanelBasedOnTenants = () => {
    return checkTenant(UIConfig.iamMapping.etihadarena) || checkTenant(UIConfig.iamMapping.ymc);
  };

  try {
    const { myProfile } = UIConfig.emailVerification.heroPanel.source;
    if (emailVerificationVariant && hideHeroPanelBasedOnTenants() && data?.source === myProfile) {
      return <></>;
    }
  } catch (err) {
    return logComponentRenderingError(err, 'HeroPanel', data.variant);
  }

  try {
    if (isHeroPanelWithCards) {
      const { backgroundColor, image } = data;

      const theme = data.theme || '';
      const textAlignment = data.textAlignment || '';
      const variant = data.variant || '';
      const mainObj = getMainObject();
      const contrastValue = mainObj && mainObj.applyTextShadow;

      return (
        <div
          className={classNames(
            'hero-panel-with-card component',
            { 'hero-panel-contain-card': cards && cards.length },
            theme,
            textAlignment,
            variant,
          )}
        >
          {contrastValue && (
            <DynamicContent
              tagName="style"
              innerHtml={`
              .hero-panel-with-card .hero-banner-container .title .hero-banner-title {
                text-shadow: 0 2px 4px rgba(0,0,0,.4);
              }
              .hero-panel-with-card .hero-banner-container .hero-banner-desc {
                text-shadow: 0 2px 4px rgba(0,0,0,.4);
              }
            `}
            />
          )}
          {image?.mobileImage?.src && (
            <Helmet>
              <link
                rel="preload"
                as="image"
                href={image?.mobileImage?.src}
                imagesrcset={image?.mobileImage?.src}
                imagesizes="50vw"
              />
            </Helmet>
          )}
          {image && renderHeroPanel(backgroundColor, body, heroCta, image, data.title, variant)}
          {getCardList(true, cards)}
          {detectViewPort() === UIConfig.viewportTypes.mobile && isMatchTenant(UIConfig.tenants.ymc) && (
            <Suspense fallback={<></>}>
              <StickyFooter links={[{ ...heroCta }]} />
            </Suspense>
          )}
        </div>
      );
    } else {
      const { personalized, shortTitle, variant } = data;
      const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
        if (checkfortenants) {
          bodyClicks(eventName, titleData, labelData);
        }
      };
      const getBodyHtml = () => {
        if (variant.match(/b2b-no-tiles/g)) {
          return <DynamicContent tagName="div" attrs={{ className: 'hero-panel--body' }} innerHtml={body} />;
        } else {
          return <DynamicContent tagName="h3" attrs={{ className: 'hero-panel--sub-title' }} innerHtml={body} />;
        }
      };

      return (
        <div
          data-c-render="universal"
          className={classNames(
            'hero-panel component',
            { ' container ': isSwad },

            variant,
          )}
        >
          <div className="hero-panel--content">
            <div className="w--content">
              {shortTitle && <DynamicContent tagName="p" innerHtml={shortTitle} attrs={{ className: 'heading-7' }} />}
              {componentTitle(title, personalized)}
              {!variant.match(/b2b-no-tiles/g) && cards && !cards.length && (
                <DynamicContent tagName="p" attrs={{ className: 'hero-panel--text' }} innerHtml={body} />
              )}
            </div>
          </div>
          <div
            className={classNames(
              'hero-panel--list-content',
              { hide: data.variant === 'profile-tabs' },
              { 'no-cards': !(cards && cards.length) },
            )}
          >
            {!variant.match(/no-curve/g) && !isB2BTenant && (
              <div
                id="hero-panel--curve-wrapper"
                className={classNames({ 'no-tiles-version': variant.match(/no-tiles/g) }, 'svgCurve')}
              >
                {whichCurve(variant)}
              </div>
            )}
            {!variant.match(/no-tiles/g) && <div className="w--content">{getCardList(false, cards)}</div>}
          </div>
          <div className="w--content">
            {body && (variant.match(/b2b-no-tiles/g) || (cards && cards.length > 0)) ? getBodyHtml() : null}
            {heroCta && (
              <div className="btn btn-hero-cta">
                <AnchorLink
                  link={{ ...heroCta }}
                  onClick={() => {
                    googleAnalyticHandlerbody(UIConfig.commonVariant.gaClickEvents.linkClick, title, heroCta.label);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  } catch (err) {
    return logComponentRenderingError(err, 'HeroPanel', data.variant);
  }
};

export default HeroPanel;

HeroPanel.propTypes = {
  data: PropTypes.shape({
    backgroundColor: PropTypes.string,
    body: PropTypes.string,
    cards: PropTypes.array,
    heroCta: PropTypes.object,
    image: PropTypes.object,
    tiles: PropTypes.array,
    personalized: PropTypes.bool,
    shortTitle: PropTypes.string,
    textAlignment: PropTypes.string,
    theme: PropTypes.string,
    title: PropTypes.shape({
      text: PropTypes.string,
      heading: PropTypes.string,
    }),
    variant: PropTypes.string,
  }),
};
