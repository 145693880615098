import React from 'react';
import { logComponentRenderingError } from '../../../../common/logger';
import Cart from '../cart/cart-component';

const MiniCartComponent = (props) => {
  try {
    return (
      <div className="c-b2c-mini-cart component">
        <Cart
          isHeaderMiniCart={props.isHeaderMiniCart}
          data={props.data}
          cartData={props.items}
          errObj={props.errObj}
          bErrObj={props.bErrObj}
          updateQuantity={props.updateQuantity}
          deleteProduct={props.deleteProduct}
          showClassType={props.showClassType}
          stickyCart={props.stickyCart}
          currencyCallBack={props.currencyCallBack}
          convertedCurrency={props.convertedCurrency}
          showMinicartCouponCode={props.showMinicartCouponCode}
          addOnsErrorProdArr={props.addOnsErrorProdArr}
          recommendationActions={props.recommendationActions}
          isRecommendationControlled={props.isRecommendationControlled}
          isYASIDVersionEnable={props.isYASIDVersionEnable}
          isYaNonSeatedJourney={props.isYaNonSeatedJourney}
          isRecommendationData={props.isRecommendationData}
          isPostPurchasedAddon={props.isPostPurchasedAddon}
          showToastNotification={props.showToastNotification}
          cartEmpty={props.cartEmpty}
          yasArenaCart={{
            deleteAndChange: props.yasArenaCart.deleteAndChange,
            isYasArenaView: props.yasArenaCart.isYasArenaView,
            eventName: props.yasArenaCart.eventName,
            availabilityMapping: props.yasArenaCart.availabilityMapping,
          }}
          recommendationUrl={props.recommendationUrl}
          isCartPaymentView={props.yasArenaCart.isYasArenaView}
          couponCode={{
            applyCouponCallback: props.couponCode.applyCouponCallback,
            removeCouponCallback: props.couponCode.removeCouponCallback,
            services: props.couponCode.services,
          }}
          isMiniCartEnabled={props.isMiniCartEnabled}
          isHeader={props.isHeader}
        />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'MiniCart');
  }
};

export default MiniCartComponent;
