import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFlexibleSelector from '../date-flexible-selector/date-flexible-selector-component';
import VisitorsCountSelector from '../visitors-count-selector/visitors-count-selector-component';
import TicketSelector from '../ticket-selector/ticket-selector-component';
import TierComponent from '../../../presentation/b2c-purchase-journey/annual-pass/tier-component';
import ErrorSummary from '../../../presentation/base/error-summary/error-summary-component';
import UIConfig from '../../../../common/UIConfig';
import { toTwoDecimalPlaces, momentTimezone, getClientUtcOffset } from '../../../../common/utility';
import { some, get, set } from 'lodash';
import moment from 'moment';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';

export default class GeneralAdmission extends Component {
  constructor(props) {
    super(props);

    const generalAdmissionObj = this.props.data.controls;
    this.ticketSelectorCoveoKey =
      generalAdmissionObj.ticketSelector &&
      Array.isArray(generalAdmissionObj.ticketSelector && generalAdmissionObj.ticketSelector.options) &&
      generalAdmissionObj.ticketSelector.options[0].coveoKey;
    this.visitorSelectorCoveoKey = generalAdmissionObj.visitorCounter && generalAdmissionObj.visitorCounter.coveoKey;
    this.parksCoveoKey = generalAdmissionObj.parks
      ? generalAdmissionObj.parks.coveoKey
      : generalAdmissionObj.ticketSelector && generalAdmissionObj.ticketSelector.options[0].parks[0].coveoKey;
    this.maxCount = generalAdmissionObj.visitorCounter && generalAdmissionObj.visitorCounter.maxCount;
    this.dateSelectorFlexible =
      generalAdmissionObj.dateSelector &&
      generalAdmissionObj.dateSelector.options.filter((option) => option.name === 'flexible');

    this.finalSelectedProducts = [];
    const ticketCount = {
      primaryCounterCount: {},
      additionalCounterCount: {},
    };

    generalAdmissionObj.visitorCounter &&
      generalAdmissionObj.visitorCounter.options.forEach((option) => {
        const optionCategory = option.coveoValue ? option.coveoValue : option.name;
        ticketCount.primaryCounterCount[optionCategory] = option.defaultQuantity;
      });

    const selectedPark = {};
    if (this.props.data.controls.parks) {
      this.props.data.controls.parks.options.forEach((park) => (selectedPark[park.name] = ''));
      selectedPark[this.props.data.controls.parks.options[0].name] = true;
    }

    let selectedTier = '';
    if (this.props.data.controls.tierSelector) {
      this.tierSelector = this.props.data.controls.tierSelector;
      this.tierSelectorCoveoKey = this.tierSelector.tiers[0].coveoKey;
      selectedTier = this.tierSelector.tiers[0].coveoValue;
    }
    if (this.props.data.controls.dateSelector) {
      this.dateSelector = this.props.data.controls.dateSelector;
      this.isDateSelectorHidden = this.props.data.controls.dateSelector.hidden;
    }
    const selectedTicket =
      generalAdmissionObj.ticketSelector && generalAdmissionObj.ticketSelector.options[0].coveoValue;
    // props.errors should be in format {error1:{text : "aaa"}, error2 :{text : "bbb"}},

    const selectedTicketData = {
      selectedTicket,
      selectedPark,
      ticketCount,
      selectedTier,
    };

    this.state = {
      disableAddToCart: false,
      errors: props.errors,
      counterComponentState: 'default',
      dateComponentState: 'default',
      ticketComponentState: 'default',
      tierComponentState: 'default',
      displayTotal: false,
      totalCalculatedAmount: '',
      date: {},
      selectedTicketData,
      addToCartClicked: false,
      isJuniorTicketError: false,
    };
  }

  componentDidMount() {
    this.props.createProductStore(this.props.data.type).then((response) => {
      this.responseObj = response;
      this.setState({ displayTotal: true }, () => this.updateState());
    });
  }

  updateState = () => {
    let counterSum = 0;
    for (const counter in this.state.selectedTicketData.ticketCount) {
      if (this.state.selectedTicketData.ticketCount[counter]) {
        counterSum += Object.values(this.state.selectedTicketData.ticketCount[counter]).reduce(
          (sum, value) => (sum += value),
          0,
        );
      }
    }
    const disableButton =
      counterSum > 0 &&
      counterSum < parseInt(this.maxCount, 10) + 1 &&
      this.getComponentState() &&
      this.checkIfDateSet();

    if (disableButton) {
      this.finalSelectedProducts = [];
      if (this.responseObj) this.addProductAndCalculateTotal(disableButton);
    } else {
      this.setState({
        disableAddToCart: !disableButton,
        totalCalculatedAmount: '',
      });
    }
  };

  addSelectedTicketToQueryObj = (queryObj) =>
    this.ticketSelectorCoveoKey &&
    queryObj.push(this.getQueryObj(this.ticketSelectorCoveoKey, this.state.selectedTicketData.selectedTicket));

  addProductAndCalculateTotal = (disableButton) => {
    let totalCalculatedAmount = 0;
    for (const category in this.state.selectedTicketData.ticketCount.primaryCounterCount) {
      if (this.state.selectedTicketData.ticketCount.primaryCounterCount[category] > 0) {
        const queryObj = [this.getQueryObj(this.visitorSelectorCoveoKey, category), this.selectedParks()];
        this.addSelectedTicketToQueryObj(queryObj);
        this.selectedParks().value.length === 1 &&
          this.dateSelector &&
          queryObj.push(this.getQueryObj(this.state.date.accessPolicykey, this.state.date.accessPolicyValue));
        this.tierSelector &&
          queryObj.push(this.getQueryObj(this.tierSelectorCoveoKey, this.state.selectedTicketData.selectedTier));
        let filteredProduct = this.responseObj.getFilteredProductsFromArray(queryObj);
        if (filteredProduct.length > 0 && this.selectedParks().value.length > 1) {
          filteredProduct = this.filterValidityFrame(filteredProduct);
        }
        const selectedProduct = this.getDiscountedProducts(filteredProduct);
        if (selectedProduct.length > 0) {
          this.finalSelectedProducts.push(...this.updateProductForCoveo(selectedProduct, category));
          totalCalculatedAmount +=
            parseFloat(selectedProduct[0].gross).toFixed(2) *
            this.state.selectedTicketData.ticketCount.primaryCounterCount[category];
        }
      }
    }
    this.finalSelectedProducts.length > 0
      ? this.setState({
          disableAddToCart: !disableButton,
          totalCalculatedAmount: toTwoDecimalPlaces(totalCalculatedAmount),
        })
      : this.setState({
          disableAddToCart: true,
          totalCalculatedAmount: '',
        });
  };

  filterValidityFrame = (filteredProduct) => {
    let selectedTicketDetials;
    this.props.data.controls.ticketSelector &&
      this.props.data.controls.ticketSelector.options.forEach((option) => {
        if (
          option.coveoValue === this.state.selectedTicketData.selectedTicket &&
          option.parks
            .map((park) => park.name)
            .sort()
            .join(',') ===
            this.selectedParks()
              .value.sort()
              .join(',')
        ) {
          selectedTicketDetials = option;
        }
      });
    if (selectedTicketDetials) {
      return filteredProduct.filter((product) =>
        this.state.date.isFlexible
          ? product[selectedTicketDetials.validityFrameKey.coveoKey] !== selectedTicketDetials.validityFrameKey.dated
          : product[selectedTicketDetials.validityFrameKey.coveoKey] === selectedTicketDetials.validityFrameKey.dated,
      );
    }
  };

  getComponentState = () => {
    return (
      this.state.counterComponentState !== 'noSelect' &&
      this.state.ticketComponentState !== 'noSelect' &&
      this.state.dateComponentState !== 'noSelect' &&
      this.state.tierComponentState !== 'noSelect'
    );
  };

  getQueryObj = (key, value) => {
    return {
      key: key,
      value: value,
    };
  };

  selectedParks = () => {
    const selectedParks = [];
    for (const park in this.state.selectedTicketData.selectedPark) {
      selectedParks.push(park);
    }
    return { key: this.parksCoveoKey, value: selectedParks, logic: 'AND' };
  };

  checkIfDateSet = () => {
    if (this.state.date.isFlexible) return true;
    else if (this.state.date.from instanceof moment) return true;
    else if (!this.dateSelector) return true;
    else return false;
  };

  updateProductForCoveo = (product, category) => {
    const selectedProdData =
      this.props.data.controls.visitorCounter &&
      this.props.data.controls.visitorCounter.options.find((data) => data.coveoValue === category);
    product[0].isFlexible = this.state.date.isFlexible;
    product[0].fromDate =
      this.state.date.from instanceof moment ? this.state.date.from.format(UIConfig.calendar.dateFormat) : '';
    product[0].quantity = this.state.selectedTicketData.ticketCount.primaryCounterCount[category];
    product[0].rangeInMonths = this.state.date.isFlexible ? this.state.date.rangeInMonths : '';
    product[0].minCount = selectedProdData.minimumQuantity;
    product[0].maxCount = selectedProdData.maximumQuantity;
    return product;
  };

  getDiscountedProducts = (products) => {
    if (!this.state.date || !this.state.date.from) return this.props.createMappedData(products);
    const days = momentTimezone(this.state.date.from, getClientUtcOffset())
      .startOf('day')
      .diff(moment().startOf('day'), 'days');
    return this.props.getDiscountedProducts(this.props.createMappedData(products), days);
  };

  setVisitorData = (data) => {
    this.setState(
      {
        counterComponentState: 'default',
        addToCartClicked: false,
        selectedTicketData: { ...this.state.selectedTicketData, ticketCount: data.spinnerCount },
      },
      () => {
        this.updateState();
      },
    );
  };

  setDateData = (data) => {
    let accessPolicykey = '';
    if (data.accessPolicy.key) {
      accessPolicykey = data.accessPolicy.key;
    } else if (this.dateSelectorFlexible.length > 0) {
      accessPolicykey = this.dateSelectorFlexible[0].coveoKey;
    }

    const date = {
      from: data.selectedField === 'flexible' ? moment() : data.selectedDate,
      isFlexible: data.selectedField === 'flexible',
      rangeInMonths: data.selectedField === 'flexible' ? data.rangeInMonths : null,
      accessPolicykey: accessPolicykey,
      accessPolicyValue: data.accessPolicy.value
        ? data.accessPolicy.value
        : this.dateSelectorFlexible.length > 0
        ? this.dateSelectorFlexible[0].coveoValue
        : '',
    };

    this.setState(
      {
        dateComponentState: 'default',
        addToCartClicked: false,
        date,
      },
      () => {
        this.updateState();
      },
    );
  };

  handleTierSelection = (selectedTier) => {
    this.setState(
      {
        tierComponentState: 'default',
        selectedTicketData: { ...this.state.selectedTicketData, selectedTier },
      },
      () => {
        this.updateState();
      },
    );
  };

  setTicketParkData = (data) => {
    this.setState(
      {
        ticketComponentState: 'default',
        addToCartClicked: false,
        selectedTicketData: {
          ...this.state.selectedTicketData,
          selectedTicket: data.coveoValueTicket,
          selectedPark: { ...data.selectedPark },
        },
        isJuniorTicketError: false,
      },
      () => {
        this.updateState();
      },
    );
  };

  isDateSelectorOptionAvailable = () => this.dateSelector.options && this.dateSelector.options.length > 0;

  // return true if junior ticket is not available for a product but junior counter value is > 0
  checkJuniorTicketError = () => {
    const juniorCounter = get(this.state, 'selectedTicketData.ticketCount.primaryCounterCount.JUNIOR', 0);
    const hasJuniorProductinCoveo = some(this.finalSelectedProducts, { classType: 'JUNIOR' });
    return juniorCounter > 0 && !hasJuniorProductinCoveo;
  };

  addToCart = () => {
    const { selectedTicketData, date } = this.state;
    let isSpinerValue =
      selectedTicketData.ticketCount &&
      selectedTicketData.ticketCount.primaryCounterCount &&
      Object.values(selectedTicketData.ticketCount.primaryCounterCount).some((i) => i > 0);
    if (!isSpinerValue || (!date.isFlexible && date.from == null)) {
      this.setState({
        addToCartClicked: true,
      });
    } else if (this.checkJuniorTicketError()) {
      // set junior counter to zero and show error
      set(selectedTicketData, 'ticketCount.primaryCounterCount.JUNIOR', 0);
      this.setState({
        selectedTicketData,
        isJuniorTicketError: true,
      });
    } else if (this.props.addToCartCallback) {
      this.props.addToCartCallback(this.finalSelectedProducts);
      const tierComponentState = this.tierSelector ? 'noSelect' : 'default';
      const dateComponentState =
        this.dateSelector && !this.isDateSelectorHidden && this.isDateSelectorOptionAvailable()
          ? 'default'
          : 'noSelect';
      const selectedPark = {};
      if (this.props.data.controls.parks) {
        this.props.data.controls.parks.options.forEach((park) => (selectedPark[park.name] = ''));
        selectedPark[this.props.data.controls.parks.options[0].name] = true;
      }
      const selectedTicketData = { ...this.state.selectedTicketData, selectedPark };
      if (selectedTicketData.ticketCount) {
        Object.keys(selectedTicketData.ticketCount).forEach((ticket) => {
          Object.keys(selectedTicketData.ticketCount[ticket]).forEach((category) => {
            selectedTicketData.ticketCount[ticket][category] = 0;
          });
        });
      }

      this.setState({
        tierComponentState,
        dateComponentState,
        selectedTicketData,
        counterComponentState: 'noSelect',
        ticketComponentState: 'noSelect',
        disableAddToCart: true,
        totalCalculatedAmount: '',
        addToCartClicked: false,
        isJuniorTicketError: false,
      });
    }
  };

  displayVisitorsCountSelector = (controls) => {
    if (controls.visitorCounter) {
      return (
        <VisitorsCountSelector
          data={{ primaryCounter: controls.visitorCounter }}
          name={this.props.data.name}
          setVisitorData={this.setVisitorData}
          addToCartClicked={this.state.addToCartClicked}
          componentState={this.state.counterComponentState}
          isJuniorTicketError={this.state.isJuniorTicketError}
        />
      );
    }
    return null;
  };

  displayDateFlexibleSelector = (controls) => {
    if (controls.dateSelector) {
      return (
        <DateFlexibleSelector
          parent={this.props.data.type}
          data={controls.dateSelector}
          setDateData={this.setDateData}
          addToCartClicked={this.state.addToCartClicked}
          componentState={this.state.dateComponentState}
        />
      );
    }
    return null;
  };

  displayTicketSelector = (controls, isTicketWithCTA, addToCart) => {
    if (controls.ticketSelector && controls.parks && Array.isArray(controls.parks.options)) {
      return (
        <TicketSelector
          tktVal={this.props.tktVal}
          carousel={{ ...this.props.carousel, cssClass: this.props.data.cssClass, variant: this.props.data.variant }}
          data={{
            tickets: controls.ticketSelector,
            parks: controls.parks,
            isTicketWithCTA: isTicketWithCTA,
            addToCartText: addToCart,
          }}
          setTicketParkData={this.setTicketParkData}
          componentState={this.state.ticketComponentState}
          isticketDisable={this.state.disableAddToCart}
          multiparkExpandable={controls.parks.multiparkExpandable}
          callAddToCart={this.addToCart}
          panelDefaultStatus={controls.ticketSelector.panelDefaultStatus}
          disableAddToCart={this.state.disableAddToCart}
        />
      );
    }
    return null;
  };

  displayTierSelection = (tiers) => {
    return tiers.map((tier, index) => {
      return (
        <TierComponent
          key={index}
          data={tier}
          handleOnClick={this.handleTierSelection}
          selectedTier={this.state.selectedTicketData.selectedTier}
          componentState={this.state.tierComponentState}
        />
      );
    });
  };

  render() {
    const { controls, addToCart, isTicketWithCTA = false } = this.props.data;
    return (
      <div className="component c-general-admission">
        {this.displayVisitorsCountSelector(controls)}

        {this.displayDateFlexibleSelector(controls)}

        {this.tierSelector && (
          <div className="tier-container-wrapper">{this.displayTierSelection(this.tierSelector.tiers)}</div>
        )}

        {this.displayTicketSelector(controls, isTicketWithCTA, addToCart)}

        {this.state.errors && <ErrorSummary data={this.state.errors} />}

        {this.responseObj && this.state.displayTotal && (
          <div className="ticket-total-amount-wrapper">
            <div className="body-3"> {this.props.data.priceLabel} </div>
            <div className={`total-amoumt ${this.state.totalCalculatedAmount === '' ? 'disabled' : ''}`}>
              <h3 className="totalText">{this.props.data.total}</h3>
              <div className="amount">
                <p className="currency">
                  {this.props.data.currency}&nbsp;
                  {this.state.totalCalculatedAmount}
                </p>
              </div>
            </div>
          </div>
        )}
        {addToCart && !isTicketWithCTA && (
          <div
            className={'btn-primary c-general-admission-add-cart ' + (this.state.disableAddToCart ? 'disabled' : '')}
          >
            <button
              className="add-to-cart"
              onClick={this.addToCart}
              tabIndex={this.state.disableAddToCart ? '-1' : '0'}
            >
              {addToCart}
            </button>
          </div>
        )}
        {this.props.data.vatInfo && (
          <DynamicContent tagName="div" attrs={{ className: 'vat-info body-2' }} innerHtml={this.props.data.vatInfo} />
        )}
      </div>
    );
  }
}

GeneralAdmission.PropsTypes = {
  data: PropTypes.shape({
    controls: PropTypes.object.isRequired,
  }),
};
