import { canUseDOM } from '../common/utility';

export const setReconcilRequestAction = () => {
  return canUseDOM() && localStorage.setItem('isReconcilRequest', true);
};

export const getIsReconcilRequest = () => {
  let isReconcilRequestvalue = JSON.parse(localStorage.getItem('isReconcilRequest'));
  return isReconcilRequestvalue;
};
