/*
 * mega-nav-component.js
 * This file contains code for Mega Nav component, it renders 1 RTE ,links array and image array
 * @author    Sunil Sharma, SapientNitro <ssharma193@sapient.com>
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';

import { AnchorLink, Image, RichText } from '../base';
import { logComponentRenderingError } from '../../../common/logger';
import './mega-nav-component.scss';

import { subHeaderClickAnalytics } from '../../../common/analytics-events';

/**
 * MegaNav is a component which will be rendered by Nav component, it expects its props from Nav component
 * @param    {object}    data     having data of Mega Nav
 */

const renderimages = (data) => {
  let imageTiles = [];
  data.imageDetails &&
    data.imageDetails.forEach((item, idx) =>
      imageTiles.push(
        <li key={idx} className="meganav--image-wrapper-list">
          {item.linkData && item.linkData.href ? (
            <a className="meganav--image-wrapper-list-content" href={item.linkData.href}>
              <Image image={item.imageDetail} disableLazyLoad={true} />
              <p className="meganav--right-wrapper-list-content-item">{item.subTitle}</p>
            </a>
          ) : (
            <div>
              <Image image={item.imageDetail} disableLazyLoad={true} />
              <p className="meganav--right-wrapper-list-content-item">{item.subTitle}</p>
            </div>
          )}
        </li>,
      ),
    );

  for (let i = data.imageDetails && data.imageDetails.length; i < 6; i++) {
    imageTiles.push(<li key={i} className="meganav--image-wrapper-list image-placeholder"></li>);
  }
  return imageTiles;
};
const googleAnalyticSubHeaderHandleClick = (event, data) => {
  subHeaderClickAnalytics(event, data);
};

const MegaNav = ({ data, className }) => {
  try {
    return (
      <div className={'meganav meganav-' + className}>
        <div className="meganav--main-wrapper">
          <div className="meganav--text-wrapper">
            {data.bodyCopy && (
              <RichText data={{ bodyCopy: data.bodyCopy, attrs: { className: 'meganav--text-wrapper-bodycopy' } }} />
            )}
            <ul className="meganav--text-wrapper-list">
              {data.navLinks &&
                data.navLinks.map((item, idx) => (
                  <li key={idx} className="meganav--text-wrapper-list-item">
                    {item.linkData && (
                      <AnchorLink
                        link={{
                          href: item.linkData.href,
                          label: item.label,
                          target: item.target,
                        }}
                        onClick={(e) => googleAnalyticSubHeaderHandleClick(e, item.label)}
                      />
                    )}
                  </li>
                ))}
            </ul>
          </div>
          <ul className="meganav--image-wrapper">{renderimages(data)}</ul>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'MegaNav');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
MegaNav.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MegaNav;
