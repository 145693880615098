import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { canUseDOM, customMomentChange, detectMobile, getLoggedInUser, isArabicMode } from '../../../common/utility';
import FullCalendar from '@fullcalendar/react';
import listMonth from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import './full-calendar.scss';
import { AnchorLink, DynamicContent } from '../../presentation/base';
import { Logging } from '../../../common/logger';
import ApiWrapper from '../../../common/api-wrapper';
import UIConfig from '../../../common/UIConfig';
import { formatDate, getDateformatStamp, applyClass, checkTenant } from '../../../common/utility/helpers';
import { isEmpty } from 'lodash';

const FullWidthCalendar = (props) => {
  const calendarRef = React.useRef();
  const { loadMoreText, title, subTitle, services, numberOfMonths, parkTimings, closedText } = props.data;
  const [isMobile, setMobileView] = useState(detectMobile());
  const [isArabic, setArabic] = useState(false);
  const [isCalendarEvents, setCalendarEvents] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { next, prev } = UIConfig.fullCalendar;
  // loadEventCount is used in mobile view where specified number of events are
  // loaded and others are loaded on click of "Load More" button
  const loadEventCount = 7;
  const [isEventCount, setEventCount] = useState(loadEventCount);

  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const getDate = (date) => {
    return date.split('T')[0];
  };

  const updateCalendarData = (eventData) => {
    const newDates = [];
    eventData.map((data) => {
      const date = getDate(data.start);
      const presentIndex = newDates.findIndex((d) => getDate(d.start) === date);
      if (presentIndex >= 0) {
        const oldData = newDates[presentIndex];
        newDates[presentIndex] = {
          ...oldData,
          extendedProps: {
            events: [...oldData.extendedProps?.events, data],
          },
        };
      } else {
        const newData = {
          start: data.start,
          extendedProps: {
            events: [data],
          },
        };
        newDates.push(newData);
      }
    });
    setCalendarEvents(newDates);
    setLoading(false);
  };

  const navigateCalendar = (type) => {
    if (type) {
      const calender = calendarRef.current._calendarApi;
      isPrevAction(type) ? calender.prev() : calender.next();
      setEventCount(loadEventCount);
    }
  };

  const getCalendarEvents = (range, type) => {
    const { getCalendarAPI } = services;
    const { start, end } = range;
    const userData = getLoggedInUser();
    const serviceUrl = `${getCalendarAPI?.url}?tenantid=${userData.tenantID}&fromDate=${start}&toDate=${end}`;
    const config = {
      url: serviceUrl,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + userData.idToken,
      },
      preLoader: true,
      preLoaderTarget: isSwad ? UIConfig.loader.bodyPreLoaderTarget : UIConfig.loader.defaultPreLoaderTarget,
    };
    ApiWrapper.api(config)
      .then((res) => {
        updateCalendarData(res.data.parkTimingslist.parkTimings);
        navigateCalendar(type);
      })
      .catch((err) => {
        Logging(err, 'FullWidthCalendar', false, 'Error in FullWidthCalendar');
        navigateCalendar(type);
        setLoading(false);
        setCalendarEvents([]);
      });
  };

  const updateMobileView = () => {
    setMobileView(detectMobile());
  };

  useEffect(() => {
    customMomentChange(props.data);
    setArabic(isArabicMode());
    updateMobileView();
    window.addEventListener('resize', updateMobileView);
    return () => {
      window.removeEventListener('resize', updateMobileView);
    };
  }, []);

  useEffect(() => {
    const todayDate = moment()
      .locale(UIConfig.languages.enLang)
      .format('YYYY-MM-DD');
    const endDate = moment(todayDate)
      .locale(UIConfig.languages.enLang)
      .endOf('month')
      .format('YYYY-MM-DD');
    const range = {
      start: todayDate,
      end: endDate,
    };
    getCalendarEvents(range);
  }, []);

  const loadMoreDates = () => {
    setEventCount(isEventCount + loadEventCount);
  };

  const sortEvents = (events) => {
    return events.sort((a, b) => moment(a.start).format('hh') - moment(b.start).format('hh'));
  };

  const getMainEvent = (mainEvent) => {
    if (isEmpty(mainEvent)) {
      return <></>;
    }

    return (
      <>
        {mainEvent?.extendedProps?.isOpeningHours && (
          <h6 id={applyClass(mainEvent.start) > 0 || applyClass(mainEvent.end) ? 'display-with-minutes' : ''}>
            {formatDate(mainEvent.start, getDateformatStamp(mainEvent.start, isArabic), 'locale')}
            <span> - </span>
            {formatDate(mainEvent.end, getDateformatStamp(mainEvent.end, isArabic), 'locale')}
          </h6>
        )}
        {mainEvent?.extendedProps?.isClosed && <h6>{closedText}</h6>}
      </>
    );
  };

  const getEventView = (extendedProps) => {
    const sameDayEvents = sortEvents(extendedProps.events);
    const mainEventIdx = sameDayEvents.findIndex(
      (val) => val?.extendedProps?.isOpeningHours || val?.extendedProps?.isClosed,
    );
    const isMainEventIdx = sameDayEvents[mainEventIdx];
    const eventType = isMainEventIdx?.extendedProps;
    const subEvent = sameDayEvents.filter((e, i) => i !== mainEventIdx)?.[0];

    return (
      <>
        {getMainEvent(isMainEventIdx)}
        {!eventType?.isClosed && subEvent && (
          <p>
            {parkTimings?.[subEvent.id] || subEvent.title}:{' '}
            {formatDate(subEvent.start, getDateformatStamp(subEvent.start, isArabic), 'locale')}
            <span> - </span>
            {formatDate(subEvent.end, getDateformatStamp(subEvent.end, isArabic), 'locale')}
          </p>
        )}
      </>
    );
  };

  const renderEventContent = (eventInfo) => {
    const { extendedProps, start } = eventInfo.event;
    return (
      <div className="custom-event">
        {isMobile && (
          <div className="date">
            <h1>{formatDate(start, 'D')}</h1>
            <h4>
              {moment(start)
                .locale(isArabic ? UIConfig.languages.arLang : UIConfig.languages.enLang)
                .format('dddd')}
            </h4>
          </div>
        )}
        <div className="desc">{getEventView(extendedProps)}</div>
      </div>
    );
  };

  const getAllEventData = () => {
    if (isMobile) {
      return [...isCalendarEvents.slice(0, isEventCount)];
    }
    return isCalendarEvents;
  };

  const getProps = () => {
    return {
      initialView: isMobile ? 'listMonth' : 'dayGridMonth',
    };
  };

  const isPrevAction = (type) => {
    return type === prev;
  };

  const navNextPrevMonth = (type) => {
    const currentDate = calendarRef?.current?._calendarApi?.getDate();
    let strtDate;
    if (isPrevAction(type)) {
      strtDate = moment(currentDate).subtract(1, 'month');
    } else {
      strtDate = moment(currentDate).add(1, 'month');
    }
    const todayDate = moment().locale(UIConfig.languages.enLang);
    const isCurrentMonth = strtDate.month() === todayDate.month();
    const range = {
      start: isCurrentMonth ? todayDate.format('YYYY-MM-DD') : strtDate.startOf('month').format('YYYY-MM-DD'),
      end: moment(strtDate)
        .endOf('month')
        .format('YYYY-MM-DD'),
    };
    getCalendarEvents(range, type);
  };

  const getTitleFormat = (data) => {
    const { month, year } = data.date;
    const currentMonth = moment(month + 1, 'M')
      .locale(isArabic ? UIConfig.languages.arSA : UIConfig.languages.enLang)
      .format('MMMM');
    return `${currentMonth} ${year} `;
  };

  if (!canUseDOM() || isLoading) {
    return <></>;
  }

  return (
    <div className="component c-full-width-calendar">
      <div className="w--content">
        <div className="w--top no-cta-btn-container ">
          {!isSwad && title && <DynamicContent tagName="p" attrs={{ className: 'heading-7' }} innerHtml={title} />}
          {subTitle && (
            <DynamicContent
              tagName="h2"
              attrs={{
                className: 'heading-1 align-center',
              }}
              innerHtml={subTitle}
            />
          )}
          {isSwad && title && <DynamicContent tagName="p" attrs={{ className: 'heading-7' }} innerHtml={title} />}
        </div>
        <FullCalendar
          ref={calendarRef}
          fixedWeekCount={false}
          noEventsText={services?.getCalendarAPI?.errors?.API404 || UIConfig.fullCalendar.noEventMsg}
          handleWindowResize
          events={getAllEventData()}
          eventContent={renderEventContent}
          {...getProps()}
          plugins={[listMonth, dayGridPlugin]}
          height="auto"
          direction={isArabic ? 'rtl' : 'ltr'}
          headerToolbar={{
            right: `${prev},${next}`,
          }}
          titleFormat={function(data) {
            return getTitleFormat(data);
          }}
          dayCellContent={(arg) => {
            return moment(arg.date)
              .locale(UIConfig.languages.enLang)
              .format('D');
          }}
          dayHeaderContent={(arg) => {
            return moment(arg.date)
              .locale(isArabic ? UIConfig.languages.arLang : UIConfig.languages.enLang)
              .format('dddd');
          }}
          views={{
            listMonth: {
              type: 'list',
              buttonText: 'List Week',
              listDayFormat: false,
              listDaySideFormat: false,
            },
          }}
          firstDay={1}
          dayMaxEventRows={3}
          showNonCurrentDates={false}
          validRange={(nowDate) => {
            const monthStart = moment().startOf('month');
            const futureMonth = moment(nowDate)
              .add(numberOfMonths, 'month')
              .endOf('month');
            return {
              start: monthStart.toDate(),
              end: futureMonth.toDate(),
            };
          }}
          customButtons={{
            [prev]: {
              text: prev,
              click: function() {
                navNextPrevMonth(prev);
              },
            },
            [next]: {
              text: next,
              click: function() {
                navNextPrevMonth(next);
              },
            },
          }}
          windowResize={function(arg) {
            setMobileView(window.innerWidth <= 1023);
          }}
          locale={isArabic ? UIConfig.languages.arLang : UIConfig.languages.enLang}
        />
        {isMobile && loadMoreText && isCalendarEvents.length !== 0 && isEventCount < isCalendarEvents?.length && (
          <div className="load-more">
            <AnchorLink
              link={{
                href: 'javascript:void(0)', // eslint-disable-line
                label: `${loadMoreText} <span>+</span>`,
              }}
              className="cta-wrap secondary-cta right-arrow"
              onClick={() => loadMoreDates()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FullWidthCalendar;
