import React from 'react';
import PropTypes from 'prop-types';
import { logComponentRenderingError } from '../../../../common/logger';

export default class InlineSvg extends React.Component {
  // This is the m function from "CSS in JS" and can be extracted to a mixin
  mergeStyles(...args) {
    return Object.assign({}, ...args);
  }

  renderSVG() {
    switch (this.props.icon) {
      case 'hero-tile-curve':
        return (
          <path d="M98.496 366H0V0h98.275c1.66 9.866 10.647 17.404 21.485 17.404S139.585 9.866 141.245 0H240v366h-98.976c-2.125-9.148-10.838-16-21.264-16s-19.14 6.852-21.264 16z" />
        );
      case 'hero-tile-without-curve':
        return <path d="M0 0h240v367.018H0z" />;
      case 'hero-bottom-curve':
        return (
          <g>
            <defs>
              <linearGradient x1="0%" x2="100%" id="heroCurve">
                <stop offset="0%" id="HeroCurveLeft" />
                <stop offset="100%" id="HeroCurveRight" />
              </linearGradient>
            </defs>
            <path
              fill="url(#heroCurve)"
              d="M0 0h1365.96l-.042 35.91C1163.482 103.97 936.154 138 683.936 138 431.716 138 203.738 104 0 36V0z"
            />
          </g>
        );
      case 'down-chevron':
      case 'up-chevron-red':
        return (
          <path
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            fillRule="evenodd"
            stroke="#979797"
            d="M1 .485l8.485 8.486L17.971.485"
          />
        );
      default:
    }
  }

  render() {
    try {
      let styles = {
        color: this.props.color,
        verticalAlign: 'middle',
      };
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={this.props.viewBox}
          className={this.props.cssClass}
          width={this.props.width}
          height={this.props.height}
          preserveAspectRatio="xMinYMin slice"
          style={this.mergeStyles(styles, this.props.style)}
        >
          {this.renderSVG()}
        </svg>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'InlineSvg');
    }
  }
}

InlineSvg.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shortTitle: PropTypes.string,
  style: PropTypes.object,
  viewBox: PropTypes.string,
  cssClass: PropTypes.string,
  color: PropTypes.string,
};
