import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import UIConfig from '../../../../common/UIConfig';
import {
  createMappedData,
  flipObjectKeyValues,
  getErrorMap,
  getFallbackLanguage,
  getLoggedInUser,
  getUserAgent,
  hasHeaderComponent,
  isEmpty,
  createDeepCopy,
  isEnterPressed,
  checkTenant,
  checkParksTenants,
  isMatchTenant,
} from '../../../../common/utility';
import {
  getCategoryProds,
  updateSecProdArray,
  renderOverlayErrorMsg,
  setStateOfMinicart,
  changeCategoryToString,
} from '../../../../common/helpersPJ';
import { DynamicContent, Overlay, AnchorLink } from '../../../presentation/base';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { logComponentRenderingError } from '../../../../common/logger';
import TileComponent from '../booking-overlay/tile-component';
import CartActions from '../cart/cart-actions';
import { getB2CProductDetails } from '../../../../common/coveo-api.js';
import ProductStore from '../purchase-journey-wrapper/product-store';
import { secProds } from '../add-ons/add-ons-utility.js';
import GTMData from '../gtm-data';

/**
 * ProductWidgetOverlay dumb Component creates ProductWidgetOverlay
 * functionality of showing directions when a user selects a location from dropdown.
 * @param    {data} Object as defined from data-contract.
 * @return   {htmlMarkUp}
 */
const ProductWidgetOverlay = ({ data, addToCartCallback, getDiscountedProducts, variant }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [errorOverlayObj, setErrorOverlayObj] = useState({});
  const [purchaseWrapperErrors, setPurchaseWrapperErrors] = useState('');
  const [errObj, setErrObj] = useState('');
  const [cartData, setCartData] = useState({});
  const [showPjPopupSegment, setShowPjPopupSegment] = useState(false);

  const products = useRef({});
  const productId = useRef('');
  const pjData = useRef(null);
  const cartActions = useRef(null);
  const productList = useRef([]);
  const coveoDefaultParams = useRef({});
  const responseObj = useRef(null);
  const responseObjAll = useRef(null);
  const productData = useRef({});
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isParks = checkParksTenants();

  useEffect(() => {
    const productId = data?.productWidget?.productDetails?.productId || '';
    if (productId) {
      window.PubSub.subscribe(UIConfig.events.PURCHASEJOURNEYDATA, (msg, data) => {
        pjData.current = data.purchaseJourneyData ? data.purchaseJourneyData : null;

        cartActions.current = new CartActions({
          serviceUrl: pjData.current.services,
          moduleName: 'purchase-journey',
          tenantId: getLoggedInUser().tenantID,
        });

        if (isParks && isEmpty(productData.current)) {
          getSelectedProd(productId, pjData.current);
        }

        setStateOfMinicart(cartActions, productList, errorCallback, setCartData, setErrObj);
      });
      setShowPjPopupSegment(true);
    }

    window.PubSub.subscribe('closeOverlay', closeOverlay);
    window.PubSub.subscribe('mainOverLayClosed', (msg, data) => {
      if (data) {
        closeOverlay();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedProd = (prodId, pjData) => {
    productId.current = prodId;
    coveoDefaultParams.current = {
      perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
      coveoKeyMap: pjData.coveoMappingList,
      serviceUrl: pjData.services.getCoveoProducts.url,
      fieldsToInclude: Object.values(pjData.coveoMappingList),
      lang: getFallbackLanguage(),
    };

    createProductStore(prodId, true).then((res) => {
      responseObj.current = res;
      const activeProduct = res && createMappedData(res.products, pjData.coveoMappingList)[0];
      const currentTabData =
        pjData &&
        pjData.tabs &&
        pjData.tabs.filter(
          (tab) => activeProduct.tab && activeProduct.tab.includes(tab.coveoValue), //modified
          // tab.coveoValue.toLowerCase() === (activeProduct && activeProduct.tab && activeProduct.tab.toLowerCase()),
        )[0];
      createProductStore(currentTabData.coveoValue, false).then((response) => {
        responseObjAll.current = response;
        getProductData(res, pjData);
      });
    });
  };

  const getProductData = (res, pjData) => {
    const activeProduct = res && createMappedData(res.products, pjData.coveoMappingList)[0];

    const currentTabData =
      pjData &&
      pjData.tabs &&
      pjData.tabs.filter((tab) => activeProduct.tab && activeProduct.tab.includes(tab.coveoValue))[0]; //modified

    const category = { [currentTabData.coveoKey]: currentTabData.coveoValue };
    const resProducts = responseObjAll.current.getFilteredProductsFromKeyVal(category);
    const allProducts = resProducts.length && createMappedData(resProducts, pjData.coveoMappingList);
    const categoryProducts =
      allProducts &&
      allProducts.length &&
      allProducts.filter((prod) => {
        if (prod.category) {
          return prod.category.toString() === activeProduct.category.toString();
        }
      }); //modified
    GTMData.push('viewItem', {
      productItem: [activeProduct],
      productWidget: true,
      additionalPayload: {
        currencyId: currentTabData.currency,
      },
    });
    const updateProdData = {
      activeProduct: activeProduct,
      currentTabData: currentTabData,
      categoryProducts: categoryProducts,
      allProducts: allProducts,
    };
    if (isParks) {
      productData.current = updateProdData;
    } else {
      return updateProdData;
    }
  };

  /**
   * error handling
   * @param {Object} err error object
   */
  const errorCallback = (serviceName, error) => {
    if (error) {
      if (serviceName === 'getCoveoProducts' && error.code.toString() === UIConfig.errorCodes.notFound) {
        const errObj = getErrorMap(
          serviceName,
          pjData.current.services[serviceName].errors,
          false,
          error,
          purchaseWrapperErrors,
        );
        setPurchaseWrapperErrors(errObj);
      } else {
        const errorObj = getErrorMap(serviceName, pjData.current.services[serviceName].errors, false, error, errObj);
        setErrObj(errorObj);
      }
    }
  };

  /**
   * Product data coveo call
   * @param {Object} err error object
   */
  const createProductStore = (tab, getActiveProduct) => {
    return new Promise((resolve, reject) => {
      if (!products.current[tab]) {
        const params = [];
        getActiveProduct
          ? params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.productId, value: productId.current },
            )
          : tab &&
            params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.tab, value: tab },
            );

        getB2CProductDetails({
          ...coveoDefaultParams.current,
          queryParams: params,
        })
          .then((response) => {
            const resultWithEventtypeChange = isSwad
              ? response.results.map((item) =>
                  Array.isArray(item[pjData.current.coveoMappingList.eventType])
                    ? {
                        ...item,
                        [pjData.current.coveoMappingList.eventType]: item[pjData.current.coveoMappingList.eventType][0],
                      }
                    : { ...item },
                )
              : response.results;
            const res = isSwad ? { ...response, results: resultWithEventtypeChange } : response;
            if (
              isMatchTenant(UIConfig.tenants.fwad) ||
              isMatchTenant(UIConfig.tenants.wbw) ||
              isMatchTenant(UIConfig.tenants.yww)
            ) {
              res.results = changeCategoryToString(res.results, coveoDefaultParams);
            }
            products.current[tab] = new ProductStore(res.results);
            setPurchaseWrapperErrors('');
            resolve(products.current[tab]);
          })
          .catch((res) => {
            errorCallback('getCoveoProducts', res.error);
            const errObj = getErrorMap(
              'getCoveoProducts',
              pjData.current.services.getCoveoProducts.errors,
              false,
              res.error,
            );
            reject(errObj);
          });
      } else {
        resolve(products.current[tab]);
      }
    });
  };

  const renderPjPopup = (id, itemData = {}) => {
    if (!isParks) productId.current = id;
    if (!pjData.current) {
      return;
    }

    coveoDefaultParams.current = showPjPopupSegment
      ? {
          perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.current.coveoMappingList,
          serviceUrl: pjData.current.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.current.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};
    if (showPjPopupSegment) {
      if (isParks) {
        if (!isEmpty(responseObjAll.current)) {
          setIsOverlayOpen(true);
          setErrorOverlayObj({});
          const { categoryProducts, activeProduct } = productData.current;
          let updatedCategoryProducts = categoryProducts
          if(categoryProducts?.length) {
            const relatedProductId = categoryProducts[0]?.relatedProduct || ''
            if(relatedProductId) {
              const relatedProduct = categoryProducts.find((prd) => prd?.productId === relatedProductId)
              updatedCategoryProducts = [categoryProducts[0], relatedProduct]
            }
          }
          if (categoryProducts.length && activeProduct) {
            GTMData.push('selectItem', {
              itemsList: updatedCategoryProducts,
              productWidget: true,
              category: activeProduct.category,
            });
          }
        }
      } else {
        createProductStore(productId.current, true).then((response) => {
          responseObj.current = response;
          const activeProduct = response && createMappedData(response.products, pjData.current.coveoMappingList)[0];
          const currentTabData =
            pjData.current &&
            pjData.current.tabs &&
            pjData.current.tabs.filter(
              (tab) => activeProduct.tab && activeProduct.tab.includes(tab.coveoValue), //modified
              // tab.coveoValue.toLowerCase() === (activeProduct && activeProduct.tab && activeProduct.tab.toLowerCase()),
            )[0];

          createProductStore(currentTabData.coveoValue, false).then((response) => {
            responseObjAll.current = response;
            setIsOverlayOpen(true);
            setErrorOverlayObj({});
          });
        });
      }
    }
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const renderPJPopupData = () => {
    let counterData = null;
    let dateSelector = null;
    let timeSlotSelector = null;
    let catgry = null;
    let categry = null;
    let productOverlaySelector = null;
    let activeProduct, currentTabData, categoryProducts, allProducts;
    const productDataCopy = createDeepCopy(productData.current);
    if (isParks) {
      activeProduct = productDataCopy.activeProduct;
      currentTabData = productDataCopy.currentTabData;
      categoryProducts = productDataCopy.categoryProducts;
      allProducts = productDataCopy.allProducts;
    } else {
      activeProduct =
        responseObj.current && createMappedData(responseObj.current.products, pjData.current.coveoMappingList)[0];

      currentTabData =
        pjData.current &&
        pjData.current.tabs &&
        pjData.current.tabs.filter((tab) => activeProduct.tab && activeProduct.tab.includes(tab.coveoValue))[0]; //modified
      var category = { [currentTabData.coveoKey]: currentTabData.coveoValue };
      var resProducts = responseObjAll.current.getFilteredProductsFromKeyVal(category);
      allProducts = resProducts.length && createMappedData(resProducts, pjData.current.coveoMappingList);
      categoryProducts =
        allProducts &&
        allProducts.length &&
        allProducts.filter((prod) => {
          if (prod.category) {
            return prod.category.toString() === activeProduct.category.toString();
          }
        }); //modified
    }

    for (let tktType in currentTabData.controls) {
      let currentBaseProdOptions =
        currentTabData.controls[tktType].options &&
        currentTabData.controls[tktType].options.find((opt) => activeProduct.category.includes(opt.coveoValue)); //modified

      if (currentBaseProdOptions) {
        catgry = currentBaseProdOptions;
        counterData = currentBaseProdOptions.visitorSelector;
        dateSelector = currentBaseProdOptions.dateSelector;
        timeSlotSelector = currentBaseProdOptions.timeSlotSelector;
        productOverlaySelector = currentBaseProdOptions.productOverlaySelector;
      }
      categry = currentBaseProdOptions;
    }

    if (!products.current.hasOwnProperty('quantity')) {
      products.current.quantity =
        (counterData &&
          counterData.visitorSelector &&
          counterData.visitorSelector.options &&
          counterData.visitorSelector.options[0] &&
          counterData.visitorSelector.options[0].defaultQuantity) ||
        0;
    }

    const additionalProds = getCategoryProds(activeProduct.category, allProducts);

    // Reset Secondary products
    Object.keys(secProds).forEach((key) => delete secProds[key]);

    return categoryProducts.map((product, index) => {
      const multiTicketSelector = categry.multiTicketSelector,
        keyMap = multiTicketSelector ? multiTicketSelector.stringMapping : {},
        category = product.category;

      let isCategoryMappingExist = false;
      if (keyMap) {
        for (let item in keyMap) {
          if (item === category[0]) {
            isCategoryMappingExist = true;
            break;
          }
        }
      }

      if (
        keyMap &&
        (category.indexOf(keyMap.drivingExperience) !== -1 ||
          category.indexOf(keyMap.passengerExperience) !== -1 ||
          isCategoryMappingExist)
      ) {
        updateSecProdArray(product, category[0], keyMap);
      }

      if (!product.hasOwnProperty('quantity')) {
        product.quantity = categry.visitorSelector.options[0].defaultQuantity;
      }

      return (
        <div>
          {!multiTicketSelector ||
          (product.experienceCatgory === keyMap.primary && getDiscountedProducts([product], 0).length) ? (
            <TileComponent
              data={currentTabData}
              cartData={pjData.current.miniCart}
              enablePJPopup={showPjPopupSegment}
              businessErrors={pjData.current.miniCart.businessErrors}
              key={index}
              ticket={product}
              dateSelector={dateSelector}
              visitorCounter={counterData}
              createMappedData={createMappedData}
              addToCartCallback={addToCartCallback}
              services={pjData.current.services}
              getDiscountedProducts={getDiscountedProducts}
              timeSlotSelector={timeSlotSelector}
              category={catgry}
              handleOnClick={() => {}}
              additionalProds={additionalProds}
              searchProductList={allProducts}
              copySearchProductList={createDeepCopy(allProducts)}
              coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
              productOverlaySelector={productOverlaySelector}
              overlayErrorCallback={overlayErrorCallback}
              variant={showPjPopupSegment ? 'v-overlay-selected' : ''}
              productWidget
            />
          ) : null}
        </div>
      );
    });
  };
  const openOverlay = (tncPopup) => {
    if (!isExperienceEditorActive()) {
      window.PubSub.publish('toggleOverlayState', {
        shouldOpen: true,
        dataToAppend: tncOverlay(tncPopup),
        customClass: 'ride-info-overlay c-flash-sale-overlay',
      });
    }
  };

  const tncOverlay = (tncPopup) => {
    return (
      <div className="overlay-sub-container">
        {tncPopup.tncTitle && (
          <DynamicContent
            tagName="h2"
            innerHtml={tncPopup.tncTitle}
            attrs={{
              className: 'overlay-sub-heading',
            }}
          />
        )}
        {tncPopup.tncDescription && (
          <DynamicContent
            tagName="p"
            innerHtml={tncPopup.tncDescription}
            attrs={{
              className: 'overlay-description',
            }}
          />
        )}
      </div>
    );
  };

  const checkKeyDownHandler = (e, tncPopup) => {
    if (isEnterPressed(e)) {
      openOverlay(tncPopup);
    }
  };
  const overlayErrorCallback = (error, errorData) => {
    let errorObj = {};
    if (!isEmpty(error) && error.errorcode) {
      errorObj.errorcode = error.errorcode;
      if (errorData && errorData[error.errorcode]) {
        errorObj.text = errorData[error.errorcode];
      } else if (error && error.errordescription) {
        errorObj.text = error.errordescription;
      }
      setErrorOverlayObj(errorObj);
      setIsOverlayOpen(false);
    }
  };
  try {
    return renderWidgetCTA(
      data,
      renderPjPopup,
      renderPJPopupData,
      errorOverlayObj,
      isOverlayOpen,
      showPjPopupSegment,
      openOverlay,
      checkKeyDownHandler,
    );
  } catch (err) {
    return logComponentRenderingError(err, 'productWidgetOverlay', variant);
  }
};

const renderWidgetCTA = (
  data,
  renderPjPopup,
  renderPJPopupData,
  errorOverlayObj,
  isOverlayOpen,
  showPjPopupSegment,
  openOverlay,
  checkKeyDownHandler,
) => {
  const userAgent = getUserAgent() || !hasHeaderComponent();
  const { productWidget } = data;
  return (
    <div className="product-widget-overlay">
      {showPjPopupSegment && productWidget.productDetails.productId && productWidget.ctaDetails.label && (
        <div className="btn-primary-wrapper">
          <div className={`btn-primary`}>
            <DynamicContent
              tagName="button"
              innerHtml={productWidget.ctaDetails.label}
              attrs={{
                onClick: () => renderPjPopup(productWidget.productDetails.productId),
              }}
            />
          </div>
          <div>
            {productWidget.tncPopup && (
              <AnchorLink
                className="tnc-link"
                link={{ label: productWidget.tncPopup.tncLabel }}
                onClick={() => openOverlay(productWidget.tncPopup)}
                onKeyDown={(e) => checkKeyDownHandler(e, productWidget.tncPopup)}
                tabIndex="0"
              />
            )}
          </div>
        </div>
      )}
      {showPjPopupSegment && isOverlayOpen && renderPJPopupData()}
      {renderOverlayErrorMsg(errorOverlayObj)}
      {userAgent && <Overlay />}
    </div>
  );
};

export default ProductWidgetOverlay;

ProductWidgetOverlay.propTypes = {
  data: PropTypes.shape({
    productWidget: PropTypes.object,
    variant: PropTypes.string,
  }),
};
