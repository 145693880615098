import moment from 'moment';

import UIConfig from '../../../../common/UIConfig';
import {
  deepCloneObject,
  momentTimezone,
  scrollTo,
  toTwoDecimalPlaces,
  isEmpty,
  resolvePath,
  isMatchTenant,
  addLoaderOverlay,
  redirectTo,
  removeLoaderOverlay,
  priceValue,
  convertJsDateToMomentObj,
  getClientUtcOffset,
  canUseDOM,
  toLowerCase,
  checkIfParks,
  getLocalStorageByKey,
} from '../../../../common/utility';
import { checkTenant, isParkTenant } from '../../../../common/utility/tenantsUtils';
import GTMData from '../gtm-data';

const GTMDataOnClickCTA = (data) => {
  if (isMatchTenant(UIConfig.tenants.fwad) || isMatchTenant(UIConfig.tenants.yi)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
      name: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.name) : data.name,
      [UIConfig.ga4Constants.ELEMENTTEXT]: isMatchTenant(UIConfig.tenants.yi)
        ? toLowerCase(data.elementText)
        : data.elementText,
      category: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.category) : data.category,
    });
  }
};

export const enableVisitedDate = () =>
  canUseDOM() && localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.visitedDate) && isParkTenant();
const isSelectedDateActive = (highlightsDates, date) => {
  const activeDates =
    highlightsDates &&
    highlightsDates.length &&
    highlightsDates.find((item) => Object.keys(item)[0] === 'active-dates');

  return (
    activeDates &&
    activeDates['active-dates'] &&
    activeDates['active-dates'].length &&
    activeDates['active-dates']
      .map((date) => convertJsDateToMomentObj(date))
      ?.some((item) => momentTimezone(item, getClientUtcOffset()).isSame(date, 'day'))
  );
};
const isSelectedDateLimitedSeats = (highlightsDates, date) => {
  const limitedSeats =
    highlightsDates &&
    highlightsDates.length &&
    highlightsDates.find((item) => Object.keys(item)[0] === 'limited-seats');
  return (
    limitedSeats &&
    limitedSeats['limited-seats'] &&
    limitedSeats['limited-seats'].length &&
    limitedSeats['limited-seats']
      .map((date) => convertJsDateToMomentObj(date))
      ?.some((item) => momentTimezone(item, getClientUtcOffset()).isSame(date, 'day'))
  );
};
const isSelectedDateExcluded = (excludedDates, date) =>
  excludedDates &&
  excludedDates.length &&
  excludedDates
    ?.map((dates) => convertJsDateToMomentObj(dates.toDate()))
    ?.some((item) => momentTimezone(item, getClientUtcOffset()).isSame(date, 'day'));

const checkValidityCommon = (self, date) => {
  const dateSupMinDate = momentTimezone(date, getClientUtcOffset()).isSameOrAfter(self.props.minDate, 'day');
  const dateInfMaxDate = momentTimezone(self.props.maxDate, getClientUtcOffset()).isSameOrAfter(date, 'day');
  const isDateExcluded = isSelectedDateExcluded(self.excludeDates, date);
  const isDateValid = self.props.filterDate(date);

  return dateInfMaxDate && dateSupMinDate && !isDateExcluded && isDateValid;
};
const checkValidityPerformance = (self, date) => {
  const isDateActive = isSelectedDateActive(self.props.highlightDates, date);
  const isDateLimitedSeats = isSelectedDateLimitedSeats(self.props.highlightDates, date);
  return checkValidityCommon(self, date) && (isDateActive || isDateLimitedSeats);
};
export const visitedDatePreselection = (self, date, isDynamicPricing, firstLoad) => {
  date = date === '' ? new Date() : convertJsDateToMomentObj(date);
  if (!isDynamicPricing) self.onLoad = false;

  if (firstLoad && localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.visitedDate) && isParkTenant()) {
    const visitedDate = convertJsDateToMomentObj(
      localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.visitedDate),
    );
    const disabledSelector = `.react-datepicker__day.date-${moment(visitedDate)
      .format(UIConfig.dateFormats.DD_MM_YYYY)
      .toLowerCase()}.react-datepicker__day--disabled`;
    const soldOutSelector = `.react-datepicker__day.date-${moment(visitedDate)
      .format(UIConfig.dateFormats.DD_MM_YYYY)
      .toLowerCase()}.sold-out`;
    const checkIdPreselectDayIsDIsabled = document.querySelector(disabledSelector);
    const checkIdPreselectDayIsSoldOut = document.querySelector(soldOutSelector);

    // in case of the product doesn't have a performance call then there is no need to check on the active-sates class
    const isActiveDate = self.props.hasPerformanceCall
      ? checkValidityPerformance(self, visitedDate)
      : checkValidityCommon(self, visitedDate);
    if (!checkIdPreselectDayIsDIsabled && !checkIdPreselectDayIsSoldOut && isActiveDate) date = visitedDate;
  }
  return date;
};
export const confgDfltSetngs = (self, secProducts) => {
  const stnSecProd = secProducts && secProducts.length ? deepCloneObject(secProducts) : [];
  stnSecProd.sort((a, b) => b.gross - a.gross);
  if (stnSecProd && stnSecProd.length) {
    self.product = self.combineCloneObject(self.product, stnSecProd[0]);
    self.handleSecondaryProdOffer(self.product);
  }
};

export const checkTnc = (self) => {
  const isCheckTnc =
    (self.props.multiTicketSelector && self.props.multiTicketSelector.hideTnC) ||
    (self.props.timeSlotData && self.props.timeSlotData.hideTnC);
  let checkTnc = true;
  if (!isCheckTnc && (self.props.showMinicartCouponCode || self.props.enablePJPopup)) {
    checkTnc = isCheckTnc;
  }
  return checkTnc;
};

export const getOverlaySelMapping = (self) => {
  let overlaySelMap = {};
  const {
    product: { productId },
  } = self.props;
  if (self.productOverlaySelector && self.productOverlaySelector.collections) {
    for (let itm in self.productOverlaySelector.collections) {
      if (self.props.data.cssClass === 'v-multi-product-widget') {
        if (self.productOverlaySelector.collections[itm].productId === productId) {
          overlaySelMap[self.productOverlaySelector.collections[itm].coveoval] = true;
          self.productOverlaySelector.collections[itm].defaultchecked = true;
          self.overlaySelAvailable = true;
        } else {
          overlaySelMap[self.productOverlaySelector.collections[itm].coveoval] = false;
          self.productOverlaySelector.collections[itm].defaultchecked = false;
        }
      } else {
        overlaySelMap[self.productOverlaySelector.collections[itm].coveoval] =
          self.productOverlaySelector.collections[itm].defaultchecked;
        if (self.productOverlaySelector.collections[itm].defaultchecked === true) self.overlaySelAvailable = true;
      }
    }
  }
  return overlaySelMap;
};

export const getDefaultAdditionalProd = (self) => {
  if (self.overlaySelAvailable) {
    // Change the coveo items order as per CMS
    const newProdObjTemp = {};
    let prodCoveoKey = '';
    let filterAdditionalProds = self.props.additionalProds;
    if (
      self.props.data.variant === 'v-multi-product-widget' ||
      self.props.data.variant === UIConfig.dyanmicPricingVariant
    ) {
      filterAdditionalProds = self.props.additionalProds.filter(({ productId }) =>
        self.overlaySelMap.hasOwnProperty(productId),
      );
    }
    filterAdditionalProds.forEach((prod, indx) => {
      if (
        self.props.data.variant === 'v-multi-product-widget' ||
        self.props.data.variant === UIConfig.dyanmicPricingVariant
      ) {
        prodCoveoKey = self.productOverlaySelector.collections.filter(
          ({ productId }) => prod.productId === productId,
        )[0].coveokey;
      } else {
        prodCoveoKey = self.productOverlaySelector.collections[indx].coveokey;
      }
      newProdObjTemp[prod[self.props.coveoMapList[prodCoveoKey]]] = prod;
    });
    let cntr = 0;
    Object.keys(self.overlaySelMap).forEach((itm) => {
      self.additionalProducts[cntr] = newProdObjTemp[itm];
      cntr += 1;
    });
    // xxxxxxx Order change code ends here
  }

  var addProd = [];
  if (self.overlaySelAvailable) {
    addProd =
      self.additionalProducts &&
      self.additionalProducts.length > 0 &&
      self.additionalProducts.filter((prod, indx) => {
        let prodCoveoKey = self.productOverlaySelector.collections[indx].coveokey;
        return self.overlaySelMap[prod[self.props.coveoMapList[prodCoveoKey]]] === true;
      });
  } else {
    let selectedProd = self.props.additionalProds[0];
    if (checkTenant(UIConfig.tenants.ymc)) {
      selectedProd =
        self.props.additionalProds?.find((prod) => prod.classType === 'ADULT') ?? self.props.additionalProds[0];
    }
    addProd.push(selectedProd);
  }
  return addProd[0];
};

export const scrollToNextSection = (self, section) => {
  let sectionEle = document.getElementsByClassName(section)[0];
  if (sectionEle) {
    if (section === 'react-datepicker') {
      self.isDesktop &&
        (sectionEle = document.querySelector('.react-datepicker-popper')
          ? document.querySelector('.react-datepicker-popper').parentElement
          : sectionEle);
      sectionEle = sectionEle.parentElement;
    }
    scrollTo(
      document.getElementsByClassName('overlay-wrapper')[0],
      section === 'c-date-selector--time-slot'
        ? sectionEle
          ? sectionEle.offsetTop
          : 0
        : sectionEle && sectionEle.nextElementSibling
        ? sectionEle.nextElementSibling.offsetTop
        : 0,
      500,
    );
  }
};

export const redirectToBookingPageCallback = (self, checkoutContext) => {
  const { data, closeOverlay, showMinicartCouponCode } = self.props;
  addLoaderOverlay();
  if (
    data.variant === 'v-multi-product-widget' ||
    (data.variant === UIConfig.dyanmicPricingVariant && checkoutContext.href)
  ) {
    redirectTo(checkoutContext.href);
  } else if (
    (self.isAdultChildOverlay || self.isQuantityInOverlay || self.isAddOnExperienceOverlay) &&
    !showMinicartCouponCode &&
    !(checkoutContext && checkoutContext.href)
  ) {
    window.location.href = data.addToCartCTA.href;
  }
  closeOverlay();
  if (!checkoutContext.href) {
    removeLoaderOverlay();
  }
};

export const addToCart = (self, checkoutContext) => {
  const { data, addToCartCallback, closeOverlay, counterData } = self.props;
  const elementText = checkoutContext?.label || checkoutContext?.currentTarget?.textContent;
  const btnType =
    checkoutContext?.currentTarget?.className === 'add-to-cart'
      ? UIConfig.ga4Constants.SECONDARY_CTA
      : UIConfig.ga4Constants.PRIMARY_CTA;
  GTMDataOnClickCTA({ category: btnType, elementText, name: `${data.name} - ${elementText}` });
  if (counterData) {
    self.product.maxCount = counterData.options[0].maximumQuantity;
    self.product.minCount = counterData.options[0].minimumQuantity;
  }
  if (
    self.product.itemType &&
    (self.product.itemType === UIConfig.moneyCardProduct.ticketType ||
      self.product.itemType === UIConfig.moneyCardProduct.flexibleTicketType) &&
    self.state.totalCalculatedAmount &&
    self.flexibleMoneyCard
  ) {
    if (self.state.cardAmount) {
      self.product.gross = self.state.cardAmount;
    }

    self.product.rechargeAmount = self.state.cardAmount ? self.state.cardAmount : self.product.gross;
  }
  if (self.state.maxAvailableTickets) {
    self.product.maxAvailableTickets = parseInt(self.state.maxAvailableTickets, 10);
    self.prodQty.forEach((item) => {
      item.maxAvailableTickets = parseInt(self.state.maxAvailableTickets, 10);
    });
  }
  //booking
  if (
    self.bookingOverlay ||
    (checkIfParks() &&
      self.props.subCategory &&
      (self.product.ticketType === 'VVIP' || self.product.ticketType === 'VIP'))
  ) {
    for (const category in self.state.ticketCount.primaryCounterCount) {
      if (self.state.ticketCount.primaryCounterCount[category] > 0) {
        const selectedProducts = self.props.copySearchProductList.filter(
          (prd) => prd.category && prd.category.includes(self.props.subCategory.toString()),
        );
        let filteredProduct = {};
        //ADDED FOR HIGHLIGHTDATE LOGIC
        if (
          self.calendarSettings.highlightDate &&
          self.calendarSettings.discountOptions &&
          self.calendarSettings.discountOptions.length > 0
        ) {
          filteredProduct =
            selectedProducts &&
            selectedProducts.length > 0 &&
            selectedProducts.find(
              (item) =>
                item.pricing === self.state.selectedDiscount.code &&
                item.classType &&
                item.classType.toLowerCase() === category.toLowerCase(),
            );
        } else if (self.props.data.cssClass === 'v-multi-product-widget') {
          filteredProduct = self.props.copySearchProductList.find((prd) => prd.productId === self.product.productId);
        } else {
          //addtocart for highlighted needs to be updated
          filteredProduct =
            selectedProducts &&
            selectedProducts.length > 0 &&
            selectedProducts.find((product) => product.classType.toLowerCase() === category.toLowerCase());
        }
        if (filteredProduct && Object.keys(filteredProduct).length > 0) {
          self.finalSelectedProducts.push(updateProductForCoveo(self, filteredProduct, category));
        }
      }
    }
  }

  if (self.isAdultChildOverlay && self.prodQty.length) {
    const updatedProdQty = self.prodQty.filter((item) => item.quantity > 0);
    addToCartCallback &&
      addToCartCallback(
        updatedProdQty,
        true,
        checkoutContext,
        () => self.redirectToBookingPageCallback(checkoutContext),
        '',
        self.props.data.variant === UIConfig.dyanmicPricingVariant ? UIConfig.dyanmicPricingVariant : '',
      );
  } else {
    //booking
    if (self.bookingOverlay || self.finalSelectedProducts?.length > 0) {
      addToCartCallback &&
        addToCartCallback(
          self.finalSelectedProducts && self.finalSelectedProducts.length > 0
            ? self.finalSelectedProducts
            : [self.product],
          self.finalSelectedProducts && self.finalSelectedProducts.length > 0 ? false : true,
          checkoutContext,
          () => self.redirectToBookingPageCallback(checkoutContext),
          '',
          self.props.data.variant === UIConfig.dyanmicPricingVariant ? UIConfig.dyanmicPricingVariant : '',
        );
      self.finalSelectedProducts = [];
    } else {
      addToCartCallback &&
        addToCartCallback(
          [self.product],
          true,
          checkoutContext,
          () => self.redirectToBookingPageCallback(checkoutContext),
          '',
          self.props.data.variant === UIConfig.dyanmicPricingVariant ? UIConfig.dyanmicPricingVariant : '',
        );
    }
  }
  const isProductWidget = self.props.childVariant && self.props.childVariant === 'v-overlay-selected' ? true : false;
  if (!isProductWidget) {
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: false,
    });
  }
  window.PubSub.subscribe('redirectToBookingPageCallback', (msg, data) => {
    if (
      data.redirectToBookingPage &&
      (self.props.data.variant === 'v-multi-product-widget' ||
        self.props.data.variant === UIConfig.dyanmicPricingVariant)
    ) {
      if (isProductWidget) {
        window.PubSub.publish('toggleOverlayState', {
          shouldOpen: false,
        });
      }
      redirectToBookingPageCallback(self, checkoutContext);
    }
  });
  if (!isProductWidget) {
    closeOverlay();
  }
  if (isParkTenant()) {
    localStorage.setItem(
      UIConfig.localStoreKeys.purchaseJourney.visitedDate,
      convertJsDateToMomentObj(self.state.visitedDate),
    );
  }
};

export const setVisitorsCount = (self, data) => {
  self.setState(
    (prevState) => {
      return {
        ticketCount: data.spinnerCount,
        initialCount:
          prevState.initialCount && self.props.data.variant === UIConfig.dyanmicPricingVariant
            ? deepCloneObject(prevState.initialCount)
            : deepCloneObject(data.spinnerCount),
        isProductOverlayClicked: data.isProductOverlayClicked,
      };
    },
    () => {
      self.calculateTotal();
      self.updateButtonState();
    },
  );
};
//booking
const updateProductForCoveo = (self, product, category) => {
  const selectedProdData =
    (self.counterData.options && self.counterData.options.find((data) => data.coveoValue === category)) || '';
  let isIncreaseDecreaseEnabled = false;
  if (self.counterData.options && self.counterData.options.length > 1) {
    if (self.counterData.options[1].coveoValue === category) {
      isIncreaseDecreaseEnabled = self.counterData.isIncreaseDecreaseEnabled;
    }
  }
  product.fromDate = self.product.fromDate ? self.product.fromDate : '';
  product.timeSlot = self.product.timeSlot ? self.product.timeSlot : '';
  product.quantity = self.state.ticketCount.primaryCounterCount[category];
  product.rangeInMonths = '';
  product.performanceId = self.product.performanceId ? self.product.performanceId : '';
  product.minCount = (selectedProdData && selectedProdData.minimumQuantity) || 0;
  product.maxCount = (selectedProdData && selectedProdData.maximumQuantity) || 0;
  product.tab = product.tab ? product.tab.toString() : product.tab;
  product.isIncreaseDecreaseEnabled = isIncreaseDecreaseEnabled || false;
  product.increaseDecreaseBy = (selectedProdData && selectedProdData.increaseDecreaseBy) || 1;
  return product;
};

export const calculateTotal = (self) => {
  let counterSum = 0;
  for (const counter in self.state.ticketCount) {
    if (self.state.ticketCount[counter]) {
      counterSum += Object.values(self.state.ticketCount[counter]).reduce((sum, value) => (sum += value), 0);
    }
  }
  const disableButton = counterSum > 0 && counterSum < parseInt(self.maxCount, 10) + 1;
  if (disableButton || self.isQuantityInOverlay) {
    let totalCalculatedAmount = 0;
    if (self.isAdultChildOverlay) {
      for (let idx = 0; idx < self.updatedAdditionalProds.length; idx++) {
        self.prodQty[idx] = self.updatedAdditionalProds[idx];
        self.prodQty[idx].performanceId = self.product.performanceId;
        self.prodQty[idx].timeSlot = self.product.timeSlot;
        if (self.isQuantityInOverlay && !self.props.data.enableRecommendation) {
          self.prodQty[idx].isFlexible = true;
          self.prodQty[idx].fromDate = moment().format(UIConfig.calendar.dateFormat);
          self.prodQty[idx].toDate = moment()
            .add(self.ticketValidity, 'months')
            .format(UIConfig.calendar.dateFormat);
        } else {
          self.prodQty[idx].fromDate = self.product.fromDate;
          self.prodQty[idx].toDate = self.product.toDate;
        }
        for (const category in self.state.ticketCount.primaryCounterCount) {
          if (self.updatedAdditionalProds[idx].classType === category) {
            self.prodQty[idx].quantity = self.state.ticketCount.primaryCounterCount[category];
            totalCalculatedAmount +=
              (self.flexibleMoneyCard
                ? priceValue(self.state.cardAmount)
                : parseFloat(self.updatedAdditionalProds[idx].gross).toFixed(2)) *
              self.state.ticketCount.primaryCounterCount[category];
            self.tnc
              ? self.setState({
                  disableAddToCart: !disableButton,
                  totalCalculatedAmount: toTwoDecimalPlaces(totalCalculatedAmount),
                })
              : self.setState(
                  {
                    totalCalculatedAmount: toTwoDecimalPlaces(totalCalculatedAmount),
                  },
                  () => self.updateButtonState(),
                );
          }
        }
      }
    } else {
      for (const category in self.state.ticketCount.primaryCounterCount) {
        if (self.state.ticketCount.primaryCounterCount[category] > 0) {
          self.product.quantity = self.state.ticketCount.primaryCounterCount[category];
          if (self.props.data.variant === UIConfig.dyanmicPricingVariant && !self.state.slotSelected) {
            totalCalculatedAmount = 0;
          } else {
            if (
              self.bookingOverlay ||
              (checkIfParks() &&
                self.props.subCategory &&
                (self.product.ticketType === 'VVIP' || self.product.ticketType === 'VIP'))
            ) {
              const selectedProducts = self.props.copySearchProductList.filter(
                (prd) => prd.category && prd.category.includes(self.props.subCategory.toString()),
              );
              let filteredProduct = {};
              //ADDED FOR HIGHLIGHTDATE LOGIC
              if (
                self.calendarSettings.highlightDate &&
                self.calendarSettings.discountOptions &&
                self.calendarSettings.discountOptions.length > 0
              ) {
                filteredProduct =
                  selectedProducts &&
                  selectedProducts.length > 0 &&
                  selectedProducts.find(
                    (item) =>
                      item.pricing === self.state.selectedDiscount.code &&
                      item.classType &&
                      item.classType.toLowerCase() === category.toLowerCase(),
                  );
              } else if (self.props.data.cssClass === 'v-multi-product-widget') {
                filteredProduct = self.props.copySearchProductList.find(
                  (prd) => prd.productId === self.product.productId,
                );
              } else {
                filteredProduct =
                  selectedProducts &&
                  selectedProducts.length > 0 &&
                  selectedProducts.find(
                    (product) => product.classType && product.classType.toLowerCase() === category.toLowerCase(),
                  );
              }
              if (filteredProduct && Object.keys(filteredProduct).length > 0) {
                //  self.finalSelectedProducts.push(updateProductForCoveo(self, filteredProduct, category));
                totalCalculatedAmount +=
                  parseFloat(filteredProduct.gross).toFixed(2) * self.state.ticketCount.primaryCounterCount[category];
              }
            } else {
              totalCalculatedAmount += self.flexibleMoneyCard
                ? priceValue(self.state.cardAmount) * self.state.ticketCount.primaryCounterCount[category]
                : self.product.gross
                ? parseFloat(self.product.gross).toFixed(2) * self.state.ticketCount.primaryCounterCount[category]
                : 0;
            }
          }
          self.tnc
            ? self.setState(
                {
                  disableAddToCart: !disableButton,
                  totalCalculatedAmount: toTwoDecimalPlaces(totalCalculatedAmount),
                },
                () => self.updateButtonState(),
              )
            : self.setState(
                {
                  totalCalculatedAmount: toTwoDecimalPlaces(totalCalculatedAmount),
                },
                () => self.updateButtonState(),
              );
        }
      }
    }
    //booking
    const selectedDateEle = document.querySelector('.react-datepicker__month');
    if (!self.enableDynamicCalendar && self.bookingOverlay && selectedDateEle) {
      selectedDateEle.classList.add('enable-active-dates');
    }
  } else {
    self.setState(
      {
        disableAddToCart: !disableButton,
        totalCalculatedAmount:
          self.props.data.variant === UIConfig.dyanmicPricingVariant ? parseFloat(0).toFixed(2) : '',
      },
      () => self.updateButtonState(),
    );
  }
};

export const addStyleTagDynamically = (self) => {
  const elem = document.getElementsByClassName('c-add-ons-overlay');
  let node = document.createElement('style');
  const textNode = document.createTextNode(self.dynamicStylesClasses());
  node.appendChild(textNode);
  elem && elem.length && elem[0].appendChild(node);
};

export const dynamicStylesClasses = (self) => {
  let dynamicStyle = '';
  const calendProds = self.productOverlaySelector.collections || [];
  calendProds &&
    calendProds.length &&
    calendProds.forEach((item) => {
      let colorCode = '#008484';
      if (item.colorCode) {
        colorCode = item.colorCode;
      }
      if (item.cssClassKey) {
        dynamicStyle += `.${item.cssClassKey} {
              color:${colorCode} !important;
          }
          .${item.cssClassKey}-icon {
              background-color:${colorCode} !important;
          }
          .react-datepicker__day--selected.${item.cssClassKey},
          .${item.cssClassKey}:hover {
              background-color:${colorCode} !important;
              color:#f5f5f5 !important;
          }
          `;

        if (item.cssClassKey.indexOf('sold-out') === -1) {
          dynamicStyle += `.${item.cssClassKey} {
          pointer-events: auto !important;
          cursor: pointer !important;
        }`;
        }
      }
    });
  return dynamicStyle;
};

export const initialOverlayPerformanceCall = (self) => {
  const endDataBasedOnRange = moment()
    .locale('en')
    .add(parseInt(self.calendarSettings.range, 10), 'days')
    .format(UIConfig.calendar.dateFormat);
  if (self.calendarSettings.range && self.calendarSettings.endDate) {
    const endDate = momentTimezone(self.calendarSettings.endDate, getClientUtcOffset()).format(
      UIConfig.calendar.dateFormat,
    );
    self.product.toDate = momentTimezone(endDataBasedOnRange, getClientUtcOffset()).isSameOrAfter(endDate, 'days')
      ? endDate
      : endDataBasedOnRange;
  } else {
    self.product.toDate = endDataBasedOnRange;
  }
  const selectedDateEle = document.querySelector('.react-datepicker__month');
  if (!self.enableDynamicCalendar && self.bookingOverlay && selectedDateEle) {
    selectedDateEle.classList.add('enable-active-dates');
  }
  self.setDate(moment());
};

const productsWithRelatedIds = (cartItems) => {
  return Object.values(cartItems).reduce((cartItem, prod) => {
    cartItem = [...cartItem, ...prod.products];
    return !!cartItem.length && cartItem.filter((item) => item.relatedProductIds);
  }, []);
};

export const getMinDateFromCartData = (product, { cart }) => {
  const items = !isEmpty(cart) && cart.items;
  const productswithRelatedIds = productsWithRelatedIds(items);

  const findFirstMatchedMainProd =
    !!productswithRelatedIds.length &&
    productswithRelatedIds.find(({ relatedProductIds }) => relatedProductIds.includes(product.productId));

  const datesSet =
    !isEmpty(findFirstMatchedMainProd) &&
    findFirstMatchedMainProd.fromDate &&
    new Date(findFirstMatchedMainProd.fromDate);
  return datesSet instanceof Date && !isNaN(datesSet.valueOf()) ? datesSet : null;
};

export const createTACData = (self, perfList) => {
  let availableDate = [],
    enabledDates = [],
    limitedDates = [],
    soldOutDates = [],
    availableDateMoment = [];
  const isYMCrecommendationTab =
    resolvePath(self.props, 'data.type', '') === UIConfig.b2c.purchaseJourney.recommendationTabCode &&
    isMatchTenant(UIConfig.tenants.ymc);
  if (perfList && perfList.performance && perfList.performance.length) {
    perfList.performance.forEach((item) => {
      if (availableDate.indexOf(item.date) === -1) {
        availableDate.push(item.date);
      }
    });

    const modifiedPerformanceData = availableDate.map((date) => {
      let available = 0,
        total = 0,
        data = [],
        sellable = '',
        productId = '';
      perfList.performance.forEach((filterItem) => {
        if (filterItem.date === date && filterItem.sellable === 'true') {
          sellable = filterItem.sellable;
          available += parseInt(filterItem.availability.available, 10);
          total += parseInt(filterItem.availability.total, 10);
          if (self.isMultiProductCal && filterItem.hasOwnProperty('productId')) {
            productId = filterItem.productId;
          }
          data.push(filterItem);
        }
      });
      return { date, total, available, performance: data, sellable, productId };
    });

    // initialize an array for combined product
    let enabledDatesObj = {};
    let productMap = {};
    if (self.isMultiProductCal && self.productOverlaySelector) {
      const calendProds = self.productOverlaySelector.collections || [];
      calendProds.forEach((id) => {
        if (!enabledDatesObj.hasOwnProperty(id.coveoval)) {
          enabledDatesObj[id.productId] = {
            key: id.cssClassKey,
            enabledDates: [],
          };
        }
      });
    }
    productMap = self.props.searchProductList.reduce((acc, curValue) => {
      return (acc = {
        ...acc,
        [curValue.productId]: { productId: curValue.productId },
      });
    }, {});

    modifiedPerformanceData &&
      modifiedPerformanceData.forEach((item) => {
        if ((Math.floor(item.total) && !Math.floor(item.available)) || item.sellable !== 'true') {
          // if total is 0 then that is unlimited seats scenario.
          soldOutDates.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
        } else {
          if (item.available < self.props.data.limitedAvailabilityThreshold) {
            limitedDates.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
          }
          if (self.isMultiProductCal && item.hasOwnProperty('productId')) {
            if (self.props.searchProductList && self.props.data.variant === UIConfig.dyanmicPricingVariant) {
              const legendObj = self.legends.find((legend) => legend.productId === item.productId);
              if (!enabledDatesObj[item.productId]) {
                enabledDatesObj = {
                  ...enabledDatesObj,
                  [item.productId]: {
                    enabledDates: [],
                    key: productMap[item.productId] ? productMap[item.productId].productId : null,
                    calendarColorCode: legendObj && legendObj.colorCode,
                    productName: productMap[item.productId] ? productMap[item.productId].productName : null,
                  },
                };
              }
              enabledDatesObj[item.productId].enabledDates.push(
                momentTimezone(item.date, getClientUtcOffset()).toDate(),
              );
              enabledDatesObj[item.productId].key = productMap[item.productId]
                ? productMap[item.productId].productId.replaceAll('.', '-')
                : null;
              enabledDatesObj[item.productId].productName = legendObj && legendObj.colorCode;
              availableDateMoment.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
            } else if (enabledDatesObj.hasOwnProperty(item.productId)) {
              enabledDatesObj[item.productId].enabledDates.push(
                momentTimezone(item.date, getClientUtcOffset()).toDate(),
              );
              availableDateMoment.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
            }
          } else {
            enabledDates.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
            availableDateMoment.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
          }
        }
      });

    let currentDate = moment().format(UIConfig.calendar.dateFormat);
    if (self.isTotalAvailabilityCal && !self.isMultiProductCal) {
      self.currentDateSlots = modifiedPerformanceData.find((performanceItem) => {
        return performanceItem.date === currentDate;
      });
    }

    const secCustomDates = [];
    if (self.isMultiProductCal) {
      Object.keys(enabledDatesObj).forEach((item) => {
        secCustomDates.push({
          [enabledDatesObj[item].key]: enabledDatesObj[item].enabledDates,
        });
      });
      if (self.props.data.variant === UIConfig.dyanmicPricingVariant) {
        secCustomDates.push({
          'current-date': [momentTimezone(currentDate, getClientUtcOffset()).toDate()],
        });
        document.querySelectorAll('.react-datepicker__day--keyboard-selected')[0] &&
          document
            .querySelectorAll('.react-datepicker__day--keyboard-selected')[0]
            .classList.remove('react-datepicker__day--keyboard-selected');

        document.querySelectorAll('.react-datepicker__day--selected')[0] &&
          document
            .querySelectorAll('.react-datepicker__day--selected')[0]
            .classList.remove('react-datepicker__day--keyboard-selected');
      }
    } else {
      if (self.currentDateSlots && self.currentDateSlots.available) {
        secCustomDates.push({
          'current-date-selected': [momentTimezone(currentDate, getClientUtcOffset()).toDate()],
        });
      }
      secCustomDates.push({
        'available-seats': enabledDates,
      });
    }
    const highlightRange =
      self.state.selectedDiscount && Object.keys(self.state.selectedDiscount).length > 0
        ? self.state.selectedDiscount.highlightRange
        : [];
    const customDates = [
      ...secCustomDates,
      {
        'limited-seats': limitedDates,
      },
      {
        'sold-out': soldOutDates,
      },
      {
        'active-dates': availableDateMoment,
      },
      ...highlightRange,
    ];
    const removeDatePreselection = !self.bookingOverlay ? moment() : '';

    self.setState(
      {
        customDates,
        modifiedPerformanceData,
        minDateFromCartData: isYMCrecommendationTab ? getMinDateFromCartData(self.product, self.props.cartData) : '',
      },
      () => {
        !self.isMultiProductCal &&
          self.isTotalAvailabilityCal &&
          self.currentDateSlots &&
          self.currentDateSlots.available &&
          self.props.data.cssClass !== 'v-multi-product-widget' &&
          self.calendarDateCallback(removeDatePreselection, null, true);
      },
    );
    if (
      self.isDynamicPricingProd &&
      (self.calendarSettings.enableDatePreSelection || enableVisitedDate()) &&
      modifiedPerformanceData &&
      modifiedPerformanceData.length > 0
    ) {
      self.setState({
        selectDefaultDate: true,
      });
      setTimeout(() => {
        const keyboardSelectedDateCssRemove = document.querySelectorAll('.react-datepicker__day--keyboard-selected');
        if (keyboardSelectedDateCssRemove && keyboardSelectedDateCssRemove[0]) {
          keyboardSelectedDateCssRemove[0].classList.remove('react-datepicker__day--keyboard-selected');
        }
      }, 10);
    }
  } else {
    self.setState({
      performanceData: { error: { errorcode: '7001' } },
      slotSelected: false,
      disableAddToCart: true,
      customDates: [],
    });
  }
};

export const defaultDropDownSelection = (self, isMount = true, selectedDate) => {
  const { performanceData } = self.state;
  const updatedPerformanceData = performanceData.performance.filter(
    (item) => item.sellable === 'true' && item.availability.available !== '0',
  );

  const unlimitedPerformanceProduct = performanceData.performance.filter(
    (item) =>
      !((Math.floor(item.availability.total) && !Math.floor(item.availability.available)) || item.sellable !== 'true'),
  );

  if (unlimitedPerformanceProduct.length === 1 && self.props.data.cssClass === 'v-multi-product-widget') {
    self.setState({ carProdsClicked: false });
  }
  if ((!isMount || isMount !== null) && self.props.data.cssClass === 'v-multi-product-widget') {
    self.product.toDate = self.product.fromDate;
  }
  if (!self.bookingOverlay) {
    if (updatedPerformanceData.length === 1) {
      let event = {
        value: updatedPerformanceData[0].performanceId,
        disabled:
          updatedPerformanceData[0].sellable === 'false' || updatedPerformanceData[0].availability.available === '0',
        text: self.timeFormatTac(updatedPerformanceData[0]),
      };
      if (
        !checkTenant(UIConfig.tenants.fwad) ||
        (self.product.ticketType !== UIConfig.ProductType.VVIP && self.product.ticketType !== UIConfig.ProductType.VIP)
      ) {
        self.selectTimeSlot(event, true);
      }
    }
  } else {
    //booking
    if (
      self.props.timeSlotData &&
      self.props.data.variant === UIConfig.dyanmicPricingVariant &&
      self.bookingOverlay &&
      selectedDate
    ) {
      if (updatedPerformanceData.length === 1) {
        let event = {
          value: updatedPerformanceData[0].performanceId,
          disabled:
            updatedPerformanceData[0].sellable === 'false' || updatedPerformanceData[0].availability.available === '0',
          text: self.timeFormatTac(updatedPerformanceData[0]),
        };
        if (
          !checkTenant(UIConfig.tenants.fwad) ||
          (self.product.ticketType !== UIConfig.ProductType.VVIP &&
            self.product.ticketType !== UIConfig.ProductType.VIP)
        ) {
          self.selectTimeSlot(event, true);
        }
      } else {
        self.getPerformanceProduct();
      }
    }
  }
};

export const calendarDateCallback = (self, date, mount, preventScroll) => {
  self.setState(
    {
      selectDefaultDate: false,
      carProdsClicked: false,
      slotSelected: self.props.timeSlotData ? false : self.state.slotSelected,
      totalCalculatedAmount: self.props.timeSlotData ? '' : self.state.totalCalculatedAmount,
      visitedDate: date,
    },
    () => {
      self.calculateTotal();
    },
  );
  const isMount = mount && typeof mount === 'boolean';
  let selectedDate = date ? date.format(UIConfig.calendar.dateFormat) : '';
  self.product.fromDate = selectedDate;
  const performanceData = self.state.modifiedPerformanceData.filter((item) => item.date === selectedDate)[0];
  if (self.calendarSettings && self.calendarSettings.highlightDate) {
    self.toggleRangeAndDiscount(null, true, date.clone());
  }
  self.product &&
    self.fallBackProducts.baseProduct &&
    self.resetPriceDescriptionForCounter(selectedDate, performanceData);

  if (performanceData && performanceData.hasOwnProperty('performance') && performanceData.performance.length) {
    self.product.performanceId = '';
    self.product.timeSlot = '';
    self.calendarDateChosen = true;
    if (self.props.data.variant !== UIConfig.dyanmicPricingVariant) {
      self.setState({
        totalCalculatedAmount: '',
        slotSelected: false,
        disableAddToCart: true,
      });
    }
    self.setState(
      {
        performanceData,
        date: date,
        selectedTimeSlot: null,
        selectedTicketId: null,
        maxAvailableTickets: '',
      },
      () => {
        self.defaultDropDownSelection(isMount, selectedDate);
        const { customDates } = self.state;
        if (!preventScroll && customDates) {
          const currentDate = customDates.find((item) => item.hasOwnProperty('current-date-selected'));
          if (currentDate) {
            const updatedCustomDates = customDates.filter((item) => !item.hasOwnProperty('current-date-selected'));
            self.setState({ customDates: updatedCustomDates });
          }
          if (selectedDate && !isMount) {
            if (
              self.props.data.variant === UIConfig.dyanmicPricingVariant &&
              self.props.timeSlotData &&
              self.props.timeSlotData.showSingleTimeSlot &&
              !isEmpty(self.props.timeSlotData)
            ) {
              self.scrollToNextSection('c-date-selector--time-slot');
            } else {
              const seperator = document.getElementsByClassName('v-line-separator')?.[0];
              if (seperator) {
                self.scrollToNextSection('v-line-separator');
              } else {
                self.scrollToNextSection('react-datepicker');
              }
            }
          }
        }
      },
    );
    //booking
  } else if (!self.isMultiProductCal && self.bookingOverlay) {
    self.setState({ slotSelected: selectedDate ? true : false });
    if (selectedDate && !isMount) {
      if (
        self.props.data.variant === UIConfig.dyanmicPricingVariant &&
        self.props.timeSlotData &&
        !isEmpty(self.props.timeSlotData) &&
        self.props.timeSlotData.showSingleTimeSlot
      ) {
        self.scrollToNextSection('c-date-selector--time-slot');
      } else {
        self.scrollToNextSection('react-datepicker');
      }
    }
  } else {
    if (self.product.hasperformance === '1' && self.enableDynamicCalendar) {
      if (self.state.fetchMultiProductPerformance) {
        self.setState({
          performanceData: { error: { errorcode: '7001' } },
          slotSelected: false,
          disableAddToCart: true,
        });
      }
    } else
      self.setState({ performanceData: { error: { errorcode: '7001' } }, slotSelected: false, disableAddToCart: true });
  }
};

export const timeFormat = (self, performance) => {
  const openTime = moment
      .utc(self.product.fromDate + ' ' + performance.openTime)
      .locale('en')
      .format(UIConfig.calendar.timeFormat),
    closeTime = moment
      .utc(self.product.fromDate + ' ' + performance.closeTime)
      .locale('en')
      .format(UIConfig.calendar.timeFormat);
  return openTime + ' - ' + closeTime;
};

export const timeFormatTac = (self, performance) => {
  const openTime = moment
      .utc(self.product.fromDate + ' ' + performance.openTime)
      .locale('en')
      .format(UIConfig.calendar.timeFormat),
    closeTime = moment
      .utc(self.product.fromDate + ' ' + performance.closeTime)
      .locale('en')
      .format(UIConfig.calendar.timeFormat);
  return self.props.timeSlotData && self.props.timeSlotData.showEndTime ? openTime + ' - ' + closeTime : openTime;
};

export const selectTimeSlot = (self, event, isAutoSelected, isSwad) => {
  let newEvent = event;
  if (isSwad && event.text) {
    const regex = '<span.*?>(.*?)<\\/span> <span.*?>';
    if (event.text.match(regex)) {
      newEvent.text = event.text.match(regex)[1];
    }
  }
  if (self.props.data.variant === UIConfig.dyanmicPricingVariant) {
    const currentDateCssRemove = document.querySelectorAll('.current-date');
    if (currentDateCssRemove && currentDateCssRemove[0]) {
      currentDateCssRemove[0].classList.remove('current-date');
    }
  }
  self.product.performanceId = newEvent.value;
  self.product.timeSlot = newEvent.text.split('(')[0].trim();
  if (!self.enableTimeSlotExperience && !isSwad && !checkTenant(UIConfig.tenants.ymc)) delete self.product.timeSlot;
  let maxAvailableTickets = self.state.performanceData.performance.filter(
    (perf) => perf.performanceId === newEvent.value,
  )[0].availability.available;

  if (self.props.data.cssClass === 'v-multi-product-widget') {
    maxAvailableTickets = self.state.maxAvailableTickets;
  }

  const sameTimeSlotProds = self.state.performanceData.performance.reduce((sameTimeProducts, performance) => {
    if (sameTimeProducts.length === 0) {
      sameTimeProducts = [...sameTimeProducts, performance];
    }
    if (sameTimeProducts.find((prod) => prod.openTime === performance.openTime)) {
      sameTimeProducts = [...sameTimeProducts, performance];
    }
    return sameTimeProducts;
  }, []);
  self.setState(
    {
      slotSelected: true,
      maxAvailableTickets,
      carProdsClicked: false,
      resetonTabChange: false,
      selectedTimeSlot: self.product.timeSlot,
      selectedTicketId: self.product.productId,
    },
    () => {
      self.calculateTotal();
    },
  );
  !isAutoSelected && self.scrollToNextSection('c-date-selector--time-slot');
  if (self.isTotalAvailabilityCal) {
    self.slctdSecIdx = 0;
    if (isMatchTenant(UIConfig.tenants.ymc) && sameTimeSlotProds && sameTimeSlotProds.length > 1) {
      self.getPromotionProducts();
    } else {
      if (
        !(
          self.props.data.variant === UIConfig.dyanmicPricingVariant &&
          self.props.timeSlotData &&
          (!self.bookingOverlay || self.props.data.cssClass === 'v-multi-product-widget')
        )
      )
        self.getPromotionProducts();
    }
  }

  self.props.checkAvailablity &&
    self.props.checkAvailablity(self.product.productId, self.state?.performanceData?.available);
  self.updateButtonState();
};

export const filterOutOfferProductsCoveo = (self, baseProduct = []) => {
  return (
    self.props.searchProductList &&
    self.props.searchProductList.filter((item) => {
      let matchedData = false;
      baseProduct.forEach((prod) => {
        if (item.code === prod.code && item.productId !== prod.productId) {
          matchedData = true;
        }
      });
      return matchedData;
    })
  );
};

export const filterPromotionProduct = (self, availablePromProduct, coveoProducts) => {
  let promotionProduct = [];
  availablePromProduct.forEach((item) => {
    item.productId.forEach((prodId) => {
      const searchProduct = coveoProducts.filter((prod) => prod.productId === prodId);
      searchProduct && searchProduct.length && promotionProduct.push(searchProduct[0]);
    });
  });
  return promotionProduct;
};

export const filterSecondaryPromotionProduct = (self, coveoSecPromProd, baseProd, availableProProduct) => {
  const secFilterProduct = coveoSecPromProd.filter(
    (dynProd) => dynProd.experienceCatgory !== baseProd.experienceCatgory,
  );
  const groupSecondaryProducts = self.groupSecondaryProducts(secFilterProduct);
  let dynamicProduct = [];
  let promotionProduct = [];
  Object.keys(groupSecondaryProducts).forEach((category) => {
    let offerData = '';
    groupSecondaryProducts[category].forEach((item) => {
      availableProProduct.forEach((prod) => {
        if (prod.productId.indexOf(item.productId) !== -1) {
          offerData = item;
          promotionProduct.push(item);
        }
      });
    });
    if (offerData) {
      if (
        isMatchTenant(UIConfig.tenants.ymc) &&
        groupSecondaryProducts?.[category]?.length > 1 &&
        offerData?.productId === self?.product?.productId
      ) {
        offerData.fromDate = self?.product?.fromDate;
      }
      dynamicProduct.push(offerData);
    } else {
      const filterData = self.secProds.find((item) => item.experienceCatgory === category);
      filterData && dynamicProduct.push(filterData);
    }
  });
  if (dynamicProduct && dynamicProduct.length) {
    self.confgDfltSetngs(dynamicProduct);
    self.secProds = dynamicProduct;
  }

  return promotionProduct;
};

export const checkPromotionProduct = (self, availableProProduct) => {
  let promotionProduct = [];
  if (self.secProds.length && self.isAddOnExperienceOverlay && self.product.hasOwnProperty('experienceCatgory')) {
    let coveoPrimaryProducts = self.filterOutOfferProductsCoveo([self.primaryProd]) || [];
    const primaryData = coveoPrimaryProducts.filter(
      (item) => item.experienceCatgory === self.primaryProd.experienceCatgory,
    );
    let promotionPrimaryProduct = self.filterPromotionProduct(availableProProduct, primaryData);
    if (promotionPrimaryProduct && promotionPrimaryProduct.length) {
      self.primaryProd = self.combineCloneObject(self.primaryProd, promotionPrimaryProduct[0]);
      promotionProduct.push(promotionPrimaryProduct[0]);
    }
    if (
      isMatchTenant(UIConfig.tenants.ymc) &&
      self.product?.fromDate &&
      (self.secProds?.[0]?.fromDate || self.primaryProd.fromDate)
    ) {
      self.secProds[0].fromDate = self.product.fromDate;
      self.primaryProd.fromDate = self.product.fromDate;
    }
    const coveoSecPromProd = self.filterOutOfferProductsCoveo(self.secProds) || [];
    const SecPromotion = self.filterSecondaryPromotionProduct(coveoSecPromProd, self.primaryProd, availableProProduct);
    if (SecPromotion && SecPromotion.length) {
      promotionProduct = promotionProduct.concat(SecPromotion);
    }
    promotionProduct.length && self.calculateTotal();
  } else if (self.isAdultChildOverlay) {
    const coveoPromProd = self.filterOutOfferProductsCoveo(self.props.additionalProds) || [];
    const filterPromotions = self.filterPromotionProduct(availableProProduct, coveoPromProd);
    const addProds = [];
    if (filterPromotions.length) {
      self.props.additionalProds.forEach((updatedAddProds, idx) => {
        const orderedAddProd = filterPromotions.find((proProd) => proProd.classType === updatedAddProds.classType);
        if (orderedAddProd) {
          const baseProductPrice =
            self.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(updatedAddProds.gross));
          addProds.push({ ...orderedAddProd, baseProductPrice });
          self.updatedVisitorCounterPrice(orderedAddProd, idx, updatedAddProds);
          orderedAddProd.classType === self.product.classType && self.updateBaseProduct(orderedAddProd, false);
        } else {
          addProds.push(updatedAddProds);
        }
      });
      self.updatedAdditionalProds = addProds;
      self.calculateTotal();
      promotionProduct = filterPromotions;
    }
  } else {
    const coveoBaseProducts = self.filterOutOfferProductsCoveo([self.product]) || [];
    const promotionProducts = self.filterPromotionProduct(availableProProduct, coveoBaseProducts);
    if (promotionProducts && promotionProducts.length && !self.isPackageJourney) {
      self.updateBaseProduct(promotionProducts[0], true);
      promotionProduct.push(promotionProducts[0]);
    }
  }

  if (!promotionProduct.length) {
    self.checkDynamicProduct();
  }
};

export const updatedVisitorCounterPrice = (self, primaryProduct, idx, baseProduct) => {
  if (self.props.data.variant !== UIConfig.dyanmicPricingVariant) {
    self.props.counterData.options[idx].description =
      self.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(primaryProduct.gross));
  }
  if (baseProduct) {
    self.props.counterData.options[idx].baseProductPrice =
      self.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(baseProduct.gross));
    self.product.baseProductPrice = self.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(baseProduct.gross));
  } else {
    self.props.counterData.options[idx].baseProductPrice = null;
    self.product.baseProductPrice = null;
  }
  self.calculateTotal();
};

export const updateBaseProduct = (self, prod, isPriceUpdateRequired) => {
  if (prod) {
    self.product = self.combineCloneObject(self.product, prod);
    isPriceUpdateRequired && self.updatedVisitorCounterPrice(self.product, 0, self.fallBackProducts.baseProduct);
    isPriceUpdateRequired && self.calculateTotal();
    if (self.secProds.length) {
      self.primaryProd = deepCloneObject(prod);
    }
  }
};

export const combineCloneObject = (self, obj, copyObj) => {
  return [obj, copyObj].reduce(function(primaryProd, secProd) {
    Object.keys(secProd).forEach(function(key) {
      primaryProd[key] = secProd[key];
    });
    return primaryProd;
  }, {});
};

export const fallBackInitialProducts = (self) => {
  self.fallBackProducts = {
    baseProduct: deepCloneObject(self.product),
    secPrds: deepCloneObject(self.secProds),
    primaryProd: deepCloneObject(self.primaryProd),
  };
};

export const resetPriceDescriptionForCounter = (self, selectedDate, performanceData) => {
  let filterDateProd = [];
  self.product = self.fallBackProducts.baseProduct;
  if (
    self.isMultiProductCal &&
    performanceData &&
    performanceData.hasOwnProperty('productId') &&
    performanceData.productId
  ) {
    if (self.props.data.variant === UIConfig.dyanmicPricingVariant) {
      filterDateProd = self.props.copySearchProductList.filter((item) => item.productId === performanceData.productId);
      self.product = Array.isArray(filterDateProd) && filterDateProd.length && filterDateProd[0];
    } else {
      filterDateProd = self.props.additionalProds.filter((item) => item.productId === performanceData.productId);
    }
    if (filterDateProd && filterDateProd.length && self.product.productId !== filterDateProd[0].productId) {
      self.product = filterDateProd[0];
      self.fallBackProducts.baseProduct = filterDateProd[0];
    }
  }
  self.product.fromDate = selectedDate;
  if (self.isAdultChildOverlay && self.props.counterData.options.length === self.props.additionalProds.length) {
    const additionalProductArray = deepCloneObject(self.props.additionalProds);
    additionalProductArray[0].classType.toLowerCase() !== self.props.counterData.options[0].coveoValue.toLowerCase() &&
      additionalProductArray.reverse();
    additionalProductArray.forEach((item, idx) => {
      self.resetPriceLabelCounter(item, idx);
    });
    self.updatedAdditionalProds = self.props.additionalProds;
  } else if (
    self.secProds.length &&
    self.isAddOnExperienceOverlay &&
    self.product.hasOwnProperty('experienceCatgory')
  ) {
    self.resetPriceLabelCounter(self.product, 0);
    self.secProds = self.fallBackProducts.secPrds;
    self.primaryProd = self.fallBackProducts.primaryProd;
    self.slctdSecIdx = 0;
  } else {
    self.resetPriceLabelCounter(self.product, 0);
  }
  if (self.props.data.variant === UIConfig.dyanmicPricingVariant && !self.props.timeSlotData) {
    self.setState(
      {
        slotSelected: true,
      },
      () => {
        self.calculateTotal();
      },
    );
  } else {
    self.calculateTotal();
  }
};

export const resetPriceLabelCounter = (self, product, idx) => {
  if (self.props.counterData.options.length) {
    if (self.props.data.variant !== UIConfig.dyanmicPricingVariant) {
      self.props.counterData.options[idx].description =
        self.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(product.gross));
    }
    self.props.counterData.options[idx].baseProductPrice = null;
    self.product.baseProductPrice = null;
  }
};

export const calDateDelta = (self, date) => {
  return momentTimezone(date, getClientUtcOffset())
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
};

export const filterPrimaryDynamicProd = (self, similarDynamicProd, baseProd, days, disableDiscount) => {
  const discountMap = self.props.discountMap,
    getDisProd = self.props.getDiscountedProducts;
  let dynamicProduct = [];
  const similarProducts = similarDynamicProd.filter(
    (dynProd) => dynProd.experienceCatgory === baseProd.experienceCatgory,
  );
  const dynamicProds = getDisProd(similarProducts || [], days, null, disableDiscount);
  if (dynamicProds && dynamicProds.length && discountMap && discountMap.length) {
    dynamicProduct = dynamicProds.find((item) => item.pricing !== discountMap[0].code);
  }
  return dynamicProduct;
};

export const filterSecondaryDynamicProd = (self, similarDynamicProd, baseProd, days, disableDiscount) => {
  const discountMap = self.props.discountMap,
    getDisProd = self.props.getDiscountedProducts;
  const secFilterProduct = similarDynamicProd.filter(
    (dynProd) => dynProd.experienceCatgory !== baseProd.experienceCatgory,
  );
  const groupSecondaryProducts = self.groupSecondaryProducts(secFilterProduct);
  let dynamicProduct = [];
  Object.keys(groupSecondaryProducts).forEach((experienceCatgory) => {
    const dynamicProds = getDisProd(groupSecondaryProducts[experienceCatgory] || [], days, null, disableDiscount);
    if (dynamicProds && dynamicProds.length && discountMap && discountMap.length) {
      const offerProd = dynamicProds.find((item) => item.pricing !== discountMap[0].code);
      if (offerProd) {
        dynamicProduct.push(offerProd);
      } else {
        const withoutOffer = self.secProds.find((item) => item.experienceCatgory === experienceCatgory);
        withoutOffer && dynamicProduct.push(withoutOffer);
      }
    }
  });
  if (dynamicProduct && dynamicProduct.length) {
    self.secProds = dynamicProduct;
    self.confgDfltSetngs(dynamicProduct);
  }
};

export const groupSecondaryProducts = (self, products) => {
  const grouppedProducts = {};
  products.forEach((product) => {
    if (product.experienceCatgory) {
      if (!grouppedProducts.hasOwnProperty(product.experienceCatgory)) grouppedProducts[product.experienceCatgory] = [];

      grouppedProducts[product.experienceCatgory].push(product);
    }
  });
  return grouppedProducts;
};

export const checkDynamicProduct = (self) => {
  let similarDynamicProd = self.filterOutOfferProductsCoveo([self.product]) || [];
  const days = self.calDateDelta(self.state.date),
    discountMap = self.props.discountMap,
    disableDiscount =
      self.props.multiTicketSelector &&
      self.props.multiTicketSelector.stringMapping &&
      self.props.multiTicketSelector.stringMapping.disableDiscount === 'true',
    getDisProd = self.props.getDiscountedProducts;

  // let newSecProds;
  self.daysDelta = days;
  if (self.secProds.length && self.isAddOnExperienceOverlay && self.product.hasOwnProperty('experienceCatgory')) {
    let similarPrimaryDynamicProd = self.filterOutOfferProductsCoveo([self.primaryProd]) || [];
    const primaryProduct = self.filterPrimaryDynamicProd(
      similarPrimaryDynamicProd,
      self.primaryProd,
      days,
      disableDiscount,
    );
    if (primaryProduct && primaryProduct.length) {
      self.primaryProd = self.combineCloneObject(self.primaryProd, primaryProduct[0]);
    }
    const similarSecDynamicProd = self.filterOutOfferProductsCoveo(self.secProds) || [];
    self.filterSecondaryDynamicProd(similarSecDynamicProd, self.primaryProd, days, disableDiscount);
    self.calculateTotal();
  } else if (self.isAdultChildOverlay) {
    const addProds = [];
    let similarDynamicProd = self.filterOutOfferProductsCoveo(self.props.additionalProds) || [];
    self.props.additionalProds.forEach((updatedAddProds, idx) => {
      const PrimarySimilarPro = similarDynamicProd.filter((dynProd) => dynProd.classType === updatedAddProds.classType);
      const dynPrimaryProd = getDisProd(PrimarySimilarPro || [], days, null, disableDiscount);
      if (dynPrimaryProd && dynPrimaryProd.length && discountMap && discountMap.length) {
        const orderedAddProd = dynPrimaryProd.find((item) => item.pricing !== discountMap[0].code);
        if (orderedAddProd) {
          const baseProductPrice =
            self.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(updatedAddProds.gross));
          addProds.push({ ...orderedAddProd, baseProductPrice });
          self.updatedVisitorCounterPrice(orderedAddProd, updatedAddProds, idx);
          orderedAddProd.classType === self.product.classType && self.updateBaseProduct(orderedAddProd, false);
        } else {
          addProds.push(updatedAddProds);
        }
      } else {
        addProds.push(updatedAddProds);
      }
    });
    self.updatedAdditionalProds = addProds;
    self.calculateTotal();
  } else {
    const dynamicProducts = getDisProd(similarDynamicProd || [], days, null, disableDiscount);
    if (dynamicProducts && dynamicProducts.length && discountMap && discountMap.length) {
      const dynamicProduct = dynamicProducts && dynamicProducts.find((item) => item.pricing !== discountMap[0].code);
      dynamicProduct && dynamicProduct.length && self.updateBaseProduct(dynamicProduct, true);
    }
  }
};

const getFormattedDates = (dates) => {
  return dates && dates.length
    ? dates.map((dateString) => momentTimezone(dateString, getClientUtcOffset()).toDate())
    : null;
};

export const allIncludedDates = (self) => {
  const allEnabledDates = getFormattedDates(self.calendarSettings.enabledDates);
  const includeDates = allEnabledDates
    ? allEnabledDates.sort(function(left, right) {
        return moment(left).format('X') - moment(right).format('X');
      })
    : null;

  const currentDate = moment();
  return includeDates
    ? includeDates.filter((d) => momentTimezone(d, getClientUtcOffset()).isSameOrAfter(currentDate, 'days'))
    : includeDates;
};

const isSameDate = (self, date) => {
  const includeDateLogic = allIncludedDates(self);
  const findDate =
    includeDateLogic &&
    includeDateLogic.find((item) =>
      momentTimezone(item, getClientUtcOffset()).isSame(momentTimezone(date, getClientUtcOffset()), 'day'),
    );
  if (findDate) {
    return true;
  }
  return false;
};

export const isValidDay = (self, date) => {
  date = convertJsDateToMomentObj(date);
  if (!self.validOnRange.length) {
    return 1;
  }
  if (isSameDate(self, date)) {
    return true;
  }

  const day = date.day();
  return self.validOnRange.indexOf(day) >= 0;
};

const getPrimaryCounterCount = (self, productId) => {
  const selectedProduct = self.productOverlaySelector?.collections?.find((item) => item.coveoval === productId);
  const allOptions = self.counterData?.options;
  const primaryCounter =
    allOptions?.length &&
    allOptions?.reduce((res, curr) => {
      if (curr.type === selectedProduct.type) {
        const minQty = Math.max(parseInt(curr.defaultQuantity, 10), parseInt(curr.minimumQuantity || 0, 10));
        res[curr.coveoValue ? curr.coveoValue : curr.name] = minQty;
      }
      return res;
    }, {});
  return primaryCounter;
};

export const isVIPPrivateOrShared = (self) =>
  self.props?.productOverlaySelector?.collections?.length &&
  self.props.subCategory === UIConfig.ProductType.VIP &&
  isMatchTenant(UIConfig.tenants.fwad);

export const getVIPProductOptions = (self, productId) => {
  if (isVIPPrivateOrShared(self)) {
    const selectedProduct = self.productOverlaySelector?.collections?.find((item) => item.coveoval === productId);
    const allOptions = [...self.counterData?.options];
    const selectedOptions = selectedProduct && allOptions?.filter((option) => option.type === selectedProduct.type);
    return selectedOptions;
  }
  return null;
};

export const getVIPCounterData = (self, productId) => {
  if (isVIPPrivateOrShared(self)) {
    const options = getVIPProductOptions(self, productId);
    return { ...self.props.counterData, options: options };
  }
  return self.props.counterData;
};

export const addCarsCallBack = (self, evt) => {
  const currentProduct =
    (self.props.data.variant === 'v-multi-product-widget' ||
      self.props.data.variant === UIConfig.dyanmicPricingVariant) &&
    self.state.isMobile &&
    !evt.target
      ? evt
      : deepCloneObject(JSON.parse(evt.target.dataset.prod));
  if (self.state.isVIPPrivateOrShared) {
    self.setState({
      vipCounterData: getVIPCounterData(self, currentProduct.productId),
    });
  }
  self.product = currentProduct;

  const additionalProds =
    self.updatedAdditionalProds?.filter((prd) => prd?.ticketType === currentProduct?.ticketType && prd?.productId !== currentProduct?.productId) || {};

  if(additionalProds.length) {
    self.GTMDataOnSelectItem([currentProduct, ...additionalProds]);
  } else {
    self.GTMDataOnSelectItem([currentProduct]);
  }

  self.fallBackProducts.baseProduct = currentProduct;
  if (self.isTotalAvailabilityCal) {
    self.initialOverlayPerformanceCall();
    self.setState({ date: null });
    self.calendarDateChosen = false;
    if (self.props.data.variant === UIConfig.dyanmicPricingVariant) {
      self.product.gross = 0;
      self.props.counterData.options[0].description = '';
      const keyboardSelectedDateCssRemove = document.querySelectorAll('.react-datepicker__day--keyboard-selected');
      const selectedDateCssRemove = document.querySelectorAll('.react-datepicker__day--selected');
      if (keyboardSelectedDateCssRemove && keyboardSelectedDateCssRemove[0]) {
        keyboardSelectedDateCssRemove[0].classList.remove('react-datepicker__day--keyboard-selected');
      }
      if (!self.state.isVIPPrivateOrShared && selectedDateCssRemove && selectedDateCssRemove[0]) {
        selectedDateCssRemove[0].classList.remove('react-datepicker__day--selected');
      }
      self.state.initialCount &&
        !isEmpty(self.state.initialCount) &&
        self.setVisitorsCount({
          //self.state.initialCount,
          spinnerCount: self.state.isVIPPrivateOrShared
            ? { primaryCounterCount: getPrimaryCounterCount(self, currentProduct.productId) }
            : self.state.initialCount,
          isProductOverlayClicked: true,
        });
    } else {
      if (self.props.data.variant !== UIConfig.dyanmicPricingVariant) {
        self.props.counterData.options[0].description =
          self.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(self.product.gross));
      }
    }
  } else if (self.calendarDateChosen) {
    self.setDate(self.state.date);
  }
  self.setState({
    totalCalculatedAmount: self.props.data.variant === UIConfig.dyanmicPricingVariant ? parseFloat(0).toFixed(2) : '',
    disableAddToCart: true,
    performanceData: {},
    slotSelected: false,
    maxAvailableTickets: '',
    carProdsClicked: true,
  });

  self.scrollToNextSection('exctmnt');
  if (self.props.data.variant === 'v-dynamic-product-pricing' || self.props.data.variant === 'v-multi-product-widget')
    self.calculateTotal();
};

export const getDefaultCheckedValue = (self, prod) => {
  if (prod) {
    return Boolean(
      (prod.ticketVal && self.overlaySelMap && self.overlaySelMap[prod.ticketVal]) ||
        prod.productId === self.defaultSelAdditionalProds.productId,
    );
  }
  return false;
};

export const handleSecondaryProdOffer = (self, currentProduct) => {
  const currentPrice = parseFloat(currentProduct.gross).toFixed(2);
  if (currentProduct.experienceCatgory === self.primaryProd.experienceCatgory) {
    const primaryPrice = parseFloat(self.fallBackProducts.primaryProd.gross).toFixed(2);
    self.updatedVisitorCounterPrice(
      currentProduct,
      0,
      primaryPrice > currentPrice ? self.fallBackProducts.primaryProd : false,
    );
  } else {
    let filterBaseProd;
    if (isMatchTenant(UIConfig.tenants.ymc)) {
      const secProds = self?.fallBackProducts?.secPrds;
      const sletdProd =
        secProds?.length > 1 &&
        secProds.reduce(function(prev, current) {
          return Number(prev.gross) > Number(current.gross) ? prev : current;
        }); //returns object;
      if (sletdProd?.experienceCatgory === currentProduct?.experienceCatgory) {
        filterBaseProd = sletdProd;
      } else {
        filterBaseProd =
          secProds?.length && secProds.find((item) => item.experienceCatgory === currentProduct.experienceCatgory);
      }
    } else {
      filterBaseProd =
        self.fallBackProducts.secPrds &&
        self.fallBackProducts.secPrds.find((item) => item.experienceCatgory === currentProduct.experienceCatgory);
    }
    let secPrice = 0;
    if (filterBaseProd) {
      secPrice = parseFloat(filterBaseProd.gross).toFixed(2);
    }
    self.updatedVisitorCounterPrice(currentProduct, 0, secPrice > currentPrice ? filterBaseProd : false);
  }
};

export const secPrdCallBack = (self, evt) => {
  self.slctdSecIdx = Number(evt.target.dataset.value);
  const selectedProduct = JSON.parse(evt.target.dataset.prod);
  const currentProduct = self.combineCloneObject(self.product, selectedProduct);
  self.product = currentProduct;
  currentProduct && self.handleSecondaryProdOffer(currentProduct);
  self.calculateTotal();
  self.props.packageStateHandler && self.props.packageStateHandler(self.product);
  self.slctdExpCatgory = selectedProduct.experienceCatgory;
  self.scrollToNextSection('exctmnt');
};

export const updateButtonState = (self) => {
  if (
    self.isAdultChildOverlay ||
    self.isQuantityInOverlay ||
    self.isTotalAvailabilityCal ||
    self.isAddOnExperienceOverlay
  ) {
    let disable = true;
    let licenseRendered = self.licenseAvailable;
    let licenseCheck = licenseRendered ? licenseRendered && self.product.license : true;
    let termsAndConCheck = !self.tnc ? self.termsAndCondCheck : true;
    let slotCheck = self.isTotalAvailabilityCal ? self.state.slotSelected : true;
    const checkSpinnerCount = parseFloat(self.state.totalCalculatedAmount) > 0 ? true : false;
    const checktimeslot =
      self.bookingOverlay && self.props.timeSlotData && !isEmpty(self.props.timeSlotData)
        ? self.product.timeSlot
          ? true
          : false
        : true;

    if (self.isPackageJourney && licenseCheck && slotCheck && checktimeslot) {
      self.product.selectedDate = self.state.date;
      self.props.packageStateHandler(self.product);
      self.props.updateProductValidity(self.product.productId, true);
    } else if (
      self.props.data.variant === UIConfig.dyanmicPricingVariant &&
      self.state.slotSelected &&
      checkSpinnerCount &&
      checktimeslot
    ) {
      disable = false;
    } else if (self.flexibleMoneyCard && !!self.state.amountMultiplesOfTen) {
      disable = true;
    } else if (
      licenseCheck &&
      slotCheck &&
      termsAndConCheck &&
      parseFloat(self.state.totalCalculatedAmount) &&
      checktimeslot
    ) {
      //TODO: parseFloat to be changed
      disable = false;
    }
    self.setState({
      disableAddToCart: disable,
      existingProductError: false,
    });
  }
};

export const lcnsCallBack = (self, data) => {
  self.product.license = data.text;
  self.updateButtonState();
  self.scrollToNextSection('drvLcns');
};

export const termAndCondcallback = (self, event) => {
  self.termsAndCondCheck = event.target.checked;
  self.updateButtonState();
};

export const configureCntry = (self, cntries) => {
  return cntries.map((item) => {
    return {
      value: item.value,
      text: item.value,
    };
  });
};
