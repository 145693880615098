import React, { lazy, Suspense } from 'react';
import { canUseDOM, checkTenant } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';

const importComponent = (folderName) =>
  lazy(() => import(`../../../container/${folderName}`).catch(() => ({ default: () => null })));

const DynamicImport = ({ componentData }) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isCollapsibleComponent = componentData?.componentName?.toLowerCase() === UIConfig.component.collapsibleContent;
  try {
    const { componentName, data } = componentData;

    if (!canUseDOM() || !componentName || !data) {
      return null;
    }

    const folderName = componentName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    const DIComponent = importComponent(folderName);

    return (
      <Suspense fallback={!isSwad && <div className="dynamic-import">Loading...</div>}>
        <DIComponent {...data} isCollapsibleComponent={isCollapsibleComponent} />
      </Suspense>
    );
  } catch (err) {
    return logComponentRenderingError(err, DynamicImport);
  }
};

export default DynamicImport;
