import React from 'react';
import { DynamicContent } from '../base';
import { logComponentRenderingError } from '../../../common/logger';
import UIConfig from '../../../common/UIConfig';
import './search-description-component.scss';

/**
    * SearchDescription is dumb Component, shows description, like which text user searched for
      and how many results found
	* @param    {props} Object from parent component i.e. search component.
	* @return   {htmlMarkUp}
*/

const SearchDescription = (props) => {
  const checkHtmlSearch = (searchTerm) => {
    const htmlCheckRegex = new RegExp(/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)(<\/\1>)?/, 'gi');
    return htmlCheckRegex.test(searchTerm);
  };
  const searchSettingVariant = props.data.searchVariant || '';
  const updateInputWithCorrectData = (correctedQuery) => {
    document.querySelectorAll('.autocomplete')[0].value = correctedQuery;
    let submit = document.querySelectorAll('.autocomplete-wrapper .input-container .search-trigger')[0];
    if (submit) {
      submit.click();
    }
  };

  const noResultText = () => {
    const {
      data: { resultText, noResultText, didYouMeanText },
      searchInfo: { correctedQuery, resultsCount, searchTerm },
    } = props;

    const searchHasHtmlEntity = checkHtmlSearch(props.searchInfo.searchTerm);
    const content = props.searchInfo.correctedQuery ? (
      <div onClick={() => updateInputWithCorrectData(correctedQuery)}>
        <DynamicContent
          tagName="span"
          innerHtml={
            didYouMeanText &&
            didYouMeanText.replace(
              /\{0\}/g,
              '<a href="javascript:void(0)" class="cta-wrap" >' + correctedQuery + '</a>', // eslint-disable-line
            )
          }
        />
        {searchHasHtmlEntity ? (
          <p>
            {resultText
              .replace(/\{0\}/g, "<span class='search-term-wrapper'>" + resultsCount + '</span>')
              .replace(/\{1\}/g, '')}
            <span className="search-term-wrapper">{searchTerm}</span>
          </p>
        ) : (
          <DynamicContent
            tagName="p"
            innerHtml={resultText
              .replace(/\{0\}/g, "<span class='search-term-wrapper'>" + resultsCount + '</span>')
              .replace(/\{1\}/g, "<span class='search-term-wrapper'>\"" + searchTerm + '"</span>')}
          />
        )}
      </div>
    ) : searchHasHtmlEntity ? (
      <p>
        {noResultText && noResultText.replace(/\{0\}/g, '').replace(/\{1\}/g, '')}
        <span className="search-term-wrapper">{searchTerm}</span>
      </p>
    ) : (
      <DynamicContent
        tagName="p"
        innerHtml={
          noResultText &&
          noResultText
            .replace(/\{0\}/g, "<span class='search-term-wrapper'>\"" + searchTerm + '"</span>')
            .replace(/\{1\}/g, "<span class='search-term-wrapper'>\"" + searchTerm + '"</span>')
        }
      />
    );

    return content;
  };

  try {
    return (
      <div className={'component c-search-description '} data-c-name="SearchDescription" data-c-render="universal">
        <div className="w--top">
          <div
            className={`search-desc ${
              props.noResultsPage && searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
                ? 'no-reslult-text-style'
                : ''
            }`}
          >
            {props.searchInfo.searchTerm ? (
              <div>
                {!props.searchInfo.resultsCount || props.noResultsPage ? (
                  noResultText()
                ) : (
                  <div
                    className={`${
                      searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
                        ? 'c-search-description-container'
                        : ''
                    }`}
                  >
                    <DynamicContent
                      tagName={searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? 'span' : 'p'}
                      innerHtml={props.data.queryText.replace(
                        /\{0\}/g,
                        "<span class='search-term-wrapper'>\"" + props.searchInfo.searchTerm + '"</span>',
                      )}
                    />
                    <DynamicContent
                      tagName={searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? 'span' : 'p'}
                      innerHtml={props.data.resultText
                        .replace(
                          /\{0\}/g,
                          "<span class='result-count-wrapper'>" + props.searchInfo.resultsCount + '</span>' || '',
                        )
                        .replace(
                          /\{1\}/g,
                          "<span class='search-term-wrapper'>\"" + props.searchInfo.searchTerm + '"</span>',
                        )}
                    />
                    {searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant && (
                      <span> "{props.searchInfo.searchTerm}"</span>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'SearchDescription');
  }
};
export default SearchDescription;
