import React, { Component } from 'react';
import classNames from 'classnames';
import {
  getClosestByClass,
  canUseDOM,
  resolvePath,
  checkTenant,
  checkIfParks,
  detectMobile,
} from '../../../common/utility';
import DynamicContent from '../base/dynamic-content/dynamic-content-component';
import { KeyCodes, KeyboardEvents, MouseEvents, KeyCodeNames } from '../../../common/constants';
import { logComponentRenderingError } from '../../../common/logger';
import UIConfig from '../../../common/UIConfig';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import './drop-down-component.scss';
import { SvgSprite } from '../base';
/**  DropDown is custom drop down stateful component creates a selectable content from datalist
 * functionality : creates a dropdown list and enables item selection
 * @param  {props} Object is datalist to bind with dropdown
 */

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.subscriberToken = undefined;
    if (typeof window !== 'undefined') {
      this.closeOtherDropDownEvent = new CustomEvent('closeOtherDropDowns');
    }
    this.listItems = [];
    this.mounted = true;
    this.ListItemItemOnFocus = 0;
    this.loadImage = false;
    this.errorMessage = false;
    this.isLanguageSwitcher = this.props.isLanguageSwitcher;
    this.state = {
      expanded: false,
      firstTimeLoading: this.props.firstTimeLoad ? false : true,
      selectedOption: this.props.defaultValue ? this.props.defaultValue : this.props.listItems[0].value,
      selectedValue: this.props.listItems.length > 0 ? this.props.listItems[0].value : null,
      defaultProductId: this.props.defaultProductId ? this.props.defaultProductId : '',
      currecyScroll: false,
    };
    this.lang = this.props.lang;
    this.bindDropdown();
    this.appendElement = props?.appendElement;
    this.disclaimerText = this.props.disclaimerText || {};
    this.getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    this.checkfortenants = resolvePath(this.getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
    this.isYmc = checkTenant(UIConfig.iamMapping.ymc);
  }
  isTimeSlotChanged = (nextprops) => {
    if (
      this.props.listItems &&
      this.props.listItems.length &&
      nextprops.listItems &&
      nextprops.listItems.length &&
      nextprops.listItems.length === this.props.listItems.length
    ) {
      if (this.props.listItems[0].hasOwnProperty('performanceId')) {
        const nextPerfoIds = nextprops.listItems.map((item) => item.performanceId);
        const oldPerfoIds = this.props.listItems.map((item) => item.performanceId);
        return !(
          nextPerfoIds.length === oldPerfoIds.length &&
          nextPerfoIds.every((value, index) => value === oldPerfoIds[index])
        );
      }
    }
    return false;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isUpdateDropdownProps) {
      const selectedOption = nextProps.defaultValue ? nextProps.defaultValue : nextProps.listItems[0].value;
      selectedOption !== this.state.selectedOption &&
        this.setState({
          selectedOption,
        });
    }
    if (
      (this.props.listItems && nextProps.listItems && this.props.listItems.length !== nextProps.listItems.length) ||
      this.isTimeSlotChanged(nextProps)
    ) {
      const selectedOption = nextProps.defaultValue ? nextProps.defaultValue : nextProps.listItems[0].value;
      selectedOption !== this.state.selectedOption &&
        this.setState({
          selectedOption,
        });
    }
  }

  makeItemsArray = (e) => {
    this.listItems.push(e);
  };

  collapseDropdown = () => {
    if (this.mounted) {
      this.setState(
        {
          expanded: false,
        },
        () => {
          this.ListItemItemOnFocus = 0;
        },
      );
    }
  };

  attachEventToDocument = () => {
    window.addEventListener(MouseEvents.Click, this.collapseDropdown);
    this.subscriberToken = window.PubSub.subscribe('closeOtherDropDowns', this.collapseDropdown);
  };

  removeEventFromDocument = () => {
    window.removeEventListener(MouseEvents.Click, this.collapseDropdown);
    window.PubSub.unsubscribe(this.subscriberToken);
  };

  toggleDropDown = (e) => {
    // Enable below to add scroll to dropdown
    // let isMyCart;
    // if (detectMobile()) {
    //   isMyCart = document.querySelector('.c-my-cart-b2c .c-my-cart-b2c-container .minicart-scroll-wrapper');
    // } else {
    //   isMyCart = document.querySelector('.c-my-cart-b2c .c-my-cart-b2c-container');
    // }

    // if (this.props.isCurrencyConverter && isMyCart) {
    //   const windowHeight = window.innerHeight - 235;
    //   if (isMyCart.offsetHeight >= windowHeight) {
    //     this.setState({ currecyScroll: true });
    //   } else {
    //     this.setState({ currecyScroll: false });
    //   }
    // }

    document.body.classList.remove('bookin-widget-opened');
    if (
      e.type === MouseEvents.Click ||
      (e.type === KeyboardEvents.Keydown &&
        (e.which === KeyCodes.Enter ||
          e.which === KeyCodes.Esc ||
          e.which === KeyCodes.ArrowDown ||
          e.which === KeyCodes.Space))
    ) {
      e.preventDefault();
      setTimeout(() => {
        if (this.state.expanded) {
          this.listItems &&
            this.listItems[this.ListItemItemOnFocus] &&
            this.listItems[this.ListItemItemOnFocus].focus();
        }
      }, 100);
      if (this.state.expanded) {
        this.collapseDropdown();
        e.stopPropagation();
      } else {
        window.PubSub.publishSync('closeOtherDropDowns');
        this.setState({
          expanded: true,
          firstTimeLoading: false,
        });
        e.stopPropagation();
        window.addEventListener(KeyboardEvents.Keydown, this.preventPageScroll);
      }
      if (this.props.listItems.length === 0) {
        this.errorMessage = true;
      }
    } else if (this.listItems.length && e.type === KeyboardEvents.Keydown) {
      if (e.which === KeyCodes.ArrowDown) {
        this.listItems[this.ListItemItemOnFocus].focus();
      }
      if ((e.which === KeyCodes.Esc && this.state.expanded) || (e.shiftKey && e.which === KeyCodes.Tab)) {
        this.collapseDropdown();
        e.stopPropagation();
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.expanded === false) {
      this.attachEventToDocument();
    } else {
      this.removeEventFromDocument();
    }
    if (this.props.listItems.length >= 1) {
      this.errorMessage = false;
    }
    if (prevState.selectedValue !== this.state.selectedValue && this.isYmc) {
      this.props?.getSelectedValue && this.props.getSelectedValue(this.state.selectedValue);
    }
  }
  /**
   * preventPageScroll: prevent window scroll using up, down arrows
   * @param {*} e event object
   */
  preventPageScroll = (e) => {
    if ([KeyCodes.ArrowUp, KeyCodes.ArrowDown].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  };
  /**
   * getUserAction: returns user actions happening on drop-drown list
   * @param {*} e event object
   * return userAction object
   */
  getUserAction = (e) => {
    const action = {
      'item-selected': this.isMenuTampered(e),
      'up-arrow': e.type === KeyboardEvents.Keydown && e.which === KeyCodes.ArrowUp,
      'down-arrow': e.type === KeyboardEvents.Keydown && e.which === KeyCodes.ArrowDown,
    };
    return action;
  };
  /**
   * isMenuTampered : checks if any item is selected from dropdown list
   * @param {*} e event object
   * return boolean value
   */
  isMenuTampered = (e) => {
    const selectionKeys = [KeyCodes.Enter, KeyCodes.Space];
    return e.type === MouseEvents.Click || (e.type === KeyboardEvents.Keydown && selectionKeys.indexOf(e.which) !== -1);
  };

  extractDatasetOfTarget = (e) => {
    if (!e.target.dataset.index) {
      const targetEle = getClosestByClass(e.target, 'dropdown-item');
      return targetEle.dataset;
    } else {
      return e.target.dataset;
    }
  };

  upAndDownArrowNavigation = (keyPressed) => {
    const totalListItems = this.listItems.length;
    if (keyPressed[KeyCodeNames.DownArrow] && this.ListItemItemOnFocus <= totalListItems - 1) {
      if (this.ListItemItemOnFocus === totalListItems - 1) {
        this.ListItemItemOnFocus = -1;
      }
      const listItem = this.listItems[this.ListItemItemOnFocus + 1];
      if (listItem) {
        listItem.focus();
        this.ListItemItemOnFocus += 1;
      }
    } else if (keyPressed[KeyCodeNames.upArrow] && this.ListItemItemOnFocus >= 0) {
      if (this.ListItemItemOnFocus === 0) {
        this.ListItemItemOnFocus = totalListItems;
      }
      this.listItems[this.ListItemItemOnFocus - 1].focus();
      this.ListItemItemOnFocus -= 1;
    }
  };
  setValue = (e) => {
    const totalListItems = this.listItems.length;
    const userAction = this.getUserAction(e);
    this.upAndDownArrowNavigation(userAction);
    if (userAction['item-selected']) {
      const datasetOfTarget = this.extractDatasetOfTarget(e);
      if (datasetOfTarget && datasetOfTarget.disabled !== 'true') {
        this.setState(
          {
            expanded: false,
            selectedOption: this.props.showSelected
              ? this.props.listItems[datasetOfTarget.index].text
              : this.props.listItems[datasetOfTarget.index].value,
            selectedValue: this.props.listItems[datasetOfTarget.index].value,
            defaultProductId: this.props.listItems[datasetOfTarget.index].value,
          },
          () => {
            this.refs.selectedOption.focus();
            this.ListItemItemOnFocus = 0;
          },
        );
        this.props.callBackFunction.call(e, this.props.listItems[datasetOfTarget.index], this.props.callBackParameters);
      }
      e.preventDefault();
      e.stopPropagation();
      window.removeEventListener(KeyboardEvents.Keydown, this.preventPageScroll);
      return false;
    } else if (
      (!e.shiftKey &&
        e.keyCode === KeyCodes.Tab &&
        parseInt(document.activeElement.parentElement.getAttribute('data-index')) === totalListItems - 1) ||
      (e.shiftKey &&
        e.keyCode === KeyCodes.Tab &&
        parseInt(document.activeElement.parentElement.getAttribute('data-index')) === 0)
    ) {
      this.collapseDropdown();
      e.stopPropagation();
      window.removeEventListener(KeyboardEvents.Keydown, this.preventPageScroll);
    }
    if (e.which === KeyCodes.Esc) {
      this.collapseDropdown();
      this.refs.selectedOption.focus();
      e.stopPropagation();
      window.removeEventListener(KeyboardEvents.Keydown, this.preventPageScroll);
    }
  };

  componentDidMount = () => {
    this.mounted = true;
    canUseDOM() && window.PubSub.subscribe('toggleMenuState', () => this.setState({ expanded: false }));

    if (this.appendElement) {
      this.appendElement('[lang="AR"]', 'sr-only', 'Arabic');
    }
    window.PubSub.subscribe('miniCartToggle', (msg, data) => {
      if (data.isOpen && this.state.expanded) {
        this.setState({ expanded: !data.isOpen });
      }
    });
    const defaultSelectedValue = this.props.listItems?.length > 0 ? this.props.listItems[0].value : null;
    if (defaultSelectedValue && this.isYmc) {
      this.props?.getSelectedValue && this.props.getSelectedValue(defaultSelectedValue);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
    canUseDOM() && window.PubSub.unsubscribe('toggleMenuState');
  };

  renderImage = () => {
    return this.props.lazyLoadListImage ? canUseDOM() && this.props.lazyLoadListImage && this.state.expanded : true;
  };

  /**
   * bindDropdown: binds dataList with drop down
   * creates <li> items quantifies to list items
   */
  switchLanguage = (languageCode) => this.props.listItems.find((item) => item.languageCode === languageCode)?.url;

  bindDropdown = () => {
    if (!this.loadImage) this.loadImage = this.renderImage();
    return this.props.listItems.map((item, index) => {
      const isSelected = this.state.selectedOption === (this.props.showSelected ? item.text : item.value);
      const dropDownItemClasses = classNames('dropdown-item', {
        'dropdown-item--disabled': item.disabled,
        'dropdown-item--selected': isSelected,
        [item.performanceId]: item.performanceId,
      });
      return (
        <li
          key={index}
          data-index={index}
          data-disabled={item.disabled}
          className={dropDownItemClasses}
          data-value={item.value}
        >
          <div tabIndex="0" ref={this.makeItemsArray}>
            {this.isLanguageSwitcher ? (
              (checkIfParks() && !checkTenant(UIConfig.iamMapping.fwad)) || checkTenant(UIConfig.iamMapping.ppad) ? (
                <a
                  lang={this.lang && item.value}
                  data-value={item.value}
                  aria-label={item.value}
                  data-index={index}
                  data-disabled={item.disabled}
                  href={this.switchLanguage(item.languageCode)}
                >
                  {item.text}
                  {isSelected && <SvgSprite id="icn-check-mark" />}
                </a>
              ) : (
                <a
                  lang={this.lang && item.value}
                  data-value={item.value}
                  aria-label={item.value}
                  data-index={index}
                  data-disabled={item.disabled}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                  href={this.switchLanguage(item.languageCode)}
                />
              )
            ) : (
              <span
                lang={this.lang && item.value}
                data-value={item.value}
                data-index={index}
                data-disabled={item.disabled}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            )}

            {item.priceValue && (
              <span
                lang={this.lang && item.value}
                data-value={item.value}
                data-index={index}
                className={'price-details'}
                dangerouslySetInnerHTML={{ __html: item.priceValue }}
              />
            )}
            {this.props.showItemCode && item.code && <span className="code">({item.code})</span>}
            {item.img && this.loadImage ? (
              <img src={item.img} alt="" data-index={index} data-value={item.value} />
            ) : null}
          </div>
        </li>
      );
    });
  };

  displaySelectedValue = () => {
    const selectedItem = this.props.listItems.filter((item) => item.value === this.state.selectedOption)[0];
    let displayValue = this.state.selectedOption;
    if (this.props.showAlwaysDefaultValue) {
      displayValue = this.props.defaultValue;
    }
    if (this.props.showItemCode) {
      displayValue = selectedItem.code;
    }
    if (this.props.onValueChange) {
      this.props.onValueChange(displayValue);
    }
    return displayValue;
  };

  displaySelectedPriceValue = () => {
    const selectedItem = this.props.listItems.filter((item) => item.value === this.state.defaultProductId)[0];
    const displayPriceValue = selectedItem && selectedItem.priceValue;
    return displayPriceValue;
  };

  getDropdownList = () => {
    return (
      <>
        {this.bindDropdown()}
        {this.checkfortenants && this.disclaimerText && this.disclaimerText.length !== null && (
          <li className="dropdown-item disclaimer-text">
            <div className="disclaimer-title">{this.disclaimerText.desclaimerTitle}</div>
            <RichText
              tag="div"
              className={`disclaimer-description`}
              field={{
                value: this.disclaimerText?.desclaimerDescription,
                editable: this.disclaimerText?.desclaimerDescription,
              }}
            />
          </li>
        )}
      </>
    );
  };

  render() {
    try {
      const itemValue = this.props.isAriaLabelAsText ? 'text' : 'value';
      const selectedItem = this.props.listItems.filter((item) => item[itemValue] === this.state.selectedOption)[0];
      const itemClasses = classNames('selected-option', { collapse: !this.state.expanded });
      const dropExpandedClasses = classNames('dropdown-menu', {
        hide: !this.state.expanded,
        custom: this.state.currecyScroll,
      });
      // eslint-disable jsx-a11y/role-supports-aria-props
      return (
        <div className="dropdown-component" role="menu">
          {/* eslint-disable-next-line  jsx-a11y/role-supports-aria-props */}
          <div
            className={itemClasses}
            role="menuitem"
            aria-haspopup="true"
            aria-expanded={this.state.expanded ? 'true' : 'false'}
            aria-label={this.props.ariaLabel ? `${this.props.ariaLabel} ${selectedItem[itemValue]}` : null}
            ref="selectedOption"
            tabIndex={this.props.tabIndex || 0}
            onClick={this.toggleDropDown}
            onKeyDown={this.toggleDropDown}
          >
            {this.props.showSelectedImage && <img alt={selectedItem.value} src={selectedItem.img} />}
            <DynamicContent tagName="span" innerHtml={this.displaySelectedValue()} />
            <DynamicContent
              tagName="span"
              attrs={{ className: 'price-details' }}
              innerHtml={this.displaySelectedPriceValue()}
            />
            {this.isLanguageSwitcher &&
              (checkTenant(UIConfig.iamMapping.ppad) ||
                checkTenant(UIConfig.iamMapping.wbw) ||
                checkTenant(UIConfig.iamMapping.yww)) &&
              (this.props.lightMode || checkTenant(UIConfig.iamMapping.wbw) ? (
                <SvgSprite id="icn-dropdown-white" />
              ) : (
                <SvgSprite id="icn-dropdown-dark" />
              ))}
          </div>
          {!this.state.firstTimeLoading ? (
            this.state.currecyScroll ? (
              <div className={dropExpandedClasses} onKeyDown={this.setValue} onClick={this.setValue}>
                <ul>{this.getDropdownList()}</ul>
              </div>
            ) : (
              <ul className={dropExpandedClasses} onKeyDown={this.setValue} onClick={this.setValue}>
                {this.getDropdownList()}
              </ul>
            )
          ) : null}
          {this.errorMessage && (
            <DynamicContent
              tagName="span"
              attrs={{ className: 'error-msg body-copy-6' }}
              innerHtml={this.props.errorMessage}
            />
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'DropDown');
    }
  }
}

export default DropDown;
