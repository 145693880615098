import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import UIConfig from '../UIConfig';
import '../pubsub';
import { canUseDOM, getBaseURL, setLocalStorage } from './window';
import { resolvePath, getLocalStorageByKey, getMainObject } from './helpers';
import { isSeatedProduct } from './product';

export const openIAMOverlay = (IAMPopup) => {
  window.PubSub.publish('stickyClose');

  window.PubSub.publish('toggleOverlayState', {
    shouldOpen: true,
    dataToAppend: IAMPopup,
    customClass: 'iam-overlay',
  });
};

/* alternative for window.atob, becuase native browser function window.atob does not respect unicode
  characters beyod its limit. E.g. characters present in arabic, chinese and other languages are not suported.
  */
export const atou = (b64) => {
  return decodeURIComponent(escape(atob(b64)));
};

export const getCookie = (cname) => {
  if (canUseDOM()) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
};

// setCookie method is for creating a browser cookie
export const setCookie = (name, value, time, domainName) => {
  let expires = '';
  let domain = '';
  // time should be in minutes
  if (time) {
    let date = new Date();
    date.setTime(date.getTime() + time * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  if (domainName) {
    domain = '; domain=' + domainName;
  }

  document.cookie = name + '=' + (value || '') + expires + '; path=/; Secure' + domain;
};

/* Method to extract payload from JWT Token */
export const parseJwt = (token) => {
  if (canUseDOM()) {
    var base64Url = token?.split('.')[1];
    var base64 = base64Url?.replace('-', '+')?.replace('_', '/');
    return JSON.parse(atou(base64));
  }
};

/**
 *  Get data of UserProfile cookie from idToken
 * @return   {[Object]} returns the user data from userProfile cookie if available,
 *                      otherwise take it from JWT token
 */
export const getUserProfile = () => {
  let userProfile = getCookie('UserProfile');

  if (
    userProfile.length &&
    JSON.parse(userProfile).firstName &&
    !JSON.parse(userProfile).userName &&
    JSON.parse(userProfile).mobileNum
  ) {
    userProfile = userProfile.replace('firstName', 'userName');
    return JSON.parse(userProfile);
  } else {
    let jwtData = parseJwt(getCookie('idToken'));
    let expireAt = (jwtData.exp - jwtData.iat) / 60;

    //Note: Here we'll be redefining the userProfile cookie by taking values from the JWT token but we have to make sure, we also persist below prosperities from the SignIn API response
    // - mdmId
    // - firstName
    // - nationality
    // - gender
    // - preferencesVO
    // - segmentsDataList
    // - membership
    let userProfileJSON = userProfile.length ? JSON.parse(userProfile) : {};
    let { mdmId, firstName, nationality, gender, preferencesVO, segmentsDataList, membership } = userProfileJSON;

    userProfile = {
      userName: jwtData['given_name'],
      lastName: jwtData['family_name'],
      fullName: `${jwtData['given_name']} ${jwtData['family_name']}`,
      mobileNum: jwtData['extension_phone_number'],
      businessPhone: jwtData['extension_phone_number'],
      email: typeof jwtData['emails'] === 'string' ? jwtData['emails'] : jwtData['emails'][0],
      nationalityAlphaCode: jwtData['extension_Nationality'],
      country: jwtData['country'],
      yasId: jwtData['sub'],
      mdmId: mdmId ?? null,
      firstName: firstName ?? null,
      nationality: nationality ?? null,
      gender: gender ?? null,
      preferencesVO: preferencesVO ?? null,
      segmentsDataList: segmentsDataList ?? null,
      membership: membership ?? null,
      phoneCountryCode: jwtData['extension_CountryCode'],
      loginProvider: jwtData?.['idp'] ?? null,
    };
    if (isB2B()) {
      userProfile['partnerId'] = jwtData['extension_partnerId'];
      userProfile['marketType'] = jwtData['extension_marketType'];
      userProfile['contactPurchaseRight'] = jwtData['extension_contactPurchaseright'];
      userProfile['userType'] = jwtData['extension_userType'];
      userProfile['bookingSystemId'] = jwtData['extension_bookingSystemId'];
      userProfile['bookingSystemBillingId'] = jwtData['extension_bookingSystemBillingId'];
      userProfile['agentId'] = jwtData['extension_agentID'];
      userProfile['showTarget'] = jwtData['extension_showTarget'];
    }

    setCookie('UserProfile', JSON.stringify(userProfile), expireAt, getBaseURL());
    userProfile = getCookie('UserProfile');
    return JSON.parse(userProfile);
  }
};

//function to get login in user details for header bottom component to check if loggedInUser field exist in localstorage or not.
export const getLoginUser = () => {
  if (canUseDOM() && getCookie('idToken')) {
    return getUserProfile();
  }
  return {};
};

/* function to retrieve logged in detail from the local storage */
export const getLoggedInUser = () => {
  let user = {};
  let mainObj = getLocalStorageByKey('mainObj');

  if (getCookie('idToken')) {
    let userProfile = getUserProfile();
    let idToken = { idToken: getCookie('idToken') };
    user = { ...idToken, ...userProfile, ...mainObj };
  } else if (mainObj) {
    user = mainObj;
  }

  return user;
};

/* function to retrieve logged in detail from the local storage */
export const getMembershipDetails = (key) => {
  const localstoragemembership =
    canUseDOM() && localStorage.getItem('membershipDetails')
      ? JSON.parse(decryptParam(localStorage.getItem('membershipDetails')))
      : '';

  if (key) {
    const memberDetails =
      localstoragemembership && Object.keys(localstoragemembership.membershipData).length > 0
        ? localstoragemembership[key]
        : '';
    return memberDetails;
  }
  return localstoragemembership;
};
export const getPartnerCodeData = (memberDiscounts, membershipData) => {
  const localstoragemembership = getMembershipDetails('membershipData') || membershipData;
  const segmentType = localstoragemembership ? localstoragemembership.segmentType : '';
  const findMembership =
    memberDiscounts &&
    memberDiscounts.length > 0 &&
    memberDiscounts.find((member) => member.discountType.toLowerCase() === segmentType.toLowerCase());

  return findMembership;
};

/* Method to get local storage data required for renew ID token and Idle timeout.
Output: object of data stored for renew request.
*/
export const getRenewTokenConfig = () => {
  var userData = getLoggedInUser();
  if (userData && userData.hasOwnProperty('idToken')) {
    const renewConfig = userData.loginInfo;
    return renewConfig;
  }
  return false;
};

export const isLoginValid = () => {
  let renewConfig = getRenewTokenConfig();
  if (renewConfig && renewConfig.expireAt) {
    let tokenExpireTime = Number(renewConfig.expireAt);
    let currentTime = Date.now();
    return tokenExpireTime > currentTime;
  }
  return false;
};

export const encryptParam = (param) => {
  if (canUseDOM()) {
    return window.btoa(unescape(encodeURIComponent(param)));
  }
};

export const decryptParam = (param) => {
  if (canUseDOM()) {
    return window.atob(param) || '';
  }
};

export const decryptPassword = (data) => {
  if (resolvePath(data, 'cart.reservationOwner.password')) {
    data.cart.reservationOwner.password = decryptParam(data.cart.reservationOwner.password);
  }
  return data;
};

export const checkYasIdExist = () => {
  if (canUseDOM()) {
    if (localStorage.getItem(UIConfig.localStoreKeys.yasIdUserData)) {
      return true;
    }
    return false;
  }
};

export const checkInlineSignup = () => {
  if (canUseDOM()) {
    if (localStorage.getItem(UIConfig.localStoreKeys.isInlineSignUpForm)) {
      return true;
    }
    return false;
  }
};
export const getExpressCheckoutPostPurchaseUser = () => {
  return (
    (canUseDOM() && JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.expressCheckoutPostPurchaseUser))) || null
  );
};

export const deleteExpressCheckoutPostPurchaseUser = () => {
  getExpressCheckoutPostPurchaseUser() &&
    localStorage.removeItem(UIConfig.localStoreKeys.expressCheckoutPostPurchaseUser);
};

export const mycartError = () => {
  if (canUseDOM()) {
    const isError = document.getElementsByClassName('heading-error');
    if (isError && isError.length > 0) {
      const parent = document.querySelector('.c-b2c-cart-header-container');
      parent && parent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }
  }
};

export const setYasId = (data) => {
  data.yasId && setLocalStorage(UIConfig.localStoreKeys.yasIdUserData, data.yasId);
};

/**
 * Desc: Non logged in user check
 * @return {Boolean} return true is user logged in and false if not logged in
 */
export const isLoggedInUser = () => {
  return canUseDOM() && !!getCookie(UIConfig.appCookies.idToken);
};

export const getTenantId = (isLowerCaseNotReq) => {
  if (canUseDOM()) {
    const user = getLoggedInUser();
    const tenantID = resolvePath(user, 'tenantID', '');
    if (isLowerCaseNotReq) {
      return tenantID;
    }
    return tenantID.toLowerCase();
  }
  return '';
};

export const isYasArenaJourney = () => {
  return getLoggedInUser().tenantID === UIConfig.yasArenaB2CTenant;
};

/**
 * Desc: To get the B2B tenantId if user is logged in
 * @return {boolean}
 */
export const isB2B = () => {
  if (isExperienceEditorActive()) {
    const tenantContainer = document.querySelector('.container');
    if (tenantContainer && tenantContainer.length > 0) {
      const tenantName = tenantContainer.classList[1].split('-')[1];
      return UIConfig.coveoMapping[tenantName.toLowerCase()] === UIConfig.coveoMapping.b2b;
    }
  }

  return resolvePath(getMainObject(), 'tenantID', '') === 'ALLB2B';
};

export const cartHasYASeatedProduct = (productList) => {
  if (getLoggedInUser().tenantID === UIConfig.yasArenaB2CTenant) {
    const seatedProducts = productList.filter((product) => isSeatedProduct(product));
    return seatedProducts.length;
  }

  return false;
};

/**
 * Desc: To match the passed  tenant
 * @args nameOfTenant
 * @return {boolean}
 */
export const isMatchTenant = (matchTenant) => {
  if (canUseDOM()) {
    return (getLoggedInUser().tenantID || '').toLocaleLowerCase() === matchTenant;
  }
  return false;
};

/**
 * Set expireAt value
 */
export const setExpireAtValue = () => {
  const token = getCookie('idToken');
  if (token) {
    const payloads = parseJwt(token);
    const expireAt = payloads.exp ? Number(payloads.exp) * 1000 : 0;
    let updatedMainObj = getLocalStorageByKey('mainObj');
    updatedMainObj.loginInfo.expireAt = expireAt;
    setLocalStorage('mainObj', JSON.stringify(updatedMainObj));
  }
};

/*
 * Function for checking true/false, if the desired role matches with CMS role settings
 */
export const getUserRoleByType = (type, role) => {
  if (role && canUseDOM()) {
    const roleMapping = getLocalStorageByKey('roles');
    switch (type) {
      case 'operator':
        return roleMapping['operator'].toLowerCase() === role.toLowerCase();
      case 'agent':
        return roleMapping['agentCanPay'].toLowerCase().indexOf(role.toLowerCase()) >= 0;
      case 'primary':
        return roleMapping['primaryContact'].toLowerCase() === role.toLowerCase();
      case 'admin':
        return (
          roleMapping['admin'].toLowerCase().indexOf(role.toLowerCase()) >= 0 ||
          roleMapping['superAdmin'].toLowerCase().indexOf(role.toLowerCase()) >= 0
        );
      default:
        return false;
    }
  } else {
    return false;
  }
};

export const formatUserData = (userData) => {
  try {
    const mainObj = getMainObject();
    if (typeof userData === 'object' && (!mainObj || !Object.keys(mainObj).length)) {
      return userData;
    }

    if (typeof userData !== 'object' || !userData) {
      return;
    }
    if (isMatchTenant(UIConfig.tenants.b2b)) {
      Object.keys(mainObj).forEach((key) => {
        if (userData.hasOwnProperty(key)) {
          delete userData[key];
        }
      });
      return userData;
    } else {
      const {
        mdmId,
        yasId,
        nationality,
        country,
        email,
        userName: firstName,
        lastName,
        preferencesVO,
        segmentsDataList,
        gender,
      } = userData;
      return {
        mdmId,
        yasId,
        nationality,
        country,
        email,
        firstName,
        lastName,
        preferencesVO,
        segmentsDataList,
        gender,
      };
    }
  } catch (err) {
    console.error(err);
  }
};
export const getYasIdGTMData = () => {
  return resolvePath(getLoginUser(), 'yasId', '');
};
export const getEmailIdGTMData = () => {
  return resolvePath(getLoginUser(), 'email', '');
};
