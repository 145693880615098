/*
 * input-components.js
 * This file contains code for input component which will render input based on schema.
 * @licensor  Miral
 */
import React from 'react';

import TicketTooltip from '../../b2c-purchase-journey/ticket-tooltip/ticket-tooltip.js';
import Input from './input-component';
import { logComponentRenderingError } from '../../../../common/logger';

import './input-tooltip-component.scss';

const InputTooltip = (props) => {
  try {
    const modifiedInputData = { ...props.data };
    modifiedInputData.type = 'Password';

    return (
      <div className="c-input-tooltip form-element">
        <Input
          data={modifiedInputData}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onReset={props.onReset}
          onPaste={props.onPaste}
          onCopy={props.onCopy}
          isTabIndex={props.isTabIndex}
          controlled={props.controlled}
          is2StepPayment={props.is2StepPayment}
        />
        {modifiedInputData.tooltip && (
          <TicketTooltip
            description={modifiedInputData.tooltip}
            is2StepJourney={props.is2StepPayment}
            tabIndex={props.isTabIndex}
          />
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'InputTooltip');
  }
};

export default InputTooltip;
