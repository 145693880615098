import React, { useLayoutEffect, useState } from 'react';
import { logComponentRenderingError } from '../../../../common/logger';
import { DynamicContent } from '../../base';

const Button = (props) => {
  const field = props.field;
  const [loadButton, setLoadButton] = useState(false);
  const onClickHandler = (e, data) => {
    props.onButtonClick(field.buttonField.name);
  };

  useLayoutEffect(() => {
    if (!loadButton) {
      setLoadButton(true);
    }
  }, []);

  try {
    return (
      <div className="text-center">
        {loadButton && (
          <div className="btn btn-primary">
            <button
              disabled={props.disabled}
              value={field.model.title}
              name={field.buttonField.name}
              id={field.buttonField.id}
              onClick={onClickHandler}
              tabIndex={0}
            >
              <DynamicContent tagName="span" innerHtml={field.model.title} />
            </button>
          </div>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Button');
  }
};

export default Button;
