/*
 * editorial-grid-components.js
 * This file contains code for Editorial grid component, It renders some optional fields and Editiroal Item component
 * @licenssor  Miral
 */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import {
  canUseDOM,
  carouselDefaultSettings,
  checkInlineSignup,
  deepCloneObject,
  detectMobile,
  detectViewPort,
  getArrayOfElement,
  getCardButtonLabel,
  getMainObject,
  handleEnterKey,
  isArabicMode,
  isBuyButton,
  isTouchDevice,
  parseQueryString,
  resolvePath,
  isZoomIn,
  isLoggedInUser,
  getTenantId,
  isMatchTenant,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import useBeforeFirstRender from '../../../hooks/useBeforeFirstRender';
import {
  AnchorLink,
  Carousel,
  DynamicContent,
  EditorialItem,
  Image,
  RichText,
  MasonryGrid,
} from '../../presentation/base';
import YasIDRegistration from '../../presentation/b2c-purchase-journey/yas-id-registration/yas-id-registration';
import { logComponentRenderingError } from '../../../common/logger';
import {
  redirectToURL,
  allViewSettings,
  getCustomCarouselSettings,
  characterCarouselSettings,
  contentCarouselSettings,
  showOverLay,
  CAROUSEL_VARIANT,
  CENTERPADDING,
  GRID_CAROUSEL_VARIANT,
} from './helpers';

import './editorial-grid.scss';
import './editorial-item-component.scss';
import GTMData from '../b2c-purchase-journey/gtm-data';

const EditorialGrid = (props) => {
  const { data } = props;
  const errorCompVariant = data.variant === 'v-confirmation-summary-error-editorialgrid';
  const allPackagesVariant = data.variant === 'v-carousel-view' && data.theme === 'editorial-grid-allpackages';
  const isFourGridLayout = data.variant === CAROUSEL_VARIANT.fourGridLayout;
  const isMasonryGrid = data.variant === CAROUSEL_VARIANT.masonryGrid;
  const gridLimit = data?.gridLimit && +data?.gridLimit;
  const viewport = detectViewPort();
  const isB2B = () => {
    return canUseDOM() && resolvePath(window, 'dataLayer.0.siteType', '').toLowerCase() === 'b2b';
  };

  const [mobileView, setMobileView] = useState(isB2B() ? false : viewport === 'mobile');
  const [showCards, setShowCard] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isErrorExist, setIsErrorExist] = useState(false);
  const [isPPAD, setIsPPAD] = useState(false);
  const [show, setShow] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [productList, setProductList] = useState(data?.imageDetail || []);

  const settings = useRef({});
  const isCenterTileZoomed = useRef(false);
  let lastFocusableEle = null;
  let previousSlideFocusable = null;
  const isEtihadErina = isMatchTenant(UIConfig.tenants.ya);

  const gridRef = useRef();
  const sliderRef = useRef();
  const totalSlides = (data.imageDetail && data.imageDetail.length) || 0;
  const tenantID = getTenantId(true);

  const carouselDiv = useRef();

  // show Load More button based on the condition
  const showLoadMoreCheck = useCallback(() => isEtihadErina && gridLimit && totalSlides > gridLimit, []);

  const setInitialTabIndex = () => {
    setTimeout(() => {
      const slick = gridRef.current;
      if (!slick) {
        setInitialTabIndex();
      } else {
        const slidersElements = slick.querySelectorAll('.slick-track .slick-slide');
        if (slidersElements.length) {
          let mobileSettings = '';
          if (viewport === 'mobile') {
            mobileSettings = settings.current.responsive.find((i) => i.breakpoint === 767);
          }
          const slidesToShow = mobileSettings ? mobileSettings.settings.slidesToShow : settings.current.slidesToShow;
          addTabIndex(slidesToShow + 1, slidesToShow);
        } else {
          // Saadiyat contact-us: "find us on map" link tabindex change from -1 to 0
          const anchors = slick.querySelectorAll('.right-arrow');
          let anchorsLength = anchors.length;
          while (anchorsLength--) {
            anchors[anchorsLength].setAttribute('tabindex', '0');
          }
        }
      }
    }, 500);
  };

  /**
   * componentWillMount and componentDidMount LifeCycle Method equivalent hook call to subscribe to windowResize event to check
   * if current view is Mobile or not.
   */
  useEffect(() => {
    const { data } = props;
    const { cssClass, theme } = data;
    if (isMatchTenant(UIConfig.tenants.ya) && data?.variant === GRID_CAROUSEL_VARIANT.gridView) {
      const { imageDetail } = data;
      GTMData.push(UIConfig.ga4Constants.VIEW_ITEM_LIST, { imageDetail });
    }
    const status = parseQueryString('status') === 'true';
    const isYasIdCreationPage = parseQueryString('id');

    const clickToRegisterContent = document.getElementsByClassName('click-to-register-content');
    if (isYasIdCreationPage && clickToRegisterContent.length) {
      clickToRegisterContent[0].click();
    }
    //enable LoadMore for Etihad Erina
    if (showLoadMoreCheck()) {
      setShowLoadMore(true);
      const productListLocal = data?.imageDetail.slice(0, gridLimit);
      setProductList(productListLocal);
    }

    const errorMessage =
      canUseDOM() && localStorage.getItem('error_message') ? JSON.parse(localStorage.getItem('error_message')) : null;

    if (!status && errorMessage && errorCompVariant) {
      const errorMessages = [];

      Object.keys(errorMessage).forEach((key) => {
        errorMessages.push(errorMessage[key]);
      });
      setIsPPAD(tenantID === 'PPB2C');
      setErrorMsg(errorMessages);
    }

    if (canUseDOM() && window.location.href.includes('confirmation') && !status && !errorCompVariant) {
      setIsErrorExist(true);
    }

    setShow(true);

    window.PubSub.subscribe('hideEditorialGrid', (msg, data) => {
      if (data.comp === 'noSearch') {
        setShow(data.hide);
      }
    });

    window.PubSub.subscribe('windowResize', updateEditorialItemListView);
    if (data) {
      isCenterTileZoomed.current = theme === 'editorial-grid-center-tile-zoomed';

      if (cssClass === 'character-carousel' && totalSlides === 1 && document.querySelector('.right-arrow')) {
        document.querySelector('.right-arrow').removeAttribute('tabindex');
      }

      setInitialTabIndex();
    }

    setTimeout(() => {
      const eleToScroll = document.getElementById(window.location.hash.substr(1));
      eleToScroll && eleToScroll.scrollIntoView();
    }, 1500);

    /**
     * componentWillUnmount LifeCycle equivalent hook functionality to unsubscribe to windowResize event.
     */
    return () => {
      window.PubSub.unsubscribe('windowResize');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * updateEditorialItemListView function to update Editorial Item List View on Window Resize event.
   * It calls setState function to check if current view is Mobile or not
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  const updateEditorialItemListView = () => {
    setMobileView(isB2B() ? false : viewport === 'mobile');
  };

  /**
   * adds the tabIndex and accessibility before slide changes
   * Also, add events when tab moves on items for auto scroll
   */
  const addTabIndex = (currentSlide, slidesToShow) => {
    const slick = gridRef.current;
    const slidersElements = slick.querySelectorAll('.slick-track .slick-slide');

    const { carousel } = data;
    if (slidersElements.length) {
      let classList = ['.editorial-item--title', '.right-arrow'];
      if (tenantID === 'YMCB2C') {
        classList = ['.editorial-item'];
      }
      const theme = (data.theme && data.theme.replace(/\s/g, '')) || '';
      const cssClass = (data.cssClass && data.cssClass.replace(/\s/g, '')) || '';

      const customDots = carousel && carousel.dots && slick.querySelectorAll('.slick-slider .custom-dots button');
      if (customDots) {
        for (let idx = 0; idx < customDots.length; idx++) {
          customDots[idx].setAttribute('tabindex', '-1');
        }
      }

      // add accessibility to center slide when isCenterTileZoomed is true else add accessibility to all visible slides
      if (isCenterTileZoomed.current) {
        classList.forEach((currentVal, index) => {
          const sliderElement = slidersElements[currentSlide - 1];
          const element = sliderElement && sliderElement.querySelector(currentVal);

          if (element) {
            element.setAttribute('tabindex', '0');
            element.setAttribute('data-accessible', `${theme}-${cssClass}-accessible-${0}-${index}`);
          }
        });
      } else {
        for (let i = 0; i < slidesToShow; i++) {
          classList.forEach((currentVal, index) => {
            const sliderElement =
              slidersElements[
                carousel.centerMode
                  ? slidesToShow < 2
                    ? currentSlide
                    : currentSlide + i - Math.floor(slidesToShow / 2) - 1
                  : currentSlide + i - 1
              ];
            const element = sliderElement && sliderElement.querySelector(currentVal);

            if (element) {
              element.setAttribute('tabindex', '0');
              element.setAttribute('data-accessible', `${theme}-${cssClass}-accessible-${i}-${index}`);
            }
          });
        }
      }

      //return if there are no arrow keys
      if (!slick.querySelectorAll('.slick-arrow').length) {
        return false;
      }

      const params = {
        currentSlide,
        classList,
        theme,
        cssClass,
        slidesToShow,
      };

      const focusable = getArrayOfElement('.c-editorial-grid--item-content [tabindex]:not([tabindex="-1"])');
      for (let i = 0; i < focusable.length; i++) {
        focusable[i].addEventListener('keydown', (e) => {
          !detectMobile() && handleKeyDown(e, params);
        });
      }

      carouselTriggeringElements(params);
    }
  };

  const handleKeyDown = (e, attrs) => {
    const keyCode = e.which || e.keyCode;
    const { dots, slidesToScroll } = data.carousel;

    const isLastSlide = e.target === lastFocusableEle;
    const isFirstSlide = e.target.getAttribute('data-accessible') === `${attrs.theme}-${attrs.cssClass}-accessible-0-0`;

    if (
      !e.shiftKey &&
      keyCode === 9 &&
      isLastSlide &&
      attrs.currentSlide - attrs.slidesToShow <
        totalSlides -
          (isCenterTileZoomed.current || dots
            ? 0
            : data.carousel.centerMode
            ? attrs.slidesToShow - 2
            : attrs.slidesToShow - 1)
    ) {
      sliderRef.current.slickNext();
      setTimeout(() => {
        if (isCenterTileZoomed.current) {
          document.querySelector(`[data-accessible = ${attrs.theme}-${attrs.cssClass}-accessible-0-0]`).focus();
        } else {
          document
            .querySelector(
              `[data-accessible = ${attrs.theme}-${attrs.cssClass}-accessible-${attrs.slidesToShow -
                slidesToScroll}-0]`,
            )
            .focus();
        }
        carouselTriggeringElements(attrs);
      }, settings.current.speed + 100);
    } else if (e.shiftKey && keyCode === 9 && isFirstSlide && attrs.currentSlide - attrs.slidesToShow !== 1) {
      sliderRef.current.slickPrev();
      setTimeout(() => {
        previousSlideFocusable.focus();
        carouselTriggeringElements(attrs);
      }, settings.current.speed + 100);
    }
  };

  const carouselTriggeringElements = (attrs) => {
    const { carousel } = data;
    //Get Last focussable element after which we need to trigger slick next
    lastFocusableEle = null;
    for (let i = attrs.classList.length; i >= 0; i--) {
      const element = document.querySelector(
        `[data-accessible = ${attrs.theme}-${attrs.cssClass}-accessible-${
          isCenterTileZoomed.current ? 0 : attrs.slidesToShow - 1
        }-${i}]`,
      );

      if (element) {
        lastFocusableEle = element;
        break;
      }
    }

    //Get Last focusable element of previous slide after we trigger slick prev
    previousSlideFocusable = null;
    for (let i = attrs.classList.length; i >= 0; i--) {
      const element = document.querySelector(
        `[data-accessible = ${attrs.theme}-${attrs.cssClass}-accessible-${
          isCenterTileZoomed.current ? 0 : carousel.slidesToScroll - 1
        }-${i}]`,
      );

      if (element) {
        previousSlideFocusable = element;
        break;
      }
    }
  };

  /**
   * remove the tabIndex and accessibility before slide changes
   */
  const removeTabIndex = () => {
    const slick = gridRef.current;
    const slidersElem = slick.querySelectorAll('.slick-track .slick-slide');
    let classList = ['.editorial-item--title', '.right-arrow'];
    if (tenantID === 'YMCB2C') {
      classList = ['.editorial-item'];
    }

    for (let i = 0; i < slidersElem.length; i++) {
      classList.forEach((currentVal) => {
        const focusableElm = slidersElem[i].querySelector(currentVal);
        if (focusableElm) {
          focusableElm.setAttribute('tabindex', '-1');
          focusableElm.removeAttribute('data-accessible');
        }
      });
    }
  };

  const handleClickOnIcon = (value) => {
    let showCardsState = showCards;
    const indexValue = showCardsState.indexOf(value);

    if (indexValue >= 0) {
      showCardsState.splice(indexValue, 1);
      setShowCard(showCardsState);
    } else {
      showCardsState = [...showCardsState, value];
      setShowCard(showCardsState);
    }
  };

  const renderProductInfo = (data) => {
    const { bodyCopy, currentPrice, imageTitle, netPrice, priceText, tagging } = data;

    return (
      <div className="product-info">
        {tagging && tagging.length && <div className="product-tag">{tagging[0]}</div>}
        {imageTitle && (
          <strong className="editorial-item--title heading-5" dangerouslySetInnerHTML={{ __html: imageTitle }} />
        )}
        <div className="price-wrapper">
          {currentPrice && (
            <div className="current-price">
              {priceText} <span className="price">{currentPrice}</span>
            </div>
          )}
          {netPrice && (
            <div className="net-price">
              {priceText} <span className="price">{netPrice}</span>
            </div>
          )}
        </div>
        <RichText data={{ bodyCopy: bodyCopy, attrs: { className: 'editorial-item--bodycopy body-copy-4' } }} />
      </div>
    );
  };

  const keyDownHandlerInfo = (e, index) => {
    if (e.charCode === 13 || e.keyCode === 13 || e.key === 'Enter') {
      handleClickOnIcon(index);
    }
  };

  const getCarouselDivRef = () => {
    return carouselDiv;
  };

  /**
  * showEditorialItems   function to display Editorial Items based on the selected viewtype selected
    and device
  * @param    {[data]} data object of the element
  * @return   {[Object]} Return a render function which conatins the JSX of the component.
  */
  const showEditorialItems = () => {
    const { buyNowLabel, imageDetail, isFullTileClickAble, showInfoIcon, theme, variant, carousel } = data;
    let imageDetailList = imageDetail;

    // if (isFourGridLayout && mobileView) {
    //   settings.current.centerPadding = '';
    // }

    if (allPackagesVariant && mobileView && isArabicMode()) {
      let newImageDetailArray = [];
      if (imageDetailList && imageDetailList.length > 0) {
        for (let i = 0; i < imageDetailList.length; i++) {
          newImageDetailArray.push(imageDetailList[imageDetailList.length - (i + 1)]);
        }
        imageDetailList = newImageDetailArray;
      }
      settings.current.responsive = [{ breakpoint: 767, settings: { slidesToShow: 1, rtl: true } }];
    }
    // if (isMatchTenant(UIConfig.tenants.ya) && isArabicMode() && !mobileView) {
    //   let newImageDetailArray1 = [...imageDetail];
    //   if (imageDetail && imageDetail.length > 0) {
    //     newImageDetailArray1.reverse();
    //     const element = newImageDetailArray1[0];
    //     newImageDetailArray1.splice(0, 1);
    //     newImageDetailArray1.splice(imageDetail.length - 1, 0, element);
    //     imageDetailList = newImageDetailArray1;
    //   }
    //   settings.current.responsive = [
    //     {
    //       breakpoint: 767,
    //       settings: {
    //         slidesToShow: 1,
    //         infinite: true,
    //         slidesToScroll: 1,
    //         asNavFor: '.v-carousel-view-container',
    //         dots: false,
    //         centerMode: true,
    //         focusOnSelect: true,
    //         rtl: true,
    //       },
    //     },
    //   ];
    // }
    if (
      errorCompVariant ||
      (theme && theme.indexOf('editorial-grid-five-cards') > -1 && imageDetailList && imageDetailList.length === 0)
    ) {
      return null;
    }
    const direction = canUseDOM() && isArabicMode() && data.carousel.rtl ? 'rtl' : 'ltr';

    if (variant === CAROUSEL_VARIANT.yaFeatureListingExtn) {
      for (let i = 0; i < data.imageDetail.length; i++) {
        const { imageTitle, startDate, endDate, tagging } = imageDetail[i];
        if (!imageTitle) {
          if (startDate) {
            imageDetail[i].imageTitle = startDate + (endDate && ` - ${endDate}`);
          } else if (tagging && tagging.length > 0) {
            imageDetail[i].imageTitle = tagging[0];
          }
        }
      }
    }
    if (variant === GRID_CAROUSEL_VARIANT.gridView && showLoadMore) {
      return (
        <div className="w--middle">
          <div className="w--content">
            <div
              className={
                'v-carousel-view-container no-carousel-grid-view clearfix ' +
                (imageDetailList && imageDetailList.length <= 2 ? ' two-item-carousel' : '')
              }
              dir={direction}
              ref={carouselDiv}
            >
              <div className={!mobileView ? 'c-editorial-grid--item-content' : 'c-editorial-grid--item'}>
                {productList.map((item, index) => (
                  <EditorialItem
                    {...item}
                    index={index}
                    tabIndex={imageDetailList.length <= 2 ? 0 : ''}
                    mobileDisableLazyLoad={data.carousel.mobileDisableLazyLoad}
                  />
                ))}
              </div>
              {showLoadMore && data?.loadMoreLabel && (
                <div className="button-wrapper">
                  <Text
                    tag="button"
                    className="editorial-button--loadmore"
                    field={{ value: data.loadMoreLabel, editable: data.loadMoreLabel }}
                    onClick={() => {
                      setShowLoadMore(false);
                      setProductList(data?.imageDetail);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (variant === GRID_CAROUSEL_VARIANT.gridView && !mobileView) {
      return (
        <div className="w--middle">
          <div className="w--content">
            <div
              className={
                'v-carousel-view-container no-carousel-grid-view clearfix ' +
                (imageDetailList && imageDetailList.length <= 2 ? ' two-item-carousel' : '')
              }
              dir={direction}
              ref={carouselDiv}
            >
              <div className="c-editorial-grid--item-content">
                {imageDetailList.map((item, index) => (
                  <EditorialItem
                    {...item}
                    index={index}
                    tabIndex={imageDetailList.length <= 2 ? 0 : ''}
                    mobileDisableLazyLoad={data.carousel.mobileDisableLazyLoad}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (variant === CAROUSEL_VARIANT.mobileView && mobileView) ||
      variant === CAROUSEL_VARIANT.allView ||
      variant === CAROUSEL_VARIANT.yaCarouselView ||
      variant === CAROUSEL_VARIANT.yaBannerExternal ||
      ((variant === CAROUSEL_VARIANT.yaFeatureListingExtn || isFourGridLayout || isMasonryGrid) && detectMobile()) ||
      (variant === CAROUSEL_VARIANT.similarProducts &&
        ((imageDetailList && imageDetailList.length >= 4) || mobileView)) ? (
      <div className="w--middle">
        <div className="w--content">
          <div
            className={
              'v-carousel-view-container clearfix ' +
              (imageDetailList && imageDetailList.length <= 2 ? ' two-item-carousel' : '')
            }
            dir={direction}
            ref={carouselDiv}
          >
            {isMasonryGrid && imageDetailList && imageDetailList.length > 0 && (
              <MasonryGrid images={imageDetailList} columnLayout={carousel.slidesToShow} />
            )}
            {!isMasonryGrid && imageDetailList && imageDetailList.length > 0 && (
              <Carousel
                data={{
                  ...settings.current,
                  autoplay: !!data.carousel?.autoplay,
                  ...(isFourGridLayout && { className: 'c-editorial-grid--items' }),
                }}
                ref={sliderRef}
                getCarouselDivRef={getCarouselDivRef}
              >
                {imageDetailList.map((item, index) => (
                  <div
                    key={index}
                    className={isFourGridLayout ? 'c-editorial-grid--item' : 'c-editorial-grid--item-content'}
                  >
                    {(() => {
                      if (isFullTileClickAble && item.moreLinkButton) {
                        return (
                          <div
                            className="full-tile-clickable"
                            onClick={() => redirectToURL(item.moreLinkButton.href, item.moreLinkButton.target, variant)}
                            onKeyDown={(e) =>
                              handleEnterKey(e, () =>
                                redirectToURL(item.moreLinkButton.href, item.moreLinkButton.target, variant),
                              )
                            }
                            tabIndex={tenantID === UIConfig.yasArenaB2CTenant ? 0 : -1}
                          >
                            <EditorialItem
                              {...item}
                              index={index}
                              mobileDisableLazyLoad={data.carousel.mobileDisableLazyLoad}
                            />
                            {renderFeatureTxt(item.isFeatured)}
                          </div>
                        );
                      } else if (variant === CAROUSEL_VARIANT.similarProducts && showInfoIcon) {
                        return (
                          <div className="editorial-carousel-info-data">
                            <div
                              className={'info-icon ' + (showCards.indexOf(index) >= 0 ? 'show-cross-icon' : '')}
                              onClick={() => {
                                (isTouchDevice() || isZoomIn()) && handleClickOnIcon(index);
                              }}
                              onKeyDown={(e) => {
                                isZoomIn() && keyDownHandlerInfo(e, index);
                              }}
                              tabIndex="0"
                            ></div>
                            <EditorialItem
                              {...item}
                              index={index}
                              variant={variant}
                              buyNowLabel={buyNowLabel}
                              mobileDisableLazyLoad={data.carousel.mobileDisableLazyLoad}
                            />
                            <div className={'info-card ' + (showCards.indexOf(index) >= 0 ? 'showCard' : '')}>
                              {renderProductInfo({ ...item })}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <EditorialItem
                            {...item}
                            index={index}
                            tabIndex={imageDetailList.length <= 2 ? 0 : ''}
                            mobileDisableLazyLoad={data.carousel.mobileDisableLazyLoad}
                          />
                        );
                      }
                    })()}
                    {renderActionButton(item)}
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    ) : variant === CAROUSEL_VARIANT.similarProducts && showInfoIcon ? (
      <div className="no-carousel-similar-products">
        <div className="w--middle">
          <div className="w--content">
            {imageDetailList &&
              imageDetailList.map((item, index) => (
                <div className="editorial-carousel-info-data">
                  <div
                    className={'info-icon ' + (showCards.indexOf(index) >= 0 ? 'show-cross-icon' : '')}
                    onClick={() => {
                      (isTouchDevice() || isZoomIn()) && handleClickOnIcon(index);
                    }}
                    onKeyDown={(e) => {
                      isZoomIn() && keyDownHandlerInfo(e, index);
                    }}
                    tabIndex="0"
                  ></div>
                  <EditorialItem
                    {...item}
                    variant={variant}
                    buyNowLabel={buyNowLabel}
                    mobileDisableLazyLoad={data.carousel.mobileDisableLazyLoad}
                  />
                  <div className={'info-card ' + (showCards.indexOf(index) >= 0 ? 'showCard' : '')}>
                    {renderProductInfo({ ...item })}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    ) : (
      <div className="w--middle">
        <div className="w--content">
          {isMasonryGrid && imageDetailList && (
            <MasonryGrid images={imageDetailList} columnLayout={carousel.slidesToShow} />
          )}
          {!isMasonryGrid && (
            <ul className="c-editorial-grid--items">
              {imageDetailList &&
                imageDetailList.map((item, index) => (
                  <li key={index} className="c-editorial-grid--item">
                    {isFullTileClickAble && item.moreLinkButton ? (
                      <div
                        className="full-tile-clickable"
                        onClick={() => redirectToURL(item.moreLinkButton.href, item.moreLinkButton.target, variant)}
                        onKeyDown={(e) =>
                          handleEnterKey(e, () =>
                            redirectToURL(item.moreLinkButton.href, item.moreLinkButton.target, variant),
                          )
                        }
                        tabIndex="0"
                      >
                        <EditorialItem {...item} mobileDisableLazyLoad={data.carousel.mobileDisableLazyLoad} />
                        {renderFeatureTxt(item.isFeatured)}
                      </div>
                    ) : (
                      <EditorialItem
                        variant={data.variant}
                        {...item}
                        mobileDisableLazyLoad={data.carousel.mobileDisableLazyLoad}
                      />
                    )}
                    {renderActionButton(item)}
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    );
  };

  const renderFeatureTxt = (isFeatured) => {
    if (isFeatured && data.variant === CAROUSEL_VARIANT.yaFeatureListingExtn) {
      return <div className="featured">{data.featuredTextLabel}</div>;
    }
    return null;
  };

  const renderActionButton = ({
    isFree,
    isSoldOut,
    isUpcomingEvent,
    eventBookingUrl: url,
    freeActivityFormUrl,
    moreLinkButton: { target },
  }) => {
    if (data.variant === CAROUSEL_VARIANT.yaFeatureListingExtn) {
      const { soldOutLabel, buyNowLabel, registerLabel, upcomingEventLabel, theme } = data;
      if (isFree) {
        url = freeActivityFormUrl;
      }

      const buttonLabel = getCardButtonLabel(
        isSoldOut,
        soldOutLabel,
        isFree,
        registerLabel,
        buyNowLabel,
        isUpcomingEvent,
        upcomingEventLabel,
      );

      return (
        <div
          className={classNames('btn-primary', {
            'buy-button': isBuyButton(isSoldOut, isFree, theme, isUpcomingEvent),
          })}
        >
          <Text
            tag="button"
            className={classNames({ disabled: isSoldOut || isUpcomingEvent })}
            field={{ value: buttonLabel, editable: buttonLabel }}
            onClick={
              isSoldOut || isUpcomingEvent
                ? null
                : () => {
                    if (target) {
                      window.open(url, target);
                      return;
                    }
                    window.location.href = url;
                  }
            }
          />
        </div>
      );
    }
    return null;
  };

  const renderGridHeader = (imageRenditions) => {
    const { bodyCopy, headerImage, imageDetail, shortTitle, title, titleAlignment, variant, ctaDetail } = data;
    return (
      (headerImage || title || bodyCopy) && (
        <div
          className={`w--top w--content${!ctaDetail.label ? ' no-cta-btn-container' : ''} ${
            variant === CAROUSEL_VARIANT.similarProducts && imageDetail && imageDetail.length < 4
              ? ' no-carousel-similar-products'
              : ''
          }`}
        >
          {!errorCompVariant && headerImage && imageRenditions && <Image image={headerImage} />}
          {errorCompVariant && (
            <div className="err-image-container">
              <div className="error-image"></div>
            </div>
          )}
          <div className="w--content">
            {!errorCompVariant && (
              <DynamicContent tagName="p" attrs={{ className: 'heading-7' }} innerHtml={shortTitle} />
            )}
            {title ? (
              <DynamicContent
                tagName="h2"
                attrs={{
                  className: `heading-1 align-${(titleAlignment && titleAlignment.toLowerCase()) || 'center'}`,
                }}
                innerHtml={title}
              />
            ) : (
              ''
            )}
            {canUseDOM() && !errorCompVariant && bodyCopy && (
              <DynamicContent tagName="p" attrs={{ className: 'body-copy-3' }} innerHtml={bodyCopy} />
            )}
            {errorCompVariant && errorMsg && (
              <p className="dynamic-err-message body-copy-3">{errorMsg.map((data, key) => data.text)}</p>
            )}
          </div>
          {allPackagesVariant && (
            <div
              className={classNames({
                'btn-primary': errorCompVariant,
                'c-editorial-grid--cta btn btn-hero-cta': tenantID !== 'FWB2C' && !errorCompVariant,
                'c-booking-confirmation-error--backCta btn-primary': tenantID === 'FWB2C' && !errorCompVariant,
              })}
            >
              <AnchorLink link={ctaDetail} />
            </div>
          )}
        </div>
      )
    );
  };

  const gridMainComponent = ({ imageRenditions, CAROUSEL_VARIANT_CLASS }) => {
    const { componentTitle, ctaDetail, cssClass, theme, titleAlignment } = data;
    return (
      <div
        className={classNames(
          'component c-editorial-grid',
          { 'error-component': errorCompVariant },
          { 'masanory-grid': variant === 'v-editorialgrid-gallary-view' },
          theme,
          cssClass,
          CAROUSEL_VARIANT_CLASS,
        )}
        data-c-name="EditorialData"
        data-c-render="Universal"
        ref={gridRef}
      >
        {cssClass === 'character-carousel' && <div className="background-image-handler" />}
        {componentTitle && (
          <DynamicContent
            tagName="h3"
            attrs={{
              id: 'grid-title',
              className: `heading-3 align-${(titleAlignment && titleAlignment.toLowerCase()) || 'center'}`,
            }}
            innerHtml={componentTitle}
          />
        )}
        {renderGridHeader(imageRenditions)}
        {showEditorialItems()}
        {ctaDetail.label ? (
          !allPackagesVariant && (
            <div className={`${errorCompVariant ? 'w--bottom-err-component' : 'w--bottom'}`}>
              <div className="w--content">
                <div
                  className={classNames({
                    'btn-primary': errorCompVariant,
                    'c-editorial-grid--cta btn btn-hero-cta': tenantID !== 'FWB2C' && !errorCompVariant,
                    'c-booking-confirmation-error--backCta btn-primary': tenantID === 'FWB2C' && !errorCompVariant,
                  })}
                >
                  <AnchorLink link={ctaDetail} />
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="no-cta-btn"></div>
        )}
        {theme === 'editorial-grid-title-on-left' ? <div className="clear-float" /> : ''}
        {isPPAD && <div className="hero-image"></div>}
      </div>
    );
  };

  const preRender = () => {
    const { carousel, cssClass, imageDetail, theme, variant } = data;

    let customCarouselSettings = { centerPadding: '0px' };

    if (
      variant === CAROUSEL_VARIANT.allView ||
      variant === CAROUSEL_VARIANT.yaFeatureListingExtn ||
      variant === CAROUSEL_VARIANT.yaCarouselView ||
      variant === CAROUSEL_VARIANT.similarProducts ||
      variant === CAROUSEL_VARIANT.yaBannerExternal
    ) {
      customCarouselSettings = allViewSettings(customCarouselSettings, carousel, imageDetail, theme);
    } else if (variant === CAROUSEL_VARIANT.mobileView) {
      customCarouselSettings = getCustomCarouselSettings(carousel, imageDetail, theme);
    } else if (variant === CAROUSEL_VARIANT.fourGridLayout) {
      customCarouselSettings = getCustomCarouselSettings({ carousel, imageDetail, theme });
    }

    if (viewport === 'mobile') {
      if (theme === 'editorial-grid-center-tile-zoomed') {
        customCarouselSettings.centerPadding = CENTERPADDING.zoomedMobile;
      } else if (variant === CAROUSEL_VARIANT.similarProducts) {
        customCarouselSettings.centerPadding = CENTERPADDING.similarProduct;
      } else if (theme === 'editorial-grid-center-align') {
        customCarouselSettings.centerPadding = CENTERPADDING.titleOnLeft;
      } else if (theme === 'editorial-grid-title-on-left') {
        customCarouselSettings.centerPadding = CENTERPADDING.titleOnLeft;
      } else if (theme && theme.indexOf('editorial-grid-content-on-left') > -1) {
        customCarouselSettings.centerPadding = CENTERPADDING.contentOnLeft;
      } else if (theme && theme.indexOf('editorial-grid-half-full') > -1) {
        customCarouselSettings.centerPadding = CENTERPADDING.halfFull;
      } else if (theme && theme.indexOf('editorial-grid-five-cards') > -1) {
        customCarouselSettings.centerPadding = CENTERPADDING.pdpFiveCardIcons;
      } else if (isFourGridLayout) {
        customCarouselSettings.centerPadding = CENTERPADDING.sliderOnLeft;
      } else {
        customCarouselSettings.centerPadding = CENTERPADDING.mobile;
      }
    } else {
      customCarouselSettings.centerPadding = '0px';
    }

    if (theme && theme.indexOf('editorial-grid-five-cards') > -1) {
      customCarouselSettings.arrows =
        imageDetail && imageDetail.length > carousel.slidesToShow ? carousel.arrows : false;
      if (viewport === 'tablet') {
        customCarouselSettings.slidesToShow = 3;
      }
    }

    if (cssClass === 'character-carousel') {
      customCarouselSettings = characterCarouselSettings(customCarouselSettings, viewport, imageDetail);
    } else if (cssClass === 'content-carousel') {
      customCarouselSettings = contentCarouselSettings(customCarouselSettings, viewport);
    }

    settings.current = Object.assign(
      {},
      deepCloneObject(carouselDefaultSettings),
      deepCloneObject(customCarouselSettings),
      {
        beforeChange: (prev, next) => {
          setTimeout(() => {
            const slidesToShow = carousel.slidesToShow;
            !detectMobile() && addTabIndex(next + slidesToShow + 1, slidesToShow);
          }, customCarouselSettings.speed);
          !detectMobile() && removeTabIndex();
        },
      },
    );
    //Moving this code snippet out of useEffect
    if (
      variant === CAROUSEL_VARIANT.yaCarouselView ||
      variant === CAROUSEL_VARIANT.yaFeatureListingExtn ||
      variant === CAROUSEL_VARIANT.yaBannerExternal
    ) {
      settings.current.responsive = [
        { breakpoint: 1023, settings: { slidesToShow: 2, centerPadding: '5px' } },
        { breakpoint: 767, settings: { slidesToShow: 1, centerPadding: '5px' } },
      ];
    }
  };

  useBeforeFirstRender(preRender);
  const { headerImage, imageDetail, theme, variant } = data;

  try {
    const mainObj = getMainObject() || {};
    const enableYasIDVersion = Boolean(resolvePath(mainObj, 'additionalProperty.enableYasIDVersion', false));

    settings.current.infinite = !(imageDetail && imageDetail.length <= 1);
    if (theme && theme.indexOf('editorial-grid-five-cards') > -1) {
      const viewport = detectViewPort();
      if (viewport === 'desktop' || viewport === 'desktop-xl') {
        settings.current.infinite = settings.current.arrows;
      }
    }
    let CAROUSEL_VARIANT_CLASS = '';
    const imageRenditions =
      (headerImage && (headerImage.mobileImage.src || headerImage.tabletImage.src || headerImage.desktopImage.src)) ||
      null;

    if (
      variant === CAROUSEL_VARIANT.mobileView ||
      variant === CAROUSEL_VARIANT.twoGridMobileView ||
      variant === CAROUSEL_VARIANT.similarProducts
    ) {
      CAROUSEL_VARIANT_CLASS = variant;
    } else if (
      variant === CAROUSEL_VARIANT.allView ||
      variant === CAROUSEL_VARIANT.yaCarouselView ||
      variant === CAROUSEL_VARIANT.yaBannerExternal ||
      variant === GRID_CAROUSEL_VARIANT.gridView
    ) {
      CAROUSEL_VARIANT_CLASS = 'v-carousel-all-view';
    } else if (variant === CAROUSEL_VARIANT.yaFeatureListingExtn) {
      CAROUSEL_VARIANT_CLASS = CAROUSEL_VARIANT.yaFeatureListingExtn;
    } else if (variant === CAROUSEL_VARIANT.fourGridLayout) {
      CAROUSEL_VARIANT_CLASS = CAROUSEL_VARIANT.fourGridLayout;
    }

    return (
      show &&
      (UIConfig.b2c.purchaseJourney.editorialGridVariant.step_two === variant || errorCompVariant ? (
        checkInlineSignup() ? (
          <div>
            <YasIDRegistration
              {...data}
              showOverLay={showOverLay}
              enableYasIDVersion={enableYasIDVersion}
              variant={variant}
            />
            {errorMsg && gridMainComponent({ CAROUSEL_VARIANT_CLASS, imageRenditions })}
          </div>
        ) : !errorCompVariant && !isErrorExist && !isLoggedInUser() ? (
          <YasIDRegistration
            {...data}
            showOverLay={showOverLay}
            enableYasIDVersion={enableYasIDVersion}
            variant={variant}
          />
        ) : (
          errorMsg && gridMainComponent({ CAROUSEL_VARIANT_CLASS, imageRenditions })
        )
      ) : CAROUSEL_VARIANT_CLASS === CAROUSEL_VARIANT.similarProducts && imageDetail && imageDetail.length ? (
        gridMainComponent({ CAROUSEL_VARIANT_CLASS, imageRenditions })
      ) : (
        CAROUSEL_VARIANT_CLASS !== CAROUSEL_VARIANT.similarProducts &&
        gridMainComponent({ CAROUSEL_VARIANT_CLASS, imageRenditions })
      ))
    );
  } catch (err) {
    return logComponentRenderingError(err, 'EditorialGrid', variant);
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
EditorialGrid.propTypes = {
  data: PropTypes.shape({
    imageDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
    headerImage: PropTypes.object,
    shortTitle: PropTypes.string,
    title: PropTypes.string,
    bodyCopy: PropTypes.string,
    ctaDetail: PropTypes.object,
  }),
};

export default EditorialGrid;
