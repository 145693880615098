import React from 'react';
import { DynamicContent } from '../base';
import { getMainObject } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { logComponentRenderingError } from '../../../common/logger';
import { yasIconLoader } from '../../../assets/img';

import './redirection-overlay.scss';

const RedirectionOverlay = ({ redirectionLogo, redirectionTitle, redirectionDescription, redirectionFooterText }) => {
  try {
    const mainObj = getMainObject();
    let loaderImg = yasIconLoader;
    mainObj && mainObj.tenantID === 'SIB2C' && (loaderImg = UIConfig.loaderImage.saadiyatIsland);

    return (
      <div className="redirection-overlay-containar">
        <DynamicContent tagName="div" attrs={{ className: 'logo-containar' }} innerHtml={redirectionLogo.html} />
        <div className="redirection-body">
          <div className="redirection-content">
            <DynamicContent tagName="h2" innerHtml={redirectionTitle} attrs={{ className: 'redirection-title' }} />
            <DynamicContent
              tagName="h3"
              innerHtml={redirectionDescription}
              attrs={{ className: 'redirection-description' }}
            />
            <div className="redirection-loader">
              <img src={loaderImg} alt={redirectionLogo.loadAltText ? redirectionLogo.loadAltText : 'Loader'} />
            </div>
          </div>
        </div>
        <div className="redirection-footer">
          <DynamicContent
            tagName="p"
            attrs={{ className: 'redirection-footer-text' }}
            innerHtml={redirectionFooterText}
          />
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'RedirectionOverlay');
  }
};

export default RedirectionOverlay;
