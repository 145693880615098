import { Logging } from '../logger';

export class PendingSearchEvent {
  /**
   *
   * @param {analyticsService} analyticsService
   * @param {Object} searchEvent
   * @param {Object} queryObj of type {config : Object, promise: Promise}
   * @param {boolean} sendToCloud
   */
  constructor(analyticsService, searchEvent, queryObj, sendToCloud) {
    this.analyticsService = analyticsService;
    this.searchEvent = searchEvent;
    this.sendToCloud = sendToCloud;
    this.searchPromises = [];
    this.searchEvents = [];
    this.results = [];
    if (queryObj.promise) {
      this.handleDuringQuery(queryObj);
    }
  }

  getEventCause() {
    return this.searchEvent.actionCause;
  }

  /**
   *
   * @param {Object} queryObj
   */
  handleDuringQuery(queryObj) {
    if (this.finished) {
      Logging(null, 'pending-search-event', true, 'Query is already done executing, Exiting handleQuery');
      return false;
    }

    // we need to collect all search events and send them in one single batch.
    this.searchPromises.push(queryObj.promise);

    queryObj.promise
      .then((queryResults) => {
        const searchEvent = Object.assign({}, this.searchEvent);
        PendingSearchEvent.fillSearchEvent(searchEvent, queryObj.config, queryResults);
        this.searchEvents.push(searchEvent);
        this.results.push(queryResults);
        return queryResults;
      })
      .catch((error) => {
        Logging(error, 'pending-search-event', false, 'Search Api failed to get result');
      })
      // IE Edge `finally` block fix
      // https://github.com/quasarframework/quasar/issues/3427#issuecomment-468540385
      .catch(() => true)
      .then(() => {
        //This keeps track of all the searchPromises and when all the promises have been resolved, it fires flush method for sending events to server
        const index = this.searchPromises.indexOf(queryObj.promise);
        this.searchPromises.splice(index, 1);
        if (this.searchPromises.length === 0 && this.searchEvents.length) {
          this.flush();
        }
      });
  }

  /**
   * This method will send Search Events to Coveo
   */
  flush() {
    this.finished = true;
    if (this.searchEvents.length !== this.results.length) {
      Logging(null, 'pending-search-event', true, 'Mismatch between Search Initiated and results returned');
    }
    //This will Send Search Events to Analytics API
    setTimeout(() => {
      if (this.sendToCloud) {
        this.analyticsService.sendSearchEvents(this.searchEvents);
      }
    }, 0);
  }

  /**
   *
   * @param { Object } searchEvent
   * @param { Object } queryConfig
   * @param { Object } queryResults
   */
  static fillSearchEvent(searchEvent, queryConfig, queryResults) {
    searchEvent.queryPipeline = queryResults.pipeline;
    searchEvent.originLevel2 = searchEvent.originLevel2 || 'All';
    searchEvent.queryText = queryConfig.query || ''; // do not log the query sent to the server if possible; it may contain added syntax depending on options
    searchEvent.advancedQuery = queryConfig.aq;
    searchEvent.didYouMean = queryConfig.enableDidYouMean;
    searchEvent.numberOfResults = queryResults.totalCount;
    searchEvent.responseTime = queryResults.duration;
    searchEvent.pageNumber = queryConfig.pageNo / queryConfig.perPageResults;
    searchEvent.resultsPerPage = queryConfig.numberOfResults;
    searchEvent.searchQueryUid = queryResults.searchUid;
    searchEvent.queryPipeline = queryResults.pipeline;

    // The refinedKeywords field is important for Coveo Machine Learning in order to learn properly on query
    // made based on the long query.
    if (queryResults.refinedKeywords !== undefined && queryResults.refinedKeywords.length !== 0) {
      searchEvent.customData['refinedKeywords'] = queryResults.refinedKeywords;
    }
  }
}
