/*
 * tabs.js
 * This file contains the logic for making the tabs.
 * @licensor  Miral
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DropDown from '../drop-down';
import {
  getClosestByClass,
  generateArrays,
  switchTabFocusOnArrowPress,
  getLanguage,
  getMembershipDetails,
  isLoggedInUser,
  canUseDOM,
  isArabicMode,
  isMatchTenant,
  getGa4Category,
  toLowerCase,
  checkTenant,
  getLocalStorageByKey,
  setLocalStorage,
} from '../../../common/utility';
import { detectMobile, breakpoints, detectViewPort } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { logComponentRenderingError } from '../../../common/logger';
import cn from 'classnames';

import './tabs.scss';
import { ArrowToggle } from '../base/arrow-toggle/arrow-toggle';
import Slider from 'react-slick';
import GTMData from '../../container/b2c-purchase-journey/gtm-data';
import { SvgSprite } from '../base';
import SwadBanner from '../base/swad-banner';

/**
 * bindPanels   binds panel component for tabs
 * @param    {[Array]} tabPanels array of objects for which content panel needs ot be created
 * @return   {[Object]} JSX for navaigation links as Dropdown
 */
const bindPanels = (activeTab, tabPanels) =>
  tabPanels.map(($panel) => (
    <Panel
      index={$panel.props.index}
      id={$panel.props.id}
      activeTab={activeTab}
      classes={$panel.props.classes}
      key={$panel.props.index}
    >
      {$panel.props.children}
    </Panel>
  ));

/**
 * getPanelId   function to return active tab's id
 * @param    {[event]} e event object of the clicked element
 * @return   {[Number]}  oanelId Function returns id of panel to show.
 */
const getPanelId = (e) => {
  var id = parseInt(e.target && e.target.getAttribute('data-tab-id'), 10);
  var ddnId = parseInt(e && e.index, 10);
  let panelId = isNaN(id) ? ddnId : id;

  if (panelId || panelId === 0) {
    return panelId;
  } else {
    const ele = getClosestByClass(e.target, 'tabs-menu--links'),
      parentEle = ele ? ele.dataset : null;

    if (parentEle && (parentEle.tabId || parentEle.index)) {
      panelId = parseInt(parentEle.tabId, 10);
    } else {
      panelId = null;
    }
  }
  return panelId;
};

/**
 * handleKeyPress   function to handle key press on tab
 * @param    {[event]} e event object of the clicked element
 * @return   {[Void]} Function does not return anything.
 */
const handleKeyPress = (e, setActive, togglePanel) => {
  var code = e.keyCode ? e.keyCode : e.which;
  if (code === 13) {
    setActive(e);
  }

  if (e.which === 39 || e.which === 37) {
    if (getLanguage() === 'AR') {
      arrowKeyHandlerLangAR(e, togglePanel);
    } else {
      arrowKeyHandler(e, togglePanel);
    }
  }
};

const arrowKeyHandler = (e, togglePanel) => {
  const selectedTab = e.target.parentElement.querySelector('li.is-active');
  const tabCount = e.target.parentElement.childElementCount;
  let panelId = parseInt(selectedTab.getAttribute('data-tab-id'), 10);

  if (e.which === 37 && panelId > 0 && panelId <= tabCount) {
    panelId--;
    togglePanel(panelId);
  } else if (e.which === 39 && panelId >= 0 && panelId < tabCount - 1) {
    panelId++;
    togglePanel(panelId);
  }
};

/**
 * Arrow key movement when language is arabic
 */

const arrowKeyHandlerLangAR = (e, togglePanel) => {
  const selectedTab = e.target.parentElement.querySelector('li.is-active');
  const tabCount = e.target.parentElement.childElementCount;
  let panelId = parseInt(selectedTab.getAttribute('data-tab-id'), 10);
  if (e.which === 37 && panelId >= 0 && panelId < tabCount - 1) {
    // left arrow
    panelId++;
    togglePanel(panelId);
  } else if (e.which === 39 && panelId > 0 && panelId <= tabCount) {
    // right arrow
    panelId--;
    togglePanel(panelId);
  }
};
/**
 * createDetail   creates JSX for details in tab component
 * @param    {Void} function input param
 * @return   {[Object]} JSX for tab panels
 */
const createDetail = (activeTab, props) => {
  if (props.children[2]) {
    const tabPanelContainer = props.children[2];
    const tabPanels = tabPanelContainer.props.children;
    return <TabPanels classes={tabPanelContainer.props.classes}>{bindPanels(activeTab, tabPanels)}</TabPanels>;
  }
};

/**
 * createPanels   creates JSX for panels in tab component
 * @param    {Void} function input param
 * @return   {[Object]} JSX for tab panels
 */
const createPanels = (activeTab, props) => {
  const tabPanelContainer = props.children[1];
  const tabPanels = tabPanelContainer.props.children;
  return <TabPanels classes={tabPanelContainer.props.classes}>{bindPanels(activeTab, tabPanels)}</TabPanels>;
};

/**
 * bindHeaders   binds dropdown component for tab navigation on mobile
 * @param    {[Array]} menuLinks array of objects for which navifation links needs ot be created
 * @return   {[Object]} JSX for navaigation links as Dropdown
 */
const bindHeaders = (activeTab, menuLinks, setActive) =>
  menuLinks.map(($menu) => (
    <Header
      handleClick={setActive}
      setActive={setActive}
      index={$menu.props.index}
      activeTab={activeTab}
      classes={$menu.props.classes}
      key={$menu.props.index}
    >
      {$menu.props.children}
    </Header>
  ));

const bindSliderLogoHeaders = (activeTab, menuLinks, setActive) => {
  return (
    <>
      {menuLinks.length > 4 && (
        <>
          <Slider
            {...{
              dots: false,
              arrows: true,
              infinite: false,
              slidesToShow: 4,
              slidesToScroll: 4,
              afterChange: function(currentSlide) {
                const currSlideCount = currentSlide / 4 + 1;
                document.getElementsByClassName('slideCount')[0].innerText = currSlideCount;
              },
            }}
          >
            {menuLinks.map(($menu) => (
              <span>
                <Header
                  handleClick={setActive}
                  setActive={setActive}
                  index={$menu.props.index}
                  activeTab={activeTab}
                  classes={$menu.props.classes}
                  key={$menu.props.index}
                >
                  {$menu.props.children}
                </Header>
              </span>
            ))}
          </Slider>

          <div className="sliderCounter">
            <span className="slideCount">{UIConfig.heroCarouselSetting.slidesToShow}</span> /{' '}
            {Math.ceil(menuLinks.length / 4)}
          </div>
        </>
      )}
    </>
  );
};

const Tabs = (props) => {
  const isDropDownEnabled = props.dropDownOnMobile || false;
  const isArrowToggleEnabled = props.enableArrowToggle;
  const isSlideTabsAnimation = props.slideTabsAnimation || false;
  const [activeTab, setActiveTab] = useState(isNaN(props.defaultActiveTab) ? 0 : props.defaultActiveTab);
  const [enableDropDown, setEnableDropDown] = useState(isDropDownEnabled && detectMobile());
  const [enableSwadCorpBanner, setEnableSwadCorpBanner] = useState(() => getLocalStorageByKey('showBookingMembership'));
  const [membershipResponse, setMembershipResponse] = useState({
    showMembership: false,
    membershipData: {},
  });
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isSubVariant = props.subVariant;
  const tabs = useRef('');
  const isTogglePanel = useRef(false);
  const defaultActiveTab = useRef(props.defaultActiveTab);
  const membershipPropsData = (props.tabData && props.tabData.miniCart && props.tabData.miniCart.memberDiscounts) || [];

  useEffect(() => {
    if (!props.resetActiveTab && props.defaultActiveTab === defaultActiveTab.current) {
      return;
    }
    const tabIdx = isNaN(props.defaultActiveTab) ? 0 : props.defaultActiveTab;
    defaultActiveTab.current = tabIdx;
    setActiveTab(tabIdx);
  }, [props.defaultActiveTab, props.resetActiveTab]);

  useEffect(() => {
    if (props.onTabsChange) {
      props.onTabsChange(activeTab);
    }
    window.PubSub.publish('dropdownChangeInMobile', {
      isDropdownChange: true,
    });
  }, [activeTab]);
  useEffect(() => {
    tabs.current = generateArrays('[role="tab"]');
  });

  useEffect(() => {
    window.PubSub.subscribe('oreintationChange', () => {
      if (isDropDownEnabled) {
        if (window.innerWidth >= breakpoints.desktop) {
          setEnableDropDown(false);
        } else {
          setEnableDropDown(true);
        }
      }
    });
    const localstoragemembership = getMembershipDetails();

    if (localstoragemembership) {
      setMembershipResponse({
        showMembership: localstoragemembership.isMembershipDetails,
        membershipData: localstoragemembership.membershipData,
      });
    }
    window.PubSub.subscribe('membershipDetailsFromScv', (msg, data) => {
      setMembershipResponse({
        showMembership: data.isMembershipDetails,
        membershipData: data.membershipData,
      });
    });

    tabs.current = generateArrays('[role="tab"]');

    return () => {
      window.PubSub.unsubscribe('oreintationChange');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTogglePanel.current) {
      const { onAfterChange } = props;
      if (onAfterChange) {
        onAfterChange(activeTab);
      }
      isTogglePanel.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  /**
   * setActive   function to set the clicked active tab
   * @param    {[event]} e event object of the clicked element
   * @return   {[Void]} Function does not return anything.
   */
  const setActive = (e) => {
    if (isMatchTenant(UIConfig.tenants.yi)) {
      GTMData.push(UIConfig.ga4Constants.CLICK_TAB, {
        name: toLowerCase(e.target?.innerText),
        elementText: toLowerCase(e.target?.innerText),
        category: toLowerCase(getGa4Category(window?.location?.pathname)),
      });
    }
    const panelId = getPanelId(e);

    if (panelId || panelId === 0) {
      togglePanel(panelId);
    }

    e.preventDefault && e.preventDefault();
  };

  /**
   * togglePanel   toggle visbility of the panel show/hide
   * @param    {[Number]} id id of the tab clicked
   * @return   {[Void]} Function does not return anything.
   */
  const togglePanel = (id) => {
    const { onBeforeChange } = props;
    if (onBeforeChange) {
      onBeforeChange(id);
    }

    setActiveTab(id);

    isTogglePanel.current = true;
  };

  /**
   * createHeaderLinks      creates JSX for navigation links in tab component
   * @param    {Void} function input param
   * @return   {[Object]} JSX for tab navigations
   */
  const createHeaderLinks = () => {
    if (props.children[0] && props.children[0].props.children) {
      const tabHeaderContainer = props.children[0];
      const menuLinks = tabHeaderContainer.props.children;
      return enableDropDown && !tabHeaderContainer.props.hideFilterDropdownOnMobile ? (
        bindDropDown(menuLinks)
      ) : (
        <TabHeaders
          classes={tabHeaderContainer.props.classes}
          handleKeyUp={(event) => {
            handleKeyPress(event, setActive, togglePanel);
            switchTabFocusOnArrowPress(event, tabs.current);
          }}
          isArrowToggleEnabled={isArrowToggleEnabled}
          isSlideTabsAnimation={isSlideTabsAnimation}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          title={tabHeaderContainer.props.title}
          sortingElement={tabHeaderContainer.props.sortingElement}
        >
          {isSubVariant === 'scroll-logo' && detectViewPort() !== UIConfig.viewportTypes.mobile
            ? bindSliderLogoHeaders(activeTab, menuLinks, setActive)
            : bindHeaders(activeTab, menuLinks, setActive)}
        </TabHeaders>
      );
    } else {
      return;
    }
  };

  const closeOverlay = () => {
    setEnableSwadCorpBanner(false);
    setLocalStorage('showBookingMembership', false);
  };

  /**
   * bindDropDown   binds dropdown component for tab navigation on mobile
   * @param    {[Array]} menuLinks array of objects for which navifation links needs ot be created
   * @return   {[Object]} JSX for navaigation links as Dropdown
   */
  const bindDropDown = (menuLinks) => {
    const menuItems = [];
    let defaultText = '';
    menuLinks = menuLinks.filter((item) => item.props.classes !== 'hide-tab');
    menuLinks.forEach(($menu) => {
      let menuItem = {};
      if ($menu.props.children.props) {
        menuItem = {
          text: $menu.props.children.props.innerHtml,
          value: $menu.props.children.props.innerHtml,
          classes: $menu.props.classes,
          index: $menu.props.index,
        };
        if (activeTab === menuItem.index) {
          defaultText = menuItem.value;
        }
        menuItems.push(menuItem);
      }
    });
    if (menuItems.length !== 0) {
      return (
        <div className="tabs-dropdown">
          <DropDown
            defaultValue={defaultText}
            listItems={menuItems}
            callBackFunction={setActive}
            isUpdateDropdownProps={enableDropDown}
          />
        </div>
      );
    }
  };
  const renderSegmentBanner = (findMembership) => {
    if (isSwad)
      return (
        <SwadBanner
          title={findMembership.bookingDiscountTitle}
          description={findMembership.bookingDiscountSubtitle}
          onClose={closeOverlay}
        />
      );
    return (
      <div className={cn('my-cart-member-container')}>
        <div className="member-title member-border">{findMembership.bookingDiscountTitle}</div>
        <div className="member-subtitle">{findMembership.bookingDiscountSubtitle}</div>
      </div>
    );
  };

  try {
    const menuLinks = createHeaderLinks();
    const panels = createPanels(activeTab, props);
    const details = createDetail(activeTab, props);
    const membershipfromscv =
      Object.keys(membershipResponse.membershipData).length > 0 ? membershipResponse.membershipData.segmentType : '';
    const findMembership =
      membershipPropsData &&
      membershipPropsData.length > 0 &&
      membershipPropsData.find((member) => member.discountType.toLowerCase() === membershipfromscv.toLowerCase());
    const sessionBasedPopUp = isSwad ? (enableSwadCorpBanner && membershipResponse.showMembership) || false : true;
    return (
      <div
        data-c-render="client-only"
        data-c-name="tabs"
        className={`tabs ${
          !enableDropDown
            ? 'tab-slider'
            : props.children[0] && props.children[0].props && props.children[0].props.hideFilterDropdownOnMobile
            ? 'tab-slider'
            : ''
        }`}
      >
        {canUseDOM() &&
          isLoggedInUser() &&
          !props.annualPassGridVariant &&
          findMembership &&
          sessionBasedPopUp &&
          Object.keys(findMembership).length > 0 &&
          renderSegmentBanner(findMembership)}

        {menuLinks}
        {panels}
        {details}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Tabs');
  }
};

Tabs.propTypes = {
  dropDownOnMobile: PropTypes.bool,
  defaultActiveTab: PropTypes.number,
  onAfterChange: PropTypes.func,
  onBeforeChange: PropTypes.func,
};

const TabHeaders = ({
  children,
  classes = '',
  handleKeyUp,
  isArrowToggleEnabled,
  isSlideTabsAnimation,
  setActiveTab,
  activeTab,
  title,
  sortingElement,
}) => {
  try {
    const switchTab = (switchDetails) => {
      let _currentTabIndex = activeTab;

      if (switchDetails === 'increment' && _currentTabIndex < children.length - 1) {
        setActiveTab((_currentTabIndex = _currentTabIndex + 1));
      } else if (switchDetails === 'decrement' && _currentTabIndex > 0) {
        setActiveTab((_currentTabIndex = _currentTabIndex - 1));
      }
    };

    return (
      <nav className={classNames('tabs-menu-container', classes)} onKeyUp={handleKeyUp}>
        {sortingElement && <>{sortingElement} </>}
        <div class="tab-wrapper">
          {title && (
            <div className="form-control-tabs">
              <h5 className="tab-title">{title}</h5>
            </div>
          )}
          {isArrowToggleEnabled && (
            <ArrowToggle maxCount={children.length} currentIndex={activeTab} onToggle={switchTab} />
          )}
          <ul className={`tabs-menu custom-${activeTab}`} role="tablist" aria-label="" data-testid="lists">
            {children}
            {/* apply for slideanimation */}
            {isSlideTabsAnimation && <li className="glider"></li>}
          </ul>
        </div>
      </nav>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'TabHeaders');
  }
};

const Header = ({ activeTab, children, classes = '', handleClick, index }) => {
  try {
    let simpleAccessibility = classes.includes('simple_accessibility');
    const isActiveTab = activeTab === index;
    return (
      <li
        tabIndex={isActiveTab || simpleAccessibility ? 0 : -1}
        id={`tab${index}`}
        data-testid={`tab${index}`}
        data-tab-id={index}
        className={classNames('tabs-menu--links heading-6', { 'is-active': isActiveTab }, classes)}
        key={index}
        onClick={handleClick}
        role="tab"
        aria-controls={`panel${index}`}
        aria-selected={!!isActiveTab}
        aria-label={children.props.innerHtml}
      >
        {children}
      </li>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Tabs Header');
  }
};

Header.propTypes = {
  activeTab: PropTypes.number,
  classes: PropTypes.string,
  handleClick: PropTypes.func,
  index: PropTypes.number.isRequired,
};

const TabPanels = ({ children, classes = '' }) => {
  return <section className={classNames('tabs-panel-container', classes)}>{children}</section>;
};

const Panel = ({ activeTab, children, classes = '', index, id }) => {
  try {
    const isActive = activeTab === index ? 'is-active ' : '';

    return (
      !!isActive && (
        <div
          className={classNames('tabs-panel', isActive, classes)}
          key={index}
          id={id ?? `panel${index}`}
          role="tabpanel"
          aria-labelledby={`tab${index}`}
        >
          {children}
        </div>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Panel');
  }
};

Panel.propTypes = {
  activeTab: PropTypes.number,
  classes: PropTypes.string,
  index: PropTypes.number.isRequired,
};

export { Tabs, Header, Panel, TabPanels, TabHeaders };
