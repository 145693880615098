/**
 * tab-panel-component.js
 * This file contains code for generating multiple compnents inside a TAB
 * @licensor  Miral
 */

import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';

import { DynamicImport } from '../../presentation/base';
import { canUseDOM } from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';
import CollapsibleContent from '../../container/collapsible-content';
import EditorialList from '../../container/editorial-list';

const renderedComponents = [];

const importSSRComponent = (componentData) => {
  const { componentName, data } = componentData;

  if (!canUseDOM || !componentName || !data) {
    return null;
  }
  let Component = CollapsibleContent;
  if (componentName === 'EditorialList') {
    Component = EditorialList;
  }
  return <Component {...data} />;
};

const getTabHtml = (components, tabTitle, index) => {
  return components.map((component, ix) => {
    const componentData = { ...component, data: { data: { ...component.data.data, tabTitle } } };
    if (!canUseDOM()) {
      return importSSRComponent(componentData);
    }
    return <DynamicImport componentData={componentData} index={index} key={`tab-${ix}`} />;
  });
};

const TabPanel = ({ activeTab, index, tabsData }) => {
  const tabHtml = useRef(null);
  const { components, tabTitle = '' } = tabsData;

  try {
    if (canUseDOM() && activeTab) {
      tabHtml.current = getTabHtml(components, tabTitle, index);
    }

    if (!canUseDOM() && index) {
      tabHtml.current = getTabHtml(components, tabTitle, index);
    }

    return tabHtml.current && <div className="component-wrapper">{tabHtml.current}</div>;
  } catch (err) {
    return logComponentRenderingError(err, 'TabPanel', tabsData.data.variant);
  }
};

TabPanel.propTypes = {
  tabsData: PropTypes.shape({
    componentName: PropTypes.string,
    data: PropTypes.object,
  }),
};

export default memo(TabPanel, (prevProps, nextProps) => {
  let isComponentRendered = false;
  if (nextProps.activeTab) {
    const compId = `${nextProps.tabsData.placeholderName}-${nextProps.index}`;
    isComponentRendered = renderedComponents.includes(compId);
    !isComponentRendered && renderedComponents.push(compId);
  }
  return isComponentRendered;
});
