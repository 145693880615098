/**
 * spinner-components.js
 * This file contains code for product-quantity component.
 * @licensor  Miral
 */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { SvgSprite } from '..';
import { canUseDOM, isArabicMode, isMatchTenant } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import { DynamicContent } from '../../../presentation/base';

import './spinner-component.scss';

const Spinner = (props) => {
  const [quantity, setQuantity] = useState(parseInt(props.quantity, 10));
  const [changeSpinner, setChangeSpinner] = useState(false);
  const [soldOutLimitError, setSoldOutLimitError] = useState('');
  const name = props.name || '';
  const spinnerInput = useRef();
  const adultcounter = useRef(null);
  const dummycounter = useRef(null);
  const addToCartClicked = useRef(props.addToCartClicked);

  useEffect(() => {
    const concernedElement = document.querySelector('.v-BookingWidget_RedBackground-active');

    document.addEventListener('mousedown', (e) => {
      if (concernedElement && concernedElement.contains(e.target)) {
      } else {
        setChangeSpinner(false);
      }
    });

    window.PubSub.subscribe('addonAvailibility', (msg, data) => {
      data.quantity && setQuantity(data.quantity);
      setSoldOutLimitError(data.soldOutLimitError);
    });
  }, []);

  useEffect(() => {
    if (
      ((!props.quantity && addToCartClicked.current !== props.addToCartClicked) || props.isJuniorTicketError) &&
      adultcounter.current
    ) {
      adultcounter.current.focus();
      setTimeout(() => {
        adultcounter.current.scrollIntoView({ behaviour: 'instant', block: 'center' });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    setQuantity(props.quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const selectText = (e) => {
    e.target.select();
  };

  const checkIfPkgMaxQty = (callback, updateError) => {
    if (props.isPackageProd) {
      if (!props.isMaxQuantity()) {
        callback(quantity + props.increaseDecreaseBy, updateError ? name : '');
        updateError && props.setShowMaxQuantityError(false);
      } else {
        updateError && props.setShowMaxQuantityError(true);
      }
    } else {
      callback(quantity + props.increaseDecreaseBy, updateError ? name : '');
    }
  };

  const increaseCount = (increaseCount) => {
    if (!props.isPastPurchasedRecommendation) {
      checkIfPkgMaxQty(updateState);
    } else {
      if (!props.isMaxQuantity()) {
        updateState(quantity + props.increaseDecreaseBy, 'inc');
        props.setShowMaxQuantityError(false);
      } else {
        props.setShowMaxQuantityError(true);
      }
    }
    if (props.checkQuantity) {
      checkIfPkgMaxQty(props.checkQuantity, true);
    }
    addAriaLive('assertive');
    if (props.sectorQuantity !== 0 && props.checkQuantity > props.sectorQuantity) {
      props.buttonDisabledOnError(true);
    }
    if (props.GAForIncreaseDecrease) {
      props.GAForIncreaseDecrease(
        true,
        UIConfig.ticketTypeJunior === increaseCount.toLowerCase(),
        props.increaseDecreaseBy,
      );
    }
  };

  const decreaseCount = (increaseCount) => {
    const { isPackageProd, packageMaxAvail } = props;
    let quantityCount = quantity - props.increaseDecreaseBy;
    if (quantityCount < 0) quantityCount = props.min;
    updateState(quantityCount, 'dec');
    if (
      (props.isPastPurchasedRecommendation && props.isMaxQuantity && !props.isMaxQuantity()) ||
      (isPackageProd && quantityCount < packageMaxAvail)
    ) {
      props.setShowMaxQuantityError(false);
    }
    if (props.checkQuantity) {
      props.checkQuantity(quantityCount, name);
    }
    addAriaLive('assertive');
    if (props.sectorQuantity !== 0 && props.checkQuantity > props.sectorQuantity) {
      props.buttonDisabledOnError(true);
    }
    if (props.GAForIncreaseDecrease) {
      props.GAForIncreaseDecrease(
        false,
        UIConfig.ticketTypeJunior === increaseCount.toLowerCase(),
        props.increaseDecreaseBy,
      );
    }
  };

  const addAriaLive = (ariaLiveProp) => {
    if (!spinnerInput.current.getAttribute('aria-live')) {
      spinnerInput.current.setAttribute('aria-live', ariaLiveProp);
    }
  };

  const changeValue = (e) => {
    let val = e.target.value;
    if (val === '') {
      updateState(val);
    } else {
      val = parseInt(val, 10);
      if (/^[0-9]+$/.test(val) && val >= props.min) {
        updateState(val);
      }
    }
  };

  const checkEmpty = (e) => {
    if (!e.target.value) {
      updateState(0);
    }
    if (props.checkQuantity) {
      props.checkQuantity(quantity);
    }
  };

  const updateState = (val, type) => {
    setQuantity(val);
    setChangeSpinner(true);
    if (props.addSubQuantity && type) {
      let newValue =
        type === 'inc' ? props.addedQuatity + props.increaseDecreaseBy : props.addedQuatity - props.increaseDecreaseBy;
      if (newValue >= 0) {
        props.addSubQuantity(newValue);
      }
    }
    if (props.updateQuantity) {
      props.updateQuantity(val, props.eventKey);
    }
  };

  const spinnerActiveBorder = props.isHotelBookingWidgetRedVariant
    ? `${props.isHotelBookingWidgetRedVariant + '-active'}`
    : '';

  const spinnerActiveBorderYi = props.vBookingWidgetOnlyStartDate
    ? `${props.vBookingWidgetOnlyStartDate + '-active'}`
    : '';

  const hasAdultSpinnerError = (spinnerName) => spinnerName === 'adult' && props.addToCartClicked;

  const hasJuniorSpinnerError = (spinnerName) => spinnerName === 'junior' && props.isJuniorTicketError;

  try {
    const ariaLabel = props.ariaLabel || props.ariaLabelSpinner || 'Ticket(s)';
    const direction = canUseDOM() && isArabicMode() ? 'ltr' : '';
    const nameInLC = name.toLowerCase();
    const allParkTanent =
      isMatchTenant(UIConfig.tenants.fwad) ||
      isMatchTenant(UIConfig.tenants.wbw) ||
      isMatchTenant(UIConfig.tenants.yww) ||
      isMatchTenant(UIConfig.tenants.ppad);

    return (
      <Fragment>
        <div className="c-spinner" dir={direction}>
          <button
            className="decerease-btn"
            disabled={quantity <= props.min || props.inValidCart || props.isSpinnerDisabled}
            onClick={() => decreaseCount(ariaLabel)}
            tabIndex={props.disableSpinner ? '-1' : '0'}
            aria-label={`${props.ariaLabelDecrease} ${props.increaseDecreaseBy}. ${props.ticketCounterText} ${quantity} ${ariaLabel}`}
          >
            <SvgSprite id="spinner-subtract" />
          </button>
          <label className="sr-only" htmlFor={`${nameInLC}_counter`}></label>
          <input
            ref={spinnerInput}
            type="text"
            className={`quantity body-copy-5 ${(isMatchTenant(UIConfig.YIB2C) && changeSpinner
              ? spinnerActiveBorder
              : '') || (props.vBookingWidgetOnlyStartDate && changeSpinner ? spinnerActiveBorderYi : '')} ${
              !props.quantity && (hasAdultSpinnerError(nameInLC) || hasJuniorSpinnerError(nameInLC))
                ? 'input-focus'
                : ''
            } ${quantity > 0 ? 'quantity-active' : ''}`}
            min={props.min}
            max={props.max}
            disabled={Boolean(props.isInputDisabled) || false}
            value={quantity}
            onChange={changeValue}
            onClick={selectText}
            onBlur={checkEmpty}
            id={`${nameInLC}_${props?.ariaLabel || ''}_counter`}
            name={`${nameInLC}_${props?.ariaLabel || ''}_counter`}
            aria-live={null}
          />
          <button
            className="increase-btn"
            disabled={
              quantity >= props.max ||
              props.inValidCart ||
              props.increamentDisabed ||
              props.isSpinnerDisabled ||
              hasJuniorSpinnerError(nameInLC) ||
              (props.sectorQuantity !== 0 && props.quantity > props.sectorQuantity)
            }
            onClick={() => increaseCount(ariaLabel)}
            tabIndex={props.disableSpinner ? '-1' : '0'}
            ref={nameInLC === 'adult' ? adultcounter : dummycounter}
            aria-label={`${props.ariaLabelIncrease} ${props.increaseDecreaseBy}. ${props.ticketCounterText} ${quantity} ${ariaLabel}`}
          >
            <SvgSprite id="spinner-add" />
          </button>
        </div>
        {props.sectorQuantity !== 0 && props.quantity > props.sectorQuantity && (
          <DynamicContent
            attrs={{ className: 'sector-capacity-error' }}
            tagName="p"
            innerHtml={props.sectorErrorMessage}
          />
        )}
        {allParkTanent && !props.quantity && (hasAdultSpinnerError(nameInLC) || hasJuniorSpinnerError(nameInLC)) && (
          <DynamicContent
            attrs={{ className: 'adult-ticket-error' }}
            tagName="p"
            innerHtml={props.adultSpinnerErrorMsg}
          />
        )}
        {props.isAddOn && soldOutLimitError && (
          <DynamicContent attrs={{ className: 'sold-out-error' }} tagName="p" innerHtml={soldOutLimitError} />
        )}
      </Fragment>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Spinner');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
Spinner.propTypes = {
  min: PropTypes.number,
  quantity: PropTypes.number,
  increaseDecreaseBy: PropTypes.number,
};

Spinner.defaultProps = {
  min: 0,
  quantity: 0,
  increaseDecreaseBy: 1,
  ariaLabelDecrease: 'Decrease ticket count by',
  ariaLabelIncrease: 'Increase ticket count by',
  ticketCounterText: 'Current count',
  isSpinnerDisabled: false,
};

export default Spinner;
