import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  checkAnonymousCart,
  checkInlineSignup,
  getAnonymousCart,
  getArrayOfElement,
  getErrorMap,
  getLoggedInUser,
  getMainObject,
  getUserAgent,
  isEmpty,
  isEmptyObject,
  massageMobileData,
  matchInArray,
  parseQueryString,
  removeADCBTouchPointsStorage,
  resolvePath,
  getSessionStorage,
  checkTenant,
  checkParksTenants,
  isMatchTenant,
} from '../../../../common/utility';
import { loadPayPal } from '../../payment/paypal/paypal-component';
import { seatedLocalStorageSvc } from '../cart/seated-journey-actions';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import GTMData from '../gtm-data';

/**
 * Sets Payment Component Variants.
 * Default Mode - 2 Step Payment
 */
export const setPaymentJourneyModes = (self, variant) => {
  const paymentJourneyModes = UIConfig.b2c.purchaseJourney.paymentVariants;

  switch (variant) {
    case paymentJourneyModes.is2StepPaymentYasArena:
      self.is2StepPayment = true;
      self.is2StepPaymentYasArena = true;
      break;
    default:
      self.is2StepPayment = true;
      break;
  }
};

/**
 * Sets Apple Pay Option Availaibility.
 * As CMS/Backend layer cannot detect from which browser Client is coming, we have to explicitly
 * remove Apple Pay Option for Chrome, Mozilla
 */
export const setApplePayVisibility = (self) => {
  self.props.data.paymentOptions.paymentTypes = self.props.data.paymentOptions.paymentTypes.filter(
    (item, i) => item.value !== UIConfig.paymentOption.applePay,
  );

  self.defaultOptionSelected = self.props.data.paymentOptions.paymentTypes[0];
  self.paymentMethod = self.defaultOptionSelected;
};

export const cartData = (self) => {
  if (canUseDOM()) {
    if (self.isB2B) {
      if (self.B2B_isCreditSettlement) {
        window.PubSub.subscribe('CreditSettlement', self.creditSettlementCallBack);
      } else if (self.B2B_isAdvanceTopUp) {
        window.PubSub.subscribe('AdvanceTopUp', self.advanceTopUpCallBack);
      } else {
        window.PubSub.subscribe('B2B_Cart', self.cartEveCallBack);
      }
    } else {
      window.PubSub.subscribe(UIConfig.events.ENABLE_PAYMENT_MYCART, self.cartEveCallBack);
    }
  }
};

export const initializingB2BClassVariables = (self) => {
  if (self.props.data && self.props.data.paymentOptions) {
    self.B2B_isCreditSettlement = self.props.data.paymentOptions.pageVariant === 'b2bCreditsettlement';
    self.B2B_isAdvanceTopUp = self.props.data.paymentOptions.pageVariant === 'b2bAdvanceTopUp';
    self.B2B_pageVariation = self.props.data.paymentOptions.pageVariant;
    self.B2B_reconcileCSAPData = [];
    self.isB2B = getLoggedInUser().tenantID === 'ALLB2B';
  }
};

export const b2bValidateDataSettlement = (self, data) => {
  const disabledId = document.getElementById('b2b-payment-disabled');
  if (data) {
    disabledId.classList.remove('c-payment-overlay');
  } else {
    disabledId.classList.add('c-payment-overlay');
  }
};

export const enableGuestPayment = (self) => {
  let proceedToPay = true;
  if (self.state.currentOption === UIConfig.paymentOption.paypal) {
    self.setPayPalData();
    proceedToPay = false;
  }
  self.setState({
    proceedToPay,
    cart: checkAnonymousCart() ? getAnonymousCart() : self.cart,
  });
};

export const checkPaymentOnOff = (self) => {
  if (
    sessionStorage.getItem(UIConfig.sessionStorageKeys.MY_CARD_SUCCESS) == 'true' &&
    sessionStorage.getItem(UIConfig.sessionStorageKeys.GUEST_DETAIL_SUCCESS) == 'true' &&
    sessionStorage.getItem(UIConfig.sessionStorageKeys.ANNUAL_PASS_FORM_SUCCESS) == 'true'
  ) {
    self.setState({ isEnabled: true });
  } else {
    self.setState({ isEnabled: false });
  }
};

export const checkPaymentMethodValidity = (self, data, currentOption) => {
  const { creditCard, adcbTouchPoints } = UIConfig.paymentOption;
  const paymentType = self.payData.paymentType;
  if (
    data &&
    (currentOption
      ? currentOption !== creditCard || currentOption !== adcbTouchPoints
      : paymentType !== creditCard || paymentType !== adcbTouchPoints)
  ) {
    const filterCode = self.payData.promoCodes.filter((item) => {
      return item.promoCode === data;
    });

    if (filterCode.length > 0) {
      self.setState({
        isPaymentMethodBlocked: true,
        blockedErrorMessage: filterCode[0].secondaryErrorMessage,
        isTncChecked: false,
      });
    }
  }
};

/**
 * setPayPalData function is used to append orderid and reservation code as query parameter for my cart component
 */
export const setPayPalData = (self) => {
  const {
    apMainPassholderPurchaseEmailTemplateID,
    apMainPassholderRenewEmailTemplateID,
    apAddPassholderNewUserPurchaseEmailTemplateID,
    apAddPassholderNewUserRenewEmailTemplateID,
    apAddPassholderRenewEmailTemplateID,
    apAddPassholderPurchaseEmailTemplateID,
  } = self.paymentOptions;

  const cartData = JSON.parse(getSessionStorage(UIConfig.localStoreKeys.purchaseJourney.cartData));
  const paypalCart = checkAnonymousCart()
    ? getAnonymousCart()
    : cartData && isEmptyObject(cartData)
    ? cartData
    : self.cart;

  let total = 0;

  if (paypalCart && paypalCart.cart && paypalCart.cart.grossPrice) {
    total = paypalCart.cart.grossPrice;
  }
  if (self.isB2B) {
    if (!self.state.B2BPaymentEnable && !self.B2B_isCreditSettlement) {
      total = paypalCart.cart.totalPrice;
    } else if (self.B2B_isCreditSettlement) {
      total = paypalCart.cart.CreditSettle.Amount;
    }
  }
  // For Advance Top Up Paypal amoumt
  if (self.isB2B && paypalCart.cart && paypalCart.cart.amount) {
    total = paypalCart.cart.amount;
  }
  self.payData.total = total;

  let currency = 'AED';
  if (paypalCart && paypalCart.cart && paypalCart.cart.currency) {
    currency = paypalCart.cart.currency;
  }
  self.payData.currency = currency;

  self.payData.conversionRate = self.paymentOptions.conversionRate;
  self.payData.emailTemplateId = self.emailTemplateId;
  self.payData.invoiceTemplateId = self.invoiceTemplateId;
  self.payData.paymentType = 'PayPal';
  self.payData.thankYouPageUrl = self.thankYouPageUrl;
  self.payData.marketType = self.marketType;
  self.payData.services = self.services;
  self.payData.componentName = UIConfig.moduleName.paypal;
  self.payData.dueDate = self.dueDate;
  self.payData.agentRefNum = self.partnerReference;
  self.payData.environment = self.paymentOptions.payPalEnvironment;
  self.payData.is2StepPayment = self.is2StepPayment;
  self.payData.emailTemplates = {
    apMainPassholderPurchaseEmailTemplateID,
    apMainPassholderRenewEmailTemplateID,
    apAddPassholderNewUserPurchaseEmailTemplateID,
    apAddPassholderNewUserRenewEmailTemplateID,
    apAddPassholderRenewEmailTemplateID,
    apAddPassholderPurchaseEmailTemplateID,
  };
  // used for changing the URL for sendTranction
  self.payData.B2B_isCreditSettlement = self.B2B_isCreditSettlement;
  self.payData.B2B_isAdvanceTopUp = self.B2B_isAdvanceTopUp;
  // used for sending page variation to paypal component
  self.payData.pageVariant = self.props.data.paymentOptions.pageVariant;
  // for view order
  if (
    self.isB2B &&
    !self.state.B2BPaymentEnable &&
    paypalCart.cart &&
    paypalCart.cart.orderID &&
    self.reservationCode
  ) {
    self.payData.orderID = paypalCart.cart.orderID;
    self.payData.reservationCode = self.reservationCode;
  }
  //For loading paypal button only once
  // if (checkAnonymousCart()) {
  //   if (self.isPayPalBtnLoaded) {
  // loadPayPal(self.payData, self.isErrorOccured, paypalCart, self.state.isPaymentMethodBlocked);
  //     self.isPayPalBtnLoaded = false;
  //   }
  // } else {
  //   if (self.isPayPalBtnLoaded) {
  // only for B2B: setting the reservation code and other data
  // const paymentFailureData = {
  //   reservationCode: self.reservationCode,
  //   url: self.services.UpdatePaymentStatus.url,
  // };
  // loadPayPal(self.payData, self.isErrorOccured, paymentFailureData);
  //     self.isPayPalBtnLoaded = false;
  //   }
  // }
  self.paypalCart = paypalCart;
  self.saveInvoiceDetails();
};

export const getGuestCheckoutSuccess = (self) => {
  if (canUseDOM()) {
    window.PubSub.subscribe('dataPosted', (msg, data) => {
      const { currentOption } = self.state;
      if (data.isDataPosted && self.state.currentOption === 'PayPal') {
        self.onPayPalButtonSubmitSuccess = true;
        self.setState({
          isTncChecked: true,
        });
      } else if (data.isDataPosted && UIConfig.payfort.payfortTypes.find((item) => item === currentOption)) {
        self.setPayfortData();
      } else if (
        data.isDataPosted &&
        (currentOption === UIConfig.paymentOption.postPay ||
          currentOption === UIConfig.paymentOption.aliPay ||
          self.checkPointsPayMethod(currentOption))
      ) {
        self.setRedirectPaymentData();
      } else if (
        data.isDataPosted &&
        (currentOption === UIConfig.paymentOption.adcbTouchPoints || currentOption === UIConfig.paymentOption.moneyCard)
      ) {
        const touchPointPurchaseInfo = localStorage.getItem(UIConfig.b2c.purchaseJourney.touchPoint.purchaseInfo),
          touchPointsCardInfo = touchPointPurchaseInfo && JSON.parse(touchPointPurchaseInfo);
        if (touchPointsCardInfo && touchPointsCardInfo.redeemAmount > 0 && touchPointsCardInfo.cardAmount <= 0) {
          self.setRedirectPaymentData();
        } else {
          self.setPayfortData();
        }
      }
    });
  }
};

export const guestUserHasErrors = (self) => {
  if (localStorage.anonymousCart) {
    let isDataNotEmpty = true;
    const { reservationOwner } = getAnonymousCart().cart;
    const inlineSignupHasError =
      (localStorage.inlineSignupHasError && localStorage.inlineSignupHasError.toString() === 'true') ||
      reservationOwner === null
        ? true
        : false;
    if (reservationOwner) {
      const { firstName, lastName, email, password, nationality, country } = reservationOwner;
      const data = {
        firstName,
        lastName,
        email,
        nationality,
        country,
      };
      if (checkInlineSignup()) {
        data.password = password;
      }
      isDataNotEmpty = isEmptyObject(data);
    }
    if (self.isYAB2C && !self.isLoggedInUser) {
      if (inlineSignupHasError || document.querySelectorAll('.yasid-email-error').length > 0) {
        self.setState({ isPaymentSectionEnabled: true });
      } else {
        self.setState({ isPaymentSectionEnabled: false });
      }
    }
    window.PubSub.publish('guestFormHasError', {
      isError:
        reservationOwner === null ||
        document.querySelectorAll('.error-msg').length > 0 ||
        document.querySelectorAll('.yasid-email-error').length > 0 ||
        !isDataNotEmpty,
    });
    return (
      reservationOwner === null ||
      document.querySelectorAll('.error-msg').length > 0 ||
      document.querySelectorAll('.yasid-email-error').length > 0 ||
      !isDataNotEmpty
    );
  }
  return false;
};

export const getExpressCheckoutMiniCartHeaderHeight = () => {
  if (canUseDOM()) {
    return isMatchTenant(UIConfig.tenants.fwad) ||
      isMatchTenant(UIConfig.tenants.wbw) ||
      isMatchTenant(UIConfig.tenants.yww)
      ? 64
      : 0;
  }
  return 0;
};

export const handleScrollOnPaymentSelection = (self) => {
  setTimeout(() => {
    const ele = document.querySelector('.selected-mode');
    let headerTop;
    const isLandscape = window.innerWidth > window.innerHeight;
    if (isLandscape) {
      headerTop = 60 + getExpressCheckoutMiniCartHeaderHeight();
    } else {
      headerTop = 90 + getExpressCheckoutMiniCartHeaderHeight();
    }
    let offsetTop = ele.offsetTop;
    offsetTop = offsetTop - headerTop;
    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
  }, 200);
};

/**
 * onOptionChangeHandler function changes the state of the Radio buttons. It detects the clicked radio button
 * and sets state accrdingly
 * @param    {[Object]} e [Event object of click].
 * @return   {Void} function does not return anything.
 */
export const onOptionChangeHandler = (self, e, selectedValue, blockPaymentCheckValidity, paymentHasError) => {
  self.isADCBAllowedMethodLoaded = true;
  window.PubSub.unsubscribe('CreateOrderSuccess');
  removeADCBTouchPointsStorage();
  if (checkParksTenants()) {
    self.isMobile && self.handleScrollOnPaymentSelection();
  }
  const val = !self.is2StepPayment ? e.target.value : selectedValue;
  let hasCardInvalidError = paymentHasError && !e;
  self.setState({
    isFirstPaymentExpanded: true,
    isPaymentMethodSwitched: !self.state.isPaymentMethodSwitched,
    hasTnCError: false,
  });
  if (val !== UIConfig.paymentOption.postPay) {
    self.setState({ postpayPaymentError: '' });
    localStorage.removeItem(UIConfig.localStoreKeys.postPayLimitError);
  }
  if (val !== UIConfig.paymentOption.tabby) {
    self.setState({ tabbyPaymentError: '' });
    localStorage.removeItem(UIConfig.localStoreKeys.tabbyLimitError);
  }
  if (self.is2StepPayment) {
    const payfortType = UIConfig.payfort.payfortTypes.find((item) => item === val);
    if (payfortType || val === UIConfig.paymentOption.adcbTouchPoints) {
      self.payFortData(payfortType);
    } else if (
      val === UIConfig.paymentOption.postPay ||
      val === UIConfig.paymentOption.gPay ||
      val === UIConfig.paymentOption.applePay ||
      self.checkPointsPayMethod(val)
    ) {
      // Need to reload paypal button when switched from Apple Pay Payment Option. Reason - Button is removed from dom
      self.isPayPalBtnLoaded = true;
    }
    if (
      val === UIConfig.paymentOption.paypal &&
      self.isMobile &&
      self.isPaymentVerticalView &&
      document.getElementsByClassName('paypal-button-label-checkout').length === 0
    ) {
      self.isPayPalBtnLoaded = true;
    }

    const currentTenant = getLoggedInUser().tenantID.toLowerCase();

    if (currentTenant !== UIConfig.YIB2C && !self.isB2B && !blockPaymentCheckValidity) {
      self.checkPaymentMethodValidity(self.state.cart.couponCode, val);
    }

    // YasIsland - This publish is used in PackageSummary component to handle its payment button's visibility
    if (currentTenant === UIConfig.YIB2C) window.PubSub.publish('paymentOptionChanged', val);
  }

  window.PubSub.publish(UIConfig.events.TOGGLE_GUEST_CHECKOUT);
  let proceedToPay, disableBookButton;
  if (val === UIConfig.paymentOption.paypal) {
    proceedToPay = true;
    disableBookButton = false;
  } else {
    proceedToPay = true;
    if (self.state.addDisableCheck) {
      disableBookButton = true;
    }
  }
  self.hasDefaultOption = false;
  if (self.is2StepPayment && getLoggedInUser().tenantID.toLowerCase() !== UIConfig.YIB2C) {
    !self.isB2B && !blockPaymentCheckValidity && self.checkPaymentMethodValidity(self.state.cart.couponCode, val);
  }
  if (self.isMobile) {
    self.slickSettings.initialSlide = self.paymentOptions.paymentTypes.findIndex((val) => val.value === selectedValue);
  }
  self.setState(
    {
      showCheckBox:
        self.is2StepPayment &&
        (val === UIConfig.paymentOption.paypal ||
          val === UIConfig.paymentOption.applePay ||
          val === UIConfig.paymentOption.aliPay ||
          val === UIConfig.paymentOption.gPay)
          ? true
          : !self.is2StepPayment
          ? true
          : false,
      currentOption: val,
      isCardDetailsNotValid: hasCardInvalidError,
      proceedToPay: proceedToPay,
      disableBookButton: disableBookButton,
      isTncChecked: false,
      errorData: null,
    },
    () => {
      val === UIConfig.paymentOption.paypal ? self.setPayPalData() : self.onPayment();
    },
  );

  setTimeout(() => {
    const data = document.querySelector('.swad-news-checkbox');
    if (data !== null) {
      data.checked = false;
    }
  }, 200);
};

/**
 * showLimitError function return a true or false in case of there is a limit error
 * @return   {Boolean} function return true or false.
 */
export const showLimitError = (self) => {
  const {
    cartData: { grossPrice },
  } = self.props;

  const { maximumPurchaseLimit, errorMsgForMaxPurchaseLimit } = self.paymentType;

  if (maximumPurchaseLimit && errorMsgForMaxPurchaseLimit && grossPrice && grossPrice > maximumPurchaseLimit) {
    return errorMsgForMaxPurchaseLimit;
  }
  return '';
};
/**
 * isErrorOccured function changes the state of the errorOccured to display error message incase error comes
 * @return   {Void} function does not return anything.
 */
export const isErrorOccured = (self, key, error, status = false, limitError = false) => {
  if (limitError) {
    switch (key) {
      case UIConfig.limitErrorsKeys.postPayLimit:
        self.setState({ postpayPaymentError: error });
        break;
      case UIConfig.limitErrorsKeys.tabbyLimit:
        self.setState({ tabbyPaymentError: error });
        break;

      default:
        break;
    }
    return;
  }
  const cmsErrors = key === 'default' ? key : self.services[key].errors,
    errorData = getErrorMap(key, cmsErrors, status, error, self.state.errorData);
  const isApplePayError = key === 'applePayMakePurchase' ? true : false;
  if (!isApplePayError) {
    self.setState({ errorData: errorData });
    if (
      self.props?.data?.showPerformanceCapacityErrors &&
      checkTenant(UIConfig.iamMapping.swad) &&
      errorData?.createOrder?.code == 5035
    ) {
      window.location.reload();
    }
  } else {
    self.setState({ paymentError: errorData[key].text, isCardDetailsNotValid: true });
  }
};

export const payFortData = (self, payfortType) => {
  const payfortCart = self.state.cart;
  const payFortData = self.props.data.paymentOptions;

  let total = 0;
  if (payfortCart && payfortCart.cart && payfortCart.cart.payfortAmount) {
    total = payfortCart.cart.payfortAmount;
  }
  self.payData.total = total;

  let currency = 'AED';
  if (payfortCart && payfortCart.cart && payfortCart.cart.currency) {
    currency = payfortCart.cart.currency;
  }

  self.payData.currency = currency;
  self.payData.emailTemplateId = self.emailTemplateId;
  self.payData.invoiceTemplateId = self.invoiceTemplateId;
  self.payData.marketType = self.marketType;
  self.payData.thankYouPageUrl = self.thankYouPageUrl;
  self.payData.paymentType = payfortType;
  self.payData.componentName = UIConfig.moduleName.payfort;
  self.payData.is2StepPayment = self.is2StepPayment;

  self.payData.paymentOptions = payFortData
    ? resolvePath(
        payFortData.paymentTypes.find((item) => item.value === UIConfig.paymentOption.creditCard),
        'paymentFormSection',
        null,
      )
    : '';
  self.payData.primaryCTA = payFortData ? payFortData.primaryCTA : '';
  self.payData.cardTypes = payFortData ? payFortData.cardTypes : '';
  self.payData.tAndcLabel = payFortData ? payFortData.tAndcLabel : '';
  self.payData.newsLetterLabel = payFortData ? payFortData.newsLetterLabel : '';
  self.payData.vatLabel = payFortData ? payFortData.vatLabel : '';
  self.payData.privacyPolicy = payFortData ? payFortData.privacyPolicy : '';
  self.payData.termsAndcondition = payFortData ? payFortData.termsAndcondition : '';
  self.payData.cardDetailImage = payFortData
    ? !self.is2StepPayment
      ? payFortData.cardDetailImage
      : payFortData.verifiedByCardImage
    : '';
  self.payData.errorMessageTNCRequired = payFortData ? payFortData.errorMessageTNCRequired : '';

  self.payData.promoCodes = self.props.data.promoCodes || [];
  self.payData.paymentCards = Array.isArray(self.props.data.paymentCards) ? self.props.data.paymentCards : [];

  if (self.isB2B && !self.state.B2BPaymentEnable) {
    self.payData.notEncrypted = true;
  }

  if (self.state.currentOption === UIConfig.paymentOption.adcbTouchPoints) {
    self.payData.componentName = UIConfig.paymentOption.adcbTouchPoints;
    self.payData.paymentType = UIConfig.paymentOption.adcbTouchPoints;
  }
};

export const getPointsPayConfig = (self) => {
  const { currentOption } = self.state;
  let pointsPayConfig = {};
  if (currentOption === UIConfig.paymentOption.pointsPayEtihad) {
    pointsPayConfig = self.paymentOptions.pointsPayEtihadConfig;
  } else if (currentOption === UIConfig.paymentOption.pointsPayEtisalat) {
    pointsPayConfig = self.paymentOptions.pointsPayEtisalatConfig;
  }
  return { ...pointsPayConfig, ...self.paymentOptions.pointsPayCategoryConfig };
};

export const getPostPayConfig = (self) => {
  const { currentOption } = self.state;
  let postPayConfig = {};
  if (currentOption === UIConfig.paymentOption.postPay) {
    postPayConfig = self.paymentOptions.postPayConfig;
  }
  return { ...postPayConfig, ...self.paymentOptions.postPayCategoryConfig };
};

export const checkForSeatedOrderId = (self) => {
  const tenantId = getLoggedInUser().tenantID || resolvePath(getMainObject(), 'tenantID');
  let isSeatedOrderIdExist = false;
  if (self.state.cart && self.state.cart.saleAk && (tenantId || '').toLowerCase() === UIConfig.ymcB2CTenant) {
    self.seatedLastOrderData = seatedLocalStorageSvc.GetSetOrderDetails();
    if (self.seatedLastOrderData && self.seatedLastOrderData.orderID) {
      isSeatedOrderIdExist = true;
    }
  }
  return isSeatedOrderIdExist;
};

export const yaCanCreateOrder = (self) => {
  // For Yas Arena - if Payment is failed we cannot do create order again and
  // we have to use existing orderDetails
  if (self.state.paymentError) {
    const lastOrderData = YALocalStorageSvc.getSetOrderDetails();
    self.payData.total = lastOrderData.payfortAmount;
    window.PubSub.publish('CreateOrderSuccess', {
      isCreateOrderSuccess: true,
      resCode: lastOrderData.reservationCode,
      orderId: lastOrderData.orderID,
      total: lastOrderData.payfortAmount,
    });
    return false;
  }
  return true;
};

export const saveInvoiceDetails = (self) => {
  localStorage.setItem(
    UIConfig.invoiceDetail,
    JSON.stringify({
      invoiceTemplateId: self.invoiceTemplateId,
      paymentMode: self.state.currentOption,
    }),
  );
};

/**
 * redirectToThankyou Page function redirects to thankyou page once payment is successful after order is closed
 */
export const redirectToThankyou = (self, status, orderID, mode) => {
  const userAgent = getUserAgent();
  const tenantID = getLoggedInUser().tenantID;
  if (tenantID === 'ALLB2B') {
    canUseDOM() && localStorage.removeItem('isOrderHistoryPayment');
  }
  if (!userAgent) {
    if (tenantID !== 'ALLB2B') {
      window.location.href = self.thankYouPageUrl + '?status=' + status + '&oId=' + btoa(orderID) + '&ref=' + mode;
    } else {
      window.location.href =
        self.thankYouPageUrl +
        '?status=' +
        status +
        '&oId=' +
        encodeURIComponent(orderID) +
        '&ref=' +
        mode +
        (self.notEncrypted ? '&notEncrypted=true' : '');
    }
  } else {
    window.JSbridge.nativeCallback(
      massageMobileData({
        status: status,
        orderID: orderID,
        ref: mode,
      }),
    );
  }
};

/**
 *  onPayment function is used to execute different payment methods
 *  when user clicks on Proceed to Payment button depending on what payment option is seleted
 *
 */
export const onPayment = (self, e) => {
  window.PubSub.publish(UIConfig.events.TOGGLE_GUEST_CHECKOUT);
  if (self.isB2B && self.partnerReference && !self.B2B_isCreditSettlement) {
    window.PubSub.subscribe('input_filled', (item, obj) => {
      self.setState({ B2BPaymentEnable: obj.isEnabled });
    });
  }
  if (!self.state.proceedToPay || !self.state.isTncChecked) {
    return;
  }
  const currOption = self.state.currentOption;
  if (UIConfig.payfort.payfortTypes.find((item) => item === currOption)) {
    self.setPayfortData();
  } else if (currOption === UIConfig.paymentOption.aliPay) {
    self.setRedirectPaymentData();
  }

  self.saveInvoiceDetails();
};

/**
 * Add tab index -1 to skip the disabled element of payment paget T&C text
 */
export const toggleTabIndexOnSelectedElm = (self, selector) => {
  let anchoreElm = getArrayOfElement(selector);
  anchoreElm.forEach((item, index) => {
    item.setAttribute('tabIndex', self.state.tabIndex);
  });
};

/**
 * Desc: Annual pass login case
 */
export const annualPassLoginCase = (self) => {
  let cart = self.state.cart;
  let productType =
    cart &&
    cart.items.map(function(currentVal, index, arr) {
      return currentVal.productType;
    });
  return matchInArray('AnnualPass', productType);
};

export const resetPaymentError = (self) => {
  const paymentError = parseQueryString(UIConfig.querStringParams.payfort.paymentError);
  if ((canUseDOM() && paymentError) || self.state.paymentError) {
    window.history.replaceState(null, null, window.location.pathname);
    self.setState({ paymentError: '' });
  }
};

export const showTnCError = (self, showError) => {
  self.setState({ hasTnCError: showError }, () => {
    window.PubSub.publish('tncError', {
      isCheckBoxDisable: showError,
    });
  });
};

/**
  * handleKeyDown function works on keyboard events of facet filter
    calls a callback function with selected filter object.
  * @param    {e} function accepts event object
  * @return   {[Void]} function does not return anything.
  */
export const handleKeyDown = (self, e, selectedValue) => {
  if (e.keyCode === 13) {
    self.onOptionChangeHandler(e, selectedValue);
  }
};

export const onLoginClickAnalytics = (self) => {
  GTMData.getTenantId() === UIConfig.ymcB2CTenant &&
    !isEmpty(self.state.cart.items) &&
    GTMData.push('checkout', {
      products: self.state.cart.items,
      step: UIConfig.gtmStepThree,
      subTotal: self.state.cart.grossPrice,
    });
};
