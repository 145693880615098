/*
 * Recaptcha.js
 * This file contains code for Captcha
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { logComponentRenderingError } from '../../../../common/logger';
import ReCAPTCHA from 'react-google-recaptcha';
import { canUseDOM, getLocalStorageByKey, resolvePath } from '../../../../common/utility';
import FieldErrorComponent from '../field-error';
import { validateJSSField } from '../rules-validator';
import UIConfig from '../../../../common/UIConfig';

/**
 * ReCaptcha is a component which will be rendered by Form component, it expects its props from Form component
 * @param    {object}    data
 */
const Recaptcha = (props) => {
  const { tracker, field, errors } = props;
  const { siteKey, cssClass } = field.model;
  const valueFieldName = field.valueField.name;
  const [errorMsg, setErrorMsg] = useState(errors);
  const mainObj = canUseDOM() ? getLocalStorageByKey('mainObj') : {};
  const addSiteKey =
    (mainObj && mainObj.additionalProperty && resolvePath(mainObj.additionalProperty, 'siteKey', '')) || '';
  useEffect(() => {
    window.PubSub.subscribe(UIConfig.jssForms.events.formSubmissionApiError, (msg, data) => {
      if (data.apiError) {
        window.grecaptcha.reset();
        props.onChange(valueFieldName, '', true, []);
        tracker.onBlurField(field, '', []);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  const verifyCaptcha = (res) => {
    if (res) {
      let valid = true,
        errorMessages = [];
      const errorMessagesObj = validateJSSField(field, res);
      const message = errorMessagesObj[valueFieldName];
      if (message && message.message) {
        valid = false;
        errorMessages.push(message.message);
      }
      setErrorMsg(errorMessages);
      props.onChange(valueFieldName, res, valid, errorMessages);
      tracker.onBlurField(field, res, errorMessages);
    }
  };
  const expireCaptcha = () => {
    let valid = true,
      errorMessages = [];
    const errorMessagesObj = validateJSSField(field, '');
    const message = errorMessagesObj[valueFieldName];
    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }
    setErrorMsg(errorMessages);
    props.onChange(valueFieldName, '', valid, errorMessages);
    tracker.onBlurField(field, '', errorMessages);
  };
  try {
    return (
      <>
        <div className={`form-element ${cssClass}`}>
          <ReCAPTCHA
            sitekey={addSiteKey ? addSiteKey : siteKey}
            render="explicit"
            onChange={(res) => verifyCaptcha(res)}
            onExpired={expireCaptcha}
          />
          <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
        </div>
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Recaptcha');
  }
};

Recaptcha.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }),
};

export default Recaptcha;
