/*
 * my-cart-componet.js
 * @author    SapientNitro
 * @licensor  Miral
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { getB2CProductDetails } from '../../../../common/coveo-api';
import { Logging } from '../../../../common/logger';
import { CartService, CurrencyConverterService, AnnualPassData } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  createDeepCopy,
  createMappedData,
  detectMobile,
  getErrorMap,
  getErrorObj,
  getFallbackLanguage,
  getLoggedInUser,
  getUserAgent,
  hasHeaderComponent,
  isDisabledProduct,
  parseQueryString,
  setLocalStorage,
  getLocalStorageByKey,
  removePromoCode,
  isLoggedInUser,
  getSessionStorage,
  setSessionStorage,
  deepCopyArrayOfObjects,
  isYasArenaJourney,
  setAnonymousCart,
  resolvePath,
  getMembershipDetails,
  canUseDOM,
  getPartnerCodeData,
  checkTenant,
  removeLoaderOverlay,
  checkParksTenants,
  isMatchTenant,
  getAnonymousCart,
  checkIfParks,
  isParksExpressCheckoutTenant,
} from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import Overlay from '../../../presentation/base/overlay/overlay-component';
import PayfortUtility from '../../payment/payfort/payfort-utility';
import CartActions from '../cart/cart-actions';
import Cart from '../cart/cart-component';
import { CartModel } from '../cart/model/cart-model';
import { CartModelProduct } from '../cart/model/cart-model-product';
import GTMData from '../gtm-data';
import { YALocalStorageSvc } from './yasarena-cart-management-service';
import RecommendationActions from '../../../container/b2c-purchase-journey/recommendation-rules/recommendation-rules-component';
import YaAddOnsService from '../event-performance-list/yas-arena-add-ons-service';
import YaAvailabiltyService from '../event-performance-list/add-ons-availability-service';

import * as helper from './helper';

/**
 * MyCart Class ( which extends the React.Component) contains the
 * functionality to show cart summary
 * Depends on following component DynamicContent
 * @return   {[Object]} Return a render function which contains the JSX of the component.
 */
export default class MyCart extends React.Component {
  constructor(props) {
    super(props);
    this.cartObj = props.data;
    this.cartViewLabels = props.data.cart;
    this.isParks = checkIfParks();
    // isCartPaymentView is used to run 2 step journey
    this.isCartPaymentView =
      this.cartViewLabels.cartType === UIConfig.b2c.purchaseJourney.cartVariant.cartPaymentView ||
      this.cartViewLabels.cartType === UIConfig.b2c.purchaseJourney.cartVariant.yasArenaCart;

    this.yasArenaCartView = this.cartViewLabels.cartType === UIConfig.b2c.purchaseJourney.cartVariant.yasArenaCart;
    this.isYaNonSeatedJourney = YALocalStorageSvc.hadSelectedNonSeatedEvent();

    this.CartActionObj = new CartActions(this.setCartDefaults(props));
    this.coveoMappingList = props.data.coveoMappingList;
    this.emptyCartModel = createDeepCopy(CartModel);
    this.cartModelWithProduct = createDeepCopy(CartModelProduct);
    this.serviceProps = this.props.data.services;
    this.selectedCurrency = '';
    this.isMobile = detectMobile();
    this.productList = [];
    this.gridview = this.cartViewLabels.cartType.indexOf('gridview') !== -1;
    this.isRenewAnnualPass = props.data.cart.isRenewAnnualPass;
    this.cartLoaded = false;
    this.cartSummaryExpandedView = this.cartViewLabels.showCartSummaryExpandedView;
    this.loading = false;
    this.isBeginCheckoutLoaded = false;
    this.state = {
      cartData: this.setDefaultCartState(props.data.isInlineEditMode),
      errorObj: null,
      bErrObj: null,
      convertedCurrency: null,
      cartEmpty: false,
      showExpandedCart: false,
      focusPromoWithExpandedCart: false,
      isAnnupassLoggedInUser: false,
      yasArenaOrderId: '',
      apiError: '',
      disableOnSeatedPaymentError: false,
      availabilityProductMapping: {},
      isUserLoggedIn: false,
      maxQuantityExceeded: false,
      membershipDetails: '',
    };
    this.isRecommendationControlled = this.props.data.cart && this.props.data.cart.isRecommendationControlled;
    this.isRecommendationAllowedForAll = this.props.data.cart && this.props.data.cart.isRecommendationAllowedForAll;
    this.productPackageTypeMapping = this.props.data.cart && this.props.data.cart.productPackageTypeMapping;
    this.userAgent = getUserAgent() || !hasHeaderComponent();
    if (this.isRecommendationControlled) {
      this.recommendationActions = new RecommendationActions({
        productPackageTypeMapping: this.productPackageTypeMapping,
      });
    }
    if (this.isCartPaymentView) setLocalStorage('isCartSummaryExpanded', false);

    this._yaCartSvc = null;
    this.corpCouponRemoved = false;
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
    this.showMiniCart = this.cartObj.isHeaderMiniCartEnabled;
    this.isHeaderMiniCartEnabledOnBookingToPaymentPages = this.cartObj.isHeaderMiniCartEnabledOnBookingToPaymentPages;

    this.CartDataSetTime = null;
  }

  stickyMyCart = () => helper.stickyMyCart(this);

  componentWillUnmount() {
    clearTimeout(this.CartDataSetTime);
  }

  componentDidMount() {
    const maxCartQuantity = this.props.data.miniCart.maxCartQuantity;
    this.CartDataSetTime = setTimeout(() => {
      window.PubSub.publish('showHeader', true);
      window.PubSub.publish(UIConfig.events.MAX_CART_QUANTITY, maxCartQuantity);
      if (this.cartObj.isHeaderMiniCartEnabled) {
        window.PubSub.publish('myCartMockObject', this.cartObj);
      } else {
        return;
      }
    }, 300);

    /**
     *  when tenantId is YAB2C i.e Yas Arena. First we need to pull details of cart created on viva page
     *  then create anonymous cart and continue with normal flow.
     */
    window.PubSub.subscribe('membershipDetailsFromScv', (msg, data) => {
      this.setState({ membershipDetails: data });
    });
    if (
      this.isSwad &&
      canUseDOM() &&
      (!this.cartObj.isHeaderMiniCartEnabled || this.cartObj.isHeaderMiniCartEnabledOnBookingToPaymentPages)
    ) {
      !this.isMobile && window.addEventListener('scroll', this.stickyMyCart);
    }
    if (isYasArenaJourney()) {
      if (this.isYaNonSeatedJourney) {
        const addOnParams = {
          services: this.props.data.services,
          coveoMappingList: this.coveoMappingList,
        };

        this._yaAddOnSvc = new YaAddOnsService(addOnParams);
        this._availabilitySvc = new YaAvailabiltyService(addOnParams);
      }

      this.CartActionObj.initYaJourneyActions({
        propsData: this.props.data,
        addToCart: this.updateNonSeatedEvent,
        isNonSeatedJourney: this.isYaNonSeatedJourney,
        isPaymentPage: true,
        getCartItems: async () => {
          return await this.productList;
        },
      }).then((response) => {
        this._yaCartSvc = response.actionsObj;
        this.pageSetup();
      });
    } else {
      this.pageSetup();
    }
    window.PubSub.subscribe('get_cart_with_shipping_charges', (msg) => {
      this.renderCartUI();
    });

    setTimeout(() => {
      window.PubSub.publish('hideCartTimerOnParticularPage');
    }, 1000);

    window.PubSub.subscribe(UIConfig.events.GET_RECIPIENT_EXTRA_PARAMS, (msg, data) => {
      /* Validate and display error if both ticket and gift voucher available in cart */
      const isExist = this.checkIfGiftVoucherExist(data.cartData, data.extraParams);
      let giftVoucherError = null;
      if (isExist) {
        giftVoucherError = getErrorObj(UIConfig.errorCodes.giftVoucherError, this.props.data.cart.businessErrors);
        this.setState({
          errorObj: giftVoucherError,
        });
      }
    });

    window.PubSub.subscribe(UIConfig.b2c.purchaseJourney.ymcMapping.pubSubKey.seatedPaymentError, () => {
      this.setState({ disableOnSeatedPaymentError: true });
    });
    this.setState({
      isUserLoggedIn: isLoggedInUser(),
    });
    window.PubSub.subscribe('paypalLoaded', (msg, data) => {
      window.PubSub.publish(UIConfig.events.ONCARTUPDATE, {
        cart: this.state.cartData.cart,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isYaNonSeatedJourney) {
      const { items } = this.state.cartData.cart;
      let cartItems = Object.values(items);
      const prevCartItems = Object.values(prevState.cartData.cart.items);

      if (cartItems.length > 0) {
        //check if addOn In Cart
        //check if main product is updated
        const isAddOn = this._yaAddOnSvc.checkIfAddOnsInCart(cartItems);
        const currentQuantity = this._availabilitySvc.getMainProductQuantity(cartItems);
        const prevQuantity = prevCartItems.length > 0 && this._availabilitySvc.getMainProductQuantity(prevCartItems);
        const isInitialLoad = !this._availabilitySvc.coveoMappedData;

        //Initial load implementation
        if (isAddOn && currentQuantity !== prevQuantity && isInitialLoad) {
          this._yaAddOnSvc
            .getRecommendedProducts({
              cartItems: cartItems,
            })
            .then((response) => {
              return this._availabilitySvc.getAvailability({
                coveoMappedData: response.mappedProducts,
                cartItems,
              });
            })
            .then((response) => {
              const updatedCartProperties = this._availabilitySvc.yaGetUpdatedProductList({
                productList: [...cartItems],
                cartActionProps: this.setCartDefaults(this.props),
                updatedAvailability: response.availability,
                returnGroupedData: false,
              });

              const { productList } = updatedCartProperties;
              this.CartActionObj.updateCart(productList, []).then((response) => {
                this.doCheckbasketAfterCartUpdate();
              });
              //updating the product list with the updated one
              this.productList = deepCopyArrayOfObjects(productList);
            });
        }

        //Implementation when updated the cart in non seated journey
        if (isAddOn && currentQuantity !== prevQuantity && !isInitialLoad) {
          const updatedAvailability = this._availabilitySvc.getAvailabiltyProductMapping({
            coveoMappedData: this._availabilitySvc.coveoMappedData,
            availabilityFromApi: this._availabilitySvc.availabilityFromApi,
            performanceQuantity: currentQuantity,
          });

          const updatedCartProperties = this._availabilitySvc.yaGetUpdatedProductList({
            productList: [...cartItems],
            cartActionProps: this.setCartDefaults(this.props),
            updatedAvailability,
            returnGroupedData: false,
          });

          const { productList } = updatedCartProperties;

          this.CartActionObj.updateCart(productList, []).then((response) => {
            this.doCheckbasketAfterCartUpdate();
          });
          //updating the product list with the updated one
          this.productList = deepCopyArrayOfObjects(productList);
        }
      }
    }
  }

  runBeginCheckout = (cartData) => {
    const paymentError = new URLSearchParams(window.location.search).get('payment_error');
    let enableSessionStorage = getSessionStorage('enableBeginCheckout');
    let loggedInStatus = localStorage.getItem(UIConfig.localStoreKeys.loggedInCount);
    if (checkParksTenants() && this.props.data.showInlineCart) {
      window.addEventListener('message', (e) => {
        if (e.data.message === 'Add loader.') {
          localStorage.setItem(UIConfig.localStoreKeys.loggedInCount, 1);
        }
      });
      if (loggedInStatus === '1') {
        localStorage.setItem(UIConfig.localStoreKeys.loggedInCount, 2);
      } else {
        if (!paymentError || enableSessionStorage) {
          GTMData.push('beginCheckout', {
            step: UIConfig.gtmStepFive,
            products: cartData.items,
            subTotal: cartData.grossPrice,
            promotions: cartData.promotions,
          });
          this.isBeginCheckoutLoaded = true;

          if (!paymentError && enableSessionStorage) {
            sessionStorage.removeItem('enableBeginCheckout');
          }
        } else if (paymentError) {
          setSessionStorage('enableBeginCheckout', true);
        }
      }
    }
  };

  pageSetup = () => {
    this.cartLoaded = false;
    const uidEmail = parseQueryString('uid', false);
    if (uidEmail) {
      //some cart type
      CartService.getGuestCart('GuestDetails', this.props.data.services.getCart.url, uidEmail)
        .then((res) => {
          let newAnonymousCart = res.data;

          setAnonymousCart(newAnonymousCart);
          this.loadCartOnPageLoad();
          this.setState({
            showExpandedCart:
              (window.location.hash.indexOf('#expanded') === 0 &&
                !(isParksExpressCheckoutTenant() && this.isRenewAnnualPass && detectMobile())) ||
              window.location.hash.indexOf('#expandedwithpromo') === 0,
            focusPromoWithExpandedCart: window.location.hash.indexOf('#expandedwithpromo') === 0,
          });
        })
        .catch((response) => {
          if (this.isCartEmpty(response)) {
            this.cartLoaded = true;
            this.renderEmptyCart();
          } else {
            this.setState({
              errorObj: getErrorMap(
                this.serviceProps.getCart.url,
                this.serviceProps.getCart.errors,
                false,
                response.data.error,
                this.state.errorObj,
              ),
            });
          }
        });
    } else if (this.isRenewAnnualPass) {
      this.setComponentData();
    } else {
      if (this.isParks || this.isSwad) {
        if (this.cartObj.isHeaderMiniCartEnabled && this.cartObj.isHeaderMiniCartEnabledOnBookingToPaymentPages) {
          this.loadCartOnPageLoad();
        }
      } else {
        this.loadCartOnPageLoad();
      }
    }
    if (this.isCartPaymentView) {
      this.updateCartDataOnSaveAnnualPass();
    }
  };

  getuserDetails = (cartData) => {
    let payload = { accounts: [] };
    let guestDetails = [];
    cartData.cart.items.forEach((item) => {
      item.guestDetails.forEach((guestDetail) => {
        guestDetails.push(guestDetail);
      });
    });
    guestDetails.forEach((guestDetail) => {
      payload.accounts.push({ id: guestDetail.accountId });
    });
    GTMData.push('progressFlow', {});
    const config = {
      url: `${this.serviceProps.ReadAccountDetails.url}?tenantid=${this.getTenantId()}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      preLoader: false,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
      data: payload,
    };
    AnnualPassData.annualPassDataAPI(config)
      .then((response) => {
        const fetchedGuestDetails = response.data.guestDetails;
        cartData.cart.items.map((item) => {
          item.guestDetails.map((guestDetail) => {
            fetchedGuestDetails.forEach((guest) => {
              if (guest.accountId === guestDetail.accountId) {
                guestDetail.photo = guest.photo;
              }
            });
            return guestDetail;
          });
          return item;
        });
        this.updateCartState({
          cartResponse: cartData,
        });
        window.PubSub.publish(UIConfig.events.ENABLE_PAYMENT_MYCART, cartData);
        this.setState({
          apiError: '',
        });
      })
      .catch((err) => {
        if (err.error.code) {
          let apiError = this.state.data.services.ReadAccountDetails.errors[err.error.code];
          if (apiError) {
            this.setState({
              apiError,
            });
          }
        }
      });
  };

  setComponentData = () => {
    this.cartLoaded = true;
    const cartData = JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart));
    if (cartData) {
      this.getuserDetails(cartData);
      this.runBeginCheckout(cartData.cart);
      setTimeout(() => {
        this.updateCartState({
          cartResponse: cartData,
        });
        window.PubSub.publish(UIConfig.events.ENABLE_PAYMENT_MYCART, cartData);
      }, 1000);
    }
  };

  /**
   * setCartDefaults function to set default values for cart actions
   * @param    {[Object]} props [properties from server].
   * @return   {[Object]} configuration object
   */
  setCartDefaults = (props) => {
    const services = props.data.services;
    if (
      this.isParks &&
      this.cartObj.isHeaderMiniCartEnabled &&
      !this.cartObj.isHeaderMiniCartEnabledOnBookingToPaymentPages
    ) {
      delete services.getCartMashupAnonymous;
    }
    return {
      serviceUrl: services,
      moduleName: 'My Cart',
      tenantId: this.getTenantId(),
      is2StepPaymentJourney: this.isCartPaymentView,
      isSeatedTypeJourney: props.data.cart.isSeatedTypeJourney,
      coveoMappingList: props.data.coveoMappingList,
      isMyCartPage: true,
      isYaNonSeatedJourney: this.isYaNonSeatedJourney,
    };
  };

  getTenantId = () => {
    const userData = getLoggedInUser();
    return userData.tenantID;
  };
  setPartnerSegmentData = () => {
    const localstoragemembership = getMembershipDetails('membershipData') || this.state.membershipDetails;
    const findMembership = getPartnerCodeData(this.cartViewLabels.memberDiscounts, this.state.membershipDetails);
    this.preappliedCouponCode = localstoragemembership && localstoragemembership.couponCode[0];
    this.currentDate = moment().format(UIConfig.calendar.dateFormat);
    this.partnersegProductIds =
      findMembership && findMembership.products && findMembership.products.length > 0 ? findMembership.products : [];
  };

  isInvalidCorporateCode = () => {
    const localstoragemembership = getMembershipDetails();

    if (!localstoragemembership) return false;
    const membershipData = localstoragemembership.membershipData;

    const enabledUserDetail = localstoragemembership.membershipDetails.filter(
      (detail) =>
        detail.segmentType === UIConfig.CORPORATE && detail.status !== UIConfig.profile.CORP_USER_TYPE.ENABLED,
    );

    //deleted and disabled status of corporate user and coupon empty
    if (
      localstoragemembership.isMembershipDetails &&
      enabledUserDetail &&
      enabledUserDetail.length &&
      membershipData &&
      membershipData?.couponCode[0].length < 1
    ) {
      return true;
    }
    return false;
  };

  removeInvalidCorpCoupon = (cartItem) => {
    this.removeCoupanCodeFromCard(cartItem);
    this.loadCartOnPageLoad();

    this.corpCouponRemoved = true;
  };

  /**
   * loadCartOnPageLoad function to set local store data in cart api
   * @return   {[Object]}  returns updated cart data.
   */
  loadCartOnPageLoad = () => {
    sessionStorage.setItem(UIConfig.sessionStorageKeys.MY_CARD_SUCCESS, false);
    sessionStorage.setItem(UIConfig.sessionStorageKeys.ANNUAL_PASS_FORM_SUCCESS, true);
    sessionStorage.setItem(UIConfig.sessionStorageKeys.GUEST_DETAIL_SUCCESS, true);
    this.CartActionObj.releaseLockOrderService(null, false).then((response) => {
      this.CartActionObj.getCartOnPageLoad(true)
        .then((response) => {
          this.cartLoaded = true;
          if (response.data && response.data.cart) {
            isLoggedInUser() && this.setPartnerSegmentData();
            const cart =
              isLoggedInUser() &&
              this.preappliedCouponCode &&
              response.data.cart.items &&
              response.data.cart.items.length > 0
                ? this.checkPartnerSegmentProduct(response.data.cart)
                : response.data.cart;

            const isCouponInvalid = cart.couponCode && !(Array.isArray(cart.promotions) && cart.promotions.length > 0);
            const isInvalidCorporateCoupon = this.isInvalidCorporateCode();

            !this.preappliedCouponCode && !this.isBeginCheckoutLoaded && this.runBeginCheckout(response.data.cart);
            if (isCouponInvalid) {
              //Remove coupan code if coupan is not applied and present on cart
              this.removeCoupanCodeFromCard(cart);
              this.loadCartOnPageLoad();
            } else if (isInvalidCorporateCoupon && !this.corpCouponRemoved) {
              this.removeInvalidCorpCoupon(cart);
            } else {
              window.PubSub.publish('payment_page_b2c_cart', {
                cart: cart,
              });

              this.productList = deepCopyArrayOfObjects(cart.items);
              cart.packages?.length &&
                cart.packages.forEach((pkg) => {
                  this.productList.push(...pkg.items);
                });
              const cartResp = this.wrapCartResponse(cart);
              const isParks = checkParksTenants() && !isMatchTenant(UIConfig.SWADB2C);
              this._updateCoveoProduct(cartResp.cart, isParks).then((cartResp) => {
                const errObj = cartResp.cart.disabled
                  ? getErrorObj(UIConfig.errorCodes.disabledCart, this.props.data.cart.businessErrors)
                  : null;

                if (GTMData.getTenantId() === UIConfig.yasArenaB2CTenant.toLocaleLowerCase()) {
                  const { date } = YALocalStorageSvc.getSetPerformance();
                  cartResp.cart.items[0]['timeSlot'] = cartResp.cart.timeSlot;
                  cartResp.cart.items[0]['dateOfEvent'] = date ? date.substr(0, 10) : '';
                  cartResp.cart.items[0]['eventId'] = YALocalStorageSvc.getSetEventDetails().eventId;
                  cartResp.cart.items[0]['isYAPaymentPageLoad'] = true;
                }

                // Disable event when miniCart is visible
                if (
                  !this.showMiniCart ||
                  (this.showMiniCart &&
                    this.isHeaderMiniCartEnabledOnBookingToPaymentPages &&
                    this.props.data.cart.cartType === UIConfig.b2c.purchaseJourney.cartVariant.cartPaymentView)
                ) {
                  GTMData.push('checkout', {
                    products: cartResp.cart.items,
                    step: this.isSwad ? UIConfig.gtmStepOne : UIConfig.gtmStepTwo,
                    subTotal: cartResp.cart.grossPrice,
                  });
                }
                if (GTMData.getTenantId() === UIConfig.ymcB2CTenant && this.state.isUserLoggedIn) {
                  GTMData.push('checkout ', {
                    products: cartResp.cart.items,
                    step: UIConfig.gtmStepThree,
                    subTotal: cartResp.cart.grossPrice,
                  });
                }

                sessionStorage.setItem(UIConfig.sessionStorageKeys.MY_CARD_SUCCESS, !cartResp.cart.disabled);
                this.updateCartState({
                  cartResponse: cartResp,
                  event: UIConfig.events.CHECK_PAYMENT_ON_OFF,
                  errorObj: errObj,
                  // isPartnerSegment: this.preappliedCouponCode ? true : false,
                });
                window.PubSub.publish(UIConfig.events.ENABLE_PAYMENT_MYCART, cartResp);

                if (!this.isCartPaymentView) window.PubSub.publish(UIConfig.events.TIGGER_ANNUAL_PASS);
              });

              // if its guestUser(in localhost) and "cartsummarygridview"  call updatecart
              const guestUser = JSON.parse(localStorage.getItem('guestUser'));
              const anonymousCart = getAnonymousCart();
              const isTicketUpgradeCart = anonymousCart?.cart?.isTicketUpgradeCart || false;
              const serviceURL = this.props.data.services.updateCart.url + '?uid=' + guestUser.email;
              if (
                guestUser &&
                (this.props.data.cart.cartType === UIConfig?.parksTicketUpgrade?.cartsummarygridview ||
                  this.isCartPaymentView) &&
                !isTicketUpgradeCart
              ) {
                CartService.updateGuestCart(response.data, 'myCart', serviceURL)
                  .then((res) => {
                    Logging(res, 'myCart', true, 'Cart Details posted');
                  })
                  .catch((response) => {
                    this.setState({
                      errorObj: getErrorMap(
                        this.serviceProps.updateCart.url,
                        this.serviceProps.updateCart.errors,
                        false,
                        response.error,
                        this.state.errorObj,
                      ),
                    });
                  });
              }
            }
          }
          window.PubSub.publish(UIConfig.cartErrorCodesForPayment, {
            errorCode: [],
          });
        })
        .catch((apiData) => {
          isLoggedInUser() && this.setPartnerSegmentData();
          this.cartLoaded = true;
          this.handleCheckBasketError(apiData);
          if (apiData.cart && apiData.cart.items) {
            window.PubSub.publish('payment_page_b2c_cart', {
              cart: apiData.cart,
            });
          }
        });
    });
  };

  checkIfGiftVoucherExist = (cartData, extraParams) => {
    const items = resolvePath(cartData.cart, 'items', []);
    let isVoucher = false;
    let isTicket = false;

    /* Don't show form when results are empty or coveo mapping is empty */
    if (!items || !Array.isArray(items)) {
      return;
    }

    items.forEach((item) => {
      if (item) {
        /* Get the avaiable category list */
        const category = !this.isSwad ? item.productType : item.productName.toLowerCase();
        /* Check if category is an array */
        const giftVoucher = !this.isSwad
          ? resolvePath(extraParams, 'giftVoucher', '')
          : resolvePath(extraParams, 'giftVoucher', '').toLowerCase();
        if (category && category.includes(giftVoucher)) {
          // Change this hard coded value to data && data.extraParams.giftVoucher
          isVoucher = true;
        } else {
          isTicket = true;
        }
      }
    });
    return isVoucher && isTicket;
  };

  removeCoupanCodeFromCard = (cartData) => {
    const extraCartParams = { promotions: null, couponCode: '' };
    removePromoCode();
    this.CartActionObj.updateCart(cartData.items, [], '', '', extraCartParams);
  };

  checkPartnerSegmentProduct = (cartData) => {
    const enrichCartProducts = cartData.items.map((data) => {
      return this.partnersegProductIds.includes(data.productId)
        ? { ...data, isPartnerSegment: true }
        : { ...data, isPartnerSegment: false };
    });
    const updatedCartData = { ...cartData, items: enrichCartProducts };
    const applypartnerSegmentPromo =
      canUseDOM() && localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo)
        ? JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo))
        : true;
    const shouldApplyPromocode = cartData.items.some(
      (item) => this.partnersegProductIds.includes(item.productId) && applypartnerSegmentPromo,
    );
    setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo, JSON.stringify(shouldApplyPromocode));
    return updatedCartData;
  };

  getApplePayData = (cartData) => helper.getApplePayData(this, cartData);

  getGPayData = (cartData) => helper.getGPayData(this, cartData);

  /**
   * updateCartState function update cart state and trigger event optinally
   * @param    {[Object]} cartResponse [cart response to be updated in state].
   * @param    {[event]} event [any event that needs to be triggered after set state].
   * @return   {[Object]}  returns updated cart data.
   */
  updateCartState = ({
    cartResponse,
    event = null,
    errorObj = null,
    bErrObj = null,
    cartEmpty = false,
    isPartnerSegment = false,
  }) => {
    this.setState(
      {
        cartData: cartResponse,
        errorObj: errorObj,
        bErrObj: bErrObj,
        cartEmpty: cartEmpty,
        showExpandedCart:
          (window.location.hash.indexOf('#expanded') === 0 &&
            !(isParksExpressCheckoutTenant() && this.isRenewAnnualPass && detectMobile())) ||
          window.location.hash.indexOf('#expandedwithpromo') === 0,
        focusPromoWithExpandedCart: window.location.hash.indexOf('#expandedwithpromo') === 0,
      },
      () => {
        if (event) {
          window.PubSub.publish(event, {
            cartData: this.state.cartData,
          });
        }
      },
    );
    window.PubSub.publish(UIConfig.events.MINICARTUPDATED, cartEmpty);
    window.PubSub.publish(UIConfig.events.STEP_2_STICKY_CTA_WITH_COOKIES_EXIST, cartEmpty ? true : false);
    if (this.isCartPaymentView) {
      let enablePaymentButton = true;
      const cartItems = this.getGroupedCart(cartResponse).cart;
      for (const key in cartItems.items) {
        if (cartItems.items[key].invalidQuantity) {
          enablePaymentButton = false;
        }
        const cartError = errorObj && errorObj[this.serviceProps.getCartMashup.url];
        this.renderPartnerSegmentOverlay(cartError, cartResponse, isPartnerSegment);

        if (key.search('AnnualPass') > -1) {
          this.setState({
            isAnnupassLoggedInUser: true,
          });
        }
      }
      if (this.state.isAnnupassLoggedInUser || this.isRenewAnnualPass) {
        window.PubSub.publish(UIConfig.events.TIGGER_ANNUAL_PASS, { cart: cartResponse.cart });
      }
      window.PubSub.publish(UIConfig.events.ONCARTUPDATE, {
        cart: cartResponse.cart,
        enablePaymentButton,
        applePayData: this.getApplePayData(cartResponse.cart),
        GPayData: this.getGPayData(cartResponse.cart),
      });
      window.PubSub.publish(UIConfig.events.ONCARTUPDATEPOSTPAY, {
        cart: cartResponse.cart,
      });
      window.PubSub.publish(UIConfig.events.ONCARTUPDATETABBY, {
        cart: cartResponse.cart,
      });
    }
  };
  renderPartnerSegmentOverlay = (cartError, cartResponse, isPartnerSegment) => {
    const env_variables_ele = document.getElementById('__ENV_VARIABLES__');
    let isFutureDateEnabled = false;
    if (env_variables_ele) {
      isFutureDateEnabled = JSON.parse(env_variables_ele.innerHTML)?.partnerSegFutureEnabled === 'true';
    }
    if (isLoggedInUser() && this.preappliedCouponCode && isPartnerSegment) {
      const filterProductsFromCart = cartResponse.cart.items.filter((item) =>
        this.partnersegProductIds.includes(item.productId),
      );
      const shouldOpenFutureDateOverlay =
        filterProductsFromCart &&
        filterProductsFromCart.length > 0 &&
        filterProductsFromCart.every((item) => item.fromDate !== this.currentDate);
      if (cartError && (cartError.code === '4104' || cartError.code === '4003')) {
        window.PubSub.publish(UIConfig.events.MAX_QUANTITY_GEN_ADM, {
          quantityExceededError: true,
          shouldOpenFutureDateOverlay: false,
        });
      } else {
        window.PubSub.publish(UIConfig.events.MAX_QUANTITY_GEN_ADM, {
          quantityExceededError: false,
          shouldOpenFutureDateOverlay: isFutureDateEnabled ? false : shouldOpenFutureDateOverlay,
        });
      }
    }
  };

  /**
   * renderCartUI function to do check basket and render cart
   * @return   {[Object]} returns html .
   */
  renderCartUI = (errorData = null) => {
    this.CartActionObj.getCartMashup('grouped')
      .then((response) => {
        if (response.data && response.data.cart) {
          if (checkTenant(UIConfig.iamMapping.ymc)) {
            const pkgList = response.data.cart.packages;
            if (pkgList?.length) {
              response.data.cart.promotions = this.CartActionObj.updateSelectedPromos(response.data.cart);
              response.data.cart.packages = this.CartActionObj.getValidPkgDiscount(pkgList);
            }
          }
          this.updateCartState({
            cartResponse: this.wrapCartResponse(response.data.cart),
            errorObj: errorData,
            isPartnerSegment: this.preappliedCouponCode ? true : false,
          });
          if (this.isYaNonSeatedJourney) {
            this.productList = deepCopyArrayOfObjects(response.data.cart.items);
          }
          if (isYasArenaJourney()) {
            this.loading = false;
          }
          this.preappliedCouponCode && !this.isBeginCheckoutLoaded && this.runBeginCheckout(response.data.cart);
        }
        window.PubSub.publish(UIConfig.cartErrorCodesForPayment, {
          errorCode: [],
        });
      })
      .catch((apiData) => {
        this.handleCheckBasketError(apiData);
      });
  };

  handleCheckBasketError = (apiData) => helper.handleCheckBasketError(this, apiData);

  /**
   * wrapCartResponse function to wrap cart response in a cart object
   * @param    {[Object]} cart [cart object without outer cart object].
   * @return   {[Object]}cart [cart object wrapped in cart object] .
   */
  wrapCartResponse = (cartAPIData) => {
    const wrapperData = {
      cart: cartAPIData,
    };
    return wrapperData;
  };

  _updateCoveoProduct = (cartAPIData, excludeDisabled) => {
    this.coveoDefaultParams = {
      perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
      coveoKeyMap: this.coveoMappingList,
      serviceUrl: this.props.data.services.getCoveoProducts.url,
      fieldsToInclude: Object.values(this.coveoMappingList),
      lang: getFallbackLanguage(),
    };

    const params = [
      {
        key: this.coveoMappingList.productId,
        value: cartAPIData.items.map((item) => item.productId),
      },
    ];

    return new Promise((resolve, reject) => {
      getB2CProductDetails({
        ...this.coveoDefaultParams,
        queryParams: params,
        ...(excludeDisabled && { excludeDisabled: excludeDisabled }),
      }).then((res) => {
        const mappedProductsData = createMappedData(res.results, this.coveoMappingList);
        cartAPIData.items.forEach((item) => {
          item.disabledProduct = isDisabledProduct(
            mappedProductsData.filter((product) => item.productId === product.productId)[0],
          );
          if (item.disabledProduct) cartAPIData.disabled = true;
        });
        const wrapperData = { cart: cartAPIData, results: res.results };
        resolve(wrapperData);
      });
    });
  };

  /**
   * setDefaultCartState function to initialize car t with default values
   * @param    {[Boolean]} isInlineEditMode [flag to set cart structure with or without product data].
   * @return   {[Object]} CartModel [cart structure with default values] .
   */
  setDefaultCartState = (isInlineEditMode) => {
    if (isInlineEditMode) {
      return this.cartModelWithProduct;
    } else {
      return this.emptyCartModel;
    }
  };

  /**
   * getGroupedCartto to return cart structure with grouped products for mini cart
   * @param    {[Object]} cart [flat cart structure].
   * @return   {[Object]} returns grouped cart structure
   */
  getGroupedCart = (cart) => {
    const groupedCart = createDeepCopy(cart);
    groupedCart.cart.items = this.CartActionObj.groupCartProducts(groupedCart.cart.items);

    return groupedCart;
  };

  updateCartProducts = (product, quantity, maxQuantity, extraCartParams) =>
    helper.updateCartProducts(this, product, quantity, maxQuantity, extraCartParams);

  deleteCartProducts = (product, maxQuantity, url) => helper.deleteCartProducts(this, product, maxQuantity, url);

  /**
   *  Arena Tour - Open Event Overlay in update mode.
   *
   */
  handleChangeActionNonSeated = (fromCartAction) => {
    this._yaCartSvc.showNonSeatedOverlay(YALocalStorageSvc.getSetPerformance(), fromCartAction);
  };

  updateNonSeatedEvent = (products, isGrouped, context, availability, extraParams) =>
    helper.updateNonSeatedEvent(this, products, isGrouped, context, availability, extraParams);

  // Do checkbasket when any product or crossSell is updated.
  // And render cart with updated status(error if any)
  doCheckbasketAfterCartUpdate = () => {
    this.renderCartUI(getErrorMap(this.serviceProps.updateCart.url, {}, true));
  };

  /**
   * deleteAndChangeYAEvent function to remove event from cart
   * @param    {[String]} orderId [Event to be deleted].
   * @return   {[void]} setState to render final cart after deletion .
   */
  deleteAndChangeYAEvent = (mode) => {
    this.CartActionObj.clearYACart({ mode, productList: this.productList }).then((response) => {
      this.renderEmptyCart();
    });
  };

  isCartEmpty = (response) => {
    return response && response.error && response.error.code === UIConfig.errorCodes.emptyCart;
  };

  renderEmptyCart = () => {
    this.productList = [];
    removePromoCode();
    this.updateCartState({
      cartResponse: this.emptyCartModel,
      event: null,
      errorObj: null,
      bErrObj: null,
      cartEmpty: true,
    });
  };

  applyCouponCallback = (response, isSuccess) => {
    if (isSuccess) {
      const couponData = {
        promotions: [],
      };
      const promoModel = {
        code: response.coupondetails.coupon.code,
        name: response.coupondetails.coupon.name,
        type: response.coupondetails.coupon.type,
        description: '',
        discount: '',
      };

      couponData.promotions.push(promoModel);
      couponData.couponCode = response.coupondetails.coupon.code;

      this.updateCartProducts('', '', '', couponData);
    }
    if (response.coupondetails && response.coupondetails.coupon) {
      window.PubSub.publish(UIConfig.events.ON_ADDING_PROMOCODE, response.coupondetails.coupon.code);
    }
  };

  removeCouponCallback = (removeClicked = false) => {
    //not allowing to remove promocode if user is HAW member;
    if (!this.preappliedCouponCode || removeClicked) {
      canUseDOM() && localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo);
      setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.applypartnerSegmentPromo, false);
      const couponData = { promotions: null, couponCode: '' };

      this.updateCartProducts('', '', '', couponData);
      removePromoCode();
      if (!isYasArenaJourney() || !this.loading) window.PubSub.publish(UIConfig.events.ON_REMOVING_PROMOCODE);
    } else {
      this.updateCartProducts('', '', '', {});
    }
  };

  currencyCallBack = (e, updateCart) => {
    const defaultCurrency = this.state.cartData.cart.currency;
    if (e.value === defaultCurrency || !e.value) {
      this.selectedCurrency = defaultCurrency;
      if (this.state.convertedCurrency) {
        this.setState({
          convertedCurrency: null,
        });
      }
      return;
    }
    this.selectedCurrency = e.value;
    const currencyData = this.props.data.cart.currencyConverters,
      url = this.props.data.services.getConvertedCurrency.url,
      apiData = {
        access_code: currencyData.accessCode,
        amount: 100,
        converted_currency: e.value.replace(/[^\w\s]/gi, ''),
        currency: defaultCurrency,
        language: 'en',
        merchant_identifier: currencyData.merchantIdentifier,
        service_command: currencyData.serviceCommand,
      };
    apiData.signature = PayfortUtility.getPayFortSignature(apiData, currencyData.merchantPassphrase);
    CurrencyConverterService.getConvertedCurrency(url, apiData, 'my-cart', true, UIConfig.loader.defaultPreLoaderTarget)
      .then((res) => {
        const data = {
          converted_currency: e.value,
          converted_amount: res.data.converted_amount / 100,
        };
        this.setState({
          convertedCurrency: data,
          errObj: getErrorMap('CurrencyConverter', {}, true, {}, this.state.errObj),
        });
      })
      .catch((res) => {
        this.setState({
          convertedCurrency: null,
          errObj: getErrorMap(
            'CurrencyConverter',
            this.props.data.services.getConvertedCurrency,
            false,
            res.error,
            this.state.errObj,
          ),
        });
      });
  };

  showClassType = (data) => {
    return this.CartActionObj.showClassType(data, this.props.data.cart.showClassTypeName);
  };

  showPageTitle = () => {
    const { pageTitle } = this.props.data;
    const { shortDescription } = this.props.data.cart;
    const cartData = this.state.cartData;

    if (this.props.data.pageTitle && this.gridview) {
      return (
        <DynamicContent tagName="h2" attrs={{ className: 'page-heading' }} innerHtml={this.props.data.pageTitle} />
      );
    } else if (this.yasArenaCartView && pageTitle && shortDescription && cartData.cart && cartData.cart.items.length) {
      return (
        <div className="ya-arena-page-title">
          <p className="page-title">{pageTitle}</p>
          <p className="page-description">{shortDescription}</p>
        </div>
      );
    }

    return null;
  };

  showNewSummaryEmptyCheckoutButton = () => {
    return this.isCartPaymentView && this.state.cartEmpty && !this.yasArenaCartView;
  };

  /**
   * Desc: Update cartData state while save the annual pass form
   *
   */
  updateCartDataOnSaveAnnualPass = () => {
    window.PubSub.subscribe(UIConfig.events.UPDATE_MYCART_CARTDATA, (msg, data) => {
      this.setState({
        cartData: data.cart,
      });
    });
  };

  render() {
    try {
      const myCartClasses = classNames('c-my-cart-b2c component', {
        'cart-summary-editable': this.isCartPaymentView,
        'cart-empty-wrapper': this.isCartPaymentView && this.state.cartEmpty,
        'logged-in-cart': this.state.isUserLoggedIn,
      });
      const gridViewClasses = classNames({
        'cart-min-height': !(this.gridview || this.isCartPaymentView),
      });
      const renewalClasses = classNames(
        'c-my-cart-b2c-container',
        {
          'renewal-journey': this.isRenewAnnualPass,
        },
        { 'c-my-cart-b2c-sticky-container': this.isSwad },
      );
      setSessionStorage(UIConfig.localStoreKeys.purchaseJourney.cartData, JSON.stringify(this.state.cartData));

      let eventTitle = YALocalStorageSvc.getEventTitle();

      return (
        <>
          {!((this.isSwad || this.isParks) && !this.props.data?.showInlineCart) && (
            <div data-c-name="MyCart" data-component-name="MyCart" className={myCartClasses} data-c-render="universal">
              <div className={gridViewClasses}>
                <div className={renewalClasses}>
                  {this.showPageTitle()}
                  {this.props.data.isInlineEditMode || this.cartLoaded ? (
                    <Cart
                      data={this.cartViewLabels}
                      isCartPaymentView={this.isCartPaymentView}
                      cartSummaryExpandedView={this.cartSummaryExpandedView}
                      cartData={this.getGroupedCart(this.state.cartData)}
                      deleteProduct={this.deleteCartProducts}
                      updateQuantity={this.updateCartProducts}
                      currencyCallBack={this.currencyCallBack}
                      convertedCurrency={this.state.convertedCurrency}
                      isYaNonSeatedJourney={this.isYaNonSeatedJourney}
                      yasArenaCart={{
                        deleteAndChange: this.deleteAndChangeYAEvent,
                        isYasArenaView: this.yasArenaCartView,
                        eventName: eventTitle || '',
                      }}
                      couponCode={{
                        applyCouponCallback: this.applyCouponCallback,
                        removeCouponCallback: this.removeCouponCallback,
                        services: this.props.data.services,
                      }}
                      errObj={this.state.errorObj}
                      bErrObj={this.state.bErrObj}
                      showClassType={this.showClassType}
                      cartEmpty={this.state.cartEmpty}
                      cart={this.state.cartData.cart}
                      showExpandedCart={this.state.showExpandedCart}
                      focusPromoWithExpandedCart={this.state.focusPromoWithExpandedCart}
                      isRenewAnnualPass={this.isRenewAnnualPass}
                      recommendationActions={this.recommendationActions}
                      isRecommendationControlled={this.isRecommendationControlled}
                      isRecommendationAllowedForAll={this.isRecommendationAllowedForAll}
                      disableOnSeatedPaymentError={this.state.disableOnSeatedPaymentError}
                      handleChangeActionNonSeated={this.handleChangeActionNonSeated}
                      clearCart={this.CartActionObj}
                      stickyCart={this.stickyMyCart}
                      isMiniCartEnabled={this.showMiniCart && !this.isHeaderMiniCartEnabledOnBookingToPaymentPages}
                    />
                  ) : null}
                  {this.userAgent && !this.isCartPaymentView && <Overlay />}
                </div>
              </div>
              {this.showNewSummaryEmptyCheckoutButton() && (
                <div className="positionRelative">
                  <div data-c-type="footer-buy-tickets" className="footer-checkout-sm">
                    <DynamicContent
                      tagName="a"
                      attrs={{ href: this.props.data.cart.emptyCart.cta.href }}
                      innerHtml={this.props.data.cart.emptyCart.cta.label}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'MyCart');
    }
  }
}

MyCart.propTypes = {
  data: PropTypes.shape({
    cart: PropTypes.object.isRequired,
  }),
};
