/*
 * Notification-component.js
 * This file contains code for Notification component, it renders messages to be displayed in Notification component
 * @licensor  Miral
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Image, AnchorLink, DynamicContent } from '../../presentation/base';
import {
  setCookie,
  getCookie,
  canUseDOM,
  resolvePath,
  getSessionStorage,
  setSessionStorage,
} from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';

const NotificationMessage = (props) => {
  const { variant, details } = props.data;
  const isYaVariant = variant === 'v-ya-crisisnotification-view';

  // FEYA-2571: Crisis Notification feature -
  // To over-ride default notification dismissal timeout if a new version is set in CMS.
  // Version is represented using field `props.data.displayVersion`
  const cookieName = isYaVariant ? 'crisisNotification_' + props.data.displayVersion : 'crisisNotification';
  const html = (
    <div className="crisisNotification">
      {props.data.image && <Image image={props.data.image} />}
      <ul>
        {props.data.details.map(function(name, index) {
          return <NotificationMsg key={index} data={name} variant={variant} />;
        })}
      </ul>
      {isYaVariant && resolvePath(details[0], 'url.href') && (
        <div className="btn-read-more">
          <button
            onClick={() => {
              window.PubSub.publish('closeOverlay');

              if (details[0].url.href) {
                window.location.href = details[0].url.href;
              }
            }}
          >
            {details[0].linkText}
          </button>
        </div>
      )}
      {props.data.button && resolvePath(props.data, 'button.label') && (
        <div className="btn-primary">
          <Text
            tag="button"
            className="primaryAction"
            field={{ value: props.data.button.label, editable: props.data.button.label }}
            onClick={() => window.PubSub.publish('closeOverlay')}
          />
        </div>
      )}
    </div>
  );
  useEffect(() => {
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      dataToAppend: html,
      customClass: 'crisis-info-overlay',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  try {
    if (canUseDOM()) {
      window.PubSub.subscribe('closeOverlay', () => {
        // Cookie set for Notification timeout
        setCookie(cookieName, 'session', Number(props.data.overlayTimeOut));

        window.PubSub.publish('toggleOverlayState', {
          shouldOpen: false,
        });
      });
      const notificationCookie = getCookie(cookieName);

      if (notificationCookie === '' && (getSessionStorage('isFirstLogIN') === 'true' || isYaVariant)) {
        if (props.data.image)
          props.data.image.imageAltText =
            props.data.image.desktopImage.imageAltText ||
            props.data.image.mobileImage.imageAltText ||
            props.data.image.tabletImage.imageAltText;

        setSessionStorage('isFirstLogIN', 'false');
        window.PubSub.publish('showAlert', {
          showPopUp: false,
        });
      } else {
        window.PubSub.publish('showAlert', {
          showPopUp: true,
        });
      }
    }

    return null;
  } catch (err) {
    return logComponentRenderingError(err, 'NotificationMessage', variant);
  }
};

//NotificationMsg will render each row in li with dynamically bound data
const NotificationMsg = (props) => {
  const { variant } = props;
  const isYaVariant = variant === 'v-ya-crisisnotification-view';

  return (
    <li>
      {props.data.label && (
        <Text tag="div" className="heading-4" field={{ value: props.data.label, editable: props.data.label }} />
      )}
      {props.data.date && <Text tag="div" field={{ value: props.data.date, editable: props.data.date }} />}
      {props.data.details && (
        <div>
          <DynamicContent tagName="div" innerHtml={props.data.details} />
          {!isYaVariant && props.data.url && <AnchorLink link={props.data.url} className="notification_link" />}
        </div>
      )}
    </li>
  );
};

export default NotificationMessage;

NotificationMessage.propTypes = {
  guid: PropTypes.string.isRequired,
  data: PropTypes.shape({
    button: PropTypes.object.isRequired,
    recordCount: PropTypes.string,
    overlayTimeOut: PropTypes.string.isRequired,
  }),
};
