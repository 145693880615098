import React from 'react';
import { AnchorLink, Image, RichText } from '..';

import './styles.scss';
import UIConfig from '../../../../common/UIConfig';
import { checkTenant, isMatchTenant } from '../../../../common/utility';

const index = ({ image, link, shortTitle, title, secondaryLink, variant, count }) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isCrossPromotion = isSwad && variant?.includes(UIConfig.swadVariant.quickLinksOneLine);

  const quickLinksContent = (nolinks = false) => {
    return (
      <>
        {!isCrossPromotion && <Image image={{ src: image, alt: title }} noRendition />}
        <div className="quick-links-band-item__link__content">
          <div className="quick-links-band-item__link__content__text">
            {isCrossPromotion && <Image image={{ src: image, alt: title }} noRendition />}
            <span className="ldc-custom title">{title}</span>
            <span className="short-title">
              <RichText
                data={{
                  bodyCopy: shortTitle,
                }}
              />
            </span>
          </div>

          {!isCrossPromotion && <div className="quick-links-band-item__link__content__arrow"></div>}
        </div>
        {isCrossPromotion && !nolinks && (
          <>
            <div className="quick-links-band-item__link__buttons section-header">
              <AnchorLink link={secondaryLink} className="cta-button" />
              <AnchorLink link={link} className="learn-more-button" />
            </div>
          </>
        )}
      </>
    );
  };
  const isYasIsland = checkTenant(UIConfig.iamMapping.yasisland);
  return (
    <div className={`quick-links-band-item ${isYasIsland && count > 3 ? `moreThanThreeBands` : ''} `}>
      {isSwad && count === 1 ? (
        <div className="quick-links-band-item__link no-click">{quickLinksContent()}</div>
      ) : isMatchTenant(UIConfig.tenants.yi) ? (
        quickLinksContent(true)
      ) : (
        <a className="quick-links-band-item__link" target={link.target} href={link.href}>
          {quickLinksContent()}
        </a>
      )}
    </div>
  );
};

export default index;
