import React from 'react';
import DynamicContent from '../dynamic-content/dynamic-content-component';
const CollapsedView = ({ title, subtitle, extraClassName = '', onClick }) => {
  return (
    <div
      className={`collapsed-container ${extraClassName}`}
      role="button"
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={(e) => {
        // Trigger the click event when the Enter key is pressed
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      <div className="left-section">
        <DynamicContent
          tagName="p"
          attrs={{
            className: 'collapsed-title',
          }}
          innerHtml={title}
        />
        <DynamicContent
          tagName="p"
          attrs={{
            className: 'collapsed-subtitle',
          }}
          innerHtml={subtitle}
        />
      </div>
      <div className="right-section"></div>
    </div>
  );
};

export default CollapsedView;
