import UIConfig from '../../../common/UIConfig';
import { resolvePath, addLoaderOverlay, removeLoaderOverlay, setLocalStorage, isEmpty } from '../../../common/utility';
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import { checkFieldsToExclude } from '../../../common/forms-utility';
import { validateConditions, showOrHideInput } from '../../presentation/jss-form-components/custom-conditions';
import moment from 'moment';
import ApiWrapper from '../../../common/api-wrapper';
import { Logging } from '../../../common/logger';

const { hiddenTypeId, buttonId, captchaTypeId, selectWithTextId, calendarId } = UIConfig.jssForms.fields;
const { ageValidator } = UIConfig.jssForms.customSections;
const { saveSiteCoreData, emailConfirmationId, emailFlagValue } = UIConfig.jssForms;
const { CheckboxList, Button, Email } = FieldTypes;

export const getButtonData = (formFields) => {
  let customButtonField = {};
  const buttonArr = [buttonId, Button];
  resolvePath(formFields, 'fields', []).forEach((item) => {
    const fieldTypeItemId = resolvePath(item, 'model.fieldTypeItemId', '');
    if (item.hasOwnProperty('fields')) {
      customButtonField = item.fields.find((itm) => buttonArr.includes(itm.model.fieldTypeItemId));
    } else if (fieldTypeItemId && buttonArr.includes(fieldTypeItemId)) {
      customButtonField = item;
    }
  });
  const submitActions = resolvePath(customButtonField, 'model.submitActions', []);
  const isSaveSiteCoreData = submitActions.filter((itm) => itm.submitActionId === saveSiteCoreData).length > 0;
  const url = resolvePath(customButtonField, 'model.absoluteEndPoint', '');
  const triggerEmail = resolvePath(customButtonField, 'model.navigationStep', '') === emailFlagValue;
  return { url, isSaveSiteCoreData, triggerEmail };
};

const getCombinedFormData = (formData, field) => {
  const formDataArr = [];
  const typeId = resolvePath(field, 'model.fieldTypeItemId', '');
  let fieldData = {},
    textParamsValue = '';
  if (typeId === hiddenTypeId) {
    fieldData = {
      key: field.model.name,
      value: field.model.text,
    };
  } else if (field.hasOwnProperty('valueField')) {
    const filterData = formData.filter((form) => form.key === field.valueField.name);
    fieldData = filterData && filterData.length ? Object.assign({}, filterData[0]) : null;

    if (typeId === CheckboxList && filterData && filterData.length) {
      let arrValue = [];
      filterData.forEach((item) => {
        if (!arrValue.includes(item.value)) {
          arrValue.push(item.value);
        }
      });
      fieldData.value = arrValue;
    }

    if (!fieldData) {
      let addObj = { key: field.valueField.name, value: field.model.value || '' };
      formDataArr.push({ key: field.indexField.name, value: field.indexField.value });
      formDataArr.push({ key: field.fieldIdField.name, value: field.fieldIdField.value });
      formDataArr.push(addObj);
      fieldData = { ...addObj, value: field.model.value || '' };
      if (typeId === CheckboxList) {
        fieldData.value = fieldData.value || [];
      }
    } else {
      fieldData.value = fieldData.value || '';
    }
    const fieldItems = resolvePath(field, 'model.items', []);
    textParamsValue = fieldData.value;
    if (typeId !== selectWithTextId && fieldData.value && fieldItems.length) {
      if (Array.isArray(fieldData.value) && fieldData.value.length) {
        const textParamsArr = [];
        fieldData.value.forEach((item) => {
          const valueText = fieldItems.find((itm) => itm.value === item) || {};
          textParamsArr.push(valueText.text);
        });
        textParamsValue = textParamsArr;
      } else {
        const valueText = fieldItems.find((itm) => itm.value === fieldData.value) || {};
        textParamsValue = valueText.text;
      }
    }
  }
  return { fieldData, formDataArr, textParamsValue };
};

const verifyFormAgeValidator = (items, formData) => {
  const guardianField = items.fields.find((itm) => itm.model.fieldTypeItemId !== calendarId);
  const calField = items.fields.find((itm) => itm.model.fieldTypeItemId === calendarId);
  let ageObj = {
    isFormDataUpdated: false,
    filterData: formData,
  };
  if (guardianField && resolvePath(guardianField, 'model.required', '')) {
    const valueFieldName = resolvePath(calField, 'valueField.name', '');
    const formInputObj = formData.find((item) => item.key === valueFieldName) || {};
    if (calField && formInputObj && formInputObj.value) {
      const selectedDate = moment(formInputObj.value).format(UIConfig.b2c.profile.dateFormat);
      const ageDiff = moment().diff(selectedDate || moment(), 'years');
      const computedValue = validateConditions({
        value: ageDiff,
        conditionSettings: calField.model.conditionSettings,
      });
      const checkForError = showOrHideInput(calField.model.conditionSettings);
      if (!(computedValue && checkForError)) {
        ageObj.filterData = formData.filter((itm) => {
          const keysArr = [
            guardianField.valueField.name,
            guardianField.indexField.name,
            guardianField.fieldIdField.name,
          ];
          if (!keysArr.includes(itm.key)) {
            return true;
          }
        });
        ageObj.isFormDataUpdated = true;
      }
    }
  }
  return ageObj;
};

export const getCompleteFormData = (props) => {
  const { formData, formFields } = props;
  let data = formData.data;
  let payload = {};
  let gtmParams = {};
  formFields &&
    formFields.fields.forEach((item) => {
      if (item.hasOwnProperty('fields')) {
        item.fields.forEach((field) => {
          if (field.hasOwnProperty('fields')) {
            field.fields.forEach((itm) => {
              if (!checkFieldsToExclude(itm)) {
                const filterSectionData = getCombinedFormData(data, itm);
                payload[itm.model.name] = filterSectionData.fieldData.value || '';
                gtmParams[itm.model.name] = filterSectionData.textParamsValue || '';
                data = [...data, ...filterSectionData.formDataArr];
              }
            });
            const fieldName = resolvePath(field, 'model.name', '');
            const fieldArr = fieldName.split('-');
            if (fieldArr && fieldArr[0] === ageValidator) {
              const ageObj = verifyFormAgeValidator(field, data);
              if (ageObj.isFormDataUpdated) {
                data = ageObj.filterData;
              }
            }
          } else if (!checkFieldsToExclude(field)) {
            const filterSectionData = getCombinedFormData(data, field);
            payload[field.model.name] = filterSectionData.fieldData.value || '';
            gtmParams[field.model.name] = filterSectionData.textParamsValue || '';
            data = [...data, ...filterSectionData.formDataArr];
          }
        });
      } else if (!checkFieldsToExclude(item)) {
        const filterSectionData = getCombinedFormData(data, item);
        payload[item.model.name] = filterSectionData.fieldData.value || '';
        gtmParams[item.model.name] = filterSectionData.textParamsValue || '';
        data = [...data, ...filterSectionData.formDataArr];
      }
    });
  return { payload, data, gtmParams };
};

export const getCaptchaField = (formFields) => {
  let captchaField = null;
  formFields.fields.forEach((item) => {
    if (item.hasOwnProperty('fields')) {
      captchaField = item.fields.find((field) => field.model.fieldTypeItemId === captchaTypeId);
    } else if (resolvePath(item, 'model.fieldTypeItemId', '') === captchaTypeId) {
      captchaField = item;
    }
  });
  return captchaField;
};

export const getSiteCoreUrl = (key, endPoint) => {
  const urlArr = endPoint.split('&').map((item) => {
    if (item && item.includes('sc_apikey')) {
      const keyValueArr = item.split('=');
      if (keyValueArr && keyValueArr.length > 1 && !keyValueArr[1]) {
        item = `sc_apikey=${key}`;
      }
    }
    return item;
  });
  return urlArr.join('&');
};

export const handleFormLoader = (isRemoveLoader, onlySiteCoreFormSubmission) => {
  const el = document.querySelector(UIConfig.loader.contactusDefaultPreLoaderTarget);
  if (el && onlySiteCoreFormSubmission) {
    if (isRemoveLoader) {
      removeLoaderOverlay(el);
    } else {
      addLoaderOverlay(el);
    }
  }
};

const getArrayPayloadKey = (inputArr, payload) => {
  const payArr = Object.keys(payload);
  let value = '';
  inputArr.forEach((val) => {
    if (!value && payArr.includes(val)) {
      value = val;
    }
  });
  return value;
};

export const triggerSuccessEmailPayload = (payload) => {
  const firstFieldName = ['FirstName', 'firstname', 'firstName'];
  const emailFieldName = ['Email', 'emailaddress1', 'email'];
  const lastNameFieldName = ['LastName', 'lastname', 'lastName'];

  const lastName = getArrayPayloadKey(lastNameFieldName, payload) || 'lastName';
  const firstName = getArrayPayloadKey(firstFieldName, payload) || 'firstName';
  const email = getArrayPayloadKey(emailFieldName, payload) || 'email';

  return {
    firstName: payload[firstName],
    lastName: payload[lastName],
    email: payload[email],
  };
};

export const emailZeroBounce = async (url) => {
  let el = document.querySelector("input[type='email']");
  let val = el.value;

  if (!val) return {};
  let sanatizeUrl = url.replace('/:email', `?email=${val}`);

  let emailResponse = [];
  await ApiWrapper.apiGateway({
    url: sanatizeUrl,
    method: 'GET',
    body: {},
  })
    .then((res) => {
      emailResponse = res;
      Logging(res, resolvePath(res, 'config.moduleName', 'JSSForm'), true, 'NewsletterSubscriptionAPI submitted');
    })
    .catch((error) => {
      Logging(
        error,
        resolvePath(error, 'config.moduleName', 'JSSForm'),
        true,
        'NewsletterSubscriptionAPI submission failed',
      );
    });

  return emailResponse;
};

export const replaceByCorpEmailId = (error, emailId) => {
  if (isEmpty(error) || error?.length < 1) return '';

  return error.replace('{emailId}', emailId);
};

export const setCorpEmailId = (corpEmail) => {
  setLocalStorage(UIConfig.localStoreKeys.CORPORATE_EMAIL, JSON.stringify({ corpEmail }));
};
