/*
 * order-container-components.js
 * This file contains code for Order Container component, It is a smart componet,it gets filter data from it's parent and make ajax call and then update the data
 * @author SapientNitro
 * @licensor  Miral
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OrderTable from '../../presentation/order-table/order-table-component';
import ErrorOverlay from '../../presentation/error-overlay/error-overlay-component';
import ErrorSummary from '../../presentation/base/error-summary/error-summary-component';
import { OrderService, OmniService } from '../../../common/services';
import {
  canUseDOM,
  getLoggedInUser,
  groupBy,
  filterList,
  getErrorMap,
  parseQueryString,
  getCurrentLanguage,
  getProp,
  resolvePath,
} from '../../../common/utility';
import { getProductTypes } from '../../../common/coveo-api';
import UIConfig from '../../../common/UIConfig';

/**
 * order container Class ( which extends the React.Component)
 * it is the container class for My Order page, that will be exposed to server
 */

export default class OrderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      orderDetail: {},
      currentExpandedContext: '',
      filterData: '',
      errorData: '',
    };
    this.currentSortItem = 'createdDate';
    this.currentSortDirection = 'desc';
    this.defaultFilterStatus = 'Pending';
    this.onLoad = true;
    this.orderCallCompleted = false;
    const services = this.props.data.services;
    this.urls = {
      orders: services.getOrders.url,
      getOrderDetail: services.getOmniOrder.url,
      deleteOrder: services.deleteOrder.url,
    };
    this.userData = getLoggedInUser();
  }

  /*
   * WIll publish event
   * Invoke Delete Order Modal
   * Get Modal html from getDeleteOverlayHTML function
   */

  deleteOrder = (e) => {
    if (canUseDOM()) {
      e.preventDefault();
      this.selectedDeleteItem = e.currentTarget.attributes['data-bookingId'].value;
      const modalHTML = this.getDeleteOverlayHTML();
      window.PubSub.publish('toggleOverlayState', {
        shouldOpen: true,
        dataToAppend: modalHTML,
        customClass: 'c-error-overlay',
      });
    }
  };

  /*
   * callback, once user click on Yes in Delete pop up
   * Make api call to delete the order
   * then set the state of the component again to load fresh data
   */

  deleteItem = (e) => {
    const orderData = OrderService.deleteOrder(
      this.urls.deleteOrder + '/' + this.selectedDeleteItem,
      true,
      '.cart-delete-overlay',
    );
    orderData
      .then(() => {
        const orderData = OrderService.getOrders(this.url, true, '.c-order-data-grid-list');
        orderData
          .then((response) => {
            this.setState({
              currentExpandedContext: this.state.currentExpandedContext,
              orderData: response.data.orderItems,
              orderDetail: this.state.orderDetail,
              preLoader: true,
              preLoaderTarget: '.c-order-data-grid-list',
            });
            this.closeOverlay(e);
          })
          .catch((response) => {
            const errorObj = getErrorMap(
              'getOrders',
              this.props.data.services.getOrders.errors,
              false,
              response.error,
              this.state.errorData,
            );
            this.setState({ errorData: errorObj });
          });
      })
      .catch((response) => {
        this.closeOverlay(e);
        const errorObj = getErrorMap(
          'deleteOrder',
          this.props.data.services.deleteOrder.errors,
          false,
          response.error,
          this.state.errorData,
        );
        this.setState({ errorData: errorObj });
      });
  };

  //Close the Delete Modal Overlay

  closeOverlay = (e) => {
    e.preventDefault();
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: false,
    });
  };

  // Return the Delete Modal Html

  getDeleteOverlayHTML = () => {
    if (this.deleteOveralyLoaded) {
      return this.deleteOveralyHTML;
    } else {
      const attrs = {
        title: this.props.data.deleteOverlay.title,
        primaryCTA: this.props.data.deleteOverlay.deleteCta.label,
        secondaryCTA: this.props.data.deleteOverlay.cancelCta.label,
        primaryCallback: this.deleteItem,
      };

      this.deleteOveralyHTML = <ErrorOverlay {...attrs} />;
      return this.deleteOveralyHTML;
    }
  };

  /*
   * headerClick - will get caled once user clicks on grid heading
   *  used when user wants to sort the data
   * make server call for sorting the data
   */

  headerClick = (e) => {
    e.preventDefault();
    let url;
    if (e.target.attributes['data-sort'] && e.target.attributes['data-sort'].value && canUseDOM()) {
      let sortDirection = 'asc';
      if (this.currentSortItem === e.target.attributes['data-sort'].value) {
        sortDirection = this.currentSortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.currentSortItem = e.target.attributes['data-sort'].value;
      this.currentSortDirection = sortDirection;
      url = `${this.generateUrl(this.props)}`;
      const orderData = OrderService.getOrders(url, true, '.c-order-data-grid-list');
      orderData
        .then((response) => {
          this.setState({
            currentExpandedContext: this.state.currentExpandedContext,
            orderData: response.data.orderItems,
            orderDetail: this.state.orderDetail,
          });
        })
        .catch((response) => {
          const errorObj = getErrorMap(
            'getOrders',
            this.props.data.services.getOrders.errors,
            false,
            response.error,
            this.state.errorData,
          );
          this.setState({ errorData: errorObj });
        });
    }
  };

  /*
   * generateUrl - Will generate the url by checking the filter value
   * it takes base url from config and then add paraneters as per the selected filter
   */

  generateUrl(props) {
    let agentId = this.userData.agentId,
      role = this.userData.userType,
      partnerId = this.userData.partnerId,
      fromDate,
      toDate,
      dateString = '',
      url;

    if (parseQueryString(UIConfig.encryptedParams.others.agentId, true)) {
      agentId = parseQueryString(UIConfig.encryptedParams.others.agentId, true);
    }

    if (parseQueryString(UIConfig.encryptedParams.others.role, true)) {
      role = parseQueryString(UIConfig.encryptedParams.others.role, true);
    }

    if (parseQueryString(UIConfig.encryptedParams.others.partnerId, true)) {
      partnerId = parseQueryString(UIConfig.encryptedParams.others.partnerId, true);
    }

    if (parseQueryString('fromDate') && parseQueryString('toDate')) {
      fromDate = parseQueryString('fromDate');
      toDate = parseQueryString('toDate');
      dateString = `&fromDate=${fromDate}&toDate=${toDate}`;
    }

    url = `${this.urls.orders}?agentId=${agentId}&role=${role}&partnerId=${partnerId}&pageno=${props.gridData.pageNo}&pageSize=${props.data.pagination.pageSize}&sortmember=${this.currentSortItem}&sortdirection=${this.currentSortDirection}`;

    if (resolvePath(props, 'gridData.filters.status', null)) {
      url = `${url}&status=${props.gridData.filters.status}`;
    } else {
      if (this.selectecStatus) {
        this.defaultFilterStatus = this.selectecStatus;
      }
      let status = this.defaultFilterStatus;
      if (this.onLoad && parseQueryString('status')) {
        status = parseQueryString('status');
      }
      url = `${url}&status=${status}`;
    }

    if (getProp(props, 'gridData.filters.AgentRefNo')) {
      url = `${url}&agentRefNo=${getProp(props, 'gridData.filters.AgentRefNo')}`;
    }
    if (getProp(props, 'gridData.filters.BookingId')) {
      url = `${url}&bookingId=${getProp(props, 'gridData.filters.BookingId')}`;
    }
    if (getProp(props, 'gridData.filters.reservationCode')) {
      url = `${url}&reservationCode=${getProp(props, 'gridData.filters.reservationCode')}`;
    }
    if (getProp(props, 'gridData.filters.AgentName')) {
      url = `${url}&agentName=${getProp(props, 'gridData.filters.AgentName')}`;
    }

    //Check if dates are being selected from custom drop down, if yes then append them to URL otherwise append from URL param
    var isDatesAvailable =
      props.gridData.filters.orderDate &&
      (props.gridData.filters.orderDate.startDate || props.gridData.filters.orderDate.endDate);
    if (getProp(props, 'gridData.filters') && isDatesAvailable) {
      if (getProp(props, 'gridData.filters.orderDate.startDate')) {
        url = `${url}&fromDate=${getProp(props, 'gridData.filters.orderDate.startDate')}`;
      }

      if (props.gridData.filters.orderDate.endDate) {
        url = `${url}&toDate=${props.gridData.filters.orderDate.endDate}`;
      }
    } else {
      url = `${url}${dateString}`;
    }

    return url;
  }

  /*
   * chevronClick - This will be called once user click on chevron for order detail
   * Render expanded View
   * First make Omni call to get Order Detail
   * Then make Coveo call for grouping
   * Grouped the data
   * Then it sets the state, this grouped data will be used Order-Detail-Mini component
   */

  chevronClick = (e) => {
    e.preventDefault();
    const bookingId = e.currentTarget.attributes['data-bookingid'].value;
    const agentRef = e.currentTarget.attributes['data-agentref'].value;
    const obj = this.state.orderDetail;

    //scenario when user wants to close the order detail section
    if (this.state.currentExpandedContext === bookingId) {
      obj[bookingId].orderClass = 'hide';
      this.setState({
        currentExpandedContext: '',
        orderData: this.state.orderData,
        orderDetail: obj,
      });
      return;
    }

    const callBack = () => {
      //getting selected language and if there is no data then fallback to default language
      let lang = getCurrentLanguage();
      if (getLoggedInUser().productLanguages && getLoggedInUser().productLanguages.indexOf(lang) === -1) {
        lang = getLoggedInUser().productLanguages[0];
      }
      //making request for getting order detail(omni) for a particular id
      const omniData = OmniService.getOmniOrder(
        this.urls.getOrderDetail + '/' + bookingId + '?tenantid=' + this.userData.tenantID + '&isEncrypted=false',
        true,
        '.c-order-detail-mini-table',
      );
      omniData
        .then((orderResponse) => {
          //making request for getting product detail(coveo) for all produect in a particular order
          const productIdList = orderResponse.data.orderdetails.order.items.map((item) => {
            return item.productId;
          });
          var keyMap = this.props.data.coveoMapping,
            productTypeConfig = {
              productIdList: productIdList,
              lang: lang,
              fieldsToInclude: Object.values(keyMap),
              url:
                this.props.data.services && this.props.data.services.coveoSearchUrl
                  ? this.props.data.services.coveoSearchUrl.url
                  : '',
              coveoKeyMap: {
                templatename: keyMap.templatename,
                Code: keyMap.id,
                language: keyMap.language,
              },
              numberOfResults: UIConfig.coveoResultsPerPage,
              preLoaderTarget: '.c-order-detail-mini-table',
            };
          getProductTypes(productTypeConfig)
            .then((productResponse) => {
              //looping as we need to extract first value of searchtabcategory, temporary work around
              productResponse.forEach((product) => {
                if (typeof product[keyMap['tab']] === 'object') {
                  product[keyMap['tab']] = product[keyMap['tab']][0];
                }
              });

              const mainOrderObj = {};
              mainOrderObj.columns = this.props.data.orderDetail.agentColumns;
              mainOrderObj.agentLabel = filterList(this.props.data.orderDetail.agentColumns, 'AgentRefKey').label + ':';
              mainOrderObj.agentRef = agentRef;
              mainOrderObj.orderData = this.state.orderData.filter((order) => {
                return order.bookingId === bookingId;
              })[0];
              mainOrderObj.data = [];
              const groupedData = groupBy(productResponse, keyMap['tab']);
              let total = 0;
              for (const key in groupedData) {
                if (groupedData.hasOwnProperty(key)) {
                  const obj = orderResponse.data.orderdetails.order.items
                    .filter((order) => {
                      return groupedData[key].map((item) => item[keyMap['id']]).indexOf(order.productId) >= 0;
                    })
                    .reduce(
                      (accumulator, item) => {
                        return {
                          quantity: accumulator.quantity + parseInt(item.quantity, 10),
                          price: accumulator.price + Number(item.price.gross, 10),
                        };
                      },
                      { price: 0, quantity: 0 },
                    );
                  total = total + obj.price; //price here is the total price for that product
                  obj.subTotal =
                    this.props.data.currency +
                    ' ' +
                    Number(
                      obj.price, //price here is the total price for that product
                      10,
                    ).toFixed(2);
                  obj.price = this.props.data.currency + ' ' + Number(obj.price).toFixed(2);
                  let summary;
                  if (typeof groupedData[key][0][keyMap['tabTitle']] === 'object') {
                    summary = groupedData[key][0][keyMap['tabTitle']][0];
                  } else {
                    summary = groupedData[key][0][keyMap['tabTitle']];
                  }
                  obj.summary = summary;
                  mainOrderObj.data.push(obj);
                }
              }

              mainOrderObj.data.push({
                className: 'total',
                summary: this.props.data.orderDetail.productTypeLabel.total,
                subTotal: this.props.data.currency + ' ' + total.toFixed(2),
              });

              //setting the class so that we can shoow or hide order detail component

              if (bookingId) {
                obj[bookingId] = {};
                if (this.state.currentExpandedContext) {
                  obj[this.state.currentExpandedContext].orderClass = 'hide';
                }
                obj[bookingId].orderClass = 'show';
                obj[bookingId].orderDetailData = mainOrderObj;
                //Finally setting the state with new object
                this.setState({
                  currentExpandedContext: bookingId,
                  orderData: this.state.orderData,
                  orderDetail: obj,
                });
              }
            })
            .catch((response) => {
              const errorObj = getErrorMap(
                'coveoSearchUrl',
                this.props.data.services.coveoSearchUrl.errors,
                false,
                response.error,
                this.state.errorData,
              );
              this.setState({ errorData: errorObj });
            });
        })
        .catch((response) => {
          const errorObj = getErrorMap(
            'getOmniOrder',
            this.props.data.services.getOmniOrder.errors,
            false,
            response.error,
            this.state.errorData,
          );
          this.setState({ errorData: errorObj });
        });
    };

    //Scenario when we have already brought the order detail of a partcular oder and user wants to see it again, so avoiding server call again
    if (this.state && this.state.orderDetail && this.state.orderDetail[bookingId]) {
      const obj = this.state.orderDetail[bookingId];
      obj.orderClass = 'show';
      this.setState({
        currentExpandedContext: bookingId,
        orderData: this.state.orderData,
        orderDetail: this.state.orderDetail,
      });
      return;
    }
    const tempObj = {};
    if (bookingId) {
      tempObj[bookingId] = {};
      if (this.state.currentExpandedContext && this.state.orderDetail) {
        this.state.orderDetail[this.state.currentExpandedContext].orderClass = 'hide';
      }
      tempObj[bookingId].orderClass = 'show';
      const mainTempObj = {};
      mainTempObj.columns = this.props.data.orderDetail.agentColumns;
      mainTempObj.data = [];
      mainTempObj.orderData = this.state.orderData.filter((order) => {
        return order.bookingId === bookingId;
      })[0];
      tempObj[bookingId].orderDetailData = mainTempObj;
      //Finally setting the state with new object
      this.setState(
        {
          currentExpandedContext: bookingId,
          orderData: this.state.orderData,
          orderDetail: tempObj,
        },
        callBack,
      );
    }
  };

  /*
        make api call to get the data on load
        calls parent callback as well to update the pagination
    */

  componentDidMount = () => {
    if (canUseDOM()) {
      const filterData = this.props.data.filters.orderType.filter(function(o) {
        return o.selected;
      });
      if (filterData[0]) {
        this.selectecStatus = filterData[0].id;
      }

      const url = this.generateUrl(this.props);
      this.url = url;

      const orderData = OrderService.getOrders(url, true, '.c-order-data-grid-list');

      orderData
        .then((response) => {
          this.setState({ orderData: response.data.orderItems });
          this.orderCallCompleted = true;
          this.props.updatePageCount(Math.ceil(response.data.total / this.props.data.pagination.pageSize));
        })
        .catch((response) => {
          const errorObj = getErrorMap(
            'getOrders',
            this.props.data.services.getOrders.errors,
            false,
            response.error,
            this.state.errorData,
          );
          this.setState({ errorData: errorObj });
        });
    }
  };

  /*
   * will get called once filter gots changed
   * make api call to get the data as per selected filter
   */

  componentWillReceiveProps = (props) => {
    let newUrl = '';
    if (canUseDOM()) {
      newUrl = this.generateUrl(props);
    }
    if (this.url !== newUrl || this.freshCall) {
      this.freshCall = false;
      this.url = newUrl;
      this.orderCallCompleted = false;
      const orderData = OrderService.getOrders(this.url, true, '.c-order-data-grid-list');

      orderData
        .then((response) => {
          this.setState({ orderData: response.data.orderItems });
          this.orderCallCompleted = true;
          this.props.updatePageCount(Math.ceil(response.data.total / this.props.data.pagination.pageSize));
        })
        .catch((response) => {
          const errorObj = getErrorMap(
            'getOrders',
            this.props.data.services.getOrders.errors,
            false,
            response.error,
            this.state.errorData,
          );
          this.setState({ errorData: errorObj });
        });
    }
  };
  /*
    For Rendering the whole component, will call OrderTable component
    */

  render() {
    return (
      <div>
        <ErrorSummary data={this.state.errorData} />
        <OrderTable
          className="component c-order-container"
          labelConfig={this.props.data}
          deleteOrder={this.deleteOrder.bind(this)}
          selectedStatus={this.props.gridData.filters.status ? this.props.gridData.filters.status : this.selectecStatus}
          orderCallCompleted={this.orderCallCompleted}
          currentExpandedContext={this.state.currentExpandedContext}
          headerClick={this.headerClick.bind(this)}
          currency={this.props.data.currency}
          orderData={this.state.orderData}
          click={this.chevronClick.bind(this)}
          orderDetail={this.state.orderDetail}
        />
      </div>
    );
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */

OrderContainer.propTypes = {
  gridData: PropTypes.shape({
    filters: PropTypes.object,
  }),
  data: PropTypes.shape({
    pagination: PropTypes.object,
  }),
};
