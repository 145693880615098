import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logComponentRenderingError } from '../../../common/logger';
import { detectMobile } from '../../../common/utility';

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.toggleState = this.toggleState.bind(this);
    this.openTooltip = this.openTooltip.bind(this);
    this.closeTooltip = this.closeTooltip.bind(this);
    this.myHandler = this.myHandler.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.keyPressCallBack = this.keyPressCallBack.bind(this);
    this.state = {
      expanded: false,
    };
    //properties to enable dropdown functionalities of profile
    this.listItems = [];
    this.listItemOnFocus = 0;
    this.isProfileToolTip = false;
    this.tooltipRef = React.createRef();
    this.isFwad = this.props.isFwad;
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.body.addEventListener('click', this.myHandler);
      document.body.addEventListener('touchend', this.myHandler);
      this.setKeyDownEventsOnProfileTooltip(true);
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.body.removeEventListener('click', this.myHandler);
      document.body.removeEventListener('touchend', this.myHandler);
      this.setKeyDownEventsOnProfileTooltip(false);
      window.removeEventListener('keydown', this.preventPageScroll);
    }
  }
  setKeyDownEventsOnProfileTooltip = (enable) => {
    var profileToolTip = document.body.querySelector('.header-bottom-profile-tooltip');
    if (profileToolTip) {
      if (enable) {
        profileToolTip.addEventListener('keydown', this.myHandler);
      } else {
        profileToolTip.removeEventListener('keydown', this.myHandler);
      }
    }
  };
  myHandler(e) {
    if (this.state.expanded && this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.closeTooltip();
    }
    if (this.isProfileToolTip) {
      this.executeDropDownFunctionality(e);
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleState(e) {
    const languageSelectionOpen = document.querySelector(
      '.c-mini-header .header-bottom-lang-switcher .language-switcher .selected-option:not(.collapse)',
    );
    this.notifyParent(!this.state.expanded);
    e.preventDefault();
    if (this.props.isMiniHeader && languageSelectionOpen) {
      window.PubSub.publishSync('closeOtherDropDowns');
    }
    this.setState((prevState) => {
      return {
        expanded: !prevState.expanded,
      };
    });
    e.stopPropagation();
  }
  /**
   * preventPageScroll: prevent window scroll using up, down arrows
   * @param {*} e event object
   */
  preventPageScroll(e) {
    if ([38, 40].indexOf(e.which) > -1) {
      e.preventDefault();
    }
  }

  /**
   * executeDropDownFunctionality: performs the dropdown functionality in user profile
   * @param {*} e event object
   */
  executeDropDownFunctionality = (e) => {
    this.listItems = this.getFocussableElements();
    if (e.type === 'keydown' && !e.shiftKey && this.listItems.length > 0) {
      window.addEventListener('keydown', this.preventPageScroll);
      if (e.which === 40 && this.state.expanded && this.listItemOnFocus <= this.listItems.length - 1) {
        if (this.listItemOnFocus === this.listItems.length - 1) {
          this.listItemOnFocus = -1;
        }
        const listItem = this.listItems[this.listItemOnFocus + 1];
        if (listItem) {
          listItem.focus();
          this.listItemOnFocus += 1;
        }
      } else if (e.which === 38 && this.state.expanded && this.listItemOnFocus >= 0) {
        if (this.listItemOnFocus === 0) {
          this.listItemOnFocus = this.listItems.length;
        }
        this.listItems[this.listItemOnFocus - 1].focus();
        this.listItemOnFocus -= 1;
      } else if (
        e.which === 27 ||
        (e.which === 9 && document.activeElement === this.listItems[this.listItems.length - 1])
      ) {
        this.closeTooltip();
        this.isProfileToolTip && this.tooltipRef.focus();
      }
    }
    if (e.shiftKey && e.which === 9 && document.activeElement === this.listItems[0]) {
      this.closeTooltip();
    }
  };

  getFocussableElements = () => {
    return document.querySelectorAll('.header-bottom-profile-tooltip [tabindex]');
  };

  openTooltip() {
    this.notifyParent(true);
    this.setState({
      expanded: this.props.isMiniHeader ? !this.state.expanded : true,
    });
  }

  closeTooltip() {
    setTimeout(() => {
      this.setState({
        expanded: false,
      });
      this.notifyParent(false);
    });
  }

  notifyParent = (isOpen) => this.props.callback && this.props.callback(isOpen);

  keyPressCallBack = (e) => {
    if (this.isProfileToolTip && e.type === 'keydown' && (e.which === 13 || e.which === 32 || e.which === 40)) {
      e.preventDefault();
      this.toggleState(e);
      this.setFocusOnFirstListItem();
    } else if (e.type === 'keydown' && (e.which === 13 || e.which === 32)) {
      e.preventDefault();
      this.isProfileToolTip = true;
      this.toggleState(e);
      this.setFocusOnFirstListItem();
    } else if (e.which === 27 || (e.shiftKey && e.which === 9)) {
      this.closeTooltip();
    }
  };
  setFocusOnFirstListItem = () => {
    const focussable = this.getFocussableElements();
    focussable[0].focus();
    setTimeout(() => {
      focussable[0].focus();
    }, 10);
  };

  render() {
    try {
      const options = {
        role: 'tooltip',
        className: '',
      };
      const isDefaultOpen = this.props.isDefaultOpen;
      if (this.props.buttonClass) {
        options.className = this.props.buttonClass;
      }
      if (this.state.expanded) {
        options.className = `${options.className} tooltipOpen`;
      }
      if (this.props.onHover) {
        options.onMouseEnter = this.openTooltip;
        options.onMouseLeave = this.closeTooltip;
        options.onKeyDown = this.keyPressCallBack;
        options.onBlur = () => this.closeTooltip();
      } else if (this.props.onClick) {
        options['aria-haspopup'] = 'true';
        options['aria-expanded'] = this.state.expanded ? 'true' : 'false';
        options.onClick = this.toggleState;
        options.onKeyDown = this.keyPressCallBack;
      }
      if (this.props.tabIndex !== -1) {
        options.tabIndex = '0';
        if (this.props.buttonClass && this.props.buttonClass.includes('profile')) {
          options['aria-haspopup'] = 'true';
          options['aria-expanded'] = this.state.expanded ? 'true' : 'false';
          options.onClick = this.toggleState;
          options.onKeyDown = this.keyPressCallBack;
        }
      }
      return (
        <div className={this.props.wrapperClass} ref={this.setWrapperRef}>
          <span {...(this.props.buttonClass?.includes('profile') ? '' : { ...options })}>
            {this.props.headerText}
            <a
              {...(this.props.buttonClass?.includes('profile') ? { ...options } : '')}
              ref={this.tooltipRef}
              tabIndex={options.tabIndex ? '0' : '-1'}
              aria-label={
                this.props.ariaLabelToolTip
                  ? this.props.ariaLabelToolTip
                  : this.props.ariaLabel.replace(/<[^>]*>/g, ' ')
              }
              {...((detectMobile() || this.isFwad) &&
                (isDefaultOpen || this.props.isMiniHeader) && {
                  onClick: () => {
                    this.openTooltip();
                  },
                })}
            >
              {this.props.buttonInnerHtml}
            </a>
            {(isDefaultOpen || this.state.expanded) && this.props.children}
          </span>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'Tooltip');
    }
  }
}

Tooltip.propTypes = {
  wrapperClass: PropTypes.string,
  buttonClass: PropTypes.string,
  buttonInnerHtml: PropTypes.any,
};

Tooltip.defaultProps = {
  ariaLabel: 'Press enter to open Tooltip',
};
export default Tooltip;
