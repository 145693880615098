/*
 * content-search-results-component.js
 * This file contains code for content search results, It renders search results of content
 * @licensor  Miral
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { queryAnalyticsService } from '../../../common/coveo-search/query-analytics.js';
import {
  decodeQueryString,
  ellipsisDecorator,
  canUseDOM,
  onPopState,
  pushState,
  isKeyboardNavigation,
  getPipeLineAndSearchHubValues,
  getOrigin,
  detectViewPort,
} from '../../../common/utility';
import { isEmpty } from '../../../common/utility/helpers.js';
import TextWithCTA from '../../container/text-with-cta';
import Pagination from '../pagination/pagination-component';
import { getErrorMap, resolvePath } from '../../../common/utility';
import { ErrorSummary } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';
import UIConfig from '../../../common/UIConfig';
import './content-search-results-component.scss';
import { bodyClicks } from '../../../common/analytics-events';

/** Change tab index of disabled next/previous button of pagination */
const changeTabIndex = () => {
  let navigationElm = document.querySelectorAll('.next a[tabindex="-1"], .previous a[tabindex="-1"]'),
    disabledElm = document.querySelector('.pagination .disabled a');
  navigationElm.length && navigationElm[0].setAttribute('tabIndex', 0);
  disabledElm && disabledElm.setAttribute('tabIndex', '-1');
};
const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
const isSwad = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.SWADB2C;
const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
  if (checkfortenants) {
    bodyClicks(eventName, titleData, labelData);
  }
};

const contentDecorator = (results, data) => {
  const {
    coveoKeyMap: { description, title },
  } = data;
  if (results.length > 0) {
    results.forEach((result) => {
      if (result[description]) {
        //calling ellipsisDecorator to append ellipsis after a character limit
        result[description] = ellipsisDecorator(result[description], 230);
      }
      if (result[title]) {
        //calling ellipsisDecorator to append ellipsis after a character limit
        result[title] = ellipsisDecorator(result[title], 45);
      }
    });
    return results;
  }
};

const clickResult = (event, i, searchResults, data) => {
  const {
    coveoKeyMap: { title, url },
  } = data;
  event.preventDefault();
  const selectedResult = searchResults[i];
  selectedResult.documentTitle = selectedResult[title];
  selectedResult.documentUrl = selectedResult[url];
  window.location.href = selectedResult.documentUrl;
  queryAnalyticsService.logClickEvent(selectedResult).catch((error) => {
    console.error('Error logging the click event:', error);
  });
  // IE Edge `finally` block fix
  // https://github.com/quasarframework/quasar/issues/3427#issuecomment-468540385
  // .then(() => {
  //   window.location.href = selectedResult.documentUrl;
  // })
  // .catch(() => true)
};

/**
 * printSearchResults , prints search results
 */
const printSearchResults = (searchResults, data) => {
  const searchSettingVariant = data.searchVariant || '';

  const {
    coveoKeyMap: { url, title, description },
    additionalProperty: { resultLinkLabel } = {},
  } = data;
  const checkMobilePortrait = detectViewPort() === 'mobile';
  return searchResults.map((result, i) => {
    return (
      result[url] && (
        <div key={i} className="search-result">
          <div
            className={`${
              searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? 'search-header-container' : ''
            }`}
          >
            {result[title] ? (
              <a
                className="content-title"
                onClick={(e) => {
                  clickResult(e, i, searchResults, data);
                  googleAnalyticHandlerbody(
                    UIConfig.commonVariant.gaClickEvents.linkClick,
                    result[title],
                    resultLinkLabel,
                  );
                }}
                href={result[url] || ''}
              >
                <h2 className="heading-4">{result[title]}</h2>
              </a>
            ) : null}
            {result[url] &&
            checkMobilePortrait === false &&
            searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? (
              <div className="btn_secondary_arrow">
                <a
                  className={resultLinkLabel ? 'secondary-cta right-arrow' : ''}
                  onClick={(e) => {
                    clickResult(e, i, searchResults, data);
                    googleAnalyticHandlerbody(
                      UIConfig.commonVariant.gaClickEvents.linkClick,
                      result[title],
                      resultLinkLabel,
                    );
                  }}
                  aria-label={resultLinkLabel + result[title]}
                  href={result[url] || ''}
                >
                  {resultLinkLabel || result[url]}
                </a>
              </div>
            ) : null}
          </div>
          {result[description] ? (
            <div
              className={`${
                searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
                  ? 'search-body-description'
                  : ''
              }`}
            >
              {searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
                ? getHighlightedText(result[description], queryParams.q)
                : result[description]}
            </div>
          ) : null}
          {result[url] && checkMobilePortrait === true ? (
            <div className="btn_secondary_arrow">
              <a
                className={resultLinkLabel ? 'secondary-cta right-arrow' : ''}
                onClick={(e) => {
                  clickResult(e, i, searchResults, data);
                  googleAnalyticHandlerbody(
                    UIConfig.commonVariant.gaClickEvents.linkClick,
                    result[title],
                    resultLinkLabel,
                  );
                }}
                aria-label={resultLinkLabel + result[title]}
                href={result[url] || ''}
              >
                {resultLinkLabel || result[url]}
              </a>
            </div>
          ) : null}
          {result[url] &&
          checkMobilePortrait === false &&
          searchSettingVariant !== UIConfig.commonVariant.autoSuggestSearchVariant ? (
            <div className="btn_secondary_arrow">
              <a
                className={resultLinkLabel ? 'secondary-cta right-arrow' : ''}
                onClick={(e) => {
                  clickResult(e, i, searchResults, data);
                  googleAnalyticHandlerbody(
                    UIConfig.commonVariant.gaClickEvents.linkClick,
                    result[title],
                    resultLinkLabel,
                  );
                }}
                aria-label={resultLinkLabel + result[title]}
                href={result[url] || ''}
              >
                {resultLinkLabel || result[url]}
              </a>
            </div>
          ) : null}
        </div>
      )
    );
  });
};
const getHighlightedText = (text, setkeyword) => {
  // search text user inputs
  const value = setkeyword;
  if (value === '' || value == null || value === 0) {
    return <div>{text}</div>;
  } else {
    // split the search value
    const words = value.split(/\s+/g).filter((word) => word.length);

    // join if search value has more than 1 word
    const pattern = words.join(' ');

    const re = new RegExp(pattern, 'gi');
    const children = [];

    let before,
      highlighted,
      match,
      pos = 0;
    // match using RegExp with my text
    const matches = text.match(re);

    if (matches != null) {
      // loop all the matches
      for (match of matches) {
        match = re.exec(text);

        if (pos < match.index) {
          // before has all the text before the word
          // that has to highlighted
          before = text.substring(pos, match.index);

          if (before.length) {
            children.push(before);
          }
        }
        highlighted = <span className="li-highlight-text">{match[0]}</span>;
        // text is highlighted
        children.push(highlighted);

        pos = match.index + match[0].length;
      }
    }
    if (pos < text.length) {
      // text after the highlighted part
      const last = text.substring(pos);
      children.push(last);
    }
    // children array will have the entire text
    return <>{children}</>;
  }
};

/**
 * raiseSearchQuery: makes a search query to coveo
 * @return promise object
 */
const raiseSearchQuery = () => {
  return queryAnalyticsService.getQuery(config);
};

const raiseSearchQueryAdvancedSearch = () => {
  return queryAnalyticsService.getAdvancedSearchQuery(configData);
};

/**
 * setting up configuration to be used by coveo
 */
const setupConfigDataCoveo = (data) => {
  const {
    additionalProperty: { lastDaysPeriod, tz, p, n, m, d, s, asc, includeMetadata },
  } = data;
  const { searchHub } = getPipeLineAndSearchHubValues();
  const mainObj = JSON.parse(localStorage.mainObj);
  const lastDayPeriod = Number(lastDaysPeriod) + 1;
  let fromDate = Date.now() + -lastDayPeriod * 24 * 3600 * 1000;
  fromDate = new Date(fromDate).toISOString();
  let lastDate = Date.now() + -1 * 24 * 3600 * 1000;
  lastDate = new Date(lastDate).toISOString();

  configData = {
    url: mainObj.additionalProperty.coveoStatsUrl,
    method: 'GET',
    params: queryParams.q || '',
    access_token: mainObj.coveoToken,
    fromDate: fromDate,
    toDate: lastDate,
    timezone: tz,
    page: p,
    numberofresults: n,
    metrics: m,
    dimensions: d,
    order: s,
    ascending: asc,
    includeMetadata: includeMetadata,
    filter: "(language IN ['" + data.currentLanguage + "']) AND (originlevel1=='" + searchHub + "')",
    tab: data.additionalProperty.defaultTab,
    language: data.currentLanguage,
    preLoader: true,
  };
};

/**
 * set aria label for pagination's previous & next element
 */
const setPaginationAriaLabel = (data) => {
  const { paginationPreviousAriaLabel, paginationNextAriaLabel } = data;
  const previousEl = document.querySelector('.pagination .previous > a');
  const nextEl = document.querySelector('.pagination .next > a');
  if (previousEl) {
    previousEl.setAttribute('role', 'button');
    previousEl.setAttribute('aria-label', paginationPreviousAriaLabel || 'Previous Page');
  }
  if (nextEl) {
    nextEl.setAttribute('role', 'button');
    nextEl.setAttribute('aria-label', paginationNextAriaLabel || 'Next Page');
  }
};

const analyticsTracker = (response) => {
  if (canUseDOM()) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      searchCount: parseFloat(response.totalCount),
      searchTerm: queryParams.q.toString(),
    });
  }
};

/**
 * ContentSearchResults Class ( which extends the React.Component)
 * and the list elements of search Items
 * @param    {Void} Class does not accept any parants.
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */
let queryParams = {};
let config = {};
let configData = {};
let searchBtnClicked = false;
const ContentSearchResults = (props) => {
  const { data } = props;
  const resultsPerPage = props.data.resultsPerPage || 10;

  const [searchResults, setSearchResults] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [forcePage, setForcePage] = useState(0);
  const [error, setError] = useState('');
  const [isPaginationCall, setIsPaginationCall] = useState(false);

  useEffect(() => {
    if (canUseDOM()) {
      onPopState(() => {
        config.pageNo = (decodeQueryString().page - 1) * resultsPerPage;
        raiseSearchQuery()
          .then(resolveExecutor)
          .catch((error) => {
            const errorMsgData = getErrorMap('searchService', {}, false, error.error, {});
            setError(errorMsgData);
          });
      });
      window.scrollTo(0, 0);
    }

    //decoding query string parameters to object
    queryParams = decodeQueryString();
    //firing search query only if query string has a value
    window.PubSub.subscribe(UIConfig.search.resultsSubmit, (msg, data) => {
      const queryRef = { ...queryParams };
      if (data.btnClicked) {
        document.querySelector('.autosuggest-list') && document.querySelector('.autosuggest-list').remove();
        if (window.history.pushState) {
          var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?q=${encodeURI(
            data.value,
          )}`;
          window.history.pushState({ path: newurl }, '', newurl);
        }
        searchBtnClicked = true;
        queryParams.q = data.value;
        if (queryParams.q && queryRef.q !== queryParams.q) {
          //setting up config data for raising search query
          setupConfigData();
          // Raising search query which returns a promise
          raiseSearchQuery()
            .then(resolveExecutor)
            .catch((error) => {
              const errorMsgData = getErrorMap('searchService', {}, false, error.error, {});
              setError(errorMsgData);
            });
        }
      }
    });
    if (queryParams.q && !searchBtnClicked) {
      //setting up config data for raising search query
      setupConfigData();
      // Raising search query which returns a promise
      raiseSearchQuery()
        .then(resolveExecutor)
        .catch((error) => {
          const errorMsgData = getErrorMap('searchService', {}, false, error.error, {});
          setError(errorMsgData);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* First focusable Element */
    isPaginationCall && setTimeout(focusOnFirstSearchElement, 0);
    /* change tab index of desabled element */
    changeTabIndex();
  }, [isPaginationCall]);

  useEffect(() => {
    pageCount > 1 && setPaginationAriaLabel(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  /** Focus on first search element */
  const focusOnFirstSearchElement = () => {
    if (isKeyboardNavigation()) {
      let firstFocusableElm = document.querySelector('.search-result a');
      firstFocusableElm && firstFocusableElm.focus();
    }
    setIsPaginationCall(false);
  };

  /**
   * resolveExecutor, is callback function when promise is resolved
   */
  const resolveExecutor = (response) => {
    if (response.totalCount === 0) {
      setSearchResults([]);
    }

    window.PubSub.publish('hideEditorialGrid', {
      comp: 'noSearch',
      hide: response.totalCount === 0,
    });

    props.publishSearchData({
      searchTerm: queryParams.q,
      resultsCount: response.totalCount,
      correctedQuery: response.correctedQuery,
      noResult: !response.totalCount,
    });

    analyticsTracker(response);

    if (response.totalCount !== 0) {
      contentDecorator(response.results, data);
      setSearchResults(response.results || undefined);
      setPageCount(Math.ceil(response.totalCount / resultsPerPage));
      setForcePage(decodeQueryString().page - 1);
    } else if (data.tenantId !== 'ALLB2B') {
      displayAdvancedSearchData();
    }
  };

  /* Advanced search results call to coveo*/

  const displayAdvancedSearchData = () => {
    setupConfigDataCoveo(data);
    if (data.additionalProperty.lastDaysPeriod) {
      raiseSearchQueryAdvancedSearch()
        .then(resolveAdvancedSearchExecutor)
        .catch((error) => {
          const errorMsgData = getErrorMap('searchService', {}, false, error.error, {});
          setError(errorMsgData);
        });
    }
  };

  /**
   * resolveAdvancedSearchExecutor, is callback function when promise is resolved
   */
  const resolveAdvancedSearchExecutor = (response) => {
    const results = response.combinations.map((val) => {
      return {
        [data.coveoKeyMap.description]: val.documentTitle,
        [data.coveoKeyMap.title]: val.documentTitle,
        [data.coveoKeyMap.url]: val.documentURL,
      };
    });
    contentDecorator(results, data);
    setSearchResults(results.slice(0, data.additionalProperty.n) || undefined);
    setPageCount(0);
    setForcePage(0);
  };

  /**
   * setting up configuration to be used by coveo
   */
  const setupConfigData = () => {
    const { pipeline, searchHub } = getPipeLineAndSearchHubValues();
    let enableDidYouMean = (data.additionalProperty && data.additionalProperty.enableDidYouMean) || true,
      wildcards = (data.additionalProperty && data.additionalProperty.wildcards) || false;
    const regexPattern = /(<([^>]+)>)/gi;
    config = {
      query: queryParams.q?.replace(regexPattern, '') || '',
      perPageResults: resultsPerPage,
      fieldsToInclude: ['@' + data.coveoKeyMap.title, '@' + data.coveoKeyMap.description, data.coveoKeyMap.url],
      coveoKeyMap: data.coveoKeyMap,
      pageNo: --queryParams.page * resultsPerPage || 0,
      serviceUrl: data.serviceUrls.search,
      tenantId: data.tenantId || '',
      language: data.currentLanguage,
      enableDidYouMean: enableDidYouMean,
      wildcards: wildcards,
      pipeline: pipeline,
      searchHub: searchHub,
      referrer: getOrigin(),
      tab: data.additionalProperty && data.additionalProperty.defaultTab,
      showinmobileonly: data.showinmobileonly,
      preLoader: true,
    };
  };

  /**
   * goToNextPage callback function executes onPageChange of pagination component
   */
  const goToNextPage = (data) => {
    //setting up pageNo in config data for raising search query
    config.pageNo = data.selected * resultsPerPage;
    // for Coveo analytics : we are storing info that search was initiated from page links
    const searchInfo = { queryType: 'pageLinks' };
    window.localStorage.setItem('searchInfo', JSON.stringify(searchInfo));

    //firing search query and pushing state only if query string has a value
    if (queryParams.q) {
      const queryString = '?q=' + queryParams.q,
        stateObject = { nextPage: true };

      if (canUseDOM()) {
        const url =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          queryString +
          '&page=' +
          parseInt(data.selected + 1, 10);

        //pushing state to history
        pushState(stateObject, 'search page' + parseInt(data.selected + 1, 10), url);
        let target = document.getElementsByClassName('c-search');
        if (target) {
          window.scrollTo(0, target[0].offsetTop);
          setIsPaginationCall(true);
        }
      }

      //Raising search query again to get next page results
      raiseSearchQuery()
        .then(resolveExecutor)
        .catch((error) => {
          const errorMsgData = getErrorMap('searchService', {}, false, error.error, {});
          setError(errorMsgData);
        });
    }
  };

  try {
    return (
      <div data-c-name="ContentSearchResults" data-c-render="universal" className="component c-content-search-results">
        <div className="w--top">
          {!isEmpty(error) ? <ErrorSummary data={error} /> : null}
          <TextWithCTA data={data.promo} />
        </div>
        <div className="w--middle">
          <div className="w--content">
            <div className="c-content-search-results-wrapper">
              {props.noResultsPage && searchResults.length > 0 ? (
                <h3 className="heading-3 c-content-search-results-wrapper-advanceSearchTitle">
                  {data.mostPopularText}
                </h3>
              ) : null}
              {printSearchResults(searchResults, data)}
            </div>
          </div>
        </div>
        {pageCount > 1 ? (
          <div className="w--bottom">
            <div className="w--content">
              <Pagination
                iconForward={data.additionalProperty && (data.additionalProperty.paginationIconForward || null)}
                iconBackward={data.additionalProperty && (data.additionalProperty.paginationIconBackward || null)}
                forcePage={forcePage}
                pageCount={pageCount}
                onPageChange={goToNextPage}
              />
            </div>
          </div>
        ) : null}
        {isSwad && <div className="container swad-searchLoader"></div>}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ContentSearchResults');
  }
};

/**
 Used to define the proptypes that will be received by the component.
*/

ContentSearchResults.propTypes = {
  data: PropTypes.shape({
    promo: PropTypes.shape({
      bodyCopy: PropTypes.string,
      primaryCTA: PropTypes.shape({ html: PropTypes.string }),
    }),
    coveoKeyMap: PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
    queryText: PropTypes.string,
    resultText: PropTypes.string,
  }),
  noResultPage: PropTypes.bool,
};

export default ContentSearchResults;
