import React from 'react';

import { DynamicContent } from '../../../presentation/base';

const HighlightDate = (props) => {
  const bindDiscounts = () => {
    let { discounts, selectedDiscount, toggleRangeAndDiscount } = props;
    let { checkedDays } = selectedDiscount;
    return discounts.map((discount, idx) => {
      let { days } = discount;
      return (
        <li className="discount--list-items" key={idx}>
          <input
            type="radio"
            name="discount"
            id={'discount' + days}
            onClick={toggleRangeAndDiscount}
            data-value={days}
            value={days}
            defaultChecked={days === checkedDays}
            checked={days === checkedDays}
            onChange={toggleRangeAndDiscount}
            tabIndex={days === checkedDays ? '0' : '-1'}
          />
          <label htmlFor={'discount' + days}>
            <div className="radio"></div>
            <p className="title">
              <DynamicContent tagName="span" attrs={{ className: 'title' }} innerHtml={discount.title} />
            </p>
            {/*<p className="price">
                                        <span className="price--currency">{this.props.data.currency}</span>
                                        <span className="price--value">{Number(addGuest.gross) * idx}</span>
                                    </p>
                                    */}
          </label>
        </li>
      );
    });
  };

  return <ul className="discount--wrapper">{bindDiscounts()}</ul>;
};

export default HighlightDate;
