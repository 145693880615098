import * as types from './book-ticket-constants';

const BookTicketReducer = (namespace) => (BookTicketData, action) => {
  switch (action.type) {
    case `${namespace}-${types.LOAD_PRODUCT_LISTING}`:
      BookTicketData.productListing.products = action.payload.coveoProducts;
      BookTicketData.productListing.remainingCount = action.payload.remainingCount;
      BookTicketData.productFilters.coveoFilter = action.payload.filters;
      BookTicketData.productFilters.allProducts = action.payload.coveoProductsAll;
      return { ...BookTicketData };

    case `${namespace}-${types.LOAD_FILTERED_PRODUCTS}`:
      BookTicketData.productListing.products = action.payload.coveoProducts;
      BookTicketData.productListing.remainingCount = action.payload.remainingCount;
      BookTicketData.productFilters.allProducts = action.payload.coveoProductsAll;
      return { ...BookTicketData };

    case `${namespace}-${types.STORE_USER_DETAILS}`:
      BookTicketData.userDetails = action.payload;
      return { ...BookTicketData };

    case `${namespace}-${types.ADD_PRODUCT}`:
      BookTicketData.productAdded = action.payload;
      return { ...BookTicketData };

    case `${namespace}-${types.LOAD_NEXT_PRODUCTS}`:
      BookTicketData.productListing.products = {
        ...BookTicketData.productListing.products,
        ...action.payload.coveoProducts,
      };
      BookTicketData.productListing.remainingCount = action.payload.remainingCount;
      return { ...BookTicketData };

    case `${namespace}-${types.API_ERROR}`:
      BookTicketData.errorObject = action.payload.errObj;
      return { ...BookTicketData };

    default:
      return { ...BookTicketData };
  }
};

export default BookTicketReducer;
