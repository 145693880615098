import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import { canUseDOM, resolvePath, currentLocale, convertJsDateToMomentObj } from '../../../../common/utility';
import LabelComponent from '../label-component';
import FieldErrorComponent from '../field-error';
import { validateJSSField } from '../rules-validator';
import classNames from 'classnames';
import './calendar.scss';
import { getMainObject } from '../../../../common/utility';

const Calendar = (props) => {
  const { field, tracker, errors } = props;
  const valueFieldName = field.valueField.name;
  const [selectedDateValue, setSelectedDateValue] = useState(null);
  const [openCal, setOpenCal] = useState(false);
  const [tabFocus, setTabFocus] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errors);
  const datePickerRef = useRef(null);

  const mainObj = canUseDOM() && localStorage.mainObj && getMainObject();
  const isYMCTenant = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase() === UIConfig.ymcB2CTenant;

  const handleDateSelection = (date, selectedDate) => {
    let valid = true,
      errorMessages = [];
    const errorMessagesObj = validateJSSField(field, selectedDate);
    const message = errorMessagesObj[valueFieldName];
    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }
    setErrorMsg(errorMessages);
    if (!props.hasDependentField) {
      props.onChange(field.valueField.name, selectedDate, valid, errorMessages);
      tracker.onBlurField(field, selectedDate, errorMessages);
    }
    props.selectDateHandler && props.selectDateHandler(date, { valid, errorMessages });
  };

  const closeCal = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setOpenCal(false);
      setTabFocus(false);
    }
  };

  const setDatePickerRef = (node) => {
    datePickerRef.current = node;
  };

  useEffect(() => {
    if (canUseDOM) {
      document.addEventListener('mousedown', closeCal);
      document.addEventListener('touchstart', closeCal);
    }
    return () => {
      document.removeEventListener('mousedown', closeCal);
      document.removeEventListener('touchstart', closeCal);
    };
  }, []);

  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  useEffect(() => {
    const { apiFieldValue } = props;
    apiFieldValue && updateSelectedDate(moment(apiFieldValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiFieldValue]);

  const updateSelectedDate = (date) => {
    setStartDate(date);
    const selectedDate = moment(date).format(UIConfig.b2c.profile.dateFormat);
    const displayDate = moment(date).format(isYMCTenant ? 'DD MMM yyyy' : UIConfig.calendar.spaceDateFormat);
    setSelectedDateValue(displayDate);
    handleDateSelection(date, selectedDate);
  };

  const calendarCallBack = (date) => {
    date = convertJsDateToMomentObj(date);
    setOpenCal(false);
    updateSelectedDate(date);
  };

  const toggleShowHideCalendar = () => {
    setOpenCal(true);
  };

  const onInputFocus = () => {
    setIsFocus(true);
  };

  const onInputBlur = () => {
    !selectedDateValue && handleDateSelection();
    setIsFocus(false);
  };

  try {
    const { pastDate, futureDate } = UIConfig.jssForms.calender;
    const dateIdentifier = resolvePath(field.model, 'dateIdentifier', '');
    const today = moment();
    let dateObj = {
      maxDate: null,
      minDate: null,
    };
    if (dateIdentifier === pastDate) {
      dateObj.maxDate = today;
    } else if (dateIdentifier === futureDate) {
      dateObj.minDate = today;
    }
    const wrapperClasses = classNames(
      'form-element dob-calendar-wrapper',
      { focused: openCal || tabFocus, active: openCal || selectedDateValue || tabFocus || isFocus },
      'input-type-select-date',
    );
    const isErrorExist = errorMsg && errorMsg.length;
    const inputClasses = classNames('selected-option single', { error: isErrorExist });
    return (
      <div className={`${wrapperClasses} ${field.model.cssClass}`}>
        <LabelComponent field={field} />
        <input
          type="text"
          defaultValue={selectedDateValue}
          maxLength="0"
          onClick={toggleShowHideCalendar}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          tabIndex={0}
          className={inputClasses}
        />
        {(openCal || tabFocus) && (
          <div className="form-element-date-picker" ref={setDatePickerRef}>
            <DatePicker
              calendarClassName={`dob-calendar`}
              selected={startDate == null ? today.toDate() : startDate.toDate()}
              onChange={calendarCallBack}
              yearDropdownItemNumber={110}
              dropdownMode="select"
              showMonthDropdown={true}
              showYearDropdown={true}
              scrollableYearDropdown={true}
              inline
              allowSameDay
              shouldCloseOnSelect
              maxDate={dateObj.maxDate == null ? dateObj.maxDate : dateObj.maxDate.toDate()}
              minDate={dateObj.minDate == null ? dateObj.minDate : dateObj.minDate.toDate()}
              locale={currentLocale()}
            ></DatePicker>
          </div>
        )}
        <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CustomInputDate');
  }
};

export default Calendar;
