import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getNullTracker } from '@uniformdev/tracking';

import { parseQueryString, canUseDOM, getEnvVariablesClient, isUniformEnabled, resolvePath } from './utility';
import { Logging } from './logger';
import { GLOBAL_VARS } from './global-vars';

export default class EventTracking {
  constructor(sc_apikey, fetcher, sc_site) {
    this.sc_apikey = sc_apikey;
    this.fetcher = fetcher;
    this.sc_site = sc_site;
  }

  trackingOptions = () => {
    const envVariables = canUseDOM() && getEnvVariablesClient();
    const siteCoreApiUrl = resolvePath(envVariables, 'envSiteCoreApiUrl');
    const isAkamai = resolvePath(envVariables, 'akamaiEnabled', 'true');

    return {
      host: isAkamai === 'false' ? siteCoreApiUrl : '',
      querystringParams: {
        sc_apikey: this.sc_apikey,
        sc_site: this.sc_site,
      },
      fetcher: this.fetcher,
    };
  };

  submitEvent = (pageEvents) => {
    trackingApi
      .trackEvent([{ eventId: pageEvents }], this.trackingOptions())
      .then((res) => Logging(res, 'Event Tracking', true, 'Page event pushed'))
      .catch((error) => Logging(error, 'Event Tracking', false, 'Page event push failed'));
  };

  submitGoal = (goalId, pageUrl, trackerContext, goalName) => {
    if (trackerContext) {
      const tracker = resolvePath(trackerContext, 'tracker', null) || getNullTracker();
      const visitorId = resolvePath(trackerContext, 'visitorId', null);

      if (resolvePath(GLOBAL_VARS, 'appInsightsObj.appInsights')) {
        GLOBAL_VARS.appInsightsObj.appInsights.trackTrace({
          message: 'Triggering a goal using Uniform tracker: ',
          severityLevel: SeverityLevel.Verbose,
          properties: tracker,
        });
      }

      const goalData = { id: goalId, name: goalName, points: 200 };

      const activity = {
        type: 'goal',
        date: new Date().toISOString(),
        data: goalData,
      };

      try {
        tracker.event('visit-activity', activity, {
          visitorId,
        });
      } catch (error) {
        Logging(error, 'Sitecore Event Tracking', true, 'Error during triggering a goal with Uniform tracker');
      }
    }

    if (!isUniformEnabled() && this.fetcher && this.sc_apikey) {
      trackingApi
        .trackEvent([{ goalId, pageUrl }], this.trackingOptions())
        .then((res) => Logging(res, 'Event Tracking', true, 'Goal pushed'))
        .catch((error) => Logging(error, 'Event Tracking', false, 'Goal push failed'));
    }
  };

  submitOutcome = (monetaryValue, outcomeNameValue) => {
    trackingApi
      .trackEvent(
        [
          {
            outcomeId: outcomeNameValue,
            currencyCode: 'USD',
            monetaryValue: monetaryValue,
          },
        ],
        this.trackingOptions(),
      )
      .then((res) => Logging(res, 'Event Tracking', true, 'Outcome pushed'))
      .catch((error) => Logging(error, 'Event Tracking', false, 'Outcome pushed failed'));
  };

  triggerCampaign = (campaignId) => {
    trackingApi
      .trackEvent([{ campaignId }], this.trackingOptions())
      .then((res) => Logging(res, 'Event Tracking', true, 'Campaign set'))
      .catch((error) => Logging(error, 'Event Tracking', false, 'Campaign set failed'));
  };

  submitPageView = (pageId, url) => {
    const scCamp = url.search && parseQueryString('sc_camp');
    let pageEvents = [{ pageId, url: url.pathname }];

    if (scCamp) {
      pageEvents.push({ campaignId: scCamp });
    }

    trackingApi
      .trackEvent(pageEvents, this.trackingOptions())
      .then((res) => Logging(res, 'Event Tracking', true, 'Page view pushed'))
      .catch((error) => Logging(error, 'Event Tracking', false, 'Page view push failed'));
  };

  abandonSession = () => {
    const abandonOptions = {
      action: 'flush',
      ...this.trackingOptions(),
    };

    trackingApi
      .trackEvent([], abandonOptions)
      .then((res) =>
        Logging(res, 'Event Tracking', true, 'Interaction has been terminated and its data pushed to xConnect'),
      )
      .catch((error) => Logging(error, 'Event Tracking', false, 'Interaction termination failed'));
  };

  submitBatching = (data) => {
    trackingApi
      .trackEvent(
        [
          { eventId: data.eventId },
          { goalId: data.goalId },
          { outcomeId: data.outcomeId },
          { pageId: data.pageId, url: data.url },
          // this goal will be added to the new page/route ID set above, not the current route
          { goalId: data },
        ],
        this.trackingOptions(),
      )
      .then((res) => Logging(res, 'Event Tracking', true, 'Batch of events pushed'))
      .catch((error) => Logging(error, 'Event Tracking', false, 'Batch of events push failed'));
  };
}
