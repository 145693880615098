import { CartService, OrderService } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  checkAnonymousCart,
  getCurrentLanguage,
  getLoggedInUser,
  getPromoCode,
  removePromoCode,
  setOrderIdpromoCode,
  isAnnualPassCase,
  getSessionStorage,
  createAnnualPassData,
  checkInlineSignup,
  getAnonymousCart,
  getMainObject,
  resolvePath,
  checkTenant,
  isLoggedInUser,
  getRecipientDetails,
  isEmpty,
  checkIfGiftVoucherIsInTheCart,
  getLocalStorageByKey,
  isTicketUpgradePayment,
  getAnpGuestDetailsForTuJourney,
  getAnpTicketHoldersForTuJourney,
  backToUpgradePage,
} from '../../../../common/utility';
import { YALocalStorageSvc } from '../../b2c-purchase-journey/my-cart/yasarena-cart-management-service';

export class GPayComponentService {
  constructor(componentData) {
    this.componentData = componentData;
    this.getGPayReconcileData = this.getGPayReconcileData.bind(this);
    this.getPaymentRequest = this.getPaymentRequest.bind(this);
    this._getGuestUserStatus = this._getGuestUserStatus.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.isRenewAnnualPass = componentData.paymentOptions.pageVariant === 'annualPassRenew';
    this.is2StepPaymentYasArena = componentData.is2StepPaymentYasArena;
  }

  formAllowedPaymentMethods = (gpayConfig) => {
    return [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: gpayConfig.allowedAuthMethods,
          allowedCardNetworks: gpayConfig.allowedCardNetworks,
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: gpayConfig.gateway,
            gatewayMerchantId: gpayConfig.gatewayMerchantId,
          },
        },
      },
    ];
  };

  formMerchantinfo = ({ merchantId, merchantName }) => {
    return {
      merchantId,
      merchantName,
    };
  };

  getPaymentRequest() {
    const { gpayConfig } = this.componentData.paymentOptions;
    const { totalPriceStatus, totalPriceLabel, currencyCode, countryCode } = gpayConfig.transactionInfo;
    const apiVersion = gpayConfig.apiVersion;
    const apiVersionMinor = 0;
    const allowedPaymentMethods = this.formAllowedPaymentMethods(gpayConfig);
    const merchantInfo = this.formMerchantinfo(gpayConfig);
    const emailRequired = true;

    const transactionInfo = {
      totalPriceStatus,
      totalPriceLabel,
      currencyCode,
      countryCode,
    };

    return {
      apiVersion,
      apiVersionMinor,
      allowedPaymentMethods,
      merchantInfo,
      emailRequired,
      transactionInfo,
      // shippingOptionRequired: true,
      // shippingAddressRequired: true,
    };
  }

  getGPayReconcileData(cartRes, token, orderData, cardType = null, YIAnonymousUserEmailId) {
    const gpayConfig = this.componentData.paymentOptions.gpayConfig;
    const { currencyCode } = gpayConfig.transactionInfo;
    let userId = YIAnonymousUserEmailId;
    let annualPassRequest = null;
    const isYI = checkTenant(UIConfig.iamMapping.yasisland);
    const isTicketUpgrade = isTicketUpgradePayment();
    const ticketUpgradeData = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);

    if (!isYI && checkAnonymousCart()) {
      if (isTicketUpgrade) {
        userId = ticketUpgradeData?.loggedAccount?.email;
      } else {
        const cart = getAnonymousCart();
        userId = cart.cart.reservationOwner && cart.cart.reservationOwner.email;
      }
    }
    if (isLoggedInUser()) {
      const loggedinUser = getLoggedInUser();
      userId = loggedinUser.email;
    }

    if (!isYI) {
      const isRenewAnnualPass = this.componentData.paymentOptions.pageVariant === 'annualPassRenew';
      const cartData = isTicketUpgrade
        ? getAnonymousCart()
        : isRenewAnnualPass
        ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
        : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart));

      if (cartData) {
        const isAnnualPass = isAnnualPassCase(cartData, true);
        if (isAnnualPass) {
          const {
            apMainPassholderPurchaseEmailTemplateID,
            apMainPassholderRenewEmailTemplateID,
            apAddPassholderNewUserPurchaseEmailTemplateID,
            apAddPassholderNewUserRenewEmailTemplateID,
            apAddPassholderRenewEmailTemplateID,
            apAddPassholderPurchaseEmailTemplateID,
          } = this.componentData.paymentOptions;
          const annualPassTicketHolders = isTicketUpgrade
            ? getAnpTicketHoldersForTuJourney()
            : createAnnualPassData({ cartData });
          annualPassRequest = {
            isAnnualRenew: isRenewAnnualPass,
            emailTemplateIdAnnualPass: isRenewAnnualPass
              ? apMainPassholderRenewEmailTemplateID
              : apMainPassholderPurchaseEmailTemplateID,
            emailTemplateIdWithYasId: isRenewAnnualPass
              ? apAddPassholderRenewEmailTemplateID
              : apAddPassholderPurchaseEmailTemplateID,
            emailTemplateIdWithNoYasId: isRenewAnnualPass
              ? apAddPassholderNewUserRenewEmailTemplateID
              : apAddPassholderNewUserPurchaseEmailTemplateID,
            ticketHolders: annualPassTicketHolders,
          };
        }
      }
    }

    const RequestPayload = {
      returnUrl: `${window.location.origin}/${getCurrentLanguage()}/`,
      amount: orderData.totalPrice || orderData.sale?.total?.gross,
      description: orderData.reservationCode || orderData.sale?.reservationcode?.ak || '',
      reference: orderData.reservationCode || orderData.sale?.reservationcode?.ak || '',
      currency: currencyCode || orderData?.sale?.total?.currency,
      TokenData: token || null,
      cardType,
    };

    const customerDetail = null;
    const shippingDetails = null; // optional
    const ReconcileRequest = {
      locale: getCurrentLanguage(),
      orderId: orderData.orderID || orderData?.sale?.ak,
      emailTemplateId: this.componentData.paymentOptions.emailTemplateId,
      invoiceTemplateId: this.componentData.paymentOptions.invoiceTemplateId,
      paymentMethod: UIConfig.paymentOption.gPay,
      tenantId: getLoggedInUser().tenantID,
      annualPassRequest: annualPassRequest,
      PaymentId: null,
      reservationCode: orderData.reservationCode || orderData.sale?.reservationcode?.ak,
      salesAK: cartRes.salesAK || null,
      userId: userId || ticketUpgradeData?.loggedAccount?.email,
      ...(isYI && {
        cartId: orderData.CartId || null,
        TravelBoxAddPaymentUrl: orderData.TravelBoxAddPaymentUrl?.url || '',
        TravelBoxConfirmBookingUrl: orderData.TravelBoxConfirmBookingUrl?.url || '',
      }),
      isTicketUpgradeRequest: isTicketUpgrade,
    };

    return { ...RequestPayload, customerDetail, shippingDetails, ReconcileRequest };
  }

  _getGuestUserStatus = () => {
    const cart = getAnonymousCart();
    if (cart) {
      return cart;
    }
    return false;
  };

  //Creating Order in omini
  createOrder = async (cartData, partnerRefernce, dueDate) => {
    const cart = {
      cart: { ...cartData },
    };
    const getPaymentInfo = window.localStorage.payment_info && JSON.parse(window.localStorage.payment_info);
    const updatePaymentOptionsTnc = {
      ...getPaymentInfo.cmsData.paymentOptions,
      tncType: this.componentData.paymentOptions.tncType,
      tncCondition: this.componentData.paymentOptions.tncCondition,
    };
    const updatePaymentInfo = {
      ...getPaymentInfo,
      cmsData: {
        ...getPaymentInfo.cmsData,
        paymentOptions: updatePaymentOptionsTnc,
      },
    };

    localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(updatePaymentInfo));
    const { createAnnonymousOrder, createOrderYasId, ticketUpgrades, checkTermsCtdnApi } = this.componentData.services;
    const serviceUrl = checkInlineSignup() ? createOrderYasId.url : createAnnonymousOrder.url;

    if (this.is2StepPaymentYasArena) {
      const orderDetails = YALocalStorageSvc.getSetOrderDetails();
      if (Object.keys(orderDetails).length > 0) {
        return await { data: orderDetails };
      }
    }

    const handleThen = (response) => {
      const userPromoCode = isTicketUpgradePayment()
        ? response?.data?.sale?.reservationcode?.ak
        : `${response.data.reservationCode}_${getPromoCode()}`;
      setOrderIdpromoCode(userPromoCode);
      removePromoCode();
      this.is2StepPaymentYasArena && YALocalStorageSvc.getSetOrderDetails(response.data);
      if (isTicketUpgradePayment()) {
        if (response?.data?.sale?.ak) {
          localStorage.setItem(UIConfig.parksTicketUpgrade.cartTypeLocalStorage, UIConfig.parksTicketUpgrade.cartType);
          return response;
        } else {
          return backToUpgradePage();
        }
      } else {
        return response;
      }
    };

    if (isTicketUpgradePayment()) {
      const tenantId = getLoggedInUser().tenantID || resolvePath(getMainObject(), 'tenantID');
      const ticketUpgradesUrl = ticketUpgrades?.url?.replace('{0}', tenantId);
      let ticketUpgradePayload = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);
      const yasId = getLoggedInUser()?.yasId;
      if (yasId && ticketUpgradePayload && ticketUpgradePayload.yasId === null) {
        ticketUpgradePayload.loggedAccount.yasId = yasId;
        ticketUpgradePayload.yasId = yasId;
      }
      if (ticketUpgradePayload?.productItemType === UIConfig.parksTicketUpgrade.annualPassType) {
        const guestDetails = getAnpGuestDetailsForTuJourney();
        if (guestDetails && guestDetails.length > 0) {
          ticketUpgradePayload.items.map((obj, index) => {
            return (obj.ticketHolderDetails = guestDetails[index]);
          });
        }
      }

      return await OrderService.createTicketUpgradeOrder(
        ticketUpgradesUrl,
        UIConfig.loader.defaultPreLoaderTarget,
        ticketUpgradePayload,
        checkTermsCtdnApi?.url,
      ).then(handleThen);
    } else {
      if (checkAnonymousCart()) {
        cart.cart.reservationOwner =
          this._getGuestUserStatus().cart.reservationOwner !== null
            ? this._getGuestUserStatus().cart.reservationOwner
            : null;
        let mainObj = getMainObject(),
          autoLoginStatus = resolvePath(mainObj, 'additionalProperty.enableAutoLogin'),
          autoLoginParam = `isAutoLogin=${autoLoginStatus}`,
          extraParams;

        if (autoLoginStatus) {
          extraParams = autoLoginParam;
        }

        const recipientDetails = getRecipientDetails();
        if (recipientDetails && !isEmpty(recipientDetails) && checkIfGiftVoucherIsInTheCart(cart?.cart))
          cart.cart.personalisedGiftObj = recipientDetails;
        else delete cart?.cart?.personalisedGiftObj;
        return await OrderService.createAnnonymousOrder(
          'GooglePay',
          serviceUrl,
          UIConfig.loader.defaultPreLoaderTarget,
          cart,
          extraParams,
          this.componentData.services.updateCart,
        ).then(handleThen);
      } else {
        const recipientDetails = getRecipientDetails();
        if (recipientDetails && !isEmpty(recipientDetails) && checkIfGiftVoucherIsInTheCart(cart?.cart))
          await CartService.updateCart(
            {
              cart: { ...cart?.cart },
            },
            'Gpay update cart',
            this.componentData.services.updateCart.url,
          );
        return await OrderService.createOrder(
          'GooglePay',
          this.isRenewAnnualPass
            ? this.componentData.services.renewPass.url
            : this.componentData.services.createOrder.url,
          UIConfig.loader.defaultPreLoaderTarget,
          partnerRefernce,
          dueDate,
          null,
          this.isRenewAnnualPass,
          this.componentData.services.getGuestProfile && this.componentData.services.getGuestProfile.url,
          null,
          this.componentData.services.updateCart,
          this.componentData.services,
        ).then(handleThen);
      }
    }
  };
}

GPayComponentService.displayName = 'GPayService';
