/*
 * my-preferences-component.js
 * This file contains code for My Preference for Guest Users
 * @licensor  Miral
 */

import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Button, Radio, InputRange, Select, ErrorSummary, DynamicContent } from '../../presentation/base/';
import { PartnerApi } from '../../../common/partner-api';
import ApiWrapper from '../../../common/api-wrapper';
import {
  differenceByKey,
  deepCloneObject,
  getErrorMap,
  getLeisureFacility,
  resolvePath,
  checkTenant,
  getLoggedInUser,
  getCookie,
  getLanguageWithoutLocal,
  checkIfParks,
  isMatchTenant,
  checkGA4Tenants,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { ProfileServices } from '../../../common/services';
import { Logging, logComponentRenderingError } from '../../../common/logger';
import { getLanguage } from '../../../utils/langUtils';
import GTMData from '../b2c-purchase-journey/gtm-data';

export default class MyPreferences extends React.Component {
  mapper = {
    Radio: Radio,
    Checkbox: CheckboxGroup,
    Button: Button,
    Submit: Button,
    Reset: Reset,
    Select: Select,
    RangeFilter: InputRange,
  };

  constructor(props) {
    super(props);
    this.apiData = [];
    this.CMPResponse = [];
    this.initialElementState = {};
    this.CMPInitialElementState = {};
    this.preferences = this.props.data;
    this.state = {
      focus: false,
      errors: null,
      apiStatus: false,
      isNewConsentUser: getCookie('isOldConsentUser') === 'false' ? false : true,
      prefLangPPAD: '',
      prefLangEA: '',
      prefLangYMC: '',
    };
    this.defaultParams = {
      leisureFacility: getLeisureFacility() || '',
      source: this.props.data.source,
    };
    this.defaultPostParams = {
      status: this.props.data.apiStatus,
    };
    this.serviceObj = this.props.data.services;
    this.defaultActiveClass = '';
    this.onFieldFocus = this.onFieldFocus.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
  }

  /**
   * componentDidMount, Send Req to API and process the result or error
   * @param    {Void} function does not accept anything.
   * @return   {Void} function does not return anything.
   */
  onFieldFocus(e) {
    this.setState({ focus: true });
  }
  onFieldBlur(e) {
    this.setState({ focus: false });
  }
  componentDidMount() {
    if (!isExperienceEditorActive()) {
      if (this.serviceObj.GetProfileConsent) {
        this.getCommunicationPreferences();
      }
      ProfileServices.GetPreferenceInfo(
        'Preference Profile',
        this.serviceObj.getPreferences.url,
        true,
        '.c-my-preferences',
        this.props.data.includeLeisure,
      )
        .then((response) => {
          if (response && response.data && response.data.code === 404) {
            this.apiData = [];
          } else {
            const apiData = response.data.preferencesVO;
            this.apiData = apiData;
          }
          this.mapApiToForm(this.apiData);
        })
        .catch((response) => {
          this.setState({
            errors: getErrorMap(
              this.serviceObj.getPreferences.url,
              this.serviceObj.getPreferences.errors,
              false,
              response.error,
              this.state.errors,
            ),
            apiStatus: true,
          });
        });
    }
  }

  getCommunicationPreferences() {
    this.mapCMPApiToForm(this.CMPResponse);
    ProfileServices.GetCMPPreferenceInfo(
      'CMP Preference',
      this.serviceObj.GetProfileConsent.url,
      true,
      '.c-my-preferences',
    )
      .then((response) => {
        if (response && response.data && response.data.consentPurpose) {
          this.CMPResponse = [];
          const purposes = response.data.consentPurpose?.map((item) => ({ ...item, name: Object.keys(item)[0] }));
          this.CMPResponse = purposes;
        }
        const { language_ymc, language_ea, language } = response?.data;
        this.mapCMPApiToForm(this.CMPResponse, language_ymc, language_ea, language);
      })
      .catch((error) => {
        this.setState({
          CMPerrors: getErrorMap(
            this.serviceObj.GetProfileConsent.url,
            this.serviceObj.GetProfileConsent.errors,
            false,
            error,
            this.state.errors,
          ),
          CMPApiStatus: true,
        });
      });
  }

  /**
   * mapApiToForm, after success it will change the current form elements from CMS
   * @param    {apiData} Object of API response
   * @return   {Void} function does not return anything.
   */
  mapApiToForm(apiData) {
    const elements = [];

    [...this.props.data.sections].forEach((section) => {
      if (section.sectionType !== 'communication-prefrence-cmp' && section.sectionType !== 'cmp-submit') {
        const fields = section.fields.map((field) => {
          const newValueObj = this.mapFieldDataWithAPI(field, apiData);
          return { ...field, ...newValueObj };
        });
        elements.push({ ...section, fields });
      }
    });

    //Keep a reference of first created form elements, will be used after reset button
    this.initialElementState = elements;
    //Change the state and create an array of preference object for future PUT request
    this.setState(
      {
        changedElements: elements,
        apiStatus: true,
        errors: getErrorMap(this.serviceObj.updatePreferences.url, {}, true),
      },
      () => {
        this.createPOSTData();
      },
    );
  }

  mapCMPApiToForm(apiData, language_ymc, language_ea, language) {
    const CMPFieldsList = [];
    let isAllSelected = true;
    [...this.props.data.sections].forEach((section) => {
      if (section.sectionType === 'communication-prefrence-cmp' || section.sectionType === 'cmp-submit') {
        const fields = section.fields.map((field) => {
          const newValueObj = this.mapFieldDataWithAPI(field, apiData, true);
          return { ...field, ...newValueObj };
        });
        CMPFieldsList.push({ ...section, fields });
      }
    });

    CMPFieldsList.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === 'Checkbox' && !field.checked) {
          isAllSelected = false;
        }
      });
    });

    this.CMPInitialElementState = CMPFieldsList;
    this.setState(
      {
        cmpChangeElements: CMPFieldsList,
        CMPApiStatus: true,
        CMPerrors: getErrorMap(this.serviceObj.GetProfileConsent.url, {}, true),
        isSelectAllCheckboxChecked: isAllSelected,
        prefLangPPAD: language || getLanguage(),
        prefLangYMC: language_ymc || getLanguage(),
        prefLangEA: language_ea || getLanguage(),
      },
      () => {
        this.createCMPPOSTData();
      },
    );
  }

  /**
   * createPOSTData, create the object that will be used for Update the preferences
   * @param    {Void} function does not accept anything.
   * @return   {Void} function does not return anything.
   */
  createPOSTData() {
    this.formData = [];
    [...this.state.changedElements]
      .map((section) => {
        return { data: section.fields, section: section.sectionType };
      })
      .forEach((fields) => {
        fields.data.forEach((field) => {
          if (field.type === 'Submit' || field.type === 'Reset') {
            return;
          }
          field.type !== 'Checkbox' &&
            field.options.length &&
            field.options.forEach((option) => {
              option.value &&
                this.formData.push({
                  preferenceName: option.value,
                  preferenceValue: option.checkedValue,
                  preferenceType: fields.section,
                  prefId: option.perfId,
                  source: this.defaultParams.source,
                  leisureFacility: this.defaultParams.leisureFacility,
                });
            });

          if (field.type === 'Checkbox') {
            this.formData.push({
              preferenceName: field.apiMapping,
              preferenceValue: field.checked
                ? UIConfig.partnersApi.preferenceChecked
                : UIConfig.partnersApi.preferenceUnChecked,
              preferenceType: fields.section,
              prefId: field.perfId,
              source: this.defaultParams.source,
              leisureFacility: this.defaultParams.leisureFacility,
            });
          }
        });
      });
  }

  createCMPPOSTData() {
    this.CMPFormData = [];

    [...this.state.cmpChangeElements]
      .map((section) => {
        return { data: section.fields, section: section.sectionType };
      })
      .forEach((fields) => {
        fields.data.forEach((field) => {
          if (field.type === 'Submit' || field.type === 'Reset') {
            return;
          }

          if (field.type === 'Checkbox') {
            this.CMPFormData.push({
              [field.name]: field.checked,
              key: field.name,
            });
          }
        });
      });
  }

  /**
   * getSingleCheckBox, create the object that will be used for Update the preferences
   * @param    {field, apiData} form element and API response
   * @return   {Void} function does not return anything.
   */
  mapFieldDataWithAPI(field, apiData, isCMP) {
    const type = field.type;
    /*No Need to check the submit and reset buttons*/
    if (type === 'Submit' || type === 'Reset') {
      return;
    }

    let val = field.value,
      items = [];

    if (isCMP && type === 'Checkbox') {
      const item = (({ label, name }) => ({ value: label, text: name }))(field);
      const apiField = apiData.filter((data) => {
        return data.name === field.name;
      })[0];
      if (apiField) {
        field.perfId = '';
        field.checked = apiField[apiField.name];
        item.checkedValue = apiField[apiField.name]
          ? UIConfig.partnersApi.preferenceChecked
          : UIConfig.partnersApi.preferenceUnChecked;
      } else {
        field.perfId = '';
        field.checked = false;
        item.checkedValue = UIConfig.partnersApi.preferenceUnChecked;
      }
      items.push(item);
      return { options: items, value: val };
    }

    if (type === 'Checkbox') {
      const item = (({ label, name }) => ({ value: label, text: name }))(field);
      const apiField = apiData.filter((data) => {
        return data.preferenceName === field.apiMapping && data.leisureFacility === this.defaultParams.leisureFacility;
      })[0];
      if (apiField) {
        field.perfId = apiField.prefId;
        field.checked = apiField.preferenceValue === UIConfig.partnersApi.preferenceChecked;
        item.checkedValue = apiField.preferenceValue;
      } else {
        field.perfId = '';
        field.checked = false;
        item.checkedValue = UIConfig.partnersApi.preferenceUnChecked;
      }
      items.push(item);
    } else {
      items = [...field.options];
      items.forEach((item) => {
        const apiField = apiData.filter((data) => {
          return data.preferenceName === item.value && data.leisureFacility === this.defaultParams.leisureFacility;
        })[0];
        item.perfId = apiField && apiField.prefId;
        item.checkedValue = apiField
          ? apiField.preferenceValue
          : field.checked
          ? UIConfig.partnersApi.preferenceChecked
          : UIConfig.partnersApi.preferenceUnChecked;
      });

      if (field.type === 'RangeFilter') {
        val = items.map((o) => o.checkedValue).indexOf(UIConfig.partnersApi.preferenceChecked) + 1;
      } else {
        const filteredOptions = items.filter((o) => o.checkedValue === UIConfig.partnersApi.preferenceChecked);
        val = Array.isArray(filteredOptions) && filteredOptions.length ? filteredOptions[0].value : val;
      }
    }
    return { options: items, value: val };
  }

  /**
   * onFieldChange, callback for onChange Event
   * @param    {event}
   * @return   {Void} function does not return anything.
   */
  onFieldChange(field, e) {
    let filterdInput;
    const eventAsParam = Object.assign(e, {});
    if (e.type === 'range') {
      filterdInput = this.formData.filter((data) => data.preferenceType === field.apiMapping);
      filterdInput.forEach(
        (data) =>
          (data.preferenceValue =
            data.preferenceName === e.value
              ? UIConfig.partnersApi.preferenceChecked
              : UIConfig.partnersApi.preferenceUnChecked),
      );
    } else {
      if (e.target.type === 'select-one') {
        filterdInput = this.formData.filter((data) => data.preferenceType === field.apiMapping);
        filterdInput.forEach(
          (data) =>
            (data.preferenceValue =
              data.preferenceName === e.target.value
                ? UIConfig.partnersApi.preferenceChecked
                : UIConfig.partnersApi.preferenceUnChecked),
        );
      } else {
        filterdInput = this.formData.filter((data) => data.preferenceName === field.apiMapping);
        filterdInput[0].preferenceValue =
          e.target.value === UIConfig.partnersApi.preferenceChecked
            ? UIConfig.partnersApi.preferenceChecked
            : UIConfig.partnersApi.preferenceUnChecked;
      }
    }
    this.changeFormState(eventAsParam, field);
    this.formData = this.mergeArrays('preferenceName', this.formData, filterdInput);
  }

  onCMPFieldChange(field, e) {
    const eventAsParam = Object.assign(e, {});

    this.CMPFormData.forEach((item) => {
      if (item.key === field.name) {
        item[field.name] = e.target.value === UIConfig.partnersApi.preferenceChecked;
      }
    });
    this.changeCMPFormState(eventAsParam, field);
  }
  /*
   * getInput, sets a new object holdin value and checkedValue
   * @param    {event}
   * @return   {Obj} {value,checkedValue}
   */
  getInput(e, field) {
    if (e.target.type === 'select-one') {
      return e.target.value;
    } else {
      return { value: field.apiMapping, checkedValue: e.target.value };
    }
  }

  /**
   * changeFormState, change the elements array with new value and set in the state to re-render
   * @param    {event}
   * @return   {Void} function does not return anything.
   */
  changeFormState(event, selectedField) {
    const value = event.type === 'range' ? event.value : this.getInput(event, selectedField),
      newStateOfForm = deepCloneObject(this.state.changedElements);
    newStateOfForm.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === 'Submit' || field.type === 'Reset') {
          return;
        }
        if (field.type === 'Checkbox') {
          if (field.apiMapping === value.value) {
            field.options[0].checkedValue = value.checkedValue;
            field.checked = value.checkedValue === UIConfig.partnersApi.preferenceChecked;
          }
        } else if (field.type === 'RangeFilter') {
          field.options.forEach((option) => {
            if (option.value === value) {
              let index = field.options.map((f) => f.value);
              index = index.indexOf(value) + 1;
              option.checkedValue = UIConfig.partnersApi.preferenceChecked;
              field.value = index;
            } else {
              option.checkedValue = UIConfig.partnersApi.preferenceUnChecked;
            }
          });
        } else {
          field.options.forEach((option) => {
            if (option.value === value) {
              field.value = value;
            }
          });
        }
      });
    });

    this.setState({
      changedElements: newStateOfForm,
    });
  }

  changeCMPFormState(event, selectedField) {
    const value = event.target.value,
      newStateOfForm = deepCloneObject(this.state.cmpChangeElements);
    let isAllSelected = true;
    newStateOfForm.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === 'Submit' || field.type === 'Reset') {
          return;
        }
        if (field.type === 'Checkbox') {
          if (field.name === selectedField.name) {
            field.options[0].checkedValue = value;
            field.checked = value === UIConfig.partnersApi.preferenceChecked;
          }
        }
      });
    });

    newStateOfForm.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === 'Checkbox' && !field.checked) {
          isAllSelected = false;
        }
      });
    });

    let selectStateChange = { cmpChangeElements: newStateOfForm, isSelectAllCheckboxChecked: isAllSelected };
    if (event?.target?.id === 'language') {
      selectStateChange = {
        ...selectStateChange,
        prefLangPPAD: event.target.value,
        prefLangYMC: event.target.value,
        prefLangEA: event.target.value,
      };
    }
    this.setState(selectStateChange);
  }

  /**
   * onSubmit, POST and PUT logic for the preferences array
   * @param    {event}
   * @return   {Void} function does not return anything.
   */

  onSubmit = (e) => {
    e.preventDefault();
    const toPOSTRequest = this.formData.filter(differenceByKey(this.apiData, 'preferenceName')),
      differenceInKeys = toPOSTRequest.map((req) => req.preferenceName),
      filteredFormData = this.formData.filter((data) => differenceInKeys.indexOf(data.preferenceName) < 0),
      toPUTRequest = this.mergeArrays('preferenceName', this.apiData, filteredFormData);

    let paramsPut, paramsPost;
    const promiseArr = [];

    if (toPOSTRequest.length) {
      paramsPost = this.getParam('POST', toPOSTRequest);
      promiseArr.push(
        ProfileServices.CreatePreferenceInfo(
          'Preference',
          this.serviceObj.createPreferences.url,
          paramsPost,
          true,
          '.c-my-preferences',
          this.props.data.includeLeisure,
        ),
      );
    }

    if (toPUTRequest.length) {
      toPUTRequest.forEach((req) => {
        req = Object.assign(req, this.defaultPostParams);
        const isPrefIdEmpty = req && req.prefId;
        if (!isPrefIdEmpty) {
          const findItemWithPrefName =
            this.apiData &&
            this.apiData.length &&
            this.apiData.find((item) => `${item.preferenceName}` === req.preferenceName);

          if (findItemWithPrefName && findItemWithPrefName.prefId) req.prefId = `${findItemWithPrefName.prefId}`;
        }
      });
      paramsPut = this.getParam('PUT', toPUTRequest);
      promiseArr.push(
        ProfileServices.UpdatePreferenceInfo(
          'Preference',
          this.serviceObj.updatePreferences.url,
          paramsPut,
          true,
          '.c-my-preferences',
          this.props.data.includeLeisure,
        ),
      );
    }

    Promise.all(promiseArr)
      .then((responses) => {
        this.setState(
          {
            errors: getErrorMap(this.serviceObj.updatePreferences.url, {}, true),
            apiStatus: true,
          },
          () => {
            const dataSet1 = (responses[0].data && responses[0].data.preferencesVO) || [];
            Logging(
              responses[0],
              responses[0].config && responses[0].config.moduleName
                ? responses[0].config.moduleName
                : 'my-preferences-save1',
              true,
              'my preferences 1 saved',
            );
            let finalDataSet = [];
            if (responses.length > 1) {
              Logging(
                responses[1],
                responses[1].config && responses[1].config.moduleName
                  ? responses[1].config.moduleName
                  : 'my-preferences-save2',
                true,
                'my preferences 1 saved',
              );
              const dataSet2 = (responses[1].data && responses[1].data.preferencesVO) || [];
              finalDataSet = dataSet1.concat(dataSet2);
            } else {
              finalDataSet = dataSet1;
            }
            this.apiData = finalDataSet;
            this.formData = finalDataSet;

            this.initialElementState = deepCloneObject(this.state.changedElements);
          },
        );
        if (checkIfParks()) {
          GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
            name: this.props?.data?.formName || this.props?.data?.formType || '',
          });
        }
      })
      .catch((response) => {
        this.setState({
          errors: getErrorMap(
            this.serviceObj.updatePreferences.url,
            this.serviceObj.updatePreferences.errors,
            false,
            response.error,
            this.state.errors,
          ),
          apiStatus: true,
        });
      });
  };

  onCMPSubmit = (e) => {
    e.preventDefault();
    const { yasId, email, tenantID } = getLoggedInUser();
    const { NewsletterSubscriptionAPI } = this.serviceObj;
    let cmpData = {
      emailId: email,
      myPassId: yasId,
      channelName: tenantID,
      language: getLanguageWithoutLocal(),
      consentPurpose: this.CMPFormData?.map(({ key, ...keepAttrs }) => keepAttrs),
    };
    if (checkTenant(UIConfig.iamMapping.etihadarena)) {
      cmpData.language_ea = document.getElementById('language') && document.getElementById('language').value;
    }
    if (checkTenant(UIConfig.iamMapping.ymc)) {
      cmpData.language_ymc = document.getElementById('language') && document.getElementById('language').value;
    }
    ApiWrapper.apiGateway({
      url: NewsletterSubscriptionAPI.url,
      method: 'POST',
      data: cmpData,
      preLoader: true,
      preLoaderTarget: '.c-my-preferences',
    }).then((response) => {
      if (checkGA4Tenants([UIConfig.tenants.yi], [UIConfig.tenants.ya])) {
        GTMData.push(UIConfig.ga4Constants.FORM_COMPLETE, {
          name: this.props?.data?.formName || this.props?.data?.formType || '',
        });
      }
    });
  };
  /**
   * getParam, change the parameter as per PUT/POST
   * @param    {type, data}, type is PUT/POST, data is full preferences data
   * @return   {params} return params object having the required header key from PartnerAPI config file
   */
  getParam(type, data) {
    const params = {};

    if (type === 'PUT') {
      params[PartnerApi.getGuestPreferences.putHeader] = [...data];
    } else {
      params[PartnerApi.getGuestPreferences.postHeader] = [...data];
    }

    return params;
  }

  /**
   * onReset, change the state to first time created props
   * @param    {Void}
   * @return   {Void}
   */
  onReset = (e) => {
    e.preventDefault();
    this.setState({ changedElements: this.initialElementState, reset: new Date() }, () => {
      this.createPOSTData();
    });
  };

  /**
   * mergeArrays, merge two arrays of preference with replace or add
   * @param    {Void}
   * @return   {Void}
   */
  mergeArrays(key, arrays) {
    const array = [];
    const groups = new Map(); // key => [pos in array, [array, of, objects, with, the, same, key]]

    for (let i = 1; i < arguments.length; ++i) {
      for (let j = 0; j < arguments[i].length; ++j) {
        const element = arguments[i][j];
        if (element.hasOwnProperty(key)) {
          const keyValue = element[key];
          if (groups.has(keyValue)) {
            groups.get(keyValue)[1].push(element);
          } else {
            array.push(element);
            groups.set(keyValue, [array.length - 1, []]);
          }
        } else {
          array.push(element);
        }
      }
    }

    for (const group of groups) {
      if (group[1][1].length === 0) continue;
      array[group[1][0]] = Object.assign.apply(Object, [{}, array[group[1][0]]].concat(group[1][1]));
    }
    return array;
  }

  checkPrfLang = () => {
    if (checkTenant(UIConfig.iamMapping.etihadarena)) {
      return this.state.prefLangEA;
    }
    if (checkTenant(UIConfig.iamMapping.ymc)) {
      return this.state.prefLangYMC;
    }
    return '';
  };

  /**
   * formBuilder, will create the Form from the elements array
   * @param    {mapper, fields} mapper object, and fields array
   * @return   {Void} JSX for Form Field
   */
  formBuilder(mapper, sections, reset, index, isCMP) {
    const onFieldFocus = this.onFieldFocus;
    const onFieldBlur = this.onFieldBlur;
    const form =
      Array.isArray(sections[index].fields) &&
      sections[index].fields.map((field, i) => {
        const Field = this.mapper[field.type];
        if ((field.type === 'Select' && field.value) || this.state.focus) {
          field.active = 'active';
        } else {
          field.active = '';
        }
        if (field.type === 'Select' && isCMP) {
          field.value = this.checkPrfLang();
        }
        if (Field) {
          return (
            <Field
              key={i}
              data={field}
              onChange={isCMP ? this.onCMPFieldChange.bind(this, field) : this.onFieldChange.bind(this, field)}
              reset={reset}
              onReset={this.onReset}
              controlled={true}
              onFocus={onFieldFocus}
              onBlur={onFieldBlur}
            />
          );
        }
        return null;
      });
    return form;
  }

  onSelectAllCheckboxChange(e) {
    const value = e.target.checked;
    const newStateOfForm = deepCloneObject(this.state.cmpChangeElements);
    newStateOfForm.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === 'Submit' || field.type === 'Reset') {
          return;
        }
        if (field.type === 'Checkbox') {
          field.options[0].checkedValue = value
            ? UIConfig.partnersApi.preferenceChecked
            : UIConfig.partnersApi.preferenceUnChecked;
          field.checked = value === UIConfig.partnersApi.preferenceChecked;
        }
      });
    });

    this.setState({
      cmpChangeElements: newStateOfForm,
      isSelectAllCheckboxChecked: value,
    });

    this.CMPFormData.forEach((item) => {
      item[item.key] = value;
    });
  }

  selectAllCheckbox() {
    const { isSelectAllCheckboxChecked } = this.state;
    return (
      <fieldset className="cmp-select-all-checkbox">
        <div className="radio-group form-element form-checkbox">
          <label className="checkbox-label" key="select-all-checkbox">
            <input
              className="body-copy-3"
              name="select_all"
              onChange={this.onSelectAllCheckboxChange.bind(this)}
              type="checkbox"
              value={isSelectAllCheckboxChecked ? 'Y' : 'N'}
              checked={isSelectAllCheckboxChecked}
            />
            <i className="form-checkbox-fancy"></i>
            <DynamicContent
              tagName="span"
              innerHtml={this.props.data.extraParams.allCom}
              attrs={{ className: 'body-copy-5' }}
            />
          </label>
        </div>
      </fieldset>
    );
  }

  buildCMPForm() {
    const CMPSection = this.state.cmpChangeElements || [];
    const CMPformFields = [];
    const { userName } = getLoggedInUser();
    Array.isArray(CMPSection) &&
      CMPSection.forEach((section, i) => {
        CMPformFields.push(
          <div className={`fieldsets communication-preference ${section.sectionClass}`} key={i}>
            <fieldset>
              <DynamicContent
                tagName="div"
                attrs={{ className: 'fieldset-title' }}
                innerHtml={section.sectionTitle.replace('{username}', `<span>${userName}</span>`)}
              />
              <div className="fieldset-inner">
                {i === 0 && this.selectAllCheckbox()}
                {section.subtitle && <p className="">{section.subtitle}</p>}
                {this.formBuilder(this.mapper, CMPSection, this.state.reset, i, true)}
              </div>
            </fieldset>
          </div>,
        );
      });

    return CMPformFields;
  }

  render() {
    try {
      if (!this.state.apiStatus) {
        return null;
      }
      const { data } = this.props;
      const sections = this.state.changedElements || [];
      const isSwad = checkTenant(UIConfig.iamMapping.swad);
      const isEA = checkTenant(UIConfig.iamMapping.etihadarena);
      const isYMC = checkTenant(UIConfig.iamMapping.ymc);
      const isQAW = checkTenant(UIConfig.iamMapping.ppad);
      const isNotPark = isEA || isYMC || isQAW;
      let isNewConsentUser = this.state.isNewConsentUser;
      const oldConsentUser = isNewConsentUser ? 'old-consent-user' : '';
      const fields = [];
      const CMPformFields = (isNewConsentUser || isNotPark) && this.buildCMPForm();

      Array.isArray(sections) &&
        sections.forEach((section, i) => {
          fields.push(
            <div className={`fieldsets ${section.sectionClass} section-${i}`} key={i}>
              <fieldset>
                <DynamicContent
                  tagName="div"
                  attrs={{ className: 'fieldset-title' }}
                  innerHtml={section.sectionTitle}
                />
                <div className="fieldset-inner">
                  {/* <legend className="body-1">{section.sectionTitle}</legend> */}
                  {section.subtitle && <p className="">{section.subtitle}</p>}
                  {this.formBuilder(this.mapper, this.state.changedElements, this.state.reset, i)}
                </div>
              </fieldset>
            </div>,
          );
        });
      const renderPreferenceTitle = () => {
        const renderTitle = (
          <DynamicContent tagName="div" attrs={{ className: 'preference-title' }} innerHtml={data.title} />
        );
        if (isSwad) {
          return !this.state.errors && renderTitle;
        } else {
          return renderTitle;
        }
      };
      return (
        <div
          data-c-name="c-my-preferences"
          data-c-render="client"
          className={`component c-form c-my-preferences ${data.className}`}
        >
          {(isNewConsentUser || isNotPark) && (
            <div className="w--content c-form-wrapper cmp-preference">
              <form
                noValidate="novalidate"
                className={`cmp-preference-form ${oldConsentUser}`}
                onSubmit={this.onCMPSubmit}
                method={data.method}
              >
                {CMPformFields}
              </form>
            </div>
          )}

          {this.state.errors && <ErrorSummary data={this.state.errors} />}
          <div className="w--content c-form-wrapper">
            {renderPreferenceTitle()}
            <form
              /* role="form" */
              noValidate="novalidate"
              name="my-preference-form"
              className="my-preference-form"
              onSubmit={this.onSubmit}
              onReset={this.onReset}
              autoComplete={data.autoComplete}
              method={data.method}
            >
              {fields}
            </form>
          </div>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'MyPreferences', this.props.data.variant);
    }
  }
}

/**
 * CheckboxGroup, that will create checkbox group from the options array
 * @param    {boxes, onChange} function does not accept anything.
 * @return   {Void} JSX of CheckBox Group
 */
function CheckboxGroup({ data, onChange }) {
  return (
    <div className="radio-group form-element form-checkbox">
      {data.options.map((opt, i) => {
        return (
          <label className="checkbox-label" key={opt.value.toString()}>
            <input
              className="body-copy-3"
              name={data.name}
              onClick={updateValue}
              onChange={onChange}
              type="checkbox"
              value={opt.checkedValue}
              checked={opt.checkedValue === UIConfig.partnersApi.preferenceChecked}
            />
            <i className="form-checkbox-fancy"></i>
            <DynamicContent tagName="span" innerHtml={data.label} attrs={{ className: 'body-copy-5' }} />
          </label>
        );
      })}
    </div>
  );
}

const updateValue = (e) => {
  if (!e.target.checked) {
    e.target.value = UIConfig.partnersApi.preferenceUnChecked;
  } else {
    e.target.value = UIConfig.partnersApi.preferenceChecked;
  }
};

function Reset(props) {
  return (
    <div className="text-center">
      <div
        className={`btn reset-button ${
          resolvePath(JSON.parse(localStorage.getItem('mainObj')), 'tenantID', '').toLowerCase() !== UIConfig.YIB2C
            ? 'btn-primary'
            : ''
        }`}
      >
        <button onClick={props.onReset}>
          <span>{props.data.label}</span>
        </button>
      </div>
    </div>
  );
}
