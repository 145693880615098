import React, { useState, useCallback, useEffect } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import MasonryGridItem from './masonry-grid-item';
import { detectMobile } from '../../../../common/utility';

const MasonryGrid = ({ images, columnLayout }) => {
  const [currentImage, setCurrentImage] = useState({ img: 0, open: false, photo: '', title: '' });
  const isMobile = detectMobile();
  const imagesTiles = images.map((item, index) => ({
    src: isMobile ? item.imageInfo.tabletImage.src : item.imageInfo.desktopImage.src,
    index: index,
    title: item.bodyCopy,
  }));

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage({ img: index, open: true, photo: photo.src, title: imagesTiles[index].title });
  }, []);

  return (
    <div className="v-editorialgrid-gallary-view">
      <MasonryGridItem
        photos={imagesTiles}
        onClick={openLightbox}
        columnLayout={columnLayout}
        key={columnLayout}
        mobile={isMobile}
      />
      <ModalGateway>
        {currentImage.open && (
          <Modal onClose={() => setCurrentImage({ img: 0, open: false })}>
            <Carousel
              currentIndex={currentImage.img}
              views={imagesTiles.map((x) => ({
                ...x,
                caption: x.title,
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
};

export default MasonryGrid;
