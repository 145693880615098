import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import moment from 'moment';

import UIConfig from '../../../../common/UIConfig';
import { Calendar, DynamicContent, ErrorSummary, AnchorLink } from '../../../presentation/base';
import CounterComponent from '../visitors-count-selector/visitors-count-selector-component';
import { PerformanceService, PromotionService } from '../../../../common/services';
import DropDown from '../../../presentation/drop-down';
import { secProds } from '../add-ons/add-ons-utility';
import HighlightDate from '../highlight-date/highlight-date-component';
import {
  toTwoDecimalPlaces,
  deepCloneObject,
  momentTimezone,
  getValidStartDate,
  canUseDOM,
  resolvePath,
  isDesktopViewPort,
  detectMobile,
  isEmpty,
  decodeHtmlEntity,
  getClientUtcOffset,
  checkTenant,
  isMatchTenant,
} from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

import * as helper from '../add-ons-overlay/helper';
import { TotalAmount, AmountWithButtons, CalendarLabels, LineSeparator, ImportantNotes } from '../common-components';
import GTMData from '../gtm-data';

export default class CalenderOverlayComponent extends Component {
  constructor(props) {
    super(props);
    this.getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    this.isSWAD = resolvePath(this.getMainObj, 'tenantID', '').toLowerCase() === UIConfig.SWADB2C;
    this.isFWAD = resolvePath(this.getMainObj, 'tenantID', '').toLowerCase() === UIConfig.tenants.fwad;
    this.overlaySelAvailable = false;
    this.additionalProducts = [];
    this.validOnRange = [];
    this.isAdultChildOverlay = this.props.data.isAdultChildOverlay;
    this.isQuantityInOverlay = this.props.data.isQuantityInOverlay;
    this.isAddOnExperienceOverlay = this.props.data.isAddOnExperienceOverlay;
    this.isTotalAvailabilityCal = this.props.data.enableTac;
    this.controls = this.props.data.controls;
    this.productOverlaySelector = this.props.productOverlaySelector || {};
    this.isMultiProductCal = this.productOverlaySelector.isMultiProductCal || false;
    this.enableDynamicCalendar = this.props.enableDynamicCalendar || false;
    this.disableTotalPrice = this.props.disableTotalPrice || false;
    this.legends = this.props.legends || [];
    this.showProductNameInOverlay = this.props.showProductNameInOverlay || false;
    this.overlaySelMap = !this.isQuantityInOverlay ? this.getOverlaySelMapping() : null;
    this.counterData = this.props.counterData;
    this.isDynamicPricingProd = this.props.data.variant === 'v-dynamic-product-pricing';
    this.flexibleMoneyCard = false;
    this.maxCount = this.props.counterData.maxCount;
    this.isExpeditionProduct =
      this.props?.productOverlaySelector?.collections?.length > 1 &&
      this.props?.data?.type === 'expeditions' &&
      this.isSWAD;
    this.bookingOverlay = !this.isExpeditionProduct;
    this.selectedTicketTimeSlot = [];
    this.calendarData =
      this.props.calenderData && this.props.calenderData.options && this.props.calenderData.options[0].calendarSettings;
    this.defaultSelAdditionalProds = this.props.additionalProds ? this.getDefaultAdditionalProd() : false;
    this.isOverlaySelectionLength =
      this.productOverlaySelector &&
      Array.isArray(this.productOverlaySelector.collections) &&
      this.productOverlaySelector.collections.length > 1;
    //VIP Product(Shared and Private) in one popup
    this.isVIPPrivateOrShared =
      this.props?.productOverlaySelector?.collections?.length &&
      this.props.subCategory === UIConfig.ProductType.VIP &&
      checkTenant(UIConfig.iamMapping.fwad);
    this.product = this.defaultSelAdditionalProds
      ? this.defaultSelAdditionalProds
      : deepCloneObject(this.props.product);
    if (this.isDynamicPricingProd) this.product.gross = 0;
    this.prodQty = [];
    this.isPJOnly = this.props.data.cssClass === 'v-multi-product-widget';
    this.daysDelta = 0;
    this.tnc = this.checkTnc();
    if (this.isAdultChildOverlay && props.counterData.options.length === this.props.additionalProds.length) {
      this.props.additionalProds.forEach((item, idx) => {
        if (props.counterData.options.length) {
          props.counterData.options[idx].description =
            props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(item.gross));
        }
      });
    } else {
      if (props.data.variant !== UIConfig.dyanmicPricingVariant) {
        const prodPriceLabel = props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(this.product.gross));
        props.counterData.options[0].description = prodPriceLabel;
      }
    }
    if (!this.isQuantityInOverlay) {
      this.calendarSettings = this.props.calenderData.options[0].calendarSettings;
      this.minDate = this.getValidStartDate(this.calendarSettings.startDate).add(
        this.calendarSettings.defaultDateDelta,
        'days',
      );
      if (this.calendarSettings.endDate)
        this.maxDate = momentTimezone(this.calendarSettings.endDate, getClientUtcOffset());
      else if (this.isTotalAvailabilityCal)
        this.maxDate = moment().add(parseInt(this.calendarSettings.range, 10), 'days');
      else
        this.maxDate = momentTimezone(this.calendarSettings.startDate, getClientUtcOffset()).add(
          parseInt(this.calendarSettings.range, 10),
          'days',
        );

      this.validOn = this.calendarSettings.validOn;
    }
    if (this.isQuantityInOverlay) {
      this.ticketValidity = this.props.calenderData && this.props.calenderData.options[0].ticketValidity;
    }
    if (this.validOn) {
      this.validOnRange = this.validOn.split(',').map((x) => +x);
    }
    this.calendarDateChosen = false;
    const category = this.props.product.category;
    this.primaryProd = this.props.product;
    this.slctdSecIdx = 0;
    this.secProds =
      secProds[category] && secProds[category].secondary ? deepCloneObject(secProds[category].secondary) : [];
    this.daysDelta = 0;
    this.licenseAvailable = false;
    this.termsAndCondCheck = false;
    this.updatedAdditionalProds = this.props.additionalProds;
    this.fallBackProducts = {};
    this.isDesktop = isDesktopViewPort();
    this.currentDateSlots = null;
    this.productsArr = [];
    this.defaultValue = {
      defaultDateDelta: 1,
      range: 0,
    };
    this.finalSelectedProducts = [];
    this.enableTimeSlotExperience = this.props.enableTimeSlotExperience;
    this.state = {
      selectedDiscount: {
        highlightRange: [],
        checkedDays: null,
        discountMap: [],
        description: '',
        code: '',
        openToDate: null,
      },
      isVIPPrivateOrShared: false,
      vipCounterData: this.props.counterData,
      selectDefaultDate: false,
      resetonTabChange: false,
      totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
      date: this.minDate,
      visitedDate: null,
      disableAddToCart: true,
      performanceData: {},
      slotSelected: false,
      maxAvailableTickets: '',
      customDates: [],
      modifiedPerformanceData: [],
      existingProductError: false,
      calendarReset: false,
      isMobile: false,
      carProdsClicked: false,
      minDateFromCartData: '',
      cardAmount: '',
      amountMultiplesOfTen: 0,
      selectedAttentions: [],
    };
  }

  confgDfltSetngs = (secProducts) => helper.confgDfltSetngs(this, secProducts);

  checkTnc = () => helper.checkTnc(this);

  getOverlaySelMapping = () => helper.getOverlaySelMapping(this);

  getDefaultAdditionalProd = () => helper.getDefaultAdditionalProd(this);

  scrollToNextSection = (section) => helper.scrollToNextSection(this, section);

  getValidStartDate = (date) => {
    if (this.validOn) {
      const currentDate = momentTimezone(date, getClientUtcOffset());
      return currentDate;
    }
    return getValidStartDate(date);
  };

  redirectToBookingPageCallback = (checkoutContext) => helper.redirectToBookingPageCallback(this, checkoutContext);

  addToCart = (checkoutContext) => helper.addToCart(this, checkoutContext);

  setVisitorsCount = (data) => helper.setVisitorsCount(this, data);

  calculateTotal = () => helper.calculateTotal(this);

  fetchMultiProductPerformance = () => {
    this.productsArr = [];
    this.productDetails = [];
    if (this.isPJOnly) {
      this.props.additionalProds.forEach((prod) => {
        if (prod.productId === this.product.productId) {
          this.productsArr.push(prod.productId);
          this.productDetails.push({
            productId: prod.productId,
            eventAk: prod.eventAks,
          });
        }
      });
    } else if (this.isDynamicPricingProd) {
      this.props.searchProductList
        .filter(
          ({ ticketVal, category, experienceCatgory, productId, ticketType, classType }) =>
            ticketVal &&
            ticketVal === this.product.ticketVal &&
            category &&
            this.product.category &&
            (this.isFWAD && (ticketType === UIConfig.ProductType.VIP || ticketType === UIConfig.ProductType.VVIP)
              ? classType === this.product.classType
              : true) &&
            experienceCatgory &&
            experienceCatgory.toLowerCase() === 'primary' &&
            category[0] === this.product.category[0] &&
            category[0] === this.product.category[0] &&
            (this.isExpeditionProduct ? productId && productId === this.product.productId : true),
        )
        .forEach((prod) => {
          this.productsArr.push(prod.productId);
          this.productDetails.push({
            productId: prod.productId,
            eventAk: prod.eventAks,
          });
        });
    } else {
      this.props.additionalProds.forEach((prod) => this.productsArr.push(prod.productId));
    }

    PerformanceService.getMultiProductPerformanceData(
      this.props.services.getMultiProdPerformance.url,
      this.product.fromDate,
      this.product.toDate || this.product.fromDate,
      this.isDynamicPricingProd ? { productDetail: this.productDetails } : { productId: this.productsArr },
      true,
      '.addOns-Overlay',
    )
      .then((response) => {
        if (response.data.performancelist.hasOwnProperty('performance') && this.isTotalAvailabilityCal) {
          this.createTACData(response.data.performancelist);
        } else {
          this.setState({
            performanceData: { error: { errorcode: '7001' } },
            slotSelected: false,
            disableAddToCart: true,
          });
        }
        this.setState({
          fetchMultiProductPerformance: true,
        });
      })
      .catch((err) => {
        this.setState({
          performanceData: { error: err.error },
          slotSelected: false,
          disableAddToCart: true,
          modifiedPerformanceData: [],
          customDates: [],
          fetchMultiProductPerformance: true,
        });
      });
  };

  getPerformanceProduct = () => {
    const url = this.props.services.getPerformance.url.replace('{0}', this.product.productId);
    PerformanceService.getPerformanceData(url, this.product.fromDate, this.product.fromDate, true, '.addOns-Overlay')
      .then((response) => {
        if (
          response.data.performancelist.hasOwnProperty('performance') &&
          this.isTotalAvailabilityCal &&
          !this.isDynamicPricingProd
        ) {
          this.createTACData(response.data.performancelist);
        } else {
          const availabile =
            response.data.performancelist.hasOwnProperty('performance') &&
            response.data.performancelist.performance.some((timeSlot) => {
              return timeSlot.availability.available > 0 && timeSlot.sellable === 'true';
            });
          if (availabile) {
            this.product.performanceId = '';
            this.product.timeSlot = '';
            if (
              !(this.props.timeSlotData && this.props.timeSlotData.showSingleTimeSlot) &&
              response.data.performancelist.performance.length === 1
            ) {
              this.product.performanceId = response.data.performancelist.performance[0].performanceId;
              this.setState({ slotSelected: true, performanceData: {}, minDateFromCartData: '' }, () =>
                this.calculateTotal(),
              );
            } else {
              this.setState({ performanceData: response.data.performancelist });
            }
            // if (
            //   this.isDynamicPricingProd &&
            //   this.props.timeSlotData &&
            //   !isEmpty(this.props.timeSlotData) &&
            //   this.props.timeSlotData.showSingleTimeSlot
            // ) {
            //   // scroll by default to current month
            //   this.scrollToNextSection('c-date-selector--time-slot');
            // } else {
            //   this.scrollToNextSection('react-datepicker');
            // }
          } else {
            this.setState({
              performanceData: { error: { errorcode: '7001' } },
              slotSelected: false,
              disableAddToCart: true,
              minDateFromCartData: '',
              totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          performanceData: { error: err.error },
          slotSelected: false,
          disableAddToCart: true,
          modifiedPerformanceData: [],
          customDates: [],
          totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
        });
      });
  };

  setDate = (date) => {
    this.product.fromDate = date.locale('en').format(UIConfig.calendar.dateFormat);
    this.calendarDateChosen = this.isTotalAvailabilityCal ? false : true;
    this.setState({
      totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
      date: this.isTotalAvailabilityCal ? null : date,
      disableAddToCart: true,
      performanceData: {},
      slotSelected: false,
      maxAvailableTickets: '',
      carProdsClicked: false,
      selectedTimeSlot: null,
      selectedTicketId: null,
      visitedDate: date,
    });

    if (this.product.hasperformance === '1') {
      if (this.enableDynamicCalendar) {
        this.fetchMultiProductPerformance();
      }
    } else {
      if (this.isDynamicPricingProd && this.calendarSettings.enableDatePreSelection) {
        this.setState({
          selectDefaultDate: true,
        });
      }
    }
  };
  setSelectedDefaultDate = () => {
    this.setState({
      selectDefaultDate: true,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.enablePJPopup) {
      this.calendarSettings = nextProps.calenderData.options[0].calendarSettings;
      this.minDate = this.getValidStartDate(this.calendarSettings.startDate).add(
        this.calendarSettings.defaultDateDelta,
        'days',
      );
      this.maxDate = this.calendarSettings.endDate
        ? momentTimezone(this.calendarSettings.endDate, getClientUtcOffset())
        : momentTimezone(this.calendarSettings.startDate, getClientUtcOffset()).add(
            parseInt(this.calendarSettings.range, 10),
            'days',
          );
      this.setState({
        totalCalculatedAmount: this.isDynamicPricingProd ? parseFloat(0).toFixed(2) : 0,
        date: this.minDate,
        selectedTimeSlot: null,
        selectedTicketId: null,
      });
    }
  }

  componentWillMount() {
    if (
      this.calendarSettings.highlightDate &&
      this.calendarSettings.discountOptions &&
      this.calendarSettings.discountOptions.length > 0
    ) {
      const startDate = this.calendarSettings && this.calendarSettings.startDate;
      const validStartDate = getValidStartDate(startDate);
      const givenStartDate = moment(startDate).isValid()
        ? momentTimezone(startDate, getClientUtcOffset())
        : validStartDate.clone();
      this.calendarSettings = {
        ...this.calendarSettings,
        discountStartDate: validStartDate.add(this.calendarSettings.defaultDateDelta, 'days'),
        discountEndDate: this.calendarSettings.endDate
          ? momentTimezone(this.calendarSettings.endDate, getClientUtcOffset())
          : givenStartDate.add(Number(this.calendarSettings.range) || this.defaultValue.range, 'days'),
        tooltipmap: this.calendarSettings.tooltipmap,
        // bestRate: this.props.calenderData.options[0].bestRate,
        selectDateCta: this.calendarSettings.calendarLabel,
        validOnRange: this.validOnRange,
      };
    }
    this.props.data && this.props.data.isAddOnExperienceOverlay && this.confgDfltSetngs(this.secProds);
    this.calendarSettings.highlightDate &&
      this.calendarSettings.discountOptions &&
      this.calendarSettings.discountOptions.length > 0 &&
      this.manipulateOptions(this.calendarSettings.discountOptions);
  }

  /* toggle range on discount change and discount on date change */
  toggleRangeAndDiscount = (e, clck, selectedDate) => {
    const reset = clck ? false : true;
    let selectedDiscount = this.state.selectedDiscount;
    let { discountMap } = selectedDiscount;
    let highlightWithRanges,
      range,
      checkedDays,
      description,
      code,
      openToDate = null;
    if (selectedDate) {
      let selectedObj = this.getSelectedDiscount(discountMap, { type: 'date', value: selectedDate });
      range = selectedObj.range;
      description = selectedObj.description;
      code = selectedObj.code;
      checkedDays = selectedObj.checkedDays;
      openToDate = selectedObj.openToDate;
    } else if (e && e.target) {
      checkedDays = e && e.target ? parseInt(e.target.dataset.value, 10) : e;
      let isCheckedDays = !(checkedDays === undefined);
      range = isCheckedDays && discountMap.get(checkedDays).range;
      description = isCheckedDays && discountMap.get(checkedDays).description;
      code = isCheckedDays && discountMap.get(checkedDays).code;
    }
    highlightWithRanges = this.getHighlightedWithRanges(range);
    openToDate =
      openToDate ||
      (this.calculateDateValidity(highlightWithRanges[0]['react-datepicker__day--in-range'][0])
        ? highlightWithRanges[0]['react-datepicker__day--in-range'][0]
        : '');
    //this.date = openToDate || this.date;
    const updatedCustomDates = this.state.customDates.map((data) => {
      if (Object.keys(data).includes('react-datepicker__day--in-range')) {
        return {
          'react-datepicker__day--in-range': highlightWithRanges[0]['react-datepicker__day--in-range'],
        };
      }
      return data;
    });
    this.setState({
      selectedDiscount: {
        ...selectedDiscount,
        highlightRange: highlightWithRanges,
        checkedDays: checkedDays,
        description: description,
        openToDate: openToDate,
        code: code,
      },
      resetonTabChange: reset,
      customDates: updatedCustomDates,
    });

    if (reset) {
      const keyboardSelectedDateCssRemove = document.querySelectorAll('.react-datepicker__day--keyboard-selected');
      const selectedDateCssRemove = document.querySelectorAll('.react-datepicker__day--selected');
      this.setState({ slotSelected: false, resetonTabChange: true });
      if (keyboardSelectedDateCssRemove && keyboardSelectedDateCssRemove[0]) {
        keyboardSelectedDateCssRemove[0].classList.remove('react-datepicker__day--keyboard-selected');
      }
      if (selectedDateCssRemove && selectedDateCssRemove[0]) {
        selectedDateCssRemove[0].classList.remove('react-datepicker__day--selected');
      }
      this.state.initialCount &&
        !isEmpty(this.state.initialCount) &&
        this.setVisitorsCount({ spinnerCount: this.state.initialCount, isProductOverlayClicked: true });
    }
  };
  /*create a discountMap */
  createDiscountMap(discounts, data) {
    let discountMap = new Map();
    discounts.forEach((discount, idx) => {
      const startDate = data.discountStartDate.clone();
      const endDate = data.discountEndDate.clone();
      discount.endDays = idx < discounts.length - 1 && discounts[idx + 1].days;
      let startDays = startDate.clone();
      let startRange = startDays.add(discount.days, 'days');
      let endRange =
        idx === discounts.length - 1
          ? endDate.diff(startRange) > 0
            ? endDate
            : startRange
          : startDate.add(discount.endDays - 1, 'days');
      discountMap.set(discount.days, {
        range: [startRange, endRange],
        description: discount.description,
        code: discount.code,
      });
    });
    return discountMap;
  }

  /* validate discount start date in respect of product or offer end date */
  calculateDateValidity(discountStartDate) {
    if (this.calendarSettings.discountEndDate) {
      const { disabledDates } = this.calendarSettings;

      if (
        disabledDates &&
        Array.isArray(disabledDates) &&
        disabledDates.length > 0 &&
        disabledDates.includes(discountStartDate.format(UIConfig.calendar.slashDateFormat))
      ) {
        return false;
      }

      const endDate = momentTimezone(this.calendarSettings.discountEndDate.clone(), getClientUtcOffset()).startOf(
        'day',
      );
      return endDate.diff(discountStartDate.startOf('day'), 'days') > 0;
    }

    return '';
  }
  /* returns object with selected range, day, date */
  getSelectedDiscount(discountMap, selectedInput) {
    let range,
      checkedDays,
      description,
      code,
      openToDate = null;
    discountMap.forEach((value, key) => {
      let input = selectedInput.value;
      if (selectedInput.type === 'discount') {
        if (input === value.code) {
          range = value.range;
          checkedDays = key;
          description = value.description;
          code = value.code;
          openToDate = this.calculateDateValidity(value.range[0]) ? value.range[0] : '';
        }
      } else {
        let currentRange = value && value.range;
        if (
          currentRange &&
          currentRange[0].diff(input, 'days') <= 0 &&
          currentRange &&
          currentRange[1].diff(input, 'days') >= 0
        ) {
          range = currentRange;
          checkedDays = key;
          description = value.description;
          code = value.code;
          openToDate = selectedInput.value;
        }
      }
    });
    this.date = openToDate;
    return { range, checkedDays, description, code, openToDate };
  }
  /* return array of dates from range */
  enumerateDaysBetweenDates = (startDate, endDate) => {
    let dates = [];
    const currDate = momentTimezone(startDate.clone(), getClientUtcOffset()).startOf('day'),
      lastDate = momentTimezone(endDate.clone(), getClientUtcOffset()).startOf('day'),
      disableDates = this.calendarSettings.disabledDates;
    do {
      if (disableDates && disableDates.length && disableDates.indexOf(currDate.format('YYYY/MM/DD')) !== -1) {
        continue;
      }
      dates.push(currDate.clone());
    } while (currDate.add(1, 'days').diff(lastDate) <= 0);

    return dates;
  };
  /*return a range if dates in highlight format with react date picker */
  getHighlightedWithRanges = (highlightedRange) => {
    const highlightWithRanges = [
      {
        'react-datepicker__day--in-range':
          this.calendarSettings.highlightDate && highlightedRange
            ? this.enumerateDaysBetweenDates(highlightedRange[0], highlightedRange[1])
            : [],
      },
    ];
    return highlightWithRanges;
  };
  //*manipulate and set date and discount on mounting of calendar */
  manipulateOptions = (discounts) => {
    const discountMap = this.createDiscountMap(discounts, this.calendarSettings);
    const discountCode = discounts[0] && discounts[0].code;
    const selectedInput = {
      type: 'discount',
      value: this.calendarSettings.defaultSelectorDiscount
        ? this.calendarSettings.defaultSelectorDiscount
        : discountCode,
    };
    const selectedObj = this.getSelectedDiscount(discountMap, selectedInput);
    const highlightWithRanges = this.getHighlightedWithRanges(selectedObj.range);
    this.setState({
      selectedDiscount: {
        ...this.state.selectedDiscount,
        discountMap: discountMap,
        checkedDays: selectedObj.checkedDays,
        highlightRange: highlightWithRanges,
        description: selectedObj.description,
        code: selectedObj.code,
        openToDate: selectedObj.openToDate,
      },
    });
  };
  componentDidMount() {
    this.product = this.defaultSelAdditionalProds
      ? this.defaultSelAdditionalProds
      : deepCloneObject(this.props.product);
    if (this.isDynamicPricingProd) {
      this.product.gross = 0;
    }
    if (this.props.data.variant !== UIConfig.dyanmicPricingVariant) {
      this.props.counterData.options[0].description =
        this.props.data.currency + ' ' + this.product.gross ? toTwoDecimalPlaces(parseFloat(this.product.gross)) : 0;
    } //If amount needs to be shown configure from cms as "0.00"
    if (this.isTotalAvailabilityCal) {
      this.fallBackInitialProducts();
      this.initialOverlayPerformanceCall();
    } else {
      this.minDate && !this.isPJOnly && this.setDate(this.minDate);
    }
    this.setState({
      isMobile: detectMobile(),
      vipCounterData: helper.getVIPCounterData(this, this.product.productId),
      isVIPPrivateOrShared: helper.isVIPPrivateOrShared(this),
    });
    this.isMultiProductCal && this.isTotalAvailabilityCal && canUseDOM() && this.addStyleTagDynamically();
    let newSelectedTicketTimeSlot = [];
    if (this.props.cartData?.cart?.items) {
      Object.values(this.props.cartData.cart.items).forEach((item) => {
        item.products.forEach((product) => {
          newSelectedTicketTimeSlot.push({
            productId: product.productId,
            slot: product.fromDate + product.timeSlot,
            fromDate: product.fromDate,
            timeSlot: product.timeSlot,
          });
        });
      });
      this.selectedTicketTimeSlot = newSelectedTicketTimeSlot;
    }
  }

  addStyleTagDynamically = () => helper.addStyleTagDynamically(this);

  dynamicStylesClasses = () => helper.dynamicStylesClasses(this);

  initialOverlayPerformanceCall = () => helper.initialOverlayPerformanceCall(this);

  createTACData = (perfList) => helper.createTACData(this, perfList);

  defaultDropDownSelection = (isMount, selectedDate) => helper.defaultDropDownSelection(this, isMount, selectedDate);

  calendarDateCallback = (date, isMount, preventScroll) =>
    helper.calendarDateCallback(this, date, isMount, preventScroll);

  timeFormat = (performance) => helper.timeFormat(this, performance);

  timeFormatTac = (performance) => helper.timeFormatTac(this, performance);

  selectTimeSlot = (event, isAutoSelected) => helper.selectTimeSlot(this, event, isAutoSelected, this.isSWAD);

  getPromotionProducts = (isMultiSameTimeSlot = false) => {
    let url = resolvePath(this.props.services, 'getPromotion.url', '');
    if (url) {
      url = url.replace('{0}', this.product.performanceId);
      PromotionService.getPromotionData(url, true, '.addOns-Overlay')
        .then((response) => {
          const perfProduct = response.data.products;
          if (perfProduct && perfProduct.length) {
            this.checkPromotionProduct(perfProduct);
            if (isMultiSameTimeSlot) this.product.productId = perfProduct[0].productId[0];
          } else {
            this.checkDynamicProduct();
          }
        })
        .catch(() => {
          this.checkDynamicProduct();
        });
    }
  };

  filterOutOfferProductsCoveo = (baseProduct) => helper.filterOutOfferProductsCoveo(this, baseProduct);

  filterPromotionProduct = (availablePromProduct, coveoProducts) =>
    helper.filterPromotionProduct(this, availablePromProduct, coveoProducts);

  filterSecondaryPromotionProduct = (coveoSecPromProd, baseProd, availableProProduct) =>
    helper.filterSecondaryPromotionProduct(this, coveoSecPromProd, baseProd, availableProProduct);

  checkPromotionProduct = (availableProProduct) => helper.checkPromotionProduct(this, availableProProduct);

  updatedVisitorCounterPrice = (primaryProduct, idx, baseProduct) =>
    helper.updatedVisitorCounterPrice(this, primaryProduct, idx, baseProduct);

  updateBaseProduct = (prod, isPriceUpdateRequired) => helper.updateBaseProduct(this, prod, isPriceUpdateRequired);

  combineCloneObject = (obj, copyObj) => helper.combineCloneObject(this, obj, copyObj);

  fallBackInitialProducts = () => helper.fallBackInitialProducts(this);

  resetPriceDescriptionForCounter = (selectedDate, performanceData) =>
    helper.resetPriceDescriptionForCounter(this, selectedDate, performanceData);

  resetPriceLabelCounter = (product, idx) => helper.resetPriceLabelCounter(this, product, idx);

  calDateDelta = (date) => helper.calDateDelta(this, date);

  filterPrimaryDynamicProd = (similarDynamicProd, baseProd, days, disableDiscount) =>
    helper.filterPrimaryDynamicProd(this, similarDynamicProd, baseProd, days, disableDiscount);

  filterSecondaryDynamicProd = (similarDynamicProd, baseProd, days, disableDiscount) =>
    helper.filterSecondaryDynamicProd(this, similarDynamicProd, baseProd, days, disableDiscount);

  groupSecondaryProducts = (products) => helper.groupSecondaryProducts(this, products);

  checkDynamicProduct = () => helper.checkDynamicProduct(this);

  renderErrorMsg = () => {
    return (
      <ErrorSummary
        data={{
          error: {
            code: this.state.performanceData.error.errorcode,
            text: this.props.services.getPerformance.errors[this.state.performanceData.error.errorcode],
          },
        }}
      />
    );
  };

  renderTimeSlots = () => {
    const items = [],
      labels = this.props.timeSlotData && this.props.timeSlotData.labels ? this.props.timeSlotData.labels : {};

    this.state.performanceData.performance.forEach((performance) => {
      items.push({
        value: performance.performanceId,
        disabled: performance.sellable === 'false' || performance.availability.available === '0',
        text: `${this.timeFormat(performance)} (${performance.availability.available} ${labels.availableLabel})`,
      });
    });

    const filterAvailableSlots = items.filter((item) => !item.disabled);

    return (
      <div className="c-date-selector--time-slot">
        <DynamicContent tagName="h3" attrs={{ className: 'heading-5' }} innerHtml={labels.title} />
        <DynamicContent
          tagName="p"
          attrs={{ className: 'sub-heading' }}
          innerHtml={this.props.timeSlotData.description}
        />
        <DropDown
          listItems={filterAvailableSlots}
          defaultValue={
            this.product.performanceId
              ? filterAvailableSlots.filter((ele) => ele.value === this.product.performanceId)[0].text
              : labels.dropdownLabel
          }
          callBackFunction={this.selectTimeSlot}
          showSelected="true"
        />
        {this.renderSameTimeWarning()}
        {this.renderSameTimeError()}
      </div>
    );
  };
  renderSameTimeWarning = () => {
    let errorMessage = (
      <ErrorSummary
        data={{
          error: {
            code: UIConfig.errorCodes.timeslotWarning,
            text: this.props.businessErrors[UIConfig.errorCodes.timeslotWarning],
          },
        }}
      />
    );
    if (this.isSWAD && !this.checkTicketHaveSameTimeSlot()) {
      return (
        <div className="swad-warning">
          {this.checkCartHaveSameTimeSlot() ? (
            errorMessage
          ) : !this.checkCartHaveSameTimeSlot() && this.checkCartHaveSameTimeSlotWithGap() ? (
            errorMessage
          ) : (
            <></>
          )}
        </div>
      );
    }
  };
  renderSameTimeError = () => {
    return (
      <>
        {this.isSWAD && this.checkTicketHaveSameTimeSlot() && (
          <ErrorSummary
            data={{
              error: {
                code: UIConfig.errorCodes.invalidTimeSlot,
                text: this.props.businessErrors[UIConfig.errorCodes.invalidTimeSlot],
              },
            }}
          />
        )}
      </>
    );
  };
  renderTimeSlotsTAC = () => {
    const items = [],
      labels = this.props.timeSlotData && this.props.timeSlotData.labels ? this.props.timeSlotData.labels : {};
    this.state.performanceData.performance.forEach((performance) => {
      let timeslotDropdownContent = {
        value: performance.performanceId,
        disabled: performance.sellable === 'false' || performance.availability.available === '0',
      };
      if (this.isSWAD) {
        timeslotDropdownContent.text = `<span class='available-time'>${this.timeFormatTac(
          performance,
        )}</span> <span class='available-seats'>${performance.availability.available} ${
          parseInt(performance.availability.available || '0') ? labels.availableLabel : labels.notAvailableLabel || ' '
        }</span>`;
      } else {
        timeslotDropdownContent.text = `${this.timeFormatTac(performance)} (${performance.availability.available} ${
          parseInt(performance.availability.available || '0') ? labels.availableLabel : labels.notAvailableLabel || ' '
        })`;
      }

      items.push(timeslotDropdownContent);
    });

    const filterAvailableSlots = items.filter((item) => !item.disabled);
    const availableSlotData = filterAvailableSlots.filter((ele) => ele.value === this.product.performanceId);
    let defaultValue;
    if (availableSlotData && availableSlotData.length) {
      defaultValue = availableSlotData[0].text;
    }
    if (filterAvailableSlots && filterAvailableSlots.length) {
      return (
        <div className="c-date-selector--time-slot">
          <DynamicContent tagName="h3" attrs={{ className: 'heading-5' }} innerHtml={labels.title} />
          {this.props.timeSlotData && (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'sub-heading' }}
              innerHtml={this.props.timeSlotData.description}
            />
          )}
          <DropDown
            listItems={filterAvailableSlots}
            defaultValue={this.product.performanceId && defaultValue ? defaultValue : labels.dropdownLabel}
            callBackFunction={this.selectTimeSlot}
            showSelected="true"
            isUpdateDropdownProps={this.isTotalAvailabilityCal}
          />
          {this.renderSameTimeWarning()}
          {this.renderSameTimeError()}
        </div>
      );
    } else {
      this.setState({ performanceData: { error: { errorcode: '7001' } }, slotSelected: false });
    }
  };

  //Should be used for the YPS-7184 do not remove.
  renderTimeSlotsV2 = () => {
    const items = [],
      timeRange = [],
      labels = this.props.timeSlotData && this.props.timeSlotData.labels ? this.props.timeSlotData.labels : {};

    this.state.performanceData.performance.forEach((performance) => {
      let timeSplit = performance.openTime.split(':')[0];

      if (
        timeRange.indexOf(timeSplit) === -1 &&
        performance.sellable === 'true' &&
        performance.availability.available !== '0'
      ) {
        timeRange.push(timeSplit);

        let timeslotDropdownContent = {
          value: performance.performanceId,
          disabled: performance.sellable === 'false' || performance.availability.available === '0',
        };
        if (this.isSWAD) {
          timeslotDropdownContent.text = `<span class='available-time'>${this.timeFormat(
            performance,
          )}</span> <span class='available-seats'>${performance.availability.available} ${
            labels.availableLabel
          }</span>`;
        } else {
          timeslotDropdownContent.text = `${this.timeFormat(performance)} (${performance.availability.available} ${
            labels.availableLabel
          })`;
        }
        items.push(timeslotDropdownContent);
      }
    });

    return (
      <div className="c-date-selector--time-slot">
        <DynamicContent tagName="h3" attrs={{ className: 'heading-5' }} innerHtml={labels.title} />
        <DynamicContent
          tagName="p"
          attrs={{ className: 'sub-heading' }}
          innerHtml={this.props.timeSlotData.description}
        />
        <DropDown
          listItems={items}
          defaultValue={
            this.product.performanceId
              ? items.filter((ele) => ele.value === this.product.performanceId)[0].text
              : labels.dropdownLabel
          }
          callBackFunction={this.selectTimeSlot}
          showSelected="true"
        />
        {this.renderSameTimeWarning()}
        {this.renderSameTimeError()}
      </div>
    );
  };

  renderTotalAmount = () => <TotalAmount context={this} />;

  isValidDay = (date) => helper.isValidDay(this, date);

  addCarsCallBack = (evt) => helper.addCarsCallBack(this, evt);

  getDefaultCheckedValue = (prod) => helper.getDefaultCheckedValue(this, prod);

  GTMDataOnSelectItem = (tickets) => {
    try {
      const { data } = this.props;
      const category =
        data && data.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      GTMData.push('selectItem', { itemsList: tickets, category, gaTabTitle: data?.gaTabTitle || undefined });
    } catch (error) {
      console.log('GA4 error: ', error);
    }
  };

  bindCars = (itms) => {
    const cars = [],
      carProds = this.additionalProducts || [];
    let idx,
      carsProdLen = carProds.length;

    for (idx = 0; idx < carsProdLen; idx++) {
      // Capture the current value of idx in an IIFE
      ((currentIndex) => {
        cars.push(
          <li
            className={`exctmnt--list-items ${this.isDynamicPricingProd ? 'dynamic-pricing-list' : ''}`}
            key={currentIndex}
          >
            <input
              type="radio"
              name="secPrds"
              id={'secPrds' + currentIndex}
              data-value={currentIndex}
              data-prod={JSON.stringify(carProds[currentIndex])}
              value={currentIndex}
              defaultChecked={this.getDefaultCheckedValue(carProds[currentIndex])}
              onChange={this.addCarsCallBack}
              tabIndex={
                carProds[currentIndex].productId ===
                (resolvePath(this.fallBackProducts, 'baseProduct.productId') ||
                  this.defaultSelAdditionalProds.productId)
                  ? '0'
                  : '-1'
              }
            />
            <label htmlFor={'secPrds' + currentIndex}>
              <div className="radio"></div>
              <div className="exctmnt-content-wrapper">
                <p className="title">{itms.collections[currentIndex].title}</p>
                {!this.isPJOnly && (
                  <p className="price">
                    <span className="price--currency">{`${itms.collections[currentIndex].price || ''}`}</span>
                  </p>
                )}
              </div>
            </label>
          </li>,
        );
      })(idx);
    }

    // }

    return cars;
  };

  renderCarsAddOns = () => {
    let prodOS = this.productOverlaySelector;

    return (
      <div className="exctmnt section">
        <DynamicContent
          tagName="p"
          innerHtml={prodOS.title}
          attrs={{
            className: 'exctmnt--title',
          }}
        />
        {this.isOverlaySelectionLength && (
          <div>
            <ul className="exctmnt--wrapper">{this.bindCars(prodOS)}</ul>
            {prodOS?.subTitle && (
              <DynamicContent
                tagName="p"
                innerHtml={prodOS?.subTitle}
                attrs={{
                  className: 'exctmnt--subtitle',
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  showCalendarLabels = () => <CalendarLabels context={this} />;

  handleSecondaryProdOffer = (currentProduct) => helper.handleSecondaryProdOffer(this, currentProduct);

  secPrdCallBack = (evt) => helper.secPrdCallBack(this, evt);

  bindSecPrds = (itm) => {
    const lstItms = [],
      sortedArray = this.secProds,
      sortedArrayLng = sortedArray.length;
    let idx;
    for (idx = 0; idx < sortedArrayLng; idx++) {
      lstItms.push(
        <li className="exctmnt--list-items upsell-items" key={idx}>
          <input
            type="radio"
            name="secPrds"
            id={'secPrds' + idx}
            onClick={this.secPrdCallBack}
            data-value={idx}
            data-prod={JSON.stringify(sortedArray[idx])}
            value={idx}
            onChange={this.secPrdCallBack}
            className={`${idx === this.slctdSecIdx ? 'button-checked' : 'button-unchecked'}`}
            tabIndex={idx === this.slctdSecIdx ? '0' : '-1'}
          />
          <label htmlFor={'secPrds' + idx}>
            <div className="radio-button"></div>
            <p className="title">{sortedArray[idx].productName}</p>
            <p className="price">
              <span className="price--currency">{this.props.data.currency}</span>
              <span className="price--value">{Number(sortedArray[idx].gross) - Number(this.primaryProd.gross)}</span>
            </p>
          </label>
        </li>,
      );
    }
    lstItms.push(
      <li className="exctmnt--list-items upsell-items" key={idx}>
        <input
          type="radio"
          name="secPrds"
          id={'secPrds' + idx}
          onClick={this.secPrdCallBack}
          data-value={idx}
          data-prod={JSON.stringify(this.primaryProd)}
          value={idx}
          onChange={this.secPrdCallBack}
          className={`${this.slctdSecIdx === sortedArrayLng ? 'button-checked' : 'button-unchecked'}`}
          tabIndex={this.slctdSecIdx === sortedArrayLng ? '0' : '-1'}
        />
        <label htmlFor={'secPrds' + idx}>
          <div className="radio-button"></div>
          <p className="title">{itm.collection.noThanks}</p>
          <p className="price price-hide">
            <span className="price--currency">{this.props.data.currency}</span>
            <span className="price--value">0</span>
          </p>
        </label>
      </li>,
    );
    return lstItms;
  };

  renderExcitemnt = (itm) => {
    if (this.secProds && this.secProds.length && this.product) {
      return (
        <div className="exctmnt section">
          <DynamicContent
            tagName="p"
            innerHtml={itm.title}
            attrs={{
              className: 'exctmnt--title',
            }}
          />
          <DynamicContent
            tagName="p"
            innerHtml={itm.description}
            attrs={{
              className: 'exctmnt--sub-title',
            }}
          />
          <ul className="exctmnt--wrapper">{this.bindSecPrds(itm)}</ul>
        </div>
      );
    } else {
      return null;
    }
  };

  updateButtonState = () => helper.updateButtonState(this);

  lcnsCallBack = (data) => helper.lcnsCallBack(this, data);

  termAndCondcallback = (event) => helper.termAndCondcallback(this, event);

  configureCntry = (cntries) => helper.configureCntry(this, cntries);

  renderDrivingLicense = (item) => {
    this.licenseAvailable = true;
    return (
      <div className="drvLcns section">
        <DynamicContent
          tagName="p"
          innerHtml={item.title}
          attrs={{
            className: 'drvLcns--title',
          }}
        />
        <DynamicContent
          tagName="p"
          innerHtml={item.description}
          attrs={{
            className: 'drvLcns--sub-title',
          }}
        />
        <DropDown
          listItems={this.configureCntry(item.country)}
          defaultValue={this.product.license ? this.product.license : item.defaultText}
          callBackFunction={this.lcnsCallBack}
          isUpdateDropdownProps={true}
          showSelected="true"
        />
        <DynamicContent
          tagName="p"
          innerHtml={item.tnctext}
          attrs={{
            className: 'drvLcns--verbose',
          }}
        />
      </div>
    );
  };

  renderSections = () => {
    const multiTktSelc = this.props.multiTicketSelector || {},
      sections = multiTktSelc.options,
      keyMap = multiTktSelc.stringMapping;
    return (
      sections &&
      sections.map((item) => {
        switch (item.identifier) {
          case keyMap.license:
            return this.renderDrivingLicense(item);
          case keyMap.secondaryProd:
            return this.renderExcitemnt(item);
          default:
            return null;
        }
      })
    );
  };

  setCarProdState = () => {
    if (!this.isDynamicPricingProd) this.setState({ carProdsClicked: false });
  };

  getTimeSlotArray = (timeSlotArray) => {
    return timeSlotArray.map((timeSlotObject) => {
      return timeSlotObject.date + this.timeFormatTac(timeSlotObject);
    });
  };

  getSelectedTimeSlotData = (timeSlotGap) => {
    let selectedSlotStartDate = moment(this.state.date);
    let selectedSlotEndDate = moment(this.state.date);
    if (this.state.selectedTimeSlot) {
      let timeSlotArray = this.state.selectedTimeSlot.split(' - ');
      let selectedStartSlot = timeSlotArray[0].split(':');
      let selectedEndSlot;
      if (timeSlotArray[1]) {
        selectedEndSlot = timeSlotArray[1].split(':');
      }
      selectedSlotStartDate.set({
        h: selectedStartSlot[0],
        m: selectedStartSlot[1],
        s: 0,
      });
      if (selectedEndSlot) {
        selectedSlotEndDate.set({
          h: selectedEndSlot[0],
          m: selectedEndSlot[1],
          s: 0,
        });
      }

      let isTicketInCart = [];
      this.selectedTicketTimeSlot.forEach((obj) => {
        let slotStartDate = moment(obj.fromDate);
        let slotEndDate = moment(obj.fromDate);
        if (obj.timeSlot) {
          let timeSlotArr = obj.timeSlot.split(' - ');
          let startSlot = timeSlotArr[0].split(':');
          let endSlot = timeSlotArr[1]?.split(':');
          slotStartDate.set({
            h: startSlot[0],
            m: startSlot[1],
            s: 0,
          });
          slotEndDate.set({
            h: endSlot[0],
            m: endSlot[1],
            s: 0,
          });
          if (timeSlotGap) {
            slotEndDate.add(timeSlotGap, 'minutes');
            selectedSlotEndDate.add(timeSlotGap, 'minutes');
          }
          if (slotStartDate <= selectedSlotStartDate && selectedSlotStartDate <= slotEndDate) {
            isTicketInCart.push(obj.productId);
          } else if (selectedEndSlot && slotStartDate <= selectedSlotEndDate && selectedSlotEndDate <= slotEndDate) {
            isTicketInCart.push(obj.productId);
          }
        }
      });
      return isTicketInCart;
    }
  };
  checkCartHaveSameTimeSlot = () => {
    let selectedTimeSlotData = this.getSelectedTimeSlotData();
    return !!(selectedTimeSlotData && selectedTimeSlotData.length > 0);
  };
  checkCartHaveSameTimeSlotWithGap = () => {
    let selectedTimeSlotData = this.getSelectedTimeSlotData(this.props.data.timeSlotGap);
    return !!(selectedTimeSlotData && selectedTimeSlotData.length > 0);
  };
  checkTicketHaveSameTimeSlot = () => {
    let selectedTimeSlotData = this.getSelectedTimeSlotData();

    if (selectedTimeSlotData && selectedTimeSlotData.length > 0) {
      return selectedTimeSlotData.includes(this.state.selectedTicketId);
    }
    return false;
  };
  renderAmountwithButtons = ({ timeSlotFunction, mobileAttention }) => {
    const isAllAttentionChecked =
      this.props.attentions &&
      this.props.attentions.length > 0 &&
      !(this.props.attentions.length === this.state.selectedAttentions.length);

    return (
      <AmountWithButtons
        context={this}
        timeSlotFunction={timeSlotFunction}
        mobileAttention={mobileAttention}
        attentionCheck={
          this.isSWAD ? isAllAttentionChecked || this.checkTicketHaveSameTimeSlot() : isAllAttentionChecked
        }
      />
    );
  };

  addRemoveSelectedCheckbox = (attention) => {
    let selectedAttention = this.state.selectedAttentions;
    if (selectedAttention.includes(attention)) {
      selectedAttention.forEach((att, index) => {
        if (att.label === attention.label) {
          selectedAttention.splice(index, 1);
          return att;
        }
      });
    } else {
      selectedAttention.push(attention);
    }
    this.setState({ selectedAttentions: selectedAttention });
  };
  getCheckboxValue = (label) => {
    return this.state.selectedAttentions
      .map((attention) => {
        return attention.label;
      })
      .includes(label);
  };
  renderAttentions = () => {
    let attentions = this.props.attentions;
    let attentionTitle = this.props.attentionTitle;
    return (
      <div className="attentions-box">
        <div className="attentions-row">
          <DynamicContent tagName="h6" attrs={{ className: 'heading-6' }} innerHtml={attentionTitle} />
        </div>
        {attentions.map((attention, i) => (
          <div className="attentions-row" key={i}>
            <label htmlFor={`attention-${i}`} className="checkbox-label">
              <input
                type="checkbox"
                tabIndex="0"
                onClick={() => this.addRemoveSelectedCheckbox(attention)}
                className="attention-checkbox"
                id={`attention-${i}`}
                checked={this.getCheckboxValue(attention.label)}
              />
              <div className="checkbox"></div>
              <DynamicContent tagName="div" attrs={{ className: 'attention-label' }} innerHtml={attention.value} />
            </label>
          </div>
        ))}
      </div>
    );
  };

  renderTimeslotFn = () => {
    return (
      <>
        {' '}
        {this.props.timeSlotData && this.props.timeSlotData.displayTimeSlotByHour
          ? this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length >= 1 &&
            this.renderTimeSlotsV2()
          : this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length &&
            this.isTotalAvailabilityCal &&
            this.props.timeSlotData &&
            this.props.timeSlotData.showSingleTimeSlot
          ? this.renderTimeSlotsTAC()
          : this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length >= 1 &&
            this.props.timeSlotData &&
            this.props.timeSlotData.showSingleTimeSlot &&
            this.renderTimeSlots()}
      </>
    );
  };

  renderSwadTenantsStructure = () => {
    let showCars = !!(
      this.props.productOverlaySelector &&
      this.props.additionalProds &&
      this.props.additionalProds.length
    );
    let hideQtySelector = !!(this.state.maxAvailableTickets <= 1 && showCars);
    const calenderDesc = this.props.calenderData.description.replace(/&nbsp;/g, '');
    const { selectedDiscount } = this.state;
    const discountOptions = this.calendarSettings.discountOptions;
    let updatedCounterData = {};
    if (this.props.counterData.isIncreaseDecreaseEnabled) {
      const updatedCounterOptions =
        this.props.counterData.options &&
        this.props.counterData.options.map((data) => {
          const secondaryOption = this.props.counterData.options[1];
          if (secondaryOption.coveoValue === data.coveoValue) {
            data.isIncreaseDecreaseEnabled = this.props.counterData.isIncreaseDecreaseEnabled;
          }
          return data;
        });
      updatedCounterData = { ...this.props.counterData, options: updatedCounterOptions };
    }

    return (
      <div
        className={`c-add-ons-overlay calendar-inside-overlay ${
          this.isPJOnly || this.isDynamicPricingProd ? 'v-multi-product-widget' : ''
        } ${this.isDynamicPricingProd ? 'v-dynamic-product-pricing' : ''}`}
      >
        {this.calendarSettings.calendarTooltip && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
          .calendar-overlay-genral-admission .calendar-inside-overlay .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month):hover::after {
              content : "${decodeHtmlEntity(this.calendarSettings.calendarTooltip)}";
            }
          `,
            }}
          />
        )}
        {this.isDynamicPricingProd &&
          this.props.searchProductList
            .filter((elem, pos, arr) => {
              return arr.indexOf(elem) === pos;
            })
            .map((prod) => {
              const product = this.legends.find((item) => item.productId === prod.productId);
              if (product) {
                return (
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `
                  .calendar-overlay-genral-admission .calendar-inside-overlay .react-datepicker__day.${prod.productId.replaceAll(
                    '.',
                    '-',
                  )}::before {
              content : "";
              background-color: ${product.colorCode};
              position: absolute;
              height: 6px;
              width: 6px;
              bottom: -4px;
              border-radius: 50%;
              left: 50%;
              border: none;
              transform: translateX(-50%);
            }
          `,
                    }}
                  />
                );
              }
            })}
        <div className="swad-left-panel">
          {selectedDiscount.description && (
            <div className="calendar-title">
              <DynamicContent
                tagName="span"
                attrs={{ className: 'title' }}
                innerHtml={selectedDiscount.description.toUpperCase()}
              />
            </div>
          )}
          {this.calendarSettings.highlightDate && discountOptions && discountOptions.length > 0 && (
            <div className="discount">
              <HighlightDate
                discounts={discountOptions}
                selectedDiscount={selectedDiscount}
                toggleRangeAndDiscount={this.toggleRangeAndDiscount}
              />
            </div>
          )}
          {(!this.isMultiProductCal || this.isDynamicPricingProd) && showCars && this.renderCarsAddOns()}

          <DynamicContent
            tagName="h3"
            attrs={{ className: 'heading-3 calendar-title' }}
            innerHtml={this.props.calenderData.title}
          />
          {this.isTotalAvailabilityCal && this.showCalendarLabels()}
          <Calendar
            ref={(instance) => (this._calendar = instance)}
            setDateCustom={this.isTotalAvailabilityCal ? this.calendarDateCallback : this.setDate}
            minDate={this.minDate}
            maxDate={this.maxDate}
            filterDate={this.isValidDay}
            calendarSettings={this.calendarSettings}
            isTotalAvailabilityCal={this.isTotalAvailabilityCal}
            openToDate={
              selectedDiscount.highlightRange.length > 0 &&
              selectedDiscount.highlightRange[0]['react-datepicker__day--in-range'][0]
            }
            highlightDates={
              !this.enableDynamicCalendar && selectedDiscount.highlightRange
                ? selectedDiscount.highlightRange
                : this.state.customDates
            }
            reset={this.state.calendarReset}
            inlineDisable={true}
            disableCloseOnSelect={true}
            isKeyboardNavigationAllowed={this.isDesktop}
            onBlurCustom={(e, name, data) => this._calendar.openDatePicker()}
            onClickOutsideCustom={(e) => this._calendar.openDatePicker()}
            carProdsClicked={this.state.carProdsClicked}
            resetonTabChange={this.state.resetonTabChange}
            setCarProdState={this.setCarProdState}
            selectedDate={this.state.minDateFromCartData}
            variant={this.isPJOnly}
            isDynamicPricingProd={this.isDynamicPricingProd}
            enableActiveDates={!this.enableDynamicCalendar}
            isBookingOverlay={this.bookingOverlay}
            isDatePreSelection={this.state.selectDefaultDate}
          />
          {this.isDynamicPricingProd && <LineSeparator />}
          {calenderDesc && (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'calendar-subtitle' }}
              innerHtml={this.props.calenderData.description}
            />
          )}
          {this.isSWAD &&
            !this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.hasOwnProperty('error') &&
            this.renderErrorMsg()}
          {!this.state.isMobile && this.props.attentions && this.props.attentions.length > 0 && this.renderAttentions()}
        </div>
        <div className="swad-right-panel">
          {!this.isSWAD &&
            !this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.hasOwnProperty('error') &&
            this.renderErrorMsg()}
          {this.state.slotSelected && this.showProductNameInOverlay && (
            <div className="product-name-title">
              <DynamicContent tagName="h4" attrs={{ className: 'heading-4' }} innerHtml={this.product.productName} />
            </div>
          )}
          {(this.state.slotSelected || this.isDynamicPricingProd) && (
            <CounterComponent
              data={{
                primaryCounter: this.props.counterData.isIncreaseDecreaseEnabled
                  ? updatedCounterData
                  : this.props.counterData,
              }}
              name={this.props.product.productName}
              isVIPPrivateOrShared={this.state.isVIPPrivateOrShared}
              setVisitorData={this.setVisitorsCount}
              isAdultChildOverlay={this.isAdultChildOverlay}
              maxAvailableTicketsCount={this.state.maxAvailableTickets}
              hideQtySelector={this.isPJOnly || this.isDynamicPricingProd ? false : hideQtySelector}
              isPJOnly={this.isPJOnly}
              timeSlotAvailable={this.props.timeSlotData}
              spinnerInitialCount={this.state.initialCount} // Count passed for dynamic pricing products on click of different overlay selector radio button
              isDynamicPricingProd={this.isDynamicPricingProd}
              isProductOverlayClicked={this.state.isProductOverlayClicked}
              overlayShowPrice={this.props.overlayShowPrice}
              expeditionPrice={this?.isExpeditionProduct ? `${this?.product?.currency} ${this?.product?.gross}` : false}
            />
          )}
          {this.props.data && this.props.data.isAddOnExperienceOverlay && this.renderSections()}
          {!this.disableTotalPrice && this.isDynamicPricingProd && <LineSeparator param="middle" />}
          {this.renderAmountwithButtons({
            timeSlotFunction: this.renderTimeslotFn,
            mobileAttention:
              this.state.isMobile && this.props.attentions && this.props.attentions.length > 0 && this.renderAttentions,
          })}
          {/* placeholder for total amount and buttons */}
          {this.state.slotSelected && (
            <div className="vat-info">
              <DynamicContent tagName="p" attrs={{ className: 'vat-info-label' }} innerHtml={this.props.data.vatInfo} />
            </div>
          )}
          {this.isDynamicPricingProd &&
            this.calendarData &&
            Array.isArray(this.calendarData.options) &&
            this.calendarData.options.length > 0 && <LineSeparator param="bottom" />}
          <ImportantNotes options={this.calendarData && this.calendarData.options} />
        </div>
      </div>
    );
  };

  getVIPCounterData = (productId) => {
    if (this.isVIPPrivateOrShared) {
      const options = this.getVIPProductOptions(productId);
      return { ...this.props.counterData, options: options };
    }
    return this.props.counterData;
  };

  renderCommonTenantStructure = () => {
    let showCars = !!(
      this.props.productOverlaySelector &&
      this.props.additionalProds &&
      this.props.additionalProds.length
    );
    let hideQtySelector = !!(this.state.maxAvailableTickets <= 1 && showCars);
    const calenderDesc = this.props.calenderData.description.replace(/&nbsp;/g, '');
    const { selectedDiscount } = this.state;
    const discountOptions = this.calendarSettings.discountOptions;
    let updatedCounterData = {};
    if (this.props.counterData.isIncreaseDecreaseEnabled) {
      const updatedCounterOptions =
        this.props.counterData.options &&
        this.props.counterData.options.map((data) => {
          const secondaryOption = this.props.counterData.options[1];
          if (secondaryOption.coveoValue === data.coveoValue) {
            data.isIncreaseDecreaseEnabled = this.props.counterData.isIncreaseDecreaseEnabled;
          }
          return data;
        });
      updatedCounterData = { ...this.props.counterData, options: updatedCounterOptions };
    }
    return (
      <div
        className={`c-add-ons-overlay calendar-inside-overlay ${
          this.isPJOnly || this.isDynamicPricingProd ? 'v-multi-product-widget' : ''
        } ${this.isDynamicPricingProd ? 'v-dynamic-product-pricing' : ''}`}
      >
        {this.calendarSettings.calendarTooltip && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
          .calendar-overlay-genral-admission .calendar-inside-overlay .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month):hover::after {
              content : "${decodeHtmlEntity(this.calendarSettings.calendarTooltip)}";
            }
          `,
            }}
          />
        )}
        {this.isDynamicPricingProd &&
          this.props.searchProductList
            .filter((elem, pos, arr) => {
              return arr.indexOf(elem) === pos;
            })
            .map((prod) => {
              const product = this.legends.find((item) => item.productId === prod.productId);
              if (product) {
                return (
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `
                  .calendar-overlay-genral-admission .calendar-inside-overlay .react-datepicker__day.${prod.productId.replaceAll(
                    '.',
                    '-',
                  )}::before {
              content : "";
              background-color: ${product.colorCode};
              position: absolute;
              height: 6px;
              width: 6px;
              bottom: -4px;
              border-radius: 50%;
              left: 10px;
              border: none;
              transform: none;
            }
          `,
                    }}
                  />
                );
              }
            })}
        {selectedDiscount.description && (
          <div className="calendar-title">
            <DynamicContent
              tagName="span"
              attrs={{ className: 'title' }}
              innerHtml={selectedDiscount.description.toUpperCase()}
            />
          </div>
        )}
        {this.calendarSettings.highlightDate && discountOptions && discountOptions.length > 0 && (
          <div className="discount">
            <HighlightDate
              discounts={discountOptions}
              selectedDiscount={selectedDiscount}
              toggleRangeAndDiscount={this.toggleRangeAndDiscount}
            />
          </div>
        )}
        {(!this.isMultiProductCal || this.isDynamicPricingProd) && showCars && this.renderCarsAddOns()}

        <DynamicContent
          tagName="h3"
          attrs={{ className: 'heading-3 calendar-title' }}
          innerHtml={this.props.calenderData.title}
        />
        <Calendar
          ref={(instance) => (this._calendar = instance)}
          setDateCustom={this.isTotalAvailabilityCal ? this.calendarDateCallback : this.setDate}
          minDate={this.minDate}
          maxDate={this.maxDate}
          filterDate={this.isValidDay}
          calendarSettings={this.calendarSettings}
          isTotalAvailabilityCal={this.isTotalAvailabilityCal}
          openToDate={
            selectedDiscount.highlightRange.length > 0 &&
            selectedDiscount.highlightRange[0]['react-datepicker__day--in-range'][0]
          }
          highlightDates={
            !this.enableDynamicCalendar && selectedDiscount.highlightRange
              ? selectedDiscount.highlightRange
              : this.state.customDates
          }
          reset={this.state.calendarReset}
          inlineDisable={true}
          disableCloseOnSelect={true}
          isKeyboardNavigationAllowed={this.isDesktop}
          onBlurCustom={(e, name, data) => this._calendar.openDatePicker()}
          onClickOutsideCustom={(e) => this._calendar.openDatePicker()}
          carProdsClicked={this.state.carProdsClicked}
          resetonTabChange={this.state.resetonTabChange}
          setCarProdState={this.setCarProdState}
          selectedDate={this.state.minDateFromCartData}
          variant={this.isPJOnly}
          isDynamicPricingProd={this.isDynamicPricingProd}
          enableActiveDates={!this.enableDynamicCalendar ? true : false}
          isBookingOverlay={this.bookingOverlay}
          isDatePreSelection={this.state.selectDefaultDate}
          setSelectedDefaultDate={this.setSelectedDefaultDate}
          hasPerformanceCall={this.product.hasperformance === '1' && this.enableDynamicCalendar}
          calendarOverlay
        />
        {this.isTotalAvailabilityCal && this.showCalendarLabels()}
        {this.isDynamicPricingProd && <LineSeparator />}
        {calenderDesc && (
          <DynamicContent
            tagName="p"
            attrs={{ className: 'calendar-subtitle' }}
            innerHtml={this.props.calenderData.description}
          />
        )}
        {this.props.timeSlotData && this.props.timeSlotData.displayTimeSlotByHour
          ? this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length > 1 &&
            this.renderTimeSlotsV2()
          : this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length &&
            this.isTotalAvailabilityCal &&
            this.props.timeSlotData &&
            this.props.timeSlotData.showSingleTimeSlot
          ? this.renderTimeSlotsTAC()
          : this.state.performanceData.hasOwnProperty('performance') &&
            this.state.performanceData.performance.length >= 1 &&
            this.props.timeSlotData &&
            this.props.timeSlotData.showSingleTimeSlot &&
            this.renderTimeSlots()}
        {/* {this.state.performanceData.hasOwnProperty("performance") && this.state.performanceData.performance.length > 1 && this.renderTimeSlots()} */}
        {!this.state.performanceData.hasOwnProperty('performance') &&
          this.state.performanceData.hasOwnProperty('error') &&
          this.renderErrorMsg()}
        {this.state.slotSelected && this.showProductNameInOverlay && (
          <div className="product-name-title">
            <DynamicContent tagName="h4" attrs={{ className: 'heading-4' }} innerHtml={this.product.productName} />
          </div>
        )}
        {(this.state.slotSelected || this.isDynamicPricingProd) && (
          <CounterComponent
            data={{
              primaryCounter: this.state.isVIPPrivateOrShared
                ? this.state.vipCounterData
                : this.props.counterData.isIncreaseDecreaseEnabled
                ? updatedCounterData
                : this.props.counterData,
            }}
            isVIPPrivateOrShared={this.state.isVIPPrivateOrShared}
            name={this.props.product.productName}
            setVisitorData={this.setVisitorsCount}
            isAdultChildOverlay={this.isAdultChildOverlay}
            maxAvailableTicketsCount={this.state.maxAvailableTickets}
            hideQtySelector={this.isPJOnly || this.isDynamicPricingProd ? false : hideQtySelector}
            isPJOnly={this.isPJOnly}
            timeSlotAvailable={this.props.timeSlotData}
            spinnerInitialCount={this.state.initialCount} // Count passed for dynamic pricing products on click of different overlay selector radio button
            isDynamicPricingProd={this.isDynamicPricingProd}
            isProductOverlayClicked={this.state.isProductOverlayClicked}
          />
        )}
        {this.props.data && this.props.data.isAddOnExperienceOverlay && this.renderSections()}
        {!this.disableTotalPrice && this.isDynamicPricingProd && <LineSeparator param="middle" />}
        {this.renderAmountwithButtons({})}
        {/* placeholder for total amount and buttons */}
        {this.state.slotSelected && !this.isSWAD && (
          <div className="vat-info">
            <DynamicContent tagName="p" attrs={{ className: 'vat-info-label' }} innerHtml={this.props.data.vatInfo} />
          </div>
        )}
        {this.isDynamicPricingProd &&
          this.calendarData &&
          Array.isArray(this.calendarData.options) &&
          this.calendarData.options.length > 0 && <LineSeparator param="bottom" />}
        <ImportantNotes options={this.calendarData && this.calendarData.options} />
      </div>
    );
  };

  renderAddOns = () => {
    return this.isSWAD ? this.renderSwadTenantsStructure() : this.renderCommonTenantStructure();
  };

  moneyCardAmountHandler = (e) => {
    this.setState({ cardAmount: parseFloat(e.target.value || 0) }, () => this.calculateTotal());
  };

  amountValidation = (e) => {
    const value = e.target.value ? parseFloat(e.target.value) : 0;
    this.setState({ amountMultiplesOfTen: !!(value % 10) }, () => this.updateButtonState());
  };

  renderQuantity = () => {
    const maxCount = this.props.counterData.maxCount;
    let hideQtySelector = maxCount <= 1 ? true : false;

    const moneyCardDetails = this.props.moneyCardDetails;
    this.flexibleMoneyCard =
      moneyCardDetails &&
      !isEmpty(moneyCardDetails) &&
      moneyCardDetails.type === UIConfig.moneyCardProduct.flexibleMoneyCard
        ? true
        : false;
    return (
      <div className="c-add-ons-overlay">
        {this.flexibleMoneyCard && (
          <>
            <div className="money-card-title">
              <DynamicContent tagName="h4" attrs={{ className: 'heading-4' }} innerHtml={moneyCardDetails.title} />
            </div>
            <div className="c-flexible-money-card">
              <DynamicContent
                tagName="div"
                attrs={{ className: 'money-card-subTitle' }}
                innerHtml={moneyCardDetails.amountTitle}
              />

              <div className="form-element">
                <input
                  type="number"
                  name="moneyCardAmount"
                  className="form-input body-copy-3 card-number "
                  data-value={this.state.cardAmount ? this.state.cardAmount : null}
                  placeholder={moneyCardDetails.amoutPlaceHolder}
                  value={this.state.cardAmount ? this.state.cardAmount : null}
                  onChange={(e) => this.moneyCardAmountHandler(e)}
                  onBlur={(e) => this.amountValidation(e)}
                  tabIndex={0}
                />
                {!!this.state.amountMultiplesOfTen && (
                  <DynamicContent
                    tagName="span"
                    attrs={{ className: 'amount-validation-error' }}
                    innerHtml={moneyCardDetails.errorMessage}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {
          <CounterComponent
            data={{ primaryCounter: this.props.counterData }}
            name={this.props.product.productName}
            setVisitorData={this.setVisitorsCount}
            maxAvailableTicketsCount={maxCount}
            hideQtySelector={hideQtySelector}
            timeSlotAvailable={this.props.timeSlotData}
            spinnerInitialCount={this.state.initialCount} // Count passed for dynamic pricing products on click of different overlay selector radio button
            isDynamicPricingProd={this.isDynamicPricingProd}
            isProductOverlayClicked={this.state.isProductOverlayClicked}
            priceForFlexibleMoneyCard={this.state.cardAmount}
            flexibleMoneyCard={this.flexibleMoneyCard}
          />
        }
        {this.renderTotalAmount()}
        {!this.tnc && (
          <div className={`t-n-c ${this.tnc ? 't-n-c-hide' : ''}`}>
            <label htmlFor="tAndCCheckbox" className="checkbox-label">
              <input type="checkbox" onClick={this.termAndCondcallback} className="hide" id="tAndCCheckbox" />
              <div className="checkbox"></div>
              <DynamicContent
                tagName="div"
                attrs={{ className: 't-n-c--label' }}
                innerHtml={
                  (this.props.multiTicketSelector && this.props.multiTicketSelector.tnc) ||
                  (this.props.timeSlotData && this.props.timeSlotData.tnc)
                }
              />
            </label>
          </div>
        )}
        <div className="btn-add-to-cart" data-product={`{productId:${this.props.product.productId}}`}>
          <div className={`btn-primary ${this.state.disableAddToCart ? 'disabled' : ''}`}>
            <DynamicContent
              tagName="button"
              innerHtml={
                (this.props.data.addToCartCTA && this.props.data.addToCartCTA.label) || this.props.data.addToCart
              }
              attrs={{
                onClick: this.addToCart,
                disabled: this.state.disableAddToCart,
                className: 'add-to-cart',
              }}
            />
          </div>
        </div>
        {this.props.data && this.props.data.continueCheckoutCTA && (
          <div className="btn-checkout">
            <div className={`btn-primary ${this.state.disableAddToCart ? 'disabled' : ''}`}>
              <DynamicContent
                tagName="button"
                innerHtml={this.props.data.continueCheckoutCTA.label}
                attrs={{
                  onClick: () => this.addToCart(this.props.data.continueCheckoutCTA),
                  disabled: this.state.disableAddToCart,
                  className: 'continue-checkout',
                }}
              />
            </div>
          </div>
        )}
        {this.state.slotSelected && (
          <div className="vat-info">
            <DynamicContent tagName="p" attrs={{ className: 'vat-info-label' }} innerHtml={this.props.data.vatInfo} />
          </div>
        )}
      </div>
    );
  };

  render() {
    try {
      return <div>{this.isQuantityInOverlay ? this.renderQuantity() : this.renderAddOns()}</div>;
    } catch (err) {
      return logComponentRenderingError(err, 'CalenderOverlayComponent');
    }
  }
}

CalenderOverlayComponent.PropsTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
};
