import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import UIConfig from '../../../../common/UIConfig';
import EventPerformance from './event-performance-component';
import { PerformanceService } from '../../../../common/services';
import {
  getLanguageWithoutLocal,
  getUserTypeForGa4,
  isEmpty,
  setLocalStorage,
  isMatchTenant,
  getCurrency,
} from '../../../../common/utility';
import YaCartManagementService, { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import ErrorSummary from '../../../presentation/base/error-summary/error-summary-component';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import { yaBuyTicketAnalytics } from '../../../../common/analytics-events';
import GTMData from '../gtm-data';

const closeOverlay = () => {
  window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
    shouldOpen: false,
  });
};

class EventPerformanceList extends Component {
  state = {
    performances: [],
    servicesError: false,
    serviceErrorCode: '',
    serviceErrorText: '',
  };

  constructor(props) {
    super(props);

    this.isYaNonSeatedJourney = this.props.isYaNonSeatedJourney && this.props.data.eventDetails.isNonSeated;
  }

  componentDidMount() {
    const {
      eventId,
      startDate,
      endDate,
      showTnC,
      showPerformanceTimeout = 120,
      isPromotionalEvent,
      isPromoCodeRequired,
    } = this.props.data.eventDetails;

    const { url } = this.props.services.getPerformance;
    const abuDhabiUTCInMin = 240;
    const currentDateTimeAD = moment().utcOffset() - abuDhabiUTCInMin;
    const currentDateTimeADWithPerfTimeoutBuffer = moment()
      .subtract(currentDateTimeAD, 'minutes')
      .add(showPerformanceTimeout, 'minutes');
    const requestData = {
      productId: null,
      eventId: [eventId],
      fromTime: '',
      toTime: '',
    };
    const eventDetails = YALocalStorageSvc.getSetEventDetails();
    //Disable tnc overlay
    // When show tnc is set to false by default set visited event to true
    // and prevent TnC dialog from opening
    if (!showTnC) {
      this.setVisitedEvents();
    }
    GTMData.updatEventId(eventId);

    PerformanceService.searchPerformance(
      url,
      startDate,
      endDate,
      requestData,
      true,
      UIConfig.loader.defaultPreLoaderTarget,
    )
      .then((res) => {
        const { performance: performancelist } = res.data.performancelist;
        const filteredPerformances = performancelist.filter((performance) => {
          const time = `${performance.date} ${performance.openTime}`;
          const momentShowTime = moment(time);

          return momentShowTime.isAfter(currentDateTimeADWithPerfTimeoutBuffer);
        });

        this.makePerformanceData(filteredPerformances);
        const keys = Object.keys(this.props.cartData.cart.items);
        if (keys.length > 0) {
          const isCartData = this.props.cartData.cart.items[keys[0]].products;
          if (eventId !== eventDetails.eventId && isCartData.length > 0 && this.props.isFirstTimeLoad) {
            this.props.setOverlay(false);
            window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
              shouldOpen: true,
              dataToAppend: this.createOpenOverlayonLoad(),
              customClass: classnames('multiDateBooking-Overlay'),
            });
          }
        }
        YALocalStorageSvc.getSetEventDetails({
          ...eventDetails,
          isPromoCodeRequired: isPromoCodeRequired,
          isPromotionalEvent: isPromotionalEvent,
        });
        setLocalStorage('isPromoCodeRequired', isPromoCodeRequired);
      })
      .catch((err) => {
        this.setErrorMsg(err, 'getPerformance');
      });
  }

  setErrorMsg = (err, serviceName) => {
    const { errors: cmsErrors = {} } = this.props.services[serviceName];
    let errorCode = err.error && (err.error.code || err.error.errorcode);
    if (errorCode) {
      this.setState({
        servicesError: true,
        serviceErrorCode: errorCode,
        serviceErrorText: err.error.text || cmsErrors[errorCode] || err.error.errordescription,
      });
    } else {
      this.redirectToErrorPage();
    }
  };

  renderErrorMsg = () => {
    return (
      <ErrorSummary
        data={{
          error: {
            code: this.state.serviceErrorCode,
            text: this.state.serviceErrorText,
          },
        }}
      />
    );
  };

  makePerformanceData = (calendarPerformances) => {
    const cmsPerformances = this.props.data.eventDetails.performances;
    let performances = [];
    const keys = Object.keys(this.props.cartData.cart.items);
    const performanceDetails = YALocalStorageSvc.getSetPerformance();

    calendarPerformances.forEach((performance) => {
      let obj = cmsPerformances[performance.performanceId];
      if (obj) {
        performances.push({
          ...obj,
          sellable:
            (obj.sellable === 'true' || obj.sellable === true) &&
            (performance.sellable === 'true' || performance.sellable === true),
          performanceId: performance.performanceId,
          availability: performance.availability,
        });
      }
    });
    if (keys.length && isEmpty(performanceDetails)) {
      const performanceList = this.props.cartData.cart.items[keys[0]].products;
      const selectPerformance = performances.find((perf) => perf.performanceId === performanceList[0].performanceId);
      YALocalStorageSvc.getSetPerformance({
        ...selectPerformance,
        isNonSeatedSector: this.props.data.eventDetails.isNonSeatedSector,
      });
    }
    this.setState({ performances });
  };

  /**
   *  Show Terms and Conditions and when user accepts, loads non seated product overlay
   *  @param {[string]} - performanceId of selected time slot
   *  @param {[function]} - showNonSeatedProductsFn - loads non seating product overlay
   */
  nonSeatedJourneyTAndC = (performanceId, showNonSeatedProductsFn) => {
    this.openOverlay(performanceId, false, showNonSeatedProductsFn);
  };

  /**
   *  Show message when user tries to selected new slot or event when items are already in their cart.
   *  if user agrees to continue, we delete existing cart and show Tour Overlay for new Timeslot
   */
  showMessageWhenItemsInCart = (performanceId, showNonSeatedProductsFn) => {
    this.openOverlay(performanceId, true, showNonSeatedProductsFn);
  };

  setVisitedEvents = () => {
    const { eventId, showTnCAlways } = this.props.data.eventDetails;
    let obj = YALocalStorageSvc.getSetVisitedEvents();
    obj[eventId] = showTnCAlways ? false : true;
    obj['isNonSeatedEvent'] = this.isYaNonSeatedJourney || false;
    YALocalStorageSvc.getSetVisitedEvents(obj);
  };

  handleRedirection = (performanceId) => {
    // This method should only do the redirection on the basis of performanceId.
    const { actionLink, eventId, returnUrl, isPromotionalEvent, isPromoCodeRequired } = this.props.data.eventDetails;
    this.setVisitedEvents();

    YaCartManagementService.handleRedirection(
      UIConfig.b2c.purchaseJourney.yasArenaCart.callerPage.multidateEvent,
      {
        eventId: eventId,
        returnUrl,
        performanceId,
        vivaCartPageUrl: actionLink,
      },
      isPromotionalEvent,
      isPromoCodeRequired,
    );
  };

  openOverlay = (performanceId, isDataInCart, extraCallback) => {
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: true,
      dataToAppend: this.createOverlayData(performanceId, isDataInCart, extraCallback),
      customClass: classnames('multiDateBooking-Overlay', { tnc: !isDataInCart }),
    });
  };

  createOpenOverlayonLoad = () => {
    const { cartEmptyWarningOverlay } = this.props.data.eventDetails;
    const onConfirmClickHandler = () => {
      this.props.clearYACart();
      closeOverlay();
    };
    return (
      <div className="overlay-data">
        <DynamicContent
          tagName="p"
          attrs={{ className: '' }}
          innerHtml={cartEmptyWarningOverlay && cartEmptyWarningOverlay.bodyCopy}
        />
        <div className="action-line">
          <div className="btn-primary">
            <button type="button" onClick={() => onConfirmClickHandler()}>
              {cartEmptyWarningOverlay.primaryCTA}
            </button>
          </div>
          <div className="btn-secondary">
            <button type="button" onClick={closeOverlay}>
              {cartEmptyWarningOverlay && cartEmptyWarningOverlay.secondaryCTA}
            </button>
          </div>
        </div>
      </div>
    );
  };
  createOverlayData = (performanceId, isDataInCart, extraCallback) => {
    const { tncOverlay, cartEmptyWarningOverlay } = this.props.data.eventDetails;
    const onClickHandler = () => {
      if (this.isYaNonSeatedJourney) {
        !isDataInCart && this.setVisitedEvents();
        extraCallback();
      } else {
        this.handleSuccessScenario(performanceId, isDataInCart);
      }
    };
    return (
      <div className="overlay-data">
        {!isDataInCart ? (
          <div>
            <h2 className="overlay-title heading-2" id="overlay-title">
              {tncOverlay && tncOverlay.title}
            </h2>
            <DynamicContent
              tagName="ul"
              attrs={{ className: 'overlay-list' }}
              innerHtml={tncOverlay && tncOverlay.bodyCopy}
            />
          </div>
        ) : (
          <DynamicContent
            tagName="p"
            attrs={{ className: '' }}
            innerHtml={cartEmptyWarningOverlay && cartEmptyWarningOverlay.bodyCopy}
          />
        )}
        <div className="action-line">
          <div className="btn-primary">
            <button type="button" onClick={() => onClickHandler()}>
              {isDataInCart ? cartEmptyWarningOverlay.primaryCTA : tncOverlay.primaryCTA}
            </button>
          </div>
          {isDataInCart && (
            <div className="btn-secondary">
              <button type="button" onClick={closeOverlay}>
                {cartEmptyWarningOverlay && cartEmptyWarningOverlay.secondaryCTA}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   * This function handles the affirmative response on the ovelray.
   * This function will be used to invalidate the cart or to redirect.
   * @param {string} performanceId
   * @param {boolean} isDataInCart
   */
  handleSuccessScenario = (performanceId, isDataInCart) => {
    if (isDataInCart) {
      closeOverlay();
      this.props
        .selectNewYAPerformance()
        .then(() => {
          if (!this.isEventVisited()) {
            this.openOverlay(performanceId, false);
          } else {
            this.handleRedirection(performanceId);
          }
        })
        .catch((err) => {
          this.setErrorMsg(err, 'changeDeleteYaCart');
        });
    } else {
      this.handleRedirection(performanceId);
    }
  };

  isEventVisited = () => {
    const { eventId } = this.props.data.eventDetails;
    let obj = YALocalStorageSvc.getSetVisitedEvents();
    return obj[eventId];
  };

  /**
   *  Arena Tour - checks if user is trying to select new timeslot.
   *  @param  {String}    perfId - performanceId of time slot.
   */
  isNewPerformanceSelection = (perfId) => {
    const { performanceId } = YALocalStorageSvc.getSetPerformance();

    return performanceId && performanceId !== perfId;
  };

  handleCartScenario = (performanceId, isDataInCart) => {
    if (isDataInCart) {
      this.openOverlay(performanceId, true);
    } else if (!this.isEventVisited()) {
      this.openOverlay(performanceId, false);
    } else {
      this.handleRedirection(performanceId);
    }
  };

  validateCartObj = (performance) => {
    const { performanceId } = performance;
    const { eventDetails, currency } = this.props.data;
    yaBuyTicketAnalytics({
      ...performance,
      currency,
      eventId: eventDetails.eventId,
      eventName: eventDetails.eventName,
    });

    this.props
      .getProductsList()
      .then((products) => {
        if (products.length) {
          this.handleCartScenario(performanceId, true);
        } else {
          //Empty cart scenario
          this.handleCartScenario(performanceId, false);
        }
      })
      .catch((err) => {
        this.redirectToErrorPage();
      });
  };

  redirectToErrorPage = () => {
    if (this.props.data.eventDetails && this.props.data.eventDetails.errorPageUrl) {
      window.location = this.props.data.eventDetails.errorPageUrl;
    }
  };

  getUniqueKey = ({ date, openTime, closeTime }) => `${date}_${openTime}_${closeTime}`;

  getMashedUpPerformances = (performances) => {
    const flatList = [];
    const keys = Object.keys(this.props.cartData.cart.items);
    if (keys.length) {
      keys.forEach((key) => {
        flatList.push(...this.props.cartData.cart.items[key].products);
      });
    }

    return performances.map((perf) => {
      perf.isDisabled = !perf.sellable || flatList.find((p) => p.performanceId === perf.performanceId);
      return perf;
    });
  };

  renderEvents = () => {
    if (this.props.data.eventDetails === null) return null;

    const {
      isNonSeated,
      soldOutText,
      eventPackageUrlTitle,
      eventPackageUrl,
      actionLink,
    } = this.props.data.eventDetails;
    const { clearYACart } = this.props;
    const { addToCart } = this.props.data;
    const secondaryCTA = { label: eventPackageUrlTitle, url: eventPackageUrl?.href, target: eventPackageUrl?.target };

    const performances = this.getMashedUpPerformances(this.state.performances);

    return performances.map((performance) => (
      <EventPerformance
        performanceData={performance}
        isNonSeated={isNonSeated}
        key={performance.performanceId}
        soldOutText={soldOutText}
        addToCartLabel={addToCart}
        secondaryCTA={secondaryCTA}
        validateCartAndRedirection={() => this.validateCartObj(performance)}
        isEventVisited={this.isEventVisited}
        nonSeatedJourneyTAndC={this.nonSeatedJourneyTAndC}
        showMessageWhenItemsInCart={this.showMessageWhenItemsInCart}
        isNewPerformanceSelection={this.isNewPerformanceSelection}
        clearYACart={clearYACart}
        closeOverlay={closeOverlay}
        showYaNonSeatedOverlay={this.props.showYaNonSeatedOverlay}
        cartData={this.props.cartData}
        actionLink={actionLink}
      />
    ));
  };

  render() {
    return (
      <div className="component c-fast-pass">
        <div className="w--content">
          <div className="c-fast-content">{this.state.servicesError ? this.renderErrorMsg() : this.renderEvents()}</div>
        </div>
      </div>
    );
  }
}

EventPerformanceList.PropsTypes = {
  data: PropTypes.shape({
    eventDetails: PropTypes.object.isRequired,
  }),
};

export default EventPerformanceList;
