import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DayPicker, { DateUtils } from 'react-day-picker';
import { DynamicContent, SvgSprite } from '../base';
import { logComponentRenderingError } from '../../../common/logger';

import './date-range-picker-component.scss';

const getDate = (date) => {
  return date ? new Date(date) : new Date();
};

const applyCallback = (applyDateCallback, dateFormat, from, obj, to) => {
  if (from && to && typeof applyDateCallback === 'function') {
    applyDateCallback(from, to, obj, dateFormat);
  }
};

const handleResetClick = (resetCallback) => {
  if (typeof resetCallback === 'function') {
    resetCallback();
  }
};

const DateRangePicker = (props) => {
  const { applyDateCallback, data, dateFormat, errorMsg, obj, resetCallback } = props;
  const { applyCta, endDate, numberOfMonths, resetCta, startDate } = data;

  const [from, setFrom] = useState(getDate(startDate));
  const [to, setTo] = useState(getDate(endDate));

  useEffect(() => {
    if (props.data.startDate !== from && props.data.endDate !== to) {
      setFrom(getDate(props.data.startDate));
      setTo(getDate(props.data.endDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleDayClick = (day, { disabled }) => {
    if (disabled) {
      return;
    }
    const range = DateUtils.addDayToRange(day, { from, to });
    setFrom(range.from);
    setTo(range.to);
  };

  try {
    return (
      <div className="c-component c-component-date-range-picker">
        <DayPicker
          numberOfMonths={Number(numberOfMonths) || 1}
          modifiers={{ start: from, end: to }}
          selectedDays={[from, { from, to }]}
          month={from}
          onDayClick={handleDayClick}
          disabledDays={[{ after: new Date(), before: null }]}
        />
        <div className="c-component-date-range-picker-cta">
          <button onClick={() => handleResetClick(resetCallback)}>
            <span>
              <DynamicContent tagName="span" innerHtml={resetCta} />
              <SvgSprite id="icon-reset" />
            </span>
          </button>
          <button onClick={() => applyCallback(applyDateCallback, dateFormat, from, obj, to)}>
            <span>
              <DynamicContent tagName="span" innerHtml={applyCta} />
              <SvgSprite id="icon-tick" />
            </span>
          </button>
        </div>
        {(!from || !to) && <DynamicContent tagName="div" attrs={{ className: 'errorMsg' }} innerHtml={errorMsg} />}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'DateRangePicker');
  }
};

export default DateRangePicker;

DateRangePicker.propTypes = {
  applyDateCallback: PropTypes.func,
  data: PropTypes.shape({
    applyCta: PropTypes.object,
    endDate: PropTypes.string,
    numberOfMonths: PropTypes.number,
    resetCta: PropTypes.object,
    startDate: PropTypes.string,
  }),
  dateFormat: PropTypes.string,
  errorMsg: PropTypes.string,
  obj: PropTypes.object,
  resetCallback: PropTypes.func,
};
