import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  getOrderIdpromoCode,
  removeGuestUser,
  getSessionStorage,
  isAnnualPassCase,
  createAnnualPassData,
  parseQueryString,
  getUserAgent,
  massageMobileData,
  getErrorDataInObject,
  getCurrentLanguage,
  resolvePath,
  getAnonymousCart,
  getLoggedInUser,
} from '../../../../common/utility';
import api from '../../../../common/api-wrapper';
import { Logging } from '../../../../common/logger';
import crypto from 'crypto';
import { getEncrytpedText, checkYAEventId } from '../utils';
import { setReconcilRequestAction } from '../../../../utils/paymentUtils';

export class PointsPayPayment {
  constructor({ pointsPayFinalData, hashCode, cmsData, paymentDataAnonymous }) {
    this.paymentFailure = ['canceled'];
    this.pointsPayData = pointsPayFinalData;
    this.paymentData = cmsData || {};
    this.paymentData.componentName = UIConfig.paymentOption.pointsPay;
    this.cartData = paymentDataAnonymous && paymentDataAnonymous.cart != null ? paymentDataAnonymous.cart : null;
    this.anonymousUser = canUseDOM() && this.cartData;
    this.isRenewAnnualPass = false;
    this.hashCode = hashCode;
    this.accessToken = this.paymentData.accessToken;
    this.pointsPayTypeMethod = this.paymentData.pointsPayTypeMethod;
  }

  /**
   *  Successfull Payment
   *  Send status to Native app
   */

  actionMobileApp = ({ status }) => {
    window.JSbridge.nativeCallback(
      massageMobileData({
        status: status,
        orderID: localStorage.getItem('orderId') || null,
        ref: UIConfig.paymentOption.pointsPay,
      }),
    );
  };

  /**
   *  Redirect action method
   *  if something fails
   */

  pageRedirectURL = ({ paymentData, pageUrl, status, key, responseOrError }) => {
    if (paymentData) {
      const { url, error } = paymentData;
      window.location.href = `${url.href}?payment_error=${error}#${this.pointsPayTypeMethod}`;
    } else {
      let errorData = getErrorDataInObject(key, this.paymentData.services, responseOrError);
      localStorage.setItem(UIConfig.localStoreKeys.payment.errorMessage, JSON.stringify(errorData));
      window.location.href = `${pageUrl.url}?${UIConfig.querStringParams.thankYouPage.status}=${status}&${UIConfig.querStringParams.thankYouPage.reference}=${UIConfig.paymentOption.pointsPay}`;
    }
  };

  /**
   *  Successfull Payment
   *  Complete Payment and close Payment Sheet
   *  call reconcile to close order
   */

  redirectToActionPage = ({ isSuccess, status, statusCode }) => {
    const {
        cmsData: { primaryCTA, paymentURL },
      } = this.paymentData,
      {
        pointsPayTransaction: { errors },
      } = this.paymentData.services;

    if (isSuccess) {
      setReconcilRequestAction();
      this.reconciliation()
        .then((res) => {
          const orderId = btoa(localStorage.getItem('orderId')),
            isRenewAnnualPass = this.isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
          if (res.data.isSuccess) {
            Logging(res, this.paymentData.componentName, true, 'Points pay reconcile call success');
            this.clearStorage();
            if (!getUserAgent()) {
              window.location.href = `${primaryCTA.url}?${checkYAEventId()}${
                UIConfig.querStringParams.thankYouPage.status
              }=${status}&${UIConfig.querStringParams.thankYouPage.orderId}=${orderId}&${
                UIConfig.querStringParams.thankYouPage.reference
              }=${UIConfig.paymentOption.pointsPay}${isRenewAnnualPass}`;
            } else {
              this.actionMobileApp({ status });
            }
          } else {
            Logging(res.data, this.paymentData.componentName, false, 'Reconcile Status False');
            this.pageRedirectURL({
              pageUrl: primaryCTA,
              status: false,
              key: 'pointsPayReconcile',
              responseOrError: res.data.error,
            });
          }
        })
        .catch((err) => {
          Logging(err, this.paymentData.componentName, true, 'PointsPay reconcile call error');
          this.pageRedirectURL({
            pageUrl: primaryCTA,
            status: false,
            key: 'pointsPayReconcile',
            responseOrError: err.error,
          });
        });
    } else {
      if (!getUserAgent()) {
        const pointsPayStatusCode = statusCode && statusCode.toLowerCase();
        if (this.paymentFailure && this.paymentFailure.indexOf(pointsPayStatusCode) > -1) {
          const paymentData = {
            url: paymentURL,
            error: errors[statusCode] || errors[pointsPayStatusCode],
          };
          this.pageRedirectURL({ paymentData });
        } else {
          this.pageRedirectURL({
            pageUrl: primaryCTA,
            status: false,
            key: 'pointsPayTransaction',
            responseOrError: { code: statusCode, text: '' },
          });
        }
      } else {
        this.actionMobileApp({ status });
      }
    }
  };

  getHashCode = (apiData, accessToken) => {
    let keyString = '';
    for (let key in apiData) {
      if (apiData.hasOwnProperty(key)) {
        keyString += apiData[key];
      }
    }

    const hmac = crypto.createHmac('md5', accessToken), //creating hmac object
      data = hmac.update(keyString), //passing the data to be hashed
      gen_hmac = data.digest('hex'); //Creating the hmac in the required format

    //Printing the output on the console
    return gen_hmac;
  };

  /**
   *  if payment success, move to confirmation page
   *  if fails, redirect to payment journey
   */

  moveToNextPaymentStep = () => {
    const statusCode = this.pointsPayData.status,
      hashCode = this.getHashCode(this.pointsPayData, this.accessToken); // check here for hash code
    if (statusCode && statusCode.toLowerCase() === 'success' && hashCode === this.hashCode) {
      this.redirectToActionPage({
        isSuccess: true,
        status: true,
      });
    } else {
      this.redirectToActionPage({
        isSuccess: false,
        status: false,
        statusCode,
      });
    }
  };

  /**
   *  Begin Reconcilation
   *  When successfull -  redirects to Confirmation Page.
   *  When Failed -  close payment sheet and show error
   */

  reconciliation = () => {
    let userId,
      annualPassRequest = null;

    const { transactionid } = this.pointsPayData,
      {
        emailTemplateId,
        invoiceTemplateId,
        tenantID,
        pageVariant,
        apMainPassholderRenewEmailTemplateID,
        apMainPassholderPurchaseEmailTemplateID,
        apAddPassholderRenewEmailTemplateID,
        apAddPassholderPurchaseEmailTemplateID,
        apAddPassholderNewUserRenewEmailTemplateID,
        apAddPassholderNewUserPurchaseEmailTemplateID,
      } = this.paymentData.cmsData,
      { pointsPayReconcile } = this.paymentData.services,
      getOrderIdpromoCodeTemp = getOrderIdpromoCode(),
      orderIdpromoCode = getOrderIdpromoCodeTemp ? getOrderIdpromoCodeTemp.split('_') : 'null',
      isRenewAnnualPass = pageVariant === 'annualPassRenew';

    this.isRenewAnnualPass = isRenewAnnualPass;

    const cartData = isRenewAnnualPass
        ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
        : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart)),
      orderId = localStorage.getItem('orderId');

    if (this.anonymousUser) {
      userId = resolvePath(this.cartData, 'reservationOwner.email');
    } else {
      if (cartData) {
        const isAnnualPass = isAnnualPassCase(cartData, true);
        if (isAnnualPass) {
          const annualPassTicketHolders = createAnnualPassData({ cartData });
          annualPassRequest = {
            isAnnualRenew: isRenewAnnualPass,
            emailTemplateIdAnnualPass: isRenewAnnualPass
              ? apMainPassholderRenewEmailTemplateID
              : apMainPassholderPurchaseEmailTemplateID,
            emailTemplateIdWithYasId: isRenewAnnualPass
              ? apAddPassholderRenewEmailTemplateID
              : apAddPassholderPurchaseEmailTemplateID,
            emailTemplateIdWithNoYasId: isRenewAnnualPass
              ? apAddPassholderNewUserRenewEmailTemplateID
              : apAddPassholderNewUserPurchaseEmailTemplateID,
            ticketHolders: annualPassTicketHolders,
          };
        }
      }
      const loggedInData = getLoggedInUser();
      userId = loggedInData && loggedInData.email;
    }
    const apiData = {
        locale: getCurrentLanguage(),
        tenantId: tenantID,
        orderId: orderId,
        transactionId: transactionid,
        emailTemplateId: emailTemplateId,
        invoiceTemplateId: invoiceTemplateId,
        promoCode: orderIdpromoCode[1] === 'null' ? null : orderIdpromoCode[1],
        annualPassRequest: annualPassRequest,
        paymentMethod: UIConfig.paymentOption.pointsPay,
        userId: getEncrytpedText(userId),
      },
      pointsPayConfig = {
        method: 'POST',
        url: `${pointsPayReconcile.url}?isEncrypted=true`,
        data: {
          ...apiData,
        },
      };

    if (this.anonymousUser) {
      return api.api(pointsPayConfig);
    } else {
      return api.experienceServices(pointsPayConfig);
    }
  };

  /**
   *  clear stored data on localstorage
   */

  clearStorage = () => {
    if (this.isRenewAnnualPass) {
      sessionStorage.removeItem('cartForAnnualPassRenewal');
      sessionStorage.removeItem(UIConfig.payloadForRenew);
      sessionStorage.removeItem('annualPassRenewalData');
    }
    localStorage.removeItem('payment_info');
    localStorage.removeItem('payfort_data');
    localStorage.removeItem('isOrderHistoryPayment');
    localStorage.removeItem('cms_data');
    localStorage.removeItem('resCode');
    localStorage.removeItem('yasIdUserData');
    removeGuestUser();
  };
}

export const initializePointsPay = (props) => {
  const cmsData = JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.payment.cmsData)),
    paymentDataAnonymous = getAnonymousCart(),
    status = parseQueryString('status'),
    msg = parseQueryString('msg'),
    order = parseQueryString('order'),
    transactionid = parseQueryString('guid'),
    pointsAmount = parseQueryString('points_amount'),
    cashAmount = parseQueryString('cash_amount'),
    pointsRedeemed = parseQueryString('points_redeemed'),
    pointsAccrued = parseQueryString('points_accrued'),
    hashCode = parseQueryString('hash'),
    pointsPayFinalData = {
      ...(status && { status }),
      ...(msg && { msg }),
      ...(order && { order }),
      ...(transactionid && { transactionid }),
      ...(pointsAmount && { pointsAmount }),
      ...(cashAmount && { cashAmount }),
      ...(pointsRedeemed && { pointsRedeemed }),
      ...(pointsAccrued && { pointsAccrued }),
    },
    PointsPay = new PointsPayPayment({ pointsPayFinalData, hashCode, cmsData, paymentDataAnonymous });
  PointsPay.moveToNextPaymentStep();
};
