import React, { useEffect, useState } from 'react';
import './managebookinghowerbox.scss';

const ManageBookingHowerBox = ({ data }) => {
  const [toolTipData, setToolTipData] = useState({});

  useEffect(() => {
    let bookingField = data?.sections[0]?.fields?.find((field) => field.id === 'BookingRefId');

    let result = {
      heading: bookingField?.tooltip,
      showToolTip: bookingField?.showToolTip,
      tooltipDescription: bookingField?.tooltipDescription,
      tooltipImage: bookingField?.tooltipImage,
    };
    setToolTipData(result);
  }, [data]);

  return (
    <div className="CTA-filter-dropdown">
      <div className="frame">
        <div className="div">
          <div className="manage-booking">{toolTipData?.heading}</div>
          <div className="frame-2">
            <img className="img" alt="Yas ticket" src={toolTipData?.tooltipImage} />
          </div>
          <p className="button-text">{toolTipData?.tooltipDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default ManageBookingHowerBox;
