import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import ApiWrapper from './api-wrapper';
import { ServiceConfig } from './services';
import UIConfig from './UIConfig';
import { generateGUID, getDuration, getSearchHubValues } from './utility';

/**
 * here are few important Paramenters which are being during making Coveo Ajax Call

 * q : The basic query expression.
        This is typically the query expression entered by the user in a query box. Since this part of the query is expected to come from user input, it is processed by the Did You Mean feature.
 * aq: The advanced query expression.
        This is the part of the query expression generated by code based on various rules.
        ex. (@fparkskey18441==("FWDEC1"))(@fparkskey18441==("FERRARI"))(@fproductid18441==(FPK.EVN1.MCC248,FPK.EVN2.MCC229,FPK.EVN7.MCC542))(@fmarkettypevalues18441==LOC)(@fz95xtemplatename18441==product)(@flanguage18441==en)(@fsearchtabcategory18441==ADD)"

 * wildcards:   Whether to enable wildcards on the basic expression keywords.
                This enables the wildcard features of the index. Coveo Platform will expand keywords containing wildcard characters to the possible matching keywords to broaden the query.
                If not specified, this parameter defaults to false.

* firstResult:  Index of the first result to return.
                This is the 0-based index of the first result to return.
                If not specified, this parameter defaults to 0.

* numberOfResults:  The number of results to return.
                    This is the number of results to return, starting from firstResult.
                    If not specified, this parameter defaults to 10.

* fieldsToInclude: Fields that should be included in the query results.
                    This specifies an array of fields that should be returned for each result. Use the syntax for a JSON array when passing this value through the query string (e.g., fieldsToInclude=["foo", "bar"])
                    If not specified, all fields are returned by default.

* groupBy:   Definition of the facets to compute on the results.
             This specifies an array of Group By operations that can be performed on the query results to extract facets (see Group By Parameters).


* queryOverride:  Override the query on which the Group By operation is performed
This specifies a different query expression on which to compute the Group By operation. By default, the query expression being executed is used.
This feature is typically used for performance reasons to retrieve Group By values on separate expressions while executing a normal query in a single operation.
It work same as advance query for free text search (aq), but in autocomplte we use "queryOverride" for autocomplete value

*/

/**
 * get Free text Search for Search Results Page
 */

export const getSearchContent = ({
  query = '',
  perPageResults = 1,
  fieldsToInclude = [],
  pageNo = 0,
  coveoKeyMap = {},
  serviceUrl = '',
  tenantId = 'ALLB2B',
  language = 'en',
  enableDidYouMean = false,
  pipeline,
  searchHub,
  referrer,
  wildcards = false,
  showinmobileonly,
  showinmobileonlyquery = '',
  tab,
  preLoader = false,
  preLoaderTarget = '.container',
}) => {
  const contentResults = {};
  contentResults.results = [];
  wildcards = enableDidYouMean ? false : wildcards;
  if (showinmobileonly) {
    showinmobileonlyquery = `(@${coveoKeyMap.showinmobileonly} == "0")`;
  }
  const start = new Date();
  return ApiWrapper.coveoService({
    url: serviceUrl,
    method: 'POST',
    preLoader,
    preLoaderTarget,
    data: {
      q: wildcards ? `${query}*` : query,
      numberOfResults: perPageResults,
      firstResult: pageNo,
      aq: `(@${coveoKeyMap.haslayout}=="1")(@${coveoKeyMap.templatename}<>Product)(@${coveoKeyMap.includeinsearch} == "1")(@${coveoKeyMap.tenantId} == ${tenantId})(@${coveoKeyMap.language}== ${language})${showinmobileonlyquery}`,
      fieldsToInclude: fieldsToInclude,
      enableQuerySyntax: false,
      enableDidYouMean: enableDidYouMean,
      wildcards: wildcards,
      pipeline: pipeline,
      searchHub: searchHub,
      referrer: referrer,
      tab: tab,
    },
  })
    .then(function(coveoData) {
      contentResults.totalCount = coveoData.data.totalCount;
      contentResults.searchAPIDuration = coveoData.data.duration;
      contentResults.duration = getDuration(start, new Date());
      contentResults.searchUid = coveoData.data.searchUid ? coveoData.data.searchUid : generateGUID();
      contentResults.pipeline = coveoData.data.pipeline;
      contentResults.correctedQuery =
        enableDidYouMean && coveoData.data.queryCorrections.length
          ? coveoData.data.queryCorrections[0].correctedQuery
          : null;
      coveoData.data.results.forEach(function(v, i) {
        v.raw.searchUid = contentResults.searchUid;
        v.raw.pipeline = contentResults.pipeline;
        v.raw.index = i;
        v.raw.rankingModifier = v.rankingModifier;
        contentResults.results.push(v.raw);
      });
      return contentResults;
    })
    .catch((error) => Promise.reject(error));
};

/**
 * get content for Advanced Search Page
 */

export const getAdvancedSearchContent = (data) => {
  let serviceURL,
    metricsQuery,
    dimensionQuery,
    orderQuery,
    contentResults = [];

  data.metrics = data.metrics.split(',');
  data.metrics.forEach((e) => {
    if (metricsQuery !== 'undefined' && metricsQuery !== undefined) {
      metricsQuery += '&m=' + e;
    } else {
      metricsQuery = '&m=' + e;
    }
  });
  data.dimensions = data.dimensions.split(',');
  data.dimensions.forEach((e) => {
    if (dimensionQuery !== 'undefined' && dimensionQuery !== undefined) {
      dimensionQuery += '&d=' + e;
    } else {
      dimensionQuery = '&d=' + e;
    }
  });
  data.order = data.order.split(',');
  data.order.forEach((e) => {
    if (orderQuery !== 'undefined' && orderQuery !== undefined) {
      orderQuery += '&s=' + e;
    } else {
      orderQuery = '&s=' + e;
    }
  });

  serviceURL =
    data.url +
    `?access_token=${data.access_token}${metricsQuery}&from=${data.fromDate}&to=${data.toDate}&tz=${data.timezone}${dimensionQuery}${orderQuery}&f=${data.filter}&p=${data.page}&n=${data.numberofresults}&asc=${data.ascending}&includeMetadata=${data.includeMetadata}`;
  return ApiWrapper.coveoService({
    url: serviceURL,
    method: data.method,
    preLoader: data.preLoader || false,
    preLoaderTarget: '.container',
  })
    .then(function(coveoData) {
      contentResults = coveoData.data;
      return contentResults;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

/**
 * get content for Listing pages (B2C)
 */

export const getListContent = ({
  perPageResults = 10,
  fieldsToInclude = [],
  coveoKeyMap = {},
  serviceUrl = '',
  tenantId = '',
  language = '',
  slideFilter = {},
  facetFilter = {},
  calenderFilter = {},
  venueFilter = {},
  multiSelectFilters = {},
  expirendEventsFilter = null,
  groupBy = [],
  contentType = '',
  coveoToken = '',
  sortField = '',
  sortCriteria = '',
  defaultParams = null,
  categoryKey = null,
  productEventType = null,
  excludeProductId = null,
  includeProductIds = '',
  firstResult = 0,
  tabType = null,
  availabilityFilter = null,
}) => {
  let slideFilterQuery = '',
    facetFilterQuery = '',
    calenderFilterQuery = '',
    defaultParamsQuery = '',
    venueFilterQuery = '',
    multiSelectFilterQuery = '',
    expirendEventsFilterQuery = '',
    categoryKeyQuery = '',
    productEventTypeQuery = '',
    filterExcludeProductQuery = '',
    includeProductIdsQuery = '',
    tabTypeQuery = '',
    availabilityFilterQuery = '',
    disableOnListingPageQuery = '';

  const { listingSearchHub } = getSearchHubValues();
  for (const keys in slideFilter) {
    if (slideFilter.hasOwnProperty(keys)) {
      slideFilterQuery += `(${keys}<=${slideFilter[keys]})`;
    }
  }

  for (const keys in facetFilter) {
    if (facetFilter.hasOwnProperty(keys)) {
      facetFilterQuery += `(${facetFilter[keys]})`;
    }
  }

  for (const keys in calenderFilter) {
    if (calenderFilter.hasOwnProperty(keys)) {
      calenderFilterQuery += `(${calenderFilter[keys]})`;
    }
  }

  for (const keys in defaultParams) {
    if (defaultParams.hasOwnProperty(keys)) {
      if (isExperienceEditorActive() && keys.includes('ftenantinfo')) {
        const tenantName = document.querySelector('.container').classList[1].split('-')[1];

        defaultParams[keys] = `${UIConfig.coveoMapping[tenantName]}B2C`;
      }
      defaultParamsQuery += `(${'@' + keys}=${defaultParams[keys]})`;
    }
  }
  for (const keys in venueFilter) {
    if (venueFilter.hasOwnProperty(keys)) {
      venueFilterQuery += `(${keys}=${venueFilter[keys]})`;
    }
  }
  for (const keys in multiSelectFilters) {
    if (multiSelectFilters.hasOwnProperty(keys) && multiSelectFilters[keys].length > 0) {
      multiSelectFilterQuery += `(@${keys}=("${multiSelectFilters[keys].join('","')}"))`;
    }
  }
  if (expirendEventsFilter) {
    expirendEventsFilterQuery = expirendEventsFilter;
  }

  if (categoryKey) {
    categoryKeyQuery = `(@${coveoKeyMap.categoryKey}==${categoryKey})`;
  }

  if (productEventType) {
    productEventTypeQuery = `(@${coveoKeyMap.productEventType}==${productEventType})`;
  }
  if (excludeProductId) {
    filterExcludeProductQuery = `(@${coveoKeyMap.productid}<>${excludeProductId})`;
  }
  if (includeProductIds) {
    includeProductIdsQuery = `(@${coveoKeyMap.productid}==(${includeProductIds}))`;
  }

  if (tabType) {
    tabTypeQuery = `(@${coveoKeyMap.groupBy}=${tabType})`;
  }

  if (availabilityFilter) {
    availabilityFilterQuery = `(@${coveoKeyMap.soldoutkey}=0)`;
  }

  if (coveoKeyMap.hasOwnProperty('disableOnListingPage')) {
    disableOnListingPageQuery = `(@${coveoKeyMap.disableOnListingPage}=0)`;
  }

  return ApiWrapper.coveoService({
    url: serviceUrl,
    method: 'POST',
    coveoToken: coveoToken,
    preLoader: true,
    preLoaderTarget: 'body',
    data: {
      numberOfResults: perPageResults,
      aq: `(@${coveoKeyMap.templateName}==${contentType})${slideFilterQuery}${availabilityFilterQuery}${facetFilterQuery}${calenderFilterQuery}${defaultParamsQuery}${venueFilterQuery}${multiSelectFilterQuery}${expirendEventsFilterQuery}${includeProductIdsQuery}${productEventTypeQuery}${categoryKeyQuery}${filterExcludeProductQuery}${tabTypeQuery}${disableOnListingPageQuery}(@${coveoKeyMap.language}==${language})`,
      fieldsToInclude: fieldsToInclude,
      groupBy: groupBy,
      sortField: sortField,
      sortCriteria: sortCriteria,
      searchHub: listingSearchHub || '',
      firstResult: firstResult,
    },
  }).then(function(coveoData) {
    return coveoData.data;
  });
};

/***
 * auto suggest for input text
 */

export const getQuerySuggestions = ({
  count = 5,
  enableWordCompletion = 'true',
  autocompleter = 'true',
  pipeline = undefined,
  query = '',
  locale = 'en',
  serviceUrl = '',
  searchHub = 'default',
  visitorId = '',
  isGuestUser = 'false',
}) => {
  return ApiWrapper.coveoService({
    url: serviceUrl,
    method: 'POST',
    data: {
      count: count,
      enableWordCompletion: enableWordCompletion,
      pipeline: pipeline,
      q: query,
      locale: locale,
      searchHub: searchHub,
      visitorId: visitorId,
      isGuestUser: isGuestUser,
      autocompleter: autocompleter,
    },
  })
    .then(function(coveoData) {
      const comparator = [];
      coveoData.data.completions.forEach((item) => {
        comparator.push({
          value: item.expression,
        });
      });
      return comparator;
    })
    .catch((error) => Promise.reject(error));
};

/**
 * get Autocomplete for Search text input
 */

export const getAutoCompleteResults = ({
  query = '',
  coveoKeyMap = {},
  lang = 'en',
  autoSuggestLimit = 5,
  serviceUrl = '',
  tenantId = 'ALLB2B',
}) => {
  return ApiWrapper.coveoService({
    url: serviceUrl,
    method: 'POST',
    data: {
      field: '@' + coveoKeyMap.title,
      maximumNumberOfValues: autoSuggestLimit,
      queryOverride: `(@${coveoKeyMap.includeinsearch}=="1")(@${coveoKeyMap.haslayout}=="1")(@${coveoKeyMap.templatename}<>Product)(@${coveoKeyMap.language}==${lang})(@${coveoKeyMap.tenantId}==${tenantId})`,
      patternType: 'wildcard',
      pattern: '*' + query + '**',
    },
  }).then(function(coveoData) {
    return coveoData.data;
  });
};

/**
 * get product details for booking journey
 */

export const getProductDetails = ({
  perPageResults = 10,
  productResults = {},
  coveoKeyMap = {},
  ticketType = {},
  marketType = '',
  filtersLogic = [],
  productIdList = '',
  genricFilters = '',
  excluefilters = '',
  searchTemplate = 'product',
  lang = 'en',
  filterExcludeFields = [],
  fieldsToInclude = [],
  groupBy = [],
  preLoaderTarget = UIConfig.loader.defaultPreLoaderTarget,
  serviceUrl = '',
  sortQueryValue = {},
}) => {
  const groupbyResults = [];
  const { listingSearchHub } = getSearchHubValues();
  let filter = '';

  groupBy.forEach(function(v) {
    groupbyResults.push({ field: '@' + v });
  });

  /**
   * since every filter is having the feature of "AND" / "OR" logic,
   * below logic will make the query based on "AND / "OR" operation and this will return [filter] as a string to pass on advance query
   */

  if (filtersLogic.length) {
    filtersLogic.forEach(function(v) {
      var currentFilter = v,
        filterString = '',
        temp;
      if (v.logic === 'AND') {
        v.fields.forEach(function(k) {
          filterString += `(@${currentFilter.filterField}==("${k}"))`;
        });
        filter += filterString;
      } else {
        filterString = v.fields.toString().replace(/,/g, '","');
        temp = `("${filterString}")`;
        filter += `(@${v.filterField}==${temp})`;
      }
    });
  }

  const data = {
    numberOfResults: perPageResults,
    groupBy: groupbyResults,
    fieldsToInclude: fieldsToInclude,
    aq: `((@${coveoKeyMap.class}=="") OR (@${coveoKeyMap.class}<>JUNIOR))(@${coveoKeyMap.language}==${lang})(@${
      coveoKeyMap.isDeleted
    }<>1)${filter}${productIdList.length ? `(@${coveoKeyMap.productId}==(${productIdList.toString()}) OR` : '('}(@${
      coveoKeyMap.isGeneric
    } == 1))(@${coveoKeyMap.marketType}==${marketType})(@${coveoKeyMap.templatename}==${searchTemplate})(@${
      ticketType.tab
    }==${ticketType.value})`,
    searchHub: listingSearchHub || '',
  };
  if (sortQueryValue.fieldToSort && sortQueryValue.sortOrder) {
    data.sortCriteria = sortQueryValue.sortOrder;
    data.sortField = sortQueryValue.fieldToSort;
  }

  const productDetails = ApiWrapper.coveoService({
    preLoaderTarget,
    url: serviceUrl,
    method: 'POST',
    preLoader: true,
    data: data,
  }).then(function(coveoData) {
    productResults.results = [];
    productResults.filters = [];
    productResults.totalCount = coveoData.data.totalCount;
    coveoData.data.results.forEach(function(v) {
      productResults.results.push(v.raw);
    });

    productResults.filters = coveoData.data.groupByResults.length ? coveoData.data.groupByResults : [];
    return productResults;
  });

  return productDetails;
};

/**
 * get Product types and package type for passed Product ID --- Currently used in orders

 */

export const getProductTypes = ({
  coveoKeyMap = {},
  productIdList = '',
  fieldsToInclude = [],
  lang = 'en',
  preLoaderTarget = '',
  numberOfResults = UIConfig.coveoResultsPerPage, // if we dont pass numberOfResults then Coveo will only return 10 results by deafult
  url,
}) => {
  const productTypes = [];
  const { listingSearchHub } = getSearchHubValues();

  return ApiWrapper.coveoService({
    url: url,
    method: 'POST',
    preLoader: true,
    preLoaderTarget: preLoaderTarget,
    data: {
      fieldsToInclude: fieldsToInclude,
      numberOfResults: numberOfResults,
      aq: `(@${coveoKeyMap.Code}==(${productIdList.toString()}))(@${coveoKeyMap.templatename}==product)(@${
        coveoKeyMap.language
      }==${lang})`,
      searchHub: listingSearchHub || '',
    },
  }).then(function(coveoData) {
    coveoData.data.results.forEach(function(v) {
      productTypes.push(v.raw);
    });

    return productTypes;
  });
};

/**
 * it's Query Builder which will retrun data accroding to the coveo queries
 */

export const queryToCoveo = (data) => {
  return ApiWrapper.coveoService({
    url: ServiceConfig.URL.COVEO_SERVICE,
    method: 'POST',
    data: data,
  }).then(function(coveoData) {
    return coveoData;
  });
};

/**
 * this function is for b2c purchase journey
 */
export const getB2CProductDetails = ({
  perPageResults = 10,
  productResults = {},
  coveoKeyMap = {},
  queryParams = [],
  searchTemplate = 'ProductB2C',
  lang = 'en',
  fieldsToInclude = [],
  sortField,
  sortCriteria,
  preLoaderTarget = UIConfig.loader.defaultPreLoaderTarget,
  serviceUrl = '',
  isPreLoader = true,
  excludeDisabled = false,
}) => {
  let query = '';
  const { listingSearchHub } = getSearchHubValues();

  //building query as per the provided params
  queryParams.forEach((v) => {
    if (v.value && v.key) {
      if (v.value instanceof Array) {
        /**
         * since every array param is having the feature of "AND" / "OR" logic,
         * below logic will make the query based on "AND / "OR" operation
         */
        if (v.logic === 'AND') {
          const sv = v.value,
            l = sv.length;
          for (let i = 0; i < l; i++) {
            query += `(@${v.key} ${sv[i].selected ? '==' : '<>'} ${sv[i].searchVal})`;
          }
        } else if (v.logic === 'NOT') {
          query += `(@${v.key} <> ("${v.value.toString().replace(/,/g, '","')}"))`;
        } else {
          query += `(@${v.key} == ("${v.value.toString().replace(/,/g, '","')}"))`;
        }
      } else {
        query += `(@${v.key} == ${v.value})`;
      }
    }
  });

  let disabledProducts = '';
  if (excludeDisabled) {
    disabledProducts = `(@${coveoKeyMap.disabledProduct}==0)`;
  }
  const productDetails = ApiWrapper.coveoService({
    preLoaderTarget,
    url: serviceUrl,
    method: 'POST',
    preLoader: isPreLoader,
    data: {
      numberOfResults: perPageResults,
      fieldsToInclude: fieldsToInclude,
      sortField: sortField,
      sortCriteria: sortCriteria,
      aq: `(@${coveoKeyMap.language}==${lang})(@${coveoKeyMap.isDeleted}<>1)(@${coveoKeyMap.templatename}==${searchTemplate})${disabledProducts}${query}`,
      searchHub: listingSearchHub || '',
    },
  }).then((coveoData) => {
    productResults.results = [];
    productResults.totalCount = coveoData.data.totalCount;
    coveoData.data.results.forEach((v) => {
      productResults.results.push(v.raw);
    });
    return productResults;
  });

  return productDetails;
};
