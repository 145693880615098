export const analyticsActionCauseList = {
  searchboxSubmit: {
    name: 'searchboxSubmit',
    type: 'search box',
  },
  searchFromLink: {
    name: 'searchFromLink',
    type: 'interface',
  },
  omniboxAnalytics: {
    name: 'omniboxAnalytics',
    type: 'omnibox',
  },
  documentOpen: {
    name: 'documentOpen',
    type: 'document',
  },
  pagerNumber: {
    name: 'pageNumber',
    type: 'getMoreResults',
  },
};
