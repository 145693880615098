import React from 'react';
import { logComponentRenderingError } from '../../../common/logger';
import { LogLevels } from '../../../common/constants';

const NullCheckHoc = (WrappedComponent, isDCR) => {
  return (props) => {
    const data = isDCR
      ? props && props.fields && (Object.keys(props.fields).length || props.fields.renderingType)
      : props &&
        props.fields &&
        ((props.fields.data && Object.keys(props.fields.data).length) || props.fields.renderingType);
    if (data) {
      return <WrappedComponent {...props} />;
    } else {
      const { componentName } = props.rendering || props;

      return logComponentRenderingError(
        { message: 'Empty props' },
        `${componentName} NullCheckHoc`,
        '',
        LogLevels.Warning,
      );
    }
  };
};

export default NullCheckHoc;
