import React, { useState, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import IAMPopup from '../iam-popup/iam-popup';
import { detectViewPort, openIAMOverlay, canUseDOM, decodeQueryString } from '../../../common/utility';
import { UIConfig, resolvePath } from '../../../..';
import { checkTenant } from '../../../common/utility/tenantsUtils';
import Tooltip from '../../presentation/tooltip/tooltip-component';
import { Overlay, DynamicContent } from '../base';
import { setLoginGTM } from '../../../common/analytics-events';

const SignInBox = ({ data, propData, yasIdExist, isGuestUser }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isElementHide, setIsElementHide] = useState(false);

  useEffect(() => {
    const params = decodeQueryString();
    if (window.location.hostname.includes(UIConfig.locationSelectorType.varient1)) {
      setModalOpen(true);
    }

    document.body.classList.add('formAndPayment');
    if ((checkTenant(UIConfig.YAS) && 'payment_error' in params) || !isGuestUser) {
      document.body.classList.remove('formAndPayment');
      data?.isGuestFormPopup && setIsElementHide(true);
    }
  }, []);

  const toggleGuestFormAndPayment = () => {
    document.body.classList.remove('formAndPayment');
    setIsElementHide(true);
  };

  return (
    <section
      className={`guest-checkout-wrapper-container ${
        checkTenant(UIConfig.yasArenaB2CTenant) ? 'club-lounge-addon' : ''
      }`}
    >
      <div className="signin-wrapper">
        {propData && propData.sections.length === 1 && propData.sections[0].fields[0].id === UIConfig.isLogInSignUp ? (
          <div className="past-purchased-login login-mypass-btn">
            <button
              type="button"
              className="form-wrapper-buttons-anchor"
              tabIndex={yasIdExist ? '-1' : '0'}
              onClick={() => {
                openIAMOverlay(<IAMPopup loginURL={propData.sections[0].fields[0].href} />);
                setLoginGTM();
              }}
            >
              {propData && propData.sections[0].fields[0].label}
            </button>
          </div>
        ) : (
          <div className={`signin-box ${isElementHide ? 'active' : ''}`}>
            <div className="signin-box-title">
              <Text tag="p" field={{ value: data.signInTitle, editable: data.signInTitle }} />
            </div>
            {data.toolTipText && (
              <Tooltip
                onHover={true}
                wrapperClass="signin-box__tooltip"
                buttonClass="signin-box__tooltip-infotext"
                buttonInnerHtml={
                  <div className="infotext">
                    <span className="sr-only">{data.toolTipText}</span>
                  </div>
                }
                tabIndex="1"
                ariaLabel={data?.toolTipText}
              >
                <div className="signin-box__tooltip-body">
                  <DynamicContent tagName="div" innerHtml={data.toolTipText} attrs={{ className: 'description' }} />
                </div>
              </Tooltip>
            )}
            <div
              aria-label={data?.loginCta ? data?.loginCta?.label : UIConfig.loginCta.label}
              className="signin-box-text"
              onClick={() =>
                data?.isGuestFormPopup
                  ? toggleGuestFormAndPayment()
                  : resolvePath(data, 'loginCta.href') && openIAMOverlay(<IAMPopup loginURL={data.loginCta.href} />)
              }
            >
              <Text
                tag="p"
                className={`cta-button ${detectViewPort() === UIConfig.viewportTypes.mobile ? 'secondary' : 'iconCta'}`}
                field={{ value: data.signInText, editable: data.signInText }}
              />
            </div>
            {isModalOpen && <IAMPopup loginURL={data.loginCta.href} />}
            {/* Overlay component holds login iframe */}
            <Overlay />
          </div>
        )}
      </div>
    </section>
  );
};

export default SignInBox;
