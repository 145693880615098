import React, { useState, useEffect } from 'react';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  getLoggedInUser,
  isAddonProduct,
  isF1SeatedTypeProduct,
  isYasArenaJourney,
  resolvePath,
  parseQueryString,
  detectMobile,
  checkTenant,
} from '../../../../common/utility';
import { SvgSprite } from '../../../presentation/base';
import CartActions from '../../b2c-purchase-journey/cart/cart-actions';
import GTMData from '../../b2c-purchase-journey/gtm-data';
import subscribeUpdateCartCount from './CartConfig';

let cartActions = null;

const CartCount = ({
  data,
  variant,
  scrollToAddons,
  isHeaderMiniCartEnabled,
  isFwad,
  isHeaderMiniCartEnabledOnBookingToPaymentPages,
  hideCart,
  setHideCart,
  cartTotalQty,
}) => {
  const [cartTotalQuantity, setCartTotalQuantity] = useState(0);
  const [isAnnualPassPage, setIsAnnualPassPage] = useState(false);

  useEffect(() => {
    if (subscribeUpdateCartCount[variant]) {
      subscribeCart();
      updateCartIcon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (checkTenant(UIConfig.iamMapping.fwad) ||
        checkTenant(UIConfig.iamMapping.wbw) ||
        checkTenant(UIConfig.iamMapping.yww)) &&
      detectMobile() &&
      document.body.classList.contains('buy-annual-pass--page')
    ) {
      setIsAnnualPassPage(true);
    }
    return () => {
      setIsAnnualPassPage(false);
    };
  }, []);

  const subscribeCart = () => {
    cartActions = new CartActions({
      serviceUrl: data.services,
      moduleName: 'header',
      tenantId: getLoggedInUser().tenantID,
    });

    if (canUseDOM()) {
      window.PubSub.subscribe(UIConfig.events.UPDATE_HEADER_CART, updateCartIcon);
    }
  };

  const toggleAnnualPassMiniCart = () => {
    let bodyRef = document.getElementsByTagName('body')[0];
    let headerRef = document.getElementsByClassName('c-header')[0];
    let stickyRef = document.getElementsByClassName('show-cart-content')[0];
    if (((isAnnualPassPage && stickyRef) || (!isAnnualPassPage && !stickyRef)) && parseInt(cartTotalQty)) {
      bodyRef.classList.remove('disable-scroll');
      headerRef.classList.remove('show-cart-content');
    } else {
      bodyRef.classList.add('disable-scroll');
      headerRef.classList.add('show-cart-content');
    }
  };

  const updateCartIcon = (msg, data) => {
    if (data) {
      setCartTotalQuantity(data.totalQuantity);
    } else {
      cartActions &&
        cartActions
          .getCartOnPageLoad()
          .then((res) => {
            setCartTotalQuantity(parseInt(res.data.cart.totalQuantity));
            const f1SeatedProduct =
              res.data.cart &&
              res.data.cart.items &&
              res.data.cart.items.find((item) => {
                return isF1SeatedTypeProduct(item);
              });

            GTMData.push('cartItems', {
              products: resolvePath(res.data.cart, 'items', []),
            });
            if (parseQueryString('status') === 'false' || parseQueryString('payment_error')) {
              GTMData.push('cartItems', {
                products: resolvePath(res.data.cart, 'items', []),
                otherEvent: UIConfig.failedTransaction,
              });
            }

            if (isYasArenaJourney()) {
              const yaProduct =
                res.data.cart && res.data.cart.items && res.data.cart.items.find((item) => !isAddonProduct(item));

              if (yaProduct) {
                scrollToAddons();
                window.PubSub.publish('startCartTimer', {
                  startDate: yaProduct.timeStamp,
                });
              }
            }

            if (getLoggedInUser().tenantID && getLoggedInUser().tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
              if (f1SeatedProduct) {
                window.PubSub.publish('startCartTimer', {
                  startDate: f1SeatedProduct.timeStamp,
                });
              } else {
                window.PubSub.publish('stopCartTimer');
              }
            }
          })
          .catch((res) => {
            if (getLoggedInUser().tenantID && getLoggedInUser().tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
              window.PubSub.publish('stopCartTimer');
            }
            setCartTotalQuantity(0);
          });
    }
  };

  if (isFwad && isHeaderMiniCartEnabled && detectMobile()) {
    return (
      <button
        className="c-header-top-icon cart"
        id="header-cart"
        onClick={(e) => {
          e.stopPropagation();
          const isMiniCartVisible = document.querySelector('.cart-dropdown-wrap.showCart');
          const isMiniCart = document.querySelector('.c-header-top-icon.cart');
          if (isMiniCartVisible && isMiniCart) {
            isMiniCart.click();
          }
          if (isAnnualPassPage) {
            toggleAnnualPassMiniCart();
          }
          document.body.classList.toggle('minicart-expanded');
          const crossIcon = document.querySelector('#hamburger-cross-icon');
          if (crossIcon) {
            document.querySelector('#hamburger-cross-icon').click();
          }
          if (isHeaderMiniCartEnabledOnBookingToPaymentPages) {
            window.PubSub.publish(UIConfig.headerCartClick, { isClicked: true });
          } else {
            window.PubSub.publish('miniCartToggle', { isOpen: !hideCart });
            setHideCart(!hideCart);
          }
        }}
        tabIndex="0"
        aria-label={cartTotalQty}
      >
        <SvgSprite id="icn-cart" cssClass="cart-icon" />
        {parseInt(cartTotalQty) ? <p className="total-quantity"> {cartTotalQty} </p> : null}
      </button>
    );
  }

  return (
    <a
      href={data.cartCta.href}
      role="button"
      className="c-header-top-icon cart"
      aria-label={(data.cartCta.basketAriaLabel || '') + 2}
    >
      <SvgSprite id="icn-cart" cssClass="cart-icon" />
      <span className="sr-only">Link to Cart</span>
      {(cartTotalQty || cartTotalQuantity) > 0 && (
        <p className="total-quantity"> {cartTotalQty || cartTotalQuantity} </p>
      )}
    </a>
  );
};

export default CartCount;
