import React, { useEffect, useState } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import UIConfig from '../../../common/UIConfig';
import { checkTenant } from '../../../common/utility';
import classNames from 'classnames';

const MultiColumnSublayout = (props) => {
  const { placeholders, params } = props;
  const keys = Object.keys(placeholders || {});
  const classMapping = UIConfig.multiColumnClassMapping;

  // SWAD Map Component Swap

  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const showCardComponentFirstInImageMap = params.showCardComponentFirstInImageMapView === 'true';
  const [activeComponentValue, setActiveComponentValue] = useState(
    showCardComponentFirstInImageMap ? 'ImageMapView' : 'HeroMap',
  );
  const [backgroundImage, setBackgroundImage] = useState();

  useEffect(() => {
    let placeholder = { ...props.placeholders };
    let backgroundImage = '';
    Object.keys(placeholder).forEach((componentKey) => {
      placeholder[componentKey].forEach((component) => {
        if (
          component?.fields?.data?.backgroundImage !== null &&
          component?.fields?.data?.backgroundImage !== undefined
        ) {
          backgroundImage = component.fields.data.backgroundImage;
        }
      });
    });
    setBackgroundImage(backgroundImage);
  }, [backgroundImage]);

  useEffect(() => {
    window.PubSub.subscribe(UIConfig.swapMapView, (msg, data) => {
      if (data.isMapviewClick != activeComponentValue) {
        setActiveComponentValue(data.isMapviewClick);
      }
      if (data.isMapviewClick === 'HeroMap') {
        window.PubSub.publish(UIConfig.reSizeMapComponent, { reSize: true });
      }
    });
  }, [activeComponentValue]);

  // SWAD Map Component Swap

  return (
    <div
      className={`${params.cssAdditionalClass} ${params.cssClassName} ${
        showCardComponentFirstInImageMap ? 'reverse-component' : ''
      }`}
      style={{ backgroundImage: `url(${backgroundImage ? backgroundImage : ''})` }}
    >
      {params.cssClassName === 'container' || (isSwad && params.imageMapViewEnable) ? (
        <div
          className={classNames(params.cssRowClassName, {
            imageMapViewDisplay:
              isSwad && activeComponentValue !== 'HeroMap' && activeComponentValue === 'ImageMapView',
            imageMapViewDisplayReverse:
              isSwad && activeComponentValue === 'HeroMap' && activeComponentValue !== 'ImageMapView',
          })}
        >
          <div
            className={`${
              showCardComponentFirstInImageMap ? params.cssRightColumnClassName : params.cssLeftColumnClassName
            }`}
          >
            <Placeholder
              name={showCardComponentFirstInImageMap ? 'jss-col-2' : 'jss-col-1'}
              rendering={{ placeholders }}
            />
          </div>
          <div
            className={
              showCardComponentFirstInImageMap ? params.cssLeftColumnClassName : params.cssRightColumnClassName
            }
          >
            <Placeholder
              name={showCardComponentFirstInImageMap ? 'jss-col-1' : 'jss-col-2'}
              rendering={{ placeholders }}
            />
          </div>
        </div>
      ) : (
        keys.map((name) => (
          <>
            <div key={name} className={params.cssClassName === 'component-two-column' ? classMapping[name] : ''}>
              <Placeholder name={name} rendering={{ placeholders }} />
            </div>
          </>
        ))
      )}
    </div>
  );
};

export default MultiColumnSublayout;
