import React, { Component } from 'react';
import PaymentFormComponent from '../base/payment-form/payment-form-component';
import RichText from '../../../presentation/base/rich-text/rich-text-component';
import { Logging } from '../../../../common/logger';
import Button from '../../../presentation/base/button/button-component.js';
import ADCBTouchPointsStep2 from './adcb-touchpoints-step2';
import UIConfig from '../../../../common/UIConfig';
import classNames from 'classnames';
import {
  cardInputInitializer,
  handleCardNumberPaste,
  expiryInputInitializer,
  cvvInputInitializer,
  rsaEncryption,
  getValidityFormatted,
} from '../../../../common/payment-action/actions';
import {
  getLoggedInUser,
  getAnonymousCart,
  resolvePath,
  isLoggedInUser,
  isEnvDevelopment,
  checkTenant,
  canUseDOM,
  checkParksTenants,
  getLocalStorageByKey,
} from '../../../../common/utility';
import { ADCBTouchPointsService } from '../../../../common/services';
import { logComponentRenderingError } from '../../../../common/logger';
import { scrollToServerError, isThereExtraErrors, consentRequest, checkAnnualPassError } from '../utils';
import GTMData from '../../b2c-purchase-journey/gtm-data';
class ADCBTouchPoints extends Component {
  constructor(props) {
    super(props);
    this.propsData = this.props.propsData;
    this.payData = this.props.payData;
    this.cartData = this.props.cartData;
    this.cardEncryptedValue = null;
    this.lang = 'en';

    const formData = {
      sections: [{ fields: this.payData.paymentOptions.fields }],
    };

    this.state = {
      data: formData,
      touchPointBalanceDetails: null,
      formhasError: false,
    };

    this.cardDetails = {};
    this.anonymousUser = !isLoggedInUser();
  }

  handleFormFields = () => {
    const {
      paymentOptions: { cardTypes },
    } = this.propsData;

    cardInputInitializer(cardTypes, this.payData.paymentCards);
    expiryInputInitializer();
    cvvInputInitializer();
    this.dateValidationInitialize();
    document.getElementById('cardNumber') &&
      document.getElementById('cardNumber').addEventListener('paste', (e) => handleCardNumberPaste(e, cardTypes));
  };

  componentDidMount() {
    const {
      paymentOptions: {
        adcbTouchPointspayconfig: { rsaEncryptionURL, applyRSAEncryption },
        tncCondition,
        tncType,
      },
    } = this.props.propsData;
    this.handleFormFields();

    const paymentInfo = {
      cmsData: {
        paymentOptions: {
          tncType,
          tncCondition,
        },
      },
    };
    localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(paymentInfo));

    if (applyRSAEncryption) {
      const script = document.createElement('script');
      script.src = rsaEncryptionURL.source || '';
      script.async = true;
      document.body.appendChild(script);
    }
  }

  handleEditCallback = () => {
    this.setState({ touchPointBalanceDetails: null }, () => {
      const cardNumberEle = document.getElementById('cardNumber');
      cardNumberEle && cardNumberEle.focus();
      this.handleFormFields();
    });
  };

  isADCBCard = () => {
    const cardNumber = document.getElementById('cardNumber');
    let cardNumberValue = (cardNumber && cardNumber.value) || '';
    let notADCBCard = false;

    const [cardErrorMsgElem] = document.getElementsByClassName('error-touchpoint-card');
    cardErrorMsgElem && cardErrorMsgElem.parentNode && cardErrorMsgElem.parentNode.removeChild(cardErrorMsgElem);
    cardNumberValue && cardNumber.classList.remove('error');
    if (cardNumberValue && cardNumberValue.length >= 7) {
      cardNumberValue = cardNumberValue.replace(/ /g, '');
      const { paymentCards, paymentOptions } = this.props.payData,
        [cardNumberFieldConfig] =
          paymentOptions && paymentOptions.fields.filter((field) => field.name === 'cardNumber'),
        binNumbers = paymentCards[0].binNumbers.split(','),
        adcbCardUsed = binNumbers.filter((binNum) => cardNumberValue.indexOf(binNum) === 0);

      if (adcbCardUsed.length === 0) {
        setTimeout(() => {
          const errorExists = document.getElementById('error_cardNumber');
          if (!errorExists) {
            const node = document.createElement('span'),
              cardTypeError = resolvePath(cardNumberFieldConfig, 'cardTypeErrorMessage.ADCB', ''),
              textNode = document.createTextNode(cardTypeError);
            node.setAttribute('class', 'error-msg body-copy-6 form-error--field error-touchpoint-card');
            node.setAttribute('id', 'error_cardNumber');
            cardNumber.classList.add('error');
            node.appendChild(textNode);
            cardNumber.parentElement.appendChild(node);
          }
        }, 500);

        notADCBCard = true;
      }
    }
    return notADCBCard;
  };

  handleSubmitValidation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let hasAnnualPassError = false;
    if (checkAnnualPassError(this.props.cartData, this.handleSubmitValidation)) {
      hasAnnualPassError = true;
    }
    let formSubmitError = false;
    const { payFordata } = this.props;
    // MarketingConsentUpdateAPI call here
    const newsletterCheckbox = canUseDOM() && document.getElementById('paymentNewsletter')?.checked;
    if (newsletterCheckbox && isLoggedInUser()) {
      const { MarketingConsentUpdateAPI } = payFordata.services;
      consentRequest(MarketingConsentUpdateAPI?.url);
    }

    const cardNumber = document.getElementById('cardNumber'),
      validity = document.getElementById('validity'),
      cardCvv = document.getElementById('CVV');
    if ((cardNumber && !cardNumber.value) || (validity && !validity.value) || (cardCvv && !cardCvv.value)) {
      formSubmitError = true;
    }

    if (
      this.anonymousUser &&
      (this.props.guestUserHasErrors() || document.querySelectorAll('.yasid-email-error').length > 0)
    ) {
      formSubmitError = true;
      this.setState({ formhasError: true });
    }

    if (formSubmitError || hasAnnualPassError) {
      window.PubSub.publish('initiatePaymentSubmitValidation');
      scrollToServerError(e);
    } else {
      if (isThereExtraErrors(e)) return false;
      const isVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
      if (isVoucher) {
        window.PubSub.publish('formSubmitVoucher', true);
      }
      if (checkParksTenants()) {
        GTMData.push('addPaymentInfo', {
          cartData: this.props.cartData,
          paymentType: UIConfig.paymentOption.adcbTouchPoints,
        });
      }
      this.handleCheckTouchPointBalance(cardNumber, validity, cardCvv);
    }
  };

  handleCheckTouchPointBalance = (cardNumber, validity, cardCvv) => {
    if (cardNumber && cardNumber.value && validity.value) {
      const blockCheckBalance = this.isADCBCard();
      if (!blockCheckBalance) {
        const cardNumberValue = cardNumber.value.replace(/\s/g, ''),
          validityValue = getValidityFormatted(validity.value); // YYMM

        this.cardEncryptedValue = rsaEncryption(cardNumberValue);
        this.expiryDateEncryptedValue = rsaEncryption(validityValue);

        this.cardDetails = {
          cardNumber: cardNumberValue,
          validity: validity.value,
          cvv: cardCvv.value,
        };
        this.getTouchPointsBalance();
      }
    }
  };

  getTouchPointsBalance = () => {
    const userData = {},
      tenantId = getLoggedInUser().tenantID;
    if (this.anonymousUser) {
      const anonymousUserData = getAnonymousCart();
      userData.userEmail = resolvePath(anonymousUserData, 'cart.reservationOwner.email', '');
      userData.firstName = resolvePath(anonymousUserData, 'cart.reservationOwner.firstName', '');
    } else {
      const loggedInUserData = getLoggedInUser();
      userData.userEmail = loggedInUserData && loggedInUserData.email;
      userData.firstName = loggedInUserData && loggedInUserData.userName;
    }
    const params = {
      tenantId,
      userId: userData.userEmail,
      customerName: userData.firstName,
      cardNumber: this.cardEncryptedValue,
      expiryDate: this.expiryDateEncryptedValue,
    };

    ADCBTouchPointsService.getTouchPointEnquiry({
      url: this.propsData.services.getTouchPointEnquiry.url,
      apiData: JSON.stringify(params),
      moduleName: UIConfig.paymentOption.adcbTouchPoints,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    })
      .then((res) => {
        if (res.data && res.data.isSuccess) {
          Logging(
            res.data,
            UIConfig.paymentOption.adcbTouchPoints,
            true,
            'Get TouchPoints Balance Enquiry call Success',
          );
          this.setState({ touchPointBalanceDetails: res.data.balanceResponse }, () => {
            this.props.errorData && this.props.isErrorOccured('getTouchPointEnquiry', {}, true);
            this.props.resetPaymentError();
          });
        } else if (res.data && !res.data.isSuccess) {
          this.props.isErrorOccured('getTouchPointEnquiry', res.data.error);
          Logging(res.data, UIConfig.paymentOption.adcbTouchPoints, true, 'Get TouchPoints Balance Enquiry call Error');
        }
      })
      .catch((err) => {
        this.props.isErrorOccured('getTouchPointEnquiry', err.error);
        Logging(err, UIConfig.paymentOption.adcbTouchPoints, true, 'Get TouchPoints Balance Enquiry call Error');
      });
  };

  dateValidationInitialize = () => {
    const today = new Date();
    this.currentMonth = today.getMonth();
    this.currentYear = today
      .getFullYear()
      .toString()
      .substr(-2);
    const validationObject = this.payData.paymentOptions.fields.filter((value) => {
      return value.id === 'validity';
    });
    const dateValidationErrorArray = validationObject[0].validations.filter((value) => {
      return value.regex === '';
    });
    this.dateValidationError = dateValidationErrorArray[0].error;
  };

  payButtonCallback = () => {
    //TODO: Remove after testing
    if (isEnvDevelopment()) {
      this.cardDetails = {
        cardNumber: '4005550000000001',
        validity: '05/30',
        cvv: '123',
      };
    }
    this.props.usePayFortForTouchPointsPayment(this.cardDetails);
  };

  render() {
    try {
      const paymentLabels = this.propsData.paymentOptions.paymentLabels;
      const { touchPointBalanceDetails, formhasError } = this.state;
      const buttonData = {
        type: 'button',
        label: paymentLabels && paymentLabels.checkTouchpointBalanceCTALabel,
        class: classNames('active-btn payment-submit-btn btn-primary', {
          'button-enabled-dark': !formhasError && !isThereExtraErrors(),
          'button-enabled-light': formhasError || isThereExtraErrors(),
        }),
      };

      return (
        <div className="adcb-payment-step2-wrapper payment-input-box-form">
          {!touchPointBalanceDetails && (
            <>
              <form
                action=""
                method="POST"
                name="adcb"
                id="adcbform"
                autoComplete="off"
                className="c-payment-form contact-us-form c-form"
              >
                <PaymentFormComponent
                  lang={this.lang}
                  propsData={this.propsData}
                  is2StepPayment={this.props.is2StepPayment}
                  data={this.state.data}
                  isNewForm={true}
                  handleValidityValidation={this.isADCBCard}
                  payfortType={UIConfig.paymentOption.adcbTouchPoints}
                />
                {this.props.emailConfirmationText}
                <div className="adcb-submit-btn" id="adcbSubmitButton">
                  <Button
                    data={buttonData}
                    isNewForm={true}
                    clickCallback={this.handleSubmitValidation}
                    isTabIndex={this.props.isTabIndex}
                  />
                </div>
              </form>
              <RichText
                data={{
                  bodyCopy: paymentLabels.adcbSubLabel,
                  attrs: { className: 'adcb-sec-label' },
                }}
              />
            </>
          )}
          {touchPointBalanceDetails && (
            <ADCBTouchPointsStep2
              {...this.props}
              propsData={this.propsData}
              payData={this.payData}
              cartData={this.props.cartData}
              handleEditCallback={this.handleEditCallback}
              showTnCError={this.props.showTnCError}
              guestUserHasErrors={this.props.guestUserHasErrors}
              isEnabled={this.props.isEnabled}
              onTncChangeHandler={this.props.onTncChangeHandler}
              touchPointBalanceDetails={touchPointBalanceDetails}
              displayCardValue={this.cardDetails.cardNumber}
              payButtonCallback={this.payButtonCallback}
              isRenewAnnualPass={this.props.isRenewAnnualPass}
              annualPassCartData={this.props.annualPassCartData}
              setRedirectPaymentData={this.props.setRedirectPaymentData}
              isErrorOccured={this.props.isErrorOccured}
              showCheckBox={this.props.showCheckBox}
              hideNewsLetter={this.props.hideNewsLetter}
              disableBookButton={this.props.disableBookButton}
              hasTnCError={this.props.hasTnCError}
              tabIndex={this.props.isTabIndex}
              onNewsLetterChangeHandler={this.props.onNewsLetterChangeHandler}
              newsLetterEnabled={this.props.newsLetterEnabled}
            />
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'ADCBTouchPointsOTP');
    }
  }
}

export default ADCBTouchPoints;
