/*
 * radio-components.js
 * This file contains code for radio component which will render input based on schema.
 * @licensor  Miral
 */

import React, { useState, useEffect } from 'react';
import { DynamicContent } from '../../base';
import { validateJSSField } from '../rules-validator';
import { logComponentRenderingError } from '../../../../common/logger';
import FieldErrorComponent from '../field-error';
import classNames from 'classnames';
import './checkbox-list.scss';
/**
 * CheckboxGroup Component that will create radio or CheckboxGroup element as per data type
 * @param  {object} having all required attributes such as form data, events
 */
const CheckboxGroup = (props) => {
  const { tracker, field, errors } = props;

  const groupedCheckedValue = () => {
    return field.model.items
      .filter((opt) => {
        return opt.selected || (field.model.itmes && [field.model.itmes].includes(opt.value));
      })
      .reduce((acc, opt) => {
        acc.push(opt.value);
        return acc;
      }, []);
  };
  const valueFieldName = field.valueField.name,
    initialValue = groupedCheckedValue(),
    fieldName = props.field.model.name,
    inputType = 'Checkbox';
  const [fieldValue, setFieldValue] = useState(initialValue);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [radioValues, setRadioValues] = useState({ [fieldName]: fieldValue });

  const addRemoveArrayElement = (checkedValue) => {
    var index = fieldValue.indexOf(checkedValue);
    if (index > -1) {
      // remove element if it is in the array
      fieldValue.splice(index, 1);
      return fieldValue;
    }
    // add element if it is not in the array
    return [...fieldValue, checkedValue];
  };

  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  const handleChange = (checkedValue) => {
    let valid = true,
      errorMessages = [];
    let updatedArrayValue = addRemoveArrayElement(checkedValue);
    setFieldValue(updatedArrayValue);
    const errorMessagesObj = validateJSSField({ ...field, type: inputType }, { [fieldName]: updatedArrayValue });
    const message = errorMessagesObj[valueFieldName];
    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }

    setRadioValues({ [fieldName]: updatedArrayValue });
    setErrorMsg(errorMessages);
    props.onChange(valueFieldName, updatedArrayValue, valid, errorMessages);
    tracker.onBlurField(field, updatedArrayValue, errorMessages);
  };

  const onChange = (e) => {
    const checkBoxValue = e.target.dataset['checkedvalue'];
    handleChange(checkBoxValue);
  };

  try {
    const isErrorExist = errorMsg && errorMsg.length > 0;
    const inputClasses = classNames('`body-copy-3', { error: isErrorExist });
    return (
      <div className={`checkbox-group form-checkbox ${field.model.cssClass}`}>
        {props.field.model.title && (
          <DynamicContent tagName="span" innerHtml={props.field.model.title} attrs={{ className: 'body-copy-6' }} />
        )}
        {Array.isArray(props.field.model.items) &&
          props.field.model.items.map((opt, i) => {
            return (
              <label
                key={opt.value}
                className={opt.checked ? 'checkbox-label disabled' : 'checkbox-label'}
                htmlFor={`checkboxgroup-${fieldName}-${opt.value.toLowerCase()}`}
              >
                <input
                  className={inputClasses}
                  name={props.field.valueField.name}
                  onChange={onChange}
                  value={
                    radioValues &&
                    Array.isArray(radioValues[fieldName]) &&
                    radioValues[fieldName].includes(opt.value) &&
                    opt.value
                  }
                  data-checkedValue={opt.value}
                  id={`checkboxgroup-${fieldName}-${opt.value.toLowerCase()}`}
                  type="checkbox"
                  checked={
                    opt.selected ||
                    (radioValues &&
                      opt.value &&
                      Array.isArray(radioValues[fieldName]) &&
                      radioValues[fieldName].includes(opt.value))
                  }
                />
                <div className="form-checkbox-fancy"></div>
                <DynamicContent tagName="span" innerHtml={opt.text} attrs={{ className: 'body-copy-5' }} />
              </label>
            );
          })}
        <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CheckboxGroup');
  }
};

export default CheckboxGroup;
