/*
 * radio-components.js
 * This file contains code for radio component which will render input based on schema.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { DynamicContent } from '..';
import { decodeHtmlEntity, isMatchTenant } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';

/**
 * Updating checkbox value if user checked.
 * @param  {object} type {event , data}
 *
 */
const updateValue = (e) => {
  if (!e.target.checked) {
    e.target.value = false;
  } else {
    e.target.value = true;
  }
};

const InputRadioButton = ({ isTabRadio, radioDisabled, data, onChange, radioValues, opt, i, isTabIndex, isActive }) => {
  try {
    return (
      <label
        className={`${isTabRadio ? 'yas-custom-radio' : ''} ${
          radioDisabled && radioDisabled.disabled && radioDisabled.elem.indexOf(data.name) < 0
            ? 'checkbox-label disabled'
            : 'checkbox-label'
        } ${isActive ? 'active' : ''}`}
        key={i.toString()}
      >
        <input
          className={`body-copy-3 ${data.valid}`}
          name={data.name}
          onChange={onChange}
          checked={
            radioValues && radioValues[data.name] && radioValues[data.name].toLowerCase() === opt.value.toLowerCase()
          }
          value={opt.value}
          aria-required={data.isRequired}
          type={data.type}
          disabled={radioDisabled && radioDisabled.disabled && radioDisabled.elem.indexOf(data.name) < 0}
        />
        {!isTabRadio && <span className="form-checkbox-fancy" tabIndex={isTabIndex ? isTabIndex : 0}></span>}
        <DynamicContent tagName="span" innerHtml={opt.text} attrs={{ className: 'body-copy-5' }} />
      </label>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'InputRadioButton');
  }
};

/**
 * Radio/Checkbox Component that will create radio or checkbox element as per data type
 * @param  {object} having all required attributes such as form data, events
 */
const Radio = ({
  isTabRadio,
  data,
  onChange,
  onRadioClick,
  radioValues,
  radioDisabled,
  controlled,
  isTabIndex,
  activeTab,
  isFilter,
}) => {
  try {
    let radioHtml = null;

    /*Check if type of Input is Radio OR CheckBox */

    if (data?.type?.toLowerCase() === 'radio') {
      if (data.class && data.class.includes('Inline-Style-Radio')) {
        radioHtml = (
          <div className="radio-group radio inline-style-radio-container">
            <DynamicContent tagName="span" innerHtml={data.label} attrs={{ className: 'body-copy-6' }} />
            {data.options.map((opt, i) => {
              return (
                <div className="radio" key={`radio-${i}`}>
                  <InputRadioButton
                    radioDisabled={radioDisabled}
                    data={data}
                    onChange={onChange}
                    activeFromExpiredDate
                    radioValues={radioValues}
                    opt={opt}
                    i={i}
                    isTabIndex={isTabIndex}
                    isTabRadio={isTabRadio}
                  />
                </div>
              );
            })}
            {data.showErrors ? <span className="error-msg body-copy-6">{data.message}</span> : null}
          </div>
        );
      } else {
        radioHtml = (
          <div aria-live="polite" className="radio-group radio">
            <DynamicContent tagName="span" innerHtml={data.label} attrs={{ className: 'body-copy-6' }} />
            {Array.isArray(data.options) &&
              data.options.map((opt, i) => {
                return (
                  <InputRadioButton
                    radioDisabled={radioDisabled}
                    data={data}
                    isActive={opt.value === activeTab}
                    onChange={onChange}
                    radioValues={radioValues}
                    opt={opt}
                    i={i}
                    key={`radio-${i}`}
                    isTabRadio={isTabRadio}
                  />
                );
              })}
            {data.showErrors ? <span className="error-msg body-copy-6">{data.message}</span> : null}
          </div>
        );
      }
    } else if (data?.type?.toLowerCase() === 'checkboxgroup') {
      radioHtml = (
        <div className="radio-group form-checkbox">
          {data.label && <DynamicContent tagName="span" innerHtml={data.label} attrs={{ className: 'body-copy-6' }} />}
          {Array.isArray(data.options) &&
            data.options.map((opt, i) => {
              return (
                <label
                  key={opt.value}
                  className={opt.checked ? 'checkbox-label disabled' : 'checkbox-label'}
                  htmlFor={`checkboxgroup-${data.name}-${opt.value.toLowerCase()}`}
                >
                  {controlled ? (
                    <input
                      className={`body-copy-3 ${data.valid}`}
                      name={data.name}
                      onChange={onChange}
                      value={
                        radioValues &&
                        Array.isArray(radioValues[data.name]) &&
                        radioValues[data.name].includes(opt.value) &&
                        opt.value
                      }
                      data-checkedValue={opt.value}
                      id={`checkboxgroup-${data.name}-${opt.value.toLowerCase()}`}
                      onClick={updateValue}
                      type="checkbox"
                      checked={
                        opt.checked ||
                        (radioValues &&
                          opt.value &&
                          Array.isArray(radioValues[data.name]) &&
                          radioValues[data.name].includes(opt.value))
                      }
                    />
                  ) : (
                    <input
                      className={`body-copy-3 ${data.valid}`}
                      name={data.name}
                      onChange={onChange}
                      value={radioValues && Array.isArray(radioValues) && radioValues.includes(opt.value)}
                      data-checkedValue={opt.value}
                      id={`checkboxgroup-${data.name}-${opt.value.toLowerCase()}`}
                      onClick={updateValue}
                      type="checkbox"
                      defaultChecked={opt.checked}
                    />
                  )}
                  <div className="form-checkbox-fancy"></div>
                  <DynamicContent tagName="span" innerHtml={opt.text} attrs={{ className: 'body-copy-5' }} />
                </label>
              );
            })}
          {data.showErrors ? <span className="group-error-msg error-msg body-copy-6">{data.message}</span> : null}
        </div>
      );
    } else {
      radioHtml = (
        <div
          className={
            'form-element form-checkbox ' + (data.checked === true ? 'active' : '') + (data.class ? data.class : '')
          }
        >
          <label key={data.key} className="form-label checkbox-label" htmlFor={data.id}>
            {controlled ? (
              <input
                className={`body-copy-3 ${data.valid}`}
                name={data.name}
                onChange={onChange}
                value={data.checked ? true : false}
                id={data.id}
                aria-required={data.isRequired}
                onClick={updateValue}
                type={data.type}
                checked={data.checked}
                aria-labelledby={data.showErrors && 'error_' + data.id}
                aria-invalid={data.showErrors ? true : false}
                tabIndex={isTabIndex ? isTabIndex : 0}
              />
            ) : (
              <input
                className={`body-copy-3 ${data?.valid}`}
                name={data.name}
                onChange={!isFilter ? onChange : (e) => onChange(e, data)}
                value={data.checked ? true : false}
                id={data.id}
                aria-required={data.isRequired}
                onClick={updateValue}
                type={!isFilter ? data.type : 'checkbox'}
                defaultChecked={data.checked}
                aria-labelledby={data.showErrors && 'error_' + data.id}
                aria-invalid={data.showErrors ? true : false}
                tabIndex={isTabIndex ? isTabIndex : 0}
              />
            )}
            <div className="form-checkbox-fancy"></div>
            <DynamicContent tagName="span" innerHtml={data.label} attrs={{ className: 'body-copy-2' }} />
          </label>
          {data?.showErrors ? (
            <Text
              tag="span"
              id={`error_${data.id}`}
              aria-live="assertive"
              aria-relevant="additions removals"
              className="error-msg body-copy-6"
              field={{
                value: decodeHtmlEntity(data.message),
                editable: decodeHtmlEntity(data.message),
              }}
            />
          ) : null}
        </div>
      );
    }

    return radioHtml;
  } catch (err) {
    return logComponentRenderingError(err, 'Radio');
  }
};

Radio.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf(['Checkbox', 'Radio']).isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
  }),
};

export default Radio;
