import React, { useEffect, forwardRef, useRef } from 'react';
import Slider from 'react-slick';
import { logComponentRenderingError } from '../../../../common/logger';
import { detectViewPort, iOSDevice } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';

import './carousel-component.scss';

const Carousel = forwardRef((props, ref) => {
  if (!ref) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ref = useRef(null);
  }

  let firstClientX, clientX;

  const disableBodyScroll = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Prevent vertical scrolling when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
  };

  useEffect(() => {
    let parentDiv = null;
    const deviceType = detectViewPort();

    if (
      iOSDevice() &&
      (deviceType === UIConfig.viewportTypes.mobile || deviceType === UIConfig.viewportTypes.tablet) &&
      typeof props.getCarouselDivRef === 'function'
    ) {
      parentDiv = props.getCarouselDivRef();
      if (parentDiv && parentDiv.current) {
        parentDiv.current.addEventListener('touchstart', touchStart);
        parentDiv.current.addEventListener('touchmove', disableBodyScroll, {
          passive: false,
        });
      }
    }

    return () => {
      if (iOSDevice() && parentDiv && parentDiv.current) {
        parentDiv.current.removeEventListener('touchstart', touchStart);
        parentDiv.current.removeEventListener('touchmove', disableBodyScroll, {
          passive: false,
        });
      }
    };
  }, []);

  useEffect(() => {
    const { initialSlide, slickTabs, slideUpdate } = props.data;

    if (slickTabs) {
      ref.current && ref.current.slickGoTo(initialSlide);
    } else {
      (slideUpdate !== null || slideUpdate !== undefined) &&
      ref.current &&
      ref.current.innerSlider && // Just to make sure if the innerSlider is present before calling slickGoTo in order to prevent slickGoTo of undefined issue
        ref.current.slickGoTo(slideUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  try {
    return (
      <Slider {...props.data} ref={ref}>
        {props.children}
      </Slider>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Carousel');
  }
});

Carousel.defaultProps = {
  prevButtonAriaLabel: 'Previous button of carousel',
  nextButtonAriaLabel: 'Next button of carousel',
};

export default Carousel;
