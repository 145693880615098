import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { logComponentRenderingError } from '../../../common/logger';
import { DynamicContent, AnchorLink } from '../../presentation/base/';
import FeatureTile from './feature-tile';
import { canUseDOM, resolvePath, checkTenant } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { bodyClicks } from '../../../common/analytics-events';
import SectionHeader from '../../presentation/section-header/SectionHeader';

const ArticleList = ({ data }) => {
  const { lazyloadWithBackground } = data;

  try {
    const classNames = classnames('component article-component article-flow', data.cardAlignment, data.variant);
    const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
    const isSwad = checkTenant(UIConfig.iamMapping.swad);

    const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
      if (checkfortenants) {
        bodyClicks(eventName, titleData, labelData);
      }
    };
    return (
      <div className={classNames}>
        {(data.title || data.titleDescription || data.shortTitle) && (
          <section className="w--content article-list-heading">
            {data.shortTitle && (
              <DynamicContent tagName="p" attrs={{ className: 'heading-7' }} innerHtml={data.shortTitle} />
            )}
            {data.title && (
              <DynamicContent
                tagName="h2"
                attrs={{ className: 'heading-3 title ' + data.titleAlignment }}
                innerHtml={data.title}
              />
            )}
            {data.titleDescription && (
              <DynamicContent tagName="div" attrs={{ className: 'body-2' }} innerHtml={data.titleDescription} />
            )}
          </section>
        )}
        {/* SWAD Section Header */}
        {isSwad && data.sectionHeader && (
          <div className="container article-header">
            <div className="row">
              <div className="col-md-12">
                <SectionHeader data={data.sectionHeader} />
              </div>
            </div>
          </div>
        )}
        {data.cardslist &&
          data.cardslist.map((tabTileData, index) => (
            <div
              key={index}
              className={classnames(`w--content clearfix flip-${tabTileData.showImageonRight ? 'right' : 'left'}`)}
            >
              <FeatureTile
                tile={tabTileData}
                index={index}
                variant={data.variant}
                disableReadMore={data.disableReadMore}
                lazyloadWithBackground={lazyloadWithBackground}
                {...(isSwad && { articleListSwadCMS: 'article-list-swad-cms' })}
              />
            </div>
          ))}
        {data.primaryCTA && !data.disableprimaryCTA && (
          <div className="w--content primary-cta-wrapper">
            <AnchorLink
              link={data.primaryCTA}
              className="primary-cta"
              onClick={() => {
                googleAnalyticHandlerbody(
                  UIConfig.commonVariant.gaClickEvents.linkClick,
                  data.primaryCTA.title,
                  data.primaryCTA.label,
                );
              }}
            />
          </div>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ArticleList', data.variant);
  }
};

export default ArticleList;

ArticleList.propTypes = {
  data: PropTypes.shape({
    cardslist: PropTypes.array,
  }),
};
