import UIConfig from '../../../common/UIConfig';
import { resolvePath } from '../../../common/utility';

const checkValidation = (value, cond) => {
  const convertedNumValue = parseInt(cond.value);
  let condition, switchCondition, result;

  if (value === null) {
    condition = UIConfig.jssForms.actions;
    switchCondition = condition[cond.actionTypeId];
  } else {
    condition = UIConfig.jssForms.operators;
    switchCondition = condition[cond.operatorId];
  }

  switch (switchCondition) {
    case '<': {
      if (value < convertedNumValue) {
        result = true;
        break;
      }
      result = false;
      break;
    }
    case '<=': {
      if (value <= convertedNumValue) {
        result = true;
        break;
      }
      result = false;
      break;
    }
    case '>': {
      if (value > convertedNumValue) {
        result = true;
        break;
      }
      result = false;
      break;
    }
    case '>=': {
      if (value >= convertedNumValue) {
        result = true;
        break;
      }
      result = false;
      break;
    }
    case '=': {
      if (value === convertedNumValue) {
        result = true;
        break;
      }
      result = false;
      break;
    }
    case 'show':
    case 'enable': {
      result = true;
      break;
    }
    case 'hide':
    case 'disable': {
      result = false;
      break;
    }
    default: {
      result = null;
    }
  }
  return result;
};

export const validateConditions = ({ value, conditionSettings }) => {
  const fieldConditions = resolvePath(conditionSettings, 'fieldConditions', []);
  let result;

  fieldConditions.forEach((item) => {
    const conditions = resolvePath(item, 'conditions', []);
    conditions.forEach((cond) => {
      return (result = checkValidation(value, cond));
    });
  });
  return result;
};

export const showOrHideInput = (conditionSettings) => {
  const fieldConditions = resolvePath(conditionSettings, 'fieldConditions', []);
  let result;

  fieldConditions.forEach((item) => {
    const actions = resolvePath(item, 'actions', []);
    actions.forEach((cond) => {
      result = checkValidation(null, cond);
      return result;
    });
  });
  return result;
};
