import { DeviceUtils } from '../device-utils';
import { canUseDOM, getLoggedInUser, isEmpty, getMainObject, getCurrentLanguage } from '../utility';
import { Logging } from '../logger.js';

class AnalyticsConfig {
  /**
   *
   * @param {boolean} sendToCloud
   */
  constructor(sendToCloud) {
    this.sendToCloud = sendToCloud;
    this.isContextual = false;
    this.initialiseConfig();
    this.additionalFields = ['sysuri', 'sysurihash', 'syssource', 'syscollection'];
  }

  initialiseConfig() {
    const isFrontEnd = canUseDOM();
    if (isFrontEnd) {
      this.device = DeviceUtils.getDeviceName();
      this.mobile = DeviceUtils.isMobileDevice();
      const userData = getLoggedInUser();
      this.setUserDetails(userData);
      const globalObject = getMainObject();
      this.configureSettings(globalObject);
    }
  }

  /**
   * This method initialises some critical inforrmation like
   * service end points, user details and app version
   * @param userData
   */
  setUserDetails(userData) {
    if (typeof userData === 'object' && userData !== null && !isEmpty(userData)) {
      this.userId = userData.emails && userData.emails[0];
      this.userDisplayName = userData.userName;
      this.anonymous = !!this.userId;
    }
  }

  /**
   * Initialises tenant , pipeline and language
   * @param globalObject
   */
  configureSettings(globalObject) {
    try {
      this.language = getCurrentLanguage();
      this.tenant = globalObject.tenantID;
      this.originContext = globalObject.tenantID;
      this.pipeline = globalObject.additionalProperty.pipeline;
      this.searchHub = globalObject.additionalProperty.searchHub;
      this.analyticsUrl = globalObject.additionalProperty.analyticsUrl;
      this.originLevel2 = globalObject.additionalProperty.originLevel2 || 'All';
    } catch (e) {
      Logging(
        e,
        'analytics-config',
        false,
        'Configuration required for Analytics Library is not available in localStorage',
      );
    }
  }
}

export const analyticsConfig = new AnalyticsConfig(true);
