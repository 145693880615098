import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

import { logComponentRenderingError } from '../../../../common/logger';
import { DynamicContent } from '..';

import './timer-component.scss';

const getCurrentOffsetTimeOnOtherTZ = (offset) => {
  let t = moment();
  t.utcOffset(offset);

  return moment.now();
};

const getFieldsToShow = (opts) => {
  if (!opts) {
    return ['days', 'hours', 'minutes'];
  }
  return Object.keys(opts);
};

const renderTimerSection = (val, label, index) => {
  return (
    <div className="c-timer-section" key={index}>
      <span className="c-timer-section--value">{val}</span>
      <span className="c-timer-section--label">{label}</span>
    </div>
  );
};

const Timer = (props) => {
  const { data, disableSelection, withoutPurchase, isTakeOverBanner } = props;
  const { endDate: eDate, isCartTimer, options, startDate: sDate, timerLabel } = data;

  const [mTime, setMTime] = useState('');
  const startDate = useRef(moment.utc(sDate || undefined));
  const endDate = useRef(moment.utc(eDate || undefined));
  const timer = useRef(null);
  const isFirstRender = useRef(false);

  useEffect(() => {
    isFirstRender.current = true;
    setMTime(moment.duration(endDate.current.diff(moment.utc())));

    window.PubSub.subscribe('clearCartTimer', () => {
      if (isCartTimer) {
        clearInterval(timer.current);
      }
    });

    return () => {
      window.PubSub.unsubscribe('clearCartTimer');
      if (withoutPurchase) {
        hideTimerBeforeStartDate();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCartTimer) {
      endDate.current = moment.utc(eDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eDate]);

  useEffect(() => {
    if (isFirstRender.current) {
      startTimer();
      isFirstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mTime]);

  const startTimer = () => {
    timer.current = setInterval(() => {
      let time = moment.duration(endDate.current.diff(moment.utc()));
      if (withoutPurchase) {
        time = moment.duration(
          endDate.current.diff(getCurrentOffsetTimeOnOtherTZ(moment.parseZone(endDate.current)._offset)),
        );
      }

      setMTime(time);

      if (withoutPurchase) {
        hideTimerBeforeStartDate();
      }

      if (moment.utc().format() >= moment.utc(endDate.current).format()) {
        stopTimer();
      }
    }, 1000);
  };

  const hideTimerBeforeStartDate = () => {
    if (isTakeOverBanner) {
      return;
    }
    // Hide timer in case start date is future date
    disableSelection(
      moment.duration(
        startDate.current.diff(getCurrentOffsetTimeOnOtherTZ(moment.parseZone(startDate.current)._offset)),
      ) >= 0,
    );
  };

  const stopTimer = () => {
    clearInterval(timer.current);
    disableSelection(true);
  };

  try {
    const timerData = { months: '0', days: '0', hours: '00', minutes: '00', seconds: '00' };
    if (mTime > 0) {
      timerData.months = (mTime.months() < 10 && '0') + mTime.months();
      timerData.days = mTime.days();
      timerData.hours = (mTime.hours() < 10 && '0') + mTime.hours();
      timerData.minutes = (mTime.minutes() < 10 && '0') + mTime.minutes();
      timerData.seconds = (mTime.seconds() < 10 && '0') + mTime.seconds();
    }

    return (
      <div className="c-timer" tabIndex="0">
        <DynamicContent tagName="h2" attrs={{ className: 'c-timer-header' }} innerHtml={timerLabel} />
        <div className="c-timer-body">
          {getFieldsToShow(options).map((field, index) => renderTimerSection(timerData[field], options[field], index))}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Timer');
  }
};

export default Timer;
