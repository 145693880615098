/*
 * api-wrapper.js
 *
 * @author    Manoj Singh, SapientNitro <msingh121@sapient.com>
 * @licensor  Miral
 */
import axios from 'axios';

import { Logging } from './logger.js';
import { logoutCurrentUser } from './session/session-utility';
import UIConfig from './UIConfig';
import {
  addLoaderOverlay,
  canUseDOM,
  getErrorObj,
  getGenericErrors,
  getLoggedInUser,
  getUserAgent,
  isEmpty,
  removeLoaderOverlay,
  getMainObject,
  getLoginUser,
  removeDuplicateCoveoItems,
  getCookie,
  isSafariBrowser,
} from './utility';

const ApiWrapper = (() => {
  let platformToken = '',
    coveoToken = '',
    userData = {},
    loaderObject = {};
  let ErrorResponseRequire = false;
  let preLoaderTarget;
  const isFrontEnd = canUseDOM();

  if (isFrontEnd) {
    userData = getLoggedInUser();
    if (typeof userData === 'object' && userData !== null && !isEmpty(userData)) {
      platformToken = getCookie('idToken') || userData.idToken;
      coveoToken = userData.coveoToken;
    }

    window.PubSub.subscribe('userLoggedInSuccesfully', (msg, data) => {
      userData = data.userData;
      if (typeof userData !== 'object' || userData !== null || isEmpty(userData)) {
        userData = getLoggedInUser();
      }
      platformToken = getCookie('idToken') || userData.idToken;
      coveoToken = userData.coveoToken;
    });
  }

  /**
   * Callback on success of API request.
   * @param    {[Object]} response [Object retured from axios api response]
   */
  const onSuccess = (response) => {
    const data = response.data;
    if (data && data.error && data.error.code && data.error.code !== 200) {
      data.error.systemError = false;
      data.response = response;
      Logging(response, response.config && response.config.moduleName ? response.config.moduleName : '');
      return Promise.reject(data);
    } else {
      return response;
    }
  };

  /**
   * Default callback on failure of API request.
   * @param    {[Object]} error [Object retured from axios api on failure]
   */

  const onError = (error) => {
    let errorObj;
    const genericErrors = getGenericErrors();
    let otherDetails;
    if (error && !error.response) {
      error.response = {};
      error.response.status = 404;
      otherDetails = error.message || 'Network Error';
    }
    if (error && error.response) {
      const status =
        error.response.data && error.response.data.error ? error.response.data.error.code : error.response.status;
      if (error.response.status === 401) {
        const userAgent = getUserAgent();
        const loggedInUser = getLoginUser();
        if (!userAgent && Object.keys(loggedInUser).length) {
          logoutCurrentUser('unauthorized access', true);
          errorObj = getErrorObj(status, genericErrors, true);
        } else {
          errorObj = getErrorObj(status, genericErrors, true);
          window.JSbridge.nativeCallback(errorObj);
        }
      } else {
        errorObj = getErrorObj(status, genericErrors, true, ErrorResponseRequire, error.response);
      }
      Logging(error, error.config && error.config.moduleName ? error.config.moduleName : '', false, otherDetails);
      return Promise.reject(errorObj);
    }
  };

  /**
   * removeLoadingDiv function checks if loaderObject has property with selector name, if true it decreases the counter from that selector and check if the counter has reached 0, if it is found true it removes the loader.
   * @param    {[Object]} customConfig configuration object
   * @param    {[DOM]} el dom element on which loader was implemented
   */
  const removeLoadingDiv = (customConfig, el) => {
    if (isSafariBrowser() && !preLoaderTarget && UIConfig.loader.defaultPreLoaderTarget in loaderObject) {
      preLoaderTarget = UIConfig.loader.defaultPreLoaderTarget;
    }
    if (loaderObject.hasOwnProperty(preLoaderTarget)) {
      loaderObject[preLoaderTarget] -= 1;
      if (loaderObject[preLoaderTarget] <= 0) {
        removeLoaderOverlay(el);
        delete loaderObject[preLoaderTarget];
      }
    } else {
      removeLoaderOverlay(el);
    }
  };

  /**
   * createLoaderDiv function checks if loaderObject has property with selector name, if true it increases the counter, otherwise if it at the loader to the provides dom element.
   * @param    {[Object]} customConfig configuration object
   * @param    {[DOM]} el dom element on which loader was implemented
   */
  const createLoaderDiv = (customConfig, el) => {
    if (loaderObject.hasOwnProperty(preLoaderTarget)) {
      loaderObject[preLoaderTarget] += 1;
    } else {
      loaderObject[preLoaderTarget] = 1;
      addLoaderOverlay(el);
    }
  };

  /**
   * Function for handeling the all api calls.
   * @param    {[Object]} config [configuration object required for handeling the api]
   * @config   {[String]} url [url for api call]
   * @config   {[String]} method [type of request GET/POST/PUT/DELETE]
   * @config   {[String]} baseURL [base url for API calls]
   * @config   {[Number]} timeout [time in milisecond for request timeout]
   * @config   {[String]} responseType [type of response]
   * @config   {[Function]} customErrorHandeler [function that need to be executed on failure]
   * @config   {[Object]} data [data to be send to API call]
   * @config   {[Boolean]} preLoader [does the loader is required]
   * @config   {[String]} preLoaderTarget [query selector of the element on which loader need to implmented]
   *
   */

  const api = (config) => {
    let axiosRef = axios.create();

    //--- setting default properties if properties are missing
    config.method = config.method || 'get';
    config.responseType = config.responseType || 'json';
    config.noCache = config.noCache || false;
    config.timeout = config.timeout || UIConfig.apiTimeout;
    if (config.method.toLowerCase() !== 'get') {
      config.data = config.data || {};

      if (typeof config.headers === 'object' && config.headers !== null) {
        if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json';
        }
      } else {
        config.headers = { 'Content-Type': 'application/json' };
      }
    }

    //Checking if the cache is required or not. If not the add no cache in the headers.
    if (config.noCache) {
      let temp = {
        'Cache-Control': 'no-cache',
      };
      config.headers = config.headers ? config.headers : {};
      config.headers = { ...config.headers, ...temp };
    }
    config.customErrorHandeler = config.customErrorHandeler || onError;
    config.customSuccessHandler = config.customSuccessHandler || onSuccess;
    config.preLoader = config.preLoader || false;
    config.preLoaderTarget = config.preLoaderTarget || null;
    config.url = config.url || null;
    config.removeLoader = !config.continueLoader;
    preLoaderTarget = config.preLoaderTarget;

    ErrorResponseRequire = config.isCompleteErrorResNeeded || false;

    //----- setting loader on the element during API call
    if (UIConfig.loader.enableLoader && config.preLoader && config.preLoaderTarget) {
      let el;
      if (isFrontEnd) {
        el = document.querySelector(config.preLoaderTarget);
      }
      if (el) {
        axiosRef.interceptors.request.use(
          (config) => {
            createLoaderDiv(config, el);
            return config;
          },
          (error) => {
            removeLoadingDiv(config, el);
            return Promise.reject(error);
          },
        );

        axiosRef.interceptors.response.use(
          (response) => {
            if (response.status === 200 || response.status === 201) {
              config.removeLoader && removeLoadingDiv(response.config, el);
            }
            return response;
          },
          (error) => {
            removeLoadingDiv(config, el);
            return Promise.reject(error);
          },
        );
      }
    }
    //------------------
    if (config.noAuthHeader) delete config?.headers?.Authorization;

    return axiosRef(config).then(config.customSuccessHandler, config.customErrorHandeler);
  };

  const updateConfig = (config) => {
    config = config ? config : {};
    const newToken = getCookie('idToken');
    if (platformToken) {
      if (config.headers) {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${newToken}`;
        }
      } else {
        config.headers = {
          Authorization: `Bearer ${newToken}`,
        };
      }
    }
    return config;
  };

  /**
   * Callback on success of OmniService Request.
   * @param    {[Object]} response [Object retured from axios api response]
   */
  const onOmniSuccess = (response) => {
    const data = response.data;
    if (data && data.error && data.error.errorcode) {
      data.error.code = data.error.errorcode;
      data.error.systemError = false;
      data.response = response;
      Logging(response, response.config && response.config.moduleName ? response.config.moduleName : '');
      return Promise.reject(data);
    } else {
      return response;
    }
  };

  /**
   * Callback on success of CrmService Request.
   * @param    {[Object]} response [Object retured from axios api response]
   */
  const onCrmSuccess = (response) => {
    const data = response.data,
      error = data ? data[Object.keys(data)[0]].error : null;
    if (error && error.code) {
      error.systemError = false;
      response.error = error;
      Logging(response, response.config && response.config.moduleName ? response.config.moduleName : '');
      return Promise.reject(response);
    } else {
      return response;
    }
  };

  /**
   * Callback on success of coveoService Request.
   * @param    {[Object]} response [Object retured from axios api response]
   */
  const onCoveoSuccess = (response) => {
    const data = response.data;
    if (data && data.error && data.error.code && data.error.code !== 200) {
      data.error.systemError = false;
      data.response = response;
      Logging(response, response.config && response.config.moduleName ? response.config.moduleName : '');
      return Promise.reject(data);
    } else {
      const removedDuplicateItems = removeDuplicateCoveoItems(data);
      if (removedDuplicateItems && removedDuplicateItems.results.length > 0)
        return { ...response, data: removedDuplicateItems };
      return response;
    }
  };

  const platformService = (config) => {
    config = config ? config : {};
    if (config.headers) {
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
    } else {
      config.headers = { 'X-Requested-With': 'XMLHttpRequest' };
    }

    updateConfig(config);
    return api(config);
  };

  const apiGateway = (config) => {
    updateConfig(config);
    return api(config);
  };

  const omniServices = (config) => {
    updateConfig(config);
    config.customSuccessHandler = onOmniSuccess;
    return api(config);
  };

  const crmServices = (config) => {
    updateConfig(config);
    config.customSuccessHandler = onCrmSuccess;
    return api(config);
  };

  const experienceServices = (config, shouldUpdateConfig = true) => {
    if (shouldUpdateConfig) {
      updateConfig(config);
    }
    return api(config);
  };

  const validateMerchantService = (config, shouldUpdateConfig = true) => {
    if (shouldUpdateConfig) {
      updateConfig(config);
    }
    return api(config);
  };

  const coveoService = (config) => {
    if (typeof config === 'object') {
      const localObj = getMainObject() || getLoggedInUser();
      const isB2B = localObj.tenantID === 'ALLB2B' ? true : false;
      const mainObj = isB2B ? localObj.tenantSettings : localObj.additionalProperty;

      if (isFrontEnd && localObj && config.method.toLowerCase() === 'post') {
        config.data.maximumAge = localObj.coveoQueryMaxAge || -1;
        const pagePath = window.location.pathname;
        let excludestaticQueryFlag = false,
          excludestaticQuery = mainObj && mainObj.ExcludestaticQuery ? mainObj.ExcludestaticQuery.split(',') : [];
        if (excludestaticQuery.length) {
          for (let qKey in excludestaticQuery) {
            if (pagePath.indexOf(excludestaticQuery[qKey]) !== -1) {
              excludestaticQueryFlag = true;
              break;
            }
          }
          if (!excludestaticQueryFlag && localObj.staticQuery) {
            config.data.staticQuery = true;
          }
        }
      }
      coveoToken = config.coveoToken || coveoToken || localObj.coveoToken;
      config.customSuccessHandler = onCoveoSuccess;
      config.headers = {
        Authorization: `Bearer ${coveoToken}`,
      };
    }
    return api(config);
  };

  return {
    platformService: platformService,
    coveoService: coveoService,
    apiGateway: apiGateway,
    omniServices: omniServices,
    crmServices: crmServices,
    experienceServices: experienceServices,
    validateMerchantService: validateMerchantService,
    api: api,
  };
})();
export default ApiWrapper;
