import moment from 'moment';
import { PerformanceService } from '../../../../../common/services';

import UIConfig from '../../../../../common/UIConfig';
import {
  addLoaderOverlay,
  getIncludedDates,
  isEmpty,
  isMatchTenant,
  momentTimezone,
  redirectTo,
  removeLoaderOverlay,
  resolvePath,
  roundToTwoPlaces,
  toTwoDecimalPlaces,
  getClientUtcOffset,
  convertJsDateToMomentObj,
} from '../../../../../common/utility';
import { isParkTenant } from '../../../../../common/utility/tenantsUtils';

export const confgDfltSetngs = (self) => {
  let secProdAmt = 0,
    addOnGuestAmt = 0;
  const getDisProd = self.props.getDiscountedProducts,
    daysDelta = self.daysDelta,
    stnSecProd = self.secProds && self.secProds.length ? getDisProd(self.secProds || [], daysDelta) : [],
    stnAddOn =
      self.addOnGuestProds && self.addOnGuestProds.length ? getDisProd(self.addOnGuestProds || [], daysDelta) : null;
  stnSecProd.sort((a, b) => b.gross - a.gross);
  if (stnSecProd && stnSecProd.length) {
    secProdAmt = Number(stnSecProd[0].gross);
    self.secProdSlctd = stnSecProd[0];
    self.slctdSecIdx = 0;
  } else {
    secProdAmt = Number(self.primaryProd.gross);
    self.secProdSlctd = self.primaryProd;
  }
  if (stnAddOn && stnAddOn.length) {
    const keyMap = self.props.multiTicketSelector.stringMapping,
      slctrs = self.props.multiTicketSelector.options || [];
    let trgtElm = slctrs.find((elm) => elm.identifier === keyMap.addGuest);
    self.addGuestSlctdQnty = trgtElm && trgtElm.defaultSelected === keyMap.last ? trgtElm.maxLength : 0;
    addOnGuestAmt = roundToTwoPlaces(Number(stnAddOn[0].gross) * self.addGuestSlctdQnty);
    self.addOnGuest = stnAddOn[0];
    self.addOnGuest.quantity = self.addGuestSlctdQnty;
  } else {
    addOnGuestAmt = 0;
  }
  self.setState({
    total: secProdAmt + addOnGuestAmt,
  });
};

export const changeDiscountProd = (self, date) => {
  const days = self.calDateDelta(date),
    disableDiscount = self.props.multiTicketSelector.stringMapping.disableDiscount === 'true',
    getDisProd = self.props.getDiscountedProducts;

  let newSecProds;
  self.daysDelta = days;

  self.primaryProd = getDisProd(self.primaryProds || [], days, null, disableDiscount)[0];
  newSecProds = getDisProd(self.secProds || [], days);

  newSecProds = newSecProds.sort((a, b) => b.gross - a.gross) || [];
  self.secProdSlctd = self.slctdSecIdx < newSecProds.length ? newSecProds[self.slctdSecIdx] : self.primaryProd;

  self.addOnGuest =
    self.addOnGuestProds && self.addOnGuestProds.length ? getDisProd(self.addOnGuestProds || [], days)[0] : undefined;
  if (self.addOnGuest) {
    self.addOnGuest.quantity = self.addGuestSlctdQnty;
  }
  self.timeSlotSlctd = undefined;

  self.setState({
    buttonDisable: true,
    isError: false,
  });

  self.calcTotal();
};

export const renderTimeSlots = (self, data, date) => {
  const itm = [],
    labels = self.props.timeSlotData && self.props.timeSlotData.labels ? self.props.timeSlotData.labels : {};
  let availableSlots,
    availabileLabel = labels.availableLabel || '';

  if (self.enableDynamicCalendar && self.isDynamicPricingProd) {
    const findPerformancesData =
      self.state.modifiedPerformanceData && self.state.modifiedPerformanceData.find((item) => item.date === date);
    const selectedPerformanceData = findPerformancesData && findPerformancesData.performance;
    selectedPerformanceData &&
      selectedPerformanceData.forEach((perf) => {
        availableSlots = perf.availability.available > 1 ? `(${perf.availability.available} ${availabileLabel})` : '';
        itm.push({
          value: `${self.timeFormat(perf)}${availableSlots && ` ${availableSlots}`}`,
          performanceId: perf.performanceId,
          disabled: perf.sellable === 'false' || !parseInt(perf.availability.available, 10),
          text: `${self.timeFormat(perf)}${availableSlots && ` ${availableSlots}`}`,
        });
      });
    self.setState({
      performanceData: selectedPerformanceData,
    });
  } else {
    data &&
      data.performance &&
      data.performance.length &&
      data.performance.forEach((perf) => {
        availableSlots = perf.availability.available > 1 ? `(${perf.availability.available} ${availabileLabel})` : '';
        itm.push({
          value: `${self.timeFormat(perf)}${availableSlots && ` ${availableSlots}`}`,
          performanceId: perf.performanceId,
          disabled: perf.sellable === 'false' || !parseInt(perf.availability.available, 10),
          text: `${self.timeFormat(perf)}${availableSlots && ` ${availableSlots}`}`,
        });
      });
    self.setState({
      performanceData: data.performance,
    });
  }

  const filterAvailableSlots = itm.filter((item) => !item.disabled);

  self.setState({
    timeSlots: filterAvailableSlots,
    existingProductError: false,
  });
};

export const updateButtonState = (self) => {
  const lcnsCnd = self.lcnsAvlbe ? self.licenseSlctd : true;
  let disable = true;
  let tickeCounter = false;
  if (self.props.multiTicketSelector.hideTNC) {
    for (const category in self.state.ticketCount.primaryCounterCount) {
      if (self.state.ticketCount.primaryCounterCount[category] === 0) {
        tickeCounter = true;
      }
    }
  }
  if (tickeCounter && self.props.multiTicketSelector.hideTNC) {
    disable = true;
  } else if ((self.tAndCchecked || self.props.multiTicketSelector.hideTNC) && self.timeSlotSlctd && lcnsCnd) {
    disable = false;
  }

  self.setState({
    buttonDisable: disable,
    existingProductError: false,
    maxAvailableTickets: self.maxCount,
  });
};

export const redirectToPaymentPageCallback = (self, checkoutContext) => {
  const { closeOverlay } = self.props;
  addLoaderOverlay();
  checkoutContext.href && redirectTo(checkoutContext.href);
  closeOverlay();
  if (!checkoutContext.href) {
    removeLoaderOverlay();
  }
};
export const addToCart = (self, checkoutContext) => {
  const finalProds = [];
  self.secProdSlctd.quantity = self.product.quantity || 1;
  self.updateDateInProds();
  self.updatePerfInProducts();
  self.updateTimeSlotInProds();
  self.updateLicnsInProd();
  if (self.addOnGuest && self.addOnGuest.quantity) {
    self.updateFnlAddGust();
    finalProds.push(self.addOnGuest);
  }
  if (self.secProdSlctd) {
    self.secProdSlctd.productType = self.secProdSlctd.tab;
    self.secProdSlctd.otherReason = self.secProdSlctd.experienceCatgory;
    finalProds.push(self.secProdSlctd);
  }

  if (finalProds.length > 0) {
    finalProds.forEach((prod) => {
      prod.showQtySpinnerInCart = self.props.counterData && self.props.counterData.maxCount > 0;
    });
  }

  if (!self.isTimeslotBooked(finalProds)) {
    checkoutContext && redirectToPaymentPageCallback(self, checkoutContext);
    self.props.addToCartCallback(finalProds);
    self.props.closeOverlay();
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: false,
    });
  } else {
    self.setState({
      existingProductError: true,
    });
  }

  if (isParkTenant()) {
    localStorage.setItem(
      UIConfig.localStoreKeys.purchaseJourney.visitedDate,
      convertJsDateToMomentObj(self.state.visitedDate),
    );
  }
};
export const getProductId = (self) => {
  if (self.props.additionalProds.length > 0) {
    const filteredProduct = self.props.additionalProds.filter(
      (prod) => prod.experienceCatgory && prod.experienceCatgory.toLowerCase() === 'primary',
    );
    return filteredProduct && filteredProduct.length > 0 ? filteredProduct[0].productId : self.product.productId;
  } else {
    return self.product.productId;
  }
};

export const getDefaultAdditionalProd = (self) => {
  if (self.overlaySelAvailable) {
    // Change the coveo items order as per CMS
    const newProdObjTemp = {};
    self.productOverlaySelector.collections.forEach((prod, indx) => {
      let prodCoveoVal = prod?.coveoval;
      const product = self.props.additionalProds.find((pr) => pr.productId === prod?.coveoval);
      if (prodCoveoVal) {
        newProdObjTemp[prodCoveoVal] = product;
      }
    });
    let cntr = 0;
    Object.keys(self.overlaySelMap).forEach((itm) => {
      self.additionalProducts[cntr] = newProdObjTemp[itm];
      cntr += 1;
    });
    // xxxxxxx Order change code ends here
  }

  var addProd = [];
  if (self.overlaySelAvailable) {
    addProd = self.additionalProducts.filter((prod, indx) => {
      let prodCoveoKey = self.productOverlaySelector.collections[indx].coveokey;
      return self.overlaySelMap[prod[self.props.coveoMapList[prodCoveoKey]]] === true;
    });
  } else {
    addProd.push(self.props.additionalProds[0]);
  }
  return addProd[0];
};

export const calculateTotal = (self) => {
  let counterSum = 0;
  for (const counter in self.state.ticketCount) {
    if (self.state.ticketCount[counter]) {
      counterSum += Object.values(self.state.ticketCount[counter]).reduce((sum, value) => (sum += value), 0);
    }
  }

  const disableButton = counterSum > 0 && counterSum < parseInt(self.maxCount, 10) + 1;
  if (disableButton || self.isQuantityInOverlay) {
    let totalCalculatedAmount = 0;
    if (self.isAdultChildOverlay) {
      for (let idx = 0; idx < self.updatedAdditionalProds.length; idx++) {
        self.prodQty[idx] = self.updatedAdditionalProds[idx];
        self.prodQty[idx].performanceId = self.product.performanceId;
        self.prodQty[idx].timeSlot = self.product.timeSlot;
        if (self.isQuantityInOverlay && !self.props.data.enableRecommendation) {
          self.prodQty[idx].isFlexible = true;
          self.prodQty[idx].fromDate = moment().format(UIConfig.calendar.dateFormat);
          self.prodQty[idx].toDate = moment()
            .add(self.ticketValidity, 'months')
            .format(UIConfig.calendar.dateFormat);
        } else {
          self.prodQty[idx].fromDate = self.product.fromDate;
          self.prodQty[idx].toDate = self.product.toDate;
        }
        for (const category in self.state.ticketCount.primaryCounterCount) {
          if (self.updatedAdditionalProds[idx].classType === category) {
            self.prodQty[idx].quantity = self.state.ticketCount.primaryCounterCount[category];
            totalCalculatedAmount +=
              parseFloat(self.updatedAdditionalProds[idx].gross).toFixed(2) *
              self.state.ticketCount.primaryCounterCount[category];
            self.tnc
              ? self.setState({
                  disableAddToCart: !disableButton,
                  total: toTwoDecimalPlaces(totalCalculatedAmount),
                })
              : self.setState(
                  {
                    total: toTwoDecimalPlaces(totalCalculatedAmount),
                  },
                  () => self.updateButtonState(),
                );
          }
        }
      }
    } else {
      for (const category in self.state.ticketCount.primaryCounterCount) {
        if (self.state.ticketCount.primaryCounterCount[category] > 0) {
          self.product.quantity = self.state.ticketCount.primaryCounterCount[category];

          totalCalculatedAmount +=
            parseFloat(self.product.gross).toFixed(2) * self.state.ticketCount.primaryCounterCount[category];

          self.tnc
            ? self.setState({
                disableAddToCart: !disableButton,
                total: toTwoDecimalPlaces(totalCalculatedAmount),
              })
            : self.setState(
                {
                  total: toTwoDecimalPlaces(totalCalculatedAmount),
                },
                () => self.updateButtonState(),
              );
        }
      }
    }
  } else {
    self.setState(
      {
        disableAddToCart: !disableButton,
        total: '',
      },
      () => self.updateButtonState(),
    );
  }
};

export const getProductPerformance = (self, date) => {
  if (!self.calendarSettings.enableDatePreSelection && self.isFirstRender) {
    self.setState({
      timeSlots: [],
      total: 0,
      buttonDisable: true,
    });
    self.isFirstRender = false;
    return;
  }
  const url = self.props.services.getPerformance.url.replace('{0}', getProductId(self));
  const prod = self.secProdSlctd;
  PerformanceService.getPerformanceData(url, prod.fromDate, prod.toDate || prod.fromDate, true, '.addOns-Overlay')
    .then((response) => {
      const availabile =
        response.data.performancelist.hasOwnProperty('performance') &&
        response.data.performancelist.performance.some((timeSlot) => {
          return timeSlot.availability.available > 0 && timeSlot.sellable === 'true';
        });
      if (availabile) {
        self.isUpdateDropdownProps = true;
        if (
          !self.props.multiTicketSelector.showSingleTimeSlot &&
          response.data.performancelist.performance.length === 1
        ) {
          self.perfSlctd = response.data.performancelist.performance[0].performanceId;
          self.updatePerfInProducts();
          prod.ticketVal === 'DRIVE' && self.changeDiscountProd(date);
        } else {
          renderTimeSlots(self, response.data.performancelist);
          prod.ticketVal === 'DRIVE' && self.changeDiscountProd(date);
          self.setState({ isError: false, buttonDisable: true });
        }
        self.scrollToNextSection('calender');
      } else {
        self.setState({
          slotSelected: false,
          disableAddToCart: true,
          buttonDisable: true,
          total: '',
          isError: true,
          error: {
            errorcode: '7001',
          },
        });
      }
    })
    .catch((err) => {
      self.isUpdateDropdownProps = true;
      self.setState({
        error: {
          errorcode: '7001',
        },
        isError: true,
        buttonDisable: true,
        total: '',
        slotSelected: false,
        disableAddToCart: true,
        modifiedPerformanceData: [],
        customDates: [],
      });
    });
};

export const fetchMultiProductPerformance = (self) => {
  let productsArr = [];
  let productDetails = [];
  if (self.isDynamicPricingProd) {
    self.props.searchProductList
      .filter(
        ({ ticketVal, category, experienceCatgory, pricing }) =>
          ticketVal &&
          ticketVal === self.product.ticketVal &&
          experienceCatgory &&
          experienceCatgory.toLowerCase() === 'primary' &&
          category &&
          self.product.category &&
          category[0] === self.product.category[0] &&
          pricing.toLowerCase() === self.product.pricing.toLowerCase(),
      )
      .forEach((prod) => {
        productsArr.push(prod.productId);
        productDetails.push({
          productId: prod.productId,
          eventAk: prod.eventAks,
        });
      });
  } else {
    self.props.additionalProds.forEach((prod) => productsArr.push(prod.productId));
  }
  const { fromDate, toDate } = dateRangePerformance(self);
  PerformanceService.getMultiProductPerformanceData(
    self.props.services.getMultiProdPerformance.url,
    fromDate,
    toDate.replace(/\//g, '-'),
    self.isDynamicPricingProd ? { productDetail: productDetails } : { productId: productsArr },
    true,
    '.addOns-Overlay',
  )
    .then((response) => {
      if (response.data.performancelist.hasOwnProperty('performance')) {
        createTACData(self, response.data.performancelist);
        self.setState({
          performanceData: response.data.performancelist && response.data.performancelist.performance,
        });
      } else {
        self.setState({
          performanceData: { error: { errorcode: '7001' } },
          slotSelected: false,
          disableAddToCart: true,
        });
      }
    })
    .catch((err) => {
      self.setState({
        performanceData: { error: err.error },
        slotSelected: false,
        disableAddToCart: true,
        modifiedPerformanceData: [],
        // customDates: [],
      });
    });
};
const productsWithRelatedIds = (cartItems) => {
  return Object.values(cartItems).reduce((cartItem, prod) => {
    cartItem = [...cartItem, ...prod.products];
    return !!cartItem.length && cartItem.filter((item) => item.relatedProductIds);
  }, []);
};
export const getMinDateFromCartData = (product, { cart }) => {
  const items = !isEmpty(cart) && cart.items;
  const productswithRelatedIds = productsWithRelatedIds(items);

  const findFirstMatchedMainProd =
    !!productswithRelatedIds.length &&
    productswithRelatedIds.find(({ relatedProductIds }) => relatedProductIds.includes(product.productId));

  const datesSet =
    !isEmpty(findFirstMatchedMainProd) &&
    findFirstMatchedMainProd.fromDate &&
    new Date(findFirstMatchedMainProd.fromDate);
  return datesSet instanceof Date && !isNaN(datesSet.valueOf()) ? datesSet : null;
};
export const createTACData = (self, perfList) => {
  let availableDate = [],
    enabledDates = [],
    limitedDates = [],
    soldOutDates = [],
    availableDateMoment = [];
  const isYMCrecommendationTab =
    resolvePath(self.props, 'data.type', '') === UIConfig.b2c.purchaseJourney.recommendationTabCode &&
    isMatchTenant(UIConfig.tenants.ymc);
  if (perfList && perfList.performance && perfList.performance.length) {
    perfList.performance.forEach((item) => {
      if (availableDate.indexOf(item.date) === -1) {
        availableDate.push(item.date);
      }
    });

    const modifiedPerformanceData = availableDate.map((date) => {
      let available = 0,
        total = 0,
        data = [],
        sellable = '',
        productId = '';
      perfList.performance.forEach((filterItem) => {
        if (filterItem.date === date && filterItem.sellable === 'true') {
          sellable = filterItem.sellable;
          available += parseInt(filterItem.availability.available, 10);
          total += parseInt(filterItem.availability.total, 10);
          if (filterItem.hasOwnProperty('productId')) {
            productId = filterItem.productId;
          }
          data.push(filterItem);
        }
      });
      return { date, total, available, performance: data, sellable, productId };
    });

    // initialize an array for combined product
    let enabledDatesObj = {};
    let productMap = {};
    if (self.productOverlaySelector) {
      const calendProds = self.productOverlaySelector.collections || [];
      calendProds.forEach((id) => {
        if (!enabledDatesObj.hasOwnProperty(id.coveoval)) {
          enabledDatesObj[id.productId] = {
            key: id.cssClassKey,
            enabledDates: [],
          };
        }
      });
    }
    productMap = self.props.searchProductList.reduce((acc, curValue) => {
      return (acc = {
        ...acc,
        [curValue.productId]: { productId: curValue.productId },
      });
    }, {});

    modifiedPerformanceData &&
      modifiedPerformanceData.forEach((item) => {
        if ((Math.floor(item.total) && !Math.floor(item.available)) || item.sellable !== 'true') {
          // if total is 0 then that is unlimited seats scenario.
          soldOutDates.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
        } else {
          if (item.available < self.props.data.limitedAvailabilityThreshold) {
            limitedDates.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
          }
          if (item.hasOwnProperty('productId')) {
            if (self.props.searchProductList && self.props.data.variant === UIConfig.dyanmicPricingVariant) {
              if (!enabledDatesObj[item.productId]) {
                enabledDatesObj = {
                  ...enabledDatesObj,
                  [item.productId]: {
                    enabledDates: [],
                    key: productMap[item.productId] ? productMap[item.productId].productId : null,
                    productName: productMap[item.productId] ? productMap[item.productId].productName : null,
                  },
                };
              }
              enabledDatesObj[item.productId].enabledDates.push(
                momentTimezone(item.date, getClientUtcOffset()).toDate(),
              );
              enabledDatesObj[item.productId].key = productMap[item.productId]
                ? productMap[item.productId].productId.replaceAll('.', '-')
                : null;
              availableDateMoment.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
            } else if (enabledDatesObj.hasOwnProperty(item.productId)) {
              enabledDatesObj[item.productId].enabledDates.push(
                momentTimezone(item.date, getClientUtcOffset()).toDate(),
              );
              availableDateMoment.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
            }
          } else {
            enabledDates.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
            availableDateMoment.push(momentTimezone(item.date, getClientUtcOffset()).toDate());
          }
        }
      });

    let currentDate = moment().format(UIConfig.calendar.dateFormat);
    if (self.enableDynamicCalendar && self.isDynamicPricingProd) {
      self.currentDateSlots = modifiedPerformanceData.find((performanceItem) => {
        return performanceItem.date === currentDate;
      });
    }

    const secCustomDates = [];
    Object.keys(enabledDatesObj).forEach((item) => {
      secCustomDates.push({
        [enabledDatesObj[item].key]: enabledDatesObj[item].enabledDates,
      });
    });
    if (self.props.data.variant === UIConfig.dyanmicPricingVariant) {
      secCustomDates.push({
        'current-date': [momentTimezone(currentDate, getClientUtcOffset()).toDate()],
      });
      document.querySelectorAll('.react-datepicker__day--keyboard-selected')[0] &&
        document
          .querySelectorAll('.react-datepicker__day--keyboard-selected')[0]
          .classList.remove('react-datepicker__day--keyboard-selected');

      document.querySelectorAll('.react-datepicker__day--selected')[0] &&
        document
          .querySelectorAll('.react-datepicker__day--selected')[0]
          .classList.remove('react-datepicker__day--keyboard-selected');
    }
    const highlightRange =
      self.state.selectedDiscount && Object.keys(self.state.selectedDiscount).length > 0
        ? self.state.selectedDiscount.highlightRange
        : [];
    const customDates = [
      ...secCustomDates,
      {
        'limited-seats': limitedDates,
      },
      {
        'sold-out': soldOutDates,
      },
      {
        'active-dates': availableDateMoment,
      },
      {
        'react-datepicker__day--disabled': unavailableDates(self, modifiedPerformanceData),
      },
      ...highlightRange,
    ];
    self.setState({
      customDates,
      modifiedPerformanceData,
      minDateFromCartData: isYMCrecommendationTab ? getMinDateFromCartData(self.product, self.props.cartData) : '',
    });
    if (
      self.isDynamicPricingProd &&
      self.calendarSettings.enableDatePreSelection &&
      modifiedPerformanceData &&
      modifiedPerformanceData.length > 0
    ) {
      self.setState({
        selectDefaultDate: true,
      });
      setTimeout(() => {
        const keyboardSelectedDateCssRemove = document.querySelectorAll('.react-datepicker__day--keyboard-selected');
        if (keyboardSelectedDateCssRemove && keyboardSelectedDateCssRemove[0]) {
          keyboardSelectedDateCssRemove[0].classList.remove('react-datepicker__day--keyboard-selected');
        }
      }, 10);
    }
  } else {
    self.setState({
      performanceData: { error: { errorcode: '7001' } },
      slotSelected: false,
      disableAddToCart: true,
      // customDates: [],
    });
  }
};

export const calendarDateCallback = (self, date, mount, preventScroll) => {
  const isMount = mount && typeof mount === 'boolean';
  self.setState({
    total: 0,
    slotSelected: false,
    selectDefaultDate: false,
    visitedDate: date,
  });
  self.isTimeSlotSelected = false;
  let selectedDate = date
    ? date.format(UIConfig.calendar.dateFormat)
    : moment()
        .locale('en')
        .format(UIConfig.calendar.dateFormat);
  self.product.fromDate = selectedDate;
  self.dateSlctd = selectedDate;
  const performanceData = self.state.modifiedPerformanceData.filter((item) => item.date === selectedDate)[0];

  if (performanceData && performanceData.hasOwnProperty('performance') && performanceData.performance.length) {
    renderTimeSlots(self, performanceData, selectedDate);
    self.product.performanceId = '';
    self.product.timeSlot = '';
    self.calendarDateChosen = true;
    if (self.props.data.variant !== UIConfig.dyanmicPricingVariant) {
      self.setState({
        totalCalculatedAmount: '',
        slotSelected: false,
        disableAddToCart: true,
      });
    }
    self.setState(
      {
        performanceData,
        maxAvailableTickets: '',
      },
      () => {
        self.isFirstRender = false;
        const { customDates } = self.state;
        if (!preventScroll && customDates) {
          const currentDate = customDates.find((item) => item.hasOwnProperty('current-date-selected'));
          if (currentDate) {
            const updatedCustomDates = customDates.filter((item) => !item.hasOwnProperty('current-date-selected'));
            self.setState({ customDates: updatedCustomDates });
          }
          if (selectedDate && !isMount) {
            if (
              self.props.data.variant === UIConfig.dyanmicPricingVariant &&
              self.props.timeSlotData &&
              self.props.timeSlotData.showSingleTimeSlot &&
              !isEmpty(self.props.timeSlotData)
            ) {
              self.scrollToNextSection('c-date-selector--time-slot');
            } else {
              self.scrollToNextSection('timeSlot');
            }
          }
        }
      },
    );
  } else {
    self.setState({ performanceData: { error: { errorcode: '7001' } }, slotSelected: false, disableAddToCart: true });
  }
};

export const removeDuplicatedSecProd = (secProds) => {
  const newSecProds = new Set();
  return secProds.filter((item) => {
    const isDuplicate = newSecProds.has(item.productId);
    newSecProds.add(item.productId);
    if (!isDuplicate) {
      return true;
    }

    return false;
  });
};

export const dateRangePerformance = (self) => {
  const toDate =
    self.calendarSettings.endDate ??
    moment()
      .locale('en')
      .add(parseInt(self.calendarSettings.range, 10), 'days')
      .format(UIConfig.calendar.dateFormat);

  const fromDate =
    self.product.fromDate ??
    moment()
      .locale('en')
      .format(UIConfig.calendar.dateFormat);

  return { fromDate, toDate };
};

const unavailableDates = (self, modifiedPerformanceData) => {
  let unavailableDateMoment = [];
  const allPerformanceDate = modifiedPerformanceData && modifiedPerformanceData.map((item) => item.date);

  const formatRangeDates = getRangeDates(self);

  formatRangeDates &&
    formatRangeDates.length &&
    formatRangeDates.forEach((item) => {
      const newItem = moment(item).format('YYYY-MM-DD');
      if (!allPerformanceDate.includes(newItem)) {
        unavailableDateMoment.push(momentTimezone(item, getClientUtcOffset()).toDate());
      }
    });

  return unavailableDateMoment;
};
export const getRangeDates = (self) => {
  const { fromDate, toDate } = dateRangePerformance(self);
  const momentRangeDates = getIncludedDates(toDate, fromDate);

  return momentRangeDates && momentRangeDates.length && momentRangeDates;
};
