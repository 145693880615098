import moment from 'moment';

import UIConfig from '../../../common/UIConfig';
import {
  checkInlineSignup,
  deepCloneObject,
  getLocalStorageByKey,
  isMatchTenant,
  resolvePath,
} from '../../../common/utility';
import { initValdiations, validateField } from './validator';

let num;
/**
 * Update Payload object if it is B2B registration form.
 * @param    @Object type {field name, Value}
 */
export const updatePartnersApiPayload = (params) => {
  const updatedParams = {
    createPartnerInput: {
      ...params,
      ContactPurchaseRight: true,
      ContactRole: 'Primary Contact',
      Telephone1: params.ContactTelephone1,
      countryCode: params.Country,
      Country: '',
    },
  };

  return updatedParams;
};

/**
 * Update Payload object on change of field values.
 * @param    @String type {Value , field name}
 * @param additionalObj additional value with field value
 */
export const updatePayLoad = (self, value, field, evntObj, additionalObj) => {
  const payLoad = {
    ...self.state.payLoad,
    [field]:
      field === 'Captcha'
        ? self.getCaptchaValue(value, evntObj)
        : evntObj.target && evntObj.target.getAttribute && evntObj.target.getAttribute('data-checkedValue')
        ? self.getCheckboxGroupValue(field, value, evntObj)
        : value,
  };

  if (additionalObj && additionalObj.target && additionalObj.target.value) {
    payLoad.additionalObjVal = additionalObj.target.value;
  }

  const updatedFields = { ...self.state.updatedFields };
  if (self.state.formData.variant !== 'b2bContactUsLoggedIn') {
    self.state.formData.sections
      .map((section) => section.fields.filter((f) => f.hide))
      .reduce((acc, val) => acc.concat(val), [])
      .forEach((f) => {
        delete payLoad[f.id];
      });
  }

  const updatedState = {};

  if (self.isControlledForm) {
    const updatedFormData = deepCloneObject(self.state.formData);
    updatedFormData.sections.forEach((section) => {
      section.fields.forEach((currentField, fieldKey) => {
        if (currentField.name === field) {
          currentField.value = value;
          if (currentField && currentField.type && currentField.type.toLowerCase() === 'selectwithtext') {
            section.fields[fieldKey].dropdownValue = additionalObj.target.value || '';
          }
          if (currentField && currentField.type && currentField.type.toLowerCase() === 'checkbox') {
            currentField.checked = value && value !== 'false' ? true : false;
          }
          if (currentField && currentField.type && currentField.type.toLowerCase() === 'checkboxgroup') {
            currentField.options = currentField.options.map((opt) => {
              if (evntObj.target.getAttribute('data-checkedValue') === self.allActivities) {
                return { ...opt, checked: value === 'true' };
              } else if (opt.value === self.allActivities) {
                return { ...opt, checked: false };
              } else if (opt.value === evntObj.target.getAttribute('data-checkedValue')) {
                return { ...opt, checked: value && value !== 'false' ? true : false };
              }
              return opt;
            });
            const selectAllLength = currentField.options.filter((item) => item.checked === true);
            if (selectAllLength && currentField.options.length - 1 === selectAllLength.length) {
              currentField.options = currentField.options.map((opt) => {
                if (opt.value === self.allActivities) {
                  return { ...opt, checked: true };
                } else {
                  return opt;
                }
              });
            }
          }
          if (currentField.updateField !== '') {
            updatedFields[currentField.updateField] = value;
            if (
              !self.props.is2StepPayment ||
              (self.props.is2StepPayment && currentField.updateField !== 'MobileNumber')
            ) {
              // To prevent to reassign to empty string for phone
              if (!self.props.preventUpdateField) {
                payLoad[currentField.updateField] = '';
              }
            }
          }
        }
      });
    });
    if (['HeightInCm', 'WeightInKg'].includes(field)) {
      updatedFormData.sections.forEach(({ fields }) => {
        const height = self.state.payLoad['HeightInCm'];
        const weight = self.state.payLoad['WeightInKg'];
        const updateField = fields.filter((f) => f.name === field).map((f) => f.updateField)[0];

        fields.forEach((cf) => {
          if (cf.name === updateField && height && weight) {
            const bmi = self.calculateBMI(height, weight);
            cf.active = 'active';
            cf.value = bmi;
            payLoad[updateField] = bmi;
          }
        });
      });
    }
    updatedState.formData = updatedFormData;
  }
  updatedState.payLoad = payLoad;
  updatedState.updatedFields = updatedFields;
  self.updatedPayLoadValue = updatedState.payLoad;
  self.setState(updatedState, () => {
    self.props.isNewForm &&
      self.props.handleCustomValidation &&
      self.props.handleCustomValidation(self.state.payLoad, self.state.focus, self.state.validationErrors) &&
      evntObj.target &&
      evntObj.target.getAttribute &&
      evntObj.target.getAttribute('data-checkedValue') &&
      self.checkError(updatedState.payLoad[field], field);
    if (self.retainDataOnError) {
      const { data } = self.props;
      const formAction = data.action
          ? data.action
          : data.formType && data.services[data.formType]
          ? data.services[data.formType].url
          : null,
        formStorage = localStorage.getItem('form-data-object'),
        formJson = formStorage && JSON.parse(formStorage),
        storageFormData = formJson && formJson[formAction];
      if (storageFormData) {
        storageFormData[field] = payLoad[field];
        !storageFormData.hasOwnProperty('additionalObjValCollection') &&
          (storageFormData['additionalObjValCollection'] = {});
        const mobileFieldIds = ['mobileNum', 'MobilePhone', 'EmergencyContactNumber', 'PhoneNumber'];
        if (mobileFieldIds.includes(field)) {
          storageFormData.additionalObjVal = payLoad.additionalObjVal;
          storageFormData.additionalObjValCollection[field] = payLoad.additionalObjVal;
          storageFormData[field] = storageFormData[field].replace(storageFormData.additionalObjVal, '');
        }
        if (field === 'HeightInCm' || field === 'WeightInKg') {
          const height = updatedState.payLoad['HeightInCm'];
          const weight = updatedState.payLoad['WeightInKg'];
          if (height && weight) {
            const bmi = self.calculateBMI(height, weight);
            storageFormData['BodyMassIndex'] = bmi;
          }
        }
        localStorage.setItem('form-data-object', JSON.stringify({ ...formJson, [formAction]: storageFormData }));
      } else {
        localStorage.setItem('form-data-object', JSON.stringify({ ...formJson, [formAction]: payLoad }));
      }
    }
  });
};

//get Captcha value
export const getCaptchaValue = (self, value, evntObj) => {
  if (evntObj && value) {
    let capthcaParent = evntObj.target.parentNode.parentNode;
    self.captchaID = capthcaParent ? capthcaParent.getAttribute('data-id') : null;
    return {
      botnetSessionId: capthcaParent ? capthcaParent.getAttribute('data-captchaId') : null,
      value: value,
    };
  }
  return value;
};

const isSameCardNumber = (newNum) => {
  if (num === newNum) {
    num = num;
    return true;
  } else {
    num = newNum;
    return false;
  }
};

/**
 * Check for error on field change event if error update state.
 * @param    @String type {Value , field name}
 *
 */
export const checkError = async (self, value, field, checkExistingYasIdError = false, id) => {
  const payLoad = self.state.payLoad,
    errors = validateField(self.rules, value, field, payLoad, self);

  let isValidErrors = {};
  const mainObj = getLocalStorageByKey('mainObj');
  const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();
  const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
  const isSwad = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.SWADB2C;
  const isYasIsland = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
  const isInsApplicable = Object.values(UIConfig.tenants).includes(tenantId) || isSwad;
  const sameCardNumber = field === 'cardNumber' && isSameCardNumber(Number(value.replace(/ /g, '')));
  let tempemailValidation = {};
  // To make the Existing Yas ID null in case we get any other error for the email field
  if (errors.Email && self.state.existingEmailError) {
    self.setState({
      existingEmailError: null,
    });
  }

  const typeEmail = (self.rules && self.rules.length > 0 && self.rules.find((e) => e.name === field)) || {};
  const type = typeEmail.rules.type;

  // Field to confirm has a value if confirm email field is added from the CMS , now we need to apply the check for 'Confirm' + value of fieldToConfirm rather than hardcoding it as field !== id

  let confirmFieldId;
  for (const obj of self?.rules) {
    if (obj.rules && obj.rules.fieldToConfirm) {
      confirmFieldId = 'Confirm' + obj.rules.fieldToConfirm;
      break; // Exit the loop as we found the value
    }
  }

  // to trigger API call for checking zero bounce in email field

  if (
    type &&
    type.toLowerCase() === 'email' &&
    // field !== 'ContactEmailAddress1' &&
    (!isMatchTenant(UIConfig.tenants.yi) || (isMatchTenant(UIConfig.tenants.yi) && id !== confirmFieldId)) &&
    value.length > 0 &&
    (!self.isGuestCheckoutForm || isYasIsland) &&
    (self.isNewsLetterForm ||
      self.isCorpEnrollmentForm ||
      self.isContactUs ||
      self.isGuestCheckoutForm ||
      self.isExpressCheckoutForm)
    // && !isMatchTenant(UIConfig?.tenants?.wbw)
  ) {
    if (self.isExpressCheckoutForm) {
      await self.checkZeroBounceEmail(value, field);
      isValidErrors = { ...errors };
    } else {
      tempemailValidation = await self.checkZeroBounceEmail(value, field);
      isValidErrors = { ...errors, ...tempemailValidation };
    }
  } else {
    isValidErrors = { ...errors };
  }

  if (isValidErrors) {
    if (Object.keys(isValidErrors).length !== 0 && isValidErrors.constructor === Object) {
      self.setState({ validationErrors: isValidErrors, showErrors: true });
    } else {
      if (self.props.isNewForm || self.props.is2StepPayment) {
        self.setState({
          active: true,
          showErrors: false,
        });
      } else {
        self.setState({ active: true, showErrors: false });
      }
    }
  }
  const inlineFormCheck = isYasIsland
    ? checkInlineSignup() && self.isGuestCheckoutForm
    : checkInlineSignup() || self.isGuestCheckoutForm;
  // to trigger API call for existing Yas ID if no other error exists in email field
  if (inlineFormCheck && checkExistingYasIdError && !errors.Email) {
    //Add emailId to gtmdataLayer

    const updatedArray =
      window.dataLayer &&
      window.dataLayer.length > 0 &&
      window.dataLayer.map((data) => {
        return data.event === 'checkout' ? { ...data, emailId: value } : data;
      });
    window.dataLayer = updatedArray && updatedArray.length > 0 ? updatedArray : window.dataLayer;
    self.checkExistingYasID(value, field);
  }
  if (self.props.is2StepPayment) {
    window.PubSub.publish('checkPayButtonStatus');
  }
  if (
    isInsApplicable &&
    field === 'cardNumber' &&
    self.props.is2StepPayment &&
    self.props.cartData &&
    value &&
    !errors.cardNumber &&
    !sameCardNumber
  ) {
    self.props.getInstallmentPlans(value);
  }
};

export const onResetClick = (self, e) => {
  e.preventDefault();
  initValdiations();
  let updatedFormData = deepCloneObject(self.state.formData);
  updatedFormData.sections.forEach((section) => {
    section.fields.forEach((field) => {
      let fieldNotFound = true;
      for (const key in self.initialPayload) {
        if (self.initialPayload.hasOwnProperty(key) && field.name === key) {
          field.value = self.initialPayload[key];
          if (field && field.type && field.type.toLowerCase() === 'checkbox') {
            field.checked = field.value && field.value !== 'false' ? true : false;
          }
          fieldNotFound = false;
        }
      }
      if (fieldNotFound) {
        field.value = '';
        field.notFound = true;
      }
    });
  });
  if (self.props.resetCallBack) {
    const data = self.props.resetCallBack(deepCloneObject(updatedFormData));
    updatedFormData = data ? data : updatedFormData;
  }
  self.setState({
    payLoad: self.initialPayload,
    formData: updatedFormData,
    showErrors: false,
  });
};

/**
 * Field change event  pass field value to error and payload method.
 * @param    @String type {Value , field name}
 *
 */
export const onFieldChange = async (self, e) => {
  typeof self.props.onSubmitHideNewsletterError === 'function' && self.props.onSubmitHideNewsletterError();
  let value, field, type, target;
  target = !e.target ? e : e.target;
  value = target.value;
  field = target.name;
  type = target.type;
  self.updatePayLoad(value, field, e);

  if (self.isCorpEnrollmentForm) {
    await self.checkError(value || '', 'corporateEmail');
  }

  if (e.target && e.target.getAttribute && e.target.getAttribute('data-checkedValue')) {
    const { payLoad, formData } = self.state;
    const targetSection =
      formData &&
      Array.isArray(formData.sections) &&
      formData.sections.filter(({ fields }) => fields.some(({ name }) => name === field));
    const targetField =
      targetSection &&
      Array.isArray(targetSection) &&
      targetSection[0].fields.reduce((acc, f) => {
        if (f.name === field) {
          acc.push(f);
        }
        return acc;
      }, []);
    if (Array.isArray(payLoad[field]) && !payLoad[field].length && value !== 'false') {
      value = [e.target.getAttribute('data-checkedValue')];
    } else if (Array.isArray(payLoad[field]) && payLoad[field].length === 1 && value !== 'true') {
      value = [];
    } else {
      value = self.state.payLoad[field];
    }
    if (targetField[0].isRequired) {
      self.checkError(value, field);
    }
  } else if (type === 'checkbox' || type === 'radio') {
    self.checkError(value, field);
  }
  // added for radio
  if (type === 'radio' && self.props.onChange) {
    self.props.onChange(e.target);
  }
  //commenting for issue FEEXP-3150

  if (field && field.type && field.toLowerCase() === 'country') {
    self.setState({
      selectedCountry: value,
    });
  }

  if (field && field.type && field.toLowerCase() === 'countryofresidence') {
    self.setState({
      selectedCountryOfResidence: value,
    });
  }

  if (field && field.type && field.toLowerCase() === 'nationality') {
    self.setState({
      selectedNationality: value,
    });
  }
};

/**
 * Field blur event  pass field value to error and payload method.
 * @param    @String type {Value , field name}
 *
 */
export const onFieldBlur = (self, e, additionalObj = false) => {
  let value = e.target.value;
  const payLoad = self.state.payLoad;
  const field = e.target.name;
  const id = e?.target?.id;
  self.updatePayLoad(value, field, e, additionalObj);
  if (self.props.getFields) {
    const getPayLoad = self.props.getFields;
    getPayLoad.call(self, self.state.payLoad, e, validateField(self.rules, value, field, payLoad));
  }
  if (e.target && e.target.getAttribute && e.target.getAttribute('data-checkedValue')) {
    value = self.state.payLoad[field];
  }
  if (isMatchTenant(UIConfig?.YIB2C)) {
    checkError(self, value, field, false, id);
  } else {
    self.checkError(value, field);
  }

  if (!value) {
    self.setState({ focus: '' });
  }
  if (self.props.getFields) {
    const getPayLoad = self.props.getFields;
    getPayLoad.call(self, self.updatedPayLoadValue, e, validateField(self.rules, value, field, payLoad));
  }
  if (self.props.is2StepPayment && self.props.handleValidityValidation) {
    self.props.handleValidityValidation(field, value);
  }
  if (checkInlineSignup() && e.target.id === 'Email' && e.target.value !== '') {
    if (isMatchTenant(UIConfig?.YIB2C)) {
      checkError(self, value, field, true, id);
    } else {
      self.checkError(value, field, true);
    }
  }
};

export const createNewPayload = (self, data) => {
  let sections = data.sections,
    payload,
    formElements =
      Array.isArray(sections) &&
      sections.map((section, i) => {
        return (
          Array.isArray(section.fields) &&
          section.fields.map((field, i, fields) => {
            if (field.updateField && field.updateField !== '') {
              const [updateField] = fields.filter((elem) => elem.id === field.updateField);

              if (updateField.value === '') {
                self.initialUpdatedFields[field.updateField] = field.value;
              } else {
                self.initialUpdatedFields[field.updateField] = updateField.value;
              }
            }

            if (field && field.type && field.type.toLowerCase() === 'radio' && Array.isArray(field.options)) {
              const checkedRadio = field.options.filter((radioItem) => radioItem.checked);
              return {
                [field.name]: (checkedRadio.length && checkedRadio[0].value) || '',
              };
            }

            if (field && field.type && field.type.toLowerCase() === 'checkboxgroup' && Array.isArray(field.options)) {
              const { name, options } = field;
              let checkboxDetailObject = {
                [name]: {
                  allRegisterValues: [],
                },
              };
              self.checkboxDetailStatus = {
                ...self.checkboxDetailStatus,
                ...checkboxDetailObject,
              };
              return {
                [name]: options
                  .filter((opt) => {
                    if (opt.value !== self.allActivities) {
                      self.checkboxDetailStatus[name].allRegisterValues.push(opt.value);
                    }
                    return opt.checked;
                  })
                  .reduce((acc, opt) => {
                    return acc;
                  }, []),
              };
            }

            return {
              [field.name]: field.value || field.checked || '',
            };
          })
        );
      });

  payload = [].concat([], ...formElements).reduce((Obj, eachFieldPayLoad) => {
    for (let key in eachFieldPayLoad) Obj[key] = eachFieldPayLoad[key];
    return Obj;
  }, {});
  return payload;
};

export const updateFieldValueWithStorage = (self, field) => {
  if (self.retainDataOnError) {
    const currentField = field.name;
    // retain form data
    const formStorage = localStorage.getItem('form-data-object'),
      formJson = formStorage && JSON.parse(formStorage),
      data = self.props.data,
      formAction = data.action
        ? data.action
        : data.formType && data.services[data.formType]
        ? data.services[data.formType].url
        : null,
      storageFormData = formJson && formJson[formAction];

    field.value =
      storageFormData && storageFormData.hasOwnProperty(currentField) ? storageFormData[currentField] : field.value;
    field.val =
      storageFormData && storageFormData.hasOwnProperty(currentField) ? storageFormData[currentField] : field.val;
  }

  return field;
};

export const updatePayloadWithStorageData = (self) => {
  if (self.retainDataOnError) {
    const formData = deepCloneObject(self.state.formData);
    const data = self.props.data,
      formAction = data.action
        ? data.action
        : data.formType && data.services[data.formType]
        ? data.services[data.formType].url
        : null,
      formStorage = localStorage.getItem('form-data-object'),
      formJson = formStorage && JSON.parse(formStorage),
      storageFormData = formJson && formJson[formAction];

    const statePayload = deepCloneObject(self.state.payLoad);
    const additionalObjVal = (storageFormData && storageFormData.additionalObjVal) || '';
    const additionalObjValCollection = (storageFormData && storageFormData.additionalObjValCollection) || {};
    if (formData) {
      const sections = formData.sections;
      const updatedSections = sections.map((section) => {
        section.fields =
          section &&
          section.fields.map((field) => {
            if (field.type !== 'Captcha') {
              field = self.updateFieldValueWithStorage(field);
            }
            if (field.value) {
              field.active = 'active';
              statePayload[field.name] = field.value;
              if (field.type === 'Calendar') {
                field.value = moment(field.value).format(UIConfig.contactUs.dateFormat);
              }
              const mobileFieldIds = ['mobileNum', 'MobilePhone', 'EmergencyContactNumber'];
              if (mobileFieldIds.includes(field.id)) {
                additionalObjValCollection &&
                  Object.keys(additionalObjValCollection).forEach((itemKey) => {
                    if (itemKey === field.name) {
                      const key = additionalObjValCollection[itemKey];
                      field.dropdownValue = key;
                    }
                  });
              }
              if (field.id === 'TermsAndConditionsContactUs') {
                field.val = field.value = '';
                statePayload[field.name] = '';
              }
            }
            return field;
          });
        return section;
      });

      formData.sections = updatedSections;
      statePayload.additionalObjVal = additionalObjVal;
      self.setState({
        formData,
        payLoad: statePayload,
      });
    }
  }
};

/**
 * controls the conditional display of child
 *
 * @param {boolean} condition
 * @param {Object} child
 */
export const displayChild = (self, condition, child) => {
  if (condition) {
    child.toggleDisplay = 'hide-element';
    if (self.isYAFreeActivity) {
      child.label =
        child.label[child.label.length - 1] === '*' ? child.label.substring(0, child.label.length - 1) : child.label;
    } else {
      child.value = '';
    }
    child.isRequired = false;
    if (self.rules) {
      const targetRule = self.rules[self.rules.findIndex(({ name }) => name === child.name)];
      targetRule.rules.isRequired = false;
    }
    const { showErrors, validationErrors } = self.state;
    if (showErrors && validationErrors && Object.keys(validationErrors).length === 1 && validationErrors[child.name]) {
      self.setState({ showErrors: false, validationErrors: {} });
    }
    if (self.isYAFreeActivity && showErrors && validationErrors && validationErrors[child.name]) {
      let validationErrors = { ...self.state.validationErrors };
      delete validationErrors[child.name];
      self.setState({
        validationErrors,
      });
    }
  } else {
    child.toggleDisplay = 'show-element';
    if (self.isYAFreeActivity) {
      child.label = child.label[child.label.length - 1] !== '*' ? child.label + '*' : child.label;
    }
    child.isRequired = true;
    if (self.rules) {
      const targetRule = self.rules[self.rules.findIndex(({ name }) => name === child.name)];
      targetRule.rules.isRequired = true;
    }
  }
};
