import React, { useEffect } from 'react';

// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

const NotFound = ({ context = { site: { name: '' }, language: '' }, tenantName }) => {
  useEffect(() => {
    const pathName = window.location.pathname.split('/')[2];
    if (tenantName === pathName) {
      window.location = `${window.location.origin}/${context.language.split('-')[0]}/${pathName}/error`;
    } else {
      window.location = `${window.location.origin}/${context.language.split('-')[0]}/error`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default NotFound;
