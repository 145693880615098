import React, { useEffect, useRef, useState } from 'react';
import useScript from '../../../hooks/useScript';
import { getDataLayerScript } from '../common-analytics-scripts';
import {
  checkGA4Tenants,
  generateShaEncryption,
  getEmailIdGTMData,
  getLoggedInUser,
  getYasIdGTMData,
  isLoggedInUser,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';

const Analytics = (props) => {
  const intervalRef = useRef(null);

  useEffect(() => {
    const events = ['scroll', 'mousemove', 'touchstart'];

    const initGTMOnEvent = (event) => {
      initGTM();
      events.forEach((e) => event.currentTarget.removeEventListener(e, initGTMOnEvent));
    };

    const initGTM = () => {
      let dataLayer = window.dataLayer || [];
      if (window.gtmDidInit) {
        return false;
      }
      window.gtmDidInit = true;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => {
        dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 });
      };
      script.src = `https://www.googletagmanager.com/gtm.js?id=${props.gtmId}`;

      document.head.appendChild(script);
    };
    events.forEach((e) => document.addEventListener(e, initGTMOnEvent));
  }, [props]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      window.dataLayer && checkGA4Tenants([UIConfig.tenants.yi]) && initPageViewEvent();
    }, 1000);
    setTimeout(() => {
      clearInterval(intervalRef.current);
    }, 10000);
    return () => clearInterval(intervalRef.current); //This is important
  }, []);

  const initPageViewEvent = () => {
    const {
      siteType,
      marketType,
      tenantName,
      language,
      primaryItem,
      secondaryItem,
      tertiarySection,
      pageName,
      userRole,
      userRoleLoggedin,
      loginStatusLoggedIn,
      loginStatus,
      searchTerm,
      searchCount,
      pageCategory,
    } = props;
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      site_type: siteType || undefined,
      event: 'page_view',
      tenant: tenantName,
      page_language: language || undefined,
      primary_section: primaryItem || undefined,
      secondary_section: secondaryItem || undefined,
      tertiary_section: tertiarySection || undefined,
      page_name: pageName,
      page_category: pageCategory || undefined,
      user_role: isLoggedInUser() ? userRoleLoggedin : userRole,
      login_status: isLoggedInUser() ? loginStatusLoggedIn : loginStatus,
      search_term: searchTerm || undefined,
      search_count: searchCount || undefined,
      market_type: marketType || undefined,
      my_pass_id: getYasIdGTMData() || undefined,
      user_id: getYasIdGTMData() || undefined,
      login_type: getLoggedInUser()?.loginProvider || undefined,
      email: getEmailIdGTMData() || undefined,
      email_hashed: getEmailIdGTMData() ? generateShaEncryption(getEmailIdGTMData()) : undefined,
    });
    clearInterval(intervalRef.current);
  };

  useScript(`
    ${!checkGA4Tenants([UIConfig.tenants.yi]) && getDataLayerScript(props)}
    document.addEventListener('DOMContentLoaded', function () {
      if ('${props.tenantId}' === 'ymcb2c'){
        var loggedInUserAnalytics = JSON.parse(localStorage.getItem('loggedInUser'));
        if (loggedInUserAnalytics && (typeof loggedInUserAnalytics === object)) {
            dataLayer[0].userId = loggedInUserAnalytics.yasId;
        }
        else {
            dataLayer[0].userId = '';
        }
      }
    }, false);
  `);

  return <React.Fragment></React.Fragment>;
};

export default Analytics;
