/*
 * Form-Component.js
 * This file contains code for form component, it renders form
 * @licensor  Miral
 */

import React, { useEffect, useState, useRef, useContext } from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { TrackerContext } from '@uniformdev/tracking-react';

import Form from '../../container/form/form-component';
import ApiWrapper from '../../../common/api-wrapper';
import TextWithCTA from '../../container/text-with-cta';
import {
  getErrorMap,
  getLoggedInUser,
  scrollToFormErrorOrSuccess,
  isLoginValid,
  deepCloneObject,
  canUseDOM,
  filterErrorsData,
  decodeQueryString,
  getUserAgent,
  resolvePath,
  getCurrentLanguage,
  isLoggedInUser,
  checkInlineSignup,
  getLanguageWithoutLocal,
  isMatchTenant,
  checkIfParks,
  checkTenant,
} from '../../../common/utility';
import { PartnerApi } from '../../../common/partner-api';
import UIConfig from '../../../common/UIConfig';
import { PartnerService, ProfileServices, PerformanceService, OrderService } from '../../../common/services';
import { ErrorSummary } from '../../presentation/base';
import {
  contactUsAnalytics,
  getQuoteAnalytics,
  HnFRegistrationAnalytics,
  preregistrationAnalytics,
} from '../../../common/analytics-events';
import { Logging, logComponentRenderingError } from '../../../common/logger';
import { bindModalToAnchorLink } from '../../presentation/base/bind-modal-to-anchor-link/bind-modal-to-anchor-link-component.js';
import GoalAnalytics from '../../../common/goal-analytics';
import { GLOBAL_VARS } from '../../../common/global-vars';

const getAddTowalletFields = (sections = []) => {
  let fields = {};
  for (let i = 0; i < sections.length; i++) {
    const [field1, field2] = sections?.[i]?.fields?.filter(
      (field) =>
        field.type === UIConfig.formFields.type.HIDDEN &&
        (field.id === UIConfig.formFields.id.PASS_TYPE || field.id === UIConfig.formFields.id.PRODUCT_DESC),
    );
    if (field1 || field2) {
      fields = { [field1.id]: field1.value, ...(field2 ? { [field2.id]: field2.value } : {}) };
      return fields;
    }
  }
  return fields;
};

const ContactUsForm = (props) => {
  const [responseStatus, setResponseStatus] = useState({
    serverErrors: null,
    success: false,
    errors: null,
    data: props.data,
  });
  const [enableLogin, setEnableLogin] = useState(false);
  const [passType, setPassType] = useState('');
  const [mediaId, setMediaId] = useState('');
  const [name, setName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  let moduleName = props.moduleName || 'Contact Us Form';
  let isYaFreeActivity = props.data.variant === 'v-ya-free-activity';
  let userInfo = useRef({});
  const performanceId = useRef(null);
  const retainFormData = useRef(props.data.retainFormData || false);
  const downloadTicketPayload = useRef(null);
  const tContext = useContext(TrackerContext);
  const isYMC = checkTenant(UIConfig.iamMapping.ymc);

  const [disableForm, setDisableForm] = useState(true);

  useEffect(() => {
    const { passType, productDescription } = getAddTowalletFields(props.data?.sections);
    passType && setPassType(passType || '');
    productDescription && setProductDescription(productDescription || '');
  }, []);

  /*
   * Method to call API for B2C guest detail APIs
   */
  const getB2CDetails = (user, props) => {
    if (props.data.services.getGuestProfile && props.data.services.getGuestProfile.url) {
      ProfileServices.GetGuestInfo(props.moduleName, props.data.services.getGuestProfile.url, true, '.container').then(
        (response) => {
          if (response && response.data) {
            const data = deepCloneObject(props.data);
            data.sections.forEach((section) => {
              section.fields.forEach((field) => {
                changeFieldValue(field, response.data);
              });
            });
            setResponseStatus({ ...responseStatus, data });
          }
        },
      );
    }
  };

  /*
   * Method to map Contact Us inputs with API response
   */
  const changeFieldValue = (field, response, mapperFlag = false) => {
    const countryField = ['Country', 'country'];
    let country = countryField.find((val) => response.hasOwnProperty(val)) || 'countryAlphaCode';
    let mapper = {
      Email: 'Email',
      FirstName: 'FirstName',
      LastName: 'LastName',
      ConfirmEmail: 'email',
      PhoneNumber: 'mobileNum',
      Country: country,
    };
    if (!mapperFlag) {
      mapper = { ...mapper, Email: 'email', FirstName: 'firstName', LastName: 'lastName' };
    }
    if (isYaFreeActivity) {
      mapper = {
        firstName: 'firstName',
        lastName: 'lastName',
        email: 'email',
        phone: 'mobileNum',
        country: country,
        nationality: 'nationalityAlphaCode',
      };
    }
    if (countryField.includes(field.id) && resolvePath(response, 'countryAlphaCode', '')) {
      mapper = { ...mapper, [field.id]: 'countryAlphaCode' };
    }
    if (mapper[field.name]) {
      field.value = response[mapper[field.name]] || field.value;
      if (field.type === 'SelectWithText') {
        field.dropdownValue = response['countryCode'];
      }
    }
  };

  /**
   * On form submit success handler.
   * @param    {[Array]} data array of props objects
   *
   */
  const onFormSubmitSuccess = (data, params, response, gtmParams) => {
    const getMainObj = localStorage.getItem('mainObj');
    const mainObj = canUseDOM() && getMainObj === null ? '' : JSON.parse(getMainObj);
    const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();
    const { services, successMessage } = props.data;
    const sections = data.sections;
    let textsOfParams = {};
    //create download ticket payload in case we have download ticket label and API :: Start
    if (services.downloadTicket && successMessage && successMessage.downloadTicketLabel) {
      downloadTicketPayload.current = {
        service: services.downloadTicket,
        orderId: response.data.orderdetails.order.orderId,
        userId: params.Email,
        language: params.language,
        tenantId: tenantId,
        isOneTicket: true,
      };

      successMessage.downloadTicket = download;
    }
    //create download ticket payload in case we have download ticket label and API :: End

    setResponseStatus({ ...responseStatus, success: true, errors: null });

    const contentEle = document.querySelector('.contact-us-form-wrapper .c-text-with-cta .w--content');
    const titleEle = document.querySelector('.yas-island')
      ? contentEle.querySelector('.description').firstElementChild
      : contentEle.querySelector('.title');
    if (titleEle) {
      titleEle.setAttribute('tabindex', '0');
      titleEle.focus();
    }

    if (sections.length) {
      sections.map((section) => {
        section.fields.forEach((field) => {
          if (field.options) {
            field.options.forEach((option) => {
              if (option.value === gtmParams[field.id]) {
                textsOfParams[field.id] = option.text;
              } else if (Array.isArray(gtmParams[field.id]) && gtmParams[field.id].indexOf(option.value) > -1) {
                textsOfParams[field.id] = textsOfParams[field.id] || [];
                textsOfParams[field.id].push(option.text);
              }
            });
          }
        });

        return textsOfParams;
      });
    }

    const { goal } = props.data.successMessage;
    if (resolvePath(goal, 'goalId', '') && resolvePath(goal, 'services.setGoal.pageUrl', '')) {
      const goalAnalytics = new GoalAnalytics(goal, [], tContext, 'ContactUsForm Goal');
      goalAnalytics.sendGoal();
    }

    if (tenantId === UIConfig.ymcB2CTenant) {
      if (data.formType === 'getQuote') {
        getQuoteAnalytics(params, response.data, textsOfParams);
      } else if (data.formType === 'getHealthNFitnessRegistration') {
        HnFRegistrationAnalytics(params, response.data, textsOfParams);
      } else if (data.formType === 'getWrxPreRegistration') {
        preregistrationAnalytics(params, textsOfParams);
      } else if (data.formType === 'getContactUs') {
        contactUsAnalytics(data.formType, params);
      } else {
        contactUsAnalytics(data.formType);
      }
    } else if (isYaFreeActivity) {
      contactUsAnalytics(null, { AnalyticsEventAction: 'Activity Registration' });
    } else {
      contactUsAnalytics(null, params);
    }
  };

  /**
   * On form submit error handler.
   * @param    {object} response objects containing errors and form submit boolean value
   *
   */
  const onFormSubmitError = (response, captchaID) => {
    if (typeof response === 'object' && !response.isFormSubmitted) {
      setResponseStatus({ ...responseStatus, serverErrors: response.error });
    }
    if (captchaID) {
      window[captchaID].ReloadImage();
    }
    window.scrollTo(0, 0);
  };

  const getErrorData = () => {
    const { errorData } = props.data;
    if (errorData && Array.isArray(errorData) && errorData.length) {
      return filterErrorsData(errorData) || errorData;
    }
    return errorData;
  };

  /* Error Framework Call */
  const errorFramework = (response, key) => {
    let data = deepCloneObject(props.data);
    const errorMsgData = getErrorMap(
      key,
      props.data.services[props.data.formType] ? props.data.services[props.data.formType].errors : getErrorData(),
      false,
      response.error ? response.error : response.data ? response.data.error : undefined,
      responseStatus.serverErrors,
    );
    if (response && response.payLoad) {
      data.sections.forEach((section) => {
        section.fields.forEach((field) => {
          changeFieldValue(field, response.payLoad, true);
        });
      });
    }
    if (isYaFreeActivity && getUserAgent()) {
      window.JSbridge.nativeCallback({
        orderID: '',
        ref: UIConfig.paymentOption.free,
        status: false,
        error: {
          code: (errorMsgData && errorMsgData.errorcode) || '',
          text: (errorMsgData && errorMsgData.errordescription) || '',
        },
      });
    } else {
      setResponseStatus({ ...responseStatus, errors: errorMsgData, data });
    }
  };

  const formSubmitSuccessHandling = (paramObj) => {
    const { response, data, params, gtmParams, captchaID } = paramObj;

    // Adding this check to accomodate for the Parks Booking Form and YA Free Activity API calls
    if (
      typeof response.data === 'object' &&
      (response.data.isFormSubmitted ||
        response.data.success ||
        response.data.orderdetails ||
        (isYaFreeActivity && response.data.orderDetails))
    ) {
      Logging(response, response.config && (response.config.modulename || 'contact-us'), true, 'form submitted');
      if (isYaFreeActivity && getUserAgent()) {
        window.JSbridge.nativeCallback({
          orderID: response.data.orderDetails.orderId,
          ref: UIConfig.paymentOption.free,
          status: true,
          email: params.email,
        });
      } else {
        onFormSubmitSuccess(data, params, response, gtmParams);
        scrollToFormErrorOrSuccess('success-notification');
      }
    } else {
      onFormSubmitError(response.data, captchaID);
      errorFramework(response, 'contactUs');
    }
  };

  const submitContactUsForm = (errors, payLoad, captchaID, gtmPayLoad) => {
    const data = props.data;
    let params = { ...payLoad },
      gtmParams = { ...gtmPayLoad },
      getLang = null;

    if (canUseDOM()) {
      getLang = getCurrentLanguage();
    }
    if (isLoggedInUser() && props.data.variant === 'b2bContactUsLoggedIn' && userInfo.current) {
      params = { ...params, ...userInfo.current };
      params.email = params.emailaddress1;
      params.phone = params.telephone1;
    } else {
      params.session = userInfo.current;
    }
    params.dataSource = data.dataSource;
    params.language = getLang;
    if (data.extraParams) {
      for (const prop in data.extraParams) {
        if (data.extraParams.hasOwnProperty(prop)) params[prop] = data.extraParams[prop];
      }
    }

    if (isYaFreeActivity) {
      params.eventType = 'FreeActivity';
      params.productId = params.productId || decodeQueryString().productid;
      params.performanceId = performanceId.current || '';
      params.quantity = params.quantity || '1';
    }

    params.stringData = JSON.stringify(params);
    if (isLoggedInUser() && props.data.variant === 'B2C-bookDate') {
      params.yasId = getLoggedInUser().yasId;
    }
    if (data.formType === 'swadSummerCamp') {
      params['childAge'] = Number(params['childAge']);
      params['childGender'] = Number(params['childGender']);
      params['childTShirtSize'] = Number(params['childTShirtSize']);
      params['personOfDetermination'] = params['personOfDetermination'] === 'true' ? true : false;
      if (params?.childDOB?.includes('-')) {
        const convertDateFormat = (dateString) => {
          let parts = dateString.split('-');

          let formattedDate = parts[2] + '/' + parts[1] + '/' + parts[0];

          return formattedDate;
        };
        params['childDOB'] = convertDateFormat(params?.childDOB);
      }
    }
    if (isYMC) {
      params.passType = passType || '';
      params.productDescription = productDescription || '';
    }
    if (!Object.keys(errors).length && (data.action || data.formType)) {
      let header = {
        __RequestVerificationToken: document.getElementsByName('__RequestVerificationToken').length
          ? document.getElementsByName('__RequestVerificationToken')[0].value
          : '',
      };
      ApiWrapper.platformService({
        url: data.action
          ? data.action
          : data.formType && data.services[data.formType]
          ? data.services[data.formType].url
          : null,
        method: data.method ? data.method : 'POST',
        headers: header,
        data: params,
        moduleName: moduleName,
        preLoader: true,
        preLoaderTarget: UIConfig.loader.contactusDefaultPreLoaderTarget,
        timeout: 120000,
      })
        .then(function(response) {
          // remove storage temporary data if exists
          const formAction = data.action
              ? data.action
              : data.formType && data.services[data.formType]
              ? data.services[data.formType].url
              : null,
            formStorage = localStorage.getItem('form-data-object'),
            formJson = formStorage && JSON.parse(formStorage),
            storageFormData = formJson && formJson[formAction];
          if (storageFormData) {
            delete formJson[formAction];
            localStorage.setItem('form-data-object', JSON.stringify(formJson));
          }
          if (isYMC) {
            setMediaId(
              (response?.data?.success?.CRMId && JSON.parse(response?.data?.success?.CRMId)?.dot_barcodeid) || '',
            );
            setName(payLoad.FirstName);
          }
          const sendCommPrefs =
            document.getElementById('ReceiveUpdatesEmail') && document.getElementById('ReceiveUpdatesEmail').checked;
          const sendCommPrefsYMC =
            document.getElementById('InterestedInUpdatesAndOffers') &&
            document.getElementById('InterestedInUpdatesAndOffers').checked;
          if (sendCommPrefsYMC || sendCommPrefs) {
            const apiData = data.services.NewsletterSubscriptionAPI;
            const { yasId = '', tenantID } = getLoggedInUser();
            const { email, phone, sms, whatsApp, website, mobile } = UIConfig.CMPconsent;

            const postData = {
              emailId: params.Email,
              myPassId: yasId,
              channelName: tenantID,
              language: getLanguageWithoutLocal(),
              consentPurpose: [
                { [email]: true },
                { [phone]: true },
                { [sms]: true },
                { [whatsApp]: true },
                { [website]: true },
                { [mobile]: true },
              ],
              source: '',
            };
            const postUrl = isLoggedInUser()
              ? apiData.url.replace(':email', params.Email) + '?yasId=' + getLoggedInUser().yasId
              : apiData.url.replace(':email', params.Email);

            ApiWrapper.apiGateway({
              url: postUrl,
              method: data.method ? data.method : 'POST',
              data: postData,
              preLoader: true,
              preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
              noAuthHeader: true,
            })
              .then(() => {
                if ((typeof response.data === 'object' && response.status === 200) || response.status === 201) {
                  formSubmitSuccessHandling({ response, data, params, gtmParams, captchaID });
                }
              })
              .catch(() => {
                if ((typeof response.data === 'object' && response.status === 200) || response.status === 201) {
                  formSubmitSuccessHandling({ response, data, params, gtmParams, captchaID });
                }
              });
          } else {
            formSubmitSuccessHandling({ response, data, params, gtmParams, captchaID });
          }
        })
        .catch((response) => {
          if (captchaID) {
            window[captchaID].ReloadImage();
          }
          errorFramework(response, 'contactUs');
        });
    }
  };

  const validateCaptcha = (errors, payLoad, captchaID, gtmPayLoad) => {
    ApiWrapper.platformService({
      url: window[captchaID].ValidationUrl + '&i=' + document.getElementById('Captcha').value,
      method: 'GET',
      responseType: '',
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    })
      .then(function(response) {
        if (response.data) {
          submitContactUsForm(errors, payLoad, captchaID, gtmPayLoad);
        } else {
          response = {
            ...response,
            payLoad,
            error: { code: UIConfig.errorCodes.invalidCaptcha },
          };
          onFormSubmitError(response.data, captchaID);
          errorFramework(response, 'contactUs');
        }
      })
      .catch(() => {
        if (captchaID) {
          window[captchaID].ReloadImage();
        }
      });
  };

  const redirectToNewPage = (payLoad, path) => {
    const { LastName, BookingReferenceId } = payLoad;
    let redirectLink = path.replace('{lastName}', LastName).replace('{bookingId}', BookingReferenceId);
    window.location = redirectLink;
  };

  const submitContactUs = (errors, payLoad, captchaID) => {
    const gtmPayLoad = deepCloneObject(payLoad);
    const { redirecturl, variant } = props.data;
    if (redirecturl && variant === 'B2C-bookDate') {
      redirectToNewPage(payLoad, redirecturl);
    } else {
      if (props.data.formType && payLoad) {
        for (let key in payLoad) {
          if (typeof payLoad[key] === 'string' && payLoad[key].indexOf(UIConfig.form.isEmptyValue) !== -1) {
            payLoad[key] = '';
          }
        }
      }
      if (props.data.formType && captchaID) {
        validateCaptcha(errors, payLoad, captchaID, gtmPayLoad);
      } else {
        submitContactUsForm(errors, payLoad, captchaID, gtmPayLoad);
      }
    }
  };

  useEffect(() => {
    const enableLoginCheck =
      props.data.sections.filter((section) => section.sectionClass === 'CheckOut').length > 0 && !isLoggedInUser();
    setEnableLogin(enableLoginCheck);
    setDisableForm(props.data.variant === 'B2C-bookDate' && enableLoginCheck);
    checkInlineSignup() && localStorage.removeItem(UIConfig.localStoreKeys.isInlineSignUpForm); //remove isinlinesignup from localstorage to avoid duplicate email service call
    if (isLoginValid()) {
      const user = getLoggedInUser();

      if (props.data.variant === 'B2C-contactUs' || props.data.variant === 'B2C-bookDate' || isYaFreeActivity) {
        getB2CDetails(user, props);
      } else {
        //Get user details from SelfContact API
        PartnerService.getContactUsDetails(
          moduleName,
          props.data.services.getSelfContact.url.replace('{0}', user.partnerId).replace('{1}', user.agentId),
        )
          .then((response) => {
            userInfo.current = Object.assign({}, userInfo, response.data[PartnerApi.selfContact.getSuccessKey]);
          })
          .catch((error) => {
            errorFramework(error, 'getSelfContact');
          });

        //Get company information from GetPartner API
        PartnerService.getContactUsDetails(
          moduleName,
          props.data.services.getPartner.url.replace('{0}', user.partnerId),
        )
          .then((response) => {
            userInfo.current.companyName =
              response.data[PartnerApi.getPartner.getSuccessKey][UIConfig.partnersApi.companyNameKey];
          })
          .catch((error) => {
            errorFramework(error, 'getPartner');
          });
      }
    }

    if (isYaFreeActivity && !isExperienceEditorActive()) {
      const { url, errors: cmsErrors } = props.data.services.getPerformance;
      const { startdate, enddate, eventid } = decodeQueryString();
      const requestData = {
        productId: null,
        eventId: [eventid],
        fromTime: '',
        toTime: '',
      };
      PerformanceService.searchPerformance(url, startdate, enddate, requestData, true, '.contact-us-form-wrapper')
        .then((res) => {
          const { performance } = res.data.performancelist;
          if (performance && performance[0]) {
            performanceId.current = performance[0].performanceId || '';
          } else {
            const text = props.data.sections[0].fields.filter((field) => field.name === 'emptyPerformance')[0].value;
            setResponseStatus({
              ...responseStatus,
              errors: {
                performanceId: {
                  text,
                },
              },
            });
          }
        })
        .catch((err) => {
          const errorMsgData = getErrorMap('performanceId', cmsErrors, false, err.error, responseStatus.serverErrors);
          setResponseStatus({ ...responseStatus, errors: errorMsgData });
        });
    }

    if (enableLoginCheck) {
      let links = document.querySelectorAll('.contact-us-form .checkbox-label span a');
      links.forEach((item) => {
        item.setAttribute('tabindex', '-1');
      });
    }
    if (
      isLoggedInUser() &&
      localStorage.getItem(UIConfig.localStoreKeys.emailInvoiceTemplateId) &&
      props.data.variant === 'B2C-bookDate'
    ) {
      localStorage.removeItem(UIConfig.localStoreKeys.emailInvoiceTemplateId);
    }

    bindModalToAnchorLink();
    responseStatus.errors && scrollToFormErrorOrSuccess('server-error');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * download is a function which enables to download the ticket hitting the ticket service API
   * @param    {event}    object onClick event
   * @return    {file}    object pdf file i.e ticket
   */
  const download = () => {
    OrderService.downloadOrderTickets(downloadTicketPayload.current).catch((response) => {
      setResponseStatus({ ...responseStatus, errors: response.error });
    });
  };

  const redirectToHomePage = () => {
    const { redirecturl, variant } = props.data;
    return !isLoggedInUser() && redirecturl && variant === 'B2C-bookDate' && !isExperienceEditorActive();
  };

  try {
    let atwData = {};
    let ctaCheckout =
      enableLogin && props.data.sections.filter((section) => section.sectionClass === 'CheckOut')[0].fields;
    let toolTip =
      enableLogin && ctaCheckout && ctaCheckout[0] && ctaCheckout[0].showToolTip ? ctaCheckout[0].tooltip : '';
    let variant = props.data.variant;
    let loginData = {
      data: {
        ctaCheckout,
        toolTip,
        variant,
      },
    };
    if (isYMC) {
      atwData = {
        bookProUrl: props.data?.services?.getbookprourl || null,
        atwFormType: props.data?.formType,
        enableAddToWallet: props.data?.enableAddToWallet || false,
        addToWallet: props.data?.addToWallet || {},
        mediaId: mediaId || '',
        reservationCode: mediaId || '',
        passType,
        productDescription,
        name,
      };
    }
    const isUserLoggedIn = isLoggedInUser();
    if (canUseDOM() && redirectToHomePage()) {
      const tenantName = GLOBAL_VARS.pageDetails.tenantName;
      const contactUsWrapper = document.querySelector('.contact-us-form-wrapper');
      if (contactUsWrapper) {
        contactUsWrapper.style.display = 'none';
      }
      const lang = document.getElementsByTagName('html')[0].getAttribute('lang') || 'en';
      window.location.href.includes(`/${tenantName}`)
        ? (window.location.href = `/${lang}/${tenantName}`)
        : (window.location.href = `/${lang}`);
      return <></>;
    } else {
      return (
        <div className={`contact-us-form-wrapper ${variant} ${!isUserLoggedIn ? 'logged-out' : ''}`}>
          <div className="container"></div>
          {responseStatus.errors && <ErrorSummary data={responseStatus.errors} />}
          {responseStatus.success ? (
            <div className="success-notification">
              <TextWithCTA data={props.data.successMessage} atwData={atwData} />
            </div>
          ) : (
            <Form
              data={responseStatus.data}
              submitCallback={submitContactUs}
              isControlled={true}
              submitSuccess={true}
              preventUpdateField={true}
              retainDataOnError={retainFormData.current}
              formSubmitError={responseStatus.errors}
              loginData={loginData}
              isTabIndex={enableLogin ? '-1' : '0'}
              disableForm={!!disableForm}
              formType={UIConfig.zeroBounce.contactUs}
            />
          )}
        </div>
      );
    }
  } catch (err) {
    return logComponentRenderingError(err, 'ContactUsForm', props.data.variant);
  }
};

export default ContactUsForm;
