import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  isEmpty,
  canUseDOM,
  getFallbackLanguage,
  createMappedData,
  flipObjectKeyValues,
  getErrorMap,
} from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';
import PackageExperienceB2C from './add-ons/b2c-package-experience/b2c-package-experience-component';
import { getCategoryProds, updateSecProdArray } from '../hero-component/helpers';
import { changeCategoryToString, getDiscount } from '../../../common/helpersPJ';
import { secProds } from './add-ons/add-ons-utility';
import { getB2CProductDetails } from '../../../common/coveo-api';
import ProductStore from './purchase-journey-wrapper/product-store';
import { errorCallback } from './purchase-journey-wrapper/helper';
import AddOnsTicketComponent from './add-ons/add-ons-ticket-component';
import { UIConfig } from '../../../..';
import ApiWrapper from '../../../common/api-wrapper';

const InvokePurchaseJourney = (props) => {
  const {
    addToCartCallback,
    productWidget,
    enablePJPopup,
    pjData,
    isPjDataFetched,
    btnLabel,
    setPurchaseJrnyActive,
  } = props;

  const { packageCode } = productWidget.productDetails;
  const isPackageJrny = packageCode && packageCode !== '';

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const activePackageInfo = useRef(null);
  const activePackageDetails = useRef([]);
  const coveoDefaultParams = useRef({});
  const productId = useRef('');
  const responseObj = useRef(null);
  const responseObjAll = useRef(null);
  const products = useRef({});

  /**
   * Product data coveo call
   * @param {Object} err error object
   */
  const createProductStore = (tab, getActiveProduct) => {
    return new Promise((resolve, reject) => {
      if (!products.current[tab]) {
        const params = [];
        getActiveProduct
          ? params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.productId, value: productId.current },
            )
          : tab &&
            params.push(
              { key: pjData.current.coveoMappingList.disabledProduct, value: '0' },
              { key: pjData.current.coveoMappingList.tab, value: tab },
            );

        getB2CProductDetails({
          ...coveoDefaultParams.current,
          queryParams: params,
        })
          .then((res) => {
            res.results = changeCategoryToString(res.results, coveoDefaultParams);
            products.current[tab] = new ProductStore(res.results);
            resolve(products.current[tab]);
          })
          .catch((res) => {
            errorCallback('getCoveoProducts', res.error);
            const errObj = getErrorMap(
              'getCoveoProducts',
              pjData.current.services.getCoveoProducts.errors,
              false,
              res.error,
            );
            reject(errObj);
          });
      } else {
        resolve(products.current[tab]);
      }
    });
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const invokePackagedJourney = (packageCode) => {
    const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const preLoaderTarget = UIConfig.loader.defaultPreLoaderTarget;
    const isPreLoader = true;
    const perPageResults = UIConfig.b2c.purchaseJourney.coveoResultsPerPage;
    const { packageKeyMap, coveoMappingList } = pjData.current;
    const fieldsToInclude = Object.values(packageKeyMap);
    const lang = getFallbackLanguage();
    const searchTemplate = 'Package';
    const productResults = {};

    if (!packageCode) {
      return;
    }
    ApiWrapper.coveoService({
      preLoaderTarget,
      url: 'https://platform.cloud.coveo.com/rest/search/',
      method: 'POST',
      preLoader: isPreLoader,
      data: {
        numberOfResults: perPageResults,
        fieldsToInclude: fieldsToInclude,
        aq: `(@${coveoMappingList.language}==${lang})(@${coveoMappingList.templatename}==${searchTemplate})(@${packageKeyMap.packageCode}==${packageCode})`,
        searchHub: mainObj.additionalProperty.searchHub,
      },
    }).then((coveoData) => {
      productResults.results = [];
      productResults.totalCount = coveoData.data.totalCount;

      const pkgInfo = {
        pkgCode: packageCode,
        pkgTitle: coveoData.data.results[0].title || '',
      };
      activePackageInfo.current = pkgInfo;

      coveoData.data.results.forEach((v) => {
        productResults.results.push(v.raw);
      });

      const packageDetails = Object.entries(packageKeyMap).reduce((acc, [key, value]) => {
        if (productResults.results.length && productResults.results[0].hasOwnProperty(value)) {
          return { ...acc, [key]: productResults.results[0][value] };
        }
        return acc;
      }, {});

      activePackageDetails.current = packageDetails;

      setIsOverlayOpen(true);
    });
  };

  const renderPackageJourney = () => {
    const { variant } = productWidget;

    coveoDefaultParams.current = enablePJPopup
      ? {
          perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.current.coveoMappingList,
          serviceUrl: pjData.current.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.current.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};

    return window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      customClass: 'addOns-Overlay package-journey-overlay calendar-overlay-genral-admission',
      dataToAppend: (
        <PackageExperienceB2C
          data={productWidget}
          packageDetails={{ ...activePackageInfo.current, ...activePackageDetails.current }}
          createProductStore={createProductStore}
          addToCartCallback={addToCartCallback}
          getDiscountedProducts={getDiscountedProducts}
          getCategoryProds={getCategoryProds}
          changeCategoryToString={changeCategoryToString}
          errorCallback={errorCallback}
          getErrorMap={getErrorMap}
          closeOverlay={closeOverlay}
          pjData={pjData.current}
          services={pjData.current.services}
          enablePJPopup={enablePJPopup}
          coveoSettings={{ serviceUrl: 'https://platform.cloud.coveo.com/rest/search/' }}
          coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
          overlayErrorCallback={overlayErrorCallback}
          variant={
            (variant === 'v-product-list-filter' || variant === 'v-f1-multi-filter') && enablePJPopup
              ? 'v-overlay-selected'
              : ''
          }
        />
      ),
    });
  };

  const getDiscountedProducts = (products, days, discountMap, disableDiscount) => {
    discountMap = discountMap || pjData.current.discountMap;
    const baseProducts = [];
    const selectedProducts = [];
    const baseProductPrices = {};
    const baseDis = getDiscount(0, discountMap);
    const selectedDis = getDiscount(days, discountMap);

    products.forEach((prod) => {
      const prodCode = prod.pricing;
      prod.discount = {};
      prod.discount.actualPerc = 0;
      prod.discount.computed = 0;

      if (prodCode === baseDis.code) {
        prod.unitPrice = prod.gross || 0;
        baseProducts.push(prod);
        baseProductPrices[prod.classType] = prod.gross || 0;
      } else if (prodCode === selectedDis.code) {
        prod.discount.actualPerc = selectedDis.discountPer;
        selectedProducts.push(prod);
      }
    });

    if (baseDis.code === selectedDis.code || disableDiscount) {
      return baseProducts;
    } else {
      return selectedProducts.map((prod) => {
        prod.unitPrice = baseProductPrices[prod.classType];
        return prod;
      });
    }
  };

  const renderPjPopup = (id) => {
    productId.current = id;

    if (!pjData.current) {
      return;
    }

    coveoDefaultParams.current = enablePJPopup
      ? {
          perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
          coveoKeyMap: pjData.current.coveoMappingList,
          serviceUrl: pjData.current.services.getCoveoProducts.url,
          fieldsToInclude: Object.values(pjData.current.coveoMappingList),
          lang: getFallbackLanguage(),
        }
      : {};

    enablePJPopup &&
      createProductStore(productId.current, true).then((response) => {
        responseObj.current = response;
        const activeProduct = response && createMappedData(response.products, pjData.current.coveoMappingList)[0];
        const currentTabData =
          pjData.current.tabs &&
          pjData.current.tabs.filter(
            (tab) =>
              tab.coveoValue.toLowerCase() === (activeProduct && activeProduct.tab && activeProduct.tab.toLowerCase()),
          )[0];

        createProductStore(currentTabData.coveoValue, false).then((response) => {
          responseObjAll.current = response;
          setIsOverlayOpen(true);
        });
      });
  };

  const renderPJPopupData = () => {
    const activeProduct =
      responseObj.current && createMappedData(responseObj.current.products, pjData.current.coveoMappingList)[0];

    const currentTabData =
      pjData.current.tabs &&
      pjData.current.tabs.filter(
        (tab) => tab.coveoValue.toLowerCase() === (activeProduct.tab && activeProduct.tab.toLowerCase()),
      )[0];

    let counterData = null;
    let dateSelector = null;
    let timeSlotSelector = null;
    let catgry = null;
    let categry = null;
    let productOverlaySelector = null;

    const category = { [currentTabData.coveoKey]: currentTabData.coveoValue };
    const resProducts = responseObjAll.current.getFilteredProductsFromKeyVal(category);
    const allProducts = resProducts.length && createMappedData(resProducts, pjData.current.coveoMappingList);
    const categoryProducts = allProducts.filter(
      (prod) => prod.category && prod.category[0] === activeProduct.category[0],
    );

    for (let tktType in currentTabData.controls) {
      let currentBaseProdOptions =
        currentTabData.controls[tktType].options &&
        currentTabData.controls[tktType].options.find((opt) => opt.coveoValue === activeProduct.category.toString());

      if (currentBaseProdOptions) {
        catgry = currentBaseProdOptions;
        counterData = currentBaseProdOptions.visitorSelector;
        dateSelector = currentBaseProdOptions.dateSelector;
        timeSlotSelector = currentBaseProdOptions.timeSlotSelector;
        productOverlaySelector = currentBaseProdOptions.productOverlaySelector;
      }
      categry = currentBaseProdOptions;
    }

    if (!products.current.hasOwnProperty('quantity')) {
      products.current.quantity =
        (counterData &&
          counterData.visitorSelector &&
          counterData.visitorSelector.options &&
          counterData.visitorSelector.options[0] &&
          counterData.visitorSelector.options[0].defaultQuantity) ||
        0;
    }

    const additionalProds = getCategoryProds(activeProduct.category, allProducts);

    // Reset Secondary products
    Object.keys(secProds).forEach((key) => delete secProds[key]);

    return categoryProducts.map((product, index) => {
      const multiTicketSelector = categry.multiTicketSelector,
        keyMap = multiTicketSelector ? multiTicketSelector.stringMapping : {},
        category = product.category;

      let isCategoryMappingExist = false;
      if (keyMap) {
        for (let item in keyMap) {
          if (item === category[0]) {
            isCategoryMappingExist = true;
            break;
          }
        }
      }

      if (
        keyMap &&
        (category.indexOf(keyMap.drivingExperience) !== -1 ||
          category.indexOf(keyMap.passengerExperience) !== -1 ||
          isCategoryMappingExist)
      ) {
        updateSecProdArray(product, category[0], keyMap);
      }

      if (!product.hasOwnProperty('quantity')) {
        product.quantity = categry.visitorSelector.options[0].defaultQuantity;
      }

      return (
        <div>
          {allProducts && additionalProds && (!multiTicketSelector || product.experienceCatgory === keyMap.primary) && (
            <AddOnsTicketComponent
              data={currentTabData}
              cartData={pjData.current.miniCart}
              businessErrors={pjData.current.miniCart.businessErrors}
              ticket={product}
              dateSelector={dateSelector}
              visitorCounter={counterData}
              createMappedData={createMappedData}
              addToCartCallback={addToCartCallback}
              services={pjData.current.services}
              getDiscountedProducts={getDiscountedProducts}
              timeSlotSelector={timeSlotSelector}
              category={catgry}
              handleOnClick={() => {}}
              additionalProds={additionalProds}
              enablePJPopup={enablePJPopup}
              searchProductList={allProducts}
              coveoMapList={flipObjectKeyValues(pjData.current.coveoMappingList)}
              productOverlaySelector={productOverlaySelector}
              overlayErrorCallback={overlayErrorCallback}
              variant={enablePJPopup ? 'v-overlay-selected' : ''}
            />
          )}
        </div>
      );
    });
  };

  const overlayErrorCallback = (error, errorData) => {
    let errorObj = {};
    if (!isEmpty(error) && error.errorcode) {
      errorObj.errorcode = error.errorcode;
      if (errorData && errorData[error.errorcode]) {
        errorObj.text = errorData[error.errorcode];
      } else if (error && error.errordescription) {
        errorObj.text = error.errordescription;
      }
      setIsOverlayOpen(false);
    }
  };

  const initiatePJPopup = (prdId) => {
    const takeOverBanner = document.querySelector('.takeover-banner-overlay.is-open');
    if (takeOverBanner) {
      takeOverBanner.classList.remove('is-open');
      setPurchaseJrnyActive();
    }
    if (isPackageJrny) {
      invokePackagedJourney(prdId);
    } else {
      renderPjPopup(prdId);
    }
  };

  try {
    const { productId, packageCode } = productWidget?.productDetails;
    const productWidgetId = isPackageJrny ? packageCode : productId;

    return (
      productWidgetId &&
      isPjDataFetched && (
        <>
          <div className="pj-buy-now-button">
            <div className={`btn-primary-dark`}>
              <button
                onClick={() => {
                  initiatePJPopup(productWidgetId);
                }}
                className="btn-primary"
              >
                {btnLabel}
              </button>
            </div>
          </div>
          {isOverlayOpen && (isPackageJrny ? renderPackageJourney() : renderPJPopupData())}
        </>
      )
    );
  } catch (err) {
    return logComponentRenderingError(err, 'InvokePurchaseJourney');
  }
};

InvokePurchaseJourney.PropsTypes = {
  data: PropTypes.shape({
    productWidget: PropTypes.object,
    timerWidget: PropTypes.object,
    variant: PropTypes.string,
  }),
};

export default InvokePurchaseJourney;
