/*
 * order-manager-components.js
 * This file contains code for Order grid Filter component.
 * This container component contains all the filters to be used in Order Manager Component.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import DynamicContent from '../../presentation/base/dynamic-content/dynamic-content-component';
import Filters from '../../presentation/base/filter/Filters';
import InputFilter from '../../presentation/base/filter/InputFilter';
import SingleSelectRadioFilter from '../../presentation/base/filter/SingleSelectRadioFilter';
import SingleSelectCheckboxFilter from '../../presentation/base/filter/SingleSelectCheckboxFilter';
import DateFilter from '../../presentation/base/filter/DateFilter';
import { detectMobile, filterList } from '../../../common/utility';
import SvgSprite from '../../presentation/base/svg-sprite/svg-sprite-component';
import Tooltip from '../../presentation/tooltip/tooltip-component';

import './order-grid-filter-component.scss';

/**
 * OrderGridFilter Class ( which extends the React.Component) is a wrapper which contains
 * various filters that are required in Order Management
 */
export default class OrderGridFilter extends React.Component {
  /**
   * Constructor of the class is defined which handles binding of the events to the elements, the
   * props to the super class and defining the state of the component.
   * @state   {filters} defines current set of filters applied on the component
   * @state   {mobileView} defines if the current viewPort is Mobile or not
   */
  constructor(props) {
    super(props);
    this.data = this.props.data;
    this.dateFormat = 'MM-DD-YYYY';
    this.state = {
      filters: {},
      mobileView: false,
      refClass: detectMobile() ? 'hide' : 'show',
      dateClass: detectMobile() ? 'hide' : 'show',
    };
  }
  /**
   * onFilterChange function updates the state of filters and notifies Parent Component of this change.
   * @param    {[filter, dontNotifyMainComponent]} accepts new values of filter and flag denoting
   * whether Parent component should be notified
   * @return   {[Void]} function does not return anything.
   */

  onFilterChange = (filter, dontNotifyMainComponent) => {
    this.setState({
      filters: filter,
    });
    this.props.notifyFilters(filter, dontNotifyMainComponent);
  };

  showFilter = (e) => {
    e.preventDefault();
    if (e.target.attributes['data-filter'].value === 'datefilter') {
      this.setState({ dateClass: 'show', refClass: 'hide' });
    } else {
      this.setState({ dateClass: 'hide', refClass: 'show' });
    }
  };

  /**
   * componentDidMount LifeCycle Method to subscribe to windowResize event to check
   * if current view is Mobile or not.
   */

  componentDidMount() {
    this.setState({ mobileView: detectMobile() });
    window.PubSub.subscribe('windowResize', this.updateSingleSelectFilter);
  }
  /**
   * updateSingleSelectFilter   function to update Single Select Filter View on Window Resize event.
   * It calls setState function to check if current view is Mobile or not
   */

  updateSingleSelectFilter = () => {
    this.setState({
      mobileView: detectMobile(),
    });
  };
  /**
   * notifyFilters function sends the updated state of filter to Parent Component -> order-manager-component.js
   */
  notifyFilters = (e) => {
    if (this.state.mobileView) {
      this.setState({ dateClass: 'hide', refClass: 'hide' });
    }
    this.props.notifyFilters(this.state.filters);
  };

  render() {
    const { data } = this.props;

    return (
      <Filters onChange={this.onFilterChange}>
        {data.title ? (
          <DynamicContent
            tagName="h5"
            attrs={{
              className: 'c-order-data-grid-filters--title',
            }}
            innerHtml={data.title}
          />
        ) : (
          ''
        )}
        {data.bodyCopy && (
          <DynamicContent
            tagName="p"
            attrs={{
              className: 'c-order-data-grid-filters--shorttitle body-copy-4',
            }}
            innerHtml={data.bodyCopy}
          />
        )}

        {this.state.mobileView ? (
          <div className="order-select">
            <SingleSelectCheckboxFilter
              filterName="status"
              className="order-type-filter body-copy-4"
              filterList={data.orderType}
            />
            <SvgSprite id="icn-arrow-down" />
          </div>
        ) : (
          <SingleSelectRadioFilter
            filterName="status"
            className="order-type-filter body-copy-4"
            filterList={data.orderType}
          />
        )}
        <div className="bottomFilters">
          <div>
            <a
              className={this.state.dateClass === 'hide' ? 'show datefilter' : 'hide datefilter'}
              data-filter="datefilter"
              onClick={this.showFilter.bind(this)}
            >
              {data.searchDetail.searchDateTitleMobile}
            </a>
            <SvgSprite
              styleClass={this.state.dateClass === 'hide' ? 'show' : 'hide'}
              id="icn-calendar"
              viewBox="0 0 21 21"
            />
            <a
              className={this.state.refClass === 'hide' ? 'show reffilter' : 'hide reffilter'}
              data-filter="reffilter"
              onClick={this.showFilter.bind(this)}
            >
              {data.searchDetail.searchOrderTitleMobile}
            </a>
            <SvgSprite styleClass={this.state.refClass === 'hide' ? 'show' : 'hide'} id="icn-search-white" />
          </div>
        </div>
        <div className={'date-filter ' + this.state.dateClass}>
          <DateFilter filterName="orderDate" filterList={data.searchDetail} className="calendar" />
          <div className="btn btn-primary">
            <DynamicContent
              tagName="button"
              attrs={{
                onClick: this.notifyFilters.bind(this),
                datafilter: 'datefilter',
              }}
              innerHtml={data.searchDetail.searchDateCTA.label}
            />
          </div>
        </div>
        <div className={'order-info-filter ' + this.state.refClass}>
          <div className="order-info-filter-body">
            <DynamicContent
              tagName="p"
              attrs={{
                className: 'filter--label body-copy-4',
              }}
              innerHtml={data.searchDetail.searchOrderTitle}
            />
            {data.searchDetail.searchOrderIconBodycopy && (
              <Tooltip
                wrapperClass="search-tooltip"
                onHover={true}
                buttonInnerHtml={
                  <div>
                    <SvgSprite id="icn-info" />
                  </div>
                }
              >
                <div className="search-tooltip-body">
                  <DynamicContent
                    tagName="p"
                    innerHtml={data.searchDetail.searchOrderIconBodycopy}
                    attrs={{ className: 'body-copy-4' }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
          {filterList(data.searchDetail.searchFilter, 'agentRef') ? (
            <InputFilter
              className="agent-reference"
              data={filterList(data.searchDetail.searchFilter, 'agentRef')}
              filterName="AgentRefNo"
            />
          ) : (
            ''
          )}
          {filterList(data.searchDetail.searchFilter, 'agentName') ? (
            <InputFilter
              className="agent-reference"
              data={filterList(data.searchDetail.searchFilter, 'agentName')}
              filterName="AgentName"
            />
          ) : (
            ''
          )}
          {filterList(data.searchDetail.searchFilter, 'reservationCode') ? (
            <InputFilter
              className="agent-reference"
              data={filterList(data.searchDetail.searchFilter, 'reservationCode')}
              filterName="reservationCode"
            />
          ) : (
            ''
          )}
          <InputFilter
            className="booking-id"
            data={filterList(data.searchDetail.searchFilter, 'bookingNum')}
            filterName="BookingId"
          />
          <div className="btn btn-primary">
            <DynamicContent
              tagName="button"
              attrs={{
                onClick: this.notifyFilters.bind(this),
                datafilter: 'reffilter',
              }}
              innerHtml={data.searchDetail.searchOrderCTA.label}
            />
          </div>
        </div>
      </Filters>
    );
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */

OrderGridFilter.propTypes = {
  notifyFilters: PropTypes.func,
  data: PropTypes.object,
};
