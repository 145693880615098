/**
 * facet-filter-component.js
 * This file contains code for facet-filter component
 * @author      SapientRazorfish
 * @license     Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { getClosestByClass, isTouchDevice } from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';
import { DynamicContent } from '../base';

/**
 * onChange   function works on selection of facet filter
 *            calls a callback function with selected filter object.
 * @param    {e} function accepts event object
 * @return   {[Void]} function does not return anything.
 */
const onChange = (e, data) => {
  const { facetCallBack, filterVariant, name, resetCallBack } = data;

  const element = getClosestByClass(e.target, 'facet-list');
  const { value, coveovalue, filter, operator } = element.dataset;
  const obj = {};
  const expression = {};

  if ((filterVariant === 'v-product-list-filter' || filterVariant === 'v-f1-multi-filter') && value === 'All') {
    resetCallBack(filterVariant);
    return;
  }
  if (filterVariant === 'v-product-list-filter' || filterVariant === 'v-f1-multi-filter') {
    expression[name] = `@${filter}${operator}${value}`;
  } else {
    expression[name] = `@${filter}${operator}${coveovalue}`;
  }
  obj[name] = value;

  facetCallBack(obj, name, expression);
};

/**
 * handleKeyDown  function works on keyboard events of facet filter
 *                calls a callback function with selected filter object.
 * @param    {e} function accepts event object
 * @return   {[Void]} function does not return anything.
 */
const handleKeyDown = (e, data) => {
  const keyCode = e.keyCode;
  if (keyCode === 13) {
    onChange(e, data);
  }
};

const FacetFilter = (props) => {
  const { data, facetCallBack, filterVariant, name, resetCallBack, selected } = props;

  try {
    return (
      <div className="c-facet-filter component">
        <ul
          onClick={(e) => onChange(e, { facetCallBack, filterVariant, name, resetCallBack })}
          className="facets"
          onKeyDown={(e) => handleKeyDown(e, { facetCallBack, filterVariant, name, resetCallBack })}
        >
          {data &&
            data.map((item, index) => {
              return (
                <li
                  key={index}
                  data-index={index}
                  data-value={item.name}
                  data-coveovalue={item.toValue}
                  data-operator={item.operator}
                  data-filter={item.fieldToConfirm}
                  className={selected.toLowerCase() === item.name.toLowerCase() ? 'facet-list active' : 'facet-list'}
                  tabIndex="0"
                >
                  <input
                    type="radio"
                    id={item.name}
                    checked={selected.toLowerCase() === item.name.toLowerCase()}
                    name={name}
                    value={item.name}
                    onChange={(e) => onChange(e, { facetCallBack, filterVariant, name, resetCallBack })}
                    onClick={() =>
                      (filterVariant === 'v-product-list-filter' || filterVariant === 'v-f1-multi-filter') &&
                      isTouchDevice &&
                      window.PubSub.publish('resetTileState')
                    }
                  />
                  <DynamicContent
                    tagName="label"
                    attrs={{ htmlFor: item.name, className: 'heading-5' }}
                    innerHtml={item.label}
                  />
                  {filterVariant === 'v-f1-multi-filter' && item.sublabel && (
                    <Text
                      tag="div"
                      className="tab-sub-label"
                      field={{ value: item.sublabel, editable: item.sublabel }}
                    />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'FacetFilter');
  }
};

export default FacetFilter;

FacetFilter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fieldToConfirm: PropTypes.string,
      label: PropTypes.string.isRequired,
      name: PropTypes.string,
      operator: PropTypes.string,
      sublabel: PropTypes.string,
      toValue: PropTypes.string,
    }),
  ),
  facetCallBack: PropTypes.func,
  filterVariant: PropTypes.string,
  name: PropTypes.string,
  resetCallBack: PropTypes.func,
  selected: PropTypes.string,
};
