import configureDynamicStore from './createStore';
import { createMainReducer } from '../../root-reducer';

function configureStore(data) {
  const { initialState, mainReducer } = createMainReducer(data);
  const store = configureDynamicStore({
    initialState: initialState,
    reducer: mainReducer,
  });

  return store;
}

export default configureStore;
