/*
 * order-manager-components.js
 * This file contains code for Order grid component.
 * This container component contains Filters, Data Grid and Pagination.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import OrderGridFilter from '../order-grid-filter/order-grid-filter-component';
import OrderContainer from '../../presentation/order-container/order-container-component';
import {
  scrollToFormErrorOrSuccess,
  canUseDOM,
  momentTimezone,
  detectViewPort,
  getErrorMap,
  getLoggedInUser,
  resolvePath,
  currentLocale,
  convertJsDateToMomentObj,
} from '../../../common/utility';
import Pagination from '../../presentation/pagination/pagination-component';
import OrderTable from '../../presentation/order-table/order-table-component';
import { DynamicContent, SvgSprite } from '../../presentation/base';
import { SettlementData, ServiceConfig } from '../../../common/services';

import './order-manager-component.scss';

/**
 * OrderManager Class ( which extends the React.Component) creates API driven Order List, which gets
 * updated on the selection of Filters, Pagination and sorting
 */
export default class OrderManager extends React.Component {
  /**
   * Constructor of the class is defined which handles binding of the events to the elements, the
   * props to the super class and defining the state of the component.
   * @localVar  {customPaginationSettings} defines pagination settings that need to overridden from
   * default paginationSettings
   * @prop   {data} defines data accessed from passed data
   * @prop   {paginationSettings} defines settings for Pagination component
   * @state   {filters} defines current set of filters applied on the component
   * @state   {pageCount} defines total Count of records recieved from API
   * @state   {pageNo} defines current Page No. in pagination component
   */

  constructor(props) {
    super(props);
    this.data = this.props.data;
    this.payload = {};
    this.settlementLabels = this.data.filters.searchDetail.searchFilter;
    this.selectedOrderCount = 0;
    this.reconsilePayloadData = [];
    this.prevObj = {};
    this.maxSettlementRange = 50;
    this.paginationStartValue = 0;
    this.state = {
      filters: {},
      pageCount: '',
      pageNo: this.data.pagination.pageNo || 1,

      SettlementList: [],
      orderData: [],
      forSettlement: {},
      allCheck: false,
      orderDetail: {},
      totalPrice: '0',
      filterData: '',
      errorData: '',
      isDatePickerOpen: false,
      calendarLabelError: false,
      selectRange: true,
      format: 'YYYY/MM/DD', //Note as discussed with CMS Team we are maintaining date format in the JS file
      pageNumber: 1,
      paginationEndValue: null,
      resultPerPage: parseInt(this.props.data.pagination.pageSize, 10),
      startDate: '',
      endDate: '',
      selectedEndDate: moment(),
      firstLoad: true,
    };
    this.orderCallCompleted = false;
  }
  /**
   * notifyFilters function updates the value of filter in component's state
   * @param    {[newFilters, dontNotifyMainComponent]} function accepts the updated value of Filters and
   * the flag to check if Parent component should be upated or not .
   * @return   {[Void]} function does not return anything.
   */

  notifyFilters(newFilters, dontNotifyMainComponent) {
    if (!dontNotifyMainComponent) {
      this.setState(
        {
          filters: newFilters,
          pageNo: 1,
        },
        () => {
          if (canUseDOM()) {
            scrollToFormErrorOrSuccess('c-order-data-grid');
          }
        },
      );
    }
  }
  /**
   * updatePageNo function updates the value of pageNo in component's state
   * @param    {[data]} function accepts data from Pagination component' clicked element .
   * @return   {[Void]} function does not return anything.
   */
  updatePageNo = (data) => {
    this.setState({ pageNo: data.selected + 1 }, function() {
      scrollToFormErrorOrSuccess('c-order-table');
    });
  };
  /**
   * updatePageCount function updates the value of PageCount in component's state
   * @param    {[data]} accepts the updated value of Page Count in State
   * @return   {[Void]} function does not return anything.
   */
  updatePageCount = (data) => {
    this.setState({ pageCount: data });
  };

  /**
   * handleChange function swaps the value of endDate and startDate to ensure endDate is greater
   * than startDate and update the state of dates in component's state
   * @param    {endDate, startDate} function accepts the value of Start Date and End Date
   * @return   {[Void]} function does not return anything.
   */
  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    let checkEndDate = startDate
      ? moment(startDate).add('days', this.data.orderDetail.statusLabel.dateOfApproval - 1)
      : this.state.endDate;
    if (checkEndDate > moment()) {
      checkEndDate = moment();
    }
    endDate = checkEndDate;
    this.setState({
      selectedEndDate: endDate,
      startDate: startDate,
      endDate: endDate,
      firstLoad: false,
    });
  };

  updateFromDate = ({ endDate }) => {
    endDate = endDate || this.state.endDate;
    this.setState({
      selectedEndDate: endDate,
      endDate: endDate,
    });
  };
  /**
   * disableDatesTillStartDate function ensures that user can only select dates greater than Start Date
   * in End Date
   * @param    {[date]} function accepts the value of target end Date
   * @return   {[Void]} function does not return anything.
   */
  disableDatesTillStartDate = (date) => {
    return date >= this.state.startDate;
  };
  /**
   * handleChangeStart function sends startDate to handleChange function
   * to ensure start Date is greater or equal to End Date
   * @param    {[date]} function accepts the value of target start Date
   * @return   {[Void]} function does not return anything.
   */
  handleChangeStart = (startDate) => {
    startDate = convertJsDateToMomentObj(startDate, this.state.startDate, this.state.endDate);
    if (moment(startDate).isValid()) {
      this.handleChange({ startDate });
    } else {
      this.setState({
        startDate: '',
      });
    }
  };

  /**
   * handleChangeEnd function sends endDate to handleChange function
   * to ensure start Date is greater or equal to End Date
   * @param    {[date]} function accepts the value of target end Date
   * @return   {[Void]} function does not return anything.
   */
  handleChangeEnd = (endDate) => {
    endDate = convertJsDateToMomentObj(endDate, this.state.startDate, this.state.endDate);
    if (moment(endDate).isValid()) {
      this.updateFromDate({ endDate });
    } else {
      this.setState({
        selectedEndDate: endDate,
        endDate: '',
      });
    }
  };
  handleBlur = (e) => {
    if (!moment(e.target.value, 'DD/MM/YYYY', true).isValid()) {
      e.target.value = '';
    }
  };

  formatSettleId = (prevObj) => {
    let settleIDs = Object.values(prevObj).reduce((acc, { reservationId }) => acc + reservationId + ',', '');
    const removeComma = settleIDs.slice(-1);
    if (removeComma === ',') {
      settleIDs = settleIDs.slice(0, -1);
    }
    return settleIDs;
  };

  onChangeHandler = (e, dataRow) => {
    const allCheck = e.target.name === 'selectall' && e.target.checked,
      allUncheck = e.target.name === 'selectall' && !e.target.checked;

    if (Object.keys(this.prevObj).length < this.maxSettlementRange) {
      document.getElementById('selection-exceed-error').classList.add('hide');
      if (allCheck) {
        this.state.orderData.forEach((item) => {
          if (this.selectedOrderCount < this.maxSettlementRange && !item.checked) {
            this.selectedOrderCount++;
            item.checked = true;
            this.prevObj[item.creditAk] = item;
          }
        });
      } else if (allUncheck) {
        this.state.orderData.forEach((item) => {
          item.checked = false;
          delete this.prevObj[item.creditAk];
        });
        document.getElementById('selection-exceed-error').classList.add('hide');
      } else {
        if (dataRow.checked === true) {
          dataRow.checked = false;
          delete this.prevObj[dataRow.creditAk];
          this.allCheckRef.checked = false;
          this.allCheckRef.className = 'settlement-table-row-checkbox';
        } else {
          dataRow.checked = true;
          this.prevObj[dataRow.creditAk] = dataRow;
          document.getElementById('selection-exceed-error').classList.add('hide');
        }
      }
    } else {
      if (allUncheck) {
        this.state.orderData.forEach((item) => {
          item.checked = false;
          delete this.prevObj[item.creditAk];
          document.getElementById('selection-exceed-error').classList.add('hide');
        });
      } else if (!e.target.checked) {
        dataRow.checked = false;
        delete this.prevObj[dataRow.creditAk];
        this.allCheckRef.checked = false;
        this.allCheckRef.className = 'settlement-table-row-checkbox';
        document.getElementById('selection-exceed-error').classList.add('hide');
      } else {
        document.getElementById('selection-exceed-error').classList.remove('hide');
        return;
      }
    }
    const SettleId =
      Object.values(this.prevObj) && Object.values(this.prevObj)[0] && Object.values(this.prevObj)[0].reservationId
        ? this.formatSettleId(this.prevObj)
        : '';
    this.setState(
      {
        forSettlement: { ...this.prevObj },
        // below commented line is old code, this old code is creating issue in summing all amount
        //  totalPrice: Object.keys(this.prevObj).reduce((total, value) => total + parseFloat(this.prevObj[value].amount, 10), 0),
        totalPrice: Object.values(this.prevObj).reduce((total, { amount }) => total + parseFloat(amount), 0),
      },
      () => {
        this.payload = {
          CreditSettle: {
            PartnerId: ServiceConfig.getPartnerId(),
            PartnerEmailId: ServiceConfig.getPartnerEmailId(),
            CreditAks: Object.keys(this.state.forSettlement).toString(),
            Amount: this.state.totalPrice,
            Status: 1,
            SettleId,
          },
        };
        this.reconcilePayloadData = [];
        for (let key in this.state.forSettlement) {
          if (this.state.forSettlement.hasOwnProperty(key)) {
            const mainKey = this.state.forSettlement[key];
            const tempObj = {
              ak: key,
              amount: mainKey.amount,
              bookingId: mainKey.bookingId,
              reservationId: mainKey.reservationId,
            };
            const isDuplicateValues = this.reconcilePayloadData.find((value) => {
              return value.ak === tempObj.ak;
            });
            if (!isDuplicateValues) {
              this.reconcilePayloadData.push(tempObj);
            }
          }
        }
        window.PubSub.publish('CreditSettlement', {
          cart: this.payload,
          createSettlementAPI: resolvePath(this.data, 'services.b2bCreateSettlement', {}),
          reconcilePayloadData: this.reconcilePayloadData,
        });
        this.selectedOrderCount = Object.keys(this.state.forSettlement).length;
      },
    );
    e.target.checked
      ? (e.target.className = 'settlement-table-row-checkbox active')
      : (e.target.className = 'settlement-table-row-checkbox');
  };

  setAllCheckRef = (node) => {
    this.allCheckRef = node;
  };

  updatePageNoSettlement = (data) => {
    this.setState({ pageNumber: data.selected + 1 }, function() {
      let startValue = this.state.pageNumber * this.state.resultPerPage - this.state.resultPerPage + 1,
        endValue = this.state.pageNumber * this.state.resultPerPage;
      this.paginationStartValue = startValue;
      this.setState(
        {
          paginationEndValue: endValue,
          orderData: this.state.SettlementList.slice(startValue - 1, endValue),
          allCheck: false,
        },
        function() {
          const checkedItems = [];
          if (!this.state.allCheck) {
            this.allCheckRef.className = 'settlement-table-row-checkbox';
            this.allCheckRef.checked = false;
          }
          this.state.orderData.map((item) => (item.checked === true ? checkedItems.push(item) : null));
          if (checkedItems.length === this.state.resultPerPage) {
            this.allCheckRef.className = 'settlement-table-row-checkbox active';
            this.allCheckRef.checked = true;
          }
        },
      );
    });
  };

  handleSearch = () => {
    this.setState({
      pageNumber: 1,
      totalPrice: '0',
    });
    document.getElementById('selection-exceed-error').classList.add('hide');
    window.PubSub.publish('CreditSettlement', {
      reconcilePayloadData: [],
    });
    this.prevObj = {};
    this.selectedOrderCount = 0;
    this.paginationStartValue = 0;
    let url,
      fromDate = this.state.startDate.format('YYYY-MM-DD'),
      toDate = this.state.endDate.format('YYYY-MM-DD'),
      data = {
        fromDate: fromDate,
        toDate: toDate,
        status: 1,
      };

    const getDataForSettlementUrl = resolvePath(this.props, 'data.services.getDataForSettlement.url', '');
    if (getDataForSettlementUrl) {
      url = `${getDataForSettlementUrl}?tenantid=${getLoggedInUser().tenantID}`;
      const dataForSettlement = SettlementData.getSettlementData(url, true, '.loader-container', data);

      dataForSettlement
        .then((response) => {
          const totalRecords = response.data.totalRecords,
            settlementData = response.data.credits,
            filteredData = settlementData.slice(this.paginationStartValue, this.state.resultPerPage);
          this.orderCallCompleted = true;
          this.setState({
            SettlementList: settlementData,
            pageCount: Math.ceil(totalRecords / this.state.resultPerPage),
            paginationEndValue: this.state.resultPerPage,
            orderData: filteredData,
          });
          this.allCheckRef.checked = false;
          if (totalRecords) {
            this.allCheckRef.disabled = false;
            this.allCheckRef.className = 'settlement-table-row-checkbox';
          } else {
            this.allCheckRef.disabled = true;
            this.allCheckRef.className = 'settlement-table-row-checkbox visibility-hidden';
          }
        })
        .catch((response) => {
          const errorObj = getErrorMap(
            'getSettlementData',
            this.props.data.services.getOrders.errors,
            false,
            response.error,
            null,
          );
          this.setState({ errorData: errorObj });
        });
    }
  };

  componentDidMount() {
    const startDate = document.getElementById('start-date');
    const endDate = document.getElementById('end-date');
    startDate && startDate.setAttribute('autocomplete', 'off');
    endDate && endDate.setAttribute('autocomplete', 'off');
  }

  componentWillUnmount() {
    const startDate = document.getElementById('start-date');
    const endDate = document.getElementById('end-date');
    startDate && startDate.removeAttribute('autocomplete', 'off');
    endDate && endDate.removeAttribute('autocomplete', 'off');
  }

  render() {
    return this.data.variant === 'credit-settlement-variant' ? (
      <div className={`credit-settlement ${this.state.firstLoad && 'credit-load'}`}>
        <div className="date-picker-component" ref={this.setDatePickerRef}>
          <DynamicContent
            tagName="div"
            attrs={{ className: 'date-picker-label' }}
            innerHtml={this.data.filters.searchDetail.searchDateTitle}
          />
          <div className="date-picker-wrapper">
            <div className="date-picker-container">
              <DatePicker
                showDisabledMonthNavigation
                selected={
                  this.state.startDate == null || this.state.startDate == ''
                    ? this.state.startDate
                    : this.state.startDate.toDate()
                }
                selectsStart
                startDate={
                  this.state.startDate == null || this.state.startDate == ''
                    ? this.state.startDate
                    : this.state.startDate.toDate()
                }
                endDate={
                  this.state.endDate == null || this.state.endDate == ''
                    ? this.state.endDate
                    : this.state.endDate.toDate()
                }
                shouldCloseOnSelect={false}
                onChange={this.handleChangeStart}
                placeholderText={this.settlementLabels[0].label}
                onBlur={this.handleBlur}
                className="start-date"
                id="start-date"
                maxDate={momentTimezone()
                  .add(0, 'days')
                  .toDate()} //0 needs to be replaced with value provided by cms
                monthsShown={detectViewPort() === 'mobile' ? 1 : 2}
                locale={currentLocale()}
              >
                <Text
                  tag="div"
                  className="calendar-label"
                  field={{
                    value: this.props.data.filters.searchDetail.searchOrderTitle,
                    editable: this.props.data.filters.searchDetail.searchOrderTitle,
                  }}
                />
              </DatePicker>
              <div className="calendar-icon">
                <SvgSprite id="icn-calendar" />
              </div>
            </div>
            <div className="date-picker-container">
              <DatePicker
                showDisabledMonthNavigation
                selected={
                  this.state.endDate == null || this.state.endDate == ''
                    ? this.state.endDate
                    : this.state.endDate.toDate()
                }
                selectsEnd
                startDate={
                  this.state.startDate == null || this.state.startDate == ''
                    ? this.state.startDate
                    : this.state.startDate.toDate()
                }
                endDate={
                  this.state.selectedEndDate == null || this.state.selectedEndDate == ''
                    ? this.state.selectedEndDate
                    : this.state.selectedEndDate.toDate()
                }
                shouldCloseOnSelect={false}
                onChange={this.handleChangeEnd}
                filterDate={this.disableDatesTillStartDate}
                placeholderText={this.settlementLabels[1].label}
                onBlur={this.handleBlur}
                className="end-date"
                id="end-date"
                maxDate={momentTimezone()
                  .add(0, 'days')
                  .toDate()}
                monthsShown={detectViewPort() === 'mobile' ? 1 : 2}
                locale={currentLocale()}
              >
                <Text
                  tag="div"
                  className="calendar-label"
                  field={{
                    value: this.props.data.filters.searchDetail.searchOrderTitle,
                    editable: this.props.data.filters.searchDetail.searchOrderTitle,
                  }}
                />
              </DatePicker>
              <div className="calendar-icon">
                <SvgSprite id="icn-calendar" />
              </div>
            </div>
          </div>
          <div className="btn btn-primary">
            <button
              disabled={this.state.startDate && this.state.endDate ? false : true}
              title={this.data.filters.searchDetail.SearchDateIconTitle}
              onClick={this.handleSearch.bind(this)}
              className={'date-picker-search'}
              href="/"
            >
              <DynamicContent tagName="span" innerHtml={this.data.filters.searchDetail.SearchDateIconTitle} />
            </button>
          </div>
        </div>
        <DynamicContent
          tagName="div"
          attrs={{ className: 'hide', id: 'selection-exceed-error' }}
          innerHtml={resolvePath(this.data, 'services.selectCountCreditSettlement.errors.999', '')}
        />
        <OrderTable
          className="component c-order-container"
          labelConfig={this.props.data}
          variant={this.props.data.variant}
          onChangeHandler={(e, dataRow) => this.onChangeHandler(e, dataRow)}
          orderCallCompleted={this.orderCallCompleted}
          allCheck={this.state.allCheck}
          setAllCheckRef={this.setAllCheckRef}
          //Need to change
          orderData={this.state.orderData}
          orderDetail={this.state.orderDetail}
          beforeResultMsg={this.props.data.filters.searchDetail.searchOrderIconBodycopy}
        />
        {this.state.orderData.length > 0 && (
          <div>
            <Pagination
              pageCount={this.state.pageCount}
              onPageChange={this.updatePageNoSettlement}
              forcePage={this.state.pageNumber - 1}
            />
            <div className="total-amount-container">
              <div className="total-amount-label">
                <DynamicContent tagName="span" innerHtml={this.data.orderDetail.productTypeLabel.total} />
              </div>
              <div className="total-amount">
                <DynamicContent tagName="span" innerHtml={this.data.currency} />
                <span className="final-price">{this.state.totalPrice}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div
        className={`component c-order-data-grid ${this.data.theme} ${this.data.variant}`}
        data-c-name="OrderDataGrid"
        data-c-render="Universal"
      >
        <div className="w--content data-grid-wrapper">
          <div className="c-order-data-grid-filters">
            <OrderGridFilter data={this.data.filters} notifyFilters={this.notifyFilters.bind(this)} />
          </div>
          <div className="c-order-data-grid-list">
            <OrderContainer gridData={this.state} data={this.data} updatePageCount={this.updatePageCount.bind(this)} />
            {this.state.pageCount > 1 ? (
              <Pagination
                pageCount={this.state.pageCount}
                onPageChange={this.updatePageNo.bind(this)}
                forcePage={this.state.pageNo - 1}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */

OrderManager.propTypes = {
  data: PropTypes.shape({
    filters: PropTypes.shape({
      title: PropTypes.string.isRequired,
      bodyCopy: PropTypes.string,
      orderType: PropTypes.arrayOf(PropTypes.object).isRequired,
      searchDetail: PropTypes.object,
    }),
    currency: PropTypes.string.isRequired,
    pagination: PropTypes.shape({
      pageSize: PropTypes.number.isRequired,
    }),
  }),
};
