import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  getOrderIdpromoCode,
  removeGuestUser,
  getSessionStorage,
  isAnnualPassCase,
  createAnnualPassData,
  parseQueryString,
  getUserAgent,
  massageMobileData,
  getErrorDataInObject,
  getCurrentLanguage,
  resolvePath,
  getAnonymousCart,
  isLoggedInUser,
  getLoggedInUser,
} from '../../../../common/utility';
import api from '../../../../common/api-wrapper';
import { Logging } from '../../../../common/logger';
import { checkYAEventId } from '../utils';
import ApiWrapper from '../../../../common/api-wrapper';
import { setReconcilRequestAction } from '../../../../utils/paymentUtils';

export class PostPayPayment {
  constructor({ postPayFinalData, cmsData, paymentDataAnonymous }) {
    this.paymentFailure = ['canceled'];
    this.postPayData = postPayFinalData;
    this.paymentData = cmsData || {};
    this.paymentData.componentName = UIConfig.paymentOption.postPay;
    this.cartData = paymentDataAnonymous && paymentDataAnonymous.cart != null ? paymentDataAnonymous.cart : null;
    this.anonymousUser = canUseDOM() && this.cartData;
    this.isRenewAnnualPass = false;
    this.postPayTypeMethod = this.paymentData.postPayTypeMethod;
  }

  /**
   *  Successfull Payment
   *  Send status to Native app
   */

  actionMobileApp = ({ status }) => {
    window.JSbridge.nativeCallback(
      massageMobileData({
        status: status,
        orderID: localStorage.getItem('orderId') || null,
        ref: UIConfig.paymentOption.postPay,
      }),
    );
  };

  /**
   *  Redirect action method
   *  if something fails
   */

  pageRedirectURL = ({ paymentData, pageUrl, status, key, responseOrError }) => {
    if (paymentData) {
      const { url, error } = paymentData;
      window.location.href = `${url.href}?payment_error=${error}#${this.postPayTypeMethod}`;
    } else {
      let errorData = getErrorDataInObject(key, this.paymentData.services, responseOrError);
      localStorage.setItem(UIConfig.localStoreKeys.payment.errorMessage, JSON.stringify(errorData));
      window.location.href = `${pageUrl.url}?${UIConfig.querStringParams.thankYouPage.status}=${status}&${UIConfig.querStringParams.thankYouPage.reference}=${UIConfig.paymentOption.postPay}`;
    }
  };

  /**
   *  Successfull Payment
   *  Complete Payment and close Payment Sheet
   *  call reconcile to close order
   */

  redirectToActionPage = ({ isSuccess, status, statusCode }) => {
    const {
        cmsData: { primaryCTA, paymentURL },
      } = this.paymentData,
      {
        postPayTransaction: { errors },
      } = this.paymentData.services;

    if (isSuccess) {
      setReconcilRequestAction();
      this.reconciliation()
        .then((res) => {
          const orderId = btoa(localStorage.getItem('orderId')),
            isRenewAnnualPass = this.isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
          if (res.data.isSuccess) {
            Logging(res, this.paymentData.componentName, true, 'Post pay reconcile call success');
            this.clearStorage();
            if (!getUserAgent()) {
              window.location.href = `${primaryCTA.url}?${checkYAEventId()}${
                UIConfig.querStringParams.thankYouPage.status
              }=${status}&${UIConfig.querStringParams.thankYouPage.orderId}=${orderId}&${
                UIConfig.querStringParams.thankYouPage.reference
              }=${UIConfig.paymentOption.postPay}${isRenewAnnualPass}`;
            } else {
              this.actionMobileApp({ status });
            }
          } else {
            Logging(res.data, this.paymentData.componentName, false, 'Reconcile Status False');
            this.pageRedirectURL({
              pageUrl: primaryCTA,
              status: false,
              key: 'postPayReconcile',
              responseOrError: res.data.error,
            });
          }
        })
        .catch((err) => {
          Logging(err, this.paymentData.componentName, true, 'PostPay reconcile call error');
          const paymentData = {
            url: paymentURL,
            error: errors[err.error.code] ?? err.error.text,
          };
          this.pageRedirectURL({ paymentData });
        });
    } else {
      if (!getUserAgent()) {
        const postPayStatusCode = statusCode && statusCode.toLowerCase();
        if (this.paymentFailure && this.paymentFailure.indexOf(postPayStatusCode) > -1) {
          const paymentData = {
            url: paymentURL,
            error: errors[statusCode] || errors[postPayStatusCode],
          };
          this.pageRedirectURL({ paymentData });
        } else {
          this.pageRedirectURL({
            pageUrl: primaryCTA,
            status: false,
            key: 'postPayTransaction',
            responseOrError: { code: statusCode, text: '' },
          });
        }
      } else {
        this.actionMobileApp({ status });
      }
    }
  };

  /**
   *  if payment success, move to confirmation page
   *  if fails, redirect to payment journey
   */

  moveToNextPaymentStep = () => {
    const statusCode = this.postPayData.status;
    if (statusCode && statusCode.toLowerCase() === 'approved') {
      this.redirectToActionPage({
        isSuccess: true,
        status: true,
      });
    } else {
      this.redirectToActionPage({
        isSuccess: false,
        status: false,
        statusCode,
      });
    }
  };

  /**
   *  Begin Reconcilation
   *  When successfull -  redirects to Confirmation Page.
   *  When Failed -  close payment sheet and show error
   */

  reconciliation = () => {
    let userId,
      annualPassRequest = null;

    const {
        emailTemplateId,
        invoiceTemplateId,
        tenantID,
        pageVariant,
        apMainPassholderRenewEmailTemplateID,
        apMainPassholderPurchaseEmailTemplateID,
        apAddPassholderRenewEmailTemplateID,
        apAddPassholderPurchaseEmailTemplateID,
        apAddPassholderNewUserRenewEmailTemplateID,
        apAddPassholderNewUserPurchaseEmailTemplateID,
      } = this.paymentData.cmsData,
      { postPayReconcile } = this.paymentData.services,
      getOrderIdpromoCodeTemp = getOrderIdpromoCode(),
      orderIdpromoCode = getOrderIdpromoCodeTemp ? getOrderIdpromoCodeTemp.split('_') : 'null',
      isRenewAnnualPass = pageVariant === 'annualPassRenew';

    this.isRenewAnnualPass = isRenewAnnualPass;

    const cartData = isRenewAnnualPass
        ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
        : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart)),
      orderId = localStorage.getItem('orderId');

    if (this.anonymousUser) {
      userId = resolvePath(this.cartData, 'reservationOwner.email');
    } else {
      if (cartData) {
        const isAnnualPass = isAnnualPassCase(cartData, true);
        if (isAnnualPass) {
          const annualPassTicketHolders = createAnnualPassData({ cartData });
          annualPassRequest = {
            isAnnualRenew: isRenewAnnualPass,
            emailTemplateIdAnnualPass: isRenewAnnualPass
              ? apMainPassholderRenewEmailTemplateID
              : apMainPassholderPurchaseEmailTemplateID,
            emailTemplateIdWithYasId: isRenewAnnualPass
              ? apAddPassholderRenewEmailTemplateID
              : apAddPassholderPurchaseEmailTemplateID,
            emailTemplateIdWithNoYasId: isRenewAnnualPass
              ? apAddPassholderNewUserRenewEmailTemplateID
              : apAddPassholderNewUserPurchaseEmailTemplateID,
            ticketHolders: annualPassTicketHolders,
          };
        }
      }
      const loggedInData = getLoggedInUser();
      userId = loggedInData && loggedInData.email;
    }
    const { status, reservationCode } = this.postPayData;
    const apiData = {
        locale: getCurrentLanguage(),
        tenantId: tenantID,
        orderId: orderId,
        postPayStatus: status,
        reservationCode: reservationCode,
        emailTemplateId: emailTemplateId,
        invoiceTemplateId: invoiceTemplateId,
        promoCode: orderIdpromoCode[1] === 'null' ? null : orderIdpromoCode[1],
        annualPassRequest: annualPassRequest,
        paymentMethod: UIConfig.paymentOption.postPay,
        userId: userId,
      },
      postPayConfig = {
        method: 'POST',
        url: `${postPayReconcile.url}?isEncrypted=true`,
        data: {
          ...apiData,
        },
      };

    if (this.anonymousUser) {
      return api.api(postPayConfig);
    } else {
      return api.experienceServices(postPayConfig);
    }
  };

  /**
   *  clear stored data on localstorage
   */

  clearStorage = () => {
    if (this.isRenewAnnualPass) {
      sessionStorage.removeItem('cartForAnnualPassRenewal');
      sessionStorage.removeItem(UIConfig.payloadForRenew);
      sessionStorage.removeItem('annualPassRenewalData');
    }
    localStorage.removeItem('payment_info');
    localStorage.removeItem('payfort_data');
    localStorage.removeItem('isOrderHistoryPayment');
    localStorage.removeItem('cms_data');
    localStorage.removeItem('resCode');
    localStorage.removeItem('yasIdUserData');
    removeGuestUser();
  };
}

export const initializePostPay = (props) => {
  const cmsData = JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.payment.cmsData)),
    paymentDataAnonymous = getAnonymousCart(),
    status = parseQueryString('status'),
    msg = parseQueryString('msg'),
    reservationCode = parseQueryString('order_id'),
    postAmount = parseQueryString('post_amount'),
    cashAmount = parseQueryString('cash_amount'),
    postRedeemed = parseQueryString('post_redeemed'),
    postAccrued = parseQueryString('post_accrued'),
    postPayFinalData = {
      ...(status && { status }),
      ...(msg && { msg }),
      ...(reservationCode && { reservationCode }),
      ...(postAmount && { postAmount }),
      ...(cashAmount && { cashAmount }),
      ...(postRedeemed && { postRedeemed }),
      ...(postAccrued && { postAccrued }),
    },
    PostPay = new PostPayPayment({ postPayFinalData, cmsData, paymentDataAnonymous });
  PostPay.moveToNextPaymentStep();
};
