import moment from 'moment';
import { isWithinInterval } from 'date-fns';

import { canUseDOM } from './window';
import { getMainObject } from './helpers';
import UIConfig from '../UIConfig';
import { registerLocale } from 'react-datepicker';
import dateFnsEn from 'date-fns/locale/en-GB';
import dateFnsAr from 'date-fns/locale/ar-SA';
import dateFnsZh from 'date-fns/locale/zh-CN';
import dateFnsRu from 'date-fns/locale/ru';
registerLocale('en-gb', { ...dateFnsEn, options: { ...dateFnsEn.options, weekStartsOn: 1 } });
registerLocale('ar-sa', { ...dateFnsAr, options: { ...dateFnsAr.options, weekStartsOn: 1 } });
registerLocale('zh-cn', { ...dateFnsZh, options: { ...dateFnsZh.options, weekStartsOn: 1 } });
registerLocale('ru', { ...dateFnsRu, options: { ...dateFnsRu.options, weekStartsOn: 1 } });
let _utcOffset;

export const getClientUtcOffset = () => 0;

/**
 * returns utc offset saved in localstorage
 */
export const getUtcOffset = () => {
  if (_utcOffset) {
    return _utcOffset;
  }
  const mainObj = getMainObject();
  return (_utcOffset =
    mainObj && mainObj.additionalProperty && mainObj.additionalProperty.utcOffset
      ? parseInt(mainObj.additionalProperty.utcOffset, 10)
      : 4);
};

/**
 * function to convert a date to a particular timezone
 * @param {Integer} offset timezone for which the date needs to be convert
 * @param {Date,Moment,String} date date that needs to bbe converted
 */
export const momentTimezone = (date, offset = getUtcOffset()) => {
  if (moment.isMoment(date) && (date._offset || date.utcOffset()) / 60 === offset) {
    return date;
  }
  date = date && moment(date).isValid() ? (moment.isMoment(date) ? date : moment.utc(date)) : moment();
  const tzDate = date.clone();
  tzDate.subtract(tzDate.utcOffset(), 'minutes');
  tzDate.utcOffset(offset);
  tzDate._isUTC = false;
  return tzDate;
};

export const getCurrentLanguage = () => {
  if (canUseDOM()) {
    const language =
      document.getElementsByTagName('html')[0].getAttribute('platform-lang') ||
      document.getElementsByTagName('html')[0].getAttribute('lang');
    return language ? language : 'en';
  }
};

/*Method to get the valid start date for calender. If the start date provided by the CMS is a date has already passed in time
then the start date will be the current date.
*/
export const getValidStartDate = (date, checkOnly = false) => {
  const currDate = moment();
  if (!checkOnly && !moment(date).isValid()) {
    return currDate;
  }
  const chkDate = momentTimezone(date, getClientUtcOffset()).startOf('day'),
    currDateStart = moment().startOf('day'),
    isValidDate = chkDate.diff(currDateStart, 'days') >= 0;
  return checkOnly ? isValidDate : isValidDate ? chkDate : currDate;
};

export const displayDigitsWithCommaFormat = (number) => {
  return typeof number !== 'undefined'
    ? number.toLocaleString('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    : null;
};

/**
 * Converting date into DD MM YYYY formate
 */
export const getISOFormattedDate = (stringDate) => {
  let isoFormattedDate = stringDate.match(/\d{4}-\d{2}-d{2}/);
  if (!isoFormattedDate) {
    const regexForDDMMYYYY = /\d{2}-\d{2}-\d{4}/;
    let matchedDate = stringDate.match(regexForDDMMYYYY)[0];
    isoFormattedDate = stringDate.replace(
      regexForDDMMYYYY,
      matchedDate
        .split('-')
        .reverse()
        .join('-'),
    );
  } else {
    isoFormattedDate = stringDate;
  }

  return isoFormattedDate;
};

export const formatDate = (stringDate, format = UIConfig.dateDisplayFormat) => {
  let formattedDate = null;
  if (stringDate && stringDate.length > 0) {
    formattedDate = moment.utc(getISOFormattedDate(stringDate)).format(format);
  }
  return formattedDate;
};

/* Sort method to be used for Array.sort()
 * Default order of sorting is ascending
 * For descending order pass third perameter "DESC"
 */
export const sortByDate = (date1, date2, order = 'ASC') => {
  const isoDate1 = Date.parse(getISOFormattedDate(date1));
  const isoDate2 = Date.parse(getISOFormattedDate(date2));

  let comparision = isoDate1 > isoDate2 ? 1 : isoDate1 < isoDate2 ? -1 : 0;
  return comparision !== 0 && order === 'DESC' ? comparision * -1 : comparision;
};

//Get locale for current site
export const getLocale = () => {
  return 'en_US';
};

/** getCurrecnt Locale  */
export const currentLocale = () => {
  let activeLocale = 'en';
  switch (getCurrentLanguage()) {
    case 'ar-AE':
      activeLocale = 'ar-sa';
      break;
    case 'zh-CN':
      activeLocale = 'zh-cn';
      break;
    case 'ru-RU':
      activeLocale = 'ru';
      break;
    case 'en':
    default:
      activeLocale = 'en-gb';
      break;
  }
  return activeLocale;
};

/**
 * function to calculate local time
 * in a different city
 * given the city's UTC offset
 */
export const calcTime = (offset) => {
  switch (getCurrentLanguage()) {
    case 'ar-AE': {
      moment.locale('ar-sa');
      break;
    }
    default: {
      moment.locale('en');
      break;
    }
  }

  if (!offset) {
    offset = 0; //Making offset default to GMT
  }

  let d = new Date();
  let utc = d.getTime() + d.getTimezoneOffset() * 60000;
  let localDate = new Date(utc + 3600000 * offset);
  let current = moment(localDate);
  let date = current.format('DD MMM');
  let time = current.format('hh:mm a');

  return date + ' I ' + time;
};

/**
 * Desc: To convert ISO formatted datetime to simple datetime format
 * @return {String} return simple datetime format
 */
export const convertToDateTime = (str, format = UIConfig.b2c.profile.purchase.dateTimeFormat) => {
  let convertedDateTime = '';
  const isIsoFormattedDate = str && str.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/);
  if (isIsoFormattedDate) {
    convertedDateTime = moment.utc(str).format(format);
  }
  return convertedDateTime;
};

export const addDays = (curDate, days) => {
  let date = curDate;
  date.setDate(date.getDate() + days);
  return date;
};

/**
 * arabic english date format
 */
export const arabicEnglishDateFormat = (date, isYearNotRequired) => {
  let stringDate = '';
  if (date) {
    const day = moment(date)
      .locale('en')
      .format('DD');
    const month = moment(date).format('MMM');
    const year = moment(date)
      .locale('en')
      .format('YYYY');
    if (isYearNotRequired) {
      stringDate = day + ' ' + month;
    } else {
      stringDate = day + ' ' + month + ' ' + year;
    }
  }
  return stringDate;
};

/*
  returning empty string when time is undefined or null
  */
export const getFormattedTime = (time) => {
  if (!time) {
    return '';
  }

  return moment(time.substr(0, 8), [UIConfig.b2c.purchaseJourney.yaTimeFormat]).format(
    UIConfig.b2c.purchaseJourney.yaReqTimeFormat,
  );
};

/*
  returning empty string when date is undefined or null
  */
export const getYAFormattedTimeFromDate = (date) => {
  if (!date) {
    return '';
  }

  return moment.utc(date).format(UIConfig.b2c.purchaseJourney.yaReqTimeFormat);
};

/**
 * return calculated days from milliseconds
 */
export const calculateDaysFromMilliseconds = (milliseconds) => {
  var dayInMilliseconds = 1000 * 60 * 60 * 24;

  return milliseconds / dayInMilliseconds;
};

export const sortByDateTime = (date1, date2, order = 'ASC') => {
  const d1 = parseInt(moment(date1).format('YYYYMMDDHHmmss'));
  const d2 = parseInt(moment(date2).format('YYYYMMDDHHmmss'));
  const comparision = d1 < d2 ? 1 : d1 > d2 ? -1 : 0;

  return order === 'DESC' ? comparision : comparision * -1;
};

export const getUTCTimeByFormat = (isoDate, format = 'HH:mm') => {
  return moment(isoDate)
    .utc()
    .format(format);
};

/**
 * Check current date between start date and end date
 * @param {Object} data: Data Object
 * @return {boolean}
 */
export const isLiveStreamingStarted = (data) => {
  let isLiveStreamingStarted = true;
  const streamingStartDate = data.startDate
      ? moment
          .unix(momentTimezone(data.startDate).format('X'))
          .startOf('day')
          .format('X')
      : null,
    streamingEndDate = data.endDate
      ? moment
          .unix(momentTimezone(data.endDate).format('X'))
          .startOf('day')
          .format('X')
      : null,
    currentDate = moment()
      .startOf('day')
      .format('X');

  if (streamingStartDate && streamingEndDate) {
    isLiveStreamingStarted = currentDate >= streamingStartDate && currentDate <= streamingEndDate;
  } else if (streamingStartDate) {
    isLiveStreamingStarted = currentDate >= streamingStartDate;
  } else if (streamingEndDate) {
    isLiveStreamingStarted = currentDate <= streamingEndDate;
  }

  return isLiveStreamingStarted;
};

export const isValidSelection = (arrayOfDates, startDate, endDate) => {
  if (!arrayOfDates.length) return;
  return arrayOfDates.find((date) =>
    isWithinInterval(
      new Date(
        moment(date)
          .locale('en')
          .format(UIConfig.calendar.dateFormat),
      ),
      {
        start: new Date(
          moment(startDate)
            .locale('en')
            .format(UIConfig.calendar.dateFormat),
        ),
        end: new Date(
          moment(endDate)
            .locale('en')
            .format(UIConfig.calendar.dateFormat),
        ),
      },
    ),
  );
};

/**
 * Get All Dates between Start & End Date
 * @param {Object} data: Date Object
 * @return {boolean}
 */
export const getIncludedDates = (endDate, startDate) => {
  const dateArray = [];
  let currentDate = new Date(startDate);
  const stopDate = new Date(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(currentDate);
    currentDate = moment(currentDate).add(1, 'days');
    currentDate = currentDate.toDate();
  }
  return dateArray;
};

/**
 * @function convertJsDateToMomentObj is a function to convert the javascript Date Object into the moment object
 **/
export const convertJsDateToMomentObj = (date, startDate, endDate) => {
  let momentObj;
  if (date.length == 2) {
    let [start, end] = date;
    start = moment(start);
    end = moment(end);
    if (!startDate || start.diff(startDate) < 0) {
      momentObj = moment(start);
    } else if (!endDate) {
      momentObj = moment(end);
    } else {
      momentObj = moment(start);
    }
  } else {
    momentObj = moment(date);
  }
  return momentObj;
};
