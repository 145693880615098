import React from 'react';
import PropTypes from 'prop-types';

import { DynamicContent, Image, Spinner } from '../../base';
import { scrollToFormErrorOrSuccess } from '../../../../common/utility';
import { groupBookingErrorAnalytics } from '../../../../common/analytics-events';
import { logComponentRenderingError } from '../../../../common/logger';

export default class FastTicketComponent extends React.Component {
  constructor(props) {
    super(props);
    this.ticket = this.props.data.ticket;
    this.willScroll = this.props.tktVal === this.ticket.coveoValue ? true : false;
    this.maxCount = this.props.data.visitorCounter.maxCount;
    this.deltaCountForError = props.deltaCountForError ? props.deltaCountForError : 0;
    this.updateQuantity = this.updateQuantity.bind(this);

    this.state = {
      showError: false,
      quantity: this.props.data.visitorCounter.options[0],
    };
  }

  updateQuantity = (quantity) => {
    const isError = quantity < this.maxCount + this.deltaCountForError ? false : true;
    this.props.updateQuantity({
      quantity: quantity,
      max: this.maxCount,
      ticket: this.ticket,
      initialState: false,
    });

    isError && groupBookingErrorAnalytics(this.props.name || '', window.dataLayer && window.dataLayer[0].pageName);
    this.setState({
      showError: isError,
      quantity: quantity,
    });
  };

  renderPriceList = (price, index) => {
    return (
      <li className="body-2" key={index}>
        <span className="c-fast-price-for">{price.for}</span>
        <span className="c-fast-price-from"> {price.from}</span>
        <span className="c-fast-price-currency"> {price.currency}</span>
        <span className="c-fast-price-price"> {price.price}</span>
      </li>
    );
  };

  renderVisitorCounter = (visitorCounter) => {
    const { defaultQuantity } = visitorCounter.options[0];
    return (
      <div className={this.state.quantity === 0 ? '' : 'color-change'}>
        <Spinner
          updateQuantity={this.updateQuantity}
          isInputDisabled="true"
          min={0}
          max={visitorCounter.maxCount + this.deltaCountForError}
          quantity={defaultQuantity}
        />
      </div>
    );
  };

  componentDidMount() {
    if (this.willScroll) {
      scrollToFormErrorOrSuccess('scrollDiv', true);
    }
  }

  render() {
    try {
      const { visitorCounter, ticket } = this.props.data;
      return (
        <div className="c-fast-ticket-wrapper">
          <div className={this.willScroll ? 'c-fast-ticket scrollDiv' : 'c-fast-ticket'}>
            <div className="c-fast-desc">
              <p className="heading-4 c-fast-desc-title">{ticket.title}</p>
              <p className="body-1 c-fast-desc-sub-title">{ticket.subTitle}</p>
              <p className="body-2 ticket-desc">{ticket.description}</p>
              <div className="c-fast-price">
                <ul>{ticket.prices.map((price, index) => this.renderPriceList(price, index))}</ul>
              </div>
            </div>
            <div className="c-fast-logo-counter-wrapper">
              <div className="c-fast-logo">{ticket.image && <Image image={ticket.image} disableLazyLoad={true} />}</div>
              <div className="c-fast-counter">{this.renderVisitorCounter(visitorCounter)}</div>
            </div>
          </div>
          {this.state.showError && (
            <div className="error-group-booking">
              <DynamicContent attrs={{ className: '' }} tagName="p" innerHtml={visitorCounter.vistorCountError} />
            </div>
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'FastTicketComponent');
    }
  }
}

FastTicketComponent.PropsTypes = {
  data: PropTypes.shape({
    ticket: PropTypes.shape({
      parks: PropTypes.arrayOf(PropTypes.object.isRequired),
    }),
  }),
};
