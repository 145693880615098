export { default as arrowDown } from './arrow-down.png';
export { default as hamburgerIcon } from './hamburger-icon.png';
export { default as hamburgerIconRevamp } from './revamp-Hambuger-menu.svg';
export { default as hamburgerIconRevampDark } from './revamp-dark-Hambuger-menu.svg';
export { default as iconPayment } from './icon_payment.svg';
export { default as iconTicket } from './icon_ticket.svg';
export { default as yasIconLoader } from './loader.gif';
export { default as cartIcon } from './cart-icon.png';
export { default as timeClock } from './timer-clock.svg';
export { default as whatsappWhiteIcon } from './whatsapp-white.svg';
export { default as DownloadSVG } from './download-wbw.svg';
export { default as tickYWW } from './tick-yww.png';
export { default as tickQAW } from './tick-qaw.svg';
