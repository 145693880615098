import React, { useState } from 'react';
import Input from '../input';
import moment from 'moment';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import Calendar from '../calendar';
import { validateConditions, showOrHideInput } from '../custom-conditions';
import { getJSSFieldSectionErrors, getFieldValue } from '../../../../common/forms-utility';

const CalendarWithInput = (props) => {
  const fields = props.field.fields,
    tracker = props.tracker;
  const { calendarId } = UIConfig.jssForms.fields;
  const [isChildMinor, setIsChildMinor] = useState(true);

  try {
    const selectDateHandler = (date, errorObj) => {
      const selectedDate = moment(date).format(UIConfig.b2c.profile.dateFormat);
      const ageDiff = moment().diff(selectedDate || moment(), 'years');
      let computedValue = false;

      const calField = fields.find((item) => item.model.fieldTypeItemId === calendarId);

      if (date && calField && calField.valueField.name) {
        computedValue = validateConditions({
          value: ageDiff,
          conditionSettings: calField.model.conditionSettings,
        });

        if (computedValue) {
          setIsChildMinor(showOrHideInput(calField.model.conditionSettings));
        } else {
          setIsChildMinor(false);
        }
      }
      const { valid, errorMessages } = errorObj;
      props.onChange(calField.valueField.name, selectedDate, valid, errorMessages);
      tracker.onBlurField(calField, selectedDate, errorMessages);
    };

    const handleCalendarInput = () => {
      return fields.map((field) => {
        const errors = getJSSFieldSectionErrors(field, props.errors);
        const typeId = field.model.fieldTypeItemId;
        const fieldValue = getFieldValue(field, props.apiFieldValue);
        if (typeId === calendarId) {
          return (
            <Calendar
              {...props}
              field={field}
              selectDateHandler={selectDateHandler}
              hasDependentField={true}
              apiFieldValue={fieldValue}
              errors={errors}
            />
          );
        }
        if (isChildMinor) {
          return <Input {...props} field={field} errors={errors} apiFieldValue={fieldValue} />;
        }
      });
    };

    return handleCalendarInput();
  } catch (err) {
    return logComponentRenderingError(err, 'CustomCalendarInput');
  }
};

export default CalendarWithInput;
