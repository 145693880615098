import UIConfig from '../../../common/UIConfig';
import { parseQueryString, getAnonymousCart, resolvePath } from '../../../common/utility';
import PayfortPayment from '../payment/payfort/payfort-payment';

export const initializePayfort = (props) => {
  const method = resolvePath(props, 'data.paymentMethod', null);
  const paymentMethod = method && method.toLowerCase();

  const payFortData = props.data.paymentData;
  const paymentData = JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.payment.paymentInfo));
  const paymentDataAnonymous = getAnonymousCart();
  const token =
    payFortData && payFortData.token_name
      ? payFortData.token_name.toString()
      : parseQueryString(UIConfig.querStringParams.thankYouPage.tokenName);

  const status =
    payFortData && payFortData.status
      ? payFortData.status.toString()
      : parseQueryString(UIConfig.querStringParams.thankYouPage.status);

  const responseCode =
    payFortData && payFortData.response_code
      ? payFortData.response_code.toString()
      : parseQueryString(UIConfig.querStringParams.thankYouPage.responseCode);

  let cardDetails = {};
  cardDetails.cardNumber =
    payFortData && payFortData.card_number
      ? resolvePath(payFortData, 'card_number', '')
      : parseQueryString('card_number');
  cardDetails.expiryDate =
    payFortData && payFortData.expiry_date
      ? resolvePath(payFortData, 'expiry_date', '')
      : parseQueryString('expiry_date');
  const payfort = new PayfortPayment(token, paymentData, status, paymentDataAnonymous, responseCode, cardDetails);
  if (props.data.paymentStep && props.data.paymentStep.toLowerCase() === 'one') {
    payfort.makePayment();
  } else if (
    (props.data.paymentStep && props.data.paymentStep.toLowerCase() === 'two') ||
    paymentMethod === UIConfig.paymentMethod.tabby
  ) {
    paymentData.fortId =
      payFortData && payFortData.fort_id
        ? payFortData.fort_id
        : parseQueryString(UIConfig.querStringParams.payfort.fortId);
    paymentData.authorizationCode =
      payFortData && payFortData.authorization_code
        ? payFortData.authorization_code
        : parseQueryString(UIConfig.querStringParams.payfort.authorizationCode);
    paymentData.numberOfInstallments =
      payFortData && payFortData.number_of_installments ? payFortData.number_of_installments : '0';
    if (paymentMethod === UIConfig.paymentMethod.tabby) {
      paymentData.settlement_reference = payFortData.settlement_reference;

      payfort.makeTabbyPayment();
      return;
    }
    paymentData.isFrictionlessPayfort ? payfort.authorizeSucess() : payfort.reconcilePrice();
  }
};
