import React, { useRef, useEffect } from 'react';
import { Carousel, Image } from '../base';
import Video from '../video';
import {
  carouselDefaultSettings,
  detectViewPort,
  canUseDOM,
  deepCloneObject,
  isTouchDevice,
  detectEdge,
  isArabicMode,
  detectMobile,
  getArrayOfElement,
} from '../../../common/utility';
import smoothscroll from 'smoothscroll-polyfill';
import useBeforeFirstRender from '.././../../hooks/useBeforeFirstRender';
import { logComponentRenderingError } from '../../../common/logger';

const CAROUSEL_VARIANT = {
  allView: 'v-carousel-all-view',
};

const CarouselInCard = (props) => {
  const data = {
    variant: CAROUSEL_VARIANT.allView,
    cards: props.imageDetail,
    carousel: {
      arrows: props.imageDetail.length > 1 ? true : false,
      autoplay: false,
      centerMode: false,
      dots: true,
      rtl: false,
      slidesToScroll: 1,
      slidesToShow: 1,
    },
  };
  const sliderRef = useRef();
  const carouselRef = useRef();
  const viewport = detectViewPort();
  const isCenterTileZoomed = useRef(false);
  let lastFocusableEle = null;
  let previousSlideFocusable = null;
  const totalSlides = (data.cards && data.cards.length) || 0;
  const settings = useRef({});

  const setInitialTabIndex = () => {
    setTimeout(() => {
      const slick = sliderRef.current;
      if (!slick) {
        setInitialTabIndex();
      } else {
        const slidersElements = slick.querySelectorAll('.slick-track .slick-slide');
        if (slidersElements.length) {
          let mobileSettings = '';
          if (viewport === 'mobile') {
            mobileSettings = settings.current.responsive.find((i) => i.breakpoint === 767);
          }
          const slidesToShow = mobileSettings ? mobileSettings.settings.slidesToShow : settings.current.slidesToShow;
          addTabIndex(slidesToShow + 1, slidesToShow);
        } else {
          // Saadiyat contact-us: "find us on map" link tabindex change from -1 to 0
          const anchors = slick.querySelectorAll('.right-arrow');
          let anchorsLength = anchors.length;
          while (anchorsLength--) {
            anchors[anchorsLength].setAttribute('tabindex', '0');
          }
        }
      }
    }, 500);
  };
  /**
   * adds the tabIndex and accessibility before slide changes
   * Also, add events when tab moves on items for auto scroll
   */
  const addTabIndex = (currentSlide, slidesToShow) => {
    const slick = sliderRef.current;
    const slidersElements = slick.querySelectorAll('.slick-track .slick-slide');

    const { carousel } = data;
    if (slidersElements.length) {
      const classList = ['.carousel-item'];
      const theme = (data.theme && data.theme.replace(/\s/g, '')) || '';
      const cssClass = (data.cssClass && data.cssClass.replace(/\s/g, '')) || '';

      const customDots = carousel && carousel.dots && slick.querySelectorAll('.slick-slider .custom-dots button');
      if (customDots) {
        for (let idx = 0; idx < customDots.length; idx++) {
          customDots[idx].setAttribute('tabindex', '-1');
        }
      }

      // add accessibility to center slide when isCenterTileZoomed is true else add accessibility to all visible slides
      if (isCenterTileZoomed.current) {
        classList.forEach((currentVal, index) => {
          const sliderElement = slidersElements[currentSlide - 1];
          const element = sliderElement && sliderElement.querySelector(currentVal);

          if (element) {
            element.setAttribute('tabindex', '0');
            element.setAttribute('data-accessible', `${theme}-${cssClass}-accessible-${0}-${index}`);
          }
        });
      } else {
        for (let i = 0; i < slidesToShow; i++) {
          classList.forEach((currentVal, index) => {
            const sliderElement =
              slidersElements[
                carousel.centerMode
                  ? slidesToShow < 2
                    ? currentSlide
                    : currentSlide + i - Math.floor(slidesToShow / 2) - 1
                  : currentSlide + i - 1
              ];
            const element = sliderElement && sliderElement.querySelector(currentVal);
            if (element) {
              element.setAttribute('tabindex', '0');
              element.setAttribute('data-accessible', `${theme}-${cssClass}-accessible-${i}-${index}`);
            }
          });
        }
      }

      //return if there are no arrow keys
      if (!slick.querySelectorAll('.slick-arrow').length) {
        return false;
      }

      const params = {
        currentSlide,
        classList,
        theme,
        cssClass,
        slidesToShow,
      };
      const focusable = getArrayOfElement('.c-carousel-in-card-content [tabindex]:not([tabindex="-1"])', slick);
      for (let i = 0; i < focusable.length; i++) {
        focusable[i].addEventListener('keydown', (e) => {
          !detectMobile() && handleKeyDown(e, params);
        });
      }

      carouselTriggeringElements(params);
    }
  };

  const handleKeyDown = (e, attrs) => {
    const slick = sliderRef.current;
    const keyCode = e.which || e.keyCode;
    const { slidesToScroll } = data.carousel;

    const isLastSlide = e.target === lastFocusableEle;
    const isFirstSlide = e.target.getAttribute('data-accessible') === `${attrs.theme}-${attrs.cssClass}-accessible-0-0`;

    if (
      !e.shiftKey &&
      keyCode === 9 &&
      isLastSlide &&
      attrs.currentSlide - attrs.slidesToShow !==
        totalSlides -
          (isCenterTileZoomed.current ? 0 : data.carousel.centerMode ? attrs.slidesToShow - 2 : attrs.slidesToShow - 1)
    ) {
      carouselRef.current.slickNext();
      setTimeout(() => {
        if (isCenterTileZoomed.current) {
          slick.querySelector(`[data-accessible = ${attrs.theme}-${attrs.cssClass}-accessible-0-0]`).focus();
        } else {
          slick
            .querySelector(
              `[data-accessible = ${attrs.theme}-${attrs.cssClass}-accessible-${attrs.slidesToShow -
                slidesToScroll}-0]`,
            )
            .focus();
        }
        carouselTriggeringElements(attrs);
      }, settings.current.speed + 100);
    } else if (e.shiftKey && keyCode === 9 && isFirstSlide && attrs.currentSlide - attrs.slidesToShow !== 1) {
      carouselRef.current.slickPrev();
      setTimeout(() => {
        previousSlideFocusable.focus();
        carouselTriggeringElements(attrs);
      }, settings.current.speed + 100);
    }
  };

  const carouselTriggeringElements = (attrs) => {
    const slick = sliderRef.current;
    const { carousel } = data;
    //Get Last focussable element after which we need to trigger slick next
    lastFocusableEle = null;
    for (let i = attrs.classList.length; i >= 0; i--) {
      const element = slick.querySelector(
        `[data-accessible = ${attrs.theme}-${attrs.cssClass}-accessible-${
          isCenterTileZoomed.current ? 0 : attrs.slidesToShow - 1
        }-${i}]`,
      );

      if (element) {
        lastFocusableEle = element;
        break;
      }
    }

    //Get Last focusable element of previous slide after we trigger slick prev
    previousSlideFocusable = null;
    for (let i = attrs.classList.length; i >= 0; i--) {
      const element = slick.querySelector(
        `[data-accessible = ${attrs.theme}-${attrs.cssClass}-accessible-${
          isCenterTileZoomed.current ? 0 : carousel.slidesToScroll - 1
        }-${i}]`,
      );

      if (element) {
        previousSlideFocusable = element;
        break;
      }
    }
  };

  /**
   * remove the tabIndex and accessibility before slide changes
   */
  const removeTabIndex = () => {
    const slick = sliderRef.current;
    const slidersElem = slick.querySelectorAll('.slick-track .slick-slide');
    const classList = ['.carousel-item'];

    for (let i = 0; i < slidersElem.length; i++) {
      classList.forEach((currentVal) => {
        const focusableElm = slidersElem[i].querySelector(currentVal);
        if (focusableElm) {
          focusableElm.setAttribute('tabindex', '-1');
          focusableElm.removeAttribute('data-accessible');
        }
      });
    }
  };

  const getCustomCarouselSettings = () => {
    return {
      className: `c-card-tile--item ${
        data.carousel && data.carousel.dots && data.cards.length > 1 ? 'dot-enabled' : ''
      }`,
      dotsClass: 'custom-dots',
      customPaging: function(i) {
        return (
          <span>
            <span className="slide-in-view">
              {data.carousel && data.cards && data.carousel.rtl ? data.cards.length - i : i + 1}
            </span>
            <span className="separator">/</span>
            <span className="total-slide">{data.cards && data.cards.length}</span>
          </span>
        );
      },
      afterChange: (i) => {
        data.cards.forEach((item) => {
          item.isActive = false;
          if (item.activeCardId === i) {
            item.isActive = true;
          }
        });
      },
    };
  };

  const allViewSettings = (customCarouselSettings, viewport) => {
    /* 2048 define the Maximum View of Desktop   */
    customCarouselSettings = getCustomCarouselSettings();
    let responsiveCarouselSettings = {
      breakpoint: 100000,
      settings: {
        centerMode: data.carousel.centerMode || false,
        draggable: data.carousel.arrows,
      },
    };

    customCarouselSettings = Object.assign(
      {},
      deepCloneObject(carouselDefaultSettings),
      deepCloneObject(customCarouselSettings),
    );

    customCarouselSettings.responsive.push(responsiveCarouselSettings);
    customCarouselSettings.slidesToScroll = data.carousel.slidesToScroll;
    customCarouselSettings.arrows = data.carousel.arrows;
    customCarouselSettings.rtl = data.carousel.rtl;
    customCarouselSettings.slidesToShow = data.carousel.slidesToShow <= 0 ? 3 : data.carousel.slidesToShow;
    customCarouselSettings.swipe = isTouchDevice() || detectEdge();
    customCarouselSettings.centerMode = data.carousel.centerMode;
    return customCarouselSettings;
  };

  useBeforeFirstRender(() => {
    if (canUseDOM()) {
      smoothscroll.polyfill();
      let customCarouselSettings = {};
      const viewport = detectViewPort();
      if (data.variant === CAROUSEL_VARIANT.allView) {
        customCarouselSettings = allViewSettings(customCarouselSettings, viewport);
      }

      settings.current = Object.assign(
        {},
        deepCloneObject(carouselDefaultSettings),
        deepCloneObject(customCarouselSettings),
        {
          beforeChange: (prev, next) => {
            setTimeout(() => {
              const slidesToShow = data.carousel.slidesToShow;
              !detectMobile() && addTabIndex(next + slidesToShow + 1, slidesToShow);
            }, customCarouselSettings.speed);
            !detectMobile() && removeTabIndex();
          },
        },
      );
    }
  });
  useEffect(() => {
    if (data) {
      setInitialTabIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const iconTypeHtml = (card) => {
    if (card.mediaType === 'video') {
      return <Video active={card.isActive} data={card.videoInfo} imageInfo={card.imageInfo} />;
    }
    return <Image image={card.imageInfo} disableLazyLoad={true} />;
  };

  const videoImageContent = () => {
    if (data.cards) {
      return (
        <Carousel data={settings.current} ref={carouselRef}>
          {data.cards.map((card, index) => (
            <div key={index} className="c-carousel-in-card-content">
              <div className="carousel-item">{iconTypeHtml(card)}</div>
            </div>
          ))}
        </Carousel>
      );
    } else {
      return null;
    }
  };

  try {
    const direction = canUseDOM() && isArabicMode() ? 'ltr' : '';
    return (
      <div className="carousel-in-card-component" dir={direction} ref={sliderRef}>
        {videoImageContent()}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CarouselInCard');
  }
};

export default CarouselInCard;
