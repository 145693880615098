import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { resolvePath } from '../../../../common/utility';

const FieldErrorComponent = (props) => {
  const errors = resolvePath(props, 'errors', '');
  const valueFieldName = resolvePath(props, 'valueFieldName', '') || '';
  if (errors && errors.length) {
    return (
      <>
        {
          <Text
            tag="span"
            id={`error_${valueFieldName}`}
            aria-live="assertive"
            aria-relevant="additions removals"
            className="error-msg body-copy-6"
            field={{
              value: errors,
              editable: errors,
            }}
          />
        }
      </>
    );
  }
  return null;
};

export default FieldErrorComponent;
