import React, { useEffect } from 'react';
import { decodeQueryString, resolvePath } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

const handleOnKeyDown = (e, dataCoveoValue, handleOnClickFn) => {
  if (e.keyCode === 13) {
    handleOnClickFn(dataCoveoValue);
  }
};

const PassTierComponent = ({
  data,
  selectedTier,
  handleOnClick,
  componentState,
  ariaLabel,
  type,
  tabIndex,
  selectedParentTicket,
}) => {
  const queryParams = decodeQueryString();
  const parentTktCoveoValue = resolvePath(queryParams, 'tkt', '').toLowerCase();
  const additonalInfoQueryParam = resolvePath(queryParams, 'additional', '').toLowerCase() === 'true' ? true : false;

  useEffect(() => {
    if (additonalInfoQueryParam && selectedParentTicket && parentTktCoveoValue === selectedParentTicket.toLowerCase()) {
      handleOnClick(data.coveoValue);
    }
  }, [parentTktCoveoValue]);

  try {
    return (
      <div className={`c-pass tier ${!data.coveoValue ? 'disabled' : ''}`}>
        <div className="tier-category">
          <label
            onClick={() => {
              handleOnClick(data.coveoValue);
            }}
            htmlFor={data.title}
          >
            {data.title}
          </label>
          <div className="checkbox-label" htmlFor={data.coveoValue}>
            <input
              type={`${type ? type : 'radio'}`}
              id={data.coveoValue}
              name={data.coveoValue}
              value={data.title}
              checked={componentState !== 'noSelect' && selectedTier === data.coveoValue}
              onChange={() => {
                handleOnClick(data.coveoValue);
              }}
            />
            <div
              className="checkbox"
              onClick={() => handleOnClick(data.coveoValue)}
              tabIndex={`${tabIndex ? tabIndex : '0'}`}
              aria-label={ariaLabel}
              role="radio"
              aria-checked={componentState !== 'noSelect' && selectedTier === data.coveoValue}
              onKeyDown={(e) => handleOnKeyDown(e, data.coveoValue, handleOnClick)}
            ></div>
          </div>
        </div>
        <p className="tier-description"> {data.description} </p>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'PassTierComponent');
  }
};

export default PassTierComponent;
