import React, { useEffect, useState } from 'react';
import { logComponentRenderingError, Logging } from '../../../common/logger';
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import { resolvePath, canUseDOM, getLocalStorageByKey, isLoggedInUser } from '../../../common/utility';
import { getJSSFieldSectionErrors, getFieldValue } from '../../../common/forms-utility';
import { componentMapping, customSectionMapping } from './jss-form-component-mapping';
import { ProfileServices } from '../../../common/services';
import FormSubSection from './form-sub-section';
import UIConfig from '../../../common/UIConfig';

const FormSectionWrapper = (props) => {
  const field = props.field;
  const [formErrors, setFormErrors] = useState({});
  const [services, setServices] = useState({});
  const [guestDetails, setGuestDetails] = useState({});

  useEffect(() => {
    canUseDOM() &&
      window.PubSub.subscribe(UIConfig.jssForms.events.formSubmissionError, (msg, data) => {
        if (data.isFormErrorExist) {
          setFormErrors(data.errors);
        }
      });
    const jssFormData = getLocalStorageByKey(UIConfig.jssForms.events.jssFormSettingsData);
    const service = resolvePath(jssFormData, 'data.services', {});
    setServices(service);
    isLoggedInUser() && getB2CDetails(service);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Method to call API for B2C guest detail APIs
   */
  const getB2CDetails = (service) => {
    let url = resolvePath(service, 'getGuestProfile.url', '');
    if (url) {
      ProfileServices.GetGuestInfo('JSSForm', url)
        .then((res) => {
          if (res && res.data) {
            setGuestDetails(res.data);
          }
        })
        .catch((err) => {
          Logging(err, 'Get Guest Profile API failed');
        });
    }
  };

  const sectionHandler = () => {
    const itemType = field.model.name;
    let Component = null;
    let SectionErrors = getJSSFieldSectionErrors(props.field, formErrors);
    // format Cascade-0, AgeValidator-0
    const itemTypeArr = itemType.split('-');
    if (itemType && itemTypeArr && customSectionMapping[itemTypeArr[0]]) {
      Component = customSectionMapping[itemTypeArr[0]];
      return <Component {...props} errors={SectionErrors} services={services} apiFieldValue={guestDetails} />;
    } else if (resolvePath(field, 'fields', []).length) {
      return (
        <fieldset aria-label={field.model.ariaLabel || `field section`}>
          <legend>{itemType}</legend>
          {field.fields.map((item) => {
            const type = resolvePath(item, 'model.fieldTypeItemId', '');
            let errors = getJSSFieldSectionErrors(item, SectionErrors);
            if (type && type === FieldTypes.Section) {
              return (
                <FormSubSection
                  {...props}
                  field={item}
                  errors={errors}
                  services={services}
                  apiFieldValue={guestDetails}
                />
              );
            } else if (type && componentMapping[type]) {
              Component = componentMapping[type];
              const fieldValue = getFieldValue(item, guestDetails);
              return (
                <Component {...props} field={item} errors={errors} services={services} apiFieldValue={fieldValue} />
              );
            }
          })}
        </fieldset>
      );
    }
  };

  try {
    return sectionHandler();
  } catch (err) {
    return logComponentRenderingError(err, 'FormSectionWrapper');
  }
};

export default FormSectionWrapper;
