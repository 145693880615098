import React, { useState } from 'react';
import moment from 'moment';

import { DownloadService } from '../../../../common/services';
import { blobToFile, getErrorMap } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';

const AddToCalendar = (props) => {
  const [error, setError] = useState('');

  const getFormattedDate = (date) => moment.utc(date).format(UIConfig.b2c.purchaseJourney.yaDateTimeFormate);

  const downloadCalendar = () => {
    const { tickets, performances } = props.product;
    const { url, errors: cmsErrors } = props.serviceCall;
    let validTo = '',
      validFrom = '';
    if (performances && performances[0].date) {
      validFrom = getFormattedDate(performances[0].date);
      validTo = performances[0].endDate ? getFormattedDate(performances[0].endDate) : validFrom;
    } else if (tickets) {
      validFrom = getFormattedDate(tickets[0].validFrom || '');
      validTo = getFormattedDate(tickets[0].validTo || '');
    }
    setError('');
    DownloadService.downloadFile(
      {
        title: props.title,
        EventStartDateWithTime: validFrom,
        EventEndDateWithTime: validTo,
        EventLocation: props.eventLocation,
      },
      url,
    )
      .then((response) => {
        let fileName = props.title + '.ics';
        blobToFile(response, fileName, 'text/calendar');
      })
      .catch((err) => {
        let { addToCalendar: { text } = {} } = getErrorMap('addToCalendar', cmsErrors, false, err.error, {});
        setError(text);
      });
  };

  try {
    return (
      <div className="add-to-calendar">
        <div className="btn-wrapper">
          <button className="btn-primary" onClick={downloadCalendar}>
            {props.label}
          </button>
        </div>
        {error && <div className="error-wrapper">{error}</div>}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'AddToCalendar');
  }
};

export default AddToCalendar;
