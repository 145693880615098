import React, { useRef, useState, useEffect } from 'react';
import Image from '../../../../presentation/base/image/image-component';
import DynamicContent from '../../../../presentation/base/dynamic-content/dynamic-content-component';
import Input from '../../../../presentation/base/input/input-component';
import Button from '../../../../presentation/base/button/button-component.js';
import UIConfig from '../../../../../common/UIConfig';
import {
  getLocalStorageByKey,
  getSessionStorage,
  isAnnualPassCase,
  getLoggedInUser,
  getUserAgent,
  createAnnualPassData,
  getCurrentLanguage,
  getOrderIdpromoCode,
  removeGuestUser,
  massageMobileData,
  removeADCBTouchPointsStorage,
  canUseDOM,
  getAnonymousCart,
  resolvePath,
  isLoggedInUser,
  checkTenant,
} from '../../../../../common/utility';
import { validateInputFieldNum } from '../../../../../common/payment-action/actions';
import PayfortUtility from '../../payfort/payfort-utility';
import { Logging } from '../../../../../common/logger';
import { ADCBTouchPointsService } from '../../../../../common/services';
import { logComponentRenderingError } from '../../../../../common/logger';

const ADCBTouchPointsOTP = (props) => {
  const propsData = props.propsData;
  const paymentData = getLocalStorageByKey(UIConfig.localStoreKeys.payment.cmsData) || {};
  const userAgent = getUserAgent();
  const anonymousUser = !isLoggedInUser();
  const isRenewAnnualPass = resolvePath(paymentData, 'cmsData.pageVariant') === 'annualPassRenew';
  const redemptionRequest = useRef(resolvePath(paymentData, 'orderData.redemptionRequest'));
  const serviceKey = {
    touchPointsRedemption: 'touchPointsRedemption',
  };
  let userProfileData = {};
  if (anonymousUser) {
    const anonymousUserData = getAnonymousCart();
    userProfileData.userId = resolvePath(anonymousUserData, 'cart.reservationOwner.email', '');
    userProfileData.customerName = resolvePath(anonymousUserData, 'cart.reservationOwner.firstName', '');
  } else {
    const loggedInUserData = getLoggedInUser();
    userProfileData.userId = loggedInUserData && loggedInUserData.email;
    userProfileData.customerName = loggedInUserData && loggedInUserData.userName;
  }

  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const [isErrorExist, setIsErrorExist] = useState(false);
  const [focus, setFocus] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState(null);
  const [disableResendBtn, setDisableResendBtn] = useState(false);

  const onFieldBlur = (e) => {
    setEnteredOtp(e.target.value);
    setFocus(false);
    e.target.value && setIsErrorExist(false);
  };

  const onFieldFocus = (e) => {
    setFocus(true);
  };

  useEffect(() => {
    if (canUseDOM()) {
      const otpInput = document.getElementById('otp');
      otpInput &&
        otpInput.addEventListener('keypress', (e) => {
          return validateInputFieldNum(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReconcileData = () => {
    let data = {};
    const {
      apMainPassholderRenewEmailTemplateID,
      apMainPassholderPurchaseEmailTemplateID,
      apAddPassholderRenewEmailTemplateID,
      apAddPassholderPurchaseEmailTemplateID,
      apAddPassholderNewUserRenewEmailTemplateID,
      apAddPassholderNewUserPurchaseEmailTemplateID,
      emailTemplateId,
      invoiceTemplateId,
      tenantID,
    } = paymentData.cmsData;
    const cartData = isRenewAnnualPass
      ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
      : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart));
    if (cartData) {
      let isAnnualPass = isAnnualPassCase(cartData, true);
      if (isAnnualPass) {
        const annualPassTicketHolders = createAnnualPassData({ cartData });
        data.annualPassRequest = {
          isAnnualRenew: isRenewAnnualPass,
          emailTemplateIdAnnualPass: isRenewAnnualPass
            ? apMainPassholderRenewEmailTemplateID
            : apMainPassholderPurchaseEmailTemplateID,
          emailTemplateIdWithYasId: isRenewAnnualPass
            ? apAddPassholderRenewEmailTemplateID
            : apAddPassholderPurchaseEmailTemplateID,
          emailTemplateIdWithNoYasId: isRenewAnnualPass
            ? apAddPassholderNewUserRenewEmailTemplateID
            : apAddPassholderNewUserPurchaseEmailTemplateID,
          ticketHolders: annualPassTicketHolders,
        };
      }
    }
    data.locale = getCurrentLanguage();
    data.orderId = paymentData.orderData.orderId;
    data.emailTemplateId = emailTemplateId;
    data.invoiceTemplateId = invoiceTemplateId;
    data.marketType = paymentData.cmsData.marketType;
    data.paymentMethod = UIConfig.paymentOption.adcbTouchPoints;
    data.tenantId = tenantID;
    const getOrderIdpromoCodeTemp = getOrderIdpromoCode();
    const orderIdpromoCode = getOrderIdpromoCodeTemp ? getOrderIdpromoCodeTemp.split('_') : 'null';
    data.promoCode = orderIdpromoCode[1] === 'null' ? null : orderIdpromoCode[1];
    data.userId = userProfileData.userId;
    data.redemptionRequest = {
      ...redemptionRequest.current,
      customerName: userProfileData.customerName,
      otp: enteredOtp,
    };
    return data;
  };

  const pageRedirect = ({ paymentPageErrorMessage, status }) => {
    const thankYouPageVar = UIConfig.querStringParams.thankYouPage;
    window.top.location.href = `${resolvePath(paymentData, 'cmsData.thankYouPageUrl')}?${
      thankYouPageVar.status
    }=${status}&${thankYouPageVar.reference}=${UIConfig.paymentOption.adcbTouchPoints}`;
  };

  const redirectToThankYouPage = (
    status,
    orderId = 0,
    serviceKey = '',
    responseOrError = null,
    paymentPageErrorMessage = {},
    paymentStatus,
  ) => {
    PayfortUtility.clearLocalStore();
    removeADCBTouchPointsStorage();
    removeGuestUser();
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: false,
    });
    if (!userAgent) {
      if (status) {
        localStorage.removeItem(UIConfig.localStoreKeys.yasIdUserData);
        if (isRenewAnnualPass) {
          sessionStorage.removeItem('cartForAnnualPassRenewal');
          sessionStorage.removeItem(UIConfig.payloadForRenew);
          sessionStorage.removeItem('annualPassRenewalData');
        }
        const isRenewAnnualPassStr = isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
        const isEncryptedStr = resolvePath(paymentData, 'cmsData.notEncrypted') ? '&notEncrypted=true' : '';
        localStorage.removeItem('userReservationInfo');
        const thankYouPageVar = UIConfig.querStringParams.thankYouPage;
        window.top.location.href = `${resolvePath(paymentData, 'cmsData.thankYouPageUrl')}?${
          thankYouPageVar.status
        }=${status}&${thankYouPageVar.orderId}=${encodeURIComponent(orderId)}&${thankYouPageVar.reference}=${
          UIConfig.paymentOption.adcbTouchPoints
        }${isEncryptedStr}&isPage=${paymentData.pageVariant}${isRenewAnnualPassStr}`;
      } else {
        const errorData = PayfortUtility.getErrorObject(serviceKey, paymentData.services, responseOrError);
        localStorage.setItem(UIConfig.localStoreKeys.payment.errorMessage, JSON.stringify(errorData));
        pageRedirect({ paymentPageErrorMessage, status });
      }
    } else {
      window.JSbridge.nativeCallback(
        massageMobileData({
          status: status,
          orderID: orderId || null,
          ref: UIConfig.paymentOption.adcbTouchPoints,
        }),
      );
    }
  };

  const handleSubmitOTP = () => {
    const encryptionParam = paymentData.cmsData.notEncrypted ? '?isEncrypted=false' : '';
    if (enteredOtp) {
      ADCBTouchPointsService.getTouchPointRedemption({
        url: paymentData.services.touchPointsRedemption.url + encryptionParam,
        apiData: getReconcileData(),
        preLoaderTarget: '.otp-overlay',
      })
        .then((response) => {
          if (response.data && response.data.isSuccess) {
            Logging(
              response,
              paymentData.cmsData.componentName || 'ADCBTouchPointRedemption',
              true,
              'step2 reconcile price success',
            );
            redirectToThankYouPage(true, paymentData.orderData.orderId);
          } else {
            Logging(response, paymentData.cmsData.componentName, false, 'TouchPoint Redemption Status False');
            redirectToThankYouPage(
              false,
              0,
              serviceKey.touchPointsRedemption,
              response.data.responseMessage.error,
              {},
              'System Failure',
            );
          }
        })
        .catch((err) => {
          Logging(err, paymentData.cmsData.componentName, false, 'TouchPoint Redemption api call Failed');
          redirectToThankYouPage(false, 0, serviceKey.touchPointsRedemption, err.error, {}, 'System Failure');
        });
    } else {
      setIsErrorExist(true);
      const otpInput = document.getElementById('otp');
      otpInput && otpInput.focus();
    }
  };

  const resendOTP = () => {
    const tenantId = getLoggedInUser().tenantID;
    const params = {
      ...props.resendParams,
      tenantId,
      userId: userProfileData.userId,
      customerName: userProfileData.customerName,
    };
    ADCBTouchPointsService.getTouchPointOTP({
      url: propsData.services.touchPointsGenerateOtp.url,
      apiData: JSON.stringify(params),
      preLoaderTarget: '.otp-overlay',
    })
      .then((res) => {
        if (res.data && res.data.isSuccess) {
          Logging(res.data, paymentData.cmsData.componentName, true, 'OTP Resend Success');
          redemptionRequest.current = {
            ...redemptionRequest.current,
            requestId: res.data.otpResponse.requestId,
            merchantId: res.data.otpResponse.merchantId,
            otpReferenceNbr: res.data.otpResponse.otpReferenceNumber,
          };
          setDisableResendBtn(true);
        } else if (res.data && !res.data.isSuccess) {
          Logging(res.data, paymentData.cmsData.componentName, true, 'OTP Resend Error');
        }
      })
      .catch((err) => {
        Logging(err, paymentData.cmsData.componentName, true, 'OTP Resend Error');
      });
  };

  try {
    const { paymentOptions } = propsData;
    const adcbTouchPointsConfig = paymentOptions.adcbTouchPointspayconfig || {};
    const paymentLabels = paymentOptions.paymentLabels || {};
    let otpInputData = {
      class: isErrorExist ? 'error' : '',
      value: enteredOtp || '',
      label: paymentLabels.otpPlaceholder,
      type: 'Number',
      name: 'otp',
      id: 'otp',
      active: enteredOtp || focus ? 'active' : '',
      showErrors: isErrorExist,
      message: paymentLabels.enterOtpErrorMsg,
      inputMode: 'decimal',
    };
    let buttonData = {
      type: 'button',
      label: paymentLabels.submitCTALabel,
      disabled: true,
      class: 'payment-submit-btn btn-primary button-enabled-dark',
    };
    let resendButtonData = {
      type: 'button',
      label: paymentLabels.resendCTALabel,
      disabled: true,
      class: 'resend-otp',
    };

    const buttonContainer = () => {
      return (
        <>
          <div className="adcb-otp-submit-btn">
            <Button
              data={buttonData}
              isNewForm={true}
              clickCallback={handleSubmitOTP}
              disabled={parseInt(props.isTabIndex)}
            />
          </div>
          <Button data={resendButtonData} isNewForm={true} clickCallback={resendOTP} disabled={disableResendBtn} />
        </>
      );
    };

    return (
      <div className="adcb-otp-wrapper">
        {adcbTouchPointsConfig.cardImage && (
          <div className="otp-logo">
            <Image image={paymentOptions.adcbTouchPointspayconfig.cardImage} />
          </div>
        )}
        <DynamicContent tagName="p" attrs={{ className: 'otp-text' }} innerHtml={paymentLabels.otpTextLabel} />
        <Input data={otpInputData} onFocus={onFieldFocus} onBlur={onFieldBlur} />
        {isSwad ? <div className="btn-wrapper">{buttonContainer()}</div> : <>{buttonContainer()}</>}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ADCBTouchPointsOTP');
  }
};

export default ADCBTouchPointsOTP;
