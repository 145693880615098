import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import {
  getLoggedInUser,
  getSessionStorage,
  isEmpty,
  isEnterPressed,
  momentTimezone,
  resolvePath,
  setSessionStorage,
} from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
import { AnchorLink, DynamicContent, Image, Timer } from '../../../presentation/base';
import Video from '../../../presentation/video';
import DateFlexibleSelector from '../date-flexible-selector/date-flexible-selector-component';
import VisitorsCountSelector from '../visitors-count-selector/visitors-count-selector-component';
import InvokePurchaseJourney from '../invokePurchaseJourney';
import CartActions from '../cart/cart-actions';

const openOverlay = (tncPopup) => {
  if (!isExperienceEditorActive()) {
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      dataToAppend: tncOverlay(tncPopup),
      customClass: 'ride-info-overlay c-flash-sale-overlay',
    });
  }
};

const tncOverlay = (tncPopup) => {
  return (
    <div className="overlay-sub-container">
      {tncPopup.tncTitle && (
        <DynamicContent
          tagName="h2"
          innerHtml={tncPopup.tncTitle}
          attrs={{
            className: 'overlay-sub-heading',
          }}
        />
      )}
      {tncPopup.tncDescription && (
        <DynamicContent
          tagName="p"
          innerHtml={tncPopup.tncDescription}
          attrs={{
            className: 'overlay-description',
          }}
        />
      )}
    </div>
  );
};

const checkKeyDownHandler = (e, tncPopup) => {
  if (isEnterPressed(e)) {
    openOverlay(tncPopup);
  }
};

const renderBuyTicketCta = (ctaDetails, isTakeOverBanner, productWidget, isPjDataFetched) => {
  if (!isEmpty(ctaDetails) && !isEmpty(ctaDetails.label)) {
    const href = ctaDetails.link ? ctaDetails.link.href : '';
    const productWidgetId = productWidget?.productDetails?.productId;
    return (
      <div
        className={
          isTakeOverBanner ? `btn btn-primary ${isPjDataFetched && productWidgetId ? 'pj-enabled' : ''}` : 'cta-section'
        }
      >
        <AnchorLink
          className="buy-tickets"
          link={{
            href,
            label: ctaDetails.label,
          }}
        />
      </div>
    );
  }
  return null;
};

const FlashSale = (props) => {
  const { addToCartCallback, data: propsData, errObj, withWrapper } = props;
  const { productWidget, timerWidget, variant, isTakeOverBanner } = propsData;
  const productOnly = variant === 'product-only';
  const timerOnly = variant === 'timer-only';
  const data = timerOnly && !isTakeOverBanner ? timerWidget : productWidget;
  const [err, setErr] = useState(errObj);
  const [disabled, setDisabled] = useState(false);
  const [flashCheckoutClicked, setFlashCheckoutClicked] = useState(false);
  const [isTakeOverBannerOpen, setTakeOverBannerOpen] = useState(false);
  const [isPjDataFetched, setPjDataFetched] = useState(false);
  const [isBannerLoaded, setBannerLoaded] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [isPurchaseJrnyActive, setPurchaseJrnyActive] = useState(false);

  let isPJEnabled = false;
  if (isTakeOverBanner && productWidget?.productDetails) {
    const { productId, packageCode } = productWidget?.productDetails;
    if (productId || packageCode) {
      isPJEnabled = true;
    }
  }

  const visitorCountObj = useRef({});
  const date = useRef({});
  const key = useRef('');
  const prodStore = useRef({});
  const prods = useRef();
  const pjData = useRef(null);
  const cartActions = useRef(null);

  const getMobileHeight = () => {
    if (!checkIfBannerClosed()) {
      const doc = document.documentElement;
      doc.style.setProperty('--takeover-popup-height', `${window.innerHeight - 42}px`);
    }
  };

  useEffect(() => {
    const { visitorCounter } = data;
    const prodCodes = [];
    const ticketData = {};
    if (!timerOnly && !isTakeOverBanner && resolvePath(visitorCounter, 'options', []).length) {
      key.current = visitorCounter.options[0].coveoKey;
      visitorCounter.options.forEach((element) => {
        prodCodes.push(element.coveoValue);
        ticketData[element.coveoValue] = element.defaultQuantity;
      });
      visitorCountObj.current = ticketData;
      props.createProductStore('flashSale', { key: key.current, value: prodCodes }, false).then((res) => {
        prodStore.current = res;
      });
    }

    if (isTakeOverBanner) {
      // YMC: TakeoverBanner
      //Below function will check if Banner is closed it should not open for that page
      const closedTakeOverBannerUrls = JSON.parse(getSessionStorage('closedTakeOverBannerUrls'));
      const { timerDetails } = productWidget;
      const currentDate = moment.utc().format();
      const startDate = moment.utc(timerDetails?.startDate).format();
      const endDate = moment.utc(timerDetails?.endDate).format();
      const timerStopped = timerDetails ? currentDate >= endDate : false;
      const timerNotStarted = timerDetails ? currentDate < startDate : false;
      if (!timerNotStarted) {
        if (closedTakeOverBannerUrls?.length) {
          const willOpen = !closedTakeOverBannerUrls.some((url) => url === window.location.href);
          setTakeOverBannerOpen(willOpen && !timerStopped);
          if (willOpen) {
            openTakeOverBanner();
          }
        } else {
          setTakeOverBannerOpen(true);
          openTakeOverBanner();
          setShowTimer(!timerStopped);
        }
      }

      window.PubSub.subscribe('closeOverlay', () => {
        closeOverLay();
      });

      // YMC: TakeoverBanner
      // If takeoverbanner has purchase journey the below pubsub will be subscribed.
      window.PubSub.subscribe(UIConfig.events.PURCHASEJOURNEYDATA, (msg, data) => {
        pjData.current = data.purchaseJourneyData;

        cartActions.current = new CartActions({
          serviceUrl: pjData.current.services,
          moduleName: 'purchase-journey',
          tenantId: getLoggedInUser().tenantID,
        });

        setPjDataFetched(true);
      });
    }

    getMobileHeight();
    window.addEventListener('resize', getMobileHeight);

    return () => {
      window.removeEventListener('resize', getMobileHeight);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.errObj) {
      if (!err || err.errorCode !== props.errObj.errorCode) {
        setErr(props.errObj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  //Skipping first iteration
  const isFlashCheckoutClickedFirstRun = useRef(true);
  useEffect(() => {
    if (isFlashCheckoutClickedFirstRun.current) {
      isFlashCheckoutClickedFirstRun.current = false;
      return;
    }

    addToCartCallback && addToCartCallback(prods.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flashCheckoutClicked]);

  const openTakeOverBanner = () => {
    setTimeout(() => {
      setBannerLoaded(true);
    }, 100);
  };

  // YMC: TakeoverBanner
  // If takeoverbanner closed then it should never be opened until the url is stored in session
  const closeOverLay = () => {
    const closedTakeOverBannerUrls = JSON.parse(getSessionStorage('closedTakeOverBannerUrls')) || [];
    const href = window.location.href;
    if (!closedTakeOverBannerUrls.includes(href)) {
      const updateCloseUrl = [...closedTakeOverBannerUrls, href];
      setSessionStorage('closedTakeOverBannerUrls', JSON.stringify(updateCloseUrl));
    }
  };

  const checkIfBannerClosed = () => {
    const closedTakeOverBannerUrls = JSON.parse(getSessionStorage('closedTakeOverBannerUrls')) || [];
    const href = window.location.href;
    return closedTakeOverBannerUrls.includes(href);
  };

  const setDateData = (data) => {
    const { selectedField, selectedDate, rangeInMonths } = data;
    const isFlexible = selectedField === 'flexible';
    date.current = {
      from: isFlexible ? momentTimezone() : selectedDate,
      isFlexible,
      rangeInMonths: isFlexible ? rangeInMonths : null,
    };
    setErr();
  };

  const setVisitorData = (data) => {
    visitorCountObj.current = data.spinnerCount.primaryCounterCount;
    setErr();
  };

  const addToCart = (e) => {
    e.preventDefault();
    if (err) {
      return false;
    }

    if (prodStore.current && prodStore.current.products && prodStore.current.products.length === 0) {
      return false;
    }

    const { dateSelector, visitorCounter } = data;
    const ticketValidity =
      dateSelector &&
      dateSelector.options &&
      dateSelector.options.length &&
      dateSelector.options[0] &&
      dateSelector.options[0].ticketValidity !== '0';

    if (dateSelector && !date.current.from) {
      setErr({ errorCode: 'INVALID_DATE', errorText: dateSelector.options[0].errorMessage });
      return false;
    }
    prods.current = [];
    let totalCount = 0;
    for (const prodCode in visitorCountObj.current) {
      if (!visitorCountObj.current.hasOwnProperty(prodCode)) {
        continue;
      }
      if (!visitorCountObj.current[prodCode]) {
        setErr({ errorCode: 'INVALID_QTY', errorText: visitorCounter.minVistorCountError });
        return false;
      }
      let prod = prodStore.current.getFilteredProductsFromKeyVal({ [key.current]: prodCode });
      prod = props.createMappedData(prod)[0];
      prod.fromDate =
        date.current.from instanceof moment && ticketValidity
          ? date.current.from.format(UIConfig.calendar.dateFormat)
          : '';
      totalCount += props.getProductQuantityInCart(prod);
      prod.quantity = visitorCountObj.current[prodCode];
      totalCount += parseInt(prod.quantity);
      prod.isFlexible = date.current.isFlexible;
      prod.rangeInMonths = date.current.rangeInMonths;
      prod.otherReason = JSON.stringify({ maxGrouppedQuantity: visitorCounter.maxCount });
      prod.maxCount = visitorCounter.maxCount;
      prod.minCount = visitorCounter.minCount;
      prods.current.push(prod);
    }
    if (totalCount > visitorCounter.maxCount) {
      setErr({ errorCode: 'INVALID_QTY', errorText: visitorCounter.maxVistorCountError });
      return false;
    }
    setFlashCheckoutClicked(true);
    return true;
  };

  const setDisableState = (state) => {
    setDisabled(state);
  };

  const flashSale = () => {
    const { ctaDetails, dateSelector, productDetails, tncPopup, visitorCounter } = data;
    const { discountLabel, saveLabel, regularPrice, salePrice, vatInfo } = productDetails;
    return (
      <div
        className={`product-selection ${disabled ? 'disabled' : ''} ${date.current.isFlexible ? 'flexible-date' : ''} ${
          !productOnly && (discountLabel || saveLabel || regularPrice || salePrice || vatInfo) ? 'show-divider' : ''
        }`}
      >
        <div className="product-selection-selectors section-hide">
          {visitorCounter && (
            <VisitorsCountSelector
              data={{ primaryCounter: visitorCounter }}
              setVisitorData={setVisitorData}
              componentState={flashCheckoutClicked ? 'forceNoSelect' : ''}
              isSpinnerDisabled={!!disabled}
              maxAvailableTicketsCount={visitorCounter.maxCount}
            />
          )}
          {!productOnly && dateSelector && (
            <DateFlexibleSelector data={dateSelector} setDateData={setDateData} disabled={disabled} />
          )}
        </div>
        {err && (
          <DynamicContent
            tagName="p"
            attrs={{ className: `error-text${err.errorCode === 'INVALID_QTY' ? ' qty-err' : ''}` }}
            innerHtml={err.errorText}
          />
        )}
        <div className="product-selection-buttons">
          <div className="btn-primary section-hide">
            {ctaDetails && ctaDetails.link && (
              <AnchorLink
                className="add-to-cart"
                link={{
                  href: ctaDetails.link.href,
                  label: disabled ? ctaDetails.secondaryLabel : ctaDetails.label,
                }}
                onClick={addToCart}
                tabIndex={disabled ? '-1' : null}
              />
            )}
          </div>
          {tncPopup && (
            <AnchorLink
              className="tnc-link"
              link={{ label: tncPopup.tncLabel }}
              onClick={() => openOverlay(tncPopup)}
              onKeyDown={(e) => checkKeyDownHandler(e, tncPopup)}
              tabIndex="0"
            />
          )}
        </div>
        {productDetails && vatInfo && (
          <DynamicContent
            tagName="p"
            attrs={{ className: `${productOnly ? 'vat-info vat-info-product' : 'vat-info'} ` }}
            innerHtml={vatInfo}
          />
        )}
      </div>
    );
  };

  const flashSaleProductOnly = () => {
    const { ctaDetails, dateSelector, productDetails, tncPopup, visitorCounter } = data;
    return (
      <div
        className={`product-selection ${disabled ? 'disabled' : ''} ${date.current.isFlexible ? 'flexible-date' : ''}`}
      >
        <div
          className={`product-selection-selectors ${dateSelector &&
            (dateSelector.options[0].name === 'flexible' || dateSelector.hidden
              ? 'hide-date-selector'
              : 'show-date-selector')}`}
        >
          {visitorCounter && (
            <VisitorsCountSelector
              data={{ primaryCounter: visitorCounter }}
              setVisitorData={setVisitorData}
              componentState={flashCheckoutClicked ? 'forceNoSelect' : ''}
              maxAvailableTicketsCount={visitorCounter.maxCount}
            />
          )}

          {dateSelector && <DateFlexibleSelector data={dateSelector} setDateData={setDateData} />}
          {err && (
            <DynamicContent
              tagName="p"
              attrs={{ className: `error-text${err.errorCode === 'INVALID_QTY' ? ' qty-err' : ''}` }}
              innerHtml={err.errorText}
            />
          )}
          <div
            className={`btn-primary-wrapper ${
              dateSelector.options[0].name === 'flexible' ? ' btn-primary-margin' : ''
            }`}
          >
            <div className="btn-primary">
              {ctaDetails && ctaDetails.link && (
                <AnchorLink
                  className="add-to-cart"
                  link={{
                    href: ctaDetails.link.href,
                    label: disabled ? ctaDetails.secondaryLabel : ctaDetails.label,
                  }}
                  onClick={addToCart}
                />
              )}
            </div>
          </div>

          {tncPopup && (
            <AnchorLink
              className="tnc-link"
              link={{
                label: tncPopup.tncLabel,
              }}
              onClick={() => openOverlay(tncPopup)}
            />
          )}
        </div>
        {productDetails && productDetails.vatInfo && (
          <DynamicContent
            tagName="p"
            attrs={{ className: `${productOnly ? 'vat-info vat-info-product' : 'vat-info'} ` }}
            innerHtml={productDetails.vatInfo}
          />
        )}
      </div>
    );
  };

  const getFlashSale = (data) => {
    const { productDetails, timerDetails, tncPopup, widgetDetails } = data;
    const { discountLabel, regularPrice, salePrice, saveLabel, vatInfo } = productDetails || {};
    const fullWidth = (variant || '').includes('full-width');
    const imageFirst = (variant || '').includes('image-first');
    let renderimageOrVideo = null;
    if (widgetDetails && widgetDetails.showFlashVideo && widgetDetails.video) {
      renderimageOrVideo = (
        <div className="c-flash-sale-video">
          <Video data={widgetDetails.video} active={widgetDetails.video.autoplay} />
        </div>
      );
    } else if (widgetDetails && !isEmpty(widgetDetails.image)) {
      renderimageOrVideo = (
        <div className="c-flash-sale-image">
          <Image noRendition={false} image={widgetDetails.image} />
        </div>
      );
    }
    return (
      <div className={`c-flash-sale ${productOnly ? 'c-flash-sale-product' : ''} ${fullWidth ? 'full-width' : ''}`}>
        {productOnly ? '' : imageFirst && renderimageOrVideo}
        <div className={`${productOnly ? 'c-flash-sale-details-product' : 'c-flash-sale-details'}`}>
          {!productOnly && widgetDetails && <DynamicContent tagName="h2" innerHtml={widgetDetails.title} />}
          {!productOnly && widgetDetails && <DynamicContent tagName="p" innerHtml={widgetDetails.description} />}
          <div className="ticket-details">
            {!productOnly && timerDetails && <Timer data={timerDetails} disableSelection={setDisableState} />}
            {!productOnly && productDetails && (salePrice || regularPrice || discountLabel || saveLabel) && (
              <div
                className="product-details"
                tabIndex="0"
                aria-label={`${discountLabel.replace(/<[^>]*>/g, '')} ${saveLabel.replace(
                  /<[^>]*>/g,
                  '',
                )} ${regularPrice.replace(/<[^>]*>/g, '')} ${salePrice.replace(/<[^>]*>/g, '')}`}
              >
                <p>
                  <DynamicContent tagName="span" innerHtml={discountLabel} />
                  <DynamicContent tagName="span" attrs={{ className: 'saving-price' }} innerHtml={saveLabel} />
                </p>
                <DynamicContent tagName="p" attrs={{ className: 'reg-price' }} innerHtml={regularPrice} />
                <DynamicContent tagName="h3" attrs={{ className: 'sale-price' }} innerHtml={salePrice} />
              </div>
            )}
            {!productOnly && (salePrice || regularPrice || discountLabel || saveLabel) && (
              <div
                className={`divider ticket-box ${!((productDetails && vatInfo) || (tncPopup && tncPopup.tncLabel)) &&
                  ' divider-hide'}`}
              ></div>
            )}
            {productOnly ? flashSaleProductOnly() : flashSale()}
          </div>
        </div>
        {productOnly ? '' : !imageFirst && renderimageOrVideo}
      </div>
    );
  };

  // YMC: TakeoverBanner
  // Created a "InvokePurchaseJourney" component, if right values passed can use to enable purchase journey from any page as a common component
  const getButtonWrapper = (ctaDetails) => {
    const { secondaryCtaLink, secondaryCtaLabel, label, link } = ctaDetails;
    if (isTakeOverBanner) {
      const primaryBtn = label && link;
      return (
        <div className="pj-btn-wrapper">
          {isPJEnabled && primaryBtn ? (
            <InvokePurchaseJourney
              productWidget={productWidget}
              enablePJPopup={isPJEnabled}
              addToCartCallback={addToCartCallback}
              pjData={pjData}
              isPjDataFetched={isPjDataFetched}
              btnLabel={label}
              setPurchaseJrnyActive={() => setPurchaseJrnyActive(true)}
            />
          ) : (
            primaryBtn && (
              <div className="pj-buy-now-button">
                <div className={`btn-primary-dark`}>
                  <AnchorLink
                    className="btn-primary"
                    link={{
                      href: link?.href,
                      label: label,
                    }}
                  />
                </div>
              </div>
            )
          )}
          {secondaryCtaLabel &&
            secondaryCtaLink?.href &&
            renderBuyTicketCta(
              { label: secondaryCtaLabel, link: secondaryCtaLink },
              isTakeOverBanner,
              productWidget,
              isPjDataFetched,
            )}
        </div>
      );
    }
    return ctaDetails && ctaDetails.label && renderBuyTicketCta(ctaDetails, isTakeOverBanner);
  };

  const getTimer = (timerDetails) => {
    if (productOnly && !timerDetails) {
      return;
    }

    if (isTakeOverBanner) {
      if (!timerDetails || !showTimer || disabled) {
        return null;
      }
    }

    return (
      <div className="timer-wrapper">
        <Timer
          data={timerDetails}
          disableSelection={setDisableState}
          withoutPurchase={timerOnly}
          isTakeOverBanner={isTakeOverBanner}
        />
      </div>
    );
  };
  const getBoolInt = (setting) => (setting ? 1 : 0);

  const YoutubeVideo = (video, title) => {
    const { videoId, autoplay, muted, controls, loop, startTime, endTime } = video;
    return (
      <div className="c-video">
        <div className="c-video-player">
          <iframe
            title={title || ''}
            width="100%"
            height="100%"
            src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=${getBoolInt(autoplay)}&mute=${getBoolInt(
              muted,
            )}&controls=${controls}&loop=${getBoolInt(loop)}&rel=0&start=${startTime}&end=${endTime}${
              loop ? `&playlist=${videoId}` : ''
            }`}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    );
  };

  const getTimerOnlyFlashSale = (data) => {
    const { ctaDetails, timerDetails, widgetDetails } = data;

    // YMC: TakeoverBanner
    // Below "renderimageOrVideo" will trigger only when Takeover baner is enabled
    let renderimageOrVideo = null;
    const { image, video, showFlashVideo, title } = widgetDetails;
    if (showFlashVideo && video) {
      renderimageOrVideo = (
        <div className="c-flash-sale-video">
          {video.videoId ? YoutubeVideo(video, title) : <Video data={video} active={video.autoplay} />}
        </div>
      );
    } else if (image && !isEmpty(image)) {
      renderimageOrVideo = (
        <div className="c-flash-sale-image">
          <Image noRendition={false} image={image} />
        </div>
      );
    }

    return (
      <>
        {isTakeOverBanner && <div className="c-flash-sale-media-container">{renderimageOrVideo}</div>}
        <div className={`c-flash-sale ${!isTakeOverBanner && disabled ? 'hide' : ''}`}>
          <div className="c-flash-sale-details">
            <div className="sale-description">
              {isTakeOverBanner && <DynamicContent tagName="label" innerHtml={widgetDetails.tagLine} />}
              {!productOnly && widgetDetails && <DynamicContent tagName="h4" innerHtml={widgetDetails.title} />}
              {!productOnly && widgetDetails && <DynamicContent tagName="p" innerHtml={widgetDetails.description} />}
            </div>
            {getTimer(timerDetails)}
            {getButtonWrapper(ctaDetails)}
          </div>
        </div>
      </>
    );
  };

  const flashSaleWrapper = (fsElement) => {
    return (
      <div className={`component c-flash-sale-only ${timerOnly ? 'no-flex' : 'no-padding-top'}`}>
        <div className={`w--content ${timerOnly ? 'purchase-content' : ''}`}>{fsElement}</div>
      </div>
    );
  };

  // YMC: TakeoverBanner
  // Below function will open flash sale in a popup
  const takeOverBannerWrapper = (fsElement) => {
    return window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      customClass: `addOns-Overlay takeover-banner-overlay is-open ${isBannerLoaded ? 'banner-slide-down' : ''}`,
      dataToAppend: flashSaleWrapper(fsElement),
    });
  };

  try {
    const fsElement = timerOnly || isTakeOverBanner ? getTimerOnlyFlashSale(data) : getFlashSale(data);

    if (isTakeOverBanner) {
      return (
        isTakeOverBannerOpen &&
        !isPurchaseJrnyActive && <div className="takeover-banner-container">{takeOverBannerWrapper(fsElement)}</div>
      );
    }

    if (!withWrapper) {
      return flashSaleWrapper(fsElement);
    }

    return fsElement;
  } catch (err) {
    return logComponentRenderingError(err, 'FlashSale', variant);
  }
};

FlashSale.PropsTypes = {
  data: PropTypes.shape({
    productWidget: PropTypes.object,
    timerWidget: PropTypes.object,
    variant: PropTypes.string,
  }),
};

export default FlashSale;
