/**
 * coupon-code-components.js
 * @licensor  Miral
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Promotion } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  setPromoCode,
  resolvePath,
  isEmpty,
  getMembershipDetails,
  getLocalStorageByKey,
  checkTenant,
  detectMobile,
  canUseDOM,
  getPartnerCodeData,
  isYasArenaJourney,
  setSessionStorage,
  getSessionStorage,
  checkIfParks,
  isParksExpressCheckoutTenant,
  checkParksTenants,
  isTicketUpgradeJourney,
} from '../../../../common/utility';
import { DynamicContent } from '..';
import Input from '../input/input-component';
import { logComponentRenderingError } from '../../../../common/logger';
import { KeyCodes } from '../../../../common/constants';
import './coupon-code-component.scss';

/**
 * coupon-code-component is a component which validates the coupon code
 * and returns the updated value and validity
 */
class CouponCode extends Component {
  constructor(props) {
    super(props);
    //Partner segment declarations
    const membershipCode = getMembershipDetails('membershipData');
    const findMembership = getPartnerCodeData(props.cartProps?.data?.memberDiscounts, membershipCode);
    const status = membershipCode?.status;
    const mSegment = membershipCode?.segmentType;
    this.specialPromoCode = '';
    const ispartnerSegmentPromo = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo);
    this.memberCouponCode =
      (ispartnerSegmentPromo &&
        membershipCode &&
        membershipCode.couponCode.length > 0 &&
        membershipCode.couponCode[0].split(':')[1]) ||
      '';
    const segmentType = (this.memberCouponCode && membershipCode.segmentType) || '';

    if (
      (mSegment?.toLowerCase() === 'corporate' && status === 'enabled') ||
      mSegment?.toLowerCase() === 'haw' ||
      mSegment?.toLowerCase() === 'fazaa'
    ) {
      this.specialPromoCode = findMembership?.promoCode;
    }
    this.state = {
      couponValue: this.memberCouponCode ? this.memberCouponCode : props.data.couponCode,
      couponApplied: this.memberCouponCode || props.data.couponCode ? true : false,
      error: false,
      success: false,
      errorMessage: null,
      successMessage: null,
      showUsePromoCodeLink:
        props.isCartPaymentView &&
        !props.cartSummaryExpandedView &&
        !props.data.couponCode.trim() &&
        !this.memberCouponCode &&
        !props.focusOnCouponCode,
      isCartPaymentViewExpanded: false,
      hasImplicitPromotion: props.cartData.cart.hasImplicitPromotion,
      userMembership: { title: '', subTitle: '' },
      expanded: false,
    };
    this.tempCouponCode =
      props.cartProps &&
      props.cartProps.data &&
      props.cartProps.data.memberDiscounts &&
      props.cartProps.data.memberDiscounts.length > 0 &&
      props.cartProps.data.memberDiscounts.find(
        (item) => item.discountType.toLowerCase() === segmentType.toLowerCase(),
      );
    this.cartCouponCode =
      this.memberCouponCode && this.state.couponValue ? (this.tempCouponCode ? this.tempCouponCode.promoCode : '') : '';
    this.classes = classNames('c-coupon-code', {
      'c-coupon-code-payment c-coupon-code-payment--center c-coupon-code-payment--no-border': props.isCartPaymentView,
    });
    this.expressChekoutCase = checkTenant(UIConfig.iamMapping.swad) || this.props.isParksExpressCheckout;
    this.isYmc = checkTenant(UIConfig.iamMapping.ymc);
    this.isParksExpressChekoutTenant = isParksExpressCheckoutTenant();
    this.fieldFocus = React.createRef();
    this.hidePromoOnTicketUpgrade = checkParksTenants() && isTicketUpgradeJourney();
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState(this.setInitalCouponData(nextProps));
  };

  componentWillMount = () => {
    if (this.props.cartSummaryExpandedView) {
      this.setState(this.setInitalCouponData(this.props));
    }
  };

  componentDidMount() {
    const b2cMobileCartTriggered = getSessionStorage('isParksCouponApplied');
    if (
      this.state.couponApplied &&
      this.state.couponValue &&
      !this.memberCouponCode &&
      (b2cMobileCartTriggered === 'false' || !b2cMobileCartTriggered)
    ) {
      const context = this;
      this.isParksExpressChekoutTenant && setSessionStorage('isParksCouponApplied', true);
      Promotion.ValidateCoupon(this.props.data.service.url, this.state.couponValue, 'CouponCode', true)
        .then((response) => {
          // this.cartCouponCode =
          //   this.memberCouponCode && this.state.couponValue
          //     ? this.tempCouponCode
          //       ? this.tempCouponCode.promoCode
          //       : ''
          //     : '';
          setPromoCode(this.specialPromoCode || this.state.couponValue);
          context.props.addCallback && context.props.addCallback(response.data, true);

          context.setState({
            error: false,
            couponApplied: true,
            success: true,
            successMessage: this.props.data.successMessage,
          });
        })
        .catch((response) => {
          // We have set the error false as we don't want to show coupon error in case of page referesh for payment failure for one time coupon.
          // if (context.memberCouponCode) {
          //   this.cartCouponCode = '';
          //   context.setState(
          //     {
          //       couponValue: '',
          //       error: true,
          //       couponApplied: false,
          //       errorMessage: this.props.data.errorMessage,
          //     },
          //     () => {
          //       context.props.addCallback && context.props.addCallback(response, false);
          //     },
          //   );
          // } else {
          // this.cartCouponCode = '';
          context.setState(
            {
              couponValue: '',
              error: false,
              success: false,
              couponApplied: false,
              errorMessage: '',
              successMessage: '',
            },
            () => {
              resolvePath(context.props, 'setCouponServerErrorDisplay', '') &&
                context.props.setCouponServerErrorDisplay(false);
              context.props.removeCallback && context.props.removeCallback();
            },
          );
          this.isParksExpressChekoutTenant && setSessionStorage('isParksCouponApplied', false);
          // }
        });
    }
    if (this.memberCouponCode) {
      setPromoCode(this.specialPromoCode || this.state.couponValue);
      const coupondetails = {
        coupon: {
          code: this.memberCouponCode,
          name: '',
          type: '',
        },
      };

      this.props.addCallback && this.props.addCallback({ coupondetails }, true);
      this.setState({ error: false, couponApplied: true });
    }
    if (this.props.isCartPaymentView) {
      window.PubSub.subscribe(UIConfig.events.ONCARTPAYMENTVIEWEXPANDED, (msg, data) => {
        const isCartPaymentViewExpanded = data.expanded;
        // const hasChevronClicked = data.chevronClicked;

        const error = !this.memberCouponCode ? false : this.state.error;
        let showUsePromoCodeLink = true;

        // when coupon code applied
        if (!this.state.couponApplied && !this.memberCouponCode) {
          if (!isCartPaymentViewExpanded) {
            showUsePromoCodeLink = true;
          }
          //} else if(this.props.focusOnCouponCode && !hasChevronClicked) {
          else if (this.props.focusOnCouponCode) {
            showUsePromoCodeLink = false;
          }
        } else {
          showUsePromoCodeLink = false;
        }

        if (this.props.cartSummaryExpandedView) {
          showUsePromoCodeLink = false;
        }
        this.setState(
          {
            isCartPaymentViewExpanded,
            showUsePromoCodeLink,
            error,
          },
          () => {
            if (this.props.focusOnCouponCode) this.setCouponCodeFocus();
          },
        );
      });
    }

    if (this.props.cartData.cart.promotions && this.props.data.vivaMemberShip) {
      if (this.props.data.vivaMemberShip.promocode) {
        let vivaMemberShipExists = false;
        this.props.cartData.cart.promotions.forEach((promotion) => {
          this.props.data.vivaMemberShip.promocode.forEach((promocode) => {
            if (promotion.code === promocode) {
              vivaMemberShipExists = true;
            }
          });
        });
        if (vivaMemberShipExists) {
          this.setState({
            ...this.state,
            userMembership: {
              title: this.props.data.vivaMemberShip.title,
              subTitle: this.props.data.vivaMemberShip.subTitle,
            },
          });
        }
      }
    }
    // resized cart when user scroll to the top
    if (this.expressChekoutCase && canUseDOM() && !this.props.isHeaderMiniCartEnabled) {
      !this.isMobile &&
        window.addEventListener('scroll', () => {
          if (window.scrollY === 0 && this.props.setstickyCartHeight) {
            this.props.setstickyCartHeight(true);
          }
        });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.expressChekoutCase && this.state.expanded) {
      this.props.stickyCart();
      // resized cart when user at the top and expand promo-code
    }
    if (
      this.expressChekoutCase &&
      this.state.expanded !== prevState.expanded &&
      window.scrollY === 0 &&
      this.props.setstickyCartHeight
    ) {
      this.props.setstickyCartHeight(true);
      detectMobile() && this.fieldFocus.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  hasPromotions = (cart) => {
    return Array.isArray(cart.promotions) && cart.promotions.length > 0;
  };

  checkIfValidPromo = (cart, promo) => {
    const { promotions } = cart;
    if (this.isYmc) {
      if (promotions?.length) {
        return promotions.some((prm) => prm.code === promo && (prm.type === '2' || prm.type === '4'));
      }
      return false;
    }
    return true;
  };

  setInitalCouponData = (propsData) => {
    const couponCode = resolvePath(propsData.cartData, 'cart.couponCode', ''),
      vivaMemberShip = resolvePath(propsData.data, 'vivaMemberShip', '');
    const propCoupon = propsData?.data?.couponCode;
    const isValidPromo = this.checkIfValidPromo(propsData.cartData.cart, propCoupon);
    const stateData = {
      couponValue: isValidPromo && propCoupon && this.hasPromotions(propsData.cartData.cart) ? propCoupon : '',
      couponApplied: isValidPromo && propCoupon && this.hasPromotions(propsData.cartData.cart) ? true : false,
      error: isValidPromo && propCoupon && !this.hasPromotions(propsData.cartData.cart) ? true : false,
      errorMessage:
        propCoupon && !this.hasPromotions(propsData.cartData.cart)
          ? resolvePath(propsData, 'businessErrors.invalidcpn', 'Invalid coupon')
          : propsData.data.errorMessage,
      showUsePromoCodeLink:
        !this.memberCouponCode && this.props.cartSummaryExpandedView
          ? !this.props.cartSummaryExpandedView
          : this.props.isCartPaymentView &&
            !this.state.isCartPaymentViewExpanded &&
            !propCoupon &&
            !this.memberCouponCode &&
            !this.hasPromotions(propsData.cartData.cart),
      hasImplicitPromotion: propsData.cartData.cart.hasImplicitPromotion,
    };

    // this.cartCouponCode =
    //   propsData.data.couponCode && !this.hasPromotions(propsData.cartData.cart) ? '' : this.cartCouponCode;

    if (couponCode && !isEmpty(vivaMemberShip)) {
      if (vivaMemberShip.promocode && Array.isArray(vivaMemberShip.promocode) && vivaMemberShip.promocode.length > 0) {
        vivaMemberShip.promocode.forEach((code) => {
          if (code === couponCode) {
            stateData.couponApplied = false;
            if (stateData.couponValue) {
              stateData.couponValue = '';
            }
          }
        });
      }
    }

    return stateData;
  };

  applyCoupon = (e) => {
    e.preventDefault();
    if (this.state.couponValue) {
      const context = this;
      Promotion.ValidateCoupon(this.props.data.service.url, this.state.couponValue, 'CouponCode', true)
        .then((response) => {
          setPromoCode(this.state.couponValue);
          context.props.addCallback && context.props.addCallback(response.data, true);
          context.setState({
            error: false,
            couponApplied: true,
            success: true,
            successMessage: this.props.data.successMessage,
          });
          this.cartCouponCode = '';
        })
        .catch((response) => {
          context.setState(
            {
              couponValue: '',
              error: true,
              couponApplied: false,
              errorMessage: this.props.data.errorMessage,
            },
            () => {
              this.setCouponCodeFocus();
              context.props.addCallback && context.props.addCallback(response, false);
            },
          );
        });
    } else {
      return false;
    }
  };

  removeCoupon = (e) => {
    e.preventDefault();
    // this.setState({ error: false, couponApplied: false, couponValue: '' }, () => {
    this.props.removeCallback && this.props.removeCallback(true);
    // });
    // this.props.removeCallback && this.props.removeCallback(true);
    this.setCouponCodeFocus();
  };

  sanitizeCouponCode = (value) => {
    value = value.replace(new RegExp(this.props.data.regex), '');
    this.setState({ couponValue: value });
  };

  handleOnKeyUp = (e) => {
    if (e.which === KeyCodes.Enter) {
      this.applyCoupon(e);
    }
  };

  handleRemoveCoupon = (e) => {
    if (e.which === KeyCodes.Enter) {
      this.removeCoupon(e);
      this.cartCouponCode = '';
    }
  };

  removeButton = () => {
    const classes = classNames('c-coupon-code-wrapper--remove', {
      // disabled: this.memberCouponCode ? true : false,
      'coupon-active': this.state.couponValue && !this.props.isCartPaymentView,
      'c-coupon-code-payment--remove': this.props.isCartPaymentView,
    });
    const iconClasses = classNames('c-coupon-code-wrapper--icon', {
      'c-coupon-code-payment--icon': this.props.isCartPaymentView,
    });

    if (!this.expressChekoutCase && this.props.isCartPaymentView && !this.state.isCartPaymentViewExpanded) {
      if (!this.props.cartSummaryExpandedView) {
        return null;
      }
    }

    return (
      <div onClick={this.removeCoupon} className={classes} onKeyUp={this.handleRemoveCoupon} tabIndex="0">
        <div className={iconClasses}>
          <DynamicContent tagName="span" attrs={{ className: '' }} innerHtml={this.props.data.cancelLabel} />
        </div>
      </div>
    );
  };

  applyButton = () => {
    const classes = classNames('c-coupon-code-wrapper--add', {
      'coupon-active': this.state.couponValue && !this.props.isCartPaymentView,
      'c-coupon-code-payment--add': this.props.isCartPaymentView,
      disabled:
        (this.props.isCartPaymentView && !this.state.couponValue.trim() && !this.props.disableOnSeatedPaymentError) ||
        // this.memberCouponCode ||
        (this.props.showMinicartCouponCode && !this.state.couponValue.trim()),
    });

    const iconClasses = classNames('c-coupon-code-wrapper--icon', {
      'c-coupon-code-payment--icon': this.props.isCartPaymentView,
    });

    return (
      <div onClick={this.applyCoupon} className={classes} onKeyUp={this.handleOnKeyUp} tabIndex="0">
        <div className={iconClasses}>
          <DynamicContent tagName="span" attrs={{ className: '' }} innerHtml={this.props.data.applyLabel} />
        </div>
      </div>
    );
  };

  couponCodeApplied = () =>
    `<span class="bold promo-code-applied-bold">
          ${
            this.cartCouponCode && this.state.couponValue && this.state.couponApplied
              ? this.cartCouponCode
              : this.state.couponValue
          }
        </span>
        <span class="c-coupon-code-payment--add-icon">
          <span class="icon-applied" tabIndex="0"></span>
        </span>
        <span class="promo-code-applied-header">${this.props.data.successMessage}</span>
      `;

  editableCartSummaryView = () => {
    const couponInfo = [
      <span className="normal">{this.props.data.couponCodeLabel}</span>,
      <span className="bold">
        {this.cartCouponCode && this.state.couponValue && this.state.couponApplied
          ? this.cartCouponCode
          : this.state.couponValue}
      </span>,
      <span className="c-coupon-code-payment--add-icon">
        <span className="icon-applied" tabIndex="0"></span>
      </span>,
    ];

    const classes = classNames('form-element c-coupon-code-wrapper--input c-coupon-code-wrapper--input-payment', {
      implicitCoupon: this.state.hasImplicitPromotion,
    });

    if (
      this.state.isCartPaymentViewExpanded ||
      this.props.cartSummaryExpandedView ||
      this.props.showMinicartCouponCode ||
      this.expressChekoutCase
    ) {
      return (
        <div className={classes}>
          <span className="c-coupon-code-value">
            {this.cartCouponCode && this.state.couponValue && this.state.couponApplied
              ? this.cartCouponCode
              : this.state.couponValue}
          </span>
          <span className="c-coupon-code-payment--add-icon">
            <span className="icon-applied" tabIndex="0"></span>
          </span>
          {this.expressChekoutCase &&
            this.state.success &&
            this.state.successMessage &&
            !this.props.isParksExpressCheckout && (
              <span className="input-success-msg">{this.state.successMessage}</span>
            )}
        </div>
      );
    }

    return (
      <div className="c-coupon-code-payment--applied">
        {couponInfo.map((el) => el)}
        <span
          className="c-coupon-code-payment--remove-icon"
          onClick={this.removeCoupon}
          onKeyUp={this.handleRemoveCoupon}
        >
          {this.state.hasImplicitPromotion || <span className="remove-icon" tabIndex="0"></span>}
        </span>
      </div>
    );
  };

  couponCodeInput = () => {
    if (
      (this.props.isCartPaymentView && this.state.couponApplied) ||
      (this.props.showMinicartCouponCode && this.state.couponApplied)
    ) {
      return this.editableCartSummaryView();
    }

    return (
      <Input
        onChange={(e) => this.sanitizeCouponCode(e.target.value)}
        onKeyUp={this.handleOnKeyUp}
        data={{
          class:
            'c-coupon-code-wrapper--input ' +
            (this.state.couponApplied ? 'disabled ' : '') +
            (this.state.error ? 'input-error-msg' : ''),
          name: 'couponcodeinput-2step',
          type: 'Text',
          id: 'couponcodeinput',
          placeholder: this.props.data.placeholder,
          val:
            this.cartCouponCode && this.state.couponValue && this.state.couponApplied
              ? this.cartCouponCode
              : this.state.couponValue,
          showErrors: this.state.error,
          message: this.state.errorMessage,
        }}
      />
    );
  };

  handleOnClick = (e) => {
    e.preventDefault();
    this.setState({ showUsePromoCodeLink: false, isCartPaymentViewExpanded: true }, () => {
      window.PubSub.publish(UIConfig.events.ONPROMOCODEEXPANDED);
      this.setCouponCodeFocus();
    });
  };
  handleOnClickSwad = (e) => {
    e.preventDefault();
    this.setState({ expanded: !this.state.expanded });
  };

  setCouponCodeFocus = () => {
    const couponEl = document.querySelector('#couponcodeinput');
    if (couponEl) couponEl.focus();
  };

  renderUsePromoCode = () => {
    return (
      <DynamicContent
        tagName="a"
        attrs={{
          className: '',
          href: '#',
          target: '_blank',
          onClick: this.handleOnClick,
        }}
        innerHtml={this.props.data.linkLabel}
      />
    );
  };

  renderUsePromoCodeSwad = () => {
    return (
      <div className="c-coupon-code-header" onClick={(e) => this.handleOnClickSwad(e)}>
        <DynamicContent
          tagName="a"
          attrs={{
            className: 'coupon-code-title',
            tabIndex: this.expressChekoutCase && { checked: '0' },
            role: 'button',
            href: 'javascript:;',
          }}
          innerHtml={
            this.state.couponApplied && this.props.isParksExpressCheckout && !this.state.expanded
              ? this.couponCodeApplied()
              : this.props.data.linkLabel
          }
        />
        <a
          href="javascript:;"
          className="coupon-code-toggle-icon"
          {...(this.expressChekoutCase && { tabIndex: '0' })}
        ></a>
      </div>
    );
  };

  renderCouponCode = () => {
    const disabledClass = this.props.disableOnSeatedPaymentError ? 'disabled' : '';
    if (!this.expressChekoutCase && this.state.showUsePromoCodeLink) {
      return this.renderUsePromoCode();
    }

    return (
      <>
        {this.expressChekoutCase && this.renderUsePromoCodeSwad()}
        <div className={`c-coupon-code-wrapper ${disabledClass}`} role="form" ref={this.fieldFocus}>
          <div className={!this.state.userMembership.title ? 'c-coupon-code-section' : 'c-coupon-code-wrapper--left'}>
            {this.couponCodeInput()}
            {!this.state.hasImplicitPromotion && (this.state.couponApplied ? this.removeButton() : this.applyButton())}
          </div>
          {this.state.userMembership.title && (
            <div class="c-coupon-code-wrapper--right">
              <span class="c-coupon-code-wrapper--right--title">{this.state.userMembership.title}</span>
              <span class="c-coupon-code-wrapper--right--description">{this.state.userMembership.subTitle}</span>
            </div>
          )}
        </div>
      </>
    );
  };

  render() {
    try {
      return (
        <div className={classNames(this.classes, { expanded: this.expressChekoutCase && this.state.expanded })}>
          {!this.hidePromoOnTicketUpgrade && this.renderCouponCode()}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'CouponCode');
    }
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */
CouponCode.propTypes = {
  data: PropTypes.shape({
    regex: PropTypes.string,
    placeholder: PropTypes.string,
    businessErrors: PropTypes.object,
    isCartPaymentView: PropTypes.bool,
    cta: PropTypes.object,
    services: PropTypes.string,
    promoDetails: PropTypes.array,
    couponCode: PropTypes.string,
  }),
  addCallback: PropTypes.func,
  removeCallback: PropTypes.func,
};

export default CouponCode;
