import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../tooltip/tooltip-component';
import { DynamicContent, SvgSprite } from '../../base';
import { logComponentRenderingError } from '../../../../common/logger';

const TicketTooltip = ({ title, subTitle, description, parentCallback, is2StepJourney, tabIndex }) => {
  try {
    let tooltipComp = null;
    const close = () => {
      tooltipComp.closeTooltip();
    };

    const tooltipId = is2StepJourney ? 'tooltip-icon-tickets-2step' : 'tooltip-icon-tickets';

    return (
      <div>
        {(description || title || subTitle) && (
          <Tooltip
            onHover={true}
            wrapperClass="c-ticket-tooltip"
            ref={(tooltip) => (tooltipComp = tooltip)}
            callback={parentCallback}
            buttonInnerHtml={
              <div>
                <SvgSprite id={tooltipId} cssClass="tooltip-icon" />
              </div>
            }
            tabIndex={tabIndex ? '0' : null}
            ariaLabel={(title ? title : '') + (subTitle ? subTitle : '') + (description ? description : '')}
            buttonClass=""
          >
            <div className="ticket-tooltip-body">
              <div className="close-tooltip">
                <span className="close-icon" onClick={close}>
                  <SvgSprite id="close-icon" />
                </span>
              </div>
              {title && <DynamicContent tagName="div" attrs={{ className: 'title' }} innerHtml={title} />}
              {subTitle && <DynamicContent tagName="div" attrs={{ className: 'sub-title' }} innerHtml={subTitle} />}
              {description && (
                <DynamicContent tagName="div" attrs={{ className: 'description' }} innerHtml={description} />
              )}
            </div>
          </Tooltip>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'TicketTooltip');
  }
};

TicketTooltip.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
};

export default TicketTooltip;
