/*
 * loggedin-user-component.js
 * This file contains code to set logged in user data and other data required during login
 * @licensor  Miral
 */

import { setPayloads } from './session/session-utility';
import {
  getBaseURL,
  setCookie,
  getMainObject,
  isB2B,
  getLoggedInUser,
  setSessionStorage,
  resolvePath,
} from './utility';
import { Wishlist } from './wishlist-api';

export const login = () => {
  let userData = getLoggedInUser();

  const returnClientSecret = () => {
    return userData.clientSecret ? userData.renewTokenData.clientSecret.replace(/&quot;/g, '"') : '';
  };

  const setToken = () => {
    const renewTokenContentType = 'application/x-www-form-urlencoded';
    const loggedInUser = userData ? userData : {},
      renewIDTokenConfig = userData.loginInfo ? userData.loginInfo : {},
      loggingObj = {
        logObjects: [],
        logObjectCounter: 0,
        logginApiUrl: resolvePath(loggedInUser, 'additionalProperty.logginApiUrl', ''),
      };

    if (userData && userData.idToken) {
      setPayloads(userData.idToken, loggedInUser, renewIDTokenConfig);
    }
    // removing guest user for guest checkout
    localStorage.removeItem('guestUser');
    localStorage.removeItem('guestUserEmail');
    localStorage.removeItem('guestUserData');

    renewIDTokenConfig.contentType = renewTokenContentType;

    const clientSecret = returnClientSecret();
    renewIDTokenConfig.clientSecret = clientSecret ? clientSecret : '';

    localStorage.roles = userData.roles ? JSON.stringify(userData.roles) : '';

    localStorage.errors = userData.errors ? JSON.stringify(userData.errors) : '';

    localStorage.removeItem('logoutLog');
    if (!localStorage.logging) {
      localStorage.logging = JSON.stringify(loggingObj);
    }

    window.PubSub.publishSync('userLoggedInSuccesfully', {
      userData: loggedInUser,
    });

    localStorage.removeItem('logoutLog');
    if (localStorage.logoutLoopCount) {
      localStorage.removeItem('logoutLoopCount');
    }

    if (isB2B()) {
      sessionStorage.setItem('isFirstLogIN', true);
    }

    const mainObj = getMainObject();
    if (mainObj && mainObj.additionalProperty && mainObj.additionalProperty.wishlistUrl) {
      Wishlist.GetWishlist('wishlist')
        .then(() => {
          setCookie('wishlistID', '', '', getBaseURL());
          setSessionStorage('isSessionInit', 1);
        })
        .catch((err) => err);
    }
  };

  setToken();
};
