/*
 * payment-component.js
 * @author SapientRazorfish
 * @licensor Miral
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { Helmet } from 'react-helmet';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  getLoggedInUser,
  isAnnualPassCase,
  isApplePayAvailable,
  parseQueryString,
  getLoginUser,
  isEmpty,
  getAnonymousCart,
  getSessionStorage,
  deepCloneObject,
  detectMobile,
  viewPortWidthCheck,
  getUrlHashArr,
  openIAMOverlay,
  checkInlineSignup,
  checkYasIdExist,
  removeADCBTouchPointsStorage,
  removeMoneyCardStorage,
  getMainObject,
  setLocalStorage,
  isLoggedInUser,
  checkTenant,
  removeLoaderOverlay,
  getCookie,
  setSessionStorage,
  isMatchTenant,
  resolvePath,
  checkIfParks,
  getLanguageWithoutLocal,
  isParksExpressCheckoutTenant,
  getEmailTemplateId,
  keyCodeObject,
  isTicketUpgradePayment,
} from '../../../../common/utility';
import IAMPopup from '../../../presentation/iam-popup/iam-popup';
import { AnchorLink, Carousel, CollapsedView, DynamicContent, ErrorSummary, Image } from '../../../presentation/base';
import ErrorOverlay from '../../../presentation/error-overlay/error-overlay-component';
import PayFort from '../../payment/payfort/payfort-component';
import { ApplePay } from '../../payment/applepay/applepay-component';
import { GPay } from '../../payment/googlepay/gpay-component';
import { setCreditSettlementData, setAdvanceTopupData } from '../../../../common/b2b-purchase-journey-utility';
import AliPay from '../../payment/alipay/alipay-component';
import PointsPay from '../../payment/pointspay';
import PostPay from '../../payment/postpay';
import PaymentCheckboxes from '../../payment/base/payment-checkbox/payment-checkbox';
import B2BPaymentCheckbox from '../../payment/base/payment-checkbox/b2b-payment-tnc-checkbox';
import ADCBTouchPoints from '../../payment/adcb-touchpoints';
import MoneyCard from '../../payment/moneycard';
import { logComponentRenderingError, Logging } from '../../../../common/logger';
import { guestFormError } from '../../../../common/payment-action/actions';

import * as helper from './helper';
import * as serviceHelper from './service-helper';
import Paypal from '../../payment/paypal/paypal';
import { AnnualPassData } from '../../../../common/services';
import { checkForCartGlobalErrors, checkForInvalidQuantityProducts } from '../cart/helper';
import { isParkTenant } from '../../../../common/utility/tenantsUtils';

export default class PaymentComponent extends Component {
  constructor(props) {
    super(props);
    this.isLoggedInUser = isLoggedInUser();
    this.setPayPalData = this.setPayPalData.bind(this);
    this.isErrorOccured = this.isErrorOccured.bind(this);
    this.openOverlay = this.openOverlay.bind(this);
    this.cartData = this.cartData.bind(this);
    this.initializingB2BClassVariables();
    this.paymentWrapperRef = createRef();
    this.tabKeydownAttached = false;
    this.mainObj = canUseDOM() && localStorage.mainObj && getMainObject();
    this.enableYasIDVersion =
      this.mainObj && this.mainObj.additionalProperty ? this.mainObj.additionalProperty.enableYasIDVersion : false;
    this.isYMCTenant =
      this.mainObj && this.mainObj.tenantID && this.mainObj.tenantID.toLowerCase() === UIConfig.ymcB2CTenant;
    this.isPPADTenant =
      this.mainObj && this.mainObj.tenantID && this.mainObj.tenantID.toLowerCase() === UIConfig.tenants.ppad;

    this.isYAB2C = this.mainObj && this.mainObj.tenantID && this.mainObj.tenantID.toLowerCase() === UIConfig.tenants.ya;
    // default OPtion selected is the item which contains 'expandPaymentOption' as true this is coming from CMS
    this.defaultOptionSelected =
      props.data.paymentOptions && props.data.paymentOptions.paymentTypes && props.data.paymentOptions.paymentTypes[0];
    this.confirmedEmail = '';
    this.enablePaymentOptions = this.props.data.paymentOptions.enablePaymentOptions;
    this.isOnlyLoginEnabled = this.props.data.paymentOptions.isOnlyLoginEnabled;
    this.paymentMethod = this.defaultOptionSelected;
    this.is2StepPayment = this.props.data.paymentOptions.variant === 'v-Payment-2Step';
    this.isPaymentVerticalView = this.props.data.paymentOptions.enablePaymentVerticalView;
    this.isRenewAnnualPass = !!(this.props.data.paymentOptions.pageVariant === 'annualPassRenew');
    this.is2StepPaymentYasArena = false;
    this.disableAuthorPaymentEA = this.isYAB2C && this.props.data.disableAuthorPayment;
    this.isScrolledToError = false;
    this.isMobile = detectMobile();
    this.pointsPayMethods = [UIConfig.paymentOption.pointsPayEtihad, UIConfig.paymentOption.pointsPayEtisalat];
    this.hidePayCTABtn = [
      ...this.pointsPayMethods,
      UIConfig.paymentOption.applePay,
      UIConfig.paymentOption.gPay,
      UIConfig.paymentOption.paypal,
    ];
    this.setPaymentJourneyModes(this.props.data.paymentOptions.variant);
    const applePayOption = props.data.paymentOptions.paymentTypes.find(
      (item) => item.value === UIConfig.paymentOption.applePay,
    );
    this.scriptTabby = null;
    this.TabbyPromoInstance = null;
    if (this.is2StepPayment && applePayOption) {
      // if: When payment journey is two step and Apple Pay is not available not show apple pay option
      // else : when apple pay is available - it should be the default payment option selected
      if (!isApplePayAvailable()) {
        this.setApplePayVisibility(this.props.data.paymentOptions);
      }
    }

    this.state = {
      disabledInlineSignUp: false,
      isTncChecked: false,
      isErrorExist: false,
      isPaymentDisabledDuetoCartQuantity: false,
      maxCartQuantity: 0,
      newsLetterEnabled: false,
      parkConsentEnabled: false,
      // this Property will define if the first option is collapsed or expanded
      isFirstPaymentExpanded: detectMobile() ? this.props.data.paymentOptions.expandPaymentOption : true,
      currentOption: this.defaultOptionSelected.value,
      proceedToPay:
        this.defaultOptionSelected.value !== UIConfig.paymentOption.paypal ||
        this.defaultOptionSelected.value !== UIConfig.paymentOption.applePay ||
        this.defaultOptionSelected.value !== UIConfig.paymentOption.aliPay ||
        this.defaultOptionSelected.value !== UIConfig.paymentOption.gPay,
      errorData: null,
      isEnabled: false,
      cart: null,
      srcPayfort: '',
      disableBookButton: false,
      addDisableCheck: false,
      hidePic: false,
      isCardDetailsNotValid: false,
      ispaypalError: false,
      paymentError: '',
      postpayPaymentError: '',
      tabbyPaymentError: '',
      hasTnCError: false,
      showCheckBox:
        this.is2StepPayment &&
        (this.defaultOptionSelected.value === UIConfig.paymentOption.paypal ||
          this.defaultOptionSelected.value === UIConfig.paymentOption.applePay ||
          this.defaultOptionSelected.value === UIConfig.paymentOption.aliPay ||
          this.defaultOptionSelected.value === UIConfig.paymentOption.gPay)
          ? true
          : !this.is2StepPayment
          ? true
          : false,
      enablePaymentButton: true,
      applePayData: {},
      GPayData: {},
      isPaymentMethodBlocked: false,
      blockedErrorMessage: '',
      applePayReconcilationData: {},
      GPayReconcilationData: {},
      hideNewsletterOption: false,
      B2BPaymentEnable: this.props.data.paymentOptions.pageVariant === 'b2bCart' ? true : false,
      isLowCredit: false,
      isPaymentSectionEnabled: false,
      loggedInUser: false,
      isUserPartner: false,
      isPaymentMethodSwitched: false,
      tabIndex: '0',
      isCCWrapperRef: false,
      usePayfortWithADCB: false,
      usePayfortWithMoneyCard: false,
      cartError: [],
      paypalErrorMessage: {},
    };
    this.payData = {
      total: 0,
      currency: '',
      emailTemplateId: '',
      marketType: '',
      thankYouPageUrl: '',
      conversionRate: 0,
      paymentType: '',
      dueDate: '',
      agentRefNum: '',
      invoiceTemplateId: '',
      promoCodes: [],
    };
    this.thankYouPageUrl = this.props.data.paymentOptions.primaryCTA.url;
    this.emailTemplateId = getEmailTemplateId(
      this.props.data.paymentOptions.emailTemplateId,
      this.props.data.paymentOptions.anonymousEmailTemplateId,
    );
    this.invoiceTemplateId = this.props.data.paymentOptions.invoiceTemplateId;
    this.isPayPalBtnLoaded = true;
    this.services = this.props.data.services;
    this.marketType = ' ';
    this.partnerReference = '';
    this.dueDate = ' ';
    this.paymentOptions = this.props.data.paymentOptions;
    this.waitForGustDtl = false;
    this.onPayPalButtonSubmitSuccess = false;
    this.onApplePayButtonSubmitSuccess = false;
    this.onGPayButtonSubmitSuccess = false;
    this.cart = {};
    this.ccOptionWrapper = null;
    this.cartData();
    if (
      UIConfig.payfort.payfortTypes.find((item) => item === this.defaultOptionSelected.value) ||
      this.defaultOptionSelected.value === UIConfig.paymentOption.adcbTouchPoints
    ) {
      this.payFortData(this.defaultOptionSelected.value);
    }
    this.hasBeenScrolledToError = false;
    this.aliPayCmsData = {};
    this.slickSettings = {
      slickTabs: true,
      arrows: false,
      infinite: false,
      slidesToShow: 3.5,
      autoplay: false,
      slidesToScroll: 1,
      dots: false,
      variableWidth: false,
      useCSS: false,
      useTransform: false,
      waitForAnimate: false,
      responsive: [
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 3.5,
          },
        },
      ],
      initialSlide: this.paymentOptions.paymentTypes.findIndex((val) => val.value === this.defaultOptionSelected.value),
    };
    // annual pass and 2step journey is true.
    this.isAnnualPass = false;

    // last order details for seated journey
    this.seatedLastOrderData = null;
    this.hasADCBProductInCart = false;
    this.paymentTypesCopy = deepCloneObject(props.data.paymentOptions.paymentTypes);
    this.paymentMethodNotBlocked = [
      UIConfig.paymentOption.creditCard,
      UIConfig.paymentOption.tabby,
      UIConfig.paymentOption.adcbTouchPoints,
    ];
    this.annualPassCartData = null;
    this.isParksExpressCheckout = isParksExpressCheckoutTenant();
  }

  setPaymentJourneyModes = (variant) => helper.setPaymentJourneyModes(this, variant);

  setApplePayVisibility = () => helper.setApplePayVisibility(this);

  cartData = () => helper.cartData(this);

  initializingB2BClassVariables = () => helper.initializingB2BClassVariables(this);

  b2bValidateDataSettlement = (data) => helper.b2bValidateDataSettlement(this, data);

  cartEveCallBack = (msg, data) => {
    this.cart = data;
    if (this.isB2B) {
      this.total = data.cart.totalPrice;
      this.orderID = data.cart.orderID;
      this.emailTemplateId = data.cart.emailTemplateId;
      this.marketType = data.cart.marketType;
      this.reservationCode = data.cart.reservationCode;
      this.dueDate = data.cart.dueDate;
      this.partnerReference = data.cart.agentRefNum;
      this.payData.marketType = data.cart.marketType;
      this.setState({ cart: data }, () => this.payFortData());
    }
    this.cartAvailable = true;
  };

  isPromoEnable = (paymentOptions, paymentMethod) =>
    paymentOptions.paymentTypes?.find((item) => item.value.toLowerCase() === paymentMethod.toLowerCase())
      ?.enableInstallmentOverlay;

  /* Method is used for B2B in Credit Settlement page */
  creditSettlementCallBack = (msg, data) => {
    this.cart = data;
    this.setState({ cart: data });
    this.cartAvailable = true;
    this.B2B_reconcileCSAPData = data.reconcilePayloadData;
    this.b2bValidateDataSettlement(data.reconcilePayloadData.length);
  };

  /* Method is used for B2B in Credit Settlement page */
  advanceTopUpCallBack = (msg, data) => {
    this.cart = data;
    this.setState({ cart: data });
    this.cartAvailable = true;
    const cartAmount = parseFloat(data.cart.amount) === 0 ? 0 : data.cart.amount;
    this.b2bValidateDataSettlement(cartAmount);
  };

  enableGuestPayment = () => helper.enableGuestPayment(this);

  checkPaymentOnOff = () => helper.checkPaymentOnOff(this);
  scrollToStepTwoError = () => {
    const step2Err = document.querySelector('.body-copy-6.payment-error');
    const topHeaderHeight = document.querySelector('.c-header-wrapper')?.offsetHeight ?? 0;
    const miniCartHeight = document.querySelector('.c-b2c-cart-header')?.offsetHeight ?? 0;
    if (step2Err) {
      step2Err.style.scrollMarginTop = `${topHeaderHeight + miniCartHeight + 5}px`;
      step2Err.scrollIntoView({ behavior: 'smooth' });
      this.isScrolledToError = true;
    }
  };

  componentDidMount = () => {
    if (isLoggedInUser()) localStorage.removeItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED);
    if (this.paymentOptions && this.paymentOptions.paymentProcessingMessages) {
      setLocalStorage('paymentProcessingMessages', JSON.stringify(this.paymentOptions.paymentProcessingMessages));
    }
    //to handle refresh issue on payment page for FWAD
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    const activeSlideTab = getUrlHashArr();
    window.localStorage.setItem(UIConfig.localStoreKeys.purchaseJourney.orderStatus, false);
    window.PubSub.subscribe(UIConfig.events.CHECK_PAYMENT_ON_OFF, this.checkPaymentOnOff);
    window.PubSub.subscribe(UIConfig.events.DISABLE_INLINE_SIGNUP, (msg, enabledData) => {
      if (!enabledData.enabled) {
        this.setState({
          disabledInlineSignUp: true,
        });
      } else
        this.setState({
          disabledInlineSignUp: false,
        });
    });
    removeADCBTouchPointsStorage();
    removeMoneyCardStorage();

    if (this.isB2B) {
      this.getCredits();
      window.PubSub.subscribe('input_filled', (item, obj) => {
        this.partnerReference = obj.partnerInputField;
        this.dueDate = obj.dueDate;
      });
      if (getLoggedInUser().userType === 'Primary Contact') {
        this.setState({ isUserPartner: true });
      }
    }
    window.PubSub.subscribe(UIConfig.events.MAX_CART_QUANTITY, (msg, maxCartQuantity) => {
      this.setState({
        maxCartQuantity,
      });
    });
    window.PubSub.subscribe('handleCouponError', this.blockPaymentMethod);
    if (this.is2StepPayment) {
      window.PubSub.subscribe(UIConfig.events.ONCARTUPDATE, (msg, data) => {
        this.setState(
          {
            cart: data.cart,
            enablePaymentButton: data.enablePaymentButton,
            applePayData: data.applePayData,
            GPayData: data.GPayData,
          },
          () => {
            this.disablePaymentSection(data?.cart?.items);
            this.checkPaymentMethodValidity(data.cart.couponCode, this.state.currentOption);
            if (this.checkForSeatedOrderId()) {
              window.PubSub.publish(UIConfig.b2c.purchaseJourney.ymcMapping.pubSubKey.seatedPaymentError);
            }
          },
        );
      });

      this.getGuestCheckoutSuccess();
      const paymentError = parseQueryString(UIConfig.querStringParams.payfort.paymentError);

      if (this.isLoggedInUser) {
        this.setState({
          //hideNewsletterOption: true,
          loggedInUser: true,
        });
      } else {
        this.guestUserHasErrors();
        window.PubSub.subscribe('checkPayButtonStatus', this.guestUserHasErrors);
      }
      window.PubSub.subscribe('recipientdetailsHasError', this.checkGuestFormError);

      if (paymentError) {
        this.setState({
          isCardDetailsNotValid: true,
          paymentError: paymentError,
          currentOption:
            UIConfig.payfort.payfortTypes.find((item) => item === this.state.currentOption) ??
            UIConfig.paymentOption.creditCard,
          showCheckBox: false,
          yasIdExist: this.isParksExpressCheckout ? !this.isLoggedInUser && checkYasIdExist() : checkYasIdExist(),
        });
      }
      window.PubSub.subscribe(UIConfig.events.ON_ADDING_PROMOCODE, (msg, data) => {
        this.checkPaymentMethodValidity(data, this.state.currentOption);
      });
      window.PubSub.subscribe(UIConfig.events.ON_REMOVING_PROMOCODE, this.removePaymentInvalidMsg);
      // should only run when default Payment Option is Paypal
      if (this.defaultOptionSelected.value === UIConfig.paymentOption.paypal) {
        this.setPayPalData();
      }
      this.isAnnualPass = isAnnualPassCase();
      if (this.is2StepPayment) {
        window.PubSub.subscribe(UIConfig.events.ENABLE_PAYMENT, () => {
          this.isAnnualPass = isAnnualPassCase();
          sessionStorage.setItem('annualPassFormSuccess', true);
          this.disabledPaymentFrom();
        });

        // #region If purchase journey is hotel only flow then disable the payment on first load until user adds promocode or giftvoucher

        let _packageSummaryData = getSessionStorage('packageSummaryData');
        if (_packageSummaryData) {
          _packageSummaryData = JSON.parse(_packageSummaryData);
        }

        _packageSummaryData?.Brand === UIConfig.b2c.flowType.shortName.hotelonly &&
          this.setState({ isPaymentSectionEnabled: true });

        window.PubSub.subscribe(UIConfig.events.DISABLE_PAYMENT, (msg, isPaymentDisabled) => {
          this.setState({ isPaymentSectionEnabled: isPaymentDisabled });
        });

        // #endregion
      }
      if (this.isB2B) {
        window.PubSub.subscribe('isLowCredit', (msg, data) => {
          this.setState({ isLowCredit: data.isLowCredit });
        });
      }
      if (activeSlideTab && activeSlideTab.length) {
        let currentActiveTabValue = '';
        this.paymentOptions &&
          this.paymentOptions.paymentTypes.forEach((item) => {
            if (!currentActiveTabValue && activeSlideTab.includes(item.value)) {
              currentActiveTabValue = item.value;
            }
          });
        currentActiveTabValue && this.onOptionChangeHandler('', currentActiveTabValue, true, paymentError);
      }
    }
    if (this.isRenewAnnualPass) {
      this.paymentOptions.paymentURL.url =
        window.location.pathname + (window.location.hash ? window.location.hash : '');
      this.paymentOptions.paymentURL.href =
        window.location.pathname + (window.location.hash ? window.location.hash : '');
    }
    window.PubSub.subscribe(UIConfig.events.CART_DATA_WITH_PHOTO, (msg, data) => {
      if (data && data.cartData) {
        this.annualPassCartData = data.cartData;
      }
    });

    this.emailInvoiceTemplatesId = {
      emailTemplateId: this.emailTemplateId,
      invoiceTemplateId: this.invoiceTemplateId ? this.invoiceTemplateId : ' ',
    };

    localStorage.setItem(UIConfig.localStoreKeys.emailInvoiceTemplateId, JSON.stringify(this.emailInvoiceTemplatesId));
    window.PubSub.subscribe(UIConfig.annualPassSavedChanges, (msg, data) => {
      this.ccOptionWrapper && this.ccOptionWrapper.scrollIntoView({ behavior: 'smooth' });
    });
    window.PubSub.subscribe(UIConfig.cartErrorCodesForPayment, (msg, data) => {
      this.setState({ cartError: data.errorCode });
    });
    const paymentErrorcode = parseQueryString(UIConfig.querStringParams.paymentErrorQuery.paymentError);
    const paymentErrortype = parseQueryString(UIConfig.querStringParams.paymentErrorQuery.paymentType);
    if (
      paymentErrorcode === UIConfig.errorCodes.paymentProcessed ||
      paymentErrorcode === UIConfig.errorCodes.paymentProgress
    ) {
      this.paymentErrorOverlay(paymentErrorcode, paymentErrortype);
    }
    const customerIpLocation = getSessionStorage('customerIpLocation') || '';
    if (!customerIpLocation) {
      this._getIPLocation();
    }

    window.PubSub.subscribe(UIConfig.events.EXPRESS_CHECKOUT_ENABLED, (msg, expressCheckoutEnabled) => {
      if (expressCheckoutEnabled)
        this.setState({
          expressCheckoutEnabled: true,
        });
    });
    window.PubSub.subscribe('confirmEmailFire', (msg, data) => {
      sessionStorage.setItem('confirmedEmail', data?.contactEmail);
      // let emailMessageToShow = this.props?.data?.paymentOptions?.paymentLabels?.emailConfirmMessage?.replace("{email}" , data?.contactEmail)
      this.setState({
        confirmedEmail: data?.contactEmail,
      });
    });
    window.PubSub.subscribe('expressCheckoutAnnualPass', (msg, expressCheckoutAnnualPass) => {
      if (expressCheckoutAnnualPass)
        this.setState({
          expressCheckoutAnnualPass: true,
        });
    });
  };

  handleTabKeyDown = (e) => {
    if (e.keyCode == keyCodeObject.TAB && this.state.isPaymentDisabledDuetoCartQuantity) {
      e.preventDefault();
    }
  };

  disablePaymentSection = (items) => {
    const isGlobalQuantityError = checkForCartGlobalErrors(items, this.state.maxCartQuantity);
    const isProductQuantityError = checkForInvalidQuantityProducts(items);
    const isPaymentDisabledDuetoCartQuantity = isGlobalQuantityError || isProductQuantityError || false;
    this.setState({ isPaymentDisabledDuetoCartQuantity }, () => {
      if (this.paymentWrapperRef.current && !this.tabKeydownAttached && isPaymentDisabledDuetoCartQuantity) {
        this.paymentWrapperRef.current.addEventListener('keydown', this.handleTabKeyDown);
        this.tabKeydownAttached = true;
      } else if (this.paymentWrapperRef.current && this.tabKeydownAttached && !isPaymentDisabledDuetoCartQuantity) {
        this.paymentWrapperRef.current.removeEventListener('keydown', this.handleTabKeyDown);
        this.tabKeydownAttached = false;
      }
      this.openCartIfPaymentDisabled();
    });
  };

  openCartIfPaymentDisabled = () => {
    if (this.state.isPaymentDisabledDuetoCartQuantity) {
      const chevronElem = document.querySelector('.chevronDown');
      const isCartExpanded = document.querySelector('.c-b2c-cart-content.c-b2c-cart-payment.express-cart-expanded');
      if (chevronElem && !isCartExpanded) {
        chevronElem.click();
      }
    }
  };

  _getIPLocation = () => {
    AnnualPassData.getGeoIP()
      .then((res) => {
        const { ipAddress } = res.data;
        const ipLoc = ipAddress || '';
        setSessionStorage('customerIpLocation', ipLoc);
      })
      .catch((err) => {
        Logging(err, 'GEO Location API failed');
        return '';
      });
  };

  initializePromotionPopup = () => {
    this.TabbyPromoInstance = new window.TabbyPromo({
      selector: '#tabby-learn-more',
      price: this.state.cart?.grossPrice,
      currency: this.props.data.currencyCode,
      lang: getLanguageWithoutLocal(),
      source: UIConfig.tabby.promo.CART,
    });
  };

  handleScriptInject = ({ scriptTags }) => {
    if (!this.TabbyPromoInstance) {
      if (scriptTags) {
        const scriptTag = scriptTags[0];
        scriptTag.onload = this.initializePromotionPopup;
      }
    } else {
      this.TabbyPromoInstance = null;
      this.initializePromotionPopup();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentOption !== prevState.currentOption) {
      this.isSwad && !detectMobile() && window.PubSub.publish(UIConfig.hiddenPayment, { isPaymentMethodChanged: true });
    }
    if (this.isParksExpressCheckout && !this.isScrolledToError && parseQueryString('payment_error') && detectMobile()) {
      this.scrollToStepTwoError();
    }
  }

  componentWillUnmount() {
    if (this.tabKeydownAttached) {
      this.paymentWrapperRef.current.removeEventListener('keydown', this.handleTabKeyDown);
    }
  }

  paymentErrorOverlay = (payErr, payType) => {
    let cookieEmail = JSON.parse(getCookie('UserProfile')).email;

    const { bodyCopy, primaryCTA, overlayIcon } = this.props?.data?.doublePaymentOverlay;

    const bodyText = bodyCopy && bodyCopy.replace('{email}', cookieEmail);
    const doublePaymentCTA = primaryCTA || '';
    const alertIcon = overlayIcon || {};

    const message = parseQueryString('message');

    const buttonDetails = {
      primaryCTA: `${doublePaymentCTA}`,
      primaryCallback: () => (window.location.href = '/'),
    };
    let defaultErrMessage = {
      title: `${message}`,
      bodyCopy: `${bodyText}`,
      ...buttonDetails,
      alertIcon,
    };
    if (payErr === UIConfig.errorCodes.paymentProgress) {
      defaultErrMessage = {
        title: `${message}`,
        bodyCopy: `${bodyText}`,
        ...buttonDetails,
        alertIcon,
      };
    } else if (payErr === UIConfig.errorCodes.paymentProcessed && payType === 'CloseOrder') {
      defaultErrMessage = {
        title: `${message}`,
        bodyCopy: `${bodyText}`,
        ...buttonDetails,
        alertIcon,
      };
    }
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: true,
      dataToAppend: <ErrorOverlay {...defaultErrMessage} />,
      customClass: 'c-error-overlay double-payment-overlay',
    });
  };

  getCredits = () => serviceHelper.getCredits(this);

  checkGuestFormError = (msg, data) => {
    const isError =
      Object.keys(data).length > 0 && data.hasOwnProperty('isRecipientError')
        ? data.isRecipientError
        : !this.isLoggedInUser
        ? guestFormError(data, this.props.isEnabled)
        : false;
    this.setState({
      isErrorExist: isError,
    });
  };

  checkPaymentMethodValidity = (data, currentOption) => helper.checkPaymentMethodValidity(this, data, currentOption);
  removePaymentInvalidMsg = () => this.setState({ isPaymentMethodBlocked: false });

  setPayPalData = () => helper.setPayPalData(this);

  openOverlay = (title, description) => {
    if (canUseDOM()) {
      window.PubSub.publish('toggleOverlayState', {
        shouldOpen: true,
        dataToAppend: <ErrorOverlay title={title} bodyCopy={description} />,
        customClass: 'payment-b2c-tnc-overlay',
      });
    }
  };

  showPaypalError = (error) => {
    this.setState(
      {
        paypalErrorMessage: { err: error },
        ispaypalError: true,
      },
      () => {
        removeLoaderOverlay();
      },
    );
  };

  getGuestCheckoutSuccess = () => helper.getGuestCheckoutSuccess(this);

  guestUserHasErrors = () => helper.guestUserHasErrors(this);

  onTncChangeHandler = (e) => serviceHelper.onTncChangeHandler(this, e);

  onNewsLetterChangeHandler = (e) => this.setState({ newsLetterEnabled: !this.state.newsLetterEnabled });

  onParkConsentChangeHandler = (e) => {
    this.setState({ parkConsentEnabled: !this.state.parkConsentEnabled });
    sessionStorage.setItem('isMultiParkConsent', e.target.checked);
  };

  handleScrollOnPaymentSelection = () => helper.handleScrollOnPaymentSelection(this);

  onOptionChangeHandler = (e, selectedValue, blockPaymentCheckValidity, paymentHasError) => {
    sessionStorage.setItem('isMultiParkConsent', false);
    this.setState({ parkConsentEnabled: false });
    helper.onOptionChangeHandler(this, e, selectedValue, blockPaymentCheckValidity, paymentHasError);
  };

  onSubmitForApproval = (e) => serviceHelper.onSubmitForApproval(this, e);

  onPayWithCredit = (e) => serviceHelper.onPayWithCredit(this, e);

  isErrorOccured = (key, error, status, postpayLimit) => helper.isErrorOccured(this, key, error, status, postpayLimit);

  getGuestUserStatus = () => !!getAnonymousCart();

  payFortData = (payfortType) => helper.payFortData(this, payfortType);

  setOrderId = (value) => localStorage.setItem('orderId', value);

  checkPointsPayMethod = (val) => (val ? this.pointsPayMethods.includes(val) : false);

  getPointsPayConfig = () => helper.getPointsPayConfig(this);
  getPostPayConfig = () => helper.getPostPayConfig(this);

  setRedirectPaymentData = () => serviceHelper.setRedirectPaymentData(this);

  checkForSeatedOrderId = () => helper.checkForSeatedOrderId(this);

  yaCanCreateOrder = () => helper.yaCanCreateOrder(this);

  setPayfortData = () => serviceHelper.setPayfortData(this);

  saveInvoiceDetails = () => helper.saveInvoiceDetails(this);

  redirectToThankyou = (status, orderID, mode) => helper.redirectToThankyou(this, status, orderID, mode);

  onPayment = (e) => helper.onPayment(this, e);

  renderPaymentOptions = (item, i, paypalFormClassWrapper) => {
    const getPaymentItemClasses = (paymentItem) => {
      const { accCredit, submitApproval } = UIConfig.paymentOption;
      const { enable, value } = paymentItem;
      const { currentOption, isLowCredit, isUserPartner } = this.state;
      const defaultClasses = `c-payment-items ${paymentItem.value}`;

      const payItemClasses = classNames(defaultClasses, {
        disabled: !enable,
        'selected-mode': currentOption === value && this.state.isFirstPaymentExpanded,
        'is-fund-low': isLowCredit && value === accCredit,
        'is-partner': isUserPartner && value === submitApproval,
      });
      return payItemClasses;
    };
    return this.is2StepPayment && this.enablePaymentOptions && this.isPaymentVerticalView ? (
      // eslint-disable-next-line jsx-a11y/role-supports-aria-props
      <li
        key={item.id}
        data-index={i}
        data-value={item.value}
        title={item.label}
        onKeyDown={(e) => {
          this.handleKeyDown(e, item.value);
        }}
        aria-selected={this.state.currentOption === item.value && this.state.isFirstPaymentExpanded}
        className={getPaymentItemClasses(item)}
      >
        <div
          className="payment-items-container-label"
          tabIndex={
            this.getPaymentDisabledStatus(this.state?.cartError, this.props?.data?.errorCodeToDisablePayments)
              ? '-1'
              : this.state.tabIndex
          }
          onClick={(e) => {
            e.stopPropagation();
            if (this.isMobile) {
              helper.handleScrollOnPaymentSelection(e);
            }
          }}
        >
          <input
            type="radio"
            name="paymentOptions"
            id={item.id}
            onChange={(e) => {
              this.onOptionChangeHandler(e, item.value);
              this.setState({ ispaypalError: false });
            }}
            value={item.value}
            checked={this.state.currentOption === item.value && this.state.isFirstPaymentExpanded ? true : false}
            {...(this.isSwad && { tabIndex: '-1' })}
          />{' '}
          <DynamicContent
            tagName="label"
            innerHtml={item.label}
            attrs={{ className: 'c-payment-items-text body-3', htmlFor: item.id }}
          />
          {item.cardImage && (
            <div className="c-payment-items-image">
              <Image image={item.cardImage} />
            </div>
          )}
        </div>
        {this.isMobile && (
          <>
            {this.state.showCheckBox && this.state.currentOption === item.value && this.state.isFirstPaymentExpanded && (
              <div className={`${paypalFormClassWrapper} ${this.hasADCBProductInCart ? 'disabled' : ''}`}>
                {
                  <PaymentCheckboxes
                    paymentOptions={this.paymentOptions}
                    openOverlay={this.openOverlay}
                    onTncChangeHandler={this.onTncChangeHandler}
                    onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                    onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                    cartData={this?.state?.cart}
                    key="terms-condition-1"
                    showParkConsentCheckbox
                    stateData={{
                      showCheckBox: this.state.showCheckBox,
                      hideNewsletterOption: this.state.hideNewsletterOption,
                      disableBookButton: this.state.disableBookButton,
                      hasTnCError: this.state.hasTnCError,
                      tabIndex: this.state.tabIndex,
                      isTncChecked: this.state.isTncChecked,
                      newsLetterEnabled: this.state.newsLetterEnabled,
                      parkConsentEnabled: this.state.parkConsentEnabled,
                      bindCheckBoxError: true,
                    }}
                  />
                }
                {this.hidePayCTABtn.indexOf(this.state.currentOption) === -1 && this.renderProceedToPayButton()}
              </div>
            )}
            {this.is2StepPayment &&
              this.state.currentOption === UIConfig.paymentOption.accCredit &&
              this.state.currentOption === item.value &&
              this.state.isFirstPaymentExpanded &&
              this.isB2B &&
              this.state.proceedToPay && (
                <B2BPaymentCheckbox
                  paymentOptions={this.paymentOptions}
                  onTncChangeHandler={this.onTncChangeHandler}
                  onClickHandler={this.onPayWithCredit}
                  stateData={{
                    proceedToPay: this.state.proceedToPay,
                    isTncChecked: this.state.isTncChecked,
                  }}
                />
              )}
            {this.is2StepPayment &&
              this.state.currentOption === UIConfig.paymentOption.submitApproval &&
              this.state.currentOption === item.value &&
              this.state.isFirstPaymentExpanded &&
              this.isB2B &&
              this.state.proceedToPay && (
                <B2BPaymentCheckbox
                  paymentOptions={this.paymentOptions}
                  onTncChangeHandler={this.onTncChangeHandler}
                  onClickHandler={this.onSubmitForApproval}
                  stateData={{
                    proceedToPay: this.state.proceedToPay,
                    isTncChecked: this.state.isTncChecked,
                  }}
                />
              )}
            {this.is2StepPayment &&
              ((UIConfig.payfort.payfortTypes.find((item) => item === this.state.currentOption) &&
                this.state.isFirstPaymentExpanded) ||
                this.state.usePayfortWithADCB ||
                this.state.usePayfortWithMoneyCard) &&
              this.state.currentOption === item.value &&
              this.state.isFirstPaymentExpanded &&
              this.state.proceedToPay &&
              this.renderPayfortComponent()}
            {this.state.currentOption === UIConfig.paymentOption.adcbTouchPoints &&
              this.state.isFirstPaymentExpanded &&
              this.state.currentOption === item.value &&
              this.state.proceedToPay && (
                <ADCBTouchPoints
                  is2StepPayment={this.is2StepPayment}
                  propsData={this.props.data}
                  payData={this.payData}
                  enablePaymentButton={this.state.enablePaymentButton}
                  cartData={this.state.cart}
                  hideNewsLetter={this.state.hideNewsletterOption}
                  isTabIndex={this.state.tabIndex}
                  hasADCBProductInCart={this.hasADCBProductInCart}
                  showTnCError={this.showTnCError}
                  guestUserHasErrors={this.guestUserHasErrors}
                  isEnabled={this.state.isTncChecked}
                  showCheckBox={true}
                  onTncChangeHandler={this.onTncChangeHandler}
                  isErrorOccured={this.isErrorOccured}
                  setRedirectPaymentData={this.setRedirectPaymentData}
                  usePayFortForTouchPointsPayment={this.usePayFortForTouchPointsPayment}
                  resetPaymentError={this.resetPaymentError}
                  isRenewAnnualPass={this.isRenewAnnualPass}
                  annualPassCartData={this.annualPassCartData}
                  errorData={this.state.errorData}
                  disableBookButton={this.state.disableBookButton}
                  hasTnCError={this.state.hasTnCError}
                  emailConfirmationText={this.emailConfirmationText()}
                  onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                  onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                  parkConsentEnabled={this.state.parkConsentEnabled}
                  newsLetterEnabled={this.state.newsLetterEnabled}
                />
              )}
            {this.state.proceedToPay &&
            this.is2StepPayment &&
            this.state.currentOption === UIConfig.paymentOption.aliPay &&
            this.state.isFirstPaymentExpanded &&
            this.state.currentOption === item.value ? (
              <AliPay
                is2StepPayment={this.is2StepPayment}
                cartData={this.state.cart}
                paymentOptions={this.paymentOptions}
                services={this.props.data.services}
                onPayment={this.onPayment}
                showTnCError={this.showTnCError}
                isEnabled={this.state.isTncChecked}
                setRedirectPaymentData={this.setRedirectPaymentData}
                guestUserHasErrors={this.guestUserHasErrors}
                isPaymentMethodSwitched={this.isPaymentMethodSwitched}
                isTabIndex={this.state.tabIndex}
                currentOption={this.state.currentOption}
                isErrorOccured={this.isErrorOccured}
                hasADCBProductInCart={this.hasADCBProductInCart}
              />
            ) : (
              ''
            )}
            {this.state.proceedToPay &&
              this.state.currentOption === item.value &&
              this.state.isFirstPaymentExpanded &&
              this.checkPointsPayMethod(this.state.currentOption) && (
                <PointsPay
                  cartData={this.state.cart}
                  services={this.props.data.services}
                  isEnabled={this.state.isTncChecked}
                  showTnCError={this.showTnCError}
                  openOverlay={this.openOverlay}
                  onTncChangeHandler={this.onTncChangeHandler}
                  is2StepPayment={this.is2StepPayment}
                  paymentOptions={this.paymentOptions}
                  onPayment={this.onPayment}
                  guestUserHasErrors={this.guestUserHasErrors}
                  showCheckBox={true}
                  setRedirectPaymentData={this.setRedirectPaymentData}
                  hasADCBProductInCart={this.hasADCBProductInCart}
                  pointsPayConfig={this.getPointsPayConfig()}
                  isErrorOccured={this.isErrorOccured}
                  isRenewAnnualPass={this.isRenewAnnualPass}
                  hasTnCError={this.state.hasTnCError}
                  hideNewsletterOption={this.state.hideNewsletterOption}
                  disableBookButton={this.state.disableBookButton}
                  tabIndex={this.state.tabIndex}
                  currentOption={this.state.currentOption}
                  emailConfirmationText={this.emailConfirmationText()}
                  onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                  onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                  parkConsentEnabled={this.state.parkConsentEnabled}
                  newsLetterEnabled={this.state.newsLetterEnabled}
                />
              )}
            {this.state.proceedToPay &&
              this.state.currentOption === item.value &&
              this.state.isFirstPaymentExpanded &&
              this.state.currentOption === UIConfig.paymentOption.postPay && (
                <PostPay
                  cartData={this.state.cart}
                  services={this.props.data.services}
                  isEnabled={this.state.isTncChecked}
                  showTnCError={this.showTnCError}
                  openOverlay={this.openOverlay}
                  onTncChangeHandler={this.onTncChangeHandler}
                  onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                  onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                  parkConsentEnabled={this.state.parkConsentEnabled}
                  newsLetterEnabled={this.state.newsLetterEnabled}
                  is2StepPayment={this.is2StepPayment}
                  paymentOptions={this.paymentOptions}
                  onPayment={this.onPayment}
                  guestUserHasErrors={this.guestUserHasErrors}
                  showCheckBox={true}
                  setRedirectPaymentData={this.setRedirectPaymentData}
                  hasADCBProductInCart={this.hasADCBProductInCart}
                  postPayConfig={this.getPostPayConfig()}
                  isErrorOccured={this.isErrorOccured}
                  isRenewAnnualPass={this.isRenewAnnualPass}
                  hasTnCError={this.state.hasTnCError}
                  hideNewsletterOption={this.state.hideNewsletterOption}
                  disableBookButton={this.state.disableBookButton}
                  tabIndex={this.state.tabIndex}
                  currentOption={this.state.currentOption}
                  emailConfirmationText={this.emailConfirmationText()}
                  isPromoEnable={this.isPromoEnable}
                />
              )}
            {this.state.currentOption === UIConfig.paymentOption.gPay &&
              this.state.isFirstPaymentExpanded &&
              this.state.proceedToPay &&
              this.is2StepPayment &&
              this.state.currentOption === item.value && (
                <GPay
                  services={this.props.data.services}
                  paymentData={this.state.GPayData}
                  isPaymentMethodBlocked={this.state.isPaymentMethodBlocked}
                  showTnCError={this.showTnCError}
                  cartData={this.state.cart}
                  isEnabled={this.state.isTncChecked}
                  newsLetterEnabled={this.state.newsLetterEnabled}
                  payData={this.payData}
                  partnerReference={this.partnerReference}
                  dueData={this.dueDate}
                  propsData={this.props.data}
                  guestUserHasErrors={this.guestUserHasErrors}
                  hasADCBProductInCart={this.hasADCBProductInCart}
                  isErrorOccured={this.isErrorOccured}
                  is2StepPaymentYasArena={this.is2StepPaymentYasArena}
                  emailConfirmationText={this.emailConfirmationText()}
                ></GPay>
              )}
            {this.state.currentOption === UIConfig.paymentOption.paypal &&
              this.state.isFirstPaymentExpanded &&
              this.state.proceedToPay &&
              this.is2StepPayment &&
              this.state.currentOption === item.value && (
                <Paypal
                  paymentData={this.state.GPayData}
                  isPaymentMethodBlocked={this.state.isPaymentMethodBlocked}
                  showTnCError={this.showTnCError}
                  cartData={this.state.cart}
                  isEnabled={this.state.isTncChecked}
                  payData={this.payData}
                  partnerReference={this.partnerReference}
                  dueData={this.dueDate}
                  propsData={this.props.data}
                  setRedirectPaymentData={this.setRedirectPaymentData}
                  onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                  parkConsentEnabled={this.state.parkConsentEnabled}
                  guestUserHasErrors={this.guestUserHasErrors}
                  hasADCBProductInCart={this.hasADCBProductInCart}
                  isErrorOccured={this.isErrorOccured}
                  is2StepPaymentYasArena={this.is2StepPaymentYasArena}
                  showPaypalError={this.showPaypalError}
                  hasTnCError={this.state.hasTnCError}
                  emailConfirmationText={this.emailConfirmationText()}
                />
              )}
            {this.state.currentOption === item.value &&
              this.state.isFirstPaymentExpanded &&
              this.renderApplePayComponent()}
            {!this.paymentMethodNotBlocked.includes(this.state.currentOption) && this.state.isPaymentMethodBlocked && (
              <p className="coupon-failed-error error-msg body-copy-6">{this.state.blockedErrorMessage}</p>
            )}

            {this.state.showCheckBox &&
              this.state.currentOption === item.value &&
              this.state.isFirstPaymentExpanded &&
              this.paymentOptions.newsLetterLabel && (
                <div className={`${paypalFormClassWrapper} ${this.hasADCBProductInCart ? 'disabled' : ''}`}>
                  {
                    <PaymentCheckboxes
                      paymentOptions={this.paymentOptions}
                      openOverlay={this.openOverlay}
                      onTncChangeHandler={this.onTncChangeHandler}
                      onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                      onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                      cartData={this?.state?.cart}
                      key="news-letter-checkbox-1"
                      showNewsLetterCheckBoxOnly={true}
                      stateData={{
                        showCheckBox: this.state.showCheckBox,
                        hideNewsletterOption: this.state.hideNewsletterOption,
                        disableBookButton: this.state.disableBookButton,
                        hasTnCError: this.state.hasTnCError,
                        tabIndex: this.state.tabIndex,
                        isTncChecked: this.state.isTncChecked,
                        parkConsentEnabled: this.state.parkConsentEnabled,
                        newsLetterEnabled: this.state.newsLetterEnabled,
                        bindCheckBoxError: true,
                      }}
                    />
                  }
                </div>
              )}
          </>
        )}
      </li>
    ) : (
      <>
        {!this.is2StepPayment || this.enablePaymentOptions ? (
          <li
            key={item.id}
            data-index={i}
            data-value={item.value}
            title={item.label}
            aria-selected={this.state.currentOption === item.value && this.state.isFirstPaymentExpanded}
            className={`c-payment-items ${
              this.state.currentOption === item.value && this.state.isFirstPaymentExpanded ? 'selected-mode' : ''
            } ${!item.enable ? 'disabled' : ''} ${item.value}`}
          >
            <input
              type="radio"
              name="paymentOptions"
              id={item.id}
              value={item.value}
              checked={this.state.currentOption === item.value && this.state.isFirstPaymentExpanded ? true : false}
            />
            {this.mainObj.tenantID.toLowerCase() !== UIConfig.YIB2C && (
              <DynamicContent
                tagName="label"
                innerHtml={item.label}
                attrs={{ className: 'c-payment-items-text body-3', htmlFor: item.id }}
              />
            )}
            {/* Render input inside the image if tenant is yasisland */}
            {item.cardImage && (
              <div
                className="c-payment-items-image"
                onClick={(e) => {
                  this.onOptionChangeHandler(e, item.value);
                  this.setState({ ispaypalError: false });
                }}
              >
                <Image image={item.cardImage} />
                {this.mainObj.tenantID.toLowerCase() === UIConfig.YIB2C && (
                  <DynamicContent
                    tagName="label"
                    innerHtml={item.label}
                    attrs={{ className: 'c-payment-items-text body-3', htmlFor: item.id }}
                  />
                )}
              </div>
            )}
          </li>
        ) : (
          <li
            key={item.id}
            data-index={i}
            data-value={item.value}
            title={item.label}
            aria-selected={this.state.currentOption === item.value && this.state.isFirstPaymentExpanded}
            className={getPaymentItemClasses(item)}
            tabIndex={this.state.tabIndex}
            onKeyDown={(e) => this.handleKeyDown(e, item.value)}
          >
            <div
              id={item.id}
              data-value={item.value}
              onClick={(e) => {
                this.onOptionChangeHandler(e, item.value);
                this.setState({ ispaypalError: false });
              }}
            >
              <Image image={item.cardImage} />
            </div>
          </li>
        )}
      </>
    );
  };

  renderProceedToPayButton = () => {
    const { proceedToPay, currentOption } = this.state;

    const classes = classNames({
      'c-payment-btn-show': proceedToPay,
      'c-payment-hide-btn': !proceedToPay,
      'c-payment-btn-show-step2':
        this.is2StepPayment &&
        proceedToPay &&
        currentOption === UIConfig.paymentOption.paypal &&
        this.state.isFirstPaymentExpanded,
    });

    return (
      <div className={classes}>
        {!this.is2StepPayment &&
          this.state.currentOption === 'CreditCard' &&
          this.state.isFirstPaymentExpanded &&
          this.paymentOptions.cardDetailImage && (
            <div className={`${this.state.hidePic ? `hide` : `new-style`}`}>
              <Image noRendition={true} image={this.paymentOptions.cardDetailImage} />
            </div>
          )}
        <div
          className={
            this.state.isTncChecked && !this.state.disableBookButton && !this.state.isErrorExist
              ? 'c-payment-btn'
              : 'c-payment-btn c-payment-btn-disabled'
          }
        >
          {this.paymentOptions.primaryCTA.html && <div className="btn-primary btn-hero-cta" />}
        </div>
      </div>
    );
  };

  showDisablePaymentOverlay = () =>
    !this.state.isEnabled && !this.is2StepPayment && <div className="c-payment-overlay" />;

  renderApplePayComponent = () => {
    if (
      canUseDOM() &&
      this.is2StepPayment &&
      isApplePayAvailable() &&
      this.state.currentOption === UIConfig.paymentOption.applePay &&
      this.state.isFirstPaymentExpanded &&
      this.props.data.paymentOptions.applepayconfig &&
      Object.keys(this.props.data.paymentOptions.applepayconfig).length > 0 &&
      this.props.data.services.hasOwnProperty('applePayMakePurchase') &&
      this.props.data.services.hasOwnProperty('applePayMerchantValidation')
    ) {
      return (
        <ApplePay
          paymentData={this.state.applePayData}
          isPaymentMethodBlocked={this.state.isPaymentMethodBlocked}
          showTnCError={this.showTnCError}
          cartData={this.state.cart}
          isEnabled={this.state.isTncChecked}
          payData={this.payData}
          partnerReference={this.partnerReference}
          dueData={this.dueDate}
          propsData={this.props.data}
          guestUserHasErrors={this.guestUserHasErrors}
          hasADCBProductInCart={this.hasADCBProductInCart}
          isErrorOccured={this.isErrorOccured}
          is2StepPaymentYasArena={this.is2StepPaymentYasArena}
          emailConfirmationText={this.emailConfirmationText()}
          onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
          onParkConsentChangeHandler={this.onParkConsentChangeHandler}
          parkConsentEnabled={this.state.parkConsentEnabled}
          newsLetterEnabled={this.state.newsLetterEnabled}
        ></ApplePay>
      );
    }
    return null;
  };

  checkPromitionRequired = () => {
    const anonymousCart = this.state.cart;
    const { isCouponRequired, couponCode } = anonymousCart;
    return isCouponRequired && couponCode === '';
  };

  /**
   * Desc: payment form disabled if annual pass form not successfully save and if is for 2 step journey
   */
  disabledPaymentFrom = () => {
    if (canUseDOM() && !localStorage.getItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED)) {
      if (this.getPaymentDisabledStatus(this.state?.cartError, this.props?.data?.errorCodeToDisablePayments)) {
        if (this.state.tabIndex === '0') {
          this.setState({ tabIndex: '-1' });
        }
      } else if (
        (this.isYAB2C && this.checkPromitionRequired()) ||
        this.state.isPaymentSectionEnabled ||
        (this.is2StepPayment && this.isAnnualPass)
      ) {
        if (this.state.tabIndex === '0') {
          this.setState({ tabIndex: '-1' });
        }
        this.toggleTabIndexOnSelectedElm('.c-payment-tnc a, .c-ticket-tooltip a');
        return <div className="c-payment-overlay" />;
      } else {
        if (this.state.tabIndex === '-1') {
          this.setState({ tabIndex: '0' });
        }
        this.toggleTabIndexOnSelectedElm('.c-payment-tnc a, .c-ticket-tooltip a');
        return null;
      }
    }
  };

  toggleTabIndexOnSelectedElm = (selector) => helper.toggleTabIndexOnSelectedElm(this, selector);

  /**
   * Desc: payment form disabled if page is credit settlement or advance topup
   */
  disabledPaymentFromForSettlement = () => {
    if (canUseDOM()) {
      if (this.B2B_isCreditSettlement || this.B2B_isAdvanceTopUp) {
        return <div className="c-payment-overlay" id="b2b-payment-disabled" />;
      }
      return null;
    }
  };

  annualPassLoginCase = () => helper.annualPassLoginCase(this);

  closeError = () => this.setState({ isCardDetailsNotValid: false, ispaypalError: false });
  closePostpayError = () =>
    this.setState({ postpayPaymentError: '', tabbyPaymentError: '', paypalErrorMessage: { err: '' } });

  renderPaymentOptionsComponent = (paypalFormClassWrapper) => {
    let paymentTypes = this.paymentOptions.paymentTypes,
      loggedInUserData = getLoggedInUser(),
      cartData = getAnonymousCart();

    if (loggedInUserData && loggedInUserData.userName) {
      cartData = JSON.parse(getSessionStorage(UIConfig.localStoreKeys.purchaseJourney.cartData));
    }

    if (cartData) {
      const cartItems = cartData.cart.items;
      this.hasADCBProductInCart = false;
      this.isENBDproduct = false;
      cartItems.forEach((item) => {
        if (item.paymentCardType === UIConfig.bankOptions.adcb) {
          this.hasADCBProductInCart = true;
        }
        if (item.paymentCardType === UIConfig.bankOptions.enbd) {
          this.isENBDproduct = true;
          this.hasADCBProductInCart = true;
        }
      });
    }

    if (!isApplePayAvailable()) {
      paymentTypes = this.paymentOptions.paymentTypes.filter((item) => {
        return item.value !== UIConfig.paymentOption.applePay;
      });
    }

    let newPaymentTypes = paymentTypes;

    if (this.hasADCBProductInCart) {
      newPaymentTypes = [];
      const toShowPayment = this.isENBDproduct
        ? [UIConfig.paymentOption.creditCard]
        : this.paymentMethodNotBlocked || [];
      toShowPayment.forEach((item) => {
        const paymentMethod = paymentTypes.filter((itm) => item === itm.value);
        paymentMethod && paymentMethod.length && newPaymentTypes.push(paymentMethod[0]);
      });
      this.defaultOptionSelected = newPaymentTypes.length && newPaymentTypes[0];
      this.paymentMethod = this.defaultOptionSelected;
      if (
        this.paymentMethod &&
        this.paymentMethod.value !== this.state.currentOption &&
        !this.isADCBAllowedMethodLoaded
      ) {
        this.isADCBAllowedMethodLoaded = true;
        this.onOptionChangeHandler('', this.paymentMethod.value);
      }
    }

    const isMultiRowPaymentMode = this.props.data.isMultiRowPaymentMode,
      gridVariation = this.props.data.gridVariation,
      isCarouselNeeded = !(isMultiRowPaymentMode && gridVariation) && this.isMobile && viewPortWidthCheck('570'),
      multiRowPayCheck = isMultiRowPaymentMode && this.isMobile && viewPortWidthCheck('570'),
      classes = classNames({ 'non-carousal': !isCarouselNeeded });

    let extraClass = '';
    if (multiRowPayCheck && gridVariation) {
      extraClass = ` multi-row-payment ${gridVariation}`;
    }

    return (
      <ul className={classes + extraClass}>
        {!isCarouselNeeded || !this.is2StepPayment || this.enablePaymentOptions ? (
          isTicketUpgradePayment() ? (
            newPaymentTypes
              .filter(
                (el) =>
                  el.value === UIConfig.paymentOption.creditCard ||
                  el.value === UIConfig.paymentOption.paypal ||
                  el.value === UIConfig.paymentOption.gPay ||
                  el.value === UIConfig.paymentOption.applePay,
              )
              .map((item, i) => (
                <React.Fragment key={i}>{this.renderPaymentOptions(item, i, paypalFormClassWrapper)}</React.Fragment>
              ))
          ) : (
            newPaymentTypes.map((item, i) => this.renderPaymentOptions(item, i, paypalFormClassWrapper))
          )
        ) : (
          <Carousel data={this.slickSettings}>
            {newPaymentTypes.map((item, i) => this.renderPaymentOptions(item, i, paypalFormClassWrapper))}
          </Carousel>
        )}
      </ul>
    );
  };

  renderLimitPaymentError = () =>
    ((this.state.postpayPaymentError && this.state.currentOption === UIConfig.paymentOption.postPay) ||
      (this.state.tabbyPaymentError && this.state.currentOption === UIConfig.paymentOption.tabby)) && (
      <div
        className={classNames('body-copy-6 payment-error', {
          'payment-error-loggedIn': this.state.loggedInUser,
          'invalid-card': this.isSwad,
        })}
      >
        {!this.isSwad && <div className="error-icon-2step"></div>}
        <div className="error-msg-2step" role="alert">
          {this.isSwad && <div className="error-icon-2step"></div>}
          {this.state.postpayPaymentError || this.state.tabbyPaymentError}
        </div>
        <span className="close-icon-2step" onClick={this.closePostpayError}></span>
      </div>
    );
  renderCardInvalidError = () => {
    let paymentError = (
      <div>
        {this.state.yasIdExist && (
          <div
            className={classNames('body-copy-6 payment-error', {
              'payment-error-loggedIn': this.state.loggedInUser,
              'invalid-card': this.isSwad,
            })}
          >
            {!this.isSwad && <div className="error-icon-2step"></div>}
            <div className="error-msg-2step" role="alert">
              {this.isSwad && <div className="error-icon-2step"></div>}
              {this.paymentOptions.yasIdCreatedMessage}
            </div>
            <span className="close-icon-2step" onClick={this.closeError}></span>
          </div>
        )}
        {this.state.paymentError && (
          <div
            className={classNames('body-copy-6 payment-error', {
              'payment-error-loggedIn': this.state.loggedInUser,
              'invalid-card': this.isSwad,
            })}
          >
            {!this.isSwad && <div className="error-icon-2step"></div>}
            <div className="error-msg-2step" role="alert">
              {this.isSwad && <div className="error-icon-2step"></div>}
              {this.state.paymentError}
            </div>
            <span className="close-icon-2step" onClick={this.closeError}></span>
          </div>
        )}
      </div>
    );

    if (!this.ccOptionWrapper && this.state.isCardDetailsNotValid && this.isB2B) {
      this.setState({ isCCWrapperRef: true });
    }
    if (this.is2StepPayment && this.state.isCardDetailsNotValid) {
      if (this.ccOptionWrapper && canUseDOM() && !this.hasBeenScrolledToError) {
        window.scrollTo(0, this.ccOptionWrapper.offsetTop - 120);
        this.hasBeenScrolledToError = true;
      }
      return paymentError;
    }

    return null;
  };

  renderPaypalError = () => {
    const { data } = this.props;
    let filteredError;
    let errList;
    if (this.state.paypalErrorMessage.err) {
      errList = data.services.payPalCapture.errors;
      if (checkTenant(UIConfig.tenants.b2b)) {
        errList = data.services.captureTransactionAdcs.errors;
      }
      const errCode = this.state.paypalErrorMessage?.err.code;
      filteredError = errList[errCode];
    }

    let paypalpaymentError = (
      <div className="paypal-payment-error">
        {this.state.paypalErrorMessage.err && this.state.ispaypalError && (
          <div
            className={classNames('body-copy-6 payment-error', {
              'payment-error-loggedIn': this.state.loggedInUser,
              'invalid-card': this.isSwad,
            })}
          >
            <div class="error-icon-2step"></div>
            <div className="error-msg-2step" role="alert">
              {this.isSwad && <div className="error-icon-2step"></div>}
              {filteredError}
            </div>
            <span className="close-icon-2step" onClick={this.closeError}></span>
          </div>
        )}
      </div>
    );

    // Commenting below code to fix the bug EP-7597
    // Shifting this code to showPaypalError function
    // if (this.state.currentOption === UIConfig.paymentOption.paypal) {
    //   removeLoaderOverlay();
    // }

    if (this.is2StepPayment) {
      return paypalpaymentError;
    }
    return null;
  };

  resetPaymentError = () => helper.resetPaymentError(this);

  usePayFortForTouchPointsPayment = (cardDetails) => {
    this.setState({ usePayfortWithADCB: true }, () => window.PubSub.publish('SubmitDataForm', { cardDetails }));
  };

  usePayFortForMoneyCard = (cardDetails) => {
    this.setState({ usePayfortWithMoneyCard: true }, () => window.PubSub.publish('SubmitDataForm', { cardDetails }));
  };
  showTnCError = (showError) => helper.showTnCError(this, showError);

  handleKeyDown = (e, selectedValue) => helper.handleKeyDown(this, e, selectedValue);

  onLoginClickAnalytics = () => helper.onLoginClickAnalytics(this);

  onLoginClickOpenPopup = (loginURL) => {
    openIAMOverlay(<IAMPopup loginURL={loginURL.href} />);
    this.onLoginClickAnalytics();
  };

  displayInlineSignupError = (error) => {
    const errorMessages = [];
    Object.keys(error).forEach(function(key) {
      errorMessages.push(error[key]);
    });

    if (errorMessages.length) {
      return (
        <div>
          {errorMessages.map((errorMessage, i) => (
            <div className={`body-copy-6 payment-error ${this.isSwad ? 'invalid-card' : ''}`} key={i}>
              {!this.isSwad && <div className="error-icon-2step"></div>}
              <div className="error-msg-2step" role="alert">
                {this.isSwad && <div className="error-icon-2step"></div>}
                {errorMessage.text}
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  getPaymentDisabledStatus = (cartErrorCodes, errorCodeToDisablePayments) => {
    if (
      Array.isArray(errorCodeToDisablePayments) &&
      Array.isArray(cartErrorCodes) &&
      cartErrorCodes.length > 0 &&
      errorCodeToDisablePayments.length > 0
    ) {
      if (errorCodeToDisablePayments.filter((value) => cartErrorCodes.includes(value)).length > 0) {
        return true;
      }
    }
    return false;
  };
  getPaymentContainerClassName = (cartErrorCodes, errorCodeToDisablePayments) => {
    let className = 'payment-container-wrapper ';
    if (this.getPaymentDisabledStatus(cartErrorCodes, errorCodeToDisablePayments)) {
      className += ' disabled';
    }
    return className;
  };

  renderPayfortComponent = () => (
    <>
      {(checkIfParks() || isMatchTenant(UIConfig.tenants.swadb2c)) &&
        this.state.currentOption === UIConfig.paymentOption.tabby &&
        this.isPromoEnable(this.paymentOptions, UIConfig.paymentMethod.tabby) && (
          <>
            <Helmet
              script={[
                {
                  src: this.props.data?.tabbyInstallmentOverlayScriptUrl || UIConfig.tabby.promo.SCRIPT_SRC,
                  async: true,
                },
              ]}
              onChangeClientState={(_, addedTags) => this.handleScriptInject(addedTags)}
            />
            <div id="tabby-learn-more" className="tabby-learn-more"></div>
          </>
        )}
      <PayFort
        key={this.state.currentOption}
        payFordata={this.props.data}
        payData={this.payData}
        is2StepPayment={this.is2StepPayment}
        setPayFortData={this.setPayfortData}
        enablePaymentButton={this.state.enablePaymentButton}
        cartData={this.state.cart}
        hideNewsLetter={this.state.hideNewsletterOption}
        isCreditSettlement={this.B2B_isCreditSettlement}
        setCreditSettlementData={setCreditSettlementData}
        isAdvanceTopup={this.B2B_isAdvanceTopUp}
        setAdvanceTopupData={setAdvanceTopupData}
        B2B_pageVariation={this.B2B_pageVariation}
        isTabIndex={this.state.tabIndex}
        hasADCBProductInCart={this.hasADCBProductInCart}
        is2StepPaymentYasArena={this.is2StepPaymentYasArena}
        usePayfortWithADCB={this.state.usePayfortWithADCB}
        usePayfortWithMoneyCard={this.state.usePayfortWithMoneyCard}
        selectedPayfortType={this.state.currentOption}
        {...(this.state.currentOption === UIConfig.paymentOption.tabby && {
          isErrorOccured: this.isErrorOccured,
        })}
        emailConfirmationText={this.emailConfirmationText()}
        newsLetterEnabled={this.state.newsLetterEnabled}
        onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
      />
    </>
  );
  emailConfirmationText = () => {
    if (
      this.state.expressCheckoutEnabled &&
      this.props?.data?.paymentOptions?.paymentLabels?.emailConfirmMessage &&
      (this?.state?.confirmedEmail || sessionStorage.getItem('confirmedEmail'))
    )
      return (
        <p className="express-email-confirmation-text">
          {this.props?.data?.paymentOptions?.paymentLabels?.emailConfirmMessage?.replace('{email}', '')}
          <span className="express-email-confirmation-text-email">
            {this?.state?.confirmedEmail || sessionStorage.getItem('confirmedEmail') || ''}
          </span>
        </p>
      );

    return <></>;
  };
  isApExist = () => this.state?.cart?.items?.some((item) => item?.productIdType?.toLowerCase().includes('annualpass_'));
  renderHiTitle = () => {
    return (
      this.isLoggedInUser &&
      this.paymentOptions?.title &&
      !this.isApExist() &&
      !document.querySelector(
        '.contact-us-form-wrapper.guest-checkout-form-wrapper.recipient-details-form.inline-signup',
      )
    );
  };

  isMultiParkTicketExist = () => {
    if (isParkTenant() || isMatchTenant(UIConfig.tenants.swadb2c)) {
      return this?.state?.cart?.items?.some((item) => item?.itemType?.toLowerCase() === 'mup');
    }
  };

  render() {
    try {
      const paymentOptionsClasses = classNames('c-payment-paymentMode payment-options', {
        'payment-types-variation ': this.is2StepPayment,
        'payment-radio-btn-variation': this.isPaymentVerticalView,
      });

      const paymentOptionsClassesMainWrapper = classNames('component c-payment c-payment-b2c', {
        'c-payment-b2c-step2': this.is2StepPayment && !this.state.loggedInUser,
        'c-payment-b2c-step2 logged-in-user': this.is2StepPayment && this.state.loggedInUser,
        'c-payment-b2b': this.isB2B,
        'c-payment-b2b-creditsettle': this.B2B_isCreditSettlement,
        'c-payment-b2b-advancetopup': this.B2B_isAdvanceTopUp,
        'c-payment-vertical': this.isPaymentVerticalView, //Added for payment vertical view
        'hide-payment-component': isAnnualPassCase() && localStorage.getItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED),
        multiparkConsentPaymentWrapper:
          this.isMultiParkTicketExist() && (isMatchTenant(UIConfig.tenants.swadb2c) || isParkTenant()),
      });

      const paypalFormClassWrapper = classNames({
        'c-form paypal-wrapper':
          this.is2StepPayment &&
          this.state.currentOption === UIConfig.paymentOption.paypal &&
          this.state.isFirstPaymentExpanded,
        'c-form ali-pay-wrapper':
          this.is2StepPayment &&
          this.state.currentOption === UIConfig.paymentOption.aliPay &&
          this.state.isFirstPaymentExpanded,
        'c-form points-pay-checkbox-wrapper':
          this.is2StepPayment && this.checkPointsPayMethod(this.state.currentOption),
        'c-form apple-pay-checkbox-wrapper':
          this.is2StepPayment &&
          this.state.currentOption === UIConfig.paymentOption.applePay &&
          this.state.isFirstPaymentExpanded,
        'checkbox-wrapper-step2': this.is2StepPayment,
        'c-form g-pay-checkbox-wrapper':
          this.is2StepPayment &&
          this.state.currentOption === UIConfig.paymentOption.gPay &&
          this.state.isFirstPaymentExpanded,
      });
      if (this.is2StepPayment && this.defaultOptionSelected.value !== UIConfig.paymentOption.paypal) {
        if (!this.B2B_isCreditSettlement && !this.B2B_isAdvanceTopUp) {
          if (this.mainObj.tenantID.toLowerCase() !== UIConfig.YIB2C) {
            if (
              this.state.cart === null ||
              (this.state.cart.hasOwnProperty('packages')
                ? this.state.cart?.items &&
                  this.state.cart.items?.length === 0 &&
                  this.state.cart.packages?.length === 0
                : this.state.cart?.items && this.state.cart.items?.length === 0)
            ) {
              return null;
            }
          }
        }
        if (this.isOnlyLoginEnabled && isEmpty(getLoginUser())) {
          return (
            <div
              data-c-name="Payment"
              data-component-name="Payment"
              data-c-render="client-only"
              className={paymentOptionsClassesMainWrapper}
            >
              <div className="c-payment-wrapper w--content payment-login">
                <DynamicContent
                  tagName="p"
                  innerHtml={this.paymentOptions.descriptionText}
                  attrs={{ className: 'description-text' }}
                />
                {this.enableYasIDVersion === 'true' ? (
                  <div className="login-cta">
                    <button onClick={() => this.onLoginClickOpenPopup(this.paymentOptions.secondaryCTA)}>
                      {this.paymentOptions.secondaryCTA.label}
                    </button>
                  </div>
                ) : (
                  <div className="login-cta">
                    <AnchorLink link={this.paymentOptions.secondaryCTA} onClick={() => this.onLoginClickAnalytics()} />
                  </div>
                )}
              </div>
            </div>
          );
        }
      }
      return (
        <>
          {this.isPaymentVerticalView ? (
            <div
              data-c-name="Payment"
              data-component-name="Payment"
              data-c-render="client-only"
              className={paymentOptionsClassesMainWrapper}
              ref={(ccOptionWrapper) => {
                this.ccOptionWrapper = ccOptionWrapper;
              }}
            >
              <div>
                {this.disabledPaymentFromForSettlement()}
                {this.showDisablePaymentOverlay() || this.disabledPaymentFrom()}
                {this.state.isPaymentDisabledDuetoCartQuantity && <div className="c-payment-overlay"></div>}
                <div className="c-payment-wrapper w--content " ref={this.paymentWrapperRef}>
                  {this.renderHiTitle() && (
                    <DynamicContent
                      tagName="h3"
                      innerHtml={this.paymentOptions?.title.replace(
                        '{firstName}',
                        resolvePath(getLoggedInUser(), 'userName', ''),
                      )}
                      attrs={{ className: 'heading-3 title' }}
                    />
                  )}
                  <DynamicContent
                    tagName="h2"
                    innerHtml={this.paymentOptions.subTitle}
                    attrs={{ className: 'heading-3 sub-title' }}
                  />
                  {this.getPaymentDisabledStatus(this.state?.cartError, this.props?.data?.errorCodeToDisablePayments) &&
                    this.props?.data?.errorMessageForDisablePayment && (
                      <DynamicContent
                        tagName="p"
                        attrs={{ className: 'tab-warning' }}
                        innerHtml={this.props.data.errorMessageForDisablePayment}
                      />
                    )}
                  {checkInlineSignup() && this.state.errorData ? (
                    this.displayInlineSignupError(this.state.errorData)
                  ) : (
                    <ErrorSummary data={this.state.errorData} />
                  )}
                  {this.renderCardInvalidError()}
                  {this.renderLimitPaymentError()}
                  {this.renderPaypalError()}
                  <div
                    className={this.getPaymentContainerClassName(
                      this.state?.cartError,
                      this.props?.data?.errorCodeToDisablePayments,
                    )}
                    aria-hidden={this.getPaymentDisabledStatus(
                      this.state?.cartError,
                      this.props?.data?.errorCodeToDisablePayments,
                    )}
                    tabIndex={
                      this.getPaymentDisabledStatus(this.state?.cartError, this.props?.data?.errorCodeToDisablePayments)
                        ? '-1'
                        : 0
                    }
                  >
                    <div className={paymentOptionsClasses}>
                      {this.renderPaymentOptionsComponent(paypalFormClassWrapper)}
                    </div>
                    <div className="right-section">
                      {!this.isMobile && (
                        <>
                          <div
                            className={`${paypalFormClassWrapper} ${this.hasADCBProductInCart ? 'disabled' : ''} ${
                              this.state.showCheckBox ? 'checkbox-show' : 'checkbox-hide'
                            }`}
                          >
                            {
                              <PaymentCheckboxes
                                paymentOptions={this.paymentOptions}
                                openOverlay={this.openOverlay}
                                onTncChangeHandler={this.onTncChangeHandler}
                                onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                                onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                                cartData={this?.state?.cart}
                                key="terms-condition-2"
                                showParkConsentCheckbox={true}
                                stateData={{
                                  showCheckBox: this.state.showCheckBox,
                                  hideNewsletterOption: this.state.hideNewsletterOption,
                                  disableBookButton: this.state.disableBookButton,
                                  hasTnCError: this.state.hasTnCError,
                                  tabIndex: this.state.tabIndex,
                                  isTncChecked: this.state.isTncChecked,
                                  parkConsentEnabled: this.state.parkConsentEnabled,
                                  newsLetterEnabled: this.state.newsLetterEnabled,
                                  bindCheckBoxError: true,
                                }}
                              />
                            }
                            {this.hidePayCTABtn.indexOf(this.state.currentOption) === -1 &&
                              this.renderProceedToPayButton()}
                          </div>
                          {this.is2StepPayment &&
                            this.state.currentOption === UIConfig.paymentOption.accCredit &&
                            this.state.isFirstPaymentExpanded &&
                            this.isB2B &&
                            this.state.proceedToPay && (
                              <B2BPaymentCheckbox
                                paymentOptions={this.paymentOptions}
                                onTncChangeHandler={this.onTncChangeHandler}
                                onClickHandler={this.onPayWithCredit}
                                stateData={{
                                  proceedToPay: this.state.proceedToPay,
                                  isTncChecked: this.state.isTncChecked,
                                }}
                              />
                            )}
                          {this.is2StepPayment &&
                            this.state.currentOption === UIConfig.paymentOption.submitApproval &&
                            this.state.isFirstPaymentExpanded &&
                            this.isB2B &&
                            this.state.proceedToPay && (
                              <B2BPaymentCheckbox
                                paymentOptions={this.paymentOptions}
                                onTncChangeHandler={this.onTncChangeHandler}
                                onClickHandler={this.onSubmitForApproval}
                                stateData={{
                                  proceedToPay: this.state.proceedToPay,
                                  isTncChecked: this.state.isTncChecked,
                                }}
                              />
                            )}
                          {this.is2StepPayment &&
                            ((UIConfig.payfort.payfortTypes.find((item) => item === this.state.currentOption) &&
                              this.state.isFirstPaymentExpanded) ||
                              this.state.usePayfortWithADCB ||
                              this.state.usePayfortWithMoneyCard) &&
                            this.state.proceedToPay &&
                            this.renderPayfortComponent()}

                          {this.state.currentOption === UIConfig.paymentOption.adcbTouchPoints &&
                            this.state.isFirstPaymentExpanded &&
                            this.state.proceedToPay && (
                              <ADCBTouchPoints
                                is2StepPayment={this.is2StepPayment}
                                propsData={this.props.data}
                                payData={this.payData}
                                enablePaymentButton={this.state.enablePaymentButton}
                                cartData={this.state.cart}
                                hideNewsLetter={this.state.hideNewsletterOption}
                                isTabIndex={this.state.tabIndex}
                                hasADCBProductInCart={this.hasADCBProductInCart}
                                showTnCError={this.showTnCError}
                                guestUserHasErrors={this.guestUserHasErrors}
                                isEnabled={this.state.isTncChecked}
                                showCheckBox={true}
                                onTncChangeHandler={this.onTncChangeHandler}
                                isErrorOccured={this.isErrorOccured}
                                setRedirectPaymentData={this.setRedirectPaymentData}
                                usePayFortForTouchPointsPayment={this.usePayFortForTouchPointsPayment}
                                resetPaymentError={this.resetPaymentError}
                                isRenewAnnualPass={this.isRenewAnnualPass}
                                annualPassCartData={this.annualPassCartData}
                                errorData={this.state.errorData}
                                disableBookButton={this.state.disableBookButton}
                                hasTnCError={this.state.hasTnCError}
                                emailConfirmationText={this.emailConfirmationText()}
                                onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                                onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                                parkConsentEnabled={this.state.parkConsentEnabled}
                                newsLetterEnabled={this.state.newsLetterEnabled}
                              />
                            )}
                          {this.state.proceedToPay &&
                          this.is2StepPayment &&
                          this.state.currentOption === UIConfig.paymentOption.aliPay &&
                          this.state.isFirstPaymentExpanded ? (
                            <AliPay
                              is2StepPayment={this.is2StepPayment}
                              cartData={this.state.cart}
                              paymentOptions={this.paymentOptions}
                              services={this.props.data.services}
                              onPayment={this.onPayment}
                              showTnCError={this.showTnCError}
                              isEnabled={this.state.isTncChecked}
                              setRedirectPaymentData={this.setRedirectPaymentData}
                              guestUserHasErrors={this.guestUserHasErrors}
                              isPaymentMethodSwitched={this.isPaymentMethodSwitched}
                              isTabIndex={this.state.tabIndex}
                              currentOption={this.state.currentOption}
                              isErrorOccured={this.isErrorOccured}
                              hasADCBProductInCart={this.hasADCBProductInCart}
                              emailConfirmationText={this.emailConfirmationText()}
                            />
                          ) : (
                            ''
                          )}
                          {this.state.proceedToPay && this.checkPointsPayMethod(this.state.currentOption) && (
                            <PointsPay
                              cartData={this.state.cart}
                              services={this.props.data.services}
                              isEnabled={this.state.isTncChecked}
                              showTnCError={this.showTnCError}
                              openOverlay={this.openOverlay}
                              onTncChangeHandler={this.onTncChangeHandler}
                              is2StepPayment={this.is2StepPayment}
                              paymentOptions={this.paymentOptions}
                              onPayment={this.onPayment}
                              guestUserHasErrors={this.guestUserHasErrors}
                              showCheckBox={true}
                              setRedirectPaymentData={this.setRedirectPaymentData}
                              hasADCBProductInCart={this.hasADCBProductInCart}
                              pointsPayConfig={this.getPointsPayConfig()}
                              isErrorOccured={this.isErrorOccured}
                              isRenewAnnualPass={this.isRenewAnnualPass}
                              hasTnCError={this.state.hasTnCError}
                              hideNewsletterOption={this.state.hideNewsletterOption}
                              disableBookButton={this.state.disableBookButton}
                              tabIndex={this.state.tabIndex}
                              currentOption={this.state.currentOption}
                              emailConfirmationText={this.emailConfirmationText()}
                              onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                              onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                              parkConsentEnabled={this.state.parkConsentEnabled}
                              newsLetterEnabled={this.state.newsLetterEnabled}
                            />
                          )}
                          {this.state.proceedToPay && this.state.currentOption === UIConfig.paymentOption.postPay && (
                            <PostPay
                              cartData={this.state.cart}
                              services={this.props.data.services}
                              isEnabled={this.state.isTncChecked}
                              showTnCError={this.showTnCError}
                              openOverlay={this.openOverlay}
                              onTncChangeHandler={this.onTncChangeHandler}
                              onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                              onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                              parkConsentEnabled={this.state.parkConsentEnabled}
                              newsLetterEnabled={this.state.newsLetterEnabled}
                              is2StepPayment={this.is2StepPayment}
                              paymentOptions={this.paymentOptions}
                              onPayment={this.onPayment}
                              guestUserHasErrors={this.guestUserHasErrors}
                              showCheckBox={true}
                              setRedirectPaymentData={this.setRedirectPaymentData}
                              hasADCBProductInCart={this.hasADCBProductInCart}
                              postPayConfig={this.getPostPayConfig()}
                              isErrorOccured={this.isErrorOccured}
                              isRenewAnnualPass={this.isRenewAnnualPass}
                              hasTnCError={this.state.hasTnCError}
                              hideNewsletterOption={this.state.hideNewsletterOption}
                              disableBookButton={this.state.disableBookButton}
                              tabIndex={this.state.tabIndex}
                              currentOption={this.state.currentOption}
                              emailConfirmationText={this.emailConfirmationText()}
                              isPromoEnable={this.isPromoEnable}
                            />
                          )}
                          {this.state.currentOption === UIConfig.paymentOption.moneyCard &&
                            this.state.isFirstPaymentExpanded &&
                            this.state.proceedToPay && (
                              <MoneyCard
                                is2StepPayment={this.is2StepPayment}
                                propsData={this.props.data}
                                payData={this.payData}
                                enablePaymentButton={this.state.enablePaymentButton}
                                cartData={this.state.cart}
                                hideNewsLetter={this.state.hideNewsletterOption}
                                isTabIndex={this.state.tabIndex}
                                hasADCBProductInCart={this.hasADCBProductInCart}
                                showTnCError={this.showTnCError}
                                guestUserHasErrors={this.guestUserHasErrors}
                                isEnabled={this.state.isTncChecked}
                                showCheckBox={true}
                                onTncChangeHandler={this.onTncChangeHandler}
                                isErrorOccured={this.isErrorOccured}
                                setRedirectPaymentData={this.setRedirectPaymentData}
                                usePayFortForMoneyCard={this.usePayFortForMoneyCard}
                                usePayFortForTouchPointsPayment={this.usePayFortForTouchPointsPayment}
                                resetPaymentError={this.resetPaymentError}
                                isRenewAnnualPass={this.isRenewAnnualPass}
                                annualPassCartData={this.annualPassCartData}
                                errorData={this.state.errorData}
                                disableBookButton={this.state.disableBookButton}
                                hasTnCError={this.state.hasTnCError}
                                emailConfirmationText={this.emailConfirmationText()}
                              />
                            )}

                          {this.state.currentOption === UIConfig.paymentOption.gPay &&
                            this.state.isFirstPaymentExpanded &&
                            this.state.proceedToPay &&
                            this.is2StepPayment && (
                              <GPay
                                services={this.props.data.services}
                                paymentData={this.state.GPayData}
                                isPaymentMethodBlocked={this.state.isPaymentMethodBlocked}
                                showTnCError={this.showTnCError}
                                cartData={this.state.cart}
                                isEnabled={this.state.isTncChecked}
                                newsLetterEnabled={this.state.newsLetterEnabled}
                                payData={this.payData}
                                partnerReference={this.partnerReference}
                                dueData={this.dueDate}
                                propsData={this.props.data}
                                guestUserHasErrors={this.guestUserHasErrors}
                                hasADCBProductInCart={this.hasADCBProductInCart}
                                isErrorOccured={this.isErrorOccured}
                                is2StepPaymentYasArena={this.is2StepPaymentYasArena}
                                emailConfirmationText={this.emailConfirmationText()}
                              ></GPay>
                            )}
                          {this.state.currentOption === UIConfig.paymentOption.paypal &&
                            this.state.isFirstPaymentExpanded &&
                            this.state.proceedToPay &&
                            this.is2StepPayment && (
                              <Paypal
                                paymentData={this.state.GPayData}
                                isPaymentMethodBlocked={this.state.isPaymentMethodBlocked}
                                showTnCError={this.showTnCError}
                                cartData={this.state.cart}
                                isEnabled={this.state.isTncChecked}
                                payData={this.payData}
                                partnerReference={this.partnerReference}
                                dueData={this.dueDate}
                                propsData={this.props.data}
                                setRedirectPaymentData={this.setRedirectPaymentData}
                                onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                                parkConsentEnabled={this.state.parkConsentEnabled}
                                guestUserHasErrors={this.guestUserHasErrors}
                                hasADCBProductInCart={this.hasADCBProductInCart}
                                isErrorOccured={this.isErrorOccured}
                                is2StepPaymentYasArena={this.is2StepPaymentYasArena}
                                showPaypalError={this.showPaypalError}
                                hasTnCError={this.state.hasTnCError}
                                emailConfirmationText={this.emailConfirmationText()}
                              />
                            )}
                          {this.renderApplePayComponent()}
                          {!this.paymentMethodNotBlocked.includes(this.state.currentOption) &&
                            this.state.isPaymentMethodBlocked && (
                              <p className="coupon-failed-error error-msg body-copy-6">
                                {this.state.blockedErrorMessage}
                              </p>
                            )}

                          {this.paymentOptions.newsLetterLabel && (
                            <div
                              className={`${paypalFormClassWrapper} ${this.hasADCBProductInCart ? 'disabled' : ''} ${
                                this.state.showCheckBox ? 'checkbox-show' : 'checkbox-hide'
                              }`}
                            >
                              <PaymentCheckboxes
                                paymentOptions={this.paymentOptions}
                                openOverlay={this.openOverlay}
                                onTncChangeHandler={this.onTncChangeHandler}
                                onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                                onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                                cartData={this?.state?.cart}
                                showNewsLetterCheckBoxOnly={true}
                                showParkConsentCheckbox={false}
                                key="news-letter-checkbox-2"
                                stateData={{
                                  showCheckBox: this.state.showCheckBox,
                                  hideNewsletterOption: this.state.hideNewsletterOption,
                                  disableBookButton: this.state.disableBookButton,
                                  hasTnCError: this.state.hasTnCError,
                                  tabIndex: this.state.tabIndex,
                                  isTncChecked: this.state.isTncChecked,
                                  newsLetterEnabled: this.state.newsLetterEnabled,
                                  parkConsentEnabled: this.state.parkConsentEnabled,
                                  bindCheckBoxError: true,
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {this.state.disabledInlineSignUp && this.isSwad && (
                    <CollapsedView
                      title={
                        JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.expressCheckoutLabels))?.signInTitle ||
                        ''
                      }
                      subtitle={
                        JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.expressCheckoutLabels))?.signInText ||
                        ''
                      }
                      extraClassName="express-checkout-form"
                      onClick={() => {
                        window.PubSub.publish(UIConfig.events.DISABLE_INLINE_SIGNUP, { enabled: true });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              data-c-name="Payment"
              data-component-name="Payment"
              data-c-render="client-only"
              className={paymentOptionsClassesMainWrapper}
              ref={(ccOptionWrapper) => {
                this.ccOptionWrapper = ccOptionWrapper;
              }}
            >
              <div>
                {this.disabledPaymentFromForSettlement()}
                {this.showDisablePaymentOverlay() || this.disabledPaymentFrom()}
                {this.disableAuthorPaymentEA && <div class="c-payment-overlay"></div>}
                <div className="c-payment-wrapper w--content">
                  {this.renderHiTitle() && (
                    <DynamicContent
                      tagName="h3"
                      innerHtml={this.paymentOptions?.title.replace(
                        '{firstName}',
                        resolvePath(getLoggedInUser(), 'userName', ''),
                      )}
                      attrs={{ className: 'heading-3 title' }}
                    />
                  )}
                  <DynamicContent
                    tagName="h2"
                    innerHtml={this.paymentOptions.subTitle}
                    attrs={{ className: 'heading-3 sub-title' }}
                  />
                  {checkInlineSignup() && this.state.errorData ? (
                    this.displayInlineSignupError(this.state.errorData)
                  ) : (
                    <ErrorSummary data={this.state.errorData} />
                  )}
                  {this.isYMCTenant && this.renderCardInvalidError()}
                  {this.isYMCTenant && this.renderLimitPaymentError()}
                  <div className={paymentOptionsClasses}>{this.renderPaymentOptionsComponent()}</div>
                  {!this.isYMCTenant && this.renderCardInvalidError()}
                  {!this.isYMCTenant && this.renderLimitPaymentError()}
                  {!this.isYMCTenant && this.renderPaypalError()}
                  <div className={`${paypalFormClassWrapper} ${this.hasADCBProductInCart ? 'disabled' : ''}`}>
                    {
                      <PaymentCheckboxes
                        paymentOptions={this.paymentOptions}
                        openOverlay={this.openOverlay}
                        onTncChangeHandler={this.onTncChangeHandler}
                        onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                        onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                        cartData={this?.state?.cart}
                        key="terms-condition-3"
                        stateData={{
                          showCheckBox: this.state.showCheckBox,
                          hideNewsletterOption: this.state.hideNewsletterOption,
                          disableBookButton: this.state.disableBookButton,
                          hasTnCError: this.state.hasTnCError,
                          tabIndex: this.state.tabIndex,
                          isTncChecked: this.state.isTncChecked,
                          parkConsentEnabled: this.state.parkConsentEnabled,
                          newsLetterEnabled: this.state.newsLetterEnabled,
                          bindCheckBoxError: true,
                        }}
                      />
                    }

                    {this.hidePayCTABtn.indexOf(this.state.currentOption) === -1 && this.renderProceedToPayButton()}
                  </div>
                  {this.is2StepPayment &&
                    this.state.currentOption === UIConfig.paymentOption.accCredit &&
                    this.state.isFirstPaymentExpanded &&
                    this.isB2B &&
                    this.state.proceedToPay && (
                      <B2BPaymentCheckbox
                        paymentOptions={this.paymentOptions}
                        onTncChangeHandler={this.onTncChangeHandler}
                        onClickHandler={this.onPayWithCredit}
                        stateData={{
                          proceedToPay: this.state.proceedToPay,
                          isTncChecked: this.state.isTncChecked,
                        }}
                      />
                    )}
                  {this.is2StepPayment &&
                    this.state.currentOption === UIConfig.paymentOption.submitApproval &&
                    this.state.isFirstPaymentExpanded &&
                    this.isB2B &&
                    this.state.proceedToPay && (
                      <B2BPaymentCheckbox
                        paymentOptions={this.paymentOptions}
                        onTncChangeHandler={this.onTncChangeHandler}
                        onClickHandler={this.onSubmitForApproval}
                        stateData={{
                          proceedToPay: this.state.proceedToPay,
                          isTncChecked: this.state.isTncChecked,
                        }}
                      />
                    )}
                  {this.is2StepPayment &&
                    ((UIConfig.payfort.payfortTypes.find((item) => item === this.state.currentOption) &&
                      this.state.isFirstPaymentExpanded) ||
                      this.state.usePayfortWithADCB ||
                      this.state.usePayfortWithMoneyCard) &&
                    this.state.proceedToPay &&
                    this.renderPayfortComponent()}
                  {this.is2StepPayment &&
                    this.state.currentOption !== UIConfig.paymentOption.creditCard &&
                    sessionStorage.getItem('confirmedEmail') &&
                    isMatchTenant(UIConfig.YIB2C) &&
                    !this.isLoggedInUser && (
                      <p className="emailConfirmation-text-yib2c">
                        {this.props?.data?.paymentOptions?.paymentLabels?.emailConfirmMessage?.replace('{email}', '')}
                        <span className="emailConfirmation-text-yib2c-email">
                          {sessionStorage.getItem('confirmedEmail')}
                        </span>
                      </p>
                    )}

                  {this.is2StepPayment &&
                    ((this.state.currentOption === UIConfig.paymentOption.paypal &&
                      this.state.isFirstPaymentExpanded) ||
                      this.state.usePayfortWithADCB ||
                      this.state.usePayfortWithMoneyCard) &&
                    this.state.proceedToPay && (
                      <Paypal
                        paymentData={this.state.GPayData}
                        isPaymentMethodBlocked={this.state.isPaymentMethodBlocked}
                        showTnCError={this.showTnCError}
                        cartData={this.state.cart}
                        isEnabled={this.state.isTncChecked}
                        payData={this.payData}
                        partnerReference={this.partnerReference}
                        dueData={this.dueDate}
                        propsData={this.props.data}
                        setRedirectPaymentData={this.setRedirectPaymentData}
                        onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                        parkConsentEnabled={this.state.parkConsentEnabled}
                        guestUserHasErrors={this.guestUserHasErrors}
                        hasADCBProductInCart={this.hasADCBProductInCart}
                        isErrorOccured={this.isErrorOccured}
                        is2StepPaymentYasArena={this.is2StepPaymentYasArena}
                        showPaypalError={this.showPaypalError}
                        hasTnCError={this.state.hasTnCError}
                        emailConfirmationText={this.emailConfirmationText()}
                      />
                    )}
                  {this.state.currentOption === UIConfig.paymentOption.adcbTouchPoints &&
                    this.state.isFirstPaymentExpanded &&
                    this.state.proceedToPay && (
                      <ADCBTouchPoints
                        is2StepPayment={this.is2StepPayment}
                        propsData={this.props.data}
                        payData={this.payData}
                        enablePaymentButton={this.state.enablePaymentButton}
                        cartData={this.state.cart}
                        hideNewsLetter={this.state.hideNewsletterOption}
                        isTabIndex={this.state.tabIndex}
                        hasADCBProductInCart={this.hasADCBProductInCart}
                        showTnCError={this.showTnCError}
                        guestUserHasErrors={this.guestUserHasErrors}
                        isEnabled={this.state.isTncChecked}
                        showCheckBox={true}
                        onTncChangeHandler={this.onTncChangeHandler}
                        isErrorOccured={this.isErrorOccured}
                        setRedirectPaymentData={this.setRedirectPaymentData}
                        usePayFortForTouchPointsPayment={this.usePayFortForTouchPointsPayment}
                        resetPaymentError={this.resetPaymentError}
                        isRenewAnnualPass={this.isRenewAnnualPass}
                        annualPassCartData={this.annualPassCartData}
                        errorData={this.state.errorData}
                        disableBookButton={this.state.disableBookButton}
                        hasTnCError={this.state.hasTnCError}
                        emailConfirmationText={this.emailConfirmationText()}
                        onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                        onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                        parkConsentEnabled={this.state.parkConsentEnabled}
                        newsLetterEnabled={this.state.newsLetterEnabled}
                      />
                    )}
                  {this.state.proceedToPay &&
                  this.is2StepPayment &&
                  this.state.currentOption === UIConfig.paymentOption.aliPay &&
                  this.state.isFirstPaymentExpanded ? (
                    <AliPay
                      is2StepPayment={this.is2StepPayment}
                      cartData={this.state.cart}
                      paymentOptions={this.paymentOptions}
                      services={this.props.data.services}
                      onPayment={this.onPayment}
                      showTnCError={this.showTnCError}
                      isEnabled={this.state.isTncChecked}
                      setRedirectPaymentData={this.setRedirectPaymentData}
                      guestUserHasErrors={this.guestUserHasErrors}
                      isPaymentMethodSwitched={this.isPaymentMethodSwitched}
                      isTabIndex={this.state.tabIndex}
                      currentOption={this.state.currentOption}
                      isErrorOccured={this.isErrorOccured}
                      hasADCBProductInCart={this.hasADCBProductInCart}
                      emailConfirmationText={this.emailConfirmationText()}
                    />
                  ) : (
                    ''
                  )}
                  {this.state.proceedToPay && this.checkPointsPayMethod(this.state.currentOption) && (
                    <PointsPay
                      cartData={this.state.cart}
                      services={this.props.data.services}
                      isEnabled={this.state.isTncChecked}
                      showTnCError={this.showTnCError}
                      openOverlay={this.openOverlay}
                      onTncChangeHandler={this.onTncChangeHandler}
                      is2StepPayment={this.is2StepPayment}
                      paymentOptions={this.paymentOptions}
                      onPayment={this.onPayment}
                      guestUserHasErrors={this.guestUserHasErrors}
                      showCheckBox={true}
                      setRedirectPaymentData={this.setRedirectPaymentData}
                      hasADCBProductInCart={this.hasADCBProductInCart}
                      pointsPayConfig={this.getPointsPayConfig()}
                      isErrorOccured={this.isErrorOccured}
                      isRenewAnnualPass={this.isRenewAnnualPass}
                      hasTnCError={this.state.hasTnCError}
                      hideNewsletterOption={this.state.hideNewsletterOption}
                      disableBookButton={this.state.disableBookButton}
                      tabIndex={this.state.tabIndex}
                      currentOption={this.state.currentOption}
                      emailConfirmationText={this.emailConfirmationText()}
                      onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                      onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                      parkConsentEnabled={this.state.parkConsentEnabled}
                      newsLetterEnabled={this.state.newsLetterEnabled}
                    />
                  )}
                  {this.state.proceedToPay && this.state.currentOption === UIConfig.paymentOption.postPay && (
                    <PostPay
                      cartData={this.state.cart}
                      services={this.props.data.services}
                      isEnabled={this.state.isTncChecked}
                      showTnCError={this.showTnCError}
                      openOverlay={this.openOverlay}
                      onTncChangeHandler={this.onTncChangeHandler}
                      onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                      onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                      parkConsentEnabled={this.state.parkConsentEnabled}
                      newsLetterEnabled={this.state.newsLetterEnabled}
                      is2StepPayment={this.is2StepPayment}
                      paymentOptions={this.paymentOptions}
                      onPayment={this.onPayment}
                      guestUserHasErrors={this.guestUserHasErrors}
                      showCheckBox={true}
                      setRedirectPaymentData={this.setRedirectPaymentData}
                      hasADCBProductInCart={this.hasADCBProductInCart}
                      postPayConfig={this.getPostPayConfig()}
                      isErrorOccured={this.isErrorOccured}
                      isRenewAnnualPass={this.isRenewAnnualPass}
                      hasTnCError={this.state.hasTnCError}
                      hideNewsletterOption={this.state.hideNewsletterOption}
                      disableBookButton={this.state.disableBookButton}
                      tabIndex={this.state.tabIndex}
                      currentOption={this.state.currentOption}
                      emailConfirmationText={this.emailConfirmationText()}
                      isPromoEnable={this.isPromoEnable}
                    />
                  )}
                  {this.state.currentOption === UIConfig.paymentOption.moneyCard &&
                    this.state.isFirstPaymentExpanded &&
                    this.state.proceedToPay && (
                      <MoneyCard
                        is2StepPayment={this.is2StepPayment}
                        propsData={this.props.data}
                        payData={this.payData}
                        enablePaymentButton={this.state.enablePaymentButton}
                        cartData={this.state.cart}
                        hideNewsLetter={this.state.hideNewsletterOption}
                        isTabIndex={this.state.tabIndex}
                        hasADCBProductInCart={this.hasADCBProductInCart}
                        showTnCError={this.showTnCError}
                        guestUserHasErrors={this.guestUserHasErrors}
                        isEnabled={this.state.isTncChecked}
                        showCheckBox={true}
                        onTncChangeHandler={this.onTncChangeHandler}
                        isErrorOccured={this.isErrorOccured}
                        setRedirectPaymentData={this.setRedirectPaymentData}
                        usePayFortForMoneyCard={this.usePayFortForMoneyCard}
                        usePayFortForTouchPointsPayment={this.usePayFortForTouchPointsPayment}
                        resetPaymentError={this.resetPaymentError}
                        isRenewAnnualPass={this.isRenewAnnualPass}
                        annualPassCartData={this.annualPassCartData}
                        errorData={this.state.errorData}
                        disableBookButton={this.state.disableBookButton}
                        hasTnCError={this.state.hasTnCError}
                        emailConfirmationText={this.emailConfirmationText()}
                      />
                    )}
                  {this.paymentOptions.newsLetterLabel && checkTenant(UIConfig.iamMapping.ymc) && (
                    <div className={`${paypalFormClassWrapper} ${this.hasADCBProductInCart ? 'disabled' : ''}`}>
                      <PaymentCheckboxes
                        paymentOptions={this.paymentOptions}
                        openOverlay={this.openOverlay}
                        onTncChangeHandler={this.onTncChangeHandler}
                        onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                        showNewsLetterCheckBoxOnly={true}
                        key="news-letter-checkbox-2"
                        stateData={{
                          showCheckBox: this.state.showCheckBox,
                          hideNewsletterOption: this.state.hideNewsletterOption,
                          disableBookButton: this.state.disableBookButton,
                          hasTnCError: this.state.hasTnCError,
                          tabIndex: this.state.tabIndex,
                          isTncChecked: this.state.isTncChecked,
                          newsLetterEnabled: this.state.newsLetterEnabled,
                          bindCheckBoxError: true,
                        }}
                      />
                    </div>
                  )}
                  {this.state.currentOption === UIConfig.paymentOption.gPay &&
                    this.state.isFirstPaymentExpanded &&
                    this.state.proceedToPay &&
                    this.is2StepPayment && (
                      <GPay
                        services={this.props.data.services}
                        paymentData={this.state.GPayData}
                        isPaymentMethodBlocked={this.state.isPaymentMethodBlocked}
                        showTnCError={this.showTnCError}
                        cartData={this.state.cart}
                        isEnabled={this.state.isTncChecked}
                        newsLetterEnabled={this.state.newsLetterEnabled}
                        payData={this.payData}
                        partnerReference={this.partnerReference}
                        dueData={this.dueDate}
                        propsData={this.props.data}
                        guestUserHasErrors={this.guestUserHasErrors}
                        hasADCBProductInCart={this.hasADCBProductInCart}
                        isErrorOccured={this.isErrorOccured}
                        is2StepPaymentYasArena={this.is2StepPaymentYasArena}
                        emailConfirmationText={this.emailConfirmationText()}
                      ></GPay>
                    )}
                  {this.renderApplePayComponent()}
                  {!this.paymentMethodNotBlocked.includes(this.state.currentOption) &&
                    this.state.isPaymentMethodBlocked && (
                      <p className="coupon-failed-error error-msg body-copy-6">{this.state.blockedErrorMessage}</p>
                    )}
                  {this.paymentOptions.newsLetterLabel && !checkTenant(UIConfig.iamMapping.ymc) && (
                    <div className={`${paypalFormClassWrapper} ${this.hasADCBProductInCart ? 'disabled' : ''}`}>
                      <PaymentCheckboxes
                        paymentOptions={this.paymentOptions}
                        openOverlay={this.openOverlay}
                        onTncChangeHandler={this.onTncChangeHandler}
                        onNewsLetterChangeHandler={this.onNewsLetterChangeHandler}
                        showNewsLetterCheckBoxOnly={true}
                        onParkConsentChangeHandler={this.onParkConsentChangeHandler}
                        cartData={this?.state?.cart}
                        key="news-letter-checkbox-2"
                        stateData={{
                          showCheckBox: this.state.showCheckBox,
                          hideNewsletterOption: this.state.hideNewsletterOption,
                          disableBookButton: this.state.disableBookButton,
                          hasTnCError: this.state.hasTnCError,
                          tabIndex: this.state.tabIndex,
                          isTncChecked: this.state.isTncChecked,
                          parkConsentEnabled: this.state.parkConsentEnabled,
                          newsLetterEnabled: this.state.newsLetterEnabled,
                          bindCheckBoxError: true,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'B2C Payment Component');
    } finally {
      if (this.state.cart === null || (this.state.cart.items && this.state.cart.items.length === 0)) {
        this.isSwad && !detectMobile() && window.PubSub.publish(UIConfig.hiddenPayment, { isCartEmpty: true });
      }
    }
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */
PaymentComponent.propTypes = {
  data: PropTypes.shape({
    defaultOptionSelected: PropTypes.arrayOf(PropTypes.object),
  }),
};
