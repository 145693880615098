import React from 'react';
import { logComponentRenderingError } from '../../../../common/logger';

const InputHidden = (props) => {
  const { field } = props,
    fieldName = field.model.name;

  try {
    return (
      <div className="form-element input-type-hidden">
        <input
          className="form-input body-copy-3"
          name={fieldName}
          type="hidden"
          defaultValue={field.model.text}
          id={fieldName}
        />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'InputHidden');
  }
};

export default InputHidden;
