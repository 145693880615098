import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { canUseDOM, detectMobile, isLoggedInUser, openIAMOverlay, checkGA4Tenants } from '../../../common/utility';
import { DynamicContent } from '../../presentation/base';
import IAMPopup from '../../presentation/iam-popup/iam-popup';

import { arrowDown } from '../../../assets/img';
import { footerClickAnalytics } from '../../../common/analytics-events';
import UIConfig from '../../../common/UIConfig';
import GTMData from '../b2c-purchase-journey/gtm-data';

const ariaExpandedToggle = (e, genId) => {
  if (canUseDOM()) {
    const checkedStatus = document.querySelector('#footer-column-checkbox-' + genId).checked;
    document.querySelector(`[for="footer-column-checkbox-${genId}"]`).setAttribute('aria-expanded', checkedStatus);
  }
};

const setProperty = (genId) => {
  if (canUseDOM() && detectMobile()) {
    const listContainer = document.querySelector(`.sect${genId}`);
    listContainer && listContainer.setAttribute({ 'aria-labelledby': `accordion${genId}id` });
  }
};

const checkLoginPopup = (modifyBookingLink) => {
  if (!isLoggedInUser()) {
    openIAMOverlay(<IAMPopup loginURL={modifyBookingLink.url} />);
  } else {
    window.location.href = modifyBookingLink.href;
  }
};

const isFeedbackLink = (label) => {
  return label?.toLowerCase() === UIConfig.FEEDBACK_LINK_LABEL;
};

const linkClickHandler = (e, variant, label, classVal, categoryTitle) => {
  (variant === UIConfig.YIB2C || checkGA4Tenants([UIConfig.tenants.yi])) &&
    googleAnalyticHandleClickFooter(e, label, categoryTitle);
  isFeedbackLink(classVal) && window && window.handleFeedbackQSIClick && window.handleFeedbackQSIClick();
};
const googleAnalyticHandleClickFooter = (event, data, sectionName) => {
  if (checkGA4Tenants()) {
    GTMData.push(UIConfig.ga4Constants.CLICK_FOOTER, { data, sectionName });
  } else {
    footerClickAnalytics(event, data, sectionName);
  }
};

const FooterColumn = ({ category, genId, arrowAltText, variant }) => {
  const [footerDropdownOpen, setFooterDropdownOpen] = useState(false);
  const toggleState = () => {
    setFooterDropdownOpen(!footerDropdownOpen);
  };

  return (
    <li className={`footer-container-body-links-column ${!category.label ? 'hidden-title' : ''}`}>
      {category.href ? (
        <DynamicContent
          tagName="a"
          attrs={{
            className: 'footer-container-body-links-column-desktop heading-8',
            href: category.href,
            target: category.target,
            onClick:
              variant === UIConfig.YIB2C ? (e) => googleAnalyticHandleClickFooter(e, category.label, category) : '',
          }}
          innerHtml={category.label}
        />
      ) : (
        <DynamicContent
          tagName="label"
          attrs={{
            className: `footer-container-body-links-column-desktop heading-8`,
          }}
          innerHtml={!category.label && variant === UIConfig.YIB2C ? 'Hidden Title' : category.label}
        />
      )}
      {category.linkSubcategories && category.linkSubcategories.length > 0 ? (
        [
          <>
            {variant !== UIConfig.SWADB2C && (
              <input
                id={'footer-column-checkbox-' + genId}
                type="checkbox"
                name="checkbox"
                key={`footer-input-${genId}`}
                onChange={(e) => ariaExpandedToggle(e, genId)}
                {...(variant === UIConfig.YIB2C && { checked: true })}
              />
            )}
          </>,
          <>
            <DynamicContent
              tagName="label"
              attrs={{
                className: 'footer-container-body-links-column-mobile',
                htmlFor: 'footer-column-checkbox-' + genId,
                'aria-expanded': false,
                role: 'tab',
                id: `accordion${genId}id`,
                'aria-controls': `sect${genId}`,
                onClick:
                  variant === UIConfig.YIB2C
                    ? (e) => googleAnalyticHandleClickFooter(e, category.label, category.title)
                    : '',
              }}
              innerHtml={category.label}
              key={`footer-label-${genId}`}
            />
            {variant === UIConfig.SWADB2C && (
              <span
                className={footerDropdownOpen ? 'drop-active dropdown-arrow ' : 'dropdown-arrow'}
                onClick={toggleState}
              ></span>
            )}
          </>,
          <>
            {variant !== UIConfig.SWADB2C && (
              <img className="footer-arrow-icon" src={arrowDown} alt={arrowAltText} key={`footer-img-${genId}`} />
            )}
          </>,
        ]
      ) : (
        <DynamicContent
          tagName="a"
          attrs={{
            className: 'footer-container-body-links-column-mobile',
            href: category.href,
            onClick:
              variant === UIConfig.YIB2C
                ? (e) => googleAnalyticHandleClickFooter(e, category.label, category.title)
                : '',
          }}
          innerHtml={category.label}
        />
      )}
      <ul id={`sect${genId}`}>
        {setProperty(genId)}
        {category.linkSubcategories &&
          category.linkSubcategories.map((sub, i) =>
            sub.label ? (
              <li key={i} className={'body-copy-6'}>
                {sub.href ? (
                  sub.class === 'redirect' && variant !== UIConfig.YIB2C ? (
                    <span className={'modify-booking'} tabIndex="0" onClick={() => checkLoginPopup(sub)}>
                      {sub.label}
                    </span>
                  ) : (
                    <DynamicContent
                      tagName="a"
                      attrs={{
                        className: sub.class,
                        id: isFeedbackLink(sub.class) ? 'feedbackSurvey' : '',
                        href: isFeedbackLink(sub.class) ? 'javascript:void(0);' : sub.href,
                        target: sub.target,
                        onClick: (e) => linkClickHandler(e, variant, sub.label, sub.class, category.title),
                      }}
                      innerHtml={sub.label}
                    />
                  )
                ) : (
                  <DynamicContent tagName="span" innerHtml={sub.label} />
                )}
              </li>
            ) : (
              ''
            ),
          )}
      </ul>
    </li>
  );
};

FooterColumn.propTypes = {
  category: PropTypes.object,
};

export default FooterColumn;
