import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PromotionService } from '../../../../../common/services';
import {
  deepCloneObject,
  isF1SeatedTypeProduct,
  canUseDOM,
  isEmpty,
  detectMobile,
  resolvePath,
  checkTenant,
} from '../../../../../common/utility';
import AddOnsEventOverlayComponent from './add-ons-event-overlay';
import SeatedJourneyActions from '../../cart/seated-journey-actions';
import userBeforeFirstRender from '../../../../../hooks/useBeforeFirstRender';
import UIConfig from '../../../../../common/UIConfig';
import { logComponentRenderingError } from '../../../../../common/logger';

const AddOnsEventComponent = (props) => {
  const isProductOverlaySelector = resolvePath(props, 'productOverlaySelector.collections', '');
  const product = useRef(deepCloneObject(props.product));
  const isSeatedTypeProduct = isF1SeatedTypeProduct(product.current);
  const disableOffers = !props.data.disableOffers;
  const compareDiscountOfferPrices = props.data.compareDiscountOfferPrices;
  const isPlpVariant = props.variant === 'v-overlay-selected';
  const isMobile = detectMobile();
  const seatedJourneyActions = useRef(null);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  userBeforeFirstRender(() => {
    if (isSeatedTypeProduct && !isProductOverlaySelector) {
      seatedJourneyActions.current = new SeatedJourneyActions();
    }
  });

  const redirectSeatedProduct = () => {
    seatedJourneyActions.current.handleRedirection({
      serviceUrl: props.services,
      product: product.current,
      showRedirectLoader: true,
    });
  };

  const handleSeatedTypeProduct = () => {
    if (disableOffers) {
      getPromotionProducts();
    } else {
      redirectSeatedProduct();
    }
  };

  useEffect(() => {
    product.current.performanceId = product.current.performanceAk;
    canUseDOM() &&
      window.PubSub.subscribe('closeOverlay', () => {
        if (!isPlpVariant) {
          setIsOverlayOpen(false);
        }
      });
    if (isSeatedTypeProduct && !isProductOverlaySelector && isPlpVariant) {
      handleSeatedTypeProduct();
    } else if (
      checkTenant(UIConfig.iamMapping.ymc) &&
      !isSeatedTypeProduct &&
      isProductOverlaySelector?.length > 1 &&
      isPlpVariant
    ) {
      setIsOverlayOpen(isProductOverlaySelector[0]?.productId === product.current.productId);
    } else if (isPlpVariant) {
      setIsOverlayOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const classToAppend = classNames('addOns-Overlay calendar-overlay-genral-admission', {
      'addons-event-overlay': isAddEventOverlayClass(),
    });
    canUseDOM() &&
      isOverlayOpen &&
      window.PubSub.publish('toggleOverlayState', {
        shouldOpen: true,
        customClass: classToAppend,
        dataToAppend: componentToAppend(),
      });
  }, [isOverlayOpen]);

  const updateBaseProduct = (prod) => {
    if (!isEmpty(prod)) {
      product.current = combineCloneObject(product.current, prod);
    }
  };

  const updateBaseProductBasedOnPricesCheck = (prod) => {
    if (compareDiscountOfferPrices) {
      if (parseFloat(product.current.gross).toFixed(2) > parseFloat(prod.gross).toFixed(2)) {
        updateBaseProduct(prod);
      }
    } else {
      updateBaseProduct(prod);
    }
  };

  const setErrorState = (errObj, errorcode) => {
    let error = {};
    if (errObj) {
      error = errObj;
    } else if (errorcode) {
      error = { errorcode };
    }
    if (isPlpVariant) {
      const servicesErrObj = props.services.getPromotion.errors;
      closeOverlay();
      props.overlayErrorCallback(error, servicesErrObj);
    }
  };

  const getPromotionProducts = () => {
    const url = props.services.getPromotion.url.replace('{0}', product.current.performanceId);
    PromotionService.getPromotionData(url, true, '.b2c-booking-tabs-wrapper')
      .then((response) => {
        if (resolvePath(response, 'data.products', '')) {
          const perfProduct = response.data.products;
          if (perfProduct && perfProduct.length) {
            checkPromotionProduct(perfProduct);
          } else {
            setErrorState(null, 'C7001'); // Error Products are not available
          }
        }
      })
      .catch((err) => {
        if (err.error && err.error.errorcode) {
          setErrorState(err.error);
        } else {
          setErrorState(null, '501');
        }
      });
  };

  const filterOutOfferProductsCoveo = (baseProduct = []) => {
    return (
      props.searchProductList &&
      props.searchProductList.filter((item) => {
        let matchedData = false;
        baseProduct.forEach((prod) => {
          if (item.code === prod.code && item.productId !== prod.productId) {
            matchedData = true;
          }
        });
        return matchedData;
      })
    );
  };

  const filterPromotionProduct = (availablePromProduct, coveoProducts) => {
    let promotionProduct = [];
    availablePromProduct.forEach((item) => {
      item.productId.forEach((prodId) => {
        const searchProduct = coveoProducts.find((prod) => prod.productId === prodId);
        searchProduct && promotionProduct.push(searchProduct);
      });
    });
    return promotionProduct;
  };

  const checkPromotionProduct = (availableProProduct) => {
    const coveoBaseProducts = filterOutOfferProductsCoveo([product.current]) || [];
    const promotionProducts = filterPromotionProduct(availableProProduct, coveoBaseProducts);
    if (promotionProducts && promotionProducts.length) {
      updateBaseProductBasedOnPricesCheck(promotionProducts[0]);
    }
    redirectSeatedProduct();
  };

  const combineCloneObject = (obj, copyObj) => {
    return [obj, copyObj].reduce((primaryProd, secProd) => {
      Object.keys(secProd).forEach((key) => {
        primaryProd[key] = secProd[key];
      });
      return primaryProd;
    }, {});
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const componentToAppend = () => {
    return <AddOnsEventOverlayComponent {...props} closeOverlay={closeOverlay} />;
  };

  const handleOnClick = () => {
    if (isSeatedTypeProduct && !isProductOverlaySelector) {
      handleSeatedTypeProduct();
    } else {
      setIsOverlayOpen(true);
    }
  };

  const isAddEventOverlayClass = () => {
    let isClassReq = true;
    const { eventType, seatType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    if (
      isProductOverlaySelector &&
      product.current.eventType &&
      product.current.eventType.toLowerCase() === eventType.f1 &&
      product.current.seatType
    ) {
      if (product.current.seatType.toLowerCase() === seatType.nonSeated) {
        isClassReq = false;
      } else if (isMobile && resolvePath(props, 'counterData.description', '')) {
        isClassReq = false;
      }
    }
    return isClassReq;
  };

  try {
    return (
      <div className="btn-primary">
        {!isPlpVariant && (
          <button type="button" onClick={handleOnClick}>
            {props.data.pjSelectLabel}
          </button>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'AddOnsEventComponent');
  }
};

AddOnsEventComponent.PropsTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
};

export default AddOnsEventComponent;
