import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UIConfig from '../../../../../common/UIConfig';
import ApiWrapper from '../../../../../common/api-wrapper';
import { DynamicContent, ErrorSummary, Image } from '../../../../presentation/base';
import CounterComponent from '../../visitors-count-selector/visitors-count-selector-component';
import { PerformanceService, PromotionService } from '../../../../../common/services';
import DropDown from '../../../../presentation/drop-down';
import {
  toTwoDecimalPlaces,
  deepCloneObject,
  scrollTo,
  resolvePath,
  isF1SeatedTypeProduct,
  isEmpty,
  checkEventTypeProduct,
  addLoaderOverlay,
} from '../../../../../common/utility';
import SeatedJourneyActions from '../../cart/seated-journey-actions';
import { logComponentRenderingError } from '../../../../../common/logger';

export default class AddOnsEventOverlayComponent extends Component {
  constructor(props) {
    super(props);
    this.additionalProducts = [];
    this.overlaySelAvailable = false;
    this.isProductOverlaySelector = props.productOverlaySelector && props.productOverlaySelector.collections;
    this.productOverlaySelector = props.productOverlaySelector || {};
    this.overlaySelMap = this.getOverlaySelMapping();
    this.counterData = props.counterData;
    this.maxCount = props.counterData.maxCount;
    this.defaultSelAdditionalProds = props.additionalProds ? this.getDefaultAdditionalProd() : false;
    this.product = this.defaultSelAdditionalProds ? this.defaultSelAdditionalProds : deepCloneObject(props.product);
    const prodPriceLabel = `${props.data.currency} ${toTwoDecimalPlaces(parseFloat(this.product.gross))}`;
    props.counterData.options[0].description = prodPriceLabel;
    this.ticketValidity = props.calenderData && props.calenderData.options[0].ticketValidity;
    this.isSeatedTypeProduct = isF1SeatedTypeProduct(this.product);
    this.isF1TypeProduct = this.checkF1EventTypeProduct();
    this.isNonSeated = this.checkNonSeated();
    this.tnc = this.checkTnc();
    this.sectorAvailable = false;
    this.updatedAdditionalProducts = this.additionalProducts;
    this.disableOffers = !props.data.disableOffers;
    this.compareDiscountOfferPrices = props.data.compareDiscountOfferPrices;
    this.state = {
      totalCalculatedAmount: this.isSeatedTypeProduct ? this.getTotalAmountSeated(this.product) : 0,
      ticketCount: {},
      disableAddToCart: true,
      isErrorOccurred: false,
      error: {},
      sectorsDropdown: [],
      sectorQuantity: 0,
    };
    if (this.isSeatedTypeProduct) {
      this.seatedJourneyActions = new SeatedJourneyActions();
    }
  }

  checkF1EventTypeProduct = () => {
    const { f1 } = UIConfig.b2c.purchaseJourney.ymcMapping.eventType;
    return checkEventTypeProduct(this.product, f1);
  };

  getTotalAmountSeated = (product) => {
    const amount = parseFloat(product.gross).toFixed(2) * 1;
    return toTwoDecimalPlaces(amount);
  };

  checkTnc = () => {
    const isCheckTnc =
      resolvePath(this.props, 'multiTicketSelector.hideTnC', '') || resolvePath(this.props, 'timeSlotData.hideTnC', '');
    let checkTnc = true;
    if (!isCheckTnc && (this.props.showMinicartCouponCode || this.props.enablePJPopup)) {
      checkTnc = isCheckTnc;
    }
    return checkTnc;
  };

  getOverlaySelMapping = () => {
    let overlaySelMap = {};
    if (this.isProductOverlaySelector) {
      for (let itm in this.productOverlaySelector.collections) {
        const coveoVal = this.productOverlaySelector.collections[itm].coveoval;
        if (coveoVal) {
          overlaySelMap[coveoVal] = this.productOverlaySelector.collections[itm].defaultchecked;
        }
        if (this.productOverlaySelector.collections[itm].defaultchecked === true) this.overlaySelAvailable = true;
      }
    }
    return overlaySelMap;
  };
  getDefaultAdditionalProd = () => {
    if (this.overlaySelAvailable) {
      // Change the coveo items order as per CMS
      const newProdObjTemp = {};
      this.props.additionalProds.forEach((prod, indx) => {
        let prodCoveoKey = this.productOverlaySelector.collections[indx].coveokey;
        newProdObjTemp[prod[this.props.coveoMapList[prodCoveoKey]]] = prod;
      });
      let cntr = 0;
      Object.keys(this.overlaySelMap).forEach((itm) => {
        this.additionalProducts[cntr] = newProdObjTemp[itm];
        cntr += 1;
      });
      // xxxxxxx Order change code ends here
    }

    var addProd = [];
    if (this.overlaySelAvailable) {
      addProd = this.additionalProducts.filter((prod, indx) => {
        let prodCoveoKey = this.productOverlaySelector.collections[indx].coveokey;
        return this.overlaySelMap[prod[this.props.coveoMapList[prodCoveoKey]]] === true;
      });
    } else {
      addProd.push(this.props.additionalProds[0]);
    }
    return addProd[0];
  };

  scrollToNextSection = (section) => {
    let sectionEle = document.getElementsByClassName(section)[0];
    scrollTo(document.getElementsByClassName('overlay-wrapper')[0], sectionEle.nextElementSibling.offsetTop, 500);
  };

  redirectToBookingPageCallback = (checkoutContext) => {
    if (!this.props.showMinicartCouponCode && !(checkoutContext && checkoutContext.href)) {
      addLoaderOverlay();
      window.location.href = this.props.data.addToCartCTA.href;
      this.props.closeOverlay();
    }
  };

  addToCart = (checkoutContext) => {
    this.product.performanceId = this.product.performanceAk;
    if (this.props.counterData) {
      this.product.maxCount = this.props.counterData.maxCount;
      this.product.minCount =
        this.props.counterData.options[0].defaultQuantity || this.props.counterData.options[0].minimumQuantity;
    }
    if (this.isF1TypeProduct) {
      this.product.shippingEventType = this.product.eventType;
    }

    if (this.isSeatedTypeProduct) {
      this.seatedJourneyActions.handleRedirection({
        serviceUrl: this.props.services,
        product: this.product,
        showRedirectLoader: true,
      });
    } else {
      this.props.addToCartCallback &&
        this.props.addToCartCallback([this.product], true, checkoutContext, () =>
          this.redirectToBookingPageCallback(checkoutContext),
        );
    }

    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: false,
    });
    this.props.closeOverlay();
  };

  updateButtonState = () => {
    let disable = true;
    let termsAndConCheck = !this.tnc ? this.termsAndCondCheck : true;
    let sectorRendered = this.sectorAvailable;
    let sectorCheck = sectorRendered ? sectorRendered && this.product.sector : true;
    if (
      sectorCheck &&
      termsAndConCheck &&
      parseInt(this.state.totalCalculatedAmount, 10) &&
      !this.state.isErrorOccurred
    ) {
      disable = false;
    }
    this.setState({ disableAddToCart: disable });
  };

  errorSpinnerButtonDisabled = (disable) => {
    this.setState({ disableAddToCart: disable });
  };

  setVisitorsCount = (data) => {
    this.setState({ ticketCount: data.spinnerCount }, () => {
      this.calculateTotal();
      this.updateButtonState();
    });
  };

  calculateTotal = () => {
    let totalCalculatedAmount = 0;
    const { primaryCounterCount } = this.state.ticketCount;
    if (this.isSeatedTypeProduct) {
      totalCalculatedAmount = this.getTotalAmountSeated(this.product);
      this.setState({ totalCalculatedAmount }, () => this.updateButtonState());
    } else {
      for (const category in primaryCounterCount) {
        if (primaryCounterCount[category] > 0) {
          this.product.quantity = primaryCounterCount[category];
          totalCalculatedAmount += parseFloat(this.product.gross).toFixed(2) * primaryCounterCount[category];
          this.setState({ totalCalculatedAmount: toTwoDecimalPlaces(totalCalculatedAmount) }, () =>
            this.updateButtonState(),
          );
        }
      }
    }
  };

  updateOverlaySelectorProducts = (products = [], productFoundArr = []) => {
    productFoundArr.length !== this.updatedAdditionalProducts.length &&
      products.forEach((seatCat) => {
        // check for super park type product
        this.updatedAdditionalProducts.forEach((item) => {
          if (productFoundArr.indexOf(item) === -1) {
            const matchedProduct = seatCat.productList.find((product) => {
              return product.productAk === item.productId;
            });
            if (matchedProduct) {
              productFoundArr.push(matchedProduct.productAk);
              const maxAvailableTickets = parseInt(seatCat.available, 10);
              item.maxAvailableTickets = maxAvailableTickets;
            }
          }
        });
      });
    return productFoundArr;
  };

  getNonSeatedPerformances = () => {
    const url = this.props.services.getPerformanceCapacityAndProduct.url.replace('{0}', this.product.performanceId);
    PerformanceService.getPerformanceCapacityAndProduct(url, true, '.addOns-Overlay').then((response) => {
      let isProductFound = false;
      const productInfo = resolvePath(response, 'data.ProductCapacityDetails.productInfo', []);
      let productFoundArr = [];
      productInfo.forEach((products) => {
        if (this.isProductOverlaySelector) {
          productFoundArr = this.updateOverlaySelectorProducts(products.seatCategory, productFoundArr);
        }
        !isProductFound &&
          products.seatCategory.forEach((seatCat) => {
            const matchedProduct = seatCat.productList.find((product) => {
              return product.productAk === this.product.productId;
            });
            if (matchedProduct) {
              isProductFound = true;
              const maxAvailableTickets = parseInt(seatCat.available, 10);
              this.product.maxAvailableTickets = maxAvailableTickets;
              this.calculateTotal();
            }
          });
      });
    });
  };

  checkNonSeated = () => {
    const { eventType, seatType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    if (
      this.product.eventType &&
      ((this.product.eventType.toLowerCase() === eventType.f1 &&
        this.product.seatType &&
        this.product.seatType.toLowerCase() === seatType.nonSeated) ||
        this.product.eventType.toLowerCase() === eventType.wrx)
    ) {
      return true;
    }
    return false;
  };

  componentDidMount() {
    this.props.counterData.options[0].description =
      this.props.data.currency + ' ' + toTwoDecimalPlaces(parseFloat(this.product.gross));
    if (this.product && this.product.performanceAk) {
      this.product.performanceId = this.product.performanceAk;
    }
    this.isNonSeated && this.getNonSeatedPerformances();
    this.disableOffers && this.getPromotionProducts();
    // Sector Capacity API Fucntion
    this.renderSectorsSection(this.props);
    // Sector Capacity API Fucntion
  }

  renderSectorsSection = (props) => {
    const { multiTicketSelector } = this.props,
      sections = multiTicketSelector.options,
      keyMap = multiTicketSelector.stringMapping;
    sections &&
      sections.map((item) => {
        switch (item.identifier) {
          case keyMap.sectors:
            this.apiSectorCapacity(props, item);
        }
      });
  };

  apiSectorCapacity = (propsData, item) => {
    const sectorsArr = this.getSectorsKey(item.sectors);
    const sectorsKeyData = { Keys: sectorsArr };
    // Sector Capacity API For Showing the Seats Capacity //
    ApiWrapper.platformService({
      url: propsData.services.getRedisKeysData.url,
      method: 'POST',
      tokenRequired: false,
      data: sectorsKeyData,
      moduleName: 'Tab-filter-listing-add-ons-event-overlay',
    })
      .then((response) => {
        this.mergeSectorsArray(response.data.result, item.sectors);
      })
      .catch((response) => {
        this.setErrorState(response.error);
      });
  };

  getSectorsKey = (item) => {
    let arrSectorsKey = [];
    item &&
      item.map((data) => {
        arrSectorsKey.push(data.key);
      });
    return arrSectorsKey;
  };

  mergeSectorsArray = (response, arrSectors) => {
    const availabilityText = this.props.data.sectorCapacityTracker.availabilityText;
    const sectorThreshholdValue = parseInt(this.props.data.sectorCapacityTracker.sectorThreshholdValue);
    let arrayNewSectors = [];
    if (response && response.length > 0 && arrSectors && arrSectors.length > 0) {
      response.map((res) => {
        arrSectors.map((sec) => {
          if (res.key === sec.key) {
            if (res.remaningQty > 0) {
              arrayNewSectors.push({
                value: sec.value,
                text: `${sec.value} ${
                  res.remaningQty <= sectorThreshholdValue ? ` - ${res.remaningQty} ${availabilityText}` : ''
                }`,
                quantity: res.remaningQty,
                key: sec.key,
              });
            }
          }
        });
      });
    }
    this.setState({ sectorsDropdown: arrayNewSectors });
  };

  renderErrorMsg = () => {
    const error = this.state.error;
    let errorText = '';
    if (!isEmpty(error) && error.errorcode) {
      const errors = this.props.services.getPromotion.errors;
      if (errors && errors[error.errorcode]) {
        errorText = errors[error.errorcode];
      } else if (error && error.errordescription) {
        errorText = error.errordescription;
      }
      return (
        <ErrorSummary
          data={{
            error: {
              code: error.errorcode,
              text: errorText,
            },
          }}
        />
      );
    }
    return '';
  };

  setErrorState = (errObj, errorcode) => {
    let error = {};
    if (errObj) {
      error = errObj;
    } else if (errorcode) {
      error = { errorcode };
    }
    this.setState({ error, isErrorOccurred: true }, () => this.updateButtonState());
  };

  getPromotionProducts = () => {
    const url = this.props.services.getPromotion.url.replace('{0}', this.product.performanceId);
    PromotionService.getPromotionData(url, true, '.addOns-Overlay')
      .then((response) => {
        if (response.data && response.data.products) {
          const perfProduct = response.data.products;
          if (perfProduct && perfProduct.length) {
            this.checkPromotionProduct(perfProduct);
          } else {
            this.setErrorState(null, 'C7001'); // Products are not available
          }
        }
      })
      .catch((err) => {
        if (err.error && err.error.errorcode) {
          this.setErrorState(err.error);
        } else {
          this.setErrorState(null, '501');
        }
      });
  };

  filterOutOfferProductsCoveo = (baseProduct = []) => {
    return (
      this.props.searchProductList &&
      this.props.searchProductList.filter((item) => {
        let matchedData = false;
        baseProduct.forEach((prod) => {
          if (item.code === prod.code && item.productId !== prod.productId) {
            matchedData = true;
          }
        });
        return matchedData;
      })
    );
  };

  filterPromotionProduct = (availablePromProduct, coveoProducts) => {
    let promotionProduct = [];
    availablePromProduct.forEach((item) => {
      item.productId.forEach((prodId) => {
        const searchProduct = coveoProducts.find((prod) => prod.productId === prodId);
        searchProduct && promotionProduct.push(searchProduct);
      });
    });
    return promotionProduct;
  };

  updateProductOverlayBaseProduct = (products) => {
    this.updatedAdditionalProducts = products;
    // update base product here for offered Product
    const baseProduct = products.find((item) => item.code === this.product.code);
    if (baseProduct) {
      this.updateBaseProductBasedOnPricesCheck(baseProduct);
    }
  };

  getBaseProductPrice = (product) => {
    if (!this.isF1TypeProduct) {
      return `${this.props.data.currency} ${toTwoDecimalPlaces(parseFloat(product.gross))}`;
    }
    return '';
  };

  updateComparePricesProductOverlay = (baseProd, newProd) => {
    const basePrice = this.getBaseProductPrice(baseProd);
    if (this.compareDiscountOfferPrices) {
      if (parseFloat(baseProd.gross).toFixed(2) > parseFloat(newProd.gross).toFixed(2)) {
        newProd.baseProductPrice = basePrice;
      }
    } else {
      newProd.baseProductPrice = basePrice;
    }
    return newProd;
  };

  createUpdatedAddProds = (products) => {
    const updatedProducts = [];
    let product = {};
    this.additionalProducts.forEach((item) => {
      const availableProd = products.find((prod) => item.code === prod.code);
      if (availableProd) {
        const prod = this.updateComparePricesProductOverlay(item, availableProd);
        updatedProducts.push(prod);
      } else {
        product = deepCloneObject(item);
        product.baseProductPrice = '';
        updatedProducts.push(item);
      }
    });
    this.updateProductOverlayBaseProduct(updatedProducts);
  };

  checkPromotionProduct = (availableProProduct) => {
    let promotionProduct = {};
    let products = [this.product];
    if (this.isProductOverlaySelector) {
      products = this.additionalProducts;
    }
    const coveoBaseProducts = this.filterOutOfferProductsCoveo(products) || [];
    const promotionProducts =
      (coveoBaseProducts &&
        coveoBaseProducts.length &&
        this.filterPromotionProduct(availableProProduct, coveoBaseProducts)) ||
      [];
    // created merge updated additional Products
    if (this.isProductOverlaySelector) {
      this.createUpdatedAddProds(promotionProducts);
    } else {
      if (promotionProducts && promotionProducts.length) {
        promotionProduct = promotionProducts[0];
      }
      this.updateBaseProductBasedOnPricesCheck(promotionProduct, true);
    }
  };

  updatedVisitorCounterPrice = (primaryProduct, idx) => {
    this.props.counterData.options[idx].description = `${this.props.data.currency} ${toTwoDecimalPlaces(
      parseFloat(primaryProduct.gross),
    )}`;
    this.props.counterData.options[idx].baseProductPrice = primaryProduct.baseProductPrice;
    this.calculateTotal();
  };

  updateBaseProduct = (prod, isPriceUpdateRequired) => {
    if (!isEmpty(prod)) {
      if (isPriceUpdateRequired) {
        prod.baseProductPrice = this.getBaseProductPrice(prod);
      }
      this.product = this.combineCloneObject(this.product, prod);
      this.updatedVisitorCounterPrice(this.product, 0);
    }
  };

  updateBaseProductBasedOnPricesCheck = (prod, isPriceUpdateRequired) => {
    if (this.compareDiscountOfferPrices) {
      if (parseFloat(this.product.gross).toFixed(2) > parseFloat(prod.gross).toFixed(2)) {
        this.updateBaseProduct(prod, isPriceUpdateRequired);
      }
    } else {
      this.updateBaseProduct(prod, isPriceUpdateRequired);
    }
  };

  combineCloneObject = (obj, copyObj) => {
    return [obj, copyObj].reduce(function(primaryProd, secProd) {
      Object.keys(secProd).forEach(function(key) {
        primaryProd[key] = secProd[key];
      });
      return primaryProd;
    }, {});
  };

  renderTotalAmount = () => {
    return (
      <div
        className="ticket-total-amount-wrapper"
        data-product={`{productId:${this.product.productId},performanceAk:${this.product.performanceAk}}`}
      >
        <div className={`total-amount ${this.state.totalCalculatedAmount === '' ? 'disabled' : ''}`}>
          <h3>{this.props.data.total}</h3>
          <div className="amount">
            <h3>
              {this.props.data.currency} {this.state.totalCalculatedAmount}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  addCarsCallBack = (evt) => {
    const currentProduct = deepCloneObject(JSON.parse(evt.target.dataset.prod));
    this.product = currentProduct;
    this.updatedVisitorCounterPrice(this.product, 0);
    this.scrollToNextSection('event-sections');
  };

  getDefaultCheckedValue = (prod) => {
    if (prod) {
      return Boolean(
        (prod.ticketVal && this.overlaySelMap && this.overlaySelMap[prod.ticketVal]) ||
          prod.productId === this.defaultSelAdditionalProds.productId,
      );
    }
    return false;
  };

  bindCars = (itms) => {
    const cars = [],
      carProds = this.updatedAdditionalProducts || [];
    let idx,
      carsProdLen = carProds.length;
    for (idx = 0; idx < carsProdLen; idx++) {
      cars.push(
        <li className="exctmnt--list-items" key={idx}>
          <input
            type="radio"
            name="secPrds"
            id={'secPrds' + idx}
            data-value={idx}
            data-prod={JSON.stringify(carProds[idx])}
            value={idx}
            defaultChecked={this.getDefaultCheckedValue(carProds[idx])}
            onChange={this.addCarsCallBack}
            onClick={this.addCarsCallBack}
            tabIndex={
              carProds[idx].productId ===
              (resolvePath(this.product, 'productId') || this.defaultSelAdditionalProds.productId)
                ? '0'
                : '-1'
            }
          />
          <label htmlFor={'secPrds' + idx}>
            <div className="radio"></div>
            <p className="title">{itms.collections[idx].title}</p>
            <p className="price">
              <span className="price--currency">{`${itms.collections[idx].price || ''}`}</span>
            </p>
          </label>
        </li>,
      );
    }
    return cars;
  };
  renderCarsAddOns = () => {
    let prodOS = this.productOverlaySelector;
    const description = resolvePath(this.props, 'counterData.description', '');
    return (
      <div className="exctmnt section event-sections">
        <DynamicContent
          tagName="p"
          innerHtml={prodOS.title}
          attrs={{
            className: 'exctmnt--title',
          }}
        />
        {description && (
          <DynamicContent
            attrs={{ className: 'body-2 c-primary-section-description' }}
            tagName="p"
            innerHtml={description}
          />
        )}
        <ul className="exctmnt--wrapper">{this.bindCars(prodOS)}</ul>
      </div>
    );
  };

  termAndCondCallback = (event) => {
    this.termsAndCondCheck = event.target.checked;
    this.updateButtonState();
  };

  sectorCallBack = (data) => {
    this.setState({ sectorQuantity: data.quantity });
    this.product.sector = data.text;
    this.product.sectorId = data.key;
    this.updateButtonState();
    // this.scrollToNextSection('drvLcns');
  };

  renderSector = (item) => {
    this.sectorAvailable = true;
    return (
      <div className="drvLcns section sector-section">
        <DynamicContent
          tagName="p"
          innerHtml={item.title}
          attrs={{
            className: 'drvLcns--title',
          }}
        />
        <DynamicContent
          tagName="p"
          innerHtml={item.description}
          attrs={{
            className: 'drvLcns--sub-title',
          }}
        />
        <DropDown
          listItems={this.state.sectorsDropdown}
          defaultValue={this.product.sector ? this.product.sector : item.defaultText}
          callBackFunction={this.sectorCallBack}
          isUpdateDropdownProps={true}
          showSelected="true"
        />
        <DynamicContent
          tagName="p"
          innerHtml={item.tnctext}
          attrs={{
            className: 'drvLcns--verbose',
          }}
        />
      </div>
    );
  };

  renderSections = () => {
    const { multiTicketSelector } = this.props,
      sections = multiTicketSelector.options,
      keyMap = multiTicketSelector.stringMapping;
    return (
      sections &&
      sections.map((item) => {
        switch (item.identifier) {
          case keyMap.sectors:
            return this.renderSector(item);
          default:
            return null;
        }
      })
    );
  };

  renderSectorImage = (sectorImage) => {
    return (
      <div className="sector-image section">
        <Image image={sectorImage} />
      </div>
    );
  };

  renderQuantity = () => {
    const { additionalProds, multiTicketSelector } = this.props;
    const options = resolvePath(multiTicketSelector, 'options', []);
    const sectors = options.find(({ identifier }) => identifier === UIConfig.f1Listing.SECTORS);
    const showCars = this.isProductOverlaySelector && additionalProds && additionalProds.length ? true : false;
    const { disableAddToCart } = this.state;
    let hideQtySelector = this.maxCount <= 1 ? true : false;
    if (this.product.maxAvailableTickets) {
      this.maxCount = this.product.maxAvailableTickets;
    }

    const overlayClasses = classNames('c-add-ons-overlay event-quantity-overlay', {
      'add-ons-seated-type': this.isSeatedTypeProduct,
    });
    const tncClasses = classNames('t-n-c ', { 't-n-c-hide': this.tnc });
    const btnClasses = classNames('btn-primary', { disabled: disableAddToCart });
    const continueCheckoutCTA = resolvePath(this.props, 'data.continueCheckoutCTA', '');

    return (
      <div className={overlayClasses}>
        {showCars && this.renderCarsAddOns()}
        {!this.isSeatedTypeProduct && (
          <CounterComponent
            data={{ primaryCounter: this.props.counterData }}
            name={this.props.product.productName}
            setVisitorData={this.setVisitorsCount}
            maxAvailableTicketsCount={this.maxCount}
            hideQtySelector={hideQtySelector}
            isEventOverlayDescription={!this.isProductOverlaySelector}
            hideDescription={true}
            addToCartClicked={false}
            timeSlotAvailable={this.props.data.timeSlotSelector}
            sectorQuantity={this.state.sectorQuantity}
            sectorErrorMessage={this.props.data.calenderLabels.nonAvailabilityLabel}
            buttonDisabledOnError={this.errorSpinnerButtonDisabled}
          />
        )}
        {this.state.isErrorOccurred && this.renderErrorMsg()}
        {/* Adding for sectors required for f1 listing */}
        {!isEmpty(multiTicketSelector) && !isEmpty(sectors) && this.renderSections()}
        {!isEmpty(multiTicketSelector) &&
          !isEmpty(sectors) &&
          sectors.sectorImage &&
          this.renderSectorImage(sectors.sectorImage)}
        {this.renderTotalAmount()}
        {!this.tnc && (
          <div className={tncClasses}>
            <label htmlFor="tAndCCheckbox" className="checkbox-label">
              <input type="checkbox" onClick={this.termAndCondCallback} className="hide" id="tAndCCheckbox" />
              <div className="checkbox"></div>
              <DynamicContent
                tagName="div"
                attrs={{ className: 't-n-c--label' }}
                innerHtml={
                  resolvePath(this.props, 'multiTicketSelector.tnc', '') ||
                  resolvePath(this.props, 'timeSlotData.tnc', '')
                }
              />
            </label>
          </div>
        )}
        <div className="btn-add-to-cart">
          <div className={btnClasses}>
            <DynamicContent
              tagName="button"
              innerHtml={resolvePath(this.props, 'data.addToCartCTA.label', '') || this.props.data.addToCart}
              attrs={{
                onClick: this.addToCart,
                disabled: disableAddToCart,
                className: 'add-to-cart',
              }}
            />
          </div>
        </div>
        {!this.isSeatedTypeProduct && continueCheckoutCTA && (
          <div className="btn-checkout">
            <div className={btnClasses}>
              <DynamicContent
                tagName="button"
                innerHtml={continueCheckoutCTA.label}
                attrs={{
                  onClick: () => this.addToCart(continueCheckoutCTA),
                  disabled: this.state.disableAddToCart,
                  className: 'continue-checkout',
                }}
              />
            </div>
          </div>
        )}
        {this.state.slotSelected && (
          <div className="vat-info">
            <DynamicContent tagName="p" attrs={{ className: 'vat-info-label' }} innerHtml={this.props.data.vatInfo} />
          </div>
        )}
      </div>
    );
  };

  render() {
    try {
      return <div>{this.renderQuantity()}</div>;
    } catch (err) {
      return logComponentRenderingError(err, 'AddOnsEventOverlayComponent');
    }
  }
}

AddOnsEventOverlayComponent.PropsTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
};
