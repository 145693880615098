import React, { Component } from 'react';
import { UIConfig } from '../../../..';
import { checkIfParks, checkTenant } from '../../../common/utility';

import PurchaseJourney from '../../container/b2c-purchase-journey/purchase-journey-wrapper/index';

class HeaderMiniCart extends Component {
  constructor(props) {
    super(props);
    this.cartObj = props;
    this.isParks = checkIfParks();
  }
  render() {
    const { isHeaderMiniCartEnabled, isHeaderMiniCartEnabledOnBookingToPaymentPages } = this.cartObj.data;
    return (
      <div className="header-miniCart">
        {this.cartObj.data && (
          <PurchaseJourney
            data={this.cartObj.data}
            isMiniCart={isHeaderMiniCartEnabled && !isHeaderMiniCartEnabledOnBookingToPaymentPages}
            hideToastNotificationOfCart={true}
            isHeader={this.isParks}
            updatedCart={this.cartObj.updatedCart}
          />
        )}
      </div>
    );
  }
}

export default HeaderMiniCart;
