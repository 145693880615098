import React from 'react';
import TileComponent from './tile-component';

import AddOnsGrouppedTicketComponent from '../add-ons/add-ons-groupped-ticket-component';
import { secProds } from '../add-ons/add-ons-utility.js';
import { createDeepCopy } from '../../../../common/utility';
import get from 'lodash/get';
import { logComponentRenderingError } from '../../../../common/logger';
import classNames from 'classnames';

class CalenderCategoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.category = this.getProductsCategoryFromProps();
    this.cartProducts = [];
    this.aryExist = false;
    this.isPJOnly = props.data.cssClass === 'v-multi-product-widget';
    this.state = {
      disableAddToCart: true,
    };
  }

  getProductsCategoryFromProps = () => {
    return this.props.specialTicket
      ? this.props.data.controls.specialTicket.options.filter(
          (opt) => opt.ticketSelector.coveoValue === this.props.category,
        )[0]
      : this.props.data.controls.extras.options.filter((opt) => opt.coveoValue === this.props.category)[0];
  };

  componentWillReceiveProps = () => {
    this.aryExist = true;
  };

  updateSecProdArray = (prod, type, map, isCategoryMappingExist) => {
    secProds[type] = secProds[type] || {};
    switch (prod.experienceCatgory) {
      case map.secondary:
        secProds[type].secondary = secProds[type].secondary || [];
        secProds[type].secondary.push(prod);
        break;
      case map.addOnGuest:
        secProds.addOnGuest = secProds.addOnGuest || [];
        secProds.addOnGuest.push(prod);
        break;
      case map.primary:
        secProds[type].primary = secProds[type].primary || [];
        secProds[type].primary.push(prod);
        break;
      default:
        break;
    }
  };

  renderGrouppedTicket = (products) => {
    return (
      <div>
        <AddOnsGrouppedTicketComponent
          data={this.props.data}
          key={this.category.coveoValue}
          allTickets={products}
          isGrouppedTickets={true}
          dateSelector={this.category.dateSelector}
          visitorCounter={this.category.visitorSelector}
          createMappedData={this.props.createMappedData}
          addToCartCallback={this.props.addToCartCallback}
          services={this.props.services}
          getDiscountedProducts={this.props.getDiscountedProducts}
          timeSlotSelector={this.category.timeSlotSelector}
          category={this.category}
          selectedTicket={this.props.selectedTicket}
          handleOnClick={this.props.handleOnClick}
          ticketState={this.props.ticketState}
        />
      </div>
    );
  };

  getCategoryProds = (category, products) =>
    products.filter((product) => product.category.toString() === category.toString());

  getCategoryStatus = (pCat) => {
    if (this.isPJOnly) return true;
    if (!this.props.prodCategoryList[pCat]) {
      this.props.prodCategoryList[pCat] = true;
      return true;
    }
    return false;
  };

  renderTicket = (product, index) => {
    const multiTicketSelector = this.category.multiTicketSelector,
      keyMap = multiTicketSelector ? multiTicketSelector.stringMapping : {},
      category = product.category,
      getDisProd = this.props.getDiscountedProducts;

    let isCategoryMappingExist = false;
    if (keyMap) {
      for (var item in keyMap) {
        if (item === category[0]) {
          isCategoryMappingExist = true;
          break;
        }
      }
    }
    if (
      keyMap &&
      !this.aryExist &&
      (category.indexOf(keyMap.drivingExperience) !== -1 ||
        category.indexOf(keyMap.passengerExperience) !== -1 ||
        isCategoryMappingExist)
    ) {
      isCategoryMappingExist = false;
      this.updateSecProdArray(product, category[0], keyMap, isCategoryMappingExist);
    }
    if (!product.hasOwnProperty('quantity')) {
      product.quantity = get(this.category, 'visitorSelector.options[0].defaultQuantity', 0);
    }
    return (
      <>
        {/* remove this div because is not required */}
        {(!multiTicketSelector || (product.experienceCatgory === keyMap.primary && getDisProd([product], 0).length)) &&
        this.getCategoryStatus(product.category) ? (
          <div>
            <TileComponent
              data={this.props.data}
              bookingUrl={this.props.bookingUrl}
              productMainCategoryWithTabName={this.props.productMainCategoryWithTabName}
              showPostPurchaseRecommendation={this.props.showPostPurchaseRecommendation}
              postPurchaseRecommendationCTA={this.props.postPurchaseRecommendationCTA}
              isGAEnabledForCrossSell={this.props.isGAEnabledForCrossSell}
              cartData={this.props.cartData}
              businessErrors={this.props.businessErrors}
              key={index}
              recommendationUrl={this.props.recommendationUrl}
              ticket={product}
              dateSelector={this.category.dateSelector}
              visitorCounter={this.category.visitorSelector}
              createMappedData={this.props.createMappedData}
              addToCartCallback={this.props.addToCartCallback}
              services={this.props.services}
              getDiscountedProducts={this.props.getDiscountedProducts}
              timeSlotSelector={this.category.timeSlotSelector}
              category={this.category}
              selectedTicket={this.props.selectedTicket}
              handleOnClick={this.props.handleOnClick}
              ticketState={this.props.ticketState}
              additionalProds={this.getCategoryProds(product.category, this.props.products)}
              coveoMapList={this.props.coveoMapList}
              searchProductList={this.props.searchProductList}
              copySearchProductList={createDeepCopy(this.props.searchProductList)}
              showMinicartCouponCode={this.props.showMinicartCouponCode}
              discountMap={this.props.discountMap}
              additionalRecommendedData={this.props.additionalRecommendedData}
              ariaLabelCheckbox={this.props.ariaLabelCheckbox}
              ctaDetails={this.category.continueCheckoutCTA}
              tncPopup={this.category.tncPopup}
            />
          </div>
        ) : null}
      </>
    );
  };

  renderTickets = () => {
    let tickets = [];
    const reArrangedTickets = [];
    let ticketFilter = this.props.products.length > 0 && this.props.products;
    if (this.isPJOnly) {
      const collections = get(this.category, 'productOverlaySelector.collections', []);
      const optionsCoveoVal = collections && collections.map((opt) => opt.coveoval);

      ticketFilter = this.props.products.filter(({ productId }) => optionsCoveoVal.includes(productId));
      optionsCoveoVal.forEach((prod) => {
        const findTicket = ticketFilter.find((ticket) => prod === ticket.productId);
        if (findTicket) reArrangedTickets.push(findTicket);
      });
      ticketFilter = reArrangedTickets;
    }

    if (this.props.products.length > 0) {
      tickets = tickets.concat(
        this.category.groupInSingleTicket
          ? this.renderGrouppedTicket(this.props.products)
          : ticketFilter.map((product, index) => {
              if (this.isPJOnly) {
                const filteredCategory = this.props.data.controls.extras.options.filter(
                  ({ productId }) => productId === product.productId,
                )[0];
                this.category = filteredCategory;
              }
              return this.renderTicket(product, index);
            }),
      );
    }
    return tickets;
  };
  render() {
    try {
      return (
        <div
          className={classNames('c-fast-pass ', {
            'v-dynamic-product-pricing':
              this.props.data.variant.toLowerCase() === 'v-dynamic-product-pricing' && !this.isPJOnly,
          })}
        >
          <div className="w--content">
            <div className="c-fast-content">{this.renderTickets()}</div>
          </div>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'AddOnsCalenderCategoryComponent');
    }
  }
}

export default CalenderCategoryComponent;
