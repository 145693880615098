import React, { useEffect } from 'react';
import icon from './Icon.png';
import './kgfuicomponent.scss';
import { getMainObject } from '../../../common/utility';

const StarIcon = () => <img src="https://uat-static.myconnect.ae/-/media/yasconnect/icon" className="starIcon" />;

const Type1 = ({ nosOfChild, kfgText }) => {
  // THIS IS WITH THE SPINNER IN THE TABSWITHPLACEHOLDER
  return (
    nosOfChild === 0 && (
      <>
        <StarIcon />
        <div className={`kgftext type1`}>{kfgText[nosOfChild]}</div>
      </>
    )
  );
};

const Type2 = ({ messageIndex }) => {
  // THIS IS WITH THE ACCORDIANS OF CHILDREN WITH AGE WHICH GETS ADDED ON ADDING CHILD
  const { kgfOfferData } = getMainObject();
  const kfgText = [
    kgfOfferData?.TextAddKids?.value, // 0
    kgfOfferData?.TextApplied?.value, // 1
    kgfOfferData?.TextOneKidAdult?.value, // 2
  ];
  return (
    <>
      {messageIndex <= 1 && <StarIcon />}
      <div className={`kgftext  ${messageIndex > 1 && 'childMoreThantwo'}`}>{kfgText[messageIndex]}</div>
    </>
  );
};

const Kgfuicomponents = ({ messageIndex, nosOfChild, isSpinner, isKgfEnabled }) => {
  const { kgfOfferData } = getMainObject();
  const kfgText = [
    kgfOfferData?.TextAddKids?.value, // 0
    kgfOfferData?.TextApplied?.value, // 1
    kgfOfferData?.TextOneKidAdult?.value, // 2
  ];
  return (
    isKgfEnabled && (
      <div className="kgf-tag-bookingwidget">
        {isSpinner && <Type1 nosOfChild={nosOfChild} kfgText={kfgText} />}

        {!isSpinner && <Type2 messageIndex={messageIndex} />}
      </div>
    )
  );
};

export default Kgfuicomponents;
