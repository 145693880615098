import React from 'react';
import moment from 'moment';

import UIConfig from '../../../../common/UIConfig';
import {
  toTwoDecimalPlaces,
  isF1SeatedTypeProduct,
  isEventTypeProduct,
  checkEventTypeProduct,
  isYasArenaJourney,
  isAddonProduct,
  resolvePath,
  isMatchTenant,
  checkTenant,
  getMainObject,
  getLoggedInUser,
  checkParksTenants,
} from '../../../../common/utility';
import { KeyCodes, KeyCodeNames } from '../../../../common/constants';
import { DynamicContent, Spinner, SvgSprite } from '../../../presentation/base';
import ErrorOverlay from '../../../presentation/error-overlay/error-overlay-component.js';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import { isGeneral, isAnnualPass } from './cart-component';
import CreateItemRow from './create-item-row';
import { errorCallback } from '../purchase-journey-wrapper/helper';
import { CartService, ServiceConfig } from '../../../../common/services';

const _checkAddonAvailability = (product, quantity, maxQuantityForSpinner, updateQuantity, checkPerformanceUrl) => {
  const { url, errors } = checkPerformanceUrl;
  const updatedUrl = url?.replace('{tenantId}', ServiceConfig.getTenantId());
  const payload = {
    AddonCheckBaskets: [
      {
        productId: product.productId || '',
        quantity: product.quantity + quantity,
      },
    ],
  };

  if (url) {
    CartService.checkAddonAvailability(payload, 'AddOnsTile', updatedUrl)
      .then((response) => {
        if (response.status === 200 && typeof response.data === 'object') {
          const { error, orderdetails } = response.data;
          if (error?.errorcode) {
            window.PubSub.publish('addonAvailibility', {
              quantity: product.quantity,
              soldOutLimitError: errors?.[error?.errorcode],
            });
          } else if (orderdetails?.order.items?.length) {
            window.PubSub.publish('addonAvailibility', {
              soldOutLimitError: '',
            });
            updateQuantity(product, quantity, maxQuantityForSpinner);
          }
        }
      })
      .catch((res) => {
        errorCallback('getCoveoProducts', res.error);
      });
  }
};

export const getUpdateQuantity = (
  product,
  propData,
  quantity,
  maxQuantityForSpinner,
  updateQuantity,
  isAddOn,
  checkPerformanceUrl,
) => {
  const recommendationTabName = UIConfig.b2c.purchaseJourney.recommendationTabCode;
  const checkValidation = propData && propData.isRecommendationControlled && product;
  const isReAllowedForAll = propData && propData.isRecommendationAllowedForAll;
  const { crossSellType } = UIConfig.b2c.purchaseJourney.ymcMapping;
  // For ADD ONS products which are required to be in unlimited quantity
  if (isAddOn && checkPerformanceUrl?.url) {
    _checkAddonAvailability(product, quantity, maxQuantityForSpinner, updateQuantity, checkPerformanceUrl);
  } else {
    if (
      isMatchTenant(UIConfig.ymcB2CTenant) &&
      checkValidation &&
      product.subEventType &&
      product.subEventType.toLowerCase() === crossSellType.FEB
    ) {
      updateQuantity(product, quantity, maxQuantityForSpinner);
    } else if (checkValidation && (isEventTypeProduct(product) || isReAllowedForAll)) {
      const {
        cartData,
        addOnsErrorProdArr,
        recommendationActions,
        isRecommendationData,
        isPostPurchasedAddon,
      } = propData;
      let addOnsErrObj = {};
      if (recommendationTabName === recommendationActions.getTabNameForProduct(product)) {
        addOnsErrObj = recommendationActions.checkMaxQuantityAndErrorProdArr(
          product,
          cartData,
          addOnsErrorProdArr,
          quantity,
          isRecommendationData,
          isPostPurchasedAddon,
        );
      } else if (recommendationActions.getArray(product, 'relatedProductIds')) {
        addOnsErrObj = recommendationActions.getAddOnsProdsOnParentQuantityChange(
          product,
          cartData,
          addOnsErrorProdArr,
          quantity,
        );
        if (addOnsErrObj && addOnsErrObj.hasOwnProperty('isOpenPopOverlay') && addOnsErrObj.isOpenPopOverlay) {
          openPopAddOns(propData);
        }
      }
      updateQuantity(product, quantity, maxQuantityForSpinner, addOnsErrObj);
    } else {
      updateQuantity(product, quantity, maxQuantityForSpinner);
    }
  }
};

export const isFastPass = (data) => {
  const fastPassProd =
    data.products && data.products.filter((product) => product.itemType === UIConfig.b2c.purchaseJourney.tabs.PER);
  return fastPassProd && fastPassProd.length > 0;
};

export const getDeleteButton = (
  isEditable,
  deleteOverlay,
  productName,
  deleteIconAriaLabel = 'Delete ',
  propData,
  isDeleteBtnPosChange,
  disableBtn,
) => {
  let disabledClass = '',
    deletePosClass = '';
  if ((propData && propData.disableOnSeatedPaymentError) || disableBtn) {
    disabledClass = 'disabled';
  }
  if (isDeleteBtnPosChange) {
    deletePosClass = ' delete-btn-top-inc';
  }
  return isEditable !== false ? (
    <div
      className={`delete-btn${deletePosClass} ${disabledClass}`}
      onClick={deleteOverlay}
      onKeyDown={(e) => {
        /**
         * if user press enter key then this condition become true
         */
        if (e.charCode === KeyCodes.Enter || e.keyCode === KeyCodes.Enter || e.key === KeyCodeNames.Enter) {
          deleteOverlay();
        }
      }}
      tabIndex={disableBtn ? '-1' : '0'}
    >
      <SvgSprite id="b2c-delete-icon" ariaHidden="false" focusableAttribute="true" />
      <span className="sr-only">{`${deleteIconAriaLabel} ${productName}`}</span>
    </div>
  ) : null;
};

export const openPopAddOns = (propData) => {
  const { title, bodyCopy, primaryCTA } = propData.data.preventDeleteOverlay;
  window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
    shouldOpen: true,
    dataToAppend: <ErrorOverlay title={title} bodyCopy={bodyCopy} primaryCTA={primaryCTA} />,
    customClass: 'purchase-overlay cart-delete-overlay v-b2c-overlay prevent-delete-overlay',
  });
};

/**
 * Get formatted date value
 * @param {*} elementType   Type of Element need to create
 * @param {*} className     css class need to be applied
 * @param {*} innerHtml     Display Text
 * @return Formatted HTML connect
 */

/**
 * GroupedProductRow function creates a complete ticket type
 * @param    {[string]} objectKey [key of each object of grouped data].
 * @param    {[Object]} groupedData [object containing each ticket type data].
 * @param    {[Object]} data [data for rendering th eview].
 * @return   {[Object]} returns html .
 */
const _getFormattedDateValue = (elementType = 'p', className = 'date-value', innerHtml = '') => {
  return (
    <span>
      <DynamicContent tagName={elementType} attrs={{ className: className }} innerHtml={innerHtml} />
    </span>
  );
};

const CreateYAItemRow = ({ products, currency, isYaNonSeatedJourney }) => {
  const ticketInfo = YALocalStorageSvc.getSetSeatInfo();

  const getTicketItem = (ticketholder, price) => {
    return (
      <div className="ticket-item">
        <div className="ticket-type" tabIndex="0">
          <DynamicContent tagName="p" attrs={{ className: 'heading-4 ticket-holder' }} innerHtml={ticketholder} />
        </div>
        <DynamicContent tagName="p" attrs={{ className: 'price' }} innerHtml={price} />
      </div>
    );
  };

  return (
    <div>
      {
        <div className="ticket-sub-section">
          <div>
            {isYaNonSeatedJourney &&
              products.map((product) => {
                return getTicketItem(
                  `${product.productName}  X  ${product.quantity}`,
                  `${currency} ${toTwoDecimalPlaces(product.price.gross / product.quantity)}`,
                );
              })}
            {!isYaNonSeatedJourney &&
              ticketInfo &&
              products.map((product) => {
                const seatInfo = ticketInfo[product.productId];
                if (seatInfo && Array.isArray(seatInfo) && seatInfo.length > 0) {
                  return seatInfo.map((seatInfo) => {
                    return getTicketItem(
                      `${seatInfo.seat} ${seatInfo.stand}`,
                      `${currency} ${toTwoDecimalPlaces(product.price.gross / product.quantity)}`,
                    );
                  });
                } else {
                  if (product && product.productName) {
                    let prodDetailsArray = [];
                    for (let i = 0; i < product.quantity; i++) {
                      prodDetailsArray.push(product);
                    }
                    return prodDetailsArray.map((prod) => {
                      return getTicketItem(
                        `${prod.productName}`,
                        `${currency} ${toTwoDecimalPlaces(product.price.gross / product.quantity)}`,
                      );
                    });
                  }
                }
              })}
          </div>
        </div>
      }
    </div>
  );
};

const CreateYAAddOnRow = ({ products, updateQuantity, availabilityMapping, isAddOn, checkPerformanceUrl }) => {
  return (
    <div>
      {
        <div className="ticket-sub-section">
          <div>
            {products.map((product) => {
              return (
                <div className="ticket-item">
                  <Spinner
                    min={1}
                    isInputDisabled="true"
                    quantity={product.quantity}
                    name={product.productIdType}
                    max={YaGetAddonsAvailabilty({ productId: product.productId, availabilityMapping })}
                    checkQuantity={(val) =>
                      getUpdateQuantity(
                        product,
                        null,
                        Number(val) - Number(product.quantity),
                        YaGetAddonsAvailabilty({ productId: product.productId, availabilityMapping }),
                        updateQuantity,
                        isAddOn,
                        checkPerformanceUrl,
                      )
                    }
                    isAddOn={isAddOn}
                  />
                </div>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
};

//To get availability in payment page for yas arena
const YaGetAddonsAvailabilty = ({ productId, availabilityMapping }) => {
  if (availabilityMapping) {
    return availabilityMapping[productId];
  } else {
    return YALocalStorageSvc.getSetAddonsAvailabilty()[productId];
  }
};

const loadYaDeleteButton = ({
  yaOverlayData,
  addOnOverlayData,
  yaDeleteProduct,
  yaDeleteAddOn,
  deleteYAOverlay,
  deleteOverlay,
  eventName,
  product,
  isAddOn,
}) => {
  if (isAddOn) {
    return getDeleteButton(true, () => deleteOverlay(addOnOverlayData, yaDeleteAddOn, product));
  }
  return getDeleteButton(
    true,
    () =>
      deleteYAOverlay(yaOverlayData, yaDeleteProduct, UIConfig.b2c.purchaseJourney.yasArenaCart.overlayModes.remove),
    eventName,
  );
};

const GroupedProductRow = ({
  objectKey,
  bErrObj,
  groupedData,
  propData,
  deleteOverlay,
  isCartPaymentView = false,
  isRenewAnnualPass,
  isYaNonSeatedJourney = false,
  deleteYAOverlay,
}) => {
  const { data } = propData;
  const productGroupList = groupedData[objectKey].products;
  const grossTicketPrice = isMatchTenant(UIConfig.ymcB2CTenant) && parseInt(groupedData[objectKey].products[0].gross);
  let addGuestCount = 0;

  const hasYaArenaCart = propData.yasArenaCart && propData.yasArenaCart.isYasArenaView;

  let isYaProduct = hasYaArenaCart && productGroupList.filter((prod) => !isAddonProduct(prod)).length > 0;

  // const deleteAction = hasYaArenaCart && isYaProduct ? deleteYAOverlay : deleteOverlay;
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const getProductInfo = (ticketPrice) => {
    const elementsArr = [];
    let productName = '';

    if (isCartPaymentView && hasYaArenaCart && isYaProduct) {
      const { eventTitle } = YALocalStorageSvc.getSetEventDetails();
      const cartEventTitle = YALocalStorageSvc.getSetCartTitle();
      productName = cartEventTitle || eventTitle || '';
    } else {
      productName = groupedData[objectKey].productName;
    }

    const {
      data: { cartLabels },
    } = propData;
    const isProdFlexible = groupedData[objectKey].products[0].isFlexible;
    let label = '';
    let innerHtml = '';
    const eventLocation = getEventLocation();
    eventLocation && elementsArr.push(eventLocation);
    const sectionAndSeat = getSectionAndSeat();
    eventLocation && elementsArr.push(sectionAndSeat);
    const sector = getSector();
    sector && elementsArr.push(sector);
    if (isCartPaymentView) {
      if (propData.yasArenaCart && propData.yasArenaCart.isYasArenaView) {
        // 2 Step journey and Yas Arena Cart
        const { date, openTime, closeTime } = YALocalStorageSvc.getSetPerformance();
        let timeStr = `${cartLabels.timeSlot} ${openTime} - ${closeTime}`;
        let dateTimeSlot = `
                          <div class="date">${moment(date).format(UIConfig.b2c.purchaseJourney.yaPurchaseDateFormat)}
                          </div><div class="time">${timeStr}</div>`;

        if (!isYaProduct) {
          if (productGroupList[0].toDate) {
            const productDate = productGroupList[0].toDate;
            dateTimeSlot = `<div class="date hide-time">${cartLabels.validUntil} ${moment(productDate).format(
              UIConfig.b2c.purchaseJourney.yaPurchaseDateFormat,
            )}
                          </div>`;
          } else {
            dateTimeSlot = `
                          <div class="date hide-time">${moment(date).format(
                            UIConfig.b2c.purchaseJourney.yaPurchaseDateFormat,
                          )}
                          </div>`;
          }
        }

        elementsArr.push(_getFormattedDateValue('p', 'date-value', dateTimeSlot));
      } else if (!propData.cartSummaryExpandedView && groupedData[objectKey].toDate) {
        label = isProdFlexible || isRenewAnnualPass ? cartLabels.validUntil : cartLabels.validOn;
        let prodDate =
          isProdFlexible || isRenewAnnualPass ? groupedData[objectKey].toDate : groupedData[objectKey].fromDate;
        innerHtml = `<span>${cartLabels.flexible} ${label}</span> <span class="date">${moment(prodDate).format(
          !isSwad ? UIConfig.b2c.purchaseJourney.dateFormat : UIConfig.b2c.purchaseJourney.swadDateFormat,
        )}</span>
        ${
          groupedData[objectKey].products[0].timeSlot
            ? `${!isSwad ? '|' : ''} <span class="mycart-timeslot">${cartLabels.timeSlot} ${
                groupedData[objectKey].products[0].timeSlot
              }</span>`
            : ''
        }`;
        elementsArr.push(_getFormattedDateValue('p', 'date-value', innerHtml));
      } else if (propData.cartSummaryExpandedView) {
        label = isProdFlexible ? cartLabels.validUntil : cartLabels.validOn;
        if (isEventTypeProduct(groupedData[objectKey].products[0])) {
          innerHtml = `${propData.data.cartLabels.validOn} ${getValidOnDay()}`;
          elementsArr.push(_getFormattedDateValue('p', 'date-value', innerHtml));
        } else if (groupedData[objectKey].toDate) {
          innerHtml = `${cartLabels.flexible} ${label} ${moment(groupedData[objectKey].toDate).format(
            UIConfig.b2c.purchaseJourney.dateFormat,
          )} ${
            groupedData[objectKey].products[0].timeSlot && !groupedData[objectKey].products[0].isDynamicPricing
              ? ` ${groupedData[objectKey].products[0].timeSlot}`
              : ''
          }`;
          elementsArr.push(_getFormattedDateValue('p', 'date-value', innerHtml));
        }
      }
    } else if (!checkParksTenants) {
      elementsArr.push(
        <DynamicContent
          tagName="p"
          attrs={{ className: 'park-name' }}
          innerHtml={`${groupedData[objectKey].parks.join('+')} ${
            isFastPass(groupedData[objectKey]) && data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.cartSummary
              ? 'x ' + groupedData[objectKey].products[0].quantity
              : ''
          } `}
        />,
      );
    }
    if (propData.cartSummaryExpandedView) {
      if (
        groupedData[objectKey].products[0].quantity &&
        groupedData[objectKey].products[0].unitPrice &&
        groupedData[objectKey].products[0].classTypeName
      )
        elementsArr.push(
          ticketPrice === 0 ? (
            ''
          ) : (
            <div className="ticket-value">
              <div className="ticket-quantity-value">
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'quantity-amount' }}
                  innerHtml={`${groupedData[objectKey].products[0].quantity}`}
                />
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'quantity-type' }}
                  innerHtml={`${groupedData[objectKey].products[0].classTypeName}`}
                />
              </div>
              <DynamicContent
                tagName="span"
                attrs={{ className: 'quantity-price' }}
                innerHtml={`${propData.cartData.cart.currency} ${toTwoDecimalPlaces(groupedData[objectKey].gross)}`}
              />
            </div>
          ),
        );
    }

    return (
      <div className="product-name">
        <div className="product-title-wrapper">
          <DynamicContent tagName="p" attrs={{ className: 'heading-4 11' }} innerHtml={productName} />

          {data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart || data.showCategoryLabel ? (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'sub-category' }}
              innerHtml={groupedData[objectKey].products && groupedData[objectKey].products[0].productCategoryLabel}
            />
          ) : null}
        </div>
        {grossTicketPrice === 0 ? '' : elementsArr.map((el) => el)}
      </div>
    );
  };

  const getEventLocation = () => {
    const product = groupedData[objectKey].products[0];
    const { eventType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    if (checkEventTypeProduct(product, eventType.f1) && product.eventLocationName) {
      return <div className="event-location">{product.eventLocationName}</div>;
    }
    return null;
  };

  const getSectionAndSeat = () => {
    const product = groupedData[objectKey].products[0];
    if (isF1SeatedTypeProduct(product) && product.seatInfo) {
      return <div className="section-and-seat">{product.seatInfo}</div>;
    }
    return null;
  };

  const getSector = () => {
    const product = groupedData[objectKey].products[0];
    if (product.sector) {
      return <div className="section-and-seat">{product.sector}</div>;
    }
    return null;
  };

  const getValidOnDay = () => {
    const product = groupedData[objectKey].products[0];
    const { eventType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    let validOn = '';
    if (checkEventTypeProduct(product, eventType.wrx)) {
      validOn = product.wrcEventDate || '';
    } else if (checkEventTypeProduct(product, eventType.f1) && propData.data.weekDaysDisplay) {
      const dayKey = product.weekDays;
      validOn = propData.data.weekDaysDisplay[dayKey];
    } else {
      validOn = moment(groupedData[objectKey].fromDate).format(
        !isSwad ? UIConfig.b2c.purchaseJourney.dateFormat : UIConfig.b2c.purchaseJourney.swadDateFormat,
      );
    }
    return validOn;
  };
  const getTicketsValidityInfo = () => {
    if (isCartPaymentView) return null;

    return (isGeneral(groupedData[objectKey].products && groupedData[objectKey].products[0].itemType) ||
      isAnnualPass(groupedData[objectKey].products && groupedData[objectKey].products[0].itemType) ||
      parseInt(groupedData[objectKey].products[0].validUntilDate)) &&
      groupedData[objectKey].products[0].isFlexible ? (
      <span className="ticket-validity">
        <DynamicContent
          tagName="p"
          attrs={{ className: 'date-label' }}
          innerHtml={`${propData.data.cartLabels.flexible} ${propData.data.cartLabels.validUntil}`}
        />
        <p className="date-value">
          {parseInt(groupedData[objectKey].products[0].validUntilDate)
            ? moment(groupedData[objectKey].products[0].toDate).format(
                !isSwad ? UIConfig.b2c.purchaseJourney.dateFormat : UIConfig.b2c.purchaseJourney.swadDateFormat,
              )
            : moment(groupedData[objectKey].toDate).format(
                !isSwad ? UIConfig.b2c.purchaseJourney.dateFormat : UIConfig.b2c.purchaseJourney.swadDateFormat,
              )}
        </p>
      </span>
    ) : (
      !parseInt(groupedData[objectKey].products[0].validUntilDate) &&
        (groupedData[objectKey].fromDate || isEventTypeProduct(groupedData[objectKey].products[0])) && (
          <span className="ticket-validity">
            <div className="valid-date">
              <DynamicContent
                tagName="p"
                attrs={{ className: 'date-label body-1' }}
                innerHtml={`${propData.data.cartLabels.validOn}`}
              />
              <p className="date-value">{getValidOnDay()}</p>
            </div>
            {groupedData[objectKey].products && groupedData[objectKey].products[0].timeSlot && (
              <span className="time-slot">
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'date-label body-1' }}
                  innerHtml={`${propData.data.cartLabels.timeSlot}`}
                />
                {!isSwad ? (
                  <p className="date-value">{groupedData[objectKey].products[0].timeSlot}</p>
                ) : (
                  <DynamicContent
                    tagName="p"
                    attrs={{ className: 'date-value' }}
                    innerHtml={`${groupedData[objectKey].products[0].timeSlot}`}
                  />
                )}
              </span>
            )}
          </span>
        )
    );
  };

  /**
   *
   * @param {[Object]} e - button event.
   */
  const changeAction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isYaNonSeatedJourney) {
      if (propData.handleChangeActionNonSeated) {
        // called from my cart component - payment page
        const fromCartAction = true;
        propData.handleChangeActionNonSeated(fromCartAction);
      } else {
        // called from Event Performance Component - Booking Page
        window.PubSub.publish('show_arena_tour_overlay');
      }
    } else {
      propData.data.updateOverlay &&
        Object.keys(propData.data.updateOverlay).length &&
        deleteYAOverlay(
          propData.data.updateOverlay,
          propData.yasArenaCart.deleteAndChange,
          UIConfig.b2c.purchaseJourney.yasArenaCart.overlayModes.change,
        );
    }
  };

  const itemPromotionList =
    groupedData[objectKey].products &&
    groupedData[objectKey].products.length > 0 &&
    groupedData[objectKey].products.filter((prod) => prod.ItemPromotionList?.length > 0);

  const getTotalPrice = (prod, type) => {
    const { products } = prod;
    let total = 0;
    products?.length &&
      products.forEach((pro) => {
        const { ItemPromotionList, price } = pro;
        let originalPrice = 0;
        if (ItemPromotionList?.length) {
          originalPrice = getTotalProductDiscount(ItemPromotionList, type);
        } else if (price) {
          originalPrice = price.gross;
        }
        total += originalPrice;
      });
    return total;
  };

  const getTotalDiscountPrice = (prod, type) => {
    const { products } = prod;
    let total = 0;
    products?.length &&
      products.forEach((pro) => {
        const { ItemPromotionList } = pro;
        let originalPrice = 0;
        if (ItemPromotionList?.length) {
          originalPrice = getTotalProductDiscount(ItemPromotionList, type);
        }
        total += originalPrice;
      });
    return total;
  };

  const getPriceTotal = () => {
    return (
      <DynamicContent
        tagName="p"
        attrs={{ className: 'body-1 sub-total-price' }}
        innerHtml={`${propData.cartData.cart.currency} ${
          isSwad
            ? groupedData[objectKey].gross.toLocaleString('en', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            : toTwoDecimalPlaces(groupedData[objectKey].gross)
        }`}
      />
    );
  };

  const getPriceLabel = () => {
    const { cartLabels, additionalLabels } = propData.data;
    if (isMatchTenant(UIConfig.tenants.fwad)) {
      return additionalLabels.price;
    }
    return cartLabels.subTotal;
  };

  const getPriceSection = () => {
    if (productLevelDiscountEnable()) {
      return (
        <DynamicContent
          tagName="p"
          attrs={{ className: 'body-1 sub-total-price' }}
          innerHtml={`${propData.cartData.cart.currency} 
              ${toTwoDecimalPlaces(getTotalPrice(groupedData[objectKey], UIConfig.discountOptions.originalPrice))}`}
        />
      );
    }
    return getPriceTotal();
  };

  const getTotalProductDiscount = (promotion, type) => {
    let sum = promotion.reduce((acc, val) => {
      return acc + parseFloat(Math.abs(val[type].amount));
    }, 0);
    return sum;
  };

  const productLevelDiscountEnable = () => {
    const localObj = getMainObject() || getLoggedInUser();
    const mainObj = localObj?.additionalProperty;
    const showProductDiscount = mainObj?.productLevelDiscount === 'true';
    return (
      isCartPaymentView && showProductDiscount && isMatchTenant(UIConfig.tenants.fwad) && itemPromotionList.length > 0
    );
  };

  const isDrivingVipExp = (cat) => {
    return cat === 'DRIVE' || cat === 'VipPlus';
  };

  // if true loads YA main event row otherwise YA Addon Row.
  const loadYAProductRow = hasYaArenaCart && productGroupList && isYaProduct;

  const cartItem = propData?.cartData?.cart;

  const getValidErrorText = (prd) => {
    const { products } = prd;
    const prod = products?.[0];
    const errors = resolvePath(propData, 'data.businessErrors', '');
    let error = '';
    if (prod.ticketType === 'VVIP' || prod.ticketType === 'VIP') {
      error = prod.ticketType === 'VVIP' ? errors?.VIPEXPERIENCES : errors?.VIPEXPERIENCESSHARED;
    } else {
      error = errors?.INVALIDQTY;
    }
    return <DynamicContent tagName="div" attrs={{ className: 'ticket-section--error' }} innerHtml={error} />;
  };

  return (
    <div className={`ticket-section ${productLevelDiscountEnable() ? 'discount-available' : ''}`}>
      <div
        className={
          grossTicketPrice === 0 ? 'product-details-wrapper prodect-detials-artist' : 'product-details-wrapper'
        }
      >
        <div className="product-details" tabIndex="0">
          {getProductInfo(grossTicketPrice)}
          {grossTicketPrice === 0 ? '' : getTicketsValidityInfo()}
        </div>

        <div className={'ticket-subsection-container'}>
          {isYasArenaJourney() && loadYAProductRow && (
            <CreateYAItemRow
              products={productGroupList}
              currency={propData.cartData.cart.currency}
              isYaNonSeatedJourney={isYaNonSeatedJourney}
            />
          )}
          {isYasArenaJourney() && !loadYAProductRow && (
            <CreateYAAddOnRow
              products={productGroupList}
              updateQuantity={propData.updateQuantity}
              isYaNonSeatedJourney={isYaNonSeatedJourney}
              checkPerformanceUrl={propData.couponCode?.services?.addoncheckbasket || ''}
              isAddOn
            />
          )}
          {!hasYaArenaCart &&
            groupedData[objectKey].products &&
            groupedData[objectKey].products.map((item, index) => {
              const keyMap = propData.data.stringMapping ? propData.data.stringMapping : {};
              addGuestCount =
                item.otherReason && item.otherReason === keyMap.addOnGuest ? item.quantity : addGuestCount || 0;
              return (
                <CreateItemRow
                  key={index}
                  item={item}
                  currency={propData.cartData.cart.currency}
                  data={propData.data}
                  updateQuantity={propData.updateQuantity}
                  deleteProduct={propData.deleteProduct}
                  deleteOverlay={deleteOverlay}
                  cartTotalQuantity={propData.cartData.cart.totalQuantity}
                  showClassType={propData.showClassType}
                  addGuestCount={addGuestCount}
                  isCartPaymentView={isCartPaymentView}
                  isRenewAnnualPass={isRenewAnnualPass}
                  propData={propData}
                  ticketPrice={groupedData[objectKey].gross}
                />
              );
            })}
          {checkParksTenants() && isDrivingVipExp(groupedData[objectKey]?.products?.[0]?.category?.[0]) && (
            <div className="ticket-sub-section driving-exp">
              <div className="ticket-item">
                <span className="ticket-type" tabindex="0">
                  <DynamicContent
                    tagName="p"
                    attrs={{ className: 'heading-4 ticket-holder' }}
                    innerHtml={getPriceLabel()}
                  />
                  <DynamicContent
                    tagName="p"
                    attrs={{ className: 'price' }}
                    innerHtml={`${propData.cartData.cart.currency} 
                ${toTwoDecimalPlaces(getTotalPrice(groupedData[objectKey], UIConfig.discountOptions.originalPrice))}`}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        {hasYaArenaCart && (
          <div className="changeButtonContainer">
            {isYaProduct && (
              <a href="/#" className="changeButton" onClick={changeAction}>
                {' '}
                {propData.data.updateOverlay.ctaLink.label}
              </a>
            )}
            <span className="tickets">
              {data.deleteOverlay &&
                Object.keys(data.deleteOverlay).length &&
                loadYaDeleteButton({
                  yaOverlayData: propData.data.deleteOverlay,
                  yaDeleteProduct: propData.yasArenaCart.deleteAndChange,
                  deleteYAOverlay,
                  eventName: propData.yasArenaCart.eventName,
                  isAddOn: false,
                })}
              {!isYaProduct &&
                loadYaDeleteButton({
                  addOnOverlayData: propData.data,
                  yaDeleteAddOn: propData.deleteProduct,
                  deleteOverlay,
                  product: productGroupList[0],
                  isAddOn: true,
                })}
            </span>
          </div>
        )}
      </div>
      {groupedData[objectKey].invalidQuantity && getValidErrorText(groupedData[objectKey])}
      {grossTicketPrice === 0 ? (
        ''
      ) : (
        <>
          {!checkParksTenants() && (
            <div className="sub-total">
              <DynamicContent tagName="p" attrs={{ className: 'sub-total-label body-2' }} innerHtml={getPriceLabel()} />
              {getPriceSection()}
            </div>
          )}
          {productLevelDiscountEnable() && (
            <div className="discount-sub-total">
              <div className="coupon-discount-container">
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'coupon-discount-label' }}
                  innerHtml={propData.data.cartLabels.discount}
                />
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'coupon-discount-value' }}
                  innerHtml={`${cartItem.currency} -${toTwoDecimalPlaces(
                    getTotalDiscountPrice(groupedData[objectKey], UIConfig.discountOptions.discount),
                  )}`}
                />
              </div>
              <div className="sub-total">
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'sub-total-label body-2' }}
                  innerHtml={propData.data.additionalLabels.subTotal}
                />
                {getPriceTotal()}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GroupedProductRow;
