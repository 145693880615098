import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/en-gb';
import moment from 'moment';

import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  momentTimezone,
  isDesktopViewPort,
  decodeHtmlEntity,
  deepCloneObject,
  isMatchTenant,
  getClientUtcOffset,
} from '../../../../common/utility';
import { DynamicContent, Calendar, ErrorSummary } from '../../../presentation/base';
import HighlightDate from '../highlight-date/highlight-date-component';
import { PerformanceService } from '../../../../common/services';
import TimeslotSelector from '../time-slot-selector';
import { logComponentRenderingError } from '../../../../common/logger';
import { ImportantNotes } from '../common-components';

export default class CalendarOverlayB2c extends Component {
  constructor(props) {
    super(props);
    this.date = this.calculateDateValidity(props.data.startDate) ? props.data.startDate : '';
    this.selectDate = this.selectDate.bind(this);
    this._calendar = null;
    this.isDesktop = isDesktopViewPort();
    this.selectedDate = null;

    /* Check if it is wbw tenant */
    this.isWBW = isMatchTenant(UIConfig.tenants.wbw);

    this.state = {
      selectedDiscount: {
        highlightRange: [],
        checkedDays: null,
        discountMap: [],
        description: '',
        openToDate: null,
      },
      performanceData: {},
      showTimeSlot: false,
      product: deepCloneObject(props.product),
      disableSelect: false,
    };
  }

  componentWillMount() {
    this.props.data.highlightDates && this.manipulateOptions(this.props.data.options);
  }

  selectDate(date, isMount) {
    const { bestRate, data, timeSlotData } = this.props;
    const { selectedDiscount } = this.state;
    const { openToDate } = selectedDiscount;

    this.date = isMount && bestRate.code ? openToDate : date;
    if (data && data.highlightDates && !(isMount === true)) {
      this.toggleRangeAndDiscount(null, null, this.date.clone());
    }

    /* Enable timeslot selector only for wbw */
    if (isMount && timeSlotData && this.isWBW) {
      this.selectedDate = this.date.format(UIConfig.calendar.dateFormat);
      this.setState({
        performanceData: {},
      });
      this.getPerformanceData();
    }
  }

  /* Get performance data by product Id */
  getPerformanceData = () => {
    if (!this.props.services) {
      return;
    }
    const { product } = this.state;
    const { services, timeSlotData } = this.props;
    const productClone = product ? { ...product } : {};
    productClone.fromDate = this.selectedDate;
    productClone.toDate = this.selectedDate;
    const url = services.getPerformance.url.replace('{0}', product.productId);
    PerformanceService.getPerformanceData(url, this.selectedDate, this.selectedDate, true, '.addOns-Overlay')
      .then((response) => {
        const availabile =
          response.data.performancelist.hasOwnProperty('performance') &&
          response.data.performancelist.performance.some((timeSlot) => {
            return timeSlot.availability.available > 0 && timeSlot.sellable === 'true';
          });
        if (availabile) {
          productClone.performanceId = '';
          productClone.timeSlot = '';

          if (
            !(timeSlotData && timeSlotData.showSingleTimeSlot) &&
            response.data.performancelist.performance.length === 1
          ) {
            productClone.performanceId = response.data.performancelist.performance[0].performanceId;
            this.setState({
              showTimeSlot: false,
              product: productClone,
              performanceData: response.data.performancelist,
              disableSelect: false,
            });
          }
          if (timeSlotData) {
            /* Show timeslot selector */
            this.setState({
              product: productClone,
              disableSelect: true,
              showTimeSlot: true,
              performanceData: response.data.performancelist,
            });
          }
          if (!timeSlotData) {
            this.setState({
              product: productClone,
              disableSelect: false,
              performanceData: response.data.performancelist,
            });
          }
        } else {
          this.setState({
            showTimeSlot: false,
            performanceData: { error: { errorcode: '7001' } },
            disableSelect: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          showTimeSlot: false,
          performanceData: { error: err.error },
          disableSelect: true,
        });
      });
  };

  /* validate discount start date in respect of product or offer end date */
  calculateDateValidity(discountStartDate) {
    if (this.props.data.endDate) {
      const { disabledDates } = this.props.data;

      if (
        disabledDates &&
        Array.isArray(disabledDates) &&
        disabledDates.includes(discountStartDate.format(UIConfig.calendar.slashDateFormat))
      ) {
        return false;
      }

      const endDate = momentTimezone(this.props.data.endDate.clone(), getClientUtcOffset()).startOf('day');
      return endDate.diff(discountStartDate.startOf('day'), 'days') > 0;
    }

    return '';
  }

  handleSelectButton = () => {
    const { product } = this.state;
    if (canUseDOM()) {
      window.PubSub.publish('toggleOverlayState', { shouldOpen: false });
      this.props.updateData({
        date: this.date,
        timeSlot: product.timeSlot,
        performanceId: product.performanceId,
      });
    }
  };

  /* returns object with selected range, day, date */
  getSelectedDiscount(discountMap, selectedInput) {
    let range,
      checkedDays,
      description,
      openToDate = null;
    discountMap.forEach((value, key) => {
      let input = selectedInput.value;
      if (selectedInput.type === 'discount') {
        if (input === value.code) {
          range = value.range;
          checkedDays = key;
          description = value.description;
          openToDate = this.calculateDateValidity(value.range[0]) ? value.range[0] : '';
        }
      } else {
        let currentRange = value && value.range;
        if (
          currentRange &&
          currentRange[0].diff(input, 'days') <= 0 &&
          currentRange &&
          currentRange[1].diff(input, 'days') >= 0
        ) {
          range = currentRange;
          checkedDays = key;
          description = value.description;
          openToDate = selectedInput.value;
        }
      }
    });
    this.date = openToDate;
    return { range, checkedDays, description, openToDate };
  }

  /* toggle range on discount change and discount on date change */
  toggleRangeAndDiscount = (e, clck, selectedDate) => {
    let selectedDiscount = this.state.selectedDiscount;
    let { discountMap } = selectedDiscount;
    let highlightWithRanges,
      range,
      checkedDays,
      description,
      openToDate = null;
    if (selectedDate) {
      let selectedObj = this.getSelectedDiscount(discountMap, { type: 'date', value: selectedDate });
      range = selectedObj.range;
      description = selectedObj.description;
      checkedDays = selectedObj.checkedDays;
      openToDate = selectedObj.openToDate;
    } else if (e && e.target) {
      checkedDays = e && e.target ? parseInt(e.target.dataset.value, 10) : e;
      let isCheckedDays = !(checkedDays === undefined);
      range = isCheckedDays && discountMap.get(checkedDays).range;
      description = isCheckedDays && discountMap.get(checkedDays).description;
    }
    highlightWithRanges = this.getHighlightedWithRanges(range);
    openToDate =
      openToDate ||
      (this.calculateDateValidity(highlightWithRanges[0]['react-datepicker__day--in-range'][0])
        ? highlightWithRanges[0]['react-datepicker__day--in-range'][0]
        : '');
    this.date = openToDate || this.date;
    this.setState({
      selectedDiscount: {
        ...selectedDiscount,
        highlightRange: highlightWithRanges,
        checkedDays: checkedDays,
        description: description,
        openToDate: openToDate,
      },
    });

    // To forcefully rebuild the calendar (Bug in calendar)
    setTimeout(() => {
      this._calendar && this._calendar.setDatePickerPreSelection(openToDate);
    }, 0);
  };

  /* return array of dates from range */
  enumerateDaysBetweenDates = (startDate, endDate) => {
    let dates = [];
    const currDate = momentTimezone(startDate.clone(), getClientUtcOffset()).startOf('day'),
      lastDate = momentTimezone(endDate.clone(), getClientUtcOffset()).startOf('day'),
      disableDates = this.props.data.disabledDates;
    do {
      if (disableDates && disableDates.length && disableDates.indexOf(currDate.format('YYYY/MM/DD')) !== -1) {
        continue;
      }
      dates.push(currDate.clone());
    } while (currDate.add(1, 'days').diff(lastDate) <= 0);

    return dates;
  };

  /* return selectedDiscount comparing best rate and default discount */
  getDefaultSelectedDiscount(bestRate, data, intialDiscount) {
    let defaultSelectorDiscount =
      bestRate && bestRate.code
        ? bestRate.code
        : data.defaultSelectorDiscount
        ? data.defaultSelectorDiscount
        : intialDiscount;
    return defaultSelectorDiscount;
  }

  /*create a discountMap */
  createDiscountMap(discounts, data) {
    let discountMap = new Map();
    discounts.forEach((discount, idx) => {
      const startDate = data.startDate.clone();
      const endDate = data.endDate.clone();
      discount.endDays = idx < discounts.length - 1 && discounts[idx + 1].days;
      let startDays = startDate.clone();
      let startRange = startDays.add(discount.days, 'days');
      let endRange =
        idx === discounts.length - 1
          ? endDate.diff(startRange) > 0
            ? endDate
            : startRange
          : startDate.add(discount.endDays - 1, 'days');
      discountMap.set(discount.days, {
        range: [startRange, endRange],
        description: discount.description,
        code: discount.code,
      });
    });
    return discountMap;
  }

  /*return a range if dates in highlight format with react date picker */
  getHighlightedWithRanges = (highlightedRange) => {
    const highlightWithRanges = [
      {
        'react-datepicker__day--in-range':
          this.props.data.highlightDates && highlightedRange
            ? this.enumerateDaysBetweenDates(highlightedRange[0], highlightedRange[1])
            : [],
      },
    ];
    return highlightWithRanges;
  };

  /*manipulate and set date and discount on mounting of calendar */
  manipulateOptions = (discounts) => {
    const { bestRate, defaultDate, data } = this.props;
    const discountMap = this.createDiscountMap(discounts, data);
    const discountCode = discounts[0] && discounts[0].code;
    const selectedInput =
      !bestRate.code && defaultDate
        ? { type: 'date', value: defaultDate }
        : {
            type: 'discount',
            value: this.getDefaultSelectedDiscount(bestRate, data, discountCode),
          };
    const selectedObj = this.getSelectedDiscount(discountMap, selectedInput);
    const highlightWithRanges = this.getHighlightedWithRanges(selectedObj.range);
    this.setState({
      selectedDiscount: {
        ...this.state.selectedDiscount,
        discountMap: discountMap,
        checkedDays: selectedObj.checkedDays,
        highlightRange: highlightWithRanges,
        description: selectedObj.description,
        openToDate: selectedObj.openToDate,
      },
    });
  };

  /* Render error message */
  renderErrorMsg = () => {
    const { performanceData } = this.state;
    const { services } = this.props;
    return (
      <ErrorSummary
        data={{
          error: {
            code: performanceData.error.errorcode,
            text: services.getPerformance.errors[performanceData.error.errorcode],
          },
        }}
      />
    );
  };

  renderCalendar() {
    const { showTimeSlot, selectedDiscount, performanceData, disableSelect } = this.state;
    const { data, defaultDate, filterDate } = this.props;
    const isError = !performanceData.hasOwnProperty('performance') && performanceData.hasOwnProperty('error');

    const minDate = data.startDate || moment(),
      options = data.options,
      { openToDate, highlightRange } = selectedDiscount;
    return (
      <div className="calendar-inside-overlay">
        {data.calendarTooltip && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
                .calendar-inside-overlay .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month):hover::after {
                  content : "${decodeHtmlEntity(data.calendarTooltip)}";
                }
              `,
            }}
          />
        )}
        <span className="top-border">
          <span className="circle"></span>
        </span>
        {selectedDiscount.description && (
          <div className="calendar-title">
            <DynamicContent
              tagName="span"
              attrs={{ className: 'title' }}
              innerHtml={selectedDiscount.description.toUpperCase()}
            />
          </div>
        )}
        {data.highlightDates && options && options.length > 0 && (
          <div className="discount">
            <HighlightDate
              discounts={options}
              selectedDiscount={selectedDiscount}
              toggleRangeAndDiscount={this.toggleRangeAndDiscount}
            />
          </div>
        )}
        <div className="calendar-title">
          {<DynamicContent tagName="span" attrs={{ className: 'title' }} innerHtml={data.title} />}
        </div>
        <Calendar
          ref={(instance) => (this._calendar = instance)}
          setDateCustom={this.selectDate}
          selectedDate={defaultDate || this.date}
          openToHighlightedDate={openToDate}
          minDate={minDate}
          maxDate={data.endDate}
          filterDate={filterDate}
          calendarSettings={data}
          highlightDates={highlightRange}
          openToDate={highlightRange.length > 0 && highlightRange[0]['react-datepicker__day--in-range'][0]}
          inlineDisable={this.isDesktop}
          disableCloseOnSelect={true}
          isKeyboardNavigationAllowed={this.isDesktop}
          onBlurCustom={(e, name, data) => this._calendar.openDatePicker()}
          onClickOutsideCustom={(e) => this._calendar && this._calendar.openDatePicker()}
        />
        {/* render time slot component when date is selected */}
        {showTimeSlot ? this.renderTimeSlot() : null}
        {isError && this.renderErrorMsg()}
        <div className="btn-primary-wrapper">
          <div className={`btn-primary ${disableSelect ? 'disabled' : ''}`}>
            <DynamicContent
              tagName="button"
              innerHtml={data.selectDateCta}
              attrs={{
                onClick: this.handleSelectButton,
              }}
            />
          </div>
        </div>
        {!data.highlightDates && <ImportantNotes options={options} />}
      </div>
    );
  }

  renderOverlay() {
    if (canUseDOM()) {
      window.PubSub.publish('toggleOverlayState', {
        shouldOpen: true,
        customClass: 'addOns-Overlay calendar-overlay-genral-admission',
        dataToAppend: this.renderCalendar(),
      });
    }
  }

  getSelectedTimeSlotDetails = (timeslotDetails) => {
    if (!timeslotDetails) {
      return;
    }
    this.setState((prevState) => ({
      product: {
        ...prevState.product,
        performanceId: timeslotDetails.performanceId,
        timeSlot: timeslotDetails.timeSlot,
      },
      disableSelect: false,
    }));
  };

  renderTimeSlot() {
    const { timeSlotData, services } = this.props;
    const { performanceData, product } = this.state;

    return (
      <TimeslotSelector
        timeSlotData={timeSlotData}
        product={product}
        services={services}
        performanceData={performanceData}
        getSelectedTimeSlotDetails={this.getSelectedTimeSlotDetails}
      />
    );
  }

  render() {
    try {
      return <div>{this.renderOverlay()}</div>;
    } catch (err) {
      return logComponentRenderingError(err, 'CalendarOverlayB2c');
    }
  }
}

CalendarOverlayB2c.PropsTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
};
