import React from 'react';
import { paginationDefaultSettings } from '../../../common/utility';
import ReactPaginate from 'react-paginate';
import { SvgSprite } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';

import './pagination-component.scss';

/**
 * Search is Pagination Component, shows pagination of listed content
 * @param    {data} Object as defined from parent state.
 * @return   {htmlMarkUp}
 */

const Pagination = (data) => {
  try {
    const { onPageChange, pageCount, forcePage, iconForward, iconBackward } = data;
    const customPaginationSettings = {
      onPageChange: onPageChange,
      pageCount: pageCount,
      forcePage: forcePage || 0,
      iconForward: iconForward || null,
      iconBackward: iconBackward || null,
    };
    const previousLabel = customPaginationSettings.iconForward ? (
      <SvgSprite id="icn-arrow-left" />
    ) : (
      <SvgSprite id="icn-pagination-b" styleClass="icn-pagination-b icon" />
    );
    paginationDefaultSettings.previousLabel = previousLabel;
    paginationDefaultSettings.nextLabel = customPaginationSettings.iconForward ? (
      <SvgSprite id="icn-arrow-right" />
    ) : (
      <SvgSprite id="icn-pagination-f" styleClass="icn-pagination-f icon" />
    );
    const paginationSettings = Object.assign({}, paginationDefaultSettings, customPaginationSettings);
    return (
      <div className="c-pagination">
        <ReactPaginate {...paginationSettings} />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Pagination');
  }
};
export default Pagination;
