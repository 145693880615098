/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFormattedTime, handleEnterKey, getYasIdGTMData, getEmailIdGTMData } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { DynamicContent, Spinner, Image } from '../../../presentation/base';
import DropDown from '../../../presentation/drop-down';
import classNames from 'classnames';
import { PerformanceService } from '../../../../common/services';

/**
 *  Arena Tour - Product Overlay
 */
export default class TourOverlayComponentWithSector extends Component {
  constructor(props) {
    super(props);
    const { add, update } = props.labels;
    this.state = {
      total: 0,
      products: [...props.products],
      allowAddUpdate: !props.products.filter((prod) => prod.quantity > 0).length,
      hasMaxAvailabilityError: false,
      productsWithSectors: [],
    };
  }

  componentDidMount() {
    this.subSectorList();
    this.calculateTotal();
  }
  subSectorList = () => {
    const { performanceData, products } = this.props;
    let productsWithSectors = [];
    Array.isArray(performanceData.sectors) &&
      performanceData.sectors.length > 0 &&
      performanceData.sectors.forEach((sector) => {
        let subSectorMappedProducts = [];
        Array.isArray(sector.subSectors) &&
          sector.subSectors.length > 0 &&
          sector.subSectors.forEach((item) => {
            const findsubSectorProductMapping =
              products && products.length && products.find((product) => product.productId === item.productId);
            const mappedResults = {
              text: item.seatCategoryCode,
              value: item.seatCategoryDescription,
              sectorId: sector.sectorId,
              ...findsubSectorProductMapping,
            };
            findsubSectorProductMapping && subSectorMappedProducts.push(mappedResults);
          });
        subSectorMappedProducts.length > 0 &&
          productsWithSectors.push({
            ...sector,
            subSectorMappedProducts,
            isExpand: false,
            selectedProduct: subSectorMappedProducts[0],
          });
      });
    this.setState({ productsWithSectors: productsWithSectors });
  };

  // set the latest quantity provided in state
  updateQuantity = (val, key) => {
    const products = [...this.state.productsWithSectors];
    const product = products.find((prod) => prod.selectedProduct.productId === key);
    product.selectedProduct.quantity = val;

    const selectedProducts = products.map((prd) => prd.selectedProduct);
    let totalQuantity = selectedProducts.reduce((total, product) => {
      return total + product.quantity;
    }, 0);

    const allowAddUpdate = totalQuantity === 0;
    this.setState({ products: [...products], allowAddUpdate, hasMaxAvailabilityError: false }, this.calculateTotal);
  };

  /**
   *  Get the Total quantity * tourGroupQuantity
   *  @param {Object} products - collection of Non Seated Products
   *  @returns {Number} total quantity multiplied by group quantity
   */
  getQuantityWithMultiplier = (products) => {
    let totalQuantity = products.reduce((total, product) => {
      const quantity = product.quantity * product.tourGroupQuantity;
      return total + quantity;
    }, 0);

    return totalQuantity;
  };

  /**
   *  Calculate total price of selected products
   *  and  save to state
   */
  calculateTotal = () => {
    const selectedProduct = this.state.productsWithSectors.map((prd) => prd.selectedProduct);
    const total = selectedProduct.reduce((total, product) => {
      return total + (product.gross ? product.quantity * product.gross : 0);
    }, 0);

    this.setState({ total });
  };
  findSectorSubSector = (response) => {
    const { productInfo } = response;
    let products = [];
    productInfo &&
      productInfo.length > 0 &&
      productInfo.forEach((seatCat) => {
        seatCat.seatCategory.forEach((seat) => {
          products.push(seat);
        });
      });
    return products;
  };

  /**
   *  Save/update selected quantity
   *
   */
  addToCart = () => {
    const { performanceId } = this.props.performanceData;
    const url = this.props.findperformancecapacityandproduct.replace('{performanceak}', performanceId);
    const selectedProduct = this.state.productsWithSectors.map((prd) => prd.selectedProduct);
    PerformanceService.findperformancecapacityproduct(url, true, performanceId, false).then((response) => {
      const allProducts = this.findSectorSubSector(response);
      const checkAvailableProducts = selectedProduct.some((product) => {
        const availableProduct = allProducts.find((prd) => prd.productList[0].productAk === product.productId);
        return product.quantity <= parseInt(availableProduct.available) ? false : true;
      });
      if (checkAvailableProducts) {
        this.setState({ hasMaxAvailabilityError: checkAvailableProducts });
      } else {
        this.props.addToCart(selectedProduct);
      }
    });
  };

  /* PushEventFlags method will push the object into the datalayer */
  pushEventFlags = () => {
    const { title, date, openTime, closeTime, performanceId } = this.props.performanceData;

    const { total } = this.state;
    const { products } = this.state;
    const metric = {};

    window.dataLayer = window.dataLayer || [];

    products.forEach((product, i) => {
      metric[`metric${i + 1}`] = parseFloat(product.quantity);
    });

    window.dataLayer.push({
      event: 'addToCart',
      myPassId: getYasIdGTMData(),
      emailId: getEmailIdGTMData(),
      ecommerce: {
        currencyCode: 'AED',
        add: {
          products: [
            {
              name: title,
              id: products[0].eventId[0],
              category: '<event category>',
              price: parseFloat(total),
              dimension8: date,
              dimension10: `${getFormattedTime(openTime)} - ${getFormattedTime(closeTime)}`,
              dimension11: performanceId,
              ...metric,
            },
          ],
        },
      },
    });
  };
  handleExpandSector = (sector) => {
    const { productsWithSectors } = this.state;
    const selectedSector = productsWithSectors.find((sec) => sec.sectorId === sector.sectorId);
    const updatedSectors = productsWithSectors.map((sector) => {
      if (sector.sectorId === selectedSector.sectorId) {
        return { ...sector, isExpand: !sector.isExpand };
      }
      return { ...sector, isExpand: false };
    });
    this.setState({ productsWithSectors: updatedSectors });
  };
  onSelectSubSectors = (event) => {
    const { productsWithSectors } = this.state;
    const updateProduct = productsWithSectors.map((sector) => {
      if (sector.sectorId === event.sectorId) {
        return { ...sector, selectedProduct: event };
      }
      return sector;
    });
    this.setState({ productsWithSectors: updateProduct });
  };

  render() {
    const { closeOverlay, labels, nonAvailabilityMessage } = this.props;
    const { products, allowAddUpdate, hasMaxAvailabilityError, productsWithSectors } = this.state;
    const { title, cancel, total, add, update } = labels;
    const actionButtonText = this.props.mode === UIConfig.b2c.purchaseJourney.yaTourOverlay.add ? add : update;

    return (
      <div className="tour-overlay-with-sector">
        <div className="overlay-data">
          <DynamicContent
            tagName="h2"
            attrs={{ className: 'overlay-title title-sector', id: 'overlay-title' }}
            innerHtml={title}
          />
          <div className="overlay-body">
            {productsWithSectors.map((sector) => {
              const { subSectorMappedProducts, description, sectorId, isExpand, selectedProduct, image } = sector;
              return (
                <div className="event-overlay-content-sectors">
                  <div className="event-overlay-item-sector" key={sectorId}>
                    <div
                      className="event-overlay-cell-title"
                      tabIndex="0"
                      onClick={() => this.handleExpandSector(sector)}
                      onKeyDown={(e) => handleEnterKey(e, () => this.handleExpandSector(sector))}
                    >
                      <span
                        className={classNames('chevron-down chevron-size', {
                          'chevron-dir': isExpand,
                        })}
                      ></span>
                      <span className="sector-item">{description}</span>
                    </div>
                    {isExpand && (
                      <div className="event-overlay-cell-expanded event-overlay-item">
                        <div className="event-overlay-cell-dropdown">
                          <DropDown
                            listItems={subSectorMappedProducts}
                            callBackFunction={(e, isAutoSelected) => this.onSelectSubSectors(e, isAutoSelected)}
                            tabIndex="0"
                            defaultValue={subSectorMappedProducts[0].value}
                          />
                        </div>
                        {selectedProduct && selectedProduct.gross && (
                          <div className="event-overlay-cell-content">
                            <div className="event-overlay-cell-ccy">
                              {selectedProduct.currency} {selectedProduct.gross}
                            </div>
                            <div className="event-overlay-cell-spinner">
                              <Spinner
                                eventKey={selectedProduct.productId}
                                isInputDisabled="true"
                                tabIndex="0"
                                min={0}
                                max={selectedProduct.available}
                                quantity={selectedProduct.quantity}
                                updateQuantity={this.updateQuantity}
                                isSpinnerDisabled={selectedProduct.available > 0 ? false : true}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {isExpand && !!image && (
                      <div className="event-overlay-cell-image-wrapper">
                        <Image image={image} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {hasMaxAvailabilityError && nonAvailabilityMessage && (
            <div id="maxAvailabilityError" className="maxAvailabilityError">
              {nonAvailabilityMessage}
            </div>
          )}
          <div className="action-line">
            <div className="btn-primary">
              <button type="button" onClick={this.addToCart} disabled={allowAddUpdate || hasMaxAvailabilityError}>
                {actionButtonText}
              </button>
            </div>
            <div className="btn-secondary">
              <button type="button" onClick={closeOverlay}>
                {cancel}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TourOverlayComponentWithSector.PropsTypes = {
  closeOverlay: PropTypes.func,
  products: PropTypes.object.isRequired,
  performanceData: PropTypes.object,
  mode: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};
