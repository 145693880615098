/* eslint-disable no-unused-expressions */

import ApiWrapper from './api-wrapper';
import { ServiceConfig } from './services';
import { getLoginUser, getMainObject, throttle, getBaseURL, setCookie } from './utility';
import UIConfig from './UIConfig';

export class Wishlist {
  static GetWishlist = (moduleName) => {
    const mainObj = getMainObject();
    if (!mainObj) {
      return Promise.reject('no mainObj');
    }
    const isUserLoggedIn = Object.keys(getLoginUser()).length > 0 ? true : false;
    const url = `${mainObj.additionalProperty.wishlistUrl}/${
      ServiceConfig.getWishlistID() ? '?wid=' + ServiceConfig.getWishlistID() : ''
    }`;

    if (!ServiceConfig.getWishlistID() && !isUserLoggedIn) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }

    const config = {
      url: url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': mainObj.additionalProperty.ocpApimSubscriptionKey,
      },
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: '.c-wishlist-component',
    };

    return ApiWrapper.experienceServices(config, isUserLoggedIn ? true : false)
      .then((response) => {
        return new Promise((resolve) => {
          const wishlistData = {};
          if (response && response.data && response.data.wishlist) {
            response.data.wishlist
              .map((parent) => parent.itemId)
              .forEach((item) => (item ? (wishlistData[item] = true) : null));
            response.data.wishlist
              .map((parent) => parent.items.map((child) => child.itemId))
              .toLocaleString()
              .split(',')
              .forEach((item) => (item ? (wishlistData[item] = true) : null));
            localStorage.wishlistData = JSON.stringify(wishlistData);
          }
          throttle(window.PubSub.publish(UIConfig.events.WISHLIST_REFRESH, true), 3000);
          resolve(response.data, response.status);
        });
      })
      .catch((response) => {
        return new Promise((resolve, reject) => {
          reject(response.error);
        });
      });
  };
  static PostWishlist = (apiData, moduleName, itemId, parentId) => {
    const mainObj = getMainObject();
    if (!mainObj) {
      return Promise.reject('no mainObj');
    }
    const url = `${mainObj.additionalProperty.wishlistUrl}/${
      ServiceConfig.getWishlistID() ? '?wid=' + ServiceConfig.getWishlistID() : ''
    }`;
    const isUserLoggedIn = Object.keys(getLoginUser()).length > 0 ? true : false;

    const config = {
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': mainObj.additionalProperty.ocpApimSubscriptionKey,
      },
      data: apiData,
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: '.c-wishlist-component',
    };

    return ApiWrapper.experienceServices(config, isUserLoggedIn ? true : false)
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (!ServiceConfig.getWishlistID()) {
            const cookieExpireTime =
              mainObj.additionalProperty.wishlistCookieExpireTime || UIConfig.defaultCookieExpireTime;
            setCookie('wishlistID', response.data.wishlistId, cookieExpireTime, getBaseURL());
          }
          const wishlistData = localStorage.wishlistData ? JSON.parse(localStorage.wishlistData) : {};
          if (itemId) {
            wishlistData[itemId] = true;
          }
          if (parentId) {
            wishlistData[parentId] = true;
          }
          localStorage.wishlistData = JSON.stringify(wishlistData);
          throttle(window.PubSub.publish(UIConfig.events.WISHLIST_REFRESH, true), 3000);
          resolve(response.data, response.status);
        });
      })
      .catch((response) => {
        return new Promise((resolve, reject) => {
          reject(response.error);
        });
      });
  };
  static DeleteWishlist = (wishlistChild, moduleName, type) => {
    const mainObj = getMainObject();
    if (!mainObj) {
      return Promise.reject('no mainObj');
    }
    const itemsToDelete = wishlistChild ? 'cid=' + wishlistChild.join() : '';
    const url = `${mainObj.additionalProperty.wishlistUrl}/${
      ServiceConfig.getWishlistID() ? '?wid=' + ServiceConfig.getWishlistID() + '&' : '?'
    }${itemsToDelete} `;
    const isUserLoggedIn = Object.keys(getLoginUser()).length > 0 ? true : false;

    const config = {
      url: url,
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': mainObj.additionalProperty.ocpApimSubscriptionKey,
      },
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: '.c-wishlist-component',
    };

    return ApiWrapper.experienceServices(config, isUserLoggedIn ? true : false)
      .then((response) => {
        return new Promise((resolve, reject) => {
          const wishlistData = localStorage.wishlistData ? JSON.parse(localStorage.wishlistData) : {};
          wishlistChild.forEach((child) => {
            wishlistData[child] = false;
          });
          localStorage.wishlistData = JSON.stringify(wishlistData);
          throttle(window.PubSub.publish(UIConfig.events.WISHLIST_REFRESH, true), 3000);
          resolve(response.data, response.status);
        });
      })
      .catch((response) => {
        return new Promise((resolve, reject) => {
          reject(response.error);
        });
      });
  };
}
