import UIConfig from '../../../../common/UIConfig';

class RecommendationActions {
  constructor({ productPackageTypeMapping }) {
    this.productPackageTypeMapping = productPackageTypeMapping;
    this.recommendationTabName = UIConfig.b2c.purchaseJourney.recommendationTabCode;
  }

  /*
        get event Type in lower case
    */
  getCheckedKey = (product, key) => {
    if (product && product.hasOwnProperty(key) && product[key]) {
      return product[key].toLowerCase();
    }
  };

  /*
        get string from semi-colon to weekdays Array
    */
  getArray = (product, key) => {
    if (product && product.hasOwnProperty(key) && product[key]) {
      return product[key].split(';');
    }
    return [];
  };

  /*
        get all product from cart without eventType
    */
  getAllCartProducts = (cartData) => {
    const cartState = cartData.cart;
    const cartProductArr = [];
    if (cartState && cartState.totalQuantity !== '0' && Object.keys(cartState.items).length) {
      Object.keys(cartState.items).forEach((cartProd) => {
        let filterProd = cartState.items[cartProd].products;
        if (filterProd && filterProd.length) {
          cartProductArr.push(filterProd[0]);
        }
      });
    }
    return cartProductArr;
  };

  /*
        get product from cart based on event type
    */
  getAllEventBasedCartProducts = (product, cartData) => {
    const cartState = cartData.cart;
    const cartProductArr = [];
    let baseProdEventType = this.getCheckedKey(product, 'eventType');
    if (cartState && cartState.totalQuantity !== '0' && Object.keys(cartState.items).length) {
      Object.keys(cartState.items).forEach((cartProd) => {
        let filterProd = cartState.items[cartProd].products;
        let itemEventType = this.getCheckedKey(filterProd[0], 'eventType');
        if (
          filterProd &&
          filterProd.length &&
          itemEventType &&
          baseProdEventType &&
          baseProdEventType === itemEventType
        ) {
          cartProductArr.push(filterProd[0]);
        }
      });
    }
    return cartProductArr;
  };

  /*
        check product whether it is a package type
        or not.
    */
  checkForPackageTypeProduct = (product, isEventTypeForAddons) => {
    let packageTypeArr = [];
    const eventType = this.getCheckedKey(product, 'eventType');
    const weekDaysArr = this.getArray(product, 'weekDays');
    let isPackageTypeProduct = true;
    if (this.productPackageTypeMapping && isEventTypeForAddons && eventType) {
      packageTypeArr = this.productPackageTypeMapping.addOns[eventType];
    } else if (this.productPackageTypeMapping && eventType) {
      packageTypeArr = this.productPackageTypeMapping[eventType];
    }

    if (weekDaysArr.length !== packageTypeArr.length) {
      isPackageTypeProduct = false;
    } else {
      packageTypeArr.every((item) => {
        if (weekDaysArr.indexOf(item) === -1) {
          isPackageTypeProduct = false;
          return false;
        } else {
          return true;
        }
      });
    }
    return isPackageTypeProduct;
  };

  /*
        get package difference between base product package
        and addOns Package
    */
  getPackageDifference = (product) => {
    let packageTypeArr = [],
      addonsPackageTypeArr = [],
      diffPackage = [];
    const eventType = this.getCheckedKey(product, 'eventType');
    if (eventType && this.productPackageTypeMapping) {
      packageTypeArr = this.productPackageTypeMapping[eventType];
      addonsPackageTypeArr = this.productPackageTypeMapping.addOns[eventType];
    }
    addonsPackageTypeArr.forEach((item) => {
      if (packageTypeArr.indexOf(item) === -1) {
        diffPackage.push(item);
      }
    });
    return diffPackage;
  };

  /*
        get parent products from filter cart products
    */
  getParentProducts = (product, cartData) => {
    const cartProducts = this.getAllEventBasedCartProducts(product, cartData);
    const parentsProducts = cartProducts.filter((item) => {
      const relatedProductIds = this.getArray(item, 'relatedProductIds');
      if (relatedProductIds && relatedProductIds.length) {
        return relatedProductIds.some((prod) => prod === product.productId);
      }
    });
    return parentsProducts;
  };

  /*
        get product tab name
    */
  getTabNameForProduct = (product) => {
    let productTabName = '';
    if (product.tab) {
      productTabName = Array.isArray(product.tab) ? product.tab[0] : product.tab;
      productTabName = productTabName.toLowerCase();
    } else if (product.productType) {
      productTabName = product.productType.toLowerCase();
    }
    return productTabName;
  };

  /*
        get parent products from filter cart products
    */
  getChildAddOnsProducts = (product, cartData) => {
    const cartProducts = this.getAllEventBasedCartProducts(product, cartData);
    const isChildPackageProduct = this.checkForPackageTypeProduct(product, true);
    const recommendedCartProducts =
      cartProducts.filter((item) => this.recommendationTabName === this.getTabNameForProduct(item)) || [];
    const prodWeekDaysArr = this.getArray(product, 'weekDays');
    const productSubEventType = this.getCheckedKey(product, 'subEventType');
    const childCartTierProducts = recommendedCartProducts.filter((item) => {
      const weekDaysArr = this.getArray(item, 'weekDays');
      const itemSubEventType = this.getCheckedKey(item, 'subEventType');
      const isItemPostPurchasedAddon = item.isPostPurchasedAddon;
      let filterChecked = false;
      if (itemSubEventType && productSubEventType && weekDaysArr.length && prodWeekDaysArr.length) {
        if (isChildPackageProduct) {
          filterChecked = productSubEventType === itemSubEventType;
        } else {
          filterChecked =
            productSubEventType === itemSubEventType &&
            weekDaysArr.indexOf(prodWeekDaysArr[0]) !== -1 &&
            !isItemPostPurchasedAddon;
        }
      }
      return filterChecked;
    });
    return childCartTierProducts;
  };

  /*
        get max quantity
    */
  getMaxQuantityPackageProduct = (quantityObj) => {
    let nonPkgArr = Object.keys(quantityObj);
    if (nonPkgArr.length > 1) {
      let arr = Object.values(quantityObj);
      let max = Math.max(...arr);
      return max;
    } else {
      return quantityObj[nonPkgArr[0]];
    }
  };

  /*
        get quantity for child addons product from parent
        product except quantity for package product
    */
  getAdditionalAddOnsQuantity = (product, cartData) => {
    const cartProducts = this.getAllEventBasedCartProducts(product, cartData);
    const filterCartProducts =
      cartProducts.filter((item) => {
        if (this.recommendationTabName !== this.getTabNameForProduct(item)) {
          const relatedProductIds = this.getArray(item, 'relatedProductIds');
          return relatedProductIds.indexOf(product.productId) !== -1;
        }
      }) || [];
    let productTierQuantity = {};
    let sumQuantity = 0;
    filterCartProducts.forEach((item) => {
      if (!this.checkForPackageTypeProduct(item)) {
        sumQuantity += item.groupedQuantity;
        let itemWeekDays = this.getArray(item, 'weekDays');
        itemWeekDays.forEach((tier) => {
          if (productTierQuantity.hasOwnProperty(tier)) {
            productTierQuantity[tier] = productTierQuantity[tier] + item.groupedQuantity;
          } else {
            productTierQuantity[tier] = item.groupedQuantity;
          }
        });
      }
    });
    const packageDiffArr = this.getPackageDifference(product);
    packageDiffArr.forEach((item) => {
      productTierQuantity[item] = sumQuantity;
    });
    return productTierQuantity;
  };

  /*
        get quantity for both child addons and parent product
        return overall quantity of package and non package
    */
  getQuantityProducts = (cartProducts, isEventTypeForAddons, isChildPackageType, product, cartData) => {
    let quantityObj = {
      pkg: 0,
      nonPkg: 0,
      nonPkgObj: {},
    };
    cartProducts.forEach((item) => {
      const itemWeekDays = this.getArray(item, 'weekDays');
      if (
        (isEventTypeForAddons && this.checkForPackageTypeProduct(item, true)) ||
        this.checkForPackageTypeProduct(item)
      ) {
        quantityObj.pkg = quantityObj.pkg + item.groupedQuantity;
      } else if (isChildPackageType && quantityObj.nonPkgObj.hasOwnProperty(itemWeekDays[0])) {
        quantityObj.nonPkgObj[itemWeekDays[0]] = quantityObj.nonPkgObj[itemWeekDays[0]] + item.groupedQuantity;
      } else if (isChildPackageType) {
        quantityObj.nonPkgObj[itemWeekDays[0]] = item.groupedQuantity;
      } else {
        quantityObj.nonPkg = quantityObj.nonPkg + item.groupedQuantity;
      }
    });

    let nonPkgArr = Object.keys(quantityObj.nonPkgObj);
    if (isChildPackageType && nonPkgArr.length) {
      // filter parent product which has this tier except package product
      const tierQuantity = this.getAdditionalAddOnsQuantity(product, cartData);
      nonPkgArr.forEach((item) => {
        if (tierQuantity.hasOwnProperty(item)) {
          let currQuantity = quantityObj.nonPkgObj[item] - tierQuantity[item];
          if (currQuantity < 0) {
            quantityObj.nonPkgObj[item] = 0;
          } else {
            quantityObj.nonPkgObj[item] = quantityObj.nonPkgObj[item] - tierQuantity[item];
          }
        }
      });
      const max = this.getMaxQuantityPackageProduct(quantityObj.nonPkgObj);
      quantityObj.nonPkg = max;
    }
    return quantityObj.pkg + quantityObj.nonPkg;
  };

  deepCopyArrayOfObjects = (arryObject) => {
    return JSON.parse(JSON.stringify(arryObject));
  };

  getParentProductQuantity = (product, cartData, parentProduct, quantity) => {
    let pCartQuantity = 0,
      cartParentFilteredProd = [];
    let parentCartProducts = this.getParentProducts(product, cartData) || [];
    if (parentProduct) {
      cartParentFilteredProd = this.deepCopyArrayOfObjects(parentCartProducts);
      cartParentFilteredProd = cartParentFilteredProd.map((item) => {
        if (item.productId === parentProduct.productId) {
          item.groupedQuantity = parseInt(item.groupedQuantity, 10) + quantity;
        }
        return item;
      });
      parentCartProducts = cartParentFilteredProd;
    }
    if (parentCartProducts && parentCartProducts.length) {
      pCartQuantity = this.getQuantityProducts(parentCartProducts);
    }
    return pCartQuantity;
  };
  getChildProductFromCart = (product, cartData) => {
    const { crossSellType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    const cartProducts = this.getAllEventBasedCartProducts(product, cartData);
    const recommendedCartProducts =
      (cartProducts &&
        cartProducts.length > 0 &&
        cartProducts.filter((item) => this.recommendationTabName === this.getTabNameForProduct(item))) ||
      [];
    const childCartTierProducts =
      recommendedCartProducts &&
      recommendedCartProducts.length > 0 &&
      recommendedCartProducts.find(
        (item) => item.productId === product.productId && item.subEventType.toLowerCase() === crossSellType.ARC,
      );
    if (childCartTierProducts && Object.keys(childCartTierProducts).length > 0) {
      return true;
    }
    return false;
  };
  checkRecommendedProductAvailable = (product, cartData) => {
    const cartProducts = this.getAllEventBasedCartProducts(product, cartData);
    const recommendedCartProducts =
      (cartProducts &&
        cartProducts.length > 0 &&
        cartProducts.filter((item) => this.recommendationTabName === this.getTabNameForProduct(item))) ||
      [];
    if (recommendedCartProducts && recommendedCartProducts.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  getChildProductQuantity = (product, cartData) => {
    let cCartQuantity = 0;
    const childCartProducts = this.getChildAddOnsProducts(product, cartData) || [];
    if (childCartProducts && childCartProducts.length) {
      if (this.checkForPackageTypeProduct(product, true)) {
        cCartQuantity = this.getQuantityProducts(childCartProducts, true, true, product, cartData);
      } else {
        cCartQuantity = this.getQuantityProducts(childCartProducts, true);
      }
    }
    return cCartQuantity;
  };

  /*
       check Max quantity
       return max quantity which can be allowed for addons based on parent product
    */

  checkMaxQuantity = (product, cartData, parentProduct, quantity) => {
    const pCartQuantity = this.getParentProductQuantity(product, cartData, parentProduct, quantity);
    const cCartQuantity = this.getChildProductQuantity(product, cartData);
    return pCartQuantity - cCartQuantity;
  };
  /*
       check Max quantity based on productId
       return max quantity which can be allowed for addons based on parent product
    */

  checkMaxQuantityByProductId = (parentProduct, cartData, productId, quantity) => {
    const cartProducts = this.getAllCartProducts(cartData);
    const product = cartProducts.find((item) => item.productId === productId);
    let remQuantity = 0;
    if (product) {
      remQuantity = this.checkMaxQuantity(product, cartData, parentProduct, quantity);
    }
    return remQuantity;
  };

  checkMaxQuantityAndErrorProdArr = (
    product,
    cartData,
    addOnsErrorProdArr,
    quantity,
    isRecommendationData,
    isPostPurchasedAddon,
  ) => {
    let addOnsErrorArray = addOnsErrorProdArr.slice();
    let isMaxLimit = false;
    let isMaxError = false;
    if (quantity < 0) {
      addOnsErrorArray = [];
    } else {
      if (product.isPostPurchasedAddon) {
        const { maxQuantity, isUnlimited } = product.postPurchasedAddonCount;
        const prQuantity = product.quantity;
        isMaxError = prQuantity >= maxQuantity && !isUnlimited;
      } else {
        const remQuantity = this.checkMaxQuantity(product, cartData);
        isMaxError = remQuantity < quantity;
      }
      if (isMaxError) {
        if (addOnsErrorProdArr.indexOf(product.productIdType) === -1) {
          addOnsErrorArray.push(product.productIdType);
        }
        isMaxLimit = true;
      } else if (addOnsErrorProdArr.indexOf(product.productIdType) !== -1) {
        addOnsErrorArray = addOnsErrorProdArr.filter((prodId) => prodId !== product.productIdType);
      }
    }
    return { isMaxLimit, addOnsErrorArray };
  };

  getAddOnsProdsOnParentQuantityChange = (parentProduct, cartData, addOnsErrorProdArr, quantity) => {
    let addOnsErrorArray = addOnsErrorProdArr && addOnsErrorProdArr.slice();
    let isOpenPopOverlay = false;
    if (quantity < 0) {
      const relatedProductIds = this.getArray(parentProduct, 'relatedProductIds');
      relatedProductIds.forEach((productId) => {
        if (!isOpenPopOverlay) {
          const remQuantity = this.checkMaxQuantityByProductId(parentProduct, cartData, productId, quantity);
          if (remQuantity < 0) {
            isOpenPopOverlay = true;
          }
        }
      });
    } else {
      addOnsErrorArray = [];
    }
    return { addOnsErrorArray, isOpenPopOverlay, isMaxLimit: isOpenPopOverlay };
  };

  getAddOnsProdsOnParent = (parentProduct, cartData, addOnsErrorProdArr, quantity) => {
    let addOnsErrorArray = addOnsErrorProdArr && addOnsErrorProdArr.slice();
    let isOpenPopOverlay = false;
    if (quantity < 0) {
      const relatedProductIds = this.getArray(parentProduct, 'relatedProductIds');
      const cartProducts = this.getAllCartProducts(cartData);

      relatedProductIds.forEach((productId) => {
        if (!isOpenPopOverlay) {
          const product = cartProducts.find((item) => item.productId === productId);
          if (product && Object.values(product).length > 0) {
            isOpenPopOverlay = true;
          }
        }
      });
    } else {
      addOnsErrorArray = [];
    }
    return { addOnsErrorArray, isOpenPopOverlay, isMaxLimit: isOpenPopOverlay };
  };
}

export default RecommendationActions;
