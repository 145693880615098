import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import CalenderOverlay from './calender-overlay-component';
import DrivingExperienceB2C from '../add-ons/b2c-driving-experience/b2c-driving-experience-component';
import {
  canUseDOM,
  createCustomEvent,
  isEnterPressed,
  isEventTypeProduct,
  isMatchTenant,
  checkTenant,
  checkParksTenants,
} from '../../../../common/utility';
import GTMData from '../gtm-data';
import { DynamicContent, Image, Spinner, AnchorLink } from '../../../presentation/base';
import TicketTooltip from '../../../presentation/b2c-purchase-journey/ticket-tooltip/ticket-tooltip';
import UIConfig from '../../../../common/UIConfig';
import RecommendationActions from '../recommendation-rules/recommendation-rules-component';
import AddOnsEventComponent from '../add-ons/add-ons-event';
import { logComponentRenderingError } from '../../../../common/logger';
import userBeforeFirstRender from '../../../../hooks/useBeforeFirstRender';

const checkForRecommendationControl = (
  ticket,
  isRecommendationControlled,
  recommendationTabName,
  isRecommendationAllowedForAll,
) => {
  if (isRecommendationControlled && ticket.tab && recommendationTabName === ticket.tab.toLowerCase()) {
    const { crossSellType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    // For ADD ONS products which are required to be in unlimited quantity
    if (
      isMatchTenant(UIConfig.ymcB2CTenant) &&
      (isRecommendationAllowedForAll ||
        (ticket.subEventType && ticket.subEventType.toLowerCase() === crossSellType.FEB))
    ) {
      return false;
    } else if (isRecommendationAllowedForAll || isEventTypeProduct(ticket)) {
      return true;
    }
  }
  return false;
};

const componentToAppend = ({ props, closeOverlay, isDrivingExp, ticketDetails }) => {
  const {
    additionalProds,
    addToCartCallback,
    businessErrors,
    cartData,
    category,
    coveoMapList,
    data,
    dateSelector,
    getDiscountedProducts,
    overlayErrorCallback,
    searchProductList,
    services,
    ticket,
    timeSlotSelector,
    variant,
    visitorCounter,
    showMinicartCouponCode,
    discountMap,
    enablePJPopup,
    copySearchProductList,
    isSingleImage,
  } = props;

  let Component = CalenderOverlay;
  if (data.isEventQuantityOverlay) {
    Component = AddOnsEventComponent;
  } else if (isDrivingExp) {
    Component = DrivingExperienceB2C;
  }

  return (
    <Component
      data={data}
      product={ticket}
      counterData={visitorCounter}
      calenderData={dateSelector}
      addToCartCallback={addToCartCallback}
      closeOverlay={closeOverlay}
      services={services}
      multiTicketSelector={category.multiTicketSelector}
      productOverlaySelector={category.productOverlaySelector}
      showProductNameInOverlay={category.showProductNameInOverlay}
      getDiscountedProducts={getDiscountedProducts}
      timeSlotData={timeSlotSelector}
      cartData={cartData}
      businessErrors={businessErrors}
      additionalProds={additionalProds}
      coveoMapList={coveoMapList}
      searchProductList={searchProductList}
      copySearchProductList={copySearchProductList}
      showMinicartCouponCode={showMinicartCouponCode}
      discountMap={discountMap}
      enablePJPopup={enablePJPopup}
      variant={variant}
      overlayErrorCallback={overlayErrorCallback}
      legends={category.legends}
      moneyCardDetails={category.moneyCardAmount}
      subCategory={category.coveoValue}
      enableDynamicCalendar={category.enableDynamicCalendar}
      disableTotalPrice={category.disableTotalPrice}
      childVariant={props.variant}
      recommendationUrl={props.recommendationUrl}
      attentions={ticketDetails?.attentions}
      attentionTitle={ticketDetails?.attentionsLabel}
      overlayShowPrice={ticketDetails?.prices}
      enableTimeSlotExperience={category?.enableTimeSlotExperience}
    />
  );
};

const openOverlay = (tncPopup, isPJOnly) => {
  if (!isExperienceEditorActive()) {
    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      dataToAppend: tncOverlay(tncPopup),
      customClass: classNames('ride-info-overlay c-flash-sale-overlay', { 'v-multi-product-widget': isPJOnly }),
    });
  }
};

const tncOverlay = (tncPopup) => {
  return (
    <div className="overlay-sub-container">
      {tncPopup.tncTitle && (
        <DynamicContent
          tagName="h2"
          innerHtml={tncPopup.tncTitle}
          attrs={{
            className: 'overlay-sub-heading',
          }}
        />
      )}
      {tncPopup.tncDescription && (
        <DynamicContent
          tagName="p"
          innerHtml={tncPopup.tncDescription}
          attrs={{
            className: 'overlay-description',
          }}
        />
      )}
    </div>
  );
};
// get conditional price classes for component
const getClassPriceClassName = (className, isOfferActive, isOfferPrice) => {
  let classes = className;
  if (isOfferActive) {
    classes += ' active-offer';
  }
  return classes;
};

const renderPrice = ({ price, isOfferPrice, isSwad }) => {
  return (
    <>
      <DynamicContent
        tagName="span"
        attrs={{ className: getClassPriceClassName('c-fast-price-currency', price.isOfferActive) }}
        innerHtml={price.currency}
      />
      <DynamicContent
        tagName="span"
        attrs={{ className: getClassPriceClassName('c-fast-price-price', price.isOfferActive) }}
        innerHtml={
          isOfferPrice
            ? isSwad
              ? price.offerPrice.toLocaleString('en', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : price.offerPrice
            : isSwad
            ? price.price.toLocaleString('en', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            : price.price
        }
      />
    </>
  );
};
const checkKeyDownHandler = (e, tncPopup, isPJOnly) => {
  if (isEnterPressed(e)) {
    openOverlay(tncPopup, isPJOnly);
  }
};

// get conditional price classes for wrapper component
const getPriceWrapperClass = (isSubPrice, isOfferPrice) => {
  let className = 'price-wrapper ';
  if (isSubPrice) {
    className += 'sub-price-currency';
  }
  if (isOfferPrice) {
    className += ' remove-margin';
  }
  return className;
};
const renderPriceList = (price, index, showPriceTogether) => {
  return (
    <li className="c-fast-price" key={index}>
      <DynamicContent
        tagName="span"
        attrs={{
          className: `${
            price.isOfferActive && price.offerPrice
              ? 'offer-price-block-wrapper c-fast-price-currency'
              : 'c-fast-price-currency'
          }`,
        }}
        innerHtml={price.for}
      />
      {price.from && (
        <DynamicContent tagName="span" attrs={{ className: 'c-fast-price-description' }} innerHtml={price.from} />
      )}
      {showPriceTogether && price.isOfferActive && price.offerPrice ? (
        <div className={getPriceWrapperClass(price.for) + ' offer-price-wrapper'}>
          {renderPrice({ price, isOfferPrice: true, isSwad: showPriceTogether })}
        </div>
      ) : (
        <></>
      )}
      {showPriceTogether ? (
        <div className={getPriceWrapperClass(price.for, price.isOfferActive ? true : false)}>
          {renderPrice({ price, isSwad: showPriceTogether })}
        </div>
      ) : (
        renderPrice({ price })
      )}
    </li>
  );
};

//function added to handle the keyboard handling of the custom checkboxes
const handleCheckBoxKeyDownEvent = (e) => {
  if (isEnterPressed(e)) {
    handleCheckUncheck(e.target);
  }
};

const handleCheckUncheck = (targetElement) => {
  const checkBox = document.querySelector('#' + targetElement.previousSibling.id);
  if (checkBox !== null) {
    const event = createCustomEvent('change');
    checkBox.checked = !checkBox.checked;
    checkBox.dispatchEvent(event);
  }
};

const handleOnClick = (e, handleOnClick, coveoValue) => {
  handleOnClick(coveoValue, e.target);
};

const getOverlaySelector = (
  data,
  dateSelector,
  renderQuantityOverlay,
  renderDateSelector,
  renderCounterAndAddToCart,
  ticketDetails,
  renderPriceList,
  showPostPurchaseRecommendation,
  bookingUrl,
  postPurchaseRecommendationCTA,
  isGAEnabledForCrossSell,
  productMainCategoryWithTabName,
) => {
  if (data.isQuantityInOverlay) {
    return renderQuantityOverlay();
  } else if (dateSelector && !showPostPurchaseRecommendation) {
    return renderDateSelector(ticketDetails, renderPriceList);
  }
  return renderCounterAndAddToCart(
    ticketDetails,
    showPostPurchaseRecommendation,
    bookingUrl,
    postPurchaseRecommendationCTA,
    isGAEnabledForCrossSell,
    productMainCategoryWithTabName,
  );
};

const TileComponent = (props) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [enableAddToCart, setEnableAddToCart] = useState(false);
  const [showMaxQuantityError, setShowMaxQuantityError] = useState(false);
  const [showRcmdProductsLimitError, setRcmdProductsLimitError] = useState(false);

  const coveoValue = props.category.coveoValue || props.category.ticketSelector.ticket.coveoValue;
  const isRecommendationControlled =
    props.additionalRecommendedData && props.additionalRecommendedData.isRecommendationControlled;
  const isRecommendationAllowedForAll =
    props.additionalRecommendedData && props.additionalRecommendedData.isRecommendationAllowedForAll;
  const recommendationTabName = UIConfig.b2c.purchaseJourney.recommendationTabCode;
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  const isPlpVariant = props.variant === 'v-overlay-selected';
  const isPJOnly = props.data.cssClass === 'v-multi-product-widget';
  const isDynamicPricingProd = props.data.variant === 'v-dynamic-product-pricing';

  let quantity = useRef(props.ticket.quantity || 0);
  const recommendationActions = useRef(null);

  userBeforeFirstRender(() => {
    if (isRecommendationControlled) {
      recommendationActions.current = new RecommendationActions({
        productPackageTypeMapping: props.additionalRecommendedData.productPackageTypeMapping,
      });
    }
  });

  useEffect(() => {
    if (!props.data.isEventQuantityOverlay) {
      canUseDOM() && subscribeCloseOverlay();
      isSwad &&
        window.PubSub.subscribe('closeUpsellOverlay', () => {
          subscribeCloseOverlay();
        });
      if (isPlpVariant) {
        setIsOverlayOpen(true);
      }
    }
    toggleAddToCart();
  }, []);

  useEffect(() => {
    if (props.productWidget && !props.data.isEventQuantityOverlay) {
      if (isPlpVariant) {
        setIsOverlayOpen(true);
      }
    }
  }, [props.productWidget]);

  const subscribeCloseOverlay = () => {
    window.PubSub.subscribe('closeOverlay', () => {
      if (isDynamicPricingProd) {
        props.copySearchProductList.forEach((newCoveo, index) => {
          props.searchProductList[index].gross = newCoveo.gross;
        });
      }
      setIsOverlayOpen(false);
    });
  };

  // componentWillReceiveProps equivalent code
  const isFirstRun = useRef(true);
  const prevTicket = useRef(null);
  const prevCartData = useRef(null);
  useEffect(() => {
    const { ticket, cartData } = props;
    const { crossSellType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    if (isFirstRun.current) {
      isFirstRun.current = false;
      prevTicket.current = ticket;
      prevCartData.current = cartData;
      return;
    }

    if (
      checkForRecommendationControl(
        ticket,
        isRecommendationControlled,
        recommendationTabName,
        isRecommendationAllowedForAll,
      )
    ) {
      const propParentQuantity = recommendationActions.current.getParentProductQuantity(ticket, cartData);
      const prevPropParentQuantity = recommendationActions.current.getParentProductQuantity(
        prevTicket.current,
        prevCartData.current,
      );
      const propChildQuantity = recommendationActions.current.getChildProductQuantity(ticket, cartData);
      const prevPropChildQuantity = recommendationActions.current.getChildProductQuantity(
        prevTicket.current,
        prevCartData.current,
      );
      const childProductFromCart = recommendationActions.current.getChildProductFromCart(ticket, cartData);
      const recommendedProductFromCart = recommendationActions.current.checkRecommendedProductAvailable(
        ticket,
        cartData,
      );

      if (propParentQuantity > prevPropParentQuantity || propChildQuantity < prevPropChildQuantity) {
        setEnableAddToCart(quantity.current > 0);
        setShowMaxQuantityError(false);
        setRcmdProductsLimitError(false);
      } else if (propParentQuantity !== prevPropParentQuantity || propChildQuantity !== prevPropChildQuantity) {
        quantity.current = 0;
        setEnableAddToCart(quantity.current > 0);
        setShowMaxQuantityError(false);
        setRcmdProductsLimitError(false);
      }
      if (
        childProductFromCart &&
        recommendedProductFromCart &&
        ticket.subEventType.toLowerCase() === crossSellType.ARC
      ) {
        if (propChildQuantity < propParentQuantity) {
          setEnableAddToCart(quantity.current > 0);
        } else {
          setEnableAddToCart(false);
        }
      } else if (recommendedProductFromCart && ticket.subEventType.toLowerCase() === crossSellType.ARC) {
        setEnableAddToCart(false);
      } else {
        setRcmdProductsLimitError(false);
      }
      prevTicket.current = ticket;
      prevCartData.current = cartData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const toggleAddToCart = () => {
    setEnableAddToCart(quantity.current > 0);
  };

  const updateQuantity = (updatedQuantity) => {
    const { ticket, cartData } = props;
    const { crossSellType } = UIConfig.b2c.purchaseJourney.ymcMapping;

    if (
      checkForRecommendationControl(
        ticket,
        isRecommendationControlled,
        recommendationTabName,
        isRecommendationAllowedForAll,
      )
    ) {
      const maxQuantity = recommendationActions.current.checkMaxQuantity(ticket, cartData);
      const childProductFromCart = recommendationActions.current.getChildProductFromCart(ticket, cartData);
      const recommendedProductFromCart = recommendationActions.current.checkRecommendedProductAvailable(
        ticket,
        cartData,
      );
      if (
        !childProductFromCart &&
        recommendedProductFromCart &&
        ticket.subEventType.toLowerCase() === crossSellType.ARC
      ) {
        setRcmdProductsLimitError(true);
        setShowMaxQuantityError(false);
        setEnableAddToCart(false);
      } else if (updatedQuantity > maxQuantity) {
        setShowMaxQuantityError(true);
        setRcmdProductsLimitError(false);
        setEnableAddToCart(quantity.current > 0);
      } else {
        quantity.current = updatedQuantity;
        setShowMaxQuantityError(false);
        setRcmdProductsLimitError(false);
        setEnableAddToCart(quantity.current > 0);
      }
    } else {
      quantity.current = updatedQuantity;
      toggleAddToCart();
    }
  };

  const renderCounterAndAddToCart = (
    ticketDetails,
    showPostPurchaseRecommendation,
    bookingUrl,
    postPurchaseRecommendationCTA,
    isGAEnabledForCrossSell,
    productMainCategoryWithTabName,
  ) => {
    const { additionalRecommendedData, data, visitorCounter } = props;

    const increaseDecreaseBy = visitorCounter.options[0].increaseDecreaseBy || 1;
    const ariaLabelDecrease = visitorCounter.options[0].ariaLabelDecrease || '';
    const ariaLabelIncrease = visitorCounter.options[0].ariaLabelIncrease || '';
    const ticketCounterText = visitorCounter.options[0].ticketCounterText || '';
    let cartLabels = {};
    if (additionalRecommendedData) {
      cartLabels = additionalRecommendedData.cartLabels;
    }
    return (
      <div className={`c-fast-counter-cart-button-wrapper${quantity.current === 0 ? '' : ' color-change'}`}>
        {isSwad ? (
          <div className={'c-spinner-wrapper'}>
            {(ticketDetails?.prices[0]?.currency || ticketDetails?.prices[0]?.price) && (
              <p className="ticket-price">
                {ticketDetails?.prices[0]?.currency + ' ' + ticketDetails?.prices[0]?.price}
              </p>
            )}
            <Spinner
              updateQuantity={updateQuantity}
              isInputDisabled="true"
              min={visitorCounter.minCount}
              max={visitorCounter.maxCount}
              quantity={quantity.current}
              increaseDecreaseBy={increaseDecreaseBy}
              ariaLabelDecrease={ariaLabelDecrease}
              ariaLabelIncrease={ariaLabelIncrease}
              ticketCounterText={ticketCounterText}
            />
          </div>
        ) : (
          <Spinner
            updateQuantity={updateQuantity}
            isInputDisabled="true"
            min={0}
            max={visitorCounter.maxCount}
            quantity={quantity.current}
            increaseDecreaseBy={increaseDecreaseBy}
            ariaLabelDecrease={ariaLabelDecrease}
            ariaLabelIncrease={ariaLabelIncrease}
            ticketCounterText={ticketCounterText}
          />
        )}
        {showMaxQuantityError && cartLabels && cartLabels.maxLimitErrorLabel && (
          <p className="max-limit-error">{cartLabels.maxLimitErrorLabel}</p>
        )}
        {showRcmdProductsLimitError && cartLabels && cartLabels.rcmdProductsLimitError && (
          <p className="max-limit-error">{cartLabels.rcmdProductsLimitError}</p>
        )}

        <div className="c-fast-bottom">
          {isSwad && visitorCounter?.description && (
            <DynamicContent
              attrs={{ className: 'body-2 c-primary-section-description' }}
              tagName="p"
              innerHtml={visitorCounter.description}
            />
          )}
          <div className={isSwad ? 'btn-primary' : enableAddToCart ? 'btn-primary' : 'btn-primary disabled'}>
            {!showPostPurchaseRecommendation ? (
              <button
                type="button"
                className="add-to-cart"
                aria-label={`${ticketDetails.title} ${data.addToCart}`}
                onClick={addToCart.bind(this)}
                tabIndex={!enableAddToCart && '-1'}
              >
                {data.addToCart}
              </button>
            ) : (
              <button
                type="button"
                className="add-to-cart"
                aria-label={`${ticketDetails.title} ${data.addToCart}`}
                onClick={() => {
                  let windowURL = bookingUrl;
                  if (ticketDetails.coveoValue) {
                    windowURL += '?tkt=' + ticketDetails.coveoValue + '&addedFrom=' + UIConfig.postPurchaseCrossSellTxt;
                  }
                  if (ticketDetails.productCategoryType) {
                    windowURL += '#' + ticketDetails.productCategoryType;
                  }
                  if (isGAEnabledForCrossSell) {
                    let ticketData = ticketDetails;
                    ticketData.productCategory = productMainCategoryWithTabName
                      ? Object.keys(productMainCategoryWithTabName)?.find(
                          (key) => productMainCategoryWithTabName[key] === ticketDetails.productCategoryType,
                        )
                      : ticketDetails.productCategoryType;
                    ticketData.price = ticketDetails.prices;
                    let productData = { products: ticketData };
                    productData.clickedFrom = UIConfig.postPurchaseCrossSellTxt;
                    GTMData.push('ticketClick', productData);
                  }

                  window.location = windowURL;
                }}
                tabIndex={0}
              >
                {postPurchaseRecommendationCTA}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const addToCart = (e) => {
    const { addToCartCallback, ticket, visitorCounter } = props;
    e.stopPropagation();
    ticket.quantity = quantity.current;
    if (isEventTypeProduct(ticket) && ticket.performanceAk) {
      ticket.performanceId = ticket.performanceAk;
    }
    ticket.minCount = visitorCounter.minCount;
    ticket.maxCount = visitorCounter.maxCount;
    addToCartCallback && addToCartCallback([Object.assign({}, ticket)], true);
    updateQuantity(isSwad ? visitorCounter.minCount : 0);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    props.productWidget && window.PubSub.publish('mainOverLayClosed', true);
    if (isDynamicPricingProd) {
      props.copySearchProductList.forEach((newCoveo, index) => {
        props.searchProductList[index].gross = newCoveo.gross;
      });
    }
  };

  const GTMDataOnSelectItem = () => {
    try {
      const { data, additionalProds } = props;
      const category =
        data && data.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      const productList = [];
      if (Array.isArray(additionalProds) && additionalProds.length > 1) {
        const { category } = additionalProds[0];
        const prodCat = Array.isArray(category) ? category[0] : category;
        const selectedTab = data.controls.extras.options.find((itm) => itm.coveoValue === prodCat);

        const selectedTabProductIds = selectedTab.productOverlaySelector.collections.map((itm) => itm.productId);

        productList.push(...additionalProds.filter((itm) => selectedTabProductIds.includes(itm.productId)));
        productList.push(
          ...additionalProds.filter((itm) => !productList.some((prod) => prod.productId === itm.productId)),
        );
      }
      GTMData.push('selectItem', {
        itemsList: productList?.length ? productList : additionalProds,
        category,
        gaTabTitle: data.gaTabTitle || undefined,
      });
    } catch (error) {
      console.log('GA4 error: ', error);
    }
  };

  const renderDateSelector = (ticketDetails, renderPriceList) => {
    const { data, dateSelector, ticket, visitorCounter } = props;

    const multiTicketSelector = props.category.multiTicketSelector;
    const keyMap = multiTicketSelector && multiTicketSelector.stringMapping;
    const category = ticket.category;
    const isDrivingExp =
      keyMap &&
      (category.indexOf(keyMap.drivingExperience) !== -1 || category.indexOf(keyMap.passengerExperience) !== -1);
    const classToAppend = isDrivingExp
      ? 'drvng-exp addOns-Overlay'
      : classNames('addOns-Overlay calendar-overlay-genral-admission', {
          'v-multi-product-widget': isPJOnly,
          'v-dynamic-product-pricing': isDynamicPricingProd,
          'fit-onscreen-overlay': data.enableTac,
          'calender-overlay-mobile-height mobile-fix-height': isSwad,
        });

    const btnLabel = dateSelector.options[0].calendarSettings && dateSelector.options[0].calendarSettings.calendarLabel;
    return (
      <>
        {isSwad && visitorCounter?.description && !isDynamicPricingProd && (
          <DynamicContent
            attrs={{ className: 'body-2 c-primary-section-description' }}
            tagName="p"
            innerHtml={visitorCounter.description}
          />
        )}
        {!isPlpVariant && !isPJOnly && (
          <div className="btn-primary">
            <Text
              tag="button"
              onClick={() => {
                setIsOverlayOpen(true);
                if (checkParksTenants()) {
                  GTMDataOnSelectItem();
                }
              }}
              aria-label={`${ticketDetails.title} ${btnLabel}`}
              field={{ value: btnLabel, editable: btnLabel }}
            />
          </div>
        )}

        {isPJOnly && (
          <div className="btn-primary">
            <button
              type="button"
              aria-label={`${ticketDetails.title} ${dateSelector.options[0].calendarSettings &&
                dateSelector.options[0].calendarSettings.calendarLabel}`}
              onClick={() => {
                setIsOverlayOpen(true);
                if (checkParksTenants()) {
                  GTMDataOnSelectItem();
                }
              }}
            >
              {ticketDetails.prices && ticketDetails.prices.map((price, index) => renderPriceList(price, index))}{' '}
            </button>
          </div>
        )}

        {isOverlayOpen &&
          window.PubSub.publish('toggleOverlayState', {
            shouldOpen: true,
            customClass: classToAppend,
            dataToAppend: componentToAppend({ props, closeOverlay, isDrivingExp, ticketDetails }),
          })}
      </>
    );
  };
  const renderTicketContent = ({
    ticket,
    ticketDetails,
    info,
    eventLocationName,
    category,
    data,
    dateSelector,
    tncPopup,
    visitorCounter,
    showPostPurchaseRecommendation,
    bookingUrl,
    postPurchaseRecommendationCTA,
    isGAEnabledForCrossSell,
    productMainCategoryWithTabName,
  }) => {
    return (
      <>
        <div className="c-fast-desc">
          {!ticketDetails.image && ticketDetails.offerLabel && (
            <DynamicContent tagName="div" attrs={{ className: 'offer-label' }} innerHtml={ticketDetails.offerLabel} />
          )}
          {ticket.productDetailLink && !isSwad ? (
            <Text
              tag="a"
              href={ticket.productDetailLink}
              className="heading-4"
              field={{
                value: ticketDetails.title,
                editable: ticketDetails.title,
              }}
            />
          ) : (
            <DynamicContent tagName="div" attrs={{ className: 'heading-4' }} innerHtml={ticketDetails.title} />
          )}
          {isSwad && (
            <>
              <ul className="c-fast-price-list">
                {!isPJOnly &&
                  ticketDetails.prices &&
                  ticketDetails.prices.map((price, index) => renderPriceList(price, index, isSwad))}
              </ul>
              <DynamicContent
                tagName="div"
                attrs={{ className: 'body-2 ticket-desc' }}
                innerHtml={ticketDetails.description}
              />
            </>
          )}

          {eventLocationName && (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'heading-6 event-location' }}
              innerHtml={eventLocationName}
            />
          )}
          <DynamicContent tagName="div" attrs={{ className: 'body-1' }} innerHtml={ticketDetails.subTitle} />
          {category && category.Icons && category.Icons.length > 0 && (
            <div className="parks-logo">
              {category.Icons.map((icon) => (
                <Image image={icon} disableLazyLoad={true} />
              ))}
            </div>
          )}
          {!isSwad && (
            <>
              <DynamicContent
                tagName="div"
                attrs={{ className: 'body-2 ticket-desc' }}
                innerHtml={ticketDetails.description}
              />
              <div className="c-fast-price-list">
                {!isPJOnly &&
                  ticketDetails.prices &&
                  ticketDetails.prices.map((price, index) => renderPriceList(price, index))}
              </div>
            </>
          )}
          {isSwad && info && (info.title || info.description) && (
            <div className="ticket-includes-wrapper">
              <DynamicContent tagName="span" attrs={{ className: 'ticket-includes-title' }} innerHtml={info.title} />
              <DynamicContent
                tagName="span"
                attrs={{ className: 'ticket-includes-description' }}
                innerHtml={info.description}
              />
            </div>
          )}
          {isSwad && (ticketDetails?.readMoreLink?.href || ticket.productDetailLink) && ticketDetails.readMoreLabel && (
            <Text
              tag="a"
              target="_blank"
              href={ticketDetails?.readMoreLink?.href || ticket.productDetailLink}
              className="read-more-link"
              field={{ value: ticketDetails.readMoreLabel }}
            />
          )}
        </div>
        <div className="c-fast-logo-counter-wrapper">
          {!dateSelector && ticket.image && !isSwad && (
            <div className="c-fast-logo">
              <Image image={null} disableLazyLoad={true} />
            </div>
          )}
          <div className="c-fast-counter">
            {data.isEventQuantityOverlay
              ? componentToAppend({ props, closeOverlay, ticketDetails })
              : getOverlaySelector(
                  data,
                  dateSelector,
                  renderQuantityOverlay,
                  renderDateSelector,
                  renderCounterAndAddToCart,
                  ticketDetails,
                  renderPriceList,
                  showPostPurchaseRecommendation,
                  bookingUrl,
                  postPurchaseRecommendationCTA,
                  isGAEnabledForCrossSell,
                  productMainCategoryWithTabName,
                )}
          </div>
          {info && (
            <TicketTooltip title={info.title} subTitle={info.subTitle} description={info.description} tabIndex="0" />
          )}
        </div>
        {/* {!isPJOnly && (
    <div className="checkbox-label" htmlFor={ticketDetails.name}>
      <input
        type="radio"
        id={coveoValue}
        name="ticket-selection"
        value={ticketDetails.title}
        checked={ticketSelectedState}
        onChange={(e) => handleOnClick(e, props.handleOnClick, coveoValue)}
      />
      <div
        className="checkbox"
        id={coveoValue}
        tabIndex={0}
        aria-label={data.ariaLabelCheckbox ? data.ariaLabelCheckbox : ariaLabelCheckbox}
        role="radio"
        aria-checked={ticketSelectedState}
        onKeyDown={(e) => handleCheckBoxKeyDownEvent(e)}
      ></div>
    </div>
  )} */}
        {isPJOnly && tncPopup && (
          <AnchorLink
            className="tnc-link"
            link={{ label: tncPopup.tncLabel }}
            onClick={() => openOverlay(tncPopup, isPJOnly)}
            onKeyDown={(e) => checkKeyDownHandler(e, tncPopup, isPJOnly)}
            tabIndex="0"
          />
        )}
      </>
    );
  };
  const renderQuantityOverlay = () => {
    const { data, ticket } = props;
    const multiTicketSelector = props.category.multiTicketSelector;
    const keyMap = multiTicketSelector && multiTicketSelector.stringMapping;
    const category = ticket.category;
    const isDrivingExp =
      keyMap &&
      (category.indexOf(keyMap.drivingExperience) !== -1 || category.indexOf(keyMap.passengerExperience) !== -1);
    const classToAppend = isDrivingExp
      ? 'drvng-exp addOns-Overlay'
      : classNames(
          'addOns-Overlay calendar-overlay-genral-admission',
          { 'v-multi-product-widget': isPJOnly },
          { 'v-dynamic-product-pricing': isDynamicPricingProd },
        );
    return (
      <div className="btn-primary">
        {!isPlpVariant && (
          <button
            type="button"
            onClick={() => {
              setIsOverlayOpen(true);
              if (checkParksTenants()) {
                GTMDataOnSelectItem();
              }
            }}
          >
            {' '}
            {data.pjSelectLabel}{' '}
          </button>
        )}
        {isOverlayOpen &&
          window.PubSub.publish('toggleOverlayState', {
            shouldOpen: true,
            customClass: classToAppend,
            dataToAppend: componentToAppend({ props, closeOverlay, isDrivingExp }),
          })}
      </div>
    );
  };

  try {
    const { category, data, dateSelector, selectedTicket, ticket, ticketState, tncPopup, visitorCounter } = props;
    const ticketDetails = category.ticketSelector ? category.ticketSelector.ticket : category;
    const info = ticketDetails.info;
    const ticketSelectedState = ticketState === 'default' && selectedTicket === coveoValue;
    const eventLocationName = ticket.eventLocationName;
    if (isPlpVariant) {
      return (
        <div className="v-overlay-selected">
          {data.isEventQuantityOverlay
            ? componentToAppend({ props, closeOverlay, ticketDetails })
            : getOverlaySelector(
                data,
                dateSelector,
                renderQuantityOverlay,
                renderDateSelector,
                renderCounterAndAddToCart,
                ticketDetails,
              )}
        </div>
      );
    }
    return (
      <div
        className={`c-fast-ticket-wrapper ${ticketSelectedState ? ' selected' : ''}`}
        onClick={(e) => handleOnClick(e, props.handleOnClick, coveoValue)}
        data-product={ticket.productId}
      >
        <div className="c-fast-ticket">
          {!isSwad && ticket.productImage && (
            <img className="product-image" src={ticket.productImage} alt={ticket.productImage} />
          )}
          {isSwad && ticketDetails.image && (
            <div className="product-image-wrapper">
              {ticketDetails.offerLabel && (
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'offer-label' }}
                  innerHtml={ticketDetails.offerLabel}
                />
              )}
              <Image image={ticketDetails.image} disableLazyLoad={true} />
            </div>
          )}
          {isSwad ? (
            <div className="ticket-content-wrapper">
              {renderTicketContent({
                ticket,
                ticketDetails,
                info,
                eventLocationName,
                category,
                data,
                dateSelector,
                tncPopup,
                visitorCounter,
                showPostPurchaseRecommendation: props.showPostPurchaseRecommendation,
                bookingUrl: props.bookingUrl,
                postPurchaseRecommendationCTA: props.postPurchaseRecommendationCTA,
                isGAEnabledForCrossSell: props.isGAEnabledForCrossSell,
                productMainCategoryWithTabName: props.productMainCategoryWithTabName,
              })}
            </div>
          ) : (
            renderTicketContent({
              ticket,
              ticketDetails,
              info,
              eventLocationName,
              category,
              data,
              dateSelector,
              tncPopup,
            })
          )}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'TileComponent');
  }
};

TileComponent.defaultProps = {
  ariaLabelCheckbox: 'Press enter to select ticket',
};

TileComponent.propTypes = {
  additionalProds: PropTypes.array,
  additionalRecommendedData: PropTypes.object,
  addToCartCallback: PropTypes.func,
  ariaLabelCheckbox: PropTypes.string,
  businessErrors: PropTypes.object,
  cartData: PropTypes.object,
  category: PropTypes.object,
  coveoMapList: PropTypes.object,
  data: PropTypes.object,
  dateSelector: PropTypes.object,
  getDiscountedProducts: PropTypes.func,
  handleOnClick: PropTypes.func,
  overlayErrorCallback: PropTypes.func,
  searchProductList: PropTypes.array,
  selectedTicket: PropTypes.string,
  services: PropTypes.object,
  ticket: PropTypes.object,
  ticketState: PropTypes.string,
  timeSlotSelector: PropTypes.object,
  variant: PropTypes.string,
  visitorCounter: PropTypes.object,
};

export default TileComponent;
