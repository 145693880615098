import React, { useEffect, useState } from 'react';
import ThirdPartyWidget from '../../presentation/third-party-widget';
import { logComponentRenderingError } from '../../../common/logger';
import UIConfig from '../../../common/UIConfig';
import { addThisHandler } from '../../../common/utility';

const SocialShare = (fields) => {
  const [propsToPass, setPropsToPass] = useState([
    {
      type: 'share',
    },
  ]);
  const [showShareButton, setShowShareButton] = useState(false);

  useEffect(() => {
    const events = ['scroll', 'mousemove', 'touchstart'];

    setPropsToPass([
      {
        type: 'share',
        ...fields,
      },
    ]);

    const initSocialShareButtonOnEvent = (event) => {
      initSocialShareButton();
      events.forEach((e) => event.currentTarget.removeEventListener(e, initSocialShareButtonOnEvent));
    };

    setShowShareButton(Boolean(fields.shareText));

    const initSocialShareButton = () => {
      getScript(fields.hashTag, fields.pubId, fields);
    };

    if (fields && fields.variant === UIConfig.socialShareVariation) {
      events.forEach((e) => document.addEventListener(e, initSocialShareButtonOnEvent));
    } else {
      window.PubSub.subscribe('loadAddWidget', (data, obj) => {
        if (obj.loadAddWidget) {
          getScript(fields.hashTag, fields.pubId, fields);
        }
      });
    }
  }, [fields]);

  try {
    if (showShareButton) {
      return (
        <div
          data-c-name="c-social-share-button"
          data-component-name="c-social-share-button"
          data-c-render="universal"
          className={`component c-social-share-button ${fields.variant || ''}`}
        >
          <ThirdPartyWidget data={propsToPass} wishlistData={null} />
        </div>
      );
    } else {
      return <></>;
    }
  } catch (err) {
    return logComponentRenderingError(err, 'SocialShare', fields.variant);
  }
};

const getScript = (hashTag, pubId, fields) => {
  const id = 'socialsharescript';
  const addThisId = 'addthisid';

  const scriptElement = document.querySelector(`#${id}`);
  const addThisScriptEl = document.querySelector(`#${addThisId}`);

  if (!scriptElement) {
    const script = document.createElement('script');
    script.innerHTML = `
      var addthis_config = addthis_config||{};
      addthis_config.data_track_addressbar = false;
      addthis_config.data_track_clickback = false;
      
      var addthis_share = addthis_share || {}
      addthis_share = {
        passthrough: {
          twitter: {
            hashtags: '${hashTag}'
          }
        }
      }`;
    script.id = id;
    script.setAttribute('data-testid', id);
    document.body.appendChild(script);
  }

  if (!addThisScriptEl) {
    const addThis = document.createElement('script');
    addThis.src = `//s7.addthis.com/js/300/addthis_widget.js#pubid=${pubId}`;
    addThis.id = addThisId;
    addThis.setAttribute('data-testid', addThisId);
    document.body.appendChild(addThis);
  }

  if (fields && fields.variant !== UIConfig.socialShareVariation) {
    const clearClickInterval = setInterval(() => {
      const addThisScriptEl = document.querySelector(`#${addThisId}`);
      if (addThisScriptEl) {
        window.PubSub.unsubscribe('loadAddWidget');
        document.getElementById('shareWidget').click();
        clearInterval(clearClickInterval);
      }
    }, 200);
  }
  addThisHandler();
};

export default SocialShare;
