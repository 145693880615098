import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DynamicContent, Spinner, SvgSprite } from '../../presentation/base';
import { decodeHtmlEntity, matchVariant, detectViewPort, checkTenant, getMainObject } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { logComponentRenderingError } from '../../../common/logger';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Kgfuicomponents from '../../presentation/Kgfuicomponents';

const HotelBookingWidgetGuestSelector = forwardRef((props, ref) => {
  try {
    const {
      data: { guestSettings },
      enableStackView,
      roomSettings,
    } = props;

    const { ageDropDown } = guestSettings.guestTypeChild;
    const defaultSelectedAge =
      (ageDropDown && ageDropDown.defaultSelectedAge && parseInt(ageDropDown.defaultSelectedAge)) || 0;
    const vBookingWidgetOnlyStartDate = props.data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate;
    const isRoomsEnabled = props.data.roomSettings.isRoomsEnable;
    const isYasIsland = props?.data?.brand === UIConfig.YAS;
    const isSwad = checkTenant(UIConfig.iamMapping.swad);
    const isYMC = checkTenant(UIConfig.iamMapping.ymc);
    const experienceWithKgfCarting = props.data?.packageCategories?.categories.find(
      (category) => category?.kgfCartingEnabled,
    );
    const isKgfEnabled = experienceWithKgfCarting?.value === sessionStorage?.getItem('selectedCategory');

    return (
      <div className="c-hotel-booking-widget-guest-selector">
        {!isSwad && (
          <div className={classNames('guest-selector-label', { error: props.guestLabelError })}>
            {decodeHtmlEntity(guestSettings.label)}
          </div>
        )}
        <div className="guest-selector-component" ref={ref}>
          <div
            className={
              vBookingWidgetOnlyStartDate && props.guestLabelError
                ? classNames(
                    `guest-selector-placeholder ${props.isGuestSelectorOpen ? 'opened' : ''}`,
                    props.guestLabelError && { error: props.guestLabelError },
                  )
                : classNames(`guest-selector-placeholder ${props.isGuestSelectorOpen ? 'opened' : ''}`, {
                    error: props.guestLabelError,
                  })
            }
            onClick={() => props.guestSelectorShowHide(props.isGuestSelectorOpen ? false : true)}
            onKeyDown={(event) => props.handleKeyDown(event)}
            tabIndex="0"
            data-testid="guest-selector"
          >
            <div className="guest-lable-wrapper">
              <DynamicContent attrs={{ className: 'room-label' }} tagName="div" innerHtml={props.guestSelectorLabel2} />
              {(isSwad || isYMC) && props.guestSelectorLabel2 && <span className="separator">-</span>}
              {vBookingWidgetOnlyStartDate ? (
                <DynamicContent
                  attrs={{ className: 'guest-label room-on' }}
                  tagName="div"
                  innerHtml={isRoomsEnabled === true || props.guestLabelError ? props.guestSelectorLabel1 : ''}
                />
              ) : (
                <DynamicContent
                  attrs={{ className: 'guest-label' }}
                  tagName="div"
                  innerHtml={props.guestSelectorLabel1}
                />
              )}
            </div>
          </div>

          {props.isGuestSelectorOpen && (
            <div className={classNames('room-container-wrapper', { 'full-width': enableStackView })}>
              <div className="room-container">
                <div className="room-inner-container">
                  {isYasIsland ? (
                    <Accordion allowZeroExpanded preExpanded={[0]}>
                      {props.guestData.map((room, roomNum) => {
                        let adultMaxCount, childMaxCount;
                        if (enableStackView && !guestSettings.hasGuestCombination) {
                          adultMaxCount = guestSettings.guestTypeAdult.maxValue;
                          childMaxCount = guestSettings.guestTypeChild.maxValue;
                        } else {
                          adultMaxCount = guestSettings.maxGuestInRoom - props.guestData[roomNum].child;
                          childMaxCount = guestSettings.maxGuestInRoom - props.guestData[roomNum].adult;
                        }
                        return (
                          <AccordionItem uuid={roomNum}>
                            {roomNum > 0 && (
                              <div className="delete-btn">
                                <span onClick={() => props.deleteRoom(roomNum)}>
                                  <button
                                    aria-label={`Delete ${guestSettings.roomLabel} ${roomNum + 1}`}
                                    data-testid="deleteRoom-button"
                                  >
                                    <SvgSprite id="b2c-delete-icon" />
                                  </button>
                                </span>
                              </div>
                            )}
                            <div className="room-wrapper heading-6" key={roomNum}>
                              <div className="member-count">
                                {(detectViewPort() === UIConfig.viewportTypes.mobile ||
                                  detectViewPort() === UIConfig.viewportTypes.tablet) && (
                                  <span>{guestSettings.mobileSelectLabel}: </span>
                                )}
                                {room.adult} {props.data.guestSettings.guestTypeAdult.label} / {room.child}{' '}
                                {vBookingWidgetOnlyStartDate && props.data.guestSettings.guestTypeChild.label}
                                <span className="room-number-wrapper">
                                  {props.data.guestSettings.guestTypeChild.label} - 1{' '}
                                  {props.data.guestSettings.roomLabel}
                                </span>
                              </div>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <div className="room-number">
                                    {guestSettings.roomLabel} {roomNum + 1}
                                  </div>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <div className="inner-container">
                                  <div
                                    className={classNames('spinner-container', {
                                      selected: props.guestData[roomNum].adult,
                                    })}
                                    data-testid="adult-selector"
                                  >
                                    <span className="guest-type">
                                      {guestSettings.guestTypeAdult.boxLabel.replace('{age}', props.adultAgeLimit)}
                                    </span>

                                    <Spinner
                                      quantity={props.guestData[roomNum].adult}
                                      isInputDisabled
                                      name={`adult_${roomNum}`}
                                      min={parseInt(guestSettings.guestTypeAdult.minValue) || 0}
                                      checkQuantity={props.handleSpinnerCallback}
                                      max={adultMaxCount}
                                      ariaLabelDecrease={
                                        guestSettings.ariaLabelDecrease ? guestSettings.ariaLabelDecrease : ''
                                      }
                                      ariaLabelIncrease={
                                        guestSettings.ariaLabelIncrease ? guestSettings.ariaLabelIncrease : ''
                                      }
                                      ticketCounterText={
                                        guestSettings.ticketCounterText ? guestSettings.ticketCounterText : ''
                                      }
                                      isHotelBookingWidgetRedVariant={props.isRedVariant}
                                      vBookingWidgetOnlyStartDate={vBookingWidgetOnlyStartDate}
                                    />
                                  </div>
                                  {props.childAgeLimit > 0 && (
                                    <div
                                      className={classNames('spinner-container', {
                                        selected: props.guestData[roomNum].child,
                                        'hide-children-container': matchVariant(
                                          props.data.variant,
                                          UIConfig.yasislandVariant.NOCHILD,
                                        ),
                                      })}
                                      data-testid="child-selector"
                                    >
                                      {props.childAgeLimit && guestSettings.guestTypeChild.boxLabel && (
                                        <div className="hotel-booking-widget-container-withkgftag">
                                          <span className="guest-type">
                                            {guestSettings.guestTypeChild.boxLabel.replace(
                                              '{age}',
                                              props.childAgeLimit,
                                            )}
                                          </span>
                                          <Kgfuicomponents
                                            nosOfChild={props?.guestData[roomNum]?.child}
                                            isSpinner={true}
                                            isKgfEnabled={isKgfEnabled}
                                            guestData={props?.guestData}
                                          />
                                        </div>
                                      )}

                                      <Spinner
                                        quantity={props.guestData[roomNum].child}
                                        isInputDisabled
                                        name={`child_${roomNum}`}
                                        min={parseInt(guestSettings.guestTypeChild.minValue) || 0}
                                        checkQuantity={props.handleSpinnerCallback}
                                        max={childMaxCount}
                                        ariaLabelDecrease={
                                          guestSettings.ariaLabelDecrease ? guestSettings.ariaLabelDecrease : ''
                                        }
                                        ariaLabelIncrease={
                                          guestSettings.ariaLabelIncrease ? guestSettings.ariaLabelIncrease : ''
                                        }
                                        ticketCounterText={
                                          guestSettings.ticketCounterText ? guestSettings.ticketCounterText : ''
                                        }
                                        isHotelBookingWidgetRedVariant={props.isRedVariant}
                                      />
                                    </div>
                                  )}
                                  {vBookingWidgetOnlyStartDate && isRoomsEnabled === false
                                    ? ''
                                    : enableStackView && (
                                        <div
                                          className={classNames('spinner-container', {
                                            selected: props.guestData[roomNum].room,
                                          })}
                                          data-testid="room-selector"
                                        >
                                          <span className="guest-type"> {roomSettings.label} </span>
                                          <Spinner
                                            quantity={parseInt(props.guestData[roomNum].room)}
                                            isInputDisabled
                                            name={`room_${roomNum}`}
                                            min={parseInt(roomSettings.minValue) || 0}
                                            checkQuantity={props.handleSpinnerCallback}
                                            max={parseInt(roomSettings.maxValue) || 0}
                                            ariaLabelDecrease={
                                              roomSettings.ariaLabelDecrease ? roomSettings.ariaLabelDecrease : ''
                                            }
                                            ariaLabelIncrease={
                                              roomSettings.ariaLabelIncrease ? roomSettings.ariaLabelIncrease : ''
                                            }
                                            ticketCounterText={
                                              roomSettings.ticketCounterText ? roomSettings.ticketCounterText : ''
                                            }
                                            isHotelBookingWidgetRedVariant={props.isRedVariant}
                                          />
                                        </div>
                                      )}
                                </div>
                                {enableStackView && props.guestData[roomNum].child > 0 && (
                                  <div className="child-details-title">{guestSettings.guestTypeChild.title}</div>
                                )}
                                {room.childAge && props.childAgeLimit > 0 && room.childAge.length > 0 && (
                                  <div className="child-details">
                                    {room.childAge &&
                                      room.childAge.map((item, childNum) => {
                                        const ageSelected =
                                          (enableStackView &&
                                            (props.guestData[roomNum].childAge[childNum] || defaultSelectedAge)) ||
                                          props.guestData[roomNum].childAge[childNum];

                                        // logic for KGF TEXT
                                        const roomData = props?.guestData[roomNum];
                                        const selectedMsgIndex = () => {
                                          if (
                                            roomData.child === 1 ||
                                            roomData.child === roomData.adult ||
                                            (roomData.child >= roomData.adult && childNum === 0)
                                          )
                                            return 1;

                                          return 2;
                                        };

                                        return (
                                          <div className="form-element select-box" key={childNum}>
                                            {isYasIsland && (
                                              <div className="hotel-booking-widget-container-withkgftag">
                                                <div className="child-room-label">
                                                  {guestSettings.guestTypeChild.childAgeLabel.replace(
                                                    '{child_number}',
                                                    childNum + 1,
                                                  )}
                                                </div>
                                                <Kgfuicomponents
                                                  nosOfChild={childNum + 1}
                                                  isKgfEnabled={isKgfEnabled}
                                                  messageIndex={selectedMsgIndex()}
                                                />
                                              </div>
                                            )}
                                            <div className="select-custom-box">
                                              <select
                                                className={classNames(
                                                  'form-select body-copy-4',
                                                  {
                                                    error:
                                                      props.guestLabelError &&
                                                      !props.guestData[roomNum].childAge[childNum],
                                                  },
                                                  { selected: !props.guestData[roomNum].childAge[childNum] },
                                                )}
                                                onChange={(e) => props.onChildAgeChange(e, roomNum, childNum)}
                                                onfocus={(e) => props.onChildFocus(e)}
                                                defaultValue={ageSelected}
                                                tabIndex="0"
                                                data-testid={`select-child-${childNum}`}
                                              >
                                                {props.data.variant.includes(props.isRedVariant) ||
                                                (vBookingWidgetOnlyStartDate && !isYasIsland) ? (
                                                  <option value="">
                                                    {guestSettings.guestTypeChild.childAgeLabel.replace(
                                                      '{child_number}',
                                                      childNum + 1,
                                                    )}{' '}
                                                    {guestSettings.guestTypeChild.childAgePlaceholder}
                                                  </option>
                                                ) : (
                                                  <option value="">
                                                    {guestSettings.guestTypeChild.childAgePlaceholder}
                                                  </option>
                                                )}
                                                {props.childAgeArray.map((opt) => {
                                                  return (
                                                    <option key={opt} value={opt}>
                                                      {opt}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                              <span className="select-arrow"> </span>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </AccordionItemPanel>
                            </div>
                          </AccordionItem>
                        );
                      })}
                    </Accordion>
                  ) : (
                    props.guestData.map((room, roomNum) => {
                      let adultMaxCount, childMaxCount;
                      if (enableStackView && !guestSettings.hasGuestCombination) {
                        adultMaxCount = guestSettings.guestTypeAdult.maxValue;
                        childMaxCount = guestSettings.guestTypeChild.maxValue;
                      } else {
                        adultMaxCount = guestSettings.maxGuestInRoom - props.guestData[roomNum].child;
                        childMaxCount = guestSettings.maxGuestInRoom - props.guestData[roomNum].adult;
                      }
                      return (
                        <div className="room-wrapper heading-6" key={roomNum}>
                          <div className="room-number">
                            {guestSettings.roomLabel} {roomNum + 1}
                          </div>
                          <div className="inner-container">
                            <div
                              className={classNames('spinner-container', {
                                selected: props.guestData[roomNum].adult,
                              })}
                              data-testid="adult-selector"
                            >
                              <span className="guest-type">{guestSettings.guestTypeAdult.labels}</span>
                              <Spinner
                                quantity={props.guestData[roomNum].adult}
                                isInputDisabled
                                name={`adult_${roomNum}`}
                                min={parseInt(guestSettings.guestTypeAdult.minValue) || 0}
                                checkQuantity={props.handleSpinnerCallback}
                                max={adultMaxCount}
                                ariaLabelDecrease={
                                  guestSettings.ariaLabelDecrease ? guestSettings.ariaLabelDecrease : ''
                                }
                                ariaLabelIncrease={
                                  guestSettings.ariaLabelIncrease ? guestSettings.ariaLabelIncrease : ''
                                }
                                ticketCounterText={
                                  guestSettings.ticketCounterText ? guestSettings.ticketCounterText : ''
                                }
                                isHotelBookingWidgetRedVariant={props.isRedVariant}
                                vBookingWidgetOnlyStartDate={vBookingWidgetOnlyStartDate}
                              />
                            </div>
                            <div
                              className={classNames('spinner-container', {
                                selected: props.guestData[roomNum].child,
                                'hide-children-container': matchVariant(
                                  props.data.variant,
                                  UIConfig.yasislandVariant.NOCHILD,
                                ),
                              })}
                              data-testid="child-selector"
                            >
                              <span className="guest-type"> {guestSettings.guestTypeChild.label} </span>
                              <Spinner
                                quantity={props.guestData[roomNum].child}
                                isInputDisabled
                                name={`child_${roomNum}`}
                                min={parseInt(guestSettings.guestTypeChild.minValue) || 0}
                                checkQuantity={props.handleSpinnerCallback}
                                max={childMaxCount}
                                ariaLabelDecrease={
                                  guestSettings.ariaLabelDecrease ? guestSettings.ariaLabelDecrease : ''
                                }
                                ariaLabelIncrease={
                                  guestSettings.ariaLabelIncrease ? guestSettings.ariaLabelIncrease : ''
                                }
                                ticketCounterText={
                                  guestSettings.ticketCounterText ? guestSettings.ticketCounterText : ''
                                }
                                isHotelBookingWidgetRedVariant={props.isRedVariant}
                              />
                            </div>
                            {vBookingWidgetOnlyStartDate && isRoomsEnabled === false
                              ? ''
                              : enableStackView && (
                                  <div
                                    className={classNames('spinner-container', {
                                      selected: props.guestData[roomNum].room,
                                    })}
                                    data-testid="room-selector"
                                  >
                                    <span className="guest-type"> {roomSettings.label} </span>
                                    <Spinner
                                      quantity={parseInt(props.guestData[roomNum].room)}
                                      isInputDisabled
                                      name={`room_${roomNum}`}
                                      min={parseInt(roomSettings.minValue) || 0}
                                      checkQuantity={props.handleSpinnerCallback}
                                      max={parseInt(roomSettings.maxValue) || 0}
                                      ariaLabelDecrease={
                                        roomSettings.ariaLabelDecrease ? roomSettings.ariaLabelDecrease : ''
                                      }
                                      ariaLabelIncrease={
                                        roomSettings.ariaLabelIncrease ? roomSettings.ariaLabelIncrease : ''
                                      }
                                      ticketCounterText={
                                        roomSettings.ticketCounterText ? roomSettings.ticketCounterText : ''
                                      }
                                      isHotelBookingWidgetRedVariant={props.isRedVariant}
                                    />
                                  </div>
                                )}
                            {roomNum > 0 && (
                              <div className="delete-btn">
                                <span onClick={() => props.deleteRoom(roomNum)}>
                                  <button
                                    aria-label={`Delete ${guestSettings.roomLabel} ${roomNum + 1}`}
                                    data-testid="deleteRoom-button"
                                  >
                                    <SvgSprite id="b2c-delete-icon" />
                                  </button>
                                </span>
                              </div>
                            )}
                          </div>
                          {enableStackView && props.guestData[roomNum].child > 0 && (
                            <div className="child-details-title">{guestSettings.guestTypeChild.title}</div>
                          )}
                          {room.childAge && room.childAge.length > 0 && (
                            <div className="child-details">
                              {room.childAge &&
                                room.childAge.map((room, childNum) => {
                                  const ageSelected =
                                    (enableStackView &&
                                      (props.guestData[roomNum].childAge[childNum] || defaultSelectedAge)) ||
                                    props.guestData[roomNum].childAge[childNum];
                                  return (
                                    <div className="form-element select-box" key={childNum}>
                                      {!(
                                        props.data.variant.includes(props.isRedVariant) || vBookingWidgetOnlyStartDate
                                      ) && (
                                        <div className="child-room-label">
                                          {guestSettings.guestTypeChild.childAgeLabel.replace(
                                            '{child_number}',
                                            childNum + 1,
                                          )}
                                        </div>
                                      )}
                                      <select
                                        className={classNames(
                                          'form-select body-copy-4',
                                          {
                                            error:
                                              props.guestLabelError && !props.guestData[roomNum].childAge[childNum],
                                          },
                                          { selected: !props.guestData[roomNum].childAge[childNum] },
                                        )}
                                        onChange={(e) => props.onChildAgeChange(e, roomNum, childNum)}
                                        onfocus={(e) => props.onChildFocus(e)}
                                        defaultValue={ageSelected}
                                        tabIndex="0"
                                        data-testid={`select-child-${childNum}`}
                                      >
                                        {props.data.variant.includes(props.isRedVariant) ||
                                        vBookingWidgetOnlyStartDate ? (
                                          <option value="">
                                            {guestSettings.guestTypeChild.childAgeLabel.replace(
                                              '{child_number}',
                                              childNum + 1,
                                            )}{' '}
                                            {guestSettings.guestTypeChild.childAgePlaceholder}
                                          </option>
                                        ) : (
                                          <option value="">{guestSettings.guestTypeChild.childAgePlaceholder}</option>
                                        )}
                                        {props.childAgeArray.map((opt) => {
                                          return (
                                            <option key={opt} value={opt}>
                                              {opt}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <span className="select-arrow"> </span>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      );
                    })
                  )}
                  {!enableStackView && guestSettings.maxRoomNum > props.guestData.length && (
                    <div className="addroom-btn-wrapper">
                      <button className="add-btn" onClick={(e) => props.addRoom(e)} data-testid="addRoom-button">
                        <span className="add-icon">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            id="plus-circle-solid"
                            data-prefix="fas"
                            data-icon="plus-circle"
                            className="svg-inline--fa fa-plus-circle fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
                            ></path>
                          </svg>
                        </span>
                        {guestSettings.addRoomLabel}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HotelBookingWidgetGuestSelector', props.data.variant);
  }
});

export default HotelBookingWidgetGuestSelector;

HotelBookingWidgetGuestSelector.propTypes = {
  data: PropTypes.object,
  guestSelectorShowHide: PropTypes.func,
  childAgeArray: PropTypes.array,
  handleSpinnerCallback: PropTypes.func,
  onChildAgeChange: PropTypes.func,
  deleteRoom: PropTypes.func,
  addRoom: PropTypes.func,
  guestLabelError: PropTypes.bool,
  guestSelectorLabel2: PropTypes.string,
  guestSelectorLabel1: PropTypes.string,
  isGuestSelectorOpen: PropTypes.bool,
  guestData: PropTypes.array,
  roomSettings: PropTypes.object,
  handleKeyDown: PropTypes.func,
  enableStackView: PropTypes.bool,
};
