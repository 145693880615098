/*
 * captcha-component.js
 * This file contains code for Captcha, it renders Captcha image with input field and refresh icon

 * @licensor  Miral
 */
import React from 'react';
import PropTypes from 'prop-types';
import { canUseDOM } from '../../../../common/utility';
import Script from 'react-load-script';
import { logComponentRenderingError } from '../../../../common/logger';
import Input from '../input';

import './captcha.scss';

/**
 * Captcha is a component which will be rendered by JSS Form component, it expects its props from Form component
 */

const Captcha = (props) => {
  const { botnetSessionId, id, url, altTextCaptcha, altTextHelpIcon, altTextRefreshIcon } = props.field.model;
  const valueFieldName = props.field.valueField.name;
  const isWindowExist = typeof window !== 'undefined';

  const onHelpClick = (e) => {
    e.preventDefault();
    if (isWindowExist) {
      window[id].OnHelpLinkClick();
      return window[id].FollowHelpLink;
    }
  };

  const refreshCaptcha = (e) => {
    e.preventDefault();
    if (canUseDOM()) {
      window[id].ReloadImage();
      return false;
    }
  };

  const playSound = (e) => {
    e.preventDefault();
    if (canUseDOM()) {
      window[id].PlaySound();
      return false;
    }
  };
  const initBotDetect = (
    captchaId,
    instanceId,
    inputId,
    autoUppercaseInput,
    autoFocusInput,
    autoClearInput,
    autoReloadExpiredImage,
    autoReloadPeriod,
    autoReloadTimeout,
    soundStartDelay,
    limitSoundRegeneration,
  ) => {
    let inputIdString = null;
    if (inputId) {
      inputIdString = "'" + inputId + "'";
    }

    if (document.getElementById(captchaId + '_CaptchaImage') && window.BotDetect) {
      let BotDetect = window.BotDetect;
      window[captchaId] = new BotDetect(
        captchaId,
        instanceId,
        inputIdString,
        autoUppercaseInput,
        autoFocusInput,
        autoClearInput,
        autoReloadExpiredImage,
        autoReloadPeriod,
        autoReloadTimeout,
        soundStartDelay,
        limitSoundRegeneration,
      );
      window[captchaId].PostInit();
    }
  };

  const handleScriptLoad = () => {
    if (isWindowExist && window.BotDetect) {
      initBotDetect(id, botnetSessionId, valueFieldName, true, true, true, true, 1200, 7200, 0, false);
    }
  };
  try {
    return (
      <div className="component c-captcha" data-captchaId={botnetSessionId} data-id={id}>
        <div className="c-captcha--botdetect" id={`${id}_CaptchaDiv`}>
          <div className="c-captcha--botdetect-image" id={`${id}_CaptchaImageDiv`}>
            <a href="//captcha.org/captcha.html?asp.net" onClick={onHelpClick} tabIndex="-1">
              <img
                className="c-captcha--botdetect-image"
                id={`${id}_CaptchaImage`}
                src={`/BotDetectCaptcha.ashx?get=image&c=${id}&t=${botnetSessionId}`}
                alt={altTextCaptcha || ''}
              />
            </a>
          </div>
          <div className="c-captcha--botdetect-refresh" id={`${id}_CaptchaIconsDiv`}>
            <a className="c-captcha--botdetect-reload" id={`${id}_ReloadLink`} href="/" onClick={refreshCaptcha}>
              <img
                className="c-captcha--botdetect-reload"
                id={`${id}_ReloadIcon`}
                src="/BotDetectCaptcha.ashx?get=reload-icon"
                alt={altTextRefreshIcon || ''}
              />
            </a>

            <a
              className="LBD_SoundLink"
              id={`${id}_SoundLink`}
              href={`/BotDetectCaptcha.ashx?get=sound&c=${id}&t=${botnetSessionId}`}
              onClick={playSound}
            >
              <img
                className="LBD_SoundIcon"
                id={`${id}_SoundIcon`}
                src="/BotDetectCaptcha.ashx?get=sound-icon"
                alt={altTextHelpIcon || ''}
              />
            </a>

            <div className="LBD_Placeholder" id={`${id}_AudioPlaceholder`}></div>
          </div>
          <input type="hidden" name={`BDC_VCID_${id}`} id={`BDC_VCID_ ${id}`} value={botnetSessionId} />
          <input type="hidden" name={`BDC_BackWorkaround_${id}`} id={`BDC_BackWorkaround_${id}`} value="0" />
        </div>
        <Input {...props} />
        {isWindowExist ? <Script url={url} onLoad={handleScriptLoad} /> : ''}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Captcha');
  }
};

Captcha.propTypes = {
  data: PropTypes.shape({
    captcha: PropTypes.object.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }),
};

export default Captcha;
