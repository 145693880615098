/**
 * This hook handles the force update feature of React in case set state cannot be used to update the component
 */

import { useState, useCallback } from 'react';

export default function useForceUpdate() {
  const [, setTick] = useState(0);
  return useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
}
