import { validateField } from '../../container/form/validator';
import { resolvePath, deepCloneObject } from '../../../common/utility';
import {
  checkFieldsToExclude,
  getFieldErrorMessage,
  createConfirmEmailField,
  getTypeOfField,
} from '../../../common/forms-utility';
import { validateConditions, showOrHideInput } from './custom-conditions';
import moment from 'moment';
import UIConfig from '../../../common/UIConfig';

let firstInputErrorField = null;
const {
  customSections: { ageValidator },
  fields: { calendarId, emailConfirmationId },
} = UIConfig.jssForms;

const getErrorMessage = (msg, name) => {
  return msg.replace('{0}', name);
};

const getValidationObj = (field) => {
  let validationObj = [];
  resolvePath(field, 'validationDataModels', []).forEach((item) => {
    let rule = {};
    if (item.parameters) {
      const parseValue = JSON.parse(item.parameters);
      rule.regex = parseValue.regex;
      rule.error = getErrorMessage(item.message, field.name);
      validationObj.push(rule);
    }
  });
  return validationObj;
};

const getConfirmationMessage = (fieldModel) => {
  const { emailConfirmation } = UIConfig.jssForms.errorConstant;
  const fieldTypeId = resolvePath(fieldModel, 'fieldTypeItemId', '');
  let msg = '';
  if (fieldTypeId === emailConfirmationId) {
    msg = getFieldErrorMessage(fieldModel, emailConfirmation);
  }
  return msg;
};

export const rulesMapping = (field, fieldValueName) => {
  const fieldModel = resolvePath(field, 'model', {});
  const { required } = UIConfig.jssForms.errorConstant;
  return {
    name: fieldValueName,
    rules: {
      isRequired: fieldModel.required,
      message: getFieldErrorMessage(fieldModel, required),
      minDate: fieldModel.minDate,
      minMessage: fieldModel.isMinMessage,
      maxDate: fieldModel.maxDate,
      maxMessage: fieldModel.isMaxMessage,
      type: field.type || '',
      isConfirm: fieldModel.isConfirm ? fieldModel.isConfirm : false,
      cMessage: fieldModel.isConfirm ? getConfirmationMessage(fieldModel) : null,
      fieldToConfirm: fieldModel.fieldToConfirm ? fieldModel.fieldToConfirm : null,
      validation: getValidationObj(fieldModel),
    },
  };
};

export const validateJSSField = (field, value, matchEmailValue) => {
  const fieldValueName = resolvePath(field, 'valueField.name', '');
  const rules = rulesMapping(field, fieldValueName);
  if (typeof value === 'string') {
    value = value.trim();
  }
  return validateField([rules], value, fieldValueName, { [fieldValueName]: value, ...matchEmailValue });
};

const handleEmailConfirmation = (field, formData, valueFieldName, value) => {
  let confirmEmailField = deepCloneObject(field);
  let error = {};
  confirmEmailField = createConfirmEmailField(confirmEmailField, valueFieldName);
  const type = getTypeOfField(confirmEmailField);
  const valueEmailFieldName = resolvePath(confirmEmailField, 'valueField.name', '');
  const emailFormData = formData.data.find((item) => item.key === valueEmailFieldName) || {};
  const emailValue = resolvePath(emailFormData, 'value', '');
  const errorEmailObj = validateJSSField({ ...confirmEmailField, type }, emailValue, { [valueFieldName]: value });
  const errorMessage = resolvePath(errorEmailObj[valueEmailFieldName], 'message', '');
  if (errorMessage) {
    error[valueEmailFieldName] = errorMessage;
    if (!firstInputErrorField) {
      firstInputErrorField = document.querySelector('form').elements[valueEmailFieldName];
    }
  }
  return error;
};

const validateFieldError = (field, formData) => {
  let error = {};
  const valueFieldName = resolvePath(field, 'valueField.name', '');
  const type = getTypeOfField(field);
  if (valueFieldName && !checkFieldsToExclude(field)) {
    const formInputObj = formData.data.find((item) => item.key === valueFieldName) || {};
    const value = resolvePath(formInputObj, 'value', '');
    const errorObj = validateJSSField({ ...field, type }, value);
    const errorMessage = resolvePath(errorObj[valueFieldName], 'message', '');
    if (errorMessage) {
      error[valueFieldName] = errorMessage;
      if (!firstInputErrorField) {
        firstInputErrorField = document.querySelector('form').elements[valueFieldName];
      }
    }
    const typeId = resolvePath(field, 'model.fieldTypeItemId', '');
    if (typeId === emailConfirmationId) {
      const emailConfirmMessage = handleEmailConfirmation(field, formData, valueFieldName, value);
      error = { ...error, ...emailConfirmMessage };
    }
  }
  return error;
};

const handleAgeValidatorValidation = (items, formData) => {
  const calField = items.fields.find((itm) => itm.model.fieldTypeItemId === calendarId);
  const valueFieldName = resolvePath(calField, 'valueField.name', '');
  const formInputObj = formData.data.find((item) => item.key === valueFieldName) || {};
  const errorData = {
    error: {},
    reValidate: true,
  };
  if (calField && formInputObj && formInputObj.value) {
    const selectedDate = moment(formInputObj.value).format(UIConfig.b2c.profile.dateFormat);
    const ageDiff = moment().diff(selectedDate || moment(), 'years');
    const computedValue = validateConditions({
      value: ageDiff,
      conditionSettings: calField.model.conditionSettings,
    });
    const checkForError = showOrHideInput(calField.model.conditionSettings);
    if (!(computedValue && checkForError)) {
      errorData.reValidate = false;
      errorData.error = validateFieldError(calField, formData);
    }
  }
  return errorData;
};

//set focus on the first input field with error
export const focusFirstJSSInputErrorField = () => {
  if (firstInputErrorField) {
    if (firstInputErrorField.length) {
      firstInputErrorField[0].focus();
    } else {
      firstInputErrorField.focus();
    }
  }
};

export const validateJSSForm = (props) => {
  firstInputErrorField = null;
  const { formData, formFields } = props;
  let errorObj = {};
  formFields.fields.forEach((field) => {
    let sectionErrors = {};
    if (field.hasOwnProperty('fields')) {
      field.fields.forEach((item) => {
        const fieldName = resolvePath(item, 'model.name', '');
        const fieldArr = fieldName.split('-');
        if (item.hasOwnProperty('fields')) {
          let checkError = true;
          if (fieldArr && fieldArr[0] === ageValidator) {
            const errorData = handleAgeValidatorValidation(item, formData);
            if (!errorData.reValidate) {
              checkError = false;
              sectionErrors[item.model.name] = {
                ...sectionErrors[item.model.name],
                ...errorData.error,
              };
            }
          }
          if (checkError) {
            item.fields.forEach((itm) => {
              sectionErrors[item.model.name] = {
                ...sectionErrors[item.model.name],
                ...validateFieldError(itm, formData),
              };
            });
          }
        } else {
          sectionErrors = { ...sectionErrors, ...validateFieldError(item, formData) };
        }
      });
      errorObj[field.model.name] = { ...errorObj, ...sectionErrors };
    } else {
      errorObj = { ...errorObj, ...validateFieldError(field, formData) };
    }
  });
  return errorObj;
};
