/*
 * SingleSelectCheckboxFilter.js
 * This file contains code for Single Select Filter of Radio Button Type
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DynamicContent } from '..';
import filter from './filter-composition';
import { parseQueryString } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * SingleSelectCheckboxFilter Class ( which extends the React.Component) creates Radio button based fitler,
 * and updates the value of filter on change
 */

class SingleSelectCheckboxFilter extends React.Component {
  constructor(props) {
    super(props);
    this.defaultValue = this.getDefaultValue();
    this.state = {
      selectValue: this.defaultValue,
    };
  }

  /**
   * getDefaultValue  Used to get a default value for select element
   * @return default value of ordertype
   */
  getDefaultValue() {
    let defaultValue;
    const list = [...this.props.filterList];

    for (let i = 0; i < list.length; i++) {
      defaultValue = list[i].selected ? list[i].id : '';
      if (defaultValue) {
        break;
      }
    }

    return defaultValue;
  }
  /**
   * onChange  Used to update the value of filter of Checkbox based filter
   * @param    {[value]} accepts the value of the target radio element.
   * @return   {[Void]} function does not return anything.
   */

  onChange = ({ target: { value } }) => {
    this.props.updateFilter(value);
    this.setState({
      selectValue: value,
    });
  };

  componentDidMount() {
    this.orderType = parseQueryString('status');
    this.setState({
      selectValue: this.orderType ? this.orderType : this.defaultValue,
    });
  }

  render() {
    try {
      return (
        <div className={this.props.className}>
          {this.props.filterList.label ? (
            <DynamicContent
              tagName="p"
              attrs={{
                className: 'filter--label',
              }}
              innerHtml={this.props.filterList.label}
            />
          ) : (
            ''
          )}
          <div className="filter--input-field">
            <select onChange={this.onChange} value={this.state.selectValue}>
              {this.props.filterList.map((options) => (
                <option value={options.id} id={options.id} key={options.id}>
                  {options.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'SingleSelectCheckboxFilter');
    }
  }
}

SingleSelectCheckboxFilter.propTypes = {
  props: PropTypes.shape({
    filterList: PropTypes.shape({
      orderType: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
  }),
};

export default filter(SingleSelectCheckboxFilter);
