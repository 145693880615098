/*
 * text-area-components.js
 * This file contains code for text area component which will render textarea based on schema.
 * @licensor  Miral
 */

import React, { useState, useEffect } from 'react';
import { logComponentRenderingError } from '../../../../common/logger';
import LabelComponent from '../label-component';
import FieldErrorComponent from '../field-error';
import { validateJSSField } from '../rules-validator';
import classNames from 'classnames';
import { resolvePath } from '../../../../common/utility';

const TextArea = (props) => {
  const { tracker, field, errors } = props,
    valueFieldName = field.valueField.name,
    valueFieldId = field.valueField.id,
    initialValue = resolvePath(field, 'model.value', '');

  const [isFocus, setFocus] = useState(false);
  const [fieldValue, setFieldValue] = useState(initialValue);
  const [errorMsg, setErrorMsg] = useState(errors);

  useEffect(() => {
    props.errors && setErrorMsg(props.errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  const handleChange = (inputValue) => {
    let valid = true,
      errorMessages = [];
    const errorMessagesObj = validateJSSField(field, inputValue);
    const message = errorMessagesObj[valueFieldName];
    if (message && message.message) {
      valid = false;
      errorMessages.push(message.message);
    }
    setErrorMsg(errorMessages);
    props.onChange(valueFieldName, inputValue, valid, errorMessages);
    tracker.onBlurField(field, inputValue, errorMessages);
  };

  const onChange = (e) => {
    setFieldValue(e.target.value);
  };

  const onFocus = (e) => {
    tracker.onFocusField(field, e.target.value);
    setFocus(true);
  };

  const onBlur = (e) => {
    const value = e.target.value;
    handleChange(value);
    setFieldValue(value);
    setFocus(false);
  };
  try {
    const isErrorExist = errorMsg && errorMsg.length;
    const customWrapperClasses = classNames('form-element', { active: isFocus || fieldValue });
    const textAreaClasses = classNames('form-text-area body-copy-3', { error: isErrorExist });
    return (
      <div aria-live="polite" className={`${customWrapperClasses} ${field.model.cssClass}`}>
        <LabelComponent field={field} />
        <textarea
          className={textAreaClasses}
          name={valueFieldName}
          rows={field.model.rows}
          value={fieldValue}
          aria-required={field.model.required}
          id={valueFieldId}
          maxLength={field.model.maxLength}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={field.model.title ? '' : field.model.placeholderText}
        />
        <FieldErrorComponent errors={errorMsg} valueFieldName={valueFieldName} />
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'TextArea');
  }
};

export default TextArea;
