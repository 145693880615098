/*
 * Apple Pay Component
 * This file contains code for Apple Pay Payment Component
 * @Author Miral
 * @licensor  Miral
 */
import classNames from 'classnames';
import React from 'react';

import { PaymentAnalytics } from '../../../../common/analytics-events';
import { Logging } from '../../../../common/logger';
import { AnnualPassData, ApplePayService } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  getLoggedInUser,
  removeGuestUser,
  setYasId,
  checkAnonymousCart,
  checkInlineSignup,
  getAnonymousCart,
  isLoggedInUser,
  getMainObject,
  resolvePath,
  getAPIStateParam,
  getRecipientDetails,
  checkShippingDetails,
  setLocalStorage,
  isYasArenaJourney,
  getSessionStorage,
  generateGUID,
  getMembershipDetails,
  addLoaderOverlay,
  removeLoaderOverlay,
  removeLoader,
  setSessionStorage,
  checkParksTenants,
  getLocalStorageByKey,
  isTicketUpgradePayment,
  backToUpgradePage,
  isANPUpgradation,
} from '../../../../common/utility';
import { ApplePayComponentService } from './applepay-component-service';
import { OrderService } from '../../../../common/services';
import GTMData from '../../b2c-purchase-journey/gtm-data';
import {
  scrollToServerError,
  isThereExtraErrors,
  checkYAEventId,
  doublePaymentOverlay,
  checkAnnualPassError,
  checkAnnualUpgradeError,
} from '../utils';
import { checkTenant } from '../../../../common/utility/tenantsUtils';
import { setReconcilRequestAction } from '../../../../utils/paymentUtils';
import DeviceFingerPrint from '../../../../jss/scripts/device-finger-print';
class _ApplePayComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEnabled: props.isEnabled,
      inPaymentMode: true,
      inSetupMode: false,
      isCustomerIP: '',
    };

    this._isErrorOccured = props.isErrorOccured;

    this._componentSvc = new ApplePayComponentService({
      ...props.propsData,
      is2StepPaymentYasArena: props.is2StepPaymentYasArena,
    });

    this._sessionRequest = this._componentSvc.getSessionRequestObject();

    this._supportedVersion = this._componentSvc.getSupportedVersion();

    this._merchantIdentifier = props.propsData.paymentOptions.applepayconfig.merchantIdentifier;
    this._displayName = props.propsData.paymentOptions.applepayconfig.displayName;
    this._enableDeviceFingerprint = props.propsData.paymentOptions.applepayconfig.enableDeviceFingerprint;

    this._applePay = null;
    this._orderDetails = null;

    this._services = {
      validation: props.propsData.services.applePayMerchantValidation.url,
      purchase: props.propsData.services.applePayMakePurchase.url,
      reconcile: props.propsData.services.reconcile.url,
    };
  }

  checkFormValidation = () => {
    const parentForm = document.querySelector('.guest-checkout-form-wrapper');
    const areaRequired = Array.from(parentForm.querySelectorAll('[aria-required=true]'));
    const requiredFields = Array.from(parentForm.querySelectorAll('.required--field'));

    const allRequired = [...new Set([...areaRequired, ...requiredFields])].filter(
      (field) => !field.value || field.value === 'false',
    );
    return (
      allRequired.length ||
      document.querySelectorAll('.yasid-email-error')?.length > 0 ||
      document.getElementsByClassName('form-error--field')?.length ||
      parentForm?.getElementsByClassName('error-msg')?.length
    );
  };
  /**
   *  Validate, if payment process can be started.
   *
   */
  canProceedToPay = (e) => {
    let proceedToPay = true;
    const tenantID = getLoggedInUser().tenantID;
    const cartData = checkInlineSignup() && checkAnonymousCart() ? getAnonymousCart().cart : this.props.cartData;

    //if tnc checkbox is not clicked
    if (!this.props.isEnabled) {
      this.props.showTnCError(true);
      proceedToPay = false;
    }

    if (checkTenant(UIConfig.YIB2C) && !isLoggedInUser()) {
      if (this.checkFormValidation()) {
        window.PubSub.publish('initiatePaymentSubmitValidation');
        scrollToServerError(e, 'error-msg');
        proceedToPay = false;
      }
    }

    // don't proceed with payment, if user Guest Details are not filled
    if (!isLoggedInUser()) {
      if (!checkTenant(UIConfig.YIB2C)) {
        if (proceedToPay) {
          document.getElementById('submit-btn').click();
        } else {
          window.PubSub.publish('initiatePaymentSubmitValidation');
          scrollToServerError(e, 'error-msg');
        }
      }
      if (this.props.guestUserHasErrors()) {
        proceedToPay = false;
      }
    }
    if (tenantID.toLowerCase() === UIConfig.ymcB2CTenant) {
      const isShippingRequired = checkShippingDetails(cartData);
      if (isShippingRequired) {
        proceedToPay = false;
      }
    }
    return proceedToPay;
  };

  callTravelboxApi = async (_guid) => {
    const configUrl = this.props.propsData?.services?.TravelBoxPayfortConfig?.url.replace(
      '{cartId}',
      getSessionStorage('cartId'),
    );
    try {
      const response = await fetch(configUrl);
      const _data = await response.json();
      this._orderDetails = {
        ..._data,
        orderID: _data.merchant_reference + _guid,
        merchant_extra: _data.CartId,
        merchant_extra1: _data.merchant_extra1,
      };
      this._applePay.begin();
      Logging(response, 'Apple_Pay_Component', true, `Apple Pay - Step 2 - Order Id Created`);

      const serverErrorElement = document.getElementsByClassName('yasid-email-error server-error');
      if (serverErrorElement && serverErrorElement.length > 0) {
        return false;
      }
    } catch (error) {
      this._applePay.completePayment(window.ApplePaySession.STATUS_FAILURE);
      this.createOrderErrorHandler(error);
      removeLoaderOverlay();
    }
  };

  /**
   *  Handle Pay Button Click
   *  First Create Order and then begin Payment Process.
   */
  handlePayClick = async (e) => {
    const cartData = checkInlineSignup() && checkAnonymousCart() ? getAnonymousCart().cart : this.props.cartData;
    let hasAnnualPassError = false;
    if (isTicketUpgradePayment() && isANPUpgradation()) {
      if (checkAnnualUpgradeError()) {
        const updatedAnnualPassError = sessionStorage.getItem('annualPassFormSuccess') === 'false' ? true : false;
        if (!updatedAnnualPassError) {
          hasAnnualPassError = false;
        } else {
          hasAnnualPassError = true;
        }
      }
    } else {
      if (checkAnnualPassError(this.props.cartData, this.handlePayClick)) {
        hasAnnualPassError = true;
      }
    }
    if (!this.canProceedToPay(e) || this.props.isPaymentMethodBlocked || hasAnnualPassError) {
      e.preventDefault();
      e.stopPropagation();
      scrollToServerError(e);
      return false;
    } else {
      if (isThereExtraErrors(e)) return false;

      const isVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
      if (isVoucher) {
        window.PubSub.publish('formSubmitVoucher', true);
      }
      if (checkParksTenants()) {
        GTMData.push('addPaymentInfo', {
          cartData: cartData,
          paymentType: UIConfig.paymentOption.applePay,
        });
      }
      if (isLoggedInUser() && cartData) {
        const recipientDetails = getRecipientDetails();
        cartData.personalisedGiftObj = recipientDetails;
      }

      if (checkTenant(UIConfig.YIB2C)) {
        addLoaderOverlay();
        !isLoggedInUser() && document.getElementById('submit-btn').click();

        const appleCartLabel = JSON.parse(localStorage.getItem(UIConfig.events.PUBLISH_YI_CART_LABELS));
        const cartSummaryObj = JSON.parse(getSessionStorage('cartSummaryForConfirmation'));
        const applePaySessionRequest = () => {
          const getLineItem = (label = '', amount = 0, itemType = 'final') => ({
            label,
            type: itemType,
            amount,
          });
          const lineItems = [];
          if (cartSummaryObj?.Cart?.Totals?.BasePackageAmount !== 0) {
            lineItems.push(
              getLineItem(
                appleCartLabel.basePackageAmountTitle,
                cartSummaryObj.Cart.Totals.BasePackageAmount.toFixed(2),
              ),
            );
          }

          if (cartSummaryObj?.Cart?.Totals?.AddOnsAmount !== 0) {
            lineItems.push(
              getLineItem(appleCartLabel.addOnsAmountTitle, cartSummaryObj.Cart.Totals.AddOnsAmount.toFixed(2)),
            );
          }

          if (cartSummaryObj?.Cart?.Totals?.TaxAmount !== 0) {
            lineItems.push(getLineItem(appleCartLabel.taxAmountTitle, cartSummaryObj.Cart.Totals.TaxAmount.toFixed(2)));
          }

          if (cartSummaryObj?.Cart?.Totals?.GiftVoucherAmount !== 0) {
            lineItems.push(
              getLineItem(
                appleCartLabel.giftVoucherAmountTitle,
                cartSummaryObj.Cart.Totals.GiftVoucherAmount.toFixed(2),
              ),
            );
          }

          if (
            cartSummaryObj?.Cart?.Totals?.SubTotalAmount !== 0 &&
            cartSummaryObj?.Cart?.Totals?.SubTotalAmount !== cartSummaryObj?.Cart?.Totals?.TotalAmount
          ) {
            lineItems.push(
              getLineItem(appleCartLabel.subTotalAmountTitle, cartSummaryObj.Cart.Totals.SubTotalAmount.toFixed(2)),
            );
          }

          if (cartSummaryObj?.Cart?.Totals?.PromoCodeAmount !== 0) {
            lineItems.push(
              getLineItem(appleCartLabel.promoCodeAmountTitle, cartSummaryObj.Cart.Totals.PromoCodeAmount.toFixed(2)),
            );
          }

          const total = getLineItem(
            appleCartLabel?.totalAmountTitle,
            cartSummaryObj?.Cart?.Totals?.TotalAmount?.toFixed(2),
          );

          return { total, lineItems };
        };

        this._sessionRequestYi = applePaySessionRequest();
      }
      this._sessionRequest = {
        ...this._sessionRequest,
        total: this.props?.paymentData?.total,
        lineItems: this.props?.paymentData?.lineItems,
        ...(checkTenant(UIConfig.YIB2C) && { ...this._sessionRequestYi }),
      };

      this._applePay = new window.ApplePaySession(this._supportedVersion, this._sessionRequest);

      this._applePay.onvalidatemerchant = this.onValidateMerchant;

      this._applePay.onpaymentauthorized = this.onPaymentAuthorised;

      this._applePay.oncancel = this.onPaymentCancelled;
      GTMData.getTenantId() === UIConfig.ymcB2CTenant &&
        GTMData.push('checkout', {
          step: UIConfig.gtmStepFive,
          products: cartData.items,
          subTotal: cartData.grossPrice,
        });

      if (checkTenant(UIConfig.YIB2C)) {
        // Send passenger details if user is logged in and tenant is YasIsland
        const loggedInUser = getLoggedInUser();
        const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
        const _isPostBookingJourney = getSessionStorage('addonAddedAfterConfirmation') === 'true' ?? false;
        const _guid = _isPostBookingJourney ? `_${generateGUID()}` : '';
        if (isLoggedInUser()) {
          if (!_isAddonAddedAfterBooking || _isAddonAddedAfterBooking === 'false') {
            let userId = getSessionStorage('packageSummaryData');
            if (userId) userId = JSON.parse(userId).userId;
            const localStorageMem = getMembershipDetails('membershipData');
            const { segmentType, status, email } = localStorageMem;
            const isCorpUser = segmentType?.toLowerCase() === 'corporate' && status === 'enabled';
            const { phoneCountryCode, mobileNum } = loggedInUser;
            let passengerObject = {
              Title: 'Mr.',
              FirstName: loggedInUser.userName,
              LastName: loggedInUser.lastName,
              Email: loggedInUser.email,
              ...(isCorpUser && { CorporateEmail: email }),
              Phone: mobileNum ? `${phoneCountryCode} ${mobileNum}` : '',
              CountryOfResidence: loggedInUser.country,
              Nationality: loggedInUser.nationalityAlphaCode,
              UserId: userId,
              AuthToken: loggedInUser.idToken,
            };
            const specialRequests = getSessionStorage('specialRequests');
            if (specialRequests) {
              passengerObject.SpecialRequests = JSON.parse(specialRequests);
            }

            const PassengerDetailsOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
              },
              body: JSON.stringify(passengerObject),
            };

            const passengerUrl = this.props.propsData?.services?.SetPassengerDetails.url.replace(
              '{cartId}',
              getSessionStorage('cartId'),
            );

            const passengerCallResponse = await fetch(passengerUrl, PassengerDetailsOptions);
            const passengerCallData = await passengerCallResponse.json();
            if (!passengerCallData?.isSuccess) {
              removeLoaderOverlay();
              return;
            }
          }
          this.callTravelboxApi(_guid);
        }

        !isLoggedInUser() &&
          window.PubSub.subscribe('dataPosted', (msg, data) => {
            data.isDataPosted && this.callTravelboxApi(_guid);
          });
      } else {
        this._componentSvc
          .createOrder(cartData, this.props.partnerRefernce, this.props.dueData)
          .then((response) => {
            if (isTicketUpgradePayment()) {
              this._orderDetails = response?.data;
              this._applePay.begin();
              Logging(response, 'GPay_Component', true, `GPay - Order Id Created`);
            } else {
              setYasId(response.data);
              let mainObj = getMainObject(),
                idToken = resolvePath(response.data, 'id_token'),
                defaultStateVal = getAPIStateParam(),
                signInData = { state: defaultStateVal, id_token: idToken },
                signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
              // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
              if (signInURL && idToken) {
                OrderService.yasIdAutoLogin(
                  'YasIdAutoLogin',
                  signInURL,
                  UIConfig.loader.defaultPreLoaderTarget,
                  signInData,
                )
                  .then((autoLoginRes) => {
                    this._orderDetails = response.data;
                    this._applePay.begin();
                    Logging(response, 'Apple_Pay_Component', true, `Apple Pay - Step 2 - Order Id Created`);
                  })
                  .catch((err) => {
                    /* do nothing */
                  });
              } else {
                this._orderDetails = response.data;
                this._applePay.begin();
                Logging(response, 'Apple_Pay_Component', true, `Apple Pay - Step 2 - Order Id Created`);
              }
            }
          })
          .catch(this.createOrderErrorHandler);
      }
    }
  };

  // Handles Create Order Errors
  createOrderErrorHandler = (response_error) => {
    Logging(response_error, 'Apple_Pay_Component', true, `Apple Pay - Step 2 - Order Id Generation Failed`);
    if (isTicketUpgradePayment()) {
      return backToUpgradePage();
    }
    if (checkInlineSignup()) {
      this._isErrorOccured('createOrderYasId', response_error.error);
    } else {
      this._isErrorOccured('createOrder', response_error.error);
    }
    this.removeSession();
    PaymentAnalytics && PaymentAnalytics(response_error.error.code, response_error.error.text);
  };

  /**
   *  When Payment is cancelled due to inactivity of user OR user took more than 30 secs enter creditial
   *
   */
  onPaymentCancelled = (response) => {
    Logging(response, 'Apple_Pay_Component', true, 'Apple Pay - Payment Cancelled');
    checkTenant(UIConfig.YIB2C) && removeLoader();
    this.removeSession();
  };

  /**
   *  validate merchants
   */
  onValidateMerchant = (response) => {
    let svc = new ApplePayService();
    const tenantID = getLoggedInUser().tenantID;
    const requestObj = {
      validationURL: response.validationURL,
      domainName: window.location.hostname,
      merchantIdentifier: this._merchantIdentifier,
      displayName: this._displayName,
      tenantID,
    };

    const successHandler = (response) => {
      const data = { ...response.data };
      Logging(response, 'Apple_Pay_Component', true, 'Apple Pay - Step 1 - Merchant Validation Successfull');
      svc = null;
      this._applePay.completeMerchantValidation(data);
    };

    svc
      .validateMerchant(requestObj, this._services.validation)
      .then(successHandler)
      .catch((erroResponse) => {
        checkTenant(UIConfig.YIB2C) && removeLoaderOverlay();
        Logging(response, 'Apple_Pay_Component', true, 'Apple Pay - Step 1 - Merchant Validation Failed');
        this._isErrorOccured('applePayMerchantValidation', erroResponse.error);
        this._applePay.completePayment(window.ApplePaySession.STATUS_FAILURE);
        this.removeSession();
      });
  };

  /**
   *  When user has successfully verified Touch id or entered valid password
   *  Call Payfort with encrypted payment and cart details
   */
  onPaymentAuthorised = (response) => {
    const token = response?.payment?.token; // Encrypted Card and payment data processed on Apple server
    const { displayName, network } = token?.paymentMethod;
    const cardNumber = displayName?.split(' ');
    const orderApprovalInfo = {
      approvalCode: null,
      cardNumber: cardNumber?.[cardNumber?.length - 1] || null,
      paymentOption: network.toUpperCase() || null,
    };
    setLocalStorage('orderApprovalInfo', btoa(JSON.stringify(orderApprovalInfo)));
    const svc = new ApplePayService();
    const { isCustomerIP } = this.state;
    const customerIp = isCustomerIP || this.props.propsData?.customerIP;
    const apiData = this._componentSvc.getPayFortApiData(token, this._orderDetails, customerIp);

    svc
      .makePurchase(apiData, this._services.purchase)
      .then(this.purchaseSuccessHandler)
      .catch(this.purchaseErrorHanlder);
  };

  /**
   *  Successfull Payment
   *  Complete Payment and close Payment Sheet
   *  call reconcile to close order
   */
  purchaseSuccessHandler = (response_payfort) => {
    Logging(response_payfort, 'Apple_Pay_Component', true, `Apple Pay - Step 2 - Payment Successful`);
    //Added this check to show applepay error message in case of status=200
    if (
      response_payfort.data.response_code &&
      response_payfort.data.response_code.toString() !== '1400' &&
      response_payfort.status.toString() === '200'
    ) {
      const errorResponse = {
        error: {
          code: response_payfort.data.response_code,
          text: response_payfort.data.response_message,
        },
      };
      this.purchaseErrorHanlder(errorResponse);
    } else {
      this._applePay.completePayment(window.ApplePaySession.STATUS_SUCCESS);
      this.reconcile(response_payfort.data);
    }
  };

  /**
   *  when purchase is failed
   *  close payment sheet with message - Payment not completed
   *  remove payment session and show error.
   */
  purchaseErrorHanlder = (purchase_error) => {
    checkTenant(UIConfig.YIB2C) && removeLoaderOverlay();
    if (purchase_error.error && checkTenant(UIConfig.yasArenaB2CTenant)) {
      doublePaymentOverlay(purchase_error.error, purchase_error.text);
    } else {
      Logging(purchase_error, 'Apple_Pay_Component', true, `Apple Pay - Step 2 - Payment Failed`);

      this._applePay.completePayment(window.ApplePaySession.STATUS_FAILURE);

      this._isErrorOccured('applePayMakePurchase', purchase_error.error);
      this.removeSession();
    }
  };

  /**
   *  Begin Reconcilation
   *  When successfull -  redirects to Confirmation Page.
   *  When Failed -  close payment sheet and show error
   *  removepayment session.
   */
  reconcile = (reconcileData) => {
    setReconcilRequestAction();
    this._componentSvc
      .reconcile(reconcileData)
      .then((response) => {
        if (response?.data?.error && checkTenant(UIConfig.yasArenaB2CTenant)) {
          doublePaymentOverlay(response.data.error);
        }
        localStorage.removeItem('orderApprovalInfo');
        if (checkTenant(UIConfig.YIB2C)) {
          this.newOrderId = this._orderDetails?.orderID;
          if (this._orderDetails?.orderID?.includes('_')) {
            // ! Underscore will only come in upsell of addon journey in payfort. We need to remove values after underscore from order id.
            this.newOrderId = this._orderDetails?.orderID?.split('_')[0];
          }
          // Storing bookingId for after payment services i.e Upsell of addons
          setSessionStorage('bookingId', this.newOrderId);
        }
        this.redirectToThankYouPage(
          true,
          checkTenant(UIConfig.YIB2C) ? this.newOrderId : this._orderDetails.orderID || this._orderDetails?.sale?.ak,
          'applePayMakePurchase',
        );
        checkTenant(UIConfig.YIB2C) && removeLoaderOverlay();
      })
      .catch((reconcile_error) => {
        checkTenant(UIConfig.YIB2C) && removeLoaderOverlay();
        if (reconcile_error && checkTenant(UIConfig.yasArenaB2CTenant)) {
          doublePaymentOverlay(reconcile_error.error);
        } else {
          Logging(reconcile_error, 'Apple_Pay_Component', true, `Apple Pay - Step 2 - Reconcile step`);
          this._isErrorOccured('reconcile', reconcile_error.error);
          this.removeSession();
          if (isYasArenaJourney()) {
            this.redirectToThankYouPage(false, 0, 'failPurchase', reconcile_error.error, {});
          }
        }
      });
  };

  /**
   *  In case of Error remove apple pay session and order details.
   */
  removeSession = () => {
    this._applePay = null;
    this._orderDetails = null;
  };

  //Redirecting to thank you page
  redirectToThankYouPage = (
    status,
    orderId = 0,
    serviceKey = '',
    responseOrError = null,
    paymentPageErrorMessage = {},
  ) => {
    removeGuestUser();
    const tenantID = getLoggedInUser().tenantID;
    let isRenewAnnualPass = this.props.propsData.paymentOptions.pageVariant === 'annualPassRenew';
    if (isRenewAnnualPass) {
      sessionStorage.removeItem('cartForAnnualPassRenewal');
      sessionStorage.removeItem(UIConfig.payloadForRenew);
      sessionStorage.removeItem('annualPassRenewalData');
    }
    if (isYasArenaJourney()) {
      if (responseOrError) {
        localStorage.setItem(
          UIConfig.localStoreKeys.payment.applePayReconcileError,
          JSON.stringify({
            reconcile: {
              code: responseOrError.code,
              text: responseOrError.text,
            },
          }),
        );
      } else {
        localStorage.removeItem(UIConfig.localStoreKeys.payment.applePayReconcileError);
      }
    }
    let annualPassRenew = isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
    const ticketUpgrade = isTicketUpgradePayment() ? '&isTicketUpgrade=true' : '';
    if (status) {
      if (tenantID !== 'ALLB2B') {
        if (checkTenant(UIConfig.YIB2C)) {
          const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
          sessionStorage.removeItem('cartId');
          if (_isAddonAddedAfterBooking == 'true') {
            setSessionStorage('addonSuccessfullyAdded', 'true');
          }
          sessionStorage.removeItem('addonAddedAfterConfirmation');
        }
        localStorage.removeItem('yasIdUserData');
        window.top.location.href =
          this.props.propsData.paymentOptions.primaryCTA.url +
          '?' +
          checkYAEventId() +
          UIConfig.querStringParams.thankYouPage.status +
          '=' +
          status +
          '&' +
          UIConfig.querStringParams.thankYouPage.orderId +
          '=' +
          btoa(orderId) +
          '&' +
          UIConfig.querStringParams.thankYouPage.reference +
          '=' +
          UIConfig.paymentOption.applePay +
          annualPassRenew +
          ticketUpgrade;
      } else {
        window.top.location.href =
          this.props.propsData.paymentOptions.primaryCTA.url +
          '?' +
          UIConfig.querStringParams.thankYouPage.status +
          '=' +
          status +
          '&' +
          UIConfig.querStringParams.thankYouPage.orderId +
          '=' +
          encodeURIComponent(orderId) +
          '&' +
          UIConfig.querStringParams.thankYouPage.reference +
          '=' +
          UIConfig.paymentOption.applePay;
      }
    } else {
      window.top.location.href =
        this.props.propsData.paymentOptions.primaryCTA.url +
        '?' +
        UIConfig.querStringParams.thankYouPage.status +
        '=' +
        status +
        '&' +
        UIConfig.querStringParams.thankYouPage.reference +
        '=' +
        UIConfig.paymentOption.applePay;
    }
  };

  componentDidMount() {
    AnnualPassData.getGeoIP()
      .then((res) => {
        const { ipAddress } = res.data;
        this.setState({ isCustomerIP: ipAddress || '' });
      })
      .catch((err) => {
        Logging(err, 'GEO Location API failed');
      });
  }

  componentWillUnmount() {
    this.removeSession();
  }

  render() {
    const classes = classNames('apple-pay-wrapper btn-primary', {
      disabled: this.props.hasADCBProductInCart,
    });

    return (
      <>
        {this.props.emailConfirmationText}
        {this._enableDeviceFingerprint && <DeviceFingerPrint />}
        <div
          data-c-name="apple-pay-component"
          data-component-name="apple-pay-component"
          data-c-render="client-only"
          className={classes}
        >
          <button className="apple-pay-button" id="applePayButton" onClick={this.handlePayClick}></button>
        </div>
      </>
    );
  }
}

_ApplePayComponent.displayName = 'ApplePayComponent';
export { _ApplePayComponent as ApplePay };
