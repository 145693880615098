/*
 * SingleSelectRadioFilter.js
 * This file contains code for Single Select Filter of Radio Button Type
 * @licensor  Miral
 */

import PropTypes from 'prop-types';
import React from 'react';
import filter from './filter-composition';
import { parseQueryString } from '../../../../common/utility';
import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';

/**
 * SingleSelectRadioFilter Class ( which extends the React.Component) creates Radio button based fitler,
 * and updates the value of filter on change
 */
class SingleSelectRadioFilter extends React.Component {
  /**
   * onChange  Used to update the value of filter of Radio Button based filter
   * @param    {[value]} accepts the value of the target radio element.
   * @return   {[Void]} function does not return anything.
   */
  onChange = ({ target: { value } }) => {
    this.props.updateFilter(value);
  };

  render() {
    try {
      return (
        <div className={this.props.className}>
          {this.props.filterList.map((options) => (
            <div className="radio" key={options.id}>
              <label>
                <input
                  type="radio"
                  name={this.props.filterName}
                  defaultChecked={
                    parseQueryString('status')
                      ? parseQueryString('status') === options.id
                        ? true
                        : false
                      : options.selected
                  }
                  value={options.id}
                  onClick={this.onChange}
                />
                <span></span>
                <DynamicContent tagName="span" innerHtml={options.label} />
              </label>
            </div>
          ))}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'SingleSelectRadioFilter');
    }
  }
}

SingleSelectRadioFilter.propTypes = {
  props: PropTypes.shape({
    filterList: PropTypes.shape({
      orderType: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
  }),
};
export default filter(SingleSelectRadioFilter);
