import React from 'react';
import PropTypes from 'prop-types';

import { appendElement } from '../../../common/utility';
import DropDown from '../drop-down';
import { logComponentRenderingError } from '../../../common/logger';

import './language-switcher.scss';
import { languageToggleGA } from '../../../common/analytics-events';

/**
 * LanguageSwitcher dumb Component helps enabling localization.
 * functionality of switching between different languages
 * @param    {props} Object as defined from data-contract.
 * @return   {DropDown} List of available langugaes
 */

const callBackFunction = (selectedLangObj, callbackParams) => {
  let pathname = window.location.pathname;
  const langCodeInLC = selectedLangObj.languageCode.toLowerCase();
  let urlFrags = pathname.split('/');
  urlFrags = urlFrags.filter((frag) => frag);

  const urlFrag = urlFrags.length && urlFrags[0].toLowerCase();
  const isLangCodeInURL = urlFrag ? callbackParams.languageList.includes(urlFrag) : false;
  currentLanguage && languageToggleGA(currentLanguage, selectedLangObj.languageCode);
  if (isLangCodeInURL) {
    if (langCodeInLC !== urlFrag) {
      urlFrags[0] = langCodeInLC;
      pathname = urlFrags.join('/');
      window.location.href = `${window.location.origin}/${pathname}${window.location.search}${window.location.hash}`.replace(
        /\/$/,
        '',
      );
    }
  } else {
    pathname = `/${langCodeInLC}${pathname}`;
    window.location.href = `${window.location.origin}${pathname}${window.location.search}${window.location.hash}`.replace(
      /\/$/,
      '',
    );
  }
};

let currentLanguage = '';

const LanguageSwitcher = ({ data, lightMode }) => {
  try {
    const { labels, languages, languageSelectorAriaLabel } = data;
    currentLanguage = labels?.languageCode;
    if (languages.length < 2) {
      return null;
    }

    languages.forEach(function(obj) {
      obj.value = obj.languageCode;
      obj.text = obj.languageName;
      obj.img = obj.languageFlag;
    });

    const languageList = languages.map((lang) => lang.languageCode.toLowerCase());

    return (
      <div className="language-switcher">
        {languages && languages.length && languages.length !== 1 ? (
          <DropDown
            callBackFunction={callBackFunction}
            listItems={languages}
            defaultValue={labels && labels.languageCode}
            callBackParameters={{ defaultLanguageObj: languages[0], languageList }}
            showSelectedValue="true"
            firstTimeLoad="true"
            lang="true"
            ariaLabel={`${languageSelectorAriaLabel}`}
            appendElement={appendElement}
            isLanguageSwitcher
            lightMode={lightMode}
          />
        ) : (
          <div className="selected-option language-switcher--lng-label">{labels.languageCode}</div>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'LanguageSwitcher');
  }
};

LanguageSwitcher.propTypes = {
  props: PropTypes.shape({
    data: PropTypes.shape({
      labels: PropTypes.shape({
        selectedLanguage: PropTypes.string.isRequired,
      }),
    }),
  }),
};

LanguageSwitcher.defaultProps = {
  languageSelectorAriaLabel: 'Change language dropdown, currently selected language',
};

export default LanguageSwitcher;
