import React, { useEffect, useContext } from 'react';
import LazyLoad from 'react-lazyload';
import { Placeholder, VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import Init from '../components/miscellaneous/init';
import { currentLocale, isB2B, isEnvDevelopment } from '../common/utility';
// import { logCustomProfiler } from '../common/logger';
import setGlobalVars from '../common/global-vars';
import CustomProfiler from './CustomProfiler.js';
import UIConfig from '../common/UIConfig';
import { UniformContext } from '@uniformdev/optimize-react';
import { useSitecoreTracker } from '@uniformdev/tracking-react';

import { SitecorePersonalizationContextProvider } from '@uniformdev/personalize-react';

import * as uniformdev from '@uniformdev/optimize-js';
import { arCalendar } from '../utils/calendarArUtils';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ar-sa';
import { convertRoute } from './utils/helper';

if (typeof window !== 'undefined' && typeof window.uniformdev === 'undefined') {
  window.uniformdev = uniformdev;
}

/*** TODO: START BLOCK - REMOVE TO DISABLE UNIFORM LOGGING ***/
function getLogger(name) {
  return {
    debug: (message, data) => {
      console.log(new Date().toISOString() + ` [DEBUG] [${name}] ` + message, data);
    },
    info: (message, data) => {
      console.log(new Date().toISOString() + ` [INFO]  [${name}] ` + message, data);
    },
    warn: (message, data) => {
      console.log(new Date().toISOString() + ` [WARN]  [${name}] ` + message, data);
    },
    error: (message, data) => {
      console.log(new Date().toISOString() + ` [ERROR] [${name}] ` + message, data);
    },
  };
}
/*** TODO: END BLOCK - REMOVE TO DISABLE UNIFORM LOGGING ***/

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route, context, tenantName, IconSprite, sitecoreContext }) => {
  let placeholderData = context && context.staticComponents && context.staticComponents.sitecore.route;
  let layoutInitTime = new Date().toISOString();
  setGlobalVars({
    pageDetails: {
      itemId: route.itemId,
      pageName: route.name,
      tenantName: UIConfig.iamMapping[tenantName.toLowerCase()],
    },
  });
  useEffect(() => {
    if (currentLocale() === UIConfig.languages.arSA) moment.updateLocale(UIConfig.languages.arSA, { ...arCalendar });
    else moment.locale(currentLocale());
    if (isB2B()) moment.locale(UIConfig.languages.enGB);
    console.log('Build number is: ##BUILD_NUMBER##');
  }, []);

  const siteName = context && context.site ? context.site.name : undefined;

  /*** TODO: START BLOCK - REMOVE TO DISABLE UNIFORM LOGGING ***/
  const ctx = useContext(UniformContext);
  useEffect(() => {
    if (!ctx.logger && isEnvDevelopment()) {
      ctx.logger = getLogger('context');
    }
  }, [ctx.logger]);
  /*** TODO: END BLOCK - REMOVE TO DISABLE UNIFORM LOGGING ***/
  let siteCoreTrackerProps = {
    type: 'jss',
  };

  if (isEnvDevelopment()) {
    siteCoreTrackerProps['logger'] = getLogger('tracker');
  }
  useSitecoreTracker(sitecoreContext, {
    ...siteCoreTrackerProps,
  });

  // let siteCoreTrackerProps = {
  //   setContextInCookies: true,
  //   type: 'jss',
  // };

  // if (isEnvDevelopment()) {
  //   siteCoreTrackerProps['logger'] = getLogger('tracker');
  // }

  // useSitecoreTracker(sitecoreContext, {
  //   ...siteCoreTrackerProps,
  // });

  //
  // import common scss file for disconnected mode

  // require('../scss/componentStyles/common.scss');
  // require('../scss/main.scss');
  // // require('../components/container/my-profile/my-profile-component.scss');

  // require('../../../tenants/ppad/src/scss/Main.scss');
  // require('../../../tenants/ppad/src/components/Header/index.scss');
  // require('../../../tenants/ppad/src/components/GuestCheckoutForm/index.scss');
  // require('../../../tenants/ppad/src/components/MyCartB2C/index.scss');
  // require('../../../tenants/ppad/src/components/PaymentComponent/index.scss');
  // require('../../../tenants/ppad/src/components/Header/header-component.scss');
  // require('../../../tenants/yasIsland/src/components/TabsWithPlaceholder/');
  // require('../../../tenants/yasIsland/src/components/TabsWithPlaceholder/index.js');
  // require('../../../tenants/fwad/src/components/MyProfileB2c/my-profile-component.scss');
  // require('../../../tenants/fwad/src/components/MyPreferences/my-preferences-component.scss');

  // require('../../../tenants/yasisland/src/components/TabsWithPlaceholder/tabs-with-placeholder-component.scss');
  // require('../../../tenants/yasisland/src/components/MyProfileB2c/my-profile-component.scss');
  // require('../../../tenants/yasisland/src/components/MyPreferences/my-preferences-component.scss');

  // // Pass mock data to components if we are in disconnected mode
  // const module = require(`@yas/mocks/${tenantName}`);
  // if (route.databaseName === 'available-in-connected-mode') {
  //   placeholderData = route;
  //   for (const placeholderName in route.placeholders) {
  //     const components = route.placeholders[placeholderName];
  //     components.forEach((c) => {
  //       const compName = c.componentName;
  //       const fieldsFile = c.params.fieldsFile || `${compName}`;
  //       const mockJson = module[fieldsFile];
  //       if (Object.keys(mockJson.placeholders || {}).length) {
  //         c.params = mockJson.params;
  //         c.placeholders = mockJson.placeholders;
  //       } else {
  //         if (Object.keys(mockJson.params || {}).length) {
  //           c.params = mockJson.params;
  //         }
  //         c.fields = mockJson.fields;
  //       }
  //     });
  //   }
  // }

  //Custom Profiler Methods
  let profilerLogs = [];

  //Callback collecting components data
  const collectComponentData = (componentData) => {
    profilerLogs.push(componentData);
  };

  //Callback logging the components and layout layer profiling
  const layoutLayerProfiling = () => {
    let layoutEndTime = new Date().toISOString();
    profilerLogs.unshift({
      componentName: 'Layout',
      componentStartTime: layoutInitTime,
      componentEndTime: layoutEndTime,
      componentRenderTime: `${new Date(layoutEndTime).getTime() - new Date(layoutInitTime).getTime()}ms`,
    });
    for (let i = 1; i < profilerLogs.length - 1; i++) {
      if (profilerLogs[i + 1]) {
        profilerLogs[i].componentRenderTime = `${new Date(profilerLogs[i + 1].componentStartTime).getTime() -
          new Date(profilerLogs[i].componentStartTime).getTime()}ms`;
        profilerLogs[i].componentEndTime = profilerLogs[i + 1].componentStartTime;
      }
    }

    //Logging the client side and server side profiling
    if (isEnvDevelopment()) {
      console.table && console.table(profilerLogs);
    }
    // else {
    //   logCustomProfiler(profilerLogs);
    // }
  };

  const renderFn = (lastPlaceholder = false) => {
    return (components) => (
      <React.Fragment>
        {components.map((component, index) => {
          const { componentName } = component.props.rendering || component.props || {};
          return (
            <CustomProfiler
              key={index}
              componentName={componentName}
              collectComponentData={collectComponentData}
              componentsNumber={components.length - 1}
              index={index}
              layoutLayerProfiling={layoutLayerProfiling}
              lastPlaceholder={lastPlaceholder}
            >
              {component}
            </CustomProfiler>
          );
        })}
      </React.Fragment>
    );
  };

  let SPCPProps = {
    contextData: context,
    personalizationMode: 'jss-esi',
    sitecoreApiKey: '{CDF31AAC-705C-4FAC-8189-A68F0391738B}',
    sitecoreSiteName: siteName,
  };

  if (isEnvDevelopment()) {
    SPCPProps['logger'] = getLogger('global');
  }

  return (
    <SitecorePersonalizationContextProvider {...SPCPProps}>
      <React.Fragment>
        {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
        <Helmet>
          <title>{(route.fields && route.fields.PageTitle && route.fields.PageTitle.value) || 'Page'}</title>
        </Helmet>

        {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
        <VisitorIdentification />
        <Placeholder
          name={`${UIConfig.staticPlaceholdersMapping[tenantName.toLowerCase()]}-static`}
          rendering={placeholderData}
          render={renderFn()}
        />
        {/* root placeholder for the app, which we add components to using route data */}
        {/* If tenant is yasisland or swad, We've removed container because we're using bootstrap */}
        <div className={`${tenantName !== 'yasisland' && tenantName !== 'swad' && 'container'} tenant-${tenantName}`}>
          <Init />

          {IconSprite &&
            (tenantName === 'swad' ? (
              <IconSprite />
            ) : (
              <LazyLoad offset={4000}>
                <IconSprite />
              </LazyLoad>
            ))}

          <Placeholder name="esi-context" rendering={convertRoute(route)} render={renderFn()} />
          {/* Passing true in the Placeholder to detemine we have reached the last placeholder*/}
          <Placeholder name="jss-header" rendering={convertRoute(route)} render={renderFn()} />
          <main className="page-container" id="main-content">
            <Placeholder name="jss-main" rendering={route} render={renderFn()} />
          </main>
          <Placeholder name="jss-footer" rendering={convertRoute(route)} render={renderFn(true)} />
        </div>
      </React.Fragment>
    </SitecorePersonalizationContextProvider>
  );
};

export default withSitecoreContext()(Layout);
