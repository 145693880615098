import React from 'react';
import { LineSeparator } from '..';
import DynamicContent from '../../../../presentation/base/dynamic-content/dynamic-content-component';
const index = ({ options, isDrivingExperience = false }) => {
  return (
    (options && Array.isArray(options) && options.length && (
      <>
        {isDrivingExperience && <LineSeparator param="bottom" />}
        <div className="help-text-container">
          {options.map((opt, i) => (
            <div key={i} className="help-text-wrapper">
              <DynamicContent tagName="span" attrs={{ className: 'title' }} innerHtml={opt.title} />
              <DynamicContent tagName="span" attrs={{ className: 'description' }} innerHtml={opt.description} />
            </div>
          ))}
        </div>
      </>
    )) || <></>
  );
};
export default index;
