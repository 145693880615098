/*
 * collapsible-content-components.js
 * This Module makes the collapisble content component to be used on FAQ page
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { detectMobile } from '../../../common/utility';

import Accordion from '../../presentation/accordian';
import { DynamicContent } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';

/**
 * CollapsibleContent creates the collapsible component
 * @param    {[Array]} data [contains the array of objects of tile and thier corresponding description]
 * @return   {[Object]} Return the compiled JSX of the component.
 */

const CollapsibleContent = ({ data, isCollapsibleComponent, isMealPlan = false }) => {
  try {
    return (
      <div
        className={classNames(
          `component c-collapsible-content ${data?.variant} ${isMealPlan ? 'insideMealPlan' : ''}`,
          {
            'border-bottom-in-mobile': detectMobile() && data?.showBorderBottomInMobile,
          },
        )}
        data-c-name="CollapsibleContent"
        data-c-render="universal"
      >
        <div className="w--content">
          <dl className="collapsible-content-container">
            {data.titleList.reduce((acc, item, index) => {
              return acc.concat([
                <DynamicContent
                  tagName="dt"
                  key={'title' + index}
                  attrs={{ className: 'c-collapsible-content--title heading-4' }}
                  innerHtml={item.titleRTE}
                />,
                <dd
                  key={'list' + index}
                  className={
                    item.titleRTE === ''
                      ? 'c-collapsible-content--bodycopy c-collapsible-content--bodycopy--no-title body-copy-4'
                      : 'c-collapsible-content--bodycopy body-copy-4'
                  }
                >
                  <Accordion
                    accordianItems={item.summaryTextList}
                    isMultiExpandable={true}
                    index={index}
                    isCollapsibleComponent={isCollapsibleComponent}
                    title={item?.titleRTE}
                    tabTitle={data?.tabTitle}
                    variant={data?.variant}
                  />
                </dd>,
              ]);
            }, [])}
          </dl>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'CollapsibleContent', data?.variant);
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
CollapsibleContent.propTypes = {
  data: PropTypes.object,
};

export default CollapsibleContent;
