import React from 'react';
import AddOnsCategoryComponent from './add-ons-category-component';
import { DynamicContent } from '../../../presentation/base';
import {
  getClosestByClass,
  getLoggedInUser,
  detectViewPort,
  isEmpty,
  checkParksTenants,
  isMatchTenant,
  toLowerCase,
} from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { logComponentRenderingError } from '../../../../common/logger';
import GTMData from '../gtm-data';

const GTMDataOnClickCTA = (data) => {
  if (isMatchTenant(UIConfig.tenants.fwad) || isMatchTenant(UIConfig.tenants.yi)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
      name: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.name) : data.name,
      [UIConfig.ga4Constants.ELEMENTTEXT]: isMatchTenant(UIConfig.tenants.yi)
        ? toLowerCase(data.elementText)
        : data.elementText,
      category: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.category) : data.category,
    });
  }
};

class AddOnsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.tktVal = props.tktVal;
    this.extraTicketObj = props.data.controls.extras.options;
    this.paramObj = this.extraTicketObj.filter((option) => option.coveoValue === this.tktVal);
    this.categoriesOrder = this.props.data.controls.extras.options.map((category) => category.coveoValue);
    const selectedTicket = this.getSelectedTicket();
    const selectedTicketData = {
      selectedTicket,
    };
    this.state = {
      products: [],
      disableAddToCart: true,
      selectedTicketData,
      ticketState: this.tktVal || this.props.hightLightFirstTicket ? 'default' : 'noSelect',
      searchProductList: [],
    };
    this.scrollToTicket = false;
    this.recommendationTabName = UIConfig.b2c.purchaseJourney.recommendationTabCode;
    this.tenantId = getLoggedInUser().tenantID;
    this.isPJOnly = props.data.variant === 'v-multi-product-widget';
  }

  componentDidMount() {
    if (this.props.data.type !== this.recommendationTabName) {
      this.props.createProductStore(this.props.data.type).then((response) => {
        if (typeof response.getAllProducts == 'function') {
          let mappedData = this.props.createMappedData(response.getAllProducts());
          const products = this.groupCounterProducts(mappedData);
          this.setState(
            {
              products,
              searchProductList: mappedData,
            },
            () => {
              this.scrollToTicket = true;
              this.updateState();
            },
          );

          if (checkParksTenants()) {
            try {
              const category =
                this.props.data &&
                this.props.data.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
              const allProductList = this.props.data.controls.extras.options.map((items) => {
                const prds = products[items.coveoValue];
                const collection = items?.productOverlaySelector?.collections?.[0];

                if (prds?.length > 1 && collection) {
                  return prds.find((prd) => prd.productId === collection.coveoval);
                } else {
                  return prds[0];
                }
              });
              GTMData.push('viewItemList', {
                allProductList,
                category,
                gaTabTitle: this.props.data.gaTabTitle || undefined,
              });
            } catch (error) {
              console.log('GA4 error:', error);
            }
          }
        }
      });
    }
    if (this.props.data.type === this.recommendationTabName && this.props.recommendedDataRes) {
      this.setRecommendedDetails(this.props.recommendedDataRes);
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.data.type &&
      this.props.data.type.toLowerCase() === this.recommendationTabName &&
      this.props.setRecommendedData !== newProps.setRecommendedData &&
      newProps.setRecommendedData
    ) {
      const { recommendedDataRes } = newProps;
      this.setRecommendedDetails(recommendedDataRes);
    }
  }

  setRecommendedDetails(recommendedDataRes) {
    if (typeof recommendedDataRes.getAllProducts == 'function') {
      let mappedData = this.props.createMappedData(recommendedDataRes.getAllProducts());
      this.setState(
        {
          products: this.groupCounterProducts(mappedData),
          searchProductList: mappedData,
        },
        () => {
          this.scrollToTicket = true;
        },
      );
    }
  }

  getSelectedTicket = () => (this.paramObj.length ? this.tktVal : this.extraTicketObj[0]?.coveoValue);

  handleTicketSelection = (selectedTicket, elem) => {
    this.selectedTicket = this.props.data.controls.extras.options.filter(
      (option) => option.coveoValue === selectedTicket,
    )[0];

    this.setState(
      {
        ticketState: 'default',
        selectedTicketData: { ...this.state.selectedTicketData, selectedTicket },
      },
      () => {
        GTMDataOnClickCTA({
          name: `${this.props.data?.name} - ${selectedTicket} - ${elem.textContent}`,
          category: UIConfig.ga4Constants.PRIMARY_CTA,
          [UIConfig.ga4Constants.ELEMENTTEXT]: elem.textContent,
        });
        return this.updateState(elem);
      },
    );
  };
  updateState = (elem) => {
    if (this.tenantId && this.tenantId.toLowerCase() !== UIConfig.ymcB2CTenant) {
      if (elem) {
        setTimeout(() => {
          const parent = getClosestByClass(elem, 'c-fast-ticket-wrapper');
          parent && parent.scrollIntoView({ behaviour: 'instant', block: 'center' });
        }, 0);
      } else if (this.tktVal) {
        setTimeout(() => {
          const parent = document.querySelector('.c-fast-ticket-wrapper.selected');
          if (detectViewPort() !== 'mobile') {
            parent && parent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
          } else {
            parent && parent.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
          }
        }, 0);
        setTimeout(() => {
          if (detectViewPort() === 'mobile') {
            window.scrollBy(0, -10);
          }
        }, 500);
      }
    }
  };
  groupCounterProducts = (products) => {
    const grouppedProducts = {};
    products.forEach((product) => {
      let filterCat = this.filteredCategoriesOrder();
      let category =
        filterCat &&
        filterCat.length > 0 &&
        filterCat.find((element) => product.category && product.category.includes(element));
      if (product.category && product.category.length > 0 && category && !isEmpty(category)) {
        if (!grouppedProducts.hasOwnProperty(category)) grouppedProducts[category] = [];
        grouppedProducts[category].push(product);
      }
    });
    return grouppedProducts;
  };

  addToCart = (products, noGroup, context, productMaxQty, extraCartParams, variantFromChild = '') => {
    this.props.addToCartCallback(products, noGroup, context, productMaxQty, extraCartParams, variantFromChild);
    this.setState({
      ticketState: 'noSelect',
    });
  };

  getProductCategoriesList = () => {
    let myCategoryList = {};
    for (let pKey in this.state.products) {
      myCategoryList[pKey] = false;
    }
    return myCategoryList;
  };
  filteredCategoriesOrder = () =>
    !this.isPJOnly ? this.categoriesOrder : this.categoriesOrder.filter((v, i, a) => a.indexOf(v) === i);
  render() {
    try {
      const categoryComponents = [];
      const prodCategoryList = this.getProductCategoriesList();
      return (
        <>
          {this.props.data.tabTitle && (
            <DynamicContent tagName="h5" attrs={{ className: 'tab-title' }} innerHtml={this.props.data.tabTitle} />
          )}
          {this.props.data.tabDescription && (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'tab-description' }}
              innerHtml={this.props.data.tabDescription}
            />
          )}
          {this.props.data.tabWarning && (
            <DynamicContent tagName="p" attrs={{ className: 'tab-warning' }} innerHtml={this.props.data.tabWarning} />
          )}
          <div>
            {Object.keys(this.state.products).length > 0 &&
              this.filteredCategoriesOrder().forEach((category) => {
                if (this.state.products[category]) {
                  categoryComponents.push(
                    <AddOnsCategoryComponent
                      data={this.props.data}
                      key={category}
                      category={category}
                      products={this.state.products[category]}
                      createProductStore={this.props.createProductStore}
                      createMappedData={this.props.createMappedData}
                      addToCartCallback={this.addToCart}
                      services={this.props.services}
                      getDiscountedProducts={this.props.getDiscountedProducts}
                      cartData={this.props.cartData}
                      recommendationUrl={this.props.recommendationUrl}
                      businessErrors={this.props.businessErrors}
                      selectedTicket={this.state.selectedTicketData.selectedTicket}
                      handleOnClick={this.handleTicketSelection}
                      ticketState={this.state.ticketState}
                      prodCategoryList={prodCategoryList}
                      coveoMapList={this.props.coveoMapList}
                      searchProductList={this.state.searchProductList}
                      showMinicartCouponCode={this.props.showMinicartCouponCode}
                      discountMap={this.props.discountMap}
                      additionalRecommendedData={this.props.additionalRecommendedData}
                      ariaLabelCheckbox={this.props.ariaLabelCheckbox}
                    />,
                  );
                }
              })}

            {categoryComponents}

            {this.props.data.vatInfo && (
              <DynamicContent
                tagName="div"
                attrs={{ className: 'vat-info body-2' }}
                innerHtml={this.props.data.vatInfo}
              />
            )}
          </div>
        </>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'AddOnsComponent');
    }
  }
}

export default AddOnsComponent;

AddOnsComponent.defaultProps = {
  ariaLabelCheckbox: 'Press enter to select ticket',
};
