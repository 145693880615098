import { isEmpty } from 'lodash';
import UIConfig from '../../../common/UIConfig';
import {
  canUseDOM,
  checkIfParks,
  getLanguageWithoutLocal,
  getLoggedInUser,
  getMembershipDetails,
  isAnnualPassCase,
  isArabicMode,
  isMatchTenant,
  isTicketUpgradePayment,
} from '../../../common/utility';
import { YALocalStorageSvc } from '../b2c-purchase-journey/my-cart/yasarena-cart-management-service';
import { Logging } from '../../../common/logger';
import ApiWrapper from '../../../common/api-wrapper';
import JSEncrypt from 'jsencrypt';

const checkAnnualPassError = (cartData, callback = false) => {
  const tenantID = getLoggedInUser().tenantID;
  const isAnnualPass = tenantID !== 'ALLB2B' && isAnnualPassCase({ cart: cartData }, true);
  if (isAnnualPass && sessionStorage.getItem('annualPassFormSuccess') === 'false') {
    const annualPassSubmit = document.getElementById('submit-btn');
    if (callback) {
      if (annualPassSubmit) {
        annualPassSubmit.click();
      }
      if (checkIfParks() || isMatchTenant(UIConfig.tenants.swadb2c)) {
        window.PubSub.subscribe(UIConfig.events.ANNUAL_PASS_FORM_SUCCESS, () => {
          callback();
        });
      }
    }
    return true;
  }
  return false;
};

const isThereExtraErrors = (e) => {
  if (localStorage.getItem(UIConfig.localStoreKeys.cartError)) {
    e && scrollToServerError(e);
  }
  return localStorage.getItem(UIConfig.localStoreKeys.cartError);
};
//Showing smothli scroll to the error message with className serverError
const scrollToServerError = (e, err) => {
  e && e.preventDefault();
  const serverError = document.getElementsByClassName(err ? err : `${UIConfig.errorClassNames.serverError}`);
  if (serverError.length) {
    serverError[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

const loadMembershipDetails = () => {
  const localstoragemembership = getMembershipDetails();

  if (localstoragemembership) {
    return localstoragemembership.membershipDetails;
  }
};

const getCorporateMemberData = () => {
  const membershipDetails = loadMembershipDetails();
  const corporateMemberData = membershipDetails?.find((memberdata) => memberdata.segmentType === UIConfig.CORPORATE);

  return corporateMemberData;
};

const getCorporateUserEmailId = () => {
  const corporateMemberData = getCorporateMemberData();

  if (isEmpty(corporateMemberData)) {
    return '';
  }
  return corporateMemberData?.email || '';
};

const isCorpCouponApplied = (promoCode) => {
  const corporateMemberData = getCorporateMemberData();
  let appliedPromocode = promoCode;
  if (!corporateMemberData?.couponCode?.length) return false;

  const couponCodeData = corporateMemberData?.couponCode?.[0].split(':');
  const couponCode = couponCodeData?.[1];

  if (appliedPromocode && ('PromoCode' in appliedPromocode || 'code' in appliedPromocode)) {
    appliedPromocode = promoCode?.PromoCode || promoCode?.code || '';
  }
  return appliedPromocode === couponCode ? true : false;
};

const getEventId = () => {
  const { eventId } = YALocalStorageSvc.getSetEventDetails();
  const visitedEvents = YALocalStorageSvc.getSetVisitedEvents();
  const visitedEventID = Object.keys(visitedEvents)[0];
  return eventId || visitedEventID || '';
};

const checkYAEventId = () => {
  if (getLoggedInUser().tenantID.toLowerCase() === UIConfig.yasArenaB2CTenant.toLowerCase()) {
    return UIConfig.querStringParams.thankYouPage.eventId + '=' + getEventId() + '&';
  }
  return '';
};

const getPayfortTypeNameID = (type) => {
  return UIConfig.payfort.payfortTypesMap[type];
};

const doublePaymentOverlay = (error) => {
  const { code, type, text } = error;
  const paymentProcessed = UIConfig.errorCodes.paymentProcessed;
  const paymentInProgress = UIConfig.errorCodes.paymentProgress;
  const paymentType = UIConfig.querStringParams.paymentErrorQuery.paymentType;
  const payErrQuery = UIConfig.querStringParams.paymentErrorQuery.paymentError;

  if (code === paymentProcessed && type === 'CloseOrder') {
    window.top.location.href = `/${
      isArabicMode() ? 'ar' : 'en'
    }/mypayment?${payErrQuery}=${paymentProcessed}&${paymentType}=CloseOrder&message=${text}`;
  } else if (code == paymentProcessed) {
    window.top.location.href = `/${
      isArabicMode() ? 'ar' : 'en'
    }/mypayment?${payErrQuery}=${paymentProcessed}&${paymentType}=Payment&message=${text}`;
  } else if (code == paymentInProgress) {
    window.top.location.href = `/${
      isArabicMode() ? 'ar' : 'en'
    }/mypayment?${payErrQuery}=${paymentInProgress}&${paymentType}=Payment&message=${text}`;
  }
};

const consentRequest = (url) => {
  const tenent_id = getLoggedInUser().tenantID;
  const guestEmail = getLoggedInUser().email || (canUseDOM() && document.getElementById('Email').value);
  const { phone, sms, whatsApp, website, mobile, email } = UIConfig.CMPconsent;

  ApiWrapper.apiGateway({
    method: 'POST',
    url: `${url}`,
    data: {
      emailId: guestEmail,
      channelName: tenent_id,
      language: getLanguageWithoutLocal(),
      consentPurpose: [
        { [email]: true },
        { [phone]: true },
        { [sms]: true },
        { [whatsApp]: true },
        { [website]: true },
        { [mobile]: true },
      ],
      source: '',
    },
    moduleName: 'Consent API',
    preLoader: true,
    preLoaderTarget: '.container',
  })
    .then((response) => {
      const { status, data } = response;
      if (typeof data === 'object' && (status === 201 || status === 200)) {
        Logging(data, 'Marketing Consent Update', true, 'Logged In User');
      }
    })
    .catch((error) => {
      console.log('consent error', error);
    });
};

const getEncrytpedText = (val) => {
  if (!val) {
    return '';
  }
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(UIConfig.rsaEncKeys.public);
  const encryptedVal = encrypt.encrypt(val);
  return encryptedVal.toString();
};

const checkAnnualUpgradeError = () => {
  let annualPassError = sessionStorage.getItem('annualPassFormSuccess') === 'false' ? true : false;
  if (isTicketUpgradePayment() && annualPassError) {
    const annualPassSubmitBtn = document.getElementById('submit-btn');
    if (annualPassSubmitBtn) {
      annualPassSubmitBtn.click();
    }
  }
  return true;
};

export {
  isThereExtraErrors,
  scrollToServerError,
  getCorporateUserEmailId,
  isCorpCouponApplied,
  checkYAEventId,
  getPayfortTypeNameID,
  doublePaymentOverlay,
  consentRequest,
  checkAnnualPassError,
  getEncrytpedText,
  checkAnnualUpgradeError,
};
