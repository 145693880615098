/*
 * auto-suggest-component.js
 * This file contains code for Auto Suggest component.
 * This component provides search suggestions based on COVEO API, on the basis of input entered by user
 */

import React from 'react';
import PropTypes from 'prop-types';

import { DynamicContent, SvgSprite } from '..';
import { getAutoCompleteResults, getQuerySuggestions } from '../../../../common/coveo-api';
import UIConfig from '../../../../common/UIConfig';
import { Logging } from '../../../../common/logger';
import {
  KeyCode,
  detectMobile,
  canUseDOM,
  autoSuggestConfig,
  moveCaretAtEnd,
  getLoggedInUser,
  debounce,
  parseQueryString,
  resolvePath,
  checkTenant,
  isMatchTenant,
} from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import { searchClickAnalytics } from '../../../../common/analytics-events';
import './auto-suggest.scss';
import GTMData from '../../../container/b2c-purchase-journey/gtm-data';

/**
 *
 * AutoSuggest Class ( which extends the React.Component) renders auto-suggest search field
 * which shows autocomplete results based on data returned by Coveo API.
 */

class AutoSuggest extends React.Component {
  /**
   * Constructor of the class is defined which handles binding of the events to the elements, the
   * props to the super class and defining the state of the component.
   * @state   {queryParams} defines current query parameters for Search Autocomplete API
   * @state   {[autoSuggestResults]} defines autoSuggest results array
   * @state   {resultsReady} defines if autoSuggest results are ready or not
   * @state   {desktopView} defines if the current viewPort is Mobile or not
   * @props   {minCharacters} defines minimum no. of character on which auto suggest
   * results will be shown
   * @props   {maxAutoCompleteResults} defines maximum no. of results to be shown in auto-suggest results
   * @props   {initialQueryParams} defines initial url query parameter
   * @props   {coveoConfig} defines coveo Key Map configuration object
   * @props   {serviceUrl} defines service url for Coveo Autocomplete API
   */

  constructor(props) {
    super(props);
    this.data = props.data;
    this.isSWADB2C = checkTenant(UIConfig.iamMapping.swad);
    this.isYIB2C = checkTenant(UIConfig.iamMapping.yasisland);
    if (props.data) {
      this.minCharacters = this.data.additionalProperty.minimumCharacters || autoSuggestConfig.minimumCharacters;
      this.maxAutoCompleteResults = this.data.autoSuggestLimit;
      this.currentLanguage = this.data.currentLanguage;
      this.coveoConfig = this.data.coveoKeyMap;
      this.analyticsEnabled = this.props.data.additionalProperty && this.props.data.additionalProperty.analyticsEnabled;
      this.locale = this.data.currentLocale;
      this.serviceUrl =
        this.analyticsEnabled === 'true' ? this.data.serviceUrls.querySuggest : this.data.serviceUrls.autoComplete;
      this.autoSuggestMobileView = this.props.data.autoSuggestMobileView;
    }
    if (this.isYIB2C || this.isSWADB2C) {
      this.searchSettingVariant = props.data.searchSettingVariant || props.data.searchVariant || '';
    }

    this.childToParent = (val) => {
      if (
        this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
        this.searchPage === false &&
        (this.isYIB2C || this.isSWADB2C)
      ) {
        this.props.childToParent(val);
      }
    };
    this.urlParams = parseQueryString('q');
    if (canUseDOM()) {
      const regexPattern = /(<([^>]+)>)/gi;
      this.urlParams = this.urlParams?.replace(regexPattern, '');
    }
    this.state = {
      queryParams: props.variant === 'v-search' ? this.urlParams || '' : '',
      autoSuggestResults: [],
      getKeyword: '',
      resultsReady: false,
      desktopView: false,
      autoSuggestMobileView: this.autoSuggestMobileView,
      autoSuggestTextToggle: false,
    };
    this.currUser = '';
    if (this.analyticsEnabled && canUseDOM()) {
      this.mainObj = localStorage.mainObj && JSON.parse(localStorage.mainObj);
      this.additionalProperty = (this.mainObj && this.mainObj.additionalProperty) || {};
    }
    this.searchInputRef = React.createRef();
    this.searchPage = this.props.variant === 'v-search';
    if (this.isYIB2C || this.isSWADB2C) {
      this.customSuggestionText = this.props.data.customSuggestionText || props.data.suggestionText || '';
    }
  }

  /**
   * clear function updates clears query parameters and auto suggest results sets on the click of
   * Clear button
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  clear = (e) => {
    if (
      (this.isYIB2C || this.isSWADB2C) &&
      this.searchPage === false &&
      this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant
    ) {
      this.childToParent(false);
    }
    this.searchInputRef.current.focus();
    this.setState({
      queryParams: '',
      autoSuggestResults: [],
    });
  };
  /**
   * clickSearchResults function navigates the user to next Search page based on auto suggestion
   * clicked by user
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  clickSearchResults = (index, itemValue) => {
    const searchInfo = {
      queryType: this.analyticsEnabled === 'true' ? 'querySuggest' : 'genericSearch',
      partialQuery: this.state.queryParams,
      suggestionRanking: index, // index of result Clicked
      suggestions: this.state.autoSuggestResults.map((item) => item.value).join(';'), // semicomma separated querySuggestions
    };
    if (
      this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
      (this.isYIB2C || this.isSWADB2C)
    ) {
      this.setState({ autoSuggestTextToggle: true });
      searchClickAnalytics(itemValue);
    }

    localStorage.searchInfo = JSON.stringify(searchInfo);
    if (this.searchPage) {
      this.publishSubmitDataForSearchResults(itemValue);
    } else {
      this.navigateToNewSearchPage(itemValue);
    }
  };

  /**
   * updateAnalyticsResults function updates query suggest result set by sending updated query to Coveo Search
   * @param    {[queryText]} accepts query parameters that needs to be passed to Coveo Search Auto
   * Suggest API
   * @return   {[Void]} function does not return anything.
   */
  updateAnalyticsResults = debounce((queryText) => {
    const autoCompleteConfig = {
      count: this.data.autoSuggestLimit,
      enableWordCompletion: this.data.additionalProperty.enableWordCompletion,
      autocompleter: this.data.additionalProperty.autoCompleter,
      pipeline: this.additionalProperty.pipeline,
      query: queryText,
      locale: this.locale,
      searchHub: this.mainObj.additionalProperty.searchHub,
      serviceUrl: this.serviceUrl,
      visitorId: window.sessionStorage && window.sessionStorage.visitorId,
      isGuestUser: getLoggedInUser().idToken ? 'false' : 'true',
    };

    getQuerySuggestions(autoCompleteConfig)
      .then((data) => {
        data &&
          this.setState({
            autoSuggestResults: data,
            resultsReady: data.length ? true : false,
          });
      })
      .catch((error) => {
        Logging(error, 'auto-suggest-component', false, 'Server error in call to querySuggest');
      });
  }, UIConfig.ajaxCallDebounce);

  /**
   * updateResults function updates auto suggest result set by sending updated query to Coveo Search
   * Auto Suggest API
   * @param    {[queryText]} accepts query parameters that needs to be passed to Coveo Search Auto
   * Suggest API
   * @return   {[Void]} function does not return anything.
   */
  updateResults = debounce((queryText) => {
    if (this.isYIB2C || this.isSWADB2C) {
      const autoCompleteConfig = {
        query: queryText,
        coveoKeyMap: this.coveoConfig,
        lang: this.currentLanguage,
        autoSuggestLimit: this.maxAutoCompleteResults,
        getKeyword: queryText,
        serviceUrl: this.serviceUrl,
        tenantId: (this.currUser && this.currUser.tenantID) || this.data.tenantId,
      };
      getAutoCompleteResults(autoCompleteConfig).then((data) => {
        data &&
          this.setState({
            autoSuggestResults: data.values,
            getKeyword: queryText,
            resultsReady: data.values && data.values.length > 0,
          });
      });
    } else {
      const autoCompleteConfig = {
        query: queryText,
        coveoKeyMap: this.coveoConfig,
        lang: this.currentLanguage,
        autoSuggestLimit: this.maxAutoCompleteResults,
        serviceUrl: this.serviceUrl,
        tenantId: (this.currUser && this.currUser.tenantID) || this.data.tenantId,
      };
      getAutoCompleteResults(autoCompleteConfig).then((data) => {
        data &&
          this.setState({
            autoSuggestResults: data.values,
            resultsReady: data.values && data.values.length > 0,
          });
      });
    }
  }, UIConfig.ajaxCallDebounce);
  /**
   * handleKeyUpDown function handles keyboard events - Up Arrow and Down Arrow -
   * while the cursor focus is on Result Set
   * <ul> element
   * @param    {e} accepts the event passed on keyboard key press
   * @return   {[Void]} function does not return anything.
   */
  handleKeyUpDown = (e, KeyCode) => {
    e.preventDefault();
    e.target.removeAttribute('class', 'active');
    let listItem;
    e.keyCode === KeyCode.UP
      ? (listItem = e.target.previousSibling ? e.target.previousSibling : e.target.parentNode.lastChild)
      : (listItem = e.target.nextSibling ? e.target.nextSibling : e.target.parentNode.firstChild);

    if (listItem) {
      this.setState({ queryParams: listItem.textContent });
      listItem.setAttribute('class', 'active');
      listItem.focus();
    }
  };
  /**
   * handleListKeydown function handles keyboard events while the cursor focus is on Result Set
   * <ul> element
   * @param    {e} accepts the event passed on keyboard key press
   * @return   {[Void]} function does not return anything.
   */
  handleListKeydown = (e) => {
    switch (e.keyCode) {
      case KeyCode.UP:
      case KeyCode.DOWN:
        this.handleKeyUpDown(e, KeyCode);

        break;
      case KeyCode.ESC:
        this.clear(e);
        break;
      case KeyCode.RETURN:
        if (
          this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
          (this.isYIB2C || this.isSWADB2C)
        ) {
          this.setState({ autoSuggestTextToggle: true });
          searchClickAnalytics(e.target.textContent);
        }
        if (this.searchPage) {
          this.publishSubmitDataForSearchResults(e.target.textContent);
        } else {
          this.navigateToNewSearchPage(e.target.textContent);
        }
        break;
      default:
        return;
    }
  };
  /**
   * handleInputKeydown function handles keyboard events while the cursor focus is on Search Input Field
   * @param    {e} accepts the event passed on keyboard key press
   * @return   {[Void]} function does not return anything
   */
  handleInputKeydown = (e) => {
    switch (e.keyCode) {
      case KeyCode.UP:
      case KeyCode.DOWN:
        e.preventDefault();
        if (!this.state.autoSuggestResults.length) {
          return;
        }

        const listItem = this.results.firstElementChild;

        listItem && listItem.focus();
        this.setState({ queryParams: listItem.textContent });
        listItem.setAttribute('class', 'active');
        listItem.focus();

        break;
      case KeyCode.ESC:
        this.clear(e);
        break;
      case KeyCode.TAB:
        e.keyCode === KeyCode.ESC && e.preventDefault();

        break;
      case KeyCode.BACKSPACE:
        if (
          this.searchPage === false &&
          this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
          (this.isYIB2C || this.isSWADB2C)
        ) {
          if (e.target.value.length === 0) {
            this.clear(e);
          }
        }
        break;
      case KeyCode.RETURN:
        if (
          this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
          (this.isYIB2C || this.isSWADB2C)
        ) {
          this.setState({ autoSuggestTextToggle: true });
        }
        if ((e.target.value || '').trim().length >= this.minCharacters) {
          if (
            this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
            (this.isYIB2C || this.isSWADB2C)
          ) {
            searchClickAnalytics(e.target.value);
          }

          if (this.searchPage) {
            e.preventDefault();
            this.publishSubmitDataForSearchResults(e.target.value);
          } else {
            this.navigateToNewSearchPage(e.target.textContent);
          }
        } else {
          e.preventDefault();
        }
        break;
      default:
        return;
    }
  };
  /**
   * updateQueryParams function updates the search parameter and if search input's length matches
   * minimum character length, it calls updateResults function to update AutoSuggest Search Results
   * @param    {[query]} accepts query text as updated in input field
   * @return   {[Void]} function does not return anything.
   */
  updateQueryParams = (query) => {
    const regexPattern = /^(?!.*[<>]).*$/;
    if (!regexPattern.test(query)) {
      return;
    } else {
      if (
        this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
        (this.isYIB2C || this.isSWADB2C)
      ) {
        this.setState({ autoSuggestTextToggle: false });
      }
      if (this.isYIB2C || this.isSWADB2C) {
        this.childToParent(true);
      }

      this.setState({
        queryParams: query,
      });

      const updateQueryResults = this.analyticsEnabled === 'true' ? this.updateAnalyticsResults : this.updateResults;

      query.length >= this.minCharacters && (this.state.desktopView || this.autoSuggestMobileView)
        ? updateQueryResults(query)
        : this.isYIB2C || this.isSWADB2C
        ? this.setState({
            autoSuggestResults: [],
            getKeyword: query,
            resultsReady: false,
          })
        : this.setState({
            autoSuggestResults: [],
            resultsReady: false,
          });

      if (
        this.searchPage === false &&
        this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
        (this.isYIB2C || this.isSWADB2C)
      ) {
        if (query.length >= this.minCharacters) {
          this.childToParent(true);
        } else {
          this.childToParent(false);
        }
      }
    }
  };
  /**
   * navigateToNewSearchPage function navigates the user to new Search page based on value passed
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  navigateToNewSearchPage = (newParams) => {
    newParams = newParams === '' ? this.searchInputRef.current.value : newParams;
    if (canUseDOM()) {
      window.location.href = (this.data && this.data.searchPageUrl) + '?q=' + encodeURI(newParams);
    }
  };
  /**
   * componentDidMount LifeCycle Method to -
   * i) subscribe to windowResize event to check if current view is Mobile or not.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  componentDidMount() {
    if (canUseDOM()) {
      this.currUser = getLoggedInUser();
      window.PubSub.subscribe('windowResize', this.updateView);
    }
  }
  /**
   * componentWillUnmount LifeCycle Method to unsubscribe to windowResize event.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */

  componentWillUnmount() {
    window.PubSub.unsubscribe('windowResize');
  }

  /**
   * componentWillMount LifeCycle Method sets the state if view is Desktop or not
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  componentWillMount() {
    this.updateView();
  }

  /**
   * updateView function updates if the current view is desktopView or not.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */
  updateView = () => {
    this.setState({
      desktopView: !detectMobile(),
      autoSuggestMobileView: this.autoSuggestMobileView,
    });
  };
  /**
   * return true or false if the cookie bar is visible on the page.
   */
  isCookiePolicyBarVisible = () => {
    return document.body.contains(document.querySelector('.c-cookies'));
  };

  /**
   * This method pass search key to subscriber for search results
   */

  publishSubmitDataForSearchResults = (val) => {
    this.setState(
      {
        queryParams: val,
      },
      () => {
        window.PubSub.publish(UIConfig.search.resultsSubmit, {
          btnClicked: true,
          value: val,
        });
      },
    );
  };

  /**
   * This method is called right before page is directed to search.html
   * It sets searchInfo in localStorage signifying the search was initiated as a result of submit button press
   */
  submitHandler = (e) => {
    const searchInfo = { queryType: 'genericSearch' };
    window.localStorage.setItem('searchInfo', JSON.stringify(searchInfo));
    if (isMatchTenant(UIConfig.tenants.ya)) {
      GTMData.push(UIConfig.ga4Constants.WEBSITE_SEARCH, { search_term: this.searchInputRef.current.value });
    }
    if (this.searchPage) {
      e.preventDefault();
      this.publishSubmitDataForSearchResults(this.searchInputRef.current.value);
    } else {
      this.navigateToNewSearchPage(this.searchInputRef.current.value);
    }
  };

  /**
   * renderSearchInputContainer function renders markup for Search Input container of Auto Suggest
   * @param    {data} function accepts data props of Auto Suggest Class
   * @return   {HTML} function returns HTML markup
   */
  renderSearchInputContainer = () => {
    if (!this.data) {
      return null;
    }
    return (
      <form name="autoSuggestForm" className="input-container" action={this.data.searchPageUrl}>
        {this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
          (this.isYIB2C || this.isSWADB2C) &&
          (this.data.submitBtnLabel && this.state.desktopView ? (
            <div className="btn btn-secondary">
              <DynamicContent
                tagName="button"
                attrs={{
                  disabled: this.state.queryParams && this.state.queryParams.trim().length < this.minCharacters,
                  type: 'submit',
                  onClick: this.submitHandler,
                  class: 'search-trigger',
                }}
                innerHtml={this.data.submitBtnLabel}
              />
            </div>
          ) : this.state.desktopView !== true &&
            this.props.variant === UIConfig.commonVariant.searchVariant &&
            this.searchPage &&
            this.state.queryParams.length > 0 ? (
            <button
              onClick={(e) => this.clear(e)}
              aria-label={this.data.resetAriaLabel}
              type="reset"
              className={`btn-reset search-btn-reset search-mobile-detect ${
                this.state.queryParams.length ? '' : 'visibility-hidden'
              }`}
            >
              {this.searchSettingVariant !== UIConfig.commonVariant.autoSuggestSearchVariant ? (
                <SvgSprite id={'icn-close'} />
              ) : (
                ''
              )}
            </button>
          ) : (
            <button
              className="btn btn-secondary btn-submit search-trigger"
              aria-label={this.data.searchAriaLabel}
              onClick={this.submitHandler}
              disabled={this.state.queryParams && this.state.queryParams.trim().length < this.minCharacters}
              type="button"
            ></button>
          ))}

        {this.data.tenantId !== UIConfig.yasArenaB2CTenant && (
          <DynamicContent
            tagName="label"
            attrs={{
              className: 'input-placeholder',
            }}
            innerHtml={this.data.placeHolder}
          />
        )}
        <input
          ref={this.searchInputRef}
          onFocus={moveCaretAtEnd}
          type="search"
          spellCheck="false"
          autoComplete="off"
          className="autocomplete form-input heading-3"
          name="q"
          placeholder={this.data.placeHolder}
          value={this.state.queryParams || ''}
          onChange={(e) => this.updateQueryParams(e.target.value)}
          onKeyDown={(e) => this.handleInputKeydown(e)}
        />
        <button
          onClick={(e) => this.clear(e)}
          aria-label={this.data.resetAriaLabel}
          type="reset"
          className={`btn-reset ${this.isYIB2C || this.isSWADB2C ? 'search-btn-reset' : ''} ${
            this.state.queryParams.length ? '' : 'visibility-hidden'
          }`}
        >
          {this.searchSettingVariant !== UIConfig.commonVariant.autoSuggestSearchVariant ? (
            <SvgSprite id={'icn-close'} />
          ) : (
            ''
          )}
        </button>
        {this.searchSettingVariant !== UIConfig.commonVariant.autoSuggestSearchVariant &&
          (this.data.submitBtnLabel && this.state.desktopView ? (
            <div className="btn btn-secondary">
              <DynamicContent
                tagName="button"
                attrs={{
                  disabled: this.state.queryParams && this.state.queryParams.trim().length < this.minCharacters,
                  type: 'submit',
                  onClick: this.submitHandler,
                  class: 'search-trigger',
                }}
                innerHtml={this.data.submitBtnLabel}
              />
            </div>
          ) : (
            this.searchSettingVariant !== UIConfig.commonVariant.autoSuggestSearchVariant && (
              <button
                className="btn btn-secondary btn-submit search-trigger"
                aria-label={this.data.searchAriaLabel}
                onClick={this.submitHandler}
                disabled={this.state.queryParams && this.state.queryParams.trim().length < this.minCharacters}
                type="button"
              >
                <SvgSprite
                  id={
                    (this.props.data.additionalProperty && this.props.data.additionalProperty.searchIcon) ||
                    'icn-search-white'
                  }
                />
              </button>
            )
          ))}
      </form>
    );
  };
  /**
   * renderAutoSuggestResults function contains markup for Results container of Auto Suggest
   * @param    {data} function accepts data props of Auto Suggest Class
   * @return   {HTML} function returns HTML markup
   */

  getHighlightedText = (text, setkeyword) => {
    // search text user inputs
    const value = setkeyword;
    if (value === '' || value == null || value === 0) {
      return <div>{text}</div>;
    } else {
      // split the search value
      const words = value.split(/\s+/g).filter((word) => word.length);

      // join if search value has more than 1 word
      const pattern = words.join('|');

      const re = new RegExp(pattern, 'gi');
      const children = [];

      let before,
        highlighted,
        match,
        pos = 0;
      // match using RegExp with my text
      const matches = text.match(re);

      if (matches != null) {
        // loop all the matches
        for (match of matches) {
          match = re.exec(text);

          if (pos < match.index) {
            // before has all the text before the word
            // that has to highlighted
            before = text.substring(pos, match.index);

            if (before.length) {
              children.push(before);
            }
          }
          highlighted = <span className="li-highlight-text">{match[0]}</span>;
          // text is highlighted
          children.push(highlighted);

          pos = match.index + match[0].length;
        }
      }
      if (pos < text.length) {
        // text after the highlighted part
        const last = text.substring(pos);
        children.push(last);
      }
      // children array will have the entire text
      return <>{children}</>;
    }
  };

  renderAutoSuggestResults = () => {
    return this.state.autoSuggestResults.length && (this.state.desktopView || this.autoSuggestMobileView) ? (
      <div className="results-container">
        {this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant &&
          (this.isYIB2C || this.isSWADB2C) && (
            <div
              className={`autosuggest-list-text ${this.state.autoSuggestTextToggle ? 'auto-suggest-text-hide' : ''}`}
            >
              {this.customSuggestionText}
            </div>
          )}
        <ul
          className="autosuggest-list body-copy-4"
          role="listbox"
          ref={(ul) => {
            this.results = ul;
          }}
          onKeyDown={(e) => this.handleListKeydown(e)}
        >
          {this.state.autoSuggestResults.map((item, index) => (
            <li
              role="option"
              tabIndex="0"
              aria-selected="false"
              key={index}
              onClick={this.clickSearchResults.bind(null, index, item.value)}
            >
              {(this.searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant && this.isYIB2C) ||
              this.isSWADB2C
                ? this.getHighlightedText(item.value, this.state.getKeyword)
                : item.value}
            </li>
          ))}
        </ul>
      </div>
    ) : null;
  };

  render() {
    try {
      return (
        <div className="autocomplete-wrapper">
          {this.renderSearchInputContainer()}
          {this.renderAutoSuggestResults()}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'AutoSuggest');
    }
  }
}

/**
 * basic structure of data contract
 */
AutoSuggest.propTypes = {
  data: PropTypes.shape({
    coveoKeyMap: PropTypes.object.isRequired,
    autoSuggestLimit: PropTypes.number.isRequired,
    placeholder: PropTypes.string,
  }),
};

export default AutoSuggest;
