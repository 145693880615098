import React from 'react';
import Input from '../base/input/input-component';
import InputFile from '../base/input/input-file-component';
import Select from '../base/select/select-component';
import moment from 'moment';
import InputDate from '../base/input/input-date-component';
import UIConfig from '../../../common/UIConfig';
import SelectWithText from '../base/select-with-text/select-with-text-component';
import { isANPToANPUpgradation, isTicketUpgradePayment } from '../../../common/utility';

const AnnualPassHolder = (props) => {
  const mapper = {
    Email: Input,
    Text: Input,
    Number: Input,
    SelectWithText: SelectWithText,
    Date: Input,
    Date2: InputDate,
    Select: Select,
    File: InputFile,
  };

  let countryMapper = {},
    nationalityMapper = {},
    genderMapper = {},
    emiratesMapper = {};

  /**
   * formBuilder, will create the Form from the elements array
   * @param    {mapper, fields} mapper object, and fields array
   * @return   {Void} JSX for Form Field
   */
  const formBuilder = (mapper, field, reset, index) => {
    const form = () => {
      const Field = mapper[field.type];
      field &&
        field['data-name'] &&
        field['data-name'].toLowerCase() === UIConfig.guestCheckoutForm.country &&
        field.value === '' &&
        (field.value = props.detectedCountry);
      if (field && field.isChildField) {
        const parentField = props.data.filter((f) => f['data-name'] === field.parentFieldId.toLowerCase())[0];
        field.hide = parentField.value !== field.parentFieldValue;
        if (field.hide) {
          field.value = '';
          props.toggleRules(field, 'remove');
        } else {
          props.toggleRules(field, 'add');
        }
      }
      if (Field && !field.hide) {
        let annualPassFormDisableFields = UIConfig?.parksTicketUpgrade?.annualPassFormDisableFields?.includes(
          field['data-name']?.toLowerCase(),
        );
        if (
          (isTicketUpgradePayment() && isANPToANPUpgradation() && annualPassFormDisableFields) ||
          (props.isRenewAnnualPass && field.aPRenewReadOnly) ||
          field.readonly
        ) {
          return (
            <div className="disabled">
              <Field
                key={index}
                data={field}
                onChange={props.onFieldChange}
                onBlur={props.onFieldBlur}
                onFocus={props.onFocus}
                reset={reset}
                onReset={props.onReset}
                controlled={true}
                isRenewAnnualPass={props.isRenewAnnualPass}
                photoError={props.photoError}
                isTabIndex={-1}
              />
            </div>
          );
        } else {
          return (
            <Field
              key={index}
              data={field}
              onChange={props.onFieldChange}
              onBlur={props.onFieldBlur}
              onFocus={props.onFocus}
              reset={reset}
              onReset={props.onReset}
              controlled={true}
              isRenewAnnualPass={props.isRenewAnnualPass}
              photoError={props.photoError}
              emailValidated={props.emailValidated}
            />
          );
        }
      }
    };
    return form();
  };

  const createMapper = (options) => {
    const obj = {};
    options.forEach((option) => {
      obj[option.value] = option.text;
    });
    return obj;
  };

  const displayDetail = (field, value, i, disabledAriaLabel) => {
    if (field.type === 'Select') {
      if (field['data-name'] === 'country' && Object.getOwnPropertyNames(countryMapper).length === 0) {
        countryMapper = createMapper(field.options);
        value = countryMapper[value];
      } else if (field['data-name'] === 'nationality' && Object.getOwnPropertyNames(nationalityMapper).length === 0) {
        nationalityMapper = createMapper(field.options);
        value = nationalityMapper[value];
      } else if (field['data-name'] === 'gender' && Object.getOwnPropertyNames(genderMapper).length === 0) {
        genderMapper = createMapper(field.options);
        value = genderMapper[value];
      } else if (
        field['data-name'] === UIConfig.guestCheckoutForm.emirate &&
        Object.getOwnPropertyNames(emiratesMapper).length === 0
      ) {
        emiratesMapper = createMapper(field.options);
        value = emiratesMapper[value];
      }
    } else if (field.type === 'Date2' && value) {
      value = props.isMobile
        ? moment(value, UIConfig.b2c.profile.dateFormat).format(UIConfig.b2c.profile.dateDisplayFormat)
        : value;
    } else if (field.type === UIConfig.guestCheckoutForm.File) {
      return (
        <InputFile
          key={i}
          data={field}
          onChange={props.onFieldChange}
          onBlur={props.onFieldBlur}
          onReset={props.onReset}
          controlled={true}
          isRenewAnnualPass={props.isRenewAnnualPass}
          tabIndex={props.mode === 'display' ? -1 : 0}
          photoError={props.photoError}
        />
      );
    }
    if (field && field.isChildField) {
      const parentField = props.data.filter((f) => f['data-name'] === field.parentFieldId.toLowerCase())[0];
      field.hide = parentField.value !== field.parentFieldValue;
      if (field.hide) {
        field.value = '';
        props.toggleRules(field, 'remove');
      } else {
        props.toggleRules(field, 'add');
      }
    }
    let displayField = '';
    if (!field.hide) {
      displayField = (
        <div className="form-element" key={i}>
          <span
            className="label"
            aria-label={
              field.label.replace(/\*/g, ' ') +
              (disabledAriaLabel ? disabledAriaLabel : ' field is disabled, Please login to enable')
            }
          >
            {field.label}
          </span>
          <div className="value" type={field.type}>
            {value}
          </div>
        </div>
      );
    }

    return displayField;
  };

  const data = props.data;
  const reset = new Date();

  const createForm = (data) => {
    if (props.mode === 'edit') {
      return data.map((item, i) => {
        return formBuilder(mapper, item, reset, i);
      });
    } else if (props.mode === 'display') {
      return data
        .filter((item) => item !== 'isPrimaryForm')
        .map((item, i) => {
          const itemValue = item.value ? item.value : '';
          return displayDetail(item, itemValue, i, props.disabledAriaLabel);
        });
    }
  };
  return (
    <div
      className="c-pass-holder"
      data-is-summary="false"
      aria-hidden={!props.isOpenSection}
      id={`accordion_body_${props.formIndex}`}
      aria-labelledby={`accordion_header_${props.formIndex}`}
    >
      {createForm(data)}
    </div>
  );
};
export default AnnualPassHolder;
