/*
 * Paypal-component.js
 * This file contains code for PayPal Payment Component
 * @licensor  Miral
 */
import React from 'react';
import { checkoutAnalytics, PaymentAnalytics } from '../../../../common/analytics-events';
import { Logging } from '../../../../common/logger';
import { CartService, OrderService, PayPalServices, ServiceConfig } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  addLoaderOverlay,
  checkAnonymousCart,
  getErrorMap,
  getLocale,
  getLoggedInUser,
  getPromoCode,
  getUserAgent,
  massageMobileData,
  parseQueryString,
  removeGuestUser,
  removeLoaderOverlay,
  removePromoCode,
  roundToTwoPlaces,
  setOrderIdpromoCode,
  isAnnualPassCase,
  getSessionStorage,
  createAnnualPassData,
  checkInlineSignup,
  setYasId,
  getAnonymousCart,
  isLoggedInUser,
  getMainObject,
  resolvePath,
  getAPIStateParam,
  getRecipientDetails,
  setSessionStorage,
  generateGUID,
  getLocalStorageByKey,
  getMembershipDetails,
  scrollToView,
  isB2B,
  isMatchTenant,
  toLowerCase,
  isTicketUpgradePayment,
  getAnpGuestDetailsForTuJourney,
  getAnpTicketHoldersForTuJourney,
  backToUpgradePage,
  isANPUpgradation,
} from '../../../../common/utility';
import { savePaymentFailureStatus } from '../../../../common/b2b-purchase-journey-utility';
import {
  checkAnnualPassError,
  checkYAEventId,
  consentRequest,
  scrollToServerError,
  checkAnnualUpgradeError,
} from '../utils';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { checkTenant } from '../../../../common/utility/tenantsUtils';
import { checkParksTenants, getPurchaseAllowed, isEmpty } from '../../../../common/utility/helpers';
import GTMData from '../../b2c-purchase-journey/gtm-data';
import { setReconcilRequestAction } from '../../../../utils/paymentUtils';
/**
 * defualt variables declared
 *
 */

let config = {
    amount: 0,
    locale: getLocale(),
    deviceData: null,
    tenantId: ServiceConfig.getTenantId(),
    serviceKey: {
      sendTransaction: 'sendTransaction',
      createOrder: 'createOrder',
      getToken: 'getToken',
      default: 'default',
    },
    error: {
      code: 'default',
      text: '',
    },
    paypalCartData: '',
  },
  showError = () => {
    //Please comment purpose of the empty block
  };

const userAgent = getUserAgent();
let localOrderId;

//Send Transaction API call
const doTransaction = (data) => {
  const { services, componentName } = config.data;
  const userPromoCode = `${data.reservationCode}_${getPromoCode()}`;
  setOrderIdpromoCode(userPromoCode);
  removePromoCode();
  if ((data.annualPassRequest && data.annualPassRequest.isAnnualRenew) || data.isTicketUpgradeRequest) {
    data.reservationCode = '';
  }
  const url = `${services.payPalSendOrder.url}`;
  return PayPalServices.sendOrder(componentName, data, url)
    .then((res) => {
      if (res) {
        Logging(res, componentName ? componentName : 'Paypal-transaction', true, 'Paypal transaction success');
        return res.data.id;
      }
    })
    .catch((response) => {
      console.log(response, 'error');
    });
};

const createAnnonymousOrderBody = (payLoad, res, config, deleteAnonymousCart) => {
  payLoad.orderId = res.data.orderID;
  payLoad.reservationCode = res.data.reservationCode;
  payLoad.amount = res.data.totalPrice;
  payLoad.userId = getAnonymousCart().cart.reservationOwner.email;
  Logging(
    res,
    config.data && config.data.componentName ? config.data.componentName : 'create-order',
    true,
    'Paypal create order success',
  );
  deleteAnonymousCart && localStorage.removeItem('anonymousCart');
  return doTransaction(payLoad);
};

// Send Transaction API call B2B
const b2bDoTransaction = (data) => {
  const { services, componentName, B2B_isCreditSettlement, B2B_isAdvanceTopUp } = config.data;

  const userAgent = getUserAgent();
  const sendTransactionURL =
    B2B_isCreditSettlement || B2B_isAdvanceTopUp ? services.sendTransactionAdcs.url : services.sendTransaction.url;
  const encryptionParam = parseQueryString('notEncrypted') ? '?isEncrypted=false' : '';
  return PayPalServices.sendPayPalTrans(
    componentName,
    data,
    sendTransactionURL + encryptionParam,
    true,
    UIConfig.loader.defaultPreLoaderTarget,
  )
    .then((res) => {
      if (res) {
        Logging(res, componentName ? componentName : 'Paypal-transaction', true, 'Paypal transaction success');
        return res.data.id;
      }
    })
    .catch((response) => {
      const cmsErrors = services.sendTransaction.error,
        errorData = getErrorMap(config.serviceKey.sendTransaction, cmsErrors, false, response.error, null);
      PaymentAnalytics && PaymentAnalytics(errorData.code, errorData.text);

      const status = '?status=false';
      localStorage.setItem('error_message', JSON.stringify(errorData));

      if (getLoggedInUser().tenantID === 'ALLB2B' && data.reservationCode) {
        savePaymentFailureStatus(
          {
            reservationCode: data.reservationCode,
            paymentStatus: services.UpdatePaymentStatus.paymentErrorCode[9991],
          },
          services.UpdatePaymentStatus.url,
        )
          .then(() => {
            this.redirectToThankYouPage(status);
          })
          .catch(() => {
            this.redirectToThankYouPage(status);
          });
      } else {
        if (!userAgent) {
          this.redirectToThankYouPage(status);
        } else {
          window.JSbridge.nativeCallback(
            massageMobileData({
              status: true,
              orderID: data.orderId,
              ref: 'PayPal',
            }),
          );
        }
      }
    });
};

const getCartData = () => {
  window.PubSub.subscribe(UIConfig.events.ONCARTUPDATE, (msg, data) => {
    config.paypalCartData = { cart: data.cart };
    config.amount = this.convertAedToUsd(data.cart.grossPrice, config.data.conversionRate);
  });
};

const updateRecipientDetails = async (cartData) => {
  if (!cartData) {
    return;
  }
  const recipientDetails = getRecipientDetails();
  cartData.personalisedGiftObj = recipientDetails;
  await CartService.updateCart(
    {
      cart: cartData,
    },
    'RecipientDetails',
    config.data.services.updateCart.url,
  );
};

class Paypal extends React.Component {
  constructor(props) {
    super(props);
    this.paypalConfig = this.props.propsData.paymentOptions.payPalConfig;
    const { clientId, currency, components, intent, disableFunding } = this.paypalConfig;
    this.disableFunding = disableFunding.toLowerCase();
    this.isB2B = getLoggedInUser().tenantID === 'ALLB2B';
    this.state = {
      locale: getLocale(),
      styles: {
        layout: 'vertical',
        shape: 'pill',
        width: '100%',
      },
      initialOptions: {
        'client-id': clientId,
        currency: currency,
        components: components,
        intent: intent,
      },
      isNotValid: true,
      isValid: false,
      B2BPaymentEnable: false,
      isPaypalBtnDisable: true,
    };
  }

  //Converting USD to AED
  convertAedToUsd = (price, exhngRate) => {
    return roundToTwoPlaces(price / exhngRate);
  };

  //Redirecting to thank you page
  redirectToThankYouPage = (data) => {
    removeGuestUser();
    let isRenewAnnualPass = config.data.pageVariant === 'annualPassRenew';
    if (isRenewAnnualPass) {
      sessionStorage.removeItem('cartForAnnualPassRenewal');
      sessionStorage.removeItem(UIConfig.payloadForRenew);
      sessionStorage.removeItem('annualPassRenewalData');
    }
    canUseDOM() && localStorage.removeItem('isOrderHistoryPayment');
    canUseDOM() && localStorage.removeItem('yasIdUserData');
    let annualPassRenew = isRenewAnnualPass ? '&isRenewAnnualPass=true' : '';
    const ticketUpgrade = isTicketUpgradePayment() ? '&isTicketUpgrade=true' : '';
    window.location.replace(config.data.thankYouPageUrl + data + annualPassRenew + ticketUpgrade);
  };

  getGuestUserStatus = () => {
    const cart = getAnonymousCart();
    if (cart) {
      return cart;
    }
    return false;
  };

  yasIdAutoLogin = (signInURL, signInData, res, payLoad) => {
    return OrderService.yasIdAutoLogin(
      'YasIdAutoLogin',
      signInURL,
      UIConfig.loader.defaultPreLoaderTarget,
      signInData,
    ).then(() => {
      Logging(
        res,
        config.data && config.data.componentName ? config.data.componentName : 'create-order',
        true,
        'Paypal create order success',
      );
      return doTransaction(payLoad);
    });
  };

  getAnnualPassRequest = (isRenewAnnualPass, cartData, isTicketUpgrade) => {
    const annualPassTicketHolders = isTicketUpgrade
      ? getAnpTicketHoldersForTuJourney()
      : createAnnualPassData({ cartData });

    const {
      emailTemplates: {
        apMainPassholderPurchaseEmailTemplateID,
        apMainPassholderRenewEmailTemplateID,
        apAddPassholderNewUserPurchaseEmailTemplateID,
        apAddPassholderNewUserRenewEmailTemplateID,
        apAddPassholderRenewEmailTemplateID,
        apAddPassholderPurchaseEmailTemplateID,
      },
    } = config.data;

    return {
      isAnnualRenew: isRenewAnnualPass,
      emailTemplateIdAnnualPass: isRenewAnnualPass
        ? apMainPassholderRenewEmailTemplateID
        : apMainPassholderPurchaseEmailTemplateID,
      emailTemplateIdWithYasId: isRenewAnnualPass
        ? apAddPassholderRenewEmailTemplateID
        : apAddPassholderPurchaseEmailTemplateID,
      emailTemplateIdWithNoYasId: isRenewAnnualPass
        ? apAddPassholderNewUserRenewEmailTemplateID
        : apAddPassholderNewUserPurchaseEmailTemplateID,
      ticketHolders: annualPassTicketHolders,
    };
  };

  //Creating Order in omini
  createOrder = (payLoad, cartData) => {
    if (isTicketUpgradePayment()) {
      const tenantId = getLoggedInUser().tenantID || resolvePath(getMainObject(), 'tenantID');
      const ticketUpgradesUrl = config.data.services.ticketUpgrades?.url?.replace('{0}', tenantId);
      let ticketUpgradePayload = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);
      const yasId = getLoggedInUser()?.yasId;
      if (yasId && ticketUpgradePayload && ticketUpgradePayload.yasId === null) {
        ticketUpgradePayload.loggedAccount.yasId = yasId;
        ticketUpgradePayload.yasId = yasId;
      }
      if (ticketUpgradePayload?.productItemType === UIConfig.parksTicketUpgrade.annualPassType) {
        const guestDetails = getAnpGuestDetailsForTuJourney();
        if (guestDetails && guestDetails?.length > 0 && ticketUpgradePayload.items?.length > 0) {
          ticketUpgradePayload.items.map((obj, index) => {
            return (obj.ticketHolderDetails = guestDetails[index]);
          });
        }
        // Set the doTransaction payLoad
        payLoad.annualPassRequest = this.getAnnualPassRequest(false, cartData, true);
      }

      return OrderService.createTicketUpgradeOrder(
        ticketUpgradesUrl,
        UIConfig.loader.defaultPreLoaderTarget,
        ticketUpgradePayload,
        config.data.services.checkTermsCtdnApi?.url,
      )
        .then((res) => {
          const { sale } = res?.data;
          if (sale?.ak) {
            payLoad.currencyCode = sale?.total?.currency;
            payLoad.redirectUrl = window.location.href;
            payLoad.orderId = sale?.ak;
            payLoad.reservationCode = sale?.reservationcode?.ak;
            payLoad.amount = sale?.total?.gross;
            payLoad.userId = ticketUpgradePayload?.loggedAccount?.email;
            payLoad.isTicketUpgradeRequest = true;
            localOrderId = sale?.ak;

            let mainObj = getMainObject(),
              idToken = resolvePath(res.data, 'id_token'),
              defaultStateVal = getAPIStateParam(),
              signInData = { state: defaultStateVal, id_token: idToken },
              signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
            // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
            if (signInURL && idToken) {
              this.yasIdAutoLogin(signInURL, signInData, res, payLoad);
            } else {
              Logging(
                res,
                config.data && config.data.componentName ? config.data.componentName : 'create-order',
                true,
                'Paypal create order success',
              );
              return doTransaction(payLoad);
            }
          } else {
            return backToUpgradePage();
          }
        })
        .catch((response) => {
          if (isTicketUpgradePayment()) {
            return backToUpgradePage();
          }
          if (checkInlineSignup()) {
            showError('createOrderYasId', response.error);
          } else {
            showError(config.serviceKey.createOrder, response.error);
          }
          PaymentAnalytics && PaymentAnalytics(response.error.code, response.error.text);
        });
    } else {
      const isRenewAnnualPass = config.data.pageVariant === 'annualPassRenew';
      if (isLoggedInUser()) {
        const cartData = isRenewAnnualPass
          ? JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart))
          : JSON.parse(getSessionStorage(UIConfig.annualPassPurchaseCart));
        if (cartData) {
          const isAnnualPass = isAnnualPassCase(cartData, true);
          if (isAnnualPass) {
            payLoad.annualPassRequest = this.getAnnualPassRequest(isRenewAnnualPass, cartData, false);
          }
        }
      }

      if (!isLoggedInUser()) {
        cartData =
          checkInlineSignup() || localStorage.getItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED)
            ? getAnonymousCart()
            : cartData;
        cartData.cart.reservationOwner =
          this.getGuestUserStatus().cart.reservationOwner !== null
            ? this.getGuestUserStatus().cart.reservationOwner
            : null;
      }
      if (checkAnonymousCart()) {
        let extraParams = null;
        const serviceUrl = checkInlineSignup()
          ? config.data.services.createOrderYasId.url
          : config.data.services.createAnnonymousOrder.url;
        let mainObj = getMainObject(),
          autoLoginStatus = resolvePath(mainObj, 'additionalProperty.enableAutoLogin'),
          autoLoginParam = `isAutoLogin=${autoLoginStatus}`;

        if (autoLoginStatus) {
          if (extraParams) {
            extraParams += `&${autoLoginParam}`;
          } else {
            extraParams = autoLoginParam;
          }
        }

        let bioConsentStatus = sessionStorage.getItem('isMultiParkConsent') === 'true';
        let bioConsentParam = bioConsentStatus ? 'bioConsent=true' : '';

        if (bioConsentStatus) {
          if (extraParams) {
            extraParams += `&${bioConsentParam}`;
          } else {
            extraParams = bioConsentParam;
          }
        }

        return OrderService.createAnnonymousOrder(
          config.data.componentName,
          serviceUrl,
          UIConfig.loader.defaultPreLoaderTarget,
          cartData,
          extraParams,
          config.data.services.updateCart,
        )
          .then(function(res) {
            if (res.data.orderID) {
              payLoad.currencyCode = config.data.currency;
              payLoad.promoCode = getPromoCode();
              payLoad.redirectUrl = window.location.href;
              setYasId(res.data);
              let mainObj = getMainObject(),
                idToken = resolvePath(res.data, 'id_token'),
                defaultStateVal = getAPIStateParam(),
                signInData = { state: defaultStateVal, id_token: idToken },
                signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
              // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
              if (signInURL && idToken) {
                this.yasIdAutoLogin(signInURL, signInData, res, payLoad);
              } else {
                return createAnnonymousOrderBody(payLoad, res, config, false);
              }
            }
          })
          .catch((response) => {
            if (checkInlineSignup()) {
              showError('createOrderYasId', response.error);
            } else {
              showError(config.serviceKey.createOrder, response.error);
            }
            PaymentAnalytics && PaymentAnalytics(response.error.code, response.error.text);
          });
      } else {
        /* Send recipient details */
        updateRecipientDetails(cartData);
        return OrderService.createOrder(
          config.data.componentName,
          isRenewAnnualPass ? config.data.services.renewPass.url : config.data.services.createOrder.url,
          UIConfig.loader.defaultPreLoaderTarget,
          config.data.agentRefNum,
          config.data.dueDate,
          null,
          isRenewAnnualPass,
          config.data.services.getGuestProfile && config.data.services.getGuestProfile.url,
          null,
          config.data.services.updateCart,
          config.data.services,
        )
          .then(function(res) {
            if (res.data.orderID) {
              payLoad.promoCode = getPromoCode();
              payLoad.orderId = res.data.orderID;
              payLoad.reservationCode = res.data.reservationCode;
              payLoad.amount = res.data.totalPrice;
              payLoad.currencyCode = config.data.currency;
              payLoad.redirectUrl = window.location.href;
              Logging(
                res,
                config.data && config.data.componentName ? config.data.componentName : 'create-order',
                true,
                'Paypal create order success',
              );
              localOrderId = res.data.orderID;
              return doTransaction(payLoad);
            }
          })
          .catch((response) => {
            showError(config.serviceKey.createOrder, response.error);
            PaymentAnalytics && PaymentAnalytics(response.error.code, response.error.text);
          });
      }
    }
  };

  paypalErrorHandler = (error) => {
    if (error === null || error === '' || isEmpty(error)) {
      return;
    }
    if (error) {
      this.props.showPaypalError(error);
    }
  };

  verifyPayfortForm = () => {
    this.checkAllValidation(this.props.isEnabled);
  };

  checkFormValidation = () => {
    const err = [];
    const parentForm = document.querySelector('.guest-checkout-form-wrapper');
    parentForm &&
      parentForm
        .querySelectorAll('[aria-required=true]')
        .forEach((field) => (!field.value || field.value === 'false') && err.push(field.name));
    return err.length;
  };

  checkAllValidation = (isEnabled) => {
    const parentForm = document.querySelector('.guest-checkout-form-wrapper');
    if (
      !isEnabled ||
      this.checkFormValidation() ||
      document.getElementsByClassName('form-error--field').length ||
      (parentForm && parentForm.getElementsByClassName('error-msg').length) ||
      document.querySelectorAll('.yasid-email-error').length > 0
    ) {
      this.setState({ isNotValid: true, isValid: false });
    } else {
      this.setState({ isValid: true, isNotValid: false });
    }
  };

  componentWillReceiveProps(newProps) {
    this.checkAllValidation(newProps.isEnabled);
  }

  componentDidMount() {
    if (this.isB2B) {
      window.PubSub.subscribe('input_filled', (item, obj) => {
        this.setState({ B2BPaymentEnable: obj.isEnabled });
      });
    }
    getCartData();
    window.PubSub.subscribe('checkPayButtonStatus', this.verifyPayfortForm);
    this.checkAllValidation(this.props.isEnabled);
    config.data = this.props.payData;
    config.amount = this.convertAedToUsd(config.data.total, config.data.conversionRate);
    setTimeout(() => {
      this.setState({ isPaypalBtnDisable: false });
    }, 1000);
    const payInfo = getLocalStorageByKey(UIConfig.localStoreKeys.payment.paymentInfo);
    if (!payInfo) {
      const paypalPaymentInfo = {
        cmsData: this.props.propsData,
      };
      localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(paypalPaymentInfo));
    }
  }

  createOrderOmni = () => {
    // Using for GA
    if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
      const currencyId = JSON.parse(getSessionStorage('GA_CurrencyId')) || '';
      checkoutAnalytics(currencyId, 'payment', 'Paypal');
    }
    if (!isLoggedInUser()) {
      if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
        const serverErrorElement = document.getElementsByClassName('yasid-email-error server-error');

        if (serverErrorElement && serverErrorElement.length > 0) {
          // e && e.preventDefault();
          return false;
        }
      }

      const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
      // We are not converting session object, we are directly refering session object. It's not a boolean type so we are not using strict type checking.
      if (_isAddonAddedAfterBooking && _isAddonAddedAfterBooking === 'true') {
        window.PubSub.publish('dataPosted', {
          isDataPosted: true,
        });
      } else {
        document.getElementById('submit-btn').click();
      }
    } else if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
      // Send passenger details if user is logged in and tenant is YasIsland
      const loggedInUser = getLoggedInUser();
      const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');

      if (!_isAddonAddedAfterBooking || _isAddonAddedAfterBooking === 'false') {
        let userId = getSessionStorage('packageSummaryData');
        if (userId) userId = JSON.parse(userId).userId;
        const localStorageMem = getMembershipDetails('membershipData');
        const { segmentType, status, email } = localStorageMem;
        const isCorpUser = segmentType?.toLowerCase() === 'corporate' && status === 'enabled';
        const { phoneCountryCode, mobileNum } = loggedInUser;
        let passengerObject = {
          Title: 'Mr.',
          FirstName: loggedInUser.userName,
          LastName: loggedInUser.lastName,
          Email: loggedInUser.email,
          ...(isCorpUser && { CorporateEmail: email }),
          Phone: mobileNum ? `${phoneCountryCode} ${mobileNum}` : '',
          CountryOfResidence: loggedInUser.country,
          Nationality: loggedInUser.nationalityAlphaCode,
          UserId: userId,
          AuthToken: loggedInUser.idToken,
        };
        const specialRequests = getSessionStorage('specialRequests');
        if (specialRequests) {
          passengerObject.SpecialRequests = JSON.parse(specialRequests);
        }

        const PassengerDetailsOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify(passengerObject),
        };

        const passengerUrl = config.data.services.SetPassengerDetails.url.replace(
          '{cartId}',
          getSessionStorage('cartId'),
        );

        fetch(passengerUrl, PassengerDetailsOptions);
      }
    }

    const newsletterCheckbox = canUseDOM() && document.getElementById('paymentNewsletter')?.checked;
    if (newsletterCheckbox && isLoggedInUser()) {
      const { MarketingConsentUpdateAPI } = config.data.services;
      consentRequest(MarketingConsentUpdateAPI?.url);
    }
  };

  onApproverAction = (data) => {
    const { captureTransactionAdcs, payPalCapture } = this.props.propsData.services;
    const { B2B_isCreditSettlement, B2B_isAdvanceTopUp, pageVariant, orderID } = config.data;
    const approveUrl =
      B2B_isCreditSettlement || B2B_isAdvanceTopUp
        ? `${captureTransactionAdcs.url}?token=${data.orderID}&PayerID=${data.payerID}`
        : `${payPalCapture.url}?token=${data.orderID}&PayerID=${data.payerID}`;
    if (data) {
      let tenantId = '';
      addLoaderOverlay();
      setReconcilRequestAction();
      OrderService.getOrders(approveUrl, true, true)
        .then((response) => {
          const { orderDetail } = response.data;
          if (orderDetail) {
            this.paypalErrorHandler(orderDetail?.error);
            removeLoaderOverlay();
            let errLoader = document.querySelector('.loader');
            errLoader.length && errLoader.remove();
          } else {
            if (!userAgent) {
              const encryptionParam = parseQueryString('notEncrypted') ? '&notEncrypted=true' : '';
              if (isLoggedInUser()) {
                tenantId = getLoggedInUser().tenantID;
              } else {
                const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
                tenantId = resolvePath(getMainObj, 'tenantID', '').toLowerCase();
              }
              if (tenantId.toLowerCase() === UIConfig.YIB2C) {
                const _isAddonAddedAfterBooking = getSessionStorage('addonAddedAfterConfirmation');
                sessionStorage.removeItem('cartId');
                // We are not converting session object, we are directly refering session object. It's not a boolean type so we are not using strict type checking.
                if (_isAddonAddedAfterBooking === 'true') {
                  setSessionStorage('addonSuccessfullyAdded', 'true');
                }
                sessionStorage.removeItem('addonAddedAfterConfirmation');
                // Storing bookingId for after payment services i.e Upsell of addons
                setSessionStorage('bookingId', response.data.BookingId);
              }
              const orderData =
                '?' +
                checkYAEventId() +
                'status=true&ref=PayPal&oId=' +
                (getLoggedInUser().tenantID !== 'ALLB2B' ? btoa(data.orderID) : orderID) +
                '&isPage=' +
                pageVariant +
                encryptionParam;
              const queryParams =
                '?' +
                checkYAEventId() +
                'status=true&ref=PayPal&oId=' +
                (tenantId.toLowerCase() === UIConfig.YIB2C
                  ? btoa(response.data.BookingId)
                  : tenantId !== 'ALLB2B'
                  ? btoa(response.data.orderId)
                  : encodeURIComponent(response.data.orderId)) +
                encryptionParam;
              this.redirectToThankYouPage(
                checkTenant(UIConfig.tenants.b2b) && (B2B_isCreditSettlement || B2B_isAdvanceTopUp)
                  ? orderData
                  : queryParams,
              );
            } else {
              window.JSbridge.nativeCallback(
                massageMobileData({
                  status: true,
                  orderID: response.data.orderId,
                  ref: 'PayPal',
                }),
              );
            }
          }
        })
        .catch((error) => {
          if (error.error.code === '200') {
            const encryptionParam = parseQueryString('notEncrypted') ? '&notEncrypted=true' : '';
            const orderData =
              '?' +
              checkYAEventId() +
              'status=true&ref=PayPal&oId=' +
              localOrderId +
              '&isPage=' +
              pageVariant +
              encryptionParam;
            this.redirectToThankYouPage(orderData);
          } else {
            removeLoaderOverlay();
            this.paypalErrorHandler(error);
          }
        });
      localStorage.removeItem('EA_orderId');
    }
  };

  onSubmit = () => {
    let hasAnnualPassError = false;
    if (isTicketUpgradePayment() && isANPUpgradation()) {
      if (checkAnnualUpgradeError()) {
        const updatedAnnualPassError = sessionStorage.getItem('annualPassFormSuccess') === 'false' ? true : false;
        if (!updatedAnnualPassError) {
          hasAnnualPassError = false;
        } else {
          hasAnnualPassError = true;
        }
      }
    } else {
      if (checkAnnualPassError(this.props.cartData, this.onSubmit)) {
        hasAnnualPassError = true;
      }
    }
    if (isMatchTenant(UIConfig.tenants.yi)) {
      GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
        name: toLowerCase(`${UIConfig.paymentOption.paypal} - ${UIConfig.paymentOption.paypal}`),
        [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(UIConfig.paymentOption.paypal),
        category: toLowerCase(UIConfig.ga4Constants.PAYMENT),
      });
    }
    if (isMatchTenant(UIConfig.YIB2C)) {
      try {
        if (isLoggedInUser()) {
          const loggedInUser = getLoggedInUser();
          setSessionStorage('userName', loggedInUser.userName);
        } else {
          let userName = document.querySelector('input[name="FirstName"]')?.value;

          setSessionStorage('userName', userName);
        }
      } catch (error) {}
    }
    if (
      !this.props.isEnabled ||
      this.state.isNotValid ||
      (!config?.data?.agentRefNum && this.isB2B) ||
      hasAnnualPassError
    ) {
      if (
        !this.state.B2BPaymentEnable &&
        this.isB2B &&
        document.getElementsByClassName('c-my-cart-order--agent-reference')[0]
      ) {
        scrollToView({ errorBlock: document.getElementsByClassName('c-my-cart-order--agent-reference')[0] }, true);
        !this.props.isEnabled && this.props.showTnCError(true);
        return;
      }
      if (!this.isB2B) {
        window.PubSub.publish('initiatePaymentSubmitValidation');
      }
      scrollToServerError();
      if (this.isB2B) {
        scrollToServerError('', 'error-msg');
        !this.props.hasTnCError && this.props.showTnCError(true);
        return;
        // scrollToFormErrorOrSuccess('error-msg');
      } else {
        scrollToServerError();
      }
      !this.props.isEnabled && this.props.showTnCError(true);
    } else {
      const isVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
      if (isVoucher) {
        window.PubSub.publish('formSubmitVoucher', true);
      }
      if (checkParksTenants() || isMatchTenant(UIConfig.tenants.ya)) {
        GTMData.push('addPaymentInfo', {
          cartData: this.props.cartData,
          paymentType: UIConfig.paymentOption.paypal,
        });
      }
    }
  };

  render() {
    return (
      <>
        {this.props.emailConfirmationText}
        <div
          className={`paypal-wrapper paypal-button-container ${
            this.state.isPaypalBtnDisable ? 'paypal-disabled' : ''
          } ${checkTenant(UIConfig.tenants.b2b) ? 'b2b-width' : 'paypalbtn-full'}`}
        >
          <div>
            <PayPalScriptProvider
              options={{
                ...this.state.initialOptions,
                ...(this.disableFunding !== UIConfig.paymentOption.card && {
                  'disable-funding': UIConfig.paymentOption.card,
                }),
              }}
              tabIndex={this.props.isEnabled ? '0' : '-1'}
            >
              <PayPalButtons
                tabIndex={this.props.isEnabled ? '0' : '-1'}
                style={{
                  ...this.state.style,
                  ...(this.props.isEnabled && {
                    classNames: {
                      disabled: 'paypal-button-disabled',
                    },
                  }),
                }}
                forceReRender={[this.amount, this.currency, this.style]}
                fundingSource={undefined}
                onClick={(e) => this.onSubmit(e)}
                disabled={
                  !this.props.isEnabled ||
                  this.state.isNotValid ||
                  !this.state.isValid ||
                  (!config?.data?.agentRefNum && this.isB2B) ||
                  checkAnnualPassError(this.props.cartData)
                }
                createOrder={async () => {
                  const {
                    B2B_isCreditSettlement,
                    B2B_isAdvanceTopUp,
                    creditSettlementInvoice,
                    total,
                    pageVariant,
                  } = config.data;
                  this.createOrderOmni();
                  let orderId;
                  let payLoad = {
                    locale: config.locale,
                    tenantId: config.tenantId,
                    paymentMode: config.data.paymentType,
                    emailTemplateId: config.data.emailTemplateId,
                    invoiceTemplateId: config.data.invoiceTemplateId ? config.data.invoiceTemplateId : ' ',
                    marketType: config.data.marketType,
                    isOrderHistoryPayment:
                      (canUseDOM() && JSON.parse(localStorage.getItem('isOrderHistoryPayment'))) || false,
                  };

                  if (B2B_isCreditSettlement || B2B_isAdvanceTopUp) {
                    payLoad.creditSettlementInvoice = creditSettlementInvoice;
                    payLoad.omniAmount = parseFloat(total);
                    payLoad.reconType = pageVariant;
                  }
                  if (
                    getPurchaseAllowed() &&
                    isB2B() &&
                    Number(this.props.cartData.cart.totalQuantity) > Number(this.props.propsData.maxTickets)
                  ) {
                    payLoad.isBulkOrder = true;
                  } else {
                    payLoad.isBulkOrder = false;
                  }
                  addLoaderOverlay();
                  const localEAOrderId = localStorage.getItem('EA_orderId');
                  if (localEAOrderId) {
                    orderId = atob(localEAOrderId);
                  } else {
                    if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
                      //#region  Get payfort configurations
                      const _paymentData = getLocalStorageByKey('payment_info');
                      // We are not converting session object, we are directly refering session object. It's not a boolean type so we are not using strict type checking.
                      const _isPostBookingJourney =
                        getSessionStorage('addonAddedAfterConfirmation') === 'true' ?? false;
                      // if post booking journey i.e upsell of addons then we'll have to append guid after merchant_reference
                      const _guid = _isPostBookingJourney ? `_${generateGUID()}` : '';

                      const configUrl = _paymentData.cmsData.services.TravelBoxPayfortConfig.url.replace(
                        '{cartId}',
                        getSessionStorage('cartId'),
                      );
                      const response = await fetch(configUrl);
                      const _data = await response.json();
                      config.data.reservationCode = _data.merchant_reference + _guid;
                      config.data.orderID = _data.merchant_reference + _guid;
                      config.amountInAed = _data.TotalAmount;
                      config.amount = this.convertAedToUsd(
                        _data.TotalAmount,
                        _paymentData.cmsData.paymentOptions.conversionRate,
                      );
                      //#endregion
                      const serverErrorElement = document.getElementsByClassName('yasid-email-error server-error');
                      if (serverErrorElement && serverErrorElement.length > 0) {
                        return false;
                      }
                    }
                    if (config.data && config.data.orderID) {
                      payLoad.orderId = config.data.orderID;
                      payLoad.reservationCode = config.data.reservationCode;
                      payLoad.amount = config.amount;
                      payLoad.redirectUrl = window.location.href;

                      if (config.tenantId.toLowerCase() === UIConfig.YIB2C) {
                        payLoad.amount = config.amountInAed;
                        payLoad.amountInAed = config.amountInAed;
                        payLoad.TravelBoxAddPaymentUrl = config.data.services.TravelBoxAddPaymentUrl.url;
                        payLoad.TravelBoxConfirmBookingUrl = config.data.services.TravelBoxConfirmBookingUrl.url;
                        payLoad.cartId = getSessionStorage('cartId');
                        payLoad.deviceData = '827e437012c709a171df592bccf5567e';
                        payLoad.conversionRate = config.data.conversionRate;
                        payLoad.is2StepJourney = true;
                        payLoad.currencyCode = config.data.currency;
                      }

                      orderId =
                        config.data.B2B_isCreditSettlement || config.data.B2B_isAdvanceTopUp
                          ? await b2bDoTransaction(payLoad)
                          : await doTransaction(payLoad);
                    } else {
                      orderId = await this.createOrder(payLoad, config.paypalCartData);
                    }
                    if (config.tenantId.toLowerCase() === UIConfig.tenants.ya) {
                      const eaOrderId = btoa(orderId);
                      localStorage.setItem('EA_orderId', eaOrderId);
                    }
                  }
                  return orderId;
                }}
                onApprove={async (data, actions) => {
                  await this.onApproverAction(data, actions);
                }}
                onCancel={(data) => {
                  console.log(JSON.stringify(data), 'cancelled');
                  removeLoaderOverlay();
                }}
                onError={(err) => {
                  PaymentAnalytics && PaymentAnalytics(config.error.code, config.error.text);
                  console.log(err, 'error');
                  removeLoaderOverlay();
                }}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      </>
    );
  }
}

export default Paypal;
