/*
 * text-area-components.js
 * This file contains code for text area component which will render textarea based on schema.
 * @licensor  Miral
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';
import UIConfig from '../../../../common/UIConfig';
const TextArea = ({ data, onChange, onBlur, onFocus }) => {
  const [inputValue, setInputValue] = useState(data.value || '');

  const onChangeHandler = (event) => {
    const regexPattern = /^(?!.*[<>]).*$/;
    if (regexPattern.test(event?.target?.value)) {
      setInputValue(event?.target?.value);
      onChange(event);
    } else {
      return;
    }
  };

  try {
    return (
      <div
        aria-live="polite"
        className={`form-element ${data.active} ${
          data.class === UIConfig.contactUs.yaClasses.textareaMsg ? data.class : ''
        } input-type-${data.type.toLowerCase()}`}
      >
        <DynamicContent tagName="label" attrs={{ className: 'form-label', htmlFor: data.id }} innerHtml={data.label} />
        <textarea
          className={`form-text-area body-copy-3 ${data.valid}`}
          name={data.name}
          rows={data.rows}
          defaultValue={data.value}
          aria-required={data.isRequired}
          id={data.id}
          maxLength={data.maxLength}
          onChange={onChangeHandler}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={data.placeholder}
          value={inputValue}
        />
        {data.showErrors ? <span className="error-msg body-copy-6">{data.message}</span> : null}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'TextArea');
  }
};

TextArea.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rows: PropTypes.number,
    resize: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
  }),
};

export default TextArea;
