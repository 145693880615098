/* This file contains code for Media item component,
 * it renders some optional fields and wraps some images
 * within the anchor tag depends on icon type
 * @licensor  Miral
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Video from '../../video';
import { AnchorLink, DynamicContent, Image, RichText } from '..';
import {
  handleEnterKey,
  canUseDOM,
  isLiveStreamingStarted,
  resolvePath,
  checkTenant,
  isMatchTenant,
  detectMobile,
  checkIfTagetDateAproached,
  isDesktopViewPort,
  isMobileOrTab,
} from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';
import { GLOBAL_VARS } from '../../../../common/global-vars';
import UIConfig from '../../../../common/UIConfig';
import ThirdPartyWidget from '../../../presentation/third-party-widget';
import CTAButton from '../cta-button/CTAButton';
import { bodyClicks } from '../../../../common/analytics-events';
import { TimerCountdown } from '../../../presentation/base';
import EventTimer from '../../../presentation/base/timer/event-timer-component';
import moment from 'moment';

const iconTypeHtml = (data) => {
  const {
    actionLink,
    activeItem,
    bodyCopy,
    imageInfo,
    imageTitle,
    index,
    isImageClickable,
    mediaType,
    overlay,
    subTitle,
    thumbnail,
    variant,
    videoData,
    videoInfo,
    fromTabsWithSlider,
    isSmartHeroCarousel,
    videoDetail,
    showControls,
    setShowControls,
  } = data;
  const isOpacityRemove = !(!overlay && (imageTitle || bodyCopy || subTitle || !!(actionLink && actionLink.href)));
  if (mediaType === 'video' || mediaType === 'media-video') {
    if (
      (overlay && !thumbnail) ||
      variant.includes('hero-carousel') ||
      variant.includes('v-carousel-view') ||
      isSmartHeroCarousel
    ) {
      return (
        <div className={`${mediaType ? `media-carousel-item--${mediaType}` : ''}`}>
          {!isSmartHeroCarousel && (
            <span className="icon-ctspan">
              <button className="icon-ct" aria-hidden="true"></button>
            </span>
          )}
          <Video
            data={isSmartHeroCarousel ? videoDetail?.videoInfo : videoInfo}
            videoData={videoData}
            imageInfo={imageInfo}
            active={activeItem}
            isOpacityRemove={isOpacityRemove}
            isSmartHeroCarousel={isSmartHeroCarousel}
            showControls={showControls}
            setShowControls={setShowControls}
          />
        </div>
      );
    } else {
      return (
        <div
          className={`${mediaType ? `media-carousel-item--${mediaType}` : ''}`}
          onClick={() => onMediaClick('parent', { index, onMediaClick: data.onMediaClick, overlay })}
        >
          {imageInfo.showExpandIcon !== false && (
            <span
              className="icon-ctspan"
              onClick={() => onMediaClick('icon', { index, onMediaClick: data.onMediaClick, overlay })}
              onKeyDown={(e) =>
                handleEnterKey(e, () => onMediaClick('icon', { index, onMediaClick: data.onMediaClick, overlay }))
              }
              tabIndex="0"
            >
              <button aria-label="play" className="icon-ct mc-play"></button>
            </span>
          )}
          <Image image={imageInfo} disableLazyLoad={true} isOpacityRemove={isOpacityRemove} />
        </div>
      );
    }
  } else {
    return (
      <div
        className={`media-carousel-item--${mediaType ? mediaType : ''}`}
        onClick={() => onMediaClick('parent', { index, onMediaClick: data.onMediaClick, overlay })}
      >
        {imageInfo.showExpandIcon !== false && (
          <span
            className="icon-ctspan"
            onClick={() => onMediaClick('icon', { index, onMediaClick: data.onMediaClick, overlay })}
            onKeyDown={(e) =>
              handleEnterKey(e, () => onMediaClick('icon', { index, onMediaClick: data.onMediaClick, overlay }))
            }
            tabIndex="0"
          >
            <button aria-label="expand" className="icon-ct mc-expand" value="mc-expand">
              <span className="sr-only">mc-expand</span>
            </button>
          </span>
        )}
        {getImage({ actionLink, imageInfo, isImageClickable }, isOpacityRemove, fromTabsWithSlider)}
      </div>
    );
  }
};
const getImage = ({ actionLink, imageInfo, isImageClickable }, isOpacityRemove, fromTabsWithSlider) => {
  const isYasIsland = resolvePath(GLOBAL_VARS, 'pageDetails.tenantName', '') === UIConfig.iamMapping.yasisland;
  const image = (
    <Image
      image={imageInfo}
      disableLazyLoad={true}
      isOpacityRemove={isOpacityRemove}
      isMediaItem={isYasIsland && !fromTabsWithSlider}
    />
  );
  if (isImageClickable && actionLink && actionLink.href) {
    return <a href={actionLink.href}>{image}</a>;
  } else {
    return image;
  }
};
const onMediaClick = (source, { index, onMediaClick, overlay }) => {
  if (!overlay && source === 'parent') {
    return false;
  }
  if (onMediaClick) {
    onMediaClick(index);
  }
};
/**
 * @param {object} data  will have imageinfo,image title and body copy fields
 */
const MediaCarouselItem = (props) => {
  const { isSmartHeroCarousel, changeToH1, showControls, setShowControls } = props;
  const isLiveStreamEnabled = props.liveStreaming && props.liveStreaming.enableLiveStream;
  let isLiveStreamStarted = true;
  let isBookingWidgetEnable = false;
  const [isEventLive, setIsEventLive] = useState(false);
  const [initIsEventLiveCheck, setInitIsEventLiveCheck] = useState(false);
  const isYIB2C = checkTenant(UIConfig.iamMapping.yasisland);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isVideo = props.mediaType === 'media-video';
  // EP-755 - add Travel box related button, only for EA
  const isEtihadArena = resolvePath(GLOBAL_VARS, 'pageDetails.tenantName', '') === UIConfig.iamMapping.etihadarena;
  const isEASecondaryCTA = isEtihadArena && resolvePath(props, 'secondaryActionLink.label', null);
  const multiColorCta =
    props && props.actionLink && props.actionLink.class === 'v-multicolor-cta' ? 'v-multicolor-cta' : '';
  const { imageTitle, bodyCopy, videoDetail, actionLink } = props;
  const [isMobile, setIsMobile] = useState(false);

  const checkIfEventIsLive = () => {
    const fromDate = moment();
    const toDate = moment(moment.utc(props?.flashSale?.startTime || undefined));
    const seconds = toDate.diff(fromDate, UIConfig.calendar.seconds);
    return +seconds <= 0;
  };

  useEffect(() => {
    const isEventLive = checkIfEventIsLive();
    setIsEventLive(isEventLive);
    setInitIsEventLiveCheck(true);
    setIsMobile(!isDesktopViewPort());
  }, []);

  if (isLiveStreamEnabled) {
    isLiveStreamStarted = isLiveStreamingStarted(props.liveStreaming);
  }
  if (canUseDOM()) {
    isBookingWidgetEnable = document.getElementsByClassName('c-hotel-booking-widget').length > 0;
  }
  const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
    if (isYIB2C) {
      bodyClicks(eventName, titleData, labelData);
    }
  };

  const renderTimerCta = () => {
    return (
      <div className={`btn-primary button-wrapper ${multiColorCta}`}>
        <AnchorLink
          link={isVideo ? { ...videoDetail?.actionLink } : { ...actionLink }}
          onClick={() => {
            googleAnalyticHandlerbody(
              UIConfig.commonVariant.gaClickEvents.linkClick,
              isVideo ? videoDetail?.videoTitle : imageTitle,
              isVideo ? videoDetail?.actionLink?.label : actionLink.label,
            );
          }}
        />
      </div>
    );
  };

  try {
    return (
      <div
        className={classNames('media-carousel-item', {
          'media-carousel-item-withText': props.imageTitle,
        })}
      >
        {(props.imageInfo || isSmartHeroCarousel) &&
          iconTypeHtml({
            ...props,
            isSmartHeroCarousel: isSmartHeroCarousel,
            showControls: showControls,
            setShowControls: setShowControls,
          })}
        {props.heroBelowImage && props.thirdPartyWidget && props.thirdPartyWidget.length > 0 && (
          <ThirdPartyWidget
            data={props.thirdPartyWidget}
            wishlistData={{
              title: props.mediaItems[0].imageTitle,
              desc: props.mediaItems[0].bodyCopy,
              image: props.mediaItems[0].imageInfo.desktopImage.src,
            }}
          />
        )}
        {!props.overlay && !props.variant.includes(UIConfig.commonVariant.kgfCarouselWithCtaVariant) && (
          <div className={classNames('media-desc-container', { 'with-booking-widget': isBookingWidgetEnable })}>
            <div className={`media-desc-wrapper ${isEASecondaryCTA && 'flex-div'}`}>
              {!isYIB2C && !isSwad ? (
                <>
                  {(imageTitle || videoDetail?.videoTitle) && (
                    <DynamicContent
                      tagName={changeToH1 ? 'p' : 'h1'}
                      attrs={{ className: 'media-carousel-item--title' }}
                      innerHtml={isVideo ? videoDetail?.videoTitle : props.imageTitle}
                    />
                  )}
                  {(bodyCopy || videoDetail?.videoDescription) && (
                    <RichText
                      data={{
                        bodyCopy: isVideo ? videoDetail?.videoDescription : bodyCopy,
                        attrs: { className: 'media-carousel-item--bodycopy' },
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {props.imageTitle && (
                    <DynamicContent
                      tagName={isSwad ? 'div' : 'h2'}
                      attrs={{ className: 'media-carousel-item--title' }}
                      innerHtml={props.imageTitle}
                    />
                  )}
                  {(isYIB2C || isSwad || props.activeItem) && props.bodyCopy && (
                    <>
                      {props.flashSale && (
                        <TimerCountdown
                          data={{ ...props.flashSale }}
                          hidleFlashSaleBanner={props.hidleFlashSaleBanner}
                        />
                      )}
                      <DynamicContent
                        tagName={isSwad ? 'div' : 'h1'}
                        attrs={{ className: 'media-carousel-item--bodycopy' }}
                        innerHtml={props.bodyCopy}
                      />
                    </>
                  )}
                </>
              )}
              {props.subTitle && (
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'media-carousel-item--sub-title' }}
                  innerHtml={props.subTitle}
                />
              )}
              {props.secondaryActionLink && props.secondaryActionLink.label && (
                <div className={`${isEASecondaryCTA ? 'btn-cta-golden' : 'btn-secondary'} button-wrapper`}>
                  <AnchorLink
                    link={{ ...props.secondaryActionLink }}
                    onClick={() => {
                      googleAnalyticHandlerbody(
                        UIConfig.commonVariant.gaClickEvents.linkClick,
                        props.imageTitle,
                        props.secondaryActionLink.label,
                      );
                    }}
                  />
                </div>
              )}
              {(actionLink || videoDetail?.actionLink) &&
                (!props.isImageClickable && !isLiveStreamEnabled ? (
                  props.isFree ? (
                    <div className={`btn-primary free-btn ${multiColorCta}`}>
                      <a
                        href={props.freeActivityFormUrl}
                        onClick={() => {
                          googleAnalyticHandlerbody(
                            UIConfig.commonVariant.gaClickEvents.linkClick,
                            isVideo ? videoDetail?.videoTitle : imageTitle,
                            props.registerLabel,
                          );
                        }}
                      >
                        {props.registerLabel}
                      </a>
                    </div>
                  ) : // Variant condition is only appicable for the Photo Collage Theme Park Component
                  (actionLink?.label || videoDetail?.actionLink?.label) &&
                    (props.variant === UIConfig.commonVariant.photoCollageThemeParkVariant ||
                      props.variant === UIConfig.swadVariant.photoCollageThemeParkVariant) ? (
                    <CTAButton
                      ctaButton={isVideo ? { ...videoDetail?.actionLink } : { ...actionLink }}
                      type="secondary"
                      onClick={() => {
                        googleAnalyticHandlerbody(
                          UIConfig.commonVariant.gaClickEvents.buttonClick,
                          isVideo ? videoDetail?.videoTitle : imageTitle,
                          isVideo ? videoDetail?.actionLink?.label : actionLink.label,
                        );
                      }}
                    />
                  ) : (
                    <>
                      {initIsEventLiveCheck && (
                        <div className="c-countdown-wrapper">
                          {isMatchTenant(UIConfig.tenants.ya) &&
                            props?.flashSale &&
                            !isEventLive &&
                            checkIfTagetDateAproached(props?.flashSale?.endTime) === false && (
                              <div className="countdown--timer">
                                <EventTimer
                                  startTime={props?.flashSale?.startTime}
                                  handleIsEventLive={setIsEventLive}
                                  options={
                                    props?.flashSale?.options || {
                                      ...(detectMobile()
                                        ? {}
                                        : { months: props.flashSale?.monthsLabel || UIConfig.calendar.months }),
                                      days: props.flashSale?.daysLabel || UIConfig.calendar.days,
                                      hours: props.flashSale?.hoursLabel || UIConfig.calendar.hours,
                                      minutes: props.flashSale?.minutesLabel || UIConfig.calendar.minutes,
                                      seconds: props.flashSale?.secondsLabel || UIConfig.calendar.seconds,
                                    }
                                  }
                                />
                              </div>
                            )}
                        </div>
                      )}
                      {isMatchTenant(UIConfig.tenants.ya) &&
                        initIsEventLiveCheck &&
                        (props?.flashSale && checkIfTagetDateAproached(props?.flashSale?.endTime) === false
                          ? isEventLive && renderTimerCta()
                          : renderTimerCta())}
                      {!isMatchTenant(UIConfig.tenants.ya) && initIsEventLiveCheck && renderTimerCta()}
                    </>
                  )
                ) : (
                  isLiveStreamEnabled &&
                  isLiveStreamStarted &&
                  (props.liveStreaming.buttonImg ? (
                    <div className="btn">
                      <DynamicContent
                        tagName="button"
                        attrs={{
                          className: 'btn-primary',
                          onClick: () => {
                            props.openLiveStream(props.liveStreaming);
                            googleAnalyticHandlerbody(
                              UIConfig.commonVariant.gaClickEvents.imageClick,
                              isVideo ? videoDetail?.actionLink?.title : actionLink.title,
                              isVideo ? videoDetail?.actionLink?.label : actionLink.label,
                            );
                          },
                        }}
                        innerHtml={`<img src="${props.liveStreaming.buttonImg}" alt="${
                          isVideo ? videoDetail?.actionLink?.title : actionLink.title
                        }" />`}
                      />
                    </div>
                  ) : (
                    <div className="button-wrapper">
                      <DynamicContent
                        tagName="button"
                        attrs={{
                          className: 'btn-primary',
                          onClick: () => {
                            props.openLiveStream(props.liveStreaming);
                            googleAnalyticHandlerbody(
                              UIConfig.commonVariant.gaClickEvents.imageClick,
                              isVideo ? videoDetail?.actionLink?.title : actionLink.title,
                              isVideo ? videoDetail?.actionLink?.title : actionLink.title,
                            );
                          },
                        }}
                        innerHtml={isVideo ? videoDetail?.actionLink?.title : actionLink.title}
                      />
                    </div>
                  ))
                ))}
            </div>
          </div>
        )}

        {props.renderKgfCarouselWithCta && !isMobile && props.renderKgfCarouselWithCta()}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'MediaCarouselItem');
  }
};
export default MediaCarouselItem;
/**
 * Used to define the proptypes that will be received by the component.
 */
MediaCarouselItem.propTypes = {
  actionLink: PropTypes.object,
  mediaType: PropTypes.string,
  imageInfo: PropTypes.object,
  videoInfo: PropTypes.object,
  subTitle: PropTypes.string,
  imageTitle: PropTypes.string,
  bodyCopy: PropTypes.string,
  onMediaClick: PropTypes.func,
  overlay: PropTypes.bool,
  thumbnail: PropTypes.bool,
  videoData: PropTypes.object,
  index: PropTypes.number,
  variant: PropTypes.string,
  activeItem: PropTypes.bool,
  isImageClickable: PropTypes.bool,
};
