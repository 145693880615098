import React from 'react';
import PropTypes from 'prop-types';
import DynamicContent from '../base/dynamic-content/dynamic-content-component';
import Image from '../base/image/image-component';
import AnchorLink from '../base/anchor-link/anchor-link-component';
import { isHidden } from '../../../common/utility';

import './card-item.scss';

/**
 * CardCarouselItem component make item cards title and strip as per data
 */
const CardCarouselItem = (props) => {
  let data = props.data,
    cardStrip = '',
    ctaButton = props.ctaButton;

  /**
   * on getting strip text make markup for card strip  and store in cardStrip variable
   */
  if (data.cardStripFlag) {
    cardStrip = <DynamicContent tagName="div" attrs={{ className: 'card-strip' }} innerHtml={data.cardStripText} />;
  }

  if (ctaButton) {
    ctaButton = (
      <div className="btn-primary">
        <AnchorLink link={ctaButton} />
      </div>
    );
  }

  const keyDownHandlerTile = (e) => {
    /**
     * if user press enter key then this condition become true
     */
    let exploreBtnContainer = document.querySelector('.carousel-with-preview-holder .hero-detail-container'),
      exploreBtn;
    if (e.charCode === 13 || e.keyCode === 13 || e.key === 'Enter') {
      props.heroHandler(data);
      props.setActiveCardState(props.id);
      setTimeout(() => {
        if (isHidden(exploreBtnContainer)) {
          exploreBtn = document.querySelector('.card-carous-list-trans-2x .btn-primary a');
        } else {
          exploreBtn = document.querySelector('.carousel-with-preview-holder .hero-detail-container .btn-primary a');
        }
        exploreBtn.focus();
      }, 500);
    }
  };

  return (
    <div className="c-card-component-container">
      <div
        className="card-frame"
        tabIndex="0"
        onClick={() => {
          props.heroHandler(props.data);
          props.setActiveCardState(props.id);
        }}
        onKeyDown={(e) => keyDownHandlerTile(e)}
      >
        <div className="card-frame-title frame-wrapper">
          <DynamicContent attrs={{ className: 'card-title' }} tagName="p" innerHtml={data.cardTitle} />
          {data.cardText && (
            <DynamicContent attrs={{ className: 'card-text' }} tagName="div" innerHtml={data.cardText} />
          )}
        </div>
        {
          <div className="card-frame-image">
            <Image image={data.cardImage} />
          </div>
        }
        {cardStrip}
        {ctaButton}
      </div>
    </div>
  );
};

/**
 * Used to define the proptypes that will be received by the component.
 */

CardCarouselItem.propTypes = {
  id: PropTypes.number.isRequired,
  heroHandler: PropTypes.func,
  setActiveCardState: PropTypes.func,
  data: PropTypes.shape({
    cardTitle: PropTypes.string,
    cardStripFlag: PropTypes.bool,
    cardStripText: PropTypes.string,
    cardDefaultValue: PropTypes.bool,
    cardImage: PropTypes.object.isRequired,
    heroDescription: PropTypes.string,
    heroTitle: PropTypes.string,
    heroImage: PropTypes.object.isRequired,
    heroButton: PropTypes.object,
    textColor: PropTypes.string,
  }).isRequired,
};

export default CardCarouselItem;
