import moment from 'moment';
import React from 'react';
import { groupBookingErrorAnalytics } from '../../../../common/analytics-events';
import UIConfig from '../../../../common/UIConfig';
import {
  getErrorMap,
  detectMobile,
  checkTenant,
  isYasArenaJourney,
  getLoggedInUser,
  getAnonymousCart,
} from '../../../../common/utility';

export const getApplePayData = (self, cartData) => {
  const cartLabels = self.cartViewLabels.cartLabels;
  const getLineItem = (label = '', amount = 0, itemType = 'final') => ({
    label,
    type: itemType,
    amount,
  });

  const lineItems = [getLineItem(cartLabels.net, cartData.netPrice)];

  if (cartData.couponCode.trim()) {
    lineItems.push(getLineItem(cartLabels.discount.trim(), cartData.promotions && cartData.promotions[0].discount));
  }

  lineItems.push(getLineItem(cartLabels.vat, cartData.totalTax));

  const total = getLineItem(cartLabels.total, cartData.grossPrice);

  return { lineItems, total };
};

export const getGPayData = (self, cartData) => {
  const cartLabels = self.cartViewLabels.cartLabels;
  const getLineItem = (label = '', amount = 0, itemType = 'final') => ({
    label,
    type: itemType,
    amount,
  });

  const lineItems = [getLineItem(cartLabels.net, cartData.netPrice)];

  if (cartData.couponCode.trim()) {
    lineItems.push(getLineItem(cartLabels.discount.trim(), cartData.promotions && cartData.promotions[0].discount));
  }

  lineItems.push(getLineItem(cartLabels.vat, cartData.totalTax));

  const total = getLineItem(cartLabels.total, cartData.grossPrice);

  return { lineItems, total };
};

const getErrorMessageForTimeSlotTicket = (apiData, bookingUrl, productMainCategoryWithTabName, deleteCartProducts) => {
  if (apiData?.error?.additionalDetails) {
    const additionalDetails = apiData.error.additionalDetails;
    return additionalDetails.map((ticketDetail, index) => {
      let selectedTicket = apiData.cart.items.filter(
        (ticketData) =>
          ticketData.productId === ticketDetail.ProductAK && ticketData.performanceId === ticketDetail.PerformanceAK,
      );
      function deleteProductFromCart(e, url) {
        e.preventDefault();
        deleteCartProducts(selectedTicket[0], selectedTicket[0].maxCount, url);
      }
      if (selectedTicket && selectedTicket[0]) {
        let url = bookingUrl;
        let ticketText = '';
        if (selectedTicket[0].toDate) {
          ticketText += ' - ' + moment(selectedTicket[0].toDate).format('DD/MM/YY');
        }
        if (!selectedTicket[0].performance && selectedTicket[0].timeSlot) {
          ticketText += ' - ' + selectedTicket[0].timeSlot;
        }
        if (selectedTicket[0]?.category && selectedTicket[0]?.category[0]) {
          url += '?openCalender=false&tkt=' + selectedTicket[0]?.category[0];
        }
        if (productMainCategoryWithTabName && productMainCategoryWithTabName[selectedTicket[0].eventType]) {
          url += '#' + productMainCategoryWithTabName[selectedTicket[0].eventType];
        }
        const className = index === 0 ? 'first' : '';

        return (
          <li class="time-slot-server-error">
            <a tabIndex="0" onClick={(e) => deleteProductFromCart(e, url)} role="button" className={className}>
              {selectedTicket[0].productName}
            </a>
            {ticketText}
          </li>
        );
      }
    });
  }
};

export const publishCartError = (currentState) => {
  if (currentState && Object.values(currentState)?.length > 0) {
    let errorCode = [];
    Object.values(currentState).map((errorObj) => {
      errorCode.push(errorObj.code);
    });
    window.PubSub.publish(UIConfig.cartErrorCodesForPayment, {
      errorCode,
    });
  }
};
/**
 * handleCheckBasketError function to handle check basket errors
 * @param    {[Object]} apiData [response form check basket].
 * @return   {[Object]} returns html .
 */
export const handleCheckBasketError = (self, apiData) => {
  const error = apiData.error || (apiData.response && apiData.response.error);
  if (error) {
    if (self.isCartEmpty(apiData)) {
      self.renderEmptyCart();
    } else if (apiData.response && apiData.response.data && apiData.response.data.cart) {
      let currentState = self.state.errorObj;
      if (
        self?.props?.data?.cart?.showPerformanceCapacityErrors &&
        apiData.error &&
        (apiData.error?.code === '5055' || apiData.error?.code === '5035') &&
        apiData.cart.items
      ) {
        currentState = {
          [self.serviceProps.getCartMashup.url + apiData.error.code]: {
            code: '5035',
            errorFrom: 'cart',
            text: getErrorMessageForTimeSlotTicket(
              apiData,
              self?.props?.data?.cart?.bookingUrl,
              self?.props?.data?.cart?.productMainCategoryWithTabName,
              self.deleteCartProducts,
            ),
          },
          ...self.state.errorObj,
        };
      }
      self.updateCartState({
        cartResponse: self.wrapCartResponse(apiData.response.data.cart),
        event: null,
        errorObj: getErrorMap(
          self.serviceProps.getCartMashup.url,
          self.serviceProps.getCartMashup.errors,
          false,
          apiData.response.data.error,
          currentState,
          ['5002'],
        ),
      });
      publishCartError(currentState);
    } else if (apiData.cart) {
      let currentState;
      if (
        self?.props?.data?.cart?.showPerformanceCapacityErrors &&
        apiData.error &&
        (apiData.error?.code === '5055' || apiData.error?.code === '5035') &&
        apiData.cart.items
      ) {
        currentState = {
          [self.serviceProps.getCartMashup.url + apiData.error.code]: {
            code: '5035',
            errorFrom: 'cart',
            text: getErrorMessageForTimeSlotTicket(
              apiData,
              self?.props?.data?.cart?.bookingUrl,
              self?.props?.data?.cart?.productMainCategoryWithTabName,
              self.deleteCartProducts,
            ),
          },
          ...self.state.errorObj,
        };
      } else {
        currentState = self.state.errorObj;
      }
      self.updateCartState({
        cartResponse: self.wrapCartResponse(apiData.cart),
        event: null,
        isPartnerSegment: self.preappliedCouponCode ? true : false,
        errorObj: getErrorMap(
          self.serviceProps.getCartMashup.url,
          self.serviceProps.getCartMashup.errors,
          false,
          apiData.error,
          currentState,
          ['5002'],
        ),
      });
      publishCartError(currentState);
    } else {
      self.setState({
        errorObj: getErrorMap(
          self.serviceProps.getCartMashup.url,
          self.serviceProps.getCartMashup.errors,
          false,
          error,
          self.state.errorObj,
          ['5002'],
        ),
      });
      publishCartError(self.state.errorObj);
    }
  }
};

/**
 * updateCartProducts function to update cart products
 * @param    {[Object]} product [product ot be modified].
 * @param    {[Number]} quantity [changed quantity of product].
 * @param    {[Number]} maxQuantity [maximum quantity that can be added for give product].
 * @param    {[Object]} extraCartParams [extraParameters required to be added in cart].
 * @return   {[void]} setState to render final cart after updation .
 */
export const updateCartProducts = (self, product, quantity, maxQuantity, extraCartParams) => {
  const productArr = [];
  if (product) {
    product.currQuantity = quantity;
    productArr.push(product);
  }
  const annonymousCartData = getAnonymousCart();
  const userInfo = getLoggedInUser();
  let productList = self.state.cartData.cart.items;
  let pkgList = [];
  if (!userInfo.idToken && checkTenant(UIConfig.iamMapping.ymc)) {
    productList = annonymousCartData?.cart?.items;
    pkgList = annonymousCartData?.cart?.packages;
  }

  const unqueCode = [];
  const uniquePkgList = [];
  pkgList?.length &&
    pkgList.forEach((pkg) => {
      const pkgCode = pkg.items?.[0].packageGroupCode;
      if (!unqueCode.includes(pkgCode)) {
        unqueCode.push(pkgCode);
        uniquePkgList.push(pkg);
      }
    });

  if (uniquePkgList?.length) {
    uniquePkgList.forEach((pkg) => {
      productList.push(...pkg.items);
    });
  }

  self.CartActionObj.updateCart(
    productList,
    productArr,
    maxQuantity,
    self.props.data.cart.maxCartQuantity - self.state.cartData.cart.totalQuantity,
    extraCartParams,
  )
    .then(() => {
      //do a check basket
      self.renderCartUI(getErrorMap(self.serviceProps.updateCart.url, {}, true));
    })
    .catch((response) => {
      const serverError = response.error ? response : response.response.error ? response.response : { error: {} };
      if (serverError.error.code === UIConfig.errorCodes.invalidQuantity) {
        groupBookingErrorAnalytics(product.productName, window.dataLayer && window.dataLayer[0].pageName);
        const cart = self.CartActionObj.getCustomCart(serverError.error.updatedProductList);
        self.updateCartState({
          cartResponse: cart,
          event: null,
          errorObj: self.state.errorObj,
          bErrObj: serverError.error,
        });
      } else if (serverError.error.code === UIConfig.errorCodes.invalidCartQuantity) {
        groupBookingErrorAnalytics(product.productName, window.dataLayer && window.dataLayer[0].pageName);
        const cart = self.CartActionObj.getCustomCart(serverError.error.updatedProductList);
        self.updateCartState({
          cartResponse: cart,
          event: null,
          errorObj: getErrorMap(
            self.serviceProps.updateCart.url,
            self.props.data.cart.businessErrors,
            false,
            serverError.error,
            self.state.errorObj,
          ),
        });
      } else {
        //error handling
        self.setState({
          errorObj: getErrorMap(
            self.serviceProps.updateCart.url,
            self.serviceProps.updateCart.errors,
            false,
            serverError.error,
            self.state.errorObj,
          ),
        });
      }
    });
};

/**
 * deleteCartProducts function to remove products from cart
 * @param    {[Object]} product [product to be deleted].
 * @return   {[void]} setState to render final cart after deletion .
 */
export const deleteCartProducts = (self, product, maxQuantity, url) => {
  let productList = self.state.cartData.cart.items || [];
  const isPackages = self.state.cartData.cart?.packages;
  if (isPackages?.length) {
    const unGroupPkgProd = self.CartActionObj.getUngroupedPkgPrd(isPackages);
    productList = [...productList, ...unGroupPkgProd];
  }
  if (isYasArenaJourney()) {
    if (self.loading) {
      return;
    }
    self.loading = true;
  }
  self.CartActionObj.deleteCart(productList, product, maxQuantity)
    .then((response) => {
      if (response.productList.length > 0) {
        //remove promo code after product removal
        self.removeCouponCallback();
        //do a check basket
        if (!isYasArenaJourney() || !self.loading) {
          self.doCheckbasketAfterCartUpdate();
        }
      } else {
        self.renderEmptyCart();
      }
      document.querySelector('.c-progress-bar-wrapper .tab a') &&
        document.querySelector('.c-progress-bar-wrapper .tab a').focus();
      if (url && (!isYasArenaJourney() || !self.loading)) {
        window.location.href = url;
      }
    })
    .catch((response) => {
      if (url) {
        window.location.href = url;
      }
      if (self.isCartEmpty(response)) {
        self.renderEmptyCart();
      } else {
        self.setState({
          errorObj: getErrorMap(
            self.serviceProps.deleteCart.url,
            self.serviceProps.deleteCart.errors,
            false,
            response.data.error,
            self.state.errorObj,
          ),
        });
      }
      document.querySelector('.c-progress-bar-wrapper .tab a') &&
        document.querySelector('.c-progress-bar-wrapper .tab a').focus();
    });
};

/**
 *  Update and sync cart with updated quantity.
 */
export const updateNonSeatedEvent = (self, products, isGrouped, context, availability, extraParams) => {
  const cartItems = self.state.cartData.cart.items;
  const enrichedProducts = self._yaCartSvc.enrichData(products, cartItems);

  //removed non-seated products to use udpatecart fn in addProduct mode
  //const productListWithAddons  = self.productList.filter((product) => utility.isAddonProduct(product));

  // Always udpate product in add mode.
  self.CartActionObj.updateCart(
    self.productList,
    enrichedProducts,
    availability,
    availability - self.state.cartData.cart.totalQuantity,
    extraParams,
  )
    .then((response) => {
      self.doCheckbasketAfterCartUpdate();
    })
    .catch((response) => {
      const serverError = response.error ? response : response.response.error ? response.response : { error: {} };
      if (serverError.error.code === UIConfig.errorCodes.invalidQuantity) {
        const cart = self.CartActionObj.getCustomCart(serverError.error.updatedProductList);
        self.updateCartState({
          cartResponse: cart,
          event: null,
          errorObj: self.state.errorObj,
          bErrObj: serverError.error,
        });
      }
    });
};

//sticky myCart-SWAD
export const stickyMyCart = (self) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const multiColumnWrapper = document.querySelector('.col-lg-4');
  const myCartWrapper = document.getElementsByClassName('c-my-cart-b2c')[0];
  const miniCartref = document.getElementsByClassName('c-my-cart-b2c-sticky-container')[0];
  const cartSibling = document.querySelector('.sticky-cart-sibbling');
  const header = document.getElementsByClassName('c-header')[0];
  if (!myCartWrapper || !miniCartref || !cartSibling || !header) {
    return;
  }
  const siblingWrapperTop = cartSibling.getBoundingClientRect().top + window.pageYOffset;

  if (
    window.pageYOffset >=
      siblingWrapperTop -
        (header.classList.contains('sticky-header') ? (!isSwad ? header.offsetHeight : header.offsetHeight + 74) : 0) &&
    window.pageYOffset <=
      myCartWrapper.offsetHeight +
        myCartWrapper.offsetTop -
        miniCartref.offsetHeight -
        (header.classList.contains('sticky-header') ? (isSwad ? header.offsetHeight + 74 : header.offsetHeight) : 0) -
        40
  ) {
    myCartWrapper.classList.add('sticky-cart');
    miniCartref.style.position = 'fixed';
    miniCartref.style.width = `${multiColumnWrapper.getBoundingClientRect().width - 30}px`;
    window.addEventListener('resize', () => {
      return (miniCartref.style.width = `${multiColumnWrapper.getBoundingClientRect().width - 30}px`);
    });
    miniCartref.style.top =
      (header.classList.contains('sticky-header') ? (isSwad ? header.offsetHeight + 74 : header.offsetHeight) : 0) +
      'px';
  } else if (detectMobile() || window.pageYOffset <= siblingWrapperTop) {
    myCartWrapper.classList.remove('sticky-cart');
    miniCartref.style.position = 'relative';
    miniCartref.style.top = '0px';
  } else {
    myCartWrapper.classList.remove('sticky-cart');
    miniCartref.style.top = myCartWrapper.offsetHeight - miniCartref.offsetHeight - 40 + 'px';
    miniCartref.style.position = 'relative';
  }
};
