import React from 'react';
import Helmet from 'react-helmet';
import { logComponentRenderingError } from '../../../common/logger';

const SchemaMarkup = (props) => {
  const structuredData = props.data && props.data.schema;
  try {
    return (
      <>
        <Helmet>
          <script type="application/ld+json">{structuredData}</script>
        </Helmet>
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'SCHEMA MARKUP', props.data);
  }
};

export default SchemaMarkup;
