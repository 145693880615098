import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { canUseDOM, detectViewPort } from '../../../common/utility';
import GoogleMapsDirectionComponent from '../../presentation/google-maps-direction';
import { DynamicContent, Image } from '../../presentation/base';
import UIConfig from '../../../common/UIConfig';

/**
 * GoogleMapsComponent Class ( which extends the React.Component) creates the google map and contains the
 * functionality of showing directions when a user selects locations in direction panel and different location for YAS Island
 */

export default class GoogleMapsComponent extends React.Component {
  /**
   * Constructor of the class is defined which handles binding of the events and the props to the super class.
   */

  constructor(props) {
    super(props);
    if (canUseDOM()) {
      window.mapInit = this.mapInit.bind(this);
    }
    this.defaultLocation = this.getDefaultLocation();
    this.markers = [];
    this.state = {
      notGoogleLoaded: true,
      directionUrl: '',
    };
  }

  /**
   * mapInit method is a callback method whenever google map api loads
   */
  mapInit() {
    //Center of map is a position object that will be used as the center of the map
    this.centerOfMap = new window.google.maps.LatLng(
      this.props.data.googleMap.centerOfMap.lat,
      this.props.data.googleMap.centerOfMap.lng,
    );
    const isYAGettingHere = this.props.data.variant === 'yaGettingHere';
    let styles = [
      { featureType: 'administrative', elementType: 'labels.text.fill', stylers: [{ color: '#444444' }] },
      { featureType: 'administrative.neighborhood', elementType: 'labels.text.fill', stylers: [{ visibility: 'off' }] },
      { featureType: 'landscape', stylers: [{ color: '#f2f2f2' }, { visibility: 'on' }] },
      { featureType: 'landscape', elementType: 'geometry.fill', stylers: [{ color: '#ffe797' }, { lightness: '60' }] },
      { featureType: 'landscape.natural', stylers: [{ visibility: 'on' }] },
      {
        featureType: 'landscape.natural.landcover',
        elementType: 'geometry.fill',
        stylers: [{ hue: '#ff0000' }, { saturation: '-4' }, { lightness: '44' }, { visibility: 'on' }],
      },
      { featureType: 'landscape.natural.terrain', elementType: 'geometry.fill', stylers: [{ color: '#c0da9c' }] },
      { featureType: 'poi', stylers: [{ visibility: 'off' }] },
      {
        featureType: 'poi',
        elementType: 'geometry.fill',
        stylers: [{ color: '#f8c38c' }, { lightness: '40' }, { visibility: 'off' }],
      },
      { featureType: 'poi', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { featureType: 'poi.attraction', stylers: [{ color: '#626060' }, { visibility: 'off' }] },
      {
        featureType: 'poi.attraction',
        elementType: 'geometry.fill',
        stylers: [{ hue: '#5eff00' }, { visibility: 'off' }],
      },
      { featureType: 'poi.attraction', elementType: 'geometry.stroke', stylers: [{ visibility: 'off' }] },
      {
        featureType: 'poi.attraction',
        elementType: 'labels.icon',
        stylers: [{ color: '#fff5cd' }, { visibility: 'on' }],
      },
      { featureType: 'poi.attraction', elementType: 'labels.text', stylers: [{ visibility: 'on' }] },
      { featureType: 'poi.attraction', elementType: 'labels.text.fill', stylers: [{ visibility: 'simplified' }] },
      { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
      { featureType: 'poi.park', elementType: 'geometry.fill', stylers: [{ color: '#c0da9c' }, { visibility: 'on' }] },
      { featureType: 'poi.park', elementType: 'labels.icon', stylers: [{ visibility: 'on' }] },
      { featureType: 'poi.place_of_worship', stylers: [{ visibility: 'off' }] },
      { featureType: 'poi.place_of_worship', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { featureType: 'poi.sports_complex', stylers: [{ visibility: 'off' }] },
      { featureType: 'poi.sports_complex', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { featureType: 'road', stylers: [{ saturation: -100 }, { lightness: 45 }, { visibility: 'on' }] },
      { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: '#e5e3e3' }] },
      { featureType: 'road.arterial', stylers: [{ visibility: 'on' }] },
      { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'on' }] },
      { featureType: 'road.highway', stylers: [{ visibility: 'simplified' }] },
      { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ hue: '#edff00' }] },
      { featureType: 'road.local', stylers: [{ visibility: 'on' }] },
      { featureType: 'transit', stylers: [{ visibility: 'on' }] },
      { featureType: 'transit', elementType: 'geometry.fill', stylers: [{ color: '#b8bec3' }, { lightness: '-1' }] },
      { featureType: 'transit.line', elementType: 'labels.icon', stylers: [{ visibility: 'on' }] },
      { featureType: 'transit.station', stylers: [{ visibility: 'on' }] },
      { featureType: 'transit.station.airport', elementType: 'labels.icon', stylers: [{ visibility: 'on' }] },
      { featureType: 'transit.station.bus', elementType: 'labels.icon', stylers: [{ visibility: 'on' }] },
      { featureType: 'transit.station.rail', elementType: 'labels.icon', stylers: [{ visibility: 'on' }] },
      { featureType: 'water', stylers: [{ color: '#84bfd7' }, { visibility: 'on' }] },
      { featureType: 'water', elementType: 'geometry.fill', stylers: [{ color: '#acd7f0' }, { lightness: '40' }] },
    ];
    if (isYAGettingHere) {
      styles = [
        { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
        { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
        { featureType: 'administrative.land_parcel', elementType: 'labels.text.fill', stylers: [{ color: '#bdbdbd' }] },
        { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] },
        { featureType: 'poi', elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
        { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#e5e5e5' }] },
        { featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] },
        { featureType: 'road', elementType: 'geometry', stylers: [{ color: '#ffffff' }] },
        { featureType: 'road.arterial', elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
        { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#dadada' }] },
        { featureType: 'road.highway', elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] },
        { featureType: 'road.local', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] },
        { featureType: 'transit.line', elementType: 'geometry', stylers: [{ color: '#000' }] },
        { featureType: 'transit.station', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] },
        { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#c9c9c9' }] },
        { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] },
      ];
    }
    //Create Map Instance
    this.map = new window.google.maps.Map(this.mapRef, {
      zoom: Number(this.props.data.googleMap.zoom),
      center: this.centerOfMap,
      mapTypeControl: this.props.data.googleMap.mapTypeControl || false,
      streetViewControl: this.props.data.googleMap.streetViewControl || false,
      scrollwheel: false,
      styles,
    });

    //instance of infoWindow
    this.infoWindow = new window.google.maps.InfoWindow();
    //Now create markers & polygon and add listeners for autocomplete
    if (!isYAGettingHere) {
      this.addPolygon();
      this.placeMarkersToMap();
    }
    this.setState({
      notGoogleLoaded: false,
    });
  }

  /**
   * This method will add a polygon around the locations, thus will cover the whole island
   */
  addPolygon() {
    //Get the polygon coordinates string, and crate the PATH for Polygon
    const points = this.props.data.googleMap.highlighted.coordinates.split(' '),
      path = [];

    if (!points) {
      return;
    }

    points.forEach((point) => {
      const coords = point.split(',');
      path.push({ lat: Number(coords[1]), lng: Number(coords[0]) });
    });

    // Construct the polygon.
    this.yasIsland = new window.google.maps.Polygon({
      paths: path,
      strokeColor: this.props.data.googleMap.highlighted.strokeColor,
      strokeOpacity: Number(this.props.data.googleMap.highlighted.strokeOpacity),
      strokeWeight: Number(this.props.data.googleMap.highlighted.strokeWeight),
      zIndex: 99,
      clickable: true,
      fillOpacity: Number(this.props.data.googleMap.highlighted.fillOpacity),
      fillColor: this.props.data.googleMap.highlighted.fillColor,
    });

    //Add event listener to the Polygon
    window.google.maps.event.addListener(this.yasIsland, 'mouseover', () => {
      this.yasIsland.setOptions({ fillColor: this.props.data.googleMap.highlighted.hoverColor });
    });

    window.google.maps.event.addListener(this.yasIsland, 'mouseout', () => {
      this.yasIsland.setOptions({ fillColor: this.props.data.googleMap.highlighted.fillColor });
    });

    this.yasIsland.setMap(this.map);
  }

  /**
   * This method will add all the location from locations array into the map, and set the listener
   */
  placeMarkersToMap() {
    const places = [...this.props.data.googleMap.locations];
    this.bounds = new window.google.maps.LatLngBounds();
    this.activeIcon = {
      url: (this.props.data.googleMap.activeIcon && this.props.data.googleMap.activeIcon.icon) || null,
    };

    //create the markers for the location array
    places.forEach((place) => {
      if (!place.lat && !place.lng) {
        return;
      }
      const position = new window.google.maps.LatLng(Number(place.lat), Number(place.lng)),
        icon = {
          url: (place.markerStyle && place.markerStyle.icon) || UIConfig.googleMap.defaultIcon,
        };
      this.bounds.extend(position);

      const marker = new window.google.maps.Marker({
        position: position,
        title: place.name,
        map: this.map,
        icon: icon,
        defaultIcon: icon,
        activeIcon: this.activeIcon,
      });

      this.markers.push(marker);

      //create the content of infowindow for the marker
      const infoWindowHtml = `<div class="infowindow-content">
                    <h4>${place.name}</h3>
                    <p class="${!place.markerCta && 'hide'}">${place.description}</p>
                    <div class="${place.markerCta && place.markerCta.href ? 'button active' : 'hide'}">
                        <a href="${place.markerCta && place.markerCta.href}">${place.markerCta &&
        place.markerCta.label}</a>
                    </div>
                </div>`;

      //Marker Click Event
      window.google.maps.event.addListener(marker, 'click', () => {
        if (marker.activeIcon.url) {
          this.changeIcon();
          marker.setIcon(marker.activeIcon);
        }
        this.infoWindow.setContent(infoWindowHtml);
        this.infoWindow.open(this.map, marker);
      });
    });

    //InfoWindow Close event, to make it align again
    window.google.maps.event.addListener(this.infoWindow, 'closeclick', () => {
      if (this.activeIcon.url) {
        this.changeIcon();
      }
      this.map.setCenter(this.centerOfMap);
    });

    detectViewPort() === 'mobile' && this.map.fitBounds(this.bounds);
  }

  /**
   * This method will either show all the markers or remove all the markers from the map
   */
  hideShowMarkers(map) {
    this.markers.forEach((marker) => {
      marker.setMap(map);
    });
  }

  /**
   * This method will setIcon on the markers to its default state
   */
  changeIcon() {
    this.markers.forEach((marker) => {
      marker.setIcon(marker.defaultIcon);
    });
  }

  /**
   * This method will retrieve the default location from locations array, that are plotted in the map
   */
  getDefaultLocation() {
    let location;
    this.props.data.googleMap.locations.forEach((loc) => {
      if (loc.default) {
        location = loc;
      }
    });

    return location;
  }

  /**
   * This method will check if we need to load direction component or not
   */
  shouldShowDirection() {
    return !this.state.notGoogleLoaded && this.props.data.directions && this.defaultLocation;
  }

  renderHeader = () => (
    <div className="w--content c-google-maps-component-header">
      {this.props.data.shortTitle && (
        <DynamicContent tagName="p" innerHtml={this.props.data.shortTitle} attrs={{ className: 'map-heading' }} />
      )}
      {this.props.data.bodyCopy && (
        <DynamicContent tagName="div" innerHtml={this.props.data.bodyCopy} attrs={{ className: 'map-title' }} />
      )}
      {this.props.data.headerImage && (
        <div className="c-google-maps-component--img">
          <Image image={this.props.data.headerImage} disableLazyLoad={true} />
        </div>
      )}
    </div>
  );

  directionURL = (directionUrl) => {
    this.setState({ directionUrl });
  };

  /**
   * render  Used to render the JSX of the component
   */
  render() {
    const isYAGettingHere = this.props.data.variant === 'yaGettingHere';
    const { directionUrl } = this.state;

    return (
      <div className="component c-google-maps-component">
        {!isYAGettingHere && this.renderHeader()}
        <div className="c-google-maps-component-content">
          <div className="map-input-wrapper">
            {isYAGettingHere && this.renderHeader()}
            {this.shouldShowDirection() && (
              <GoogleMapsDirectionComponent
                data={this.props.data}
                defaultLocation={this.defaultLocation}
                yasIsland={this.yasIsland}
                markers={this.markers}
                map={this.map}
                centerOfMap={this.centerOfMap}
                getDirectionURL={this.directionURL}
              />
            )}
          </div>
          <div className="map" ref={(mapRef) => (this.mapRef = mapRef)}></div>
          {isYAGettingHere && (
            <div className="getdirection-button">
              <Text
                tag="a"
                href={directionUrl}
                disabled={!directionUrl.length}
                target="_blank"
                className={classNames({ isDisabled: !directionUrl.length })}
                aria-disabled={!directionUrl.length}
                field={{
                  value: this.props.data.directions.getDirectionCTA || 'GET DIRECTIONS',
                  editable: this.props.data.directions.getDirectionCTA || 'GET DIRECTIONS',
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

/**
 * Used to define the proptypes that will be received by the component.
 */

GoogleMapsComponent.propTypes = {
  data: PropTypes.shape({
    googleMap: PropTypes.shape({
      locations: PropTypes.arrayOf(PropTypes.object).isRequired,
      zoom: PropTypes.string.isRequired,
      centerOfMap: PropTypes.shape({
        lat: PropTypes.string,
        lng: PropTypes.string,
        name: PropTypes.string,
      }).isRequired,
    }),
    directions: PropTypes.shape({
      modes: PropTypes.array,
      startLabel: PropTypes.string,
      endLabel: PropTypes.string,
      getDirectionCTA: PropTypes.string,
    }),
  }),
};
