import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

import { logComponentRenderingError } from '../../../../common/logger';

import './timer-component.scss';
import UIConfig from '../../../../common/UIConfig';

const getFieldsToShow = (opts) => {
  if (!opts) {
    return ['months', 'days', 'hours', 'minutes'];
  }
  return Object.keys(opts);
};

// Inspired by underscore
function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

const renderTimerSection = (val, label, index) => {
  return (
    <div className="c-timer-section" key={index}>
      <span className="c-timer-section--value">{val}</span>
      <span className="c-timer-section--label">{label}</span>
    </div>
  );
};

const EventTimer = (props) => {
  const { options, startTime: sTime } = props;

  const [mTime, setMTime] = useState('');
  const startTime = useRef(moment.utc(sTime || undefined));
  const timer = useRef(null);
  const [isEventLive, setIsEventLive] = useState(false);

  useEffect(() => {
    if (!mTime && startTime.current && moment(startTime.current).isValid()) {
      startTimer();
    }
  }, [mTime]);

  const setTimerInterval = () => {
    let time = moment.duration(startTime.current.diff(moment.utc()));
    setMTime(time);
    const isEventLive = checkIfEventIsLive();
    if (isEventLive === true) {
      stopTimer();
      setIsEventLive(true);
      isFunction(props.handleIsEventLive) && props.handleIsEventLive(true);
    }
  };

  const startTimer = () => {
    if (!timer.current) {
      timer.current = setInterval(setTimerInterval, 1000);
    }
  };

  const prependZero = (num) => {
    return (num < 10 && '0') + num;
  };

  const calculateTotalUnits = (unit) => {
    const fromDate = moment();
    const toDate = moment(startTime.current);
    return toDate.diff(fromDate, unit);
  };

  const stopTimer = () => {
    clearInterval(timer.current);
  };

  const checkIfEventIsLive = () => {
    const fromDate = moment();
    const toDate = moment(startTime.current);
    const seconds = toDate.diff(fromDate, UIConfig.calendar.seconds);
    return +seconds <= 0;
  };

  try {
    let timerData = { months: '00', days: '00', hours: '00', minutes: '00', seconds: '00' };
    if (mTime > 0 && !isEventLive) {
      const months = mTime.years() > 0 ? calculateTotalUnits(UIConfig.calendar.months) : prependZero(mTime.months());
      let days = prependZero(mTime.days());
      const hours = prependZero(mTime.hours());
      const minutes = prependZero(mTime.minutes());
      const seconds = prependZero(mTime.seconds());
      if (!options.months) {
        const totalDays = calculateTotalUnits(UIConfig.calendar.days);
        days = prependZero(totalDays);
      }
      timerData = { months, days, hours, minutes, seconds };
    }
    return (
      <div className="c-timer" tabIndex="0">
        <div className="c-timer-body">
          {getFieldsToShow(options).map((field, index) => renderTimerSection(timerData[field], options[field], index))}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Timer');
  }
};

export default EventTimer;
