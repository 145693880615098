import UIConfig from '../../../../common/UIConfig';
import { CartService } from '../../../../common/services';
import { getB2CProductDetails } from '../../../../common/coveo-api';
import {
  canUseDOM,
  getFallbackLanguage,
  createMappedData,
  getProdIdType,
  redirectTo,
  setLocalStorage,
  encryptParam,
  decryptParam,
  getUTCTimeByFormat,
  getSortedYaTickets,
} from '../../../../common/utility';
import TourOverlay from '../tour-overlay/tour-overlay';
import { yaRemoveItemAnalytics } from '../../../../common/analytics-events';
import moment from 'moment';

const {
  yasArenaSeatInfo,
  yasArenaEventId,
  yasArenaEventDetails,
  yasArenaVisitedEvents,
  yasArenaPerformanceDetails,
  yasArenaCreateOrder,
  yaStore,
  addOnsAvailability,
  staticUrlDetail,
  promotionDetail,
  yasArenaEventTitle,
  yasArenaCartEventTitle,
} = UIConfig.b2c.purchaseJourney.yasArenaCart.localStorageKeys;

/**
 *  Class - Responsible for managing Yas Arena Cart
 */
export default class YaCartManagementService {
  /** constructor */
  constructor({
    servicesUrls,
    coveoMappingList,
    errorPageUrl,
    nonSeatedOverlayLabels,
    addToCart,
    isNonSeatedJourney,
    getCartItems,
    pageUrls,
    eventDetails,
    ticketValidityMap,
  }) {
    const {
      readOrderDetails,
      getCoveoProducts,
      getProductsByPerformance,
      getPerformanceCapacityandProduct,
    } = servicesUrls;
    this._isNonSeatedJourney = isNonSeatedJourney;
    this._selectedEvent = eventDetails;
    this._nonSeatedOverlayLabels = nonSeatedOverlayLabels;
    this._addToCart = addToCart;
    this._errorPageUrl = pageUrls.errorPageUrl;
    this._coveoMappingList = coveoMappingList;
    this._readOrderDetails = readOrderDetails.url;
    this._coveoService = getCoveoProducts.url;
    this._seatSelectionRequest = pageUrls.actionLink;
    this._seatSelectionResponse = pageUrls.returnUrl;
    this._getProductsByPerformance = getProductsByPerformance.url;
    this._getPerformanceCapacityandProduct = getPerformanceCapacityandProduct.url;
    this._getCartItems = getCartItems;
    this.nonSeatedData = {};
    this._ticketValidityMap = ticketValidityMap || {};
  }

  /***
   *  Initialize Static Data for Non Seated Overlay.
   *
   */
  initNonSeatedOverlay = () => {
    this.nonSeatedData = {
      labels: this._nonSeatedOverlayLabels,
      coveoMappingList: this._coveoMappingList,
      performanceService: this._getProductsByPerformance,
      findperformancecapacityandproduct: this._getPerformanceCapacityandProduct,
      coveoProductSvc: this._coveoService,
      addToCartCallback: this._addToCart,
      eventDetails: this._selectedEvent,
    };

    const subscriptionCallback = (msg, data) => {
      const selectedPerfData = YALocalStorageSvc.getSetPerformance();
      this.showNonSeatedOverlay(selectedPerfData, false);
    };

    window.PubSub.subscribe('show_arena_tour_overlay', subscriptionCallback);
  };

  /**
   *  Check availability of cart and loads Non Seated overlay component
   */
  showNonSeatedOverlay = (selectedPerformance, fromCartAction) => {
    if (!selectedPerformance) {
      console.error('Selected Performance data is missing');
      return;
    }

    this._getCartItems().then((response) => {
      showProducts(response);
    });

    const showProducts = (items) => {
      this.nonSeatedData.cartItems = items;
      this.nonSeatedData.performanceData = selectedPerformance;

      const { update, add } = UIConfig.b2c.purchaseJourney.yaTourOverlay;
      const mode = items.length ? update : add;
      const obj = new TourOverlay(this.nonSeatedData);

      obj.showTourProducts(mode, fromCartAction);
    };
  };

  /**
   * Get the details of order created on VIVA
   * Enrich products
   * @param   {string}    OrderId
   * @returns {Array} - Enriched products
   */
  getExistingOrderDetails = async (orderId) => {
    try {
      return CartService.getOrderDetails('GetYAOrderDetails', this._readOrderDetails, orderId).then((response) => {
        let order = response.data.orderdetails.order;

        const orderStatus = order.status.toUpperCase();
        if (orderStatus === 'DELETED' || orderStatus === 'COMPLETED') {
          return { products: [] };
        }

        YALocalStorageSvc.getSetOrderId(orderId);

        //Get Mapped Products and EventTitle
        return this.getCoveoProducts(order.items).then((response) =>
          this.setUpSeatedJourneyAndProducts(response, order),
        );
      });
    } catch (error) {
      return await this.catchHandler(error);
    }
  };

  setUpSeatedJourneyAndProducts = async (response, order) => {
    const enrichedProducts = this.enrichData(response.products, order.items, order);

    let eventDetails = YALocalStorageSvc.getSetEventDetails();

    eventDetails = {
      ...eventDetails,
      eventTitle: response.eventTitle,
      isPromotionalEvent: this._selectedEvent && this._selectedEvent.isPromotionalEvent,
      isPromoCodeRequired: this._selectedEvent && this._selectedEvent.isPromoCodeRequired,
    };

    // When promotion is pre-applied from viva
    // promotions will be removed in cart-actions/getCustomCart from product item as it is not required at item level.
    YALocalStorageSvc.removePromotions();
    if (order.promotions.length > 0) {
      const { code, discount, name, promotionType: type, promotionTypeDesc: description } = order.promotions[0];

      enrichedProducts[0].promotions = [{ code, discount, name, type, description }];
    }

    YALocalStorageSvc.getSetEventDetails(eventDetails);
    YALocalStorageSvc.getSetCartTitle(true);

    const { performanceId, endDate, date } = order.items[0].performances[0];
    const perfObject = {
      openTime: getUTCTimeByFormat(date),
      closeTime: getUTCTimeByFormat(endDate),
      date: moment.utc(date).format(moment.HTML5_FMT.DATE),
      performanceId,
      isNonSeatedEvent: false,
    };
    YALocalStorageSvc.getSetPerformance(perfObject);

    //Create Seat map and save to local Storage.
    const seatMap = this.createSeatMap(order.items, enrichedProducts);
    YALocalStorageSvc.getSetSeatInfo(seatMap);

    canUseDOM() && window.history.replaceState(null, null, window.location.pathname);

    return await { products: enrichedProducts };
  };

  /**
   *  Get Coveo Products
   *  @param {Array} - orderItems  Array of Products
   *  @return mapped coveo products and eventTitle
   */
  getCoveoProducts = async (orderItems) => {
    //Get all ProductId's for coveo call.
    const productIds = orderItems.map((item) => item.productId);
    const ticketValidityMap = this._ticketValidityMap;
    const coveoDefaultParams = {
      perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
      coveoKeyMap: this._coveoMappingList,
      serviceUrl: this._coveoService,
      fieldsToInclude: Object.values(this._coveoMappingList),
      lang: getFallbackLanguage(),
    };
    const queryParams = [
      {
        key: this._coveoMappingList.productId,
        value: productIds,
      },
      {
        ...ticketValidityMap,
      },
    ];

    return getB2CProductDetails({
      ...coveoDefaultParams,
      queryParams: queryParams,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    }).then((res) => {
      let mappedProductsData = createMappedData(res.results, this._coveoMappingList);

      let eventTitle = mappedProductsData[0].eventTitle ? mappedProductsData[0].eventTitle[0] : '';

      // remove eventName and eventId from products as not required by checkbasket.
      mappedProductsData = mappedProductsData.map((product) => {
        delete product.eventTitle;
        delete product.eventId;
        return product;
      });

      return { products: mappedProductsData, eventTitle: eventTitle };
    });
  };

  enrichData = (products, cartItems, order) => {
    const isNonSeated = this._isNonSeatedJourney;
    const result = products.map((prod) => {
      const cartItem = cartItems.find((or) => or.productId === prod.productId);

      //For Seated Event from and to date will be picked up from items performances
      if (!isNonSeated) {
        if (order.items && order.items.length && order.items[0].performances && order.items[0].performances.length) {
          prod.fromDate = moment.utc(order.items[0].performances[0].date).format(moment.HTML5_FMT.DATE);
          prod.toDate = moment.utc(order.items[0].performances[0].endDate).format(moment.HTML5_FMT.DATE);
        }
        prod.timeStamp = order.date;

        prod.seatId = cartItems[0].tickets.map((seat) => {
          return seat.Seat.seatId;
        });
      }

      prod.productIdType = getProdIdType(prod);
      const { net, gross, tax } = isNonSeated ? prod : cartItem.price;
      const { fee } = (!isNonSeated && order.total) || 0;
      prod.price = {};
      prod.price.gross = Number(gross) || 0;
      prod.price.net = Number(net) || 0;
      prod.price.tax = Number(tax) || 0;
      if (!isNonSeated && fee) {
        prod.price.fee = Number(fee) || 0;
      }

      prod.unitPrice = prod.unitPrice || prod.gross;

      const quantity = isNonSeated ? prod.quantity : cartItem.quantity;
      prod.quantity = quantity;
      prod.currQuantity = quantity;
      prod.groupedQuantity = quantity;

      if (cartItem) {
        prod.performanceId = isNonSeated
          ? cartItem.performanceId
          : cartItem.performances && cartItem.performances.length > 0
          ? cartItem.performances[0].performanceId
          : '';
        const guestDetails = isNonSeated ? cartItem.guestDetails || null : prod.guestDetails || null;
        prod.guestDetails = guestDetails; //for checkbasket
      }

      prod.discount = prod.discount || {}; //for checkbasket
      prod.discount.actualPerc = prod.discount.actualPerc || 0; //for checkbasket
      prod.discount.computed = prod.unitPrice ? prod.unitPrice - prod.gross : 0;

      delete prod['description'];

      return prod;
    });
    return result;
  };

  catchHandler = async (error) => {
    console.error(error);
    return await { product: [] };
  };

  /**
   * Create seat map and save to localStorage
   */
  createSeatMap = (items, mappedProducts) => {
    //Save Ticket Details in localstorage
    const seatMap = {};

    items.forEach((product) => {
      const key = product.productId;
      const mappedProduct = mappedProducts.find((p) => p.productId === product.productId);

      seatMap[key] = getSortedYaTickets(product.tickets)
        .map((ticket) => {
          const seat = {
            seat: `${ticket.Seat.rowLabel} ${ticket.Seat.colLabel}`,
            stand: mappedProduct.productName,
            seatId: parseInt(ticket.Seat.seatId),
            col: parseInt(ticket.Seat.col),
            row: parseInt(ticket.Seat.row),
          };
          return seat;
        })
        .sort((firstSeat, secondSeat) => {
          return firstSeat.row - secondSeat.row || firstSeat.col - secondSeat.col;
        });
    });

    return seatMap;
  };

  /**
   * Aborts Order when User change or delete cart
   * @param {[string]}    mode - change or remove - when mode is changes user redirected to viva else renders empty cart
   * @param [[Array]]     products - list of products deleted
   */
  onCartAndChange = (mode, products) => {
    const performance = YALocalStorageSvc.getSetPerformance();
    //Depending upon mode change or remove perform action.
    if (mode === UIConfig.b2c.purchaseJourney.yasArenaCart.overlayModes.change) {
      YaCartManagementService.handleRedirection(
        UIConfig.b2c.purchaseJourney.yasArenaCart.callerPage.payment,
        {
          vivaCartPageUrl: this._seatSelectionRequest,
          returnUrl: this._seatSelectionResponse,
        },
        this._selectedEvent,
      );
    } else {
      // incase delete action render empty cart
      products &&
        performance &&
        yaRemoveItemAnalytics({ products: products, timeSlot: performance.timeSlot, date: performance.date });
    }
  };

  /**
   *  Handles redirect to viva cart page. this method is responsible for :
   *      -  Save/Read event and redirect url configuration in localstorage
   *      -  gets performance Id and redirects to viva cart page
   *      -
   *  @param  {[string]}  callerPage  page from which this method is called ex. home or payment
   *  @param  {[string]}  eventId Id of an selected event.
   *  @param  {[string]}  vivaCartPageUrl URL of viva cart page
   *  @param  {[string]}  startDate   start date of an event
   *  @param  {[string]}  endDate end date of an event
   *  @param  {[string]}  performanceSvc performance service url
   *  @param  {[string]}  returnUrl   yas arena payment page url
   */

  static handleRedirection = (callerPage, { eventId, vivaCartPageUrl, returnUrl, performanceId }, selectedEvent) => {
    let eventDetails;
    const { multidateEvent } = UIConfig.b2c.purchaseJourney.yasArenaCart.callerPage;
    // When called from home page
    if (callerPage === multidateEvent) {
      eventDetails = {
        eventId,
        performanceId,
        returnUrl,
        vivaCartPageUrl,
        isPromotionalEvent: selectedEvent && selectedEvent.isPromotionalEvent,
        isPromoCodeRequired: selectedEvent && selectedEvent.isPromoCodeRequired,
      };

      YALocalStorageSvc.getSetEventDetails(eventDetails);
    } else {
      // called from Payments Page
      eventDetails = YALocalStorageSvc.getSetEventDetails();
    }
    if (!eventDetails) {
      return null;
    }

    if (
      eventDetails.vivaCartPageUrl &&
      eventDetails?.vivaCartPageUrl?.indexOf('?') > -1 &&
      eventDetails.performanceId
    ) {
      redirectTo(
        `${eventDetails.vivaCartPageUrl}${eventDetails.vivaCartPageUrl.indexOf('?') > -1 ? '&' : '?'}performanceAk=${
          eventDetails.performanceId
        }&return=${eventDetails.returnUrl}`,
      );
      return;
    }

    redirectTo(returnUrl);
  };
}

export class YALocalStorageSvc {
  static _getDecryptedValue = (key) => {
    const item = localStorage.getItem(key);

    if (item) {
      return JSON.parse(decryptParam(item));
    }

    return {};
  };

  static _setEncryptedValue = (key, value) => {
    setLocalStorage(key, encryptParam(JSON.stringify(value)));
  };

  static getSetOrderId = (orderId) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(yasArenaEventId, orderId);
  };

  static getSetEventDetails = (eventDetails) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(yasArenaEventDetails, eventDetails);
  };
  static getSetEventTitle = (eventTitle) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(yasArenaEventTitle, eventTitle);
  };
  static getSetCartTitle = (isSet = false) => {
    const cartTitle = YALocalStorageSvc.getSetEventTitle();
    if (!canUseDOM()) return {};

    if (isSet) {
      return YALocalStorageSvc._setLocalStore(yasArenaCartEventTitle, cartTitle);
    } else {
      const store = YALocalStorageSvc._getDecryptedValue(yaStore);
      return store[yasArenaCartEventTitle] || '';
    }
  };

  static getSetStaticUrl = (url) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(staticUrlDetail, url);
  };

  static getSetSeatInfo = (seatInfo) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(yasArenaSeatInfo, seatInfo);
  };

  static getSetPerformance = (performance) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(yasArenaPerformanceDetails, performance);
  };

  static getSetVisitedEvents = (visitedEvent) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(yasArenaVisitedEvents, visitedEvent);
  };

  static hadSelectedNonSeatedEvent = () => {
    const visitedEvents = YALocalStorageSvc.getSetVisitedEvents();
    return visitedEvents ? visitedEvents.isNonSeatedEvent : false;
  };

  static getEventTitle = () => {
    const eventDetails = YALocalStorageSvc.getSetEventDetails();
    return eventDetails ? eventDetails.eventTitle : '';
  };

  static getSetOrderDetails = (orderDetails) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(yasArenaCreateOrder, orderDetails);
  };

  static removeSelectedPerformanceDetails = () => {
    const ls = YALocalStorageSvc._getDecryptedValue(yaStore);
    const keysToRemove = [
      yasArenaSeatInfo,
      yasArenaEventId,
      yasArenaPerformanceDetails,
      yasArenaCreateOrder,
      addOnsAvailability,
    ];
    for (const key of keysToRemove) {
      delete ls[key];
    }
    YALocalStorageSvc._setEncryptedValue(yaStore, ls);
  };

  static removePromotions = () => {
    const ls = YALocalStorageSvc._getDecryptedValue(yaStore);
    delete ls[promotionDetail];
    YALocalStorageSvc._setEncryptedValue(yaStore, ls);
  };

  static removeSeatInfoDetails = () => {
    if (canUseDOM()) {
      localStorage.removeItem(yaStore);
      // Also clearing the timer.
      localStorage.removeItem('cartTimerEndDate');
    }
  };

  static getSetAddonsAvailabilty = (availabilityMapping) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(addOnsAvailability, availabilityMapping);
  };

  static getSetPromotionDetail = (promoDetails) => {
    return YALocalStorageSvc._getSetValueFromLocalStore(promotionDetail, promoDetails);
  };

  static _getSetValueFromLocalStore = (key, value) => {
    if (!canUseDOM()) return {};

    if (!value) {
      const store = YALocalStorageSvc._getDecryptedValue(yaStore);
      return store[key] || {};
    } else {
      return YALocalStorageSvc._setLocalStore(key, value);
    }
  };

  static _setLocalStore = (key, value) => {
    let ls = YALocalStorageSvc._getDecryptedValue(yaStore);

    ls = {
      ...ls,
      [key]: value,
    };

    YALocalStorageSvc._setEncryptedValue(yaStore, ls);
  };
}
