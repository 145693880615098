/**
 * sitemap-component.js
 * This file contains code for sitemap in the website
 * @author      Miral
 * @license     Miral
 */

import React, { useRef, useState } from 'react';
import DynamicContent from '../../presentation/base/dynamic-content/dynamic-content-component';
import { logComponentRenderingError } from '../../../common/logger';
import { canUseDOM, isMatchTenant, resolvePath, toLowerCase } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { bodyClicks } from '../../../common/analytics-events';
import GTMData from '../b2c-purchase-journey/gtm-data';

const createSiteMap = (siteArr, numberOfColumn) => {
  let HeadersArr = [];
  let ColumnsArr = [];
  let counter = 0;
  let columnCount = numberOfColumn || 4;
  siteArr.childPages.map((siteItem, siteIdx) => {
    if (siteItem.childPages && siteItem.childPages.length) {
      const childLength = siteItem.childPages.length;
      if (Math.floor((Math.ceil(childLength / 10) + counter - 1) / columnCount) === Math.floor(counter / columnCount)) {
        siteItem.childPages.forEach((childItem, childIdx) => {
          if (childIdx % 10 === 0) {
            counter++;
            if (childIdx === 0) {
              HeadersArr.push([siteItem.title, siteItem.url]);
            } else {
              HeadersArr.push('');
            }
            ColumnsArr.push([]);
          }
          ColumnsArr[ColumnsArr.length - 1].push(childItem);
        });
      } else {
        while (counter % columnCount !== 0) {
          HeadersArr.push('');
          ColumnsArr.push([]);
          counter++;
        }
        siteItem.childPages.forEach((childItem, childIdx) => {
          if (childIdx % 10 === 0) {
            counter++;
            if (childIdx === 0) {
              HeadersArr.push([siteItem.title, siteItem.url]);
            } else {
              HeadersArr.push('');
            }
            ColumnsArr.push([]);
          }
          ColumnsArr[ColumnsArr.length - 1].push(childItem);
        });
      }
    } else {
      HeadersArr.push([siteItem.title, siteItem.url]);
      ColumnsArr.push([]);
      counter++;
    }
  });
  return { HeadersArr, ColumnsArr };
};

const toggle = (headerIdx, childIdx) => {
  const element = document.getElementsByClassName(`chevron-child${headerIdx}${childIdx}`)?.[0];
  if (!element.classList.contains('close')) {
    element.classList.add('close');
  } else {
    element.classList.remove('close');
  }
};

//GA Variables Starts here
const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;

const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
  if (checkfortenants) {
    bodyClicks(eventName, titleData, labelData);
  }
  if (isMatchTenant(UIConfig.tenants.yi)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
      name: toLowerCase(labelData) || '',
      [UIConfig.ga4Constants.ELEMENTTEXT]: toLowerCase(titleData) || '',
      category: 'sitemap',
    });
  }
};
//GA Variables Ends here

const renderLinks = (links, numberOfColumn, sitemaptitle) => {
  const sitemapDetails = createSiteMap(links, numberOfColumn);

  return (
    <div className="sitemap-link-block">
      {sitemapDetails.HeadersArr.map((item, headIdx) => {
        return (
          <div className="block">
            <div className={`parent-title ${!item ? 'empty-parent' : ''}`}>
              <a
                href={item ? item[1] : ''}
                onClick={() => {
                  googleAnalyticHandlerbody(
                    UIConfig.commonVariant.gaClickEvents.linkClick,
                    sitemaptitle ? sitemaptitle : '',
                    item ? item[0] : '',
                  );
                }}
              >
                {item ? item[0] : 'dummy'}
              </a>
            </div>

            <div className="child">
              {sitemapDetails.ColumnsArr[headIdx].map((child, childIdx) => {
                return (
                  <ul>
                    {child.childPages && child.childPages.length > 0 && (
                      <span
                        className={`chevron-child${headIdx}${childIdx} chevron`}
                        onClick={() => toggle(headIdx, childIdx)}
                      ></span>
                    )}
                    <li className={`${child.childPages && child.childPages.length > 0 ? 'child-first-li' : ''}`}>
                      <a
                        href={child.url}
                        onClick={() => {
                          googleAnalyticHandlerbody(
                            UIConfig.commonVariant.gaClickEvents.linkClick,
                            item ? item[0] : '',
                            child.title,
                          );
                        }}
                      >
                        {child && child.title}
                      </a>
                    </li>
                    <ul className="gChild">
                      {child &&
                        child.childPages &&
                        child.childPages.length > 0 &&
                        child.childPages.map((grandChild, k) => {
                          return (
                            <li className="grand-child">
                              <a
                                href={grandChild.url}
                                onClick={() => {
                                  googleAnalyticHandlerbody(
                                    UIConfig.commonVariant.gaClickEvents.linkClick,
                                    child.title,
                                    grandChild.title,
                                  );
                                }}
                              >
                                {grandChild && grandChild.title}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </ul>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Sitemap = (props) => {
  try {
    const {
      data: { sitemap, title, numberOfColumn },
    } = props;

    return (
      <div className="c-sitemap component">
        {/* <DynamicContent tagName="div" innerHtml={title} attrs={{ className: 'sitemap-title' }} /> */}
        {/* <DynamicContent tagName="div" innerHtml={sitemap.title} attrs={{ className: 'sitemap-header' }} /> */}
        <h1 className="sitemap-title">{title}</h1>

        <div className="sitemap-header">
          <a
            href={sitemap.url}
            onClick={() => {
              googleAnalyticHandlerbody(
                UIConfig.commonVariant.gaClickEvents.linkClick,
                title ? title : '',
                sitemap.title ? sitemap.title : '',
              );
            }}
          >
            {sitemap.title}
          </a>
        </div>

        {renderLinks(sitemap, numberOfColumn, title ? title : '')}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Sitemap');
  }
};

export default Sitemap;
