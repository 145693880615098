import React, { useEffect, useRef } from 'react';

const DeviceFingerPrint = () => {
  const textareaRef = useRef(null); // Create a ref for the textarea

  useEffect(() => {
    // Script content as a string
    const scriptContent = `
      var io_bbout_element_id = 'device_fingerprint';
      var io_install_stm = false;
      var io_exclude_stm = 0;
      var io_install_flash = false;
      var io_enable_rip = true;
    `;

    // Create a script element for the script content
    const inlineScript = document.createElement('script');
    inlineScript.type = 'text/javascript';
    inlineScript.text = scriptContent;
    document.body.appendChild(inlineScript);

    // Create a script element for the external script
    const externalScript = document.createElement('script');
    externalScript.type = 'text/javascript';
    externalScript.src = 'https://mpsnare.iesnare.com/snare.js';
    document.body.appendChild(externalScript);

    // Function to check the textarea value and store it in localStorage
    const storeFingerprint = () => {
      const value = textareaRef.current.value;
      if (value) {
        // Check if the value is not empty
        localStorage.setItem('device_fingerprint', value);
      }
    };

    // Polling the textarea for changes
    const intervalId = setInterval(() => {
      if (textareaRef.current && textareaRef.current.value) {
        storeFingerprint();
        clearInterval(intervalId); // Stop polling once a value is detected
      }
    }, 1000); // Check every 1000 milliseconds (1 second)

    // Cleanup function
    return () => {
      document.body.removeChild(inlineScript);
      document.body.removeChild(externalScript);
      clearInterval(intervalId); // Clear the interval on component unmount
      localStorage.removeItem('io_temp');
      localStorage.removeItem('device_fingerprint');
    };
  }, []);

  return (
    <>
      <form action="" method="post" name="submitForm" id="loginSubmitForm">
        <fieldset>
          <ul>
            <textarea
              ref={textareaRef}
              name="device_fingerprint"
              id="device_fingerprint"
              style={{ width: '945px', height: '250px' }}
              hidden
            />
          </ul>
        </fieldset>
      </form>
    </>
  );
};

export default DeviceFingerPrint;
