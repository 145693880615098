/**
 * Desc: Accessibility function for Menu bar
 */

import { MenubarItem } from './MenuBarItemLinks';

var Menubar = function(domNode) {
  this.domNode = domNode;
  this.menubarItems = []; // See Menubar init method
  this.hasHover = false; // See Menubar handleMouseover, handleMouseout
};

/*
 *   @method Menubar.prototype.init
 *
 *   @desc
 *       Adds ARIA role to the menubar node
 *       Traverse menubar children for A elements to configure each A element as a ARIA menuitem
 *       and populate menuitems array. Initialize firstItem and lastItem properties.
 */

Menubar.prototype.init = function() {
  var menubarItem, menuElement, numItems; /* textContent, childElement,*/

  // Traverse the element children of menubarNode: configure each with
  // menuitem role behavior and store reference in menuitems array.
  let elemLi = this.domNode && this.domNode.querySelectorAll('li.has-submenu');
  if (elemLi) {
    for (let node of elemLi) {
      menuElement = node.querySelector('a');
      if (menuElement) {
        menubarItem = new MenubarItem(menuElement, this);
        menubarItem.init();
        this.menubarItems.push(menubarItem);
      }
    }
  }

  // Use populated menuitems array to initialize firstItem and lastItem.
  numItems = this.menubarItems.length;
  if (numItems > 0) {
    this.firstItem = this.menubarItems[0];
    this.lastItem = this.menubarItems[numItems - 1];
  }
};

export { Menubar };
