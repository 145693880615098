import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import {
  breakpoints,
  canUseDOM,
  carouselDefaultSettings,
  currentLocale,
  deepCloneObject,
  detectEdge,
  detectMobile,
  detectViewPort,
  getMainObject,
  isTouchDevice,
  momentTimezone,
  resolvePath,
  isZoomIn,
  getLoggedInUser,
  isLoggedInUser,
  getLoginUser,
  throttle,
  openIAMOverlay,
  isBuyButton,
  getCardButtonLabel,
  checkParksTenants,
  decodeQueryString,
  callToAction,
  getCookie,
  getFallbackLanguage,
  getErrorMap,
  isEmpty,
  createMappedData,
  flipObjectKeyValues,
  createDeepCopy,
  redirectTo,
  checkTenant,
  isMatchTenant,
  enableCalendarOnListingPage,
  toLowerCase,
} from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import GTMData from '../../container/b2c-purchase-journey/gtm-data';
import { AnchorLink, Carousel, DynamicContent, SvgSprite } from '../base';
import Image from '../base/image/image-component';
import CarouselInCard from '../carousel-in-card';
import { logComponentRenderingError } from '../../../common/logger';
import WishlistIcon from '../wishlist-icon';
import moment from 'moment';
import classNames from 'classnames';
import IAMPopup from '../iam-popup/iam-popup';
import { ServiceConfig } from '../../../common/services';
import ApiWrapper from '../../../common/api-wrapper';
import { getB2CProductDetails } from '../../../common/coveo-api';
import { secProds } from '../../container/b2c-purchase-journey/add-ons/add-ons-utility';

import './feature-grid-component.scss';
import { bodyClicks } from '../../../common/analytics-events';
import Slider from 'react-slick';
import get from 'lodash/get';
import AddOnsEventComponent from '../../container/b2c-purchase-journey/add-ons/add-ons-event';
import DrivingExperienceB2C from '../../container/b2c-purchase-journey/add-ons/b2c-driving-experience/b2c-driving-experience-component';
import AddOnsOverlayComponent from '../../container/b2c-purchase-journey/add-ons-overlay/add-ons-overlay-component';
import CalenderOverlay from '../../container/b2c-purchase-journey/booking-overlay/calender-overlay-component';

const GTMDataOnClickCTA = (data) => {
  if (isMatchTenant(UIConfig.tenants.fwad)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
      name: data.name,
      elementText: data.elementText,
      category: data.category,
    });
  }
};

/**
 * FeatureGrid Class ( which extends the React.Component) contains the
 * functionality of creating tiles
 * Depends on following component FeatureTile
 * @param   {[Array]} tiles array of tiles which has to be created
 * @param   {String} classes string of classes to be appended
 * @return  {[Object]} Return a render function which conatins the JSX of the component.
 */
const CAROUSEL_VARIANT = {
  carouselView: 'v-tab-feature-carousel-view',
};

let CAROUSEL_SETTING = {
  arrows: false,
  autoplay: false,
  centerMode: true,
  dots: true,
  rtl: false,
  slidesToScroll: 1,
  slidesToShow: 2,
  centerPadding: '47px',
};
let CAROUSEL_SETTING1 = {
  arrows: false,
  autoplay: false,
  dots: true,
  rtl: false,
  slidesToScroll: 1,
  slidesToShow: 1,
};

class FeatureGrid extends React.Component {
  constructor(props) {
    super(props);
    // to dynamic import of respective locale
    this.activeLocale = currentLocale();
    this.queryParams = decodeQueryString();
    require(`moment/locale/${this.activeLocale}`);
    this.isCoveoComponent = props.tiles[0].listType || this.props.tiles[0].finePrint ? true : false;
    this.tilesToLoad = this.props.tilesToShowInitially;
    this.dynamicLoadMore = checkParksTenants() && !isMatchTenant(UIConfig.SWADB2C);
    this.state = {
      mobileView: false,
      defaultTileShow: this.tilesToShowInitially(),
      showCards: [],
      pageIndex: (this.queryParams.page && Number(this.queryParams.page)) || 2,
      startIndex: this.getStartIndex(),
      totalLoadedTitles: this.tilesToShowInitially(),
    };
    this.initialPage = 1;
    this.loadMoreUrl =
      canUseDOM() &&
      window.location.pathname + '?page=' + this.state.pageIndex + '&startIndex=' + this.state.startIndex;
    this.loadMoreUrl =
      this.props.includeTabs && this.props.tabType !== 'All'
        ? this.loadMoreUrl + '&tabType=' + this.props.tabType
        : this.loadMoreUrl;

    this.cssClasses = this.props.classes ? this.props.classes : '';
    let customCarouselSettings = {};
    this.isListingCarouselOnAllView =
      this.props.carouselData && this.props.carouselData.isListingCarouselOnAllView
        ? this.props.carouselData.isListingCarouselOnAllView
        : false;
    this.isCarouselInsideCard =
      this.props.carouselData && this.props.carouselData.isCarouselInsideCard
        ? this.props.carouselData.isCarouselInsideCard
        : false;
    this.carouselInsideClass = this.isCarouselInsideCard ? ' tab-feature-list-carousel-in-card' : '';
    const viewport = detectViewPort();
    this.panelIndex = this.props.index;
    if (this.isListingCarouselOnAllView) {
      this.setCarouselSettingCarouselOnAllView(viewport);
      customCarouselSettings = this.allViewSettings(customCarouselSettings, viewport);
    } else if (props.variant === CAROUSEL_VARIANT.carouselView) {
      customCarouselSettings = this.allViewSettings(customCarouselSettings, viewport);
    } else {
      customCarouselSettings = this.getCrouselSettings();
    }
    this.settings = Object.assign(
      {},
      deepCloneObject(carouselDefaultSettings),
      deepCloneObject(customCarouselSettings),
    );
    if (this.props.tiles.length <= 2) {
      this.settings.infinite = false;
    }

    // this makes card swipe quicker with less delay
    this.settings.speed = 200;
    this.slider = React.createRef();
    this.carouselDiv = React.createRef();
    this.isYMCTenant = false;
    this.isEATenant = false;
    this.isListOfMorePackagesVariant = this.props.variant === UIConfig.commonVariant.listOfMorePackagesWithFilter;
  }
  /* 
  increment number of tiles to show initialy if the dynamic is enabled and if the grid variation is two-grid-layout,
  if the initial tiles to show number is odd and the full width is enabled then OR the full width is disabled and the initial
  tiles to show is even then we need to increment to avoid empty space,
  */
  incrementTilesToShowInitially = (oldValue) => {
    const isTilesCountOdd = !!(this.props.tiles && this.props.tiles.length % 2);

    if (!oldValue) return;
    if ((isTilesCountOdd && !(parseInt(oldValue) % 2)) || (!isTilesCountOdd && parseInt(oldValue) % 2)) {
      return (parseInt(oldValue) + 1).toString();
    }
    return oldValue;
  };
  convertTilesToShowInitially = () => {
    if (this.props.isDynamicListEnabled)
      return {
        mobile: this.props?.tilesToShowInitially?.mobile,
        tablet: this.props?.tilesToShowInitially?.tablet,
        desktop: this.incrementTilesToShowInitially(this.props?.tilesToShowInitially?.desktop),
      };
    return this.props?.tilesToShowInitially;
  };

  tilesToShowInitially = () => {
    const deviceType = detectViewPort();
    const tilesToShowInitially = this.convertTilesToShowInitially();

    if (this.queryParams.page && !this.dynamicLoadMore) {
      tilesToShowInitially.mobile = 'default';
      tilesToShowInitially.tablet = 'default';
      tilesToShowInitially.desktop = 'default';
    }
    if (deviceType === 'mobile') {
      return (tilesToShowInitially && tilesToShowInitially.mobile) || 'default';
    } else if (deviceType === 'tablet') {
      return (tilesToShowInitially && tilesToShowInitially.tablet) || 'default';
    } else {
      return (tilesToShowInitially && tilesToShowInitially.desktop) || 'default';
    }
  };

  renderPJCallback = (tileData, invokePJCallback, invokePackageJourney, isPackagedProduct) => {
    if (isPackagedProduct) {
      return (
        <div className="buy-ticket-cta">
          <button onClick={() => invokePackageJourney(tileData.productid, tileData.title)}>
            {tileData.buyNowCtaLabel}
          </button>
        </div>
      );
    } else if (tileData.productId)
      return (
        <div className="buy-ticket-cta">
          <button onClick={() => invokePJCallback(tileData.productid, tileData.title)}>
            {tileData.buyNowCtaLabel}
          </button>
        </div>
      );
  };

  getStartIndex = () => {
    const deviceType = detectViewPort();
    const tilesToShowInitially = this.convertTilesToShowInitially();
    if (deviceType === 'mobile') {
      return (tilesToShowInitially && tilesToShowInitially.mobile) || 0;
    } else if (deviceType === 'tablet') {
      return (tilesToShowInitially && tilesToShowInitially.tablet) || 0;
    } else {
      return (tilesToShowInitially && tilesToShowInitially.desktop) || 0;
    }
  };

  customCarouselSettings = (data) => {
    if (!data) {
      return null;
    }
    return {
      className: `c-feature-tile--pagination ${CAROUSEL_SETTING.dots ? 'dot-enabled' : ''}`,
      dotsClass: 'custom-dots',
      customPaging: function(i) {
        return (
          <span>
            <span className="slide-in-view">
              {data.variant === CAROUSEL_VARIANT.carouselView && i < 9 && <span className="prefixed-zero">0</span>}
              {data.tiles && CAROUSEL_SETTING.rtl ? data.tiles.length - i : i + 1}
            </span>
            <span className="separator">/</span>
            <span className="total-slide">
              {data.variant === CAROUSEL_VARIANT.carouselView && data.tiles.length < 10 && (
                <span className="prefixed-zero">0</span>
              )}
              {data.tiles && data.tiles.length}
            </span>
          </span>
        );
      },
      afterChange: (i) => {
        data.tiles.forEach((item) => {
          item.isActive = false;
          if (item.activeCardId === i) {
            item.isActive = true;
          }
        });
        this.setState({
          activeCard: i,
        });
      },
    };
  };

  allViewSettings(customCarouselSettings, viewport) {
    const mainObj = getMainObject();
    const tenantId = getLoggedInUser().tenantID || (mainObj && mainObj.tenantID);
    if (canUseDOM() && isZoomIn() && tenantId && tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
      CAROUSEL_SETTING.arrows = true;
    }
    customCarouselSettings = this.customCarouselSettings(this.props);
    let responsiveCarouselSettings = {
      breakpoint: 100000,
      settings: {
        centerMode: CAROUSEL_SETTING.centerMode || false,
        draggable: CAROUSEL_SETTING.arrows,
      },
    };

    customCarouselSettings = Object.assign(
      {},
      deepCloneObject(carouselDefaultSettings),
      deepCloneObject(customCarouselSettings),
    );
    if (viewport === 'tablet') {
      responsiveCarouselSettings.settings.arrows = false;
    }
    if (canUseDOM() && isZoomIn() && tenantId && tenantId.toLowerCase() === UIConfig.ymcB2CTenant) {
      responsiveCarouselSettings.settings.arrows = true;
    }
    customCarouselSettings.responsive.push(responsiveCarouselSettings);
    customCarouselSettings.slidesToScroll = CAROUSEL_SETTING.slidesToScroll;
    customCarouselSettings.centerMode = CAROUSEL_SETTING.centerMode;
    customCarouselSettings.arrows = viewport === 'tablet' ? false : CAROUSEL_SETTING.arrows;
    customCarouselSettings.rtl = CAROUSEL_SETTING.rtl;
    customCarouselSettings.slidesToShow = CAROUSEL_SETTING.slidesToShow <= 0 ? 3 : CAROUSEL_SETTING.slidesToShow;
    customCarouselSettings.centerPadding = viewport === 'mobile' ? CAROUSEL_SETTING.centerPadding : '0px';
    customCarouselSettings.dots = CAROUSEL_SETTING.dots;
    customCarouselSettings.autoplay = CAROUSEL_SETTING.autoplay;
    customCarouselSettings.swipe = isTouchDevice() || detectEdge();
    return customCarouselSettings;
  }

  getCrouselSettings = () => {
    if (this.props.showDots) {
      return {
        dots: true,
        responsive: [
          {
            breakpoint: 1023,
          },
        ],
      };
    } else {
      let tileCount = this.props.tiles.length;
      return {
        dotsClass: 'no-dots',
        customPaging: function(i) {
          return (
            <span>
              {i + 1}/{tileCount}
            </span>
          );
        },
        responsive: [
          {
            breakpoint: 1023,
          },
        ],
      };
    }
  };
  isBorder = (tileData) => {
    if (this.isCoveoComponent) {
      return tileData.websiteUrl || tileData.bookingUrl || tileData.showDetail === '1' ? true : false;
    } else {
      return tileData.cta.href || tileData.cta2.href ? true : false;
    }
  };

  handleClickOnIcon = (value) => {
    let showCards = this.state.showCards;
    const indexValue = showCards.indexOf(value);
    if (indexValue >= 0) {
      showCards.splice(indexValue, 1);
      this.setState({
        showCards,
      });
    } else {
      showCards = [...showCards, value];
      this.setState({
        showCards,
      });
    }
  };

  renderInfoCard = (tabTileData, index) => {
    return (
      <div className={'info-card ' + (this.state.showCards.indexOf(index) >= 0 ? 'showCard' : '')}>
        {this.renderProductInfo(tabTileData)}
      </div>
    );
  };

  renderProductInfo = (tile) => {
    return (
      <div className="product-info">
        {tile.tagginglabel && tile.tagginglabel.length > 0 && (
          <div className="product-tag">{tile.tagginglabel[tile.tagginglabel.length - 1]}</div>
        )}
        <div className="heading-description-wrapper">
          {tile.cta && tile.cta.href && tile.cta.label && checkParksTenants() ? (
            <a href={tile.cta.href} tabIndex="-1">
              <DynamicContent tabIndex="0" tagName="h3" attrs={{ className: 'heading-3' }} innerHtml={tile.title} />
            </a>
          ) : (
            <DynamicContent tagName="h3" attrs={{ className: 'heading-3' }} innerHtml={tile.title} />
          )}
        </div>
        <div className="price-wrapper">
          {tile.currentprice && (
            <div className="current-price">
              {tile.priceText} <span className="price">{tile.currentprice}</span>
            </div>
          )}
          {tile.netprice && (
            <div className="net-price">
              {tile.priceText} <span className="price">{tile.netprice}</span>
            </div>
          )}
        </div>
        <DynamicContent
          tagName="div"
          attrs={{ className: 'body-copy-4' }}
          innerHtml={this.props.filterVariant === 'v-f1-multi-filter' ? tile.description : tile.bodyCopy}
        />
      </div>
    );
  };

  getDeviceLoadTiles = () => {
    const detectDevice = detectViewPort();
    if (detectDevice === 'mobile' || detectDevice === 'tablet') {
      return Number(this.tilesToLoad[detectDevice]);
    }
    return Number(this.tilesToLoad['desktop']);
  };

  componentDidMount() {
    this.setState({
      mobileView:
        this.props.tiles[0].listType ||
        this.props.tiles[0].finePrint ||
        this.props.variant === 'v-yas-island-feature-tile' ||
        this.props.variant === 'v-yas-island-feature-tile-titleLink' ||
        (this.props.variant === 'v-two-tile-mobile' && this.props.tiles.length <= 2) ||
        this.props.variant === 'v-ya-tab-feature-list-extn-carousel'
          ? false
          : detectMobile(),
    });
    window.PubSub.subscribe('oreintationChange', () => {
      if (window.innerWidth >= breakpoints.desktop) {
        this.setState({ mobileView: false });
      } else {
        if (
          !this.state.mobileView &&
          !this.props.tiles[0].finePrint &&
          this.props.variant !== 'v-yas-island-feature-tile'
        ) {
          this.setState({ mobileView: true });
        }
      }
      this.props.tiles[0].listType || (this.props.variant === 'v-two-tile-mobile' && this.props.tiles.length <= 2)
        ? this.setState({ mobileView: false })
        : Boolean(true);
    });

    window.PubSub.subscribe('resetTileState', () => {
      this.setState({
        showCards: [],
      });
    });

    const tenantId = getLoggedInUser().tenantID || resolvePath(getMainObject(), 'tenantID');
    this.isYMCTenant = (tenantId || '').toLowerCase() === UIConfig.ymcB2CTenant ? true : false;
    this.isEATenant = tenantId === UIConfig.yasArenaB2CTenant ? true : false;
    if (this.dynamicLoadMore) {
      const lang = document.getElementsByTagName('html')[0].getAttribute('lang') || 'en';
      const urlParams = new URLSearchParams(window.location.search);
      for (const [key, value] of urlParams) {
        if (key === 'page') {
          if (Number(value) > 2) {
            window.location.href = `${window.location.origin}/${lang}/error`;
          }
          if (Number(value) === 2) {
            this.setState({ defaultTileShow: this.props.tiles.length });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.PubSub.unsubscribe('resetTileState');
  }

  loadMoreButton() {
    if (this.props.filterVariant !== 'v-event-filter') {
      for (let i = this.tilesToShowInitially(); i < this.tilesToShowInitially() * 2; i++) {
        let link = document.querySelectorAll('.feature-tile-wrapper')[i],
          focusableElm = '';
        if (link.querySelector('.feature-tile--cta')) {
          focusableElm = link.querySelector('.feature-tile--cta');
        } else if (link.querySelector('.heading-5')) {
          focusableElm = link.querySelector('.heading-5');
        }
        if (focusableElm) {
          setTimeout(() => {
            // IE Fix
            focusableElm.focus();
          }, 0);
          return false;
        }
      }
    }
  }

  setCarouselSettingCarouselOnAllView = (deviceType) => {
    CAROUSEL_SETTING = this.props.carouselData.carouselSetting;
    if (deviceType === 'mobile') {
      CAROUSEL_SETTING = {
        ...CAROUSEL_SETTING,
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '11px',
      };
    } else if (deviceType === 'tablet') {
      CAROUSEL_SETTING = {
        ...CAROUSEL_SETTING,
        centerMode: false,
        slidesToShow: 2,
      };
    } else {
      CAROUSEL_SETTING.arrows = this.props.tiles.length < CAROUSEL_SETTING.slidesToShow ? false : true;
    }
  };

  checkCarouselVariation = () => {
    if (this.isListingCarouselOnAllView) {
      return (
        <div className={'carousel-container ' + this.carouselInsideClass}>
          <div className="v-tab-feature-list-carousel-view">
            <Carousel data={this.settings}>
              {this.props.tiles.map((tabTileData, index) => (
                <div key={index}>
                  <FeatureTile
                    key={index}
                    tile={tabTileData}
                    index={index}
                    variation={this.props.variation}
                    showExtraField={this.props.showExtraField}
                    filterVariant={this.props.filterVariant}
                    tileLabels={this.props.tileLabels}
                    isCoveoComponent={this.isCoveoComponent}
                    enablePJPopup={this.props.enablePJPopup}
                    invokePJCallback={this.props.invokePJCallback}
                    invokePackageJourney={this.props.invokePackageJourney}
                    carouselConfigData={this.props.carouselData}
                    pjData={this.props.pjData}
                    addToCartCallback={this.props.addToCartCallback}
                    getDiscountedProducts={this.props.getDiscountedProducts}
                    cartData={this.props.cartData}
                    businessErrors={this.props.businessErrors}
                    soldOutCtaLabel={this.props?.soldoutCtaLabel}
                  />
                  {this.props.filterVariant === 'v-f1-multi-filter' && tabTileData.eventLocationName && (
                    <div className="stand-name">{tabTileData.eventLocationName}</div>
                  )}
                  {this.props.filterVariant === 'v-f1-multi-filter' && this.props.showInfoIcon && (
                    <div
                      className={'info-icon ' + (this.state.showCards.indexOf(index) >= 0 ? 'show-cross-icon' : '')}
                      onClick={() => {
                        isTouchDevice() ? this.handleClickOnIcon(index) : Boolean(false);
                      }}
                    ></div>
                  )}
                  {this.props.filterVariant === 'v-f1-multi-filter' &&
                    this.props.showInfoIcon &&
                    this.renderInfoCard(tabTileData, index)}
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      );
    } else if (this.state.mobileView) {
      return (
        <div className={'carousel-container ' + this.carouselInsideClass} ref={this.carouselDiv}>
          <Carousel data={this.settings} ref={this.slider} getCarouselDivRef={this.getCarouselDivRef}>
            {this.props.tiles.map((tabTileData, index) => (
              <div key={index}>
                <FeatureTile
                  key={index}
                  tile={tabTileData}
                  index={index}
                  pjData={this.props.pjData}
                  addToCartCallback={this.props.addToCartCallback}
                  getDiscountedProducts={this.props.getDiscountedProducts}
                  cartData={this.props.cartData}
                  businessErrors={this.props.businessErrors}
                  soldOutCtaLabel={this.props?.soldoutCtaLabel}
                />
              </div>
            ))}
          </Carousel>
        </div>
      );
    }
  };

  getCarouselDivRef = () => {
    return this.carouselDiv;
  };

  keyDownHandlerInfo = (e, index) => {
    if (e.charCode === 13 || e.keyCode === 13 || e.key === 'Enter') {
      this.handleClickOnIcon(index);
    }
  };

  renderActionButton = ({ free, soldout, upcoming, eventBookingUrl: url, freeActivityFormUrl, endDate }) => {
    const { soldOutLabel, registerLabel, upcomingEventLabel, pastEventLabel } = this.props.tileLabels;
    const currentDate = moment.utc().format(UIConfig.calendar.dateFormat);
    const eventEndDate = moment(endDate)
      .utc()
      .format(UIConfig.calendar.dateFormat);
    const isExpired = moment(eventEndDate).isBefore(currentDate);
    // By default, url is for event booking.
    // If the event is free, then changing the url to free activity.
    if (free == true) {
      url = freeActivityFormUrl;
    }

    const buttonLabel = getCardButtonLabel(
      soldout,
      soldOutLabel,
      free,
      registerLabel,
      this.props.buyNowLabel,
      upcoming,
      upcomingEventLabel,
      pastEventLabel,
      isExpired,
    );

    return (
      <div
        className={classNames('btn-primary', {
          'buy-button': isBuyButton(soldout, free, this.props.theme, upcoming),
        })}
      >
        <Text
          tag="button"
          field={{ value: buttonLabel, editable: buttonLabel }}
          className={classNames({ disabled: soldout === true || upcoming === true || isExpired })}
          onClick={soldout === true || upcoming === true ? null : () => (window.location.href = url)}
        />
      </div>
    );
  };

  // EP-755 - add Travel box related button, only for EA
  renderCTAButton = ({ eventPackageUrlTitle, eventPackageUrl }) => {
    if (!eventPackageUrlTitle || !eventPackageUrl) return null;

    const buttonLabel = eventPackageUrlTitle;
    const url = eventPackageUrl;
    return (
      <>
        <div className="btn-cta-spacer"></div>
        <div className="btn-cta-golden">
          <Text
            tag="button"
            field={{ value: buttonLabel, editable: buttonLabel }}
            onClick={() => (window.location.href = url)}
          />
        </div>
      </>
    );
  };

  preventDefaultClick = (e) => {
    e.preventDefault();
  };

  loadMoreTiles = (isKeyPress) => {
    if (this.dynamicLoadMore) {
      this.initialPage++;
      const hash = window.location.hash;
      window.history.pushState(null, '', `?page=${this.initialPage}${hash}`);
    }
    this.setState(
      {
        defaultTileShow: this.props.tiles.length,
      },
      isKeyPress && this.loadMoreButton(),
    );
  };

  getLoadMoreUrl = () => {
    return window.location.pathname + '?page=' + (this.initialPage + 1) + '&startIndex=' + this.state.startIndex;
  };

  render() {
    const getMainObj = JSON.parse(localStorage.getItem('mainObj'));
    const currentTenant = resolvePath(getMainObj, 'tenantID', '').toLowerCase();
    const YAS_ARENA_FILTER = UIConfig.eventListingFilter.yaFilterVariant;
    try {
      return this.isListingCarouselOnAllView || this.state.mobileView ? (
        this.checkCarouselVariation()
      ) : (
        <div>
          {currentTenant === UIConfig.YIB2C && <div className="loader hide"></div>}
          <ul
            data-c-render="client-only"
            data-c-name="FeatureGrid"
            className={`${this.cssClasses} ${this.carouselInsideClass} ${
              this.isListOfMorePackagesVariant ? 'row' : 'c-feature-grid'
            } ${(this.isListOfMorePackagesVariant || this.props.variant === 'v-yasisland-filter') &&
              'list-of-more-packages'}`}
          >
            {Array.isArray(this.props.tiles) &&
              this.props.tiles.map(
                (tabTileData, index) =>
                  (index < this.state.defaultTileShow || this.state.defaultTileShow === 'default') && (
                    <li
                      className={`${
                        this.isListOfMorePackagesVariant
                          ? 'yas-feature-tile-wrapper col-md-6 col-12 col-lg-4'
                          : 'feature-tile-wrapper'
                      } ${(this.isListOfMorePackagesVariant || this.props.variant === 'v-yasisland-filter') &&
                        'card-wrapper'} ${this.isBorder(tabTileData) ? 'border' : 'no-border'}`}
                      key={index}
                    >
                      {(this.props.filterVariant === 'v-product-list-filter' ||
                        this.props.filterVariant === 'v-f1-multi-filter') &&
                        this.props.showInfoIcon &&
                        !parseInt(tabTileData?.soldoutkey) &&
                        isMatchTenant(UIConfig?.tenants?.ymc) && (
                          <div
                            tabIndex="0"
                            aria-label={this.props.infoAriaLabel}
                            className={
                              'info-icon ' + (this.state.showCards.indexOf(index) >= 0 ? 'show-cross-icon' : '')
                            }
                            onKeyDown={(e) => {
                              isZoomIn() && this.keyDownHandlerInfo(e, index);
                            }}
                            onClick={() => {
                              isTouchDevice() || isZoomIn() ? this.handleClickOnIcon(index) : Boolean(false);
                            }}
                          ></div>
                        )}
                      {(this.props.filterVariant === 'v-product-list-filter' ||
                        this.props.filterVariant === 'v-f1-multi-filter') &&
                        this.props.showInfoIcon &&
                        this.renderInfoCard(tabTileData, index)}
                      <FeatureTile
                        tile={tabTileData}
                        index={index}
                        onlyAllTabs={this.props.includeAllTab && !this.props.includeTabs}
                        variation={this.props.variation}
                        variant={this.props.variant}
                        showExtraField={this.props.showExtraField}
                        filterVariant={this.props.filterVariant}
                        maxLengthForDescription={this.props.maxLengthForDescription}
                        tileLabels={this.props.tileLabels}
                        isCoveoComponent={this.isCoveoComponent}
                        enablePJPopup={this.props.enablePJPopup}
                        invokePJCallback={this.props.invokePJCallback}
                        invokePackageJourney={this.props.invokePackageJourney}
                        carouselConfigData={this.props.carouselData}
                        disableLazyLoadImage={this.props.disableLazyLoadImage}
                        isMorepackagesVariant={this.isListOfMorePackagesVariant}
                        enableWishList={this.props.enableWishList}
                        services={this.props.services}
                        loginCtaUrl={this.props.loginCtaUrl}
                        pjData={this.props.pjData}
                        addToCartCallback={this.props.addToCartCallback}
                        getDiscountedProducts={this.props.getDiscountedProducts}
                        cartData={this.props.cartData}
                        businessErrors={this.props.businessErrors}
                        buyNowCtaLabel={this.props.buyNowLabel}
                        soldOutCtaLabel={this.props?.soldoutCtaLabel}
                      />
                      {this.props.filterVariant === 'v-f1-multi-filter' && tabTileData.eventLocationName && (
                        <div className="stand-name">{tabTileData.eventLocationName}</div>
                      )}
                      {this.isYMCTenant && tabTileData.offerTagText && tabTileData.offerTagText.length && (
                        <div
                          className="offer-tag"
                          style={{
                            backgroundColor: `${tabTileData.offerTagBgColor ? tabTileData.offerTagBgColor : ''}`,
                          }}
                        >
                          {tabTileData.offerTagText}
                        </div>
                      )}
                      {this.props.filterVariant === YAS_ARENA_FILTER && this.renderActionButton(tabTileData)}
                      {this.isEATenant && this.renderCTAButton(tabTileData)}
                    </li>
                  ),
              )}
          </ul>
          {this.state.defaultTileShow < this.props.tiles.length && !this.props.redirectCta?.href && (
            <span
              className="load-more-cta"
              onClick={() => this.loadMoreTiles()}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (this.dynamicLoadMore) {
                    this.loadMoreTiles(true);
                    return;
                  }
                  this.setState({ defaultTileShow: this.props.tiles.length }, () => this.loadMoreButton());
                }
              }}
            >
              <a href={this.getLoadMoreUrl()} onClick={(e) => this.preventDefaultClick(e)}>
                {this.dynamicLoadMore ? (
                  this.props.viewMoreLabel
                ) : (
                  <Text tag="button" field={{ value: this.props.viewMoreLabel, editable: this.props.viewMoreLabel }} />
                )}
              </a>
            </span>
          )}

          {this.props.redirectCta && this.props.redirectCta.href && (
            <div className="view-more-cta-container">
              <AnchorLink link={this.props.redirectCta} className="view-more-cta" />
            </div>
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'FeatureGrid');
    }
  }
}

const isUrlRelative = (urlString) => {
  return urlString.indexOf('http://') === -1 && urlString.indexOf('https://') === -1;
};

const checkURL = (imageData) => {
  const output = deepCloneObject(imageData);
  const mainObj = getMainObject();
  // const staticDomain = mainObj && mainObj.additionalProperty.StaticDomain;
  const staticDomain = 'https://uat-ymc.myconnect.ae';
  if (!staticDomain) {
    return output;
  }
  if (isUrlRelative(output && output.desktopImage && output.desktopImage.src)) {
    output.desktopImage.src = staticDomain + output.desktopImage.src;
  }
  if (isUrlRelative(output && output.tabletImage && output.tabletImage.src)) {
    output.tabletImage.src = staticDomain + output.tabletImage.src;
  }
  if (isUrlRelative(output && output.mobileImage && output.mobileImage.src)) {
    output.mobileImage.src = staticDomain + output.mobileImage.src;
  }
  return output;
};

const FeatureTile = ({
  tile,
  showExtraField,
  tileLabels,
  index,
  classes = '',
  variation,
  variant,
  enableWishList,
  services,
  loginCtaUrl,
  isCoveoComponent,
  filterVariant,
  enablePJPopup,
  invokePJCallback,
  invokePackageJourney,
  carouselConfigData,
  disableLazyLoadImage,
  isMorepackagesVariant,
  maxLengthForDescription,
  pjData,
  addToCartCallback,
  getDiscountedProducts,
  cartData,
  businessErrors,
  buyNowCtaLabel,
  onlyAllTabs = false,
  soldOutCtaLabel,
}) => {
  const YAS_ARENA_FILTER = UIConfig.eventListingFilter.yaFilterVariant;
  let activeLocale = currentLocale();
  let localization = require(`moment/locale/${activeLocale}`);
  let dateFormat = 'ddd, MMM D, Y';
  const isSwad = checkTenant(UIConfig.iamMapping.swad);

  if (activeLocale === 'ar-sa') {
    moment.defineLocale('ar-modified', {
      parentLocale: 'ar-sa',
      preparse: function(string) {
        return string;
      },
      postformat: function(string) {
        return string;
      },
    });

    activeLocale = 'ar-modified';
    dateFormat = 'dddd, MMM D, Y';
  }

  const iconClass = tile.iconData ? 'lisiting-icon' : '';
  const renderPriceText = () => {
    const mainObj = (canUseDOM() && JSON.parse(window.localStorage.getItem('mainObj'))) || {};
    if (GTMData.getTenantId() === UIConfig.ymcB2CTenant) {
      return typeof tile.price !== 'undefined' || typeof tile.fromPrice !== 'undefined'
        ? `${tile.priceText} ${mainObj.currency} ${tile.price || tile.fromPrice}`
        : `${tile.priceText}`;
    } else {
      return typeof tile.gaPrice !== 'undefined' ||
        typeof tile.price !== 'undefined' ||
        typeof tile.fromPrice !== 'undefined'
        ? `${tile.priceText} ${mainObj.currency} ${tile.gaPrice || tile.price || tile.fromPrice}`
        : `${tile.priceText}`;
    }
  }; // add only for parks
  const isTileClickable =
    (filterVariant === 'v-venue-find-filter' ||
      filterVariant === 'v-event-filter' ||
      filterVariant === YAS_ARENA_FILTER) &&
    tile.cta &&
    tile.cta.href;

  const coveoDefaultParams = {
    perPageResults: UIConfig.b2c.purchaseJourney.coveoResultsPerPage,
    coveoKeyMap: pjData?.purchaseJourneyData?.coveoMappingList,
    serviceUrl: pjData?.purchaseJourneyData?.services.getCoveoProducts.url,
    fieldsToInclude:
      pjData?.purchaseJourneyData?.coveoMappingList && Object.values(pjData?.purchaseJourneyData?.coveoMappingList),
    lang: getFallbackLanguage(),
  };

  const componentToAppend = ({ props, closeOverlay, isDrivingExp, ticketDetails }) => {
    const {
      additionalProds,
      addToCartCallback,
      businessErrors,
      cartData,
      category,
      coveoMapList,
      data,
      getDiscountedProducts,
      overlayErrorCallback,
      searchProductList,
      services,
      ticket,
      timeSlotSelector,
      variant,
      showMinicartCouponCode,
      discountMap,
      enablePJPopup,
      copySearchProductList,
    } = props;

    let Component = CalenderOverlay;
    if (data.type === 'extras' || data.type === 'experiences') {
      Component = AddOnsOverlayComponent;
    } else if (data.isEventQuantityOverlay) {
      Component = AddOnsEventComponent;
    }
    if (isDrivingExp) {
      Component = DrivingExperienceB2C;
    }

    return (
      <Component
        data={data}
        product={ticket}
        counterData={ticketDetails.visitorSelector}
        calenderData={ticketDetails.dateSelector}
        addToCartCallback={addToCartCallback}
        closeOverlay={closeOverlay}
        services={services}
        multiTicketSelector={category.multiTicketSelector}
        productOverlaySelector={category.productOverlaySelector}
        showProductNameInOverlay={category.showProductNameInOverlay}
        getDiscountedProducts={getDiscountedProducts}
        timeSlotData={timeSlotSelector}
        cartData={cartData}
        businessErrors={businessErrors}
        additionalProds={additionalProds}
        coveoMapList={coveoMapList}
        searchProductList={searchProductList}
        copySearchProductList={copySearchProductList}
        showMinicartCouponCode={showMinicartCouponCode}
        discountMap={discountMap}
        enablePJPopup={enablePJPopup}
        variant={variant}
        overlayErrorCallback={overlayErrorCallback}
        legends={category.legends}
        moneyCardDetails={category.moneyCardAmount}
        enableDynamicCalendar={category?.enableDynamicCalendar}
        attentions={ticketDetails?.attentions}
        attentionTitle={ticketDetails?.attentionsLabel}
        recommendationUrl={props.recommendationUrl}
        subCategory={category.coveoValue}
        disableTotalPrice={category.disableTotalPrice}
        childVariant={props.variant}
        overlayShowPrice={ticketDetails?.prices}
        enableTimeSlotExperience={category?.enableTimeSlotExperience}
      />
    );
  };

  const changeCategoryToString = (prods) => {
    const tabKey = coveoDefaultParams.coveoKeyMap['tab'];
    return prods.map((product) => {
      if (product[tabKey] && product[tabKey] instanceof Array) {
        product[tabKey] = product[tabKey].sort().join('');
      }
      return product;
    });
  };
  const getCatAndSubCat = (categories, subCategories) => {
    const tab = pjData?.purchaseJourneyData?.tabs?.find(
      (tab) =>
        categories.includes(tab.coveoValue) &&
        tab?.controls?.extras?.options?.find((item) => subCategories.includes(item.coveoValue)),
    );

    const subTab = tab?.controls?.extras?.options?.find((item) => subCategories.includes(item.coveoValue));
    return { tab, subTab };
  };
  const getProductStore = (categories, subCategories) => {
    let tabs = {};
    if (pjData.purchaseJourneyData?.tabs?.length) {
      pjData.purchaseJourneyData.tabs.forEach(
        (tab) => (tabs[tab.coveoValue] = { key: tab.coveoKey, value: tab.coveoValue }),
      );
    }

    const { tab, subTab } = getCatAndSubCat(categories, subCategories);
    return new Promise((resolve, reject) => {
      let params = tabs[tab?.coveoValue] ? [tabs[tab?.coveoValue]] : [];
      params.push({ key: pjData.purchaseJourneyData.coveoMappingList.disabledProduct, value: '0' });
      params.push({ key: pjData.purchaseJourneyData.coveoMappingList.productSubCategory, value: subTab?.coveoValue });

      getB2CProductDetails({
        ...coveoDefaultParams,
        queryParams: params,
      })
        .then((res) => {
          res.results = changeCategoryToString(res.results);
          resolve(res.results);
        })
        .catch((res) => {
          const errObj = getErrorMap(
            'getCoveoProducts',
            pjData.purchaseJourneyData.services.getCoveoProducts.errors,
            false,
            res.error,
          );
          reject(errObj);
        });
    });
  };
  const groupCounterProducts = (products, filterCat) => {
    const grouppedProducts = {};
    products.forEach((product) => {
      let category =
        filterCat && filterCat.length > 0 && filterCat.find((element) => product?.category.includes(element));
      if (product.category && product.category.length > 0 && category && !isEmpty(category)) {
        if (!grouppedProducts.hasOwnProperty(category)) grouppedProducts[category] = [];
        grouppedProducts[category].push(product);
      }
    });
    return grouppedProducts;
  };

  const groupCounterProductsSecProd = (products, filterCat) => {
    return products.filter(
      (product) =>
        filterCat &&
        filterCat.length &&
        filterCat.includes(product?.category && product?.category.length && product?.category[0]),
    );
  };
  const updateSecProdArray = (prod, type, map) => {
    secProds[type] = secProds[type] || {};
    switch (prod.experienceCatgory) {
      case map.secondary:
        secProds[type].secondary = secProds[type].secondary || [];
        secProds?.[type]?.secondary?.productId !== prod.productId && secProds[type].secondary.push(prod);
        break;
      case map.addOnGuest:
        secProds.addOnGuest = secProds.addOnGuest || [];
        secProds?.[type]?.addOnGuest?.productId !== prod.productId && secProds.addOnGuest.push(prod);
        break;
      case map.primary:
        secProds[type].primary = secProds[type].primary || [];
        secProds?.[type]?.primary?.productId !== prod.productId && secProds[type].primary.push(prod);

        break;
      default:
        break;
    }
  };
  const showCalendarOverlay = async (tile) => {
    const prods = await getProductStore(tile.categoryKey, tile.subCategory);

    const { tab, subTab } = getCatAndSubCat(tile.categoryKey, tile.subCategory);

    const category = subTab;
    const categoriesOrder = tab?.controls?.extras.options.map((category) => category.coveoValue);
    const mappedProducts = createMappedData(prods, pjData.purchaseJourneyData.coveoMappingList);
    const counterProducts = groupCounterProducts(mappedProducts, categoriesOrder);
    let final_prods = counterProducts[subTab?.coveoValue];
    final_prods = final_prods.filter((item) => item.experienceCatgory === 'PRIMARY')?.[0] || final_prods[0];

    if (!final_prods.hasOwnProperty('quantity')) {
      final_prods.quantity = get(category, 'visitorSelector.options[0].defaultQuantity', 0);
    }

    const getCategoryProds = (category, products) =>
      products.filter((product) => product.category.toString() === category.toString());

    const multiTicketSelector = category?.multiTicketSelector;
    const keyMap = multiTicketSelector?.stringMapping;
    const isDrivingExp =
      keyMap &&
      (tile.subCategory.indexOf(keyMap.drivingExperience) !== -1 ||
        tile.subCategory.indexOf(keyMap.passengerExperience) !== -1);
    const isPJOnly = tab.cssClass === 'v-multi-product-widget';
    const isDynamicPricingProd = tab.variant === 'v-dynamic-product-pricing';
    const classToAppend = isDrivingExp
      ? 'drvng-exp addOns-Overlay'
      : classNames('addOns-Overlay calendar-overlay-genral-admission', {
          'v-multi-product-widget': isPJOnly,
          'v-dynamic-product-pricing': isDynamicPricingProd,
          'fit-onscreen-overlay': tab.enableTac,
        });

    const all_Prods = groupCounterProductsSecProd(mappedProducts, categoriesOrder);

    all_Prods?.length &&
      all_Prods.forEach((product) => {
        const prodCat = product?.category;
        const experienceCatgory = product?.experienceCatgory;
        let isCategoryMappingExist = false;
        if (keyMap) {
          for (var item in keyMap) {
            if (keyMap[item] === experienceCatgory) {
              isCategoryMappingExist = true;
              break;
            }
          }
        }
        if (
          keyMap &&
          ((prodCat?.length && prodCat.indexOf(keyMap.drivingExperience) !== -1) ||
            (prodCat?.length && prodCat.indexOf(keyMap.passengerExperience) !== -1) ||
            isCategoryMappingExist)
        ) {
          isCategoryMappingExist = false;
          updateSecProdArray(product, prodCat?.length && prodCat[0], keyMap);
        }
      });

    if (checkParksTenants()) {
      const classType = [],
        updatedList = [];
      let productList = [];
      if (Array.isArray(mappedProducts) && mappedProducts.length > 1) {
        const { category } = mappedProducts[0];
        const prodCat = Array.isArray(category) ? category[0] : category;
        const selectedTab = tab?.controls?.extras.options.find((itm) => itm.coveoValue === prodCat);

        const selectedTabProductIds = selectedTab?.productOverlaySelector?.collections?.[0]?.coveoval;
        const primaryProd = mappedProducts?.find((prod) => prod.productId === selectedTabProductIds);
        const secondaryProd = mappedProducts?.find((prod) => prod?.experienceCatgory?.toLowerCase() !== 'primary');
        if (primaryProd) {
          productList.push(primaryProd);
        }
        if (secondaryProd) {
          productList.push(secondaryProd);
        }
      } else {
        productList = [...mappedProducts];
      }
      productList.forEach((prd) => {
        if (!classType.includes(prd.classType)) {
          classType.push(prd.classType);
          updatedList.push({ ...prd, groupByLabel: tile.groupByLabel });
        }
      });
      GTMData.push('selectItem', {
        itemsList: updatedList,
        category: final_prods?.category,
        gaTabTitle: tab?.gaTabTitle || undefined,
      });
    }

    window.PubSub.publish('toggleOverlayState', {
      shouldOpen: true,
      customClass: classToAppend,
      dataToAppend: componentToAppend({
        props: {
          ...{ ...pjData.purchaseJourneyData, ticket: final_prods },
          category: { ...category },
          data: { ...tab },
          searchProductList: mappedProducts,
          copySearchProductList: createDeepCopy(mappedProducts),
          additionalProds: getCategoryProds(final_prods.category, counterProducts[category.coveoValue]),
          coveoMapList: flipObjectKeyValues(pjData.purchaseJourneyData.coveoMappingList),
          addToCartCallback: addToCartCallback,
          getDiscountedProducts: getDiscountedProducts,
          cartData: cartData,
          businessErrors: businessErrors,
          variant: pjData.purchaseJourneyData?.variant === 'v-multi-product-widget',
          timeSlotSelector: category.timeSlotSelector,
        },
        closeOverlay: () => {},
        isDrivingExp,
        ticketDetails: { ...category },
      }),
    });
  };

  const handleProductClickGTM = async (tile) => {
    GTMDataOnClickCTA({
      name: `${isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(tile?.gaCategory) : tile?.gaCategory} - ${
        isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(tile?.title) : tile?.title
      } - ${renderPriceText()}`,
      category: isMatchTenant(UIConfig.tenants.yi)
        ? toLowerCase(UIConfig.ga4Constants.PRIMARY_CTA)
        : UIConfig.ga4Constants.PRIMARY_CTA,
      [UIConfig.ga4Constants.ELEMENTTEXT]: renderPriceText(),
    });
    GTMData.getTenantId() === UIConfig.ymcB2CTenant
      ? GTMData.push('productClick', { products: tile })
      : GTMData.push('ticketClick', {
          products: tile,
          ...(onlyAllTabs && { type: 'All' }),
        });
    if (enableCalendarOnListingPage()) {
      if (enablePJPopup && tile.enablePj === '1') {
        showCalendarOverlay(tile);
      } else {
        tile.purchaseLink && redirectTo(tile.purchaseLink);
      }
    } else {
      tile.purchaseLink && redirectTo(tile.purchaseLink);
    }
  };

  const showAmenities = (amenities) => {
    amenities = amenities ? JSON.parse(amenities) : [];
    return amenities.map((amenity) => {
      return (
        <div className={'amenity-icon'}>
          <div className={'icon-img'}>
            <img src={amenity.AmenitiesIcon} alt={amenity.AmenitiesTitle} />
          </div>
          <div className={'icon-label'}>{amenity.AmenitiesTitle}</div>
        </div>
      );
    });
  };
  const tileClickableUrl = () => {
    let url = tile.cta.href;
    if (filterVariant === 'v-event-filter' && tile.hasOwnProperty('websiteUrl') && tile.websiteUrl) {
      url = tile.websiteUrl;
    }
    return url;
  };

  // Mapper for carousel in card
  const Mapper = {
    mediaType: 'mediaType',
    alt: 'alt',
    videoInfo: 'videoInfo',
    imageInfo: 'imageInfo',
    desktopImage: 'desktopImage',
    mobileImage: 'mobileImage',
    tabletImage: 'tabletImage',
    src: 'src',
    imageAltText: 'imageAltText',
    videoData: 'videoData',
    isSwipable: 'isSwipable',
    type: 'type',
    videoId: 'videoId',
    videoUrl: 'videoUrl',
    controls: 'controls',
  };

  // Mapping image and video config for carousel in card
  const getVideoImageCarouselMappedData = (carouselData) => {
    let carouselItems = [];
    if (carouselData) {
      const carouselArray = JSON.parse(carouselData) ? JSON.parse(carouselData) : [];
      carouselArray.forEach((item, index) => {
        let carouselItem = {},
          desktopImage = {};
        if (item.mediaType === 'image') {
          carouselItem[Mapper.mediaType] = item.mediaType || 'image';
          carouselItem[Mapper.imageInfo] = {};
          desktopImage[Mapper.src] = item.imageUrl || '';
          carouselItem[Mapper.imageInfo][Mapper.imageAltText] = item.alt || '';
          carouselItem[Mapper.imageInfo][Mapper.desktopImage] = desktopImage;
          carouselItem[Mapper.imageInfo][Mapper.mobileImage] = desktopImage;
          carouselItem[Mapper.imageInfo][Mapper.tabletImage] = desktopImage;
        } else if (item.mediaType === 'video' || item.mediaType === 'youtube') {
          carouselItem[Mapper.mediaType] = 'video';

          // Default image in case of browsers not supporting HTML5 video tag
          carouselItem[Mapper.imageInfo] = {};
          desktopImage[Mapper.src] = item.imageUrl || '';
          carouselItem[Mapper.imageInfo][Mapper.desktopImage] = desktopImage;
          carouselItem[Mapper.imageInfo][Mapper.mobileImage] = desktopImage;
          carouselItem[Mapper.imageInfo][Mapper.tabletImage] = desktopImage;
          carouselItem[Mapper.imageInfo][Mapper.imageAltText] = item.alt;
          carouselItem[Mapper.videoData] = {};
          carouselItem[Mapper.videoData][Mapper.isSwipable] = true;
          carouselItem[Mapper.videoInfo] = {};
          carouselItem[Mapper.videoInfo][Mapper.type] = item.mediaType === 'youtube' ? item.mediaType : 'media';
          carouselItem[Mapper.videoInfo][Mapper.videoId] = item.youtubeVideo && (item.youtubeVideo.videoId || null);
          carouselItem[Mapper.videoInfo][Mapper.videoUrl] =
            item.mediaType === 'youtube' &&
            item.youtubeVideo &&
            (item.youtubeVideo.videoUrl || item.sitecoreVideoUrl || '');
          carouselItem[Mapper.videoInfo][Mapper.controls] = item.mediaType === 'youtube' ? 0 : 1;
        }
        carouselItems.push(carouselItem);
      });
    }
    return carouselItems;
  };

  /* ----------------------- WishList (My Faves) START --------------------------*/

  // Remove To Wishlist Function for Each Card
  const removeToWishList = (wishlistChild, currentElement) => {
    const mainObj = getMainObject();
    if (!mainObj) {
      return Promise.reject('no mainObj');
    }
    let userEmailId = ServiceConfig.getPartnerEmailId();
    const isUserLoggedIn = Object.keys(getLoginUser()).length > 0 ? true : false;
    const itemsToDelete = wishlistChild ? '&cid=' + wishlistChild : '';
    const url = `${services.DeleteWishList.url}/?UserId=${userEmailId}${ServiceConfig.getWishlistID() &&
      '&wid=' + ServiceConfig.getWishlistID()}${itemsToDelete} `;

    const config = {
      url: url,
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': mainObj.additionalProperty.ocpApimSubscriptionKey,
      },
      moduleName: 'wishlist',
    };
    document.querySelector('.loader').classList.remove('hide');
    return ApiWrapper.experienceServices(config, isUserLoggedIn ? true : false)
      .then((response) => {
        return new Promise((resolve, reject) => {
          const wishlistData = localStorage.wishlistData ? JSON.parse(localStorage.wishlistData) : {};
          const wishlistArr = wishlistData.split(',');
          let finalWishlistArr = [];
          wishlistArr.map((item) => {
            if (item !== wishlistChild) {
              finalWishlistArr.push(item);
            }
          });
          localStorage.wishlistData = JSON.stringify(finalWishlistArr.toString());
          currentElement.classList.remove('added');
          document.querySelector('.loader').classList.add('hide');
          resolve(response.data, response.status);
        });
      })
      .catch((response) => {
        return new Promise((resolve, reject) => {
          reject(response.error);
        });
      });
  };

  // Add To Wishlist Function for Each Card
  const addToWishList = (postData, itemId, currentElement) => {
    let ObjModel = {};
    const categoryLabel = postData?.primaryLink ? JSON.parse(postData?.primaryLink) : '';
    const secondaryLink = postData?.secondaryLink ? JSON.parse(postData?.secondaryLink) : '';
    const cardimages = postData?.cardimages ? JSON.parse(postData?.cardimages) : '';

    const myFavsListImage = cardimages.map((item) => {
      return {
        desktopImage: item.desktopImage.src,
        mobileImage: item.mobileImage.src,
        tabletImage: item.tabletImage.src,
      };
    });
    ObjModel = {
      myFavslists: [
        {
          itemId: postData?.id ?? '',
          title: postData?.title ?? '',
          subTitle: postData?.subTitle ?? '',
          desc: postData?.description ?? '',
          location: postData?.location ?? '',
          images: myFavsListImage,
          rating: postData?.hotelrating ?? '',
          category: postData?.packagetype ?? '',
          linkLabel: postData?.listType === UIConfig.packageType ? secondaryLink.Label : categoryLabel.Label,
          linkURL: postData?.listType === UIConfig.packageType ? secondaryLink.Href : categoryLabel.Href,
          categoryLabel: categoryLabel.Label,
          itemType: postData?.listType ?? '',
          language: postData?.language ?? '',
        },
      ],
    };

    addMyFavsWishlist(ObjModel, currentElement);
  };

  // Add to Wishlist
  const addMyFavsWishlist = (data, currentElement) => {
    const mainObj = getMainObject();
    if (!mainObj) {
      return Promise.reject('no mainObj');
    }

    let userEmailId = ServiceConfig.getPartnerEmailId();
    const isUserLoggedIn = Object.keys(getLoginUser()).length > 0 ? true : false;

    const url = `${services.AddWishList.url}?UserId=${userEmailId} ${
      ServiceConfig.getWishlistID() ? '&wid=' + ServiceConfig.getWishlistID() : ''
    }`;
    if (!ServiceConfig.getWishlistID() && !isUserLoggedIn) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    const newToken = getCookie('idToken');
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Ocp-Apim-Subscription-Key': mainObj.additionalProperty.ocpApimSubscriptionKey,
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify(data),
    };

    document.querySelector('.loader').classList.remove('hide');
    fetch(url, config)
      .then((response) => response.json())
      .then((data) => {
        document.querySelector('.loader').classList.add('hide');
      })
      .catch((response) => {
        return new Promise((resolve, reject) => {
          reject(response.error);
        });
      });

    // Added ItemID into Local Storage
    let itemId = data.myFavslists[0].itemId;
    const wishlistData = localStorage.wishlistData ? JSON.parse(localStorage.wishlistData) : '';
    localStorage.wishlistData = JSON.stringify(
      Object.keys(wishlistData).length !== 0 ? wishlistData + ',' + itemId : itemId,
    );
    currentElement.classList.add('added');
  };

  // Get Wishlist Icon with ItemID
  const getWishlistIcon = (itemId) => {
    if (localStorage.wishlistData) {
      if (
        Object.values(JSON.parse(localStorage.wishlistData)).length === 0 ||
        Object.values(JSON.parse(localStorage.wishlistData))[0].toString() === 'true'
      ) {
        return false;
      } else {
        if (Object.keys(JSON.parse(localStorage.wishlistData)).length !== 0) {
          const wishlistIdArr = JSON.parse(localStorage.wishlistData).split(',');
          for (let i = 0; i <= wishlistIdArr.length; i++) {
            if (wishlistIdArr[i] === itemId) {
              return itemId;
            }
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  const renderBuyNowCta = (tile) => {
    return (
      <div className="itemDesc">
        <DynamicContent
          tagName="div"
          attrs={{ className: classNames({ fineprint: isSwad }) }}
          innerHtml={tile.finePrint}
        />
        <div className={`btn-primary${!tile.finePrint ? ' no-desc' : ''}`}>
          <a
            onClick={() => handleProductClickGTM(tile)}
            aria-label={`${renderPriceText()} ${tile.title}`}
            href="javascript:void(0)"
          >
            {isSwad && buyNowCtaLabel ? buyNowCtaLabel : renderPriceText()}
          </a>
        </div>
      </div>
    );
  };

  /* ----------------------- WishList (My Faves) END --------------------------*/

  try {
    const imageDetail = (tile.tabCarousel && getVideoImageCarouselMappedData(tile.tabCarousel)) || [];
    const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;
    const primaryLink = tile.primaryLink ? JSON.parse(tile.primaryLink) : '';
    const secondaryLink = tile.secondaryLink ? JSON.parse(tile.secondaryLink) : '';
    const sliderImages = tile.cardimages ? JSON.parse(tile.cardimages) : [];
    const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
      if (checkfortenants) {
        bodyClicks(eventName, titleData, labelData);
      }
    };
    return (
      <div
        className={
          `${isTileClickable ? 'clickable-tile' : ''} feature-tile ${parseInt(tile?.soldoutkey) &&
            isMatchTenant(UIConfig?.tenants?.ymc) &&
            'soldOutProduct-overlay'}` + classes
        }
        onClick={isTileClickable ? () => (window.location.href = tileClickableUrl()) : undefined}
        tabIndex={isTileClickable ? '0' : '-1'}
      >
        <div className="feature-tile-top">
          {!!(parseInt(tile?.soldoutkey) && isMatchTenant(UIConfig?.tenants?.ymc)) && (
            <div className="tile-sold-out-placeholder">{soldOutCtaLabel}</div>
          )}
          {!isMorepackagesVariant ? (
            carouselConfigData && carouselConfigData.isCarouselInsideCard && imageDetail.length ? (
              <CarouselInCard imageDetail={imageDetail} />
            ) : (
              <span className={`feature-tile--image `}>
                <Image image={checkURL(tile.featureImage)} disableLazyLoad={disableLazyLoadImage} />
              </span>
            )
          ) : (
            sliderImages &&
            sliderImages.length === 1 &&
            sliderImages[0].desktopImage.src && (
              <div className="card-image">
                <Image image={sliderImages[0]} disableLazyLoad={true} />
              </div>
            )
          )}
          {sliderImages && sliderImages.length > 1 && sliderImages[0].desktopImage.src && (
            <div className="card-image">
              <Slider {...CAROUSEL_SETTING1}>
                {sliderImages.map((image, index) => {
                  return (
                    <div key={index}>
                      <Image image={image} disableLazyLoad={true} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}

          {enableWishList && (
            <>
              {isLoggedInUser() ? (
                <div
                  className={`card-wishlist-icon ${getWishlistIcon(tile.id) === tile.id ? 'added' : ''}`}
                  onClick={(e) =>
                    getWishlistIcon(tile.id) === tile.id
                      ? removeToWishList(tile.id, e.target)
                      : addToWishList(tile, tile.id, e.target)
                  }
                >
                  <span className="sr-only">WishList</span>
                </div>
              ) : (
                <div
                  className={`card-wishlist-icon`}
                  onClick={(e) => openIAMOverlay(<IAMPopup loginURL={loginCtaUrl} />)}
                >
                  <span className="sr-only">WishList</span>
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={`feature-tile-bottom ${iconClass} ${
            tile.startDate && tile.endDate && showExtraField ? ' display-date-slot' : ''
          }`}
        >
          {tile.wishlist && tile.wishlist.enableWishList ? (
            <WishlistIcon
              data={{
                wishlist: tile.wishlist,
                childData: {
                  title: tile.title,
                  link: tile.cta.href,
                  desc: tile.bodyCopy,
                  image: tile.featureImage.desktopImage.src,
                },
              }}
            />
          ) : null}
          {(tile.place || tile.free) && showExtraField && (
            <div className="place-title-holder">
              {tile.place ? (
                <DynamicContent tagName="div" attrs={{ className: 'place-title' }} innerHtml={tile.place} />
              ) : null}
              {tile.free === '0' && (
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'ticket-ticketed' }}
                  innerHtml={tileLabels.ticketedText}
                />
              )}
              {tile.free === '1' && (
                <DynamicContent tagName="span" attrs={{ className: 'ticket-free' }} innerHtml={tileLabels.freeText} />
              )}
              {tile.featuredLabel === '1' && (
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'ticket-ticketed' }}
                  innerHtml={tileLabels.featuredLabel}
                />
              )}
            </div>
          )}
          {filterVariant === 'v-venue-find-filter' && (
            <div className="event-names">
              {tile.eventType && <span className="event-type">{tile.eventType}</span>}
              {tile.venueTypeLabel && <span className="venue-type">{tile.venueTypeLabel}</span>}
            </div>
          )}
          {filterVariant === 'v-product-list-filter' && tile.tagginglabel && tile.tagginglabel.length > 0 && (
            <div className="product-tag">{tile.tagginglabel[tile.tagginglabel.length - 1]}</div>
          )}
          {(filterVariant === 'v-swad-feature-tile-titleLink' ||
            filterVariant === 'v-swad-feature-tile-titleLink-dark') &&
            tile.tagginglabel &&
            tile.tagginglabel.length > 0 && (
              <div className="product-tag-wrapper">
                {tile.tagginglabel.map((tagName) => {
                  return <div className="product-tag">{tagName}</div>;
                })}
              </div>
            )}
          {(filterVariant === 'v-swad-feature-tile-titleLink' ||
            filterVariant === 'v-swad-feature-tile-titleLink-dark') &&
          tile.tileDate ? (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'tile-date' }}
              innerHtml={
                filterVariant === YAS_ARENA_FILTER
                  ? momentTimezone(tile.tileDate)
                      .locale(activeLocale, localization)
                      .format(UIConfig.b2c.profile.purchase.dateDisplayFormat)
                  : tile.tileDate
              }
            />
          ) : null}
          {carouselConfigData && carouselConfigData.isProductTypeEventQuery && tile.productCategoryLabel && (
            <div className="product-tag">{tile.productCategoryLabel}</div>
          )}
          {filterVariant === 'v-event-filter' && tile.eventCategoryLabel && tile.eventCategoryLabel.length && (
            <div className="event-category">{tile.eventCategoryLabel[0]}</div>
          )}
          {filterVariant === YAS_ARENA_FILTER && (tile.featured === '1' || tile.featured === true) && (
            <DynamicContent tagName="span" attrs={{ className: 'featured' }} innerHtml={tileLabels.featuredLabel} />
          )}
          {tile?.tagType && (
            <div class="card-package-type text-left">
              <p>{tile?.tagType}</p>
            </div>
          )}
          <div className={` ${isMorepackagesVariant ? 'card-title' : 'heading-description-wrapper'} `}>
            {/* {tile.title && tile.cta.href && variant === 'v-yas-island-feature-tile-titleLink' ? ( */}

            {tile.title &&
            tile.cta.href &&
            tile.cta.label &&
            (checkParksTenants() ||
              variant === 'v-yas-island-feature-tile-titleLink' ||
              variant === 'v-swad-feature-tile-titleLink' ||
              variant === 'v-swad-feature-tile-titleLink-dark') ? (
              <a href={tile.cta.href} tabindex="-1">
                <h3 className="heading-5" tabindex="0">
                  {tile.title}
                </h3>
              </a>
            ) : tile.title ? (
              <DynamicContent
                tagName="h3"
                attrs={{ className: !isMorepackagesVariant && 'heading-5' }}
                innerHtml={tile.title}
              />
            ) : null}
            {filterVariant !== 'v-venue-find-filter' && tile.shortTitle ? (
              <DynamicContent tagName="p" attrs={{ className: 'body-copy-7' }} innerHtml={tile.shortTitle} />
            ) : (
              tile.teaserText && (
                <DynamicContent tagName="p" attrs={{ className: 'body-copy-7' }} innerHtml={tile.teaserText} />
              )
            )}
          </div>
          {(filterVariant === 'v-swad-feature-tile-titleLink' ||
            filterVariant === 'v-swad-feature-tile-titleLink-dark') &&
          tile.price ? (
            <div className="tileprice">
              {canUseDOM() ? JSON.parse(window.localStorage.getItem('mainObj'))?.currency : ''} {tile.price}
            </div>
          ) : (
            ''
          )}
          {tile?.hotelrating && (
            <div className="card-rating">
              <div className="rating" value={tile.hotelrating}></div>
            </div>
          )}

          {tile?.description && isMorepackagesVariant && (
            <div className="card-description">
              <p>{tile.description}</p>
            </div>
          )}

          {tile?.location && (
            <div className="card-location">
              <span className="location-icon"></span>
              <Text tag="p" field={{ value: tile.location, editable: tile.location }} />
            </div>
          )}

          {isMorepackagesVariant && (
            <div className="card-cta">
              {tile.packagetype
                ? primaryLink.Label && (
                    <a
                      className={`cta-button primary`}
                      onClick={() => {
                        callToAction(tile.packagetype);
                        googleAnalyticHandlerbody(
                          UIConfig.commonVariant.gaClickEvents.buttonClick,
                          tile.title,
                          primaryLink.Label,
                        );
                      }}
                    >
                      {primaryLink.Label}
                    </a>
                  )
                : primaryLink.Label && (
                    <a className={`cta-button primary`} href={primaryLink.Href}>
                      {primaryLink.Label}
                    </a>
                  )}
              {secondaryLink.Label && (
                <a
                  className={`cta-button iconCta`}
                  href={secondaryLink.Href}
                  onClick={() => {
                    googleAnalyticHandlerbody(
                      UIConfig.commonVariant.gaClickEvents.buttonClick,
                      tile.title,
                      secondaryLink.Label,
                    );
                  }}
                >
                  {secondaryLink.Label}
                </a>
              )}
            </div>
          )}

          {filterVariant === 'v-f1-multi-filter' && tile.eventdayLabel && (
            <div className="valid-on-days">{tile.eventdayLabel}</div>
          )}
          {filterVariant === 'v-f1-multi-filter' && tile.amenities && (
            <div className={'amenities-icon'}>{showAmenities(tile.amenities)}</div>
          )}
          {filterVariant === 'v-f1-multi-filter' && tile.bodyCopy && (
            <DynamicContent tagName="div" attrs={{ className: 'body-copy-4' }} innerHtml={tile.bodyCopy} />
          )}
          {(filterVariant === 'v-product-list-filter' || filterVariant === 'v-f1-multi-filter') && (
            <div className="price-wrapper">
              {tile.currentprice && (
                <div className="current-price">
                  {tile.priceText} <span className="price">{tile.currentprice}</span>
                </div>
              )}
              {tile.netprice && (
                <div className="net-price">
                  {tile.priceText} <span className="price">{tile.netprice}</span>
                </div>
              )}
            </div>
          )}
          {!(
            filterVariant === 'v-swad-feature-tile-titleLink' || filterVariant === 'v-swad-feature-tile-titleLink-dark'
          ) && tile.tileDate ? (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'tile-date' }}
              innerHtml={
                filterVariant === YAS_ARENA_FILTER
                  ? momentTimezone(tile.tileDate)
                      .locale(activeLocale, localization)
                      .format(UIConfig.b2c.profile.purchase.dateDisplayFormat)
                  : tile.tileDate
              }
            />
          ) : null}
          {filterVariant === YAS_ARENA_FILTER &&
            !tile.startDate &&
            tile.taggingLabel &&
            tile.taggingLabel.length > 0 && <div className="tagging-label">{tile.taggingLabel[0]}</div>}
          {!showExtraField && filterVariant !== 'v-product-list-filter' && filterVariant !== 'v-f1-multi-filter' && (
            <DynamicContent
              tagName="div"
              attrs={{ className: 'body-copy-4 feature-title-wrapper' }}
              innerHtml={
                (filterVariant === 'v-swad-feature-tile-titleLink' ||
                  filterVariant === 'v-swad-feature-tile-titleLink-dark') &&
                maxLengthForDescription
                  ? tile?.bodyCopy.length > maxLengthForDescription
                    ? tile?.bodyCopy.substring(0, maxLengthForDescription) + '...'
                    : tile.bodyCopy
                  : tile.bodyCopy
              }
            />
          )}
          {tile.startDate && tile.endDate && (showExtraField || filterVariant === YAS_ARENA_FILTER) ? (
            <div className="date-slot">
              <SvgSprite id={'icn-facebook'} viewBox="0 0 33 33" />
              <DynamicContent
                tagName="p"
                attrs={{ className: 'date' }}
                innerHtml={
                  momentTimezone(tile.startDate)
                    .locale(activeLocale, localization)
                    .format(dateFormat) +
                  ' - ' +
                  momentTimezone(tile.endDate)
                    .locale(activeLocale, localization)
                    .format(dateFormat)
                }
              />
            </div>
          ) : null}
          {filterVariant === 'v-event-filter' && tile.startDate && tile.endDate ? (
            <div className={`date-slot ${tile.eventdayLabel ? 'event-day' : ''}`}>
              <DynamicContent
                tagName="p"
                attrs={{ className: 'date' }}
                innerHtml={
                  tile.eventdayLabel
                    ? tile.eventdayLabel
                    : tile.startDate === tile.endDate
                    ? momentTimezone(tile.startDate).format('MMM D, Y')
                    : momentTimezone(tile.startDate).format('MMM D') +
                      ' - ' +
                      momentTimezone(tile.endDate).format('MMM D, Y')
                }
              />
            </div>
          ) : null}
          {(tile.finePrint || (tile.purchaseLink && tile.priceText)) &&
            (enableCalendarOnListingPage() || !enablePJPopup) &&
            filterVariant !== 'v-f1-multi-filter' &&
            !isSwad &&
            renderBuyNowCta(tile)}

          {tile.redeemUrl && (
            <div className="redeemUrl">
              <div className="btn-primary">
                <a href={tile.redeemUrl}>{tile.redeemText}</a>
              </div>
            </div>
          )}
          {filterVariant !== YAS_ARENA_FILTER && (
            <div className={classNames('btn-wrapper', { 'no-margin': isSwad && tile.purchaseLink })}>
              {filterVariant !== 'v-venue-find-filter' &&
                filterVariant !== 'v-event-filter' &&
                tile.cta &&
                tile.cta.label &&
                tile.cta.href &&
                (!isCoveoComponent || tile.showDetail === '1' || !showExtraField) && (
                  <div
                    className={`readMoreCTA ${parseInt(tile?.soldoutkey) &&
                      isMatchTenant(UIConfig?.tenants?.ymc) &&
                      'soldOutProduct-buynow-btn'}`}
                  >
                    <AnchorLink
                      link={{ ...tile.cta, ariaLabel: `${tile.cta.label} ${tile.title}` }}
                      onClick={() => {
                        if (isMatchTenant(UIConfig.tenants.ya)) {
                          GTMData.push(UIConfig.ga4Constants.PARTNER_LINKS, {
                            click_text: tile?.cta?.label,
                            click_url: tile?.cta?.href || undefined,
                          });
                        }
                        GTMDataOnClickCTA({
                          name: `${
                            isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(tile?.gaCategory) : tile?.gaCategory
                          } - ${isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(tile?.title) : tile?.title} - ${
                            isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(tile?.cta?.label) : tile?.cta?.label
                          }`,
                          elementText: isMatchTenant(UIConfig.tenants.yi)
                            ? toLowerCase(tile?.cta?.label)
                            : tile?.cta?.label,
                          category: isMatchTenant(UIConfig.tenants.yi)
                            ? toLowerCase(UIConfig.ga4Constants.SECONDARY_CTA)
                            : UIConfig.ga4Constants.SECONDARY_CTA,
                        });
                        GTMData.getTenantId() === UIConfig.ymcB2CTenant && handleProductClickGTM(tile);
                        googleAnalyticHandlerbody(
                          UIConfig.commonVariant.gaClickEvents.linkClick,
                          tile.title,
                          tile.cta.label,
                        );
                      }}
                      className="feature-tile--cta"
                    />
                  </div>
                )}
              {tile.buybuttonurl && !enablePJPopup && filterVariant === 'v-f1-multi-filter' && tile.buyNowCtaLabel && (
                <div className="buy-ticket-cta">
                  <Text
                    tag="a"
                    className="buy-now-button"
                    field={{ value: tile.buyNowCtaLabel, editable: tile.buyNowCtaLabel }}
                    href={tile.buybuttonurl}
                  />
                </div>
              )}
              {filterVariant !== 'v-event-filter' && tile.websiteUrl && tileLabels && (
                <div className="readMoreCTA">
                  <a
                    href={tile.websiteUrl}
                    target={tile.websiteUrl}
                    className={'feature-tile--cta'}
                    aria-label={`${tile.cta.label} ${tile.title}`}
                    onClick={() => {
                      GTMDataOnClickCTA({
                        name: `${
                          isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(tile?.gaCategory) : tile?.gaCategory
                        } - ${isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(tile?.title) : tile?.title} - ${
                          isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(tile?.cta?.label) : tile?.cta?.label
                        }`,
                        elementText: isMatchTenant(UIConfig.tenants.yi)
                          ? toLowerCase(tile?.cta?.label)
                          : tile?.cta?.label,
                        category: isMatchTenant(UIConfig.tenants.yi)
                          ? toLowerCase(UIConfig.ga4Constants.SECONDARY_CTA)
                          : UIConfig.ga4Constants.SECONDARY_CTA,
                      });
                      googleAnalyticHandlerbody(
                        UIConfig.commonVariant.gaClickEvents.linkClick,
                        tile.title,
                        tile.cta.label,
                      );
                    }}
                  >
                    {tileLabels.websiteLabel}
                  </a>
                </div>
              )}

              {tile.bookingUrl && (
                <span className="booking-url">
                  <a href={tile.bookingUrl} aria-label="booking" target={tile.bookingUrl}>
                    {''}
                  </a>
                </span>
              )}
              {tile.cta2 && tile.cta2.href && (
                <div className="readMoreCTA">
                  <AnchorLink
                    link={{ ...tile.cta2, ariaLabel: `${tile.cta2.label} ${tile.title}` }}
                    className="feature-tile--cta"
                  />
                </div>
              )}

              {filterVariant === 'v-product-list-filter' && !enablePJPopup && tile.buybutton && tile.buyNowCtaLabel && (
                <div className="buy-ticket-cta">
                  <Text
                    tag="a"
                    className="buy-now-button"
                    field={{ value: tile.buyNowCtaLabel, editable: tile.buyNowCtaLabel }}
                    href={tile.buybutton}
                  />
                </div>
              )}
              {(filterVariant === 'v-product-list-filter' ||
                (filterVariant === 'v-f1-multi-filter' && tile.soldoutkey === '0')) &&
                enablePJPopup &&
                typeof invokePJCallback === 'function' &&
                tile.buyNowCtaLabel &&
                (tile.packageCode ? (
                  <div className="buy-ticket-cta">
                    <button onClick={() => invokePackageJourney(tile.packageCode, tile.title)}>
                      {tile.buyNowCtaLabel}
                    </button>
                  </div>
                ) : (
                  tile.productid &&
                  tile?.soldoutkey === '0' && (
                    <div className="buy-ticket-cta">
                      <button onClick={() => invokePJCallback(tile.productid, tile.title)}>
                        {tile.buyNowCtaLabel}
                      </button>
                    </div>
                  )
                ))}
              {filterVariant === 'v-f1-multi-filter' && tile.soldoutkey === '1' && soldOutCtaLabel && (
                <div className="sold-out-cta disabled">
                  {/* <button tabIndex="0" aria-label={tile.soldOutCtaLabel}>
                    {tile.soldOutCtaLabel}
                  </button> */}
                </div>
              )}
            </div>
          )}
          {isSwad && tile.purchaseLink && renderBuyNowCta(tile)}
          {resolvePath(tile, 'iconData.iconImageUrl') && (
            <div className="listingIconContainer">
              {Array.isArray(tile.iconData.iconData) &&
                tile.iconData.iconData.map((item, index) => {
                  return (
                    <div key={index} className="icon-data">
                      <img src={item.iconImageUrl} alt={item.iconImageAlt} />
                      <span className="iconText">
                        <span className="iconText-title">{item.iconTitle}</span>
                        <span className="iconText-colon">:</span>
                        <span className="iconText-value">{item.iconValue}</span>
                      </span>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'FeatureTile');
  }
};

export default FeatureGrid;

/**
 * Used to define the proptypes that will be received by the component.
 */
FeatureTile.propTypes = {
  index: PropTypes.number,
  classes: PropTypes.string,
  tile: PropTypes.object.isRequired,
};

/**
 * Used to define the proptypes that will be received by the component.
 */
FeatureGrid.propTypes = {
  tiles: PropTypes.array.isRequired,
  classes: PropTypes.string,
};
