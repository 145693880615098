import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import UIConfig from '../../../../common/UIConfig';
import { KeyCodes } from '../../../../common/constants';
import PaymentCheckboxes from '../base/payment-checkbox/payment-checkbox';
import { guestFormError, shouldProceedToPay, cartCall } from '../../../../common/payment-action/actions';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import Button from '../../../presentation/base/button/button-component.js';
import RichText from '../../../presentation/base/rich-text/rich-text-component';
import { languagePostpay } from './utils';
import {
  redirectTo,
  setLocalStorage,
  removePostPayStorage,
  isLoggedInUser,
  getRecipientDetails,
  mycartError,
  checkPaymentType,
  checkParksTenants,
  checkIfParks,
  getLoggedInUser,
  canUseDOM,
  getLanguageWithoutLocal,
  isMatchTenant,
  getLocalStorageByKey,
} from '../../../../common/utility';
import { Logging } from '../../../../common/logger';
import { logComponentRenderingError } from '../../../../common/logger';
import { scrollToServerError, isThereExtraErrors, checkAnnualPassError } from '../utils';
import { showLimitError } from '../../b2c-purchase-journey/payment/helper';
import GTMData from '../../b2c-purchase-journey/gtm-data';
import ApiWrapper from '../../../../common/api-wrapper';
import { isParkTenant } from '../../../../common/utility/tenantsUtils.js';
/**
 * functionality to make payment through PostPay
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */

/**
 * PostPay Class ( which extends the React.Component) contains the
 * functionality to make payment through PostPay
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */

export default class PostPay extends Component {
  constructor(props) {
    super(props);
    this.postPayBtnRef = null;
    this.paymentType = this.props.paymentOptions.paymentTypes.find(
      (item) => item.value === UIConfig.paymentOption.postPay,
    );

    this.state = {
      guestHasError: false,
      payBtnPostPay: 'payment-submit-btn btn-primary button-enabled-light',
      postPayLimitError: false,
    };
    this.btnClass = {
      light: 'button-enabled-light',
      dark: 'button-enabled-dark',
    };
    this.scriptPostpay = null;
    this.PostpayPromoInstance = null;
  }

  showLimitError = () => showLimitError(this);

  initializePromotionPopup = () => {
    const { merchantId } = this.props.paymentOptions.postPayConfig;
    this.PostpayPromoInstance = window.postpayAsyncInit = function() {
      window.postpay.init({ merchantId });
    };
    window.postpayAsyncInit();
  };

  handleScriptInject = ({ scriptTags }) => {
    if (!this.PostpayPromoInstance) {
      if (scriptTags) {
        const scriptTag = scriptTags[0];
        scriptTag.onload = this.initializePromotionPopup;
      }
    } else {
      this.PostpayPromoInstance = null;
      this.initializePromotionPopup();
    }
  };

  postPayLimitError = (msg, data) => {
    if (!this.showLimitError()) {
      this.props.isErrorOccured(UIConfig.limitErrorsKeys.postPayLimit, '', false, true);
      localStorage.removeItem(UIConfig.localStoreKeys.postPayLimitError);
    } else {
      localStorage.setItem(UIConfig.localStoreKeys.postPayLimitError, true);
    }
  };

  componentDidMount() {
    if (!isLoggedInUser()) {
      window.PubSub.subscribe('tncError', this.payButtonColorHandled);
    } else {
      window.PubSub.subscribe('recipientdetailsHasError', this.checkGuestFormError);
    }
    window.PubSub.subscribe('tncError', this.payButtonColorHandled);
    window.PubSub.subscribe(UIConfig.events.ONCARTUPDATEPOSTPAY, this.postPayLimitError);
    if (this.showLimitError()) localStorage.setItem(UIConfig.localStoreKeys.postPayLimitError, true);
  }
  componentDidUpdate() {
    if (this.state.postPayLimitError) {
      this.props.isErrorOccured(UIConfig.limitErrorsKeys.postPayLimit, this.showLimitError(), false, true);
      localStorage.setItem(UIConfig.localStoreKeys.postPayLimitError, true);
      this.setState({ postPayLimitError: false });
      const paymentError = document.querySelector(`.heading-3.sub-title`);
      if (paymentError) {
        paymentError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  componentWillUnmount() {
    removePostPayStorage();
    this.setState({ postPayLimitError: false });
  }

  payButtonColorHandled = (msg, data) => {
    const { light, dark } = this.btnClass;
    this.setState({
      payBtnPostPay: `payment-submit-btn btn-primary ${
        data.isCheckBoxDisable || document.querySelectorAll('.yasid-email-error').length > 0 || isThereExtraErrors()
          ? light
          : dark
      }`,
    });
  };

  /**
   *  non loggedin case
   *  check guest form error and update the state of guest form
   */

  checkGuestFormError = (msg, data) => {
    const isErrorExist =
      Object.keys(data).length > 0 && data.hasOwnProperty('isRecipientError')
        ? data.isRecipientError
        : !isLoggedInUser()
        ? guestFormError(data, this.props.isEnabled)
        : false;

    this.setState({
      guestHasError: isErrorExist || isThereExtraErrors(),
    });
  };

  /**
   *  blocks payment if conditions not met
   *  conditions : guest form not filled, t&c not checked
   */

  canProceedToPay = () => {
    return shouldProceedToPay({
      checkboxEnabled: this.props.isEnabled,
      tncErrorFunc: this.props.showTnCError,
      guestFormErrorFunc: this.props.guestUserHasErrors,
      guestErrorOnChangeState: this.state.guestHasError,
      cartData: this.props.cartData,
    });
  };

  setProductsCategoryObj = () => {
    const { normal, promotional } = this.props.postPayConfig;
    const cartData = this.props.cartData;
    const totalGrossPrice = cartData.grossPrice;
    const promotionProductsGross = cartData.items
      .filter((item) => item.ItemPromotionList && item.ItemPromotionList.length > 0)
      .reduce((sum, item) => {
        return sum + item.price.gross;
      }, 0);

    //  YA promotional(discount) event based on isPromotionalEvent
    const promotionalEventProductGross = cartData.items
      .filter((item) => item.isPromotionalEvent)
      .reduce((sum, item) => {
        return sum + item.price.gross;
      }, 0);
    const paymentInfo = {
      cmsData: {
        paymentOptions: {
          tncType: this.props.paymentOptions.tncType,
          tncCondition: this.props.paymentOptions.tncCondition,
        },
      },
    };
    localStorage.setItem(UIConfig.localStoreKeys.payment.paymentInfo, JSON.stringify(paymentInfo));

    if (promotionalEventProductGross) {
      setLocalStorage(
        UIConfig.paymentOption.postPay,
        JSON.stringify([
          { code: promotional, amount: promotionalEventProductGross },
          { code: normal, amount: totalGrossPrice - promotionalEventProductGross },
        ]),
      );
    } else {
      setLocalStorage(
        UIConfig.paymentOption.postPay,
        JSON.stringify([
          { code: promotional, amount: promotionProductsGross },
          { code: normal, amount: totalGrossPrice - promotionProductsGross },
        ]),
      );
    }
  };

  submitDataPostPayOnEnter = (e) => {
    if (e.which === KeyCodes.Enter) {
      // 13 is key code for enter key
      this.submitDataPostPay(e);
    }
  };

  postPayExtraErrors = (e) => {
    if (this.showLimitError()) {
      this.setState({ postPayLimitError: true });
    }
    if (localStorage.getItem(UIConfig.localStoreKeys.cartError)) {
      scrollToServerError(e);
    }
    return localStorage.getItem(UIConfig.localStoreKeys.cartError) || this.showLimitError();
  };

  /**
   *  Redirect to PostPay page if create  order is success
   */

  submitDataPostPay = (e) => {
    let hasAnnualPassError = false;
    if (checkAnnualPassError(this.props.cartData, this.submitDataPostPay)) {
      hasAnnualPassError = true;
    }
    const tenent_id = getLoggedInUser().tenantID;

    const guestEmail = getLoggedInUser().email || (canUseDOM() && document.getElementById('Email').value);

    let qData = {
      emailId: guestEmail,
      channelName: tenent_id,
      language: getLanguageWithoutLocal(),
      consentPurpose: [
        {
          email_consent: true,
        },
        {
          phone_consent: true,
        },
        {
          sms_consent: true,
        },
        {
          whatsApp_consent: true,
        },
        {
          website_consent: true,
        },
        {
          mobile_consent: true,
        },
      ],
      source: '',
    };
    const { MarketingConsentUpdateAPI } = this.props.services;
    if (this.props.newsLetterEnabled && isLoggedInUser()) {
      ApiWrapper.apiGateway({
        method: 'POST',
        url: `${MarketingConsentUpdateAPI.url}`,
        data: qData,
        moduleName: 'Consent API',
        preLoader: true,
        preLoaderTarget: '.container',
      })
        .then((response) => {
          console.log('SUCCESS CONSENT');
          if (typeof response.data === 'object' && response.status === 201) {
            Logging(response.data, 'Marketing Consent Update', true, 'Logged In User');
          }
        })
        .catch((error) => {
          console.log('CONSENT ERROR:', error);
        });
    }

    window.PubSub.unsubscribe('CreateOrderSuccess');
    // Creates category object which we have to pass in Cart API
    this.setProductsCategoryObj();
    if (!this.canProceedToPay() || hasAnnualPassError) {
      e.preventDefault();
      e.stopPropagation();
      scrollToServerError(e);
      if (this.showLimitError()) {
        this.setState({ postPayLimitError: true });
      }
      return false;
    }
    if (this.postPayExtraErrors(e)) return false;

    if (this.props.isEnabled && isLoggedInUser()) {
      const updateCartURL = this.props.services.updateCart.url;
      const recipientDetails = getRecipientDetails();
      const cartData = {
        ...this.props.cartData,
        personalisedGiftObj: recipientDetails,
      };
      cartCall(updateCartURL, { cart: cartData }, this.props.isRenewAnnualPass, UIConfig.paymentOption.postPay)
        .then((res) => {
          this.props.setRedirectPaymentData();
        })
        .catch((res) => {
          this.props.isErrorOccured('updateCart', res.error);
        });
    }

    const isVoucher = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.giftVoucher);
    if (isVoucher) {
      window.PubSub.publish('formSubmitVoucher', true);
    }

    if (checkParksTenants()) {
      GTMData.push('addPaymentInfo', {
        cartData: this.props.cartData,
        paymentType: UIConfig.paymentOption.postPay,
      });
    }

    window.PubSub.subscribe('CreateOrderSuccess', (msg, data) => {
      let cmsDataLocal = localStorage.getItem(UIConfig.localStoreKeys.payment.cmsData);
      let cmsData = (cmsDataLocal && JSON.parse(cmsDataLocal)) || {};
      cmsData.orderData = {
        postPayResponse: data.postPayResponse,
      };
      if (this.props.postPayConfig) {
        cmsData.postPayTypeMethod = this.props.currentOption;
      }

      localStorage.setItem(UIConfig.localStoreKeys.payment.cmsData, JSON.stringify(cmsData));
      if (data.postPayResponse && data.postPayResponse.isSuccess) {
        const { redirectUrl } = data.postPayResponse;
        Logging(data, this.props.currentOption, true, 'PostPay redirect success');
        redirectUrl && redirectTo(`${redirectUrl}${languagePostpay()}`);
      } else {
        mycartError();
        Logging(data, this.props.currentOption, true, 'PostPay redirect error');
      }
    });
  };

  onParkConsentChangeHandler = (e) => {
    this.setState({
      isMultiParkConsentChecked: e.target.checked,
    });
    sessionStorage.setItem('isMultiParkConsent', e.target.checked);
  };

  showParkConsentCheckbox = () => {
    if (isParkTenant()) {
      return this.props?.cartData?.items?.some((item) => item?.itemType?.toLowerCase() === 'mup');
    }
  };

  render() {
    const {
      paymentOptions,
      openOverlay,
      onTncChangeHandler,
      showCheckBox,
      hideNewsletterOption,
      disableBookButton,
      hasTnCError,
      tabIndex,
      isEnabled,
      is2StepPayment,
      onNewsLetterChangeHandler,
      newsLetterEnabled,
      isPromoEnable,
      onParkConsentChangeHandler,
      parkConsentEnabled,
      cartData,
    } = this.props;

    const postPayDescription = this.paymentType?.bodyCopy;
    let buttonData = {
      type: 'submit',
      label:
        (checkParksTenants() && checkPaymentType(paymentOptions?.paymentTypes, UIConfig.paymentOption.postPay)) ||
        paymentOptions?.primaryCTA?.label,
      disabled: `${parseInt(tabIndex)}`,
      class: this.state.payBtnPostPay,
    };
    try {
      return (
        <div className="c-form post-pay-wrapper checkbox-wrapper-step2">
          {(checkIfParks() || isMatchTenant(UIConfig.tenants.swadb2c)) &&
            isPromoEnable(paymentOptions, UIConfig.paymentMethod.postpay) && (
              <>
                <Helmet
                  script={[
                    {
                      src:
                        paymentOptions.postPayConfig?.installmentOverlayScriptUrl || UIConfig.postpay.promo.SCRIPT_SRC,
                      async: true,
                    },
                  ]}
                  onChangeClientState={(_, addedTags) => this.handleScriptInject(addedTags)}
                />
                <div
                  class="postpay-widget postpay-promo-message"
                  data-type={UIConfig.postpay.promo.CART}
                  data-amount={this.props.cartData?.grossPrice * 100}
                  data-currency={paymentOptions.postPayConfig.currencyCode}
                  data-country={paymentOptions.postPayConfig.countryCode}
                  data-locale={getLanguageWithoutLocal()}
                ></div>
              </>
            )}
          {postPayDescription && (
            <DynamicContent
              attrs={{ className: 'postpay-description' }}
              tagName="span"
              innerHtml={postPayDescription}
            />
          )}

          <PaymentCheckboxes
            paymentOptions={paymentOptions}
            openOverlay={openOverlay}
            onTncChangeHandler={onTncChangeHandler}
            key="post-pay-term-condition"
            onNewsLetterChangeHandler={onNewsLetterChangeHandler}
            onParkConsentChangeHandler={onParkConsentChangeHandler}
            cartData={cartData}
            showParkConsentCheckbox={true}
            stateData={{
              showCheckBox,
              hideNewsletterOption,
              disableBookButton,
              hasTnCError,
              tabIndex,
              isTncChecked: isEnabled,
              newsLetterEnabled: newsLetterEnabled,
              parkConsentEnabled: parkConsentEnabled,
              bindCheckBoxError: true,
            }}
          />

          {this.props.emailConfirmationText}
          <div className="postpay-submit-btn" id="postpaySubmitButton">
            <Button
              disabled={parseInt(tabIndex)}
              data={buttonData}
              isNewForm={is2StepPayment}
              clickCallback={this.submitDataPostPay}
              isTabIndex={tabIndex}
            />
            <RichText
              data={{
                bodyCopy: paymentOptions.vatLabel,
                attrs: { className: 'body-3 vat-label' },
              }}
            />

            {/* {detectMobile() && paymentOptions.cardDetailImage && (
              <div className="card-detail-image">
                <Image noRendition image={paymentOptions.cardDetailImage} />
              </div>
            )} */}
          </div>

          <PaymentCheckboxes
            paymentOptions={paymentOptions}
            openOverlay={openOverlay}
            onTncChangeHandler={onTncChangeHandler}
            showNewsLetterCheckBoxOnly={true}
            onNewsLetterChangeHandler={onNewsLetterChangeHandler}
            key="post-pay-news-letter-condition"
            stateData={{
              showCheckBox,
              hideNewsletterOption,
              disableBookButton,
              hasTnCError,
              tabIndex,
              isTncChecked: isEnabled,
              newsLetterEnabled: newsLetterEnabled,
              bindCheckBoxError: true,
            }}
          />
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, UIConfig.paymentOption.PostPay);
    }
  }
}
