import React, { useState } from 'react';
import { logComponentRenderingError, Logging } from '../../../../common/logger';
import { DropDown, SelectWithText } from '..';
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import UIConfig from '../../../../common/UIConfig';
import { getJSSFieldSectionErrors, getFieldValue, getEnvProxyUrl } from '../../../../common/forms-utility';
import { resolvePath } from '../../../../common/utility';
import { FormServices } from '../../../../common/services';

const CascadeDropDown = (props) => {
  const [fields, setFields] = useState(props.field.fields);
  const { selectWithTextId, dropDownId } = UIConfig.jssForms.fields;

  const getInitialValue = () => {
    const filterData = fields.filter((itemData) => itemData.model.fieldTypeItemId !== selectWithTextId);
    const typeId = filterData.length > 0 ? resolvePath(filterData[0], 'model.fieldTypeItemId', '') : '';
    if (typeId && typeId === dropDownId) {
      const initialObj = filterData[0].model.items.find((item) => item.selected) || {};
      return resolvePath(initialObj, 'value', '');
    }
    return '';
  };
  const [selectWithTextValue, setSelectWithTextValue] = useState(getInitialValue());
  const [apiData, setApiData] = useState(null);

  const getCascadeDropList = ({ item, nextItem, url, selectedItemId }) => {
    if (apiData && apiData[selectedItemId]) {
      nextItem.model.items = apiData[selectedItemId];
    } else {
      FormServices.getDataByItemID({
        moduleName: 'CustomCalendarInput',
        serviceUrl: url,
        preLoader: false,
      })
        .then((res) => {
          if (res.data) {
            nextItem.model.items = res.data;
            setApiData({
              ...apiData,
              [selectedItemId]: res.data,
            });
            return item;
          }
        })
        .catch((err) => {
          Logging(err, 'CustomCalendarInput API failed');
        });
    }
    return item;
  };

  const updateSelectWithTextValue = ({ valueFieldName, selectedItemValue }) => {
    const filter = fields.filter((itemData) => itemData.model.fieldTypeItemId !== selectWithTextId);
    if (filter.length && valueFieldName === filter[0].valueField.name) {
      setSelectWithTextValue(selectedItemValue);
    }
  };

  const dropDownHandler = ({ dropDownSelectedItem, valueFieldName, cascadingUrl }) => {
    const selectedItemId = resolvePath(dropDownSelectedItem, 'itemId', '');
    const selectedItemValue = resolvePath(dropDownSelectedItem, 'value', '');
    const updatedSelectedId = selectedItemId && selectedItemId.replace(/[{}]/g, '');
    const getProxyObj = getEnvProxyUrl();
    let url = cascadingUrl && cascadingUrl.replace('{itemId}', updatedSelectedId);
    if (getProxyObj.isAkamai === 'false') {
      url = getProxyObj.siteCoreApiUrl + url;
    }

    let cascadeDropDownValue =
      fields &&
      fields.map((item, idx) => {
        if (item.valueField.name === valueFieldName) {
          updateSelectWithTextValue({ dropDownSelectedItem, selectedItemValue, item, valueFieldName });
          if (cascadingUrl && updatedSelectedId) {
            return getCascadeDropList({
              selectedItemId,
              url,
              item,
              nextItem: fields[idx + 1],
            });
          }
        }
        return item;
      });
    setFields(cascadeDropDownValue);
  };

  const handleDropDown = () => {
    return fields.map((field) => {
      const errors = getJSSFieldSectionErrors(field, props.errors);
      const typeId = field.model.fieldTypeItemId;
      const fieldValue = getFieldValue(field, props.apiFieldValue);
      if (typeId === selectWithTextId) {
        return (
          <SelectWithText
            {...props}
            dropDownValue={selectWithTextValue}
            field={field}
            hasDependentField={true}
            errors={errors}
            apiFieldValue={fieldValue}
          />
        );
      } else if (typeId === FieldTypes.DropdownList || typeId === dropDownId) {
        return (
          <DropDown
            {...props}
            field={field}
            dropDownHandler={dropDownHandler}
            hasDependentField={true}
            errors={errors}
            apiFieldValue={fieldValue}
          />
        );
      }
    });
  };

  try {
    return handleDropDown();
  } catch (err) {
    return logComponentRenderingError(err, 'CascadeDropDown');
  }
};

export default CascadeDropDown;
