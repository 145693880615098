import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { isEnvDevelopment } from '../../../common/utility';

const middleWares = [thunk];
const enhancers = [applyMiddleware(...middleWares)];

// Choose compose method depending upon environment and platform
const composeEnhancers = isEnvDevelopment() && typeof window === 'object' ? composeWithDevTools : compose;

/**
 * Create redux store with the middleWares and enhancers
 *
 * @param {Object} options
 * @param {Object} options.reducer - Reducers associated with the page component
 */

export default (options) => {
  const { reducer, initialState } = options;
  return createStore(reducer, initialState, composeEnhancers(...enhancers));
};
