import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import OfflineComponent from './youtube-offline';
import { Logging } from '../../../common/logger';

const LiveStreaming = (props) => {
  const [isLive, setIsLive] = useState(false);
  const [youtubeVideoId, setYoutubeVideoId] = useState(null);
  const [showOfflineComponent, setShowOfflineComponent] = useState(false);

  useEffect(() => {
    if (props.youtubeChannelId && props.youtubeApiKey) {
      fetchYoutubeData();
    } else {
      Logging({}, 'LiveStreaming', false, 'YouTube support requires a youtubeApiKey and youtubeChannelId prop');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchYoutubeData = () => {
    fetch(
      `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${props.youtubeChannelId}&eventType=live&type=video&key=${props.youtubeApiKey}`,
      {
        headers: {
          Accept: 'application/json',
        },
      },
    )
      .then(async (res) => {
        const response = await res.json();

        if (response.items && response.items.length >= 1) {
          const streamInfo = response.items[0];
          setIsLive(true);
          setYoutubeVideoId(streamInfo && streamInfo.id.videoId);
        } else {
          setShowOfflineComponent(true);
        }
      })
      .catch((err) => {
        Logging(err, 'LiveStreaming', false, 'Error fetching data from YouTube API');
      });
  };

  return props.enableLiveStream ? (
    isLive ? (
      <div className="live-streaming-wrapper">
        <iframe
          src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1&mute=1&rel=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Live Streaming"
        ></iframe>
      </div>
    ) : (
      showOfflineComponent && <OfflineComponent message={props.offlineMessage} />
    )
  ) : (
    <OfflineComponent message={props.offlineMessage} />
  );
};

LiveStreaming.propTypes = {
  youtubeChannelId: PropTypes.string,
  youtubeApiKey: PropTypes.string,
};

LiveStreaming.defaultProps = {
  youtubeChannelId: null,
  youtubeApiKey: null,
};

export default LiveStreaming;
