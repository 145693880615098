/*
 * accordian-components.js
 * This file contains the logic for making the accordian.
 * This comes in the two configurations-> Multi epxnadable and single expandable. This internally usses dl dt dd
 * configuration for the markup.
 * @licensor  Miral
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import UIConfig from '../../../common/UIConfig.js';
import { getClosestByClass, parseQueryString, checkTenant, canUseDOM } from '../../../common/utility';
import AccordianListItem from './accordian-listitem-component';
import { logComponentRenderingError } from '../../../common/logger';

import './accordian-component.scss';
import classNames from 'classnames';

const summaryConditions = (e, parentEle) => {
  //Checking if the click has happened on SVG
  const isClickOnSvg = !e.target.dataset.hasOwnProperty('isSummary') && e.target.classList.contains('chevron');
  //Checking if the click has happend on summary text or detail text. State is changed only when the
  //click has happened on the summary text which is determine don the basis of data-issummary
  const isClickOnText = e.target.dataset.hasOwnProperty('isSummary') && e.target.dataset.isSummary === 'true';
  const isParentEle = !e.target.dataset.hasOwnProperty('isSummary') && parentEle && parentEle.isSummary === 'true';
  return isClickOnSvg || isClickOnText || isParentEle;
};

/**
 * Accordion creates the accordion with the list provided
 */
const Accordion = (props) => {
  const [elementClicked, setElementClicked] = useState(true);
  const [reRenderedByAccordian, setReRenderedByAccordian] = useState(false);
  const [refreshTabs, setRefreshTabs] = useState(false);
  const isRTE = !props.hasOwnProperty('isRTE') || props.isRTE;
  const openDefaultIndex = props.hasOwnProperty('openDefaultIndex') ? props.openDefaultIndex : -1;
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    if (canUseDOM()) setDomReady(true);
  }, []);

  useEffect(() => {
    window.PubSub.subscribe(UIConfig.events.REFRESH_TABS, () => {
      setRefreshTabs((prevRefreshTabs) => !prevRefreshTabs);
    });

    // returned function will be called on component unmount
    return () => {
      window.PubSub.unsubscribe(UIConfig.events.REFRESH_TABS);
    };
  }, []);

  /**
   * changeAccordianState function changes the state of the accordian. It detects the clicked element
   * and send the same to the child elements so that they can expand or collapse accordingly.
   * @param    {[Object]} e [Event object of click].
   * @return   {Void} function does not return anything.
   */
  const changeAccordianState = (e) => {
    //Checking if the click event has happend or a space bar or enter has been pressed.
    const keyCode = e.which || e.keyCode;
    if (e.type === 'click' || (e.type === 'keypress' && (keyCode === 13 || keyCode === 32))) {
      if (e.type === 'keypress' && (keyCode === 13 || keyCode === 32)) {
        e.preventDefault();
      }
      //The RTE coming inside the elements can be wrapped in a div and hence we need to find a closest
      //conatiner that carries data-issumary attribute
      const parentEle = getClosestByClass(e.target, 'rte-field')
        ? getClosestByClass(e.target, 'rte-field').dataset
        : null;
      if (summaryConditions(e, parentEle)) {
        //Fetching the which list index which has been clicked and setting that in state param which will be
        //passed on to the children
        const clickedEleIndex = getClosestByClass(e.target, 'accordian--list-item').dataset.index;
        setElementClicked(clickedEleIndex);
        setReRenderedByAccordian(!reRenderedByAccordian);
      }
    }
  };

  const itemList = (item, index) => (
    <AccordianListItem
      key={domReady ? index + 'rerender' : index}
      data={item}
      index={index}
      parentIndex={props.index}
      isRTE={isRTE}
      isMultiExpandable={props.isMultiExpandable}
      elementClicked={elementClicked}
      reRenderedByAccordian={reRenderedByAccordian}
      defaultOpen={
        domReady ? (item.hashKey && item.hashKey === parseQueryString('cl') ? true : index === openDefaultIndex) : false
      }
      refreshTabs={refreshTabs}
      getOrderDetail={props.getOrderDetailFn}
      prevCurrentLimit={props.prevCurrentLimit}
      isPastPurchasedRecommendation
      isCollapsibleComponent={props.isCollapsibleComponent}
      title={props.title}
      tabTitle={props?.tabTitle}
      variant={props?.variant}
    />
  );

  try {
    return (
      <ul
        className={classNames('accordian', {
          container: !isSwad,
        })}
        data-c-name="Accordion"
        onClick={changeAccordianState}
        onKeyPress={changeAccordianState}
      >
        {props.accordianItems.map((item, index) =>
          props.isPastPurchasedRecommendation
            ? item && item.hasOwnProperty('summaryComponent') && itemList(item, index)
            : itemList(item, index),
        )}
      </ul>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Accordion');
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
Accordion.propTypes = {
  accordianItems: PropTypes.arrayOf(PropTypes.object),
};

export default Accordion;
