import { combineReducers } from 'redux';
import MiniCartReducer from '../../components/container/mini-cart/mini-cart-reducer';
import BookTicketReducer from '../../components/container/book-ticket/book-ticket-reducer';

const defaultFunction = () => {
  //default function
};

export const createMainReducer = (storeData) => {
  var combinedReducerObject = {};
  var combinedInitialState = {};
  var reducer = {
    mainReducer: {},
    initialState: {},
  };

  // 1st loop to find parent reducer
  for (var components in storeData) {
    if (storeData.hasOwnProperty(components)) {
      const reducerObj = {},
        initialState = {};
      let reducerFunction;

      // 2nd loop to get GUID of a reducer to make multiple instances
      storeData[components].forEach((componentInstance) => {
        switch (componentInstance.reducerName) {
          //find matching reducer to get reference to associated  reducer function
          case 'MiniCartReducer':
            reducerFunction = MiniCartReducer;
            break;
          case 'BookTicketReducer':
            reducerFunction = BookTicketReducer;
            break;
          default:
            reducerFunction = defaultFunction;
        }
        //execute the reducer function with specific GUID as namespace for a reducer
        reducerObj[componentInstance.uid] = reducerFunction(componentInstance.uid);
        initialState[componentInstance.uid] = componentInstance.data;
      }, this);
      //add it to main reducer object with key as parent reducer name and value of combine reducers
      combinedReducerObject[components] = combineReducers(reducerObj);
      combinedInitialState[components] = initialState;
    }
  }
  //combine all main reducers
  reducer.mainReducer = combineReducers(combinedReducerObject);
  reducer.initialState = combinedInitialState;
  return reducer;
};
