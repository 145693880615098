import moment from 'moment';
import UIConfig from '../../../common/UIConfig';

let validationErrors = {},
  firstInputErrorField = null;
const defaultRules = {
  isRequired: {
    test: function(value, type) {
      if (type === 'Checkbox' || type === 'Radio') {
        return value === 'false' || value === '' || value === false;
      }
      if (type === 'CheckBoxGroup') {
        return !Array.isArray(value) || !value.length;
      }
      return value === undefined || value === ' ' || value === '';
    },
  },
  isConfirm: {
    test: function(value, confirmFieldValue) {
      return !(value.toLowerCase() === confirmFieldValue.toLowerCase() && value !== ' ');
    },
  },
};
const defaultMessages = {
  default: 'Please correct fields errors',
  isRequired: 'Please enter required field',
};

const getErrorMessage = (field, isError, msg, rule) => {
  return {
    [field]: { message: msg !== undefined ? msg : defaultMessages[rule], valid: isError ? 'error' : 'valid' },
  };
};
const removeValidField = (validationErrors, field) => {
  return delete validationErrors[field];
};
const runRule = (rule, value, field, optional) => {
  if (!defaultRules[rule]) {
    let pattern = new RegExp(rule);
    return !pattern.test(value);
  }
  return defaultRules[rule].test.call(this, value, optional);
};
const ruleValidator = (validationRules, value, field, payLoad) => {
  let isError = false,
    errorOject = null;
  const ruleObject = validationRules.filter((rule, i) => {
      return rule.name === field;
    }),
    rules = ruleObject[0] && ruleObject[0].rules;
  if (!rules) {
    return;
  }
  if (rules.isRequired === true) {
    isError = runRule('isRequired', value, field, rules.type);
    if (isError) {
      let msg = rules.message;
      errorOject = getErrorMessage(field, isError, msg, 'isRequired');
    }
  } else {
    if (value && !Array.isArray(value) && value !== 'false') {
      isError = runRule('isRequired', value, field, rules.type);
      if (isError) {
        let msg = rules.message;
        errorOject = getErrorMessage(field, isError, msg, 'isRequired');
      }
    } else {
      return null;
    }
  }
  if (rules.minDate) {
    const date = value && moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      momentDate = moment(date);
    if (momentDate.isValid() && momentDate.isBefore(rules.minDate)) {
      isError = runRule('minDate', value, field);
      errorOject = getErrorMessage(field, isError, rules.minMessage, 'minDate');
    }
  }
  if (rules.maxDate || rules.maxDate === '0') {
    const date = value && moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      momentDate = moment(date);
    if (momentDate.isValid()) {
      const maxDate = moment()
        .add(parseInt(rules.maxDate), 'day')
        .format('YYYY-MM-DD');
      if (momentDate.isAfter(maxDate)) {
        isError = runRule('maxDate', value, field);
        errorOject = getErrorMessage(field, isError, rules.maxMessage, 'maxDate');
      }
    }
  }
  if (rules.isConfirm === true && payLoad && !isError) {
    const fieldToConfirm = rules.fieldToConfirm ? rules.fieldToConfirm : null;
    if (fieldToConfirm !== null) {
      const confirmFieldValue = payLoad[fieldToConfirm];

      isError = runRule('isConfirm', value, field, confirmFieldValue);
      if (isError) {
        const msg = rules.cMessage;
        errorOject = getErrorMessage(field, isError, msg, 'isRequired');
      }
    }
  }
  if (!isError && rules.validation) {
    const validation = rules.validation;
    for (let i = 0; i < validation.length; i++) {
      const rule = validation[i];
      if (payLoad.selectedCardType?.length && payLoad.selectedCardType === 'Amex') {
        let regex =
          '^((?:4d{3})|(?:5[1-5]d{2})|(?:6011)|(?:3[68]d{2})|(?:30[012345]d))[ -]?(d{4})[ -]?(d{4})[ -]?(d{4}|3[4,7]d{13})|(^3$|^3[47][0-9]{0,13})$';
        let valueWithoutSpace = value.replace(/ /g, '');
        isError = runRule(regex, valueWithoutSpace, field);
      } else {
        isError = runRule(rule.regex, value, field);
      }
      if (isError) {
        const msg = rule.error;
        errorOject = getErrorMessage(field, isError, msg, 'default');
        break;
      }
    }
  }
  return errorOject;
};
export const validateField = (validationRules, value, field, payLoad, self = {}) => {
  if (field === 'cardNumber') {
    let cardType = self?.props?.payData?.cardTypes.find((item) => {
      let regExpression = new RegExp(item?.regexValue);
      return regExpression?.test(value);
    });
    let selectedCardType = cardType ? cardType?.key : '';
    payLoad = { ...payLoad, selectedCardType: selectedCardType };
  }
  let error = ruleValidator(validationRules, value, field, payLoad);
  if (error === null) {
    if (validationErrors.hasOwnProperty(field)) {
      error = removeValidField(validationErrors, field);
    }
    error = {};
  }
  window.PubSub.subscribe(UIConfig.events.DISABLE_INLINE_SIGNUP, () => {
    validationErrors = {};
  });
  validationErrors = { ...validationErrors, ...error };
  return validationErrors;
};

export const validateForm = (validationRules, payLoad) => {
  let errors;
  for (let key in payLoad) {
    if (payLoad.hasOwnProperty(key)) {
      let value = payLoad[key],
        field = key;
      errors = validateField(validationRules, value, field, payLoad);
      //for form submit accessibility(reference of the first input field with error)
      if (Object.keys(errors).length === 1 && !firstInputErrorField) {
        firstInputErrorField = document.querySelector('form').elements[Object.keys(errors)];
      }
    }
  }
  return errors;
};

export const initValdiations = () => {
  validationErrors = {};
  firstInputErrorField = null;
};
//set focus on the first input field with error
export const focusFirstInputErrorField = () => {
  if (firstInputErrorField) {
    firstInputErrorField.focus();
  }
};
