/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import ContentSearchResults from '../../presentation/content-search-results/content-search-results-component';
import SearchDescription from '../../presentation/search-description/search-description-component';
import { AutoSuggest, SvgSprite } from '../../presentation/base';
import { logComponentRenderingError } from '../../../common/logger';
import DynamicContent from '../../presentation/base/dynamic-content/dynamic-content-component';
import UIConfig from '../../../common/UIConfig';

/**
 * Search is dumb Component, shows listing of searched content
 * @param    {data} Object as defined from data-contract.
 * @return   {htmlMarkUp}
 */

const Search = (props) => {
  const { data } = props;
  const searchSettingVariant = props.data.searchSettingVariant || props.data.searchVariant || '';
  try {
    const [searchDescription, setSearchDescription] = useState({
      resultsCount: 0,
      searchTerm: '',
    });

    const publishSearchData = (searchInfo) => {
      setSearchDescription(searchInfo);
    };

    return (
      <div
        className={`component c-search ${
          searchSettingVariant === UIConfig.commonVariant.autoSuggestSearchVariant ? 'search-icon-style' : ''
        } `}
        data-c-name="Search"
        data-c-render="universal"
      >
        <div className="bg-container">
          <div className="auto-suggest">
            <AutoSuggest data={data} variant={UIConfig.commonVariant.searchVariant} />
          </div>
          <div className="search-wrapper">
            <SearchDescription data={data} noResultsPage={searchDescription.noResult} searchInfo={searchDescription} />
          </div>
          <div className="svg-container">
            <SvgSprite id="icn-rmedia-bot" styleClass="icn-rmedia-bot" />
          </div>
        </div>
        {searchDescription.noResult && data.additionalProperty && data.additionalProperty.enableDidYouMean && (
          <div className="popular-search-results">
            <DynamicContent
              tagName="h3"
              attrs={{ className: 'heading-3 result-text' }}
              innerHtml={data.additionalProperty && data.additionalProperty.noResultSorryText}
            />
          </div>
        )}
        {data && (
          <ContentSearchResults
            data={data}
            noResultsPage={
              searchDescription.noResult && data.additionalProperty && data.additionalProperty.enableDidYouMean
            }
            publishSearchData={publishSearchData}
          />
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Search', data.variant);
  }
};

export default Search;
