/*
 * cart-componet.js
 *
 * @author    SapientNitro
 * @licensor  Miral
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  detectMobile,
  getLocalStorageByKey,
  groupBy,
  setLocalStorage,
  toTwoDecimalPlaces,
  isEventTypeF1InCart,
  getUserAgent,
  showShippingBillingAddress,
  isYasArenaJourney,
  getPartnerCodeData,
  isLoggedInUser,
  redirectTo,
  checkTenant,
  getMainObject,
  getLoggedInUser,
  isMobileOrTab,
  checkParksTenants,
  checkIfParks,
  isMatchTenant,
  setSessionStorage,
  checkGA4Tenants,
  isParksExpressCheckoutTenant,
  checkIfParksTenants,
  parseQueryString,
  isTicketUpgradeJourney,
  removeTicketUpgradeCart,
  getSessionStorage,
} from '../../../../common/utility';
import { KeyCodes } from '../../../../common/constants';
import CartSummary from '../../../presentation/b2c-purchase-journey/cart-summary/cart-summary-component';
import CurrencyConverter from '../../../presentation/b2c-purchase-journey/currency-converter';
import CouponCode from '../../../presentation/base/coupon-code';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import ErrorSummary from '../../../presentation/base/error-summary/error-summary-component';
import Image from '../../../presentation/base/image/image-component';
import SvgSprite from '../../../presentation/base/svg-sprite/svg-sprite-component';
import ErrorOverlay from '../../../presentation/error-overlay/error-overlay-component.js';
import CheckoutButtons from './checkout-buttons-component';
import GroupedProductRow from './grouped-product-row';
import PackageProductRow from './package-product-row';
import GTMData from '../gtm-data';
import Tooltip from '../../../presentation/tooltip/tooltip-component';
import { checkForCartGlobalErrors, checkForInvalidQuantityProducts } from './helper.js';

export const isGeneral = (itemType) => {
  return UIConfig.b2c.purchaseJourney.tabs.general_admission.indexOf(itemType) !== -1;
};

export const isAnnualPass = (itemType) => {
  return UIConfig.b2c.purchaseJourney.tabs.annual_pass === itemType;
};

/**
 * renderEmptyCart function renders empty cart view incase no products are in cart
 * @param    {[Object]} data [empty cart data required to render the view].
 * @param    {[Boolean]} isCartPaymentView [Render Empty Cart in 2-step Purchase Journey Mode]
 * @return   {[Object]} returns html .
 */
const RenderEmptyCart = ({ data, isCartPaymentView = false, propsData, isSwad, scaleMiniCartContainer }) => {
  const isMiniCart = data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.miniCart;
  const iscouponCodeMiniCart = data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart;
  const emptyContainerClass = classNames('empty-cart-container', { 'empty-cart-container-payment': isCartPaymentView });
  const imageClasses = classNames('empty-cart-container-image', {
    'empty-cart-container-payment-image': isCartPaymentView,
  });
  const footerClasses = classNames({
    'button btn-primary disabled': isMiniCart || iscouponCodeMiniCart,
    'button btn-primary': !isMiniCart || !iscouponCodeMiniCart,
    'footer-checkout-payment': isCartPaymentView,
  });
  detectMobile() && scaleMiniCartContainer(true);

  useEffect(() => {
    const element = document.querySelector('.mypayment--page');
    const headerRef = document.getElementsByClassName('c-header')[0];
    const stickyRef = document.getElementsByClassName('show-cart-content')[0];

    if (element) {
      element.classList.add('empty-cart');
    }
    if (stickyRef && headerRef) {
      headerRef.classList.remove('show-cart-content');
    }
    return () => {
      if (element) {
        element.classList.remove('empty-cart');
      }
      if (stickyRef && headerRef) {
        headerRef.classList.remove('show-cart-content');
      }
    };
  }, []);

  return (
    <div className={emptyContainerClass}>
      <DynamicContent tagName="p" attrs={{ className: 'empty-title' }} innerHtml={data.emptyCart.title} />
      <DynamicContent tagName="p" attrs={{ className: 'empty-subTitle' }} innerHtml={data.emptyCart.subTitle} />
      <div className={imageClasses}>
        {!isCartPaymentView && data.emptyCart.image && <Image image={data.emptyCart.image} />}
        {isCartPaymentView && <div className="empty-cart-container-payment-freddie"></div>}
      </div>

      {data.emptyCart.cta && (
        <div className={footerClasses}>
          {data.emptyCart.cta.html ? (
            <DynamicContent tagName="div" innerHtml={data.emptyCart.cta.html} />
          ) : (
            <DynamicContent
              tagName="a"
              attrs={
                !isSwad
                  ? {
                      href: data.emptyCart.cta.href,
                      tabIndex: `${isMiniCart || iscouponCodeMiniCart ? '-1' : '0'}`,
                      onClick: (e) => handleJSBridge(e, propsData.yasArenaCart),
                    }
                  : {
                      href: data.emptyCart.cta.href,
                      tabIndex: `${isMiniCart || iscouponCodeMiniCart ? '-1' : '0'}`,
                      onClick: (e) => handleJSBridge(e, propsData.yasArenaCart),
                      'aria-hidden': !isCartPaymentView ? true : false,
                    }
              }
              innerHtml={`${data.emptyCart.cta.label} <span class="btn-icon"><span>`}
            />
          )}
        </div>
      )}

      {data.emptyCart.secondaryCta && (
        <div className={`${footerClasses} btn-second`}>
          {data.emptyCart.secondaryCta.html ? (
            <DynamicContent tagName="div" innerHtml={data.emptyCart.secondaryCta.html} />
          ) : (
            <DynamicContent
              tagName="a"
              attrs={{ href: data.emptyCart.secondaryCta.href }}
              innerHtml={data.emptyCart.secondaryCta.label}
            />
          )}
        </div>
      )}
    </div>
  );
};

//Triggered on click of view event Button Link of empty cart
//Only for Etihad Arena
const handleJSBridge = (e, propsData) => {
  const { isYasArenaView } = propsData.yasArenaCart;
  if (isYasArenaView && getUserAgent()) {
    e.preventDefault();
    window.JSbridge.nativeCallback({
      orderID: '',
      ref: '',
      status: '',
      error: {
        code: '1001',
        text: UIConfig.mobileAppMessage.eventDetail,
      },
    });
  }
};

const getCouponCodeData = (propsData) => {
  const data = propsData.data.couponCode;
  data.service = propsData.couponCode.services.validateCoupon;
  data.promoDetails = propsData.cartData.cart.promotions ? propsData.cartData.cart.promotions : [];
  data.couponCode = propsData?.cartData?.cart?.couponCode;
  data.vivaMemberShip = propsData.data.vivaMemberShip;
  return data;
};

const getConvertedCurrencyLabel = (props) => {
  return props.convertedCurrency
    ? ` (${props.convertedCurrency.converted_currency} ${toTwoDecimalPlaces(
        props.convertedCurrency.converted_amount * props.cartData.cart.grossPrice,
      )})`
    : '';
};

const loadMyCartSummaryView = (propData) => {
  const tenantId = getLoggedInUser().tenantID || getMainObject().tenantID;
  const isYMCTenant = tenantId.toLowerCase() === UIConfig.ymcB2CTenant;
  const isFwad = tenantId.toLowerCase() === UIConfig.tenants.fwad;
  return (
    (propData.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.myCart ||
      propData.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.cartPaymentView ||
      propData.showMinicartCouponCode ||
      propData.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.yasArenaCart) &&
    propData.cartData.cart.couponCode &&
    Array.isArray(propData.cartData.cart.promotions) &&
    propData.cartData.cart.promotions.length > 0 &&
    propData.cartData.cart?.promotions?.map((promo, index) => {
      if ((isYMCTenant || checkIfParks()) && promo?.type !== '2' && promo?.type !== '4') {
        return null;
      }
      return (
        <div className="coupon-discount-container">
          <DynamicContent
            tagName="p"
            attrs={{ className: 'coupon-discount-label' }}
            innerHtml={`${propData.data.cartLabels.discount}`}
          />
          <DynamicContent
            tagName="p"
            attrs={{ className: 'coupon-discount-value' }}
            innerHtml={` ${propData.cartData.cart.currency} 
        ${toTwoDecimalPlaces(
          propData.cartData.cart.promotions && propData.cartData.cart.promotions[index].discount
            ? parseInt(propData.cartData.cart.promotions[index].discount)
            : 0,
        )}`}
          />
        </div>
      );
    })
  );
};

const getAllCartProducts = (cartData) => {
  const cartState = cartData.cart;
  const cartProductArr = [];
  if (cartState && cartState.totalQuantity !== '0' && Object.keys(cartState.items).length) {
    Object.keys(cartState.items).forEach((cartProd) => {
      let filterProd = cartState.items[cartProd].products;
      if (filterProd && filterProd.length) {
        cartProductArr.push(filterProd[0]);
      }
    });
  }
  return cartProductArr;
};

/**
 * CreateCartSummary function renders the cart summary section.
 * @param    {[Object]} propData [data required to render the view].
 * @param    {[function]} openOverlay [function to open the overlay].
 * @param    {[Boolean]} isCartPaymentView [Create Cart Summary for 2-step Purchase Journey Mode]
 * @return   {[Object]} returns html .
 */
const CreateCartSummary = ({
  propData,
  openOverlay,
  invalidProductQuantity,
  isCartPaymentView = false,
  isParksExpressCheckout,
  isCartExpanded,
  isSwad,
  isParksExpressCheckoutTenant,
  isMiniCartEnabled,
  toggleMiniCart,
  isMiniCartExpanded,
}) => {
  const [isAnnualPassPage, setIsAnnualPassPage] = useState(false);

  useEffect(() => {
    if (
      (checkTenant(UIConfig.iamMapping.fwad) ||
        checkTenant(UIConfig.iamMapping.wbw) ||
        checkTenant(UIConfig.iamMapping.yww)) &&
      detectMobile() &&
      document.body.classList.contains('buy-annual-pass--page')
    ) {
      setIsAnnualPassPage(true);
    }
    return () => {
      setIsAnnualPassPage(false);
    };
  }, []);

  const cartProductsArr = getAllCartProducts(propData.cartData);
  const promoCodeShow =
    checkParksTenants() && propData?.data?.ticketUpgrade?.enableTicketUpgrade && isTicketUpgradeJourney()
      ? false
      : true;

  let isF1FoundInCart = false;
  let showShippingBilling = true;
  if (cartProductsArr && cartProductsArr.length) {
    isF1FoundInCart = isEventTypeF1InCart(cartProductsArr);
    showShippingBilling = showShippingBillingAddress(cartProductsArr);
  }
  const isMiniCart = propData.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart;
  const getCurrencyDropDownContainer = () => {
    return (
      <div className="currency-dropdown-container">
        {propData.data.currencyConverters && (
          <CurrencyConverter
            defaultCurrency={propData.convertedCurrency ? propData.convertedCurrency.converted_currency : null}
            currencyList={propData.data.currencyConverters.currencyMapping}
            currencyCallback={propData.currencyCallBack}
            title={propData.data.currencyConverters.currencyConverterTitle}
            subTitle={propData.data.currencyConverters.currencyConverterSubTitle}
            isCartPaymentView={propData.isCartPaymentView}
            showMinicartCouponCode={propData.showMinicartCouponCode}
            currencySelectorAriaLabel={propData.data.currencyConverters.currencySelectorAriaLabel}
            isCurrencyConverter={checkTenant(UIConfig.iamMapping.yww)}
          />
        )}
      </div>
    );
  };

  const toggleAnnualPassMiniCart = () => {
    window.PubSub.publish('miniCartArrowClicked', {
      showCart: false,
    });
    let bodyRef = document.getElementsByTagName('body')[0];
    let headerRef = document.getElementsByClassName('c-header')[0];
    let stickyRef = document.getElementsByClassName('show-cart-content')[0];
    if (stickyRef) {
      bodyRef.classList.remove('disable-scroll');
      headerRef.classList.remove('show-cart-content');
    } else {
      bodyRef.classList.add('disable-scroll');
      headerRef.classList.add('show-cart-content');
    }
  };

  return (
    <div className="cart-summary-container">
      <div className="cart-summary">
        <div className="cart-summary-details">
          {isSwad && propData.isCartPaymentView && propData.data.cartLabels.billingSummaryLabel && (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'billing-summary-title' }}
              innerHtml={propData.data.cartLabels.billingSummaryLabel}
            />
          )}
          <div className="cart-price-container">
            <label
              className="c-mini-check-icon"
              htmlFor="cartCheckbox"
              onClick={(e) => {
                if (isAnnualPassPage) {
                  toggleAnnualPassMiniCart();
                } else {
                  canUseDOM() && document.querySelector('body').classList.toggle('minicart-no-scroll');
                  document.body.classList.toggle('minicart-expanded');
                  /* added for fwad minicart */
                  if (checkTenant(UIConfig.iamMapping.fwad) || isMiniCartEnabled) {
                    if (
                      isMobileOrTab() &&
                      !isCartPaymentView &&
                      !isMiniCartExpanded &&
                      !checkTenant(UIConfig.iamMapping.ppad)
                    ) {
                      document
                        .getElementsByClassName('b2c-mini-cart-wrapper')[0]
                        .scrollIntoView({ behavior: 'smooth' });
                    }
                    toggleMiniCart();
                    window.PubSub.publish('miniCartArrowClicked', {
                      showCart: false,
                    });
                  }
                }
              }}
            >
              <span className="sr-only">
                {propData.data.ariaLabelCart
                  ? `${propData.data.ariaLabelCart} ${propData.data.cartTitle}`
                  : `${propData.ariaLabelCart} ${propData.data.cartTitle}`}
              </span>
            </label>
            <div className="total-price-container">
              <div
                className="icon-container"
                aria-label={
                  propData.data.basketAriaLabel
                    ? propData.data.basketAriaLabel + ' ' + propData.cartData.cart.totalQuantity.toString()
                    : propData.basketAriaLabel + ' ' + propData.cartData.cart.totalQuantity.toString()
                }
              >
                <SvgSprite id="icon-basket" ariaHidden="false" focusableAttribute="true" />
                <span>
                  {propData.cartData.cart.totalQuantity && (
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'total-quantity' }}
                      innerHtml={propData.cartData.cart.totalQuantity.toString()}
                    />
                  )}
                </span>
              </div>
              {!isCartPaymentView &&
                propData.data.showCouponSection &&
                !propData.isRenewAnnualPass &&
                promoCodeShow && (
                  <CouponCode
                    data={getCouponCodeData(propData)}
                    businessErrors={propData.data.businessErrors}
                    cartData={propData.cartData}
                    cartProps={propData}
                    addCallback={propData.couponCode.applyCouponCallback}
                    showMinicartCouponCode={propData.showMinicartCouponCode ? propData.showMinicartCouponCode : false}
                    removeCallback={propData.couponCode.removeCouponCallback}
                    setCouponServerErrorDisplay={propData.setCouponServerErrorDisplay}
                    focusOnCouponCode={propData.focusPromoWithExpandedCart}
                  />
                )}
              {!isCartPaymentView && !propData.showMinicartCouponCode && loadMyCartSummaryView(propData)}
              {propData.cartData.cart.netPrice && !isParksExpressCheckoutTenant ? (
                <div className="net-price-container">
                  <div className="net-price-container-wrapper">
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'net-price-label' }}
                      innerHtml={`${propData.data.cartLabels.net}`}
                    />
                    {propData.cartData.cart.shippingChargeNetPrice ? (
                      <DynamicContent
                        tagName="p"
                        attrs={{ className: 'net-price-value' }}
                        innerHtml={` ${propData.cartData.cart.currency} ${toTwoDecimalPlaces(
                          propData.cartData.cart.netPrice - propData.cartData.cart.shippingChargeNetPrice,
                        )}`}
                      />
                    ) : (
                      <DynamicContent
                        tagName="p"
                        attrs={{ className: 'net-price-value' }}
                        innerHtml={` ${propData.cartData.cart.currency} ${toTwoDecimalPlaces(
                          propData.cartData.cart.netPrice,
                        )}`}
                      />
                    )}
                  </div>
                </div>
              ) : null}
              {(propData.showMinicartCouponCode || isCartPaymentView) &&
                !(isParksExpressCheckout && !isCartExpanded) &&
                loadMyCartSummaryView(propData)}
              {!isMiniCart && isF1FoundInCart && showShippingBilling && (
                <div className="shipping-price-container">
                  <div className="shipping-price-container-wrapper">
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'shipping-price-label' }}
                      innerHtml={`${propData.data.cartLabels.shippingLabel}`}
                    />
                    {(propData.cartData.cart.shippingChargeNetPrice ||
                      propData.cartData.cart.shippingChargeNetPrice === 0) &&
                    propData.cartData.cart.shippingAddress ? (
                      <DynamicContent
                        tagName="p"
                        attrs={{ className: 'shipping-price-value' }}
                        innerHtml={`${propData.cartData.cart.currency} ${toTwoDecimalPlaces(
                          propData.cartData.cart.shippingChargeNetPrice,
                        )}`}
                      />
                    ) : (
                      <DynamicContent
                        tagName="p"
                        attrs={{ className: 'shipping-price-value' }}
                        innerHtml={`${propData.data.cartLabels.tbdLabel}`}
                      />
                    )}
                  </div>
                </div>
              )}
              {!(isParksExpressCheckout && !isCartExpanded) &&
              !isParksExpressCheckoutTenant &&
              propData.cartData.cart.totalTax ? (
                <div className="vat-price-container">
                  <div className="vat-price-container-wrapper">
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'vat-price-label' }}
                      innerHtml={`${propData.data.cartLabels.vat}`}
                    />
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'vat-price-value' }}
                      innerHtml={`${propData.cartData.cart.currency} ${toTwoDecimalPlaces(
                        propData.cartData.cart.totalTax,
                      )}`}
                    />
                  </div>
                </div>
              ) : null}
              {!(isParksExpressCheckout && !isCartExpanded) &&
              !isParksExpressCheckoutTenant &&
              propData.cartData.cart.totalFee &&
              propData.data.cartLabels.feeLabel &&
              propData.cartData.cart.totalFee > 0 ? (
                <div className="vat-price-container">
                  <div className="vat-price-container-wrapper">
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'vat-price-label' }}
                      innerHtml={propData.data.cartLabels.feeLabel}
                    />
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'vat-price-value' }}
                      innerHtml={`${propData.cartData.cart.currency} ${toTwoDecimalPlaces(
                        propData.cartData.cart.totalFee,
                      )}`}
                    />
                  </div>
                </div>
              ) : null}
              {!isSwad && (isCartPaymentView || propData.showMinicartCouponCode) && getCurrencyDropDownContainer()}
              <div className="currency-price-container">
                {!propData.showMinicartCouponCode && !isCartPaymentView && getCurrencyDropDownContainer()}

                <div className="final-price-container">
                  <div className="final-price-container-wrapper" tabIndex="0">
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'cart-total-label heading-4' }}
                      innerHtml={propData.data.cartLabels.total}
                    />
                    <DynamicContent
                      tagName="p"
                      attrs={{ className: 'cart-total-price heading-4' }}
                      innerHtml={`<span class="cart-total-price-aed">${
                        propData.cartData.cart.currency
                      } ${toTwoDecimalPlaces(
                        propData.cartData.cart.grossPrice,
                      )}</span><span class="cart-total-price-other-lang">${getConvertedCurrencyLabel(propData)}</span>`}
                    />
                  </div>
                </div>
                {propData.showMinicartCouponCode && (
                  <DynamicContent
                    tagName="p"
                    attrs={{ className: 'vat-info body-3' }}
                    innerHtml={propData.data.cartLabels.vatInfo}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {!isSwad && (
          <CheckoutButtons
            propData={propData}
            cartSummaryExpandedView={propData.cartSummaryExpandedView}
            invalidProductQuantity={invalidProductQuantity}
          />
        )}
      </div>
      {propData.data.tnc && (
        <div className="tnc-info-wrapper">
          <DynamicContent
            tagName="span"
            attrs={{
              onClick: () => {
                return openOverlay(propData);
              },
              className: 'tnc-info',
            }}
            innerHtml={propData.data.tncCtaTitle}
          />
        </div>
      )}

      {!isCartPaymentView && !propData.showMinicartCouponCode && (
        <DynamicContent
          tagName="p"
          attrs={{ className: 'vat-info body-3' }}
          innerHtml={propData.data.cartLabels.vatInfo}
        />
      )}
      {isCartPaymentView && propData.convertedCurrency && (
        <DynamicContent
          tagName="p"
          attrs={{ className: 'terms body-copy-6' }}
          innerHtml={propData.data.currencyConverters.currencyConverterSubTitle}
        />
      )}
    </div>
  );
};

/**
 * Cart function that renders the cart view and calls different functions for that
 * @param    {[Object]} props [data for rendering th eview].
 * @return   {[Object]} returns html .
 */
const Cart = (props) => {
  /**
   * openOverlay function to open overlay
   * @param    {[Object]} null [null].
   * @return   {[Object]} returns html .
   */
  const gridView = props.data.cartType.indexOf('gridview') !== -1;
  let chevronEl = null;
  let cartCheckBoxel = null;
  let chevronElDiv = null;
  let isStickyHeader = null;
  let isStickyCart = null;
  const miniCartChevron = useRef(null);
  const globalCartError = useRef(null);
  let cartRef = useRef(null);
  const [showErrorWrapper, setShowErrorWrapper] = useState(true);
  const [membershipDetails, setMembershipDetails] = useState({});
  const [isCartExpanded, setIsCartExpanded] = useState(false); //check whether payment-cart is exapnded or not
  const [cartItemWrapper, setCartItemWrapper] = useState();
  const [isQuotaExceeded, setQuotaExceeded] = useState(false);
  const [isMiniCartExpanded, setMiniCartExpanded] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isHeaderStickyHeight, setHeaderStickyHeight] = useState('');
  const [showEmptyMiniCart, setShowEmptyMiniCart] = useState(false);
  const [isParksExpressCheckout, setIsParksExpressCheckout] = useState(false);
  const [isMiniHeader, setMiniHeader] = useState(false);

  const [isBodyHeight, setBodyHeight] = useState('');
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isFwad = checkTenant(UIConfig.iamMapping.fwad);
  const isParks = checkIfParks();
  const isGA4Tenants = checkGA4Tenants();
  const isMiniCartEnabled = props.isMiniCartEnabled;

  const isMiniCart = props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart;
  const findMembership = getPartnerCodeData(props.data.memberDiscounts, membershipDetails.membershipData);
  const isHeaderMiniCart = props.isHeaderMiniCart;
  const [showToastMsg, setShowToastMsg] = useState(false);
  const toastMsgRef = useRef(null);
  const [cartCount, setCartCount] = useState(0);
  let invalidProductQuantity = false;
  let isGlobalCartQuantityError = false;
  const promoCodeShow =
    checkParksTenants() && props?.data?.ticketUpgrade?.enableTicketUpgrade && isTicketUpgradeJourney() ? false : true;

  const cartTypeQsValue = parseQueryString('cart-type');
  const isTicketUpgrade =
    cartTypeQsValue && cartTypeQsValue.toLowerCase() === UIConfig.parksTicketUpgrade.cartType ? true : false;

  let toastTimeout;
  let showToastTime =
    props.data?.toastNotificationTimeout && Number(props.data?.toastNotificationTimeout) > 0
      ? props.data?.toastNotificationTimeout
      : false;

  const openOverlay = (propData) => {
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: true,
      dataToAppend: <ErrorOverlay title={propData.data.tnc.title} bodyCopy={propData.data.tnc.copy} />,
      customClass: 'purchase-overlay tnc-overlay v-b2c-overlay',
    });
  };

  if (checkIfParksTenants([], [UIConfig.tenants.ppad])) {
    invalidProductQuantity = checkForInvalidQuantityProducts(props?.cartData?.cart?.items);
    isGlobalCartQuantityError = checkForCartGlobalErrors(props?.cartData?.cart?.items, props.data.maxCartQuantity);
    if (isGlobalCartQuantityError && isSwad) {
      setTimeout(() => {
        globalCartError.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }

  const checkStickyHeaderHeight = () => {
    const winHeight = window.outerHeight;
    const diffHeight = winHeight - window.innerHeight;
    setHeaderStickyHeight(diffHeight);
  };

  const getBodyHeight = () => {
    const winHeight = window.outerHeight;
    const isMobileHeaderSticky = document.querySelector('.custom-mobile-header.sticky-header');
    const topPadding = !isMobileHeaderSticky && isFwad ? 28 : 0;
    const topHeaderHeight = document.querySelector('.c-header-top')?.offsetHeight + topPadding;
    const cartSummaryEl = document.querySelector('.cart-summary-container')?.offsetHeight;
    let rmngHeight = winHeight - (topHeaderHeight + cartSummaryEl);
    setBodyHeight(rmngHeight);
  };

  const getMobileHeight = () => {
    if (isParks && isMiniCartEnabled) {
      checkStickyHeaderHeight();
      getBodyHeight();
    }
  };

  const handleNavigation = (e) => {
    const window = e.currentTarget;
    const miniCartWrapper = document.querySelector('.b2c-mini-cart-wrapper');
    const expressCheckoutMiniCartWrapper = document.querySelector('.col-lg-4.col-md-12.col-sm-12');
    if (miniCartWrapper && detectMobile()) {
      if (window.scrollY > 0) {
        miniCartWrapper.classList.add('page-scrolled');
      }
      if (window.scrollY === 0) {
        miniCartWrapper.classList.remove('page-scrolled');
      }
    } else if (expressCheckoutMiniCartWrapper && detectMobile()) {
      if (window.scrollY > 0) {
        expressCheckoutMiniCartWrapper.classList.add('page-scrolled');
      }
      if (window.scrollY === 0) {
        expressCheckoutMiniCartWrapper.classList.remove('page-scrolled');
      }
    }
  };

  useEffect(() => {
    // handles on refresh scenario to update cart count, didn't use pubsub as this component is loaded much after publish event
    if (props.isRenewAnnualPass) {
      const { cart } = JSON.parse(getSessionStorage(UIConfig.annualpassSessionCart)) || {};
      setCartCount(cart?.totalQuantity || 0);
    } else {
      const cartCount = localStorage.getItem(UIConfig.localStoreKeys.cartCount);
      setCartCount(cartCount || 0);
    }

    // handles after page load scenario to update cart count
    window.PubSub.subscribe(UIConfig.events.UPDATE_CART_COUNTER, (msg, data) => {
      setCartCount(data?.value || 0);
    });
    const isItemsInCart = !!Object.keys(props?.cartData?.cart?.items).length;
    if (isMatchTenant(UIConfig.tenants.ya) && isItemsInCart) {
      GTMData.push(UIConfig.ga4Constants.VIEW_CART, { ...props });
    }
    window.PubSub.subscribe('membershipDetailsFromScv', (msg, data) => {
      setMembershipDetails(data);
    });
    window.PubSub.subscribe(UIConfig.headerCartClick, (msg, data) => {
      setShowEmptyMiniCart(data.isEmptyToggle);
      if (data.isEmptyToggle) {
        scaleMiniCartContainer(data.isEmptyToggle);
      }
      let mycartChevron = document.querySelector('.chevronDown');
      let element =
        miniCartChevron && miniCartChevron.current ? miniCartChevron.current : mycartChevron ? mycartChevron : '';
      if (element) {
        if ((isParks && mycartChevron) || !detectMobile()) {
          element.focus();
          element.classList.add('focus');
        } else {
          element.click();
        }
      }
      //else added for fwad minicart toggle on booking page
      if (
        isParks &&
        (document.body.classList.contains('booking--page') || document.body.classList.contains('mypaymentold--page')) &&
        document.querySelector('.c-mini-check-icon')
      ) {
        if (!mycartChevron && detectMobile()) {
          document.querySelector('.c-mini-check-icon').click();
        } else {
          const myCartSummary = document.querySelector('#grid-check-icon-cart-summary');
          const miniCartSummary = document.querySelector('#mini-cart-summary');
          if (myCartSummary) {
            myCartSummary.click();
          }
          if (miniCartSummary) {
            miniCartSummary.click();
          }
        }
      }
    });
    window.PubSub.subscribe(UIConfig.AddedToCart, (msg, data) => {
      showToastMessage();
    });

    if ((isSwad && !isHeaderMiniCart) || (isSwad && isHeaderMiniCart && detectMobile())) {
      if (!detectMobile()) {
        window.onbeforeunload = function() {
          window.scrollTo(0, 0);
        };
        !isHeaderMiniCart &&
          window.PubSub.subscribe(UIConfig.hiddenPayment, (msg, data) => {
            props.stickyCart();
            data?.isPaymentMethodChanged && cartRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          });
      }
      const onPageLoad = () => {
        const onPageResize = () => {
          detectMobile() ? cartWrapperScrollHeight() : setWindowHeight(window.innerHeight);
        };
        isSwad && cartWrapperScrollHeight(); //Calculate max-height of cart-item-wraper.
        if (isHeaderMiniCart) {
          window.PubSub.subscribe('toggleHeaderCart', (msg, data) => {
            cartWrapperScrollHeight();
          });
        }
        window.addEventListener('resize', onPageResize);
        return () => {
          window.removeEventListener('resize', onPageResize);
        };
      };

      // Check if the page has already loaded
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
    }
    if (isSwad) window.PubSub.publish(UIConfig.events.EXPRESS_CHECKOUT_AP_LABELS, props.data.additionalLabels);
    getMobileHeight();
    window.addEventListener('resize', getMobileHeight);
    window.addEventListener('scroll', handleNavigation);
    return () => {
      window.removeEventListener('resize', getMobileHeight);
      window.removeEventListener('scroll', handleNavigation);
      window.PubSub.unsubscribe(UIConfig.events.UPDATE_CART_COUNTER);
      localStorage.removeItem(UIConfig.localStoreKeys.cartCount);
    };
  }, []);

  useEffect(() => {
    if (isMiniCartExpanded) {
      getMobileHeight();
    }
  }, [isMiniCartExpanded]);

  useEffect(() => {
    isSwad && !detectMobile() && props.stickyCart();
  }, [isCartExpanded]);

  useEffect(() => {
    const cartProducts = getProductByType(props.cartData.cart.items);
    if (isParksExpressCheckout && detectMobile()) {
      if (isCartExpanded && !isCartEmpty(cartProducts)) {
        document.querySelector('body').classList.add('minicart-no-scroll');
        document.getElementsByTagName('body')[0].classList.add('expanded-mycart');
      } else {
        document.querySelector('body').classList.remove('minicart-no-scroll');
        document.getElementsByTagName('body')[0].classList.remove('expanded-mycart');
      }
    }
  }, [isCartExpanded, props.cartData.cart.items]);

  useEffect(() => {
    if (isSwad && detectMobile()) {
      let cartWrapper = document.getElementById('mini-cart-summary');
      let cartShowCheckbox = document.querySelector('#cartHideShowCheckbox');
      if (cartShowCheckbox) {
        cartShowCheckbox.checked = false;
      }
      cartWrapper && cartWrapper.classList.remove('open');
    }
  }, [!Object.keys(props.cartData.cart.items).length]);

  useEffect(() => {
    if (isSwad && detectMobile()) {
      cartWrapperScrollHeight();
    }

    if (canUseDOM()) {
      if (!isTicketUpgrade && isTicketUpgradeJourney()) {
        removeTicketUpgradeCart();
        window.location.reload();
      }
    }
  }, [props.cartData]);

  useEffect(() => {
    let headerObserver;
    let cartObserver;
    setMiniHeader(!!document.querySelector('.c-mini-header'));
    if (isSwad && !detectMobile()) {
      const header = document.querySelector('.c-header.swad');
      const miniCartWrapper = document.querySelector('.b2c-mini-cart-wrapper');
      headerObserver = new MutationObserver(function(events) {
        const event = events[0];
        if (event.target.classList.contains('sticky-header')) {
          // Only triggers one time when the change happned
          if (!isStickyHeader) {
            // We need to delay this operation because the sicky header needs time to adjust with the cart container
            setTimeout(() => cartWrapperScrollHeight(), 100);
            isStickyHeader = true;
          }
        } else {
          // Only triggers one time when the change happned
          cartWrapperScrollHeight();
          isStickyHeader = false;
        }
      });
      cartObserver = new MutationObserver(function(events) {
        const event = events[0];
        if (event.target.classList.contains('sticky-cart')) {
          if (!isStickyCart) {
            setTimeout(() => cartWrapperScrollHeight(), 100);
            isStickyCart = true;
          }
        } else {
          isStickyCart = false;
        }
      });
      const options = {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false,
      };
      header && headerObserver.observe(header, options);
      miniCartWrapper && cartObserver.observe(miniCartWrapper, options);
    }
    return () => {
      headerObserver && headerObserver.disconnect();
      cartObserver && cartObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const isExpressCheckout = document.querySelector('.col-lg-4.col-md-12.col-sm-12 .component.c-my-cart-b2c');
    setIsParksExpressCheckout(!!isExpressCheckout && isParks);

    window.addEventListener('beforeunload', function(e) {
      setSessionStorage('isParksCouponApplied', false);
    });
  }, []);

  useEffect(() => {
    // Expand cart on error detection
    if (props.errObj && isParksExpressCheckout) {
      cartCheckBoxel.checked = true;
      setIsCartExpanded(true);
      chevronOnClick();
    }
  }, [props.errObj]);

  /**
   * FutureDateOverlay function tthat creates the data for partner segment overlay
   * @param    {[Object]} data cms data to create the html.
   * @param    {[Object]} onClickContinue function that deletes the product.
   * @return   {[Object]} returns html .
   */
  const FutureDateOverlay = (data, cartData) => {
    return (
      <div className="overlay-data">
        {data.title && (
          <DynamicContent
            tagName="h3"
            attrs={{ className: 'overlay-title heading-4', id: 'overlay-title' }}
            innerHtml={data.title}
          />
        )}
        {data.bodyCopy && (
          <DynamicContent tagName="p" attrs={{ className: 'overlay-bodyCopy body-1' }} innerHtml={data.bodyCopy} />
        )}
        <div className="future-date-overlay-bottom">
          <div className="btn-add-to-cart">
            <div className="btn-primary">
              <DynamicContent
                tagName="button"
                innerHtml={data.primaryCTA}
                attrs={{
                  onClick: () => onClickContinue(data.ctaLink),
                  'aria-label': data.title + data.primaryCTA,
                  className: 'add-to-cart',
                }}
              />
            </div>
          </div>
          <div className="btn-checkout">
            <div className="btn-primary">
              <DynamicContent
                tagName="button"
                innerHtml={data.secondaryCTA}
                attrs={{
                  onClick: () => onCloseAction(),
                  className: 'continue-checkout',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onClickContinue = (ctaLink) => {
    props.clearCart &&
      props.clearCart._clearCart().then(() => {
        window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
          shouldOpen: false,
        });
        ctaLink.href && redirectTo(ctaLink.href);
      });
  };
  const onCloseAction = () => {
    setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo, false);
    props.couponCode.removeCouponCallback && props.couponCode.removeCouponCallback(true);
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: false,
    });
  };
  /**
   * closeOverlay function to close overlay
   * @param    {[Object]} null [null].
   * @return   {[Object]} returns html .
   */
  const closeOverlay = () => {
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: false,
    });
  };
  const onCloseOverlayAction = () => {
    setLocalStorage(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo, false);
    props.couponCode.removeCouponCallback && props.couponCode.removeCouponCallback(true);
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: false,
    });
  };

  /**
   * deleteCallBack function that calls the deleteProduct function and closes the overlay
   * @param    {[Object]} deleteProduct function that deletes the product.
   * @param    {[Object]} item the product that has to be deleted.
   * @return   {[null]} null .
   */
  const deleteCallBack = (deleteProduct, item, maxQuantity) => {
    deleteProduct(item, maxQuantity);
    closeOverlay();
    if (props.isCartPaymentView) {
      // window.PubSub.publish(UIConfig.events.TOGGLE_GUEST_CHECKOUT_ANNUALCASE);
      window.PubSub.publish(UIConfig.events.ENABLE_PAYMENT);
    }
  };

  /**
   * Remove and changes Cart function that calls the removeYaEvent function and closes the overlay
   * @param    {[Object]} removeYaEvent function that remove/Changes the product.
   * @param    {[String]} eventid that needs to be removed.
   * @return   {[null]} null .
   */
  const yaRemoveAndChangeCallback = (removeYaEvent, mode) => {
    removeYaEvent(mode);
    UIConfig.b2c.purchaseJourney.yasArenaCart.overlayModes.change !== mode && closeOverlay();
  };

  /**
   * createOverlayData function tthat creates the data for delete overlay
   * @param    {[Object]} data cms data to create the html.
   * @param    {[Object]} deleteProduct function that deletes the product.
   * @param    {[Object]} item the product that has to be deleted.
   * @return   {[Object]} returns html .
   */
  const createOverlayData = (data, deleteProduct, item, maxQuantity) => {
    return (
      <div className="overlay-data">
        {data.title && (
          <DynamicContent
            tagName="h3"
            attrs={{ className: 'overlay-title heading-4', id: 'overlay-title' }}
            innerHtml={data.title}
          />
        )}
        {data.bodyCopy && (
          <DynamicContent tagName="p" attrs={{ className: 'overlay-bodyCopy body-1' }} innerHtml={data.bodyCopy} />
        )}
        {isSwad ? (
          <div className="btn-wrapper">
            <div className="remove-btn btn-primary">
              <DynamicContent
                tagName="button"
                attrs={{
                  onClick: () => deleteCallBack(deleteProduct, item, maxQuantity),
                  'aria-label': data.title + data.primaryCTA,
                }}
                innerHtml={data.primaryCTA}
              />
            </div>
            <div className="cancel-btn btn-secondary">
              <DynamicContent tagName="button" attrs={{ onClick: closeOverlay }} innerHtml={data.secondaryCTA} />
            </div>
          </div>
        ) : (
          <>
            <div className="remove-btn btn-primary">
              <DynamicContent
                tagName="button"
                attrs={{
                  onClick: () => {
                    deleteCallBack(deleteProduct, item, maxQuantity);
                    if (isMatchTenant(UIConfig.tenants.ya)) {
                      GTMData.push(UIConfig.ga4Constants.REMOVE_FROM_CART, [item]);
                    }
                  },
                  'aria-label': data.title + data.primaryCTA,
                }}
                innerHtml={data.primaryCTA}
              />
            </div>
            <div className="cancel-btn btn-secondary">
              <DynamicContent tagName="button" attrs={{ onClick: closeOverlay }} innerHtml={data.secondaryCTA} />
            </div>
          </>
        )}
      </div>
    );
  };
  /**
   * createOverlayData function tthat creates the data for delete overlay
   * @param    {[Object]} data cms data to create the html.
   * @param    {[Object]} deleteProduct function that deletes the product.
   * @param    {[Object]} item the product that has to be deleted.
   * @return   {[Object]} returns html .
   */
  const LimitExceedOverlay = (data) => {
    return (
      <div className="overlay-data">
        {data.title && (
          <DynamicContent
            tagName="h3"
            attrs={{ className: 'overlay-title heading-4', id: 'overlay-title' }}
            innerHtml={data.title}
          />
        )}
        {data.bodyCopy && (
          <DynamicContent tagName="p" attrs={{ className: 'overlay-bodyCopy body-1' }} innerHtml={data.bodyCopy} />
        )}
        <div className="remove-btn btn-primary">
          <DynamicContent
            tagName="button"
            attrs={{
              onClick: onCloseOverlayAction,
              'aria-label': data.title + data.primaryCTA,
            }}
            innerHtml={data.primaryCTA}
          />
        </div>
      </div>
    );
  };

  /**
   * create YA overlay function that creates the data for delete overlay
   * @param    {[Object]} data cms data to create the html.
   * @param    {[object]} removeYAEvent remove event.
   * @param    {[string]} mode - could be change or delete .
   * @return   {[Object]} returns html .
   */
  const createYaOverLayData = (data, removeYaEvent, mode) => {
    return (
      <div className="overlay-data">
        <DynamicContent tagName="h3" attrs={{ className: 'overlay-title heading-4' }} innerHtml={data.bodyCopy} />

        <div className="remove-btn btn-primary">
          <DynamicContent
            tagName="button"
            attrs={{
              onClick: () => {
                if (isMatchTenant(UIConfig.tenants.ya)) {
                  GTMData.push(UIConfig.ga4Constants.REMOVE_FROM_CART, GTMData.getAllCartProducts(props?.cartData));
                }
                yaRemoveAndChangeCallback(removeYaEvent, mode);
              },
            }}
            innerHtml={data.primaryCTA}
          />
        </div>
        <div className="cancel-btn btn-secondary">
          <DynamicContent tagName="button" attrs={{ onClick: closeOverlay }} innerHtml={data.secondaryCTA} />
        </div>
      </div>
    );
  };

  /**
   * deleteOverlay function that opens th eoverlay on click of
   * @param    {[Object]} data cms data to create the html.
   * @param    {[Object]} deleteProduct function that deletes the product.
   * @param    {[Object]} item the product that has to be deleted.
   * @return   {[Object]} returns html .
   */
  const deleteOverlay = (data, deleteProduct, item) => {
    !checkParksTenants() && data.deleteOverlay && Object.keys(data.deleteOverlay).length
      ? window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
          shouldOpen: true,
          dataToAppend: createOverlayData(data.deleteOverlay, deleteProduct, item, data.maxQuantity),
          customClass: `purchase-overlay cart-delete-overlay v-b2c-overlay ${isSwad ? 'mobile-fix-height' : ''}`,
          overlayCloseAriaLabel: data.deleteOverlay.overlayCloseAriaLabel,
        })
      : deleteCallBack(deleteProduct, item, data.maxQuantity);
  };

  /**
   *  delete YA Overlay
   * @param    {[Object]} data cms data to create the html.
   * @param    {[Function]} deleteYAEvent to be called when user response is affirmative
   * @param    {[String]} mode  - can be change or remove
   * @return   {[Object]} returns overlay html .
   */
  const deleteYAOverlay = (data, deleteYAEvent, mode) => {
    data &&
      Object.keys(data).length &&
      window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
        shouldOpen: true,
        dataToAppend: createYaOverLayData(data, deleteYAEvent, mode),
        customClass: 'purchase-overlay cart-delete-overlay v-b2c-overlay',
      });
  };

  const renderEmptyCartView = (props) => {
    if (document.querySelector('.renewpasspayment--page') && checkTenant(UIConfig.iamMapping.yww)) {
      // created a dummy loader to show the loader during the white page
      return (
        <div className="loader-wrapper">
          <div className="loader-elem"></div>
        </div>
      );
    }
    if (
      props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.myCart ||
      props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.cartSummary ||
      (props.yasArenaCart && props.yasArenaCart.isYasArenaView)
    ) {
      if (props.cartEmpty) {
        return <RenderEmptyCart data={props.data} propsData={props} scaleMiniCartContainer={scaleMiniCartContainer} />;
      } else {
        return null;
      }
    } else {
      return (
        <RenderEmptyCart
          data={props.data}
          isCartPaymentView={props.isCartPaymentView}
          propsData={props}
          isSwad={isSwad}
          scaleMiniCartContainer={scaleMiniCartContainer}
        />
      );
    }
  };

  const setCouponServerErrorDisplay = (displayStatus) => {
    setShowErrorWrapper(displayStatus);
  };

  const removeMiniCartContainer = () => {
    if (isParksExpressCheckout && detectMobile()) {
      const exBookingPageContainer = document.querySelector('.page-container');
      const exCartProgressBar = document.querySelector('.c-progress-bar');
      exBookingPageContainer.style.setProperty('--cart-summary-height', '0px');
      exCartProgressBar.style.position = 'absolute';
      return;
    }
    const bookingPageContainer = document.querySelector('.booking--page.minicart-enabled .page-container');
    const cartProgressBar = document.querySelector('.booking--page.minicart-enabled .c-progress-bar');
    bookingPageContainer.style.setProperty('--cart-summary-height', '0px');
    if (cartProgressBar) {
      cartProgressBar.style.position = 'absolute';
    }
  };

  const scaleMiniCartContainer = (isEmpty, forceIsParksExpressCheckout) => {
    if ((isParksExpressCheckout || forceIsParksExpressCheckout) && detectMobile()) {
      const exPageContainer = document.querySelector('.page-container');
      const exCartProgressBar = document.querySelector('.c-progress-bar');
      const exCartSumCont = document.querySelector('.c-b2c-cart-header')?.clientHeight;
      const exCartErrorCont = document.querySelector('.c-b2c-cart-wrapper ul')?.clientHeight;

      if (exCartProgressBar) {
        exCartProgressBar.style.position = 'absolute';
      }
      if (isEmpty) {
        exPageContainer.style.setProperty('--cart-summary-height', '0px');
      } else {
        if (exPageContainer && exCartSumCont) {
          exPageContainer.style.setProperty(
            '--cart-summary-height',
            `${exCartSumCont + Number(exCartErrorCont ?? 0)}px`,
          );
        }
        return;
      }
    }

    if (!isParks || !isMiniCartEnabled) {
      return;
    }
    const bookingPageContainer = document.querySelector('.booking--page.minicart-enabled .page-container');
    const emptyCartContainer = document.querySelector('.booking--page.minicart-enabled .empty-cart-container');
    const cartProgressBar = document.querySelector('.booking--page.minicart-enabled .c-progress-bar');
    const cartSumCont = document.querySelector('.booking--page.minicart-enabled .cart-summary-container')?.clientHeight;

    if (cartProgressBar) {
      cartProgressBar.style.position = 'absolute';
    }
    if (isEmpty) {
      if (emptyCartContainer) {
        bookingPageContainer.style.setProperty('--cart-summary-height', `${emptyCartContainer.clientHeight}px`);
      }
    } else {
      if (bookingPageContainer) {
        if (cartSumCont) {
          bookingPageContainer.style.setProperty('--cart-summary-height', `${cartSumCont}px`);
        } else {
          setTimeout(() => {
            const cartSumCont = document.querySelector('.booking--page.minicart-enabled .cart-summary-container')
              ?.clientHeight;
            bookingPageContainer.style.setProperty('--cart-summary-height', `${cartSumCont}px`);
          }, 0);
        }
      }
    }
  };

  const getProductRowWrapper = (productGroupRow, productRow, props) => {
    if (isSwad) {
      return productGroupRow;
    }
    if (isParks) {
      return (
        <>
          {(!isMiniCartEnabled || !detectMobile()) && (
            <div className="minicart-mobile-total-wrapper">
              <div className="ticket-section" tabIndex="0">
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'cart-total-label heading-4' }}
                  innerHtml={props.data.cartLabels.total}
                />
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'cart-total-price heading-4' }}
                  innerHtml={`<span class="cart-total-price-aed">${props.cartData.cart.currency} ${toTwoDecimalPlaces(
                    props.cartData.cart.grossPrice,
                  )}</span><span class="cart-total-price-other-lang">${getConvertedCurrencyLabel(props)}</span>`}
                />
              </div>
            </div>
          )}
          <div
            className="minicart-scroll-wrapper"
            style={{
              ...(isParks && isMiniCartEnabled && isMobileOrTab() && isBodyHeight && { height: `${isBodyHeight}px` }),
              ...(isParks &&
                isMiniCartEnabled &&
                isMobileOrTab() &&
                isHeaderStickyHeight && { paddingBottom: `${isHeaderStickyHeight}px` }),
            }}
          >
            <>
              {isGlobalCartQuantityError && (
                <div className="ticket-section">
                  <div className="ticket-section--error global-cart-error" ref={globalCartError}>
                    {props.data.businessErrors.INVALIDCARTQTY}
                  </div>
                </div>
              )}
            </>
            {productRow}
          </div>
          {isMiniCartEnabled && props.errObj && (
            <div className="minicart-mobile-error">
              <ErrorSummary data={props.errObj} disableScroll={true} />
            </div>
          )}
        </>
      );
    }

    return productRow;
  };

  /**
   * renderCart function that decides  wether to render empty cart or not
   * @param    {[Object]} props [data for rendering the view].
   * @return   {[Object]} returns html .
   */
  const renderCart = (props) => {
    const cartb2cclasses = classNames(
      'c-b2c-cart-content',
      { 'c-b2c-cart-payment': props.isCartPaymentView },
      { 'cart-item-wrapper': isSwad },
      { 'express-cart-expanded': isCartExpanded },
    );
    const hasYaCart = props.yasArenaCart && props.yasArenaCart.isYasArenaView;
    const miniCartWrapper = document.getElementsByClassName('b2c-mini-cart-wrapper')[0];

    if (isParks && isMiniCartEnabled) {
      document.body.classList.add('minicart-enabled');
    }

    //save same category items in categoryGroup-SWAD(Compare cart eventtype with categoryLabel and create group accordingly)
    const categoriesProduct = (groupedData, categoryGroup, key) => {
      if (props.data.categoryLabel) {
        const eventType = groupedData[key].products[0].eventType ? groupedData[key].products[0].eventType : 'undefined';
        //Check : Category already there or not
        const availableItem = Object.keys(categoryGroup)?.filter((item) => {
          return (
            eventType.toLowerCase().includes(item.toLowerCase()) || item.toLowerCase().includes(eventType.toLowerCase())
          );
        });

        if (availableItem?.length > 0) {
          categoryGroup[availableItem[0]].push(
            !props.isCartPaymentView ? (
              <GroupedProductRow
                objectKey={key}
                key={key}
                bErrObj={props.bErrObj}
                groupedData={groupedData}
                propData={props}
                deleteOverlay={deleteOverlay}
                isRenewAnnualPass={props.isRenewAnnualPass}
              />
            ) : (
              <GroupedProductRow
                objectKey={key}
                key={key}
                bErrObj={props.bErrObj}
                groupedData={groupedData}
                propData={props}
                deleteOverlay={deleteOverlay}
                isCartPaymentView={props.isCartPaymentView}
                isRenewAnnualPass={props.isRenewAnnualPass}
                isYaNonSeatedJourney={props.isYaNonSeatedJourney}
                deleteYAOverlay={deleteYAOverlay}
              />
            ),
          );
        } else {
          categoryGroup[eventType] = [
            !props.isCartPaymentView ? (
              <GroupedProductRow
                objectKey={key}
                key={key}
                bErrObj={props.bErrObj}
                groupedData={groupedData}
                propData={props}
                deleteOverlay={deleteOverlay}
                isRenewAnnualPass={props.isRenewAnnualPass}
              />
            ) : (
              <GroupedProductRow
                objectKey={key}
                key={key}
                bErrObj={props.bErrObj}
                groupedData={groupedData}
                propData={props}
                deleteOverlay={deleteOverlay}
                isCartPaymentView={props.isCartPaymentView}
                isRenewAnnualPass={props.isRenewAnnualPass}
                isYaNonSeatedJourney={props.isYaNonSeatedJourney}
                deleteYAOverlay={deleteYAOverlay}
              />
            ),
          ];
        }
      }
    };

    const ExpressCheckoutAPHeader = (label) => {
      const isMobile = detectMobile();

      if (
        label?.length > 1 &&
        label[0].includes('AnnualPasses') &&
        isSwad &&
        props.data?.additionalLabels?.aPGuestCheckoutDisableMsg
      )
        return (
          <div className="exp-checkout-ap-enabled">
            <DynamicContent tagName="p" attrs={{ className: 'category-heading' }} innerHtml={label[1]} />
            <Tooltip
              onHover={!isMobile}
              onClick={isMobile}
              wrapperClass="exp-checkout-ap-tooltip-container"
              buttonClass=""
              buttonInnerHtml={<span className="info-icon-express"></span>}
              tabIndex={0}
              ariaLabel={''}
            >
              <div className="exp-ap-tooptip-wrapper">
                <div className="exp-ap-tooptip-wrapper--triangle"></div>
                {/* {isMobile && <button></button>} */}
                <span>{props.data?.additionalLabels?.aPGuestCheckoutDisableMsg}</span>
              </div>
            </Tooltip>
          </div>
        );

      return <DynamicContent tagName="p" attrs={{ className: 'category-heading' }} innerHtml={label[1]} />;
    };
    //sort categoryGroup based on categoryLabel object order-SWAD
    const sortProduct = (categoryGroup) => {
      let sortedProduct = [];
      if (props.data.categoryLabel) {
        Object.entries(props.data.categoryLabel).forEach((label) => {
          Object.entries(categoryGroup).forEach((currentLabel, index) => {
            currentLabel[0].toLowerCase().includes(label[0].toLowerCase()) &&
              (sortedProduct = [
                ...sortedProduct,
                <div className="items-group" key={index}>
                  {ExpressCheckoutAPHeader(label)}
                  {currentLabel[1]}
                </div>,
              ]);
          }, label);
        });
      }
      return sortedProduct;
    };

    const cartProducts = getProductByType(props.cartData.cart.items);
    const { packages, items } = cartProducts;
    if (!isCartEmpty(cartProducts)) {
      // Give the DOM time to update for the calculations
      setTimeout(() => {
        detectMobile() && scaleMiniCartContainer();
      }, 300);
      if (isMiniCartEnabled) {
        document.body.classList.add('minicart-expanded');
      }

      let productRow, cartSummary, productGroupRow, categoryGroup;
      if (gridView) {
        cartSummary = createCartGridView(props.cart, props.data.groupingMap, props.data.cartLabels);
      } else if (props.isCartPaymentView || hasYaCart) {
        const groupedData = {};
        productRow = [];
        productGroupRow = [];
        categoryGroup = {};
        const packageList = props.cartData.cart.packages;
        if (packageList || packageList?.length) {
          const pkgGroupList = groupPkgByType(packages);
          const pkgList = pkgGroupList.length ? pkgGroupList : groupPkgList(packageList);

          pkgList.length &&
            pkgList.forEach((packageItem) => {
              productRow.push(
                <PackageProductRow
                  bErrObj={props.bErrObj}
                  groupedData={groupedData}
                  packageItem={packageItem}
                  isCartPaymentView={props.isCartPaymentView}
                  deleteOverlay={deleteOverlay}
                  propData={props}
                />,
              );
            });
        }

        for (const key in items) {
          if (!items.hasOwnProperty(key)) {
            continue;
          }
          groupedData[key] = items[key];

          //categories the product by its type-SWAD
          isSwad && categoriesProduct(groupedData, categoryGroup, key);

          productRow.push(
            <GroupedProductRow
              objectKey={key}
              key={key}
              bErrObj={props.bErrObj}
              groupedData={groupedData}
              propData={props}
              deleteOverlay={deleteOverlay}
              isCartPaymentView={props.isCartPaymentView}
              isRenewAnnualPass={props.isRenewAnnualPass}
              isYaNonSeatedJourney={props.isYaNonSeatedJourney}
              deleteYAOverlay={deleteYAOverlay}
            />,
          );
        }

        //Sort the product based on categoryLabel object from mock data.
        isSwad && (productGroupRow = sortProduct(categoryGroup));

        cartSummary = (
          <CreateCartSummary
            propData={props}
            openOverlay={() => openOverlay(props)}
            isCartPaymentView={props.isCartPaymentView}
            setCouponServerErrorDisplay={(dispStatus) => setCouponServerErrorDisplay(dispStatus)}
            isSwad={isSwad}
            invalidProductQuantity={invalidProductQuantity || isGlobalCartQuantityError}
            isParksExpressCheckoutTenant={isParksExpressCheckoutTenant()}
            isMiniCartEnabled={isMiniCartEnabled}
            isParksExpressCheckout={isParksExpressCheckout}
            isCartExpanded={isCartExpanded}
            toggleMiniCart={() => setMiniCartExpanded(!isMiniCartExpanded)}
            isMiniCartExpanded={isMiniCartExpanded}
          />
        );
      } else {
        const groupedData = {};
        productRow = [];
        productGroupRow = [];
        categoryGroup = {};

        const pkgGroupList = groupPkgByType(packages);

        pkgGroupList.length &&
          pkgGroupList.forEach((packageItem) => {
            productRow.push(
              <PackageProductRow
                bErrObj={props.bErrObj}
                groupedData={groupedData}
                packageItem={packageItem}
                isCartPaymentView={props.isCartPaymentView}
                deleteOverlay={deleteOverlay}
                propData={props}
              />,
            );
          });

        for (const key in items) {
          if (!items.hasOwnProperty(key)) {
            continue;
          }
          groupedData[key] = items[key];
          if (
            props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart &&
            props.data.categoryLabel
          ) {
            const eventType = groupedData[key].products[0].eventType
              ? props.data.categoryLabel[groupedData[key].products[0].eventType.toLowerCase()]
              : props.data.categoryLabel.experience;
            productRow.push(
              <DynamicContent tagName="p" attrs={{ className: 'category-heading' }} innerHtml={eventType} />,
            );
          }

          //categories the product by its type-SWAD
          isSwad && categoriesProduct(groupedData, categoryGroup, key);

          productRow.push(
            <GroupedProductRow
              objectKey={key}
              key={key}
              bErrObj={props.bErrObj}
              groupedData={groupedData}
              propData={props}
              deleteOverlay={deleteOverlay}
              isRenewAnnualPass={props.isRenewAnnualPass}
            />,
          );
        }

        //Sort the product based on categoryLabel object from mock data.
        isSwad && (productGroupRow = sortProduct(categoryGroup));

        cartSummary = (
          <CreateCartSummary
            propData={props}
            openOverlay={() => openOverlay(props)}
            isCartPaymentView={false}
            setCouponServerErrorDisplay={(dispStatus) => setCouponServerErrorDisplay(dispStatus)}
            isSwad={isSwad}
            invalidProductQuantity={invalidProductQuantity || isGlobalCartQuantityError}
            isParks={isParks}
            isMiniCartEnabled={isMiniCartEnabled}
            isParksExpressCheckout={isParksExpressCheckout}
            isCartExpanded={isCartExpanded}
            toggleMiniCart={() => setMiniCartExpanded(!isMiniCartExpanded)}
            isMiniCartExpanded={isMiniCartExpanded}
          />
        );
      }

      const isExpandFromParams =
        window.location.hash.indexOf('#expanded') === 0 || window.location.hash.indexOf('#expandedwithpromo') === 0;

      return (
        <>
          <div
            className={cartb2cclasses}
            aria-expanded={true}
            aria-labelledby={'cart-title'}
            style={
              isSwad && windowHeight && cartItemWrapper ? { maxHeight: `${windowHeight - cartItemWrapper}px` } : {}
            }
          >
            {isGlobalCartQuantityError && isSwad && (
              <div className="ticket-section">
                <div className="ticket-section--error global-cart-error" ref={globalCartError}>
                  {props.data.businessErrors.INVALIDCARTQTY}
                </div>
              </div>
            )}
            {getProductRowWrapper(productGroupRow, productRow, props)}
            {isYasArenaJourney() &&
              !props.isYaNonSeatedJourney &&
              props.data.cartLabels.feeLabel &&
              props.cartData.cart.totalFee > 0 &&
              miniCartWrapper && (
                <div className="convienence-fee">
                  <DynamicContent
                    tagName="p"
                    attrs={{ className: 'convienence-fee-label body-2' }}
                    innerHtml={props.data.cartLabels.feeLabel}
                  />
                  <DynamicContent
                    tagName="p"
                    attrs={{ className: 'body-1 convienence-fee-price' }}
                    innerHtml={`${props.cartData.cart.currency} ${toTwoDecimalPlaces(props.cartData.cart.totalFee)}`}
                  />
                </div>
              )}
            {isSwad && props.isCartPaymentView && !detectMobile() && !isCartExpanded ? '' : cartSummary}
            {isSwad && !props.showMinicartCouponCode && detectMobile() && renderCouponSection()}
          </div>
          {isSwad && props.isCartPaymentView && !detectMobile() && !isCartExpanded && cartSummary}
          {isParksExpressCheckout &&
            props.isCartPaymentView &&
            !detectMobile() &&
            !isCartExpanded &&
            ((isExpandFromParams && !isCartExpanded) || !isExpandFromParams) &&
            cartSummary}
        </>
      );
    } else {
      canUseDOM() && document.querySelector('body').classList.remove('minicart-no-scroll');
      const isBookingPage = document.querySelector('.booking--page');
      if (
        isParks &&
        detectMobile() &&
        isCartEmpty(cartProducts) &&
        !showEmptyMiniCart &&
        (isBookingPage || isParksExpressCheckout)
      ) {
        const bookingPageContainer = document.querySelector('.booking--page .page-container');
        const miniCartExpanded = document.querySelector('.minicart-expanded');
        if (miniCartExpanded) {
          document.body.classList.remove('minicart-expanded');
        }
        if (isParksExpressCheckout && detectMobile()) {
          detectMobile() && scaleMiniCartContainer(true, true);
          return renderEmptyCartView(props);
        }
        removeMiniCartContainer();
        return;
      }
      return !props.isRenewAnnualPass && renderEmptyCartView(props);
    }
  };

  const isCartEmpty = (cartProducts) => {
    if (
      props.cartData?.cart?.packages?.length ||
      Object.keys(cartProducts.packages).length ||
      Object.keys(cartProducts.items).length
    ) {
      return false;
    }
    return true;
  };

  const getProductByType = (items) => {
    const allProds = {
      packages: {},
      items: {},
    };
    Object.keys(items)?.length &&
      Object.keys(items).forEach((key) => {
        if (items[key].isPackageProd) {
          allProds.packages[key] = items[key];
        } else {
          allProds.items[key] = items[key];
        }
      });
    return allProds;
  };

  const groupPkgList = (packages) => {
    const packagePrd = {};
    packages?.length &&
      packages.forEach((pkg) => {
        if (!packagePrd[pkg.items[0].packageGroupCode]) {
          const prods = pkg.items.map((prd) => ({ ...prd, packageQty: 1 }));
          packagePrd[pkg.items[0].packageGroupCode] = { ...pkg, items: prods };
        } else {
          const prods = packagePrd[pkg.items[0].packageGroupCode].items.map((prd) => ({
            ...prd,
            packageQty: prd.packageQty + 1,
          }));
          packagePrd[pkg.items[0].packageGroupCode] = { ...pkg, items: prods };
        }
      });
    const prodArr = [];
    Object.keys(packagePrd).forEach((key) => {
      prodArr.push(packagePrd[key]);
    });
    return prodArr;
  };

  const groupPkgByType = (packages) => {
    const packagePrd = {};
    Object.keys(packages).forEach((key) => {
      const pkgGroupCode = packages[key].packageGroupCode;
      if (!packagePrd[pkgGroupCode]) {
        packagePrd[pkgGroupCode] = [];
      }
      packagePrd[pkgGroupCode].push(packages[key]);
      return packagePrd;
    }, {});

    const prodArr = [];
    Object.keys(packagePrd).forEach((key) => {
      prodArr.push({
        packageCode: key.split('-')[0],
        items: packagePrd[key],
      });
    });
    return prodArr;
  };

  const createCartGridView = (cart, groupingMap, cartLabels) => {
    const groupedItems = groupBy(cart.items, groupingMap.key, true),
      cartSummary = [];
    for (let key in groupedItems) {
      if (groupedItems.hasOwnProperty(key)) {
        cartSummary.push(
          <CartSummary
            key={key}
            products={groupedItems[key]}
            heading={groupingMap.displayValueMap[key] || key}
            currency={cart.currency}
            cartLabels={cartLabels}
            isGeneral={isGeneral}
            isAnnualPass={isAnnualPass}
          />,
        );
      }
    }
    return cartSummary;
  };

  const shouldMiniCartVisible = () => {
    if (
      canUseDOM() &&
      (props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.miniCart ||
        props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart ||
        props.yasArenaCart.isYasArenaView) &&
      Object.keys(props.cartData.cart.items).length === 0
    ) {
      if (isSwad) {
        return ' hide-cart';
      } else if (document.getElementById('cartCheckbox')) {
        document.getElementById('cartCheckbox').checked = false;
        return ' hide-cart';
      }
    }
    return '';
  };

  const toggleCartPaymentView = () => {
    if (cartCheckBoxel) {
      cartCheckBoxel.checked = !cartCheckBoxel.checked;
      isParks && setIsCartExpanded(!isCartExpanded);
      chevronEl.classList.toggle('open');
      chevronElDiv.setAttribute('aria-expanded', chevronEl.classList.contains('open'));
    }
  };

  if (canUseDOM() && props.isCartPaymentView) {
    window.PubSub.subscribe(UIConfig.events.ONPROMOCODEEXPANDED, (msg, data) => {
      if (!isParksExpressCheckout && cartCheckBoxel && !cartCheckBoxel.checked) {
        cartCheckBoxel.checked = true;
        chevronEl.classList.toggle('open');
      }
    });

    window.PubSub.subscribe(UIConfig.events.MAX_QUANTITY_GEN_ADM, (msg, data) => {
      const getMemberOverLay =
        findMembership && findMembership.messageOverlay && findMembership.messageOverlay.length > 0
          ? findMembership.messageOverlay.find((item) =>
              data.quantityExceededError
                ? item.overlayKey.toLowerCase() ===
                  UIConfig.localStoreKeys.purchaseJourney.limitExceededOverlay.toLowerCase()
                : item.overlayKey.toLowerCase() ===
                  UIConfig.localStoreKeys.purchaseJourney.futureDateOverlay.toLowerCase(),
            )
          : '';
      const ispartnerSegmentPromo = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo);
      if (isLoggedInUser()) {
        if (data.quantityExceededError && getMemberOverLay) {
          setQuotaExceeded(true);
          window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
            shouldOpen: true,
            dataToAppend: LimitExceedOverlay(getMemberOverLay),
            customClass: 'purchase-overlay cart-delete-overlay v-b2c-overlay limit-exceed-error error',
            restrictCloseOnEsc: true,
          });
        } else if (
          !data.quantityExceededError &&
          data.shouldOpenFutureDateOverlay &&
          getMemberOverLay &&
          ispartnerSegmentPromo
        ) {
          window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
            shouldOpen: true,
            dataToAppend: FutureDateOverlay(getMemberOverLay, props.cartData.cart),
            customClass: 'purchase-overlay cart-delete-overlay v-b2c-overlay limit-exceed-error future-date',
            restrictCloseOnEsc: true,
          });
        }
      }
    });
  }

  if (props.showExpandedCart && !getLocalStorageByKey('isCartSummaryExpanded')) {
    const timeoutId = setTimeout(() => {
      toggleCartPaymentView();
      expandcartPaymentView(true);
      setLocalStorage('isCartSummaryExpanded', true);
      clearTimeout(timeoutId);
    }, 0);
  }

  const expandcartPaymentView = (isExpanded, chevronClicked = false) => {
    window.PubSub.publish(UIConfig.events.ONCARTPAYMENTVIEWEXPANDED, {
      expanded: isExpanded,
      chevronClicked,
    });
  };

  const GTMViewCart = () => {
    GTMData.push('viewCart', props.cartData.cart);
  };

  const chevronOnClick = () => {
    chevronEl.classList.toggle('open');
    if (props.isCartPaymentView) {
      expandcartPaymentView(chevronEl.classList.contains('open'), true);
    }
    chevronElDiv.setAttribute('aria-expanded', chevronEl.classList.contains('open'));
    if (!isSwad && isGA4Tenants && chevronEl.classList.contains('open')) {
      GTMViewCart();
    } else if (isSwad && !chevronEl.classList.contains('open')) {
      GTMViewCart();
    }
  };

  const renderChevron = () => {
    if (gridView || props.isCartPaymentView)
      return (
        <label className="grid-check-icon">
          <div
            ref={(el) => (chevronElDiv = el)}
            className="chevronDown"
            tabIndex="0"
            aria-label={`${props.data.cartTitle}`}
            aria-expanded={false}
            role="button"
            onClick={(e) => {
              if (!isCartExpanded) {
                if (isMatchTenant(UIConfig.tenants.ya)) {
                  GTMData.push(UIConfig.ga4Constants.VIEW_CART, { ...props });
                }
              }
              isSwad && document.getElementsByTagName('body')[0].classList.toggle('expanded-mycart');
              isSwad && detectMobile() && cartWrapperScrollHeight();
              cartCheckBoxel.checked = !cartCheckBoxel.checked;
              setIsCartExpanded(!isCartExpanded);
              chevronOnClick();
              e.target.classList.remove('focus');
              isSwad &&
                (!detectMobile()
                  ? props.stickyCart()
                  : document.getElementsByTagName('body')[0].classList.toggle('minicart-no-scroll'));
            }}
            onKeyDown={(e) => {
              e.which === KeyCodes.Enter && e.target.click();
            }}
            onBlur={(e) => e.target.classList.remove('focus')}
          >
            <span id="grid-check-icon-cart-summary" ref={(el) => (chevronEl = el)} />
          </div>
        </label>
      );

    return null;
  };

  //TODO: reamining work for desktop - SWAD
  //min height for cart-wrapper to fit in screen viewheight for mobile
  const cartWrapperScrollHeight = (noreRender = false) => {
    const header = document.querySelector('.c-header');
    const cart = document.querySelector('.c-b2c-cart');
    const cartHeader = document.querySelector('.c-b2c-cart-header');
    const cartError = document.querySelector('.cart-server-error');
    const cartCheckoutButton = document.querySelector('.c-my-cart-b2c-container .checkout-button');
    const cartWrapper = document.querySelector('.cart-item-wrapper');
    const cartPromoCode = document.querySelector('.c-coupon-code');

    if (!detectMobile()) {
      let cartDesktopWrapperHeight =
        (cartHeader && cartHeader.offsetHeight) +
        (cart && cart.getBoundingClientRect().top) +
        (cartCheckoutButton && !props.isCartPaymentView && cartCheckoutButton.offsetHeight) +
        (cartError && cartError.offsetHeight) +
        (cartPromoCode && props.isCartPaymentView && cartPromoCode.offsetHeight);
      noreRender
        ? cartWrapper && (cartWrapper.style.maxHeight = `${windowHeight - cartDesktopWrapperHeight}px`)
        : setCartItemWrapper(cartDesktopWrapperHeight);
    } else {
      let cartWrapperHeight =
        (isHeaderMiniCart && header ? header.offsetHeight - 2 : 0) +
        (cartHeader && cartHeader.offsetHeight) +
        (cartCheckoutButton && !props.isCartPaymentView && cartCheckoutButton.offsetHeight) +
        (cartError && cartError.offsetHeight);
      if (cartWrapper) {
        cartWrapper.style.maxHeight = `${window.innerHeight - cartWrapperHeight}px`;
      }
    }
  };

  const showToastMessage = () => {
    if (toastMsgRef && toastMsgRef.current) {
      if (toastTimeout) {
        clearTimeout(toastTimeout);
        toastTimeout = null;
      }
      !detectMobile() && toastMsgRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setShowToastMsg(true);
      toastTimeout = setTimeout(
        () => {
          setShowToastMsg(false);
        },
        Number(showToastTime) ? Number(showToastTime) : 2000,
      );
    }
  };

  const getMessages = (data = {}) => {
    let msg = [];
    if (!data) return msg;
    Object.keys(data).forEach(function(key) {
      msg.push(data[key]);
    });
    return msg;
  };
  const renderErrorMessages = () => {
    let errors = getMessages(props.errObj);
    if (errors.length) {
      if (errors[0].text) {
        return errors.map((items, i) => (
          <li key={i}>
            {items.errorFrom !== 'cart' ? (
              <DynamicContent
                tagName="div"
                attrs={{
                  className: 'heading-error',
                }}
                innerHtml={items.text}
              />
            ) : (
              <div className="heading-error" role="alert" aria-live="assertive">
                {items.text}
              </div>
            )}
          </li>
        ));
      }
    }
  };

  /**
   *  to check Ticket Has TimeSlot or not
   */
  const checkTicketHaveTimeSlot = (props) => {
    if (props.cartData?.cart?.items) {
      let allTicketsHaveSlots = true;
      Object.keys(props.cartData.cart.items).map((item) => {
        let tickets = props.cartData.cart.items[item];
        tickets.products.map((ticket) => {
          let annualPassValue = ticket?.eventType?.toLowerCase()?.includes('annualpasses');
          if (!ticket.timeSlot) {
            if (!annualPassValue) {
              allTicketsHaveSlots = false;
            }
          }
        });
      });
      return !allTicketsHaveSlots;
    }
  };
  /**
   *  Render Coupon Code Section when CartType is cartpaymentview
   */
  const renderCouponSection = () => {
    if (
      canUseDOM() &&
      props.data.showCouponSection &&
      props.isCartPaymentView &&
      !props.isRenewAnnualPass &&
      promoCodeShow
    )
      return (
        <>
          <CouponCode
            data={getCouponCodeData(props)}
            isParksExpressCheckout={isParksExpressCheckout}
            isCartPaymentView={props.isCartPaymentView}
            businessErrors={props.data.businessErrors}
            cartData={props.cartData}
            cartProps={props}
            cartSummaryExpandedView={props.cartSummaryExpandedView}
            addCallback={props.couponCode.applyCouponCallback}
            removeCallback={props.couponCode.removeCouponCallback}
            disableOnSeatedPaymentError={props && props.disableOnSeatedPaymentError}
            setCouponServerErrorDisplay={(dispStatus) => setCouponServerErrorDisplay(dispStatus)}
            focusOnCouponCode={props.focusPromoWithExpandedCart}
            stickyCart={props.stickyCart}
            isCartExpanded={isCartExpanded}
            setstickyCartHeight={cartWrapperScrollHeight}
          />
          {canUseDOM() &&
            !isMiniCart &&
            isLoggedInUser() &&
            findMembership &&
            (isParksExpressCheckout || isSwad) &&
            Object.keys(findMembership).length > 0 && (
              <div className="my-cart-member-container">
                <div className="member-title member-border">{findMembership.paymentDicountTitle}</div>
              </div>
            )}
        </>
      );
    return null;
  };

  const displayGoBackButton = (buttonData) => {
    return <DynamicContent tagName="p" attrs={{ className: 'back-button' }} innerHtml={buttonData} />;
  };

  const getAriaLabelCart = () => {
    if (props.data.ariaLabelCart) {
      return `${props.data.ariaLabelCart} ${props.data.cartTitle}`;
    }
    return `${props.ariaLabelCart} ${props.data.cartTitle}`;
  };

  const cartWrapperClasses = classNames(`c-b2c-cart-wrapper ${shouldMiniCartVisible()}`, {
    'renewal-journey': props.isRenewAnnualPass,
    'go-back-button-margin': props.data.shortDescription,
    'cart-grid-view': gridView || props.isCartPaymentView,
    'cart-payment-empty':
      props.isCartPaymentView &&
      !Object.entries(props?.cartData?.cart?.items)?.length &&
      !props.cartData.cart.packages?.length,
    'cart-couponcodeminicart':
      isSwad && props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart,
  });

  const headerClasses = classNames('c-b2c-cart component w--content', {
    'c-b2c-cart-header-cart-summnary': gridView || props.isCartPaymentView,
    'c-b2c-cart-header-cart-payment': props.isCartPaymentView,
  });

  const emptyCartClasses = classNames({
    'c-b2c-cart-header': true,
    'empty-cart':
      props?.cartData?.cart && !Object.keys(props.cartData.cart.items)?.length && !props.cartData.cart.packages?.length,
  });

  const cartCheckboxClasses = classNames({
    'grid-view-checkbox sr-only': gridView || props.isCartPaymentView,
    'c-mini-cart-checkbox': !(gridView || props.isCartPaymentView),
  });

  let showErr = true;
  if (props.errObj) {
    Object.keys(props.errObj).forEach((itemKey) => {
      if (props.errObj[itemKey] && props.errObj[itemKey].code && props.errObj[itemKey].code === '4002') {
        showErr = false;
      }
    });
  }
  const cartProducts = getProductByType(props.cartData.cart.items);

  const getProcessedCartTitle = () => {
    return detectMobile() &&
      (checkTenant(UIConfig.iamMapping.yww) ||
        checkTenant(UIConfig.iamMapping.ppad) ||
        checkTenant(UIConfig.iamMapping.wbw))
      ? `${props.data.cartTitle} (${cartCount})`
      : props.data.cartTitle;
  };
  return (
    <div className={headerClasses} ref={cartRef}>
      {props.showToastNotification &&
        (props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.miniCart ||
          props.data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart) && (
          <div
            className={classNames('cart-toast', { show: showToastMsg })}
            aria-hidden={showToastMsg ? false : true}
            ref={toastMsgRef}
          >
            <DynamicContent
              tagName="p"
              attrs={{ className: 'cart-toast__msg' }}
              innerHtml={props.data.businessErrors.ADDTOCARTTOASTMESSAGE}
            />
            <div className="cart-toast__close-btn" onClick={() => setShowToastMsg(false)}></div>
          </div>
        )}
      {props.data.shortDescription && props.isRenewAnnualPass && !isMiniHeader
        ? displayGoBackButton(props.data.shortDescription)
        : ''}
      <div className={cartWrapperClasses}>
        <input
          type="checkbox"
          ref={(el) => (cartCheckBoxel = el)}
          aria-hidden="true"
          name="checkbox"
          id="cartCheckbox"
          className={cartCheckboxClasses}
          tabIndex={gridView || props.isCartPaymentView ? '-1' : '0'}
          {...(isParks && detectMobile() && { checked: props.isHeader })}
        />
        <input
          type="checkbox"
          name="checkbox"
          id="cartHideShowCheckbox"
          aria-hidden="true"
          title="cartHideShowCheckbox"
          className="mini-cart-checkbox sr-only"
          onChange={() => !isHeaderMiniCart && !detectMobile() && props.stickyCart()}
          tabIndex="-1"
        />
        <div className={emptyCartClasses}>
          <DynamicContent
            tagName="p"
            attrs={{ className: 'heading-4 cart-title', id: 'cart-title' }}
            innerHtml={getProcessedCartTitle()}
          />
          {props.data.cartType.indexOf(UIConfig.b2c.purchaseJourney.cartVariant.cartSummary) === -1 &&
          !props.isCartPaymentView ? (
            <>
              {isSwad && (
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'cart-total-header' }}
                  innerHtml={`${props.cartData.cart.currency} ${props.cartData.cart.grossPrice.toLocaleString('en', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`}
                />
              )}
              <label htmlFor="cartHideShowCheckbox" className="mini-cart-expand-collapse">
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'short-description' }}
                  innerHtml={props.data.shortDescription}
                />
                <span
                  id="mini-cart-summary"
                  className="ticket-section-content"
                  tabIndex="0"
                  aria-label={getAriaLabelCart()}
                  ref={miniCartChevron}
                  onClick={() => {
                    const elmn = document.getElementById('mini-cart-summary');
                    elmn.classList.toggle('open');
                    miniCartChevron.current && miniCartChevron.current.classList.remove('focus');
                    if (isSwad && detectMobile()) {
                      document.querySelector('body').classList.toggle('minicart-no-scroll');
                      cartWrapperScrollHeight();
                    }
                    if (!isSwad && isGA4Tenants && elmn.classList.contains('open')) {
                      GTMData.push('viewCart', props.cartData.cart);
                    } else if (isSwad && !elmn.classList.contains('open')) {
                      GTMViewCart();
                    }
                  }}
                  onKeyDown={(e) => {
                    e.which === KeyCodes.Enter && e.target.click();
                  }}
                  onBlur={(e) => e.target.classList.remove('focus')}
                />
              </label>
            </>
          ) : (
            <div className="c-b2c-cart-header-container">
              {!((isParksExpressCheckout && !detectMobile()) || isCartEmpty(cartProducts)) && (
                <p className="cart-summary-sub-heading">
                  <DynamicContent
                    tagName="span"
                    attrs={{ className: 'total' }}
                    innerHtml={props.data.cartLabels.total}
                  />
                  <span className="total-title">
                    <span>{isSwad ? `${props.cartData.cart.currency} ` : props.cartData.cart.currency}</span>
                    <span>
                      {isSwad
                        ? props.cartData.cart.grossPrice.toLocaleString('en', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : toTwoDecimalPlaces(props.cartData.cart.grossPrice)}
                    </span>
                  </span>
                </p>
              )}
              {gridView && (
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'vat-info body-3' }}
                  innerHtml={props.data.cartLabels.vatInfo}
                />
              )}
              {!isSwad && !isParksExpressCheckout && !props.showMinicartCouponCode && renderCouponSection()}
              {canUseDOM() &&
                !isMiniCart &&
                isLoggedInUser() &&
                findMembership &&
                !isParksExpressCheckout &&
                !isSwad &&
                Object.keys(findMembership).length > 0 && (
                  <div className="my-cart-member-container">
                    <div className="member-title member-border">{findMembership.paymentDicountTitle}</div>
                  </div>
                )}
              {!isCartEmpty(cartProducts) && renderChevron()}
              {props.cartSummaryExpandedView && (
                <DynamicContent
                  tagName="p"
                  attrs={{ className: 'short-description' }}
                  innerHtml={props.data.shortDescription}
                />
              )}
            </div>
          )}
        </div>
        {isParksExpressCheckout && detectMobile() && !isCartExpanded && <ul>{renderErrorMessages()}</ul>}
        {isSwad && detectMobile() && (
          <CheckoutButtons
            propData={props}
            showCrossSell={checkTicketHaveTimeSlot(props)}
            invalidProductQuantity={invalidProductQuantity || isGlobalCartQuantityError}
            cartSummaryExpandedView={props.cartSummaryExpandedView}
          />
        )}
        {isSwad && showErr && showErrorWrapper && props.errObj ? (
          <>
            {document.querySelector('body').classList.add('cart-error')}
            <div className={`cart-server-error`}>
              <span
                className="close-button"
                onClick={() => {
                  document.querySelector('.cart-server-error').classList.toggle('closed');
                  document.querySelector('body').classList.remove('cart-error');
                  cartWrapperScrollHeight();
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    document.querySelector('.cart-server-error').classList.toggle('closed');
                    document.querySelector('body').classList.remove('cart-error');
                    cartWrapperScrollHeight();
                  }
                }}
                tabIndex={0}
              ></span>
              <ErrorSummary data={props.errObj} disableScroll={true} />
            </div>
          </>
        ) : (
          !isQuotaExceeded &&
          !(isParksExpressCheckout && detectMobile() && !isCartExpanded) &&
          (!isParks || !isMiniCartEnabled || props.cartSummaryExpandedView) && (
            <ErrorSummary data={props.errObj} disableScroll={true} />
          )
        )}
        {renderCart(props)}
        {isSwad && !detectMobile() && (
          <CheckoutButtons
            propData={props}
            showCrossSell={checkTicketHaveTimeSlot(props)}
            cartSummaryExpandedView={props.cartSummaryExpandedView}
            invalidProductQuantity={invalidProductQuantity || isGlobalCartQuantityError}
            isSwad={isSwad}
          />
        )}
        {isSwad && !props.showMinicartCouponCode && !detectMobile() && renderCouponSection()}
        {isParksExpressCheckout &&
          !isCartEmpty(cartProducts) &&
          !props.showMinicartCouponCode &&
          (!detectMobile() || (detectMobile() && isCartExpanded)) &&
          renderCouponSection()}
      </div>
    </div>
  );
};

Cart.propTypes = {
  props: PropTypes.shape({
    data: PropTypes.object,
    cartData: PropTypes.object,
    updateQuantity: PropTypes.func,
    deleteProduct: PropTypes.func,
    stickyCart: PropTypes.func,
  }),
};

Cart.defaultProps = {
  basketAriaLabel: `Basket item`,
  ariaLabelCart: 'Press enter to view',
};

export default Cart;
