/*
 * info-box-component.js
 * This file contains code for info-box component which is renders only server side
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import { AnchorLink, DynamicContent, Image, ImageWithRatio, SvgSprite } from '../../presentation/base';
import { getMainObject, applyGradientValue, canUseDOM } from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';
import { resolvePath } from '../../../common/utility';
import UIConfig from '../../../common/UIConfig';
import { bodyClicks } from '../../../common/analytics-events';

/**
 * Infobox is a component which render the provided html from backend
 * @param   {[Object]}  wrapper html element for the provided html.
 * @return  {[Object]} Return the compiled info-box Html.
 */

const InfoBox = ({ data }) => {
  if (!data) {
    return null;
  }
  const bindInfoHeaders = () => {
    const { bodyCopy, headerImage, shortTitle, lazyloadWithBackground } = data;

    return (
      <div>
        <div className="w--content c-info-box--header">
          {shortTitle && <DynamicContent tagName="p" innerHtml={shortTitle} attrs={{ className: 'heading-7' }} />}
          {bodyCopy && <DynamicContent tagName="div" innerHtml={bodyCopy} attrs={{ className: 'heading-1' }} />}
        </div>
        {headerImage &&
          (!lazyloadWithBackground ? (
            <div className="c-info-box--header--img--wrapper">
              <div className="c-info-box--header--img">
                <Image image={headerImage} />
              </div>
            </div>
          ) : (
            <div className="c-info-box--header--img--wrapper">
              <div className="c-info-box--header--img">
                <ImageWithRatio image={headerImage} />
              </div>
            </div>
          ))}
      </div>
    );
  };

  const renderTile = () => {
    const { tile, lazyloadWithBackground } = data;
    if (!tile) {
      return null;
    }
    const { shortTile, tileLogo, title } = tile;

    return (
      <div className="w--content c-info-box--wrapper">
        <div className="c-info-box--information">
          {tileLogo &&
            (!lazyloadWithBackground ? (
              <div className="c-info-box--information--logo">
                <Image image={tileLogo} />
              </div>
            ) : (
              <div className="c-info-box--information--logo">
                <ImageWithRatio image={tileLogo} />
              </div>
            ))}

          {shortTile && <DynamicContent tagName="p" innerHtml={shortTile} attrs={{ className: 'heading-7' }} />}

          {title && <DynamicContent tagName="h3" innerHtml={title} attrs={{ className: 'heading-2' }} />}
          {renderContent()}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const { tile: { cta, firstColumn, footerNote, secondColumn } = {}, variant } = data;

    const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;

    const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
      if (checkfortenants) {
        bodyClicks(eventName, titleData, labelData);
      }
    };
    return (
      <div className="c-info-box-content body-copy-5">
        {firstColumn && firstColumn.bodyCopy && variant.indexOf('v-infobox-view2') < 0 && (
          <div className="c-info-box-content--col-l">
            <DynamicContent tagName="div" innerHtml={firstColumn.bodyCopy} />
          </div>
        )}

        {secondColumn && secondColumn.bodyCopy && (
          <div className="c-info-box-content--col-r">
            <DynamicContent tagName="div" innerHtml={secondColumn.bodyCopy} />
            {cta && (
              <div className="button-wrapper btn-primary">
                <AnchorLink
                  link={cta}
                  onClick={() => {
                    googleAnalyticHandlerbody(
                      UIConfig.commonVariant.gaClickEvents.buttonClick,
                      data.tile.firstColumn.bodyCopy,
                      cta.label,
                    );
                  }}
                />
              </div>
            )}
          </div>
        )}

        {footerNote && footerNote.bodyCopy && variant.indexOf('v-infobox-view2') < 0 && (
          <div className="body-copy-6">
            <DynamicContent tagName="div" innerHtml={footerNote.bodyCopy} />
          </div>
        )}
      </div>
    );
  };

  const renderImage = () => {
    const { image, lazyloadWithBackground } = data;

    return (
      image &&
      (!lazyloadWithBackground ? (
        <div className="c-info-box--body--img">
          <Image image={image} />
        </div>
      ) : (
        <div className="c-info-box--body--img">
          <ImageWithRatio image={image} />
        </div>
      ))
    );
  };

  const bindInfoBox = () => {
    const { variant, addContrast } = data;
    const mainObj = getMainObject();
    const defaultImageContrast = mainObj && mainObj.applyTextShadow;
    const contrastValue = applyGradientValue(addContrast, defaultImageContrast);

    return (
      <div className="c-info-box--body">
        {contrastValue && (
          <DynamicContent
            tagName="style"
            innerHtml={`
              .c-info-box--body--img picture:after {
                background: rgb(0,0,0, ${contrastValue});
              }
              .c-info-box--body .c-info-box-content .c-info-box-content--col-l div:first-child {
                text-shadow: 0 2px 4px rgba(0,0,0,.4);
              }
              .c-info-box--body .c-info-box-content .c-info-box-content--col-r div:first-child {
                text-shadow: 0 2px 4px rgba(0,0,0,.4);
              }
            `}
          />
        )}
        {variant.match(/active-curve/g) && (
          <div className="c-info-box--svg">
            <SvgSprite id="icn-infobox-smile" styleClass="icn-infobox-smile" />
          </div>
        )}
        {renderImage()}
        {renderTile()}
      </div>
    );
  };

  try {
    return (
      <>
        <div
          data-c-render="server-only"
          className={`c-info-box component ${data.variant ? data.variant : ''} ${
            data.textAlignment ? data.textAlignment : ''
          }`}
        >
          {bindInfoHeaders()}
          {bindInfoBox()}
        </div>
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'InfoBox', data.variant);
  }
};

/**
 * Used to define the proptypes that will be received by the component.
 */
InfoBox.propTypes = {
  data: PropTypes.shape({
    bodyCopy: PropTypes.string,
    headerImage: PropTypes.object,
    image: PropTypes.shape({
      desktopImage: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    shortTitle: PropTypes.string,
    tile: PropTypes.shape({
      cta: PropTypes.object,
      firstColumn: PropTypes.object,
      footerNote: PropTypes.object,
      secondColumn: PropTypes.object,
      shortTile: PropTypes.string,
      tileLogo: PropTypes.object,
      title: PropTypes.string,
    }),
    variant: PropTypes.string,
  }),
};

export default InfoBox;
