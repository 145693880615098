class ProductStore {
  constructor(products) {
    this.products = products || [];
  }

  setProducts = (products) => {
    this.products = products;
  };

  getAllProducts = () => {
    return this.products;
  };

  /**
   * To get the filtered data
   * for e.g if productStore is instance of this class
   * productStore.getFilteredProducts({
   *      "fcategorykey22302":"1-Day-1-Park",
   *      "fpricingvalues22302": "STAN",
   * }));
   */
  getFilteredProductsFromKeyVal = (params) => {
    return this.products.filter((prod) => {
      let val = true;
      for (const key in params) {
        if (!prod.hasOwnProperty(key) || prod[key].indexOf(params[key]) === -1) {
          val = false;
          break;
        }
      }
      return val;
    });
  };

  /**
   * To get the filtered data with coveo like params
   * for e.g if productStore is instance of this class
   * productStore.getFilteredProducts([
   *  {
   *      key: "xyz",
   *      value: "xyx" | ["xyz","ABC"],
   *      <logic: "AND"|"OR">
   *  }
   * ]);
   */
  getFilteredProductsFromArray = (params) => {
    return this.products.filter((prod) => {
      let val = true;
      for (let index = 0; index < params.length; index++) {
        const v = params[index];
        if (!prod.hasOwnProperty(v.key)) {
          val = false;
        } else if (v.value instanceof Array) {
          /**
           * since every array param is having the feature of "AND" / "OR" logic,
           * below logic will make the query based on "AND / "OR" operation
           */
          val =
            v.logic === 'AND'
              ? prod[v.key].sort().join('') === v.value.sort().join('')
              : v.value.indexOf(prod[v.key]) !== -1;
        } else {
          val = prod[v.key].indexOf(v.value) !== -1;
        }
        if (!val) {
          break;
        }
      }
      return val;
    });
  };
}

export default ProductStore;
