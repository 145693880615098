import React, { forwardRef, useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classNames from 'classnames';

import { DynamicContent, Note } from '../../presentation/base';
import {
  momentTimezone,
  currentLocale,
  createDeepCopy,
  decodeHtmlEntity,
  detectViewPort,
  isEmpty,
  parseQueryString,
  getClientUtcOffset,
  canUseDOM,
  checkTenant,
  getMainObject,
  isMatchTenant,
  getCurrentLanguage,
} from '../../../common/utility';

import UIConfig from '../../../common/UIConfig';
import { logComponentRenderingError } from '../../../common/logger';

import 'react-datepicker/dist/react-datepicker.min.css';

const HotelBookingWidgetDatepicker = forwardRef((props, ref) => {
  try {
    const [selectedDateItem, setSelectedDateItem] = useState(new Date());

    const [domReady, setDomReady] = useState(false);
    const [interfaceHeight, setInterfaceHeight] = useState(undefined);
    const [outSideCalenderKgfDate, setOutSideCalenderKgfDate] = useState('');
    useEffect(() => {
      if (canUseDOM()) {
        setDomReady(true);
        setInterfaceHeight(window.outerHeight - window.innerHeight);
      }
    }, []);

    const checkIfDateValid = (date) => {
      if (checkTenant(UIConfig.YIB2C)) {
        if (!date) {
          return '';
        }
        if (!moment(date, UIConfig.dateFormats.DD_MM_YYYY).isValid()) {
          return '';
        }
      }
      return date;
    };

    useEffect(() => {
      isKgfCartJourney();
    }, [props]);

    const { data, holidayExperience } = props;
    const { events } = data;
    const viewport = detectViewPort();
    const inline = [UIConfig.viewportTypes.mobile, UIConfig.viewportTypes.tablet].includes(viewport);

    const activeLocale = currentLocale();
    const monthsTranslations = data.calendarSettings.monthsTranslations || null;
    const weekTranslations = data.calendarSettings.weekTranslations || null;
    const actualStartDate = createDeepCopy(props.actualStartDate);
    const vBookingWidgetOnlyStartDate = props.data.variant === UIConfig.commonVariant.bookingWidgetOnlyStartDate;
    const weekDaysNames = Array.from(weekTranslations.shortName);
    const queryStringFromDate = checkIfDateValid(parseQueryString('from')) || '';
    const isYasIsland = props?.data?.brand === UIConfig.YAS;
    const isSwad = props?.data?.brand === UIConfig.SWADB2C;
    const isYMC = checkTenant(UIConfig.iamMapping.ymc);
    let sCategory = sessionStorage.getItem('selectedCategory');
    let isInline;
    if (sCategory !== holidayExperience) {
      isInline = '';
      setTimeout(() => {
        isInline = inline;
      }, 1000);
    }
    const kgfOfferData = isMatchTenant(UIConfig?.tenants?.yi) && getMainObject()?.kgfOfferData;
    const openDatepickerFunction = () => {
      queryStringFromDate !== '' &&
        setSelectedDateItem(props.startDate == null ? props.startDate : props.startDate.toDate());
    };

    sessionStorage.setItem('selectedCategory', holidayExperience);

    if (!isYasIsland) {
      weekDaysNames.push(weekDaysNames.shift());
    }
    if (monthsTranslations) {
      const { fullName, shortName } = monthsTranslations;
      if (fullName && fullName.length) {
        moment.updateLocale(activeLocale, { months: fullName });
      }

      if (shortName && shortName.length) {
        moment.updateLocale(activeLocale, { monthsShort: shortName });
      }
    }

    if (weekTranslations) {
      const { fullName, minName, shortName } = weekTranslations;
      if (minName && minName.length) {
        moment.updateLocale(activeLocale, { weekdaysMin: minName });
      }

      if (shortName && shortName.length) {
        moment.updateLocale(activeLocale, { weekdaysShort: shortName });
      }

      if (fullName && fullName.length) {
        moment.updateLocale(activeLocale, { weekdays: fullName });
      }
    }

    const renderLegendForEvent = () => {
      const { colorCode, eventName } = events;

      return (
        <div className="legend-wrapper">
          <div className="legend-text" style={{ backgroundColor: colorCode }}></div>
          <DynamicContent tagName="div" attrs={{ className: 'legend-name' }} innerHtml={eventName} />
        </div>
      );
    };

    const calenderMobileClose = ({ className, children }) => {
      return (
        <div className={className}>
          <div className="date-picker-mobile-close">
            <span onClick={() => props.datePickerShowHide(false)}>x</span>
          </div>
          <div style={{ position: 'relative' }}>{children}</div>
        </div>
      );
    };

    let customSelectedDate;
    if (props.startDate == null) {
      customSelectedDate = props.startDate;
    } else {
      customSelectedDate = props.startDate.toDate();
    }
    let selectedInitialDate;
    if (viewport === UIConfig.viewportTypes.mobile && isYasIsland && checkIfDateValid(parseQueryString('from'))) {
      selectedInitialDate = selectedDateItem;
    } else {
      selectedInitialDate = props.startDate == null ? props.startDate : props.startDate.toDate();
    }

    const footerNote = props?.data?.packageCategories?.categories.find(
      (item) => item.value === props?.holidayExperience,
    )?.footerNote;

    const getMonthsShown = (viewport) => {
      if (!isYMC && (viewport === UIConfig.viewportTypes.mobile || viewport === UIConfig.viewportTypes.tablet)) {
        if (isSwad) {
          if (viewport === UIConfig.viewportTypes.tablet) {
            return 2;
          }
          return 1;
        }
        return 12;
      }
      return 2;
    };

    const getWeekDayName = (date) => {
      const number = moment()
        .day(date)
        .day();
      return weekDaysNames[number] || date;
    };

    const isKgfCartJourney = () => {
      if (props?.holidayExperience) {
        const experienceWithKgfCarting = props.data?.packageCategories?.categories.find(
          (category) => category?.kgfCartingEnabled,
        );

        return experienceWithKgfCarting?.value === holidayExperience;
      }
    };

    const cutomHeaderNextBtnStyles = (customHeaderCount) => {
      if (viewport === UIConfig.viewportTypes.mobile) {
        if (customHeaderCount !== 0) {
          return { visibility: 'hidden' };
        } else {
          return { top: '-90px' };
        }
      } else {
        if (customHeaderCount === 0) {
          return { visibility: 'hidden' };
        } else {
          return null;
        }
      }
    };

    const cutomHeaderPrevBtnStyles = (customHeaderCount) => {
      if (viewport === UIConfig.viewportTypes.mobile) {
        if (customHeaderCount !== 0) {
          return { visibility: 'hidden' };
        } else {
          return { top: '-90px' };
        }
      } else {
        if (customHeaderCount === 1) {
          return { visibility: 'hidden' };
        } else {
          return null;
        }
      }
    };

    function isMonthAndYearInRange(dateToCheck, startDate, oneDayBeforeEndDate) {
      return dateToCheck.isBetween(startDate, oneDayBeforeEndDate, null, '[]');
    }

    // sartDate : 05/08/2024 -> month and year  = 08/2024
    // endDate : 1/10/2024 - > subtract 1 -> month and year = 09/2024

    const showKgfTagsInsideCalender = (currentMonth, maxDate) => {
      const { disabledDates, startDatesByCategory } = props.data.calendarSettings;

      const startDateObject = startDatesByCategory?.find((data) => data?.categoryCode === holidayExperience);
      const endDateObject = disabledDates?.find((data) => data?.categoryCode === holidayExperience);

      const outSideCalenderKgfDate = moment(endDateObject?.startDate, 'YYYY/MM/DD').format('DD MMM');

      setOutSideCalenderKgfDate(kgfOfferData?.TextBookOffer?.value + ' ' + outSideCalenderKgfDate);

      const startDate = moment(startDateObject.startDate);

      // We are doing this , because we need to check the day before the endDate or the startDate of disabled dates
      const oneDayBeforeEndDate = moment(endDateObject?.startDate).subtract(1, 'days');

      const visibleMonthOnCalendar = moment(currentMonth, 'MMMM YYYY');

      const isInRange = isMonthAndYearInRange(visibleMonthOnCalendar, startDate, oneDayBeforeEndDate);

      return isKgfCartJourney() && isInRange;
    };

    return (
      <div
        className={classNames('c-hotel-booking-widget-date-picker', { 'date-event-error': props.dateNotInRangeError })}
      >
        {/* On some mobile devices, the viewport height may not include the height of the browser's address bar and other
        interface elements, see interfaceHeight state. */}
        {domReady && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
              @media screen and (max-width: 480px) {
                .react-datepicker {
                  height: calc(100vh - ${interfaceHeight ? 185 + interfaceHeight : 350}px) !important;
                }
              }
            `,
            }}
          />
        )}
        <style
          dangerouslySetInnerHTML={{
            __html: `
              .c-hotel-booking-widget-date-picker .react-datepicker__day:not(.react-datepicker__day--disabled):hover::after {
                content : "${decodeHtmlEntity(props.calendarTooltip)}";
              }
            `,
          }}
        />
        {!isSwad && (
          <div
            className={classNames('date-picker-label', {
              error: props.calendarLabelError || props.dateNotInRangeError,
            })}
          >
            {data.calendarSettings.label}
          </div>
        )}
        <div className="date-picker-component" ref={ref}>
          {vBookingWidgetOnlyStartDate ? (
            !isYasIsland ? (
              <div
                className={classNames(`date-picker-placeholder ${props.isDatePickerOpen ? 'opened' : ''}`, {
                  error: (!props.startDate && props.calendarLabelError) || props.dateNotInRangeError,
                })}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  props.datePickerShowHide(props.isDatePickerOpen ? false : true);
                }}
                onKeyDown={(event) => props.handleKeyDown(event)}
                tabIndex="0"
                data-testid="date-picker-placeholder"
              >
                <DynamicContent tagName="span" innerHtml={props.calendarLabel} />
              </div>
            ) : (
              <div
                className={classNames(`date-picker-placeholder ${props.isDatePickerOpen && 'opened'}`, {
                  error: props.calendarLabelError || props.dateNotInRangeError,
                })}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  props.datePickerShowHide(props.isDatePickerOpen ? false : true);
                }}
                onKeyDown={(event) => props.handleKeyDown(event)}
                tabIndex="0"
                data-testid="date-picker-placeholder"
              >
                {isYasIsland && props.data.calendarSettings?.placeHolder && (
                  <p className="mobile-placehilder">{props.data.calendarSettings.placeHolder}</p>
                )}
                <DynamicContent tagName="span" innerHtml={props.calendarLabel} />
                {isYasIsland &&
                  (viewport === UIConfig.viewportTypes.mobile || viewport === UIConfig.viewportTypes.tablet) &&
                  props.currentWidgetStep === 2 && (
                    <div className="date-picker-custom-weekdaysnames" data-current-step={props.currentWidgetStep}>
                      {weekDaysNames.map((item, i) => {
                        return <DynamicContent tagName="span" innerHtml={item} attrs={{ className: 'weekday-txt' }} />;
                      })}
                    </div>
                  )}
              </div>
            )
          ) : (
            <div
              className={classNames(`date-picker-placeholder ${props.isDatePickerOpen && 'opened'}`, {
                error: props.calendarLabelError || props.dateNotInRangeError,
              })}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                props.datePickerShowHide(props.isDatePickerOpen ? false : true);
              }}
              onKeyDown={(event) => props.handleKeyDown(event)}
              tabIndex="0"
              data-testid="date-picker-placeholder"
            >
              {isYasIsland && props.data.calendarSettings?.placeHolder && (
                <p className="mobile-placehilder">{props.data.calendarSettings.placeHolder}</p>
              )}
              <DynamicContent tagName="span" innerHtml={props.calendarLabel} />

              {isKgfCartJourney() &&
                isYasIsland &&
                (viewport === UIConfig.viewportTypes.mobile || viewport === UIConfig.viewportTypes.tablet) &&
                props.currentWidgetStep === 2 && (
                  <div className="yi-kgf-calender-outside__mobile">
                    <img src={kgfOfferData?.StarIcon?.value?.src} className="calender-outside-icon" />
                    <div className="calender-outside-text">{outSideCalenderKgfDate}</div>
                  </div>
                )}

              {isYasIsland &&
                (viewport === UIConfig.viewportTypes.mobile || viewport === UIConfig.viewportTypes.tablet) &&
                props.currentWidgetStep === 2 && (
                  <div className="date-picker-custom-weekdaysnames" data-current-step={props.currentWidgetStep}>
                    {weekDaysNames.map((item, i) => {
                      return <DynamicContent tagName="span" innerHtml={item} attrs={{ className: 'weekday-txt' }} />;
                    })}
                  </div>
                )}
            </div>
          )}
          {props.isDatePickerOpen && (
            <div className="only-start-date-cal">
              <span aria-live="assertive" id="selectedDateId" className="sr-only">
                {props.calendarTooltip}
              </span>
              {vBookingWidgetOnlyStartDate && (
                <DatePicker
                  allowSameDay
                  calendarClassName={props.dateSelectionError ? 'date-range-error' : ''}
                  className="date-picker-placeholder date-picker-input"
                  dateFormat={data.calendarSettings.datePickerFormat}
                  dayClassName={(date) =>
                    `tooltip-${moment(date).date()}-${moment(date).month() + 1} react-datepicker__day--${moment(date)
                      .format('ddd')
                      .toLowerCase()}`
                  }
                  excludeDates={props.excludeDates}
                  inline={inline}
                  locale={activeLocale}
                  maxDate={momentTimezone(actualStartDate, getClientUtcOffset())
                    .add(data.calendarSettings.range, 'days')
                    .toDate()}
                  minDate={props.minDate == null ? props.minDate : props.minDate.toDate()}
                  monthsShown={viewport === UIConfig.viewportTypes.mobile ? 12 : 2} // Number represents Months. 12 Months should be visible for Mobile and 2 for Desktop.
                  onCalendarOpen={openDatepickerFunction}
                  onChange={props.handleSingleDateCallback}
                  onKeyDown={props.handleKeyDown}
                  onMonthChange={props.monthChangeHandler}
                  placeholderText={props.calendarLabel}
                  selected={customSelectedDate || selectedDateItem}
                  selectsStart
                  shouldCloseOnSelect={viewport !== UIConfig.viewportTypes.mobile}
                  startDate={props.startDate == null ? props.startDate : props.startDate.toDate()}
                  highlightDates={props.highlightDates}
                  withPortal={!isYasIsland && viewport === UIConfig.viewportTypes.mobile ? true : false}
                  inputProps={isYasIsland ? { readOnly: true } : ''}
                  onFocus={(e) => {
                    if (isYasIsland) {
                      e.target.setAttribute('inputmode', 'none');
                      e.target.readOnly = true;
                    }
                  }}
                  calendarContainer={
                    !isYasIsland && viewport === UIConfig.viewportTypes.mobile ? calenderMobileClose : false
                  }
                  {...(isYasIsland && weekDaysNames?.length && { formatWeekDay: getWeekDayName })}
                >
                  {props.excludedStartDateToolTip()}
                  {isYasIsland && footerNote && (
                    <Note>
                      <DynamicContent
                        tagName="div"
                        attrs={{ className: 'calendar-note-text' }}
                        innerHtml={footerNote}
                      />
                    </Note>
                  )}
                  {!footerNote && (props.dateSelectionError || props.monthlyNotes.length > 0 || !isEmpty(events)) && (
                    <div className="calendar-footer">
                      {props.dateSelectionError && (
                        <DynamicContent
                          tagName="div"
                          attrs={{ className: 'dateSelectionError' }}
                          innerHtml={props.dateSelectionErrorMessage}
                        />
                      )}
                      {props.monthlyNotes.length > 0 && (
                        <div className="help-text-container">
                          <div className="help-text-wrapper">
                            <DynamicContent
                              tagName="span"
                              attrs={{ className: 'title' }}
                              innerHtml={props.notesTitle}
                            />
                            {props.monthlyNotes.map((note, i) => (
                              <DynamicContent
                                key={i}
                                tagName="span"
                                attrs={{ className: 'description' }}
                                innerHtml={note}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                      {!isEmpty(events) && events.eventName && renderLegendForEvent()}
                    </div>
                  )}
                </DatePicker>
              )}

              {!vBookingWidgetOnlyStartDate && (
                <div
                  className={`date-picker-range-responsive-hide-nav ${viewport === UIConfig.viewportTypes.mobile &&
                    'mobile-view'} ${isKgfCartJourney() && 'kgf-calender-enabled'}`}
                >
                  <DatePicker
                    allowSameDay
                    calendarClassName={props.dateSelectionError ? 'date-range-error' : ''}
                    className="date-picker-placeholder date-picker-input"
                    dateFormat={data.calendarSettings.datePickerFormat}
                    dayClassName={(date) =>
                      `tooltip-${moment(date).date()}-${moment(date).month() + 1} react-datepicker__day--${moment(date)
                        .format('ddd')
                        .toLowerCase()}`
                    }
                    endDate={props.endDate == null ? props.endDate : props.endDate.toDate()}
                    excludeDates={props.excludeDates}
                    inline={isYasIsland ? isInline : inline}
                    locale={activeLocale}
                    maxDate={momentTimezone(actualStartDate, getClientUtcOffset())
                      .add(data.calendarSettings.range, 'days')
                      .toDate()}
                    minDate={props.minDate.toDate()}
                    monthsShown={getMonthsShown(viewport)}
                    onCalendarOpen={openDatepickerFunction}
                    onChange={props.handleDateCallback}
                    onKeyDown={props.handleKeyDown}
                    onMonthChange={props.monthChangeHandler}
                    placeholderText={props.calendarLabel}
                    selected={selectedInitialDate}
                    selectsEnd={props.selectRange}
                    selectsStart
                    shouldCloseOnSelect={false}
                    selectsRange
                    startDate={props.startDate == null ? props.startDate : props.startDate.toDate()}
                    highlightDates={props.highlightDates}
                    withPortal={
                      !isYasIsland &&
                      viewport === UIConfig.viewportTypes.mobile &&
                      !vBookingWidgetOnlyStartDate &&
                      props.isRedVariant
                        ? true
                        : false
                    }
                    inputProps={isYasIsland ? { readOnly: true } : ''}
                    onFocus={(e) => {
                      if (isYasIsland) {
                        e.target.setAttribute('inputmode', 'none');
                        e.target.readOnly = true;
                      }
                    }}
                    calendarContainer={
                      !isYasIsland &&
                      viewport === UIConfig.viewportTypes.mobile &&
                      !vBookingWidgetOnlyStartDate &&
                      props.isRedVariant
                        ? calenderMobileClose
                        : false
                    }
                    {...(isYasIsland && weekDaysNames?.length && { formatWeekDay: getWeekDayName })}
                    {...(isKgfCartJourney() && {
                      renderCustomHeader: ({
                        monthDate,
                        date,
                        increaseMonth,
                        decreaseMonth,
                        customHeaderCount,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => {
                        return (
                          <>
                            <button
                              onClick={decreaseMonth}
                              type="button"
                              className="react-datepicker__navigation react-datepicker__navigation--previous"
                              aria-label="Previous Month"
                              style={cutomHeaderPrevBtnStyles(customHeaderCount)}
                              hidden={prevMonthButtonDisabled}
                            >
                              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                                Previous Month
                              </span>
                            </button>

                            <div className="react-datepicker__current-month">
                              {monthDate.toLocaleString(getCurrentLanguage(), { month: 'long', year: 'numeric' })}
                              {showKgfTagsInsideCalender(
                                monthDate.toLocaleString(getCurrentLanguage(), { month: 'long', year: 'numeric' }),
                                momentTimezone(actualStartDate, getClientUtcOffset())
                                  .add(data.calendarSettings.range, 'days')
                                  .toDate(),
                              ) && <span className="kgf-calender-inside-tag">{kgfOfferData?.TextOffer?.value}</span>}
                            </div>
                            <button
                              onClick={increaseMonth}
                              type="button"
                              className="react-datepicker__navigation react-datepicker__navigation--next"
                              aria-label="Next Month"
                              style={cutomHeaderNextBtnStyles(customHeaderCount)}
                              hidden={nextMonthButtonDisabled}
                            >
                              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                                Next Month
                              </span>
                            </button>
                          </>
                        );
                      },
                    })}
                  >
                    {isKgfCartJourney() &&
                      (viewport !== UIConfig.viewportTypes.mobile || viewport !== UIConfig.viewportTypes.tablet) && (
                        <div className="yi-kgf-calender-outside">
                          <img src={kgfOfferData?.StarIcon?.value?.src} className="calender-outside-icon" />
                          <div className="calender-outside-text">{outSideCalenderKgfDate}</div>
                        </div>
                      )}
                    {props.excludedDateToolTip()}
                    {isYasIsland && footerNote && (
                      <Note>
                        <DynamicContent
                          tagName="div"
                          attrs={{ className: 'calendar-note-text' }}
                          innerHtml={footerNote}
                        />
                      </Note>
                    )}
                    {!footerNote && (props.dateSelectionError || props.monthlyNotes.length > 0 || !isEmpty(events)) && (
                      <div className="calendar-footer">
                        {props.dateSelectionError && (
                          <DynamicContent
                            tagName="div"
                            attrs={{ className: 'dateSelectionError' }}
                            innerHtml={props.dateSelectionErrorMessage}
                          />
                        )}
                        {props.monthlyNotes.length > 0 && (
                          <div className="help-text-container">
                            <div className="help-text-wrapper">
                              <DynamicContent
                                tagName="span"
                                attrs={{ className: 'title' }}
                                innerHtml={props.notesTitle}
                              />
                              {props.monthlyNotes.map((note, i) => (
                                <DynamicContent
                                  key={i}
                                  tagName="span"
                                  attrs={{ className: 'description' }}
                                  innerHtml={note}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {!isEmpty(events) && events.eventName && renderLegendForEvent()}
                      </div>
                    )}
                  </DatePicker>
                </div>
              )}
            </div>
          )}
        </div>
        {!isEmpty(events) && events.eventName && props.isRedVariant && props.getEventNotIncludedErrorMsg()}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'HotelBookingWidgetDatepicker', props.data.variant);
  }
});

export default HotelBookingWidgetDatepicker;

HotelBookingWidgetDatepicker.propTypes = {
  data: PropTypes.object,
  handleDateCallback: PropTypes.func,
  datePickerShowHide: PropTypes.func,
  calendarTooltip: PropTypes.string,
  calendarLabelError: PropTypes.bool,
  calendarLabel: PropTypes.string,
  isDatePickerOpen: PropTypes.bool,
  selectRange: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  minDate: PropTypes.instanceOf(moment),
  handleKeyDown: PropTypes.func,
  excludeDates: PropTypes.array,
  excludedDateToolTip: PropTypes.func,
  dateSelectionError: PropTypes.bool,
  dateSelectionErrorMessage: PropTypes.string,
  handleFocusOutCalendar: PropTypes.func,
  actualStartDate: PropTypes.instanceOf(moment),
  monthChangeHandler: PropTypes.func,
  monthlyNotes: PropTypes.array,
  notesTitle: PropTypes.string,
};
