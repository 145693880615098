/*
    mini-cart-constants.js  This file contains all the constants for mini cart actions.

    @license Miral
*/
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_PRODUCT_STORE_CART = 'DELETE_PRODUCT_STORE_CART';
export const ADD_PRODUCT_STORE_CART = 'ADD_PRODUCT_STORE_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const INVALID_CART = 'INVALID_CART';
