import React from 'react';
import { SvgSprite } from '..';
import { detectViewPort, isSafariBrowser } from '../../../../common/utility';
import { createFocusLoop } from '../../../../common/accessibility/overlay-accessibility';
import { logComponentRenderingError } from '../../../../common/logger';

import './overlay-component.scss';
import UIConfig from '../../../../common/UIConfig';

/**
 * Overlay Class ( which extends the React.Component) This creates the fuctionality for the
 * overlay component
 * @param    {Void} Class does not accept any parameters.
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */

export default class SearchOverlay extends React.Component {
  /**
   * Constructor of the class is defined which handles binding of the events and the props
   * to the super class. There are two state parameters :->
   * 1. Active -> Decides if the overlay should be active or not.
   * 2. Data -> Data that needs to be appended in the overlay.
   * @param    {[Object]} props [Props that are passed to the component].
   * @return   {Void} constructor does not return anything.
   */

  constructor() {
    super();
    this.searchToggleOverlay = this.searchToggleOverlay.bind(this);
    this.searchCloseOverlay = this.searchCloseOverlay.bind(this);
    this.state = {
      active: false,
      data: null,
      customClass: '',
      closeOnClick: false,
      variant: '',
      customFixedTitle: '',
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.focusTrap = null;
    this.wrapperRef = null;
    this.closeButtonRef = React.createRef();
  }

  /**
   * searchCloseOverlay function closes the Over lay on the click of cross button.
   * @param    {Void} function does not accept anything..
   * @return   {Void} function does not return anything.
   */

  searchCloseOverlay(e) {
    if (e.type === 'click' || (e.type === 'keydown' && (e.which === 13 || e.which === 27))) {
      const isClicked = e.type === 'click';
      this.setState({
        active: false,
        data: null,
      });
      window.PubSub.publish('searchCloseOverlay', {
        close: true,
      });
      setTimeout(() => {
        if (!isClicked) {
          this.previouslyFocussed.focus();
        }
      }, 0);
      document.getElementsByTagName('body')[0].classList.remove('no-scroll');
      if (detectViewPort() === 'mobile') {
        document.getElementsByTagName('html')[0].classList.remove('no-scroll');
      }
    }
  }

  /**
   * searchToggleOverlay function toggels the overlay once the searchToggleOverlayState is fired.
   * @param    {Anything} data [Data passed by the publisher of the event].
   * @return   {Void} function does not return anything.
   */
  searchToggleOverlay = (msg, data) => {
    this.setState(
      {
        active: data.shouldOpen ? true : false,
        data: data.dataToAppend ? data.dataToAppend : null,
        customClass: data.customClass ? data.customClass : null,
        closeOnClick: data.closeOnClick ? true : false,
        variant: data.variant || '',
        customFixedTitle: data.customFixedTitle || data.dataToAppend.props.data.customFixedTitle,
        overlayCloseAriaLabel: data.overlayCloseAriaLabel,
        resetAriaLabel:
          (data.dataToAppend && data.dataToAppend.props.data && data.dataToAppend.props.data.resetAriaLabel) || null,
        sessionTimeOutAriaLabel: data?.sessionTimeOutAriaLabel || null,
      },
      () => {
        if (this.closeButtonRef && data.customClass === 'nav-item-search ') {
          document.querySelector('[name=autoSuggestForm] input').focus();
        } else if (this.state.active === true && this.state.variant !== UIConfig.commonVariant.b2cSearchVariant) {
          document.getElementsByTagName('body')[0].classList.add('no-scroll');
          if (detectViewPort() === 'mobile') {
            document.getElementsByTagName('html')[0].classList.add('no-scroll');
          }
        } else {
          this.previouslyFocussed && this.previouslyFocussed.focus();
          document.getElementsByTagName('body')[0].classList.remove('no-scroll');
          if (detectViewPort() === 'mobile') {
            document.getElementsByTagName('html')[0].classList.remove('no-scroll');
          }
        }
      },
    );
  };

  /**
   * componentDidMount  Lifecycle event ,which attches the searchToggleOverlayState event on the window
   * from which the user can pass the data to be append to it.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */

  componentDidMount() {
    window.PubSub.unsubscribe('searchToggleOverlayState');
    window.PubSub.subscribe('searchToggleOverlayState', this.searchToggleOverlay);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.focusTrap && this.wrapperRef && this.wrapperRef.removeEventListener('keydown', this.focusTrap);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
    if (this.wrapperRef) {
      this.previouslyFocussed = document.activeElement;
      this.focusTrap = createFocusLoop(this.wrapperRef, this.searchCloseOverlay);
      this.focusTrap && this.wrapperRef.addEventListener('keydown', this.focusTrap);
    }
  }

  handleClickOutside(event) {
    if (this.state.closeOnClick === true) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({
          active: false,
          data: null,
        });
      }
      document.getElementsByTagName('body')[0].classList.remove('no-scroll');
    }
  }

  /**
   * render  Used to render the JSX of the Overlay component. This will contain the Data which
   * will be passed by user using this overlay.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Object]} JSX of the component
   */

  render() {
    try {
      return this.state.active ? (
        <div
          data-c-name="Overlay"
          className={
            this.state.active
              ? this.state.customClass
                ? this.state.customClass + ' overlay active'
                : 'overlay active'
              : 'hide'
          }
        >
          <div className={'overlay-container ' + (isSafariBrowser() ? 'safari' : '')}>
            <div
              role="dialog"
              className="overlay-wrapper"
              ref={this.setWrapperRef}
              tabIndex="-1"
              aria-label={this.state.sessionTimeOutAriaLabel}
            >
              <div className="custom-fixed-title">{this.state.customFixedTitle}</div>

              <div className="overlay-content-container">
                <div className="overlay-content-inner-container">
                  <div className="brand-logo-container">
                    <div className="brand-logo"></div>
                  </div>
                  {this.state.data ? this.state.data : null}
                  {this.props.children}
                </div>
              </div>
              <div className="top-border">
                <span className="circle"></span>
                <div
                  role="button"
                  aria-label={
                    this.state.overlayCloseAriaLabel
                      ? this.state.overlayCloseAriaLabel
                      : this.state.resetAriaLabel
                      ? this.state.resetAriaLabel
                      : 'close'
                  }
                  className="overlay--close-button"
                  tabIndex="0"
                  onClick={this.searchCloseOverlay}
                  onKeyDown={this.searchCloseOverlay}
                  ref={this.closeButtonRef}
                >
                  <SvgSprite
                    id={
                      this.state.variant === UIConfig.commonVariant.b2cSearchVariant ? 'icn-close-white' : 'icn-close'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null;
    } catch (err) {
      return logComponentRenderingError(err, 'Overlay');
    }
  }
}
