import React from 'react';
import { SvgSprite } from '..';
import UIConfig from '../../../../common/UIConfig';
import { detectMobile, detectViewPort, isSafariBrowser, checkTenant } from '../../../../common/utility';
import { createFocusLoop } from '../../../../common/accessibility/overlay-accessibility';
import { logComponentRenderingError } from '../../../../common/logger';

import './overlay-component.scss';

/**
 * Overlay Class ( which extends the React.Component) This creates the fuctionality for the
 * overlay component
 * @param    {Void} Class does not accept any parameters.
 * @return   {[Object]} Return a render function which conatins the JSX of the component.
 */

export default class Overlay extends React.Component {
  /**
   * Constructor of the class is defined which handles binding of the events and the props
   * to the super class. There are two state parameters :->
   * 1. Active -> Decides if the overlay should be active or not.
   * 2. Data -> Data that needs to be appended in the overlay.
   * @param    {[Object]} props [Props that are passed to the component].
   * @return   {Void} constructor does not return anything.
   */

  constructor() {
    super();
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.state = {
      active: false,
      forceOpenOnMobile: false,
      data: null,
      customClass: '',
      closeOnClick: false,
      variant: '',
      customFixedTitle: '',
      restrictCloseOnEsc: false,
      screenHeight: {},
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.focusTrap = null;
    this.wrapperRef = null;
    this.closeButtonRef = React.createRef();
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
  }

  /**
   * closeOverlay function closes the Over lay on the click of cross button.
   * @param    {Void} function does not accept anything..
   * @return   {Void} function does not return anything.
   */

  closeOverlay(e) {
    if (
      e.type === 'click' ||
      (e.type === 'keydown' && (e.which === 13 || (!this.state.restrictCloseOnEsc && e.which === 27)))
    ) {
      const isClicked = e.type === 'click';
      this.setState({
        active: false,
        data: null,
      });
      window.PubSub.publish('closeOverlay', {
        close: true,
        upsell: this.state?.customClass?.includes('upsell') || false,
        context: this.state.redirectionContext ? this.state.redirectionContext : false,
      });
      setTimeout(() => {
        if (!isClicked) {
          this.previouslyFocussed.focus();
        }
      }, 0);
      document.getElementsByTagName('body')[0].classList.remove('no-scroll');
      if (detectViewPort() === 'mobile') {
        document.getElementsByTagName('html')[0].classList.remove('no-scroll');
      }
    }
  }

  /**
   * toggleOverlay function toggels the overlay once the toggleOverlayState is fired.
   * @param    {Anything} data [Data passed by the publisher of the event].
   * @return   {Void} function does not return anything.
   */
  toggleOverlay = (msg, data) => {
    this.setState(
      {
        active: data.shouldOpen ? true : false,
        forceOpenOnMobile: data.forceOpenOnMobile,
        data: data.dataToAppend ? data.dataToAppend : null,
        customClass: data.customClass ? data.customClass : null,
        closeOnClick: data.closeOnClick ? true : false,
        redirectionContext: data.redirectionContext,
        variant: data.variant || '',
        customFixedTitle: data.customFixedTitle,
        overlayCloseAriaLabel: data.overlayCloseAriaLabel,
        resetAriaLabel:
          (data.dataToAppend && data.dataToAppend.props.data && data.dataToAppend.props.data.resetAriaLabel) || null,
        sessionTimeOutAriaLabel: data?.sessionTimeOutAriaLabel || null,
        restrictCloseOnEsc: !!data.restrictCloseOnEsc,
      },
      () => {
        if (this.closeButtonRef && data.customClass === 'nav-item-search ') {
          document.querySelector('[name=autoSuggestForm] input').focus();
        } else if (this.state.active === true && this.state.variant !== 'nav-item-search-b2c') {
          const isMiniCartExpanded = document.querySelector('.minicart-expanded');
          if (!isMiniCartExpanded) {
            document.getElementsByTagName('body')[0].classList.add('no-scroll');
            if (detectViewPort() === 'mobile') {
              document.getElementsByTagName('html')[0].classList.add('no-scroll');
            }
          }
        } else {
          this.previouslyFocussed && this.previouslyFocussed.focus();
          document.getElementsByTagName('body')[0].classList.remove('no-scroll');
          if (detectViewPort() === 'mobile') {
            document.getElementsByTagName('html')[0].classList.remove('no-scroll');
          }
        }
      },
    );
  };

  /**
   * componentDidMount  Lifecycle event ,which attches the toggleOverlayState event on the window
   * from which the user can pass the data to be append to it.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Void]} function does not return anything.
   */

  updateDimensions = () => {
    const diffHeight = window.outerHeight - window.innerHeight;
    this.setState({ screenHeight: { height: window.outerHeight, paddingBottom: diffHeight } });
  };

  componentDidMount() {
    window.PubSub.unsubscribe('toggleOverlayState');
    window.PubSub.subscribe('toggleOverlayState', this.toggleOverlay);
    document.addEventListener('mousedown', this.handleClickOutside);
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    //TODO check with jitendra.Removed because calendar overlay was not working
    // window.PubSub.unsubscribe('toggleOverlayState');
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.focusTrap && this.wrapperRef && this.wrapperRef.removeEventListener('keydown', this.focusTrap);
    window.removeEventListener('resize', this.updateDimensions);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
    if (this.wrapperRef) {
      this.previouslyFocussed = document.activeElement;
      this.focusTrap = createFocusLoop(this.wrapperRef, this.closeOverlay);
      this.focusTrap && this.wrapperRef.addEventListener('keydown', this.focusTrap);
    }
  }

  handleClickOutside(event) {
    if (this.state.closeOnClick === true) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({
          active: false,
          data: null,
        });
      }
      document.getElementsByTagName('body')[0].classList.remove('no-scroll');
    }
  }

  /**
   * isNotYMCTakeOverBanner  Used to if it is not takeover banner used in YMC
   */

  isNotYMCTakeOverBanner = () => {
    const { customClass } = this.state;
    return !customClass.includes('takeover-banner-overlay');
  };

  /**
   * render  Used to render the JSX of the Overlay component. This will contain the Data which
   * will be passed by user using this overlay.
   * @param    {[Void]} function does not accept anything.
   * @return   {[Object]} JSX of the component
   */

  render() {
    const isMobile = detectMobile();
    const isForceOpen = this.state.forceOpenOnMobile && isMobile;
    try {
      return this.state.active ? (
        <div
          data-c-name="Overlay"
          style={
            this.isSwad && this.state.customClass && this.state.customClass.includes('calender-overlay-mobile-height')
              ? {}
              : {
                  height:
                    isMobile && !isForceOpen && this.isNotYMCTakeOverBanner() ? this.state.screenHeight.height : '',
                }
          }
          className={
            this.state.active
              ? this.state.customClass
                ? this.state.customClass + ' overlay active'
                : 'overlay active'
              : 'hide'
          }
        >
          <div className={'overlay-container ' + (isSafariBrowser() ? 'safari' : '')}>
            <div
              role="dialog"
              className="overlay-wrapper"
              ref={this.setWrapperRef}
              tabIndex="-1"
              aria-label={this.state.sessionTimeOutAriaLabel}
              style={
                this.isSwad &&
                this.state.active &&
                this.state.customClass &&
                this.state.customClass.includes('mobile-fix-height') &&
                detectMobile()
                  ? { height: window.innerHeight }
                  : {}
              }
            >
              <div className="custom-fixed-title">{this.state.customFixedTitle}</div>

              <div
                className="overlay-content-container"
                style={{
                  paddingBottom:
                    !this.isSwad && this.isNotYMCTakeOverBanner() && isMobile && !isForceOpen
                      ? this.state.screenHeight.paddingBottom
                      : '',
                }}
              >
                <div className="overlay-content-inner-container">
                  <div className="brand-logo-container">
                    <div className="brand-logo"></div>
                  </div>
                  {this.state.data ? this.state.data : null}
                  {this.props.children}
                </div>
              </div>
              <div className="top-border">
                <span className="circle"></span>
                <div
                  role="button"
                  aria-label={
                    this.state.overlayCloseAriaLabel
                      ? this.state.overlayCloseAriaLabel
                      : this.state.resetAriaLabel
                      ? this.state.resetAriaLabel
                      : 'close'
                  }
                  className="overlay--close-button"
                  tabIndex="0"
                  onClick={this.closeOverlay}
                  onKeyDown={this.closeOverlay}
                  ref={this.closeButtonRef}
                >
                  <SvgSprite id={this.state.variant === 'nav-item-search-b2c' ? 'icn-close-white' : 'icn-close'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null;
    } catch (err) {
      return logComponentRenderingError(err, 'Overlay');
    }
  }
}
