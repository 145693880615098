import { Logging } from '../../../../common/logger';
import {
  AdvanceTopupService,
  CreditSettle,
  OrderService,
  PartnerService,
  ServiceConfig,
} from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  checkInlineSignup,
  getAnonymousCart,
  getCurrentLanguage,
  getErrorMap,
  getLoggedInUser,
  getMainObject,
  getPromoCode,
  isEmpty,
  parseQueryString,
  removePromoCode,
  resolvePath,
  scrollToView,
  setOrderIdpromoCode,
  setYasId,
  getAPIStateParam,
  getSessionStorage,
  generateGUID,
  getPurchaseAllowed,
  isYasArenaJourney,
  getLocalStorageByKey,
  isTicketUpgradePayment,
  getAnpGuestDetailsForTuJourney,
  backToUpgradePage,
} from '../../../../common/utility';
import { seatedLocalStorageSvc } from '../cart/seated-journey-actions';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';

//Request object for close order API
let dataForClose = {
  orderId: '',
  amount: '',
  paymentMode: UIConfig.paymentOption.accCredit,
  tenantId: ServiceConfig.getTenantId(),
  language: getCurrentLanguage() || 'en',
  marketType: '',
  emailTemplateId: '',
};

export const getCredits = (self) => {
  const { pageVariant } = self.props.data.paymentOptions;
  const { b2bRedirectForAdcs } = self.props.data.services;
  PartnerService.getPartnerAccountCredit('', self.props.data.services.getPartnerAccountCredit.url).then((res) => {
    if (getLoggedInUser().userType === 'Primary Contact') {
      if (pageVariant === 'b2bCreditsettlement' && res.data.totalCredit === '0') {
        window.location.href = b2bRedirectForAdcs.url;
      } else if (pageVariant === 'b2bAdvanceTopUp' && res.data.totalCredit !== '0') {
        window.location.href = b2bRedirectForAdcs.url;
      } else if ((pageVariant === 'b2bCart' || pageVariant === 'b2bViewOrder') && res.data.totalCredit === '0') {
        self.setState({ accountCreditIsZero: true });
      }
    } else if (pageVariant === 'b2bCreditsettlement' || pageVariant === 'b2bAdvanceTopUp') {
      window.location.href = b2bRedirectForAdcs.url;
    } else {
      self.setState({ accountCreditIsZero: true });
    }
  });
};

/**
 * onTncChangeHandler function changes the state of the checkbox for terms and condition.
 * and sets state accordingly
 * @return   {Void} function does not return anything.
 */
export const onTncChangeHandler = (self, e) => {
  const isGuestUserNotExists = self.guestUserHasErrors();
  const { currentOption } = self.state;
  if (
    resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.YIB2C &&
    self.is2StepPayment &&
    !self.onPayPalButtonSubmitSuccess &&
    !self.isLoggedInUser
  ) {
    // Initiate form validation for guest-checkout-form
    // window.PubSub.publish('initiatePaymentSubmitValidation');
    self.setState({
      isTncChecked: e.currentTarget.checked,
    });
    self.showTnCError(!e.currentTarget.checked);
    setTimeout(() => {
      if (document.querySelectorAll('.error-msg').length > 0) {
        // self.setState({
        //   isTncChecked: false,
        // });
        self.onPayPalButtonSubmitSuccess = false;
      } else {
        // self.setState({
        //   isTncChecked: !self.state.isTncChecked,
        // });
        self.onPayPalButtonSubmitSuccess = !self.state.isTncChecked;
      }
    }, 200);
    return;
  }

  if (self.isB2B && self.B2B_isCreditSettlement) {
    CreditSettle.createSettle(
      self.state.cart.cart,
      'c-payment-wrapper',
      `${self.services.b2bCreateSettlement.url}?tenantid=${getLoggedInUser().tenantID}`,
    ).then((res) => {
      self.payData.orderID = res.data.settleId;
      self.payData.total = res.data.amount;
      self.payData.creditSettlementInvoice = self.state.cart.reconcilePayloadData;
      const res_Code = res.data.settleId.split('-');
      const settleIdLength = res_Code[res_Code.length - 1];

      self.payData.reservationCode = settleIdLength;
    });
  }

  if (self.isB2B && self.B2B_isAdvanceTopUp) {
    AdvanceTopupService.getAdvanceTopupData(
      `${self.services.b2bAdvanceTopUp.url}?tenantid=${getLoggedInUser().tenantID}`,
      true,
      'c-payment-wrapper',
      self.cart.cart,
    ).then((res) => {
      self.payData.orderID = res.data.sale.ak;
      self.payData.total = res.data.sale.total.gross;
    });
  }

  const isError = canUseDOM() && document.getElementsByClassName('heading-error');

  if (isGuestUserNotExists) {
    self.onPayPalButtonSubmitSuccess = false;
  }
  if (
    isError &&
    isError.length > 0 &&
    ((!isYasArenaJourney() && currentOption === 'PayPal') || currentOption === UIConfig.paymentOption.gPay)
  ) {
    if (currentOption === 'PayPal') {
      self.onPayPalButtonSubmitSuccess = false;
      const submitBtn = document.getElementById('submit-btn');
      if (submitBtn) {
        submitBtn.click();
      }
    }
    const parent = document.querySelector('.c-b2c-cart-header-container');
    parent && parent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    self.setState({
      isTncChecked: false,
    });
  }
  if (self.is2StepPayment && currentOption === UIConfig.paymentOption.applePay && e.currentTarget.checked) {
    self.showTnCError(false);
  }

  if (self.is2StepPayment && currentOption === UIConfig.paymentOption.gPay && e.currentTarget.checked) {
    self.showTnCError(false);
  }
  if (
    currentOption === 'PayPal' &&
    self.is2StepPayment &&
    !self.onPayPalButtonSubmitSuccess &&
    !self.isLoggedInUser &&
    isGuestUserNotExists
  ) {
    self.setState({
      isTncChecked: e.currentTarget.checked,
    });
    self.showTnCError(!e.currentTarget.checked);
  } else if (
    currentOption === UIConfig.paymentOption.postPay ||
    currentOption === UIConfig.paymentOption.aliPay ||
    self.checkPointsPayMethod(currentOption) ||
    currentOption === UIConfig.paymentOption.adcbTouchPoints ||
    currentOption === UIConfig.moneyCardProduct.productType ||
    currentOption === UIConfig.paymentOption.paypal
  ) {
    if (!e.currentTarget.checked) {
      self.setState({ isTncChecked: e.currentTarget.checked }, () => {
        self.showTnCError(true);
      });
    } else {
      self.setState({ isTncChecked: e.currentTarget.checked }, () => {
        self.showTnCError(false);
        const tenantID = getLoggedInUser().tenantID;
        const {
          emailTemplateId,
          invoiceTemplateId,
          conversionRate,
          primaryCTA,
          paymentURL,
          pageVariant,
          apMainPassholderPurchaseEmailTemplateID,
          apMainPassholderRenewEmailTemplateID,
          apAddPassholderNewUserPurchaseEmailTemplateID,
          apAddPassholderNewUserRenewEmailTemplateID,
          apAddPassholderRenewEmailTemplateID,
          apAddPassholderPurchaseEmailTemplateID,
        } = self.paymentOptions;

        // setting up in localstorage
        self.aliPayCmsData.cmsData = {
          emailTemplateId,
          invoiceTemplateId,
          conversionRate,
          tenantID,
          primaryCTA,
          paymentURL,
          pageVariant,
          apMainPassholderPurchaseEmailTemplateID,
          apMainPassholderRenewEmailTemplateID,
          apAddPassholderNewUserPurchaseEmailTemplateID,
          apAddPassholderNewUserRenewEmailTemplateID,
          apAddPassholderRenewEmailTemplateID,
          apAddPassholderPurchaseEmailTemplateID,
        };

        self.aliPayCmsData.services = self.props.data.services;
        localStorage.setItem(UIConfig.localStoreKeys.payment.cmsData, JSON.stringify(self.aliPayCmsData));
      });
    }
  } else if (currentOption === UIConfig.paymentOption.applePay && self.is2StepPayment) {
    self.setState({ isTncChecked: e.currentTarget.checked }, self.onPayment);
  } else if (currentOption === UIConfig.paymentOption.gPay && self.is2StepPayment) {
    self.setState({ isTncChecked: e.currentTarget.checked }, self.onPayment);
  } else {
    if (self.isB2B && self.partnerReference === '' && !self.B2B_isCreditSettlement && !self.B2B_isAdvanceTopUp) {
      scrollToView({ errorBlock: document.getElementsByClassName('c-my-cart-order--agent-reference')[0] }, true);
    } else {
      self.setState(
        {
          isTncChecked: !self.is2StepPayment
            ? !self.state.isTncChecked
            : !self.state.isTncChecked && !self.state.isPaymentMethodBlocked,
        },
        self.onPayment,
      );
    }
  }
};

/**
 * only used for B2B Payment
 * onSubmitForApproval function is used to  to redirect to thank you page, on my cart page after create order is
 * successful and directly in case of Order detail page
 *
 */
export const onSubmitForApproval = (self, e) => {
  e.preventDefault();
  OrderService.createOrder(
    self.componentName,
    self.services.createOrder.url,
    UIConfig.loader.defaultPreLoaderTarget,
    self.partnerReference,
    self.dueDate,
    true,
    null,
    null,
    null,
    self.services.updateCart,
    self.services,
  )
    .then((res) => {
      if (res.data.orderID) {
        Logging(res, self.componentName || 'Payment_Submit_create-order', true, 'Order created');
        self.redirectToThankyou(true, res.data.orderID, UIConfig.paymentOption.submitApproval);
      }
    })
    .catch((response) => {
      self.isErrorOccured('createOrder', response.error);
    });
};

/**
 * only used for B2B Payment
 * onPayWithCredit function is used to  to redirect to thank you page, on my cart page after create order is
 * successful and directly in case of Order detail page
 *
 */
export const onPayWithCredit = (self, e) => {
  e.preventDefault();
  self.notEncrypted = parseQueryString('notEncrypted');
  if (self.state.B2BPaymentEnable) {
    OrderService.createOrder(
      self.componentName,
      self.services.createOrder.url,
      UIConfig.loader.defaultPreLoaderTarget,
      self.partnerReference,
      self.dueDate,
      null,
      null,
      null,
      null,
      self.services.updateCart,
      self.services,
    )
      .then((res) => {
        let data = res.data;
        const b2bCheckoutQuantity = getLocalStorageByKey(UIConfig.localStoreKeys.b2bCheckoutQuantity);
        const b2bMaxTicket = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.maxTickets);
        const isBulkOrder = getPurchaseAllowed() && b2bCheckoutQuantity > b2bMaxTicket;
        dataForClose.orderId = data.orderID;
        dataForClose.amount = data.totalPrice;
        dataForClose.marketType = self.marketType;
        dataForClose.emailTemplateId = self.emailTemplateId;
        // only for B2B: Setting the reservationCode
        if (self.isB2B && !getPurchaseAllowed()) {
          dataForClose.isOrderHistoryPayment =
            (canUseDOM() && JSON.parse(localStorage.getItem('isOrderHistoryPayment'))) || false;
          self.reservationCode = res.reservationCode;
        }
        if (getPurchaseAllowed()) {
          dataForClose.paymentInfo = [
            {
              code: UIConfig.paymentOption.accCredit,
              amount: data.totalPrice,
            },
          ];
        }
        Logging(res, self.componentName || 'Payment_credit_create-order', true, 'Order created');
        OrderService.closeOrder(
          self.componentName,
          dataForClose,
          isBulkOrder ? self.services.closeBulkOrder.url : self.services.closeOrder.url,
          UIConfig.loader.defaultPreLoaderTarget,
          self.notEncrypted,
        )
          .then((response) => {
            const { orderId, error } = response.data;
            if (orderId !== 'undefined' || orderId) {
              Logging(res, self.componentName || 'Payment_credit_close-order', true, 'Order closed');
              self.redirectToThankyou(true, dataForClose.orderId, UIConfig.paymentOption.accCredit);
            } else if (error.code === '200') {
              Logging(res, self.componentName || 'Payment_credit_close-order', true, 'Order closed');
              self.redirectToThankyou(true, dataForClose.orderId, UIConfig.paymentOption.accCredit);
            }
          })
          .catch((response) => {
            self.isErrorOccured('closeOrder', response.error);
          });
      })
      .catch((response) => {
        self.isErrorOccured('createOrder', response.error);
      });
  } else {
    dataForClose.amount = self.total;
    dataForClose.orderId = self.orderID;
    dataForClose.marketType = self.marketType;
    dataForClose.emailTemplateId = self.emailTemplateId;
    dataForClose.isOrderHistoryPayment =
      (canUseDOM() && JSON.parse(localStorage.getItem('isOrderHistoryPayment'))) || false;
    self.notEncrypted = parseQueryString('notEncrypted');
    if (dataForClose.orderId) {
      OrderService.closeOrder(
        self.componentName,
        dataForClose,
        self.services.closeOrder.url,
        UIConfig.loader.defaultPreLoaderTarget,
        self.notEncrypted,
      )
        .then((response) => {
          Logging(response, self.componentName || 'Payment_credit_close-order', true, 'Order closed');
          self.redirectToThankyou(true, dataForClose.orderId, UIConfig.paymentOption.accCredit);
        })
        .catch((response) => {
          let cmsErrors = self.services.closeOrder.error,
            errorData = getErrorMap('closeOrder', cmsErrors, false, response.error, null);

          localStorage.setItem('error_message', JSON.stringify(errorData));
          self.redirectToThankyou(false, self.props.data.orderID, UIConfig.paymentOption.accCredit);
        });
    }
  }
};

const createAnnonymousOrderBodyOther = (self, res) => {
  if (self.is2StepPayment) {
    if (self.is2StepPaymentYasArena) {
      YALocalStorageSvc.getSetOrderDetails(res.data);
    }
    const userPromoCode = `${res.data.reservationCode}_${getPromoCode()}`;

    setOrderIdpromoCode(userPromoCode);
    removePromoCode();

    window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
      isCreateOrderSuccess: true,
      resCode: res.data.reservationCode,
      orderId: res.data.orderID,
      total: res.data.payfortAmount,
      pointsPayResponse: {
        ...res?.data?.pointsPayResponse,
      },
      postPayResponse: {
        ...res?.data?.postPayResponse,
      },
      touchpointResponse: {
        ...res?.data?.touchpointResponse,
      },
      moneyCardResponse: {
        ...res?.data?.moneyCardResponse,
      },
    });
  }

  Logging(res, 'Payment_create-order', true, 'Order created');

  self.setOrderId(res.data.orderID);
};

/**
 * setRedirectPaymentData function creates order ID for further call to initialize payment
 * local storage in case of Mycart after successful order created and directly on order detail page
 * @return   {Void} function does not return anything.
 */
export const setRedirectPaymentData = (self) => {
  const { createAnnonymousOrder, createOrderYasId, updateCart } = self.services;
  let extraParams = null;
  let proceedToCreateOrder = true;
  const { currentOption, isPaymentMethodBlocked } = self.state;
  if (self.checkPointsPayMethod(currentOption)) {
    const { cancelUrl, payCallBackUrl } = self.getPointsPayConfig();
    extraParams = `paymentMethod=${UIConfig.paymentOption.pointsPay}&intermediatePageUrl=${payCallBackUrl.source}&cancelUrl=${cancelUrl.source}`;
  }
  if (currentOption === UIConfig.paymentOption.postPay) {
    const { cancelUrl, payCallBackUrl } = self.getPostPayConfig();
    extraParams = `paymentMethod=${UIConfig.paymentOption.postPay}&intermediatePageUrl=${payCallBackUrl.source}&cancelUrl=${cancelUrl.source}`;
  }
  if (isPaymentMethodBlocked && !self.paymentMethodNotBlocked.includes(currentOption)) {
    proceedToCreateOrder = false;
  }
  if (currentOption === UIConfig.paymentOption.adcbTouchPoints) {
    extraParams = `paymentMethod=${currentOption}`;
  } else if (currentOption === UIConfig.paymentOption.moneyCard) {
    extraParams = `paymentMethod=${currentOption}Payment`;
  }
  if (canUseDOM() && proceedToCreateOrder) {
    if (self.getGuestUserStatus()) {
      let newAnnonymouscartData = getAnonymousCart();
      if (newAnnonymouscartData.cart) {
        if (currentOption === UIConfig.paymentOption.moneyCard) {
          newAnnonymouscartData.cart.domain = window.location.origin;
          if (resolvePath(newAnnonymouscartData.cart, 'reservationOwner', '')) {
            newAnnonymouscartData.cart.reservationOwner.TermsAndConditions = self.state.isTncChecked;
          }
        }
        let mainObj = getMainObject(),
          autoLoginStatus = resolvePath(mainObj, 'additionalProperty.enableAutoLogin'),
          autoLoginParam = `isAutoLogin=${autoLoginStatus}`;

        if (autoLoginStatus) {
          if (extraParams) {
            extraParams += `&${autoLoginParam}`;
          } else {
            extraParams = autoLoginParam;
          }
        }

        let bioConsentStatus = sessionStorage.getItem('isMultiParkConsent') === 'true';
        let bioConsentParam = bioConsentStatus ? 'bioConsent=true' : '';

        if (bioConsentStatus) {
          if (extraParams) {
            extraParams += `&${bioConsentParam}`;
          } else {
            extraParams = bioConsentParam;
          }
        }

        OrderService.createAnnonymousOrder(
          self.componentName,
          checkInlineSignup() ? createOrderYasId.url : createAnnonymousOrder.url,
          UIConfig.loader.defaultPreLoaderTarget,
          newAnnonymouscartData,
          extraParams,
          updateCart,
        )
          .then((res) => {
            if (res.data.orderID) {
              setYasId(res.data);
              let mainObj = getMainObject(),
                idToken = resolvePath(res.data, 'id_token'),
                defaultStateVal = getAPIStateParam(),
                signInData = { state: defaultStateVal, id_token: idToken },
                signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
              // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
              if (signInURL && idToken) {
                OrderService.yasIdAutoLogin(
                  'YasIdAutoLogin',
                  signInURL,
                  UIConfig.loader.defaultPreLoaderTarget,
                  signInData,
                )
                  .then((autoLoginRes) => {
                    createAnnonymousOrderBodyOther(self, res);
                  })
                  .catch((err) => {
                    /* do nothing */
                  });
              } else {
                createAnnonymousOrderBodyOther(self, res);
              }
            }
          })
          .catch((response) => {
            window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, false);
            if (checkInlineSignup()) {
              self.isErrorOccured('createOrderYasId', response.error);
            } else {
              self.isErrorOccured('createOrder', response.error);
            }

            self.setOrderId('');
          });
      } else {
        window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, false);
        self.isErrorOccured('createOrder');
        self.setOrderId('');
      }
    } else {
      OrderService.createOrder(
        self.componentName,
        self.isRenewAnnualPass ? self.services.renewPass.url : self.services.createOrder.url,
        UIConfig.loader.defaultPreLoaderTarget,
        self.partnerReference,
        self.dueDate,
        null,
        self.isRenewAnnualPass,
        self.services.getGuestProfile && self.services.getGuestProfile.url,
        extraParams,
        updateCart,
        self.services,
      )
        .then((res) => {
          if (res.data.orderID) {
            if (self.is2StepPayment) {
              if (self.is2StepPaymentYasArena) {
                YALocalStorageSvc.getSetOrderDetails(res.data);
              }
              const userPromoCode = `${res.data.reservationCode}_${getPromoCode()}`;

              setOrderIdpromoCode(userPromoCode);
              removePromoCode();
              window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
                isCreateOrderSuccess: true,
                resCode: res.data.reservationCode,
                orderId: res.data.orderID,
                total: res.data.payfortAmount,
                pointsPayResponse: {
                  ...res?.data?.pointsPayResponse,
                },
                postPayResponse: {
                  ...res?.data?.postPayResponse,
                },
                touchpointResponse: {
                  ...res?.data?.touchpointResponse,
                },
                moneyCardResponse: {
                  ...res?.data?.moneyCardResponse,
                },
              });
            }
            Logging(res, 'Payment_create-order', true, 'Order created');
            self.setOrderId(res.data.orderID);
          }
        })
        .catch((response) => {
          if (self.is2StepPayment) {
            window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, false);
          }
          self.isErrorOccured('createOrder', response.error);
          self.setOrderId('');
        });
    }
  }
};

const createAnnonymousOrderBody = (self, res) => {
  if (self.is2StepPaymentYasArena) {
    YALocalStorageSvc.getSetOrderDetails(res.data);
  }

  const userPromoCode = `${res.data.reservationCode}_${getPromoCode()}`;
  setOrderIdpromoCode(userPromoCode);
  removePromoCode();
  Logging(res, self.componentName || 'Payment_create-order', true, 'Order created');
  self.payData.total = res.data.payfortAmount;
  window.localStorage.setItem(UIConfig.localStoreKeys.payment.payFortData, JSON.stringify(self.payData));
  window.localStorage.setItem('currentLanguage', getCurrentLanguage());

  if (self.is2StepPayment) {
    window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
      isCreateOrderSuccess: true,
      resCode: res.data.reservationCode,
      orderId: res.data.orderID,
      total: res.data.payfortAmount,
      touchpointResponse: {
        ...res?.data?.touchpointResponse,
      },
      moneyCardResponse: {
        ...res?.data?.moneyCardResponse,
      },
    });
  }

  self.setOrderId(res.data.orderID);
  if (!self.is2StepPayment) {
    self.setState(
      {
        srcPayfort:
          self.paymentOptions.payfortUrl + '?oId=' + res.data.orderID + '&res_code=' + res.data.reservationCode,
        disableBookButton: true,
        addDisableCheck: true,
        hidePic: true,
      },
      () => {
        const ele = document.getElementById('payfort-section');
        ele && ele.scrollIntoView({ behavior: 'smooth' });
      },
    );
  }
};

/**
 * setPayfortData function stores totalPrice,currency and emailID for payfort further transaction into
 * local storage in case of Mycart after successful order created and directly on order detail page
 * @return   {Void} function does not return anything.
 */
export const setPayfortData = (self) => {
  const {
    createAnnonymousOrder,
    createOrderYasId,
    renewPass,
    createOrder,
    getGuestProfile,
    updateCart,
    ticketUpgrades,
    checkTermsCtdnApi,
  } = self.services;
  const { currentOption } = self.state;
  let extraParams = null;
  if (currentOption === UIConfig.paymentOption.adcbTouchPoints) {
    extraParams = `paymentMethod=${currentOption}`;
  } else if (currentOption === UIConfig.paymentOption.moneyCard) {
    extraParams = `paymentMethod=${currentOption}Payment`;
  }
  const orderData = JSON.parse(localStorage.getItem('userReservationInfo')) || {};
  const paymentError = parseQueryString(UIConfig.querStringParams.payfort.paymentError);

  if (canUseDOM()) {
    if (self.is2StepPaymentYasArena && !self.yaCanCreateOrder()) {
      return;
    }

    if (!self.is2StepPayment) {
      window.localStorage.setItem(UIConfig.localStoreKeys.payment.payFortData, JSON.stringify(self.payData));
    }
    if (self.getGuestUserStatus()) {
      let newAnnonymouscartData = getAnonymousCart();

      if (newAnnonymouscartData.cart) {
        // For Yas Arena - if Payment is failed we cannot do create order again and
        // we have to use existing orderDetails
        if (self.is2StepPaymentYasArena && self.state.paymentError) {
          const lastOrderData = YALocalStorageSvc.getSetOrderDetails();
          self.payData.total = lastOrderData.payfortAmount;
          window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
            isCreateOrderSuccess: true,
            resCode: lastOrderData.reservationCode,
            orderId: lastOrderData.orderID,
            total: lastOrderData.payfortAmount,
          });
          return;
        }

        let mainObj = getMainObject(),
          autoLoginStatus = resolvePath(mainObj, 'additionalProperty.enableAutoLogin'),
          autoLoginParam = `isAutoLogin=${autoLoginStatus}`;

        if (autoLoginStatus) {
          if (extraParams) {
            extraParams += `&${autoLoginParam}`;
          } else {
            extraParams = autoLoginParam;
          }
        }

        if (isTicketUpgradePayment()) {
          const tenantId = getLoggedInUser().tenantID || resolvePath(getMainObject(), 'tenantID');
          const ticketUpgradesUrl = ticketUpgrades?.url?.replace('{0}', tenantId);
          let ticketUpgradePayload = getLocalStorageByKey(UIConfig.parksTicketUpgrade.ticketUpgradeData);
          const yasId = getLoggedInUser()?.yasId;
          if (yasId && ticketUpgradePayload && ticketUpgradePayload.yasId === null) {
            ticketUpgradePayload.loggedAccount.yasId = yasId;
            ticketUpgradePayload.yasId = yasId;
          }
          if (ticketUpgradePayload?.productItemType === UIConfig.parksTicketUpgrade.annualPassType) {
            const guestDetails = getAnpGuestDetailsForTuJourney();
            if (guestDetails && guestDetails?.length > 0 && ticketUpgradePayload?.items?.length > 0) {
              ticketUpgradePayload.items.map((obj, index) => {
                return (obj.ticketHolderDetails = guestDetails[index]);
              });
            }
          }

          ticketUpgradePayload &&
            OrderService.createTicketUpgradeOrder(
              ticketUpgradesUrl,
              UIConfig.loader.defaultPreLoaderTarget,
              ticketUpgradePayload,
              checkTermsCtdnApi?.url,
            )
              .then((res) => {
                const { sale } = res?.data;
                if (sale?.ak) {
                  //setYasId(res.data);
                  let mainObj = getMainObject(),
                    idToken = resolvePath(res.data, 'id_token'),
                    defaultStateVal = getAPIStateParam(),
                    signInData = { state: defaultStateVal, id_token: idToken },
                    signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
                  // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
                  if (signInURL && idToken) {
                    OrderService.yasIdAutoLogin(
                      'YasIdAutoLogin',
                      signInURL,
                      UIConfig.loader.defaultPreLoaderTarget,
                      signInData,
                    ).then((autoLoginRes) => {
                      //console.log('AutoLogin success response ', autoLoginRes);
                      window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
                        isCreateOrderSuccess: true,
                        resCode: sale?.reservationcode?.ak,
                        orderId: sale?.ak,
                        total: sale?.total?.gross * 100,
                      });
                    });
                  } else {
                    window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
                      isCreateOrderSuccess: true,
                      resCode: sale?.reservationcode?.ak,
                      orderId: sale?.ak,
                      total: sale?.total?.gross * 100,
                    });
                  }
                } else {
                  return backToUpgradePage();
                }
              })
              .catch((response) => {
                window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, false);
                if (checkInlineSignup()) {
                  self.isErrorOccured('createOrderYasId', response.error);
                } else {
                  self.isErrorOccured('createOrder', response.error);
                }

                self.setOrderId('');

                if (isTicketUpgradePayment()) {
                  return backToUpgradePage();
                }
              });
        } else {
          let bioConsentStatus = sessionStorage.getItem('isMultiParkConsent') === 'true';
          let bioConsentParam = bioConsentStatus ? 'bioConsent=true' : '';

          if (bioConsentStatus) {
            if (extraParams) {
              extraParams += `&${bioConsentParam}`;
            } else {
              extraParams = bioConsentParam;
            }
          }

          OrderService.createAnnonymousOrder(
            self.componentName,
            checkInlineSignup() ? createOrderYasId.url : createAnnonymousOrder.url,
            UIConfig.loader.defaultPreLoaderTarget,
            newAnnonymouscartData,
            extraParams,
            updateCart,
          )
            .then((res) => {
              if (res.data.orderID) {
                setYasId(res.data);
                let mainObj = getMainObject(),
                  idToken = resolvePath(res.data, 'id_token'),
                  defaultStateVal = getAPIStateParam(),
                  signInData = { state: defaultStateVal, id_token: idToken },
                  signInURL = resolvePath(mainObj, 'loginInfo.loginRedirectUri');
                // idToken will only be available from API response only when autoLogin settings are enabled in sitecore
                if (signInURL && idToken) {
                  OrderService.yasIdAutoLogin(
                    'YasIdAutoLogin',
                    signInURL,
                    UIConfig.loader.defaultPreLoaderTarget,
                    signInData,
                  )
                    .then((autoLoginRes) => {
                      //console.log('AutoLogin success response ', autoLoginRes);
                      createAnnonymousOrderBody(self, res);
                    })
                    .catch((err) => {
                      /* do nothing */
                    });
                } else {
                  createAnnonymousOrderBody(self, res);
                }
              }
            })
            .catch((response) => {
              window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, false);
              if (checkInlineSignup()) {
                self.isErrorOccured('createOrderYasId', response.error);
              } else {
                self.isErrorOccured('createOrder', response.error);
              }

              self.setOrderId('');
            });
        }
      } else {
        window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, false);
        self.isErrorOccured('createOrder');
        self.setOrderId('');
      }
    } else if (self.orderID) {
      window.localStorage.setItem('currentLanguage', getCurrentLanguage());
      window.localStorage.setItem(UIConfig.localStoreKeys.payment.payFortData, JSON.stringify(self.payData));
      if (self.is2StepPayment) {
        window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
          isCreateOrderSuccess: true,
          resCode: self.reservationCode,
          orderId: self.orderID,
          total: self.total * 100,
        });
      }
    } else if (self.isB2B && paymentError && !isEmpty(orderData)) {
      const { reservationCode, payfortAmount, orderID } = orderData;
      self.payData.reservationCode = reservationCode;
      self.payData.total = payfortAmount;
      window.localStorage.setItem('currentLanguage', getCurrentLanguage());
      window.localStorage.setItem(UIConfig.localStoreKeys.payment.payFortData, JSON.stringify(self.payData));
      window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
        isCreateOrderSuccess: true,
        resCode: reservationCode,
        orderId: orderID,
        total: payfortAmount,
      });
    } else if (self.checkForSeatedOrderId() && self.seatedLastOrderData) {
      window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
        isCreateOrderSuccess: true,
        resCode: self.seatedLastOrderData.reservationCode,
        orderId: self.seatedLastOrderData.orderID,
        total: self.seatedLastOrderData.payfortAmount,
      });
    } else if (resolvePath(getMainObject(), 'tenantID', '').toLowerCase() === UIConfig.YIB2C && self.is2StepPayment) {
      const cartId = getSessionStorage('cartId');

      const configUrl = self.props.data.services.TravelBoxPayfortConfig.url
        .replace('{cartId}', cartId)
        .replace('{language}', 'en');

      fetch(configUrl)
        .then((res) => res.json())
        .then((data) => {
          const isPassengerError = localStorage.getItem('isPassengerError') || false;
          if (data && !isPassengerError) {
            const _isPostBookingJourney = getSessionStorage('addonAddedAfterConfirmation') == 'true' ?? false;

            // if post booking journey i.e upsell of addons then we'll have to append guid after merchant_reference
            const _guid = _isPostBookingJourney ? `_${generateGUID()}` : '';

            window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
              isCreateOrderSuccess: true,
              resCode: data.merchant_reference + _guid,
              orderId: data.merchant_reference + _guid,
              total: parseInt((data.TotalAmount * 100).toFixed(2)),
              merchantExtra: cartId,
              merchantExtra1: data.merchant_extra1,
            });
          }
        });
    } else {
      OrderService.createOrder(
        self.componentName,
        self.isRenewAnnualPass ? renewPass.url : createOrder.url,
        UIConfig.loader.defaultPreLoaderTarget,
        self.partnerReference,
        self.dueDate,
        null,
        self.isRenewAnnualPass,
        getGuestProfile && getGuestProfile.url,
        extraParams,
        updateCart,
        self.services,
      )
        .then((res) => {
          if (res.data.orderID) {
            if (self.state.cart && self.state.cart.saleAk) {
              const tenantId = getLoggedInUser().tenantID || resolvePath(getMainObject(), 'tenantID');
              if ((tenantId || '').toLowerCase() === UIConfig.ymcB2CTenant) {
                seatedLocalStorageSvc.GetSetOrderDetails(res.data);
              }
            }

            if (self.is2StepPaymentYasArena) {
              YALocalStorageSvc.getSetOrderDetails(res.data);
            }

            let userPromoCode = `${res.data.reservationCode}${self.isB2B ? null : `_${getPromoCode()}`}`;

            // only for B2B: Setting the reservationCode
            if (self.isB2B) {
              self.payData.reservationCode = res.data.reservationCode;
            }

            setOrderIdpromoCode(userPromoCode);
            removePromoCode();
            Logging(res, self.componentName || 'Payment_create-order', true, 'Order created');
            self.payData.total = res.data.payfortAmount;
            window.localStorage.setItem(UIConfig.localStoreKeys.payment.payFortData, JSON.stringify(self.payData));
            window.localStorage.setItem('userReservationInfo', JSON.stringify(res.data));
            window.localStorage.setItem('currentLanguage', getCurrentLanguage());
            if (self.is2StepPayment) {
              window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
                isCreateOrderSuccess: true,
                resCode: res.data.reservationCode,
                orderId: res.data.orderID,
                total: res.data.payfortAmount,
                touchpointResponse: {
                  ...res?.data?.touchpointResponse,
                },
                moneyCardResponse: {
                  ...res?.data?.moneyCardResponse,
                },
              });
            }

            self.setOrderId(res.data.orderID);
            if (!self.is2StepPayment) {
              self.setState(
                {
                  srcPayfort:
                    self.paymentOptions.payfortUrl +
                    '?oId=' +
                    res.data.orderID +
                    '&res_code=' +
                    res.data.reservationCode,
                  disableBookButton: true,
                  addDisableCheck: true,
                  hidePic: true,
                },
                () => {
                  const ele = document.getElementById('payfort-section');
                  ele && ele.scrollIntoView({ behavior: 'smooth' });
                },
              );
            }
          }
        })
        .catch((response) => {
          if (self.is2StepPayment) {
            window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, false);
          }
          self.isErrorOccured('createOrder', response.error);
          self.setOrderId('');
          const isError = document.getElementsByClassName('heading-error');
          if (isError && isError.length > 0) {
            const parent = document.querySelector('.c-b2c-cart-header-container');
            parent && parent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
          }
        });
    }
  }
};

export const setPayfortDataFrictionless = (self) => {
  const paymentError = parseQueryString(UIConfig.querStringParams.payfort.paymentError);
  if (canUseDOM()) {
    window.localStorage.setItem(UIConfig.localStoreKeys.payment.payFortData, JSON.stringify(self.payData));
    window.localStorage.setItem('currentLanguage', getCurrentLanguage());
    if (self.is2StepPayment) {
      window.PubSub.publish(UIConfig?.parksTicketUpgrade?.CreateOrderSuccess, {
        isCreateOrderSuccess: true,
        total: 100, //Closed to be hardcoded as it will be fixed amount of 1AED
        touchpointResponse: null,
        moneyCardResponse: null,
      });
    }
  }
};
