import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { DynamicContent } from '..';
import DropDown from '../../drop-down';
import UIConfig from '../../../../common/UIConfig';
import { getMainObject } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

class SelectWithText extends React.Component {
  constructor(props) {
    super(props);
    this.fieldName = this.props.data.name;
    this.mainObj = getMainObject();
    this.imageAssetUrl =
      (this.mainObj && this.mainObj.additionalProperty.flagUrl) || UIConfig.form.selectWithText.imageUrl;
    this.inputValue = this.props.data.value || '';
    this.selectList = this.props.data.options.map((option) => {
      return {
        img: option.image || this.imageAssetUrl + `/${option.description.toLowerCase().replace(' ', '_')}.png`,
        value: option.value,
        text: `${option.text}`,
        description: option.description,
        code: option.code,
      };
    });

    const { value, dropdownValue } = this.props.data;

    // eslint-disable-next-line array-callback-return
    let [selectedIndex] = this.selectList.filter((selectItem, index) => {
      if (selectItem.code === dropdownValue) {
        selectItem.index = index;
        return true;
      }
    });

    selectedIndex = selectedIndex !== undefined ? selectedIndex.index : 0;

    this.inputValue = dropdownValue && value ? value.replace(dropdownValue, '') : value;

    this.state = {
      selectedItem: this.selectList[selectedIndex],
      inputFocusActive: false,
      largeLength: false,
    };
  }

  onSelect = (e, y) => {
    this.setSelectedItem(e.value);
  };

  onInputFocus = (e) => {
    this.setState({
      inputFocusActive: true,
    });
  };

  onValueChange = (value) => {
    let currentFlag = false;
    if (value.length > 5) {
      currentFlag = true;
    }
    if (currentFlag != this.state.largeLength) {
      this.setState({ largeLength: currentFlag });
    }
  };

  onInputBlur = (e, evt, calledFromSetSelect) => {
    const userNumberVal = e.target.value || '';
    this.inputValue = userNumberVal.charAt(0) === '0' ? (this.inputValue = userNumberVal.substring(1)) : e.target.value;
    const eventObj = {
      target: {
        value: this.state.selectedItem.code + this.inputValue,
        name: e.target.name,
        id: e.target.id,
        type: e.target.type,
      },
    };

    const dropdownObj = {
      target: {
        value: this.state.selectedItem.code,
        name: 'dropdownValue',
        type: 'dropdown',
      },
    };

    if (!(calledFromSetSelect && !this.inputValue)) {
      this.props.onBlur(eventObj, dropdownObj);
    }
    this.setState({
      inputFocusActive: false,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.inputValue &&
      this.props.data.value !== nextProps.data.value &&
      nextProps.data.value !== nextProps.data.dropdownValue
    ) {
      this.inputValue = nextProps.data.value;
    }
    if (this.props.data.value !== nextProps.data.value && !nextProps.data.value) {
      this.inputValue = '';
    }
    if (nextProps.data.dropdownValue && this.props.data.dropdownValue !== nextProps.data.dropdownValue) {
      const dropdownValue = nextProps.data.dropdownValue;
      this.inputValue = dropdownValue ? nextProps.data.value.replace(dropdownValue, '') : nextProps.data.value;

      let [selectedIndex] = this.selectList.filter((selectItem, index) => {
        if (selectItem.code === dropdownValue) {
          selectItem.index = index;
          return true;
        }
        return false;
      });

      selectedIndex = selectedIndex !== undefined ? selectedIndex.value : 0;
      this.setState({
        selectedItem: this.selectList.filter((item) => item.value === selectedIndex)[0] || {},
      });
    }

    if (this.props.updatedFieldValue !== nextProps.updatedFieldValue) {
      const { updatedFieldValue } = nextProps;

      if (isNaN(updatedFieldValue)) {
        this.onSelect({ value: updatedFieldValue });
      } else {
        const { dropdownValue } = nextProps.data;

        let [selectedIndex] = this.selectList.filter((selectItem, index) => {
          if (selectItem.code === dropdownValue) {
            selectItem.index = index;
            return true;
          }
          return false;
        });

        selectedIndex = selectedIndex !== undefined ? selectedIndex.value : 0;

        this.inputValue = dropdownValue ? updatedFieldValue.replace(dropdownValue, '') : updatedFieldValue;

        this.onSelect({ value: selectedIndex });
      }
    }
  }

  componentDidMount() {
    const { value, dropdownValue } = this.props.data;
    if (document.getElementById(this.props.data.id))
      document.getElementById(this.props.data.id).value =
        dropdownValue && value ? value.replace(dropdownValue, '') : value;
  }

  componentDidUpdate() {
    const { value, dropdownValue } = this.props.data;
    if (document.getElementById(this.props.data.id))
      document.getElementById(this.props.data.id).value =
        dropdownValue && value ? value.replace(dropdownValue, '') : value;
  }

  setSelectedItem = (value) => {
    this.setState(
      {
        selectedItem: this.selectList.filter((item) => item.value === value)[0] || {},
      },
      () => {
        this.onInputBlur(
          {
            target: {
              value: this.inputValue,
              name: this.fieldName,
              id: this.props.data.id,
              type: 'text',
            },
          },
          undefined,
          true,
        );
      },
    );
  };

  render() {
    try {
      const { data, controlled, is2StepPayment, isTabIndex } = this.props;

      return (
        <div
          className={`${controlled} ${
            this.state.inputFocusActive ? 'focus-active' : ''
          } form-element select-with-text active ${data.hide ? 'hide' : ''} ${
            this.state.largeLength ? 'large-exchange-code' : ''
          }`}
        >
          <DynamicContent
            tagName="label"
            attrs={{ className: 'form-label', htmlFor: data.id }}
            innerHtml={data.label}
          />
          <div className={`form-dropdown ${this.fieldName.toLowerCase()}`}>
            <DropDown
              callBackFunction={this.onSelect}
              listItems={this.selectList}
              defaultValue={this.state.selectedItem.value}
              showSelectedValue="true"
              showSelectedImage={true}
              showItemCode={true}
              firstTimeLoad="true"
              isUpdateDropdownProps={true}
              lazyLoadListImage={true}
              tabIndex={isTabIndex}
              onValueChange={this.onValueChange}
            />
          </div>
          <input
            disabled={!this.state.selectedItem.code}
            className={`form-input body-copy-3 ${data.showErrors ? 'error' : ''} ${
              is2StepPayment && data.isRequired ? 'required--field' : ''
            }`}
            name={this.fieldName}
            id={this.fieldName}
            onBlur={this.onInputBlur}
            onFocus={this.onInputFocus}
            type="tel"
            aria-labelledby={'input_' + data.id}
            aria-invalid={data.showErrors ? true : false}
            tabIndex={isTabIndex || 0}
            placeholder={data.placeholder}
          />
          {data.showErrors ? (
            <Text
              tag="span"
              id={`error_${data.id}`}
              aria-live="assertive"
              aria-relevant="additions removals"
              className={`error-msg body-copy-6 ${is2StepPayment ? 'form-error--field' : ''}`}
              field={{
                value: data.message,
                editable: data.message,
              }}
            />
          ) : null}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'SelectWithText');
    }
  }
}

SelectWithText.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    controlFunc: PropTypes.func,
    resize: PropTypes.bool,
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.string,
    placeholder: PropTypes.string,
  }),
};

export default SelectWithText;
