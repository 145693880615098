/*
 * select-components.js
 * This file contains code for select component which will render select based on schema.
 * @licensor  Miral
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DynamicContent } from '..';
import { logComponentRenderingError } from '../../../../common/logger';

const Select = ({ data, onChange, onBlur, onFocus, controlled, is2StepPayment, isTabIndex, hidePlaceholder }) => {
  try {
    return (
      <div
        className={`${controlled} form-element select-box ${data.active} ${data.hide ? 'hide' : ''} ${
          data.class ? data.class : ''
        }`}
      >
        <DynamicContent tagName="label" attrs={{ className: 'form-label', htmlFor: data.id }} innerHtml={data.label} />
        <div>
          {controlled ? (
            <select
              id={data.id}
              name={data.name}
              value={data.value}
              data-index={data.index}
              aria-required={data.isRequired}
              data-pid={data.produtId}
              data-fieldname={data.fieldName}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              tabIndex={isTabIndex ? isTabIndex : 0}
              className={`form-select body-copy-3 ${data.valid} ${
                is2StepPayment && data.isRequired ? 'required--field' : ''
              }`}
              required={data.isRequired && data.tenantId === 'YIB2C'}
            >
              {!hidePlaceholder && (
                <option value="" disabled={data.tenantId === 'YIB2C'} selected={data.tenantId === 'YIB2C'}>
                  {data.placeholder}
                </option>
              )}
              {data.options
                ? data.options.map((opt, index) => {
                    return (
                      <option key={`${opt.value}_${index}`} value={opt.value} disabled={opt.disabled || false}>
                        {opt.text}
                      </option>
                    );
                  })
                : null}
            </select>
          ) : (
            <select
              id={data.id}
              name={data.name}
              data-defaultvalue={data.value}
              defaultValue={data.val || data.value}
              data-index={data.index}
              aria-required={data.isRequired}
              data-pid={data.produtId}
              data-fieldname={data.fieldName}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              className={`form-select body-copy-3 ${data.valid}`}
            >
              <option value="">{data.placeholder}</option>
              {data.options
                ? data.options.map((opt, index) => {
                    return (
                      <option key={`${opt.value}_${index}`} value={opt.value} disabled={opt.disabled || false}>
                        {decodeURI(opt.text)}
                      </option>
                    );
                  })
                : null}
            </select>
          )}
          <span className="down-arrow"></span>
        </div>
        {data.showErrors ? (
          <span className={`error-msg body-copy-6 ${is2StepPayment ? 'form-error--field' : ''}`}>{data.message}</span>
        ) : null}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'Select');
  }
};

Select.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    controlFunc: PropTypes.func,
    resize: PropTypes.bool,
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.string,
    placeholder: PropTypes.string,
  }),
};

export default Select;
