import PayfortUtility from '../../components/container/payment/payfort/payfort-utility';
import ApiWrapper from '../api-wrapper';
import UIConfig from '../UIConfig';
import {
  blobToPdf,
  canUseDOM,
  checkAnonymousCart,
  getCurrentLanguage,
  getErrorMap,
  getLoggedInUser,
  guestUser,
  guestUserEmail,
  replacePlaceHolder,
  parseQueryString,
  getSessionStorage,
  getLoginUser,
  resolvePath,
  isEmpty,
  setLocalStorage,
  getLocalStorageByKey,
  getEmailIdGTMData,
  getMembershipDetails,
  getCookie,
  getLeisureFacility,
  isLoggedInUser,
  isB2B,
  getPurchaseAllowed,
  getRecipientDetails,
  checkIfGiftVoucherIsInTheCart,
  addLoaderOverlay,
  removeLoaderOverlay,
} from '../utility';
import { Logging } from '../logger.js';

import { ServiceConfig, callBackFunction, preLoaderTarget } from '.';
import { isTokenExpired } from '../session/session-utility';

export class AliPayService {
  static initAliPay = ({ url, apiData, moduleName, preLoaderTarget }) => {
    const config = {
      url: url,
      method: 'POST',
      data: apiData,
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.api(config);
  };

  static transactionAliPay = ({ url, apiData, moduleName, preLoaderTarget }) => {
    const config = {
      url: url,
      method: 'POST',
      data: apiData,
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(config);
  };
}

export class ADCBTouchPointsService {
  static getTouchPointEnquiry = ({ url, apiData, moduleName, preLoaderTarget }) => {
    const config = {
      url: url,
      method: 'POST',
      data: apiData,
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.api(config);
  };
  static getTouchPointOTP = ({ url, apiData, preLoaderTarget }) => {
    const config = {
      url: url,
      method: 'POST',
      data: apiData,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.api(config);
  };
  static getTouchPointRedemption = ({ url, apiData, preLoaderTarget }) => {
    const config = {
      url: url,
      method: 'POST',
      data: apiData,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    const loggedInUser = getLoginUser();
    if (!isEmpty(loggedInUser)) {
      return ApiWrapper.experienceServices(config);
    }
    return ApiWrapper.api(config);
  };
}

export class MoneyCardService {
  static getMoneyCardBalance = ({ url, apiData, moduleName, preLoaderTarget }) => {
    const config = {
      url: url,
      method: 'GET',
      data: apiData,
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.api(config);
  };

  static getMoneyCardFullRedemption = ({ url, apiData, preLoaderTarget }) => {
    const config = {
      url: url,
      method: 'POST',
      data: apiData,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    const loggedInUser = getLoginUser();
    if (!isEmpty(loggedInUser)) {
      return ApiWrapper.experienceServices(config);
    }
    return ApiWrapper.api(config);
  };
}

export class ApplePayService {
  constructor() {
    this.validateMerchant = this.validateMerchant.bind(this);
    this.makePurchase = this.makePurchase.bind(this);
  }
  async validateMerchant(requestObj, url, moduleName = 'ApplePay') {
    let validateMerchantConfig = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
      data: requestObj,
      noCache: true,
    };
    return ApiWrapper.validateMerchantService(validateMerchantConfig, false);
  }

  async makePurchase(requestObj, url, moduleName = 'ApplePay') {
    const newToken = getCookie('idToken');
    let purchaseObject = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        ...(newToken && { Authorization: 'Bearer ' + newToken }),
      },
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
      data: requestObj,
      noCache: true,
    };

    return ApiWrapper.validateMerchantService(purchaseObject, false);
  }
}

export class GPayService {
  constructor() {
    this.makePurchase = this.makePurchase.bind(this);
  }

  async makePurchase(requestObj, url, moduleName = 'GPay') {
    let purchaseObject = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName,
      data: requestObj,
      noCache: true,
      isCompleteErrorResNeeded: true,
    };
    return ApiWrapper.experienceServices(purchaseObject, true);
  }
}

export class ProductService {
  static getRelatedProducts = (moduleName, data, serviceUrl, preLoader, preLoaderTarget) => {
    const config = {
      method: 'POST',
      url: `${serviceUrl}?mode=${data.mode}&tenantid=${ServiceConfig.getTenantId()}&recordCount=${data.recordCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data.payload,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName || 'closeOrder',
    };
    return ApiWrapper.experienceServices(config);
  };
}

export class CartService {
  static getCart(moduleName, serviceUrl) {
    if (canUseDOM()) {
      let promiseResolve,
        promiseReject,
        promiseFnObj,
        promise = new Promise((resolve, reject) => {
          promiseResolve = resolve;
          promiseReject = reject;
        });
      promiseFnObj = {
        resolveFn: promiseResolve,
        rejectFn: promiseReject,
      };
      ServiceConfig.promiseStack.getCartService.push(promiseFnObj);
      if (Object.keys(ServiceConfig.config.cart).length) {
        return Promise.resolve(ServiceConfig.config.cart);
      } else {
        if (!ServiceConfig.callPending.getCartService) {
          let getCartConfig = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            url: `${serviceUrl}/${ServiceConfig.getTenantId()}?r=${Math.random()}`,
            moduleName: moduleName || 'getCart',
            preLoader: true,
            preLoaderTarget: preLoaderTarget,
            noCache: true,
          };

          ServiceConfig.callPending.getCartService = true;

          ApiWrapper.experienceServices(getCartConfig)
            .then((res) => {
              callBackFunction('resolveFn', 'getCartService', 'cart', res);
            })
            .catch((res) => {
              callBackFunction('rejectFn', 'getCartService', 'cart', res);
            });
        }
        return promise;
      }
    }
  }

  static getCartMashup(moduleName, serviceUrl, shouldCache = true) {
    if (canUseDOM()) {
      let promiseResolve,
        promiseReject,
        promiseFnObj,
        promise = new Promise((resolve, reject) => {
          promiseResolve = resolve;
          promiseReject = reject;
        });
      promiseFnObj = {
        resolveFn: promiseResolve,
        rejectFn: promiseReject,
      };
      ServiceConfig.promiseStack.getCartMashupService.push(promiseFnObj);
      if (shouldCache && Object.keys(ServiceConfig.config.cartMashUp).length) {
        return Promise.resolve(ServiceConfig.config.cartMashUp);
      } else {
        if (!ServiceConfig.callPending.getCartMashupService) {
          let getCartConfig = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            url: `${serviceUrl}/${ServiceConfig.getTenantId()}?r=${Math.random()}`,
            moduleName: moduleName || 'getCart',
            preLoader: true,
            preLoaderTarget: preLoaderTarget,
            noCache: true,
          };

          ServiceConfig.callPending.getCartMashupService = true;
          ApiWrapper.experienceServices(getCartConfig)
            .then((res) => {
              callBackFunction('resolveFn', 'getCartMashupService', 'cartMashUp', res);
            })
            .catch((res) => {
              callBackFunction('rejectFn', 'getCartMashupService', 'cartMashUp', res);
            });
        }
        return promise;
      }
    }
  }

  static getCartMashupAnonymous(moduleName, serviceUrl, cart = []) {
    const getCartConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: cart,
      // url: `${serviceUrl}/${ServiceConfig.getTenantId()}` + "?r=" + Math.random(),
      url: `${serviceUrl}/${ServiceConfig.getTenantId()}?r=${Math.random()}`,
      moduleName: moduleName || 'getCart',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      noCache: true,
    };

    return ApiWrapper.experienceServices(getCartConfig, false);
  }

  static getGuestCart = (moduleName, serviceUrl, uidEmail) => {
    let getGuestCartOptions = {
      method: 'GET',
      url: `${serviceUrl}/${ServiceConfig.getTenantId()}?r=${Math.random()}&uid=${encodeURIComponent(uidEmail)}`,
      moduleName: moduleName || 'guestCheckoutForm',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(getGuestCartOptions, false);
  };

  static checkAddonAvailability = (payload, moduleName, serviceUrl) => {
    let updateGuestCartOptions = {
      method: 'POST',
      url: serviceUrl,
      data: payload,
      moduleName: moduleName,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };

    return ApiWrapper.apiGateway(updateGuestCartOptions);
  };

  static updateGuestCart = (cart, moduleName, serviceUrl, hideLoader, idToken) => {
    const seatedCreateOrder = getLocalStorageByKey('seatedCreateOrder');
    if (seatedCreateOrder && resolvePath(cart, 'cart.saleAk', '')) {
      cart.cart.seatedCreateOrder = true;
    }
    const recipientDetails = getRecipientDetails();
    if (recipientDetails && !isEmpty(recipientDetails) && checkIfGiftVoucherIsInTheCart(cart?.cart))
      cart.cart.personalisedGiftObj = recipientDetails;
    else delete cart?.cart?.personalisedGiftObj;
    let updateGuestCartOptions = {
      method: 'POST',
      url: serviceUrl,
      data: cart,
      moduleName: moduleName || 'guestCheckoutForm',
      preLoader: hideLoader ? false : true,
      preLoaderTarget: preLoaderTarget,
      ...(idToken && {
        headers: {
          Authorization: 'Bearer ' + idToken,
        },
      }),
    };
    return ApiWrapper.experienceServices(updateGuestCartOptions, false);
  };

  static updateCartForSeatedJourney = (cart, moduleName, serviceUrl, idToken) => {
    const seatedCreateOrder = getLocalStorageByKey('seatedCreateOrder');
    if (seatedCreateOrder && resolvePath(cart, 'cart.saleAk', '')) {
      cart.cart.seatedCreateOrder = true;
    }
    let updateCartOptions = {
      method: 'POST',
      url: serviceUrl,
      data: cart,
      moduleName: moduleName || 'updateCart',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      headers: {
        Authorization: 'Bearer ' + idToken,
      },
    };
    return ApiWrapper.experienceServices(updateCartOptions, true);
  };

  static updateCart = (cart, moduleName, serviceUrl, maxTickets) => {
    const seatedCreateOrder = getLocalStorageByKey('seatedCreateOrder');
    if (seatedCreateOrder && resolvePath(cart, 'cart.saleAk', '')) {
      cart.cart.seatedCreateOrder = true;
    }
    const recipientDetails = getRecipientDetails();
    if (recipientDetails && !isEmpty(recipientDetails) && checkIfGiftVoucherIsInTheCart(cart?.cart))
      cart.cart.personalisedGiftObj = recipientDetails;
    else delete cart?.cart?.personalisedGiftObj;
    const localstoragemembership = getMembershipDetails('membershipData');
    const ispartnerSegmentPromo = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.partnerSegmentPromo);
    const preappliedCouponCode = localstoragemembership && localstoragemembership.couponCode[0];
    if (preappliedCouponCode && ispartnerSegmentPromo) {
      cart.cart.partnerCode = (localstoragemembership && localstoragemembership.partnerCode) || '';
    } else {
      cart.cart.partnerCode = '';
    }
    // add the isBulkOrder
    if (getPurchaseAllowed() && isB2B() && Number(cart?.cart?.totalQuantity) > Number(maxTickets)) {
      cart.cart.isBulkOrder = true;
    } else {
      cart.cart.isBulkOrder = false;
    }

    let updateCartOptions = {
      method: 'POST',
      url: serviceUrl,
      data: cart,
      moduleName: moduleName || 'updateCart',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(updateCartOptions);
  };

  static deleteCart = (moduleName, serviceUrl) => {
    const deleteCartOptions = {
      method: 'DELETE',
      url: `${serviceUrl}/${ServiceConfig.getTenantId()}`,
      moduleName: moduleName || 'deleteCart',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(deleteCartOptions);
  };

  /**
   * Delete Yas Arena Event.
   * @param    {[string]} moduleName [name of module].
   * @param    {[String]} serviceUrl [delete service url].
   * @param    {[string]} orderId [Id of an event which need to be deleted].
   */
  static deleteYAEvent = (moduleName, serviceUrl, orderId) => {
    const svcUrl = serviceUrl.replace('{orderId}', orderId).replace('{tenantId}', ServiceConfig.getTenantId());
    const deleteCartOptions = {
      method: 'DELETE',
      url: svcUrl,
      moduleName: moduleName || 'deleteYAevent',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(deleteCartOptions);
  };

  /**
   * Yas Arena - Get Details of order booked on VIVA
   */
  static getOrderDetails = (moduleName, serviceUrl, salesId) => {
    let getOrderDetails = {
      method: 'GET',
      url: serviceUrl.replace('{orderId}', salesId).replace('{tenantid}', ServiceConfig.getTenantId()),
      moduleName: moduleName || 'getOrderDetails',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(getOrderDetails, false);
  };
}

export class PayPalServices {
  static getPayPalToken = (moduleName, serviceUrl) => {
    let PayPalTokenOption = {
      method: 'GET',
      url: serviceUrl,
      moduleName: moduleName || 'PayPal',
    };
    if (guestUser() || checkAnonymousCart()) {
      return ApiWrapper.api(PayPalTokenOption);
    } else {
      return ApiWrapper.experienceServices(PayPalTokenOption);
    }
  };
  static sendPayPalTrans = (moduleName, data, serviceUrl) => {
    let PayPalTransaction = {
      method: 'POST',
      url: serviceUrl,
      data: data,
      moduleName: moduleName || 'PayPal',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };

    if (ServiceConfig.getTenantId().toLowerCase() === UIConfig.YIB2C && isLoggedInUser()) {
      PayPalTransaction.headers = {
        Authorization: `Bearer ${getCookie('idToken')}`,
      };
    }

    if (guestUser() || checkAnonymousCart()) {
      return ApiWrapper.api(PayPalTransaction);
    } else {
      return ApiWrapper.apiGateway(PayPalTransaction);
    }
  };

  static sendOrder = (moduleName, data, serviceUrl) => {
    let Order = {
      method: 'POST',
      url: serviceUrl,
      data: data,
      moduleName: moduleName || 'PayPal',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
    };

    if (ServiceConfig.getTenantId().toLowerCase() === UIConfig.YIB2C && isLoggedInUser()) {
      Order.headers = {
        Authorization: `Bearer ${getCookie('idToken')}`,
      };
    }

    if (guestUser() || checkAnonymousCart()) {
      return ApiWrapper.api(Order);
    } else {
      return ApiWrapper.apiGateway(Order);
    }
  };
}

export class OmniService {
  static getPartenerProducts = (moduleName, url, preLoader, preLoaderTarget) => {
    let omniConfig = {
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      url: url.replace('{0}', ServiceConfig.getPartnerId()) + '?tenantid=' + ServiceConfig.getTenantId(),
      moduleName: moduleName,
    };

    return ApiWrapper.omniServices(omniConfig);
  };

  static getOmniOrder = (url, preLoader, preLoaderTarget) => {
    let omniConfig = {
      method: 'GET',
      url: url,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.omniServices(omniConfig);
  };
}

export class DownloadService {
  static downloadFile = (data, serviceUrl) => {
    let downloadFileOptions = {
      url: serviceUrl,
      method: 'POST',
      responseType: 'blob',
      ContentType: 'application/json',
      data: JSON.stringify(data),
    };
    return ApiWrapper.experienceServices(downloadFileOptions);
  };
}

export class PerformanceService {
  static getPerformanceData = (url, from, to, preLoader, preLoaderTarget) => {
    let config = {
      method: 'GET',
      url: `${url}?tenantid=${ServiceConfig.getTenantId()}&fromDate=${from}&toDate=${to}`,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return ApiWrapper.omniServices(config);
  };

  static getPerformacnePrice = (url, data, preLoader, preLoaderTarget) => {
    let config = {
      method: 'POST',
      url: url.replace('{tenantId}', ServiceConfig.getTenantId()),
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return ApiWrapper.omniServices(config);
  };

  static getMultiProductPerformanceData = (url, from, to, data, preLoader, preLoaderTarget) => {
    let config = {
      method: 'POST',
      url: `${url}?tenantid=${ServiceConfig.getTenantId()}&fromDate=${from}&toDate=${to}`,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return ApiWrapper.omniServices(config);
  };

  static getPerformanceCapacityAndProduct = (url, preLoader, preLoaderTarget) => {
    let config = {
      method: 'GET',
      url: `${url}?tenantid=${ServiceConfig.getTenantId()}`,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return ApiWrapper.omniServices(config);
  };

  static searchPerformance = (url, from, to, data, preLoader, preLoaderTarget) => {
    let config = {
      method: 'POST',
      url: url
        .replace('{tenantId}', ServiceConfig.getTenantId())
        .replace('{fromDate}', from)
        .replace('{toDate}', to),
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return ApiWrapper.omniServices(config);
  };

  static findAllPerformanceProducts = async (url, preLoader, performanceId) => {
    let config = {
      method: 'GET',
      url: `${url}?tenantid=${ServiceConfig.getTenantId()}`,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (ServiceConfig.config.performanceProducts[performanceId])
      return await { data: { products: ServiceConfig.config.performanceProducts[performanceId] } };

    return ApiWrapper.omniServices(config).then((response) => {
      ServiceConfig.config.performanceProducts[performanceId] = response.data.products;
      return response;
    });
  };
  static findperformancecapacityproduct = async (url, preLoader, performanceId, fromCartAction) => {
    let config = {
      method: 'GET',
      url: `${url}?tenantid=${ServiceConfig.getTenantId()}`,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (fromCartAction && ServiceConfig.config.performanceProducts[performanceId])
      return await { productInfo: ServiceConfig.config.performanceProducts[performanceId] };

    return ApiWrapper.omniServices(config).then((response) => {
      ServiceConfig.config.performanceProducts[performanceId] = response.data.ProductCapacityDetails.productInfo;
      return response.data.ProductCapacityDetails;
    });
  };
}

const isPaymentTnCChecked = () => {
  if (document.getElementsByClassName('tnc-check') && document.getElementsByClassName('tnc-check').length > 1) {
    const tnCElementsLength = document.getElementsByClassName('tnc-check').length;
    const parent = document.getElementsByClassName('tnc-check');
    const lastChild = parent[tnCElementsLength - 1];
    const lastInput = lastChild.getElementsByTagName('input');
    if (lastInput && lastInput[0]) {
      for (let i = 0; i < tnCElementsLength; i++) {
        parent[i].getElementsByTagName('input')[0].checked = lastInput[0].checked;
      }
      return lastInput[0].checked;
    }
  }
  return document.getElementById('paymentTnC').checked;
};

export class OrderService {
  //To create order
  static createOrder = (
    moduleName,
    serviceUrl,
    preLoaderTarget,
    agentRefNum,
    dueDate,
    isSubmitForApproval = false,
    isRenewAnnualPass,
    guestProfileUrl,
    extraParams,
    updateCart,
    services,
  ) => {
    agentRefNum = agentRefNum || 'default';
    dueDate = dueDate || 'default';
    updateCart = updateCart || {};
    const yasId = getLoggedInUser().yasId;
    const checkTandCUrl = `${services.checkTermsCtdnApi.url}?yasId=${yasId}`;
    const paymentTnC = isPaymentTnCChecked();
    const paymentInfo = window.localStorage.payment_info && JSON.parse(window.localStorage.payment_info);
    const termsNctdnParams = {
      termsNConditionsIO: [
        {
          accepted: paymentTnC ? 'Y' : 'N',
          channel: 'Email',
          condition: paymentInfo?.cmsData?.paymentOptions?.tncCondition || '',
          leisureFacility: getLeisureFacility() || '',
          type: paymentInfo.cmsData.paymentOptions.tncType || '',
          version: 'V1',
        },
      ],
    };
    var url = isRenewAnnualPass
      ? `${serviceUrl}?tenantid=${ServiceConfig.getTenantId()}`
      : `${serviceUrl}/${ServiceConfig.getTenantId()}?agentRefNum=${agentRefNum}&dueDate=${dueDate}&isSubmitForApproval=${isSubmitForApproval}`;
    const cartData = JSON.parse(getSessionStorage(UIConfig.localStoreKeys.purchaseJourney.cartData));

    let bioConsentStatus = sessionStorage.getItem('isMultiParkConsent') === 'true';
    let bioConsentParam = bioConsentStatus ? 'bioConsent=true' : '';

    if (bioConsentStatus) {
      if (url) {
        url += `&${bioConsentParam}`;
      }
    }

    if (extraParams) {
      url += `&${extraParams}`;
    }
    let id_token = getCookie('idToken');
    let orderConfig = {
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        ...(id_token && {
          Authorization: `Bearer ${id_token}`,
        }),
      },
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName || 'createOrder',
    };
    if (isRenewAnnualPass) {
      const getGuestInfoConfig = {
        url: replacePlaceHolder(
          '{yasId}',
          yasId,
          guestProfileUrl, // 'https://fe-sitnew-apimgmt-service.azure-api.net/v3.0/guests/{yasId}',
        ),
        method: 'GET',
        preLoader: true,
        preLoaderTarget: preLoaderTarget,
        moduleName: moduleName,
      };
      if (paymentTnC) {
        ApiWrapper.platformService({
          url: replacePlaceHolder('{emailId}', getEmailIdGTMData(), checkTandCUrl),
          method: 'POST',
          tokenRequired: false,
          data: termsNctdnParams,
          moduleName: 'Payment-Component',
        })
          .then(function(response) {
            return new Promise((resolve) => {
              resolve(response);
            });
          })
          .catch((err) => {
            Logging(err, 'checkT&C API response failed', false, '');
          });
      }
      return new Promise((resolve, reject) => {
        ApiWrapper.experienceServices(getGuestInfoConfig).then((response) => {
          const {
            firstName,
            lastName,
            email,
            yasId,
            mobileNum: mobileNumber,
            nationalityAlphaCode: nationality,
            countryAlphaCode: countryOfResidence,
          } = response.data;
          const loggedAccount = { firstName, lastName, email, yasId, mobileNumber, nationality, countryOfResidence };
          const payload = JSON.parse(getSessionStorage(UIConfig.payloadForRenew));
          const loggedInUser = getLoginUser();
          var data = {
            MATCHITEMLIST: payload,
            YasId: loggedInUser.yasId,
            loggedAccount: loggedAccount,
          };
          orderConfig.data = data;
          orderConfig.method = 'POST';
          resolve(ApiWrapper.experienceServices(orderConfig));
        });
      });
    } else {
      if (paymentTnC) {
        ApiWrapper.platformService({
          url: replacePlaceHolder('{emailId}', getEmailIdGTMData(), checkTandCUrl),
          method: 'POST',
          tokenRequired: false,
          data: termsNctdnParams,
          moduleName: 'Payment-Component',
        })
          .then(function(response) {
            return Promise.resolve(response);
          })
          .catch((err) => {
            Logging(err, 'create Order API response failed', false, '');
          });
      }
      return new Promise((resolve, reject) => {
        ApiWrapper.experienceServices(orderConfig)
          .then((res) => {
            if (resolvePath(cartData, 'cart.saleAk', '') && resolvePath(updateCart, 'url', '')) {
              setLocalStorage('seatedCreateOrder', true);
              CartService.updateCart(
                {
                  cart: { ...cartData.cart, seatedCreateOrder: true },
                },
                'seated create Order',
                updateCart.url,
              );
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            Logging(err, 'create Order API response failed', false, '');
          });
      });
    }
  };
  //To create order Annonymous/Guest User   --data, url, preLoader, preLoaderTarget
  static createAnnonymousOrder = (moduleName, serviceUrl, preLoaderTarget, data, extraParams, updateCart = {}) => {
    let url = `${serviceUrl}/${ServiceConfig.getTenantId()}`;
    if (extraParams) {
      url += `?${extraParams}`;
    }
    let orderConfig = {
      method: 'POST',
      url: url,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
      moduleName: moduleName || 'createOrder',
    };
    // For seated journey tickets, calling update cart with additional
    // property seatedCreateOrder so that backend will not make call to VIVA twice for same orderID.
    return new Promise((resolve, reject) => {
      ApiWrapper.api(orderConfig)
        .then((res) => {
          if (resolvePath(data, 'cart.saleAk', '') && resolvePath(updateCart, 'url', '')) {
            setLocalStorage('seatedCreateOrder', true);
            CartService.updateCartForSeatedJourney(
              {
                cart: { ...data.cart, seatedCreateOrder: true },
              },
              'seated create Order',
              updateCart.url,
              res.data.id_token,
            );
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          Logging(err, 'create Order Yas ID API response failed', false, '');
        });
    });
  };

  static createTicketUpgradeOrder = (serviceUrl, preLoaderTarget, data, checkTermsCtdnApiUrl) => {
    if (isLoggedInUser()) {
      const yasId = getLoggedInUser()?.yasId;
      const checkTandCUrl = `${checkTermsCtdnApiUrl}?yasId=${yasId}`;
      const paymentTnC = isPaymentTnCChecked();
      const paymentInfo = getLocalStorageByKey(UIConfig.localStoreKeys.payment.paymentInfo);
      const termsNctdnParams = {
        termsNConditionsIO: [
          {
            accepted: paymentTnC ? 'Y' : 'N',
            channel: 'Email',
            condition: paymentInfo?.cmsData?.paymentOptions?.tncCondition || '',
            leisureFacility: getLeisureFacility() || '',
            type: paymentInfo.cmsData.paymentOptions.tncType || '',
            version: 'V1',
          },
        ],
      };

      if (paymentTnC) {
        ApiWrapper.platformService({
          url: replacePlaceHolder('{emailId}', getEmailIdGTMData(), checkTandCUrl),
          method: 'POST',
          tokenRequired: false,
          data: termsNctdnParams,
          moduleName: 'Payment-Component',
        })
          .then(function(response) {
            return Promise.resolve(response);
          })
          .catch((err) => {
            Logging(err, 'create ticket upgrade Order Yas ID API response failed', false, '');
          });
      }
    }

    let config = {
      url: serviceUrl,
      method: 'PUT',
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        ...(isLoggedInUser() && { Authorization: `Bearer ${getCookie('idToken')}` }),
      },
    };
    return ApiWrapper.omniServices(config);
  };

  static yasIdAutoLogin = (moduleName, serviceUrl, preLoaderTarget, data, extraParams) => {
    let url = `${serviceUrl}`;
    if (extraParams) {
      url += `?${extraParams}`;
    }
    let orderConfig = {
      method: 'POST',
      url: url,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
      moduleName: moduleName,
    };

    return ApiWrapper.api(orderConfig);
  };

  //To close order
  static closeOrder = (moduleName, data, serviceUrl, preLoaderTarget, notEncrypted) => {
    const encryptionParam = notEncrypted ? '&isEncrypted=false' : '';
    let orderConfig = {
      method: 'PUT',
      url: serviceUrl + '/' + data.orderId + '?tenantid=' + ServiceConfig.getTenantId() + encryptionParam,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
      preLoader: true,
      preLoaderTarget: preLoaderTarget,
      moduleName: moduleName || 'closeOrder',
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static getOrders = (url, preLoader, preLoaderTarget) => {
    let orderConfig = {
      url: url,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static getDataForSettlement = (url, preLoader, preLoaderTarget, data) => {
    let orderConfig = {
      url: url,
      method: 'POST',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      data: data,
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static deleteOrder = (url, preLoader, preLoaderTarget) => {
    let orderConfig = {
      url: url,
      method: 'DELETE',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static getOrderGuestDetails = (url, preLoader, preLoaderTarget) => {
    let orderConfig = {
      url: url,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static getB2BOrder = (url, preLoader, preLoaderTarget, notEncrypted) => {
    const encryptionParameter = notEncrypted ? '?isEncrypted=false' : '';
    let orderConfig = {
      url: url + encryptionParameter,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static getB2cOrder = (url, preLoader, preLoaderTarget, isEncrypted, separator = '?') => {
    const encryptionParameter = isEncrypted ? '&isEncrypted=false' : '';
    const orderConfig = {
      url: url + separator + 'tenantid=' + ServiceConfig.getTenantId() + encryptionParameter,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    if (checkAnonymousCart()) {
      return ApiWrapper.api(orderConfig);
    } else {
      return ApiWrapper.experienceServices(orderConfig);
    }
  };
  static B2cOrderAnonymous = (serviceUrl, data, preLoaderTarget) => {
    let orderConfig = {
      method: 'POST',
      url: `${serviceUrl}?tenantid=${ServiceConfig.getTenantId()}`,
      preLoaderTarget: preLoaderTarget,
      data: data,
    };
    return ApiWrapper.api(orderConfig);
  };

  static getB2cOrdersList = (url, preLoader, preLoaderTarget, timeOut) => {
    const expried = isTokenExpired();
    if (expried) return new Promise((_, reject) => reject('Token expired'));
    const orderUrl = url.replace('{yasid}', getLoggedInUser().yasId);
    const orderConfig = {
      url: orderUrl,
      method: 'GET',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      timeout: timeOut,
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static declineOrder = (data, url, preLoader, preLoaderTarget) => {
    let orderConfig = {
      url: url,
      method: 'POST',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      data: data,
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static downloadOrder = (data, url, responseType, preLoader, preLoaderTarget, notEncrypted) => {
    const encryptionParameter = notEncrypted ? '?isEncrypted=false' : '';
    let orderConfig = {
      url: url + encryptionParameter,
      method: 'POST',
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      data: data,
      responseType: responseType,
      timeout: 600000, // increasing timeout for bulk orders which is the case in b2b
      headers: {
        token: ServiceConfig.getUserToken(),
      },
    };

    if (guestUserEmail()) {
      return ApiWrapper.api(orderConfig);
    } else {
      return ApiWrapper.experienceServices(orderConfig);
    }
  };

  static downloadOrderTickets = (config) => {
    const downloadOrderPromise = new Promise((resolve, reject) => {
      const language = getCurrentLanguage();
      const refQueryString = parseQueryString('ref');
      const refQueryStringValue = refQueryString ? refQueryString : '';
      let data = {
        orderId: config?.orderId,
        language: getCurrentLanguage(),
        tenantId: getLoggedInUser()?.tenantID,
        promoCode: config?.promoCode,
        paymentMode: refQueryStringValue,
      };

      if (config.isOneTicket) {
        data.isOneTicket = config?.isOneTicket;
      }

      if (checkAnonymousCart() || guestUserEmail()) {
        data.signature = PayfortUtility?.getPayFortSignature(data, '');
      }
      if (localStorage.getItem(UIConfig.localStoreKeys.emailInvoiceTemplateId)) {
        data = { ...data, ...JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.emailInvoiceTemplateId)) };
      }
      const downloadTicket = OrderService.downloadOrder(
        JSON.stringify(data),
        config.service.url,
        'blob',
        true,
        config.preLoaderTarget || UIConfig.loader.defaultPreLoaderTarget,
      );
      downloadTicket
        .then((response) => {
          const fileName = (config.fileName || config.orderId) + '_' + language + '.pdf';
          blobToPdf(response, fileName);
          resolve({ success: 'Download successful' });
        })
        .catch((response) => {
          const errorObj = getErrorMap(
            'eTicketDownload',
            config.service.errors,
            false,
            response.error,
            config.errorData,
          );
          reject({ error: errorObj });
        });
    });

    return downloadOrderPromise;
  };

  static getTicketUpgradeDetails = (url, data, preLoader, preLoaderTarget) => {
    let shouldUpdateConfig = true;
    if (data?.userId) {
      shouldUpdateConfig = false;
    }
    let ticketUpgradeConfig = {
      method: 'POST',
      url: url,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      data: JSON.stringify(data),
    };
    return ApiWrapper.experienceServices(ticketUpgradeConfig, shouldUpdateConfig);
  };

  static getGuestAccountDetails = (url, data, preLoader, preLoaderTarget) => {
    let config = {
      method: 'POST',
      url: url,
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
      data: JSON.stringify(data),
    };
    return ApiWrapper.experienceServices(config);
  };

  static getTryUpgradeDetails = (url, data, preLoader, preLoaderTarget) => {
    let tryUpgradeConfig = {
      url: url,
      method: 'PUT',
      data: JSON.stringify(data),
      preLoader: preLoader,
      preLoaderTarget: preLoaderTarget,
    };
    return ApiWrapper.experienceServices(tryUpgradeConfig, false);
  };

  // B2B only : to update the order payment status: failure
  static updatePaymentStatusOrder = (data, url) => {
    let orderConfig = {
      url: url,
      method: 'PUT',
      data: data,
    };
    return ApiWrapper.experienceServices(orderConfig);
  };

  static getShippedToAddress = (data, url, preLoaderTarget) => {
    let getShippedToAddressConfig = {
      url: url,
      method: 'POST',
      preLoaderTarget: preLoaderTarget,
      data: data,
    };
    return ApiWrapper.experienceServices(getShippedToAddressConfig);
  };
}
