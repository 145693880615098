import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import PassComponent from '../../../presentation/b2c-purchase-journey/annual-pass/pass-component';
import TierComponent from '../../../presentation/b2c-purchase-journey/annual-pass/tier-component';
import VisitorsCountSelector from '../visitors-count-selector/visitors-count-selector-component';
import GTMData from '../gtm-data';
import {
  toTwoDecimalPlaces,
  getClosestByClass,
  focusHandlerOnAccordion,
  momentTimezone,
  isEmpty,
  detectViewPort,
  checkTenant,
  detectMobile,
  isArabicMode,
  getLoggedInUser,
  getYasIdGTMData,
  checkParksTenants,
  isMatchTenant,
  updateGA4LocalStorage,
} from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import { AnchorLink, Carousel } from '../../../presentation/base';
import { getCustomCarouselSettings } from '../../editorial-grid/helpers';

const GTMDataOnClickCTA = (data) => {
  if (isMatchTenant(UIConfig.tenants.fwad)) {
    GTMData.push(UIConfig.ga4Constants.CLICK_CTA, {
      name: data.name,
      elementText: data.elementText,
      category: data.category,
    });
  }
};
export default class AnnualPassComponent extends Component {
  constructor(props) {
    super(props);
    this.tktVal = props.tktVal;
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
    this.annualPassGridVariant = props.annualPassGridVariant;
    this.annualPassObj = props.data.controls.annualPass.options;
    this.paramObj = this.annualPassObj.filter((option) => option.ticketSelector.ticket.coveoValue === this.tktVal);
    const selectedTicket = this.getSelectedTicket(),
      ticketCount = {
        primaryCounterCount: {},
        additionalCounterCount: {},
      };

    const defaultSelectedTicketObj = this.annualPassObj.filter(
        (option) => option.ticketSelector.ticket.coveoValue === selectedTicket,
      )[0],
      showTierSelector =
        defaultSelectedTicketObj.tierSelector &&
        defaultSelectedTicketObj.tierSelector.tiers &&
        defaultSelectedTicketObj.tierSelector.tiers.length > 0
          ? true
          : false,
      showInfoSelector =
        defaultSelectedTicketObj.additionalInfoSelector &&
        defaultSelectedTicketObj.additionalInfoSelector.additionalInfo &&
        defaultSelectedTicketObj.additionalInfoSelector.additionalInfo.length > 0
          ? true
          : false,
      selectedTier = showTierSelector ? defaultSelectedTicketObj.tierSelector.tiers[0].coveoValue : null,
      selectedInfo = undefined;

    defaultSelectedTicketObj.visitorSelector &&
      defaultSelectedTicketObj.visitorSelector.options.length > 0 &&
      defaultSelectedTicketObj.visitorSelector.options.forEach((option) => {
        const optionCategory = option.coveoValue ? option.coveoValue : option.name;
        ticketCount.primaryCounterCount[optionCategory] = option.defaultQuantity;
      });

    this.isAdditionalCounterPresent(defaultSelectedTicketObj) &&
      defaultSelectedTicketObj.additionalCounter.options.forEach((option) => {
        const optionCategory = option.coveoValue ? option.coveoValue : option.name;
        ticketCount.additionalCounterCount[optionCategory] = option.defaultQuantity;
      });

    const disableAddToCart =
      Object.values(ticketCount.primaryCounterCount).reduce((sum, value) => (sum += value), 0) > 0 ? true : false;
    this.tierSelectorOptions = this.annualPassObj.filter(
      (option) => option.tierSelector && option.tierSelector.tiers && option.tierSelector.tiers.length > 0,
    );
    this.infoSelectorOptions = this.annualPassObj.filter(
      (option) =>
        option.additionalInfoSelector &&
        option.additionalInfoSelector.additionalInfo &&
        option.additionalInfoSelector.additionalInfo.length > 0,
    );
    this.adInfoKey = showInfoSelector
      ? defaultSelectedTicketObj.additionalInfoSelector.additionalInfo[0].coveoValue
      : null;
    this.selectedTicket = defaultSelectedTicketObj;
    this.ticketSelectorCoveoKey = defaultSelectedTicketObj.ticketSelector.ticket.coveoKey;
    this.visitorSelectorCoveoKey = defaultSelectedTicketObj.visitorSelector.coveoKey;
    this.tierSelectorCoveoKey =
      this.tierSelectorOptions.length > 0 ? this.tierSelectorOptions[0].tierSelector.tiers[0].coveoKey : null;
    this.infoSelectorCoveoKey =
      this.infoSelectorOptions.length > 0
        ? this.infoSelectorOptions[0].additionalInfoSelector.additionalInfo[0].coveoKey
        : null;
    this.finalSelectedProducts = [];
    this.maxCount = defaultSelectedTicketObj.visitorSelector.maxCount;
    this.annualPassObj.forEach((option) => {
      if (option.additionalCounter) {
        this.additionalCounterCoveoKey = option.additionalCounter.coveoKey;
        return;
      }
    });
    this.isFirstIncrement = true;
    this.sliderRef = {};
    this.checkoutCTA = this.props.data?.continueCheckoutCTA;
    this.isMobile = detectMobile();
    this.annualPassList = [];
    this.coveoMapdList = [];

    const selectedTicketData = {
      selectedTicket,
      selectedTier,
      ticketCount,
      selectedInfo,
    };

    this.state = {
      showPassesCounter: true,
      counterComponentState: 'default',
      passComponentState: this.tktVal || this.props.hightLightFirstTicket ? 'default' : 'noSelect',
      tierComponentState: 'default',
      infoComponentState: 'default',
      totalCalculatedAmount: '',
      showTierSelector,
      showInfoSelector,
      disableAddToCart,
      selectedTicketData,
    };
  }

  componentDidMount() {
    if (this.annualPassGridVariant) {
      this.manageTabIndex(0);
    }
    let dataType = this.props.data.type;
    if (this.isSwad && this.props.isCrossSellRecommendationEnable) {
      dataType = UIConfig.b2c.purchaseJourney.annualPass;
    }
    this.props.createProductStore(dataType).then((response) => {
      let newResponse = response;
      if (this.isSwad && this.props.isCrossSellRecommendationEnable) {
        newResponse.products = this.props.searchProductList;
      }
      this.responseObj = newResponse;
      this.updateState();

      if (checkParksTenants()) {
        try {
          const resProducts =
            this.responseObj && this.props.createMappedData(this.responseObj.products, this.props.coveoMappingList);
          const keyList = GTMData.getKeyList(true);
          const { tenantName } = getLoggedInUser();
          this.annualPassList = this.annualPassList.filter(
            (value, index, self) =>
              index === self.findIndex((item) => item.ticket.coveoValue === value.ticket.coveoValue),
          );
          let product = [];
          let currency = '';
          const category =
            this.props.data &&
            this.props.data.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
          this.annualPassList.forEach((item, index) => {
            const { productId, title, prices, classType } = item.ticket;
            const prodData = (resProducts?.length && resProducts.find((prd) => prd.productId === productId)) || {};
            const prodPrice = prices?.[0];
            currency = prodPrice?.currency || UIConfig.currencyArabic;
            item.gaTitle = prodData?.gaTitle;
            this.coveoMapdList.push(prodData);
            const { gaTitle, gaCategory, experienceCatgory, classTypeName, itemType } = prodData;
            updateGA4LocalStorage({
              productId,
              itemType,
              classTypeName,
              classType,
              gaTitle,
              gaCategory,
              experienceCatgory,
              index: index + 1,
            });
            product.push({
              [keyList.id]: productId,
              [keyList.name]: prodData?.gaTitle || title,
              [keyList.category]: prodData?.gaCategory || undefined,
              // [keyList.nosadult]: 0,
              // [keyList.nosjunior]: 0,
              affiliation: tenantName,
              [keyList.itemVariant]: classType || classTypeName || undefined,
              [keyList.itemListId]: this.props.data.gaTabTitle,
              [keyList.itemListName]: category,
              [keyList.price]: prodPrice?.price,
              index: index + 1,
            });
          });
          window.dataLayer.push({
            event: keyList.viewItemList,
            ecommerce: {
              currency,
              items: product,
            },
            my_pass_id: getYasIdGTMData(),
          });
        } catch (error) {
          console.log('GA Error: ', error);
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.resetOtherSelectedTicketForCrossSell !== prevProps.resetOtherSelectedTicketForCrossSell &&
      this.props.resetOtherSelectedTicketForCrossSell &&
      this.props.isCrossSellRecommendationEnable
    ) {
      this.setState({
        passComponentState: 'noSelect',
      });
    }
  }

  getSelectedTicket = () =>
    this.paramObj.length ? this.tktVal : this.annualPassObj[0].ticketSelector.ticket.coveoValue;

  isAdditionalCounterPresent = (defaultSelectedTicketObj) =>
    defaultSelectedTicketObj.additionalCounter && defaultSelectedTicketObj.additionalCounter.options.length > 0;

  getProductUpdatedCoveo = (selectedProd, cat) => {
    selectedProd = this.props.createMappedData(selectedProd)[0];
    return this.updateProductForCoveo(selectedProd, cat);
  };

  deleteProduct = () => {
    const cat = Object.keys(this.state.selectedTicketData.ticketCount.primaryCounterCount)[0];
    let selectedProd = this.getSelectedProduct(cat);
    const coverSelectedProd = this.getProductUpdatedCoveo(selectedProd, cat);
    this.finalSelectedProducts.push(coverSelectedProd);
    const existingProd = this.checkProdCartExist(coverSelectedProd);
    if (!isEmpty(existingProd)) {
      this.updateCart(existingProd, 'delete');
    }
  };

  updateState = (elem) => {
    let counterSum = 0;
    for (const counter in this.state.selectedTicketData.ticketCount) {
      if (this.state.selectedTicketData.ticketCount[counter]) {
        counterSum += Object.values(this.state.selectedTicketData.ticketCount[counter]).reduce(
          (sum, value) => (sum += value),
          0,
        );
      }
    }
    const disableButton =
      counterSum > 0 &&
      counterSum < parseInt(this.selectedTicket.visitorSelector.maxCount, 10) + 1 &&
      (this.getComponentState() || this.annualPassGridVariant);
    if (disableButton) {
      let totalCalculatedAmount = 0;
      this.finalSelectedProducts = [];
      if (this.responseObj) totalCalculatedAmount = this.addProductAndCalculateTotal(totalCalculatedAmount);
      totalCalculatedAmount !== '' &&
        this.setState(
          {
            disableAddToCart: !disableButton,
            totalCalculatedAmount: toTwoDecimalPlaces(totalCalculatedAmount),
          },
          () => {
            if (this.annualPassGridVariant && !this.isFirstIncrement) {
              const existingProd = this.checkProdCartExist(this.finalSelectedProducts[0]);
              if (!isEmpty(existingProd)) {
                this.updateCart(existingProd);
              } else {
                this.addToCart();
              }
            }
          },
        );
    } else {
      this.setState({ disableAddToCart: !disableButton, totalCalculatedAmount: '' }, () => {
        if (this.annualPassGridVariant && !this.isFirstIncrement) {
          this.deleteProduct();
        }
      });
    }
    if (elem) {
      setTimeout(() => {
        let parent = getClosestByClass(elem, 'c-pass');
        if (this.isSwad) {
          parent = getClosestByClass(elem, 'pass-component');
        }
        parent && parent.scrollIntoView({ behaviour: 'instant', block: 'center' });
      }, 0);
    } else if (this.tktVal) {
      setTimeout(() => {
        const selector = '.c-pass.ticket-box.pass.selected';
        let parent = document.querySelector(selector);
        if (this.isSwad) {
          parent = getClosestByClass(document.querySelector(selector), 'pass-component');
        }
        if (detectViewPort() !== 'mobile') {
          parent && parent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        } else {
          parent && parent.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      }, 0);
      setTimeout(() => {
        if (detectViewPort() === 'mobile') {
          window.scrollBy(0, -10);
        }
      }, 500);
    }
  };

  getComponentState = () => {
    return (
      this.state.counterComponentState !== 'noSelect' &&
      this.state.tierComponentState !== 'noSelect' &&
      this.state.passComponentState !== 'noSelect' &&
      this.state.infoComponentState !== 'noSelect'
    );
  };

  getSelectedProduct = (cat) => {
    return this.responseObj.getFilteredProductsFromKeyVal(this.getQueryParamsObj(cat));
  };

  addProductAndCalculateTotal = (totalCalculatedAmount) => {
    const { selectedTicketData } = this.state;
    for (const category in selectedTicketData.ticketCount.primaryCounterCount) {
      if (selectedTicketData.ticketCount.primaryCounterCount[category] > 0) {
        let selectedProduct = this.getSelectedProduct(category);
        if (selectedProduct.length > 1 && this.adInfoKey) {
          selectedProduct = this.getFilterProd(selectedProduct);
        }
        if (selectedProduct.length > 0) {
          selectedProduct = this.props.createMappedData(selectedProduct)[0];
          this.finalSelectedProducts.push(this.updateProductForCoveo(selectedProduct, category));
          totalCalculatedAmount +=
            parseFloat(selectedProduct.gross, 10) * selectedTicketData.ticketCount.primaryCounterCount[category];
        } else {
          if (!this.annualPassGridVariant) {
            totalCalculatedAmount = '';
            this.setState({ disableAddToCart: true, totalCalculatedAmount });
          }
        }
      }
    }
    if (
      selectedTicketData.ticketCount &&
      selectedTicketData.ticketCount.additionalCounterCount &&
      !isEmpty(selectedTicketData.ticketCount.additionalCounterCount)
    ) {
      const count = Object.values(selectedTicketData.ticketCount.additionalCounterCount)[0];
      const additionalProduct = this.getAdditionalProduct();
      if (count > 0 && additionalProduct && additionalProduct.gross) {
        this.finalSelectedProducts.push(additionalProduct);
        totalCalculatedAmount += parseFloat(additionalProduct.gross, 10) * count;
      }
    }
    return totalCalculatedAmount;
  };

  getFilterProd = (products) => {
    const product = products.filter(
      (prod) => !prod.hasOwnProperty(this.infoSelectorCoveoKey) || prod[this.infoSelectorCoveoKey] !== this.adInfoKey,
    );
    return product;
  };

  getQueryParamsObj = (category) => {
    let selectedTicket = {
      [this.ticketSelectorCoveoKey]: this.state.selectedTicketData.selectedTicket,
      [this.visitorSelectorCoveoKey]: category,
    };

    if (this.tierSelectorCoveoKey && this.state.selectedTicketData.selectedTier)
      selectedTicket = { ...selectedTicket, [this.tierSelectorCoveoKey]: this.state.selectedTicketData.selectedTier };

    if (this.infoSelectorCoveoKey && this.state.selectedTicketData.selectedInfo) {
      selectedTicket = { ...selectedTicket, [this.infoSelectorCoveoKey]: this.state.selectedTicketData.selectedInfo };
    }

    return selectedTicket;
  };

  getAdditionalProduct = () => {
    let queryObj = {
      [this.selectedTicket.additionalCounter.coveoKey]: this.selectedTicket.additionalCounter.options[0].coveoValue,
    };
    if (this.tierSelectorCoveoKey && this.state.selectedTicketData.selectedTier)
      queryObj = { ...queryObj, [this.tierSelectorCoveoKey]: this.state.selectedTicketData.selectedTier };
    if (this.infoSelectorCoveoKey && this.state.selectedTicketData.selectedInfo)
      queryObj = { ...queryObj, [this.infoSelectorCoveoKey]: this.state.selectedTicketData.selectedInfo };

    let selectedProduct = this.responseObj.getFilteredProductsFromKeyVal(queryObj);
    if (selectedProduct.length > 0) {
      selectedProduct = this.props.createMappedData(selectedProduct)[0];
      selectedProduct.quantity = Object.values(this.state.selectedTicketData.ticketCount.additionalCounterCount)[0];
      selectedProduct.otherReason = this.selectedTicket.additionalCounter.options[0].maxCount;
      selectedProduct.groupType = UIConfig.b2c.purchaseJourney.additionalProductCode;
      selectedProduct.category = this.finalSelectedProducts[0].category;
      selectedProduct.minCount = this.selectedTicket.additionalCounter.options[0].minimumQuantity;
      selectedProduct.maxCount = this.selectedTicket.additionalCounter.options[0].maxCount;
      return selectedProduct;
    }
  };

  updateProductForCoveo = (product, category) => {
    product.isFlexible = true;
    product.fromDate = momentTimezone()
      .startOf('day')
      .format(UIConfig.calendar.dateFormat);
    product.quantity = this.state.selectedTicketData.ticketCount.primaryCounterCount[category];
    product.rangeInMonths = 12;
    product.minCount = this.selectedTicket.visitorSelector.options[0].minimumQuantity;
    product.maxCount = this.selectedTicket.visitorSelector.options[0].maximumQuantity;
    return product;
  };

  handleTicketSelection = (selectedTicket, elem) => {
    this.selectedTicket = this.props.data.controls.annualPass.options.filter(
      (option) => option.ticketSelector.ticket.coveoValue === selectedTicket,
    )[0];
    const showTierSelector =
        this.selectedTicket.tierSelector && this.selectedTicket.tierSelector.tiers.length > 0 ? true : false,
      showInfoSelector =
        this.selectedTicket.additionalInfoSelector &&
        this.selectedTicket.additionalInfoSelector.additionalInfo &&
        this.selectedTicket.additionalInfoSelector.additionalInfo.length > 0
          ? true
          : false,
      selectedTier = showTierSelector ? this.selectedTicket.tierSelector.tiers[0].coveoValue : '',
      selectedInfo = undefined;

    if (elem === 'return-ticket') {
      return {
        passComponentState: 'default',
        selectedTicketData: { ...this.state.selectedTicketData, selectedTicket, selectedTier, selectedInfo },
        counterComponentState: 'noSelect',
        showPassesCounter: true,
        showTierSelector,
      };
    }

    this.setState(
      {
        showPassesCounter: false,
        showTierSelector: false,
        showInfoSelector,
      },
      () => {
        this.setState(
          {
            passComponentState: 'default',
            selectedTicketData: { ...this.state.selectedTicketData, selectedTicket, selectedTier, selectedInfo },
            counterComponentState: 'noSelect',
            showPassesCounter: true,
            showTierSelector,
          },
          () => {
            this.updateState(elem);
          },
        );
      },
    );
  };

  handleTierSelection = (selectedTier) => {
    this.setState(
      {
        selectedTicketData: { ...this.state.selectedTicketData, selectedTier },
      },
      () => {
        this.updateState();
      },
    );
  };

  handleAdditionalInfo = (selectedInfo) => {
    this.adInfoKey = '';
    if (this.state.selectedTicketData.selectedInfo === selectedInfo) {
      this.adInfoKey = selectedInfo;
      selectedInfo = undefined;
    }
    this.setState(
      {
        selectedTicketData: { ...this.state.selectedTicketData, selectedInfo },
      },
      () => {
        this.updateState();
      },
    );
  };

  checkProdCartExist = (coverSelectedProd) => {
    const cartItems = this.props.cartData.cart.items;
    const existInCart = Object.keys(cartItems).find(
      (key, i) => cartItems[key].products[0].productId === coverSelectedProd.productId,
    );
    return cartItems[existInCart];
  };

  getUpdatedQty = (cartProd, currentQty) => {
    return currentQty - cartProd.products[0].quantity;
  };

  updateCart = (existingProd, type) => {
    const product = this.finalSelectedProducts;
    const { quantity, maxCount } = product[0];
    const prdQty = this.getUpdatedQty(existingProd, quantity);
    const cartProd = existingProd.products[0];
    if (type) {
      this.props.deleteProduct(cartProd, maxCount);
    } else {
      this.props.updateQuantity(cartProd, prdQty, maxCount, true);
    }
  };

  addToCart = () => {
    GTMDataOnClickCTA({
      name: `${this.props.data?.name} - ${this.props.data?.addToCart}`,
      elementText: this.props.data?.addToCart,
      category: UIConfig.ga4Constants.PRIMARY_CTA,
    });
    if (this.props.addToCartCallback) {
      const selectedPassEle = document.querySelector('.pass.selected');
      this.props.addToCartCallback(this.finalSelectedProducts);
      if (!this.annualPassGridVariant) {
        this.setState(
          {
            counterComponentState: 'noSelect',
            passComponentState: 'noSelect',
            disableAddToCart: true,
            totalCalculatedAmount: '',
          },
          () => {
            selectedPassEle && selectedPassEle.parentElement.focus();
          },
        );
      }
    }
  };

  getCurrentTicketData = (data) => {
    const { productCoveoValue } = data;
    const isAnnualPassGrid = this.annualPassGridVariant;
    if (isAnnualPassGrid && productCoveoValue) {
      this.isFirstIncrement = false;
      const selectedTicketData = this.handleTicketSelection(productCoveoValue, 'return-ticket');
      return selectedTicketData.selectedTicketData;
    }
    return this.state.selectedTicketData;
  };

  setVisitorsCount = (data) => {
    const { spinnerCount, category } = data;
    const selectedTicket = this.getCurrentTicketData(data, spinnerCount, category);
    if (selectedTicket.selectedTicket === category) {
      this.setState(
        {
          counterComponentState: 'default',
          selectedTicketData: {
            ...selectedTicket,
            ticketCount: spinnerCount,
          },
        },
        () => {
          this.updateState();
        },
      );
    }
  };

  onClickHandle = (e, tickt) => {
    if (this.isSwad) {
      if (
        tickt.ticketSelector &&
        !(
          this.state.passComponentState !== 'noSelect' &&
          this.state.selectedTicketData.selectedTicket === tickt.ticketSelector.ticket.coveoValue
        )
      ) {
        this.handleTicketSelection(tickt.ticketSelector.ticket.coveoValue, e.target);
        if (this.props.isCrossSellRecommendationEnable && this.props.resetOtherSelectedTicket) {
          this.props.resetOtherSelectedTicket();
        }
      }
    }
  };

  /**
   *  Accessibility Handler for accordion
   */

  handleKeyDownAccordion = (event, coveoValue) => {
    let keyCode = event.keyCode.toString();

    // trigger the function to open the tab panel
    if (keyCode.match(/13|32/)) {
      // ENTER|SPACE
      this.handleTicketSelection(coveoValue, event.target);
    }
    // move focus on the accordion heading using arrow key
    else if (keyCode.match(/38|40/)) {
      // UP|DOWN arrow
      focusHandlerOnAccordion(event);
    }
  };
  getDescriptionClassNames = (tickt) => {
    let className = 'button-wrapper ';
    if (
      this.isSwad &&
      this.state.disableAddToCart &&
      tickt.ticketSelector &&
      this.state.passComponentState !== 'noSelect' &&
      this.state.selectedTicketData.selectedTicket === tickt.ticketSelector.ticket.coveoValue
    ) {
      className += ' disabled';
    }
    return className;
  };
  getClassNames = (tickt) => {
    let className = 'btn-primary c-annual-pass-add-cart';
    if (this.props.showPostPurchaseRecommendation) {
      return className;
    }
    if (!this.isSwad && this.state.disableAddToCart) {
      className += ' disabled';
    }
    if (this.isSwad) {
      if (
        !(
          tickt.ticketSelector &&
          this.state.passComponentState !== 'noSelect' &&
          this.state.selectedTicketData.selectedTicket === tickt.ticketSelector.ticket.coveoValue
        )
      ) {
        className += ' disabled';
      }
    }
    return className;
  };

  renderPassTotal = (total) => {
    return (
      <div
        className={`ticket-total-amount-wrapper ${this.checkTicketCount() && !this.isMobile ? 'slider-active' : ''}`}
      >
        <div className="body-3"> {this.props.data.priceLabel} </div>
        <div className={`total-amoumt ${total === '' ? 'disabled' : ''}`}>
          <h3 className="totalText">{this.props.data.total}</h3>
          <div className="amount">
            <p className="currency">
              {this.props.data.currency}&nbsp;
              {total}
            </p>
          </div>
        </div>
      </div>
    );
  };

  GTMDataOnSelectItem = (tkt) => {
    if (checkParksTenants()) {
      try {
        const { productId } = tkt;
        const selectedPrd = this.coveoMapdList.find((prd) => prd.productId === productId);
        GTMData.push('selectItem', { itemsList: [selectedPrd], category: this.props.data.gaTabTitle });
      } catch (error) {
        console.log('GA4 error: ', error);
      }
    }
  };

  renderTicketDetails = (tickt, index) => {
    const isThisSelectedTicket =
      tickt.ticketSelector.ticket.coveoValue === this.state.selectedTicketData.selectedTicket;

    this.annualPassList.push(tickt.ticketSelector);

    return (
      <div
        className="pass-component"
        tabIndex={this.isSwad ? 0 : -1}
        onClick={(e) => {
          this.onClickHandle(e, tickt);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            this.onClickHandle(e, tickt);
          }
        }}
      >
        <PassComponent
          key={index}
          data={tickt.ticketSelector}
          handleOnClick={this.handleTicketSelection}
          selectedTicket={this.state.selectedTicketData.selectedTicket}
          componentState={this.state.passComponentState}
          handelKeyDown={this.handleKeyDownAccordion}
          accordionID={`accordion_header_${index}`}
          ariaControls={`accordion_body_${index}`}
          ariaExpanded={isThisSelectedTicket || this.annualPassGridVariant}
          gaOnClick={this.GTMDataOnSelectItem}
          ariaLabel={tickt.ariaLabelCheckbox ? tickt.ariaLabelCheckbox : this.props.ariaLabelCheckbox}
          {...(this.annualPassGridVariant && {
            annualPassGridVariant: this.annualPassGridVariant,
            colorGradient: tickt.productCategoryType?.propertyValues?.Gradient,
          })}
        />
        {((isThisSelectedTicket && this.state.passComponentState === 'default') ||
          this.isSwad ||
          this.annualPassGridVariant) && (
          <div
            className={`option-selector ${tickt.additionalInfoSelector ? 'additional-info-enabled' : ''}
          ${
            this.isSwad &&
            tickt.ticketSelector &&
            this.state.passComponentState !== 'noSelect' &&
            this.state.selectedTicketData.selectedTicket === tickt.ticketSelector.ticket.coveoValue
              ? ' selected'
              : ''
          }`}
            id={`accordion_body_${index}`}
            aria-expanded={isThisSelectedTicket}
            {...(this.isSwad &&
              tickt.ticketSelector &&
              !(
                this.state.passComponentState !== 'noSelect' &&
                this.state.selectedTicketData.selectedTicket === tickt.ticketSelector.ticket.coveoValue
              ) && {
                'aria-hidden': true,
              })}
          >
            <div className="calendar-passcounter-wrapper">
              {this.renderPassCounter(
                tickt,
                this.isSwad &&
                  tickt.ticketSelector &&
                  !(
                    this.state.passComponentState !== 'noSelect' &&
                    this.state.selectedTicketData.selectedTicket === tickt.ticketSelector.ticket.coveoValue
                  )
                  ? true
                  : false,
              )}

              {tickt.tierSelector && tickt.tierSelector.tiers && tickt.tierSelector.tiers.length > 0 && (
                <div className="tier-container-wrapper">
                  {this.displayTierSelection(tickt.tierSelector.tiers, tickt.ariaLabelCheckbox)}
                </div>
              )}
              {tickt.additionalInfoSelector &&
                tickt.additionalInfoSelector.additionalInfo &&
                tickt.additionalInfoSelector.additionalInfo.length > 0 && (
                  <div className="gift-container-wrapper">
                    {this.displayAdditionalInfo(tickt.additionalInfoSelector.additionalInfo, tickt.ariaLabelCheckbox)}
                  </div>
                )}
            </div>

            {this.responseObj && !this.annualPassGridVariant && this.renderPassTotal(this.state.totalCalculatedAmount)}
            {!this.annualPassGridVariant && (
              <div className={this.getClassNames(tickt)}>
                {this.isSwad && (
                  <>
                    <DynamicContent
                      attrs={{
                        className: `body-2 c-primary-section-description ${this.state.disableAddToCart &&
                          this.isSwad &&
                          'opacity-1'}`,
                      }}
                      tagName="p"
                      innerHtml={tickt?.visitorSelector?.description}
                    />
                    <div
                      className={this.getDescriptionClassNames(tickt)}
                      tabIndex="-1"
                      ariaHidden={
                        this.isSwad &&
                        tickt.ticketSelector &&
                        this.state.passComponentState !== 'noSelect' &&
                        this.state.selectedTicketData.selectedTicket === tickt.ticketSelector.ticket.coveoValue
                          ? false
                          : true
                      }
                    >
                      {this.props.showPostPurchaseRecommendation ? (
                        <button
                          className="add-to-cart"
                          onClick={() => {
                            let windowURL = this.props.bookingUrl;
                            if (tickt.coveoValue) {
                              windowURL +=
                                '?tkt=' + tickt.coveoValue + '&addedFrom=' + UIConfig.postPurchaseCrossSellTxt;
                            }
                            if (tickt.productCategoryType) {
                              windowURL += '#' + tickt.productCategoryType;
                            }
                            if (this.props.isGAEnabledForPostCrossSell) {
                              GTMData.push('postPurchaseCrossSellClick', { products: tickt });
                            }
                            window.location = windowURL;
                          }}
                          tabIndex="0"
                        >
                          {this.props.postPurchaseRecommendationCTA}
                        </button>
                      ) : (
                        <button
                          className="add-to-cart"
                          onClick={this.addToCart}
                          tabIndex={this.state.disableAddToCart ? -1 : 0}
                          disabled={this.state.disableAddToCart ? true : false}
                        >
                          {this.props.data.addToCart}
                        </button>
                      )}
                    </div>
                  </>
                )}
                {!this.isSwad && (
                  <button
                    className="add-to-cart"
                    onClick={this.addToCart}
                    disabled={this.state.disableAddToCart ? true : false}
                  >
                    {this.props.data.addToCart}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  checkTicketCount = () => {
    const tickets = this.props.data.controls.annualPass.options;
    const { carousel } = this.props;
    if (detectViewPort === 'tablet') {
      return tickets.length > 2;
    }
    return tickets.length > carousel.slidesToShow;
  };

  checkVisibleSlides = (val, firstElement) => {
    const { carousel } = this.props;
    const lastElement = firstElement + carousel.slidesToShow;
    return (
      Number(val.getAttribute('data-index')) >= firstElement && Number(val.getAttribute('data-index')) < lastElement
    );
  };

  manageTabIndex = (firstElement) => {
    const slides = document.querySelectorAll('.slick-slide');
    slides.forEach((val) => {
      if (this.checkVisibleSlides(val, firstElement)) {
        val.style.visibility = 'visible';
        return;
      }
      val.style.visibility = 'hidden';
    });
  };

  displayTicketsView = (tickets) => {
    const { carousel } = this.props;
    const carouselData = { carousel, imageDetail: tickets };
    const settings = {
      ...carousel,
      ...getCustomCarouselSettings(carouselData),
      infinite: carousel.infinteScroll,
      cssEase: 'linear',
      speed: 800,
      useCSS: true,
      ...(isArabicMode() && { initialSlide: carousel.slidesToShow }),
    };

    if (this.annualPassGridVariant && this.checkTicketCount() && !this.isMobile) {
      return (
        <Carousel data={{ ...settings }} ref={this.sliderRef}>
          {this.displayTickets(tickets)}
        </Carousel>
      );
    }
    return this.displayTickets(tickets);
  };

  displayTickets = (tickets, responseObj) => {
    return tickets.map((tickt, index) => {
      if (this.isSwad && this.props.isCrossSellRecommendationEnable) {
        if (responseObj) {
          let isTicketAvailable = false;
          responseObj.products.forEach((newTicket) => {
            if (
              Array.isArray(newTicket[tickt?.ticketSelector?.ticket?.coveoKey]) &&
              tickt?.ticketSelector?.ticket?.coveoValue === newTicket[tickt?.ticketSelector?.ticket?.coveoKey][0]
            ) {
              isTicketAvailable = true;
            }
          });
          if (isTicketAvailable) {
            return this.renderTicketDetails(tickt, index);
          }
        }
      } else {
        if (this.isSwad && this.props.productDetailsKeyForAnnualPass && responseObj) {
          responseObj.products.forEach((newTicket) => {
            if (
              Array.isArray(newTicket[tickt?.ticketSelector?.ticket?.coveoKey]) &&
              newTicket[this.props.productDetailsKeyForAnnualPass] &&
              tickt?.ticketSelector?.ticket?.coveoValue === newTicket[tickt?.ticketSelector?.ticket?.coveoKey][0]
            ) {
              tickt.ticketSelector.ticket.productDetailLink = newTicket[this.props.productDetailsKeyForAnnualPass];
            }
          });
        }
        if (this.checkTicketCount() && !this.isMobile && !this.isSwad) {
          return <div>{this.renderTicketDetails(tickt, index)}</div>;
        }
        return this.renderTicketDetails(tickt, index);
      }
    });
  };

  getProductCartData = (key, category) => {
    let selectedTicket = {
      [this.ticketSelectorCoveoKey]: key,
      [this.visitorSelectorCoveoKey]: category,
    };
    if (this.responseObj) {
      let selectedProd = this.responseObj?.getFilteredProductsFromKeyVal?.(selectedTicket);
      const mappedData = this.getProductUpdatedCoveo(selectedProd, category);
      return mappedData;
    }
    return '';
  };

  displayTicketsCounter = (selectedTicketData, disableSpinner) => {
    if (selectedTicketData && selectedTicketData.visitorSelector) {
      const counterData =
        selectedTicketData.additionalCounter && !isEmpty(selectedTicketData.additionalCounter)
          ? {
              primaryCounter: selectedTicketData.visitorSelector,
              additionalCounter: selectedTicketData.additionalCounter,
            }
          : { primaryCounter: selectedTicketData.visitorSelector };

      const cat = counterData.primaryCounter.options[0].coveoValue;
      const coveoValue = selectedTicketData.ticketSelector.ticket.coveoValue;
      return (
        <div className="annual-pass visitor-counter">
          <VisitorsCountSelector
            data={counterData}
            disableSpinner={disableSpinner}
            setVisitorData={this.setVisitorsCount}
            ticketData={selectedTicketData.ticketSelector}
            componentState={this.state.counterComponentState}
            name={this.props.data.name}
            {...(this.annualPassGridVariant && {
              annualPassGridVariant: this.annualPassGridVariant,
              handleOnClick: this.handleTicketSelection,
              primaryProd: this.getProductCartData(coveoValue, cat),
              checkProdCartExist: this.checkProdCartExist,
              isFirstIncrement: this.isFirstIncrement,
            })}
          />
        </div>
      );
    }
    return null;
  };

  displayTierSelection = (tiers, ariaLabelCheckbox) => {
    const { selectedTicketData, tierComponentState } = this.state;
    return tiers.map((tier, index) => {
      return (
        <TierComponent
          key={index}
          data={tier}
          handleOnClick={this.handleTierSelection}
          selectedTier={selectedTicketData.selectedTier}
          selectedParentTicket={selectedTicketData.selectedTicket}
          componentState={tierComponentState}
          ariaLabel={ariaLabelCheckbox ? ariaLabelCheckbox : this.props.ariaLabelCheckbox}
        />
      );
    });
  };

  displayAdditionalInfo = (additionalInfo, ariaLabelCheckbox) => {
    const { selectedTicketData, infoComponentState } = this.state;
    return additionalInfo.map((info, index) => {
      return (
        <TierComponent
          key={index}
          data={info}
          handleOnClick={this.handleAdditionalInfo}
          selectedTier={selectedTicketData.selectedInfo}
          selectedParentTicket={selectedTicketData.selectedTicket}
          componentState={infoComponentState}
          ariaLabel={ariaLabelCheckbox ? ariaLabelCheckbox : this.props.ariaLabelCheckbox}
          type={'checkbox'}
          tabIndex={'-1'}
        />
      );
    });
  };

  renderPassCounter = (selectedTicket, disableSpinner) => {
    return this.state.showPassesCounter && this.displayTicketsCounter(selectedTicket, disableSpinner);
  };

  /* handleTicketSelectionOnKeyDown = (event) => {
        if(event.keyCode === 13) {
            this.handleTicketSelection(tickt.ticketSelector, elm)
        }
    } */

  renderAnnualPassContent = (ticketType, responseObj) => {
    return (
      <>
        {!this.props.isCrossSellRecommendationEnable && (
          <>
            {this.props.data.tabTitle && (
              <DynamicContent tagName="h5" attrs={{ className: 'tab-title' }} innerHtml={this.props.data.tabTitle} />
            )}
            {this.props.data.tabDescription && (
              <DynamicContent
                tagName="p"
                attrs={{ className: 'tab-description' }}
                innerHtml={this.props.data.tabDescription}
              />
            )}
            {this.props.data.tabWarning && (
              <DynamicContent tagName="p" attrs={{ className: 'tab-warning' }} innerHtml={this.props.data.tabWarning} />
            )}
          </>
        )}
        {this.props.isCrossSellRecommendationEnable ? (
          ticketType.length > 0 && this.displayTickets(ticketType, responseObj)
        ) : (
          <div
            className={`pass-container-wrapper ${
              this.checkTicketCount() && !this.isMobile ? 'slider-active v-carousel-all-view' : ''
            }`}
          >
            {this.isSwad && ticketType.length > 0 && this.displayTickets(ticketType, responseObj)}
            {!this.isSwad && ticketType.length > 0 && this.displayTicketsView(ticketType)}
          </div>
        )}
        {this.props.data.vatInfo && !this.annualPassGridVariant && (
          <DynamicContent tagName="div" attrs={{ className: 'vat-info body-2' }} innerHtml={this.props.data.vatInfo} />
        )}
        {this.annualPassGridVariant && !this.isMobile && (
          <>
            {this.renderPassTotal(this.props.cartData.cart.grossPrice)}
            <div className={`btn-primary ${this.props.cartData.cart.grossPrice === '0' ? 'disabled' : ''}`}>
              <AnchorLink
                className="form-wrapper-buttons-anchor"
                link={{
                  href: this.checkoutCTA.href,
                  label: this.checkoutCTA.label,
                }}
              />
            </div>
          </>
        )}
      </>
    );
  };

  render() {
    const ticketType = this.props.data.controls.annualPass.options;

    return (
      <>
        {this.props.isCrossSellRecommendationEnable ? (
          this.renderAnnualPassContent(ticketType, this.responseObj)
        ) : (
          <div className="component c-annual-pass">{this.renderAnnualPassContent(ticketType, this.responseObj)}</div>
        )}
      </>
    );
  }
}

AnnualPassComponent.propTypes = {
  data: PropTypes.shape({
    controls: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

AnnualPassComponent.defaultProps = {
  ariaLabelCheckbox: 'Press enter to select ticket',
};
