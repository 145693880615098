import UIConfig from '../../../common/UIConfig';
import {
  resolvePath,
  getCurrentLanguage,
  scrollToFormErrorOrSuccess,
  isLoggedInUser,
  getLoggedInUser,
  getLanguageWithoutLocal,
  checkTenant,
} from '../../../common/utility';
import { getObjectFromNestedObj, getFieldErrorMessage } from '../../../common/forms-utility';
import ApiWrapper from '../../../common/api-wrapper';
import { Logging } from '../../../common/logger';
import {
  getButtonData,
  getCaptchaField,
  getSiteCoreUrl,
  getCompleteFormData,
  handleFormLoader,
  triggerSuccessEmailPayload,
  setCorpEmailId,
} from './helper';
import { validateJSSForm, focusFirstJSSInputErrorField } from '../../presentation/jss-form-components/rules-validator';

const submitNewsletterSubscriptionAPI = (services, params) => {
  return new Promise((resolve, reject) => {
    const apiData = services.NewsletterSubscriptionAPI;
    //  const mainObj = getMainObject();
    //  const leisureFacility = resolvePath(mainObj, 'leisureFacility', '');
    const url = apiData.url.replace(':email', params.Email);
    const { tenantId, yasId = '' } = getLoggedInUser();
    const { email, phone, sms, whatsApp, website, mobile } = UIConfig.CMPconsent;
    ApiWrapper.apiGateway({
      url: isLoggedInUser() ? url + '?yasId=' + yasId : url,
      method: 'POST',
      data: {
        emailId: params.Email,
        myPassId: yasId,
        channelName: tenantId,
        language: getLanguageWithoutLocal(),
        consentPurpose: [
          { [email]: true },
          { [phone]: true },
          { [sms]: true },
          { [whatsApp]: true },
          { [website]: true },
          { [mobile]: true },
        ],
        source: '',
      },
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    })
      .then((res) => {
        resolve(res);
        Logging(res, resolvePath(res, 'config.moduleName', 'JSSForm'), true, 'NewsletterSubscriptionAPI submitted');
      })
      .catch((error) => {
        reject(error);
        Logging(
          error,
          resolvePath(error, 'config.moduleName', 'JSSForm'),
          true,
          'NewsletterSubscriptionAPI submission failed',
        );
      });
  });
};

const submitFormAPI = (props, params, gtmParams, customEndpoint) => {
  const { variant, services } = props;
  const { B2CBookDate } = UIConfig.jssForms.variant;
  for (let key in params) {
    if (typeof params[key] === 'string' && params[key].indexOf(UIConfig.form.isEmptyValue) !== -1) {
      params[key] = '';
    }
  }
  params.language = getCurrentLanguage();
  params.stringData = JSON.stringify(params);
  return new Promise((resolve, reject) => {
    ApiWrapper.platformService({
      url: customEndpoint,
      method: 'POST',
      data: params,
      moduleName: 'JSSForm',
      preLoader: true,
      preLoaderTarget: UIConfig.loader.contactusDefaultPreLoaderTarget,
    })
      .then((res) => {
        const { ReceiveUpdatesEmail } = params;
        Logging(res, resolvePath(res, 'config.moduleName', 'JSSForm'), true, 'form submitted');
        if (variant === B2CBookDate && (ReceiveUpdatesEmail === 'true' || ReceiveUpdatesEmail === true)) {
          submitNewsletterSubscriptionAPI(services, params)
            .then(() => {
              resolve({ data: res, gtmParams: { ...params, ...gtmParams } });
            })
            .catch(() => {
              resolve({ data: res, gtmParams: { ...params, ...gtmParams } });
            });
        } else {
          resolve({ data: res, gtmParams: { ...params, ...gtmParams } });
        }
      })
      .catch((err) => {
        Logging(err, resolvePath(err, 'config.moduleName', 'JSSForm'), false, 'Form Submission Failed');
        reject({ ...err, isFormError: true });
      });
  });
};

const submitFormSiteCore = (props, gtmParams, onlySiteCoreFormSubmission, triggerEmail, emailUrl, payload) => {
  const { formData, endpoint, siteCoreApiKey } = props;
  const url = siteCoreApiKey ? getSiteCoreUrl(siteCoreApiKey, endpoint) : endpoint;
  handleFormLoader(false, onlySiteCoreFormSubmission);
  return fetch(url, {
    body: formData.toUrlEncodedFormData(),
    method: 'post',
    // IMPORTANT: Sitecore forms relies on cookies for some state management, so credentials must be included.
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then((res) => {
      Logging(res, resolvePath(res, 'config.moduleName', 'JSSForm'), false, 'Sitecore Form Submitted');
      let error = {};
      if (res.statusText && !res.ok) {
        error = {
          text: res.statusText,
          code: res.status,
        };
      }

      //We dont need to trigger email if error occurs or triggerEmail boolean is false
      if (Object.keys(error).length || !triggerEmail) {
        handleFormLoader(true, onlySiteCoreFormSubmission);
        window.PubSub.publish(UIConfig.jssForms.events.formSubmissionApiError, { apiError: true });
        return { success: res.ok, onlySiteCoreFormSubmission, gtmParams, error };
      } else {
        window.PubSub.publish(UIConfig.jssForms.events.formSubmissionApiError, { apiError: false });
        return triggerSuccessEmail(gtmParams, emailUrl, onlySiteCoreFormSubmission, payload);
      }
    })
    .catch((err) => {
      Logging(err, resolvePath(err, 'config.moduleName', 'JSSForm'), false, 'Sitecore Form Submission Failed');
      handleFormLoader(true, onlySiteCoreFormSubmission);
      window.PubSub.publish(UIConfig.jssForms.events.formSubmissionApiError, { apiError: true });
      return {
        onlySiteCoreFormSubmission,
        success: false,
        error: {
          text: resolvePath(err, 'message', 'Something went wrong. Error was thrown when submit form'),
          code: '500',
        },
      };
    });
};

const submitJSSForm = (props, payload, gtmParams) => {
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  const isCorpFormType = payload.formType === UIConfig.CORPORATE_ENROLLMENT;
  // if (isSwad && isCorpFormType) {
  //   payload.leisureFacility = 'FWAD';
  //   payload.PID = 'FWAD';
  //   payload.tenant = 'FWAD';
  // }
  const buttonActionObj = getButtonData(props.formFields);
  const { url, isSaveSiteCoreData, triggerEmail } = buttonActionObj;
  if (isSaveSiteCoreData && url && !triggerEmail) {
    submitFormSiteCore(props, null, false);
    return submitFormAPI(props, payload, gtmParams, url);
  } else if (url && !triggerEmail) {
    return submitFormAPI(props, payload, gtmParams, url);
  } else if (isSaveSiteCoreData && triggerEmail) {
    return submitFormSiteCore(props, { ...payload, ...gtmParams }, true, triggerEmail, url, payload);
  } else {
    return submitFormSiteCore(props, { ...payload, ...gtmParams }, true, false);
  }
};

const validateCaptcha = (props, payload, gtmParams, captchaField) => {
  const captchaID = resolvePath(captchaField, 'model.id', '');
  const fieldName = resolvePath(captchaField, 'model.name', '');
  const captchaErrMsg = getFieldErrorMessage(captchaField.model, UIConfig.jssForms.errorConstant.captcha);
  const captchaValue = payload[fieldName];
  return new Promise((resolve, reject) => {
    ApiWrapper.platformService({
      url: `${window[captchaID].ValidationUrl}&i=${captchaValue}`,
      method: 'GET',
      responseType: '',
      preLoader: true,
      preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
    })
      .then((response) => {
        if (response.data) {
          submitJSSForm(props, payload, gtmParams)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject({ ...err, captchaID });
            });
        } else {
          response = {
            ...response,
            error: { code: UIConfig.errorCodes.invalidCaptcha },
            captchaErrMsg,
          };
          reject({ ...response, captchaID });
        }
      })
      .catch((error) => {
        reject({ ...error, captchaID, isFormError: true });
      });
  });
};

const triggerSuccessEmail = (gtmParams, url, onlySiteCoreFormSubmission, payload) => {
  const language = getCurrentLanguage();
  const { lastName, firstName, email } = triggerSuccessEmailPayload(payload);

  let requestPayload = {
    TemplateKey: payload && payload['RedisEmailTemplateId'],
    ToEmail: email,
    EmailTokens: [
      { Key: 'Username', Value: `${firstName} ${lastName}` || '' }, //needed in YMC emails
      { Key: 'Language', Value: language },
    ], // key: Language expected in API request body
  };
  return new Promise((resolve, reject) => {
    ApiWrapper.platformService({
      url: url,
      method: 'POST',
      data: requestPayload,
      moduleName: 'JSSForm',
      preLoader: true,
      preLoaderTarget: UIConfig.loader.contactusDefaultPreLoaderTarget,
    })
      .then((res) => {
        handleFormLoader(true, onlySiteCoreFormSubmission);
        Logging(res, resolvePath(res, 'config.moduleName', 'JSSForm'), true, 'Generic Mail API success');
        resolve({ success: true, onlySiteCoreFormSubmission, gtmParams, error: {} });
      })
      .catch((err) => {
        Logging(err, resolvePath(err, 'config.moduleName', 'JSSForm'), true, 'Generic Mail API failed');
        //scenario when data is saved in sitecore but email is not triggered
        // We are taking this as a success scenario as well
        resolve({ success: true, onlySiteCoreFormSubmission, gtmParams, error: {} });
      });
  });
};

export const formFetcher = (props) => {
  const { formData, formFields, defaultFormValues, isCorpEnrollment } = props;
  const formFieldData = getCompleteFormData(props);
  const { payload, data, gtmParams } = formFieldData;
  let captchaField = getCaptchaField(formFields);

  formData.data = data;
  const errors = validateJSSForm(props);
  const isErrorExist = Object.keys(getObjectFromNestedObj(errors)).length > 0;

  if (isErrorExist) {
    window.PubSub.publish(UIConfig.jssForms.events.formSubmissionError, { isFormErrorExist: true, errors });

    let interval = setInterval(() => {
      const target = document.getElementsByClassName('error-msg');

      if (target && target.length) {
        scrollToFormErrorOrSuccess('error-msg');
        focusFirstJSSInputErrorField();
        handleClearInterval();
      }
    }, 500);

    const handleClearInterval = () => {
      clearInterval(interval);
    };
    return new Promise((resolve, reject) => {
      reject({
        success: false,
        isFormValidationFailed: true,
        errors: 'Form Submission failed, due to the form validation',
      });
    });
  } else if (captchaField) {
    return validateCaptcha(props, payload, gtmParams, captchaField);
  } else {
    const finalPayload = { ...payload, ...defaultFormValues };

    if (isCorpEnrollment && finalPayload?.corporateEmail) {
      setCorpEmailId(finalPayload?.corporateEmail);
    }
    return submitJSSForm(props, finalPayload, gtmParams);
  }
};
