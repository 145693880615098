/**
 * list-filter-component.js
 * This file contains code for list-filter component
 * @author      SapientRazorfish
 * @license     Miral
 */
import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import FacetFilter from '../facet-filter';
import { DynamicContent, InputRange, SvgSprite } from '../base';
import Tooltip from '../tooltip/tooltip-component';
import DateRangePicker from '../date-range-picker';
import DropDown from '../drop-down';
import { Radio } from '../base';
import UIConfig from '../../../common/UIConfig';
import {
  detectViewPort,
  momentTimezone,
  currentLocale,
  updateUrlQueryParam,
  canUseDOM,
  convertJsDateToMomentObj,
  isMatchTenant,
  detectMobile,
  checkTenant,
  getSessionStorage,
  decodeQueryString,
  parseQueryString,
} from '../../../common/utility';
import { compareTicketsAnalytics } from '../../../common/analytics-events';
import { MouseEvents, TouchEvents } from '../../../common/constants';
import { logComponentRenderingError } from '../../../common/logger';

import './list-filter-component.scss';

const CONFIG = {
  sliderType: 'RangeFilter',
  facetType: 'ToggleButton',
  dateType: 'DateFilter',
  dateRangeType: 'DateRangeFilter',
  checkBoxType: 'checkBoxFilter',
  sortFilter: 'sortFilter',
  tomorowOperator: '>',
  fieldascending: 'fieldascending',
};

const getUpdatedDates = (date, { startDate, endDate, minDate, selectRange }) => {
  let dates = { startDate, endDate, minDate, selectRange };

  if (!startDate || date.diff(startDate) < 0 || (startDate ? date.format() === startDate.format() : false)) {
    dates.startDate = date;
    dates.minDate = date;
    dates.endDate = null;
    dates.selectRange = true;
  } else if (!endDate) {
    dates.endDate = date;
    dates.minDate = moment();
    dates.selectRange = false;
  } else {
    dates.startDate = date;
    dates.minDate = date;
    dates.endDate = null;
    dates.selectRange = true;
  }
  return dates;
};

const renderDropDown = (data) => {
  return data.options.map((item) => {
    return {
      value: item.value || item.text,
      text: item.text,
      code: item.code,
    };
  });
};

/**
 * renderFilterType   function calls respective function based on the type of filter
 * @param    {obj} function accepts data object for filters
 * @return   {[function]} function returns the respective function
 */
const renderFilterType = (item, itemIndex, renderDate, renderSlider, renderFacet) => {
  return item.fields.map((data, index) => {
    if (data.type === CONFIG.sliderType || data.type === CONFIG.dateRangeType) {
      return renderSlider(data, item.fields[0].type, itemIndex, true);
    }

    if (index !== 0) {
      return null;
    }

    if (data.type === CONFIG.facetType) {
      return renderFacet(item.fields, item.sectionTitle, data.type);
    }

    if (data.type === CONFIG.dateType) {
      let parentKeysObj = {
        sectionTitle: item.sectionTitle,
        errormsg: item.errormsg,
        dateBtnText: item.dateBtnText,
        type: data.type,
        datePattern: item.datePattern,
        datePatternRegExp: item.datePatternRegExp[0].regex,
      };
      return renderDate(item.fields, parentKeysObj);
    }
    return null;
  });
};

const renderFilterSortingAvailablityType = (item, itemIndex, renderDate, renderSlider, renderFacet, renderCheckbox) => {
  return item.fields.map((data, index) => {
    if (data.type === CONFIG.sliderType) {
      return renderSlider(data, item.fields[0].type, itemIndex);
    }

    if (index !== 0) {
      return null;
    }
    if (item.sectionType === CONFIG.checkBoxType) {
      return renderCheckbox(item);
    }

    return null;
  });
};

/**
 * datefilterexpressionUpdater   function make expression for coveo call for particular date field according to data
 * @param   {object} -data  contain data of particular date filed like fromDate or toDate
 * @param   {string} -dateValue  contain selected date of one field
 * @param   {string} -dateValue  contain selected date of one field
 * @param   {string} -dateValue  contain dateformat for the dates to be validated
 * @return   {undefined}
 */
const datefilterexpressionUpdater = (
  data,
  filterConf = { fromDate: '', toDate: '', dateFormat: '', addDay: '' },
  applyDatefilterHandler,
) => {
  const { endDateFieldToConfirm, endDateOperator, startDateFieldToConfirm, startDateOperator } = data;
  const calenderObj = {
    fromDate: `@${startDateFieldToConfirm}${startDateOperator}${moment
      .utc(filterConf.fromDate, filterConf.dateFormat)
      .clone()
      .locale('en')
      .format(UIConfig.calendar.slashDateFormat)}`,
    toDate: `@${endDateFieldToConfirm}${filterConf.addDay === '1' ? CONFIG.tomorowOperator : endDateOperator}${moment
      .utc(filterConf.toDate, filterConf.dateFormat)
      .clone()
      .locale('en')
      .format(UIConfig.calendar.slashDateFormat)}`,
  };

  applyDatefilterHandler(calenderObj, data.type);
};

const defaultDropdownValue = (
  variant,
  options,
  placeDropdownPlaceholder,
  label,
  filterData,
  isUpdateDropdownProps,
  tenantId,
  isSetDefault,
  isSortingFilterEnable,
) => {
  let selectedParam = '';

  if (filterData && filterData.hasOwnProperty(label) && tenantId === UIConfig.ymcB2CTenant) {
    selectedParam = options.find((param) => param.value.toLowerCase() === filterData[label].toLowerCase());
    selectedParam && isSetDefault ? (isUpdateDropdownProps.current = true) : (isUpdateDropdownProps.current = false);
  }
  let option;
  let sortingOption;
  let queryParams = decodeQueryString();
  // const selectedFilter = getSessionStorage('ymcSorting');
  const selectedFilter = queryParams?.sortingBy || null;
  if (label === 'PriceSorting' && selectedFilter !== null && isMatchTenant(UIConfig.tenants.ymc)) {
    let selectedSortFilterText = options?.find((data) => data?.value === selectedFilter);
    sortingOption = selectedSortFilterText?.text;
  }
  if (selectedFilter === null) sortingOption = options[0].text;
  option = selectedParam ? selectedParam.text : label !== 'PriceSorting' ? options[0].text : sortingOption;
  return variant === 'v-venue-find-filter' || variant === 'v-f1-multi-filter' || isSortingFilterEnable
    ? option
    : placeDropdownPlaceholder;
};

const changeFacetToDropdown = (items, variant, isSortingFilterEnable) => {
  if (variant === 'v-f1-multi-filter' || isSortingFilterEnable) {
    return items.map((item) => {
      return {
        value: item.id,
        text: `<div class="tab-label">${item.label}</div><div class="tab-sub-label">${item.sublabel}</div>`,
      };
    });
  }
  return [];
};

const showCompareBenefitsTable = (compareDescription) => {
  window.PubSub.publish('toggleOverlayState', {
    shouldOpen: true,
    customClass: 'category-benefits-compare-overlay',
    dataToAppend: <DynamicContent tagName="div" innerHtml={compareDescription} />,
  });
  compareTicketsAnalytics();
};

const ListFilter = (props) => {
  const prpFilters = props.data.filters || [];
  const isUpdateDropdownProps = useRef(false);
  const datePickerRef = useRef();
  const calendarTooltip = prpFilters[1] ? prpFilters[1].dateBtnText : '';
  const mainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
  const tenantId = mainObj && mainObj.tenantID && mainObj.tenantID.toLowerCase();

  const [showFilter, setShowFilter] = useState(!props.data.enableHideShow);
  const [results, setResults] = useState({});
  const [toFilter, setToFilter] = useState({});
  const [reset, setReset] = useState(false);
  const [filterIcon, setFilterIcon] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [openCal, setOpenCal] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(moment());
  const [selectRange, setSelectRange] = useState(true);
  const [showFacetFilter, setShowFacetFilter] = useState(false);
  const [searchFilter, setSearchFilter] = useState(false);
  const [isSetDefault, setDefaultFilter] = useState(false);
  const [isSortingFilterEnable, setIsSortingFilterEnable] = useState(false);
  const [classnameForLeftDropDownsAvailable, setClassnameForLeftDropDownsAvailable] = useState('');

  const [dateDropdownPlaceholder, setDateDropdownPlaceholder] = useState(
    prpFilters.length > 1 &&
      prpFilters[1] &&
      prpFilters[1].fields &&
      prpFilters[1].fields.length &&
      prpFilters[1].fields[0].options &&
      prpFilters[1].fields[0].options.length &&
      prpFilters[1].fields[0].options[0].text,
  );

  const [placeDropdownPlaceholder, setPlaceDropdownPlaceholder] = useState(
    prpFilters.length &&
      prpFilters[0].fields &&
      prpFilters[0].fields.length &&
      prpFilters[0].fields[0].options &&
      prpFilters[0].fields[0].options.length &&
      prpFilters[0].fields[0].options[0].text,
  );

  // const [packageListingSortSelection, setPackageListingSortSelection ] = useState('fieldascending')

  const packageListingSortSelection2 = useRef(CONFIG.fieldascending);

  const isYasArenaFilter = props.data.variant === UIConfig.eventListingFilter.yaFilterVariant;
  const isYasisland = props.data.variant === 'v-yasisland-filter';
  const isYMC = props.data.variant === 'v-event-filter';

  /**
   * componentWillMount   function adds keys to the state object dynamically
   *                      as per the existing type of filter
   * @param    {[Void]} function  does not accepts anything.
   * @return   {[Void]} function does not return anything.
   */
  useEffect(() => {
    //componentWillMount code
    const obj = {};
    const toFilter = {};
    const { filterData } = props;
    prpFilters.forEach((item) => {
      item.fields.forEach((fieldName) => {
        obj[fieldName.name] = fieldName.defaultValue || '';
        toFilter[fieldName.name] = '';
      });
    });

    const enableSorting = prpFilters?.some((item) => item.sectionType === 'sortFilter');
    if (enableSorting) setIsSortingFilterEnable(true);

    const { filterVariant } = props;

    const updatedObj = {
      ...obj,
      ToggleButton: filterData && filterData.hasOwnProperty(CONFIG.facetType) ? filterData[CONFIG.facetType] : 'All',
    };
    setResults(filterVariant === 'v-product-list-filter' || filterVariant === 'v-f1-multi-filter' ? updatedObj : obj);
    if (filterData && tenantId === UIConfig.ymcB2CTenant) {
      const updatedFilter = Object.keys(filterData).reduce((c, k) => ((c[k.toLowerCase()] = filterData[k]), c), {});
      delete updatedFilter[CONFIG.facetType.toLowerCase()];
      setSearchFilter({
        ...updatedFilter,
      });
      setDefaultFilter(true);
    }
    setToFilter(toFilter);
    setShowFacetFilter(true);
    //componentDidMount code
    document.addEventListener(MouseEvents.MouseDown, closeCal);
    document.addEventListener(TouchEvents.TouchStart, closeCal);

    return () => {
      document.removeEventListener(MouseEvents.MouseDown, closeCal);
      document.removeEventListener(TouchEvents.TouchStart, closeCal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData]);

  const searchResult = () => {
    if (tenantId === UIConfig.ymcB2CTenant) {
      for (const key in searchFilter) {
        updateUrlQueryParam(key, searchFilter[key]);
      }
    }
    props.callBack(results, toFilter);
    isUpdateDropdownProps.current = false;
  };

  /**
   * reset   function resets the selected filters to default values.
   * @param    {[Void]} function does not accepts anything.
   * @return   {[Void]} function does not return anything.
   */
  const resetData = (filterVariant) => {
    let updateFilter = searchFilter;
    const isFilterVariantMatched = filterVariant === 'v-product-list-filter' || filterVariant === 'v-f1-multi-filter';
    delete updateFilter[CONFIG.facetType.toLowerCase()];
    setDateSelected(null);
    setFromDate('');
    setToDate('');
    setReset(!reset);
    setFilterIcon(false);
    setOpenCal(false);
    setResults(isFilterVariantMatched ? { ToggleButton: 'All' } : {});
    setToFilter({});
    if (tenantId === UIConfig.ymcB2CTenant) {
      window.location.hash = 'all';
      setSearchFilter({ ...updateFilter });
    } else {
      props.callBack(null, null);
    }
  };

  const resetcalendar = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const dropDownCallBack = (obj) => {
    const addDay = obj.value;
    if (addDay.toLowerCase() === 'no-filter') {
      noFilter();
      return;
    }
    // 999 denotes we are selecting date range
    if (addDay === '999') {
      setDateDropdownPlaceholder(
        startDate
          ? `${moment(startDate).format(UIConfig.calendar.spaceDateFormat)}-${moment(endDate).format(
              UIConfig.calendar.spaceDateFormat,
            )}`
          : obj.text,
      );
    } else {
      setDateDropdownPlaceholder(obj.text);
      resetcalendar();
    }
    if (prpFilters[1].fields && addDay !== '999' && obj.code !== 'past-filter') {
      datefilterexpressionUpdater(
        prpFilters[1].fields[0],
        {
          fromDate: moment().add(addDay, 'days'),
          toDate: moment(),
          dateFormat: prpFilters[1].fields[0].dateFormat,
          addDay,
        },
        applyDatefilterHandler,
      );
    } else if (obj.code && obj.code === 'past-filter') {
      getExpiredEventExpression(addDay);
    } else {
      toggleShowHideCalendar();
      if (isYasisland) {
        setTimeout(() => {
          const dateRangeEle = document.querySelector('.CalendarRange .event-date-range-cal');
          dateRangeEle && dateRangeEle.focus();
        }, 0);
      } else if (isYMC) {
        setTimeout(() => {
          const dateRangeEle = document.querySelector('.YMC-CalendarRange .event-date-range-cal');
          dateRangeEle && dateRangeEle.focus();
        }, 0);
      }
    }
  };

  const getExpiredEventExpression = (addDay) => {
    const { endDateFieldToConfirm, endDateOperator, startDateOperator } = prpFilters[1].fields[0];

    //For expired event expression, the operators are flipped
    //Current date is reduced by 1 to use the less than and equal to operator
    const calenderObj = {
      toDate: `@${endDateFieldToConfirm}${startDateOperator}${moment()
        .clone()
        .locale('en')
        .add(-1, 'days')
        .format(UIConfig.calendar.slashDateFormat)}`,
      fromDate: `@${endDateFieldToConfirm}${endDateOperator}${moment()
        .clone()
        .locale('en')
        .add(addDay, 'days')
        .format(UIConfig.calendar.slashDateFormat)}`,
    };

    setResults({ ...results, [CONFIG.dateRangeType]: calenderObj });
    setToFilter({ ...toFilter, [CONFIG.dateRangeType]: calenderObj });
    setFilterIcon(true);
  };

  const calendarCallBack = (dateSelection) => {
    dateSelection = convertJsDateToMomentObj(dateSelection, startDate, endDate);
    const UpdatedDates = getUpdatedDates(dateSelection, {
      startDate,
      endDate,
      minDate,
      selectRange,
    });
    setStartDate(UpdatedDates.startDate);
    setEndDate(UpdatedDates.endDate);
    setMinDate(UpdatedDates.minDate);
    setSelectRange(UpdatedDates.selectRange);
    setDateDropdownPlaceholder(
      `${moment(UpdatedDates.startDate).format(UIConfig.calendar.spaceDateFormat)}-${moment(
        UpdatedDates.endDate,
      ).format(UIConfig.calendar.spaceDateFormat)}`,
    );

    isUpdateDropdownProps.current = true;

    if (UpdatedDates.startDate && UpdatedDates.endDate && prpFilters[1].fields) {
      datefilterexpressionUpdater(
        prpFilters[1].fields[0],
        {
          toDate: UpdatedDates.startDate.clone().locale('en'),
          fromDate: UpdatedDates.endDate.clone().locale('en'),
          dateFormat: prpFilters[1].fields[0].dateFormat,
        },
        applyDatefilterHandler,
      );
      toggleShowHideCalendar();

      if (isYasisland) {
        const dropdownEle = document.querySelector('.CalendarRange .selected-option');
        dropdownEle && dropdownEle.focus();
      } else if (isYMC) {
        const dropdownEle = document.querySelector('.YMC-CalendarRange .selected-option');
        dropdownEle && dropdownEle.focus();
      }
    }
  };

  const getSliderFilter = (callbackParam = {}, obj) => {
    const {
      callBack,
      data: { filters, variant },
      filterVariant,
    } = props;
    const dropdownIndex = (callbackParam && callbackParam?.dropdownIndex) || 0;
    if (
      isYasArenaFilter ||
      variant === 'v-yasisland-filter' ||
      variant === 'v-venue-find-filter' ||
      variant === 'v-event-filter' ||
      filterVariant === 'v-f1-multi-filter'
    ) {
      const latestFilters = callbackParam?.leftside ? getLatestFilters(filters, callbackParam?.leftside) : filters;
      const itemName = latestFilters[dropdownIndex].fields[0];
      setResults({ ...results, [itemName.name]: obj.value });
      setToFilter({ ...toFilter, [itemName.name]: itemName.coveoQuery });
      setSearchFilter({ ...searchFilter, [itemName.name.toLowerCase()]: obj.value.toLowerCase() });
      setFilterIcon(true);
      setPlaceDropdownPlaceholder(obj.text);
      setDefaultFilter(false);
    } else {
      const updateObj = { [obj.name]: obj.value };
      const toFilter = { [obj.name]: obj.toFilter };
      setSearchFilter({ ...searchFilter, [obj.name.toLowerCase()]: obj.value.toLowerCase() });
      setResults({ ...results, ...updateObj });
      setToFilter({ ...toFilter, ...toFilter });
      setFilterIcon(true);
      callBack(updateObj, toFilter);
    }
  };

  /**
   * sliderCallBack   function is a callback function.
   *                  Called on change of input-slider component
   *                  updates the state as per the selected values
   * @param    {obj} function accepts the updated object  as param.
   * @return   {[Void]} function does not return anything.
   */
  const sliderCallBack = (obj, callbackParam) => {
    const {
      data: { filters },
    } = props;
    const dropdownIndex = (callbackParam && callbackParam.dropdownIndex) || 0;

    if (checkTenant(UIConfig.iamMapping.ymc)) {
      if (callbackParam?.leftSide) {
        getSliderFilter(callbackParam, obj);
      } else {
        const filtersList = getLatestFilters(filters, callbackParam?.leftSide);
        let itemName = filtersList[dropdownIndex].fields[0]?.name;
        let name = itemName;
        let expression = {};
        facetCallBack(obj, name, expression, true);
      }
    } else {
      getSliderFilter({}, obj);
    }
  };

  /**
   * facetCallBack   function is a callback function.
   *                 Called on change of facet-filter component.
   *                 updates the state as per the selected values
   * @param    {obj} function accepts the updated object  as param.
   * @return   {[Void]} function does not return anything.
   */
  const facetCallBack = (obj, name, expression, isDirectApiSortingCall = false) => {
    let updateObj = { [name]: expression };
    setResults({ ...results, ...obj });
    setToFilter({ ...toFilter, ...updateObj });
    setSearchFilter({ ...searchFilter, [name?.toLowerCase()]: obj[name]?.toLowerCase() });
    setFilterIcon(true);
    setDefaultFilter(false);
    if (tenantId === UIConfig.ymcB2CTenant && !isDirectApiSortingCall) {
      // window.location.hash = obj[name].toLowerCase();
      const urlParams = new URLSearchParams(window.location.search)?.toString();

      if (urlParams) {
        const currentHash = obj[name].toLowerCase();
        const newUrl = `${window.location.pathname}?${urlParams}`;
        window.history.replaceState({}, '', newUrl);
        window.location.hash = currentHash;
      } else {
        window.location.hash = obj[name].toLowerCase();
      }
    } else if (checkTenant(UIConfig.iamMapping.ymc) && isDirectApiSortingCall) {
      let sortingCriteriaItem = obj.value;

      if (sortingCriteriaItem) {
        packageListingSortSelection2.current = sortingCriteriaItem;
        updateObj = { [name]: sortingCriteriaItem };
        // sessionStorage.setItem('ymcSorting', sortingCriteriaItem);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('sortingBy', sortingCriteriaItem);
        const currentHash = window.location.hash;
        const newUrl = `${window.location.pathname}?${urlParams.toString()}${currentHash}`;
        window.history.replaceState({}, '', newUrl);
      }

      props.callBack(obj, updateObj, isDirectApiSortingCall, false, packageListingSortSelection2.current);
    } else {
      props.callBack(obj, updateObj, name);
    }
  };

  const noFilter = () => {
    const { endDateFieldToConfirm, endDateOperator } = prpFilters[1].fields[0];

    const calenderObj = {
      toDate: `@${endDateFieldToConfirm}${endDateOperator}${moment
        .utc()
        .clone()
        .locale('en')
        .format(UIConfig.calendar.slashDateFormat)}`,
    };
    setResults({ ...results, [CONFIG.dateRangeType]: calenderObj });
    setToFilter({ ...toFilter, [CONFIG.dateRangeType]: calenderObj });
    setFilterIcon(true);
  };

  const closeCal = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setOpenCal(false);
    }
  };

  const toggleShowHideCalendar = () => {
    setOpenCal(!openCal);
  };

  /**
   * renderDate   function renders the markup for DateFilter type of filter
   */
  const renderDate = (items, parentKeysObj) => {
    return (
      <div className="date-filter-wrapper">
        <div className="date-filter-fields-wrapper">
          {items &&
            items.map((obj) => {
              return (
                <div>
                  <button className="date-filter-submit-button" onClick={toggleShowHideCalendar}>
                    {dateSelected ? dateSelected : obj.title}
                  </button>
                  {openCal && (
                    <DateRangePicker
                      resetCallback={resetDateFilter}
                      applyDateCallback={applyDateFilter}
                      dateFormat={parentKeysObj.datePattern}
                      errorMsg={parentKeysObj.errormsg}
                      obj={obj}
                      data={{
                        startDate: fromDate || null,
                        endDate: toDate || null,
                        resetCta: obj.resetDateBtnText,
                        applyCta: obj.applyDateBtnText,
                      }}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  /**
   * resetDateFilter   function to reset date filter
   * @param   {string} -dateValue  contain selected date of from field
   * @param   {string} -dateValue  contain selected date of one field
   * @return   {undefined}
   */
  const resetDateFilter = (setDateSelected, setFromDate, setToDate) => {
    setDateSelected(null);
    setFromDate('');
    setToDate('');
  };

  /**
   * applyDateFilter   function to reset date filter
   * @param   {string} -dateValue  contain selected date of from field
   * @param   {string} -dateValue  contain selected date of to field
   * @param   {string} -dateValue  contain selected date of one field
   * @param   {string} -dateValue  contain dateformat for the dates to be validated
   * @return   {undefined}
   */
  const applyDateFilter = (from, to, data, dateFormat) => {
    setFromDate(from);
    setToDate(to);
    setOpenCal(false);
    setDateSelected(`${moment(from, dateFormat).format('ll')} - ${moment(to, dateFormat).format('ll')}`);

    datefilterexpressionUpdater(data, { fromDate, toDate, dateFormat }, applyDatefilterHandler);
  };

  /**
   * applyDatefilterHandler   function validate expression  fromDate and toDate  and compare both date and call props.callBack
   * @param   {object} -data  contain data of particular date filed like fromDate or toDate
   * @param   {string} -dateValue  contain selected date of one field
   */
  const applyDatefilterHandler = (obj = null, type = 'DateRangeFilter') => {
    const updateObj = { [type]: obj };
    setResults({ ...results, ...updateObj });
    setToFilter({ ...toFilter, ...updateObj });
    setFilterIcon(true);

    const {
      callBack,
      data: { variant },
    } = props;
    if (!(variant === 'v-yasisland-filter' || variant === 'v-event-filter' || isYasArenaFilter)) {
      callBack(obj, updateObj, type);
    }
  };

  const getLatestFilters = useCallback((filters, leftSide) => {
    const leftDropDowns = filters.filter(
      (item) => item.sectionType !== CONFIG.sortFilter && item.sectionType !== CONFIG.checkBoxType,
    );
    const rightDropDowns = filters.filter(
      (item) => item.sectionType === CONFIG.sortFilter || item.sectionType === CONFIG.checkBoxType,
    );

    if (leftSide) return leftDropDowns;
    if (!leftSide) return rightDropDowns;
  }, []);

  useLayoutEffect(() => {
    if (document.getElementsByClassName('slider-container').length > 1)
      setClassnameForLeftDropDownsAvailable('leftDropDownClassAvailable');
  }, []);

  /**
   * renderSlider   function renders the markup for range type of filter
   * @param    {obj} function accepts data object for filters for type range
   * @return   {[obj]} function returns the markup for slider filter
   */
  const renderSlider = (sliderItem, sectionType, itemIndex, leftSide = false) => {
    const itemOptions = renderDropDown(sliderItem);
    const { label, name, placeholder, placeholderTitle } = sliderItem;
    const { range, variant } = props.data;
    let extraProps = {};

    //prevents virtual keyboard from opening in mobile device when date range selector is opened
    // readOnly attr is available but prevents user from opening date range picker
    const onFocus = (event) => {
      event.currentTarget.readOnly = true;
    };
    if (detectViewPort() === UIConfig.viewportTypes.mobile) {
      extraProps = {
        onFocus: onFocus,
      };
    }

    let activeLocale = currentLocale();

    if (activeLocale === 'ar-sa') {
      moment.defineLocale('ar-modified', {
        parentLocale: 'ar-sa',
        preparse: function(string) {
          return string;
        },
        postformat: function(string) {
          return string;
        },
      });

      activeLocale = 'ar-modified';
    }

    return (
      <div className={classNames('slider-container', name)} key={name}>
        <span>
          <DynamicContent tagName="p" attrs={{ className: 'slider-label heading-4' }} innerHtml={label} />
          {placeholder && (
            <Tooltip
              wrapperClass="slider-tooltip"
              onHover={true}
              buttonInnerHtml={
                <div>
                  <SvgSprite id="tooltip-icon-black" />
                </div>
              }
            >
              <div className="slider-tooltip-body">
                {placeholderTitle && (
                  <DynamicContent
                    tagName="p"
                    innerHtml={placeholderTitle}
                    attrs={{ className: 'slider-tooltip-placeholder-title' }}
                  />
                )}
                <DynamicContent
                  tagName="p"
                  innerHtml={placeholder}
                  attrs={{ className: 'slider-tooltip-placeholder-description body-2' }}
                />
              </div>
            </Tooltip>
          )}
        </span>
        {variant === 'v-yasisland-filter' ||
        isYasArenaFilter ||
        variant === 'v-venue-find-filter' ||
        variant === 'v-event-filter' ||
        variant === 'v-f1-multi-filter' ||
        isSortingFilterEnable ? (
          <DropDown
            listItems={itemOptions}
            defaultValue={
              sectionType === 'DateRangeFilter'
                ? dateDropdownPlaceholder
                : defaultDropdownValue(
                    variant,
                    itemOptions,
                    placeDropdownPlaceholder,
                    name,
                    props.filterData,
                    isUpdateDropdownProps,
                    tenantId,
                    isSetDefault,
                    isSortingFilterEnable,
                  )
            }
            showSelected={true}
            isUpdateDropdownProps={isUpdateDropdownProps.current}
            callBackFunction={sectionType === 'DateRangeFilter' ? dropDownCallBack : sliderCallBack}
            callBackParameters={{ dropdownIndex: itemIndex, leftSide }}
            isAriaLabelAsText={variant === 'v-f1-multi-filter' || isSortingFilterEnable}
            ariaLabel={(variant === 'v-f1-multi-filter' && sliderItem.label) || isSortingFilterEnable}
          />
        ) : (
          <InputRange data={sliderItem} onChange={sliderCallBack} reset={reset} />
        )}
        {openCal && sectionType === 'DateRangeFilter' && (
          <div className="c-hotel-booking-widget-date-picker" ref={datePickerRef}>
            {variant === 'v-event-filter' && calendarTooltip && (
              <style
                dangerouslySetInnerHTML={{
                  __html: `
                    .c-hotel-booking-widget-date-picker .react-datepicker__day:hover::after {
                      content : "${calendarTooltip}";
                    }
                  `,
                }}
              />
            )}
            <DatePicker
              locale={activeLocale}
              selected={startDate == null ? startDate : startDate.toDate()}
              selectsStart
              selectsRange
              selectsEnd={selectRange}
              startDate={startDate == null ? startDate : startDate.toDate()}
              endDate={endDate == null ? endDate : endDate.toDate()}
              maxDate={momentTimezone()
                .add(range ? range : 365, 'days')
                .toDate()}
              minDate={minDate == null ? minDate : minDate.toDate()}
              onChange={calendarCallBack}
              allowSameDay
              monthsShown={detectViewPort() === 'mobile' ? 1 : 2}
              className="event-date-range-cal"
              inline={!isYasisland && !isYMC}
              shouldCloseOnSelect={!isYasisland && !isYMC}
              onBlur={() => isYasisland && toggleShowHideCalendar()}
              {...extraProps}
            />
          </div>
        )}
      </div>
    );
  };

  const dropDownCallBackFunction = (selected, argument) => {
    const item = argument.item.find((option) => option.id === selected.value);
    const value = item.id;
    const name = argument.type;

    if (value === 'All') {
      resetData(props.filterVariant);
      return;
    }
    const expression = { [name]: `@${item.fieldToConfirm}${item.operator}${item.name}` };
    const obj = { [name]: value };
    facetCallBack(obj, name, expression);
  };

  const handleCheckboxChange = (e, data) => {
    sessionStorage.setItem('ymcAvailablitySort', e.target.checked);
    const name = data?.type;
    const value = data?.id;

    const nohash =
      window.location.hash && decodeURIComponent(window.location.hash.substring(1)) !== 'all' ? false : true;
    if (nohash) window.location.hash = 'all';

    if (value === 'All') {
      resetData(props.filterVariant);
      return;
    }

    const params = new URL(window.location).searchParams;
    const searchValues = new URL(window.location).search;
    const hashValue = decodeURIComponent(window.location.hash.substring(1));
    // it is the call to the useeffect writteen in the parent to call the coveo api for the hash params in the url
    if (((searchValues && searchValues.length > 0) || hashValue) && checkTenant(UIConfig.iamMapping.ymc)) {
      props.getCoveoDatabyUrlParam(props.filters, params, hashValue);
    } else {
      // this is the call for the normal flow without the hash params in the url
      const expression = {};
      if (e.target.checked) expression[name] = `@${data.fieldToConfirm}=0`;
      const obj = { [name]: value };
      facetCallBack(obj, name, expression, true);
    }
  };

  const renderCheckbox = ({ fields, sectionClass, sectionTitle, sectionType, showFieldsByDefault }) => {
    const ymcAvailablitySortIsChecked = getSessionStorage('ymcAvailablitySort') || null;

    return fields?.map((fieldsData) => {
      let checkboxData = {
        isTabRadio: false,
        ...fieldsData,
        type: 'ToggleButton',
        checked: ymcAvailablitySortIsChecked !== null ? JSON.parse(ymcAvailablitySortIsChecked) : fieldsData?.checked,
        showErrors: false,
        onRadioClick: {},
        radioValues: {},
        radioDisabled: {},
        controlled: false,
        isTabIndex: false,
        activeTab: false,
      };
      return <Radio data={checkboxData} isFilter onChange={handleCheckboxChange} />;
    });
  };
  /**
   * renderFacet   function renders the markup for facet type of filter
   * @param    {obj} function accepts data object for filters for type facet
   * @return   {[obj]} function returns the markup for facet filter
   */
  const renderFacet = (item, title, type) => {
    const {
      data: { variant },
      filterVariant,
      filterData,
    } = props;
    const itemOptions = changeFacetToDropdown(item, variant, isSortingFilterEnable);
    const viewport = detectViewPort();

    return (
      showFacetFilter && (
        <div className="facet-container" key={type}>
          {title && (
            <span className="facet-title heading-4">
              <DynamicContent tagName="p" innerHtml={title} />
            </span>
          )}
          {variant === 'v-f1-multi-filter' &&
          tenantId !== UIConfig.YIB2C &&
          (viewport === 'mobile' || viewport === 'tablet') ? (
            <DropDown
              listItems={itemOptions}
              defaultValue={defaultDropdownValue(
                variant,
                itemOptions,
                placeDropdownPlaceholder,
                type,
                filterData,
                isUpdateDropdownProps,
                tenantId,
                isSetDefault,
                isSortingFilterEnable,
              )}
              showSelected={true}
              isUpdateDropdownProps={isUpdateDropdownProps.current}
              callBackFunction={dropDownCallBackFunction}
              callBackParameters={{ item, type }}
            />
          ) : (
            <FacetFilter
              selected={results[type]}
              data={item}
              name={type}
              facetCallBack={facetCallBack}
              resetCallBack={resetData}
              toFilter={toFilter[type]}
              filterVariant={filterVariant}
            />
          )}
        </div>
      )
    );
  };

  const renderFilterSortingAvailabilityCallback = () => {
    return (
      <div className={`avaialbaility-sorting-container filter-list ${classnameForLeftDropDownsAvailable}`}>
        {/* Mapping over prpFilters including only items with sectionType 'sortByFilter' or 'checkbox' */}
        {getLatestFilters(prpFilters, false)?.map((item, index) =>
          renderFilterSortingAvailablityType(item, index, renderDate, renderSlider, renderFacet, renderCheckbox),
        )}
      </div>
    );
  };

  const {
    compareBenefits,
    compareDescription,
    enableHideShow,
    filterAriaLabel,
    hideFilterText,
    resetAriaLabel,
    resetFilterText,
    search,
    showFilterText,
    tileLabels,
    variant,
  } = props.data;

  try {
    return (
      <>
        <div
          className={classNames('c-list-filter component', showFilter ? 'filter-expanded' : 'filter-collapsed', {
            'no-hide-show': !enableHideShow,
          })}
        >
          {variant === 'v-yasisland-filter' ||
            (variant === 'v-venue-find-filter' && (
              <Text tag="div" className="filter-text" field={{ value: showFilterText, editable: showFilterText }} />
            ))}
          <div className={showFilter ? 'show-filter heading-5 show' : 'show-filter heading-5'}>
            <button
              onClick={() => setShowFilter(!showFilter)}
              aria-label={
                filterAriaLabel
                  ? showFilter
                    ? hideFilterText + filterAriaLabel
                    : showFilterText + filterAriaLabel
                  : 'Filter icon'
              }
            >
              <DynamicContent tagName="span" innerHtml={showFilter ? hideFilterText : showFilterText} />
              <div className="filter-icon">
                <SvgSprite id={filterIcon ? 'filter-icon' : 'filters-check'} />
              </div>
            </button>
          </div>
          {!isYasArenaFilter ? (
            <div className={showFilter ? 'filter-list show' : 'hide'}>
              {/* Mapping over prpFilters excluding items with sectionType 'sortByFilter' or 'checkbox' */}
              {getLatestFilters(prpFilters, true)?.map((item, index) =>
                renderFilterType(item, index, renderDate, renderSlider, renderFacet, renderCheckbox),
              )}

              <button
                className="reset-btn body-2"
                onClick={resetData}
                aria-label={resetAriaLabel ? resetAriaLabel : 'Reset icon'}
              >
                <DynamicContent tagName="span" innerHtml={resetFilterText} />
                <div className="reset-icon">
                  <SvgSprite id="reset-icon" />
                </div>
              </button>
            </div>
          ) : (
            prpFilters.map((item, index) => renderFilterType(item, index, renderDate, renderSlider, renderFacet))
          )}

          {(variant === 'v-yasisland-filter' ||
            isYasArenaFilter ||
            variant === 'v-venue-find-filter' ||
            variant === 'v-event-filter') && (
            <div className="button-search">
              <Text
                tag="button"
                field={{ value: tileLabels.searchButtonText, editable: tileLabels.searchButtonText }}
                onClick={searchResult}
              />
            </div>
          )}
          {variant === 'v-f1-multi-filter' && search && (
            <div className="button-search">
              <Text tag="button" field={{ value: search, editable: search }} onClick={searchResult} />
            </div>
          )}

          {variant === 'v-f1-multi-filter' && compareBenefits && compareBenefits.label && (
            <div className="compare-benefits-cta" onClick={() => showCompareBenefitsTable(compareDescription)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M13 9H11V7H13M13 17H11V11H13M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                  fill="white"
                />
              </svg>
              <button>
                <DynamicContent tagName="span" innerHtml={compareBenefits.label} />
              </button>
            </div>
          )}

          {renderFilterSortingAvailabilityCallback()}
        </div>
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'ListFilter');
  }
};

export default ListFilter;

ListFilter.propTypes = {
  data: PropTypes.shape({
    compareBenefits: PropTypes.object,
    compareDescription: PropTypes.string,
    filterAriaLabel: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        sectionTitle: PropTypes.string.isRequired,
        fields: PropTypes.array.isRequired,
      }),
    ),
    enableHideShow: PropTypes.bool,
    hideFilterText: PropTypes.string,
    range: PropTypes.number,
    resetAriaLabel: PropTypes.string,
    resetFilterText: PropTypes.string,
    search: PropTypes.string,
    showFilterText: PropTypes.string,
    tileLabels: PropTypes.object,
    variant: PropTypes.string,
  }),
  callback: PropTypes.func,
  filterVariant: PropTypes.string,
};
