import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { getLoggedInUser, getLoginUser, getMainObject, isEmpty, resolvePath } from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';
import { OrderService, RecommendationService } from '../../../../common/services';
import { getB2CProductDetails } from '../../../../common/coveo-api';
import AddOnsTile from './add-ons-tile-component';
import { Logging } from '../../../../common/logger';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import classnames from 'classnames';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';

const PastPurchaseAddOn = (props) => {
  const isUserLoggedIn = Object.keys(getLoginUser()).length > 0;
  const showAddons = props.data.isRecommendationAllowedForAll || isUserLoggedIn;
  const [addOnProductsList, setAddonProducts] = useState([]);
  const [overlayDisplay, setOverlayDisplay] = useState(false);
  const [emptyBookingError, setEmptyBookingError] = useState(false);
  const getOrdersList = async () => {
    const tenantId = getLoggedInUser().tenantID || getMainObject().tenantID;
    let url = resolvePath(props, 'services.getOrders.url', '');
    if (tenantId) url += '?tenantid=' + tenantId;

    return await OrderService.getB2cOrdersList(url, true, UIConfig.loader.defaultPreLoaderTarget);
  };
  const coveoProductsList = async (query) => {
    return await getB2CProductDetails({
      queryParams: query,
      perPageResults: UIConfig.coveoResultsPerPage,
      coveoKeyMap: props.coveoMappingList,
      serviceUrl: props.services.getCoveoProducts.url,
      fieldsToInclude: Object.values(props.coveoMappingList),
    });
  };
  const checkSuggestiveCall = async (requestData) => {
    let RecommendationServiceUrl = resolvePath(props, 'services.recommendedProducts.url', '');
    return await RecommendationService.getFilterProducts(
      RecommendationServiceUrl,
      'Purchase-Journey',
      requestData,
      true,
    );
  };
  const orderQueryFromOrder = (orderList) => {
    let list = [];
    orderList.data.orderdetails.orders.forEach((order) =>
      order.items.forEach((item) => {
        if (!list.includes(item.productId)) list.push(item.productId);
      }),
    );
    return list;
  };

  const queryFromSuggestive = (checkSuggestiveCallData) => {
    let list = [];
    checkSuggestiveCallData.data.PRODUCTGROUP.forEach((fItem) =>
      fItem.PRODUCTID.forEach((item) => {
        if (!list.includes(item)) list.push(item);
      }),
    );
    return list;
  };
  useEffect(() => {
    const apiCalls = async () => {
      try {
        if (props.data.isRecommendationAllowedForAll) {
          let query = [{ key: props.data.coveoKey, value: props.data.coveoValue }];
          const addOnProducts = await coveoProductsList(query);
          setAddonProducts(addOnProducts);
          props.isYAEmptyBookingPage(false);
          setEmptyBookingError(false);
          setOverlayDisplay(true);
        } else {
          const orderList = await getOrdersList();
          let query = [
            { key: resolvePath(props, 'coveoMappingList.productId', ''), value: orderQueryFromOrder(orderList) },
            {
              key: resolvePath(props, 'coveoMappingList.eventId', ''),
              value: props.data.eventDetails.eventId,
            },
          ];
          const coveoProductsListData = await coveoProductsList(query);
          const checkSuggestiveCallData = await checkSuggestiveCall({
            checkSuggestiveSell:
              coveoProductsListData &&
              coveoProductsListData.results &&
              coveoProductsListData.results.length &&
              coveoProductsListData.results.map((item) => {
                const mappedItem = coveoMap(item);
                return (
                  {
                    productId: mappedItem['productId'],
                    quantity: 1,
                  } ?? []
                );
              }),
          });

          let queryCoveo = [
            {
              key: resolvePath(props, 'coveoMappingList.productId', ''),
              value: queryFromSuggestive(checkSuggestiveCallData),
            },
          ];
          const coveoProductsListData2 = await coveoProductsList(queryCoveo);
          setAddonProducts(coveoProductsListData2);
          props.isYAEmptyBookingPage(false);
          setEmptyBookingError(false);
        }
      } catch (err) {
        props.isYAEmptyBookingPage(true);
        setEmptyBookingError(true);
        Logging(err, 'pending-search-event', false, 'Search Api failed to get result');
      } finally {
        if (!emptyBookingError) {
          await getOverlayData();
        }
      }
    };

    apiCalls();
  }, []);
  const coveoMap = (raw) => {
    let addOnData = {};
    Object.keys(props.coveoMappingList)
      .map((item) => {
        return { [item]: raw[props.coveoMappingList[item]] };
      })
      .forEach((item) => (addOnData = Object.assign(addOnData, item)));
    return addOnData;
  };
  const closeOverlay = () => {
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: false,
    });
  };
  const createOpenOverlayonLoad = () => {
    const { cartEmptyWarningOverlay } = props.data.eventDetails;
    const onConfirmClickHandler = () => {
      props.clearYACart();
      closeOverlay();
    };
    return (
      <div className="overlay-data">
        <DynamicContent
          tagName="p"
          attrs={{ className: '' }}
          innerHtml={cartEmptyWarningOverlay && cartEmptyWarningOverlay.bodyCopy}
        />
        <div className="action-line">
          <div className="btn-primary">
            <button type="button" onClick={() => onConfirmClickHandler()}>
              {cartEmptyWarningOverlay.primaryCTA}
            </button>
          </div>
          <div className="btn-secondary">
            <button type="button" onClick={closeOverlay}>
              {cartEmptyWarningOverlay && cartEmptyWarningOverlay.secondaryCTA}
            </button>
          </div>
        </div>
      </div>
    );
  };
  const displayPopUp = () => {
    props.setOverlay(false);
    window.PubSub.publish(UIConfig.events.TOGGLE_OVERLAY_STATE, {
      shouldOpen: true,
      dataToAppend: createOpenOverlayonLoad(),
      customClass: classnames('multiDateBooking-Overlay'),
    });
  };

  const updateOverlayState = () => {
    setOverlayDisplay(false);
  };

  const getOverlayData = () => {
    const eventDetails = YALocalStorageSvc.getSetEventDetails();
    const isCartProductAvailable = eventDetails && eventDetails.eventId && eventDetails.eventId.length;

    const addOnProductIdEventId = props.data.eventDetails.eventId;
    const { eventId } = YALocalStorageSvc.getSetEventDetails();
    if (addOnProductIdEventId !== eventId && !isEmpty(eventId) && isCartProductAvailable) {
      setOverlayDisplay(true);
      displayPopUp();
    }
  };

  const addOnProducts = (props) => {
    return (
      <div>
        {showAddons &&
          addOnProductsList.results &&
          addOnProductsList.results.length > 0 &&
          addOnProductsList.results.map((item) => {
            return (
              <AddOnsTile
                data={coveoMap(item)}
                addToCartCallBack={props.addToCartCallback}
                eventDetails={props.data.eventDetails}
                overlayDisplay={overlayDisplay}
                cartData={props.cartData}
                emptyBookingError={emptyBookingError}
                mupValidityMap={props.mupValidityMap}
                tabsData={props.data}
                displayPopUp={displayPopUp}
                additionalRecommendedData={props.additionalRecommendedData}
                isYaNonSeatedJourney={props.isYaNonSeatedJourney}
                displayMaxLimit
                recommendationForAll={props.data.isRecommendationAllowedForAll}
                checkPerformanceUrl={props?.services?.addoncheckbasket}
              />
            );
          })}
      </div>
    );
  };
  return <>{addOnProducts(props)}</>;
};

export default PastPurchaseAddOn;
