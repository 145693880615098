import className from 'classnames';
import moment from 'moment';
import React from 'react';
import { TrackerContext } from '@uniformdev/tracking-react';
import {
  PaymentAnalytics,
  getFWBookingConfirmationAnalytics,
  getYABookingConfirmationAnalytics,
} from '../../../../common/analytics-events';
import { Logging } from '../../../../common/logger';
import { getB2CProductDetails } from '../../../../common/coveo-api';
import GoalAnalytics from '../../../../common/goal-analytics';
import { OrderService } from '../../../../common/services';
import ApiWrapper from '../../../../common/api-wrapper';
import SojrenAnalytics from '../../../../common/sojren-analytics';
import UIConfig from '../../../../common/UIConfig';
import {
  canUseDOM,
  createMappedData,
  getErrorMap,
  getFallbackLanguage,
  getLoggedInUser,
  getOrderIdpromoCode,
  guestUserEmail,
  parseQueryString,
  toTwoDecimalPlaces,
  detectViewPort,
  checkInlineSignup,
  resolvePath,
  getYasIdGTMData,
  getEmailIdGTMData,
  iOSDevice,
  isEmpty,
  isSafariBrowser,
  getLocalStorageByKey,
  isYasArenaJourney,
  checkTenant,
  detectMobile,
  getExpressCheckoutPostPurchaseUser,
  getSessionStorage,
  isMatchTenant,
  checkParksTenants,
  getCurrency,
  getProductIdx,
  checkIfParks,
  isParksExpressCheckoutTenant,
  getMembershipDetails,
} from '../../../../common/utility';
import ConfirmedTicketTile from '../../../presentation/b2c-purchase-journey/ticket-tile/confirmed-ticket-tile';
import DynamicContent from '../../../presentation/base/dynamic-content/dynamic-content-component';
import PayfortUtility from '../../payment/payfort/payfort-utility';
import GTMData from '../gtm-data';
import { YALocalStorageSvc } from '../my-cart/yasarena-cart-management-service';
import { seatedLocalStorageSvc } from '../cart/seated-journey-actions';
import ErrorSummary from '../../../presentation/base/error-summary/error-summary-component';
import { getCorporateUserEmailId, isCorpCouponApplied } from '../../payment/utils';
import { confirmationReleaseLockOrderService } from '../cart/helper';
import { getIsReconcilRequest } from '../../../../utils/paymentUtils';
import { isParkTenant } from '../../../../common/utility/tenantsUtils';
import TicketUpgradeConfirmation from './ticket-upgrade-confirmation';

export default class BookingConfirmationComponent extends React.Component {
  constructor(props) {
    super(props);
    const { step_two, ya_step_two, ymc_step_two } = UIConfig.b2c.purchaseJourney.confirmationSummaryVariant;
    this.services = this.props.data.services;
    this.variant = resolvePath(props, 'data.variant', '');
    this.yaOrderConfirmation = this.variant === ya_step_two;
    this.ymcOrderConfirmation = this.variant === ymc_step_two;
    this.ticketImage = this.props.data.ticketImage;
    this.multiparkTicketImage = this.props.data.multiparkTicketImage;
    this.ticketDisclaimerTitle = this.props.data.ticketDisclaimerTitle;
    this.ticketDisclaimerDescription = this.props.data.ticketDisclaimerDescription;
    this.isYANonSeatedEvent = YALocalStorageSvc.hadSelectedNonSeatedEvent() && this.yaOrderConfirmation;
    this.isStepTwo = this.props.data.variant === step_two || this.yaOrderConfirmation || this.ymcOrderConfirmation;
    this.isTicketUpgradeEnabled = (this.props.data?.ticketUpgrade?.enableTicketUpgrade || false) && checkParksTenants();
    this.ticketUpgradeBackgroundImage = this?.props?.data?.ticketUpgrade?.ticketUpgradeBackgroundImage;
    this.urls = {
      Anonymousorder: resolvePath(props, 'data.services.getAnonymousOrderDetail.url', ''),
      order: resolvePath(props, 'data.services.getOrderDetail.url', ''),
      products: resolvePath(props, 'data.services.getCoveoProducts.url', ''),
      eTicket: resolvePath(props, 'data.services.eTicketDownload.url', ''),
      eticketDownloadAnonymous: resolvePath(props, 'data.services.eticketDownloadAnonymous.url', ''),
      ticketUpgradeSuggestions: resolvePath(props, 'data.services.getTicketUpgradeSuggestions.url', ''),
    };
    this.businessError = this.props.data.businessError;
    this.purchaseComplete = false;
    this.coveoMapping = this.props.data.coveoMappingList;
    this.formGetProRequestData = [];
    this.reservationCode = null;
    this.reservationOwnerAccount = null;
    this.promoCode = null;
    this.promotions = null;
    this.orderStatus = null;
    this.state = {
      orderData: null,
      errorData: null,
      productData: null,
      isRenewPage: false,
      isReconcileError: false,
      isOrderDataLoading: false,
      isScrollToTop: false,
      isOrderPurchasedStatus: false,
      ticketUpgradeData: null,
      isTicketUpgrade: false,
    };
    this.gtmData = [];
    this.tenantId = GTMData.getTenantId();
    this.isRenewAnnualPassFlag = false;
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
    this.isFwad = checkTenant(UIConfig.iamMapping.fwad);
    this.isParks = checkIfParks();
    this.isParksExpressCheckout = isParksExpressCheckoutTenant();
    this.disableDownloadButton =
      getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.disableDownloadButton) || false;
  }

  static contextType = TrackerContext;

  /**
   * error handling
   * @param {Object} err error object
   */
  errorCallback = (err) => {
    this.setState({
      errorData: err,
    });
  };

  removeCreatedSeatedOrder = () => {
    if (getLocalStorageByKey('seatedCreateOrder')) {
      localStorage.removeItem('seatedCreateOrder');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const orderData = prevState.orderData;
    if (orderData && (orderData.reservationCode || parseQueryString('isRenewAnnualPass') === 'true')) {
      const orderStatus = getLocalStorageByKey(UIConfig.localStoreKeys.purchaseJourney.orderStatus);
      if (!orderStatus) {
        this.purchaseComplete = true;
        localStorage.setItem('purchaseEventSuccess', false);
        window.PubSub.publish(UIConfig.events.PUBLISH_ORDER_STATUS_ONCE, { orderStatus: false });
      }
      localStorage.setItem(UIConfig.localStoreKeys.purchaseJourney.orderStatus, true);
      localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.disableDownloadButton);
    }

    if (this.isParksExpressCheckout && this.state.isScrollToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.setState({ isScrollToTop: false });
    }
  }

  /* make api call(s) to get Order Data */
  getTicketUpgradeInfo = (userId) => {
    const isTicketUpgrade = parseQueryString('isTicketUpgrade') === 'true' || false;
    this.setState({ isTicketUpgrade });
    if (this.isTicketUpgradeEnabled && this.urls?.ticketUpgradeSuggestions && !isTicketUpgrade) {
      const requestObj = {
        orderId: this.orderId,
        userId: userId ?? '',
        language: getFallbackLanguage(),
        tenantId: this.tenantId,
      };
      const ticketUpgradeDetails = OrderService.getTicketUpgradeDetails(
        this.urls.ticketUpgradeSuggestions,
        requestObj,
        true,
        UIConfig.loader.defaultPreLoaderTarget,
      );
      ticketUpgradeDetails
        .then((response) => {
          this.setState({ ticketUpgradeData: response.data });
        })
        .catch((res) => {
          this.errorCallback(
            getErrorMap(
              'getTicketUpgradeSuggestions',
              this.services?.getTicketUpgradeSuggestions?.errors,
              false,
              res.error,
              this.state.errorData,
            ),
          );
        });
    }
  };

  getUpdatedOrderItems = (orderItems) => {
    return orderItems?.map((item) => {
      const orderItem = { ...item };
      //Note: If the item's tickets > 1 then ignore an upgraded ticket
      if (orderItem?.tickets?.length > 1) {
        orderItem.tickets = orderItem.tickets.filter((ticket) => !ticket.isUpgraded);
      }
      return orderItem;
    });
  };

  componentDidMount = () => {
    this.orderStatus = JSON.parse(localStorage.getItem(UIConfig.localStoreKeys.purchaseJourney.orderStatus));
    this.setState({ isOrderPurchasedStatus: this.orderStatus });
    this.isSwad && localStorage.removeItem(UIConfig.events.EXPRESS_CHECKOUT_ENABLED);
    setTimeout(() => {
      window.PubSub.publish(
        UIConfig.events.STEP_2_STICKY_CTA_WITH_COOKIES_EXIST,
        detectViewPort() === 'mobile' ? true : false,
      );
    }, 0);
    const ticketValidityMap = this.props.data.ticketValidityMap;
    this.user = getLoggedInUser();
    try {
      if (parseQueryString('ref') === UIConfig.paymentOption.gPay) {
        this.orderId = parseQueryString('oId', false, window.location.href, true);
      } else {
        this.orderId = atob(parseQueryString('oId'));
      }
    } catch (e) {
      if (e.name === 'InvalidCharacterError') {
        this.orderId = parseQueryString('oId');
      }
    }
    this.status = parseQueryString('status') === 'true';
    this.errorMessage = this.status ? undefined : JSON.parse(localStorage.getItem('error_message'));
    this.setState({ errorData: this.errorMessage });
    if (this.status && this.isParksExpressCheckout) {
      sessionStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.cartData);
      localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.anonymousCart);
    }
    if (guestUserEmail() && this.orderId && this.status) {
      const usr = JSON.parse(localStorage.getItem('guestUserEmail')).email;
      const config = {
        orderId: this.orderId,
        userId: usr,
      };
      this.setState({ isOrderDataLoading: true });
      PayfortUtility.clearAnonymousCart();
      OrderService.B2cOrderAnonymous(this.urls.Anonymousorder, config, UIConfig.loader.defaultPreLoaderTarget)
        .then((response) => {
          const { order } = response.data.orderdetails;

          if (this.isTicketUpgradeEnabled) {
            const isOrderUpgraded = order?.items?.map((data) => JSON.parse(data?.tickets?.[0]?.isUpgraded));
            if (isOrderUpgraded) {
              order.items = this.getUpdatedOrderItems(order?.items);
            }
          }

          this.reservationCode = order.reservationCode;
          this.reservationOwnerAccount = order.reservationOwnerAccount;
          this.promotions = order.promotions;
          this.yaOrderConfirmation && YALocalStorageSvc.removeSeatInfoDetails();
          this.setState({ orderData: order, isOrderDataLoading: false, isScrollToTop: true });
          this.removeCreatedSeatedOrder();
          const productIdList = order.items.map((item) => {
              return item.productId;
            }),
            keyMap = this.coveoMapping,
            params = [
              {
                key: keyMap.productId,
                value: productIdList,
              },
              {
                ...ticketValidityMap,
              },
            ];

          // Get Ticket Upgrade Info
          this.getTicketUpgradeInfo(usr);

          // coveo calling function that will return product\s as per the query params
          getB2CProductDetails({
            queryParams: params,
            perPageResults: UIConfig.coveoResultsPerPage,
            coveoKeyMap: keyMap,
            serviceUrl: this.urls.products,
            fieldsToInclude: Object.values(keyMap),
            lang: getFallbackLanguage(),
          })
            .then((res) => {
              this.setState({ productData: res.results });
              if (isMatchTenant(UIConfig.tenants.ymc)) {
                window.PubSub.publish(UIConfig.events.PUBLISH_ORDER_DETAILS, {
                  products: order.items,
                  coveoProducts: res.results,
                  coveoMapping: {
                    eventType: this.coveoMapping.eventType,
                    productId: this.coveoMapping.productId,
                  },
                });
              }
              // GTM Code will be pushed only for new orders
              this.pushGTMFlags(this.gtmData);
              if (this.props.data.goal) {
                const goalAnalytics = new GoalAnalytics(
                  this.props.data.goal,
                  res.results,
                  this.context,
                  'BookingConfirmation Goal',
                );
                if (goalAnalytics.goal.search.category || goalAnalytics.goal.search.products) {
                  goalAnalytics.startSearch();
                } else {
                  goalAnalytics.sendGoal();
                }
              }
            })
            .catch((res) => {
              this.errorCallback(
                getErrorMap(
                  'getOrderDetail',
                  this.services.getCoveoProducts.errors,
                  false,
                  res.error,
                  this.state.errorData,
                ),
              );
            });
        })
        .catch((res) => {
          this.setState({ isOrderDataLoading: false });
          this.errorCallback(
            getErrorMap('getOrderDetail', this.services.getOrderDetail.errors, false, res.error, this.state.errorData),
          );
        });
    } else if (this.orderId && this.status) {
      /* Remove recipient details from local storage */
      this.setState({ isOrderDataLoading: true });
      PayfortUtility.clearRecipientDetails();
      const orderData = OrderService.getB2cOrder(
        this.urls.order + '/' + this.orderId,
        true,
        UIConfig.loader.defaultPreLoaderTarget,
      );
      orderData
        .then((response) => {
          seatedLocalStorageSvc.RemoveSeatedJourneyKeys();
          const isRenewPage = parseQueryString('isRenewAnnualPass') === 'true';
          const { order } = response.data.orderdetails;

          if (this.isTicketUpgradeEnabled) {
            const isOrderUpgraded = order?.items?.map((data) => data.tickets[0].isUpgraded)?.includes(true);
            if (isOrderUpgraded) {
              order.items = this.getUpdatedOrderItems(order?.items);
            }
          }

          this.reservationCode = order.reservationCode;
          this.reservationOwnerAccount = order.reservationOwnerAccount;
          this.promotions = order.promotions;
          let updatedOrder = { ...order };
          if (isYasArenaJourney() && order.items.length > 0) {
            const filterItems = order.items.filter((item) => item.tickets && item.tickets.length > 0);
            updatedOrder = { ...order, items: filterItems };
          }

          this.setState({
            orderData: updatedOrder,
            isRenewPage,
            isOrderDataLoading: false,
            isScrollToTop: true,
          });
          this.removeCreatedSeatedOrder();
          const productIdList = updatedOrder.items.map((item) => {
              return item.productId;
            }),
            keyMap = this.coveoMapping,
            params = [
              {
                key: keyMap.productId,
                value: productIdList,
              },
              {
                ...ticketValidityMap,
              },
            ];

          this.yaOrderConfirmation && YALocalStorageSvc.removeSeatInfoDetails();

          // Get Ticket Upgrade Info
          this.getTicketUpgradeInfo();

          // coveo calling function that will return product\s as per the query params
          getB2CProductDetails({
            queryParams: params,
            perPageResults: UIConfig.coveoResultsPerPage,
            coveoKeyMap: keyMap,
            serviceUrl: this.urls.products,
            fieldsToInclude: Object.values(keyMap),
            lang: getFallbackLanguage(),
          })
            .then((res) => {
              this.setState({ productData: res.results });
              if (isMatchTenant(UIConfig.tenants.ymc)) {
                window.PubSub.publish(UIConfig.events.PUBLISH_ORDER_DETAILS, {
                  products: order.items,
                  coveoProducts: res.results,
                  coveoMapping: {
                    eventType: this.coveoMapping.eventType,
                    productId: this.coveoMapping.productId,
                  },
                });
              }
              SojrenAnalytics.set('bookingConfirmation', {
                products: createMappedData(this.state.productData, this.coveoMapping),
                orderId: this.state.orderData.reservationCode,
                price: this.state.orderData.total.gross,
                currency: 'AED',
              });

              // GTM Code will be pushed only for new orders
              this.pushGTMFlags(this.gtmData);
              if (this.props.data.goal) {
                const goalAnalytics = new GoalAnalytics(
                  this.props.data.goal,
                  res.results,
                  this.context,
                  'BookingConfirmation Goal',
                );
                if (goalAnalytics.goal.search.category || goalAnalytics.goal.search.products) {
                  goalAnalytics.startSearch();
                } else {
                  goalAnalytics.sendGoal();
                }
              }
            })
            .catch((res) => {
              this.errorCallback(
                getErrorMap(
                  'getOrderDetail',
                  this.services.getCoveoProducts.errors,
                  false,
                  res.error,
                  this.state.errorData,
                ),
              );
            });
        })
        .catch((res) => {
          this.setState({ isOrderDataLoading: false });
          this.errorCallback(
            getErrorMap('getOrderDetail', this.services.getOrderDetail.errors, false, res.error, this.state.errorData),
          );
        });
    } else {
      if (isYasArenaJourney()) {
        const appleReconcileError = JSON.parse(
          localStorage.getItem(UIConfig.localStoreKeys.payment.applePayReconcileError),
        );
        if (appleReconcileError) {
          this.setState({ isReconcileError: true });
          this.errorCallback(appleReconcileError);
        }
      }
      PaymentAnalytics &&
        this.errorMessage &&
        this.errorMessage.makePayment &&
        PaymentAnalytics(this.errorMessage.makePayment.code, this.errorMessage.makePayment.text);
    }

    this.orderId && this.status && localStorage.removeItem(UIConfig.localStoreKeys.purchaseJourney.visitedDate);

    // if order failed , then abort order functionality:
    if (
      !this.status &&
      !getIsReconcilRequest() &&
      (isMatchTenant(UIConfig?.tenants?.fwad) ||
        isMatchTenant(UIConfig?.tenants?.swadb2c) ||
        isMatchTenant(UIConfig?.tenants?.ppad))
    ) {
      const releaseLockApiUrl = this.props?.data?.services?.deleteOrder?.url;
      confirmationReleaseLockOrderService(releaseLockApiUrl);
    }
    localStorage.removeItem('isReconcilRequest');
  };

  formResAccToURL = (response) => {
    const bookProUrl = resolvePath(response.BookProResponseModel[0], 'bookproUrl', '');
    if (bookProUrl) {
      if (isSafariBrowser() || iOSDevice()) {
        window.open(bookProUrl, '_self');
      } else {
        window.open(bookProUrl);
      }
    }
  };

  getBookProUrl = async (selectedTicketId) => {
    const data = this.formGetProRequestData.find((item) => item.ticketId === selectedTicketId);
    const { services } = this.props.data;

    const productList = createMappedData(this.state.productData, this.coveoMapping);

    let selectedProduct = productList.find((product) => product.productId === data?.productAK);

    if (this.isF1Product() && selectedProduct?.f1PassType && data?.mediaType) {
      data.passType = `${selectedProduct?.f1PassType}${data?.mediaType}`;
    } else if (selectedProduct?.passType) {
      data.passType = selectedProduct?.passType;
    }

    let isBioMetricConsent =
      selectedProduct?.itemType?.toLowerCase() === 'mup' && sessionStorage.getItem('isMultiParkConsent') === 'true';

    const url = resolvePath(services, 'getbookprourl.url', '');
    if (url && data) {
      const config = {
        url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        preLoader: true,
        preLoaderTarget: UIConfig.loader.defaultPreLoaderTarget,
        data: {
          BookProRequestModel: [data],
          tenantId: getLoggedInUser().tenantID,
          ...(isParkTenant() || isMatchTenant(UIConfig.tenants.swadb2c) ? { isBioMetricConsent } : {}),
        },
      };
      try {
        GTMData.push(
          'addToWallet',
          {
            productDetails: { ...data },
          },
          false,
          false,
          this.state.isTicketUpgrade,
        );
        const res = await ApiWrapper.experienceServices(config);
        if (res && res.data) {
          this.formResAccToURL(res.data);
        }
      } catch (err) {
        this.errorCallback(
          getErrorMap('getbookprourl', resolvePath(services, 'getbookprourl.errors'), false, err, this.state.errorData),
        );
        Logging(err, 'Add to wallet module', true, 'get Book pro API failed');
      }
    }
  };

  getProductType = (ticket) => {
    if (ticket && ticket[this.coveoMapping.classType] === 'ADULT') {
      return this.props.data.adult.value;
    } else if (ticket && ticket[this.coveoMapping.classType] === 'JUNIOR') {
      return this.props.data.seniorJunior.value;
    } else {
      return this.props.data.passes.value;
    }
  };

  getTicketDateInfo = (ticket, product) => {
    const data = {
      validOnLabel: '',
      ticketDate: '',
      dimension8: 'flexible',
      timeSlotLabel: this.props.data.timeSlot.value,
      seatLabel: this.props.data.seatLabel && this.props.data.seatLabel.value,
      timeSlot: '',
    };
    if (!ticket || !product) return data;
    /*if classification = ANP || validityFrame = 6MON display valid until
        else show valid on label*/
    if (
      this.shouldRenderValidUntilLabel(ticket, product) ||
      parseInt(product[this.coveoMapping.validUntilDate]) ||
      product[this.coveoMapping.tab].includes(UIConfig.profile.YASGIFTS)
    ) {
      data.validOnLabel = this.props.data.validUntil.value;
      data.ticketDate = ticket.tickets.length ? ticket.tickets[0].validTo : '';
      data.timeSlot = ticket.tickets.filter((tick) => tick.timeSlot !== null);
    } else {
      data.validOnLabel = this.props.data.showPaymentMethod
        ? this.getValidOnLabel(ticket, product)
        : this.props.data.validOn.value;
      data.ticketDate = this.getValidOnDate(ticket);
      data.dimension8 = moment.utc(data.ticketDate).format(UIConfig.b2c.profile.dateFormat);
    }
    return data;
  };

  shouldRenderValidUntilLabel = (ticket, product) => {
    const criteriaObj = this.props.data.validUntilCriteria ? this.props.data.validUntilCriteria : {};
    for (const criteria in criteriaObj) {
      if (product && product[criteria] === criteriaObj[criteria]) {
        return true;
      }
    }
  };

  getValidOnDate = (ticket) => {
    let validOnDate = '';
    if (ticket.performances && ticket.performances.length) {
      validOnDate = ticket.performances.length && ticket.performances[0].date;
    } else if (ticket.tickets.length && ticket.tickets[0].validFrom && ticket.tickets[0].validTo) {
      validOnDate = ticket.tickets[0].validFrom;
    }
    return validOnDate;
  };

  getValidOnLabel = (ticket, product) => {
    let validOnLabel = '';
    let { ymcMapping } = UIConfig.b2c.purchaseJourney;
    let { eventType } = this.coveoMapping;
    if (
      (ticket.tickets.length && ticket.tickets[0].validFrom && ticket.tickets[0].validTo) ||
      (eventType &&
        product &&
        product[eventType] &&
        (product[eventType].toLowerCase() === ymcMapping.eventType.f1 ||
          product[eventType].toLowerCase() === ymcMapping.eventType.wrx))
    ) {
      validOnLabel = this.props.data.validOn.value;
    }
    return validOnLabel;
  };

  formAddToWalletData = (ticket, product) => {
    const { displayName, accountAKId, accountId } = this.reservationOwnerAccount;
    const { tickets } = ticket;
    tickets &&
      tickets.forEach((item) => {
        const data = {};
        data.productAK = ticket.productId;
        data.performances = ticket.performances;
        data.ticketId = item.ticketId;
        data.reservationCode = this.reservationCode;
        data.activeFrom = item.validFrom
          ? moment.utc(item.validFrom).format(UIConfig.calendar.AnnualPassValidityDateFormat)
          : null;
        data.activeTo = item.validTo
          ? moment.utc(item.validTo).format(UIConfig.calendar.AnnualPassValidityDateFormat)
          : null;
        data.mediaId = Array.isArray(item.media) && !!item.media.length && item.media[0].mediaId;
        data.mediaType = Array.isArray(item.media) && !!item.media.length && item.media[0].mediaType;
        data.productType =
          Array.isArray(ticket.ticketHolderAccounts) && !!ticket.ticketHolderAccounts.length ? 'ANP' : null;
        data.productName = product && product[this.coveoMapping['productName']];
        if (this.tenantId === UIConfig.ymcB2CTenant) {
          data.validfrom = item.validFrom
            ? moment.utc(item.validFrom).format(UIConfig.calendar.AnnualPassValidityDateFormat)
            : null;
          data.validto = item.validTo
            ? moment.utc(item.validTo).format(UIConfig.calendar.AnnualPassValidityDateFormat)
            : null;
          data.rowseat = item.Seat;
          data.time = ticket.timeSlot && ticket.timeSlot?.split('-')?.[0];
          const validOnDate = this.getValidOnDate(ticket);
          data.validon = validOnDate
            ? moment.utc(validOnDate).format(UIConfig.calendar.AnnualPassValidityDateFormat)
            : '';
        }
        if (Array.isArray(ticket.ticketHolderAccounts) && !!ticket.ticketHolderAccounts.length) {
          data.name = item.ticketHolder.displayName;
          data.accountAk = item.ticketHolder.accountAKId;
          data.accountID = item.ticketHolder.accountId;
        } else {
          data.name = displayName;
          data.accountAk = accountAKId;
          data.accountID = accountId;
        }
        this.formGetProRequestData.push(data);
      });
  };

  getImageUrlBasedOnMobileOS = () => {
    const { addToWallet } = this.props.data;
    if (detectViewPort() === UIConfig.viewportTypes.mobile && !isEmpty(addToWallet)) {
      if (iOSDevice()) {
        return addToWallet.iosImage;
      } else {
        return addToWallet.androidImage;
      }
    }
    return null;
  };

  getDimension7Data = (ticket) => {
    const cartData = JSON.parse(getSessionStorage(UIConfig.localStoreKeys.purchaseJourney.cartData));
    if (cartData?.cart?.items && cartData?.cart?.items.length > 0) {
      let ticketFromCart = cartData?.cart?.items.filter((cartTicket) => cartTicket.productId === ticket.productId);
      if (ticketFromCart[0]) {
        return ticketFromCart[0].addedFromType;
      }
    }
    return UIConfig.regularText;
  };

  renderTickets = (ticket, index) => {
    const product = this.state.productData.filter((item) => ticket.productId === item[this.coveoMapping.productId])[0],
      productType = this.getProductType(product),
      quantityLabel = this.props.data.quantity ? this.props.data.quantity.value : '';
    let validOnLabel = null,
      ticketDate = null,
      timeSlotLabel = null,
      seatLabel = null,
      timeSlotFilter = this.props.data.timeslotFilterSection;
    let timeSlot = null;
    //svashisth - if ticket has validTo date label should be valid until else valid on
    const data = this.getTicketDateInfo(ticket, product);
    ticket && product && this.formAddToWalletData(ticket, product);
    validOnLabel = data.validOnLabel;
    timeSlotLabel = data.timeSlotLabel;
    // timeSlot = data?.timeSlot;
    seatLabel = data.seatLabel;
    ticketDate = data.ticketDate;
    const category = product && product[this.coveoMapping['tab']]; // cache coveo product category
    const firstCategory = Array.isArray(category) ? category[0] : category; // pick category at index 0
    let productCategory = ''; // default category empty
    if (firstCategory && firstCategory.indexOf('_') !== -1) {
      // get string before "_"
      productCategory = firstCategory.substring(0, firstCategory.indexOf('_'));
    } else {
      productCategory = firstCategory;
    }

    // Adding analytics info only when ya varient available
    let yaAnalyticsInfo;
    if (this.yaOrderConfirmation) {
      yaAnalyticsInfo = {
        dimension10: ticket.timeSlot,
        dimension11: ticket.performances.length && ticket.performances[0].performanceId,
        productId: ticket.productId,
      };
    }
    const ymcCategory = GTMData.getCategoryNameFromEventType(product && product[this.coveoMapping['eventType']]);
    const { promotionAppliedList } = ticket;
    const validCpn =
      promotionAppliedList?.length &&
      promotionAppliedList.find((cpn) => cpn.promotionType === '2' || cpn.promotionType === '4');
    const gtmProductsData =
      this.tenantId === UIConfig.ymcB2CTenant
        ? {
            name: (product && product[this.coveoMapping['productName']]) || '',
            id: (product && product[this.coveoMapping['productId']]) || '',
            price: parseFloat(ticket.price.gross / ticket.quantity) || '',
            quantity: ticket && ticket.quantity ? parseInt(ticket.quantity, 10) : '',
            category: `${ymcCategory}/${productCategory}`,
            addons:
              productCategory &&
              productCategory.toLowerCase() === UIConfig.b2c.purchaseJourney.recommendationTabCode &&
              product &&
              product[this.coveoMapping['category']] &&
              product &&
              product[this.coveoMapping['category']][0]
                ? product && product[this.coveoMapping['category']][0]
                : '',
            date: ticketDate ? moment(ticketDate).format('YYYY-MM-DD') : '',
          }
        : this.tenantId === UIConfig.SWADB2C
        ? {
            name: (product && product[this.coveoMapping['productName']]) || '',
            id: (product && product[this.coveoMapping['productId']]) || '',
            price: parseFloat(ticket.price.gross / ticket.quantity) || '',
            brand: (product && product[this.coveoMapping['parksKey']])?.join('|') || '',
            category: productCategory,
            variant: (product && product[this.coveoMapping['ticketType']]) || '',
            quantity: parseFloat(ticket.quantity) || '',
            nosadult:
              product && product[this.coveoMapping['classType']] === 'ADULT' ? parseInt(ticket.quantity, 10) : 0,
            noschild:
              product && product[this.coveoMapping['classType']] === 'JUNIOR' ? parseInt(ticket.quantity, 10) : 0,
            dateType: data.dimension8,
            dateOfVisit: data.ticketDate ? moment.utc(data.ticketDate).format(UIConfig.b2c.profile.dateFormat) : 'N/A',
            ...yaAnalyticsInfo,
            dimension2: 'Yes',
            affiliation: 'Online Store',
            dimension5: productCategory,
            dimension6: data.ticketDate ? moment.utc(data.ticketDate).format(UIConfig.b2c.profile.dateFormat) : 'N/A',
            dimension7: this.getDimension7Data(ticket),
            dimension11:
              product && product[this.coveoMapping['ticketVal']]?.toUpperCase() === '1DAY' ? 'Dated' : 'Flexible',
            metric1: product && product[this.coveoMapping['classType']] === 'ADULT' ? parseInt(ticket.quantity, 10) : 0,
            metric2:
              product && product[this.coveoMapping['classType']] === 'JUNIOR' ? parseInt(ticket.quantity, 10) : 0,
            metric3: 0,
            discount: Math.abs(parseFloat(validCpn?.discount)) || undefined,
          }
        : {
            name:
              (product && product[this.coveoMapping['gaTitle']]) ||
              (product && product[this.coveoMapping['productName']]) ||
              '',
            id: (product && product[this.coveoMapping['productId']]) || '',
            price: parseFloat(ticket.price.gross / ticket.quantity) || '',
            brand: (product && product[this.coveoMapping['parksKey']]) || '',
            category: productCategory,
            variant: (product && product[this.coveoMapping['ticketType']]) || '',
            quantity: parseFloat(ticket.quantity) || '',
            discount: Math.abs(parseFloat(validCpn?.discount)) || undefined,
            nosadult:
              product && product[this.coveoMapping['classType']] === 'ADULT' ? parseInt(ticket.quantity, 10) : 0,
            noschild:
              product && product[this.coveoMapping['classType']] === 'JUNIOR' ? parseInt(ticket.quantity, 10) : 0,
            dateType: data.dimension8,
            dateOfVisit: data.ticketDate ? moment.utc(data.ticketDate).format(UIConfig.b2c.profile.dateFormat) : 'N/A',
            ...yaAnalyticsInfo,
          };

    if (this.tenantId === UIConfig.SWADB2C && parseQueryString('status') === 'true') {
      gtmProductsData.dimension8 = 'Successful';
      if (parseQueryString('ref') || window.location?.hash?.split('#')[1]) {
        gtmProductsData.dimension9 = parseQueryString('ref') || window.location?.hash?.split('#')[1];
      }
    }
    if (this.tenantId === UIConfig.SWADB2C && parseQueryString('payment_error')) {
      gtmProductsData.dimension8 = 'Failed';
      gtmProductsData.dimension12 = parseQueryString('payment_error');
      if (parseQueryString('ref') || window.location?.hash?.split('#')[1]) {
        gtmProductsData.dimension9 = parseQueryString('ref') || window.location?.hash?.split('#')[1];
      }
    }
    this.gtmData.push(gtmProductsData);

    if (this.state.orderData.total.gross && this.state.orderData.total.currency && window.tracking) {
      window.tracking(this.state.orderData.total.currency, this.state.orderData.total.gross);
    }
    let ticketName = '';

    if (this.isStepTwo && this.props.data.ticketName && this.props.data.ticketName.value) {
      ticketName = this.props.data.ticketName.value;
    }
    return (
      <ConfirmedTicketTile
        data={product}
        validOn={validOnLabel}
        timeSlot={timeSlotLabel}
        timeSlotFilter={timeSlotFilter}
        quantity={quantityLabel}
        ticketDate={ticketDate}
        product={ticket}
        productType={productType}
        coveoKeyMap={this.coveoMapping}
        key={index}
        isStepTwo={this.isStepTwo}
        ticketName={ticketName}
        ticketDisclaimerTitle={this.ticketDisclaimerTitle}
        ticketDisclaimerDescription={this.ticketDisclaimerDescription}
        multiparkTicketImage={this.multiparkTicketImage}
        ticketImage={this.ticketImage}
        tenantId={GTMData.getTenantId()}
        isRenewAnnualPassFlag={this.isRenewAnnualPassFlag}
        annualPassProductCategorization={this.props.data.annualPassProductCategorization}
        yaOrderConfirmation={this.yaOrderConfirmation}
        isYANonSeatedEvent={this.isYANonSeatedEvent}
        seatLabel={seatLabel}
        propData={{
          addToCalendar: {
            label: this.props.data.addToCalendarLabel,
            eventLocation: this.props.data.eventLocation,
            serviceCall: this.props.data.services.AddToCalendar,
          },
          formulaOneData: this.props.data.formulaOneData,
          variant: this.variant,
        }}
        index={index}
        image={this.getImageUrlBasedOnMobileOS()}
        onImageClick={this.getBookProUrl}
        addToWallet={this.props.data.addToWallet}
        showYMCAddToWallet={!!this.getPassType(ticket)}
        isCorpCouponApplied={isCorpCouponApplied(this.state?.orderData?.promotions?.[0])}
        enableAddToWallet={this.props.data.enableAddToWallet}
      />
    );
  };

  /**
   * download is a function which enables to download the ticket hitting the ticket service API
   * @param    {event}    object onClick event
   * @return    {file}    object pdf file i.e ticket
   */
  getConfigDownloadTicket = (promoCode) => {
    return {
      service: guestUserEmail()
        ? this.props.data.services.eticketDownloadAnonymous
        : this.props.data.services.eTicketDownload,
      orderId: this.orderId,
      errorData: this.state.errorData,
      promoCode: this.promoCode,
      fileName: this.state.orderData.orderId,
    };
  };

  download = () => {
    const reservationId = getOrderIdpromoCode().split('_');
    if (reservationId[0] == this.reservationCode) {
      this.promoCode = reservationId[1] == 'null' ? null : reservationId[1];
    }
    OrderService.downloadOrderTickets(this.getConfigDownloadTicket(this.promoCode)).catch((response) => {
      this.setState({ errorData: response.error });
    });
  };

  renderBillingSummary = (currency, net, tax, gross, fee, shippingCharges) => {
    return (
      <>
        <div className="c-booking-confirmation-subtotal heading-4">
          <span className={className({ label: this.isSwad })}>{this.props.data.subTotal.value}</span>
          {shippingCharges && shippingCharges.price && shippingCharges.price.net ? (
            <span className={className({ value: this.isSwad })}>
              {currency + ' ' + toTwoDecimalPlaces(Number(net - shippingCharges.price.net))}
            </span>
          ) : (
            <span className={className({ value: this.isSwad })}>
              {currency + ' ' + toTwoDecimalPlaces(Number(net))}
            </span>
          )}
        </div>
        {shippingCharges && shippingCharges.price && shippingCharges.price.net && this.props.data.formulaOneData && (
          <div className="c-booking-confirmation-tax heading-4 shipping-charges">
            <span className={className({ label: this.isSwad })}>
              {this.props.data.formulaOneData.shippingData.shippingLabel}
            </span>
            <span className={className({ value: this.isSwad })}>
              {currency + ' ' + toTwoDecimalPlaces(Number(shippingCharges.price.net))}
            </span>
          </div>
        )}
        {this.promotions && this.props.data.discount.value && this.promotions[0] && this.promotions[0].discount ? (
          <div
            className={className('c-booking-confirmation-tax heading-4', {
              'c-booking-confirmation-discount': this.isSwad,
            })}
          >
            <span className={className({ label: this.isSwad })}>
              {!this.isSwad ? `${this.props.data.discount.value} - ` : this.props.data.discount.value}
            </span>
            <span className={className({ value: this.isSwad })}>
              {currency + ' ' + toTwoDecimalPlaces(Number(this.promotions[0].discount))}
            </span>
          </div>
        ) : (
          ''
        )}
        <div className="c-booking-confirmation-tax heading-4">
          <span className={className({ label: this.isSwad })}>{this.props.data.vat.value}</span>
          <span className={className({ value: this.isSwad })}>{currency + ' ' + toTwoDecimalPlaces(Number(tax))}</span>
        </div>
        {fee && this.props.data.feeLabel.value && Number(fee) > 0 && (
          <div className="c-booking-confirmation-tax heading-4">
            <span className={className({ label: this.isSwad })}>{this.props.data.feeLabel.value}</span>
            <span className={className({ value: this.isSwad })}>
              {currency + ' ' + toTwoDecimalPlaces(Number(fee))}
            </span>
          </div>
        )}
        <div className="c-booking-confirmation-total heading-3" tabIndex="0">
          <span className={className({ label: this.isSwad })}>{this.props.data.total.value}</span>
          <span className={className({ value: this.isSwad })}>
            {currency + ' ' + toTwoDecimalPlaces(Number(gross))}
          </span>
        </div>
      </>
    );
  };
  renderTotal = () => {
    const { currency, net, tax, gross, fee } = this.state.orderData.total;
    const { shippingCharges } = this.state.orderData;

    return (
      <div
        className={`c-booking-confirmation-price ${
          isCorpCouponApplied(this.state?.orderData?.promotions?.[0]) ? 'mb-100' : ''
        }`}
      >
        {this.isSwad && this.props.data.billingSummaryLabel && (
          <DynamicContent
            tagName="p"
            attrs={{ className: 'order-summary-title' }}
            innerHtml={this.props.data.billingSummaryLabel}
          />
        )}
        {this.isSwad ? (
          <div className="c-booking-confirmation-price-contentwrapper">
            {this.renderBillingSummary(currency, net, tax, gross, fee, shippingCharges)}
          </div>
        ) : (
          this.renderBillingSummary(currency, net, tax, gross, fee, shippingCharges)
        )}
      </div>
    );
  };

  checkApplicablePromo = () => {
    const promotions = this.state.orderData?.promotions || [];
    if (!promotions.length) {
      return '';
    }

    const { promotionType, code } = promotions[0];
    if (checkParksTenants()) {
      if (promotionType === '2' || promotionType === '4') {
        const prmOrderId = getOrderIdpromoCode()?.split('_');
        const appliedPromo = (prmOrderId?.length && prmOrderId?.[1]) || '';
        return appliedPromo || code || '';
      }
      return '';
    }
    return code;
  };

  analyticObj = (gtmData, products, isGtm4) => {
    const keyList = GTMData.getKeyList(isGtm4);
    const validCoupon = this.checkApplicablePromo();
    return {
      [keyList.currencyCode]: 'AED',
      type: 'ticket',
      purchase: {
        actionField: {
          id: this.state.orderData.reservationCode || this.state.orderData.orderNo || undefined,
          revenue: this.state?.orderData?.total?.net ? parseFloat(this.state.orderData.total.net, 10) : '',
          tax: this.state?.orderData?.total?.tax ? parseFloat(this.state.orderData.total.tax, 10) : '',
          coupon: validCoupon,
          discountAmount:
            validCoupon && this.promotions && this.promotions[0] && Math.abs(Number(this.promotions[0]?.discount)) > 0
              ? Math.abs(Number(this.promotions[0].discount))
              : 0,
        },
        [keyList.products]: this.yaOrderConfirmation
          ? getYABookingConfirmationAnalytics(products, gtmData, this.state.orderData.items)
          : getFWBookingConfirmationAnalytics(gtmData, keyList, isGtm4),
      },
    };
  };

  isTimeSlotVisible = (data, timeSlotMap) => {
    const key = timeSlotMap.timeslotFilterKey || '';
    if (data && key && data[key] && timeSlotMap.timeslotFilterValue) {
      for (let i = 0; i < data[key].length; i++) {
        if (timeSlotMap.timeslotFilterValue.indexOf(data[key][i]) !== -1) {
          return true;
        }
      }
    }
    return false;
  };

  getAdultQty = (classType, experienceCatgory, qty) => {
    if (classType === 'ADULT' || experienceCatgory === 'PRIMARY' || experienceCatgory === 'Secondary') {
      return qty;
    }
    return 0;
  };

  compareIfExists = (unqiueProd, restObj) => {
    return (
      unqiueProd?.length &&
      unqiueProd.findIndex((itm) => {
        const { quantity, ...uniqueObj } = itm;
        return JSON.stringify(uniqueObj) === JSON.stringify(restObj);
      })
    );
  };

  /**
   * pushGTMFlags pushing gtmData in analyticsObj which in turn modifies dataLayer
   * @param {*} gtmData
   */
  pushGTMFlags(gtmData) {
    let unqiueProd = [];
    window.dataLayer = window.dataLayer || [];
    const productList = createMappedData(this.state.productData, this.coveoMapping);

    if (this.tenantId === UIConfig.ymcB2CTenant) {
      this.analyticObj.purchase = { ...this.analyticObj.purchase, ...GTMData.getUserData };
      delete this.analyticObj.type;
    }

    window.dataLayer.push({
      paymentMode: parseQueryString('ref'),
      ecommerce: this.analyticObj(gtmData, productList),
      event: 'Thankyoupage',
      myPassId: getYasIdGTMData(),
      emailId: getEmailIdGTMData(),
      upgrade: this.state.isTicketUpgrade ? 'True' : 'False',
    });

    let purchaseStatus;
    purchaseStatus = JSON.parse(localStorage.getItem('purchaseEventSuccess'));
    if (this.purchaseComplete && !purchaseStatus) {
      try {
        const keyList = GTMData.getKeyList(true);
        const { currencyCode, purchase } = this.analyticObj(gtmData, productList);
        const { products = [], actionField } = purchase;
        const { coupon, discountAmount, revenue, tax, id } = actionField;
        const items = [];
        const tenantName = getLoggedInUser()?.tenantName || 'FWAD';
        const purchasedProd = getLocalStorageByKey('cartPurchaseProd') || [];
        products.forEach((prd) => {
          const { quantity, ...restObj } = prd;
          const existingProdIdx = this.compareIfExists(unqiueProd, restObj);
          if (existingProdIdx === -1 || !unqiueProd.length) {
            unqiueProd.push(prd);
          } else {
            const exstinPrd = unqiueProd[existingProdIdx];
            unqiueProd[existingProdIdx] = {
              ...exstinPrd,
              quantity: Number(exstinPrd.quantity) + Number(prd.quantity),
            };
          }
        });
        unqiueProd?.length &&
          unqiueProd.forEach((item) => {
            const { dateOfVisit, isFlexible, category, id, price, quantity, name, discount } = item;
            const prodDetails = productList.find((prdId) => prdId.productId === id);
            const { itemType, classType, classTypeName, experienceCatgory, gaCategory } = prodDetails;
            const timeslot = this.state?.orderData?.items.find((itm) => itm?.productId === id)?.timeSlot;
            const coveoProd = this.state?.productData?.find((itm) => itm?.[this.coveoMapping?.['productId']] === id);
            const purchdProd = purchasedProd.length && purchasedProd.find((prdId) => prdId.productId === id);
            items.push({
              affiliation: tenantName,
              [keyList.dateOfVisit]: itemType === 'ANP' ? undefined : dateOfVisit || undefined,
              [keyList.dateType]: isFlexible || itemType === 'ANP' || itemType === 'VCH' ? 'flexible' : 'dated',
              [keyList.category]: gaCategory || purchdProd?.gaCategory || category,
              [keyList.id]: id,
              [keyList.discount]: (discount && Math.abs(Number(discount)) / quantity) || undefined,
              [keyList.nosAddonGuest]: experienceCatgory === 'AddonGuest' ? quantity : 0,
              [keyList.nosadult]: this.getAdultQty(classType, experienceCatgory, quantity),
              [keyList.nosjunior]: classType === 'JUNIOR' ? parseFloat(quantity) : 0,
              [keyList.itemVariant]: classType || classTypeName || undefined,
              [keyList.name]: purchdProd?.gaTitle || name,
              ...(this.isTimeSlotVisible(coveoProd, this.props.data.timeslotFilterSection) && {
                [keyList.timeSlot]: timeslot || '',
              }),
              index: getProductIdx(id),
              price,
              quantity,
            });
          });

        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            currency: currencyCode,
            coupon: coupon || undefined,
            [keyList.discountValue]: discountAmount || undefined,
            [keyList.paymentType]: parseQueryString('ref'),
            tax,
            [keyList.transactionId]: id,
            value: (revenue + tax).toFixed(2),
            items,
          },
          my_pass_id: getYasIdGTMData() || undefined,
          upgrade: this.state.isTicketUpgrade ? 'True' : 'False',
        });
        localStorage.setItem('purchaseEventSuccess', true);
        localStorage.removeItem('cartPurchaseProd');
      } catch (error) {
        console.log('GA4 error', error);
      }
    }

    if (this.tenantId === UIConfig.yasArenaB2CTenant.toLowerCase()) {
      const { actionField, products } = this.analyticObj(gtmData, productList)?.purchase;
      const { id, name, quantity, price } = products?.length && products[0];
      const { success, failed } = UIConfig.paymentRes;
      window.dataLayer.push({
        event: 'purchaseActionData',
        currencyCode: UIConfig.currencyArabic,
        eventid: id || name,
        price: price,
        quantity: quantity,
        emailId: getEmailIdGTMData(),
        paymentStatus: this.status ? success : failed,
        transactionId: actionField?.id,
      });
      GTMData.push(UIConfig.ga4Constants.PURCHASE, {
        products: { products, actionField, currency: getCurrency() },
      });
      window.localStorage.removeItem('EAGa4Storage');
    }
  }

  /**
   * @function - renderSubTitle return thankyou subtitle with email wrapped in span
   * @param - Thankyou sub title
   */
  renderSubTitle = (subTitle) => {
    const isCorpCouponPurchase = isCorpCouponApplied(this.state?.orderData?.promotions?.[0]);
    const corpEmailId = isCorpCouponPurchase ? getCorporateUserEmailId() : '';

    const userPrimaryEmail = guestUserEmail()
      ? JSON.parse(localStorage.getItem('guestUserEmail')).email
      : this.user.email;

    const userEmailId = corpEmailId || userPrimaryEmail;

    const innerHtml = this.isStepTwo
      ? subTitle.replace('{0}', '<span> <span>' + userEmailId + '</span></span>')
      : subTitle.replace('{0}', '<span>' + userEmailId + '</span>');

    return <DynamicContent tagName="p" attrs={{ className: 'body-2 thankyou-description' }} innerHtml={innerHtml} />;
  };

  renderModeOfPayment = (paymentInfo) => {
    let modeOfPayment = null;
    for (var mode in this.props.data.paymentMethod) {
      if (paymentInfo.mode.toLowerCase() === mode.toLowerCase()) {
        modeOfPayment = this.props.data.paymentMethod[mode];
        break;
      }
    }
    return (
      <div className="c-booking-confirmation-payment-mode">
        {this.props.data.modeOfPayment}: <span>{modeOfPayment}</span>
      </div>
    );
  };

  isCorporatePurchase = () => {
    const corpEmailId = getCorporateUserEmailId();
    return corpEmailId?.length > 0 ? true : false;
  };

  renderOrderData = (
    renewTitle,
    renewSubTitle,
    renewReservationTitle,
    renewReservationSubTitle,
    renewDownloadTicket,
    renewBookingIDLabel,
  ) => {
    const { reservationCode, orderNo } = this.state.orderData;
    const bookingId = this.isStepTwo && !this.state.isRenewPage ? reservationCode : orderNo;
    return (
      <div>
        <div className="c-booking-confirmation-header">
          <div className="thankyou-content">
            <p className="heading-2 thankyou-title">{renewTitle}</p>
            {this.renderSubTitle(renewSubTitle)}
          </div>
          <div className="reservation-content" tabIndex="0">
            <p className="heading-3 reservation-title">{renewReservationTitle}</p>
            <span>
              <p className="body-2 reservation-subtitle">{renewReservationSubTitle}</p>
              <p className="body-2 reservation-id">{renewBookingIDLabel.replace('{0}', bookingId)}</p>
            </span>
            {this.state.orderData.paymentInfo &&
              this.state.orderData.paymentInfo[0] &&
              this.props.data.showPaymentMethod &&
              this.tenantId === UIConfig.ymcB2CTenant &&
              this.renderModeOfPayment(this.state.orderData.paymentInfo[0])}
          </div>
          {this.isTicketUpgradeEnabled &&
            this.state.ticketUpgradeData &&
            this.state.ticketUpgradeData?.ticketUpgradeSuggestions?.length > 0 && (
              <div className="ticket-upgrade-parent">
                <TicketUpgradeConfirmation
                  ticketUpgradeData={this.state.ticketUpgradeData}
                  ticketUpgradeBackgroundImage={this.ticketUpgradeBackgroundImage}
                />
              </div>
            )}
        </div>
        {!(this.isParksExpressCheckout && this.state.isOrderPurchasedStatus) && (
          <div className="c-booking-confirmation-tickets">
            {this.state.productData && this.state.orderData.items.map((item, index) => this.renderTickets(item, index))}
          </div>
        )}
        {!(this.isParksExpressCheckout && this.state.isOrderPurchasedStatus) && this.renderTotal()}
        {!(this.isParksExpressCheckout && this.state.isOrderPurchasedStatus) &&
          this.state.orderData.paymentInfo &&
          this.state.orderData.paymentInfo[0] &&
          this.props.data.showPaymentMethod &&
          this.tenantId !== UIConfig.ymcB2CTenant &&
          this.renderModeOfPayment(this.state.orderData.paymentInfo[0])}
        {!(this.isParksExpressCheckout && this.state.isOrderPurchasedStatus) &&
          !isCorpCouponApplied(this.state?.orderData?.promotions?.[0]) && (
            <div
              className={className('c-booking-confirmation-footer', {
                'step-two': this.isStepTwo,
              })}
            >
              {this.renderViewOrderBtn()}
              {this.props.data.enableAddToWallet && !this.disableDownloadButton && renewDownloadTicket && (
                <div className="btn-primary body-4 download-btn">
                  <button type="button" onClick={this.download}>
                    {renewDownloadTicket}
                  </button>
                </div>
              )}
            </div>
          )}
        {this.variant === 'v-ymc-booking-confirmation-summary' && this.renderKidsGoFreeEventField()}
      </div>
    );
  };

  renderSwadOrderData = (
    renewTitle,
    renewSubTitle,
    renewDownloadTicket,
    renewBookingIDLabel,
    reneworderNumberLabel,
    renewticketLabel,
  ) => {
    const { reservationCode, orderNo } = this.state.orderData;
    const bookingId = this.isStepTwo && !this.state.isRenewPage ? reservationCode || orderNo : orderNo;
    const bookingLabel = this.isStepTwo && !this.state.isRenewPage ? renewBookingIDLabel : reneworderNumberLabel;
    return (
      <div className="container">
        <div className="c-booking-confirmation-title">
          <p className="heading-2 thankyou-title">{renewTitle}</p>
        </div>
        <div className="row">
          <div className="col-12 col-md-7 col-lg-8">
            <div className="c-booking-confirmation-content">
              <div className="reservation-content" tabIndex="0">
                {this.isSwad ? (
                  //show reservstion number / order number if reservation number is not avilable. only for seaworld
                  <p className="reservation-ordernumber">{bookingLabel.replace('{0}', bookingId)}</p>
                ) : (
                  <p className="reservation-ordernumber">
                    {reneworderNumberLabel.replace('{0}', this.state.orderData.orderNo)}
                  </p>
                )}

                {// hide in seaworld tenant
                !this.isSwad && this.isStepTwo && !this.state.isRenewPage && (
                  <>
                    <p className="body-2 reservation-id">
                      {renewBookingIDLabel.replace('{0}', this.state.orderData.reservationCode)}
                    </p>
                  </>
                )}
                {this.renderSubTitle(renewSubTitle)}
              </div>
              {this.isTicketUpgradeEnabled &&
                this.state.ticketUpgradeData &&
                this.state.ticketUpgradeData.ticketUpgradeSuggestions?.length > 0 && (
                  <div className="ticket-upgrade-parent">
                    <TicketUpgradeConfirmation
                      ticketUpgradeData={this.state.ticketUpgradeData}
                      ticketUpgradeBackgroundImage={this.ticketUpgradeBackgroundImage}
                    />
                  </div>
                )}
              <div className={`c-booking-confirmation-tickets ${this.isTicketUpgradeEnabled ? 'extended-width' : ''}`}>
                <p className="ticket-title">{renewticketLabel}</p>
                <div className="ticket-wrapper">
                  {this.state.productData &&
                    this.state.orderData.items.map((item, index) => this.renderTickets(item, index))}
                </div>
              </div>
              {detectViewPort() !== 'mobile' && !isCorpCouponApplied(this.state?.orderData?.promotions?.[0]) && (
                <div
                  className={className('c-booking-confirmation-footer', {
                    'step-two': this.isStepTwo,
                  })}
                >
                  <div className="btn-wrapper">
                    {renewDownloadTicket && (
                      <div className="btn-primary body-4 download-primary-btn">
                        <button type="button" onClick={this.download}>
                          {renewDownloadTicket}
                        </button>
                      </div>
                    )}
                    {this.renderViewOrderBtn()}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-4">
            {this.renderTotal()}
            {detectViewPort() === 'mobile' && !isCorpCouponApplied(this.state?.orderData?.promotions?.[0]) && (
              <div
                className={className('c-booking-confirmation-footer', {
                  'step-two': this.isStepTwo,
                })}
              >
                <div className="btn-wrapper">
                  {renewDownloadTicket && (
                    <div className="btn-primary body-4 download-primary-btn">
                      <button type="button" onClick={this.download}>
                        {renewDownloadTicket}
                      </button>
                    </div>
                  )}
                  {this.renderViewOrderBtn()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderGetOrderDOM = (
    renewTitle,
    renewSubTitle,
    renewReservationTitle,
    renewReservationSubTitle,
    renewDownloadTicket,
    renewBookingIDLabel,
    reneworderNumberLabel,
    renewticketLabel,
  ) => {
    return (
      <div>
        {this.state.orderData &&
          (!this.isSwad
            ? this.renderOrderData(
                renewTitle,
                renewSubTitle,
                renewReservationTitle,
                renewReservationSubTitle,
                renewDownloadTicket,
                renewBookingIDLabel,
              )
            : this.renderSwadOrderData(
                renewTitle,
                renewSubTitle,
                renewDownloadTicket,
                renewBookingIDLabel,
                reneworderNumberLabel,
                renewticketLabel,
              ))}
      </div>
    );
  };

  isF1Product = () => {
    let { productData } = this.state;
    let { eventType } = this.coveoMapping;
    let { ymcMapping } = UIConfig.b2c.purchaseJourney;
    let checkF1Product =
      productData &&
      eventType &&
      productData.filter(
        (product) => product && product[eventType] && product[eventType].toLowerCase() === ymcMapping.eventType.f1,
      );
    return !!(checkF1Product && Array.isArray(checkF1Product) && checkF1Product.length > 0);
  };

  getPassType = (ticket) => {
    const productList = createMappedData(this.state.productData, this.coveoMapping);
    const selectedProduct = productList.find((product) => product.productId === ticket?.productId);
    return this.isF1Product() ? selectedProduct?.f1PassType : selectedProduct?.passType;
  };

  renderOrder = () => {
    this.isRenewAnnualPassFlag = parseQueryString('isRenewAnnualPass') ? parseQueryString('isRenewAnnualPass') : '';
    const isRenewAnnualPassLocal = !this.isRenewAnnualPassFlag;

    if (this.isParksExpressCheckout && this.state.isOrderDataLoading) {
      return null;
    }

    if (!this.orderStatus || (this.isParksExpressCheckout && this.state.orderData)) {
      if (isRenewAnnualPassLocal) {
        let {
          title: renewTitle,
          subTitle: renewSubTitle,
          reservationTitle: renewReservationTitle,
          reservationSubTitle: renewReservationSubTitle,
          downloadTicket: renewDownloadTicket,
          bookingIDLabel: renewBookingIDLabel,
          orderNumberLabel: reneworderNumberLabel,
          ticketLabel: renewticketLabel,
        } = this.props.data;

        return (
          <div>
            {this.renderGetOrderDOM(
              renewTitle,
              renewSubTitle,
              renewReservationTitle,
              renewReservationSubTitle,
              renewDownloadTicket,
              renewBookingIDLabel,
              reneworderNumberLabel,
              renewticketLabel,
            )}
          </div>
        );
      } else {
        let {
          annualPaasTitle: renewTitle,
          annualPaasSubTitle: renewSubTitle,
          annualPaasReservationTitle: renewReservationTitle,
          annualPaasReservationSubTitle: renewReservationSubTitle,
          annualPaasDownloadTicket: renewDownloadTicket,
          annualPaasBookingIDLabel: renewBookingIDLabel,
          orderNumberLabel: reneworderNumberLabel,
          ticketLabel: renewticketLabel,
        } = this.props.data;

        return (
          <div>
            {this.renderGetOrderDOM(
              renewTitle,
              renewSubTitle,
              renewReservationTitle,
              renewReservationSubTitle,
              renewDownloadTicket,
              renewBookingIDLabel,
              reneworderNumberLabel,
              renewticketLabel,
            )}
          </div>
        );
      }
    } else {
      return (
        <>
          {!this.isSwad ? (
            this.renderBookTicketBtn()
          ) : (
            <div className="bookticket-btn-wrapper container">{this.renderBookTicketBtn()}</div>
          )}
        </>
      );
    }
  };

  renderKidsGoFreeEventField = () => {
    let { productData } = this.state;
    let { eventType } = this.coveoMapping;
    let { ymcMapping } = UIConfig.b2c.purchaseJourney;
    const { registerhere } = this.props.data;

    let checkF1Product =
      productData &&
      eventType &&
      productData.filter(
        (product) => product && product[eventType] && product[eventType].toLowerCase() === ymcMapping.eventType.f1,
      );

    if (registerhere && checkF1Product && checkF1Product.length) {
      return (
        <DynamicContent tagName="div" attrs={{ className: 'c-booking-kids-go-free-event' }} innerHtml={registerhere} />
      );
    }
  };

  renderBookTicketBtn = () => {
    if (localStorage.getItem(UIConfig.localStoreKeys.emailInvoiceTemplateId) && !checkInlineSignup()) {
      localStorage.removeItem(UIConfig.localStoreKeys.emailInvoiceTemplateId);
    }

    window.PubSub.publish(UIConfig.events.CONFIRMATION_PAGE_REFRESH);

    const btnHtml = this.props.data.ctaBack && (
      <div className="c-booking-confirmation-error--backCta btn-primary">
        {this.props.data.ctaBack.html ? (
          <DynamicContent tagName="div" innerHtml={this.props.data.ctaBack.html} />
        ) : (
          <DynamicContent
            tagName="a"
            attrs={{ href: this.props.data.ctaBack.href }}
            innerHtml={this.props.data.ctaBack.label}
          />
        )}
      </div>
    );
    return btnHtml;
  };

  renderViewOrderBtn = () => {
    return this.props.data.viewOrder && !getExpressCheckoutPostPurchaseUser() ? (
      <div className={className({ vieworderbtn: this.isSwad }, { 'btn-primary body-4 download-btn': !this.isSwad })}>
        {this.props.data.viewOrder.html ? (
          <DynamicContent tagName="div" innerHtml={this.props.data.viewOrder.html} />
        ) : (
          <DynamicContent
            tagName="a"
            attrs={{ href: this.props.data.viewOrder.href }}
            innerHtml={this.props.data.viewOrder.label}
          />
        )}
      </div>
    ) : null;
  };

  renderErrorSummary = () => {
    return <div className="c-booking-confirmation-error">{this.renderBookTicketBtn()}</div>;
  };

  showApiError = () => {
    if (this.orderStatus) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <div className={className({ 'container confirmation-summary': this.isSwad })}>
        {this.state.isReconcileError ? (
          <ErrorSummary data={this.state.errorData} />
        ) : (
          <>
            {(this.yaOrderConfirmation || this.isSwad) && this.state.errorData && this.showApiError() && (
              <ErrorSummary data={this.state.errorData} />
            )}
            {this.status && (
              <div
                className={className(
                  'component c-booking-confirmation',
                  {
                    'c-booking-confirmation_step-two': this.isStepTwo,
                  },
                  { 'border-bottom-in-mobile': detectMobile() && this.props.data?.showBorderBottomInMobile },
                )}
              >
                <div className="w--content">{this.status && this.renderOrder()}</div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
