import React, { useEffect } from 'react';
import './masonry-grid-item.scss';

const MasonryGridItem = ({ columnLayout, photos, onClick, mobile }) => {
  const totalColumn = columnLayout;
  const columnWidth = mobile ? 100 : 100 / totalColumn;
  const columns = Array.from({ length: totalColumn }, () => []);

  photos.forEach((img, i) => {
    columns[i % totalColumn].push(img);
  });

  return (
    <div className="row">
      {columns.map((col, i) => (
        <div id={`column-${i}`} key={`column-${i}`} className="column " style={{ width: `${columnWidth}%` }}>
          {col.map((img, j) => (
            <>
              <img
                key={`image-${img.index}`}
                id={`images_${img.index}`}
                src={img.src}
                alt={`Image ${img.index}`}
                onClick={(e) => {
                  onClick(e, { photo: { src: img.src }, index: img.index });
                }}
                loading="lazy"
              />
              {mobile && <div className="mobile-title">{img.title}</div>}
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export default React.memo(MasonryGridItem);
