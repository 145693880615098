import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { groupBookingErrorAnalytics } from '../../../../common/analytics-events';
import { logComponentRenderingError } from '../../../../common/logger';
import { DynamicContent, Spinner } from '../../../presentation/base';
import {
  deepCloneObject,
  isMatchTenant,
  isEmpty,
  priceValue,
  toTwoDecimalPlaces,
  checkTenant,
  checkIfParks,
} from '../../../../common/utility';
import UIConfig from '../../../../common/UIConfig';

export default class VisitorsCountSelector extends Component {
  constructor(props) {
    super(props);
    this.currentCount = 0;
    this.isSwad = checkTenant(UIConfig.iamMapping.swad);
    this.isParks = checkIfParks();
    this.maxCount = this.props.isAdultChildOverlay
      ? this.props.maxAvailableTicketsCount - 1
      : parseInt(props.data.primaryCounter.maxCount, 10) || 14;
    this.minCount = parseInt(props.data.primaryCounter.minCount, 10) || 0;
    //conditional check added for maxcount error message - addonsoverlay/addonseventoverlay.
    this.deltaCountForError = isEmpty(props.timeSlotAvailable) && isMatchTenant(UIConfig.ymcB2CTenant) ? 0 : 1;
    this.pplPerTicket = this.props.data.primaryCounter.pplPerTicket || 4;
    const spinnerCount = props.data.additionalCounter
      ? { primaryCounterCount: {}, additionalCounterCount: {} }
      : { primaryCounterCount: {} };
    if (props.data.additionalCounter && props.data.primaryCounter) {
      props.data.additionalCounter.options[0].defaultQuantity =
        props.data.additionalCounter.options[0].minPrimaryCount > props.data.primaryCounter.options[0].defaultQuantity
          ? 0
          : props.data.additionalCounter.options[0].defaultQuantity;
    }

    this.isError = { maxVistorCountError: false, minVistorCountError: false };
    this.state = {
      spinnerCount,
      showError: false,
    };
    this.annualPassGridVariant = props.annualPassGridVariant;
  }

  calculateCurrentCount = () => {
    this.currentCount = 0;
    for (let counter in this.state.spinnerCount) {
      if (this.state.spinnerCount[counter]) {
        this.currentCount += Object.values(this.state.spinnerCount[counter]).reduce(
          (sum, value) =>
            (sum += this.isFamilyProd(Object.keys(this.state.spinnerCount[counter])[0])
              ? value * this.pplPerTicket
              : value),
          0,
        );
      }
    }
  };

  validateCount = () => {
    this.isError.maxVistorCountError = this.currentCount >= this.maxCount + this.deltaCountForError;
    this.isError.minVistorCountError = this.currentCount < this.minCount;
  };

  shouldShowError = (isError) => {
    return Object.values(isError).some((status) => status);
  };

  getErrorMessage = () => {
    return (
      this.state.showError &&
      this.props.data.primaryCounter[Object.keys(this.isError).filter((key) => this.isError[key])[0]]
    );
  };

  updateCountPrimary = (count, category) => {
    const { primaryCounterCount } = this.state.spinnerCount;
    const countPrimary = count < primaryCounterCount[category];
    primaryCounterCount[category] = count;
    let spinnerCount = { ...this.state.spinnerCount, primaryCounterCount };

    if (this.props.data.primaryCounter && this.props.data.primaryCounter.isIncreaseDecreaseEnabled && count) {
      for (let key in primaryCounterCount) {
        if (key !== category) {
          const secondarySpinner = this.props.data.primaryCounter.options[1];
          const countonParentIncrement = countPrimary
            ? primaryCounterCount[key] - Number(secondarySpinner.increaseDecreaseBy)
            : primaryCounterCount[key] + Number(secondarySpinner.increaseDecreaseBy);
          if (countonParentIncrement < parseInt(secondarySpinner.minimumQuantity, 10)) {
            primaryCounterCount[key] = parseInt(secondarySpinner.minimumQuantity, 10);
          } else {
            primaryCounterCount[key] =
              countonParentIncrement <= secondarySpinner.maximumQuantity
                ? countonParentIncrement
                : primaryCounterCount[key];
          }
        }
      }
      spinnerCount = { ...spinnerCount, primaryCounterCount };
    }
    if (this.props.data.additionalCounter && this.props.data.additionalCounter.isIncreaseDecreaseEnabled && count) {
      const { additionalCounterCount } = this.state.spinnerCount;
      for (let key in additionalCounterCount) {
        if (additionalCounterCount[key]) {
          const countonParentIncrement = countPrimary
            ? additionalCounterCount[key] - this.props.data.additionalCounter.options[0].increaseDecreaseBy
            : additionalCounterCount[key] + this.props.data.additionalCounter.options[0].increaseDecreaseBy;
          if (countonParentIncrement < parseInt(this.props.data.additionalCounter.options[0].minimumQuantity, 10)) {
            additionalCounterCount[key] = parseInt(this.props.data.additionalCounter.options[0].minimumQuantity, 10);
          } else {
            additionalCounterCount[key] =
              countonParentIncrement <= this.props.data.additionalCounter.options[0].maxCount
                ? countonParentIncrement
                : additionalCounterCount[key];
          }
        }
      }
      spinnerCount = { ...spinnerCount, additionalCounterCount };
    }
    if (this.props.data.additionalCounter && count < this.props.data.additionalCounter.options[0].minPrimaryCount) {
      const { additionalCounterCount } = this.state.spinnerCount;
      for (let key in additionalCounterCount) {
        if (additionalCounterCount[key]) {
          additionalCounterCount[key] = 0;
        }
      }
      spinnerCount = { ...spinnerCount, additionalCounterCount };
    }

    this.calculateCurrentCount();
    this.validateCount();
    this.isError &&
      groupBookingErrorAnalytics(
        this.props.name || '',
        window.dataLayer && window.dataLayer.length ? window.dataLayer[0].pageName : '',
      );
    this.setState(
      {
        spinnerCount,
        showError: this.shouldShowError(this.isError),
      },
      () => {
        this.props.setVisitorData({
          ...(this.props.annualPassGridVariant && { productCoveoValue: this.props.ticketData?.ticket?.coveoValue }),
          spinnerCount: this.state.spinnerCount,
          isProductOverlayClicked: false,
          category: this.props.ticketData?.ticket?.coveoValue,
        });
      },
    );
  };

  updateCountAdditional = (count, category) => {
    const { additionalCounterCount } = this.state.spinnerCount;
    additionalCounterCount[category] = count;
    this.calculateCurrentCount();
    this.validateCount();
    this.isError && groupBookingErrorAnalytics(this.props.name || '', document.title);
    this.setState(
      {
        spinnerCount: { ...this.state.spinnerCount, additionalCounterCount },
        showError: this.shouldShowError(this.isError),
      },
      () => {
        this.props.setVisitorData({
          spinnerCount: this.state.spinnerCount,
          category: this.props.ticketData?.ticket?.coveoValue,
        });
      },
    );
  };

  getMinimumquantity = (fields) => {
    const counter = {};
    fields.options.forEach((field) => {
      const fieldCategory = field.coveoValue ? field.coveoValue : field.name,
        minQty = parseInt(field.minimumQuantity || 0, 10);
      counter[fieldCategory] = minQty;
      this.currentCount += minQty;
    });
    return counter;
  };

  getSpinnerMaxCount = (fieldCategory) => {
    const counter = this.isFamilyProd(fieldCategory) ? this.pplPerTicket : 1;
    let factor = this.maxCount - this.currentCount;
    factor = this.isFamilyProd(fieldCategory) && factor < 0 ? -counter : factor;
    return (
      this.state.spinnerCount.primaryCounterCount[fieldCategory] +
      this.deltaCountForError +
      parseInt(factor / counter, 10)
    );
  };

  isFamilyProd = (type) => {
    return type === 'FAM' || type === 'FAMN';
  };

  updateAnnualPass = (newProps) => {
    const primaryProd = newProps.primaryProd;
    if (!primaryProd) {
      return <></>;
    }
    const existingProd = this.props.checkProdCartExist(primaryProd);
    const primaryCounterCount = {};
    const { primaryCounter } = this.props.data;
    if (!isEmpty(existingProd)) {
      primaryCounter.options.forEach((field, i) => {
        const fieldCategory = field.coveoValue ? field.coveoValue : field.name,
          minQty = existingProd.products[0].quantity;
        primaryCounterCount[fieldCategory] = minQty;
        this.currentCount += minQty;
      });
      const spinnerCount = {
        primaryCounterCount,
      };
      this.setState({ spinnerCount });
    } else {
      primaryCounter.options.forEach((field, i) => {
        const fieldCategory = field.coveoValue || field.name,
          minQty = 0;
        primaryCounterCount[fieldCategory] = minQty;
        this.currentCount = minQty;
      });
      const spinnerCount = {
        primaryCounterCount,
      };
      this.setState({ spinnerCount });
    }
  };

  componentWillReceiveProps(newProps) {
    if (this.annualPassGridVariant && (this.isParks || newProps.isFirstIncrement)) {
      this.updateAnnualPass(newProps);
    }
    if (newProps.data.additionalCounter && newProps.data.primaryCounter) {
      newProps.data.additionalCounter.options[0].defaultQuantity =
        newProps.data.additionalCounter.options[0].minPrimaryCount >
        newProps.data.primaryCounter.options[0].defaultQuantity
          ? 0
          : newProps.data.additionalCounter.options[0].defaultQuantity;
    }
    if (newProps.componentState === 'noSelect') {
      this.currentCount = 0;
      const spinnerCount = newProps.data.additionalCounter
        ? {
            primaryCounterCount: this.getMinimumquantity(newProps.data.primaryCounter),
            additionalCounterCount: this.getMinimumquantity(newProps.data.additionalCounter),
          }
        : {
            primaryCounterCount: this.getMinimumquantity(newProps.data.primaryCounter),
          };
      this.setState({ spinnerCount });
    } else if (newProps.componentState === 'forceNoSelect') {
      this.currentCount = 0;
      const spinnerCount = {
        primaryCounterCount: this.getMinimumquantity(newProps.data.primaryCounter),
      };
      Object.keys(spinnerCount.primaryCounterCount).forEach((key) => {
        spinnerCount.primaryCounterCount[key] = 0;
      });
      this.setState({ spinnerCount });
    }

    if (
      newProps.data.primaryCounter &&
      this.state.spinnerCount &&
      newProps.maxAvailableTicketsCount &&
      !newProps.isVIPPrivateOrShared
    ) {
      let spinnerCount = deepCloneObject(this.state.spinnerCount);
      Object.keys(this.state.spinnerCount.primaryCounterCount).forEach((field) => {
        if (this.state.spinnerCount.primaryCounterCount[field] > parseInt(newProps.maxAvailableTicketsCount)) {
          spinnerCount.primaryCounterCount[field] = parseInt(newProps.maxAvailableTicketsCount);
          this.setState({ spinnerCount });
          this.props.setVisitorData({
            spinnerCount,
            category: this.props.ticketData?.ticket?.coveoValue,
          });
        }
      });
    }
    let spinnerCount = { ...this.state.spinnerCount };
    if (
      newProps.isDynamicPricingProd &&
      newProps.spinnerInitialCount &&
      newProps.data.primaryCounter &&
      newProps.isProductOverlayClicked &&
      !newProps.isVIPPrivateOrShared
    ) {
      const primaryCounterCount = {};
      newProps.data.primaryCounter.options.forEach((field, i) => {
        const fieldCategory = field.coveoValue ? field.coveoValue : field.name,
          minQty = Math.max(parseInt(field.defaultQuantity, 10), parseInt(field.minimumQuantity || 0, 10));
        if (minQty === newProps.spinnerInitialCount.primaryCounterCount[fieldCategory]) {
          primaryCounterCount[fieldCategory] = minQty;
          spinnerCount = { primaryCounterCount }; // this.currentCount += minQty;
          this.setState({ spinnerCount });
        }
      });
    }
    if (
      newProps.isVIPPrivateOrShared &&
      newProps.data?.primaryCounter?.options?.length !== this.props.data?.primaryCounter?.options?.length
    ) {
      const newOptions = newProps.data?.primaryCounter?.options;
      const primaryCounterCount =
        Array.isArray(newOptions) &&
        newOptions.reduce((res, curr) => {
          const minQty = Math.max(parseInt(curr.defaultQuantity, 10), parseInt(curr.minimumQuantity || 0, 10));
          res[curr.coveoValue ? curr.coveoValue : curr.name] = minQty;
          return res;
        }, {});
      spinnerCount = { primaryCounterCount };
      this.setState({ spinnerCount });
      this.props.setVisitorData({
        spinnerCount,
        category: this.props.ticketData?.ticket?.coveoValue,
      });
    }
  }

  getMaxCountYMC = (field) => {
    const { maxAvailableTicketsCount } = this.props;
    const { maximumQuantity } = field;
    return (
      (maxAvailableTicketsCount < maximumQuantity ? maxAvailableTicketsCount : field.maximumQuantity) ||
      field.maximumQuantity
    );
  };

  componentDidMount() {
    const { primaryCounter, additionalCounter } = this.props.data;
    const primaryCounterCount = {};
    const additionalCounterCount = {};

    primaryCounter.options.forEach((field, i) => {
      const fieldCategory = field.coveoValue ? field.coveoValue : field.name,
        minQty = this.annualPassGridVariant
          ? 0
          : Math.max(parseInt(field.defaultQuantity, 10), parseInt(field.minimumQuantity || 0, 10));

      primaryCounterCount[fieldCategory] = minQty;
      this.currentCount += minQty;
    });

    additionalCounter &&
      additionalCounter.options.forEach((field, i) => {
        const fieldCategory = field.coveoValue ? field.coveoValue : field.name;
        let minQty = Math.max(parseInt(field.defaultQuantity, 10), parseInt(field.minimumQuantity || 0, 10));
        minQty = Math.min(minQty, parseInt(field.maxCount, 10));
        additionalCounterCount[fieldCategory] = minQty;
        this.currentCount += minQty;
      });

    const spinnerCount = additionalCounter ? { primaryCounterCount, additionalCounterCount } : { primaryCounterCount };
    const coveoKeys = additionalCounter
      ? { primaryCounter: primaryCounter.coveoKey, additionalCounter: additionalCounter.coveoKey }
      : { primaryCounter: primaryCounter.coveoKey };

    this.setState({ spinnerCount });
    this.props.setVisitorData({
      spinnerCount,
      coveoKeys,
      maxCount: this.maxCount,
      isFirstTimeCalled: true,
      category: this.props.ticketData?.ticket?.coveoValue,
    });
  }

  renderOtherTenantsStructure = (field, i) => {
    let fieldCategory = field.coveoValue ? field.coveoValue : field.name;
    let ariaLabelSpinner = field.name || field.title ? field.name || field.title : field.coveoValue;
    const increaseDecreaseBy = field.increaseDecreaseBy || 1;
    this.juniorSpinnerErrorMsg =
      this.props.isJuniorTicketError && fieldCategory.toLowerCase() === 'junior' ? field.errorMessage : '';
    this.adultSpinnerErrorMsg =
      fieldCategory.toLowerCase() === 'adult' ||
      fieldCategory.toLowerCase() === 'adults' ||
      fieldCategory.toLowerCase() === 'Adults'
        ? field.errorMessage
        : '';
    return (
      <>
        {!this.props.annualPassGridVariant && (
          <DynamicContent attrs={{ className: 'body-2-title' }} tagName="p" innerHtml={field.title} />
        )}
        <Spinner
          quantity={this.state.spinnerCount.primaryCounterCount[fieldCategory]}
          isInputDisabled={true}
          checkQuantity={this.updateCountPrimary}
          name={fieldCategory}
          isSpinnerDisabled={field.isIncreaseDecreaseEnabled || this.props.isSpinnerDisabled || false}
          max={
            this.props.isAdultChildOverlay
              ? this.getSpinnerMaxCount(fieldCategory)
              : isMatchTenant(UIConfig.ymcB2CTenant)
              ? this.getMaxCountYMC(field)
              : field.maximumQuantity || this.props.maxAvailableTicketsCount || this.getSpinnerMaxCount(fieldCategory)
          }
          min={Number(field.minimumQuantity) || 0}
          addToCartClicked={this.props.addToCartClicked}
          increaseDecreaseBy={increaseDecreaseBy}
          isJuniorTicketError={this.props.isJuniorTicketError}
          ariaLabelSpinner={ariaLabelSpinner}
          ariaLabelDecrease={field.ariaLabelDecrease ? field.ariaLabelDecrease : ''}
          ariaLabelIncrease={field.ariaLabelIncrease ? field.ariaLabelIncrease : ''}
          ticketCounterText={field.ticketCounterText ? field.ticketCounterText : ''}
          adultSpinnerErrorMsg={this.adultSpinnerErrorMsg}
          sectorQuantity={this.props.sectorQuantity}
          sectorErrorMessage={this.props.sectorErrorMessage}
          buttonDisabledOnError={this.props.buttonDisabledOnError}
        />
        {!this.props.isPJOnly && (
          <DynamicContent
            attrs={{ className: 'sub-title' }}
            tagName="p"
            innerHtml={
              this.props.flexibleMoneyCard
                ? toTwoDecimalPlaces(priceValue(this.props.priceForFlexibleMoneyCard))
                : field.description
            }
          />
        )}
        {field.hasOwnProperty('baseProductPrice') && field.baseProductPrice && (
          <DynamicContent
            tagName="p"
            innerHtml={field.baseProductPrice}
            attrs={{
              className: 'base-product-price',
            }}
          />
        )}
      </>
    );
  };

  renderSwadTenantsStructure = (field, i) => {
    let fieldCategory = field.coveoValue ? field.coveoValue : field.name;
    let ariaLabelSpinner = field.name || field.title ? field.name || field.title : field.coveoValue;
    const increaseDecreaseBy = field.increaseDecreaseBy || 1;
    this.juniorSpinnerErrorMsg =
      this.props.isJuniorTicketError && fieldCategory.toLowerCase() === 'junior' ? field.errorMessage : '';
    this.adultSpinnerErrorMsg =
      fieldCategory.toLowerCase() === 'adult' ||
      fieldCategory.toLowerCase() === 'adults' ||
      fieldCategory.toLowerCase() === 'Adults'
        ? field.errorMessage
        : '';
    let baseProductPrice = field?.baseProductPrice;
    let overlayShowPrice =
      this.props?.overlayShowPrice && this.props?.overlayShowPrice[i]?.price
        ? this.props?.overlayShowPrice[i]?.price
        : '';
    let overlayShowCurrency =
      this.props?.overlayShowPrice && this.props?.overlayShowPrice[i]?.currency
        ? this.props?.overlayShowPrice[i]?.currency
        : '';
    return (
      <>
        <div className="ticket-content">
          <DynamicContent attrs={{ className: 'body-2-title' }} tagName="p" innerHtml={field.title} />
          {!this.props.expeditionPrice ? (
            ((field.hasOwnProperty('baseProductPrice') && field.baseProductPrice) || this.props?.overlayShowPrice) && (
              <DynamicContent
                tagName="p"
                innerHtml={baseProductPrice || overlayShowCurrency + ' ' + overlayShowPrice}
                attrs={{
                  className: 'base-product-price',
                }}
              />
            )
          ) : (
            <DynamicContent
              tagName="p"
              innerHtml={this.props.expeditionPrice}
              attrs={{
                className: 'base-product-price',
              }}
            />
          )}
          {!this.props.isPJOnly && (
            <DynamicContent
              attrs={{ className: 'sub-title' }}
              tagName="p"
              innerHtml={
                this.props.flexibleMoneyCard
                  ? toTwoDecimalPlaces(priceValue(this.props.priceForFlexibleMoneyCard))
                  : field.description
              }
            />
          )}
        </div>

        <Spinner
          quantity={this.state.spinnerCount.primaryCounterCount[fieldCategory]}
          isInputDisabled={true}
          checkQuantity={this.updateCountPrimary}
          name={fieldCategory}
          isSpinnerDisabled={field.isIncreaseDecreaseEnabled || this.props.isSpinnerDisabled || false}
          max={
            this.props.isAdultChildOverlay
              ? this.getSpinnerMaxCount(fieldCategory)
              : isMatchTenant(UIConfig.ymcB2CTenant)
              ? this.props.maxAvailableTicketsCount || field.maximumQuantity
              : this.props.maxAvailableTicketsCount || field.maximumQuantity || this.getSpinnerMaxCount(fieldCategory)
          }
          min={Number(field.minimumQuantity) || 0}
          addToCartClicked={this.props.addToCartClicked}
          increaseDecreaseBy={increaseDecreaseBy}
          isJuniorTicketError={this.props.isJuniorTicketError}
          ariaLabelSpinner={ariaLabelSpinner}
          ariaLabelDecrease={field.ariaLabelDecrease ? field.ariaLabelDecrease : ''}
          ariaLabelIncrease={field.ariaLabelIncrease ? field.ariaLabelIncrease : ''}
          ticketCounterText={field.ticketCounterText ? field.ticketCounterText : ''}
          adultSpinnerErrorMsg={this.adultSpinnerErrorMsg}
          sectorQuantity={this.props.sectorQuantity}
          sectorErrorMessage={this.props.sectorErrorMessage}
          buttonDisabledOnError={this.props.buttonDisabledOnError}
          disableSpinner={this.props.disableSpinner}
        />
      </>
    );
  };

  renderPrimarySpinner(data) {
    return data.map((field, i) => {
      let fieldCategory = field.coveoValue ? field.coveoValue : field.name;
      let ariaLabelSpinner = field.name || field.title ? field.name || field.title : field.coveoValue;
      const increaseDecreaseBy = field.increaseDecreaseBy || 1;
      this.juniorSpinnerErrorMsg =
        this.props.isJuniorTicketError && fieldCategory.toLowerCase() === 'junior' ? field.errorMessage : '';
      this.adultSpinnerErrorMsg =
        fieldCategory.toLowerCase() === 'adult' ||
        fieldCategory.toLowerCase() === 'adults' ||
        fieldCategory.toLowerCase() === 'Adults'
          ? field.errorMessage
          : '';
      return (
        <div
          key={i}
          className={`c-visitors-count-selector-spinner-wrapper${
            this.state.spinnerCount.primaryCounterCount[fieldCategory] === 0 ? ' color-change' : ''
          }`}
        >
          {this.isSwad ? this.renderSwadTenantsStructure(field, i) : this.renderOtherTenantsStructure(field, i)}
        </div>
      );
    });
  }

  renderAdditionalSpinner(additionalCounter) {
    const data = additionalCounter.options;
    return data.map((field, i) => {
      let fieldCategory = field.coveoValue ? field.coveoValue : field.name;
      let ariaLabelSpinner = field.name || field.title ? field.name || field.title : field.coveoValue;
      const increaseDecreaseBy = field.increaseDecreaseBy || 1;
      return (
        <div
          key={i}
          className={`c-visitors-count-selector-spinner-wrapper${
            this.state.spinnerCount.additionalCounterCount[fieldCategory] === 0 ? ' color-change' : ''
          }`}
        >
          <DynamicContent attrs={{ className: 'body-2-title' }} tagName="p" innerHtml={field.title} />
          <Spinner
            quantity={this.state.spinnerCount.additionalCounterCount[fieldCategory]}
            isInputDisabled={true}
            checkQuantity={this.updateCountAdditional}
            name={fieldCategory}
            isSpinnerDisabled={additionalCounter.isIncreaseDecreaseEnabled || this.props.isSpinnerDisabled}
            max={Math.min(
              this.state.spinnerCount.additionalCounterCount[fieldCategory] +
                this.deltaCountForError +
                this.maxCount -
                this.currentCount,
              field.maxCount,
            )}
            min={Number(field.minimumQuantity) || 0}
            addToCartClicked={this.props.addToCartClicked}
            increamentDisabed={Object.values(this.state.spinnerCount.primaryCounterCount)[0] < field.minPrimaryCount}
            increaseDecreaseBy={increaseDecreaseBy}
            ariaLabelSpinner={ariaLabelSpinner}
            ariaLabelDecrease={field.ariaLabelDecrease ? field.ariaLabelDecrease : ''}
            ariaLabelIncrease={field.ariaLabelIncrease ? field.ariaLabelIncrease : ''}
            ticketCounterText={field.ticketCounterText ? field.ticketCounterText : ''}
          />
          <DynamicContent attrs={{ className: 'sub-title' }} tagName="p" innerHtml={field.description} />
        </div>
      );
    });
  }

  render() {
    try {
      if (!this.state.spinnerCount) {
        return null;
      }

      const { primaryCounter, additionalCounter } = this.props.data;

      return (
        <div className="c-visitors-count-selector">
          {!this.props.hideQtySelector && (
            <div className="selector">
              {!this.props.annualPassGridVariant && (primaryCounter.title || primaryCounter.description) && (
                <div className="c-visitors-count-selector-text-wrapper">
                  <DynamicContent attrs={{ className: 'heading-4' }} tagName="h4" innerHtml={primaryCounter.title} />
                </div>
              )}
              {this.props.isEventOverlayDescription ||
                (this.isSwad && primaryCounter?.description && (
                  <DynamicContent
                    attrs={{ className: 'body-2 c-primary-section-description overlay-description' }}
                    tagName="p"
                    innerHtml={primaryCounter.description}
                  />
                ))}
              <div className="c-visitors-count-selector-wrapper">
                <div className={this.props.disableSpinner ? 'c-primary-spinners disabled' : 'c-primary-spinners'}>
                  {this.isSwad ? (
                    <>
                      {(this.props.ticketData?.ticket?.prices[0]?.currency ||
                        this.props.ticketData?.ticket?.prices[0]?.price) && (
                        <p className="ticket-price">
                          {this.props.ticketData?.ticket?.prices[0]?.currency +
                            ' ' +
                            this.props.ticketData?.ticket?.prices[0]?.price}
                        </p>
                      )}
                      {this.renderPrimarySpinner(primaryCounter.options)}
                    </>
                  ) : (
                    this.renderPrimarySpinner(primaryCounter.options)
                  )}
                </div>
                {this.props.isJuniorTicketError && (
                  <DynamicContent
                    attrs={{ className: 'junior-ticket-error' }}
                    tagName="p"
                    innerHtml={this.juniorSpinnerErrorMsg}
                  />
                )}
                {!this.props.hideDescription && !this.isSwad && (
                  <DynamicContent
                    attrs={{ className: 'body-2 c-primary-section-description' }}
                    tagName="p"
                    innerHtml={primaryCounter.description}
                  />
                )}
              </div>
            </div>
          )}

          {additionalCounter && (
            <div className="selector">
              <div className="c-visitors-count-selector-text-wrapper">
                <DynamicContent attrs={{ className: 'heading-4' }} tagName="h4" innerHtml={additionalCounter.title} />
                <DynamicContent attrs={{ className: 'body-2' }} tagName="p" innerHtml={additionalCounter.description} />
              </div>
              <div className="c-visitors-count-selector-wrapper">{this.renderAdditionalSpinner(additionalCounter)}</div>
            </div>
          )}
          {window.PubSub.publish('toggleDatepickerError', this.state.showError)}
          {window.PubSub.publish('datePickerErrorMsg', this.getErrorMessage())}
          {!this.isSwad && this.state.showError && (
            <div className="error-group-booking">
              <DynamicContent attrs={{ className: '' }} tagName="div" innerHtml={this.getErrorMessage()} />
            </div>
          )}
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'VisitorsCountSelector');
    }
  }
}

VisitorsCountSelector.PropsTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
};
