import React, { Component } from 'react';
import AddOnsTicketOverlayComponent from '../add-ons-overlay/add-ons-ticket-overlay-component';
import { DynamicContent, Image } from '../../../presentation/base';
import { PerformanceService } from '../../../../common/services';
import UIConfig from '../../../../common/UIConfig';
import moment from 'moment';
import { canUseDOM, deepCloneObject } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

class AddOnsGrouppedTicketComponent extends Component {
  constructor(props) {
    super(props);
    this.performanceList = {};
    this.selectedTimeSlot = { value: '', text: '' };

    this.itemOtherReason = {
      nonEditable: false,
      grouped: true,
      minGrouppedQuantity: this.props.visitorCounter.minCount,
      maxGrouppedQuantity: this.props.visitorCounter.maxCount,
    };

    this.resetProductsToAddList();
    this.state = {
      isOverlayOpen: false,
    };
  }

  resetProductsToAddList = () => {
    this.productsToAdd = [];
  };

  timeFormat = (performance) => {
    const openTime = moment.utc(performance.date + ' ' + performance.openTime).format(UIConfig.calendar.timeFormat),
      closeTime = moment.utc(performance.date + ' ' + performance.closeTime).format(UIConfig.calendar.timeFormat);
    return openTime + ' - ' + closeTime;
  };

  getTimeSlots = () => {
    const labels =
      this.props.timeSlotSelector && this.props.timeSlotSelector.labels ? this.props.timeSlotSelector.labels : {};
    const timeSlots = { slots: [], defaultValue: labels.dropdownLabel };
    for (let productId in this.performanceList) {
      let performances = this.performanceList[productId];
      performances.forEach((performance) => {
        if (!timeSlots.slots.some((timeSlot) => timeSlot.value === this.timeFormat(performance))) {
          timeSlots.slots.push({
            value: this.timeFormat(performance),
            text: this.timeFormat(performance),
            disabled: Object.values(this.performanceList)
              .map((performances) =>
                performances
                  .filter((per) => this.timeFormat(performance) === this.timeFormat(per))
                  .every((per) => per.sellable === 'false' || per.availability.available === '0'),
              )
              .reduce((status, flag) => status || flag),
          });
        }
      });
    }
    return timeSlots;
  };

  onSelectTimeSlot = (selectedTimeSlot) => {
    this.resetProductsToAddList();
    this.selectedTimeSlot = selectedTimeSlot;
  };

  setProductsToAdd = () => {
    for (let productId in this.performanceList) {
      const selectedPerformace =
        this.performanceList[productId].filter(
          (per) =>
            this.timeFormat(per) === this.selectedTimeSlot.value &&
            per.sellable === 'true' &&
            parseInt(per.availability.available) > 0,
        )[0] || null;
      const selectedProduct = deepCloneObject(
        this.props.allTickets.filter((product) => product.productId === productId)[0],
      );
      if (
        selectedPerformace &&
        !this.productsToAdd.some((productToAdd) => productToAdd.classType === selectedProduct.classType)
      ) {
        selectedProduct.performanceId = selectedPerformace.performanceId;
        selectedProduct.fromDate = this.selectedDate;
        selectedProduct.timeSlot = this.timeFormat(selectedPerformace);
        selectedProduct.otherReason = JSON.stringify(this.itemOtherReason);
        this.productsToAdd.push(selectedProduct);
      }
    }
  };

  updateProdcutsQuantity = (counterData) => {
    this.productsToAdd.forEach((productToAdd) => {
      productToAdd.quantity = counterData.spinnerCount.primaryCounterCount[productToAdd.classType];
    });
  };

  getTotalCalculatedAmount = (counterData) => {
    this.productsToAdd.length < 1 && this.setProductsToAdd();
    this.updateProdcutsQuantity(counterData);
    return this.productsToAdd
      .map((product) => product.quantity * product.gross)
      .reduce((total, quantity) => total + quantity);
  };

  addToCart = () => {
    this.props.addToCartCallback(this.productsToAdd.filter((product) => product.quantity > 0));
    this.closeOverlay();
  };

  getPerformanceListFromResponse = (responses) => {
    for (let i = 0; i < responses.length; i++) {
      this.performanceList[this.props.allTickets[i].productId] = responses[i].data.performancelist.performance;
    }
    return this.performanceList;
  };

  getPerformanceService = (product, date) => {
    const url = this.props.services.getPerformance.url.replace('{0}', product.productId);
    return PerformanceService.getPerformanceData(url, date, date, true, '.addOns-Overlay');
  };

  getPerformanceServices = (date) => {
    const res = [];
    this.selectedDate = date.format(UIConfig.calendar.dateFormat);
    this.props.allTickets.forEach((product) => res.push(this.getPerformanceService(product, this.selectedDate)));
    return res;
  };

  closeOverlay = () => {
    this.setState({ isOverlayOpen: false });
  };

  componentDidMount() {
    canUseDOM() &&
      window.PubSub.subscribe('closeOverlay', () => {
        this.setState({
          isOverlayOpen: false,
        });
      });
  }

  renderDateSelector = () => {
    return (
      <div className="btn-primary">
        <button type="button" onClick={() => this.setState({ isOverlayOpen: true })}>
          {' '}
          {this.props.dateSelector.options[0].calendarSettings.calendarLabel}{' '}
        </button>
        {this.state.isOverlayOpen &&
          window.PubSub.publish('toggleOverlayState', {
            shouldOpen: true,
            customClass: 'addOns-Overlay calendar-overlay-genral-admission groupped-tickets',
            dataToAppend: (
              <AddOnsTicketOverlayComponent
                data={this.props.data}
                products={this.props.allTickets}
                getPerformanceServices={this.getPerformanceServices}
                getPerformanceListFromResponse={this.getPerformanceListFromResponse}
                getTimeSlots={this.getTimeSlots}
                onSelectTimeSlot={this.onSelectTimeSlot}
                getTotalCalculatedAmount={this.getTotalCalculatedAmount}
                counterData={this.props.visitorCounter}
                calenderData={this.props.dateSelector}
                addToCartCallback={this.addToCart}
                closeOverlay={this.closeOverlay}
                createMappedData={this.props.createMappedData}
                services={this.props.services}
                timeSlotData={this.props.timeSlotSelector}
              />
            ),
          })}
      </div>
    );
  };

  render() {
    try {
      return (
        <div className="c-fast-ticket-wrapper">
          <div className="c-fast-ticket">
            <div className="c-fast-desc">
              <DynamicContent tagName="div" attrs={{ className: 'heading-4' }} innerHtml={this.props.category.title} />
              <DynamicContent
                tagName="div"
                attrs={{ className: 'body-1' }}
                innerHtml={this.props.allTickets[0].parks.join(' ') || ''}
              />
              <DynamicContent
                tagName="div"
                attrs={{ className: 'body-2 ticket-desc' }}
                innerHtml={this.props.category.description}
              />
            </div>
            <div className="c-fast-logo-counter-wrapper">
              <div className="c-fast-logo">
                {!this.props.dateSelector && this.props.ticket.image && <Image image={null} disableLazyLoad={true} />}
              </div>
              <div className="c-fast-counter">{this.props.dateSelector && this.renderDateSelector()}</div>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'AddOnsGrouppedTicketComponent');
    }
  }
}

export default AddOnsGrouppedTicketComponent;
