import React from 'react';
import { DynamicContent } from '../../base';

const TextField = (props) => {
  const field = props.field;
  return (
    <DynamicContent
      tagName="div"
      innerHtml={field.model.text}
      attrs={{ htmlFor: field.model.name, className: `text-field heading-1 ${field.model.cssClass}` }}
    />
  );
};

export default TextField;
