/*
 * DateFilter.js
 * This file contains code for Date Filter component.
 * This base component contains Date Filter to be used for Date Range Filter, which contains
 * Start Date and End Date.
 * @licensor  Miral
 */
import React from 'react';
import filter from './filter-composition';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { DynamicContent, SvgSprite } from '..';
import { filterList, currentLocale, convertJsDateToMomentObj } from '../../../../common/utility';
import { logComponentRenderingError } from '../../../../common/logger';

import 'react-datepicker/dist/react-datepicker.css';

/**
 * DateFilter Class ( which extends the React.Component) creates Date Range Based Filter
 */
class DateFilter extends React.Component {
  /**
   * Constructor of the class is defined which handles binding of the events to the elements, the
   * props to the super class and defining the state of the component.
   * @param    {[Object]} props [Props that are passed to the component].
   * @return   {Void} constructor does not return anything.
   */
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
    };
  }

  /**
   * handleChange function swaps the value of endDate and startDate to ensure endDate is greater
   * than startDate and update the state of dates in component's state
   * @param    {endDate, startDate} function accepts the value of Start Date and End Date
   * @return   {[Void]} function does not return anything.
   */
  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
    this.props.updateFilter(
      {
        startDate: moment(startDate).isValid() ? moment(startDate).format('MM-DD-YYYY') : '',
        endDate: moment(endDate).isValid() ? moment(endDate).format('MM-DD-YYYY') : '',
      },
      true,
    );
  };
  /**
   * disableDatesTillStartDate function ensures that user can only select dates greater than Start Date
   * in End Date
   * @param    {[date]} function accepts the value of target end Date
   * @return   {[Void]} function does not return anything.
   */
  disableDatesTillStartDate = (date) => {
    date = convertJsDateToMomentObj(date);
    return date >= this.state.startDate;
  };
  /**
   * handleChangeStart function sends startDate to handleChange function
   * to ensure start Date is greater or equal to End Date
   * @param    {[date]} function accepts the value of target start Date
   * @return   {[Void]} function does not return anything.
   */
  handleChangeStart = (startDate) => {
    startDate = convertJsDateToMomentObj(startDate, this.state.startDate, this.state.endDate);
    if (moment(startDate).isValid()) {
      this.handleChange({ startDate });
    } else {
      this.setState({
        startDate: '',
      });
      this.props.updateFilter(
        {
          startDate: '',
          endDate: moment(this.state.endDate).isValid() ? moment(this.state.endDate).format('MM-DD-YYYY') : '',
        },
        true,
      );
    }
  };

  /**
   * handleChangeEnd function sends endDate to handleChange function
   * to ensure start Date is greater or equal to End Date
   * @param    {[date]} function accepts the value of target end Date
   * @return   {[Void]} function does not return anything.
   */
  handleChangeEnd = (endDate) => {
    endDate = convertJsDateToMomentObj(endDate, this.state.startDate, this.state.endDate);
    if (moment(endDate).isValid()) {
      this.handleChange({ endDate });
    } else {
      this.setState({
        endDate: '',
      });
      this.props.updateFilter(
        {
          endDate: '',
          startDate: moment(this.state.startDate).isValid() ? moment(this.state.startDate).format('MM-DD-YYYY') : '',
        },
        true,
      );
    }
  };
  handleBlur = (e) => {
    if (!moment(e.target.value, 'DD/MM/YYYY', true).isValid()) {
      e.target.value = '';
    }
  };

  render() {
    try {
      return (
        <div className={this.props.className}>
          {this.props.filterList.searchDateTitle ? (
            <DynamicContent
              tagName="p"
              attrs={{
                className: 'filter--label body-copy-4',
              }}
              innerHtml={this.props.filterList.searchDateTitle}
            />
          ) : (
            ''
          )}
          <div className="icon-svg">
            <label className="label-date" htmlFor="start-date">
              {' '}
              {filterList(this.props.filterList.searchFilter, 'startDateInfo').label}{' '}
            </label>
            <DatePicker
              selected={this.state.startDate == null ? this.state.startDate : this.state.startDate.toDate()}
              selectsStart
              selectsRange
              startDate={this.state.startDate == null ? this.state.startDate : this.state.startDate.toDate()}
              endDate={this.state.endDate == null ? this.state.endDate : this.state.endDate.toDate()}
              onChange={this.handleChangeStart}
              placeholderText={filterList(this.props.filterList.searchFilter, 'startDateInfo').label}
              onBlur={this.handleBlur}
              className="start-date"
              id="start-date"
              locale={currentLocale()}
            />
            <SvgSprite id="icn-calendar" />
          </div>
          <label className="label-date" htmlFor="end-date">
            {' '}
            {filterList(this.props.filterList.searchFilter, 'endDateInfo').label}{' '}
          </label>
          <div className="icon-svg">
            <DatePicker
              selected={this.state.endDate == null ? this.state.endDate : this.state.endDate.toDate()}
              selectsEnd
              selectsRange
              startDate={this.state.startDate == null ? this.state.startDate : this.state.startDate.toDate()}
              endDate={this.state.endDate == null ? this.state.endDate : this.state.endDate.toDate()}
              onChange={this.handleChangeEnd}
              filterDate={this.disableDatesTillStartDate}
              placeholderText={filterList(this.props.filterList.searchFilter, 'endDateInfo').label}
              onBlur={this.handleBlur}
              className="end-date"
              id="end-date"
              locale={currentLocale()}
            />
            <SvgSprite id="icn-calendar" />
          </div>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'DateFilter');
    }
  }
}

DateFilter.propTypes = {
  props: PropTypes.shape({
    filterList: PropTypes.shape({
      orderDate: PropTypes.object.isRequired,
    }),
  }),
};

export default filter(DateFilter);
