import React, { Component } from 'react';
import moment from 'moment';

import { Calendar, DynamicContent, ErrorSummary } from '../../../../presentation/base';
import UIConfig from '../../../../../common/UIConfig';
import {
  getValidStartDate,
  deepCloneObject,
  scrollTo,
  getProdIdType,
  momentTimezone,
  isEnterPressed,
  isDesktopViewPort,
  resolvePath,
  isEmpty,
  getClientUtcOffset,
  convertJsDateToMomentObj,
  decodeHtmlEntity,
} from '../../../../../common/utility';
import DropDown from '../../../../presentation/drop-down';
import { secProds } from '../add-ons-utility';
import CounterComponent from '../../visitors-count-selector/visitors-count-selector-component';
import { logComponentRenderingError } from '../../../../../common/logger';

import * as helper from './helper';
import { AmountWithButtons, ImportantNotes, TotalAmount } from '../../common-components';
import { initialOverlayPerformanceCall } from '../../add-ons-overlay/helper';
import GTMData from '../../gtm-data';

export default class DrivingExperienceB2C extends Component {
  constructor(props) {
    super(props);
    this.calendarSettings = this.props.calenderData.options[0].calendarSettings;
    /**  Language Switch Start */
    this.additionalProducts = [];
    this.overlaySelAvailable = false;
    this.isQuantityInOverlay = this.props.data && this.props.data.isQuantityInOverlay;
    this.productOverlaySelector = this.props.productOverlaySelector || {};
    this.overlaySelMap = !this.isQuantityInOverlay ? this.getOverlaySelMapping() : null;
    this.defaultSelAdditionalProds = this.props.additionalProds ? this.getDefaultAdditionalProd() : false;
    this.fallBackProducts = {};
    /** Language Switch End */
    /** Quantity Start */
    this.counterData = this.props.counterData;
    this.maxCount = this.props.counterData ? this.props.counterData.maxCount : 0;
    this.product = this.defaultSelAdditionalProds
      ? this.defaultSelAdditionalProds
      : deepCloneObject(this.props.product);
    /** Qauntity End */
    const category = this.props.product && this.props.product.category;
    this.minDate = getValidStartDate(this.calendarSettings.startDate).add(
      this.calendarSettings.defaultDateDelta,
      'days',
    );
    this.maxDate = this.calendarSettings.endDate
      ? momentTimezone(this.calendarSettings.endDate, getClientUtcOffset())
      : momentTimezone(this.calendarSettings.startDate, getClientUtcOffset()).add(
          parseInt(this.calendarSettings.range, 10),
          'days',
        );
    this.primaryProd = this.productOverlaySelector?.collections?.length ? this.product : this.props.product;
    this.secProds =
      secProds[category] && secProds[category].secondary ? deepCloneObject(secProds[category].secondary) : [];
    this.addOnGuestProds = secProds.addOnGuest ? deepCloneObject(secProds.addOnGuest) : [];
    this.primaryProds =
      secProds[category] && secProds[category].primary ? deepCloneObject(secProds[category].primary) : [];
    this.secProdSlctd = undefined;
    this.addOnGuest = undefined;
    this.addGuestSlctdQnty = 0;
    this.dateSlctd = undefined;
    this.timeSlotSlctd = undefined;
    this.licenseSlctd = undefined;
    this.lcnsAvlbe = false;
    this.perfSlctd = undefined;
    this.slctdSecIdx = 0;
    this.daysDelta = 0;
    this.tAndCavailable = false;
    this.tAndCchecked = false;
    this.isUpdateDropdownProps = false;
    this.isDesktop = isDesktopViewPort();
    this.productChanged = false;
    this.isFirstRender = true;
    this.validOnRange = [];
    this.productOverlaySelector = this.props.productOverlaySelector || {};
    this.isMultiProductCal = this.productOverlaySelector.isMultiProductCal || false;
    this.isDynamicPricingProd = this.props.data.variant === 'v-dynamic-product-pricing';
    this.enableDynamicCalendar = !!this.props.enableDynamicCalendar;
    this.productDetails = [];
    this.currentDateSlots = null;

    this.state = {
      timeSlots: [],
      total: 0,
      buttonDisable: true,
      isError: false,
      errorInfo: null,
      existingProductError: false,
      maxAvailableTickets: this.maxCount,
      ticketCount: 0,
      date: this.minDate,
      performanceData: {},
      modifiedPerformanceData: [],
      selectDefaultDate: false,
      visitedDate: null,
    };
    this.isTimeSlotSelected = false;
  }

  componentWillMount = () => {
    this.confgDfltSetngs();
    this.product.showQtySpinnerInCart = this.props.counterData && this.props.counterData.maxCount > 0;

    if (this.calendarSettings.validOn) {
      this.validOnRange = this.calendarSettings.validOn.split(',').map((x) => +x);
    }
  };
  componentDidMount = () => {
    this.product = this.defaultSelAdditionalProds
      ? this.defaultSelAdditionalProds
      : deepCloneObject(this.props.product);
    if (this.enableDynamicCalendar && this.isDynamicPricingProd) {
      initialOverlayPerformanceCall(this);
    } else {
      this.minDate && this.setDate(this.minDate);
    }
  };

  // this update the start date, if a ticket needs to be valid on a certain day of week.
  updateStartDate(validDayOfWeek) {
    const currentDate = this.minDate;

    while (validDayOfWeek.indexOf(currentDate.day()) < 0) {
      currentDate.add(1, 'day');
    }

    this.minDate = currentDate;
  }

  getFormattedDates = (dates) => {
    return dates && dates.length ? dates.map((dateString) => momentTimezone(dateString, getClientUtcOffset())) : null;
  };

  allIncludedDates = () => {
    const allEnabledDates = this.getFormattedDates(this.calendarSettings.enabledDates);
    const includeDates = allEnabledDates
      ? allEnabledDates.sort(function(left, right) {
          return moment(left).format('X') - moment(right).format('X');
        })
      : null;

    const currentDate = moment();
    return includeDates
      ? includeDates.filter((d) => momentTimezone(d, getClientUtcOffset()).isSameOrAfter(currentDate, 'days'))
      : includeDates;
  };

  isSameDate = (date) => {
    const includeDateLogic = this.allIncludedDates();
    const findDate =
      includeDateLogic &&
      includeDateLogic.find((item) =>
        momentTimezone(item, getClientUtcOffset()).isSame(momentTimezone(date, getClientUtcOffset()), 'day'),
      );
    if (findDate) {
      return true;
    }
    return false;
  };

  isValidDay = (date) => {
    date = convertJsDateToMomentObj(date);
    if (!this.validOnRange.length) {
      return 1;
    }

    if (this.isSameDate(date)) {
      return true;
    }
    const day = date.day();
    return this.validOnRange.indexOf(day) >= 0;
  };

  confgDfltSetngs = () => helper.confgDfltSetngs(this);

  calendarDateCallback = (date, isMount, preventScroll) =>
    helper.calendarDateCallback(this, date, isMount, preventScroll);

  scrollToNextSection = (section) => {
    !this.isFirstRender &&
      scrollTo(
        document.getElementsByClassName('overlay-content-inner-container')[0],
        document.getElementsByClassName(section)[0].nextSibling.offsetTop - 70,
        500,
      );
    this.isFirstRender = false;
  };
  calDateDelta = (date) => {
    return momentTimezone(date, getClientUtcOffset())
      .startOf('day')
      .diff(moment().startOf('day'), 'days');
  };

  changeDiscountProd = (date) => helper.changeDiscountProd(this, date);
  getTotal = () => {
    if (!this.isTimeSlotSelected) return 0;
    const addGuestAmt = this.addOnGuest ? this.addOnGuest.gross : 0;
    return Number(this.secProdSlctd.gross) + Number(addGuestAmt) * this.addGuestSlctdQnty;
  };

  calcTotal = () => {
    this.setState({
      total: this.getTotal(),
    });
  };

  timeFormat = (perf) => {
    const prod = this.secProdSlctd;
    //Added the below check for resolving invalid date in the dropdown when the selected product fromdate value is empty/null.
    const fromDate = prod.fromDate ? prod.fromDate : this.state.date.format(UIConfig.calendar.dateFormat);
    const openTime = moment.utc(fromDate + ' ' + perf.openTime).format(UIConfig.calendar.timeFormat),
      closeTime = moment.utc(fromDate + ' ' + perf.closeTime).format(UIConfig.calendar.timeFormat);
    return openTime + ' - ' + closeTime;
  };

  renderTimeSlots = (data) => helper.renderTimeSlots(this, data);

  updateDateInProds = () => {
    const main = this.secProdSlctd,
      addOn = this.addOnGuest,
      date = this.dateSlctd;
    main.fromDate = date;
    main.toDate = date;
    if (addOn) {
      addOn.fromDate = date;
      addOn.toDate = date;
    }
  };

  updatePerfInProducts = () => {
    const time = this.perfSlctd;
    this.secProdSlctd.performanceId = time;
    if (this.addOnGuest) {
      this.addOnGuest.performanceId = time;
    }
  };

  renderDiscount = (discount) => {
    return (
      <div className="calender--dscnt-wrapper">
        {discount.options.map((itm) => {
          return (
            <div className={`calender--dscnt-items ${itm.hideImage === true ? 'hide-image' : ''}`}>
              <DynamicContent
                tagName="p"
                innerHtml={itm.title}
                attrs={{
                  className: 'calender--dscnt-items--title',
                }}
              />
              <DynamicContent tagName="p" innerHtml={itm.description} />
            </div>
          );
        })}
      </div>
    );
  };
  calendarDynamicPricingProdProps = () => ({
    ...(this.isDynamicPricingProd && {
      isDynamicPricingProd: this.isDynamicPricingProd,
      isDatePreSelection: this.state.selectDefaultDate,
      highlightDates: (this.enableDynamicCalendar && this.state.customDates) || [],
      setDateCustom: this.enableDynamicCalendar ? this.calendarDateCallback : this.setDate,
      selectedDate: null,
    }),
  });
  renderCalender = () => {
    return (
      <div
        className={`calender calendar-inside-overlay ${this.enableDynamicCalendar && 'b2c-driving-dynamic-calendar'}`}
      >
        {this.calendarSettings.calendarTooltip && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
          .drvng-exp .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month):hover::after {
            content : "${decodeHtmlEntity(this.calendarSettings.calendarTooltip)}";
          }
        `,
            }}
          />
        )}
        <DynamicContent
          tagName="div"
          innerHtml={this.props.calenderData.title}
          attrs={{
            className: 'calender--title',
          }}
        />
        <Calendar
          ref={(instance) => (this._calendar = instance)}
          setDateCustom={this.setDate}
          selectedDate={this.state.date || moment()}
          openToHighlightedDate={this.state.date}
          minDate={this.minDate}
          maxDate={this.maxDate}
          filterDate={this.isValidDay}
          calendarSettings={this.calendarSettings}
          inlineDisable={this.isDesktop}
          disableCloseOnSelect={true}
          isKeyboardNavigationAllowed={this.isDesktop}
          onBlurCustom={(e, name, data) => this._calendar.openDatePicker()}
          onClickOutsideCustom={(e) => this._calendar.openDatePicker()}
          hasPerformanceCall={this.product.itemType === 'PER' || this.product.hasperformance === '1'}
          {...this.calendarDynamicPricingProdProps()}
        />
        {!this.isDynamicPricingProd &&
          this.props.calenderData.options[0].calendarSettings.options &&
          this.props.calenderData.options[0].calendarSettings.options.length &&
          this.renderDiscount(this.props.calenderData.options[0].calendarSettings)}
      </div>
    );
  };

  updateTimeSlotInProds = () => {
    const time = this.timeSlotSlctd;
    this.secProdSlctd.timeSlot = time;
    this.secProdSlctd.timeSlotMaxAvailableQty = this.maxCount;
    if (this.addOnGuest) {
      this.addOnGuest.timeSlot = time;
    }
    this.setState({ slotSelected: true });
    this.updateButtonState();
  };

  timeSlotCallBack = (itm) => {
    const maxVisitorCount =
      !isEmpty(this.state.performanceData) &&
      this.state.performanceData &&
      this.state.performanceData.performance &&
      this.state.performanceData.performance.length &&
      this.state.performanceData.performance.filter((perf) => perf.performanceId === itm.performanceId)[0].availability
        .available;
    this.timeSlotSlctd = itm.value;
    this.isUpdateDropdownProps = false;
    this.perfSlctd = itm.performanceId;
    this.maxCount = maxVisitorCount ? maxVisitorCount : this.maxCount;
    this.updateTimeSlotInProds();
    this.updatePerfInProducts();
    this.updateButtonState();
    this.scrollToNextSection('timeSlot');
    if (this.isDynamicPricingProd) {
      this.isTimeSlotSelected = true;
      this.calcTotal();
    }
  };

  renderTimeSlot = (item) => {
    return (
      <div className="timeSlot section">
        {this.state.isError ? (
          <ErrorSummary
            data={{
              error: {
                code: this.state.error.errorcode,
                text: this.props.services.getPerformance.errors[this.state.error.errorcode],
              },
            }}
          />
        ) : (
          <div>
            <DynamicContent
              tagName="p"
              innerHtml={item.title}
              attrs={{
                className: 'timeSlot--title',
              }}
            />
            <DynamicContent
              tagName="p"
              innerHtml={item.description}
              attrs={{
                className: 'timeSlot--sub-title',
              }}
            />
            <DropDown
              listItems={this.state.timeSlots}
              defaultValue={item.defaultText}
              callBackFunction={this.timeSlotCallBack}
              isUpdateDropdownProps={this.isUpdateDropdownProps}
              errorMessage={item.validationMessage}
            />
            <DynamicContent
              tagName="p"
              innerHtml={item.tnctext}
              attrs={{
                className: 'timeSlot--verbose',
              }}
            />
          </div>
        )}
      </div>
    );
  };

  updateLicnsInProd = () => {
    this.secProdSlctd.license = this.licenseSlctd;
    this.updateButtonState();
  };

  lcnsCallBack = (data) => {
    this.licenseSlctd = data.text;
    this.isUpdateDropdownProps = false;
    this.updateLicnsInProd();
    this.scrollToNextSection('drvLcns');
    if (this.isDynamicPricingProd) {
      this.calcTotal();
    }
  };

  configureCntry = (cntries) => {
    return cntries.map((item) => {
      return {
        value: item.value,
        text: item.value,
      };
    });
  };

  renderDrivingLicense = (item) => {
    if (this.product.ticketVal !== 'DRIVE') {
      this.lcnsAvlbe = false;
      return <></>;
    }
    this.lcnsAvlbe = true;
    return (
      <div className="drvLcns section">
        <DynamicContent
          tagName="p"
          innerHtml={item.title}
          attrs={{
            className: 'drvLcns--title',
          }}
        />
        <DynamicContent
          tagName="p"
          innerHtml={item.description}
          attrs={{
            className: 'drvLcns--sub-title',
          }}
        />
        <DropDown
          listItems={this.configureCntry(item.country)}
          defaultValue={item.defaultText}
          callBackFunction={this.lcnsCallBack}
        />
        <DynamicContent
          tagName="p"
          innerHtml={item.tnctext}
          attrs={{
            className: 'drvLcns--verbose',
          }}
        />
      </div>
    );
  };

  addGuestCallBack = (evt) => {
    this.addGuestSlctdQnty = Number(evt.target.dataset.value);
    this.isUpdateDropdownProps = false;
    this.addOnGuest.quantity = this.addGuestSlctdQnty;
    this.calcTotal();
    this.scrollToNextSection('addGuest');
  };

  bindGuest = (itm) => {
    const guest = [],
      max = itm.maxLength,
      addGuest = this.props.getDiscountedProducts(this.addOnGuestProds, this.daysDelta)[0];
    let idx;
    for (idx = max; idx > 0; idx--) {
      guest.push(
        <li className="addGuest--guest-items" key={idx}>
          <input
            type="radio"
            name="addGust"
            id={'guest' + idx}
            onClick={this.addGuestCallBack}
            data-value={idx}
            value={idx}
            defaultChecked={this.addGuestSlctdQnty === idx ? true : false}
            onChange={this.addGuestCallBack}
            tabIndex={this.addGuestSlctdQnty === idx ? '0' : '-1'}
          />
          <label htmlFor={'guest' + idx}>
            <div className="radio"></div>
            <div className="title-container">
              <p className="title">
                <span>{idx}</span>
                <span>{idx !== 1 ? itm.collection.passengers : itm.collection.passenger}</span>
              </p>
              <p className="price">
                <span className="price--currency">{this.props.data.currency}</span>
                <span className="price--value">{Number(addGuest.gross) * idx}</span>
              </p>
            </div>
          </label>
        </li>,
      );
    }
    guest.push(
      <li className="addGuest--guest-items">
        <input
          type="radio"
          name="addGust"
          id={'guest' + idx}
          onClick={this.addGuestCallBack}
          data-value={idx}
          value={idx}
          defaultChecked={this.addGuestSlctdQnty === idx ? true : false}
          onChange={this.addGuestCallBack}
          tabIndex={this.addGuestSlctdQnty === idx ? '0' : '-1'}
        />
        <label htmlFor={'guest' + idx}>
          <div className="radio"></div>
          <div className="title-container">
            <p className="title">
              <span>{itm.collection.noThanks}</span>
            </p>

            <p className="price price-hide">
              <span className="price--currency">{this.props.data.currency}</span>
              <span className="price--value">0</span>
            </p>
          </div>
        </label>
      </li>,
    );
    return guest;
  };

  renderAddGuest = (itm) => {
    if (itm.maxLength && this.addOnGuest) {
      return (
        <div className="addGuest section">
          <DynamicContent
            tagName="p"
            innerHtml={itm.title}
            attrs={{
              className: 'addGuest--title',
            }}
          />
          <DynamicContent
            tagName="p"
            innerHtml={itm.description}
            attrs={{
              className: 'addGuest--sub-title',
            }}
          />
          <ul className="addGuest--wrapper">{this.bindGuest(itm)}</ul>
        </div>
      );
    } else {
      return null;
    }
  };

  getSecProducts = (primProd) => {
    const secProds = this.props.getDiscountedProducts(this.secProds || [], this.daysDelta),
      sortedArray = helper.removeDuplicatedSecProd(secProds).sort((a, b) => b.gross - a.gross),
      filterTypeProd = sortedArray.filter((prod) => prod.ticketVal === primProd.ticketVal);
    return filterTypeProd;
  };

  secPrdCallBack = (evt) => {
    this.slctdSecIdx = Number(evt.target.dataset.value);
    this.isUpdateDropdownProps = false;
    this.secProdSlctd = JSON.parse(evt.target.dataset.prod);
    this.calcTotal();
    this.scrollToNextSection('b2c-drive-options');
  };

  bindSecPrds = (itm) => {
    const lstItms = [],
      secSortedProds = this.getSecProducts(this.primaryProd),
      sortedArrayLng = secSortedProds.length;
    let idx;
    for (idx = 0; idx < sortedArrayLng; idx++) {
      lstItms.push(
        <li className="exctmnt--list-items" key={idx}>
          <input
            type="radio"
            name="secPrds"
            id={'secPrds' + idx}
            onClick={this.secPrdCallBack}
            data-value={idx}
            data-prod={JSON.stringify(secSortedProds[idx])}
            value={idx}
            {...(!this.productChanged && { defaultChecked: idx === this.slctdSecIdx })}
            {...(this.productChanged && { checked: idx === this.slctdSecIdx })}
            onChange={this.secPrdCallBack}
            tabIndex={this.slctdSecIdx === idx ? '0' : '-1'}
          />
          <label htmlFor={'secPrds' + idx}>
            <div className="radio"></div>
            <div className="title-container">
              <p className="title">{secSortedProds[idx].productName}</p>
              <p className="price">
                <span className="price--currency">{this.props.data.currency}</span>
                <span className="price--value">
                  {Number(secSortedProds[idx].gross) - Number(this.primaryProd.gross)}
                </span>
              </p>
            </div>
          </label>
        </li>,
      );
    }
    lstItms.push(
      <li className="exctmnt--list-items" key={idx}>
        <input
          type="radio"
          name="secPrds"
          id={'secPrds' + idx}
          onClick={this.secPrdCallBack}
          data-value={idx}
          data-prod={JSON.stringify(this.primaryProd)}
          value={idx}
          {...(!this.productChanged && { defaultChecked: this.slctdSecIdx === sortedArrayLng })}
          {...(this.productChanged && { checked: this.slctdSecIdx === sortedArrayLng })}
          defaultChecked={this.slctdSecIdx === sortedArrayLng ? true : false}
          onChange={this.secPrdCallBack}
          tabIndex={this.slctdSecIdx === sortedArrayLng ? '0' : '-1'}
        />
        <label htmlFor={'secPrds' + idx}>
          <div className="radio"></div>
          <div className="title-container">
            <p className="title">{itm.collection.noThanks}</p>
            <p className="price price-hide">
              <span className="price--currency">{this.props.data.currency}</span>
              <span className="price--value">0</span>
            </p>
          </div>
        </label>
      </li>,
    );
    return lstItms;
  };

  renderExcitemnt = (itm) => {
    if (this.secProds && this.secProds.length && this.secProdSlctd) {
      return (
        <div className="exctmnt section b2c-drive-options">
          <DynamicContent
            tagName="p"
            innerHtml={itm.title}
            attrs={{
              className: 'exctmnt--title',
            }}
          />
          <DynamicContent
            tagName="p"
            innerHtml={itm.description}
            attrs={{
              className: 'exctmnt--sub-title',
            }}
          />
          <ul className="exctmnt--wrapper">{this.bindSecPrds(itm)}</ul>
        </div>
      );
    } else {
      return null;
    }
  };

  updateButtonState = () => helper.updateButtonState(this);

  tAndCcallback = (evt) => {
    this.tAndCchecked = evt.target.checked ? true : false;
    this.isUpdateDropdownProps = false;
    this.updateButtonState();
  };

  tAndCKeyDown = (evt) => {
    if (isEnterPressed(evt)) {
      const ele = evt.target.parentElement.querySelector('[type=radio], [type=checkbox]');
      ele && ele.click();
    }
  };

  renderSections = () => {
    const multiTktSelc = this.props.multiTicketSelector,
      sections = multiTktSelc.options,
      keyMap = multiTktSelc.stringMapping;

    return sections.map((item) => {
      switch (item.identifier) {
        case keyMap.timeSlot:
          return this.renderTimeSlot(item);
        case keyMap.license:
          return this.renderDrivingLicense(item);
        case keyMap.addGuest:
          return this.renderAddGuest(item);
        case keyMap.secondaryProd:
          return this.renderExcitemnt(item);
        default:
          return null;
      }
    });
  };

  updateFnlAddGust = () => {
    this.addOnGuest.groupType = UIConfig.b2c.purchaseJourney.additionalProductCode;
    this.addOnGuest.otherReason = this.addOnGuest.experienceCatgory;
    this.addOnGuest.ticketVal = this.secProdSlctd.ticketVal;
    this.addOnGuest.ticketType = this.secProdSlctd.ticketType;
    this.addOnGuest.accessPolicy = this.secProdSlctd.accessPolicy;
    this.addOnGuest.parksKey = this.secProdSlctd.parksKey;
    this.addOnGuest.category = this.secProdSlctd.category;
    this.addOnGuest.productType = this.addOnGuest.tab;
  };

  isTimeslotBooked = (finalProds) => {
    const regEx = new RegExp(/DRIVE|PASS/, 'g');
    const productIdTypeList = finalProds.map((prod) => getProdIdType(prod).replace(regEx, ''));
    return (
      this.props.cartData &&
      Object.keys(this.props.cartData.cart.items).some((key) => productIdTypeList.includes(key.replace(regEx, '')))
    );
  };

  addToCart = (checkoutContext) => helper.addToCart(this, checkoutContext);

  /** Language Switcher Start */

  getOverlaySelMapping = () => {
    let overlaySelMap = {};
    if (this.productOverlaySelector && this.productOverlaySelector.collections) {
      for (let itm in this.productOverlaySelector.collections) {
        overlaySelMap[this.productOverlaySelector.collections[itm].coveoval] = this.productOverlaySelector.collections[
          itm
        ].defaultchecked;
        if (this.productOverlaySelector.collections[itm].defaultchecked === true) this.overlaySelAvailable = true;
      }
    }
    return overlaySelMap;
  };

  getDefaultAdditionalProd = () => helper.getDefaultAdditionalProd(this);

  scrollToNextSectionNew = (section) => {
    let sectionEle = document.getElementsByClassName(section)[0];
    if (section === 'react-datepicker' && this.isDesktop) {
      sectionEle = document.querySelector('.react-datepicker-popper').parentElement;
    }
    scrollTo(document.getElementsByClassName('overlay-wrapper')[0], sectionEle.nextElementSibling.offsetTop, 500);
  };
  setDate = (date) => {
    this.isUpdateDropdownProps = true;
    this.product.fromDate = date.locale('en').format(UIConfig.calendar.dateFormat);
    const dateSlc = date.format(UIConfig.calendar.dateFormat);
    this.dateSlctd = dateSlc;
    this.updateDateInProds(dateSlc);
    this.setState({
      date: date,
      visitedDate: date,
    });

    if (this.product.itemType === 'PER' || this.product.hasperformance === '1') {
      if (this.enableDynamicCalendar && this.isDynamicPricingProd) {
        helper.fetchMultiProductPerformance(this);
      } else {
        helper.getProductPerformance(this, date);
      }
    } else {
      if (this.isDynamicPricingProd && this.calendarSettings.enableDatePreSelection) {
        this.setState({
          selectDefaultDate: true,
        });
      }
    }
  };

  GTMDataOnSelectItem = (ticket) => {
    try {
      const { data, additionalProds = [] } = this.props;
      const secondaryProd = additionalProds.find((prod) => prod.experienceCatgory?.toLowerCase() !== 'primary');
      const itemArr = [ticket];
      if (secondaryProd) {
        itemArr.push(secondaryProd);
      }
      const category =
        data && data.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      GTMData.push('selectItem', { itemsList: itemArr, category, gaTabTitle: data?.gaTabTitle || undefined });
    } catch (error) {
      console.log('GA4 error: ', error);
    }
  };

  addCarsCallBack = (evt) => {
    this.productChanged = true;
    this.isUpdateDropdownProps = false;
    this.calcTotal();
    const currentProduct = deepCloneObject(JSON.parse(evt.target.dataset.prod));
    const secSortedProds = this.getSecProducts(currentProduct);
    const defaultSec = 0;
    this.product = currentProduct;
    this.primaryProd = currentProduct;
    this.GTMDataOnSelectItem(currentProduct);
    this.slctdSecIdx = defaultSec;
    this.secProdSlctd = secSortedProds?.length ? secSortedProds[defaultSec] : this.product;
    this.fallBackProducts.baseProduct = currentProduct;
    this.scrollToNextSection('calender--title');
    this.setDate(this.state.date);
    this.setState({
      disableAddToCart: true,
      performanceData: {},
      slotSelected: false,
    });
  };

  bindCars = (itms) => {
    const cars = [],
      carProds = this.additionalProducts || [];
    let idx,
      carsProdLen = carProds.length;

    for (idx = 0; idx < carsProdLen; idx++) {
      let defaultCheck = false;
      if (
        !this.productChanged &&
        (this.overlaySelMap[carProds[idx].ticketVal] ||
          carProds[idx].productId === this.defaultSelAdditionalProds.productId)
      ) {
        this.primaryProd = carProds[idx];
        defaultCheck = true;
      }
      cars.push(
        <li className="exctmnt--list-items" key={idx}>
          <input
            type="radio"
            name="carPrds"
            id={'carPrds' + idx}
            data-value={idx}
            data-prod={JSON.stringify(carProds[idx])}
            value={idx}
            defaultChecked={defaultCheck}
            onClick={this.addCarsCallBack}
            tabIndex={
              carProds[idx].productId ===
              (resolvePath(this.fallBackProducts, 'baseProduct.productId') || this.defaultSelAdditionalProds.productId)
                ? '0'
                : '-1'
            }
          />
          <label htmlFor={'carPrds' + idx}>
            <div className="radio"></div>
            <div>
              <p className="title">{itms.collections[idx].title}</p>
              <p className="price">
                <span className="price--currency">{`${itms.collections[idx].price || ''}`}</span>
              </p>
            </div>
          </label>
        </li>,
      );
    }
    return cars;
  };

  renderCarsAddOns = () => {
    let prodOS = this.productOverlaySelector;
    return (
      <div className="exctmnt section b2c-drive-section">
        <DynamicContent
          tagName="p"
          innerHtml={prodOS.title}
          attrs={{
            className: 'exctmnt--title',
          }}
        />
        <ul className="exctmnt--wrapper">{this.bindCars(prodOS)}</ul>
      </div>
    );
  };

  /** Language Switcher End */
  /** Quantiy Start */

  setVisitorsCount = (data) => {
    this.setState(
      {
        ticketCount: data.spinnerCount,
      },
      () => {
        this.calculateTotal();
        this.updateButtonState();
      },
    );
  };

  calculateTotal = () => helper.calculateTotal(this);

  /**  Qauntity End */

  renderTotalAmount = () => <TotalAmount context={this} isDrivingExperience />;
  renderAmountwithButtons = () => <AmountWithButtons context={this} isDrivingExperience />;
  importantNotes = () =>
    this.isDynamicPricingProd &&
    this.calendarSettings &&
    this.calendarSettings.options && <ImportantNotes options={this.calendarSettings.options} isDrivingExperience />;

  render() {
    try {
      const data = this.props.data;
      const hideTnC = this.props.multiTicketSelector.hideTNC;
      this.tAndCavailable = this.props.multiTicketSelector.tnc ? true : false;
      let showCars =
        this.props.productOverlaySelector && this.props.additionalProds && this.props.additionalProds.length
          ? true
          : false;
      let hideQtySelector = this.state.maxAvailableTickets <= 1 && showCars;

      return (
        <div
          className={`component c-driving-exp ${this.isDynamicPricingProd && 'v-dynamic-product-pricing'}`}
          data-component-name="DrivingExperienceB2C"
          data-c-render="client-only"
        >
          {this.isDynamicPricingProd && showCars && this.renderCarsAddOns()}
          {this.renderCalender()}
          {this.renderSections()}
          {this.props.counterData && this.state.slotSelected && (
            <CounterComponent
              data={{ primaryCounter: this.props.counterData }}
              name={this.props.product.productName}
              setVisitorData={this.setVisitorsCount}
              isAdultChildOverlay={this.isAdultChildOverlay}
              maxAvailableTicketsCount={this.state.maxAvailableTickets}
              hideQtySelector={hideQtySelector}
            />
          )}
          {!hideTnC && (
            <div className={`t-n-c ${hideTnC ? 't-n-c-hide' : ''}`}>
              <label htmlFor="tAndCCheckbox" className="checkbox-label">
                <input type="checkbox" onClick={this.tAndCcallback} className="hide" id="tAndCCheckbox" />
                <div className="checkbox" onKeyDown={this.tAndCKeyDown}></div>
                <DynamicContent
                  tagName="div"
                  attrs={{ className: 't-n-c--label' }}
                  innerHtml={this.props.multiTicketSelector.tnc}
                />
              </label>
            </div>
          )}

          {this.state.existingProductError && (
            <div className="existingProductError">
              <ErrorSummary
                data={{
                  error: {
                    code: UIConfig.errorCodes.invalidTimeSlot,
                    text: this.props.businessErrors[UIConfig.errorCodes.invalidTimeSlot],
                  },
                }}
              />
            </div>
          )}

          {this.renderAmountwithButtons()}
          {this.importantNotes()}
          <DynamicContent tagName="div" attrs={{ className: 'vat-info' }} innerHtml={data.vatInfo} />
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'DrivingExperienceB2C');
    }
  }
}
