import React from 'react';
import { DynamicContent } from '../base';

// Optional component to be rendered
// when you're not streaming
function OfflineComponent(data) {
  return (
    <div className="live-streaming-wrapper">
      <DynamicContent tagName="div" attrs={{ className: 'live-stream-offline' }} innerHtml={data.message} />
    </div>
  );
}

export default OfflineComponent;
