import React from 'react';
import PropTypes from 'prop-types';
import CardCarouselComponent from '../../presentation/card-carousel-component';
import HeroBanner from '../../presentation/hero-banner-component';
import { DynamicContent } from '../../presentation/base';
import EditoralGrid from '../editorial-grid';
import { canUseDOM, detectMobile, detectViewPort, getYasIdGTMData } from '../../../common/utility';

/**
 * CarouselWithPreview class use  CardCarouselComponent and HeroBanner component
 * and position them according to viewport
 */
export default class CarouselWithPreview extends React.Component {
  /**
   * editorialGridObject function to get the object with particulars of editorial grid data with carousel
   */

  editorialGridObject(data) {
    const viewport = detectViewPort();
    let gridData = {
      data: {
        imageDetail: [],
        ctaDetail: {},
        title: data.title,
        variant: 'v-carousel-view',
        carousel: {
          infinite: false,
          slidesToShow: viewport === 'tablet' ? 3 : 2,
          mobileDisableLazyLoad: !!(viewport === 'mobile'),
        },
      },
    };
    data.carouselList &&
      data.carouselList.forEach((carouselItem, idx) => {
        let cardData = {
          imageTitle: carouselItem.cardTitle || '',
          imageInfo: carouselItem.videoImage || carouselItem.heroImage || carouselItem.cardImage,
          shortImageTitle: carouselItem.shortTitle || '',
          bodyCopy: carouselItem.heroDescription,
          moreLinkButton: {
            href: carouselItem.heroButton && carouselItem.heroButton.href,
            label: carouselItem.heroButton && carouselItem.heroButton.label,
            target: carouselItem.heroButton && carouselItem.heroButton.target,
          },
          videoFirstOnMobile: true,
          videoData: {
            isSwipable: true,
          },
          activeCardId: idx,
          isActive: idx === 0,
        };
        if (carouselItem.videoInfo) {
          cardData.videoInfo = carouselItem.videoInfo;
        }
        gridData.data.imageDetail.push(cardData);
      });

    return gridData;
  }

  /**
   * heroObject function to get the object with particular keys for Hero component
   * @param  {object} data --contain json data
   * @return {object} according to supplied data return object
   */
  heroObject(data) {
    return {
      heroTitle: data.heroTitle,
      heroDescription: data.heroDescription,
      heroButton: data.heroButton,
      heroImage: data.heroImage,
      textAlignment: data.textAlignment,
      theme: data.theme,
      videoInfo: data.videoInfo,
      videoImage: data.videoImage,
      shortTitle: data.shortTitle,
      videoData: {
        isSwipable: true,
      },
      addContrast: data.addContrast,
    };
  }

  componentDidMount() {
    if (canUseDOM() && this.props.data && this.props.data.variant === 'v-mobile-editorial-grid') {
      window.PubSub.subscribe('oreintationChange', () => {
        setTimeout(() => {
          this.setupComponentVariables();
          this.forceUpdate();
        }, 50);
      });
    }
  }

  setupComponentVariables = () => {
    this.isMobileEditorialGridVariant =
      this.props.data && this.props.data.variant === 'v-mobile-editorial-grid' && detectMobile();
    if (this.isMobileEditorialGridVariant) {
      this.state = {};
      this.editorialGridData = this.editorialGridObject(this.props.data);
    } else {
      const carouselItem =
        this.props.data && this.props.data.carouselList && Boolean(this.props.data.carouselList.length)
          ? this.props.data.carouselList
          : null;
      /**
       * Use "cardDefaultValue" key to find the default selected card in cms
       * if condition get true it store the index of that card in activeCardId variable
       */
      this.activeCardId = 0;
      carouselItem &&
        carouselItem.some((obj, idx) => {
          if (obj.cardDefaultValue) {
            this.activeCardId = idx;
            return true;
          }
        });

      /**
       * initialize the state with activeCardId value
       */
      this.state = carouselItem && this.heroObject(carouselItem[this.activeCardId]);
    }
  };

  /**
   * constructor function to set the state of hero object with details of default selected card by using for loop
   * and binding this with functions
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.setupComponentVariables();
  }

  /**
   * heroHandler is used to set the state  for HeroBanner component
   * @param {object} data -- contain the properties to set Hero component data
   * @return {undefined}
   */
  heroHandler = (data) => {
    this.setState(this.heroObject(data));
  };

  render() {
    if (!this.props.data ? true : !this.props.data.carouselList || !this.props.data.carouselList.length) {
      return null;
    }

    if (this.isMobileEditorialGridVariant) {
      return (
        <div
          className={
            'component c-carousel-with-preview-component ' + this.props.data.variant + ' ' + this.props.data.theme
          }
          data-c-render="universal"
        >
          <div className="carousel-with-preview-holder">
            <EditoralGrid data={this.editorialGridData.data} />
          </div>
        </div>
      );
    }

    return (
      <div
        className={
          'component c-carousel-with-preview-component ' + this.props.data.variant + ' ' + this.props.data.theme
        }
        data-c-render="universal"
      >
        {this.props.data.title ? (
          <DynamicContent
            tagName={'div'}
            innerHtml={this.props.data.title}
            attrs={{ className: 'carousel-with-preview-title heading-1' }}
          />
        ) : (
          ''
        )}
        <div className="carousel-with-preview-holder">
          <div
            className={`hero-banner-holder ${this.state.theme ? this.state.theme : 'light'} ${
              this.state.textAlignment ? this.state.textAlignment : 'center'
            }`}
          >
            <HeroBanner
              title={{
                text: this.state.heroTitle,
                heading: this.props.data.bannerHeading,
              }}
              titleDescription={this.state.heroDescription}
              cta={this.state.heroButton}
              heroImage={this.state.heroImage}
              shortTitle={this.state.shortTitle}
              heroVideo={this.state.videoInfo}
              heroVideoImage={this.state.imageInfo}
              heroVideoData={this.state.videoData}
              activeCardId={this.activeCardId}
              addContrast={this.state.addContrast}
              disableLazyLoadImage
            />
          </div>
          <div className="card-carousel-component-holder">
            <CardCarouselComponent
              data={this.props.data}
              heroHandler={this.heroHandler}
              activeCardId={this.activeCardId}
              heroObject={this.heroObject}
            />
          </div>
          {/* <video
                        autoPlay
                        muted
                        playsInline
                        loop
                        src={this.state.videoInfo.videoUrl}
                    /> */}
        </div>
      </div>
    );
  }
}

CarouselWithPreview.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    bannerHeading: PropTypes.string,
    carouselList: PropTypes.arrayOf(
      PropTypes.shape({
        cardTitle: PropTypes.string,
        cardStripFlag: PropTypes.bool,
        cardStripText: PropTypes.string,
        cardDefaultValue: PropTypes.bool,
        cardImage: PropTypes.object.isRequired,
        heroDescription: PropTypes.string,
        heroTitle: PropTypes.string,
        heroImage: PropTypes.shape({
          desktopImage: PropTypes.shape({
            html: PropTypes.string,
            src: PropTypes.string.isRequired,
          }).isRequired,
          mobileImage: PropTypes.shape({
            html: PropTypes.string,
            src: PropTypes.string.isRequired,
          }).isRequired,
          tabletImage: PropTypes.shape({
            html: PropTypes.string,
            src: PropTypes.string.isRequired,
          }).isRequired,
          imageAltText: PropTypes.string.isRequired,
        }).isRequired,
        heroButton: PropTypes.object,
        videoInfo: PropTypes.object,
        videoImage: PropTypes.shape({
          desktopImage: PropTypes.shape({
            html: PropTypes.string,
            src: PropTypes.string.isRequired,
          }).isRequired,
          mobileImage: PropTypes.shape({
            html: PropTypes.string,
            src: PropTypes.string.isRequired,
          }).isRequired,
          tabletImage: PropTypes.shape({
            html: PropTypes.string,
            src: PropTypes.string.isRequired,
          }).isRequired,
          imageAltText: PropTypes.string.isRequired,
        }),
        textAlignment: PropTypes.string,
        theme: PropTypes.string,
        variant: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};
