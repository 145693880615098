import React from 'react';
import './disclaimer-card-component.scss';

function DisclaimerCard({ ticketDisclaimerTitle, ticketDisclaimerDescription, isSwad }) {
  return (
    <div className="disclaimerCard-frame">
      <div className="div">
        {/* <img className="information" alt="Information" src="information.svg" /> */}
        <div className="disclaimer-heading">
          {!isSwad ? (
            <svg
              className="information"
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
            >
              <path
                d="M9.57031 0C14.541 0 18.5703 4.0293 18.5703 9C18.5703 13.9707 14.541 18 9.57031 18C4.59961 18 0.570312 13.9707 0.570312 9C0.570312 4.0293 4.59961 0 9.57031 0ZM9.56131 7.2H8.67031C8.44092 7.20025 8.22028 7.28809 8.05348 7.44556C7.88668 7.60304 7.7863 7.81826 7.77286 8.04726C7.75941 8.27625 7.83392 8.50174 7.98115 8.67765C8.12838 8.85356 8.33723 8.96661 8.56501 8.9937L8.67031 9V13.491C8.67031 13.959 9.02491 14.346 9.48031 14.3946L9.57931 14.4H10.0203C10.2096 14.4 10.3941 14.3403 10.5475 14.2295C10.7009 14.1186 10.8154 13.9622 10.8749 13.7825C10.9343 13.6028 10.9355 13.4089 10.8784 13.2284C10.8213 13.048 10.7087 12.8901 10.5567 12.7773L10.4703 12.7206V8.109C10.4703 7.641 10.1157 7.254 9.66031 7.2054L9.56131 7.2ZM9.57031 4.5C9.33162 4.5 9.1027 4.59482 8.93392 4.7636C8.76513 4.93239 8.67031 5.1613 8.67031 5.4C8.67031 5.63869 8.76513 5.86761 8.93392 6.0364C9.1027 6.20518 9.33162 6.3 9.57031 6.3C9.80901 6.3 10.0379 6.20518 10.2067 6.0364C10.3755 5.86761 10.4703 5.63869 10.4703 5.4C10.4703 5.1613 10.3755 4.93239 10.2067 4.7636C10.0379 4.59482 9.80901 4.5 9.57031 4.5Z"
                fill="#3860BE"
              />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M10.0008 19.2008C12.4408 19.2008 14.7809 18.2315 16.5062 16.5062C18.2315 14.7809 19.2008 12.4408 19.2008 10.0008C19.2008 7.5608 18.2315 5.22069 16.5062 3.49532C14.7809 1.77005 12.4408 0.800781 10.0008 0.800781C7.56079 0.800781 5.22069 1.77005 3.49532 3.49532C1.77005 5.22069 0.800781 7.5608 0.800781 10.0008C0.800781 12.4408 1.77005 14.7809 3.49532 16.5062C5.22069 18.2315 7.56079 19.2008 10.0008 19.2008ZM10.0008 4.24789C10.3261 4.24789 10.6381 4.37712 10.8682 4.60712C11.0982 4.83712 11.2274 5.14913 11.2274 5.47454C11.2274 5.79982 11.0982 6.11184 10.8682 6.34182C10.6381 6.57195 10.3261 6.70119 10.0008 6.70119C9.67551 6.70119 9.36348 6.57196 9.13336 6.34182C8.90337 6.11182 8.77413 5.79981 8.77413 5.47454C8.77413 5.14912 8.90337 4.8371 9.13336 4.60712C9.3635 4.37712 9.67551 4.24789 10.0008 4.24789ZM8.77413 10.0009C8.77413 9.56266 9.00796 9.15769 9.38746 8.93854C9.76695 8.71949 10.2346 8.71949 10.6141 8.93854C10.9936 9.15772 11.2274 9.56269 11.2274 10.0009V14.9075C11.2274 15.3457 10.9936 15.7507 10.6141 15.9699C10.2346 16.1889 9.76695 16.1889 9.38746 15.9699C9.00796 15.7507 8.77413 15.3457 8.77413 14.9075V10.0009Z"
                fill="#AB21AA"
              />
            </svg>
          )}

          <h5 className="text-wrapper">{ticketDisclaimerTitle}</h5>
        </div>
        <p className="p">{ticketDisclaimerDescription}</p>
      </div>
    </div>
  );
}

export default DisclaimerCard;
