import { CreditSettle, AdvanceTopupService, OrderService } from '../../src/common/services';
import { getLoggedInUser } from './utility';

export function setCreditSettlementData(data) {
  CreditSettle.createSettle(data.cart, 'c-payment-wrapper', data.createSettlementAPI.url).then((res) => {
    // splitiing settleID receving from response
    // its been ussed for resCode which is send in payload for send3Durl and reconsile
    const res_Code = res.data.settleId.split('-');
    const settleIdLength = res_Code[res_Code.length - 1];

    window.PubSub.publish('CreateOrderSuccess', {
      isCreateOrderSuccess: true,
      orderId: res.data.settleId,
      resCode: settleIdLength,
      CreditAks: data.reconcilePayloadData,
      total: res.data.payfortAmount,
    });
  });
  // .catch()
}

// payfort service for Advance Top UP
export function setAdvanceTopupData(data) {
  const newUrl = `${data.advancePaymentAPI.url}?tenantid=${getLoggedInUser().tenantID}`;
  AdvanceTopupService.getAdvanceTopupData(newUrl, true, 'c-payment-wrapper', data.cart).then((res) => {
    // splitiing settleID receving from response
    // its been ussed for resCode which is send in payload for send3Durl and reconsile
    const res_Code = res.data.sale.ak.split('.');
    const settleIdLength = res_Code[res_Code.length - 1];

    window.PubSub.publish('CreateOrderSuccess', {
      isCreateOrderSuccess: true,
      orderId: res.data.sale.ak,
      resCode: settleIdLength,
      total: res.data.sale.total.payfortAmount,
      // total: res.data.sale.total.net*100,
    });
  });
}
// B2b only : to update the order payment status: failure
export function savePaymentFailureStatus(data, url) {
  const payload = {
    paymentstatus: {
      ReservationCode: data.reservationCode,
      status: data.paymentStatus || 'Payment Failed',
    },
  };
  return OrderService.updatePaymentStatusOrder(payload, url);
}
