import React from 'react';
import { AnchorLink } from '..';

function CTAButton(props) {
  const { ctaButton, type, size } = props;
  return (
    <AnchorLink
      {...props}
      link={ctaButton}
      className={`cta-button ${type} ${size} ${ctaButton.class}`}
      onClick={props.customClickAnalytic || ''}
    />
  );
}

export default CTAButton;
