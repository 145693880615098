/**
 * tabs-with-placeholder-component.js
 * This file contains code for Multiple Component Renderting with tabs functionality
 * @licensor  Miral
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import UIConfig from '../../../common/UIConfig.js';
import {
  checkTenant,
  canUseDOM,
  detectMobile,
  generateArrays,
  getHeaderHeight,
  putElementInViewport,
  randomNumber,
  resolvePath,
  parseQueryString,
  isMatchTenant,
  getGa4Category,
  toLowerCase,
  getLoggedInUser,
  checkIfParks,
  checkGA4Tenants,
  isEmailVerificationEnabled,
  checkIfParksTenants,
} from '../../../common/utility';
import { DynamicContent, SvgSprite } from '../../presentation/base';
import TabHeader from './tab-header-component';
import TabPanel from './tab-panel-component';
import { logComponentRenderingError } from '../../../common/logger';
import './tabs-with-placeholder-component.scss';
import './tabs-with-placeholder-component-ar.scss';
import GTMData from '../b2c-purchase-journey/gtm-data.js';
import { ProfileServices } from '../../../common/services/index.js';

/**
 * getSelectedTab is called to check activeTab
 * @param {[Void]} function does not accept anything.
 * @return {[number]} function return selected index.
 */
const getSelectedTab = (defaultTab, tabHashValue, tabs, variant, tabsComponentRef, tenantVariant) => {
  let selectedTab = '';
  if (canUseDOM()) {
    if (defaultTab) {
      selectedTab = parseInt(defaultTab);
    } else {
      let hashValue = window.location.hash.split('#')[1],
        activeTab = 0,
        tabIndex;
      if (hashValue) {
        tabIndex = tabHashValue.indexOf(hashValue);
        activeTab = !isNaN(tabIndex) && (tabs || []).length > tabIndex ? tabIndex : 0;
        if (activeTab === -1) {
          activeTab = 0;
        }
        scrollToTabs(tabsComponentRef);
      }
      putElementInViewport(document.getElementsByClassName('tabs-menu--links')[activeTab]);
      selectedTab = activeTab;
    }
  }

  return selectedTab;
};

const isCookiesHeaderVisible = () => canUseDOM() && !!document.getElementsByClassName('c-cookies')[0];

const scrollToTabs = (tabsComponentRef) => {
  if (tabsComponentRef && tabsComponentRef.current) {
    setTimeout(() => {
      const isProfile = window.location.pathname.includes(UIConfig.pages.profile);
      if (!isProfile && !checkIfParksTenants([UIConfig.tenants.swadb2c])) {
        tabsComponentRef.current && tabsComponentRef.current.scrollIntoView({ block: 'start', behaviour: 'smooth' });
      }
    }, 0);
  }
};

const GTMDataOnClickTab = (data) => {
  if (checkGA4Tenants()) {
    GTMData.push(UIConfig.ga4Constants.CLICK_TAB, {
      name: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.tabTitle) : data.tabTitle,
      elementText: isMatchTenant(UIConfig.tenants.yi) ? toLowerCase(data.tabTitle) : data.tabTitle,
      category: toLowerCase(getGa4Category(window?.location?.pathname)),
    });
  }
};

/**
 * TabsWithPlaceholder Component contains the functionality to show multiple components as a TAB feature
 * @return   {[Object]} Return a JSX of the component
 */
const TabsWithPlaceholder = ({ data }) => {
  const { componentTitle, defaultTab, gradient, tabs, variant, tenantVariant } = data;
  const [allTabs, setAllTabs] = useState(tabs);
  const theme = data.theme || '';
  const tabsHeader = allTabs && allTabs.map((tab) => tab.tabTitle);
  const tabHashValue = (allTabs || []).map((tab) => tab.placeholderName);
  const randNum = randomNumber(3);
  const FESTIVE_SEASON = 'festive-season';

  const [activeTab, setActiveTab] = useState('');
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isCookies, setIsCookies] = useState(false);
  const [linkedValue, setLinkedValue] = useState(false);
  const [tabsElem, setTabsElem] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [slickSettings, setSlickSettings] = useState({
    slickTabs: true,
    infinite: false,
    autoplay: false,
    arrows: false,
    dots: false,
    variableWidth: false,
    useTransform: false,
    slidesToShow: canUseDOM() && document.querySelector('body').classList.contains('lang-ar') ? 3.5 : 2.5,
    initialSlide: 0,
  });
  const [isMyProfile, setIsMyProfile] = useState(false);
  const isEmailVerifEnabled = isEmailVerificationEnabled();
  const yasTab = tabs?.filter((item) => {
    if (item?.components[0]?.data?.data?.packageCategories?.categories?.length > 0) {
      return item;
    }
  });

  const tabsComponentRef = useRef();
  let category = parseQueryString('ctype');

  let isYasIsland = tenantVariant === UIConfig.YAS;

  const listingYasTab = category && tabs.filter((item) => category === item.tabKey);
  const isSwad = checkTenant(UIConfig.iamMapping.swad);
  useEffect(() => {
    // if (data.variant === 'v-tabs-dropdown') {
    const PROFILE_PLACEHOLDER = 'myprofileb2c';
    const MY_ACCOUNT_PLACEHOLDER = 'myaccount'; // this is added for swad as 'myprofileb2c' is not available for swad
    const myProfile = data.tabs.find((tab) => {
      return tab.placeholderName === PROFILE_PLACEHOLDER || tab.placeholderName === MY_ACCOUNT_PLACEHOLDER;
    });
    if (myProfile) {
      setIsMyProfile(true);
      const { apiStatus, sourceSystemName, services } =
        myProfile.components.find((component) => {
          return component?.componentName?.toLowerCase() === PROFILE_PLACEHOLDER;
        })?.data?.data || {};
      const config = {
        componentName: 'Profile',
        uniqueKey: 'channelType',
        preLoadTarget: '.my-profile-b2c',
        status: apiStatus,
        mapping: {},
        fieldTypes: {
          Link: 'link',
          Button: 'button',
          CheckBox: 'checkbox',
          Date: 'date',
          Reset: 'reset',
          Date2: 'date2',
          SelectWithText: 'SelectWithText',
        },
        sourceSystemName,
      };
      let newurl = getLoggedInUser().yasId;
      // fetching guest data which contains if email is verified or not
      var guestUrl = services.getGuestProfile.url.replace('{yasId}', newurl);
      const getGuestApiComplete = ProfileServices.GetGuestInfo(
        config.componentName,
        guestUrl,
        true,
        UIConfig.loader.defaultPreLoaderTarget,
      );
      getGuestApiComplete.then((infoResponse) => {
        if (infoResponse.data) {
          const { emailValidation } = infoResponse.data;
          const isVerified = emailValidation === 'Y';
          window.PubSub.publish(UIConfig.events.EMAIL_VERIFIED, isVerified);
          setIsEmailVerified(isVerified);
        }
      });
    }
    const newTabs = [];
    allTabs.map((tab) => {
      if (
        tab?.components[0]?.componentName === 'MyAnnualPasses' &&
        tab?.components[0]?.data?.data?.showLinkAnnualPassInManageAnnualPass
      ) {
        let linkAnnualPassData;
        let linkAnnualPassTabs = allTabs.filter((tab) => tab?.components[0]?.componentName === 'LinkAnnualPass');
        if (linkAnnualPassTabs[0]) {
          linkAnnualPassData = linkAnnualPassTabs[0]?.components[0]?.data?.data;
          tab.components[0].data.data['linkAnnualPassData'] = linkAnnualPassData;
        }
      } else if (
        tab?.components[0]?.componentName === 'LinkAnnualPass' &&
        tab?.components[0]?.data?.data?.hideLinkAnnualPassFromGlobalLevel
      ) {
        return;
      }
      newTabs.push(tab);
    });
    setAllTabs(newTabs);
    const selectedTab = getSelectedTab(defaultTab, tabHashValue, allTabs, variant, tabsComponentRef, tenantVariant);
    setActiveTab(selectedTab);
    detectMobile() && setSlickSettings({ ...slickSettings, initialSlide: selectedTab });
    setIsCookies(isCookiesHeaderVisible());
    setTabsElem(generateArrays('.tabs-menu [role="tab"]'));

    if (isYasIsland) document.getElementsByTagName('body')[0].classList.add('yas-hotel-booking-widget');

    window.PubSub.subscribe(UIConfig.events.COOKIE_CLOSE, () => {
      setIsCookies(isCookiesHeaderVisible());
    });
    window.PubSub.subscribe(UIConfig.events.REFRESH_TABS, () => {
      setActiveTab(getSelectedTab(defaultTab, tabHashValue, allTabs, variant, tabsComponentRef, tenantVariant));
    });
    window.PubSub.subscribe(UIConfig.events.ticketLinkedEvent, () => {
      setLinkedValue(true);
    });

    window.PubSub.subscribe('widgetData', (msg, data) => {
      if (data && data.eventType) {
        const index = newTabs.findIndex((obj) => obj.placeholderName === data.eventType);
        const finalIndex = index !== -1 ? index : 0;
        setActiveTab(finalIndex);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps

    let elAccordion = document.getElementsByClassName('v-splittabwithplaceholder-view');

    if (elAccordion.length > 0 && window.innerWidth <= 752) {
      let elChildren = elAccordion[0].children;
      let actionClick = null;

      for (let item of elChildren) {
        let listclass = item.classList.contains('component-title-collapse');
        if (listclass) actionClick = item;
      }

      if (actionClick != null || actionClick != undefined) {
        actionClick.addEventListener('click', (e) => {
          e.stopPropagation();

          let getAttr = elAccordion[0].getAttribute('data-display');

          if (getAttr === 'true') elAccordion[0].setAttribute('data-display', 'false');
          else {
            window.PubSub.subscribe('stickyClose', () => {
              elAccordion[0].setAttribute('data-display', 'false');
              elAccordion[0].style.zIndex = '1001';
            });

            elAccordion[0].removeAttribute('style');
            elAccordion[0].setAttribute('data-display', 'true');
            window.PubSub.publish('menuClose');
          }
        });

        let closeElmts = document.getElementsByClassName('c-header-top-icon');

        for (const item of closeElmts) {
          item.addEventListener('click', () => {
            let getAttr = elAccordion[0].getAttribute('data-display');
            if (getAttr === 'true') elAccordion[0].setAttribute('data-display', 'false');
          });
        }
      }
    }
    // move focus to top
    if (isSwad) {
      const onPageLoad = () => {
        window.scrollTo(0, 0);
      };
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
    }
  }, []);

  /**
   * onTabSelectionChange is called when user navigate to next tab  and then it set state of activeTab
   * @param {[number]} id-- index number of selected tab
   * @return {[Void]} function does not return anything.
   */
  const onTabSelectionChange = (id, tab) => {
    GTMDataOnClickTab(tab);
    if (canUseDOM()) {
      const isMobile = detectMobile();
      let lValue = linkedValue;
      if (lValue && tab.placeholderName === 'myannualpass') {
        window.PubSub.publish(UIConfig.events.fetchAnnualPasses);
        lValue = false;
      }
      if (isMobile && document.querySelector('.c-cookies')) {
        if (tab.components[0].componentName === 'MyAnnualPasses') {
          window.PubSub.publish(UIConfig.events.CHECK_COOKIE_POSITION);
        } else {
          if (!document.getElementsByTagName('body')[0].classList.contains('purchase-cookie')) {
            document.getElementsByTagName('body')[0].classList.add('purchase-cookie');
          }
        }
      }
      window.location.hash = tabHashValue[id];
      putElementInViewport(document.getElementsByClassName('tabs-menu--links')[id]);

      setActiveTab(id);
      setLinkedValue(lValue);
      isMobile && setSlickSettings({ ...slickSettings, initialSlide: id });
      if (tenantVariant === 'v-autoscroll-disabled') {
        return;
      }
      if (!checkTenant(UIConfig.iamMapping.swad) && !checkTenant(UIConfig.iamMapping.yasisland)) {
        if (variant != 'v-splittabwithplaceholder-view') {
          scrollToTabs(tabsComponentRef);
        }
      }
    }
  };

  const dropdownPanelHandler = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  const closeTab = () => {
    setActiveTab('');
  };

  // const emailVerificationVariant = data.variant === 'v-tabs-dropdown';
  if (isEmailVerifEnabled && isMyProfile && !isEmailVerified) {
    return <></>;
  }

  try {
    return (
      <div
        ref={tabsComponentRef}
        data-c-name="TabsWithPlaceholder"
        className={`c-tabs-with-placeholder component ${variant} ${isYasIsland ? 'yas-tenant' : ''} ${theme} ${
          isCookies ? 'cookies-visble' : ''
        } ${
          variant === 'v-splittabwithplaceholder-view' && gradient === FESTIVE_SEASON ? FESTIVE_SEASON : ''
        } gradient${randNum} ${gradient === 'v-tabwithplaceholder-toggle-gradient' && gradient} ${
          detectMobile() && data.showBorderBottomInMobile ? 'border-bottom-in-mobile' : ''
        }`}
        data-c-render="universal"
      >
        {componentTitle && (
          <DynamicContent
            tagName="p"
            attrs={{
              className: `component-title heading-2 ${
                variant === 'v-splittabwithplaceholder-view' ? 'component-title-collapse' : ''
              }`,
            }}
            innerHtml={componentTitle}
          />
        )}
        {variant && gradient && gradient !== FESTIVE_SEASON && (
          <DynamicContent
            tagName="style"
            innerHtml={`.c-tabs-with-placeholder.${variant}.component.gradient${randNum}::before { background: ${gradient}; }`}
          />
        )}
        <div className="tabs-with-content">
          {isYasIsland && variant === 'v-tabs-hotel-booking-widget' ? (
            !category &&
            yasTab.length > 1 && (
              <TabHeader
                variant={variant}
                headings={tabsHeader}
                changeTab={onTabSelectionChange}
                activeTab={activeTab}
                dropdownPanelHandler={dropdownPanelHandler}
                isDropdownActive={isDropdownActive}
                tabs={yasTab}
                tabsElem={tabsElem}
                slickSettings={slickSettings}
                theme={theme}
              />
            )
          ) : (
            <TabHeader
              variant={variant}
              headings={tabsHeader}
              changeTab={onTabSelectionChange}
              activeTab={activeTab}
              dropdownPanelHandler={dropdownPanelHandler}
              isDropdownActive={isDropdownActive}
              tabs={allTabs}
              tabsElem={tabsElem}
              slickSettings={slickSettings}
              theme={theme}
            />
          )}
          {!canUseDOM() && <div className="ssr-rendered">{''}</div>}
          <section
            style={
              tenantVariant !== UIConfig.YAS && variant === 'v-tabs-hotel-booking-widget' && detectMobile()
                ? { top: (activeTab + 1) * 60 - 15 + 'px' }
                : {}
            }
            className="tabs-panel-container"
          >
            {isYasIsland && variant === 'v-tabs-hotel-booking-widget'
              ? category
                ? listingYasTab.map((tab, i) =>
                    tab.components[0].data.data.packageCategories.categories.length < 1 ? (
                      ''
                    ) : (
                      <div
                        className={activeTab === i ? 'is-active tabs-panel' : 'tabs-panel'}
                        key={i.toString()}
                        id={`${tab.placeholderName}_panel_${i}`}
                        role="tabpanel"
                        aria-labelledby={`${tab.placeholderName}_tab_${i}`}
                      >
                        {variant === 'v-tabs-hotel-booking-widget' && (
                          <div className="close-tab" onClick={closeTab} role="button">
                            <SvgSprite id={'icn-close'} />
                          </div>
                        )}
                        <TabPanel tabsData={tab} index={i + 1} activeTab={activeTab === i} />
                      </div>
                    ),
                  )
                : yasTab &&
                  yasTab.map((tab, i) =>
                    tab.components[0].data.data.packageCategories.categories.length < 1 ? (
                      ''
                    ) : (
                      <div
                        className={activeTab === i ? 'is-active tabs-panel' : 'tabs-panel'}
                        key={i.toString()}
                        id={`${tab.placeholderName}_panel_${i}`}
                        role="tabpanel"
                        aria-labelledby={`${tab.placeholderName}_tab_${i}`}
                      >
                        {variant === 'v-tabs-hotel-booking-widget' && (
                          <div className="close-tab" onClick={closeTab} role="button">
                            <SvgSprite id={'icn-close'} />
                          </div>
                        )}
                        <TabPanel tabsData={tab} index={i + 1} activeTab={activeTab === i} />
                      </div>
                    ),
                  )
              : allTabs &&
                allTabs.map((tab, i) => (
                  <div
                    className={activeTab === i ? 'is-active tabs-panel' : 'tabs-panel'}
                    key={i.toString()}
                    id={`${tab.placeholderName}_panel_${i}`}
                    role="tabpanel"
                    aria-labelledby={`${tab.placeholderName}_tab_${i}`}
                  >
                    {variant === 'v-tabs-hotel-booking-widget' && (
                      <div className="close-tab" onClick={closeTab} role="button">
                        <SvgSprite id={'icn-close'} />
                      </div>
                    )}
                    <TabPanel tabsData={tab} index={i + 1} activeTab={activeTab === i} />
                  </div>
                ))}
          </section>
        </div>
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'TabsWithPlaceholder', variant);
  }
};

TabsWithPlaceholder.propTypes = {
  data: PropTypes.shape({
    tabTitle: PropTypes.string,
    placeholderName: PropTypes.string,
    components: PropTypes.shape({
      componentName: PropTypes.string,
      data: PropTypes.object,
    }),
  }),
};

export default TabsWithPlaceholder;
