import React, { useEffect } from 'react';
import { DynamicContent } from '../../../../presentation/base';
import Spinner from '../../../../presentation/base/spinner/spinner-component';
import { logComponentRenderingError } from '../../../../../common/logger';
import { toTwoDecimalPlaces } from '../../../../../common/utility';

const PackageQuantityExperienceB2C = (props) => {
  const { currency, data, maxErrorText } = props;
  const maxCount = data?.maximum;
  const minCount = data?.minimum;

  useEffect(() => {
    const calculatedAmount = parseInt(props.totalQuantity) * parseInt(props.data.price);
    props.handleQuantity(parseInt(props.totalQuantity));
    props.handleCalculatedAmount(calculatedAmount);
  }, []);

  const updateQuantity = (val) => {
    const calculatedAmount = parseInt(val) * parseInt(data?.price);
    props.handleQuantity(parseInt(val));
    props.handleCalculatedAmount(calculatedAmount);
  };

  try {
    return (
      <>
        <DynamicContent tagName="h3" attrs={{ className: 'heading-3' }} innerHtml={'Choose Your Package Quantity'} />
        <div className="c-visitors-count-selector">
          <div className="selector">
            <div className="c-visitors-count-selector-wrapper">
              <div className="c-primary-spinners">
                <div className="c-visitors-count-selector-spinner-wrapper">
                  <p className="body-2-title">Adult</p>
                  <Spinner
                    max={maxCount}
                    min={minCount}
                    updateQuantity={updateQuantity}
                    quantity={parseInt(props.totalQuantity)}
                    isInputDisabled="true"
                  />
                  <p className="sub-title">
                    {currency} {toTwoDecimalPlaces(Number(data?.price))}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {parseInt(props.totalQuantity) >= maxCount && (
            <div className="error-group-booking">
              <DynamicContent attrs={{ className: '' }} tagName="div" innerHtml={maxErrorText} />
            </div>
          )}
        </div>
      </>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'PackageQuantityExperienceB2C');
  }
};

export default PackageQuantityExperienceB2C;
