/*
 * logger.js
 *
 * This file contains code for logging audit and error logs
 */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { LoggingService } from './services';
import { canUseDOM, getLoggedInUser, getUserDetails, isEnvDevelopment, resolvePath, getGenericErrors } from './utility';
import UIConfig from './UIConfig';
import moment from 'moment';
import { LogLevels } from './constants';
// import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { GLOBAL_VARS } from './global-vars';
import { ErrorSummary } from '../components/presentation/base';

let logObject = {},
  tempData = [],
  checkAndPOstLogData,
  createLogs,
  ajaxInProgress = false,
  getlogginApiUrl = '',
  logging = '',
  checkLoggerStatus = '',
  logTimeInterval = '';

  function checkAndClearLocalStorage(updateLocalStorage) {
    const keyToCheck = 'logging';
    const maxSizeInBytes = 4 * 1024 * 1024; // 4MB
    if ('localStorage' in window && window['localStorage'] !== null) {
      let totalSizeInBytes = 0;
      for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
          totalSizeInBytes += (localStorage[key].length * 2);
        }
      }
      if (totalSizeInBytes > maxSizeInBytes) {
        localStorage.setItem(keyToCheck, "");
        console.log(`LocalStorage cleared: ${keyToCheck}`);
      }else{
        localStorage.setItem('logging', JSON.stringify(updateLocalStorage));
      }
    } else {
      console.warn('LocalStorage is not supported in this browser.');
    }
  }

/*Load and unload events are handeled here*/
if (canUseDOM()) {
  (() => {
    checkLoggerStatus = UIConfig.LoggingEnabled;
    logTimeInterval = UIConfig.loggingTimeInterval;
    if (localStorage.logging && checkLoggerStatus) {
      window.addEventListener('load', () => {
        logging = JSON.parse(localStorage.logging);
        if (logging.logObjects.length > 0) {
          checkAndPOstLogData();
        }
        setInterval(function() {
          checkAndPOstLogData();
        }, logTimeInterval);
      });
    }
  })();

  let createLogObject, updateLocalStorage, logger, updateTempData;

  updateTempData = () => {
    logging.logObjects.push(tempData);
    logging.logObjectCounter = logging.logObjectCounter + tempData.length;
    let updateLocalStorage = {
      logObjects: logging.logObjects,
      logObjectCounter: logging.logObjectCounter,
      logginApiUrl: logging.logginApiUrl,
    };
    checkAndClearLocalStorage(updateLocalStorage);
    tempData = [];
  };

  createLogObject = (res, moduleName, audit, otherDetails) => {
    const userData = getLoggedInUser();
    let data = Object.assign({}, res);
    if (data.data && data.data.response) {
      delete data.data.response;
    }
    let customConfig = data.config
        ? { headers: data.config.headers, url: data.config.url, method: data.config.method, data: data.data }
        : '',
      responseObj = data.request ? { response: data.request.response, status: data.request.status } : '',
      date = moment().format('YYYY-MM-DD HH:mm:ss');

    if (data.response && data.response.headers) {
      data.response.headers = '';
    }
    let __JSS_STATE__ = null,
      trueClientIP;
    const ssrRawJson = document.getElementById('__JSS_STATE__');
    if (ssrRawJson) {
      __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
      trueClientIP = (__JSS_STATE__ && __JSS_STATE__.trueClientIP) || '';
    }
    logObject = {
      tenantId: userData.tenantID || '',
      logType: audit !== true ? 'log' : 'audit',
      userName: userData.userName || '',
      userId: userData.agentId || getUserDetails(userData),
      date: date,
      moduleName: moduleName ? moduleName : '',
      logLevel: audit !== true ? 'error' : 'all',
      pageUrl: window.location.href,
      additionalDetails: otherDetails ? otherDetails : '',
      xhrObject: JSON.stringify({
        request: customConfig,
        response: responseObj,
      }),
      clientIp: trueClientIP,
      message: data.request && data.request.response ? data.request.response.message : '',
    };
  };

  checkAndPOstLogData = () => {
    if (localStorage.logging && logging.logObjects && logging.logObjects.length > 0) {
      const logObjects = logging.logObjects;
      logger(logObjects);
    }
  };

  createLogs = (data, moduleName, audit, otherDetails) => {
    createLogObject(data, moduleName, audit, otherDetails);
    updateLocalStorage(logObject);
  };

  updateLocalStorage = (data) => {
    logging.logObjects.push(data);
    logging.logObjectCounter++;
    let loggingObj = {
      logObjects: logging.logObjects,
      logObjectCounter: logging.logObjectCounter,
      logginApiUrl: logging.logginApiUrl,
    };

    checkAndClearLocalStorage(loggingObj)
  };

  logger = (data) => {
    if (!ajaxInProgress) {
      ajaxInProgress = true;
      LoggingService.postLogs(data, getlogginApiUrl)
        .then(function() {
          ajaxInProgress = false;
          logging = { logObjects: [], logObjectCounter: 0, logginApiUrl: logging.logginApiUrl };
          checkAndClearLocalStorage(logging)

          if (tempData && tempData.length > 0) {
            updateTempData();
          }

          getlogginApiUrl = logging.logginApiUrl;
        })
        .catch(() => {
          ajaxInProgress = false;
          if (tempData && tempData.length > 0) {
            updateTempData();
          }
        });
    } else {
      tempData.push(logObject);
    }
  };
}

export const Logging = (data, moduleName, audit, otherDetails) => {
  audit = audit || false;
  if (checkLoggerStatus && canUseDOM() && localStorage.logging) {
    logging = JSON.parse(localStorage.logging);
    if (logging.logObjectCounter > UIConfig.checkLogCount) {
      createLogs(data, moduleName, audit, otherDetails);
      checkAndPOstLogData();
    } else {
      createLogs(data, moduleName, audit, otherDetails);
    }
  }
};

export const logComponentRenderingError = (error, moduleName, variant, logLevel = LogLevels.Error) => {
  // const userData = getLoggedInUser();

  // const data = {
  //   date: moment()
  //     .locale(UIConfig.languages.enGB)
  //     .format('YYYY-MM-DD HH:mm:ss'),
  //   logLevel,
  //   logType: 'log',
  //   message: error.message || '',
  //   moduleName: moduleName || '',
  //   pageDetails: GLOBAL_VARS.pageDetails,
  //   renderingType: GLOBAL_VARS.renderingType
  //     ? GLOBAL_VARS.renderingType.client
  //       ? 'client-side rendering'
  //       : 'server-side rendering'
  //     : '',
  //   tenantId: userData.tenantID || '',
  //   userName: userData.userName || '',
  //   userId: userData.agentId || (typeof getUserDetails === 'function' ? getUserDetails(userData) : ''),
  //   variant: variant || '',
  // };

  if (isEnvDevelopment() || (GLOBAL_VARS.renderingType && GLOBAL_VARS.renderingType.client)) {
    // if (logLevel === LogLevels.Warning) {
    //   console.warn('Log Details: ', data);
    // } else {
    //   console.error('Log Details: ', data);
    // }
  } else if (resolvePath(GLOBAL_VARS, 'appInsightsObj.appInsights')) {
    if (logLevel === LogLevels.Warning) {
      // GLOBAL_VARS.appInsightsObj.appInsights.trackTrace({
      //   message: error.message,
      //   severityLevel: SeverityLevel.Warning,
      //   properties: data,
      // });
    } else {
      // GLOBAL_VARS.appInsightsObj.appInsights.trackException({
      //   exception: error,
      //   severityLevel: SeverityLevel.Error,
      //   properties: data,
      // });

      if (canUseDOM()) {
        const genericErrors = getGenericErrors() || {};
        return (
          <ErrorSummary
            className="component-rendering-error"
            data={{ message: { text: genericErrors['666'] || 'Something Went Wrong' } }}
          />
        );
      }
      return null;
    }
  }

  return null;
};

// export const logCustomProfiler = (logData) => {
//   const userData = getLoggedInUser();

//   //sending the other relevant data in message of track trace
//   const messageData = {
//     date: moment()
//       .locale(UIConfig.languages.enGB)
//       .format('YYYY-MM-DD HH:mm:ss'),
//     logLevel: 'profiler',
//     logType: 'log',
//     pageDetails: GLOBAL_VARS.pageDetails,
//     renderingType: GLOBAL_VARS.renderingType
//       ? GLOBAL_VARS.renderingType.client
//         ? 'client-side rendering'
//         : 'server-side rendering'
//       : '',
//     tenantId: userData.tenantID || '',
//     userName: userData.userName || '',
//     userId: userData.agentId || getUserDetails(userData),
//   };

//   const data = {};
//   //changing the structure of logdata for app insights to show component Name with rendered time
//   // in table format
//   for (let i = 0; i < logData.length; i++) {
//     data[
//       logData[i].componentName
//     ] = `|rendered in ${logData[i].componentRenderTime}| |startTime: ${logData[i].componentStartTime}| |endTime: ${logData[i].componentEndTime}|`;
//   }

//   if (isEnvDevelopment() || (GLOBAL_VARS.renderingType && GLOBAL_VARS.renderingType.client)) {
//     console.log('Log Details: ', data);
//   } else {
//     GLOBAL_VARS.appInsightsObj &&
//       GLOBAL_VARS.appInsightsObj.appInsights &&
//       GLOBAL_VARS.appInsightsObj.appInsights.trackTrace({
//         message: JSON.stringify(messageData),
//         severity: SeverityLevel.Information,
//         properties: data,
//       });
//   }

//   return null;
// };
