import React from 'react';
import moment from 'moment';

import { ErrorSummary } from '../components/presentation/base';
import { isEmpty, getProdIdType, getErrorMap, isMatchTenant } from './utility';
import { secProds } from '../components/container/b2c-purchase-journey/add-ons/add-ons-utility.js';
import UIConfig from './UIConfig';

/**
 * change category key to String
 * @param {Object} err error object
 */
export const changeCategoryToString = (prods, coveoDefaultParams) => {
  const tabKey = coveoDefaultParams.current.coveoKeyMap['tab'];

  return prods.map((product) => {
    if (product[tabKey] && product[tabKey] instanceof Array) {
      product[tabKey] = product[tabKey].sort().join('');
    }
    return product;
  });
};
export const updateCouponCodeData = (cartData, cartActions) => {
  const cartState = cartData.cart;
  if (cartState && cartState.hasOwnProperty('couponCode') && cartState.couponCode) {
    cartActions.current._storeExtraCartParams(cartState);
  }
};

/**
 * this function will add the keys to products which would be needed for cart and checkbasket
 * @param {Object} products array of the products to be updated
 * @param {Object} data selection made in tabs
 */
export const enrichProductData = (products, data, groupingNotRequired) => {
  const mupValidityMap = data && data.mupValidityMap ? data.mupValidityMap : '';
  const result = products.map((prod) => {
    if (prod.fromDate) {
      prod.isFlexible = prod.isFlexible || prod.accessPolicy === '1EAD'; //added to identify flexible prod
      prod.toDate = calculateToDate(prod, mupValidityMap);
    } else {
      prod.fromDate = '';
      prod.toDate = '';
    }
    prod.productType = prod.hasOwnProperty('isPackageJourney') ? prod.tab[0] : prod.tab; // added a tab category to find out prduct tab category
    prod.productIdType = getProdIdType(prod, groupingNotRequired.current);
    prod.price = {};
    prod.price.gross = prod.gross || 0;
    prod.price.net = prod.net || 0;
    prod.price.tax = prod.tax || 0;
    prod.discount = prod.discount || {}; //for checkbasket
    prod.discount.actualPerc = prod.discount.actualPerc || 0; //for checkbasket
    prod.discount.computed = prod.unitPrice ? prod.unitPrice - prod.gross : 0;
    prod.unitPrice = prod.unitPrice || prod.gross;

    delete prod['description']; // Delete description for CART API.

    return prod;
  });
  return result;
};

export const setStateOfMinicart = (cartActions, productList, errorCallback, setCartData, setErrObj, errObj) => {
  cartActions.current
    .getCartOnPageLoad()
    .then((res) => {
      if (res.data && res.data.cart) {
        productList.current = res.data.cart.items || [];
      }
    })
    .catch((res) => {
      productList.current = [];
      errorCallback('getCart', res.error);
    })
    .catch(() => true)
    .then(() => {
      setCartData(cartActions.current.getCustomCart(productList.current || [], true));
      setErrObj(getErrorMap('getCart', {}, true, '', errObj));
    });
};
/**
 * extract discount code from discount map provided
 * @param {Number} days days in advance for which booking is done
 * @param {Object} discountMap map containg details of discount against the range of days
 */
export const getDiscount = (days, discountMap, products) => {
  if (isMatchTenant(UIConfig.ymcB2CTenant) && products && products.length > 0) {
    const { pricing } = products[0];
    const matched = discountMap.find(({ code }) => code === pricing);
    return matched && !isEmpty(matched) ? matched : discountMap[discountMap.length - 1];
  } else {
    for (let i = 0; i < discountMap.length - 1; i++) {
      if (days >= discountMap[i].days && days < discountMap[i + 1].days) {
        return discountMap[i];
      }
    }
  }

  return discountMap[discountMap.length - 1];
};

/**
 * caculate "to" date of on the basis of product type and from date
 * @param {Object} date contains date reated data like flexible, from, range
 * @param {object} prod product for which to date calculated
 */
export const calculateToDate = (prod, mupValidityMap) => {
  const to = moment(prod.fromDate).clone();

  if (prod.validUntil) {
    return moment(prod.validUntil)
      .locale('en')
      .format(UIConfig.calendar.dateFormat);
  } else if (prod.isFlexible) {
    to.add(prod.rangeInMonths, 'month');
    to.subtract(1, 'days');
  } else if (prod.itemType === 'MUP' && mupValidityMap.hasOwnProperty(prod.accessPolicy)) {
    to.add(mupValidityMap[prod.accessPolicy] - 1, 'day');
  }
  return parseInt(prod.validUntilDate) ? prod.toDate : to.locale('en').format(UIConfig.calendar.dateFormat);
};

/**
 * currently returning performance id at 0th position as there is ony
 * 1 time slot
 */
export const getPerformanceId = (list, singleSlot) => {
  if (singleSlot && list && list.length) {
    return list[0].sellable && list[0].performanceId;
  }
};

export const getCategoryProds = (category, products) => {
  return products.filter((product) => {
    if (!product.category || !category) {
      return false;
    }
    return product.category.toString() === category.toString();
  });
};

export const updateSecProdArray = (prod, type, map) => {
  secProds[type] = secProds[type] || {};

  switch (prod.experienceCatgory) {
    case map.secondary:
      secProds[type].secondary = secProds[type].secondary || [];
      secProds[type].secondary.push(prod);
      break;
    case map.addOnGuest:
      secProds.addOnGuest = secProds.addOnGuest || [];
      secProds.addOnGuest.push(prod);
      break;
    case map.primary:
      secProds[type].primary = secProds[type].primary || [];
      secProds[type].primary.push(prod);
      break;
    default:
      break;
  }
};

export const renderOverlayErrorMsg = (errorOverlayObj) => {
  if (!isEmpty(errorOverlayObj)) {
    return (
      <ErrorSummary
        data={{
          error: {
            code: errorOverlayObj.errorcode,
            text: errorOverlayObj.text,
          },
        }}
      />
    );
  }
};
