import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DynamicContent, SvgSprite } from '../base';
import Tooltip from '../../presentation/tooltip/tooltip-component';
import { detectViewPort } from '../../../common/utility';
import { logComponentRenderingError } from '../../../common/logger';

/**
 * variation of editorial grid with icons and tooltip component
 */
const config = {
  maxCountMobile: 2,
  maxCountTablet: 4,
};

const viewport = detectViewPort();

const EditorialGridTooltip = ({ cards, disableMore }) => {
  const [showNumber, setShowNumber] = useState(
    viewport === 'mobile'
      ? config.maxCountMobile
      : viewport === 'tablet'
      ? config.maxCountTablet
      : cards && cards.length,
  );

  const [showLoadMore, setLoadMoreState] = useState(false);

  useEffect(() => {
    if (!disableMore) {
      setLoadMoreState(true);
    } else {
      setShowNumber(cards && cards.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // closing tooltip on click of cross icon
  const closeToolTip = () => {
    document.getElementsByTagName('body')[0].click();
  };

  const getOverlayTitle = (item) => {
    return `${item.overlayTooltipTitle || item.title}${
      item.hideShortTitle || !item.shortTitle ? ' ' : `: ${item.shortTitle}`
    }`;
  };

  const maxCount = viewport === 'tablet' ? config.maxCountTablet : config.maxCountMobile;

  try {
    return (
      <div className="editorial-grid-tooltip-wrapper">
        {cards && (
          <div className="editorial-grid-icon">
            {cards.map((item, index) => {
              return (
                index < showNumber && (
                  <div className="c-editorial-grid-tooltip" key={index}>
                    {!item.bodyCopy && <img src={item.image.desktopImage.src} alt={item.image.imageAltText} />}
                    {item.bodyCopy && (
                      <Tooltip
                        onHover={true}
                        wrapperClass="product-tooltip"
                        tabIndex="0"
                        buttonInnerHtml={
                          <div className="editorial-grid-tile-icon">
                            {item.bodyCopy && <img src={item.image.desktopImage.src} alt={item.image.imageAltText} />}
                            <span className="arrow-icon"></span>
                          </div>
                        }
                      >
                        <div className="product-tooltip-body">
                          <div className="cookie-close-icon">
                            <span className="close-tooltip" onClick={closeToolTip}>
                              <SvgSprite id="icn-close" />
                            </span>
                          </div>
                          <DynamicContent
                            tagName="p"
                            innerHtml={getOverlayTitle(item)}
                            attrs={{ className: 'body-copy-4' }}
                          />
                          <DynamicContent
                            tagName="p"
                            innerHtml={item.bodyCopy}
                            attrs={{ className: 'header-bottom-profile-name' }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    <span className="short-title">
                      <DynamicContent tagName="p" innerHtml={item.title} attrs={{ className: 'body-copy-4' }} />
                      <DynamicContent
                        tagName="p"
                        innerHtml={item.shortTitle}
                        attrs={{ className: 'body-copy-4 desc' }}
                      />
                    </span>
                  </div>
                )
              );
            })}
          </div>
        )}
        {showLoadMore && cards.length > config.maxCountMobile && (
          <span
            className={showNumber === maxCount ? 'more-content-visible' : 'more-content-hidden'}
            onClick={() => setShowNumber(cards.length)}
          >
            +{cards.length - maxCount}MORE
          </span>
        )}
      </div>
    );
  } catch (err) {
    return logComponentRenderingError(err, 'EditorialGridTooltip');
  }
};

export default EditorialGridTooltip;

EditorialGridTooltip.propTypes = {
  data: PropTypes.shape({
    cards: PropTypes.array.isRequired,
  }),
};
