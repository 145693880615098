import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import setGlobalVars, { GLOBAL_VARS } from './global-vars';

let reactPlugin = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  /**
   * Initialize the Application Insights class
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (browserHistory) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }

    reactPlugin = new ReactPlugin();

    const env_variables_ele = document.getElementById('__ENV_VARIABLES__');
    let instrumentationKey = '';

    if (env_variables_ele) {
      const env_variables = JSON.parse(env_variables_ele.innerHTML);

      instrumentationKey = env_variables
        ? env_variables.appInsights_instrumentation_key
        : '3de9a333-3ecf-4057-b96f-8a753b76ed7b';
    }

    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });

    appInsights.loadAppInsights();
    setGlobalVars({ renderingType: { client: true, server: false }, appInsightsObj: { appInsights } });
  };
  return { reactPlugin, initialize };
};

export const ai = createTelemetryService();
export const setAppInsights = (appInsights) => {
  if (appInsights && !GLOBAL_VARS.appInsightsObj) {
    setGlobalVars({ renderingType: { client: false, server: true }, appInsightsObj: { appInsights } });
  }
};
