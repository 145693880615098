import React from 'react';
import PropTypes from 'prop-types';
import {
  canUseDOM,
  setLocalStorage,
  getLocalStorageByKey,
  isPopupZoomIn,
  getProtocolHostName,
  iOSDevice,
  isSafariBrowser,
  getAPIStateParam,
  setSessionStorage,
  isMatchTenant,
} from '../../../common/utility';

import UIConfig from '../../../common/UIConfig';
import { logComponentRenderingError } from '../../../common/logger';
import './iam-popup.scss';
import { resolvePath } from '../../../common/utility';
import { bodyClicks } from '../../../common/analytics-events';

export default class IAMPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSocialTitle: false,
      showSocialOptions: false,
    };
    this.mainObj = getLocalStorageByKey(UIConfig.appLocalStorageKeys.mainObj);
    this.originURL = getProtocolHostName(props.loginURL);
    this.facebookURL =
      this.mainObj && this.mainObj.additionalProperty ? this.mainObj.additionalProperty.facebookURL : '';
    this.googleURL = this.mainObj && this.mainObj.additionalProperty ? this.mainObj.additionalProperty.googleURL : '';
    this.facebookTitle =
      this.mainObj && this.mainObj.additionalProperty ? this.mainObj.additionalProperty.facebookTitle : 'Facebook';
    this.googleTitle =
      this.mainObj && this.mainObj.additionalProperty ? this.mainObj.additionalProperty.googleTitle : 'Google';
    this.appleTitle =
      this.mainObj && this.mainObj.additionalProperty ? this.mainObj.additionalProperty.appleTitle : 'Apple';
    this.appleURL = this.mainObj && this.mainObj.additionalProperty ? this.mainObj.additionalProperty.appleURL : '';
    this.socialTitle =
      this.mainObj && this.mainObj.additionalProperty ? this.mainObj.additionalProperty.socialTitle : 'Continue With';
  }

  loadHTMLMarkUp = () => {
    if (canUseDOM()) {
      document.getElementById('loginiframe').src = this.props.loginURL;
    }
  };

  componentWillUnmount = () => {
    if (canUseDOM()) {
      document.body.classList.remove('iam-zoom-in');
    }
  };

  checkZoomLevel = () => {
    isPopupZoomIn() ? document.body.classList.add('iam-zoom-in') : document.body.classList.remove('iam-zoom-in');
  };

  componentDidMount() {
    if (canUseDOM()) {
      this.checkZoomLevel();
      this.loadHTMLMarkUp(this.props.loginURL);
      const container = document.getElementsByClassName('overlay-content-container');
      const socialIntro = document.getElementById('socialIntro');
      const socialOptions = document.getElementById('socialOptions');
      const iamWrapper = document.getElementById('iam-login-wrapper');
      const socialWrapper = document.getElementById('socialWrapper');
      const facebook = document.getElementById('FacebookExchange');
      const google = document.getElementById('GoogleExchange');
      const apple = document.getElementById('AppleExchange');
      const iframeIAMPopup = document.querySelector('.iframe-iam-popup');
      container[0].classList.add('login-screen');
      socialIntro && socialIntro.focus();
      if (window.addEventListener && socialOptions && socialIntro && iamWrapper && socialWrapper) {
        window.addEventListener(
          'message',
          (e) => {
            if (e.origin === this.originURL && e.data.message === 'Message text from iframe.') {
              socialOptions.style.display = 'none';
              socialIntro.style.display = 'none';
              socialWrapper.removeAttribute('style');
              socialWrapper.style.height = 'auto';
              iamWrapper.classList.remove('login-screen');
              container[0].classList.remove('login-screen');
              iframeIAMPopup.removeAttribute('style');
            }
            if (e.origin === this.originURL && e.data.message === 'Message text from login page.') {
              socialOptions.style.display = 'flex';
              socialIntro.style.display = 'block';
              iamWrapper.classList.add('login-screen');
              container[0].classList.add('login-screen');
              facebook.style.display = 'inline-block';
              google.style.display = 'inline-block';
              apple && (apple.style.display = 'block');
              iamWrapper.classList.remove('loadingIAM');
            }
            if (e.origin === this.originURL && e.data.message === 'Add loader.') {
              iamWrapper.classList.add('loadingIAM');
            }
            if (e.origin === this.originURL && e.data.message === 'Remove loader.') {
              iamWrapper.classList.remove('loadingIAM');
            }
            if (e.origin === this.originURL && e.data.message === 'Login Successful.') {
              setTimeout(() => {
                socialWrapper.style.height = socialWrapper.offsetHeight;
                socialIntro.style.display = 'none';
                socialOptions.style.display = 'none';
              }, 1000);
            }
            if (e.origin === this.originURL && e.data.message === 'Registration Successful.') {
              setTimeout(() => {
                socialWrapper.style.height = socialWrapper.offsetHeight;
                socialIntro.style.display = 'none';
                socialOptions.style.display = 'none';
              }, 1000);
              setSessionStorage('RegistrationComplete', true);
            }
            if (e.origin === this.originURL && e.data.message === 'Email Address.') {
              setLocalStorage(UIConfig.appLocalStorageKeys.userEmailAddress, e.data.email);
            }
            if (e.origin === this.originURL && e.data.hasOwnProperty('containerWidth')) {
              socialWrapper.style.width = `${e.data.containerWidth}px`;
            }
            if (e.origin === this.originURL && e.data.hasOwnProperty('frameHeight')) {
              iframeIAMPopup.style.paddingTop = `${e.data.frameHeight}px`;
            }
          },
          false,
        );
      }
    }
  }

  setSocialState = (socialUrl) => {
    if (canUseDOM()) {
      const stateParam = getAPIStateParam();
      return `${socialUrl}&state=${stateParam}`;
    }
    return '';
  };

  render() {
    const getMainObj = canUseDOM() && JSON.parse(localStorage.getItem('mainObj'));
    const checkfortenants = resolvePath(getMainObj, 'tenantID', '').toLowerCase() === UIConfig.YIB2C;

    const googleAnalyticHandlerbody = (eventName, titleData, labelData) => {
      if (checkfortenants) {
        bodyClicks(eventName, titleData, labelData);
      }
    };
    try {
      return (
        <div className="iam-login-wrapper loadingIAM" id="iam-login-wrapper">
          <div className="socialWrapper" id="socialWrapper">
            {
              <div className="socialIntro hideSocialSignIn" id="socialIntro">
                {this.socialTitle}
              </div>
            }
            {
              <div className="socialOptions hideSocialSignIn" id="socialOptions">
                {isMatchTenant(UIConfig.tenants.ymc)
                  ? // If the tenant is "ymc", only check for the URL
                    this.appleURL && (
                      <div>
                        <a
                          className="socialAccountButton hideSocialSignIn"
                          id="AppleExchange"
                          tabIndex="0"
                          href={this.setSocialState(this.appleURL)}
                          onClick={() => {
                            googleAnalyticHandlerbody(
                              UIConfig.commonVariant.gaClickEvents.imageClick,
                              this.socialTitle,
                              this.appleTitle,
                            );
                          }}
                        >
                          {this.appleTitle}
                        </a>
                      </div>
                    )
                  : // If the tenant is not "ymc", check for Safari and iOS device
                    (iOSDevice() || isSafariBrowser()) &&
                    this.appleURL && (
                      <div>
                        <a
                          className="socialAccountButton hideSocialSignIn"
                          id="AppleExchange"
                          tabIndex="0"
                          href={this.setSocialState(this.appleURL)}
                          onClick={() => {
                            googleAnalyticHandlerbody(
                              UIConfig.commonVariant.gaClickEvents.imageClick,
                              this.socialTitle,
                              this.appleTitle,
                            );
                          }}
                        >
                          {this.appleTitle}
                        </a>
                      </div>
                    )}
                <div>
                  <a
                    className="socialAccountButton socialFirstButton hideSocialSignIn"
                    id="FacebookExchange"
                    tabIndex="0"
                    href={this.setSocialState(this.facebookURL)}
                    onClick={() => {
                      googleAnalyticHandlerbody(
                        UIConfig.commonVariant.gaClickEvents.imageClick,
                        this.socialTitle,
                        this.facebookTitle,
                      );
                    }}
                  >
                    {this.facebookTitle}
                  </a>
                </div>

                <div>
                  <a
                    className="socialAccountButton hideSocialSignIn"
                    id="GoogleExchange"
                    tabIndex="0"
                    href={this.setSocialState(this.googleURL)}
                    onClick={() => {
                      googleAnalyticHandlerbody(
                        UIConfig.commonVariant.gaClickEvents.imageClick,
                        this.socialTitle,
                        this.googleTitle,
                      );
                    }}
                  >
                    {this.googleTitle}
                  </a>
                </div>
              </div>
            }
          </div>
          <div className="iframe-iam-popup">
            {
              <iframe
                tabIndex="0"
                width="100%"
                name="IAM_iframe"
                title="IAM"
                id="loginiframe"
                className="iframe-wrapper"
              />
            }
          </div>
        </div>
      );
    } catch (err) {
      return logComponentRenderingError(err, 'IAMPopup');
    }
  }
}
/**
 * basic structure of data contract
 */
IAMPopup.propTypes = {
  data: PropTypes.shape({
    loginURL: PropTypes.string.isRequired,
  }),
};
