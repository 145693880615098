import React, { useEffect, useState } from 'react';
import moment from 'moment';

import UIConfig from '../../../../common/UIConfig';
import { toTwoDecimalPlaces, checkEventTypeProduct, resolvePath } from '../../../../common/utility';
import { DynamicContent } from '../../../presentation/base';
import PackageQuantityRow from './package-quantity-row';

const _getFormattedDateValue = (elementType = 'p', className = 'date-value', innerHtml = '') => {
  return (
    <span>
      <DynamicContent tagName={elementType} attrs={{ className: className }} innerHtml={innerHtml} />
    </span>
  );
};

const PackageProductRow = ({ packageItem, propData, isCartPaymentView = false, deleteOverlay }) => {
  const [isPkgTotal, setPkgTotal] = useState(0);
  const { data } = propData;
  const productGroupList = packageItem.items;

  const getPkgTotal = (data) => {
    const pkgCode = packageItem?.items?.[0]?.packageGroupCode;
    const sltdPkg = data?.packages.find((pkg) => pkg?.items?.[0]?.packageGroupCode === pkgCode);
    const pkgList = sltdPkg || packageItem;
    let result = 0;
    pkgList?.items.length &&
      pkgList.items.forEach((item) => {
        if (item.hasOwnProperty('isPackageProd')) {
          result += Number(item.gross);
        } else {
          result += Number(item.price.gross);
        }
      });
    setPkgTotal(result);
  };

  useEffect(() => {
    window.PubSub.subscribe('getPackageRes', (msg, data) => {
      getPkgTotal(data);
    });
    getPkgTotal();
  }, []);

  const getProductInfo = (item, idx) => {
    const elementsArr = [];
    let productName = '';

    productName = item.productName;

    const {
      data: { cartLabels },
    } = propData;
    let label = '';
    let innerHtml = '';
    if (isCartPaymentView) {
      if (!propData.cartSummaryExpandedView && item.toDate) {
        label = cartLabels.validOn;
        let prodDate = item.fromDate;
        innerHtml = `<span>${cartLabels.flexible} ${label}</span> <span class="date">${moment(prodDate).format(
          UIConfig.b2c.purchaseJourney.dateFormat,
        )}</span>
        ${item.timeSlot ? `| <span class="mycart-timeslot">${cartLabels.timeSlot} ${item.timeSlot}</span>` : ''}`;
        elementsArr.push(_getFormattedDateValue('p', 'date-value', innerHtml));
      } else if (propData.cartSummaryExpandedView) {
        label = item.isFlexible ? cartLabels.validUntil : cartLabels.validOn;
        if (item.toDate) {
          innerHtml = `${cartLabels.flexible} ${label} ${moment(item.toDate).format(
            UIConfig.b2c.purchaseJourney.dateFormat,
          )} ${item.timeSlot ? ` ${item.timeSlot}` : ''}`;
          elementsArr.push(_getFormattedDateValue('p', 'date-value', innerHtml));
        }
      }
    }

    const { products, productCategoryLabel } = item;
    const productCatLabel = products?.length && products[0].productCategoryLabel;

    return (
      <div className="product-name">
        <div className="product-title-wrapper">
          <DynamicContent tagName="p" attrs={{ className: 'heading-4 11' }} innerHtml={productName} />

          {data.cartType === UIConfig.b2c.purchaseJourney.cartVariant.couponcodeminicart || data.showCategoryLabel ? (
            <DynamicContent
              tagName="p"
              attrs={{ className: 'sub-category' }}
              innerHtml={item && (productCatLabel || productCategoryLabel)}
            />
          ) : null}
        </div>
        {packageItem.packageGross === 0 ? '' : elementsArr.map((el) => el)}

        {propData.cartSummaryExpandedView &&
          productGroupList.length - 1 === idx &&
          packageItem?.items?.[0]?.packageQty &&
          item.unitPrice &&
          item.classTypeName && (
            <div className="ticket-value">
              <div className="ticket-quantity-value">
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'quantity-amount' }}
                  innerHtml={`${packageItem.items[0].packageQty}`}
                />
                <DynamicContent
                  tagName="span"
                  attrs={{ className: 'quantity-type' }}
                  innerHtml={`${item.classTypeName}`}
                />
              </div>
              <DynamicContent
                tagName="span"
                attrs={{ className: 'quantity-price' }}
                innerHtml={`${propData.cartData.cart.currency} ${toTwoDecimalPlaces(
                  Number(isPkgTotal * packageItem.items[0].packageQty),
                )}`}
              />
            </div>
          )}
      </div>
    );
  };

  const getValidOnDay = (item) => {
    const { eventType } = UIConfig.b2c.purchaseJourney.ymcMapping;
    let validOn = '';
    if (checkEventTypeProduct(item, eventType.wrx)) {
      validOn = item.wrcEventDate || '';
    } else if (checkEventTypeProduct(item, eventType.f1) && propData.data.weekDaysDisplay) {
      const dayKey = item.weekDays;
      validOn = propData.data.weekDaysDisplay[dayKey];
    } else {
      validOn = moment(item.fromDate).format(UIConfig.b2c.purchaseJourney.dateFormat);
    }
    return validOn;
  };

  const getTicketsValidityInfo = (item) => {
    const { products, timeSlot, fromDate } = item;
    const { isFlexible, toDate } = products?.length ? products[0] : item;
    if (isCartPaymentView) return null;

    let isTimeSlot;
    if (products?.length) {
      isTimeSlot = products[0].timeSlot;
    } else {
      isTimeSlot = timeSlot;
    }

    const { validUntil, validOn } = propData.data.cartLabels;

    const validDate = isFlexible ? toDate : fromDate || '';

    return (
      validDate && (
        <span className="ticket-validity">
          <DynamicContent
            tagName="p"
            attrs={{ className: 'date-label body-1' }}
            innerHtml={`${isFlexible ? validUntil : validOn}`}
          />
          <p className="date-value">
            {isFlexible ? moment(validDate).format(UIConfig.b2c.purchaseJourney.dateFormat) : getValidOnDay(item)}
          </p>
          {item && isTimeSlot && (
            <span className="time-slot">
              <DynamicContent
                tagName="p"
                attrs={{ className: 'date-label body-1' }}
                innerHtml={`${propData.data.cartLabels.timeSlot}`}
              />
              <p className="date-value">{isTimeSlot || ''}</p>
            </span>
          )}
        </span>
      )
    );
  };

  return (
    <div className="ticket-section package-group-section">
      <DynamicContent tagName="p" attrs={{ className: 'heading-4' }} innerHtml={packageItem.items[0].packageTitle} />
      <div className={'product-details-wrapper'}>
        <div className="">
          {productGroupList &&
            productGroupList.map((item, idx) => {
              return (
                <div className="product-details" tabIndex="0">
                  {getProductInfo(item, idx)}
                  {getTicketsValidityInfo(item)}
                  {/* {grossTicketPrice === 0 ? '' : getTicketsValidityInfo()} */}
                </div>
              );
            })}
        </div>
        <div className={'ticket-subsection-container'}>
          <PackageQuantityRow
            packageItem={packageItem}
            propData={propData}
            currency={propData.cartData.cart.currency}
            pkgPrice={isPkgTotal}
            deleteOverlay={deleteOverlay}
            data={propData.data}
            deleteProduct={propData.deleteProduct}
          />
        </div>
      </div>
      {packageItem.invalidQuantity && (
        <DynamicContent
          tagName="div"
          attrs={{ className: 'ticket-section--error' }}
          innerHtml={resolvePath(propData, 'data.businessErrors.INVALIDQTY', '')}
        />
      )}
      <div className="sub-total">
        <DynamicContent
          tagName="p"
          attrs={{ className: 'sub-total-label body-2' }}
          innerHtml={propData.data.cartLabels.subTotal}
        />
        <DynamicContent
          tagName="p"
          attrs={{ className: 'body-1 sub-total-price' }}
          innerHtml={`${propData.cartData.cart.currency} ${toTwoDecimalPlaces(
            Number(isPkgTotal * packageItem.items[0].packageQty),
          )}`}
        />
      </div>
    </div>
  );
};

export default PackageProductRow;
